export default {
  Foundation: {
    Number: {
      StructureAndCalculation: {
        OrderingNumbers: false,
        OperationsAndPlaceValue: false,
        OrderOfOperations: false,
        FactorsMultiplesAndPrimes: false,
        SystematicListing: false,
        Powers: false,
        ExactValues: false,
        StandardForm: false,
      },
      FractionsDecimalsAndPercentages: {
        ConvertingFractions: false,
        FractionsAndRatio: false,
        FractionsOfAmounts: false,
      },
      MeasuresAndAccuracy: {
        Measures: false,
        Estimation: false,
        Rounding: false,
        Bounds: false,
      },
    },
    Algebra: {
      NotationVocabularyAndManipulation: {
        Notation: false,
        Substitution: false,
        Vocabulary: false,
        Manipulation: false,
        ChangingTheSubject: false,
        Functions: false,
      },
      Graphs: {
        Coordinates: false,
        Lines: false,
        GradientAndIntercept: false,
        FunctionGraphs: false,
        GraphProblems: false,
      },
      SolvingEquationsAndInequalities: {
        LinearEquations: false,
      },
      Sequences: {
        Terms: false,
        AdvancedSequences: false,
        NthTerm: false,
      },
    },
    RatioProportionAndRatesOfChange: {
      RatioProportionAndRatesOfChange: {
        ConvertingUnits: false,
        Scale: false,
        FractionsOfQuantities: false,
        Ratio: false,
        RatioProblems: false,
        ComparingQuantity: false,
        Proportion: false,
        RatioAndLinearFunctions: false,
        Percentages: false,
        ProportionProblems: false,
        CompoundUnits: false,
        MeasuresAndRatio: false,
      },
    },
    Geometry: {
      PropertiesAndConstructions: {
        Terminology: false,
        Angles: false,
        Shapes: false,
        ShapeTransformations: false,
        Circles: false,
        GeometricalProblems: false,
        Shapes3D: false,
        Plans3D: false,
      },
      MensurationAndCalculation: {
        Measures: false,
        Measuring: false,
        AreaAndVolume: false,
        PerimeterAndSurfaceArea: false,
      },
      Vectors: {
        Vectors: false,
      },
    },
    ProbabilityAndStatistics: {
      Probability: {
        BasicProbability: false,
        Randomness: false,
        ExpectedFrequencies: false,
        SumsOfProbabilities: false,
        ProbabilityDiagrams: false,
        ProbabilitySpaces: false,
      },
      Statistics: {
        StatisticalDiagrams: false,
        StatisticalMeasures: false,
        Populations: false,
        ScatterGraphs: false,
      },
    },
  },
  Crossover: {
    Number: {
      StructureAndCalculation: {
        CalculationsWithPowers: false,
        ExactValues: false,
      },
      MeasuresAndAccuracy: {
        Rounding: false,
      },
    },
    Algebra: {
      NotationVocabularyAndManipulation: {
        Vocabulary: false,
        Manipulation: false,
        IdentityAndProof: false,
      },
      Graphs: {
        Lines: false,
        Quadratics: false,
        FunctionGraphs: false,
        GraphProblems: false,
      },
      SolvingEquationsAndInequalities: {
        LinearEquations: false,
        Quadratics: false,
        SimultaneousEquations: false,
        AlgebraProblems: false,
        Inequalities: false,
      },
      Sequences: {
        AdvancedSequences: false,
      },
    },
    RatioProportionAndRatesOfChange: {
      RatioProportionAndRatesOfChange: {
        ConvertingUnits: false,
        CompoundUnits: false,
        MeasuresAndRatio: false,
        InverseProportion: false,
        ProportionAndGraphs: false,
        GrowthAndDecay: false,
      },
    },
    Geometry: {
      PropertiesAndConstructions: {
        Construction: false,
        Congruence: false,
        Triangles: false,
        ShapeTransformations: false,
        Circles: false,
        Plans3D: false,
      },
      MensurationAndCalculation: {
        PerimeterAndSurfaceArea: false,
        CircleMeasurments: false,
        Congruence: false,
        TriangleTheorems: false,
        TrigExactValues: false,
      },
      Vectors: {
        VectorArithmetic: false,
      },
    },
    ProbabilityAndStatistics: {
      Probability: {
        ProbabilitiesFromSamples: false,
        ProbabilityDiagrams: false,
        DependentProbabilities: false,
      },
      Statistics: {
        Sampling: false,
        StatisticalDiagrams: false,
        ScatterGraphs: false,
      },
    },
  },
  Higher: {
    Number: {
      StructureAndCalculation: {
        SystematicListing: false,
        Powers: false,
        CalculationsWithPowers: false,
        ExactValues: false,
      },
      FractionsDecimalsAndPercentages: {
        ConvertingFractions: false,
      },
      MeasuresAndAccuracy: {
        Bounds: false,
      },
    },
    Algebra: {
      NotationVocabularyAndManipulation: {
        Manipulation: false,
        IdentityAndProof: false,
        Functions: false,
      },
      Graphs: {
        Lines: false,
        Quadratics: false,
        FunctionGraphs: false,
        Transformations: false,
        GraphProblems: false,
        AreaAndGradient: false,
        Circles: false,
      },
      SolvingEquationsAndInequalities: {
        Quadratics: false,
        SimultaneousEquations: false,
        Iteration: false,
        Inequalities: false,
      },
      Sequences: {
        AdvancedSequences: false,
        NthTerm: false,
      },
    },
    RatioProportionAndRatesOfChange: {
      RatioProportionAndRatesOfChange: {
        InverseProportion: false,
        Gradients: false,
        GrowthAndDecay: false,
      },
    },
    Geometry: {
      PropertiesAndConstructions: {
        ShapeTransformations: false,
        CombinedTransformations: false,
        CircleTheorems: false,
      },
      MensurationAndCalculation: {
        Congruence: false,
        TriangleTheorems: false,
        TrigRules: false,
        TriangleArea: false,
      },
      Vectors: {
        VectorArithmetic: false,
      },
    },
    ProbabilityAndStatistics: {
      Probability: {
        DependentProbabilities: false,
      },
      Statistics: {
        HistogramsAndCumulativeFrequency: false,
        StatisticalMeasures: false,
      },
    },
  },
};
