import * as MCRandom from './../modules/MCRandom';
import * as MCMaths from './../modules/MCMaths';
import * as MCQuestion from './../modules/MCQuestion';
import * as MCMisc from './../modules/MCMisc';
import * as MCError from './../modules/MCError';

export function A2_AlgebraAndFunctions_A2Transformations_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const funcs = [
      new MCMaths.Trig(1, "sin"),
      new MCMaths.Trig(1, "cos"),
      new MCMaths.Exponential(),
      new MCMaths.Logarithm(),
      new MCMaths.Polynomial([1, 0, 0]),
      new MCMaths.Polynomial([1, 0, 0, 0]),
    ];
    const pSize = 10;
    const ranges = [
      [2 * Math.PI, -2 * Math.PI, 6, -6, Math.PI / 2, 1],
      [2 * Math.PI, -2 * Math.PI, 6, -6, Math.PI / 2, 1],
      [pSize, -pSize, pSize, -pSize, 2, 2],
      [pSize, -pSize, pSize, -pSize, 2, 2],
      [pSize, -pSize, pSize, -pSize, 2, 2],
      [pSize, -pSize, pSize, -pSize, 2, 2],
    ];
    const fType = MCRandom.randomInt(0, funcs.length - 1);
    const r = ranges[fType];
    const x = funcs[fType];

    // Q Types
    // X translation X scale
    // X translation Y scale
    // Y translation X scale
    // Y translation Y scale
    // X translation X reflection
    // X translation Y reflection
    // Y translation X reflection
    // Y translation Y reflection

    const t1 = MCRandom.randomInt(2, 4);
    const s1 = MCRandom.randomInt(2, 4);

    const qs = [
      x.toString().replace(/x/g, `(${s1}x-${t1 * s1})`),
      `${s1}${x.toString().replace(/x/g, `(x-${t1})`)}`,
      `${x.toString().replace(/x/g, `(${s1}x)`)}+${t1}`,
      `${s1}${x.toString()}+${t1 * s1}`,
      x.toString().replace(/x/g, `(-x+${t1})`),
      `-${x.toString().replace(/x/g, `(x-${t1})`)}`,
      `${x.toString().replace(/x/g, `(-x)`)}+${t1}`,
      `${-t1}-${x.toString()}`,
    ];

    const as = [
      [
        `b) A translation by $\\begin{pmatrix} ${t1} \\\\ 0 \\end{pmatrix}$, followed by a strech of scale factor $${s1}$ in the $x$-direction.`,
        `Alternatively: a strech of scale factor $${s1}$ in the $x$-direction followed by a translation $\\begin{pmatrix} ${
          t1 * s1
        } \\\\ 0 \\end{pmatrix}$.`,
      ],
      [
        `b) A translation by $\\begin{pmatrix} ${t1} \\\\ 0 \\end{pmatrix}$, and a strech of scale factor $${s1}$ in the $y$-direction, in either order.`,
      ],
      [
        `b) A translation by $\\begin{pmatrix} 0 \\\\ ${t1} \\end{pmatrix}$, and a strech of scale factor $${s1}$ in the $x$-direction, in either order.`,
      ],
      [
        `b) A translation by $\\begin{pmatrix} 0 \\\\ ${t1} \\end{pmatrix}$, followed by a strech of scale factor $${s1}$ in the $y$-direction.`,
        `Alternatively: a strech of scale factor $${s1}$ in the $y$-direction followed by a translation $\\begin{pmatrix} 0 \\\\ ${
          t1 * s1
        } \\end{pmatrix}$.`,
      ],
      [
        `b) A translation by $\\begin{pmatrix} ${t1} \\\\ 0 \\end{pmatrix}$, followed by a reflection in the $x$-axis.`,
        `Alternatively, a reflection in the $x$-axis, followed by a translation by $\\begin{pmatrix} -${t1} \\\\ 0 \\end{pmatrix}$.`,
      ],
      [
        `b) A translation by $\\begin{pmatrix} ${t1} \\\\ 0 \\end{pmatrix}$, and a reflection in the $y$-axis, in either order.`,
      ],
      [
        `b) A translation by $\\begin{pmatrix} 0 \\\\ ${t1} \\end{pmatrix}$, and a reflection in the $x$-axis, in either order.`,
      ],
      [
        `b) A translation by $\\begin{pmatrix} 0 \\\\ ${t1} \\end{pmatrix}$, followed by a reflection in the $y$-axis.`,
        `Alternatively, a reflection in the $y$-axis, followed by a translation by $\\begin{pmatrix} 0 \\\\ -${t1} \\end{pmatrix}$.`,
      ],
    ];

    const type1 = 7; // MCRandom.randomInt(0, qs.length - 1);
    const q1 = MCMaths.cleaner(
      qs[type1].replace("((", "(").replace(")\\right)", "\\right)")
    );

    const f = x.functionString();

    let f2;
    if (type1 === 0) {
      f2 = x.functionString().replace(/x/g, `(${s1} * x - ${t1} * ${s1})`);
    }
    if (type1 === 1) {
      f2 = `${s1}*${x.functionString().replace(/x/g, `(x - ${t1})`)}`;
    }
    if (type1 === 2) {
      f2 = `${t1}+${x.functionString().replace(/x/g, `(${s1} * x)`)}`;
    }
    if (type1 === 3) {
      f2 = `${s1}* ${x
        .functionString()
        .replace(/x/g, `(${s1}* x)`)}+(${t1}*${s1})`;
    }
    if (type1 === 4) {
      f2 = x.functionString().replace(/x/g, `(${t1} - x)`);
    }
    if (type1 === 5) {
      f2 = `-${x.functionString().replace(/x/g, `(x - ${t1})`)}`;
    }
    if (type1 === 6) {
      f2 = `${x.functionString().evaluate(/x/g, `(-x)`)}+${t1}`;
    }
    if (type1 === 7) {
      f2 = `${-t1}-${x.functionString()}`;
    }

    // const a1 = MCMaths.cleaner(as[type1]);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph("solution", "a)");

      const g1 = new MCQuestion.Graph(...r);
      g1.plot(f, r[1], r[0]);
      question.addGraph("solution", g1);

      question.addMultipleParagraphs("question", [
        `Given $y_1=${x.toString()}$:`,
        `a) Plot the graph of $y_1$.`,
        `b) What two transformations map this function to $y_2=${q1}$.`,
        `c) Plot the graph of $y_2$.`,
      ]);

      question.addMultipleParagraphs("solution", as[type1]);

      question.addParagraph("solution", "c)");

      const g2 = new MCQuestion.Graph(...r);
      g2.plot(f2, r[1], r[0]);
      question.addGraph("solution", g2);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_CompositeAndInverseFunctions_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const top = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const bot = MCRandom.randomInt(1, 9);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `The function $f(x)$ is defined by`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$f(x)=\\frac{${top}}{x^2+${bot}}, x \\geq 0$`)
      );
      question.addParagraph("question", `a) State the range of $f(x)$`);
      question.addParagraph(
        "question",
        `b) Find $f^{-1}(x)$, giving your answer in the form $f^{-1}(x)=\\sqrt{\\frac{A}{x}+B}$`
      );
      question.addParagraph("question", `c) State the domain of $f^{-1}(x)$`);
      // solution
      // a
      question.addHeading("solution", `a) State the range of $f(x)$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f(x)=\\frac{${top}}{x^2+${bot}}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`$x^2 \\geq 0$ for all values of $x$`)
      );
      if (top > 0) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\frac{${top}}{x^2+${bot}} \\leq \\frac{${top}}{${bot}}$ for all values of $x$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore f(x) \\leq ${top / bot}$`)
        );
        question.addHeading("solution", MCMaths.cleaner(`Also $f(x) > 0$`));
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore 0<f(x) \\leq ${top / bot}$`)
        );

        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput(0, 0, "a) $\\ f(x)>$");
        group1.addInput(top / bot, 0, "a) $\\ f(x) \\leq$");
        question.addInputGroup(group1);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\frac{${top}}{x^2+${bot}} \\geq \\frac{${top}}{${bot}}$ for all values of $x$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore f(x) \\geq ${top / bot}$`)
        );
        question.addHeading("solution", MCMaths.cleaner(`Also $f(x) < 0$`));
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore  ${top / bot}\\leq f(x)<0 $`)
        );

        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput(top / bot, 0, "a) $\\ f(x) \\geq$");
        group1.addInput(0, 0, "a) $\\ f(x)<$");
        question.addInputGroup(group1);
      }
      // b
      question.addHeading(
        "solution",
        `b) Find $f^{-1}(x)$, giving your answer in the form $f^{-1}(x)=\\sqrt{\\frac{A}{x}+B}$`
      );
      question.addHeading("solution", `Let $y=f(x)$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=\\frac{${top}}{x^2+${bot}}$`)
      );
      question.addHeading("solution", `Swap $y$ and $x$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x=\\frac{${top}}{y^2+${bot}}$`)
      );
      question.addHeading("solution", `Make $y$ the subject`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x(y^2+${bot})=${top}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y^2+${bot}=\\frac{${top}}{x}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y^2=\\frac{${top}}{x}+${-bot}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=\\sqrt{\\frac{${top}}{x}+${-bot}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore f^{-1}(x)=\\sqrt{\\frac{${top}}{x}+${-bot}}$`
        )
      );

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(top, 0, "b) $\\ A=$");
      group2.addInput(-bot, 0, "b) $\\ B=$");
      question.addInputGroup(group2);
      // c
      question.addHeading("solution", `c) State the domain of $f^{-1}(x)$`);
      question.addHeading(
        "solution",
        `Domain of $f^{-1}(x)$ is the same as the range of $f(x)$`
      );
      if (top > 0) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore 0<f(x) \\leq ${top / bot}$`)
        );
        const group3 = new MCQuestion.InputGroup(2);
        group3.addInput(0, 0, "c) $\\ f(x)>$");
        group3.addInput(top / bot, 0, "c) $\\ f(x) \\leq$");
        question.addInputGroup(group3);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore  ${top / bot}\\leq f(x)<0 $`)
        );
        const group3 = new MCQuestion.InputGroup(2);
        group3.addInput(top / bot, 0, "c) $\\ f(x) \\geq$");
        group3.addInput(0, 0, "c) $\\ f(x)<$");
        question.addInputGroup(group3);
      }

      //
      question.requiresSelfMarking();

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_CompositeAndInverseFunctions_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const c = MCRandom.randomInt(4, 10) ** 2;
    const r =
      MCRandom.randomInt(1, Math.sqrt(c)) * MCRandom.randomInt(-1, 1, 2);

    const solve =
      -1 * MCRandom.randomInt(1, Math.sqrt(c)) ** 2 + (c - 1 * r ** 2);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(`question`, `A function $f(x)$ is defined by`);
      question.addParagraph(
        `question`,
        `$f(x)=\\sqrt{${c}-x^2}, 0 \\leq x \\leq ${Math.sqrt(c)}$`
      );
      question.addParagraph(`question`, `a) Show that $ff(x)=x$`);
      question.addParagraph(`question`, `b) Hence, find $f^{-1}(x)$`);
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`$g(x)=(x+${r})(x+${-r})$`)
      );
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`c) Solve $gf(x)=${100}$`)
      );
      // solution
      // a
      question.addHeading(`solution`, `a) Show that $ff(x)=x$`);
      question.addParagraph(`solution`, `$f(x)=\\sqrt{${c}-x^2}$`);
      question.addParagraph(
        `solution`,
        `$\\therefore ff(x)=\\sqrt{${c}-(\\sqrt{${c}-x^2})^2}$`
      );
      question.addParagraph(`solution`, `$=\\sqrt{${c}-(${c}-x^2)}$`);
      question.addParagraph(`solution`, `$=\\sqrt{x^2}$`);
      question.addParagraph(
        `solution`,
        `$=x$ as $0 \\leq x \\leq ${Math.sqrt(c)}$`
      );
      // b
      question.addHeading(`solution`, `b) Hence, find $f^{-1}(x)$`);
      question.addHeading(
        `solution`,
        `As $ff(x)=x$ , $f(x)$ is self-inversive`
      );
      question.addHeading(`solution`, `$\\therefore f^{-1}(x)=f(x)$`);
      question.addHeading(`solution`, `$=\\sqrt{${c}-x^2}$`);
      // c
      question.addHeading(
        `solution`,
        MCMaths.cleaner(`c) Solve $gf(x)=${solve}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$g(x)=(x+${r})(x+${-r})$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=x^2+${-1 * r ** 2}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$gf(x)=(\\sqrt{${c}-x^2})^2+${-1 * r ** 2}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=(${c}-x^2)+${-1 * r ** 2}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore gf(x)=${c - 1 * r ** 2}-x^2$`)
      );
      question.addHeading(`solution`, MCMaths.cleaner(`Let $gf(x)=${solve}$`));
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${c - 1 * r ** 2}-x^2=${solve}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$x^2=${c - 1 * r ** 2 - solve}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$\\therefore x=${Math.sqrt(
            c - 1 * r ** 2 - solve
          )}$ as $0 \\leq x \\leq ${Math.sqrt(c)}$`
        )
      );

      //
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(Math.sqrt(c - 1 * r ** 2 - solve), 0, "c) $\\ x=$");
      question.addInputGroup(group1);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_CompositeAndInverseFunctions_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(2, 8);
    const c = MCRandom.randomInt(-8, -2);

    const b = MCRandom.randomInt(1, 5);
    const d = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const domain = -d / b;

    const x = MCRandom.randomInt(Math.ceil(domain), Math.ceil(domain) + 5);
    /* END VARIABLES AND CONSTANTS */
    if (a * d + c !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `The functions $f(x)$ and $g(x)$ are defined by`
      );
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`$f(x)=${a}e^{x}+${c}$`)
      );
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`$g(x)=\\ln(${b}x+${d}),x>${domain}$`)
      );
      question.addParagraph(`question`, `a) State the range of $f(x)$`);
      question.addParagraph(
        `question`,
        `b) Find $f^{-1}(x)$, giving your answer in the form $f^{-1}(x)=\\ln(x+A)-\\ln(B)$`
      );
      question.addParagraph(`question`, `c) Evaluate $fg(${x})$`);
      // solution
      // a
      question.addHeading(`solution`, `a) State the range of $f(x)$`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$f(x)=${a}e^{x}+${c}$`)
      );
      question.addHeading(
        `solution`,
        MCMaths.cleaner(`$${a}e^{x}>0$ for all values of $x$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${a}e^{x}+${c}>${c}$ for all values of $x$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore f(x)>${c}$`)
      );
      // b
      question.addHeading(
        `solution`,
        `b) Find $f^{-1}(x)$, giving your answer in the form $f^{-1}(x)=\\ln(x+A)-\\ln(B)$`
      );
      question.addHeading(`solution`, `Let $y=f(x)$`);
      question.addParagraph(`solution`, MCMaths.cleaner(`$y=${a}e^{x}+${c}$`));
      question.addHeading(`solution`, `Swap $y$ and $x$`);
      question.addParagraph(`solution`, MCMaths.cleaner(`$x=${a}e^{y}+${c}$`));
      question.addHeading(`solution`, `Make $y$ the subject`);
      question.addParagraph(`solution`, MCMaths.cleaner(`$x+${-c}=${a}e^{y}$`));
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$e^{y}=\\frac{x+${-c}}{${a}}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$y=\\ln(\\frac{x+${-c}}{${a}})$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$y=\\ln(x+${-c})-\\ln(${a})$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore f^{-1}(x)=\\ln(x+${-c})-\\ln(${a})$`)
      );
      // c
      question.addHeading(`solution`, `c) Evaluate $fg(${x})$`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$fg(x)=${a}e^{\\ln(${b}x+${d})}+${c}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=${a}(${b}x+${d})+${c}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=${a * b}x+${a * d + c}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$fg(${x})=${a * b}(${x})+${a * d + c}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=${a * b * x + a * d + c}$`)
      );
      //
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(c, 0, "a) $\\ f(x)>$");
      question.addInputGroup(group1);

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(-c, 0, "b) $\\ A=$");
      group2.addInput(a, 0, "b) $\\ B=$");
      question.addInputGroup(group2);

      const group3 = new MCQuestion.InputGroup(1);
      group3.addInput(a * b * x + a * d + c, 0, "c)");
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_CompositeAndInverseFunctions_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const r1 = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const r2 = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const poly = MCMaths.Polynomial.generateFromRoots([r1, r2], "x");

    const x = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);

    let roots_t = ["No Real Roots", "No Real Roots"];
    let top_t = 0;
    let solve_t = 0;
    let poly2_t = 0;
    while (roots_t[0] === "No Real Roots") {
      top_t = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
      solve_t = MCRandom.randomInt(1, 4);
      poly2_t = poly
        .multiply(solve_t)
        .add(new MCMaths.Polynomial([-top_t], "x"));
      roots_t = poly2_t.roots();
    }
    const top = top_t;
    const solve = solve_t;
    const poly2 = poly2_t;
    const roots = roots_t;

    const b_ans = poly.evaluate(top / x);

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(r1 - r2) > 2 &&
      parseFloat(b_ans.toFixed(3)) === b_ans &&
      parseFloat(roots[0].toFixed(3)) === roots[0] &&
      parseFloat(roots[1].toFixed(3)) === roots[1] &&
      roots[0] !== roots[1]
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addGraph("question", poly.graph());
      question.addParagraph(
        `question`,
        `The figure above shows the curve of $y=f(x)$ where`
      );
      question.addParagraph(
        `question`,
        `$f(x)=${poly.toString()}$ for all real values of $x$`
      );
      question.addParagraph(
        `question`,
        `Another function $g(x)$ is defined by`
      );
      question.addParagraph(`question`, `$g(x)=\\frac{${top}}{x},x\\neq 0$`);
      question.addParagraph(
        `question`,
        `a) Explain why $f(x)$ does not have an inverse`
      );
      question.addParagraph(`question`, `b) Evaluate $fg(${x})$`);
      question.addParagraph(`question`, `c) Solve $gf(x)=${solve}$`);
      // solution
      // a
      question.addHeading(
        `solution`,
        `a) Explain why $f(x)$ does not have an inverse`
      );
      question.addParagraph(
        `solution`,
        `$f(x)$ is a many-to-one function so it does not have an inverse`
      );
      //
      question.addHeading(`solution`, `b) Evaluate $fg(${x})$`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$fg(x)=${poly.toString().replace(/x/g, `(\\frac{${top}}{x})`)}$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$fg(${x})=${poly
            .toString()
            .replace(/x/g, `(\\frac{${top}}{${x}})`)}$`
        )
      );
      question.addParagraph(`solution`, MCMaths.cleaner(`$=${b_ans}$`));
      // c
      question.addHeading(`solution`, `c) Solve $gf(x)=${solve}$`);
      question.addParagraph(
        `solution`,
        `$gf(x)=\\frac{${top}}{${poly.toString()}}$`
      );
      question.addParagraph(
        `solution`,
        `$\\frac{${top}}{${poly.toString()}}=${solve}$`
      );
      question.addParagraph(
        `solution`,
        `$${poly
          .multiply(new MCMaths.Polynomial([solve], "x"))
          .toString()}=${top}$`
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${poly2.toString()}=0$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore x=${roots[1]},x=${roots[0]}$`)
      );
      //
      question.requiresSelfMarking();

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(b_ans, 0, "b) ");
      question.addInputGroup(group2);

      const group3 = new MCQuestion.InputGroup(2);
      group3.addInput(roots, 0, "c) $\\ x=$");
      question.addInputGroup(group3);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_CompositeAndInverseFunctions_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(2, 12) * -1;

    const m = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(2, 12) * MCRandom.randomInt(-1, 1, 2);

    const x1 = MCRandom.randomInt(-8, 8);
    const x2 = MCRandom.randomInt(-8, 8);

    const solve = MCRandom.randomInt(-20, 20);
    const coeff = MCRandom.randomInt(2, 4) * MCRandom.randomInt(-1, 1, 2);

    const a_ans = a * Math.abs(m * x1 + c + b);
    const b_ans = m * a * Math.abs(x2 + b) + c;
    const c_ans = (solve - (m * c + c + coeff * c)) / (m * m + coeff * m);

    /* END VARIABLES AND CONSTANTS */
    if (
      x1 !== x2 &&
      m * c + c + coeff * c !== 0 &&
      m * m + coeff * m !== 0 &&
      Math.abs(a_ans) < 200 &&
      Math.abs(b_ans) < 200 &&
      Math.abs(c_ans) < 200
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `The functions $f(x)$ and $g(x)$ are defined by`
      );
      question.addParagraph(`question`, MCMaths.cleaner(`$f(x)=${a}|x+${b}|$`));
      question.addParagraph(`question`, MCMaths.cleaner(`$g(x)=${m}x+${c}$`));
      question.addParagraph(`question`, `a) Evaluate $fg(${x1})$`);
      question.addParagraph(`question`, `b) Evaluate $gf(${x2})$`);
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`c) Solve the equation $gg(x)+${coeff}g(x)=${solve}$`)
      );
      // solution
      // a
      question.addHeading(`solution`, `a) Evaluate $fg(${x1})$`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$fg(x)=${a}|(${m}x+${c})+${b}|$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=${a}|${m}x+${c + b}|$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$fg(${x1})=${a}|(${m}(${x1})+${c + b}|$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=${a}|${m * x1 + c + b}|$`)
      );
      question.addParagraph(`solution`, MCMaths.cleaner(`$=${a_ans}$`));
      // b
      question.addHeading(`solution`, `b) Evaluate $gf(${x2})$`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$gf(x)=${m}(${a}|x+${b}|)+${c}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=${m * a}|x+${b}|+${c}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$gf(${x2})=${m * a}|${x2}+${b}|+${c}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=${m * a}|${x2 + b}|+${c}$`)
      );
      question.addParagraph(`solution`, MCMaths.cleaner(`$=${b_ans}$`));
      // c
      question.addHeading(
        `solution`,
        MCMaths.cleaner(`c) Solve the equation $gg(x)+${coeff}g(x)=${solve}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$gg(x)=${m}(${m}x+${c})+${c}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=${m * m}x+${m * c + c}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${coeff}g(x)=${coeff}(${m}x+${c})$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=${coeff * m}x+${coeff * c}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$gg(x)+${coeff}g(x)=${m * m}x+${m * c + c}+${coeff * m}x+${
            coeff * c
          }$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=${m * m + coeff * m}x+${m * c + c + coeff * c}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$${m * m + coeff * m}x+${m * c + c + coeff * c}=${solve}$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore x=${c_ans}$`)
      );
      //
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(a_ans, 0, "a) ");
      question.addInputGroup(group2);
      //
      const group3 = new MCQuestion.InputGroup(1);
      group3.addInput(b_ans, 0, "b) ");
      question.addInputGroup(group3);
      //
      const group4 = new MCQuestion.InputGroup(1);
      group4.addInput(c_ans, 0, "c) ");
      question.addInputGroup(group4);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_CompositeAndInverseFunctions_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x_intercept = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const sign = MCRandom.randomInt(-1, 1, 2);

    const m1 = (sign * MCRandom.randomInt(1, 8)) / 2;
    const m2 = (sign * MCRandom.randomInt(1, 8)) / 2;
    const c1 = -m1 * x_intercept;
    const c2 = -m2 * x_intercept;

    const a_x = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const b_solve = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const tc = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const tx = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const bc = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const bx = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);

    function f1(x) {
      return m1 * (x - x_intercept);
    }
    function f2(x) {
      return m2 * (x - x_intercept);
    }

    let a2t = 0;
    if (a_x > x_intercept) {
      a2t = m2 * a_x + c2;
    } else {
      a2t = m1 * a_x + c1;
    }
    const a2 = a2t;

    const x_toInverse = (b_solve * bc - tc) / (tx - b_solve * bx);

    /* END VARIABLES AND CONSTANTS */
    if (
      m1 !== m2 &&
      Math.abs(f1(-10)) < 10 &&
      Math.abs(f2(10)) < 10 &&
      a_x !== x_intercept &&
      a2 !== x_intercept &&
      b_solve !== x_intercept &&
      a_x !== a2 &&
      x_toInverse < f2(10) &&
      x_toInverse > f1(-10) &&
      x_toInverse % 0.5 === 0 &&
      x_toInverse !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g.plot(`${m1}*(x-${x_intercept})`, -10, x_intercept);
      g.plot(`${m2}*(x-${x_intercept})`, x_intercept, 10);
      question.addGraph("question", g);
      // question
      question.addParagraph(
        "question",
        MCMaths.cleaner(`The function $f(x)$ has domain $-10 \\leq x \\leq 10$ and is linear from $(-10,${f1(
          -10
        )})$
         to $(${x_intercept}, 0)$ and from $(${x_intercept}, 0)$ to $(10,${f2(
          10
        )}).$ The diagram above shows the graph of $f(x).$`)
      );

      question.addParagraph(`question`, `a) Find $ff(${a_x})$`);

      question.addParagraph(
        "question",
        `Another function, $g(x)$, is defined by`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$g(x)=\\frac{${tx}x+${tc}}{${bx}x+${bc}}$`)
      );

      question.addParagraph(`question`, `b) Solve $gf(x)=${b_solve}$`);
      // solution
      // setup
      question.addHeading(`solution`, `Equation for first linear section`);
      question.addParagraph(
        `solution`,
        `gradient$ = \\frac{\\Delta y}{\\Delta x}$`
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=\\frac{0-${f1(-10)}}{${x_intercept}+10}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=${-f1(-10) / (x_intercept + 10)}$`)
      );
      question.addHeading(`solution`, `Using point $(${x_intercept}, 0)$`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$y-0=${m1}(x-${x_intercept})$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore y=${m1}x+${c1}$`)
      );

      question.addHeading(`solution`, `Equation for second linear section`);
      question.addParagraph(
        `solution`,
        `gradient$ = \\frac{\\Delta y}{\\Delta x}$`
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=\\frac{0-${f2(10)}}{${x_intercept}-10}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=${-f2(10) / (x_intercept - 10)}$`)
      );
      question.addHeading(`solution`, `Using point $(${x_intercept}, 0)$`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$y-0=${m2}(x-${x_intercept})$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore y=${m2}x+${c2}$`)
      );

      // a
      question.addHeading(`solution`, `a) Find $ff(${a_x})$`);
      question.addHeading(`solution`, `First find $f(${a_x})$`);
      if (a_x > x_intercept) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `The point at $x=${a_x}$ lies on second linear section`
          )
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$\\therefore f(x)=${m2}x+${c2}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$f(${a_x})=${m2}(${a_x})+${c2}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$=${m2 * a_x + c2}$`)
        );
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `The point at $x=${a_x}$ lies on first linear section`
          )
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$\\therefore f(x)=${m1}x+${c1}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$f(${a_x})=${m1}(${a_x})+${c1}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$=${m1 * a_x + c1}$`)
        );
      }
      question.addHeading(`solution`, MCMaths.cleaner(`Then find $f(${a2})$`));
      if (a2 > x_intercept) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `The point at $x=${a2}$ lies on second linear section`
          )
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$\\therefore f(x)=${m2}x+${c2}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$f(${a2})=${m2}(${a2})+${c2}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$=${m2 * a2 + c2}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$\\therefore ff(${a_x})=${m2 * a2 + c2}$`)
        );
        const groupa1 = new MCQuestion.InputGroup(1);
        groupa1.addInput(m2 * a2 + c2, 0, "a) ");
        question.addInputGroup(groupa1);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`The point at $x=${a2}$ lies on first linear section`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$\\therefore f(x)=${m1}x+${c1}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$f(${a2})=${m1}(${a2})+${c1}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$=${m1 * a2 + c1}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$\\therefore ff(${a_x})=${m1 * a2 + c1}$`)
        );
        const groupa1 = new MCQuestion.InputGroup(1);
        groupa1.addInput(m1 * a2 + c1, 0, "a) ");
        question.addInputGroup(groupa1);
      }
      // b
      question.addHeading(`solution`, `b) Solve $gf(x)=${b_solve}$`);
      question.addHeading("solution", `First solve $g(x)=${b_solve}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{${tx}x+${tc}}{${bx}x+${bc}}=${b_solve}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${tx}x+${tc}=${b_solve * bx}x+${b_solve * bc}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$x=${x_toInverse}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore f(x)=${x_toInverse}$`)
      );
      if (
        (x_toInverse < 0 && x_toInverse > f1(-10)) ||
        (x_toInverse > 0 && x_toInverse < f1(-10))
      ) {
        question.addHeading(
          "solution",
          MCMaths.cleaner(
            `The point at $y=${x_toInverse}$ lies on first linear section`
          )
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$\\therefore f(x)=${m1}x+${c1}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$${m1}x+${c1}=${x_toInverse}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$\\therefore x=${x_toInverse / m1 - c1 / m1}$`)
        );
        const groupb1 = new MCQuestion.InputGroup(1);
        groupb1.addInput(x_toInverse / m1 - c1 / m1, 0, "b) $\\ x=$");
        question.addInputGroup(groupb1);
      } else {
        question.addHeading(
          "solution",
          MCMaths.cleaner(
            `The point at $y=${x_toInverse}$ lies on second linear section`
          )
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$\\therefore f(x)=${m2}x+${c2}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$${m2}x+${c2}=${x_toInverse}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$\\therefore x=${x_toInverse / m2 - c2 / m2}$`)
        );
        const groupb1 = new MCQuestion.InputGroup(1);
        groupb1.addInput(x_toInverse / m2 - c2 / m2, 0, "b) $\\ x=$");
        question.addInputGroup(groupb1);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_CompositeAndInverseFunctions_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const c = MCRandom.randomInt(-8, -2);
    const a = MCRandom.randomInt(1, -2 * c - 1) / 2;

    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      //
      const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g.plot(`${a}*Math.E**x+${c}`, -10, 10);
      question.addGraph("question", g);
      //
      question.addParagraph(
        "question",
        `A curve $y=f(x)$ is shown above where`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$f(x)=${a}e^x+${c}$ `)
      );
      question.addParagraph(
        "question",
        `a) Find the exact values where $f(x)$ crosses the coordinate axis, giving your answers in the form $y=A$ and $x=\\ln B$`
      );
      question.addParagraph(
        "question",
        `b) State how the graphs of $y=f(x)$ and $y=f^{-1}(x)$ are related geometrically`
      );
      question.addParagraph(
        "question",
        `c) Hence, sketch the graph of $y=f^{-1}(x)$, stating the exact values where it crosses the coordinate axis`
      );

      // a
      question.addHeading(
        "solution",
        `a) Find the exact values where $f(x)$ crosses the coordinate axis, giving your answers in the form $y=A$ and $x=\\ln B$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f(x)=${a}e^x+${c}$ `)
      );
      question.addHeading("solution", MCMaths.cleaner(`Let $x=0$ `));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f(0)=${a}e^x+${c}$ `)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=${a + c}$ `)
      );
      question.addHeading("solution", MCMaths.cleaner(`Let $f(x)=0$ `));
      question.addParagraph("solution", MCMaths.cleaner(`$${a}e^x+${c}=0$ `));
      question.addParagraph("solution", MCMaths.cleaner(`$e^x=${-c / a}$ `));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x=\\ln(${-c / a})$ `)
      );
      // b
      question.addHeading(
        "solution",
        `b) State how the graphs of $y=f(x)$ and $y=f^{-1}(x)$ are related geometrically`
      );
      question.addParagraph(
        "solution",
        `$f^{-1}(x)$ is a reflection of $f(x)$ in the line $y=x$`
      );
      // c
      question.addHeading(
        "solution",
        `c) Hence, sketch the graph of $y=f^{-1}(x)$, stating where it crosses the coordinate axis`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `Crosses coordinate axis at $(0, \\ln${-c / a})$ and $(${a + c}, 0)$`
        )
      );
      const g2 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g2.addParametric(`${a}*Math.E**t+${c}`, `t `, -10, 10);
      question.addGraph("solution", g2);
      //
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(a + c, 0, "a) $\\ A=$");
      group1.addInput(-c / a, 0, "a) $\\ B=$");
      question.addInputGroup(group1);
      //
      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_CompositeAndInverseFunctions_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const fa = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const fc = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    const ga = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const gc = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);

    /* END VARIABLES AND CONSTANTS */
    if (
      fc !== -fa * gc &&
      gc !== -ga * fc &&
      fc !== -gc &&
      ga !== -fa &&
      gc * fa !== -ga * fc &&
      fa * gc + fc !== ga * fc + gc &&
      ga + fa !== ga * fa
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(`The functions $f(x)$ and $g(x)$ are defined by`);
      question.addParagraph(MCMaths.cleaner(`$f(x)=${fa}x+${fc}$`));
      question.addParagraph(MCMaths.cleaner(`$g(x)=${ga}x+${gc}$`));
      question.addParagraph(`Select the expression for $fg(x)$`);
      //
      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(MCMaths.cleaner(`$fg(x)=${fa * ga}x+${fa * gc + fc}$`)); // fg(x)

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(MCMaths.cleaner(`$fg(x)=${ga * fa}x+${ga * fc + gc}$`)); // gf(x)

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(MCMaths.cleaner(`$fg(x)=${ga + fa}x+${fc + gc}$`)); // f(x)+g(x)

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(
        MCMaths.cleaner(
          `$fg(x)=${ga * fa}x^2+${gc * fa + ga * fc}x+${fc * gc}$`
        )
      ); // f(x)g(x)
      //
      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_CompositeAndInverseFunctions_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Which of the following functions are self-inversive for all values of $x$`
      );
      question.addHeading(`Choose TWO`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(
        MCMaths.cleaner(`$f(x)=\\frac{${MCRandom.randomInt(1, 8)}}{x}$`)
      );

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(MCMaths.cleaner(`$f(x)=${MCRandom.randomInt(1, 12)}-x$`));

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(
        MCMaths.cleaner(`$f(x)=\\sqrt{x^2+${MCRandom.randomInt(1, 8)}}$`)
      );

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(MCMaths.cleaner(`$f(x)=\\frac{1}{x^2}$`));

      question.addAnswer(a1, true);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_CompositeAndInverseFunctions_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Select the function below for which there is an inverse function`
      );
      //
      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(
        MCMaths.cleaner(
          [
            `$f(x)=${MCRandom.randomInt(1, 5)}x^2, x \\geq 0$`,
            `$f(x)=\\sqrt{${MCRandom.randomInt(1, 5)}x}, x \\geq 0$`,
            `$f(x)=${MCRandom.randomInt(1, 4)}x^3$`,
          ][MCRandom.randomInt(0, 2)]
        )
      );

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(
        MCMaths.cleaner(
          [
            `$f(x)=${MCRandom.randomInt(1, 5)}x^2$`,
            `$f(x)=\\sqrt{${MCRandom.randomInt(1, 5)}x}$`,
          ][MCRandom.randomInt(0, 1)]
        )
      );

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(
        MCMaths.cleaner(
          [
            `$f(x)=\\frac{${MCRandom.randomInt(1, 5)}}{x^2},x\\neq 0$`,
            `$f(x)=${MCRandom.randomInt(1, 5)}x^{\\frac{-3}{2}},x\\neq 0$`,
          ][MCRandom.randomInt(0, 1)]
        )
      );

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(MCMaths.cleaner(`$f(x)=${MCRandom.randomInt(2, 5)}|x|$`));
      //
      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ModulusFunction_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a1 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const b1 = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);

    const a2 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const b2 = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);

    const p1 = new MCMaths.Polynomial([a1, b1], "x");
    const p2 = new MCMaths.Polynomial([a2, b2], "x");

    const m1 = new MCMaths.Modulus(p1);
    const m2 = new MCMaths.Modulus(p2);

    const x1 = (b2 - b1) / (a1 - a2);
    const x2 = (-b2 - b1) / (a1 + a2);

    const y1 = Math.abs(p1.evaluate(x1));
    const y2 = Math.abs(p1.evaluate(x2));

    const symbol = ["<", ">"][MCRandom.randomInt(0, 1)];
    /* END VARIABLES AND CONSTANTS */
    if (
      a1 !== a2 &&
      Math.abs(a1 - a2) !== 1 &&
      a1 !== -a2 &&
      Math.abs(a1 + a2) !== 1 &&
      x2 !== x1 &&
      Math.abs(x1) < 9 &&
      Math.abs(x2) < 9 &&
      Math.abs(y1) < 9 &&
      Math.abs(y2) < 9 &&
      Math.abs(x1 - x2) > 1 &&
      Math.abs(a1) > Math.abs(a2)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(`a) Solve $${m1.toString()}=${m2.toString()}$`)
      );
      question.addParagraph(
        "question",
        `b) Sketch, on the same axis, the graphs of:`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`i) $y=${m1.toString()}$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`ii) $y=${m2.toString()}$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `c) Hence, solve $${m1.toString()}${symbol}${m2.toString()}$`
        )
      );
      // solution
      // a
      question.addHeading(
        "solution",
        MCMaths.cleaner(`a) Solve $${m1.toString()}=${m2.toString()}$`)
      );
      question.addHeading("solution", `First solve`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${p1.toString()}=${p2.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${a1 - a2}x=${b2 - b1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${x1}$`)
      );
      question.addHeading("solution", `Then solve`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${p1.toString()}=-(${p2.toString()})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${a1 + a2}x=${-b2 - b1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${x2}$`)
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput([x1, x2], 0, "a) $\\ x=$");
      question.addInputGroup(group1);
      // b
      question.addHeading(
        "solution",
        `b) Sketch, on the same axis, the graphs of:`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`i) $y=${m1.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`ii) $y=${m2.toString()}$`)
      );
      const g1 = new MCQuestion.Graph(10, -10, 10, -2, 2, 2);
      const f1 = `Math.abs(${p1.functionString()})`; // blue
      const f2 = `Math.abs(${p2.functionString()})`; // red
      g1.plot(f1, -10, 10);
      g1.plot(f2, -10, 10);
      question.addGraph("solution", g1);
      // c
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `c) Hence, solve $${m1.toString()}${symbol}${m2.toString()}$`
        )
      );
      question.addHeading("solution", `Using the graph from part b`);
      if (symbol === ">") {
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$x<${Math.min(x1, x2)}$ or $x>${Math.max(x1, x2)}$`)
        );
        const group2 = new MCQuestion.InputGroup(2);
        group2.addInput(Math.min(x1, x2), 0, "b) $\\ x<$");
        group2.addInput(Math.max(x1, x2), 0, "b) $\\ x>$");
        question.addInputGroup(group2);
      } else {
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$${Math.min(x1, x2)}<x<${Math.max(x1, x2)}$`)
        );
        const group2 = new MCQuestion.InputGroup(2);
        group2.addInput(Math.min(x1, x2), 0, "b) $\\ x>$");
        group2.addInput(Math.max(x1, x2), 0, "b) $\\ x<$");
        question.addInputGroup(group2);
      }
      //
      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ModulusFunction_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);

    const fx = `${a}*Math.abs(x+${b})`;

    const x = -b;
    const y = a * Math.abs(b);
    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(y) < 5) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$f(x)=a|x+b|$ where $a$ and $b$ are constants where $a \\neq 0$ and $b \\neq 0$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `The graph of $y=f(x)$ meets the coordinate axis at $(0,${y})$ and $(${x}, 0)$`
        )
      );
      question.addParagraph("question", `a) Find $a$ and $b$`);
      question.addParagraph("question", `b) Sketch the graph of $y=f(x)$`);
      // solution
      // a
      question.addHeading("solution", `a) Find $a$ and $b$`);
      question.addHeading("solution", `Let $x=${x}$`);
      question.addParagraph("solution", MCMaths.cleaner(`$f(${x})=a|${x}+b|$`));
      question.addParagraph("solution", MCMaths.cleaner(`$a|${x}+b|=0$`));
      question.addParagraph("solution", MCMaths.cleaner(`$|${x}+b|=0$`));
      question.addParagraph("solution", MCMaths.cleaner(`$${x}+b=0$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore b=${-x}$`)
      );
      question.addHeading("solution", `Let $x=0$`);
      question.addParagraph("solution", MCMaths.cleaner(`$f(0)=a|${-x}|$`));
      question.addParagraph("solution", MCMaths.cleaner(`$a|${-x}|=${y}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore a=${y / Math.abs(x)}$`)
      );
      // b
      question.addHeading("solution", `b) Sketch the graph of $y=f(x)$`);
      const graph1 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      graph1.plot(fx, -10, 10);
      question.addGraph("solution", graph1);
      //
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(a, 0, "a) $\\ a=$");
      group1.addInput(b, 0, "a) $\\ b=$");
      question.addInputGroup(group1);

      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ModulusFunction_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const oa = MCRandom.randomInt(2, 4) * MCRandom.randomInt(-1, 1, 2);
    const oc = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);

    const a = MCRandom.randomInt(1, 3) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);

    function fx(x) {
      return oa * Math.abs(a * x + c) + oc;
    }
    function cx(x) {
      return Math.abs(a * x + c);
    }

    const fxd = `${oa} * Math.abs(${a}*x+${c})+${oc}`;
    const cxd = `Math.abs(${a}*x+${c})`;

    /* END VARIABLES AND CONSTANTS */
    if (
      ((oc < 0 && oa > 0) || (oc > 0 && oa < 0)) &&
      Math.abs(fx(0)) < 8 &&
      Math.abs(-c / a) > 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`$f(x)=${oa}|${a}x+${c}|+${oc}$`)
      );
      if (oa > 0) {
        question.addParagraph(
          `question`,
          `a) Find the smallest possible value of $f(x)$ and the value of $x$ for which it occurs`
        );
      } else {
        question.addParagraph(
          `question`,
          `a) Find the largest possible value of $f(x)$ and the value of $x$ for which it occurs`
        );
      }
      question.addParagraph("question", `b) Evaluate $f(0)$`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(`c) Sketch the graph of $y=|${a}x+${c}|$`)
      );
      question.addParagraph(
        "question",
        `d) Hence, sketch the graph of $y=f(x)$`
      );
      // solution
      // a
      if (oa > 0) {
        question.addHeading(
          `solution`,
          `a) Find the smallest possible value of $f(x)$ and the value of $x$ for which it occurs`
        );
        question.addHeading(
          `solution`,
          MCMaths.cleaner(`Smallest possible value when $|${a}x+${c}|=0$`)
        );
      } else {
        question.addHeading(
          `solution`,
          `a) Find the largest possible value of $f(x)$ and the value of $x$ for which it occurs`
        );
        question.addHeading(
          `solution`,
          MCMaths.cleaner(`Largest possible value when $|${a}x+${c}|=0$`)
        );
      }
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore x=${-c / a}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f(${-c / a})=${oa}|${a}(${-c / a})+${c}|+${oc}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${fx(-c / a)}$`));
      if (oa > 0) {
        question.addHeading(
          `solution`,
          MCMaths.cleaner(
            `$\\therefore$ Minimum value of $f(x)$ is $${oc}$, when $x=${
              -c / a
            }$`
          )
        );
      } else {
        question.addHeading(
          `solution`,
          MCMaths.cleaner(
            `$\\therefore$ Maximum value of $f(x)$ is $${oc}$, when $x=${
              -c / a
            }$`
          )
        );
      }
      // b
      question.addHeading("solution", `b) Evaluate $f(0)$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f(0)=${oa}|${a}(0)+${c}|+${oc}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${fx(0)}$`));
      // c
      question.addHeading(
        "solution",
        MCMaths.cleaner(`c) Sketch the graph of $y=|${a}x+${c}|$`)
      );
      const g1 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g1.plot(cxd, 10, -10);
      question.addGraph("solution", g1);
      // d
      question.addHeading("solution", `d) Hence, sketch the graph of $y=f(x)$`);
      const g2 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g2.plot(fxd, 10, -10);
      question.addGraph("solution", g2);
      //
      const group = new MCQuestion.InputGroup(2);
      group.addInput(oc, 0, "a) $\\ f(x)=$");
      group.addInput(-c / a, 0, "a) $\\ x=$");
      question.addInputGroup(group);
      //
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(fx(0), 0, "b)");
      question.addInputGroup(group2);
      //
      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ModulusFunction_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const m = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const y = MCRandom.randomInt(3, 8);

    function fx(x) {
      return Math.abs(a * x + b);
    }
    function gx(x) {
      return m * x + c;
    }

    const fxd = `Math.abs(${a}*x+${b})`;
    const gxd = `${m}*x+${c}`;

    const sign = ["<", ">"][MCRandom.randomInt(0, 1)];

    const x1 = (y - b) / a;
    const x2 = (y + b) / -a;
    const x3 = (c - b) / (a - m);
    const x4 = (c + b) / (-a - m);
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(m) < a &&
      y - b !== 0 &&
      y + b !== 0 &&
      x1 % 1 === 0 &&
      x2 % 1 === 0 &&
      x3 % 1 === 0 &&
      x4 % 1 === 0 &&
      x3 !== x4 &&
      gx(x3) > 0 &&
      gx(x4) > 0 &&
      gx(x3) < 9 &&
      gx(x4) < 9
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `a) Sketch on the same axis the graphs of:`
      );
      question.addParagraph(`question`, MCMaths.cleaner(`i) $y=${m}x+${c}$`));
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`ii) $y=|${a}x+${b}|$`)
      );
      question.addParagraph(
        `question`,
        `b)Using your sketch from part a, solve`
      );
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`i) $|${a}x+${b}|${sign}${y}$`)
      );
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`ii) $|${a}x+${b}|=${m}x+${c}$`)
      );
      // solution
      // a
      question.addHeading(
        `solution`,
        `a) Sketch on the same axis the graphs of:`
      );
      question.addParagraph(`solution`, MCMaths.cleaner(`i) $y=${m}x+${c}$`));
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`ii) $y=|${a}x+${b}|$`)
      );
      const g1 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g1.plot(fxd, 10, -10);
      g1.plot(gxd, 10, -10);
      question.addGraph("solution", g1);
      // b
      question.addHeading(`solution`, `b)Using your sketch from part a, solve`);
      question.addHeading(
        `solution`,
        MCMaths.cleaner(`i) $|${a}x+${b}|${sign}${y}$`)
      );
      question.addParagraph(
        `solution`,
        `Plot line $y=${y}$ and find where it intercepts the plot of $y=|${a}x+${b}|$`
      );
      question.addHeading(
        `solution`,
        MCMaths.cleaner(`Intercepts at $x=${x1}$ and $x=${x2}$`)
      );
      if (sign === "<") {
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(
            `$\\therefore ${Math.min(x1, x2)}<x<${Math.max(x1, x2)}$`
          )
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput(Math.min(x1, x2), 0.25, "bi) $\\ x>$");
        group1.addInput(Math.max(x1, x2), 0.25, "bi) $\\ x<$");
        question.addInputGroup(group1);
      } else {
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(
            `$\\therefore x<${Math.min(x1, x2)}$ or $x>${Math.max(x1, x2)}$`
          )
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput(Math.min(x1, x2), 0.25, "bi) $\\ x<$");
        group1.addInput(Math.max(x1, x2), 0.25, "bi) $\\ x>$");
        question.addInputGroup(group1);
      }

      const g2 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g2.plot(fxd, 10, -10);
      g2.plot(gxd, 10, -10);

      g2.plot(`${y} `, -10, 10);
      question.addGraph("solution", g2);
      // c
      question.addHeading(
        `solution`,
        MCMaths.cleaner(`ii) $|${a}x+${b}|=${m}x+${c}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `Find the intercepts of your plots of $y=${m}x+${c}$ and $y=|${a}x+${b}|$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore x=${x3},x=${x4}$`)
      );

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput([x3, x4], 0.25, "bii) $\\ x=$");
      question.addInputGroup(group2);
      //
      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ModulusFunction_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const r1 = MCRandom.randomInt(1, 10);
    const r2 = MCRandom.randomInt(1, 10);

    const poly = MCMaths.Polynomial.generateFromRoots([r1, r2], "x");
    const xmp = r1 / 2 + r2 / 2;
    const ymp = poly.evaluate(xmp);

    function fx(x) {
      return poly.evaluate(Math.abs(x));
    }
    function gx(x) {
      return poly.evaluate(x);
    }

    const fxd = poly.functionString().replace(/x/g, `Math.abs(x)`);
    const gxd = poly.functionString();
    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(r1 - r2) > 2 && poly.evaluate(0) < 10 && ymp < 15) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const g1 = new MCQuestion.Graph(12, -12, 16, -16, 2, 2);
      g1.plot(gxd, -12, 12);
      question.addGraph("solution", g1);
      // question
      question.addParagraph(
        "question",
        `The figure above shows a curve with equation $y=f(x)$ where`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$f(x)=(x-${r1})(x-${r2})$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `The curve has a minimum point $P$ at $(${xmp},${ymp})$`
        )
      );
      question.addParagraph(
        "question",
        `a) Find the coordinates to which $P$ is transformed on the curve of $y=|f(x)|$`
      );
      question.addParagraph(
        "question",
        `b) Sketch the curve of $y=f(|x|)$, stating the coordinates of any turning points`
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) Find the coordinates to which $P$ is transformed on the curve of $y=|f(x)|$`
      );
      question.addParagraph("solution", MCMaths.cleaner(`$P(${xmp},${ymp})$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore f(${xmp})=${ymp}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$|f(${xmp})|=|${ymp}|$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore |f(${xmp})|=${-ymp}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `$\\therefore P$ transformed onto point $(${xmp},${-ymp})$`
        )
      );
      // b
      question.addHeading(
        "solution",
        `b) Sketch the curve of $y=f(|x|)$, stating the coordinates of any turning points`
      );

      const g2 = new MCQuestion.Graph(12, -12, 16, -16, 2, 2);
      g2.plot(fxd, -12, 12);
      question.addGraph("solution", g2);

      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Turning points at $(${xmp},${ymp})$ and $(${-xmp},${ymp})$`
        )
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(xmp, 0, "a) $\\ x=$");
      group1.addInput(-ymp, 0, "a) $\\ y=$");
      question.addInputGroup(group1);

      /* const group2 = new MCQuestion.InputGroup(2);
      group2.addInput([xmp,-xmp], 0, "b) $\\ x=$");    
      group2.addInput(ymp, 0, "b) $\\ y=$");    
      group2.addInput(ymp, 0, "b) $\\ y=$");    
      question.addInputGroup(group2); */

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ModulusFunction_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const index = MCRandom.randomInt(0, 1);
    const type = ["cosec", "sec"][index];
    const type2 = ["\\sin", "\\cos"][index];

    const a = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);

    const exact = [1, 2][MCRandom.randomInt(0, 1)]; // exact values compatible with sec and cosec

    const trig = new MCMaths.Trig(a, type);
    let fx;
    if (type === "cosec") {
      fx = `${a}/Math.sin(x)`;
    } else {
      fx = `${a}/Math.cos(x)`;
    }

    let gx;
    if (type === "cosec") {
      gx = `Math.abs(${a}/Math.sin(x))`;
    } else {
      gx = `Math.abs(${a}/Math.cos(x))`;
    }

    let a_solutions = [];
    let b_solutions = [];
    let ta_ps = [];
    let tb_ps = [];
    if (type2 === "\\sin" && 1 / exact === 1) {
      a_solutions = [Math.PI / 2];
      b_solutions = [(3 * Math.PI) / 2];
      ta_ps = [1 / 2];
      tb_ps = [3 / 2];
    } else if (type2 === "\\sin" && 1 / exact === 1 / 2) {
      a_solutions = [Math.PI / 6, (5 * Math.PI) / 6];
      b_solutions = [(7 * Math.PI) / 6, (11 * Math.PI) / 6];
      ta_ps = [1 / 6, 5 / 6];
      tb_ps = [7 / 6, 11 / 6];
    } else if (type2 === "\\cos" && 1 / exact === 1) {
      a_solutions = [0, 2 * Math.PI];
      b_solutions = [Math.PI];
      ta_ps = [0, 2];
      tb_ps = [1];
    } else {
      a_solutions = [Math.PI / 3];
      b_solutions = [(2 * Math.PI) / 3, (4 * Math.PI) / 3];
      ta_ps = [1 / 3];
      tb_ps = [2 / 3, 4 / 3];
    }

    const a_ans = a_solutions;
    const a_ps = ta_ps;
    const b_ps = tb_ps;
    const b_ans = b_solutions;

    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const g1 = new MCQuestion.Graph(
        2 * Math.PI,
        0,
        Math.abs(a) + 2,
        -Math.abs(a) - 2,
        Math.PI / 2,
        1
      );
      g1.plot(fx, 0, 2 * Math.PI);
      question.addGraph("question", g1);
      //
      question.addParagraph(
        `question`,
        MCMaths.cleaner(
          `The figure above shows the graph of $y=${trig.toString()}, 0\\leq x \\leq 2\\pi$`
        )
      );
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`a) Solve $${trig.toString()}=${a * exact}$`)
      );
      question.addParagraph(
        `question`,
        `b) Sketch the graph of $y=|${trig.toString()}|$`
      );
      question.addParagraph(
        `question`,
        MCMaths.cleaner(
          `c) Solve $|${trig.toString()}|=${Math.abs(a * exact)}$`
        )
      );
      question.addParagraph(
        `question`,
        `Give all of your answers in the interval $0\\leq x \\leq 2\\pi$ and in the form $p\\pi$ where $p$ is a constant`
      );
      // solution
      // a
      question.addHeading(
        `solution`,
        MCMaths.cleaner(`a) Solve $${trig.toString()}=${a * exact}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\frac{${a}}{${type2}(x)}=${a * exact}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${type2}(x)=${1 / exact}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore x=${a_ans}$`)
      );
      // b
      question.addHeading(
        `solution`,
        `b) Sketch the graph of $y=|${trig.toString()}|$`
      );
      const g2 = new MCQuestion.Graph(
        2 * Math.PI,
        0,
        Math.abs(a) + 2,
        -Math.abs(a) - 2,
        Math.PI / 2,
        1
      );
      g2.plot(gx, 0, 2 * Math.PI);
      question.addGraph("solution", g2);
      // c
      question.addHeading(
        `solution`,
        MCMaths.cleaner(
          `c) Solve $|${trig.toString()}|=${Math.abs(a * exact)}$`
        )
      );
      question.addHeading("solution", `First solve`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${a}\\${type}(x)=${a * exact}$`)
      );
      question.addHeading("solution", `Using solutions from part a`);
      question.addParagraph(`solution`, MCMaths.cleaner(`$x=${a_ans}$`));

      question.addHeading("solution", `Then solve`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${-a}\\${type}(x)=${a * exact}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\frac{${-a}}{${type2}(x)}=${a * exact}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${type2}(x)=${-1 / exact}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore x=${b_ans}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore x=${a_ans.concat(b_ans).sort()}$`)
      );
      //
      const group1 = new MCQuestion.InputGroup(a_ans.length);
      group1.addInput(a_ps, 0, "a) $\\ p=$");
      question.addInputGroup(group1);

      const group2 = new MCQuestion.InputGroup(a_ans.concat(b_ans).length);
      group2.addInput(a_ps.concat(b_ps), 0, "b) $\\ p=$");
      question.addInputGroup(group2);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ModulusFunction_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const r1 = MCRandom.randomInt(1, 8);
    const r2 = MCRandom.randomInt(1, 8) * -1;

    const top = MCRandom.randomInt(
      6 * Math.abs(r1 * r2),
      10 * Math.abs(r1 * r2),
      Math.abs(r1 * r2)
    );
    const poly = MCMaths.Polynomial.generateFromRoots([r1, r2], "x");

    const fx = `(${top}/((x+${-r1})*(x+${-r2})))`;
    const gx = `(${top}/((Math.abs(x)+${-r1})*(Math.abs(x)+${-r2})))`;
    const hx = `Math.abs(${fx})`;

    const xmp = (r1 + r2) / 2;
    const ymp = top / ((xmp - r1) * (xmp - r2));

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(r1 - r2) > 3 &&
      parseFloat(ymp.toFixed(3)) === ymp &&
      xmp > 1 &&
      ymp > -7
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const g1 = new MCQuestion.Graph(20, -20, 10, -10, 4, 2);
      g1.plot(fx, -20, 20);
      question.addGraph("question", g1);

      question.addParagraph(
        "question",
        MCMaths.cleaner(`The figure above shows the graph of $y=f(x)$`)
      );
      // question.addParagraph('question', MCMaths.cleaner(`$f(x)=\\frac{${top}}{${poly.toString()}}$`));
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `The curve has asymptotes at $x=${r2},x=${r1}$ and $y=0$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `The point $P$ is a maximum point at $(${xmp}, ${ymp})$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`a) Sketch the graph of $y=f(|x|)$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`b) Sketch the graph of $y=|f(x)|$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `In each case state the coordinates of any turning points and asymptotes`
        )
      );
      // solution
      // a
      question.addHeading(
        "solution",
        MCMaths.cleaner(`a) Sketch the graph of $y=f(|x|)$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `Turning points at $(${-xmp}, ${ymp})$ and $(${xmp}, ${ymp})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`Asymptotes at $x=${-r1},x=${r1}$ and $y=0$`)
      );

      const g2 = new MCQuestion.Graph(20, -20, 10, -10, 4, 2);
      g2.plot(gx, -20, 20);
      question.addGraph("solution", g2);
      // b
      question.addHeading(
        "solution",
        MCMaths.cleaner(`b) Sketch the graph of $y=|f(x)|$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`Turning point at $(${xmp}, ${-ymp})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`Asymptotes at $x=${r2},x=${r1}$ and $y=0$`)
      );

      const g3 = new MCQuestion.Graph(20, -20, 10, -10, 4, 2);
      g3.plot(hx, -20, 20);
      question.addGraph("solution", g3);
      //
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ModulusFunction_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const k = MCRandom.randomInt(4, 12);
    const a = MCRandom.randomInt(3, 7) / 2;
    const log = new MCMaths.Logarithm(1, "e", k, "x");
    const fx = `${log.functionString()} - ${a}`;
    const gx = `Math.abs(${log.functionString()} - ${a})`;

    /* END VARIABLES AND CONSTANTS */
    if (true && log.evaluate(Math.E / k + 1) - a > 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      //
      const g1 = new MCQuestion.Graph(10, -1, 6, -6, 1, 1);
      g1.plot(fx, -2, 10);
      question.addGraph("question", g1);
      //
      question.addParagraph(
        "question",
        `The figure above shows the graph of $y=\\ln(${k}x)-a$`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Given that the graph of $y=|\\ln(${k}x)-a|$ passes through the point $(${
            1 / k
          }e,${a - 1})$`
        )
      );
      question.addParagraph("question", MCMaths.cleaner(`a) Find $a$`));
      question.addParagraph(
        "question",
        `b) Sketch the graph of $y=|\\ln(${k}x)-a|$`
      );
      // solution
      // a
      question.addHeading("solution", MCMaths.cleaner(`a) Find $a$`));
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Using point $(${1 / k}e,${a - 1})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$|\\ln(${k}\\times ${1 / k}e)-a|=${a - 1}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `On the graph of $y=\\ln(${k}x)-a$ ,$y<0$ at $x=${1 / k}e$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore \\ln(${k} \\times ${1 / k}e)-a=${1 - a}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$1-a=${1 - a}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore a=${a}$`)
      );
      // b
      question.addHeading(
        "solution",
        `b) Sketch the graph of $y=|\\ln(${k}x)-a|$`
      );
      const g2 = new MCQuestion.Graph(10, -1, 6, -6, 1, 1);
      g2.plot(gx, -2, 10);
      question.addGraph("solution", g2);
      //
      const group = new MCQuestion.InputGroup(1);
      group.addInput(a, 0, `a) $\\ a=$`);
      question.addInputGroup(group);
      //
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ModulusFunction_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const type = MCRandom.randomInt(0, 1); // 0 for |fx|, 1 for f|x|

    const top = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const offset = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const fx = `${top}/(x+${offset})`;
    const gx = `Math.abs(${top}/(x+${offset}))`;
    const hx = `${top}/(Math.abs(x)+${offset})`;
    const ix = `${-top}/(x+${offset})`;
    const jx = `${top}/(-1*Math.abs(x)+${offset})`;
    /* END VARIABLES AND CONSTANTS */
    if (top / offset > 2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      const g1 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g1.plot(fx, -10, 10);
      question.addGraph(g1);
      question.addParagraph(`The diagram above shows the graph of $y=f(x)$`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      const ga = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      ga.plot(gx, -10, 10);
      a1.addGraph(ga);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      const ga2 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      ga2.plot(hx, -10, 10);
      a2.addGraph(ga2);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      const ga3 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      if (type === 0) {
        ga3.plot(ix, -10, 10);
      } else {
        ga3.plot(jx, -10, 10);
      }
      a3.addGraph(ga3);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      const ga4 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      if (type === 0) {
        if (top > 0) {
          ga4.plot(fx, -offset, 10);
        } else {
          ga4.plot(fx, -10, -offset);
        }
      } else {
        ga4.plot(fx, 0, 10);
      }
      a4.addGraph(ga4);

      if (type === 0) {
        question.addParagraph(`Select from below the graph of $y=|f(x)|$`);
        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      } else {
        question.addParagraph(`Select from below the graph of $y=f(|x|)$`);
        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ModulusFunction_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const c = MCRandom.randomInt(2, 5);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(`It is given that $a=${c}|b|$`);
      question.addParagraph(
        `Choose the statement below that is true for all values of $a$ and $b$`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(
        MCMaths.cleaner(
          [
            `$a^2=${c ** 2}b^2$`,
            `$a^2+${-1 * c ** 2}b^2=0$`,
            `$${1 / c ** 2}a^2=b^2$`,
            `$${1 / c ** 2}a^2-b^2=0$`,
          ][MCRandom.randomInt(0, 3)]
        )
      );

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(
        MCMaths.cleaner(
          [`$a=${c}b$`, `$a+${-c}b=0$`, `$${1 / c}a=b$`, `$${1 / c}a-b=0$`][
            MCRandom.randomInt(0, 3)
          ]
        )
      );

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(
        MCMaths.cleaner(
          [`$a=${-c}b$`, `$a+${c}b=0$`, `$${1 / c}a=-b$`, `$${1 / c}a+b=0$`][
            MCRandom.randomInt(0, 3)
          ]
        )
      );

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(
        MCMaths.cleaner(
          [
            `$a^2=${c}b^2$`,
            `$a^2+${-c}b^2=0$`,
            `$${1 / c}a^2=b^2$`,
            `$${1 / c}a^2-b^2=0$`,
          ][MCRandom.randomInt(0, 3)]
        )
      );

      // add the answers to the question
      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ModulusFunction_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(4, 10) / 2;
    const b = MCRandom.randomInt(4, 20) * -1;

    const falseB = MCRandom.randomInt(1, 5) * -1;
    const falseA = (3 * a) / 2;

    const f = new MCMaths.Modulus(new MCMaths.Polynomial([a, b], "x"));
    const g = new MCMaths.Modulus(new MCMaths.Polynomial([a, -b], "x"));
    const h = new MCMaths.Modulus(new MCMaths.Polynomial([falseA, b], "x"));
    const i = new MCMaths.Modulus(new MCMaths.Polynomial([a, falseB], "x"));
    const fx = `Math.abs(${a}*x+${b})`;
    const gx = `Math.abs(${a}*x+${-b})`;
    const hx = `Math.abs(${falseA}*x+${b})`;
    const ix = `Math.abs(${a}*x+${falseB})`;

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(-b / a + falseB / a) > 1 &&
      Math.abs(-b / a + b / falseA) > 1 &&
      -b / a < 7 &&
      Math.abs(b) < 10
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(MCMaths.cleaner(`$f(x)=${a}x+${b}$`));
      question.addParagraph(`Choose the correct graph of $y=|f(x)|$ below`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      const g1 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g1.plot(fx, -10, 10);
      a1.addGraph(g1);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      const g2 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g2.plot(gx, -10, 10);
      a2.addGraph(g2);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      const g3 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g3.plot(hx, -10, 10);
      a3.addGraph(g3);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      const g4 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g4.plot(ix, -10, 10);
      a4.addGraph(g4);

      // add the answers to the question
      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ModulusFunction_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const y = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `The curve $y=f(x)$ has a minimum point, $A$ at $(${x}, ${y})$`
      );

      question.addParagraph(
        `Find the coordinates to which $A$ is transformed, if it's transformed at all, on the graph of $y=|f(x)|$`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$A$ is transformed onto the point $(${x},${-y})$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$A$ is transformed onto the point $(${-x},${-y})$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$A$ is transformed onto the point $(${-x},${y})$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$A$ does not change with the transformation`);

      if (y < 0) {
        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      } else {
        question.addAnswer(a1, false);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, true);
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ParametricEquations_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const xs = [
      ["x=ln \\left(T+A \\right)", "e^{x}-A"],
      ["x=e^{T}+A", "ln(x-A)"],
      ["x=\\frac{A}{T}", "\\frac{A}{x}"],
      ["x=\\sin\\left(T+A\\right)", "\\sin^{-1}\\left(x\\right)-A"],
      ["x=\\cos\\left(T+A\\right)", "\\cos^{-1}\\left(x\\right)-A"],
      ["x=\\tan\\left(T+A\\right)", "\\tan^{-1}\\left(x\\right)-A"],
      ["x=T^2+A", "\\sqrt{x-A}"],
    ];
    const type1 = MCRandom.randomInt(0, xs.length - 1);
    const type2 = MCRandom.randomInt(0, xs.length - 1);
    const x = xs[type1];
    const a1 = MCRandom.randomInt(1, 9);
    const xx = x[0].replace(/A/g, a1).replace(/T/, "t");
    const xt = x[1].replace(/A/g, a1);
    const y = xs[type2];
    const a2 = MCRandom.randomInt(1, 9);
    const yx = y[0].replace(/A/g, a2).replace(/x/g, "y");
    const b = MCRandom.randomInt(1, 9) / 10 ** MCRandom.randomInt(0, 1);

    const evalsX = [
      Math.exp(b) - a1,
      Math.log(b - a1),
      a1 / b,
      Math.asin(b) - a1,
      Math.acos(b) - a1,
      Math.atan(b) - a1,
      Math.sqrt(b - a1),
    ];
    const T = evalsX[type1];
    const evalsY = [
      Math.log(T + a2),
      Math.exp(T) + a2,
      a2 / T,
      Math.sin(T + a2),
      Math.cos(T + a2),
      Math.tan(T + a2),
      T * T + a2,
    ];

    const ans = evalsY[type2];
    /* END VARIABLES AND CONSTANTS */
    if (
      x !== y &&
      !Number.isNaN(Number(ans)) &&
      Math.abs(ans) > 0.1 &&
      Math.abs(ans) < 30 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `By first finding the cartesian form, $y=f(x)$, for the equation given by $${xx}$, $${yx.replace(
          /T/,
          "t"
        )}$, $t\\ge 0$ , find $f\\left(${MCMaths.cleaner(
          `${b}`
        )}\\right)$, correct to 2dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Rearanging $x(t)$:`,
        `$t=${xt}$`,
        `HEADING Substituting $t(x)$ into $y(t)$:`,
        `$${yx.replace("T^2", "\\left(T\\right)^2").replace(/T/g, xt)}$`,
        `HEADING Evaluating at $x=${MCMaths.cleaner(`${b}`)}$:`,
        `$${yx
          .replace("T^2", "\\left(T\\right)^2")
          .replace(/T/g, xt)
          .replace(/x/g, b)}$`,
        `$=${Math.round(ans * 100) / 100}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(Math.round(ans * 100) / 100, 0.01);
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ParametricEquations_Exam_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 12);
    const p = 4 / a ** 3;
    const q = -3 / a;
    const xVal = Math.sqrt(-q / (p * 3));
    const t1 = Math.acos(xVal / a);
    const t2 = Math.acos(-xVal / a);
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(250 * p) === 250 * p &&
      Math.round(250 * q) ===
        250 * q /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A curve has parameters $x=${a}\\cos(t), \\ y=\\cos(3t)$.`,
        `a) Find $p,q$ for the cartesian equation of this curve $y(x)=px^3+qx$.`,
        `b) Find the values of $t$ corresponding to the turning points of $y(x)$, $0\\le t \\le \\pi$, to 2dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING $t=\\cos^{-1}\\left(\\frac{x}{${a}}\\right)$`,
        `$\\cos\\left(3t\\right) = \\cos(2t+t)$`,
        `$\\cos(2t+t) = \\cos(t)\\cos(2t) - \\sin(t)\\sin(2t)$`,
        `$= \\cos(t)\\big(\\cos^2(t)-\\sin^2(t)\\big) - \\sin(t)\\big(2\\sin(t)\\cos(t)\\big)$`,
        `$= \\cos(t)\\big(\\cos^2(t)-1+\\cos^2(t)\\big) - 2\\sin^2(t)\\cos(t)$`,
        `$= 2\\cos^3(t)-\\cos(t) - 2(1-\\cos^2(t))\\cos(t)$`,
        `$= 4\\cos^3(t) - 3\\cos(t)$`,
        `HEADING Substituting into $y(x)=px^3+qx$:`,
        `$4\\cos^3(t) - 3\\cos(t) = p\\big(${a}\\cos(t)\\big)^3 + ${a}q\\cos(t)$`,
        `$p=\\frac{4}{${a ** 3}}$`,
        `$= ${p}$`,
        `$q=\\frac{-3}{${a}}$`,
        `$= ${q}$`,
        `b)HEADING $y'(x)=${MCMaths.cleaner(`${p * 3}x^2+${q}`)}$`,
        `HEADING Finding where $y'(x)=0$:`,
        `$x^2=${MCMaths.cleaner(`${-q / (p * 3)}`)}$`,
        `$x = \\pm ${MCMaths.cleaner(`${xVal}`)}$`,
        `$\\pm${MCMaths.cleaner(`${xVal}`)} = ${a}\\cos(t)$`,
        `$t = \\cos^{-1}\\left(\\pm${MCMaths.cleaner(`${xVal / a}`)}\\right) $`,
        `$t\\approx ${Math.round(100 * t1) / 100}, t\\approx ${
          Math.round(100 * t2) / 100
        }$`,
      ]);
      const group = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(2);
      group.addInput(p, 0.01, "p=");
      group.addInput(q, 0.01, "q=");
      group2.addInput(
        [Math.round(100 * t1) / 100, Math.round(100 * t2) / 100],
        0.02,
        "t="
      );
      question.addInputGroup(group);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ParametricEquations_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const r1 = MCRandom.randomInt(-9, 9);
    const r2 = MCRandom.randomInt(-9, 9);
    const x = MCMaths.Polynomial.generateFromRoots([r1, r2], "t");
    const r3 = MCRandom.randomInt(-9, 9);
    const y = MCMaths.Polynomial.generateFromRoots([r3], "t");
    /* END VARIABLES AND CONSTANTS */
    if (r1 !== r2 /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A curve is defined by the equations $x=${x.toString()}$, $y=${y.toString()}$.`,
        `a) Find the x coordinate of where this curve intersects the $x$ axis.`,
        `b) Find the y coordinates of where this curve intersects the $y$ axis.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding where $y=0$:`,
        `$0=${y.toString()}$`,
        `$t=${r3}$`,
        `HEADING Finding $x$ coordinate:`,
        `$x=${x.toString().replace(/t/g, `\\left(${r3}\\right)`)}$`,
        `$x=${x.evaluate(r3)}$`,
        `b) HEADING Finding where $x=0$:`,
        `$x=${MCMaths.cleaner(
          `\\left(t-${r1}\\right)\\left(t-${r2}\\right)`
        )}$`,
        `$t=${r1},t=${r2}$`,
        `HEADING Finding $y$ coordinates:`,
        `$y_1=${y.toString().replace(/t/g, `\\left(${r1}\\right)`)}$`,
        `$y_1=${y.evaluate(r1)}$`,
        `$y_2=${y.toString().replace(/t/g, `\\left(${r2}\\right)`)}$`,
        `$y_2=${y.evaluate(r2)}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(x.evaluate(r3), 0, "$x=$");
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput([y.evaluate(r1), y.evaluate(r2)], 0, "$y=$");
      question.addInputGroup(group);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ParametricEquations_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const r = MCRandom.randomInt(1, 5);

    const m = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const inter = new MCMaths.Intersections(
      [m, -1, -c],
      [-2 * a, -2 * b, 1, 1, r * r - a * a - b * b]
    );
    let y1 = inter.solutions()[1];
    let y2 = inter.solutions()[3];
    /* END VARIABLES AND CONSTANTS */
    if (
      inter.solutions()[0] !== "N" &&
      y1 !== "No Real Roots" &&
      Math.round(y1 * 10) === y1 * 10 &&
      Math.round(y2 * 10) ===
        y2 * 10 /* conditions to be met - true if no conditions */
    ) {
      y1 = Math.round(y1 * 10) / 10;
      y2 = Math.round(y2 * 10) / 10;
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A curve is defined by the parameters $${MCMaths.cleaner(
          `x=${a}+${r}\\cos(t)`
        )}$, $${MCMaths.cleaner(`y=${b}+${r}\\sin(t)`)}$.`,
        `a) Find the $y$ coordinates where this curve intersect the line $${MCMaths.cleaner(
          `${m}x-y=${-1 * c}`
        )}$.`,
        `b) Sketch this curve, and the line.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING These parameters define a circle, centre $(${a},${b})$, with radius ${r}.`,
        `In cartesian form, this is $${MCMaths.cleaner(
          `(x-${a})^{2}+(y-${b})^2=${r}^2`
        )}$`,
        `Expanding, ${inter.toStringArray()[1]}`,
      ]);
      question.addMultipleParagraphs("solution", inter.solutionsWorking());
      question.addParagraph("solution", "b)HEADING");
      question.addGraph(
        "solution",
        inter.graph(Math.ceil(1.1 * Math.max(Math.abs(a) + r, Math.abs(b) + r)))
      );
      const group = new MCQuestion.InputGroup(2);
      group.addInput([y1, y2], 0, "$y=$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ParametricEquations_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 12);
    const b = MCRandom.randomInt(1, 5);
    const t0 = MCRandom.randomInt(0, 3);
    const t1 = t0 + MCRandom.randomInt(1, 6);
    const p1 = Math.sqrt(a * t0);
    const q1 = Math.sqrt(a * t1);

    const critPoints = [t0 * (t0 - b), t1 * (t1 - b)];
    if (b / 2 > t0 && b / 2 < t1) {
      critPoints.push((-b * b) / 4);
    }

    const p2 = Math.min(...critPoints);
    const q2 = Math.max(...critPoints);

    const graphSizeX = Math.ceil(1.2 * q1);
    const graphSizeY0 = Math.min(Math.floor(1.2 * p2), -1);
    const graphSizeY1 = Math.max(Math.ceil(1.2 * q2), 1);

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(p1) === p1 &&
      Math.round(q1) === q1 &&
      q2 < 6 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A curve is defined by the equations $x=\\sqrt{${a}t}, \\ y = t\\left(t-${b}\\right), \\ ${t0}\\le t \\le ${t1}.$`,
        `a) For the cartesian function $y=f(x)$, find the domain $p\\le x \\le q$.`,
        `b) Sketch this curve.`,
        `c) For the same function, find the range $p\\le y\\le q$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING $x$ increases as $t$ increases: `,
        `$p=x(${t0})$`,
        `$=\\sqrt{${a}\\cdot ${t0}}$`,
        `$=${p1}$`,
        `$q=x(${t1})$`,
        `$=\\sqrt{${a}\\cdot ${t1}}$`,
        `$=${q1}$`,
        `b) HEADING`,
      ]);

      const bGraph = new MCQuestion.Graph(
        graphSizeX,
        0,
        graphSizeY1,
        graphSizeY0,
        1,
        1
      );
      bGraph.addParametric(`Math.sqrt(${a} * t)`, `t * (t - ${b})`, t0, t1);
      question.addGraph("solution", bGraph);

      question.addParagraph("solution", `c)HEADING From the graph:`);
      if (t1 * (t1 - b) === p2) {
        question.addMultipleParagraphs("solution", [
          `HEADING Curve minimun is at endpoint, $t=${t1}$.`,
          `$y(${t1})=${t1}(${t1}-${b})$`,
          `$=${MCMaths.cleaner(`${p2}`)}$`,
        ]);
      } else {
        question.addMultipleParagraphs("solution", [
          `HEADING Curve minimun is at turning point,`,
          `$\\frac{dy}{dt}=2t-${b}$`,
          `Turning point is at $\\frac{dy}{dt}=0$`,
          `$0=2t-${b}$`,
          `$t=${MCMaths.cleaner(`${b / 2}`)}$`,
          `$y\\left(${MCMaths.cleaner(`${b / 2}`)}\\right)=${MCMaths.cleaner(
            `${p2}`
          )}$`,
        ]);
      }
      if (t1 * (t1 - b) === q2) {
        question.addMultipleParagraphs("solution", [
          `HEADING Curve maximun is at endpoint, $t=${t1}$.`,
          `$y(${t1})=${t1}(${t1}-${b})$`,
          `$=${MCMaths.cleaner(`${q2}`)}$`,
        ]);
      } else {
        question.addMultipleParagraphs("solution", [
          `HEADING Curve maximun is at startpoint, $t=${t0}$.`,
          `$y(${t0})=${t0}(${t0}-${b})$`,
          `$=${MCMaths.cleaner(`${q2}`)}$`,
        ]);
      }
      const group = new MCQuestion.InputGroup(2);
      group.addInput(p1, 0, "$p=$");
      group.addInput(q1, 0, "$q=$");
      group.addInput(p2, 0, "$p=$");
      group.addInput(q2, 0, "$q=$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ParametricEquations_Exam_Q5() {
  let count = 1;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const types = [
      [2, 6, 1, Math.PI / 6],
      [2, 8, 1, Math.PI / 4],
      [3, 2, 2, Math.PI / 12],
      [3, 4, 2, Math.PI / 2],
      [3, 9, 2 / 3, Math.PI / 2],
      [5, 3, 2, Math.PI],
      [5, 4, 2, Math.PI],
    ];
    const type = MCRandom.randomInt(0, types.length - 1);
    const cTypes = [
      [`c) Finding where $y(t)=0$ and $x(t)=1$:`][
        `c) Finding where $y(t)=0$ and $x(t)=1$:`
      ],
    ];
    const x = new MCMaths.Trig(
      MCRandom.randomInt(2, 3),
      "cos",
      1,
      types[type][0]
    );
    const y = new MCMaths.Trig(
      MCRandom.randomInt(4, 5),
      "sin",
      1,
      types[type][1]
    );

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A tailor is stiching a pattern defined by $x=${x
          .toString()
          .replace(/x/g, "t")}$, $y=${y
          .toString()
          .replace(/x/g, "t")}$, $t\\ge 0$.`
      );
      const bGraph = new MCQuestion.Graph(6, -6, 6, -6, 1, 1);

      function f(t) {
        return x.evaluate(t);
      }
      function g(t) {
        return y.evaluate(t);
      }
      bGraph.addParametric(
        x.functionString().replace(/x/g, "t"),
        y.functionString().replace(/x/g, "t"),
        0,
        10
      );
      question.addGraph("question", bGraph);
      question.addMultipleParagraphs("question", [
        `a) Given the tailor starts at $t=0$, find the coordinates where they start stiching.`,
        `b) If the tailor can stich $\\frac{\\pi}{8}$ units of $t$ per minute, how many minutes will this pattern take to stich?`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) $x(0)=${x.toString().replace(/x/g, "(0)")}$`,
        `$=${x.evaluate(0)}$`,
        `$y(0)=${y.toString().replace(/x/g, "(0)")}$`,
        `$=${y.evaluate(0)}$`,
        `b) The period of $x(t)$ is $${MCMaths.cleaner(
          `${(2 * Math.PI) / types[type][0]}`
        )}$. The period of $y(t)$ is $${MCMaths.cleaner(
          `${(2 * Math.PI) / types[type][1]}`
        )}$. `,
        `The LCM of these periods is $${MCMaths.cleaner(
          `${Math.PI * types[type][2]}`
        )}$.`,
        `This occurs at $${MCMaths.cleaner(
          `${Math.PI * types[type][2]}`
        )}\\times \\frac{8}{\\pi} =${MCMaths.cleaner(
          `${8 * types[type][2]}`
        )} $ minutes.`,
      ]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ParametricEquations_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const angles = [30, 45, 60];
    const a = angles[MCRandom.randomInt(0, angles.length - 1)];
    const v = MCRandom.randomInt(10, 20);
    const h = MCRandom.randomInt(10, 25, 5);

    const t1 =
      (-v * Math.sin((a * Math.PI) / 180) -
        Math.sqrt(v * v * Math.sin((a * Math.PI) / 180) ** 2 + 39.2 * h)) /
      -19.6;

    function f(t) {
      return v * Math.cos((a * Math.PI) / 180) * t;
    }
    function g(t) {
      return -9.8 * t ** 2 + v * Math.sin((a * Math.PI) / 180) * t + h;
    }

    const fd = `${v} * Math.cos((${a} * Math.PI) / 180) * t`;
    const gd = `-9.8 * t ** 2 + ${v} * Math.sin((${a} * Math.PI) / 180) * t + ${h}`;

    const graphSize = Math.max(
      g((v * Math.sin((a * Math.PI) / 180)) / 19.6),
      f(t1)
    );
    const maxH = g((v * Math.sin((a * Math.PI) / 180)) / 19.6);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A ball is thrown from the top of a $${h}m$ building at an initial speed of $${`${v}ms^{-1}`}$, at an angle of $${a}^\\circ$.`
      );

      const bGraph = new MCQuestion.Graph(graphSize, 0, graphSize, 0, 5, 5);
      bGraph.addParametric(fd, gd, 0, t1);
      question.addGraph("question", bGraph);
      question.addMultipleParagraphs("question", [
        `The path of the ball is given by the equations $x=${v}\\cos\\left(${a}\\right)t, \\ y=-9.8t^2 + ${v}\\sin\\left(${a}\\right)t + ${h}, \\ t\\ge 0$, for time $t$ in seconds.`,
        `a) What times does the ball hit the ground, to 2dp.`,
        `b) How far is the ball from the building when it hits the ground, to 2dp?`,
        `c) What is the greatest height the ball achieves, to 2dp?`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING Solving $y(t)=0$:`,
        `$0=-9.8t^2 + ${v}\\sin\\left(${a}\\right)t + ${h}$`,
        `$t=\\frac{-${v}\\sin\\left(${a}\\right)\\pm\\sqrt{${v}^2\\sin^2\\left(${a}\\right)+4\\cdot 9.8 \\cdot${h}}}{-2\\cdot 9.8}$`,
        `Picking positive solution, $t=${Math.round(t1 * 100) / 100}s$.`,
        `b) HEADING $x(${
          Math.round(t1 * 100) / 100
        })=${v}\\cos\\left(${a}\\right)\\cdot${Math.round(t1 * 100) / 100}$`,
        `$\\approx ${Math.round(f(t1) * 100) / 100}m$.`,
        `c)HEADING Solving $y'(t)=0$`,
        `$y'(t) = -19.6t + ${v}\\sin\\left(${a}\\right)$`,
        `$t = \\frac{${v}\\sin\\left(${a}\\right)}{-19.6}$`,
        `$t \\approx ${
          Math.round((v * Math.sin((a * Math.PI) / 180) * 100) / 19.6) / 100
        }$`,
        `$y(${
          Math.round((v * Math.sin((a * Math.PI) / 180) * 100) / 19.6) / 100
        }) = ${Math.round(maxH * 100) / 100}m$.`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(Math.round(t1 * 100) / 100, 0.01, "a)");
      group.addInput(Math.round(f(t1) * 100) / 100, 0.01, "b)");
      group.addInput(Math.round(maxH * 100) / 100, 0.01, "c)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ParametricEquations_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(1, 5);
    const c = MCRandom.randomInt(-5, 5);
    const d = MCRandom.randomInt(-5, 5);
    const xt = new MCMaths.Polynomial([a, 0], "t");
    const yt = new MCMaths.Polynomial([b, c, d], "t");
    const f = new MCMaths.Polynomial([b / a ** 2, c / a, d]);

    const min = f.evaluate(-c / a / ((2 * b) / a ** 2));
    const c2 = Math.floor(min) - MCRandom.randomInt(1, 5);

    const line = new MCMaths.Polynomial([
      MCRandom.randomInt(1, 5),
      MCRandom.randomInt(-5, 5),
    ]);

    const mEq = new MCMaths.Polynomial(
      [
        1,
        -2 * f.getCoefficients()[1],
        f.getCoefficients()[1] ** 2 -
          4 * f.getCoefficients()[0] * (f.getCoefficients()[2] - c2),
      ],
      "m"
    );
    const r1 = Math.round(mEq.roots()[0] * 100) / 100;
    const r2 = Math.round(mEq.roots()[1] * 100) / 100;
    /* END VARIABLES AND CONSTANTS */
    if (
      c2 !== 0 &&
      Math.round(
        10000 * mEq.getCoefficients()[1] ** 2 -
          4 * mEq.getCoefficients()[0] * mEq.getCoefficients()[2]
      ) ===
        10000 * mEq.getCoefficients()[1] ** 2 -
          4 * mEq.getCoefficients()[0] * mEq.getCoefficients()[2] &&
      Math.abs(mEq.getCoefficients()[1]) !== 1 &&
      Math.abs(mEq.getCoefficients()[1]) !==
        0 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A curve is defined by $x=${xt.toString()}, \\ y=${yt.toString()}$. The line $${MCMaths.cleaner(
          `mx+${c2}`
        )}$ only meets the curve at one point. Find the possible values of $m$, to 2dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Writing curve in cartesian form:`,
        `$t=\\frac{x}{${a}}$`,
        `$y=${yt.toString().replace(/t/g, `\\left(\\frac{x}{${a}}\\right)`)}$`,
        `$y=${f.toString()}$`,
        `HEADING Finding curve/line intersection:`,
        `$${MCMaths.cleaner(`mx+${c2}`)} = ${f.toString()}$`,
        `$${MCMaths.cleaner(
          `${f.getCoefficients()[0]}x^2+\\left(${
            f.getCoefficients()[1]
          }-m\\right)x+${f.getCoefficients()[2] - c2}=0`
        )}$`,
        `HEADING For one point of intersection, discriminant is 0:`,
        `$${MCMaths.cleaner(
          `\\left(${f.getCoefficients()[1]}-m\\right)^2-4\\cdot ${
            f.getCoefficients()[0]
          } \\cdot ${f.getCoefficients()[2] - c2} = 0`
        )}$`,
        `$${mEq.toString()}=0$`,
        `$m=${mEq.quadraticFormula()}$`,
        `$m=${r1}, m=${r2}$.`,
      ]);
      const group = new MCQuestion.InputGroup(2);
      group.addInput([r1, r2], 0.01, "m=");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ParametricEquations_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */

    const a = MCRandom.randomInt(1, 4);
    const b = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);

    function f(t) {
      return a * Math.log(t) - b;
    }
    function g(t) {
      return c + (a * (t + 1 / t)) / 2;
    }

    const fd = `${a} * Math.log(t) - ${b}`;
    const gd = `${c} + (${a} * (t + 1 / t)) / 2`;

    const x0 = MCRandom.randomInt(-5, -1);
    const x2 = MCRandom.randomInt(1, 5);

    const p = Math.exp((x0 + b) / a);
    const q = Math.exp((x2 + b) / a);

    const y0 = g(p);
    const y2 = g(q);

    let midT = -1;
    if (Math.ceil(p) < Math.floor(q)) {
      midT = MCRandom.randomInt(Math.ceil(p), Math.floor(q));
    }

    const y1 = g(midT);

    // const p = Math.exp(-()/2)
    /* END VARIABLES AND CONSTANTS */
    if (
      midT !== -1 &&
      Math.abs(midT) < 10 &&
      Math.round(y1 * 10) === y1 * 10 &&
      p !== 1 &&
      Math.abs(y0) < 20 &&
      Math.abs(y2) < 20 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A rope hung between two points forms a curve called a Catenary, which can be defined by the equations $x=a\\ln(t)-b, \\ y=c+a\\cdot\\frac{t+t^{-1}}{2}, \\ p\\le t \\le q$.`,
        `A rope is hung between $x=${x0}$ and $x=${x2}$.`,
        `a) Given $a=${a}$, $b=${b}$, find $p$ and $q$ to 2dp.`,
        `b) At ${MCMaths.cleaner(`$t=${midT}$, $y=${y1}$`)}. Find $c$.`,
        `c) Find the y coordinates of the start and end of the rope, to 2dp.`,
        `d) Find the coordinates of the lowest point of the rope.`,
        `e) Sketch the rope.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING $x(p)=${x0}$`,
        `$${a}\\ln(p)-${b} = ${x0}$`,
        `$p=e^{${MCMaths.cleaner(`${(x0 + b) / a}`)}}$`,
        `$p\\approx ${Math.round(100 * p) / 100}$`,
        `$x(q)=${x0}$`,
        `$${a}\\ln(q)-${b} = ${x2}$`,
        `$q=e^{${MCMaths.cleaner(`${(x2 + b) / a}`)}}$`,
        `$q\\approx ${Math.round(100 * q) / 100}$`,
        `b)HEADING $y(${midT})=${MCMaths.cleaner(`${y1}`)}$`,
        `$c+${a}\\cdot\\left(\\frac{${midT}+${midT}^{-1}}{2}\\right)=${MCMaths.cleaner(
          `${y1}`
        )}$`,
        `$c=${c}$`,
        `c)HEADING $y\\left(e^{${MCMaths.cleaner(
          `${(x0 + b) / a}`
        )}}\\right) = ${c} + \\frac{${a}}{2}\\cdot\\left(e^{${MCMaths.cleaner(
          `${(x0 + b) / a}`
        )}}+e^{${MCMaths.cleaner(`${-(x0 + b) / a}`)}}\\right)$`,
        `$y\\approx${Math.round(y0 * 100) / 100}$`,
        `$y\\left(e^{${MCMaths.cleaner(
          `${(x2 + b) / a}`
        )}}\\right) = ${c} + \\frac{${a}}{2}\\cdot\\left(e^{${MCMaths.cleaner(
          `${(x2 + b) / a}`
        )}}+e^{${MCMaths.cleaner(`${-(x2 + b) / a}`)}}\\right)$`,
        `$y\\approx${Math.round(y2 * 100) / 100}$`,
        `d)HEADING Finding the turning point of $y(t):$`,
        `$y'(t) = ${MCMaths.cleaner(`${a / 2}\\left(1-t^{-2}\\right)`)}$`,
        `$y'(t)=0$`,
        `$${MCMaths.cleaner(`${a / 2}\\left(1-t^{-2}\\right)`)}=0$`,
        `$t^{-2}=1$`,
        `$t=1$`,
        `$x(1)=${-b}$`,
        `$y(1)=${c + a}$`,
        `e)HEADING`,
      ]);
      const e = new MCQuestion.Graph(
        Math.ceil(x2 * 1.1),
        Math.floor(x0 * 1.1),
        Math.round(Math.max(0, y0, y2) * 1.1),
        Math.round(Math.min(-1, c + a) * 1.1),
        1,
        1
      );
      e.addParametric(fd, gd, p, q);
      question.addGraph("solution", e);

      const groupA = new MCQuestion.InputGroup(2);
      groupA.addInput(Math.round(p * 100) / 100, 0.01, "a) $\\ p=$");
      groupA.addInput(Math.round(q * 100) / 100, 0.01, "a) $\\ q=$");
      question.addInputGroup(groupA);
      const groupB = new MCQuestion.InputGroup(1);
      groupB.addInput(c, 0, "b) $\\ c=$");
      question.addInputGroup(groupB);
      const groupC = new MCQuestion.InputGroup(2);
      groupC.addInput([y0, y2], 0.01, "c) $\\ y=$");
      question.addInputGroup(groupC);
      const groupD = new MCQuestion.InputGroup(2);
      groupD.addInput(-b, 0, "d) $\\ x=$");
      groupD.addInput(c + a, 0, "d) $\\ y=$");
      question.addInputGroup(groupD);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_AlgebraAndFunctions_ParametricEquations_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const r = MCRandom.randomInt(2, 6);
    const a = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const b = 0; // MCRandom.randomInt(1,5)*MCRandom.randomInt(-1,1,2)

    /* END VARIABLES AND CONSTANTS */
    if (
      a !== r &&
      a !== -r /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A circle is defined by the equations $${MCMaths.cleaner(
          `x=\\frac{${a + r}+${a - r}t^2}{1+t^2}, \\ y=\\frac{${2 * r}t}{1+t^2}`
        )}$. Given the centre of the circle is $(${a},0)$, find the radius of the circle.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING The equation of a circle is $(x-a)^2+(y-b)^2=r^2$.`,
        `HEADING Substituting $(${a},0)$:`,
        `$(${MCMaths.cleaner(`x-${a}`)})^2+y^2=r^2$`,
        `HEADING Substituting $x(t),y(t)$:`,
        `$${MCMaths.cleaner(
          `\\left(\\frac{${a + r}+${
            a - r
          }t^2}{1+t^2}-${a}\\right)^2 + \\left(\\frac{${
            2 * r
          }t}{1+t^2}\\right)^2= r^2`
        )}$`,
        `$${MCMaths.cleaner(
          `\\left(\\frac{${r}+${-r}t^2}{1+t^2}\\right)^2 + \\left(\\frac{${
            2 * r
          }t}{1+t^2}\\right)^2= r^2`
        )}$`,
        `$${MCMaths.cleaner(
          `\\frac{${r ** 2}-${2 * r ** 2}t^2+${
            r ** 2
          }t^4}{(1+t^2)^2} + \\frac{${4 * r ** 2}t^2}{(1+t^2)^2}= r^2`
        )}$`,
        `$${MCMaths.cleaner(
          `\\frac{${r ** 2}+${2 * r ** 2}t^2+${r ** 2}t^4}{(1+t^2)^2} = r^2`
        )}$`,
        `$${MCMaths.cleaner(`\\frac{${r ** 2}(1+t^2)^2}{(1+t^2)^2} = r^2`)}$`,
        `$${r ** 2}=r^2$`,
        `$r=${r}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(r, 0, "r=");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_A2FirstPrinciples_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 5);
    const s = new MCMaths.Trig(1, "sin", 1, MCRandom.randomInt(2, 5));
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Prove $\\displaystyle \\frac{d}{dx} ${s.toString()}=${s
          .derivative()
          .toString()}$, from first principles.`,
        `You may use the formula for $\\sin(A+B)$, aswell as the facts $\\displaystyle \\lim_{h\\to 0}\\frac{\\sin(kh)}{h}=k$ and $\\displaystyle \\lim_{h\\to 0}\\frac{\\cos(kh)-1}{h}=0$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Forming Equations:`,
        `$\\displaystyle\\frac{d}{dx}${s.toString()}=\\lim_{h\\to 0} \\frac{${s
          .toString()
          .replace(/x/g, "(x+h)")}-${s.toString()}}{h}$`,
        `HEADING Using $\\sin(A+B)$ formula.`,
        `$\\displaystyle = \\lim_{h\\to 0}\\frac{\\sin(${a}x)\\cos(${a}h)+\\cos(${a}x)\\sin(${a}h)-${s.toString()}}{h}$`,
        `$\\displaystyle = \\lim_{h\\to 0}\\left(\\frac{\\cos(${a}h)-1}{h}\\right)\\sin(${a}x)+\\lim_{h\\to 0}\\left(\\frac{\\sin(${a}h)}{h}\\right)\\cos(${a}x)$`,
        `HEADING Using the given limits:`,
        `$\\displaystyle = (0)\\sin(${a}x)+(${a})\\cos(${a}x)$`,
        `$=${a}\\cos(${a}x)$`,
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_A2FirstPrinciples_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const x1 = (MCRandom.randomInt(-1, 1, 2) * MCRandom.randomInt(2, 3)) / 2;
    const x2 = Math.round((x1 + MCRandom.randomInt(3, 8) / 10) * 10) / 10;

    const a = Math.sin(x1);
    const b = Math.sin(x2);
    const m = (b - a) / (x2 - x1);

    const m2 = Math.cos(x2);
    /* END VARIABLES AND CONSTANTS */
    if (x2 !== 0 /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `a) Sketch the graph of $f(x)=\\sin(x)$ from $-2\\pi \\le x \\le 2\\pi$.`,
        `b) Plot the line passing through $f(${x1})$ and $f(${x2})$.`,
        `c) Find the gradient of the line in part b).`,
        `d) Find the gradient of the line as $${x1}$ approaches $${x2}$, using the facts $\\displaystyle \\lim_{h\\to 0}\\frac{\\sin(h)}{h}=1$ and $\\displaystyle \\lim_{h\\to 0}\\frac{\\cos(h)-1}{h}=0$.`,
      ]);

      const graph1 = new MCQuestion.Graph(
        2 * Math.PI,
        -2 * Math.PI,
        2,
        -2,
        Math.PI / 2,
        1
      );

      const graph2 = new MCQuestion.Graph(
        2 * Math.PI,
        -2 * Math.PI,
        2,
        -2,
        Math.PI / 2,
        1
      );

      graph2.plot(`Math.sin(x)`, -2 * Math.PI, 2 * Math.PI);

      graph2.plot(`${m} * (x + ${-x1}) + ${a}`, -2 * Math.PI, 2 * Math.PI);

      question.addParagraph("solution", "a/b)HEADING");

      question.addGraph("solution", graph2);

      question.addMultipleParagraphs("solution", [
        "c) HEADING Evaluating:",
        `$f(${x1}) = \\sin(${x1})$`,
        `$\\approx ${Math.round(a * 1000) / 1000}$`,
        `$f(${x2}) = \\sin(${x2})$`,
        `$\\approx ${Math.round(x2 * 1000) / 1000}$`,
        `HEADING Finding Derivative:`,
        `$\\displaystyle m=\\frac{f(${x2})-f(${x1})}{${x2}}$`,
        `$\\approx ${Math.round(m * 1000) / 1000}$`,
        `HEADING d) Differentiation by first principles:`,
        `$\\displaystyle =\\lim_{h\\to 0}\\frac{f(${x2}-h)-f(${x2})}{h}$`,
        `$\\displaystyle =\\lim_{h\\to 0}\\frac{\\sin(${x2}-h)-\\sin(${x2})}{h}$`,
        `$\\displaystyle = \\lim_{h\\to 0}\\frac{\\sin(${x2})\\cos(h)+\\cos(${x2})\\sin(h)-\\sin(${x2})}{h}$`,
        `$\\displaystyle = \\lim_{h\\to 0}\\left(\\frac{\\cos(h)-1}{h}\\right)\\sin(${x2})+\\lim_{h\\to 0}\\left(\\frac{\\sin(h)}{h}\\right)\\cos(${x2})$`,
        `$= (0)\\sin(${x2}) + (1)\\cos(${x2})$`,
        `$= \\cos(${x2})$`,
        `$\\approx ${Math.round(m2 * 1000) / 1000}$`,
      ]);

      question.requiresSelfMarking();
      const group = new MCQuestion.InputGroup(1);
      group.addInput(m, 0.05, "c)");
      group.addInput(m2, 0.05, "d)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ChainRule_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    let vari;
    let deriv;
    const coordinate =
      (MCRandom.randomInt(1, 3) * MCRandom.randomInt(-1, 1, 2)) / 10;
    switch (MCRandom.randomInt(1, 3)) {
      case 1:
        vari = new MCMaths.Polynomial([
          MCRandom.randomInt(1, 2),
          0,
          MCRandom.randomInt(1, 5),
        ]);
        deriv = "x";
        break;
      case 2:
        vari = new MCMaths.Polynomial([
          MCRandom.randomInt(1, 2),
          0,
          0,
          MCRandom.randomInt(1, 5),
        ]);
        deriv = "x^2";
        break;
      case 3:
        vari = new MCMaths.Polynomial([
          MCRandom.randomInt(1, 2),
          0,
          0,
          0,
          MCRandom.randomInt(1, 5),
        ]);
        deriv = "x^3";
        break;
      default:
        break;
    }
    const power = MCRandom.randomInt(3, 6);
    const bAns =
      Math.round(vari.pow(power).derivative().evaluate(coordinate) * 100) / 100;
    const p = power * vari.coefficients[0] * (vari.coefficients.length - 1);
    const q = power - 1;

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given $y=\\left(${vari.toString()}\\right)^${power}$,`,
        `a) Find $\\frac{dy}{dx}$ in the form $p${deriv}(${vari})^{q}$.`,
        `b) Evaluate $\\frac{dy}{dx}$ at $x=${coordinate}$, rounded to $2dp$.`,
      ]);
      const group = new MCQuestion.InputGroup(2);
      group.addInput(p, 0, "a) p=");
      group.addInput(q, 0, "a) q=");
      question.addInputGroup(group);
      group.addInput(bAns, 0.01, "b)");

      question.addMultipleParagraphs("solution", [
        `a) HEADING Using the chain rule:`,
        `$u=${vari.toString()}$`,
        `$\\frac{du}{dx} = ${vari.derivative().toString()}$`,
        `$y=u^{${power}}$`,
        `$\\frac{dy}{du} = ${power}u^{${power - 1}}$`,
        `$\\frac{dy}{dx} = \\frac{dy}{du}\\times\\frac{du}{dx}$`,
        `$=${vari.derivative().toString()} \\times ${power}u^{${power - 1}}$`,
        `$=${vari
          .derivative()
          .toString()} \\times ${power}(${vari.toString()})^{${power - 1}}$`,
        `$=${p}${deriv}(${vari})^{${q}}$`,
        `b) HEADING Substituting in $x=${coordinate}$:`,
        `$=${p}${deriv}(${vari})^{${q}}$`.replace(/x/g, `(${coordinate})`),
        `$=${bAns}$, rounded to $2dp$.`,
      ]);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ChainRule_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    let vari;
    let coordinate;
    switch (MCRandom.randomInt(1, 5)) {
      case 1:
        vari = new MCMaths.Trig(
          MCRandom.randomInt(1, 4),
          "sin",
          1,
          MCRandom.randomInt(1, 4)
        );
        coordinate = (MCRandom.randomInt(1, 9) * Math.PI) / 6;
        break;
      case 2:
        vari = new MCMaths.Trig(
          MCRandom.randomInt(1, 4),
          "cos",
          1,
          MCRandom.randomInt(1, 4)
        );
        coordinate = (MCRandom.randomInt(1, 9) * Math.PI) / 6;
        break;
      case 3:
        vari = new MCMaths.Trig(
          MCRandom.randomInt(1, 4),
          "tan",
          1,
          MCRandom.randomInt(1, 4)
        );
        coordinate = (MCRandom.randomInt(1, 9) * Math.PI) / 6;
        break;
      case 4:
        vari = new MCMaths.Polynomial([
          MCRandom.randomInt(1, 2),
          0,
          0,
          MCRandom.randomInt(-5, 5),
          MCRandom.randomInt(1, 5),
        ]);
        coordinate = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);
        break;
      case 5:
        vari = new MCMaths.Polynomial([
          MCRandom.randomInt(1, 2),
          0,
          0,
          0,
          MCRandom.randomInt(-5, 5),
          MCRandom.randomInt(1, 5),
        ]);
        coordinate = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);
        break;

      default:
        break;
    }
    const m =
      vari.derivative().evaluate(coordinate) /
      (2 * Math.sqrt(vari.evaluate(coordinate)));
    const y1 = Math.sqrt(vari.evaluate(coordinate));
    const c = y1 - m * coordinate;
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(vari.evaluate(coordinate)) >= 0.00001 &&
      vari.evaluate(coordinate) > 0 &&
      vari.evaluate(coordinate) < 1000 &&
      Math.abs(vari.derivative().evaluate(coordinate)) >=
        0.00001 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given $y=\\sqrt{${vari.toString()}}$`,
        `a) Find $\\frac{dy}{dx}$ at $x={${MCMaths.cleaner(
          `${coordinate}`
        )}}$, to 2dp.`,
        `b) Find the $y$-intercept of the line tangent to $y$ at $x=${MCMaths.cleaner(
          `${coordinate}`
        )}$, correct to 2dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Using the chain rule:`,
        `$u=${vari.toString()}$`,
        `$\\frac{du}{dx} = ${vari.derivative().toString()}$`,
        `$y=u^{\\frac{1}{2}}$`,
        `$\\frac{dy}{du} = \\frac{1}{2}u^{-\\frac{1}{2}}$`,
        `$\\frac{dy}{dx} = \\frac{dy}{du}\\times\\frac{du}{dx}$`,
        `$=\\frac{${vari
          .derivative()
          .toString()}}{2\\sqrt{${vari.toString()}}}$`,
        `Substituting $x=${MCMaths.cleaner(`${coordinate}`)}$:`,
        `$=\\frac{${vari
          .derivative()
          .toString()}}{2\\sqrt{${vari.toString()}}}$`.replace(
          /x/g,
          `\\left(${MCMaths.cleaner(`${coordinate}`)}\\right)`
        ),
        `$=${Math.round(m * 100) / 100}$`,
        `b) HEADING Using the line equation $y-y_1=m(x-x_1)$:`,
        `$x_1 = ${MCMaths.cleaner(`${coordinate}`)}$`,
        `$y_1 = y\\left(${MCMaths.cleaner(`${coordinate}`)}\\right)$`,
        `$=${Math.round(y1 * 100) / 100}...$`,
        `From part a), $m=${Math.round(m * 100) / 100}...$`,
        `Substituting $x=0$, $y-${Math.round(y1 * 100) / 100}=${
          Math.round(m * 100) / 100
        }\\left(0-${MCMaths.cleaner(`${coordinate}`)}\\right)$`,
        `$y=${Math.round(c * 100) / 100}$, to 2dp.`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(Math.round(m * 100) / 100, 0.01, "a)");
      group.addInput(Math.round(c * 100) / 100, 0.01, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ChainRule_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const funcList = [
      new MCMaths.Trig(1, "sin", 1, 1),
      new MCMaths.Trig(1, "cos", 1, 1),
      new MCMaths.Trig(1, "tan", 1, 1),
      new MCMaths.Exponential(1, "e", 1),
      new MCMaths.Logarithm(),
    ];
    const variList = [
      new MCMaths.Trig(1, "sin", 1, MCRandom.randomInt(1, 4)),
      new MCMaths.Trig(1, "cos", 1, MCRandom.randomInt(1, 4)),
      new MCMaths.Trig(1, "tan", 1, MCRandom.randomInt(1, 4)),
      new MCMaths.Exponential(
        1,
        "e",
        MCRandom.randomInt(1, 4),
        "x",
        MCRandom.randomInt(1, 5)
      ),
      new MCMaths.Logarithm(),
      new MCMaths.Polynomial([
        MCRandom.randomInt(1, 2),
        0,
        0,
        MCRandom.randomInt(1, 4),
        MCRandom.randomInt(1, 4),
      ]),
      new MCMaths.Polynomial([
        MCRandom.randomInt(1, 2),
        0,
        MCRandom.randomInt(1, 4),
        MCRandom.randomInt(1, 4),
      ]),
    ];
    const func = funcList[MCRandom.randomInt(0, funcList.length - 1)];
    const vari = variList[MCRandom.randomInt(0, variList.length - 1)];
    const chain = new MCMaths.ChainRule(func, vari);
    const coordinate =
      MCRandom.randomInt(1, 5) *
      MCRandom.randomInt(-1, 1, 2) *
      Math.PI ** MCRandom.randomInt(0, 1) *
      0.1 ** MCRandom.randomInt(0, 1);
    let chainString = chain.toString();
    if (chainString.includes("\\left(\\left(")) {
      chainString = chainString
        .replace("\\left(\\left(", "\\left(")
        .replace("\\right)\\right)", "\\right)");
    }
    const m = chain.evaluate(coordinate);
    const y1 = func.evaluate(vari.evaluate(coordinate));
    const c = func.evaluate(vari.evaluate(coordinate)) - m * coordinate;
    /* END VARIABLES AND CONSTANTS */
    if (
      !Number.isNaN(Number(c)) &&
      Math.abs(m) < 100 &&
      Math.abs(m) > 0.01 &&
      Math.abs(c) < 100 &&
      Math.abs(c) > 0.01 &&
      !MCMaths.cleaner(
        chain.derivative().replace(/x/g, `\\left(${coordinate}\\right)`)
      ).includes("\\right)\\right)\\right)\\right)") &&
      !chainString.includes(
        "ln\\left(e"
      ) /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given $y=${chainString}$, find the equation of line tangent to it at $x=${MCMaths.cleaner(
          `${coordinate}`
        )}$, in the form $y=mx+c$, correct to 2dp where applicable.`,
      ]);
      question.addMultipleParagraphs("solution", chain.derivativeWorking());
      question.addMultipleParagraphs("solution", [
        `HEADING Substituting $x=${MCMaths.cleaner(`${coordinate}`)}$,`,
        `$m=${MCMaths.cleaner(
          chain.derivative().replace(/x/g, `\\left(${coordinate}\\right)`)
        )}$`,
        `$=${Math.round(m * 100) / 100}$`,
        `HEADING Using the line equation:`,
        `$y-y\\left(${MCMaths.cleaner(`${coordinate}`)}\\right)= ${
          Math.round(m * 100) / 100
        }\\left(x${MCMaths.cleaner(`-${coordinate}`)}\\right)$`,
        `$y\\left(${MCMaths.cleaner(`${coordinate}`)}\\right)= ${
          Math.round(y1 * 100) / 100
        }$`,
        `$\\therefore y = ${MCMaths.cleaner(
          `${Math.round(m * 100) / 100}x+${Math.round(c * 100) / 100}`,
          false,
          false
        )}$`,
      ]);
      const group = new MCQuestion.InputGroup(2);
      group.addInput(Math.round(m * 100) / 100, 0.01, "m=");
      group.addInput(Math.round(c * 100) / 100, 0.01, "c=");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ChainRule_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const variList = [
      new MCMaths.Trig(1, "sin", 1, MCRandom.randomInt(1, 4)),
      new MCMaths.Trig(1, "cos", 1, MCRandom.randomInt(1, 4)),
      new MCMaths.Trig(1, "tan", 1, MCRandom.randomInt(1, 4)),
      new MCMaths.Exponential(
        1,
        "e",
        MCRandom.randomInt(1, 4),
        "x",
        MCRandom.randomInt(1, 5)
      ),
      new MCMaths.Logarithm(),
      new MCMaths.Polynomial([MCRandom.randomInt(1, 2), 0, 0, 0, 0]),
      new MCMaths.Polynomial([MCRandom.randomInt(1, 2), 0, 0, 0]),
      new MCMaths.Polynomial([MCRandom.randomInt(1, 2), 0, 0]),
    ];
    const type1 = MCRandom.randomInt(0, variList.length - 1);
    const type2 = MCRandom.randomInt(0, variList.length - 1);
    const func1 = variList[type1];
    const func2 = variList[type2];
    const fullFunc = `${func1
      .toString()
      .replace(/x/g, "y")}+${func2.toString().replace(/x/g, "y")}`;
    const coordinate =
      MCRandom.randomInt(1, 5) *
      MCRandom.randomInt(-1, 1, 2) *
      Math.PI ** MCRandom.randomInt(0, 1) *
      0.1 ** MCRandom.randomInt(0, 1);
    const ans =
      Math.round(
        100 /
          (func1.derivative().evaluate(coordinate) +
            func2.derivative().evaluate(coordinate))
      ) / 100;

    /* END VARIABLES AND CONSTANTS */
    if (
      type1 !== type2 &&
      Math.abs(ans) > 0.1 &&
      Math.abs(ans) < 20 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given $x=${fullFunc}$, find $\\frac{dy}{dx}$ at $y=${MCMaths.cleaner(
          `${coordinate}`
        )}$, correct to 2dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Deriving with respect to $y$:`,
        `$1=${func1
          .derivative()
          .toString()
          .replace(
            /x/g,
            "y"
          )}\\frac{dy}{dx} + ${func2
          .derivative()
          .toString()
          .replace(/x/g, "y")}\\frac{dy}{dx}$`,
        `$\\frac{dy}{dx}=\\frac{1}{${MCMaths.cleaner(
          `${func1
            .derivative()
            .toString()
            .replace(/x/g, "y")}+${func2
            .derivative()
            .toString()
            .replace(/x/g, "y")}`
        )}}$`,
        `HEADING Substituting $y=${MCMaths.cleaner(`${coordinate}`)}$,`,
        `$=\\frac{1}{${MCMaths.cleaner(
          `${func1
            .derivative()
            .toString()
            .replace(/x/g, "y")}+${func2
            .derivative()
            .toString()
            .replace(/x/g, "y")}`
        )}}$`.replace(
          /y/g,
          `\\left(${MCMaths.cleaner(`${coordinate}`)}\\right)`
        ),
        `$=${ans}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans, 0.01);
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_FunctionDerivatives_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a_sin = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);
    const a_cos = MCRandom.randomInt(1, 8);
    const b_cos = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);

    const k = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const d = MCRandom.randomInt(1, 6);
    const n = MCRandom.randomInt(0, 3 * d);

    const m =
      ((k * a_sin) / b_cos) *
        (1 / Math.cos((k * Math.PI * n) / d)) *
        (1 / Math.cos((k * Math.PI * n) / d)) +
      a_cos / b_cos;

    /* END VARIABLES AND CONSTANTS */
    if (
      d !== 5 &&
      (n / d) % 0.5 !== 0 &&
      (n / d) % 1 !== 0 &&
      parseFloat(m.toFixed(3)) === m &&
      m - 1 !== m
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph("question", `A curve $C$ has equation`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$y=\\frac{${a_sin}\\sin(${k}x)+${a_cos}x\\cos(${k}x)}{${b_cos}\\cos(${k}x)}$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `a) Find $\\frac{dy}{dx}$ in the form $A\\sec^2(${k}x)+B$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `b) Hence, find the gradient of $C$ at the point $P$ where $x=${
            n / d
          }\\pi$`
        )
      );
      // solution
      // a
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `a) Find $\\frac{dy}{dx}$ in the form $A\\sec^2(${k}x)+B$`
        )
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `$y=\\frac{${a_sin}\\sin(${k}x)+${a_cos}x\\cos(${k}x)}{${b_cos}\\cos(${k}x)}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\frac{${a_sin}\\sin(${k}x)}{${b_cos}\\cos(${k}x)}+\\frac{${a_cos}x\\cos(${k}x)}{${b_cos}\\cos(${k}x)}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${a_sin / b_cos}\\tan(${k}x)+${a_cos / b_cos}x$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore \\frac{dy}{dx}=${(k * a_sin) / b_cos}\\sec^2(${k}x)+${
            a_cos / b_cos
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore A=${(k * a_sin) / b_cos}, B=${a_cos / b_cos}$`
        )
      );
      // b
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `b) Hence, find the gradient of $C$ at the point $P$ where $x=${
            n / d
          }\\pi$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{dy}{dx}=${(k * a_sin) / b_cos}\\sec^2(${k}x)+${
            a_cos / b_cos
          }$`
        )
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given $x=${n / d}\\pi$ at $P$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{dy}{dx}=${(k * a_sin) / b_cos}\\sec^2(${(k * n) / d}\\pi)+${
            a_cos / b_cos
          }$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${m}$`));
      // answer
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput((k * a_sin) / b_cos, 0, "a) $\\ A = $");
      group1.addInput(a_cos / b_cos, 0, "a) $\\ B = $");
      question.addInputGroup(group1);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(m, 0, "b)");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_FunctionDerivatives_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const c1 = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const dln = MCRandom.randomInt(2, 5);
    const cln =
      (MCRandom.randomInt(1, 2 * dln, 2) / dln) * MCRandom.randomInt(-1, 1, 2);

    const a = MCRandom.randomInt(2, 5);
    const k = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);

    const dx = MCRandom.randomInt(2, 5);
    const x =
      (MCRandom.randomInt(1, 2 * dx, 2) / dx) * MCRandom.randomInt(-1, 1, 2);

    const b_ans = c1 * k * a ** (k * x) * Math.log(a) + cln * (1 / x);
    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(b_ans) > 1 && Math.abs(b_ans) < 50) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$f(x)=${c1} \\times ${a}^{${k}x}+${cln}\\ln(x)$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `a) Find $f'(x)$ in the form $A \\times ${a}^{Bx}\\ln(${a}) + Cx^{-1}$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `b) Hence, evaluate $f'(${x})$, giving your answer to $2$ decimal places`
        )
      );
      // solution
      // a
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `a) Find $f'(x)$ in the form $A \\times ${a}^{${k}x}\\ln(B) + Cx^{-1}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f(x)=${c1} \\times ${a}^{${k}x}+${cln}\\ln(x)$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$f'(x)=${c1}[${k} \\times ${a}^{${k}x}\\ln(${a})]+${cln}(\\frac{1}{x})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=${c1 * k} \\times ${a}^{${k}x}\\ln(${a})+${cln}x^{-1}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore A=${c1 * k}, B=${k}$ and $C=${cln}$`)
      );
      // b
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `b) Hence, evaluate $f'(${x})$, giving your answer to $2$ decimal places`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$f'(x)=${c1 * k} \\times ${a}^{${k}x}\\ln(${a})+${cln}x^{-1}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$f'(${x})=${
            c1 * k
          } \\times ${a}^{${k}(${x})}\\ln(${a})+${cln}(${x})^{-1}$`
        )
      );
      question.addParagraph("solution", `$=${b_ans.toFixed(4)}...$`);
      question.addParagraph("solution", `$\\approx ${b_ans.toFixed(2)}$`);
      // answer
      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput(c1 * k, 0, "a) $\\ A = $");
      group1.addInput(k, 0, "a) $\\ B = $");
      group1.addInput(cln, 0, "a) $\\ C = $");
      question.addInputGroup(group1);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(parseFloat(b_ans.toFixed(2)), 0, "b)");
      question.addInputGroup(group2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_FunctionDerivatives_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const trig = ["\\sin", "\\cos"][MCRandom.randomInt(0, 1)];

    const d = MCRandom.randomInt(1, 5);
    const n = MCRandom.randomInt(1, 2 * d) * MCRandom.randomInt(-1, 1, 2);
    const c = n / d;
    const k = MCRandom.randomInt(2, 3);

    const x_coeff = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);

    const dx = MCRandom.randomInt(1, 6);
    const nx = MCRandom.randomInt(0, Math.ceil((2 * dx) / k) - 1);
    const x = nx / dx;

    const dxb = MCRandom.randomInt(1, 6);
    const nxb = MCRandom.randomInt(0, Math.floor((2 * dxb) / k));
    const xb = nxb / dxb;

    let a_tmp = 0;
    let mb_tmp = 0;
    if (trig === "\\sin") {
      a_tmp = Math.sin(k * x * Math.PI);
      mb_tmp = k * c * Math.cos(xb * k * Math.PI) + x_coeff;
    } else {
      a_tmp = Math.cos(k * x * Math.PI);
      mb_tmp = -k * c * Math.sin(xb * k * Math.PI) + x_coeff;
    }
    const a = a_tmp;
    const mb = mb_tmp;

    const b_anstmp = [0, 0];
    if (trig === "\\sin") {
      b_anstmp[0] = Math.acos((mb - x_coeff) / (c * k));
      if (b_anstmp[0] > 0) {
        b_anstmp[1] = -b_anstmp[0] + 2 * Math.PI;
      } else {
        b_anstmp[0] = -b_anstmp[0];
        b_anstmp[1] = -b_anstmp[0] + 2 * Math.pi;
      }
    } else {
      b_anstmp[0] = Math.asin((mb - x_coeff) / (-c * k));
      if (b_anstmp[0] > 0) {
        b_anstmp[1] = -b_anstmp[0] + Math.PI;
      } else {
        b_anstmp[1] = -b_anstmp[0] - Math.PI;
        b_anstmp[0] += 2 * Math.PI;
        b_anstmp[1] += 2 * Math.PI;
      }
    }
    const b_ans = b_anstmp;
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat((c * a).toFixed(3)) === c * a &&
      x !== 0 &&
      x < 2 / k &&
      xb !== 0 &&
      xb < 2 / k &&
      parseFloat(mb.toFixed(3)) === mb &&
      (parseFloat(b_ans[0] / Math.PI) * 12) % 1 === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        `The gradient of a curve $C$ is calculated using the equation`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$\\frac{dy}{dx}=${c}${trig}(${k}x)+${x_coeff}x, 0 < x \\leq ${
            2 / k
          }\\pi$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `a) Find $\\frac{dy}{dx}$ when $x=${x}\\pi$ in the form $A+B\\pi$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `b) Find the smallest possible value of $x$ when the rate of change of gradient of $C$ is $${mb}$`
        )
      );
      question.addParagraph(
        "question",
        `Give your answer in the form $p\\pi$ where p is a constant`
      );
      // solution
      // a
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `a) Find $\\frac{dy}{dx}$ when $x=${x}\\pi$ in the form $A+B\\pi$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{dy}{dx}=${c}${trig}(${k}x)+${x_coeff}x$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{dy}{dx}|_{x=${x}\\pi}=${c}${trig}(${
            k * x
          }\\pi)+${x_coeff}(${x}\\pi)$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${c * a}+${x_coeff * x}\\pi$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore A=${c * a}, B=${x_coeff * x}$`)
      );
      // b
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `b) Find the smallest possible value of $x$ when the rate of change of gradient of $C$ is $${mb}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{dy}{dx}=${c}${trig}(${k}x)+${x_coeff}x$`)
      );
      if (trig === "\\sin") {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\therefore \\frac{d^{\\ 2}y}{dx^{2}}=${
              c * k
            }\\cos(${k}x)+${x_coeff}$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$${c * k}\\cos(${k}x)+${x_coeff}=${mb}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\cos(${k}x)=${(mb - x_coeff) / (c * k)}$`)
        );
        if (b_ans[0] === b_ans[1]) {
          question.addParagraph(
            "solution",
            MCMaths.cleaner(`$${k}x=${b_ans[0]}$`)
          );
          question.addHeading(
            "solution",
            MCMaths.cleaner(`$\\therefore$ smallest $x=${b_ans[0] / k}$`)
          );
        } else {
          question.addParagraph(
            "solution",
            MCMaths.cleaner(`$${k}x=${b_ans}$`)
          );
          question.addParagraph(
            "solution",
            MCMaths.cleaner(`$x=${b_ans[0] / k},${b_ans[1] / k}$`)
          );
          question.addHeading(
            "solution",
            MCMaths.cleaner(`$\\therefore$ smallest $x=${b_ans[0] / k}$`)
          );
        }
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\therefore \\frac{d^{\\ 2}y}{dx^{2}}=${
              -c * k
            }\\sin(${k}x)+${x_coeff}$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$${-c * k}\\sin(${k}x)+${x_coeff}=${mb}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\sin(${k}x)=${(mb - x_coeff) / (-c * k)}$`)
        );
        if (b_ans[0] === b_ans[1]) {
          question.addParagraph(
            "solution",
            MCMaths.cleaner(`$${k}x=${b_ans[0]}$`)
          );
          question.addHeading(
            "solution",
            MCMaths.cleaner(`$\\therefore$ smallest $x=${b_ans[0] / k}$`)
          );
        } else {
          question.addParagraph(
            "solution",
            MCMaths.cleaner(`$${k}x=${b_ans[1]},${b_ans[0]}$`)
          );
          question.addParagraph(
            "solution",
            MCMaths.cleaner(`$x=${b_ans[1] / k},${b_ans[0] / k}$`)
          );
          question.addHeading(
            "solution",
            MCMaths.cleaner(`$\\therefore$ smallest $x=${b_ans[1] / k}$`)
          );
        }
      }
      // answers
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(parseFloat(c * a), 0, "a) $\\ A = $");
      group1.addInput(parseFloat(x_coeff * x), 0, "a) $\\ B = $");
      question.addInputGroup(group1);

      if (b_ans[0] === b_ans[1]) {
        const group2 = new MCQuestion.InputGroup(1);
        group2.addInput(b_ans[0] / (Math.PI * k), 0.1, "b) $\\ p=$");
        question.addInputGroup(group2);
      } else {
        const group2 = new MCQuestion.InputGroup(2);
        if (b_ans[0] < b_ans[1]) {
          group2.addInput(b_ans[0], 0.1, "b) $\\ p=$");
        } else {
          group2.addInput(b_ans[1], 0.1, "b) $\\ p=$");
        }
        question.addInputGroup(group2);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_FunctionDerivatives_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const da = MCRandom.randomInt(1, 5);
    const a = MCRandom.randomInt(1, 2 * da);
    const k = 1 / (MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2));

    // const b = MCRandom.randomInt(1, 8);

    const dv = MCRandom.randomInt(1, 4);
    const v = MCRandom.randomInt(1, 2 * da) ** (-1 / k); // subbed in as ln(v)

    const d1 = k * a * Math.E ** (k * Math.log(v)); // + b;
    const d2 = k * k * a * Math.E ** (k * Math.log(v));

    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(v) < 100 && Math.abs(1 / v) < 100 && v !== 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        `question`,
        MCMaths.cleaner(
          `$f(x)=ae^{kx}$, where $a$ and $k$ are constants and $a>0$`
        )
      );
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`Given $f'(\\ln${v})=${d1}$ and $f''(\\ln${v})=${d2}$`)
      );
      question.addParagraph(`question`, `Find $a$ and $k$`);
      // solution
      question.addHeading(`solution`, `Find $a$ and $k$`);
      question.addParagraph(`solution`, MCMaths.cleaner(`$f(x)=ae^{kx}$`));
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore f'(x)=kae^{kx}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore f''(x)=k^2ae^{kx}$`)
      );
      question.addHeading(`solution`, MCMaths.cleaner(`Let $x=\\ln${v}$`));
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$f'(\\ln${v})=kae^{k\\ln${v}}$`)
      );
      question.addParagraph(`solution`, MCMaths.cleaner(`$=ka(${v})^k$`));
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore ka(${v})^k=${d1}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$f''(\\ln${v})=k^2ae^{k\\ln${v}}$`)
      );
      question.addParagraph(`solution`, MCMaths.cleaner(`$=k^2a(${v})^k$`));
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore k^2a(${v})^k=${d2}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$\\frac{k^2a(${v})^k}{ka(${v})^k}=\\frac{${d2}}{${d1}}$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore k=${d2 / d1}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${k}a(${v})^{${k}}=${d1}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore a=${a}$`)
      );
      // answer
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(a, 0, "$\\ a = $");
      group1.addInput(k, 0, "$\\ k = $");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_FunctionDerivatives_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const trig = ["\\sin", "\\cos", "\\tan"][MCRandom.randomInt(0, 2)];
    const a = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);

    const k = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(MCMaths.cleaner(`$f(x)=${a}${trig}(${k}x)$`));
      question.addParagraph(`Find $f'(x)$`);

      if (trig === "\\sin") {
        const a1 = new MCQuestion.MultipleChoiceAnswer();
        a1.addParagraph(MCMaths.cleaner(`$f'(x)=${a * k}\\cos(${k}x)$`));

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        a2.addParagraph(MCMaths.cleaner(`$f'(x)=${-a * k}\\cos(${k}x)$`));

        const a3 = new MCQuestion.MultipleChoiceAnswer();
        a3.addParagraph(MCMaths.cleaner(`$f'(x)=${a}\\cos(${k}x)$`));

        const a4 = new MCQuestion.MultipleChoiceAnswer();
        a4.addParagraph(MCMaths.cleaner(`$f'(x)=${a * k}\\cos(${k - 1}x)$`));

        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      } else if (trig === "\\cos") {
        const a1 = new MCQuestion.MultipleChoiceAnswer();
        a1.addParagraph(MCMaths.cleaner(`$f'(x)=${-a * k}\\sin(${k}x)$`));

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        a2.addParagraph(MCMaths.cleaner(`$f'(x)=${a * k}\\sin(${k}x)$`));

        const a3 = new MCQuestion.MultipleChoiceAnswer();
        a3.addParagraph(MCMaths.cleaner(`$f'(x)=${-a}\\sin(${k}x)$`));

        const a4 = new MCQuestion.MultipleChoiceAnswer();
        a4.addParagraph(MCMaths.cleaner(`$f'(x)=${-a * k}\\sin(${k - 1}x)$`));

        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      } else {
        const a1 = new MCQuestion.MultipleChoiceAnswer();
        if (MCRandom.randomInt(0, 1) === 0) {
          a1.addParagraph(MCMaths.cleaner(`$f'(x)=${a * k}\\sec^2(${k}x)$`));
        } else {
          a1.addParagraph(
            MCMaths.cleaner(`$f'(x)=\\frac{${a * k}}{\\cos^2(${k}x)}$`)
          );
        }

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        if (a * k > 0) {
          a2.addParagraph(
            MCMaths.cleaner(`$f'(x)=\\frac{1}{${a * k}\\cos^2(${k}x)}$`)
          );
        } else {
          a2.addParagraph(
            MCMaths.cleaner(`$f'(x)=\\frac{-1}{${-a * k}\\cos^2(${k}x)}$`)
          );
        }

        const a3 = new MCQuestion.MultipleChoiceAnswer();
        a3.addParagraph(MCMaths.cleaner(`$f'(x)=${a * k}\\sec(${k}x)$`));

        const a4 = new MCQuestion.MultipleChoiceAnswer();
        if (MCRandom.randomInt(0, 1) === 0) {
          a4.addParagraph(MCMaths.cleaner(`$f'(x)=${a}\\sec^2(${k}x)$`));
        } else {
          a4.addParagraph(
            MCMaths.cleaner(`$f'(x)=\\frac{${a}}{\\cos^2(${k}x)}$`)
          );
        }

        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_FunctionDerivatives_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(2, 6);
    const k = MCRandom.randomInt(2, 6);
    /* END VARIABLES AND CONSTANTS */
    if (a !== k) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(MCMaths.cleaner(`$y=${a}^{${k}x}$`));
      question.addParagraph(
        `Choose the correct expression for $\\frac{dy}{dx}$`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(
        MCMaths.cleaner(`$\\frac{dy}{dx}=${k}\\times ${a}^{${k}x}\\ln(${a}) $`)
      );

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(
        MCMaths.cleaner(`$\\frac{dy}{dx}=${k}\\times ${a}^{${k}x}\\ln(${k}) $`)
      );

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(
        MCMaths.cleaner(`$\\frac{dy}{dx}=${k}x\\times ${a}^{${k}x-1}$`)
      );

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(
        MCMaths.cleaner(
          `$\\frac{dy}{dx}=${k * a}^{${k}x}\\ln(${
            [a, k][MCRandom.randomInt(0, 1)]
          })$`
        )
      );

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_FunctionDerivatives_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const given = ["y", "\\frac{dy}{dx}"][MCRandom.randomInt(0, 1)];

    const ae = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const ke = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);

    const aln = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const kln = MCRandom.randomInt(2, 4) * MCRandom.randomInt(-1, 1, 2);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        MCMaths.cleaner(`$${given}=${ae}e^{${ke}x}+${aln}\\ln(${kln}x)$`)
      );
      question.addParagraph(`Find $\\frac{d^{\\ 2}y}{dx^2}$`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      // 1 time derived
      if (MCRandom.randomInt(0, 1) === 0) {
        if (aln > 0) {
          a1.addParagraph(
            MCMaths.cleaner(`$${ae * ke}e^{${ke}x}+\\frac{${aln}}{x}$`)
          );
        } else {
          a1.addParagraph(
            MCMaths.cleaner(`$${ae * ke}e^{${ke}x}-\\frac{${-aln}}{x}$`)
          );
        }
      } else {
        a1.addParagraph(MCMaths.cleaner(`$${ae * ke}e^{${ke}x}+${aln}x^{-1}$`));
      }
      // 2 time derived
      const a2 = new MCQuestion.MultipleChoiceAnswer();
      if (MCRandom.randomInt(0, 1) === 0) {
        if (aln < 0) {
          a2.addParagraph(
            MCMaths.cleaner(`$${ae * ke ** 2}e^{${ke}x}+\\frac{${-aln}}{x^2}$`)
          );
        } else {
          a2.addParagraph(
            MCMaths.cleaner(`$${ae * ke ** 2}e^{${ke}x}-\\frac{${aln}}{x^2}$`)
          );
        }
      } else {
        a2.addParagraph(
          MCMaths.cleaner(`$${ae * ke * ke}e^{${ke}x}+${-aln}x^{-2}$`)
        );
      }

      if (given === "y") {
        // 2nd derivative mistakes
        const a3 = new MCQuestion.MultipleChoiceAnswer();
        if (MCRandom.randomInt(0, 1) === 0) {
          if (aln < 0) {
            a3.addParagraph(
              MCMaths.cleaner(
                `$${ae * ke ** 2}e^{${ke}x}+\\frac{${-aln * kln ** 2}}{x^2}$`
              )
            );
          } else {
            a3.addParagraph(
              MCMaths.cleaner(
                `$${ae * ke ** 2}e^{${ke}x}-\\frac{${aln * kln ** 2}}{x^2}$`
              )
            );
          }
        } else {
          a3.addParagraph(
            MCMaths.cleaner(
              `$${ae * ke * ke}e^{${ke}x}+${-aln * kln ** 2}x^{-2}$`
            )
          );
        }

        const a4 = new MCQuestion.MultipleChoiceAnswer();
        if (aln < 0) {
          a4.addParagraph(
            MCMaths.cleaner(`$${ae * ke ** 2}e^{${ke}x}+\\frac{1}{${-aln}x^2}$`)
          );
        } else {
          a4.addParagraph(
            MCMaths.cleaner(`$${ae * ke ** 2}e^{${ke}x}-\\frac{1}{${aln}x^2}$`)
          );
        }

        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      } else {
        // first derivative mistakes
        const a3 = new MCQuestion.MultipleChoiceAnswer();
        if (MCRandom.randomInt(0, 1) === 0) {
          if (aln * kln > 0) {
            a3.addParagraph(
              MCMaths.cleaner(`$${ae * ke}e^{${ke}x}+\\frac{${aln * kln}}{x}$`)
            );
          } else {
            a3.addParagraph(
              MCMaths.cleaner(`$${ae * ke}e^{${ke}x}-\\frac{${-aln * kln}}{x}$`)
            );
          }
        } else {
          a3.addParagraph(
            MCMaths.cleaner(`$${ae * ke}e^{${ke}x}+${aln * kln}x^{-1}$`)
          );
        }

        const a4 = new MCQuestion.MultipleChoiceAnswer();
        if (aln * kln > 0) {
          a4.addParagraph(
            MCMaths.cleaner(`$${ae * ke}e^{${ke}x}+\\frac{1}{${aln * kln}x}$`)
          );
        } else {
          a4.addParagraph(
            MCMaths.cleaner(`$${ae * ke}e^{${ke}x}-\\frac{1}{${-aln * kln}x}$`)
          );
        }

        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_MaximaAndMinima_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const xTerm = MCRandom.randomInt(5, 15);
    const xSquaredTerm = -(MCRandom.randomInt(15, 30) / 100);

    const p1 = new MCMaths.Polynomial([xSquaredTerm, xTerm, 0], "x");
    const p2 = p1.derivative();
    const p2Coefficients = p2.getCoefficients();
    const optimalX = p2Coefficients[1] / -p2Coefficients[0];

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The profit of a steel works on a monthly basis is given by the equation:`
      );
      question.addParagraph("question", `$P = ${p1.toString()}$`);
      question.addParagraph(
        "question",
        `Where $x$ is the amount of steel produced in metric tonnes.`
      );
      question.addParagraph(
        "question",
        `What is the optimal amount of steel to produce on a monthly basis to maximise profits?`
      );
      question.addParagraph(
        "question",
        `Give your answer to $3$ significant figures.`
      );

      question.addHeading(
        "solution",
        `In order to find the optimal amount of steel we find the derivative of P:`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{dP}{dx} = ${p2.toString()}$`
      );
      question.addHeading(
        "solution",
        `Then to find where the curve stops providing an increase in profit for an increase in steel produced we find when $\\displaystyle \\frac{dP}{dx} = 0$.`
      );
      question.addParagraph("solution", `$0 = ${p2.toString()}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${-p2Coefficients[0]}x = ${p2Coefficients[1]}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle x = \\frac{${
            p2Coefficients[1]
          }}{\\left(${-p2Coefficients[0]}\\right)}$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore x = ${optimalX.toPrecision(3)}$ metric tonnes.`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(optimalX.toPrecision(3)),
        Math.abs(
          parseFloat(optimalX.toPrecision(3)) -
            parseFloat(optimalX.toPrecision(2))
        ),
        `$x =$`
      );
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_MaximaAndMinima_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(2, 5);
    const b = -MCRandom.randomInt(2, 9);
    const c = MCRandom.randomInt(2, 9);

    const p1 = new MCMaths.Polynomial([a, b, 0, c], "x");
    const p2 = p1.derivative();
    const p2Coefficients = p2.getCoefficients();
    const p3 = p2.derivative();
    const p3Coefficients = p3.getCoefficients();

    const x1 = 0;
    const x2 = new MCMaths.Fraction(-p2Coefficients[1] / p2Coefficients[0]);

    const working1 = p3.evaluate(x2.toFloat());

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given the curve with the equation:`);
      question.addParagraph("question", `$y = ${p1.toString()}$`);
      question.addParagraph(
        "question",
        `a) Find the value of $x$ at which the curve is a maximum stationary point.`
      );
      question.addParagraph(
        "question",
        `b) Find the value of $x$ at which the curve is a minimum stationary point.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures where appropriate.`
      );

      question.addHeading(
        "solution",
        `In order to find stationary points we differentiate the equation and set $\\displaystyle \\frac{dy}{dx} = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{dy}{dx} = ${p2.toString()}$`
      );
      question.addParagraph("solution", `$0 = ${p2.toString()}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x(${p2Coefficients[0]}x+${p2Coefficients[1]}) = 0$`)
      );
      question.addParagraph(
        "solution",
        `Therefore the stationary points are at $x = ${x1}, \\text{ } x = ${x2.toString()}$`
      );
      question.addHeading(
        "solution",
        `In order to find whether a stationary point is a maximum or minimum we need to take the second derivative of the curve:`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{d^{2}y}{dx^{2}} = ${p3.toString()}$`
      );

      question.addParagraph("solution", `a) When $x = 0$,`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\frac{d^{2}y}{dx^{2}} = ${p3Coefficients[0]}(0)+${p3Coefficients[1]}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$= ${p3Coefficients[1]}$`)
      );
      question.addParagraph(
        "solution",
        `Which is $\\lt 0$ therefore at $x = 0$ the curve is at a maximum stationary point.`
      );

      question.addParagraph("solution", `b) When $x = ${x2.toString()}$,`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\frac{d^{2}y}{dx^{2}} = ${
            p3Coefficients[0]
          }\\left(${x2.toString()}\\right)+${p3Coefficients[1]}$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$= ${working1}$`));
      question.addParagraph(
        "solution",
        `Which is $\\gt 0$ therefore at $x = ${x2.toString()}$ the curve is at a minimum stationary point.`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(x1, 0, `a) $\\text{ } x =$`);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(x2.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(x2.toFloat().toPrecision(3)) -
            parseFloat(x2.toFloat().toPrecision(2))
        ),
        `b) $\\text{ } x =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_MaximaAndMinima_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = -MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(2, 15);
    const c = MCRandom.randomInt(2, 9);

    const p1 = new MCMaths.Polynomial([a, b, c], "t");
    const p1Coefficients = p1.getCoefficients();
    const p2 = p1.derivative();
    const p2Coefficients = p2.getCoefficients();

    const t = new MCMaths.Fraction(p2Coefficients[1] / -p2Coefficients[0]);
    const maxHeight = p1.evaluate(t.toFloat());

    const check1 = new MCMaths.Fraction(maxHeight);

    /* END VARIABLES AND CONSTANTS */
    if (`${check1.numerator}`.length <= 3) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A projectile is launched from a box of height $${c}m$.`
      );
      question.addParagraph(
        "question",
        `At time $t > 0$ the height, in metres, of the projectile $h$, can be expressed with the equation:`
      );
      question.addParagraph("question", `$h(t) = ${p1.toString()}$`);
      question.addParagraph(
        "question",
        `a) Find the time, $t_0$, at which the projectile reaches its maximum height.`
      );
      question.addParagraph(
        "question",
        `b) Find the maximum height, $H_0$, that the projectile reaches.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures where appropriate.`
      );

      question.addHeading(
        "solution",
        `a) In order to find when the projectile stops gaining height we need to find when derivative is zero.`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{dh}{dt} = ${p2.toString()}$`
      );
      question.addParagraph("solution", `$0 = ${p2.toString()}$`);
      question.addParagraph(
        "solution",
        `$${-p2Coefficients[0]}t = ${p2Coefficients[1]}$`
      );
      question.addParagraph("solution", `$t = ${t.toString()}$`);
      question.addParagraph(
        "solution",
        `$\\therefore t_0 = ${t.toFloat().toPrecision(3)}s$`
      );

      question.addHeading(
        "solution",
        `b) To find the maximum height of the projectile we need to substitute $t = ${t.toString()}$ into the original equation:`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$h\\left(${t.toString()}\\right) = ${
            p1Coefficients[0]
          }\\left(${t.toString()}\\right)^{2}+${
            p1Coefficients[1]
          }\\left(${t.toString()}\\right)+${p1Coefficients[2]}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$h\\left(${t.toString()}\\right) = ${maxHeight}$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore H_0 = ${maxHeight.toPrecision(3)}m$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(t.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(t.toFloat().toPrecision(3)) -
            parseFloat(t.toFloat().toPrecision(2))
        ),
        `a) $\\text{ } t_0 =$`
      );
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(maxHeight.toPrecision(3)),
        Math.abs(
          parseFloat(maxHeight.toPrecision(3)) -
            parseFloat(maxHeight.toPrecision(2))
        ),
        `b) $\\text{ } H_0 =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_MaximaAndMinima_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const perimeter = MCRandom.randomInt(10, 30) * 10;
    const p1 = new MCMaths.Polynomial([-1, perimeter / 2, 0], "x");
    const p1Coefficients = p1.getCoefficients();
    const p2 = p1.derivative();
    const p2Coefficients = p2.getCoefficients();

    const x0 = new MCMaths.Fraction(p2Coefficients[1] / -p2Coefficients[0]);
    const A_0 = p1.evaluate(x0.toFloat());

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Given a rectangle with width $x$ and height $y$ and perimeter $${perimeter}m$`
      );
      question.addParagraph(
        "question",
        `Find the maximum area, $A_0$, of the rectangle.`
      );

      question.addParagraph(
        "solution",
        `Since the perimeter of the rectangle is $${perimeter}m$`
      );
      question.addParagraph("solution", `$2x + 2y = ${perimeter}$`);
      question.addParagraph("solution", `$\\implies y = ${perimeter / 2} - x$`);
      question.addParagraph(
        "solution",
        `Since area is given by the equation $A = xy$`
      );
      question.addParagraph("solution", `$\\implies A(x) = ${p1.toString()}$`);
      question.addParagraph(
        "solution",
        `In order to find the maximum area we need to find the value of $x$ which maximises $A(x)$.`
      );
      question.addParagraph(
        "solution",
        `To do this we find the derivative of $A(x)$ and set it to zero:`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{dA}{dx} = ${p2.toString()}$`
      );
      question.addParagraph("solution", `$0 = ${p2.toString()}$`);
      question.addParagraph(
        "solution",
        `$${-p2Coefficients[0]}x = ${p2Coefficients[1]}$`
      );
      question.addParagraph("solution", `$x = ${x0.toString()}$`);
      question.addParagraph(
        "solution",
        `Then substituting this value into the equation for area we obtain:`
      );
      question.addParagraph(
        "solution",
        `$A\\left(${x0.toString()}\\right) = -\\left(${x0.toString()}\\right)^2+${
          p1Coefficients[1]
        }\\left(${x0.toString()}\\right)$`
      );
      question.addParagraph("solution", MCMaths.cleaner(`$= ${A_0}$`));
      question.addParagraph("solution", `$\\therefore A_0 = ${A_0}m^2$`);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(A_0, 0, `$A_0 =$`);
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_MaximaAndMinima_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = -MCRandom.randomInt(2, 6) / 10;
    const b = MCRandom.randomInt(2, 12);

    const p1 = new MCMaths.Polynomial([a, b, 0], "t");
    const p1Coefficients = p1.getCoefficients();
    const p2 = p1.derivative();
    const p2Coefficients = p2.getCoefficients();
    const p3 = p2.derivative();
    const p3Coefficients = p3.getCoefficients();

    const t = new MCMaths.Fraction(p2Coefficients[1] / -p2Coefficients[0]);
    const maxVelocity = p1.evaluate(t.toFloat());

    const check1 = new MCMaths.Fraction(maxVelocity);

    /* END VARIABLES AND CONSTANTS */
    if (
      `${check1.numerator}`.length <= 3 &&
      `${t.numerator}`.length <= 3 &&
      maxVelocity < 105 &&
      maxVelocity > 60
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A formula-1 car is being tested to find its maximum speed.`
      );
      question.addParagraph(
        "question",
        `The velocity, in $ms^{-1}$, of the formula-1 car between the start and finish line can be modelled by the equation:`
      );
      question.addParagraph(
        "question",
        `$v = ${p1.toString()}, \\text{ } t \\geq 0$`
      );
      question.addParagraph(
        "question",
        `a) Find the time, $t_0$, at which the formula-1 car reaches its top speed.`
      );
      question.addParagraph(
        "question",
        `b) Find the top speed, $v_0$, of the formula-1 car.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures where appropriate.`
      );

      question.addHeading(
        "solution",
        `a) In order to find when the formula-1 car stops gaining velocity we need to find when derivative is zero.`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{dv}{dt} = ${p2.toString()}$`
      );
      question.addParagraph("solution", `$0 = ${p2.toString()}$`);
      question.addParagraph(
        "solution",
        `$${-p2Coefficients[0]}t = ${p2Coefficients[1]}$`
      );
      question.addParagraph("solution", `$t = ${t.toString()}$`);
      question.addParagraph(
        "solution",
        `$\\therefore t_0 = ${t.toFloat().toPrecision(3)}s$`
      );

      question.addHeading(
        "solution",
        `b) To find the maximum velocity of the formula-1 car we need to substitute $t = ${t.toString()}$ into the original equation:`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$v\\left(${t.toString()}\\right) = ${
            p1Coefficients[0]
          }\\left(${t.toString()}\\right)^{2}+${
            p1Coefficients[1]
          }\\left(${t.toString()}\\right)$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$v\\left(${t.toString()}\\right) = ${maxVelocity}$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore v_0 = ${maxVelocity.toPrecision(3)}ms^{-1}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(t.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(t.toFloat().toPrecision(3)) -
            parseFloat(t.toFloat().toPrecision(2))
        ),
        `a) $\\text{ } t_0 =$`
      );
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(maxVelocity.toPrecision(3)),
        Math.abs(
          parseFloat(maxVelocity.toPrecision(3)) -
            parseFloat(maxVelocity.toPrecision(2))
        ),
        `b) $\\text{ } v_0 =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_MaximaAndMinima_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = -MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(2, 20) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(2, 5);
    const functionShift = MCRandom.randomInt(2, 10);

    const p1 = new MCMaths.Polynomial([a, b, 0, c], `(x-${functionShift})`);
    const p1Coefficients = p1.getCoefficients();
    const p2 = p1.derivative();
    const p2Coefficients = p2.getCoefficients();
    const p3 = p2.derivative();
    const p3Coefficients = p3.getCoefficients();

    const p2Discriminant =
      p2Coefficients[1] ** 2 - 4 * p2Coefficients[0] * p2Coefficients[2];

    const x1 = functionShift;
    const x2 = functionShift - (2 * b) / (3 * a);
    const x2Fraction = new MCMaths.Fraction(x2);

    const quota = x1 * 1000 - MCRandom.randomInt(10, 20) * 10;

    const p3Evaluator_x1 = 6 * a * (x1 - functionShift) + 2 * b;
    const p3Evaluator_x2 = 6 * a * (x2 - functionShift) + 2 * b;

    const working1 = p2Coefficients[0] * -functionShift + p2Coefficients[1];

    /* END VARIABLES AND CONSTANTS */
    if (
      p2Discriminant >= 0 &&
      x1 > 0 &&
      x2 > 0 &&
      `${x2Fraction.numerator}`.length <= 3 &&
      x1 < x2
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The profit $P$ of a manufacturer depends on $x$, where $x$ is the number of products produced in thousands in a given week.`
      );
      question.addParagraph(
        "question",
        `$P$ can be modelled by the following equation:`
      );
      question.addParagraph("question", `$P = ${p1.toString()}$`);
      question.addParagraph(
        "question",
        `a) Find the $x$-coordinates of the two stationary points of the equation to $3$ significant figures where appropriate.`
      );
      question.addParagraph(
        "question",
        `Given that the manufacturer has to produce at least $${quota}$ products per week`
      );
      question.addParagraph(
        "question",
        `b) Determine what the optimal amount of products, $x_p$, the manufacturer should produce on a weekly basis in order to make the most profit.`
      );

      question.addHeading(
        "solution",
        `a) In order to find stationary points we differentiate the equation and set $\\displaystyle \\frac{dy}{dx} = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{dy}{dx} = ${p2.toString()}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(x-${functionShift})\\left[${p2Coefficients[0]}(x-${functionShift})+${p2Coefficients[1]}\\right] = 0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(x-${functionShift})\\left(${p2Coefficients[0]}x+${working1}\\right) = 0$`
        )
      );
      question.addParagraph(
        "solution",
        `Therefore the stationary points are at $x = ${x1}, \\text{ } x = ${x2Fraction.toString()}$`
      );
      question.addHeading(
        "solution",
        `b) In order to find whether a stationary point is a maximum or minimum we need to take the second derivative of the curve:`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{d^{2}y}{dx^{2}} = ${p3.toString()}$`
      );

      question.addParagraph("solution", `When $x = ${x1}$,`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\frac{d^{2}y}{dx^{2}} = ${p3Coefficients[0]}(${x1}-${functionShift})+${p3Coefficients[1]}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$= ${p3Evaluator_x1.toPrecision(3)}$`)
      );
      question.addParagraph(
        "solution",
        `Which is $\\gt 0$ therefore at $x = ${x1}$ the curve is at a minimum stationary point.`
      );

      question.addParagraph("solution", `When $x = ${x2Fraction.toString()}$,`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\frac{d^{2}y}{dx^{2}} = ${
            p3Coefficients[0]
          }\\left(${x2Fraction.toString()} - ${functionShift}\\right)+${
            p3Coefficients[1]
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$= ${p3Evaluator_x2.toPrecision(3)}$`, false)
      );
      question.addParagraph(
        "solution",
        `Which is $\\lt 0$ therefore at $x = ${x2Fraction.toString()}$ the curve is at a maximum stationary point.`
      );
      question.addParagraph(
        "solution",
        `Therefore the optimal amount of products to produce is $${(
          x2 * 1000
        ).toFixed(0)}$ products per week.`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [parseFloat(x1.toPrecision(3)), parseFloat(x2.toPrecision(3))],
        Math.abs(parseFloat(x1.toPrecision(3)) - parseFloat(x1.toPrecision(2))),
        `a) $\\text{ } x =$`
      );
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat((x2 * 1000).toFixed(0)),
        0,
        `b) $\\text{ } x_p =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_MaximaAndMinima_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const volume = MCRandom.randomInt(30, 90) / 10;

    const r = Math.cbrt((2 * volume) / (4 * Math.PI));
    const minSurfaceArea = 2 * Math.PI * r ** 2 + 2 * volume * (1 / r);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Given a cylinder of height $h$ and radius $r$ with volume $${volume}m^3$.`
      );
      question.addParagraph(
        "question",
        `Find the minimum surface area of the cylinder, $S_0$ to $3$ significant figures.`
      );
      question.addHeading("question", `Formulae for a cylinder:`);
      question.addParagraph(
        "question",
        `Surface area: $S = 2\\pi r^2 + 2\\pi rh$`
      );
      question.addParagraph("question", `Volume: $S = \\pi r^2 h$`);

      question.addParagraph(
        "solution",
        `Rearranging the formula for volume we obtain $\\displaystyle h = \\frac{${volume}}{\\pi r^2}$`
      );
      question.addParagraph("solution", `Then the formula for $S$ becomes:`);
      question.addParagraph(
        "solution",
        `$S = 2\\pi r^2 + 2\\pi r \\left(\\frac{${volume}}{\\pi r^2}\\right)$`
      );
      question.addParagraph("solution", `$= 2\\pi r^2 + ${2 * volume}r^{-1}$`);
      question.addParagraph(
        "solution",
        `Then in order to find the minimum value we need to find when $\\displaystyle \\frac{dS}{dr} = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{dS}{dr} = 4\\pi r - ${2 * volume}r^{-2}$`
      );
      question.addParagraph("solution", `$0 = 4\\pi r - ${2 * volume}r^{-2}$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle 4\\pi r = \\frac{${2 * volume}}{r^2}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle r^3 = \\frac{${2 * volume}}{4\\pi}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle r = \\sqrt[3]{\\frac{${2 * volume}}{4\\pi}}$`
      );
      question.addParagraph("solution", `$= ${r.toPrecision(3)}...$`);
      question.addParagraph(
        "solution",
        `Substituting this value back into our equation for $S$ gives us:`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle S = 2\\pi \\left(\\sqrt[3]{\\frac{${
          2 * volume
        }}{4\\pi}}\\right)^2 + ${2 * volume}\\left(\\sqrt[3]{\\frac{${
          2 * volume
        }}{4\\pi}}\\right)^{-1}$`
      );
      question.addParagraph(
        "solution",
        `$ = ${minSurfaceArea.toPrecision(6)}...$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore S_0 = ${minSurfaceArea.toPrecision(3)}m^2$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(minSurfaceArea.toPrecision(3)),
        Math.abs(
          parseFloat(minSurfaceArea.toPrecision(3)) -
            parseFloat(minSurfaceArea.toPrecision(2))
        ),
        `$S_0 =$`
      );
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_MaximaAndMinima_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(2, 9);
    const b = MCRandom.randomInt(2, 9);
    const c = MCRandom.randomInt(2, 9);

    const f_x = (x) => {
      return (a * x) / (b * x * x + c);
    };

    const f_xd = `(${a} * x) / (${b} * x * x + ${c})`;

    const working1 = new MCMaths.Fraction(c / b);
    const x1 = -Math.sqrt(c / b);
    const x2 = Math.sqrt(c / b);

    const f_x1 = f_x(x1);
    const f_x2 = f_x(x2);

    const P_x = x1;
    const P_y = f_x1;
    const Q_x = x2;
    const Q_y = f_x2;

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given the function:`);
      question.addParagraph(
        "question",
        `$\\displaystyle f(x) = \\frac{${a}x}{${b}x^2 + ${c}}$`
      );
      const myGraph = new MCQuestion.Graph(
        10,
        -10,
        Math.ceil(f_x2),
        Math.floor(f_x1),
        2,
        0.5
      );
      myGraph.plot(f_xd, -11, 11);
      question.addGraph("question", myGraph);
      question.addParagraph(
        "question",
        `And that the $x$-coordinate of stationary point $P$ is less than the $x$-coordinate of stationary point $Q$.`
      );
      question.addParagraph(
        "question",
        `a) Find the stationary points, $P$ and $Q$, of $f$.`
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, state the maxima and minima of $f$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addHeading(
        "solution",
        `a) In order to find the stationary points of $f$ we need to find when $f'(x) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle f'(x) = \\frac{(${b}x^2 + ${c})${a} - ${a}x(${
          2 * b
        }x)}{(${b}x^2 + ${c})^2}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = \\frac{${a * b}x^2 + ${a * c} - ${
          2 * a * b
        }x^2}{(${b}x^2 + ${c})^2}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = \\frac{${a * c} - ${a * b}x^2}{(${b}x^2 + ${c})^2}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = \\frac{${a}(${c} - ${b}x^2)}{(${b}x^2 + ${c})^2}$`
      );
      question.addParagraph("solution", `$f'(x) = 0$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle \\implies \\frac{${a}(${c} - ${b}x^2)}{(${b}x^2 + ${c})^2} = 0$`
      );
      question.addParagraph("solution", `$${a}(${c} - ${b}x^2) = 0$`);
      question.addParagraph("solution", `$${c} - ${b}x^2 = 0$`);
      question.addParagraph("solution", `$${b}x^2 = ${c}$`);
      question.addParagraph("solution", `$x^2 = ${working1.toString()}$`);
      question.addParagraph(
        "solution",
        `$x = \\pm \\sqrt{${working1.toString()}}$`
      );

      question.addParagraph(
        "solution",
        `When $x = -\\sqrt{${working1.toString()}}$,`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle f(x) =\\frac{-${a}\\sqrt{${working1.toString()}}}{${b}\\left(-\\sqrt{${working1.toString()}}\\right)^2 + ${c}}$`
      );
      question.addParagraph("solution", `$= ${f_x1.toPrecision(3)}$`);
      question.addParagraph(
        "solution",
        `So one stationary point is $\\left(-\\sqrt{${working1.toString()}}, \\text{ } ${f_x1.toPrecision(
          3
        )}\\right)$`
      );

      question.addParagraph(
        "solution",
        `When $x = \\sqrt{${working1.toString()}}$,`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle f(x) =\\frac{${a}\\sqrt{${working1.toString()}}}{${b}\\left(\\sqrt{${working1.toString()}}\\right)^2 + ${c}}$`
      );
      question.addParagraph("solution", `$= ${f_x2.toPrecision(3)}$`);
      question.addParagraph(
        "solution",
        `And the other stationary point is $\\left(\\sqrt{${working1.toString()}}, \\text{ } ${f_x2.toPrecision(
          3
        )}\\right)$`
      );

      question.addHeading(
        "solution",
        `b) From the graph it indicates that $\\left(-\\sqrt{${working1.toString()}}, \\text{ } ${f_x1.toPrecision(
          3
        )}\\right)$ is a minimum and $\\left(\\sqrt{${working1.toString()}}, \\text{ } ${f_x2.toPrecision(
          3
        )}\\right)$ is a maximum`
      );
      question.addParagraph(
        "solution",
        `$\\therefore min = ${f_x1.toPrecision(
          3
        )}, \\text{ } max = ${f_x2.toPrecision(3)}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        parseFloat(P_x.toPrecision(3)),
        Math.abs(
          parseFloat(P_x.toPrecision(3)) - parseFloat(P_x.toPrecision(2))
        ),
        `a) $\\text{ } P_x =$`
      );
      group1.addInput(
        parseFloat(P_y.toPrecision(3)),
        Math.abs(
          parseFloat(P_y.toPrecision(3)) - parseFloat(P_y.toPrecision(2))
        ),
        `a) $\\text{ } P_y =$`
      );
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        parseFloat(Q_x.toPrecision(3)),
        Math.abs(
          parseFloat(Q_x.toPrecision(3)) - parseFloat(Q_x.toPrecision(2))
        ),
        `a) $\\text{ } Q_x =$`
      );
      group2.addInput(
        parseFloat(Q_y.toPrecision(3)),
        Math.abs(
          parseFloat(Q_y.toPrecision(3)) - parseFloat(Q_y.toPrecision(2))
        ),
        `a) $\\text{ } Q_y =$`
      );
      const group3 = new MCQuestion.InputGroup(2);
      group3.addInput(
        parseFloat(P_y.toPrecision(3)),
        Math.abs(
          parseFloat(P_y.toPrecision(3)) - parseFloat(P_y.toPrecision(2))
        ),
        `b) $\\text{ } min =$`
      );
      group3.addInput(
        parseFloat(Q_y.toPrecision(3)),
        Math.abs(
          parseFloat(Q_y.toPrecision(3)) - parseFloat(Q_y.toPrecision(2))
        ),
        `b) $\\text{ } max =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_MaximaAndMinima_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pointA = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `The function $f$ has a local maximum at point $A = (${pointA[0]}, ${pointA[1]})$.`
      );
      question.addParagraph(`Choose the correct statement:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$f''(${pointA[0]}) < 0$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$f''(${pointA[0]}) > 0$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$f''(${pointA[0]}) = 0$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$f''(${pointA[0]}) = 1$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_MaximaAndMinima_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pointA = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];

    const answerArray = [false, true, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `The function $f$ has a local minimum at point $A = (${pointA[0]}, ${pointA[1]})$.`
      );
      question.addParagraph(`Choose the correct statement:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$f''(${pointA[0]}) < 0$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$f''(${pointA[0]}) > 0$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$f''(${pointA[0]}) = 0$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$f''(${pointA[0]}) = 1$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_MaximaAndMinima_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pointA = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];

    const answerArray = [false, false, true, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `The function $f$ has a stationary point at point $A = (${pointA[0]}, ${pointA[1]})$.`
      );
      question.addParagraph(`Choose the correct statement:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$f'(${pointA[0]}) < 0$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$f'(${pointA[0]}) > 0$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$f'(${pointA[0]}) = 0$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$f'(${pointA[0]}) = 1$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_MaximaAndMinima_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const p1 = new MCMaths.Polynomial([a, b, 0], "x");
    const x2 = new MCMaths.Fraction(-b / a);

    const wrong_x2_1 = new MCMaths.Fraction(-a / b);
    const wrong_x2_2 = new MCMaths.Fraction(b / a);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (a !== b) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Given that $\\displaystyle \\frac{dy}{dx} = ${p1.toString()}$`
      );
      question.addParagraph(
        `Choose the correct pair of $x$-coordinates that belong to the local minima and maxima of the curve:`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$x = 0, \\text{ } x = ${x2.toString()}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$x = 0, \\text{ } x = ${wrong_x2_1.toString()}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$x = 1, \\text{ } x = ${x2.toString()}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$x = 0, \\text{ } x = ${wrong_x2_2.toString()}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_MaximaAndMinima_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = -MCRandom.randomInt(2, 9);
    const b = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const p1 = new MCMaths.Polynomial([a, b, 0], "x");
    const p2 = p1.derivative();
    const p2Coefficients = p2.getCoefficients();
    const x1 = new MCMaths.Fraction(-p2Coefficients[1] / p2Coefficients[0]);
    const maxValue = new MCMaths.Fraction(p1.evaluate(x1.toFloat()));
    const wrongAnswer1 = new MCMaths.Fraction(p2.evaluate(x1.toFloat()));
    const wrongAnswer2 = x1.toString();
    const wrongAnswer3 = new MCMaths.Fraction(-p1.evaluate(x1.toFloat()));

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (a !== b && x1.toFloat() !== maxValue.toFloat()) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Given that $f(x) = ${p1.toString()}$ and that $f$ has a maximum at $x=${x1.toString()}$`
      );
      question.addParagraph(`What is the maximum value of $f$?:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${maxValue.toString()}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${wrongAnswer1.toString()}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${wrongAnswer2}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${wrongAnswer3.toString()}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ParametricResults_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const funcs = [
      new MCMaths.Polynomial(
        [MCRandom.randomInt(1, 5), 0, 0, MCRandom.randomInt(-5, 5)],
        "t"
      ),
      new MCMaths.Polynomial(
        [MCRandom.randomInt(1, 5), 0, MCRandom.randomInt(-5, 5)],
        "t"
      ),
      new MCMaths.Trig(1, "sin", 1, 1, "t"),
      new MCMaths.Trig(1, "cos", 1, 1, "t"),
      new MCMaths.Exponential(1, "e", 1, "t"),
    ];
    const x = funcs[MCRandom.randomInt(0, funcs.length - 1)];
    const y = funcs[MCRandom.randomInt(0, funcs.length - 1)];
    const t = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const dx = x.derivative().evaluate(t);
    const dy = y.derivative().evaluate(t);
    let dxd = `=${dx}`;
    if (Math.round(dx * 10000) !== dx * 10000) {
      dxd = `\\approx${Math.round(dx * 10000) / 10000}`;
    }
    let dyd = `=${dy}`;
    if (Math.round(dy * 10000) !== dy * 10000) {
      dyd = `\\approx${Math.round(dy * 10000) / 10000}`;
    }
    const m = dy / dx;

    const x1 = x.evaluate(t);
    const y1 = y.evaluate(t);
    let x1d = `=${x1}`;
    let y1d = `=${y1}`;
    if (Math.round(x1 * 10000) !== x1 * 10000) {
      x1d = `\\approx${Math.round(x1 * 10000) / 10000}`;
    }
    if (Math.round(y1 * 10000) !== y1 * 10000) {
      y1d = `\\approx${Math.round(y1 * 10000) / 10000}`;
    }
    const c = y1 - m * x1;
    /* END VARIABLES AND CONSTANTS */
    if (
      x !== y &&
      Math.abs(m) > 0.1 &&
      Math.abs(m) < 30 &&
      Math.abs(c) < 30 &&
      Math.abs(c) > 0.1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Find the tangent to the curve with parameters $x=${x.toString()}$, $y=${y.toString()}$, at $t=${t}$. Give your answer in the form $y=mx+c$, with $m,c$ to 2dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Finding $\\displaystyle \\frac{dx}{dt}$:`,
        `$\\displaystyle \\frac{dx}{dt} = ${x.derivative().toString()}$`,
        `HEADING Evaluating at $t=${t}$:`,
        `$x'(${t}) ${dxd}$`,
        `HEADING Finding $\\displaystyle \\frac{dy}{dt}$:`,
        `$\\displaystyle \\frac{dy}{dt} = ${y.derivative().toString()}$`,
        `HEADING Evaluating at $t=${t}$:`,
        `$y'(${t}) ${dyd}$`,
        `HEADING Finding $\\displaystyle\\frac{dy}{dx}$:`,
        `$\\displaystyle \\frac{dy}{dx} = \\frac{dy}{dt} \\div \\frac{dx}{dt}$`,
        `$ = ${Math.round(dy * 10000) / 10000} \\div ${
          Math.round(dx * 10000) / 10000
        }$`,
        `$ = ${Math.round(m * 10000) / 10000}$`,
        `HEADING Finding coordinates:`,
        `$x_1 = ${x
          .toString()
          .replace("\\left(t\\right)", `\\left(${t}\\right)`)
          .replace(/t(?![()])/g, `\\left(${t}\\right)`)}$`,
        `$${x1d}$`,
        `$y_1 = ${y
          .toString()
          .replace("\\left(t\\right)", `\\left(${t}\\right)`)
          .replace(/t(?![()])/g, `\\left(${t}\\right)`)}$`,
        `$${y1d}$`,
        `HEADING Finding Line:`,
        MCMaths.cleaner(
          `$y-${Math.round(y1 * 10000) / 10000} = ${
            Math.round(m * 10000) / 10000
          }(x-${Math.round(x1 * 10000) / 10000})$`,
          false,
          false
        ),
        `$y=${Math.round(m * 100) / 100}x+${
          Math.round(c * 100) / 100
        }$.`.replace("+-", "-"),
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(Math.round(m * 100) / 100, 0.01, "m=");
      group.addInput(Math.round(c * 100) / 100, 0.01, "c=");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ParametricResults_Exam_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const t = MCRandom.randomInt(1, 9);

    const funcs = [
      new MCMaths.Trig(1, "sin", 1, MCRandom.randomInt(1, 3), "t"),
      new MCMaths.Trig(1, "cos", 1, MCRandom.randomInt(1, 3), "t"),
      new MCMaths.Trig(1, "tan", 1, MCRandom.randomInt(1, 3), "t"),
      new MCMaths.Logarithm(1, "e", 1, "t"),
      new MCMaths.Exponential(1, "e", MCRandom.randomInt(1, 3), "t"),
      new MCMaths.Polynomial([1, 0, 0], "t"),
      new MCMaths.Polynomial([1, 0, 0, 0], "t"),
    ];

    const a = MCRandom.randomInt(0, funcs.length - 1);
    const b = MCRandom.randomInt(0, funcs.length - 1);
    const c = MCRandom.randomInt(0, funcs.length - 1);
    const d = MCRandom.randomInt(0, funcs.length - 1);

    const x1 = funcs[a];
    const x2 = funcs[b];
    const y1 = funcs[c];
    const y2 = funcs[d];

    const x = new MCMaths.QuotientRule(x1, x2);
    const y = new MCMaths.ProductRule(y1, y2);

    const ansA = Math.round(x.evaluate(t) * 100) / 100;
    const ansB = Math.round(y.evaluate(t) * 100) / 100;
    const ansC = Math.round((y.evaluate(t) * 100) / x.evaluate(t)) / 100;

    /* END VARIABLES AND CONSTANTS */
    if (
      a !== b &&
      a !== c &&
      a !== d &&
      b !== c &&
      b !== d &&
      c !== d &&
      a + b !== 11 &&
      c + d !== 11 &&
      a + b !== 1 &&
      b !== 2 &&
      Math.abs(ansA) < 10 &&
      Math.abs(ansA) > 0.5 &&
      Math.abs(ansB) < 10 &&
      Math.abs(ansB) > 0.5 &&
      Math.abs(ansC) < 10 &&
      Math.abs(ansC) > 0.5 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A curve is defined by the parameters $\\displaystyle x = ${x.toString()}, \\ y=${y.toString()}$`,
        `a) Find the value of $\\displaystyle \\frac{dx}{dt}$ at $t=${t}$, to 2dp.`,
        `b) Find the value of $\\displaystyle \\frac{dy}{dt}$ at $t=${t}$, to 2dp.`,
        `c) Find the value of $\\displaystyle \\frac{dx}{dy}$ at $t=${t}$, to 2dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)${x.derivativeWorking()[0]}`,
        x.derivativeWorking()[1].replace(/x/g, "t"),
        x.derivativeWorking()[2].replace(/x/g, "t"),
        x.derivativeWorking()[3].replace(/x/g, "t"),
        x.derivativeWorking()[4].replace(/x/g, "t"),
        x.derivativeWorking()[5].replace(/x/g, "t"),
        x.derivativeWorking()[6].replace(/x/g, "t"),
        `HEADING Evaluating:`,
        x
          .derivativeWorking()[6]
          .replace(/x/g, "t")
          .replace(/\\left\(t\\right\)/g, "t")
          .replace(/t(?![ay()])/g, `(${t})`),
        `$\\approx ${ansA}$`,
        `b)${y.derivativeWorking()[0]}`,
        y.derivativeWorking()[1].replace(/x/g, "t"),
        y.derivativeWorking()[2].replace(/x/g, "t"),
        y.derivativeWorking()[3].replace(/x/g, "t"),
        y.derivativeWorking()[4].replace(/x/g, "t"),
        y.derivativeWorking()[5].replace(/x/g, "t"),
        y.derivativeWorking()[6].replace(/x/g, "t"),
        `HEADING Evaluating:`,
        y
          .derivativeWorking()[6]
          .replace(/x/g, "t")
          .replace(/\\left\(t\\right\)/g, "t")
          .replace(/t(?![ay()])/g, `(${t})`),
        `$\\approx ${ansB}$`,
        `c) HEADING Finding $\\displaystyle \\frac{dy}{dx}$:`,
        `$\\displaystyle \\frac{dy}{dx}=\\frac{dy}{dt}\\div\\frac{dx}{dt}$`,
        `$= ${ansB} \\div ${ansA}$`,
        `$\\approx ${ansC}$.`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ansA, 0.1, "a)");
      group.addInput(ansB, 0.1, "b)");
      group.addInput(ansC, 0.1, "c)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ParametricResults_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 8);
    const b = MCRandom.randomInt(2, 8);
    const yWorking = new MCMaths.QuotientRule(
      new MCMaths.Polynomial([1, 0, 0], "t"),
      new MCMaths.Polynomial([-1, a], "t")
    );
    const yW2 = new MCMaths.Polynomial([-1, 2 * a, 0], "t");

    const w3 = new MCMaths.Polynomial(
      [-1 / (a - b), (2 * a) / (a - b), 0],
      "t"
    );
    const w4 = new MCMaths.Polynomial([-1 / (a - b), (2 * a) / (a - b)], "t");
    /* END VARIABLES AND CONSTANTS */
    if (
      a !== b &&
      Math.abs(a - b) !== 1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Find the values of $t$ at the turning points of the curve given by the parameters $\\displaystyle x=\\frac{${b}-t}{${a}-t}, \\ y=\\frac{t^2}{${a}-t}$.`
      );
      question.addMultipleParagraphs("solution", [
        `HEADING Derivative of $x$:`,
        `$\\displaystyle x = \\frac{${b - a}}{${a}-t} + 1$`,
        `$\\displaystyle \\frac{dx}{dt} = ${a - b}(${a}-t)^{-2}$`,
        `HEADING Derivative of $y$ using Quotient Rule:`,
        yWorking.derivativeWorking()[1],
        yWorking.derivativeWorking()[2],
        yWorking.derivativeWorking()[3],
        yWorking.derivativeWorking()[4],
        yWorking.derivativeWorking()[5],
        `$\\displaystyle\\frac{dy}{dt}$${yWorking.derivativeWorking()[6]}`,
        `$\\displaystyle = \\frac{${yW2.toString()}}{\\left(${a}-t\\right)^2}$`,
        `HEADING Finding $\\displaystyle \\frac{dy}{dx}$:`,
        `$\\displaystyle\\frac{dy}{dx} = \\frac{dy}{dt}\\div \\frac{dx}{dt}$`,
        `$\\displaystyle = \\frac{${yW2.toString()}}{\\left(${a}-t\\right)^2} \\div ${
          a - b
        }(${a}-t)^{-2}$`,
        `$=${w3.toString()}$`,
        `HEADING Finding roots:`,
        `$=t\\left(${w4.toString()}\\right)$`,
        `$t=0$ or $t=${a * 2}$`,
      ]);
      const group = new MCQuestion.InputGroup(2);
      group.addInput([0, 2 * a], 0, "t=");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ParametricResults_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const x = new MCMaths.Polynomial(
      [
        MCRandom.randomInt(1, 5),
        MCRandom.randomInt(-5, 5),
        MCRandom.randomInt(-5, 5),
      ],
      "t"
    );
    const y = new MCMaths.Polynomial(
      [
        MCRandom.randomInt(1, 5),
        MCRandom.randomInt(-5, 5),
        MCRandom.randomInt(-5, 5),
      ],
      "t"
    );
    const dx = x.derivative();
    const dy = y.derivative();
    const m = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const w1 = dx.multiply(m).subtract(dy);
    const t = -w1.getCoefficients()[1] / w1.getCoefficients()[0];

    const y1 = y.evaluate(t);
    const x1 = x.evaluate(t);

    let mx = `${m}x`;
    if (m === 1) {
      mx = "x";
    }
    if (m === -1) {
      mx = "-x";
    }

    const c = y1 - m * x1;
    /* END VARIABLES AND CONSTANTS */
    if (
      x !== y &&
      dx !== dy &&
      Math.round(c * 10) === c * 10 &&
      x1 !== 0 &&
      y1 !== 0 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A curve is formed by the parameters $x=${x.toString()}$, $y=${y.toString()}$. A tangent to this curve has gradient $${m}$.`,
        `Find the coordinate of the $y$-intercept of the tangent.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Finding Derivatives:`,
        `$x'(t) = ${dx.toString()}$`,
        `$y'(t) = ${dy.toString()}$`,
        `$\\displaystyle \\frac{dy}{dx} = \\frac{y'(t)}{x'(t)}$`,
        `$\\displaystyle = \\frac{${dy.toString()}}{${dx.toString()}}$`,
        `HEADING Finding $t$:`,
        `$\\displaystyle ${m} = \\frac{${dy.toString()}}{${dx.toString()}}$`,
        `$${dx.multiply(m).toString()} = ${dy.toString()}$`,
        `$${dx.multiply(m).subtract(dy).toString()}=0$`,
        MCMaths.cleaner(`$t=${t}$`),
        `HEADING Finding Coordinates:`,
        MCMaths.cleaner(`$y(${t}) = ${y.toString().replace(/t/g, `(${t})`)}$`),
        MCMaths.cleaner(`$=${y1}$`),
        MCMaths.cleaner(`$x(${t}) = ${x.toString().replace(/t/g, `(${t})`)}$`),
        MCMaths.cleaner(`$=${x1}$`),
        `HEADING Finding line:`,
        MCMaths.cleaner(`$y-${y1} = ${m}(x-${x1})$`),
        MCMaths.cleaner(`$y = ${mx}+${c}$`),
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(c, 0, "y=");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ParametricResults_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 8);
    const b = MCRandom.randomInt(1, 8);
    const x = new MCMaths.Trig(MCRandom.randomInt(1, 4), "cos", 1, a, "t");
    const y = new MCMaths.Trig(MCRandom.randomInt(1, 4), "sin", 1, b, "t");
    const dx = x.derivative();
    const dy = y.derivative();

    const w1 = 3;
    const t =
      Math.PI *
      (MCRandom.randomInt(0, 4) + 0.5) *
      0.5 ** MCRandom.randomInt(0, 2);
    const m = -dx.evaluate(t) / dy.evaluate(t);

    const y1 = y.evaluate(t);
    const x1 = x.evaluate(t);

    let mx = `${m}x`;
    if (m === 1) {
      mx = "x";
    }
    if (m === -1) {
      mx = "-x";
    }
    const c = y1 - m * x1;
    /* END VARIABLES AND CONSTANTS */
    if (
      a !== b &&
      Math.round(m * 100) === m * 100 &&
      Math.abs(m) > 0.001 &&
      Math.abs(m) < 100 &&
      Math.abs(x1) > 0.01 &&
      Math.abs(x1) < 100 &&
      Math.abs(y1) > 0.01 &&
      Math.abs(y1) < 100 &&
      Math.round(y1 * 100) === y1 * 100 &&
      Math.round(x1 * 100) ===
        x1 * 100 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A curve is formed by the parameters $x=${x.toString()}$, $y=${y.toString()}$. Find equation of the line normal to curve at $t=${MCMaths.cleaner(
          `${t}`
        )}$, in the form $y=mx+c$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Finding Derivatives:`,
        `$x'(t) = ${dx.toString()}$`,
        `$y'(t) = ${dy.toString()}$`,
        `$\\displaystyle \\frac{dy}{dx} = \\frac{y'(t)}{x'(t)}$`,
        `$\\displaystyle = \\frac{${dy.toString()}}{${dx.toString()}}$`,
        `HEADING Finding $m$:`,
        MCMaths.cleaner(
          `$\\displaystyle -m^{-1} = \\frac{${dy
            .toString()
            .replace(/\dt/, `${b}\\cdot t`)
            .replace(`t\\right)`, `${t}\\right)`)}}{${dx
            .toString()
            .replace(/\dt/, `${a}\\cdot t`)
            .replace(`t\\right)`, `${t}\\right)`)}}$`
        ),
        MCMaths.cleaner(`$=${-1 / m}$`),
        MCMaths.cleaner(`$m=${m}$`),
        `HEADING Finding Coordinates:`,
        MCMaths.cleaner(
          `$y\\left(${t}\\right) = ${y
            .toString()
            .replace(/\dt/, `${b}\\cdot t`)
            .replace(`t\\right)`, `${t}\\right)`)}$`
        ),
        MCMaths.cleaner(`$=${y1}$`),
        MCMaths.cleaner(
          `$x\\left(${t}\\right) = ${x
            .toString()
            .replace(/\dt/, `${a}\\cdot t`)
            .replace(`t\\right)`, `${t}\\right)`)}$`
        ),
        MCMaths.cleaner(`$=${x1}$`),
        `HEADING Finding line:`,
        MCMaths.cleaner(`$y-${y1} = ${m}(x-${x1})$`),
        MCMaths.cleaner(`$y = ${mx}+${c}$`),
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(m, 0, "m=");
      group.addInput(c, 0, "c=");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ParametricResults_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // M>2 otherise 4 roots
    const m = MCRandom.randomInt(3, 8) * MCRandom.randomInt(-1, 1, 2);
    const tt1 = m / 8 - 0.125 * Math.sqrt(m ** 2 + 32);
    const tt2 = m / 8 + 0.125 * Math.sqrt(m ** 2 + 32);
    let t = tt1;
    let notT = tt2;
    if (Math.abs(t) > 1) {
      t = tt2;
      notT = tt1;
    }

    const t1 = Math.asin(t);
    const t2 = Math.PI - Math.asin(t);

    const c1 = Math.sin(2 * t1) - m * Math.cos(t1);
    const c2 = Math.sin(2 * t2) - m * Math.cos(t2);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A curve is given by the parameters $x=\\cos(t), \\ y=\\sin(2t)$. `,
        `Find the coordinates of the $y$-intercepts of the tangents to the curve with gradient $${m}$. Give your answers to 2dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Finding Derivatives:`,
        `$\\displaystyle \\frac{dx}{dt} = -\\sin(t)$`,
        `$\\displaystyle \\frac{dy}{dt} = 2\\cos(2t)$`,
        `$\\displaystyle \\frac{dy}{dx} = -\\frac{2\\cos(2t)}{\\sin(t)}$`,
        `HEADING Finding $t$:`,
        `$\\displaystyle ${m} =-\\frac{2\\cos(2t)}{\\sin(t)}$`,
        `$\\displaystyle ${m} =-\\frac{2(1-2\\sin^2(t))}{\\sin(t)}$`,
        MCMaths.cleaner(`$4\\sin^2(t)+${-m}\\sin(t)-2=0$`),
        `HEADING Using quadratic formula:`,
        MCMaths.cleaner(
          `$\\displaystyle \\sin(t) = \\frac{${m}\\pm\\sqrt{${m * m}+32}}{8}$`
        ),
        MCMaths.cleaner(
          `$\\sin(t) = ${m / 8} \\pm ${0.125 * Math.sqrt(m * m + 32)}$`
        ),
        `As $|${Math.round(notT * 1000) / 1000}|>1$, $ \\sin(t)\\approx ${
          Math.round(t * 1000) / 1000
        }$.`,
        `$t_1=\\sin^{-1}(${Math.round(t * 1000) / 1000})$`,
        `$\\approx${Math.round(1000 * t1) / 1000}$`,
        MCMaths.cleaner(
          `$t_2=\\pi-${Math.round(1000 * t1) / 1000}$`,
          false,
          false
        ),
        `$\\approx ${Math.round(1000 * t2) / 1000}$`,
        `HEADING First Line:`,
        `$y-\\sin(2\\times ${
          Math.round(1000 * t1) / 1000
        }) = ${m}\\big(x-\\cos(${Math.round(1000 * t1) / 1000})\\big)$`,
        MCMaths.cleaner(
          `$y=${m}x+${Math.round(c1 * 100) / 100}$`,
          false,
          false
        ),
        `HEADING Second Line:`,
        `$y-\\sin(2\\times ${
          Math.round(1000 * t2) / 1000
        }) = ${m}\\big(x-\\cos(${Math.round(1000 * t2) / 1000})\\big)$`,
        MCMaths.cleaner(
          `$y=${m}x+${Math.round(c2 * 100) / 100}$`,
          false,
          false
        ),
      ]);
      const group = new MCQuestion.InputGroup(2);
      group.addInput(
        [Math.round(c1 * 100) / 100, Math.round(c2 * 100) / 100],
        0.01,
        "y="
      );
      question.addInputGroup(group);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ParametricResults_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(2, 5);
    const t0 = (Math.PI * MCRandom.randomInt(1, 60)) / 30;

    const ans1 =
      (-2 * b * Math.sin(b * t0) * Math.cos(b * t0)) / Math.cos(t0) ** 2;
    const p = Math.round((ans1 * Math.sqrt(3)) / 3);

    const sinRoots = [
      "",
      "",
      "0",
      "-\\frac{\\pi}{3},0,\\frac{\\pi}{3}",
      "-\\frac{\\pi}{4},0,\\frac{\\pi}{4}",
      "-\\frac{2\\pi}{5},-\\frac{\\pi}{5},0,\\frac{\\pi}{5},\\frac{2\\pi}{5}",
    ];
    const cosRoots = [
      "",
      "",
      "-\\frac{\\pi}{4},0,\\frac{\\pi}{4}",
      "-\\frac{\\pi}{6},\\frac{\\pi}{6}",
      "-\\frac{3\\pi}{8},-\\frac{\\pi}{8},\\frac{\\pi}{8},\\frac{3\\pi}{8}",
      "-\\frac{3\\pi}{10},-\\frac{\\pi}{10},\\frac{\\pi}{10},\\frac{3\\pi}{10}",
    ];
    const fullRoots = [
      "",
      "",
      "-\\frac{\\pi}{4}, 0, \\frac{\\pi}{4}",
      "-\\frac{\\pi}{3}, -\\frac{\\pi}{6}, 0, \\frac{\\pi}{6}, \\frac{\\pi}{3}",
      "-\\frac{3\\pi}{8}, -\\frac{\\pi}{4},-\\frac{\\pi}{8}, 0,\\frac{\\pi}{8}, \\frac{\\pi}{4}, \\frac{3\\pi}{8}",
      "-\\frac{2\\pi}{5}, -\\frac{3\\pi}{10},-\\frac{\\pi}{5},-\\frac{\\pi}{10}, 0,\\frac{\\pi}{10},\\frac{\\pi}{5}, \\frac{3\\pi}{10}, \\frac{2\\pi}{5}",
    ];

    const ans2 = 2 * b - 1;
    /* END VARIABLES AND CONSTANTS */
    if (
      MCMaths.cleaner(`$=${ans1}$`).includes("\\sqrt{3}") &&
      Math.abs(ans1) < 10000 &&
      Math.abs(ans1) > 0.1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here.
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A curve has parametric equations $x=${a}\\tan(t)$, $y=\\cos^2(${b}t)$, $\\displaystyle -\\frac{\\pi}{2}< t < \\frac{\\pi}{2}$.`,
        MCMaths.cleaner(
          `a) Find $\\displaystyle \\frac{dy}{dx}$ at $t = ${t0}$, in the form $p\\sqrt{3}$.`
        ),
        `b) Find how many turning points this curve has.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Derivative of $x$:`,
        `$\\displaystyle \\frac{dx}{dt} = ${a}\\sec^2(t)$`,
        `HEADING Derivative of $y$:`,
        `$\\displaystyle \\frac{dy}{dt} = ${-2 * b}\\sin(${b}t)\\cos(${b}t)$`,
        `HEADING Gradient of curve:`,
        `$\\displaystyle \\frac{dy}{dx} = \\frac{dy}{dt} \\div \\frac{dx}{dt}$`,
        `$\\displaystyle = -\\frac{${
          2 * b
        }\\sin(${b}t)\\cos(${b}t)}{${a}\\sec^2(t)}$`,
        `HEADING Evaluating:`,
        MCMaths.cleaner(
          `$\\displaystyle = -\\frac{${
            2 * b
          }\\sin\\left(${b}\\cdot ${t0}\\right)\\cos\\left(${b}\\cdot ${t0}\\right)}{${a}\\sec^2\\left(${t0}\\right)}$`
        ),
        MCMaths.cleaner(`$=${ans1}$`),
        `$p=${p}$`,
        `b) HEADING Rewriting Derivative:`,
        MCMaths.cleaner(
          `$\\displaystyle = ${(-2 * b) / a}\\sin(${b}t)\\cos(${b}t)\\cos^2(t)$`
        ),
        `HEADING Roots of $\\sin(${b}t)$:`,
        `$\\displaystyle ${b}t =k\\pi$ some integer $k$, for $\\displaystyle -\\frac{\\pi}{2}< t < \\frac{\\pi}{2}$`,
        `$\\displaystyle =${sinRoots[b]}$`,
        `HEADING Roots of $\\cos(${b}t)$:`,
        `$\\displaystyle ${b}t = \\frac{\\pi}{2}+k\\pi$ some integer $k$, for $\\displaystyle -\\frac{\\pi}{2}< t < \\frac{\\pi}{2}$`,
        `$\\displaystyle =${cosRoots[b]}$`,
        `HEADING Roots of $\\cos^2(t)$:`,
        `$\\displaystyle t = \\frac{\\pi}{2}+k\\pi$ some integer $k$, for $\\displaystyle -\\frac{\\pi}{2}< t < \\frac{\\pi}{2}$`,
        `$\\displaystyle \\cos^2(t)$ has no roots in this range.`,
        `HEADING Unique Roots:`,
        `$\\displaystyle =${fullRoots[b]}$`,
        `$=${ans2}$ turing points.`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(p, 0, "a) $\\ p=$");
      group.addInput(ans2, 0, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ParametricResults_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const graph = new MCQuestion.Graph(12, -12, 12, -12, 3, 3);
    graph.addParametric(
      `(t * t * Math.sin(t)) / 12`,
      `t * Math.cos(t)`,
      -3.5 * Math.PI,
      3.5 * Math.PI
    );

    const w1 = new MCMaths.ProductRule(
      new MCMaths.Polynomial([1 / 12, 0, 0], "t"),
      new MCMaths.Trig(1, "sin", 1, 1, "t")
    );
    const w2 = new MCMaths.ProductRule(
      new MCMaths.Polynomial([1, 0], "t"),
      new MCMaths.Trig(1, "cos", 1, 1, "t")
    );

    const t0 = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);

    const ans1 = w2.evaluate(t0) / w1.evaluate(t0);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A company logo is generated by the equations $\\displaystyle x=\\frac{t^2}{12}\\sin(t), \\ y=t\\cos(t), \\ -\\frac{7}{2}\\pi\\le t \\le \\frac{7}{2}\\pi$.`,
      ]);
      question.addGraph("question", graph);
      question.addMultipleParagraphs("question", [
        `Find $\\frac{dy}{dx}$ at $t=${t0}$, to 2dp.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING Finding $\\frac{dx}{dy}$ using Product Rule:`,
        w1.derivativeWorking()[1],
        w1.derivativeWorking()[2],
        w1.derivativeWorking()[3],
        w1.derivativeWorking()[4],
        w1.derivativeWorking()[5],
        `$\\displaystyle \\frac{dx}{dt} = \\frac{t\\sin(t)}{6}+\\frac{t^2\\cos(t)}{12}$`,
        `HEADING Finding $\\frac{dy}{dy}$ using Product Rule:`,
        w2.derivativeWorking()[1],
        w2.derivativeWorking()[2],
        w2.derivativeWorking()[3],
        w2.derivativeWorking()[4],
        `$\\displaystyle \\frac{dy}{dt} = \\cos(t)-t\\sin(t)$`,
        `HEADING Finding $\\displaystyle\\frac{dy}{dx}$:`,
        `$\\displaystyle \\frac{dy}{dx} = \\frac{dy}{dt} \\div \\frac{dx}{dt}$`,
        `$\\displaystyle = 12 \\frac{\\cos(t)-t\\sin(t)}{2t\\sin(t)+t^2\\cos(t)}$`,
        `HEADING Evaluating at $t=${t0}$`,
        MCMaths.cleaner(
          `$\\displaystyle = 12 \\frac{\\cos(${t0})-${t0}\\sin(${t0})}{${
            2 * t0
          }\\sin(${t0})+${t0}^2\\cos(${t0})}$`
        ),
        `$\\approx ${Math.round(ans1 * 100) / 100}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(Math.round(ans1 * 100) / 100, 0.01);
      question.addInputGroup(group);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ParametricResults_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const x = new MCMaths.Polynomial(
      [
        MCRandom.randomInt(1, 5),
        MCRandom.randomInt(-5, 5),
        MCRandom.randomInt(-5, 5),
      ],
      "t"
    );
    const y = new MCMaths.Polynomial(
      [
        MCRandom.randomInt(1, 5),
        MCRandom.randomInt(-5, 5),
        MCRandom.randomInt(-5, 5),
      ],
      "t"
    );

    const t = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const dx = x.derivative().evaluate(t);
    const dy = y.derivative().evaluate(t);
    const m = -dx / dy;
    const md = MCMaths.cleaner(`${m}`);
    const mn = MCMaths.cleaner(`${-1 / m}`);

    const ansx = x.evaluate(t);
    const ansy = y.evaluate(t);

    const c = ansy - m * ansx;

    const bPoly = y
      .subtract(x.multiply(m))
      .subtract(new MCMaths.Polynomial([c]), "t");
    const Ba = bPoly.getCoefficients()[0];
    const Bb = bPoly.getCoefficients()[1];
    const Bc = bPoly.getCoefficients()[2];

    let t2 = (-Bb + Math.sqrt(Bb * Bb - 4 * Ba * Bc)) / (2 * Ba);
    if (t === t2) {
      t2 = (-Bb - Math.sqrt(Bb * Bb - 4 * Ba * Bc)) / (2 * Ba);
    }

    const ansx2 = x.evaluate(t2);
    const ansy2 = y.evaluate(t2);
    /* END VARIABLES AND CONSTANTS */
    if (
      x !== y &&
      x.derivative() !== y.derivative() &&
      Math.abs(dx) !== 1 &&
      Math.abs(dy) !== 1 &&
      Math.round(30 * c) === 30 * c &&
      Math.round(30 * m) === m * 30 &&
      Math.abs(m) < 10000 &&
      Math.abs(m) !== 1 &&
      Math.round(Ba) === Ba &&
      Math.round(Bb) === Bb &&
      Math.round(Bc) === Bc &&
      Math.abs(Bc) < 10 &&
      Math.abs(Bb) < 10 &&
      Math.abs(Ba) < 10 &&
      m !== 0 &&
      1 / m !== 0 &&
      Math.round(t2 * 6) === t2 * 6 &&
      Math.round(ansx2) === ansx2 &&
      Math.round(ansy2) === ansy2 &&
      Math.abs(ansx2) < 20 &&
      Math.abs(ansy2) < 20 &&
      ansx !== ansx2 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A curve is given by the parameters $x=${x.toString()}$, $y=${y.toString()}$.`,
        `A line normal to the curve has gradient $\\displaystyle ${md}$.`,
        `a) Find the point where the line meets the curve as a normal.`,
        `b) Find another point where the line intersects the curve.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding derivatives:`,
        `$\\displaystyle \\frac{dx}{dt}=${x.derivative().toString()}$`,
        `$\\displaystyle \\frac{dy}{dt}=${y.derivative().toString()}$`,
        `$\\displaystyle \\frac{dy}{dx}=\\frac{dy}{dt}\\div\\frac{dx}{dt}$`,
        `$\\displaystyle = \\frac{${y
          .derivative()
          .toString()}}{${x.derivative().toString()}}$`,
        `HEADING Finding $t$:`,
        `$\\displaystyle -\\left(${md}\\right)^{-1} = \\frac{${y
          .derivative()
          .toString()}}{${x.derivative().toString()}}$`,
        `$\\displaystyle ${mn}(${x
          .derivative()
          .toString()}) = ${y.derivative().toString()} $`,
        `$t=${t}$`,
        `HEADING Finding Coordinates:`,
        `$x(${t})=${x.toString().replace(/t/g, `(${t})`)}$`,
        `$=${ansx}$`,
        `$y(${t})=${y.toString().replace(/t/g, `(${t})`)}$`,
        `$=${ansy}$`,
        `b) HEADING Line Equation:`,
        `$\\displaystyle y-${ansy} = ${md}(x-${ansx})$`,
        MCMaths.cleaner(`$\\displaystyle y = ${md}x+${c}$`),
        `HEADING Substituting parameters:`,
        MCMaths.cleaner(
          `$\\displaystyle ${y.toString()} = ${md}(${x.toString()})+${c}$`
        ),
        `HEADING Rearranging:`,
        `$\\displaystyle ${bPoly.toString()}=0$`,
        `HEADING Solving:`,
        `$\\displaystyle t=${bPoly.quadraticFormula()}$`,
        `$t=${t}, t=${MCMaths.cleaner(`${t2}`)}$`,
        `HEADING Finding Coordinates:`,
        MCMaths.cleaner(
          `$x\\left(${t2}\\right)=${x
            .toString()
            .replace(/t/g, `\\left(${t2}\\right)`)}$`
        ),
        MCMaths.cleaner(`$=${ansx2}$`),
        MCMaths.cleaner(
          `$y\\left(${t2}\\right)=${y
            .toString()
            .replace(/t/g, `\\left(${t2}\\right)`)}$`
        ),
        MCMaths.cleaner(`$=${ansy2}$`),
      ]);

      const group = new MCQuestion.InputGroup(2);
      group.addInput(ansx, 0.01, "x=");
      group.addInput(ansy, 0.01, "y=");
      group.addInput(ansx2, 0.01, "x=");
      group.addInput(ansy2, 0.01, "y=");
      question.addInputGroup(group);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ParametricResults_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 7);
    const m = 2;
    const p = new MCMaths.Polynomial([2, -m * a, m - 2], "t");
    const t1 = p.roots()[0];
    const t2 = p.roots()[1];
    const t = Math.max(t1, t2);

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(8 * t1) === t1 * 8 &&
      Math.round(8 * t2) ===
        t2 * 8 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A curve is given by the parameters $x=${a}t-\\ln(${a}t), \\ y=t^2-\\ln\\left(t^2 \\right)$, $t>0$.`,
        `a) Show there is only one value of $t$ for which $\\frac{dy}{dx}=${m}$, and state the value of $t$.`,
        `b) Find the $y$ intercept of the line tangent to the curve at the value of $t$ found in part $a$. Give your answer in the form $p+\\ln(q)$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding derivatives:`,
        `$\\displaystyle \\frac{dx}{dt} = ${a} - \\frac{1}{t}$`,
        `$\\displaystyle \\frac{dy}{dy} = 2t - \\frac{2}{t}$`,
        `$\\displaystyle \\frac{dy}{dx} = \\frac{2t - \\frac{2}{t}}{ ${a} - \\frac{1}{t}}$`,
        `HEADING Finding $t$:`,
        `$\\displaystyle ${m} = \\frac{2t - \\frac{2}{t}}{ ${a} - \\frac{1}{t}}$`,
        `$\\displaystyle  ${m * a} - \\frac{${m}}{t} = 2t - \\frac{2}{t}$`,
        `$\\displaystyle ${p.toString()} = 0$`,
        `$\\displaystyle t=${t1}, t=${t2}$`,
        `$t>0, t=${t}$`,
        `b)HEADING Finding coordinates:`,
        `$x_1 = ${a * t} - \\ln(${a * t})$`,
        `$y_1 = ${t * t} - \\ln(${t * t})$`,
        `HEADING Line Equation:`,
        `$y-${t * t} + \\ln(${t * t})=2(x-${t * t} + \\ln(${t * t}))$`,
        `$y = 2x -${t * t}+\\ln(${t * t})$.`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(t, 0, "t=");
      question.addInputGroup(group);
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(-t * t, 0, "p=");
      group2.addInput(t * t, 0, "q=");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ProductRule_Exam_Q1() {
  let count = 0;
  while (true) {
    const ran1 = MCRandom.randomInt(2, 4);
    const ran2 = MCRandom.randomInt(1, 3);
    const f1 = new MCMaths.Fraction(1 / ran1);
    const e1 = new MCMaths.Exponential(1, "e", ran2);

    // Decides which power to "turn on" for the polynomial term.
    let quarticPower = 0;
    let cubicPower = 0;
    let squarePower = 0;

    switch (ran1) {
      case 2:
        squarePower = 1;
        break;

      case 3:
        cubicPower = 1;
        break;

      case 4:
        quarticPower = 1;
        break;

      default:
        break;
    }

    const p1 = new MCMaths.Polynomial([
      quarticPower,
      cubicPower,
      squarePower,
      0,
      0,
    ]);

    const pr = new MCMaths.ProductRule(e1, p1);
    const e2 = e1.derivative();
    const ans_1 = parseFloat(e2.evaluate(1 / ran1).toFixed(2));

    const temp = pr.evaluate(1 / ran1);

    const ans_2 = parseFloat(temp.toFixed(2));

    if (true) {
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `a) Find the value of $\\frac{d}{dx}$ at the point where $ x = ${f1}$ on the exponential curve $${e1}$.`,
        `b) Hence, find the value of $\\frac{dy}{dx}$ at the point where $ x = ${f1}$ on the curve $${e1}x^{${ran1}}$.`,
        `Give your answers to 2 decimal places.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) Find the value of $\\frac{d}{dx}$ at the point where $ x = ${f1}$ on the exponential curve $${e1}$`,
        `$\\frac{d}{dx} = ${e1.derivative().toString()}$`,
        MCMaths.cleaner(
          `$\\therefore$ at $x = ${f1}, \\frac{d}{dx} \\approx ${e2
            .evaluate(1 / ran1)
            .toFixed(2)}$`,
          false,
          false
        ),
        `b) Hence, find the value of $\\frac{dy}{dx}$ at the point where $ x = ${f1}$ on the curve $ y = ${e1}x^{${ran1}}$`,
        `Using the Product Rule:`,
        `$\\frac{dy}{dx} = ${pr.derivative().toString()}$`,
        `At $ x = ${f1}, \\frac{dy}{dx} \\approx ${temp.toFixed(2)}$`,
      ]);

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(ans_1, 0, `a) $\\ $`);
      question.addInputGroup(g1);
      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(ans_2, 0, `b) $\\ $`);
      question.addInputGroup(g2);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ProductRule_Exam_Q2() {
  let count = 0;
  while (true) {
    const ran1 = MCRandom.randomInt(1, 5);
    const ran2 = MCRandom.randomInt(1, 5);
    const ran3 = MCRandom.randomInt(1, 3);
    const coefficient = MCRandom.randomInt(-2, 3);
    const p1 = new MCMaths.Polynomial([coefficient, 0, ran1], "x");
    const l1 = new MCMaths.Logarithm(1, "e", ran3, "x");
    const prod = new MCMaths.ProductRule(p1, l1);

    const ans1 =
      Math.round(
        (coefficient * ran2 * ran2 + ran1) * Math.log(ran3 * ran2) * 100
      ) / 100;
    const ans2 = Math.round(prod.evaluate(ran2) * 100) / 100;

    if (
      true &&
      ran1 !== 0 &&
      coefficient !== 0 &&
      Math.abs(ans1) < 10 &&
      Math.abs(ans2) < 10
    ) {
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$f(x) = (${coefficient}x^2 + ${ran1})${l1.toString()}$`
        )
      );
      question.addParagraph("question", `a) State the value of $f(${ran2})$`);
      question.addParagraph("question", `b) State the value of $f'(${ran2})$`);
      question.addParagraph("question", `Give your answers to $2dp$.`);
      question.addMultipleParagraphs("solution", [
        `a) HEADING $f(${ran2}) = $${MCMaths.cleaner(
          `$(${coefficient}x^2 + ${ran1})${l1.toString()}$`
        ).replace(/x/g, `(${ran2})`)}`,
        `$\\approx ${ans1}$`,
        `b) HEADING Using Product Rule:`,
        prod.derivativeWorking()[1],
        prod.derivativeWorking()[2],
        prod.derivativeWorking()[3],
        prod.derivativeWorking()[4],
        prod.derivativeWorking()[5],
        prod.derivativeWorking()[6],
        `HEADING Evaluating:`,
        `$f'(${ran2})$${prod.derivativeWorking()[6]}`.replace(
          /x/g,
          `(${ran2})`
        ),
        `$\\approx ${ans2}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(ans1, 0.05, `a)`);
      question.addInputGroup(group1);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(ans2, 0.05, `b)`);
      question.addInputGroup(group2);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ProductRule_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const funcList = [
      new MCMaths.Trig(1, "sin", 1, 1),
      new MCMaths.Trig(1, "cos", 1, 1),
      new MCMaths.Trig(1, "tan", 1, 1),
      new MCMaths.Exponential(1, "e", 1),
      new MCMaths.Logarithm(),
      new MCMaths.Polynomial([1, 0, 0]),
    ];
    const f1 = funcList[MCRandom.randomInt(0, funcList.length - 1)];
    const f2 = funcList[MCRandom.randomInt(0, funcList.length - 1)];
    const prod = new MCMaths.ProductRule(f1, f2);
    const k = MCRandom.randomInt(1, 100) / 10;

    // fix double brackets
    function db(str) {
      return str.replace(
        /\\left\(\\left\(\d+\.*\d*\\right\)\\right\)/g,
        function rep(x) {
          return x.replace("\\left(", "").replace("\\right)", "");
        }
      );
    }

    const ansA = Math.round(100 * prod.evaluate(k)) / 100;

    const y1 = f1.evaluate(k) * f2.evaluate(k);
    const y1d = Math.round(y1 * 100) / 100;

    const ansB = Math.round((y1 - prod.evaluate(k) * k) * 100) / 100;
    /* END VARIABLES AND CONSTANTS */
    if (
      f1 !== f2 &&
      Math.abs(ansA) < 10 &&
      Math.abs(ansA) > 1 &&
      Math.abs(ansB) < 10 &&
      Math.abs(ansB) > 1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given $f(x)=${prod.toString()}$,`,
        `a) Find the value of $\\displaystyle f'\\left(${k}\\right)$.`,
        `b) Find the $y$ intercept of the line tangent to $f$ at $${k}$ .`,
        `Give your answers to $2dp$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Using Product Rule:`,
        prod.derivativeWorking()[1],
        prod.derivativeWorking()[2],
        prod.derivativeWorking()[3],
        prod.derivativeWorking()[4],
        prod.derivativeWorking()[5],
        prod.derivativeWorking()[6],
        `HEADING Evaluating:`,
        db(
          `$f'(${k})$${prod.derivativeWorking()[6]}`.replace(
            /x/g,
            `\\left(${k}\\right)`
          )
        ),
        `$\\approx ${ansA}$`,
        `b) HEADING Finding Coordinate:`,
        db(
          `$f(${k}) = ${prod.toString().replace(/x/g, `\\left(${k}\\right)`)}$`
        ),
        `$\\approx ${y1d}$`,
        `HEADING Finding Line:`,
        MCMaths.cleaner(
          `$y-${y1d}= ${ansA}\\left(x-${k}\\right)$`,
          false,
          false
        ),
        MCMaths.cleaner(`$y = ${ansA} x+${ansB}$`, false, false),
        `$\\therefore y$ intercept is $${ansB}$.`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ansA, 0.01, "a)");
      group.addInput(ansB, 0.01, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_ProductRule_Exam_Q4() {
  let count = 0;
  while (true) {
    /** const ran1 = MCRandom.randomInt(-6,6);
    const ran2 = MCRandom.randomInt(-6,6);
    const ran3 = MCRandom.randomInt(-6,6);
    const ran4 = MCRandom.randomInt(-6,6);
    const ran5 = MCRandom.randomInt(2,5);
    const l1 = new MCMaths.Logarithm(1,"e",ran1,"x^2",ran2);
    const p1 = new MCMaths.Polynomial([1,ran3,ran4]);

    if (true && ran1 !== 0 && ran3 !== 0) {
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph("question",MCMaths.cleaner(`$\\frac{d}{dx}(`+l1.toString()+`)$ can be expressed in the form $\\frac{ax}{bx^2+c}$`));
      question.addParagraph("question",`a) State the values of a, b and c.`);
      question.addParagraph("question",MCMaths.cleaner(`b), Hence, find the gradient of the curve $ y= (`+p1.toString()+`)`+l1.toString()+`$ at the point where $x = `+ran5+`$`));
      question.addHeading("solution",`a) State the values of a, b and c.`);
      question.addHeading("solution",MCMaths.cleaner(`b), Hence, find the gradient of the curve $ y= (`+p1.toString()+`)`+l1.toString()+`$ at the point where $x = `+ran5+`$`));

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    } */
    /* START VARIABLES AND CONSTANTS */
    const funcList = [
      new MCMaths.Trig(1, "sin", 1, 1),
      new MCMaths.Trig(1, "cos", 1, 1),
      new MCMaths.Trig(1, "tan", 1, 1),
      new MCMaths.Exponential(1, "e", 1),
      new MCMaths.Logarithm(),
      new MCMaths.Polynomial([1, 0, 0]),
    ];
    const f1 = funcList[MCRandom.randomInt(0, funcList.length - 1)];
    const f2 = funcList[MCRandom.randomInt(0, funcList.length - 1)];
    const prod = new MCMaths.ProductRule(f1, f2);
    const k = MCRandom.randomInt(1, 100) / 10;

    // fix double brackets
    function db(str) {
      return str.replace(
        /\\left\(\\left\(\d+\.*\d*\\right\)\\right\)/g,
        function rep(x) {
          return x.replace("\\left(", "").replace("\\right)", "");
        }
      );
    }

    const ansA = Math.round(100 * prod.evaluate(k)) / 100;

    const y1 = f1.evaluate(k) * f2.evaluate(k);
    const y1d = Math.round(y1 * 100) / 100;

    const ansB = Math.round((y1 - prod.evaluate(k) * k) * 100) / 100;
    /* END VARIABLES AND CONSTANTS */
    if (
      f1 !== f2 &&
      Math.abs(ansA) < 10 &&
      Math.abs(ansA) > 1 &&
      Math.abs(ansB) < 10 &&
      Math.abs(ansB) > 1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given $f(x)=${prod.toString()}$,`,
        `a) Find the value of $\\displaystyle f'\\left(${k}\\right)$.`,
        `b) Find the $y$ intercept of the line tangent to $f$ at $${k}$ .`,
        `Give your answers to $2dp$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Using Product Rule:`,
        prod.derivativeWorking()[1],
        prod.derivativeWorking()[2],
        prod.derivativeWorking()[3],
        prod.derivativeWorking()[4],
        prod.derivativeWorking()[5],
        prod.derivativeWorking()[6],
        `HEADING Evaluating:`,
        db(
          `$f'(${k})$${prod.derivativeWorking()[6]}`.replace(
            /x/g,
            `\\left(${k}\\right)`
          )
        ),
        `$\\approx ${ansA}$`,
        `b) HEADING Finding Coordinate:`,
        db(
          `$f(${k}) = ${prod.toString().replace(/x/g, `\\left(${k}\\right)`)}$`
        ),
        `$\\approx ${y1d}$`,
        `HEADING Finding Line:`,
        MCMaths.cleaner(
          `$y-${y1d}= ${ansA}\\left(x-${k}\\right)$`,
          false,
          false
        ),
        MCMaths.cleaner(`$y = ${ansA} x+${ansB}$`, false, false),
        `$\\therefore y$ intercept is $${ansB}$.`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ansA, 0.01, "a)");
      group.addInput(ansB, 0.01, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_QuotientRule_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 4);
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const p1 = new MCMaths.Polynomial([a, b]);
    const p2 = new MCMaths.Polynomial([1, 0]);
    const q = `\\frac{x}{${p1}}`;
    const grad = 1 / b;
    const tan = new MCMaths.Polynomial([grad, 0]);

    const quotient = new MCMaths.QuotientRule(p2, p1);

    const wrking = quotient.derivativeWorking();
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Given that $\\displaystyle y = ${q}$`,
        `a) Find $ {dy \\over dx}$`,
      ]);

      question.addHeading("solution", `a) Using the quotient rule.`);

      question.addMultipleParagraphs(
        "solution",
        wrking.slice(1, wrking.length)
      );

      question.addParagraph(
        "solution",
        `$\\displaystyle = \\frac{${b}}{(${p1})^2}$`
      );

      question.addMultipleParagraphs("question", [
        `b) Find the equation of the tangent to the curve when $x = 0$, in the form $y = mx + c$`,
      ]);

      question.addHeading(
        "solution",
        `b) Finding the gradient of the tangent line at $x=0$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\displaystyle m = \\frac{${b}}{(${p1
          .toString()
          .replace("x", "(0)")})^2}$`,
        `$\\displaystyle = \\frac{${b}}{(${b})^2}$`,
        MCMaths.cleaner(`$\\ = ${grad}$`),
      ]);

      question.addHeading("solution", `Finding the $y$-intercept.`);

      question.addMultipleParagraphs("solution", [
        `$\\displaystyle c = ${q.replace("x", "(0)").replace("x", "(0)")}$`,
        `$\\ = 0$`,
      ]);

      question.addHeading(
        "solution",
        `Hence the equation of the tangent line is,`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ y = ${tan}$`,
        MCMaths.cleaner(`$\\therefore m = ${grad}, c = 0$`),
      ]);

      question.requiresSelfMarking();

      const group = new MCQuestion.InputGroup(2);
      group.addInput(grad, 0, "b) $\\ m = $");
      group.addInput(0, 0, "b) $\\ c = $");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_QuotientRule_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    let u = new MCMaths.Polynomial([1, a]);
    const u2 = new MCMaths.Polynomial([1, b]);
    u = u.multiply(u2);
    const v = new MCMaths.Polynomial([1, c]);
    const q = `\\frac{${u}}{${v}}`;
    const du = u.derivative();
    let num = v.multiply(du);
    num = num.subtract(u);
    let y0 = u.getCoefficients()[2];
    y0 /= c;
    let grad1 = num.getCoefficients()[2];
    grad1 /= c ** 2;
    const grad2 = -1 / grad1;
    const norm = new MCMaths.Polynomial([grad2, y0]);

    const quotient = new MCMaths.QuotientRule(u, v);
    const wrking = quotient.derivativeWorking();

    /* END VARIABLES AND CONSTANTS */
    if (a !== c && b !== c) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Given than $\\displaystyle y = ${q}$`,
        `a) Find $\\displaystyle {dy \\over dx}$`,
      ]);

      question.addHeading("solution", `a) Using the quotient rule.`);
      question.addMultipleParagraphs(
        "solution",
        wrking.slice(1, wrking.length)
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = \\frac{${num}}{(${v})^2}$`
      );

      question.addMultipleParagraphs("question", [
        MCMaths.cleaner(
          `b) Find the equation of the normal to the curve $\\displaystyle y = ${q}$, which passes through the point $(0, ${y0})$ on the curve.`
        ),
      ]);

      question.addHeading(
        "solution",
        `b) Finding the gradient of the curve at $x = 0$:`
      );

      question.addParagraph(
        "solution",
        `$\\displaystyle = \\frac{${num}}{\\left (${v}\\right )^2}$`.replace(
          /x/g,
          `(0)`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${quotient.evaluate(0)}$`)
      );

      question.addHeading(
        "solution",
        `Hence the gradient of the normal to the curve is,`
      );

      question.addMultipleParagraphs("solution", [
        MCMaths.cleaner(
          `$\\displaystyle \\frac{-1}{${quotient.evaluate(0)}} = ${grad2}$`
        ),
      ]);

      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Since the line passes through $(0, ${y0})$, we know the $y$-intercept is $${y0}$.`
        )
      );

      question.addHeading("solution", `Hence the equation of the normal is,`);

      question.addMultipleParagraphs("solution", [`$\\ y = ${norm}$`]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_QuotientRule_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const ln_m = MCRandom.randomInt(2, 4);
    const ln_c = MCRandom.randomInt(1, 6);
    const ln = new MCMaths.Logarithm(1, "e", ln_m, "x", ln_c);

    const multiple = MCRandom.randomInt(1, 3);
    const x_m = ln_m * multiple;
    const x_c = ln_c * multiple;
    const x = new MCMaths.Polynomial([x_m, x_c]);

    const quotient = new MCMaths.QuotientRule(ln, x);
    const wrking = quotient.derivativeWorking();

    const xa = MCRandom.randomInt(-2 * 4, 2 * 4) / 4;

    const q = Math.log(ln_m * xa + ln_c) / (x_m * xa + x_c);
    const b_answer =
      (ln_m * multiple - x_m * Math.log(ln_m * xa + ln_c)) /
      (x_m * xa + x_c) ** 2;
    const c_answer = -1 / b_answer;
    /* END VARIABLES AND CONSTANTS */
    if (ln_m * xa + ln_c > 0 && b_answer % 0.25 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `The point $P(${xa}, q)$ lies on the curve with equation $y=${quotient.toString()}$`
        )
      );
      question.addParagraph("question", MCMaths.cleaner(`a) Find $q$.`));
      question.addParagraph(
        "question",
        MCMaths.cleaner(`b) Find the gradient of the curve at $P$.`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `c) Calculate the gradient of the normal to the curve at $P$.`
        )
      );

      // solution
      // a
      question.addHeading("solution", MCMaths.cleaner(`a) Let $x=${xa}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$q=${quotient.toString().replace(/x/g, `(${xa})`)}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${q}$`));

      // b
      question.addHeading("solution", `b) Using the quotient rule.`);
      question.addMultipleParagraphs(
        "solution",
        wrking.slice(1, wrking.length)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\displaystyle\\frac{${
            ln_m * multiple
          }-${x_m}\\ln(${ln_m}x+${ln_c})}{(${x.toString()})^2}$`
        )
      );

      question.addHeading("solution", MCMaths.cleaner(`Let $x=${xa}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\displaystyle\\frac{${
            ln_m * multiple
          }-${x_m}\\ln(${ln_m}x+${ln_c})}{(${x.toString()})^2}$`.replace(
            /x/g,
            `(${xa})`
          )
        )
      );
      if (parseFloat(b_answer.toFixed(2)) === b_answer) {
        question.addParagraph("solution", `$=${b_answer}$`);
      } else {
        question.addParagraph("solution", `$=${b_answer.toFixed(5)}$`);
        question.addParagraph("solution", `$\\approx ${b_answer.toFixed(2)}$`);
      }
      // b
      question.addHeading(
        "solution",
        `c) Calculating the gradient of the normal.`
      );
      question.addParagraph(
        "solution",
        `$m_n=\\displaystyle\\frac{-1}{${b_answer}}$`
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${c_answer}$`));

      // answers

      const g = new MCQuestion.InputGroup(1);
      g.addInput(q, 0, "a) $\\ q=$");
      g.addInput(b_answer, 0, "b)");
      g.addInput(c_answer, 0, "c)");
      question.addInputGroup(g);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_QuotientRule_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const trig_m = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const trig_type = ["sin", "cos"][MCRandom.randomInt(0, 1)];
    const trig = new MCMaths.Trig(1, trig_type, 1, trig_m);

    const power = MCRandom.randomInt(2, 4);
    const polyArray = [
      [1, 0, 0],
      [1, 0, 0, 0],
      [1, 0, 0, 0, 0],
    ][power - 2];
    const poly = new MCMaths.Polynomial(polyArray);

    const quotient = new MCMaths.QuotientRule(trig, poly);
    const wrking = quotient.derivativeWorking();

    const bx = (MCRandom.randomInt(2, 10) * Math.PI) / 4;
    let grad = 0;
    if (trig_type === "sin") {
      grad =
        (trig_m * bx * Math.cos(trig_m * bx) - power * Math.sin(trig_m * bx)) /
        bx ** (power + 1);
    } else {
      grad =
        (-trig_m * bx * Math.sin(trig_m * bx) - power * Math.cos(trig_m * bx)) /
        bx ** (power + 1);
    }

    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(grad) > 0.01 && grad !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A curve $C$ has equation $y=h(x)$ where;`
      );
      question.addParagraph(
        "question",
        `$h(x)=\\displaystyle${quotient.toString()}, x>0$`
      );
      question.addParagraph("question", `a) Find $h'(x)$.`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `b) Determine whether $C$ is increasing or decreasing at the point $P$ where $x=${bx}$`
        )
      );

      // solution

      question.addHeading("solution", `a) Using the quotient rule.`);
      question.addMultipleParagraphs(
        "solution",
        wrking.slice(1, wrking.length)
      );
      if (trig_type === "sin") {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$=\\displaystyle\\frac{${trig_m}x\\cos(${trig_m}x)-${power}\\sin(${trig_m}x)}{x^{${
              power + 1
            }}}$`
          )
        );
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$=\\displaystyle\\frac{${-trig_m}x\\sin(${trig_m}x)-${power}\\cos(${trig_m}x)}{x^{${
              power + 1
            }}}$`
          )
        );
      }

      // b
      question.addHeading("solution", MCMaths.cleaner(`b) Let $x=${bx}$`));
      if (trig_type === "sin") {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$f'(${bx})=\\displaystyle\\frac{${trig_m}x\\cos(${trig_m}x)-${power}\\sin(${trig_m}x)}{x^{${
              power + 1
            }}}$`.replace(/x/g, `(${bx})`)
          )
        );
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$f'(${bx})=\\displaystyle\\frac{${-trig_m}x\\sin(${trig_m}x)-${power}\\cos(${trig_m}x)}{x^{${
              power + 1
            }}}$`.replace(/x/g, `(${bx})`)
          )
        );
      }
      question.addParagraph("solution", `$\\approx${grad.toFixed(4)}$`);
      if (grad > 0) {
        question.addParagraph(
          "solution",
          `$\\therefore C$ is increasing at $P$.`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore C$ is decreasing at $P$.`
        );
      }

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_QuotientRule_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const em1 = MCRandom.randomInt(1, 3);
    const ec1 = MCRandom.randomInt(-8, -1);
    // const e1 = new MCMaths.Exponential(em1, 'e', 1, 'x');

    const em2 = MCRandom.randomInt(1, 3);
    const ec2 = MCRandom.randomInt(-8, -1);
    // const e2 = new MCMaths.Exponential(em1, 'e', 1, 'x');
    const fx = `${em1}e^x+${ec1}`;
    const fdx = `${em1}e^x`;
    const gx = `${em2}e^x+${ec2}`;
    const gdx = `${em2}e^x`;

    const differentiated = `\\displaystyle\\frac{${
      em1 * ec2 - em2 * ec1
    }e^x}{(${gx})^2}`;
    let one = 0;
    if (ec1 / em1 < ec2 / em2) {
      one = 1;
    } else {
      one = -1;
    }

    const coeff = [one * em2 ** 2, one * 2 * ec2, one * ec2 ** 2];
    const solveCoeff = [...coeff];
    solveCoeff[1] -= em1 * ec2 - em2 * ec1;

    const poly = new MCMaths.Polynomial(solveCoeff);
    const roots = poly.roots();

    /* END VARIABLES AND CONSTANTS */
    if (
      ec1 / em1 !== ec2 / em2 &&
      roots.length === 2 &&
      roots[0] !== "No Real Roots" &&
      roots[0] % 0.25 === 0 &&
      roots[1] % 0.25 === 0 &&
      roots[0] !== roots[1] &&
      roots[0] > 1 &&
      roots[1] > 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `A function $h(x)$ is defined by:`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$h(x)=\\displaystyle\\frac{${em1}e^x+${ec1}}{${em2}e^x+${ec2}}$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`a) Show that $h'(x)=${differentiated}$`)
      );
      question.addParagraph(
        "question",
        `b) Find the values of $x$ for which $h'(x)=${one}$, giving your answers in the form $\\ln C$ where $C$ is constant.`
      );
      // solution
      // a
      question.addHeading("solution", `a) Using the quotient rule.`);
      question.addParagraph("solution", MCMaths.cleaner(`$f(x)=${fx}$`));
      question.addParagraph("solution", MCMaths.cleaner(`$f'(x)=${fdx}$`));
      question.addParagraph("solution", MCMaths.cleaner(`$g(x)=${gx}$`));
      question.addParagraph("solution", MCMaths.cleaner(`$g'(x)=${gdx}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\left( \\displaystyle\\frac{f(x)}{g(x)} \\right)'=\\displaystyle\\frac{f'(x)g(x)-f(x)g'(x)}{(g(x)^2)}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\displaystyle\\frac{${fdx}(${gx})-(${fx})(${gdx})}{(${gx})^2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${differentiated}$`)
      );
      // b
      question.addHeading("solution", `b) Let $h'(x)=${one}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${differentiated}=${one}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${em1 * ec2 - em2 * ec1}e^x=${coeff[0]}e^{2x}+${coeff[1]}e^x+${
            coeff[2]
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${solveCoeff[0]}e^{2x}+${solveCoeff[1]}e^x+${solveCoeff[2]}=0$`
        )
      );
      question.addHeading(
        "solution",
        `Using the quadratic formula with $e^x=\\displaystyle\\frac{-b \\pm \\sqrt{b^2-4ac} }{2a}, a=${solveCoeff[0]}, b=${solveCoeff[1]}$ and $c=${solveCoeff[2]}$`
      );
      question.addParagraph("solution", MCMaths.cleaner(`$e^x=${roots}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=\\ln(${roots[0]}),\\ln(${roots[1]})$`)
      );

      // answers
      question.requiresSelfMarking();
      const g = new MCQuestion.InputGroup(2);
      g.addInput(roots, 0, "b)");
      question.addInputGroup(g);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_QuotientRule_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const top = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);

    const trig = "sec";
    const trig2 = "cos";

    const fx = `${top}`;
    const fdx = `${0}`;
    const gx = new MCMaths.Trig(1, trig2);
    const gdx = gx.derivative();

    const differentiated_V1 = `\\frac{${top}\\sin(x)}{\\cos^2(x)}`;
    const differentiated_V2 = `${top}\\tan(x)\\sec(x)`;

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `A function $h(x)$ is defined by:`);
      question.addParagraph("question", `$h(x)=${top}\\${trig} (x)$`);
      question.addParagraph(
        "question",
        `a) By writing $h(x)$ as $\\displaystyle\\frac{${top}}{\\${trig2} (x)}$, find $h'(x)$.`
      );
      question.addParagraph(
        "question",
        `A curve $C$ is defined using the equation $y=h(x), -\\frac{\\pi}{2}<x<\\frac{\\pi}{2}$`
      );
      question.addParagraph(
        "question",
        `b) Find the coordinates of the stationary point on $C$.`
      );

      // solution
      // a
      question.addHeading("solution", `a) Using the quotient rule.`);
      question.addParagraph("solution", MCMaths.cleaner(`$f(x)=${fx}$`));
      question.addParagraph("solution", MCMaths.cleaner(`$f'(x)=${fdx}$`));
      question.addParagraph("solution", MCMaths.cleaner(`$g(x)=${gx}$`));
      question.addParagraph("solution", MCMaths.cleaner(`$g'(x)=${gdx}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\left( \\displaystyle\\frac{f(x)}{g(x)} \\right)'=\\displaystyle\\frac{f'(x)g(x)-f(x)g'(x)}{(g(x)^2)}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\displaystyle\\frac{${fdx}(${gx})-(${fx})(${gdx})}{(${gx})^2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${differentiated_V1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${differentiated_V2}$`)
      );
      // b
      question.addHeading("solution", `b) Let $h'(x)=0$.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${differentiated_V2}=0$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$\\tan(x)=0$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=n\\pi$ where $n$ is an integer.`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `As $-\\frac{\\pi}{2}<x<\\frac{\\pi}{2}$, the only valid solution is $n=0$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Stationary point at $x=0$`
      );
      question.addHeading("solution", ` Let $x=0$`);
      question.addParagraph(
        "solution",
        `$h(0)=\\displaystyle\\frac{${top}}{\\cos(0)}$`
      );
      question.addParagraph("solution", `$=${top}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ Stationary point at $(0,${top})$`
      );

      // answers

      question.requiresSelfMarking();

      const g = new MCQuestion.InputGroup(2);
      g.addInput(0, 0, `b) $\\ x=$`);
      g.addInput(top, 0, `b) $\\ y=$`);
      question.addInputGroup(g);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_QuotientRule_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const top_c = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);
    const top_m = MCRandom.randomInt(1, 3);
    const bot_c = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);
    const bot_m = MCRandom.randomInt(1, 3);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        MCMaths.cleaner(
          `Differentiate $\\displaystyle\\frac{${top_m}x+${top_c}}{${bot_m}x+${bot_c}}$`
        )
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle\\frac{${
            top_m * bot_c - bot_m * top_c
          }}{(${bot_m}x+${bot_c})^2}$`
        )
      );

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle\\frac{${
            -top_m * bot_c + bot_m * top_c
          }}{(${bot_m}x+${bot_c})^2}$`
        )
      );

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle\\frac{${2 * top_m * bot_m}x+${
            top_m * bot_c + bot_m * top_c
          }}{(${bot_m}x+${bot_c})^2}$`
        )
      );

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle\\frac{${
            MCRandom.randomInt(-1, 1, 2) * (top_m * bot_c - bot_m * top_c)
          }}{${bot_m}x+${bot_c}}$`
        )
      );

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_QuotientRule_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const trig = ["sin", "cos"][MCRandom.randomInt(0, 1)];
    const trig_m = MCRandom.randomInt(1, 3) * MCRandom.randomInt(-1, 1, 2);
    const trig_inner = MCRandom.randomInt(2, 3) * MCRandom.randomInt(-1, 1, 2);

    const q = new MCMaths.QuotientRule(
      new MCMaths.Polynomial([1, 0]),
      new MCMaths.Trig(trig_m, trig, 1, trig_inner)
    );
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        MCMaths.cleaner(
          `Differentiate $\\displaystyle\\frac{x}{${trig_m}\\${trig}(${trig_inner}x)}$`
        )
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      if (trig === "sin") {
        answer1.addParagraph(
          MCMaths.cleaner(
            `$\\displaystyle\\frac{${trig_m}\\${trig}(${trig_inner}x)-${
              trig_m * trig_inner
            }x\\cos(${trig_inner}x)}{${
              trig_m ** 2
            }\\${trig}^2(${trig_inner}x)}$`
          )
        );
      } else {
        answer1.addParagraph(
          MCMaths.cleaner(
            `$\\displaystyle\\frac{${trig_m}\\${trig}(${trig_inner}x)+${
              trig_m * trig_inner
            }x\\sin(${trig_inner}x)}{${
              trig_m ** 2
            }\\${trig}^2(${trig_inner}x)}$`
          )
        );
      }

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      if (trig === "sin") {
        answer2.addParagraph(
          MCMaths.cleaner(
            `$\\displaystyle\\frac{${trig_m}\\${trig}(${trig_inner}x)+${
              trig_m * trig_inner
            }x\\cos(${trig_inner}x)}{${
              trig_m ** 2
            }\\${trig}^2(${trig_inner}x)}$`
          )
        );
      } else {
        answer2.addParagraph(
          MCMaths.cleaner(
            `$\\displaystyle\\frac{${trig_m}\\${trig}(${trig_inner}x)-${
              trig_m * trig_inner
            }x\\sin(${trig_inner}x)}{${
              trig_m ** 2
            }\\${trig}^2(${trig_inner}x)}$`
          )
        );
      }

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      if (trig === "sin") {
        answer3.addParagraph(
          MCMaths.cleaner(
            `$\\displaystyle\\frac{${trig_m}\\${trig}(${trig_inner}x)-${trig_m}x\\cos(${trig_inner}x)}{${
              trig_m ** 2
            }\\${trig}^2(${trig_inner}x)}$`
          )
        );
      } else {
        answer3.addParagraph(
          MCMaths.cleaner(
            `$\\displaystyle\\frac{${trig_m}\\${trig}(${trig_inner}x)+${trig_m}x\\sin(${trig_inner}x)}{${
              trig_m ** 2
            }\\${trig}^2(${trig_inner}x)}$`
          )
        );
      }

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      if (trig === "sin") {
        answer4.addParagraph(
          MCMaths.cleaner(
            `$\\displaystyle\\frac{${trig_m}\\${trig}(${trig_inner}x)+${trig_m}x\\cos(${trig_inner}x)}{${
              trig_m ** 2
            }\\${trig}^2(${trig_inner}x)}$`
          )
        );
      } else {
        answer4.addParagraph(
          MCMaths.cleaner(
            `$\\displaystyle\\frac{${trig_m}\\${trig}(${trig_inner}x)-${trig_m}x\\sin(${trig_inner}x)}{${
              trig_m ** 2
            }\\${trig}^2(${trig_inner}x)}$`
          )
        );
      }

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_TurningPoints_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const ke = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);

    const c = MCRandom.randomInt(-8, -1);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `A curve $C$ has equation $y=f(x)$ where`
      );
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`$f(x)=e^{${ke}x}+${c}x^2$`)
      );
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`a) Find $f''(x)$ in the form $Ae^{${ke}x}+B$`)
      );
      question.addParagraph(
        `question`,
        `b) Hence determine the the x-coordinate of $C$ at it's point of inflection`
      );
      question.addParagraph(
        `question`,
        `Give your answer in the form of $p \\ln q$`
      );
      // solution
      // a
      question.addHeading(
        `solution`,
        MCMaths.cleaner(`a) Find $f''(x)$ in the form $Ae^{${ke}x}+B$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$f(x)=e^{${ke}x}+${c}x^2$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$f'(x)=${ke}e^{${ke}x}+${2 * c}x$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$f''(x)=${ke * ke}e^{${ke}x}+${2 * c}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore A=${ke * ke}, B=${2 * c}$`)
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(ke * ke, 0, "a) $\\ A = $");
      group1.addInput(2 * c, 0, "a) $\\ B = $");
      question.addInputGroup(group1);
      // b
      question.addHeading(
        `solution`,
        `b) Hence determine the the x-coordinate of $C$ at it's point of inflection`
      );
      question.addHeading(`solution`, `Point of inflection when $f''(x)=0$`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${ke * ke}e^{${ke}x}+${2 * c}=0$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$e^{${ke}x}=${(-2 * c) / ke ** 2}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${ke}x=\\ln(${(-2 * c) / ke ** 2})$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$x=${1 / ke}\\ln(${(-2 * c) / ke ** 2})$`)
      );

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(1 / ke, 0, "b) $\\ p = $");
      group2.addInput((-2 * c) / ke ** 2, 0, "b) $\\ q = $");
      question.addInputGroup(group2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_TurningPoints_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x3 = MCRandom.randomInt(1, 3) * MCRandom.randomInt(-1, 1, 2);
    const x2 = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const x = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);
    const k = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

    const poly = new MCMaths.Polynomial([x3, x2, x, k], "x");
    const d1 = poly.derivative();
    const d2 = d1.derivative();

    const i = MCRandom.randomInt(0, 1);
    const sign = ["<", ">"];
    const type = ["concave", "convex"][i];

    const j = MCRandom.randomInt(0, 1);
    const line = ["tangent", "normal"][j];

    const x_poi = (-2 * x2) / (6 * x3);
    const m = d1.evaluate(x_poi);
    const c = poly.evaluate(x_poi);

    const m2 = [m, -1 / m][j];
    const final_c = -x_poi * m2 + c;
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(m.toFixed(3)) === m &&
      parseFloat(c.toFixed(3)) === c &&
      parseFloat(final_c.toFixed(3)) === final_c &&
      Math.abs(c) < 20 &&
      Math.abs(m) < 20 &&
      Math.abs(m) > 1 / 20
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `A curve $C$ has equation`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$y=${poly.toString()}$`)
      );
      question.addParagraph(
        "question",
        `a) Find the range of values of $x$ where $C$ is ${type}`
      );
      question.addParagraph(
        "question",
        `b) Find an equation for the ${line} to $C$ at it's point of inflection, giving your answer in the form $y=mx+c$`
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) Find the range of values of $x$ where $C$ is ${type}`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=${poly.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{dy}{dx}=${d1.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{d^{\\ 2}y}{dx^2}=${d2.toString()}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `$C$ is ${type} when $\\frac{d^{\\ 2}y}{dx^2}${sign[i]}0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${d2.toString()}${sign[i]}0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${6 * x3}x${sign[i]}${-2 * x2}$`)
      );
      if (x3 < 0) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x${sign[(i + 1) % 2]}${x_poi}$`)
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput(x_poi, 0, `a) $\\ x${sign[(i + 1) % 2]} $`);
        question.addInputGroup(group1);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x${sign[i]}${x_poi}$`)
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput(x_poi, 0, `a) $\\ x${sign[i]} $`);
        question.addInputGroup(group1);
      }
      // b
      question.addHeading(
        "solution",
        `b) Find an equation for the ${line} to $C$ at it's point of inflection, giving your answer in the form $y=mx+c$`
      );
      question.addHeading(
        "solution",
        `Point of inflection when $\\frac{d^{\\ 2}y}{dx^2}=0$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${6 * x3}x+${2 * x2}=0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${x_poi}$`)
      );
      question.addHeading("solution", MCMaths.cleaner(`Let $x=${x_poi}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=${poly.toString().replace(/x/g, `(${x_poi})`)}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${c}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{dy}{dx}=${d1.toString().replace(/x/g, `(${x_poi})`)}$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${m}$`));
      question.addHeading(
        "solution",
        MCMaths.cleaner(`$\\therefore$ gradient of ${line} is $${m2}$`)
      );
      question.addHeading("solution", `Sub values into straight line equation`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y+${-c}=${m2}(x+${-x_poi})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=${m2}x+${final_c}$`)
      );

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(m2, 0, "b) $\\ m =$");
      group2.addInput(final_c, 0, "b) $\\ c =$");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_TurningPoints_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const coeff = MCRandom.randomInt(-10, -4);
    const d = MCRandom.randomInt(2, 5);
    const k =
      (MCRandom.randomInt(1, Math.floor((3 * d) / 4)) / d) *
      MCRandom.randomInt(-1, 1, 2);

    const t = MCRandom.randomInt(1, 20);

    const c_ans = Math.abs(coeff * k) * Math.sin(k * t);
    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(c_ans) > 0.1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // image
      const Image = new MCQuestion.Image("Leo/bucket.svg", "height", 30);
      // Image.addOverlay(`$${angle}^\\circ$`, 80, 90, 0.8);
      question.addImage("question", Image);
      // question
      question.addParagraph(
        `question`,
        `A group of students investigate the volume of water held in a self-tipping bucket. At the start of their experiment the bucket is completely empty. They assume that the bucket takes the same amount of time to fill as it does to empty and fills to its capacity before tipping.`
      );
      question.addParagraph(
        `question`,
        `They model the amount of water $L$, in litres, at time $t$ seconds using the equation`
      );
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`$L=${coeff}\\cos(${k}t)+${-coeff}$`)
      );
      question.addParagraph(
        `question`,
        `a) Find, to the nearest second, the amount of time the bucket takes to fill`
      );
      question.addParagraph(
        `question`,
        `b) Hence, find the capacity of the bucket`
      );
      question.addParagraph(
        `question`,
        `c) Determine the rate of change of $L$, to $2$ decimal places, after $${t}$ seconds `
      );
      // solution
      // a
      question.addHeading(
        `solution`,
        `a) Find, to the nearest second, the amount of time the bucket takes to fill`
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$L=${coeff}\\cos(${k}t)+${-coeff}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\frac{dL}{dt}=${Math.abs(coeff * k)}\\sin(${k}t)$`)
      );
      question.addHeading(
        `solution`,
        `Reaches capacity when $\\frac{dL}{dt}=0$`
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${Math.abs(coeff * k)}\\sin(${k}t)=0$`)
      );
      question.addParagraph(`solution`, MCMaths.cleaner(`$\\sin(${k}t)=0$`));
      question.addParagraph(`solution`, MCMaths.cleaner(`$${k}t=0,\\pi$`));
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$t=0,${Math.abs(1 / k)}\\pi$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$\\therefore t = ${Math.abs(1 / k)}\\pi$ as bucket empty at $t=0$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\approx ${Math.round(Math.abs(1 / k) * Math.PI)}$s`)
      );
      // b
      question.addHeading(
        `solution`,
        `b) Hence, find the capacity of the bucket`
      );
      question.addHeading(
        `solution`,
        MCMaths.cleaner(`Evaluate $L$ when $t=${Math.abs(1 / k)}\\pi$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$L=${coeff}\\cos(${k}(${Math.abs(1 / k)}\\pi))+${-coeff}$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=${Math.abs(2 * coeff)}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$\\therefore$ Bucket has a capacity of $${Math.abs(
            2 * coeff
          )}$ litres`
        )
      );
      // c
      question.addHeading(
        `solution`,
        `c) Determine the rate of change of $L$, to $2$ decimal places, after $${t}$ seconds `
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\frac{dL}{dt}=${Math.abs(coeff * k)}\\sin(${k}t)$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$\\frac{dL}{dt}|_{t=${t}}=${Math.abs(coeff * k)}\\sin(${k}(${t}))$`
        )
      );
      question.addParagraph(`solution`, `$\\approx ${c_ans.toFixed(4)}...$`);
      question.addParagraph(`solution`, `$\\approx ${c_ans.toFixed(2)}$`);
      //
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(Math.round(Math.abs(1 / k) * Math.PI), 0, "a)");
      group1.addInput(Math.abs(2 * coeff), 0, "b)");
      group1.addInput(parseFloat(c_ans.toFixed(2)), 0, "c)");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_TurningPoints_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x2 = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const x = MCRandom.randomInt(-10, -4);
    const k = MCRandom.randomInt(1, 15) * MCRandom.randomInt(-1, 1, 2);

    const poly = new MCMaths.Polynomial([1, x2, x, k], "x");
    const d1 = poly.derivative();
    const d2 = d1.derivative();

    const x_poi = (-2 * x2) / 6;
    const x_used = Math.round(x_poi);
    const y_used = poly.evaluate(x_used);

    /* END VARIABLES AND CONSTANTS */
    if (parseFloat(y_used.toFixed(3)) === y_used && y_used < 20) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(3);
      // question
      // question.addGraph(poly.graph());
      question.addParagraph(`The curve $C$ has equation $y=f(x)$ where `);
      question.addParagraph(MCMaths.cleaner(`$f''(x)=${d2.toString()}$`));
      question.addParagraph(
        MCMaths.cleaner(
          `Select the statement below that's true at point $P(${x_used},${y_used})$ on $C$`
        )
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$P$ lies on a concave section of $C$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$P$ lies on a convex section of $C$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$P$ is a point of inflection on $C$`);

      question.addAnswer(a1, d2.evaluate(x_used) < 0);
      question.addAnswer(a2, d2.evaluate(x_used) > 0);
      question.addAnswer(a3, d2.evaluate(x_used) === 0);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Differentiation_TurningPoints_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const r1 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const r2 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const m = MCRandom.randomInt(-1, 1, 2);

    const dydx = new MCMaths.Polynomial([m, m * r1 + m * r2, r1 * r2 * m], "x");
    const point = [[-r1, -r2][MCRandom.randomInt(0, 1)], (-r1 - r2) / 2][
      MCRandom.randomInt(0, 1)
    ];
    const y_tp = dydx.evaluate((-r1 - r2) / 2);

    const d2 = dydx.derivative();

    const index = MCRandom.randomInt(0, 1);
    const con = ["concave", "convex"][index];
    const inc = ["decreasing", "increasing"][index];

    const a1_ans =
      (con === "concave" && d2.evaluate(point) < 0) ||
      (con === "convex" && d2.evaluate(point) > 0);
    const a2_ans =
      (inc === "increasing" && dydx.evaluate(point) > 0) ||
      (inc === "decreasing" && dydx.evaluate(point) < 0);

    const answers = [
      a1_ans,
      a2_ans,
      point === (-r1 - r2) / 2,
      point === -r1 || point === -r2,
    ];
    let c = -1;
    for (let i = 0; i < 4; i += 1) {
      if (answers[i]) {
        c += 1;
      }
    }
    const amount = ["ONE", "TWO"][c];
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(r1 - r2) > 2 &&
      parseFloat(y_tp.toFixed(3)) === y_tp &&
      r1 > r2
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addGraph(dydx.graph());
      question.addParagraph(`A curve $C$ has equation $y=f(x)$`);
      if (point === (-r1 - r2) / 2) {
        question.addParagraph(
          MCMaths.cleaner(
            `The graph above shows the curve $y=f'(x)$ which has a turning point at $P(${
              (-r1 - r2) / 2
            },${y_tp})$`
          )
        );
      } else {
        question.addParagraph(
          MCMaths.cleaner(
            `The graph above shows the curve $y=f'(x)$ which has roots at $R_1(${-r1},0)$ and $R_2(${-r2},0)$`
          )
        );
      }
      question.addParagraph(
        MCMaths.cleaner(
          `Select from the following what is true for $C$ at point $P$ where $x=${point}$`
        )
      );
      question.addHeading(`Choose ${amount}`);
      //
      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$C$ is ${con} at $P$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$C$ is ${inc} at $P$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$P$ is a point of inflection on $C$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$P$ is a stationary point of $C$`);

      question.addAnswer(a1, a1_ans);
      question.addAnswer(a2, a2_ans);
      question.addAnswer(a3, point === (-r1 - r2) / 2);
      question.addAnswer(a4, point === -r1 || point === -r2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const rodForce = MCRandom.randomInt(10, 20);
    const theta = MCRandom.randomInt(30, 60);
    const thetaRadians = theta * (Math.PI / 180);
    const boxMass = MCRandom.randomInt(2, 9);

    const R = (boxMass * 9.8) / Math.cos(thetaRadians);
    const mu =
      (1 / Math.tan(thetaRadians)) *
      (1 -
        (rodForce * Math.sin(thetaRadians) * Math.cos(thetaRadians)) /
          (boxMass * 9.8));

    /* END VARIABLES AND CONSTANTS */
    if (mu < 0.9) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The diagram below shows a box of mass $${boxMass}kg$ held up by a rod providing a force of $${rodForce}N$ on a rough plane inclined at $${theta}^{\\circ}$, with coefficient of friction $\\mu$:`
      );

      const myImage = new MCQuestion.Image(
        "Matt/boxOnInclinedPlane1.svg",
        "width",
        25
      );
      myImage.addOverlay(`$${rodForce}N$`, 67, 37);
      myImage.addOverlay(`$${theta}^{\\circ}$`, 82, 75);
      question.addImage("question", myImage);

      question.addMultipleParagraphs("question", [
        `a) Find the normal reaction force, $R$, of the plane on the box.`,
        `b) Find the coefficient of friction, $\\mu$, between the box and the inclined plane.`,
        `Assume that $g = 9.8ms^{-2}$ and give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Resolving the forces of the box perpendicular to the plane we can see that:`,
        `$R\\cos(${theta}^{\\circ}) = ${boxMass}g$`,
        `$\\displaystyle R = \\frac{${boxMass}g}{\\cos(${theta}^{\\circ})}$`,
        `$\\therefore R = ${R.toPrecision(3)}N$`,

        `HEADING b) Resolving the forces along the plane we can see that:`,
        `$\\displaystyle \\mu R + ${rodForce}\\cos(${theta}^{\\circ}) = \\frac{${boxMass}g}{\\sin(${theta}^{\\circ})}$`,
        `Since $\\displaystyle R = \\frac{${boxMass}g}{\\cos(${theta}^{\\circ})}$`,
        `$\\displaystyle \\implies \\frac{${boxMass}\\mu g}{\\cos(${theta}^{\\circ})} + ${rodForce}\\cos(${theta}^{\\circ}) = \\frac{${boxMass}g}{\\sin(${theta}^{\\circ})}$`,
        `$\\displaystyle \\mu \\tan(${theta}^{\\circ}) + \\frac{${rodForce}\\sin(${theta}^{\\circ})\\cos(${theta}^{\\circ})}{${boxMass}g} = 1$`,
        `$\\displaystyle \\mu \\tan(${theta}^{\\circ}) = 1 - \\frac{${rodForce}\\sin(${theta}^{\\circ})\\cos(${theta}^{\\circ})}{${boxMass}g}$`,
        `$\\displaystyle \\mu  = \\frac{1}{\\tan(${theta}^{\\circ})}\\left(1 - \\frac{${rodForce}\\sin(${theta}^{\\circ})\\cos(${theta}^{\\circ})}{${boxMass}g}\\right)$`,
        `$\\mu  = ${mu.toPrecision(3)}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(parseFloat(R.toPrecision(3)), 0.1, `a) $\\text{ } R =$`);
      group2.addInput(
        parseFloat(mu.toPrecision(3)),
        0.001,
        `b) $\\text{ } \\mu =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_Exam_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const theta = MCRandom.randomInt(20, 60);
    const thetaRadians = theta * (Math.PI / 180);
    const boxMass = MCRandom.randomInt(2, 9);
    const smoothSlopeAcceleration = 9.8 * Math.sin(thetaRadians);

    const s = MCRandom.randomInt(2, 5);
    const t = MCRandom.randomInt(2, 5);

    const roughSlopeAcceleration = (2 * s) / t ** 2;
    const mu =
      (9.8 * Math.sin(thetaRadians) - roughSlopeAcceleration) /
      (9.8 * Math.cos(thetaRadians));

    /* END VARIABLES AND CONSTANTS */
    if (mu > 0.1 && mu < 0.9 && roughSlopeAcceleration > 0.8) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A wooden box is placed at rest on a smooth slope inclined at $${theta}^{\\circ}$ to the horizontal.`,
        `The box starts to uniformly accelerate down the slope.`,
        `a) Find the acceleration, $a_1$, of the box`,
        `It is now discovered that the slope is actually rough and travels $${s}m$ down the slope in $${t}s$.`,
        `b) Find the new, revised acceleration, $a_2$.`,
        `c) Find the coefficient of friction of friction, $\\mu$, between the wooden box and the slope.`,
        `Assume that $g = 9.8ms^{-2}$ and give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Resolving forces in the direction of the slope we see that:`,
        `$${boxMass}g\\sin(${theta}^{\\circ}) = ${boxMass}a_1$`,
        `$g\\sin(${theta}^{\\circ}) = a_1$`,
        `$\\therefore a_1 = ${smoothSlopeAcceleration.toPrecision(3)}ms^{-2}$.`,

        `HEADING b) Rearranging $s = ut + \\frac{1}{2}at^2$ for $a$ and since $u = 0$ we can see that:`,
        `$\\displaystyle a = \\frac{2s}{t^2}$`,
        `$\\displaystyle a_2 = \\frac{2\\times${s}}{${t}^2}$`,
        `$\\therefore a_2 = ${roughSlopeAcceleration.toPrecision(3)}ms^{-2}$`,

        `HEADING c) Again resolving forces in the direction of the slope we see that:`,
        `$${boxMass}g\\sin(${theta}^{\\circ}) - \\mu R = ${boxMass}\\times${roughSlopeAcceleration.toPrecision(
          3
        )}$`,
        `$R = ${boxMass}g\\cos(${theta}^{\\circ})$`,
        `So $${boxMass}g\\sin(${theta}^{\\circ}) - \\mu (${boxMass}g\\cos(${theta}^{\\circ})) = ${boxMass}\\times${roughSlopeAcceleration.toPrecision(
          3
        )}$`,
        `$\\mu (${boxMass}g\\cos(${theta}^{\\circ})) = ${boxMass}g\\sin(${theta}^{\\circ}) - ${boxMass}\\times${roughSlopeAcceleration.toPrecision(
          3
        )}$`,
        `$\\displaystyle \\mu = \\frac{${boxMass}(g\\sin(${theta}^{\\circ}) - ${roughSlopeAcceleration.toPrecision(
          3
        )})}{${boxMass}g\\cos(${theta}^{\\circ})}$`,
        `$\\displaystyle \\mu = \\frac{g\\sin(${theta}^{\\circ}) - ${roughSlopeAcceleration.toPrecision(
          3
        )}}{g\\cos(${theta}^{\\circ})}$`,
        `$\\therefore \\mu = ${mu.toPrecision(3)}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      const group3 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(smoothSlopeAcceleration.toPrecision(3)),
        0.01,
        `a) $\\text{ } a_1 =$`
      );
      group2.addInput(
        parseFloat(roughSlopeAcceleration.toPrecision(3)),
        0.01,
        `b) $\\text{ } a_2 =$`
      );
      group3.addInput(
        parseFloat(mu.toPrecision(3)),
        0.001,
        `c) $\\text{ } \\mu =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const massA = MCRandom.randomInt(2, 9);
    const massB = MCRandom.randomInt(2, 9);
    const theta = MCRandom.randomInt(20, 60);
    const thetaRadians = theta * (Math.PI / 180);
    const externalForce = MCRandom.randomInt(40, 180);
    const s = MCRandom.randomInt(5, 15);
    const t = MCRandom.randomInt(2, 9);
    const a = new MCMaths.Fraction((2 * s) / t ** 2);

    const working1 = new MCMaths.Fraction(
      (a.numerator * (massA + massB)) / a.denominator
    );

    const mu =
      (externalForce * Math.cos(thetaRadians) - working1.toFloat()) /
      ((massA + externalForce * Math.sin(thetaRadians) - massB) * 9.8);

    const T = massA * (a.toFloat() + 9.8 * mu);
    /* END VARIABLES AND CONSTANTS */
    if (
      massA < massB &&
      mu > 0.1 &&
      mu < 0.9 &&
      (massA + externalForce * Math.sin(thetaRadians) - massB) * 9.8 > 5
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Two masses, $A$ and $B$, with mass $${massA}kg$ and $${massB}$ respectively are connected by an inextensible rope with tension $T$.`
      );
      question.addParagraph(
        "question",
        `The two masses are being pulled by an external force of $${externalForce}N$ at $${theta}^{\\circ}$ to the horizontal.`
      );

      const myImage = new MCQuestion.Image(
        `Matt/twoBoxesOnHoriztonalPlane1.svg`,
        "width",
        30
      );
      myImage.addOverlay(`$A$`, 30.5, 46);
      myImage.addOverlay(`$${massA}kg$`, 30.5, 62);
      myImage.addOverlay(`$B$`, 64, 46);
      myImage.addOverlay(`$${massB}kg$`, 64, 62);
      myImage.addOverlay(`$${theta}^{\\circ}$`, 77, 59);
      myImage.addOverlay(`$${externalForce}N$`, 90, 10);
      question.addImage("question", myImage);

      question.addMultipleParagraphs("question", [
        `The boxes move $${s}m$ in $${t}s$.`,
        `The coefficient of friction between the boxes and the plane is $\\mu$`,
        `a) Find the acceleration of $B$.`,
        `b) Find the coefficient of friction, $\\mu$.`,
        `c) Find the tension in the rope, $T$.`,
        `Assume that $g = 9.8ms^{-2}$ and give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Since $s = ut + \\frac{1}{2}at^{2}$:`,
        `$\\implies ${s} = \\frac{1}{2}a \\times ${t}^{2}$`,
        `So $a = \\frac{2\\times${s}}{${t ** 2}}$`,
        `$a = ${a.toString()}$`,
        `$\\therefore a = ${a.toFloat().toPrecision(3)}ms^{-2}$`,

        `HEADING b) Resolving forces with respect to the horizontal about mass $B$ we obtain:`,
        `$${externalForce}\\cos(${theta}^{\\circ}) - ${massA}g\\mu - (${externalForce}\\sin(${theta}^{\\circ}) - ${massB}g)\\mu = ${
          massA + massB
        }\\times${a.toString()}$`,
        `$g\\mu(${massA} + ${externalForce}\\sin(${theta}^{\\circ}) - ${massB}) = ${externalForce}\\cos(${theta}^{\\circ}) - ${
          massA + massB
        } \\times ${a.toString()}$`,
        `$\\displaystyle \\mu = \\frac{${externalForce}\\cos(${theta}^{\\circ}) - ${working1.toString()}}{(${massA} + ${externalForce}\\sin(${theta}^{\\circ}) - ${massB})g}$`,
        `$\\therefore \\mu = ${mu.toPrecision(3)}$`,

        `HEADING c) Resolving forces to the horizontal about mass $A$ we obtain:`,
        `$T - ${massA}g\\mu = ${massA}\\times${a.toString()}$`,
        `$T  = \\left(${massA}\\times${a.toString()}\\right) + ${massA}\\times 9.8 \\times ${mu.toPrecision(
          3
        )}$`,
        `$\\therefore T = ${T.toPrecision(3)}N$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      const group3 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(a.toFloat().toPrecision(3)),
        0.001,
        `a) $\\text{ } a =$`
      );
      group2.addInput(
        parseFloat(mu.toPrecision(3)),
        0.001,
        `b) $\\text{ } \\mu =$`
      );
      group3.addInput(
        parseFloat(T.toPrecision(3)),
        0.001,
        `c) $\\text{ } T =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const drivingForce = MCRandom.randomInt(50, 100) * 100;
    const mu = MCRandom.randomInt(100, 900) / 1000;
    const u = MCRandom.randomInt(13, 22);
    const v = MCRandom.randomInt(31, 44);
    const t = MCRandom.randomInt(5, 30);
    const a = new MCMaths.Fraction((v - u) / t);

    const M = drivingForce / (3 * 9.8 * mu + a.toFloat());
    const T = M * (a.toFloat() + 9.8 * mu);

    const T_stringWorkaround = parseFloat((T / 1000).toPrecision(3)) * 1000;

    /* END VARIABLES AND CONSTANTS */
    if (a > 2 && a < 5 && M < 1000 && M > 350) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A train is towing $3$ cargo containers, each with mass $M$. The train has a driving force of $${drivingForce}N$.`,
        `Given that the train is uniformly accelerating from a speed of $${u}ms^{-1}$ to $${v}ms^{-1}$ in a time of $${t}s$,`,
        `a) Find the acceleration of the train.`,
        `Given that the coefficient of friction between the containers and the track is $\\mu = ${mu}$,`,
        `b) Find $M.$`,
        `Given that each container is connected by a coupler that feels a force of $T$,`,
        `c) Find $T$.`,
        `Assume that $g = 9.8ms^{-2}$ and give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Since $v = u + at$:`,
        `$a = \\frac{${v}-${u}}{${t}}$`,
        `$= ${a.toString()}$`,
        `$\\therefore a = ${a.toFloat().toPrecision(3)}ms^{-2}$.`,

        `HEADING b) Resolving forces with respect to the horizontal we see that:`,
        `$${drivingForce} - 3Mg\\mu = ${a.toString()}M$`,
        `$M(3g\\mu + ${a.toString()}) = ${drivingForce}$`,
        `$\\displaystyle M = \\frac{${drivingForce}}{\\left(3g\\mu + ${a.toString()}\\right)}$`,
        `$\\therefore M = ${M.toPrecision(3)}kg$.`,

        `HEADING c) Using part b) we know that:`,
        `$T - ${M.toPrecision(3)}g\\mu = ${M.toPrecision(
          3
        )}\\times${a.toString()}$`,
        `$T = ${M.toPrecision(3)}\\times${a.toString()} + ${M.toPrecision(
          3
        )}g\\mu$`,
        `$T = ${T_stringWorkaround.toFixed(0)}N$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      const group3 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(a.toFloat().toPrecision(3)),
        0.1,
        `a) $\\text{ } a =$`
      );
      group2.addInput(parseFloat(M.toPrecision(3)), 1, `b) $\\text{ } M =$`);
      group3.addInput(parseFloat(T.toPrecision(3)), 10, `c) $\\text{ } T =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const massP = MCRandom.randomInt(2, 9);
    const massQ = MCRandom.randomInt(2, 9);

    const working1 = new MCMaths.Fraction(massP / massQ);
    const working2 = new MCMaths.Fraction(
      (massQ * working1.numerator) / working1.denominator
    );
    const working3 = new MCMaths.Fraction(working1.toFloat() + 1);

    const T = new MCMaths.Fraction(
      (2 * massP * working3.denominator) / working3.numerator
    ); // Actually = T/g
    const a = new MCMaths.Fraction((massP - T.toFloat()) / massP); // Actually = a/g

    /* END VARIABLES AND CONSTANTS */
    if (massP > massQ && a.toFloat() * 9.8 < 5) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The diagram below shows $2$ masses, $P$ and $Q$ with mass $${massP}kg$ and $${massQ}kg$ respectively. They are connected by an inextensible rope under tension $T$ hanging over a pulley.`
      );

      const myImage = new MCQuestion.Image(
        `Matt/hangingMassProblem1.svg`,
        "height",
        20
      );
      myImage.addOverlay(`$P$`, 36, 69);
      myImage.addOverlay(`$Q$`, 61, 88);
      question.addImage("question", myImage);

      question.addMultipleParagraphs("question", [
        `Given that mass $P$ starts to uniformly accelerate downwards,`,
        `a) Find the tension $T$.`,
        `b) Find the acceleration that mass $P$ moves with.`,
        `Assume that $g = 9.8ms^{-2}$ and give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Resolving forces with respect to the vertical we can see that:`,
        `$${massP}g - T = ${massP}a$`,
        `And $T - ${massQ}g = ${massQ}a$`,
        `HEADING So solving the system of equations we obtain:`,
        `$${massP}g - T = ${working1.toString()}(T - ${massQ}g)$`,
        `$${massP}g - T = ${working1.toString()}T - ${working2.toString()}g$`,
        `$g\\left(${massP} + ${working2.toString()}\\right) = ${working3.toString()}T$`,
        `$\\displaystyle T = \\frac{g\\left(${massP} + ${working2.toString()}\\right)}{${working3.toString()}}$`,
        `$\\displaystyle T = ${T.toString()}g$`,
        `$\\therefore T = ${(T.toFloat() * 9.8).toPrecision(3)}N$`,

        `HEADING b) Using part a) the equation $${massP}g - T = ${massP}a$ becomes:`,
        `$${massP}g - ${T.toString()}g = ${massP}a$`,
        `$\\displaystyle a = \\frac{g\\left(${massP} - ${T.toString()}\\right)}{${massP}}$`,
        `$a = ${a.toString()}g$`,
        `$\\therefore a = ${(a.toFloat() * 9.8).toPrecision(3)}ms^{-2}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat((T.toFloat() * 9.8).toPrecision(3)),
        0.1,
        `a) $\\text{ } T =$`
      );
      group2.addInput(
        parseFloat((a.toFloat() * 9.8).toPrecision(3)),
        0.01,
        `b) $\\text{ } a =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const north_Force = MCRandom.randomInt(5, 15);
    const northEast_Force = MCRandom.randomInt(5, 15);
    const theta = MCRandom.randomInt(46, 80);
    const thetaRadians = theta * (Math.PI / 180);
    const particleMass = MCRandom.randomInt(2, 9);
    const particleAcceleration = MCRandom.randomInt(2, 6);

    const F_1 = northEast_Force * Math.cos(thetaRadians);
    const F_2 =
      north_Force +
      northEast_Force * Math.sin(thetaRadians) -
      particleMass * particleAcceleration;

    /* END VARIABLES AND CONSTANTS */
    if (F_2 > 1 && F_2 < 10) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A particle of mass $${particleMass}kg$ is being acted on by various forces.`,
        `The diagram below shows a bird's eye view of the particle which is on top of a smooth surface:`,
      ]);

      const myImage = new MCQuestion.Image(
        `Matt/forcesActingOnParticle1.svg`,
        "width",
        20
      );
      myImage.addOverlay(`$F_1$`, 2, 51);
      myImage.addOverlay(`$F_2$`, 51, 100);
      myImage.addOverlay(`$${north_Force}N$`, 51, 4);
      myImage.addOverlay(`$${northEast_Force}N$`, 70, 4);
      myImage.addOverlay(`$${theta}^{\\circ}$`, 59, 47);
      question.addImage("question", myImage);

      question.addMultipleParagraphs("question", [
        `Given that the particle is moving North with an acceleration of $${particleAcceleration}ms^{-2}$,`,
        `a) Find $F_1$.`,
        `b) Find $F_2$.`,
        `Give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Resolving forces horizontally we can see that:`,
        `$F_1 = 6\\cos(${theta}^{\\circ})$`,
        `$\\therefore F_1 = ${F_1.toPrecision(3)}N$.`,

        `HEADING b) Resolving forces vertically we can see that:`,
        `$${north_Force} + ${northEast_Force}\\sin(${theta}^{\\circ}) - F_2 = ${particleMass}\\times${particleAcceleration}$`,
        `$\\implies F_2 = ${north_Force} + ${northEast_Force}\\sin(${theta}^{\\circ}) - ${particleMass}\\times${particleAcceleration}$`,
        `$\\therefore F_2 = ${F_2.toPrecision(3)}N$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(F_1.toPrecision(3)),
        0.01,
        `a) $\\text{ } F_1 =$`
      );
      group2.addInput(
        parseFloat(F_2.toPrecision(3)),
        0.01,
        `b) $\\text{ } F_2 =$`
      );
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const northForce = MCRandom.randomInt(3, 10);
    const eastForce = MCRandom.randomInt(3, 10);
    const force_PSquared = northForce ** 2 + eastForce ** 2;
    const force_P = Math.sqrt(force_PSquared);
    const tanTheta = new MCMaths.Fraction(eastForce / northForce);
    const thetaRadians = Math.atan(tanTheta.toFloat());
    const theta = thetaRadians * (180 / Math.PI);

    /* END VARIABLES AND CONSTANTS */
    if (theta < 45) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `A particle has three forces acting in equilibrium on the particle as shown in the diagram below:`
      );

      const myImage = new MCQuestion.Image(
        `Matt/forcesActingOnParticle2.svg`,
        "width",
        22.5
      );
      myImage.addOverlay(`$${northForce}N$`, 51, 3);
      myImage.addOverlay(`$${eastForce}N$`, 99, 51);
      myImage.addOverlay(`$\\theta$`, 46, 65);
      myImage.addOverlay(`$P$`, 13, 97);
      question.addImage("question", myImage);

      question.addMultipleParagraphs("question", [
        `Find the force $P$.`,
        `Find the angle $\\theta$.`,
        `Give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Since the particle is in equilibrium we can see that:`,
        `$P^2 = ${northForce}^2 + ${eastForce}^2$`,
        `$P = \\sqrt{${northForce}^2 + ${eastForce}^2}$`,
        `$P = \\sqrt{${force_PSquared}}$`,
        `$P = ${force_P.toPrecision(3)}N$`,

        `HEADING b) Using some trigonometry we can see that:`,
        `$\\tan(\\theta) = ${tanTheta.toString()}$`,
        `$\\theta = \\tan^{-1}\\left(${tanTheta}\\right)$`,
        `$\\theta = ${theta.toPrecision(3)}^{\\circ}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(force_P.toPrecision(3)),
        0.01,
        `a) $\\text{ } P =$`
      );
      group2.addInput(
        parseFloat(theta.toPrecision(3)),
        0.1,
        `b) $\\text{ } \\theta =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const boxMass = MCRandom.randomInt(2, 9);
    const sphereMass = MCRandom.randomInt(10, 20);

    const a = MCRandom.randomInt(2, 5);
    const T = sphereMass * (9.8 - a);
    const mu = (T - boxMass * a) / (boxMass * 9.8);

    /* END VARIABLES AND CONSTANTS */
    if (mu < 0.9 && mu > 0.1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A box of mass $${boxMass}kg$ is initially at rest on a rough plane, where the coefficient of friction between the box and plane is $\\mu$.`,
        `Then an inextensible rope is attached to the box and hung over a pulley where a spherical mass of mass $${sphereMass}kg$ is attached to the other end.`,
        `This is shown in the diagram below:`,
      ]);
      const myImage = new MCQuestion.Image(
        `Matt/freeBodyPulleyProblem1.svg`,
        "width",
        25
      );
      myImage.addOverlay(`$${boxMass}kg$`, 41, 20);
      myImage.addOverlay(`$${sphereMass}kg$`, 90, 67);
      question.addImage("question", myImage);

      question.addMultipleParagraphs("question", [
        `Given that the box begins to move with acceleration $${a}ms^{-2}$`,
        `a) Find the tension in the rope, $T$.`,
        `b) Find $\\mu$.`,
        `Assume that $g = 9.8ms^{-2}$ and give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Resolving forces vertically and using $F = ma$ we can see that:`,
        `$${sphereMass}g - T = ${sphereMass}\\times${a}$`,
        `$T = ${sphereMass}(g - ${a})$`,
        `$T = ${T.toPrecision(3)}N$.`,

        `HEADING b) Resolving forces horizontally and using part b) we can see that:`,
        `$${T.toPrecision(3)} - ${boxMass}g\\mu = ${boxMass}\\times${a}$`,
        `$${boxMass}g\\mu = ${T.toPrecision(3)} - ${boxMass}\\times${a}$`,
        `$\\displaystyle \\mu = \\frac{${T.toPrecision(
          3
        )} - ${boxMass}\\times${a}}{${boxMass}g}$`,
        `$\\mu = ${mu.toPrecision(3)}$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(parseFloat(T.toPrecision(3)), 0.1, `a) $\\text{ } T =$`);
      group2.addInput(
        parseFloat(mu.toPrecision(3)),
        0.001,
        `b) $\\text{ } \\mu =$`
      );
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const particleMass = MCRandom.randomInt(2, 9);
    const a = MCRandom.randomInt(2, 5);

    const vectorF = [MCRandom.randomInt(2, 9), MCRandom.randomInt(2, 9)];
    const magnitudeFSquared = vectorF[0] ** 2 + vectorF[1] ** 2;
    const magnitudeF = Math.sqrt(magnitudeFSquared);
    const mu = (magnitudeF - particleMass * a) / (particleMass * 9.8);

    /* END VARIABLES AND CONSTANTS */
    if (mu > 0.1 && mu < 0.9) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A particle of mass $${particleMass}kg$ experiences of force of $\\text{ } \\textbf{F} = ${vectorF[0]}\\textbf{i} + ${vectorF[1]}\\textbf{j}$`,
        `a) Find the magnitude of the force $\\textbf{F}$`,
        `The particle subsequently starts to move with acceleration of magnitude $${a}ms^{-2}$ on a rough surface where the coefficient of friction between the particle and the ground is $\\mu$`,
        `b) Find $\\mu$`,
        `Assume that $g = 9.8ms^{-2}$ and give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Using pythagoras' theorem we can see that:`,
        `$|\\textbf{F}|^2 = ${vectorF[0]}^2 + ${vectorF[1]}^2$`,
        `$|\\textbf{F}| = \\sqrt{${vectorF[0]}^2 + ${vectorF[1]}^2}$`,
        `$|\\textbf{F}| = \\sqrt{${magnitudeFSquared}}$`,
        `$\\therefore |\\textbf{F}| = ${magnitudeF.toPrecision(3)}N$.`,

        `HEADING b) Since $F = ma$ we can see that:`,
        `$\\sqrt{${magnitudeFSquared}} - \\mu R = ${particleMass}\\times${a}$`,
        `Since $R = ${particleMass}g$`,
        `$\\sqrt{${magnitudeFSquared}} - ${particleMass}g\\mu = ${particleMass}\\times${a}$`,
        `$${particleMass}g\\mu = \\sqrt{${magnitudeFSquared}} - ${particleMass}\\times${a}$`,
        `$\\displaystyle \\mu = \\frac{\\sqrt{${magnitudeFSquared}} - ${particleMass}\\times${a}}{${particleMass}g}$`,
        `$\\mu = ${mu.toPrecision(3)}$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(magnitudeF.toPrecision(3)),
        0.01,
        `a) $\\text{ } |\\textbf{F}| =$`
      );
      group2.addInput(
        parseFloat(mu.toPrecision(3)),
        0.001,
        `b) $\\text{ } \\mu =$`
      );
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const containerMass = MCRandom.randomInt(40, 60) * 10;
    const theta = MCRandom.randomInt(20, 40);
    const thetaRadians = theta * (Math.PI / 180);

    const T = (containerMass * 9.8) / (2 * Math.cos(thetaRadians));
    const T_DisplayWorkaround = parseFloat((T / 1000).toPrecision(3)) * 1000;

    const maxTension = MCRandom.randomInt(40, 50) * 100;
    const M = (2 * maxTension * Math.cos(thetaRadians)) / 9.8;

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A crane is moving containers at a cargo bay.`,
        `The crane has two inextensible chains attatached to the container.`,
        `The container has a mass of $${containerMass}kg$.`,
      ]);

      const myImage = new MCQuestion.Image(
        `Matt/massHangingFromStringsProblem1.svg`,
        "width",
        20
      );
      myImage.addOverlay(`$${theta}^{\\circ}$`, 45, 30);
      myImage.addOverlay(`$${theta}^{\\circ}$`, 56, 30);
      myImage.addOverlay(`$${containerMass}kg$`, 50, 72);
      question.addImage("question", myImage);

      question.addMultipleParagraphs("question", [
        `The container in the diagram is not moving and being suspended in the air by the crane.`,
        `a) Given that the tension in both chains are equal, find the tension, $T$, in the chains.`,
        `b) If the tension in a chain exceeds $${maxTension}N$ then the chain will break. What is the mass, $M$, of the heaviest container that the chains can suspend in the air?`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) By resolving forces vertically we can see that:`,
        `$T\\cos(${theta}^{\\circ}) + T\\cos(${theta}^{\\circ}) = ${containerMass}g$`,
        `$2T\\cos(${theta}^{\\circ}) = ${containerMass}g$`,
        `$\\displaystyle T = \\frac{${containerMass}g}{2\\cos(${theta}^{\\circ})}$`,
        `$\\therefore T = ${T_DisplayWorkaround}N$.`,

        `HEADING b) Again by resolving forces vertically but solving for $M$ instead of $T$ we can see that:`,
        `$2(${maxTension})\\cos(${theta}^{\\circ}) = Mg$`,
        `$\\displaystyle M = \\frac{${
          2 * maxTension
        }\\cos(${theta}^{\\circ})}{g}$`,
        `$\\therefore M = ${M.toPrecision(3)}kg$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(T_DisplayWorkaround, 10, `a) $\\text{ } T =$`);
      group2.addInput(parseFloat(M.toPrecision(3)), 1, `b) $\\text{ } M =$`);
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const theta = MCRandom.randomInt(30, 60);
    const thetaRadians = theta * (Math.PI / 180);
    const angledForce = MCRandom.randomInt(2, 9);
    const F = angledForce * Math.cos(thetaRadians);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        `The following diagram represents a particle with forces acting on it in equilibrium.`
      );

      const myImage = new MCQuestion.Image(
        `Matt/forcesActingOnParticle3.svg`,
        "width",
        20
      );
      myImage.addOverlay(`$F$`, -2, 63);
      myImage.addOverlay(`$G$`, 49, 95);
      myImage.addOverlay(`$${theta}^{\\circ}$`, 65, 58);
      myImage.addOverlay(`$${angledForce}N$`, 102, 17);
      question.addImage(myImage);

      question.addParagraph(`Choose the correct force, $F$.`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$F = ${angledForce}\\cos(${theta}^{\\circ})N$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$F = ${angledForce}\\sin(${theta}^{\\circ})N$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$F = ${angledForce}\\tan(${theta}^{\\circ})N$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$F = ${angledForce}N$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const theta = MCRandom.randomInt(30, 60);
    const thetaRadians = theta * (Math.PI / 180);
    const angledForce = MCRandom.randomInt(2, 9);
    const F = angledForce * Math.cos(thetaRadians);

    const F_1 = MCRandom.randomInt(2, 9);
    const F_2 = MCRandom.randomInt(2, 9);
    const F_3_Squared = F_1 ** 2 + F_2 ** 2;

    const alphaRadians = Math.atan(F_2 / F_1);
    const alphaDegrees = alphaRadians * (180 / Math.PI);
    const wrongAngle1 = MCRandom.randomInt(200, 600) / 10;
    const wrongAngle2 = MCRandom.randomInt(200, 600) / 10;
    const wrongAngle3 = MCRandom.randomInt(200, 600) / 10;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (
      wrongAngle1 !== wrongAngle2 &&
      wrongAngle2 !== wrongAngle3 &&
      alphaDegrees !== wrongAngle1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        `The following diagram represents a particle with forces acting on it in equilibrium.`
      );

      const myImage = new MCQuestion.Image(
        `Matt/forcesActingOnParticle3.svg`,
        "width",
        20
      );
      myImage.addOverlay(`$${F_1}N$`, -2, 63);
      myImage.addOverlay(`$${F_2}N$`, 49, 95);
      myImage.addOverlay(`$\\alpha$`, 65, 58);
      myImage.addOverlay(`$\\sqrt{${F_3_Squared}}N$`, 102, 17);
      question.addImage(myImage);

      question.addParagraph(`Choose the correct angle, $\\alpha$.`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$\\alpha = ${alphaDegrees.toPrecision(3)}^{\\circ}$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$\\alpha = ${wrongAngle1.toPrecision(3)}^{\\circ}$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$\\alpha = ${wrongAngle2.toPrecision(3)}^{\\circ}$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$\\alpha = ${wrongAngle3.toPrecision(3)}^{\\circ}$`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const boxMass = MCRandom.randomInt(2, 9);
    const theta = MCRandom.randomInt(20, 60);
    const thetaRadians = theta * (Math.PI / 180);
    const R = boxMass * 9.8 * Math.cos(thetaRadians);
    const wrongAnswer1 = boxMass * 9.8 * Math.sin(thetaRadians);
    const wrongAnswer2 = boxMass * Math.sin(thetaRadians);
    const wrongAnswer3 = boxMass * Math.cos(thetaRadians);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (
      R !== wrongAnswer1 &&
      wrongAnswer1 !== wrongAnswer2 &&
      wrongAnswer2 !== wrongAnswer3
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        `The following diagram represents a box of mass $${boxMass}kg$ on an plane inclined at $${theta}^{\\circ}$.`
      );
      question.addParagraph(`It is assumed that $g = 9.8ms^{-2}$.`);

      const myImage = new MCQuestion.Image(
        `Matt/boxOnInclinedPlane2.svg`,
        "width",
        25
      );
      myImage.addOverlay(`$${boxMass}kg$`, 35, 35);
      myImage.addOverlay(`$${theta}^{\\circ}$`, 81, 75);
      question.addImage(myImage);

      question.addParagraph(`Choose the correct normal reaction force, $R$:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$R = ${R.toPrecision(3)}N$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$R = ${wrongAnswer1.toPrecision(3)}N$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$R = ${wrongAnswer2.toPrecision(3)}N$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$R = ${wrongAnswer3.toPrecision(3)}N$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const boxMass = MCRandom.randomInt(2, 9);
    const mu = MCRandom.randomInt(100, 900) / 1000;
    const theta = MCRandom.randomInt(20, 60);
    const thetaRadians = theta * (Math.PI / 180);
    const R = boxMass * 9.8 * Math.cos(thetaRadians);
    const F = mu * R;
    const wrongAnswer1 = R;
    const wrongAnswer2 = mu * R * R;
    const wrongAnswer3 = mu;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (
      F !== wrongAnswer1 &&
      wrongAnswer1 !== wrongAnswer2 &&
      wrongAnswer2 !== wrongAnswer3 &&
      wrongAnswer2 < 1000
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        `The following diagram represents a box of mass $${boxMass}kg$ on a rough plane inclined at $${theta}^{\\circ}$.`
      );
      question.addParagraph(`It is assumed that $g = 9.8ms^{-2}$.`);

      const myImage = new MCQuestion.Image(
        `Matt/boxOnInclinedPlane2.svg`,
        "width",
        25
      );
      myImage.addOverlay(`$${boxMass}kg$`, 35, 35);
      myImage.addOverlay(`$${theta}^{\\circ}$`, 81, 75);
      question.addImage(myImage);

      question.addMultipleParagraphs([
        `Given that the reaction force is $${R.toPrecision(
          3
        )}N$ and that $\\mu = ${mu}$`,
        `Choose the correct magnitude of the force being generated via friction, $F$:`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$F = ${F.toPrecision(3)}N$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$F = ${wrongAnswer1.toPrecision(3)}N$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$F = ${wrongAnswer2.toPrecision(3)}N$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$F = ${wrongAnswer3.toPrecision(3)}N$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const carMass = MCRandom.randomInt(130, 150) * 10;
    const drivingForce = MCRandom.randomInt(100, 200) * 100;
    const resistance = MCRandom.randomInt(50, 99) * 100;
    const a = (drivingForce - resistance) / carMass;
    const wrongAnswer1 = drivingForce / carMass;
    const wrongAnswer2 = resistance / carMass;
    const wrongAnswer3 = drivingForce - resistance;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (a < 6 && a > 3) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addMultipleParagraphs([
        `Given that a car of mass $${carMass}kg$ is moving with a driving force of $${drivingForce}N$ and is experiencing an resistance force of $${resistance}N$`,
        `Choose the correct acceleration, $a$, that the car is moving with:`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$a = ${a.toPrecision(3)}ms^{-2}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$a = ${wrongAnswer1.toPrecision(3)}ms^{-2}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$a = ${wrongAnswer2.toPrecision(3)}ms^{-2}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$a = ${wrongAnswer3}ms^{-2}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const carMass = MCRandom.randomInt(130, 150) * 10;
    const drivingForce = MCRandom.randomInt(100, 200) * 100;
    const resistance = MCRandom.randomInt(50, 99) * 100;
    const a = (drivingForce - resistance) / carMass;
    const wrongAnswer1 = carMass * 9.8;
    const wrongAnswer2 = drivingForce - 2 * resistance;
    const wrongAnswer3 = drivingForce - resistance;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (a < 6 && a > 3) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addMultipleParagraphs([
        `Given that a car of mass $${carMass}kg$ is moving with a driving force of $F$, experiencing a resistance force of $${resistance}N$ and is moving with acceleration $${a.toPrecision(
          3
        )}ms^{-2}$`,
        `Choose the correct driving force, $F$, that the car is moving with:`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$F = ${drivingForce.toFixed(0)}N$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$F = ${wrongAnswer1.toFixed(0)}N$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$F = ${wrongAnswer2.toFixed(0)}N$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$F = ${wrongAnswer3.toFixed(0)}N$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_MCQ_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pullingForce = MCRandom.randomInt(5, 15) * 10;
    const boxMass = MCRandom.randomInt(3, 9);
    const a = MCRandom.randomInt(2, 6);

    const mu = (pullingForce - boxMass * a) / (boxMass * 9.8);
    const wrongAnswer1 = (pullingForce - boxMass * a) / boxMass;
    const wrongAnswer2 = (pullingForce - a) / (boxMass * 9.8);
    const wrongAnswer3 = pullingForce / (boxMass * 9.8);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (mu > 0.1 && mu < 0.9 && wrongAnswer2 < 1 && wrongAnswer3 < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `A box of mass $${boxMass}kg$ is being pulled on a rough plane by a pulling force of $${pullingForce}N$`,
        `The coefficient of friction between the box and the plane is $\\mu$.`,
        `The box subsequently experiences a friction force, $F$`,
      ]);
      const myImage = new MCQuestion.Image(`Matt/boxOnPlane1.svg`, "width", 25);
      myImage.addOverlay(`$${pullingForce}N$`, 99, 49);
      myImage.addOverlay(`$${boxMass}kg$`, 50, 50);
      myImage.addOverlay(`$F$`, 3, 49);
      question.addImage(myImage);

      question.addMultipleParagraphs([
        `Given that the box is moving with acceleration $${a}ms^{-2}$, choose the correct value of $\\mu$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$\\mu = ${mu.toPrecision(3)}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$\\mu = ${wrongAnswer1.toPrecision(3)}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$\\mu = ${wrongAnswer2.toPrecision(3)}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$\\mu = ${wrongAnswer3.toPrecision(3)}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Forces_ResolvingForces_MCQ_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const F_1 = MCRandom.randomInt(5, 15) * 10;
    const theta = MCRandom.randomInt(20, 40);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addMultipleParagraphs([
        `Given that a rectangular box is experiencing a force of $${F_1}N$ at an angle of $${theta}^{\\circ}$ to the horizontal`,
        `Another force, $F$, is acting on the box and stopping it from moving.`,
        `This is shown in the diagram below:`,
      ]);

      const myImage = new MCQuestion.Image(
        `Matt/rectangleOnPlane1.svg`,
        "width",
        30
      );
      myImage.addOverlay(`$F$`, 5, 60);
      myImage.addOverlay(`$${theta}^{\\circ}$`, 81, 44);
      myImage.addOverlay(`$${F_1}N$`, 98, 17);
      question.addImage(myImage);

      question.addMultipleParagraphs([
        `Choose the correct value for the force $F$:`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$F = ${F_1}\\cos(${theta}^{\\circ})N$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$F = ${F_1}g\\sin(${theta}^{\\circ})N$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$F = ${F_1}\\sin(${theta}^{\\circ})N$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$F = ${F_1}g\\cos(${theta}^{\\circ})N$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const m = MCRandom.randomInt(2, 9);
    const u = [MCRandom.randomInt(2, 9), MCRandom.randomInt(2, 9)];

    const a = [MCRandom.randomInt(2, 9), MCRandom.randomInt(2, 9)];

    const F = [m * a[0], m * a[1]];

    const t1 = MCRandom.randomInt(2, 9);

    const v = [u[0] + a[0] * t1, u[1] + a[1] * t1];
    const speedSquared = v[0] ** 2 + v[1] ** 2;
    const speed = Math.sqrt(speedSquared);

    const bearing = Math.atan(v[0] / v[1]) * (180 / Math.PI);

    /* END VARIABLES AND CONSTANTS */
    if (speed < 50 && speed > 5) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `In this question take $\\textbf{i}$ and $\\textbf{j}$ to be the unit vectors due east and north respectively.`,
        MCMaths.cleaner(
          `A particle $P$ of mass $${m}kg$ moves with an initial velocity of $(${u[0]}\\textbf{i}+${u[1]}\\textbf{j})ms^{-1}$`
        ),
        MCMaths.cleaner(
          `Given that the particle experiences a constant force of $\\textbf{F} = (${F[0]}\\textbf{i}+${F[1]}\\textbf{j})N$`
        ),
        `a) Find the acceleration of the particle $P$.`,
        `b) Find the speed of the particle at time $t=${t1}$.`,
        `c) Find the bearing on which the particle is travelling at time $t=${t1}$.`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Since $\\textbf{F} = m\\textbf{a}, \\text{ } \\implies \\textbf{a} = \\frac{\\textbf{F}}{m}$:`,
        MCMaths.cleaner(
          `So $\\textbf{a} = \\frac{1}{${m}}(${F[0]}\\textbf{i}+${F[1]}\\textbf{j})$`
        ),
        MCMaths.cleaner(`$= (${a[0]}\\textbf{i}+${a[1]}\\textbf{j})$.`),

        `HEADING b) Use $\\textbf{v} = \\textbf{u} + \\textbf{a}t$:`,
        MCMaths.cleaner(
          `$\\textbf{v} = (${u[0]}\\textbf{i}+${u[1]}\\textbf{j}) + ${t1}(${a[0]}\\textbf{i}+${a[1]}\\textbf{j})$`
        ),
        MCMaths.cleaner(`$= ${v[0]}\\textbf{i}+${v[1]}\\textbf{j}$`),
        `Since speed $= |\\textbf{v}|$`,
        `$|v| = \\sqrt{(${v[0]})^2+(${v[1]})^2}$`,
        `$= \\sqrt{${speedSquared}}$`,
        `$= ${speed.toPrecision(3)}ms^{-1}$.`,

        `HEADING c) Form a triangle using the $\\textbf{i}$ and $\\textbf{j}$ components and use trigonometry to find the bearing:`,
        `$\\tan(\\theta) = \\frac{${v[0]}}{${v[1]}}$`,
        `$\\theta = \\tan^{-1}(\\frac{${v[0]}}{${v[1]}})$`,
        `$\\therefore \\theta = ${bearing.toPrecision(3)}^{\\circ}$`,
        `Hence the bearing is $0${bearing.toFixed(0)}^{\\circ}$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(1);
      const group3 = new MCQuestion.InputGroup(1);

      group1.addInput(a[0], 0, `a) $\\text{ } \\textbf{a}_{\\textbf{i}} =$`);
      group1.addInput(a[1], 0, `a) $\\text{ } \\textbf{a}_{\\textbf{j}} =$`);

      group2.addInput(
        parseFloat(speed.toPrecision(3)),
        0.1,
        `b) $\\text{ } |v| =$`
      );

      group3.addInput(parseFloat(`0${bearing.toFixed(0)}`), 1, `c) Bearing =`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_Exam_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];

    const t1 = MCRandom.randomInt(2, 9);
    const v_t1 = [a[0] * t1, a[1] * t1];

    const t2 = MCRandom.randomInt(2, 9);

    const r_t2 = [0.5 * a[0] * t2 ** 2, 0.5 * a[1] * t2 ** 2];

    const r_t2_magnitudeSquared = r_t2[0] ** 2 + r_t2[1] ** 2;
    const r_t2_magnitude = Math.sqrt(r_t2_magnitudeSquared);

    /* END VARIABLES AND CONSTANTS */
    if (t2 > t1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `In this question take $\\textbf{i}$ and $\\textbf{j}$ to be the unit vectors due east and north respectively.`,
        `A particle $P$ moves with constant acceleration and starts from rest at a fixed origin $O$.`,
        MCMaths.cleaner(
          `Given that the particle is moving with velocity $(${v_t1[0]}\\textbf{i}+${v_t1[1]}\\textbf{j})ms^{-1}$ at time $t=${t1}$`
        ),
        `a) Find the acceleration, $\\textbf{a}$.`,
        `b) Find the distance, $|\\textbf{r}|$, from the fixed origin $O$ at time $t=${t2}$.`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Rearranging $\\textbf{v} = \\textbf{u} + \\textbf{a}t \\text{ } $ where $\\textbf{u} = \\textbf{0}$ since $P$ starts at rest, we can see that:`,
        `$\\displaystyle \\textbf{a} = \\frac{\\textbf{v}}{t}$`,
        MCMaths.cleaner(
          `So $\\textbf{a} = \\frac{1}{${t1}}(${v_t1[0]}\\textbf{i}+${v_t1[1]}\\textbf{j})$`
        ),
        MCMaths.cleaner(`$= ${a[0]}\\textbf{i}+${a[1]}\\textbf{j}$`),

        `HEADING b) Using the equation $\\textbf{r} = \\textbf{u}t + \\frac{1}{2}\\textbf{a}t^2$ we can see that:`,
        MCMaths.cleaner(
          `$\\textbf{r} = \\frac{1}{2}(${a[0]}\\textbf{i}+${a[1]}\\textbf{j})(${t2})^2$`
        ),
        MCMaths.cleaner(`$= ${r_t2[0]}\\textbf{i}+${r_t2[1]}\\textbf{j}$`),
        `Then $|\\textbf{r}| = \\sqrt{(${r_t2[0]})^2 + (${r_t2[1]})^2}$`,
        `$= ${r_t2_magnitude.toPrecision(3)}m$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(a[0], 0, `a) $\\text{ } [\\textbf{a}]_i =$`);
      group1.addInput(a[1], 0, `a) $\\text{ } [\\textbf{a}]_j =$`);

      group2.addInput(
        parseFloat(r_t2_magnitude.toPrecision(3)),
        1,
        `b) $\\text{ } |\\textbf{r}| =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_Exam_Q11() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const initialVelocity = [
      MCRandom.randomInt(15, 30),
      MCRandom.randomInt(15, 30),
    ];
    const decleration = [-MCRandom.randomInt(2, 6), -MCRandom.randomInt(2, 6)];
    const t1 = MCRandom.randomInt(2, 9);

    const finalVelocity = [
      initialVelocity[0] + decleration[0] * t1,
      initialVelocity[1] + decleration[1] * t1,
    ];

    const r_t1 = [
      initialVelocity[0] * t1 + 0.5 * decleration[0] * t1 ** 2,
      initialVelocity[1] * t1 + 0.5 * decleration[1] * t1 ** 2,
    ];

    const r_t1_magnitude = Math.sqrt(r_t1[0] ** 2 + r_t1[1] ** 2);

    /* END VARIABLES AND CONSTANTS */
    if (finalVelocity[0] > 0 && finalVelocity[1] > 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `In this question take $\\textbf{i}$ and $\\textbf{j}$ to be the unit vectors due east and north respectively.`,
        `At time $t=0$ a car is moving with velocity $(${initialVelocity[0]}\\textbf{i}+${initialVelocity[1]}\\textbf{j})ms^{-1}$ and is at a fixed origin $O$.`,
        MCMaths.cleaner(
          `The car then starts to decelerate with deceleration $(${decleration[0]}\\textbf{i}+${decleration[1]}\\textbf{j})ms^{-2}$ and reduces its speed to $\\textbf{v} \\text{ } ms^{-1}$ over $${t1}s$.`
        ),
        `a) Find $\\textbf{v}$.`,
        `b) Find how far the car travelled, $|\\textbf{r}|$, whilst it was decelerating.`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) In order to find $\\textbf{v}$ we need to use the formula $\\textbf{v} = \\textbf{u} + \\textbf{a}t$:`,
        MCMaths.cleaner(
          `$\\textbf{v} = (${initialVelocity[0]}\\textbf{i}+${initialVelocity[1]}\\textbf{j}) + (${decleration[0]}\\textbf{i}+${decleration[1]}\\textbf{j})(${t1})$`
        ),
        MCMaths.cleaner(
          `$= (${initialVelocity[0]}\\textbf{i}+${
            initialVelocity[1]
          }\\textbf{j}) + (${decleration[0] * t1}\\textbf{i}+${
            decleration[1] * t1
          }\\textbf{j})$`
        ),
        `$= (${finalVelocity[0]}\\textbf{i}+${finalVelocity[1]}\\textbf{j})ms^{-1}.$`,

        `HEADING b) To find how far the car travelled we use the fact that $\\textbf{r} = \\textbf{u}t + \\frac{1}{2}\\textbf{a}t^2$:`,
        MCMaths.cleaner(
          `Then $\\textbf{r} = (${initialVelocity[0]}\\textbf{i}+${initialVelocity[1]}\\textbf{j})(${t1}) + \\frac{1}{2}(${decleration[0]}\\textbf{i}+${decleration[1]}\\textbf{j})(${t1})^2$`
        ),
        MCMaths.cleaner(
          `$= (${t1 * initialVelocity[0]}\\textbf{i}+${
            t1 * initialVelocity[1]
          }\\textbf{j}) + (${0.5 * t1 ** 2 * decleration[0]}\\textbf{i}+${
            0.5 * t1 ** 2 * decleration[1]
          }\\textbf{j})$`
        ),
        MCMaths.cleaner(
          `$\\displaystyle = ${r_t1[0]}\\textbf{i}+${r_t1[1]}\\textbf{j}$`
        ),
        `HEADING Then you need to find the magnitude of this vector as this will give the total distance travelled:`,
        `$|\\textbf{r}| = \\sqrt{(${r_t1[0]})^2+(${r_t1[1]})^2}$`,
        `$= ${r_t1_magnitude.toPrecision(3)}m$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(finalVelocity[0], 0, `a) $\\text{ } [\\textbf{v}]_i =$`);
      group1.addInput(finalVelocity[1], 0, `a) $\\text{ } [\\textbf{v}]_j =$`);

      group2.addInput(
        parseFloat(r_t1_magnitude.toPrecision(3)),
        0.1,
        `b) $\\text{ } |\\textbf{r}| =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_Exam_Q12() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here]

    const p_r_t = [
      new MCMaths.Polynomial(
        [
          MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
          MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
        ],
        `t`
      ),
      new MCMaths.Polynomial(
        [
          MCRandom.randomInt(2, 9),
          0,
          MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
        ],
        `t`
      ),
    ];

    const p_r_t_Coefficients = [
      p_r_t[0].getCoefficients(),
      p_r_t[1].getCoefficients(),
    ];

    const quadratic_aTerm = p_r_t_Coefficients[1][0];
    const quadratic_bTerm = -p_r_t_Coefficients[0][0];
    const quadratic_cTerm =
      -p_r_t_Coefficients[0][1] + p_r_t_Coefficients[1][2];

    const quadratic_Discriminant =
      quadratic_bTerm ** 2 - 4 * quadratic_aTerm * quadratic_cTerm;

    const t1_a =
      (-quadratic_bTerm + Math.sqrt(quadratic_Discriminant)) /
      (2 * quadratic_aTerm);
    const t1_b =
      (-quadratic_bTerm - Math.sqrt(quadratic_Discriminant)) /
      (2 * quadratic_aTerm);

    const p_r_t1 = [p_r_t[0].evaluate(t1_a), p_r_t[1].evaluate(t1_a)];

    const p_r_t1_magnitude = Math.sqrt(
      parseFloat(p_r_t1[0].toPrecision(3)) ** 2 +
        parseFloat(p_r_t1[1].toPrecision(3)) ** 2
    );

    // ########################

    const q_a = [
      new MCMaths.Polynomial(
        [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0],
        `a`
      ),
      new MCMaths.Polynomial(
        [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0],
        `a`
      ),
    ];

    const q_a_Coefficients = [
      q_a[0].getCoefficients(),
      q_a[1].getCoefficients(),
    ];

    const t2 = MCRandom.randomInt(2, 9);

    const q_v_t0 = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const q_r_t0 = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];

    const q_v_t2 = [
      new MCMaths.Polynomial(
        [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0],
        `b`
      ),
      new MCMaths.Polynomial(
        [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0],
        `b`
      ),
    ];

    const q_v_t2_Coefficients = [
      q_v_t2[0].getCoefficients(),
      q_v_t2[1].getCoefficients(),
    ];

    // Begin: Code to solve system of equations
    const eq1_bCoeff = q_v_t2_Coefficients[0][0];
    const eq1_constant = q_v_t0[0];
    const eq1_aCoeff = q_a_Coefficients[0][0];

    const eq2_bCoeff = q_v_t2_Coefficients[1][0];
    const eq2_constant = q_v_t0[1];
    const eq2_aCoeff = q_a_Coefficients[1][0];

    const a =
      (eq2_bCoeff * eq1_constant - eq2_constant * eq1_bCoeff) /
      (eq2_aCoeff * eq1_bCoeff - eq2_bCoeff * eq1_aCoeff);
    const b = (eq1_constant + eq1_aCoeff * a) / eq1_bCoeff;
    // End

    /* END VARIABLES AND CONSTANTS */
    if (
      quadratic_Discriminant >= 0 &&
      t1_a > 0 &&
      t1_b < 0 &&
      p_r_t1[0] > 0 &&
      Math.abs(a) < 100 &&
      Math.abs(b) < 100 &&
      Math.abs(a) > 0 &&
      Math.abs(b) > 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `In this question take $\\textbf{i}$ and $\\textbf{j}$ to be the unit vectors due east and north respectively.`,
        `Two particles $P$ and $Q$ are moving with constant acceleration relative to a fixed origin $O$.`,
        `At time $t \\geq 0$ particle $P$ has the position vector $\\textbf{r} = (${p_r_t[0]})\\textbf{i}+(${p_r_t[1]})\\textbf{j}$`,
        `a) Find when $P$ is directly North-East of $O$, $t_1$.`,
        `b) Find the distance, $|r|$, when $P$ is directly North-East of $O$.`,
        MCMaths.cleaner(
          `$Q$ moves with acceleration $(${q_a[0]}\\textbf{i}+${q_a[1]}\\textbf{j})ms^{-2}$`
        ),
        MCMaths.cleaner(
          `At time $t=0$, $Q$ has velocity $(${q_v_t0[0]}\\textbf{i}+${q_v_t0[1]}\\textbf{j})ms^{-1}$ and has the position vector $(${q_r_t0[0]}\\textbf{i}+${q_r_t0[1]}\\textbf{j})m$.`
        ),
        MCMaths.cleaner(
          `When $t=${t2}$, $Q$ has velocity $(${q_v_t2[0]}\\textbf{i}+${q_v_t2[1]}\\textbf{j})ms^{-1}$.`
        ),
        `c) Find the values of $a$ and $b$.`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) In order to find when $P$ is North-East of $O$ we have to set the $\\textbf{i}$ and $\\textbf{j}$ components of $\\textbf{r}$ equal to each other:`,
        `$${p_r_t[0]} = ${p_r_t[1]}$`,
        MCMaths.cleaner(
          `$\\implies ${quadratic_aTerm}t^2+${quadratic_bTerm}t+${quadratic_cTerm} = 0$`
        ),
        `HEADING Solving this quadratic we see that:`,
        `$t = ${t1_a.toPrecision(3)}, \\text{ } ${t1_b.toPrecision(3)}$.`,
        `Since $t \\geq 0$ we know that $t_1 = ${t1_a.toPrecision(3)}s$.`,

        `HEADING b) Substitute answer for a) into the expression for $\\textbf{r}$:`,
        MCMaths.cleaner(
          `$\\textbf{r} = (${p_r_t_Coefficients[0][0]}(${t1_a.toPrecision(
            3
          )})+${p_r_t_Coefficients[0][1]})\\textbf{i}+(${
            p_r_t_Coefficients[1][0]
          }(${t1_a.toPrecision(3)})^2+${p_r_t_Coefficients[1][2]})\\textbf{j}$`,
          false
        ),
        MCMaths.cleaner(
          `$= ${p_r_t1[0].toPrecision(3)}\\textbf{i}+${p_r_t1[1].toPrecision(
            3
          )}\\textbf{j}$`,
          false
        ),
        `HEADING Then the distance is given by the magnitude of the vector $\\textbf{r}$:`,
        `$|\\textbf{r}| = \\sqrt{(${p_r_t1[0].toPrecision(
          3
        )})^2+(${p_r_t1[1].toPrecision(3)})^2}$`,
        `$= ${p_r_t1_magnitude.toPrecision(3)}m$.`,

        `HEADING c) In order to find $a$ and $b$ we make use of the equation $\\textbf{v} = \\textbf{u} + \\textbf{a}t$:`,
        MCMaths.cleaner(
          `$${q_v_t2[0]}\\textbf{i}+${q_v_t2[1]}\\textbf{j} = (${q_v_t0[0]}\\textbf{i}+${q_v_t0[1]}\\textbf{j}) + (${q_a[0]}\\textbf{i}+${q_a[1]}\\textbf{j})(${t2})$`
        ),
        MCMaths.cleaner(
          `$${q_v_t2[0]}\\textbf{i}+${q_v_t2[1]}\\textbf{j} = (${q_v_t0[0]}+${
            t2 * q_a_Coefficients[0][0]
          }a)\\textbf{i} + (${q_v_t0[1]}+${
            t2 * q_a_Coefficients[1][0]
          }a)\\textbf{j}$`
        ),
        `HEADING Comparing vector components we can see that:`,
        MCMaths.cleaner(`$${eq1_bCoeff}b = ${eq1_constant}+${eq1_aCoeff}a$`),
        MCMaths.cleaner(`$${eq2_bCoeff}b = ${eq2_constant}+${eq2_aCoeff}a$`),
        `HEADING Solving the system of equation we can see that:`,
        `$a = ${a.toPrecision(3)}, \\text{ } b = ${b.toPrecision(3)}$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      const group3 = new MCQuestion.InputGroup(2);

      group1.addInput(
        parseFloat(t1_a.toPrecision(3)),
        0.1,
        `a) $\\text{ } t_1 =$`
      );
      group2.addInput(
        parseFloat(p_r_t1_magnitude.toPrecision(3)),
        0.1,
        `b) $\\text{ } |\\textbf{r}| =$`
      );
      group3.addInput(parseFloat(a.toPrecision(3)), 0.1, `c) $\\text{ } a =$`);
      group3.addInput(parseFloat(b.toPrecision(3)), 0.1, `c) $\\text{ } b =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const r0 = [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0];

    const v = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const t = MCRandom.randomInt(2, 9);

    const r = [r0[0] + v[0] * t, r0[1] + v[1] * t];

    const r_MagnitudeSquared = r[0] ** 2 + r[1] ** 2;
    const r_Magnitude = Math.sqrt(r_MagnitudeSquared);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `In this question take $\\textbf{i}$ and $\\textbf{j}$ to be the unit vectors due east and north respectively.`,
        `A particle $P$ is initially at the position vector $\\textbf{r}_0 = ${r0[0]}\\textbf{i}$ and is moving with constant velocity $\\textbf{v}$ where`,
        MCMaths.cleaner(
          `$\\textbf{v} = ${v[0]}\\textbf{i}+${v[1]}\\textbf{j}$.`
        ),
        `Given that the particle passes the position vector $\\textbf{r}$ at time $t=${t}$.`,
        `a) Find $\\textbf{r}$`,
        `Given the fixed origin $\\textbf{O} = 0\\textbf{i} + 0\\textbf{j}$`,
        `b) Find the distance between the position vector $\\textbf{r}$ and the origin, $|\\textbf{r}|$.`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Use the fact that $\\textbf{r} = \\textbf{r}_0 + \\textbf{v}t$:`,
        MCMaths.cleaner(
          `$\\textbf{r} = ${r0[0]}\\textbf{i}+(${v[0]}\\textbf{i}+${v[1]}\\textbf{j})(${t})$`
        ),
        MCMaths.cleaner(
          `$= ${r0[0]}\\textbf{i}+(${t * v[0]}\\textbf{i}+${
            t * v[1]
          }\\textbf{j})$`
        ),
        MCMaths.cleaner(`$= ${r[0]}\\textbf{i}+${r[1]}\\textbf{j}$.`),

        `HEADING b) Using $|\\textbf{r}| = \\sqrt{(r_i)^2 + (r_j)^2 }$`,
        `So $|\\textbf{r}| = \\sqrt{(${r[0]})^2 + (${r[1]})^2}$`,
        `$= \\sqrt{${r_MagnitudeSquared}}$`,
        `$= ${r_Magnitude.toPrecision(3)}$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(r[0], 0, `a) $\\text{ } r_i =$`);
      group1.addInput(r[1], 0, `a) $\\text{ } r_j =$`);

      group2.addInput(
        parseFloat(r_Magnitude.toPrecision(3)),
        0.1,
        `b) $\\text{ } |\\textbf{r}| =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const r0 = [0, MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2)];

    const v = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const t = MCRandom.randomInt(2, 9);

    const r = [r0[0] + v[0] * t, r0[1] + v[1] * t];

    const r_MagnitudeSquared = r[0] ** 2 + r[1] ** 2;
    const r_Magnitude = Math.sqrt(r_MagnitudeSquared);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `In this question take $\\textbf{i}$ and $\\textbf{j}$ to be the unit vectors due east and north respectively.`,
        `A particle $P$ is initially at the position vector $\\textbf{r}_0 = ${r0[1]}\\textbf{j}$ and is moving with constant velocity $\\textbf{v}$ where`,
        MCMaths.cleaner(
          `$\\textbf{v} = ${v[0]}\\textbf{i}+${v[1]}\\textbf{j}$.`
        ),
        `Given that the particle passes the position vector $\\textbf{r}$ at time $t=${t}$.`,
        `a) Find $\\textbf{r}$`,
        `Given the fixed origin $\\textbf{O} = 0\\textbf{i} + 0\\textbf{j}$`,
        `b) Find the distance between the position vector $\\textbf{r}$ and the origin, $|\\textbf{r}|$.`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Use the fact that $\\textbf{r} = \\textbf{r}_0 + \\textbf{v}t$:`,
        MCMaths.cleaner(
          `$\\textbf{r} = ${r0[1]}\\textbf{j}+(${v[0]}\\textbf{i}+${v[1]}\\textbf{j})(${t})$`
        ),
        MCMaths.cleaner(
          `$= ${r0[1]}\\textbf{j}+(${t * v[0]}\\textbf{i}+${
            t * v[1]
          }\\textbf{j})$`
        ),
        MCMaths.cleaner(`$= ${r[0]}\\textbf{i}+${r[1]}\\textbf{j}$.`),

        `HEADING b) Using $|\\textbf{r}| = \\sqrt{(r_i)^2 + (r_j)^2 }$`,
        `So $|\\textbf{r}| = \\sqrt{(${r[0]})^2 + (${r[1]})^2}$`,
        `$= \\sqrt{${r_MagnitudeSquared}}$`,
        `$= ${r_Magnitude.toPrecision(3)}$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(r[0], 0, `a) $\\text{ } r_i =$`);
      group1.addInput(r[1], 0, `a) $\\text{ } r_j =$`);

      group2.addInput(
        parseFloat(r_Magnitude.toPrecision(3)),
        0.1,
        `b) $\\text{ } |\\textbf{r}| =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const r0 = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];

    const v = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const t = MCRandom.randomInt(2, 9);

    const r = [r0[0] + v[0] * t, r0[1] + v[1] * t];

    const r_MagnitudeSquared = r[0] ** 2 + r[1] ** 2;
    const r_Magnitude = Math.sqrt(r_MagnitudeSquared);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `In this question take $\\textbf{i}$ and $\\textbf{j}$ to be the unit vectors due east and north respectively.`,
        MCMaths.cleaner(
          `A particle $P$ is initially at the position vector $\\textbf{r}_0 = ${r0[0]}\\textbf{i}+${r0[1]}\\textbf{j}$ and is moving with constant velocity $\\textbf{v}$ where`
        ),
        MCMaths.cleaner(
          `$\\textbf{v} = ${v[0]}\\textbf{i}+${v[1]}\\textbf{j}$.`
        ),
        `Given that the particle passes the position vector $\\textbf{r}$ at time $t=${t}$.`,
        `a) Find $\\textbf{r}$`,
        `Given the fixed origin $\\textbf{O} = 0\\textbf{i} + 0\\textbf{j}$`,
        `b) Find the distance between the position vector $\\textbf{r}$ and the origin, $|\\textbf{r}|$.`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Use the fact that $\\textbf{r} = \\textbf{r}_0 + \\textbf{v}t$:`,
        MCMaths.cleaner(
          `$\\textbf{r} = (${r0[0]}\\textbf{i}+${r0[1]}\\textbf{j})+(${v[0]}\\textbf{i}+${v[1]}\\textbf{j})(${t})$`
        ),
        MCMaths.cleaner(
          `$= (${r0[0]}\\textbf{i}+${r0[1]}\\textbf{j})+(${
            t * v[0]
          }\\textbf{i}+${t * v[1]}\\textbf{j})$`
        ),
        MCMaths.cleaner(`$= ${r[0]}\\textbf{i}+${r[1]}\\textbf{j}$.`),

        `HEADING b) Using $|\\textbf{r}| = \\sqrt{(r_i)^2 + (r_j)^2 }$`,
        `So $|\\textbf{r}| = \\sqrt{(${r[0]})^2 + (${r[1]})^2}$`,
        `$= \\sqrt{${r_MagnitudeSquared}}$`,
        `$= ${r_Magnitude.toPrecision(3)}$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(r[0], 0, `a) $\\text{ } r_i =$`);
      group1.addInput(r[1], 0, `a) $\\text{ } r_j =$`);

      group2.addInput(
        parseFloat(r_Magnitude.toPrecision(3)),
        0.1,
        `b) $\\text{ } |\\textbf{r}| =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const r0 = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const v = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const t = MCRandom.randomInt(2, 9);

    const r = [r0[0] + v[0] * t, r0[1] + v[1] * t];

    const v_MagnitudeSquared = v[0] ** 2 + v[1] ** 2;
    const v_Magnitude = Math.sqrt(v_MagnitudeSquared);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `In this question take $\\textbf{i}$ and $\\textbf{j}$ to be the unit vectors due east and north respectively.`,
        MCMaths.cleaner(
          `An RC car starts from the position vector $\\textbf{r}_0 = (${r0[0]}\\textbf{i}+${r0[1]}\\textbf{j})m$ and moves with constant velocity $\\textbf{v}ms^{-1}$.`
        ),
        MCMaths.cleaner(
          `Given that the RC car passes the position vector $\\textbf{r} = (${r[0]}\\textbf{i}+${r[1]}\\textbf{j})m$ at time $t=${t}$,`
        ),
        `find the speed, $|\\textbf{v}|$, of the RC car.`,
        `Give your answer to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING Using the formula $\\textbf{r} = \\textbf{r}_0 + \\textbf{v}t$ we can see that:`,
        MCMaths.cleaner(
          `$(${r[0]}\\textbf{i}+${r[1]}\\textbf{j}) = (${r0[0]}\\textbf{i}+${r0[1]}\\textbf{j}) + ${t}\\textbf{v}$`
        ),
        MCMaths.cleaner(
          `$${r[0] - r0[0]}\\textbf{i}+${
            r[1] - r0[1]
          }\\textbf{j} = ${t}\\textbf{v}$`
        ),
        MCMaths.cleaner(
          `$\\frac{1}{${t}}(${r[0] - r0[0]}\\textbf{i}+${
            r[1] - r0[1]
          }\\textbf{j}) = \\textbf{v}$`
        ),
        MCMaths.cleaner(
          `So $\\textbf{v} = ${v[0]}\\textbf{i}+${v[1]}\\textbf{j}$.`
        ),
        `HEADING Then using the fact that $|\\textbf{v}| = \\sqrt{(v_i)^2+(v_j)^2}$`,
        `$|\\textbf{v}| = \\sqrt{(${v[0]})^2+(${v[1]})^2}$`,
        `$= \\sqrt{${v_MagnitudeSquared}}$`,
        `$= ${v_Magnitude.toPrecision(3)}ms^{-1}$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(v_Magnitude.toPrecision(3)),
        0.1,
        `$|\\textbf{v}| =$`
      );
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const r0 = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const r0_MagnitudeSquared = r0[0] ** 2 + r0[1] ** 2;
    const r0_Magnitude = Math.sqrt(r0_MagnitudeSquared);

    const r = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const r_MagnitudeSquared = r[0] ** 2 + r[1] ** 2;
    const r_Magnitude = Math.sqrt(r_MagnitudeSquared);

    const speed = MCRandom.randomInt(2, 9);

    const rMinusr0 = [r[0] - r0[0], r[1] - r0[1]];
    const distanceTravelledSquared = rMinusr0[0] ** 2 + rMinusr0[1] ** 2;
    const distanceTravelled = Math.sqrt(distanceTravelledSquared);

    const t = distanceTravelled / speed;

    /* END VARIABLES AND CONSTANTS */
    if (r0_Magnitude !== r_Magnitude && t >= 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `In this question take $\\textbf{i}$ and $\\textbf{j}$ to be the unit vectors due east and north respectively.`,
        MCMaths.cleaner(
          `A particle $P$ begins at the position vector $\\textbf{r}_0 = (${r0[0]}\\textbf{i}+${r0[1]}\\textbf{j})m$ relative to a fixed origin and moves in a straight line with a constant speed of $${speed}ms^{-1}$.`
        ),
        MCMaths.cleaner(
          `Find the time taken, $t$, for the particle to travel from $\\textbf{r}_0$ to $\\textbf{r} = (${r[0]}\\textbf{i}+${r[1]}\\textbf{j})m$.`
        ),
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING Use the fact that time = distance/speed to see that $t = \\frac{|\\textbf{r}-\\textbf{r}_0|}{|\\textbf{v}|}$ because:`,
        `$|\\textbf{r}-\\textbf{r}_0| =$ distance travelled.`,
        `$|\\textbf{v}| =$ speed.`,
        MCMaths.cleaner(
          `So $\\textbf{r}-\\textbf{r}_0 = (${r[0]}-${r0[0]})\\textbf{i}+(${r[1]}-${r0[1]})\\textbf{j}$`
        ),
        MCMaths.cleaner(
          `$= ${rMinusr0[0]}\\textbf{i}+${rMinusr0[1]}\\textbf{j}$`
        ),
        `Then $|\\textbf{r}-\\textbf{r}_0| = \\sqrt{(${rMinusr0[0]})^2+(${rMinusr0[1]})^2}$`,
        `$= \\sqrt{${distanceTravelledSquared}}$`,
        `And $|\\textbf{v}| = ${speed}$`,
        `So $t = \\frac{\\sqrt{${distanceTravelledSquared}}}{${speed}}$`,
        `$= ${t.toPrecision(3)}s.$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(parseFloat(t.toPrecision(3)), 0.1, `$t =$`);
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const p_r0 = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const q_r0 = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];

    const p_v = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];

    const t1 = MCRandom.randomInt(2, 9);

    const p_r_t1 = [p_r0[0] + p_v[0] * t1, p_r0[1] + p_v[1] * t1];

    const q_v = [(p_r_t1[0] - q_r0[0]) / t1, (p_r_t1[1] - q_r0[1]) / t1];

    const q_v_magnitudeSquared = q_v[0] ** 2 + q_v[1] ** 2;
    const q_v_magnitude = Math.sqrt(q_v_magnitudeSquared);

    /* END VARIABLES AND CONSTANTS */
    if (p_r0[0] !== q_r0[0] && p_r0[1] !== q_r0[1]) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        MCMaths.cleaner(
          `In this question take $\\textbf{i}$ and $\\textbf{j}$ to be the unit vectors due east and north respectively.`,
          `At time $t = 0$ the particle $P$ is at the initial position vector of $(${p_r0[0]}\\textbf{i}+${p_r0[1]}\\textbf{j})m$, and moving with constant velocity $(${p_v[0]}\\textbf{i}+${p_v[1]}\\textbf{j})ms^{-1}$.`
        ),
        MCMaths.cleaner(
          `A second particle $Q$ is also at the initial position vector of $(${q_r0[0]}\\textbf{i}+${q_r0[1]}\\textbf{j})m$ and moving with constant velocity $\\textbf{v}ms^{-1}$.`
        ),
        `At time $t=${t1}$, particles $P$ and $Q$ collide.`,
        `a) Find $\\textbf{v}$.`,
        `b) Hence, find the speed of $Q$, $|\\textbf{v}|$.`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Since $P$ and $Q$ collide at time $t=${t1}$ find position vectors for $P$ and $Q$ at time $t=${t1}$ and set them equal to each other:`,
        MCMaths.cleaner(
          `$(${p_r0[0]}\\textbf{i}+${p_r0[1]}\\textbf{j}) + (${p_v[0]}\\textbf{i}+${p_v[1]}\\textbf{j})(${t1}) = (${q_r0[0]}\\textbf{i}+${q_r0[1]}\\textbf{j}) + (v_i\\textbf{i}+v_j\\textbf{j})(${t1})$`
        ),
        MCMaths.cleaner(
          `$\\implies ${p_r_t1[0]}\\textbf{i}+${p_r_t1[1]}\\textbf{j} = (${q_r0[0]}\\textbf{i}+${q_r0[1]}\\textbf{j}) + (v_i\\textbf{i}+v_j\\textbf{j})(${t1})$`
        ),
        MCMaths.cleaner(
          `$\\implies (${p_r_t1[0]}-${q_r0[0]})\\textbf{i}+(${p_r_t1[1]}-${q_r0[1]})\\textbf{j} = (v_i\\textbf{i}+v_j\\textbf{j})(${t1})$`
        ),
        MCMaths.cleaner(
          `$\\displaystyle \\implies \\frac{(${p_r_t1[0]}-${q_r0[0]})}{${t1}}\\textbf{i}+\\frac{(${p_r_t1[1]}-${q_r0[1]})}{${t1}}\\textbf{j} = v_i\\textbf{i}+v_j\\textbf{j}$`
        ),
        MCMaths.cleaner(
          `$\\implies ${q_v[0]}\\textbf{i}+${q_v[1]}\\textbf{j} = v_i\\textbf{i}+v_j\\textbf{j}$`
        ),
        `HEADING Comparing components we can see that:`,
        `$v_i = ${q_v[0].toPrecision(3)}, \\text{ } v_j = ${q_v[1].toPrecision(
          3
        )}$.`,

        `HEADING b) To find the speed of $Q$ calculate the magnitude of the vector $\\textbf{v}$, found in part a):`,
        MCMaths.cleaner(
          `$\\displaystyle |\\textbf{v}| = \\sqrt{\\left(${q_v[0]}\\right)^2+\\left(${q_v[1]}\\right)^2}$`
        ),
        `$= ${q_v_magnitude.toPrecision(3)}ms^{-1}$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(q_v[0].toPrecision(3)),
        0.1,
        `a) $\\text{ } v_i =$`
      );
      group1.addInput(
        parseFloat(q_v[1].toPrecision(3)),
        0.1,
        `a) $\\text{ } v_j =$`
      );

      group2.addInput(
        parseFloat(q_v_magnitude.toPrecision(3)),
        0.1,
        `b) $\\text{ } |\\textbf{v}| =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const p_r0 = [0, -MCRandom.randomInt(2, 9)];
    const q_r0 = [-MCRandom.randomInt(2, 9), 0];

    const p_v = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const q_v = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];

    const t1 = MCRandom.randomInt(2, 9);

    const q_r_t1 = [p_r0[0] + p_v[0] * t1, p_r0[1] + p_v[1] * t1];
    const p_r_t1 = [q_r0[0] + q_v[0] * t1, q_r0[1] + q_v[1] * t1];

    /* END VARIABLES AND CONSTANTS */
    if (p_r_t1[0] === q_r_t1[0] && p_r_t1[1] === q_r_t1[1]) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `In this question take $\\textbf{i}$ and $\\textbf{j}$ to be the unit vectors due east and north respectively.`,
        `Two particles $P$ and $Q$ are moving with a constant velocity and collide with each other at time $t=t_1$.`,
        `At time $t=0$, particle $P$ is located $${Math.abs(
          p_r0[1]
        )}m$ due South and particle $Q$ is located $${Math.abs(
          q_r0[0]
        )}m$ due West.`,
        MCMaths.cleaner(
          `Given that $P$ is moving with velocity $(${p_v[0]}\\textbf{i}+${p_v[1]}\\textbf{j})ms^{-1}$ and $Q$ is moving with velocity $(${q_v[0]}\\textbf{i}+${q_v[1]}\\textbf{j})ms^{-1}$,`
        ),
        `a) Find $t_1$.`,
        `b) Hence find the position vector, $\\textbf{r}_1$, at which both $P$ and $Q$ collide.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Since $P$ and $Q$ collide at $t=t_1$, their position vectors are the same at time $t=t_1$ so we can see that:`,
        MCMaths.cleaner(
          `$${p_r0[1]}\\textbf{j} + (${p_v[0]}\\textbf{i}+${p_v[1]}\\textbf{j})(t_1) = ${q_r0[0]}\\textbf{i} + (${q_v[0]}\\textbf{i}+${q_v[1]}\\textbf{j})(t_1)$`
        ),
        MCMaths.cleaner(
          `$\\implies ${p_v[0]}t_1 \\textbf{i}+(${p_r0[1]}+${p_v[1]}t_1)\\textbf{j} = (${q_r0[0]}+${q_v[0]}t_1)\\textbf{i}+${q_v[1]}t_1 \\textbf{j}$`
        ),
        `HEADING Comparing vector components we can see that we obtain the system of equations:`,
        MCMaths.cleaner(`$${p_v[0]}t_1 = ${q_r0[0]}+${q_v[0]}t_1$`),
        MCMaths.cleaner(`$${p_r0[1]}+${p_v[1]}t_1 = ${q_v[1]}t_1$`),
        `HEADING Solving them we can see that:`,
        `$t_1 = ${t1}$.`,

        `HEADING b) Since we have already found $t_1$ and $t_1$ is when $P$ and $Q$ collide, substitute $t_1$ into either position vector for $P$ or $Q$:`,
        MCMaths.cleaner(
          `$\\textbf{r}_1 = ${p_r0[1]}\\textbf{j} + (${p_v[0]}\\textbf{i}+${p_v[1]}\\textbf{j})(${t1})$`
        ),
        MCMaths.cleaner(`$= ${p_r_t1[0]}\\textbf{i}+${p_r_t1[1]}\\textbf{j}$.`),
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(2);

      group1.addInput(t1, 0, `a) $\\text{ } t_1 =$`);
      group2.addInput(p_r_t1[0], 0, `b) $\\text{ } [\\textbf{r}_1]_i$`);
      group2.addInput(p_r_t1[1], 0, `b) $\\text{ } [\\textbf{r}_1]_j$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const u = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const a = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];

    const t1 = MCRandom.randomInt(2, 9);

    const v_t1 = [u[0] + a[0] * t1, u[1] + a[1] * t1];

    const a_magnitudeSquared = a[0] ** 2 + a[1] ** 2;
    const a_magnitude = Math.sqrt(a_magnitudeSquared);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `In this question take $\\textbf{i}$ and $\\textbf{j}$ to be the unit vectors due east and north respectively.`,
        `A particle $P$ moves with constant acceleration, $\\textbf{a}$.`,
        MCMaths.cleaner(
          `After $${t1}$ seconds the particle is moving with velocity $(${v_t1[0]}\\textbf{i}+${v_t1[1]}\\textbf{j})ms^{-1}$.`
        ),
        MCMaths.cleaner(
          `Given that $P$ had an initial velocity of $(${u[0]}\\textbf{i}+${u[1]}\\textbf{j})ms^{-1}$,`
        ),
        `Find the magnitude of the acceleration, $|\\textbf{a}|$.`,
        `Give your answer to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Rearranging $\\textbf{v} = \\textbf{u} + \\textbf{a}t$ for $\\textbf{a}$ we can see that:`,
        `$\\displaystyle \\textbf{a} = \\frac{\\textbf{v}-\\textbf{u}}{t}$`,
        `HEADING Substituting values in we can see that:`,
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{a} = \\frac{(${v_t1[0]}\\textbf{i}+${v_t1[1]}\\textbf{j})-(${u[0]}\\textbf{i}+${u[1]}\\textbf{j})}{${t1}}$`
        ),
        MCMaths.cleaner(
          `$= \\frac{1}{${t1}}(${v_t1[0]}-${u[0]})\\textbf{i}+\\frac{1}{${t1}}(${v_t1[1]}-${u[1]})\\textbf{j}$`
        ),
        `$\\textbf{a} = ${a[0]}\\textbf{i}+${a[1]}\\textbf{j}$`,
        `Then $|\\textbf{a}| = \\sqrt{(${a[0]})^2 + (${a[1]})^2}$`,
        `$= \\sqrt{${a_magnitudeSquared}}$`,
        `$= ${a_magnitude.toPrecision(3)}ms^{-2}$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(a_magnitude.toPrecision(3)),
        0.1,
        `$|\\textbf{a}| =$`
      );
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const v = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const t = MCRandom.randomInt(2, 9);

    const r_correct = [v[0] * t, v[1] * t];
    const r_wrong1 = [v[0] * t ** 2, v[1] * t ** 2];
    const r_wrong2 = [(v[0] * t) / 2, (v[1] * t) / 2];
    const r_wrong3 = [v[0] * -1 * t, v[1] * -1 * t];

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `A particle, $P$, starts at the origin, from rest and moves with constant velocity, $\\textbf{v}$.`,
        MCMaths.cleaner(
          `Given that $\\textbf{v} = (${v[0]}\\textbf{i}+${v[1]}\\textbf{j})$,`
        ),
        `Choose the correct position, $\\textbf{r}$, of $P$ at time $t=${t}$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      const answer4 = new MCQuestion.MultipleChoiceAnswer();

      answer1.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{r} = ${r_correct[0]}\\textbf{i}+${r_correct[1]}\\textbf{j}$`
        )
      );
      answer2.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{r} = ${r_wrong1[0]}\\textbf{i}+${r_wrong1[1]}\\textbf{j}$`
        )
      );
      answer3.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{r} = ${r_wrong2[0]}\\textbf{i}+${r_wrong2[1]}\\textbf{j}$`
        )
      );
      answer4.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{r} = ${r_wrong3[0]}\\textbf{i}+${r_wrong3[1]}\\textbf{j}$`
        )
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_MCQ_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const r = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];

    const correctAnswer = Math.sqrt(r[0] ** 2 + r[1] ** 2);
    const incorrectAnswer1 = r[0] ** 2 + r[1] ** 2;
    const incorrectAnswer2 = Math.sqrt(Math.abs(r[0] + r[1]));
    const incorrectAnswer3 = r[0] * 2 + r[1] * 2;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `At time $t=0$ a paper plane is at the position $(0\\textbf{i}+0\\textbf{j})m$.`,
        MCMaths.cleaner(
          `At time $t=t_0$ the paper plane is at the position $(${r[0]}\\textbf{i}+${r[1]}\\textbf{j})m$.`
        ),
        `How far has the paper plane travelled between times $t=0$ and $t=t_0$?`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${correctAnswer.toPrecision(3)}m$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${incorrectAnswer1.toPrecision(3)}m$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${incorrectAnswer2.toPrecision(3)}m$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${incorrectAnswer3.toPrecision(3)}m$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const v = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const t = MCRandom.randomInt(2, 9);

    const r_correct = [v[0] * t, v[1] * t];
    const r_wrong1 = [v[0] * t ** 2, v[1] * t ** 2];
    const r_wrong2 = [(v[0] * t) / 2, (v[1] * t) / 2];
    const r_wrong3 = [v[0] * -1 * t, v[1] * -1 * t];

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `A particle, $P$, starts at the origin, from rest and moves with constant acceleration, $\\textbf{a}$.`,
        MCMaths.cleaner(
          `Given that $\\textbf{a} = (${v[0]}\\textbf{i}+${v[1]}\\textbf{j})$,`
        ),
        `Choose the correct velocity, $\\textbf{v}$, of $P$ at time $t=${t}$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      const answer4 = new MCQuestion.MultipleChoiceAnswer();

      answer1.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{v} = ${r_correct[0]}\\textbf{i}+${r_correct[1]}\\textbf{j}$`
        )
      );
      answer2.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{v} = ${r_wrong1[0]}\\textbf{i}+${r_wrong1[1]}\\textbf{j}$`
        )
      );
      answer3.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{v} = ${r_wrong2[0]}\\textbf{i}+${r_wrong2[1]}\\textbf{j}$`
        )
      );
      answer4.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{v} = ${r_wrong3[0]}\\textbf{i}+${r_wrong3[1]}\\textbf{j}$`
        )
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const v_0 = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const t = MCRandom.randomInt(2, 9);

    const a_correct = [v_0[0] / t, v_0[1] / t];
    const a_wrong1 = [v_0[0] / t ** 2, v_0[1] / t ** 2];
    const a_wrong2 = [(v_0[0] * t) / 2, (v_0[1] * t) / 2];
    const a_wrong3 = [v_0[0] * -1 * t, v_0[1] * -1 * t];

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `A particle, $P$, starts at the origin, from rest and moves with constant acceleration, $\\textbf{a}$.`,
        MCMaths.cleaner(
          `Given that at time $t=${t}$, $P$ has velocity $\\textbf{v} = (${v_0[0]}\\textbf{i}+${v_0[1]}\\textbf{j})$,`
        ),
        `Choose the correct acceleration, $\\textbf{a}$, of $P$ at time $t=${t}$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      const answer4 = new MCQuestion.MultipleChoiceAnswer();

      answer1.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{a} = ${a_correct[0]}\\textbf{i}+${a_correct[1]}\\textbf{j}$`
        )
      );
      answer2.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{a} = ${a_wrong1[0]}\\textbf{i}+${a_wrong1[1]}\\textbf{j}$`
        )
      );
      answer3.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{a} = ${a_wrong2[0]}\\textbf{i}+${a_wrong2[1]}\\textbf{j}$`
        )
      );
      answer4.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{a} = ${a_wrong3[0]}\\textbf{i}+${a_wrong3[1]}\\textbf{j}$`
        )
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const r_0 = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const t = MCRandom.randomInt(2, 9);

    const v_correct = [r_0[0] / t, r_0[1] / t];
    const v_wrong1 = [r_0[0] / t ** 2, r_0[1] / t ** 2];
    const v_wrong2 = [(r_0[0] * t) / 2, (r_0[1] * t) / 2];
    const v_wrong3 = [r_0[0] * -1 * t, r_0[1] * -1 * t];

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `A particle, $P$, starts at the origin, from rest and moves with constant velocity, $\\textbf{v}$.`,
        MCMaths.cleaner(
          `Given that at time $t=${t}$, $P$ has position vector $\\textbf{r}_0 = (${r_0[0]}\\textbf{i}+${r_0[1]}\\textbf{j})$,`
        ),
        `Choose the correct velocity, $\\textbf{v}$, of $P$ at time $t=${t}$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      const answer4 = new MCQuestion.MultipleChoiceAnswer();

      answer1.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{v} = ${v_correct[0]}\\textbf{i}+${v_correct[1]}\\textbf{j}$`
        )
      );
      answer2.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{v} = ${v_wrong1[0]}\\textbf{i}+${v_wrong1[1]}\\textbf{j}$`
        )
      );
      answer3.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{v} = ${v_wrong2[0]}\\textbf{i}+${v_wrong2[1]}\\textbf{j}$`
        )
      );
      answer4.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{v} = ${v_wrong3[0]}\\textbf{i}+${v_wrong3[1]}\\textbf{j}$`
        )
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const v_p = new MCMaths.Vector(
      MCRandom.randomInt(2, 9),
      MCRandom.randomInt(2, 9)
    );
    const v_q = new MCMaths.Vector(
      MCRandom.randomInt(2, 9),
      MCRandom.randomInt(2, 9)
    );

    const v_p_magnitude = v_p.magnitude();
    const v_q_magnitude = v_q.magnitude();

    const theta_correct = v_p.angle(v_q);
    const theta_incorrect1 = 360 - theta_correct;
    const theta_incorrect2 = 180 - theta_correct;
    const theta_incorrect3 =
      (theta_correct * v_p_magnitude * v_q_magnitude) / 10;
    const answerArray = [true, false, false, false];

    /* END VARIABLES AND CONSTANTS */
    if (theta_correct > 5 && theta_correct < 85) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `Particles $P$ and $Q$ are travelling with constant velocities $\\textbf{v}_P$ and $\\textbf{v}_Q$ respectively, where`,
        `$\\textbf{v}_P = ${v_p.toString()}ms^{-1}$`,
        `$\\textbf{v}_Q = ${v_q.toString()}ms^{-1}$`,
        `The angle between the two particles is $\\theta, \\text{ } 0^{\\circ} \\lt \\theta \\lt 90^{\\circ}$.`,
        `Choose the correct angle, $\\theta$, between the two particles $P$ and $Q$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$\\theta = ${theta_correct.toPrecision(3)}^{\\circ}$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$\\theta = ${theta_incorrect1.toPrecision(3)}^{\\circ}$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$\\theta = ${theta_incorrect2.toPrecision(3)}^{\\circ}$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$\\theta = ${theta_incorrect3.toPrecision(3)}^{\\circ}$`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const v_p = new MCMaths.Vector(
      MCRandom.randomInt(2, 9),
      MCRandom.randomInt(2, 9)
    );
    const v_q = new MCMaths.Vector(
      MCRandom.randomInt(2, 9),
      MCRandom.randomInt(2, 9)
    );
    const v_p_magnitude = v_p.magnitude();
    const v_q_magnitude = v_q.magnitude();

    const v_p_dot_v_q = v_p.dot(v_q);

    const theta = v_p.angle(v_q);
    const theta_Radians = theta * (Math.PI / 180);
    const cosTheta = Math.cos(theta_Radians);
    const sinTheta = Math.sin(theta_Radians);

    const correctAnswer = v_q_magnitude;
    const incorrectAnswer1 = v_q_magnitude * (1 / v_p_magnitude);
    const incorrectAnswer2 = v_q_magnitude * v_p_dot_v_q;
    const incorrectAnswer3 = v_q_magnitude * (cosTheta / sinTheta);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `Particles $P$ and $Q$ are travelling with constant velocities $\\textbf{v}_P$ and $\\textbf{v}_Q$ respectively, where`,
        `$\\textbf{v}_P = ${v_p.toString()}ms^{-1}$`,
        `The angle between the two particles is $\\theta$, where`,
        `$\\cos(\\theta) = ${cosTheta.toPrecision(3)}$`,
        `Given that ($\\textbf{v}_P \\cdot \\textbf{v}_Q) = ${v_p_dot_v_q.toPrecision(
          3
        )}$`,
        `Choose the correct speed of $Q$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$|\\textbf{v}_Q| = ${correctAnswer.toPrecision(3)}ms^{-1}$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$|\\textbf{v}_Q| = ${incorrectAnswer1.toPrecision(3)}ms^{-1}$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$|\\textbf{v}_Q| = ${incorrectAnswer2.toPrecision(3)}ms^{-1}$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$|\\textbf{v}_Q| = ${incorrectAnswer3.toPrecision(3)}ms^{-1}$`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_MCQ_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const u = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const a = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const t = MCRandom.randomInt(2, 9);

    const v_correct = [u[0] + a[0] * t, u[1] + a[1] * t];
    const v_incorrect1 = [u[0] + a[0] * t ** 2, u[1] + a[1] * t ** 2];
    const v_incorrect2 = [a[0] * t, a[1] * t];
    const v_incorrect3 = [u[0] + 0.5 * a[0] * t, u[1] + 0.5 * a[1] * t];

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        MCMaths.cleaner(
          `A particle $P$ is initially moving with velocity $(${u[0]}\\textbf{i}+${u[1]}\\textbf{j})ms^{-1}$`
        ),
        MCMaths.cleaner(
          `Given that $P$ is moving with acceleration $(${a[0]}\\textbf{i}+${a[1]}\\textbf{j})ms^{-2}$`
        ),
        `Choose the correct velocity of $P$ at the time $t=${t}$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        MCMaths.cleaner(
          `$${v_correct[0]}\\textbf{i}+${v_correct[1]}\\textbf{j}$`
        )
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        MCMaths.cleaner(
          `$${v_incorrect1[0]}\\textbf{i}+${v_incorrect1[1]}\\textbf{j}$`
        )
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        MCMaths.cleaner(
          `$${v_incorrect2[0]}\\textbf{i}+${v_incorrect2[1]}\\textbf{j}$`
        )
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        MCMaths.cleaner(
          `$${v_incorrect3[0]}\\textbf{i}+${v_incorrect3[1]}\\textbf{j}$`
        )
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_MCQ_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const u = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const a = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const t = MCRandom.randomInt(2, 9);

    const correctAnswer = [
      u[0] * t + 0.5 * a[0] * t ** 2,
      u[1] * t + 0.5 * a[1] * t ** 2,
    ];
    const incorrectAnswer1 = [
      u[0] * t + 0.5 * a[0] * t,
      u[1] * t + 0.5 * a[1] * t,
    ];
    const incorrectAnswer2 = [
      u[0] * t + a[0] * t ** 2,
      u[1] * t + a[1] * t ** 2,
    ];
    const incorrectAnswer3 = [0.5 * a[0] * t ** 2, 0.5 * a[1] * t ** 2];

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `At time $t=${t}$, a rubber ball's velocity is $\\textbf{u}$ and it's acceleration is $\\textbf{a}$ where`,
        MCMaths.cleaner(
          `$\\textbf{u} = (${u[0]}\\textbf{i}+${u[1]}\\textbf{j})ms^{-1}$`
        ),
        MCMaths.cleaner(
          `$\\textbf{a} = (${a[0]}\\textbf{i}+${a[1]}\\textbf{j})ms^{-2}$`
        ),
        `What is the position vector, $\\textbf{r}$, of the ball at time $t=${t}$?`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        MCMaths.cleaner(
          `$\\textbf{r} = (${correctAnswer[0]}\\textbf{i}+${correctAnswer[1]}\\textbf{j})$`
        )
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        MCMaths.cleaner(
          `$\\textbf{r} = (${incorrectAnswer1[0]}\\textbf{i}+${incorrectAnswer1[1]}\\textbf{j})$`
        )
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        MCMaths.cleaner(
          `$\\textbf{r} = (${incorrectAnswer2[0]}\\textbf{i}+${incorrectAnswer2[1]}\\textbf{j})$`
        )
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        MCMaths.cleaner(
          `$\\textbf{r} = (${incorrectAnswer3[0]}\\textbf{i}+${incorrectAnswer3[1]}\\textbf{j})$`
        )
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_FurtherVectors_MCQ_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const v = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];

    const correctAnswer = Math.sqrt(v[0] ** 2 + v[1] ** 2);
    const incorrectAnswer1 = v[0] ** 2 + v[1] ** 2;
    const incorrectAnswer2 = Math.sqrt(Math.abs(v[0] + v[1]));
    const incorrectAnswer3 = v[0] * 2 + v[1] * 2;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        MCMaths.cleaner(
          `An RC car is moving with velocity $(${v[0]}\\textbf{i}+${v[1]}\\textbf{j})ms^{-1}$.`
        ),
        `What is the RC car's speed?`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${correctAnswer.toPrecision(3)}ms^{-1}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${incorrectAnswer1.toPrecision(3)}ms^{-1}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${incorrectAnswer2.toPrecision(3)}ms^{-1}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${incorrectAnswer3.toPrecision(3)}ms^{-1}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const k = MCRandom.randomInt(2, 9);
    const lamda = -k;

    const v_0 = MCRandom.randomInt(2, 9);

    const c = v_0 - lamda;

    const t_1 = MCRandom.randomInt(12, 19);

    const v_t_1 = lamda * Math.cos(k * t_1) + c;

    const max_speed = -lamda + c;

    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(v_t_1.toPrecision(2)) !== parseFloat(max_speed.toPrecision(2))
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A particle $P$ is moving in a straight line with acceleration $a$ where`,
        `$a(t) = ${k ** 2}\\sin(${k}t), \\text{ } t \\geq 0$`,
        `Given that the velocity of $P$ at time $t=0$ is $${v_0}ms^{-1}$`,
        `a) Find the velocity, $v$, of $P$ at time $t=${t_1}$.`,
        `b) Find the maximum speed, $v_{max}$, of $P$.`,
        `Give your answers to $2$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Integrate $a$ to obtain general solution for $v$:`,
        `$v(t) = \\int{${k ** 2}\\sin(${k}t)}dt$`,
        `$= -${k}\\cos(${k}t) + c$`,
        `Using $v(0) = ${v_0}$:`,
        MCMaths.cleaner(`$${v_0} = ${lamda} + c$`),
        MCMaths.cleaner(`So $c = ${c}$`),
        MCMaths.cleaner(`$\\therefore v(t) = ${lamda}\\cos(${k}t)+${c}$`),
        `And $v(${t_1}) = ${v_t_1.toPrecision(2)}ms^{-1}$`,

        `HEADING b) Since $-1 \\leq \\cos(${k}t) \\leq 1$ we can see that:`,
        MCMaths.cleaner(`$${lamda}\\cos(${k}t)+${c} \\leq ${-lamda} + ${c}$`),
        MCMaths.cleaner(`So $v(t) \\leq ${max_speed}$`),
        `$\\therefore v_{max} = ${max_speed.toPrecision(2)}ms^{-1}$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(v_t_1.toPrecision(2)),
        0.1,
        `a) $\\text{ } v(${t_1}) =$`
      );
      group2.addInput(
        parseFloat(max_speed.toPrecision(2)),
        0.1,
        `b) $\\text{ } v_{max} =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(1, 3);
    const beta = MCRandom.randomInt(2, 9);

    const k1 = MCRandom.randomInt(2, 9);
    const e1 = new MCMaths.Exponential(
      MCRandom.randomInt(2, 9),
      "e",
      -alpha,
      "t",
      beta
    );

    const e1_integrated = e1.integral();

    const e1_integrated_coefficient = e1_integrated.coefficient;

    const t_0 = beta / alpha;
    const s_0 = MCRandom.randomInt(2, 9);

    const c = s_0 - e1_integrated_coefficient;

    const t_1 = MCRandom.randomInt(2, 9);
    const m = MCRandom.randomInt(2, 9) / 10;

    const v_text = `${k1}+${e1.toString()}`;

    // Part a)
    const s_t = (t) => k1 * t + e1_integrated.evaluate(t) + c;
    const s_text_c_undertermined = `${k1}t+${e1_integrated.toString()}+c`;
    const s_text_c_determined = `${k1}t+${e1_integrated.toString()}+${c}`;

    const part_a_solution = s_t(t_1);

    // Part b)
    const e1_differentiated = e1.derivative();

    const a_t = (t) => e1_differentiated.evaluate(t);
    const force_t = (t) => m * a_t(t);

    const a_text = `${e1_differentiated.toString()}`;
    const f_text = `${m} \\times ${a_text}`;

    const part_b_solution = Math.abs(force_t(t_1));

    /* END VARIABLES AND CONSTANTS */
    if (
      part_a_solution > 2 &&
      part_a_solution < 100 &&
      part_b_solution > 2 &&
      part_b_solution < 100
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A particle $P$ is moving in a straight line with velocity $\\displaystyle v = ${v_text}$`,
        MCMaths.cleaner(
          `Given that $P$ is $${s_0}m$ away from the origin at time $t=${t_0}$`
        ),
        `a) Find the displacement, $s$, from the origin at time $t=${t_1}$.`,
        `The particle is measured to have a mass of $${m}kg$`,
        `b) Find the magnitude of the force, $|F|$, acting on the particle at time $t=${t_1}.$`,
        `Give your answers to $2$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) First integrate $v(t)$ to obtain a general solution for $s(t)$:`,
        `$s(t) = \\int{${v_text}}dt$`,
        MCMaths.cleaner(`$= ${s_text_c_undertermined}$`),
        MCMaths.cleaner(
          `Using the fact that $s\\left(${t_0}\\right) = ${s_0}$`
        ),
        MCMaths.cleaner(`$\\implies c = ${c}$`),
        MCMaths.cleaner(`So $s(t) = ${s_text_c_determined}$`),
        `$\\therefore s(${t_1}) = ${part_a_solution.toPrecision(2)}m$`,

        `HEADING b) To find $F$ first find the derivative of $v$ to obtain $a$`,
        `$a(t) = ${a_text}$`,
        `Since $F = ma$:`,
        `$F(t) = ${f_text}$`,
        `$\\therefore |F(${t_1})| = ${part_b_solution.toPrecision(2)}N$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(part_a_solution.toPrecision(2)),
        0.1,
        `a) $\\text{ } s =$`
      );
      group2.addInput(
        parseFloat(part_b_solution.toPrecision(2)),
        0.1,
        `b) $\\text{ } |F| =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const k_1 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const k_2 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const omega = MCRandom.randomInt(2, 4);
    const omegaSquared = omega ** 2;
    const v_0 = MCRandom.randomInt(2, 9);
    const s_0 = MCRandom.randomInt(2, 9);
    const t_1 = MCRandom.randomInt(2, 9);

    const trig_argument = `\\frac{\\pi}{${omega}} t`;
    const a_text = `${k_1}+${k_2}\\sin\\left(${trig_argument}\\right)`;

    const v_text = `${k_1}t-\\frac{${omega}}{\\pi}\\cos\\left(${trig_argument}\\right)`;
    const v_constant = v_0 - omega / Math.PI;
    const v_constant_text = `${v_0}-\\frac{${omega}}{\\pi}`;
    const v_of_t = (t) =>
      k_1 * t - (omega / Math.PI) * Math.cos((Math.PI / omega) * t);
    const v_of_t1 = v_of_t(t_1);

    const s_text = `${
      k_1 / 2
    }t^2-\\frac{${omegaSquared}}{\\pi^2}\\sin\\left(${trig_argument}\\right)+\\left(${v_constant_text}\\right)t`;
    const s_constant = s_0;
    const s_constant_text = `${s_0}`;
    const s_of_t = (t) =>
      0.5 * k_1 * t ** 2 -
      (omegaSquared / Math.PI ** 2) * Math.cos((Math.PI / omega) * t) +
      v_constant * t +
      s_constant;
    const s_of_t1 = s_of_t(t_1);

    const part_a_answer = Math.abs(v_of_t1);
    const part_b_answer = Math.abs(s_of_t1);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A particle $P$ moves in a straight line with acceleration $a$ where $a$ is given by the expression:`,
        MCMaths.cleaner(`$a(t) = ${a_text}$`),
        `Initially $P$ is moving with velocity $${v_0}ms^{-1}$ and has displacement $${s_0}m$.`,
        `a) Find the speed, of the particle at time $t=${t_1}$`,
        `b) Find the distance travelled by the particle at time $t=${t_1}$`,
        `Give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Integrate $a$ to find $v$:`,
        MCMaths.cleaner(`$v(t) = \\int{${a_text}}dt$`),
        MCMaths.cleaner(`$= ${v_text} + c$`),
        `Using $v(0) = ${v_0}$ we have that $c = ${v_constant_text}$`,
        MCMaths.cleaner(`So $v(t) = ${v_text}+${v_constant_text}$`),
        `$v(${t_1}) = ${v_of_t1.toPrecision(3)}ms^{-1}$`,
        `So $\\text{speed} = ${part_a_answer.toPrecision(3)}ms^{-1}$.`,

        `HEADING b) Integrate $v$ to find $s$:`,
        MCMaths.cleaner(`$s(t) = \\int{${v_text}+${v_constant_text}}dt$`),
        MCMaths.cleaner(`$= ${s_text} + c$`),
        `Using $s(0) = ${s_0}$ we have that $c = ${s_constant_text}$`,
        MCMaths.cleaner(`So $s(t) = ${s_text}+${s_constant_text}$`),
        `$s(${t_1}) = ${s_of_t1.toPrecision(3)}m$`,
        `So $\\text{distance travelled} = ${part_b_answer.toPrecision(3)}m$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(part_a_answer.toPrecision(3)),
        0.1,
        `a) $\\text{ } =$`
      );
      group2.addInput(
        parseFloat(part_b_answer.toPrecision(3)),
        0.1,
        `b) $\\text{ } =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const k_1 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const k_2 = -k_1 * 2;
    const v_0 = MCRandom.randomInt(2, 9);
    const s_0 = MCRandom.randomInt(2, 9);
    const t_1 = MCRandom.randomInt(2, 9);

    const a_text = `\\frac{${k_1}}{t^2}`;

    const v_text = `\\frac{${k_2}}{t}`;
    const v_constant = v_0 - k_2;

    const v_of_t = (t) => k_2 / t + v_constant;
    const v_of_t1 = v_of_t(t_1);

    const s_text = `${k_2}\\ln(t)+${v_constant}t`;
    const s_constant = s_0 - v_constant;
    const s_constant_text = `${s_0 - v_constant}`;

    const s_of_t = (t) => Math.log(t) + v_constant * t + s_constant;
    const s_of_t1 = s_of_t(t_1);

    const part_a_answer = Math.abs(v_of_t1);
    const part_b_answer = Math.abs(s_of_t1);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A particle $P$ moves in a straight line with acceleration $a$ where $a$ is given by the expression:`,
        MCMaths.cleaner(`$a(t) = ${a_text}$`),
        `At time $t=1 \\text{ }$, $P$ is moving with velocity $${v_0}ms^{-1}$ and has displacement $${s_0}m$.`,
        `a) Find the speed, of the particle at time $t=${t_1}$`,
        `b) Find the distance travelled by the particle at time $t=${t_1}$`,
        `Give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Integrate $a$ to find $v$:`,
        MCMaths.cleaner(`$v(t) = \\int{${a_text}}dt$`),
        MCMaths.cleaner(`$= ${v_text} + c$`),
        `Using $v(1) = ${v_0}$ we have that $c = ${v_constant}$`,
        MCMaths.cleaner(`So $v(t) = ${v_text}+${v_constant}$`),
        `$v(${t_1}) = ${v_of_t1.toPrecision(3)}ms^{-1}$`,
        `So $\\text{speed} = ${part_a_answer.toPrecision(3)}ms^{-1}$.`,

        `HEADING b) Integrate $v$ to find $s$:`,
        MCMaths.cleaner(`$s(t) = \\int{${v_text}+${v_constant}}dt$`),
        MCMaths.cleaner(`$= ${s_text} + c$`),
        `Using $s(1) = ${s_0}$ we have that $c = ${s_constant_text}$`,
        MCMaths.cleaner(`So $s(t) = ${s_text}+${s_constant_text}$`),
        `$s(${t_1}) = ${s_of_t1.toPrecision(3)}m$`,
        `So $\\text{distance travelled} = ${part_b_answer.toPrecision(3)}m$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(part_a_answer.toPrecision(3)),
        0.1,
        `a) $\\text{ } =$`
      );

      group2.addInput(
        parseFloat(part_b_answer.toPrecision(3)),
        0.1,
        `b) $\\text{ } =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const k_1 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const k_2 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const k_2_squared = k_2 ** 2;
    const omega = MCRandom.randomInt(2, 5);
    const k_3 = k_1 / omega;
    const v_0 = MCRandom.randomInt(2, 9);
    const s_0 = MCRandom.randomInt(2, 9);
    const k_4 = -k_3 / omega;
    const t_1 = MCRandom.randomInt(2, 9);

    const a_text = `${k_1}\\cos(${omega}t)+t^2-${k_2_squared}`;

    const v_text = `${k_3}\\sin(${omega}t)+\\frac{1}{3}t^3-${k_2_squared}t`;
    const v_constant = v_0;

    const v_of_t = (t) =>
      k_3 * Math.sin(omega * t) +
      (1 / 3) * t ** 3 -
      k_2_squared * t +
      v_constant;
    const v_of_t1 = v_of_t(t_1);

    const s_text = `${k_4}\\cos(${omega}t) + \\frac{1}{12}t^{4}-${
      k_2_squared / 2
    }t^{2}+${v_constant}t`;
    const s_constant = s_0 - k_4;
    const s_constant_text = `${s_0 - v_constant}`;

    const s_of_t = (t) =>
      k_4 * Math.cos(omega * t) +
      (1 / 12) * t ** 4 -
      (k_2_squared / 2) * t ** 2 +
      v_constant * t +
      s_constant;
    const s_of_t1 = s_of_t(t_1);

    const s_of_t1_text_workaround = `${parseFloat(s_of_t1.toPrecision(3))}`;

    const part_a_answer = Math.abs(v_of_t1);
    const part_b_answer = Math.abs(s_of_t1);

    const part_b_answer_text_workaround = `${Math.abs(
      parseFloat(s_of_t1.toPrecision(3))
    )}`;

    /* END VARIABLES AND CONSTANTS */
    if (part_a_answer > 10) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A particle $P$ moves in a straight line with acceleration $a$ where $a$ is given by the expression:`,
        MCMaths.cleaner(`$a(t) = ${a_text}$`),
        `Initially $P$ is moving with velocity $${v_0}ms^{-1}$ and has displacement $${s_0}m$.`,
        `a) Find the speed, of the particle at time $t=${t_1}$`,
        `b) Find the distance travelled by the particle at time $t=${t_1}$`,
        `Give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Integrate $a$ to find $v$:`,
        MCMaths.cleaner(`$v(t) = \\int{${a_text}}dt$`),
        MCMaths.cleaner(`$= ${v_text} + c$`),
        `Using $v(0) = ${v_0}$ we have that $c = ${v_constant}$`,
        MCMaths.cleaner(`So $v(t) = ${v_text}+${v_constant}$`),
        `$v(${t_1}) = ${v_of_t1.toPrecision(3)}ms^{-1}$`,
        `So $\\text{speed} = ${part_a_answer.toPrecision(3)}ms^{-1}$.`,

        `HEADING b) Integrate $v$ to find $s$:`,
        MCMaths.cleaner(`$s(t) = \\int{${v_text}+${v_constant}}dt$`),
        MCMaths.cleaner(`$= ${s_text} + c$`),
        `Using $s(0) = ${s_0}$ we have that $c = ${s_constant_text}$`,
        MCMaths.cleaner(`So $s(t) = ${s_text}+${s_constant_text}$`),
        `$s(${t_1}) = ${s_of_t1_text_workaround}m$`,
        `So $\\text{distance travelled} = ${part_b_answer_text_workaround}m$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(part_a_answer.toPrecision(3)),
        1,
        `a) $\\text{ } =$`
      );

      group2.addInput(
        parseFloat(part_b_answer.toPrecision(3)),
        1,
        `b) $\\text{ } =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const k_1 = MCRandom.randomInt(2, 9);
    const omega_over_two = MCRandom.randomInt(2, 9);
    const omega = 2 * omega_over_two;

    const a_text = `${k_1}\\sin(${omega_over_two}t)\\cos(${omega_over_two}t)`;
    const a_text_simplified = `${k_1 / 2}\\sin(${omega}t)`;

    const a_min = -k_1 / 2;
    const a_max = k_1 / 2;

    const v_0 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const k_2 = -(k_1 / 2) / omega;
    const v_constant = v_0 - k_2;

    const v_text = `${k_2}\\cos(${omega}t)`;

    const alpha = (1 / omega) * (1 / 2);
    const t_max = alpha * Math.PI;
    const t_max_text = `${alpha}\\pi`;

    const beta = (1 / omega) * (-1 / 2);
    const t_min = beta * Math.PI;
    const t_min_text = `${beta}\\pi`;

    const s_0 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const k_3 = k_2 / omega;
    const s_constant = s_0;

    const s_text = `${k_3}\\sin(${omega}t)+${v_constant}t`;
    const s_t = (t) => k_3 * Math.sin(omega * t) + v_constant * t + s_constant;

    const s = s_t(t_max) - s_t(t_min);

    const part_a_answer_1 = a_min;
    const part_a_answer_2 = a_max;

    const part_b_answer_1 = t_min;
    const part_b_answer_2 = t_max;

    const part_c_answer = Math.abs(s);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A particle $P$ is moving with acceleration $a$ where $a$ is given by the equation:`,
        `$a(t) = ${a_text}, \\text{ } 0 < t < \\frac{\\pi}{${omega_over_two}}$.`,
        `a) What is $P$'s minimum and maximum acceleration, $a_{min}$ and $a_{max}$?`,
        `b) At what time does $P$ reach its minimum and maximum acceleration, $t_{min}$ and $t_{max}$`,
        `Given that $v(0) = ${v_0}ms^{-1}$`,
        `c) Calculate the distance, $|s|$, between when $P$ is at its maximum acceleration and when $P$ is at its minimum acceleration.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Use the identity $\\sin(2t) = 2\\sin(t)\\cos(t)$ to obtain:`,
        MCMaths.cleaner(`$a(t) = ${a_text_simplified}$`),
        `Since $-1 \\leq \\sin(${2 * omega_over_two}t) \\leq 1$`,
        MCMaths.cleaner(`$${a_min} \\leq a(t) \\leq ${a_max}$`),
        `$\\therefore a_{max} = ${a_max}ms^{-1}$`,
        `$\\therefore a_{min} = ${a_min}ms^{-1}$.`,

        // PART B UNFINISHED
        `HEADING b) Again using $-1 \\leq \\sin(t) \\leq 1$:`,
        MCMaths.cleaner(`$${a_min} \\leq ${a_text_simplified} \\leq ${a_max}$`),
        MCMaths.cleaner(`So $-1 \\leq \\sin(${omega}t) \\leq 1$`),
        MCMaths.cleaner(`$\\sin^{-1}(-1) \\leq ${omega}t \\leq \\sin^{-1}(1)$`),
        MCMaths.cleaner(
          `$${1 / omega}\\sin^{-1}(-1) \\leq t \\leq ${1 / omega}\\sin^{-1}(1)$`
        ),
        MCMaths.cleaner(`$${t_min_text} \\leq t \\leq ${t_max_text}$`),
        MCMaths.cleaner(
          `So $t_{min} = ${t_min_text}$ and $t_{max} = ${t_max_text}$`
        ),

        `HEADING c) Integrate $a(t)$ to obtain $v(t)$:`,
        MCMaths.cleaner(`$v(t) = \\int{${a_text}}dt$`),
        MCMaths.cleaner(`$v(t) = ${v_text}+c$`),
        MCMaths.cleaner(
          `Using $v(0) = ${v_0}$ we can see that $c = ${v_constant}$`
        ),
        MCMaths.cleaner(`$v(t) = ${v_text}+${v_constant}$`),
        `HEADING Integrate v(t) between $t_{min}$ and $t_{max}$ to obtain the distance travelled between $a_{max}$ and $a_{min}$:`,
        MCMaths.cleaner(
          `$\\displaystyle s = \\int_{${t_min_text}}^{${t_max_text}}{${v_text}+${v_constant}}dt$`
        ),
        MCMaths.cleaner(
          `$\\displaystyle s = \\left[ ${s_text} \\right]_{${t_min_text}}^{${t_max_text}}$`
        ),
        `So $s = ${s.toPrecision(3)}m$`,
        `$\\therefore |s| = ${part_c_answer.toPrecision(3)}m$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(2);
      const group3 = new MCQuestion.InputGroup(1);

      group1.addInput(part_a_answer_1, 0, `a) $\\text{ } a_{min} =$`);
      group1.addInput(part_a_answer_2, 0, `a) $\\text{ } a_{max} =$`);

      group2.addInput(part_b_answer_1, 0, `b) $\\text{ } t_{min} =$`);
      group2.addInput(part_b_answer_2, 0, `b) $\\text{ } t_{max} =$`);

      group3.addInput(
        parseFloat(part_c_answer.toPrecision(3)),
        0.1,
        `c) $\\text{ } |s| =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    // const k_1 = MCRandom.randomInt(2, 9);
    const alpha = MCRandom.randomInt(2, 4);
    const beta = MCRandom.randomInt(2, 4);
    const gamma = MCRandom.randomInt(7, 12);
    const t_1 = (gamma * alpha) / (beta + alpha);

    const v_text_part_1 = MCMaths.cleaner(`e^{\\frac{t}{${alpha}}}`);
    const v_text_part_1_domain = `\\text{ if } 0 \\leq t \\leq ${t_1}`;
    const v_text_part_1_full = v_text_part_1 + v_text_part_1_domain;

    const v_text_part_2 = MCMaths.cleaner(`e^{\\frac{${gamma}-t}{${beta}}}`);
    const v_text_part_2_domain = `\\text{ if } t \\gt ${t_1}`;
    const v_text_part_2_full = v_text_part_2 + v_text_part_2_domain;

    const t_0 = MCRandom.randomInt(1, 10);
    const t_2 = MCRandom.randomInt(1, 10);

    const a_text_part_1 = MCMaths.cleaner(`${1 / alpha}${v_text_part_1}`);
    const a_t_part_1 = (t) => (1 / alpha) * Math.E ** (t / alpha);
    const part_a_answer = a_t_part_1(t_0);

    const a_text_part_2 = MCMaths.cleaner(`${-1 / beta}${v_text_part_2}`);
    const a_t_part_2 = (t) => (-1 / beta) * Math.E ** ((gamma - t) / beta);
    const part_b_answer = a_t_part_2(t_2);
    /* END VARIABLES AND CONSTANTS */
    if (t_0 < t_1 && t_1 < t_2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `$P$ is moving with velocity $v$, in one direction, given by:`,
        MCMaths.cleaner(
          `$\\displaystyle v(t) = \\begin{cases} { ${v_text_part_1_full} } \\\\ { ${v_text_part_2_full} } \\end{cases}$`
        ),
        `a) Find the acceleration of $P$ when $t = ${t_0}$`,
        `b) Find the acceleration of $P$ when $t = ${t_2}$`,
        `Give your answer to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        MCMaths.cleaner(
          `HEADING a) Differentiate the piecewise function for time $0 \\leq t \\leq ${t_1}$`
        ),
        `$a(t) = \\frac{dv}{dt}$`,
        `$= ${a_text_part_1}$`,
        `So $a(${t_0}) = ${a_text_part_1.replace(`t`, `${t_0}`)}$`,
        `$\\therefore a(${t_0}) = ${part_a_answer.toPrecision(3)}ms^{-2}$.`,

        MCMaths.cleaner(
          `HEADING b) Differentiate the piecewise function for time $t > ${t_1}$`
        ),
        `$a(t) = \\frac{dv}{dt}$`,
        `$= ${a_text_part_2}$`,
        `So $a(${t_2}) = ${a_text_part_2.replace(`t`, `${t_2}`)}$`,
        `$\\therefore a(${t_2}) = ${part_b_answer.toPrecision(3)}ms^{-2}$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(part_a_answer.toPrecision(3)),
        0.1,
        `a) $\\text{ } a(${t_0}) =$`
      );
      group2.addInput(
        parseFloat(part_b_answer.toPrecision(3)),
        0.1,
        `b) $\\text{ } a(${t_2}) =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    // const k_1 = MCRandom.randomInt(2, 9);
    const alpha = MCRandom.randomInt(2, 4);
    const beta = MCRandom.randomInt(2, 4);
    const gamma = MCRandom.randomInt(7, 12);
    const t_1 = (gamma * alpha) / (beta + alpha);

    const v_text_part_1 = MCMaths.cleaner(`e^{\\frac{t}{${alpha}}}`);
    const v_text_part_1_domain = `\\text{ if } 0 \\leq t \\leq ${t_1}`;
    const v_text_part_1_full = v_text_part_1 + v_text_part_1_domain;

    const v_text_part_2 = MCMaths.cleaner(`e^{\\frac{${gamma}-t}{${beta}}}`);
    const v_text_part_2_domain = `\\text{ if } t \\gt ${t_1}`;
    const v_text_part_2_full = v_text_part_2 + v_text_part_2_domain;

    const t_0 = MCRandom.randomInt(1, 10);
    const t_2 = MCRandom.randomInt(1, 10);

    const s_text_part_1 = MCMaths.cleaner(`${alpha}${v_text_part_1}`);
    const s_t_part_1 = (t) => alpha * Math.E ** (t / alpha);
    const part_a_answer = s_t_part_1(t_0);

    const s_text_part_2 = MCMaths.cleaner(`${-beta}${v_text_part_2}`);
    const a_t_part_2 = (t) => -beta * Math.E ** ((gamma - t) / beta);
    const part_b_answer = a_t_part_2(t_2);
    /* END VARIABLES AND CONSTANTS */
    if (t_0 < t_1 && t_1 < t_2 && t_2 !== gamma) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `$P$ is moving with velocity $v$, in one direction, given by:`,
        MCMaths.cleaner(
          `$\\displaystyle v(t) = \\begin{cases} { ${v_text_part_1_full} } \\\\ { ${v_text_part_2_full} } \\end{cases}$`
        ),
        MCMaths.cleaner(
          `Given that $s(0) = 1$ and $s(${gamma}) = ${-1 / beta}$`
        ),
        `a) Find the displacement of $P$ when $t = ${t_0}$`,
        `b) Find the displacement of $P$ when $t = ${t_2}$`,
        `Give your answer to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        MCMaths.cleaner(
          `HEADING a) Integrate the piecewise function for time $0 \\leq t \\leq ${t_1}$`
        ),
        `$s(t) = \\int{${v_text_part_1}}dt$`,
        `$= ${s_text_part_1} + c$`,
        `Using $s(0) = 1$ we can see that $c=0$ so:`,
        `$s(t) = ${s_text_part_1}$`,
        `So $s(${t_0}) = ${s_text_part_1.replace(`t`, `${t_0}`)}$`,
        `$\\therefore s(${t_0}) = ${part_a_answer.toPrecision(3)}m$.`,

        MCMaths.cleaner(
          `HEADING b) Integrate the piecewise function for time $t > ${t_1}$`
        ),
        `$s(t) = \\int{${v_text_part_2}}dt$`,
        `$= ${s_text_part_2} + c$`,
        MCMaths.cleaner(
          `Using $s(${gamma}) = ${-1 / beta}$ we can see that $c=0$ so:`
        ),
        `$s(t) = ${s_text_part_2}$`,
        `So $s(${t_2}) = ${s_text_part_2.replace(`t`, `${t_2}`)}$`,
        `$\\therefore s(${t_2}) = ${part_b_answer.toPrecision(3)}m$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(part_a_answer.toPrecision(3)),
        0.1,
        `a) $\\text{ } s(${t_0}) =$`
      );
      group2.addInput(
        parseFloat(part_b_answer.toPrecision(3)),
        0.1,
        `b) $\\text{ } s(${t_2}) =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const k_1 = MCRandom.randomInt(2, 9);
    const omega = MCRandom.randomInt(2, 5);
    const v_text = `${k_1}\\sin(${omega}t)`;

    const a_text_correct = `${k_1 * omega}\\cos(${omega}t)`;
    const a_text_incorrect_1 = `${k_1}\\cos(${omega}t)`;
    const a_text_incorrect_2 = `${omega}\\cos(${omega}t)`;
    const a_text_incorrect_3 = `${-k_1 * omega}\\cos(${omega}t)`;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `A particle $P$ is given by the velocity $v(t) = ${v_text}$`,
        `Choose the correct expression for $a(t):$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$a(t) = ${a_text_correct}$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$a(t) = ${a_text_incorrect_1}$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$a(t) = ${a_text_incorrect_2}$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$a(t) = ${a_text_incorrect_3}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const k_1 = MCRandom.randomInt(2, 9);
    const omega = MCRandom.randomInt(2, 5);
    const v_text = `${k_1}\\cos(${omega}t)`;

    const a_text_correct = `${-k_1 * omega}\\sin(${omega}t)`;
    const a_text_incorrect_1 = `${-k_1}\\sin(${omega}t)`;
    const a_text_incorrect_2 = `${omega}\\sin(${omega}t)`;
    const a_text_incorrect_3 = `${k_1 * omega}\\sin(${omega}t)`;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `A particle $P$ is given by the velocity $v(t) = ${v_text}$`,
        `Choose the correct expression for $a(t):$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$a(t) = ${a_text_correct}$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$a(t) = ${a_text_incorrect_1}$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$a(t) = ${a_text_incorrect_2}$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$a(t) = ${a_text_incorrect_3}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const k_1 = MCRandom.randomInt(2, 9);
    const omega = MCRandom.randomInt(2, 5);
    const a_text = `${k_1}\\cos(${omega}t)`;

    const v_text_correct = `${k_1 / omega}\\sin(${omega}t) + c`;
    const v_text_incorrect_1 = `${-k_1}\\sin(${omega}t) + c`;
    const v_text_incorrect_2 = `${k_1 * omega}\\sin(${omega}t) + c`;
    const v_text_incorrect_3 = `${k_1 / omega}\\sin(t) + c`;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `A particle $P$ is given by the acceleration $a(t) = ${a_text}$`,
        `Choose the correct expression for $v(t):$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(MCMaths.cleaner(`$v(t) = ${v_text_correct}$`));

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(MCMaths.cleaner(`$v(t) = ${v_text_incorrect_1}$`));

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(MCMaths.cleaner(`$v(t) = ${v_text_incorrect_2}$`));

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(MCMaths.cleaner(`$v(t) = ${v_text_incorrect_3}$`));

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const k_1 = MCRandom.randomInt(2, 9);
    const omega = MCRandom.randomInt(2, 5);
    const a_text = `${k_1}\\sin(${omega}t)`;

    const v_text_correct = `${-k_1 / omega}\\cos(${omega}t) + c`;
    const v_text_incorrect_1 = `${k_1}\\cos(${omega}t) + c`;
    const v_text_incorrect_2 = `${-k_1 * omega}\\cos(${omega}t) + c`;
    const v_text_incorrect_3 = `${-k_1 / omega}\\cos(t) + c`;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `A particle $P$ is given by the acceleration $a(t) = ${a_text}$`,
        `Choose the correct expression for $v(t):$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(MCMaths.cleaner(`$v(t) = ${v_text_correct}$`));

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(MCMaths.cleaner(`$v(t) = ${v_text_incorrect_1}$`));

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(MCMaths.cleaner(`$v(t) = ${v_text_incorrect_2}$`));

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(MCMaths.cleaner(`$v(t) = ${v_text_incorrect_3}$`));

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const k_1 = MCRandom.randomInt(2, 9);
    const omega = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const a_text = `${k_1}e^{${omega}t}`;

    const v_text_correct = `${k_1 / omega}e^{${omega}t} + c`;
    const v_text_incorrect_1 = `${k_1 * omega}e^{${omega}t} + c`;
    const v_text_incorrect_2 = `${k_1 + omega}e^{${omega}t} + c`;
    const v_text_incorrect_3 = `${k_1 / omega}e^{t} + c`;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (k_1 + omega !== 0 /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `A particle $P$ is given by the acceleration $a(t) = ${a_text}$`,
        `Choose the correct expression for $v(t):$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(MCMaths.cleaner(`$v(t) = ${v_text_correct}$`));

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(MCMaths.cleaner(`$v(t) = ${v_text_incorrect_1}$`));

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(MCMaths.cleaner(`$v(t) = ${v_text_incorrect_2}$`));

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(MCMaths.cleaner(`$v(t) = ${v_text_incorrect_3}$`));

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const k_1 = MCRandom.randomInt(2, 9);
    const omega = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const v_text = `${k_1}e^{${omega}t}`;

    const a_text_correct = `${k_1 * omega}e^{${omega}t}`;
    const a_text_incorrect_1 = `${k_1 + omega}e^{${omega}t}`;
    const a_text_incorrect_2 = `${omega}e^{${omega}t}`;
    const a_text_incorrect_3 = `${k_1 * omega}e^{t}`;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (k_1 + omega !== 0 /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `A particle $P$ is given by the velocity $v(t) = ${v_text}$`,
        `Choose the correct expression for $a(t):$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$a(t) = ${a_text_correct}$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$a(t) = ${a_text_incorrect_1}$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$a(t) = ${a_text_incorrect_2}$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$a(t) = ${a_text_incorrect_3}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_MCQ_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const k_1 = MCRandom.randomInt(2, 9);
    const omega = MCRandom.randomInt(2, 5);
    const v_text = `${k_1}\\ln{${omega}t}`;

    const a_text_correct = `\\displaystyle \\frac{${k_1}}{t}`;
    const a_text_incorrect_1 = `\\displaystyle \\frac{${k_1}}{\\ln{t}}`;
    const a_text_incorrect_2 = `\\displaystyle ${k_1 * omega}\\ln(${omega}t)`;
    const a_text_incorrect_3 = `\\displaystyle ${k_1 * omega}\\ln(t)`;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (k_1 + omega !== 0 /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `A particle $P$ is given by the velocity $v(t) = ${v_text}$`,
        `Choose the correct expression for $a(t):$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$a(t) = ${a_text_correct}$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$a(t) = ${a_text_incorrect_1}$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$a(t) = ${a_text_incorrect_2}$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$a(t) = ${a_text_incorrect_3}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VariableAcceleration_MCQ_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const k_1 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const omega = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const a_text = `${k_1}e^{${omega}t}`;

    const v_0 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const v_constant_correct = v_0 - k_1;
    const v_constant_incorrect1 = v_0 + k_1;
    const v_constant_incorrect2 = k_1;
    const v_constant_incorrect3 = v_0;

    const v_text_correct = MCMaths.cleaner(
      `${k_1 / omega}e^{${omega}t}+${v_constant_correct}`
    );

    const v_text_incorrect_1 = MCMaths.cleaner(
      `${k_1 / omega}e^{${omega}t}+${v_constant_incorrect1}`
    );

    const v_text_incorrect_2 = MCMaths.cleaner(
      `${k_1 / omega}e^{${omega}t}+${v_constant_incorrect2}`
    );

    const v_text_incorrect_3 = MCMaths.cleaner(
      `${k_1 / omega}e^{${omega}t}+${v_constant_incorrect3}`
    );

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (
      v_constant_correct !== 0 &&
      v_constant_incorrect1 !==
        0 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `A particle $P$ is moving in a straight line with acceleration $a$ where`,
        `$a(t) = ${a_text}$`,
        `Given that at time $t=0$ the particle has velocity $v = ${v_0}ms^{-1}$`,
        `Choose the correct expression for $v(t)$:`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$v(t) = ${v_text_correct}$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$v(t) = ${v_text_incorrect_1}$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$v(t) = ${v_text_incorrect_2}$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$v(t) = ${v_text_incorrect_3}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const root = MCRandom.randomInt(2, 9);
    const notRoot = MCRandom.randomInt(2, 9) * -1;

    const at_i = MCMaths.Polynomial.generateFromRoots([root, notRoot], `t`);
    const vt_i = at_i.integral();
    const rt_i = vt_i.integral();

    const rt_j = new MCMaths.Polynomial([MCRandom.randomInt(2, 9), 0], `t`);
    const vt_j = rt_j.derivative();
    const at_j = vt_j.derivative();

    const rt0_i = rt_i.evaluate(root);
    const rt0_j = rt_j.evaluate(root);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `The position of the particle $\\textbf{r}$ is given by the following expression:`,
        `$\\textbf{r}(t) = (${rt_i.toString()})\\textbf{i} + ${rt_j.toString()}\\textbf{j}, \\text{ } t \\geq 0$`,
        `At a time $t_0$ the particle stops accelerating in every direction.`,
        `a) Find the value of $t_0$.`,
        `b) Give the position of the particle at time $t_0$.`,
        `Give your answers to the nearest integer.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) First we need to find the expression for the acceleration:`,
        `$\\displaystyle \\textbf{a}(t) = \\frac{d}{dt}\\textbf{v}(t)$`,
        `$\\displaystyle \\textbf{v}(t) = \\frac{d}{dt}\\textbf{r}(t)$`,
        `$\\displaystyle \\textbf{v}(t) = (${vt_i})\\textbf{i} + ${vt_j}\\textbf{j}$`,
        `$\\implies \\textbf{a}(t) = (${at_i})\\textbf{i}$`,
        MCMaths.cleaner(
          `$\\textbf{a}(t) = (t-${notRoot})(t-${root})\\textbf{i}$`
        ),
        `When $\\textbf{a}(t) = 0$ and since $t > 0$:`,
        `$\\therefore t_0 = ${root}s$.`,

        `HEADING b) To find the position of the particle at time $t_0$ we evaluate the original expression at time $t_0$:`,
        `$\\textbf{r}(${root}) =${`[${rt_i.toString()}]`.replace(
          /t/g,
          `(${root})`
        )}\\textbf{i}${`+[${rt_j.toString()}]`.replace(
          /t/g,
          `(${root})`
        )}\\textbf{j}$`,
        `$= ${rt0_i.toFixed(0)}\\textbf{i} + ${rt0_j.toFixed(0)}\\textbf{j}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(2);

      group1.addInput(root, 0, `a) $\\text{ } t_0 =$`);
      group2.addInput(rt0_i, 1, `b) $\\text{ } \\textbf{r}_i =$`);
      group2.addInput(rt0_j, 1, `b) $\\text{ } \\textbf{r}_j =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_Exam_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(2, 20) * MCRandom.randomInt(-1, 1, 2);
    const b = new MCMaths.Fraction(MCRandom.randomInt(1, 20) / 10);
    const vt_j = new MCMaths.Polynomial(
      [b.toFloat(), 0, MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2)],
      `t`
    );

    const at_j = vt_j.derivative();

    const at0_i = MCRandom.randomInt(2, 20);

    const t1 = MCRandom.randomInt(2, 9);

    const k = new MCMaths.Fraction(at0_i / a);
    const ak = new MCMaths.Fraction((a * k.numerator) / k.denominator);
    const kt1 = new MCMaths.Fraction((t1 * k.numerator) / k.denominator);

    const at1_j = at_j.evaluate(t1);

    const at1_magnitude =
      (ak.toFloat() * Math.E ** kt1.toFloat()) ** 2 + at1_j ** 2;

    /* END VARIABLES AND CONSTANTS */
    if (at1_magnitude < 100 && at1_magnitude > 2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `In this question $\\textbf{i}$ and $\\textbf{j}$ are the unit vectors east and north respectively.`,
        `At time $t$ seconds, a particle $P$ moves such that its velocity, $\\textbf{v}m$, is given by:`,
        MCMaths.cleaner(
          `$\\textbf{v}(t) = ${a}e^{kt}\\textbf{i}+(${vt_j})\\textbf{j}, \\text{ } t \\geq 0$`
        ),
        `Where $k$ is a constant.`,
        `Given that at time $t = 0$ the particle is accelerating with acceleration $(${at0_i}\\textbf{i})ms^{ -2}$,`,
        `a) Find the value of $k$.`,
        `b) Hence, or otherwise, find the value of the magnitude of the accleration, $|\\textbf{a}|$ at time $t=${t1}$.`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) To find the expression for $\\textbf{a}(t)$, find the derivative of $\\textbf{v}(t)$ with respect to $t$:`,
        `$\\textbf{a}(t) = \\displaystyle \\frac{d}{dt}\\textbf{v}(t)$`,
        MCMaths.cleaner(`$= ${a}ke^{kt}\\textbf{i}+${at_j}\\textbf{j}$`),
        `Since at time $t=0$ the acceleration is $(${at0_i}\\textbf{i})ms^{-2}$`,
        MCMaths.cleaner(`$${at0_i}\\textbf{i} = ${a}ke^{k(0)}\\textbf{i}$`),
        MCMaths.cleaner(`$\\implies ${at0_i} = ${a}k$`),
        `So $k = ${k.toString()}$`,
        `$\\therefore k = ${k.toFloat().toPrecision(3)}.$`,

        `HEADING b) From part a) we can see that:`,
        MCMaths.cleaner(
          `$\\textbf{a}(t) = ${ak}e^{${k.toString()}t}\\textbf{i}+${at_j}\\textbf{j}$`
        ),
        `At time $t=${t1}$:`,
        MCMaths.cleaner(
          `$\\textbf{a}(t) = ${ak.toString()}e^{${kt1.toString()}}\\textbf{i}+${at1_j.toFixed(
            1
          )}\\textbf{j}$`
        ),
        `$|\\textbf{a}| = \\sqrt{(${ak}e^{${kt1.toString()}})^2+(${at1_j.toFixed(
          1
        )})^2}$`,
        `$\\therefore |\\textbf{a}| = ${at1_magnitude.toPrecision(3)}ms^{-2}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(k.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(k.toFloat().toPrecision(3)) -
            parseFloat(k.toFloat().toPrecision(1))
        ),
        `a) $\\text{ } k =$`
      );
      group2.addInput(
        parseFloat(at1_magnitude.toPrecision(3)),
        1,
        `b) $\\text{ } |\\textbf{a}| =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const vt_i = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0],
      `t`
    );
    const vt_i_Coefficients = vt_i.getCoefficients();
    const vt_j = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0, 0],
      `t`
    );
    const vt_j_Coefficients = vt_j.getCoefficients();

    const t1 = MCRandom.randomInt(2, 9);
    const r_0 = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const t0 = MCRandom.randomInt(2, 9);

    const speedSquared = vt_i.evaluate(t1) ** 2 + vt_j.evaluate(t1) ** 2;
    const speed = Math.sqrt(speedSquared);

    const at_i = vt_i.derivative();
    const at_j = vt_j.derivative();

    const rt_i = vt_i.integral();
    const rt_i_Coefficients = rt_i.getCoefficients();
    const rt_j = vt_j.integral();
    const rt_j_Coefficients = rt_j.getCoefficients();

    const c_i = r_0[0] - rt_i.evaluate(t0);
    const c_j = r_0[1] - rt_j.evaluate(t0);

    const rt1_i = rt_i.evaluate(t1) + c_i;
    const rt1_j = rt_j.evaluate(t1) + c_j;

    /* END VARIABLES AND CONSTANTS */
    if (
      t1 > t0 &&
      speed < 250 &&
      c_j === Math.round(c_j) &&
      c_i !== 0 &&
      rt_j.evaluate(t0) === Math.round(rt_j.evaluate(t0)) &&
      rt1_i === Math.round(rt1_i) &&
      rt1_j === Math.round(rt1_j)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        MCMaths.cleaner(
          `A particle is moving with a velocity of $\\textbf{v}(t) = ${vt_i}\\textbf{i}+${vt_j}\\textbf{j}, \\text{ } t \\geq 0$`
        ),
        `a) Find the speed, $|\\textbf{v}|$, of the particle when $t = ${t1}$.`,
        `b) Find the acceleration, $\\textbf{a}$, of the particle when $t = ${t1}$.`,
        MCMaths.cleaner(
          `Given that the particle is at the position vector $(${r_0[0]}\\textbf{i}+${r_0[1]}\\textbf{j})$ when $t = ${t0}$.`
        ),
        `c) Find the position vector, $\\textbf{r}$, of the particle when $t = ${t1}$`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) To find the speed at $t=${t1}$ we first need to find the value of $\\textbf{v}(${t1})$:`,
        MCMaths.cleaner(
          `$\\textbf{v}(${t1}) = ${vt_i_Coefficients[0]}(${t1})\\textbf{i}+${vt_j_Coefficients[0]}(${t1})^2\\textbf{j}$`
        ),
        MCMaths.cleaner(
          `$\\textbf{v}(${t1}) = ${vt_i.evaluate(
            t1
          )}\\textbf{i}+${vt_j.evaluate(t1)}\\textbf{j}$`
        ),
        MCMaths.cleaner(
          `Then $|\\textbf{v}| = \\sqrt{(${vt_i.evaluate(
            t1
          )})^2+(${vt_j.evaluate(t1)})^2}$`
        ),
        `$= \\sqrt{${speedSquared}}$`,
        `$= ${speed.toPrecision(3)}ms^{-1}$.`,

        `HEADING b) First, find the derivative of $\\textbf{v}(t)$:`,
        `$\\displaystyle \\textbf{a}(t) = \\frac{d}{dt}\\textbf{v}(t)$`,
        MCMaths.cleaner(`$= ${at_i}\\textbf{i}+${at_j}\\textbf{j}$`),
        `When $t = ${t1}$:`,
        MCMaths.cleaner(
          `$= ${at_i}\\textbf{i}+${at_j.evaluate(t1)}\\textbf{j}$`
        ),
        MCMaths.cleaner(
          `$\\therefore \\textbf{a} = (${at_i.evaluate(
            t1
          )}\\textbf{i}+${at_j.evaluate(t1)}\\textbf{j})ms^{-2}$.`
        ),

        `HEADING c) To find position, $\\textbf{r}$, we need to integrate $\\textbf{v}(t)$ with respect to $t$:`,
        MCMaths.cleaner(
          `$\\displaystyle \\textbf{r}(t) = \\int{(${vt_i}\\textbf{i}+${vt_j}\\textbf{j})}dt$`
        ),
        MCMaths.cleaner(
          `$= ${rt_i}\\textbf{i}+${rt_j}\\textbf{j} + \\textbf{c}$`
        ),
        `When $t=${t0}$:`,
        MCMaths.cleaner(
          `$\\displaystyle (${r_0[0]}\\textbf{i}+${r_0[1]}\\textbf{j}) = ${rt_i_Coefficients[0]}(${t0})^2\\textbf{i}+${rt_j_Coefficients[0]}(${t0})^3\\textbf{j} + \\textbf{c}$`
        ),
        MCMaths.cleaner(
          `$\\displaystyle (${r_0[0]}\\textbf{i}+${
            r_0[1]
          }\\textbf{j}) = ${rt_i.evaluate(t0)}\\textbf{i}+${rt_j.evaluate(
            t0
          )}\\textbf{j} + \\textbf{c}$`
        ),
        MCMaths.cleaner(
          `$\\implies \\textbf{c} = ${c_i}\\textbf{i}+${c_j}\\textbf{j}$`
        ),
        MCMaths.cleaner(
          `So $\\textbf{r}(t) = (${rt_i}+${c_i})\\textbf{i}+(${rt_j}+${c_j})\\textbf{j}$`
        ),
        `When $t=${t1}$:`,
        MCMaths.cleaner(
          `$\\therefore \\textbf{r} = (${rt1_i}\\textbf{i}+${rt1_j}\\textbf{j})m$.`
        ),
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(2);
      const group3 = new MCQuestion.InputGroup(2);

      group1.addInput(
        parseFloat(speed.toPrecision(3)),
        0.1,
        `a) $\\text{ } |\\textbf{v}| =$`
      );
      group2.addInput(at_i.evaluate(t1), 0, `b) $\\text{ } \\textbf{a}_i$`);
      group2.addInput(at_j.evaluate(t1), 0, `b) $\\text{ } \\textbf{a}_j$`);
      group3.addInput(rt1_i, 0, `c) $\\text{ } \\textbf{r}_i$`);
      group3.addInput(rt1_j, 0, `c) $\\text{ } \\textbf{r}_j$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pMass = MCRandom.randomInt(2, 9);

    const vt_i = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0],
      `t`
    );
    const root = MCRandom.randomInt(2, 9);
    const nonRoot = -MCRandom.randomInt(2, 9);
    const vt_j = MCMaths.Polynomial.generateFromRoots([root, nonRoot], `t`);

    const rt_i = vt_i.integral();
    const rt_j = vt_j.integral();

    const at_i = vt_i.derivative();
    const at_j = vt_j.derivative();

    const ft0_i = at_i.evaluate(root) * pMass;
    const ft0_j = at_j.evaluate(root) * pMass;
    const ft0_magnitude = Math.sqrt(ft0_i ** 2 + ft0_j ** 2);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `In this question $\\textbf{i}$ and $\\textbf{j}$ are the unit vectors east and north respectively.`,
        `A particle $P$ has a mass of $${pMass}kg$ and originally starts at an origin point $O$. The position $\\textbf{r}$ relative to $O$ at time $t$ is given by:`,
        MCMaths.cleaner(
          `$\\textbf{r}(t) = ${rt_i}\\textbf{i}+(${rt_j})\\textbf{j}, \\text{ } t \\geq 0$`
        ),
        `a) Find the time, $t_0$, when the velocity is due east.`,
        `The particle experiences a force $\\textbf{F}N$.`,
        `b) Find the magnitude of the force, $|\\textbf{F}|$, when $t = t_0$.`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) First we need to find the expression for velocity by finding the derivative of $\\textbf{r}$:`,
        `$\\displaystyle \\textbf{v}(t) = \\frac{d}{dt}\\textbf{r}(t)$`,
        `$= ${vt_i}\\textbf{i} + (${vt_j})\\textbf{j}$`,
        `The velocity is pointing east when the $\\textbf{j}$ component is equal to zero:`,
        `So $${vt_j} = 0$`,
        MCMaths.cleaner(`$\\implies (t-${nonRoot})(t-${root}) = 0$`),
        `And since $t>0$:`,
        `$\\therefore t_0 = ${root}s$.`,

        `HEADING b) Since $\\textbf{F} = m\\textbf{a}$ we need to find the expression for $\\textbf{a}(t)$:`,
        `$\\textbf{a}(t) = \\displaystyle \\frac{d}{dt}\\textbf{v}(t)$`,
        `$= ${at_i}\\textbf{i}+(${at_j})\\textbf{j}$`,
        `When $t = ${root}$:`,
        `$\\textbf{a} = ${at_i}\\textbf{i}+${at_j.evaluate(root)}\\textbf{j}$`,
        `$\\implies \\textbf{F} = ${ft0_i}\\textbf{i}+${ft0_j}\\textbf{j}$`,
        `$|\\textbf{F}| = \\sqrt{(${ft0_i})^2+(${ft0_j})^2}$`,
        `$\\therefore |\\textbf{F}| = ${ft0_magnitude.toPrecision(3)}N.$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(root, 0, `a) $\\text{ } t_0 =$`);
      group2.addInput(
        parseFloat(ft0_magnitude.toPrecision(3)),
        1,
        `b) $\\text{ } |\\textbf{F}| =$`
      );
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const rt_i = new MCMaths.Exponential(
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      `e`,
      MCRandom.randomInt(1, 3),
      `t`
    );
    const rt_j = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0],
      `t`
    );

    const t0 = MCRandom.randomInt(2, 9);

    const vt_i = rt_i.derivative();
    const vt_j = rt_j.derivative();

    const vt0_i = vt_i.evaluate(t0);
    const vt0_j = vt_j.evaluate(t0);

    const at_i = vt_i.derivative();
    const at_j = vt_j.derivative();

    const at0_i = at_i.evaluate(t0);
    const at0_j = at_j.evaluate(t0);

    const at0_magnitude = Math.sqrt(at0_i ** 2 + at0_j ** 2);

    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(vt0_i) < 1000) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A particle $P$ originally starts at an origin point $O$. The position $\\textbf{r}$ relative to $O$ at time $t$ is given by:`,
        MCMaths.cleaner(
          `$\\textbf{r}(t) = ${rt_i}\\textbf{i}+${rt_j}\\textbf{j}, \\text{ } t \\geq 0$.`
        ),
        `a) Find the velocity, $\\textbf{v}$ when $t = ${t0}$.`,
        `b) Find the magnitude of the acceleration, $|\\textbf{a}|$ when $t = ${t0}$.`,
        `The particle experiences a force of $\\textbf{F}(t)$ at time $t$. Where $|\\textbf{F}|$ is the magnitude of the force.`,
        `c) Explain why $|\\textbf{F}| > 0$ for all times $t \\geq 0$.`,
        `Give your answers to the nearest integer.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) In order to find the velocity we need to find the derivate of $\\textbf{r}(t)$:`,
        `$\\textbf{v}(t) = \\displaystyle \\frac{d}{dt}\\textbf{r}(t)$`,
        MCMaths.cleaner(`$= ${vt_i}\\textbf{i}+${vt_j}\\textbf{j}$`),
        `When $t=${t0}$:`,
        MCMaths.cleaner(
          `$\\textbf{v} = ${vt0_i.toFixed(0)}\\textbf{i}+${vt0_j.toFixed(
            0
          )}\\textbf{j}$`,
          false
        ),

        `HEADING b) First we find the expression for acceleration by finding the derivative of $\\textbf{v}(t)$:`,
        `$\\textbf{a}(t) = \\displaystyle \\frac{d}{dt}\\textbf{v}(t)$`,
        MCMaths.cleaner(`$= ${at_i}\\textbf{i}+${at_j}\\textbf{j}$`),
        `When $t=${t0}$:`,
        MCMaths.cleaner(`$\\textbf{a} = ${at0_i.toFixed(0)}\\textbf{i}$`),
        `$\\therefore |\\textbf{a}| = ${at0_magnitude.toFixed(0)}N$.`,

        `HEADING c) $|\\textbf{F}| > 0$ for all $t \\geq 0$ because $\\textbf{F}(t) = m\\textbf{a}(t)$ and the exponential term in $\\textbf{a}(t)$ is always non-zero for all values of $t \\geq 0$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(vt0_i.toFixed(0)),
        10,
        `a) $\\text{ } \\textbf{v}_i =$`
      );
      group1.addInput(
        parseFloat(vt0_j.toFixed(0)),
        1,
        `a) $\\text{ } \\textbf{v}_j =$`
      );
      group2.addInput(at0_magnitude, 10, `b) $\\text{ } |\\textbf{a}| =$`);
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const at_i = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2)],
      `t`
    );
    const at_j = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0],
      `t`
    );

    const t_0 = MCRandom.randomInt(1, 5);

    const vt0_i = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const vt0_j = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const t_1 = MCRandom.randomInt(1, 10);

    const vt_i = at_i.integral();
    const vt_iCoefficients = vt_i.getCoefficients();
    const vt_j = at_j.integral();
    const vt_jCoefficients = vt_j.getCoefficients();

    const c_i = vt0_i - vt_i.evaluate(t_0);
    const c_j = vt0_j - vt_j.evaluate(t_0);

    const vt1_i = vt_i.evaluate(t_1) + c_i;
    const vt1_j = vt_j.evaluate(t_1) + c_j;

    const a = vt_jCoefficients[0];
    const b = -vt_iCoefficients[0];
    const c = c_j - c_i;

    const T_1 = (-b + Math.sqrt(b ** 2 - 4 * a * c)) / (2 * a);
    const T_2 = (-b - Math.sqrt(b ** 2 - 4 * a * c)) / (2 * a);

    const discriminant = b ** 2 - 4 * a * c;

    /* END VARIABLES AND CONSTANTS */
    if (
      t_1 > t_0 + 1 &&
      c_i !== 0 &&
      c_j !== 0 &&
      discriminant > 0 &&
      T_1 < 0 &&
      T_2 > 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `In this question $\\textbf{i}$ and $\\textbf{j}$ are the unit vectors east and north respectively.`,
        `A particle $P$ is moving in a plane with accleration $\\textbf{a}ms^{-2}$ where`,
        MCMaths.cleaner(
          `$\\textbf{a}(t) = ${at_i}\\textbf{i}+${at_j}\\textbf{j}, \\text{ } t \\geq 0$.`
        ),
        MCMaths.cleaner(
          `Given that at time $t = ${t_0}$, $P$ has the velocity $(${vt0_i}\\textbf{i}+${vt0_j}\\textbf{j})ms^{-1}$,`
        ),
        `a) Find the velocity, $\\textbf{v}$, when $t = ${t_1}.$`,
        `b) Find the time, $T$, when $P$ is moving directly north-east.`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) In order to find the velocity, integrate $\\textbf{a}(t)$ with respect to $t$:`,
        MCMaths.cleaner(
          `$\\textbf{v}(t) = \\displaystyle \\int{(${at_i}\\textbf{i}+${at_j}\\textbf{j})}dt$`
        ),
        MCMaths.cleaner(
          `$= ${vt_i}\\textbf{i}+${vt_j}\\textbf{j} + \\textbf{c}$`
        ),
        `When $t=${t_0}$:`,
        MCMaths.cleaner(
          `$\\textbf{v} = ${vt0_i}\\textbf{i}+${vt0_j}\\textbf{j}$`
        ),
        MCMaths.cleaner(
          `$\\implies ${vt0_i}\\textbf{i}+${vt0_j}\\textbf{j} = ${vt_iCoefficients[0]}(${t_0})\\textbf{i}+${vt_jCoefficients[0]}(${t_0})^2\\textbf{j}+\\textbf{c}$`
        ),
        MCMaths.cleaner(
          `$ ${vt0_i}\\textbf{i}+${vt0_j}\\textbf{j} = ${vt_i.evaluate(
            t_0
          )}\\textbf{i}+${vt_j.evaluate(t_0)}\\textbf{j}+\\textbf{c}$`
        ),
        MCMaths.cleaner(`$\\textbf{c} = ${c_i}\\textbf{i}+${c_j}\\textbf{j}$`),
        MCMaths.cleaner(
          `$\\therefore \\textbf{v}(t) = (${vt_i}+${c_i})\\textbf{i}+(${vt_j}+${c_j})\\textbf{j}$`
        ),
        `When $t=${t_1}$:`,
        MCMaths.cleaner(
          `$\\therefore \\textbf{v} = ${vt1_i}\\textbf{i}+${vt1_j}\\textbf{j}$.`
        ),

        `HEADING b) $P$ is moving directly north-east when the $\\textbf{i}$ and $\\textbf{j}$ components of $\\textbf{v}(t)$ are equal:`,
        MCMaths.cleaner(`$${vt_i}+${c_i} = ${vt_j}+${c_j}$`),
        MCMaths.cleaner(`$${vt_j}-${vt_i}+${c_j - c_i} = 0$`),
        `Solving the quadratic will give us our two possible values for $T$:`,
        `$T = ${T_1.toPrecision(3)}s, \\text{ } T = ${T_2.toPrecision(3)}s$`,
        `Since $T$ is a quantity of time, $T \\geq 0$, so:`,
        `$T = ${T_2.toPrecision(3)}s$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(vt1_i, 0, `a) $\\text{ } \\textbf{v}_i =$`);
      group1.addInput(vt1_j, 0, `a) $\\text{ } \\textbf{v}_j =$`);

      group2.addInput(
        parseFloat(T_2.toPrecision(3)),
        0.01,
        `b) $\\text{ } T =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const vt_i = new MCMaths.Polynomial(
      [
        MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
        0,
        MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      ],
      `t`
    );
    const vt_j = new MCMaths.Trig(
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      `sin`,
      1,
      1,
      `t`
    );

    const t_0 = new MCMaths.Fraction(MCRandom.randomInt(1, 5) / 3);
    const t_1 = MCRandom.randomInt(6, 10);

    const rt_i = vt_i.integral();
    const rt_iCoefficients = rt_i.getCoefficients();
    const rt_j = vt_j.integral();
    const rt_jCoefficient = rt_j.evaluate(0);

    const working1 = t_0.numerator ** 3;
    const working2 = t_0.denominator ** 3;
    const working3 = new MCMaths.Fraction(
      (working1 * rt_iCoefficients[0]) / working2
    );

    const working4 = new MCMaths.Fraction(rt_iCoefficients[2] * t_0.toFloat());

    const rt0_i = `(${working3}\\pi^3+${working4}\\pi)`;
    const rt0_j = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const c_i = 0;
    const c_j = parseFloat(
      (rt0_j - rt_j.evaluate(t_0.toFloat() * Math.PI)).toFixed(1)
    );

    const rt1_i = rt_i.evaluate(t_1);
    const rt1_j = rt_j.evaluate(t_1) + c_j;

    const at_i = vt_i.derivative();
    const at_j = vt_j.derivative();

    const at1_i = at_i.evaluate(t_1);
    const at1_j = at_j.evaluate(t_1);

    const at1_magnitude = Math.sqrt(at1_i ** 2 + at1_j ** 2);

    /* END VARIABLES AND CONSTANTS */
    if (
      `${working3.numerator}`.length <= 2 &&
      `${working4.numerator}`.length <= 2 &&
      c_j !== 0 &&
      Math.abs(rt1_i) < 1000
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A particle $P$ is moving with velocity $\\textbf{v}$ where`,
        MCMaths.cleaner(
          `$\\textbf{v}(t) = (${vt_i})\\textbf{i}+${vt_j}\\textbf{j}, \\text{ } t \\geq 0$`
        ),
        MCMaths.cleaner(
          `$P$ has position vector $\\left[${rt0_i}\\textbf{i}+${rt0_j}\\textbf{j}\\right]m$ when $t=${t_0}\\pi$ with respect to a fixed origin $O$.`
        ),
        `a) Find the distance, $\\textbf{r}$, of $P$ from $O$ when $t=${t_1}$.`,
        `b) The magnitude of the acceleration, $|\\textbf{a}|$, of $P$ at time $t=${t_1}$.`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) To find $\\textbf{r}$, first you need to integrate $\\textbf{v}(t)$ with respect to $t$:`,
        MCMaths.cleaner(
          `$\\textbf{r}(t) = \\displaystyle \\int{(${vt_i})\\textbf{i}+${vt_j}\\textbf{j}}dt$`
        ),
        MCMaths.cleaner(
          `$\\textbf{r}(t) = (${rt_i})\\textbf{i}+${rt_j}\\textbf{j} + \\textbf{c}$`
        ),
        `When $t=${t_0}\\pi$:`,
        MCMaths.cleaner(
          `$${rt0_i}\\textbf{i}+${rt0_j}\\textbf{j} = ${rt0_i}\\textbf{i}+${rt_jCoefficient}\\cos(${t_0}\\pi)\\textbf{j} + \\textbf{c}$`
        ),
        MCMaths.cleaner(`$\\implies \\textbf{c} = ${c_j}\\textbf{j}$`),
        MCMaths.cleaner(
          `$\\textbf{r}(t) = (${rt_i})\\textbf{i}+(${rt_j}+${c_j})\\textbf{j}$`
        ),
        `When $t=${t_1}$:`,
        MCMaths.cleaner(
          `$\\textbf{r} = (${rt1_i.toPrecision(
            3
          )}\\textbf{i}+${rt1_j.toPrecision(3)}\\textbf{j})m$.`,
          false
        ),

        `HEADING b) To find $|\\textbf{a}|$, first you need to find the derivative of $\\textbf{v}(t)$:`,
        `$\\textbf{a}(t) = \\displaystyle \\frac{d\\textbf{v}(t)}{dt}$`,
        MCMaths.cleaner(`$= ${at_i}\\textbf{i}+${at_j}\\textbf{j}$`),
        `When $t=${t_1}$:`,
        MCMaths.cleaner(
          `$\\textbf{a} = ${at1_i.toPrecision(
            3
          )}\\textbf{i}+${at1_j.toPrecision(3)}\\textbf{j}$`,
          false
        ),
        `$|\\textbf{a}| = \\sqrt{(${at1_i.toPrecision(
          3
        )})^2+(${at1_j.toPrecision(3)})^2}$`,
        `$|\\textbf{a}| = ${at1_magnitude.toPrecision(3)}ms^{-2}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(rt1_i.toPrecision(3)),
        1,
        `a) $\\text{ } \\textbf{r}_i =$`
      );
      group1.addInput(
        parseFloat(rt1_j.toPrecision(3)),
        1,
        `a) $\\text{ } \\textbf{r}_j =$`
      );
      group2.addInput(
        parseFloat(at1_magnitude.toPrecision(3)),
        1,
        `b) $\\text{ } |\\textbf{a}| =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const p1_rt0_i = 0;
    const p1_rt0_j = MCRandom.randomInt(16, 19) / 10;

    const p2_rt0_i = MCRandom.randomInt(2, 9);
    const p2_rt0_j = MCRandom.randomInt(16, 19) / 10;

    const p1_iTerm = MCRandom.randomInt(2, 6);
    const p2_iTerm = MCRandom.randomInt(2, 6);

    const p1_rt_i = new MCMaths.Sqrt([p1_iTerm, 0], `t`);
    const p1_rt_j = new MCMaths.Polynomial(
      [-1, 0, 0, MCRandom.randomInt(20, 40) / 10, p1_rt0_j],
      `t`
    );

    const p2_rt_i = new MCMaths.Sqrt([p2_iTerm, 0], `t`);
    const p2_rt_j = new MCMaths.Polynomial(
      [-1, 0, 0, MCRandom.randomInt(20, 40) / 10, p2_rt0_j],
      `t`
    );

    const p1_vt_i = `${new MCMaths.Fraction(-p1_iTerm / 2)}t^{\\frac{-1}{2}}`;
    const p1_vt_j = p1_rt_j.derivative();

    const p2_vt_i = `${new MCMaths.Fraction(-p2_iTerm / 2)}t^{\\frac{-1}{2}}`;
    const p2_vt_j = p2_rt_j.derivative();

    const t1 = MCRandom.randomInt(15, 25) / 10;

    const p1_rt1_i = p1_rt_i.evaluate(t1);
    const p1_rt1_j = p1_rt_j.evaluate(t1);
    const p1_rt1_magnitude = Math.sqrt(p1_rt1_i ** 2 + p1_rt1_j ** 2);

    const p2_rt1_i = p2_rt_i.evaluate(t1) + p2_rt0_i;
    const p2_rt1_j = p2_rt_j.evaluate(t1);
    const p2_rt1_magnitude = Math.sqrt(p2_rt1_i ** 2 + p2_rt1_j ** 2);

    /* END VARIABLES AND CONSTANTS */
    if (
      p1_rt0_i < p2_rt0_i &&
      p2_rt1_j > 0 &&
      p1_rt1_j > 0 &&
      p1_rt_j.toString() !== p2_rt_j.toString()
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `In this question $\\textbf{i}$ and $\\textbf{j}$ represent the horizontal direction and vertical direction respectively.`,
        `Two projectiles $P_1$ and $P_2$ are launched at time $t=0$ from rest at the position vectors $${p1_rt0_j}\\textbf{j}m$ and $(${p2_rt0_i}\\textbf{i}+${p2_rt0_j}\\textbf{j})m$ respectively, where the position vectors are relative to a fixed origin $O$.`,
        `$P_1$ and $P_2$ move with velocities $\\textbf{v}_1(t)$ and $\\textbf{v}_2(t)$ where:`,
        MCMaths.cleaner(
          `$\\textbf{v}_1(t) = ${p1_vt_i}\\textbf{i}+\\left(${p1_vt_j}\\right)\\textbf{j}$`
        ),
        MCMaths.cleaner(
          `$\\textbf{v}_2(t) = ${p2_vt_i}\\textbf{i}+\\left(${p2_vt_j}\\right)\\textbf{j}$`
        ),
        `a) Find the distance between $O$ and $P_1$ when $t=${t1}$:`,
        `b) Find the distance between $O$ and $P_2$ when $t=${t1}$:`,
        `c) Do $P_1$ and $P_2$ collide or not? Explain your answer.`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) To find the distance between $O$ and $P_1$, first, find the expression for position of $P_1$ by integrating $\\textbf{v}_1(t)$ with respect to $t$:`,
        `$\\textbf{r}_1(t) = \\displaystyle \\int{${p1_vt_i}\\textbf{i}+\\left(${p1_vt_j}\\right)\\textbf{j}}dt$`,
        MCMaths.cleaner(
          `$\\textbf{r}_1(t) = ${p1_rt_i}\\textbf{i}+(${p1_rt_j
            .toString()
            .slice(0, 21)})\\textbf{j} + \\textbf{c}_1$`
        ),
        `When $t=0$:`,
        `$\\implies \\textbf{c}_1 = ${p1_rt0_j}\\textbf{j}$`,
        MCMaths.cleaner(
          `So $\\textbf{r}_1(t) = ${p1_rt_i}\\textbf{i}+(${p1_rt_j})\\textbf{j}$`
        ),
        `When $t=${t1}$:`,
        MCMaths.cleaner(
          `$\\textbf{r}_1 = ${p1_rt1_i.toPrecision(
            3
          )}\\textbf{i}+${p1_rt1_j.toPrecision(3)}\\textbf{j}$`,
          false
        ),
        `$ |\\textbf{r}_1| = \\sqrt{(${p1_rt1_i.toPrecision(
          3
        )})^2+(${p1_rt1_j.toPrecision(3)})^2}$`,
        `$= ${p1_rt1_magnitude.toPrecision(3)}m$.`,

        `HEADING b) To find the distance between $O$ and $P_2$, first, find the expression for position of $P_2$ by integrating $\\textbf{v}_2(t)$ with respect to $t$:`,
        `$\\textbf{r}_2(t) = \\displaystyle \\int{${p2_vt_i}\\textbf{i}+\\left(${p2_vt_j}\\right)\\textbf{j}}dt$`,
        MCMaths.cleaner(
          `$\\textbf{r}_2(t) = ${p2_rt_i}\\textbf{i}+(${p2_rt_j
            .toString()
            .slice(0, 21)})\\textbf{j} + \\textbf{c}_2$`
        ),
        `When $t=0$:`,
        `$\\implies \\textbf{c}_2 = ${p2_rt0_i}\\textbf{i}+${p2_rt0_j}\\textbf{j}$`,
        MCMaths.cleaner(
          `So $\\textbf{r}_2(t) = (${p2_rt_i}+${p2_rt0_i})\\textbf{i}+(${p2_rt_j})\\textbf{j}$`
        ),
        `When $t=${t1}$:`,
        MCMaths.cleaner(
          `$\\textbf{r}_2 = ${p2_rt1_i.toPrecision(
            3
          )}\\textbf{i}+${p2_rt1_j.toPrecision(3)}\\textbf{j}$`,
          false
        ),
        `$ |\\textbf{r}_2| = \\sqrt{(${p2_rt1_i.toPrecision(
          3
        )})^2+(${p2_rt1_j.toPrecision(3)})^2}$`,
        `$= ${p2_rt1_magnitude.toPrecision(3)}m$.`,

        `HEADING c) $P_1$ and $P_2$ do not collide.`,
        `They do not collide because when their $\\textbf{i}$ components are equal, their $\\textbf{j}$ components are not.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(parseFloat(p1_rt1_magnitude.toPrecision(3)), 0.1, `a)`);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(parseFloat(p2_rt1_magnitude.toPrecision(3)), 0.1, `b)`);
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const rt_i = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0, 0, 0],
      `t`
    );
    const rt_j = new MCMaths.Exponential(
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      "e",
      MCRandom.randomInt(-1, 1, 2),
      `t`,
      0
    );

    const t1 = MCRandom.randomInt(2, 9);

    const vt_i = rt_i.derivative();
    const vt_j = rt_j.derivative();

    const vt1_i = vt_i.evaluate(t1);
    const vt1_j = vt_j.evaluate(t1);

    const at_i = vt_i.derivative();
    const at_j = vt_j.derivative();

    const at1_i = at_i.evaluate(t1);
    const at1_j = at_j.evaluate(t1);
    const at1_magnitude = Math.sqrt(at1_i ** 2 + at1_j ** 2);

    /* END VARIABLES AND CONSTANTS */
    if (vt1_j > 0.2 && vt1_j < 1000) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `At time $t$ seconds, a particle $P$ moves such that its position, $\\textbf{r}m$, relative to a fixed origin $O$, is given by:`,
        MCMaths.cleaner(
          `$\\textbf{r}(t) = ${rt_i}\\textbf{i}+${rt_j}\\textbf{j}, \\text{ } t \\geq 0$`
        ),
        `a) Find the velocity, $\\textbf{v}$, at time $t=${t1}$.`,
        `b) Find the magnitude of the acceleration, $|\\textbf{a}|$ at time $t=${t1}$.`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) In order to find $\\textbf{v}$, find the derivative of $\\textbf{r}(t)$ with respect to $t$:`,
        `$\\textbf{v}(t) = \\displaystyle \\frac{d}{dt}\\textbf{r}(t)$`,
        MCMaths.cleaner(`$= ${vt_i}\\textbf{i}+${vt_j}\\textbf{j}$`),
        `At time $t=${t1}$:`,
        MCMaths.cleaner(
          `$\\textbf{v} = ${vt1_i}\\textbf{i}+${vt1_j.toPrecision(
            3
          )}\\textbf{j}$.`,
          false
        ),

        `HEADING b) In order to find $\\textbf{a}$, find the derivative of $\\textbf{v}(t)$ with respect to $t$:`,
        `$\\textbf{a}(t) = \\displaystyle \\frac{d}{dt}\\textbf{v}(t)$`,
        MCMaths.cleaner(`$= ${at_i}\\textbf{i}+${at_j}\\textbf{j}$`),
        `At time $t=${t1}$:`,
        MCMaths.cleaner(
          `$\\textbf{a} = ${at1_i}\\textbf{i}+${at1_j.toPrecision(
            3
          )}\\textbf{j}$`,
          false
        ),
        MCMaths.cleaner(
          `$|\\textbf{a}| = \\sqrt{(${at1_i})^{2}+(${at1_j.toPrecision(
            3
          )})^2}$`,
          false
        ),
        MCMaths.cleaner(
          `$|\\textbf{a}| = ${at1_magnitude.toPrecision(3)}ms^{-2}.$`,
          false
        ),
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(vt1_i, 0, `a) $\\text{ } \\textbf{v}_{\\textbf{i}} =$`);
      group1.addInput(
        vt1_j,
        Math.abs(
          parseFloat(vt1_j.toPrecision(3)) - parseFloat(vt1_j.toPrecision(1))
        ),
        `a) $\\text{ } \\textbf{v}_{\\textbf{j}} =$`
      );

      group2.addInput(
        at1_magnitude,
        Math.abs(
          parseFloat(at1_magnitude.toPrecision(3)) -
            parseFloat(at1_magnitude.toPrecision(1))
        ),
        `b) $\\text{ } |\\textbf{a}| =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const rt_i = new MCMaths.Polynomial(
      [
        MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
        0,
        MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      ],
      `t`
    );
    const rt_j = new MCMaths.Polynomial(
      [
        MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
        0,
        MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
        0,
        MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
        0,
      ],
      `t`
    );

    const t1 = MCRandom.randomInt(10, 50) / 10;

    const vt_i = rt_i.derivative();
    const vt_j = rt_j.derivative();

    const vt1_i = vt_i.evaluate(t1);
    const vt1_j = vt_j.evaluate(t1);

    const at_i = vt_i.derivative();
    const at_j = vt_j.derivative();

    const at1_i = at_i.evaluate(t1);
    const at1_j = at_j.evaluate(t1);
    const at1_magnitude = Math.sqrt(at1_i ** 2 + at1_j ** 2);

    /* END VARIABLES AND CONSTANTS */
    if (vt1_j > 0.2 && vt1_j < 1000 && at1_magnitude < 500) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `At time $t$ seconds, a particle $P$ moves such that its position, $\\textbf{r}m$, relative to a fixed origin $O$, is given by:`,
        MCMaths.cleaner(
          `$\\textbf{r}(t) = (${rt_i})\\textbf{i}+(${rt_j})\\textbf{j}, \\text{ } t \\geq 0$`
        ),
        `a) Find the velocity, $\\textbf{v}$, at time $t=${t1}$.`,
        `b) Find the magnitude of the acceleration, $|\\textbf{a}|$ at time $t=${t1}$.`,
        `Give your answers to $3$ significant figures where appropriate.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) In order to find $\\textbf{v}$, find the derivative of $\\textbf{r}(t)$ with respect to $t$:`,
        `$\\textbf{v}(t) = \\displaystyle \\frac{d}{dt}\\textbf{r}(t)$`,
        MCMaths.cleaner(`$= ${vt_i}\\textbf{i}+(${vt_j})\\textbf{j}$`),
        `At time $t=${t1}$:`,
        MCMaths.cleaner(
          `$\\textbf{v} = ${vt1_i.toPrecision(
            3
          )}\\textbf{i}+${vt1_j.toPrecision(3)}\\textbf{j}$.`,
          false
        ),

        `HEADING b) In order to find $\\textbf{a}$, find the derivative of $\\textbf{v}(t)$ with respect to $t$:`,
        `$\\textbf{a}(t) = \\displaystyle \\frac{d}{dt}\\textbf{v}(t)$`,
        MCMaths.cleaner(`$= ${at_i}\\textbf{i}+(${at_j})\\textbf{j}$`),
        `At time $t=${t1}$:`,
        MCMaths.cleaner(
          `$\\textbf{a} = ${at1_i}\\textbf{i}+${at1_j.toPrecision(
            3
          )}\\textbf{j}$`,
          false
        ),
        MCMaths.cleaner(
          `$|\\textbf{a}| = \\sqrt{(${at1_i})^{2}+(${at1_j.toPrecision(
            3
          )})^2}$`,
          false
        ),
        MCMaths.cleaner(
          `$|\\textbf{a}| = ${at1_magnitude.toPrecision(3)}ms^{-2}.$`,
          false
        ),
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(
        vt1_i,
        Math.abs(
          parseFloat(vt1_i.toPrecision(3)) - parseFloat(vt1_i.toPrecision(1))
        ),
        `a) $\\text{ } \\textbf{v}_{\\textbf{i}} =$`
      );
      group1.addInput(
        vt1_j,
        Math.abs(
          parseFloat(vt1_j.toPrecision(3)) - parseFloat(vt1_j.toPrecision(1))
        ),
        `a) $\\text{ } \\textbf{v}_{\\textbf{j}} =$`
      );

      group2.addInput(
        at1_magnitude,
        Math.abs(
          parseFloat(at1_magnitude.toPrecision(3)) -
            parseFloat(at1_magnitude.toPrecision(1))
        ),
        `b) $\\text{ } |\\textbf{a}| =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const rt_i = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0],
      `t`
    );
    const rt_j = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0, 0],
      `t`
    );

    const vt_i = rt_i.derivative();
    const vt_j = rt_j.derivative();

    const t0 = MCRandom.randomInt(2, 5);

    const vt0_i = vt_i.evaluate(t0);
    const vt0_j = vt_j.evaluate(t0);

    const vt0_magnitudeSquared = vt0_i ** 2 + vt0_j ** 2;

    const wrongAnswer1 = Math.abs(vt0_i * 2 + vt0_j * 2);
    const wrongAnswer2 = Math.abs(vt0_i + vt0_j);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addMultipleParagraphs([
        `The position $\\textbf{r}(t)$, relative to a fixed origin $O$, is given by the following by the following equation:`,
        MCMaths.cleaner(
          `$\\textbf{r}(t) = ${rt_i}\\textbf{i}+${rt_j}\\textbf{j}$`
        ),
        `Choose the correct speed, $|\\textbf{v}|$ at time $t=${t0}$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$|\\textbf{v}| = \\sqrt{${vt0_magnitudeSquared}}ms^{-1}$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$|\\textbf{v}| = ${vt0_magnitudeSquared}ms^{-1}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$|\\textbf{v}| = \\sqrt{${wrongAnswer1}}ms^{-1}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$|\\textbf{v}| = ${wrongAnswer2}ms^{-1}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const vt_i = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0, 0],
      `t`
    );
    const vt_j = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0],
      `t`
    );

    const at_i = vt_i.derivative();
    const at_j = vt_j.derivative();

    const t0 = MCRandom.randomInt(2, 5);

    const at0_i = vt_i.evaluate(t0);
    const at0_j = vt_j.evaluate(t0);

    const at0_magnitudeSquared = at0_i ** 2 + at0_j ** 2;

    const wrongAnswer1 = Math.abs(at0_i * 2 + at0_j * 2);
    const wrongAnswer2 = Math.abs(at0_i + at0_j);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addMultipleParagraphs([
        `The velocity $\\textbf{v}(t)$ is given by the following by the following equation:`,
        MCMaths.cleaner(
          `$\\textbf{v}(t) = ${vt_i}\\textbf{i}+${vt_j}\\textbf{j}$`
        ),
        `Choose the correct magnitude of the acceleration, $|\\textbf{a}|$ at time $t=${t0}$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$|\\textbf{a}| = \\sqrt{${at0_magnitudeSquared}}ms^{-2}$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$|\\textbf{a}| = ${at0_magnitudeSquared}ms^{-2}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$|\\textbf{a}| = \\sqrt{${wrongAnswer1}}ms^{-2}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$|\\textbf{a}| = ${wrongAnswer2}ms^{-2}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const vt_i = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0, 0],
      `t`
    );
    const vt_j = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0],
      `t`
    );

    const at_i = vt_i.derivative();
    const at_j = vt_j.derivative();

    const t0 = MCRandom.randomInt(2, 5);

    const vt0_i = vt_i.evaluate(t0);
    const vt0_j = vt_j.evaluate(t0);

    const vt0_magnitudeSquared = vt0_i ** 2 + vt0_j ** 2;

    const wrongAnswer1 = Math.abs(vt0_i * 2 + vt0_j * 2);
    const wrongAnswer2 = Math.abs(vt0_i + vt0_j);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addMultipleParagraphs([
        `The velocity $\\textbf{v}(t)$ is given by the following by the following equation:`,
        MCMaths.cleaner(
          `$\\textbf{a}(t) = ${at_i}\\textbf{i}+${at_j}\\textbf{j}$`
        ),
        `Given that $\\textbf{v}(0) = \\textbf{0}$`,
        `Choose the correct speed, $|\\textbf{v}|$ at time $t=${t0}$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$|\\textbf{v}| = \\sqrt{${vt0_magnitudeSquared}}ms^{-1}$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$|\\textbf{v}| = ${vt0_magnitudeSquared}ms^{-1}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$|\\textbf{v}| = \\sqrt{${wrongAnswer1}{ms^{-1}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$|\\textbf{v}| = ${wrongAnswer2}ms^{-1}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const rt_i = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0],
      `t`
    );
    const rt_j = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0, 0],
      `t`
    );

    const vt_i = rt_i.derivative();
    const vt_j = rt_j.derivative();

    const t0 = MCRandom.randomInt(2, 5);

    const rt0_i = rt_i.evaluate(t0);
    const rt0_j = rt_j.evaluate(t0);

    const rt0_magnitudeSquared = rt0_i ** 2 + rt0_j ** 2;

    const wrongAnswer1 = Math.abs(rt0_i * 2 + rt0_j * 2);
    const wrongAnswer2 = Math.abs(rt0_i + rt0_j);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addMultipleParagraphs([
        `The velocity $\\textbf{v}(t)$, relative to a fixed origin $O$, is given by the following by the following equation:`,
        MCMaths.cleaner(
          `$\\textbf{v}(t) = ${vt_i}\\textbf{i}+${vt_j}\\textbf{j}$`
        ),
        `Choose the distance, $|\\textbf{r}|$, relative to the fixed origin $O$, at time $t=${t0}$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$|\\textbf{r}| = \\sqrt{${rt0_magnitudeSquared}}m$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$|\\textbf{r}| = ${rt0_magnitudeSquared}m$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$|\\textbf{r}| = \\sqrt{${wrongAnswer1}}m$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$|\\textbf{r}| = ${wrongAnswer2}m$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const rt_i = new MCMaths.Polynomial(
      [
        MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
        0,
        MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
        0,
      ],
      `t`
    );
    const rt_j = new MCMaths.Polynomial(
      [MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2), 0, 0],
      `t`
    );

    const vt_i = rt_i.derivative();
    const vt_j = rt_j.derivative();

    const t0 = MCRandom.randomInt(2, 5);

    const vt0_i = vt_i.evaluate(t0);
    const vt0_j = vt_j.evaluate(t0);

    const wrongAnswer1_i = rt_i.evaluate(t0);
    const wrongAnswer1_j = rt_j.evaluate(t0);

    const wrongAnswer2_i = 3 * rt_i.evaluate(t0);
    const wrongAnswer2_j = 2 * rt_j.evaluate(t0);

    const wrongAnswer3_i = rt_i.evaluate(t0 + 1);
    const wrongAnswer3_j = rt_j.evaluate(t0 + 1);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addMultipleParagraphs([
        `The position $\\textbf{r}(t)$, relative to a fixed origin $O$, is given by the following by the following equation:`,
        MCMaths.cleaner(
          `$\\textbf{r}(t) = (${rt_i})\\textbf{i}+${rt_j}\\textbf{j}$`
        ),
        `Choose the correct expression for the velocity, $\\textbf{v}(t)$ at time $t=${t0}$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        MCMaths.cleaner(
          `$\\textbf{v}(t) = ${vt0_i}\\textbf{i}+${vt0_j}\\textbf{j}$`
        )
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        MCMaths.cleaner(
          `$\\textbf{v}(t) = ${wrongAnswer1_i}\\textbf{i}+${wrongAnswer1_j}\\textbf{j}$`
        )
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        MCMaths.cleaner(
          `$\\textbf{v}(t) = ${wrongAnswer2_i}\\textbf{i}+${wrongAnswer2_j}\\textbf{j}$`
        )
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        MCMaths.cleaner(
          `$\\textbf{v}(t) = ${wrongAnswer3_i}\\textbf{i}+${wrongAnswer3_j}\\textbf{j}$`
        )
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_FurtherKinematics_VectorCalculus_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const vt_i = new MCMaths.Polynomial(
      [
        MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
        0,
        MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      ],
      `t`
    );
    const vt_j = new MCMaths.Polynomial(
      [
        MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
        0,
        MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
        0,
      ],
      `t`
    );

    const at_i = vt_i.derivative();
    const at_j = vt_j.derivative();

    const t0 = MCRandom.randomInt(2, 5);

    const at0_i = at_i.evaluate(t0);
    const at0_j = at_j.evaluate(t0);

    const wrongAnswer1_i = vt_i.evaluate(t0);
    const wrongAnswer1_j = vt_j.evaluate(t0);

    const wrongAnswer2_i = 2 * vt_i.evaluate(t0) - 7;
    const wrongAnswer2_j = 3 * vt_j.evaluate(t0);

    const wrongAnswer3_i = vt_i.evaluate(t0 + 1);
    const wrongAnswer3_j = vt_j.evaluate(t0 + 1);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addMultipleParagraphs([
        `The velocity $\\textbf{v}(t)$ is given by the following by the following equation:`,
        MCMaths.cleaner(
          `$\\textbf{v}(t) = (${vt_i})\\textbf{i}+(${vt_j})\\textbf{j}$`
        ),
        `Choose the correct expression for the velocity, $\\textbf{v}(t)$ at time $t=${t0}$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        MCMaths.cleaner(
          `$\\textbf{a}(t) = ${at0_i}\\textbf{i}+${at0_j}\\textbf{j}$`
        )
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        MCMaths.cleaner(
          `$\\textbf{v}(t) = ${wrongAnswer1_i}\\textbf{i}+${wrongAnswer1_j}\\textbf{j}$`
        )
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        MCMaths.cleaner(
          `$\\textbf{v}(t) = ${wrongAnswer2_i}\\textbf{i}+${wrongAnswer2_j}\\textbf{j}$`
        )
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        MCMaths.cleaner(
          `$\\textbf{v}(t) = ${wrongAnswer3_i}\\textbf{i}+${wrongAnswer3_j}\\textbf{j}$`
        )
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_DifferentialEquations_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const tankH = MCRandom.randomInt(100, 200);
    const intial = MCRandom.randomInt(1, 6);
    const crackH = MCRandom.randomInt(10, 30);
    const cH = MCRandom.randomInt(crackH + 10, tankH - 10);
    const p = -intial / Math.sqrt(tankH - crackH);
    const c = -(2 / p) * Math.sqrt(tankH - crackH);
    const tC = (2 / p) * Math.sqrt(cH - crackH) + c;
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(p * 100) === p * 100 &&
      p !== 1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A man purchases a cylindrical water tank, $${
          tankH / 100
        }m$ tall. After the tank is installed and filled, he notices a small crack near the base of the tank, which water is flowing out of.`,
        `When the tank is filled to the brim, it leaks at an intial rate of $${intial}cm$ per minute.`,
        `The water level $h$ in the tank satisfies the differential equation $\\frac{dh}{dt}=p\\sqrt{h-${crackH}}$.`,
        `a) Find $p$.`,
        `b) The flow stops when the water reaches the level of the crack. How high is the crack up the side of the tank, in $cm$?`,
        `c) After first filling the tank, when $h=${cH}cm$, $t=q$. Find $q$, to the nearest minute.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING $${-intial}=p\\sqrt{${tankH}-${crackH}}$`,
        `$p = ${p}$`,
        `b)HEADING $\\frac{dh}{dt}=0$`,
        `$${p}\\sqrt{h-${crackH}}=0$`,
        `$h=${crackH}$`,
        `c)HEADING Integrating:`,
        MCMaths.cleaner(
          `$\\displaystyle \\int 1 dt = ${
            1 / p
          }\\int \\left(h-${crackH}\\right)^{-\\frac{1}{2}}dh$`
        ),
        MCMaths.cleaner(
          `$t = ${2 / p}\\left(h-${crackH}\\right)^{\\frac{1}{2}}+c$`
        ),
        `HEADING Finding $c$ using $t=0$, $h=${tankH}$:`,
        MCMaths.cleaner(
          `$0 = ${2 / p}\\left(${tankH}-${crackH}\\right)^{\\frac{1}{2}}+c$`
        ),
        `$${MCMaths.cleaner(`c=${c}`)}$`,
        `HEADING Finding $t$:`,
        MCMaths.cleaner(
          `$t=${2 / p}\\left(${cH}-${crackH}\\right)^{\\frac{1}{2}}+${c}$`
        ),
        `$t\\approx${Math.round(tC * 100) / 100}$`,
        `$t=${Math.round(tC)}$ to the nearest minute.`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(p, 0, "a) p=");
      group.addInput(crackH, 0, "b) h=");
      group.addInput(Math.round(tC), 0.01, "c) q=");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_DifferentialEquations_Exam_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const yA = 1;
    const yB = MCRandom.randomInt(2, 7);
    const px = MCRandom.randomInt(2, 12, 2);
    const pdy = MCRandom.randomInt(2, 7);
    const py = (((pdy * px) / 2) ** 2 + yB) / yA;

    const working1 = yA * py - yB;
    const c = 2 + 2 * Math.sqrt(working1);

    const p = ((c / 2) ** 2 + yB) / yA;
    const q = (c * px) / yA;
    const r = px ** 2 / yA;
    /* END VARIABLES AND CONSTANTS */
    if (
      yA * py > yB &&
      Math.round(Math.sqrt(working1) * px * 2) ===
        Math.sqrt(working1) * px * 2 &&
      yA !== yB &&
      Math.round(p) === p &&
      Math.round(q) === q &&
      Math.round(r) === r &&
      p < 50 &&
      q < 50 &&
      r < 50 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Solve the differential equation $\\displaystyle \\frac{dy}{dx} = \\frac{${
          2 * px
        }\\sqrt{y-${yB}}}{x^2}$, given that $\\frac{dy}{dx} = ${pdy}$ when $x=${px}$.`,
        `Write your answer in the form $\\displaystyle y=p-\\frac{q}{x}+\\frac{r}{x^2}$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Rearranging:`,
        `$\\displaystyle \\frac{dy}{\\sqrt{y-${yB}}} = \\frac{${
          2 * px
        }dx}{x^2} $`,
        `HEADING Integrating:`,
        `$\\displaystyle \\int\\frac{dy}{\\sqrt{y-${yB}}} = \\int\\frac{${
          2 * px
        }dx}{x^2} $`,
        `$\\displaystyle 2\\sqrt{y-${yB}} = c-\\frac{${2 * px}}{x}$`,
        `HEADING Finding $y$:`,
        `$\\displaystyle ${pdy} = \\frac{${2 * px}\\sqrt{y-${yB}}}{${px}^2}$`,
        `$ ${(pdy * px) / 2}^2 + ${yB} = y$`,
        MCMaths.cleaner(`$ y = ${py}$`),
        `HEADING Finding $c$:`,
        MCMaths.cleaner(
          `$\\displaystyle 2\\sqrt{${yA}\\left(${py}\\right)-${yB}} = c-\\frac{${
            2 * px
          }}{${px}}$`
        ),
        `$c= ${c}$`,
        `HEADING Rearranging:`,
        `$\\displaystyle 2\\sqrt{y-${yB}} = ${c}-\\frac{${2 * px}}{x}$`,
        `$\\displaystyle y-${yB} = \\left(${c / 2}-\\frac{${px}}{x}\\right)^2$`,
        `$\\displaystyle y-${yB} = ${(c / 2) ** 2}- \\frac{${
          c * px
        }}{x} +\\frac{${px ** 2}}{x^2}$`,
        `$\\displaystyle y = ${p} - \\frac{${q}}{x} + \\frac{${r}}{x^2} $`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(p, 0, "p=");
      group.addInput(q, 0, "q=");
      group.addInput(r, 0, "r=");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_DifferentialEquations_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const k = MCRandom.randomInt(11, 44) / 10;
    const c1 = MCRandom.randomInt(100, 400, 100);
    const c2 = MCRandom.randomInt(2, 4);
    const timeA = MCRandom.randomInt(2, 5);
    const ansA = Math.round((c1 * Math.exp(timeA * k)) / 1000) * 1000;

    const workingB = Math.log(c2) / k;
    const ansB = Math.round(60 * workingB);

    const c2Terms = ["not used", "not used", "double", "triple", "quadruple"];
    /* END VARIABLES AND CONSTANTS */
    if (
      ansA < 100000 &&
      ansA > 2000 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `The population of a strain of bacteria can be modeled with the equation $\\frac{dn}{dt}=kn$, $k>0$, for population size $n$, and time $t$ hours.`,
        MCMaths.cleaner(
          `a) Setting $k=${k}$, for an inital population of $${c1}$, find the population size after $${timeA}$ hours, to the nearest thousand.`
        ),
        `b) Find the time taken for the population to ${c2Terms[c2]} in size, to the nearest minute.`,
      ]);
      question.addMultipleParagraphs("solution", [
        MCMaths.cleaner(`a)HEADING $\\frac{dn}{dt}=${k}n$`),
        MCMaths.cleaner(`$\\displaystyle t = ${1 / k}\\int n^{-1}dn$`),
        MCMaths.cleaner(`$t = ${1 / k}ln(n)+c$`),
        MCMaths.cleaner(`HEADING Inital Conditions:`),
        MCMaths.cleaner(`$0=${1 / k}ln(${c1})+c$`),
        MCMaths.cleaner(`$c=${-1 / k}ln(${c1})$`),
        MCMaths.cleaner(`$t=${1 / k}\\big(ln(n)-ln(${c1})\\big)$`),
        MCMaths.cleaner(`$${k}t=ln\\left(\\frac{n}{${c1}}\\right)$`),
        MCMaths.cleaner(`$\\frac{n}{${c1}}=e^{${k}t}$`),
        MCMaths.cleaner(`$n=${c1}e^{${k}t}$`),
        MCMaths.cleaner(`Substituting $t=${timeA}$`),
        MCMaths.cleaner(`$n=${c1}e^{${k}\\cdot ${timeA}}$`),
        MCMaths.cleaner(`$n\\approx ${ansA}$`),
        MCMaths.cleaner(`b)HEADING Finding $t$ for $n=${c2 * c1}$:`),
        MCMaths.cleaner(`$${c2 * c1} = ${c1}e^{${k}t}$`),
        MCMaths.cleaner(`$${c2} = e^{${k}t}$`),
        MCMaths.cleaner(`$ln(${c2}) = ${k}t$`),
        MCMaths.cleaner(`$t = ${1 / k}ln(${c2})$`),
        `$t\\approx ${Math.round(workingB * 100) / 100}$ hours`,
        `$\\approx ${ansB}$ minutes.`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ansA, 0, "a)");
      group.addInput(ansB, 0, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_DifferentialEquations_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = -MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(2, 9);
    const dL = new MCMaths.Polynomial([a, b, 0], "L");
    const tA = MCRandom.randomInt(1, 5);
    const l0 = MCRandom.randomInt(1, 5) / 10;

    const cLn = l0 / (b + a * l0);

    const ansB = -a / b;

    const gcd = function gcd123(a1, b1) {
      if (!b1) {
        return a1;
      }
      return gcd(b1, a1 % b1);
    };

    const g = gcd(-a, b);

    const ansA =
      (Math.round(b / g) * Math.exp((b * tA) / 7)) /
      (Math.round(-a / g) * Math.exp(((b / g) * tA) / 7) + 1 / cLn);

    /* END VARIABLES AND CONSTANTS */
    if (
      a !== -b &&
      g === 1 &&
      l0 < ansB &&
      Math.round(1 / cLn) === 1 / cLn &&
      1 / cLn > 1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `The length of a species of insect as it grows can be modeled with the differential equation $\\frac{dL}{dt} = ${b}L${a}L^2$, for length $L mm$, and time $t$ in weeks.`,
        `At $t=0$, $L=${l0}mm$`,
        `By first finding an equation for $L$ in terms of $t$, find the size of the insect at $t=${tA}$ days, in $mm$ to 2dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Rearanging:`,
        `$\\displaystyle dt = \\frac{dL}{L(${b}${a}L)}$`,
        `HEADING Partial Fractions:`,
        `$\\displaystyle = dL\\left(\\frac{a}{L}+\\frac{b}{${b}${a}L}\\right)$`,
        `$1 = a(${b}${a}L)+bL$`,
        `HEADING Setting $L=0$`,
        `$1 = ${b}a$`,
        MCMaths.cleaner(`$a = ${1 / b}$`),
        MCMaths.cleaner(`HEADING Setting $L=${-b / a}$`),
        MCMaths.cleaner(`$1 = ${-b / a}b$`),
        MCMaths.cleaner(`$b = ${-a / b}$`),
        `HEADING Integrating:`,
        `$\\displaystyle \\int t dt = \\int \\frac{1}{${b}L} + \\frac{${-a}}{${b}(${b}${a}L)} dL$`,
        MCMaths.cleaner(
          `$\\displaystyle t = ${1 / b}\\ln(L) - ${1 / b}\\ln(${b}${a}L) + c $`
        ),
        `$\\displaystyle t=\\frac{1}{${b}}\\ln\\left(\\frac{L}{${b}${a}L}\\right) + c$`,
        `HEADING Finding $c$:`,
        `$\\displaystyle 0=\\frac{1}{${b}}\\ln\\left(\\frac{${l0}}{${b}${a}\\cdot${l0}}\\right) + c$`,
        MCMaths.cleaner(
          `$\\displaystyle c = -\\frac{1}{${b}}\\ln\\left(${cLn}\\right)$`
        ),
        `$\\displaystyle t=\\frac{1}{${b}}\\ln\\left(\\frac{${
          1 / cLn
        }L}{${b}${a}L}\\right)$`,
        `HEADING Rearanging:`,
        `$\\displaystyle \\frac{${1 / cLn}L}{${b}${a}L} = e^{${b}t} $`,
        `$\\displaystyle ${Math.round(b)}e^{${b}t} = \\left(${Math.round(
          -a
        )}e^{${b}t}+${1 / cLn}\\right)L$`,
        `$\\displaystyle L = \\frac{${Math.round(b / g)}e^{${b}t}}{${Math.round(
          -a / g
        )}e^{${b / g}t}+${1 / cLn}}$`,
        `HEADING Setting $t=\\frac{${tA}}{7}$ weeks:`,
        MCMaths.cleaner(
          `$\\displaystyle L = \\frac{${Math.round(b / g)}e^{${b}\\cdot${
            tA / 7
          }}}{${Math.round(-a / g)}e^{${b / g}\\cdot${tA / 7}}+${1 / cLn}}$`
        ),
        `$L \\approx ${Math.round(ansA * 100) / 100}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(Math.round(ansA * 100) / 100, 0);
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_DifferentialEquations_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // (ax+b)/x
    const b1 = MCRandom.randomInt(2, 9);
    const c1 = MCRandom.randomInt(2, 9);
    const p1 = new MCMaths.Polynomial([1, b1, c1]);
    const b2 = MCRandom.randomInt(2, 9);
    const p2 = new MCMaths.Polynomial([1, b2], "y");
    const x1 = MCRandom.randomInt(1, 9);
    const y1 = 1 - b2;
    const c = -p1.integral().evaluate(x1);
    const x2 = x1 + MCRandom.randomInt(-9, 9) / 10;

    const xWorking = p1.integral().evaluate(x2) + c;

    const ans = Math.round(100 * (Math.exp(xWorking) - b2)) / 100;

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(c * 300) === c * 300 &&
      Math.round(xWorking * 300) === xWorking * 300 &&
      Math.abs(xWorking) < 5 &&
      x1 !== x2 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given the differential equation $\\displaystyle \\frac{dy}{dx} = x^2y + ${b2}x^2 + ${
          b1 * b2
        }x + ${c1}y + ${b1}xy + ${
          b2 * c1
        } $, and $y=${y1}$ when $x=${x1}$, find $y$ when $x=${x2}$, to 2dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Factorising:`,
        `$\\displaystyle \\frac{dy}{dx} = (${p1.toString()})(${p2.toString()}) $`,
        `HEADING Rearranging:`,
        `$\\displaystyle \\frac{dy}{${p2.toString()}} = (${p1.toString()})dx$`,
        `HEADING Integrating:`,
        `$\\displaystyle \\int \\frac{dy}{${p2.toString()}} = \\int ${p1.toString()} dx$`,
        `$\\displaystyle \\ln(${p2.toString()}) = ${p1
          .integral()
          .toString()}+c$`,
        `HEADING Finding $c$:`,
        MCMaths.cleaner(
          `$\\displaystyle \\ln(${y1}+${b2}) = ${p1
            .integral()
            .toString()
            .replace(/x/g, `(${x1})`)}+c$`
        ),
        MCMaths.cleaner(`$\\displaystyle 0 = ${p1.integral().evaluate(x1)}+c$`),
        MCMaths.cleaner(`$c=${c}$`),
        `HEADING Setting $x=${x2}$:`,
        MCMaths.cleaner(
          `$\\displaystyle \\ln(y+${b2}) = ${p1
            .integral()
            .toString()
            .replace(/x/g, `\\left(${x2}\\right)`)}+${c}$`
        ),
        MCMaths.cleaner(`$\\displaystyle \\ln(y+${b2}) = ${xWorking}$`),
        MCMaths.cleaner(`$\\displaystyle y=e^{${xWorking}}-${b2}$`),
        `$y\\approx ${ans}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans, 0.1);
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_DifferentialEquations_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const room = MCRandom.randomInt(15, 25);
    const initial = MCRandom.randomInt(65, 90);

    const t1 = MCRandom.randomInt(5, 10);
    const t2 = t1 + MCRandom.randomInt(5, 10);

    const T1 = initial - MCRandom.randomInt(20, 30);
    const T3 = room + MCRandom.randomInt(5, 15);

    const cLn = initial - room;
    const kLn = cLn / (T1 - room);

    const T2 = Math.round(room + cLn * Math.exp((-Math.log(kLn) * t2) / t1));

    // dT/dt = -k(T-25)
    // t = \\int 1/(-k(T-25)) dT
    // -kt = ln(T-25)+c
    // T = 25+e^{c-kt}

    // Finding c ->    -k t1 = ln(T1-25)+c
    // c = -

    const t3 = Math.round(
      Math.log(cLn / (T3 - room)) / ((1 / t1) * Math.log(kLn))
    );

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A cup of coffee cools at a rate proportional to the difference in temperate of the cup and the room.`,
        `The room is at $${room}^\\circ c$, and at time $t=0$, the coffee is $${initial}^\\circ c$.`,
        `After $${t1}$ minutes, the coffee is $${T1}^\\circ c$.`,
        `a) By forming and solving a differential equation, find the temperature of the coffee after $${t2}$ minutes,to the nearest degree.`,
        `b) Find the time it takes the coffee to cool to $${T3}^\\circ c$, to the nearest minute.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Forming The Equaton:`,
        `$\\displaystyle \\frac{dT}{dt} = -k(T-${room})$`,
        `HEADING Solving:`,
        `$\\displaystyle -kdt = \\frac{dT}{T-${room}} $`,
        `$\\displaystyle \\int -k dt = \\int \\frac{dT}{T-${room}} $`,
        `$-kt = \\ln(T-${room})+c$`,
        `HEADING Finding $c$:`,
        `$-k(0) = \\ln(${initial}-${room})+c$`,
        `$c = -\\ln(${cLn})$`,
        `$-kt = \\ln(T-${room}) - \\ln(${cLn})$`,
        `$\\displaystyle kt = \\ln\\left(\\frac{${cLn}}{T-${room}}\\right)$`,
        `HEADING Finding $k$:`,
        `$\\displaystyle ${t1}k = \\ln\\left(\\frac{${cLn}}{${T1}-${room}}\\right)$`,
        MCMaths.cleaner(
          `$\\displaystyle k = \\frac{1}{${t1}}\\ln\\left(${kLn}\\right)$`
        ),
        `HEADING Rearanging:`,
        MCMaths.cleaner(
          `$\\displaystyle e^{\\frac{1}{${t1}}\\ln\\left(${kLn}\\right)t} = e^{\\ln\\left(\\frac{${cLn}}{T-${room}}\\right)}$`
        ),
        MCMaths.cleaner(
          `$\\displaystyle e^{\\frac{1}{${t1}}\\ln\\left(${kLn}\\right)t} = \\frac{${cLn}}{T-${room}}$`
        ),
        MCMaths.cleaner(
          `$\\displaystyle T = ${room}+${cLn}e^{-\\frac{1}{${t1}}\\ln\\left(${kLn}\\right)t}$`
        ),
        `HEADING Evaluating:`,
        MCMaths.cleaner(
          `$\\displaystyle T = ${room}+${cLn}e^{-\\frac{1}{${t1}}\\ln\\left(${kLn}\\right)(${t2})}$`
        ),
        `$T\\approx ${T2}^\\circ c$`,
        `HEADING b) Formula from part a:`,
        MCMaths.cleaner(
          `$\\displaystyle \\frac{1}{${t1}}\\ln\\left(${kLn}\\right) t = \\ln\\left(\\frac{${cLn}}{T-${room}}\\right)$`
        ),
        `HEADING Evaluating:`,
        MCMaths.cleaner(
          `$\\displaystyle  t = \\frac{\\ln\\left(\\frac{${cLn}}{${T3}-${room}}\\right)}{\\frac{1}{${t1}}\\ln\\left(${kLn}\\right)}$`
        ),
        `$t\\approx ${t3}$ minutes`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(T2, 0.1, "a)");
      group.addInput(t3, 0.1, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_DifferentialEquations_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const xFuncs = [
      new MCMaths.Polynomial([
        1,
        MCRandom.randomInt(-5, 5),
        MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
      ]),
      new MCMaths.Polynomial([
        1,
        0,
        0,
        MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
      ]),
    ];
    const type = MCRandom.randomInt(0, 1);

    const x = xFuncs[type];
    const y = new MCMaths.Polynomial(
      [
        MCRandom.randomInt(2, 6, 2),
        MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
      ],
      "y"
    );

    const a = MCRandom.randomInt(-3, 3);
    const b = a + MCRandom.randomInt(3, 5);

    const eval1 = x.integral().evaluate(b);
    const eval2 = x.integral().evaluate(a);

    const p = Math.abs(eval1) / Math.abs(eval2);

    const y1 = MCRandom.randomInt(-5, 5);

    const qA = y.integral().getCoefficients()[0];
    const qB = y.integral().getCoefficients()[1];
    const qC = -y.integral().evaluate(y1) - Math.log(p);

    const disc = qB * qB - 4 * qA * qC;

    const ans1 = (-qB - Math.sqrt(disc)) / (2 * qA);
    const ans2 = (-qB + Math.sqrt(disc)) / (2 * qA);

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(p * 10) === p * 10 &&
      Math.abs(p) < 50 &&
      Math.abs(p) > 1 &&
      eval1 > 0 &&
      eval2 > 0 &&
      Math.round(eval1 * 10) === eval1 * 10 &&
      Math.round(eval2 * 10) === eval2 * 10 &&
      y.integral().evaluate(y1) !== 0 &&
      disc > 0 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `a) Evaluate $\\displaystyle \\int_{${a}}^{${b}} \\frac{${x
          .derivative()
          .toString()}}{${x.toString()}}dx$, in the form $\\ln(p)$.`,
        `b) Given the equation $\\displaystyle \\left(${x.toString()}\\right)\\frac{dy}{dx} = \\frac{${x
          .derivative()
          .toString()}}{${y.toString()}}$, and that when $x=${a}$, $y=${y1}$, find the possible values of $y$ when $x=${b}$, to 2dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING Using Inverse Chain Rule:`,
        `$\\displaystyle = \\left[\\ln\\left|${x
          .integral()
          .toString()}\\right|\\right]_{${a}}^{${b}}$`,
        `$\\displaystyle = \\ln\\left|${x
          .integral()
          .toString()
          .replace(
            /x/g,
            `(${b})`
          )}\\right| - \\ln\\left|${x
          .integral()
          .toString()
          .replace(/x/g, `(${a})`)}\\right|$`,
        MCMaths.cleaner(
          `$\\displaystyle = \\ln\\left|${x
            .integral()
            .evaluate(b)}\\right| - \\ln\\left|${x
            .integral()
            .evaluate(a)}\\right|$`
        ),
        MCMaths.cleaner(`$\\displaystyle =\\ln\\left(${p}\\right)$`),
        `b)HEADING Rearanging:`,
        `$\\displaystyle ${y.toString()}dy = \\frac{${x
          .derivative()
          .toString()}}{${x.toString()}}dx$`,
        `HEADING Integrating:`,
        `$\\displaystyle \\int ${y.toString()}dy = \\int \\frac{${x
          .derivative()
          .toString()}}{${x.toString()}}dx$`,
        `$${y
          .integral()
          .toString()} = \\ln\\left|${x.integral().toString()}\\right|+c$`,
        `HEADING Finding $c$:`,
        `$${y
          .integral()
          .toString()
          .replace(
            /y/g,
            `(${y1})`
          )} = \\ln\\left|${x
          .integral()
          .toString()
          .replace(/x/g, `(${a})`)}\\right|$`,
        `$ c = ${y.integral().evaluate(y1)} - \\ln(${eval2}) $`,
        `HEADING Finding $y$ at $x=${b}$:`,
        MCMaths.cleaner(
          `$ ${y.integral().toString()}-${y
            .integral()
            .evaluate(y1)} = \\ln\\left|${x
            .integral()
            .evaluate(b)}\\right| - \\ln(${eval2}) $`
        ),
        MCMaths.cleaner(
          `$${y.integral().toString()}-${y
            .integral()
            .evaluate(y1)}-\\ln\\left(${p}\\right) = 0$`
        ),
        `HEADING Using quadratic formula:`,
        MCMaths.cleaner(
          `$\\displaystyle y = \\frac{${-qB}\\pm\\sqrt{${qB}^2 - 4\\times${qA} \\times\\left( ${-y
            .integral()
            .evaluate(y1)}-\\ln\\left(${p}\\right) \\right)}}{2\\times ${qA}}$`
        ),
        `$y\\approx ${Math.round(ans1 * 100) / 100}$ or $y\\approx ${
          Math.round(ans2 * 100) / 100
        }.$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(p, 0, "p=");
      question.addInputGroup(group);
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput([ans1, ans2], 0.1, "y=");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_DifferentialEquations_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const in1 = MCRandom.randomInt(40, 100, 10);
    const start = MCRandom.randomInt(300, 1000, 100);
    const rate = MCRandom.randomInt(3, 10);
    const rate2 = MCMaths.cleaner(`${1 / rate}`);
    const c = start - in1 * rate;
    const ans1 = Math.round(in1 * rate + c * Math.exp(-30 / rate));

    const V2 = MCRandom.randomInt(in1 * rate + 100, in1 * rate + 300, 25);
    const ans2 = Math.round(rate * Math.log(c / (V2 - in1 * rate)));
    /* END VARIABLES AND CONSTANTS */
    if (
      c > 100 &&
      ans1 > in1 * rate + 10 &&
      V2 < c &&
      ans2 > 2 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        MCMaths.cleaner(
          `A man is pouring water into a sink at a constant rate of $${in1}cm^3s^{-1}$, and draining out of the sink at a rate of $${
            1 / rate
          }Vcm^3s^{-1}$, where $V$ is the volume of water currently in the sink.`
        ),
        `a) Given the sink start with $${start}cm^3$, find the volume of water in the sink after half a minute has passed to the nearest $cm^3$, by forming and solving a differential equation.`,
        `b) Find the time take for the sink to drain to $${V2}cm^3$, to the nearest second.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING Forming Equation:`,
        `$\\displaystyle ${rate}\\frac{dV}{dt} = ${in1 * rate} - V$`,
        `HEADING Solving Equations:`,
        `$\\displaystyle ${rate2}dt = \\frac{dV}{${in1 * rate}-V}$`,
        `$\\displaystyle ${rate2}t = \\int \\frac{dV}{${in1 * rate}-V}$`,
        `$${rate2}t = c - \\ln(V-${in1 * rate})$`,
        `HEADING Finding $c$:`,
        `$0 = c - \\ln(${start}-${in1 * rate})$`,
        `$c = \\ln(${c})$`,
        `HEADING Rearranging:`,
        `$\\displaystyle ${rate2}t = \\ln\\left(\\frac{${c}}{V-${
          in1 * rate
        }}\\right)$`,
        `$\\displaystyle \\frac{${c}}{V-${in1 * rate}} = e^{${rate2}t}$`,
        `$\\displaystyle V = ${in1 * rate} + ${c}e^{-${rate2}t}$`,
        `HEADING Evaluating at $t=30$:`,
        `$\\displaystyle V = ${in1 * rate} + ${c}e^{-${rate2}\\times 30}$`,
        `$V\\approx${ans1}cm^3.$`,
        `HEADING b) Equation from part a:`,
        `$\\displaystyle t = ${rate}\\ln\\left(\\frac{${c}}{V-${
          in1 * rate
        }}\\right)$`,
        `HEADING Evaluating at $V=${V2}$:`,
        `$\\displaystyle t = ${rate}\\ln\\left(\\frac{${c}}{${V2}-${
          in1 * rate
        }}\\right)$`,
        `$t \\approx ${ans2}s.$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans1, 0, "a=");
      group.addInput(ans2, 0, "b=");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_DifferentialEquations_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const k1 = MCRandom.randomInt(3, 8);
    const ans1 = Math.round((-Math.asin(-Math.log(2)) / k1 + Math.PI / k1) * 7);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `An insect population's size is modelled by the differential equation $\\displaystyle \\frac{dN}{dt} = kN\\cos(kt)$, for time $t$ weeks, for $N$ thousand insects.`,
        `From time $t=0$, find the time taken for the population to double if $k=${k1}$, to the nearst day.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Rearranging:`,
        `$\\displaystyle \\frac{dN}{N} = ${k1}\\cos(${k1}t)dt$`,
        `HEADING Integrating:`,
        `$\\displaystyle\\int \\frac{dN}{N} = \\int ${k1}\\cos(${k1}t)dt$`,
        `$\\ln(N) = c-\\sin(${k1}t)$`,
        `$\\displaystyle N = e^{c-\\sin(${k1}t)}$`,
        `HEADING Population at $t=0$:`,
        `$\\displaystyle N_0 = e^{c}$`,
        `HEADING Finding time at $2N_0$:`,
        `$\\displaystyle 2e^{c} = e^{c-\\sin(${k1}t)}$`,
        `$\\displaystyle 2e^{c} = e^{c} \\cdot e^{-\\sin(${k1}t)}$`,
        `$\\displaystyle 2 = e^{-\\sin(${k1}t)}$`,
        `$-\\sin(${k1}t) = \\ln(2)$`,
        `$\\displaystyle t = \\frac{1}{${k1}}\\sin^{-1}(-\\ln(2))$`,
        `HEADING Adjusting $t$ to get to the correct part of the curve:`,
        `$\\displaystyle t = \\frac{\\pi}{${k1}} -\\frac{1}{${k1}}\\sin^{-1}(-\\ln(2)) $`,
        `$t \\approx ${
          Math.round((-Math.asin(-Math.log(2)) / k1 + Math.PI / k1) * 1000) /
          1000
        }$ weeks`,
        `$\\displaystyle t \\approx ${ans1}$ days.`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans1, 0);
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_DifferentialEquations_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 20);
    const A0 = MCRandom.randomInt(2, 5);
    const t1 = MCRandom.randomInt(3, 9);
    const ans = Math.round(
      ((a * 3) / 2) * Math.log((2 + t1) / 2) + ((9 / 4) * A0 ** 3) ** (2 / 3)
    );
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A bacteria population grows in a petri dish. The size of the growth can be modeled as a circle with area $A$ modeled by $\\displaystyle \\frac{dA}{dt} = \\frac{${a}}{(t+2)\\sqrt{A}}$, for time $t$ hours.`,
        `Given the area equals $${
          A0 ** 2
        }cm^2$ at time zero, find the area after $${t1}$ hours, to the nearest $cm^2$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Rearranging:`,
        `$\\displaystyle \\sqrt{A}dA = \\frac{${a}}{(t+2)} dt$`,
        `HEADING Integrating:`,
        `$\\displaystyle \\int\\sqrt{A}dA = \\int \\frac{${a}}{(t+2)} dt$`,
        `$\\displaystyle \\frac{2}{3}A^{\\frac{3}{2}} = ${a}\\ln(t+2) + c$`,
        `HEADING Finding $c$:`,
        `$\\displaystyle \\frac{2}{3}\\cdot ${
          A0 ** 2
        }^{\\frac{3}{2}} = ${a}\\ln(2)+c$`,
        MCMaths.cleaner(
          `$\\displaystyle c =  ${(3 / 2) * A0 ** 3} - ${a}\\ln(2)$`
        ),
        `HEADING Evaluating at $t=${t1}$:`,
        MCMaths.cleaner(
          `$\\displaystyle \\frac{2}{3}A^{\\frac{3}{2}} = ${a}\\ln\\left(\\frac{${t1}+2}{2}\\right) +  ${
            (3 / 2) * A0 ** 3
          }$`
        ),
        MCMaths.cleaner(
          `$\\displaystyle A =\\left( ${(a * 3) / 2}\\ln\\left(${
            (2 + t1) / 2
          }\\right) +  ${(9 / 4) * A0 ** 3}\\right)^{\\frac{2}{3}} $`
        ),
        `$A\\approx ${ans}cm^2$.`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans, 0);
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationByParts_Exam_Q1() {
  let count = 0;
  while (true) {
    const funcs = [
      new MCMaths.Trig(1, "sin", 1, MCRandom.randomInt(1, 3)),
      new MCMaths.Trig(1, "cos", 1, MCRandom.randomInt(1, 3)),
      new MCMaths.Exponential(1, "e", MCRandom.randomInt(1, 3)),
    ];

    const p1 = new MCMaths.Polynomial([
      1,
      MCRandom.randomInt(-1, 1) * MCRandom.randomInt(0, 1),
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
    ]);
    const e1 = funcs[MCRandom.randomInt(0, funcs.length - 1)];
    const a = MCRandom.randomInt(-5, 3);
    const b = a + MCRandom.randomInt(1, 10);
    const int = new MCMaths.ByParts(p1, e1, a, b);

    const ans = int.doubleEvaluate();

    if (ans < 10 && ans > 1) {
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Find the value of $\\displaystyle \\int_{${a}}^{${b}}\\left(${p1.toString()}\\right)${e1.toString()} dx$, to $3dp$.`,
      ]);
      question.addMultipleParagraphs(
        "solution",
        int.doubleIntegralWorking(true)
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(ans, 0.005);
      question.addInputGroup(group2);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationByParts_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const funcs = [
      new MCMaths.Trig(1, "sin", 1, MCRandom.randomInt(1, 3)),
      new MCMaths.Trig(1, "cos", 1, MCRandom.randomInt(1, 3)),
      new MCMaths.Exponential(1, "e", MCRandom.randomInt(1, 3)),
    ];
    const p1 = new MCMaths.Polynomial([1, 0]);
    const e1 = funcs[MCRandom.randomInt(0, funcs.length - 1)];
    const a = MCRandom.randomInt(-5, 3);
    const b = a + MCRandom.randomInt(1, 10);
    const int = new MCMaths.ByParts(p1, e1, a, b);

    const ans = int.evaluate();
    /* END VARIABLES AND CONSTANTS */
    if (
      ans < 10 &&
      ans > 1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Find the value of $\\displaystyle \\int_{${a}}^{${b}}${p1.toString()}${e1.toString()} dx$, to $3dp$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        int.integralWorking()[0],
        int.integralWorking()[1],
        int.integralWorking()[2],
        int.integralWorking()[3],
        int.integralWorking()[4],
        int
          .integralWorking()[5]
          .replace("\\left(1 \\right)", "")
          .replace("\\left(x\\right)", "x"),
        int.integralWorking()[6],
        int.integralWorking()[7],
        int.integralWorking()[8],
        int.integralWorking()[9],
        int.integralWorking()[10],
        int.integralWorking()[11],
      ]);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(ans, 0.005);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationBySubstitution_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const int = `- \\int \\frac{dx}{\\sqrt{1 - x^2}}`;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Show that $${int} = \\arccos x + C$.`,
      ]);

      question.addHeading(
        "solution",
        "This result can be shown by using a substitution,"
      );

      question.addHeading("solution", "Let $x = \\cos u$:");

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow \\frac{dx}{du} = - \\sin u$`,
        `$\\Rightarrow - dx = \\sin u\\ du$`,
      ]);

      question.addHeading(
        "solution",
        "And, $\\sqrt{1 - x^2} = \\sqrt{1 - \\cos^2 u}$"
      );

      question.addMultipleParagraphs("solution", [
        `$\\ = \\sqrt{\\sin^2 u}$`,
        `$\\ = \\sin u$`,
      ]);

      question.addHeading(
        "solution",
        "So, replacing $- dx\\ $ with $\\sin u\\ du$, and $\\sqrt{1 - x^2}$ with $\\sin u$:"
      );

      question.addMultipleParagraphs("solution", [
        `$${int} = \\int \\frac{\\sin u}{\\sin u} du$`,
        `$\\ = \\int du$`,
        `$\\ = u + C$`,
        `$\\ = \\arccos x + C$`,
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationBySubstitution_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const int = `\\int \\frac{dx}{1 + x^2}`;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Show that,`,
        `$${int} = \\arctan x + C$`,
      ]);

      question.addHeading(
        "solution",
        "This result can be shown by using a substitution,"
      );

      question.addHeading("solution", "Let $x = \\tan u$:");

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow \\frac{dx}{du} = \\sec^2 u$`,
        `$\\Rightarrow dx = \\sec^2 u\\ du$`,
      ]);

      question.addHeading("solution", "And, $1 + x^2 = 1 + \\tan^2 u$");

      question.addMultipleParagraphs("solution", [`$\\ = \\sec^2 u$`]);

      question.addHeading(
        "solution",
        "So, replacing $dx\\ $ with $\\sec^2 u\\ du$, and $1 + x^2$ with $\\sec^2 u$:"
      );

      question.addMultipleParagraphs("solution", [
        `$${int} = \\int \\frac{\\sec^2 u}{\\sec^2 u} du$`,
        `$\\ = \\int du$`,
        `$\\ = u + C$`,
        `$\\ = \\arctan x + C$`,
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationBySubstitution_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const int = `\\int \\frac{1 + \\sin x}{\\cos x} dx`;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Using the substitution $u = \\sin x$, or otherwise, find`,
        `$I = ${int}$`,
      ]);

      question.addHeading("solution", "Let $u = \\sin x$:");

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow \\frac{du}{dx} = \\cos x$`,
        `$\\Rightarrow dx = \\frac{du}{\\cos x}$`,
        `$\\Rightarrow I = \\int \\frac{1 + u}{\\cos^2 x} du$`,
      ]);

      question.addHeading("solution", "Consider, $\\cos^2 x = 1 - \\sin^2 x$");

      question.addMultipleParagraphs("solution", [
        `$\\ = 1 - u^2$`,
        `$\\ = (1 - u)(1 + u)$`,
        `$\\Rightarrow I = \\int \\frac{1 + u}{(1 - u)(1 + u)} du$`,
        `$\\ = \\int \\frac{du}{1 - u}$`,
        `$\\ = - \\ln |1 - u| + C$`,
        `$\\ = - \\ln |1 - \\sin x| + C$`,
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationBySubstitution_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const alpha = MCRandom.randomInt(2, 4);
    const B = MCRandom.randomInt(1, 3);
    const A = 2 * alpha - B;
    const C = alpha - B;
    const fx = `\\sqrt{(${A} - x)(x + ${B})}`;
    const x = `${C} + ${alpha}\\cos \\theta`;
    const im = new MCQuestion.Image("Joel/quarter_circ.svg", "height", 15);
    im.addOverlay("$R$", 45, 60, 1.5);
    im.addOverlay("$y$", 10, 1, 1);
    im.addOverlay("$x$", 98, 86, 1);
    im.addOverlay("$O$", 9, 87, 1);
    im.addOverlay(`$${A}$`, 86, 87, 0.9);
    let check = false;
    let lb = Math.acos(-C / alpha);
    lb = MCMaths.cleaner(`${lb}`);
    if (Math.round((-2 * C) / alpha) === (-2 * C) / alpha) {
      check = true;
    }
    const ftheta = `\\sqrt{(${alpha} - ${alpha}\\cos \\theta)(${alpha} + ${alpha}\\cos \\theta)}`;
    const k = alpha ** 2;
    const p = k / 3;
    const q = k / 8;
    /* END VARIABLES AND CONSTANTS */
    if (C > 0 && check) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addImage("question", im);

      question.addMultipleParagraphs("question", [
        `The graph above shows a sketch of the curve $y = ${fx}, \\ 0 \\leqslant x \\leqslant ${A}$`,
        `Using the substitution $x = ${x}$, find the area of the finite region $R$.`,
      ]);

      question.addHeading(
        "solution",
        "The area of $R$ can be represented by the integral:"
      );

      question.addMultipleParagraphs("solution", [
        `$I = \\int_0^${A} ${fx}\\ dx$`,
      ]);

      question.addHeading("solution", `Let $x = ${x}$,`);

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow dx = -${alpha}\\sin \\theta d\\theta$`,
      ]);

      question.addHeading(
        "solution",
        `Converting the bounds from $x$ to $\\theta$:`
      );

      question.addMultipleParagraphs("solution", [
        `$x = 0 \\Rightarrow 0 = ${x}$`,
        MCMaths.cleaner(`$\\Rightarrow \\cos \\theta = ${-C / alpha}$`),
        MCMaths.cleaner(`$\\Rightarrow \\theta = ${lb}$`),
        `$x = ${A} \\Rightarrow ${A} = ${x}$`,
        MCMaths.cleaner(`$\\Rightarrow \\cos \\theta = 1$`),
        MCMaths.cleaner(`$\\Rightarrow \\theta = 0$`),
      ]);

      question.addHeading("solution", `Substituting into the integral:`);

      question.addMultipleParagraphs("solution", [
        `$I = ${-alpha} \\int_{${lb}}^0 \\sin \\theta ${fx
          .replace("x", `(${x})`)
          .replace("x", `(${x})`)}\\ d\\theta $`,
      ]);

      question.addHeading("solution", `Swapping the bounds:`);

      question.addMultipleParagraphs("solution", [
        `$I = ${alpha} \\int_{0}^{${lb}} \\sin \\theta ${ftheta}\\ d\\theta $`,
        `$\\ = ${k} \\int_{0}^{${lb}} \\sin \\theta \\sqrt{1 - \\cos^2 \\theta}\\ d\\theta $`,
        `$\\ = ${k} \\int_{0}^{${lb}} \\sin^2 \\theta \\ d\\theta $`,
      ]);

      question.addHeading(
        "solution",
        `Using the double angle formula, $\\cos 2\\theta = 1 - 2\\sin^2 \\theta$:`
      );

      question.addMultipleParagraphs("solution", [
        `$I = ${k / 2} \\int_{0}^{${lb}} 1 - \\cos 2\\theta \\ d\\theta $`,
        `$\\ = ${
          k / 2
        } \\Bigl[\\theta - \\frac{1}{2} \\sin 2\\theta\\Bigr]_0^{${lb}}$`,
        `$\\ = ${
          k / 2
        } \\Bigl(${lb} - \\frac{1}{2} \\sin \\frac{4\\pi}{3}\\Bigr)$`,
        MCMaths.cleaner(`$\\ = ${p}\\pi + ${q}\\sqrt{3}$`),
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationBySubstitution_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 5);
    const b = a ** 2;
    const fx = `\\frac{dx}{x^2 \\sqrt{${b} - x^2}}`;
    let ubx = 2 ** 0.5 * (a / 2);
    ubx = MCMaths.cleaner(`${ubx}`);
    let lbx = a / 2;
    lbx = MCMaths.cleaner(`${lbx}`);
    const ubu = Math.PI / 3;
    const lbu = Math.PI / 4;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Use a substitution of the form $x = k \\cos u$, where $k$ is a positive integer, to calculate the exact value of`,
        `$\\displaystyle I = \\int_{${lbx}}^{${ubx}} ${fx}$`,
      ]);

      question.addHeading(
        "solution",
        `Choosing $k = ${a}$ to give the substitution:`
      );

      question.addHeading("solution", `Let $x = ${a} \\cos u$,`);

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow dx = ${-a} \\sin u \\ du$`,
      ]);

      question.addHeading(
        "solution",
        `Also $\\sqrt{${b} - x^2} = \\sqrt{${b} - ${b} \\cos^2 u}$`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ = ${a} \\sqrt{\\sin^2 u}$`,
        `$\\ = ${a} \\sin u$`,
      ]);

      question.addHeading("solution", `Changing the bounds from $x$ to $u$:`);

      question.addMultipleParagraphs("solution", [
        `$x = ${lbx} \\Rightarrow ${lbx} = ${a} \\cos u$`,
        `$\\Rightarrow \\cos u = {1\\over 2}$`,
        `$\\therefore u = {\\pi \\over 3}$`,
        `$x = ${ubx} \\Rightarrow ${ubx} = ${a} \\cos u$`,
        `$\\Rightarrow \\cos u = {\\sqrt{2} \\over 2}$`,
        `$\\therefore u = {\\pi \\over 4}$`,
      ]);

      question.addHeading(
        "solution",
        `Substituting all of this into the original integral:`
      );

      question.addMultipleParagraphs("solution", [
        MCMaths.cleaner(
          `$\\displaystyle I = \\int_{${ubu}}^{${lbu}} \\frac{${-a} \\sin u}{${b} \\cos^2 u \\cdot ${a} \\sin u} \\ du$`
        ),
        MCMaths.cleaner(
          `$\\ \\displaystyle = {1 \\over ${b}} \\int_{${lbu}}^{${ubu}} \\frac{1}{\\cos^2 u} \\ du$`
        ),
        MCMaths.cleaner(
          `$\\ \\displaystyle = {1 \\over ${b}} \\int_{${lbu}}^{${ubu}} \\sec^2 u \\ du$`
        ),
        MCMaths.cleaner(
          `$\\ = {1 \\over ${b}} \\left[\\tan u\\right]_{${lbu}}^{${ubu}}$`
        ),
        MCMaths.cleaner(
          `$\\ = {1 \\over ${b}} \\left(\\tan {\\pi \\over 3} - \\tan {\\pi \\over 4}\\right)$`
        ),
        MCMaths.cleaner(`$\\ = {1 \\over ${b}} \\left(\\sqrt{3} - 1\\right)$`),
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationBySubstitution_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const k = MCRandom.randomInt(1, 3);
    const lbu = 1;
    const c = MCRandom.randomInt(2, 5);
    const lbx = lbu - c;
    const ubx = lbx + 1;
    const ubu = ubx + c;
    const u = new MCMaths.Polynomial([1, c]);
    u.toString();
    const x = new MCMaths.Polynomial([1, -c], "u");
    x.toString();
    const sqrt = `\\sqrt{${u}}`;
    const int = `\\int_{${lbx}}^{${ubx}} x ${sqrt} \\ dx`;
    const fx = MCMaths.cleaner(
      `${2 / 5}u^{${5 / 2}} - ${c * (2 / 3)}u^{${3 / 2}}`
    );
    let ans1 = 8 / 5 - c * (4 / 3);
    ans1 = `${ans1} \\sqrt{2}`;
    ans1 = MCMaths.cleaner(ans1);
    let ans2 = 2 / 5 - c * (2 / 3);
    let ans3 = -ans2;
    ans2 = MCMaths.cleaner(`${ans2}`);
    ans3 = MCMaths.cleaner(`${ans3}`);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Use the substitution $u = ${u}$ to calculate the exact value of`,
        `$\\displaystyle ${int}$`,
      ]);

      question.addHeading("solution", `Let $u = ${u}$,`);

      question.addMultipleParagraphs("solution", [`$\\Rightarrow du = dx$`]);

      question.addHeading("solution", `Also $${sqrt} = \\sqrt{u}$`);

      question.addMultipleParagraphs("solution", [`$\\ = u^{{1 \\over 2}}$`]);

      question.addHeading("solution", `And $x = ${x}$`);

      question.addHeading("solution", `Changing the bounds from $x$ to $u$:`);

      question.addMultipleParagraphs("solution", [
        `$x = ${lbx} \\Rightarrow u = ${lbu}$`,
        `$x = ${ubx} \\Rightarrow u = ${ubu}$`,
      ]);

      question.addHeading(
        "solution",
        `After substituting all of this, the integral becomes:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\displaystyle I = \\int_${lbu}^${ubu} (${x}) u^{{1 \\over 2}} \\ du$`,
        `$\\displaystyle = \\int_${lbu}^${ubu} u^{{3 \\over 2}} - ${c}u^{{1\\over 2}} \\ du$`,
        `$\\ = \\left[${fx}\\right]_${lbu}^${ubu}$`,
        `$\\ = \\left(${fx
          .replace("u", "(2)")
          .replace("u", "(2)")}\\right) - \\left(${fx
          .replace("u", "(1)")
          .replace("u", "(1)")}\\right)$`,
        `$\\ = ${ans1} - ${ans2}$`,
        `$\\ = ${ans3} ${ans1}$`,
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationBySubstitution_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const A = MCRandom.chooseElement([2, 3, 5]);
    const ubx = `{3 \\pi \\over 2}`;
    const lbx = `{\\pi \\over 2}`;
    const int = `\\int_{${lbx}}^{${ubx}} \\cosec x \\cot x \\sqrt{\\cosec x + ${A}}\\ dx`;
    let ans1 = (A + 1) ** 3;
    ans1 **= 0.5;
    let ans2 = (A - 1) ** 3;
    ans2 **= 0.5;
    let ans = ``;
    if (A === 3 || A === 5) {
      ans1 /= 2;
      ans2 /= 2;
      ans = `{4\\over 3} \\left(${MCMaths.cleaner(
        `${ans1}`
      )} - ${MCMaths.cleaner(`${ans2}`)}\\right)`;
    } else {
      ans = `{2\\over 3} \\left(${ans1} - ${ans2}\\right)`;
    }
    const ans1D = MCMaths.cleaner(`${ans1}`);
    const ans2D = MCMaths.cleaner(`${ans2}`);
    const ansD = MCMaths.cleaner(`${ans}`);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Use the substitution $u = \\cosec x$ to calculate the exact value of`,
        `$\\displaystyle ${int}$`,
      ]);

      question.addHeading("solution", `Let $u = \\cosec x$,`);

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow du = - \\cosec x \\cot x \\ dx$`,
      ]);

      question.addHeading("solution", `When $x = ${lbx}$,`);

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow u = \\cosec ${lbx}$`,
        `$\\ = 1$`,
      ]);

      question.addHeading("solution", `When $x = ${ubx}$,`);

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow u = \\cosec ${ubx}$`,
        `$\\ = -1$`,
      ]);

      question.addHeading(
        "solution",
        `Substituting into to the original integral:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\displaystyle I = - \\int_1^{-1} \\sqrt{u + ${A}} \\ du$`,
        `$\\displaystyle = \\int_{-1}^1 (u + ${A})^{1\\over 2} \\ du$`,
      ]);

      question.addHeading(
        "solution",
        `Using the inverse chain rule to integrate:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ I = \\left[{2\\over 3} (u + ${A})^{3\\over 2}\\right]_{-1}^{1}$`,
        `$\\ = {2\\over 3} \\left((${A + 1})^{3\\over 2} - (${
          A - 1
        })^{3\\over 2}\\right)$`,
        `$\\ = ${ansD}$`,
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationBySubstitution_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Using a suitable substitution, find the exact value of:`,
        `$\\displaystyle \\int_{0}^{\\pi \\over 4} \\sec^4 x \\ dx$`,
      ]);

      question.addHeading(
        "solution",
        `As $\\tan x$ is related to $\\sec x$ by $ {d\\over dx}(\\tan x) = \\sec^2 x$ and $1 + \\tan^2 x = \\sec^2 x$, we can start with the substitution $u = \\tan x$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow du = \\sec^2 x \\ dx$`,
      ]);

      question.addHeading("solution", `Which transforms the integral to:`);

      question.addMultipleParagraphs("solution", [
        `$\\displaystyle I = \\int_{x = 0}^{x = {\\pi \\over 4}} \\sec^2 x \\ du$`,
      ]);

      question.addHeading("solution", `As $\\sec^2 x = 1 + \\tan^2 x$,`);

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow \\sec^2 x = 1 + u^2$`,
      ]);

      question.addHeading("solution", `Changing the bounds from $x$ to $u$:`);

      question.addMultipleParagraphs("solution", [
        `$x = 0 \\Rightarrow u = \\tan 0$`,
        `$\\Rightarrow u = 0$`,
        `$x = {\\pi \\over 4} \\Rightarrow u = \\tan {\\pi \\over 4}$`,
        `$\\Rightarrow u = 1$`,
      ]);

      question.addHeading(
        "solution",
        `Substituting the rest of this in yields:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\displaystyle I = \\int_{0}^{1} 1 + u^2 \\ du$`,
        `$\\ = \\left[u + {1\\over 3}u^3\\right]_0^1$`,
        `$\\ = \\left(1 + {1\\over 3} - 0\\right)$`,
        `$\\ = {4\\over 3}$`,
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationBySubstitution_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const lbx = `\\ln 2`;
    const ubx = `\\ln 3`;
    const u = `e^x - 1`;
    const int = `\\int_{${lbx}}^{${ubx}} \\frac{e^{3x}}{${u}} \\ dx`;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Using the substitution $u^2 = ${u}$, find the exact value of:`,
        `$\\displaystyle ${int}$`,
      ]);

      question.addHeading("solution", `Let $u^2 = ${u}$,`);

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow 2u\\ du = e^x \\ dx$`,
      ]);

      question.addHeading("solution", `Which transforms the integral to:`);

      question.addMultipleParagraphs("solution", [
        `$\\displaystyle I = \\int_{x = ${lbx}}^{x = ${ubx}} \\frac{e^{2x}}{u^2} \\cdot 2u\\ du$`,
      ]);

      question.addHeading("solution", `Also $u^2 = ${u}$,`);

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow e^{2x} = (u^2 + 1)^2$`,
        `$\\ = u^4 + 2u^2 + 1$`,
      ]);

      question.addHeading("solution", `Changing the bounds from $x$ to $u$:`);

      question.addMultipleParagraphs("solution", [
        `$x = ${lbx} \\Rightarrow u^2 = e^{${lbx}} - 1$`,
        `$\\Rightarrow u = 1$`,
        `$x = ${ubx} \\Rightarrow u^2 = e^{${ubx}} - 1$`,
        `$\\Rightarrow u = \\sqrt{2}$`,
      ]);

      question.addHeading("solution", `Substituting in gives the integral:`);

      question.addMultipleParagraphs("solution", [
        `$\\displaystyle I = 2 \\int_{1}^{\\sqrt{2}} \\frac{u^4 + 2u^2 + 1}{u} \\ du$`,
        `$\\displaystyle = 2 \\int_{1}^{\\sqrt{2}} u^3 + 2u + {1\\over u} \\ du$`,
        `$\\ = \\left[{1\\over 2}u^4 + 2u^2 + 2\\ln u\\right]_1^{\\sqrt{2}}$`,
        `$\\ = \\left(6 + 2\\ln \\sqrt{2}\\right) - \\left({5\\over 2} + 2\\ln 1\\right)$`,
        `$\\ = {17\\over 2} + \\ln 2$`,
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationOfPartialFractions_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const top = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);
    const x2_coeff = MCRandom.randomInt(1, 3);
    const r2 = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);

    const pf = new MCMaths.PartialFraction([top], [1, 0], [x2_coeff, r2]);
    const A = top / r2;
    const B = top / (-r2 / x2_coeff);

    const uppper_l = MCRandom.randomInt(4, 8);
    const lower_l = uppper_l - MCRandom.randomInt(1, 3);

    const k =
      (uppper_l * (x2_coeff * lower_l + r2)) /
      ((x2_coeff * uppper_l + r2) * lower_l);
    /* END VARIABLES AND CONSTANTS */
    if (
      (lower_l > 0 || uppper_l < 0) &&
      (-r2 / x2_coeff < lower_l || -r2 / x2_coeff > uppper_l) &&
      x2_coeff * lower_l + r2 > 0 &&
      x2_coeff * uppper_l + r2 > 0 &&
      parseFloat(k.toFixed(3)) === k &&
      parseFloat(A.toFixed(3)) === A &&
      parseFloat(B.toFixed(3)) === B &&
      A % 1 === 0 &&
      B % 1 === 0 &&
      k % 1 === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$f(x)=\\frac{${top}}{x(${x2_coeff}x+${r2})}$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`a) Express $f(x)$ in the form ${pf.rhsToString()}`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `b) Hence find the exact value of $\\displaystyle\\int\\limits^{${uppper_l}}_{${lower_l}} f(x) dx$, giving your answer in the form $${A}\\ln k$`
        )
      );
      // solution
      // a
      question.addHeading(
        "solution",
        MCMaths.cleaner(`a) Express $f(x)$ in the form ${pf.rhsToString()}`)
      );
      question.addMultipleParagraphs("solution", pf.working());
      // b
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `b) Hence find the exact value of $\\displaystyle\\int\\limits^{${uppper_l}}_{${lower_l}} f(x) dx$, giving your answer in the form $${A}\\ln k$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle\\int\\limits^{${uppper_l}}_{${lower_l}} f(x) dx=\\displaystyle\\int\\limits^{${uppper_l}}_{${lower_l}} \\frac{${top}}{x(${x2_coeff}x+${r2})} dx$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\displaystyle\\int\\limits^{${uppper_l}}_{${lower_l}}$ ${pf
            .rhsToString()
            .replace("A", `(${A})`)
            .replace("B", `(${B})`)} $dx$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=[${A}\\ln(x)+${
            B / x2_coeff
          }\\ln(${x2_coeff}x+${r2})]^{^${uppper_l}}_{_${lower_l}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=[${A}\\ln(${uppper_l})+${
            B / x2_coeff
          }\\ln(${x2_coeff}(${uppper_l})+${r2})] - [${A}\\ln(${lower_l})+${
            B / x2_coeff
          }\\ln(${x2_coeff}(${lower_l})+${r2})]$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=${A}\\ln(${uppper_l})+${B / x2_coeff}\\ln(${
            x2_coeff * uppper_l + r2
          })+${-A}\\ln(${lower_l})+${-B / x2_coeff}\\ln(${
            x2_coeff * lower_l + r2
          })$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=${A}[\\ln(${uppper_l})-\\ln(${
            x2_coeff * uppper_l + r2
          })-\\ln(${lower_l})+\\ln(${x2_coeff * lower_l + r2})]$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=${A}[\\ln(\\frac{${uppper_l}(${x2_coeff * lower_l + r2})}{${
            x2_coeff * uppper_l + r2
          }(${lower_l})})]$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${A}\\ln(${k})$`));
      //
      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(A, 0, `a) $\\ A=$`);
      g1.addInput(B, 0, `a) $\\ B=$`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(k, 0, `b) $\\ k=$`);
      question.addInputGroup(g2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationOfPartialFractions_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const top_x = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const top_c = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

    let c_tmp = MCRandom.randomInt(1, 8);
    let x_tmp = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    while (
      x_tmp / top_x === c_tmp / top_c ||
      (top_x === x_tmp && top_c === c_tmp)
    ) {
      c_tmp = MCRandom.randomInt(1, 8);
      x_tmp = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    }
    const c = c_tmp;
    const x = x_tmp;

    const pf = new MCMaths.PartialFraction([top_x, top_c], [x, c], [x, c]);
    const uk = pf.unknowns();

    const k = uk.A / -x;
    const n = uk.B / x;
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(uk.A.toFixed(3)) === uk.A &&
      parseFloat(uk.B.toFixed(3)) === uk.B &&
      parseFloat(k.toFixed(3)) === k &&
      parseFloat(n.toFixed(3)) === n &&
      k % 1 === 0 &&
      uk.A % 1 === 0 &&
      uk.B % 1 === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Express ${pf.lhsToString()} in the form ${pf.rhsToString()}`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `b) Hence find $\\displaystyle\\int$${pf.lhsToString()}$dx$ in the form $\\frac{k}{${c}+${x}x}+n\\ln(${c}+${x}x)+c$`
        )
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) Express ${pf.lhsToString()} in the form ${pf.rhsToString()}`
      );
      question.addMultipleParagraphs(`solution`, pf.working());
      // b
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `b) Hence find $\\displaystyle\\int$${pf.lhsToString()}$dx$ in the form $\\frac{k}{${c}+${x}x}+n\\ln(${c}+${x}x)+c$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle\\int$${pf.lhsToString()}$dx=\\displaystyle\\int$${pf
            .rhsToString()
            .replace(`A`, `(${uk.A})`)
            .replace(`B`, `(${uk.B})`)}$dx$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\displaystyle\\int ${uk.A}(${c}+${x}x)^{-2}+\\frac{(${uk.B})}{${c}+${x}x}dx$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=\\frac{${k}}{${c}+${x}x}+${n}\\ln(${c}+${x}x)+c$`)
      );
      //
      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(uk.A, 0, `a) $\\ A=$`);
      g1.addInput(uk.B, 0, `a) $\\ B=$`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(k, 0, `b) $\\ k=$`);
      g2.addInput(n, 0, `b) $\\ n=$`);
      question.addInputGroup(g2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationOfPartialFractions_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const c = MCRandom.randomInt(1, 4);
    const x = MCRandom.randomInt(1, 4);

    const x2 = x ** 2;
    const c2 = c ** 2;

    const pf = new MCMaths.PartialFraction([x2, 0, c2], [x, c], [-x, c]);
    const uk = pf.unknowns();

    const uppper_l = MCRandom.randomInt(-8, 8);
    const lower_l = uppper_l - MCRandom.randomInt(1, 3);

    const ln2 = (x * lower_l + c) / (c - x * lower_l);

    const ans_ln = (x * uppper_l + c) / (ln2 * (c - x * uppper_l));
    const ans_c = uk.A * uppper_l - uk.A * lower_l;
    /* END VARIABLES AND CONSTANTS */
    if (
      ln2 > 0 &&
      parseFloat(ln2.toFixed(3)) === ln2 &&
      Number.isFinite(ln2) &&
      lower_l !== 0 &&
      uppper_l !== 0 &&
      ans_ln > 0 &&
      parseFloat(ans_ln.toFixed(3)) === ans_ln &&
      Number.isFinite(ans_ln) &&
      (-c / x < lower_l || -c / x > uppper_l) &&
      (c / x < lower_l || c / x > uppper_l) &&
      ln2 % 1 === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$f(x)=\\frac{${c2}+${x2}x^2}{${c2}+${-x2}x^2}$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `a) Given that $f(x)\\equiv$ ${pf.rhsToString()}, find the values of $A,B$ and $C$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `It is also given that $\\displaystyle\\int\\limits_{${lower_l}}^{k}f(x)dx=${ans_c}+${
            uk.B / x
          }\\ln(n)$`
        )
      );
      question.addParagraph("question", MCMaths.cleaner(`b) Find $k$ and $n$`));
      // solution
      // a
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `a) Given that $f(x)\\equiv$ ${pf.rhsToString()}, find the values of $A,B$ and $C$`
        )
      );
      question.addMultipleParagraphs("solution", pf.working());
      // b
      question.addHeading("solution", MCMaths.cleaner(`b) Find $k$ and $n$ `));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle\\int\\limits_{${lower_l}}^{k}f(x)dx=\\displaystyle\\int\\limits_{${lower_l}}^{k}\\frac{${c2}+${x2}x^2}{${c2}+${-x2}x^2}dx$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\displaystyle\\int\\limits_{${lower_l}}^{k}$${pf
            .rhsToString()
            .replace("A", `(${uk.A})`)
            .replace("B", `(${uk.B})`)
            .replace("C", `(${uk.C})`)}$dx$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=[${uk.A}x+${uk.B / x}\\ln(${x}x+${c})+${
            -uk.B / x
          }\\ln(${c}+${-x}x)]^{^{k}}_{_{${lower_l}}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=[${uk.A}x+${
            uk.B / x
          }\\ln(\\frac{${x}x+${c}}{${c}+${-x}x})]^{^{k}}_{_{${lower_l}}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=[${uk.A}k+${uk.B / x}\\ln(\\frac{${x}k+${c}}{${c}+${-x}k})]-[${
            uk.A
          }(${lower_l})+${
            uk.B / x
          }\\ln(\\frac{${x}(${lower_l})+${c}}{${c}+${-x}(${lower_l})})]$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=[${uk.A}k+${uk.B / x}\\ln(\\frac{${x}k+${c}}{${c}+${-x}k})]-[${
            uk.A * lower_l
          }+${uk.B / x}\\ln(${ln2})]$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=(${uk.A}k+${-uk.A * lower_l})+${
            uk.B / x
          }\\ln(\\frac{${x}k+${c}}{${ln2}(${c}+${-x}k)})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${ans_c}+${uk.B / x}\\ln(n)$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${uk.A}k+${-uk.A * lower_l}=${ans_c}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore k=${uppper_l}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$n=\\frac{${x}(${uppper_l})+${c}}{${ln2}(${c}+${-x}(${uppper_l}))}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore n=${ans_ln}$`)
      );

      //
      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(uk.A, 0, `a) $\\ A=$`);
      g1.addInput(uk.B, 0, `a) $\\ B=$`);
      g1.addInput(uk.C, 0, `a) $\\ C=$`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(uppper_l, 0, `b) $\\ k=$`);
      g2.addInput(ans_ln, 0, `b) $\\ n=$`);
      question.addInputGroup(g2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationOfPartialFractions_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const top_c = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);

    const c1 = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);

    const c2 = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);

    const c3 = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);

    const pf = new MCMaths.PartialFraction([top_c], [1, c1], [1, c2], [1, c3]);
    const uk = pf.unknowns();

    const upper_l = MCRandom.randomInt(-4, 4);
    const lower_l = upper_l - MCRandom.randomInt(1, 3);
    const k =
      ((upper_l + c1) ** uk.A *
        (upper_l + c2) ** uk.B *
        (upper_l + c3) ** uk.C) /
      (lower_l + c1) ** uk.A /
      (lower_l + c2) ** uk.B /
      (lower_l + c3) ** uk.C;
    /* END VARIABLES AND CONSTANTS */
    if (
      c1 !== c2 &&
      c1 !== c3 &&
      c2 !== c3 &&
      (-c1 < lower_l || -c1 > upper_l) &&
      (-c2 < lower_l || -c2 > upper_l) &&
      (-c3 < lower_l || -c3 > upper_l) &&
      uk.A % 1 === 0 &&
      uk.B % 1 === 0 &&
      uk.C % 1 === 0 &&
      c1 + upper_l > 0 &&
      c2 + upper_l > 0 &&
      c3 + upper_l > 0 &&
      c1 + lower_l > 0 &&
      c2 + lower_l > 0 &&
      c3 + lower_l > 0 &&
      Math.abs(k) < 100 &&
      Math.abs(uk.A) < 4 &&
      Math.abs(uk.B) < 4 &&
      Math.abs(uk.C) < 4 &&
      parseFloat(MCMaths.cleaner(`${k}`).match(/\d+/g)[0]) < 100
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `a) Express ${pf.lhsToString()} in partial fractions, giving your answer in the form ${pf.rhsToString()}`
      );
      question.addParagraph(
        `question`,
        MCMaths.cleaner(
          `b) Hence find the exact value of $\\displaystyle\\int\\limits_{${lower_l}}^{${upper_l}}\\frac{${top_c}x+${
            top_c * c3
          }}{(x+${c1})(x+${c2})(x+${c3})^2}dx$, giving your answer in the form $\\ln(k)$`
        )
      );
      // solution
      // a
      question.addHeading(
        `solution`,
        `a) Express ${pf.lhsToString()} in partial fractions, giving your answer in the form ${pf.rhsToString()}`
      );
      question.addMultipleParagraphs("solution", pf.working());
      // b
      question.addHeading(
        `solution`,
        MCMaths.cleaner(
          `b) Hence find the exact value of $\\displaystyle\\int\\limits_{${lower_l}}^{${upper_l}}\\frac{${top_c}x+${
            top_c * c3
          }}{(x+${c1})(x+${c2})(x+${c3})^2}dx$, giving your answer in the form $\\ln(k)$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$\\displaystyle\\int\\limits_{${lower_l}}^{${upper_l}}\\frac{${top_c}x+${
            top_c * c3
          }}{(x+${c1})(x+${c2})(x+${c3})^2}dx=\\displaystyle\\int\\limits_{${lower_l}}^{${upper_l}}\\frac{${top_c}}{(x+${c1})(x+${c2})(x+${c3})}dx$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$=\\displaystyle\\int\\limits_{${lower_l}}^{${upper_l}}$ ${pf
            .rhsToString()
            .replace("A", `(${uk.A})`)
            .replace("B", `(${uk.B})`)
            .replace("C", `(${uk.C})`)} $dx$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$=\\displaystyle\\int\\limits_{${lower_l}}^{${upper_l}}${uk.A}\\ln(x+${c1})+${uk.B}\\ln(x+${c2})+${uk.C}\\ln(x+${c3})dx$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$=[${uk.A}\\ln(x+${c1})+${uk.B}\\ln(x+${c2})+${uk.C}\\ln(x+${c3})]^{^{${upper_l}}}_{_{${lower_l}}}$`
        )
      );
      // question.addParagraph(`solution`, MCMaths.cleaner(`$=[${uk.A}\\ln(${upper_l}+${c1})+${uk.B}\\ln(${upper_l}+${c2})+${uk.C}\\ln(${upper_l}+${c3})]-[${uk.A}\\ln(${lower_l}+${c1})+${uk.B}\\ln(${lower_l}+${c2})+${uk.C}\\ln(${lower_l}+${c3})]$`));
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$=[${uk.A}\\ln(${upper_l + c1})+${uk.B}\\ln(${upper_l + c2})+${
            uk.C
          }\\ln(${upper_l + c3})]-[${uk.A}\\ln(${lower_l + c1})+${uk.B}\\ln(${
            lower_l + c2
          })+${uk.C}\\ln(${lower_l + c3})]$`
        )
      );
      question.addParagraph(`solution`, MCMaths.cleaner(`$=\\ln(${k})$`));

      //
      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(uk.A, 0, `a) $\\ A=$`);
      g1.addInput(uk.B, 0, `a) $\\ B=$`);
      g1.addInput(uk.C, 0, `a) $\\ C=$`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(k, 0, `b) $\\ k=$`);
      question.addInputGroup(g2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationOfPartialFractions_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    let m1_t = MCRandom.randomInt(1, 2);
    let c1_t = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    let m2_t = MCRandom.randomInt(1, 2);
    let c2_t = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    let m3_t = MCRandom.randomInt(1, 2);
    let c3_t = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    let falsec1_t = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);
    let falsec2_t = MCRandom.randomInt(1, 12);

    let top_mt = falsec1_t * m3_t + falsec2_t * m1_t;
    let top_ct = falsec1_t * c3_t + falsec2_t * c1_t;

    while (
      Math.abs(top_mt / m1_t) === Math.abs(top_ct / c1_t) ||
      Math.abs(top_mt / m2_t) === Math.abs(top_ct / c2_t) ||
      Math.abs(top_mt / m3_t) === Math.abs(top_ct / c3_t) ||
      Math.abs(m1_t / m2_t) === Math.abs(c1_t / c2_t) ||
      Math.abs(m1_t / m3_t) === Math.abs(c1_t / c3_t) ||
      Math.abs(m2_t / m3_t) === Math.abs(c2_t / c3_t)
    ) {
      m1_t = MCRandom.randomInt(1, 2);
      c1_t = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

      m2_t = MCRandom.randomInt(1, 2);
      c2_t = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

      m3_t = MCRandom.randomInt(1, 2);
      c3_t = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

      falsec1_t = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
      falsec2_t = MCRandom.randomInt(1, 4);

      top_mt = falsec1_t * m3_t + falsec2_t * m1_t;
      top_ct = falsec1_t * c3_t + falsec2_t * c1_t;
    }

    const m1 = m1_t;
    const c1 = c1_t;
    const m2 = m2_t;
    const c2 = c2_t;
    const m3 = m3_t;
    const c3 = c3_t;
    const falsec1 = falsec1_t;
    const falsec2 = falsec2_t;
    const top_m = falsec1 * m3 + falsec2 * m1;
    const top_c = falsec1 * c3 + falsec2 * c1;

    const pf = new MCMaths.PartialFraction(
      [top_m, top_c],
      [m1, c1],
      [m2, c2],
      [m3, c3]
    );
    const uk = pf.unknowns();

    const k = uk.A / m1;
    const m = uk.B / m2;
    const n = uk.C / m3;

    /* END VARIABLES AND CONSTANTS */
    if (
      top_m !== 0 &&
      top_c !== 0 &&
      Math.abs(top_m) < 15 &&
      Math.abs(top_c) < 40 &&
      (uk.A * 100) % 1 === 0 &&
      (uk.B * 100) % 1 === 0 &&
      (uk.C * 100) % 1 === 0 &&
      (m1 !== 1 || m2 !== 1 || m3 !== 1) &&
      (m1 !== 2 || m2 !== 2 || m3 !== 2)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question.addParagraph(`question`, MCMaths.cleaner(`$\\frac{${falsec1}}{(${m1}x+${c1})(${m2}x+${c2})}+\\frac{${falsec2}}{(${m2}x+${c2})(${m3}x+${c3})}$`));
      // question.addParagraph(`question`, MCMaths.cleaner(`${pf.lhsToString()}`));
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$f(x)=$${pf.lhsToString()}$\\equiv$${pf.rhsToString()}`
        )
      );
      question.addParagraph(
        "question",
        `a) Find the values of the constants $A,B$ and $C$`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `b) Hence find $\\displaystyle\\int \\frac{${falsec1}}{(${m1}x+${c1})(${m2}x+${c2})}+\\frac{${falsec2}}{(${m2}x+${c2})(${m3}x+${c3})} dx$, giving your answer in the form $k\\ln(${m1}x+${c1})+m\\ln(${m2}x+${c2})+n\\ln(${m3}x+${c3})+c$`
        )
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) Find the values of the constants $A,B$ and $C$`
      );
      question.addParagraph(
        "solution",
        `${pf.lhsToString()}$\\equiv$${pf.rhsToString()}`
      );
      question.addMultipleParagraphs("solution", pf.working());

      // b
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `b) Hence find $\\displaystyle\\int \\frac{${falsec1}}{(${m1}x+${c1})(${m2}x+${c2})}+\\frac{${falsec2}}{(${m2}x+${c2})(${m3}x+${c3})} dx$, giving your answer in the form $k\\ln(${m1}x+${c1})+m\\ln(${m2}x+${c2})+n\\ln(${m3}x+${c3})+c$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle\\int \\frac{${falsec1}}{(${m1}x+${c1})(${m2}x+${c2})}+\\frac{${falsec2}}{(${m2}x+${c2})(${m3}x+${c3})}dx=\\displaystyle\\int \\frac{${
            falsec1 * m3
          }x+${
            falsec1 * c3
          }}{(${m1}x+${c1})(${m2}x+${c2})(${m3}x+${c3})}+\\frac{${
            falsec2 * m1
          }x+${falsec2 * c1}}{(${m1}x+${c1})(${m2}x+${c2})(${m3}x+${c3})}dx$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=\\displaystyle\\int$${pf.lhsToString()}$dx$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\displaystyle\\int$${pf
            .rhsToString()
            .replace("A", `(${uk.A})`)
            .replace("B", `(${uk.B})`)
            .replace("C", `(${uk.C})`)}$dx$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=${k}\\ln(${m1}x+${c1})+${m}\\ln(${m2}x+${c2})+${n}\\ln(${m3}x+${c3})+c$`
        )
      );

      //

      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(uk.A, 0, `a) $\\ A=$`);
      g1.addInput(uk.B, 0, `a) $\\ B=$`);
      g1.addInput(uk.C, 0, `a) $\\ C=$`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(3);
      g2.addInput(k, 0, `b) $\\ k=$`);
      g2.addInput(m, 0, `b) $\\ m=$`);
      g2.addInput(n, 0, `b) $\\ n=$`);
      question.addInputGroup(g2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationOfPartialFractions_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const multiple = MCRandom.randomInt(2, 4) * MCRandom.randomInt(-1, 1, 2);

    let top_m1_t = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);
    let top_c1_t = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

    let top_m2_t = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);
    let top_c2_t = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

    let bot_m1_t = MCRandom.randomInt(1, 2);
    let bot_c1_t = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

    let bot_m2_t = MCRandom.randomInt(1, 2);
    let bot_c2_t = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

    while (
      Math.abs(top_m1_t / top_m2_t) === Math.abs(top_c1_t / top_c2_t) ||
      Math.abs(top_m1_t / bot_m1_t) === Math.abs(top_c1_t / bot_c1_t) ||
      Math.abs(top_m1_t / bot_m2_t) === Math.abs(top_c1_t / bot_c2_t) ||
      Math.abs(top_m2_t / bot_m1_t) === Math.abs(top_c2_t / bot_c1_t) ||
      Math.abs(top_m2_t / bot_m2_t) === Math.abs(top_c2_t / bot_c2_t) ||
      Math.abs(bot_m2_t / bot_m1_t) === Math.abs(bot_c2_t / bot_c1_t)
    ) {
      top_m1_t = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);
      top_c1_t = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

      top_m2_t = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);
      top_c2_t = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

      bot_m1_t = MCRandom.randomInt(1, 2);
      bot_c1_t = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

      bot_m2_t = MCRandom.randomInt(1, 2);
      bot_c2_t = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);
    }

    const top_m1 = top_m1_t;
    const top_c1 = top_c1_t;

    const top_m2 = top_m2_t;
    const top_c2 = top_c2_t;

    const bot_m1 = bot_m1_t;
    const bot_c1 = bot_c1_t;

    const bot_m2 = bot_m2_t;
    const bot_c2 = bot_c2_t;

    const x2_coeff = top_m1 * top_m2;
    const x_coeff = top_m1 * top_c2 + top_m2 * top_c1;
    const c = top_c1 * top_c2;

    const pf = new MCMaths.PartialFraction(
      [x2_coeff, x_coeff, c],
      [bot_m1, bot_c1],
      [bot_m2, bot_c2],
      [bot_m2, bot_c2]
    );
    const pf2 = new MCMaths.PartialFraction(
      [multiple * x2_coeff, multiple * x_coeff, multiple * c],
      [bot_m1, bot_c1],
      [bot_m2, bot_c2],
      [bot_m2, bot_c2]
    );
    const uk = pf.unknowns();

    const upper_l = MCRandom.randomInt(-4, 4);
    const lower_l = upper_l - MCRandom.randomInt(1, 3);

    const k = -uk.A / bot_m2;
    const m = uk.B / bot_m2;
    const n = uk.C / bot_m1;

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(uk.A) < 4 &&
      Math.abs(uk.B) < 4 &&
      Math.abs(uk.C) < 4 &&
      Math.abs(c) < 40 &&
      Math.abs(x_coeff) < 20 &&
      (uk.A * 40) % 1 === 0 &&
      (uk.B * 40) % 1 === 0 &&
      (uk.C * 40) % 1 === 0 &&
      Math.abs(uk.A) < 10 &&
      Math.abs(uk.B) < 10 &&
      Math.abs(uk.C) < 10 &&
      uk.A !== 0 &&
      uk.B !== 0 &&
      uk.C !== 0 &&
      k % 1 === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(`question`, `Given  that ${pf.toString()}`);
      question.addParagraph(`question`, `a) Find the values of $A,B$ and $C$`);
      question.addParagraph(
        `question`,
        MCMaths.cleaner(
          `b) Hence, find $\\displaystyle\\int$ ${pf2.lhsToString()} $dx$, giving your answer in the form $\\frac{k}{${bot_m2}x+${bot_c2}}+m\\ln(${bot_m2}x+${bot_c2})+n\\ln(${bot_m1}x+${bot_c1})+c$`
        )
      );
      // solution
      // a
      question.addHeading(
        `solution`,
        MCMaths.cleaner(`a) Find the values of $A,B$ and $C$`)
      );
      question.addMultipleParagraphs("solution", pf.working());
      // b
      question.addHeading(
        `solution`,
        MCMaths.cleaner(
          `b) Hence, find $\\displaystyle\\int$ ${pf2.lhsToString()} $dx$, giving your answer in the form $\\frac{k}{${bot_m2}x+${bot_c2}}+m\\ln(${bot_m2}x+${bot_c2})+n\\ln(${bot_m1}x+${bot_c1})+c$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$\\displaystyle\\int\\limits$${pf2.lhsToString()}$dx=${multiple}\\displaystyle\\int$${pf.lhsToString()}$dx$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$=${multiple}\\displaystyle\\int$${pf
            .rhsToString()
            .replace("A", `(${uk.A})`)
            .replace("B", `(${uk.B})`)
            .replace("C", `(${uk.C})`)}$dx$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$=${multiple}[\\frac{${k}}{${bot_m2}x+${bot_c2}}+${m}\\ln(${bot_m2}x+${bot_c2})+${n}\\ln(${bot_m1}x+${bot_c1})]+c$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$=\\frac{${multiple * k}}{${bot_m2}x+${bot_c2}}+${
            multiple * m
          }\\ln(${bot_m2}x+${bot_c2})+${
            multiple * n
          }\\ln(${bot_m1}x+${bot_c1})+c$`
        )
      );

      //
      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(uk.A, 0, `a) $\\ A=$`);
      g1.addInput(uk.B, 0, `a) $\\ B=$`);
      g1.addInput(uk.C, 0, `a) $\\ C=$`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(3);
      g2.addInput(multiple * k, 0, `b) $\\ k=$`);
      g2.addInput(multiple * m, 0, `b) $\\ m=$`);
      g2.addInput(multiple * n, 0, `b) $\\ n=$`);
      question.addInputGroup(g2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Integration_IntegrationOfPartialFractions_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const top_c = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const bot_xcoeff = MCRandom.randomInt(1, 4);

    const root_xcoeff = MCRandom.randomInt(1, 3);
    const root_c = MCRandom.randomInt(1, 8);

    const t1 = bot_xcoeff / root_xcoeff;
    const t2 = 1 / (root_xcoeff / 2);

    const poly = new MCMaths.Polynomial([t1, 1, -root_c * t1]);
    const roots = poly.roots();

    let tmp = 0;
    let index = 0;
    if (roots[0] % 1 !== 0) {
      [tmp] = roots;
      index = 0;
    } else {
      [tmp] = roots;
      index = 1;
    }
    const pf = new MCMaths.PartialFraction(
      [top_c * t2, 0],
      [t1, -roots[index] * t1],
      [1, -tmp]
    );
    const uk = pf.unknowns();

    /* END VARIABLES AND CONSTANTS */
    if (
      t1 !== 1 &&
      t1 !== -1 &&
      t2 % 1 === 0 &&
      t1 % 1 === 0 &&
      roots[0] !== "No Real Roots" &&
      (roots[0] % 1 === 0 || roots[0] % (1 / t1) === 0) &&
      (roots[1] % 1 === 0 || roots[1] % (1 / t1) === 0) &&
      parseFloat(MCMaths.cleaner(`${uk.A}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${uk.B}`).match(/\d+/g)[0]) < 100
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `a) Express ${pf.lhsToString()} in the form ${pf.rhsToString()}`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `b) Show that the substitution $u=\\sqrt{${root_xcoeff}x+${root_c}}$ transforms $\\displaystyle\\int\\frac{${top_c}}{${bot_xcoeff}x+\\sqrt{${root_xcoeff}x+${root_c}}}dx$ into $\\displaystyle\\int\\frac{${
            top_c * t2
          }u}{(${t1}u+${-roots[index] * t1})(u+${-tmp})}du$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `c) Hence find $\\displaystyle\\int\\frac{${top_c}}{${bot_xcoeff}x+\\sqrt{${root_xcoeff}x+${root_c}}}dx$, giving your answer in the form $k\\ln(${t1}u+${
            -roots[index] * t1
          })+m\\ln(u+${-tmp})+c$`
        )
      );

      // solution
      // a
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `a) Express ${pf.lhsToString()} in the form ${pf.rhsToString()}`
        )
      );
      question.addMultipleParagraphs("solution", pf.working());
      // b
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `b) Show that the substitution $u=\\sqrt{${root_xcoeff}x+${root_c}}$ transforms $\\displaystyle\\int\\frac{${top_c}}{${bot_xcoeff}x+\\sqrt{${root_xcoeff}x+${root_c}}}dx$ into $\\displaystyle\\int\\frac{${
            top_c * t2
          }u}{(${t1}u+${-roots[index] * t1})(u+${-tmp})}du$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u=\\sqrt{${root_xcoeff}x+${root_c}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u^2+${-root_c}=${root_xcoeff}x$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore ${t1}(u^2+${-root_c})=${bot_xcoeff}x$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{du}{dx}=${
            root_xcoeff / 2
          }(${root_xcoeff}x+${root_c})^{-0.5}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=\\frac{1}{u}(${root_xcoeff / 2})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore dx=${t2}u\\ dt$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle\\int\\frac{${top_c}}{${bot_xcoeff}x+\\sqrt{${root_xcoeff}x+${root_c}}}dx$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\displaystyle\\int\\frac{${top_c}}{${t1}(u^2+${-root_c})+u}\\times ${t2}u\\ du$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\displaystyle\\int\\frac{${top_c * t2}u}{${t1}u^2+u+${
            -root_c * t1
          }}du$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\displaystyle\\int\\frac{${top_c * t2}u}{(${t1}u+${
            -roots[index] * t1
          })(u+${-tmp})}du$`
        )
      );
      // c
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `c) Hence find $\\displaystyle\\int\\frac{${top_c}}{${bot_xcoeff}x+\\sqrt{${root_xcoeff}x+${root_c}}}dx$, giving your answer in the form $k\\ln(${t1}u+${
            -roots[index] * t1
          })+m\\ln(u+${-tmp})+c$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\displaystyle\\int\\frac{${top_c * t2}u}{(${t1}u+${
            -roots[index] * t1
          })(u+${-tmp})}du$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\displaystyle\\int$${pf
            .rhsToString()
            .replace("A", `(${uk.A})`)
            .replace("B", `(${uk.B})`)
            .replace(/x/g, "u")}$du$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=${uk.A / t1}\\ln(${t1}u+${-roots[index] * t1})+${
            uk.B
          }\\ln(u+${-tmp})+c$`
        )
      );
      //
      question.requiresSelfMarking();
      //
      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(uk.A, 0, `a) $\\ A=$`);
      g1.addInput(uk.B, 0, `a) $\\ B=$`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(uk.A / t1, 0, `b) $\\ k=$`);
      g2.addInput(uk.B, 0, `b) $\\ m=$`);
      question.addInputGroup(g2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Moments_MomentsProblems_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const lengthPC = MCRandom.randomInt(10, 30) / 10;
    const lengthCQ = MCRandom.randomInt(10, 30) / 10;
    const pMass = MCRandom.randomInt(2, 6);

    const correctAnswer = (lengthPC * pMass) / lengthCQ;
    const incorrectAnswer1 = pMass / lengthCQ;
    const incorrectAnswer2 = lengthPC / lengthCQ;
    const incorrectAnswer3 = (lengthPC * pMass * 9.8) / lengthCQ;
    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (lengthPC > lengthCQ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        `A uniform beam with a smooth support directly under the centre of mass of the beam, $C$, has two weights at points $P$ and $Q$ as shown in the diagram below:`
      );
      question.addParagraph(`$PC = ${lengthPC}m$.`);
      question.addParagraph(`$CQ = ${lengthCQ}m$.`);
      question.addParagraph(`The weight at $P$ has mass $${pMass}kg$.`);
      question.addParagraph(`The weight at $Q$ has mass $m$.`);

      const myImage = new MCQuestion.Image(
        `Matt/mcqMomentDiagram1.svg`,
        "width",
        25
      );
      myImage.addOverlay(`$P$`, 16, 35);
      myImage.addOverlay(`$C$`, 49, 46);
      myImage.addOverlay(`$Q$`, 68, 35);
      question.addImage(myImage);

      question.addParagraph(`Given that the system is in equilibrium,`);
      question.addParagraph(
        `Pick the correct mass, $m$, for the mass at point $Q$:`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$m = ${correctAnswer.toPrecision(3)}kg$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$m = ${incorrectAnswer1.toPrecision(3)}kg$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$m = ${incorrectAnswer2.toPrecision(3)}kg$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$m = ${incorrectAnswer3.toPrecision(3)}kg$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Moments_MomentsProblems_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const lengthPC = MCRandom.randomInt(10, 30) / 10;

    const pMass = MCRandom.randomInt(2, 6);
    const qMass = MCRandom.randomInt(2, 6);

    const correctAnswer = (lengthPC * pMass) / qMass;
    const incorrectAnswer1 = pMass / qMass;
    const incorrectAnswer2 = lengthPC / qMass;
    const incorrectAnswer3 = (lengthPC * pMass * 9.8) / qMass;
    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        `A uniform beam with a smooth support directly under the centre of mass of the beam, $C$, has two weights at points $P$ and $Q$ as shown in the diagram below:`
      );
      question.addParagraph(`$PC = ${lengthPC}m$.`);
      question.addParagraph(`$CQ = x$.`);
      question.addParagraph(`The weight at $P$ has mass $${pMass}kg$.`);
      question.addParagraph(`The weight at $Q$ has mass $${qMass}kg$.`);

      const myImage = new MCQuestion.Image(
        `Matt/mcqMomentDiagram1.svg`,
        "width",
        25
      );
      myImage.addOverlay(`$P$`, 16, 35);
      myImage.addOverlay(`$C$`, 49, 46);
      myImage.addOverlay(`$Q$`, 68, 35);
      question.addImage(myImage);

      question.addParagraph(`Given that the system is in equilibrium,`);
      question.addParagraph(
        `Pick the correct distance, $x$, for the mass at point $Q$:`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$x = ${correctAnswer.toPrecision(3)}m$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$x = ${incorrectAnswer1.toPrecision(3)}m$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$x = ${incorrectAnswer2.toPrecision(3)}m$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$x = ${incorrectAnswer3.toPrecision(3)}m$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Moments_MomentsProblems_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const beamLength = MCRandom.randomInt(3, 9);
    const lengthPS = (MCRandom.randomInt(1, 3) / 10) * beamLength;

    const pMass = MCRandom.randomInt(2, 6);

    const lengthSC = beamLength / 2 - lengthPS;

    const correctAnswer = (lengthPS * pMass) / lengthSC;
    const incorrectAnswer1 = pMass / lengthSC;
    const incorrectAnswer2 = lengthPS / lengthSC;
    const incorrectAnswer3 = (lengthPS * pMass * 9.8) / lengthSC;
    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        `A uniform beam of mass $m$ and length $${beamLength}m$ with a smooth support at point $S$ has a weight at points $P$ as shown in the diagram below:`
      );
      question.addParagraph(`$PS = ${lengthPS.toFixed(1)}m$.`);
      question.addParagraph(`The weight at $P$ has mass $${pMass}kg$.`);

      const myImage = new MCQuestion.Image(
        `Matt/mcqMomentDiagram2.svg`,
        "width",
        25
      );
      myImage.addOverlay(`$P$`, 7, 36);
      myImage.addOverlay(`$S$`, 32, 46);
      question.addImage(myImage);

      question.addParagraph(`Given that the system is in equilibrium,`);
      question.addParagraph(`Pick the correct mass, $m$, of the beam:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$m = ${correctAnswer.toPrecision(3)}kg$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$m = ${incorrectAnswer1.toPrecision(3)}kg$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$m = ${incorrectAnswer2.toPrecision(3)}kg$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$m = ${incorrectAnswer3.toPrecision(3)}kg$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Moments_MomentsProblems_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const beamLength = MCRandom.randomInt(3, 9);
    const lengthPS = (MCRandom.randomInt(1, 3) / 10) * beamLength;

    const beamMass = MCRandom.randomInt(2, 10);

    const pMass = MCRandom.randomInt(2, 6);

    const correctAnswer = (lengthPS * pMass) / beamMass;
    const incorrectAnswer1 = pMass / beamMass;
    const incorrectAnswer2 = lengthPS / beamMass;
    const incorrectAnswer3 = (lengthPS * pMass * 9.8) / beamMass;
    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        `A non-uniform beam of mass $${beamMass}kg$ has a centre of mass at the point $C$, with a smooth support at point $S$ has a weight at points $P$ as shown in the diagram below:`
      );
      question.addParagraph(`$PS = ${lengthPS.toFixed(1)}m$.`);
      question.addParagraph(`The weight at $P$ has mass $${pMass}kg$.`);

      const myImage = new MCQuestion.Image(
        `Matt/mcqMomentDiagram2.svg`,
        "width",
        25
      );
      myImage.addOverlay(`$P$`, 7, 36);
      myImage.addOverlay(`$S$`, 32, 46);
      question.addImage(myImage);

      question.addParagraph(`Given that the system is in equilibrium,`);
      question.addParagraph(`Pick the correct length for the distance $SC$:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$SC = ${correctAnswer.toPrecision(3)}m$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$SC = ${incorrectAnswer1.toPrecision(3)}m$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$SC = ${incorrectAnswer2.toPrecision(3)}m$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$SC = ${incorrectAnswer3.toPrecision(3)}m$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Moments_MomentsProblems_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const boxMass = MCRandom.randomInt(30, 50) / 10;
    const beam = {
      mass: MCRandom.randomInt(2, 5),
      length: MCRandom.randomInt(7, 15),
    };
    const lengthAB = (MCRandom.randomInt(25, 35) / 100) * beam.length;
    const lengthBC = (MCRandom.randomInt(4, 6) / 10) * beam.length;

    const tensionT_2 = ((lengthAB + lengthBC) * (boxMass * 9.8)) / lengthAB;
    const wrongAnswer1 = ((lengthAB + lengthBC) * boxMass) / lengthAB;
    const wrongAnswer2 = (lengthAB + lengthBC) * (boxMass * 9.8);
    const wrongAnswer3 = (lengthBC * (boxMass * 9.8)) / lengthAB;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `The diagram below shows a box of mass $${boxMass}kg$ being held on a non-uniform beam where the centre of mass is at the point $B$.`,
        `The beam is being held up by two inextensible ropes at points $A$ and $B$, where they are under a tension of $T_1$ and $T_2$ respectively.`,
        `$AB = ${lengthAB.toFixed(1)}m$`,
        `$BC = ${lengthBC.toFixed(1)}m$`,
        `The system is in equilibrium.`,
      ]);

      const myImage = new MCQuestion.Image(
        `Matt/mcqMomentDiagram3.svg`,
        "width",
        30
      );
      myImage.addOverlay(`$T_1$`, 11, 25);
      myImage.addOverlay(`$T_2$`, 39.5, 25);
      myImage.addOverlay(`$${boxMass}kg$`, 88.5, 87.5);
      myImage.addOverlay(`$A$`, 16, 74);
      myImage.addOverlay(`$B$`, 34, 74);
      myImage.addOverlay(`$C$`, 88.5, 66);
      question.addImage(myImage);

      question.addMultipleParagraphs([
        `By taking moments at point $A$, choose the correct mass, $M$, for the box at $C$.`,
        `Assume that $g = 9.8ms^{-2}$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$T_2 = ${tensionT_2.toPrecision(3)}N$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$T_2 = ${wrongAnswer1.toPrecision(3)}N$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$T_2 = ${wrongAnswer2.toPrecision(3)}N$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$T_2 = ${wrongAnswer3.toPrecision(3)}N$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Moments_MomentsProblems_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const boxMass = MCRandom.randomInt(30, 50) / 10;
    const beam = {
      mass: MCRandom.randomInt(2, 5),
      length: MCRandom.randomInt(7, 15),
    };
    const lengthAB = (MCRandom.randomInt(25, 35) / 100) * beam.length;
    const lengthBC = (MCRandom.randomInt(4, 6) / 10) * beam.length;

    const tensionT_2 = ((lengthAB + lengthBC) * (boxMass * 9.8)) / lengthAB;

    // AB*98.6 = M*(AB+BC)
    // M = AB*T2/(AB+BC)*g
    const wrongAnswer1 = (lengthAB * tensionT_2) / (lengthAB + lengthBC);
    const wrongAnswer2 = tensionT_2 / ((lengthAB + lengthBC) * 9.8);
    const wrongAnswer3 = (lengthAB * tensionT_2) / (lengthBC * 9.8);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `The diagram below shows a box of mass $M$ being held on a non-uniform beam where the centre of mass is at the point $B$.`,
        `The beam is being held up by two inextensible ropes at points $A$ and $B$, where they are under a tension of $T_1$ and $${tensionT_2.toPrecision(
          3
        )}N$ respectively.`,
        `$AB = ${lengthAB.toFixed(1)}m$`,
        `$BC = ${lengthBC.toFixed(1)}m$`,
        `The system is in equilibrium.`,
      ]);

      const myImage = new MCQuestion.Image(
        `Matt/mcqMomentDiagram3.svg`,
        "width",
        30
      );
      myImage.addOverlay(`$T_1$`, 11, 25);
      myImage.addOverlay(`$${tensionT_2.toPrecision(3)}N$`, 43, 25);
      myImage.addOverlay(`$Mkg$`, 88.5, 87.5);
      myImage.addOverlay(`$A$`, 16, 74);
      myImage.addOverlay(`$B$`, 34, 74);
      myImage.addOverlay(`$C$`, 88.5, 66);
      question.addImage(myImage);

      question.addMultipleParagraphs([
        `By taking moments at point $A$, choose the correct tension, $T_2$, in the rope at $B$.`,
        `Assume that $g = 9.8ms^{-2}$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$M = ${boxMass.toPrecision(3)}kg$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$M = ${wrongAnswer1.toPrecision(3)}kg$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$M = ${wrongAnswer2.toPrecision(3)}kg$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$M = ${wrongAnswer3.toPrecision(3)}kg$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_InverseNormal_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const mean = MCRandom.randomInt(45, 55);
    const sd = MCRandom.randomInt(12, 35) / 10;

    const nrml = new MCMaths.Normal(mean, sd);
    const std_nrml = new MCMaths.Normal(0, 1);

    const v1 = MCRandom.randomInt(42, 58); // given as less than
    const pv1 = nrml.cdf(v1).toFixed(3);
    const v2 = MCRandom.randomInt(42, 58); // given as greater than
    const pv2 = (1 - nrml.cdf(v2)).toFixed(3);
    const pv2_l = (1 - parseFloat(pv2)).toFixed(3);

    const v3 = MCRandom.randomInt(42, 58);
    const pv3 = nrml.cdf(parseFloat(v3));

    const z1 = std_nrml.inverseNormal(parseFloat(pv1)).toFixed(3);
    const z2 = std_nrml.inverseNormal(parseFloat(pv2_l)).toFixed(3);

    const sigma =
      (parseFloat(v2) - parseFloat(v1)) / (parseFloat(z2) - parseFloat(z1));
    const u = parseFloat(v1) - parseFloat(z1) * parseFloat(sigma.toFixed(1));
    /* END VARIABLES AND CONSTANTS */
    if (
      mean !== v3 &&
      mean !== v1 &&
      mean !== v2 &&
      Math.abs(v2 - v1) > 1 &&
      Math.abs(v3 - v1) > 1 &&
      Math.abs(v3 - v2) > 1 &&
      parseFloat(pv1) > 0.1 &&
      parseFloat(pv1) < 0.9 &&
      parseFloat(pv2) > 0.1 &&
      parseFloat(pv2) < 0.9 &&
      Math.abs(parseFloat(pv1) - parseFloat(pv2)) > 0.01 &&
      Math.abs(parseFloat(pv1) - parseFloat(pv2_l)) > 0.01 &&
      parseFloat(u - mean) < 0.5 &&
      parseFloat(sigma - sd) < 0.05 &&
      parseFloat(pv3) > 0.1 &&
      parseFloat(pv3) < 0.9
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `The weight, $X$ grams of a packet of sweets is normally distibuted with mean $u$ and standard deviation $\\sigma$.`
      );
      question.addParagraph(
        `question`,
        `Given that $P(X<${v1})=${pv1}$ and $P(X>${v2})=${pv2}$`
      );
      question.addParagraph(
        `question`,
        `a) Find the values of $u$ and $\\sigma$, correct to two significant figures.`
      );
      question.addParagraph(
        `question`,
        `b) Find the probability that a randomly chosen packet weighs less than $${v3}$ grams.`
      );
      // a
      // question.addHeading(`solution`, `a) Find the values of $u$ and $\\sigma$, correct to two significant figures.`);
      question.addHeading("solution", `Using $P(X<${v1})=${pv1}$`);
      question.addParagraph("solution", `When $P(Z<z)=${pv1}$, $z=${z1}$`);
      question.addParagraph("solution", `$${z1}=\\frac{${v1}-u}{\\sigma}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore u=${v1}-${z1}\\sigma$`, false)
      );

      question.addHeading("solution", `Using $P(X>${v2})=${pv2}$`);
      question.addParagraph("solution", `$P(X<${v2})=1-${pv2}$`);
      question.addParagraph("solution", `$=${pv2_l}$`);
      question.addParagraph("solution", `When $P(Z<z)=${pv2_l}$, $z=${z2}$`);
      question.addParagraph("solution", `$${z2}=\\frac{${v2}-u}{\\sigma}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore u=${v2}-${z2}\\sigma$`, false)
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${v1}-${z1}\\sigma = ${v2}-${z2}\\sigma$`, false)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\sigma = \\frac{${v2}-${v1}}{${z2}-${z1}}$`, false)
      );
      question.addParagraph("solution", `$=${sigma.toFixed(5)}$`);
      question.addParagraph("solution", `$\\approx ${sigma.toFixed(1)}$`);

      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u=${v1}-${z1}(${sigma.toFixed(1)})$`, false)
      );
      question.addParagraph("solution", `$=${u.toFixed(3)}$`);
      question.addParagraph("solution", `$\\approx ${u.toFixed(0)}$`);

      // b
      // question.addHeading(`solution`, `b) Find the probability that a randomly chosen packet weighs less than $${v3}$ grams.`);
      question.addParagraph("solution", `$X \\sim ${nrml.toString()}$`);
      question.addParagraph("solution", `$P(X<${v3})=${pv3.toFixed(5)}$`);
      question.addParagraph("solution", `$\\approx ${pv3.toFixed(3)}$`);

      //

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(mean, 0.5, `a) $\\ u=$`);
      g1.addInput(sd, 0.05, `a) $\\ \\sigma=$`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(pv3, 0.01, `b)`);
      question.addInputGroup(g2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_InverseNormal_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const mean = MCRandom.randomInt(35, 55);
    const sd = MCRandom.randomInt(5, 10);

    const nrml = new MCMaths.Normal(mean, sd);
    const std_nrml = new MCMaths.Normal(0, 1);

    const v1 = MCRandom.randomInt(40, 60, 5);
    const pv1 = (1 - nrml.cdf(v1)).toFixed(3);
    const pv1_l = (1 - parseFloat(pv1)).toFixed(3);

    const z1 = std_nrml.inverseNormal(parseFloat(pv1_l)).toFixed(3);

    const a = parseFloat(z1) * sd + mean;

    const percentage = MCRandom.randomInt(10, 40);
    const z2 = std_nrml.inverseNormal(parseFloat(percentage / 100)).toFixed(3);
    const b = parseFloat(z2) * sd + mean;

    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(pv1) < 0.9 &&
      parseFloat(pv1) > 0.1 &&
      v1 !== mean &&
      parseFloat(a.toFixed(0)) === v1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The length, $L$ centimetres of scrap pieces of wood at a woodworking company can be assumed to be normally distributed with a mean of $${mean}$cm and a standard deviation of $${sd}$cm.`
      );
      question.addParagraph(
        "question",
        `a) Given that the probability of a scrap piece of wood being longer than $a$ cm is $${pv1}$, find $a$ to the nearset whole number of centimetres.`
      );
      question.addParagraph(
        "question",
        `The company change their policy on what counts as scrap wood to reduce waste. Now only pieces less than $b$ cm can be thrown into the scrap bin, which results in them producing $${
          100 - percentage
        }$% less scrap wood.`
      );
      question.addParagraph("question", `b) Find $b$.`);
      // solution
      // a
      // question.addHeading('solution', `a) Given that the probability of a scrap piece of wood being longer than $a$ cm is $${pv1}$, find $A$ to the nearset whole number of centimetres.`);
      question.addParagraph("solution", `$L \\sim ${nrml.toString()}$`);
      question.addParagraph("solution", `$P(L>a)=1-P(L<a)$`);
      question.addParagraph("solution", `$=1-${pv1}$`);
      question.addParagraph("solution", `$=${pv1_l}$`);
      question.addHeading("solution", `When $P(Z<z)=${pv1_l}$, $z=${z1}$`);
      question.addParagraph("solution", `$${z1}=\\frac{a-${mean}}{${sd}}$`);
      question.addParagraph("solution", `$a = ${z1}(${sd})+${mean}$`);
      if (a === parseFloat(a.toFixed(0))) {
        question.addParagraph("solution", `$=${a}$`);
      } else {
        question.addParagraph("solution", `$=${a.toFixed(3)}$`);
        question.addParagraph("solution", `$\\approx ${a.toFixed(0)}$`);
      }
      // b
      // question.addHeading('solution', `b) Find $b$.`);
      question.addParagraph(
        "solution",
        `$\\frac{100-${100 - percentage}}{100}=${percentage / 100}$`
      );
      question.addParagraph(
        `solution`,
        `$\\therefore P(L<b)=${percentage / 100}$`
      );
      question.addHeading(
        "solution",
        `When $P(Z<z)=${percentage / 100}$, $z=${z2}$`
      );
      question.addParagraph("solution", `$${z2}=\\frac{b-${mean}}{${sd}}$`);
      question.addParagraph("solution", `$b = ${z2}(${sd})+${mean}$`);
      if (a === parseFloat(a.toFixed(0))) {
        question.addParagraph("solution", `$=${b}$`);
      } else {
        question.addParagraph("solution", `$=${b.toFixed(3)}$`);
        question.addParagraph("solution", `$\\approx ${b.toFixed(0)}$`);
      }
      //

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(v1, 0, `a) $\\ a=$`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(Math.round(b), 0.5, `b) $\\ b=$`);
      question.addInputGroup(g2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_InverseNormal_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const person = MCMisc.getName();

    const mean = MCRandom.randomInt(35, 45);
    const sd = MCRandom.randomInt(50, 100) / 10;

    const nrml = new MCMaths.Normal(mean, sd);

    const std_nrml = new MCMaths.Normal(0, 1);

    const lower = MCRandom.randomInt(20, 40);
    const upper = MCRandom.randomInt(40, 60);

    const p_upper = nrml.cdf(upper).toFixed(3);
    const p_lower = nrml.cdf(lower).toFixed(3);
    const p = parseFloat(p_upper) - parseFloat(p_lower);

    const z = std_nrml.inverseNormal(parseFloat(p_upper)).toFixed(3);

    const d = parseFloat(z) * sd + mean;

    const bp = MCRandom.randomInt(20, 40) / 100;

    const zb = std_nrml.inverseNormal(bp).toFixed(3);

    const u = mean - zb * sd;

    /* END VARIABLES AND CONSTANTS */
    if (
      p > 0.2 &&
      p < 0.6 &&
      p_upper < 0.9 &&
      p_upper > 0.5 &&
      p_lower > 0.1 &&
      p_lower < 0.5 &&
      sd % 1 !== 0 &&
      parseFloat(d.toFixed(0)) === upper &&
      u > mean
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `${
          person.name
        } is conducting research on the amount of money people spend on a weekly shop in the U.K. ${
          person.HeShe
        } finds that the amount spent is normally distributed with a mean of $£${mean}$ and a standard deviation of $£${sd.toFixed(
          2
        )}$.`
      );
      question.addParagraph(
        "question",
        `From ${person.hisher} research ${
          person.heshe
        } states that the probability of someone spending more than $£${lower}$ but less than $£a$ is $${p.toFixed(
          3
        )}$.`
      );
      question.addParagraph(
        "question",
        `a) Find the value of $a$, to the nearest pence.`
      );
      question.addParagraph(
        "question",
        `During the winter, the mean amount of money spent increases, so that the probability of someone spending less than $£${mean}$ is $${bp.toFixed(
          2
        )}$. The standard deviation stays the same.`
      );
      question.addParagraph(
        "question",
        `b) Find the new mean amount of money spent.`
      );
      // solution
      // a
      // question.addHeading('solution', `a) Find the value of $a$, to the nearest pence.`);
      question.addHeading(
        "solution",
        `Let $X$ be the amount of money spent on a weekly shop.`
      );
      question.addParagraph("solution", `$X \\sim ${nrml.toString()}$`);
      question.addParagraph(
        "solution",
        `$P(${lower}<X<d)=P(X<d)-P(X<${lower})$`
      );
      question.addParagraph("solution", `$P(X<${lower})=${p_lower}$`);
      question.addParagraph("solution", `$P(X<d)-${p_lower}=${p.toFixed(3)}$`);
      question.addParagraph("solution", `$\\therefore P(X<d)=${p_upper}$`);

      question.addHeading("solution", `When $P(Z<z)=${p_upper}$, $z=${z}$`);
      question.addParagraph("solution", `$${z}=\\frac{d-${mean}}{${sd}}$`);
      question.addParagraph("solution", `$d = ${z}(${sd})+${mean}$`);
      if (d === parseFloat(d.toFixed(0))) {
        question.addParagraph("solution", `$=${d}$`);
      } else {
        question.addParagraph("solution", `$=${d.toFixed(3)}$`);
        question.addParagraph("solution", `$\\approx ${upper}$`);
      }
      // b
      // question.addHeading('solution',`b) Find the new mean amount of money spent.`);
      question.addHeading(
        "solution",
        `Let $Y$ be the amount of money spent on a weekly shop.`
      );
      question.addParagraph("solution", `$Y \\sim N(u, ${sd}^2)$`);
      question.addParagraph("solution", `$P(Y < ${mean})=${bp.toFixed(2)}$`);

      question.addHeading(
        "solution",
        `When $P(Z<z)=${bp.toFixed(2)}$, $z=${zb}$`
      );
      question.addParagraph("solution", `$${zb}=\\frac{${mean}-u}{${sd}}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u=${mean}-${zb}(${sd})$`, false)
      );
      if (u === parseFloat(u.toFixed(0))) {
        question.addParagraph("solution", `$=${u}$`);
      } else {
        question.addParagraph("solution", `$=${u.toFixed(4)}$`);
        question.addParagraph("solution", `$\\approx ${u.toFixed(2)}$`);
      }

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(upper, 0.5, `a) $\\ d=$`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(parseFloat(u.toFixed(2)), 0.1, `b) $\\ u=$`);
      question.addInputGroup(g2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_InverseNormal_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const mean = MCRandom.randomInt(250, 350);
    const sd = MCRandom.randomInt(20, 60);

    const nrml = new MCMaths.Normal(mean, sd);
    const std_nrml = new MCMaths.Normal(0, 1);

    const p1 = MCRandom.randomInt(65, 95) / 100;
    const p2 = (1 - p1) / 2;

    const z1 = std_nrml.inverseNormal(p2).toFixed(3);

    const a = -parseFloat(z1) * sd;

    const bp1 = MCRandom.randomInt(20, 40) / 100;
    const bp2 = 1 - bp1;
    const bz1 = std_nrml.inverseNormal(bp2).toFixed(3);

    const b = mean + parseFloat(bz1) * sd;
    const x = parseFloat(b.toFixed(0)) - mean;
    const c = mean - x;

    let fixed = 0;
    if ((p1 * 100) % 2 === 0) {
      fixed = 2;
    } else {
      fixed = 3;
    }

    /* END VARIABLES AND CONSTANTS */
    if (mean - a > 50) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A study found that the amount of caffeine consumed by an adult each day, $C$ mg can be modelled by a normal distribution, with a mean, $u$ of $${mean}$mg and a standard deviation, $\\sigma$ of $${sd}$mg.`
      );
      question.addParagraph(
        `question`,
        `Find the values of $a,b$ and $c$ such that:`
      );
      question.addParagraph(`question`, `a) $P(u-a<C<u+a)=${p1.toFixed(2)}$`);
      question.addParagraph(`question`, `b) $P(C>b)=${bp1.toFixed(2)}$`);
      question.addParagraph(`question`, `c) $P(C<c)=${bp1.toFixed(2)}$`);

      // solution
      // a

      question.addParagraph(
        "solution",
        `$\\frac{1-${p1.toFixed(2)}}{2}=${p2.toFixed(fixed)}$`
      );
      question.addParagraph(
        `solution`,
        `Normal curve is symetrical about the mean, so $P(C>u+a) = P(C<u-a)$`
      );
      question.addParagraph(
        `solution`,
        `$\\therefore P(C<u-a)=${p2.toFixed(fixed)}$`
      );

      question.addHeading(
        "solution",
        `When $P(Z<z)=${p2.toFixed(fixed)}$, $z=${z1}$`
      );
      question.addParagraph("solution", `$${z1}=\\frac{(u-a)-u}{${sd}}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore a=${-z1}(${sd})$`, false)
      );
      question.addParagraph("solution", `$=${a.toFixed(2)}$`);
      question.addParagraph("solution", `$\\approx ${a.toFixed(0)}$`);

      // b
      // question.addHeading(`solution`, `b) $P(C>b)=${bp1.toFixed(2)}$`);
      question.addParagraph(`solution`, `$P(C<b)=1-P(C>b)$`);
      question.addParagraph(`solution`, `$=1-${bp1.toFixed(2)}$`);
      question.addParagraph(`solution`, `$=${bp2.toFixed(2)}$`);

      question.addHeading(
        "solution",
        `When $P(Z<z)=${bp2.toFixed(2)}$, $z=${bz1}$`
      );
      question.addParagraph("solution", `$${bz1}=\\frac{b-${mean}}{${sd}}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore b=${bz1}(${sd})+${mean}$`, false)
      );
      question.addParagraph("solution", `$=${b.toFixed(2)}$`);
      question.addParagraph("solution", `$\\approx ${b.toFixed(0)}$`);
      // c
      // question.addHeading(`solution`, `c) $P(C<c)=${bp1.toFixed(2)}$`);
      question.addHeading("solution", `Using answer from part b`);
      question.addParagraph(
        `solution`,
        `Normal curve is symetrical about the mean, so $P(C>u+x) = P(C<u-x)$`
      );
      question.addParagraph("solution", `$\\therefore b=u+x, c=u-x$`);
      question.addParagraph("solution", `$${b.toFixed(0)}=${mean}+x$`);
      question.addParagraph(
        "solution",
        `$\\therefore x=${parseFloat(b.toFixed(0)) - mean}$`
      );
      question.addParagraph("solution", `$c=${mean}-${x}$`);
      question.addParagraph("solution", `$=${c}$`);

      //
      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(parseFloat(a.toFixed(0)), 0.5, `a) $\\ a=$`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(parseFloat(b.toFixed(0)), 1, `b) $\\ b=$`);
      question.addInputGroup(g2);

      const g3 = new MCQuestion.InputGroup(1);
      g3.addInput(parseFloat(c.toFixed(0)), 1, `c) $\\ c=$`);
      question.addInputGroup(g3);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_InverseNormal_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = new MCMaths.Normal(0, 1);

    const p = MCRandom.randomInt(15, 85) / 100;
    const sign = ["\\leq", "\\geq"][MCRandom.randomInt(0, 1)];

    const ans = [
      n.inverseNormal(p).toFixed(3),
      n.inverseNormal(1 - p).toFixed(3),
    ];

    const ata = n.pdf(parseFloat(ans[0]));

    const correct = [false, false, false, false];
    if (sign === "\\leq") {
      correct[0] = true;
    } else {
      correct[1] = true;
    }

    ans.push((parseFloat(ans[0]) + ata).toFixed(3));
    ans.push((parseFloat(ans[1]) + ata).toFixed(3));

    function allDifferentallGood(items) {
      for (let i = 0; i < items.length; i += 1) {
        if (
          parseFloat(items[i]) === 0 ||
          parseFloat(items[i] * 100) % 1 === 0
        ) {
          return false;
        }
        for (let j = 0; j < items.length; j += 1) {
          if (i !== j && items[i] === items[j]) {
            return false;
          }
        }
      }
      return true;
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(ans[0] - ans[2]) > 0.01 &&
      Math.abs(ans[1] - ans[3]) > 0.01 &&
      allDifferentallGood(ans)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `The variable $X$ can be modelled by a normal distribution, mean $0$ and variance $1$`
      );
      question.addParagraph(
        `Given that $P(X${sign} a)=${p.toFixed(
          2
        )}$, select the correct value of $a$ below.`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${ans[0]}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${ans[1]}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${ans[2]}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${ans[3]}$`);

      question.addAnswer(answer1, correct[0]);
      question.addAnswer(answer2, correct[1]);
      question.addAnswer(answer3, correct[2]);
      question.addAnswer(answer4, correct[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_InverseNormal_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const name = MCMisc.getName();

    const mean = MCRandom.randomInt(40, 60);
    const sd = MCRandom.randomInt(8, 16);

    const score = MCRandom.randomInt(30, 70);

    const ans = [
      ((score - mean) / sd).toFixed(3),
      ((score + mean) / sd).toFixed(3),
      ((score - mean) / sd ** 2).toFixed(3),
      ((mean - score) / sd).toFixed(3),
    ];

    function allDifferentallGood(items) {
      for (let i = 0; i < items.length; i += 1) {
        if (
          parseFloat(items[i]) === 0 ||
          parseFloat(items[i] * 100) % 1 === 0
        ) {
          return false;
        }
        for (let j = 0; j < items.length; j += 1) {
          if (i !== j && items[i] === items[j]) {
            return false;
          }
        }
      }
      return true;
    }
    /* END VARIABLES AND CONSTANTS */
    if (score !== mean && allDifferentallGood(ans)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(`${name.name} scored $${score}$ marks on a test.`);
      question.addParagraph(
        `The scores on the test in ${name.hisher} class can be modelled by a normal distribution with a mean of $${mean}$ marks and a standard deviation of $${sd}$ marks.`
      );
      question.addParagraph(
        `Select the correct $z$-score for ${name.hisher} test score.`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${ans[0]}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${ans[1]}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${ans[2]}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${ans[3]}$`);

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_NormalHypothesisTesting_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const person = MCMisc.getName();

    const u = MCRandom.randomInt(15, 20);
    const sd = MCRandom.randomInt(5, 20) / 10;
    const n = MCRandom.randomInt(5, 20, 5);
    const sig = [1, 5, 10][MCRandom.randomInt(0, 2)];

    const normal = new MCMaths.Normal(u, sd);

    const x_bar = u - MCRandom.randomInt(2, 20) / 10;

    const h_normal = new MCMaths.Normal(u, sd / Math.sqrt(n));

    const prob = h_normal.cdf(x_bar).toFixed(3);

    const std_nrml = new MCMaths.Normal(0, 1);
    const z = std_nrml.inverseNormal(sig / 100).toFixed(3);
    const C = z * (sd / Math.sqrt(n)) + u;
    /* END VARIABLES AND CONSTANTS */
    if (
      sd !== 1 &&
      parseFloat(prob) > (0.4 * sig) / 100 &&
      parseFloat(prob) < (1.75 * sig) / 100 &&
      Math.abs(C - x_bar) > 0.05 &&
      parseFloat(prob) !== sig / 100
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `${person.name} investigates a manufacturers claim that their batteries have an average lifetime of $${u}$ hours. ${person.HeShe} tests a random sample of $${n}$ batteries and finds their mean lifetime is only $${x_bar}$ hours. From data released by the manufacturer, ${person.name} also calculates the standard deviation of the population's lifetime to be $${sd}$ hours.`
      );
      question.addParagraph(
        "question",
        `a) Test, at the $${sig}$% level of significance, ${person.hisher} claim that the mean lifetime of the batteries are less than the manufacturer says.`
      );
      question.addParagraph(
        "question",
        `b) State the critical value for this hypothesis test.`
      );
      // solution
      // a
      question.addHeading("solution", `a) State the hypotheses`);
      question.addParagraph("solution", `$H_0:u = ${u}$`);
      question.addParagraph("solution", `$H_1:u < ${u}$`);
      question.addParagraph(
        "solution",
        `1-Tailed Test, $${sig}$% significance.`
      );
      question.addHeading(
        "solution",
        `Let $X$ be the lifetime of a battery in hours.`
      );
      question.addParagraph(
        "solution",
        `$\\overline{X} \\sim N(${u},\\frac{${sd}^2}{${n}})$`
      );
      question.addParagraph("solution", `$P(\\overline{X}<${x_bar})=${prob}$`);
      if (sig / 100 < parseFloat(prob)) {
        // Accept H0
        question.addParagraph("solution", `$${prob} > ${sig / 100}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Accept $H_0$ at the $${sig}$% level of significance.`
        );
        question.addParagraph(
          "solution",
          `Not enough evidence to support the claim that the mean lifetime of the batteries is less than the manufacturer states.`
        );
      } else {
        // Reject H0
        question.addParagraph("solution", `$${prob} < ${sig / 100}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Reject $H_0$ at the $${sig}$% level of significance.`
        );
        question.addParagraph(
          "solution",
          `Evidence to suggest that the mean lifetime of the batteries is less than the manufacturer states.`
        );
      }
      // b
      question.addHeading("solution", `b) Let $C$ be the critical value.`);
      question.addParagraph("solution", `$P(\\overline{X}<C)=${sig / 100}$`);
      question.addParagraph("solution", `When $P(Z<z)=${sig / 100},z=${z}$`);
      question.addParagraph(
        "solution",
        `$${z}=\\displaystyle\\frac{C-${u}}{\\displaystyle\\frac{${sd}}{\\sqrt{${n}}}}$`
      );
      question.addParagraph("solution", `$\\therefore C=${C.toFixed(5)}$`);
      question.addParagraph("solution", `$\\approx ${C.toFixed(2)}$`);

      //
      question.requiresSelfMarking();
      //
      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(parseFloat(C.toFixed(0)), 0.5, `b)`);
      question.addInputGroup(g1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_NormalHypothesisTesting_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const person = MCMisc.getName();
    const years_ago = MCRandom.randomInt(2, 5);

    let u = MCRandom.randomInt(401, 599);
    let sd = MCRandom.randomInt(101, 121);

    const n = MCRandom.randomInt(100, 500);

    const index = MCRandom.randomInt(0, 1);
    const sig = [5, 10][index];
    const o_sig = [1, 5][index];

    u /= 100;
    sd /= 100;
    const x_bar = u + MCRandom.randomInt(10, 40) / 100;

    const normal = new MCMaths.Normal(u, sd);
    const h_normal = new MCMaths.Normal(u, sd / Math.sqrt(n));
    const std_nrml = new MCMaths.Normal(0, 1);

    const prob = (1 - parseFloat(h_normal.cdf(x_bar)).toFixed(3)).toFixed(3);

    const ap = (1 - o_sig / 100).toFixed(2);

    const z = std_nrml.inverseNormal(parseFloat(ap)).toFixed(3);
    const C = z * (sd / Math.sqrt(n)) + u;
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(prob) !== sig / 100 &&
      (u * 100) % 10 !== 0 &&
      (sd * 100) % 10 !== 0 &&
      (parseFloat(prob) * 100) % 10 !== 0 &&
      parseFloat(prob) > (0.1 * sig) / 100 &&
      parseFloat(prob) < (1.5 * sig) / 100 &&
      Math.abs(C - x_bar) > 0.05
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `An online survey of $${n}$ teenagers revealed that the average teenager spends $£${x_bar.toFixed(
          2
        )}$ on fizzy drinks per week. However, in a study $${years_ago}$ years prior, the mean expenditure on fizzy drinks by teenagers had a mean of $£${u.toFixed(
          2
        )}$ and a standard deviation of $£${sd.toFixed(2)}$.`
      );
      question.addParagraph(
        "question",
        `Assuming that the standard deviation remains unchanged.`
      );
      question.addParagraph(
        "question",
        `a) Investigate, at the $${sig}$% significance level, whether the mean expenditure on fizzy drinks among teenagers has increased since the original study.`
      );
      question.addParagraph(
        "question",
        `b) Calculate the smallest value of the sample mean that would cause $H_0$ to be rejected at a $${o_sig}$% level of significance.`
      );

      // solution
      // a
      question.addHeading("solution", `a) State the hypotheses`);
      question.addParagraph("solution", `$H_0:u = ${u.toFixed(2)}$`);
      question.addParagraph("solution", `$H_1:u > ${u.toFixed(2)}$`);
      question.addParagraph(
        "solution",
        `1-Tailed Test, $${sig}$% significance.`
      );
      question.addHeading(
        "solution",
        `Let $X$ be the expenditure on fizzy drinks by a teenager.`
      );
      question.addParagraph(
        "solution",
        `$\\overline{X} \\sim N(${u.toFixed(2)},\\frac{${sd.toFixed(
          2
        )}^2}{${n}})$`
      );
      question.addParagraph(
        "solution",
        `$P(\\overline{X}>${x_bar.toFixed(
          2
        )})=1-P(\\overline{X}<${x_bar.toFixed(2)})$`
      );
      question.addParagraph(
        "solution",
        `$=1-${(1 - parseFloat(prob)).toFixed(3)}$`
      );
      question.addParagraph("solution", `$=${prob}$`);
      if (sig / 100 < parseFloat(prob)) {
        // Accept H0
        question.addParagraph("solution", `$${prob} > ${sig / 100}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Accept $H_0$ at the $${sig}$% level of significance.`
        );
        question.addParagraph(
          "solution",
          `Not enough evidence to suggest that the mean spend on fizzy drink per week, of teenagers, has increased since the original study.`
        );
      } else {
        // Reject H0
        question.addParagraph("solution", `$${prob} < ${sig / 100}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Reject $H_0$ at the $${sig}$% level of significance.`
        );
        question.addParagraph(
          "solution",
          `Evidence to suggest that the mean spend on fizzy drink per week, of teenagers, has increased since the original study.`
        );
      }
      // b
      question.addHeading("solution", `b) Let $C$ be the critical value.`);
      question.addParagraph(
        "solution",
        `$P(\\overline{X}<C)=1-${o_sig / 100}$`
      );
      question.addParagraph("solution", `$=${ap}$`);
      question.addParagraph("solution", `When $P(Z<z)=${ap},z=${z}$`);
      question.addParagraph(
        "solution",
        `$${z}=\\displaystyle\\frac{C-${u}}{\\displaystyle\\frac{${sd}}{\\sqrt{${n}}}}$`
      );
      question.addParagraph("solution", `$\\therefore C=${C.toFixed(5)}$`);
      question.addParagraph("solution", `$\\approx ${C.toFixed(2)}$`);

      //
      question.requiresSelfMarking();

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(parseFloat(C.toFixed(0)), 0.5, `b)`);
      question.addInputGroup(g1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_NormalHypothesisTesting_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const u = MCRandom.randomInt(250, 500, 50);
    const sd = MCRandom.randomInt(2, 15);

    const n = MCRandom.randomInt(20, 50, 5);
    const sig = [1, 5, 10][MCRandom.randomInt(0, 2)];

    const x_bar = [u - MCRandom.randomInt(2, 8), u + MCRandom.randomInt(2, 8)][
      MCRandom.randomInt(0, 1)
    ];

    const h_normal = new MCMaths.Normal(u, sd / Math.sqrt(n));
    const std_nrml = new MCMaths.Normal(0, 1);

    const less_prob = h_normal.cdf(x_bar).toFixed(3);
    const more_prob = (1 - parseFloat(less_prob)).toFixed(3);

    const ap = (1 - sig / 100).toFixed(2);
    const z_upper = std_nrml.inverseNormal(parseFloat(ap)).toFixed(3);
    const C_upper = z_upper * (sd / Math.sqrt(n)) + u;

    const z_lower = std_nrml.inverseNormal(sig / 100).toFixed(3);
    const C_lower = z_lower * (sd / Math.sqrt(n)) + u;

    /* END VARIABLES AND CONSTANTS */
    if (
      true &&
      ((parseFloat(less_prob) > (0.25 * sig) / 200 &&
        parseFloat(less_prob) < (1.9 * sig) / 200 &&
        parseFloat(less_prob) !== sig / 200) ||
        (parseFloat(more_prob) > (0.25 * sig) / 200 &&
          parseFloat(more_prob) < (1.9 * sig) / 200 &&
          parseFloat(more_prob) !== sig / 200))
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `A company produces tins of beans which they claim have a mean weight of $${u}$g. However, due to inaccuracies in the equipment they use, the weight of beans in the tins can be modelled by a normal distribution with a standard deviation of $${sd}$g.`
      );
      question.addParagraph(
        "question",
        `After some repairs to the machines, they believe the mean weight of beans in a tin has changed due to a sample of $${n}$ tins that had an average weight of $${x_bar}$g.`
      );
      question.addParagraph(
        "question",
        `a) Test, at the $${sig}$% significance level, the companies belief that the mean weight of beans in a tin has changed.`
      );
      if (x_bar < u) {
        question.addParagraph(
          "question",
          `b) If the mean weight of beans in a tin was suspected to have increased, find the smallest possible sample mean that would result in the company rejecting the null hypothesis, at a $${sig}$% significance level.`
        );
      } else {
        question.addParagraph(
          "question",
          `b) If the mean weight of beans in a tin was suspected to have decreased, find the largest possible sample mean that would result in the company rejecting the null hypothesis, at a $${sig}$% significance level.`
        );
      }
      // a
      question.addHeading("solution", `a) State the hypotheses`);
      question.addParagraph("solution", `$H_0:u = ${u}$`);
      question.addParagraph("solution", `$H_1:u \\neq ${u}$`);
      question.addParagraph(
        "solution",
        `2-Tailed Test, $${sig}$% significance.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore ${sig / 2}$% at each tail.`
      );
      question.addHeading(
        "solution",
        `Let $X$ be the weight of beans in a tin.`
      );
      question.addParagraph(
        "solution",
        `$\\overline{X} \\sim N(${u},\\frac{${sd}^2}{${n}})$`
      );
      if (x_bar < u) {
        // sample mean is less than U
        question.addHeading("solution", `As sample mean is less than $u$.`);
        question.addParagraph(
          "solution",
          `$P(\\overline{X}<${x_bar})=${less_prob}$`
        );
        if (sig / 200 < parseFloat(less_prob)) {
          // Accept H0
          question.addParagraph("solution", `$${less_prob} > ${sig / 200}$`);
          question.addParagraph(
            "solution",
            `$\\therefore$ Accept $H_0$ at the $${sig}$% level of significance.`
          );
          question.addParagraph(
            "solution",
            `Not enough evidence to suggest the mean weight of beans in a tin has changed.`
          );
        } else {
          // Reject H0
          question.addParagraph("solution", `$${less_prob} < ${sig / 200}$`);
          question.addParagraph(
            "solution",
            `$\\therefore$ Reject $H_0$ at the $${sig}$% level of significance.`
          );
          question.addParagraph(
            "solution",
            `Evidence to suggest that the mean weight of beans in a tin has changed.`
          );
        }
      } else {
        // sample mean larger than u
        question.addHeading("solution", `As sample mean is greater than $u$.`);
        question.addParagraph(
          "solution",
          `$P(\\overline{X}>${x_bar})=1-P(\\overline{X}<${x_bar})$`
        );
        question.addParagraph("solution", `$=1-${less_prob}$`);
        question.addParagraph("solution", `$=${more_prob}$`);
        if (sig / 200 < parseFloat(more_prob)) {
          // Accept H0
          question.addParagraph("solution", `$${more_prob} > ${sig / 200}$`);
          question.addParagraph(
            "solution",
            `$\\therefore$ Accept $H_0$ at the $${sig}$% level of significance.`
          );
          question.addParagraph(
            "solution",
            `Not enough evidence to suggest the mean weight of beans in a tin has changed..`
          );
        } else {
          // Reject H0
          question.addParagraph("solution", `$${more_prob} < ${sig / 200}$`);
          question.addParagraph(
            "solution",
            `$\\therefore$ Reject $H_0$ at the $${sig}$% level of significance.`
          );
          question.addParagraph(
            "solution",
            `Evidence to suggest that the mean weight of beans in a tin has changed.`
          );
        }
      }
      // b
      if (x_bar < u) {
        question.addHeading("solution", `b) Let $C$ be the critical value.`);
        question.addParagraph(
          "solution",
          `$P(\\overline{X}<C)=1-${sig / 100}$`
        );
        question.addParagraph("solution", `$=${ap}$`);
        question.addParagraph("solution", `When $P(Z<z)=${ap},z=${z_upper}$`);
        question.addParagraph(
          "solution",
          `$${z_upper}=\\displaystyle\\frac{C-${u}}{\\displaystyle\\frac{${sd}}{\\sqrt{${n}}}}$`
        );
        question.addParagraph(
          "solution",
          `$\\therefore C=${C_upper.toFixed(5)}$`
        );
        question.addParagraph("solution", `$\\approx ${C_upper.toFixed(2)}$`);
      } else {
        question.addHeading("solution", `b) Let $C$ be the critical value.`);
        question.addParagraph("solution", `$P(\\overline{X}<C)=${sig / 100}$`);
        question.addParagraph(
          "solution",
          `When $P(Z<z)=${sig / 100},z=${z_lower}$`
        );
        question.addParagraph(
          "solution",
          `$${z_lower}=\\displaystyle\\frac{C-${u}}{\\displaystyle\\frac{${sd}}{\\sqrt{${n}}}}$`
        );
        question.addParagraph(
          "solution",
          `$\\therefore C=${C_lower.toFixed(5)}$`
        );
        question.addParagraph("solution", `$\\approx ${C_lower.toFixed(2)}$`);
      }

      //
      question.requiresSelfMarking();

      // b
      if (x_bar < u) {
        const g1 = new MCQuestion.InputGroup(1);
        g1.addInput(parseFloat(C_upper.toFixed(0)), 0.5, `b)`);
        question.addInputGroup(g1);
      } else {
        const g1 = new MCQuestion.InputGroup(1);
        g1.addInput(parseFloat(C_lower.toFixed(0)), 0.5, `b)`);
        question.addInputGroup(g1);
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_NormalHypothesisTesting_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const h = MCRandom.randomInt(165, 175);

    const symbol = [`\\neq`, `<`, `>`][MCRandom.randomInt(0, 2)];
    const state = [`accepted`, `rejected`][MCRandom.randomInt(0, 1)];

    const smpl_mean = MCRandom.randomInt(160, 180);

    const difference = Math.abs(smpl_mean / 100 - h / 100).toFixed(2);

    /* END VARIABLES AND CONSTANTS */
    if (
      (symbol === `\\neq` && smpl_mean !== h) ||
      (symbol === `<` && smpl_mean < h) ||
      (symbol === `>` && smpl_mean > h)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `A hypothesis test was carried out on the following hypotheses to see if the average height of men, in metres, had changed in the last $10$ years.`
      );
      question.addParagraph(`$H_0:u=${(h / 100).toFixed(2)}$`);
      question.addParagraph(`$H_1:u${symbol} ${(h / 100).toFixed(2)}$`);
      question.addParagraph(
        `The test resulted in $H_0$ being ${state}, using a sample mean of $${(
          smpl_mean / 100
        ).toFixed(2)}$.`
      );
      question.addParagraph(
        `Select the statement below that is supported by this result.`
      );

      if (symbol === `\\neq`) {
        if (state === `accepted`) {
          // neq h0 accpeted
          const answer1 = new MCQuestion.MultipleChoiceAnswer();
          answer1.addParagraph(`The average height of men has not changed`);

          const answer2 = new MCQuestion.MultipleChoiceAnswer();
          if (smpl_mean < h) {
            answer2.addParagraph(
              `The average height of men has decreased by $${difference}$m`
            );
          } else {
            answer2.addParagraph(
              `The average height of men has increased by $${difference}$m`
            );
          }

          const answer3 = new MCQuestion.MultipleChoiceAnswer();
          answer3.addParagraph(
            `The average height of people ${
              [`has`, `has not`][MCRandom.randomInt(0, 1)]
            } changed`
          );

          const answer4 = new MCQuestion.MultipleChoiceAnswer();
          answer4.addParagraph(`The average height of men has changed`);

          question.addAnswer(answer1, true);
          question.addAnswer(answer2, false);
          question.addAnswer(answer3, false);
          question.addAnswer(answer4, false);
        } else {
          // neq h0 rejected
          const answer1 = new MCQuestion.MultipleChoiceAnswer();
          answer1.addParagraph(`The average height of men has changed`);

          const answer2 = new MCQuestion.MultipleChoiceAnswer();
          if (smpl_mean < h) {
            answer2.addParagraph(
              `The average height of men has decreased by $${difference}$m`
            );
          } else {
            answer2.addParagraph(
              `The average height of men has increased by $${difference}$m`
            );
          }

          const answer3 = new MCQuestion.MultipleChoiceAnswer();
          answer3.addParagraph(
            `The average height of people ${
              [`has`, `has not`][MCRandom.randomInt(0, 1)]
            } changed`
          );

          const answer4 = new MCQuestion.MultipleChoiceAnswer();
          answer4.addParagraph(`The average height of men has not changed`);

          question.addAnswer(answer1, true);
          question.addAnswer(answer2, false);
          question.addAnswer(answer3, false);
          question.addAnswer(answer4, false);
        }
      } else if (symbol === `<`) {
        if (state === `accepted`) {
          // less than, h0 accepted
          const answer1 = new MCQuestion.MultipleChoiceAnswer();
          answer1.addParagraph(`The average height of men has not increased`);

          const answer2 = new MCQuestion.MultipleChoiceAnswer();
          answer2.addParagraph(`The average height of men has decreased`);

          const answer3 = new MCQuestion.MultipleChoiceAnswer();
          answer3.addParagraph(`The average height of people has not changed`);

          const answer4 = new MCQuestion.MultipleChoiceAnswer();
          answer4.addParagraph(`The average height of men has increased`);

          question.addAnswer(answer1, true);
          question.addAnswer(answer2, false);
          question.addAnswer(answer3, false);
          question.addAnswer(answer4, false);
        } else {
          const answer1 = new MCQuestion.MultipleChoiceAnswer();
          answer1.addParagraph(`The average height of men has increased`);

          const answer2 = new MCQuestion.MultipleChoiceAnswer();
          answer2.addParagraph(
            `The average height of men has increased by $${difference}$m`
          );

          const answer3 = new MCQuestion.MultipleChoiceAnswer();
          answer3.addParagraph(`The average height of people has increased`);

          const answer4 = new MCQuestion.MultipleChoiceAnswer();
          answer4.addParagraph(`The average height of men has not increased`);

          question.addAnswer(answer1, true);
          question.addAnswer(answer2, false);
          question.addAnswer(answer3, false);
          question.addAnswer(answer4, false);
        }
      } else if (state === `accepted`) {
        const answer1 = new MCQuestion.MultipleChoiceAnswer();
        answer1.addParagraph(`The average height of men has not decreased`);

        const answer2 = new MCQuestion.MultipleChoiceAnswer();
        answer2.addParagraph(`The average height of men has increased`);

        const answer3 = new MCQuestion.MultipleChoiceAnswer();
        answer3.addParagraph(`The average height of people has not changed`);

        const answer4 = new MCQuestion.MultipleChoiceAnswer();
        answer4.addParagraph(`The average height of men has decreased`);

        question.addAnswer(answer1, true);
        question.addAnswer(answer2, false);
        question.addAnswer(answer3, false);
        question.addAnswer(answer4, false);
      } else {
        const answer1 = new MCQuestion.MultipleChoiceAnswer();
        answer1.addParagraph(`The average height of men has decreased`);

        const answer2 = new MCQuestion.MultipleChoiceAnswer();
        answer2.addParagraph(
          `The average height of men has decreased by $${difference}$m`
        );

        const answer3 = new MCQuestion.MultipleChoiceAnswer();
        answer3.addParagraph(`The average height of people has decreased`);

        const answer4 = new MCQuestion.MultipleChoiceAnswer();
        answer4.addParagraph(`The average height of men has not decreased`);

        question.addAnswer(answer1, true);
        question.addAnswer(answer2, false);
        question.addAnswer(answer3, false);
        question.addAnswer(answer4, false);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_NormalHypothesisTesting_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m = MCRandom.randomInt(25, 35);
    const person = MCMisc.getName();

    const word = [`lower`, `higher`, `different`][MCRandom.randomInt(0, 2)];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(3);
      question.addParagraph(
        `A crisp manufacturer claims that the average weight of crisps in a packet is $${m}$ grams.`
      );
      question.addParagraph(
        `${person.name} believes that the mean weight of crisps in a packet is ${word} than the manufacturer claims.`
      );
      question.addParagraph(
        `Select the correct alternative hypothesis for her test.`
      );
      //

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$H_1 \\neq ${m}$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$H_1 < ${m}$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$H_1 > ${m}$`);

      question.addAnswer(answer1, word === `different`);
      question.addAnswer(answer2, word === `lower`);
      question.addAnswer(answer3, word === `higher`);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_NormalProbabilities_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const mean = MCRandom.randomInt(245, 255);
    const sd = MCRandom.randomInt(30, 60) / 10;

    const normal = new MCMaths.Normal(mean, sd);

    const a = 250 + MCRandom.randomInt(-10, 10);

    const b_lower = MCRandom.randomInt(240, 250);
    const b_upper = b_lower + MCRandom.randomInt(4, 8);

    const sign1 = [`Less`, `Greater`][MCRandom.randomInt(0, 1)];
    const sign2 = [`less`, `greater`][MCRandom.randomInt(0, 1)];

    const n = MCRandom.randomInt(3, 8);
    const avg = MCRandom.randomInt(245, 255);

    let a_ans = 0;
    if (sign1 === "Greater") {
      a_ans = (1 - normal.cdf(a)).toFixed(2);
    } else {
      a_ans = normal.cdf(a).toFixed(2);
    }

    const b_ans = (normal.cdf(b_upper) - normal.cdf(b_lower)).toFixed(2);

    const mean_normal = new MCMaths.Normal(mean, sd / Math.sqrt(n));

    let c_ans = 0;
    if (sign2 === "greater") {
      c_ans = (1 - mean_normal.cdf(avg)).toFixed(2);
    } else {
      c_ans = mean_normal.cdf(avg).toFixed(2);
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(a - mean) > 2 &&
      a !== b_lower &&
      a !== b_upper &&
      b_upper !== mean &&
      b_lower !== mean &&
      avg !== mean &&
      parseFloat(a_ans) > 0.1 &&
      parseFloat(a_ans) < 0.9 &&
      normal.cdf(b_upper) < 0.9 &&
      normal.cdf(b_lower) > 0.1 &&
      parseFloat(c_ans) > 0.1 &&
      parseFloat(c_ans) < 0.9
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The mass of rice, $M$ grams in a standard packete is normally distributed with a mean of $${mean}$ grams and a standard deviation of $${sd}$ grams.`
      );
      question.addParagraph(
        "question",
        `a) Find the probability that the mass of rice in a packet is:`
      );
      question.addParagraph("question", `i) ${sign1} than $${a}$ grams.`);
      question.addParagraph(
        "question",
        `ii) Between $${b_lower}$ grams and $${b_upper}$ grams.`
      );
      question.addParagraph(
        "question",
        `b) Find the probability that the mean mass of a random sample of $${n}$ packets is ${sign2} than $${avg}$ grams.`
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) Find the probability that the mass of rice in a packet is:`
      );
      // i
      question.addHeading("solution", `i) ${sign1} than $${a}$ grams.`);
      question.addParagraph("solution", `$M \\sim N(${mean}, ${sd}^2)$`);
      if (sign1 === "Greater") {
        question.addParagraph("solution", `$P(M>${a})=1-P(M<${a})$`);
        question.addParagraph("solution", `$=1-${normal.cdf(a).toFixed(5)}$`);
        question.addParagraph(
          "solution",
          `$=${(1 - normal.cdf(a)).toFixed(5)}$`
        );
        question.addParagraph("solution", `$\\approx ${a_ans}$`);
      } else {
        question.addParagraph(
          "solution",
          `$P(M<${a})=${normal.cdf(a).toFixed(5)}$`
        );
        question.addParagraph("solution", `$\\approx ${a_ans}$`);
      }
      // ii
      question.addHeading(
        "solution",
        `ii) Between $${b_lower}$ grams and $${b_upper}$ grams.`
      );
      question.addParagraph("solution", `$M \\sim N(${mean}, ${sd}^2)$`);
      question.addParagraph(
        "solution",
        `$P(${b_lower}<M<${b_upper}) = P(M<${b_upper}) - P(M<${b_lower})$`
      );
      question.addParagraph(
        "solution",
        `$=${normal.cdf(b_upper).toFixed(4)}-${normal.cdf(b_lower).toFixed(4)}$`
      );
      question.addParagraph(
        "solution",
        `$=${(normal.cdf(b_upper) - normal.cdf(b_lower)).toFixed(4)}$`
      );
      question.addParagraph("solution", `$\\approx ${b_ans}$`);

      // b
      question.addHeading(
        "solution",
        `b) Find the probability that the mean mass of a random sample of $${n}$ packets is ${sign2} than $${avg}$ grams.`
      );
      question.addParagraph(
        "solution",
        `$\\overline{M} \\sim N(${mean}, \\frac{${sd}^2}{${n}})$`
      );
      if (sign2 === "greater") {
        question.addParagraph(
          "solution",
          `$P(\\overline{M}>${avg})=1-P(M<${avg})$`
        );
        question.addParagraph(
          "solution",
          `$=1-${mean_normal.cdf(avg).toFixed(5)}$`
        );
        question.addParagraph(
          "solution",
          `$=${(1 - mean_normal.cdf(avg)).toFixed(5)}$`
        );
        question.addParagraph("solution", `$\\approx ${c_ans}$`);
      } else {
        question.addParagraph(
          "solution",
          `$P(\\overline{M}<${avg})=${mean_normal.cdf(avg).toFixed(5)}$`
        );
        question.addParagraph("solution", `$\\approx ${c_ans}$`);
      }
      //
      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(parseFloat(a_ans), 0.05, `ai)`);
      g1.addInput(parseFloat(b_ans), 0.05, `aii)`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(parseFloat(c_ans), 0.05, `b)`);
      question.addInputGroup(g2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_NormalProbabilities_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const eight_mean = MCRandom.randomInt(120, 150);
    const eight_sd = MCRandom.randomInt(4, 8);
    const eight_normal = new MCMaths.Normal(eight_mean, eight_sd);
    const eight_offset = MCRandom.randomInt(eight_sd, 2 * eight_sd);

    const k_mean = MCRandom.randomInt(150, 180);
    const k_sd = MCRandom.randomInt(8, 16);
    const k_normal = new MCMaths.Normal(k_mean, k_sd);
    const k_offset = MCRandom.randomInt(k_sd, 2 * k_sd);

    const ai_ans = eight_normal.cdf(eight_mean - eight_offset).toFixed(2);
    const aii_ans = (1 - k_normal.cdf(k_mean + k_offset)).toFixed(2);

    const b_ans =
      eight_normal.cdf(eight_mean - eight_offset) *
      k_normal.cdf(k_mean + k_offset);
    const b_check =
      parseFloat(eight_normal.cdf(eight_mean - eight_offset).toFixed(5)) *
      parseFloat(k_normal.cdf(k_mean + k_offset).toFixed(5));
    /* END VARIABLES AND CONSTANTS */
    if (
      ai_ans > 0.05 &&
      aii_ans > 0.05 &&
      ai_ans !== aii_ans &&
      b_ans > 0.1 &&
      b_ans < 0.9 &&
      b_check.toFixed(5) === b_ans.toFixed(5)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `The head of an athletics club records the times long distance runners take to run $800$m and $1000$m.`
      );
      question.addParagraph(
        `question`,
        `He finds that the times for the $800$m can be modelled by a normal distribution with mean $${eight_mean}$ minutes and standard deviation $${eight_sd}$ minutes.`
      );
      question.addParagraph(
        `question`,
        `Similarly, times for the $1000$m can be modelled by a normal distribution, mean $${k_mean}$ mintues and standard deviation $${k_sd}$ minutes.`
      );
      question.addParagraph(
        "question",
        `a) Find the probability that a randomly chosen runner:`
      );
      question.addParagraph(
        "question",
        `i) Runs the $800$m in a time quicker than $${
          eight_mean - eight_offset
        }$ minutes.`
      );
      question.addParagraph(
        "question",
        `ii) Runs the $1000$m in a time slower than $${
          k_mean + k_offset
        }$ minutes.`
      );
      question.addParagraph(
        "question",
        `b) Assuming that the times taken to run the two distances are independent, find the probability that a randomly chosen runner runs the $800$m in under $${
          eight_mean - eight_offset
        }$ minutes and the $1000$m in under $${k_mean + k_offset}$ minutes.`
      );
      question.addParagraph(
        "question",
        `c) Suggest why the assumption in part b may not be correct.`
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) Find the probability that a randomly chosen runner:`
      );
      // i
      question.addHeading(
        "solution",
        `i) Runs the $800$m in a time quicker than $${
          eight_mean - eight_offset
        }$ minutes.`
      );
      question.addHeading(
        "solution",
        `Let $X$ be the time taken to run the $800$m in minutes`
      );
      question.addParagraph(
        "solution",
        `$X \\sim N(${eight_mean}, ${eight_sd}^2)$`
      );
      question.addParagraph(
        "solution",
        `$P(X<${eight_mean - eight_offset})=${eight_normal
          .cdf(eight_mean - eight_offset)
          .toFixed(5)}$`
      );
      question.addParagraph("solution", `$\\approx ${ai_ans}$`);

      // ii
      question.addHeading(
        "solution",
        `ii) Runs the $1000$m in a time slower than $${
          k_mean + k_offset
        }$ minutes.`
      );
      question.addHeading(
        "solution",
        `Let $Y$ be the time taken to run the $1000$m in minutes`
      );
      question.addParagraph("solution", `$Y \\sim N(${k_mean}, ${k_sd}^2)$`);
      question.addParagraph(
        "solution",
        `$P(Y>${k_mean + k_offset})=1-P(Y<${k_mean + k_offset})$`
      );
      question.addParagraph(
        "solution",
        `$=1-${k_normal.cdf(k_mean + k_offset).toFixed(5)}$`
      );
      question.addParagraph(
        "solution",
        `$=${(1 - k_normal.cdf(k_mean + k_offset)).toFixed(5)}$`
      );
      question.addParagraph("solution", `$\\approx ${aii_ans}$`);

      // b
      question.addHeading(
        "solution",
        `b) Assuming that the times taken to run the two distances are independent, find the probability that a randomly chosen runner runs the $800$m in under $${
          eight_mean - eight_offset
        }$ minutes and the $1000$m in under $${k_mean + k_offset}$ minutes.`
      );
      question.addHeading("solution", `Assuming independence`);
      question.addParagraph(
        "solution",
        `$P(X<${eight_mean - eight_offset}) \\cap P(Y<${
          k_mean + k_offset
        }) = P(X<${eight_mean - eight_offset}) \\times P(Y<${
          k_mean + k_offset
        })$`
      );
      question.addParagraph(
        "solution",
        `$=P(X<${eight_mean - eight_offset}) \\times (1-P(Y>${
          k_mean + k_offset
        }))$`
      );
      question.addParagraph(
        "solution",
        `$=${eight_normal
          .cdf(eight_mean - eight_offset)
          .toFixed(5)} \\times (1-${(
          1 - k_normal.cdf(k_mean + k_offset)
        ).toFixed(5)})$`
      );
      question.addParagraph("solution", `$=${b_check.toFixed(5)}$`);
      question.addParagraph("solution", `$\\approx ${b_check.toFixed(2)}$`);
      // c
      question.addHeading(
        "solution",
        `c) Suggest why the assumption in part b may not be correct.`
      );
      question.addParagraph(
        "solution",
        `People who run one event in good time will be more likely to run the other in good time as well as the events are so similar. Therefore, the outcome of one event may influence how likely the runner is to achieve certain times in the other, meaning that the two times are not independent of each other.`
      );
      //
      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(parseFloat(ai_ans), 0.05, `ai)`);
      g1.addInput(parseFloat(aii_ans), 0.05, `aii)`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(parseFloat(b_ans), 0.05, `b)`);
      question.addInputGroup(g2);
      //
      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_NormalProbabilities_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const mean_R1 = MCRandom.randomInt(35, 45);
    const sd_R1 = MCRandom.randomInt(5, 10);

    const normal_R1 = new MCMaths.Normal(mean_R1, sd_R1);

    const mean_R2 = mean_R1 - MCRandom.randomInt(3, 8);
    const sd_R2 = sd_R1 + MCRandom.randomInt(5, 8);

    const normal_R2 = new MCMaths.Normal(mean_R2, sd_R2);

    const au = mean_R1 + MCRandom.randomInt(4, 10);
    const al = mean_R1 - MCRandom.randomInt(4, 10);

    const person = new MCMisc.getName();

    const t = MCRandom.randomInt(1, 5);
    const n = MCRandom.randomInt(25, 100, 5);

    const num_journeys = (
      n * parseFloat((1 - normal_R2.cdf(au)).toFixed(5))
    ).toFixed(5);

    const values = [
      normal_R1.cdf(au),
      normal_R1.cdf(al),
      normal_R1.cdf(au) - normal_R1.cdf(al),
      normal_R2.cdf(au),
    ];
    function allGood(items) {
      for (let i = 0; i < items.length; i += 1) {
        if (items[i] < 0.1 || items[i] > 0.9) {
          return false;
        }
      }
      return true;
    }
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(normal_R1.cdf(au) - normal_R2.cdf(au)) > 0.05 &&
      Math.abs(normal_R1.cdf(au) - normal_R2.cdf(au)) < 0.3 &&
      allGood(values) &&
      num_journeys > 5
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `${person.name} has a choice of two routes when driving to tennis practice.`
      );
      question.addParagraph(
        `question`,
        `${person.HisHer} travel time for route one, $R_1$ minutes, can be assumed to be normally distributed with a mean time of $${mean_R1}$ minutes and a standard deviation of $${sd_R1}$ minutes.`
      );
      question.addParagraph(`question`, `a) Calculate:`);
      question.addParagraph(`question`, `i) $P(R_1<${au})$`);
      question.addParagraph(`question`, `ii) $P(${al}<R_1<${au})$`);
      question.addParagraph(
        "question",
        `The travel time for route two can also be modelled by a normal distribution, with a mean of $${mean_R2}$ minutes and a standard deviation of $${sd_R2}$ minutes.`
      );
      if (60 - au < 10) {
        question.addParagraph(
          "question",
          `${person.name} leaves ${person.hisher} house at $${t}:0${
            60 - au
          }$pm and must arrive at practice by $${t + 1}:00$pm.`
        );
      } else {
        question.addParagraph(
          "question",
          `${person.name} leaves ${person.hisher} house at $${t}:${
            60 - au
          }$pm and must arrive at practice by $${t + 1}:00$pm.`
        );
      }
      question.addParagraph(
        "question",
        `b) Determine which route ${person.heshe} should take.`
      );
      question.addParagraph(
        "question",
        `c) In a random sample of $${n}$ journeys via route two, state how many ${person.name} would expect to take more than $${au}$ minutes, assuming that each journey is independent.`
      );
      // solution
      // a
      question.addHeading(`solution`, `a) Calculate:`);
      question.addHeading(`solution`, `i) $P(R_1<${au})$`);
      question.addParagraph("solution", `$R_1 \\sim ${normal_R1.toString()}$`);
      question.addParagraph(
        `solution`,
        `$P(R_1<${au})=${normal_R1.cdf(au).toFixed(5)}$`
      );
      question.addParagraph(
        `solution`,
        `$\\approx ${normal_R1.cdf(au).toFixed(2)}$`
      );
      question.addHeading(`solution`, `ii) $P(${al}<R_1<${au})$`);
      question.addParagraph(
        `solution`,
        `$P(${al}<R_1<${au})=P(R_1<${au})-P(R_1<${al})$`
      );
      question.addParagraph(
        `solution`,
        `$=${normal_R1.cdf(au).toFixed(5)}-${normal_R1.cdf(al).toFixed(5)}$`
      );
      question.addParagraph(
        `solution`,
        `$=${(normal_R1.cdf(au) - normal_R1.cdf(al)).toFixed(5)}$`
      );
      question.addParagraph(
        `solution`,
        `$\\approx ${(normal_R1.cdf(au) - normal_R1.cdf(al)).toFixed(2)}$`
      );
      // b
      question.addHeading(
        "solution",
        `b) Determine which route ${person.heshe} should take.`
      );
      question.addHeading("solution", `$R_2 \\sim ${normal_R2.toString()}$`);
      question.addParagraph(
        "solution",
        `$P(R_2<${au})=${normal_R2.cdf(au).toFixed(5)}$`
      );
      if (normal_R2.cdf(au) < normal_R1.cdf(au)) {
        question.addParagraph(
          "solution",
          `$${normal_R1.cdf(au).toFixed(5)}>${normal_R2.cdf(au).toFixed(5)}$`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ There is a higher probability of ${person.name} arriving to practice on time if ${person.heshe} takes route one.`
        );
      } else {
        question.addParagraph(
          "solution",
          `$${normal_R2.cdf(au).toFixed(5)}>${normal_R1.cdf(au).toFixed(5)}$`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ There is a higher probability of ${person.name} arriving to practice on time if ${person.heshe} takes route two.`
        );
      }
      // c
      question.addHeading(
        "solution",
        `c) In a random sample of $${n}$ journeys via route two, state how many ${person.name} would expect to take more than $${au}$ minutes, assuming that each journey is independent.`
      );
      question.addParagraph("solution", `$P(R_2>${au})=1-P(R_2<${au})$`);
      question.addParagraph("solution", `$=1-${normal_R2.cdf(au).toFixed(5)}$`);
      question.addParagraph(
        "solution",
        `$=${(1 - normal_R2.cdf(au)).toFixed(5)}$`
      );
      question.addHeading("solution", `For $${n}$ journeys.`);
      question.addParagraph(
        "solution",
        `$${n}(${(1 - normal_R2.cdf(au)).toFixed(5)})=${num_journeys}$`
      );
      question.addParagraph(
        "solution",
        `$\\approx ${Math.round(parseFloat(num_journeys))}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ ${person.HeShe} would expect $${Math.round(
          parseFloat(num_journeys)
        )}$ of the $${n}$ journeys to take more than $${au}$ minutes.`
      );
      //
      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(parseFloat(normal_R1.cdf(au).toFixed(2)), 0.05, `ai)`);
      g1.addInput(
        parseFloat((normal_R1.cdf(au) - normal_R1.cdf(al)).toFixed(2)),
        0.05,
        `aii)`
      );
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(Math.round(parseFloat(num_journeys)), 0, `c)`);
      question.addInputGroup(g2);
      //
      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_NormalProbabilities_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const mean = MCRandom.randomInt(240, 260);
    const sd = MCRandom.randomInt(20, 40);

    const normal = new MCMaths.Normal(mean, sd);

    const offset1 = MCRandom.randomInt(10, 2 * sd);
    const p1 = normal.cdf(mean - offset1);

    const offset2 = MCRandom.randomInt(10, 2 * sd);
    const p2 = normal.cdf(mean - offset2);

    const b_ans = 1 - parseFloat(p1.toFixed(3)) - parseFloat(p2.toFixed(3));

    const nearest = [10, 20, 25, 50][MCRandom.randomInt(0, 3)];
    const from = [mean - (mean % nearest), mean + (nearest - (mean % nearest))][
      MCRandom.randomInt(0, 1)
    ];

    const c_ans =
      normal.cdf(from + nearest / 2) - normal.cdf(from - nearest / 2);
    /* END VARIABLES AND CONSTANTS */
    if (
      p1 > 0.1 &&
      p1 < 0.3 &&
      p2 > 0.1 &&
      p2 < 0.3 &&
      p1 !== p2 &&
      c_ans > 0.1 &&
      c_ans < 0.5
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The height, $H$ feet, of redwood trees in a forest can be modelled by a normal random variable with mean $${mean}$ and standard deviation $${sd}$.`
      );
      question.addParagraph(
        "question",
        `a) State the median height of redwood trees in the forest`
      );

      question.addParagraph(
        `question`,
        `Given that $P(H<l)=${p1.toFixed(3)}$ and $P(H>u)=${p2.toFixed(3)}$`
      );
      question.addParagraph(`question`, `b) Find $P(l<H<u)$`);
      question.addParagraph(
        `question`,
        `c) Find the probability that a tree is $${from}$ feet tall, correct to the nearest $${nearest}$ feet.`
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) State the median height of redwood trees in the forest`
      );
      question.addParagraph(
        "solution",
        `Mean = Median as the normal curve is symetrical about the mean, therefore 50% of the data lies either side of the mean.`
      );
      question.addParagraph("solution", `$\\therefore$ Median $=${mean}$ feet`);
      // b
      question.addHeading(`solution`, `b) Find $P(l<H<u)$`);
      question.addHeading(`solution`, `$P(l<H<u)=P(H<u) - P(H<l)$`);
      question.addHeading(`solution`, `$=(1-P(H>u)) - P(H<l)$`);
      question.addHeading(
        `solution`,
        `$=(1-${p2.toFixed(3)}) - ${p1.toFixed(3)}$`
      );
      question.addHeading(`solution`, `$=${b_ans.toFixed(3)}$`);
      question.addHeading(`solution`, `$\\approx ${b_ans.toFixed(2)}$`);

      // c
      question.addHeading(
        `solution`,
        `c) Find the probability that a tree is $${from}$ feet tall, correct to the nearest $${nearest}$ feet.`
      );
      question.addParagraph("solution", `$H \\sim ${normal.toString()}$`);
      question.addParagraph(
        "solution",
        `$P(${from - nearest / 2}<H<${from + nearest / 2})=P(H<${
          from + nearest / 2
        })-P(H<${from - nearest / 2})$`
      );
      question.addParagraph(
        "solution",
        `$=${normal.cdf(from + nearest / 2).toFixed(5)}-${normal
          .cdf(from - nearest / 2)
          .toFixed(5)}$`
      );
      question.addParagraph("solution", `$=${c_ans.toFixed(5)}$`);
      question.addParagraph("solution", `$\\approx ${c_ans.toFixed(2)}$`);
      //
      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(mean, 0, `a)`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(parseFloat(b_ans.toFixed(2)), 0.05, `b)`);
      question.addInputGroup(g2);

      const g3 = new MCQuestion.InputGroup(1);
      g3.addInput(parseFloat(c_ans.toFixed(2)), 0.05, `c)`);
      question.addInputGroup(g3);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_NormalProbabilities_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const mean = MCRandom.randomInt(8, 12);
    const sd = MCRandom.randomInt(10, 25) / 10;

    const nrml = new MCMaths.Normal(mean, sd);
    const nrml2 = new MCMaths.Normal(mean, sd * sd);

    const sign = ["<", ">"][MCRandom.randomInt(0, 1)];

    const v = MCRandom.randomInt(8, 12);

    let variance = sd * sd;
    for (let i = 1; i < 3; i += 1) {
      if (Math.abs(parseFloat(variance.toFixed(i) - variance)) < 0.001) {
        variance = variance.toFixed(i);
        break;
      }
    }

    const answers = [
      nrml.cdf(v).toFixed(3),
      (1 - nrml.cdf(v)).toFixed(3),
      nrml2.cdf(v).toFixed(3),
      (1 - nrml2.cdf(v)).toFixed(3),
    ];

    function allGood(items) {
      for (let i = 0; i < items.length; i += 1) {
        if (items[i] < 0.1 || items[i] > 0.9) {
          return false;
        }
      }
      return true;
    }
    function allDifferent(items) {
      for (let i = 0; i < items.length; i += 1) {
        for (let j = 0; j < items.length; j += 1) {
          if (
            Math.abs(parseFloat(items[i]) - parseFloat(items[j])) < 0.01 &&
            j !== i
          ) {
            return false;
          }
        }
      }
      return true;
    }

    /* END VARIABLES AND CONSTANTS */
    if (mean !== v && allGood(answers) && allDifferent(answers)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(4);
      question.addParagraph(
        `A random variable, $X$ can be modelled by the normal distribution such that $X \\sim N(${mean}, ${variance})$`
      );
      question.addParagraph(`Find $P(X${sign}${v})$`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`${answers[0]}`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`${answers[1]}`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`${answers[2]}`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`${answers[3]}`);

      if (sign === "<") {
        question.addAnswer(answer1, true);
        question.addAnswer(answer2, false);
        question.addAnswer(answer3, false);
        question.addAnswer(answer4, false);
      } else {
        question.addAnswer(answer1, false);
        question.addAnswer(answer2, true);
        question.addAnswer(answer3, false);
        question.addAnswer(answer4, false);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NormalDistribution_NormalProbabilities_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const mean = MCRandom.randomInt(95, 105);
    const sd = MCRandom.randomInt(8, 12);

    const p = (MCRandom.randomInt(5, 30) / 100).toFixed(2);

    const less = [false, true][MCRandom.randomInt(0, 1)];

    const answers = [
      (1 - 2 * parseFloat(p)).toFixed(2),
      (1 - parseFloat(p)).toFixed(2),
      2 * p,
      (0.5 + parseFloat(p)).toFixed(2),
    ];

    function allDifferent(items) {
      for (let i = 0; i < items.length; i += 1) {
        for (let j = 0; j < items.length; j += 1) {
          if (
            Math.abs(parseFloat(items[i]) - parseFloat(items[j])) < 0.01 &&
            j !== i
          ) {
            return false;
          }
        }
      }
      return true;
    }
    /* END VARIABLES AND CONSTANTS */
    if (allDifferent(answers)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Test scores in a class, $T$ can be assumed to be normally distributed with a mean of $u$ marks and a standard deviation of $\\sigma$ marks.`
      );
      if (less) {
        question.addParagraph(
          `The probability of a student achieving less than $u-n$  marks, where $n$ is a constant, is $${p}$`
        );
      } else {
        question.addParagraph(
          `The probability of a student achieving more than $u+n$  marks, where $n$ is a constant, is $${p}$`
        );
      }
      question.addParagraph(`Find $P(u-n<T<u+n)$`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`${answers[0]}`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`${answers[1]}`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`${answers[2]}`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`${answers[3]}`);

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_IterativeMethods_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x3_coeff = MCRandom.randomInt(1, 3);
    const x_coeff = MCRandom.randomInt(1, 6);
    const c = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);
    const func = {
      evaluate: function fx(x) {
        return (-c - x3_coeff * x ** 3) / x_coeff;
      },
      toString() {
        return MCMaths.cleaner(`\\frac{${-c}+${-x3_coeff}x^3}{${x_coeff}}`);
      },
    };

    const poly = new MCMaths.Polynomial([x3_coeff, 0, x_coeff, c]);
    const nm = new MCMaths.NumericalMethods(func, 0.75);

    const iterations = nm.iterate(20);

    let limits_tmp = [0, 0];
    const rounded = Math.round(iterations);
    if (rounded > iterations) {
      limits_tmp = [rounded - 0.5, rounded];
    } else {
      limits_tmp = [rounded, rounded + 0.5];
    }
    const limits = limits_tmp;

    const nm_question = new MCMaths.NumericalMethods(
      func,
      limits[0] / 2 + limits[1] / 2
    );

    let n = 0;
    for (let i = 1; i < 20; i += 1) {
      if (
        nm_question.iterate(i).toFixed(4) ===
          nm_question.iterate(i + 1).toFixed(4) &&
        nm_question.iterate(i).toFixed(4) === iterations.toFixed(4)
      ) {
        n = i + 2;
        break;
      }
    }
    /* END VARIABLES AND CONSTANTS */
    if (
      poly.evaluate(iterations) === 0 &&
      iterations < limits[1] - 0.05 &&
      iterations > limits[0] + 0.05 &&
      n < 6
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question.addGraph(`question`, poly.graph());
      //
      question.addParagraph(
        "question",
        `The graph of $y=${poly.toString()}$ intersects the x-axis once at the point $P(\\alpha ,0)$`
      );
      // question.addParagraph('question', `$${func.evaluate(iterations)},m,,,,${poly.evaluate(iterations)}$`);
      question.addParagraph(
        "question",
        `a) Show that $\\alpha $ lies between $${limits_tmp[0]}$ and $${limits_tmp[1]}$`
      );
      question.addParagraph(
        "question",
        `b) Show that $${poly.toString()}=0$ can be rearranged into the form $x=${MCMaths.cleaner(
          func.toString()
        )}$`
      );
      question.addParagraph(
        "question",
        `c) Using the iterative formula $x_{n+1}=${func
          .toString()
          .replace(/x/g, `x_n`)}$ with $x_1=${
          limits[0] / 2 + limits[1] / 2
        }$, find $\\alpha $ giving your answer to three decimal places`
      );
      // a
      question.addHeading(
        "solution",
        `a) Show that $\\alpha $ lies between $${limits_tmp[0]}$ and $${limits_tmp[1]}$`
      );
      question.addParagraph("solution", `$y=${poly.toString()}$`);
      question.addHeading("solution", `Let $x =${limits[0]}$`);
      question.addParagraph(
        "solution",
        `$${poly.toString().replace(/x/g, `(${limits[0]})`)} = ${poly.evaluate(
          limits[0]
        )}$`
      );
      question.addHeading("solution", `Let $x =${limits[1]}$`);
      question.addParagraph(
        "solution",
        `$${poly.toString().replace(/x/g, `(${limits[1]})`)} = ${poly.evaluate(
          limits[1]
        )}$`
      );
      question.addParagraph(
        "solution",
        `Change in sign therefore root, $\\alpha$ in the range $${limits[0]}<\\alpha <${limits[1]}$`
      );
      // b
      question.addHeading(
        "solution",
        `b) Show that $${poly.toString()}=0$ can be rearranged into the form $x=${MCMaths.cleaner(
          func.toString()
        )}$`
      );
      question.addParagraph("solution", `$${poly.toString()}=0$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${x_coeff}x=${-c}+${-x3_coeff}x^3$`)
      );
      question.addParagraph("solution", `$\\therefore x=${func.toString()}$`);
      // c
      question.addHeading(
        "solution",
        `c) Using the iterative formula $x_{n+1}=${func
          .toString()
          .replace(/x/g, `x_n`)}$ with $x_1=${
          limits[0] / 2 + limits[1] / 2
        }$, find $\\alpha $ giving your answer to three decimal places`
      );
      question.addMultipleParagraphs(`solution`, nm_question.iterateWorking(n));
      question.addParagraph(
        "solution",
        `$\\therefore \\alpha \\approx ${iterations.toFixed(3)}$`
      );
      //
      question.requiresSelfMarking();
      //
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(iterations.toFixed(3)),
        0,
        `c) $\\ \\alpha \\approx$`
      );
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_IterativeMethods_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const base = MCRandom.randomInt(2, 8);

    const m = MCRandom.randomInt(1, 8);
    const c = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);
    const iterations = MCRandom.randomInt(3, 5);

    const func = {
      evaluate: function fx(x) {
        return base ** x + m * x + c;
      },
      toString() {
        return MCMaths.cleaner(`${base}^{x}+${m}x+${c}`);
      },
    };
    const func_it = {
      evaluate: function fx(x) {
        return Math.log(-c - m * x) / Math.log(base);
      },
      toString() {
        return MCMaths.cleaner(`\\frac{\\ln(${-c}+${-m}x)}{\\ln(${base})}`);
      },
    };

    const nm = new MCMaths.NumericalMethods(func_it);
    const root = nm.iterate(20);

    let limits_tmp = [0, 0];
    const rounded = Math.round(root);
    if (rounded > root) {
      limits_tmp = [rounded - 0.5, rounded];
    } else {
      limits_tmp = [rounded, rounded + 0.5];
    }
    const limits = limits_tmp;

    const nm_question = new MCMaths.NumericalMethods(
      func_it,
      limits[0] / 2 + limits[1] / 2
    );
    const bounds = [
      (parseFloat(root.toFixed(3)) - 0.0005).toFixed(4),
      (parseFloat(root.toFixed(3)) + 0.0005).toFixed(4),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (
      func.evaluate(root) === 0 &&
      root < limits[1] - 0.05 &&
      root > limits[0] + 0.05 &&
      parseFloat(root.toFixed(3)) !== parseFloat(root.toFixed(2))
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `The curve $y=${func.toString()}$ crosses the  x-axis where $x=\\alpha$`
      );
      question.addParagraph(
        `question`,
        MCMaths.cleaner(
          `a) Show that the equation $${base}^x=${-c}+${-m}x$ , can be rearranged into the form $x=${func_it.toString()}$`
        )
      );
      question.addParagraph(
        `question`,
        `b) Using the iterative formula $x_{n+1}=${func_it
          .toString()
          .replace(/x/g, `x_n`)}$ with $x_1=${
          limits[0] / 2 + limits[1] / 2
        }$, find $x_${iterations}$ to three decimal places`
      );
      question.addParagraph(
        `question`,
        `c) Prove that $\\alpha =${root.toFixed(
          3
        )}$ is a root of $${func.toString()}=0$, correct to three decimal places`
      );
      // solution
      // a
      question.addHeading(
        `solution`,
        MCMaths.cleaner(
          `a) Show that the equation $${base}^x=${-c}+${-m}x$ , can be rearranged into the form $x=${func_it.toString()}$`
        )
      );
      question.addHeading(
        `solution`,
        MCMaths.cleaner(`$${base}^x=${-c}+${-m}x$`)
      );
      question.addHeading(
        `solution`,
        MCMaths.cleaner(`$x\\ln(${base})=\\ln(${-c}+${-m}x)$`)
      );
      question.addHeading(
        `solution`,
        MCMaths.cleaner(`$\\therefore x=${func_it.toString()}$`)
      );
      // b
      question.addHeading(
        `solution`,
        `b) Using the iterative formula $x_{n+1}=${func_it
          .toString()
          .replace(/x/g, `x_n`)}$ with $x_1=${
          limits[0] / 2 + limits[1] / 2
        }$, find $x_${iterations}$ to three decimal places`
      );
      question.addMultipleParagraphs(
        "solution",
        nm_question.iterateWorking(iterations)
      );
      question.addParagraph(
        "solution",
        `$\\therefore x_${iterations} \\approx ${nm_question
          .iterate(iterations)
          .toFixed(3)}$`
      );
      // c
      question.addHeading(
        `solution`,
        `c) Prove that $\\alpha =${root.toFixed(
          3
        )}$ is a root of $${func.toString()}=0$, correct to three decimal places`
      );
      question.addHeading(`solution`, `Let $x=${bounds[0]}$`);
      question.addParagraph(
        "solution",
        `$${func.toString().replace(/x/g, `(${bounds[0]})`)} = ${func.evaluate(
          bounds[0]
        )}$`
      );
      question.addHeading(`solution`, `Let $x=${bounds[1]}$`);
      question.addParagraph(
        "solution",
        `$${func.toString().replace(/x/g, `(${bounds[1]})`)} = ${func.evaluate(
          bounds[1]
        )}$`
      );
      question.addParagraph(
        "solution",
        `Change in sign therefore root $\\alpha $ in the range $${bounds[0]}<\\alpha <${bounds[1]}$`
      );
      //
      question.requiresSelfMarking();
      //
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(nm_question.iterate(iterations).toFixed(3)),
        0,
        `b) $\\ x_{${iterations}}\\approx$`
      );
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_IterativeMethods_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const trig = ["\\sin", "\\cos"][MCRandom.randomInt(0, 1)];
    const trig_coeff = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);

    const c = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);

    const x_coeff = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);

    const new_x = MCRandom.randomInt(2, 6);

    let func;
    let func_it;
    if (trig === "\\sin") {
      func = {
        evaluate: function fx(x) {
          return trig_coeff * Math.sin(x) ** 2 + x_coeff * x + c;
        },
        toString() {
          return MCMaths.cleaner(`${trig_coeff}\\sin^2(x)+${x_coeff}x+${c}`);
        },
      };
      func_it = {
        evaluate: function fx(x) {
          return (trig_coeff * Math.sin(x) ** 2 + c) / -x_coeff;
        },
        toString() {
          return MCMaths.cleaner(
            `\\frac{${trig_coeff}\\sin^2(x_n)+${c}}{${-x_coeff}}`
          );
        },
      };
    } else {
      func = {
        evaluate: function fx(x) {
          return trig_coeff * Math.cos(x) ** 2 + x_coeff * x + c;
        },
        toString() {
          return MCMaths.cleaner(`${trig_coeff}\\cos^2(x)+${x_coeff}x+${c}`);
        },
      };
      func_it = {
        evaluate: function fx(x) {
          return (trig_coeff * Math.cos(x) ** 2 + c) / -x_coeff;
        },
        toString() {
          return MCMaths.cleaner(
            `\\frac{${trig_coeff}\\cos^2(x_n)+${c}}{${-x_coeff}}`
          );
        },
      };
    }

    const nm = new MCMaths.NumericalMethods(func_it);
    const root = nm.iterate(20);

    let limits_tmp = [0, 0];
    const rounded = Math.round(root * 10);
    if (rounded > root * 10) {
      limits_tmp = [
        parseFloat((rounded / 10 - 0.1).toFixed(1)),
        parseFloat((rounded / 10).toFixed(1)),
      ];
    } else {
      limits_tmp = [
        parseFloat((rounded / 10).toFixed(1)),
        parseFloat((rounded / 10 + 0.1).toFixed(1)),
      ];
    }
    const limits = limits_tmp;

    const start = parseFloat((limits[0] / 2 + limits[1] / 2).toFixed(2));
    const nm_question = new MCMaths.NumericalMethods(func_it, start);

    let n = 0;
    for (let i = 1; i < 20; i += 1) {
      if (
        nm_question.iterate(i).toFixed(4) ===
          nm_question.iterate(i + 1).toFixed(4) &&
        nm_question.iterate(i).toFixed(4) === root.toFixed(4)
      ) {
        n = i + 2;
        break;
      }
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      func.evaluate(root) === 0 &&
      limits[0] !== 0 &&
      limits[1] !== 0 &&
      n < 6
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `$f(x)=${func.toString()}$`);
      question.addParagraph(
        "question",
        `a) Show that $f(x)=0$ has a root $\\alpha$ in the range $${limits[0]}<\\alpha <${limits[1]}$`
      );
      question.addParagraph(
        "question",
        `b) Using the iterative formula $x_{n+1}=${func_it.toString()}$ and an appropriate starting value, find $\\alpha$ correct to three decimal places`
      );
      if (trig === "\\sin") {
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `c) Hence find the solution to the equation $${-trig_coeff}\\cos^2(${new_x}x)+${
              x_coeff * new_x
            }x+${c + trig_coeff}=0$, to three decimal places if appropriate`
          )
        );
      } else {
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `c) Hence find the solution to the equation $${-trig_coeff}\\sin^2(${new_x}x)+${
              x_coeff * new_x
            }x+${c + trig_coeff}=0$, to three decimal places if appropriate`
          )
        );
      }
      // solution
      // a
      question.addHeading(
        "solution",
        `a) Show that $f(x)=0$ has a root $\\alpha$ in the range $${limits[0]}<\\alpha <${limits[1]}$`
      );
      question.addParagraph("solution", `$f(x)=${func.toString()}$`);
      question.addHeading("solution", `Let $x =${limits[0]}$`);
      question.addParagraph(
        "solution",
        `$${func
          .toString()
          .replace("(x)", `(${limits[0]})`)
          .replace("x", `(${limits[0]})`)} = ${func.evaluate(limits[0])}$`
      );
      question.addHeading("solution", `Let $x =${limits[1]}$`);
      question.addParagraph(
        "solution",
        `$${func
          .toString()
          .replace("(x)", `(${limits[1]})`)
          .replace("x", `(${limits[1]})`)} = ${func.evaluate(limits[1])}$`
      );
      question.addParagraph(
        "solution",
        `Change in sign therefore root $\\alpha$ in the range $${limits[0]}<\\alpha <${limits[1]}$`
      );
      // b
      question.addHeading(
        "solution",
        `b) Using the iterative formula $x_{n+1}=${func_it.toString()}$ and an appropriate starting value, find $\\alpha$ correct to three decimal places`
      );
      question.addMultipleParagraphs("solution", nm_question.iterateWorking(n));
      question.addParagraph(
        "solution",
        `$\\therefore \\alpha \\approx ${root.toFixed(3)}$`
      );
      // c
      if (trig === "\\sin") {
        question.addHeading(
          "solution",
          MCMaths.cleaner(
            `c) Hence find the solution to the equation $${-trig_coeff}\\cos^2(${new_x}x)+${
              x_coeff * new_x
            }x+${c + trig_coeff}=0$, to three decimal places if appropriate`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$${-trig_coeff}\\cos^2(${new_x}x)+${x_coeff * new_x}x+${
              c + trig_coeff
            }=0$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$${-trig_coeff}(1-\\sin^2(${new_x}x))+${x_coeff * new_x}x+${
              c + trig_coeff
            }=0$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$${trig_coeff}\\sin^2(${new_x}x)+${x_coeff * new_x}x+${c}=0$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$${trig_coeff}\\sin^2(${new_x}x)+${x_coeff}(${new_x}x)+${c}=0$`
          )
        );
      } else {
        question.addHeading(
          "solution",
          MCMaths.cleaner(
            `c) Hence find the solution to the equation $${-trig_coeff}\\sin^2(${new_x}x)+${
              x_coeff * new_x
            }x+${c + trig_coeff}=0$, to three decimal places if appropriate`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$${-trig_coeff}\\sin^2(${new_x}x)+${x_coeff * new_x}x+${
              c + trig_coeff
            }=0$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$${-trig_coeff}(1-\\cos^2(${new_x}x))+${x_coeff * new_x}x+${
              c + trig_coeff
            }=0$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$${trig_coeff}\\cos^2(${new_x}x)+${x_coeff * new_x}x+${c}=0$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$${trig_coeff}\\cos^2(${new_x}x)+${x_coeff}(${new_x}x)+${c}=0$`
          )
        );
      }
      question.addParagraph(
        "solution",
        `Root when $${new_x}x=${root.toFixed(3)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore x=${parseFloat(root.toFixed(3)) / new_x}$`
      );
      if (
        parseFloat(root.toFixed(3)) / new_x !==
        parseFloat((parseFloat(root.toFixed(3)) / new_x).toFixed(3))
      ) {
        question.addParagraph(
          "solution",
          `$\\approx${(parseFloat(root.toFixed(3)) / new_x).toFixed(3)}$`
        );
      }
      //
      question.requiresSelfMarking();
      //
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(root.toFixed(3)),
        0,
        `b) $\\ \\alpha \\approx$`
      );
      group1.addInput(
        parseFloat((parseFloat(root.toFixed(3)) / new_x).toFixed(3)),
        0.001,
        `c) $\\ x \\approx$`
      );
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_IterativeMethods_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x3_coeff = MCRandom.randomInt(1, 3);
    const x_coeff = MCRandom.randomInt(1, 6);
    const c = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);
    const k = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);
    const func = {
      evaluate: function fx(x) {
        return (-c - x3_coeff * x ** 3) / x_coeff;
      },
      toString() {
        return MCMaths.cleaner(`\\frac{${-c}+${-x3_coeff}x^3}{${x_coeff}}`);
      },
    };

    const poly = new MCMaths.Polynomial([x3_coeff, 0, x_coeff, c]);
    const nm = new MCMaths.NumericalMethods(func, 0.75);

    const root = nm.iterate(20);

    let limits_tmp = [0, 0];
    const rounded = Math.round(root * 10);
    if (rounded > root * 10) {
      limits_tmp = [rounded / 10 - 0.2, rounded / 10];
    } else {
      limits_tmp = [rounded / 10, rounded / 10 + 0.2];
    }
    const limits = limits_tmp;

    const nm_question = new MCMaths.NumericalMethods(
      func,
      limits[0] / 2 + limits[1] / 2
    );

    let n = 0;
    for (let i = 1; i < 20; i += 1) {
      if (
        nm_question.iterate(i).toFixed(4) ===
          nm_question.iterate(i + 1).toFixed(4) &&
        nm_question.iterate(i).toFixed(4) === root.toFixed(4)
      ) {
        n = i + 2;
        break;
      }
    }
    const bounds = [
      parseFloat((parseFloat(root.toFixed(3)) - 0.0005).toFixed(4)),
      parseFloat((parseFloat(root.toFixed(3)) + 0.0005).toFixed(4)),
    ];
    /* END VARIABLES AND CONSTANTS */
    if (
      poly.evaluate(root) === 0 &&
      root < limits[1] &&
      root > limits[0] &&
      n < 6 &&
      limits[0] !== 0 &&
      limits[1] !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `The curve $y=${x3_coeff / 4}x^4+${
            x_coeff / 2
          }x^2+${c}x+${k}$ has a turning point at $x=\\alpha$`
        )
      );
      question.addParagraph(
        "question",
        `a) Prove that $\\alpha$ lies between $${limits[0]}$ and $${limits[1]}$`
      );
      question.addParagraph(
        "question",
        `b) Using the iterative formula $x_{n+1}=${func
          .toString()
          .replace("x", "x_n")}$ with $x_1=${(
          limits[0] / 2 +
          limits[1] / 2
        ).toFixed(1)}$, find $\\alpha$ correct to three decimal places`
      );
      question.addParagraph(
        "question",
        `c) Prove that your answer to part b is correct to three decimal places`
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) Prove that $\\alpha$ lies between $${limits[0]}$ and $${limits[1]}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=${x3_coeff / 4}x^4+${x_coeff / 2}x^2+${c}x+${k}$`)
      );
      question.addParagraph("solution", `$\\frac{dy}{dx}=${poly.toString()}$`);
      question.addHeading("solution", `Let $x =${limits[0]}$`);
      question.addParagraph(
        "solution",
        `$${poly.toString().replace(/x/g, `(${limits[0]})`)} = ${poly
          .evaluate(limits[0])
          .toFixed(3)}$`
      );
      question.addHeading("solution", `Let $x =${limits[1]}$`);
      question.addParagraph(
        "solution",
        `$${poly.toString().replace(/x/g, `(${limits[1]})`)} = ${poly
          .evaluate(limits[1])
          .toFixed(3)}$`
      );
      question.addParagraph(
        "solution",
        `Change in sign therefore root $\\alpha$ in the range $${limits[0]}<\\alpha <${limits[1]}$`
      );
      // b
      question.addHeading(
        "solution",
        `b) Using the iterative formula $x_{n+1}=${func
          .toString()
          .replace("x", "x_n")}$ with $x_1=${(
          limits[0] / 2 +
          limits[1] / 2
        ).toFixed(1)}$, find $\\alpha$ correct to three decimal places`
      );
      question.addParagraph(
        "solution",
        `$x_1=${(limits[0] / 2 + limits[1] / 2).toFixed(1)}$`
      );
      question.addMultipleParagraphs(
        `solution`,
        nm_question.iterateWorking(n).slice(1, n)
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\alpha \\approx ${root.toFixed(3)}$`
      );
      // c
      question.addHeading(
        "solution",
        `c) Prove that your answer to part b is correct to three decimal places`
      );
      question.addParagraph("solution", `$\\frac{dy}{dx}=${poly.toString()}$`);
      question.addHeading("solution", `Let $x =${bounds[0]}$`);
      question.addParagraph(
        "solution",
        `$${poly.toString().replace(/x/g, `(${bounds[0]})`)} = ${poly.evaluate(
          bounds[0]
        )}$`
      );
      question.addHeading("solution", `Let $x =${bounds[1]}$`);
      question.addParagraph(
        "solution",
        `$${poly.toString().replace(/x/g, `(${bounds[1]})`)} = ${poly.evaluate(
          bounds[1]
        )}$`
      );
      question.addParagraph(
        "solution",
        `Change in sign therefore root $\\alpha$ in the range $${bounds[0]}<\\alpha <${bounds[1]}$`
      );
      //
      question.requiresSelfMarking();
      //
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(root.toFixed(3)),
        0,
        `b) $\\ \\alpha \\approx$`
      );
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_IterativeMethods_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const pow = MCRandom.randomInt(1, 5);
    const m = MCRandom.randomInt(1, 6) * -1;
    const c = MCRandom.randomInt(4, 8);
    const func = {
      evaluate: function fx(x) {
        return Math.E ** (pow * x) - Math.sqrt(m * x + c);
      },
    };
    const func_it = {
      evaluate: function fx(x) {
        return (1 / (2 * pow)) * Math.log(m * x + c);
      },
      toString() {
        return MCMaths.cleaner(`${1 / (2 * pow)}\\ln(${m}x+${c})`);
      },
    };

    const nm = new MCMaths.NumericalMethods(func_it);
    const root = nm.iterate(20);

    let limits_tmp = [0, 0];
    const rounded = Math.round(root);
    if (rounded > root * 10) {
      limits_tmp = [
        parseFloat((rounded / 10 - 0.5).toFixed(1)),
        parseFloat((rounded / 10).toFixed(1)),
      ];
    } else {
      limits_tmp = [
        parseFloat((rounded / 10).toFixed(1)),
        parseFloat((rounded / 10 + 0.5).toFixed(1)),
      ];
    }
    const limits = limits_tmp;

    const start = parseFloat((limits[0] / 2 + limits[1] / 2).toFixed(2));
    const nm_question = new MCMaths.NumericalMethods(func_it, start);
    /* END VARIABLES AND CONSTANTS */
    if (func.evaluate(root) === 0 && root < limits[1] && root > limits[0]) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        MCMaths.cleaner(
          `The curves $y=e^{${pow}x}$ and $y=\\sqrt{${m}x+${c}}$ meet at point $P$`
        )
      );
      question.addParagraph(
        `question`,
        `a) Show that the x-coordinate of $P$ satisfies the equation $x=${func_it.toString()}$`
      );
      question.addParagraph(
        `question`,
        `b) Using the iterative formula $x_{n+1}=${func_it
          .toString()
          .replace(
            "x",
            "x_n"
          )}$ with $x_1=${start}$, find $x_4$ to three decimal places`
      );
      question.addParagraph(
        `question`,
        `c) State a value of $x_1$ for which the iterative formula in part b does not produce a valid value for $x_2$`
      );
      // solution
      // a
      question.addHeading(
        `solution`,
        `a) Show that the x-coordinate of $P$ satisfies the equation $x=${func_it.toString()}$`
      );
      question.addHeading(`solution`, `At point $P$`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$e^{${pow}x}=\\sqrt{${m}x+${c}}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$e^{${pow}x}=(${m}x+${c})^{${1 / 2}}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${pow}x=${1 / 2}\\ln(${m}x+${c})$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$x=${1 / (2 * pow)}\\ln(${m}x+${c})$`)
      );

      // b
      question.addHeading(
        `solution`,
        `b) Using the iterative formula $x_{n+1}=${func_it
          .toString()
          .replace(
            "x",
            "x_n"
          )}$ with $x_1=${start}$, find $x_4$ to three decimal places`
      );
      question.addMultipleParagraphs("solution", nm_question.iterateWorking(4));
      question.addParagraph(
        "solution",
        `$\\approx ${nm_question.iterate(3).toFixed(3)}$`
      );
      // c
      question.addHeading(
        `solution`,
        `c) State a value of $x_1$ for which the iterative formula in part b does not produce a valid value for $x_2$`
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `Any values of $x$ where $${m}x+${c}<0$ as $\\ln(k)$ is only defined for $k>0$`
        )
      );
      question.addParagraph(
        `solution`,
        `For example $x_1=${Math.floor(-c / m) - 1}$`
      );
      //
      question.requiresSelfMarking();
      //
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(nm_question.iterate(3).toFixed(3)),
        0,
        `b) $\\ x_4 \\approx$`
      );
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_IterativeMethods_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m = MCRandom.randomInt(-6, -1);
    const c = MCRandom.randomInt(-12, -4);

    const func = {
      evaluate: function fx(x) {
        return Math.tan(x) * Math.tan(x) + m * x + c;
      },
      toString() {
        return MCMaths.cleaner(`\\tan^2(x)+${m}x+${c}`);
      },
    };
    const func_it = {
      evaluate: function fx(x) {
        return Math.atan(Math.sqrt(-c - m * x));
      },
      toString() {
        return MCMaths.cleaner(`\\tan^{-1}(\\sqrt{${-m}x+${-c}})`);
      },
    };

    const nm = new MCMaths.NumericalMethods(func_it);
    const root = nm.iterate(20);

    let limits_tmp = [0, 0];
    const rounded = Math.round(root * 10);
    if (rounded > root * 10) {
      limits_tmp = [
        parseFloat((rounded / 10 - 0.1).toFixed(1)),
        parseFloat((rounded / 10).toFixed(1)),
      ];
    } else {
      limits_tmp = [
        parseFloat((rounded / 10).toFixed(1)),
        parseFloat((rounded / 10 + 0.1).toFixed(1)),
      ];
    }
    const limits = limits_tmp;

    const start = parseFloat((limits[0] / 2 + limits[1] / 2).toFixed(2));
    const nm_question = new MCMaths.NumericalMethods(func_it, start);
    const bounds = [
      (parseFloat(root.toFixed(3)) - 0.0005).toFixed(4),
      (parseFloat(root.toFixed(3)) + 0.0005).toFixed(4),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (func.evaluate(root) === 0 && limits[0] !== 0 && limits[1] !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The sequence defined by $x_1=${start}, x_{n+1}=${func_it
          .toString()
          .replace(
            "x",
            "x_n"
          )}$, where $x$ is measured in radians, converges to a value $\\alpha$`
      );
      question.addParagraph(
        "question",
        `a) Prove that $\\alpha$ lies in the range $${limits[0]}<\\alpha <${limits[1]}$`
      );
      question.addParagraph(
        "question",
        `b) Find $x_3$ correct to two decimal places`
      );
      question.addParagraph(
        "question",
        `c) Prove that $\\alpha=${root.toFixed(
          3
        )}$ is correct to three decimal places`
      );
      // a
      question.addHeading(
        "solution",
        `a) Prove that $\\alpha$ lies in the range $${limits[0]}<\\alpha <${limits[1]}$`
      );
      question.addParagraph(
        "solution",
        `The series converges to a value where $x_n=x_{n+1}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore x=${func_it.toString()}$`
      );
      question.addParagraph("solution", `$${func_it.toString()}-x=0$`);
      question.addHeading(`solution`, `Let $x=${limits[0]}$`);
      question.addParagraph(
        "solution",
        `$${func_it.toString().replace(/x/g, `(${limits[0]})`)}-(${
          limits[0]
        }) = ${func.evaluate(limits[0])}$`
      );
      question.addHeading(`solution`, `Let $x=${limits[1]}$`);
      question.addParagraph(
        "solution",
        `$${func_it.toString().replace(/x/g, `(${limits[1]})`)}-(${
          limits[1]
        }) = ${func.evaluate(limits[1])}$`
      );
      question.addParagraph(
        "solution",
        `Change in sign therefore $\\alpha $ in the range $${limits[0]}<\\alpha <${limits[1]}$`
      );
      // b
      question.addHeading(
        "solution",
        `b) Find $x_3$ correct to two decimal places`
      );
      question.addParagraph(
        "solution",
        `$x_{n+1}=${func_it.toString().replace("x", "x_n")}$`
      );
      question.addMultipleParagraphs(`solution`, nm_question.iterateWorking(3));
      question.addParagraph(
        "solution",
        `$\\approx ${nm_question.iterate(2).toFixed(2)}$`
      );
      // c
      question.addHeading(
        "solution",
        `c) Prove that $\\alpha=${root.toFixed(
          3
        )}$ is correct to three decimal places`
      );
      question.addParagraph("solution", `$${func_it.toString()}-x=0$`);
      question.addHeading(`solution`, `Let $x=${bounds[0]}$`);
      question.addParagraph(
        "solution",
        `$${func_it.toString().replace(/x/g, `(${bounds[0]})`)}-(${
          bounds[0]
        }) = ${func.evaluate(bounds[0])}$`
      );
      question.addHeading(`solution`, `Let $x=${bounds[1]}$`);
      question.addParagraph(
        "solution",
        `$${func_it.toString().replace(/x/g, `(${bounds[1]})`)}-(${
          bounds[1]
        }) = ${func.evaluate(bounds[1])}$`
      );
      question.addParagraph(
        "solution",
        `Change in sign therefore $\\alpha $ in the range $${bounds[0]}<\\alpha <${bounds[1]}$`
      );
      //
      question.requiresSelfMarking();
      //
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(nm_question.iterate(2).toFixed(2)),
        0,
        `b) $\\ x_3 \\approx$`
      );
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_IterativeMethods_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here#
    const x5_coeff = MCRandom.randomInt(1, 4);
    const x_coeff = MCRandom.randomInt(2, 12);
    const c = MCRandom.randomInt(30, 60) * -1;
    const offset = MCRandom.randomInt(10, 20) * MCRandom.randomInt(-1, 1, 2);

    const func = {
      evaluate: function fx(x) {
        return x5_coeff * x ** 5 + x_coeff * x + c;
      },
      toString() {
        return MCMaths.cleaner(`${x5_coeff}x^{5}+${x_coeff}x+${c}`);
      },
    };
    const func_it = {
      evaluate: function fx(x) {
        return (-c / x5_coeff - (x_coeff * x) / x5_coeff) ** 0.2;
      },
      toString() {
        return MCMaths.cleaner(
          `(${-c / x5_coeff}+${-x_coeff / x5_coeff}x)^{${0.2}}`
        );
      },
    };

    const nm = new MCMaths.NumericalMethods(func_it);
    const root = nm.iterate(20);

    let limits_tmp = [0, 0];
    const rounded = Math.round(root * 10);
    if (rounded > root * 10) {
      limits_tmp = [rounded / 10 - 0.2, rounded / 10];
    } else {
      limits_tmp = [rounded / 10, rounded / 10 + 0.2];
    }
    const limits = limits_tmp;

    const nm_question = new MCMaths.NumericalMethods(
      func_it,
      limits[0] / 2 + limits[1] / 2
    );
    const bounds = [
      (parseFloat(root.toFixed(3)) - 0.0005).toFixed(4),
      (parseFloat(root.toFixed(3)) + 0.0005).toFixed(4),
    ];

    let n = 0;
    for (let i = 1; i < 20; i += 1) {
      if (
        nm_question.iterate(i).toFixed(4) ===
          nm_question.iterate(i + 1).toFixed(4) &&
        nm_question.iterate(i).toFixed(4) === root.toFixed(4)
      ) {
        n = i + 2;
        break;
      }
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      func.evaluate(root) === 0 &&
      limits[0] !== 0 &&
      limits[1] !== 0 &&
      n < 6
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`$f(x)=${x5_coeff}x^5+${x_coeff}x+${c + offset}$`)
      );
      question.addParagraph(
        `question`,
        `It is given that $f(x)=${offset}$ at $x=\\alpha$`
      );
      question.addParagraph(
        `question`,
        `a) Prove that $\\alpha$ lies in the range $${limits[0].toFixed(
          1
        )}<\\alpha <${limits[1].toFixed(1)}$`
      );
      question.addParagraph(
        `question`,
        `b) Find $\\alpha$, correct to three decimal places, using the iterative formula $x_{n+1}=${func_it
          .toString()
          .replace("x", "x_n")}$ with $x_1=${(
          limits[0] / 2 +
          limits[1] / 2
        ).toFixed(1)}$`
      );
      question.addParagraph(
        `question`,
        `c) By choosing a suitable interval, prove that $\\alpha=${root.toFixed(
          3
        )}$ is correct to three decimal places `
      );
      // solution
      // a
      question.addHeading(
        `solution`,
        `a) Prove that $\\alpha$ lies in the range $${limits[0].toFixed(
          1
        )}<\\alpha <${limits[1].toFixed(1)}$`
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$f(x)=${x5_coeff}x^5+${x_coeff}x+${c + offset}$`)
      );
      question.addHeading(`solution`, `$f(x)=${offset}$ at $x=\\alpha$`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${x5_coeff}x^5+${x_coeff}x+${c + offset}=${offset}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${x5_coeff}x^5+${x_coeff}x+${c}=0$`)
      );
      question.addHeading("solution", `Let $x =${limits[0].toFixed(1)}$`);
      question.addParagraph(
        "solution",
        `$${func
          .toString()
          .replace("(x)", `(${limits[0].toFixed(1)})`)
          .replace(/x/g, `(${limits[0].toFixed(1)})`)} = ${func
          .evaluate(limits[0])
          .toFixed(4)}$`
      );
      question.addHeading("solution", `Let $x =${limits[1].toFixed(1)}$`);
      question.addParagraph(
        "solution",
        `$${func
          .toString()
          .replace("(x)", `(${limits[1].toFixed(1)})`)
          .replace(/x/g, `(${limits[1].toFixed(1)})`)} = ${func
          .evaluate(limits[1])
          .toFixed(4)}$`
      );
      question.addParagraph(
        "solution",
        `Change in sign therefore root $\\alpha$ in the range $${limits[0].toFixed(
          1
        )}<\\alpha <${limits[1].toFixed(1)}$`
      );
      // b
      question.addHeading(
        `solution`,
        `b) Find $\\alpha$, correct to three decimal places, using the iterative formula $x_{n+1}=${func_it
          .toString()
          .replace("x", "x_n")}$ with $x_1=${(
          limits[0] / 2 +
          limits[1] / 2
        ).toFixed(1)}$`
      );
      question.addParagraph(
        "solution",
        `$x_1=${(limits[0] / 2 + limits[1] / 2).toFixed(1)}$`
      );
      question.addMultipleParagraphs(
        "solution",
        nm_question.iterateWorking(n).slice(1, n)
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\alpha \\approx ${root.toFixed(3)}$`
      );
      // c
      question.addHeading(
        `solution`,
        `c) By choosing a suitable interval, prove that $\\alpha=${root.toFixed(
          3
        )}$ is correct to three decimal places `
      );
      question.addHeading(`solution`, `Let $x=${bounds[0]}$`);
      question.addParagraph(
        "solution",
        `$${func.toString().replace(/x/g, `(${bounds[0]})`)} = ${func.evaluate(
          bounds[0]
        )}$`
      );
      question.addHeading(`solution`, `Let $x=${bounds[1]}$`);
      question.addParagraph(
        "solution",
        `$${func.toString().replace(/x/g, `(${bounds[1]})`)} = ${func.evaluate(
          bounds[1]
        )}$`
      );
      question.addParagraph(
        "solution",
        `Change in sign therefore root $\\alpha $ in the range $${bounds[0]}<\\alpha <${bounds[1]}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(root.toFixed(3)),
        0,
        `b) $\\ \\alpha \\approx$`
      );
      question.addInputGroup(group1);

      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_NewtonRaphsonMethod_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const s = MCRandom.randomInt(0, 1);
    const x = new MCMaths.Polynomial([
      s,
      1 - s,
      MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 4) * -1,
    ]);
    const a = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const ans1 = x.derivative().evaluate(a);
    const ans2 = a - x.evaluate(a) / ans1;
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(ans1) < 10 &&
      Math.abs(ans1) > 1 &&
      Math.abs(ans2) < 10 &&
      Math.abs(ans2) > 1 &&
      Math.round(ans2) === ans2 &&
      a !== ans2 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$f(x)=${x.toString()}$`,
        `a) Find the value of $f'(${a})$.`,
        `b) Taking $${a}$ as a first approximation to a root of $f$, use the Newton-Raphson process once once to find a better approximation.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Find Derivative:`,
        `$f'(x)=${x.derivative().toString()}$`,
        `HEADING Evaluating:`,
        `$f'(${a})=${x.derivative().toString()}$`.replace(/x/g, `(${a})`),
        `$=${ans1}$`,
        `b) HEADING Newton-Raphson process:`,
        `$\\displaystyle = ${a} - \\frac{f(${a})}{f'(${a})}$`,
        `$\\displaystyle = ${a} - \\frac{${x.evaluate(a)}}{${ans1}}$`,
        `$=${ans2}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans1, 0, "a)");
      group.addInput(ans2, 0, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_NewtonRaphsonMethod_Exam_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const x = new MCMaths.Polynomial([
      (MCRandom.randomInt(-1, 1, 2) * MCRandom.randomInt(1, 9)) / 3,
      0,
      (MCRandom.randomInt(1, 18) * MCRandom.randomInt(-1, 1, 2)) / 3,
      0,
    ]);
    const i1 =
      Math.sqrt(
        -x.derivative().getCoefficients()[2] /
          x.derivative().getCoefficients()[0]
      ) * MCRandom.randomInt(-1, 1, 2);
    const i2 = MCRandom.randomInt(1, 5);
    const ans3 = i2 - x.evaluate(i2) / x.derivative().evaluate(i2);
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(i1) === i1 &&
      Math.round(x.evaluate(i2)) === x.evaluate(i2) &&
      Math.round(100 * ans3) !== ans3 &&
      Math.abs(ans3) < 20 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$\\displaystyle f(x)=${x.toString()}$`,
        `a) Find $f'(${i1})$.`,
        `b) Explain why $${i1}$ would not be a suitable first approximation for a root of $f$ if using the Newton Raphson method.`,
        `c) Use the Newton Raphson method once to find a second approximation to an inital approximation of $${i2}$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING Finding derivative:`,
        `$f'(x)=${x.derivative().toString()}$`,
        `HEADING Evaluating:`,
        `$f'(${i1})=${x.derivative().toString().replace(/x/g, `(${i1})`)}$`,
        `$=${x.derivative().evaluate(i1)}$`,
        `b) HEADING From part (a), $f'(${i1})=0$ so would result in division by zero.`,
        `HEADING Alternatively, $x=${i1}$ is a turning point is an acceptable answer.`,
        `c) HEADING Evaluating:`,
        `$f(${i2})=${x.toString().replace(/x/g, `(${i2})`)}$`,
        MCMaths.cleaner(`$=${x.evaluate(i2)}$`),
        `$f'(${i2})=${x.derivative().toString().replace(/x/g, `(${i2})`)}$`,
        `$=${x.derivative().evaluate(i2)}$`,
        `HEADING Newton Raphson Method:`,
        `$\\displaystyle = ${i2} - \\frac{f(${i2})}{f'(${i2})}$`,
        `$\\displaystyle = ${i2} - \\frac{${x.evaluate(
          i2
        )}}{${x.derivative().evaluate(i2)}}$`,
        MCMaths.cleaner(`$\\displaystyle = ${ans3}$`),
      ]);
      question.requiresSelfMarking();
      const group = new MCQuestion.InputGroup(1);
      group.addInput(0, 0, "a)");
      group.addInput(ans3, 0.05, "c)");
      question.addInputGroup(group);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_NewtonRaphsonMethod_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(5, 10) / 10;
    const working1 = new MCMaths.ChainRule(
      new MCMaths.Exponential(-1, "e"),
      new MCMaths.Polynomial([-1, 0, 0])
    );
    function f1(x) {
      return -Math.exp(-x * x) + (x * x) / 10;
    }
    function fdash(x) {
      return 2 * x * Math.exp(-x * x) + x / 5;
    }
    const f1d = `-1*Math.exp(-1*x * x) + (x * x) / 10`;
    const ans1 = a - f1(a) / fdash(a);
    const ans1Round = Math.round(ans1 * 100) / 100;
    const ans2 = ans1 - f1(ans1) / fdash(ans1);
    const ans2Round = Math.round(ans2 * 100) / 100;

    const graph = new MCQuestion.Graph(3, -3, 3, -3, 1, 1);
    graph.plot(f1d, -3, 3);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addGraph("question", graph);
      question.addMultipleParagraphs("question", [
        `The depth of a cross section of a river is modelled by the equation $y=-e^{-x^2}+\\frac{x^2}{10}$.`,
        `The water level is at $y=0$`,
        MCMaths.cleaner(
          `a) Using $${a}$ as a first estimate, using the Newton-Raphson process once, find a better estimate for the $x$ coordinate of the riverbank. Give your answer to 2dp.`
        ),
        `b) Using your result from part (a) as an estimate, repeat the process to improve your estimate. Give your answer to 2dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING Riverbank found when when river has depth zero, using Newton-Raphson to find roots of $y$:`,
        `${working1.derivativeWorking()[0]}:`,
        working1.derivativeWorking()[1],
        working1.derivativeWorking()[2],
        working1.derivativeWorking()[3],
        working1.derivativeWorking()[4],
        working1.derivativeWorking()[5],
        `$\\displaystyle \\frac{dy}{dx} = 2xe^{-x^2}+\\frac{x}{5}$`,
        `HEADING Newton-Raphson process:`,
        MCMaths.cleaner(
          `$\\displaystyle = ${a} - \\frac{f\\left(${a}\\right)}{f'\\left(${a}\\right)}$`
        ),
        MCMaths.cleaner(
          `$\\displaystyle = ${a} - \\frac{-e^{${-a * a}}+${(a * a) / 10}}{${
            2 * a
          }e^{${-a * a}}+${a / 5}}$`
        ),
        `Riverbank is at $\\approx ${ans1Round}$`,
        `b)HEADING Newton-Raphson process:`,
        MCMaths.cleaner(
          `$\\displaystyle = ${ans1Round} - \\frac{f\\left(${ans1Round}\\right)}{f'\\left(${ans1Round}\\right)}$`
        ),
        MCMaths.cleaner(
          `$\\displaystyle = ${ans1Round} - \\frac{-e^{${
            -Math.round(ans1Round * ans1Round * 100) / 100
          }}+${Math.round(ans1Round * ans1Round * 10) / 100}}{${
            2 * ans1Round
          }e^{${-Math.round(ans1Round * ans1Round * 100) / 100}}+${
            ans1Round / 5
          }}$`
        ),
        `Riverbank is at $\\approx ${ans2Round}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans1Round, 0.1, "a)");
      group.addInput(ans2Round, 0.1, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_NewtonRaphsonMethod_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(10, 80, 5);
    const initial = [7, 9, 10, 10, 12, 13, 14, 14, 15, 13, 13, 10, 9, 6, 4];
    const i = initial[a / 5 - 2];

    function f(x) {
      return (
        (-x * x) / (30 * Math.cos((a * Math.PI) / 180)) +
        x * Math.sin((a * Math.PI) / 180) +
        1
      );
    }
    function fdash(x) {
      return (
        -x / (15 * Math.cos((a * Math.PI) / 180)) +
        Math.sin((a * Math.PI) / 180)
      );
    }
    const ans1 = i - f(i) / fdash(i);
    const ans1Round = Math.round(ans1 * 100) / 100;
    const ans2 = ans1 - f(ans1) / fdash(ans1);
    const ans2Round = Math.round(ans2 * 100) / 100;
    /* END VARIABLES AND CONSTANTS */
    if (
      a !== 30 &&
      a !== 60 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A ball is thown, and flies in a path given by the equation $\\displaystyle y=-\\frac{x^2}{30\\cos(${a})}+\\sin(${a})x+1$.`,
        `a) Starting with an initial approximation of $x=${i}$, use the Newton-Raphson once process to find a better estimate for the $x$ coordinate of where the ball lands. Give your answer to 2dp`,
        `b) Using your result from part (a) as an estimate, repeat the process to improve your estimate. Give your answer to 2dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding Derivative:`,
        `$\\displaystyle \\frac{dy}{dx} = -\\frac{x}{15\\cos(${a})}+\\sin(${a})$`,
        `HEADING Newton-Raphson process:`,
        `$\\displaystyle = ${i} - \\frac{f(${i})}{f'(${i})}$`,
        `$\\displaystyle = ${i} - \\frac{-\\frac{${i}^2}{30\\cos(${a})}+\\sin(${a})\\cdot${i}+1}{-\\frac{${i}}{15\\cos(${a})}+\\sin(${a})}$`,
        `$\\approx ${ans1Round}$`,
        `b) HEADING Newton-Raphson process:`,
        `$\\displaystyle = ${ans1Round} - \\frac{f(${ans1Round})}{f'(${ans1Round})}$`,
        `$\\displaystyle = ${ans1Round} - \\frac{-\\frac{${ans1Round}^2}{30\\cos(${a})}+\\sin(${a})\\cdot${ans1Round}+1}{-\\frac{${ans1Round}}{15\\cos(${a})}+\\sin(${a})}$`,
        `$\\approx ${ans2Round}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans1Round, 0.1, "a)");
      group.addInput(ans2Round, 0.1, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_NewtonRaphsonMethod_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const x = new MCMaths.Polynomial([
      (Math.PI * MCRandom.randomInt(-1, 1, 2) * MCRandom.randomInt(1, 18)) / 6,
      0,
      (Math.PI * MCRandom.randomInt(-1, 1, 2) * MCRandom.randomInt(1, 18)) / 6,
      MCRandom.randomInt(-1, 1, 2) * MCRandom.randomInt(1, 4),
    ]);
    const initial = MCRandom.randomInt(3, 8);
    const m = new MCMaths.NumericalMethods(x, initial);
    function f(t) {
      return t - x.evaluate(t) / x.derivative().evaluate(t);
    }
    const x1 = f(initial);
    const x2 = f(x1);
    const x3 = f(x2);

    const x1r = Math.round(1000 * f(initial)) / 1000;
    const x2r = Math.round(1000 * f(x1)) / 1000;
    const x3r = Math.round(1000 * f(x2)) / 1000;
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(x3 - x2) > 0.3 &&
      Math.abs(x1 - x2) > 0.3 &&
      Math.abs(x.evaluate(x3)) <
        0.01 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$\\displaystyle f(x)=${x.toString()}$. Starting with an initial estimate of $x_0=${initial}$, find 3 better approximations for the roots $f$ using the Newton Raphson Method.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Finding Derivatives:`,
        `$\\displaystyle f'(x)=${x.derivative().toString()}$`,
        `HEADING Newton Raphson Method:`,
        `$\\displaystyle x_{n+1} = \\frac{${x
          .toString()
          .replace(/x/g, "x_n")}}{${x
          .derivative()
          .toString()
          .replace(/x/g, "x_n")}}$`,
        `$\\displaystyle x_1 = \\frac{${x
          .toString()
          .replace(
            /x/g,
            `\\left(${initial}\\right)`
          )}}{${x
          .derivative()
          .toString()
          .replace(/x/g, `\\left(${initial}\\right)`)}}$`,
        `$\\approx ${x1r}$`,
        `$\\displaystyle x_2 = \\frac{${x
          .toString()
          .replace(
            /x/g,
            `\\left(${x1r}\\right)`
          )}}{${x
          .derivative()
          .toString()
          .replace(/x/g, `\\left(${x1r}\\right)`)}}$`,
        `$\\approx ${x2r}$`,
        `$\\displaystyle x_3 = \\frac{${x
          .toString()
          .replace(
            /x/g,
            `\\left(${x2r}\\right)`
          )}}{${x
          .derivative()
          .toString()
          .replace(/x/g, `\\left(${x2r}\\right)`)}}$`,
        `$\\approx ${x3r}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(x1, 0.1, "$x_1=$");
      group.addInput(x2, 0.1, "$x_2=$");
      group.addInput(x3, 0.1, "$x_3=$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_NewtonRaphsonMethod_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 10);
    const b = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);
    const initial = MCRandom.randomInt(1, 50) / 10;

    function f(x) {
      return x - (1 / (x * x) - a * x - b) / (-2 / (x * x * x) - a);
    }

    const x1 = f(initial);
    const x2 = f(x1);
    const x3 = f(x2);

    const x1r = Math.round(1000 * f(initial)) / 1000;
    const x2r = Math.round(1000 * f(x1)) / 1000;
    const x3r = Math.round(1000 * f(x2)) / 1000;

    function g(x) {
      return 1 / (x * x) - a * x - b;
    }
    /* END VARIABLES AND CONSTANTS */
    if (
      x1 > 0 &&
      x1 !== x1r &&
      Math.abs(g(x3)) < 0.3 &&
      Math.abs(x3 - x2) > 0.3 &&
      Math.abs(x1 - x2) > 0.3 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        MCMaths.cleaner(
          `$\\displaystyle g(x) = \\frac{1}{x^2},\\ h(x) = ${a}x+${b}$.`
        ),
        `Using the Newton Raphson Method with $x_0=${initial}$, find three better approximations for the value of $x$ where $g(x)=h(x)$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Forming Equation:`,
        MCMaths.cleaner(`$\\displaystyle  \\frac{1}{x^2} = ${a}x+${b}$`),
        MCMaths.cleaner(`$\\displaystyle  \\frac{1}{x^2} - ${a}x-${b} = 0$`),
        MCMaths.cleaner(
          `Set $\\displaystyle f(x) = \\frac{1}{x^2} - ${a}x-${b}$`
        ),
        `HEADING Finding Derivative:`,
        MCMaths.cleaner(`$\\displaystyle f'(x) = -\\frac{2}{x^3}-${a}$`),
        `HEADING Newton Raphson Method:`,
        MCMaths.cleaner(
          `$\\displaystyle x_{n+1} = x_n - \\frac{\\frac{1}{x_n^2} - ${a}x_n-${b}}{-\\frac{2}{x_n^3}-${a}}$`
        ),
        MCMaths.cleaner(
          `$\\displaystyle x_1 = ${initial} - \\frac{\\frac{1}{${initial}^2} - ${a}\\cdot${initial}-${b}}{-\\frac{2}{${initial}^3}-${a}}$`,
          false,
          false
        ),
        `$\\approx ${x1r}$`,
        MCMaths.cleaner(
          `$\\displaystyle x_2 = ${x1r} - \\frac{\\frac{1}{${x1r}^2} - ${a}\\cdot${x1r}-${b}}{-\\frac{2}{${x1r}^3}-${a}}$`,
          false,
          false
        ),
        `$\\approx ${x2r}$`,
        MCMaths.cleaner(
          `$\\displaystyle x_3 = ${x2r} - \\frac{\\frac{1}{${x2r}^2} - ${a}\\cdot${x2r}-${b}}{-\\frac{2}{${x2r}^3}-${a}}$`,
          false,
          false
        ),
        `$\\approx ${x3r}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(x1, 0.1, "$x_1=$");
      group.addInput(x2, 0.1, "$x_2=$");
      group.addInput(x3, 0.1, "$x_3=$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_NewtonRaphsonMethod_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 6) / 10;
    const initial = MCRandom.randomInt(5, 25) / 10;
    const cr = new MCMaths.ChainRule(
      new MCMaths.Exponential(),
      new MCMaths.Polynomial([-1, 0, 1, 0])
    );
    const ans1 = cr.evaluate(initial);
    const ans1r = Math.round(ans1 * 1000) / 1000;

    const eval2 = Math.exp(initial - initial ** 3) - a;
    const eval2r = Math.round(eval2 * 1000) / 1000;

    const ans2 = initial - eval2 / ans1;
    const ans2r = Math.round(ans2 * 1000) / 1000;
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(ans1) > 0.1 &&
      Math.abs(ans1) < 5 &&
      Math.abs(Math.exp(ans2 - ans2 ** 3) - a) <
        0.1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$\\displaystyle f(x) = e^{x-x^3}-${a}$`,
        `a) Find $f'(${initial})$`,
        `b) Using the Newton-Raphson Method once with $x_0=${initial}$, find an approximation for the root of $f$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)${cr.derivativeWorking()[0]}:`,
        cr.derivativeWorking()[1],
        cr.derivativeWorking()[2],
        cr.derivativeWorking()[3],
        cr.derivativeWorking()[4],
        cr.derivativeWorking()[5],
        `$\\displaystyle \\frac{dy}{dx} = (1-3x^2)e^{x-x^3}$`,
        `HEADING Evaluating:`,
        `$\\displaystyle f'(${initial}) = (1-3\\times${initial}^2)e^{${initial}-${initial}^3}$`,
        `$\\approx ${ans1r}$`,
        `b)HEADING Evaluating $f$:`,
        `$f(${initial}) \\approx ${eval2r}$`,
        `HEADING Newton-Raphson Method:`,
        `$\\displaystyle = ${initial} - \\frac{f(${initial})}{f'(${initial})}$`,
        `$\\displaystyle \\approx ${initial} - \\frac{${eval2r}}{${ans1r}}$`,
        `$\\approx ${ans2r}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans1, 0.1, "a)");
      group.addInput(ans2, 0.1, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_NewtonRaphsonMethod_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const x = new MCMaths.Polynomial([
      (MCRandom.randomInt(-1, 1, 2) * MCRandom.randomInt(1, 8)) / 4,
      (MCRandom.randomInt(-1, 1, 2) * MCRandom.randomInt(1, 12)) / 3,
      0,
      MCRandom.randomInt(-10, 10),
      MCRandom.randomInt(1, 9),
    ]);
    const initial = MCRandom.randomInt(3, 10) * MCRandom.randomInt(-1, 1, 2);
    const ans1 = x.derivative().evaluate(initial);
    const ans2 = x.derivative().derivative().evaluate(initial);
    const ans3 = initial - ans1 / ans2;
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(ans1) < 20 &&
      ans1 !== 0 &&
      Math.abs(ans2) < 20 &&
      ans2 !== 0 &&
      Math.abs(x.derivative().evaluate(ans3)) <
        Math.abs(x.derivative().evaluate(initial)) &&
      ans3 !== 0 &&
      Math.round(100 * ans3) === 100 * ans3 &&
      Math.abs(initial - ans3) <
        1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$\\displaystyle f(x)=${x.toString()}$.`,
        `a) Find $f'(${initial})$.`,
        `b) Find $f''(${initial})$.`,
        `c) Using $${initial}$ as an initial approximation of a turning point of $f$, use the Newton Raphson process once to find a better estimate.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding Derivative:`,
        `$f'(x) = ${x.derivative().toString()}$`,
        `HEADING Evaluating:`,
        `$f'(${initial}) = ${x
          .derivative()
          .toString()
          .replace(/x/g, `\\left(${initial}\\right)`)}$`,
        `$=${ans1}$`,
        `b) HEADING Finding Second Derivative:`,
        `$f''(x) = ${x.derivative().derivative().toString()}$`,
        `HEADING Evaluating:`,
        `$f''(${initial}) = ${x
          .derivative()
          .derivative()
          .toString()
          .replace(/x/g, `\\left(${initial}\\right)`)}$`,
        `$=${ans2}$`,
        `c) HEADING Turning points are roots of the derivative:`,
        `HEADING Newton Raphson Process:`,
        `$=${initial} - \\frac{f'(${initial})}{f''(${initial})}$`,
        `$=${initial} - \\frac{${ans1}}{${ans2}}$`,
        `$=${ans3}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans1, 0, "a)");
      group.addInput(ans2, 0, "b)");
      group.addInput(ans3, 0, "c)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_NewtonRaphsonMethod_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 9);
    const b = MCRandom.randomInt(2, 9);
    const i1 = MCRandom.randomInt(-20, -1) / 10;
    const i2 = Math.round(10 * (i1 + 0.1)) / 10;
    function f(x) {
      return a * Math.exp(x) - b * x * x;
    }
    const w1 = a * Math.exp(i1) - 2 * b * i1;
    const ans1 = f(i1) / f(i2);
    const ans2 = i1 - f(i1) / w1;
    /* END VARIABLES AND CONSTANTS */
    if (
      f(i1) / f(i2) < 0 &&
      Math.round(ans2 * 100) / 100 !== i1 &&
      Math.round(ans2 * 100) / 100 !==
        i2 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$f(x) = ${a}e^{x} - ${b}x^2$`,
        `a) Show $f$ has a root between $${i1}$ and $${i2}$, and find the value $\\displaystyle\\frac{f(${i1})}{f(${i2})}$.`,
        `b) Using the Newton Raphson method once, taking $${i1}$ an a first approximation, find a better approximation of the root of $f$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING Evaluating:`,
        `$f(${i1}) \\approx ${Math.round(f(i1) * 1000) / 1000}$`,
        `$f(${i2}) \\approx ${Math.round(f(i2) * 1000) / 1000}$`,
        `$\\therefore$ Sign change implies root.`,
        `HEADING Finding Value:`,
        `$\\displaystyle\\frac{f(${i1})}{f(${i2})} \\approx \\frac{${
          Math.round(f(i1) * 1000) / 1000
        }}{${Math.round(f(i2) * 1000) / 1000}}$`,
        `$\\approx ${Math.round(ans1 * 1000) / 1000}$`,
        `b) HEADING Finding Derivative:`,
        `$f'(x) = ${a}e^{x} - ${2 * b}x$`,
        `HEADING Evaluating:`,
        `$f'(${i1}) = ${a}e^{${i1}} - ${2 * b}\\times ${i1}$`,
        `$\\approx ${Math.round(w1 * 1000) / 1000}$`,
        `HEADING Newton Raphson Method:`,
        `$\\displaystyle =${i1} - \\frac{f(${i1})}{f'(${i1})}$`,
        `$\\displaystyle \\approx ${i1} - \\frac{${
          Math.round(f(i1) * 1000) / 1000
        }}{${Math.round(w1 * 1000) / 1000}}$`,
        `$\\approx ${Math.round(ans2 * 1000) / 1000}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans1, 0.1, "a)");
      group.addInput(ans2, 0.01, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_NewtonRaphsonMethod_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function g(x) {
      return 1 / (x ** 3 + x);
    }
    const m = MCRandom.randomInt(2, 9) ** MCRandom.randomInt(-1, 1, 2);
    const md = MCMaths.cleaner(`${m}`);
    function h(x) {
      return m * x;
    }

    function f(x) {
      return g(x) - h(x);
    }

    const i1 = MCRandom.randomInt(3, 17) / 10;
    const i2 = Math.round(10 * (i1 + 0.1)) / 10;

    const w2 = -(3 * i1 ** 2 + 1) / (i1 ** 3 + i1) ** 2 - m;
    const wd = Math.round(1000 * w2) / 1000;

    const ans1 = i1 - f(i1) / w2;

    /* END VARIABLES AND CONSTANTS */
    if (
      f(i1) / f(i2) < 0 &&
      i1 !== 0.5 &&
      i2 !== 0.5 &&
      i1 !== 1 &&
      i2 !== 1 &&
      Math.round(100 * f(i1)) / 100 !== f(i1) &&
      Math.round(100 * f(i2)) / 100 !== f(i2) &&
      ans1 - i1 > 0.02 &&
      i2 - ans1 > 0.02 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const graph = new MCQuestion.Graph(4, 0, 4, 0, 1, 1);
      graph.plot(`1 / (x ** 3 + x)`, 0, 5);
      graph.plot(`${m}*x`, 0, 5);
      question.addGraph("question", graph);
      question.addMultipleParagraphs("question", [
        `This graphs shows a plot of the functions $\\displaystyle y=\\frac{1}{x^3+x}$ and $\\displaystyle y=${md}x$.`,
        `a) Show that these functions intesect between $${i1}$ and $${i2}$.`,
        `b) Using the Newton Raphson method once, taking $${i1}$ an a first approximation, find a better approximation the $x$ coordinate where these functions intersect. `,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Showing change in sign:`,
        `Setting $\\displaystyle f(x) = \\frac{1}{x^3+x}-${md}x$`,
        `$\\displaystyle f(${i1})=\\frac{1}{${i1}^3+${i1}}-${md}\\times ${i1}$`,
        `$\\approx ${Math.round(1000 * f(i1)) / 1000}$`,
        `$\\displaystyle f(${i2})=\\frac{1}{${i2}^3+${i2}}-${md}\\times ${i2}$`,
        `$\\approx ${Math.round(1000 * f(i2)) / 1000}$`,
        `$\\therefore$ A change in sign implies an intersection.`,
        `b)HEADING Using chain rule:`,
        `$\\displaystyle f'(x) = -(3x^2+1)\\cdot\\frac{1}{\\left(x^3+x\\right)^2} - ${md}$`,
        `HEADING Evaluating:`,
        `$\\displaystyle f'(${i1}) = -\\left(3\\times ${i1}^2 + 1 \\right)\\frac{1}{\\left(${i1}^3+${i1}\\right)^2}-${md}$`,
        `$\\approx ${wd}$`,
        `HEADING Newton Raphson Method:`,
        `$\\displaystyle = ${i1} - \\frac{f(${i1})}{f'(${i1})}$`,
        `$\\displaystyle = ${i1} - \\frac{${
          Math.round(1000 * f(i1)) / 1000
        }}{${wd}}$`,
        `$\\approx ${Math.round(1000 * ans1) / 1000}$`,
      ]);
      question.requiresSelfMarking();
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans1, 0.003, "b)");
      question.addInputGroup(group);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_NumericalIntegration_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const funcs = [
      new MCMaths.Trig(1, "sin"),
      new MCMaths.Trig(1, "cos"),
      new MCMaths.Exponential(),
      new MCMaths.Logarithm(),
      new MCMaths.Polynomial([
        1,
        MCRandom.randomInt(-5, 5),
        MCRandom.randomInt(-5, 5),
      ]),
      new MCMaths.Polynomial([
        1,
        0,
        MCRandom.randomInt(-5, 5),
        MCRandom.randomInt(-5, 5),
      ]),
    ];

    const x = funcs[MCRandom.randomInt(0, funcs.length - 1)];

    const a = MCRandom.randomInt(1, 3);
    const b = a + MCRandom.randomInt(1, 2) ** MCRandom.randomInt(0, 1);

    const h = (b - a) / 5;

    const partA =
      x.evaluate(a + h) +
      x.evaluate(a + 2 * h) +
      x.evaluate(a + 3 * h) +
      x.evaluate(a + 4 * h);

    const ni = new MCMaths.NumericalMethods(x);

    const ans = ni.trapeziumRuleEvaluate(a, b, 6);

    /* END VARIABLES AND CONSTANTS */
    if (ans > 1 && ans < 5 /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given $y=${x.toString()}$,`,
        `a) Find the value of $y(${a + h})+y(${a + 2 * h})+y(${a + 3 * h})+y(${
          a + 4 * h
        })$.`,
        `b) Use the trapezium rule to approximate $\\displaystyle \\int_{${a}}^{${b}} ${x.toString()} dx$, using five equal intervals.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Evaluating:`,
        `$y(${a + h}) = ${x
          .toString()
          .replace(/x/g, `(${a + h})`)
          .replace("((", "(")
          .replace(")\\right)", "\\right)")}$`,
        `$=${Math.round(x.evaluate(a + h) * 1000) / 1000}$`,
        `$y(${a + 2 * h}) = ${x
          .toString()
          .replace(/x/g, `(${a + 2 * h})`)
          .replace("((", "(")
          .replace(")\\right)", "\\right)")}$`,
        `$=${Math.round(x.evaluate(a + 2 * h) * 1000) / 1000}$`,
        `$y(${a + 3 * h}) = ${x
          .toString()
          .replace(/x/g, `(${a + 3 * h})`)
          .replace("((", "(")
          .replace(")\\right)", "\\right)")}$`,
        `$=${Math.round(x.evaluate(a + 3 * h) * 1000) / 1000}$`,
        `$y(${a + 4 * h}) = ${x
          .toString()
          .replace(/x/g, `(${a + 4 * h})`)
          .replace("((", "(")
          .replace(")\\right)", "\\right)")}$`,
        `$=${Math.round(x.evaluate(a + 4 * h) * 1000) / 1000}$`,
        `HEADING Summing:`,
        `$=${Math.round(partA * 1000) / 1000}$`,
        `b) HEADING Using Trapezium Rule:`,
        ni.trapeziumRuleString(a, b, 6),
        `$=${ans}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(Math.round(partA * 1000) / 1000, 0.05, "a)");
      group.addInput(ans, 0.05, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_NumericalIntegration_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const i = MCRandom.randomInt(0, 1);
    const x = new MCMaths.Polynomial([
      i * MCRandom.randomInt(1, 2),
      (1 - i) * MCRandom.randomInt(1, 2),
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
    ]);
    const strips = MCRandom.randomInt(4, 6);
    const a = MCRandom.randomInt(-3, 3);
    const b = a + MCRandom.randomInt(5, 15, 5) / 10;
    const h = (b - a) / (strips - 1);

    const evals = [];
    for (let j = 0; j < strips; j += 1) {
      evals.push(`$x_${j} = ${Math.round((a + j * h) * 100) / 100}$`);
      evals.push(
        `$f(x_${j})= ${Math.round(1000 * x.evaluate(a + j * h)) / 1000}$`
      );
    }

    const ni = new MCMaths.NumericalMethods(x);

    const ansA = ni.trapeziumRuleEvaluate(a, b, strips);

    const ansB =
      Math.round((x.integral().evaluate(b) - x.integral().evaluate(a)) * 1000) /
      1000;

    const percentage = Math.round((10000 * (ansA - ansB)) / ansB) / 100;

    const pd = Math.ceil(Math.abs(percentage));
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round((a + h) * 100) === (a + h) * 100 &&
      Math.abs(ansA) < 10 &&
      Math.abs(ansA) > 0.5 &&
      Math.abs(ansA - ansB) > 0.2 &&
      Math.abs(ansA - ansB) < 0.5 &&
      Math.abs(percentage) < 5 &&
      Math.abs(percentage) > 1 &&
      pd - Math.abs(percentage) >
        0.2 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$f(x) = ${x.toString()}$, $\\displaystyle I = \\int_{${a}}^{${b}} f(x)dx$`,
        `a) Use the trapezium rule with $${
          strips - 1
        }$ strips to estimate $I$.`,
        `b) Find the exact value of $I$`,
        `c) Verify the estimate is within $${pd}\\%$ of the exact value.`,
      ]);
      question.addParagraph("solution", `a) HEADING Evaluating:`);
      question.addMultipleParagraphs("solution", evals);
      question.addMultipleParagraphs("solution", [
        `HEADING Using the trapezium rule:`,
        ni.trapeziumRuleString(a, b, strips),
        `$=${ansA}$`,
        `b) HEADING Integrating:`,
        `$\\displaystyle \\int_{${a}}^{${b}} ${x.toString()}dx = \\left[${x
          .integral()
          .toString()}\\right]_{${a}}^{${b}}$`,
        `HEADING Evaluating:`,
        `$\\displaystyle =\\left(${x
          .integral()
          .toString()
          .replace(
            /x/g,
            `(${b})`
          )}\\right)-\\left(${x
          .integral()
          .toString()
          .replace(/x/g, `(${a})`)}\\right)$`,
        `$=${ansB}$`,
        `c)HEADING Finding percentage difference:`,
        MCMaths.cleaner(
          `$\\displaystyle = 100\\times\\frac{${ansA}-${ansB}}{${ansB}}$`,
          false,
          false
        ),
        `$=${percentage}\\%$`,
      ]);
      if (percentage < 0) {
        question.addParagraph(
          "solution",
          `$|${percentage}\\%|<${pd}\\%$, as required.`
        );
      } else {
        question.addParagraph(
          "solution",
          `$${percentage}\\%<${pd}\\%$, as required.`
        );
      }
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ansA, 0.05, "a)");
      group.addInput(ansB, 0.05, "b)");
      question.addInputGroup(group);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_NumericalIntegration_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const funcs = [`\\frac{1}{1+\\ln(x)}`, `\\frac{\\sin(x)}{x}`, `x^x`];
    const type = MCRandom.randomInt(0, funcs.length - 1);
    const func = funcs[type];
    function f(x) {
      if (type === 0) {
        return 1 / (1 + Math.log(x));
      }
      if (type === 1) {
        return Math.sin(x) / x;
      }
      if (type === 2) {
        return x ** x;
      }
      return 0;
    }
    const strips = MCRandom.randomInt(4, 6);
    const a = MCRandom.randomInt(1, 6) / 2;
    const b = a + MCRandom.randomInt(6, 60) / 30;
    let h = (b - a) / (strips - 1);

    let xs = `${a}`;
    for (let k = 1; k < strips; k += 1) {
      xs += `,${Math.round(100 * (a + k * h)) / 100}`;
    }

    const evals = [];
    for (let j = 0; j < strips; j += 1) {
      evals.push(
        `$f(${Math.round((a + j * h) * 100) / 100})= ${
          Math.round(1000 * f(a + j * h)) / 1000
        }$`
      );
    }

    const fullFunc = {
      evaluate: f,
      toString() {
        return func;
      },
    };

    const ni = new MCMaths.NumericalMethods(fullFunc);

    const ans = ni.trapeziumRuleEvaluate(a, b, strips);

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round((a + h) * 100) === (a + h) * 100 &&
      Math.round(b * 10) === b * 10 &&
      Math.abs(ans) < 10 &&
      Math.abs(ans) > 1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      h = Math.round(100 * h) / 100;
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Estimate the value of $\\displaystyle \\int_{${a}}^{${b}} ${func} dx$, using the trapezium rule with ${
          strips - 1
        } strips.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Finding values of $x$:`,
        MCMaths.cleaner(`$h = \\frac{${b}-${a}}{${strips - 1}}$`, false, false),
        `$=${h}$`,
        `$x_n = \\{${xs}\\}$`,
        `HEADING Evaluating:`,
      ]);
      question.addMultipleParagraphs("solution", evals);
      question.addMultipleParagraphs("solution", [
        `HEADING Using Trapezium Rule:`,
        ni.trapeziumRuleString(a, b, strips),
        `$=${ans}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans, 0.05);
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_NumericalIntegration_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const funcs = [
      `\\frac{1}{2}\\left(1+x\\sin\\left(\\frac{x}{2}\\right)\\right)`,
      `\\frac{2}{2+\\sin(x)}`,
      `e^{\\sin(x)}`,
    ];
    const type = MCRandom.randomInt(0, funcs.length - 1);
    const func = funcs[type];
    function f(x) {
      if (type === 0) {
        return 0.5 * (1 + x * Math.sin(x / 2));
      }
      if (type === 1) {
        return 2 / (2 + Math.sin(x));
      }
      if (type === 2) {
        return Math.exp(Math.sin(x));
      }
      return 0;
    }
    let fd;
    if (type === 0) {
      fd = `0.5 * (1 + x * Math.sin(x / 2))`;
    }
    if (type === 1) {
      fd = `2 / (2 + Math.sin(x))`;
    }
    if (type === 2) {
      fd = `Math.exp(Math.sin(x))`;
    }
    const strips = MCRandom.randomInt(4, 5);
    const a = MCRandom.randomInt(1, 6) / 10;
    let b = a + MCRandom.randomInt(90, 150) / 30;
    const h = (b - a) / (strips - 1);

    let xs = `${a}`;
    for (let k = 1; k < strips; k += 1) {
      xs += `,${Math.round(100 * (a + k * h)) / 100}`;
    }

    const evals = [];
    for (let j = 0; j < strips; j += 1) {
      evals.push(
        `$f(${Math.round((a + j * h) * 100) / 100})= ${
          Math.round(1000 * f(a + j * h)) / 1000
        }$`
      );
    }

    const fullFunc = {
      evaluate: f,
      toString() {
        return func;
      },
    };

    const ni = new MCMaths.NumericalMethods(fullFunc);

    const ans = ni.trapeziumRuleEvaluate(a, b, strips);
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round((a + h) * 100) === (a + h) * 100 &&
      Math.round(b * 10) === b * 10 &&
      Math.abs(ans) < 1000 &&
      b <= 5
      /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      b = Math.round(b * 10) / 10;
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A student is looking to integrate $\\displaystyle f(x)=${func}$ between $${a}$ and $${b}$. They plot the graph of this function, given below.`,
      ]);
      const g = new MCQuestion.Graph(5, 0, 3, 0, 1, 1);
      g.plot(fd, 0, 5);
      question.addGraph("question", g);
      question.addMultipleParagraphs("question", [
        `To approximate the integral the trapezium rule, with $${
          strips - 1
        }$ strips is used.`,
        `a) Sketch the plot of the function with $${
          strips - 1
        }$ trapeziums added.`,
        `b) Use the trapezium rule to approximate the integral.`,
      ]);
      question.addParagraph("solution", "a)HEADING");
      const g2 = new MCQuestion.Graph(5, 0, 3, 0, 1, 1);
      g2.plot(fd, 0, 5);
      for (let l = 0; l < strips; l += 1) {
        g2.addParametric(`${a} + ${l} * ${h}`, `t * ${f(a + l * h)}`, 0, 1);
      }

      for (let l = 0; l < strips - 1; l += 1) {
        g2.addParametric(
          `${a} + ${l} * ${h} + t * ${h}`,
          `(1 - t) * ${f(a + l * h)} + t * ${f(a + (l + 1) * h)}`,
          0,
          1
        );
      }

      question.addGraph("solution", g2);

      question.addMultipleParagraphs("solution", [
        `b) HEADING Finding values of $x$:`,
        MCMaths.cleaner(`$h = \\frac{${b}-${a}}{${strips - 1}}$`, false, false),
        `$=${Math.round(h * 10000) / 10000}$`,
        `$x_n = \\{${xs}\\}$`,
        `HEADING Evaluating:`,
      ]);
      question.addMultipleParagraphs("solution", evals);
      question.addMultipleParagraphs("solution", [
        `HEADING Using Trapezium Rule:`,
        ni.trapeziumRuleString(a, b, strips),
        `$=${ans}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans, 0.05, "b)");
      question.addInputGroup(group);
      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_NumericalMethods_NumericalIntegration_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const funcs = [
      new MCMaths.Polynomial([1, 0, 0]),
      new MCMaths.Polynomial([1, 0, 0, 0]),
      new MCMaths.Exponential(),
      new MCMaths.Trig(),
      new MCMaths.Trig(1, "cos"),
    ];
    const type = MCRandom.randomInt(0, funcs.length - 1);
    const ans = [1, 1, 1, 0, 0];
    const x = funcs[type];
    function f(x1) {
      return x.evaluate(x1);
    }
    const a = 0.5 + MCRandom.randomInt(-1, 1) / 10;
    const b = 1 + MCRandom.randomInt(-1, 1) / 10;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `A student is using the trapezium rule to approximate $\\displaystyle \\int_{${a}}^{${b}} ${x.toString()} dx$. Will the approximation be an over or under estimate?`
      );

      const g2 = new MCQuestion.Graph(3, -3, 3, -3, 1, 1);
      g2.plot(x.functionString(), -5, 5);
      g2.addParametric(`${a} `, `${f(a)} * t`, 0, 1);
      g2.addParametric(`${b} `, `${f(b)} * t`, 0, 1);
      question.addGraph(g2);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(MCMaths.cleaner(`Under Estimate`));

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(MCMaths.cleaner(`Over Estimate`));

      if (ans[type] === 0) {
        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
      } else {
        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ConditionalProbability_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const person1 = MCMisc.getName();
    const person2 = MCMisc.getName();
    const person3 = MCMisc.getName();

    const p1 = new MCMaths.Fraction(MCRandom.randomInt(4, 14) * 0.05).toFloat();
    const p2 = new MCMaths.Fraction(MCRandom.randomInt(4, 14) * 0.05).toFloat();
    const p_and3 = new MCMaths.Fraction(
      MCRandom.randomInt(2, 8) * 0.05
    ).toFloat();

    const ai = new MCMaths.Fraction(p1 * p2).toFloat();
    const aii = new MCMaths.Fraction(p1 + p2 - ai).toFloat();
    const b = new MCMaths.Fraction(p_and3 / p1).toFloat();

    /* END VARIABLES AND CONSTANTS */
    if (
      person1.name !== person2.name &&
      person1.name !== person3.name &&
      person2.name !== person3.name &&
      p_and3 < p1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `Two friends, ${person1.name} and ${person2.name}, both decide to take an online maths course. The probabilities of them completing the course are $${p1}$ and $${p2}$ respectively. Assume that the probabilities of them completing the course are independent of each other.`
      );
      question.addParagraph("question", `a) Calculate the probability that:`);
      question.addParagraph("question", `i) they both complete the course.`);
      question.addParagraph(
        "question",
        `ii) at least one of them completes the  course.`
      );
      question.addParagraph(
        "question",
        `${person1.name} has another friend, ${person3.name}, whose probability of completing the course depends on whether ${person1.name} completes it. The probability that both ${person1.name} and ${person3.name} complete the course is $${p_and3}$`
      );
      question.addParagraph(
        "question",
        `b) Calculate the probability that ${person3.name} completes the course given that ${person1.name} has completed it.`
      );
      // solution

      // ai
      question.addHeading(`solution`, `a) Calculating probabilities.`);
      question.addHeading("solution", `i) both complete the course.`);
      question.addParagraph(
        "solution",
        `When independent, $P(A \\cap B)=P(A)P(B)$`
      );
      question.addParagraph("solution", `$${p1}(${p2})=${ai}$`);
      // aii
      question.addHeading(
        "solution",
        `ii) at least one of them completes the course.`
      );
      question.addParagraph(
        "solution",
        `$P(A \\cup B)=P(A)+P(B)-P(A \\cap B)$`
      );
      question.addParagraph("solution", `$${p1}+${p2}-${ai}=${aii}$`);
      // b
      question.addHeading(
        "solution",
        `b) Let $A, B$ be the events ${person1.name} and ${person3.name} respectively complete the course.`
      );
      question.addParagraph(
        "solution",
        `$P(B|A)=\\displaystyle\\frac{P(A \\cap B)}{P(A)}$`
      );
      question.addParagraph(
        "solution",
        `$=\\displaystyle\\frac{${p_and3}}{${p1}}$`
      );
      if (parseFloat(b.toFixed(2)) !== b) {
        question.addParagraph("solution", `$=${b.toFixed(4)}$`);
        question.addParagraph("solution", `$\\approx${b.toFixed(2)}$`);
      } else {
        question.addParagraph("solution", `$=${b.toFixed(2)}$`);
      }

      // answers
      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(ai, 0.005, "ai)");
      g1.addInput(aii, 0.005, "aii)");
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(b, 0.005, "b)");
      question.addInputGroup(g2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ConditionalProbability_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const mb = MCRandom.randomInt(10, 40);
    const mc = MCRandom.randomInt(10, 40);
    const mp = MCRandom.randomInt(10, 40);
    const m_total = mb + mc + mp;

    const fb = MCRandom.randomInt(10, 40);
    const fc = MCRandom.randomInt(10, 40);
    const fp = MCRandom.randomInt(10, 40);
    const f_total = fb + fc + fp;

    const bio_total = mb + fb;
    const che_total = mc + fc;
    const phy_total = mp + fp;

    const total = m_total + f_total;
    const i = ["biology", `chemistry`, `physics`][MCRandom.randomInt(0, 2)];

    let ai_ans = 0;
    if (i === "biology") {
      ai_ans = parseFloat((bio_total / total).toFixed(2));
    } else if (i === "chemistry") {
      ai_ans = parseFloat((che_total / total).toFixed(2));
    } else {
      // physics
      ai_ans = parseFloat((phy_total / total).toFixed(2));
    }

    const ii_gender = ["male", "female"][MCRandom.randomInt(0, 1)];
    const ii_subject = ["biology", `chemistry`, `physics`][
      MCRandom.randomInt(0, 2)
    ];

    let aii_ans = 0;
    if (ii_gender === "male") {
      if (ii_subject === "biology") {
        aii_ans = parseFloat((mb / total).toFixed(2));
      } else if (ii_subject === "chemistry") {
        aii_ans = parseFloat((mc / total).toFixed(2));
      } else {
        // physics
        aii_ans = parseFloat((mp / total).toFixed(2));
      }
    } else if (ii_subject === "biology") {
      aii_ans = parseFloat((fb / total).toFixed(2));
    } else if (ii_subject === "chemistry") {
      aii_ans = parseFloat((fc / total).toFixed(2));
    } else {
      // physics
      aii_ans = parseFloat((fp / total).toFixed(2));
    }

    const b_gender = ["male", "female"][MCRandom.randomInt(0, 1)];
    const b_subject = ["biology", `chemistry`, `physics`][
      MCRandom.randomInt(0, 2)
    ];
    let b_ans = 0;
    if (b_gender === "male") {
      if (b_subject === "biology") {
        b_ans = parseFloat((mb / m_total).toFixed(2));
      } else if (b_subject === "chemistry") {
        b_ans = parseFloat((mc / m_total).toFixed(2));
      } else {
        // physics
        b_ans = parseFloat((mp / m_total).toFixed(2));
      }
    } else if (b_subject === "biology") {
      b_ans = parseFloat((fb / f_total).toFixed(2));
    } else if (b_subject === "chemistry") {
      b_ans = parseFloat((fc / f_total).toFixed(2));
    } else {
      // physics
      b_ans = parseFloat((fp / f_total).toFixed(2));
    }

    const c_ans =
      (bio_total * (bio_total - 1) * (bio_total - 2)) /
        (total * (total - 1) * (total - 2)) +
      (che_total * (che_total - 1) * (che_total - 2)) /
        (total * (total - 1) * (total - 2)) +
      (phy_total * (phy_total - 1) * (phy_total - 2)) /
        (total * (total - 1) * (total - 2));

    /* END VARIABLES AND CONSTANTS */
    if (ai_ans > 0.1 && aii_ans > 0.1 && b_ans > 0.1 && c_ans > 0.1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `A secondary school collects data about their students' favourite sciences. Their results are summarised in the table below.`
      );
      const table = new MCQuestion.Table(``, `Biology`, `Chemistry`, `Physics`);
      table.addRow("Male", `$${mb}$`, `$${mc}$`, `$${mp}$`);
      table.addRow("Female", `$${fb}$`, `$${fc}$`, `$${fp}$`);
      question.addTable("question", table);

      question.addParagraph(
        "question",
        `A student from the school is picked at random.`
      );
      question.addParagraph("question", `a) Calculate the probability that:`);
      question.addParagraph("question", `i) their favourite science is ${i}.`);
      question.addParagraph(
        "question",
        `ii) they are ${ii_gender} and their favourite science is ${ii_subject}.`
      );
      question.addParagraph(
        "question",
        `b) Calculate the probability that their favourite science is ${b_subject}, given that they were ${b_gender}.`
      );

      question.addParagraph(
        "question",
        `Three students were selected at random from the school.`
      );
      question.addParagraph(
        "question",
        `c) Calculate the probability that they all had the same favourite science.`
      );
      // solution
      // solution
      // a
      question.addHeading("solution", `a) Calculate column and row totals.`);
      const table2 = new MCQuestion.Table(
        ``,
        `Biology`,
        `Chemistry`,
        `Physics`,
        "Total"
      );
      table2.addRow("Male", `$${mb}$`, `$${mc}$`, `$${mp}$`, `$${m_total}$`);
      table2.addRow("Female", `$${fb}$`, `$${fc}$`, `$${fp}$`, `$${f_total}$`);
      table2.addRow(
        "Total",
        `$${bio_total}$`,
        `$${che_total}$`,
        `$${phy_total}$`,
        `$${total}$`
      );
      question.addTable("solution", table2);
      // i
      question.addHeading(
        "solution",
        `i) Probability that the person's favourite science is ${i}.`
      );
      if (i === "biology") {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${bio_total}}{${total}}=${(
            bio_total / total
          ).toFixed(4)}$`
        );
      } else if (i === "chemistry") {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${che_total}}{${total}}=${(
            che_total / total
          ).toFixed(4)}$`
        );
      } else {
        // civil
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${phy_total}}{${total}}=${(
            phy_total / total
          ).toFixed(4)}$`
        );
      }
      question.addParagraph("solution", `$\\approx${ai_ans}$`);
      // ii
      question.addHeading(
        "solution",
        `ii) Probability that they are ${ii_gender} and their favourite science is ${ii_subject}.`
      );
      if (ii_gender === "male") {
        if (ii_subject === "biology") {
          question.addParagraph(
            "solution",
            `$\\displaystyle\\frac{${mb}}{${total}}=${(mb / total).toFixed(4)}$`
          );
        } else if (ii_subject === "chemistry") {
          question.addParagraph(
            "solution",
            `$\\displaystyle\\frac{${mc}}{${total}}=${(mc / total).toFixed(4)}$`
          );
        } else {
          // physics
          question.addParagraph(
            "solution",
            `$\\displaystyle\\frac{${mp}}{${total}}=${(mp / total).toFixed(4)}$`
          );
        }
      } else if (ii_subject === "biology") {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${fb}}{${total}}=${(fb / total).toFixed(4)}$`
        );
      } else if (ii_subject === "chemistry") {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${fc}}{${total}}=${(fc / total).toFixed(4)}$`
        );
      } else {
        // physics
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${fp}}{${total}}=${(fp / total).toFixed(4)}$`
        );
      }
      question.addParagraph("solution", `$\\approx${aii_ans}$`);
      // b
      question.addHeading(
        "solution",
        `b) Probability that their favourite science is ${b_subject}, given that they were ${b_gender}.`
      );
      if (b_gender === "male") {
        if (b_subject === "biology") {
          question.addParagraph(
            "solution",
            `$\\displaystyle\\frac{${mb}}{${m_total}}=${(mb / m_total).toFixed(
              4
            )}$`
          );
        } else if (b_subject === "chemistry") {
          question.addParagraph(
            "solution",
            `$\\displaystyle\\frac{${mc}}{${m_total}}=${(mc / m_total).toFixed(
              4
            )}$`
          );
        } else {
          // physics
          question.addParagraph(
            "solution",
            `$\\displaystyle\\frac{${mp}}{${m_total}}=${(mp / m_total).toFixed(
              4
            )}$`
          );
        }
      } else if (b_subject === "biology") {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${fb}}{${f_total}}=${(fb / f_total).toFixed(
            4
          )}$`
        );
      } else if (b_subject === "chemistry") {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${fc}}{${f_total}}=${(fc / f_total).toFixed(
            4
          )}$`
        );
      } else {
        // physics
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${fp}}{${f_total}}=${(fp / f_total).toFixed(
            4
          )}$`
        );
      }
      question.addParagraph("solution", `$\\approx${b_ans}$`);
      // c
      question.addHeading(
        "solution",
        `c) Each person is not replaced when they are picked, so the divisor decreases by one each time giving us the calculation, but also the numerator as they are from the same subject pool.`
      );
      question.addParagraph(
        `solution`,
        `They could all prefer biology OR chemistry OR physics.`
      );
      question.addParagraph(
        "solution",
        `$\\left(\\displaystyle\\frac{${bio_total}}{${total}} \\times \\displaystyle\\frac{${
          bio_total - 1
        }}{${total - 1}} \\times \\displaystyle\\frac{${bio_total - 2}}{${
          total - 2
        }}\\right)+
        \\left(\\displaystyle\\frac{${che_total}}{${total}} \\times \\displaystyle\\frac{${
          che_total - 1
        }}{${total - 1}} \\times \\displaystyle\\frac{${che_total - 2}}{${
          total - 2
        }}\\right)+
        \\left(\\displaystyle\\frac{${phy_total}}{${total}} \\times \\displaystyle\\frac{${
          phy_total - 1
        }}{${total - 1}} \\times \\displaystyle\\frac{${phy_total - 2}}{${
          total - 2
        }}\\right)=${c_ans.toFixed(4)}$`
      );
      question.addParagraph(`solution`, `$\\approx ${c_ans.toFixed(2)}$`);

      // answers
      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(ai_ans, 0.005, `ai)`);
      g1.addInput(aii_ans, 0.005, `aii)`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g1.addInput(b_ans, 0.005, `b)`);
      question.addInputGroup(g2);

      const g3 = new MCQuestion.InputGroup(1);
      g3.addInput(c_ans, 0.005, `c)`);
      question.addInputGroup(g3);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ConditionalProbability_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const me = MCRandom.randomInt(10, 40);
    const mm = MCRandom.randomInt(10, 40);
    const ms = MCRandom.randomInt(10, 40);
    const m_total = me + mm + ms;

    const fe = MCRandom.randomInt(10, 40);
    const fm = MCRandom.randomInt(10, 40);
    const fs = MCRandom.randomInt(10, 40);
    const f_total = fe + fm + fs;

    const eng_total = me + fe;
    const mat_total = mm + fm;
    const sci_total = ms + fs;

    const total = m_total + f_total;

    const i = ["english", "maths", "science"][MCRandom.randomInt(0, 2)];
    const ii_subject = ["english", "maths", "science"][
      MCRandom.randomInt(0, 2)
    ];
    const ii_gender = ["male", "female"][MCRandom.randomInt(0, 1)];
    const b_subject = ["english", "maths", "science"][MCRandom.randomInt(0, 2)];
    const b_gender = ["male", "female"][MCRandom.randomInt(0, 1)];
    const c_subject = ["english", "maths", "science"][MCRandom.randomInt(0, 2)];
    const c_gender = ["male", "female"][MCRandom.randomInt(0, 1)];

    let ai_ans = 0;
    if (i === "english") {
      ai_ans = parseFloat((total - eng_total) / total);
    } else if (i === "maths") {
      ai_ans = parseFloat((total - mat_total) / total);
    } else {
      // physics
      ai_ans = parseFloat((total - sci_total) / total);
    }
    let aii_ans = 0;
    if (ii_gender === "male") {
      if (ii_subject === "english") {
        aii_ans = parseFloat((m_total + fe) / total);
      } else if (ii_subject === "maths") {
        aii_ans = parseFloat((m_total + fm) / total);
      } else {
        // physics
        aii_ans = parseFloat((m_total + fs) / total);
      }
    } else if (ii_subject === "english") {
      aii_ans = parseFloat((f_total + me) / total);
    } else if (ii_subject === "maths") {
      aii_ans = parseFloat((f_total + mm) / total);
    } else {
      // physics
      aii_ans = parseFloat((f_total + ms) / total);
    }

    let b_ans = 0;
    if (b_gender === "male") {
      if (b_subject === "english") {
        b_ans = parseFloat(me / eng_total);
      } else if (b_subject === "maths") {
        b_ans = parseFloat(mm / mat_total);
      } else {
        // physics
        b_ans = parseFloat(ms / sci_total);
      }
    } else if (b_subject === "english") {
      b_ans = parseFloat(fe / eng_total);
    } else if (b_subject === "maths") {
      b_ans = parseFloat(fm / mat_total);
    } else {
      // physics
      b_ans = parseFloat(fs / sci_total);
    }
    let c_ans = 0;
    if (c_gender === "male") {
      if (c_subject === "english") {
        c_ans = parseFloat(
          (me * (me - 1) * (me - 2)) /
            (eng_total * (eng_total - 1) * (eng_total - 2))
        );
      } else if (c_subject === "maths") {
        c_ans = parseFloat(
          (mm * (mm - 1) * (mm - 2)) /
            (mat_total * (mat_total - 1) * (mat_total - 2))
        );
      } else {
        // physics
        c_ans = parseFloat(
          (ms * (ms - 1) * (ms - 2)) /
            (sci_total * (sci_total - 1) * (sci_total - 2))
        );
      }
    } else if (c_subject === "english") {
      c_ans = parseFloat(
        (fe * (fe - 1) * (fe - 2)) /
          (eng_total * (eng_total - 1) * (eng_total - 2))
      );
    } else if (c_subject === "maths") {
      c_ans = parseFloat(
        (fm * (fm - 1) * (fm - 2)) /
          (mat_total * (mat_total - 1) * (mat_total - 2))
      );
    } else {
      // physics
      c_ans = parseFloat(
        (fs * (fs - 1) * (fs - 2)) /
          (sci_total * (sci_total - 1) * (sci_total - 2))
      );
    }

    /* END VARIABLES AND CONSTANTS */
    if (ai_ans > 0.1 && aii_ans > 0.1 && b_ans > 0.1 && c_ans > 0.1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `A survey was conducted where people were asked about their best subject at school. Their results are summarised in the table below.`
      );

      const table = new MCQuestion.Table(
        ``,
        `English`,
        `Maths`,
        `Science`,
        "Total"
      );
      table.addRow("Male", `$${me}$`, `$${mm}$`, `$${ms}$`, `$${m_total}$`);
      table.addRow("Female", `$${fe}$`, `$${fm}$`, `$${fs}$`, `$${f_total}$`);
      table.addRow(
        "Total",
        `$${eng_total}$`,
        `$${mat_total}$`,
        `$${sci_total}$`,
        `$${total}$`
      );
      question.addTable("question", table);
      question.addParagraph(
        "question",
        `One person from the sample is selected at random.`
      );
      question.addParagraph("question", `a) Calculate the probability that:`);
      question.addParagraph("question", `i) ${i} was not their best subject.`);
      question.addParagraph(
        "question",
        `ii) ${ii_subject} was their best subject or they were ${ii_gender}.`
      );
      question.addParagraph(
        "question",
        `b) Calculate the probability that they are ${b_gender}, given that their best subject was ${b_subject}.`
      );
      question.addParagraph(
        "question",
        `Three people from the sample are selected at random, all of who had their best subject being ${c_subject}.`
      );

      question.addParagraph(
        "question",
        `c) Calculate the probability that all 3 are ${c_gender}.`
      );
      // i
      question.addHeading(
        "solution",
        `i) Probability that ${i} was not their best subject.`
      );
      if (i === "english") {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${total}-${eng_total}}{${total}}=${ai_ans.toFixed(
            4
          )}$`
        );
      } else if (i === "maths") {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${total}-${mat_total}}{${total}}=${ai_ans.toFixed(
            4
          )}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${total}-${sci_total}}{${total}}=${ai_ans.toFixed(
            4
          )}$`
        );
      }
      question.addParagraph("solution", `$\\approx ${ai_ans.toFixed(2)}$`);
      // ii
      question.addHeading(
        "solution",
        `ii) Probability that ${ii_subject} was their best subject or they were ${ii_gender}.`
      );
      if (ii_gender === "male") {
        if (ii_subject === "english") {
          question.addParagraph(
            "solution",
            `$\\displaystyle\\frac{${m_total}+(${eng_total}-${me})}{${total}}=${aii_ans.toFixed(
              4
            )}$`
          );
        } else if (ii_subject === "maths") {
          question.addParagraph(
            "solution",
            `$\\displaystyle\\frac{${m_total}+(${mat_total}-${mm})}{${total}}=${aii_ans.toFixed(
              4
            )}$`
          );
        } else {
          // physics
          question.addParagraph(
            "solution",
            `$\\displaystyle\\frac{${m_total}+(${sci_total}-${ms})}{${total}}=${aii_ans.toFixed(
              4
            )}$`
          );
        }
      } else if (ii_subject === "english") {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${f_total}+(${eng_total}-${fe})}{${total}}=${aii_ans.toFixed(
            4
          )}$`
        );
      } else if (ii_subject === "maths") {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${f_total}+(${mat_total}-${fm})}{${total}}=${aii_ans.toFixed(
            4
          )}$`
        );
      } else {
        // physics
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${f_total}+(${sci_total}-${fs})}{${total}}=${aii_ans.toFixed(
            4
          )}$`
        );
      }
      question.addParagraph("solution", `$\\approx${aii_ans.toFixed(2)}$`);
      // b
      question.addHeading(
        "solution",
        `b) Probability that they are ${b_gender}, given that their best subject was ${b_subject}.`
      );

      if (b_gender === "male") {
        if (b_subject === "english") {
          question.addParagraph(
            "solution",
            `$\\displaystyle\\frac{${me}}{${eng_total}}=${b_ans.toFixed(4)}$`
          );
        } else if (b_subject === "maths") {
          question.addParagraph(
            "solution",
            `$\\displaystyle\\frac{${mm}}{${mat_total}}=${b_ans.toFixed(4)}$`
          );
        } else {
          // physics
          question.addParagraph(
            "solution",
            `$\\displaystyle\\frac{${ms}}{${sci_total}}=${b_ans.toFixed(4)}$`
          );
        }
      } else if (b_subject === "english") {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${fe}}{${eng_total}}=${b_ans.toFixed(4)}$`
        );
      } else if (b_subject === "maths") {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${fm}}{${mat_total}}=${b_ans.toFixed(4)}$`
        );
      } else {
        // physics
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${fs}}{${sci_total}}=${b_ans.toFixed(4)}$`
        );
      }
      question.addParagraph("solution", `$\\approx${b_ans.toFixed(2)}$`);
      // c

      question.addHeading(
        "solution",
        `c) Each person is not replaced when they are picked, so the divisor decreases by one each time.`
      );
      question.addHeading(
        "solution",
        `Also, one person who's best subject was ${c_subject} is removed from the sample so the value of the numerator decreases by one each time, so the calculation is:`
      );
      if (c_gender === "male") {
        if (c_subject === "english") {
          question.addParagraph(
            "solution",
            `$\\displaystyle\\frac{${me}}{${eng_total}}
          \\times
          \\displaystyle\\frac{${me - 1}}{${eng_total - 1}}
          \\times
          \\displaystyle\\frac{${me - 2}}{${eng_total - 2}}=${c_ans.toFixed(
              4
            )}$`
          );
        } else if (c_subject === "maths") {
          question.addParagraph(
            "solution",
            `$\\displaystyle\\frac{${mm}}{${mat_total}}
          \\times
          \\displaystyle\\frac{${mm - 1}}{${mat_total - 1}}
          \\times
          \\displaystyle\\frac{${mm - 2}}{${mat_total - 2}}=${c_ans.toFixed(
              4
            )}$`
          );
        } else {
          // physics
          question.addParagraph(
            "solution",
            `$\\displaystyle\\frac{${ms}}{${sci_total}}
          \\times
          \\displaystyle\\frac{${ms - 1}}{${sci_total - 1}}
          \\times
          \\displaystyle\\frac{${ms - 2}}{${sci_total - 2}}=${c_ans.toFixed(
              4
            )}$`
          );
        }
      } else if (c_subject === "english") {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${fe}}{${eng_total}}
          \\times
          \\displaystyle\\frac{${fe - 1}}{${eng_total - 1}}
          \\times
          \\displaystyle\\frac{${fe - 2}}{${eng_total - 2}}=${c_ans.toFixed(
            4
          )}$`
        );
      } else if (c_subject === "maths") {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${fm}}{${mat_total}}
          \\times
          \\displaystyle\\frac{${fm - 1}}{${mat_total - 1}}
          \\times
          \\displaystyle\\frac{${fm - 2}}{${mat_total - 2}}=${c_ans.toFixed(
            4
          )}$`
        );
      } else {
        // physics
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${fs}}{${sci_total}}
          \\times
          \\displaystyle\\frac{${fs - 1}}{${sci_total - 1}}
          \\times
          \\displaystyle\\frac{${fs - 2}}{${sci_total - 2}}=${c_ans.toFixed(
            4
          )}$`
        );
      }
      question.addParagraph("solution", `$\\approx ${c_ans.toFixed(2)}$`);
      // asnwers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(ai_ans, 0.005, `ai)`);
      g1.addInput(aii_ans, 0.005, `aii)`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g1.addInput(b_ans, 0.005, `b)`);
      question.addInputGroup(g2);

      const g3 = new MCQuestion.InputGroup(1);
      g3.addInput(c_ans, 0.005, `c)`);
      question.addInputGroup(g3);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ConditionalProbability_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const person1 = MCMisc.getName();
    const person2 = MCMisc.getName();
    const person3 = MCMisc.getName();

    const p1 = new MCMaths.Fraction(MCRandom.randomInt(4, 14) * 0.05).toFloat();
    const p2 = new MCMaths.Fraction(MCRandom.randomInt(4, 14) * 0.05).toFloat();
    const p3 = new MCMaths.Fraction(MCRandom.randomInt(4, 14) * 0.05).toFloat();
    const p1and3 = new MCMaths.Fraction(
      MCRandom.randomInt(2, 8) * 0.05
    ).toFloat();
    const given_1 = parseFloat((p1and3 / p1).toFixed(2));

    const b_ans = (given_1 * p1) / p3;

    const ai = parseFloat(((1 - p1) * (1 - p2)).toFixed(2));
    const aii = parseFloat(((1 - p1) * p2 + (1 - p2) * p1).toFixed(2));

    /* END VARIABLES AND CONSTANTS */
    if (
      person1.name !== person2.name &&
      person1.name !== person3.name &&
      person2.name !== person3.name &&
      p1and3 < p1 &&
      p1and3 < p3
    ) {
      /* START BUILDING QUESTION USING MCQuestion */ //                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          / your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `The probability that two students, ${person1.name} and ${person2.name}, getting full marks on any given question are $${p1}$ and $${p2}$ respectively. Assume that the probabilities of them correctly answering are independent of each other.`
      );
      question.addParagraph(
        `question`,
        `A random question is selected to test them.`
      );
      question.addParagraph("question", `a) Calculate the probability that:`);
      question.addParagraph("question", `i) neither of them get full marks.`);
      question.addParagraph(
        "question",
        `ii) only one of them gets full marks.`
      );
      question.addParagraph(
        "question",
        `There is another student, ${person3.name}, whose probability of getting full marks isn't independent of the probability of ${person1.name} doing the same. The probability of ${person3.name} getting full marks is $${p3}$. On any question, given that ${person1.name} answers correctly then there is a probability of $${given_1}$ that ${person3.name} also answers correctly.`
      );
      question.addParagraph(
        "question",
        `Another random question is selected and given to both ${person1.name} and ${person3.name}`
      );
      question.addParagraph(
        "question",
        `b) Given that ${person3.name} achieves full marks, find the probability that ${person1.name} also achieves full marks.`
      );
      // answers
      question.addHeading(`solution`, `a) Calculating probabilities.`);
      question.addHeading("solution", `i) neither of them get full marks.`);
      question.addParagraph(
        "solution",
        `When independent, $P(A' \\cap B')=P(A')P(B')$`
      );
      question.addParagraph("solution", `$(1-${p1})(1-${p2})=${ai}$`);
      // aii
      question.addHeading("solution", `ii) only one of them gets full marks.`);
      question.addParagraph("solution", `Only two scenarios to consider.`);
      question.addParagraph(
        "solution",
        `$${p1}(1-${p2})+${p2}(1-${p1})=${aii}$`
      );

      question.addHeading(
        "solution",
        `b) Let $A,B$ be the events that ${person1.name} and ${person3.name} get full marks respectively.`
      );
      question.addParagraph(
        "solution",
        `$P(B|A)=\\displaystyle\\frac{P(A\\cap B)}{P(A)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore ${given_1}=\\displaystyle\\frac{P(A\\cap B)}{${p1}}$`
      );
      question.addParagraph(
        "solution",
        `$P(A \\cap B)=${parseFloat((p1 * given_1).toFixed(4))}$`
      );
      question.addParagraph(
        "solution",
        `$P(A|B)=\\displaystyle\\frac{P(A\\cap B)}{P(B)}$`
      );
      question.addParagraph(
        "solution",
        `$=\\displaystyle\\frac{${parseFloat(
          (p1 * given_1).toFixed(4)
        )}}{${p3}}$`
      );
      if (parseFloat(b_ans.toFixed(3)) !== b_ans) {
        question.addParagraph("solution", `$=${b_ans.toFixed(4)}$`);
        question.addParagraph("solution", `$\\approx ${b_ans.toFixed(2)}$`);
      } else {
        question.addParagraph("solution", `$=${b_ans}$`);
      }
      // asnwers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(ai, 0.005, "ai)");
      g1.addInput(aii, 0.005, "aii)");
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(b_ans, 0.005, "b)");
      question.addInputGroup(g2);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ConditionalProbability_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function allDiff(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }

    const A = new MCMaths.Fraction(MCRandom.randomInt(4, 14) * 0.05).toFloat();
    const B = new MCMaths.Fraction(MCRandom.randomInt(4, 14) * 0.05).toFloat();
    const AandB = new MCMaths.Fraction(
      MCRandom.randomInt(2, 8) * 0.05
    ).toFloat();

    const f = [`P(A|B)`, `P(B|A)`][MCRandom.randomInt(0, 1)];
    let ans3 = 0;
    if (f === `P(A|B)`) {
      ans3 = A;
    } else {
      ans3 = B;
    }

    const answers = [
      parseFloat((AandB / B).toFixed(2)),
      parseFloat((AandB / A).toFixed(2)),
      ans3,
      parseFloat(Math.abs(A - B).toFixed(2)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (AandB - 0.1 < A && AandB - 0.1 < B && allDiff(answers)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(`Two events $A$ and $B$ occur with probabilities:`);
      question.addParagraph(`$P(A)=${A}$`);
      question.addParagraph(`$P(B)=${B}$`);
      question.addParagraph(`$P(A\\cap B)=${AandB}$`);
      question.addParagraph(`Calculate $${f}$`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$\\displaystyle${answers[0]}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$\\displaystyle${answers[1]}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$\\displaystyle${answers[2]}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$\\displaystyle${answers[3]}$`);

      question.addAnswer(a1, f === `P(A|B)`);
      question.addAnswer(a2, f === `P(B|A)`);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const p_x = MCRandom.randomInt(1, 99) / 100;
    const p_y = MCRandom.randomInt(1, 99) / 100;
    const p_x_and_y = MCRandom.randomInt(1, 99) / 100;

    const part_a_answer = p_x + p_y - p_x_and_y;
    const part_b_answer = 1 - part_a_answer;
    const part_c_answer = 1 - (p_x - p_x_and_y);

    /* END VARIABLES AND CONSTANTS */
    if (
      part_a_answer < 1 &&
      part_b_answer < 1 &&
      part_c_answer < 1 &&
      p_x_and_y < p_x &&
      p_x_and_y < p_y /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Given two events $X$ and $Y$ where $P(X) = ${p_x.toFixed(
          2
        )}$, $P(Y) = ${p_y.toFixed(2)}$ and $P(X \\cap Y) = ${p_x_and_y.toFixed(
          2
        )}$`,
        `Find:`,
        `a) $P(X \\cup Y)$`,
        `b) $P((X \\cup Y)')$`,
        `c) $P(X' \\cup Y)$`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) $P(X \\cup Y) = P(X) + P(Y) - P(X \\cap Y)$`,
        `$= ${p_x.toFixed(2)} + ${p_y.toFixed(2)} - ${p_x_and_y.toFixed(2)}$`,
        `$= ${part_a_answer.toFixed(2)}$`,
        `b) $P((X \\cup Y)') = 1 - P(X \\cup Y)$`,
        `$= 1 - ${part_a_answer.toFixed(2)}$`,
        `$= ${part_b_answer.toFixed(2)}$`,
        `c) $P(X' \\cup Y) = 1 - P(X \\cap Y')$`,
        `$= 1 - (P(X) - P(X \\cap Y))$`,
        `$= 1 - (${p_x} - ${p_x_and_y})$`,
        `$= ${part_c_answer.toFixed(2)}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(part_a_answer.toFixed(2)),
        0.05,
        `a) $\\text{ } P(X \\cup Y)= $`
      );
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(part_b_answer.toFixed(2)),
        0.05,
        `b) $\\text{ } P((X \\cup Y)')= $`
      );
      const group3 = new MCQuestion.InputGroup(1);
      group3.addInput(
        parseFloat(part_c_answer.toFixed(2)),
        0.05,
        `c) $\\text{ } P(X' \\cup Y)= $`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const p_a = MCRandom.randomInt(1, 99) / 100;
    const p_b = MCRandom.randomInt(1, 99) / 100;
    const p_a_or_b = MCRandom.randomInt(1, 99) / 100;

    const part_a_answer = p_a + p_b - p_a_or_b;
    const part_b_answer = 1 - part_a_answer;
    const part_c_answer = p_b - part_a_answer;

    /* END VARIABLES AND CONSTANTS */
    if (
      p_a_or_b < p_a + p_b &&
      part_a_answer < 1 &&
      part_a_answer < p_a &&
      part_a_answer < p_b
      /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Given two events $A$ and $B$ where $P(A) = ${p_a.toFixed(
          2
        )}$, $P(B) = ${p_b.toFixed(2)}$ and $P(A \\cup B) = ${p_a_or_b.toFixed(
          2
        )}$`,
        `Find:`,
        `a) $P(A \\cap B)$`,
        `b) $P((A \\cap B)')$`,
        `c) $P(A' \\cap B)$`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) $P(A \\cap B) = P(A) + P(B) - P(A \\cup B)$`,
        `$= ${p_a.toFixed(2)} + ${p_b.toFixed(2)} - ${p_a_or_b.toFixed(2)}$`,
        `$= ${part_a_answer.toFixed(2)}$`,
        `b) $P((A \\cap B)') = 1 - P(A \\cap B)$`,
        `$= 1 - ${part_a_answer.toFixed(2)}$`,
        `$= ${part_b_answer.toFixed(2)}$`,
        `c) $P(A' \\cap B) = P(B) - P(A \\cap B)$`,
        `$= ${p_a}-${part_a_answer.toFixed(2)}$`,
        `$=${part_c_answer.toFixed(2)}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(part_a_answer.toFixed(2)),
        0.05,
        `a) $\\text{ } P(A \\cap B)= $`
      );
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(part_b_answer.toFixed(2)),
        0.05,
        `b) $\\text{ } P((A \\cap B)')= $`
      );
      const group3 = new MCQuestion.InputGroup(1);
      group3.addInput(
        parseFloat(part_c_answer.toFixed(2)),
        0.05,
        `c) $\\text{ } P(A' \\cap B)= $`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const p_c = MCRandom.randomInt(1, 99) / 100;
    const p_d = MCRandom.randomInt(1, 99) / 100;
    const p_c_and_d = MCRandom.randomInt(1, 99) / 100;

    const part_a_answer = p_c + p_d + p_c_and_d;
    const part_b_answer = p_c_and_d / p_d;
    const part_c_answer = (p_c - p_c_and_d) / (1 - p_d);

    /* END VARIABLES AND CONSTANTS */
    if (p_c_and_d < p_c && p_c_and_d < p_d && part_a_answer < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Given two events $C$ and $D$ where $P(C) = ${p_c.toFixed(
          2
        )}$, $P(D) = ${p_d.toFixed(2)}$ and $P(C \\cap D) = ${p_c_and_d.toFixed(
          2
        )}$`,
        `Find:`,
        `a) $P(C \\cup D)$`,
        `b) $P(C | D)$`,
        `c) $P(C | D')$`,
        `Give your answers to 3 decimal places.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) $P(C \\cup D) = P(C) + P(D) - P(C \\cap D)$`,
        `$= ${p_c}+${p_d}-${p_c_and_d}$`,
        `$=${part_a_answer.toFixed(3)}$`,

        `b) $\\displaystyle P(C | D) = \\frac{P(C \\cap D)}{P(D)}$`,
        `$= \\frac{${p_c_and_d}}{${p_d}}$`,
        `$\\displaystyle = ${part_b_answer.toFixed(3)}$`,

        `c) $\\displaystyle P(C | D') = \\frac{P(C \\cap D')}{P(D')}$`,
        `$\\displaystyle = \\frac{P(C) - P(C \\cap D)}{1 - P(D)}$`,
        `$\\displaystyle \\frac{${p_c} - ${p_c_and_d}}{1-${p_d}}$`,
        `$=${part_c_answer.toFixed(3)}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(part_a_answer.toFixed(3)),
        0.005,
        `a) $\\text{ } P(C \\cup D) =$`
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(part_b_answer.toFixed(3)),
        0.005,
        `b) $\\text{ } P(C | D) =$`
      );

      const group3 = new MCQuestion.InputGroup(1);
      group3.addInput(
        parseFloat(part_c_answer.toFixed(3)),
        0.005,
        `c) $\\text{ } P(C | D') =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const p_x = MCRandom.randomInt(1, 99) / 100;
    const p_y = MCRandom.randomInt(1, 99) / 100;
    const p_x_and_y = MCRandom.randomInt(1, 99) / 100;

    const p_z = MCRandom.randomInt(1, 99) / 100;

    const part_a_answer = p_x + p_y - p_x_and_y + p_z;
    const part_b_answer = 0;
    const part_c_answer = p_x - p_x_and_y + p_z;
    const part_d_answer = p_x_and_y / p_x;

    /* END VARIABLES AND CONSTANTS */
    if (
      part_a_answer < 1 &&
      p_x > p_x_and_y &&
      p_y > p_x_and_y /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Given events $X$, $Y$ and $Z$ where $P(X) = ${p_x.toFixed(
          2
        )}$, $P(Y) = ${p_y.toFixed(2)}$, $P(X \\cap Y) = ${p_x_and_y.toFixed(
          2
        )}$ and $P(Z) = ${p_z.toFixed(2)}$`,
        `Also given that $Z$ is mutually exclusive of $X$ and $Y$ find:`,
        `a) $P(X \\cup Y \\cup Z)$`,
        `b) $P(X \\cap Y \\cap Z)$`,
        `c) $P(X \\cap Y' \\cup Z)$`,
        `d) $P(X | Y)$`,
        `Give your answers to $2$ decimal places.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) $P(X \\cup Y \\cup Z) = P(X) + P(Y) - P(X \\cap Y) + P(Z)$`,
        `$= ${p_x.toFixed(2)} + ${p_y.toFixed(2)} - ${p_x_and_y.toFixed(
          2
        )} + ${p_z}$`,
        `$= ${part_a_answer.toFixed(2)}$`,
        `b) $P(X \\cap Y \\cap Z) = 0$ (since Z is mutually exclusive of $X$ and $Y$)`,
        `c) $P(X \\cap Y' \\cup Z) = P(X \\cap Y') + P(Z) - P(X \\cap Y' \\cap Z)$`,
        `$= P(X \\cap Y') + P(Z)$`,
        `$= P(X) - P(X \\cap Y) + P(Z)$`,
        `$= ${p_x} - ${p_x_and_y} + ${p_z}$`,
        `$= ${part_c_answer.toFixed(2)}$`,
        `d) $P(X | Y) = \\displaystyle \\frac{P(X \\cap Y)}{P(X)}$`,
        `$= \\displaystyle \\frac{${p_x_and_y}}{${p_x}}$`,
        `$= ${part_d_answer.toFixed(2)}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      const group3 = new MCQuestion.InputGroup(1);
      const group4 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(part_a_answer.toFixed(2)),
        0.05,
        `a) $\\text{ } P(X \\cup Y \\cup Z) =$`
      );
      group2.addInput(
        parseFloat(part_b_answer.toFixed(2)),
        0.05,
        `b) $\\text{ } P(X \\cap Y \\cap Z) =$`
      );
      group3.addInput(
        parseFloat(part_c_answer.toFixed(2)),
        0.05,
        `c) $\\text{ } P(X \\cap Y' \\cup Z) =$`
      );
      group4.addInput(
        parseFloat(part_d_answer.toFixed(2)),
        0.05,
        `d) $\\text{ } P(X | Y) =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      question.addInputGroup(group4);
      /* RETURN THE QUESTION */
      /* END BUILDING QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const goals_scored = MCRandom.randomInt(3, 5);
    const goals_conceded = MCRandom.randomInt(1, 3);

    const prob_a = MCRandom.randomInt(1, 50) / 100;
    const prob_a_given_b = MCRandom.randomInt(1, 99) / 100;
    const prob_a_or_b = MCRandom.randomInt(1, 99) / 100;
    const prob_not_a_or_b = 1 - prob_a_or_b;

    const part_a_answer = prob_a * prob_a_given_b;
    const part_b_answer = part_a_answer + prob_a_or_b - prob_a;

    /* END VARIABLES AND CONSTANTS */
    if (
      prob_a_given_b < prob_a &&
      goals_conceded < goals_scored &&
      part_a_answer < prob_a &&
      part_a_answer <
        part_b_answer /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */ // P(A \\cap B) = P(A) + P(B) - P(A U B)
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `The probability that a football team scores more than $${goals_scored}$ goals is $P(A) = ${prob_a.toPrecision(
          3
        )}$`,
        `The probability that a football team scores more than $${goals_scored}$ goals given that they have conceeded $${goals_conceded}$ goal is $P(A | B) = ${prob_a_given_b.toPrecision(
          3
        )}$`,
        `a) Find the probability that the football team scores more than $${goals_scored}$ goals and conceeds $${goals_conceded}$ goal.`,
        `Given that the probability that the football team doesn't score more than $${goals_scored}$ goals nor do they conceed $${goals_conceded}$ goals is $P((A \\cup B)') = ${prob_not_a_or_b.toPrecision(
          3
        )}$`,
        `b) Find the probability that the football team concedes $${goals_conceded}$ goals.`,
        `Give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) Want to find $P(A \\cap B):$`,
        `$P(A \\cap B) = (${prob_a.toPrecision(
          3
        )})(${prob_a_given_b.toPrecision(3)})$`,
        `$= ${part_a_answer.toPrecision(3)}$`,
        `b) Want to find $P(B)$:`,
        `$P(B) = ${part_a_answer.toPrecision(
          3
        )} + (1 - ${prob_not_a_or_b.toPrecision(3)}) - ${prob_a.toPrecision(
          3
        )}$`,
        `$=${part_b_answer.toPrecision(3)}$`,
        ``,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(part_a_answer.toPrecision(3)),
        0.0005,
        `a) $\\text{ } =$`
      );
      group2.addInput(
        parseFloat(part_b_answer.toPrecision(3)),
        0.005,
        `b) $\\text{ } =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const x_a = MCRandom.randomInt(2, 6);
    const total_num_of_combinations = (12 * 13) / 2 - 1;
    const green_die_num = MCRandom.randomInt(2, 6);

    const part_a_answer = (x_a - 1) / total_num_of_combinations;
    const part_a_text = `\\frac{${x_a - 1}}{${total_num_of_combinations}}`;

    const part_b_answer = 1 / 77 / part_a_answer;

    /* END VARIABLES AND CONSTANTS */
    if (
      green_die_num < x_a /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A green and a purple pair of dice have $6$ equally likely outcomes, numbered $1$ to $6$. The two dice are rolled at the same time, and the sum of the numbers shown, $X$, is recorded.`,
        `Find:`,
        `a) $P(X=${x_a})$`,
        `b) $P(X=${x_a} | \\text{Green Die is } ${green_die_num})$`,
        `Give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) $P(X=${x_a}) = ${part_a_text}$`,
        `$=${part_a_answer.toPrecision(3)}$`,
        `b) $P(X=${x_a} | \\text{Green Die is } ${green_die_num}) = \\displaystyle \\frac{\\frac{1}{77}}{${part_a_text}}$`,
        `$= ${part_b_answer.toPrecision(3)}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(part_a_answer.toPrecision(3)),
        0.0005,
        `a) $\\text{ } P(X=${x_a}) =$`
      );
      group2.addInput(
        parseFloat(part_b_answer.toPrecision(3)),
        0.005,
        `b) $\\text{ } P(X=${x_a} | \\text{Green Die is } ${green_die_num}) =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const person1 = new MCMisc.getName();
    const person2 = new MCMisc.getName();

    const p_a = MCRandom.randomInt(1, 99) / 100;
    const p_b = MCRandom.randomInt(1, 99) / 100;
    const p_a_and_b = MCRandom.randomInt(1, 99) / 100;

    const part_a_answer = p_a + p_b - p_a_and_b;
    const part_b_answer = p_a_and_b / p_a;
    const part_c_answer = 1 - p_a_and_b;

    /* END VARIABLES AND CONSTANTS */
    if (
      person1.name[0] === "A" &&
      person2.name[0] === "B" &&
      p_a_and_b < p_a &&
      p_a_and_b < p_b &&
      part_a_answer < 1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `${person1.name} and ${person2.name} are friends and both catch the bus to school.`,
        `Let $A$ be the event that ${person1.name} misses the first bus to school.`,
        `Let $B$ be the event that ${person2.name} misses the first bus to school.`,
        `$P(A) = ${p_a.toFixed(2)}$, $P(B) = ${p_b.toFixed(
          2
        )}$ and $P(A \\cap B) = ${p_a_and_b.toFixed(2)}$`,
        `a) What is the probability that ${person1.name} or ${person2.name} misses the bus.`,
        `b) What is the probability that ${person1.name} misses the bus given that ${person2.name} misses the bus.`,
        `c) What is the probability that both ${person1.name} and ${person2.name} make the first bus to school.`,
        `Give your answer to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Here we want to find $P(A \\cup B)$:`,
        `$P(A \\cup B) = ${p_a} + ${p_b} - ${p_a_and_b}$`,
        `$= ${part_a_answer.toPrecision(3)}$`,

        `HEADING b) Here we want to find $P(A | B)$:`,
        `$\\displaystyle P(A | B) = \\frac{P(A \\cap B)}{P(A)}$`,
        `$\\displaystyle = \\frac{${p_a_and_b}}{${p_a}}$`,
        `$= ${part_b_answer.toPrecision(3)}$`,

        `HEADING c) Here we want to find P((A \\cap B)'):`,
        `$P((A \\cap B)') = 1 - P(A \\cap B)$`,
        `$= 1 - ${p_a_and_b}$`,
        `$= ${part_c_answer.toPrecision(3)}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      const group3 = new MCQuestion.InputGroup(1);
      group1.addInput(parseFloat(part_a_answer.toPrecision(3)), 0.005, `a)`);
      group2.addInput(parseFloat(part_b_answer.toPrecision(3)), 0.005, `b)`);
      group3.addInput(parseFloat(part_c_answer.toPrecision(3)), 0.005, `c)`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const p_notA_given_notB = MCRandom.randomInt(1, 99) / 100;
    const p_B_given_A = MCRandom.randomInt(1, 99) / 100;
    const p_A_and_B = MCRandom.randomInt(1, 99) / 100;

    const part_a_answer = p_A_and_B / p_B_given_A;
    const part_b_answer = 1 - (1 - p_A_and_B) / p_notA_given_notB;
    const part_c_answer = part_a_answer + part_b_answer - p_A_and_B;

    /* END VARIABLES AND CONSTANTS */
    if (
      part_a_answer < 1 &&
      part_a_answer > 0 &&
      part_b_answer < 1 &&
      part_b_answer > 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Given that $P(A' | B') = ${p_notA_given_notB.toFixed(
          2
        )}$, $P(B | A) = ${p_B_given_A.toFixed(
          2
        )}$ and $P(A \\cap B) = ${p_A_and_B.toFixed(2)}$,`,
        `Find:`,
        `a) $P(B)$`,
        `b) $P(A)$`,
        `c) $P(A \\cup B)$`,
        `Give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `Using the fact that $P(B | A) = \\displaystyle \\frac{P(B \\cap A)}{P(B)}$`,
        `$= \\displaystyle \\frac{P(A \\cap B)}{P(B)}$`,
        `$\\therefore P(B) = \\displaystyle \\frac{P(A \\cap B)}{P(B | A)}$`,
        `$= \\displaystyle \\frac{${p_A_and_B}}{${p_B_given_A}}$`,
        `$= ${part_a_answer.toPrecision(3)}$`,

        `Using the fact that $P(A' | B') = \\displaystyle \\frac{P(A' \\cap B')}{P(A')}$`,
        `$= \\displaystyle \\frac{P((A \\cap B)')}{1 - P(A)}$`,
        `$= \\displaystyle \\frac{1 - P(A \\cap B)}{1 - P(A)}$`,
        `So $1 - P(A) = \\displaystyle \\frac{1 - P(A \\cap B)}{P(A' | B')}$`,
        `$\\therefore P(A) = 1 - \\displaystyle \\frac{1 - P(A \\cap B)}{P(A' | B')}$`,
        `$= ${part_b_answer.toPrecision(3)}$`,

        `$P(A \\cup B) = P(A) + P(B) - P(A \\cap B)$`,
        `$= ${part_a_answer.toPrecision(3)} + ${part_b_answer.toPrecision(
          3
        )} - ${p_A_and_B.toPrecision(3)}$`,
        `$= ${part_c_answer.toPrecision(3)}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      const group3 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(part_a_answer.toPrecision(3)),
        0.005,
        `a) $\\text{ } P(B) =$`
      );
      group2.addInput(
        parseFloat(part_b_answer.toPrecision(3)),
        0.005,
        `b) $\\text{ } P(A) =$`
      );
      group3.addInput(
        parseFloat(part_c_answer.toPrecision(3)),
        0.005,
        `c) $\\text{ } P(A \\cup B) =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const x_Coefficient = MCRandom.randomInt(2, 9);
    const y_Coefficient = MCRandom.randomInt(2, 9);
    const z_Coefficient = MCRandom.randomInt(2, 9);

    const answerArray = [true, false, false, false];

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `Let $P(X) = ${x_Coefficient}x$, $P(Y) = ${y_Coefficient}y$ and $P(X \\cup Y) = ${z_Coefficient}z$`,
        `Select the correct value of $P(X \\cap Y):$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$P(X \\cap Y) = ${x_Coefficient}x + ${y_Coefficient}y - ${z_Coefficient}z$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$P(X \\cap Y) = ${x_Coefficient}x + ${
          y_Coefficient * z_Coefficient
        }yz$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$P(X \\cap Y) = ${
          x_Coefficient * y_Coefficient
        }xy - ${z_Coefficient}z$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$P(X \\cap Y) = ${x_Coefficient}x - ${y_Coefficient}y + ${z_Coefficient}z$`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const x_Coefficient = MCRandom.randomInt(2, 9);
    const y_Coefficient = MCRandom.randomInt(2, 9);
    const z_Coefficient = MCRandom.randomInt(2, 9);

    const answerArray = [true, false, false, false];

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `Let $P(X) = ${x_Coefficient}x$, $P(Y) = ${y_Coefficient}y$ and $P(X \\cap Y) = ${z_Coefficient}z$`,
        `Select the correct value of $P(X \\cup Y):$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$P(X \\cup Y) = ${x_Coefficient}x + ${y_Coefficient}y - ${z_Coefficient}z$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$P(X \\cup Y) = ${x_Coefficient}x + ${
          y_Coefficient * z_Coefficient
        }yz$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$P(X \\cup Y) = ${
          x_Coefficient * y_Coefficient
        }xy - ${z_Coefficient}z$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$P(X \\cup Y) = ${x_Coefficient}x - ${y_Coefficient}y + ${z_Coefficient}z$`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const x_Coefficient = MCRandom.randomInt(2, 9);
    const y_Coefficient = MCRandom.randomInt(2, 9);
    const z_Coefficient = MCRandom.randomInt(2, 9);

    const correctAnswerCoefficient = new MCMaths.Fraction(
      z_Coefficient / x_Coefficient
    );

    const incorrectAnswerCoefficient1 = new MCMaths.Fraction(
      x_Coefficient / z_Coefficient
    );

    const incorrectAnswerCoefficient2 = new MCMaths.Fraction(
      x_Coefficient / y_Coefficient
    );

    const incorrectAnswerCoefficient3 = new MCMaths.Fraction(
      z_Coefficient / y_Coefficient
    );

    const answerArray = [true, false, false, false];

    /* END VARIABLES AND CONSTANTS */
    if (
      x_Coefficient !== y_Coefficient &&
      x_Coefficient !== z_Coefficient &&
      y_Coefficient !==
        z_Coefficient /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `Let $P(X) = ${x_Coefficient}x$, $P(Y) = ${y_Coefficient}y$ and $P(X \\cap Y) = ${z_Coefficient}z$`,
        `Select the correct value of $P(X | Y):$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$P(X | Y) = \\displaystyle ${correctAnswerCoefficient.toString()}\\frac{z}{x}$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$P(X | Y) = \\displaystyle ${incorrectAnswerCoefficient1.toString()}\\frac{x}{z}$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$P(X | Y) = \\displaystyle ${incorrectAnswerCoefficient2.toString()}\\frac{x}{y}$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$P(X | Y) = \\displaystyle ${incorrectAnswerCoefficient3.toString()}\\frac{z}{y}$`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const p_a = MCRandom.randomInt(1, 99) / 100;
    const p_b = MCRandom.randomInt(1, 99) / 100;
    const p_a_given_b = MCRandom.randomInt(1, 99) / 100;

    const correctAnswer = p_a_given_b * p_a;
    const incorrectAnswer1 = p_a_given_b * p_b;
    const incorrectAnswer2 = p_a * p_b;
    const incorrectAnswer3 = p_a / p_b;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (
      correctAnswer < p_a &&
      correctAnswer < p_b /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `$P(A) = ${p_a.toFixed(2)}$, $P(B) = ${p_b.toFixed(
          2
        )}$, $P(A | B) = ${p_a_given_b.toFixed(2)}$`,
        `Choose the correct value of $P(A \\cap B)$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$P(A \\cap B) = \\displaystyle ${correctAnswer.toFixed(2)}$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$P(A \\cap B) = \\displaystyle ${incorrectAnswer1.toFixed(2)}$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$P(A \\cap B) = \\displaystyle ${incorrectAnswer2.toFixed(2)}$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$P(A \\cap B) = \\displaystyle ${incorrectAnswer3.toFixed(2)}$`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const p_a = MCRandom.randomInt(1, 99) / 100;
    const p_b = MCRandom.randomInt(1, 99) / 100;
    const p_a_and_b = MCRandom.randomInt(1, 99) / 100;

    const correctAnswer = p_a + p_b - p_a_and_b;
    const incorrectAnswer1 = p_a - p_b - p_a_and_b;
    const incorrectAnswer2 = (p_a + p_b) * p_a_and_b;
    const incorrectAnswer3 = (p_a - p_b) * p_a_and_b;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (
      p_a_and_b < p_a &&
      p_a_and_b < p_b &&
      correctAnswer > 0 &&
      correctAnswer < 1 &&
      incorrectAnswer1 > 0 &&
      incorrectAnswer1 < 1 &&
      incorrectAnswer2 > 0.01 &&
      incorrectAnswer2 < 1 &&
      incorrectAnswer3 > 0.01 &&
      incorrectAnswer3 < 1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `$P(A) = ${p_a.toFixed(2)}$, $P(B) = ${p_b.toFixed(
          2
        )}$, $P(A \\cap B) = ${p_a_and_b.toFixed(2)}$`,
        `Choose the correct value of $P(A \\cup B)$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$P(A \\cup B) = \\displaystyle ${correctAnswer.toFixed(2)}$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$P(A \\cup B) = \\displaystyle ${incorrectAnswer1.toFixed(2)}$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$P(A \\cup B) = \\displaystyle ${incorrectAnswer2.toFixed(2)}$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$P(A \\cup B) = \\displaystyle ${incorrectAnswer3.toFixed(2)}$`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const p_a = MCRandom.randomInt(1, 99) / 100;
    const p_b = MCRandom.randomInt(1, 99) / 100;
    const p_a_and_b = MCRandom.randomInt(1, 99) / 100;

    const correctAnswer = 1 - (p_a + p_b - p_a_and_b);
    const incorrectAnswer1 = p_a + p_b - p_a_and_b;
    const incorrectAnswer2 = 1 - (p_a + p_b) * p_a_and_b;
    const incorrectAnswer3 = 1 - (p_a - p_b) * p_a_and_b;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (
      p_a_and_b < p_a &&
      p_a_and_b < p_b &&
      correctAnswer > 0 &&
      correctAnswer < 1 &&
      incorrectAnswer1 > 0 &&
      incorrectAnswer1 < 1 &&
      incorrectAnswer2 > 0.01 &&
      incorrectAnswer2 < 1 &&
      incorrectAnswer3 > 0.01 &&
      incorrectAnswer3 < 1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `$P(A) = ${p_a.toFixed(2)}$, $P(B) = ${p_b.toFixed(
          2
        )}$, $P(A \\cap B) = ${p_a_and_b.toFixed(2)}$`,
        `Choose the correct value of $P((A \\cup B)')$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$P(A \\cup B) = \\displaystyle ${correctAnswer.toFixed(2)}$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$P(A \\cup B) = \\displaystyle ${incorrectAnswer1.toFixed(2)}$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$P(A \\cup B) = \\displaystyle ${incorrectAnswer2.toFixed(2)}$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$P(A \\cup B) = \\displaystyle ${incorrectAnswer3.toFixed(2)}$`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_MCQ_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const p_a = MCRandom.randomInt(1, 99) / 100;
    const p_b = MCRandom.randomInt(1, 99) / 100;
    const p_a_and_b = MCRandom.randomInt(1, 99) / 100;
    const p_c = MCRandom.randomInt(1, 99) / 100;

    const correctAnswer = p_a + p_b - p_a_and_b + p_c;
    const incorrectAnswer1 = p_a + p_b + p_c;
    const incorrectAnswer2 = (p_a + p_b) * p_a_and_b + p_c;
    const incorrectAnswer3 = p_a + p_b + p_a_and_b + p_c;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (
      p_a_and_b < p_a &&
      p_a_and_b < p_b &&
      correctAnswer < 1 &&
      correctAnswer > 0.01 &&
      incorrectAnswer1 < 1 &&
      incorrectAnswer1 > 0.01 &&
      incorrectAnswer2 < 1 &&
      incorrectAnswer2 > 0.01 &&
      incorrectAnswer3 < 1 &&
      incorrectAnswer3 > 0.01
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `$P(A) = ${p_a.toFixed(2)}$, $P(B) = ${p_b.toFixed(
          2
        )}$, $P(A \\cap B) = ${p_a_and_b.toFixed(2)}$, $P(C) = ${p_c.toFixed(
          2
        )}$`,
        `Given that event $C$ is mutually exclusive of events $A$ and $B$ choose the correct value of $P(A \\cup B \\cup C)$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$P(A \\cup B \\cup C) = \\displaystyle ${correctAnswer.toFixed(2)}$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$P(A \\cup B \\cup C) = \\displaystyle ${incorrectAnswer1.toFixed(2)}$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$P(A \\cup B \\cup C) = \\displaystyle ${incorrectAnswer2.toFixed(2)}$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$P(A \\cup B \\cup C) = \\displaystyle ${incorrectAnswer3.toFixed(2)}$`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_ProbabilityFormulae_MCQ_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const p_a = MCRandom.randomInt(1, 99) / 100;
    const p_b = MCRandom.randomInt(1, 99) / 100;
    const p_c = MCRandom.randomInt(1, 99) / 100;
    const p_a_and_b = MCRandom.randomInt(1, 99) / 100;
    const p_a_or_b = p_a + p_b - p_a_and_b;

    const correctAnswer = 0;
    const incorrectAnswer1 = 1;
    const incorrectAnswer2 = p_a * p_b;
    const incorrectAnswer3 = p_a * p_b * p_a_or_b * p_c;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (
      p_a_and_b < p_a &&
      p_a_and_b < p_b &&
      incorrectAnswer3 > 0.01 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addMultipleParagraphs([
        `$P(A) = ${p_a.toFixed(2)}$, $P(A \\cup B) = ${p_a_or_b.toFixed(
          2
        )}$ ,$P(B) = ${p_b.toFixed(2)}$ and $P(C) = ${p_c.toFixed(2)}$`,
        `Given that event $C$ is mutually exclusive of events $A$ and $B$ choose the correct value of $P(A \\cap B \\cap C)$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$P(A \\cap B \\cap C) = \\displaystyle ${correctAnswer.toFixed(2)}$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$P(A \\cap B \\cap C) = \\displaystyle ${incorrectAnswer1.toFixed(2)}$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$P(A \\cap B \\cap C) = \\displaystyle ${incorrectAnswer2.toFixed(2)}$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$P(A \\cap B \\cap C) = \\displaystyle ${incorrectAnswer3.toFixed(2)}$`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_SetNotation_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const n = MCRandom.randomInt(30, 70);
    const c = Math.floor((MCRandom.randomInt(1, 9) / 10) * n);
    const r = Math.floor((MCRandom.randomInt(1, 9) / 10) * n);
    const c_and_r = Math.floor((MCRandom.randomInt(1, 9) / 10) * n);
    const c_or_r = c + r + c_and_r;
    const not_c_or_r = n - c_or_r;

    const answer_a = new MCMaths.Fraction(c_or_r / n);
    const answer_b = new MCMaths.Fraction(not_c_or_r / n);
    const answer_c = new MCMaths.Fraction(c_and_r / n);
    const answer_d = new MCMaths.Fraction(c / n);
    const answer_e = new MCMaths.Fraction(r / n);

    const answer_b_text =
      `\\frac{${not_c_or_r}}{${n}}` === `${answer_b.toString()}`
        ? ``
        : `=  ${answer_b.toString()}`;

    const answer_c_text =
      `\\frac{${c_and_r}}{${n}}` === `${answer_c.toString()}`
        ? ``
        : `=  ${answer_c.toString()}`;

    const answer_d_text =
      `\\frac{${c}}{${n}}` === `${answer_d.toString()}`
        ? ``
        : `=  ${answer_d.toString()}`;

    const answer_e_text =
      `\\frac{${r}}{${n}}` === `${answer_e.toString()}`
        ? ``
        : `=  ${answer_e.toString()}`;

    /* END VARIABLES AND CONSTANTS */
    if (
      c_and_r < c &&
      c_and_r < r &&
      c_or_r < n &&
      not_c_or_r > 4 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A shape is chosen at random from a bag of $${n}$ coloured shapes. $C$ is the event 'the shape chosen is a cube' and $R$ is the event 'the shape chosen is red'.`,
        `The Venn diagram shows the number of outcomes for each event.`,
      ]);

      const image = new MCQuestion.Image(`Leo/2Circle.svg`, "height", 20);
      image.addOverlay(`$C$`, 20, 20, 1.5);
      image.addOverlay(`$R$`, 80, 20, 1.5);
      image.addOverlay(`$${c}$`, 30, 40, 1.5);
      image.addOverlay(`$${c_and_r}$`, 50, 50, 1.5);
      image.addOverlay(`$${r}$`, 70, 60, 1.5);
      image.addOverlay(`$${not_c_or_r}$`, 7, 90, 1.5);

      question.addImage("question", image);

      question.addMultipleParagraphs("question", [
        `Find:`,
        `a) $P(C \\cup R)$`,
        `b) $P((C \\cup R)')$`,
        `c) $P(C \\cap R)$`,
        `d) $P(C \\cap R')$`,
        `e) $P(C' \\cap R)$`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) $ P(C \\cup R) = \\frac{(${c}+${c_and_r}+${r})}{${n}}$`,
        `$= ${answer_a.toString()}$`,
        `b) $ P((C \\cup R)') = \\frac{${not_c_or_r}}{${n}}$`,
        `$${answer_b_text}$`,
        `c) $P(C \\cap R) = \\frac{${c_and_r}}{${n}}$`,
        `$${answer_c_text}$`,
        `d) $P(C \\cap R') = \\frac{${c}}{${n}}$`,
        `$${answer_d_text}$`,
        `e) $P(C' \\cap R) = \\frac{${r}}{${n}}$`,
        `$${answer_e_text}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(2);
      const group3 = new MCQuestion.InputGroup(2);

      group1.addInput(answer_a.toFloat(), 0.05, `a) =`);
      group1.addInput(answer_b.toFloat(), 0.05, `b) =`);
      group2.addInput(answer_c.toFloat(), 0.05, `c) =`);
      group2.addInput(answer_d.toFloat(), 0.05, `d) =`);
      group3.addInput(answer_e.toFloat(), 0.05, `e) =`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_SetNotation_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const numOfShapes = MCRandom.randomInt(50, 70);

    const s = MCRandom.randomInt(1, numOfShapes);
    const b = MCRandom.randomInt(1, numOfShapes);
    const m = MCRandom.randomInt(1, numOfShapes);

    const s_and_b = MCRandom.randomInt(1, numOfShapes);
    const s_and_m = MCRandom.randomInt(1, numOfShapes);
    const b_and_m = MCRandom.randomInt(1, numOfShapes);

    const s_and_b_and_m = MCRandom.randomInt(1, numOfShapes);

    const s_or_b_or_m = s + b + m + s_and_b + s_and_m + b_and_m + s_and_b_and_m;

    const not_s_or_b_or_m = numOfShapes - s_or_b_or_m;

    const part_a_answer = new MCMaths.Fraction(not_s_or_b_or_m / numOfShapes);

    const part_b_answer = new MCMaths.Fraction(s_and_b_and_m / numOfShapes);
    const part_b_answer_text =
      `\\frac{${s_and_b_and_m}}{${numOfShapes}}` ===
      `${part_b_answer.toString()}`
        ? ``
        : `= ${part_b_answer.toString()}`;

    const part_c_answer = new MCMaths.Fraction(s_and_b / numOfShapes);
    const part_c_answer_text =
      `\\frac{${s_and_b}}{${numOfShapes}}` === `${part_c_answer.toString()}`
        ? ``
        : `= ${part_c_answer.toString()}`;

    const part_d_answer = new MCMaths.Fraction(
      1 - (b + b_and_m + m) / numOfShapes
    );

    const part_e_answer = new MCMaths.Fraction(
      (s_and_b_and_m + s) / numOfShapes
    );

    /* END VARIABLES AND CONSTANTS */
    if (
      s_and_b < s &&
      s_and_b < b &&
      s_and_m < s &&
      s_and_m < m &&
      b_and_m < b &&
      b_and_m < m &&
      s_and_b_and_m < s_and_b &&
      s_and_b_and_m < s_and_m &&
      s_and_b_and_m < b_and_m &&
      s_or_b_or_m < numOfShapes
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A shape is chosen at random from a bag of $${numOfShapes}$ coloured shapes made out of plastic or metal. $S$ is the event 'the shape chosen is a sphere', $B$ is the event 'the shape chosen is blue' and $M$ is the event 'the shape chosen is metal'.`,
        `The Venn diagram shows the number of outcomes for each event.`,
      ]);

      const image = new MCQuestion.Image(`Leo/3Circle.svg`, "height", 20);
      // Event Labels
      image.addOverlay(`$S$`, 52, 8, 1.5);
      image.addOverlay(`$B$`, 20, 80, 1.5);
      image.addOverlay(`$M$`, 80, 80, 1.5);

      // Occurance Labels
      image.addOverlay(`$${s}$`, 30, 65);
      image.addOverlay(`$${b}$`, 70, 65);
      image.addOverlay(`$${m}$`, 52.5, 25);

      image.addOverlay(`$${s_and_b}$`, 40, 45);
      image.addOverlay(`$${s_and_m}$`, 60, 45);
      image.addOverlay(`$${b_and_m}$`, 49, 69);

      image.addOverlay(`$${s_and_b_and_m}$`, 50, 53);

      question.addImage("question", image);

      question.addMultipleParagraphs("question", [
        `Find:`,
        `a) $P((S \\cup \\ B \\cup M)')$`,
        `b) $P( S \\cap \\ B \\cap M )$`,
        `c) $P( B \\cap S )$`,
        `d) $P( (B \\cup M)' )$`,
        `e) $P( (S \\cap B' \\cap M') \\cup (S \\cap B \\cap M) )$`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) $P((S \\cup \\ B \\cup M)') = 1-\\frac{(${s}+${b}+${m}+${s_and_b}+${s_and_m}+${b_and_m}+${s_and_b_and_m})}{${numOfShapes}}$`,
        `$= ${part_a_answer.toString()}$`,

        `b) $P( S \\cap \\ B \\cap M ) = \\frac{${s_and_b_and_m}}{${numOfShapes}}$`,
        `$${part_b_answer_text}$`,

        `c) $P( B \\cap S ) = \\frac{${s_and_b}}{${numOfShapes}}$`,
        `$${part_c_answer_text}$`,

        `d) $P( (B \\cup M)' ) = 1 - \\frac{${b}+${m}+${b_and_m}}{${numOfShapes}}$`,
        `$= ${part_d_answer.toString()}$`,

        `e) $P( (S \\cap B' \\cap M') \\cup (S \\cap B \\cap M) ) = \\frac{${s}+${s_and_b_and_m}}{${numOfShapes}}$`,
        `$= ${part_e_answer.toString()}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(2);
      const group3 = new MCQuestion.InputGroup(1);

      group1.addInput(part_a_answer.toFloat(), 0.05, `a) =`);
      group1.addInput(part_b_answer.toFloat(), 0.05, `b) =`);
      group2.addInput(part_c_answer.toFloat(), 0.05, `c) =`);
      group2.addInput(part_d_answer.toFloat(), 0.05, `d) =`);
      group3.addInput(part_e_answer.toFloat(), 0.05, `e) =`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_SetNotation_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const prob_a = MCRandom.randomInt(1, 9) / 10;
    const prob_b = MCRandom.randomInt(1, 9) / 10;
    const prob_a_and_b = MCRandom.randomInt(1, 9) / 10;

    const num_a = 10 * (prob_a - prob_a_and_b);
    const num_b = 10 * (prob_b - prob_a_and_b);
    const num_a_and_b = 10 * prob_a_and_b;
    const num_a_or_b = num_a + num_a_and_b + num_b;
    const num_not_a_or_b = 10 - num_a_or_b;

    const part_a_answer = num_a_or_b / 10;
    const part_b_answer = 1 - part_a_answer;
    const part_c_answer = (num_b + num_not_a_or_b) / 10;
    const part_d_answer = num_b / 10;

    /* END VARIABLES AND CONSTANTS */
    if (
      prob_a + prob_b + prob_a_and_b <= 0.9 &&
      prob_a > prob_a_and_b &&
      prob_b > prob_a_and_b /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Two events $A$ and $B$ occur with probability $P(A) = ${prob_a}$ and $P(B) = ${prob_b}$.`,
        `The probability of them both occuring, $P(A \\cap B) = ${prob_a_and_b}$`,
        `These probabilities are based off $10$ events occuring.`,
        `By drawing a venn diagram, find:`,
        `a) $P(A \\cup B)$`,
        `b) $P((A \\cup B)')$`,
        `c) $P(A')$`,
        `d) $P(A' \\cap B)$`,
      ]);

      question.addParagraph(
        "solution",
        `HEADING Since the probabilites are based off $10$ events occuring we can draw a venn diagram of how many times the events occured:`
      );

      const image = new MCQuestion.Image(`Leo/2Circle.svg`, "height", 20);
      image.addOverlay(`$A$`, 20, 20, 1.5);
      image.addOverlay(`$B$`, 80, 20, 1.5);
      image.addOverlay(`$${num_a.toFixed(0)}$`, 30, 40, 1.5);
      image.addOverlay(`$${num_a_and_b.toFixed(0)}$`, 50, 50, 1.5);
      image.addOverlay(`$${num_b.toFixed(0)}$`, 70, 60, 1.5);
      image.addOverlay(`$${num_not_a_or_b}$`, 7, 90, 1.5);

      question.addImage("solution", image);

      question.addMultipleParagraphs("solution", [
        MCMaths.cleaner(
          `a) $P(A \\cup B) = \\frac{${num_a.toFixed(0)}+${num_a_and_b.toFixed(
            0
          )}+${num_b.toFixed(0)}}{10}$`,
          false
        ),
        `$= ${part_a_answer.toFixed(1)}$`,

        MCMaths.cleaner(
          `b) $P((A \\cup B)') = 1 - (${part_a_answer.toFixed(1)})$`,
          false
        ),
        `$= ${part_b_answer.toFixed(1)}$`,

        `c) $P(A') = \\frac{${num_b.toFixed(0)}+${num_not_a_or_b.toFixed(
          0
        )}}{10}$`,
        `$= ${part_c_answer.toFixed(1)}$`,
        `d) $P(A' \\cap B) = \\frac{${num_b}}{10}$`,
        `$= ${part_d_answer.toFixed(1)}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(2);

      group1.addInput(parseFloat(part_a_answer.toFixed(1)), 0.05, `a) =`);
      group1.addInput(parseFloat(part_b_answer.toFixed(1)), 0.05, `b) =`);
      group2.addInput(parseFloat(part_c_answer.toFixed(1)), 0.05, `c) =`);
      group2.addInput(parseFloat(part_d_answer.toFixed(1)), 0.05, `d) =`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_SetNotation_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const prob_a = MCRandom.randomInt(10, 90) / 100;
    const prob_b = MCRandom.randomInt(10, 90) / 100;
    const prob_a_and_b = MCRandom.randomInt(10, 90) / 100;

    const num_a = 100 * (prob_a - prob_a_and_b);
    const num_b = 100 * (prob_b - prob_a_and_b);
    const num_a_and_b = 100 * prob_a_and_b;
    const num_a_or_b = num_a + num_a_and_b + num_b;
    const num_not_a_or_b = 100 - num_a_or_b;

    const part_a_answer = num_a_or_b / 100;
    const part_b_answer = num_b / 100;
    const part_c_answer = (num_a + num_a_and_b + num_not_a_or_b) / 100;

    /* END VARIABLES AND CONSTANTS */
    if (
      prob_a + prob_b + prob_a_and_b <= 0.9 &&
      prob_a > prob_a_and_b &&
      prob_b > prob_a_and_b /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `The probability that an A-Level student studies art ($A$) is $P(A) = ${prob_a}$ and the probability that a student studies biology ($B$) is $P(B) = ${prob_b}$.`,
        `The probability of a student studying both is $${prob_a_and_b}$`,
        `Given that these probabilities are based off a sample size of $100$ students.`,
        `By drawing a venn diagram, find:`,
        `a) $P(A \\cup B)$`,
        `b) $P(A' \\cap B)$`,
        `c) $P(A \\cup B')$`,
      ]);

      question.addParagraph(
        "solution",
        `HEADING Since the probabilites are based off $100$ events occuring we can draw a venn diagram of how many times the events occured:`
      );

      const image = new MCQuestion.Image(`Leo/2Circle.svg`, "height", 20);
      image.addOverlay(`$A$`, 20, 20, 1.5);
      image.addOverlay(`$B$`, 80, 20, 1.5);
      image.addOverlay(`$${num_a.toFixed(0)}$`, 30, 40, 1.5);
      image.addOverlay(`$${num_a_and_b.toFixed(0)}$`, 50, 50, 1.5);
      image.addOverlay(`$${num_b.toFixed(0)}$`, 70, 60, 1.5);
      image.addOverlay(`$${num_not_a_or_b}$`, 7, 90, 1.5);

      question.addImage("solution", image);

      question.addMultipleParagraphs("solution", [
        `$P(A \\cup B) = \\frac{${num_a.toFixed(0)}+${num_a_and_b.toFixed(
          0
        )}+${num_b.toFixed(0)}}{100}$`,
        `$= ${part_a_answer.toFixed(2)}$`,

        `$P(A' \\cap B) = \\frac{${num_b.toFixed(0)}}{100}$`,
        `$=${part_b_answer.toFixed(2)}$`,
        `c) $P(A \\cup B') = \\frac{${num_a.toFixed(0)}+${num_a_and_b.toFixed(
          0
        )}+${num_not_a_or_b.toFixed(0)}}{100}$`,
        `$= ${part_c_answer.toFixed(2)}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(parseFloat(part_a_answer.toFixed(2)), 0.05, `a) =`);
      group1.addInput(parseFloat(part_b_answer.toFixed(2)), 0.05, `b) =`);
      group2.addInput(parseFloat(part_c_answer.toFixed(2)), 0.05, `c) =`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_SetNotation_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const person = MCMisc.getName();

    const numOfSweets = MCRandom.randomInt(30, 50);

    const numOfH = MCRandom.randomInt(1, numOfSweets);
    const numOfS = MCRandom.randomInt(1, numOfSweets);
    const numOfHnS = MCRandom.randomInt(1, numOfSweets);

    const total = numOfH + numOfS + numOfHnS;

    const part_a_answer = new MCMaths.Fraction(numOfHnS / numOfSweets);
    const part_a_answer_text =
      `\\frac{${numOfHnS}}{${numOfSweets}}` === part_a_answer.toString()
        ? ``
        : `=${part_a_answer.toString()}`;

    const part_b_answer = new MCMaths.Fraction(
      (numOfH + numOfHnS) / numOfSweets
    );
    const part_b_answer_text =
      `\\frac{${numOfH + numOfHnS}}{${numOfSweets}}` ===
      part_b_answer.toString()
        ? ``
        : `=${part_b_answer.toString()}`;

    const part_c_answer = new MCMaths.Fraction(
      (numOfH + numOfS + numOfHnS) / numOfSweets
    );
    const part_c_answer_text =
      `\\frac{${numOfH + numOfS + numOfHnS}}{${numOfSweets}}` ===
      part_c_answer.toString()
        ? ``
        : `=${part_c_answer.toString()}`;

    const part_d_answer = new MCMaths.Fraction(numOfH / numOfSweets);
    const part_d_answer_text =
      `\\frac{${numOfH}}{${numOfSweets}}` === part_d_answer.toString()
        ? ``
        : `=${part_d_answer.toString()}`;

    const part_e_answer = new MCMaths.Fraction(1 - part_c_answer.toFloat());

    const image = new MCQuestion.Image("Matt/table1.svg", "width", 30);
    image.addOverlay(`Event`, 17, 15, 2);
    image.addOverlay("Occurance", 60, 15, 2);
    image.addOverlay(`$H$`, 15, 35, 2);
    image.addOverlay(`$S$`, 15, 55, 2);
    image.addOverlay(`$H \\cap S$`, 15, 75, 2);
    image.addOverlay(`$${numOfH}$`, 60, 35, 2);
    image.addOverlay(`$${numOfS}$`, 60, 55, 2);
    image.addOverlay(`$${numOfHnS}$`, 60, 75, 2);

    /* END VARIABLES AND CONSTANTS */
    if (
      total < numOfSweets /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `${person.name} has a bag of sweets of different shapes and flavours.`,
        `The event $H$ is the event of choosing a sweet that is in the shape of a heart.`,
        `The event $S$ is the event of choosing a sweet that is strawberry flavoured.`,
        `${person.name} empties the bag and tallies whether a sweet is in the shape of a heart, is strawberry flavoured or is both.`,
        `The respective amounts are shown below:`,
      ]);

      question.addImage("question", image);

      question.addMultipleParagraphs("question", [
        `Given that there are $${numOfSweets}$ in the bag, find:`,
        `a) $P(H \\cap S)$`,
        `b) $P(H)$`,
        `c) $P(H \\cup S)$`,
        `d) $P(H \\cap S')$`,
        `e) $P((H \\cup S)')$`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) $P(H \\cap S) = \\frac{${numOfHnS}}{${numOfSweets}}$`,
        `$${part_a_answer_text}$`,
        `b) $P(H) = \\frac{(${numOfH}+${numOfHnS})}{${numOfSweets}}$`,
        `$= \\frac{${numOfH + numOfHnS}}{${numOfSweets}}$`,
        `$${part_b_answer_text}$`,
        `c) $P(H \\cup S) = \\frac{${numOfH}+${numOfS}+${numOfHnS}}{${numOfSweets}}$`,
        `$= \\frac{${numOfH + numOfS + numOfHnS}}{${numOfSweets}}$`,
        `$${part_c_answer_text}$`,
        `d) $P(H \\cap S') = \\frac{${numOfH}}{${numOfSweets}}$`,
        `$${part_d_answer_text}$`,
        `e) $P((H \\cup S)') = 1 - \\frac{${
          numOfH + numOfS + numOfHnS
        }}{${numOfSweets}}$`,
        `$= ${part_e_answer.toString()}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(2);
      const group3 = new MCQuestion.InputGroup(1);

      group1.addInput(part_a_answer.toFloat(), 0.05, `a) =`);
      group1.addInput(part_b_answer.toFloat(), 0.05, `b) =`);
      group2.addInput(part_c_answer.toFloat(), 0.05, `c) =`);
      group2.addInput(part_d_answer.toFloat(), 0.05, `d) =`);
      group3.addInput(part_e_answer.toFloat(), 0.05, `e) =`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Probability_SetNotation_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const prob_a = MCRandom.randomInt(1, 99) / 100;
    const prob_b = MCRandom.randomInt(1, 99) / 100;
    const prob_c = MCRandom.randomInt(1, 99) / 100;

    const part_b_answer = prob_a + prob_b + prob_c;

    /* END VARIABLES AND CONSTANTS */
    if (
      part_b_answer < 0.99 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Given that events $A$, $B$ and $C$ are all independent and $P(A) = ${prob_a}$, $P(B) = ${prob_b}$, $P(C) = ${prob_c}$`,
        `Find:`,
        `a) $P(A \\cap B \\cap C)$`,
        `b) $P(A \\cup B \\cup C)$`,
        `c) $P(A \\cap (B \\cup C'))$`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) $P(A \\cap B \\cap C) = 0$ (Since all of the events are independent of each other)`,
        `b) $P(A \\cup B \\cup C) = ${prob_a}+${prob_b}+${prob_c}$`,
        `$=${part_b_answer.toFixed(2)}$`,
        `c) $P(A \\cap (B \\cup C')) = P(A)$`,
        `$=${prob_a}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(0, 0, `a) =`);
      group1.addInput(parseFloat(part_b_answer.toFixed(2)), 0.05, `b) =`);
      group2.addInput(prob_a, 0.05, `c) =`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Projectiles_ProjectileProblems_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const theta = MCRandom.randomInt(30, 60);
    const thetaRadians = theta * (Math.PI / 180);
    const initialVelocity = MCRandom.randomInt(30, 50);

    const poleHeight = MCRandom.randomInt(2, 5);

    const H = (initialVelocity * Math.sin(thetaRadians)) ** 2 / (2 * 9.8);

    const heightDifference = H - poleHeight;

    const t = Math.sqrt((2 * heightDifference) / 9.8);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A ball is projected from a point with an initial velocity of $${initialVelocity}ms^{-1}$ at an angle of $${theta}^{\\circ}$`,
        `At time $t$, the ball hits a pole and comes to rest.`,
        `$\\text{ } $`,
      ]);

      const myImage = new MCQuestion.Image(
        `Matt/projectileDiagram1.svg`,
        "width",
        30
      );
      myImage.addOverlay(`$${theta}^{\\circ}$`, 14, 73);
      myImage.addOverlay(`$${initialVelocity}ms^{-1}$`, 30, 6);
      question.addImage("question", myImage);

      question.addMultipleParagraphs("question", [
        `Given that the pole has a height of $${poleHeight}m$,`,
        `a) Find the maximum height, $H$, that the ball reaches.`,
        `b) Find the value of $t$.`,
        `Assume that $g = 9.8ms^{-2}$ and give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Rearranging the SUVAT equation $v^2 = u^2 + 2as$ we can see that:`,
        `$\\displaystyle s = \\frac{v^2 - u^2}{2a}$`,
        `$\\displaystyle s = \\frac{-${initialVelocity}\\sin(${theta}^{\\circ})^2}{2(-9.8)}$`,
        `$\\therefore H = ${H.toPrecision(3)}m$`,

        `HEADING b) Using the SUVAT equation $s = ut + \\frac{1}{2}at^2$ where $s$ is the difference between the pole height and the max height, $H$, we can see that:`,
        `$${H.toPrecision(3)} - ${poleHeight} = \\frac{1}{2}(9.8)t^2$`,
        `$${heightDifference.toPrecision(3)} = \\frac{1}{2}(9.8)t^2$`,
        `$\\displaystyle t^2 = \\frac{2\\times${heightDifference.toPrecision(
          3
        )}}{9.8}$`,
        `$\\displaystyle t = \\sqrt{\\frac{2\\times${heightDifference.toPrecision(
          3
        )}}{9.8}}$`,
        `$\\therefore t = ${t.toPrecision(3)}s$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(parseFloat(H.toPrecision(3)), 0.1, `a) $\\text{ } H =$`);
      group2.addInput(parseFloat(t.toPrecision(3)), 0.01, `b) $\\text{ } t =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Projectiles_ProjectileProblems_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const initialHeight = MCRandom.randomInt(3, 9);
    const theta = MCRandom.randomInt(30, 60);

    const maxHeight = MCRandom.randomInt(10, 15);

    const s = maxHeight - initialHeight;

    const initialVelocity = 2 * 9.8 * s;

    const tMid = initialVelocity / 9.8;
    const t = Math.sqrt((2 * s) / 9.8);

    const T = tMid + t;

    /* END VARIABLES AND CONSTANTS */
    if (t > 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A projectile is launched from a height of $${initialHeight}m$ with an initial velocity of $Ums^{-1}$ at an angle of $${theta}^{\\circ}$`,
      ]);

      const myImage = new MCQuestion.Image(
        `Matt/projectileDiagram2.svg`,
        "width",
        30
      );
      myImage.addOverlay(`$${theta}^{\\circ}$`, 22, 45);
      myImage.addOverlay(`$Ums^{-1}$`, 30, 18);
      myImage.addOverlay(`$${maxHeight}m$`, 46, 50);
      myImage.addOverlay(`$${initialHeight}m$`, 3, 62);
      question.addImage("question", myImage);

      question.addMultipleParagraphs("question", [
        `a) Find the value of $U$.`,
        `b) Find how long it takes for the ball to reach its maximum height, $t_0$.`,
        `c) Hence, or otherwise, find how long it takes, $T$, for the ball to reach the ground.`,
        `Assume that $g = 9.8ms^{-2}$ and give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Using the SUVAT equation $v^2 = u^2 + 2as$ we can see that:`,
        `$0 = (U\\sin(${theta}^{\\circ}))^2 + 2(-9.8)(${s})$`,
        `$(U\\sin(${theta}^{\\circ}))^2 = 2(9.8)(${s})$`,
        `$\\displaystyle U = \\frac{\\sqrt{2(9.8)(${s})}}{\\sin(${theta}^{\\circ})}$`,
        `$\\therefore U = ${initialVelocity.toPrecision(3)}ms^{-1}$.`,

        `HEADING b) Using part a) and using the SUVAT equation $v = u + at$ we can see that:`,
        `$0 = ${initialVelocity.toPrecision(3)} - 9.8t_0$`,
        `$9.8t_0 = ${initialVelocity.toPrecision(3)}$`,
        `$\\displaystyle t_0 = \\frac{${initialVelocity.toPrecision(3)}}{9.8}$`,
        `$t_0 = ${tMid.toPrecision(3)}s$`,

        `HEADING c) Using the SUVAT equation $s = ut + \\frac{1}{2}at^{2}$ we can see that the time it takes for the projectile to fall from its maximum height:`,
        `$\\displaystyle ${maxHeight} = \\frac{1}{2}(9.8)t^2$`,
        `$\\displaystyle t^2 = \\frac{2\\times${maxHeight}}{9.8}$`,
        `$\\displaystyle t = \\sqrt{\\frac{2\\times${maxHeight}}{9.8}}$`,
        `$t = ${t.toPrecision(3)}s$`,
        `HEADING Using part b) The total time is:`,
        `$T = ${t.toPrecision(3)} + ${tMid.toPrecision(3)}$`,
        `$T = ${T.toPrecision(3)}s$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      const group3 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(initialVelocity.toPrecision(3)),
        1,
        `a) $\\text{ } U =$`
      );
      group2.addInput(
        parseFloat(tMid.toPrecision(3)),
        0.1,
        `b) $\\text{ } t_0 =$`
      );
      group3.addInput(parseFloat(T.toPrecision(3)), 0.1, `c) $\\text{ } T =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Projectiles_ProjectileProblems_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const initialVelocity = MCRandom.randomInt(30, 50);

    const maxHeight = MCRandom.randomInt(10, 15);
    const initialHeight = MCRandom.randomInt(3, 9);
    const s = maxHeight - initialHeight;

    const sinTheta = Math.sqrt(2 * s * 9.8) / initialVelocity;
    const thetaRadians = Math.asin(sinTheta);
    const thetaDegrees = thetaRadians * (180 / Math.PI);

    const a = -4.9;
    const b = initialVelocity * sinTheta;
    const c = initialHeight;

    const t1 = (-b + Math.sqrt(b ** 2 - 4 * a * c)) / (2 * a);
    const t2 = (-b - Math.sqrt(b ** 2 - 4 * a * c)) / (2 * a);

    const D = initialVelocity * Math.cos(thetaRadians) * t2;

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A projectile is launched from an initial height of $${initialHeight}m$ with an initial velocity of $${initialVelocity}ms^{-1}$ at an angle of $\\theta^{\\circ}$ to the horizontal.`,
        `The projectile reaches a maximum height of $${maxHeight}m$.`,
      ]);

      const myImage = new MCQuestion.Image(
        `Matt/projectileDiagram2.svg`,
        "width",
        30
      );
      myImage.addOverlay(`$\\theta$`, 22, 45);
      myImage.addOverlay(`$${initialVelocity}ms^{-1}$`, 31, 18);
      myImage.addOverlay(`$${maxHeight}m$`, 46, 50);
      myImage.addOverlay(`$${initialHeight}m$`, 4, 62);
      question.addImage("question", myImage);

      question.addMultipleParagraphs("question", [
        `a) Find the value of $\\theta$.`,
        `b) The horizontal distance, $D$, that the projectile travels.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Using the SUVAT equation $v^2 = u^2 + 2as$`,
        `$0 =  + 2(-9.8)(${s})$`,
        `$(${initialVelocity}\\sin(\\theta))^2 = 2(9.8)(${s})$`,
        `$${initialVelocity}\\sin(\\theta) = \\sqrt{2(9.8)(${s})}$`,
        `$\\displaystyle \\sin(\\theta) = \\frac{\\sqrt{2(9.8)(${s})}}{${initialVelocity}}$`,
        `$\\displaystyle \\theta = \\sin^{-1}\\left(\\frac{\\sqrt{2(9.8)(${s})}}{${initialVelocity}}\\right)$`,
        `$\\theta = ${thetaDegrees.toPrecision(3)}^{\\circ}$`,

        `HEADING b) Using the SUVAT equation $s = ut + \\frac{1}{2}at^2$ we can see that:`,
        `$-${initialHeight} = (${initialVelocity}\\sin(${thetaDegrees.toPrecision(
          3
        )}^{\\circ}))t + \\frac{1}{2}(-9.8)t^2$`,
        `$(${initialVelocity}\\times${sinTheta.toPrecision(
          3
        )})t - 4.9t^2 + ${initialHeight} = 0$`,
        `$${b.toPrecision(3)}t - 4.9t^2 + ${initialHeight} = 0$`,
        `Solving the quadratic we see that $t = ${t1.toPrecision(
          3
        )}s, \\text{ } t = ${t2.toPrecision(3)}s$ and since $t > 0$`,
        `$\\therefore t = ${t2.toPrecision(3)}s$`,
        `HEADING Now considering the horizontal motion of the projectile and since $v = \\frac{s}{t}$ we can see that:`,
        `$s = vt$`,
        `$\\implies D = (${initialVelocity}\\cos(${thetaDegrees.toPrecision(
          3
        )}))(${t2.toPrecision(3)})$`,
        `$D = ${D.toPrecision(3)}m$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(thetaDegrees.toPrecision(3)),
        0.1,
        `a) $\\text{ } \\theta =$`
      );
      group2.addInput(parseFloat(D.toPrecision(3)), 0.1, `b) $\\text{ } D =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Projectiles_ProjectileProblems_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const initialVelocity = MCRandom.randomInt(30, 50);
    const theta = MCRandom.randomInt(10, 45);
    const thetaRadians = theta * (Math.PI / 180);

    const a = -4.9;
    const b = initialVelocity * Math.sin(thetaRadians);
    const c = 0;

    const t1 = (-b + Math.sqrt(b ** 2 - 4 * a * c)) / (2 * a);
    const t2 = (-b - Math.sqrt(b ** 2 - 4 * a * c)) / (2 * a);

    const x = initialVelocity * Math.cos(thetaRadians) * t2;

    const v_vert = 9.8 * (t2 / 2);
    const v_horizontal = initialVelocity * Math.cos(thetaRadians);

    const v_magnitudeSquared = v_vert ** 2 + v_horizontal ** 2;
    const v_magnitude = Math.sqrt(v_magnitudeSquared);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A projectile is fired from the ground at point $P$ with a velocity of $${initialVelocity}ms^{-1}$ at an angle of $${theta}^{\\circ}$ to the horizontal.`,
        `The projectile hits the ground $x$ metres away from point $A$ at point $B$.`,
        `a) Find the time of flight, $t_0$, that the projectile is in the air.`,
        `b) Find the value of $x$.`,
        `c) Find the magnitude of the velocity, $|v|$.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Using the SUVAT equation $s = ut + \\frac{1}{2}at^2$`,
        `$0 = ${initialVelocity}\\sin(${theta}^{\\circ})t + \\frac{1}{2}(-9.8)t^2$`,
        `HEADING Solving the quadratic equation we obtain:`,
        `$t = ${t1.toPrecision(3)}s, \\text { } t = ${t2.toPrecision(3)}s$`,
        `Since $t > 0$,`,
        `$\\therefore t_0 = ${t2.toPrecision(3)}s$.`,

        `HEADING b) Now considering the horizontal motion of the projectile and since $v = \\frac{s}{t}$ we can see that:`,
        `$s = vt$`,
        `$\\implies x = (${initialVelocity}\\cos(${theta}^{\\circ}))(${t2.toPrecision(
          3
        )})$`,
        `$x = ${x.toPrecision(3)}m$.`,

        `HEADING c) For the vertical, $v_0$, component of the velocity we use the SUVAT equation $v = u + at$:`,
        `$\\implies v_0 = 0 + 9.8 \\left(\\frac{${t2.toPrecision(
          3
        )}}{2}\\right)$`,
        `$= ${v_vert.toPrecision(3)}$`,
        `HEADING The horizontal component, $v_1$ is calculated by resolving the components of the initial velocity:`,
        `$\\implies v_1 = ${initialVelocity}\\cos(${theta}^{\\circ})$`,
        `HEADING Using pythagoras' theorem we can now calculate $|v|$:`,
        `$|v|^2 = ${v_vert.toPrecision(3)}^2 + ${v_horizontal.toPrecision(
          3
        )}^2$`,
        `$|v| = \\sqrt{${v_vert.toPrecision(3)}^2 + ${v_horizontal.toPrecision(
          3
        )}^2}$`,
        `$|v| = ${v_magnitude.toPrecision(3)}ms^{-1}$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      const group3 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(t2.toPrecision(3)),
        0.01,
        `a) $\\text{ } t_0 =$`
      );
      group2.addInput(parseFloat(x.toPrecision(3)), 0.1, `b) $\\text{ } x =$`);
      group3.addInput(
        parseFloat(v_magnitude.toPrecision(3)),
        0.1,
        `c) $\\text{ } |v| =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Projectiles_ProjectileProblems_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const initialVelocity = MCRandom.randomInt(30, 50);
    const theta = MCRandom.randomInt(20, 45);
    const thetaRadians = theta * (Math.PI / 180);
    const barHeight = MCRandom.randomInt(2, 9);

    const a = -4.9;
    const b = initialVelocity * Math.sin(thetaRadians);
    const c = -barHeight;

    const t1 = (-b + Math.sqrt(b ** 2 - 4 * a * c)) / (2 * a);
    const t2 = (-b - Math.sqrt(b ** 2 - 4 * a * c)) / (2 * a);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A projectile is fired from the ground with a velocity of $${initialVelocity}ms^{-1}$ at an angle of $${theta}^{\\circ}$`,
        `The projectile hits a horizontal bar at time $t = ${t2.toPrecision(
          3
        )}s$`,
        `Find the height of the horizontal bar, $h$ to the nearest integer.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING Using the SUVAT equation $s = ut + \\frac{1}{2}at^2$`,
        `$h = ${initialVelocity}\\sin(${theta}^{\\circ})(${t2.toPrecision(
          3
        )}) + \\frac{1}{2}(-9.8)(${t2.toPrecision(3)})^2$`,
        `$\\therefore h = ${barHeight}m$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(barHeight, 0.1, `$h =$`);
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Projectiles_ProjectileProblems_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const h1 = MCRandom.randomInt(3, 7);
    const h2 = MCRandom.randomInt(8, 15);

    const theta = MCRandom.randomInt(50, 80);
    const thetaRadians = theta * (Math.PI / 180);
    const initialVelocity = MCRandom.randomInt(15, 25);

    const H = (initialVelocity * Math.sin(thetaRadians)) ** 2 / (2 * 9.8) + h1;

    const hDifference = h2 - h1;

    const a = 4.9;
    const b = initialVelocity * Math.sin(thetaRadians);
    const c = -hDifference;

    const t1 = (-b + Math.sqrt(b ** 2 - 4 * a * c)) / (2 * a);
    const t2 = (-b - Math.sqrt(b ** 2 - 4 * a * c)) / (2 * a);

    const x = initialVelocity * Math.cos(thetaRadians) * t1;

    /* END VARIABLES AND CONSTANTS */
    if (H - h2 > 2 && H - h2 < 5) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A projectile is launched from a concrete block of height $${h1}m$ to another with height $${h2}m$ as shown in the diagram below.`,
        `The projectile is launched with an initial velocity of $${initialVelocity}ms^{-1}$ at an angle of $${theta}^{\\circ}$.`,
      ]);

      const myImage = new MCQuestion.Image(
        `Matt/projectileDiagram3.svg`,
        "width",
        30
      );
      myImage.addOverlay(`$${h1}m$`, 39, 65);
      myImage.addOverlay(`$${h2}m$`, 52, 55);
      myImage.addOverlay(`$x$`, 47, 89);
      myImage.addOverlay(`$${initialVelocity}ms^{-1}$`, 39, 13);
      myImage.addOverlay(`$${theta}^{\\circ}$`, 34, 44);
      question.addImage("question", myImage);

      question.addMultipleParagraphs("question", [
        `a) Find the maximum height, $H$, that the projectile reaches.`,
        `b) Find the time it takes, $t_0$, for the projectile to reach the second concrete block.`,
        `c) Find the distance between the initial launch and where the projectile lands, $x$.`,
        `Assume that $g = 9.8ms^{-2}$ and that air resistance is negligible.`,
        `Give your answers to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Using the SUVAT equation $v^2 = u^2 + 2as$ we can see that:`,
        `$0 = (${initialVelocity}\\sin(${theta}^{\\circ}))^2 + 2(-g)s$`,
        `$2gs = (${initialVelocity}\\sin(${theta}^{\\circ}))^2$`,
        `$\\displaystyle s = \\frac{(${initialVelocity}\\sin(${theta}^{\\circ}))^2}{2g}$`,
        `Where $s$ is the maximum distance travelled upwards from the first concrete block.`,
        `For the maximum height $H$, we add the height that the projectile is initially launched from:`,
        `$\\displaystyle H = \\frac{(${initialVelocity}\\sin(${theta}^{\\circ}))^2}{2g} + ${h1}$`,
        `$\\therefore H = ${H.toPrecision(3)}m$`,

        `HEADING b) Using the SUVAT equation $s = ut + \\frac{1}{2}at^2$ we can see that:`,
        `$${h2}-${h1} = ${initialVelocity}\\sin(${theta}^{\\circ})t + \\frac{1}{2}(-g)t^2$`,
        `$- 4.9t^2 + ${initialVelocity}\\sin(${theta}^{\\circ})t - ${hDifference} = 0$`,
        `HEADING Solving the quadratic we obtain:`,
        `$t = ${t1.toPrecision(3)}s, \\text{ } t = ${t2.toPrecision(3)}s$`,
        `HEADING Since $t > 0$:`,
        `$\\therefore t_0 = ${t1.toPrecision(3)}s$`,

        `HEADING c) Considering the horizontal motion of the projectile and using the fact that $v = \\frac{s}{t}$ we can see that:`,
        `$s = vt$`,
        `$\\implies x = (${initialVelocity}\\cos(${theta}^{\\circ}))(${t1.toPrecision(
          3
        )})$`,
        `$\\therefore x = ${x.toPrecision(3)}m$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      const group3 = new MCQuestion.InputGroup(1);

      group1.addInput(parseFloat(H.toPrecision(3)), 0.1, `a) $\\text{ } H =$`);
      group2.addInput(
        parseFloat(t1.toPrecision(3)),
        0.001,
        `b) $\\text{ } t_0 =$`
      );
      group3.addInput(parseFloat(x.toPrecision(3)), 0.01, `c) $\\text{ } x =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Proof_ProofByContradiction_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const primes = [2, 3, 5, 7];
    const p = primes[MCRandom.randomInt(0, primes.length - 1)];

    const mode = MCRandom.randomInt(0, 1);
    const k1 = MCRandom.randomInt(2, 6);
    const k = (k1 ** 2) ** mode;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Prove $\\sqrt{${p * k}}$ is irrational.`
      );
      if (mode === 1) {
        question.addMultipleParagraphs("solution", [
          `$\\sqrt{${p * k}} = ${k1}\\sqrt{${p}}$.`,
          `Need to prove $\\sqrt{${p}}$`,
        ]);
      }
      question.addMultipleParagraphs("solution", [
        `HEADING Using proof by contradiction:`,
        `Assume $\\sqrt{${p}}$ is rational.`,
        `$\\displaystyle\\sqrt{${p}} = \\frac{p}{q}$, for integers $p,q$, with no common factors.`,
        `$${p}q^2=p^2$`,
        `$\\therefore p^2$ has a factor of $${p}$`,
        `As $p^2$ has a factor of $${p}$, $p$ has a factor of $${p}$.`,
        `$p$ can be written in the form $${p}n$ for some integer $n$.`,
        `$${p}q^2 = (${p}n)^2$`,
        `$q^2 = ${p}n^2$`,
        `$\\therefore q^2$ has a factor of $${p}$`,
        `So $q$ must have a factor of ${p}.`,
        `As $p,q$ have a common factor of $${p}$, this contradicts the initial assumption.`,
        `$\\therefore \\sqrt{${p}}$ is irrational by contradiction.`,
      ]);
      if (mode === 1) {
        question.addParagraph(
          "solution",
          `$\\therefore \\sqrt{${p * k}}$ is irrational.`
        );
      }
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Proof_ProofByContradiction_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        "Prove there are an infinite number of primes."
      );
      question.addMultipleParagraphs("solution", [
        `HEADING Proof By Contradiction:`,
        `Assume there a finite number of primes.`,
        `Label all primes $p_1, p_2, \\cdots , p_n$.`,
        `Consider $x=p_1 \\times p_2 \\times ... \\times p_n + 1$`,
        `Dividing $x$ by any $p_k$ gives remainder $1$`,
        `$x$ is then prime as it has no prime factors.`,
        `Assumption causes condradiction as list must not contain all primes.`,
        `$\\therefore$ There are an infinite number of primes.`,
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Proof_ProofByContradiction_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        'Prove the statement "If $n^2$ is odd, $n$ is odd", by contradiction.'
      );
      question.addMultipleParagraphs("solution", [
        `HEADING Assuming Negation:`,
        `Assume $n^2$ is odd and $n$ is even.`,
        `$n$ can be written as $2k$`,
        `$(2k)^2=4k^2$, which is even.`,
        `$\\therefore n^2$ is even, a contradiction of the assumption.`,
        `$\\therefore$ The statement is proved by contradiction.`,
      ]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Proof_ProofByContradiction_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Prove or disprove: If $ab$ is irrational, $a$ and $b$ are not both rational.`
      );
      question.addMultipleParagraphs("solution", [
        `HEADING Proof by contradiction:`,
        `Assume $a$ and $b$ are both rational.`,
        `$\\displaystyle a=\\frac{p}{q}, b=\\frac{m}{n}$`,
        `$\\displaystyle ab= \\frac{mp}{nq}$, so is rational.`,
        `$\\therefore$ by contradiction the statement is true.`,
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Proof_ProofByContradiction_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const base = MCRandom.randomInt(3, 9, 2);
    const a1 = MCRandom.randomInt(2, 18, 2);
    const b1 = MCRandom.randomInt(3, 17, 2);
    const a = base * a1;
    const b = base * b1;
    /* END VARIABLES AND CONSTANTS */
    if (
      b <= 50 &&
      a <= 50 &&
      Math.abs(a - b) < 5 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Prove or disprove: $${a}x+${b}y=1$ for some integer $x,y$.`
      );
      question.addMultipleParagraphs("solution", [
        `Assume the statement is true.`,
        `Dividing by common factor: $\\displaystyle ${a1}x+${b1}y=\\frac{1}{${base}}$`,
        `The sum of integers is never a fraction.`,
        `$\\therefore$ The statement is false by contradiction.`,
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Proof_ProofByContradiction_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Prove there is no smallest positive rational number.`
      );
      question.addMultipleParagraphs("solution", [
        `HEADING Proof By Contradiction`,
        `Assume a smallest positive rational number $n$ exists.`,
        `$\\displaystyle n=\\frac{p}{q}$ as it is rational.`,
        `$\\displaystyle \\frac{n}{2} = \\frac{p}{2q}$, so is rational.`,
        `$\\displaystyle \\frac{n}{2}<n$, which contradicts the inital assumption.`,
        `$\\therefore$ The statment is true by contradiction.`,
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Regression_CorrelationHypothesisTesting_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p = MCMisc.getName();
    const n = MCRandom.randomInt(12, 25);

    const sig = [1, 5, 10][MCRandom.randomInt(0, 2)];

    const crit = parseFloat(MCMaths.Stats.pmcc(sig / 100, n).toFixed(3));

    const pmcc = parseFloat(
      (
        crit +
        (MCRandom.randomInt(-1, 1, 2) * MCRandom.randomInt(50, 120)) / 1000
      ).toFixed(3)
    );

    const bad = MCRandom.randomInt(2, 5);
    const newcrit = parseFloat(
      MCMaths.Stats.pmcc(sig / 100, n - bad).toFixed(3)
    );

    /* END VARIABLES AND CONSTANTS */
    if (pmcc < 0.95 && pmcc > 0.05) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `${p.name} decides to conduct some market research on computer processor's cost versus their transistors count. ${p.HeShe} believes that as the number of transistors increases, so will the cost at a linear rate. ${p.HeShe} gathered data on a sample of $${n}$ processors and a product moment correlation coefficient of $${pmcc}$ was calculated.`
      );
      question.addParagraph(
        `question`,
        `a) Test, at the $${sig}$% significance level, whether there is evidence to suggest ${p.name} was right in ${p.hisher} prediction.`
      );

      question.addParagraph(
        `question`,
        `${p.name} later finds out that the information for $${bad}$ of the processors he researched was false. He removed them from the sample and recalculated the pmcc.`
      );
      question.addParagraph(
        `question`,
        `b) Determine, at the $${sig}$% significance level, the new critical region of the hypothesis test.`
      );

      // solution
      question.addHeading(`solution`, `a) State hypothesis`);
      question.addParagraph("solution", `$H_0: p = 0$`);
      question.addParagraph("solution", `$H_1: p > 0$`);

      question.addParagraph(
        "solution",
        `1-Tailed Test, $${sig}$% significance.`
      );
      question.addParagraph("solution", `Sample size = $${n}$`);

      question.addHeading(
        "solution",
        `Critical value from the table is $${crit}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Critical region is $p>${crit}$`
      );

      if (pmcc < crit) {
        // less than means DO NOT reject H_0 in this case
        question.addParagraph("solution", `$${pmcc} < ${crit}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Do not reject $H_0$ at the $${sig}$% significance level.`
        );
        question.addParagraph(
          "solution",
          `Not enough evidence to suggest a postive correlation between transistor count and processor cost.`
        );
      } else {
        question.addParagraph("solution", `$${pmcc} > ${crit}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Reject $H_0$ at the $${sig}$% significance level.`
        );
        question.addParagraph(
          "solution",
          `Evidence to suggest a postive correlation between transistor count and processor cost.`
        );
      }
      // b
      question.addHeading(
        "solution",
        `b) Same hypotheses, number of tails and significance level as part a`
      );
      question.addParagraph("solution", `New sample size = $${n}-${bad}$`);
      question.addParagraph("solution", `= $${n - bad}$`);
      question.addHeading(
        "solution",
        `New critical value from the table is $${newcrit}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ New critical region is $p>${newcrit}$`
      );

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Regression_CorrelationHypothesisTesting_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = MCRandom.randomInt(10, 100, 10);

    const index = MCRandom.randomInt(0, 1);
    const sig = [5, 10][index];
    const sig2 = [1, 5][index];

    const crit = parseFloat((MCMaths.Stats.pmcc(sig / 100, n) * -1).toFixed(3));
    const crit2 = parseFloat(
      (MCMaths.Stats.pmcc(sig2 / 100, n) * -1).toFixed(3)
    );

    const pmcc = parseFloat(
      (
        crit +
        (MCRandom.randomInt(-1, 1, 2) * MCRandom.randomInt(50, 120)) / 1000
      ).toFixed(3)
    );

    /* END VARIABLES AND CONSTANTS */
    if (pmcc < -0.05 && pmcc > -0.95) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A research team conducts a study on the link between exercise and your probability in contracting a respiratory disease. Initially, they predict that there will be a linear, negative correlation between these factors. After conducting tests on $${n}$ people a product moment correlation coefficient of $${pmcc}$ was calculated.`
      );
      question.addParagraph(
        `question`,
        `a) Test, at the $${sig}$% significance level, whether there is evidence to suggest that the team's intial hypothesis was correct.`
      );
      question.addParagraph(
        `question`,
        `b) Determine the critical region of the test at a $${sig2}$% significance level`
      );

      // solution
      question.addHeading(`solution`, `a) State hypothesis`);
      question.addParagraph("solution", `$H_0: p = 0$`);
      question.addParagraph("solution", `$H_1: p < 0$`);

      question.addParagraph(
        "solution",
        `1-Tailed Test, $${sig}$% significance.`
      );
      question.addParagraph("solution", `Sample size = $${n}$`);

      question.addHeading(
        "solution",
        `Critical value from the table is $${-crit}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Critical region is $p<${crit}$`
      );

      if (pmcc < crit) {
        // less than means reject H_0 in this case
        question.addParagraph("solution", `$${pmcc} < ${crit}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Reject $H_0$ at the $${sig}$% significance level.`
        );
        question.addParagraph(
          "solution",
          `Evidence to suggest a negative correlation between chance of respiratory disease and exercise.`
        );
      } else {
        question.addParagraph("solution", `$${pmcc} > ${crit}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Do not reject $H_0$ at the $${sig}$% significance level.`
        );
        question.addParagraph(
          "solution",
          `Not enough evidence to suggest a negative correlation between chance of respiratory disease and exercise.`
        );
      }
      // b
      question.addHeading(
        "solution",
        `b) Same hypotheses, number of tails and sample size as part a`
      );
      question.addParagraph(
        "solution",
        `New critical value from the table is $${-crit}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ New critical region is $p<${crit}$`
      );

      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Regression_CorrelationHypothesisTesting_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p = MCMisc.getName();
    const n = MCRandom.randomInt(10, 30);

    const sig = [1, 5, 10][MCRandom.randomInt(0, 2)];

    const crit = parseFloat(MCMaths.Stats.pmcc(sig / 200, n).toFixed(3));

    const pmcc = parseFloat(
      (
        crit * MCRandom.randomInt(-1, 1, 2) +
        (MCRandom.randomInt(-1, 1, 2) * MCRandom.randomInt(50, 120)) / 1000
      ).toFixed(3)
    );

    /* END VARIABLES AND CONSTANTS */
    if (pmcc < 0.95 && pmcc > -0.95 && pmcc !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A farmer, ${p.name}, investigates whether there is any correlation between the age of a chicken and how many eggs it produces every month. After a month, ${p.heshe} calculates a product moment correlation coefficient of $${pmcc}$ from ${p.hisher} observations of $${n}$ chickens.`
      );
      question.addParagraph(
        `question`,
        `Test, at the $${sig}$% significance level, whether there is evidence to suggest that there is a correlation between these two factors.`
      );

      // solution
      question.addHeading(`solution`, `State hypothesis`);
      question.addParagraph("solution", `$H_0: p = 0$`);
      question.addParagraph("solution", `$H_1: p \\neq 0$`);

      question.addParagraph(
        "solution",
        `2-Tailed Test, $${sig / 2}$% significance each tail.`
      );
      question.addParagraph("solution", `Sample size = $${n}$`);
      question.addHeading(
        "solution",
        `Critical value from the table is $${crit}$`
      );
      if (pmcc < 0) {
        question.addParagraph(
          "solution",
          `$\\therefore$ Critical region is $p<${-crit}$ as the calculated pmcc is less than zero.`
        );
        if (pmcc < -crit) {
          // less than means reject H_0 in this case
          question.addParagraph("solution", `$${pmcc} < ${-crit}$`);
          question.addParagraph(
            "solution",
            `$\\therefore$ Reject $H_0$ at the $${sig}$% significance level.`
          );
          question.addParagraph(
            "solution",
            `Evidence to suggest a correlation between a chicken's age and the number of eggs it produces.`
          );
        } else {
          question.addParagraph("solution", `$${pmcc} > ${-crit}$`);
          question.addParagraph(
            "solution",
            `$\\therefore$ Do not reject $H_0$ at the $${sig}$% significance level.`
          );
          question.addParagraph(
            "solution",
            `Not enough evidence to suggest a correlation between a chicken's age and the number of eggs it produces.`
          );
        }
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore$ Critical region is $p>${crit}$ as the calculated pmcc is greater than zero.`
        );

        if (pmcc < crit) {
          // less than means DO NOT reject H_0 in this case
          question.addParagraph("solution", `$${pmcc} < ${crit}$`);
          question.addParagraph(
            "solution",
            `$\\therefore$ Do not reject $H_0$ at the $${sig}$% significance level.`
          );
          question.addParagraph(
            "solution",
            `Not enough evidence to suggest a correlation between a chicken's age and the number of eggs it produces.`
          );
        } else {
          question.addParagraph("solution", `$${pmcc} > ${crit}$`);
          question.addParagraph(
            "solution",
            `$\\therefore$ Reject $H_0$ at the $${sig}$% significance level.`
          );
          question.addParagraph(
            "solution",
            `Evidence to suggest a correlation between a chicken's age and the number of eggs it produces.`
          );
        }
      }

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Regression_CorrelationHypothesisTesting_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p = (MCRandom.randomInt(1, 9) / 10).toFixed(1);
    const person = MCMisc.getName();

    const word = [`a negative`, `a positive`, `some`][MCRandom.randomInt(0, 2)];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(3);
      question.addParagraph(
        `${person.name} believes there is ${word} correlation between the refresh rate and resolution of a monitor.`
      );
      question.addParagraph(
        `${person.HeShe} decides to conduct a hypothesis test to see if there's evidence for ${person.hisher} belief.`
      );
      question.addParagraph(
        `Select the correct alternative hypothesis for ${person.hisher} test.`
      );
      //

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$H_1: p \\neq 0$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$H_1: p< 0$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$H_1 :p> 0$`);

      question.addAnswer(answer1, word === `some`);
      question.addAnswer(answer2, word === `a negative`);
      question.addAnswer(answer3, word === `a positive`);
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Regression_CorrelationHypothesisTesting_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p = (MCRandom.randomInt(1, 9) / 10).toFixed(1);
    const symbol = ["<", "\\neq", ">"][MCRandom.randomInt(0, 2)];
    const acc = ["accepted", "rejected"][MCRandom.randomInt(0, 1)];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `A hypothesis test for correlation was carried out using the hypotheses:`
      );
      question.addParagraph(`$H_0:p = 0$`);
      question.addParagraph(`$H_1:p ${symbol} 0$`);
      question.addParagraph(`The test resulted in $H_0$ being ${acc}.`);
      question.addParagraph(`Select the correct statement from below.`);

      if (symbol === "<") {
        if (acc === "accepted") {
          const answer1 = new MCQuestion.MultipleChoiceAnswer();
          answer1.addParagraph(
            `Not enough evidence to suggest there is a negative correlation.`
          );

          const answer2 = new MCQuestion.MultipleChoiceAnswer();
          answer2.addParagraph(
            `Evidence to suggest there is a positive correlation.`
          );

          const answer3 = new MCQuestion.MultipleChoiceAnswer();
          answer3.addParagraph(`Evidence to suggest any correlation.`);

          const answer4 = new MCQuestion.MultipleChoiceAnswer();
          answer4.addParagraph(
            `Not enough evidence to suggest there is a negative correlation.`
          );

          question.addAnswer(answer1, true);
          question.addAnswer(answer2, false);
          question.addAnswer(answer3, false);
          question.addAnswer(answer4, false);
        } else {
          const answer1 = new MCQuestion.MultipleChoiceAnswer();
          answer1.addParagraph(
            `Evidence to suggest there is a negative correlation.`
          );

          const answer2 = new MCQuestion.MultipleChoiceAnswer();
          answer2.addParagraph(
            `Evidence to suggest there is a positive correlation.`
          );

          const answer3 = new MCQuestion.MultipleChoiceAnswer();
          answer3.addParagraph(
            `Not enough evidence to suggest there is a negative correlation.`
          );

          const answer4 = new MCQuestion.MultipleChoiceAnswer();
          answer4.addParagraph(
            `Not enough evidence to suggest any correlation.`
          );

          question.addAnswer(answer1, true);
          question.addAnswer(answer2, false);
          question.addAnswer(answer3, false);
          question.addAnswer(answer4, false);
        }
      } else if (symbol === "\\neq") {
        if (acc === "accepted") {
          const answer1 = new MCQuestion.MultipleChoiceAnswer();
          answer1.addParagraph(
            `Not enough evidence to suggest there is any correlation.`
          );

          const answer2 = new MCQuestion.MultipleChoiceAnswer();
          answer2.addParagraph(`Evidence to suggest there is any correlation.`);

          const answer3 = new MCQuestion.MultipleChoiceAnswer();
          answer3.addParagraph(
            `Not enough evidence to suggest there is a positive correlation.`
          );

          const answer4 = new MCQuestion.MultipleChoiceAnswer();
          answer4.addParagraph(
            `Evidence to suggest there is a negative correlation.`
          );

          question.addAnswer(answer1, true);
          question.addAnswer(answer2, false);
          question.addAnswer(answer3, false);
          question.addAnswer(answer4, false);
        } else {
          const answer1 = new MCQuestion.MultipleChoiceAnswer();
          answer1.addParagraph(
            `Evidence to suggest there is some correlation.`
          );

          const answer2 = new MCQuestion.MultipleChoiceAnswer();
          answer2.addParagraph(
            `Not enough evidence to suggest there is some correlation.`
          );

          const answer3 = new MCQuestion.MultipleChoiceAnswer();
          answer3.addParagraph(
            `Not enough evidence to suggest any negative correlation.`
          );

          const answer4 = new MCQuestion.MultipleChoiceAnswer();
          answer4.addParagraph(
            `Evidence to suggest there is a positive correlation.`
          );

          question.addAnswer(answer1, true);
          question.addAnswer(answer2, false);
          question.addAnswer(answer3, false);
          question.addAnswer(answer4, false);
        }
      } else if (acc === "accepted") {
        const answer1 = new MCQuestion.MultipleChoiceAnswer();
        answer1.addParagraph(
          `Not enough evidence to suggest there is a positive correlation.`
        );

        const answer2 = new MCQuestion.MultipleChoiceAnswer();
        answer2.addParagraph(
          `Evidence to suggest there is a negative correlation.`
        );

        const answer3 = new MCQuestion.MultipleChoiceAnswer();
        answer3.addParagraph(`Not enough evidence to suggest any correlation.`);

        const answer4 = new MCQuestion.MultipleChoiceAnswer();
        answer4.addParagraph(
          `Evidence to suggest there is a positive correlation.`
        );

        question.addAnswer(answer1, true);
        question.addAnswer(answer2, false);
        question.addAnswer(answer3, false);
        question.addAnswer(answer4, false);
      } else {
        const answer1 = new MCQuestion.MultipleChoiceAnswer();
        answer1.addParagraph(
          `Evidence to suggest there is a positive correlation.`
        );

        const answer2 = new MCQuestion.MultipleChoiceAnswer();
        answer2.addParagraph(
          `Evidence to suggest there is a negative correlation.`
        );

        const answer3 = new MCQuestion.MultipleChoiceAnswer();
        answer3.addParagraph(
          `Not enough evidence to suggest there is a positive correlation.`
        );

        const answer4 = new MCQuestion.MultipleChoiceAnswer();
        answer4.addParagraph(`Not enough evidence to suggest any correlation.`);

        question.addAnswer(answer1, true);
        question.addAnswer(answer2, false);
        question.addAnswer(answer3, false);
        question.addAnswer(answer4, false);
      }
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Regression_MeasuringCorrelation_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const dataX = [];
    const dataY = [];
    let Xmark = 0;
    const Ymark = MCRandom.randomInt(400, 600);

    for (let i = 0; i < 8; i += 1) {
      Xmark += MCRandom.randomInt(2, 5);
      dataX.push(Xmark);
      dataY.push(Ymark - Xmark * 20 + MCRandom.randomInt(-80, 80));
    }

    const stats = new MCMaths.Stats(dataX, dataY);

    const pmcc = parseFloat((MCRandom.randomInt(80, 95) / -100).toFixed(2));
    /* END VARIABLES AND CONSTANTS */
    if (dataY[dataY.length - 1] > 20) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `An online retail company wants to investigate how the average monthly air temperature affect the sales of their heaters. The table below shows their findings.`
      );
      question.addParagraph(
        `question`,
        `They also calulate the product moment correlation coefficient, $r$ of the collected data to be $${pmcc}$`
      );
      const t = new MCQuestion.Table(
        `Average Air Temperature (Celsius)`,
        `$${dataX[0]}$`,
        `$${dataX[1]}$`,
        `$${dataX[2]}$`,
        `$${dataX[3]}$`,
        `$${dataX[4]}$`,
        `$${dataX[5]}$`,
        `$${dataX[6]}$`,
        `$${dataX[7]}$`
      );
      t.addRow(
        `Heater Sales`,
        `$${dataY[0]}$`,
        `$${dataY[1]}$`,
        `$${dataY[2]}$`,
        `$${dataY[3]}$`,
        `$${dataY[4]}$`,
        `$${dataY[5]}$`,
        `$${dataY[6]}$`,
        `$${dataY[7]}$`
      );
      question.addTable("question", t);
      question.addParagraph(
        `question`,
        `a) Plot the data in the table above on a scatter graph.`
      );
      question.addParagraph(
        `question`,
        `b) State what is measured by the product moment correlation coefficient.`
      );
      question.addParagraph(
        `question`,
        `c) Hence, interpret the value of $${pmcc}$ for the product moment correlation coefficient in context.`
      );

      question.addHeading(`solution`, `a) Plot the data on a scatter graph.`);
      question.addGraph("solution", stats.scatter(false));

      question.addHeading(
        "solution",
        `b) State what is measured by the product moment correlation coefficient.`
      );
      question.addParagraph(
        `solution`,
        `The product moment correlation coefficient is a measure of the linear correlation between two variables.`
      );

      question.addHeading(
        `solution`,
        `c) The product moment correlation coefficient is $${pmcc}$`
      );
      question.addParagraph(
        `solution`,
        `The value is negative which means there is a negative correlation between the average monthly air temperature and the number of heater sales.`
      );
      question.addParagraph(
        `solution`,
        ` Therefore as temperature increases, the number of sales decreases.`
      );
      question.addParagraph(
        `solution`,
        `Also, as the pmcc is close to $-1$, it means the negative correlation is strong.`
      );
      question.addParagraph(
        `solution`,
        `Therefore all measured values lie close to a straight line.`
      );

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Regression_MeasuringCorrelation_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const R = MCRandom.randomInt(1, 4);

    const dataX = [];
    const dataY = [];

    const falseX = MCRandom.randomInt(2, 9);

    for (let i = 2; i < 10; i += 1) {
      dataX.push(i);
      if (i === falseX) {
        dataY.push(parseFloat((MCRandom.randomInt(120, 200) / 10).toFixed(1)));
      } else {
        dataY.push(
          parseFloat((i / R + MCRandom.randomInt(-20, 20) / 100).toFixed(1))
        );
      }
    }

    const stats = new MCMaths.Stats(dataX, dataY);

    const pmcc = parseFloat((MCRandom.randomInt(85, 95) / 100).toFixed(2));

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A group of physics students are investigating the relationship between the potential difference, $V$ of an electrical circuit and the current, $A$ running through it. Their results are shown in the table below.`
      );

      const t = new MCQuestion.Table(
        `Potential Difference (V)`,
        `$${dataX[0]}$`,
        `$${dataX[1]}$`,
        `$${dataX[2]}$`,
        `$${dataX[3]}$`,
        `$${dataX[4]}$`,
        `$${dataX[5]}$`,
        `$${dataX[6]}$`,
        `$${dataX[7]}$`
      );
      t.addRow(
        `Current (A)`,
        `$${dataY[0]}$`,
        `$${dataY[1]}$`,
        `$${dataY[2]}$`,
        `$${dataY[3]}$`,
        `$${dataY[4]}$`,
        `$${dataY[5]}$`,
        `$${dataY[6]}$`,
        `$${dataY[7]}$`
      );
      question.addTable("question", t);

      question.addParagraph(
        `question`,
        `a) Identify the outlier in the table above and suggest a reason for the incorrect value.`
      );

      question.addParagraph(
        `question`,
        `Ignoring the outlier, the students calulate the product moment correlation coefficient, $r$ of the data to be $${pmcc}$`
      );

      question.addParagraph(
        `question`,
        `b) Using the product moment correlation coefficient calculated by the students, comment on the correlation of the data.`
      );
      question.addParagraph(
        `question`,
        `c) Hence, comment on the suitability of a linear regression model for this relationship.`
      );

      //
      question.addHeading(
        `solution`,
        `a) The outlier occurs when the potential difference is $${falseX}$, and the current is $${
          dataY[falseX - 2]
        }$`
      );
      question.addParagraph(`solution`, `This could be due to:`);
      question.addParagraph(`solution`, `A data entry error,`);
      question.addParagraph(`solution`, `An error with the equipment,`);
      question.addParagraph(`solution`, `or any other reasonable alternative.`);

      question.addHeading(
        `solution`,
        `b) Comment on the correlation of the data using the calculated product moment correlation coefficient of $${pmcc}$`
      );
      question.addParagraph(
        `solution`,
        `The pmcc is positive and close to $1$`
      );
      question.addParagraph(
        `solution`,
        `This means that there must be a strong positive correlation between potential difference and current.`
      );

      question.addHeading(
        `solution`,
        `c) Comment on the suitability of a linear regression model.`
      );
      question.addParagraph(
        `solution`,
        `The strong correlation means that data points lie close to a straight line.`
      );
      question.addParagraph(
        `solution`,
        `Therefore a linear regression model is suitable for this data.`
      );

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Regression_MeasuringCorrelation_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p = MCMisc.getName();
    const dataX = [];
    const dataY = [];
    const Ymark = 32;

    for (let i = 28; i < 37; i += 1) {
      dataX.push(i);
      dataY.push(Ymark - (36 - i) + MCRandom.randomInt(-3, 3));
    }

    const stats = new MCMaths.Stats(dataX, dataY);

    const a = parseFloat(stats.a().toFixed(1));
    const b = parseFloat(stats.b().toFixed(2));

    const pressure = MCRandom.randomInt(270, 370) / 10;
    const b_ans = b * pressure + a;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `${p.name} decides to investigate how the fuel economy of ${p.hisher} car, $F$ in MPG changes with ${p.hisher} tire pressure, $P$ in PSI. ${p.hisher} results are shown in the table below.`
      );
      const t = new MCQuestion.Table(
        `Tire Pressure (PSI)`,
        `$${dataX[0]}$`,
        `$${dataX[1]}$`,
        `$${dataX[2]}$`,
        `$${dataX[3]}$`,
        `$${dataX[4]}$`,
        `$${dataX[5]}$`,
        `$${dataX[6]}$`,
        `$${dataX[7]}$`,
        `$${dataX[8]}$`
      );
      t.addRow(
        `Fuel Economy (MPG)`,
        `$${dataY[0]}$`,
        `$${dataY[1]}$`,
        `$${dataY[2]}$`,
        `$${dataY[3]}$`,
        `$${dataY[4]}$`,
        `$${dataY[5]}$`,
        `$${dataY[6]}$`,
        `$${dataY[7]}$`,
        `$${dataY[8]}$`
      );
      question.addTable("question", t);

      question.addParagraph(
        `question`,
        MCMaths.cleaner(
          `The regression line of $F$ on $P$ has equation $F = ${b}P  +${a}$`,
          false
        )
      );
      question.addParagraph(
        `question`,
        `a) Plot the results and the regression line on a scatter graph.`
      );
      question.addParagraph(
        "question",
        `b) Using the regression equation, estimate the fuel economy of a car which has a tire pressure of $${pressure}$ PSI.`
      );
      question.addParagraph(
        `question`,
        `c) Suggest a suitable value for the product moment correlation correlation coefficient of this data and explain your suggestion.`
      );

      question.addHeading(
        `solution`,
        `a) Plot the results and the regression line on a scatter graph.`
      );
      question.addGraph("solution", stats.scatter(true));

      question.addHeading(
        "solution",
        MCMaths.cleaner(`b) $F = ${b}P  +${a}$`, false)
      );
      question.addHeading(
        `solution`,
        MCMaths.cleaner(`Let $P= ${pressure}$`, false)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$F = ${b}(${pressure})  +${a}$`, false)
      );
      if (parseFloat(b_ans.toFixed(4)) !== parseFloat(b_ans.toFixed(2))) {
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$ = ${b_ans.toFixed(4)}$`, false)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$\\approx ${b_ans.toFixed(2)}$`, false)
        );
      } else {
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$ = ${b_ans.toFixed(2)}$`, false)
        );
      }

      question.addHeading(
        `solution`,
        `c) Any answer such that $0.6 \\leq r \\leq 0.95$`
      );
      question.addParagraph(
        `solution`,
        `As there is a strong positive correlation as the gradient of the regression line is positive and all points lie close to the regression line.`
      );

      const g = new MCQuestion.InputGroup(1);
      g.addInput(b_ans, 1, `b)`);
      g.addInput(1.55 / 2, 0.95 - 1.55 / 2, `c)`);
      question.addInputGroup(g);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Regression_MeasuringCorrelation_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p = MCMisc.getName();

    const coeff = MCRandom.randomInt(4, 15);
    const base = MCRandom.randomInt(14, 18) / 10;

    function getVal(x) {
      return coeff * base ** x + 10;
    }
    const dataX = [];
    const dataY = [];
    const dataLog = [];

    for (let i = 0; i < 6; i += 1) {
      dataX.push(i);
      dataY.push(Math.round(getVal(i)));
      dataLog.push(parseFloat(Math.log10(dataY[i]).toFixed(2)));
    }

    const stats = new MCMaths.Stats(dataX, dataY);
    const logStats = new MCMaths.Stats(dataX, dataLog);

    const pmcc = parseFloat((MCRandom.randomInt(80, 95) / 100).toFixed(2));
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `${p.name} records the number of sea monkeys in ${p.hisher} tank over the course of $6$ weeks. ${p.HisHer} results are tabulated below.`
      );
      const t = new MCQuestion.Table(
        `Week Number ($W$)`,
        `$${dataX[0]}$`,
        `$${dataX[1]}$`,
        `$${dataX[2]}$`,
        `$${dataX[3]}$`,
        `$${dataX[4]}$`,
        `$${dataX[5]}$`
      );
      t.addRow(
        `Population Size ($P$)`,
        `$${dataY[0]}$`,
        `$${dataY[1]}$`,
        `$${dataY[2]}$`,
        `$${dataY[3]}$`,
        `$${dataY[4]}$`,
        `$${dataY[5]}$`
      );
      t.addRow(
        `log $P$`,
        `$${dataLog[0]}$`,
        `$${dataLog[1]}$`,
        `$$`,
        `$${dataLog[3]}$`,
        `$${dataLog[4]}$`,
        `$$`
      );
      question.addTable("question", t);
      question.addParagraph(
        "question",
        `a) Copy and complete the table above.`
      );
      question.addParagraph(
        "question",
        `b) Draw the graph of $\\log{P}$ against $W$.`
      );
      question.addParagraph(
        "question",
        `The value of the product moment correlation coefficient for the graph of $W$ against $\\log{P}$ is $${pmcc}$.`
      );
      question.addParagraph(
        "question",
        `c) Hence, state whether an exponential model is suitable to model the population of sea monkeys in this case.`
      );

      question.addHeading("solution", `a) Copy and complete the table.`);
      const t2 = new MCQuestion.Table(
        `Week Number ($W$)`,
        `$${dataX[0]}$`,
        `$${dataX[1]}$`,
        `$${dataX[2]}$`,
        `$${dataX[3]}$`,
        `$${dataX[4]}$`,
        `$${dataX[5]}$`
      );
      t2.addRow(
        `Population Size ($P$)`,
        `$${dataY[0]}$`,
        `$${dataY[1]}$`,
        `$${dataY[2]}$`,
        `$${dataY[3]}$`,
        `$${dataY[4]}$`,
        `$${dataY[5]}$`
      );
      t2.addRow(
        `log $P$`,
        `$${dataLog[0]}$`,
        `$${dataLog[1]}$`,
        `$${dataLog[2]}$`,
        `$${dataLog[3]}$`,
        `$${dataLog[4]}$`,
        `$${dataLog[5]}$`
      );
      question.addTable("solution", t2);

      question.addHeading(
        "solution",
        `b) Draw the graph of $\\log{P}$ against $W$.`
      );
      question.addGraph("solution", logStats.scatter());

      question.addHeading(
        "solution",
        `c) Comment on the suitability of an exponential model.`
      );
      question.addParagraph(
        "solution",
        `The pmcc is close to $1$ for $\\log{P}$ and $W$ which means there's a strong positive correlation between them.`
      );
      question.addParagraph(
        "solution",
        `This means an exponential model of growth would be suitable for the variables $P$ and $W$.`
      );
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Regression_MeasuringCorrelation_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const posX = [];
    const posY = [];
    const posPMCC = MCRandom.randomInt(75, 95) / 100;

    const negX = [];
    const negY = [];
    const negPMCC = MCRandom.randomInt(75, 95) / -100;
    const start = 15;

    const noX = [];
    const noY = [];
    const noPMCC = MCRandom.randomInt(-25, 25) / 100;

    for (let i = 0; i < 20; i += 1) {
      posX.push(i);
      posY.push(i + MCRandom.randomInt(-2, 2));

      negX.push(i);
      negY.push(15 - i + MCRandom.randomInt(-2, 2));

      noX.push(MCRandom.randomInt(0, 15));
      noY.push(MCRandom.randomInt(0, 15));
    }

    const pos = new MCMaths.Stats(posX, posY);
    const neg = new MCMaths.Stats(negX, negY);
    const no = new MCMaths.Stats(noX, noY);

    const index = MCRandom.randomInt(0, 2);

    const stats = [pos, neg, no];

    const outofrangePMCC = [
      MCRandom.randomInt(-150, -110) / 100,
      MCRandom.randomInt(110, 150) / 100,
    ][MCRandom.randomInt(0, 1)];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addGraph(stats[index].scatter());
      question.addParagraph(
        "Select the value below that is the most likely pmcc for the graph above."
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$${posPMCC}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$${negPMCC}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$${noPMCC}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${outofrangePMCC}$`);

      question.addAnswer(a1, index === 0);
      question.addAnswer(a2, index === 1);
      question.addAnswer(a3, index === 2);
      question.addAnswer(a4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Regression_MeasuringCorrelation_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const posX = [];
    const posY = [];
    const posPMCC = MCRandom.randomInt(75, 95) / 100;

    const negX = [];
    const negY = [];
    const negPMCC = MCRandom.randomInt(75, 95) / -100;
    const start = 15;

    const noX = [];
    const noY = [];
    const noPMCC = MCRandom.randomInt(-10, 10) / 100;

    for (let i = 0; i < 20; i += 1) {
      posX.push(i);
      posY.push(i + MCRandom.randomInt(-2, 2));

      negX.push(i);
      negY.push(15 - i + MCRandom.randomInt(-2, 2));

      noX.push(MCRandom.randomInt(0, 15));
      noY.push(MCRandom.randomInt(0, 15));
    }

    const pos = new MCMaths.Stats(posX, posY);
    const neg = new MCMaths.Stats(negX, negY);
    const no = new MCMaths.Stats(noX, noY);

    const index = MCRandom.randomInt(0, 2);
    const pmcc = [posPMCC, negPMCC, noPMCC][index];

    const stats = [pos, neg, no];

    const outofrangePMCC = [
      MCRandom.randomInt(-150, -110) / 100,
      MCRandom.randomInt(110, 150) / 100,
    ][MCRandom.randomInt(0, 1)];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(3);
      question.addParagraph(
        `Select the graph below most likely to have a pmcc of $${pmcc}$`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      // a1.addParagraph("positive")
      a1.addGraph(stats[0].scatter());

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      // a2.addParagraph("negative")
      a2.addGraph(stats[1].scatter());

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      // a3.addParagraph("no")
      a3.addGraph(stats[2].scatter());

      question.addAnswer(a1, index === 0);
      question.addAnswer(a2, index === 1);
      question.addAnswer(a3, index === 2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q1() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given a and d, find sums and different terms
    const a = MCRandom.randomInt(-20, 20);
    const d = MCRandom.randomInt(-10, 10);
    const sum_n = MCRandom.randomInt(15, 30);
    const term_n = MCRandom.randomInt(3, 12);
    const lower_limit = MCRandom.randomInt(4, sum_n - 5);

    const series = new MCMaths.ArithmeticSequence(a, d);
    const a_ans = series.term(term_n);
    const b_ans = series.sum(sum_n);
    const c_ans = series.sumBetween(lower_limit, sum_n);
    /* END VARIABLES AND CONSTANTS */
    if (d !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Forming the question
      question.addParagraph(
        "question",
        "An arithmetic series has first term $a$ and common difference $d$."
      );
      question.addParagraph("question", `Given $a=${a}$ and $d=${d}$:`);
      question.addParagraph("question", `a) Find $u_{${term_n}}$`);
      question.addParagraph(
        "question",
        `b) Find $\\displaystyle\\sum_{n=1}^{${sum_n}} u_{n}$`
      );
      question.addParagraph(
        "question",
        `c) Hence find $\\displaystyle\\sum_{n=${lower_limit}}^{${sum_n}} u_{n}$`
      );
      // Part a solution walkthrough
      question.addHeading("solution", `a) Find $u_{${term_n}}$`);
      question.addParagraph("solution", "$u_n=a+(n-1)d$:");
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$u_{${term_n}}=${series.nthTerm().replace("n", term_n)}$`
        )
      );
      question.addParagraph("solution", `$\\therefore u_{${term_n}}=${a_ans}$`);
      // Part b solution walkthrough
      question.addHeading(
        "solution",
        `b) Find $\\displaystyle\\sum_{n=1}^{${sum_n}} u_{n}$`
      );
      question.addMultipleParagraphs(
        "solution",
        series.sumWorking(sum_n).slice(1, 3)
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ ${series.sumWorking(sum_n)[3]}`
      );
      // Part c solution walkthrough
      question.addHeading(
        "solution",
        `c) Hence find $\\displaystyle\\sum_{n=${lower_limit}}^{${sum_n}} u_{n}$`
      );
      question.addMultipleParagraphs(
        "solution",
        series.sumBetweenWorking(lower_limit, sum_n).slice(1, 2)
      );
      question.addMultipleParagraphs(
        "solution",
        series.sumBetweenWorking(lower_limit, sum_n).slice(6, 9)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore$ ${series.sumBetweenWorking(lower_limit, sum_n)[9]}`
        )
      );
      question.addMultipleParagraphs(
        "solution",
        series.sumBetweenWorking(lower_limit, sum_n).slice(10, 12)
      );
      // Adding answer boxes
      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(a_ans, 0, "a)");
      g1.addInput(b_ans, 0, "b)");
      g1.addInput(c_ans, 0, "c)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q10() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // Given d,Sum of first and last x,y.find number of terms
    const a = MCRandom.randomInt(-20, 20);
    const d = MCRandom.randomInt(-10, 10);
    const x = MCRandom.randomInt(50, 80);
    const first_sum = MCRandom.randomInt(8, 20);
    const last_sum = MCRandom.randomInt(8, 20);

    const series = new MCMaths.ArithmeticSequence(a, d);
    /* END VARIABLES AND CONSTANTS */
    if (d !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();
      // Formulating question
      question.addParagraph(
        "question",
        "An arithmetic series has first term $a$ and common difference $d$."
      );
      question.addParagraph(
        "question",
        `The sum of the first $${first_sum}$ terms is $${series.sum(
          first_sum
        )}$ and the sum of the last $${last_sum}$ terms is $${series.sumBetween(
          x - last_sum + 1,
          x
        )}$`
      );
      question.addParagraph("question", `Given $d=${d}$`);
      question.addParagraph(
        "question",
        `Find $x$, the number of terms in the series`
      );
      // answer walkthrough
      question.addParagraph("solution", "$S_n=\\frac{1}{2}n[2a+(n-1)d]$");
      question.addParagraph(
        "solution",
        `$S_{${first_sum}}=\\frac{${first_sum}}{2}[2a+(${first_sum}-1)(${d})]$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{${first_sum}}{2}[2a+(${first_sum}-1)(${d})]=${series.sum(
          first_sum
        )}$`
      );
      question.addParagraph("solution", `$\\therefore a=${a}$`);
      question.addHeading(
        "solution",
        `$\\displaystyle\\sum_{n=x-${
          last_sum - 1
        }}^{x}u_n=S_x-S_{x-${last_sum}}$`
      );
      question.addParagraph(
        "solution",
        `$S_x-S_{x-${last_sum}}=${series.sumBetween(x - last_sum + 1, x)}$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{x}{2}[2(${a})+(x-1)(${d})]-\\frac{x-${last_sum}}{2}[2(${a})+(x-${
          last_sum + 1
        })(${d})]=${series.sumBetween(x - last_sum + 1, x)}$`
      );
      question.addParagraph("solution", `$\\therefore x=${x}$`);
      // Answer boxes

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(x, 0, "$x$=");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q11() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // sum of all multiples
    const multiple = MCRandom.randomInt(3, 12);
    const limit = MCRandom.randomInt(5, 10) * 100;

    const integers_series = new MCMaths.ArithmeticSequence(1, 1);
    const series = new MCMaths.ArithmeticSequence(multiple, multiple);
    const n = (limit - (limit % multiple)) / multiple;
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Formulating question
      question.addParagraph(
        "question",
        `Find the sum of all integers between $1$ and $${limit}$ that are not divisble by $${multiple}$`
      );
      // Walkthrough
      question.addHeading(
        "solution",
        `Sum of all integers between $1$ and $${limit}$`
      );
      question.addParagraph(
        "solution",
        "Integers form an arithmetic series, first term 1 and common difference 1"
      );
      question.addParagraph("solution", "$S_n=\\frac{1}{2}n[2a+(n-1)d]$");
      question.addParagraph(
        "solution",
        `$S_{${limit}}=\\frac{${limit}}{2}[2(1) + (${limit}-1)(1)]$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore S_{${limit}}=${integers_series.sum(limit)}$`
      );
      question.addHeading(
        "solution",
        `Sum of all multiples of $${multiple}$ between $1$ and $${limit}$`
      );
      question.addParagraph(
        "solution",
        `Forms arithmetic series, first term $${multiple}$ and common difference $${multiple}$`
      );
      question.addParagraph(
        "solution",
        `Last multiple of $${multiple}$ in the series is $${
          limit - (limit % multiple)
        }$`
      );
      question.addParagraph("solution", `$u_n=a+(n-1)d$`);
      question.addParagraph(
        "solution",
        `$${multiple}+(n-1)(${multiple})=${limit - (limit % multiple)}$`
      );
      question.addParagraph("solution", `$\\therefore n=${n}$`);
      question.addParagraph("solution", `$S_n=\\frac{1}{2}n[2a+(n-1)d]$`);
      question.addParagraph(
        "solution",
        `$S_{${n}}=\\frac{${n}}{2}[2(${multiple})+(${n}-1)(${multiple})]$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore S_{${n}}=${series.sum(n)}$`
      );
      question.addHeading(
        "solution",
        `Sum of all integers between $1$ and $${limit}$ that are not divisble by $${multiple}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${integers_series.sum(limit)}-${series.sum(n)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${integers_series.sum(limit) - series.sum(n)}$`)
      );
      // Answer box

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(integers_series.sum(limit) - series.sum(n), 0, "sum=");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q12() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a1 = MCRandom.randomInt(-20, 20);
    const d1 = MCRandom.randomInt(2, 12);

    const below = MCRandom.randomInt(10, 20) * 10;
    const denominator = MCRandom.randomInt(3, 7);

    const series = new MCMaths.ArithmeticSequence(a1, d1);

    const a_ans = (below + d1 - a1 + ((d1 - (below + d1 - a1)) % d1)) / d1;
    const b_ans = series.term(denominator * a_ans);

    /* END VARIABLES AND CONSTANTS */
    if (a1 - d1 !== 0 && (d1 - (below + d1 - a1)) % d1 !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Forming question
      question.addParagraph(
        "question",
        MCMaths.cleaner(`A series has $n$th term $u_n=${d1}n+${a1 - d1}$`)
      );
      question.addParagraph(
        "question",
        `a) Find the number of terms in the series that are less than $${below}$`
      );
      question.addParagraph(
        "question",
        `Given that $\\frac{1}{${denominator}}$ of terms in the series are less than $${below}$`
      );
      question.addParagraph("question", `b) Find the last term in the series`);
      // Walkthrough part a
      question.addHeading(
        "solution",
        `a) Find the number of terms in then series that are less than $${below}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_1=${d1}(1)+${a1 - d1}$`)
      );
      question.addParagraph("solution", `$\\therefore a=${a1}$`);
      question.addHeading("solution", `Given $d=${d1}$`);
      question.addParagraph("solution", `$u_n=a + (n-1)d$`);
      question.addParagraph("solution", `$u_n=${a1} + (n-1)(${d1})$`);
      question.addParagraph("solution", `$${a1} + (n-1)(${d1})<${below}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$n<${(below - a1) / d1 + 1}$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore ${a_ans}$ terms less than $${below}$`
      );
      // Walkthrough part b
      question.addHeading("solution", `b) Find the last term in the series`);
      question.addParagraph(
        "solution",
        `$${denominator}\\times${a_ans}=${denominator * a_ans}$`
      );
      question.addParagraph(
        "solution",
        `$u_{${denominator * a_ans}}=${a1}+(${denominator * a_ans}-1)(${d1})$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore u_{${denominator * a_ans}}=${b_ans}$`
      );
      // Answer boxes

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(a_ans, 0, "a)");
      g1.addInput(b_ans, 0, "b)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q13() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given a,d. Find Un, Sn, sum between n
    const a = MCRandom.randomInt(5, 20);
    const d = MCRandom.randomInt(10, 20);
    const person = MCMisc.getName();
    const start_year = MCRandom.randomInt(2001, 2014);
    const years_total = MCRandom.randomInt(2, 5);
    const n = MCRandom.randomInt(4, 20);
    const year = MCRandom.randomInt(2, years_total);

    const series = new MCMaths.ArithmeticSequence(a, d);

    const a_ans = series.term(n);
    const b_ans = series.sumBetween((year - 1) * 12 + 1, year * 12);
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Formulating question
      question.addParagraph(
        "question",
        `${person.name} started saving money in January of $${start_year}$ in order to buy ${person.himher}self a car. ${person.HeShe} saved $£${a}$ in the first month and increased the amount ${person.heshe} saved by $£${d}$ in every subsequent month for $${years_total}$ years.`
      );
      question.addParagraph(
        "question",
        `a) Find how much ${person.heshe} saved in the $${n}$th month`
      );
      question.addParagraph(
        "question",
        `b) Find how much ${person.heshe} saved in year $${year}$`
      );
      // Part a walkthrough
      question.addHeading(
        "solution",
        `a) Find how much ${person.heshe} saved in the $${n}$th month`
      );
      question.addParagraph("solution", `$u_n=a+(n-1)d$`);
      question.addHeading("solution", `Given $a=${a}$ and $d=${d}$`);
      question.addParagraph("solution", `$u_{${n}}=${a}+(${n}-1)(${d})$`);
      question.addParagraph("solution", `$u_{${n}}=${a_ans}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ ${person.HeShe} saved $£${a_ans}$ in the $${n}$th month`
      );
      // Part b walkthrough
      question.addHeading(
        "solution",
        `b) Find how much ${person.heshe} saved in year $${year}$`
      );
      question.addMultipleParagraphs(
        "solution",
        series.sumBetweenWorking((year - 1) * 12 + 1, year * 12)
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ ${person.HeShe} saved $£${b_ans}$ in year $${year}$`
      );
      // Answer boxes

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(a_ans, 0, "a) $\\ £$");
      g1.addInput(b_ans, 0, "b) $\\ £$");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q14() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given Sn, n, Un. Find a and d
    const a = MCRandom.randomInt(10, 20) * 10;
    const d = MCRandom.randomInt(10, 20) * 10;
    const person = MCMisc.getName();
    const years_total = MCRandom.randomInt(5, 10);
    const n = MCRandom.randomInt(4, years_total);

    const series = new MCMaths.ArithmeticSequence(a, d);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Forming question
      question.addParagraph(
        "question",
        `${person.name} has taken part in a charity fun run every year for the past $${years_total}$ years. ${person.HeShe} raised $£a$ in ${person.hisher} first year and $£d$ more than the previous year for every following year.`
      );
      question.addParagraph(
        "question",
        `Given ${person.heshe} raised $£${series.term(n)}$ in ${
          person.hisher
        } $${n}$th year and $£${series.sum(years_total)}$ total `
      );
      question.addParagraph("question", `Find $a$ and $d$`);
      // Walkthrough
      question.addParagraph("solution", "$u_n=a+(n-1)d$:");
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given $u_{${n}}=${series.term(n)}$`)
      );
      question.addParagraph("solution", `$a+(${n}-1)d=${series.term(n)}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore d=${series.term(n) / (n - 1)}-\\frac{a}{${n - 1}}$`
        )
      );
      question.addParagraph("solution", "$S_{n}=\\frac{1}{2}n[2a+(n-1)d]$");
      question.addHeading(
        "solution",
        `Given $\\displaystyle\\sum^{${years_total}}_{n=1}u_n=${series.sum(
          years_total
        )}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{${years_total}}{2}[2a+(${years_total}-1)d]$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore${years_total}a+${
            (years_total * (years_total - 1)) / 2
          }d=${series.sum(years_total)}$`
        )
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Substituting $d=${series.term(n) / (n - 1)}-\\frac{a}{${n - 1}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${years_total}a+${
            (years_total * (years_total - 1)) / 2
          }d=${series.sum(years_total)}$`
        ).replace(
          "d",
          MCMaths.cleaner(`(${series.term(n) / (n - 1)}-\\frac{a}{${n - 1}})`)
        )
      );
      question.addParagraph("solution", `$\\therefore a=${a}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$d=${series.term(n) / (n - 1)}-\\frac{(${a})}{${n - 1}}$`
        )
      );
      question.addParagraph("solution", `$\\therefore d=${d}$`);
      // Answer boxes

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(a, 0, "$a$=");
      g1.addInput(d, 0, "$d$=");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q15() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given sums, find n, a, d
    const a = MCRandom.randomInt(10, 20);
    const d = MCRandom.randomInt(5, 10);
    const n = MCRandom.randomInt(35, 45);
    const interval = MCRandom.randomInt(5, 15);

    const series = new MCMaths.ArithmeticSequence(a, d);
    /* END VARIABLES AND CONSTANTS */
    if (n % 2 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Forming q
      question.addParagraph(
        "question",
        `A semi-professional football team grows more and more popular throughout the course of their season. The number of fans they gain each week forms an arithmetic sequence.`
      );
      question.addParagraph(
        "question",
        `Given they gain $${series.sum(
          interval
        )}$ fans in the first $${interval}$ weeks, $${series.sumBetween(
          interval + 1,
          2 * interval
        )}$ fans in the $${interval}$ weeks after that and $${series.sum(
          n
        )}$ total over the entire season.`
      );
      question.addParagraph(
        "question",
        `Find $w$, the number of weeks in the season.`
      );
      // Walkthrough
      question.addHeading(
        "solution",
        `Given $\\displaystyle\\sum_{n=1}^{${interval}}u_n=${series.sum(
          interval
        )}$ and $\\displaystyle\\sum_{n=${interval + 1}}^{${
          2 * interval
        }}u_n=${series.sumBetween(interval + 1, 2 * interval)}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=${interval + 1}}^{${2 * interval}}u_n=S_{${
          2 * interval
        }}-S_{${interval + 1}-1}$`
      );
      question.addParagraph(
        "solution",
        `$S_{${2 * interval}} = \\displaystyle\\sum_{n=${interval + 1}}^{${
          2 * interval
        }}u_n + S_{${interval}}$`
      );
      question.addParagraph(
        "solution",
        `$=${series.sumBetween(interval + 1, 2 * interval)}+${series.sum(
          interval
        )}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore S_{${2 * interval}}= ${series.sum(2 * interval)}$`
      );
      question.addParagraph("solution", `$S_n=\\frac{1}{2}n[2a+(n-1)d]$`);
      question.addHeading(
        "solution",
        `Using $\\displaystyle\\sum^{${2 * interval}}_{n=1}u_n= ${series.sum(
          2 * interval
        )}$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{${2 * interval}}{2}[2a+(${2 * interval}-1)d]=${series.sum(
          2 * interval
        )}$`
      );
      question.addParagraph(
        "solution",
        `$${2 * interval}a+${
          (2 * interval * (2 * interval - 1)) / 2
        }d=${series.sum(2 * interval)}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore a=${series.sum(2 * interval) / (2 * interval)}-${
            (2 * interval - 1) / 2
          }d$`
        )
      );
      question.addHeading(
        "solution",
        `Using $\\displaystyle\\sum_{n=1}^{${interval}}u_n=${series.sum(
          interval
        )}$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{${interval}}{2}[2a+(${interval}-1)d]=${series.sum(interval)}$`
      );
      question.addParagraph(
        "solution",
        `$${interval}a+${(interval * (interval - 1)) / 2}d=${series.sum(
          interval
        )}$`
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Substituting $a=${series.sum(2 * interval) / (2 * interval)}-${
            (2 * interval - 1) / 2
          }d$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${`${interval}(${series.sum(2 * interval) / (2 * interval)}-${
            (2 * interval - 1) / 2
          }d)`}+${(interval * (interval - 1)) / 2}d=${series.sum(interval)}$`
        )
      );
      question.addParagraph("solution", `$\\therefore d=${d}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$a=${series.sum(2 * interval) / (2 * interval)}-${
            (2 * interval - 1) / 2
          }(${d})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore a=${a}$`)
      );
      question.addHeading(
        "solution",
        `Given $\\displaystyle\\sum_{n=1}^{w}u_n=${series.sum(n)}$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{w}{2}[2(${a})+(w-1)(${d})]=${series.sum(n)}$`
      );
      question.addParagraph("solution", `$\\therefore w=${n}$`);
      // Answers box

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(n, 0, "$w$=");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q16() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given a, d some S_x
    const a = MCRandom.randomInt(100, 200) * 10;
    const d = MCRandom.randomInt(50, 100) * 10;
    const goal = MCRandom.randomInt(50, 150) * 5000;

    const term_n = MCRandom.randomInt(8, 20);

    const series = new MCMaths.ArithmeticSequence(a, d);
    const polynomial = new MCMaths.Polynomial([d / 2, a - d / 2, -goal], "n");
    const roots = polynomial.roots();
    const b_ans = Math.ceil(roots[0]);
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        `A countrywide campaign aims to get people planting trees in their local areas, with an intial goal of planting $${goal}$ trees.`
      );
      question.addParagraph(
        "question",
        `Due to intial promotions $${a}$ trees are planted in the first week of the campaign, $${series.term(
          2
        )}$ in week 2, $${series.term(
          3
        )}$ in week 3 and so on, forming an arithmetic sequence.`
      );
      question.addParagraph(
        "question",
        `a) Find the number of trees planted in week $${term_n}$`
      );
      question.addParagraph(
        "question",
        "b) Find the week where the campaign will hit its goal"
      );
      // Walkthrough
      question.addHeading(
        "solution",
        `a) Find the number of trees planted in week $${term_n}$`
      );
      question.addHeading("solution", `Given $a=${a}$`);
      question.addParagraph("solution", `$d=${series.term(2)}-${a}$`);
      question.addParagraph("solution", `$\\therefore d=${d}$`);
      question.addParagraph("solution", `$u_n=a+(n-1)d$`);
      question.addParagraph(
        "solution",
        `$u_{${term_n}}=${a}+(${term_n}-1)(${d})$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore u_{${term_n}}=${series.term(term_n)}$`
      );
      // Part b
      question.addHeading(
        "solution",
        "b) Find the week where the campaign will hit its goal"
      );
      question.addParagraph("solution", `$S_n=\\frac{1}{2}n[2a+(n-1)d]$`);
      question.addHeading(
        "solution",
        `Consider $\\frac{1}{2}n[2(${a})+(n-1)(${d})]=${goal}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${polynomial.toString()} = 0$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore n=${roots[0].toFixed(2)}...(2dp),n\\neq${roots[1].toFixed(
          2
        )}...(2dp)$`
      );
      question.addParagraph("solution", `$n>${roots[0].toFixed(2)}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ Hit goal in week $${b_ans}$`
      );
      // Answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(series.term(term_n), 0, "a)");
      g1.addInput(b_ans, 0, "b)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q17() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given a, d find U_ and S_ but arithmetic till constant
    const a = MCRandom.randomInt(400, 600);
    const d = MCRandom.randomInt(-30, -20);

    const weeks = MCRandom.randomInt(5, 10);
    const constant = a - weeks * -d;
    const cost = MCRandom.randomInt(2, 5);

    const series = new MCMaths.ArithmeticSequence(a, d);
    const person = new MCMisc.getName();
    const b_ans =
      cost * (series.sum(weeks + 1) + constant * (52 - (weeks + 1)));
    /* END VARIABLES AND CONSTANTS */
    if (constant > 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Forming q
      question.addParagraph(
        "question",
        `${
          person.name
        } has recently opened a milkshake bar. During the opening week ${
          person.heshe
        } sells $${a}$ milkshakes and each week after sells $${-d}$ less than the previous week until ${
          person.heshe
        } is left with a consistent $${constant}$ sales per week.`
      );
      question.addParagraph(
        "question",
        `a) Find in what week sales become consistent`
      );
      question.addParagraph(
        "question",
        `b) Given each milkshake costs $£${cost}$, find how much ${person.name} makes in the opening year`
      );
      // Walkthrough a
      question.addHeading(
        "solution",
        `a) Find in what week sales become consistent`
      );
      question.addParagraph("solution", `$u_n=a+(n-1)d$`);
      question.addHeading("solution", `Given $a=${a}$ and $d=${d}$`);
      question.addParagraph("solution", `$${a}+(n-1)(${d})=${constant}$`);
      question.addParagraph("solution", `$n=${weeks + 1}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ Sales become consistent in week $${weeks + 1}$`
      );
      // Walkthrough b
      question.addHeading(
        "solution",
        `b) Given each milkshake costs $£${cost}$, find how much ${person.name} makes in the opening year`
      );
      question.addParagraph(
        "solution",
        `Total Sales = $\\displaystyle\\sum^{${
          weeks + 1
        }}_{n=1}u_n + (${constant})(52-${weeks + 1})$`
      );
      question.addParagraph("solution", `$S_n=\\frac{1}{2}n[2a+(n-1)d]$`);
      question.addParagraph(
        "solution",
        `$S_{${weeks + 1}}=\\frac{${weeks + 1}}{2}[2(${a})+(${
          weeks + 1
        }-1)(${d})]$`
      );
      question.addParagraph("solution", `$=${series.sum(weeks + 1)}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ Total Sales = $${
          series.sum(weeks + 1) + constant * (52 - (weeks + 1))
        }$`
      );
      question.addParagraph(
        "solution",
        `Total Earnings = $${cost}(${
          series.sum(weeks + 1) + constant * (52 - (weeks + 1))
        })$`
      );
      question.addParagraph("solution", `=$£${b_ans}$`);
      // Answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(weeks + 1, 0, "a)");
      g1.addInput(b_ans, 0, "b) $\\ £$");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q18() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // two different series intertwined
    const a1 = MCRandom.randomInt(300, 400) * 6; // Seconds
    const d1 = MCRandom.randomInt(-10, -2);

    const a2 = MCRandom.randomInt(375, 475) * 6; // Seconds

    const n = MCRandom.randomInt(12, 20);

    const series1 = new MCMaths.ArithmeticSequence(a1, d1);

    const person1 = new MCMisc.getName();
    const person2 = new MCMisc.getName();

    const check = series1.sum(n + 1) % (n + 1);
    const avg = series1.sum(n + 1) / (n + 1);

    const term1 = MCRandom.randomInt(4, n - 1);
    const term2 = MCRandom.randomInt(4, n - 1);
    const sum_two_terms = series1.term(term1) + series1.term(term2);
    /* END VARIABLES AND CONSTANTS */
    if (a1 < a2 && check === 0 && term1 !== term2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        `${person1.name} runs the $10,000$m and records ${person1.hisher} time at the end of his first week of training. ${person1.HeShe} repeats this for $${n}$ more weeks and finds that ${person1.heshe} is improving ${person1.hisher} time by a constant amount each week.`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Given that ${person1.heshe} had an average time of $${
            (avg - (avg % 60)) / 60
          }$ minutes $${avg % 60}$ seconds and the sum of ${
            person1.hisher
          } $${term1}$th and $${term2}$th runs was $${
            (sum_two_terms - (sum_two_terms % 60)) / 60
          }$ minutes $${sum_two_terms % 60}$ seconds`
        )
      );
      question.addParagraph(
        "question",
        `a) Find ${person1.hisher} initial time, $a$ in seconds and how many seconds ${person1.heshe} improved by each week, $d$`
      );
      question.addParagraph(
        "question",
        `${person2.name} starts training at the same time as ${
          person1.name
        } and trains for the same duration but has an initial time of $${
          (a2 - (a2 % 60)) / 60
        }$ minutes $${a2 % 60}$ seconds.`
      );
      question.addParagraph(
        "question",
        `b) Find how many seconds, $x$ ${person2.name} would need to improve by each week to equal the time set by ${person1.name} on the final week (to the nearest second).`
      );
      // Walkthrough a
      question.addHeading(
        "solution",
        `a) Find ${person1.hisher} initial time, $a$ in seconds and how many seconds ${person1.heshe} improved by each week, $d$`
      );
      question.addParagraph("solution", "$u_n=a+(n-1)d$");
      question.addHeading(
        "solution",
        `Given $u_{${term1}} + u_{${term2}}=${
          (sum_two_terms - (sum_two_terms % 60)) / 60
        }(60) + ${sum_two_terms % 60}=${sum_two_terms}$`
      );
      question.addParagraph(
        "solution",
        `$[a+(${term1}-1)d]+[a+(${term2}-1)d]=${sum_two_terms}$`
      );
      question.addParagraph(
        "solution",
        `$2a+${term1 + term2 - 2}d=${sum_two_terms}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore a=${sum_two_terms / 2}-${(term1 + term2 - 2) / 2}d$`
        )
      );
      question.addParagraph("solution", "$S_n=\\frac{1}{2}n[2a+(n-1)d]$");
      question.addHeading(
        "solution",
        `Given $\\displaystyle\\sum_{n=1}^{${n + 1}}u_n=${n + 1}[${
          (avg - (avg % 60)) / 60
        }(60)+ ${avg % 60}]=${avg * (n + 1)}$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{${n + 1}}{2}[2a+(${n + 1}-1)d]=${avg * (n + 1)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore ${n + 1}a +${((n + 1) / 2) * n}d=${avg * (n + 1)}$`
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Substituting $a=${sum_two_terms / 2}-${(term1 + term2 - 2) / 2}d$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${n + 1}(${sum_two_terms / 2}-${(term1 + term2 - 2) / 2}d) +${
            ((n + 1) / 2) * n
          }d=${avg * (n + 1)}$`
        )
      );
      question.addParagraph("solution", `$\\therefore d=${d1}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ Improves by $${-d1}$ seconds each week`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$a=${sum_two_terms / 2}-${(term1 + term2 - 2) / 2}(${d1})$`
        )
      );
      question.addParagraph("solution", `$\\therefore a=${a1}$`);
      // Walkthrough b
      question.addHeading(
        "solution",
        `b) Find how many seconds, $x$ ${person2.name} would need to improve by each week to equal the time set by ${person1.name} on the final week (to the nearest second).`
      );
      question.addParagraph("solution", "$u_n=a+(n-1)d$");
      question.addHeading(
        "solution",
        `Given $n = ${n + 1}$ and $a_{${person2.name}}=${a2}$`
      );
      question.addParagraph(
        "solution",
        `$${a2}+(${n + 1}-1)x = ${a1} +(${n + 1}-1)(${d1})$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ x \\approx ${Math.round((a1 + n * d1 - a2) / n)}$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Needs to improve by $\\approx$ $${-Math.round(
          (a1 + n * d1 - a2) / n
        )}$ seconds each week`
      );
      // Answer boxes

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(a1, 0, "a) $\\ a$=");
      g1.addInput(-d1, 0, "a)$\\ d$=");
      g1.addInput(-Math.round((a1 + n * d1 - a2) / n), 0, "b)$\\ x$=");
      question.addInputGroup(g1);
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q19() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(20, 50) * 10;
    const d = MCRandom.randomInt(5, 10) * 10;
    const term_n = MCRandom.randomInt(3, 8);
    const lower_sum = MCRandom.randomInt(3, 9);
    const upper_sum = MCRandom.randomInt(12, 18);

    const person = new MCMisc.getName();

    const series = new MCMaths.ArithmeticSequence(a, d);
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Forming Q
      question.addParagraph(
        "question",
        `${person.name} buys a new game on its release day. At the end of every month ${person.hisher} highscore is $${d}$ points higher than at the end of the previous month. In month $x$ after release, the game adds a feature that takes your highscore at the end of every month and adds it to your leaderboard points. However, it does not take into account the months that've already passed.`
      );
      question.addParagraph(
        "question",
        `Given ${person.name} had $${series.sumBetween(
          lower_sum,
          upper_sum
        )}$ leaderboard points at the end of month $${upper_sum}$ and had a highscore of $${series.term(
          term_n
        )}$ in month $${term_n}$.`
      );
      question.addParagraph(
        "question",
        `Find $x$, the month the feature was released.`
      );
      // Walkthrough
      question.addParagraph("solution", `$u_n=a+(n-1)d$`);
      question.addHeading(
        "solution",
        `Given $u_{${term_n}}=${series.term(term_n)}$, and $d=${d}$`
      );
      question.addParagraph(
        "solution",
        `$u_{${term_n}}=a+(${term_n}-1)(${d})$`
      );
      question.addParagraph("solution", `$\\therefore a=${a}$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=x}^{${upper_sum}}u_n=S_{${upper_sum}}-S_{x-1}$`
      );
      question.addHeading(
        "solution",
        `Given $\\displaystyle\\sum_{n=x}^{${upper_sum}}u_n=${series.sumBetween(
          lower_sum,
          upper_sum
        )}$`
      );
      question.addParagraph(
        "solution",
        `$S_{${upper_sum}}-S_{x-1}=${series.sumBetween(lower_sum, upper_sum)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore S_{x-1}=S_{${upper_sum}}-${series.sumBetween(
          lower_sum,
          upper_sum
        )}$`
      );
      question.addMultipleParagraphs("solution", series.sumWorking(upper_sum));
      question.addParagraph(
        "solution",
        `$\\therefore S_{x-1}=${
          series.sum(upper_sum) - series.sumBetween(lower_sum, upper_sum)
        }$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{x-1}{2}[2(${a}) + (x-2)(${d})] = ${
          series.sum(upper_sum) - series.sumBetween(lower_sum, upper_sum)
        }$`
      );
      question.addParagraph("solution", `$\\therefore x=${lower_sum}$`);
      question.addParagraph(
        "solution",
        `Feature released in month $${lower_sum}$`
      );
      // Answer Boxes

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(lower_sum, 0, "$x$=");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q2() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given a sum and a term, have to find a and d
    const a = MCRandom.randomInt(-20, 20);
    const d = MCRandom.randomInt(-10, 10);
    const sum_n = MCRandom.randomInt(15, 30);
    const term_n = MCRandom.randomInt(3, 12);

    const series = new MCMaths.ArithmeticSequence(a, d);
    /* END VARIABLES AND CONSTANTS */
    if (d !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Forming question
      question.addParagraph(
        "question",
        "An arithmetic series has first term $a$ and common difference $d$."
      );
      question.addParagraph(
        "question",
        `Given $\\displaystyle\\sum_{n=1}^{${sum_n}} u_{n}=${series.sum(
          sum_n
        )}$ and $u_{${term_n}}=${series.term(term_n)}$:`
      );
      question.addParagraph("question", "Find $a$ and $d$");
      /* END BUILDING QUESTION */
      // Part a solution
      question.addParagraph("solution", "$u_n=a+(n-1)d$:");
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given $u_{${term_n}}=${series.term(term_n)}$`)
      );
      question.addParagraph(
        "solution",
        `$a+(${term_n}-1)d=${series.term(term_n)}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore d=${series.term(term_n) / (term_n - 1)}-\\frac{a}{${
            term_n - 1
          }}$`
        )
      );
      question.addParagraph("solution", "$S_{n}=\\frac{1}{2}n[2a+(n-1)d]$");
      question.addHeading(
        "solution",
        `Given $S_{${sum_n}}=${series.sum(sum_n)}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `${"$\\frac{1}{2}n[2a+(n-1)d]$".replace(/n/g, sum_n)}$=${series.sum(
            sum_n
          )}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore ${sum_n}a+${(sum_n * (sum_n - 1)) / 2}d=${series.sum(
            sum_n
          )}$`
        )
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Substituting $d=${series.term(term_n) / (term_n - 1)}-\\frac{a}{${
            term_n - 1
          }}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${sum_n}a+${(sum_n * (sum_n - 1)) / 2}d=${series.sum(sum_n)}$`
        ).replace(
          "d",
          MCMaths.cleaner(
            `(${series.term(term_n) / (term_n - 1)}-\\frac{a}{${term_n - 1}})`
          )
        )
      );
      question.addParagraph("solution", `$\\therefore a=${a}$`);
      // Part b solution
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$d=${series.term(term_n) / (term_n - 1)}-\\frac{(${a})}{${
            term_n - 1
          }}$`
        )
      );
      question.addParagraph("solution", `$\\therefore d=${d}$`);
      // Answer boxes
      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(a, 0, "$a$=");
      g1.addInput(d, 0, "$d$=");
      question.addInputGroup(g1);
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q3() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // Given two sums, find another sum,find a and d,find n from a Un.
    const a = MCRandom.randomInt(-20, 20);
    const d = MCRandom.randomInt(-10, 10);
    const sum_n = MCRandom.randomInt(15, 30);
    const term_n = MCRandom.randomInt(3, 12);
    const lower_limit = MCRandom.randomInt(4, sum_n - 5);

    const series = new MCMaths.ArithmeticSequence(a, d);
    const a_ans = series.sum(sum_n) - series.sumBetween(lower_limit, sum_n);
    /* END VARIABLES AND CONSTANTS */
    if (d !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Forming question
      question.addParagraph(
        "question",
        "An arithmetic series has first term $a$ and common difference $d$."
      );
      question.addParagraph(
        "question",
        `Given $\\displaystyle\\sum^{${sum_n}}_{n=1}u_{n}=${series.sum(
          sum_n
        )}$ and` +
          `$\\displaystyle\\sum^{${sum_n}}_{n=${lower_limit}}u_{n}=${series.sumBetween(
            lower_limit,
            sum_n
          )}$`
      );
      question.addParagraph(
        "question",
        `a) Find $\\displaystyle\\sum^{${lower_limit - 1}}_{n=1}u_{n}$`
      );
      question.addParagraph(
        "question",
        `Given also $u_{x}=${series.term(term_n)}$`
      );
      question.addParagraph("question", "b) Find $x$");
      // Part a walkthrough
      question.addHeading(
        "solution",
        `a) Find $\\displaystyle\\sum^{${lower_limit - 1}}_{n=1}u_{n}$`
      );
      question.addParagraph(
        "solution",
        series.sumBetweenWorking(lower_limit, sum_n)[1]
      );
      question.addParagraph(
        "solution",
        `$\\therefore S_{${
          lower_limit - 1
        }}=S_{${sum_n}}-\\displaystyle\\sum^{${sum_n}}_{n=${lower_limit}}u_{n}$`
      );
      question.addParagraph(
        "solution",
        `$=(${series.sum(sum_n)})-(${series.sumBetween(lower_limit, sum_n)})$`
      );
      question.addParagraph("solution", `$=${a_ans}$`);
      // Part b walthrough
      question.addHeading("solution", "b) Find $x$");
      question.addParagraph("solution", "$S_{n}=\\frac{1}{2}n[2a+(n-1)d]$");
      question.addHeading(
        "solution",
        `Using $\\displaystyle\\sum^{${lower_limit - 1}}_{n=1}u_{n}=${a_ans}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `${"$\\frac{1}{2}n[2a+(n-1)d]$".replace(
            /n/g,
            lower_limit - 1
          )}$=${a_ans}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${lower_limit - 1}a+${
            ((lower_limit - 1) * (lower_limit - 1 - 1)) / 2
          }d=${a_ans}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore a=${a_ans / (lower_limit - 1)}-${
            (((lower_limit - 1) / 2) * (lower_limit - 1 - 1)) /
            (lower_limit - 1)
          }d$`
        )
      );
      question.addHeading(
        "solution",
        `Using $\\displaystyle\\sum^{${sum_n}}_{n=1}u_{n}=${series.sum(sum_n)}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `${"$\\frac{1}{2}n[2a+(n-1)d]$".replace(/n/g, sum_n)}$=${series.sum(
            sum_n
          )}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${sum_n}a+${(sum_n * (sum_n - 1)) / 2}d=${series.sum(sum_n)}$`
        )
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Substituting $a=${a_ans / (lower_limit - 1)}-${
            (((lower_limit - 1) / 2) * (lower_limit - 1 - 1)) /
            (lower_limit - 1)
          }d$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${sum_n}a+${(sum_n * (sum_n - 1)) / 2}d=${series.sum(sum_n)}$`
        ).replace(
          "a",
          MCMaths.cleaner(
            `(${a_ans / (lower_limit - 1)}-${
              (((lower_limit - 1) / 2) * (lower_limit - 1 - 1)) /
              (lower_limit - 1)
            }d)`
          )
        )
      );
      question.addParagraph("solution", `$\\therefore d=${d}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$a=${a_ans / (lower_limit - 1)}-${
            (((lower_limit - 1) / 2) * (lower_limit - 1 - 1)) /
            (lower_limit - 1)
          }(${d})$`
        )
      );
      question.addParagraph("solution", `$\\therefore a=${a}$`);
      question.addParagraph("solution", "$u_{n}=a + (n - 1)d$");
      question.addHeading("solution", `Given $u_{x}=${series.term(term_n)}$`);
      question.addParagraph(
        "solution",
        `$${a}+(x - 1)(${d})=${series.term(term_n)}$`
      );
      question.addParagraph("solution", `$\\therefore x=${term_n}$`);
      // Answer boxes

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(a_ans, 0, "a)");
      g1.addInput(term_n, 0, "b)$\\ x$=");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q4() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given series U1,U2,U3,...,Un has n terms, find Ux, find sum of all terms, find sum of last y terms
    const a = MCRandom.randomInt(-20, 20);
    const d = MCRandom.randomInt(-10, 10);
    const seriesLength = MCRandom.randomInt(30, 50);
    const term_n = MCRandom.randomInt(5, seriesLength - 5);
    const last = MCRandom.randomInt(10, 20);

    const series = new MCMaths.ArithmeticSequence(a, d);
    const a_ans = series.term(term_n);
    const b_ans = series.sum(seriesLength);
    const c_ans = series.sumBetween(seriesLength - last + 1, seriesLength);
    /* END VARIABLES AND CONSTANTS */
    if (d !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Formulating question
      question.addParagraph(
        "question",
        `The arithmetic sequence $${series.terms(1, 3)}, ... ,${series.term(
          seriesLength
        )}$ has $x$ terms`
      );
      question.addParagraph("question", `a) Find $u_{${term_n}}$`);
      question.addParagraph(
        "question",
        "b) Find $\\displaystyle\\sum_{n=1}^{x}u_{n}$"
      );
      question.addParagraph(
        "question",
        `c) Hence, find the sum of the last $${last}$ terms`
      );
      // Part a walkthrough
      question.addHeading("solution", `a) Find $u_{${term_n}}$`);
      question.addHeading("solution", `Given $a=${a}$`);
      question.addParagraph("solution", "$d=u_{n} - u_{n-1}$, where n > 1");
      question.addHeading("solution", "Let $n=3$");
      question.addParagraph("solution", "$d=u_{3} - u_{2}$");
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$d=(${series.term(3)}) - (${series.term(2)})$`)
      );
      question.addParagraph("solution", `$\\therefore d=${d}$`);
      question.addParagraph("solution", "$u_n=a+(n-1)d$");
      question.addParagraph(
        "solution",
        `$u_{${term_n}}=${a}+(${term_n}-1)(${d})$`
      );
      question.addParagraph("solution", `$\\therefore u_{${term_n}}=${a_ans}$`);
      // Part b walkthrough
      question.addHeading(
        "solution",
        "b) Find $\\displaystyle\\sum_{n=1}^{x}u_{n}$"
      );
      question.addParagraph("solution", "$u_n=a+(n-1)d$");
      question.addHeading(
        "solution",
        `Given $u_{x}=${series.term(seriesLength)}$`
      );
      question.addParagraph(
        "solution",
        `$${a}+(x - 1)(${d})=${series.term(seriesLength)}$`
      );
      question.addParagraph("solution", `$\\therefore x=${seriesLength}$`);
      question.addParagraph("solution", "$S_{n}=\\frac{1}{2}n[2a+(n-1)d]$");
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$S_{${seriesLength}}=\\frac{${seriesLength}}{2}[2(${a})+(${seriesLength}-1)(${d})]$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore S_{${seriesLength}}=${b_ans}$`
      );
      // Part c walkthrough
      question.addHeading(
        "solution",
        `c) Hence, find the sum of the last $${last}$ terms`
      );
      question.addMultipleParagraphs(
        "solution",
        series
          .sumBetweenWorking(seriesLength - last + 1, seriesLength)
          .slice(0, 2)
      );
      question.addMultipleParagraphs(
        "solution",
        series
          .sumBetweenWorking(seriesLength - last + 1, seriesLength)
          .slice(6, 12)
      );
      // answer boxes

      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(a_ans, 0, "a)");
      g1.addInput(b_ans, 0, "b)");
      g1.addInput(c_ans, 0, "c)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q5() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // Given some Sn, sum of Ux Uy. Find a & d,the largest possible sum
    const a = MCRandom.randomInt(40, 80);
    const d = MCRandom.randomInt(-20, -5);
    const term_x = MCRandom.randomInt(5, 20);
    const term_y = MCRandom.randomInt(5, 20);
    const sum_n = MCRandom.randomInt(10, 30);

    const series = new MCMaths.ArithmeticSequence(a, d);
    const b_ans = series.sum((a - (a % -d)) / -d + 1);
    /* END VARIABLES AND CONSTANTS */
    if (term_x !== term_y) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Formulate question
      question.addParagraph(
        "question",
        "An arithmetic sequence has first term $a$ and common difference $d$"
      );
      question.addParagraph(
        "question",
        `Given $\\displaystyle\\sum_{n=1}^{${sum_n}}u_n=${series.sum(sum_n)}$` +
          ` and $u_{${term_x}}+u_{${term_y}}=${
            series.term(term_x) + series.term(term_y)
          }$`
      );
      question.addParagraph("question", "a) Find $a$ and $d$");
      question.addParagraph(
        "question",
        "The sum of the first $n$ terms is $S_{n}$"
      );
      question.addParagraph(
        "question",
        "b) Find the largest positive value of $S_n$"
      );
      // Solution a
      question.addHeading("solution", "a) Find $a$ and $d$");
      question.addParagraph("solution", "$u_n=a+(n-1)d$");
      question.addHeading(
        "solution",
        `Given $u_{${term_x}}+u_{${term_y}}=${
          series.term(term_x) + series.term(term_y)
        }$`
      );
      question.addParagraph(
        "solution",
        `$[a+(${term_x}-1)d] + [a+(${term_y}-1)d]=${
          series.term(term_x) + series.term(term_y)
        }$`
      );
      question.addParagraph(
        "solution",
        `$2a+${term_x + term_y - 2}d=${
          series.term(term_x) + series.term(term_y)
        }$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore a=${(series.term(term_x) + series.term(term_y)) / 2}-${
            (term_x + term_y - 2) / 2
          }d$`
        )
      );
      question.addParagraph("solution", "$S_n=\\frac{1}{2}n[2a+(n-1)d]$");
      question.addHeading(
        "solution",
        `Given $\\displaystyle\\sum_{n=1}^{${sum_n}}u_n=${series.sum(sum_n)}$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{${sum_n}}{2}[2a+(${sum_n}-1)d]=${series.sum(sum_n)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore${sum_n}a+${(sum_n / 2) * (sum_n - 1)}d=${series.sum(
          sum_n
        )}$`
      );
      question.addHeading(
        "solution",
        `Substituting ${MCMaths.cleaner(
          `$a=${(series.term(term_x) + series.term(term_y)) / 2}-${
            (term_x + term_y - 2) / 2
          }d$`
        )}`
      );
      question.addParagraph(
        "solution",
        `$${sum_n}a+${(sum_n / 2) * (sum_n - 1)}d=${series.sum(
          sum_n
        )}$`.replace(
          "a",
          MCMaths.cleaner(
            `(${(series.term(term_x) + series.term(term_y)) / 2}-${
              (term_x + term_y - 2) / 2
            }d)`
          )
        )
      );
      question.addParagraph("solution", `$\\therefore d=${d}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$a=${(series.term(term_x) + series.term(term_y)) / 2}-${
            (term_x + term_y - 2) / 2
          }(${d})$`
        )
      );
      question.addParagraph("solution", `$\\therefore a=${a}$`);
      // Solution b
      question.addHeading(
        "solution",
        "b) Find the largest positive value of $S_n$"
      );
      question.addHeading("solution", "Find first negative term");
      question.addParagraph("solution", "$a + (n-1)d < 0$");
      question.addParagraph("solution", `$${a} + (n-1)(${d}) < 0$`);
      question.addParagraph("solution", MCMaths.cleaner(`$n >${-a / d + 1}$`));
      question.addParagraph(
        "solution",
        `$\\therefore u_{${(a - (a % -d)) / -d + 2}}$ first negative term`
      );
      question.addHeading(
        "solution",
        `$\\therefore$We want $\\displaystyle\\sum_{n=1}^{${
          (a - (a % -d)) / -d + 1
        }}u_n$ for maximum value of $S_n$`
      );
      question.addMultipleParagraphs(
        "solution",
        series.sumWorking((a - (a % -d)) / -d + 1).slice(1, 4)
      );
      // answer boxes

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(a, 0, "a)$\\ a=$");
      g1.addInput(d, 0, "a)$\\ d=$");
      g1.addInput(b_ans, 0, "b)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q6() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // 	Given Un+1=Un + d, Ux, Find SumXY,
    const a = MCRandom.randomInt(-20, 20);
    const d = MCRandom.randomInt(-10, 10);
    const term_n = MCRandom.randomInt(3, 8);
    const lower_sum = MCRandom.randomInt(5, 15);
    const upper_sum = MCRandom.randomInt(30, 50);

    const series = new MCMaths.ArithmeticSequence(a, d);
    /* END VARIABLES AND CONSTANTS */
    if (d !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Formulating question
      question.addParagraph(
        "question",
        MCMaths.cleaner(`A series has $n$th term $u_{n+1}=u_n+${d}$`)
      );
      question.addParagraph(
        "question",
        `Given $u_{${term_n}}=${series.term(
          term_n
        )}$ and $\\displaystyle\\sum_{n=x}^{${upper_sum}}u_n=${series.sumBetween(
          lower_sum,
          upper_sum
        )}$`
      );
      question.addParagraph("question", "Find $x$");
      // Walkthrough
      question.addHeading(
        "solution",
        `Given $u_{${term_n}}=${series.term(term_n)}$, and $d=${d}$`
      );
      question.addParagraph("solution", "$u_{n-y}=u_{n} - y(d)$");
      question.addParagraph(
        "solution",
        `$u_{${term_n}-${term_n - 1}}=u_{${term_n}}-${term_n - 1}(d)$`
      );
      question.addParagraph(
        "solution",
        `$u_1=${series.term(term_n)}-${term_n - 1}(${d})$`
      );
      question.addParagraph("solution", `$\\therefore a=${a}$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=x}^{${upper_sum}}u_n=S_{${upper_sum}}-S_{x-1}$`
      );
      question.addHeading(
        "solution",
        `Given $\\displaystyle\\sum_{n=x}^{${upper_sum}}u_n=${series.sumBetween(
          lower_sum,
          upper_sum
        )}$`
      );
      question.addParagraph(
        "solution",
        `$S_{${upper_sum}}-S_{x-1}=${series.sumBetween(lower_sum, upper_sum)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore S_{x-1}=S_{${upper_sum}}-${series.sumBetween(
          lower_sum,
          upper_sum
        )}$`
      );
      question.addMultipleParagraphs("solution", series.sumWorking(upper_sum));
      question.addParagraph(
        "solution",
        `$\\therefore S_{x-1}=${
          series.sum(upper_sum) - series.sumBetween(lower_sum, upper_sum)
        }$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{x-1}{2}[2(${a}) + (x-2)(${d})] = ${
          series.sum(upper_sum) - series.sumBetween(lower_sum, upper_sum)
        }$`
      );
      question.addParagraph("solution", `$\\therefore x=${lower_sum}$`);
      // Answer Boxes

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(lower_sum, 0, "$x$=");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q7() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // Given Un=dn - c, Sn=amount. Find n, find sum of first n odd/even terms.
    const a = MCRandom.randomInt(-20, 20);
    const d = MCRandom.randomInt(-10, 10);
    const sum_n = MCRandom.randomInt(20, 40);

    const series = new MCMaths.ArithmeticSequence(a, d);
    const odd_series = new MCMaths.ArithmeticSequence(a, 2 * d);
    const b_ans = odd_series.sum(sum_n);
    /* END VARIABLES AND CONSTANTS */
    if (d !== 0 && d % 2 !== 0 && (a - d) % 2 === 0 && a - d !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Formulating question
      question.addParagraph(
        "question",
        MCMaths.cleaner(`A series has $n$th term $u_n=${d}n+${a - d}$`)
      );
      question.addParagraph(
        "question",
        `Given $\\displaystyle\\sum_{n=1}^{x}u_n=${series.sum(sum_n)}$`
      );
      question.addParagraph("question", "a) Find $x$");
      question.addParagraph(
        "question",
        "b) Find the sum of the first $x$ odd terms in the series"
      );
      // Part a walkthrough
      question.addHeading("solution", "a) Find $x$");
      question.addParagraph("solution", `$u_1=${d}(1)+${a - d}$`);
      question.addParagraph("solution", `$\\therefore a=${a}$`);
      question.addParagraph("solution", "$S_n=\\frac{1}{2}n[2a+(n-1)d]$");
      question.addHeading(
        "solution",
        `Given $\\displaystyle\\sum_{n=1}^{x}u_n=${series.sum(
          sum_n
        )}$ and $d=${d}$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{x}{2}[2(${a})+(x-1)(${d})]=${series.sum(sum_n)}$`
      );
      question.addParagraph("solution", `$\\therefore x=${sum_n}$`);
      // Part b walkthrough
      question.addHeading(
        "solution",
        "b) Find the sum of the first $x$ odd terms in the series"
      );
      question.addParagraph(
        "solution",
        `From the original series $${series.terms(1, 5)},...$`
      );
      question.addParagraph(
        "solution",
        `The odd terms form their own arithmetic series, first term $${a}$ and common difference $${
          2 * d
        }$`
      );
      question.addParagraph("solution", "$S_n=\\frac{1}{2}n[2a+(n-1)d]$");
      question.addParagraph(
        "solution",
        `$S_{${sum_n}}=\\frac{${sum_n}}{2}[2(${a})+(${sum_n}-1)(${2 * d})]$`
      );
      question.addParagraph("solution", `$=${b_ans}$`);
      // Answer boxes

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(sum_n, 0, "a)");
      g1.addInput(b_ans, 0, "b)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q8() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given two series, find smallest value of n where sum of one series > than other
    const a1 = MCRandom.randomInt(-20, 20);
    const d1 = MCRandom.randomInt(1, 9);
    const increasing_series = new MCMaths.ArithmeticSequence(a1, d1);

    const a2 = MCRandom.randomInt(180, 220);
    const d2 = MCRandom.randomInt(-9, -1);
    const decreasing_series = new MCMaths.ArithmeticSequence(a2, d2);

    const ans =
      (2 * a2 - 2 * a1 + d1 - d2) / (d1 - d2) +
      (d1 - d2 - ((2 * a2 - 2 * a1 + d1 - d2) % (d1 - d2))) / (d1 - d2);
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Forming question
      question.addParagraph(
        "question",
        `$R_n$ denotes the sum of the first $n$ terms of the series $${increasing_series.terms(
          1,
          4
        )},...$`
      );
      question.addParagraph(
        "question",
        `$T_n$ denotes the sum of the first $n$ terms of the series $${decreasing_series.terms(
          1,
          4
        )},...$`
      );
      question.addParagraph(
        "question",
        "Find the smallest $n$ where $R_n > T_n$"
      );
      // Walkthrough
      question.addHeading(
        "solution",
        `For $${increasing_series.terms(1, 4)},...$`
      );
      question.addParagraph("solution", `$a=${a1}$, $d=${d1}$`);
      question.addHeading(
        "solution",
        `For $${decreasing_series.terms(1, 4)},...$`
      );
      question.addParagraph("solution", `$a=${a2}$, $d=${d2}$`);
      question.addParagraph("solution", "$S_n=\\frac{1}{2}n[2a+(n-1)d]$");
      question.addParagraph(
        "solution",
        `$\\therefore R_n=\\frac{1}{2}n[2(${a1})+(n-1)(${d1})]$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore T_n=\\frac{1}{2}n[2(${a2})+(n-1)(${d2})]$`
      );
      question.addHeading(
        "solution",
        "Find the smallest $n$ where $R_n > T_n$"
      );
      question.addParagraph(
        "solution",
        `$\\frac{1}{2}n[2(${a1})+(n-1)(${d1})] > \\frac{1}{2}n[2(${a2})+(n-1)(${d2})]$`
      );
      question.addParagraph(
        "solution",
        `$2(${a1})+(n-1)(${d1}) > 2(${a2})+(n-1)(${d2})$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$n > ${(2 * a2 - 2 * a1 + d1 - d2) / (d1 - d2)}$`)
      );
      question.addParagraph("solution", `$\\therefore n=${ans}$`);
      // Answer Box

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(ans, 0, "$n$=");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */

      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_Exam_Q9() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // algebraic format of question, find Un and Sn
    const x = MCRandom.randomInt(-5, 5);
    const a = MCRandom.randomInt(-20, 20);
    const d = MCRandom.randomInt(-10, 10);
    const term_n = MCRandom.randomInt(10, 30);
    const sum_n = MCRandom.randomInt(40, 60);

    const coefficient1 = MCRandom.randomInt(-5, 9);
    const coefficient2 = MCRandom.randomInt(-5, 9);
    const coefficient3 = MCRandom.randomInt(-5, 9);

    const series = new MCMaths.ArithmeticSequence(a, d);
    const term1 = new MCMaths.Polynomial(
      [coefficient1, series.term(1) - coefficient1 * x],
      "x"
    );
    const term2 = new MCMaths.Polynomial(
      [coefficient2, series.term(2) - coefficient2 * x],
      "x"
    );
    const term3 = new MCMaths.Polynomial(
      [coefficient3, series.term(3) - coefficient3 * x],
      "x"
    );
    /* END VARIABLES AND CONSTANTS */
    if (
      x !== 0 &&
      d !== 0 &&
      coefficient3 - coefficient2 !== coefficient2 - coefficient1 &&
      coefficient1 !== coefficient2 &&
      coefficient2 !== coefficient3 &&
      coefficient1 !== 0 &&
      coefficient2 !== 0 &&
      coefficient3 !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Formulating question
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `An arithmetic series $${term1.toString()},${term2.toString()},${term3.toString()},...$ has $${sum_n}$ terms`
        )
      );
      question.addParagraph("question", `a) Find $u_{${term_n}}$`);
      question.addParagraph(
        "question",
        "b) Find the sum of all terms in the series"
      );
      // Walkthrough part a
      question.addHeading("solution", `a) Find $u_{${term_n}}$`);
      question.addParagraph("solution", "$d=u_{n+1}-u_n$");
      question.addParagraph("solution", "$\\therefore u_3-u_2=u_2-u_1$");
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(${term3.toString()})-(${term2.toString()})=(${term2.toString()})-(${term1.toString()})$`
        )
      );
      question.addParagraph("solution", `$\\therefore x=${x}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$a=${term1.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$a=${term1.toString().replace("x", `(${x})`)}$`)
      );
      question.addParagraph("solution", `$\\therefore a=${a}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$d=(${term3.toString()})-(${term2.toString()})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$d=[${term3.toString()}]-[${term2.toString()}]$`.replace(
            /x/g,
            `(${x})`
          )
        )
      );
      question.addParagraph("solution", `$\\therefore d=${d}$`);
      question.addParagraph("solution", "$u_n=a+(n-1)d$");
      question.addParagraph(
        "solution",
        `$u_{${term_n}}=${a}+(${term_n}-1)(${d})$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore u_{${term_n}}=${series.term(term_n)}$`
      );
      // walkthrough part b
      question.addHeading(
        "solution",
        "b) Find the sum of all terms in the series"
      );
      question.addParagraph("solution", "$S_n=\\frac{1}{2}n[2a+(n-1)d]$");
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$S_{n}=\\frac{n}{2}[2(${a})+(n-1)(${d})]$`).replace(
          /n/g,
          sum_n
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore S_{${sum_n}}=${series.sum(sum_n)}$`
      );
      // Answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(series.term(term_n), 0, "a)");
      g1.addInput(series.sum(sum_n), 0, "b)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_MCQ_Q1() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(-10, 10);
    const d = MCRandom.randomInt(-5, 5);

    const lower = MCRandom.randomInt(5, 10);
    const upper = MCRandom.randomInt(15, 25);

    const series = new MCMaths.ArithmeticSequence(a, d);
    /* END VARIABLES AND CONSTANTS */
    if (d !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      // Form q
      question.addParagraph(
        `Given $u_{${lower}}=${series.term(
          lower
        )}$ , $\\displaystyle\\sum_{n=1}^{${upper}}u_n=${series.sum(
          upper
        )}$ and $\\displaystyle\\sum_{n=1}^{${lower}}u_n=${series.sum(lower)}$`
      );
      question.addParagraph(
        `Find $\\displaystyle\\sum_{n=${lower}}^{${upper}}u_n$`
      );
      // Answers
      // create our first answer
      const a1 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a1.addParagraph(`$${series.sum(upper) - series.sum(lower)}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a2.addParagraph(
        `$${series.sumBetween(lower, upper) - series.sum(upper)}$`
      );

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a3.addParagraph(
        `$${series.sum(upper) - series.sumBetween(lower, upper)}$`
      );

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a4.addParagraph(`$${series.sum(upper) - series.sum(lower - 1)}$`);

      // add the answers to the question
      question.addAnswer(a1, false);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, true);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_MCQ_Q2() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(-50, -30);
    const d = MCRandom.randomInt(-5, 5);

    const lower = MCRandom.randomInt(3, 7);
    const upper = lower + 2;

    const series = new MCMaths.ArithmeticSequence(a, d);
    /* END VARIABLES AND CONSTANTS */
    if (d !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      // Form q
      question.addParagraph(
        `Given $u_{${lower}}=${series.term(
          lower
        )}$ and $u_{${upper}}=${series.term(upper)}$`
      );
      question.addParagraph(`Find $d$ , the commond difference of the series.`);
      // Answers
      // create our first answer
      const a1 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a1.addParagraph(`$${2 * d}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a2.addParagraph(`$${d}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a3.addParagraph(`$${-2 * d}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a4.addParagraph(`$${-d}$`);

      // add the answers to the question
      question.addAnswer(a1, false);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_MCQ_Q3() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(-10, 10);
    const d = MCRandom.randomInt(-5, 5);

    const series = new MCMaths.ArithmeticSequence(a, d);
    /* END VARIABLES AND CONSTANTS */
    if (d !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      // Form q
      question.addParagraph(
        `Which of the following represent the arithmetic series`
      );
      question.addParagraph(`$${series.terms(1, 5)},...$`);
      question.addHeading("Choose TWO");
      // Answers
      // create our first answer
      const a1 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a1.addParagraph(MCMaths.cleaner(`$u_{n+1}=u_{n}+${d}$`));

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a2.addParagraph(MCMaths.cleaner(`$u_n=${d}n+${a}$`));

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a3.addParagraph(MCMaths.cleaner(`$u_{n}=${a - d}+${d}n$`));

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a4.addParagraph(MCMaths.cleaner(`$u_n=${a}(n-1)+${d}$`));

      // add the answers to the question
      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, true);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_ArithmeticSequences_MCQ_Q4() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const d = MCRandom.randomInt(3, 9);
    const a = MCRandom.randomInt(-10 * d, -4 * d, d);

    const series = new MCMaths.ArithmeticSequence(a, d);
    /* END VARIABLES AND CONSTANTS */
    if (d !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      // Form q
      question.addParagraph(`Which of the following are true for the series`);
      question.addParagraph(`$u_n=${a}+${d}(n-1)$`);
      question.addHeading("Choose TWO");
      // Answers
      // create our first answer
      const a1 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a1.addParagraph(MCMaths.cleaner(`$u_1=${series.term(2)}$`));

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a2.addParagraph(MCMaths.cleaner(`$u_{${-a / d + 1}}=0$`));

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a3.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle\\sum_{n=1}^{${2 * (-a / d) + 1}}u_n=0$`
        )
      );

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a4.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle\\sum_{n=1}^{${-a / d}}u_n=\\displaystyle\\sum_{n=${
            -a / d + 2
          }}^{${2 * (-a / d) + 1}}u_n$`
        )
      );

      // add the answers to the question
      question.addAnswer(a1, false);
      question.addAnswer(a2, true);
      question.addAnswer(a3, true);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_Exam_Q1() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given a,r find ,u_,s_,s_infinity
    const a = MCRandom.randomInt(-40, 40) * 4;
    const r = MCRandom.randomInt(-7, 7) / 8;

    const term_n = MCRandom.randomInt(4, 8);
    const sum_n = MCRandom.randomInt(3, 5);

    const series = new MCMaths.GeometricSequence(a, r);

    const a_ans = parseFloat((a * r ** (term_n - 1)).toFixed(2));
    const b_ans = parseFloat(
      (series.sumInfinity() - series.sum(sum_n)).toFixed(2)
    );
    /* END VARIABLES AND CONSTANTS */
    if (
      a !== 1 &&
      a !== 0 &&
      r !== 0 &&
      (r >= 1 / 2 || r <= -1 / 2) &&
      (a > 40 || a < -40)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Forming q
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A geometric series has $n$th term $u_n=${a}(${r})^{n-1}$`
        )
      );
      question.addParagraph(
        "question",
        `a) Find $u_{${term_n}}$ to 2 decimal places`
      );
      question.addParagraph(
        "question",
        `b) Find the difference between the sum to infinity and the sum of the first $${sum_n}$ terms to 2 decimal places`
      );
      // Part a walkthrough
      question.addHeading(
        "solution",
        `a) Find $u_{${term_n}}$ to 2 decimal places`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_n=${a}(${r})^{n-1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_{${term_n}}=${a}(${r})^{${term_n}-1}$`)
      );
      question.addParagraph(
        "solution",
        `$u_{${term_n}} = ${(a * r ** (term_n - 1)).toFixed(4)}...$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore u_{${term_n}} \\approx ${(a * r ** (term_n - 1)).toFixed(
          2
        )}$`
      );
      // Part b
      question.addHeading(
        "solution",
        `b) Find the difference between the sum to infinity and the sum of the first $${sum_n}$ terms to 2 decimal places`
      );
      question.addParagraph("solution", "$S_{\\infty} = \\frac{a}{1 - r}$");
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given $a=${a}$ and $r = ${r}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$S_{\\infty} = \\frac{${a}}{1 - ${r}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore S_{\\infty}=${a / (1 - r)}$`)
      );
      question.addParagraph("solution", `$S_n=\\frac{a(1-r^n)}{1-r}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$S_{${sum_n}}=\\frac{${a}(1-(${r})^{${sum_n})}}{1-${r}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore S_{${sum_n}}=${series.sum(sum_n)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$S_{\\infty} - S_{${sum_n}}=${series.sumInfinity()} - ${series.sum(
            sum_n
          )}$`
        )
      );
      question.addParagraph(
        "solution",
        `$=${(series.sumInfinity() - series.sum(sum_n)).toFixed(4)}...$`
      );
      question.addParagraph("solution", `$\\approx ${b_ans}$`);
      // Answer boxes

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(a_ans, 0.005, "a)");
      g1.addInput(b_ans, 0.005, "b)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_Exam_Q10() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given 2 x u_, find u_n and find total time spent
    const a = MCRandom.randomInt(210, 270);
    const r = MCRandom.randomInt(95, 99) / 100;

    const ppw = MCRandom.randomInt(3, 7);
    const n = MCRandom.randomInt(2, 4);
    const term = MCRandom.randomInt(5, n * ppw);

    const series = new MCMaths.GeometricSequence(a, r);
    const person = MCMisc.getName();
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `${person.name} practices exam papers leading up to ${person.hisher} final exam in $${n}$ weeks time. ${person.HeShe} gets progressively quicker at completing them, such that ${person.hisher} times form a geometric series.`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Given her $2$nd paper took $${series
            .term(2)
            .toFixed(
              2
            )}$ minutes to complete and her $4$th paper took $${series
            .term(4)
            .toFixed(2)}$ minutes.`
        )
      );
      question.addParagraph(
        `question`,
        `a) Find how long ${person.hisher} $${term}$th paper took, to the nearest minute`
      );
      question.addParagraph(
        "question",
        `b) Given ${person.heshe} completes $${ppw}$ papers per week, find how much time ${person.heshe} spends practicing in the last week leading up to the final exam, to the nearest minute`
      );
      // walkthrough a
      question.addHeading(
        `solution`,
        `a) Find how long ${person.hisher} $${term}$th paper took, to the nearest minute`
      );
      question.addParagraph("solution", `$u_n=ar^{n-1}$`);
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Given $u_{${2}}=${series
            .term(2)
            .toFixed(2)}$ and $u_{4}=${series.term(4).toFixed(2)}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ar=${series.term(2).toFixed(2)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ar^{3}=${series.term(4).toFixed(2)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{ar^{3}}{ar}=\\frac{${series
            .term(4)
            .toFixed(2)}}{${series.term(2).toFixed(2)}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$r^2 \\approx ${(series.term(4) / series.term(2)).toFixed(4)}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$r=${r},$ $r \\neq ${-r}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$a(${r})=${series.term(2).toFixed(2)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore a=${a}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_{${term}}=${a}(${r})^{${term}-1}$`)
      );
      question.addParagraph(
        "solution",
        `$u_{${term}}=${series.term(term).toFixed(4)}...$`
      );
      question.addParagraph(
        "solution",
        `$\\approx${Math.round(series.term(term))}$ mintues`
      );
      // walkthrough b
      question.addHeading(
        "solution",
        `b) Given ${person.heshe} completes $${ppw}$ papers per week, find how much time ${person.heshe} spends practicing in the last week leading up to the final exam, to the nearest minute`
      );
      question.addParagraph(
        "solution",
        `Completes papers $${ppw * (n - 1) + 1}$ to $${
          ppw * n
        }$ in final week so looking for $\\displaystyle\\sum_{n=${
          ppw * (n - 1) + 1
        }}^{${ppw * n}}u_n$`
      );
      question.addMultipleParagraphs(
        "solution",
        series.sumBetweenWorking(ppw * (n - 1) + 1, ppw * n).slice(0, 5)
      );
      question.addMultipleParagraphs(
        "solution",
        series.sumBetweenWorking(ppw * (n - 1) + 1, ppw * n).slice(6, 9)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle\\sum_{n=${ppw * (n - 1) + 1}}^{${
            ppw * n
          }}u_n = \\frac{${a}(1-${r}^{${
            ppw * n
          }})}{1-${r}} - \\frac{${a}(1-${r}^{${ppw * (n - 1)}})}{1-${r}}$`
        )
      );
      question.addParagraph(
        "solution",
        `$=${series.sumBetween(ppw * (n - 1) + 1, ppw * n).toFixed(4)}...$`
      );
      question.addParagraph(
        "solution",
        `$\\approx${Math.round(
          series.sumBetween(ppw * (n - 1) + 1, ppw * n)
        )}$ minutes total`
      );
      // answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(Math.round(series.term(term)), 0.5, "a)");
      g1.addInput(
        Math.round(series.sumBetween(ppw * (n - 1) + 1, ppw * n)),
        0.5,
        "b)"
      );
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_Exam_Q11() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given u_=Au_ and Sn, find individual amounts
    const total = MCRandom.randomInt(100, 250) * 1000;
    const n = MCRandom.randomInt(4, 6);

    const denominator = MCRandom.randomInt(3, 4);
    const numerator = MCRandom.randomInt(1, denominator - 1);
    const r = numerator / denominator;

    const a = (total * (1 - r)) / (1 - r ** n);
    // const term_n = MCRandom.randomInt(2, n, 2);

    const series = new MCMaths.GeometricSequence(a, r);
    /* END VARIABLES AND CONSTANTS */
    if (a % 1 === 0 && series.term(n) % 1 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        `$${n}$ contestants share $£${
          total / 1000
        },000$ of prize winnings such that their shares form a geometric sequence.`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Given that the $1$st place contestant received $${
            1 / r ** 2
          }\\times$ the amount  of the 3rd place contestant.`
        )
      );
      question.addParagraph(
        "question",
        `Find how much money last place received`
      );
      // Walkthrough
      question.addParagraph("solution", `$u_n=ar^{n-1}$`);
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given $u_1=${1 / r ** 2}u_3$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{u_3}{u_1}=${r ** 2}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{ar^2}{a}=${r ** 2}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$r^2=${r ** 2}$`));
      question.addParagraph("solution", MCMaths.cleaner(`$r\\neq${-r}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore r = ${r}$`)
      );
      question.addParagraph("solution", `$S_n=\\frac{a(1-r^n)}{1-r}$`);
      question.addHeading(
        "solution",
        `Given $\\displaystyle\\sum_{n=1}^{${n}}u_n=${total}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{a(1-(${r})^{${n}})}{1-(${r})}=${total}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore a = ${a}$`)
      );
      question.addHeading("solution", `Find $u_{${n}}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_{${n}}=${a}(${r})^{${n}-1}$`)
      );
      question.addParagraph("solution", `$=£${series.term(n)}$`);
      // Answer box

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(series.term(n), 500, "$£$");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_Exam_Q12() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // find n on top
    const person1 = MCMisc.getName();
    const a1 = MCRandom.randomInt(5, 10) * 1000;
    const r1 = MCRandom.randomInt(105, 125, 5) / 100;
    const series1 = new MCMaths.GeometricSequence(a1, r1);
    const term_n = MCRandom.randomInt(3, 6);
    const sum_n = MCRandom.randomInt(2, 6);

    const person2 = MCMisc.getName();
    const a2 = MCRandom.randomInt(100, 150) * 1000;
    const r2 = MCRandom.randomInt(75, 95, 5) / 100;
    // const series2 = new MCMaths.GeometricSequence(a2, r2);

    const c_ans = Math.log(a2 / a1) / Math.log(r1 / r2) + 1;
    /* END VARIABLES AND CONSTANTS */
    if (series1.sum(sum_n) % 1 === 0 && series1.term(term_n) % 1 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `${
            person1.name
          } makes $${a1}$ sales within the first year of starting ${
            person1.hisher
          } bottled water company. ${
            person1.HeShe
          } predicts that sales will grow by $${Math.round(
            r1 * 100 - 100
          )}$% each year after.`
        )
      );
      question.addParagraph(
        "question",
        `Assuming ${person1.hisher} prediction is correct:`
      );
      question.addParagraph(
        "question",
        `a) Find how many sales ${person1.heshe} makes in year $${term_n}$`
      );
      question.addParagraph(
        "question",
        `b) Given ${person1.heshe} makes $${series1.sum(
          sum_n
        )}$ sales in the first $x$ years, find $x$`
      );
      question.addParagraph(
        "question",
        `${
          person2.name
        } owns another bottled water company that made $${a2}$ sales within the first year of ${
          person1.name
        } starting. Due to ${person2.hisher} customers switching to ${
          person1.name
        } and other providers, sales are predicted to drop by $${Math.round(
          100 - r2 * 100
        )}$% each year.`
      );
      question.addParagraph(
        "question",
        `c) Find in what year ${person1.name} will first make more sales than ${person2.name}`
      );
      // Part a
      question.addHeading(
        "solution",
        `a) Find how many sales ${person1.name} makes in year $${term_n}$`
      );
      question.addParagraph("solution", `$u_n=ar^{n-1}$`);
      question.addHeading("solution", `Given $a=${a1}$ and $r=${r1}$`);
      question.addParagraph(
        "solution",
        `$u_{${term_n}}=${a1}(${r1})^{${term_n}-1}$`
      );
      question.addParagraph(
        "solution",
        `$u_{${term_n}}=${series1.term(term_n)}$`
      );
      // Part b
      question.addHeading(
        "solution",
        `b) Given ${person1.name} makes $${series1.sum(
          sum_n
        )}$ sales in the first $x$ years, find $x$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=1}^{x}u_n=${series1.sum(sum_n)}$`
      );
      question.addParagraph("solution", `$S_n=\\frac{a(1-r^n)}{1-r}$`);
      question.addParagraph(
        "solution",
        `$\\frac{${a1}(1-(${r1})^x)}{1-(${r1})}=${series1.sum(sum_n)}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(${r1})^x = ${((series1.sum(sum_n) * (1 - r1)) / a1 - 1) * -1}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$x=\\frac{\\ln(${
            ((series1.sum(sum_n) * (1 - r1)) / a1 - 1) * -1
          })}{\\ln(${r1})}$`
        )
      );
      question.addParagraph("solution", `$\\therefore x = ${sum_n}$`);
      // Part c
      question.addHeading(
        "solution",
        `c) Find in what year ${person1.name} will first make more sales than ${person2.name}`
      );
      question.addHeading(
        "solution",
        `For ${person2.name}; $a=${a2}$ and $r=${r2}$`
      );
      question.addParagraph(
        "solution",
        `$${a1}(${r1})^{n-1} > ${a2}(${r2})^{n-1}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(\\frac{${r1}}{${r2}})^{n-1} > ${a2 / a1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(${r1 / r2})^{n-1} > ${a2 / a1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$n > \\frac{\\ln(${a2 / a1})}{\\ln(${r1 / r2})}+1$`)
      );
      question.addParagraph("solution", `$n > ${c_ans.toFixed(4)}...$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ outsells first in year $${Math.ceil(
          c_ans
        )}$ after starting`
      );
      // Answers

      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(series1.term(term_n), 500, "a)");
      g1.addInput(sum_n, 0, "b)");
      g1.addInput(Math.ceil(c_ans), 0, "c)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_Exam_Q2() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given 2 x u_, find _sum_
    const a = MCRandom.randomInt(-12, 12) / 4;
    const r = MCRandom.randomInt(2, 5);

    const term1 = MCRandom.randomInt(2, 4, 2);
    const term2 = MCRandom.randomInt(4, 6, 2);
    const lower_sum = MCRandom.randomInt(3, 6);
    const upper_sum = MCRandom.randomInt(8, 10);

    const series = new MCMaths.GeometricSequence(a, r);
    const ans = series.sumBetween(lower_sum, upper_sum);
    /* END VARIABLES AND CONSTANTS */
    if (
      a !== 1 &&
      a !== 0 &&
      r !== 0 &&
      r !== 1 &&
      term1 !== term2 &&
      series.sumBetween(lower_sum, upper_sum) < 100000 &&
      series.sumBetween(lower_sum, upper_sum) > -100000 &&
      ans % 1 === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        `A geometric series has first term $a$, common ratio $r$ where $r > 0$.`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Given $u_{${term1}}=${series.term(
            term1
          )}$ and $u_{${term2}}=${series.term(term2)}$`
        )
      );
      question.addParagraph(
        "question",
        `Find $\\displaystyle\\sum_{n=${lower_sum}}^{${upper_sum}}u_n$`
      );
      // Walkthrough
      question.addParagraph("solution", `$u_n=ar^{n-1}$`);
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Given $u_{${term1}}=${series.term(
            term1
          )}$ and $u_{${term2}}=${series.term(term2)}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ar^{${term1 - 1}}=${series.term(term1)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ar^{${term2 - 1}}=${series.term(term2)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{ar^{${term2 - 1}}}{ar^{${term1 - 1}}}=\\frac{${series.term(
            term2
          )}}{${series.term(term1)}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$r^{${term2 - term1}} = ${series.term(term2) / series.term(term1)}$`
        )
      );
      question.addHeading("solution", `Given $r > 0$`);
      question.addParagraph("solution", `$r=${r},$ $r \\neq ${-r}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$a(${r}^{${term1 - 1}})=${series.term(term1)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore a=${a}$`)
      );
      question.addMultipleParagraphs(
        "solution",
        series.sumBetweenWorking(lower_sum, upper_sum)
      );
      // Answer

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(ans, 500, `$\\sum_{${lower_sum}}^{${upper_sum}}u_n$=`);
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_Exam_Q3() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given a S_infty, find diff u_ and u_
    const a = MCRandom.randomInt(-40, 40) * 20;
    const r = MCRandom.randomInt(-18, 18, 2) / 20;

    const term1 = MCRandom.randomInt(4, 8);
    const term2 = MCRandom.randomInt(9, 13);

    const lower_sum = MCRandom.randomInt(3, 8);

    const series = new MCMaths.GeometricSequence(a, r);
    const a_ans = (series.term(term2) + series.term(term1)).toFixed(2);
    const b_ans = series.sumBetweenInfinity(lower_sum);
    /* END VARIABLES AND CONSTANTS */
    if (
      a !== 1 &&
      a !== 0 &&
      r !== 0 &&
      series.sumInfinity() % 0.125 === 0 &&
      (r > 2 / 5 || r < -2 / 5) &&
      (a >= 120 || a <= -120) &&
      series.term(term1) % series.term(term2) === 0 &&
      (term2 - term1) % 2 === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A geomtric series has first term $${a}$ and a sum to infinty of $${series.sumInfinity()}$.`
        )
      );
      question.addParagraph(
        "question",
        `Given $u_{${term1}}=${
          series.term(term1) / series.term(term2)
        }u_{${term2}}$.`
      );
      question.addParagraph(
        "question",
        `a) Find the sum of its $${term1}$th and $${term2}$th terms to 2 decimal places.`
      );
      question.addParagraph(
        "question",
        `b) Find $\\displaystyle\\sum_{n=${lower_sum}}^{\\infty}u_n$`
      );
      // Walkthrough
      question.addHeading(
        "solution",
        `a) Find the sum of its $${term1}$th and $${term2}$th terms to 2 decimal places.`
      );
      question.addParagraph("solution", `$u_n=ar^{n-1}$`);
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Given $u_{${term1}}=${
            series.term(term1) / series.term(term2)
          }u_{${term2}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$ar^{${term1}-1}=${
            series.term(term1) / series.term(term2)
          }ar^{${term2}-1}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$r^{${term2 - term1}} = \\frac{1}{${
            series.term(term1) / series.term(term2)
          }}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore r=\\pm ${Math.abs(r)}$`)
      );
      // Sub into infinity
      question.addParagraph("solution", `$S_{\\infty} = \\frac{a}{1-r}$`);
      question.addHeading(
        "solution",
        `Given $a=${a}$ and $S_{\\infty}=${series.sumInfinity()}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{${a}}{1-(${-r})}=${a / (1 + r)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{${a}}{1-(${r})}=${a / (1 - r)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore r = ${r}$`)
      );
      //
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$u_${term1} + u_{${term2}} = ${a}(${r})^{${term1}-1} + ${a}(${r})^{${term2}-1}$`
        )
      );
      question.addParagraph(
        "solution",
        `$=${(a * r ** (term1 - 1) + a * r ** (term2 - 1)).toFixed(4)}...$`
      );
      question.addParagraph("solution", `$\\approx ${a_ans}$`);
      // part b
      question.addHeading(
        "solution",
        `b) Find $\\displaystyle\\sum_{n=${lower_sum}}^{\\infty}u_n$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=${lower_sum}}^{\\infty}u_n=S_{\\infty}-S_{${lower_sum}-1}=S_{\\infty}-S_{${
          lower_sum - 1
        }}$`
      );
      question.addParagraph("solution", `$S_n = \\frac{a(1-r^{n})}{1-r}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$S_{${lower_sum - 1}}=\\frac{${a}(1-(${r})^{${
            lower_sum - 1
          }})}{1-(${r})}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${series.sum(lower_sum - 1)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore \\displaystyle\\sum_{n=${lower_sum}}^{\\infty}u_n=${b_ans}$`
        )
      );
      // Answer box

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(parseFloat(a_ans), 0.005, "a)");
      g1.addInput(b_ans, 5, "b)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_Exam_Q4() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // algebra
    const x = MCRandom.randomInt(-10, 10);
    const a = MCRandom.randomInt(1, 10) * 64;
    const r = MCRandom.randomInt(1, 7) / 8;

    const coefficient1 = MCRandom.randomInt(-5, 5);
    const coefficient2 = MCRandom.randomInt(-5, 5);
    const coefficient3 = MCRandom.randomInt(-5, 5);

    const series = new MCMaths.GeometricSequence(a, r);
    const term1 = new MCMaths.Polynomial(
      [coefficient1, series.term(1) - coefficient1 * x],
      "x"
    );
    const term2 = new MCMaths.Polynomial(
      [coefficient2, series.term(2) - coefficient2 * x],
      "x"
    );
    const term3 = new MCMaths.Polynomial(
      [coefficient3, series.term(3) - coefficient3 * x],
      "x"
    );
    const combinedPoly = term2.multiply(term2).subtract(term3.multiply(term1));

    const p = Math.ceil(Math.log(1 / a) / Math.log(r) + 1.000000001);
    /* END VARIABLES AND CONSTANTS */
    if (combinedPoly.toString().includes("x^{2}")) {
      if (
        a !== 1 &&
        a !== 0 &&
        r !== 0 &&
        coefficient1 !== 0 &&
        coefficient2 !== 0 &&
        coefficient3 !== 0 &&
        x !== 0 &&
        coefficient3 - coefficient2 !== coefficient2 - coefficient1 &&
        coefficient1 !== coefficient2 &&
        coefficient2 !== coefficient3 &&
        Math.abs(combinedPoly.roots()[0]) > 10 &&
        combinedPoly.roots[1] !== 0 &&
        p > 2
      ) {
        /* START BUILDING QUESTION USING MCQuestion */
        // your question writing here
        const question = new MCQuestion.ExamQuestion();
        // Form q
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `A geometric sequence has first three terms $${term1.toString()},${term2.toString()}$ and $${term3.toString()}$`
          )
        );
        question.addParagraph("question", `Given $|x|< 6$`);
        question.addParagraph(
          "question",
          `Find the least value of p such that $u_p < 1$`
        );
        // Walkthrough
        question.addParagraph("solution", `$u_n=ar^{n-1}$`);
        question.addParagraph(
          "solution",
          `$r = \\frac{u_2}{u_1} = \\frac{u_3}{u_2}$`
        );
        question.addParagraph(
          "solution",
          `$\\frac{${term2.toString()}}{${term1.toString()}} = \\frac{${term3.toString()}}{${term2.toString()}}$`
        );
        question.addParagraph("solution", `$${combinedPoly}=0$`);
        question.addHeading("solution", `Given $|x|< 6$`);
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$x=${combinedPoly.roots()[1]}, x\\neq${combinedPoly.roots()[0]}$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$a=${term1.toString().replace("x", `(${x})`)}$`)
        );
        question.addParagraph("solution", `$\\therefore a= ${a}$`);
        question.addParagraph("solution", `$u_2=${a}r$`);
        question.addParagraph(
          "solution",
          `$${a}r=${term2.toString().replace("x", `(${x})`)}$`
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore r = ${r}$`)
        );
        question.addHeading(
          "solution",
          `Find the least value of p such that $u_p < 1$`
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$${a}(${r})^{p-1} < 1$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$p > \\frac{\\ln(\\frac{1}{${a}})}{\\ln(${r})}+1$`)
        );
        question.addParagraph(
          "solution",
          `$p > ${(Math.log(1 / a) / Math.log(r) + 1).toFixed(4)}...$`
        );
        question.addParagraph("solution", `$\\therefore p = ${p}$`);
        // answer

        const g1 = new MCQuestion.InputGroup(2);
        g1.addInput(p, 0, "$p$=");
        question.addInputGroup(g1);
        /* END BUILDING QUESTION */
        /* RETURN THE QUESTION */
        return question.makeQuestion();
      }
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_Exam_Q5() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // a=r, given s_infty, find sum > value
    const a = MCRandom.randomInt(1, 63) / 64;
    const N = MCRandom.randomInt(2, 8);

    const series = new MCMaths.GeometricSequence(a, a);
    const ans =
      Math.log(1 - ((1 / N) * series.sumInfinity()) / (a / (1 - a))) /
        Math.log(a) +
      0.0000001;
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(series.sumInfinity().toFixed(8)) === series.sumInfinity() &&
      ans > 3
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A geometric series has a sum to infinity of $${series.sumInfinity()}$ and common ratio equal to its first term.`
        )
      );
      question.addParagraph(
        "question",
        `Find the least value of $x$ such that $\\displaystyle\\sum_{n=1}^{x}u_n > \\frac{1}{${N}}S_{\\infty}$`
      );
      // Walkthrough
      question.addParagraph("solution", `$S_{\\infty} = \\frac{a}{1-r}$`);
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given $a=r$ and $S_{\\infty}=${series.sumInfinity()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{a}{1-a}=${series.sumInfinity()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore a = ${a}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore r = ${a}$`)
      );
      question.addParagraph("solution", `$S_n = \\frac{a(1-r^{n})}{1-r}$`);
      question.addHeading(
        "solution",
        `Given $\\displaystyle\\sum_{n=1}^{x}u_n > \\frac{1}{${N}}S_{\\infty}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{${a}(1-(${a})^{x})}{1-${a}}>\\frac{1}{${N}}(${series.sumInfinity()})$`
        )
      );
      // question.addParagraph('solution', MCMaths.cleaner(`$1-(${a})^{x}>${((1/N)*series.sumInfinity()) / (a/(1-a))}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(${a})^{x} < ${
            1 - ((1 / N) * series.sumInfinity()) / (a / (1 - a))
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$x\\ln(${a}) > \\ln(${
            1 - ((1 / N) * series.sumInfinity()) / (a / (1 - a))
          })$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$x > \\frac{\\ln(${
            1 - ((1 / N) * series.sumInfinity()) / (a / (1 - a))
          })}{\\ln(${a})}$`
        )
      );
      question.addParagraph("solution", `$x > ${ans.toFixed(4)}...$`);
      question.addParagraph("solution", `$\\therefore x = ${Math.ceil(ans)}$`);
      // Answer boxes

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(Math.ceil(ans), 0, "$x$=");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_Exam_Q6() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // Given 3 terms and U_n = A x B^n, find A and B, then find x under sum
    const a = MCRandom.randomInt(-63, 63) / 64;
    const r = MCRandom.randomInt(-4, 4);

    const x = MCRandom.randomInt(4, 8);
    const upper = MCRandom.randomInt(10, 14);

    const series = new MCMaths.GeometricSequence(a, r);
    const A = a * (1 / r);
    /* END VARIABLES AND CONSTANTS */
    if (
      a !== 0 &&
      r !== 0 &&
      r !== 1 &&
      r !== -1 &&
      series.sumBetween(x, upper) % (1 / 16) === 0 &&
      series.sumBetween(x, upper) < 100000 &&
      series.sumBetween(x, upper) > -100000
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A geometric series begins $${series.terms(
            1,
            3
          )}$ and has $n$th term $u_n=A \\times B^{n}$`
        )
      );
      question.addParagraph("question", `a) Find the values of $A$ and $B$`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Given $\\displaystyle\\sum_{n=x}^{${upper}}u_n=${series.sumBetween(
            x,
            upper
          )}$`
        )
      );
      question.addParagraph("question", `b) Find $x$`);
      // Walkhrough part a
      question.addHeading("solution", `a) Find the values of $A$ and $B$`);
      question.addHeading("solution", MCMaths.cleaner(`Given $a=${a}$`));
      question.addParagraph("solution", `$u_n=ar^{n-1}$`);
      question.addParagraph("solution", `$r = \\frac{u_2}{u_1}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$r=\\frac{${series.term(2)}}{${a}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore r = ${r}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_n=${a}(${r})^{n-1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${a}(${r})^{n}(\\frac{1}{${r}})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${a * (1 / r)} \\times ${r}^n$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore A=${a * (1 / r)}, B = ${r}$`)
      );
      // Walkthroug b
      question.addHeading("solution", `b) Find $x$`);
      question.addParagraph("solution", `$S_n = \\frac{a(1-r^n)}{1-r}$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=x}^{${upper}}u_n = S_{${upper}} - S_{x-1}$`
      );
      question.addParagraph(
        "solution",
        `$S_{x-1} = S_{${upper}} - \\displaystyle\\sum_{n=x}^{${upper}}u_n$`
      );
      question.addHeading("solution", `Sum of first $${upper}$ terms`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$S_{${upper}}=\\frac{${a}(1-(${r})^{${upper}})}{1-(${r})}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore S_{${upper}}=${series.sum(upper)}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Given $\\displaystyle\\sum_{n=x}^{${upper}}u_n=${series.sumBetween(
            x,
            upper
          )}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$S_{x-1}=${series.sum(upper)}-(${series.sumBetween(x, upper)})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore S_{x-1}=${
            series.sum(upper) - series.sumBetween(x, upper)
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{${a}(1-(${r})^{x-1})}{1-(${r})} = ${
            series.sum(upper) - series.sumBetween(x, upper)
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(${r})^{x-1}=${
            1 -
            ((series.sum(upper) - series.sumBetween(x, upper)) * (1 - r)) / a
          }$`
        )
      );
      question.addParagraph("solution", `$\\therefore x= ${x}$`);
      // Answer boxes

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(A, 0.05, `a) $\\ A$=`);
      g1.addInput(r, 0.05, `a) $\\ B$=`);
      g1.addInput(x, 0, `b) $\\ x$=`);
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_Exam_Q7() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given a and sum of first two terms. Find the largest possible value of last 4 even numbered terms
    const a = MCRandom.randomInt(2, 12, 2);
    const r = MCRandom.randomInt(9, 15) / 8;

    const size = MCRandom.randomInt(10, 16, 2);

    const series = new MCMaths.GeometricSequence(a, r);

    const poly = new MCMaths.Polynomial([a, a, a - series.sum(3)], "r");
    const roots = poly.roots();

    const even1 = new MCMaths.GeometricSequence(a * roots[0], roots[0]);
    const even2 = new MCMaths.GeometricSequence(a * roots[1], roots[1]);
    /* END VARIABLES AND CONSTANTS */
    if (
      series.sum(3) % (1 / 16) === 0 &&
      even1.sum(size / 2) % (1 / 512) === 0 &&
      even2.sum(size / 2) % (1 / 512) === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // From q
      question.addParagraph(
        "question",
        `A geometric series has first term $a$ and common ratio $r$`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Given $a=${a}$ and $\\displaystyle\\sum_{n=1}^{3}u_n=${series.sum(
            3
          )}$`
        )
      );
      question.addParagraph(
        "question",
        `Find the largest possible value of $u_2 + u_4 + u_6 + .... + u_{${size}}$ to 2 decimal places`
      );
      // Walkthrough
      question.addParagraph("solution", `$u_n=ar^{n-1}$`);
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Given $a=${a}$ and $\\displaystyle\\sum_{n=1}^{3}u_n=${series.sum(
            3
          )}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_3 + u_2 + u_1 = ${series.sum(3)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${a}r^2 + ${a}r + ${a} = ${series.sum(3)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${poly.toString()}=0$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$r=${poly.roots()}$`));
      question.addHeading(
        "solution",
        `Find the largest possible value of $u_2 + u_4 + u_6 + .... + u_{${size}}$ to 2 decimal places`
      );
      question.addParagraph(
        "solution",
        `$u_2 + u_4 + u_6 + .... + u_{${size}}=\\displaystyle\\sum_{n=1}^{${
          size / 2
        }}u_{2n}$`
      );
      question.addParagraph(
        "solution",
        `Even numbered terms from their own series with first term $u_2$ and common ratio $r^2$`
      );
      question.addHeading("solution", MCMaths.cleaner(`Using $r=${roots[0]}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_2=${a * roots[0]}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$r^2=${roots[0] ** 2}$`)
      );
      question.addHeading("solution", MCMaths.cleaner(`Using $r=${roots[1]}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_2=${a * roots[1]}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$r^2=${roots[1] ** 2}$`)
      );
      question.addParagraph("solution", `$S_n=\\frac{a(1-r^n)}{1-r}$`);
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Using $a=${a * roots[0]}$ and $r=${roots[0] ** 2}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$S_{${size / 2}} = \\frac{${series.term(2)}(1-(${roots[0] ** 2})^{${
            size / 2
          }})}{1-${roots[0] ** 2}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore S_{${size / 2}} = ${even1.sum(size / 2)}$`
        )
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Using $a=${a * roots[1]}$ and $r=${roots[1] ** 2}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$S_{${size / 2}} = \\frac{${a * roots[1]}(1-(${roots[1] ** 2})^{${
            size / 2
          }})}{1-${roots[1] ** 2}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore S_{${size / 2}} = ${even2.sum(size / 2)}$`
        )
      );
      if (even1.sum(size / 2) > even2.sum(size / 2)) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$${even1.sum(size / 2)} > ${even2.sum(size / 2)}$`)
        );
        question.addParagraph(
          "solution",
          `$\\therefore $Max$ = ${even1.sum(size / 2).toFixed(4)}...$`
        );
        question.addParagraph(
          "solution",
          `$\\approx ${even1.sum(size / 2).toFixed(2)}$`
        );
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$${even2.sum(size / 2)} > ${even1.sum(size / 2)}$`)
        );
        question.addParagraph(
          "solution",
          `$\\therefore $Max$ = ${even2.sum(size / 2).toFixed(4)}...$`
        );
        question.addParagraph(
          "solution",
          `$\\approx ${even2.sum(size / 2).toFixed(2)}$`
        );
      }
      const g1 = new MCQuestion.InputGroup(2);
      if (even1.sum(size / 2) > even2.sum(size / 2)) {
        g1.addInput(parseFloat(even1.sum(size / 2).toFixed(2)), 5, "Max=");
      } else {
        g1.addInput(parseFloat(even2.sum(size / 2).toFixed(2)), 5, "Max=");
      }
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_Exam_Q8() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given a, S_\\infty, Find sum to number, when u_n < value
    const a = MCRandom.randomInt(40, 60);
    const r = (100 - MCRandom.randomInt(2, 10)) / 100;

    const drop = MCRandom.randomInt(20, 30);
    const uptime = MCRandom.randomInt(10, 20);

    const series = new MCMaths.GeometricSequence(a, r);

    const a_ans = Math.ceil(Math.log(drop / a) / Math.log(r) + 1 + 0.000001);
    const b_ans = Math.floor(series.sum(uptime));
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A spinning top completes $${a}$ revolutions in its first second of motion.The number of revolutions in each subsequent second forms a geometric sequence where, $0<r<1$.`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `If the spinning top never tipped it would complete $\\frac{${
            a * 100
          }}{${100 - r * 100}}$ revolutions total.`
        )
      );
      question.addParagraph(
        "question",
        `a) Find in what second the top first drops below $${drop}$ revolutions per second, to the nearest second`
      );
      question.addParagraph(
        "question",
        `b) Given the top falls over after $${uptime}$ seconds, find how many complete revolutions it makes`
      );
      // Walkthrough a
      question.addHeading(
        "solution",
        `a) Find in what second the top first drops below $${drop}$ revolutions per second, to the nearest second`
      );
      question.addParagraph("solution", `$S_{\\infty}=\\frac{a}{1-r}$`);
      question.addHeading(
        "solution",
        `Given $a=${a}$ and $S_{\\infty}=\\frac{${a * 100}}{${100 - r * 100}}$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{${a}}{1-r}=\\frac{${a * 100}}{${100 - r * 100}} $`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore r=${r}$`)
      );
      question.addParagraph("solution", `$u_n=ar^{n-1}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${a}(${r})^{n-1} < ${drop}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$n > \\frac{\\ln(\\frac{${drop}}{${a}})}{\\ln(${r})}+1$`
        )
      );
      question.addParagraph(
        "solution",
        `$n > ${(Math.log(drop / a) / Math.log(r) + 1).toFixed(4)}...$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Drops below $${drop}$ rev/s in second $${a_ans}$`
      );
      // Walkthrough b
      question.addHeading(
        "solution",
        `b) Given the top falls over after $${uptime}$ seconds, find how many complete revolutions it makes`
      );
      question.addParagraph("solution", `$S_n=\\frac{a(1-r^n)}{1-r}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$S_{${uptime}} = \\frac{${a}(1-(${r})^{${uptime}})}{1-${r}} $`
        )
      );
      question.addParagraph(
        "solution",
        `$S_{${uptime}}=${series.sum(uptime).toFixed(4)}...$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Completes $${b_ans}$ full revolutions`
      );
      // Answer boxes

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(a_ans, 0, "a)");
      g1.addInput(b_ans, 0, " b)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_Exam_Q9() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // given a,r as percentage find u_, S_
    const a = MCRandom.randomInt(80, 150) * 1000;
    const r = MCRandom.randomInt(105, 120) / 100;

    const year = MCRandom.randomInt(4, 10);
    const millions = MCRandom.randomInt(1, 5);

    const series = new MCMaths.GeometricSequence(a, r);
    const n_greater =
      Math.log((millions * 1000000 * (r - 1)) / a + 1) / Math.log(r) + 0.00001;
    // const n_greater = 102.747834;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        `A company makes $£${
          a / 1000
        },000$ in its first year after rebranding and predict that profits will increase by $${Math.round(
          r * 100 - 100
        )}$% every year.`
      );
      question.addParagraph(
        "question",
        `a) Find the companies profits, to the nearest pound, in year $${year}$`
      );
      question.addParagraph(
        "question",
        `b) Find the year in which the comapnies total profits will exceed $£${millions}$ million`
      );
      // Walkthrough a
      question.addHeading(
        "solution",
        `a) Find the companies profits, to the nearest pound, in year $${year}$`
      );
      question.addParagraph("solution", `$u_n=ar^{n-1}$`);
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given a = $${a}$ and $r=${r}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_{${year}}=${a}(${r})^{${year}-1}$`)
      );
      question.addParagraph(
        "solution",
        `$u_{${year}}=${series.term(year).toFixed(2)}...$`
      );
      question.addParagraph(
        "solution",
        `$\\approx £${Math.round(series.term(year))}$ profit in year $${year}$`
      );
      // Walkthorugh b
      question.addHeading(
        "solution",
        `b) Find the year in which the comapnies total profits will exceed $£${millions}$ million`
      );
      question.addParagraph("solution", `$S_n=\\frac{a(1-r^n)}{1-r}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{${a}(1-(${r})^{n})}{1-(${r})} > ${millions}\\times 10^6$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(${r})^{n} > \\frac{${-millions * (1 - r)}\\times10^6}{${a}}+1$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$n\\ln(${r}) > \\ln(\\frac{${
            -millions * (1 - r)
          }\\times10^6}{${a}}+1)$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$n > \\frac{\\ln(\\frac{${
            -millions * (1 - r)
          }\\times10^6}{${a}}+1)}{\\ln(${r})}$`
        )
      );
      question.addParagraph("solution", `$n > ${n_greater.toFixed(4)}...$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ exceeds $£${millions}$ million profit in year $${Math.ceil(
          n_greater
        )}$`
      );
      // Answer boxes

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(Math.round(series.term(year)), 500, "a)");
      g1.addInput(Math.ceil(n_greater), 0, "b)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_MCQ_Q1() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(-10, 10);
    const r = MCRandom.randomInt(-5, 5);

    const series = new MCMaths.GeometricSequence(a, r);
    /* END VARIABLES AND CONSTANTS */
    if ((r > 1 || r < -1) && (a > 1 || a < -1)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      // Form q
      question.addParagraph(
        `Which of the following could represent the folowing geometric series:`
      );
      question.addParagraph(`$${series.terms(1, 4)}$`);
      question.addHeading("Choose TWO");
      // Answers
      // create our first answer
      const a1 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a1.addParagraph(`$u_n=${a}(${-r})^{n-1}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a2.addParagraph(MCMaths.cleaner(`$u_n=${a / r}(${r})^{n}$`));

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a3.addParagraph(`$u_{n}=${a}\\times${r}^{n}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a4.addParagraph(`$u_{n+1}=${r}u_n$`);

      // add the answers to the question
      question.addAnswer(a1, false);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, true);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_MCQ_Q2() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(-10, 10);
    const r = MCRandom.randomInt(-5, 5);

    const lower = MCRandom.randomInt(2, 4);
    const upper = lower + 2;

    const series = new MCMaths.GeometricSequence(a, r);
    /* END VARIABLES AND CONSTANTS */
    if ((r > 1 || r < -1) && (a > 1 || a < -1)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      // Form q
      question.addParagraph(
        `Given $u_{${lower}}=${series.term(
          lower
        )}$ and $u_{${upper}}=${series.term(
          upper
        )}$ are part of a geometric sequence.`
      );
      question.addParagraph(`Select the possible values of $r$`);
      question.addHeading("Choose TWO");
      // Answers
      // create our first answer
      const a1 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a1.addParagraph(`$${r}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a2.addParagraph(MCMaths.cleaner(`$${-1 * r ** 2}$`));

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a3.addParagraph(`$${r ** 2}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a4.addParagraph(`$${-r}$`);

      // add the answers to the question
      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, true);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_MCQ_Q3() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(-100, 100);
    const r = MCRandom.randomInt(-9, 9) / 10;
    const decoy_r = MCRandom.randomInt(-5, 5);
    const one = MCRandom.randomInt(-1, 1, 2);

    const series = new MCMaths.GeometricSequence(a, r);
    /* END VARIABLES AND CONSTANTS */
    if ((decoy_r > -1 || decoy_r < -1) && (a > 1 || a < -1)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      // Form q
      question.addParagraph(
        MCMaths.cleaner(
          `Given $S_{\\infty}=${series
            .sumInfinity()
            .toFixed(0)}$ for a geometric sequence`
        )
      );
      question.addParagraph(
        `Which of these values are possible for $a$ and $r$`
      );
      question.addHeading("Choose TWO");
      // Answers
      // create our first answer
      const a1 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a1.addParagraph(MCMaths.cleaner(`$r=${r.toFixed(1)}$`));

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a2.addParagraph(MCMaths.cleaner(`$r=${one}$`));

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a3.addParagraph(
        MCMaths.cleaner(
          `$a=${parseFloat(series.sumInfinity().toFixed(0)) * (1 - decoy_r)}$`
        )
      );

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a4.addParagraph(`$a=${a}$`);

      // add the answers to the question
      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, true);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_GeometricSequences_MCQ_Q4() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const percent = MCRandom.randomInt(20, 80, 10);

    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      // Form q
      question.addParagraph(
        MCMaths.cleaner(
          `The first term of a geometric series is $a$ and each term is $${percent}$% larger than previous term.`
        )
      );
      question.addParagraph(`Which of these represent a general term $u_{n}$`);
      question.addHeading("Choose TWO");
      // Answers
      // create our first answer
      const a1 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a1.addParagraph(`$u_n=(${(1 + percent / 100).toFixed(1)}a)^{n+1}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a2.addParagraph(`$u_n=a(${(1 + percent / 100).toFixed(1)})^{n+1}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a3.addParagraph(`$u_n=a(${(percent / 100).toFixed(1)})^{n+1}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a4.addParagraph(
        MCMaths.cleaner(`$u_{n}=${1 / (1 + percent / 100)}u_{n+1}$`)
      );

      // add the answers to the question
      question.addAnswer(a1, false);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, true);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}

export function A2_SequencesAndSeries_Recurrence_Exam_Q1() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(1, 4) * 4;
    const q = MCRandom.randomInt(1, 7) / 8;
    const p = MCRandom.randomInt(-16, 16);
    const formula = new MCMaths.Polynomial([q, p], `x`);
    const limit = p / (1 - q); // x(1-q) = p
    const upper_sum = MCRandom.randomInt(50, 100, 5);

    const series = new MCMaths.Recurrence(formula, a);
    /* END VARIABLES AND CONSTANTS */
    if (
      series.term(2) !== 0 &&
      series.term(3) !== 0 &&
      series.term(3) !== limit &&
      p !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        `The $n$th term of a sequence is $u_n$. The sequence is defined by:`
      );
      question.addParagraph("question", MCMaths.cleaner(`$u_{n+1}=p + qu_n$`));
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `The $2$nd and $3$rd terms are given by, $u_2=${series.term(
            2
          )}$ and $u_3=${series.term(3)}$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`The limit of the sequence is $${limit}$.`)
      );
      question.addParagraph("question", `a) Find $p$ and $q$`);
      question.addParagraph("question", `b) Find $u_1$`);
      question.addParagraph(
        "question",
        `c) Find $\\displaystyle\\sum_{n=1}^{${upper_sum}}u_{n+1}-qu_n$`
      );
      // Walkthrough a
      question.addHeading("solution", `a) Find $p$ and $q$`);
      question.addParagraph("solution", `$u_{n+1}=p+qu_n$`);
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Given $u_2=${series.term(2)}$ and $u_3=${series.term(3)}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${series.term(3)}=p+${series.term(2)}q$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore p=${series.term(3)}-${series.term(2)}q$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given Limit =$${limit}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${limit}=p+${limit}q$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore p=${limit}-${limit}q$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Substituting $p=${series.term(3)}-${series.term(2)}q$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${series.term(3)}-${series.term(2)}q=${limit}-${limit}q$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore q=${q}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$p=${series.term(3)}-${series.term(2)}(${q})$`)
      );
      question.addParagraph("solution", `$\\therefore p=${p}$`);
      // Walkthrough b
      question.addHeading("solution", `b) Find $u_1$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_{n+1}=${p}+${q}u_n$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given $u_2=${series.term(2)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${series.term(2)}=${p}+${q}u_1$`)
      );
      question.addParagraph("solution", `$\\therefore u_1=${a}$`);
      // walkthrough c
      question.addHeading(
        "solution",
        `c) Find $\\displaystyle\\sum_{n=1}^{${upper_sum}}u_{n+1}-qu_n$`
      );
      question.addParagraph("solution", `$u_{n+1}=${p}+qu_n$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=1}^{${upper_sum}}(${p}+qu_n)-qu_n$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=1}^{${upper_sum}}${p}$`
      );
      question.addParagraph("solution", `$=${upper_sum * p}$`);
      // Answer boxes

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(p, 0, "a) $\\ p$=");
      g1.addInput(q, 0, "a) $\\ q$=");
      g1.addInput(a, 0, `b)`);
      g1.addInput(upper_sum * p, 0, "c)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_Exam_Q10() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(1, 5);
    const p = MCRandom.randomInt(1, 5);
    const formula = new MCMaths.Sqrt([1, 0, p], `x`);

    const series = new MCMaths.Recurrence(formula, a);

    const terms = [
      "fill",
      a,
      series.term(2),
      series.term(3),
      series.term(4),
      series.term(5),
    ];
    const A = parseInt((a ** 2 + p + terms[3] ** 2).toFixed(0), 10);
    const B = parseInt((terms[2] ** 2 * terms[3] ** 2).toFixed(0), 10);
    const b_ans = parseInt(
      (a ** 2 + terms[2] ** 2 + terms[3] ** 2 + 3 * p).toFixed(0),
      10
    );
    /* END VARIABLES AND CONSTANTS */
    if (p !== 0 && terms[2] % 1 !== 0 && terms[3] % 1 !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        `The $n$th term of a sequence is $u_n$. The sequence is defined by:`
      );
      question.addParagraph("question", `$${series.toString()}$ , $u_1=${a}$`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(`Given $(u_2 + u_3)^2 = A + 2\\sqrt{B}$`)
      );
      question.addParagraph("question", MCMaths.cleaner(`a) Find $A$ and $B$`));
      question.addParagraph(
        "question",
        MCMaths.cleaner(`b) Find $\\displaystyle\\sum_{n=1}^{3}u_{n}^2+${p}$`)
      );
      // walkthrough a
      question.addHeading("solution", MCMaths.cleaner(`a) Find $A$ and $B$`));
      question.addParagraph("solution", `$${series.toString()}$`);
      question.addHeading("solution", `Given $u_1=${a}$`);
      question.addParagraph("solution", `$u_2=\\sqrt{${a}^{2}+${p}}$`);
      question.addParagraph("solution", `$=\\sqrt{${a ** 2 + p}}$`);
      question.addParagraph(
        "solution",
        `$u_3=\\sqrt{(\\sqrt{${a ** 2 + p}})^{2}+${p}}$`
      );
      question.addParagraph(
        "solution",
        `$=\\sqrt{${(terms[3] ** 2).toFixed(0)}}$`
      );
      question.addParagraph(
        "solution",
        `$(u_2+u_3)^2=(\\sqrt{${a ** 2 + p}}+\\sqrt{${(terms[3] ** 2).toFixed(
          0
        )}})^2$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=(\\sqrt{${a ** 2 + p}})^2+(\\sqrt{${(terms[3] ** 2).toFixed(
            0
          )}})^2+2\\sqrt{${(terms[2] ** 2).toFixed(0)} \\times ${(
            terms[3] ** 2
          ).toFixed(0)}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${A}+2\\sqrt{${B}}$`)
      );
      question.addParagraph("solution", `$\\therefore A=${A}$ , $B=${B}$`);
      // walkthrugh b
      question.addHeading(
        "solution",
        MCMaths.cleaner(`b) Find $\\displaystyle\\sum_{n=1}^{3}u_{n}^2+${p}$`)
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=1}^{3}(u_{n}^2+${p})=(u_{1}^{2}+${p})+(u_{2}^{2}+${p})+(u_{3}^{2}+${p})$`
      );
      question.addParagraph(
        "solution",
        `$=\\displaystyle\\sum_{n=1}^{3}(u_{n}^2) + ${3 * p}$`
      );
      question.addParagraph(
        "solution",
        `$=(${a})^2+(\\sqrt{${(terms[2] ** 2).toFixed(0)}})^2+(\\sqrt{${(
          terms[3] ** 2
        ).toFixed(0)}})^2+${3 * p}$`
      );
      question.addParagraph("solution", `$=${b_ans}$`);
      // answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(A, 0, `a) $\\ A$=`);
      g1.addInput(B, 0, `a) $\\ B$=`);
      g1.addInput(b_ans, 0, `b)`);
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_Exam_Q11() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(1, 16);
    const q = MCRandom.randomInt(2, 12);
    const p = MCRandom.randomInt(1, 16);
    const formula = new MCMaths.PartialFraction([q, p], [1, 0], [0, 1]); // common factor

    const series = new MCMaths.Recurrence(formula, a);
    const terms = ["fill", a, series.term(2), series.term(3), series.term(4)];
    /* END VARIABLES AND CONSTANTS */
    if (
      series.term(2) !== 0 &&
      series.term(3) % 1 === 0 &&
      series.term(3) !== a
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // form q
      question.addParagraph(
        "question",
        `A sequence $${series.terms(1, 3)},...$ has $n$th term:`
      );
      // question.addParagraph('question', `${series.toString()}`);
      question.addParagraph("question", `$u_{n+1}=\\frac{p+qu_n}{u_n}$`);
      question.addParagraph("question", `a) Find $p$ and $q$`);
      question.addParagraph("question", `b) Find $u_4$`);
      // walkthrough a
      question.addHeading("solution", `a) Find $p$ and $q$`);
      question.addParagraph("solution", `$u_{n+1}=\\frac{p+qu_n}{u_n}$`);
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given $u_1,u_2,u_3$ as $${series.terms(1, 3)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${terms[2]}=\\frac{p+${a}q}{${a}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore p=${terms[2] * a}-${a}q$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${terms[3]}=\\frac{p+${terms[2]}q}{${terms[2]}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore p=${terms[3] * terms[2]}-${terms[2]}q$`)
      );
      question.addHeading("solution", `Using both equations for $p$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${terms[2] * a}-${a}q=${terms[3] * terms[2]}-${terms[2]}q$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore q=${q}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$p=${terms[2] * a}-${a}(${q})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore p=${p}$`)
      );
      // walkthrough b
      question.addHeading("solution", `b) Find $u_4$`);
      question.addParagraph("solution", `$u_{n+1}=\\frac{${p}+${q}u_n}{u_n}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_4=\\frac{${p}+${q}(${terms[3]})}{${terms[3]}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore u_4=${terms[4]}$`)
      );
      // answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(p, 0, `a) $\\ p$=`);
      g1.addInput(q, 0, `a) $\\ q$=`);
      g1.addInput(terms[4], 0, `b)`);
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_Exam_Q12() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(1, 16);
    const q = MCRandom.randomInt(2, 12);
    const p = MCRandom.randomInt(1, 16);
    const formula = new MCMaths.PartialFraction([q, p], [1, 0], [0, 1]); // common factor

    const series = new MCMaths.Recurrence(formula, a);
    const terms = ["fill", a, series.term(2)];
    const kPoly = new MCMaths.Polynomial(
      [1, q - (terms[1] + terms[2]), p],
      "k"
    );
    const roots = kPoly.roots();
    const k0 = ((p + q ** 2) * roots[0] + q * p) / (p + q * roots[0]);
    const k1 = ((p + q ** 2) * roots[1] + q * p) / (p + q * roots[1]);
    let u3 = 0;
    if (k0 > k1) {
      u3 = k0;
    } else {
      u3 = k1;
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      series.term(2) !== 0 &&
      series.term(3) % 1 === 0 &&
      series.term(3) !== a &&
      roots[0] !== roots[1] &&
      parseFloat((terms[1] + terms[2] + u3).toFixed(2)) ===
        terms[1] + terms[2] + u3
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        `A sequence has $n$th term $u_n$ and is defined by:`
      );
      // question.addParagraph('question', `${series.toString()}`);
      // question.addParagraph('question', `$${series.terms(1,4)}$`);
      question.addParagraph(
        "question",
        `$u_{n+1}=\\frac{${p}+${q}u_n}{u_n}$ , $u_1=k$`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`Given $u_1 + u_2 = ${terms[1] + terms[2]}$`)
      );
      question.addParagraph(
        "question",
        `a) Find the largest possible value of $u_3$`
      );
      question.addParagraph(
        "question",
        `b) Using your answer from part a, find $\\displaystyle\\sum_{n=1}^{3}u_n$`
      );
      // part a
      question.addHeading(
        "solution",
        `a) Find the largest possible value of $u_3$`
      );
      question.addParagraph("solution", `$u_{n+1}=\\frac{${p}+${q}u_n}{u_n}$`);
      question.addHeading("solution", `Given $u_1=k$`);
      question.addParagraph("solution", `$u_{2}=\\frac{${p}+${q}k}{k}$`);
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given $u_1 + u_2 = ${terms[1] + terms[2]}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$k + \\frac{${p}+${q}k}{k} = ${terms[1] + terms[2]}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${kPoly.toString()}=0$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$k=${roots}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$u_3=\\frac{${p}+${q}(\\frac{${p}+${q}k}{k})}{\\frac{${p}+${q}k}{k}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=\\frac{${p + q ** 2}k+${q * p}}{${p}+${q}k}$`)
      );
      question.addHeading("solution", MCMaths.cleaner(`Using $k=${roots[0]}$`));
      question.addParagraph("solution", MCMaths.cleaner(`$u_3=${k0}$`));
      question.addHeading("solution", MCMaths.cleaner(`Using $k=${roots[1]}$`));
      question.addParagraph("solution", MCMaths.cleaner(`$u_3=${k1}$`));
      question.addHeading(
        "solution",
        MCMaths.cleaner(`$\\therefore$ Max $u_3=${u3}$`)
      );

      // part b
      question.addHeading(
        "solution",
        `b) Using your answer from part a, find $\\displaystyle\\sum_{n=1}^{3}u_n$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\sum_{n=1}^{3}u_n=(u_1+u_2)+u_3$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=(${terms[1] + terms[2]})+${u3}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${terms[1] + terms[2] + u3}$`)
      );

      // answers
      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(u3, 0, "a)");
      g1.addInput(terms[1] + terms[2] + u3, 0, "b)");
      question.addInputGroup(g1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_Exam_Q13() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(2, 10);
    // const formula = new MCMaths.PartialFraction([0, 1], [1, 0], [0, 1]);

    const n = MCRandom.randomInt(11, 39, 2);
    const sum_n = MCRandom.randomInt(a * 6, a * 20, 2 * a);

    // const series = new MCMaths.Recurrence(formula, a);
    const b_ans = (sum_n / 2) * (a + 1 / a);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        `The $n$th term of a sequence is $u_n$. The sequence is defined by:`
      );
      question.addParagraph(
        "question",
        `$u_{n+1}=\\frac{1}{u_n}$ , $u_1=${a}$`
      );
      question.addParagraph("question", `a) Find $u_{${n}}$`);
      question.addParagraph(
        "question",
        `b) Find $\\displaystyle\\sum_{n=1}^{${sum_n}}u_n$`
      );
      // Walkthrough a
      question.addHeading("solution", `a) Find $u_{${n}}$`);
      question.addParagraph("solution", `$u_{n+1}=\\frac{1}{u_n}$`);
      question.addParagraph("solution", `$u_1,u_2,u_3,...$`);
      question.addHeading("solution", `Given $u_1=${a}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${a},${1 / a},${a},...$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Sequence is periodic, of order $2$`
      );
      question.addParagraph("solution", `Odd numbered terms $=${a}$`);
      question.addParagraph("solution", `$\\therefore u_{${n}}=${a}$`);
      // walkthrough b
      question.addHeading(
        "solution",
        `b) Find $\\displaystyle\\sum_{n=1}^{${sum_n}}u_n$`
      );
      question.addParagraph(
        "solution",
        `As the sequence is periodic of order 2`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=1}^{${sum_n}}u_n$=$${
          sum_n / 2
        }\\displaystyle\\sum_{n=1}^{2}u_n$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${sum_n / 2}(${a}+${1 / a})$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${b_ans}$`));
      // answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(a, 0, "a)");
      g1.addInput(b_ans, 0, "b)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_Exam_Q14() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(3, 12);
    const p = a - 1;
    // const formula = new MCMaths.PartialFraction([1, 0], [1, -p], [0, 1]);
    const r = MCRandom.randomInt(1, 14);

    const sum_n = MCRandom.randomInt(50, 100, 5);

    // const series = new MCMaths.Recurrence(formula, a);

    /* END VARIABLES AND CONSTANTS */
    if (r + 1 > a || r - 1 < a) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        `The $n$th term of a sequence is $u_n$. The sequence is defined by:`
      );
      question.addParagraph(
        "question",
        `$u_{n+1}=\\frac{u_n}{u_n-${p}}$ , $u_1=k$`
      );
      question.addParagraph("question", `Given $u_2$=$u_3$ and $k\\neq 0$`);
      question.addParagraph("question", `a) Find $k$`);
      question.addParagraph(
        "question",
        `Given also $\\displaystyle\\sum_{n=1}^{${sum_n}}(u_n-r)=${
          sum_n * (a - r)
        }$`
      );
      question.addParagraph("question", `b) Find $r$`);
      // Walkthrough a
      question.addHeading("solution", `a) Find $k$`);
      question.addParagraph("solution", `$u_{n+1}=\\frac{u_n}{u_n-${p}}$`);
      question.addHeading("solution", `Given $u_1=k$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_2=\\frac{k}{k-${p}}$`)
      );
      question.addParagraph(
        "solution",
        `$u_3=\\frac{\\frac{k}{k-${p}}}{\\frac{k}{k-${p}}-${p}}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=\\frac{k}{${p * p}+${1 - p}k}$`)
      );
      question.addHeading("solution", `Given $u_2=u_3$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{k}{k-${p}}=\\frac{k}{${p * p}+${1 - p}k}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$k(${p * p}+${1 - p}k)=k(k-${p})$`)
      );
      question.addHeading("solution", `Given $k\\neq0$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${p * p}+${1 - p}k=k-${p}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore k=${a}$`)
      );
      // walkthrough b
      question.addHeading("solution", `b) Find $r$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=1}^{${sum_n}}(u_n-r)=${sum_n * (a - r)}$`
      );
      question.addParagraph(
        "solution",
        `As the sequence is periodic of order $1$, $u_1=u_n$ , for every $n$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\displaystyle\\sum_{n=1}^{${sum_n}}(u_n-r)=${sum_n}(u_1-r)$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${sum_n}(${a}-r)=${sum_n * (a - r)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore r=${r}$`)
      );
      // answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(a, 0, "a) $\\ k =$");
      g1.addInput(r, 0, "b) $\\ r =$");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_Exam_Q2() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here//dadad
    const a = MCRandom.randomInt(1, 8) * 4;
    const q = MCRandom.randomInt(1, 7) / 8;
    const p = MCRandom.randomInt(-16, 16);
    const formula = new MCMaths.Polynomial([q, p], `x`);
    const limit = p / (1 - q); // x(1-q) = p
    const N = MCRandom.randomInt(20, 50);

    const k = (N - p - (p + q * p)) / (1 + q + q ** 2);

    const series = new MCMaths.Recurrence(formula, a);
    /* END VARIABLES AND CONSTANTS */
    if (
      series.term(2) !== 0 &&
      series.term(3) !== 0 &&
      series.term(3) !== limit &&
      p !== 0 &&
      k !== limit &&
      Math.ceil(k) !== limit
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        `The $n$th term of a sequence is $u_n$. The sequence is defined by:`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$u_{n+1}=${p}+${q}u_n$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Given $u_1 = k$ and $\\displaystyle\\sum_{n=1}^{3}u_n \\geq ${N}, k\\in\\mathbb{Z}$`
        )
      );
      question.addParagraph(
        "question",
        `a) Find the smallest possible value of $k$`
      );
      question.addParagraph("question", `b) Find the limit of the sequence`);
      // Walkthrough a
      question.addHeading(
        "solution",
        `a) Find the smallest possible value of $k$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_{n+1}=${p}+${q}u_n$`)
      );
      question.addHeading("solution", MCMaths.cleaner(`Given $u_1=k$`));
      question.addParagraph("solution", MCMaths.cleaner(`$u_2=${p}+${q}k$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_3=${p}+${q}(${p}+${q}k)$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${p + q * p}+${q ** 2}k$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given $\\displaystyle\\sum_{n=1}^{3}u_n\\geq${N}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_1+u_2+u_3\\geq${N}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$k+(${p}+${q}k)+(${p + q * p}+${q ** 2}k)\\geq${N}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$k \\geq ${k}$`));
      question.addParagraph("solution", `$\\therefore k = ${Math.ceil(k)}$`);
      // walkthrough b
      question.addHeading("solution", `b) Find the limit of the sequence`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_{n+1}=${p}+${q}u_n$`)
      );
      question.addHeading("solution", `Let Limit = $L$`);
      question.addParagraph("solution", MCMaths.cleaner(`$L=${p}+${q}L$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore L=${limit}$`)
      );
      // answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(Math.ceil(k), 0, `a) $\\ k=$`);
      g1.addInput(limit, 0, "b)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_Exam_Q3() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(1, 8);
    const q = MCRandom.randomInt(2, 6);
    const p = MCRandom.randomInt(-8, 8);
    const formula = new MCMaths.Polynomial([q, p], `x`);

    const series = new MCMaths.Recurrence(formula, a);
    const poly = new MCMaths.Polynomial(
      [a, p + a, p + p - series.sumBetween(2, 3)],
      "q"
    );
    const roots = poly.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      series.term(2) !== 0 &&
      series.term(3) !== 0 &&
      p !== 0 &&
      roots[0] === q &&
      p + p < series.sumBetween(2, 3)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form question
      question.addParagraph(
        "question",
        `The $n$th term of a sequence is $u_n$. The sequence is defined by:`
      );
      question.addParagraph("question", `$u_{n+1}=${p}+qu_n$ , $q>0$`);
      question.addParagraph(
        "question",
        `Given $u_1=${a}$ and $u_2+u_3=${series.sumBetween(2, 3)}$`
      );
      question.addParagraph("question", `a) Find $q$`);
      question.addParagraph(
        "question",
        `b) Find $\\displaystyle\\sum_{n=1}^{4}u_n$`
      );
      // Walkthrough a
      question.addHeading("solution", `a) Find $q$`);
      question.addParagraph("solution", `$u_{n+1}=${p}+qu_n$`);
      question.addHeading("solution", `Given $u_1=${a}$`);
      question.addParagraph("solution", `$u_2 =${p}+${a}q$`);
      question.addParagraph("solution", `$u_3 =${p}+q(${p}+${a}q)$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${a}q^2+${p}q+${p}$`)
      );
      question.addHeading(
        "solution",
        `Given $u_2+u_3 = ${series.sumBetween(2, 3)}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${a}q^2+${p + a}q+${p + p}=${series.sumBetween(2, 3)}$`
        )
      );
      question.addParagraph("solution", `$${poly.toString()}=0$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore q=${roots[0]},q\\neq${roots[1]}$`)
      );
      // Walkthrough b
      question.addHeading(
        "solution",
        `b) Find $\\displaystyle\\sum_{n=1}^{4}u_n$`
      );
      question.addHeading("solution", `Using $u_3 =${p}+q(${p}+${a}q)$`);
      question.addParagraph("solution", `$u_3 =${p}+${q}[${p}+${a}(${q})]$`);
      question.addParagraph("solution", `$\\therefore u_3=${series.term(3)}$`);
      question.addParagraph("solution", `$u_{n+1}=${p}+${q}u_n$`);
      question.addParagraph("solution", `$u_4 =${p}+${q}(${series.term(3)})$`);
      question.addParagraph("solution", `$= ${series.term(4)}$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=1}^{4}u_n = u_1 + (u_2 + u_3) + u_4$`
      );
      question.addParagraph(
        "solution",
        `$= ${a}+${series.sumBetween(2, 3)}+${series.term(4)}$`
      );
      question.addParagraph("solution", `$=${series.sum(4)}$`);
      // answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(q, 0, "a) $\\ q=$");
      g1.addInput(series.sum(4), 0, "b)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_Exam_Q4() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(-8, 8);
    const q = MCRandom.randomInt(2, 4);
    const p = MCRandom.randomInt(-8, 8);
    const formula = new MCMaths.Polynomial([q, p], `x`);

    const series = new MCMaths.Recurrence(formula, a);
    const sum_2_4 = series.sumBetween(2, 4);

    /* END VARIABLES AND CONSTANTS */
    if (series.term(2) !== 0 && series.term(3) !== 0 && p !== 0 && a !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form question
      question.addParagraph(
        "question",
        `The $n$th term of a sequence is $u_n$. The sequence is defined by:`
      );
      question.addParagraph("question", `$u_{n+1}=p+${q}u_n$`);
      question.addParagraph(
        "question",
        `Given $u_1=k$ , $\\displaystyle\\sum_{n=2}^{4}u_n=${sum_2_4}$ and $u_5 = ${series.term(
          5
        )}$`
      );
      question.addParagraph("question", `Find $p$ and $k$`);
      // walkthrough
      question.addHeading("solution", `a) Find $p$ and $k$`);
      question.addParagraph("solution", MCMaths.cleaner(`$u_{n+1}=p+${q}u_n$`));
      question.addHeading("solution", MCMaths.cleaner(`Given $u_1=k$`));
      question.addParagraph("solution", MCMaths.cleaner(`$u_2=p+${q}k$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_3=p+${q}(p+${q}k)$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${1 + q}p+${q * q}k$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_4=p+${q}(${1 + q}p+${q * q}k)$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${1 + (1 + q) * q}p+${q ** 3}k$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_5=p+${q}(${1 + (1 + q) * q}p+${q ** 3}k)$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${1 + q * (1 + (1 + q) * q)}p+${q ** 4}k$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given $\\displaystyle\\sum_{n=2}^{4}u_n=${sum_2_4}$`)
      );
      question.addParagraph("solution", `$u_2 + u_3 + u_4 = ${sum_2_4}$`);
      question.addParagraph(
        "solution",
        `$(p+${q}k) + (${1 + q}p+${q * q}k) + (${1 + (1 + q) * q}p+${
          q ** 3
        }k) = ${sum_2_4}$`
      );
      question.addParagraph(
        "solution",
        `$${3 + q + (1 + q) * q}p + ${q + q * q + q ** 3}k = ${sum_2_4}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore k = ${sum_2_4 / (q + q * q + q ** 3)}-${
            (3 + q + (1 + q) * q) / (q + q * q + q ** 3)
          }p$`
        )
      );
      question.addHeading("solution", `Given $u_5 = ${series.term(5)}$`);
      question.addParagraph(
        "solution",
        `$${1 + q * (1 + (1 + q) * q)}p+${q ** 4}k = ${series.term(5)}$`
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Using $k = ${sum_2_4 / (q + q * q + q ** 3)}-${
            (3 + q + (1 + q) * q) / (q + q * q + q ** 3)
          }p$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${1 + q * (1 + (1 + q) * q)}p+${q ** 4}(${
            sum_2_4 / (q + q * q + q ** 3)
          }-${(3 + q + (1 + q) * q) / (q + q * q + q ** 3)}p) = ${series.term(
            5
          )}$`
        )
      );
      question.addParagraph("solution", `$\\therefore p =${p}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$k = ${sum_2_4 / (q + q * q + q ** 3)}-${
            (3 + q + (1 + q) * q) / (q + q * q + q ** 3)
          }(${p})$`
        )
      );
      question.addParagraph("solution", `$\\therefore k =${a}$`);
      // Answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(p, 0, "$p$=");
      g1.addInput(a, 0, "$k$=");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_Exam_Q5() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(-4, -1) * 4;
    const q = MCRandom.randomInt(1, 7) / 8;
    const p = MCRandom.randomInt(1, 16);
    const formula = new MCMaths.Polynomial([q, p, 0], `x`);
    const limit = (1 - p) / q;

    const series = new MCMaths.Recurrence(formula, a);
    const u1_polynomial = new MCMaths.Polynomial([q, p, -series.term(2)], "x");
    const roots = u1_polynomial.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      series.term(2) !== 0 &&
      series.term(3) !== 0 &&
      series.term(3) < 10000 &&
      series.term(3) !== limit &&
      limit !== 0 &&
      roots[0] > roots[1] &&
      series.sum(3) % 1 === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // form q
      question.addParagraph(
        "question",
        `The $n$th term of a sequence is $u_n$. The sequence is defined by:`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$u_{n+1}=u_n(p+${q}u_n)$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`The $2$nd term is given by, $u_2=${series.term(2)}$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`The limit of the sequence is $${limit}$.`)
      );
      question.addParagraph("question", `a) Find $p$`);
      question.addParagraph(
        "question",
        `b) Find the largest possible value of $\\displaystyle\\sum_{n=1}^{3}u_n$`
      );
      // walkthrough a
      question.addHeading("solution", `a) Find $p$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$u_{n+1}=u_n(p+${q}u_n)$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`Given Limit = $${limit}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${limit} = ${limit}[p+${q}(${limit})]$`)
      );
      question.addParagraph("solution", `$\\therefore p=${p}$`);
      // walkthrough b
      question.addHeading(
        "solution",
        `b) Find the largest possible value of $\\displaystyle\\sum_{n=1}^{3}u_n$`
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given $u_2=${series.term(2)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$u_3 =${series.term(2)}[${p}+${q}(${series.term(2)})]$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${series.term(3)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${series.term(2)}=u_1(${p}+${q}u_1)$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${q}(u_{1})^{2}+${p}u_1-${series.term(2)}=0$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$u_1=${roots}$`));
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Use $u_1=${roots[0]}$ for larger $\\displaystyle\\sum_{n=1}^{3}u_n$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\sum_{n=1}^{3}u_n = u_1+u_2+u_3$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle\\sum_{n=1}^{3}u_n = ${roots[0]}+${series.term(
            2
          )}+${series.term(3)}$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${series.sum(3)}$`));
      // Answers
      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(p, 0, "a) $ \\ p=$");
      g1.addInput(series.sum(3), 0, "b)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_Exam_Q6() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(2, 8);
    const p = a ** 2;
    const formula = new MCMaths.Polynomial([1, 0, -p], `x`);

    const series = new MCMaths.Recurrence(formula, a);
    const k_poly_display = new MCMaths.Polynomial(
      [1, 0, -2 * p, 0, p * p - p],
      "k"
    );
    const k_poly = new MCMaths.Polynomial([1, -2 * p + 1, p * p - p], "X");
    const roots = k_poly.roots();
    /* END VARIABLES AND CONSTANTS */
    if (p !== 0 && roots[0] > roots[1]) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        `The $n$th term of a sequence is $u_n$. The sequence is defined by:`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$${series.toString()}$ where $u_1=k$ , $k\\in\\mathbb{Z}^+$`
        )
      );
      question.addParagraph(
        "question",
        `Given $u_2+u_3=${series.sumBetween(2, 3)}$`
      );
      question.addParagraph("question", `a) Find $k$`);
      question.addParagraph(
        "question",
        `b) Find $\\displaystyle\\sum_{n=1}^{4}u_n-\\displaystyle\\sum_{n=2}^{3}u_n$`
      );
      // walkthrough a
      question.addHeading("solution", `a) Find $k$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${series.toString()}$`)
      );
      question.addHeading("solution", `Given $u_1=k$`);
      question.addParagraph("solution", `$u_2=k^2-${p}$`);
      question.addParagraph("solution", `$u_3=(k^2-${p})^2-${p}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${k_poly_display.toString()}$`)
      );
      question.addHeading(
        "solution",
        `Given $u_2+u_3=${series.sumBetween(2, 3)}$`
      );
      question.addParagraph(
        "solution",
        `$(k^2-${p}) + (${k_poly_display.toString()}) = ${series.sumBetween(
          2,
          3
        )}$`
      );
      question.addHeading("solution", `Let $k^2=X$`);
      question.addParagraph("solution", `$${k_poly.toString()}=0$`);
      question.addParagraph("solution", MCMaths.cleaner(`$X=${roots}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore k^2=${roots}$`)
      );
      question.addParagraph(
        "solution",
        `$k=${a}, k\\neq${-a},\\pm\\sqrt${
          roots[1]
        }$ as $k$ is a positive integer`
      );
      // part b
      question.addHeading(
        "solution",
        `b) Find $\\displaystyle\\sum_{n=1}^{4}u_n-\\displaystyle\\sum_{n=2}^{3}u_n$`
      );
      question.addParagraph("solution", `$u_1=${a}$`);
      question.addParagraph("solution", `$u_2=${a}^2-${a ** 2}$`);
      question.addParagraph("solution", `$=0$`);
      question.addParagraph("solution", `$\\therefore u_3=${series.term(3)}$`);
      question.addParagraph(
        "solution",
        `$u_4=(${series.term(3)})^2-${a ** 2}$`
      );
      question.addParagraph("solution", `$u_4=${series.term(4)}$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=1}^{4}u_n-\\displaystyle\\sum_{n=2}^{3}u_n=(u_1+u_2+u_3+u_4) - (u_2+u_3)$`
      );
      question.addParagraph("solution", `$=u_1+u_4$`);
      question.addParagraph("solution", `$=${a}+${series.term(4)}$`);
      question.addParagraph("solution", `$=${a + series.term(4)}$`);
      // answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(a, 0, "a) $\\ k=$");
      g1.addInput(a + series.term(4), 0, "b)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_Exam_Q7() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(2, 10);
    const p = a ** 2 - (a - 1) + 2 * a * MCRandom.randomInt(0, 1);
    const formula = new MCMaths.Polynomial([1, -2 * p, p ** 2], `x`);

    const n = MCRandom.randomInt(10, 20) * 10;

    const series = new MCMaths.Recurrence(formula, a ** 2);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        `The $n$th term of a sequence is $u_n$. The sequence is defined by:`
      );
      question.addParagraph(
        "question",
        `$u_{n+1}=(u_n-${p})^2$ , $u_1=${a ** 2}$`
      );
      question.addParagraph("question", `a) Find $u_{${n}}$`);
      question.addParagraph(
        "question",
        `b) Find $\\displaystyle\\sum_{n=1}^{${n}}u_n$`
      );
      // Walkthrough a
      question.addHeading("solution", `a) Find $u_{${n}}$`);
      question.addParagraph("solution", `$u_{n+1}=(u_n-${p})^2$`);
      question.addParagraph("solution", `$u_1,u_2,u_3,u_4,...$`);
      question.addHeading("solution", `Given $u_1=${a ** 2}$`);
      question.addParagraph("solution", `$=${series.terms(1, 4)},...$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ Sequence is periodic, of order $2$`
      );
      question.addParagraph(
        "solution",
        `Even numbered terms $=${series.term(2)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore u_{${n}}=${series.term(n)}$`
      );
      // walkthrough b
      question.addHeading(
        "solution",
        `b) Find $\\displaystyle\\sum_{n=1}^{${n}}u_n$`
      );
      question.addParagraph(
        "solution",
        `As the sequence is periodic of order 2`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=1}^{${n}}u_n$=$${
          n / 2
        }\\displaystyle\\sum_{n=1}^{2}u_n$`
      );
      question.addParagraph(
        "solution",
        `$=${n / 2}(${a ** 2}+${series.term(2)})$`
      );
      question.addParagraph("solution", `$=${series.sum(n)}$`);
      // answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(series.term(n), 0, "a)");
      g1.addInput(series.sum(n), 0, "b)");
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_Exam_Q8() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(4, 7) * 125;
    const q = MCRandom.randomInt(1, 4) / 5;
    const p = MCRandom.randomInt(9, 12) * 125;

    const formula = new MCMaths.Polynomial([q, p], `x`);
    const series = new MCMaths.Recurrence(formula, a);

    const salary = MCRandom.randomInt(20, 30) * 1000;
    const person = MCMisc.getName();
    const terms = [
      "fill",
      a,
      series.term(2),
      series.term(3),
      series.term(4),
      series.term(5),
    ];
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        `The amount of money ${person.name} receives as a bonus at the end of the year depends on how much ${person.heshe} received for the previous year, calculated using the formula below.`
      );
      question.addParagraph("question", `$b_{n+1} = X + Yb_n$`);
      question.addParagraph(
        "question",
        `Where $b_n$ is ${person.hisher} bonus at the end of year $n$`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Given ${person.heshe} received bonuses of $£${series
            .terms(2, 3)
            .replace(/,/g, ",£")}$ and $£${
            terms[4]
          }$ in years $2, 3$ and $4$ respectively`
        )
      );
      question.addParagraph("question", `a) Find $X$ and $Y$`);
      question.addParagraph(
        "question",
        `${person.name} has a salary of $£${salary}$ that doesn't change in ${person.hisher} first $5$ years with the company`
      );
      question.addParagraph(
        "question",
        `b) Find how much ${person.name} earned in ${person.hisher} first $5$ years`
      );
      // walkthrough a
      question.addHeading("solution", `a) Find $X$ and $Y$`);
      question.addParagraph("solution", `$b_{n+1} = X + Yb_n$`);
      question.addHeading(
        "solution",
        `Given $b_2=${terms[2]}$ and $b_3=${terms[3]}$`
      );
      question.addParagraph("solution", `$${terms[3]}=X + Y(${terms[2]})$`);
      question.addParagraph(
        "solution",
        `$\\therefore X=${terms[3]}-${terms[2]}Y$`
      );
      question.addHeading("solution", `Given $b_4=${terms[4]}$`);
      question.addParagraph("solution", `$${terms[4]}=X + Y(${terms[3]})$`);
      question.addHeading("solution", `Using $X=${terms[3]}-${terms[2]}Y$`);
      question.addParagraph(
        "solution",
        `$${terms[4]}=(${terms[3]}-${terms[2]}Y)+${terms[3]}Y$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore Y=${q}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$X=${terms[3]}-${terms[2]}(${q})$`)
      );
      question.addParagraph("solution", `$\\therefore X=${p}$`);
      // walkthrough b
      question.addHeading(
        "solution",
        `b) Find how much ${person.name} earned in ${person.hisher} first $5$ years`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$b_{n+1} = ${p} + ${q}b_n$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${terms[2]}=${p} + ${q}b_1$`)
      );
      question.addParagraph("solution", `$\\therefore b_1=${a}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$b_5=${p} + ${q}(${terms[4]})$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore b_5=${terms[5].toFixed(2)}$`
      );
      question.addHeading("solution", `Sum of Bonuses`);
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=1}^{5}b_n=u_1+u_2+...+u_5$`
      );
      question.addParagraph(
        "solution",
        `$=${terms[1]}+${terms[2]}+${terms[3]}+${terms[4]}+${terms[5].toFixed(
          2
        )}$`
      );
      question.addParagraph("solution", `$=${series.sum(5).toFixed(2)}$`);
      question.addHeading("solution", `Sum of Salaries`);
      question.addParagraph(
        "solution",
        `$\\displaystyle\\sum_{n=1}^{5}${salary}= 5(${salary})$`
      );
      question.addParagraph("solution", `$=${salary * 5}$`);
      question.addHeading("solution", `Sum Total`);
      question.addParagraph(
        "solution",
        `$=${series.sum(5).toFixed(2)}+${salary * 5}$`
      );
      question.addParagraph(
        "solution",
        `$=£${(series.sum(5) + salary * 5).toFixed(2)}$`
      );
      // answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(p, 0, "a) $\\ X$=");
      g1.addInput(q, 0, "a) $\\ Y$=");
      g1.addInput(series.sum(5) + salary * 5, 0, "b) $\\ £$");
      question.addInputGroup(g1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_Exam_Q9() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(7, 12) * 512;
    const q = MCRandom.randomInt(2, 7) / 8;
    const p = MCRandom.randomInt(1, 4) * 512;
    const limit = p / (1 - q);

    const formula = new MCMaths.Polynomial([q, p], `x`);
    const series = new MCMaths.Recurrence(formula, a);

    const terms = [
      "fill",
      a,
      series.term(2),
      series.term(3),
      series.term(4),
      series.term(5),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (terms[2] < terms[1] && limit % 1 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // form q
      question.addParagraph(
        "question",
        `An out of control rabbit population is controlled by introducing more foxes to the local area. Every month, volunteers estimate the size of the population and find their estimates satisfy the realtionship:`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$R_{n+1}=A + ${q}R_n$`)
      );
      question.addParagraph(
        "question",
        `Where $R_n$ is the estimated population of rabbits for month $n$`
      );
      question.addParagraph(
        "question",
        `Given $R_2+R_3=${terms[2] + terms[3]}$ and $R_4=${terms[4]}$`
      );
      question.addParagraph("question", `a) Find $R_1$ and $A$`);
      question.addParagraph(
        "question",
        `b) Find the lower limit of the rabbit population, according to the volunteers' model`
      );
      // walthrough a
      question.addHeading("solution", `a) Find $R_1$ and $A$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$R_{n+1}=A + ${q}R_n$`)
      );
      question.addHeading("solution", `Let $R_1=k$`);
      question.addParagraph("solution", MCMaths.cleaner(`$R_2=A + ${q}k$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$R_3=A + ${q}(A + ${q}k)$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${1 + q}A+${q * q}k$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$R_4=A + ${q}(${1 + q}A+${q * q}k)$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${1 + (1 + q) * q}A+${q ** 3}k$`)
      );
      question.addHeading("solution", `Given $R_2+R_3=${terms[2] + terms[3]}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(A + ${q}k)+(${1 + q}A+${q * q}k)=${terms[2] + terms[3]}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore k=${((terms[2] + terms[3]) * 1) / (q * q + q)}-${
            ((2 + q) * 1) / (q * q + q)
          }A$`
        )
      );
      question.addHeading("solution", `Given $R_4=${terms[4]}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${1 + (1 + q) * q}A+${q ** 3}k=${terms[4]}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Using $k=${((terms[2] + terms[3]) * 1) / (q * q + q)}-${
            ((2 + q) * 1) / (q * q + q)
          }A$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${1 + (1 + q) * q}A+${q ** 3}(${
            ((terms[2] + terms[3]) * 1) / (q * q + q)
          }-${((2 + q) * 1) / (q * q + q)}A)=${terms[4]}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore A=${p}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$k=${((terms[2] + terms[3]) * 1) / (q * q + q)}-${
            ((2 + q) * 1) / (q * q + q)
          }(${p})$`
        )
      );
      question.addParagraph("solution", `$=${a}$`);
      question.addParagraph("solution", `$\\therefore R_1=${a}$`);
      // walkthrough b
      question.addHeading(
        "solution",
        `b) Find the lower limit of the rabbit population, according to the volunteers' model`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$R_{n+1}=${p} + ${q}R_n$`)
      );
      question.addHeading("solution", `Let lower limit $= L$`);
      question.addParagraph("solution", MCMaths.cleaner(`$L=${p} + ${q}L$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore L=${limit}$`)
      );
      // answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(a, 0, `a) $\\ R_1$=`);
      g1.addInput(p, 0, `a) $\\ A$=`);
      g1.addInput(limit, 0, `b)`);
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_MCQ_Q1() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const c1 = MCRandom.randomInt(2, 10);
    const c2 = MCRandom.randomInt(-10, -2);
    const offset = MCRandom.randomInt(-20, 20);
    /* END VARIABLES AND CONSTANTS */
    if (c1 !== -c2 && offset !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      // Form q
      question.addParagraph(
        MCMaths.cleaner(`A recurrence relation is defined by`)
      );
      question.addParagraph(
        MCMaths.cleaner(
          `$u_{n+1} = u_{n}^{2}+${c1 + c2}u_n+${
            c1 * c2 + offset
          }$ , $u_2=${offset}$`
        )
      );
      question.addParagraph(
        MCMaths.cleaner(
          `Which of the following are possible values for terms in the series`
        )
      );
      question.addHeading("Choose TWO");
      // Answers
      // create our first answer
      const a1 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a1.addParagraph(`$u_1=${c1}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a2.addParagraph(`$u_1=${c2}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a3.addParagraph(`$u_1=${-c1}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a4.addParagraph(`$u_1=${-c2}$`);

      // add the answers to the question
      question.addAnswer(a1, true);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_MCQ_Q2() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(3, 12);
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      // Form q
      question.addParagraph(
        MCMaths.cleaner(`Which of the following describe the sequence`)
      );
      question.addParagraph(
        MCMaths.cleaner(`$u_{n+1}=\\frac{1}{u_n}$ , $u_1=${a}$`)
      );
      // Answers
      // create our first answer
      const a1 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a1.addParagraph(`Increasing`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a2.addParagraph(`Decreasing`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a3.addParagraph(`Periodic, Order 1`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a4.addParagraph(`Periodic, Order 2`);

      // add the answers to the question
      question.addAnswer(a1, false);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, true);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_SequencesAndSeries_Recurrence_MCQ_Q3() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p = MCRandom.randomInt(3, 12);
    const not_1 = MCRandom.randomInt(-5, 5);
    const frac_u1 = MCRandom.randomInt(3, 12);
    const alg_u1 = MCRandom.randomInt(3, 12);

    const c1 = MCRandom.randomInt(3, 12);
    const c2 = MCRandom.randomInt(3, 12);
    /* END VARIABLES AND CONSTANTS */
    if ((not_1 > 1 || not_1 < -1) && c1 !== c2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      // Form q
      question.addParagraph(
        MCMaths.cleaner(`Which of the following sequences are periodic`)
      );
      question.addHeading("Choose TWO");
      // Answers
      // create our first answer
      const a1 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a1.addParagraph(`$u_{n+1}=\\frac{u_n}{u_n-${p}}$ , $u_1=${p + 1}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a2.addParagraph(`$u_n=\\sin(n\\pi)$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a3.addParagraph(`$u_{n+1}=\\frac{${not_1}}{u_n}$ , $u_1=${frac_u1}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      // can add things to the answer just like in normal questions
      a4.addParagraph(`$u_{n+1}=(u_n)^{u_n-${alg_u1}}$ , $u_1=${alg_u1}$`);

      // add the answers to the question
      question.addAnswer(a1, true);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      return question.makeQuestion();
    }
  }
}


export function A2_Trigonometry_DoubleAngleFormulae_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(2, 9);
    const a = 2 * alpha;

    const working1 = new MCMaths.Fraction(beta / a);

    const theta_1 = Math.asin(working1.toFloat());
    const theta_2 = Math.PI - theta_1;

    /* END VARIABLES AND CONSTANTS */
    if (working1.toFloat() < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Express $${alpha}\\sin(2\\theta)$ in the form $a\\sin(\\theta)\\cos(\\theta)$ where $a$ is a constant to be found.`
      );
      question.addParagraph("question", `Given that $\\cos(\\theta) \\neq 0$,`);
      question.addParagraph(
        "question",
        `b) Solve the following equation: $\\displaystyle \\frac{${alpha}\\sin(2\\theta)}{\\cos(\\theta)} = ${beta}, \\text{ } 0 < \\theta < \\pi$`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures where appropriate.`
      );

      question.addParagraph(
        "solution",
        `a) $${alpha}\\sin(2\\theta) = ${alpha}(2\\sin(\\theta)\\cos(\\theta))$`
      );
      question.addParagraph("solution", `$= ${a}\\sin(\\theta)\\cos(\\theta)$`);
      question.addParagraph("solution", `$\\therefore a = ${a}$`);

      question.addParagraph(
        "solution",
        `b) $\\displaystyle \\frac{${alpha}\\sin(2\\theta)}{\\cos(\\theta)} = ${beta}$`
      );
      question.addParagraph("solution", `Using part a) the equation becomes:`);
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{${a}\\sin(\\theta)\\cos(\\theta)}{\\cos(\\theta)} = ${beta}$`
      );
      question.addParagraph("solution", `$${a}\\sin(\\theta) = ${beta}$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle \\sin(\\theta) = ${working1.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\sin^{-1}\\left(${working1.toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${theta_1.toPrecision(3)}$`
      );
      question.addParagraph(
        "solution",
        `Also $\\displaystyle \\theta = \\pi - \\sin^{-1}\\left(${working1.toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${theta_2.toPrecision(3)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = ${theta_1.toPrecision(
          3
        )}, \\text{ } \\theta = ${theta_2.toPrecision(3)}$.`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(a, 0, `a) $\\text{ } a =$`);
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        [
          parseFloat(theta_1.toPrecision(3)),
          parseFloat(theta_2.toPrecision(3)),
        ],
        0,
        `b) $\\text{ } \\theta =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_DoubleAngleFormulae_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(2, 9);
    const a = 2 * alpha;

    const working1 = new MCMaths.Fraction(beta / a);

    const theta_1 = Math.acos(Math.sqrt(working1.toFloat()));
    const theta_2 = -theta_1;
    const theta_3 = Math.acos(-Math.sqrt(working1.toFloat()));
    const theta_4 = -theta_3;

    const partBAnswerArray = [
      parseFloat(theta_1.toPrecision(3)),
      parseFloat(theta_2.toPrecision(3)),
      parseFloat(theta_3.toPrecision(3)),
      parseFloat(theta_4.toPrecision(3)),
    ];
    /* END VARIABLES AND CONSTANTS */
    if (working1.toFloat() < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Express $${alpha}\\cos(2\\theta)$ in the form $a\\cos^{2}(\\theta) - ${alpha}$`
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, solve the following equation:`
      );
      question.addParagraph(
        "question",
        `$${alpha}\\cos(2\\theta) + ${alpha} = ${beta}, \\text{ } -\\pi < \\theta < \\pi$`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures where appropriate.`
      );

      question.addParagraph(
        "solution",
        `a) $${alpha}\\cos(2\\theta) = ${alpha}(\\cos^{2}(\\theta) - \\sin^{2}(\\theta))$`
      );
      question.addParagraph(
        "solution",
        `$${alpha}\\cos^{2}(\\theta) - ${alpha}(1 - \\cos^{2}(\\theta))$`
      );
      question.addParagraph("solution", `$${a}\\cos^{2}(\\theta) - ${alpha}$`);
      question.addParagraph("solution", `$\\therefore a = ${a}$`);

      question.addParagraph(
        "solution",
        `b) $${alpha}\\cos(2\\theta) + ${alpha} = ${beta}$`
      );
      question.addParagraph("solution", `Using part a) the equation becomes:`);
      question.addParagraph(
        "solution",
        `$${a}\\cos^{2}(\\theta) - ${alpha} + ${alpha} = ${beta}$`
      );
      question.addParagraph("solution", `$${a}\\cos^{2}(\\theta) = ${beta}$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos^{2}(\\theta) = ${working1.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos(\\theta) = \\pm \\sqrt{${working1.toString()}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta =\\cos^{-1} \\left( \\pm \\sqrt{${working1.toString()}} \\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta =\\cos^{-1} \\left(  \\sqrt{${working1.toString()}} \\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${theta_1.toPrecision(
          3
        )}, \\text{ } \\theta = ${theta_2.toPrecision(3)}$`
      );
      question.addParagraph(
        "solution",
        `Also $\\displaystyle \\theta =\\cos^{-1} \\left( -\\sqrt{${working1.toString()}} \\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${theta_3.toPrecision(
          3
        )}, \\text{ } \\theta = ${theta_4.toPrecision(3)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = ${theta_1.toPrecision(
          3
        )}, \\text{ } \\theta = ${theta_2.toPrecision(
          3
        )}, \\text{ } \\theta = ${theta_3.toPrecision(
          3
        )}, \\text{ } \\theta = ${theta_4.toPrecision(3)}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(a, 0, `a) $\\text{ } a=$`);
      const group2 = new MCQuestion.InputGroup(4);
      group2.addInput(partBAnswerArray, 0, `b) $\\text{ } \\theta =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_DoubleAngleFormulae_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(2, 9);

    const a = 4 * alpha;
    const b = 3 * alpha;

    const working1 = new MCMaths.Fraction((beta + b) / a);
    const x1 = Math.acos(Math.sqrt(working1.toFloat()));
    const x2 = -x1;

    /* END VARIABLES AND CONSTANTS */
    if (working1.toFloat() < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Show that $${alpha}\\cos(3x) = a\\cos^{3}(x) - b\\cos(x)$ where $a$ and $b$ are constants to be found.`
      );
      question.addParagraph("question", `Given that $\\cos(x) > 0$,`);
      question.addParagraph("question", `b) Solve the following equation:`);
      question.addParagraph(
        "question",
        `$\\displaystyle \\frac{${alpha}\\cos(3x)}{\\cos(x)} = ${beta}, \\text{ } -\\frac{\\pi}{2} < x < \\frac{\\pi}{2}$`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures where appropriate.`
      );

      question.addParagraph("solution", `a) $\\cos(3x) = \\cos(2x + x)$`);
      question.addParagraph(
        "solution",
        `$\\cos(2x + x) = \\cos(2x)\\cos(x) - \\sin(2x)\\sin(x)$`
      );
      question.addParagraph(
        "solution",
        `$= (\\cos^{2}(x) - \\sin^{2}(x))\\cos(x) - (2\\sin(x)\\cos(x))\\sin(x)$`
      );
      question.addParagraph(
        "solution",
        `$= [\\cos^{2}(x) - (1 - \\cos^{2}(x))]\\cos(x) - 2\\sin^{2}(x)\\cos(x)$`
      );
      question.addParagraph(
        "solution",
        `$= [2\\cos^{2}(x) - 1]\\cos(x) - 2(1 - \\cos^{2}(x))\\cos(x)$`
      );
      question.addParagraph(
        "solution",
        `$= 2\\cos^{3}(x) - \\cos(x) - 2\\cos(x) + 2\\cos^{3}(x)$`
      );
      question.addParagraph("solution", `$= 4\\cos^{3}(x) - 3\\cos(x)$`);
      question.addParagraph(
        "solution",
        `So $\\cos(3x) = 4\\cos^{3}(x) - 3\\cos(x)$`
      );
      question.addParagraph(
        "solution",
        `Hence $${alpha}\\cos(3x) = ${alpha}(4\\cos^{3}(x) - 3\\cos(x))$`
      );
      question.addParagraph("solution", `$ = ${a}\\cos^{3}(x) - ${b}\\cos(x)$`);
      question.addParagraph(
        "solution",
        `$\\therefore a = ${a}, \\text{ } b = ${b}$`
      );

      question.addParagraph(
        "solution",
        `b) $\\displaystyle \\frac{${alpha}\\cos(3x)}{\\cos(x)} = ${beta}$`
      );
      question.addParagraph("solution", `Using part a) the equation becomes:`);
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{${a}\\cos^{3}(x) - ${b}\\cos(x)}{\\cos(x)} = ${beta}$`
      );
      question.addParagraph("solution", `Since $\\cos(x) > 0$:`);
      question.addParagraph(
        "solution",
        `$\\implies ${a}\\cos^{2}(x) - ${b} = ${beta}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos^{2}(x) = \\frac{${beta}+${b}}{${a}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos^{2}(x) = ${working1.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos(x) = \\sqrt{${working1.toString()}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle x = \\cos^{-1}\\left(\\sqrt{${working1.toString()}}\\right)$`
      );
      question.addParagraph("solution", `$x = ${x1.toPrecision(3)}$`);
      question.addParagraph("solution", `Also $x = ${x2.toPrecision(3)}$`);
      question.addParagraph(
        "solution",
        `$\\therefore x = ${x1.toPrecision(3)}, \\text{ } x = ${x2.toPrecision(
          3
        )}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(a, 0, `a) $\\text{ } a =$`);
      group1.addInput(b, 0, `a) $\\text{ } b =$`);
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        [parseFloat(x1.toPrecision(3)), parseFloat(x2.toPrecision(3))],
        0,
        `b) $\\text{ } x =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_DoubleAngleFormulae_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(2, 9);

    const RSquared = alpha ** 2 + alpha ** 2;
    const R = Math.sqrt(RSquared);

    const theta_1 = Math.acos(beta / R) - Math.PI / 4;
    const theta_2 = -theta_1;

    /* END VARIABLES AND CONSTANTS */
    if (beta / R < 1 && theta_1 > 0.01) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The expression $\\displaystyle \\frac{${alpha}\\cos(2\\theta)}{\\sin(\\theta)+\\cos(\\theta)}$ can be written as $a\\cos(\\theta)-b\\sin(\\theta)$`
      );
      question.addParagraph("question", `a) Find the constants $a$ and $b$.`);
      question.addParagraph(
        "question",
        `b) Hence or otherwise solve the following equation:`
      );
      question.addParagraph(
        "question",
        `$\\displaystyle \\frac{${alpha}\\cos(2\\theta)}{\\sin(\\theta)+\\cos(\\theta)} = ${beta}, \\text{ } -\\frac{\\pi}{2} < \\theta < \\frac{\\pi}{2}$`
      );

      question.addParagraph(
        "solution",
        `a) $\\displaystyle \\frac{${alpha}\\cos(2\\theta)}{\\sin(\\theta)+\\cos(\\theta)}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = \\frac{${alpha}(\\cos^{2}(\\theta) - \\sin^{2}(\\theta))}{\\sin(\\theta)+\\cos(\\theta)}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = \\frac{${alpha}(\\cos(\\theta) - \\sin(\\theta))(\\cos(\\theta) + \\sin(\\theta))}{\\sin(\\theta)+\\cos(\\theta)}$`
      );
      question.addParagraph(
        "solution",
        `$= ${alpha}(\\cos(\\theta) - \\sin(\\theta))$`
      );
      question.addParagraph(
        "solution",
        `$= ${alpha}\\cos(\\theta) - ${alpha}\\sin(\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore a = ${alpha}, \\text{ } b = ${alpha}$`
      );

      question.addParagraph(
        "solution",
        `b) $\\displaystyle \\frac{${alpha}\\cos(2\\theta)}{\\sin(\\theta)+\\cos(\\theta)} = ${beta}$`
      );
      question.addParagraph(
        "solution",
        `Using part a) the expression becomes:`
      );
      question.addParagraph(
        "solution",
        `$${alpha}\\cos(\\theta) - ${alpha}\\sin(\\theta) = ${beta}$`
      );
      question.addParagraph(
        "solution",
        `This expression can be solved using harmonic form:`
      );
      question.addParagraph(
        "solution",
        `$R\\cos(\\theta + \\alpha) = ${beta}$`
      );
      question.addParagraph(
        "solution",
        `$R[\\cos(\\alpha)\\cos(\\theta) - \\sin(\\alpha)\\sin(\\theta)] = ${beta}$`
      );
      question.addParagraph(
        "solution",
        `$\\implies R\\cos(\\alpha) = ${alpha}, \\text{ } R\\sin(\\alpha) = ${alpha}$`
      );
      question.addParagraph(
        "solution",
        `So $\\tan(\\alpha) = \\frac{${alpha}}{${alpha}}$`
      );
      question.addParagraph("solution", `$\\tan(\\alpha) = 1$`);
      question.addParagraph("solution", `$\\alpha = \\frac{\\pi}{4}$`);
      question.addParagraph(
        "solution",
        `$R = \\sqrt{${alpha}^2 + ${alpha}^2}$`
      );
      question.addParagraph("solution", `$R = \\sqrt{${RSquared}}$`);
      question.addParagraph(
        "solution",
        `So $\\sqrt{${RSquared}}\\cos(\\theta + \\frac{\\pi}{4}) = ${beta}$`
      );
      question.addParagraph(
        "solution",
        `$\\cos(\\theta + \\frac{\\pi}{4}) = \\frac{${beta}}{\\sqrt{${RSquared}}}$`
      );
      question.addParagraph(
        "solution",
        `$\\theta + \\frac{\\pi}{4} = \\cos^{-1}\\left(\\frac{${beta}}{\\sqrt{${RSquared}}}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = \\cos^{-1}\\left(\\frac{${beta}}{\\sqrt{${RSquared}}}\\right) - \\frac{\\pi}{4}$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${theta_1.toPrecision(3)}$`
      );
      question.addParagraph(
        "solution",
        `Also $\\theta = ${theta_2.toPrecision(3)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = ${theta_1.toPrecision(
          3
        )}, \\text{ } \\theta = ${theta_2.toPrecision(3)}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(alpha, 0, `a) $\\text{ } a =$`);
      group1.addInput(alpha, 0, `a) $\\text{ } b =$`);
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        [
          parseFloat(theta_1.toPrecision(3)),
          parseFloat(theta_2.toPrecision(3)),
        ],
        0,
        `b) $\\text{ } \\theta =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_DoubleAngleFormulae_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const constantA = MCRandom.randomInt(2, 9);
    const constantB = MCRandom.randomInt(2, 9);
    const constantC = MCRandom.randomInt(2, 9);

    const working1 = new MCMaths.Fraction(constantB / constantA);
    const alpha = Math.atan(working1.toFloat());

    const RSquared = constantA ** 2 + constantB ** 2;
    const R = Math.sqrt(RSquared);

    const theta_1 = Math.acos(constantC / R) + parseFloat(alpha.toPrecision(3));
    const theta_2 = -theta_1;

    /* END VARIABLES AND CONSTANTS */
    if (constantC / R < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Show that $R\\cos(\\theta - \\alpha) = R\\cos(\\alpha)\\cos(\\theta) + R\\sin(\\alpha)\\sin(\\theta)$`
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, solve the following equation:`
      );
      question.addParagraph(
        "question",
        `$${constantA}\\cos(\\theta) + ${constantB}\\sin(\\theta) = ${constantC}, \\text{ } -\\pi < \\theta < \\pi$`
      );

      question.addParagraph(
        "solution",
        `a) $R\\cos(\\theta - \\alpha) = R[\\cos(\\theta)\\cos(\\alpha) + \\sin(\\theta)\\sin(\\alpha)]$`
      );
      question.addParagraph(
        "solution",
        `$= R\\cos(\\alpha)\\cos(\\theta) + R\\sin(\\alpha)\\sin(\\theta)$`
      );
      question.addParagraph(
        "solution",
        `b) By comparing coefficients we can see that $R\\cos(\\alpha) = ${constantA}, \\text{ } R\\sin(\\alpha) = ${constantB}$`
      );
      question.addParagraph(
        "solution",
        `Hence $\\displaystyle \\frac{R\\sin(\\alpha)}{R\\cos(\\alpha)} = \\frac{${constantB}}{${constantA}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\implies \\tan(\\alpha) = ${working1.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\alpha = \\tan^{-1}\\left(${working1.toString()}\\right)$`
      );
      question.addParagraph("solution", `$\\alpha = ${alpha.toPrecision(3)}$`);
      question.addParagraph(
        "solution",
        `$R = \\sqrt{${constantA}^2 + ${constantB}^2}$`
      );
      question.addParagraph("solution", `$R = \\sqrt{${RSquared}}$`);
      question.addParagraph(
        "solution",
        `So $\\sqrt{${RSquared}}\\cos(\\theta - ${alpha.toPrecision(
          3
        )}) = ${constantC}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos(\\theta - ${alpha.toPrecision(
          3
        )}) = \\frac{${constantC}}{\\sqrt{${RSquared}}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta - ${alpha.toPrecision(
          3
        )} = \\cos^{-1}\\left(\\frac{${constantC}}{\\sqrt{${RSquared}}}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\cos^{-1}\\left(\\frac{${constantC}}{\\sqrt{${RSquared}}}\\right) + ${alpha.toPrecision(
          3
        )}$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${theta_1.toPrecision(3)}$`
      );
      question.addParagraph(
        "solution",
        `Also $\\theta = ${theta_2.toPrecision(3)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = ${theta_1.toPrecision(
          3
        )}, \\text{ } \\theta = ${theta_2.toPrecision(3)}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(theta_1.toPrecision(3)),
          parseFloat(theta_2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(theta_1.toPrecision(3)) -
            parseFloat(theta_1.toPrecision(2))
        ),
        `b) $\\text{ } \\theta =$`
      );

      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_DoubleAngleFormulae_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(2, 9);

    const a = 3 * alpha;
    const b = 4 * alpha;

    const working1 = new MCMaths.Fraction((beta - a) / -b);

    const x1 = Math.asin(Math.sqrt(working1.toFloat()));
    const x2 = Math.PI - x1;

    /* END VARIABLES AND CONSTANTS */
    if (working1.toFloat() < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Express $${alpha}\\sin(3x)$ in the form $a\\sin(x) - b\\sin^{3}(x)$ where $a$ and $b$ are constants to be found.`
      );
      question.addParagraph("question", `Given that $\\sin(x) > 0$,`);
      question.addParagraph("question", `Solve the following equation:`);
      question.addParagraph(
        "question",
        `$\\displaystyle \\frac{\\sin(3x)}{\\sin(x)} = ${beta}, \\text{ } 0 < x < \\pi$`
      );

      question.addParagraph("solution", `a) $\\sin(3x) = \\sin(2x + x)$`);
      question.addParagraph(
        "solution",
        `$\\sin(2x+x) = \\sin(2x)\\cos(x) + \\cos(2x)\\sin(x)$`
      );
      question.addParagraph(
        "solution",
        `$= (2\\sin(x)\\cos(x))\\cos(x) + (\\cos^{2}(x) - \\sin^{2}(x))\\sin(x)$`
      );
      question.addParagraph(
        "solution",
        `$= 2\\sin(x)\\cos^{2}(x) + [(1-\\sin^{2}(x)) - \\sin^{2}(x)]\\sin(x)$`
      );
      question.addParagraph(
        "solution",
        `$= 2\\sin(x)(1-\\sin^{2}(x)) + [1-2\\sin^{2}(x)]\\sin(x)$`
      );
      question.addParagraph(
        "solution",
        `$= 2\\sin(x)-2\\sin^{3}(x)) + \\sin(x)-2\\sin^{3}(x)$`
      );
      question.addParagraph("solution", `$= 3\\sin(x)-4\\sin^{3}(x)$`);
      question.addParagraph(
        "solution",
        `So $\\sin(3x) = 3\\sin(x) - 4\\sin^{3}(x)$`
      );
      question.addParagraph(
        "solution",
        `Hence $${alpha}\\sin(3x) = ${alpha}(3\\sin(x) - 4\\sin^{3}(x))$`
      );
      question.addParagraph("solution", `$= ${a}\\sin(x) - ${b}\\sin^{3}(x)$`);
      question.addParagraph(
        "solution",
        `$\\therefore a = ${a}, \\text{ } b = ${b}$`
      );

      question.addParagraph(
        "solution",
        `b) $\\displaystyle \\frac{\\sin(3x)}{\\sin(x)} = ${beta}$`
      );
      question.addParagraph("solution", `Using part a) the equation becomes:`);
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{${a}\\sin(x) - ${b}\\sin^{3}(x)}{\\sin(x)} = ${beta}$`
      );
      question.addParagraph("solution", `Since $\\sin(x) > 0$,`);
      question.addParagraph("solution", `$${a} - ${b}\\sin^{2}(x) = ${beta}$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle \\sin^{2}(x) = \\frac{${beta}-${a}}{-${b}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\sin^{2}(x) = ${working1.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\sin(x) = \\sqrt{${working1.toString()}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle x = \\sin^{-1}\\left(\\sqrt{${working1.toString()}}\\right)$`
      );
      question.addParagraph("solution", `$x = ${x1.toPrecision(3)}$`);
      question.addParagraph(
        "solution",
        `Also $x = \\pi - \\sin^{-1}\\left(\\sqrt{${working1.toString()}}\\right)$`
      );
      question.addParagraph("solution", `$x = ${x2.toPrecision(3)}$`);
      question.addParagraph(
        "solution",
        `$\\therefore x = ${x1.toPrecision(3)}, \\text{ } x = ${x2.toPrecision(
          3
        )}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(a, 0, `a) $\\text{ } a =$`);
      group1.addInput(b, 0, `a) $\\text{ } b =$`);
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        [parseFloat(x1.toPrecision(3)), parseFloat(x2.toPrecision(3))],
        0,
        `b) $\\text{ } x =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_DoubleAngleFormulae_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = 2;
    const c = -1;

    const b = MCRandom.randomInt(2, 9);
    const d = MCRandom.randomInt(2, 9);

    const n = MCRandom.randomInt(2, 9);

    const p1 = new MCMaths.Polynomial([n * a, b], `\\sin(x)`);
    const p1Coefficients = p1.getCoefficients();
    const p2 = new MCMaths.Polynomial([c, d], `\\sin(x)`);
    const p2Coefficients = p2.getCoefficients();

    const p3 = p1.multiply(p2);
    const p3Coefficients = p3.getCoefficients();

    const originalEqnString = `${n}\\cos(2x)=${-p3Coefficients[1]}\\sin(x)-${
      p3Coefficients[2] - n
    }, \\text{ } -\\pi < x < 0`;

    const working1 = new MCMaths.Fraction(
      -p1Coefficients[1] / p1Coefficients[0]
    );

    const x1 = Math.asin(working1.toFloat());
    const x2 = -Math.PI - x1;

    /* END VARIABLES AND CONSTANTS */
    if (p1Coefficients[1] / p1Coefficients[0] < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Show that $\\cos(2x) = 1-2\\sin^{2}(x)$`
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, solve the following equation:`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$${originalEqnString}$`)
      );

      question.addParagraph(
        "solution",
        `a) $\\cos(2x) = \\cos^{2}(x) - \\sin^{2}(x)$`
      );
      question.addParagraph(
        "solution",
        `$= (1 - \\sin^{2}(x)) - \\sin^{2}(x)$`
      );
      question.addParagraph("solution", `$= 1 - \\sin^{2}(x) - \\sin^{2}(x)$`);
      question.addParagraph("solution", `$= 1 - 2\\sin^{2}(x)$`);

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `b) $${n}\\cos(2x)=${-p3Coefficients[1]}\\sin(x)-${
            p3Coefficients[2] - n
          }$`
        )
      );
      question.addParagraph("solution", `Using part a) the equation becomes:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${n}(1 - 2\\sin^{2}(x))=${-p3Coefficients[1]}\\sin(x)-${
            p3Coefficients[2] - n
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${n}+${
            p3Coefficients[0]
          }\\sin^{2}(x)=${-p3Coefficients[1]}\\sin(x)-${p3Coefficients[2] - n}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${p3Coefficients[0]}\\sin^{2}(x)+${p3Coefficients[1]}\\sin(x)+${p3Coefficients[2]} = 0$`
        )
      );
      question.addParagraph(
        "solution",
        `$(${p1.toString()})(${p2.toString()}) = 0$`
      );
      question.addParagraph(
        "solution",
        `We know that $(${p2.toString()}) \\neq 0$ since that would imply that $\\sin(x) = ${
          p2Coefficients[1]
        }$ and  $-1 < \\sin(x) < 1$.`
      );
      question.addParagraph("solution", `So $${p1.toString()} = 0$`);
      question.addParagraph(
        "solution",
        `$${p1Coefficients[0]}\\sin(x) = ${-p1Coefficients[1]}$`
      );
      question.addParagraph("solution", `$\\sin(x) = ${working1.toString()}$`);
      question.addParagraph(
        "solution",
        `$x = \\sin^{-1}\\left(${working1.toString()}\\right)$`
      );
      question.addParagraph("solution", `$x = ${x1.toPrecision(3)}$`);
      question.addParagraph(
        "solution",
        `Also $x = -\\pi - \\sin^{-1}\\left(${working1.toString()}\\right)$`
      );
      question.addParagraph("solution", `$= ${x2.toPrecision(3)}$`);
      question.addParagraph(
        "solution",
        `$\\therefore x = ${x1.toPrecision(3)}, \\text{ } x = ${x2.toPrecision(
          3
        )}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [parseFloat(x1.toPrecision(3)), parseFloat(x2.toPrecision(3))],
        Math.abs(parseFloat(x1.toPrecision(3)) - parseFloat(x1.toPrecision(2))),
        `b) $\\text{ } x =$`
      );

      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_DoubleAngleFormulae_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = 2;
    const c = 1;

    const b = MCRandom.randomInt(2, 9);
    const d = MCRandom.randomInt(2, 9);

    const n = MCRandom.randomInt(2, 9);

    const p1 = new MCMaths.Polynomial([n * a, b], `\\cos(x)`);
    const p1Coefficients = p1.getCoefficients();
    const p2 = new MCMaths.Polynomial([c, d], `\\cos(x)`);
    const p2Coefficients = p2.getCoefficients();

    const p3 = p1.multiply(p2);
    const p3Coefficients = p3.getCoefficients();

    const originalEqnString = `${n}\\cos(2x)=${-p3Coefficients[1]}\\cos(x)-${
      p3Coefficients[2] + n
    }, \\text{ } -\\pi < x < \\pi`;

    const working1 = new MCMaths.Fraction(
      -p1Coefficients[1] / p1Coefficients[0]
    );

    const x1 = Math.acos(working1.toFloat());
    const x2 = -x1;

    /* END VARIABLES AND CONSTANTS */
    if (p1Coefficients[1] / p1Coefficients[0] < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Show that $\\cos(2x) = 2\\cos^{2}(x) - 1$`
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, solve the following equation:`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$${originalEqnString}$`)
      );

      question.addParagraph(
        "solution",
        `a) $\\cos(2x) = \\cos^{2}(x) - \\sin^{2}(x)$`
      );
      question.addParagraph(
        "solution",
        `$= \\cos^{2}(x) - (1 - \\cos^{2}(x))$`
      );
      question.addParagraph("solution", `$= \\cos^{2}(x) - 1 + \\cos^{2}(x)$`);
      question.addParagraph("solution", `$= 2\\cos^{2}(x) - 1$`);

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `b) $${n}\\cos(2x)=${-p3Coefficients[1]}\\cos(x)-${
            p3Coefficients[2] + n
          }$`
        )
      );
      question.addParagraph("solution", `Using part a) the equation becomes:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${n}(2\\cos^{2}(x) - 1)=${-p3Coefficients[1]}\\sin(x)-${
            p3Coefficients[2] + n
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${
            p3Coefficients[0]
          }\\cos^{2}(x)-${n}=${-p3Coefficients[1]}\\cos(x)-${
            p3Coefficients[2] + n
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${p3Coefficients[0]}\\cos^{2}(x)+${p3Coefficients[1]}\\cos(x)+${p3Coefficients[2]} = 0$`
        )
      );
      question.addParagraph(
        "solution",
        `$(${p1.toString()})(${p2.toString()}) = 0$`
      );
      question.addParagraph(
        "solution",
        `We know that $(${p2.toString()}) \\neq 0$ since that would imply that $\\cos(x) = ${-p2Coefficients[1]}$ and  $-1 < \\cos(x) < 1$.`
      );
      question.addParagraph("solution", `So $${p1.toString()} = 0$`);
      question.addParagraph(
        "solution",
        `$${p1Coefficients[0]}\\cos(x) = ${-p1Coefficients[1]}$`
      );
      question.addParagraph("solution", `$\\cos(x) = ${working1.toString()}$`);
      question.addParagraph(
        "solution",
        `$x = \\cos^{-1}\\left(${working1.toString()}\\right)$`
      );
      question.addParagraph("solution", `$x = ${x1.toPrecision(3)}$`);
      question.addParagraph(
        "solution",
        `Also $x = -\\cos^{-1}\\left(${working1.toString()}\\right)$`
      );
      question.addParagraph("solution", `$= ${x2.toPrecision(3)}$`);
      question.addParagraph(
        "solution",
        `$\\therefore x = ${x1.toPrecision(3)}, \\text{ } x = ${x2.toPrecision(
          3
        )}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [parseFloat(x1.toPrecision(3)), parseFloat(x2.toPrecision(3))],
        Math.abs(parseFloat(x1.toPrecision(3)) - parseFloat(x1.toPrecision(2))),
        `b) $\\text{ } x =$`
      );

      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_DoubleAngleFormulae_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);

    const answerArray = [true, true, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Select all expressions that are equal to $${alpha}\\cos(2x)$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${2 * alpha}\\cos^{2}(x) - ${alpha}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${alpha} - ${2 * alpha}\\sin^{2}(x)$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${2 * alpha}\\sin(x)\\cos(x)$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${alpha}\\sin^{2}(x) - ${alpha}\\cos^{2}(x)$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_DoubleAngleFormulae_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Select all expressions that are equal to $${alpha}\\sin(2x)$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${2 * alpha}\\sin(x)\\cos(x)$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${alpha} - ${2 * alpha}\\sin^{2}(x)$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${2 * alpha}\\cos^{2}(x) - ${alpha}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${alpha}\\sin^{2}(x) - ${alpha}\\cos^{2}(x)$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_DoubleAngleFormulae_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);

    const answerArray = [true, true, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Select all expressions that are equal to $${alpha}\\cos(3x)$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${4 * alpha}\\cos^{3}(x) - ${3 * alpha}\\cos(x)$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$${alpha}\\cos^{3}(x) - ${3 * alpha}\\sin^{2}(x)\\cos(x)$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${3 * alpha}\\sin(x) - ${4 * alpha}\\sin^{3}(x)$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${alpha}\\cos^{3}(x)-${alpha}\\sin^{3}(x)$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_DoubleAngleFormulae_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);

    const answerArray = [false, true, true, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Select all expressions that are equal to $${alpha}\\sin(3x)$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${4 * alpha}\\cos^{3}(x) - ${3 * alpha}\\cos(x)$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$${3 * alpha}\\sin(x)\\cos^{2}(x) - ${alpha}\\sin^{2}(x)$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${3 * alpha}\\sin(x) - ${4 * alpha}\\sin^{3}(x)$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${3 * alpha}\\cos(x)\\sin(x)$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const sinThetaNumerator = MCRandom.randomInt(2, 9);
    const sinThetaDenominator = MCRandom.randomInt(2, 9);
    const sinTheta = new MCMaths.Fraction(
      sinThetaNumerator / sinThetaDenominator
    );

    const cosThetaNumeratorSquared =
      sinThetaDenominator ** 2 - sinThetaNumerator ** 2;
    const cosThetaNumerator = Math.sqrt(cosThetaNumeratorSquared);
    const cosThetaDenominator = sinThetaDenominator;
    const cosTheta = {
      numerator: cosThetaNumerator,
      denominator: cosThetaDenominator,
      value: cosThetaNumerator / cosThetaDenominator,
      string: `\\frac{\\sqrt{${cosThetaNumeratorSquared}}}{${cosThetaDenominator}}`,
    };

    const alpha = MCRandom.randomInt(2, 9);

    const working1 = new MCMaths.Fraction(
      sinTheta.toFloat() * (cosThetaNumeratorSquared / cosThetaDenominator ** 2)
    );
    const partBAnswer_1 = Math.sqrt(alpha / working1.toFloat());
    const partBAnswer_2 = -partBAnswer_1;

    /* END VARIABLES AND CONSTANTS */
    if (sinTheta.toFloat() < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Given that $\\sin(\\theta) = ${sinTheta.toString()}$`
      );
      question.addParagraph(
        "question",
        `$\\cos(\\theta)$ can be written in the form $\\frac{\\sqrt{a}}{b}$`
      );
      question.addParagraph("question", `a) Find $a$ and $b$.`);
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, solve the following equation:`
      );
      question.addParagraph(
        "question",
        `$x^2 \\sin(\\theta)\\cos^2(\\theta) = ${alpha}$`
      );
      question.addParagraph(
        "question",
        `Giving your values of $x$ to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) Since $\\sin(\\theta) = \\frac{opp}{hyp}$ and $\\cos(\\theta) = \\frac{adj}{hyp}$`
      );
      question.addParagraph(
        "solution",
        `$adj = \\sqrt{${sinThetaDenominator}^2 - ${sinThetaNumerator}^2}$`
      );
      question.addParagraph(
        "solution",
        `$adj = \\sqrt{${cosThetaNumeratorSquared}}$`
      );
      question.addParagraph(
        "solution",
        `And from $\\sin(\\theta) = ${sinTheta.toString()}$ we know that $hyp = ${sinThetaDenominator}$`
      );
      question.addParagraph(
        "solution",
        `So $ \\cos(\\theta) = ${cosTheta.string}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore a = ${cosThetaNumeratorSquared}, \\text{ } b = ${cosThetaDenominator}$`
      );

      question.addParagraph(
        "solution",
        `b) $x^2 \\sin(\\theta)\\cos^2(\\theta) = ${alpha}$`
      );
      question.addParagraph(
        "solution",
        `$\\implies \\displaystyle x^2 \\left(${sinTheta.toString()}\\right)\\left(${
          cosTheta.string
        }\\right)^2 = ${alpha}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\left(${working1.toString()}\\right)x^{2} = ${alpha}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle x = \\pm \\sqrt{\\frac{${alpha}}{\\left(${working1.toString()}\\right)}}$`
      );
      question.addParagraph(
        "solution",
        `$x = ${partBAnswer_1.toPrecision(
          3
        )}, \\text{ } x = ${partBAnswer_2.toPrecision(3)}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(cosThetaNumeratorSquared, 0, `a) $\\text{ } a = $`);
      group1.addInput(cosThetaDenominator, 0, `a) $\\text{ } b = $`);

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        [
          parseFloat(partBAnswer_1.toPrecision(3)),
          parseFloat(partBAnswer_2.toPrecision(3)),
        ],
        0,
        `b) $\\text{ } x=$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const exactValues = [2 / 3, 3 / 4, 5 / 6];
    const valueChooser = MCRandom.randomInt(0, exactValues.length - 1);
    const thetaRadians = `${new MCMaths.Fraction(
      exactValues[valueChooser]
    ).toString()}\\pi`;
    const b = MCRandom.randomInt(2, 9);
    const c = MCRandom.randomInt(2, 9);

    let sinTheta = {};
    let cosTheta = {};
    switch (valueChooser) {
      case 0:
        sinTheta = {
          numerator: "\\sqrt{3}",
          q: "3",
          denominator: "2",
        };

        cosTheta = {
          numerator: "-1",
          l: "1",
          denominator: "2",
        };
        break;

      case 1:
        sinTheta = {
          numerator: "\\sqrt{2}",
          q: "2",
          denominator: "2",
        };

        cosTheta = {
          numerator: "-\\sqrt{2}",
          l: "2",
          denominator: "2",
        };
        break;

      case 2:
        sinTheta = {
          numerator: "1",
          q: "1",
          denominator: "2",
        };

        cosTheta = {
          numerator: "-\\sqrt{3}",
          l: "3",
          denominator: "2",
        };
        break;

      default:
        break;
    }

    const areaFrac = new MCMaths.Fraction(
      0.5 * b * c * (1 / parseFloat(sinTheta.denominator))
    );
    const p = areaFrac.numerator;
    const { q } = sinTheta;
    const r = areaFrac.denominator;

    const j = b ** 2 + c ** 2;
    const k = b * c;
    const { l } = cosTheta;

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given the following triangle:`);
      const myImage = new MCQuestion.Image(
        "Matt/exactValueTriangle1.svg",
        "width",
        15
      );
      myImage.addOverlay(`$${b}m$`, 50, 10);
      myImage.addOverlay(`$${c}m$`, 92, 60);
      myImage.addOverlay(`$${thetaRadians}$`, 65, 37);
      myImage.addOverlay(`$a$`, 45, 60);
      question.addImage("question", myImage);

      question.addParagraph(
        "question",
        `The area of the triangle can be given in the form $\\displaystyle \\frac{p\\sqrt{q}}{r}$`
      );
      question.addParagraph(
        "question",
        `a) Find the constants $p$, $q$ and $r$.`
      );
      question.addParagraph(
        "question",
        `The length $a$ can be written in the from $\\sqrt{j + k\\sqrt{l}}$`
      );
      question.addParagraph("question", `b) Find the constant $k$.`);

      question.addParagraph(
        "solution",
        `a) Area $= \\frac{1}{2}(${b})(${c})\\sin(${thetaRadians})$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = \\frac{${b * c}}{2}\\left(\\frac{${
          sinTheta.numerator
        }}{${sinTheta.denominator}}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = \\frac{${p}\\sqrt{${q}}}{${r}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore p = ${p}, \\text{ } q = ${q}, \\text{ } r = ${r}$`
      );

      question.addParagraph(
        "solution",
        `b) $a^2 = ${b}^2 + ${c}^2 - 2(${b})(${c})\\cos(${thetaRadians})$`
      );
      question.addParagraph("solution", `$a^2 = ${j} + ${k}\\sqrt{${l}}$`);
      question.addParagraph(
        "solution",
        `$a = \\sqrt{${j} + ${k}\\sqrt{${l}}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore j = ${j}, \\text{ } k = ${k}, \\text{ } l = ${l}$`
      );

      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput(p, 0, `a) $\\text{ } p =$`);
      group1.addInput(parseFloat(q), 0, `a) $\\text{ } q =$`);
      group1.addInput(r, 0, `a) $\\text{ } r =$`);
      const group2 = new MCQuestion.InputGroup(3);
      group2.addInput(j, 0, `b) $\\text{ } j =$`);
      group2.addInput(k, 0, `b) $\\text{ } k =$`);
      group2.addInput(parseFloat(l), 0, `b) $\\text{ } l =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const tanTheta = MCRandom.randomInt(2, 9);
    const opp = tanTheta;
    const adj = 1;
    const hypSquared = tanTheta ** 2 + 1;
    const hyp = Math.sqrt(hypSquared);

    const a = MCRandom.randomInt(2, 9);
    const b = MCRandom.randomInt(2, 9);

    const r = opp * a * b * 0.5;

    /* END VARIABLES AND CONSTANTS */
    if (r % 2 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Given that $\\tan(\\theta) = ${tanTheta}$`
      );
      question.addParagraph(
        "question",
        `$\\sin(\\theta)$ can be written in the form $\\frac{p}{\\sqrt{q}}$`
      );
      question.addParagraph(
        "question",
        `a) Find the values of the constants $p$ and $q$.`
      );

      question.addParagraph("question", `Given the following triangle:`);

      const myImage = new MCQuestion.Image(
        "Matt/exactValueTriangle2.svg",
        `width`,
        15
      );
      myImage.addOverlay(`$\\theta$`, 30, 82);
      myImage.addOverlay(`$${a}cm$`, 5, 50);
      myImage.addOverlay(`$${b}cm$`, 75, 50);
      question.addImage("question", myImage);

      question.addParagraph(
        "question",
        `The area, $A$, of the triangle can be written as $\\displaystyle \\frac{r}{\\sqrt{q}}$, where $q$ is the same as in part a).`
      );
      question.addParagraph("question", `b) Find the constant $r$.`);

      question.addParagraph(
        "solution",
        `a) Since $\\tan(\\theta) = \\frac{opp}{adj}$`
      );
      question.addParagraph(
        "solution",
        `$\\implies opp = ${tanTheta}, \\text{ } adj = 1$`
      );
      question.addParagraph(
        "solution",
        `So $hyp = \\sqrt{${tanTheta}^2 + 1^2}$`
      );
      question.addParagraph("solution", `$= \\sqrt{${hypSquared}}$`);
      question.addParagraph(
        "solution",
        `And since $\\sin(\\theta) = \\frac{opp}{hyp}$`
      );
      question.addParagraph(
        "solution",
        `$\\sin(\\theta) = \\frac{${opp}}{\\sqrt{${hypSquared}}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore p = ${opp}, \\text{ } q = ${hypSquared}$`
      );

      question.addParagraph("solution", `b) Area $= \\frac{1}{2}ab\\sin(C)$`);
      question.addParagraph(
        "solution",
        `$= \\frac{1}{2}(${a})(${b})\\left(\\frac{${opp}}{\\sqrt{${hypSquared}}}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$= \\frac{${r}}{\\sqrt{${hypSquared}}}$`
      );
      question.addParagraph("solution", `$\\therefore r = ${r}$`);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(opp, 0, `a) $\\text{ } p = $`);
      group1.addInput(hypSquared, 0, `a) $\\text{ } q = $`);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(r, 0, `b) $\\text{ } r = $`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const exactValues = [1 / 4, 1 / 3];
    const valueChooser = MCRandom.randomInt(0, exactValues.length - 1);
    const exactValue = `${new MCMaths.Fraction(
      exactValues[valueChooser]
    ).toString()}\\pi`;

    let sinExactValue = {};
    switch (valueChooser) {
      case 0:
        sinExactValue = {
          numeratorSquared: "2",
          denominator: "2",
          string: "\\frac{\\sqrt{2}}{2}",
        };
        break;

      case 1:
        sinExactValue = {
          numeratorSquared: "3",
          denominator: "2",
          string: "\\frac{\\sqrt{3}}{2}",
        };
        break;

      default:
        break;
    }

    const cosThetaNumerator = MCRandom.randomInt(2, 9);
    const cosThetaDenominator = MCRandom.randomInt(2, 9);
    const cosTheta = new MCMaths.Fraction(
      cosThetaNumerator / cosThetaDenominator
    );

    const adj = cosThetaNumerator;
    const hyp = cosThetaDenominator;
    const oppSquared = hyp ** 2 - adj ** 2;
    const sinThetaString = `\\frac{\\sqrt{${oppSquared}}}{${hyp}}`;

    const side1 = MCRandom.randomInt(5, 15);

    const pOverR = new MCMaths.Fraction(
      side1 * parseFloat(sinExactValue.denominator / hyp)
    );
    const p = pOverR.numerator;
    const q = oppSquared / parseFloat(sinExactValue.numeratorSquared);
    const r = pOverR.denominator;

    /* END VARIABLES AND CONSTANTS */
    if (
      cosTheta.toFloat() < 1 &&
      oppSquared % parseFloat(sinExactValue.numeratorSquared) === 0 &&
      Math.sqrt(q) !== Math.round(Math.sqrt(q)) &&
      Math.sqrt(oppSquared) !== Math.round(Math.sqrt(oppSquared)) &&
      oppSquared % 4 !== 0 &&
      oppSquared % 9 !== 0 &&
      q % 4 !== 0 &&
      q % 9 !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Given that $\\cos(\\theta) = ${cosTheta.toString()}$`
      );
      question.addParagraph(
        "question",
        `a) Find the exact value of $\\sin(\\theta)$ in the form $\\frac{\\sqrt{a}}{b}$ where $a$ and $b$ are constants to be found.`
      );

      question.addParagraph("question", `Given the following triangle:`);

      const myImage = new MCQuestion.Image(
        "Matt/exactValueTriangle3.svg",
        "width",
        17
      );
      myImage.addOverlay(`$\\theta$`, 10, 85);
      myImage.addOverlay(`$${exactValue}$`, 17, 23);
      myImage.addOverlay(`$x$`, 52, 30);
      myImage.addOverlay(`$${side1}m$`, 50, 85);
      question.addImage("question", myImage);

      question.addParagraph(
        "question",
        `b) Find the value of $x$ in the form $\\frac{p\\sqrt{q}}{r}$`
      );

      question.addParagraph(
        "solution",
        `a) Since $\\cos(\\theta) = \\frac{adj}{hyp}$`
      );
      question.addParagraph(
        "solution",
        `$\\implies adj = ${adj}, \\text{ } hyp = ${hyp}$`
      );
      question.addParagraph("solution", `So $opp = \\sqrt{${hyp}^2-${adj}^2}$`);
      question.addParagraph("solution", `$= \\sqrt{${oppSquared}}$`);
      question.addParagraph(
        "solution",
        `Since $\\sin(\\theta) = \\frac{opp}{hyp}$`
      );
      question.addParagraph("solution", `$\\sin(\\theta) = ${sinThetaString}$`);
      question.addParagraph(
        "solution",
        `$\\therefore a = ${oppSquared}, \\text{ } b = ${hyp}$.`
      );

      question.addParagraph(
        "solution",
        `b) $\\displaystyle \\frac{\\sin(\\theta)}{x} = \\frac{\\sin(${exactValue})}{${side1}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle x = \\frac{${side1}\\sin(\\theta)}{\\sin(${exactValue})}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle x = \\frac{${side1}\\left(${sinThetaString}\\right)}{\\left(${sinExactValue.string}\\right)}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle x = \\frac{${
          side1 * parseFloat(sinExactValue.denominator)
        }\\sqrt{${oppSquared}}}{${hyp}\\sqrt{${
          sinExactValue.numeratorSquared
        }}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle x = \\frac{${p}\\sqrt{${q}}}{${r}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore p = ${p}, \\text{ } q = ${q}, \\text{ } r = ${r}$.`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(oppSquared, 0, `a) $\\text{ } a = $`);
      group1.addInput(hyp, 0, `a) $\\text{ } b = $`);
      const group2 = new MCQuestion.InputGroup(3);
      group2.addInput(p, 0, `b) $\\text{ } p = $`);
      group2.addInput(q, 0, `b) $\\text{ } q = $`);
      group2.addInput(r, 0, `b) $\\text{ } r = $`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const sinANumerator = MCRandom.randomInt(2, 9);
    const sinADenominator = MCRandom.randomInt(2, 9);
    const sinA = new MCMaths.Fraction(sinANumerator / sinADenominator);

    const opp_A = sinANumerator;
    const hyp_A = sinADenominator;
    const adjSquared_A = hyp_A ** 2 - opp_A ** 2;
    const cosAString = `\\frac{\\sqrt{${adjSquared_A}}}{${hyp_A}}`;

    const cosBNumerator = MCRandom.randomInt(2, 9);
    const cosBDenominator = MCRandom.randomInt(2, 9);
    const cosB = new MCMaths.Fraction(cosBNumerator / cosBDenominator);

    const adj_B = cosBNumerator;
    const hyp_B = cosBDenominator;
    const oppSquared_B = hyp_B ** 2 - adj_B ** 2;
    const sinBString = `\\frac{\\sqrt{${oppSquared_B}}}{${hyp_B}}`;

    const a = cosBNumerator;
    const b = sinANumerator;
    const p = adjSquared_A;
    const q = oppSquared_B;
    const c = hyp_A * cosBDenominator;

    /* END VARIABLES AND CONSTANTS */
    if (
      sinA.toFloat() < 1 &&
      cosB.toFloat() < 1 &&
      Math.sqrt(adjSquared_A) !== Math.round(Math.sqrt(adjSquared_A)) &&
      Math.sqrt(oppSquared_B) !== Math.round(Math.sqrt(oppSquared_B)) &&
      oppSquared_B % 4 !== 0 &&
      oppSquared_B % 9 !== 0 &&
      adjSquared_A % 4 !== 0 &&
      adjSquared_A % 9 !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `$\\sin(A) = ${sinA.toString()}, \\text{ } \\cos(B) = ${cosB.toString()}$`
      );
      question.addParagraph(
        "question",
        `$\\sin(B) = \\frac{\\sqrt{q}}{r}, \\text{ } \\cos(A) = \\frac{\\sqrt{p}}{s}$`
      );
      question.addParagraph("question", `Given that $A$ and $B$ are acute`);
      question.addParagraph(
        "question",
        `Find $\\cos(A + B)$ in the form $\\displaystyle \\frac{a\\sqrt{p} - b\\sqrt{q}}{c}$,`
      );
      question.addParagraph(
        "question",
        `where $a$, $b$, $c$, $p$ and $q$ are constants to be found.`
      );

      question.addParagraph("solution", `Since $\\cos(B) = \\frac{adj}{hyp}$`);
      question.addParagraph(
        "solution",
        `$\\implies adj = ${adj_B}, \\text{ } hyp = ${hyp_B}$`
      );
      question.addParagraph(
        "solution",
        `So $opp = \\sqrt{${hyp_B}^2-${adj_B}^2}$`
      );
      question.addParagraph("solution", `$= \\sqrt{${oppSquared_B}}$`);
      question.addParagraph("solution", `Since $\\sin(B) = \\frac{opp}{hyp}$`);
      question.addParagraph("solution", `$\\sin(B) = ${sinBString}$`);
      question.addParagraph(
        "solution",
        `Using a similar method we can find that $\\cos(A) = ${cosAString}$`
      );

      question.addParagraph(
        "solution",
        `So $\\displaystyle \\cos(A + B) = \\left(${cosAString}\\right)\\left(${cosB.toString()}\\right) - \\left(${sinA.toString()}\\right)\\left(${sinBString}\\right)$`
      );
      question.addParagraph(
        "solution",
        `So $\\displaystyle \\cos(A + B) = \\frac{${a}\\sqrt{${p}}-${b}\\sqrt{${q}}}{${c}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore a = ${a}, \\text{ } b = ${b}, \\text{ } c = ${c}, \\text{ } p = ${p}, \\text{ } q = ${q}$`
      );

      const group1 = new MCQuestion.InputGroup(3);
      const group2 = new MCQuestion.InputGroup(2);
      group1.addInput(a, 0, `$a = $`);
      group1.addInput(b, 0, `$b = $`);
      group1.addInput(c, 0, `$c = $`);
      group2.addInput(p, 0, `$p = $`);
      group2.addInput(q, 0, `$q = $`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const sinANumerator = MCRandom.randomInt(2, 9);
    const sinADenominator = MCRandom.randomInt(2, 9);
    const sinA = new MCMaths.Fraction(sinANumerator / sinADenominator);

    const opp_A = sinANumerator;
    const hyp_A = sinADenominator;
    const adjSquared_A = hyp_A ** 2 - opp_A ** 2;
    const cosAString = `\\frac{\\sqrt{${adjSquared_A}}}{${hyp_A}}`;

    const cosBNumerator = MCRandom.randomInt(2, 9);
    const cosBDenominator = MCRandom.randomInt(2, 9);
    const cosB = new MCMaths.Fraction(cosBNumerator / cosBDenominator);

    const adj_B = cosBNumerator;
    const hyp_B = cosBDenominator;
    const oppSquared_B = hyp_B ** 2 - adj_B ** 2;
    const sinBString = `\\frac{\\sqrt{${oppSquared_B}}}{${hyp_B}}`;

    const a = sinANumerator * cosBNumerator;
    const p = oppSquared_B * adjSquared_A;
    const c = hyp_A * cosBDenominator;

    /* END VARIABLES AND CONSTANTS */
    if (
      sinA.toFloat() < 1 &&
      cosB.toFloat() < 1 &&
      Math.sqrt(adjSquared_A) !== Math.round(Math.sqrt(adjSquared_A)) &&
      Math.sqrt(oppSquared_B) !== Math.round(Math.sqrt(oppSquared_B)) &&
      oppSquared_B % 4 !== 0 &&
      oppSquared_B % 9 !== 0 &&
      adjSquared_A % 4 !== 0 &&
      adjSquared_A % 9 !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `$\\sin(A) = ${sinA.toString()}, \\text{ } \\cos(B) = ${cosB.toString()}$`
      );
      question.addParagraph(
        "question",
        `$\\sin(B) = \\frac{\\sqrt{q}}{r}, \\text{ } \\cos(A) = \\frac{\\sqrt{p}}{s}$`
      );
      question.addParagraph("question", `Given that $A$ and $B$ are acute`);
      question.addParagraph(
        "question",
        `Find $\\sin(A + B)$ in the form $\\displaystyle \\frac{a + \\sqrt{p}}{c}$,`
      );
      question.addParagraph(
        "question",
        `where $a$, $c$ and $p$ are constants to be found.`
      );

      question.addParagraph("solution", `Since $\\cos(B) = \\frac{adj}{hyp}$`);
      question.addParagraph(
        "solution",
        `$\\implies adj = ${adj_B}, \\text{ } hyp = ${hyp_B}$`
      );
      question.addParagraph(
        "solution",
        `So $opp = \\sqrt{${hyp_B}^2-${adj_B}^2}$`
      );
      question.addParagraph("solution", `$= \\sqrt{${oppSquared_B}}$`);
      question.addParagraph("solution", `Since $\\sin(B) = \\frac{opp}{hyp}$`);
      question.addParagraph("solution", `$\\sin(B) = ${sinBString}$`);
      question.addParagraph(
        "solution",
        `Using a similar method we can find that $\\cos(A) = ${cosAString}$`
      );

      question.addParagraph(
        "solution",
        `So $\\displaystyle \\sin(A + B) = \\left(${sinA.toString()}\\right)\\left(${cosB.toString()}\\right) + \\left(${sinBString}\\right)\\left(${cosAString}\\right)$`
      );
      question.addParagraph(
        "solution",
        `So $\\displaystyle \\cos(A + B) = \\frac{${a} + \\sqrt{${p}}}{${c}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore a = ${a}, \\text{ } c = ${c}, \\text{ } p = ${p}$`
      );

      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput(a, 0, `$a = $`);
      group1.addInput(c, 0, `$c = $`);
      group1.addInput(p, 0, `$p = $`);

      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const cosAlphaNumerator = MCRandom.randomInt(2, 9);
    const cosAlphaDenominator = MCRandom.randomInt(2, 9);
    const cosAlpha = new MCMaths.Fraction(
      cosAlphaNumerator / cosAlphaDenominator
    );

    const adj = cosAlphaNumerator;
    const hyp = cosAlphaDenominator;
    const oppSquared = hyp ** 2 - adj ** 2;
    const sinAlphaString = `\\frac{\\sqrt{${oppSquared}}}{${hyp}}`;

    const R = MCRandom.randomInt(2, 9);
    const a = R * cosAlpha.toFloat();

    /* END VARIABLES AND CONSTANTS */
    if (cosAlpha.toFloat() < 1 && R === cosAlphaDenominator) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Given that $\\cos(\\alpha) = ${cosAlpha.toString()}$`
      );
      question.addParagraph(
        "question",
        `a) Find the value of $\\sin(\\alpha)$ in the form $\\frac{\\sqrt{p}}{q}$ where $p$ and $q$ are constants to be found.`
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise express $${R}\\cos(\\theta + \\alpha)$ in the form $a\\cos(\\theta) - \\sqrt{p}\\sin(\\theta)$ where $a$ is a constant to be found.`
      );

      question.addParagraph(
        "solution",
        `a) Since $\\cos(\\theta) = \\frac{adj}{hyp}$`
      );
      question.addParagraph(
        "solution",
        `$\\implies adj = ${adj}, \\text{ } hyp = ${hyp}$`
      );
      question.addParagraph("solution", `So $opp = \\sqrt{${hyp}^2-${adj}^2}$`);
      question.addParagraph("solution", `$= \\sqrt{${oppSquared}}$`);
      question.addParagraph(
        "solution",
        `Since $\\sin(\\theta) = \\frac{opp}{hyp}$`
      );
      question.addParagraph("solution", `$\\sin(\\theta) = ${sinAlphaString}$`);
      question.addParagraph(
        "solution",
        `$\\therefore p = ${oppSquared}, \\text{ } q = ${hyp}$.`
      );

      question.addParagraph(
        "solution",
        `b) $${R}\\cos(\\theta + \\alpha) = ${R}\\left[\\cos(\\alpha)\\cos(\\theta) - \\sin(\\alpha)\\sin(\\theta)\\right]$`
      );
      question.addParagraph(
        "solution",
        `$= ${R}\\left[${cosAlpha.toString()}\\cos(\\theta) - ${sinAlphaString}\\sin(\\theta)\\right]$`
      );
      question.addParagraph(
        "solution",
        `$= ${a}\\cos(\\theta) - \\sqrt{${oppSquared}}\\sin(\\theta)$`
      );
      question.addParagraph("solution", `$\\therefore a = ${a}$`);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(oppSquared, 0, `a) $\\text{ } p =$`);
      group1.addInput(hyp, 0, `a) $\\text{ } q =$`);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(a, 0, `b) $\\text{ } a =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const thetaArray = [0, 1 / 6, 1 / 4, 1 / 3, 1 / 2, 2 / 3, 3 / 4, 5 / 6, 1];
    const randomChooser = MCRandom.randomInt(0, thetaArray.length - 1);

    const theta = thetaArray[randomChooser];

    let thetaString = ``;
    if (theta === 0) {
      thetaString = `${theta}`;
    } else if (theta === 1) {
      thetaString = `\\pi`;
    } else {
      thetaString = `${new MCMaths.Fraction(theta).toString()}\\pi`;
    }

    let sinTheta = "";
    let wrongAnswer1 = "";
    let wrongAnswer2 = "";
    let wrongAnswer3 = "";
    switch (randomChooser) {
      case 0:
        sinTheta = "0";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 1:
        sinTheta = "\\frac{1}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 2:
        sinTheta = "\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{3}}{2}";
        break;

      case 3:
        sinTheta = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 4:
        sinTheta = "1";
        wrongAnswer1 = "0";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{3}}{2}";
        break;

      case 5:
        sinTheta = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 6:
        sinTheta = "\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{3}}{2}";
        break;

      case 7:
        sinTheta = "\\frac{1}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 8:
        sinTheta = "0";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `What is the exact value of $\\sin(${thetaString})$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$\\displaystyle ${sinTheta}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$\\displaystyle ${wrongAnswer1}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$\\displaystyle ${wrongAnswer2}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$\\displaystyle ${wrongAnswer3}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const thetaArray = [1, 7 / 6, 5 / 4, 4 / 3, 3 / 2, 5 / 3, 7 / 4, 11 / 6, 2];
    const randomChooser = MCRandom.randomInt(0, thetaArray.length - 1);

    const theta = thetaArray[randomChooser];

    let thetaString = ``;
    if (theta === 1) {
      thetaString = `\\pi`;
    } else {
      thetaString = `${new MCMaths.Fraction(theta).toString()}\\pi`;
    }

    let sinTheta = "";
    let wrongAnswer1 = "";
    let wrongAnswer2 = "";
    let wrongAnswer3 = "";
    switch (randomChooser) {
      case 0:
        sinTheta = "0";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 1:
        sinTheta = "-\\frac{1}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 2:
        sinTheta = "-\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{3}}{2}";
        break;

      case 3:
        sinTheta = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 4:
        sinTheta = "-1";
        wrongAnswer1 = "0";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{3}}{2}";
        break;

      case 5:
        sinTheta = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 6:
        sinTheta = "-\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{3}}{2}";
        break;

      case 7:
        sinTheta = "-\\frac{1}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 8:
        sinTheta = "0";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `What is the exact value of $\\sin(${thetaString})$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$\\displaystyle ${sinTheta}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$\\displaystyle ${wrongAnswer1}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$\\displaystyle ${wrongAnswer2}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$\\displaystyle ${wrongAnswer3}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const thetaArray = [0, 1 / 6, 1 / 4, 1 / 3, 1 / 2, 2 / 3, 3 / 4, 5 / 6, 1];
    const randomChooser = MCRandom.randomInt(0, thetaArray.length - 1);

    const theta = thetaArray[randomChooser];

    let thetaString = ``;
    if (theta === 0) {
      thetaString = `${theta}`;
    } else {
      thetaString = `${new MCMaths.Fraction(theta).toString()}\\pi`;
    }

    let cosTheta = "";
    let wrongAnswer1 = "";
    let wrongAnswer2 = "";
    let wrongAnswer3 = "";
    switch (randomChooser) {
      case 0:
        cosTheta = "1";
        wrongAnswer1 = "0";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 1:
        cosTheta = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 2:
        cosTheta = "\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{3}}{2}";
        break;

      case 3:
        cosTheta = "\\frac{1}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 4:
        cosTheta = "0";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{3}}{2}";
        break;

      case 5:
        cosTheta = "-\\frac{1}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 6:
        cosTheta = "-\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{3}}{2}";
        break;

      case 7:
        cosTheta = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 8:
        cosTheta = "-1";
        wrongAnswer1 = "0";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `What is the exact value of $\\cos(${thetaString})$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$\\displaystyle ${cosTheta}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$\\displaystyle ${wrongAnswer1}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$\\displaystyle ${wrongAnswer2}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$\\displaystyle ${wrongAnswer3}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const thetaArray = [1, 7 / 6, 5 / 4, 4 / 3, 3 / 2, 5 / 3, 7 / 4, 11 / 6, 2];
    const randomChooser = MCRandom.randomInt(0, thetaArray.length - 1);

    const theta = thetaArray[randomChooser];

    let thetaString = ``;
    if (theta === 1) {
      thetaString = `\\pi`;
    } else {
      thetaString = `${new MCMaths.Fraction(theta).toString()}\\pi`;
    }

    let cosTheta = "";
    let wrongAnswer1 = "";
    let wrongAnswer2 = "";
    let wrongAnswer3 = "";
    switch (randomChooser) {
      case 0:
        cosTheta = "-1";
        wrongAnswer1 = "0";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 1:
        cosTheta = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 2:
        cosTheta = "-\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{3}}{2}";
        break;

      case 3:
        cosTheta = "-\\frac{1}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 4:
        cosTheta = "0";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{3}}{2}";
        break;

      case 5:
        cosTheta = "\\frac{1}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 6:
        cosTheta = "\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{3}}{2}";
        break;

      case 7:
        cosTheta = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 8:
        cosTheta = "1";
        wrongAnswer1 = "0";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `What is the exact value of $\\cos(${thetaString})$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$\\displaystyle ${cosTheta}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$\\displaystyle ${wrongAnswer1}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$\\displaystyle ${wrongAnswer2}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$\\displaystyle ${wrongAnswer3}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const thetaArray = [
      0,
      -1 / 6,
      -1 / 4,
      -1 / 3,
      -1 / 2,
      -2 / 3,
      -3 / 4,
      -5 / 6,
      -1,
    ];
    const randomChooser = MCRandom.randomInt(0, thetaArray.length - 1);

    const theta = thetaArray[randomChooser];

    let thetaString = ``;
    if (theta === 0) {
      thetaString = `${theta}`;
    } else if (theta === -1) {
      thetaString = `-\\pi`;
    } else {
      thetaString = `${new MCMaths.Fraction(theta).toString()}\\pi`;
    }

    let cosTheta = "";
    let wrongAnswer1 = "";
    let wrongAnswer2 = "";
    let wrongAnswer3 = "";
    switch (randomChooser) {
      case 0:
        cosTheta = "1";
        wrongAnswer1 = "0";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 1:
        cosTheta = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 2:
        cosTheta = "\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{3}}{2}";
        break;

      case 3:
        cosTheta = "\\frac{1}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 4:
        cosTheta = "0";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{3}}{2}";
        break;

      case 5:
        cosTheta = "-\\frac{1}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 6:
        cosTheta = "-\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{3}}{2}";
        break;

      case 7:
        cosTheta = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 8:
        cosTheta = "-1";
        wrongAnswer1 = "0";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `What is the exact value of $\\cos(${thetaString})$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$\\displaystyle ${cosTheta}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$\\displaystyle ${wrongAnswer1}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$\\displaystyle ${wrongAnswer2}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$\\displaystyle ${wrongAnswer3}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const thetaArray = [
      -1,
      -7 / 6,
      -5 / 4,
      -4 / 3,
      -3 / 2,
      -5 / 3,
      -7 / 4,
      -11 / 6,
      -2,
    ];
    const randomChooser = MCRandom.randomInt(0, thetaArray.length - 1);

    const theta = thetaArray[randomChooser];

    let thetaString = ``;
    if (theta === -1) {
      thetaString = `-\\pi`;
    } else {
      thetaString = `${new MCMaths.Fraction(theta).toString()}\\pi`;
    }

    let cosTheta = "";
    let wrongAnswer1 = "";
    let wrongAnswer2 = "";
    let wrongAnswer3 = "";
    switch (randomChooser) {
      case 0:
        cosTheta = "-1";
        wrongAnswer1 = "0";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 1:
        cosTheta = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 2:
        cosTheta = "-\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{3}}{2}";
        break;

      case 3:
        cosTheta = "-\\frac{1}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 4:
        cosTheta = "0";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{3}}{2}";
        break;

      case 5:
        cosTheta = "\\frac{1}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 6:
        cosTheta = "\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{3}}{2}";
        break;

      case 7:
        cosTheta = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 8:
        cosTheta = "1";
        wrongAnswer1 = "0";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `What is the exact value of $\\cos(${thetaString})$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$\\displaystyle ${cosTheta}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$\\displaystyle ${wrongAnswer1}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$\\displaystyle ${wrongAnswer2}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$\\displaystyle ${wrongAnswer3}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_MCQ_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const thetaArray = [
      0,
      -1 / 6,
      -1 / 4,
      -1 / 3,
      -1 / 2,
      -2 / 3,
      -3 / 4,
      -5 / 6,
      -1,
    ];
    const randomChooser = MCRandom.randomInt(0, thetaArray.length - 1);

    const theta = thetaArray[randomChooser];

    let thetaString = ``;
    if (theta === 0) {
      thetaString = `${theta}`;
    } else if (theta === -1) {
      thetaString = `-\\pi`;
    } else {
      thetaString = `${new MCMaths.Fraction(theta).toString()}\\pi`;
    }

    let sinTheta = "";
    let wrongAnswer1 = "";
    let wrongAnswer2 = "";
    let wrongAnswer3 = "";
    switch (randomChooser) {
      case 0:
        sinTheta = "0";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 1:
        sinTheta = "-\\frac{1}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 2:
        sinTheta = "-\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{3}}{2}";
        break;

      case 3:
        sinTheta = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 4:
        sinTheta = "-1";
        wrongAnswer1 = "0";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{3}}{2}";
        break;

      case 5:
        sinTheta = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 6:
        sinTheta = "-\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{3}}{2}";
        break;

      case 7:
        sinTheta = "-\\frac{1}{2}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      case 8:
        sinTheta = "0";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "-\\frac{1}{2}";
        wrongAnswer3 = "-\\frac{\\sqrt{2}}{2}";
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `What is the exact value of $\\sin(${thetaString})$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$\\displaystyle ${sinTheta}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$\\displaystyle ${wrongAnswer1}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$\\displaystyle ${wrongAnswer2}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$\\displaystyle ${wrongAnswer3}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_MCQ_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const thetaArray = [
      -1,
      -7 / 6,
      -5 / 4,
      -4 / 3,
      -3 / 2,
      -5 / 3,
      -7 / 4,
      -11 / 6,
      -2,
    ];
    const randomChooser = MCRandom.randomInt(0, thetaArray.length - 1);

    const theta = thetaArray[randomChooser];

    let thetaString = ``;
    if (theta === -1) {
      thetaString = `-\\pi`;
    } else {
      thetaString = `${new MCMaths.Fraction(theta).toString()}\\pi`;
    }

    let sinTheta = "";
    let wrongAnswer1 = "";
    let wrongAnswer2 = "";
    let wrongAnswer3 = "";
    switch (randomChooser) {
      case 0:
        sinTheta = "0";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 1:
        sinTheta = "\\frac{1}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 2:
        sinTheta = "\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{3}}{2}";
        break;

      case 3:
        sinTheta = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 4:
        sinTheta = "1";
        wrongAnswer1 = "0";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{3}}{2}";
        break;

      case 5:
        sinTheta = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 6:
        sinTheta = "\\frac{\\sqrt{2}}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{3}}{2}";
        break;

      case 7:
        sinTheta = "\\frac{1}{2}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{\\sqrt{3}}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      case 8:
        sinTheta = "0";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\frac{1}{2}";
        wrongAnswer3 = "\\frac{\\sqrt{2}}{2}";
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `What is the exact value of $\\sin(${thetaString})$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$\\displaystyle ${sinTheta}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$\\displaystyle ${wrongAnswer1}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$\\displaystyle ${wrongAnswer2}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$\\displaystyle ${wrongAnswer3}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_ExactValues_MCQ_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const thetaArray = [-1 / 3, -1 / 4, -1 / 6, 0, 1 / 6, 1 / 4, 1 / 3];
    const randomChooser = MCRandom.randomInt(0, thetaArray.length - 1);

    const theta = thetaArray[randomChooser];

    let thetaString = ``;
    if (theta === 0) {
      thetaString = `${theta}`;
    } else {
      thetaString = `${new MCMaths.Fraction(theta).toString()}\\pi`;
    }

    let tanTheta = "";
    let wrongAnswer1 = "";
    let wrongAnswer2 = "";
    let wrongAnswer3 = "";
    switch (randomChooser) {
      case 0:
        tanTheta = "-\\sqrt{3}";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "0";
        wrongAnswer3 = "-\\frac{\\sqrt{3}}{3}";
        break;

      case 1:
        tanTheta = "-1";
        wrongAnswer1 = "1";
        wrongAnswer2 = "-\\frac{\\sqrt{3}}{3}";
        wrongAnswer3 = "-\\sqrt{3}";
        break;

      case 2:
        tanTheta = "-\\frac{\\sqrt{3}}{3}";
        wrongAnswer1 = "-\\sqrt{3}";
        wrongAnswer2 = "0";
        wrongAnswer3 = "-1";
        break;

      case 3:
        tanTheta = "0";
        wrongAnswer1 = "1";
        wrongAnswer2 = "\\sqrt{3}";
        wrongAnswer3 = "\\frac{\\sqrt{3}}{3}";
        break;

      case 4:
        tanTheta = "\\frac{\\sqrt{3}}{3}";
        wrongAnswer1 = "\\sqrt{3}";
        wrongAnswer2 = "0";
        wrongAnswer3 = "1";
        break;

      case 5:
        tanTheta = "1";
        wrongAnswer1 = "-1";
        wrongAnswer2 = "\\frac{\\sqrt{3}}{3}";
        wrongAnswer3 = "\\sqrt{3}";
        break;

      case 6:
        tanTheta = "\\sqrt{3}";
        wrongAnswer1 = "1";
        wrongAnswer2 = "0";
        wrongAnswer3 = "\\frac{\\sqrt{3}}{3}";
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `What is the exact value of $\\tan(${thetaString})$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$\\displaystyle ${tanTheta}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$\\displaystyle ${wrongAnswer1}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$\\displaystyle ${wrongAnswer2}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$\\displaystyle ${wrongAnswer3}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_Proofs_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const cosCoefficient = MCRandom.randomInt(2, 9);
    const sinCoefficient = MCRandom.randomInt(2, 9);
    const rhsCoefficient = MCRandom.randomInt(2, 9);

    const constantRSquared = cosCoefficient ** 2 + sinCoefficient ** 2;
    const constantR = Math.sqrt(constantRSquared);

    const constantAlpha = Math.atan(sinCoefficient / cosCoefficient);

    const partBAnswer = Math.acos(rhsCoefficient / constantR) + constantAlpha;

    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(rhsCoefficient / constantR) < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) The expression $${cosCoefficient}\\cos(\\theta) + ${sinCoefficient}\\sin(\\theta)$ can be expressed in the form $R\\cos(\\theta - \\alpha)$ where $R$ and $\\alpha$ are constants, $R > 0$, $\\displaystyle 0 < \\alpha < \\frac{\\pi}{4} \\text{ }$ and $\\text{ } \\displaystyle 0 < \\theta < \\pi$.`
      );
      question.addParagraph(
        "question",
        `Hence, find the values of $R$ and $\\alpha$.`
      );
      question.addParagraph(
        "question",
        `b) Solve the equation $${cosCoefficient}\\cos(\\theta) + ${sinCoefficient}\\sin(\\theta) = ${rhsCoefficient}$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significiant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $R\\cos(\\theta - \\alpha) = R[\\cos(\\alpha)\\cos(\\theta) + \\sin(\\alpha)\\sin(\\theta)]$`
      );
      question.addParagraph(
        "solution",
        `$R\\cos(\\theta - \\alpha) = R\\cos(\\alpha)\\cos(\\theta) + R\\sin(\\alpha)\\sin(\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$${cosCoefficient}\\cos(\\theta) + ${sinCoefficient}\\sin(\\theta) = R\\cos(\\alpha)\\cos(\\theta) + R\\sin(\\alpha)\\sin(\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore R\\cos(\\alpha) = ${cosCoefficient}, \\text{ } R\\sin(\\alpha) = ${sinCoefficient}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\tan(\\alpha) = \\frac{R\\sin(\\alpha)}{R\\cos(\\alpha)} = \\frac{${sinCoefficient}}{${cosCoefficient}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\therefore \\alpha = \\tan^{-1}\\left(\\frac{${sinCoefficient}}{${cosCoefficient}}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\alpha = ${constantAlpha.toPrecision(3)}$.`
      );

      question.addParagraph(
        "solution",
        `$R^2 = R^{2}\\sin^{2}(\\alpha) + R^{2}\\cos^{2}(\\alpha)$`
      );
      question.addParagraph(
        "solution",
        `$R^2 = ${sinCoefficient}^{2} + ${cosCoefficient}^{2}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore R = \\sqrt{${sinCoefficient}^{2} + ${cosCoefficient}^{2}}$`
      );
      question.addParagraph("solution", `$R = \\sqrt{${constantRSquared}}$`);
      question.addParagraph("solution", `$R = ${constantR.toPrecision(3)}$.`);

      question.addParagraph(
        "solution",
        `b) $\\sqrt{${constantRSquared}}\\cos(\\theta - ${constantAlpha.toPrecision(
          3
        )}) = ${rhsCoefficient}$`
      );
      question.addParagraph(
        "solution",
        `b) $\\displaystyle \\cos(\\theta - ${constantAlpha.toPrecision(
          3
        )}) = \\frac{${rhsCoefficient}}{\\sqrt{${constantRSquared}}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta - ${constantAlpha.toPrecision(
          3
        )} = \\cos^{-1}\\left(\\frac{${rhsCoefficient}}{\\sqrt{${constantRSquared}}}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\therefore \\theta = \\cos^{-1}\\left(\\frac{${rhsCoefficient}}{\\sqrt{${constantRSquared}}}\\right) + ${constantAlpha.toPrecision(
          3
        )}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = ${partBAnswer.toPrecision(3)}$.`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        parseFloat(constantR.toPrecision(3)),
        Math.abs(
          parseFloat(constantR.toPrecision(3)) -
            parseFloat(constantR.toPrecision(2))
        ),
        `a) $\\text{ } R = $`,
        "before"
      );
      group1.addInput(
        parseFloat(constantAlpha.toPrecision(3)),
        Math.abs(
          parseFloat(constantAlpha.toPrecision(3)) -
            parseFloat(constantAlpha.toPrecision(2))
        ),
        `a) $\\text{ } \\alpha = $`,
        "before"
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(partBAnswer.toPrecision(3)),
        Math.abs(
          parseFloat(partBAnswer.toPrecision(3)) -
            parseFloat(partBAnswer.toPrecision(2))
        ),
        `b) $\\text{ } \\theta = $`,
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_Proofs_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const constant_b = -MCRandom.randomInt(2, 9);
    const constant_d = -MCRandom.randomInt(2, 9);

    const p1 = new MCMaths.Polynomial([2, constant_b], "\\cot(x)");
    const p2 = new MCMaths.Polynomial([1, constant_d], "\\cot(x)");
    const p3 = p1.multiply(p2);
    const p3Coefficients = p3.getCoefficients();

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Show that $\\cosec^{2}(x) - \\cot^{2}(x) \\equiv 1$. $\\text{    }$ (Hint: use $\\sin^{2}(x) + \\cos^{2}(x) = 1$)`
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, show that $\\cosec^{4}(x) - \\cot^{4}(x) \\equiv \\cosec^{2}(x)+\\cot^{2}(x)$.`
      );
      question.addParagraph("question", `c) Using part (b), solve:`);
      question.addParagraph(
        "question",
        `$\\displaystyle \\cosec^{4}(x) - \\cot^{4}(x) = ${-p3Coefficients[1]}\\cot(x)-${
          p3Coefficients[2] - 1
        }, \\text{ } 0 < x < \\frac{\\pi}{4}$`
      );

      question.addParagraph("solution", `a) $\\sin^{2}(x) + \\cos^{2}(x) = 1$`);
      question.addParagraph(
        "solution",
        `Dividing by $\\sin^{2}(x)$ gives $1 + \\cot^{2}(x) = \\cosec^{2}(x)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\cosec^{2}(x) - \\cot^{2}(x) = 1$`
      );

      question.addParagraph(
        "solution",
        `b) $(\\cosec^{2}(x) - \\cot^{2}(x))(\\cosec^{2}(x) + \\cot^{2}(x)) = \\cosec^{4}(x) - \\cot^{4}(x)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\cosec^{4}(x) - \\cot^{4}(x) = (\\cosec^{2}(x) + \\cot^{2}(x))$`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `c) $\\cosec^{4}(x)-\\cot^{4}(x)= ${-p3Coefficients[1]}\\cot(x)-${
            p3Coefficients[2] - 1
          } $`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\cosec^{2}(x)+\\cot^{2}(x)= ${-p3Coefficients[1]}\\cot(x)-${
            p3Coefficients[2] - 1
          } $`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(\\cot^{2}(x)+1)+\\cot^{2}(x)= ${-p3Coefficients[1]}\\cot(x)-${
            p3Coefficients[2] - 1
          } $`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${p3Coefficients[0]}\\cot^{2}(x)+${
            p3Coefficients[2]
          } = ${-p3Coefficients[1]}\\cot(x)$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${p3Coefficients[0]}\\cot^{2}(x)+${p3Coefficients[1]}\\cot(x)+${p3Coefficients[2]} = 0$`
        )
      );
      question.addParagraph(
        "solution",
        `$(${p1.toString()})(${p2.toString()}) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\tan(x) = \\frac{2}{${-constant_b}}, \\text{ } \\tan(x) = \\frac{1}{${-constant_d}} $`
      );
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_Proofs_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const cosANumerator = MCRandom.randomInt(2, 9);
    const cosADenominator = MCRandom.randomInt(2, 9);
    const cosA = new MCMaths.Fraction(cosANumerator / cosADenominator);
    const opp = cosADenominator ** 2 - cosANumerator ** 2;
    const sinADenominator = cosADenominator;

    const constantA = 2 * cosANumerator;
    const constantB = cosADenominator ** 2 - cosANumerator ** 2;
    const constantC = cosADenominator ** 2;

    const constantA_Over_C = new MCMaths.Fraction(constantA / constantC);
    const constantA_Over_C_Numerator = constantA_Over_C.numerator;
    const constantA_Over_C_Denominator = constantA_Over_C.denominator;

    const cosInputs = [7 / 4, 5 / 3, 4 / 3, 1 / 3, 1 / 4, 1 / 6, 2 / 3];
    const cosInputChoice =
      cosInputs[MCRandom.randomInt(0, cosInputs.length - 1)];
    const cosOffset = new MCMaths.Angle(cosInputChoice, "pi-rad");
    const alpha_Working = 2 * Math.cos(cosInputChoice * Math.PI);
    const alpha = parseFloat(
      (2 * Math.cos(cosInputChoice * Math.PI)).toPrecision(3)
    );
    const cosSquaredCoefficient = MCRandom.randomInt(2, 9);
    const beta = parseFloat((2 * alpha - cosSquaredCoefficient).toPrecision(3));

    const squareNumbers = [4, 9, 16, 25, 36, 49, 64, 81];
    let filter = true;
    for (let index = 0; index < squareNumbers.length - 1; index += 1) {
      if (constantB % squareNumbers[index] === 0) {
        filter = false;
      }
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      cosA.toFloat() < 1 &&
      cosA.toFloat() > 0 &&
      filter === true &&
      beta !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Given that $\\displaystyle \\cos(A) = ${cosA.toString()}, \\text{ } 0 < A < \\frac{\\pi}{2}$`
      );
      question.addParagraph(
        "question",
        `a) $\\sin(2A)$ can be expressed in its simplest form as $\\displaystyle \\frac{a\\sqrt{b}}{c}$`
      );
      question.addParagraph("question", `Find $a$, $b$ and $c$.`);
      question.addParagraph(
        "question",
        `b)(i) Show that $\\cos(2x+${cosOffset.toString()}) + \\cos(2x-${cosOffset.toString()}) = \\alpha\\cos(2x)$, where $\\alpha$ is a constant to be found.`
      );
      question.addParagraph(
        "question",
        `b)(ii) Given that $y = ${cosSquaredCoefficient}\\cos^{2}(x) - \\cos(2x+${cosOffset.toString()}) - \\cos(2x-${cosOffset.toString()})$`
      );
      question.addParagraph(
        "question",
        `Show that $\\displaystyle \\frac{dy}{dx} = \\beta\\sin(2x)$, where $\\beta$ is a constant to be found.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) Since $\\cos(A) = ${cosA.toString()}$ by using $\\cos(\\theta) = \\frac{adj}{hyp}$ and $\\sin(\\theta) = \\frac{opp}{hyp}$`
      );
      question.addParagraph(
        "solution",
        `$\\sin(A) = \\frac{\\sqrt{${cosADenominator}^{2}-${cosANumerator}^{2}}}{${sinADenominator}}$`
      );
      question.addParagraph(
        "solution",
        `$\\sin(A) = \\frac{\\sqrt{${opp}}}{${sinADenominator}}$`
      );
      question.addParagraph(
        "solution",
        `Using $\\sin(2A) = 2\\sin(A)\\cos(A)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\sin(2A) = 2\\left(\\frac{\\sqrt{${opp}}}{${sinADenominator}}\\right)\\left(${cosA.toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\sin(2A) = \\frac{ ${
          2 * cosANumerator
        } \\sqrt{${opp}}}{${sinADenominator ** 2}}$`
      );
      question.addParagraph(
        "solution",
        `In its simplest form $\\displaystyle \\text{ } \\sin(2A) = \\frac{${constantA_Over_C_Numerator}\\sqrt{${constantB}}}{${constantA_Over_C_Denominator}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore a = ${constantA_Over_C_Numerator}, \\text{ } b = ${constantB}, \\text{ } c = ${constantA_Over_C_Denominator}$.`
      );

      question.addParagraph(
        "solution",
        `b)(i) $\\cos(2x+${cosOffset.toString()}) + \\cos(2x-${cosOffset.toString()}) = \\cos(2x)\\cos(${cosOffset.toString()}) - \\sin(2x)\\sin(${cosOffset.toString()}) + \\cos(2x)\\cos(${cosOffset.toString()}) + \\sin(2x)\\sin(${cosOffset.toString()})$`
      );
      question.addParagraph(
        "solution",
        `So $ \\cos(2x+${cosOffset.toString()}) + \\cos(2x-${cosOffset.toString()}) = 2\\cos(2x)\\cos(${cosOffset.toString()})$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\cos(2x+${cosOffset.toString()}) + \\cos(2x-${cosOffset.toString()}) = ${alpha_Working}\\cos(2x)$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore \\alpha = ${alpha_Working}$.`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\alpha = ${alpha.toPrecision(
          3
        )}$ (to $3$ significant figures).`
      );

      question.addParagraph(
        "solution",
        `b)(ii) $y = ${cosSquaredCoefficient}\\cos^{2}(x) - \\cos(2x+${cosOffset.toString()}) - \\cos(2x-${cosOffset.toString()})$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `Using part b)(i) $y = ${cosSquaredCoefficient}\\cos^{2}(x)-${alpha_Working}\\cos(2x)$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\frac{dy}{dx} = -${
            cosSquaredCoefficient * 2
          }\\sin(x)\\cos(x)+(2\\times${alpha_Working})\\sin(2x)$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\frac{dy}{dx} = -${cosSquaredCoefficient}\\sin(2x)+(2\\times${alpha_Working})\\sin(2x)$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{dy}{dx} = ${beta}\\sin(2x)$`
      );
      question.addParagraph("solution", `$\\therefore \\beta = ${beta}$.`);

      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput(
        parseFloat(constantA_Over_C_Numerator),
        0,
        "a) $\\text{ } a = $"
      );
      group1.addInput(parseFloat(constantB), 0, "a) $\\text{ } b = $");
      group1.addInput(
        parseFloat(constantA_Over_C_Denominator),
        0,
        "a) $\\text{ } c = $"
      );
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(alpha, 0, "b)(i) $\\text{ } \\alpha = $");
      group2.addInput(beta, 0, "b)(ii) $\\text{ } \\beta = $");

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_Proofs_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const sin2ThetaCoefficient = MCRandom.randomInt(2, 9);
    const cos2ThetaCoefficient = MCRandom.randomInt(2, 9);
    const sinThetaCoefficient = MCRandom.randomInt(2, 9);
    const constantTerm = -cos2ThetaCoefficient;

    const alpha = 2 * sin2ThetaCoefficient;
    const beta = -2 * cos2ThetaCoefficient;
    const gamma = sinThetaCoefficient;

    const constantRSquared = alpha ** 2 + beta ** 2;
    const constantR = Math.sqrt(constantRSquared);

    const betaOverAlpha = new MCMaths.Fraction(beta / alpha);

    const constantDelta = Math.atan(beta / alpha);
    const constantRHS = MCRandom.randomInt(2, 9);
    const theta1 = Math.asin(constantRHS / constantR) - constantDelta;
    const theta2 = Math.PI - theta1;

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(betaOverAlpha.toFloat()) < 1 &&
      Math.abs(constantRHS / constantR) < 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `a) Given that:`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$${sin2ThetaCoefficient}\\sin(2\\theta)+${cos2ThetaCoefficient}\\cos(2\\theta)+${sinThetaCoefficient}\\sin(\\theta)+${constantTerm} \\equiv \\sin(\\theta)(\\alpha\\cos(\\theta) + \\beta\\sin(\\theta) + \\gamma)$`
        )
      );
      question.addParagraph(
        "question",
        `Find $\\alpha, \\beta$ and $\\gamma$.`
      );

      question.addParagraph(
        "question",
        `b) Hence, express $\\alpha\\sin(\\theta) + \\beta\\cos(\\theta)$ in the form $R\\sin(\\theta + \\delta), \\text{ } R>0, \\text{ } 0 < \\delta < \\frac{\\pi}{2}$.`
      );
      question.addParagraph(
        "question",
        `Where $R$ and $\\delta$ are constants to be found and $\\alpha$, $\\beta$ are the same constants from part (a).`
      );

      question.addParagraph("question", `c) Hence, or otherwise, solve:`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$ ${-constantTerm}+${-cos2ThetaCoefficient}\\cos(2\\theta) = ${sinThetaCoefficient}\\sin(\\theta)+${sin2ThetaCoefficient}\\sin(2\\theta)+${constantRHS}, \\text{ } 0 < \\theta < \\pi$.`
        )
      );

      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `a) $${sin2ThetaCoefficient}\\sin(2\\theta)+${cos2ThetaCoefficient}\\cos(2\\theta)+${sinThetaCoefficient}\\sin(\\theta)+${constantTerm} = ${sin2ThetaCoefficient}(2\\sin(\\theta)\\cos(\\theta))+${cos2ThetaCoefficient}(1-2\\sin^{2}(\\theta))+${sinThetaCoefficient}\\sin(\\theta)+${constantTerm}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$= ${sin2ThetaCoefficient}(2\\sin(\\theta)\\cos(\\theta))+${cos2ThetaCoefficient}+${
            -2 * cos2ThetaCoefficient
          }\\sin^{2}(\\theta)+${sinThetaCoefficient}\\sin(\\theta)+${constantTerm} $`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$= ${sin2ThetaCoefficient}(2\\sin(\\theta)\\cos(\\theta))+${
            -2 * cos2ThetaCoefficient
          }\\sin^{2}(\\theta)+${sinThetaCoefficient}\\sin(\\theta)$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$= \\sin(\\theta)(${alpha}\\cos(\\theta)+${beta}\\sin(\\theta)+${gamma})$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\alpha = ${alpha}, \\text{ } \\beta = ${beta}, \\text{ } \\gamma = ${gamma}$.`
      );

      question.addParagraph(
        "solution",
        `b) $R\\sin(\\theta + \\delta) = R[\\cos(\\delta)\\sin(\\theta) + \\sin(\\delta)\\cos(\\theta)]$`
      );
      question.addParagraph(
        "solution",
        `$\\sin(\\theta + \\delta) = \\cos(\\delta)\\sin(\\theta) + \\sin(\\delta)\\cos(\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\cos(\\delta) = ${alpha}, \\text{ } \\sin(\\delta) = ${beta}$`
      );
      question.addParagraph(
        "solution",
        `And, $\\displaystyle \\tan(\\delta) = \\frac{\\sin(\\delta)}{\\cos(\\delta)} $`
      );
      question.addParagraph(
        "solution",
        `Hence, $\\displaystyle \\tan(\\delta) = \\frac{${beta}}{${alpha}} $`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\delta = \\tan^{-1}\\left(${betaOverAlpha.toString()}\\right) $`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\displaystyle \\delta = ${constantDelta.toPrecision(
          3
        )} $.`
      );
      question.addParagraph(
        "solution",
        `$R^2 = R^{2}\\sin^{2}(\\delta) + R^{2}\\cos^{2}(\\delta)$`
      );
      question.addParagraph("solution", `$R^2 = ${alpha}^{2} + (${beta})^{2}$`);
      question.addParagraph(
        "solution",
        `$\\therefore R = \\sqrt{${alpha}^{2} + (${beta})^{2}}$`
      );
      question.addParagraph("solution", `$R = \\sqrt{${constantRSquared}}$`);
      question.addParagraph("solution", `$R = ${constantR.toPrecision(3)}$.`);

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `c) $ ${-constantTerm}+${-cos2ThetaCoefficient}\\cos(2\\theta) = ${sinThetaCoefficient}\\sin(\\theta)+${sin2ThetaCoefficient}\\sin(2\\theta)+${constantRHS}$.`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${sin2ThetaCoefficient}\\sin(2\\theta)+${cos2ThetaCoefficient}\\cos(2\\theta)+${sinThetaCoefficient}\\sin(\\theta)+${constantTerm} = ${constantRHS}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\sin(\\theta)(${alpha}\\cos(\\theta)+${beta}\\sin(\\theta)+${gamma}) = ${constantRHS}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\sin(\\theta)(\\sqrt{${constantRSquared}}\\sin(\\theta ${constantDelta.toPrecision(
            3
          )})) = ${constantRHS}$`,
          false
        )
      );
      question.addParagraph(
        "solution",
        `Since $\\theta \\neq 0, \\text{ } \\sin(\\theta) \\neq 0$`
      );
      question.addParagraph(
        "solution",
        `So, $\\sqrt{${constantRSquared}}\\sin(\\theta ${constantDelta.toPrecision(
          3
        )}) = ${constantRHS}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\sin(\\theta ${constantDelta.toPrecision(
          3
        )}) = \\frac{${constantRHS}}{\\sqrt{${constantRSquared}}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\sin^{-1}\\left(\\frac{${constantRHS}}{\\sqrt{${constantRSquared}}}\\right) + ${-constantDelta.toPrecision(
          3
        )}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\therefore \\theta = ${theta1.toPrecision(3)}$`
      );
      question.addParagraph(
        "solution",
        `Also, $\\displaystyle \\theta = \\pi - ${theta1.toPrecision(3)}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\therefore \\theta = ${theta1.toPrecision(
          3
        )}, \\text{ } \\theta = ${theta2.toPrecision(3)}$.`
      );

      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput(alpha, 0, "a) $\\text{ } \\alpha = $", "before");
      group1.addInput(beta, 0, "a) $\\text{ } \\beta = $", "before");
      group1.addInput(gamma, 0, "a) $\\text{ } \\gamma = $", "before");
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        parseFloat(constantDelta.toPrecision(3)),
        0,
        "b) $\\text{ } \\delta = $",
        "before"
      );
      group2.addInput(
        parseFloat(constantR.toPrecision(3)),
        0,
        "b) $\\text{ } R = $",
        "before"
      );
      const group3 = new MCQuestion.InputGroup(2);
      group3.addInput(
        [parseFloat(theta1.toPrecision(3)), parseFloat(theta2.toPrecision(3))],
        0,
        "c) $\\text{ } \\theta = $",
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_Proofs_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const tanCoefficient = MCRandom.randomInt(-9, 9);

    const partAAnswer_1 = 0;
    const partAAnswer_2 =
      -tanCoefficient / 2 - Math.sqrt(tanCoefficient ** 2 + 4) / 2;
    const partAAnswer_3 =
      -tanCoefficient / 2 + Math.sqrt(tanCoefficient ** 2 + 4) / 2;

    let partAAnswer_4 = 0;
    // (Math.abs(partAAnswer_2) < Math.abs(partAAnswer_3)) ? partAAnswer_4 = partAAnswer_2 : partAAnswer_4 = partAAnswer_3;
    if (Math.abs(partAAnswer_2) < Math.abs(partAAnswer_3)) {
      partAAnswer_4 = partAAnswer_2;
    } else {
      partAAnswer_4 = partAAnswer_3;
    }

    const partAAnswer_Array = [0, parseFloat(partAAnswer_4.toPrecision(3))];

    const partBAnswer_1 = 0;
    const partBAnswer_2Radians = Math.asin(partAAnswer_4);
    const partBAnswer_3Radians =
      Math.sign(partBAnswer_2Radians) *
      (Math.PI - Math.sign(partBAnswer_2Radians) * partBAnswer_2Radians);
    const partBAnswer_2Degrees = partBAnswer_2Radians * (180 / Math.PI);
    const partBAnswer_3Degrees = partBAnswer_3Radians * (180 / Math.PI);

    const partBAnswer_Array = [
      0,
      parseFloat(partBAnswer_2Radians.toPrecision(3)),
      parseFloat(partBAnswer_3Radians.toPrecision(3)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (tanCoefficient !== 0 && Math.abs(tanCoefficient) !== 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given that,`);
      question.addParagraph(
        "question",
        `$${tanCoefficient}\\tan^{2}(\\theta) = \\sin(\\theta), \\text{ } -\\pi < \\theta < \\pi$`
      );
      question.addParagraph(
        "question",
        `a) Find the possible values of $\\sin(\\theta)$.`
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, find the values of $\\theta$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $${tanCoefficient}\\tan^{2}(\\theta) = \\sin(\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle ${tanCoefficient}\\frac{\\sin^{2}(\\theta)}{\\cos^{2}(\\theta)} = \\sin(\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$${tanCoefficient}\\sin^{2}(\\theta) = \\sin(\\theta)\\cos^{2}(\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$${tanCoefficient}\\sin^{2}(\\theta) = \\sin(\\theta)(1 - \\sin^{2}(\\theta))$`
      );
      question.addParagraph(
        "solution",
        `$${tanCoefficient}\\sin^{2}(\\theta) = \\sin(\\theta) - \\sin^{3}(\\theta)$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\sin^{3}(\\theta)+${tanCoefficient}\\sin^{2}(\\theta)-\\sin(\\theta) = 0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\sin(\\theta)(\\sin^{2}(\\theta)+${tanCoefficient}\\sin(\\theta)-1) = 0$`
        )
      );
      question.addParagraph("solution", `So $\\sin(\\theta) = 0$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `Or $(\\sin^{2}(\\theta)+${tanCoefficient}\\sin(\\theta)-1) = 0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `I.e. $\\displaystyle \\sin(\\theta) = 0,\\text{ }\\sin(\\theta) = \\frac{${-tanCoefficient}+\\sqrt{${
            tanCoefficient ** 2 + 4
          }}}{2},\\text{ }\\sin(\\theta) =  \\frac{${-tanCoefficient}-\\sqrt{${
            tanCoefficient ** 2 + 4
          }}}{2}$`
        )
      );
      question.addParagraph(
        "solution",
        `But $-1 \\leq \\sin(\\theta) \\leq 1$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sin(\\theta) = 0,\\text{ } \\sin(\\theta) =  ${partAAnswer_4.toPrecision(
          3
        )}$.`
      );
      question.addParagraph(
        "solution",
        `b) $\\therefore \\theta = \\sin^{-1}(0),\\text{ } \\theta = \\sin^{-1}(${partAAnswer_4.toPrecision(
          3
        )})$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = 0,\\text{ } \\theta = ${partBAnswer_2Radians.toPrecision(
          3
        )}$`
      );
      question.addParagraph(
        "solution",
        `Also $\\theta = ${partBAnswer_3Radians.toPrecision(3)}.$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        partAAnswer_Array,
        Math.abs(
          parseFloat(partAAnswer_Array[0].toPrecision(3)) -
            parseFloat(partAAnswer_Array[0].toPrecision(2))
        ),
        "a) $\\text{ } \\sin(\\theta) = $",
        "before"
      );

      const group2 = new MCQuestion.InputGroup(3);
      group2.addInput(
        partBAnswer_Array,
        Math.abs(
          parseFloat(partBAnswer_Array[0].toPrecision(3)) -
            parseFloat(partBAnswer_Array[0].toPrecision(2))
        ),
        "b) $\\text{ } \\theta = $",
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_Proofs_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const cosCoefficient_1 = MCRandom.randomInt(-9, 9);
    const constantTerm_1 = MCRandom.randomInt(-9, 9);
    const cosCoefficient_2 = MCRandom.randomInt(-9, 9);
    const constantTerm_2 = MCRandom.randomInt(-9, 9);

    const p1 = new MCMaths.Polynomial(
      [cosCoefficient_1, constantTerm_1],
      "\\cos(x)"
    );
    const p2 = new MCMaths.Polynomial(
      [cosCoefficient_2, constantTerm_2],
      "\\cos(x)"
    );
    const polynomialProduct = p1.multiply(p2);
    const polynomialProduct_Coefficients = polynomialProduct.getCoefficients();

    const constantTerm_3 =
      polynomialProduct_Coefficients[0] + polynomialProduct_Coefficients[2];

    const partAAnswer_1 = new MCMaths.Fraction(
      -constantTerm_1 / cosCoefficient_1
    );
    const partAAnswer_2 = new MCMaths.Fraction(
      -constantTerm_2 / cosCoefficient_2
    );
    const partAAnswer_1Float = partAAnswer_1.toFloat();
    const partAAnswer_2Float = partAAnswer_2.toFloat();

    const partBAnswer_1Radians = Math.acos(partAAnswer_1Float);
    const partBAnswer_2Radians = Math.acos(partAAnswer_2Float);
    const partBAnswer_3Radians = -partBAnswer_1Radians;
    const partBAnswer_4Radians = -partBAnswer_2Radians;
    const partBAnswer_1Degrees = partBAnswer_1Radians * (180 / Math.PI);
    const partBAnswer_2Degrees = partBAnswer_2Radians * (180 / Math.PI);
    const partBAnswer_3Degrees = partBAnswer_3Radians * (180 / Math.PI);
    const partBAnswer_4Degrees = partBAnswer_4Radians * (180 / Math.PI);

    const partBAnswer_Array = [
      parseFloat(partBAnswer_1Degrees.toPrecision(3)),
      parseFloat(partBAnswer_2Degrees.toPrecision(3)),
      parseFloat(partBAnswer_3Degrees.toPrecision(3)),
      parseFloat(partBAnswer_4Degrees.toPrecision(3)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (
      cosCoefficient_1 !== 0 &&
      cosCoefficient_2 !== 0 &&
      constantTerm_1 !== 0 &&
      constantTerm_2 !== 0 &&
      Math.abs(partAAnswer_1Float) < 1 &&
      Math.abs(partAAnswer_2Float) < 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph("question", `Given that:`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$${polynomialProduct_Coefficients[0]}\\sin^{2}(x) = ${polynomialProduct_Coefficients[1]}\\cos(x)+${constantTerm_3},\\text{ } -\\pi < x < \\pi$.`
        )
      );
      question.addParagraph(
        "question",
        `a) Find the $2$ possible values of $\\cos(x)$.`
      );
      question.addParagraph("question", `a) Find all values of $x$.`);
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `a) $${polynomialProduct_Coefficients[0]}\\sin^{2}(x) = ${polynomialProduct_Coefficients[1]}\\cos(x)+${constantTerm_3}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${-1 * polynomialProduct_Coefficients[0]}\\sin^{2}(x)+${
            polynomialProduct_Coefficients[1]
          }\\cos(x)+${constantTerm_3} = 0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${polynomialProduct_Coefficients[0]}-${polynomialProduct_Coefficients[0]}\\sin^{2}(x)+${polynomialProduct_Coefficients[1]}\\cos(x)+${polynomialProduct_Coefficients[2]} = 0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${polynomialProduct_Coefficients[0]}(1-\\sin^{2}(x))+${polynomialProduct_Coefficients[1]}\\cos(x)+${polynomialProduct_Coefficients[2]} = 0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${polynomialProduct_Coefficients[0]}\\cos^{2}(x)+${polynomialProduct_Coefficients[1]}\\cos(x)+${polynomialProduct_Coefficients[2]} = 0$`
        )
      );
      question.addParagraph(
        "solution",
        `$(${p1.toString()})(${p2.toString()}) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\displaystyle \\cos(x) = ${partAAnswer_1.toString()},\\text{ } \\cos(x) = ${partAAnswer_2.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\cos(x) = ${partAAnswer_1Float.toPrecision(
          3
        )},\\text{ } \\cos(x) = ${partAAnswer_2Float.toPrecision(3)}$.`
      );

      question.addParagraph(
        "solution",
        `b) $\\therefore x = \\cos^{-1}(${partAAnswer_1Float.toPrecision(
          3
        )}),\\text{ } \\cos^{-1}(${partAAnswer_2Float.toPrecision(3)})$`
      );
      question.addParagraph(
        "solution",
        `$x = ${partBAnswer_1Radians.toPrecision(
          3
        )},\\text{ } x = ${partBAnswer_2Radians.toPrecision(3)}$`
      );

      question.addParagraph(
        "solution",
        `Also $x = ${partBAnswer_3Radians.toPrecision(
          3
        )}, \\text{ } x = ${partBAnswer_4Radians.toPrecision(3)}$.`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(partAAnswer_1Float.toPrecision(3)),
          parseFloat(partAAnswer_2Float.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(partAAnswer_1Float.toPrecision(3)) -
            parseFloat(partAAnswer_1Float.toPrecision(2))
        ),
        "a) $\\text{ } \\cos(x) = $",
        "before"
      );

      const group2 = new MCQuestion.InputGroup(4);
      group2.addInput(
        partBAnswer_Array,
        Math.abs(
          parseFloat(partBAnswer_Array[0].toPrecision(3)) -
            parseFloat(partBAnswer_Array[0].toPrecision(2))
        ),
        "b) $\\text{ } x = $",
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_Proofs_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Prove that $\\cos(\\frac{x}{2}) = \\cos(x)\\cos(\\frac{x}{2}) + \\sin(x)\\sin(\\frac{x}{2})$`
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, prove that $${alpha}\\cos(\\frac{x}{2})(1 - \\cos(x)) = ${alpha}\\sin(\\frac{x}{2})\\sin(x)$`
      );

      question.addParagraph(
        "solution",
        `a) $\\cos(\\frac{x}{2}) = \\cos(x - \\frac{x}{2})$`
      );
      question.addParagraph(
        "solution",
        `$= \\cos(x)\\cos(\\frac{x}{2}) + \\sin(x)\\sin(\\frac{x}{2})$`
      );

      question.addParagraph(
        "solution",
        `b) $${alpha}\\cos(\\frac{x}{2})(1 - \\cos(x)) = \\sin(\\frac{x}{2})\\sin(x) = ${alpha}\\cos(\\frac{x}{2}) - ${alpha}\\cos(\\frac{x}{2})\\cos(x)$`
      );
      question.addParagraph("solution", `Using part a) the equation becomes:`);
      question.addParagraph(
        "solution",
        `$=  ${alpha}(\\cos(x)\\cos(\\frac{x}{2}) + \\sin(x)\\sin(\\frac{x}{2})) - ${alpha}\\cos(\\frac{x}{2})\\cos(x)$`
      );
      question.addParagraph(
        "solution",
        `$=  ${alpha}\\cos(x)\\cos(\\frac{x}{2}) + ${alpha}\\sin(x)\\sin(\\frac{x}{2}) - ${alpha}\\cos(\\frac{x}{2})\\cos(x)$`
      );
      question.addParagraph(
        "solution",
        `$=  ${alpha}\\sin(x)\\sin(\\frac{x}{2})$`
      );

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_Proofs_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(2, 9);
    const gamma = MCRandom.randomInt(2, 9);
    const working1 = new MCMaths.Fraction(alpha / beta);

    const working2 = new MCMaths.Fraction(gamma / working1.toFloat());

    const x1 = Math.atan(working2.toFloat());

    /* END VARIABLES AND CONSTANTS */
    if (alpha !== beta && `${working2.numerator}`.length <= 3) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Show that $\\displaystyle \\frac{${alpha} + ${alpha}\\tan(x)}{${beta} + ${beta}\\cot(x)} \\equiv ${working1.toString()}\\tan(x)$`
      );
      question.addParagraph(
        "question",
        `Hence, or otherwise, solve the following equation:`
      );
      question.addParagraph(
        "question",
        `$\\displaystyle \\frac{${alpha} + ${alpha}\\tan(x)}{${beta} + ${beta}\\cot(x)} = ${gamma}, \\text{ } -\\frac{\\pi}{2} < x < \\frac{\\pi}{2}$`
      );
      question.addParagraph(
        "question",
        `Give your answer to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $\\displaystyle \\frac{${alpha} + ${alpha}\\tan(x)}{${beta} + ${beta}\\cot(x)} = \\frac{${alpha}(1+\\tan(x))}{${beta}(1+\\cot(x))}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = ${working1.toString()}\\left(\\frac{1+\\tan(x)}{1+\\frac{1}{\\tan(x)}}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = ${working1.toString()}\\left(\\frac{1+\\tan(x)}{\\frac{\\tan(x) + 1}{\\tan(x)}}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = ${working1.toString()}\\left(\\frac{1+\\tan(x)}{1 + \\tan(x)}\\right)\\tan(x)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = ${working1.toString()}\\tan(x)$`
      );

      question.addParagraph(
        "solution",
        `b) $\\displaystyle \\frac{${alpha} + ${alpha}\\tan(x)}{${beta} + ${beta}\\cot(x)} = ${gamma}$`
      );
      question.addParagraph("solution", `Using part a) we obtain:`);
      question.addParagraph(
        "solution",
        `$${working1.toString()}\\tan(x) = ${gamma}$`
      );
      question.addParagraph("solution", `$\\tan(x) = ${working2.toString()}$`);
      question.addParagraph(
        "solution",
        `$ x = \\tan^{-1}\\left(${working2.toString()}\\right)$`
      );
      question.addParagraph("solution", `$x = ${x1.toPrecision(3)}$`);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(x1.toPrecision(3)),
        Math.abs(parseFloat(x1.toPrecision(3)) - parseFloat(x1.toPrecision(2))),
        `b) $\\text{ } x =$`
      );
      question.addInputGroup(group1);
      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_Proofs_MCQ_Q1() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const scalar = MCRandom.randomInt(2, 9);

    const identitiesList = [
      [`$${scalar}(1+\\tan^{2}(x) = ${scalar}\\sec^{2}(x)$`, true],
      [`$${scalar}(1-\\tan^{2}(x) = ${scalar}\\sec^{2}(x)$`, false],
      [`$${scalar}(1+\\cot^{2}(x) = ${scalar}\\cosec^{2}(x)$`, true],
      [`$${scalar}(1-\\cot^{2}(x) = ${scalar}\\cosec^{2}(x)$`, false],
      [`$${scalar}\\tan^{2}(x) = ${scalar}+${scalar}\\sec^{2}(x)$`, false],
      [`$${scalar}\\tan^{2}(x) = ${scalar}\\sec^{2}(x)-${scalar}$`, true],
      [`$${scalar}\\cot^{2}(x) = ${scalar}+${scalar}\\cosec^{2}(x)$`, false],
      [`$${scalar}\\cot^{2}(x) = ${scalar}\\cosec^{2}(x)-${scalar}$`, true],
      [
        `$\\displaystyle \\frac{1}{${scalar}}\\cos(x) = \\pm \\sqrt{\\frac{1}{${
          scalar ** 2
        }+${scalar ** 2}\\tan^{2}(x)}}$`,
        true,
      ],
      [
        `$\\displaystyle \\frac{1}{${scalar}}\\cos(x) = \\pm \\sqrt{\\frac{1}{${scalar}+${scalar}\\tan^{2}(x)}}$`,
        false,
      ],
      [
        `$\\displaystyle \\frac{1}{${scalar}}\\cos(x) = \\pm \\sqrt{\\frac{1}{${
          scalar ** 2
        }-${scalar ** 2}\\tan^{2}(x)}}$`,
        false,
      ],
      [
        `$\\displaystyle \\frac{1}{${scalar}}\\tan(x) = \\pm \\sqrt{\\frac{1}{${
          scalar ** 2
        }+${scalar ** 2}\\cosec^{2}(x)}}$`,
        false,
      ],
      [
        `$\\displaystyle \\frac{1}{${scalar}}\\tan(x) = \\pm \\sqrt{\\frac{1}{${
          scalar ** 2
        }\\cosec^{2}(x)-${scalar ** 2}}}$`,
        true,
      ],
      [
        `$\\displaystyle \\frac{1}{${scalar}}\\tan(x) = \\pm \\sqrt{\\frac{1}{${scalar}\\cosec^{2}(x) - ${scalar}}}$`,
        false,
      ],
      [
        `$\\displaystyle \\frac{1}{${scalar}}\\sin(x) = \\pm \\sqrt{\\frac{1}{${
          scalar ** 2
        }+${scalar ** 2}\\cot^{2}(x)}}$`,
        true,
      ],
      [
        `$\\displaystyle \\frac{1}{${scalar}}\\sin(x) = \\pm \\sqrt{\\frac{1}{${scalar}+${scalar}\\cot^{2}(x)}}$`,
        false,
      ],
      [
        `$\\displaystyle \\frac{1}{${scalar}}\\sin(x) = \\pm \\sqrt{\\frac{1}{${
          scalar ** 2
        }-${scalar ** 2}\\cot^{2}(x)}}$`,
        false,
      ],
    ];

    const identityChooser1 = MCRandom.randomInt(0, identitiesList.length - 1);
    const identityChooser2 = MCRandom.randomInt(0, identitiesList.length - 1);
    const identityChooser3 = MCRandom.randomInt(0, identitiesList.length - 1);
    const identityChooser4 = MCRandom.randomInt(0, identitiesList.length - 1);

    /* END VARIABLES AND CONSTANTS */
    if (
      identityChooser1 !== identityChooser2 &&
      identityChooser3 !== identityChooser4 &&
      identityChooser1 !== identityChooser3 &&
      identityChooser2 !== identityChooser4
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(`Choose the correct identities:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`${identitiesList[identityChooser1][0]}`);
      question.addAnswer(answer1, identitiesList[identityChooser1][1]);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`${identitiesList[identityChooser2][0]}`);
      question.addAnswer(answer2, identitiesList[identityChooser2][1]);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`${identitiesList[identityChooser3][0]}`);
      question.addAnswer(answer3, identitiesList[identityChooser3][1]);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`${identitiesList[identityChooser4][0]}`);
      question.addAnswer(answer4, identitiesList[identityChooser4][1]);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_Trigonometry_Proofs_MCQ_Q2() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const scalar = MCRandom.randomInt(2, 9);

    const identitiesList = [
      [`$${scalar}\\sin(x \\pm 2\\pi) \\equiv ${scalar}\\sin(x)$`, true],
      [`$${scalar}\\cos(x \\pm 2\\pi) \\equiv ${scalar}\\cos(x)$`, true],
      [`$${scalar}\\sin(x \\pm \\pi) \\equiv ${scalar}\\sin(x)$`, true],
      [`$${scalar}\\cos(x \\pm \\pi) \\equiv ${scalar}\\cos(x)$`, false],
      [`$${scalar}\\cos(x \\pm \\pi) \\equiv -${scalar}\\cos(x)$`, true],
      [
        `$${scalar}\\sin(x \\pm \\frac{\\pi}{2}) \\equiv ${scalar}\\sin(x)$`,
        false,
      ],
      [
        `$${scalar}\\cos(x \\pm \\frac{\\pi}{2}) \\equiv ${scalar}\\cos(x)$`,
        false,
      ],
      [
        `$${scalar}\\cos(x \\pm \\frac{\\pi}{2}) \\equiv -${scalar}\\cos(x)$`,
        false,
      ],
      [`$${scalar}\\sin(-x) \\equiv -${scalar}\\sin(x)$`, true],
      [`$${scalar}\\sin(-x) \\equiv ${scalar}\\sin(x)$`, false],
      [`$${scalar}\\sin(-x) \\equiv ${scalar}\\cos(x)$`, false],
      [`$${scalar}\\cos(-x) \\equiv ${scalar}\\cos(x)$`, true],
      [`$${scalar}\\cos(-x) \\equiv -${scalar}\\cos(x)$`, false],
      [`$${scalar}\\cos(-x) \\equiv ${scalar}\\sin(x)$`, false],
    ];

    const identityChooser1 = MCRandom.randomInt(0, identitiesList.length - 1);
    const identityChooser2 = MCRandom.randomInt(0, identitiesList.length - 1);
    const identityChooser3 = MCRandom.randomInt(0, identitiesList.length - 1);
    const identityChooser4 = MCRandom.randomInt(0, identitiesList.length - 1);

    /* END VARIABLES AND CONSTANTS */
    if (
      identityChooser1 !== identityChooser2 &&
      identityChooser3 !== identityChooser4 &&
      identityChooser1 !== identityChooser3 &&
      identityChooser2 !== identityChooser4
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(`Choose the correct identities:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`${identitiesList[identityChooser1][0]}`);
      question.addAnswer(answer1, identitiesList[identityChooser1][1]);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`${identitiesList[identityChooser2][0]}`);
      question.addAnswer(answer2, identitiesList[identityChooser2][1]);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`${identitiesList[identityChooser3][0]}`);
      question.addAnswer(answer3, identitiesList[identityChooser3][1]);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`${identitiesList[identityChooser4][0]}`);
      question.addAnswer(answer4, identitiesList[identityChooser4][1]);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_Trigonometry_Proofs_MCQ_Q3() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const scalar = MCRandom.randomInt(2, 9);

    const identitiesList = [
      [`$\\sin(A \\pm B) = \\sin(A)\\cos(B) \\pm \\cos(A)\\sin(B)$`, true],
      [`$\\sin(A \\pm B) = \\sin(A)\\cos(B) \\mp \\cos(A)\\sin(B)$`, false],
      [`$\\cos(A \\pm B) = \\cos(A)\\cos(B) \\mp \\sin(A)\\sin(B)$`, true],
      [`$\\cos(A \\pm B) = \\cos(A)\\cos(B) \\pm \\sin(A)\\sin(B)$`, false],
      [
        `$\\displaystyle \\tan(A \\pm B) = \\frac{\\tan(A) \\pm \\tan(B)}{1 \\mp \\tan(A)\\tan(B)}$`,
        true,
      ],
      [
        `$\\displaystyle \\tan(A \\pm B) = \\frac{\\tan(A) \\mp \\tan(B)}{1 \\pm \\tan(A)\\tan(B)}$`,
        false,
      ],
      [`$${scalar}\\sin(2A) = ${2 * scalar}\\sin(A)\\cos(A)$`, true],
      [`$${scalar}\\sin(2A) = ${scalar}\\sin(A)\\cos(A)$`, false],
      [
        `$\\displaystyle ${scalar}\\sin(2A) = ${new MCMaths.Fraction(
          scalar / 2
        ).toString()}\\sin(A)\\cos(A)$`,
        false,
      ],
      [
        `$${scalar}\\cos(2A) = ${scalar}\\cos^{2}(A) - ${scalar}\\sin^{2}(A)$`,
        true,
      ],
      [
        `$${scalar}\\cos(2A) = ${scalar}\\cos^{2}(A) + ${scalar}\\sin^{2}(A)$`,
        false,
      ],
      [
        `$${scalar}\\cos(2A) = ${scalar ** 2}\\cos^{2}(A) - ${
          scalar ** 2
        }\\sin^{2}(A)$`,
        false,
      ],
      [
        `$\\displaystyle ${scalar}\\sin^{2}(A) = \\frac{1}{2}(${scalar} - ${scalar}\\cos(2A))$`,
        true,
      ],
      [
        `$\\displaystyle ${scalar}\\sin^{2}(A) = (${scalar ** 2} - ${
          scalar ** 2
        }\\cos(2A))$`,
        false,
      ],
      [
        `$\\displaystyle ${scalar}\\sin^{2}(A) = \\frac{1}{2}(${scalar} + ${scalar}\\cos(2A))$`,
        false,
      ],
      [
        `$\\displaystyle ${scalar}\\cos^{2}(A) = \\frac{1}{2}(${scalar} + ${scalar}\\cos(2A))$`,
        true,
      ],
      [
        `$\\displaystyle ${scalar}\\cos^{2}(A) = (${scalar ** 2} + ${
          scalar ** 2
        }\\cos(2A))$`,
        false,
      ],
      [
        `$\\displaystyle ${scalar}\\cos^{2}(A) = \\frac{1}{2}(${scalar} - ${scalar}\\cos(2A))$`,
        false,
      ],
      [
        `$\\displaystyle ${scalar}\\cos(\\frac{A}{2}) = \\pm \\sqrt{\\frac{${
          scalar ** 2
        }-${scalar ** 2}\\cos(A)}{2}}$`,
        true,
      ],
      [
        `$\\displaystyle ${scalar}\\cos(\\frac{A}{2}) = \\pm \\sqrt{\\frac{${scalar}-${scalar}\\cos(A)}{2}}$`,
        false,
      ],
      [
        `$\\displaystyle ${scalar}\\cos(\\frac{A}{2}) = \\pm \\sqrt{\\frac{${
          scalar ** 2
        }+${scalar ** 2}\\cos(A)}{2}}$`,
        false,
      ],
      [
        `$\\displaystyle ${scalar}\\sin(\\frac{A}{2}) = \\pm \\sqrt{\\frac{${
          scalar ** 2
        }+${scalar ** 2}\\cos(A)}{2}}$`,
        true,
      ],
      [
        `$\\displaystyle ${scalar}\\sin(\\frac{A}{2}) = \\pm \\sqrt{\\frac{${scalar}+${scalar}\\cos(A)}{2}}$`,
        false,
      ],
      [
        `$\\displaystyle ${scalar}\\sin(\\frac{A}{2}) = \\pm \\sqrt{\\frac{${
          scalar ** 2
        }-${scalar ** 2}\\cos(A)}{2}}$`,
        false,
      ],
    ];

    const identityChooser1 = MCRandom.randomInt(0, identitiesList.length - 1);
    const identityChooser2 = MCRandom.randomInt(0, identitiesList.length - 1);
    const identityChooser3 = MCRandom.randomInt(0, identitiesList.length - 1);
    const identityChooser4 = MCRandom.randomInt(0, identitiesList.length - 1);

    /* END VARIABLES AND CONSTANTS */
    if (
      identityChooser1 !== identityChooser2 &&
      identityChooser3 !== identityChooser4 &&
      identityChooser1 !== identityChooser3 &&
      identityChooser2 !== identityChooser4
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(`Choose the correct identities:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`${identitiesList[identityChooser1][0]}`);
      question.addAnswer(answer1, identitiesList[identityChooser1][1]);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`${identitiesList[identityChooser2][0]}`);
      question.addAnswer(answer2, identitiesList[identityChooser2][1]);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`${identitiesList[identityChooser3][0]}`);
      question.addAnswer(answer3, identitiesList[identityChooser3][1]);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`${identitiesList[identityChooser4][0]}`);
      question.addAnswer(answer4, identitiesList[identityChooser4][1]);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_Trigonometry_Proofs_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(`Choose the correct line of working:`);
      question.addParagraph(`Q: Prove that $\\cos(2x) = 1 - 2\\sin^{2}(x)$`);
      question.addParagraph(`A: $\\cos(2x) = \\cos^{2}(x) - \\sin^{2}(x)$`);
      question.addParagraph(`<Insert correct line of working here>`);
      question.addParagraph(`$= 1 - 2\\sin^{2}(x)$`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$= (1 - \\sin^{2}(x)) - \\sin^{2}(x)$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$= \\cos^{2}(x) - (1 - \\cos^{2}(x))$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$= (1 + \\sin^{2}(x)) - \\sin^{2}(x)$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$= (1 - \\sin(x))^2 - \\sin^{2}(x)$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_Proofs_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(`Choose the correct lines of working:`);
      question.addParagraph(
        `Q: Prove that $\\cot(2x) \\equiv \\frac{1}{2}(\\cot(x) - \\tan(x))$`
      );
      question.addParagraph(
        `A: $\\displaystyle \\cot(2x) = \\frac{\\cos(2x)}{\\sin(2x)}$`
      );
      question.addParagraph(
        `$\\displaystyle = \\frac{\\cos^{2}(x) - \\sin^{2}(x)}{2\\sin(x)\\cos(x)}$`
      );
      question.addParagraph(`<Insert correct lines of working here>`);
      question.addParagraph(
        `$\\displaystyle = \\frac{1}{2}\\cot(x) - \\frac{1}{2}\\tan(x)$`
      );
      question.addParagraph(
        `$\\displaystyle = \\frac{1}{2}(\\cot(x) - \\tan(x))$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$\\displaystyle = \\frac{\\cos^{2}(x)}{2\\sin(x)\\cos(x)} - \\frac{\\sin^{2}(x)}{2\\sin(x)\\cos(x)}$`
      );
      answer1.addParagraph(`$\\text{ }$`);
      answer1.addParagraph(
        `$\\displaystyle = \\frac{\\cos(x)}{2\\sin(x)} - \\frac{\\sin(x)}{2\\cos(x)}$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$\\displaystyle = \\frac{\\cos^{2}(x)}{2\\sin(x)\\cos(x)} + \\frac{\\sin^{2}(x)}{2\\sin(x)\\cos(x)}$`
      );
      answer2.addParagraph(`$\\text{ }$`);
      answer2.addParagraph(
        `$\\displaystyle = \\frac{\\cos(x)}{2\\sin(x)} - \\frac{\\sin(x)}{2\\cos(x)}$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$\\displaystyle = \\frac{\\cos^{2}(x)}{2\\sin(x)\\cos(x)} - \\frac{\\sin^{2}(x)}{2\\sin(x)\\cos(x)}$`
      );
      answer3.addParagraph(`$\\text{ }$`);
      answer3.addParagraph(
        `$\\displaystyle = \\frac{\\cos(x)}{\\sin(x)} - \\frac{\\sin(x)}{\\cos(x)}$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$\\displaystyle = \\frac{\\cos(x)}{2\\sin(x)\\cos(x)} - \\frac{\\sin(x)}{2\\sin(x)\\cos(x)}$`
      );
      answer4.addParagraph(`$\\text{ }$`);
      answer4.addParagraph(
        `$\\displaystyle = \\frac{\\cos(x)}{2\\sin(x)} - \\frac{\\sin(x)}{2\\cos(x)}$`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_Proofs_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(`Choose the correct lines of working:`);
      question.addParagraph(
        `Q: Prove that $\\cos(3\\theta) \\equiv 4\\cos^{3}(\\theta) - 3\\cos(\\theta)$`
      );
      question.addParagraph(
        `A: $\\cos(3\\theta) = \\cos(2\\theta + \\theta) $`
      );
      question.addParagraph(
        `$= \\cos(2\\theta)\\cos(\\theta) - \\sin(2\\theta)\\sin(\\theta)$`
      );
      question.addParagraph(
        `$= [\\cos^{2}(\\theta) - \\sin^{2}(\\theta)]\\cos(\\theta) - [2\\sin(\\theta)\\cos(\\theta)]\\sin(\\theta)$`
      );
      question.addParagraph(`<Insert correct lines of working here>`);
      question.addParagraph(
        `$= 2\\cos^{3}(\\theta) - \\cos(\\theta) - 2\\cos(\\theta) + 2\\cos^{3}(\\theta)$`
      );
      question.addParagraph(`$= 4\\cos^{3}(\\theta) - 3\\cos(\\theta)$`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$= [\\cos^{2}(\\theta) - (1 - \\cos^{2}(\\theta))]\\cos(\\theta) - 2\\sin^{2}(\\theta)\\cos(\\theta)$`
      );
      answer1.addParagraph(`$\\text{ }$`);
      answer1.addParagraph(
        `$= [2\\cos^{2}(\\theta) - 1]\\cos(\\theta) - 2(1 - \\cos^{2}(\\theta))\\cos(\\theta)$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$= [(1 - \\sin^{2}(\\theta)) - \\sin^{2}(\\theta)]\\cos(\\theta) - 2\\sin^{2}(\\theta)\\cos(\\theta)$`
      );
      answer2.addParagraph(`$\\text{ }$`);
      answer2.addParagraph(
        `$= [1 - 2\\sin^{2}(\\theta)]\\cos(\\theta) - 2(1 - \\cos^{2}(\\theta))\\cos(\\theta)$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$= [\\cos^{2}(\\theta) - (1 - \\cos^{2}(\\theta))]\\cos(\\theta) - 2\\sin^{2}(\\theta)\\cos(\\theta)$`
      );
      answer3.addParagraph(`$\\text{ }$`);
      answer3.addParagraph(
        `$= [2\\cos^{2}(\\theta) - 1]\\cos(\\theta) - 2(1 - \\cos(\\theta))^{2}\\cos(\\theta)$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$= [\\cos^{2}(\\theta) - (1 - \\cos^{2}(\\theta))]\\cos(\\theta) + 2\\sin^{2}(\\theta)\\cos(\\theta)$`
      );
      answer4.addParagraph(`$\\text{ }$`);
      answer4.addParagraph(
        `$= [2\\cos^{2}(\\theta) - 1]\\cos(\\theta) + 2(1 - \\cos^{2}(\\theta))\\cos(\\theta)$`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_Proofs_MCQ_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(`Choose the correct lines of working:`);
      question.addParagraph(
        `Q: Prove that $\\sin(3\\theta) \\equiv 3\\sin(\\theta) - 4\\sin^{3}(\\theta)$`
      );
      question.addParagraph(
        `A: $\\sin(3\\theta) = \\sin(2\\theta + \\theta) $`
      );
      question.addParagraph(
        `$= \\sin(2\\theta)\\cos(\\theta) + \\cos(2\\theta)\\sin(\\theta)$`
      );
      question.addParagraph(
        `$= [2\\sin(\\theta)\\cos(\\theta)]\\cos(\\theta) + [\\cos^{2}(\\theta) - \\sin^{2}(\\theta)]\\sin(\\theta)$`
      );
      question.addParagraph(`<Insert correct lines of working here>`);
      question.addParagraph(
        `$= 2\\sin(\\theta) - 2\\sin^{3}(\\theta) + \\sin(\\theta) - 2\\sin^{3}(\\theta)$`
      );
      question.addParagraph(`$= 3\\sin(\\theta) - 4\\sin^{2}(\\theta)$`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$= 2\\sin(\\theta)\\cos^{2}(\\theta) + [(1 - \\sin^{2}(\\theta)) - \\sin^{2}(\\theta)]\\sin(\\theta)$`
      );
      answer1.addParagraph(`$\\text{ }$`);
      answer1.addParagraph(
        `$= 2\\sin(\\theta)(1 - \\sin^{2}(\\theta)) + [1 - 2\\sin^{2}(\\theta)]\\sin(\\theta)$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$= 2\\sin(\\theta)\\cos^{2}(\\theta) + [\\cos^{2}(\\theta) - (1 - \\cos^{2}(\\theta))]\\sin(\\theta)$`
      );
      answer2.addParagraph(`$\\text{ }$`);
      answer2.addParagraph(
        `$= 2\\sin(\\theta)(1 - \\sin^{2}(\\theta)) + [2\\cos^{2}(\\theta) - 1]\\sin(\\theta)$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$= 2\\sin(\\theta)\\cos^{2}(\\theta) + [(1 - \\sin^{2}(\\theta)) - \\sin^{2}(\\theta)]\\sin(\\theta)$`
      );
      answer3.addParagraph(`$\\text{ }$`);
      answer3.addParagraph(
        `$= 2\\sin(\\theta)(1 - \\sin^{2}(\\theta)) + [1 + 2\\sin^{2}(\\theta)]\\sin(\\theta)$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$= 2\\sin(\\theta)\\cos^{2}(\\theta) + [(1 - \\sin^{2}(\\theta)) - \\sin^{2}(\\theta)]\\sin(\\theta)$`
      );
      answer4.addParagraph(`$\\text{ }$`);
      answer4.addParagraph(
        `$= 2\\sin(\\theta)(1 - \\sin(\\theta))^{2} + [1 - 2\\sin^{2}(\\theta)]\\sin(\\theta)$`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_SmallAngleApproximation_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const partACoefficient =
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const beta = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const partBCoefficient =
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const working1 = new MCMaths.Fraction(
      (-1 * beta ** 2) / (2 * partBCoefficient)
    );
    const partBAnswer = new MCMaths.Fraction(
      partACoefficient * working1.toFloat()
    );

    /* END VARIABLES AND CONSTANTS */
    if (`${partBAnswer.numerator}`.length <= 2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given that $x$ is small:`);
      question.addParagraph(
        "question",
        `a) Find the value of $\\displaystyle \\frac{${partACoefficient}\\sin(x)}{x}$`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `b) Hence, or otherwise, find the value of $\\displaystyle \\frac{${partACoefficient}\\sin(x)\\cos(${beta}x)-${partACoefficient}\\sin(x)}{${partBCoefficient}x^3}$ to $3$ significant figures.`
        )
      );

      question.addParagraph(
        "solution",
        `a) Since $\\sin(x) \\approx x$ for small angles:`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{${partACoefficient}\\sin(x)}{x} \\approx \\frac{${partACoefficient}x}{x}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{${partACoefficient}x}{x} = ${partACoefficient}$`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `b) $\\displaystyle \\frac{${partACoefficient}\\sin(x)\\cos(${beta}x)-${partACoefficient}\\sin(x)}{${partBCoefficient}x^3} = \\frac{${partACoefficient}\\sin(x)}{x}\\left(\\frac{\\cos(${beta}x)-1}{${partBCoefficient}x^2}\\right)$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = ${partACoefficient}\\left(\\frac{\\cos(${beta}x)-1}{${partBCoefficient}x^2}\\right)$`
      );
      question.addParagraph(
        "solution",
        `Since $\\displaystyle \\cos(x) = 1 - \\frac{x^2}{2}$ for small angles:`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\frac{\\cos(${beta}x)-1}{${partBCoefficient}x^2} = \\frac{1-\\frac{${
            beta ** 2
          }x^2}{2}-1}{${partBCoefficient}x^2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle = \\frac{-${beta ** 2}x^2}{${
            2 * partBCoefficient
          }x^2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle = ${working1.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `So $\\text{ }\\displaystyle \\frac{${partACoefficient}\\sin(x)\\cos(${beta}x) - ${partACoefficient}\\sin(x)}{${partBCoefficient}x^3} = ${partACoefficient}\\left(${working1.toString()}\\right)$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = ${partBAnswer.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = ${partBAnswer.toFloat().toPrecision(3)}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(partACoefficient, 0, `a) $\\text{ } =$`);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(partBAnswer.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(partBAnswer.toFloat().toPrecision(3)) -
            parseFloat(partBAnswer.toFloat().toPrecision(2))
        ),
        `b) $\\text{ } =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_SmallAngleApproximation_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const partACoefficient =
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const beta = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const partBCoefficient =
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const working1 = new MCMaths.Fraction(
      (-1 * beta ** 2) / (2 * partBCoefficient)
    );
    const partBAnswer = new MCMaths.Fraction(
      partACoefficient * working1.toFloat()
    );

    /* END VARIABLES AND CONSTANTS */
    if (`${partBAnswer.numerator}`.length <= 2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given that $x$ is small:`);
      question.addParagraph(
        "question",
        `a) Find the value of $\\displaystyle \\frac{${partACoefficient}\\tan(x)}{x}$`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `b) Hence, or otherwise, find the value of $\\displaystyle \\frac{${partACoefficient}\\sin(x)\\cos(${beta}x)-${partACoefficient}\\sin(x)}{${partBCoefficient}x^3\\cos(x)}$ to $3$ significant figures.`
        )
      );

      question.addParagraph(
        "solution",
        `a) Since $\\tan(x) \\approx x$ for small angles:`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{${partACoefficient}\\tan(x)}{x} \\approx \\frac{${partACoefficient}x}{x}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{${partACoefficient}x}{x} = ${partACoefficient}$`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `b) $\\displaystyle \\frac{${partACoefficient}\\sin(x)\\cos(${beta}x)-${partACoefficient}\\sin(x)}{${partBCoefficient}x^3\\cos(x)} = \\frac{${partACoefficient}\\tan(x)}{x}\\left(\\frac{\\cos(${beta}x)-1}{${partBCoefficient}x^2}\\right)$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = ${partACoefficient}\\left(\\frac{\\cos(${beta}x)-1}{${partBCoefficient}x^2}\\right)$`
      );
      question.addParagraph(
        "solution",
        `Since $\\displaystyle \\cos(x) = 1 - \\frac{x^2}{2}$ for small angles:`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\frac{\\cos(${beta}x)-1}{${partBCoefficient}x^2} = \\frac{1-\\frac{${
            beta ** 2
          }x^2}{2}-1}{${partBCoefficient}x^2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle = \\frac{-${beta ** 2}x^2}{${
            2 * partBCoefficient
          }x^2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle = ${working1.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `So $\\text{ }\\displaystyle \\frac{${partACoefficient}\\tan(x)\\cos(${beta}x) - ${partACoefficient}\\tan(x)}{${partBCoefficient}x^3} = ${partACoefficient}\\left(${working1.toString()}\\right)$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = ${partBAnswer.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = ${partBAnswer.toFloat().toPrecision(3)}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(partACoefficient, 0, `a) $\\text{ } =$`);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(partBAnswer.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(partBAnswer.toFloat().toPrecision(3)) -
            parseFloat(partBAnswer.toFloat().toPrecision(2))
        ),
        `b) $\\text{ } =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_SmallAngleApproximation_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const beta = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const gamma = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const answer = new MCMaths.Fraction(alpha ** 2 / (2 * beta * gamma));

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given that $\\theta$ is small:`);
      question.addParagraph(
        "question",
        `Find the value of $\\displaystyle A = \\frac{1-\\cos(${alpha}\\theta)}{${beta}\\theta\\sin(${gamma}\\theta)}$ to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{1-\\cos(${alpha}\\theta)}{${beta}\\theta\\sin(${gamma}\\theta)} = \\frac{1-\\cos(${alpha}\\theta)}{${beta}\\theta(${gamma}\\theta)}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = \\frac{1-\\cos(${alpha}\\theta)}{${
          beta * gamma
        }\\theta^{2}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = \\frac{1-\\left(1-\\frac{(${alpha}\\theta)^{2}}{2}\\right)}{${
          beta * gamma
        }\\theta^{2}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = \\frac{${alpha ** 2}\\theta^{2}}{${
          2 * beta * gamma
        }\\theta^{2}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = ${answer.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = ${answer.toFloat().toPrecision(3)}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(answer.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(answer.toFloat().toPrecision(3)) -
            parseFloat(answer.toFloat().toPrecision(2))
        ),
        `$A = $`
      );
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_SmallAngleApproximation_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(2, 9);
    const gamma = MCRandom.randomInt(2, 9);

    const working1 = new MCMaths.Fraction(2 / gamma ** 2);
    const partAAnswer_1 = Math.sqrt(working1.toFloat());
    const partAAnswer_2 = -partAAnswer_1;
    const working2 = new MCMaths.Fraction(alpha * beta * 0.5);

    /* END VARIABLES AND CONSTANTS */
    if (alpha < beta && beta < gamma) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Solve the equation $2-${gamma ** 2}x^2=0$`
      );
      question.addParagraph(
        "question",
        `Given that $\\theta$ is small and $\\theta \\neq 0$:`
      );
      question.addParagraph("question", `b) Solve the following equation:`);
      question.addParagraph(
        "question",
        `$\\displaystyle \\tan(${alpha}\\theta)\\sin(${beta}\\theta)\\cos(${gamma}\\theta) = 0$`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph("solution", `a) $2-${gamma ** 2}x^2=0$`);
      question.addParagraph("solution", `$\\implies ${gamma ** 2}x^2 = 2$`);
      question.addParagraph("solution", `$x^2 = ${working1.toString()}$`);
      question.addParagraph(
        "solution",
        `$x = \\pm \\sqrt{${working1.toString()}}$`
      );
      question.addParagraph(
        "solution",
        `$x = ${partAAnswer_1.toPrecision(
          3
        )}, \\text{ } x = ${partAAnswer_2.toPrecision(3)}$`
      );

      question.addParagraph(
        "solution",
        `b) $\\displaystyle \\tan(${alpha}\\theta)\\sin(${beta}\\theta)\\cos(${gamma}\\theta) = (${alpha}\\theta)(${beta}\\theta)\\left(1-\\frac{(${gamma}\\theta)^2}{2}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$= ${working2.toString()}\\theta^2\\left(2-${
          gamma ** 2
        }\\theta^2\\right)$`
      );
      question.addParagraph("solution", `Since $\\theta \\neq 0$:`);
      question.addParagraph("solution", `$2-${gamma ** 2}\\theta^2 = 0$`);
      question.addParagraph("solution", `Using part a):`);
      question.addParagraph(
        "solution",
        `$\\theta = ${partAAnswer_1.toPrecision(
          3
        )}, \\text{ } \\theta = ${partAAnswer_2.toPrecision(3)}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(partAAnswer_1.toPrecision(3)),
          parseFloat(partAAnswer_2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(partAAnswer_1.toPrecision(3)) -
            parseFloat(partAAnswer_1.toPrecision(2))
        ),
        `a) $\\text{ } x = $`
      );
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        [
          parseFloat(partAAnswer_1.toPrecision(3)),
          parseFloat(partAAnswer_2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(partAAnswer_1.toPrecision(3)) -
            parseFloat(partAAnswer_1.toPrecision(2))
        ),
        `a) $\\text{ } \\theta = $`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_SmallAngleApproximation_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(2, 9);
    const rhsConstant = new MCMaths.Fraction(1 / MCRandom.randomInt(4, 12));

    const working1 = new MCMaths.Fraction(2 * rhsConstant.toFloat());
    const working2 = new MCMaths.Fraction(working1.toFloat() / alpha);
    const theta_1 = Math.sqrt(working2.toFloat());
    const theta_2 = -theta_1;

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Solve the equation $${alpha}x^2 - ${working1.toString()} = 0$`
      );
      question.addParagraph(
        "question",
        `b) Given that $\\theta$ is small, solve the following equation:`
      );
      question.addParagraph(
        "question",
        `$\\displaystyle \\frac{${alpha}\\sin^{2}(\\theta)\\cos(${beta}\\theta)}{2-${
          beta ** 2
        }\\theta} = ${rhsConstant.toString()}$`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $${alpha}x^2 - ${working1.toString()} = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\implies ${alpha}x^2 = ${working1.toString()} $`
      );
      question.addParagraph("solution", `$x^2 = ${working2.toString()} $`);
      question.addParagraph(
        "solution",
        `$x = \\pm\\sqrt{${working2.toString()}} $`
      );
      question.addParagraph(
        "solution",
        `$x = ${theta_1.toPrecision(3)}, \\text{ } x = ${theta_2.toPrecision(
          3
        )} $`
      );

      question.addParagraph(
        "solution",
        `b) $\\displaystyle \\frac{${alpha}\\sin^{2}(\\theta)\\cos(${beta}\\theta)}{2-${
          beta ** 2
        }\\theta} = ${rhsConstant.toString()}$`
      );
      question.addParagraph("solution", `Since $\\theta$ is small:`);
      question.addParagraph(
        "solution",
        `$\\implies \\displaystyle \\frac{${alpha}\\theta^{2}\\left(1-\\frac{(${beta}\\theta)^2}{2}\\right)}{2-${
          beta ** 2
        }\\theta} = ${rhsConstant.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{${alpha}\\theta^{2}\\left(2-${
          beta ** 2
        }\\theta\\right)}{2-${beta ** 2}\\theta} = ${working1.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$${alpha}\\theta^2 = ${working1.toString()} $`
      );
      question.addParagraph("solution", `From part a):`);
      question.addParagraph(
        "solution",
        `$\\implies \\theta = ${theta_1.toPrecision(
          3
        )}, \\text{ } \\theta = ${theta_2.toPrecision(3)} $`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(theta_1.toPrecision(3)),
          parseFloat(theta_2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(theta_1.toPrecision(3)) -
            parseFloat(theta_1.toPrecision(2))
        ),
        `a) $\\text{ } x = $`
      );
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        [
          parseFloat(theta_1.toPrecision(3)),
          parseFloat(theta_2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(theta_1.toPrecision(3)) -
            parseFloat(theta_1.toPrecision(2))
        ),
        `b) $\\text{ } \\theta = $`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_SmallAngleApproximation_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const beta = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const gamma = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const working1 = alpha * beta;

    const answer = new MCMaths.Fraction(working1 / gamma);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Given that $x$ is small, find the approximate value of $\\displaystyle A = \\frac{\\tan^{2}(\\sqrt{${alpha}}x)\\sin^{2}(\\sqrt{${beta}}x)}{${gamma}x^4}$`
      );
      question.addParagraph(
        "question",
        `Give your answer to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{\\tan^{2}(\\sqrt{${alpha}}x)\\sin^{2}(\\sqrt{${beta}}x)}{${gamma}x^4} \\approx \\frac{(\\sqrt{${alpha}}x)^{2}(\\sqrt{${beta}}x)^{2}}{${gamma}x^4}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = \\frac{${working1}x^4}{${gamma}x^4}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = ${answer.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle = ${answer.toFloat().toPrecision(3)}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(answer.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(answer.toFloat().toPrecision(3)) -
            parseFloat(answer.toFloat().toPrecision(2))
        ),
        `$A = $`
      );
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_SmallAngleApproximation_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Given that $\\theta$ is small, $\\sin^{2}(${alpha}\\theta) \\approx$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${alpha ** 2}\\theta^2$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${alpha}\\theta^2$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${alpha ** 2}\\theta$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${-1 * alpha ** 2}\\theta^2$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_SmallAngleApproximation_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Given that $\\theta$ is small, $\\tan^{2}(${alpha}\\theta) \\approx$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${alpha ** 2}\\theta^2$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${alpha}\\theta^2$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${alpha ** 2}\\theta$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${-1 * alpha ** 2}\\theta^2$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_SmallAngleApproximation_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Given that $\\theta$ is small, $2\\cos(${alpha}\\theta) \\approx$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$2-${alpha ** 2}\\theta^2$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$2+${alpha ** 2}\\theta^2$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(MCMaths.cleaner(`$2-${alpha}\\theta^2$`));
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$2+${alpha ** 2}\\theta$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_SmallAngleApproximation_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Given that $\\theta$ is small, $\\displaystyle \\frac{${alpha}\\sin(\\theta)}{\\theta} \\approx$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${alpha}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${alpha ** 2}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${alpha}\\theta$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${-alpha}\\theta$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_SmallAngleApproximation_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Given that $\\theta$ is small, $\\displaystyle \\frac{${alpha}\\tan(\\theta)}{\\theta} \\approx$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${alpha}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${alpha ** 2}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${alpha}\\theta$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${-alpha}\\theta$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_SmallAngleApproximation_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = 2 * MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        MCMaths.cleaner(
          `Given that $\\theta$ is small, $\\displaystyle \\frac{${alpha}\\cos(\\theta)-${alpha}}{\\theta^2} \\approx$`
        )
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${alpha / 2}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${alpha}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${alpha}\\theta$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${-alpha}\\theta$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(10, 30) * (Math.PI / 180);
    const gamma = MCRandom.randomInt(2, 9);

    const thetaRadians1 = Math.asin(gamma / alpha) + beta;
    const thetaRadians2 = Math.PI - thetaRadians1;

    const sinTheta = Math.sin(thetaRadians1);

    /* END VARIABLES AND CONSTANTS */
    if (gamma / alpha < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `a) Solve the following equation:`);
      question.addParagraph(
        "question",
        `$${alpha}\\sin(\\theta - ${beta.toPrecision(
          3
        )}^{c}) - ${gamma} = 0, \\text{ } 0 < \\theta < \\pi$`
      );
      question.addParagraph(
        "question",
        `b) Hence find the value of $\\sin(\\theta)$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $${alpha}\\sin(\\theta - ${beta.toPrecision(
          3
        )}^{c}) - ${gamma} = 0$`
      );
      question.addParagraph(
        "solution",
        `$${alpha}\\sin(\\theta - ${beta.toPrecision(3)}^{c})  = ${gamma}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\sin(\\theta - ${beta.toPrecision(
          3
        )}^{c})  = ${new MCMaths.Fraction(gamma / alpha).toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta - ${beta.toPrecision(
          3
        )}^{c}  = \\sin^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\sin^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right) + ${beta.toPrecision(3)}^{c} $`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaRadians1.toPrecision(3)}^{c} $`
      );
      question.addParagraph(
        "solution",
        `Also, $\\theta = \\pi - ${thetaRadians1.toPrecision(3)} $`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaRadians2.toPrecision(3)}^{c}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = ${thetaRadians1.toPrecision(
          3
        )}^{c}, \\text{ } ${thetaRadians2.toPrecision(3)}^{c} $`
      );

      question.addParagraph(
        "solution",
        `b) $\\sin(\\theta) = \\sin(\\sin^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right) + ${beta.toPrecision(3)}^{c}) $`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sin(\\theta) = ${sinTheta.toPrecision(3)} $`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(thetaRadians1.toPrecision(3)),
          parseFloat(thetaRadians2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(thetaRadians1.toPrecision(3)) -
            parseFloat(thetaRadians1.toPrecision(2))
        ),
        "a) $\\text{ } \\theta = $"
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(sinTheta.toPrecision(3)),
        Math.abs(
          parseFloat(sinTheta.toPrecision(3)) -
            parseFloat(sinTheta.toPrecision(2))
        ),
        "b) $\\text{ } \\sin(\\theta) = $"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(10, 30) * (Math.PI / 180);
    const gamma = MCRandom.randomInt(2, 9);

    const thetaRadians1 = Math.asin(alpha / gamma) + beta;
    const thetaRadians2 = Math.PI - thetaRadians1;

    const cosecTheta = 1 / Math.sin(thetaRadians1);

    /* END VARIABLES AND CONSTANTS */
    if (gamma / alpha > 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `a) Solve the following equation:`);
      question.addParagraph(
        "question",
        `$${alpha}\\cosec(\\theta - ${beta.toPrecision(
          3
        )}^{c}) - ${gamma} = 0, \\text{ } 0 < \\theta < \\pi$`
      );
      question.addParagraph(
        "question",
        `b) Hence find the value of $\\sin(\\theta)$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $${alpha}\\cosec(\\theta - ${beta.toPrecision(
          3
        )}^{c}) - ${gamma} = 0$`
      );
      question.addParagraph(
        "solution",
        `$${alpha}\\cosec(\\theta - ${beta.toPrecision(3)}^{c})  = ${gamma}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cosec(\\theta - ${beta.toPrecision(
          3
        )}^{c})  = ${new MCMaths.Fraction(gamma / alpha).toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\sin(\\theta - ${beta.toPrecision(
          3
        )}^{c})  = ${new MCMaths.Fraction(alpha / gamma).toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta - ${beta.toPrecision(
          3
        )}^{c}  = \\sin^{-1}\\left(${new MCMaths.Fraction(
          alpha / gamma
        ).toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\sin^{-1}\\left(${new MCMaths.Fraction(
          alpha / gamma
        ).toString()}\\right) + ${beta.toPrecision(3)}^{c} $`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaRadians1.toPrecision(3)}^{c} $`
      );
      question.addParagraph(
        "solution",
        `Also, $\\theta = \\pi - ${thetaRadians1.toPrecision(3)} $`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaRadians2.toPrecision(3)}^{c}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = ${thetaRadians1.toPrecision(
          3
        )}^{c}, \\text{ } ${thetaRadians2.toPrecision(3)}^{c} $`
      );

      question.addParagraph(
        "solution",
        `b) $\\displaystyle \\cosec(\\theta) = \\frac{1}{\\sin(\\sin^{-1}\\left(${new MCMaths.Fraction(
          alpha / gamma
        ).toString()}\\right) + ${beta.toPrecision(3)}^{c})} $`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\cosec(\\theta) = ${cosecTheta.toPrecision(3)} $`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(thetaRadians1.toPrecision(3)),
          parseFloat(thetaRadians2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(thetaRadians1.toPrecision(3)) -
            parseFloat(thetaRadians1.toPrecision(2))
        ),
        "a) $\\text{ } \\theta = $"
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(cosecTheta.toPrecision(3)),
        Math.abs(
          parseFloat(cosecTheta.toPrecision(3)) -
            parseFloat(cosecTheta.toPrecision(2))
        ),
        "b) $\\text{ } \\cosec(\\theta) = $"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q11() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const angleCAB = MCRandom.randomInt(30, 70);
    const angleABC = MCRandom.randomInt(30, 70);
    const angleACB = 180 - angleCAB - angleABC;

    const angleCABRadians = angleCAB * (Math.PI / 180);
    const angleABCRadians = angleABC * (Math.PI / 180);
    const angleACBRadians = angleACB * (Math.PI / 180);

    const angleCABLabel = `${new MCMaths.Fraction(
      angleCAB * (1 / 180)
    ).toString()}\\pi`;
    const angleABCLabel = `${new MCMaths.Fraction(
      angleABC * (1 / 180)
    ).toString()}\\pi`;
    const angleACBLabel = `${new MCMaths.Fraction(
      angleACB * (1 / 180)
    ).toString()}\\pi`;

    const sideAC = MCRandom.randomInt(2, 9);
    const sideAB =
      (sideAC / Math.sin(angleABCRadians)) * Math.sin(angleACBRadians);
    const sideBCSquared =
      sideAC ** 2 +
      sideAB ** 2 -
      2 * sideAC * sideAB * Math.cos(angleCABRadians);
    const sideBC = Math.sqrt(sideBCSquared);

    const answer = 0.5 * sideAC * sideAB * Math.sin(angleCABRadians);

    /* END VARIABLES AND CONSTANTS */
    if (angleCAB + angleABC + angleACB === 180) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const myImage = new MCQuestion.Image("Matt/triangle1.svg", "width", 15);
      myImage.addOverlay(`$${sideAC}m$`, 20, 45);
      myImage.addOverlay(`$${angleCABLabel}$`, 55, 20);
      myImage.addOverlay(`$${angleABCLabel}$`, 77, 30);

      myImage.addOverlay(`$A$`, 50, -2);
      myImage.addOverlay(`$B$`, 103, 30);
      myImage.addOverlay(`$C$`, -5, 100);

      question.addParagraph(
        "question",
        `A triangular region is modelled by the triangle $ABC$. The length $AC$ is $${sideAC}m$.`
      );
      question.addImage("question", myImage);
      question.addParagraph(
        "question",
        `Given that angle $CAB$ is $${angleCABLabel}$ radians and angle $ABC$ is $${angleABCLabel}$ radians,`
      );
      question.addParagraph(
        "question",
        `$a)$ Calculate the area of the triangular region.`
      );
      question.addParagraph(
        "question",
        `$b)$ Calculate the length of the side $CB$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `$a)$ $(AB) = \\sin{(${angleACBLabel})}\\times \\frac{${sideAC}}{\\sin{(${angleABCLabel})}}$`
      );
      question.addParagraph("solution", `$ = ${sideAB.toPrecision(3)}$`);
      question.addParagraph(
        "solution",
        `Area $\\displaystyle = \\frac{1}{2}(${sideAC.toPrecision(
          3
        )})(${sideAB.toPrecision(3)})\\sin{(${angleCABLabel})}$`
      );
      question.addParagraph("solution", `$ = ${answer.toPrecision(3)}m^{2}$`);

      question.addParagraph(
        "solution",
        `$b)$ $CB = \\sqrt{${sideAC}^{2} + ${sideAB.toPrecision(
          3
        )}^{2} - 2(${sideAC})(${sideAB.toPrecision(
          3
        )})\\cos{(${angleCABLabel})}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore CB = ${sideBC.toPrecision(3)}m$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(answer.toPrecision(3)),
        Math.abs(
          parseFloat(answer.toPrecision(3)) - parseFloat(answer.toPrecision(2))
        ),
        `$a) $`,
        "before"
      );
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(sideBC.toPrecision(3)),
        Math.abs(
          parseFloat(sideBC.toPrecision(3)) - parseFloat(sideBC.toPrecision(2))
        ),
        `$b) $`,
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q12() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const sideBC = MCRandom.randomInt(2, 9);
    const sideAC = MCRandom.randomInt(2, 9);
    const area = MCRandom.randomInt(10, 20);

    const checkMath = (2 * area) / (sideBC * sideAC);
    const angleThetaRadians = Math.asin(checkMath);
    const angleTheta = angleThetaRadians * (180 / Math.PI);

    const angleThetaLabel_1 = `${angleThetaRadians.toPrecision(3)}^{c}`;
    const angleThetaLabel_2 = `${(Math.PI - angleThetaRadians).toPrecision(
      3
    )}^{c}`;

    const sideBA_Squared =
      sideAC ** 2 +
      sideBC ** 2 -
      2 * sideAC * sideBC * Math.cos(angleThetaRadians);
    const sideBA = Math.sqrt(sideBA_Squared);

    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(checkMath) <= 1 && sideBA_Squared > 0 && angleTheta !== 90) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Given a triangle $ABC$, side $BC$ has length $${sideBC}m$, side $AC$ has length $${sideAC}m$ and the area of the triangle is $${area.toPrecision(
          2
        )}m^2$.`
      );
      question.addParagraph("question", `Angle $ACB = \\theta$ radians.`);
      question.addParagraph(
        "question",
        `$a)$ Find the $2$ possible values of $\\theta$.`
      );
      question.addParagraph(
        "question",
        `$b)$ Given that angle $ACB$ is an acute angle, find the length of $BA$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `$a)$ $\\displaystyle ${area.toPrecision(
          2
        )} = \\frac{1}{2}(${sideBC})(${sideAC})\\sin{(\\theta)}$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = \\sin^{-1}{\\left(\\frac{2\\times${area}}{${sideBC}\\times${sideAC}}\\right)} = ${angleThetaLabel_1}, ${angleThetaLabel_2}$`
      );

      question.addParagraph(
        "solution",
        `$b)$ $ ({BA})^2 = ${sideBC}^{2} + ${sideAC}^{2} - 2(${sideBC})(${sideAC})\\cos{(${angleThetaLabel_1})} = ${sideBA_Squared.toPrecision(
          3
        )}$`
      );
      question.addParagraph("solution", `$BA = ${sideBA.toPrecision(3)}m$`);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(parseFloat(angleTheta.toPrecision(3)), 0, `$a) =$`);
      group1.addInput(
        parseFloat((180 - angleTheta).toPrecision(3)),
        Math.abs(
          parseFloat(angleTheta.toPrecision(3)) -
            parseFloat(angleTheta.toPrecision(2))
        ),
        `$a) =$`
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(sideBA.toPrecision(3)),
        Math.abs(
          parseFloat(sideBA.toPrecision(3)) - parseFloat(sideBA.toPrecision(2))
        ),
        `$b)$`,
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q13() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const areaMultiplier = new MCMaths.Fraction(
      1 + MCRandom.randomInt(1, 6) / 10
    );
    const sideAB = MCRandom.randomInt(2, 9);

    const thetaRadians = Math.acos(1 / areaMultiplier.toFloat());
    const theta = thetaRadians * (180 / Math.PI);
    const thetaLabel = `${thetaRadians.toPrecision(3)}^{c}`;

    const areaABD = 0.5 * sideAB * sideAB * Math.sin(thetaRadians);
    const areaABC = 0.5 * sideAB * sideAB * Math.tan(thetaRadians);
    const areaCBD = areaABC - areaABD;

    const working1 = new MCMaths.Fraction(sideAB * sideAB * 0.5).toString();
    const working2 = new MCMaths.Fraction(
      1 / areaMultiplier.toFloat()
    ).toString();

    /* END VARIABLES AND CONSTANTS */
    if (theta > 0 && theta < 90 && areaCBD > 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const myImage = new MCQuestion.Image("Matt/triangle2.svg", "width", 15);

      question.addParagraph(
        "question",
        `The triangle $ABC$ is a right angled triangle.`
      );

      myImage.addOverlay(`$\\theta$`, 86, 17);
      myImage.addOverlay(`$A$`, 90, 0);
      myImage.addOverlay(`$B$`, 90, 100);
      myImage.addOverlay(`$C$`, 2, 100);
      question.addImage("question", myImage);

      question.addParagraph(
        "question",
        `The point $D$ lies on the hypotenuse such that $AD = AB = ${sideAB}cm$.`
      );
      question.addParagraph(
        "question",
        `The area of the triangle $ABC = ${areaMultiplier.toString()}ABD.$`
      );
      question.addParagraph("question", `Angle $CAB = \\theta$ radians.`);
      question.addParagraph("question", `$a)$ Find the value of $\\theta$.`);
      question.addParagraph(
        "question",
        `$b)$ Find the area of the triangle $CBD$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `$a)$ $BC = ${sideAB}\\tan{(\\theta)}$.`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle ABC = \\frac{1}{2}(${sideAB})(${sideAB}\\tan{(\\theta)}) = {${working1}}\\tan{(\\theta)}$.`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle ABD = \\frac{1}{2}(${sideAB})(${sideAB})\\sin{(\\theta)} = {${working1}}\\sin{(\\theta)}$.`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle ABC = ${areaMultiplier.toString()}ABD \\implies {${working1}}\\tan{(\\theta)} = ${areaMultiplier.toString()}\\left({${working1}}\\sin{(\\theta)}\\right)$.`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\therefore \\tan{(\\theta)} = ${areaMultiplier.toString()}\\sin{(\\theta)}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\implies \\sin{(\\theta)}\\left(${areaMultiplier.toString()}\\cos{(\\theta)} - 1\\right) = 0$`
      );
      question.addParagraph(
        "solution",
        `Since $\\displaystyle \\theta \\neq 0 \\implies \\theta = \\cos^{-1}{(${working2})} = ${thetaLabel}$`
      );

      question.addParagraph("solution", `$b)$ $CBD = ABC - ABD$`);
      question.addParagraph(
        "solution",
        `$\\therefore CBD = {${working1}}\\left(\\tan{(${thetaLabel})} - \\sin{(${thetaLabel})}\\right) = ${areaCBD.toPrecision(
          3
        )}cm^{2}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(thetaRadians.toPrecision(3)),
        Math.abs(
          parseFloat(thetaRadians.toPrecision(3)) -
            parseFloat(thetaRadians.toPrecision(2))
        ),
        `$a)$`,
        "before"
      );
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        parseFloat(areaCBD.toPrecision(3)),
        Math.abs(
          parseFloat(areaCBD.toPrecision(3)) -
            parseFloat(areaCBD.toPrecision(2))
        ),
        `$b)$`,
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q14() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    // const sidePQ = MCRandom.randomInt(2,15);
    const sideQR = MCRandom.randomInt(2, 15);
    const sideRP = MCRandom.randomInt(2, 15);
    const theta = MCRandom.randomInt(30, 70);
    const thetaRadians = theta * (Math.PI / 180);

    const sidePQSquared =
      sideQR ** 2 + sideRP ** 2 - 2 * sideQR * sideRP * Math.cos(thetaRadians);
    const sidePQ = Math.sqrt(sidePQSquared);
    const area = 0.5 * sideRP * sideQR * Math.sin(thetaRadians);

    /* END VARIABLES AND CONSTANTS */
    if (sidePQ !== sideQR) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A triangle $PQR$ has sides $PQ$, $QR$, $RP$ with lengths $${sidePQ.toPrecision(
          3
        )}cm$, $${sideQR}cm$ and $${sideRP}cm$ respectively.`
      );
      question.addParagraph(
        "question",
        `Let the angle $PRQ = \\theta$ radians, $0 < \\theta < \\frac{\\pi}{2}$.`
      );
      question.addParagraph("question", `$a)$ Find the value of $\\theta$.`);
      question.addParagraph(
        "question",
        `$b)$ Hence, or otherwise, find the area of the triangle $PQR$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `$a)$ Using $c^2 = a^2 + b^2 -2bc\\cos{(A)}$`
      );
      question.addParagraph(
        "solution",
        `$\\cos{(\\theta)} = \\frac{(${sideRP}^{2} + ${sideQR}^{2} - ${sidePQ.toPrecision(
          3
        )}^{2})}{2(${sideRP})(${sideQR})}$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = \\cos^{-1}{\\left(\\frac{(${sideRP}^{2} + ${sideQR}^{2} - ${sidePQ.toPrecision(
          3
        )}^{2})}{2(${sideRP})(${sideQR})}\\right)}$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaRadians.toPrecision(3)}^{c}$`
      );

      question.addParagraph(
        "solution",
        `$b)$ Area $\\displaystyle = \\frac{1}{2}(${sideQR})(${sideRP}\\sin{(${thetaRadians.toPrecision(
          3
        )}^{c})})$`
      );
      question.addParagraph("solution", `Area $= ${area.toPrecision(3)}cm^2$`);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(thetaRadians.toPrecision(3)),
        Math.abs(
          parseFloat(thetaRadians.toPrecision(3)) -
            parseFloat(thetaRadians.toPrecision(2))
        ),
        `$a)$`,
        "before"
      );
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        parseFloat(area.toPrecision(3)),
        Math.abs(
          parseFloat(area.toPrecision(3)) - parseFloat(area.toPrecision(2))
        ),
        `$b)$`,
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q15() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const theta = MCRandom.randomInt(30, 80);
    const thetaRadians = theta * (Math.PI / 180);
    const sideOA = MCRandom.randomInt(5, 15);
    const sideOB = sideOA;
    const areaOAB = 0.5 * sideOA * sideOB * Math.sin(thetaRadians);
    const sectorPerimeter = (theta / 360) * (2 * Math.PI * sideOA);

    /* END VARIABLES AND CONSTANTS */
    if (theta !== 60 && theta !== 45) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const myImage = new MCQuestion.Image("Matt/sector1.svg", "width", 15);

      question.addParagraph(
        "question",
        `The triangle $OAB$ is a non-equilateral triangle.`
      );
      question.addParagraph(
        "question",
        `The area of the triangle $OAB$ is $${areaOAB.toPrecision(3)}m^2$.`
      );
      question.addParagraph("question", `Side $OA = ${sideOA}m$.`);
      question.addParagraph("question", `Side $OB = ${sideOB}m$.`);
      question.addParagraph(
        "question",
        `Angle $AOB = \\theta$ radians, $0 < \\theta < \\frac{\\pi}{2}$.`
      );

      myImage.addOverlay(`$\\theta$`, 49.5, 80);
      myImage.addOverlay(`$O$`, 49.5, 95);
      myImage.addOverlay(`$A$`, 0, 22);
      myImage.addOverlay(`$B$`, 97, 22);
      question.addImage("question", myImage);

      question.addParagraph("question", `$a)$ Find the angle $\\theta$.`);
      question.addParagraph(
        "question",
        `$b)$ Hence, or otherwise, find the perimeter of the sector.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ to significant figures.`
      );

      question.addParagraph(
        "solution",
        `$a)$ $${areaOAB.toPrecision(
          3
        )} = \\frac{1}{2}(${sideOA})(${sideOB})\\sin{(\\theta)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = \\sin^{-1}{\\left(\\frac{2 \\times ${areaOAB.toPrecision(
          3
        )}}{${sideOA * sideOB}}\\right)}$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaRadians.toPrecision(3)}^{c}$`
      );
      question.addParagraph(
        "solution",
        `$b)$ Since the perimeter of a sector $= r \\theta$`
      );
      question.addParagraph(
        "solution",
        `Perimeter of sector $= ${sideOA} \\times ${thetaRadians.toPrecision(
          3
        )}$`
      );
      question.addParagraph(
        "solution",
        `$= ${sectorPerimeter.toPrecision(3)}m$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(thetaRadians.toPrecision(3)),
        Math.abs(
          parseFloat(thetaRadians.toPrecision(3)) -
            parseFloat(thetaRadians.toPrecision(2))
        ),
        `$a)$`,
        "before"
      );
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        parseFloat(sectorPerimeter.toPrecision(3)),
        Math.abs(
          parseFloat(sectorPerimeter.toPrecision(3)) -
            parseFloat(sectorPerimeter.toPrecision(2))
        ),
        `$b)$`,
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q16() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const theta = MCRandom.randomInt(30, 80);
    const thetaRadians = theta * (Math.PI / 180);
    const sideOC = MCRandom.randomInt(5, 15);
    const sideOA = sideOC / 2;
    const sectorArea = (theta / 360) * (Math.PI * sideOC ** 2);
    const areaOAC = 0.5 * sideOC * sideOA * Math.sin(thetaRadians);
    const shadedArea = sectorArea - areaOAC;

    /* END VARIABLES AND CONSTANTS */
    if (theta !== 60 && theta !== 45) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const myImage = new MCQuestion.Image("Matt/sector2.svg", "width", 20);

      question.addParagraph(
        "question",
        `The sector $OBC$ is drawn such that point $A$ is exactly halfway between $O$ and $B$`
      );
      question.addParagraph("question", `Side $OC = ${sideOC}m$.`);
      question.addParagraph(
        "question",
        `Angle $AOC = \\theta$ radians, $0 < \\theta < \\frac{\\pi}{2}$.`
      );

      myImage.addOverlay(`$\\theta$`, 15, 72);
      myImage.addOverlay(`$O$`, 0, 80);
      myImage.addOverlay(`$A$`, 30, 43);
      myImage.addOverlay(`$B$`, 62, 13);
      myImage.addOverlay(`$C$`, 97, 80);
      question.addImage("question", myImage);

      question.addParagraph(
        "question",
        `Given that the area of the sector is $${sectorArea.toPrecision(
          3
        )}m^2$,`
      );
      question.addParagraph("question", `$a)$ Find the angle $\\theta$.`);
      question.addParagraph(
        "question",
        `$b)$ Hence, or otherwise, find the area of the shaded region.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $2$ to significant figures.`
      );

      question.addParagraph(
        "solution",
        `$a)$ Since the area of a sector $= \\frac{1}{2} r^{2} \\theta$`
      );
      question.addParagraph(
        "solution",
        `$ ${sectorArea.toPrecision(
          3
        )} = \\frac{1}{2} (${sideOC})^{2} \\times \\theta$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\therefore \\theta = \\frac{2 \\times ${sectorArea.toPrecision(
          2
        )}}{${sideOC}^{2}}$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaRadians.toPrecision(2)}^{c}$`
      );

      question.addParagraph(
        "solution",
        `$b)$ Area of $OAC = \\frac{1}{2}(${sideOA})(${sideOC})\\sin{(${thetaRadians.toPrecision(
          2
        )}^{c})}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Shaded region $= ${sectorArea.toPrecision(
          3
        )} - \\frac{1}{2}(${sideOA})(${sideOC})\\sin{(${thetaRadians.toPrecision(
          2
        )}^{c})}$`
      );
      question.addParagraph("solution", `$= ${shadedArea.toPrecision(2)}m^2$`);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(thetaRadians.toPrecision(2)),
        Math.abs(
          parseFloat(thetaRadians.toPrecision(3)) -
            parseFloat(thetaRadians.toPrecision(2))
        ),
        `$a)$`,
        "before"
      );
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        parseFloat(shadedArea.toPrecision(2)),
        Math.abs(
          parseFloat(shadedArea.toPrecision(3)) -
            parseFloat(shadedArea.toPrecision(2))
        ),
        `$b)$`,
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(2, 6);
    const gamma = MCRandom.randomInt(2, 9);
    const tanCoeff = new MCMaths.Fraction(alpha / gamma);

    const thetaRadians1 = (1 / beta) * Math.acos(tanCoeff.toFloat());
    const thetaRadians2 = -thetaRadians1;

    /* END VARIABLES AND CONSTANTS */
    if (tanCoeff.toFloat() < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given the following equation:`);
      question.addParagraph(
        "question",
        `$\\displaystyle ${tanCoeff.toString()}\\tan(${beta}\\theta) = \\sin(${beta}\\theta), \\text{ } -\\frac{\\pi}{2} < \\theta < \\frac{\\pi}{2}$`
      );
      question.addParagraph(
        "question",
        `a) Find the value of $\\sin(${beta}\\theta)$:`
      );
      question.addParagraph(
        "question",
        `b) Hence find all values of $\\theta$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $\\displaystyle ${tanCoeff.toString()}\\tan(${beta}\\theta) = \\sin(${beta}\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{${tanCoeff.numerator}\\sin(${beta}\\theta)}{${tanCoeff.denominator}\\cos(${beta}\\theta)} = \\sin(${beta}\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$${tanCoeff.numerator}\\sin(${beta}\\theta) = ${tanCoeff.denominator}\\sin(${beta}\\theta)\\cos(${beta}\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$\\sin(${beta}\\theta)(${alpha} - ${tanCoeff.denominator}\\cos(${beta}\\theta)) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\sin(${beta}\\theta) = 0, \\text{ } ${alpha} - ${tanCoeff.denominator}\\cos(${beta}\\theta) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sin(${beta}\\theta) = 0$.`
      );
      question.addParagraph(
        "solution",
        `b) $${tanCoeff.numerator} - ${tanCoeff.denominator}\\cos(${beta}\\theta) = 0$`
      );
      question.addParagraph(
        "solution",
        `$${tanCoeff.denominator}\\cos(${beta}\\theta) = ${tanCoeff.denominator}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos(${beta}\\theta) = ${tanCoeff.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle ${beta}\\theta = \\cos^{-1}\\left(${tanCoeff.toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\frac{1}{${beta}}\\cos^{-1}\\left(${tanCoeff.toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaRadians1.toPrecision(3)}^{c}$`
      );
      question.addParagraph(
        "solution",
        `Also, $\\theta = -${thetaRadians1.toPrecision(3)}^{c}$`
      );
      question.addParagraph(
        "solution",
        `And since $\\sin(${beta}\\theta) = 0, \\text{ } \\theta = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = ${thetaRadians1.toPrecision(
          3
        )}^{c}, \\text{ } \\theta = -${thetaRadians1.toPrecision(
          3
        )}^{c}, \\theta = 0$.`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(0, 0, `a) $\\text{ } \\sin(${beta}\\theta) = $`);

      const group2 = new MCQuestion.InputGroup(3);
      group2.addInput(
        [
          parseFloat(thetaRadians1.toPrecision(3)),
          parseFloat(thetaRadians2.toPrecision(3)),
          0,
        ],
        Math.abs(
          parseFloat(thetaRadians1.toPrecision(3)) -
            parseFloat(thetaRadians1.toPrecision(2))
        ),
        "b) $\\text{ } \\theta = $"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const cosCoefficient_1 = MCRandom.randomInt(-7, 7);
    const constantTerm_1 = MCRandom.randomInt(-7, 7);
    const cosCoefficient_2 = cosCoefficient_1;
    const constantTerm_2 = -constantTerm_1;

    const sinCoefficient = MCRandom.randomInt(2, 9);
    const epsilon = MCRandom.randomInt(2, 9) * (Math.PI / 180);

    const p1 = new MCMaths.Polynomial(
      [cosCoefficient_1, constantTerm_1],
      "\\cos(x)"
    );
    const p2 = new MCMaths.Polynomial(
      [cosCoefficient_2, constantTerm_2],
      "\\cos(x)"
    );
    const polynomialProduct = p1.multiply(p2);
    const polynomialProduct_Coefficients = polynomialProduct.getCoefficients();

    const partAAnswer_1 = new MCMaths.Fraction(
      -constantTerm_1 / cosCoefficient_1
    );
    const partAAnswer_2 = new MCMaths.Fraction(
      -constantTerm_2 / cosCoefficient_2
    );
    const partAAnswer_1Float = partAAnswer_1.toFloat();
    const partAAnswer_2Float = partAAnswer_2.toFloat();

    const partBAnswer_1Radians = Math.acos(partAAnswer_1Float);
    const partBAnswer_2Radians = Math.acos(partAAnswer_2Float);
    const partBAnswer_3Radians = -partBAnswer_1Radians;
    const partBAnswer_4Radians = -partBAnswer_2Radians;
    const partBAnswer_5Radians = -epsilon;
    const partBAnswer_6Radians = -Math.PI + epsilon;

    const partBAnswer_Array = [
      parseFloat(partBAnswer_1Radians.toPrecision(3)),
      parseFloat(partBAnswer_2Radians.toPrecision(3)),
      parseFloat(partBAnswer_3Radians.toPrecision(3)),
      parseFloat(partBAnswer_4Radians.toPrecision(3)),
      parseFloat(partBAnswer_5Radians.toPrecision(3)),
      parseFloat(partBAnswer_6Radians.toPrecision(3)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (
      cosCoefficient_1 !== 0 &&
      cosCoefficient_2 !== 0 &&
      constantTerm_1 !== 0 &&
      constantTerm_2 !== 0 &&
      Math.abs(partAAnswer_1Float) < 1 &&
      Math.abs(partAAnswer_2Float) < 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph("question", `Given that:`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$${
            polynomialProduct_Coefficients[0] * sinCoefficient
          }\\cos^{2}(x)\\sin(x+${epsilon.toPrecision(3)}^{c}) = ${
            -1 * polynomialProduct_Coefficients[2] * sinCoefficient
          }\\sin(x+${epsilon.toPrecision(3)}^{c}),\\text{ } -\\pi < x < \\pi$`,
          false,
          false
        )
      );
      question.addParagraph(
        "question",
        `a) Find the $2$ possible values of $\\cos(x)$.`
      );
      question.addParagraph("question", `b) Find all values of $x$.`);
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${
            polynomialProduct_Coefficients[0] * sinCoefficient
          }\\cos^{2}(x)\\sin(x+${epsilon.toPrecision(3)}^{c}) = ${
            -1 * polynomialProduct_Coefficients[2] * sinCoefficient
          }\\sin(x+${epsilon.toPrecision(3)}^{c})$`,
          false,
          false
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${
            polynomialProduct_Coefficients[0] * sinCoefficient
          }\\cos^{2}(x)\\sin(x+${epsilon.toPrecision(3)}^{c})+${
            polynomialProduct_Coefficients[2] * sinCoefficient
          }\\sin(x+${epsilon.toPrecision(3)}^{c}) = 0$`,
          false,
          false
        )
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${sinCoefficient}\\sin(x+${epsilon.toPrecision(3)}^{c})(${
            polynomialProduct_Coefficients[0]
          }\\cos^{2}(x)+${polynomialProduct_Coefficients[2]}) = 0$`,
          false,
          false
        )
      );

      question.addParagraph(
        "solution",
        `$${sinCoefficient}\\sin(x+${epsilon.toPrecision(
          3
        )}^{c})\\left[(${p1.toString()})(${p2.toString()})\\right] = 0$`
      );

      question.addParagraph(
        "solution",
        `$\\sin(x+${epsilon.toPrecision(
          3
        )}^{c})\\left[(${p1.toString()})(${p2.toString()})\\right] = 0$`
      );

      question.addParagraph(
        "solution",
        `$\\therefore \\displaystyle \\cos(x) = ${partAAnswer_1.toString()},\\text{ } \\cos(x) = ${partAAnswer_2.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\cos(x) = ${partAAnswer_1Float.toPrecision(
          3
        )},\\text{ } \\cos(x) = ${partAAnswer_2Float.toPrecision(3)}$.`
      );

      question.addParagraph(
        "solution",
        `b) $\\therefore x = \\cos^{-1}(${partAAnswer_1Float.toPrecision(
          3
        )}),\\text{ } \\cos^{-1}(${partAAnswer_2Float.toPrecision(3)})$`
      );
      question.addParagraph(
        "solution",
        `$x = ${partBAnswer_1Radians.toPrecision(
          3
        )}^{c},\\text{ } x = ${partBAnswer_2Radians.toPrecision(3)}^{c}$`
      );

      question.addParagraph(
        "solution",
        `Also $x = ${partBAnswer_3Radians.toPrecision(
          3
        )}^{c}, \\text{ } x = ${partBAnswer_4Radians.toPrecision(3)}^{c}$.`
      );

      question.addParagraph(
        "solution",
        `And since $\\sin(x+${epsilon.toPrecision(3)}^{c}) = 0$`
      );
      question.addParagraph(
        "solution",
        `$x+${epsilon.toPrecision(3)}^{c} = 0$`
      );
      question.addParagraph("solution", `$x = ${-epsilon.toPrecision(3)}^{c}$`);
      question.addParagraph(
        "solution",
        `Also $x = -\\pi + ${epsilon.toPrecision(3)}$`
      );
      question.addParagraph(
        "solution",
        `Also $x = ${partBAnswer_6Radians.toPrecision(3)}^{c}$`
      );
      question.addParagraph(
        "solution",
        `Therefore all values of $x = ${partBAnswer_Array[0]}^{c},\\text{ } ${partBAnswer_Array[1]}^{c},\\text{ } ${partBAnswer_Array[2]}^{c},\\text{ } ${partBAnswer_Array[3]}^{c},\\text{ } ${partBAnswer_Array[4]}^{c},\\text{ } ${partBAnswer_Array[5]}^{c}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(partAAnswer_1Float.toPrecision(3)),
          parseFloat(partAAnswer_2Float.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(partAAnswer_1Float.toPrecision(3)) -
            parseFloat(partAAnswer_1Float.toPrecision(2))
        ),
        "a) $\\text{ } \\cos(x) = $",
        "before"
      );

      const group2 = new MCQuestion.InputGroup(6);
      group2.addInput(
        partBAnswer_Array,
        Math.abs(
          parseFloat(partBAnswer_Array[0].toPrecision(3)) -
            parseFloat(partBAnswer_Array[0].toPrecision(2))
        ),
        "b) $\\text{ } x = $",
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha_1 = MCRandom.randomInt(-9, 9);
    const alpha_2 = MCRandom.randomInt(-9, 9);
    const beta_1 = MCRandom.randomInt(-9, 9);
    const beta_2 = MCRandom.randomInt(-9, 9);
    const constantK = MCRandom.randomInt(2, 9);

    const p1 = new MCMaths.Polynomial(
      [alpha_1, beta_1],
      `\\tan(${constantK}\\theta)`
    );
    const p2 = new MCMaths.Polynomial(
      [alpha_2, beta_2],
      `\\tan(${constantK}\\theta)`
    );
    const polynomialProduct = p1.multiply(p2);
    const polynomialProduct_Coefficients = polynomialProduct.getCoefficients();

    const partAAnswer1 = new MCMaths.Fraction(beta_1 / alpha_1);
    const partAAnswer2 = new MCMaths.Fraction(beta_2 / alpha_2);
    const partAAnswerArray = [
      parseFloat(partAAnswer1.toFloat().toPrecision(3)),
      parseFloat(partAAnswer2.toFloat().toPrecision(3)),
    ];

    const partBAnswer1Radians =
      (1 / constantK) * Math.atan(partAAnswer1.toFloat());
    const partBAnswer2Radians =
      (1 / constantK) * Math.atan(partAAnswer2.toFloat());
    const partBAnswerArray = [
      parseFloat(partBAnswer1Radians.toPrecision(3)),
      parseFloat(partBAnswer2Radians.toPrecision(3)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (
      alpha_1 !== 0 &&
      beta_1 !== 0 &&
      alpha_2 !== 0 &&
      beta_2 !== 0 &&
      polynomialProduct_Coefficients[1] !== 0 &&
      Math.abs(partAAnswer1.toFloat()) < 1 &&
      Math.abs(partAAnswer2.toFloat()) < 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given the following equation:`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$\\displaystyle ${polynomialProduct_Coefficients[0]}\\tan^{2}(${constantK}\\theta)+${polynomialProduct_Coefficients[1]}\\tan(${constantK}\\theta)+${polynomialProduct_Coefficients[2]}=0, \\text{ } -\\frac{\\pi}{2} < \\theta < \\frac{\\pi}{2}$`
        )
      );
      question.addParagraph(
        "question",
        `a) Find the possible values of $\\tan(${constantK}\\theta)$`
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, find all values of $\\theta$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `a) $${polynomialProduct_Coefficients[0]}\\tan^{2}(${constantK}\\theta)+${polynomialProduct_Coefficients[1]}\\tan(${constantK}\\theta)+${polynomialProduct_Coefficients[2]}=0$`
        )
      );
      question.addParagraph(
        "solution",
        `$(${p1.toString()})(${p2.toString()}) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\tan(${constantK}\\theta) = ${partAAnswer1.toString()}, \\text{ } \\tan(${constantK}\\theta) = ${partAAnswer2.toString()}$.`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\tan(${constantK}\\theta) = ${partAAnswer1
          .toFloat()
          .toPrecision(
            3
          )}, \\text{ } \\tan(${constantK}\\theta) = ${partAAnswer2
          .toFloat()
          .toPrecision(3)}$.`
      );

      question.addParagraph(
        "solution",
        `b) $\\displaystyle ${constantK}\\theta = \\tan^{-1}\\left(${partAAnswer1.toString()}\\right), \\text{ } ${constantK}\\theta = \\tan^{-1}\\left(${partAAnswer2.toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\frac{1}{${constantK}}\\tan^{-1}\\left(${partAAnswer1.toString()}\\right), \\text{ } \\theta = \\frac{1}{${constantK}}\\tan^{-1}\\left(${partAAnswer2.toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = ${partBAnswer1Radians.toPrecision(
          3
        )}^{c}, \\text{ } \\theta = ${partBAnswer2Radians.toPrecision(3)}^{c}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        partAAnswerArray,
        Math.abs(
          parseFloat(partAAnswerArray[0].toPrecision(3)) -
            parseFloat(partAAnswerArray[0].toPrecision(2))
        ),
        `a) $\\text{ } \\tan(${constantK}\\theta) = $`
      );

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        partBAnswerArray,
        Math.abs(
          parseFloat(partBAnswerArray[0].toPrecision(3)) -
            parseFloat(partBAnswerArray[0].toPrecision(2))
        ),
        `b) $\\text{ } \\theta = $`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(-9, 9);
    const beta = MCRandom.randomInt(-60, 60) * (Math.PI / 180);
    const gamma_1 = MCRandom.randomInt(-9, 9);
    const gamma_2 = MCRandom.randomInt(-9, 9);
    const gamma_Fraction = new MCMaths.Fraction(gamma_2 / gamma_1);

    const partAAnswer = gamma_Fraction.toFloat();
    const partBAnswerRadians = (Math.atan(partAAnswer) - beta) / alpha;

    /* END VARIABLES AND CONSTANTS */
    if (
      alpha !== 0 &&
      Math.abs(beta) > 10 * (Math.PI / 180) &&
      Math.abs(gamma_1) > 1 &&
      Math.abs(gamma_2) > 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given the following equation:`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$\\displaystyle ${gamma_1}\\sin(${alpha}\\theta+${beta.toPrecision(
            3
          )}^{c}) = ${gamma_2}\\cos(${alpha}\\theta+${beta.toPrecision(
            3
          )}^{c}), \\text{ } -\\frac{\\pi}{2} < \\theta < \\frac{\\pi}{2}$`,
          false,
          false
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `a) Find the value of $\\tan(${alpha}\\theta+${beta.toPrecision(
            3
          )}^{c})$.`,
          false,
          false
        )
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, find $\\theta$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `a) $${gamma_1}\\sin(${alpha}\\theta+${beta.toPrecision(
            3
          )}^{c}) = ${gamma_2}\\cos(${alpha}\\theta+${beta.toPrecision(
            3
          )}^{c})$`,
          false,
          false
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\frac{\\sin(${alpha}\\theta+${beta.toPrecision(
            3
          )}^{c})}{\\cos(${alpha}\\theta+${beta.toPrecision(
            3
          )}^{c})} = ${gamma_Fraction.toString()}$`,
          false,
          false
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\tan(${alpha}\\theta+${beta.toPrecision(
            3
          )}^{c}) = ${gamma_Fraction.toString()}$`,
          false,
          false
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\tan(${alpha}\\theta+${beta.toPrecision(
            3
          )}^{c}) = ${gamma_Fraction.toFloat().toPrecision(3)}$`,
          false,
          false
        )
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `b) $\\displaystyle \\tan(${alpha}\\theta+${beta.toPrecision(
            3
          )}^{c}) = ${gamma_Fraction.toString()}$`,
          false,
          false
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle ${alpha}\\theta+${beta.toPrecision(
            3
          )}^{c} = \\tan^{-1}\\left(${gamma_Fraction.toString()}\\right)$`,
          false,
          false
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\theta = \\frac{\\tan^{-1}\\left(${gamma_Fraction.toString()}\\right)+${-beta.toPrecision(
            3
          )}}{${alpha}}$`,
          false,
          false
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\theta = ${partBAnswerRadians.toPrecision(3)}^{c}$`,
          false,
          false
        )
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(partAAnswer.toPrecision(3)),
        Math.abs(
          parseFloat(partAAnswer.toPrecision(3)) -
            parseFloat(partAAnswer.toPrecision(2))
        ),
        MCMaths.cleaner(
          `a) $\\text{ } \\tan(${alpha}\\theta+${beta.toPrecision(3)}^{c})$`,
          false,
          false
        ),
        "before"
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(partBAnswerRadians.toPrecision(3)),
        Math.abs(
          parseFloat(partBAnswerRadians.toPrecision(3)) -
            parseFloat(partBAnswerRadians.toPrecision(2))
        ),
        `b) $\\text{ } \\theta = $`,
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(10, 30) * (Math.PI / 180);
    const gamma = MCRandom.randomInt(2, 9);

    const thetaRadians1 = Math.acos(gamma / alpha) + beta;
    const thetaRadians2 = -thetaRadians1;

    const cosTheta = Math.cos(thetaRadians1);

    /* END VARIABLES AND CONSTANTS */
    if (gamma / alpha < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `a) Solve the following equation:`);
      question.addParagraph(
        "question",
        `$${alpha}\\cos(\\theta - ${beta.toPrecision(
          3
        )}^{c}) - ${gamma} = 0, \\text{ } -\\frac{\\pi}{2} < \\theta < \\frac{\\pi}{2}$`
      );
      question.addParagraph(
        "question",
        `b) Hence find the value of $\\cos(\\theta)$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $2$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $${alpha}\\cos(\\theta - ${beta.toPrecision(
          3
        )}^{c}) - ${gamma} = 0$`
      );
      question.addParagraph(
        "solution",
        `$${alpha}\\cos(\\theta - ${beta.toPrecision(3)}^{c})  = ${gamma}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos(\\theta - ${beta.toPrecision(
          3
        )}^{c})  = ${new MCMaths.Fraction(gamma / alpha).toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta - ${beta.toPrecision(
          3
        )}^{c}  = \\cos^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\cos^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right) + ${beta.toPrecision(3)}^{c} $`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaRadians1.toPrecision(3)}^{c} $`
      );
      question.addParagraph(
        "solution",
        `Also, $\\theta = -${thetaRadians1.toPrecision(3)}^{c} $`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = ${thetaRadians1.toPrecision(
          3
        )}^{c}, \\text{ } ${thetaRadians2.toPrecision(3)}^{c} $`
      );

      question.addParagraph(
        "solution",
        `b) $\\cos(\\theta) = \\cos(\\cos^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right) + ${beta.toPrecision(3)}^{c} ) $`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\cos(\\theta) = ${cosTheta.toPrecision(3)} $`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(thetaRadians1.toPrecision(3)),
          parseFloat(thetaRadians2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(thetaRadians1.toPrecision(3)) -
            parseFloat(thetaRadians1.toPrecision(2))
        ),
        "a) $\\text{ } \\theta = $"
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(cosTheta.toPrecision(3)),
        Math.abs(
          parseFloat(cosTheta.toPrecision(3)) -
            parseFloat(cosTheta.toPrecision(2))
        ),
        "b) $\\text{ } \\cos(\\theta) = $"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(10, 30) * (Math.PI / 180);
    const gamma = MCRandom.randomInt(2, 9);

    const thetaRadians1 = Math.atan(gamma / alpha) + beta;
    const thetaRadians2 = thetaRadians1 + Math.PI;

    const tanTheta = Math.tan(thetaRadians1);

    /* END VARIABLES AND CONSTANTS */
    if (gamma / alpha < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `a) Solve the following equation:`);
      question.addParagraph(
        "question",
        `$\\displaystyle ${alpha}\\tan(\\theta - ${beta.toPrecision(
          3
        )}^{c}) - ${gamma} = 0, \\text{ } -\\frac{\\pi}{2} < \\theta < \\frac{3\\pi}{2}$`
      );
      question.addParagraph(
        "question",
        `b) Hence find the value of $\\tan(\\theta)$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $${alpha}\\tan(\\theta - ${beta.toPrecision(
          3
        )}^{c}) - ${gamma} = 0$`
      );
      question.addParagraph(
        "solution",
        `$${alpha}\\tan(\\theta - ${beta.toPrecision(3)}^{c})  = ${gamma}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\tan(\\theta - ${beta.toPrecision(
          3
        )}^{c})  = ${new MCMaths.Fraction(gamma / alpha).toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta - ${beta.toPrecision(
          3
        )}^{c}  = \\tan^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\tan^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right) + ${beta.toPrecision(3)}^{c} $`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaRadians1.toPrecision(3)}^{c} $`
      );
      question.addParagraph(
        "solution",
        `Also, $\\theta = ${thetaRadians1.toPrecision(3)} + \\pi $`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaRadians2.toPrecision(3)}^{c}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = ${thetaRadians1.toPrecision(
          3
        )}^{c}, \\text{ } ${thetaRadians2.toPrecision(3)}^{c} $`
      );

      question.addParagraph(
        "solution",
        `b) $\\tan(\\theta) = \\tan(\\tan^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right) + ${beta.toPrecision(3)}^{c} ) $`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\tan(\\theta) = ${tanTheta.toPrecision(3)} $`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(thetaRadians1.toPrecision(3)),
          parseFloat(thetaRadians2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(thetaRadians1.toPrecision(3)) -
            parseFloat(thetaRadians1.toPrecision(2))
        ),
        "a) $\\text{ } \\theta = $"
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(tanTheta.toPrecision(3)),
        Math.abs(
          parseFloat(tanTheta.toPrecision(3)) -
            parseFloat(tanTheta.toPrecision(2))
        ),
        "b) $\\text{ } \\tan(\\theta) = $"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const gamma = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(10, 30) * (Math.PI / 180);
    const alpha = MCRandom.randomInt(2, 9);

    const thetaRadians1 = Math.acos(gamma / alpha) + beta;
    const thetaRadians2 = -thetaRadians1;

    const secTheta = 1 / Math.cos(thetaRadians1);

    /* END VARIABLES AND CONSsecTS */
    if (alpha / gamma > 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `a) Solve the following equation:`);
      question.addParagraph(
        "question",
        `$\\displaystyle ${gamma}\\sec(\\theta - ${beta.toPrecision(
          3
        )}^{c}) - ${alpha} = 0, \\text{ } -\\frac{\\pi}{2} < \\theta < \\frac{\\pi}{2}$`
      );
      question.addParagraph(
        "question",
        `b) Hence find the value of $\\sec(\\theta)$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $${gamma}\\sec(\\theta - ${beta.toPrecision(
          3
        )}^{c}) - ${alpha} = 0$`
      );
      question.addParagraph(
        "solution",
        `$${gamma}\\sec(\\theta - ${beta.toPrecision(3)}^{c})  = ${alpha}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\sec(\\theta - ${beta.toPrecision(
          3
        )}^{c})  = ${new MCMaths.Fraction(alpha / gamma).toString()}$`
      );

      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos(\\theta - ${beta.toPrecision(
          3
        )}^{c})  = ${new MCMaths.Fraction(gamma / alpha).toString()}$`
      );

      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta - ${beta.toPrecision(
          3
        )}^{c}  = \\cos^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right)$`
      );

      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\cos^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right) + ${beta.toPrecision(3)}^{c} $`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaRadians1.toPrecision(3)}^{c} $`
      );
      question.addParagraph(
        "solution",
        `Also, $\\theta = -${thetaRadians1.toPrecision(3)}^{c}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = ${thetaRadians1.toPrecision(
          3
        )}^{c}, \\text{ } ${thetaRadians2.toPrecision(3)}^{c} $`
      );

      question.addParagraph(
        "solution",
        `b) $\\displaystyle \\sec(\\theta) = \\frac{1}{\\cos(\\cos^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right) + ${beta.toPrecision(3)}^{c} )} $`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sec(\\theta) = ${secTheta.toPrecision(3)} $`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(thetaRadians1.toPrecision(3)),
          parseFloat(thetaRadians2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(thetaRadians1.toPrecision(3)) -
            parseFloat(thetaRadians1.toPrecision(2))
        ),
        "a) $\\text{ } \\theta = $"
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(secTheta.toPrecision(3)),
        Math.abs(
          parseFloat(secTheta.toPrecision(3)) -
            parseFloat(secTheta.toPrecision(2))
        ),
        "b) $\\text{ } \\sec(\\theta) = $"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(2, 6);
    const gamma = MCRandom.randomInt(2, 9);
    const cotCoeff = new MCMaths.Fraction(alpha / gamma);

    const thetaRadians1 = (1 / beta) * Math.asin(cotCoeff.toFloat());
    const thetaRadians2 = Math.PI - thetaRadians1;
    const thetaRadians3 = (1 / beta) * Math.acos(0);

    /* END VARIABLES AND CONSTANTS */
    if (cotCoeff.toFloat() < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given the following equation:`);
      question.addParagraph(
        "question",
        `$\\displaystyle ${cotCoeff.toString()}\\cot(${beta}\\theta) = \\cos(${beta}\\theta), \\text{ } 0 < \\theta < \\pi$`
      );
      question.addParagraph(
        "question",
        `a) Find the value of $\\cos(${beta}\\theta)$:`
      );
      question.addParagraph(
        "question",
        `b) Hence find all values of $\\theta$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $\\displaystyle ${cotCoeff.toString()}\\cot(${beta}\\theta) = \\cos(${beta}\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{${cotCoeff.numerator}\\cos(${beta}\\theta)}{${cotCoeff.denominator}\\sin(${beta}\\theta)} = \\cos(${beta}\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$${cotCoeff.numerator}\\cos(${beta}\\theta) = ${cotCoeff.denominator}\\cos(${beta}\\theta)\\sin(${beta}\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$\\cos(${beta}\\theta)(${alpha} - ${cotCoeff.denominator}\\sin(${beta}\\theta)) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\cos(${beta}\\theta) = 0, \\text{ } ${alpha} - ${cotCoeff.denominator}\\sin(${beta}\\theta) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\cos(${beta}\\theta) = 0$.`
      );
      question.addParagraph(
        "solution",
        `b) $${cotCoeff.numerator} - ${cotCoeff.denominator}\\sin(${beta}\\theta) = 0$`
      );
      question.addParagraph(
        "solution",
        `$${cotCoeff.denominator}\\sin(${beta}\\theta) = ${cotCoeff.denominator}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\sin(${beta}\\theta) = ${cotCoeff.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle ${beta}\\theta = \\sin^{-1}\\left(${cotCoeff.toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\frac{1}{${beta}}\\sin^{-1}\\left(${cotCoeff.toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaRadians1.toPrecision(3)}^{c}$`
      );
      question.addParagraph(
        "solution",
        `Also, $\\theta = \\pi-${thetaRadians1.toPrecision(3)}$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaRadians2.toPrecision(3)}$`
      );
      question.addParagraph(
        "solution",
        `And since $\\displaystyle \\cos(${beta}\\theta) = 0, \\text{ } \\theta = \\frac{1}{${beta}}\\left(\\frac{\\pi}{2}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = ${thetaRadians1.toPrecision(
          3
        )}^{c}, \\text{ } \\theta = ${thetaRadians2.toPrecision(
          3
        )}^{c}, \\text{ }  \\theta = ${thetaRadians3.toPrecision(3)}$.`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(0, 0, `a) $\\text{ } \\cos(${beta}\\theta) = $`);

      const group2 = new MCQuestion.InputGroup(3);
      group2.addInput(
        [
          parseFloat(thetaRadians1.toPrecision(3)),
          parseFloat(thetaRadians2.toPrecision(3)),
          parseFloat(thetaRadians3.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(thetaRadians1.toPrecision(3)) -
            parseFloat(thetaRadians1.toPrecision(2))
        ),
        "b) $\\text{ } \\theta = $"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pi = Math.PI;
    const commonValues = [0, 1, Math.sqrt(3) / 2, 1 / Math.sqrt(2), 1 / 2];
    const valueChooser = MCRandom.randomInt(0, commonValues.length - 1);
    const value = commonValues[valueChooser];
    const parameterScalar = MCRandom.randomInt(2, 4);

    let valueString = ``;
    let scaledX = 0;
    let x = 0;
    switch (valueChooser) {
      case 0:
        valueString = `${value}`;
        scaledX = 1 / 2;
        x = scaledX / parameterScalar;
        break;

      case 1:
        valueString = `${value}`;
        scaledX = 0;
        x = scaledX / parameterScalar;
        break;

      case 2:
        valueString = `\\frac{\\sqrt{3}}{2}`;
        scaledX = 1 / 6;
        x = scaledX / parameterScalar;
        break;

      case 3:
        valueString = `\\frac{1}{\\sqrt{2}}`;
        scaledX = 1 / 4;
        x = scaledX / parameterScalar;
        break;

      case 4:
        valueString = `\\frac{1}{2}`;
        scaledX = 1 / 3;
        x = scaledX / parameterScalar;
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct solution to the following equation:`
      );
      question.addParagraph(
        `$\\displaystyle \\cos(${parameterScalar}x) = ${valueString}, \\text{ } 0 \\leq x \\leq \\frac{\\pi}{2}$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      const answer1String = new MCMaths.Fraction(x).toString();
      if (x === 0) {
        answer1.addParagraph(`$x = ${answer1String}$`);
      } else {
        answer1.addParagraph(`$x = ${answer1String}\\pi$`);
      }
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      const answer2aString = new MCMaths.Fraction(x + 1 / 12).toString();
      const answer2bString = new MCMaths.Fraction(scaledX).toString();
      if (scaledX === 0) {
        answer2.addParagraph(`$x = ${answer2aString}\\pi$`);
      } else {
        answer2.addParagraph(`$x = ${answer2bString}\\pi$`);
      }
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      const answer3String = new MCMaths.Fraction(x + 1 / 6).toString();
      answer3.addParagraph(`$x = ${answer3String}\\pi$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      const answer4String = new MCMaths.Fraction(x + 1 / 8).toString();
      answer4.addParagraph(`$x = ${answer4String}\\pi$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_MCQ_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const answer1Opp = MCRandom.randomInt(2, 36);
    const answer1Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer1Hyp = answer1Opp / Math.sin(answer1Theta);

    const answer2Opp = MCRandom.randomInt(2, 36);
    const answer2Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer2Hyp = answer2Opp / Math.sin(answer2Theta);

    const answer3Opp = MCRandom.randomInt(2, 36);
    const answer3Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer3Hyp = answer3Opp / Math.sin(answer3Theta);

    const answer4Opp = MCRandom.randomInt(2, 36);
    const answer4Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer4Hyp = answer4Opp / Math.sin(answer4Theta);

    const hypArray = [answer1Hyp, answer2Hyp, answer3Hyp, answer4Hyp];
    const minValue = Math.min.apply(null, hypArray);
    const newMinValue = Math.ceil(minValue / 10) * 10;

    let answer1Bool = false;
    if (answer1Hyp > newMinValue) {
      answer1Bool = true;
    }

    let answer2Bool = false;
    if (answer2Hyp > newMinValue) {
      answer2Bool = true;
    }

    let answer3Bool = false;
    if (answer3Hyp > newMinValue) {
      answer3Bool = true;
    }

    let answer4Bool = false;
    if (answer4Hyp > newMinValue) {
      answer4Bool = true;
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      answer1Opp / answer1Hyp === answer2Opp / answer2Hyp &&
      answer2Opp / answer2Hyp === answer3Opp / answer3Hyp &&
      answer1Opp / answer1Hyp !== 1 &&
      answer1Hyp > answer1Opp &&
      answer2Hyp > answer2Opp &&
      answer3Hyp > answer3Opp &&
      answer4Hyp > answer4Opp &&
      answer1Opp + answer1Hyp !== answer2Opp + answer2Hyp &&
      answer1Opp + answer1Hyp !== answer4Opp + answer4Hyp &&
      answer3Opp + answer3Hyp !== answer4Opp + answer4Hyp &&
      answer2Opp + answer2Hyp !== answer3Opp + answer3Hyp
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(`Select all triangles where $x > ${newMinValue}$.`);

      const myImage1 = new MCQuestion.Image(
        "Matt/rightAngleTriangle1.svg",
        "width",
        20
      );
      myImage1.addOverlay(`$${answer1Theta.toPrecision(2)}^{c}$`, 30, 80);
      myImage1.addOverlay(`$${answer1Opp}$`, 95, 50, 2);
      myImage1.addOverlay(`$x$`, 43, 43, 2);

      const myImage2 = new MCQuestion.Image(
        "Matt/rightAngleTriangle2.svg",
        "width",
        20
      );
      myImage2.addOverlay(`$${answer2Theta.toPrecision(2)}^{c}$`, 70, 20);
      myImage2.addOverlay(`$${answer2Opp}$`, 2, 45, 2);
      myImage2.addOverlay(`$x$`, 55, 55, 2);

      const myImage3 = new MCQuestion.Image(
        "Matt/rightAngleTriangle3.svg",
        "width",
        20
      );
      myImage3.addOverlay(`$${answer3Theta.toPrecision(2)}^{c}$`, 80, 70);
      myImage3.addOverlay(`$${answer3Opp}$`, 50, 2, 2);
      myImage3.addOverlay(`$x$`, 45, 55, 2);

      const myImage4 = new MCQuestion.Image(
        "Matt/rightAngleTriangle4.svg",
        "width",
        20
      );
      myImage4.addOverlay(`$${answer4Theta.toPrecision(2)}^{c}$`, 80, 35);
      myImage4.addOverlay(`$${answer4Opp}$`, 55, 95, 2);
      myImage4.addOverlay(`$x$`, 45, 45, 2);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addImage(myImage1);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addImage(myImage2);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addImage(myImage3);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addImage(myImage4);

      question.addAnswer(answer1, answer1Bool);
      question.addAnswer(answer2, answer2Bool);
      question.addAnswer(answer3, answer3Bool);
      question.addAnswer(answer4, answer4Bool);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_MCQ_Q11() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const answer1SideA = MCRandom.randomInt(2, 36);
    const answer1Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer1ArcLength = answer1SideA * answer1Theta;

    const answer2SideA = MCRandom.randomInt(2, 36);
    const answer2Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer2ArcLength = answer2SideA * answer2Theta;

    const answer3SideA = MCRandom.randomInt(2, 36);
    const answer3Theta = MCRandom.randomInt(20, 40) * (Math.PI / 180);
    const answer3ArcLength = answer3SideA * answer3Theta;

    const answer4SideA = MCRandom.randomInt(2, 36);
    const answer4Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer4ArcLength = answer4SideA * answer4Theta;

    const arcLengthArray = [
      answer1ArcLength,
      answer2ArcLength,
      answer3ArcLength,
      answer4ArcLength,
    ];
    const minValue = Math.min.apply(null, arcLengthArray);
    const newMinValue = Math.ceil(minValue / 10) * 10;

    let answer1Bool = false;
    if (answer1ArcLength > newMinValue) {
      answer1Bool = true;
    }

    let answer2Bool = false;
    if (answer2ArcLength > newMinValue) {
      answer2Bool = true;
    }

    let answer3Bool = false;
    if (answer3ArcLength > newMinValue) {
      answer3Bool = true;
    }

    let answer4Bool = false;
    if (answer4ArcLength > newMinValue) {
      answer4Bool = true;
    }

    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select all sectors with arc lengths greater than $${newMinValue}$.`
      );

      const myImage1 = new MCQuestion.Image("Matt/sector1.svg", "width", 20);
      myImage1.addOverlay(`$${answer1Theta.toPrecision(2)}^{c}$`, 50, 80);
      myImage1.addOverlay(`$${answer1SideA}$`, 80, 60, 2);

      const myImage2 = new MCQuestion.Image("Matt/sector2.svg", "width", 20);
      myImage2.addOverlay(`$${answer2Theta.toPrecision(2)}^{c}$`, 15, 73);
      myImage2.addOverlay(`$${answer2SideA}$`, 50, 85, 2);

      const myImage3 = new MCQuestion.Image("Matt/sector3.svg", "width", 20);
      myImage3.addOverlay(`$${answer3Theta.toPrecision(2)}^{c}$`, 26, 71);
      myImage3.addOverlay(`$${answer3SideA}$`, 60, 75, 2);

      const myImage4 = new MCQuestion.Image("Matt/sector4.svg", "width", 20);
      myImage4.addOverlay(`$${answer4Theta.toPrecision(2)}^{c}$`, 80, 18);
      myImage4.addOverlay(`$${answer4SideA}$`, 50, 15, 2);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addImage(myImage1);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addImage(myImage2);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addImage(myImage3);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addImage(myImage4);

      question.addAnswer(answer1, answer1Bool);
      question.addAnswer(answer2, answer2Bool);
      question.addAnswer(answer3, answer3Bool);
      question.addAnswer(answer4, answer4Bool);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_MCQ_Q12() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const answer1SideA = MCRandom.randomInt(2, 36);
    const answer1Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer1SectorArea = 0.5 * answer1SideA * answer1SideA * answer1Theta;

    const answer2SideA = MCRandom.randomInt(2, 36);
    const answer2Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer2SectorArea = 0.5 * answer2SideA * answer2SideA * answer2Theta;

    const answer3SideA = MCRandom.randomInt(2, 36);
    const answer3Theta = MCRandom.randomInt(20, 40) * (Math.PI / 180);
    const answer3SectorArea = 0.5 * answer3SideA * answer3SideA * answer3Theta;

    const answer4SideA = MCRandom.randomInt(2, 36);
    const answer4Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer4SectorArea = 0.5 * answer4SideA * answer4SideA * answer4Theta;

    const sectorAreaArray = [
      answer1SectorArea,
      answer2SectorArea,
      answer3SectorArea,
      answer4SectorArea,
    ];
    const minValue = Math.min.apply(null, sectorAreaArray);
    const newMinValue = Math.ceil(minValue / 10) * 10;

    let answer1Bool = false;
    if (answer1SectorArea > newMinValue) {
      answer1Bool = true;
    }

    let answer2Bool = false;
    if (answer2SectorArea > newMinValue) {
      answer2Bool = true;
    }

    let answer3Bool = false;
    if (answer3SectorArea > newMinValue) {
      answer3Bool = true;
    }

    let answer4Bool = false;
    if (answer4SectorArea > newMinValue) {
      answer4Bool = true;
    }

    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select all sectors with sector area greater than $${newMinValue}$.`
      );

      const myImage1 = new MCQuestion.Image("Matt/sector1.svg", "width", 20);
      myImage1.addOverlay(`$${answer1Theta.toPrecision(2)}^{c}$`, 50, 80);
      myImage1.addOverlay(`$${answer1SideA}$`, 80, 60, 2);

      const myImage2 = new MCQuestion.Image("Matt/sector2.svg", "width", 20);
      myImage2.addOverlay(`$${answer2Theta.toPrecision(2)}^{c}$`, 15, 73);
      myImage2.addOverlay(`$${answer2SideA}$`, 50, 85, 2);

      const myImage3 = new MCQuestion.Image("Matt/sector3.svg", "width", 20);
      myImage3.addOverlay(`$${answer3Theta.toPrecision(2)}^{c}$`, 26, 71);
      myImage3.addOverlay(`$${answer3SideA}$`, 60, 75, 2);

      const myImage4 = new MCQuestion.Image("Matt/sector4.svg", "width", 20);
      myImage4.addOverlay(`$${answer4Theta.toPrecision(2)}^{c}$`, 80, 18);
      myImage4.addOverlay(`$${answer4SideA}$`, 50, 15, 2);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addImage(myImage1);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addImage(myImage2);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addImage(myImage3);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addImage(myImage4);

      question.addAnswer(answer1, answer1Bool);
      question.addAnswer(answer2, answer2Bool);
      question.addAnswer(answer3, answer3Bool);
      question.addAnswer(answer4, answer4Bool);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pi = Math.PI;
    const commonValues = [0, 1, Math.sqrt(3) / 2, 1 / Math.sqrt(2), 1 / 2];
    const valueChooser = MCRandom.randomInt(0, commonValues.length - 1);
    const value = commonValues[valueChooser];
    const parameterScalar = MCRandom.randomInt(2, 4);

    let valueString = ``;
    let scaledX = 0;
    let x = 0;
    switch (valueChooser) {
      case 0:
        valueString = `${value}`;
        scaledX = 0;
        x = scaledX / parameterScalar;
        break;

      case 1:
        valueString = `${value}`;
        scaledX = 1 / 2;
        x = scaledX / parameterScalar;
        break;

      case 2:
        valueString = `\\frac{\\sqrt{3}}{2}`;
        scaledX = 1 / 3;
        x = scaledX / parameterScalar;
        break;

      case 3:
        valueString = `\\frac{1}{\\sqrt{2}}`;
        scaledX = 1 / 4;
        x = scaledX / parameterScalar;
        break;

      case 4:
        valueString = `\\frac{1}{2}`;
        scaledX = 1 / 6;
        x = scaledX / parameterScalar;
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct solution to the following equation:`
      );
      question.addParagraph(
        `$\\displaystyle \\sin(${parameterScalar}x) = ${valueString}, \\text{ } 0 \\leq x \\leq \\frac{\\pi}{2}$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      const answer1String = new MCMaths.Fraction(x).toString();
      if (x === 0) {
        answer1.addParagraph(`$x = ${answer1String}$`);
      } else {
        answer1.addParagraph(`$x = ${answer1String}\\pi$`);
      }
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      const answer2aString = new MCMaths.Fraction(x + 1 / 12).toString();
      const answer2bString = new MCMaths.Fraction(scaledX).toString();
      if (scaledX === 0) {
        answer2.addParagraph(`$x = ${answer2aString}\\pi$`);
      } else {
        answer2.addParagraph(`$x = ${answer2bString}\\pi$`);
      }
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      const answer3String = new MCMaths.Fraction(x + 1 / 6).toString();
      answer3.addParagraph(`$x = ${answer3String}\\pi$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      const answer4String = new MCMaths.Fraction(x + 1 / 8).toString();
      answer4.addParagraph(`$x = ${answer4String}\\pi$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pi = Math.PI;
    const commonValues = [
      0,
      1,
      2 - Math.sqrt(3),
      1 / Math.sqrt(3),
      Math.sqrt(3),
    ];
    const valueChooser = MCRandom.randomInt(0, commonValues.length - 1);
    const value = commonValues[valueChooser];
    const parameterScalar = MCRandom.randomInt(2, 4);

    let valueString = ``;
    let scaledX = 0;
    let x = 0;
    switch (valueChooser) {
      case 0:
        valueString = `${value}`;
        scaledX = 0;
        x = scaledX / parameterScalar;
        break;

      case 1:
        valueString = `${value}`;
        scaledX = 1 / 4;
        x = scaledX / parameterScalar;
        break;

      case 2:
        valueString = `\\frac{\\sqrt{3}}{2}`;
        scaledX = 1 / 12;
        x = scaledX / parameterScalar;
        break;

      case 3:
        valueString = `\\frac{1}{\\sqrt{2}}`;
        scaledX = 1 / 6;
        x = scaledX / parameterScalar;
        break;

      case 4:
        valueString = `\\frac{1}{2}`;
        scaledX = 1 / 3;
        x = scaledX / parameterScalar;
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct solution to the following equation:`
      );
      question.addParagraph(
        `$\\displaystyle \\tan(${parameterScalar}x) = ${valueString}$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      const answer1String = new MCMaths.Fraction(x).toString();
      if (x === 0) {
        answer1.addParagraph(`$x = ${answer1String}$`);
      } else {
        answer1.addParagraph(`$x = ${answer1String}\\pi$`);
      }
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      const answer2aString = new MCMaths.Fraction(x + 1 / 12).toString();
      const answer2bString = new MCMaths.Fraction(scaledX).toString();
      if (scaledX === 0) {
        answer2.addParagraph(`$x = ${answer2aString}\\pi$`);
      } else {
        answer2.addParagraph(`$x = ${answer2bString}\\pi$`);
      }
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      const answer3String = new MCMaths.Fraction(x + 1 / 6).toString();
      answer3.addParagraph(`$x = ${answer3String}\\pi$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      const answer4String = new MCMaths.Fraction(x + 1 / 8).toString();
      answer4.addParagraph(`$x = ${answer4String}\\pi$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pi = Math.PI;
    const commonValues = [0, 1, Math.sqrt(3) / 2, 1 / Math.sqrt(2), 1 / 2];
    const valueChooser = MCRandom.randomInt(0, commonValues.length - 1);
    const value = commonValues[valueChooser];
    const parameterShift =
      (MCRandom.randomInt(1, 4) * 10 + MCRandom.randomInt(0, 1) * 5) *
      (1 / 180) *
      MCRandom.randomInt(-1, 1, 2);

    let valueString = ``;
    let shiftedX = 0;
    let x = 0;
    switch (valueChooser) {
      case 0:
        valueString = `${value}`;
        shiftedX = 1 / 2;
        x = shiftedX - parameterShift;
        break;

      case 1:
        valueString = `${value}`;
        shiftedX = 0;
        x = shiftedX - parameterShift;
        break;

      case 2:
        valueString = `\\frac{\\sqrt{3}}{2}`;
        shiftedX = 1 / 6;
        x = shiftedX - parameterShift;
        break;

      case 3:
        valueString = `\\frac{1}{\\sqrt{2}}`;
        shiftedX = 1 / 4;
        x = shiftedX - parameterShift;
        break;

      case 4:
        valueString = `\\frac{1}{2}`;
        shiftedX = 1 / 3;
        x = shiftedX - parameterShift;
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct solution to the following equation:`
      );
      question.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\cos(x+${parameterShift}\\pi) = ${valueString}, \\text{ } 0 \\leq x \\leq \\frac{\\pi}{2}$`
        )
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      const answer1String = new MCMaths.Fraction(x).toString();
      answer1.addParagraph(`$x = ${answer1String}\\pi$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      const answer2aString = new MCMaths.Fraction(x + 1 / 12).toString();
      const answer2bString = new MCMaths.Fraction(shiftedX).toString();
      if (shiftedX === x + 1 / 4 || shiftedX === x + 1 / 6) {
        answer2.addParagraph(`$x = ${answer2aString}\\pi$`);
      } else {
        answer2.addParagraph(`$x = ${answer2bString}\\pi$`);
      }
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      const answer3String = new MCMaths.Fraction(x + 1 / 6).toString();
      answer3.addParagraph(`$x = ${answer3String}\\pi$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      const answer4String = new MCMaths.Fraction(x + 1 / 8).toString();
      answer4.addParagraph(`$x = ${answer4String}\\pi$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pi = Math.PI;
    const commonValues = [0, 1, Math.sqrt(3) / 2, 1 / Math.sqrt(2), 1 / 2];
    const valueChooser = MCRandom.randomInt(0, commonValues.length - 1);
    const value = commonValues[valueChooser];
    const parameterShift =
      (MCRandom.randomInt(1, 4) * 10 + MCRandom.randomInt(0, 1) * 5) *
      (1 / 180) *
      MCRandom.randomInt(-1, 1, 2);

    let valueString = ``;
    let shiftedX = 0;
    let x = 0;
    switch (valueChooser) {
      case 0:
        valueString = `${value}`;
        shiftedX = 0;
        x = shiftedX - parameterShift;
        break;

      case 1:
        valueString = `${value}`;
        shiftedX = 1 / 2;
        x = shiftedX - parameterShift;
        break;

      case 2:
        valueString = `\\frac{\\sqrt{3}}{2}`;
        shiftedX = 1 / 3;
        x = shiftedX - parameterShift;
        break;

      case 3:
        valueString = `\\frac{1}{\\sqrt{2}}`;
        shiftedX = 1 / 4;
        x = shiftedX - parameterShift;
        break;

      case 4:
        valueString = `\\frac{1}{2}`;
        shiftedX = 1 / 6;
        x = shiftedX - parameterShift;
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct solution to the following equation:`
      );
      question.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\sin(x+${parameterShift}\\pi) = ${valueString}, \\text{ } 0 \\leq x \\leq \\frac{\\pi}{2}$`
        )
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      const answer1String = new MCMaths.Fraction(x).toString();
      answer1.addParagraph(`$x = ${answer1String}\\pi$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      const answer2aString = new MCMaths.Fraction(x + 1 / 12).toString();
      const answer2bString = new MCMaths.Fraction(shiftedX).toString();
      if (shiftedX === x + 1 / 4 || shiftedX === x + 1 / 6) {
        answer2.addParagraph(`$x = ${answer2aString}\\pi$`);
      } else {
        answer2.addParagraph(`$x = ${answer2bString}\\pi$`);
      }
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      const answer3String = new MCMaths.Fraction(x + 1 / 6).toString();
      answer3.addParagraph(`$x = ${answer3String}\\pi$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      const answer4String = new MCMaths.Fraction(x + 1 / 8).toString();
      answer4.addParagraph(`$x = ${answer4String}\\pi$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pi = Math.PI;
    const commonValues = [
      0,
      1,
      2 - Math.sqrt(3),
      1 / Math.sqrt(3),
      Math.sqrt(3),
    ];
    const valueChooser = MCRandom.randomInt(0, commonValues.length - 1);
    const value = commonValues[valueChooser];
    const parameterShift =
      (MCRandom.randomInt(1, 4) * 10 + MCRandom.randomInt(0, 1) * 5) *
      (1 / 180) *
      MCRandom.randomInt(-1, 1, 2);

    let valueString = ``;
    let shiftedX = 0;
    let x = 0;
    switch (valueChooser) {
      case 0:
        valueString = `${value}`;
        shiftedX = 0;
        x = shiftedX - parameterShift;
        break;

      case 1:
        valueString = `${value}`;
        shiftedX = 1 / 4;
        x = shiftedX - parameterShift;
        break;

      case 2:
        valueString = `2-\\sqrt{3}`;
        shiftedX = 1 / 12;
        x = shiftedX - parameterShift;
        break;

      case 3:
        valueString = `\\frac{1}{\\sqrt{3}}`;
        shiftedX = 1 / 6;
        x = shiftedX - parameterShift;
        break;

      case 4:
        valueString = `\\sqrt{3}`;
        shiftedX = 1 / 3;
        x = shiftedX - parameterShift;
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct solution to the following equation:`
      );
      question.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\tan(x+${parameterShift}\\pi) = ${valueString}$`
        )
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      const answer1String = new MCMaths.Fraction(x).toString();
      answer1.addParagraph(`$x = ${answer1String}\\pi$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      const answer2aString = new MCMaths.Fraction(x + 1 / 12).toString();
      const answer2bString = new MCMaths.Fraction(shiftedX).toString();
      if (shiftedX === x + 1 / 4 || shiftedX === x + 1 / 6) {
        answer2.addParagraph(`$x = ${answer2aString}\\pi$`);
      } else {
        answer2.addParagraph(`$x = ${answer2bString}\\pi$`);
      }
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      const answer3String = new MCMaths.Fraction(x + 1 / 6).toString();
      answer3.addParagraph(`$x = ${answer3String}\\pi$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      const answer4String = new MCMaths.Fraction(x + 1 / 8).toString();
      answer4.addParagraph(`$x = ${answer4String}\\pi$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_MCQ_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const answer1Opp = MCRandom.randomInt(2, 36);
    const answer1Hyp = MCRandom.randomInt(2, 36);

    const answer2Opp = MCRandom.randomInt(2, 36);
    const answer2Hyp = MCRandom.randomInt(2, 36);

    const answer3Opp = MCRandom.randomInt(2, 36);
    const answer3Hyp = MCRandom.randomInt(2, 36);

    const answer4Opp = MCRandom.randomInt(2, 36);
    const answer4Hyp = MCRandom.randomInt(2, 36);

    /* END VARIABLES AND CONSTANTS */
    if (
      answer1Opp / answer1Hyp === answer2Opp / answer2Hyp &&
      answer2Opp / answer2Hyp === answer3Opp / answer3Hyp &&
      answer1Opp / answer1Hyp !== 1 &&
      answer1Hyp > answer1Opp &&
      answer2Hyp > answer2Opp &&
      answer3Hyp > answer3Opp &&
      answer4Hyp > answer4Opp &&
      answer1Opp + answer1Hyp !== answer2Opp + answer2Hyp &&
      answer1Opp + answer1Hyp !== answer4Opp + answer4Hyp &&
      answer3Opp + answer3Hyp !== answer4Opp + answer4Hyp &&
      answer2Opp + answer2Hyp !== answer3Opp + answer3Hyp
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        "Select all triangles that have an equal value of $\\sin(\\theta)$."
      );

      const myImage1 = new MCQuestion.Image(
        "Matt/rightAngleTriangle1.svg",
        "width",
        20
      );
      myImage1.addOverlay(`$\\theta$`, 16, 84);
      myImage1.addOverlay(`$${answer1Opp}$`, 95, 50, 2);
      myImage1.addOverlay(`$${answer1Hyp}$`, 43, 43, 2);

      const myImage2 = new MCQuestion.Image(
        "Matt/rightAngleTriangle2.svg",
        "width",
        20
      );
      myImage2.addOverlay(`$\\theta$`, 82, 17);
      myImage2.addOverlay(`$${answer2Opp}$`, 2, 45, 2);
      myImage2.addOverlay(`$${answer2Hyp}$`, 55, 55, 2);

      const myImage3 = new MCQuestion.Image(
        "Matt/rightAngleTriangle3.svg",
        "width",
        20
      );
      myImage3.addOverlay(`$\\theta$`, 84, 82);
      myImage3.addOverlay(`$${answer3Opp}$`, 50, 2, 2);
      myImage3.addOverlay(`$${answer3Hyp}$`, 45, 55, 2);

      const myImage4 = new MCQuestion.Image(
        "Matt/rightAngleTriangle4.svg",
        "width",
        20
      );
      myImage4.addOverlay(`$\\theta$`, 88, 22);
      myImage4.addOverlay(`$${answer4Opp}$`, 55, 95, 2);
      myImage4.addOverlay(`$${answer4Hyp}$`, 45, 45, 2);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addImage(myImage1);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addImage(myImage2);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addImage(myImage3);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addImage(myImage4);

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, true);
      question.addAnswer(answer3, true);
      question.addAnswer(answer4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_MCQ_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const answer1SideA = MCRandom.randomInt(2, 36);
    const answer1SideB = MCRandom.randomInt(2, 36);
    const answer1Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer1Area =
      0.5 * answer1SideA * answer1SideB * Math.sin(answer1Theta);

    const answer2SideA = MCRandom.randomInt(2, 36);
    const answer2SideB = MCRandom.randomInt(2, 36);
    const answer2Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer2Area =
      0.5 * answer2SideA * answer2SideB * Math.sin(answer2Theta);

    const answer3SideA = MCRandom.randomInt(2, 36);
    const answer3SideB = MCRandom.randomInt(2, 36);
    const answer3Theta = MCRandom.randomInt(100, 160) * (Math.PI / 180);
    const answer3Area =
      0.5 * answer3SideA * answer3SideB * Math.sin(answer3Theta);

    const answer4SideA = MCRandom.randomInt(2, 36);
    const answer4SideB = MCRandom.randomInt(2, 36);
    const answer4Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer4Area =
      0.5 * answer4SideA * answer4SideB * Math.sin(answer4Theta);

    const areaArray = [answer1Area, answer2Area, answer3Area, answer4Area];
    const minValue = Math.min.apply(null, areaArray);
    const newMinValue = Math.ceil(minValue / 10) * 10;

    let answer1Bool = false;
    if (answer1Area > newMinValue) {
      answer1Bool = true;
    }

    let answer2Bool = false;
    if (answer2Area > newMinValue) {
      answer2Bool = true;
    }

    let answer3Bool = false;
    if (answer3Area > newMinValue) {
      answer3Bool = true;
    }

    let answer4Bool = false;
    if (answer4Area > newMinValue) {
      answer4Bool = true;
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      answer1SideA + answer1SideB !== answer2SideA + answer2SideB &&
      answer1SideA + answer1SideB !== answer4SideA + answer4SideB &&
      answer3SideA + answer3SideB !== answer4SideA + answer4SideB &&
      answer2SideA + answer2SideB !== answer3SideA + answer3SideB &&
      answer3Area > newMinValue
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select all triangles with area greater than $${newMinValue}$.`
      );

      const myImage1 = new MCQuestion.Image(
        "Matt/irregularTriangle1.svg",
        "width",
        20
      );
      myImage1.addOverlay(`$${answer1Theta.toPrecision(2)}^{c}$`, 12, 60);
      myImage1.addOverlay(`$${answer1SideA}$`, 30, 25, 2);
      myImage1.addOverlay(`$${answer1SideB}$`, 43, 85, 2);

      const myImage2 = new MCQuestion.Image(
        "Matt/irregularTriangle2.svg",
        "width",
        20
      );
      myImage2.addOverlay(`$${answer2Theta.toPrecision(2)}^{c}$`, 25, 64);
      myImage2.addOverlay(`$${answer2SideA}$`, 30, 30, 2);
      myImage2.addOverlay(`$${answer2SideB}$`, 45, 87, 2);

      const myImage3 = new MCQuestion.Image(
        "Matt/irregularTriangle3.svg",
        "width",
        20
      );
      myImage3.addOverlay(`$${answer3Theta.toPrecision(2)}^{c}$`, 77, 50);
      myImage3.addOverlay(`$${answer3SideA}$`, 50, 80, 2);
      myImage3.addOverlay(`$${answer3SideB}$`, 95, 40, 2);

      const myImage4 = new MCQuestion.Image(
        "Matt/irregularTriangle4.svg",
        "width",
        20
      );
      myImage4.addOverlay(`$${answer4Theta.toPrecision(2)}^{c}$`, 78, 55);
      myImage4.addOverlay(`$${answer4SideA}$`, 55, 20, 2);
      myImage4.addOverlay(`$${answer4SideB}$`, 65, 85, 2);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addImage(myImage1);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addImage(myImage2);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addImage(myImage3);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addImage(myImage4);

      question.addAnswer(answer1, answer1Bool);
      question.addAnswer(answer2, answer2Bool);
      question.addAnswer(answer3, answer3Bool);
      question.addAnswer(answer4, answer4Bool);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Trigonometry_TrigonometryProblems_MCQ_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const answer1SideA = MCRandom.randomInt(2, 36);
    const answer1SideB = MCRandom.randomInt(2, 36);
    const answer1Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer1LongestSide = Math.sqrt(
      answer1SideA ** 2 +
        answer1SideB ** 2 -
        2 * answer1SideA * answer1SideB * Math.cos(answer1Theta)
    );

    const answer2SideA = MCRandom.randomInt(2, 36);
    const answer2SideB = MCRandom.randomInt(2, 36);
    const answer2Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer2LongestSide = Math.sqrt(
      answer2SideA ** 2 +
        answer2SideB ** 2 -
        2 * answer2SideA * answer2SideB * Math.cos(answer2Theta)
    );

    const answer3SideA = MCRandom.randomInt(2, 36);
    const answer3SideB = MCRandom.randomInt(2, 36);
    const answer3Theta = MCRandom.randomInt(100, 160) * (Math.PI / 180);
    const answer3LongestSide = Math.sqrt(
      answer3SideA ** 2 +
        answer3SideB ** 2 -
        2 * answer3SideA * answer3SideB * Math.cos(answer3Theta)
    );

    const answer4SideA = MCRandom.randomInt(2, 36);
    const answer4SideB = MCRandom.randomInt(2, 36);
    const answer4Theta = MCRandom.randomInt(30, 80) * (Math.PI / 180);
    const answer4LongestSide = Math.sqrt(
      answer4SideA ** 2 +
        answer4SideB ** 2 -
        2 * answer4SideA * answer4SideB * Math.cos(answer4Theta)
    );

    const longestSideArray = [
      answer1LongestSide,
      answer2LongestSide,
      answer3LongestSide,
      answer4LongestSide,
    ];
    const minValue = Math.min.apply(null, longestSideArray);
    const newMinValue = Math.ceil(minValue / 10) * 10;

    let answer1Bool = false;
    if (answer1LongestSide > newMinValue) {
      answer1Bool = true;
    }

    let answer2Bool = false;
    if (answer2LongestSide > newMinValue) {
      answer2Bool = true;
    }

    let answer3Bool = false;
    if (answer3LongestSide > newMinValue) {
      answer3Bool = true;
    }

    let answer4Bool = false;
    if (answer4LongestSide > newMinValue) {
      answer4Bool = true;
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      answer1SideA + answer1SideB !== answer2SideA + answer2SideB &&
      answer1SideA + answer1SideB !== answer4SideA + answer4SideB &&
      answer3SideA + answer3SideB !== answer4SideA + answer4SideB &&
      answer2SideA + answer2SideB !== answer3SideA + answer3SideB &&
      answer3LongestSide > newMinValue
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select all triangles with their longest side being greater than $${newMinValue}$.`
      );

      const myImage1 = new MCQuestion.Image(
        "Matt/irregularTriangle1.svg",
        "width",
        20
      );
      myImage1.addOverlay(`$${answer1Theta.toPrecision(2)}^{c}$`, 12, 60);
      myImage1.addOverlay(`$${answer1SideA}$`, 30, 25, 2);
      myImage1.addOverlay(`$${answer1SideB}$`, 43, 85, 2);

      const myImage2 = new MCQuestion.Image(
        "Matt/irregularTriangle2.svg",
        "width",
        20
      );
      myImage2.addOverlay(`$${answer2Theta.toPrecision(2)}^{c}$`, 25, 64);
      myImage2.addOverlay(`$${answer2SideA}$`, 30, 30, 2);
      myImage2.addOverlay(`$${answer2SideB}$`, 45, 87, 2);

      const myImage3 = new MCQuestion.Image(
        "Matt/irregularTriangle3.svg",
        "width",
        20
      );
      myImage3.addOverlay(`$${answer3Theta.toPrecision(2)}^{c}$`, 77, 50);
      myImage3.addOverlay(`$${answer3SideA}$`, 50, 80, 2);
      myImage3.addOverlay(`$${answer3SideB}$`, 95, 40, 2);

      const myImage4 = new MCQuestion.Image(
        "Matt/irregularTriangle4.svg",
        "width",
        20
      );
      myImage4.addOverlay(`$${answer4Theta.toPrecision(2)}^{c}$`, 78, 55);
      myImage4.addOverlay(`$${answer4SideA}$`, 55, 20, 2);
      myImage4.addOverlay(`$${answer4SideB}$`, 65, 85, 2);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addImage(myImage1);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addImage(myImage2);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addImage(myImage3);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addImage(myImage4);

      question.addAnswer(answer1, answer1Bool);
      question.addAnswer(answer2, answer2Bool);
      question.addAnswer(answer3, answer3Bool);
      question.addAnswer(answer4, answer4Bool);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Vectors_Vectors3D_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const p = MCRandom.randomInt(1, 15) * MCRandom.randomInt(-1, 1, 2);
    const q = MCRandom.randomInt(1, 15) * MCRandom.randomInt(-1, 1, 2);
    const r = MCRandom.randomInt(1, 15) * MCRandom.randomInt(-1, 1, 2);

    const v2 = new MCMaths.Vector(p, q, r);

    const v2q = new MCMaths.Vector("p", "q", v2.getComponents()[2]);

    const dist = v2.magnitude();
    const distd = MCMaths.cleaner(`${dist}`);

    const ang = p / v2.magnitude();
    const angd = MCMaths.cleaner(`${ang}`);

    const ans1 = p ** 2 + q ** 2;
    /* END VARIABLES AND CONSTANTS */
    if (
      angd.match(/\d+/g)[1] <
      100 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `The magnitude of $\\mathbf{v}=${v2q.toString()}$ is $${distd}$.`,
        `a) Find the value of $p^2+q^2$.`,
        `Given $\\mathbf{v}_2$ makes an angle of $\\theta$ with $\\mathbf{i}$, with $\\displaystyle \\theta = \\cos^{-1}\\left(${angd}\\right)$,`,
        `b) Find $p^2$.`,
        `c) Find $q^2$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING $|\\mathbf{v}|=${distd}$`,
        MCMaths.cleaner(`$\\sqrt{p^2+q^2+${r}^2} = ${distd}$`),
        MCMaths.cleaner(`$p^2+q^2+${r ** 2} = ${Math.round(dist ** 2)}$`),
        `$p^2+q^2 = ${ans1}$`,
        `b) HEADING Angle with $\\mathbf{i}$:`,
        `$\\displaystyle\\theta = \\cos^{-1}\\left(\\frac{p}{|\\mathbf{v}|}\\right)$`,
        `$\\cos^{-1}\\left(${angd}\\right) = \\cos^{-1}\\left(\\frac{p}{${distd}}\\right)$`,
        `$${angd} = \\frac{p}{${distd}}$`,
        `$p=${p}$`,
        `$p^2=${p ** 2}$`,
        `c) HEADING Finding $q$:`,
        `$q^2 = ${ans1} - p^2$`,
        `$q^2 = ${q ** 2}$.`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans1, 0, "a) $\\ p^2+q^2=$");
      group.addInput(p * p, 0, "b) $\\ p^2=$");
      group.addInput(q * q, 0, "c) $\\ q^2=$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Vectors_Vectors3D_Exam_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const x = MCRandom.randomInt(0, 360, 15);
    const y = MCRandom.randomInt(0, 360, 15);
    const z = MCRandom.randomInt(0, 360, 15);
    const lens = [2, 4, 6, 8, 10];
    const len = lens[MCRandom.randomInt(0, lens.length - 1)];

    const A = new MCMaths.Vector(1, 0, 0).rotate(x, y, z).multiply(len);
    const B = new MCMaths.Vector(0, 1, 0).rotate(x, y, z).multiply(len);
    const C = new MCMaths.Vector(0, 0, 1).rotate(x, y, z).multiply(len);

    const ai = A.getComponents()[0];
    const aj = A.getComponents()[1];
    const ak = A.getComponents()[2];

    function b(t) {
      return `\\left(${t}\\right)`;
    }

    const bi = B.getComponents()[0];
    const bj = B.getComponents()[1];
    const bk = B.getComponents()[2];

    const ci = C.getComponents()[0];
    const cj = C.getComponents()[1];
    const ck = C.getComponents()[2];

    const G = A.add(B).add(C);
    const Gd = `\\begin{pmatrix} ${
      Math.round(10 * G.getComponents()[0]) / 10
    } \\\\ ${Math.round(10 * G.getComponents()[1]) / 10} \\\\ ${
      Math.round(10 * G.getComponents()[2]) / 10
    } \\end{pmatrix}`;

    const mid = C.add(A.multiply(1 / 2)).add(B.multiply(1 / 2));
    const midd = `\\begin{pmatrix} ${
      Math.round(10 * mid.getComponents()[0]) / 10
    } \\\\ ${Math.round(10 * mid.getComponents()[1]) / 10} \\\\ ${
      Math.round(10 * mid.getComponents()[2]) / 10
    } \\end{pmatrix}`;
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(MCMaths.cleaner(`${ai}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${aj}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${ak}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${bi}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${bj}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${bk}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${ci}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${cj}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${ck}`).match(/\d+/g)[0]) < 100 &&
      ai !== len &&
      aj !== len &&
      ak !== len /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addImage(
        "question",
        new MCQuestion.Image("James/Cube.svg", "width", 15)
      );
      question.addMultipleParagraphs("question", [
        `In the above cube, $\\overrightarrow{OA}=${A.toString()}, \\overrightarrow{OB}=${B.toString()}, \\overrightarrow{OC}=${C.toString()}$.`,
        `a) Find $\\overrightarrow{OG}$, to 1dp.`,
        `b) Find the position vector of the centre of the face $CFGE$, to 1dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING $\\overrightarrow{OG}=\\overrightarrow{OA}+\\overrightarrow{AD}+\\overrightarrow{DG}$`,
        `$=\\overrightarrow{OA}+\\overrightarrow{OB}+\\overrightarrow{OC}$`,
        `$=${A.toString()}+${B.toString()}+${C.toString()}$`,
        `$\\approx ${Gd}$`,
        `b) HEADING $\\displaystyle =\\overrightarrow{OC}+\\frac{1}{2}\\left(\\overrightarrow{CE}+\\overrightarrow{CF}\\right)$`,
        `$\\displaystyle =\\overrightarrow{OC}+\\frac{1}{2}\\left(\\overrightarrow{OA}+\\overrightarrow{OB}\\right)$`,
        `$\\displaystyle =${C.toString()}+\\frac{1}{2}\\left(${A.toString()}+${B.toString()}\\right)$`,
        `$\\approx ${midd}$`,
      ]);
      const group = new MCQuestion.InputGroup(3);
      group.addInput(
        Math.round(10 * G.getComponents()[0]) / 10,
        0.05,
        "a) $\\ \\mathbf{i} = $"
      );
      group.addInput(
        Math.round(10 * G.getComponents()[1]) / 10,
        0.05,
        "$\\mathbf{j} = $"
      );
      group.addInput(
        Math.round(10 * G.getComponents()[2]) / 10,
        0.05,
        "$\\mathbf{k} = $"
      );
      group.addInput(
        Math.round(10 * mid.getComponents()[0]) / 10,
        0.05,
        "b) $\\ \\mathbf{i} = $"
      );
      group.addInput(
        Math.round(10 * mid.getComponents()[1]) / 10,
        0.05,
        "$\\mathbf{j} = $"
      );
      group.addInput(
        Math.round(10 * mid.getComponents()[2]) / 10,
        0.05,
        "$\\mathbf{k} = $"
      );
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Vectors_Vectors3D_Exam_Q11() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function d(s) {
      return MCRandom.randomInt(1, s) * MCRandom.randomInt(-1, 1, 2);
    }
    const F1 = new MCMaths.Vector(d(5), d(5), d(5));
    const F2 = new MCMaths.Vector(d(5), d(5), d(5));
    const F3 = F1.multiply(-1).sub(F2);

    const F12 = F1.add(F2);

    const mass = MCRandom.randomInt(2, 5);

    const v = new MCMaths.Vector(d(5), d(5), d(5));

    const t = MCRandom.randomInt(2, 5);

    const a = F12.multiply(1 / mass);

    function b(k) {
      if (k < 0) {
        return `\\left(${k}\\right)`;
      }
      return k;
    }

    const ansa = a.magnitude();
    const ansb = v.add(a.multiply(t));
    const ansbi = ansb.getComponents()[0];
    const ansbj = ansb.getComponents()[1];
    const ansbk = ansb.getComponents()[2];
    /* END VARIABLES AND CONSTANTS */
    if (
      a.getComponents()[0] !== 0 &&
      a.getComponents()[1] !== 0 &&
      a.getComponents()[2] !== 0 &&
      Math.round(ansa * 300) === ansa * 300 &&
      Math.round(ansbi * 10) === ansbi * 10 &&
      Math.round(ansbj * 10) === ansbj * 10 &&
      Math.round(ansbk * 10) ===
        ansbk * 10 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A particle of mass $${mass}kg$ is acted on by three forces $\\mathbf{F}_1=(${F1.toStringIJK()})N$, $\\mathbf{F}_2=(${F2.toStringIJK()})N$, $\\mathbf{F}_3=(${F3.toStringIJK()})N$. `,
        `The particle is not accelerating, and has velocity $\\mathbf{u}=(${v.toStringIJK()})ms^{-1}$.`,
        `After $\\mathbf{F}_3$ stops acting on the particle, it begins to accelerate.`,
        `a) Find the magnitude of the acceleration of the particle.`,
        `b) Find the velocity of the particle after $${t}s$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding Resultant Force:`,
        `$=\\mathbf{F}_1+\\mathbf{F}_2$`,
        `$=(${F1.toStringIJK()})+(${F2.toStringIJK()})$`,
        `$=(${F12.toStringIJK()})$`,
        `HEADING Finding Acceleration:`,
        `$\\mathbf{F}=m\\mathbf{a}$`,
        `$\\displaystyle \\mathbf{a} = \\frac{1}{${mass}}(${F12.toStringIJK()})$`,
        `$=${a.toStringIJK()}$`,
        `HEADING Finding Magnitude:`,
        MCMaths.cleaner(
          `$|\\mathbf{a}|=\\sqrt{${b(a.getComponents()[0])}^2+${b(
            a.getComponents()[1]
          )}^2+${b(a.getComponents()[2])}^2}$`
        ),
        MCMaths.cleaner(`$=${ansa}ms^{-2}$`),
        `b) HEADING Using $\\mathbf{v}=\\mathbf{u}+\\mathbf{a}t$:`,
        `$\\mathbf{v} = (${v.toStringIJK()}) + ${t}\\left(${a.toStringIJK()}\\right)$`,
        `$=\\left(${ansb.toStringIJK()}\\right)ms^{-1}$.`,
      ]);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(ansa, 0, "a)");
      question.addInputGroup(group2);
      const group = new MCQuestion.InputGroup(3);
      group.addInput(ansbi, 0, "b) $\\ \\mathbf{i} = $");
      group.addInput(ansbj, 0, "$\\mathbf{j} = $");
      group.addInput(ansbk, 0, "$\\mathbf{k} = $");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Vectors_Vectors3D_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function r() {
      return MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    }
    const a1 = r();
    const a2 = r();
    const a3 = r();
    const b1 = r();
    const b2 = r();
    const b3 = r();
    const c1 = r();
    const c2 = r();
    const c3 = r();
    const a = new MCMaths.Vector(a1, a2, a3);
    const b = new MCMaths.Vector(b1, b2, b3);
    const c = new MCMaths.Vector(c1, c2, c3);

    const m1 = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);
    let m1d = m1;
    if (m1 === 1) {
      m1d = "";
    }
    if (m1 === -1) {
      m1d = "-";
    }

    const m2 = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);
    let m2d = m2;
    if (m2 === 1) {
      m2d = "";
    }
    if (m2 === -1) {
      m2d = "-";
    }

    const m3 = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);
    let m3d = m3;
    if (m3 === 1) {
      m3d = "";
    }
    if (m3 === -1) {
      m3d = "-";
    }

    const working = new MCMaths.Vector(
      `${m1d}(${a1})+${m2d}(${b1})+${m3d}(${c1})`,
      `${m1d}(${a2})+${m2d}(${b2})+${m3d}(${c2})`,
      `${m1d}(${a3})+${m2d}(${b3})+${m3d}(${c3})`
    );
    const v = a.multiply(m1).add(b.multiply(m2).add(c.multiply(m3)));

    function n(x) {
      if (x < 0) {
        return `(${x})`;
      }
      return x;
    }

    const mag = v.magnitude();

    const v2 = v.multiply(1 / mag);
    const v2i = v2.getComponents()[0];
    const v2j = v2.getComponents()[1];
    const v2k = v2.getComponents()[2];

    /* END VARIABLES AND CONSTANTS */
    if (
      mag > 1 &&
      mag < 100 &&
      Math.round(mag) === mag &&
      Math.round(100 * v2i) === 100 * v2i &&
      Math.round(100 * v2j) === 100 * v2j &&
      Math.round(100 * v2k) === 100 * v2k &&
      Math.max(Math.abs(v2i), Math.abs(v2j), Math.abs(v2k)) !==
        1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given the three vectors $\\mathbf{a}=${a.toString()}$, $\\mathbf{b}=${b.toString()}$, $\\mathbf{c}=${c.toString()}$, find:`,
        MCMaths.cleaner(
          `a) The vector $${m1d}\\mathbf{a}+${m2d}\\mathbf{b}+${m3d}\\mathbf{c}$,`
        ),
        MCMaths.cleaner(
          `b) The value of $|${m1d}\\mathbf{a}+${m2d}\\mathbf{b}+${m3d}\\mathbf{c}|$,`
        ),
        MCMaths.cleaner(
          `c) The unit vector in the same direction as $${m1d}\\mathbf{a}+${m2d}\\mathbf{b}+${m3d}\\mathbf{c}$.`
        ),
      ]);
      question.addMultipleParagraphs("solution", [
        `a) $=${working.toString()}$`,
        `$=${v.toString()}$`,
        `b) $ = \\sqrt{${n(v.getComponents()[0])}^2+${n(
          v.getComponents()[1]
        )}^2+${n(v.getComponents()[2])}^2}$`,
        MCMaths.cleaner(`$=${mag}$`),
        `c) $\\displaystyle = \\frac{${m1d}\\mathbf{a}+${m2d}\\mathbf{b}+${m3d}\\mathbf{c}}{|${m1d}\\mathbf{a}+${m2d}\\mathbf{b}+${m3d}\\mathbf{c}|}$`,
        `$= \\displaystyle \\frac{1}{${mag}}${v.toString()}$`,
        `$=${v2}$`,
      ]);

      const group = new MCQuestion.InputGroup(3);
      group.addInput(v.getComponents()[0], 0, "a) $\\ \\mathbf{i}=$");
      group.addInput(v.getComponents()[1], 0, "$\\mathbf{j}=$");
      group.addInput(v.getComponents()[2], 0, "$\\mathbf{k}=$");
      const group2 = new MCQuestion.InputGroup(1);
      group.addInput(mag, 0, "b)");
      const group3 = new MCQuestion.InputGroup(3);
      group3.addInput(v2i, 0, "c) $\\ \\mathbf{i}=$");
      group3.addInput(v2j, 0, "$\\mathbf{j}=$");
      group3.addInput(v2k, 0, "$\\mathbf{k}=$");
      question.addInputGroup(group);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Vectors_Vectors3D_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function r() {
      return MCRandom.randomInt(1, 15) * MCRandom.randomInt(-1, 1, 2);
    }
    const a1 = Math.abs(r());
    const a2 = r();
    const a3 = Math.abs(r());
    const a = new MCMaths.Vector(a1, a2, a3);

    function n(x) {
      if (x < 0) {
        return `(${x})`;
      }
      return x;
    }

    const mag = a.magnitude();

    const xa = (Math.acos(a1 / mag) * 180) / Math.PI;
    const xad = Math.round(xa * 10) / 10;

    const xc = (Math.acos(a3 / mag) * 180) / Math.PI;
    const xcd = Math.round(xc * 10) / 10;
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(mag) === mag /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given $\\mathbf{v}=${a.toString()}$, find:`,
        `a) $|\\mathbf{v}|$`,
        `b) The angle $\\mathbf{v}$ makes with the positive $x$-axis`,
        `c) The angle $\\mathbf{v}$ makes with the positive $z$-axis`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING $=\\sqrt{${n(a1)}^2+${n(a2)}^2+${n(a3)}^2}$`,
        `$=${mag}$`,
        `b) = HEADING $\\displaystyle \\cos^{-1}\\left(\\frac{${a1}}{|\\mathbf{v}|}\\right)$`,
        `$=${xad}^\\circ$`,
        `c) = HEADING $\\displaystyle \\cos^{-1}\\left(\\frac{${a3}}{|\\mathbf{v}|}\\right)$`,
        `$=${xcd}^\\circ$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(mag, 0, "a)");
      group.addInput(xa, 0.5, "b)");
      group.addInput(xc, 0.5, "c)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Vectors_Vectors3D_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function d(s) {
      return MCRandom.randomInt(1, s) * MCRandom.randomInt(-1, 1, 2);
    }
    const p = new MCMaths.Vector(d(15), d(15), d(15));
    const q = new MCMaths.Vector(d(15), d(15), d(15));
    const par = new MCMaths.Vector(d(4), d(4), d(4));

    const div = MCRandom.randomInt(2, 8);

    const res = par.multiply(div);
    const resi = res.getComponents()[0];
    const resj = res.getComponents()[1];
    const resk = res.getComponents()[2];

    // straight line from res to pq. https://stackoverflow.com/questions/5227373/minimal-perpendicular-vector-between-a-point-and-a-line, a=0
    const Rp = p.add(q);
    const Rd = par.normalise();
    const Rx = Rd.multiply(Rp.dot(Rd));
    const r = Rx.sub(Rp);

    const mag = r.magnitude();
    const magd = MCMaths.cleaner(`${mag}`);

    const working1 = new MCMaths.Vector(
      MCMaths.cleaner(
        `${par.getComponents()[0]}k-${p.add(q).getComponents()[0]}`
      ),
      MCMaths.cleaner(
        `${par.getComponents()[1]}k-${p.add(q).getComponents()[1]}`
      ),
      MCMaths.cleaner(
        `${par.getComponents()[2]}k-${p.add(q).getComponents()[2]}`
      )
    );

    const w1 = MCMaths.cleaner(
      `(${par.getComponents()[0] ** 2}k^2-${
        2 * par.getComponents()[0] * p.add(q).getComponents()[0]
      }k+${p.add(q).getComponents()[0] ** 2})`
    );
    const w2 = MCMaths.cleaner(
      `(${par.getComponents()[1] ** 2}k^2-${
        2 * par.getComponents()[1] * p.add(q).getComponents()[1]
      }k+${p.add(q).getComponents()[1] ** 2})`
    );
    const w3 = MCMaths.cleaner(
      `(${par.getComponents()[2] ** 2}k^2-${
        2 * par.getComponents()[2] * p.add(q).getComponents()[2]
      }k+${p.add(q).getComponents()[2] ** 2})`
    );

    const sum =
      r.getComponents()[0] + r.getComponents()[1] + r.getComponents()[2];

    const working2 = new MCMaths.Polynomial(
      [
        par.getComponents()[0] ** 2 +
          par.getComponents()[1] ** 2 +
          par.getComponents()[2] ** 2,
        -2 * par.getComponents()[0] * p.add(q).getComponents()[0] -
          2 * par.getComponents()[1] * p.add(q).getComponents()[1] -
          2 * par.getComponents()[2] * p.add(q).getComponents()[2],
        p.add(q).getComponents()[0] ** 2 +
          p.add(q).getComponents()[1] ** 2 +
          p.add(q).getComponents()[2] ** 2 -
          mag ** 2,
      ],
      "k"
    );

    const k = working2.roots()[0];
    const k2 = working2.roots()[1];

    const ri = r.getComponents()[0];
    const rj = r.getComponents()[1];
    const rk = r.getComponents()[2];

    /* END VARIABLES AND CONSTANTS */
    // &&Math.round(mag)===mag
    if (
      div > 1 &&
      p.add(q).getComponents()[0] !== 0 &&
      p.add(q).getComponents()[1] !== 0 &&
      p.add(q).getComponents()[2] !== 0 &&
      resi !== 0 &&
      resj !== 0 &&
      resk !== 0 &&
      k !== "No Real Roots" &&
      parseFloat(magd.match(/\d+/g)[0]) < 100 &&
      mag < 15 &&
      Math.round(100 * mag ** 2) === 100 * mag ** 2 &&
      k === k2 &&
      Math.round(k * 100) === k * 100 &&
      Math.round(ri * 100) === ri * 100 &&
      Math.round(rj * 100) === rj * 100 &&
      Math.round(rk * 100) ===
        rk * 100 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `The resultant of the vectors $\\mathbf{p}$ and $\\mathbf{r}$ is parallel to $${par.toString()}$.`,
        `Given $\\mathbf{p} =${p
          .add(q)
          .toString()}$ and $|r|=${magd}$, find $\\mathbf{r}$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Forming Equation:`,
        `$\\mathbf{p}+\\mathbf{r}=k${par.toString()}$, for some $k\\in\\mathbb{R}$.`,
        `$\\mathbf{r}=k${par.toString()}-${p.add(q).toString()}$`,
        `$\\mathbf{r}=${working1.toString()}$`,
        `HEADING Using $|r|=${magd}$:`,
        `$${magd} = \\left| ${working1.toString()} \\right|$`,
        `$${magd}=\\sqrt{(${working1.getComponents()[0]})^2+(${
          working1.getComponents()[1]
        })^2+(${working1.getComponents()[2]})^2}$`,
        MCMaths.cleaner(`$${mag ** 2} = ${w1}+${w2}+${w3}$`),
        `$${working2.toString()}=0$`,
        `HEADING Solving for $k$:`,
        `$k=${working2.quadraticFormula()}$`,
        `$=${k}$`,
        `HEADING Finding $\\mathbf{r}$:`,
        `$=${working1.toString()}$`.replace(/k/g, `(${k})`),
        `$=${r.toString()}$`,
      ]);
      const group = new MCQuestion.InputGroup(3);
      group.addInput(ri, 0, "$\\mathbf{i}=$");
      group.addInput(rj, 0, "$\\mathbf{j}=$");
      group.addInput(rk, 0, "$\\mathbf{k}=$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Vectors_Vectors3D_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function d(s) {
      return MCRandom.randomInt(1, s) * MCRandom.randomInt(-1, 1, 2);
    }
    const B = new MCMaths.Vector(d(15), d(15), 0);
    const A = new MCMaths.Vector(0, 0, d(5));
    const AB = B.sub(A);

    const bi = B.getComponents()[0];
    const bj = B.getComponents()[1];
    const bk = B.getComponents()[2];

    let bid = bi;
    if (bi < 0) {
      bid = `(${bi})`;
    }
    let bjd = bj;
    if (bj < 0) {
      bjd = `(${bj})`;
    }

    const ansb = B.magnitude();

    const h = A.magnitude();

    const ansc = 0.5 * ansb * h;
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(ansb) ===
      ansb /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `In a triangle $OAB$, $\\overrightarrow{OA}=${A.toString()}$, $\\overrightarrow{AB}=${AB.toString()}$.`,
        `a) Find $\\overrightarrow{OB}$.`,
        `b) Find $|\\overrightarrow{OB}|$.`,
        `c) Find the area of the triangle`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING $\\overrightarrow{OB}=\\overrightarrow{OA}+\\overrightarrow{AB}$`,
        `$=${A.toString()}+${AB.toString()}$`,
        `$=${B.toString()}$`,
        `b) HEADING $ =\\sqrt{${bid}^2+${bjd}^2}$`,
        `$=${ansb}$`,
        `c) HEADING Triangle is right-angled.`,
        `Area $\\displaystyle =\\frac{1}{2}|\\overrightarrow{OB}|\\times \\mathbf{k}$ component of $\\overrightarrow{A}$`,
        `$\\displaystyle =\\frac{1}{2}\\times ${ansb} \\times ${h}$`,
        `$=${ansc}$`,
      ]);
      const group = new MCQuestion.InputGroup(3);
      group.addInput(bi, 0, "a) $\\ \\mathbf{i}=$");
      group.addInput(bj, 0, "$\\mathbf{j}=$");
      group.addInput(bk, 0, "$\\mathbf{k}=$");
      question.addInputGroup(group);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(ansb, 0, "b)");
      group2.addInput(ansc, 0, "c)");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Vectors_Vectors3D_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const x = MCRandom.randomInt(0, 360, 15);
    const y = MCRandom.randomInt(0, 360, 15);
    const z = MCRandom.randomInt(0, 360, 15);
    const lens = [2, 4, 6, 8, 10];
    const len = lens[MCRandom.randomInt(0, lens.length - 1)];

    const A = new MCMaths.Vector(1, 0, 0).rotate(x, y, z).multiply(len);
    const B = new MCMaths.Vector(0, 1, 0).rotate(x, y, z).multiply(len);
    const C = new MCMaths.Vector(0, 0, 1).rotate(x, y, z).multiply(len);

    const ai = A.getComponents()[0];
    const aj = A.getComponents()[1];
    const ak = A.getComponents()[2];

    function b(t) {
      return `\\left(${t}\\right)`;
    }

    const bi = B.getComponents()[0];
    const bj = B.getComponents()[1];
    const bk = B.getComponents()[2];

    const ci = C.getComponents()[0];
    const cj = C.getComponents()[1];
    const ck = C.getComponents()[2];

    const ansa = len ** 3;

    const w = A.add(B).add(C);
    const w2 = `\\begin{pmatrix} ${
      Math.round(w.getComponents()[0] * 100) / 100
    } \\\\ ${Math.round(w.getComponents()[1] * 100) / 100} \\\\ ${
      Math.round(w.getComponents()[2] * 100) / 100
    } \\end{pmatrix}`;
    const w3 = `\\begin{pmatrix} ${
      Math.round(w.getComponents()[0] * 50) / 100
    } \\\\ ${Math.round(w.getComponents()[1] * 50) / 100} \\\\ ${
      Math.round(w.getComponents()[2] * 50) / 100
    } \\end{pmatrix}`;

    const ansi = Math.round(w.getComponents()[0] * 100) / 200;
    const ansj = Math.round(w.getComponents()[1] * 100) / 200;
    const ansk = Math.round(w.getComponents()[2] * 100) / 200;
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(MCMaths.cleaner(`${ai}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${aj}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${ak}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${bi}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${bj}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${bk}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${ci}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${cj}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${ck}`).match(/\d+/g)[0]) < 100 &&
      ai !== len &&
      aj !== len &&
      ak !== len
      /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `The four points of a cube connected by edges are given by $O$, $\\overrightarrow{OA}=${A.toString()}, \\overrightarrow{OB}=${B.toString()}, \\overrightarrow{OC}=${C.toString()}$.`,
        `a) Find the volume of the cube.`,
        `b) Find the centre of mass of the cube, to 1dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding Side Length:`,
        MCMaths.cleaner(
          `$|\\overrightarrow{OA}|=\\sqrt{${b(ai)}^2+${b(aj)}^2+${b(ak)}^2}$`
        ),
        `$=${len}$`,
        `HEADING Finding Volume:`,
        `$=${len}^3$`,
        `$=${ansa}$`,
        `b) $\\displaystyle =\\frac{1}{2}\\left(${A.toString()}+${B.toString()}+${C.toString()}\\right) $`,
        `$\\displaystyle \\approx \\frac{1}{2}${w2}$`,
        `$=${w3}$`,
      ]);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(ansa, 0, "a)");
      question.addInputGroup(group2);
      const group = new MCQuestion.InputGroup(3);
      group.addInput(ansi, 0.05, "b) $\\ \\mathbf{i}=$");
      group.addInput(ansj, 0.05, "$\\mathbf{j}=$");
      group.addInput(ansk, 0.05, "$\\mathbf{k}=$");
      question.addInputGroup(group);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Vectors_Vectors3D_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function d(s) {
      return MCRandom.randomInt(1, s) * MCRandom.randomInt(-1, 1, 2);
    }
    const a = new MCMaths.Vector(6 + d(3), MCRandom.randomInt(-4, -2), d(1));
    const b = new MCMaths.Vector(d(2), 0, 6 + d(3));
    const c = new MCMaths.Vector(d(2), -a.getComponents()[1], d(1));

    const g = a.add(b).add(c);
    const bf = a.sub(b).add(c);

    const gi = g.getComponents()[0];
    const gj = g.getComponents()[1];
    const gk = g.getComponents()[2];

    const bi = b.getComponents()[0];
    const bj = b.getComponents()[1];
    const bk = b.getComponents()[2];

    const bfi = bf.getComponents()[0];
    const bfj = bf.getComponents()[1];
    const bfk = bf.getComponents()[2];

    const linEq = new MCMaths.Intersections([gi, -bfi, bi], [gk, -bfk, bk]);

    const x = linEq.solutions()[0];
    const y = linEq.solutions()[1];

    const p = g.multiply(x);
    const pi = p.getComponents()[0];
    const pj = p.getComponents()[1];
    const pk = p.getComponents()[2];
    /* END VARIABLES AND CONSTANTS */
    if (
      gj === 0 &&
      bfj === 0 &&
      Math.round(10 * x) === x * 10 &&
      Math.round(10 * y) === y * 10 &&
      Math.round(10 * pi) === pi * 10 &&
      Math.round(10 * pj) === pj * 10 &&
      Math.round(10 * pk) ===
        pk * 10 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addImage(
        "question",
        new MCQuestion.Image("James/Parallelepiped.svg", "width", 25)
      );
      question.addMultipleParagraphs("question", [
        `The above diagram shows a parallelepiped, a $3D$ shape formed by six parallelograms.`,
        `Given $\\overrightarrow{OA}=${a.toString()}$, $\\overrightarrow{OB}=${b.toString()}$, $\\overrightarrow{OC}=${c.toString()}$, find:`,
        `a) $\\overrightarrow{OG}$`,
        `b) $\\overrightarrow{BF}$`,
        `The point $P$ exists at the intersection of $\\overrightarrow{OG}$ and $\\overrightarrow{BF}$.`,
        `c) Find $\\overrightarrow{OP}$`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING $\\overrightarrow{OG} = \\overrightarrow{OA} + \\overrightarrow{AE} + \\overrightarrow{EG}$`,
        `$= \\overrightarrow{OA} + \\overrightarrow{OB} + \\overrightarrow{OC}$`,
        `$=${a.toString()}+${b.toString()}+${c.toString()}$`,
        `$=${g.toString()}$`,
        `b) HEADING $\\overrightarrow{BF} = \\overrightarrow{BO} + \\overrightarrow{OA} + \\overrightarrow{AF}$`,
        `$ = -\\overrightarrow{OB} + \\overrightarrow{OA} + \\overrightarrow{OC}$`,
        `$=-${b.toString()}+${a.toString()}+${c.toString()}$`,
        `$=${bf.toString()}$`,
        `c) HEADING Forming Equations:`,
        `$x\\overrightarrow{OG}=\\overrightarrow{OB}+y\\overrightarrow{BF}$`,
        `$x${g.toString()}=${b.toString()}+y${bf.toString()}$`,
        MCMaths.cleaner(`$${gi}x=${bi}+${bfi}y$`),
        `$0=0+0$`,
        MCMaths.cleaner(`$${gk}x=${bk}+${bfk}y$`),
        `HEADING Solving Simultaneously:`,
      ]);
      question.addMultipleParagraphs("solution", linEq.solutionsWorking());
      question.addMultipleParagraphs("solution", [
        `HEADING Finding $\\overrightarrow{OP}$:`,
        MCMaths.cleaner(`$=${x}${g.toString()}$`),
        `$=${p.toString()}$`,
      ]);
      const group = new MCQuestion.InputGroup(3);
      group.addInput(gi, 0, "a) $\\ \\mathbf{i} = $");
      group.addInput(gj, 0, "$\\mathbf{j} = $");
      group.addInput(gk, 0, "$\\mathbf{k} = $");
      group.addInput(bfi, 0, "b) $\\ \\mathbf{i} = $");
      group.addInput(bfj, 0, "$\\mathbf{j} = $");
      group.addInput(bfk, 0, "$\\mathbf{k} = $");
      group.addInput(pi, 0, "c) $\\ \\mathbf{i} = $");
      group.addInput(pj, 0, "$\\mathbf{j} = $");
      group.addInput(pk, 0, "$\\mathbf{k} = $");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Vectors_Vectors3D_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function d(s) {
      return MCRandom.randomInt(1, s) * MCRandom.randomInt(-1, 1, 2);
    }
    const a = new MCMaths.Vector(d(15), d(15), d(15));
    const t = MCRandom.randomInt(2, 5);
    const v = a.multiply(t);

    function b(k) {
      if (k < 0) {
        return `(${k})`;
      }
      return k;
    }

    const ansa = v.magnitude();

    const s = a.multiply(0.5 * t * t);

    const ansb = s.magnitude();
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(ansa) === ansa &&
      ansa < 30 &&
      Math.round(ansb * 10) === ansb * 10 &&
      ansa !== ansb /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A particle accelerates $(${a.toStringIJK()})ms^{-2}$ from rest.`,
        `a) Find the speed of the particle after $${t}s$.`,
        `b) Find total distance the particle travels after $${t}s$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING $\\mathbf{v}=\\mathbf{u}+\\mathbf{a}t$`,
        `$=\\mathbf{0}+${t}(${a.toStringIJK()})$`,
        `$=${v.toStringIJK()}$`,
        `$|${v.toStringIJK()}|=\\sqrt{${b(v.getComponents()[0])}^2+${b(
          v.getComponents()[1]
        )}^2+${b(v.getComponents()[2])}^2}$`,
        `$=${ansa}ms^{-1}$`,
        `b) HEADING $\\mathbf{s}=\\mathbf{u}t+\\frac{1}{2}\\mathbf{a}t^2$`,
        `$ = \\mathbf{0} + \\frac{1}{2}\\cdot ${t}^2\\cdot (${a.toStringIJK()})$`,
        `$ = ${s.toStringIJK()}$`,
        `$\\left|${s.toStringIJK()}\\right|=\\sqrt{${b(
          s.getComponents()[0]
        )}^2+${b(s.getComponents()[1])}^2+${b(s.getComponents()[2])}^2}$`,
        `$=${ansb}m$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ansa, 0, "a)");
      group.addInput(ansb, 0, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function A2_Vectors_Vectors3D_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function d(s) {
      return MCRandom.randomInt(1, s) * MCRandom.randomInt(-1, 1, 2);
    }
    const p = new MCMaths.Vector(d(15), d(15), d(15));
    const N = new MCMaths.Vector(d(60) / 6, d(60) / 6, d(60) / 6);

    function gcd(a1, b1) {
      const a = Math.abs(a1);
      const bk = Math.abs(b1);
      if (!bk) {
        return a;
      }
      return gcd(bk, a % bk);
    }

    function b(k) {
      if (k < 0) {
        return `\\left(${k}\\right)`;
      }
      return k;
    }

    const div = gcd(
      gcd(N.getComponents()[0], N.getComponents()[1]),
      N.getComponents()[2]
    );

    const par = N.multiply(1 / div);

    const mag = N.magnitude();

    const ans = p.sub(N).magnitude();
    /* END VARIABLES AND CONSTANTS */
    if (
      div > 2 &&
      Math.round(mag) === mag &&
      Math.round(ans) === ans /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `The point $N$ lies on the line parellel to $${par.toStringIJK()}$. Given $|ON|=${mag}$, find the distance from $N$ to $P$, given $\\overrightarrow{OP}=${p.toStringIJK()}$`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Finding $N$:`,
        `$N = k(${par.toStringIJK()})$ for some $k\\in\\mathbb{R}$.`,
        `$|N|=|k(${par.toStringIJK()})|$`,
        `$${mag}=k\\sqrt{${b(par.getComponents()[0])}^2+${b(
          par.getComponents()[1]
        )}^2+${b(par.getComponents()[2])}^2}$`,
        `$${mag}=${par.magnitude()}k$`,
        MCMaths.cleaner(`$k=${div}$`),
        MCMaths.cleaner(
          `$N = ${div}(${par.toStringIJK()})$ for some $k\\in\\mathbb{R}$.`
        ),
        `$=${N.toStringIJK()}$`,
        `HEADING Finding Distance:`,
        `$\\overrightarrow{NP}=\\overrightarrow{OP}-\\overrightarrow{ON}$`,
        `$=\\left(${p.toStringIJK()}\\right) - \\left(${N.toStringIJK()}\\right)$`,
        `$=${p.sub(N).toStringIJK()}$`,
        MCMaths.cleaner(
          `$|\\overrightarrow{NP}|=\\sqrt{${b(
            p.sub(N).getComponents()[0]
          )}^2+${b(p.sub(N).getComponents()[1])}^2+${b(
            p.sub(N).getComponents()[2]
          )}^2}$`
        ),
        `$=${ans}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans, 0);
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Graphs_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 5);
    const t = MCRandom.randomInt(1, 4);

    const m = -a / t ** 2;

    function f(t1) {
      return a / t1;
    }

    const c1 = f(t) - m * t;
    const c2 = f(-t) + m * t;
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(c1) * 10 === c1 * 10 &&
      Math.round(c2) * 10 ===
        c2 * 10 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$f(x)=\\frac{${a}}{x}$.`,
        `a) Plot the graph of $f$.`,
        `b) Write down the equations of the asymptotes of $f$.`,
        MCMaths.cleaner(`$y=${m}x+c$ is a tangent to $f$.`),
        `c) Find the possible values of $c$.`,
      ]);
      question.addMultipleParagraphs("solution", [`a) HEADING`]);
      const g = new MCQuestion.Graph(5, -5, 5, -5, 1, 1);
      g.plot(`${a}/x`, -7, 7);
      question.addGraph("solution", g);
      question.addMultipleParagraphs("solution", [
        `b) HEADING Asymptotes are $y=0$, $x=0$.`,
        `c) HEADING Finding Derivative:`,
        `$\\displaystyle \\frac{d}{dx} \\frac{${a}}{x} = -\\frac{${a}}{x^2}$`,
        `HEADING Finding $x$ coordinate:`,
        MCMaths.cleaner(`$-\\frac{${a}}{x^2} = ${m}$`),
        MCMaths.cleaner(`$x^2 = ${-a / m}$`),
        `$x=\\pm ${t}$`,
        `$x_1=${t}$, $x_2=${-t}$`,
        `HEADING Finding $c$:`,
        MCMaths.cleaner(`$y-f(x_1)=${m}(x-x_1)$`),
        MCMaths.cleaner(`$y-${f(t)}=${m}x+${-m * t}$`),
        MCMaths.cleaner(`$y=${m}x+${c1}$`),
        MCMaths.cleaner(`$c=${c1}$`),
        MCMaths.cleaner(`$y-f(x_2)=${m}(x-x_2)$`),
        MCMaths.cleaner(`$y-${f(-t)}=${m}x+${m * t}$`),
        MCMaths.cleaner(`$y=${m}x+${c2}$`),
        MCMaths.cleaner(`$c=${c2}$`),
      ]);

      const group = new MCQuestion.InputGroup(2);
      group.addInput([c1, c2], 0, "c)");
      question.addInputGroup(group);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Graphs_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const r2 = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const r3 = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const x = MCMaths.Polynomial.generateFromRoots([0, r2, r3, r3]);
    function f(t) {
      return x.evaluate(t);
    }

    const w1 = MCMaths.Polynomial.generateFromRoots([r2, r3, r3]);
    const w2 = MCMaths.Polynomial.generateFromRoots([r2, r3]);
    /* END VARIABLES AND CONSTANTS */
    if (
      r2 !== r3 &&
      x.plottingRange()[0] <=
        5 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$f(x)=${x.toString()}$.`,
        `a) Given a root of $f$ is $${r3}$, Factorise $f$. Give your answer in the form $x(x+A)(x+B)^2$.`,
        `b) Plot the graph of $f$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Factoring $x$:`,
        `$f(x) = x(${w1})$`,
        `HEADING Factoring $${r3}$:`,
        MCMaths.cleaner(`$f(x) = x(x-${r3})(${w2})$`),
        MCMaths.cleaner(`$f(x) = x(x-${r2})(x-${r3})^2$`),
        `b) HEADING`,
      ]);
      question.addGraph("solution", x.graph());
      const group = new MCQuestion.InputGroup(2);
      group.addInput(-r2, 0, "a) $\\ A=$");
      group.addInput(-r3, 0, "a) $\\ B=$");
      question.addInputGroup(group);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Graphs_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(-90, 90, 15);
    function f(t) {
      return a * Math.sin(((b + t) * Math.PI) / 180);
    }
    const y = f(0);
    const yd = MCMaths.cleaner(`${y}`);
    // http://localhost:3000/development/AS_AlgebraAndFunctions_Graphs_Exam_Q3
    /* END VARIABLES AND CONSTANTS */
    if (
      yd.match(/\d+/g)[1] <
      10 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const g = new MCQuestion.Graph(360, -360, a + 1, -a - 1, 90, 1);
      g.plot(`${a} * Math.sin(((${b} + x) * Math.PI) / 180)`, -360, 360);
      const question = new MCQuestion.ExamQuestion();
      question.addGraph("question", g);
      question.addMultipleParagraphs("question", [
        `The above graph shows $a\\sin(b+x)^\\circ$.`,
        `The $y$-intersection of the graph is at $\\left(0,${yd}\\right)$.`,
        `Given $a>0$, $-90^\\circ\\le x \\le 90^\\circ$, find the values of $a$ and $b$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Finding $a$:`,
        `Peak of graph is $y=${a}$,`,
        `Peak of $\\sin$ is $y=1$,`,
        `$\\therefore a=${a}$.`,
        `HEADING Finding $b$:`,
        `$${yd} = ${a}\\sin(b)$`,
        MCMaths.cleaner(`$b = \\sin^{-1}\\left(${y / a}\\right)$`),
        `$b=${b}^\\circ$.`,
      ]);
      const group = new MCQuestion.InputGroup(2);
      group.addInput(a, 0, "$a=$");
      group.addInput(b, 0, "$b=$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Graphs_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const r1 = MCRandom.randomInt(-5, 5);
    const r2 = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const r3 = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const x = MCMaths.Polynomial.generateFromRoots([r1, r2, r3]);

    const g = x.graph();

    function b(t) {
      if (t === 0) {
        return "x";
      }
      return `(x-${t})`;
    }

    const a = x.getCoefficients()[1];
    const b1 = x.getCoefficients()[2];
    const c = x.getCoefficients()[3];
    /* END VARIABLES AND CONSTANTS */
    if (
      r1 !== r2 &&
      r2 !== r3 &&
      r3 !== r1 &&
      x.plottingRange()[0] <= 5 &&
      a !== 0 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addGraph("question", g);
      question.addMultipleParagraphs("question", [
        `The above graph shows a cubic in the form $x^3+ax^2+bx+c$.`,
        `Given all the coefficients are integers, find $a,b$ and $c$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Finding Roots:`,
        `$=${r1}, ${r2}, ${r3}$ from graph.`,
        `HEADING Forming Polynomial:`,
        MCMaths.cleaner(`$=${b(r1)}${b(r2)}${b(r3)}$`),
        `HEADING Expanding:`,
        MCMaths.cleaner(
          `$=${b(r1)}(${MCMaths.Polynomial.generateFromRoots([
            r2,
            r3,
          ]).toString()})$`
        ),
        `$=${x.toString()}$`,
      ]);
      const group = new MCQuestion.InputGroup(3);
      group.addInput(a, 0, "$a=$");
      group.addInput(b1, 0, "$b=$");
      group.addInput(c, 0, "$c=$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Graphs_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);

    const p1 = new MCMaths.Polynomial([c, -2 * c * b, a + c * b * b]);
    const p2 = new MCMaths.Polynomial([1, -2 * b, b * b]);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$\\displaystyle f(x)=\\frac{${p1.toString()}}{${p2.toString()}}$ can be written in the form $\\displaystyle \\frac{a}{(x-b)^2}+c$.`,
        `a) Find $a,b$ and $c$.`,
        `b) Write down the asymptotes of $f(x)$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING $\\displaystyle f(x) = \\frac{${c}(${p2.toString()})+${a}}{${p2.toString()}}$`,
        MCMaths.cleaner(
          `$\\displaystyle = \\frac{${a}}{${p2.toString()}}+${c}$`
        ),
        `HEADING Factorising $${p2.toString()}$`,
        MCMaths.cleaner(`$\\displaystyle = \\frac{${a}}{(x-${b})^2}+${c}$`),
        `b) HEADING Asymptotes are:`,
        `$x=${b}$`,
        `$y=${c}$.`,
      ]);
      const group = new MCQuestion.InputGroup(3);
      group.addInput(a, 0, "$a=$");
      group.addInput(-b, 0, "$b=$");
      group.addInput(c, 0, "$c=$");
      question.addInputGroup(group);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Graphs_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const r1 = MCRandom.randomInt(0, 7);
    const r2 = MCRandom.randomInt(1, 7) * MCRandom.randomInt(-1, 1, 2);

    const f = MCMaths.Polynomial.generateFromRoots([r1, r2, r2]);

    const g = MCRandom.randomInt(1, 4);

    const fg = f
      .multiply(new MCMaths.Polynomial([1, 0, 0]))
      .subtract(new MCMaths.Polynomial([g]));

    function b(t) {
      if (t === 0) {
        return "x";
      }
      return `(x-${t})`;
    }

    const root = fg.newtonMethod();

    /* END VARIABLES AND CONSTANTS */
    if (
      [...new Set([r1, r2])].length === [r1, r2].length &&
      f.plottingRange()[0] <= 5 &&
      r1 > r2 &&
      g / root ** 2 < 4 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        MCMaths.cleaner(
          `$f(x)=${b(r1)}${b(r2)}^2$, $\\displaystyle g(x)=\\frac{${g}}{x^2}$.`
        ),
        `a) Plot the graphs of $f$ and $g$ on the same set of axis.`,
        `b) Using the graph, find the number of real roots of $${fg.toString()}$.`,
      ]);
      question.addMultipleParagraphs("solution", ["HEADING a)"]);
      const grp = new MCQuestion.Graph(5, -5, 5, -5, 1, 1);
      grp.plot(f.functionString(), -7, 7);
      grp.plot(`${g} / x ** 2`, -7, 7);
      // grp.addParametric(function f1(t){return root}, function g1(t){return t},-5,5)
      question.addGraph("solution", grp);

      question.addMultipleParagraphs("solution", [
        `b) HEADING $f(x)=g(x)$ intersect once.`,
        `HEADING Expanding $f(x)=g(x)$:`,
        `$\\displaystyle ${f.toString()}=\\frac{${g}}{x^2}$`,
        `$\\displaystyle ${f.toString()}-\\frac{${g}}{x^2}=0$`,
        `$\\displaystyle ${fg.toString()}=0$`,
        `$\\therefore ${fg.toString()}$ has one root.`,
      ]);
      question.requiresSelfMarking();
      const group = new MCQuestion.InputGroup(1);
      group.addInput(1, 0, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Graphs_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const b = MCRandom.randomInt(2, 4);

    const type = MCRandom.randomInt(0, 1);

    let f = "\\sin";
    if (type === 1) {
      f = "\\cos";
    }

    let s;
    if (type === 0) {
      s = `Math.sin((x * Math.PI) / 180)`;
    } else {
      s = `Math.cos((x * Math.PI) / 180)`;
    }
    let sb;
    if (type === 0) {
      sb = `Math.sin((x * ${b} * Math.PI) / 180)`;
    } else {
      sb = `Math.cos((x * ${b} * Math.PI) / 180)`;
    }
    let ansb = 3;

    if (type === 1 && b === 2) {
      ansb = 1;
    }

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `a) Plot the graphs of $${f}(x)$ and  $${f}(${b}x)$ on the same axis, for $-90^\\circ \\le x \\le 90^\\circ$.`,
        `b) State the number of solutions $${f}(x)-${f}(${b}x)=0$ has for $-90^\\circ \\le x \\le 90^\\circ$.`,
      ]);
      question.addMultipleParagraphs("solution", [`a) HEADING`]);
      const grp = new MCQuestion.Graph(90, -90, 1, -1, 45, 1);
      grp.plot(s, -90, 90);
      grp.plot(sb, -90, 90);
      question.addGraph("solution", grp);
      question.addMultipleParagraphs("solution", [
        `b) HEADING $${ansb}$ intersections on graph.`,
        `$\\therefore ${ansb}$ solutions.`,
      ]);

      question.requiresSelfMarking();
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ansb, 0, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Graphs_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const r1 = MCRandom.randomInt(-5, 5);
    const r2 = MCRandom.randomInt(1, 7) * MCRandom.randomInt(-1, 1, 2);
    const r3 = MCRandom.randomInt(1, 7) * MCRandom.randomInt(-1, 1, 2);

    const o = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);

    const f = MCMaths.Polynomial.generateFromRoots([r1, r2, r3]);
    const f2 = MCMaths.Polynomial.generateFromRoots([r1 + o, r2 + o, r3 + o]);

    const w = MCMaths.Polynomial.generateFromRoots([r2, r3]);

    function b(t) {
      if (t === 0) {
        return "x";
      }
      return `(x-${t})`;
    }
    /* END VARIABLES AND CONSTANTS */
    if (
      [...new Set([r1, r2, r3])].length === [r1, r2, r3].length &&
      f.plottingRange()[0] <= 5 &&
      f2.plottingRange()[0] <=
        5 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `a) Factorise $f(x)=${f.toString()}$ completely.`,
        `b) Plot the graph of $f$.`,
        MCMaths.cleaner(
          `c) Plot the graph of $${f.toString().replace(/x/g, `(x-${o})`)}$.`
        ),
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING $f(${r1})=0$`,
        MCMaths.cleaner(`$f(x) = ${b(r1)}(${w.toString()})$`),
        MCMaths.cleaner(`$f(x) = ${b(r1)}${b(r2)}${b(r3)}$.`),
        `b) HEADING`,
      ]);
      question.addGraph("solution", f.graph());
      question.addParagraph(
        "solution",
        `c) HEADING Translation of $f$ by $${new MCMaths.Vector(
          o,
          0
        ).toString()}$`
      );
      question.addGraph("solution", f2.graph());
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Inequalities_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m1 = MCRandom.randomInt(-5, 5);
    const c1 = MCRandom.randomInt(-10, 10);
    const m2 = MCRandom.randomInt(-5, 5);
    const c2 = MCRandom.randomInt(-10, 10);

    const b_x2 = MCRandom.randomInt(1, 3);
    const b_x = MCRandom.randomInt(-10, 10);
    const b_c = MCRandom.randomInt(-20, 20);

    const b_m = MCRandom.randomInt(-10, 10);
    const b_offset = MCRandom.randomInt(-20, 20);

    const b_poly = new MCMaths.Polynomial(
      [b_x2, b_x - b_m, b_c - b_offset],
      "x"
    );
    const b_roots = b_poly.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      m1 !== 0 &&
      m2 !== 0 &&
      c1 !== 0 &&
      c2 !== 0 &&
      m1 !== m2 &&
      Math.abs(m1 - m2) > 1 &&
      b_x !== 0 &&
      b_c !== 0 &&
      b_offset !== 0 &&
      b_roots[0] !== "No Real Roots" &&
      b_roots[0] % (1 / 8) === 0 &&
      b_roots[1] % (1 / 8) === 0 &&
      b_roots[0] > b_roots[1] &&
      b_m !== b_x &&
      b_offset !== b_c
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph("question", `Solve the following inequalities:`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(`a) $${m1}x+${c1} > ${m2}x+${c2}$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`b) $${b_x2}x^2+${b_x}x+${b_c}>${b_m}x+${b_offset}$`)
      );
      // solution
      question.addHeading(
        "solution",
        MCMaths.cleaner(`a) $${m1}x+${c1} > ${m2}x+${c2}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${m1 - m2}x>${c2 - c1}$`)
      );
      if (m1 - m2 > 0) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x>${(c2 - c1) / (m1 - m2)}$`)
        );
        const group1 = new MCQuestion.InputGroup(1);
        group1.addInput((c2 - c1) / (m1 - m2), 0, "a) $\\ x>$");
        question.addInputGroup(group1);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x<${(c2 - c1) / (m1 - m2)}$.`)
        );
        const group1 = new MCQuestion.InputGroup(1);
        group1.addInput((c2 - c1) / (m1 - m2), 0, "a) $\\ x<$");
        question.addInputGroup(group1);
      }
      // b_solution
      question.addHeading(
        "solution",
        MCMaths.cleaner(`b) $${b_x2}x^2+${b_x}x+${b_c}>${b_m}x+${b_offset}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${b_x2}x^2+${b_x - b_m}x+${b_c - b_offset}>0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x<${b_roots[1]}$ or $x>${b_roots[0]}$.`)
      );

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(b_roots[1], 0, "b) $\\ x<$");
      group2.addInput(b_roots[0], 0, "b) $\\ x>$");
      question.addInputGroup(group2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Inequalities_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x2 = MCRandom.randomInt(-3, 3);
    const x = MCRandom.randomInt(-10, 10);
    const xk = MCRandom.randomInt(1, 4);
    const ck = MCRandom.randomInt(-5, 5);
    const c = MCRandom.randomInt(-20, 20);

    const poly = new MCMaths.Polynomial(
      [xk ** 2, 2 * xk * x - 4 * x2 * ck, x ** 2 - 4 * x2 * c],
      "k"
    );
    const roots = poly.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      x2 !== 0 &&
      x !== 0 &&
      xk !== 0 &&
      ck !== 0 &&
      c !== 0 &&
      roots[0] !== "No Real Roots" &&
      roots[0] % (1 / 8) === 0 &&
      roots[1] % (1 / 8) === 0 &&
      roots[0] > roots[1]
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A quadratic $${x2}x^2+(${xk}k+${x})x+(${ck}k+${c})=0$ has no real roots.`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Find the range of possible values for the constant $k$.`
        )
      );
      // solution
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${x2}x^2+(${xk}k+${x})x+(${ck}k+${c})=0$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`No real roots $\\Rightarrow$ $b^2-4ac<0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(${xk}k+${x})^2-4(${x2})(${ck}k+${c})<0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${poly.toString()}<0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore ${roots[1]}<k<${roots[0]}$`)
      );
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(roots[1], 0, "$k>$");
      group1.addInput(roots[0], 0, "$k<$");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Inequalities_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m = MCRandom.randomInt(-4, 4);
    const c = MCRandom.randomInt(2, 10);

    const x2 = MCRandom.randomInt(2, 4);
    const x = MCRandom.randomInt(-10, 10);
    const c2 = MCRandom.randomInt(-20, -5);

    const line_poly = new MCMaths.Polynomial([x2, x - m, c2 - c], "x");
    const quad_poly = new MCMaths.Polynomial([x2, x, c2], "x");

    const line_roots = line_poly.roots();
    const quad_roots = quad_poly.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      line_roots[0] !== "No Real Roots" &&
      line_roots[0] % (1 / 8) === 0 &&
      line_roots[1] % (1 / 8) === 0 &&
      line_roots[0] > line_roots[1] &&
      quad_roots[0] !== "No Real Roots" &&
      quad_roots[0] % (1 / 8) === 0 &&
      quad_roots[1] % (1 / 8) === 0 &&
      quad_roots[0] > quad_roots[1] &&
      (m > 1 || m < -1) &&
      c !== 0 &&
      (x > 1 || x > -1) &&
      c2 !== 0 &&
      line_roots[0] > quad_roots[0] &&
      line_roots[1] < quad_roots[1]
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Find the values of $x$ that satisfy both of the following inequalities:`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$${x2}x^2+${x}x+${c2} < ${m}x+${c}$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$${x2}x^2+${x}x+${c2}>0$`)
      );
      // solution
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Solving $${x2}x^2+${x}x+${c2}<${m}x+${c}$ :`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${x2}x^2+${x - m}x+${c2 - c}<0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore ${line_roots[1]}<x<${line_roots[0]}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Solving $${x2}x^2+${x}x+${c2}>0$ :`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x<${quad_roots[1]}$ or $x>${quad_roots[0]}$.`
        )
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Satisfying both inequalities:`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${line_roots[1]}<x<${quad_roots[1]}$ or $${quad_roots[0]}<x<${line_roots[0]}$.`
        )
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput([quad_roots[0], line_roots[1]], 0, "$x>$");
      group1.addInput([quad_roots[1], line_roots[0]], 0, "$x<$");
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Inequalities_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const lower = MCRandom.randomInt(-20, -5);
    const upper = MCRandom.randomInt(5, 20);

    const m = MCRandom.randomInt(-5, 5);
    const c = MCRandom.randomInt(-20, 20);
    /* END VARIABLES AND CONSTANTS */
    if ((m > 1 || m < -1) && c !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // answer
      const group1 = new MCQuestion.InputGroup(2);
      // question
      question.addParagraph(
        "question",
        MCMaths.cleaner(`Solve the inequality $${lower}<${m}x+${c}<${upper}$.`)
      );
      // solution
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Solving $${lower}<${m}x+${c}$:`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${m}x+${c}>${lower}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${m}x>${lower - c}$`)
      );
      if (m > 0) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x>${(lower - c) / m}$`)
        );
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x<${(lower - c) / m}$`)
        );
      }
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Solving $${m}x+${c}<${upper}$:`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${m}x+${c}<${upper}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${m}x<${upper - c}$`)
      );
      if (m > 0) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x<${(upper - c) / m}$`)
        );
        group1.addInput((upper - c) / m, 0, "$x<$");
        group1.addInput((lower - c) / m, 0, "$x>$");
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x>${(upper - c) / m}$`)
        );
        group1.addInput((lower - c) / m, 0, "$x<$");
        group1.addInput((upper - c) / m, 0, "$x>$");
      }

      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Inequalities_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const l = MCRandom.randomInt(40, 60);
    const w = MCRandom.randomInt(15, 30);
    const area = l * w;

    const x = MCRandom.randomInt(31, 39);

    const poly = new MCMaths.Polynomial(
      [1, l - x + (w - x), (l - x) * (w - x) - area],
      "x"
    );
    const roots = poly.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      roots[0] !== "No Real Roots" &&
      roots[0] % (1 / 8) === 0 &&
      roots[1] % (1 / 8) === 0 &&
      roots[0] > roots[1]
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A swimming pool measures $(x+${l - x})$m by $(x+${w - x})$m.`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Given that its area must be greater than $${area}$m$^2$, find the range of possible values of $x$.`
        )
      );
      // solution
      question.addHeading(
        "solution",
        MCMaths.cleaner(`As $(x+${w - x})$m is a dimension:`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$x>${-w + x}$`));
      question.addHeading(
        "solution",
        `Given area is greater than $${area}$m$^2$:`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(x+${l - x})(x+${w - x})>${area}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${poly.toString()}>0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x>${roots[0]}$ , $x\\nless${roots[1]}$ as $x>${-w + x}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x>${roots[0]}$ and $x>${-w + x}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x>${roots[0]}$.`)
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(roots[0], 0, "$x>$");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Inequalities_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const l = MCRandom.randomInt(50, 80);
    const w = MCRandom.randomInt(50, 80);

    const area = Math.round(l * w * 0.75);
    const perimeter = Math.round(2 * (l + w) * 1.25);

    const denominator = MCRandom.randomInt(2, 5);
    const ratio = MCRandom.randomInt(1, denominator - 1) / denominator;
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.sqrt(area / (1 / ratio)) % (1 / 8) === 0 &&
      (perimeter / (2 * (1 + 1 / ratio))) % (1 / 8) === 0 &&
      perimeter / (2 * (1 + 1 / ratio)) > Math.sqrt(area / (1 / ratio))
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A farmer must set up a rectangular area of at least $${area}$m$^2$ for his sheep but only has access to $${perimeter}$m of fencing.`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `He also needs the length of the area to be $${ratio}$ of its width in order to optimise the usage of his land.`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Find the range of possible values of the area's length, $l$.`
        )
      );
      // solution
      question.addParagraph("solution", `Set $w$ as width of the area.`);
      question.addHeading("solution", MCMaths.cleaner(`Given $l=${ratio}w$:`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore w=${1 / ratio}l$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given area is at least $${area}$m$^2$:`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$l\\times w \\geq ${area}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Substituting $w=${1 / ratio}l$:`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$l\\times (${1 / ratio}l) \\geq ${area}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$l^2 \\geq ${area / (1 / ratio)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore l \\geq ${Math.sqrt(area / (1 / ratio))}$ , $l \\nleq ${
            -1 * Math.sqrt(area / (1 / ratio))
          }$`
        )
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Given perimeter is less than or equal to $${perimeter}m$:`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$2(l+w) \\leq ${perimeter}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Substituting $w=${1 / ratio}l$:`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$2[l+(${1 / ratio}l)] \\leq ${perimeter}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${2 * (1 + 1 / ratio)}l \\leq ${perimeter}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore l \\leq ${perimeter / (2 * (1 + 1 / ratio))}$`
        )
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(perimeter / (2 * (1 + 1 / ratio)), 0, "$l\\leq $");
      group1.addInput(Math.sqrt(area / (1 / ratio)), 0, "$l\\geq $");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Inequalities_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m = MCRandom.randomInt(-6, -1) / 4;
    const c = MCRandom.randomInt(12, 32) / 4;

    const x_min = MCRandom.randomInt(8, 28) / 4;
    const y_min = MCRandom.randomInt(8, 20) / 4;

    const poly = new MCMaths.Polynomial(
      [1, -2 * x_min - m, x_min ** 2 + y_min - c],
      "x"
    );
    const quadratic = new MCMaths.Polynomial(
      [1, -2 * x_min, x_min ** 2 + y_min],
      "x"
    );

    const k4 = -1 * ((-2 * x_min - m) ** 2 - 4 * (x_min ** 2 + y_min - c));
    const k = k4 / 4;
    /* END VARIABLES AND CONSTANTS */
    if (
      poly.roots()[0] === "No Real Roots" &&
      k4 % (1 / 8) === 0 &&
      k % (1 / 8) === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // graph
      const graph = new MCQuestion.Graph(10, 0, 10, 0, 1, 1);
      graph.plot(`${m} * x + ${c}`, 0, 10);
      graph.plot(`(x - ${x_min}) ** 2 + ${y_min}`, 0, 10);
      question.addGraph("question", graph);
      // question
      question.addParagraph(
        "question",
        `The graph above shows a line $L$ and curve $C$.`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`The line $L$ has equation: $y=${m}x+${c}$.`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `The curve $C$ has equation: $y=${quadratic.toString()}$.`
        )
      );
      question.addParagraph(
        "question",
        `The line $L$ is translated by $k$ units in the positive $y$ direction so that it now intersects $C$ at one or more points.`
      );
      question.addParagraph(
        "question",
        `Find the range of possible values of $k$.`
      );
      // solution
      question.addHeading(
        "solution",
        `$L$ is translated by $k$ units in the positive $y$ direction:`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore L$ has new equation $y = ${m}x+${c}+k$.`)
      );
      question.addHeading("solution", `Equate equations for $L$ and $C$:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${quadratic.toString()} = ${m}x+${c}+k$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${poly.toString()} - k = 0$`)
      );
      question.addHeading(
        "solution",
        `More than one solution $\\Rightarrow$ $b^2-4ac \\geq 0$:`
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `$(${-2 * x_min - m})^2-4(1)(${x_min ** 2 + y_min - c} - k) \\geq 0$`
        )
      );
      question.addHeading("solution", MCMaths.cleaner(`$4k \\geq ${k4}$`));
      question.addHeading(
        "solution",
        MCMaths.cleaner(`$\\therefore k \\geq ${k}$`)
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(k, 0, "$\\ k\\geq$");
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Inequalities_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const c = MCRandom.randomInt(3, 7);

    const root2 = MCRandom.randomInt(-2, 9);

    const poly = MCMaths.Polynomial.generateFromRoots([c, root2], "x");

    /* END VARIABLES AND CONSTANTS */
    if (root2 !== c && root2 !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(4);
      question.addParagraph(`Given:`);
      question.addHeading(`A: $x<${c}$,`);
      question.addHeading(`B: $(x-${root2})(x-${c})>0$.`);
      question.addParagraph("Choose the correct statement below:");

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`A $\\Rightarrow$ B`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`B $\\Rightarrow$ A`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`A $\\Leftrightarrow$ B`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`No relation between A and B.`);

      if (c > root2) {
        question.addAnswer(a1, false);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, true);
      } else {
        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Inequalities_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const root1 = MCRandom.randomInt(-5, -1);
    const root2 = MCRandom.randomInt(1, 5);
    const poly = MCMaths.Polynomial.generateFromRoots([root1, root2], "x");
    const Qsign = MCRandom.randomInt(0, 1); // 0 = <, 1 = >

    const value = MCRandom.randomInt(-10, 10);
    const Vsign = MCRandom.randomInt(0, 1); // 0 = <, 1 = >
    /* END VARIABLES AND CONSTANTS */
    if (value > root1 && value < root2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Which of the following sets of values for $x$ satisfy both inequalities below:`
      );
      if (Qsign === 0) {
        question.addParagraph(MCMaths.cleaner(`$(x-${root1})(x-${root2})<0$,`));
      } else {
        question.addParagraph(MCMaths.cleaner(`$(x-${root1})(x-${root2})>0$,`));
      }
      if (Vsign === 0) {
        question.addParagraph(`$x<${value}$.`);
      } else {
        question.addParagraph(`$x>${value}$.`);
      }

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$x > ${root2}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$x < ${root1}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$x > ${value}$ and $x<${root2}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$x > ${root1}$ and $x<${value}$`);

      if (Qsign === 0 && Vsign === 0) {
        question.addAnswer(a1, false);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, true);
      } else if (Qsign === 0 && Vsign === 1) {
        question.addAnswer(a1, false);
        question.addAnswer(a2, false);
        question.addAnswer(a3, true);
        question.addAnswer(a4, false);
      } else if (Qsign === 1 && Vsign === 0) {
        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      } else {
        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Inequalities_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const root1 = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);
    const root2 = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);

    const offset_x = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);
    const offset_c = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);

    const toPlot = new MCMaths.Polynomial(
      [1, -root1 - root2, root1 * root2],
      "x"
    );

    const poly = new MCMaths.Polynomial(
      [1, -root1 - root2 - offset_x, root1 * root2 - offset_c],
      "x"
    );
    const roots = poly.roots();

    const sign = MCRandom.randomInt(0, 1); // o < || 1 >

    /* END VARIABLES AND CONSTANTS */
    if (
      root1 !== 0 &&
      root2 !== 0 &&
      root1 !== root2 &&
      offset_x !== 0 &&
      roots[0] !== `No Real Roots` &&
      roots[0] % 1 === 0 &&
      roots[1] % 1 === 0 &&
      roots[0] !== roots[1] &&
      roots[1] < roots[0] &&
      Math.abs(roots[0]) < 10 &&
      Math.abs(roots[1]) < 10 &&
      Math.abs(offset_x * roots[0] + offset_c) < 10 &&
      Math.abs(offset_x * roots[1] + offset_c) < 10 &&
      Math.abs(roots[0] - roots[1]) > 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      const graph = new MCQuestion.Graph(12, -12, 12, -12, 2, 2);
      graph.plot(`${offset_x} * x + ${offset_c}`, -13, 13);
      graph.plot(toPlot.functionString(), -13, 13);
      question.addGraph(graph);

      question.addParagraph(
        `The graph above shows the intersection of the line ${MCMaths.cleaner(
          `$y=${offset_x}x+${offset_c}$`
        )} with the curve $y=${toPlot.toString()}$.`
      );

      question.addParagraph(
        `The intersections are at $x=${roots[1]}$ and $x=${roots[0]}$.`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$x=${roots[MCRandom.randomInt(0, 1)]}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(MCMaths.cleaner(`$x=${(roots[0] + roots[1]) / 2}$`));

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$x=${roots[0] + MCRandom.randomInt(2, 5)}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$x=${roots[1] - MCRandom.randomInt(2, 5)}$`);

      if (sign === 0) {
        question.addParagraph(
          MCMaths.cleaner(
            `Hence, select the value of $x$ that satisfies $${toPlot.toString()}<${offset_x}x+${offset_c}$.`
          )
        );
        // question.addHeading(`Choose ONE`);
        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      } else {
        question.addParagraph(
          MCMaths.cleaner(
            `Hence, select the values of $x$ that satisfy $${toPlot.toString()}>${offset_x}x+${offset_c}$.`
          )
        );
        // question.addHeading(`Choose TWO`);
        question.addAnswer(a1, false);
        question.addAnswer(a2, false);
        question.addAnswer(a3, true);
        question.addAnswer(a4, true);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_LawsOfIndices_Exam_Q1() {
  while (true) {
    const abase = MCRandom.randomInt(2, 6);
    const bbase = MCRandom.randomInt(2, 6);
    const apower = MCRandom.randomInt(2, 4);
    const bpower = MCRandom.randomInt(2, 4);
    const a_answer = abase ** apower;
    const b_answer = bbase ** bpower;
    const c_answer = a_answer - b_answer;
    if (true && apower !== bpower && abase !== bbase) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `State the values of:`);
      question.addParagraph(
        "question",
        `a) $${abase.toString()}^${apower.toString()}$`
      );
      question.addParagraph(
        "question",
        `b) $${bbase.toString()}^${bpower.toString()}$`
      );
      question.addParagraph(
        "question",
        `c) Hence, find the value of $${abase.toString()}^${apower.toString()}-${bbase.toString()}^${bpower.toString()}$`
      );
      question.addHeading(
        "solution",
        `a) State the value of $${abase.toString()}^${apower.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$${abase.toString()}^${apower.toString()} = ${a_answer.toString()}$`
      );
      question.addHeading(
        "solution",
        `b) State the value of $${bbase.toString()}^${bpower.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$${bbase.toString()}^${bpower.toString()} = ${b_answer.toString()}$`
      );
      question.addHeading(
        "solution",
        `c) Hence, find the value of $${abase.toString()}^${apower.toString()}-${bbase.toString()}^${bpower.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$${abase.toString()}^${apower.toString()}-${bbase.toString()}^${bpower.toString()}=${a_answer.toString()}-${b_answer.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore${abase.toString()}^${apower.toString()}-${bbase.toString()}^${bpower.toString()}=${c_answer.toString()}$`
      );
      const group = new MCQuestion.InputGroup(1);
      group.addInput(a_answer, 0, "a)");
      group.addInput(b_answer, 0, "b)");
      group.addInput(c_answer, 0, "c)");
      question.addInputGroup(group);

      // question.addAnswerBox(a_answer, 0, "a)");
      // question.addAnswerBox(b_answer, 0, "b)");
      // question.addAnswerBox(c_answer, 0, "c)");
      return question.makeQuestion(); // complete
    }
  }
}


export function AS_AlgebraAndFunctions_LawsOfIndices_Exam_Q10() {
  while (true) {
    const pow1 = MCRandom.randomInt(2, 3);
    const pow2 = MCRandom.randomInt(2, 3);
    const coefficient = MCRandom.randomInt(2, 10);
    const num1 = MCRandom.randomInt(2, 20);
    const pow3 = MCRandom.randomInt(2, 4);
    const num2 = MCRandom.randomInt(2, 5);
    const a_answer = MCRandom.randomInt(2, 5);
    const b_answer = ((num1 * num1) / coefficient) ** 0.5;
    const c_answer = num2;
    if (true && pow1 !== pow2) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Solve the following equations, to find $k$, $p$ and $q$ respectively: `
      );
      question.addParagraph(
        "question",
        `a) $(k^{${pow1}})^{${pow2}} = ${a_answer ** (pow1 * pow2)}$`
      );
      question.addParagraph(
        "question",
        `b) $(${coefficient}\\times p^2 )^{\\frac{1}{2}} = ${num1}$, given $p$ is positive and to $2dp$.`
      );
      if (pow3 === 2 || pow3 === 4) {
        question.addParagraph(
          "question",
          `c) $q^{-\\frac{1}{${pow3}}} = \\frac{1}{${
            num2 ** pow3
          }}$, given $q$ is positive.`
        );
      } else {
        question.addParagraph(
          "question",
          `c) $ \\displaystyle q^{-\\frac{1}{${pow3}}} = \\frac{1}{${
            num2 ** pow3
          }}$`
        );
      }
      question.addHeading("solution", `a) Finding  $k$: `);
      question.addParagraph(
        "solution",
        `$k^{${pow1 * pow2}} = ${a_answer ** (pow1 * pow2)}$`
      );
      question.addParagraph("solution", `$\\therefore k = ${a_answer}$`);
      question.addHeading("solution", `b) Finding $p$: `);
      question.addParagraph(
        "solution",
        `$${coefficient}\\times p^2 = ${num1 * num1}$`
      );
      question.addParagraph(
        "solution",
        `$p^2 = \\frac{${num1 * num1}}{${coefficient}}$`
      );
      question.addParagraph(
        "solution",
        `$p = \\frac{${num1}}{\\sqrt{${coefficient}}}$`
      );
      question.addParagraph("solution", `$p = ${b_answer}$`);
      question.addParagraph(
        "solution",
        `$\\therefore p = ${b_answer.toFixed(2)}$ (2dp)`
      );
      question.addHeading("solution", `c) Finding $q$: `);
      question.addParagraph(
        "solution",
        `$\\frac{1}{q^{${pow3}}} = \\frac{1}{${num2 ** pow3}}$`
      );
      question.addParagraph("solution", `$q^{${pow3}} = ${num2 ** pow3}$`);
      question.addParagraph("solution", `$\\therefore q = ${c_answer}$`);
      // complete

      const group = new MCQuestion.InputGroup(1);
      group.addInput(a_answer, 0, "a) $\\ k=$");
      group.addInput(Number(b_answer.toFixed(2)), 0.05, "b) $\\ p=$");
      group.addInput(c_answer, 0, "c) $\\ q=$");
      question.addInputGroup(group);

      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_LawsOfIndices_Exam_Q2() {
  while (true) {
    const abase = MCRandom.randomInt(2, 6);
    const bbase = MCRandom.randomInt(2, 6);
    const apower = MCRandom.randomInt(2, 4);
    const bpower = MCRandom.randomInt(2, 4);
    const a_answer = abase;
    const b_answer = bbase;
    const c_answer = a_answer - b_answer;
    if (true && apower !== bpower && abase !== bbase) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `State the values of :`);
      question.addParagraph(
        "question",
        `a) $${abase ** apower}^\\frac{1}{${apower}}$`
      );
      question.addParagraph(
        "question",
        `b) $${bbase ** bpower}^\\frac{1}{${bpower}}$`
      );
      question.addParagraph(
        "question",
        `c) Hence, find the value of $${abase ** apower}^\\frac{1}{${apower}}-${
          bbase ** bpower
        }^\\frac{1}{${bpower}}$`
      );
      question.addHeading(
        "solution",
        `a) State the value of $${abase ** apower}^\\frac{1}{${apower}}$:`
      );
      question.addParagraph(
        "solution",
        `$${abase ** apower}^\\frac{1}{${apower}} = \\sqrt[${apower}]{${
          abase ** apower
        }}$`
      );
      question.addParagraph(
        "solution",
        `$\\sqrt[${apower}]{${abase ** apower}} = ${a_answer}$`
      );
      question.addHeading(
        "solution",
        `b) State the value of $${bbase ** bpower}^\\frac{1}{${bpower}}$:`
      );
      question.addParagraph(
        "solution",
        `$${bbase ** bpower}^\\frac{1}{${bpower}} = \\sqrt[${bpower}]{${
          bbase ** bpower
        }}$`
      );
      question.addParagraph(
        "solution",
        `$\\sqrt[${bpower}]{${bbase ** bpower}} = ${b_answer}$`
      );
      question.addHeading(
        "solution",
        `c) Hence, find the value of $${abase ** apower}^\\frac{1}{${apower}}-${
          bbase ** bpower
        }^\\frac{1}{${bpower}}$:`
      );
      question.addParagraph(
        "solution",
        `$${abase ** apower}^\\frac{1}{${apower}}-${
          bbase ** bpower
        }^\\frac{1}{${bpower}} = ${a_answer} - ${b_answer}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore ${abase ** apower}^\\frac{1}{${apower}}-${
          bbase ** bpower
        }^\\frac{1}{${bpower}} = ${c_answer}$`
      );
      const group = new MCQuestion.InputGroup(1);
      group.addInput(a_answer, 0, "a)");
      group.addInput(b_answer, 0, "b)");
      group.addInput(c_answer, 0, "c)");
      question.addInputGroup(group);
      return question.makeQuestion(); // complete
    }
  }
}


export function AS_AlgebraAndFunctions_LawsOfIndices_Exam_Q3() {
  while (true) {
    const abase = MCRandom.randomInt(2, 5);
    const bbase = MCRandom.randomInt(2, 5);
    const apower = MCRandom.randomInt(2, 4);
    const bpower = MCRandom.randomInt(2, 4);
    const f1 = new MCMaths.Fraction(1 / abase);
    const f2 = new MCMaths.Fraction(1 / bbase);
    const a_answer = bbase ** bpower;
    const b_answer = abase ** apower;

    if (
      true &&
      f1 !== f2 &&
      a_answer < b_answer &&
      abase % 2 !== 0 &&
      bbase % 2 === 0
    ) {
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `The value of $${f1}^${apower}\\div${f2}^${bpower}$ can be written in the form $\\frac{p}{q}$, with no common factors between $p$ and $q$.`
      );
      question.addParagraph("question", `State the values of $p$ and $q$.`);
      question.addParagraph(
        "solution",
        `$${f1}^${apower}= \\frac{1}{${b_answer}}$`
      );
      question.addParagraph(
        "solution",
        `$${f2}^${bpower}= \\frac{1}{${a_answer}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore ${f1}^${apower}\\div${f2}^${bpower}=\\frac{1}{${b_answer}} \\div \\frac{1}{${a_answer}}$`
      );
      question.addParagraph("solution", `$= \\frac{${a_answer}}{${b_answer}}$`);
      question.addParagraph("solution", `$\\therefore p = ${a_answer}$`);
      question.addParagraph("solution", `$\\therefore q = ${b_answer}$`);

      const group = new MCQuestion.InputGroup(2);
      group.addInput(a_answer, 0, "$\\ p=$");
      group.addInput(b_answer, 0, "$\\ q=$");
      question.addInputGroup(group);

      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_LawsOfIndices_Exam_Q4() {
  while (true) {
    const abase = MCRandom.randomInt(2, 5);
    const bbase = MCRandom.randomInt(2, 5);
    const apower = MCRandom.randomInt(2, 4);
    const bpower = MCRandom.randomInt(2, 4);
    const b_answer = abase ** (3 * apower - 2 * bpower);

    if (
      true &&
      apower !== bpower &&
      abase !== bbase &&
      b_answer !== 1 &&
      (abase * abase * abase) ** apower > (abase * abase) ** bpower &&
      3 * apower - 2 * bpower !== 1
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) State the value of $${abase}^{${3 * apower - 2 * bpower}}$.`
      );
      question.addParagraph(
        "question",
        `b) Hence, find the value of $${abase * abase * abase}^${apower}\\div${
          abase * abase
        }^${bpower}$.`
      );
      question.addHeading(
        "solution",
        `a) State the value of $${abase}^{${3 * apower - 2 * bpower}}$:`
      );
      question.addParagraph(
        "solution",
        `$${abase}^${3 * apower - 2 * bpower}= ${b_answer}$`
      );
      question.addHeading(
        "solution",
        `b) Hence, find the value of $${abase * abase * abase}^${apower}\\div${
          abase * abase
        }^${bpower}$:`
      );
      question.addParagraph(
        "solution",
        `$${abase * abase * abase}^${apower}=${abase}^{${3 * apower}}$`
      );
      question.addParagraph(
        "solution",
        `$${abase * abase}^${bpower}=${abase}^{${2 * bpower}}$`
      );
      question.addParagraph(
        "solution",
        `$= \\frac{${abase}^{${3 * apower}}}{${abase}^{${2 * bpower}}}$`
      );
      question.addParagraph(
        "solution",
        `$= ${abase}^{${3 * apower - 2 * bpower}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore $ the value of $${abase * abase * abase}^${apower}\\div${
          abase * abase
        }^${bpower}$ is $${b_answer}$`
      );
      const group = new MCQuestion.InputGroup(1);
      group.addInput(b_answer, 0, "a)");
      group.addInput(b_answer, 0, "b)");
      question.addInputGroup(group);
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_LawsOfIndices_Exam_Q5() {
  while (true) {
    const abase = MCRandom.randomInt(2, 7);
    const multiplier = MCRandom.randomInt(2, 3);
    const a_answer = 2 * multiplier - 1;
    const b_answer = a_answer + 0.5;
    const apower = abase ** a_answer;

    if (true && abase !== 4 && abase !== 6) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) State the value of $${abase}^${a_answer}$.`
      );
      question.addParagraph(
        "question",
        `b) Given that the $${apower}$$\\sqrt{${abase}}$ can be written as $${abase}^n$, find the value of $n$.`
      );
      question.addHeading(
        "solution",
        `a) State the value of $${abase}^${a_answer}$`
      );
      question.addParagraph("solution", `$${abase}^${a_answer} = ${apower}$`);
      question.addHeading(
        "solution",
        `b) Given that the $${apower}$$\\sqrt{${abase}}$ can be written as $${abase}^n$, find the value of $n$:`
      );
      question.addParagraph(
        "solution",
        `From a), we know $${apower}=${abase}^${a_answer}$`
      );
      question.addParagraph("solution", `$\\sqrt{${abase}} = ${abase}^{0.5}$`);
      question.addParagraph(
        "solution",
        `$${apower}\\sqrt{${abase}} = ${abase}^${a_answer}\\times${abase}^{0.5}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore ${apower}\\sqrt{${abase}} = ${abase}^{${b_answer}}$`
      );
      // question.addAnswerBox(apower, 0, "a)");
      // question.addAnswerBox(b_answer, 0, "b)"); // complete

      const group = new MCQuestion.InputGroup(1);
      group.addInput(apower, 0, "a)");
      group.addInput(b_answer, 0, "b)");
      question.addInputGroup(group);

      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_LawsOfIndices_Exam_Q6() {
  while (true) {
    const base = MCRandom.randomInt(2, 5);
    const a_answer = MCRandom.randomInt(2, 4);
    const b_answer = -a_answer;
    const c_answer = MCRandom.randomInt(2, 4);
    const f1 = new MCMaths.Fraction(base ** -a_answer);

    if (true && base !== 4 && c_answer !== 3) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Express the following numbers in the form $${base}^n$:`
      );
      question.addParagraph("question", `a) $${base ** a_answer}$`);
      question.addParagraph("question", `b) $${f1.toString()}$`);
      question.addParagraph("question", `c) $\\sqrt[3]{${base ** c_answer}}$ `);
      question.addHeading("solution", `a) $${base ** a_answer}$`);
      question.addParagraph(
        "solution",
        `$${base ** a_answer} = ${base}^${a_answer}$`
      );
      question.addHeading("solution", `b) $${f1.toString()}$`);
      question.addParagraph(
        "solution",
        `From a), we know $${base ** a_answer} = ${base}^${a_answer}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore ${f1.toString()} = ${base}^{${b_answer}}$`
      );
      question.addHeading("solution", `c) $\\sqrt[3]{${base ** c_answer}}$`);
      question.addParagraph(
        "solution",
        `$${base ** c_answer}=${base}^${c_answer}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sqrt[3]{${
          base ** c_answer
        }} = ${base}^{\\frac{${c_answer}}{3}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore n = \\frac{${c_answer}}{3}$`
      );
      question.addParagraph(
        "solution",
        `To 2dp, this is $${(c_answer / 3).toFixed(2)}$.`
      );
      // question.addAnswerBox(a_answer, 0, "a) n=");
      // question.addAnswerBox(b_answer, 0, "b) n=");
      // question.addAnswerBox(Number((c_answer / 3).toFixed(2)), 0, "c) n="); // complete

      const group = new MCQuestion.InputGroup(1);
      group.addInput(a_answer, 0, "a) $\\ n=$");
      group.addInput(b_answer, 0, "b) $\\ n=$");
      group.addInput(Number((c_answer / 3).toFixed(2)), 0, "c) $\\ n=$"); // complete
      question.addInputGroup(group);

      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_LawsOfIndices_Exam_Q7() {
  while (true) {
    const abase = MCRandom.randomInt(2, 5);
    const bbase = MCRandom.randomInt(2, 5);
    const cbase = MCRandom.randomInt(2, 5);
    const apower = MCRandom.randomInt(2, 5);
    const bpower = MCRandom.randomInt(2, 5) - 0.5;
    const cpower = MCRandom.randomInt(2, 5);
    const a_answer = (abase * bbase) / cbase;
    const b_answer = apower + bpower - cpower;
    if (Math.round(a_answer * 100) === a_answer * 100) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `$\\displaystyle \\frac{${abase}x^{${apower}}\\times${bbase}x^{${bpower}}}{${cbase}x^{${cpower}}}$ can be written in the form $px^q$.`
      );
      question.addParagraph("question", `Find $p$ and $q$.`);
      question.addHeading("solution", `Finding $p$:`);
      question.addParagraph(
        "solution",
        `$p = \\frac{${abase}\\times${bbase}}{${cbase}}$`
      );
      question.addParagraph("solution", `$\\therefore p= ${a_answer}$`);
      question.addHeading("solution", `Finding $q$:`);
      question.addParagraph(
        "solution",
        `$x^q = x^{${apower}}\\times x^{${bpower}}\\div x^{${cpower}}$`
      );
      question.addParagraph("solution", `$q = ${apower}+${bpower}-${cpower}$`);
      question.addParagraph("solution", `$\\therefore q = ${b_answer}$`);
      // question.addAnswerBox(a_answer, 0, "a) A=");
      // question.addAnswerBox(b_answer, 0, "a) B="); // complete

      const group = new MCQuestion.InputGroup(2);
      group.addInput(a_answer, 0, "$p=$");
      group.addInput(b_answer, 0, "$q=$");
      question.addInputGroup(group);

      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_LawsOfIndices_Exam_Q8() {
  while (true) {
    const root = MCRandom.randomInt(2, 4);
    const base = MCRandom.randomInt(2, 5);
    const pow1 = MCRandom.randomInt(2, 3);
    const pow2 = MCRandom.randomInt(1, 3);
    const f1 = new MCMaths.Fraction(1 / base ** pow1);
    const a_answer = pow2 / root;
    const b_answer = -pow1;
    const c_answer = a_answer + pow1;
    if (true && base !== 4 && root !== 3 && pow2 !== root) {
      const question = new MCQuestion.ExamQuestion();
      if (root === 2) {
        question.addParagraph(
          "question",
          `a) Write down the value of $p$ given that $\\sqrt{${
            base ** pow2
          }} = ${base}^p$.`
        );
        question.addHeading(
          "solution",
          `a) Write down the value of $p$ given that $\\sqrt{${
            base ** pow2
          }} = ${base}^p$:`
        );
        question.addParagraph(
          "solution",
          `$\\sqrt{${base ** pow2}} = ${base}^{${a_answer}}$`
        );
      } else {
        question.addParagraph(
          "question",
          `a) Write down the value of $p$ given that $\\sqrt[${root}]{${
            base ** pow2
          }} = ${base}^p$.`
        );
        question.addHeading(
          "solution",
          `a) Write down the value of $p$ given that $\\sqrt[${root}]{${
            base ** pow2
          }} = ${base}^p$:`
        );
        question.addParagraph(
          "solution",
          `$\\sqrt[${root}]{${base ** pow2}} = ${base}^{${a_answer}}$`
        );
      }
      question.addParagraph("solution", `$\\therefore p = ${a_answer}$`);
      question.addParagraph(
        "question",
        ` b) Write down the value $q$ given that $${f1.toString()}=${base}^q$.`
      );
      question.addHeading(
        "solution",
        ` b) Write down the value $q$ given that $${f1.toString()}=${base}^q$:`
      );
      question.addParagraph(
        "solution",
        `$${f1.toString()}= \\frac{1}{${base}^{${pow1}}}$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{1}{${base}^{${pow1}}} = ${base}^{${-pow1}}$`
      );
      question.addParagraph("solution", `$\\therefore q = ${b_answer}$`);
      if (root === 2) {
        question.addParagraph(
          "question",
          ` c) Hence, find the value for $x$ which $${base}^x \\times${f1.toString()}=\\sqrt{${
            base ** pow2
          }}$.`
        );
        question.addHeading(
          "solution",
          ` c) Hence, find the value for $x$ which $${base}^x \\times${f1.toString()}=\\sqrt{${
            base ** pow2
          }}$:`
        );
      } else {
        question.addParagraph(
          "question",
          ` c) Hence, find the value for $x$ which $${base}^x \\times${f1.toString()}=\\sqrt[${root}]{${
            base ** pow2
          }}$.`
        );
        question.addHeading(
          "solution",
          ` c) Hence, find the value for $x$ which $${base}^x \\times${f1.toString()}=\\sqrt[${root}]{${
            base ** pow2
          }}$:`
        );
      }
      question.addParagraph(
        "solution",
        `$${base}^{x} \\times ${base}^{${b_answer}} = ${base}^{${a_answer}}$`
      );
      question.addParagraph(
        "solution",
        `$${base}^{x} = \\frac{${base}^{${a_answer}}}{${base}^{${b_answer}}}$`
      );
      question.addParagraph("solution", `$x = ${a_answer}+${-b_answer}$`);
      question.addParagraph("solution", `$\\therefore x = ${c_answer}$`);

      const group = new MCQuestion.InputGroup(1);
      group.addInput(a_answer, 0, "a) $\\ p=$");
      group.addInput(b_answer, 0, "b) $\\ q=$");
      group.addInput(c_answer, 0, "c) $\\ x=$");
      question.addInputGroup(group);

      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_LawsOfIndices_Exam_Q9() {
  while (true) {
    const base = MCRandom.randomInt(2, 4);
    const pow1 = MCRandom.randomInt(3, 5);
    const pow2 = -pow1;
    const a_answer = 1;
    const b_answer = base ** pow1;
    const c_answer = b_answer + 1;
    if (true && pow1 !== 4) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `a) State the value of $${base}^{0}$.`);
      question.addParagraph(
        "question",
        `b) State the value of $${base}^{${pow1}}$.`
      );
      question.addParagraph(
        "question",
        `c) Hence, find the value of $\\displaystyle ${
          base ** 2
        }^{-\\frac{${-pow2}}{2}}$ in the form $\\displaystyle\\frac{p}{q}$, stating the values of $p+q$.`
      );
      question.addHeading("solution", `a) State the value of $${base}^{0}$:`);
      question.addParagraph("solution", `$${base}^{0} = 1$`);
      question.addHeading(
        "solution",
        `b) State the value of $${base}^{${pow1}}$:`
      );
      question.addParagraph("solution", `$${base}^{${pow1}} = ${b_answer}$`);
      question.addHeading(
        "solution",
        `c) Hence, find the value of $\\displaystyle${
          base ** 2
        }^{-\\frac{${-pow2}}{2}}$ in the form $\\displaystyle\\frac{p}{q}$, stating the values of $p + q$:`
      );
      question.addParagraph(
        "solution",
        `$${base ** 2}^{\\frac{${pow2}}{2}} = (\\sqrt{${base ** 2}})^{${pow2}}$`
      );
      question.addParagraph(
        "solution",
        `$(\\sqrt{${base ** 2}})^{${pow2}} = \\frac{1}{${base}^{${-pow2}}}$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{1}{${base}^{${-pow2}}} = \\frac{1}{${c_answer}}$`
      );
      question.addParagraph("solution", `$p = 1$`);
      question.addParagraph("solution", `$q = ${b_answer}$`);
      question.addParagraph("solution", `$\\therefore p + q = ${c_answer}$`);

      const group = new MCQuestion.InputGroup(1);
      group.addInput(a_answer, 0, "a)");
      group.addInput(b_answer, 0, "b)");
      group.addInput(c_answer, 0, "c)");
      question.addInputGroup(group);
      // complete

      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_LawsOfIndices_Skills_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const questionTypes = [
      `Write $X^I \\times X^J$ in the form $X^p$`,
      `Write $\\frac{X^I}{X^J}$ in the form $X^p$`,
      `Write $\\left(X^I\\right)^J$ in the form $X^p$`,
    ];
    const answers = [`I+J`, `I-J`, `IJ`];
    const solutionTypes = [
      [`$=X^{i + j}$`, `$=X^{i+j}$`],
      [`$=X^{i - j}$`, `$=X^{i-j}$`],
      [`$=X^{i\\times j}$`, `$=X^{ij}$`],
    ];
    const alphabet = [`a`, `b`, `c`, `d`];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.SkillsQuestion(1);
      const parts = [
        new MCQuestion.SkillsPart(),
        new MCQuestion.SkillsPart(),
        new MCQuestion.SkillsPart(),
        new MCQuestion.SkillsPart(),
      ];
      for (let p = 0; p <= 3; p += 1) {
        const type = MCRandom.randomInt(0, questionTypes.length - 1);
        const i = MCRandom.randomInt(1, 5);
        const j = MCRandom.randomInt(1, 5);
        const k = MCRandom.randomInt(1, 5);
        const l = MCRandom.randomInt(1, 5);
        parts[p].addParagraph(
          "question",
          `${MCMaths.cleaner(
            questionTypes[type]
              .replace(/X/g, alphabet[p])
              .replace(/I/, i)
              .replace(/J/, j)
          )}`
        );
        parts[p].addParagraph(
          "solution",
          `${MCMaths.cleaner(
            solutionTypes[type][0]
              .replace(/X/g, alphabet[p])
              .replace(/i\+j/, i + j)
              .replace(/i-j/, i - j)
              .replace(/ij/, i * j)
              .replace(/i/, i)
              .replace(/j/, j)
          )}`
        );
        parts[p].addAnswer(
          Number(
            answers[type]
              .replace("I+J", i + j)
              .replace("I-J", i - j)
              .replace("IJ", i * j)
          ),
          0,
          `${alphabet[p]}) $\\ p = $ `
        );
        parts[p].addParagraph(
          "solution",
          ` ${MCMaths.cleaner(
            solutionTypes[type][1]
              .replace(/X/g, alphabet[p])
              .replace(/i\+j/, i + j)
              .replace(/i-j/, i - j)
              .replace(/ij/, i * j)
              .replace(/i/, i)
              .replace(/j/, j)
          )}`
        );
      }
      question.addPart(parts[0]);
      question.addPart(parts[1]);
      question.addPart(parts[2]);
      question.addPart(parts[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Polynomials_Exam_Q1() {
  while (true) {
    const a = MCRandom.randomInt(-8, 8);
    const b = MCRandom.randomInt(-8, 8);
    const p1 = MCMaths.Polynomial.generateFromRoots([a, b]);
    const p2 = new MCMaths.Polynomial([1, -a]);
    const p3 = new MCMaths.Polynomial([1, -b]);
    const A = (a + b) * -1;
    const B = a * b;

    if (a !== 0 && b !== 0) {
      const question = new MCQuestion.ExamQuestion();
      if (a === b) {
        question.addParagraph(
          "question",
          `a) Write down the roots of $f(x) = (${p2})^{2}$.`
        );
      } else {
        question.addParagraph(
          "question",
          `a) Write down the roots of $f(x) = (${p2})(${p3})$.`
        );
      }

      question.addHeading("solution", `a) Using the factor theorem:`);

      if (a === b) {
        question.addParagraph("solution", `$x = ${a}$`);
      } else {
        question.addParagraph("solution", `$x = ${a}, ${b}$`);
      }

      // new input group
      const group1 = new MCQuestion.InputGroup(a === b ? 1 : 2);
      if (a === b) {
        group1.addInput(a, 0, "a) $\\ x = $");
      } else {
        group1.addInput([a, b], 0, "a) $\\ x = $");
      }

      question.addParagraph(
        "question",
        `b) Express $f(x)$ in the form $x^{2} + Ax + B$.`
      );

      question.addHeading("solution", `b) Expanding the brackets gives:`);

      if (a === b) {
        question.addMultipleParagraphs("solution", [
          `$f(x) = (${p2})^{2}$`,
          `$\\ = ${p1}$`,
          `$\\therefore A = ${A}, B = ${B}$`,
        ]);
      } else {
        question.addMultipleParagraphs("solution", [
          `$f(x) = (${p2})(${p3})$`,
          `$\\ = ${p1}$`,
          `$\\therefore A = ${A}, B = ${B}$`,
        ]);
      }

      // question.addAnswerBox(A, 0, "A");
      // question.addAnswerBox(B, 0, "B");
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(A, 0, "b) $\\ A=$");
      group2.addInput(B, 0, "b) $\\ B=$");

      // add the input group to the question
      question.addInputGroup(group1);
      question.addInputGroup(group2);

      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_Polynomials_Exam_Q10() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(-5, 5);
    const b = MCRandom.randomInt(-5, 5);
    const c = MCRandom.randomInt(-5, 5);
    const mainPoly = MCMaths.Polynomial.generateFromRoots([a, b, c]);
    const p1 = new MCMaths.Polynomial([1, -a]);
    const p2 = new MCMaths.Polynomial([1, -b]);
    const p3 = new MCMaths.Polynomial([1, -c]);
    const intermediatePoly = p2.multiply(p3);
    const quadraticPoly = p1.multiply(p3);
    const rhs = MCRandom.randomInt(1, 4);
    // const multiple = MCRandom.randomInt(2, 4);
    const fraction1 = `$\\displaystyle\\frac{${mainPoly}}{${quadraticPoly}}$`;
    const fraction2 = `$\\displaystyle\\frac{(${p1})(${p2})(${p3})}{(${p1})(${p3})}$`;
    const bAnswer = rhs + b;
    /* END VARIABLES AND CONSTANTS */
    if (a !== 0 && b !== 0 && c !== 0 && b !== c && a !== b && a !== c) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `a) Find the roots the polynomial $f(x) = ${mainPoly}$, given that $(${p1})$ is a factor.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) $f(x) = ${mainPoly}$`,
        ` $ = (${p1})(${intermediatePoly})$`,
        ` $ = (${p1})(${p2})(${p3})$`,
      ]);

      question.addHeading("solution", `Using the Factor Theorem:`);

      question.addParagraph("solution", ` $ x = ${a}, ${b}, ${c}$`);

      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput([a, b, c], 0, "a) $\\ x = $");

      question.addMultipleParagraphs("question", [
        `b) Solve ${fraction1} $ = ${rhs}$`,
      ]);

      question.addHeading("solution", `b) Using part a):`);

      question.addMultipleParagraphs("solution", [
        `${fraction2} $ = ${rhs}$`,
        `$${p2} = ${rhs}$`,
        `$\\therefore x = ${bAnswer}$`,
      ]);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(bAnswer, 0, "b) $\\ x = $");

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_Polynomials_Exam_Q2() {
  while (true) {
    const a = MCRandom.randomInt(-8, 8);
    const b = MCRandom.randomInt(-8, 8);
    const c = MCRandom.randomInt(-8, 8);
    const p1 = MCMaths.Polynomial.generateFromRoots([a, b, c]);
    const p2 = new MCMaths.Polynomial([1, -a]);
    const p3 = new MCMaths.Polynomial([1, -b]);
    const p4 = new MCMaths.Polynomial([1, -c]);
    const A = (a + b + c) * -1;
    const B = a * b + b * c + a * c;
    const C = -1 * a * b * c;

    if (a !== 0 && b !== 0 && c !== 0 && a !== b && a !== c && c !== b) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Write down the roots of $f(x) = (${p2})(${p3})(${p4})$.`
      );

      question.addHeading("solution", `a) Using the factor theorem:`);

      question.addParagraph("solution", `$x = ${a}, ${b}, ${c}$`);

      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput([a, b, c], 0, "a) $\\ x = $");

      question.addParagraph(
        "question",
        `b) Express $f(x)$ in the form $x^{3} + Ax^{2} + Bx + C$.`
      );

      question.addHeading("solution", `b) Expanding Brackets:`);

      question.addParagraph(
        "solution",
        `$f(x) = (${p2})(${p3})(${p4}) = ${p1}$`
      );

      const group2 = new MCQuestion.InputGroup(3);
      group2.addInput(A, 0, "b) $\\ A = $");
      group2.addInput(B, 0, "b) $\\ B = $");
      group2.addInput(C, 0, "b) $\\ C = $");

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_Polynomials_Exam_Q3() {
  while (true) {
    const a = MCRandom.randomInt(1, 2);
    const b = MCRandom.randomInt(-5, 5);
    const c = MCRandom.randomInt(1, 2);
    const d = MCRandom.randomInt(-5, 5);
    const e = MCRandom.randomInt(1, 2);
    const f = MCRandom.randomInt(-5, 5);
    const A = a * c * e;
    const B = a * c * f + b * c * e + a * d * e;
    const C = b * c * f + b * d * e + a * d * f;
    const D = b * d * f;
    const p1 = new MCMaths.Polynomial([a, b]);
    const p2 = new MCMaths.Polynomial([c, d]);
    const p3 = new MCMaths.Polynomial([e, f]);
    const mainPolynomial = new MCMaths.Polynomial([A, B, C, D]);
    const intermediatePolynomial = p1.multiply(p2);

    if (
      a !== 0 &&
      b !== 0 &&
      c !== 0 &&
      d !== 0 &&
      e !== 0 &&
      f !== 0 &&
      A !== 1
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given that:`,
        `$(${p1})(${p2})(${p3}) \\equiv Ax^{3} + Bx^{2} + Cx + D$`,
        "Find the value of the constants $A, B, C$ and $D$.",
      ]);

      question.addHeading("solution", "Expand Brackets:");

      question.addMultipleParagraphs("solution", [
        `$(${p1})(${p2})(${p3}) = (${intermediatePolynomial})(${p3})$`,
        `$= ${mainPolynomial}$`,
      ]);

      question.addHeading("solution", "Comparing Coefficients:");

      question.addParagraph(
        "solution",
        `$A = ${A}, B = ${B}, C = ${C}, D = ${D}$`
      );

      const group = new MCQuestion.InputGroup(2);
      group.addInput(A, 0, "$A = $");
      group.addInput(B, 0, "$B = $");
      group.addInput(C, 0, "$C = $");
      group.addInput(D, 0, "$D = $");

      question.addInputGroup(group);

      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_Polynomials_Exam_Q4() {
  while (true) {
    const a = MCRandom.randomInt(1, 2);
    const b = MCRandom.randomInt(-5, 5);
    const c = MCRandom.randomInt(1, 2);
    const d = MCRandom.randomInt(-5, 5);
    const e = MCRandom.randomInt(1, 2);
    const f = MCRandom.randomInt(-5, 5);
    const g = MCRandom.randomInt(1, 2);
    const h = MCRandom.randomInt(-5, 5);
    const p1 = new MCMaths.Polynomial([a, b]);
    const p2 = new MCMaths.Polynomial([c, d]);
    const p3 = new MCMaths.Polynomial([e, f]);
    const p4 = new MCMaths.Polynomial([g, h]);
    const intermediatePolynomial1 = p1.multiply(p2);
    const intermediatePolynomial2 = intermediatePolynomial1.multiply(p3);
    const mainPolynomial = intermediatePolynomial2.multiply(p4);
    const coefficients = mainPolynomial.getCoefficients();
    const A = coefficients[0];
    const B = coefficients[1];
    const C = coefficients[2];
    const D = coefficients[3];
    const E = coefficients[4];

    if (
      a !== 0 &&
      b !== 0 &&
      c !== 0 &&
      d !== 0 &&
      e !== 0 &&
      f !== 0 &&
      g !== 0 &&
      h !== 0 &&
      A <= 4
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given that:`,
        `$(${p1})(${p2})(${p3})(${p4}) \\equiv Ax^{4} + Bx^{3} + Cx^{2} + Dx + E$`,
        "Find the value of the constants $A, B, C, D$ and $E$.",
      ]);

      question.addHeading("solution", "Expanding Brackets:");

      question.addMultipleParagraphs("solution", [
        `$(${p1})(${p2})(${p3})(${p4}) = (${intermediatePolynomial1})(${p3})(${p4})$`,
        `$= (${intermediatePolynomial2})(${p4})$`,
        `$= ${mainPolynomial}$`,
      ]);

      question.addHeading("solution", "Comparing Coefficients:");

      question.addParagraph(
        "solution",
        `$A = ${A}, B = ${B}, C = ${C}, D = ${D}, E = ${E}$`
      );

      const group = new MCQuestion.InputGroup(3);
      group.addInput(A, 0, "$A = $");
      group.addInput(B, 0, "$B = $");
      group.addInput(C, 0, "$C = $");
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(D, 0, "$D = $");
      group2.addInput(E, 0, "$E = $");
      question.addInputGroup(group);

      question.addInputGroup(group2);

      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_Polynomials_Exam_Q5() {
  while (true) {
    const a = MCRandom.randomInt(-6, 6);
    const b = MCRandom.randomInt(-6, 6);
    const e = MCRandom.randomInt(-6, 6);
    const f = MCRandom.randomInt(-6, 6);
    const polynomial1 = MCMaths.Polynomial.generateFromRoots([a, b]);
    const p1 = new MCMaths.Polynomial([1, -a]);
    const p2 = new MCMaths.Polynomial([1, -b]);
    const polynomial2 = MCMaths.Polynomial.generateFromRoots([e, f]);
    const line = polynomial1.subtract(polynomial2);
    const lineString = MCMaths.cleaner(line.toString());
    const p3 = new MCMaths.Polynomial([1, -e]);
    const p4 = new MCMaths.Polynomial([1, -f]);
    const workingArray1 = line.evaluateWorking(e);
    const workingArray2 = line.evaluateWorking(f);
    const y1 = line.evaluate(e);
    const y2 = line.evaluate(f);

    if (a !== 0 && b !== 0 && a < b && e <= f && y1 !== y2) {
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `a) Find the points at which the curve $y = ${polynomial1}$ crosses the $x$ axis.`,
      ]);

      question.addHeading("solution", `a) Factorising:`);

      question.addParagraph("solution", `$${polynomial1} = (${p1})(${p2})$`);

      question.addHeading("solution", `Using Factor Theorem:`);

      question.addParagraph("solution", `$x = ${a}, ${b}$`);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput([a, b], 0, "a) $\\ x = $");

      question.addMultipleParagraphs("question", [
        `b) Find the points $y = ${polynomial1}$ intersects the line $y = ${lineString}$.`,
      ]);

      question.addHeading("solution", `b) Forming Equation:`);

      question.addMultipleParagraphs("solution", [
        `$${polynomial1} = ${line}$`,
        `So, $${polynomial2} = 0$`,
        `$\\therefore (${p3})(${p4}) = 0$`,
        `$\\therefore x = ${polynomial2.roots()}$`,
      ]);

      question.addHeading("solution", `Substituting to find $y-$coordinates:`);

      question.addMultipleParagraphs("solution", [
        `${workingArray1[1].replace("f", "y")}$`,
        `$= ${y1}$`,
        `${workingArray2[1].replace("f", "y")}$`,
        `$= ${y2}$`,
      ]);

      question.addHeading("solution", `Points of Intersection:`);

      question.addParagraph("solution", `$(${e}, ${y1})$ and $(${f}, ${y2})$`);

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput([e, f], 0, "b) $\\ x = $");
      group2.addInput([y1, y2], 0, "b) $\\ y = $");

      question.addInputGroup(group1);
      question.addInputGroup(group2);

      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_Polynomials_Exam_Q6() {
  while (true) {
    const a = MCRandom.randomInt(-4, 4);
    const b = MCRandom.randomInt(-4, 4);
    const c = MCRandom.randomInt(-4, 4);
    const d = MCRandom.randomInt(-4, 4);
    const polynomial = MCMaths.Polynomial.generateFromRoots([a, b, c, d]);
    const p1 = new MCMaths.Polynomial([1, -a]);
    const p2 = new MCMaths.Polynomial([1, -b]);
    const p3 = new MCMaths.Polynomial([1, -c]);
    const p4 = new MCMaths.Polynomial([1, -d]);
    const coefficients = polynomial.getCoefficients();
    const A = coefficients[1];
    const B = coefficients[2];
    const C = coefficients[3];
    const D = coefficients[4];

    if (
      a !== 0 &&
      b !== 0 &&
      c !== 0 &&
      d !== 0 &&
      a !== b &&
      a !== c &&
      c !== b &&
      a !== d &&
      d !== c &&
      d !== b
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Write down the roots of $f(x) = (${p1})(${p2})(${p3})(${p4})$.`
      );

      question.addHeading("solution", `a) Using Factor Theorem:`);

      question.addParagraph("solution", `$x = ${a}, ${b}, ${c}, ${d}$`);

      const group1 = new MCQuestion.InputGroup(4);
      group1.addInput([a, b, c, d], 0, "a) $\\ x = $");

      question.addParagraph(
        "question",
        `b) Express $f(x)$ in the form $x^{4} + Ax^{3} + Bx^{2} + Cx + D$.`
      );

      question.addHeading("solution", `b) Expanding Brackets:`);

      question.addMultipleParagraphs("solution", [
        `$f(x) = (${p1})(${p2})(${p3})(${p4})$`,
        `$ = ${polynomial}$`,
      ]);

      const group2 = new MCQuestion.InputGroup(4);
      group2.addInput(A, 0, "b) $\\ A = $");
      group2.addInput(B, 0, "b) $\\ B = $");
      group2.addInput(C, 0, "b) $\\ C = $");
      group2.addInput(D, 0, "b) $\\ D = $");

      question.addInputGroup(group1);
      question.addInputGroup(group2);

      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_Polynomials_Exam_Q7() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(-5, 5);
    const b = MCRandom.randomInt(-5, 5);
    const c = MCRandom.randomInt(-5, 5);
    const d = MCRandom.randomInt(-5, 5);
    const e = MCRandom.randomInt(-5, 5);
    const cubicPoly1 = MCMaths.Polynomial.generateFromRoots([a, b, c]);
    const quadraticPoly = MCMaths.Polynomial.generateFromRoots([d, e]);
    const p1 = new MCMaths.Polynomial([1, -a]);
    const p2 = new MCMaths.Polynomial([1, -b]);
    const p3 = new MCMaths.Polynomial([1, -c]);
    const p4 = new MCMaths.Polynomial([1, -d]);
    const p5 = new MCMaths.Polynomial([1, -e]);
    const intermediatePoly = p2.multiply(p3);
    const cubicPoly2 = cubicPoly1.subtract(quadraticPoly);
    const working1 = cubicPoly1.evaluateWorking(d);
    const working2 = cubicPoly1.evaluateWorking(e);
    const y1 = cubicPoly1.evaluate(d);
    const y2 = cubicPoly1.evaluate(e);
    const quadraticPoly2 = MCMaths.Polynomial.generateFromRoots([d, e]);
    /* END VARIABLES AND CONSTANTS */
    if (
      a !== 0 &&
      b !== 0 &&
      c !== 0 &&
      a !== b &&
      a !== c &&
      c !== b &&
      d < e
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$f(x) = ${cubicPoly1}$`,
        `a) Given that $f(${a}) = 0$, find all of the points where $y = f(x)$ crosses the $x-$axis.`,
      ]);

      question.addHeading("solution", `a) Factoring $(${p1})$:`);

      question.addMultipleParagraphs("solution", [
        `$\\therefore$ $f(x) = (${p1})(${intermediatePoly})$`,
        `$ = (${p1})(${p2})(${p3})$`,
      ]);

      question.addHeading("solution", `Other roots:`);

      question.addParagraph("solution", `$x = ${b}, ${c}$`);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput([a, b], 0, "a) $\\ x = $");

      question.addMultipleParagraphs("question", [
        `b) Now find the points at which $y = f(x)$ intersects the curve $y = ${cubicPoly2}$.`,
      ]);

      question.addHeading("solution", `b) Forming Equation:`);

      question.addMultipleParagraphs("solution", [
        `$${cubicPoly1} = ${cubicPoly2}$`,
        `$${quadraticPoly2.toString()} = 0$`,
        `$\\therefore (${p4})(${p5}) = 0$`,
        `$\\therefore x = ${d}, ${e}$`,
      ]);

      question.addHeading("solution", `Substituting :`);

      question.addMultipleParagraphs("solution", [
        `${working1[1].replace("f", "y")}$`,
        `$= ${y1}$`,
        `${working2[1].replace("f", "y")}$`,
        `$= ${y2}$`,
      ]);

      question.addHeading("solution", `Points of Intersection:`);

      question.addParagraph("solution", `$(${d}, ${y1})$ and $(${e}, ${y2})$`);

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput([d, e], 0, "b) $\\ x = $");
      group2.addInput([y1, y2], 0, "b) $\\ y = $");

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_Polynomials_Exam_Q8() {
  while (true) {
    const a = MCRandom.randomInt(-5, 5);
    const b = MCRandom.randomInt(-5, 5);
    const c = MCRandom.randomInt(-5, 5);
    const mainPoly = MCMaths.Polynomial.generateFromRoots([a, a, b, c]);
    const p1 = new MCMaths.Polynomial([1, -a]);
    const p2 = new MCMaths.Polynomial([1, -b]);
    const p3 = new MCMaths.Polynomial([1, -c]);
    const intermediatePoly2 = p2.multiply(p3);
    const intermediatePoly1 = intermediatePoly2.multiply(p1);

    if (b !== c && a !== b && a !== c && a !== 0 && b !== 0 && c !== 0) {
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$f(x) = ${mainPoly}$`,
        `Given that $f(x)$ touches the $x-$axis at $x = ${a}$, find the remaining points where $y = f(x)$ crosses the $x-$axis.`,
      ]);

      question.addHeading(
        "solution",
        `$f(x)$ touches $x-$axis at $x = ${a}$ $\\Rightarrow$ $(${p1})^2$ is a factor of $f(x)$:`
      );

      question.addMultipleParagraphs("solution", [
        `$f(x) = (${p1})(${intermediatePoly1})$`,
        `$= (${p1})^{2}(${intermediatePoly2})$`,
        `$ = (${p1})^{2}(${p2})(${p3})$`,
      ]);

      question.addHeading("solution", `Using Factor Theorem:`);

      question.addParagraph("solution", `$x = ${b}, ${c}$`);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput([b, c], 0, "$\\ x = $");

      question.addInputGroup(group1);

      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_Polynomials_Exam_Q9() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(-5, -1);
    const b = MCRandom.randomInt(-5, -1);
    // const multiple = MCRandom.randomInt(2, 4);
    const rhs = MCRandom.randomInt(1, 4);
    const mainPoly = MCMaths.Polynomial.generateFromRoots([a, b]);
    const p1 = new MCMaths.Polynomial([1, -a]);
    const p2 = new MCMaths.Polynomial([1, -b]);
    const polynomials = MCRandom.shuffle([p1, p2]);
    const fraction1 = `$\\displaystyle \\frac{${mainPoly}}{${polynomials[0]}}$`;
    const fraction2 = `$\\displaystyle \\frac{(${p1})(${p2})}{${polynomials[0]}}$`;
    const bAnswer = rhs - polynomials[1].toString()[2];
    /* END VARIABLES AND CONSTANTS */
    if (a !== 0 && b !== 0 && a !== b) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `By factorising $\\displaystyle f(x) = ${mainPoly}$, solve ${fraction1} $ = ${rhs}$.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `$f(x) = ${mainPoly}$`,
        ` $ = (${p1})(${p2})$`,
      ]);

      question.addMultipleParagraphs("solution", [
        `${fraction2} $ = ${rhs}$`,
        `$${polynomials[1]} = ${rhs}$`,
        `$x = ${bAnswer}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(bAnswer, 0, "$\\ x = $");

      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p = MCRandom.randomInt(-8, 8);
    const q = MCRandom.randomInt(-20, 20);

    const y = new MCMaths.Polynomial([1, 2 * p, p ** 2 + q], "x");
    const roots = y.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      p !== 0 &&
      q !== 0 &&
      roots[0] !== "No Real Roots" &&
      roots[0] !== roots[1] &&
      roots[0] % (1 / 8) === 0 &&
      roots[1] % (1 / 8) === 0 &&
      p ** 2 !== -q
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        `A curve $C$ has the equation $y=${y.toString()}$.`
      );
      question.addParagraph(
        "question",
        `Given that $y$ can be writen in the form $(x+p)^2+q$:`
      );
      question.addParagraph("question", `a) Find $p$ and $q$.`);
      question.addParagraph(
        "question",
        `b) Hence, find the minimum point of the curve $C$.`
      );
      question.addParagraph(
        "question",
        `c) Find the $x$ coordinates of the points where $C$ meets the x-axis.`
      );
      // solution
      question.addHeading(
        "solution",
        `a) ${MCMaths.cleaner(`$y=(x^2+${2 * p}x)+${p ** 2 + q}$`)}`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\left[\\left(x+\\frac{${p * 2}}{2}\\right)^2-${p ** 2}\\right]+${
            p ** 2 + q
          }$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=(x+${p})^2+${q}$`));
      question.addParagraph("solution", `$\\therefore p=${p}$ and $q=${q}$`);

      question.addHeading(
        "solution",
        `b) Using part a), minimun point is $(${-p},${q})$.`
      );

      question.addHeading(
        "solution",
        `c) Meets the x-axis $\\Rightarrow$ $y = 0$.`
      );
      question.addParagraph("solution", `$${y.toString()}=0$`);
      question.addHeading("solution", `Using quadratic formula:`);
      question.addHeading(
        "solution",
        `$x=\\frac{${-2 * p}\\pm\\sqrt{(${2 * p})^2-4(1)(${
          p ** 2 + q
        })}}{2(1)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore x=${roots[0]}, x=${roots[1]}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(p, 0, "a) $\\ p = $");
      group1.addInput(q, 0, "a) $\\ q = $");
      group1.addInput(-p, 0, "b) $\\ x = $");
      group1.addInput(q, 0, "b) $\\ y = $");
      group1.addInput(roots, 0, "c) $\\ x = $");
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_Exam_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x2 = MCRandom.randomInt(2, 4);
    const x = MCRandom.randomInt(-5, 5);
    const c = MCRandom.randomInt(-8, -2);

    const poly = new MCMaths.Polynomial([x2, x, c], "x");
    const roots = poly.roots();

    const showPoly = new MCMaths.Polynomial([x2, 0, x, 0, c], "x");
    /* END VARIABLES AND CONSTANTS */
    if (
      x !== 0 &&
      c !== 0 &&
      roots[0] !== "No Real Roots" &&
      roots[0] % (1 / 8) === 0 &&
      roots[1] % (1 / 8) === 0 &&
      roots[0] > 0 &&
      roots[1] < 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addGraph("question", showPoly.graph());
      question.addParagraph(
        "question",
        `The curve above has equation ${MCMaths.cleaner(
          `$y=${x2}x^4+${x}x^2+${c}$`
        )}, and crosses the x-axis at points $x=\\pm \\sqrt{t}$.`
      );
      question.addParagraph(
        "question",
        `By using the substitution $x=\\sqrt{t}$, find $t$.`
      );
      // solution
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `$y=${x2}\\left(\\sqrt{t}\\right)^4+${x}\\left(\\sqrt{t}\\right)^2+${c}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=${x2}t^2+${x}t+${c}$`)
      );
      question.addHeading("solution", `Crosses x-axis at $y=0$:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${x2}t^2+${x}t+${c}=0$`)
      );
      question.addHeading("solution", `Using Quadratic Formula:`);
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle t=\\frac{${-x} \\pm \\sqrt{(${x})^2-4(${x2})(${c})}}{2(${x2})}$`
        )
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `$\\therefore t=${roots[0]}, t \\neq ${roots[1]}$ as $t>0$.`
        )
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(roots[0], 0, "$\\ t=$");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_Exam_Q11() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x2 = MCRandom.randomInt(-4, -1);
    const x = MCRandom.randomInt(-20, 20);
    const c = MCRandom.randomInt(-40, 40);

    const poly = new MCMaths.Polynomial([x2, x, c], "x");
    const roots = poly.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      x !== 0 &&
      c !== 0 &&
      roots[0] !== "No Real Roots" &&
      roots[0] % (1 / 8) === 0 &&
      roots[1] % (1 / 8) === 0 &&
      roots[0] > 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Solve $${c}\\sqrt{x}+${x}x^{${3 / 2}}+${x2}x^{${
            5 / 2
          }}=0$ for $x>0$.`
        )
      );
      // solution
      question.addHeading("solution", `Dividing by $\\sqrt{x}$ as $x>0$:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${-x2}x^{2}+${-x}x+${-c}=0$`)
      );
      question.addHeading("solution", `Using Quadratic Formula:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle x=\\frac{ ${x} \\pm \\sqrt{(${-x})^2-4(${-x2})(${-c})}}{2(${-x2})}$`
        )
      );
      if (roots[1] > 0) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]}, x=${roots[1]}$`)
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput(roots, 0, "$\\ x=$");
        question.addInputGroup(group1);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\therefore x=${roots[0]}, x \\neq ${roots[1]}$ as $x>0$`
          )
        );
        const group1 = new MCQuestion.InputGroup(1);
        group1.addInput(roots[0], 0, "$\\ x=$");
        question.addInputGroup(group1);
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_Exam_Q12() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const h = MCRandom.randomInt(400, 800) / 100;
    const w = MCRandom.randomInt(400, 800) / 10;

    const a = (-2 * h) / (2 * (w / 2) ** 2);
    const b = (-h - a * (w / 2) ** 2) / (w / 2);

    const hill = new MCMaths.Polynomial([a, b, h], "x");
    /* END VARIABLES AND CONSTANTS */
    if ((b / a) % (1 / 32) === 0 && parseFloat(a.toFixed(3)) === a) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      const graph = new MCQuestion.Graph(50, -50, 10, 0, 10, 1);
      function fx(x) {
        return hill.evaluate(x);
      }
      graph.plot(hill.functionString(), -w / 2, w / 2);
      question.addGraph("question", graph);

      question.addParagraph(
        `question`,
        `Geologists model the cross section of a mountain using the quadratic equation ${MCMaths.cleaner(
          `$y=${hill.toString()}$.`
        )}`
      );
      question.addParagraph(
        `question`,
        `This is shown on the graph above, where each unit on the graph represents $1km$.`
      );
      question.addParagraph(
        `question`,
        `The mountain measures $h$ km high and has a base $w$ km wide.`
      );
      question.addParagraph(`question`, `Find $h$ and $w$.`);
      // solution
      question.addHeading("solution", `Maximum at $x=0, y=h$:`);
      question.addHeading("solution", MCMaths.cleaner(`$${a}(0)^2+${h}=h$`));
      question.addParagraph("solution", MCMaths.cleaner(`$h=${h}$`));
      question.addParagraph("solution", `$=${h}$ km`);

      question.addHeading("solution", `Finding $w$:`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${hill.toString()}=0$`)
      );
      question.addHeading(`solution`, `Using Quadratic Formula:`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$x=\\frac{0 \\pm \\sqrt{0-4(${a})(${h})}}{2(${a})}$`)
      );
      question.addParagraph(`solution`, MCMaths.cleaner(`$x=\\pm ${w / 2}$`));
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$w= ${w / 2}-(${-w / 2})$`)
      );
      question.addParagraph(`solution`, `$= ${w}$ km`);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(h, 0, "$\\ h=$");
      group1.addInput(w, 0, "$\\ w=$");
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_Exam_Q13() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const curveHeight = MCRandom.randomInt(60, 120);
    const curveWidth = MCRandom.randomInt(140, 200);
    const boxHeight = MCRandom.randomInt(20, curveHeight - 10);
    const boxWidth = MCRandom.randomInt(40, curveWidth - 20);

    const b = curveHeight / (curveWidth / 2) ** 2;
    const fitVal = curveHeight - b * (boxWidth / 2) ** 2;
    const clearance = fitVal - boxHeight;

    /* END VARIABLES AND CONSTANTS */
    if (
      true &&
      parseFloat(b.toFixed(3)) === b &&
      parseFloat(fitVal.toFixed(2)) === fitVal &&
      parseFloat(clearance.toFixed(3)) === clearance &&
      clearance !== 0 &&
      boxWidth > curveHeight &&
      clearance > 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const image = new MCQuestion.Image(
        "James/QuadraticTable.svg",
        "height",
        20
      );
      image.addOverlay(`$${curveHeight}cm$`, 58, 26);
      image.addOverlay(`$${curveWidth}cm$`, 50, 53);
      image.addOverlay(`$y \\ cm$`, 17, 73);
      image.addOverlay(`$${boxWidth} cm$`, 50, 95);
      question.addImage("question", image);
      // question
      question.addParagraph(
        "question",
        `The figure above shows a curved coffee table, and rectangular table mat.`
      );
      question.addParagraph(
        "question",
        `Modelling the shape of the table as a quadratic, find the maximun width $y$ the mat can be, while still fitting on the table.`
      );
      // solution
      question.addHeading("solution", `Using the model $y=a-bx^2$:`);
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Using Maximum Point $(0,${curveHeight})$:`)
      );
      question.addParagraph("solution", `$${curveHeight}=a-b(0)^2$`);
      question.addParagraph("solution", `$a=${curveHeight}$`);
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Using $x$-axis intercepts $(${-curveWidth / 2},0)$, $(${
            curveWidth / 2
          }, 0)$:`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$0=${curveHeight}-b(${curveWidth / 2})^2$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$b=${curveHeight / (curveWidth / 2) ** 2}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=${curveHeight}-${b}x^2$`)
      );

      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Evaluating at $\\displaystyle x=\\frac{${boxWidth}}{2}$:`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=${curveHeight}-${b}(${boxWidth / 2})^2$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=${fitVal}$`)
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(fitVal, 0, "cm", "after");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_Exam_Q14() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here]
    const prize = MCRandom.randomInt(50, 150) * 10;
    const people = MCRandom.randomInt(10, 20);

    const falseClaims = MCRandom.randomInt(2, Math.round(people / 2));

    const more = prize / (people - falseClaims) - prize / people;

    const poly = new MCMaths.Polynomial(
      [more, more * -falseClaims, -prize * falseClaims],
      "x"
    );
    const roots = poly.roots();

    /* END VARIABLES AND CONSTANTS */
    if (
      more % (1 / 8) === 0 &&
      roots[0] !== "No Real Roots" &&
      roots[0] % (1 / 8) === 0 &&
      roots[1] % (1 / 8) === 0 &&
      roots[0] > 0 &&
      roots[1] < falseClaims
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A prize of $£${prize}$ is to be shared equally amongst $x$ people who all tied in an online competition.`
      );
      if (more % 1 === 0) {
        question.addParagraph(
          "question",
          `However, it was later found that $${falseClaims}$ cheated, so they no longer qualified for a share of the prize.`
        );
        question.addParagraph(
          "question",
          `This meant that each winner would now receive $£${more}$ more than previously thought.`
        );
      } else {
        question.addParagraph(
          "question",
          `However, it was later found that $${falseClaims}$ cheated, so they no longer qualified for a share of the  prize.`
        );
        question.addParagraph(
          "question",
          `This meant that each winner would now receive $£${more.toFixed(
            2
          )}$ more than previously thought.`
        );
      }
      question.addParagraph("question", `Find the value of $x$.`);
      // solution
      question.addHeading(
        "solution",
        `Original share is $\\frac{${prize}}{x}$.`
      );
      question.addHeading(
        "solution",
        `New share is $\\frac{${prize}}{x-${falseClaims}}$.`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore \\frac{${prize}}{x-${falseClaims}}-\\frac{${prize}}{x}=${more}$`
        )
      );
      question.addHeading("solution", "Rearranging:");
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${prize}x-${prize}(x-${falseClaims})=${more}x(x-${falseClaims})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${poly.toString()}=0$`)
      );
      question.addHeading("solution", `Using Quadratic Formula:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle x=\\frac{${more * falseClaims} \\pm \\sqrt{\\left(${
            more * -falseClaims
          }\\right)^2-4\\left(${more}\\right)\\left(${
            -prize * falseClaims
          }\\right)}}{2\\left(${more}\\right)}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x=${roots[0]}, x \\neq ${roots[1]}$, as $x>${falseClaims}$.`
        )
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(roots[0], 0, "$\\ x=$");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(2, 4);
    const p = MCRandom.randomInt(-5, 5);
    const q = -(MCRandom.randomInt(1, 5) ** 2 * a);

    const fx = new MCMaths.Polynomial([a, a * 2 * p, a * p ** 2 + q], "x");
    const roots = fx.roots();
    const top = MCRandom.randomInt(-10, 10);
    /* END VARIABLES AND CONSTANTS */
    if (
      p !== 0 &&
      top !== 0 &&
      a * p ** 2 + q !== 0 &&
      roots[0] !== "No Real Roots" &&
      roots[0] !== roots[1] &&
      roots[0] % (1 / 8) === 0 &&
      roots[1] % (1 / 8) === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$f(x)=${fx.toString()}$`)
      );
      question.addParagraph(
        "question",
        `By expressing $f(x)$ in completed square form:`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`a) Solve the equation $f(x)=0$.`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `b) Find $\\left(f(x)-${q}\\right)^2$ in the form $p(x+q)^4$.`
        )
      );
      // solution
      question.addHeading("solution", `a) Completing The Square:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f(x)=${a}(x^2+${2 * p}x+${p ** 2 + q / a})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=${a}\\left[\\left(x+\\frac{${2 * p}}{2}\\right)^2-${p ** 2}+${
            p ** 2 + q / a
          }\\right]$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${a}\\left(x+${p}\\right)^2+${q}$`)
      );

      question.addHeading("solution", `Solving:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(x+${p})^2=${-q / a}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x+${p}=\\pm ${Math.sqrt(-q / a)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x=\\pm ${Math.sqrt(-q / a)}-${p}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${roots[0]}, x=${roots[1]}$`)
      );

      question.addMultipleParagraphs("solution", [
        MCMaths.cleaner(
          `b) HEADING $\\left(f(x)-${q}\\right)^2 = \\left(${a}\\left(x+${p}\\right)^2+${q}-${q}\\right)^2$`
        ),
        MCMaths.cleaner(`$=${a ** 2}\\left(x+${p}\\right)^4$`),
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(roots, 0, "a) $\\ x = $");
      group1.addInput(a ** 2, 0, "b) $\\ p = $");
      group1.addInput(p, 0, "b) $\\ q = $");

      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x2 = MCRandom.randomInt(-4, -2);
    const kx = MCRandom.randomInt(2, 5);
    const x = MCRandom.randomInt(-10, 10);
    const c = MCRandom.randomInt(-20, 20);
    const kc = MCRandom.randomInt(-8, 8);

    const k_poly = new MCMaths.Polynomial(
      [kx ** 2, -4 * x2 * kc + 2 * x * kx, -4 * x2 * c + kx ** 2],
      "k"
    );
    const k_roots = k_poly.roots();

    const y = new MCMaths.Polynomial(
      [x2, kx * k_roots[0] + x, kc * k_roots[0] + c],
      "x"
    );
    const roots = y.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      x !== 0 &&
      c !== 0 &&
      Math.abs(kc) > 1 &&
      roots[0] !== "No Real Roots" &&
      roots[0] === roots[1] &&
      roots[0] % (1 / 8) === 0 &&
      k_roots[0] !== "No Real Roots" &&
      k_roots[0] > 0 &&
      k_roots[1] < 0 &&
      k_roots[0] % (1 / 8) === 0 &&
      k_roots[1] % (1 / 8) === 0 &&
      -((kx * k_roots[0] + x) / (2 * x2)) !== 0 &&
      kc * k_roots[0] + c !== 0 &&
      -4 * x2 * kc + 2 * x * kx !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        `question`,
        `The $x$-axis is tangent to the curve ${MCMaths.cleaner(
          `$y=${x2}x^2+(${kx}k+${x})x+(${kc}k+${c}), k>0$.`
        )}`
      );
      question.addParagraph(`question`, MCMaths.cleaner(`a) Find $k$.`));
      question.addParagraph(
        `question`,
        MCMaths.cleaner(`b) Find where the curve touches the $x$-axis.`)
      );
      // solution
      question.addHeading(`solution`, MCMaths.cleaner(`a) Using $b^2-4ac=0$:`));
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$(${kx}k+${x})^2-4(${x2})(${kc}k+${c})=0$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${k_poly.toString()}=0$`)
      );
      question.addHeading(`solution`, `Using Quadratic Formula:`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$k=\\frac{${-(-4 * x2 * kc + 2 * x * kx)} \\pm \\sqrt{(${
            -4 * x2 * kc + 2 * x * kx
          })^2-4(${kx ** 2})(${-4 * x2 * c + kx ** 2})}}{2(${kx ** 2})}$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$\\therefore k=${k_roots[0]}, k \\neq ${k_roots[1]}$ as $k>0$`
        )
      );

      question.addHeading(
        `solution`,
        `b) ${MCMaths.cleaner(
          `$y=${x2}x^2+[${kx}(${k_roots[0]})+${x}]x+[${kc}(${k_roots[0]})+${c}]$`
        )}`
      );

      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore y=${y.toString()}$`)
      );
      question.addHeading(
        `solution`,
        MCMaths.cleaner(`The curve touches the x-axis at $y=0$:`)
      );
      question.addParagraph(`solution`, MCMaths.cleaner(`$${y.toString()}=0$`));
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$x^2+${(kx * k_roots[0] + x) / x2}x+${(kc * k_roots[0] + c) / x2}=0$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$\\left(x+${(kx * k_roots[0] + x) / (2 * x2)}\\right)^2=0$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$\\therefore$ Touches at $x=${-((kx * k_roots[0] + x) / (2 * x2))}$.`
        )
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(k_roots[0], 0, "a) $\\ k = $");
      group1.addInput(-((kx * k_roots[0] + x) / (2 * x2)), 0, "b) $\\ x = $");
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x2 = MCRandom.randomInt(2, 3);
    const root1 = MCRandom.randomInt(-11, 11, 2);
    const root2 = MCRandom.randomInt(-10, 10);

    const y = new MCMaths.Polynomial([x2, x2 * root2 + root1, root1 * root2]);
    /* END VARIABLES AND CONSTANTS */
    if (root1 !== 0 && root2 !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        `A curve $C$ has equation $y=${y.toString()}$.`
      );
      question.addParagraph(
        "question",
        `By expressing $y$ as a product of two linear factors:`
      );
      question.addParagraph(
        "question",
        `a) Find where the curve crosses the $x$-axis.`
      );
      question.addParagraph(
        "question",
        `b) Find where the curve crosses the $y$-axis.`
      );
      // solution
      question.addHeading("solution", `Factorising:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=(${x2}x+${root1})(x+${root2})$`)
      );
      question.addHeading("solution", `a) Setting $y=0$:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(${x2}x+${root1})(x+${root2})=0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${-root1 / x2}, x=${-root2}$`)
      );

      question.addHeading("solution", `b) Setting $x=0$:`);

      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=(0+${root1})(0+${root2})$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${root1 * root2}$`));

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput([-root1 / x2, -root2], 0, "a) $\\ x = $");
      group1.addInput(root1 * root2, 0, "b) $\\ y = $");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const min_y = MCRandom.randomInt(-20, -5);
    const min_x = MCRandom.randomInt(-10, 10);
    const n = MCRandom.randomInt(-20, 20);

    const y = new MCMaths.Polynomial(
      [1, -2 * min_x, min_y + min_x ** 2 - n],
      "x"
    );
    const roots = y.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      min_x !== 0 &&
      roots[0] !== "No Real Roots" &&
      roots[0] % (1 / 8) === 0 &&
      roots[1] % (1 / 8) === 0 &&
      n !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        `question`,
        `The function $f(x)=x^2+bx+c$ has a minimum point of $(${min_x},${min_y})$.`
      );
      question.addParagraph(`question`, `a) Find $b$ and $c$.`);
      question.addParagraph(
        `question`,
        `b) Find the solutions of $f(x)=${n}$.`
      );
      // solution
      question.addHeading(`solution`, `a) Using the Completed Square:`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$f(x)=(x-${min_x})^2+${min_y}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=x^2-${2 * min_x}x+${min_x ** 2}+${min_y}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=x^2-${2 * min_x}x+${min_y + min_x ** 2}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$\\therefore b=${-1 * min_x * 2}, c=${min_y + min_x ** 2}$`
        )
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(-1 * min_x * 2, 0, "a) $\\ b = $");
      group1.addInput(min_y + min_x ** 2, 0, "a) $\\ c = $");

      question.addHeading(
        `solution`,
        `b) ${MCMaths.cleaner(
          `$x^2+${-1 * min_x * 2}x+${min_y + min_x ** 2}=${n}$`
        )}`
      );

      question.addParagraph(`solution`, MCMaths.cleaner(`$${y.toString()}=0$`));
      question.addHeading(`solution`, `Using Quadratic Formula:`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$\\displaystyle x=\\frac{${-(-1 * min_x * 2)} \\pm \\sqrt{(${
            -1 * min_x * 2
          })^2-4(1)(${min_y + min_x ** 2 - n})}}{2(1)}$`
        )
      );
      if (roots[0] !== roots[1]) {
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$\\therefore x=${roots[0]}, x=${roots[1]}$`)
        );
        group1.addInput(roots, 0, "b) $\\ x = $");
      } else {
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$\\therefore x=${roots[0]}$`)
        );
        group1.addInput(roots[0], 0, "b) $\\ x = $");
      }

      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const max_y = MCRandom.randomInt(2, 10);
    const max_x = MCRandom.randomInt(2, 10);

    const m = MCRandom.randomInt(-5, -2);

    const fx = new MCMaths.Polynomial([-1, 2 * max_x, -1 * max_x ** 2 + max_y]);
    const gx = new MCMaths.Polynomial([
      -m,
      -m * -2 * max_x,
      -m * (max_x ** 2 + max_y),
    ]);

    const kx = MCRandom.randomInt(2, 4);
    const k = MCRandom.randomInt(-5, 5);

    const k_poly = new MCMaths.Polynomial(
      [
        kx ** 2,
        -m * -2 * max_x * 2 * kx - 4 * -1 * m * k,
        (-m * -2 * max_x) ** 2 + 4 * m * (-m * (max_x ** 2 + max_y)),
      ],
      "k"
    );
    const kroots = k_poly.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      max_x !== 0 &&
      max_y !== 0 &&
      k !== 0 &&
      kx !== 0 &&
      kroots[0] !== "No Real Roots" &&
      kroots[0] % (1 / 8) === 0 &&
      kroots[1] % (1 / 8) === 0 &&
      kroots[0] > kroots[1]
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        `a) Find the maximum point of $f(x)=${fx.toString()}$.`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Let $g(x)=${m}f(x)+${kx}kx+${k}k$, where $k$ is a constant.`
        )
      );
      question.addParagraph(
        "question",
        `Given $g(x)=0$ has no real solutions:`
      );
      question.addParagraph(
        "question",
        `b) Find the range of possible values of $k$.`
      );
      // solution
      question.addHeading("solution", `a) Completing The Square:`);
      question.addParagraph(
        "solution",
        `$f(x)$${MCMaths.cleaner(
          `$=-(x^2+${-2 * max_x}x)+${-1 * max_x ** 2 + max_y}$`
        )}`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\left[-\\left(x+\\frac{${-2 * max_x}}{2}\\right)^2+${
            max_x ** 2
          }\\right]+${-1 * max_x ** 2 + max_y}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f(x)=${max_y}-(x-${max_x})^2$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore$ Minimum point is $(${max_x},${max_y})$.`)
      );

      question.addHeading(
        "solution",
        `b) ${MCMaths.cleaner(`$g(x)=${m}f(x)+${kx}kx+${k}k$`)}`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${m}\\left[${fx.toString()}\\right]+${kx}kx+${k}k$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=(${gx.toString()})+${kx}kx+${k}k$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=${-m}x^2+(${-m * -2 * max_x}+${kx}k)x+(${
            -m * (max_x ** 2 + max_y)
          }+${k}k)$`
        )
      );
      question.addHeading(
        "solution",
        `No Real Solutions $\\Rightarrow$ $b^2-4ac < 0$:`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(${-m * -2 * max_x}+${kx}k)^2-4(${-m})(${
            -m * (max_x ** 2 + max_y)
          }+${k}k)<0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${k_poly.toString()}<0$`)
      );
      question.addHeading("solution", `Using Quadratic Formula:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle k=\\frac{${
            -1 * (-m * -2 * max_x * 2 * kx - 4 * -1 * m * k)
          } \\pm \\sqrt{(${-m * -2 * max_x * 2 * kx - 4 * -1 * m * k})^2-4(${
            kx ** 2
          })(${
            (-m * -2 * max_x) ** 2 + 4 * m * (-m * (max_x ** 2 + max_y))
          })}}{2(${kx ** 2})}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore k=${kroots[0]}, k=${kroots[1]}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`For $${k_poly.toString()}<0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${kroots[1]}<k<${kroots[0]}$`)
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(max_x, 0, "a) $\\ x = $");
      group1.addInput(max_y, 0, "a) $\\ y = $");
      group1.addInput(kroots[1], 0, "b) $\\ k > $");
      group1.addInput(kroots[0], 0, "b) $\\ k < $");
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const kx2 = MCRandom.randomInt(-20, -2);
    let kx_tmp = MCRandom.randomInt(-15, 15);
    const k = MCRandom.randomInt(-18, -2);

    while (true) {
      if (kx_tmp ** 2 - 4 * kx2 * k === 0) {
        kx_tmp = MCRandom.randomInt(-15, 15);
      } else {
        break;
      }
    }
    const kx = kx_tmp;

    const x2 = MCRandom.randomInt(2, 5);
    const x = MCRandom.randomInt(-10, 10);
    const c = MCRandom.randomInt(-16, 16);

    const a = kx ** 2 - 4 * kx2 * k;
    const b = 2 * kx * x - 4 * (x2 * k + kx2 * c);
    const c2 = x ** 2 - 4 * (x2 * c);

    const k_poly = new MCMaths.Polynomial([a, b, c2], "k");
    const kroots = k_poly.roots();
    const ans2 = Math.min(kroots[0], -x2 / kx2);
    /* END VARIABLES AND CONSTANTS */
    if (
      kx !== 0 &&
      c !== 0 &&
      k !== 0 &&
      x !== 0 &&
      kroots[0] !== "No Real Roots" &&
      Math.round(kroots[0] * 10) === kroots[0] * 10 &&
      Math.round(kroots[1] * 10) === kroots[1] * 10 &&
      kroots[0] > kroots[1] &&
      a > 0 &&
      -x2 / kx2 > kroots[1] &&
      ans2 < 11 &&
      kroots[1] > -11
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        `A curve $C$ is defined by the equation ${MCMaths.cleaner(
          `$y=(${x2}+${kx2}k)x^2+(${x}+${kx}k)x+(${c}+${k}k)$.`
        )}`
      );
      question.addParagraph(
        "question",
        `Given that $C$ lies entirely above the $x$-axis, find the range of possible values for $k$.`
      );
      // solution
      question.addHeading("solution", `Curve Has Positive $x^2$ Coefficient:`);
      question.addParagraph("solution", MCMaths.cleaner(`$${x2}+${kx2}k>0$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore k<${-x2 / kx2}$`)
      );
      question.addHeading(
        "solution",
        `No Real Solutions $\\Rightarrow$ $b^2-4ac < 0$:`
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`$(${x}+${kx}k)^2-4(${x2}+${kx2}k)(${c}+${k}k)<0$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`$${k_poly.toString()}<0$`)
      );
      question.addHeading("solution", `Using Quadratic Formula:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle k=\\frac{${-b} \\pm \\sqrt{(${b})^2-4(${a})(${c2})}}{2(${a})}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore k=${kroots[0]}, k=${kroots[1]}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`For $${k_poly.toString()}<0$:`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${kroots[1]}<k<${kroots[0]}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Introducing $k<${-x2 / kx2}$:`)
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${kroots[1]}<k<${ans2}$`)
      );
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(kroots[1], 0, "$\\ k > $");
      group1.addInput(ans2, 0, "$\\ k < $");
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m = MCRandom.randomInt(-6, -1) / 4;
    const c = MCRandom.randomInt(12, 32) / 4;

    const x_min = MCRandom.randomInt(8, 28) / 4;
    const y_min = MCRandom.randomInt(8, 20) / 4;

    const poly = new MCMaths.Polynomial(
      [1, -2 * x_min - m, x_min ** 2 + y_min - c],
      "x"
    );
    const quadratic = new MCMaths.Polynomial(
      [1, -2 * x_min, x_min ** 2 + y_min],
      "x"
    );

    const k4 = -1 * ((-2 * x_min - m) ** 2 - 4 * (x_min ** 2 + y_min - c));
    const k = k4 / 4;
    /* END VARIABLES AND CONSTANTS */
    if (
      poly.roots()[0] === "No Real Roots" &&
      k4 % (1 / 8) === 0 &&
      k % (1 / 8) === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // graph
      const graph = new MCQuestion.Graph(10, 0, 10, 0, 1, 1);
      graph.plot(`${m} * x + ${c}`, 0, 10);
      graph.plot(`(x - ${x_min}) ** 2 + ${y_min}`, 0, 10);
      question.addGraph("question", graph);
      // question
      question.addParagraph(
        "question",
        `The graph above shows the line $L$ and curve $C$.`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`The line $L$ has equation: $y=${m}x+${c}$.`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `The curve $C$ has equation: $y=${quadratic.toString()}$.`
        )
      );
      question.addParagraph(
        "question",
        `The line $L$ is translated by $k$ units in the positive $y$ direction so that it now intersects $C$ at one or more points.`
      );
      question.addParagraph(
        "question",
        `Find the range of possible values of $k$.`
      );
      // solution
      question.addHeading("solution", `Translating $L$:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$L:$ $y = ${m}x+${c}+k$`)
      );
      question.addHeading("solution", `Setting $L$ and $C$ equal:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${quadratic.toString()} = ${m}x+${c}+k$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${poly.toString()} - k = 0$`)
      );
      question.addHeading(
        "solution",
        `One or More Real Solutions $\\Rightarrow$ $b^2-4ac \\le 0$:`
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `$(${-2 * x_min - m})^2-4(1)(${x_min ** 2 + y_min - c} - k) \\geq 0$`
        )
      );
      question.addHeading("solution", MCMaths.cleaner(`$4k \\geq ${k4}$`));
      question.addHeading(
        "solution",
        MCMaths.cleaner(`$\\therefore k \\geq ${k}$`)
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(k, 0, "$\\ k\\geq$");
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(-20, 20);
    const c = MCRandom.randomInt(1, 20);
    const originalPoly = new MCMaths.Polynomial([a, b, c], "x");
    const roots = originalPoly.roots();

    const offset = MCRandom.randomInt(-15, 15);

    const t1 = Math.sqrt(roots[0]) + offset;
    const t2 = -Math.sqrt(roots[0]) + offset;
    const t3 = Math.sqrt(roots[1]) + offset;
    const t4 = -Math.sqrt(roots[1]) + offset;
    /* END VARIABLES AND CONSTANTS */
    if (
      roots[0] !== "No Real Roots" &&
      Math.sqrt(roots[0]) % (1 / 16) === 0 &&
      Math.sqrt(roots[1]) % (1 / 16) === 0 &&
      Math.abs(offset) > 1 &&
      roots[0] !== 0 &&
      roots[1] !== 0 &&
      t1 !== -offset &&
      t2 !== -offset &&
      t3 !== -offset &&
      t4 !== -offset
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph("question", ``);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `a) Solve the equation $\\displaystyle ${a}x = -\\frac{${c}}{x}+${-b}$.`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `b) Hence, solve $\\displaystyle ${a}(t-${offset})^2 = -\\frac{${c}}{(t-${offset})^2}+${-b}$.`
        )
      );
      // solution
      question.addHeading("solution", MCMaths.cleaner(`a) Rearranging:`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${a}x^2 = ${-c}+${-b}x$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${originalPoly.toString()}=0$`)
      );
      question.addHeading("solution", `Using Quadratic Formula:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle x=\\frac{${-b} \\pm \\sqrt{(${b})^2-4(${a})(${c})}}{2(${a})}$`
        )
      );

      if (roots[0] !== roots[1]) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]}, x=${roots[1]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`b) Let $x=(t-${offset})^2$:`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`For $x=${roots[0]}$:`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$(t-${offset})^2=${roots[0]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$t-${offset}=\\pm ${Math.sqrt(roots[0])}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$t=\\pm ${Math.sqrt(roots[0])}+${offset}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\therefore t=${Math.sqrt(roots[0]) + offset}, t=${
              -Math.sqrt(roots[0]) + offset
            }$`
          )
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`For $x=${roots[1]}$:`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$(t-${offset})^2=${roots[1]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$t-${offset}=\\pm ${Math.sqrt(roots[1])}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$t=\\pm ${Math.sqrt(roots[1])}+${offset}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\therefore t=${Math.sqrt(roots[1]) + offset}, t=${
              -Math.sqrt(roots[1]) + offset
            }$`
          )
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput(roots, 0, "a) $\\ x= $");
        question.addInputGroup(group1);

        const group2 = new MCQuestion.InputGroup(2);
        group2.addInput([t1, t2, t3, t4], 0, "b) $\\ t= $");
        question.addInputGroup(group2);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`b) Let $x=(t-${offset})^2$:`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$(t-${offset})^2=${roots[0]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$t-${offset}=\\pm ${Math.sqrt(roots[0])}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$t=\\pm ${Math.sqrt(roots[0])}+${offset}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\therefore t=${Math.sqrt(roots[0]) + offset}, t=${
              -Math.sqrt(roots[0]) + offset
            }$`
          )
        );
        const group1 = new MCQuestion.InputGroup(1);
        group1.addInput(roots[0], 0, "a) $\\ x= $");
        question.addInputGroup(group1);

        const group2 = new MCQuestion.InputGroup(2);
        group2.addInput([t1, t2], 0, "b) $\\ t= $");
        question.addInputGroup(group2);
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(-8, 8);
    const c = MCRandom.randomInt(-12, 12);
    /* END VARIABLES AND CONSTANTS */
    if (b !== 0 && c !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(4);
      question.addParagraph(
        `A curve has equation${MCMaths.cleaner(` $y=${a}(x-${b})^2+${c}$.`)}`
      );
      question.addParagraph(`Determine the coordinates of its minimum point.`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$(${b}, ${c})$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$(${-b}, ${c})$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$(${a * b}, ${c})$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$(${b}, ${-c})$`);

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(-8, 8);
    const c = MCRandom.randomInt(-12, 12);
    /* END VARIABLES AND CONSTANTS */
    if (b !== 0 && c !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(4);
      question.addParagraph(
        `A curve has equation${MCMaths.cleaner(` $y=${a}(x-${b})^2+${c}$.`)}`
      );
      question.addParagraph(
        `Determine the equation for the line of symmetry of the curve.`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$x=${b}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$x=${-b}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$y=${b * MCRandom.randomInt(-1, 1, 2)}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$x=${a * b}$`);

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    // tp = (b, c)   roots are ogPolyRoots
    const a = MCRandom.randomInt(1, 1);
    const b = MCRandom.randomInt(-10, 10);
    const c = MCRandom.randomInt(-20, 20);

    const ogPoly = new MCMaths.Polynomial([a, b, c]);
    const ogPolyRoots = ogPoly.roots();

    const flipped_x = new MCMaths.Polynomial([a, -b, c]);
    const flipped_xy = new MCMaths.Polynomial([-a, b, -c]);
    const flipped_y = new MCMaths.Polynomial([-a, -b, -c]);

    const curves = [ogPoly, flipped_x, flipped_xy, flipped_y];
    const correctCurve = MCRandom.randomInt(0, 3);
    const bools = [false, false, false, false];
    bools[correctCurve] = true;
    const csForm = curves[correctCurve].completeSquare();
    const lastLine = csForm.length - 1;
    /* END VARIABLES AND CONSTANTS */
    if (
      a !== 0 &&
      b !== 0 &&
      c !== 0 &&
      ogPolyRoots[0] % 1 === 0 &&
      ogPolyRoots[1] % 1 === 0 &&
      c !== 0 &&
      ogPoly.plottingRange()[0] === 5 &&
      ogPolyRoots[1] !== ogPolyRoots[0]
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Select the graph of the curve with equation ${MCMaths.cleaner(
          `${csForm[lastLine]}.`
        )}`
      );
      question.addParagraph(`$${curves[correctCurve].toString()}$`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addGraph(ogPoly.graph());

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addGraph(flipped_x.graph());

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addGraph(flipped_xy.graph());

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addGraph(flipped_y.graph());

      question.addAnswer(a1, bools[0]);
      question.addAnswer(a2, bools[1]);
      question.addAnswer(a3, bools[2]);
      question.addAnswer(a4, bools[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const b = MCRandom.randomInt(-10, 10);
    const c = MCRandom.randomInt(-15, 15);

    const poly = new MCMaths.Polynomial([1, b, c]);
    const roots = poly.roots();
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(3);
      question.addGraph(poly.graph());
      question.addParagraph(
        `Which of the following is true for the quadratic curve plotted above:`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$b^2-4ac>0$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$b^2-4ac=0$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$b^2-4ac<0$`);

      if (roots[0] === "No Real Roots") {
        question.addAnswer(a1, false);
        question.addAnswer(a2, false);
        question.addAnswer(a3, true);
      } else if (roots[0] === roots[1] && roots[0] !== "No Real Roots") {
        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
        question.addAnswer(a3, false);
      } else {
        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_QuadraticFunctions_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(-4, 4);
    const b = MCRandom.randomInt(-4, 4);
    const c = MCRandom.randomInt(-8, 8);
    /* END VARIABLES AND CONSTANTS */
    if (b !== 0 && a !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(3);
      if (a < 0) {
        question.addParagraph(
          `Which of the following is true for a quadratic curve with a maximum point at $(${b}, ${c}):$`
        );
      } else {
        question.addParagraph(
          `Which of the following is true for a quadratic curve with a minimum point at $(${b}, ${c}):$`
        );
      }

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$b^2-4ac>0$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$b^2-4ac=0$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$b^2-4ac<0$`);

      if (a < 0) {
        if (c < 0) {
          question.addAnswer(a1, false);
          question.addAnswer(a2, false);
          question.addAnswer(a3, true);
        } else if (c === 0) {
          question.addAnswer(a1, false);
          question.addAnswer(a2, true);
          question.addAnswer(a3, false);
        } else {
          question.addAnswer(a1, true);
          question.addAnswer(a2, false);
          question.addAnswer(a3, false);
        }
      } else if (c < 0) {
        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
      } else if (c === 0) {
        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
        question.addAnswer(a3, false);
      } else {
        question.addAnswer(a1, false);
        question.addAnswer(a2, false);
        question.addAnswer(a3, true);
      }

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_Exam_Q1() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const c1 = MCRandom.randomInt(-20, 20);
    const c2 = MCRandom.randomInt(-20, 20);

    const m1 = MCRandom.randomInt(-5, 5);
    const m2 = MCRandom.randomInt(-5, 5);

    // const offset1 = MCRandom.randomInt(-5, 5);
    const offset2 = MCRandom.randomInt(2, 5);

    // const solver = new MCMaths.Intersections([-m1, 1, c1],
    //									 [offset2 * m2, offset2, offset2 * c2], 'x', 'y');

    const x = (c1 - offset2 * c2) / (offset2 - m1);
    const y = offset2 * x + offset2 * c2;
    /* END VARIABLES AND CONSTANTS */
    if (
      c1 !== 0 &&
      c2 !== 0 &&
      m1 !== 0 &&
      m2 !== 0 &&
      x !== -c2 &&
      x % 1 === 0 &&
      y % 1 === 0 &&
      Math.abs(x) + Math.abs(y) < 100
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph("question", `Solve the simultaneous equations`);
      question.addParagraph("question", MCMaths.cleaner(`$y-${m1}x=${c1}$`));
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$\\frac{y}{x+${c2}}=${offset2}$`)
      );
      // Working
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Using $\\frac{y}{x+${c2}}=${offset2}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=${offset2}x+${offset2 * c2}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Sub into $y-${m1}x=${c1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(${offset2}x+${offset2 * c2})-${m1}x=${c1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${x}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=${offset2}(${x})+${offset2 * c2}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=${y}$`)
      );

      // Answers
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(x, 0, "$x$=");
      group1.addInput(y, 0, "$y$=");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_Exam_Q10() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m = MCRandom.randomInt(-5, 5);
    const c1 = MCRandom.randomInt(-10, 10);

    const x = MCRandom.randomInt(2, 10);
    const c2 = MCRandom.randomInt(-10, 10);

    const k_poly = new MCMaths.Polynomial(
      [x ** 2, -2 * x * m - 4 * c2, m ** 2 + 4 * c1],
      "k"
    );
    const k_roots = k_poly.roots();

    const ans_poly = new MCMaths.Polynomial(
      [1, x * k_roots[0] - m, c2 * k_roots[0] - c1],
      "x"
    );
    const ans_roots = ans_poly.roots();

    /* END VARIABLES AND CONSTANTS */
    if (
      m !== 0 &&
      c1 !== 0 &&
      c2 !== 0 &&
      x !== 0 &&
      k_roots[0] !== "No Real Roots" &&
      k_roots[0] % (1 / 8) === 0 &&
      k_roots[1] % (1 / 8) === 0 &&
      ans_roots[0] !== "No Real Roots" &&
      ans_roots[0] % (1 / 8) === 0 &&
      ans_roots[0] === ans_roots[1] &&
      k_roots[1] < 0 &&
      k_roots[0] > 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form qw
      question.addParagraph("question", MCMaths.cleaner(`The line`));
      question.addParagraph("question", MCMaths.cleaner(`$y=${m}x+${c1}$`));
      question.addParagraph(
        "question",
        MCMaths.cleaner(`Is tangent to the curve`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$y=x^2+${x}kx+${c2}k$ , $k>0$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`Find the point where the line touches the curve`)
      );
      // solution
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Equating both equations`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${m}x+${c1}=x^2+${x}kx+${c2}k$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x^2+(${x}k-${m})x+(${c2}k-${c1})=0$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `$b^2-4ac=0$ as the line is tangent, so there's only one solution`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore (${x}k-${m})^2-4(1)(${c2}k-${c1})=0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${k_poly.toString()}=0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore k=${k_roots[0]}, k\\neq${k_roots[1]}$ as $k > 0$`
        )
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Using $k=${k_roots[0]}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$x^2+[${x}(${k_roots[0]})-${m}]x+[${c2}(${k_roots[0]})-${c1}]=0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${ans_poly.toString()}=0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${ans_roots[0]}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Sub into $y=${m}x+${c1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=${m}(${ans_roots[0]})+${c1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=${m * ans_roots[0] + c1}$`)
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(ans_roots[0], 0, "$x$=");
      group1.addInput(m * ans_roots[0] + c1, 0, "$y$=");
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_Exam_Q11() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const w = MCRandom.randomInt(5, 20);
    const l = MCRandom.randomInt(5, 20);

    const w_poly = new MCMaths.Polynomial([1, -(l + w), l * w], "w");
    const w_roots = w_poly.roots();
    /* END VARIABLES AND CONSTANTS */
    if (w_roots[0] !== "No Real Roots" && w_roots[0] > w_roots[1] && l < w) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        `A rectangle has length $l$cm and width $w$cm where $l<w$`
      );
      question.addParagraph(
        "question",
        `Given that it has a perimeter of $${2 * (l + w)}$cm and an area of $${
          w * l
        }$cm$^2$`
      );
      question.addParagraph("question", `Find $l$ and $w$`);
      // solution
      question.addHeading(
        "solution",
        `Given  a perimeter of $${2 * (l + w)}$cm`
      );
      question.addParagraph("solution", `$2w+2l=${2 * (l + w)}$`);
      question.addParagraph("solution", `$l=${l + w}-w$`);
      question.addHeading("solution", `Given an area of $${l * w}$cm$^2$`);
      question.addParagraph("solution", `$wl=${l * w}$`);
      question.addHeading("solution", `Sub in $l=${l + w}-w$`);
      question.addParagraph("solution", `$w(${l + w}-w)=${l * w}$`);
      question.addParagraph("solution", `$${w_poly.toString()}=0$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$w=${w_roots[0]}, w\\neq${w_roots[1]}$ as $l<w$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore l=${w_roots[1]}$`)
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(l, 0, "$l$=");
      group1.addInput(w, 0, "$w$=");
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_Exam_Q12() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const w = MCRandom.randomInt(5, 15);
    const l = MCRandom.randomInt(5, 15);
    const h = MCRandom.randomInt(5, 15);

    const w_poly = new MCMaths.Polynomial(
      [h, l * w - (l * w + l * h + w * h), h * l * w],
      "w"
    );
    const w_roots = w_poly.roots();
    /* END VARIABLES AND CONSTANTS */
    if (w_roots[0] !== "No Real Roots" && w_roots[0] > w_roots[1] && l < w) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      /* END BUILDING QUESTION */
      // Form q
      question.addParagraph(
        "question",
        `A cuboid has length $l$cm, width $w$cm and height $${h}$cm, where $l<w$`
      );
      question.addParagraph(
        "question",
        `Given that it has a volume of $${
          l * w * h
        }$cm$^3$ and a surface area of $${2 * (l * w + l * h + w * h)}$cm$^2$`
      );
      question.addParagraph("question", `Find $l$ and $w$`);
      // solution
      question.addHeading("solution", `Given a volume of $${l * w * h}$cm$^3$`);
      question.addParagraph("solution", `$${h}lw=${l * w * h}$`);
      question.addParagraph("solution", `$lw=${l * w}$`);
      question.addParagraph("solution", `$l=\\frac{${l * w}}{w}$`);
      question.addHeading(
        "solution",
        `Given a surface area of $${2 * (l * w + l * h + w * h)}$cm$^2$`
      );
      question.addParagraph(
        "solution",
        `$2(lw+${h}w+${h}l)=${2 * (l * w + l * h + w * h)}$`
      );
      question.addParagraph(
        "solution",
        `$lw+${h}w+${h}l=${l * w + l * h + w * h}$`
      );
      question.addHeading(
        "solution",
        `Using $lw=${l * w}$ and subbing in $l=\\frac{${l * w}}{w}$`
      );
      question.addParagraph(
        "solution",
        `$(${l * w})+${h}w+${h}(\\frac{${l * w}}{w})=${l * w + l * h + w * h}$`
      );
      question.addParagraph("solution", `$${w_poly.toString()}=0$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$w=${w_roots[0]}, w\\neq${w_roots[1]}$ as $l<w$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore l=${w_roots[1]}$`)
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(l, 0, "$l$=");
      group1.addInput(w, 0, "$w$=");
      question.addInputGroup(group1);
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_Exam_Q13() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const childPrice = MCRandom.randomInt(4, 10, 2);
    const adultPrice = MCRandom.randomInt(14, 20, 2);

    const numChild = MCRandom.randomInt(100, 400, 2);
    const numAdult = MCRandom.randomInt(200, 500, 2);

    const attendance = numChild + numAdult;
    const money = numChild * childPrice + numAdult * adultPrice;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Tickets to see a theatre production cost $£${childPrice}$ for children and $£${adultPrice}$ for adults.`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `One night, the show makes $£${money}$ from ticket sales and plays to $${attendance}$ people.`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Find the number of adults and the number of children in attendance`
        )
      );
      // solution
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`Let $a=$number of adults, and $c=$number of children`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given a total attendance of ${attendance}`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$a+c=${attendance}$`));
      question.addParagraph("solution", MCMaths.cleaner(`$c=${attendance}-a$`));
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given $£${money}$ made in ticket sales total`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${adultPrice}a+${childPrice}c=${money}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Sub in $c=${attendance}-a$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${adultPrice}a+${childPrice}(${attendance}-a)=${money}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${adultPrice - childPrice}a=${money - childPrice * attendance}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore a=${numAdult}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$c=${attendance}-${numAdult}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore c=${numChild}$`)
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(numAdult, 0, "adults", "after");
      group1.addInput(numChild, 0, "children", "after");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_Exam_Q14() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m1 = MCRandom.randomInt(-5, 5);
    const c1 = MCRandom.randomInt(-10, 10);
    const m2 = MCRandom.randomInt(-5, 5);
    const c2 = MCRandom.randomInt(-10, 10);
    const y = MCRandom.randomInt(-3, 3);

    const x_sol = -(c2 - y * c1) / (m2 - y * m1);
    const y_sol = m1 * x_sol + c1;
    /* END VARIABLES AND CONSTANTS */
    if (
      m1 !== m2 &&
      c1 !== 0 &&
      m1 !== 0 &&
      c2 !== 0 &&
      m2 !== 0 &&
      y !== 0 &&
      m2 - y * m1 !== 0 &&
      x_sol % (1 / 16) === 0 &&
      y_sol % (1 / 16) === 0 &&
      Math.abs(x_sol) < 50 &&
      Math.abs(y_sol) < 50
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        `Solve, by method of elimination, the simultaneous equations`
      );
      question.addParagraph("question", MCMaths.cleaner(`$y=${m1}x+${c1}$`));
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$${y}y=${m2}x+${c2}$`)
      );
      // solution
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Let E1: $y=${m1}x+${c1}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Let E2: $${y}y=${m2}x+${c2}$`)
      );
      question.addHeading("solution", MCMaths.cleaner(`Let E3=E2$-${y}$E1`));
      question.addHeading(
        "solution",
        MCMaths.cleaner(`E3: $${y}y-${y}y=(${m2}x+${c2})-${y}(${m1}x+${c1})$`)
      );
      question.addHeading("solution", MCMaths.cleaner(`Solve E3`));
      question.addHeading(
        "solution",
        MCMaths.cleaner(`$0=${m2 - y * m1}x+${c2 - y * c1}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${x_sol}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=${m1}(${x_sol})+${c1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=${y_sol}$`)
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(x_sol, 0, "$x$=");
      group1.addInput(y_sol, 0, "$y$=");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_Exam_Q15() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const r1 = MCRandom.randomInt(-10, 10);
    const r2 = MCRandom.randomInt(-10, 10);

    const curve = MCMaths.Polynomial.generateFromRoots([r1, r2], "x");

    const m = MCRandom.randomInt(-5, 5);
    const c = MCRandom.randomInt(-15, 15);

    const b = -r1 - r2;
    const ans_poly = new MCMaths.Polynomial([1, b - m, r1 * r2 - c], "x");
    const roots = ans_poly.roots();

    /* END VARIABLES AND CONSTANTS */
    if (
      r1 !== 0 &&
      r2 !== 0 &&
      m !== 0 &&
      c !== 0 &&
      r1 !== -r2 &&
      roots[0] !== "No Real Roots" &&
      roots[0] % 1 === 0 &&
      roots[1] % 1 === 0 &&
      b !== m &&
      r1 * r2 - c !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph("question", `The line`);
      question.addParagraph("question", MCMaths.cleaner(`$y=${m}x+c$`));
      question.addParagraph("question", `Intersects the curve`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$y=x^2+bx+${r1 * r2}$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`At $x=${roots[0]}$ and $x=${roots[1]}$`)
      );
      question.addParagraph("question", `Find the values of $b$ and $c$`);
      // solution
      question.addHeading(
        "solution",
        MCMaths.cleaner(`At $x=${roots[0]}$ and $x=${roots[1]}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${m}x+c=x^2+bx+${r1 * r2}$`)
      );
      question.addHeading("solution", `At $x=${roots[0]}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${m}(${roots[0]})+c=(${roots[0]})^2+${roots[0]}b+${r1 * r2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore c=${roots[0]}b+${
            r1 * r2 + roots[0] ** 2 - m * roots[0]
          }$`
        )
      );
      question.addHeading("solution", `At $x=${roots[1]}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${m}(${roots[1]})+c=(${roots[1]})^2+${roots[1]}b+${r1 * r2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore c=${roots[1]}b+${
            r1 * r2 + roots[1] ** 2 - m * roots[1]
          }$`
        )
      );
      question.addHeading("solution", `Equating both expressions for $c$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${roots[0]}b+${r1 * r2 + roots[0] ** 2 - m * roots[0]}=${
            roots[1]
          }b+${r1 * r2 + roots[1] ** 2 - m * roots[1]}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore b=${b}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$c=${roots[0]}(${b})+${r1 * r2 + roots[0] ** 2 - m * roots[0]}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore c=${c}$`)
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(b, 0, "$b$=");
      group1.addInput(c, 0, "$c$=");
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_Exam_Q2() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const r1 = MCRandom.randomInt(-10, 10);
    const r2 = MCRandom.randomInt(-10, 10);

    const curve = MCMaths.Polynomial.generateFromRoots([r1, r2], "x");

    const m = MCRandom.randomInt(-5, 5);
    const c = MCRandom.randomInt(-15, 15);

    const answer_poly = new MCMaths.Polynomial(
      [1, -r1 - r2 - m, r1 * r2 - c],
      "x"
    );
    const roots = answer_poly.roots();

    if (
      r1 !== 0 &&
      r2 !== 0 &&
      m !== 0 &&
      c !== 0 &&
      roots[0] !== "No Real Roots" &&
      roots[0] % 1 === 0 &&
      roots[1] % 1 === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph("question", `Find where the line`);
      question.addParagraph("question", MCMaths.cleaner(`$y=${m}x+${c}$`));
      question.addParagraph("question", `Intersects the curve`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$y=${curve.toString()}$`)
      );
      // Working
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Equating both equations`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${m}x+${c}=${curve.toString()}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`$${answer_poly.toString()}=0$`)
      );
      if (roots[0] !== roots[1]) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]}, x=${roots[1]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`When $x=${roots[0]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m}(${roots[0]})+${c}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[0] + c}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`When $x=${roots[1]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m}(${roots[1]})+${c}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[1] + c}$`)
        );

        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput([roots[0], roots[1]], 0, "$x$=");
        group1.addInput([m * roots[0] + c, m * roots[1] + c], 0, "$y$=");
        question.addInputGroup(group1);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m}(${roots[0]})+${c}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[0] + c}$`)
        );

        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput(roots[0], 0, "$x$=");
        group1.addInput(m * roots[0] + c, 0, "$y$=");
        question.addInputGroup(group1);
      }

      // Answers

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_Exam_Q3() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const r1 = MCRandom.randomInt(-6, 6);
    const r2 = MCRandom.randomInt(-10, 10);

    // const poly = MCMaths.Polynomial.generateFromRoots([r1,r2], 'x');
    // const coeff = poly.getCoefficients();

    const m = MCRandom.randomInt(-4, -2);
    const c = MCRandom.randomInt(-10, 10);

    const ans_poly = new MCMaths.Polynomial(
      [m ** 2 - 1, -m * c - m * c - (-r1 - r2), c ** 2 - r1 * r2],
      "x"
    );
    const roots = ans_poly.roots();

    /* END VARIABLES AND CONSTANTS */
    if (
      r1 !== 0 &&
      r2 !== 0 &&
      m !== 0 &&
      c !== 0 &&
      r1 !== r2 &&
      roots[0] !== "No Real Roots" &&
      roots[0] !== roots[1] &&
      roots[0] % 1 === 0 &&
      roots[1] % 1 === 0 &&
      roots[0] !== r1 &&
      roots[1] !== r1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // form q
      question.addParagraph(
        "question",
        MCMaths.cleaner(`Solve the simultaneous equations`)
      );
      question.addParagraph("question", MCMaths.cleaner(`$${m}x+y=${c}$`));
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$\\frac{y^2}{(x-${r1})}=(x-${r2})$`)
      );
      // walkthrough
      question.addHeading("solution", MCMaths.cleaner(`Using $${m}x+y=${c}$`));
      question.addParagraph("solution", MCMaths.cleaner(`$y=${-m}x+${c}$`));
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Sub into $\\frac{y^2}{(x-${r1})}=(x-${r2})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{(${-m}x+${c})^2}{(x-${r1})}=(x-${r2})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${m ** 2}x^2+${-m * c - m * c}x+${c ** 2}=x^2+${-r1 - r2}x+${
            r1 * r2
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${ans_poly.toString()}=0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${roots[0]}, x=${roots[1]}$`)
      );
      question.addHeading("solution", MCMaths.cleaner(`When $x=${roots[0]}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=${-m}(${roots[0]})+${c}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=${-m * roots[0] + c}$`)
      );
      question.addHeading("solution", MCMaths.cleaner(`When $x=${roots[1]}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=${-m}(${roots[1]})+${c}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=${-m * roots[1] + c}$`)
      );
      // answers
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput([roots[0], roots[1]], 0, "$x$=");
      group1.addInput([-m * roots[0] + c, -m * roots[1] + c], 0, "$y$=");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_Exam_Q4() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const y1 = MCRandom.randomInt(1, 6);
    const m1 = MCRandom.randomInt(-6, -1);
    const c1 = MCRandom.randomInt(-10, 10);

    const m2 = MCRandom.randomInt(-4, -2);
    const c2 = MCRandom.randomInt(-10, 10);

    const ans_poly = new MCMaths.Polynomial(
      [
        (m1 / y1) * (m1 / y1),
        (2 * (m1 / y1) * c1) / y1 - m2,
        (c1 * c1) / (y1 * y1) - c2,
      ],
      "x"
    );

    const roots = ans_poly.roots();
    // const solver = new MCMaths.Intersections([m1, 1, c1], [m2, 0, 1, 1, c2], 'x', 'y');

    /* END VARIABLES AND CONSTANTS */
    if (
      m1 !== 0 &&
      c1 !== 0 &&
      m2 !== 0 &&
      c2 !== 0 &&
      y1 !== 0 &&
      roots[0] !== "No Real Roots" &&
      roots[0] % (1 / 8) === 0 &&
      roots[1] % (1 / 8) === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      // form q
      question.addParagraph("question", MCMaths.cleaner(`Find where the line`));
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$${-m1}x+${y1}y=${c1}$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`Intersects the curve`)
      );
      question.addParagraph("question", MCMaths.cleaner(`$y^2=${m2}x+${c2}$`));
      // walkthrough
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Using $${-m1}x+${y1}y=${c1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=${m1 / y1}x+${c1 / y1}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Sub into $y^2=${m2}x+${c2}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(${m1 / y1}x+${c1 / y1})^2=${m2}x+${c2}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${(m1 / y1) * (m1 / y1)}x^2+${(2 * (m1 / y1) * c1) / y1}x+${
            (c1 * c1) / (y1 * y1)
          }=${m2}x+${c2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${ans_poly.toString()}=0$`)
      );
      if (roots[0] !== roots[1]) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]},x=${roots[1]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`When $x=${roots[0]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m1 / y1}(${roots[0]})+${c1 / y1}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${(m1 / y1) * roots[0] + c1 / y1}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`When $x=${roots[1]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m1 / y1}(${roots[1]})+${c1 / y1}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${(m1 / y1) * roots[1] + c1 / y1}$`)
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput([roots[0], roots[1]], 0, "$x$=");
        group1.addInput(
          [(m1 / y1) * roots[0] + c1 / y1, (m1 / y1) * roots[1] + c1 / y1],
          0,
          "$y$="
        );
        question.addInputGroup(group1);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`Sub into $y=${m1 / y1}x+${c1 / y1}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m1 / y1}(${roots[0]})+${c1 / y1}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${(m1 / y1) * roots[0] + c1 / y1}$`)
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput(roots[0], 0, "$x$=");
        group1.addInput((m1 / y1) * roots[0] + c1 / y1, 0, "$y$=");
        question.addInputGroup(group1);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_Exam_Q5() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const c1 = MCRandom.randomInt(-10, -2);
    const c2 = MCRandom.randomInt(-5, 5) * c1;

    const m = MCRandom.randomInt(2, 5);
    const con = MCRandom.randomInt(-10, 10);
    const ans_poly = new MCMaths.Polynomial([c1 * m, c1 * con, -c2], "x");
    const roots = ans_poly.roots();

    /* END VARIABLES AND CONSTANTS */
    if (
      c1 !== 0 &&
      c2 !== 0 &&
      m !== 0 &&
      con !== 0 &&
      roots[0] !== "No Real Roots" &&
      roots[0] % (1 / 8) === 0 &&
      roots[1] % (1 / 8) === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        MCMaths.cleaner(`Solve the simultaneous equations`)
      );
      question.addParagraph("question", MCMaths.cleaner(`$${c1}xy=${c2}$`));
      question.addParagraph("question", MCMaths.cleaner(`$y-${con}=${m}x$`));
      // Walkthrough
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Using $y-${con}=${m}x$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$y=${m}x+${con}$`));
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Sub into $${c1}xy=${c2}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${c1}x(${m}x+${con})=${c2}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${ans_poly.toString()}=0$`)
      );
      if (roots[0] !== roots[1]) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]},x=${roots[1]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`When $x=${roots[0]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m}(${roots[0]})+${con}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[0] + con}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`When $x=${roots[1]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m}(${roots[1]})+${con}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[1] + con}$`)
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput([roots[0], roots[1]], 0, "$x$=");
        group1.addInput([m * roots[0] + con, m * roots[1] + con], 0, "$y$=");
        question.addInputGroup(group1);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`Sub into $y=${m}x+${con}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m}(${roots[0]})+${con}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[0] + con}$`)
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput(roots[0], 0, "$x$=");
        group1.addInput(m * roots[0] + con, 0, "$y$=");
        question.addInputGroup(group1);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_Exam_Q6() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    let x = MCRandom.randomInt(-5, 5);
    const xy = MCRandom.randomInt(2, 5);
    const m = MCRandom.randomInt(-5, -2);
    const c = MCRandom.randomInt(-10, 10);
    while (true) {
      if (-x === m ** 2 + xy * m) {
        x = MCRandom.randomInt(-5, 5);
      } else {
        break;
      }
    }
    const x2 = x;
    const ans_poly = new MCMaths.Polynomial(
      [x2 + m ** 2 + xy * m, 2 * m * c + xy * c, c ** 2],
      "x"
    );
    const roots = ans_poly.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      x2 !== 0 &&
      c !== 0 &&
      roots[0] !== "No Real Roots" &&
      roots[0] % (1 / 8) === 0 &&
      roots[1] % (1 / 8) === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        MCMaths.cleaner(`Solve the simultaneous equations`)
      );
      question.addParagraph("question", MCMaths.cleaner(`$y= ${m}x+${c}$`));
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$${x2}x^2+y^2+${xy}xy=0$`)
      );
      // solution
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Sub $y=${m}x+${c}$ into $${x2}x^2+y^2+${xy}xy=0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${x2}x^2+(${m}x+${c})^2+${xy}x(${m}x+${c})=0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${ans_poly.toString()}=0$`)
      );
      if (roots[0] !== roots[1]) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]},x=${roots[1]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`When $x=${roots[0]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m}(${roots[0]})+${c}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[0] + c}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`When $x=${roots[1]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m}(${roots[1]})+${c}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[1] + c}$`)
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput([roots[0], roots[1]], 0, "$x$=");
        group1.addInput([m * roots[0] + c, m * roots[1] + c], 0, "$y$=");
        question.addInputGroup(group1);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`Sub into $y=${m}x+${c}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m}(${roots[0]})+${c}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[0] + c}$`)
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput(roots[0], 0, "$x$=");
        group1.addInput(m * roots[0] + c, 0, "$y$=");
        question.addInputGroup(group1);
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_Exam_Q7() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x = MCRandom.randomInt(-10, 10);
    const y = MCRandom.randomInt(-10, 10);
    const c1 = MCRandom.randomInt(-10, 10);

    const m = MCRandom.randomInt(2, 5);
    const c2 = MCRandom.randomInt(-10, -2);

    const ans_poly = new MCMaths.Polynomial(
      [-c2 * m, y + x * m - c2 * c1, x * c1],
      "x"
    );
    const roots = ans_poly.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      x !== 0 &&
      y !== 0 &&
      m !== 0 &&
      c1 !== 0 &&
      c2 !== 0 &&
      roots[0] !== "No Real Roots" &&
      roots[0] % (1 / 8) === 0 &&
      roots[1] % (1 / 8) === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      /* END BUILDING QUESTION */
      // Form q
      question.addParagraph(
        "question",
        MCMaths.cleaner(`Solve the simultaneous equations`)
      );
      question.addParagraph("question", MCMaths.cleaner(`$y=${m}x+${c1}$`));
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$\\frac{${y}}{y}+\\frac{${x}}{x}=${c2}$`)
      );
      // Solve
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Using $\\frac{${y}}{y}+\\frac{${x}}{x}=${c2}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${y}x+${x}y=${c2}xy$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Sub in $y=${m}x+${c1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${y}x+${x}(${m}x+${c1})=${c2}x(${m}x+${c1})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${ans_poly.toString()}=0$`)
      );
      if (roots[0] !== roots[1]) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]},x=${roots[1]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`When $x=${roots[0]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m}(${roots[0]})+${c1}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[0] + c1}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`When $x=${roots[1]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m}(${roots[1]})+${c1}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[1] + c1}$`)
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput([roots[0], roots[1]], 0, "$x$=");
        group1.addInput([m * roots[0] + c1, m * roots[1] + c1], 0, "$y$=");
        question.addInputGroup(group1);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`Sub into $y=${m}x+${c1}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m}(${roots[0]})+${c1}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[0] + c1}$`)
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput(roots[0], 0, "$x$=");
        group1.addInput(m * roots[0] + c1, 0, "$y$=");
        question.addInputGroup(group1);
      }
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_Exam_Q8() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const xy = MCRandom.randomInt(2, 5);
    let x_tmp = MCRandom.randomInt(2, 5);
    const x = MCRandom.randomInt(-5, 5);
    const xc = MCRandom.randomInt(-10, 10);

    const m = MCRandom.randomInt(-5, -2);
    const c = MCRandom.randomInt(1, 10);
    while (true) {
      if (x_tmp === xy * m) {
        x_tmp = MCRandom.randomInt(2, 5);
      } else {
        break;
      }
    }
    const x2 = x_tmp;

    const ans_poly = new MCMaths.Polynomial(
      [xy * m - x2, xy * c - x, -xc],
      "x"
    );
    const roots = ans_poly.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      xc !== 0 &&
      x !== 0 &&
      roots[0] !== "No Real Roots" &&
      roots[0] % (1 / 8) === 0 &&
      roots[1] % (1 / 8) === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      /* END BUILDING QUESTION */
      // Form q
      question.addParagraph(
        "question",
        MCMaths.cleaner(`Solve the simultaneous equations`)
      );
      question.addParagraph("question", MCMaths.cleaner(`$y-${m}x=${c}$`));
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$${xy}xy = ${x2}x^2+${x}x+${xc}$`)
      );
      // Solve
      question.addHeading("solution", MCMaths.cleaner(`Using $y-${m}x=${c}$`));
      question.addParagraph("solution", MCMaths.cleaner(`$y=${c}+${m}x$`));
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Sub into $${xy}xy = ${x2}x^2+${x}x+${xc}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${xy}x(${c}+${m}x) = ${x2}x^2+${x}x+${xc}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${ans_poly.toString()}=0$`)
      );
      if (roots[0] !== roots[1]) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]},x=${roots[1]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`When $x=${roots[0]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${c}+${m}(${roots[0]})$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[0] + c}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`When $x=${roots[1]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${c}+${m}(${roots[1]})$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[1] + c}$`)
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput([roots[0], roots[1]], 0, "$x$=");
        group1.addInput([m * roots[0] + c, m * roots[1] + c], 0, "$y$=");
        question.addInputGroup(group1);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`Sub into $y=${c}+${m}x$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${c}+${m}(${roots[0]})$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[0] + c}$`)
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput(roots[0], 0, "$x$=");
        group1.addInput(m * roots[0] + c, 0, "$y$=");
        question.addInputGroup(group1);
      }
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_Exam_Q9() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    let x_tmp = MCRandom.randomInt(2, 5);
    const y2 = MCRandom.randomInt(-5, -2);
    const x = MCRandom.randomInt(-10, 10);
    const y = MCRandom.randomInt(-10, 10);
    const c1 = MCRandom.randomInt(-10, 10);

    const m = MCRandom.randomInt(-4, 4);
    const c2 = MCRandom.randomInt(-10, 10);

    while (true) {
      if (-x_tmp === y2 * m ** 2) {
        x_tmp = MCRandom.randomInt(2, 5);
      } else {
        break;
      }
    }
    const x2 = x_tmp;

    const ans_poly = new MCMaths.Polynomial(
      [
        x2 + y2 * m ** 2,
        y2 * 2 * m * c2 + x + y * m,
        y2 * c2 ** 2 + y * c2 - c1,
      ],
      "x"
    );
    const roots = ans_poly.roots();

    /* END VARIABLES AND CONSTANTS */
    if (
      x !== 0 &&
      y !== 0 &&
      (m > 1 || m < -1) &&
      c1 !== 0 &&
      c2 !== 0 &&
      roots[0] !== "No Real Roots" &&
      roots[0] % (1 / 8) === 0 &&
      roots[1] % (1 / 8) === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // Form q
      question.addParagraph(
        "question",
        MCMaths.cleaner(`Solve the simultaneous equations`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$${x2}x^2+${y2}y^2+${x}x+${y}y=${c1}$`)
      );
      question.addParagraph("question", MCMaths.cleaner(`$y=${m}x+${c2}$`));
      // Solve
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Sub $y=${m}x+${c2}$ into $${x2}x^2+${y2}y^2+${x}x+${y}y=${c1}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${x2}x^2+${y2}(${m}x+${c2})^2+${x}x+${y}(${m}x+${c2})=${c1}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${ans_poly.toString()}=0$`)
      );
      if (roots[0] !== roots[1]) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]},x=${roots[1]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`When $x=${roots[0]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m}(${roots[0]})+${c2}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[0] + c2}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`When $x=${roots[1]}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m}(${roots[1]})+${c2}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[1] + c2}$`)
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput([roots[0], roots[1]], 0, "$x$=");
        group1.addInput([m * roots[0] + c2, m * roots[1] + c2], 0, "$y$=");
        question.addInputGroup(group1);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore x=${roots[0]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`Sub into $y=${m}x+${c2}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y=${m}(${roots[0]})+${c2}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m * roots[0] + c2}$`)
        );
        const group1 = new MCQuestion.InputGroup(2);
        group1.addInput(roots[0], 0, "$x$=");
        group1.addInput(m * roots[0] + c2, 0, "$y$=");
        question.addInputGroup(group1);
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const x = MCRandom.randomInt(-4, 4);

    const y1 = MCRandom.randomInt(-5, 5);
    const m1 = MCRandom.randomInt(-5, 5);
    const c1 = MCRandom.randomInt(-15, 15);

    const y = (m1 * x + c1) / y1;

    const y2 = MCRandom.randomInt(-5, 5);
    const m2 = MCRandom.randomInt(-5, 5);
    const c2 = (y2 * (m1 * x + c1)) / y1 - m2 * x;

    const y3 = MCRandom.randomInt(-5, 5);
    const m3 = MCRandom.randomInt(-5, 5);
    const c3 = MCRandom.randomInt(-15, 15);

    const y4 = MCRandom.randomInt(-5, 5);
    const m4 = MCRandom.randomInt(-5, 5);
    const c4 = MCRandom.randomInt(-15, 15);

    /* END VARIABLES AND CONSTANTS */
    if (
      m1 !== 0 &&
      c1 !== 0 &&
      y1 !== 0 &&
      m2 !== 0 &&
      c2 !== 0 &&
      y2 !== 0 &&
      m3 !== 0 &&
      c3 !== 0 &&
      y3 !== 0 &&
      m4 !== 0 &&
      c4 !== 0 &&
      y4 !== 0 &&
      parseFloat(((m1 * x + c1) / y1).toFixed(2)) === (m1 * x + c1) / y1 &&
      c2 % 1 === 0 &&
      m3 * x + c3 - y3 * y !== 0 &&
      m4 * x + c4 - y4 * y !== 0 &&
      c1 !== c2 &&
      y1 !== y2 &&
      m1 !== m2
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        MCMaths.cleaner(
          `Which of these lines meet at $(${x}, ${(m1 * x + c1) / y1})$`
        )
      );
      question.addHeading(`Choose TWO`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(MCMaths.cleaner(`$${y1}y -${m1}x = ${c1}$`));

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(MCMaths.cleaner(`$${y2}y -${m2}x = ${c2}$`));

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(MCMaths.cleaner(`$${y3}y-${m3}x = ${c3}$`));

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(MCMaths.cleaner(`$${y4}y -${m4}x = ${c4}$`));

      question.addAnswer(a1, true);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const base = MCRandom.randomInt(2, 5);
    const add = MCRandom.randomInt(-10, 10);

    const m = MCRandom.randomInt(-5, -1);
    const c = MCRandom.randomInt(20, 30);

    const x = MCRandom.randomInt(16, 40) / 4;
    const y = m * x + c;

    const false_x1 = x + MCRandom.randomInt(-2, 2);
    const false_x2 = x + MCRandom.randomInt(-2, 2);
    const false_y1 = y + MCRandom.randomInt(-5, 5);
    const false_y2 = y + MCRandom.randomInt(-5, 5);

    /* END VARIABLES AND CONSTANTS */
    if (
      m * x + c > 0 &&
      m * x + c === base ** x + add &&
      add !== 0 &&
      false_x1 !== x &&
      false_y1 !== y &&
      false_y2 !== false_y1 &&
      false_y2 !== y &&
      false_x2 !== false_x1 &&
      false_x2 !== x
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(`At what point does the line`);
      question.addParagraph(MCMaths.cleaner(`$y=${m}x+${c}$`));
      question.addParagraph(`Meet the curve `);
      question.addParagraph(MCMaths.cleaner(`$y=${base}^x+${add}, y > 0$`));

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(MCMaths.cleaner(`$(${x}, ${y})$`));

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(MCMaths.cleaner(`$(${false_x1}, ${y})$`));

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(MCMaths.cleaner(`$(${false_x2}, ${false_y1})$`));

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(MCMaths.cleaner(`$(${x}, ${false_y2})$`));

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_SimultaneousEquations_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a_x2 = MCRandom.randomInt(2, 5);
    const x = MCRandom.randomInt(-8, -2);
    const k = MCRandom.randomInt(1, 15);
    const m = MCRandom.randomInt(1, 5);
    const c = MCRandom.randomInt(1, 10);
    const discriminant = (x - m) ** 2 - 4 * a_x2 * (k - c);

    const b_x2 = MCRandom.randomInt(2, 4);
    const b_x = MCRandom.randomInt(-15, 15);
    const b_k = MCRandom.randomInt(-15, -2);
    const b_m = MCRandom.randomInt(2, 6);
    const b_c = MCRandom.randomInt(1, 10);
    const b_discriminant = (b_x - b_m) ** 2 - 4 * b_x2 * (b_k - b_c);

    const y1 = MCRandom.randomInt(-5, 5);
    const x1 = MCRandom.randomInt(-5, 5);
    const c1 = MCRandom.randomInt(-20, 20);
    const offset1 = MCRandom.randomInt(-10, 10);

    const y2 = MCRandom.randomInt(-5, 5);
    const x2 = MCRandom.randomInt(-5, 5);
    const c2 = MCRandom.randomInt(-20, 20);
    const offset2 = MCRandom.randomInt(-5, 5);

    /* END VARIABLES AND CONSTANTS */
    if (
      y1 !== 0 &&
      x1 !== 0 &&
      c1 !== 0 &&
      offset1 !== 0 &&
      discriminant < 0 &&
      b_discriminant > 0 &&
      offset2 !== x1 &&
      offset2 !== 0 &&
      y2 !== 0 &&
      x2 !== 0 &&
      c2 !== 0 &&
      c1 - offset1 !== 0 &&
      -x2 - offset2 !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Which of the following simultaneous equations have no solutions`
      );
      question.addHeading(`Choose TWO`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(MCMaths.cleaner(`$${y1}y+${x1}x=${c1}$`));
      a1.addParagraph(
        MCMaths.cleaner(`$y=${-x1 / y1}x+${(c1 - offset1) / y1}$`)
      );

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(MCMaths.cleaner(`$y=${a_x2}x^2+${x}x+${k}$`));
      a2.addParagraph(MCMaths.cleaner(`$y=${m}x+${c}$`));

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(MCMaths.cleaner(`$${y2}y+${x2}x=${c2}$`));
      a3.addParagraph(
        MCMaths.cleaner(`$y=${(-x2 - offset2) / y2}x+${c2 / y2}$`)
      );

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(MCMaths.cleaner(`$y=${b_x2}x^2+${b_x}x+${b_k}$`));
      a4.addParagraph(MCMaths.cleaner(`$y=${b_m}x+${b_c}$`));

      question.addAnswer(a1, true);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_Exam_Q1() {
  let count = 0;
  while (true) {
    const ran1 = MCRandom.randomInt(1, 6);
    const ran2 = MCRandom.randomInt(2, 7);
    const ran3 = MCRandom.randomInt(2, 4);
    const ran4 = MCRandom.randomInt(2, 4);
    const a_answer = ran1 * ran1 - ran2;
    const b_answer = ran3 + ran4;

    if (true && ran2 !== 4 && ran3 !== ran4) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) State the value of $ (${ran1}+\\sqrt{${ran2}})(${ran1}-\\sqrt{${ran2}})$.`
      );
      question.addParagraph(
        "question",
        `b) Express $\\sqrt{${ran2 * ran3 ** 2}}+\\sqrt{${
          ran2 * ran4 ** 2
        }}$ in the form $n\\sqrt{${ran2}}$, for integer $n$.`
      );
      question.addHeading("solution", `a) Expanding:`);
      question.addParagraph(
        "solution",
        `$=${ran1}^{2} + ${ran1}\\sqrt{${ran2}} - ${ran1}\\sqrt{${ran2}} - (\\sqrt{${ran2}})^2$`
      );
      question.addParagraph("solution", `$ = ${ran1 ** 2}-${ran2}$`);
      question.addParagraph("solution", `$ = ${a_answer}$`);
      question.addHeading(
        "solution",
        `b) $\\sqrt{${ran2 * ran3 ** 2}} = \\sqrt{${
          ran3 ** 2
        }}\\times\\sqrt{${ran2}}$`
      );
      question.addParagraph("solution", `$= ${ran3}\\times\\sqrt{${ran2}}$`);
      question.addParagraph(
        "solution",
        `$\\sqrt{${ran2 * ran4 ** 2}} = \\sqrt{${
          ran4 ** 2
        }}\\times\\sqrt{${ran2}}$`
      );
      question.addParagraph("solution", `$= ${ran4}\\times\\sqrt{${ran2}}$`);
      question.addParagraph("solution", `$n = ${ran3}+${ran4}$`);
      question.addParagraph("solution", `$= ${b_answer}$`);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(a_answer, 0, "a) $\\ $");
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(b_answer, 0, "b) $\\ n=$");
      question.addInputGroup(group1);
      question.addInputGroup(group2); // done

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_Exam_Q10() {
  let count = 0;
  while (true) {
    const base = MCRandom.randomInt(2, 6);
    const base2 = MCRandom.randomInt(2, 6);
    const coefficient = MCRandom.randomInt(2, 6);
    const ran3 = MCRandom.randomInt(2, 6);
    const ran4 = MCRandom.randomInt(2, 6);
    const a_answer = base * coefficient * coefficient;
    const b_answer = (a_answer * ran3) / (ran4 ** 3 * base2);
    if (
      true &&
      base !== 4 &&
      base2 !== 4 &&
      base !== base2 &&
      b_answer === Number(b_answer.toFixed(2))
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Simplify $(${coefficient}\\sqrt{${base}})^2$`
      );
      question.addHeading(
        "solution",
        `a) Simplify $(${coefficient}\\sqrt{${base}})^2$`
      );
      question.addParagraph(
        "solution",
        `$${coefficient}^2 = ${coefficient * coefficient}$`
      );
      question.addParagraph("solution", `$(\\sqrt{${base}})^2 = ${base}$`);
      question.addParagraph(
        "solution",
        `$${base}\\times ${coefficient * coefficient} = ${a_answer}$`
      );
      question.addParagraph(
        "question",
        `b) State the value of $\\frac{(${coefficient}\\sqrt{${base}})^2 \\times ${ran3}\\sqrt{${base2}}}{(${ran4}\\sqrt{${base2}})^3}$`
      );
      question.addHeading(
        "solution",
        `b) State the value of $\\frac{(${coefficient}\\sqrt{${base}})^2 \\times ${ran3}\\sqrt{${base2}}}{(${ran4}\\sqrt{${base2}})^3}$`
      );
      question.addParagraph(
        "solution",
        `From a), $(${coefficient}\\sqrt{${base}})^2 = ${a_answer}$`
      );
      question.addParagraph(
        "solution",
        `$(${ran4}\\sqrt{${base2}})^3 = (${ran4})^3 \\times (\\sqrt{${base2}})^3$`
      );
      question.addParagraph(
        "solution",
        `$= ${ran4 ** 3}\\times${base2}\\sqrt{${base2}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore (${ran4}\\sqrt{${base2}})^3 = ${
          ran4 ** 3 * base2
        }\\sqrt{${base2}}$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{(${coefficient}\\sqrt{${base}})^2 \\times ${ran3}\\sqrt{${base2}}}{(${ran4}\\sqrt{${base2}})^3} = \\frac{${a_answer} \\times ${ran3}\\sqrt{${base2}}}{${
          ran4 ** 3 * base2
        }\\sqrt{${base2}}}$`
      );
      question.addParagraph("solution", `Cancel $\\sqrt{${base2}}$`);
      question.addParagraph(
        "solution",
        `$ = \\frac{${a_answer} \\times ${ran3}}{${ran4 ** 3 * base2}}$`
      );
      question.addParagraph(
        "solution",
        `$ = \\frac{${a_answer * ran3}}{${ran4 ** 3 * base2}}$`
      );
      question.addParagraph("solution", `$ = ${b_answer}$`);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(a_answer, 0, "a) $\\ $");
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(b_answer, 0, "b) $\\ $");
      question.addInputGroup(group1);
      question.addInputGroup(group2); // done
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_Exam_Q11() {
  let count = 0;
  while (true) {
    const base = MCRandom.randomInt(2, 5);
    const ran1 = MCRandom.randomInt(2, 5);
    const ran2 = MCRandom.randomInt(2, 3);
    const ran3 = MCRandom.randomInt(2, 5);
    const ran4 = MCRandom.randomInt(2, 5);
    const a_answer = ran1;
    const b_answer = ran1 / base;
    const c_answer = ran2 * ran3 - ran4;
    if (
      true &&
      base !== 4 &&
      b_answer === Number(b_answer.toFixed(2)) &&
      c_answer !== 0 &&
      c_answer !== 1 &&
      b_answer !== 1
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Express in the form $k\\sqrt{${base}}$, stating the value of $k$`
      );
      question.addParagraph("question", `a) $\\sqrt{${base * ran1 ** 2}}$`);
      question.addParagraph(
        "question",
        `b) $\\frac{${ran1}}{\\sqrt{${base}}}$`
      );
      question.addParagraph(
        "question",
        `c) $${ran3}\\sqrt{${base * ran2 ** 2}} - ${ran4}\\sqrt{${base}}$`
      );
      question.addHeading("solution", `Express in the form $k\\sqrt{${base}}$`);
      question.addHeading("solution", `a) $\\sqrt{${base * ran1 ** 2}}$`);
      question.addParagraph(
        "solution",
        `$\\sqrt{${base * ran1 ** 2}} = \\sqrt{${base}} \\times \\sqrt{${
          ran1 ** 2
        }}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sqrt{${base * ran1 ** 2}} = ${a_answer}\\sqrt{${base}}$`
      );
      question.addParagraph("solution", `$\\therefore k = ${a_answer}$`);
      question.addHeading("solution", `b) $\\frac{${ran1}}{\\sqrt{${base}}}$`);
      question.addParagraph(
        "solution",
        `$\\frac{${ran1}}{\\sqrt{${base}}} \\times \\frac{\\sqrt{${base}}}{\\sqrt{${base}}}$`
      );
      question.addParagraph(
        "solution",
        `$ = \\frac{${ran1}\\times\\sqrt{${base}}}{${base}}$`
      );
      question.addParagraph("solution", `$ = ${ran1 / base}\\sqrt{${base}}$`);
      question.addParagraph("solution", `$ \\therefore k = ${b_answer}$`);
      question.addHeading(
        "solution",
        `c) $${ran3}\\sqrt{${base * ran2 ** 2}} - ${ran4}\\sqrt{${base}}$`
      );
      question.addParagraph(
        "solution",
        `$\\sqrt{${base * ran2 ** 2}} = ${ran2}\\sqrt{${base}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore ${ran3}\\sqrt{${
          base * ran2 ** 2
        }} - ${ran4}\\sqrt{${base}} = ${
          ran2 * ran3
        }\\sqrt{${base}}- ${ran4}\\sqrt{${base}}$ `
      );
      question.addParagraph("solution", `$ = ${c_answer}\\sqrt{${base}}$`);
      question.addParagraph("solution", `$\\therefore k = ${c_answer}$`);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(a_answer, 0, "a) $\\ k= $");
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(b_answer, 0, "b) $\\ k= $");
      const group3 = new MCQuestion.InputGroup(1);
      group3.addInput(c_answer, 0, "c) $\\ k= $");
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3); // done

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_Exam_Q12() {
  let count = 0;
  while (true) {
    const base1 = MCRandom.randomInt(2, 5);
    const base2 = MCRandom.randomInt(2, 5);
    const ran1 = MCRandom.randomInt(2, 7);
    const ran2 = MCRandom.randomInt(2, 7);
    const ran3 = MCRandom.randomInt(2, 7);
    const ran4 = MCRandom.randomInt(2, 7);
    const ai_answer = (ran2 * ran1) / (ran2 ** 2 - base2);
    const aii_answer = -((ran4 * ran3) / (ran4 ** 2 - base1));
    const aiii_answer = ran1 / (ran2 ** 2 - base2) - ran3 / (ran4 ** 2 - base1);
    if (
      true &&
      base1 !== 4 &&
      base2 !== 4 &&
      base2 !== base1 &&
      ran2 ** 2 > base2 &&
      ran4 ** 2 > base1 &&
      ai_answer === Number(ai_answer.toFixed(2)) &&
      aii_answer === Number(aii_answer.toFixed(2)) &&
      aiii_answer === Number(aiii_answer.toFixed(2))
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Express $ \\frac{${ran1}\\sqrt{${base1}}}{${ran2}-\\sqrt{${base2}}} - \\frac{${ran3}\\sqrt{${base2}}}{${ran4}-\\sqrt{${base1}}} $in the form $p\\sqrt{${base1}} + q\\sqrt{${base2}} + r\\sqrt{${
          base1 * base2
        }} $`
      );
      question.addHeading(
        "solution",
        `a) Express $ \\frac{${ran1}\\sqrt{${base1}}}{${ran2}-\\sqrt{${base2}}} - \\frac{${ran3}\\sqrt{${base2}}}{${ran4}-\\sqrt{${base1}}} $in the form $p\\sqrt{${base1}} + q\\sqrt{${base2}} + r\\sqrt{${
          base1 * base2
        }} $`
      );
      question.addParagraph(
        "solution",
        `Rationalising $ \\frac{${ran1}\\sqrt{${base1}}}{${ran2}-\\sqrt{${base2}}}:$`
      );
      question.addParagraph(
        "solution",
        `$ \\frac{${ran1}\\sqrt{${base1}}}{${ran2}-\\sqrt{${base2}}} \\times \\frac{${ran2}+\\sqrt{${base2}}}{${ran2}+\\sqrt{${base2}}}$`
      );
      question.addParagraph(
        "solution",
        `$ = \\frac{${ran1}\\sqrt{${base1 * base2}} + ${
          ran2 * ran1
        }\\sqrt{${base1}}}{${ran2 * ran2}-${base2}}$`
      );
      question.addParagraph(
        "solution",
        `$ = ${ran1 / (ran2 ** 2 - base2)}\\sqrt{${base1 * base2}} + ${
          (ran2 * ran1) / (ran2 ** 2 - base2)
        }\\sqrt{${base1}}$`
      );

      question.addParagraph(
        "solution",
        `Rationalising $ \\frac{${ran3}\\sqrt{${base2}}}{${ran4}-\\sqrt{${base1}}}:$`
      );
      question.addParagraph(
        "solution",
        `$ \\frac{${ran3}\\sqrt{${base2}}}{${ran3}-\\sqrt{${base1}}} \\times \\frac{${ran4}+\\sqrt{${base1}}}{${ran4}+\\sqrt{${base1}}}$`
      );
      question.addParagraph(
        "solution",
        `$ = \\frac{${ran3}\\sqrt{${base1 * base2}} + ${
          ran4 * ran3
        }\\sqrt{${base2}}}{${ran4 * ran4}-${base1}}$`
      );
      question.addParagraph(
        "solution",
        `$ = ${ran3 / (ran4 ** 2 - base1)}\\sqrt{${base1 * base2}} + ${
          (ran4 * ran3) / (ran4 ** 2 - base1)
        }\\sqrt{${base2}}$`
      );

      question.addParagraph(
        "solution",
        `$\\therefore \\frac{${ran1}\\sqrt{${base1}}}{${ran2}-\\sqrt{${base2}}} - \\frac{${ran3}\\sqrt{${base2}}}{${ran4}-\\sqrt{${base1}}} = ${
          ran1 / (ran2 ** 2 - base2)
        }\\sqrt{${base1 * base2}} + ${
          (ran2 * ran1) / (ran2 ** 2 - base2)
        }\\sqrt{${base1}} - (${ran3 / (ran4 ** 2 - base1)}\\sqrt{${
          base1 * base2
        }} + ${(ran4 * ran3) / (ran4 ** 2 - base1)}\\sqrt{${base2}})$`
      );

      question.addParagraph("solution", `$\\therefore p = ${ai_answer}$`);
      question.addParagraph("solution", `$\\therefore q = ${aii_answer}$`);
      question.addParagraph(
        "solution",
        `$\\therefore r = ${ran1 / (ran2 ** 2 - base2)}-${
          ran3 / (ran4 ** 2 - base1)
        }$`
      );
      question.addParagraph("solution", `$ = ${aiii_answer}$`);

      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput(ai_answer, 0, "a) $\\ p=$");
      group1.addInput(aii_answer, 0, "a) $\\ q=$");
      group1.addInput(aiii_answer, 0, "a) $\\ r=$");
      question.addInputGroup(group1); // done
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_Exam_Q2() {
  let count = 0;
  while (true) {
    const base = MCRandom.randomInt(2, 5);
    const coefficient1 = MCRandom.randomInt(2, 4);
    const coefficient2 = MCRandom.randomInt(2, 4);
    const ran1 = MCRandom.randomInt(2, 5);
    const ran2 = MCRandom.randomInt(2, 5);
    const pow1 = MCRandom.randomInt(2, 5);
    const pow2 = MCRandom.randomInt(2, 5);
    const aii_answer = coefficient2 * ran1 - coefficient1 * ran2;
    const ai_answer = coefficient2 * coefficient1 * base - ran1 * ran2;
    const b_answer = pow1 - pow2;

    if (
      true &&
      base !== 4 &&
      pow1 !== pow2 &&
      coefficient1 * ran2 > coefficient2 * ran1 &&
      ai_answer !== 0 &&
      aii_answer !== -1
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Express $(${coefficient1}\\sqrt{${base}}+${ran1})(${coefficient2}\\sqrt{${base}}-${ran2})$ in the form $a + b\\sqrt{${base}}$.`
      );
      question.addParagraph(
        "question",
        `b) Simplify $\\displaystyle\\frac{\\sqrt{${
          base * pow1 ** 2
        }} - \\sqrt{${base * pow2 ** 2}}}{\\sqrt{${base}}}$.`
      );
      question.addHeading("solution", `a) Expanding:`);
      question.addParagraph(
        "solution",
        `$=${coefficient2 * coefficient1 * base}-${
          coefficient1 * ran2
        }\\sqrt{${base}} + ${coefficient2 * ran1}\\sqrt{${base}} - ${
          ran1 * ran2
        }$`
      );
      question.addParagraph(
        "solution",
        `= $${coefficient2 * coefficient1 * base - ran1 * ran2} ${
          coefficient2 * ran1 - coefficient1 * ran2
        }\\sqrt{${base}}$`
      );
      question.addParagraph("solution", `$\\therefore a =  ${ai_answer}$ `);
      question.addParagraph("solution", `$\\therefore b =  ${aii_answer}$ `);
      question.addHeading("solution", `b) Simplifying:`);
      question.addParagraph(
        "solution",
        `$\\sqrt{${base * pow1 ** 2}} = \\sqrt{${base}} \\times \\sqrt{${
          pow1 ** 2
        }}$`
      );
      question.addParagraph(
        "solution",
        `$=\\sqrt{${base * pow1 ** 2}} = \\sqrt{${base}} \\times ${pow1}$`
      );
      question.addParagraph(
        "solution",
        `$\\sqrt{${base * pow2 ** 2}} = \\sqrt{${base}} \\times \\sqrt{${
          pow2 ** 2
        }}$`
      );
      question.addParagraph(
        "solution",
        `$=\\sqrt{${base * pow2 ** 2}} = \\sqrt{${base}} \\times ${pow2}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\frac{\\sqrt{${base * pow1 ** 2}} - \\sqrt{${
          base * pow2 ** 2
        }}}{\\sqrt{${base}}} = \\frac{${pow1}\\sqrt{${base}} - ${pow2}\\sqrt{${base}}}{\\sqrt${base}}$`
      );
      question.addParagraph("solution", `$ = ${b_answer}$`);
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(ai_answer, 0, "a) $\\ a=$");
      group1.addInput(aii_answer, 0, "a) $\\ b=$");
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(b_answer, 0, "b) $\\ $");
      question.addInputGroup(group1);
      question.addInputGroup(group2); // done
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_Exam_Q3() {
  let count = 0;
  while (true) {
    const base = MCRandom.randomInt(2, 5);
    const ran1 = MCRandom.randomInt(2, 5);
    const ran2 = MCRandom.randomInt(2, 5);
    const ran3 = MCRandom.randomInt(2, 5);
    const pow1 = MCRandom.randomInt(2, 5);
    const pow2 = MCRandom.randomInt(2, 3);
    const ai_answer = (base + ran2 * ran1) / (base - ran2 * ran2);
    const aii_answer = (ran1 + ran2) / (base - ran2 * ran2);
    const b_answer = pow1;
    const c_answer = (b_answer + ran3) / pow2;
    if (
      true &&
      base !== 4 &&
      ai_answer === Number(ai_answer.toFixed(1)) &&
      aii_answer === Number(aii_answer.toFixed(1)) &&
      aii_answer < 0 &&
      c_answer === Number(c_answer.toFixed(1))
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Express $\\displaystyle \\frac{\\sqrt{${base}}+${ran1}}{\\sqrt{${base}}-${ran2}}$in the form $p+q\\sqrt{${base}}$.`
      );
      question.addParagraph(
        "question",
        `b) Express $\\sqrt{${base * pow1 ** 2}}$ in the form $r\\sqrt${base}$.`
      );
      question.addParagraph(
        "question",
        `c) Find the value of $s$ when $s\\sqrt{${base * pow2 ** 2}} = \\sqrt{${
          base * pow1 ** 2
        }} + ${ran3}\\sqrt{${base}}$.`
      );
      question.addHeading(
        "solution",
        `a) $\\displaystyle \\frac{\\sqrt{${base}}+${ran1}}{\\sqrt{${base}}-${ran2}} \\times \\frac{\\sqrt{${base}}+${ran2}}{\\sqrt{${base}}+${ran2}} = \\frac{${
          base + ran2 * ran1
        } + ${ran1 + ran2}\\sqrt{${base}}}{${base - ran2 * ran2}}$`
      );
      question.addParagraph(
        "solution",
        ` $ = ${(base + ran2 * ran1) / (base - ran2 * ran2)} ${
          (ran1 + ran2) / (base - ran2 * ran2)
        }\\sqrt{${base}}$`
      );
      question.addParagraph("solution", `$\\therefore p =${ai_answer}$`);
      question.addParagraph("solution", `$\\therefore q =${aii_answer}$`);
      question.addHeading(
        "solution",
        `b) $\\sqrt{${base * pow1 ** 2}} = \\sqrt{${base}} \\times \\sqrt{${
          pow1 * pow1
        }}$`
      );
      question.addParagraph("solution", `$= \\sqrt{${base}} \\times ${pow1}$`);
      question.addParagraph("solution", `$\\therefore r = ${b_answer}$`);
      question.addHeading(
        "solution",
        `c) $${pow2} s \\times \\sqrt{${base}} = ${b_answer}\\sqrt{${base}} + ${ran3}\\sqrt{${base}}$`
      );
      question.addParagraph("solution", `$${pow2}s = ${b_answer}+${ran3}$`);
      question.addParagraph("solution", `$\\therefore s = ${c_answer}$`);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(ai_answer, 0, "a) $\\ p=$");
      group1.addInput(aii_answer, 0, "a) $\\ q=$");
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(b_answer, 0, "b) $\\ r=$");
      group2.addInput(c_answer, 0, "c) $\\ s=$");
      question.addInputGroup(group1);
      question.addInputGroup(group2); // done
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_Exam_Q4() {
  let count = 0;
  while (true) {
    const base = MCRandom.randomInt(2, 6);
    const pow1 = MCRandom.randomInt(2, 6);
    const pow2 = MCRandom.randomInt(2, 6);
    const a_answer = pow1 + pow2;
    const b_answer = base;
    if (true && base !== 4 && pow1 !== pow2 && pow1 > pow2) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `$\\sqrt{${base * pow1 ** 2}} - \\sqrt{${base * pow2 ** 2}}$`
      );
      question.addParagraph(
        "question",
        `a) The above equation can be written in the form $k\\sqrt{x}$. Find the values of $k$ and $x$`
      );
      question.addHeading("solution", `a) Find the values of $k$ and $x$`);
      question.addParagraph(
        "solution",
        `$\\sqrt{${base * pow1 ** 2}} = \\sqrt{${base}}\\times\\sqrt{${
          pow1 ** 2
        }}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sqrt{${base * pow2 ** 2}} = ${pow1}\\sqrt{${base}}$`
      );
      question.addParagraph(
        "solution",
        `$\\sqrt{${base * pow2 ** 2}} = \\sqrt{${base}}\\times\\sqrt{${
          pow2 ** 2
        }}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sqrt{${base * pow2 ** 2}} = ${pow2}\\sqrt{${base}}$`
      );
      question.addParagraph(
        "solution",
        `$${pow2}\\sqrt{${base}} - ${pow1}\\sqrt{${base}}$`
      );
      question.addParagraph("solution", `$\\therefore k =${a_answer}$`);
      question.addParagraph("solution", `$\\therefore x =${b_answer}$`);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(a_answer, 0, "a) $\\ k=$");
      group1.addInput(b_answer, 0, "a) $\\ x=$");
      question.addInputGroup(group1); // done

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_Exam_Q5() {
  let count = 0;
  while (true) {
    const coefficient = MCRandom.randomInt(2, 6);
    const base = MCRandom.randomInt(2, 6);
    const ran1 = MCRandom.randomInt(2, 6);
    const ran2 = MCRandom.randomInt(2, 6);
    const a_answer = base * coefficient * coefficient;
    const bi_answer = ran1 * ran2 - base;
    const bii_answer = ran2 - ran1;

    if (
      true &&
      base !== 4 &&
      ran1 !== ran2 &&
      ran2 > ran1 &&
      ran2 - ran1 !== 1
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Simplify the following:`);
      question.addParagraph(
        "question",
        `a) $(${coefficient}\\sqrt{${base}})^2$`
      );
      question.addParagraph(
        "question",
        `b) $(${ran1}+\\sqrt{${base}})(${ran2}-\\sqrt{${base}})$ in the form $a + b\\sqrt{${base}}$`
      );
      question.addHeading(
        "solution",
        `a) Simplify $(${coefficient}\\sqrt{${base}})^2$`
      );
      question.addParagraph(
        "solution",
        `$${coefficient}^2 = ${coefficient * coefficient}$`
      );
      question.addParagraph("solution", `$(\\sqrt{${base}})^2 = ${base}$`);
      question.addParagraph(
        "solution",
        `$${base}\\times ${coefficient * coefficient} = ${a_answer}$`
      );
      question.addHeading(
        "solution",
        `b) Simplify $(${ran1}+\\sqrt{${base}})(${ran2}-\\sqrt{${base}})$ in the form $a + b\\sqrt{${base}}$`
      );
      question.addParagraph(
        "solution",
        ` We can expand this to get $${
          ran1 * ran2
        }+${ran2}\\sqrt{${base}} - ${ran1}\\sqrt{${base}} - ${base}$`
      );
      question.addParagraph(
        "solution",
        `$ = ${ran1 * ran2 - base}+${ran2 - ran1}\\sqrt{${base}}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(a_answer, 0, "a) $\\ $");
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(bi_answer, 0, "b) $\\ a=$");
      group2.addInput(bii_answer, 0, "b) $\\ b=$");
      question.addInputGroup(group1);
      question.addInputGroup(group2); // done
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_Exam_Q6() {
  let count = 0;
  while (true) {
    const base = MCRandom.randomInt(2, 6);
    const ran1 = MCRandom.randomInt(2, 6);
    const ran2 = MCRandom.randomInt(2, 6);
    const ai_answer = ran1 * ran2 - base;
    const aii_answer = ran2 - ran1;
    const bi_answer = ai_answer / (ran2 * ran2 - base);
    const bii_answer = aii_answer / (ran2 * ran2 - base);

    if (
      true &&
      base !== 4 &&
      ran1 !== ran2 &&
      ran2 - ran1 !== 1 &&
      ran2 - ran1 > 0 &&
      bi_answer === Number(bi_answer.toFixed(1)) &&
      bii_answer === Number(bii_answer.toFixed(1))
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Expand and simplify $(${ran1}+\\sqrt{${base}})(${ran2}-\\sqrt{${base}})$ to the form $a + b\\sqrt{${base}}$`
      );
      question.addParagraph(
        "question",
        `b) Hence, simplify $\\frac{${ran1}+\\sqrt{${base}}}{${ran2}+\\sqrt{${base}}}$ to the form $p + q\\sqrt{${base}}$`
      );
      question.addHeading(
        "solution",
        `a) Expand and simplify $(${ran1}+\\sqrt{${base}})(${ran2}-\\sqrt{${base}})$ to the form $a + b\\sqrt{${base}}$`
      );
      question.addParagraph(
        "solution",
        `Expanding this we get $${
          ran1 * ran2
        }+ ${ran2}\\sqrt{${base}}-${ran1}\\sqrt{${base}}-${base}$`
      );
      question.addParagraph(
        "solution",
        `$= ${ran1 * ran2 - base}+${ran2 - ran1}\\sqrt{${base}}$`
      );
      question.addParagraph("solution", `$\\therefore a =  ${ai_answer}$`);
      question.addParagraph("solution", `$\\therefore b =  ${aii_answer}$`);
      question.addHeading(
        "solution",
        `b) Hence, simplify $\\frac{${ran1}+\\sqrt{${base}}}{${ran2}+\\sqrt{${base}}}$ to the form $p + q\\sqrt{${base}}$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{${ran1}+\\sqrt{${base}}}{${ran2}+\\sqrt{${base}}}\\times \\frac{${ran2}-\\sqrt{${base}}}{${ran2}-\\sqrt{${base}}}$`
      );
      question.addParagraph(
        "solution",
        `From a), we know the numerator $=${ai_answer}+${aii_answer}\\sqrt{${base}}$`
      );
      question.addParagraph(
        "solution",
        `$(${ran2}+\\sqrt{${base}})(${ran2}-\\sqrt{${base}}) = ${ran2}^2 - ${base}$`
      );
      question.addParagraph("solution", `$= ${ran2 * ran2 - base}$`);
      question.addParagraph(
        "solution",
        `$= \\frac{${ai_answer}+${aii_answer}\\sqrt{${base}}}{${
          ran2 * ran2 - base
        }}$`
      );
      question.addParagraph(
        "solution",
        `$= ${ai_answer / (ran2 * ran2 - base)}+${
          aii_answer / (ran2 * ran2 - base)
        }\\sqrt{${base}}$`
      );
      question.addParagraph("solution", `$\\therefore p = ${bi_answer}$`);
      question.addParagraph("solution", `$\\therefore q = ${bii_answer}$`);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(ai_answer, 0, "a) $\\ a=$");
      group1.addInput(aii_answer, 0, "a) $\\ b=$");
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(bi_answer, 0, "b) $\\ p=$");
      group2.addInput(bii_answer, 0, "b) $\\ q=$");
      question.addInputGroup(group1);
      question.addInputGroup(group2); // done
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_Exam_Q7() {
  let count = 0;
  while (true) {
    const base = MCRandom.randomInt(2, 7);
    const ran1 = MCRandom.randomInt(2, 7);
    const ran2 = MCRandom.randomInt(3, 7);
    const a_answer = ran1;
    const bi_answer = ran2 * ran2 - base;
    const bii_answer = -2 * ran2;

    if (true && base !== 4) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) $\\sqrt{${
          base * ran1 ** 2
        }}$ can be written as $a\\sqrt{${base}}$. Find $a$`
      );
      question.addParagraph(
        "question",
        `b) Find the values of $p$ and $q$ given $(${ran2}-\\sqrt{${base}})^2$ can be written as $p$ + $q$$\\sqrt{${base}}$`
      );
      question.addHeading(
        "solution",
        `a) $\\sqrt{${
          base * ran1 ** 2
        }}$ can be written as $a\\sqrt{${base}}$. Find $a$`
      );
      question.addParagraph(
        "solution",
        `$\\sqrt{${base * ran1 ** 2}} = \\sqrt{${base}} \\times \\sqrt{${
          ran1 ** 2
        }}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sqrt{${base * ran1 ** 2}} = ${ran1}\\sqrt{${base}}$`
      );
      question.addParagraph("solution", `$\\therefore a = ${a_answer}$`);
      question.addHeading(
        "solution",
        `b) Find the values of $p$ and $q$ given $(${ran2}-\\sqrt{${base}})^2$ can be written as $p$ + $q$$\\sqrt{${base}}$`
      );
      question.addParagraph(
        "solution",
        `We can expand this to give $${
          ran2 * ran2
        }-${ran2}\\sqrt{${base}} -${ran2}\\sqrt{${base}} + ${base}$`
      );
      question.addParagraph(
        "solution",
        `$= ${ran2 * ran2 - base}-${2 * ran2}\\sqrt{${base}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore p = ${ran2 * ran2 - base}$`
      );
      question.addParagraph("solution", `$\\therefore q = ${-2 * ran2}$`);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(a_answer, 0, "a) $\\ $");
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(bi_answer, 0, "b) $\\ p=$");
      group2.addInput(bii_answer, 0, "b) $\\ q=$");
      question.addInputGroup(group1);
      question.addInputGroup(group2); // done

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_Exam_Q8() {
  let count = 0;
  while (true) {
    const coef = MCRandom.randomInt(2, 4);
    const base = MCRandom.randomInt(2, 6);
    const ran1 = MCRandom.randomInt(2, 6);
    const ran2 = MCRandom.randomInt(2, 6);
    const ai_answer = coef * (ran1 * ran2 - base);
    const aii_answer = coef * (ran2 - ran1);

    if (true && aii_answer !== 0 && aii_answer > 0 && base !== 4) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Expand and simplify $${coef}(${ran1}+\\sqrt{${base}})(${ran2}-\\sqrt{${base}})$ to the form $a + b\\sqrt{${base}}$`
      );
      question.addHeading(
        "solution",
        `a) Expand and simplify $${coef}(${ran1}+\\sqrt{${base}})(${ran2}-\\sqrt{${base}})$ to the form $a + b\\sqrt{${base}}$`
      );
      question.addParagraph(
        "solution",
        `Expanding this we get $${coef}(${
          ran1 * ran2
        }+ ${ran2}\\sqrt{${base}}-${ran1}\\sqrt{${base}}-${base})$`
      );
      question.addParagraph(
        "solution",
        `$= ${ai_answer}+${coef * (ran2 - ran1)}\\sqrt{${base}}$`
      );
      question.addParagraph("solution", `$\\therefore a =  ${ai_answer}$`);
      question.addParagraph("solution", `$\\therefore b =  ${aii_answer}$`);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(ai_answer, 0, "a) $\\ a= $");
      group1.addInput(aii_answer, 0, "a) $\\ b= $");
      question.addInputGroup(group1); // done
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_Exam_Q9() {
  let count = 0;
  while (true) {
    const int = MCRandom.randomInt(2, 50);
    const ran1 = MCRandom.randomInt(3, 7);
    const ran2 = MCRandom.randomInt(2, 7);
    const a_answer = ran1 * ran1 - ran2;
    const bi_answer = int / a_answer;
    const bii_answer = (int / a_answer) * ran1;

    if (true && ran2 !== 4 && int % a_answer === 0 && bii_answer !== 0) {
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `a) State the value of $ (${ran1}+\\sqrt{${ran2}})(${ran1}-\\sqrt{${ran2}})$`,
        `b) Hence simplify and state the value of $\\frac{${int}}{${ran1} + \\sqrt{${ran2}}}$ in the form $p - q\\sqrt{${ran2}}$`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) State the value of $ (${ran1}+\\sqrt{${ran2}})(${ran1}-\\sqrt{${ran2}})$`,
        `We can expand this to get $${ran1}^{2} + ${ran1}\\sqrt{${ran2}} - ${ran1}\\sqrt{${ran2}} - (\\sqrt{${ran2}})^2$`,
        `$ = ${ran1 ** 2}-${ran2}$`,
        `$\\therefore (${ran1}+\\sqrt{${ran2}})(${ran1}-\\sqrt{${ran2}}) = ${a_answer}$`,

        `HEADING b) Hence simplify and state the value of $\\frac{${int}}{${ran1} + \\sqrt{${ran2}}}$ in the form $p - q\\sqrt{${ran2}}$`,
        `From a), we know $(${ran1}+\\sqrt{${ran2}})(${ran1}-\\sqrt{${ran2}}) = ${a_answer}$`,
        `$\\therefore \\frac{${int}}{(${ran1}+\\sqrt{${ran2}})} \\times \\frac{(${ran1}-\\sqrt{${ran2}})}{(${ran1}-\\sqrt{${ran2}})} = \\frac{(${int})(${ran1}-\\sqrt{${ran2}})}{${a_answer}}$`,
        `$ = (${int / a_answer})(${ran1}-\\sqrt{${ran2}})$`,
        `$ = ${(int / a_answer) * ran1}-${int / a_answer}\\sqrt{${ran2}}$`,
        `$\\therefore p = ${bii_answer}$`,
        `$\\therefore q = ${bi_answer}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(a_answer, 0, "a) $\\ $");
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(bi_answer, 0, "b) $\\ p= $");
      group2.addInput(bii_answer, 0, "b) $\\ q= $");
      question.addInputGroup(group1);
      question.addInputGroup(group2); // done
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_MCQ_Q1() {
  let count = 0;
  while (true) {
    const ran1 = MCRandom.randomInt(1, 7);
    const ran2 = MCRandom.randomInt(2, 7);

    if (true && ran2 !== 4) {
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        `State the value of $(${ran1}+\\sqrt{${ran2}})(${ran1}-\\sqrt{${ran2}})$`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$${ran1 * ran1 + ran2}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$${ran1 * ran1 - ran2}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$${ran2 * ran2 - ran1}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${ran2 * ran2 + ran1}$`);

      question.addAnswer(a1, false);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_MCQ_Q2() {
  let count = 0;
  while (true) {
    const ran1 = MCRandom.randomInt(2, 7);
    const ran2 = MCRandom.randomInt(2, 7);

    if (true) {
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(`State the value of $(${ran1}\\sqrt{${ran2}})^2$`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$${ran1 * ran2}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$${ran1 * ran1 * ran2}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$${ran2 * ran2 * ran1}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${ran2 * ran2 * ran1 * ran1}$`);

      question.addAnswer(a1, false);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_MCQ_Q3() {
  let count = 0;
  while (true) {
    const ran1 = MCRandom.randomInt(2, 6);
    const ran2 = MCRandom.randomInt(1, 3);
    const ran3 = MCRandom.randomInt(2, 6);

    if (
      true &&
      ran3 !== 4 &&
      ran1 % (ran2 * ran2 - ran3) === 0 &&
      ran1 / (ran2 * ran2 - ran3) !== (ran1 * ran2) / (ran2 * ran2 - ran3)
    ) {
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        `$\\frac{${ran1}}{${ran2}+\\sqrt{${ran3}}}$ can be written as $a + b\\sqrt{${ran3}}$ State the value of $b$`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$${ran1 / (ran2 * ran2 - ran3)}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$${-ran1 / (ran2 * ran2 - ran3)}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$${(ran1 * ran2) / (ran2 * ran2 - ran3)}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${(-ran1 * ran2) / (ran2 * ran2 - ran3)}$`);

      question.addAnswer(a1, false);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_MCQ_Q4() {
  let count = 0;
  while (true) {
    const ran1 = MCRandom.randomInt(2, 6);
    const ran2 = MCRandom.randomInt(2, 6);
    const ran3 = MCRandom.randomInt(2, 6);

    if (
      true &&
      ran2 !== 4 &&
      ran1 !== ran3 &&
      ran1 * ran3 - ran3 !== ran1 * ran3 - ran2 &&
      ran1 * ran3 - ran3 !== ran2 * ran3 - ran2 &&
      ran1 * ran3 - ran3 !== ran2 * ran1 - ran2 &&
      ran1 * ran3 - ran2 !== ran2 * ran3 - ran2 &&
      ran1 * ran3 - ran2 !== ran2 * ran1 - ran2 &&
      ran2 * ran3 - ran2 !== ran1 * ran2 - ran2
    ) {
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        `$(${ran1} + \\sqrt{${ran2}})(${ran3}-\\sqrt{${ran2}})$ can be written as $a + b\\sqrt{${ran2}}$. State the value of $a$`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$${ran1 * ran3 - ran3}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$${ran1 * ran3 - ran2}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$${ran2 * ran3 - ran2}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${ran1 * ran2 - ran2}$`);

      question.addAnswer(a1, false);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_MCQ_Q5() {
  let count = 0;
  while (true) {
    const ran1 = MCRandom.randomInt(2, 5);
    const ran2 = MCRandom.randomInt(2, 3);

    if (true && ran1 !== 4) {
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        `State the value of $a$ where $\\sqrt{${
          ran1 * ran2 ** 2
        }} = a\\sqrt{${ran1}}$`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$${-ran2}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$${ran2}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$${ran2 + 1}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${-(ran2 + 1)}$`);

      question.addAnswer(a1, false);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Surds_Skills_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const alphabet = ["a", "b", "c", "d"];
    const jTypes = [2, 3, 5, 10];
    const iTypes = [2, 3, 5, 6, 7, 10];
    const questionTypes = [
      `Write $\\sqrt{IIJ}$ in the form $p\\sqrt{q}$`,
      `Write $\\frac{J}{\\sqrt{I}}$ in the form $\\frac{p\\sqrt{q}}{I}$`,
      `Write $\\frac{1}{I+\\sqrt{J}}$ in the form $\\frac{p-\\sqrt{J}}{q}$`,
    ];
    const answerTypes = [
      ["I", "J"],
      ["J", "I"],
      ["I", "II-J"],
    ];
    const solutionTypes = [
      [
        `$=\\sqrt{II\\times J}$`,
        `$= \\sqrt{II}\\times \\sqrt{J} $`,
        `$= I\\sqrt{J}$`,
      ],
      [
        `Rationalising: `,
        `$=\\frac{J}{\\sqrt{I}}\\times \\frac{\\sqrt{I}}{\\sqrt{I}}$`,
        `$=\\frac{J\\sqrt{I}}{I}$`,
      ],
      [
        `Rationalising: `,
        `$=\\frac{1}{I+\\sqrt{J}}\\times \\frac{I-\\sqrt{J}}{I-\\sqrt{J}}$`,
        `$=\\frac{I-\\sqrt{J}}{II - J}$`,
        `$=\\frac{I-\\sqrt{J}}{II-J}$`,
      ],
    ];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.SkillsQuestion(2);

      const parts = [
        new MCQuestion.SkillsPart(),
        new MCQuestion.SkillsPart(),
        new MCQuestion.SkillsPart(),
        new MCQuestion.SkillsPart(),
      ];

      for (let p = 0; p <= 3; p += 1) {
        const type = MCRandom.randomInt(0, questionTypes.length - 1);
        const i = iTypes[MCRandom.randomInt(0, iTypes.length - 1)];
        const j = jTypes[MCRandom.randomInt(0, jTypes.length - 1)];
        parts[p].addParagraph(
          "question",
          `${MCMaths.cleaner(
            questionTypes[type]
              .replace(/IIJ/g, i * i * j)
              .replace(/I/g, i)
              .replace(/J/g, j)
          )}`
        );
        parts[p].addAnswer(
          Number(answerTypes[type][0].replace("I", i).replace("J", j)),
          0,
          `${alphabet[p]}) $\\ p = $ `
        );
        parts[p].addAnswer(
          Number(
            answerTypes[type][1]
              .replace("I", i)
              .replace("J", j)
              .replace("II-J", i * i - j)
          ),
          0,
          `${alphabet[p]}) $\\ q = $ `
        );
        for (let o = 0; o < solutionTypes[type].length; o += 1) {
          parts[p].addParagraph(
            "solution",
            `${MCMaths.cleaner(
              solutionTypes[type][o]
                .replace("II-J", i * i - j)
                .replace("Q", alphabet[p])
                .replace(/II/g, i * i)
                .replace(/I/g, i)
                .replace(/J/g, j)
            )}`
          );
        }
      }
      question.addPart(parts[0]);
      question.addPart(parts[1]);
      question.addPart(parts[2]);
      question.addPart(parts[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Transformations_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const funcs = [
      new MCMaths.Trig(1, "sin"),
      new MCMaths.Trig(1, "cos"),
      new MCMaths.Trig(1, "tan"),
      new MCMaths.Exponential(),
      new MCMaths.Logarithm(),
      new MCMaths.Polynomial([1, 0, 0]),
      new MCMaths.Polynomial([1, 0, 0, 0]),
    ];
    const x = funcs[MCRandom.randomInt(0, funcs.length - 1)];
    const qs = [
      `$y = ${x.toString()}+J$`,
      `$y = ${x.toString().replace(/x/g, "(x+J)")}$`,
      `$y = J${x.toString()}$`,
      `$y = ${x.toString().replace(/x/g, "(Jx)")}$`,
      `$y = -${x.toString()}$`,
      `$y = ${x.toString().replace(/x/g, "(-x)")}$`,
    ];
    const as = [
      `A translation by $\\begin{pmatrix} 0 \\\\ J \\end{pmatrix}$.`,
      `A translation by $\\begin{pmatrix} -J \\\\ 0 \\end{pmatrix}$.`,
      `A stretch in $y$-direction with scale factor $J$.`,
      `A stretch in $x$-direction with scale factor $\\displaystyle\\frac{1}{J}$.`,
      `A reflection in the $x$ axis.`,
      `A reflection in the $y$ axis.`,
    ];
    const x1 = MCRandom.randomInt(2, 7);
    const x2 = MCRandom.randomInt(2, 7);
    const x3 = MCRandom.randomInt(2, 7);

    const type1 = MCRandom.randomInt(0, qs.length - 1);
    const type2 = MCRandom.randomInt(0, qs.length - 1);
    const type3 = MCRandom.randomInt(0, qs.length - 1);

    const q1 = MCMaths.cleaner(
      qs[type1]
        .replace(/J/g, x1)
        .replace("((", "(")
        .replace(")\\right)", "\\right)")
    );
    const q2 = MCMaths.cleaner(
      qs[type2]
        .replace(/J/g, x2)
        .replace("((", "(")
        .replace(")\\right)", "\\right)")
    );
    const q3 = MCMaths.cleaner(
      qs[type3]
        .replace(/J/g, x3)
        .replace("((", "(")
        .replace(")\\right)", "\\right)")
    );

    const a1 = MCMaths.cleaner(as[type1].replace(/J/g, x1)).replace("+", "");
    const a2 = MCMaths.cleaner(as[type2].replace(/J/g, x2)).replace("+", "");
    const a3 = MCMaths.cleaner(as[type3].replace(/J/g, x3)).replace("+", "");
    /* END VARIABLES AND CONSTANTS */
    if (
      type1 !== type2 &&
      type2 !== type3 &&
      type3 !== type1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Describe the transformation that maps $y=${x.toString()}$ to:`,
        `a) ${q1}`,
        `b) ${q2}`,
        `c) ${q3}`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) ${a1}`,
        `b) ${a2}`,
        `c) ${a3}`,
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_AlgebraAndFunctions_Transformations_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const funcs = [
      new MCMaths.Trig(1, "sin"),
      new MCMaths.Trig(1, "cos"),
      new MCMaths.Exponential(),
      new MCMaths.Logarithm(),
      new MCMaths.Polynomial([1, 0, 0]),
      new MCMaths.Polynomial([1, 0, 0, 0]),
    ];
    const pSize = 10;
    const ranges = [
      [2 * Math.PI, -2 * Math.PI, 6, -6, Math.PI / 2, 1],
      [2 * Math.PI, -2 * Math.PI, 6, -6, Math.PI / 2, 1],
      [pSize, -pSize, pSize, -pSize, 2, 2],
      [pSize, -pSize, pSize, -pSize, 2, 2],
      [pSize, -pSize, pSize, -pSize, 2, 2],
      [pSize, -pSize, pSize, -pSize, 2, 2],
    ];
    const fType = MCRandom.randomInt(0, funcs.length - 1);
    const r = ranges[fType];
    const x = funcs[fType];
    const qs = [
      `$y = ${x.toString()}+J$`,
      `$y = ${x.toString().replace(/x/g, "(x+J)")}$`,
      `$y = J${x.toString()}$`,
      `$y = ${x.toString().replace(/x/g, "(Jx)")}$`,
      `$y = -${x.toString()}$`,
      `$y = ${x.toString().replace(/x/g, "(-x)")}$`,
    ];
    const as = [
      `A translation by $\\begin{pmatrix} 0 \\\\ J \\end{pmatrix}$.`,
      `A translation by $\\begin{pmatrix} -J \\\\ 0 \\end{pmatrix}$.`,
      `A stretch in $y$-direction with scale factor $J$.`,
      `A stretch in $x$-direction with scale factor $\\displaystyle\\frac{1}{J}$.`,
      `A reflection in the $x$ axis.`,
      `A reflection in the $y$ axis.`,
    ];
    const x1 = MCRandom.randomInt(2, 3);
    const type1 = MCRandom.randomInt(0, qs.length - 1);
    const q1 = MCMaths.cleaner(
      qs[type1]
        .replace(/J/g, x1)
        .replace("((", "(")
        .replace(")\\right)", "\\right)")
    );
    const a1 = MCMaths.cleaner(as[type1].replace(/J/g, x1)).replace("+", "");
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `a) Describe the transformation that maps $y=${x.toString()}$ to ${q1}.`,
        `b) Sketch the graphs of $${x.toString()}$ and ${q1} on the same axis.`,
      ]);
      question.addMultipleParagraphs("solution", [`a) ${a1}`, `b) `]);
      const g = new MCQuestion.Graph(...r);

      let f2;
      if (type1 === 0) {
        f2 = `${x.functionString()}+${x1}`;
      }
      if (type1 === 1) {
        f2 = x.functionString().replace(/x/g, `(x+${x1})`);
      }
      if (type1 === 2) {
        f2 = `${x1} * (${x.functionString()})`;
      }
      if (type1 === 3) {
        f2 = x.functionString().replace(/x/g, `(x*${x1})`);
      }
      if (type1 === 4) {
        f2 = `-1 * (${x.functionString()})`;
      }
      if (type1 === 5) {
        f2 = x.functionString().replace(/x/g, `(-1*x)`);
      }

      g.plot(x.functionString(), r[1], r[0]);
      g.plot(f2, r[1], r[0]);

      question.addGraph("solution", g);

      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVAT_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const s = MCRandom.randomInt(20, 50);
    const u = 0;
    const a = 9.8;
    const vSquared = 2 * a * s;
    const v = Math.sqrt(vSquared);
    const t = v / a;

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A tennis ball is dropped from a height of $${s}m$.`
      );
      question.addParagraph(
        "question",
        `a) Find the speed of the ball just before it hits the ground.`
      );
      question.addParagraph(
        "question",
        `b) Find how long it takes for the ball to reach the ground.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures where appropriate.`
      );

      question.addHeading(
        "solution",
        `a) Using the SUVAT equation $v^2 = u^2 + 2as$`
      );
      question.addParagraph("solution", `$v^2 = (0)^2 + 2(${a})(${s})$`);
      question.addParagraph(
        "solution",
        `$v = \\sqrt{${vSquared.toPrecision(4)}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore v = ${v.toPrecision(3)}ms^{-1}$`
      );

      question.addHeading(
        "solution",
        `b) Rearranging the SUVAT equation $v = u + at$ we obtain:`
      );
      question.addParagraph("solution", `$\\displaystyle t = \\frac{v-u}{a}$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle t = \\frac{\\sqrt{${vSquared.toPrecision(4)}}}{${a}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore t = ${t.toPrecision(3)}s$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(parseFloat(v.toPrecision(3)), 0, `a) $\\text{ } v =$`);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(parseFloat(t.toPrecision(3)), 0, `b) $\\text{ } t =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVAT_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const u = MCRandom.randomInt(25, 35);
    const v = 0;
    const t = MCRandom.randomInt(4, 10);

    const s = new MCMaths.Fraction((u * t) / 2);
    const a = new MCMaths.Fraction(-u / t);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A car is travelling on the motorway and in order to avoid a crash must perform an emergency-stop.`
      );
      question.addParagraph(
        "question",
        `The car was initially travelling at a speed of $${u}ms^{-1}$ and it took the car $${t}s$ to come to a stop.`
      );
      question.addParagraph(
        "question",
        `a) How far does the car travel between the start of the emergency-stop and when the car came to a stop.`
      );
      question.addParagraph(
        "question",
        `b) Calculate the acceleration of the car.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures where appropriate.`
      );

      question.addHeading(
        "solution",
        `a) Using the SUVAT equation $s = \\frac{1}{2}(u+v)t$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle s = \\frac{(${u})(${t})}{2}$`
      );
      question.addParagraph("solution", `$\\displaystyle s = ${s.toString()}$`);
      question.addParagraph("solution", `$s = ${s.toFloat().toPrecision(3)}m$`);

      question.addHeading(
        "solution",
        `b) Rearranging the SUVAT equation $v = u + at$ we obtain:`
      );
      question.addParagraph("solution", `$\\displaystyle a = \\frac{v-u}{t}$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle a = \\frac{${v}-${u}}{${t}}$`
      );
      question.addParagraph("solution", `$\\displaystyle a = ${a.toString()}$`);
      question.addParagraph(
        "solution",
        `$\\therefore a = ${a.toFloat().toPrecision(3)}ms^{-2}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(s.toFloat().toPrecision(3)),
        0,
        `a) $\\text{ } s =$`
      );
      group2.addInput(
        parseFloat(a.toFloat().toPrecision(3)),
        0,
        `b) $\\text{ } a =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVAT_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const initialAltitude = MCRandom.randomInt(9, 18);
    const finalAltitude = initialAltitude - MCRandom.randomInt(1, 20) / 10;
    const s = (initialAltitude - finalAltitude) * 1000;
    const u = MCRandom.randomInt(200, 400);
    const a = 9.8;
    const vSquared = u ** 2 + 2 * a * s;
    const v = Math.sqrt(vSquared);
    const t = (v - u) / a;

    /* END VARIABLES AND CONSTANTS */
    if (t > 2 && t < 20) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A fighter jet is initally flying at an altitude of $${initialAltitude}km$ and has a vertical speed of $${u}ms^{-1}$.`
      );
      question.addParagraph(
        "question",
        `The fighter jet then cuts its engines so that the only force acting is gravity and dives down to an altitude of $${finalAltitude}km$.`
      );
      question.addParagraph(
        "question",
        `a) Ignoring air resistance, find the vertical speed of the fighter jet when it reaches the altitude of $${finalAltitude}km$`
      );
      question.addParagraph(
        "question",
        `b) Find the time it took to dive from the initial altitude to its final altitude.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addHeading(
        "solution",
        `a) Using the SUVAT equation $v^2 = u^2 + 2as$ we obtain:`
      );
      question.addParagraph(
        "solution",
        `$v^2 = ${u}^2 + 2(${a})[(${initialAltitude}-${finalAltitude}) \\times 1000]$`
      );
      question.addParagraph(
        "solution",
        `$v = \\sqrt{${u}^2 + 2(${a})(${s.toFixed(0)})}$`
      );
      question.addParagraph("solution", `$v = \\sqrt{${vSquared.toFixed(0)}}$`);
      question.addParagraph(
        "solution",
        `$\\therefore v = ${v.toPrecision(3)}ms^{-1}$.`
      );

      question.addHeading(
        "solution",
        `b) Rearranging the SUVAT equation $v = u + at$ we obtain:`
      );
      question.addParagraph("solution", `$\\displaystyle t = \\frac{v-u}{a}$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle t = \\frac{\\sqrt{${vSquared.toFixed(
          0
        )}} - ${u}}{${a}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore t = ${t.toPrecision(3)}s$.`
      );

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(parseFloat(v.toPrecision(3)), 0, `a) $\\text{ } v =$`);
      group2.addInput(parseFloat(t.toPrecision(3)), 0, `b) $\\text{ } t =$`);
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVAT_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const initialHeight = MCRandom.randomInt(10, 20) / 10;
    const u = MCRandom.randomInt(20, 35);
    const v = 0;
    const a = -9.8;
    const s = (v ** 2 - u ** 2) / (2 * a);
    const maxHeight = s + initialHeight;
    const maxSpeed = Math.sqrt(2 * 9.8 * parseFloat(maxHeight.toPrecision(3)));

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A tennis ball is shot out of of a tennis ball launcher vertically upwards at a speed of $${u}ms^{-1}$.`
      );
      question.addParagraph(
        "question",
        `When the tennis ball leaves the launcher it is already at a height of $${initialHeight}m$`
      );
      question.addParagraph(
        "question",
        `a) Find the maximum height, $H$, that the tennis ball reaches.`
      );
      question.addParagraph(
        "question",
        `b) Find the speed, $v_1$, that tennis ball reaches just before it hits the ground.`
      );

      question.addHeading(
        "solution",
        `a) Rearranging the SUVAT equation $v^2 = u^2 + 2as$ we obtain:`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle s = \\frac{v^{2} - u^{2}}{2a}$`
      );
      question.addParagraph(
        "solution",
        `When the tennis ball reaches its maximum height it will have no speed therefore we know that $v = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\implies s = \\frac{-${u}^{2}}{2\\times${a}}$`
      );
      question.addParagraph("solution", `$s = ${s.toPrecision(3)}m$`);
      question.addParagraph(
        "solution",
        `Since the tennis ball was initially launched from a height of $${initialHeight}m$:`
      );
      question.addParagraph(
        "solution",
        `$H = ${initialHeight} + ${s.toPrecision(3)}$`
      );
      question.addParagraph("solution", `$H = ${maxHeight.toPrecision(3)}m$.`);

      question.addHeading(
        "solution",
        `b) Again using the SUVAT equation $v^2 = u^2 + 2as$:`
      );
      question.addParagraph(
        "solution",
        `We know that $u = 0, \\text{ } a = 9.8, \\text{ } s = ${maxHeight.toPrecision(
          3
        )}$`
      );
      question.addParagraph(
        "solution",
        `$\\implies v^2 = 2(9.8)(${maxHeight.toPrecision(3)})$`
      );
      question.addParagraph(
        "solution",
        `$v = \\sqrt{2(9.8)(${maxHeight.toPrecision(3)})}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore v_1 = ${maxSpeed.toPrecision(3)}ms^{-1}$.`
      );

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(maxHeight.toPrecision(3)),
        0,
        "a) $\\text{ } H =$"
      );
      group2.addInput(
        parseFloat(maxSpeed.toPrecision(3)),
        0,
        "b) $\\text{ } v_1 =$"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVAT_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const u = 0;
    const a = MCRandom.randomInt(2, 9);
    const angle = MCRandom.randomInt(30, 60);
    const adj = MCRandom.randomInt(5, 15);
    const s = adj / Math.cos(angle * (Math.PI / 180));
    const vSquared = u ** 2 + 2 * a * s;
    const v = Math.sqrt(vSquared);
    const t = (v - u) / a;

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A ball is released from the top of a smooth inclined plane at $${angle}^{\\circ}$ to the horizontal and travels with an acceleration of $${a}ms^{-2}$.`
      );
      question.addParagraph(
        "question",
        `The base width of the plane is $${adj}m$.`
      );
      question.addParagraph(
        "question",
        `a) Find the speed at which the ball reaches the bottom of the inclined plane.`
      );
      question.addParagraph(
        "question",
        `b) Find the time it took for the ball to travel down the inclined plane.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addHeading(
        "solution",
        `a) Using the SUVAT equation $v^2 = u^2 + 2as$ and since $u = 0$ we obtain:`
      );
      question.addParagraph("solution", `$v^2 = 2as$`);
      question.addParagraph(
        "solution",
        `In order to find $s$ we must use a bit of trigonometry:`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos(${angle}^\\circ) = \\frac{${adj}}{s}$`
      );
      question.addParagraph(
        "solution",
        `$\\implies \\displaystyle s = \\frac{${adj}}{\\cos(${angle}^\\circ)}$`
      );
      question.addParagraph("solution", `$= ${s.toFixed(3)}...$`);
      question.addParagraph(
        "solution",
        `So $v^2 = 2(${a})(${s.toFixed(3)}...)$`
      );
      question.addParagraph(
        "solution",
        `$v = \\sqrt{${vSquared.toPrecision(3)}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore v = ${v.toPrecision(3)}ms^{-1}$.`
      );

      question.addHeading(
        "solution",
        `Rearranging the SUVAT equation $v = u + at$ we obtain:`
      );
      question.addParagraph("solution", `$\\displaystyle t = \\frac{v-u}{a}$`);
      question.addParagraph("solution", `Since $u = 0$:`);
      question.addParagraph(
        "solution",
        `$\\displaystyle t = \\frac{\\sqrt{${vSquared.toPrecision(3)}}}{${a}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore t = ${t.toPrecision(3)}s$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(parseFloat(v.toPrecision(3)), 0, `a) $\\text{ } v =$`);
      group2.addInput(parseFloat(t.toPrecision(3)), 0, `b) $\\text{ } t =$`);
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVAT_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const u = MCRandom.randomInt(20, 45);
    const v = u - MCRandom.randomInt(5, 15);
    const s = MCRandom.randomInt(300, 400);
    const a = (v ** 2 - u ** 2) / (2 * s);
    const t = (v - u) / a;

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A train is travelling on a track and is informed to reduce speed from $${u}ms^{-1}$ to $${v}ms^{-1}$`
      );
      question.addParagraph(
        "question",
        `The train does so within $${s}m$ of track.`
      );
      question.addParagraph(
        "question",
        `Given that the train declerates uniformly,`
      );
      question.addParagraph(
        "question",
        `a) Calculate the deceleration of the train.`
      );
      question.addParagraph(
        "question",
        `b) Calculate the time taken to reduce the trains speed.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addHeading(
        "solution",
        `a) Rearranging the SUVAT equation $v^2 = u^2 + 2as$ we obtain:`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle a = \\frac{v^2 - u^2}{2s}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle a = \\frac{${v}^2 - ${u}^2}{2(${s})}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore a = ${a.toPrecision(3)}ms^{-2}$`
      );

      question.addHeading(
        "solution",
        `b) Rearranging the SUVAT equation $v = u + at$ we obtain:`
      );
      question.addParagraph("solution", `$\\displaystyle t = \\frac{v-u}{a}$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle t = \\frac{${v}-${u}}{${a.toPrecision(3)}}$`
      );
      question.addParagraph("solution", `$t = ${t.toPrecision(3)}s$`);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(parseFloat(a.toPrecision(3)), 0, `a) $\\text{ } a =$`);
      group2.addInput(parseFloat(t.toPrecision(3)), 0, `b) $\\text{ } t =$`);
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVAT_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(2, 9);
    const t = MCRandom.randomInt(2, 9);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Given that a particle is uniformly accelerated from rest at an acceleration of $${a}ms^{-2}$ over $${t}s$.`
      );
      question.addParagraph(`Choose the correct final velocity:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$v = ${a * t}ms^{-2}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$v = ${-a * t}ms^{-2}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$v = ${a * a * t}ms^{-2}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$v = ${-a * a * t}ms^{-2}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVAT_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(2, 9);
    const t = MCRandom.randomInt(2, 9);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Given that a particle is uniformly accelerated from rest at an acceleration of $${a}ms^{-2}$ over $${t}s$.`
      );
      question.addParagraph(`Choose the correct distance travelled:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$s = ${0.5 * a * t * t}m$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$s = ${a * t * t}m$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$s = ${a * t}m$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$s = ${-0.5 * a * t * t}m$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVAT_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(2, 9);
    const s = MCRandom.randomInt(2, 9);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Given that a particle is uniformly accelerated from rest at an acceleration of $${a}ms^{-2}$ over a distance of $${s}m$.`
      );
      question.addParagraph(`Choose the correct final velocity:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$v = \\sqrt{${2 * a * s}}ms^{-1}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$v = ${2 * a * s}ms^{-1}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$v = \\sqrt{${a * s}}ms^{-1}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$v = \\sqrt{${0.5 * a * s}}ms^{-1}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVAT_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const v = MCRandom.randomInt(2, 9);
    const t = MCRandom.randomInt(2, 9);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Given that a particle is uniformly accelerated from rest to a speed of $${v}ms^{-1}$ over a time of $${t}s$.`
      );
      question.addParagraph(`Choose the correct distance travelled:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$s = ${0.5 * v * t}m$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$s = ${v * t}m$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$s = ${2 * v * t}m$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$s = ${-0.5 * v * t}m$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVAT_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const u = MCRandom.randomInt(2, 9);
    const v = MCRandom.randomInt(2, 18);
    const a = MCRandom.randomInt(2, 9);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (v > u) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Given that a particle is uniformly accelerated from $${u}ms^{-1}$ to a speed of $${v}ms^{-1}$ with acceleration $${a}ms^{-1}$.`
      );
      question.addParagraph(`Choose the correct time elapsed:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$t = ${((v - u) / a).toPrecision(3)}s$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$t = ${(v / a).toPrecision(3)}s$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$t = ${(-u / a).toPrecision(3)}s$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$t = ${((v + u) / a).toPrecision(3)}s$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVAT_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const s = MCRandom.randomInt(2, 9);
    const a = 9.8;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(`A ball is dropped from a height of $${s}m$.`);
      question.addParagraph(`Given that $g = 9.8ms^{-2}$.`);
      question.addParagraph(
        `Choose the correct speed of the ball just before it hits the ground:`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$v = \\sqrt{${(2 * a * s).toFixed(1)}}ms^{-1}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$v = \\sqrt{${(a * s).toFixed(1)}}ms^{-1}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$v = \\sqrt{${(0.5 * a * s).toFixed(1)}}ms^{-1}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$v = ${(2 * a * s).toFixed(1)}ms^{-1}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVATGraphs_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const person = MCMisc.getName();

    const a1 = MCRandom.randomInt(2, 5);
    const a2 = 0;
    const a3 = -MCRandom.randomInt(2, 5);

    const t1 = MCRandom.randomInt(2, 9);
    const t2 = MCRandom.randomInt(2, 9);
    const t3 = MCRandom.randomInt(2, 9);

    const v1 = 0;
    const v2 = a1 * t1;
    const v3 = 0;

    const f1 = (x) => {
      return a1 * x;
    };

    const f2 = (x) => {
      return v2;
    };

    const f3 = (x) => {
      return a3 * (x - (t1 + t2)) + v2;
    };

    let x_stepSize = 1;
    let y_stepSize = 1;
    if (v2 > 15) {
      y_stepSize = 2;
    }
    if (t1 + t2 + t3 > 15) {
      x_stepSize = 2;
    }

    const s = 0.5 * t1 * v2 + t2 * v2 + 0.5 * t3 * v2;

    /* END VARIABLES AND CONSTANTS */
    if (f3(t1 + t2 + t3) === 0 && v2 < 30 && v2 > 9) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `${person.name} is travelling by car. ${person.HeShe} starts at rest and accelerates uniformly to a speed of $${v2}ms^{-1}$ over $${t1}s$.`,
        `${person.name} stops accelerating for $${t2}s$ and then decelerates at a constant rate back to rest over $${t3}s$.`,
        `This is shown below by the velocity-time graph:`,
        `a) Find the acceleration, $a_1$, of the first $${t1}s$ of the journey.`,
        `b) Find the deceleration, $a_2$, of the last $${t3}s$ of the journey.`,
        `c) Find the total distance travelled, $s_1$, over the entire journey.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Since acceleration is the gradient of the graph for the first $${t1}s$:`,
        `$\\displaystyle a = \\frac{${v2}}{${t1}}$`,
        `$\\therefore a = ${a1}ms^{-2}$.`,

        `HEADING b) Similarly as part a) deceleration is the gradient for the last $${t3}s$:`,
        `$\\displaystyle a = \\frac{${v2}}{${t3}}$`,
        `$\\therefore a = ${a3}ms^{-2}$.`,

        `HEADING c) Total distance travelled is equal to the area under the graph so:`,
        `$\\displaystyle s = \\left(\\frac{${t1}\\times${v2}}{2}\\right) + \\left(${t2}\\times${v2}\\right) + \\left(\\frac{${t3}\\times${v2}}{2}\\right)$`,
        `$\\displaystyle = ${s}$`,
        `$\\therefore s_1 = ${s}m$.`,
      ]);

      const myGraph = new MCQuestion.Graph(
        t1 + t2 + t3,
        -2,
        v2 + 5,
        -2,
        x_stepSize,
        y_stepSize
      );
      myGraph.plot(f1, 0, t1);
      myGraph.plot(f2, t1, t1 + t2);
      myGraph.plot(f3, t1 + t2, t1 + t2 + t3);
      question.addGraph("question", myGraph);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      const group3 = new MCQuestion.InputGroup(1);

      group1.addInput(a1, 0, `a) $\\text{ } a_1 =$`);
      group1.addInput(a2, 0, `b) $\\text{ } a_2 =$`);
      group1.addInput(s, 0, `c) $\\text{ } s_1 =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVATGraphs_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const person = MCMisc.getName();

    const a1 = MCRandom.randomInt(1, 3);
    const a2 = 0;
    const a3 = -MCRandom.randomInt(1, 3);

    const t1 = MCRandom.randomInt(2, 9);
    const t2 = MCRandom.randomInt(2, 9);
    const t3 = MCRandom.randomInt(2, 9);

    const v1 = 0;
    const v2 = a1 * t1;
    const v3 = 0;

    const f1 = (x) => {
      return a1 * x;
    };

    const f2 = (x) => {
      return v2;
    };

    const f3 = (x) => {
      return a3 * (x - (t1 + t2)) + v2;
    };

    let x_stepSize = 1;
    let y_stepSize = 1;
    if (v2 > 15) {
      y_stepSize = 2;
    }
    if (t1 + t2 + t3 > 15) {
      x_stepSize = 2;
    }

    const s = 0.5 * t1 * v2 + t2 * v2 + 0.5 * t3 * v2;

    /* END VARIABLES AND CONSTANTS */
    if (f3(t1 + t2 + t3) === 0 && v2 < 9) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A cyclist cycles along a straight path. He accelerates uniformly from rest for $${t1}s$, where he reaches a velocity of $${v2}ms^{-1}$.`,
        `The cyclist then stops accelerating and maints his speed for $T$ seconds.`,
        `He the continues to decelerate uniformly until he comes to rest.`,
        `In total the cyclist has cycled a distance of $${s}m$ in $${
          t1 + t2 + t3
        }s$.`,
        `a) Find the value of $T$.`,
        `b) Hence, or otherwise, graph the velocity-time graph that correctly models the motion of the cyclist.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) We are given that the cyclist has cycled a distance of $${s}m$ in $${
          t1 + t2 + t3
        }s$ so:`,
        `$\\displaystyle \\frac{${v2}\\times${t1}}{2} + ${v2}T + \\frac{${v2}}{2}(${
          t1 + t2 + t3
        } - ${t1} - T) = ${s}$`,
        `$\\displaystyle ${v2 / 2}T = ${s} - ${v2 / 2}\\times${t1 + t2 + t3}$`,
        `$\\displaystyle T = ${t2}$`,

        `HEADING b) Since $T = ${t2}$ we know that the time it takes for the cyclist to decelerate is $${t3}$ therefore the graph should look like this:`,
      ]);

      const myGraph = new MCQuestion.Graph(
        t1 + t2 + t3,
        -2,
        v2 + 2,
        -2,
        x_stepSize,
        y_stepSize
      );
      myGraph.plot(f1, 0, t1);
      myGraph.plot(f2, t1, t1 + t2);
      myGraph.plot(f3, t1 + t2, t1 + t2 + t3);
      question.addGraph("solution", myGraph);

      const group1 = new MCQuestion.InputGroup(1);

      group1.addInput(t2, 0, `a) $\\text{ } T =$`);

      question.addInputGroup(group1);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVATGraphs_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const person = MCMisc.getName();

    const a1 = 0;
    const a2 = -MCRandom.randomInt(10, 50) / 100;
    const a3 = MCRandom.randomInt(10, 50) / 100;

    const t1 = MCRandom.randomInt(5, 15);
    const t2 = MCRandom.randomInt(5, 15);
    const t3 = MCRandom.randomInt(5, 15);

    const v1 = MCRandom.randomInt(15, 20);
    const v2 = v1 + a2 * t2;
    const v3 = v2 + a3 * t3;

    const f1 = (x) => {
      return v1;
    };

    const f2 = (x) => {
      return a2 * (x - t1) + v1;
    };

    const f3 = (x) => {
      return a3 * (x - (t1 + t2)) + v2;
    };

    let y_stepSize = 1;
    if (v3 > 15) {
      y_stepSize = 2;
    }

    let x_stepSize = 1;
    if (t1 + t2 + t3 > 15 && t1 + t2 + t3 <= 35) {
      x_stepSize = 2;
    } else if (t1 + t2 + t3 > 35) {
      x_stepSize = 3;
    }

    const s =
      v1 * t1 +
      0.5 * ((v1 - v2) * t2) +
      0.5 * ((v3 - v2) * t3) +
      v2 * (t2 + t3);

    /* END VARIABLES AND CONSTANTS */
    if (v2 > 5 && v3 < 30 && v3 > v1 + 2 && v2 < v1 - 2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A train is travelling between two points at a speed of $${v1}ms^{-1}$, after $${t1}s$ the train decelerates at a rate of $${a2.toFixed(
          2
        )}ms^{-2}$ down to $${v2.toFixed(2)}ms^{-1}$ over a time period $T_0$.`,
        `The train then accelerates up to a speed of $${v3.toFixed(
          2
        )}ms^{-1}$.`,
        `a) Find $T_0$.`,
        `b) Given that the total distance is $${s.toFixed(
          3
        )}m$, find the total time taken, $T_1$ to travel between the two points.`,
        `c) Graph the velocity-time graph.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Since $v = u + at$:`,
        `$\\displaystyle t = \\frac{v-u}{a}$`,
        `$\\displaystyle T_0 = \\frac{${v2.toFixed(2)}-${v1}}{${a2}}$`,
        `$\\therefore T_0 = ${t2}$`,

        `HEADING b) Since we are given that the total distance is $${s.toFixed(
          3
        )}m$ we can find the final time period, $t_3$:`,
        `$${v1}\\times${t1} + \\frac{${t2}}{2}(${v1}+${v2.toFixed(
          2
        )}) + \\frac{t_3}{2}(${v2.toFixed(2)} + ${v3.toFixed(2)}) = ${s.toFixed(
          3
        )}$`,
        `$\\implies \\frac{t_3}{2}(${(v2 + v3).toFixed(2)}) = ${s.toFixed(
          3
        )} - \\frac{${t2}}{2}(${v1}+${v2.toFixed(2)}) - ${v1}\\times${t1}$`,
        `$\\therefore t_3 = ${t3}s$`,
        `Therefore total time, $T_1 = ${t1} + ${t2} + ${t3}$`,
        `$ = ${t1 + t2 + t3}s$`,

        `HEADING c) The graph should look like the following:`,
      ]);

      const myGraph = new MCQuestion.Graph(
        t1 + t2 + t3,
        -2,
        v3 + 5,
        -2,
        x_stepSize,
        y_stepSize
      );
      myGraph.plot(f1, 0, t1);
      myGraph.plot(f2, t1, t1 + t2);
      myGraph.plot(f3, t1 + t2, t1 + t2 + t3);
      question.addGraph("solution", myGraph);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(t2, 0, `a) $\\text{ } T_0 =$`);
      group2.addInput(t1 + t2 + t3, 0, `b) $\\text{ } T_1 =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVATGraphs_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const t1 = MCRandom.randomInt(2, 9);
    const t2 = MCRandom.randomInt(2, 9);
    const t3 = MCRandom.randomInt(2, 9);
    const t4 = MCRandom.randomInt(2, 9);
    const t5 = MCRandom.randomInt(2, 9);

    const a1 = MCRandom.randomInt(2, 5);
    const a2 = 0;
    const a3 = -MCRandom.randomInt(2, 5);
    const a4 = 0;
    const a5 = -MCRandom.randomInt(2, 5);

    const v1 = a1 * t1;
    const v2 = v1 + a3 * t3;

    const f1 = (x) => {
      return a1 * x;
    };

    const f2 = (x) => {
      return v1;
    };

    const f3 = (x) => {
      return a3 * (x - (t1 + t2)) + v1;
    };

    const f4 = (x) => {
      return v2;
    };

    const f5 = (x) => {
      return a5 * (x - (t1 + t2 + t3 + t4)) + v2;
    };

    let y_stepSize = 1;
    if (v1 > 15) {
      y_stepSize = 2;
    }

    let x_stepSize = 1;
    if (t1 + t2 + t3 + t4 + t5 > 15 && t1 + t2 + t3 + t4 + t5 <= 35) {
      x_stepSize = 2;
    } else if (t1 + t2 + t3 + t4 + t5 > 35) {
      x_stepSize = 3;
    }

    const s =
      (v1 * t1) / 2 + t2 * v1 + (t3 / 2) * (v1 + v2) + v2 * t4 + (v2 * t5) / 2;

    /* END VARIABLES AND CONSTANTS */
    if (f5(t1 + t2 + t3 + t4 + t5) === 0 && v2 < v1 && v1 < 32 && a3 !== a5) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Given the following velocity-time graph:`
      );
      const myGraph = new MCQuestion.Graph(
        t1 + t2 + t3 + t4 + t5,
        -2,
        v1 + 2,
        -2,
        x_stepSize,
        y_stepSize
      );
      myGraph.plot(f1, 0, t1);
      myGraph.plot(f2, t1, t1 + t2);
      myGraph.plot(f3, t1 + t2, t1 + t2 + t3);
      myGraph.plot(f4, t1 + t2 + t3, t1 + t2 + t3 + t4);
      myGraph.plot(f5, t1 + t2 + t3 + t4, t1 + t2 + t3 + t4 + t5);
      question.addGraph("question", myGraph);

      question.addMultipleParagraphs("question", [
        `a) Find the deceleration, $a_1$, between $${t1 + t2}$ and $${
          t1 + t2 + t3
        }$ seconds.`,
        `b) Find the deceleration, $a_2$, between $${t1 + t2 + t3 + t4}$ and $${
          t1 + t2 + t3 + t4 + t5
        }$ seconds.`,
        `c) Find the total distance, $s_1$, that is represented by the velocity-time graph.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Deceleration between $${t1 + t2}$ and $${
          t1 + t2 + t3
        }$ seconds is the gradient of the graph during that time period:`,
        `$\\displaystyle a = \\frac{${v2}-${v1}}{${t1 + t2 + t3}-${t1 + t2}}$`,
        `$\\therefore a_1 = ${a3}ms^{-2}$.`,

        `HEADING b) Deceleration between $${t1 + t2 + t3 + t4}$ and $${
          t1 + t2 + t3 + t4 + t5
        }$ seconds is the gradient of the graph during that time period:`,
        `$\\displaystyle a = \\frac{0-${v2}}{${t1 + t2 + t3 + t4 + t5}-${
          t1 + t2 + t3 + t4
        }}$`,
        `$\\therefore a_2 = ${a5}ms^{-2}$.`,

        `HEADING c) The total distance represented by the graph is the area under the graph so:`,
        `$\\displaystyle s_1 = \\left(\\frac{${v1}\\times${t1}}{2}\\right) + (${t2}\\times${v1}) + \\left(\\frac{${t3}}{2}(${v1}+${v2})\\right) + (${v2}\\times${t4}) + \\left(\\frac{${v2}\\times${t5}}{2}\\right)$`,
        `$\\therefore s_1 = ${s}m$.`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      const group3 = new MCQuestion.InputGroup(1);

      group1.addInput(a3, 0, `a) $\\text{ } a_1 =$`);
      group2.addInput(a5, 0, `b) $\\text{ } a_2 =$`);
      group3.addInput(s, 0, `c) $\\text{ } s_1 =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVATGraphs_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const person = MCMisc.getName();

    /* const s = MCRandom.randomInt(200, 300);
    const t_total = MCRandom.randomInt(66, 100);
    const v = (2*s)/t_total;
    const a = MCRandom.randomInt(2, 6);
    const t1 = v/a;
    const t2 = t_total - t1;
    const d = -v/t2; */

    const v = MCRandom.randomInt(13, 31);
    const t1 = MCRandom.randomInt(5, 9);
    const t2 = MCRandom.randomInt(5, 15);
    const a = v / t1;
    const d = -v / t2;
    const t_total = t1 + t2;
    const s = 0.5 * t_total * v;

    let y_stepSize = 1;
    if (v > 15) {
      y_stepSize = 2;
    }

    let x_stepSize = 1;
    if (t_total > 15 && t_total <= 35) {
      x_stepSize = 2;
    } else if (t_total > 35) {
      x_stepSize = 3;
    }

    const f1 = (x) => {
      return a * x;
    };

    const f2 = (x) => {
      return d * (x - t1) + v;
    };

    /* END VARIABLES AND CONSTANTS */
    if (a !== d && a * 10 === Math.round(a * 10)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `${person.name} is driving a motorbike between two sets of traffic lights.`,
        `${person.name} starts from rest, accelerates uniformly and then passes the first set of traffic lights at speed $V$.`,
        `As ${person.heshe} is passing the first set of traffic lights the second set turn red, subsequently, ${person.name} instantaneously decelerates uniformly until coming to a stop before the red lights.`,
        `Given that ${person.name} travels a total of $${s}m$ in $${t_total}s$,`,
        `a) Find $V$.`,
        `b) Given that the acceleration of the motor bike was $${a}ms^{-2}$, find the deceleration, $d$, to $3$ signficant figures.`,
        `c) Hence, or otherwise, graph the velocity-time graph.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Since the total distance travelled is equal to the area under the graph:`,
        `$${s} = \\frac{1}{2} \\times V \\times ${t_total}$`,
        `$\\displaystyle V = \\frac{${2 * s}}{${t_total}}$`,
        `$\\therefore V = ${v}ms^{-1}$`,

        `HEADING b) Since $v = u + at$:`,
        `$\\displaystyle t = \\frac{${v}}{${a}}$`,
        `$t = ${t1}s$`,
        `Then the deceleration $d = \\frac{-${v}}{(${t_total}-${t1})}$`,
        `$\\therefore d = ${d.toPrecision(3)}ms^{-2}$`,

        `HEADING c) The velocity-time graph should look like the following:`,
      ]);

      const myGraph = new MCQuestion.Graph(
        t_total,
        -2,
        v + 2,
        -2,
        x_stepSize,
        y_stepSize
      );
      myGraph.plot(f1, 0, t1);
      myGraph.plot(f2, t1, t_total);
      question.addGraph("solution", myGraph);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(v, 0, `a) $\\text{ } v =$`);
      group2.addInput(parseFloat(d.toPrecision(3)), 0.01, `b) $\\text{ } d =$`);
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVATGraphs_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const person1 = MCMisc.getName();
    const person2 = MCMisc.getName();

    const v1 = MCRandom.randomInt(7, 12);
    const v2 = MCRandom.randomInt(7, 12);

    const endTime = MCRandom.randomInt(12, 28);
    const t1 = endTime - MCRandom.randomInt(2, 5);

    const working1 = new MCMaths.Fraction((400 - v2 * t1) / v2);
    const working2 = new MCMaths.Fraction(
      (-v2 * working1.denominator) / working1.numerator
    );

    const working3 = new MCMaths.Fraction((400 - v1 * endTime) / v1);

    const d = working2.toFloat();
    const T = working3.toFloat();

    /* END VARIABLES AND CONSTANTS */
    if (
      v1 > v2 + 1 &&
      endTime === working1.toFloat() &&
      working3.toFloat() < t1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Two runners, ${person1.name} and ${person2.name}, are running a sprint on a $200m$ race track and at time $t = 0$ ${person1.name} has just overtaken ${person2.name}.`,
        `${person1.name} is moving with speed $${v1}ms^{-1}$ until at time $t = T$ where ${person1.heshe} uniformly decelerates to rest.`,
        `${person2.name} is moving with speed $${v2}ms^{-1}$ until at time $t = ${t1}$ where ${person2.heshe} uniformly decelerates to rest.`,
        `Given that ${person1.name} and ${person2.name} both finish the race at the same time, $t_0$, `,
        `a) Find the deceleration, $d$, of ${person2.name}.`,
        `b) Find $T$`,
        `Give your answers to $3$ significant figures.`,
        `A sketch of the velocity-time graph of the motion of the two runners is given below:`,
        `$\\text{ }$`,
      ]);
      const myImage = new MCQuestion.Image("Matt/SUVATGraph1.svg", "width", 25);
      myImage.addOverlay(`$${v1}$`, 2, 24);
      myImage.addOverlay(`$${v2}$`, 2, 41);
      myImage.addOverlay(`$v (ms^{-1})$`, 8, -2);
      myImage.addOverlay(`$t (s)$`, 100, 92);
      myImage.addOverlay(`$T$`, 47, 100);
      myImage.addOverlay(`$${t1}$`, 76, 100);
      myImage.addOverlay(`$t_0$`, 89, 100);
      question.addImage("question", myImage);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Since the total distance that ${person2.name} runs is $200m$ and the total distance is the area under the graph we know that:`,
        `$\\displaystyle \\frac{${v2}}{2}(${t1}+t_0) = 200$`,
        `$${v2}(${t1} + t_0) = 400$`,
        `$${v2 * t1} + ${v2}t_0 = 400$`,
        `$${v2}t_0 = ${400 - v2 * t1}$`,
        `$t_0 = ${working1.toString()}$`,
        `Since $v = u + at$`,
        `$\\displaystyle \\implies d = \\frac{-${v2}}{\\left(${working1.toString()}\\right)}$`,
        `$\\displaystyle  d = ${working2.toString()}$`,
        `$\\therefore d = ${working2.toFloat().toPrecision(3)}ms^{-2}$.`,

        `HEADING b) Since the total distance that ${person1.name} runs is $200m$ and the total distance is the area under the graph we know that:`,
        `$\\frac{${v1}}{2}(T + t_0) = 200$`,
        `$${v1}(T + ${endTime}) = 400$`,
        `$${v1}T + ${v1 * endTime} = 400$`,
        `$${v1}T = ${400 - v1 * endTime}$`,
        `$T = ${working3.toString()}$`,
        `$\\therefore T = ${working3.toFloat().toPrecision(3)}s$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(d.toPrecision(3)),
        0.001,
        `a) $\\text{ } d =$`
      );
      group2.addInput(parseFloat(T.toPrecision(3)), 0.1, `b) $\\text{ } T =$`);
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVATGraphs_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const v1 = MCRandom.randomInt(3, 9);
    const t1 = MCRandom.randomInt(2, 5);
    const a1 = v1 / t1;

    const t2 = MCRandom.randomInt(2, 5);

    const t3 = MCRandom.randomInt(2, 5);
    const a3 = -v1 / t3;

    const total_t = t1 + t2 + t3;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Given the following velocity-time graph describing a particles motion:`
      );
      const myGraph = new MCQuestion.Graph(total_t, -2, v1 + 2, -2, 1, 1);
      myGraph.plot(`${a1}*x`, 0, t1);
      myGraph.plot(`${v1}`, t1, t1 + t2);
      myGraph.plot(`${a3}*(x-${t1 + t2}) + ${v1}`, t1 + t2, total_t);
      question.addGraph(myGraph);
      question.addHeading(
        `Choose the correct description of motion described by the graph:`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `The particle accelerates uniformly with acceleration $${a1.toFixed(
          2
        )}ms^{-2}$. The particle then stops accelerating and moves with constant speed $${v1}ms^{-1}$. Then the particle decelerates uniformly at a deceleration of $${a3.toFixed(
          2
        )}ms^{-2}$.`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `The particle moves with velocity $${a1.toFixed(
          2
        )}ms^{-1}$. The particle then stops for $${t2}s$. Then the particle moves with a velocity of $${a3.toFixed(
          2
        )}ms^{-1}$.`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `The particle accelerates with acceleration $${a1.toFixed(
          2
        )}t \\text{ } ms^{-2}$. The particle then stops accelerating and moves with constant speed $${v1}ms^{-1}$. Then the particle decelerates at a deceleration of $${a3.toFixed(
          2
        )}t \\text{ } ms^{-2}$.`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `The particle accelerates uniformly with acceleration $${a1.toFixed(
          2
        )}ms^{-2}$. The particle then stops for $${t2}s$. Then the particle decelerates uniformly at a deceleration of $${a3.toFixed(
          2
        )}ms^{-2}$.`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVATGraphs_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const v1 = MCRandom.randomInt(3, 9);
    const t1 = MCRandom.randomInt(2, 5);
    const a1 = -v1 / t1;

    const t2 = MCRandom.randomInt(2, 5);

    const t3 = MCRandom.randomInt(2, 5);
    const a3 = v1 / t3;

    const total_t = t1 + t2 + t3;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Given the following velocity-time graph describing a particles motion:`
      );
      const myGraph = new MCQuestion.Graph(total_t, -2, v1 + 2, -2, 1, 1);
      myGraph.plot(`${a1}*x + ${v1}`, 0, t1);
      myGraph.plot(`0`, t1, t1 + t2);
      myGraph.plot(`${a3}*(x-${t1 + t2})`, t1 + t2, total_t);
      question.addGraph(myGraph);
      question.addHeading(
        `Choose the correct description of motion described by the graph:`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `The particle decelerates uniformly with deceleration $${a1.toFixed(
          2
        )}ms^{-2}$ until it comes to rest. The particle then stops for $${t2}s$. Then the particle accelerates uniformly at an acceleration of $${a3.toFixed(
          2
        )}ms^{-2}$.`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `The particle moves with velocity $${a1.toFixed(
          2
        )}ms^{-1}$. The particle then stops for $${t2}s$. Then the particle moves with a velocity of $${a3.toFixed(
          2
        )}ms^{-1}$.`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `The particle decelerates with deceleration $${a1.toFixed(
          2
        )}t \\text{ } ms^{-2}$ until it comes to rest. Then the particle accelerates at an acceleration of $${a3.toFixed(
          2
        )}t \\text{ } ms^{-2}$.`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `The particle decelerates uniformly with deceleration $${a1.toFixed(
          2
        )}ms^{-2}$ until it comes to rest. Then the particle accelerates uniformly at a acceleration of $${a3.toFixed(
          2
        )}ms^{-2}$.`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVATGraphs_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const v1 = MCRandom.randomInt(3, 9);
    const t1 = MCRandom.randomInt(2, 5);
    const a1 = v1 / t1;

    const t2 = MCRandom.randomInt(2, 5);
    const a2 = v1 / t2;

    const t3 = MCRandom.randomInt(2, 5);
    const a3 = v1 / t3;

    const total_t = t1 + t2 + t3;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Given the following velocity-time graph describing a particles motion:`
      );
      const myGraph = new MCQuestion.Graph(total_t, -2, v1 + 2, -2, 1, 1);
      myGraph.plot(`0`, 0, t1);
      myGraph.plot(`${a2}*(x-${t1})`, t1, t1 + t2);
      myGraph.plot(`${v1}`, t1 + t2, total_t);
      question.addGraph(myGraph);
      question.addHeading(
        `Choose the correct description of motion described by the graph:`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `The particle doesn't move for $${t1}s$. The particle then accelerates uniformly with acceleration $${a2}ms^{-2}$. Then the particle moves with constant velocity $${v1}ms^{-1}$.`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `The particle doesn't move for $${t1}s$. The particle then moves with velocity $${a2}ms^{-1}$. The particle then stops moving.`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `The particle doesn't move for $${t1}s$. The particle then accelerateswith acceleration $${a2}t \\text{ } ms^{-2}$. Then the particle moves with constant velocity $${v1}ms^{-1}$.`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `The particle doesn't move for $${t1}s$. The particle then accelerates uniformly with acceleration $${a2}ms^{-2}$. Then the particle stops moving.`
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVATGraphs_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const v1 = MCRandom.randomInt(3, 9);
    const t1 = MCRandom.randomInt(2, 5);
    const a1 = v1 / t1;

    const t2 = MCRandom.randomInt(2, 5);
    const a1Wrong = v1 / (t1 + t2);

    const t3 = MCRandom.randomInt(2, 5);
    const a3 = -v1 / t3;

    const total_t = t1 + t2 + t3;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Given the following velocity-time graph describing a particles motion:`
      );
      const myGraph = new MCQuestion.Graph(total_t, -2, v1 + 2, -2, 1, 1);
      myGraph.plot(`${a1}*x`, 0, t1);
      myGraph.plot(`${v1}`, t1, t1 + t2);
      myGraph.plot(`${a3}*(x-${t1 + t2}) + ${v1}`, t1 + t2, total_t);
      question.addGraph(myGraph);
      question.addHeading(
        `Choose the correct acceleration for the first $${t1}s$ of the motion:`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${a1.toPrecision(3)}ms^{-2}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${v1.toPrecision(3)}ms^{-2}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${(a1 * 2).toPrecision(3)}ms^{-2}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${a1Wrong.toPrecision(3)}ms^{-2}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVATGraphs_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const v1 = MCRandom.randomInt(3, 9);
    const t1 = MCRandom.randomInt(2, 5);
    const a1 = -v1 / t1;

    const t2 = MCRandom.randomInt(2, 5);
    const a1Wrong = -v1 / (t1 + t2);

    const t3 = MCRandom.randomInt(2, 5);
    const a3 = v1 / t3;

    const total_t = t1 + t2 + t3;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Given the following velocity-time graph describing a particles motion:`
      );
      const myGraph = new MCQuestion.Graph(total_t, -2, v1 + 2, -2, 1, 1);
      myGraph.plot(`${a1}*x + ${v1}`, 0, t1);
      myGraph.plot(`0`, t1, t1 + t2);
      myGraph.plot(`${a3}*(x-${t1 + t2})`, t1 + t2, total_t);
      question.addGraph(myGraph);
      question.addHeading(
        `Choose the correct deceleration for the first $${t1}s$ of the motion:`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${a1.toPrecision(3)}ms^{-2}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${v1.toPrecision(3)}ms^{-2}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${(a1 * 2).toPrecision(3)}ms^{-2}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${a1Wrong.toPrecision(3)}ms^{-2}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ConstantAcceleration_SUVATGraphs_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const v1 = MCRandom.randomInt(3, 9);
    const t1 = MCRandom.randomInt(2, 5);
    const a1 = v1 / t1;

    const t2 = MCRandom.randomInt(2, 5);
    const a2 = v1 / t2;

    const t3 = MCRandom.randomInt(2, 5);
    const a3 = v1 / t3;

    const total_t = t1 + t2 + t3;

    const s = 0.5 * v1 * t2 + v1 * t3;
    const sWrong1 = v1 * t2 + v1 * t3;
    const sWrong2 = 0.5 * v1 * t2;
    const sWrong3 = v1 * t3;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Given the following velocity-time graph describing a particles motion:`
      );
      const myGraph = new MCQuestion.Graph(total_t, -2, v1 + 2, -2, 1, 1);
      myGraph.plot(`0`, 0, t1);
      myGraph.plot(`${a2}*(x-${t1})`, t1, t1 + t2);
      myGraph.plot(`${v1}`, t1 + t2, total_t);
      question.addGraph(myGraph);
      question.addHeading(
        `Choose the correct total distance travelled by the particle:`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${s}m$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${sWrong1}m$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${sWrong2}m$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${sWrong3}m$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_Circles_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pointA = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const pointB = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const pointM = [
      pointA[0] + (pointB[0] - pointA[0]) / 2,
      pointA[1] + (pointB[1] - pointA[1]) / 2,
    ];
    const RSquared =
      ((pointB[0] - pointA[0]) / 2) ** 2 + ((pointB[1] - pointA[1]) / 2) ** 2;
    const R = Math.sqrt(RSquared);

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(R * 10) === R * 10 &&
      Math.round(pointM[0] * 10) === pointM[0] * 10 &&
      Math.round(pointM[1] * 10) ===
        pointM[1] * 10 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The points $A$ and $B$ have coordinates $(${pointA[0]}, ${pointA[1]})$, $(${pointB[0]}, ${pointB[1]})$ respectively.`
      );
      question.addParagraph(
        "question",
        `$M$ is the midpoint between $A$ and $B$, and also the centre of the circle $C$.`
      );
      question.addParagraph(
        "question",
        `$A$ and $B$ both lie on the circumference of $C$.`
      );
      question.addParagraph("question", `a) Find the midpoint $M$.`);
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, find the equation of the circle in the form $(x-a)^2 + (y-b)^2 = R^2$ where $a$, $b$ and $R$ are constants to be found.`
      );

      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `a) $\\displaystyle M = \\left(${pointA[0]}+\\frac{${pointB[0]}-${pointA[0]}}{2}, \\text{ } ${pointA[1]}+\\frac{${pointB[1]}-${pointA[1]}}{2} \\right)$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\displaystyle M = (${pointM[0]}, \\text{ } ${pointM[1]})$`
      );

      question.addHeading(
        "solution",
        `b) From part a, $a = ${pointM[0]}$ and $b = ${pointM[1]}$`
      );
      question.addHeading("solution", MCMaths.cleaner(`Finding Radius:`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle R = \\sqrt{ \\left(\\frac{${pointB[0]}-${pointA[0]}}{2}\\right)^2 + \\left(\\frac{${pointB[1]}-${pointA[1]}}{2}\\right)^2 }$`
        )
      );
      question.addParagraph("solution", `$R = \\sqrt{${RSquared}}$`);
      question.addParagraph("solution", `$\\therefore R = ${R}$`);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        parseFloat(pointM[0].toPrecision(3)),
        Math.abs(
          parseFloat(pointM[0].toPrecision(3)) -
            parseFloat(pointM[0].toPrecision(2))
        ),
        `a) $\\text{ } M_x =$`
      );
      group1.addInput(
        parseFloat(pointM[1].toPrecision(3)),
        Math.abs(
          parseFloat(pointM[1].toPrecision(3)) -
            parseFloat(pointM[1].toPrecision(2))
        ),
        `a) $\\text{ } M_y =$`
      );
      const group2 = new MCQuestion.InputGroup(3);
      group2.addInput(
        parseFloat(pointM[0].toPrecision(3)),
        Math.abs(
          parseFloat(pointM[0].toPrecision(3)) -
            parseFloat(pointM[0].toPrecision(2))
        ),
        `b) $\\text{ } a =$`
      );
      group2.addInput(
        parseFloat(pointM[1].toPrecision(3)),
        Math.abs(
          parseFloat(pointM[1].toPrecision(3)) -
            parseFloat(pointM[1].toPrecision(2))
        ),
        `b) $\\text{ } b =$`
      );
      group2.addInput(
        parseFloat(R.toPrecision(3)),
        Math.abs(parseFloat(R.toPrecision(3)) - parseFloat(R.toPrecision(2))),
        `b) $\\text{ } R =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_Circles_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(2, 20) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(2, 20) * MCRandom.randomInt(-1, 1, 2);
    const r = MCRandom.randomInt(2, 20);
    const rSquared = r * r;
    const A_x = MCRandom.randomInt(2, 20) * MCRandom.randomInt(-1, 1, 2);
    const pointA = [A_x, b + Math.sqrt(r ** 2 - (A_x - a) ** 2)];

    const m_1 = new MCMaths.Fraction((b - pointA[1]) / (a - pointA[0]));
    const m_0 = new MCMaths.Fraction(-1 / m_1.toFloat());
    const b_0 = new MCMaths.Fraction(pointA[1] - m_0.toFloat() * pointA[0]);

    const working1 = new MCMaths.Fraction(a * m_1.toFloat());
    const b_1 = new MCMaths.Fraction(-working1.toFloat() + b);

    const working2 = new MCMaths.Fraction(m_1.toFloat() - m_0.toFloat());
    const working3 = new MCMaths.Fraction(b_0.toFloat() - b_1.toFloat());

    /* END VARIABLES AND CONSTANTS */
    if (
      r ** 2 - (A_x - a) ** 2 > 0 &&
      pointA[1] === Math.round(pointA[1]) &&
      a !== A_x &&
      b_0.toFloat() === Math.round(b_0.toFloat()) &&
      `${working1.denominator}`.length <= 3 &&
      `${b_1.denominator}`.length <= 3 &&
      `${working3.denominator}`.length <= 3
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `The circle $C$ has a centre $M$ with coordinates $(${a}, ${b})$ and the line $l_0$ has the equation $y = ${m_0.toString()}x+${b_0.toString()}$.`
        )
      );
      question.addParagraph(
        "question",
        `The line $l_0$ is tangent to $C$ at the point $A$.`
      );
      question.addParagraph(
        "question",
        `The line $l_1$ is the line such that it passes through the points $A$ and $M$.`
      );
      question.addParagraph(
        "question",
        `a) Find the equation of the line $l_1$ in the form $y = m_{1}x + b_{1}$ where $m$ and $b$ are constants to be found.`
      );
      question.addParagraph(
        "question",
        `The equation of $C$ can be written in the form $(x-a)^2 + (y-b)^2 = r^2$`
      );
      question.addParagraph("question", `b) Find $a$, $b$ and $r$.`);
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $\\displaystyle m_1 = \\frac{-1}{\\left(${m_0.toString()}\\right)}$`
      );
      question.addParagraph("solution", `So $ m_1 = ${m_1.toString()}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$l_1: y-${b} = ${m_1.toString()}\\left(x-${a}\\right)$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\implies l_1: y = ${m_1.toString()}x-${working1.toString()}+${b}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y = ${m_1.toString()}x+${b_1.toString()}$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore m_1 = ${m_1
          .toFloat()
          .toPrecision(3)}, \\text{ } b_1 = ${b_1.toFloat().toPrecision(3)}$.`
      );

      question.addParagraph(
        "solution",
        `b) Clearly $a = ${a}, \\text{ } b = ${b}$`
      );
      question.addParagraph("solution", `Find point $A$:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${m_1.toString()}x+${b_1.toString()} = ${m_0.toString()}x+${b_0.toString()}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${m_1.toString()}x-${m_0.toString()}x = ${b_0.toString()}-${b_1.toString()}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${working2.toString()}x = ${working3.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x = ${pointA[0]}$`, false)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$y = ${m_0.toString()}(${pointA[0]})+${b_0.toString()}$`
        )
      );
      question.addParagraph("solution", `$y = ${pointA[1]}$`);
      question.addParagraph(
        "solution",
        `So point $A = (${pointA[0]}, ${pointA[1]})$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$r = \\sqrt{(${a}-${pointA[0]})^2 + (${b}-${pointA[1]})^2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$r = \\sqrt{${rSquared}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore r = ${r}$`)
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        parseFloat(m_1.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(m_1.toFloat().toPrecision(3)) -
            parseFloat(m_1.toFloat().toPrecision(2))
        ),
        `a) $\\text{ } m_1 =$`
      );
      group1.addInput(
        parseFloat(b_1.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(b_1.toFloat().toPrecision(3)) -
            parseFloat(b_1.toFloat().toPrecision(2))
        ),
        `a) $\\text{ } b_1 =$`
      );

      const group2 = new MCQuestion.InputGroup(3);
      group2.addInput(
        parseFloat(a.toPrecision(3)),
        Math.abs(parseFloat(a.toPrecision(3)) - parseFloat(a.toPrecision(2))),
        `b) $\\text{ } a =$`
      );
      group2.addInput(
        parseFloat(b.toPrecision(3)),
        Math.abs(parseFloat(b.toPrecision(3)) - parseFloat(b.toPrecision(2))),
        `b) $\\text{ } b =$`
      );
      group2.addInput(
        parseFloat(r.toPrecision(3)),
        Math.abs(parseFloat(r.toPrecision(3)) - parseFloat(r.toPrecision(2))),
        `b) $\\text{ } r =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_Circles_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(2, 20) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(2, 20) * MCRandom.randomInt(-1, 1, 2);
    const r = MCRandom.randomInt(2, 20);
    const rSquared = r * r;
    const A_x = new MCMaths.Fraction(
      (MCRandom.randomInt(1, 200) / 10) * MCRandom.randomInt(-1, 1, 2)
    );
    const pointA = [
      A_x.toFloat(),
      b + Math.sqrt(r ** 2 - (A_x.toFloat() - a) ** 2),
    ];
    const m_1 = new MCMaths.Fraction((b - pointA[1]) / (a - pointA[0]));
    const m_0 = new MCMaths.Fraction(-1 / m_1.toFloat());

    const gamma = m_0.toFloat() * A_x.toFloat() - b;
    const lamda =
      A_x.toFloat() ** 2 -
      2 * a * A_x.toFloat() +
      a ** 2 +
      gamma ** 2 -
      rSquared;

    const k_1 = -gamma + Math.sqrt(gamma ** 2 - lamda);
    const k_2 = -gamma - Math.sqrt(gamma ** 2 - lamda);

    const mx_1 = A_x.toFloat() * m_0.toFloat();
    const working1 = A_x.toFloat() ** 2 - 2 * a * A_x.toFloat() + a ** 2;
    const working2 = working1 - rSquared;
    const working3 = gamma ** 2 + working2;

    /* END VARIABLES AND CONSTANTS */
    if (
      r ** 2 - (A_x.toFloat() - a) ** 2 > 0 &&
      a !== A_x.toFloat() &&
      `${m_0.denominator}`.length <= 3 &&
      m_0.toFloat() * A_x.toFloat() ===
        Math.round(m_0.toFloat() * A_x.toFloat()) &&
      k_1 !== 0 &&
      k_2 !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A circle $C$ has the equation $(x-${a})^2 + (y-${b})^2 = ${rSquared}$.`
        )
      );
      question.addParagraph(
        "question",
        `The line $l_1$ is tangent to the circle at the point $A$.`
      );
      question.addParagraph(
        "question",
        `The $x$-coordinate of $A$ is $${A_x.toString()}$.`
      );
      question.addParagraph(
        "question",
        `$l_1$ can be written in the form $y = ${m_0.toString()}x + k$.`
      );
      question.addParagraph("question", `Find the $2$ possible values of $k$.`);

      question.addHeading("solution", "Forming Equation:");
      question.addParagraph("solution", `$x = ${A_x.toString()}$`);
      question.addParagraph(
        "solution",
        `$\\implies y = ${m_0.toString()}(${A_x.toString()}) + k$`
      );
      question.addParagraph("solution", `$\\implies y = ${mx_1} + k$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\implies (${A_x.toString()}-${a})^2 + (${mx_1}+k-${b})^2 = ${rSquared}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$ ${A_x.toString()}^2-2(${a})(${A_x.toString()})+${a}^2 + (k+${mx_1}-${b})^2 = ${rSquared}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ ${working1}+(k+${gamma})^2 = ${rSquared}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ (k+${gamma})^2+${working2} = 0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ k^2+2(${gamma})k+${gamma ** 2}+${working2} = 0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ k^2+${2 * gamma}k+${working3} = 0$`)
      );
      question.addHeading("solution", "Solving Quadratic:");
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ (k-${k_1})(k-${k_2}) = 0$`)
      );
      question.addParagraph("solution", `$k = ${k_1}, \\text{ } ${k_2}$`);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput([k_1, k_2], 0, `$k = $`);
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_Circles_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pointA = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const r = MCRandom.randomInt(2, 9);
    const c1 = new MCMaths.Circles(pointA[0], pointA[1], r);

    const pointP = [
      MCRandom.randomInt(2, 20) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 20) * MCRandom.randomInt(-1, 1, 2),
    ];

    const lengthAPSquared =
      (pointP[0] - pointA[0]) ** 2 + (pointP[1] - pointA[1]) ** 2;
    const lengthAP = Math.sqrt(lengthAPSquared);

    const working1 = -1 * (pointA[0] ** 2 + pointA[1] ** 2 - r ** 2);

    const lengthPQSquared = lengthAPSquared + r ** 2;
    const lengthPQ = Math.sqrt(lengthPQSquared);

    /* END VARIABLES AND CONSTANTS */
    if (lengthAP > r + 2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The circle $C$ has the equation: $${c1.expandedString()}.$`
      );
      question.addParagraph("question", `The centre of $C$ is point $A$.`);
      question.addParagraph(
        "question",
        `a) Express the equation of $C$ in the from $(x-a)^2 + (y-b)^2 = r^2$ where $a$, $b$ and $r$ are constants to be found.`
      );
      question.addParagraph(
        "question",
        `The point $P$ has coordinates $(${pointP[0]}, ${pointP[1]})$.`
      );
      question.addParagraph("question", `b) Find the length of $AP$.`);
      question.addParagraph(
        "question",
        `The point $Q$ lies on the circle such that the tangent to $C$ at point $Q$ passes through $P$.`
      );
      question.addParagraph("question", `c) Find the length of $PQ$.`);
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addHeading(
        "solution",
        `a) Equation of $C: ${c1.expandedString()}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\implies (x-${pointA[0]})^{2}-${pointA[0] ** 2}+(y-${
            pointA[1]
          })^{2}-${pointA[1] ** 2} = ${working1}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$ (x-${pointA[0]})^{2}+(y-${pointA[1]})^{2} = ${r ** 2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$ (x-${pointA[0]})^{2}+(y-${pointA[1]})^{2} = ${r}^{2}$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore a = ${pointA[0]}, \\text{ } b = ${pointA[1]}, \\text{ } r = ${r}$`
      );

      question.addHeading(
        "solution",
        `b) Length $AP = \\sqrt{(${pointP[0] - pointA[0]})^2+(${
          pointP[1] - pointA[1]
        })^2}$`
      );
      question.addParagraph("solution", `$AP = \\sqrt{${lengthAPSquared}}$`);
      question.addParagraph("solution", `$AP = ${lengthAP.toPrecision(3)}$`);

      question.addHeading(
        "solution",
        `c) Length $PQ = \\sqrt{(\\sqrt{${lengthAPSquared}})^2 + (${r})^2}$`
      );
      question.addParagraph(
        "solution",
        `$PQ = \\sqrt{${lengthAPSquared} + ${r ** 2}}$`
      );
      question.addParagraph("solution", `$PQ = \\sqrt{${lengthPQSquared}}$`);
      question.addParagraph("solution", `$PQ = ${lengthPQ.toPrecision(3)}$`);

      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput(pointA[0], 0, `a) $\\text{ } a =$`);
      group1.addInput(pointA[1], 0, `a) $\\text{ } b =$`);
      group1.addInput(r, 0, `a) $\\text{ } r =$`);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(lengthAP.toPrecision(3)),
        0,
        `b) $\\text{ } AP =$`
      );

      const group3 = new MCQuestion.InputGroup(1);
      group3.addInput(
        parseFloat(lengthPQ.toPrecision(3)),
        0,
        `c) $\\text{ } PQ =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_Circles_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const r = MCRandom.randomInt(2, 9);
    const pointA = [
      r * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];

    const c1 = new MCMaths.Circles(pointA[0], pointA[1], r);
    const working1 = -1 * (pointA[0] ** 2 + pointA[1] ** 2 - r ** 2);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The circle $C$ has the equation: $${c1.expandedString()}$`
      );
      question.addParagraph(
        "question",
        `a) Express the equation of $C$ in the from $(x-a)^2 + (y-b)^2 = r^2$ where $a$, $b$ and $r$ are constants to be found.`
      );
      question.addParagraph(
        "question",
        `$C$ touches the $y$-axis at the point $A$.`
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, find the coordinates of point $A$.`
      );

      question.addParagraph(
        "solution",
        `a) Equation of $C: ${c1.expandedString()}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\implies (x-${pointA[0]})^{2}-${pointA[0] ** 2}+(y-${
            pointA[1]
          })^{2}-${pointA[1] ** 2} = ${working1}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$ (x-${pointA[0]})^{2}+(y-${pointA[1]})^{2} = ${r ** 2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$ (x-${pointA[0]})^{2}+(y-${pointA[1]})^{2} = ${r}^{2}$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore a = ${pointA[0]}, \\text{ } b = ${pointA[1]}, \\text{ } r = ${r}$.`
      );

      question.addParagraph(
        "solution",
        `b) $C$ touches the $y$-axis when $x = 0$:`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\implies ${Math.abs(pointA[0])}^{2}+(y-${
            pointA[1]
          })^{2} = ${r}^{2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(y-${pointA[1]})^{2} = 0$`)
      );
      question.addParagraph("solution", `$\\therefore A = (0, ${pointA[1]})$.`);

      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput(pointA[0], 0, `a) $\\text{ } a =$`);
      group1.addInput(pointA[1], 0, `a) $\\text{ } b =$`);
      group1.addInput(r, 0, `a) $\\text{ } r =$`);

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(0, 0, `a) $\\text{ } A_x =$`);
      group2.addInput(pointA[1], 0, `a) $\\text{ } A_y =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_Circles_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pointA = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const pointB = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const lengthABSquared =
      (pointB[0] - pointA[0]) ** 2 + (pointB[1] - pointA[1]) ** 2;
    const lengthAB = Math.sqrt(lengthABSquared);
    const r = lengthAB / 2;
    const pointM = [
      pointA[0] + (pointB[0] + pointA[0] / 2),
      pointA[1] + (pointB[1] + pointA[1] / 2),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (r >= 2 && r <= 9) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Two points $A$ and $B$ lie on the circle $C$ with radius $R$.`
      );
      question.addParagraph(
        "question",
        `The line joining $A$ and $B$ is a diameter of $C$.`
      );
      question.addParagraph("question", `Point $M$ is the centre of $C$.`);
      question.addParagraph(
        "question",
        `$A = (${pointA[0]}, ${pointA[1]}), \\text{ } B = (${pointB[0]}, ${pointB[1]})$`
      );
      question.addParagraph("question", `a) Find the radius, $R$,  of $C$.`);
      question.addParagraph("question", `b) Find the point $M$.`);
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `a) $2R = \\sqrt{(${pointB[0]}-${pointA[0]})^{2}+(${pointB[1]}-${pointA[1]})^{2}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$2R = \\sqrt{${lengthABSquared}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$R = \\frac{1}{2}\\sqrt{${lengthABSquared}}$`)
      );
      question.addParagraph("solution", `$R = ${r.toPrecision(3)}$`);

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `b) $\\displaystyle M = \\left(${pointA[0]} + \\frac{${pointA[0]}+${pointB[0]}}{2}, \\text{ } ${pointA[1]} + \\frac{${pointA[1]}+${pointB[1]}}{2} \\right)$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle M = \\left(${pointM[0]} , \\text{ } ${pointM[1]}\\right)$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore M_x = ${pointM[0].toPrecision(
          3
        )}, \\text{ } M_y = ${pointM[1].toPrecision(3)} $`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(r.toPrecision(3)),
        Math.abs(parseFloat(r.toPrecision(3)) - parseFloat(r.toPrecision(2))),
        `a) $\\text{ } R=$`
      );

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        parseFloat(pointM[0].toPrecision(3)),
        Math.abs(
          parseFloat(pointM[0].toPrecision(3)) -
            parseFloat(pointM[0].toPrecision(2))
        ),
        `b) $\\text{ } M_x =$`
      );
      group2.addInput(
        parseFloat(pointM[1].toPrecision(3)),
        Math.abs(
          parseFloat(pointM[1].toPrecision(3)) -
            parseFloat(pointM[1].toPrecision(2))
        ),
        `b) $\\text{ } M_y =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_Circles_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const thetaA = MCRandom.randomInt(0, 360) * (Math.PI / 180);
    const thetaB = MCRandom.randomInt(0, 360) * (Math.PI / 180);
    const r = MCRandom.randomInt(2, 9);
    const pointM = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const pointA = [
      pointM[0] + r * Math.cos(thetaA),
      pointM[1] + r * Math.sin(thetaA),
    ];
    const pointB = [
      pointM[0] + r * Math.cos(thetaB),
      pointM[1] + r * Math.sin(thetaB),
    ];
    const c1 = new MCMaths.Circles(pointM[0], pointM[1], r);
    const working1 = -1 * (pointM[0] ** 2 + pointM[1] ** 2 - r ** 2);
    const triangleAMB = 0.5 * r ** 2;

    /* END VARIABLES AND CONSTANTS */
    if (
      pointA[0] * 10 === Math.round(pointA[0] * 10) &&
      pointA[1] * 10 === Math.round(pointA[1] * 10) &&
      pointB[0] * 10 === Math.round(pointB[0] * 10) &&
      pointB[1] * 10 === Math.round(pointB[1] * 10) &&
      pointA.join() !== pointB.join()
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A circle $C$ has the equation $${c1.expandedString()}$`
      );
      question.addParagraph(
        "question",
        `Two points $A$ and $B$ have coordinates $(${pointA[0]}, ${pointA[1]})$ and $(${pointB[0]}, ${pointB[1]})$ respectively.`
      );
      question.addParagraph("question", `Point $M$ is the centre of $C$.`);
      question.addParagraph("question", `a) Find $M$.`);
      question.addParagraph(
        "question",
        `b) Find the area of the triangle $AMB$`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) Equation of $C: ${c1.expandedString()}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\implies (x-${pointM[0]})^{2}-${pointM[0] ** 2}+(y-${
            pointM[1]
          })^{2}-${pointM[1] ** 2} = ${working1}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$ (x-${pointM[0]})^{2}+(y-${pointM[1]})^{2} = ${r ** 2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$ (x-${pointM[0]})^{2}+(y-${pointM[1]})^{2} = ${r}^{2}$`
        )
      );
      question.addParagraph(
        "solution",
        `So $ M = (${pointM[0]}, ${pointM[1]})$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore M_x = ${pointM[0].toPrecision(
          3
        )}, \\text{ } M_y = ${pointM[1].toPrecision(3)}$`
      );
      question.addParagraph(
        "solution",
        `b) Since $AMB$ is a right-angled triangle:`
      );
      question.addParagraph(
        "solution",
        `Area of $AMB = \\frac{1}{2}(${r})(${r})$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Area of $AMB = ${triangleAMB.toPrecision(3)}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        parseFloat(pointM[0].toPrecision(3)),
        Math.abs(
          parseFloat(pointM[0].toPrecision(3)) -
            parseFloat(pointM[0].toPrecision(2))
        ),
        `a) $\\text{ } M_x =$`
      );
      group1.addInput(
        parseFloat(pointM[1].toPrecision(3)),
        Math.abs(
          parseFloat(pointM[1].toPrecision(3)) -
            parseFloat(pointM[1].toPrecision(2))
        ),
        `a) $\\text{ } M_y =$`
      );
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(triangleAMB.toPrecision(3)),
        Math.abs(
          parseFloat(triangleAMB.toPrecision(3)) -
            parseFloat(triangleAMB.toPrecision(2))
        ),
        `a) Area of $\\text{ } AMB =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_Circles_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const r = MCRandom.randomInt(2, 9);
    const pointM = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const c1 = new MCMaths.Circles(pointM[0], pointM[1], r);
    const working1 = -1 * (pointM[0] ** 2 + pointM[1] ** 2 - r ** 2);

    const areaA = r ** 2 * (4 - Math.PI);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A circle $C$ has the equation $${c1.expandedString()}$ and sits inside a square as depicted in the image below:`
      );
      const myImage = new MCQuestion.Image(
        "Matt/circleInsideSquare.svg",
        "width",
        15
      );
      question.addImage("question", myImage);
      question.addParagraph("question", `The circle has the radius $R$.`);
      question.addParagraph("question", `a) Find $R$.`);
      question.addParagraph(
        "question",
        `Let the area of the shaded region be called $A$`
      );
      question.addParagraph("question", `b) Find $A$.`);
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) Equation of $C: ${c1.expandedString()}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\implies (x-${pointM[0]})^{2}-${pointM[0] ** 2}+(y-${
            pointM[1]
          })^{2}-${pointM[1] ** 2} = ${working1}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$ (x-${pointM[0]})^{2}+(y-${pointM[1]})^{2} = ${r ** 2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$ (x-${pointM[0]})^{2}+(y-${pointM[1]})^{2} = ${r}^{2}$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore R = ${r.toPrecision(3)}$`
      );

      question.addParagraph("solution", `b) $A = (2R)^2 - \\pi R^2$`);
      question.addParagraph("solution", `$A = R^2(2^2 - \\pi)$`);
      question.addParagraph("solution", `$A = ${r}^2(4 - \\pi)$`);
      question.addParagraph("solution", `$A = ${r ** 2}(4 - \\pi)$`);
      question.addParagraph("solution", `$A = ${areaA.toPrecision(3)}$.`);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(r.toPrecision(3)),
        Math.abs(parseFloat(r.toPrecision(3)) - parseFloat(r.toPrecision(2))),
        `a) $\\text{ } R =$`
      );
      const group2 = new MCQuestion.InputGroup(2);
      group1.addInput(
        parseFloat(areaA.toPrecision(3)),
        Math.abs(
          parseFloat(areaA.toPrecision(3)) - parseFloat(areaA.toPrecision(2))
        ),
        `b) $\\text{ } A =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_Circles_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const r = MCRandom.randomInt(2, 9);
    const rSquared = r ** 2;
    const a1 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const a2 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const a3 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const a4 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const b1 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const b2 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const b3 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const b4 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const c1 = new MCMaths.Circles(a1, b1, r);

    const answerArray = [true, true, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select all the equations of the circles with radius $${r}$:`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${c1.toString()}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        MCMaths.cleaner(`$(x-${a2})^{2}+(y-${b2})^{2}-${r}^{2}=0$`)
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        MCMaths.cleaner(`$(x-${a3})^{2}+(y-${b3})^{2}=${r}$`)
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        MCMaths.cleaner(`$(x-${a4})^{2}+(y-${b4})^{2}+${rSquared}=0$`)
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_Circles_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pointM = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const r1 = MCRandom.randomInt(2, 9);
    const r2 = MCRandom.randomInt(2, 9);
    const r3 = MCRandom.randomInt(2, 9);
    const r4 = MCRandom.randomInt(2, 9);

    const c2 = new MCMaths.Circles(pointM[0], pointM[1], r2);

    const answerArray = [true, true, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select all the equations of the circles with the centre point $(${pointM[0]}, ${pointM[1]})$:`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        MCMaths.cleaner(`$(x-${pointM[0]})^{2}+(y-${pointM[1]})^{2}=${r1}^{2}$`)
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(MCMaths.cleaner(`$${c2.expandedString()}$`));
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        MCMaths.cleaner(`$(x+${pointM[0]})^{2}+(y-${pointM[1]})^{2}=${r3}^{2}$`)
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        MCMaths.cleaner(
          `$(x-${pointM[0]})^{2}+(y+${pointM[1]})^{2}-${r4}^{2} = 0$`
        )
      );

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_Circles_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const r = MCRandom.randomInt(2, 9);

    const c1 = new MCMaths.Circles(a, b, r);
    const c2 = new MCMaths.Circles(-a, -b, r);
    const c3 = new MCMaths.Circles(-a, b, r);
    const c4 = new MCMaths.Circles(a, -b, r);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct graph for the equation of the circle $C$:`
      );
      question.addParagraph(`$C: ${c1.toString()}$`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addGraph(c1.graph(2));
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addGraph(c2.graph(2));
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addGraph(c3.graph(2));
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addGraph(c4.graph(2));

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_Circles_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = 0;
    const b = 0;
    const r = MCRandom.randomInt(5, 10);

    const c1 = new MCMaths.Circles(a, b, r);
    const c2 = new MCMaths.Circles(a, b, 2 * r);
    const c3 = new MCMaths.Circles(a, b, Math.sqrt(r));
    const c4 = new MCMaths.Circles(a, b, r / 2);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct graph for the equation of the circle $C$:`
      );
      question.addParagraph(`$C: ${c1.toString()}$`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addGraph(c1.graph(2));
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addGraph(c2.graph(2));
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addGraph(c3.graph(2));
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addGraph(c4.graph(2));

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_Circles_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const r = MCRandom.randomInt(2, 9);

    const c1 = new MCMaths.Circles(a, b, r);
    const c2 = new MCMaths.Circles(-a, -b, r);
    const c3 = new MCMaths.Circles(-a, b, r);
    const c4 = new MCMaths.Circles(a, -b, r);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct centre point for the equation of the circle $C$:`
      );
      question.addParagraph(`$C: ${c1.expandedString()}$`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$(${a}, ${b})$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$(${2 * a}, ${2 * b})$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$(${a ** 2}, ${b ** 2})$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$(${-a}, ${-b})$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_Circles_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const r = MCRandom.randomInt(2, 9);

    const c1 = new MCMaths.Circles(a, b, r);
    const c2 = new MCMaths.Circles(-a, -b, r);
    const c3 = new MCMaths.Circles(-a, b, r);
    const c4 = new MCMaths.Circles(a, -b, r);

    const working1 = Math.abs((-r * r + a * a + b * b) * -1);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct radius, $R$, for the equation of the circle $C$:`
      );
      question.addParagraph(`$C: ${c1.expandedString()}$`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$R = ${r}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$R = ${r ** 2}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$R = ${working1}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$R = \\sqrt{${working1}}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_StraightLines_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const gradient = MCRandom.randomInt(2, 9);
    const b = MCRandom.randomInt(2, 9);
    const x1 = MCRandom.randomInt(2, 9);
    const y1 = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(2, 9);

    const working1 = y1 - gradient * x1;
    const alpha = new MCMaths.Fraction((beta - working1) / gradient);

    const perpGradient = new MCMaths.Fraction(-1 / gradient);
    const working2 = perpGradient.multiply(alpha);
    const working3 = new MCMaths.Fraction(-working2.toFloat() + beta);

    /* END VARIABLES AND CONSTANTS */
    if (beta > y1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Given that the equation $y = ${gradient}x + ${b}$ is parallel to the line $AB$ passing through the points $(${x1},${y1})$ and $(\\alpha, ${beta})$`
      );
      question.addParagraph("question", `a) Find $\\alpha$.`);
      question.addParagraph(
        "question",
        `The line $PQ$ is perpendicular to $AB$ and passes through $(\\alpha, ${beta})$.`
      );
      question.addParagraph(
        "question",
        `b) Express the equation of the line $PQ$ in the form $y = mx + b$ where constants $m$ and $b$ are to be found.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) Line $AB: y - ${y1} = ${gradient}(x - ${x1})$`
      );
      question.addParagraph(
        "solution",
        `$y = ${gradient}x + ${y1} - ${gradient}(${x1})$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y = ${gradient}x+${working1}$`)
      );
      question.addParagraph(
        "solution",
        `When $x = \\alpha$ and $y = ${beta}$:`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${beta} = ${gradient}\\alpha+${working1}$`)
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\alpha = \\frac{${beta}+${-working1}}{${gradient}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\alpha = ${alpha.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\alpha = ${alpha.toFloat().toPrecision(3)}$`
      );

      question.addParagraph(
        "solution",
        `Perpendicular gradient $\\displaystyle = -\\frac{1}{${gradient}}$`
      );
      question.addParagraph(
        "solution",
        `So line $\\displaystyle PQ: y-${beta} = ${perpGradient.toString()}(x - ${alpha.toString()})$`
      );
      question.addParagraph(
        "solution",
        `So line $\\displaystyle y = ${perpGradient.toString()}(x - ${alpha.toString()}) + ${beta}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle y = ${perpGradient.toString()}x-${perpGradient.toString()}\\left(${alpha.toString()}\\right) + ${beta}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle y = ${perpGradient.toString()}x-${working2.toString()} + ${beta}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle y = ${perpGradient.toString()}x+${working3.toString()}$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore m = ${perpGradient
          .toFloat()
          .toPrecision(3)}, \\text{ } b = ${working3.toFloat().toPrecision(3)}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(alpha.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(alpha.toFloat().toPrecision(3)) -
            parseFloat(alpha.toFloat().toPrecision(2))
        ),
        "a) $\\text{ } \\alpha = $"
      );

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        parseFloat(perpGradient.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(perpGradient.toFloat().toPrecision(3)) -
            parseFloat(perpGradient.toFloat().toPrecision(2))
        ),
        "b) $\\text{ } m = $"
      );
      group2.addInput(
        parseFloat(working3.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(working3.toFloat().toPrecision(3)) -
            parseFloat(working3.toFloat().toPrecision(2))
        ),
        "b) $\\text{ } b = $"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_StraightLines_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pq_XCoeff = MCRandom.randomInt(-9, 9);
    const pq_YCoeff = MCRandom.randomInt(-9, 9);
    const pq_Constant = MCRandom.randomInt(-9, 9);

    const point_x1 = MCRandom.randomInt(-9, 9);
    const point_y1 = MCRandom.randomInt(-9, 9);

    const pr_XCoeff = MCRandom.randomInt(-9, 9);
    const pr_YCoeff = MCRandom.randomInt(-9, 9);
    const pr_Constant = MCRandom.randomInt(-9, 9);

    const partAGradient = new MCMaths.Fraction(-pq_XCoeff / pq_YCoeff);
    const partAConstant = new MCMaths.Fraction(pq_Constant / pq_YCoeff);

    const partBPerpGradient = new MCMaths.Fraction(
      -1 / partAGradient.toFloat()
    );
    const partB_a = partBPerpGradient.denominator;
    const partB_m = partBPerpGradient.numerator;
    const partB_b =
      partBPerpGradient.numerator * -point_x1 -
      partBPerpGradient.denominator * -point_y1;

    const partCGradient = new MCMaths.Fraction(-pr_XCoeff / pr_YCoeff);
    const partCConstant = new MCMaths.Fraction(pr_Constant / pr_YCoeff);
    const partC_x1Coeff = new MCMaths.Fraction(
      partAGradient.toFloat() - partCGradient.toFloat()
    );
    const partC_rhs = new MCMaths.Fraction(
      partCConstant.toFloat() - partAConstant.toFloat()
    );
    const partC_x1 = partC_rhs.multiply(1 / partC_x1Coeff.toFloat());
    const partC_y1 = partCGradient.multiply(partC_x1).add(partCConstant);

    const variableArray = [
      pq_XCoeff,
      pq_YCoeff,
      pq_Constant,
      point_x1,
      point_y1,
      pr_XCoeff,
      pr_YCoeff,
      pr_Constant,
    ];
    const testFunction = (variable) => {
      return Math.abs(variable) > 1;
    };

    /* END VARIABLES AND CONSTANTS */
    if (
      variableArray.every(testFunction) === true &&
      partBPerpGradient.denominator !== 1 &&
      partBPerpGradient.numerator !== 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `The line $PQ$ has equation $${pq_XCoeff}x+${pq_YCoeff}y = ${pq_Constant}$`
        )
      );
      question.addParagraph("question", `a) Find the gradient of $PQ$.`);
      question.addParagraph(
        "question",
        `b) Find an equation of the line that is perpendicular to the line $PQ$ and which passes through the point $(${point_x1}, ${point_y1})$.`
      );
      question.addParagraph(
        "question",
        `Express your answer in the form $ay = mx + b$, where $a$, $m$ and $b$ are integer constants to be found.`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `c) The line $PR$ has equation $${pr_XCoeff}x+${pr_YCoeff}y = ${pr_Constant}$. Find the coordinates of $P$.`
        )
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(`a) $PQ: ${pq_XCoeff}x+${pq_YCoeff}y=${pq_Constant}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${pq_YCoeff}y=${-pq_XCoeff}x+${pq_Constant}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle y=\\frac{${-pq_XCoeff}x+${pq_Constant}}{${pq_YCoeff}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle y=${partAGradient.toString()}x+${partAConstant.toString()}$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ gradient of $\\displaystyle PQ = ${partAGradient.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$= ${partAGradient.toFloat().toPrecision(3)}$`
      );

      question.addParagraph(
        "solution",
        `b) Perpendicular gradient = $\\displaystyle ${partBPerpGradient.toString()}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `So equation of the line: $\\displaystyle y-${point_y1}=${partBPerpGradient.toString()}(x-${point_x1})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\implies \\displaystyle ${
            partBPerpGradient.denominator
          }(y+${-point_y1})=${partBPerpGradient.numerator}(x+${-point_x1})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\implies \\displaystyle ${partBPerpGradient.denominator}y+${
            partBPerpGradient.denominator * -point_y1
          }=${partBPerpGradient.numerator}x+${
            partBPerpGradient.numerator * -point_x1
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\implies \\displaystyle ${partB_a}y=${partB_m}x+${partB_b}$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore a = ${partB_a}, \\text{ } m = ${partB_m}, \\text{ } b = ${partB_b}$`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `c) Line $PR: ${pr_XCoeff}x+${pr_YCoeff}y = ${pr_Constant}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle y = \\frac{${-pr_XCoeff}x+${pr_Constant}}{${pr_YCoeff}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle y = ${partCGradient.toString()}x+${partCConstant.toString()}$`
        )
      );
      question.addParagraph("solution", `Let $P = (x_1, y_1)$ then:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle ${partAGradient.toString()}x_1+${partAConstant.toString()} = ${partCGradient.toString()}x_1+${partCConstant.toString()}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\left(${partAGradient.toString()}-${partCGradient.toString()}\\right)x_1 = ${partCConstant.toString()}-${partAConstant.toString()}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle ${partC_x1Coeff}x_1 = ${partC_rhs}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle x_1 = ${partC_x1.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle y_1 = ${partCGradient.toString()}\\left(${partC_x1.toString()}\\right)+${partCConstant.toString()}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle y_1 = ${partC_y1.toString()}$`)
      );
      question.addParagraph(
        "solution",
        `So $P = (${partC_x1.toString()}, ${partC_y1.toString()})$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore P = (${partC_x1
          .toFloat()
          .toPrecision(3)}, ${partC_y1.toFloat().toPrecision(3)})$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(partAGradient.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(partAGradient.toFloat().toPrecision(3)) -
            parseFloat(partAGradient.toFloat().toPrecision(2))
        ),
        `a) gradient $\\text{ } =$`
      );
      const group2 = new MCQuestion.InputGroup(3);
      group2.addInput(partB_a, 0, `b) $\\text{ } a = $`);
      group2.addInput(partB_m, 0, `b) $\\text{ } m = $`);
      group2.addInput(partB_b, 0, `b) $\\text{ } b = $`);
      const group3 = new MCQuestion.InputGroup(2);
      group3.addInput(
        parseFloat(partC_x1.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(partC_x1.toFloat().toPrecision(3)) -
            parseFloat(partC_x1.toFloat().toPrecision(2))
        ),
        `c) $\\text{ } P_x = $`
      );
      group3.addInput(
        parseFloat(partC_y1.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(partC_y1.toFloat().toPrecision(3)) -
            parseFloat(partC_y1.toFloat().toPrecision(2))
        ),
        `c) $\\text{ } P_y = $`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_StraightLines_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const P_x = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const P_y = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const Q_x = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const Q_y = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const gradient = new MCMaths.Fraction((Q_y - P_y) / (Q_x - P_x));

    const a = -gradient.numerator;
    const b = gradient.denominator;
    const c = gradient.numerator * -P_x + gradient.denominator * P_y;

    const pointA = [0, new MCMaths.Fraction(c / b)];
    const pointB = [new MCMaths.Fraction(c / a), 0];

    const width = new MCMaths.Fraction(Math.abs(pointB[0].toFloat()));
    const height = new MCMaths.Fraction(Math.abs(pointA[1].toFloat()));

    const areaOAB = new MCMaths.Fraction(
      0.5 * width.toFloat() * height.toFloat()
    );

    /* END VARIABLES AND CONSTANTS */
    if (
      gradient !== 0 &&
      P_x !== Q_x &&
      P_y !== Q_y &&
      c !== 0 &&
      areaOAB.toFloat() > 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `The line $l$ passes through the points $(${P_x},${P_y})$ and $(${Q_x},${Q_y})$.`,
        `a) Given that line $l$ has the equation $ax + by = c$, find $a$, $b$ and $c$.`,
        `The line $l$ crosses the y-axis at point $A$ and the $x$-axis at point $B$. Point $O$ is the origin.`,
        `b) Find the area of the triangle $AOB$ to $3$ significant figures.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) Let $m$ be the gradient of $l$ then:`,
        MCMaths.cleaner(
          `$\\displaystyle m = \\frac{${Q_y}-${P_y}}{${Q_x}-${P_x}}$`
        ),
        MCMaths.cleaner(`$\\displaystyle m = ${gradient.toString()}$`),
        MCMaths.cleaner(
          `Then the equation for $l$ is: $\\displaystyle y+${-P_y} = ${gradient.toString()}(x+${-P_x})$`
        ),
        MCMaths.cleaner(
          `$\\displaystyle ${gradient.denominator}\\left(y+${-P_y}\\right) = ${
            gradient.numerator
          }(x+${-P_x})$`
        ),
        MCMaths.cleaner(
          `$\\displaystyle ${gradient.denominator}y+${
            gradient.denominator * -P_y
          } = ${gradient.numerator}x+${-gradient.numerator * P_x}$`
        ),
        MCMaths.cleaner(
          `$\\displaystyle ${-gradient.numerator}x+${gradient.denominator}y = ${
            gradient.numerator * -P_x
          }+${gradient.denominator * P_y} $`
        ),
        MCMaths.cleaner(`$\\displaystyle ${a}x+${b}y = ${c} $`),
        MCMaths.cleaner(
          `$\\therefore a = ${a}, \\text{ } b = ${b}, \\text{ } c = ${c}$.`
        ),

        MCMaths.cleaner(
          `HEADING b) $y$-coordinate for point $A = \\displaystyle ${a}(0)+${b}y = ${c} $`
        ),
        MCMaths.cleaner(`$\\displaystyle y = ${pointA[1].toString()} $`),
        MCMaths.cleaner(`So $A = (${pointA[0]}, ${pointA[1].toString()})$`),
        MCMaths.cleaner(
          `$x$-coordinate for point $B = \\displaystyle ${a}x+${b}(0) = ${c} $`
        ),
        MCMaths.cleaner(`$\\displaystyle x = ${pointB[0].toString()} $`),
        MCMaths.cleaner(`So $B = (${pointB[0].toString()}, ${pointB[1]})$`),
        MCMaths.cleaner(
          `Therefore the area of triangle $OAB = \\frac{1}{2}(${width.toString()})(${height.toString()})$`
        ),
        MCMaths.cleaner(
          `$\\therefore $ Area of $OAB = \\frac{1}{2}(${width.toString()})(${height.toString()})$`
        ),
        MCMaths.cleaner(`$= ${areaOAB.toFloat().toPrecision(3)}$.`, false),
      ]);

      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput(a, 0, "a) $\\text{ } a =$");
      group1.addInput(b, 0, "a) $\\text{ } b =$");
      group1.addInput(c, 0, "a) $\\text{ } c =$");

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(areaOAB.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(areaOAB.toFloat().toPrecision(3)) -
            parseFloat(areaOAB.toFloat().toPrecision(2))
        ),
        "b) Area $\\text{ } OAB =$"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_StraightLines_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const lambdaScaleFactor =
      MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const alpha_1 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const beta_1 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const alpha_2 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const beta_2 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const gradientNumerator = MCRandom.randomInt(-9, 9);
    const gradientDenominator = MCRandom.randomInt(-9, 9);
    const gradient = new MCMaths.Fraction(
      gradientNumerator / gradientDenominator
    );

    const lambdaDenominator =
      -lambdaScaleFactor * gradient.denominator -
      lambdaScaleFactor * gradient.numerator;
    const lambdaNumerator =
      -(alpha_2 * gradient.numerator) +
      (alpha_1 * gradient.numerator - gradient.denominator * (beta_1 - beta_2));
    const lambda = new MCMaths.Fraction(lambdaNumerator / lambdaDenominator);

    const pointP = [alpha_1 + lambdaScaleFactor * lambda, beta_1];
    const pointQ = [alpha_2, beta_2 + lambdaScaleFactor * lambda];

    const working1 = new MCMaths.Fraction(
      beta_2 + lambdaScaleFactor * lambda.toFloat()
    );
    const working2 = new MCMaths.Fraction(
      -working1.toFloat() * gradient.denominator
    );
    const working3 = new MCMaths.Fraction(
      -alpha_2 * gradient.numerator - working2.toFloat()
    );

    const a = gradient.denominator;
    const b = -gradient.numerator;
    const c = parseFloat(working3.toFloat().toPrecision(3));

    const normalGradient = new MCMaths.Fraction(-1 / gradient.toFloat());
    const normalEquation = new MCMaths.Polynomial(
      [gradient.toFloat(), working3.toFloat() / gradient.numerator],
      "x"
    ).normal(pointQ[0]);

    const working4 = new MCMaths.Fraction(
      beta_2 + lambdaScaleFactor * lambda.toFloat()
    );
    const working5 = new MCMaths.Fraction(
      -working4.toFloat() * normalGradient.denominator
    );
    const working6 = new MCMaths.Fraction(
      -alpha_2 * normalGradient.numerator - working5.toFloat()
    );
    const p = normalGradient.denominator;
    const q = -normalGradient.numerator;
    const r = parseFloat(working6.toFloat().toPrecision(3));

    /* END VARIABLES AND CONSTANTS */
    if (
      gradientNumerator !== 0 &&
      gradientDenominator !== 0 &&
      Math.abs(gradient.toFloat()) !== 1 &&
      beta_1 - beta_2 !== 0 &&
      working1.toFloat() !== 0 &&
      `${working1.numerator}`.length <= 3 &&
      `${working2.numerator}`.length <= 3 &&
      `${working3.numerator}`.length <= 3 &&
      `${working4.numerator}`.length <= 3 &&
      `${working5.numerator}`.length <= 3 &&
      `${working6.numerator}`.length <= 3
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A line $l_{1}$ interesects two distinct points $P$ and $Q$ with coordinates $(${alpha_1}+${lambdaScaleFactor}k,\\text{ } ${beta_1}), (${alpha_2},\\text{ } ${beta_2}+${lambdaScaleFactor}k)$ respectively, where $k$ is a constant.`
        )
      );
      question.addParagraph(
        "question",
        `Given that the gradient, $m$, of $l_{1}$ is $\\displaystyle ${gradient.toString()},$`
      );
      question.addParagraph("question", `a) Find $k$.`);
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, find the equation of the line $l_{1}$ in the form $ax + by = c$ where $a$, $b$ and $c$ are constants to be found.`
      );
      question.addParagraph(
        "question",
        `The line $l_{2}$ is the line such that $l_{2}$ is perpendicular to $l_1$ and passes through point $Q$.`
      );
      question.addParagraph(
        "question",
        `c) Find the equation of the line $l_{2}$ in the form $px + qy = r$ where $p$, $q$ and $r$ are constants to be found.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `a) Line $\\displaystyle l_1: y-(${beta_2}+${lambdaScaleFactor}k)=${gradient.toString()}(x-${alpha_2})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\implies y+${-beta_2}+${-lambdaScaleFactor}k=${gradient.toString()}(x-${alpha_2})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `When $x = (${alpha_1}+${lambdaScaleFactor}k)$ and $y = ${beta_1},$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\implies ${beta_1}+${-beta_2}+${-lambdaScaleFactor}k=${gradient.toString()}((${alpha_1}+${lambdaScaleFactor}k)-${alpha_2})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle ${gradient.denominator}(${
            beta_1 - beta_2
          }+${-lambdaScaleFactor}k)  =  ${
            gradient.numerator
          }(${alpha_1}+${lambdaScaleFactor}k)+${-(
            alpha_2 * gradient.numerator
          )}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle ${gradient.denominator * (beta_1 - beta_2)}+${
            -lambdaScaleFactor * gradient.denominator
          }k  =  ${alpha_1 * gradient.numerator}+${
            lambdaScaleFactor * gradient.numerator
          }k+${-(alpha_2 * gradient.numerator)}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle ${gradient.denominator * (beta_1 - beta_2)}+${
            -lambdaScaleFactor * gradient.denominator
          }k  =  ${lambdaScaleFactor * gradient.numerator}k+${
            -(alpha_2 * gradient.numerator) + alpha_1 * gradient.numerator
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle ${
            -lambdaScaleFactor * gradient.denominator -
            lambdaScaleFactor * gradient.numerator
          }k  =  ${
            -(alpha_2 * gradient.numerator) +
            (alpha_1 * gradient.numerator -
              gradient.denominator * (beta_1 - beta_2))
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle k  =  \\frac{${lambdaNumerator}}{${lambdaDenominator}}$`
        )
      );
      if (
        lambda.toString() !== `\\frac{${lambdaNumerator}}{${lambdaDenominator}}`
      ) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\displaystyle k  =  ${lambda.toString()}$`)
        );
      }
      question.addParagraph(
        "solution",
        `$\\displaystyle k  =  ${lambda.toFloat().toPrecision(3)}$`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `b) Line $\\displaystyle l_1: y-\\left(${beta_2}+${lambdaScaleFactor}\\left(${lambda.toString()}\\right)\\right)=${gradient.toString()}(x-${alpha_2})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle ${
            gradient.denominator
          }\\left(y-\\left(${working1.toString()}\\right)\\right)=${
            gradient.numerator
          }x+${-alpha_2 * gradient.numerator}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle ${gradient.denominator}y+${working2.toString()}=${
            gradient.numerator
          }x+${-alpha_2 * gradient.numerator}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle ${
            gradient.denominator
          }y+${-gradient.numerator}x = ${working3.toString()}$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore a = ${a},\\text{ } b = ${b},\\text{ } c = ${c}$.`
      );

      question.addParagraph(
        "solution",
        `c) Perpendicular gradient for $l_2: ${normalGradient.toString()}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `Line $\\displaystyle l_2: y-\\left(${beta_2}+${lambdaScaleFactor}\\left(${lambda.toString()}\\right)\\right)=${normalGradient.toString()}(x-${alpha_2})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle ${
            normalGradient.denominator
          }\\left(y-\\left(${working4.toString()}\\right)\\right)=${
            normalGradient.numerator
          }x+${-alpha_2 * normalGradient.numerator}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle ${
            normalGradient.denominator
          }y+${working5.toString()}=${normalGradient.numerator}x+${
            -alpha_2 * normalGradient.numerator
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle ${
            normalGradient.denominator
          }y+${-normalGradient.numerator}x = ${working6.toString()}$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore p = ${p},\\text{ } q = ${q},\\text{ } r = ${r}$.`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(lambda.toFloat().toPrecision(3)),
        0,
        `a) $\\text{ } k =$`
      );
      const group2 = new MCQuestion.InputGroup(3);
      group2.addInput(a, 0, `b) $\\text{ } a =$`);
      group2.addInput(b, 0, `b) $\\text{ } b =$`);
      group2.addInput(c, 0, `b) $\\text{ } c =$`);
      const group3 = new MCQuestion.InputGroup(3);
      group3.addInput(p, 0, `c) $\\text{ } p =$`);
      group3.addInput(q, 0, `c) $\\text{ } q =$`);
      group3.addInput(r, 0, `c) $\\text{ } r =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_StraightLines_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const m_0 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const b_0 = MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);
    const l0 = new MCMaths.Polynomial([m_0, b_0], "x");
    const l0Coefficients = l0.getCoefficients();

    const p1 = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];

    const m_1 = m_0;
    const b_1 = -p1[0] * m_0 + p1[1];

    const l1 = new MCMaths.Polynomial([m_1, b_1], "x");

    const l2 = l1.normal(p1[0]);
    const l2Coefficients = l2.getCoefficients();
    const m_2 = l2Coefficients[0];
    const b_2 = l2Coefficients[1];
    const l2Working = l1.normalWorking(p1[0]);

    const p2Working = l2.subtract(l1);
    const p2WorkingCoefficients = p2Working.getCoefficients();
    const checkCondition = new MCMaths.Fraction(p2WorkingCoefficients[1]);
    const p2_x = new MCMaths.Fraction(
      -p2WorkingCoefficients[1] / p2WorkingCoefficients[0]
    );
    const p2_y = new MCMaths.Fraction(l0.evaluate(p2_x.toFloat()));

    /* END VARIABLES AND CONSTANTS */
    if (`${checkCondition.denominator}`.length <= 3) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Given the following equation of a line:`
      );
      question.addParagraph("question", `$l_0: y = ${l0.toString()}$`);
      question.addParagraph(
        "question",
        `Let the equation of the line that is parallel to $l_0$ and that goes through $(${p1[0]},${p1[1]})$ be denoted by $l_1$.`
      );
      question.addParagraph(
        "question",
        `$l_1$ can be expressed as $y = m_{1}x + b_{1}$`
      );
      question.addParagraph("question", `a) Find $m_1$ and $b_1$.`);
      question.addParagraph(
        "question",
        `Let the equation of the line that is perpendicular to $l_0$ and that goes through $(a,b)$ be denoted by $l_2$.`
      );
      question.addParagraph(
        "question",
        `$l_2$ can be expressed as $y = m_{2}x + b_{2}$`
      );
      question.addParagraph("question", `b) Find $m_2$ and $b_2$.`);
      question.addParagraph(
        "question",
        `c) Hence, or otherwise, find the point of intersection between $l_1$ and $l_2$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(`a) $l_1: y-${p1[1]} = ${m_0}(x-${p1[0]})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y-${p1[1]} = ${m_0}x-${p1[0] * m_0}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y = ${m_0}x+${-p1[0] * m_0 + p1[1]}$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore m_{1} = ${m_1}, \\text{ } b_{1} = ${b_1}$`
      );

      question.addParagraph("solution", `b)`);
      question.addMultipleParagraphs("solution", l2Working);
      question.addParagraph(
        "solution",
        `$\\therefore m_2 = ${m_2.toPrecision(
          3
        )}, \\text{ } b_2 = ${b_2.toPrecision(3)}$`
      );

      question.addParagraph(
        "solution",
        `c) $x$-coordinate of the point of intersection is gained from solving: $${l0.toString()} = ${l2.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\implies ${p2Working.toString()} = 0$`
      );
      question.addParagraph("solution", `$x = ${p2_x.toString()}$`);
      question.addParagraph(
        "solution",
        `$\\therefore x = ${p2_x.toFloat().toPrecision(3)}$`
      );
      question.addParagraph(
        "solution",
        `$y$-coordinate is gained from evaluating $l_0$ at $x = ${p2_x.toString()}$:`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$y = ${l0Coefficients[0]}\\left(${p2_x.toString()}\\right)+${
            l0Coefficients[1]
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y = ${p2_y.toString()}$`)
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        parseFloat(m_1.toPrecision(3)),
        Math.abs(
          parseFloat(m_1.toPrecision(3)) - parseFloat(m_1.toPrecision(2))
        ),
        `a) $\\text{ } m_1 =$`
      );
      group1.addInput(
        parseFloat(b_1.toPrecision(3)),
        Math.abs(
          parseFloat(b_1.toPrecision(3)) - parseFloat(b_1.toPrecision(2))
        ),
        `a) $\\text{ } b_1 =$`
      );
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        parseFloat(m_2.toPrecision(3)),
        Math.abs(
          parseFloat(m_2.toPrecision(3)) - parseFloat(m_2.toPrecision(2))
        ),
        `b) $\\text{ } m_2 =$`
      );
      group2.addInput(
        parseFloat(b_2.toPrecision(3)),
        Math.abs(
          parseFloat(b_2.toPrecision(3)) - parseFloat(b_2.toPrecision(2))
        ),
        `b) $\\text{ } b_2 =$`
      );
      const group3 = new MCQuestion.InputGroup(2);
      group3.addInput(
        parseFloat(p2_x.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(p2_x.toFloat().toPrecision(3)) -
            parseFloat(p2_x.toFloat().toPrecision(2))
        ),
        `c) $\\text{ } x$-coordinate$\\text{ } =$`
      );
      group3.addInput(
        parseFloat(p2_y.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(p2_y.toFloat().toPrecision(3)) -
            parseFloat(p2_y.toFloat().toPrecision(2))
        ),
        `c) $\\text{ } y$-coordinate$\\text{ } =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_StraightLines_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pointA = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const pointB = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const m_0 = new MCMaths.Fraction(
      (pointB[1] - pointA[1]) / (pointB[0] - pointA[0])
    );

    const pointM = [
      pointA[0] + (pointB[0] - pointA[0]) / 2,
      pointA[1] + (pointB[1] - pointA[1]) / 2,
    ];
    const M_x = new MCMaths.Fraction(pointM[0]);
    const M_y = new MCMaths.Fraction(pointM[1]);

    const m_1 = new MCMaths.Fraction(-1 / m_0.toFloat());
    const b_1 = new MCMaths.Fraction(M_y - m_1.toFloat() * M_x);

    /* END VARIABLES AND CONSTANTS */
    if (
      m_0.toFloat() !== 0 &&
      m_1.toFloat() !== 0 &&
      Math.abs(m_0.toFloat()) !== Infinity &&
      Math.abs(m_1.toFloat()) !== Infinity &&
      `${b_1.numerator}`.length <= 3 &&
      pointA.join() !== pointB.join()
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The line $l_0$ intersects two distinct points $A$ and $B$ with coordinates $(${pointA[0]}, ${pointA[1]})$ and $(${pointB[0]}, ${pointB[1]})$ respectively.`
      );
      question.addParagraph(
        "question",
        `Let $M$ be the midpoint between $A$ and $B$.`
      );
      question.addParagraph(
        "question",
        `a) Find the gradient, denoted by $m_0$, of the line $l_0$.`
      );
      question.addParagraph(
        "question",
        `The line $l_1$ passes through $M$ and is perpendicular to the line $l_0$.`
      );
      question.addParagraph(
        "question",
        `b) Find the coordinates of the point $M$.`
      );
      question.addParagraph(
        "question",
        `c) Hence, or otherwise, find the equation of the line $l_1$ in the form $y = m_{1}x + b_{1}$ where $m_{1}$ and $b_{1}$ are constants to be found.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `a) $\\displaystyle m_0 = \\frac{${pointB[1]}-${pointA[1]}}{${pointB[0]}-${pointA[0]}}$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle m_0 = ${m_0.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\displaystyle m_0 = ${m_0.toFloat().toPrecision(3)}$`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `b) $\\displaystyle M = \\left(${pointA[0]} + \\frac{${pointB[0]}-${pointA[0]}}{2},${pointA[1]} +  \\frac{${pointB[1]}-${pointA[1]}}{2} \\right)$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle M = \\left(${M_x.toString()}, ${M_y.toString()} \\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore M_x = ${M_x.toFloat().toPrecision(
          3
        )}, \\text{ } M_y = ${M_y.toFloat().toPrecision(3)}$`
      );

      question.addParagraph(
        "solution",
        `Perpendicular gradient $\\displaystyle m_1 = \\frac{-1}{m_0}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle m_1 = ${m_1.toString()}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `Line $\\displaystyle l_1: y-${M_y.toString()}=${m_1.toString()}\\left(x-${M_x.toString()}\\right)$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle y=${m_1.toString()}\\left(x-${M_x.toString()}\\right)+${M_y.toString()}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle y= ${m_1.toString()}x+${b_1.toString()}$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore m_1 = ${m_1
          .toFloat()
          .toPrecision(3)}, \\text{ } b_1 = ${b_1.toFloat().toPrecision(3)}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(m_0.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(m_0.toFloat().toPrecision(3)) -
            parseFloat(m_0.toFloat().toPrecision(2))
        ),
        `a) $\\text{ } m_0 =$`
      );

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        parseFloat(M_x.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(M_x.toFloat().toPrecision(3)) -
            parseFloat(M_x.toFloat().toPrecision(2))
        ),
        `b) $\\text{ } M_x =$`
      );
      group2.addInput(
        parseFloat(M_y.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(M_y.toFloat().toPrecision(3)) -
            parseFloat(M_y.toFloat().toPrecision(2))
        ),
        `b) $\\text{ } M_y =$`
      );

      const group3 = new MCQuestion.InputGroup(2);
      group2.addInput(
        parseFloat(m_1.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(m_1.toFloat().toPrecision(3)) -
            parseFloat(m_1.toFloat().toPrecision(2))
        ),
        `c) $\\text{ } m_1 =$`
      );
      group2.addInput(
        parseFloat(b_1.toFloat().toPrecision(3)),
        Math.abs(
          parseFloat(b_1.toFloat().toPrecision(3)) -
            parseFloat(b_1.toFloat().toPrecision(2))
        ),
        `c) $\\text{ } b_1 =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_StraightLines_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const answer1_A = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const answer1_B = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const answer1Gradient =
      (answer1_B[1] - answer1_A[1]) / (answer1_B[0] - answer1_A[0]);

    const answer2_A = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const answer2_B = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const answer2Gradient =
      (answer2_B[1] - answer2_A[1]) / (answer2_B[0] - answer2_A[0]);

    const answer3_A = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const answer3_B = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const answer3Gradient =
      (answer3_B[1] - answer3_A[1]) / (answer3_B[0] - answer3_A[0]);

    const answer4_A = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const answer4_B = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const answer4Gradient =
      (answer4_B[1] - answer4_A[1]) / (answer4_B[0] - answer4_A[0]);

    let check1 = false;
    switch (MCRandom.randomInt(0, 2)) {
      case 0:
        check1 = answer1Gradient === answer2Gradient;
        break;

      case 1:
        check1 =
          answer1Gradient === answer2Gradient &&
          answer1Gradient === answer3Gradient;
        break;

      case 2:
        check1 =
          answer1Gradient === answer2Gradient &&
          answer1Gradient === answer3Gradient &&
          answer1Gradient === answer4Gradient;
        break;

      default:
        check1 = false;
        break;
    }

    let check2 = true;
    if (
      answer1_A.join() === answer3_A.join() &&
      answer1_B.join() === answer3_B.join()
    ) {
      check2 = false;
    }
    if (
      answer2_A.join() === answer4_A.join() &&
      answer2_B.join() === answer4_B.join()
    ) {
      check2 = false;
    }
    if (
      answer1_A.join() === answer4_A.join() &&
      answer1_B.join() === answer4_B.join()
    ) {
      check2 = false;
    }
    if (
      answer2_A.join() === answer3_A.join() &&
      answer2_B.join() === answer3_B.join()
    ) {
      check2 = false;
    }

    const masterGradient = answer1Gradient;
    const answerGradientArray = [
      answer1Gradient,
      answer2Gradient,
      answer3Gradient,
      answer4Gradient,
    ];
    const answerBoolArray = [false, false, false, false];
    for (let index = 0; index <= answerGradientArray.length - 1; index += 1) {
      if (answerGradientArray[index] === masterGradient) {
        answerBoolArray[index] = true;
      }
    }

    const checkFunction = (grad) => {
      return grad !== 0 && Math.abs(grad) !== Infinity;
    };

    /* END VARIABLES AND CONSTANTS */
    if (
      check1 &&
      check2 === true &&
      answerGradientArray.every(checkFunction) === true
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(`Select all lines that have the same gradient:`);
      question.addParagraph(
        `(The arrow represents what coordinates the line passes through)`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$l: (${answer1_A[0]},${answer1_A[1]}) \\rightarrow (${answer1_B[0]},${answer1_B[1]})$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$l: (${answer2_A[0]},${answer2_A[1]}) \\rightarrow (${answer2_B[0]},${answer2_B[1]})$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$l: (${answer3_A[0]},${answer3_A[1]}) \\rightarrow (${answer3_B[0]},${answer3_B[1]})$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$l: (${answer4_A[0]},${answer4_A[1]}) \\rightarrow (${answer4_B[0]},${answer4_B[1]})$`
      );

      question.addAnswer(answer1, answerBoolArray[0]);
      question.addAnswer(answer2, answerBoolArray[1]);
      question.addAnswer(answer3, answerBoolArray[2]);
      question.addAnswer(answer4, answerBoolArray[3]);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_StraightLines_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const answerBoolArray = [true, true, false, false];

    const answer1Gradient = new MCMaths.Fraction(
      (MCRandom.randomInt(1, 180) / 10) * MCRandom.randomInt(-1, 1, 2)
    );
    const answer1Constant =
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const answer2Gradient = new MCMaths.Fraction(
      -1 / answer1Gradient.toFloat()
    );
    const answer2Constant =
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const answer3Gradient = new MCMaths.Fraction(1 / answer1Gradient.toFloat());
    const answer3Constant =
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const answer4Gradient = new MCMaths.Fraction(
      (MCRandom.randomInt(1, 180) / 10) * MCRandom.randomInt(-1, 1, 2)
    );
    const answer4Constant =
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    /* END VARIABLES AND CONSTANTS */
    if (answer4Gradient.toFloat() !== answer2Gradient.toFloat()) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the two lines that are perpendicular to each other:`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle y = ${answer1Gradient.toString()}x+${answer1Constant}$`
        )
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle y = ${answer2Gradient.toString()}x+${answer2Constant}$`
        )
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle y = ${answer3Gradient.toString()}x+${answer3Constant}$`
        )
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle y = ${answer4Gradient.toString()}x+${answer4Constant}$`
        )
      );

      question.addAnswer(answer1, answerBoolArray[0]);
      question.addAnswer(answer2, answerBoolArray[1]);
      question.addAnswer(answer3, answerBoolArray[2]);
      question.addAnswer(answer4, answerBoolArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_StraightLines_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const answerBoolArray = [true, true, false, false];

    const answer1Gradient = new MCMaths.Fraction(
      (MCRandom.randomInt(1, 180) / 10) * MCRandom.randomInt(-1, 1, 2)
    );
    const answer1Constant =
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const answer2Gradient = new MCMaths.Fraction(answer1Gradient.toFloat());
    const answer2Constant =
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const answer3Gradient = new MCMaths.Fraction(-answer1Gradient.toFloat());
    const answer3Constant =
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    const answer4Gradient = new MCMaths.Fraction(
      (MCRandom.randomInt(1, 180) / 10) * MCRandom.randomInt(-1, 1, 2)
    );
    const answer4Constant =
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2);

    /* END VARIABLES AND CONSTANTS */
    if (answer4Gradient.toFloat() !== answer2Gradient.toFloat()) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the two lines that are parallel to each other:`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle y = ${answer1Gradient.toString()}x+${answer1Constant}$`
        )
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle y = ${answer2Gradient.toString()}x+${answer2Constant}$`
        )
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle y = ${answer3Gradient.toString()}x+${answer3Constant}$`
        )
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle y = ${answer4Gradient.toString()}x+${answer4Constant}$`
        )
      );

      question.addAnswer(answer1, answerBoolArray[0]);
      question.addAnswer(answer2, answerBoolArray[1]);
      question.addAnswer(answer3, answerBoolArray[2]);
      question.addAnswer(answer4, answerBoolArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_CoordinateGeometry_StraightLines_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const pointA = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const pointB = [
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 9) * MCRandom.randomInt(-1, 1, 2),
    ];
    const answer1Midpoint = [
      pointA[0] + (pointB[0] - pointA[0]) / 2,
      pointA[1] + (pointB[1] - pointA[1]) / 2,
    ];
    const answer2Midpoint = [
      (pointB[0] - pointA[0]) / 2,
      (pointB[1] - pointA[1]) / 2,
    ];
    const answer3Midpoint = [
      pointA[0] - (pointB[0] - pointA[0]) / 2,
      pointA[1] - (pointB[1] - pointA[1]) / 2,
    ];
    const answer4Midpoint = [
      pointB[0] + (pointB[0] - pointA[0]) / 2,
      pointB[1] + (pointB[1] - pointA[1]) / 2,
    ];

    const answerBoolArray = [true, false, false, false];

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct midpoint between points $A$ and $B$:`
      );
      question.addParagraph(
        `$A = (${pointA[0]}, ${pointA[1]}), \\text{ } B = (${pointB[0]}, ${pointB[1]})$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(
        `$M = (${answer1Midpoint[0]}, ${answer1Midpoint[1]})$`
      );
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(
        `$M = (${answer2Midpoint[0]}, ${answer2Midpoint[1]})$`
      );
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(
        `$M = (${answer3Midpoint[0]}, ${answer3Midpoint[1]})$`
      );
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(
        `$M = (${answer4Midpoint[0]}, ${answer4Midpoint[1]})$`
      );

      question.addAnswer(answer1, answerBoolArray[0]);
      question.addAnswer(answer2, answerBoolArray[1]);
      question.addAnswer(answer3, answerBoolArray[2]);
      question.addAnswer(answer4, answerBoolArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Data_Sampling_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const physics = MCRandom.randomInt(20, 60);
    const chemistry = MCRandom.randomInt(20, 60);
    const biology = MCRandom.randomInt(20, 60);

    const head = MCMisc.getName();

    const population = physics + chemistry + biology;

    const size = MCRandom.randomInt(20, 50, 5);

    const ps = (size / population) * physics;
    const cs = (size / population) * chemistry;
    const bs = (size / population) * biology;
    /* END VARIABLES AND CONSTANTS */
    if (Math.round(bs) + Math.round(ps) + Math.round(cs) === size) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `${head.name} is the head of a sixth form.`
      );
      question.addParagraph(
        "question",
        `${head.HeShe} wants to obtain the opinions of science students on the practical aspect of their course.`
      );
      question.addParagraph(
        "question",
        `The school has $${physics}$ physics students, $${chemistry}$ chemistry students and $${biology}$ biology students and each student only studies a single science subject.`
      );
      question.addParagraph(
        "question",
        `a) Explain how ${head.name} would take a stratified sample of size $${size}$`
      );
      question.addParagraph(
        "question",
        `b) State one advantage of taking a stratified sample over a simple random sample`
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) Explain how ${head.name} would take a stratified sample of size $${size}$`
      );
      question.addParagraph(
        "solution",
        `$${physics}+${chemistry}+${biology}=${population}$`
      );
      question.addHeading(
        "solution",
        `Split the population into representitive groups based on their subject`
      );
      question.addHeading("solution", `Physics`);
      question.addParagraph(
        "solution",
        `$\\frac{${size}}{${population}}\\times ${physics}=${ps.toFixed(2)}$`
      );
      question.addParagraph("solution", `$\\approx ${Math.round(ps)}$`);
      question.addHeading("solution", `Chemistry`);
      question.addParagraph(
        "solution",
        `$\\frac{${size}}{${population}}\\times ${chemistry}=${cs.toFixed(2)}$`
      );
      question.addParagraph("solution", `$\\approx ${Math.round(cs)}$`);
      question.addHeading("solution", `Biology`);
      question.addParagraph(
        "solution",
        `$\\frac{${size}}{${population}}\\times ${biology}=${bs.toFixed(2)}$`
      );
      question.addParagraph("solution", `$\\approx ${Math.round(bs)}$`);
      question.addParagraph(
        "solution",
        `Then use another sampling method (e.g. simple random, systematic, opportunity) to obtain opinions from $${Math.round(
          ps
        )}$ physics students, $${Math.round(
          cs
        )}$ chemistry students and $${Math.round(bs)}$ biology students`
      );
      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(Math.round(ps), 0, "physics", "after");
      g1.addInput(Math.round(cs), 0, "chemistry", "after");
      g1.addInput(Math.round(bs), 0, "biology", "after");
      question.addInputGroup(g1);
      // b
      question.addHeading(
        "solution",
        `b) State one advantage of taking a stratified sample over a simple random sample`
      );
      question.addParagraph(
        "solution",
        `A stratified sample is representitive of the population of students whereas a simple random sample may not be`
      );

      //
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Data_Sampling_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const head = MCMisc.getName();

    const population = MCRandom.randomInt(120, 200);

    const size = MCRandom.randomInt(50, 100, 5);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `${head.name} is the head of a secondary school.`
      );
      question.addParagraph(
        "question",
        `${head.HeShe} wants to obtain the opinions of GCSE students on the quality of maths lessons in school.`
      );
      question.addParagraph(
        "question",
        `The school has ${population} students studying for their maths GCSE.`
      );
      question.addParagraph(
        "question",
        `a) Explain how ${head.name} would take a simple random sample of size $${size}$`
      );
      question.addParagraph(
        "question",
        `b) State one advantage of taking a simple random sample over a stratified sample`
      );
      // a
      question.addHeading(
        "solution",
        `a) Explain how ${head.name} would take a simple random sample of size $${size}$`
      );
      question.addParagraph(
        "solution",
        `First he would obtain a list of all year $10$ and $11$ students and number them from $1$ to $${population}$`
      );
      question.addParagraph(
        "solution",
        `Then use a random number generator to select a student from the list based on their assigned number`
      );
      question.addParagraph("solution", `Ignore any repeated numbers`);
      question.addParagraph(
        "solution",
        `Continue generating numbers until you have identified $${size}$ unique students`
      );
      // b
      question.addHeading(
        "solution",
        `b) State one advantage of taking a simple random sample over a stratified sample`
      );
      question.addParagraph(
        "solution",
        `A simple random sample is easier to carry out than a stratified sample`
      );
      //
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Data_Sampling_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const size = MCRandom.randomInt(50, 100, 5);
    const every = MCRandom.randomInt(50, 100, 10);

    const capacity = MCRandom.randomInt(50, 100, 5);

    const method = ["opportunity", "systematic"][MCRandom.randomInt(0, 1)];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      if (method === "opportunity") {
        question.addParagraph(
          "question",
          `A company sells fuses and claims that the average breaking capacity of the fuses is $${capacity}A$`
        );
        question.addParagraph(
          "question",
          `They base this average on a sample of the first $${size}$ fuses produced in the factory.`
        );
        question.addParagraph(
          "question",
          `a) State the name of the sampling method used`
        );
        question.addParagraph(
          "question",
          `b) State one disadvanatage of the sampling method used`
        );
      } else {
        question.addParagraph(
          "question",
          `A company sells fuses and claims that the average breaking capacity of the fuses is $${capacity}A$`
        );
        question.addParagraph(
          "question",
          `They collect this average by testing every $${every}$th fuse produced in the factory in the first year of production.`
        );
        question.addParagraph(
          "question",
          `a) State the name of the sampling method used`
        );
        question.addParagraph(
          "question",
          `b) State one advantage of the sampling method used`
        );
      }
      question.addParagraph(
        "question",
        `c) Explain why the company chooses to use a sample, rather than testing all of the fuses they produce, other than it being cheaper and easier to do`
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) State the name of the sampling method used`
      );
      if (method === "opportunity") {
        question.addParagraph("solution", `Opportunity/Convenience sampling`);
      } else {
        question.addParagraph("solution", `Systematic sampling`);
      }
      // b
      if (method === "opportunity") {
        question.addHeading(
          "solution",
          `b) State one disadvanatage of the sampling method used`
        );
        question.addParagraph(
          "solution",
          `Opportunity sampling may introduce bias as the first number of fuses may be of higher quality than the last produced in a batch`
        );
      } else {
        question.addHeading(
          "solution",
          `b) State one advantage of the sampling method used`
        );
        question.addParagraph(
          "solution",
          `Systematic sampling is suited to large data sets`
        );
        question.addHeading("solution", "OR");
        question.addParagraph(
          "solution",
          `Systematic sampling is simple to use`
        );
      }
      // c
      question.addHeading(
        "solution",
        `c) Explain why the company chooses to use a sample, rather than testing all of the fuses they produce, other than it being cheaper and easier to do`
      );
      question.addParagraph(
        "solution",
        `As they're testing the breaking capacity, measuring it will destroy the product.`
      );
      question.addParagraph(
        "solution",
        `They can't test all of the fuses or they would have none left to sell.`
      );
      //
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Data_Sampling_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const size = MCRandom.randomInt(200, 1000, 100);
    const mult = MCRandom.randomInt(5, 20);

    const person = MCMisc.getName();

    const method = ["simple random", "systematic", "opportunity"][
      MCRandom.randomInt(0, 2)
    ];
    const give = ["advantage", "disadvantage"][MCRandom.randomInt(0, 1)];
    /* END VARIABLES AND CONSTANTS */
    if (((size * mult) / 1000) % 1 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The Government wants to measure the success of a new campaign launched in London by asking for the opinion of registered voters living in London.`
      );
      // method
      // simple random
      // systematic
      // opportunity
      if (method === "opportunity") {
        question.addParagraph(
          "question",
          `a) Explain how the Government could collect an opportunity sample of size $${size}$`
        );
      } else {
        question.addParagraph(
          "question",
          `a) Explain how the Government could collect a ${method} sample of size $${size}$ from an available group of $${
            (mult * size) / 1000
          },000$ people`
        );
      }
      if (give === "advantage") {
        question.addParagraph(
          "question",
          "b) Given an advantage of using a sample rather than a census from all available people"
        );
      } else {
        question.addParagraph(
          "question",
          "b) Given a disadvantage of using a sample rather than a census from all available people"
        );
      }
      question.addParagraph(
        "question",
        "c) Explain whether or not it would be reasonable for the Government to use their results to draw conclusions about how successful the campaign would be across the whole of the U.K."
      );
      // solution
      // a
      if (method === "opportunity") {
        question.addHeading(
          "solution",
          `a) Explain how the Government could collect an opportunity sample of size $${size}$`
        );
      } else {
        question.addHeading(
          "solution",
          `a) Explain how the Government could collect a ${method} sample of size $${size}$ from an available group of $${
            (mult * size) / 1000
          },000$ people`
        );
      }
      if (method === "opportunity") {
        question.addHeading("solution", `One of the following or similar`);
        question.addParagraph(
          "solution",
          `They could survey the first $${size}$ people on the street`
        );
        question.addParagraph(
          "solution",
          `They could use the first $${size}$ responses they receive from a survey sent to everyone in the region`
        );
      } else if (method === "systematic") {
        question.addParagraph(
          "solution",
          `$\\frac{${mult * size}}{${size}}=${mult}$`
        );
        question.addParagraph(
          "solution",
          `Obtain the list of available people and assign every person a number`
        );
        question.addParagraph(
          "solution",
          `Select the first person to use by generating a random number from 1 to $${mult}$`
        );
        question.addParagraph(
          "solution",
          `Then select every $${mult}$th person from the list`
        );
      } else {
        question.addParagraph(
          "solution",
          `Obtain the list of available people and assign every person a number from 1 to $${
            mult * size
          }$`
        );
        question.addParagraph(
          "solution",
          `Then use a random number generator to select a person from the list based on their assigned number`
        );
        question.addParagraph("solution", `Ignore any repeated numbers`);
        question.addParagraph(
          "solution",
          `Continue generating numbers until you have identified $${size}$ unique people to survey`
        );
      }
      // b
      if (give === "advantage") {
        question.addHeading(
          "solution",
          "b) Given an advantage of using a sample rather than a census from all available people"
        );
        question.addHeading(
          "solution",
          "One of the following or other reasonable advantage"
        );
        question.addParagraph("solution", `A sample is cheaper to perform`);
        question.addParagraph(
          "solution",
          `A sample is less time consuming to perform/Data is more readily available for a sample`
        );
      } else {
        question.addHeading(
          "solution",
          "b) Given a disadvantage of using a sample rather than a census from all available people"
        );
        question.addHeading(
          "solution",
          "ONE of the following or other reasonable disadvantage"
        );
        question.addParagraph(
          "solution",
          `The sample may not be completely accurate/may introduce bias`
        );
        question.addParagraph(
          "solution",
          `The sample may not be representitive of the entire population`
        );
      }

      // c
      question.addHeading(
        "solution",
        "c) Explain whether or not it would be reasonable for the Government to use their results to draw conclusions about how successful the campaign would be across the whole of the U.K."
      );
      question.addParagraph("solution", `It would not be reasonable as:`);
      question.addParagraph(
        "solution",
        `The sample size is small compared to the population of the U.K.`
      );
      question.addParagraph(
        "solution",
        `There may be regional differences in opinion`
      );
      question.addParagraph(
        "solution",
        `Opinions may be biased as the people are on an availability list for surveys`
      );
      //
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Data_Sampling_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const person = MCMisc.getName();

    const population = MCRandom.randomInt(1000, 9000, 500);
    const sample = MCRandom.randomInt(80, 200, 20);

    const word = ["population", "sample"][MCRandom.randomInt(0, 1)];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `${person.name} wishes to investigate how often people eat takeaway in ${person.hisher} local village.`
      );
      question.addParagraph(
        `${person.HeShe} has access to a phonebook with the numbers for all $${population}$ people in the village but only asks $${sample}$ people about how often they eat takeaway.`
      );
      question.addParagraph(`What is the ${word} for this investigation?`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`The $${population}$ people in ${person.hisher} village`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`The $${sample}$ people ${person.heshe} asked`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`The takeaway restaurants in ${person.hisher} village`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`${person.name}`);

      // add the answers to the question
      question.addAnswer(a1, word === "population");
      question.addAnswer(a2, word === "sample");
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);

      /* END BUILDING QUESTION
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Data_Sampling_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const person = MCMisc.getName();

    const boys = MCRandom.randomInt(30, 70, 2);
    const girls = 100 - boys;

    const i = MCRandom.randomInt(0, 4);
    const method = [
      "simple random",
      "stratified",
      "systematic",
      "quota",
      "opportunity",
    ];

    const size = MCRandom.randomInt(10, 50, 5);
    const quota = MCRandom.randomInt(10, 25);
    const system = MCRandom.randomInt(5, 10);
    /* END VARIABLES AND CONSTANTS */
    if (boys < 45 || boys > 55) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `${person.name} wants to investigate how interested young people are in sport from a school containing $${boys}$ boys and $${girls}$ girls. ${person.HeShe} uses a sample of the students to draw ${person.hisher} conclusions.`
      );
      if (method[i] === "simple random") {
        question.addParagraph(`${person.HeShe} collects the sample by:`);
        question.addParagraph(
          `Writing all of the students' names down on individual slips`
        );
        question.addParagraph(`Putting all the slips into a hat`);
        question.addParagraph(`Picking out $${size}$ names at random`);

        const a1 = new MCQuestion.MultipleChoiceAnswer();
        a1.addParagraph(`Simple Random`);

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        a2.addParagraph(`Systematic`);

        const a3 = new MCQuestion.MultipleChoiceAnswer();
        a3.addParagraph(`Opportunity`);

        const a4 = new MCQuestion.MultipleChoiceAnswer();
        a4.addParagraph([`Stratified`, `Quota`][MCRandom.randomInt(0, 1)]);

        // add the answers to the question
        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      } else if (method[i] === "stratified") {
        question.addParagraph(`${person.HeShe} collects the sample by:`);
        question.addParagraph(
          "Calcualting the number of boys and girls to ask so that the ratio of boys to girls in the sample is the same ratio as boys to girls in the school"
        );
        question.addParagraph(
          "Picking that number of boys and girls using a random name generator, ignoring any duplicates that are generated"
        );

        const a1 = new MCQuestion.MultipleChoiceAnswer();
        a1.addParagraph(`Simple Random`);

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        a2.addParagraph(`Systematic`);

        const a3 = new MCQuestion.MultipleChoiceAnswer();
        a3.addParagraph(`Opportunity`);

        const a4 = new MCQuestion.MultipleChoiceAnswer();
        a4.addParagraph(`Stratified`);

        // add the answers to the question
        question.addAnswer(a1, false);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, true);
      } else if (method[i] === "systematic") {
        question.addParagraph(`${person.HeShe} collects the sample by:`);
        question.addParagraph(
          `Acquiring a list of all the students' names, and assigning each a unique number`
        );
        question.addParagraph(
          `Picking every $${system}$th student from the list to survey`
        );

        const a1 = new MCQuestion.MultipleChoiceAnswer();
        a1.addParagraph(`Simple Random`);

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        a2.addParagraph(`Systematic`);

        const a3 = new MCQuestion.MultipleChoiceAnswer();
        a3.addParagraph([`Opportunity`, `Quota`][MCRandom.randomInt(0, 1)]);

        const a4 = new MCQuestion.MultipleChoiceAnswer();
        a4.addParagraph(`Stratified`);

        // add the answers to the question
        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      } else if (method[i] === "quota") {
        question.addParagraph(
          `${person.HeShe} collects the sample by asking the first $${quota}$ boys and $${quota}$ girls ${person.heshe} sees`
        );

        const a1 = new MCQuestion.MultipleChoiceAnswer();
        a1.addParagraph(`Simple Random`);

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        a2.addParagraph([`Systematic`, `Stratified`][MCRandom.randomInt(0, 1)]);

        const a3 = new MCQuestion.MultipleChoiceAnswer();
        a3.addParagraph(`Opportunity`);

        const a4 = new MCQuestion.MultipleChoiceAnswer();
        a4.addParagraph(`Quota`);

        // add the answers to the question
        question.addAnswer(a1, false);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, true);
      } else {
        question.addParagraph(
          `${person.HeShe} collects the sample by asking the first $${size}$ pupils ${person.heshe} sees`
        );

        const a1 = new MCQuestion.MultipleChoiceAnswer();
        a1.addParagraph(`Simple Random`);

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        a2.addParagraph(`Systematic`);

        const a3 = new MCQuestion.MultipleChoiceAnswer();
        a3.addParagraph(`Opportunity`);

        const a4 = new MCQuestion.MultipleChoiceAnswer();
        a4.addParagraph([`Quota`, `Stratified`][MCRandom.randomInt(0, 1)]);

        // add the answers to the question
        question.addAnswer(a1, false);
        question.addAnswer(a2, false);
        question.addAnswer(a3, true);
        question.addAnswer(a4, false);
      }
      question.addParagraph(`What is ${person.hisher} sampling method called?`);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Data_Sampling_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const type = ["census", "sample"][MCRandom.randomInt(0, 1)];
    const adv = ["an advantage", "a disadvantage"][MCRandom.randomInt(0, 1)];

    const adv_census = [
      `A census is completely accurate`,
      `A census is unbiased`,
    ];
    const dis_census = [
      `It's hard to obtain data for the whole population in a census`,
      `Data isn't readily available for the whole population`,
      `A census is time consuming`,
      `A census can't be used when testing involves destruction`,
      `The information gathered is usually difficult to process because there is so much of it`,
    ];
    const adv_sample = [
      `A sample survey is cheaper than a census`,
      `Sampling is advantageous when testing involves destruction`,
      `Data for a sample  is more readily available than for a census`,
      `There is less data to deal with than when taking a sample than when taking a census`,
      `Results can be obtained quicker for a sample than for a census`,
    ];
    const dis_sample = [
      `Results from a sample may be biased`,
      `A sample may not be completely accurate`,
      `A sample may not be representative of the whole population`,
      `A sample may not be large enough to cover certain smaller sub-groups of the population`,
      `There is uncertainty in any sample as it doesn't use the whole population`,
    ];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(3);
      question.addParagraph(
        `Which of the following is ${adv} of taking a ${type}`
      );
      if (type === "census" && adv === "an advantage") {
        const a1 = new MCQuestion.MultipleChoiceAnswer();
        a1.addParagraph(
          adv_census[MCRandom.randomInt(0, adv_census.length - 1)]
        );

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        a2.addParagraph(
          dis_census[MCRandom.randomInt(0, dis_census.length - 1)]
        );

        const a3 = new MCQuestion.MultipleChoiceAnswer();
        a3.addParagraph(
          [
            `A census is cheaper than a sample survey`,
            `Taking a census is advantageous when testing involves destruction`,
            `Data for a census is more readily available than for a sample survey`,
            `There is less data to deal with than when taking a census than when taking a sample`,
            `Results can be obtained quicker for a census than for a sample`,
          ][MCRandom.randomInt(0, adv_sample.length - 1)]
        );

        // const a4 = new MCQuestion.MultipleChoiceAnswer();

        // add the answers to the question
        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        // question.addAnswer(a4, false);
      } else if (type === "census" && adv === "a disadvantage") {
        const a1 = new MCQuestion.MultipleChoiceAnswer();
        a1.addParagraph(
          adv_census[MCRandom.randomInt(0, adv_census.length - 1)]
        );

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        a2.addParagraph(
          dis_census[MCRandom.randomInt(0, dis_census.length - 1)]
        );

        // const a3 = new MCQuestion.MultipleChoiceAnswer();

        const a4 = new MCQuestion.MultipleChoiceAnswer();
        a4.addParagraph(
          [
            `Results from a census may be biased`,
            `A census may not be completely accurate`,
            `A census may not be representative of the whole population`,
            `A census may not be large enough to cover certain smaller sub-groups of the population`,
            `There is uncertainty in any census as it doesn't use the whole population`,
          ][MCRandom.randomInt(0, dis_sample.length - 1)]
        );

        // add the answers to the question
        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
        // question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      } else if (type === "sample" && adv === "an advantage") {
        const a1 = new MCQuestion.MultipleChoiceAnswer();
        a1.addParagraph(
          adv_sample[MCRandom.randomInt(0, adv_sample.length - 1)]
        );

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        a2.addParagraph(
          dis_sample[MCRandom.randomInt(0, dis_sample.length - 1)]
        );

        const a3 = new MCQuestion.MultipleChoiceAnswer();
        a3.addParagraph(
          [`A sample is completely accurate`, `A sample is unbiased`][
            MCRandom.randomInt(0, adv_census.length - 1)
          ]
        );

        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
      } else {
        const a1 = new MCQuestion.MultipleChoiceAnswer();
        a1.addParagraph(
          [
            `A sample survey is cheaper than a census`,
            `Data for a sample  is more readily available than for a census`,
            `There is less data to deal with than when taking a sample than when taking a census`,
            `Results can be obtained quicker for a sample than for a census`,
          ][MCRandom.randomInt(0, adv_sample.length - 2)]
        );

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        a2.addParagraph(
          dis_sample[MCRandom.randomInt(0, dis_sample.length - 1)]
        );

        const a3 = new MCQuestion.MultipleChoiceAnswer();
        a3.addParagraph(
          [
            `It's usually hard to obtain data for a sample compared to a census`,
            `Data isn't readily available for a sample when compared to a census`,
            `A sample can't be used when testing involves destruction`,
            `The information gathered is usually difficult to process because there is so much of it`,
          ][MCRandom.randomInt(0, dis_census.length - 2)]
        );

        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
        question.addAnswer(a3, false);
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_AppliedDifferentiation_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a1 = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const r1 =
      MCRandom.randomInt(1, 3 * Math.abs(a1)) * MCRandom.randomInt(-1, 1, 2);

    const a2 = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const r2 =
      MCRandom.randomInt(1, 3 * Math.abs(a2)) * MCRandom.randomInt(-1, 1, 2);

    const find = MCRandom.randomInt(-1, 1, 2); // -1 for normal. 1 for tangent

    const d1 = MCRandom.randomInt(2, 5);
    const x =
      (MCRandom.randomInt(1, 5 * d1, 2) / d1) * MCRandom.randomInt(-1, 1, 2);

    const poly = new MCMaths.Polynomial(
      [a1 * a2, a1 * r2 + a2 * r1, r1 * r2],
      "x"
    );

    const y = poly.evaluate(x);

    let m_tmp = 0;
    let c_tmp = 0;
    if (find === -1) {
      m_tmp = -1 / poly.derivative().evaluate(x);
      c_tmp = -x * m_tmp + y;
    } else {
      m_tmp = poly.derivative().evaluate(x);
      c_tmp = -x * m_tmp + y;
    }
    const m = m_tmp;
    const c = c_tmp;

    const x_stationary = (-1 * (a1 * r2 + a2 * r1)) / (2 * a1 * a2);
    const y_stationary = poly.evaluate(x_stationary);

    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(y.toFixed(2)) === y &&
      Math.abs(y) < 20 &&
      a1 !== a2 &&
      parseFloat(m.toFixed(2)) === m &&
      Math.abs(m) < 10 &&
      m !== 0 &&
      parseFloat(y_stationary.toFixed(2)) === y_stationary &&
      parseFloat(x_stationary.toFixed(2)) === x_stationary &&
      parseFloat(c.toFixed(2)) === c &&
      Math.abs(c) < 50
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        `A curve $C$ has equation ${MCMaths.cleaner(
          `$y=(${a1}x+${r1})(${a2}x+${r2})$.`
        )}`
      );
      question.addParagraph(
        "question",
        `a) Find the coordinates of the stationary point on $C$.`
      );
      if (find === -1) {
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `b) Find the equation of the normal to $C$ at the point $(${x}, ${y})$ in the form $y=mx+c$.`
          )
        );
      } else {
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `b) Find the equation of the tangent to $C$ at the point $(${x},${y})$ in the form $y=mx+c$.`
          )
        );
      }
      // solution
      // a
      question.addHeading("solution", `a) Expanding:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=${poly.toString()}$`)
      );
      question.addHeading("solution", `Differentiating:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{dy}{dx}=${poly.derivative()}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Finding Stationary Point:`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${poly.derivative()}=0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${x_stationary}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$y=${a1 * a2}(${x_stationary})^2+${
            a1 * r2 + a2 * r1
          }(${x_stationary})+${r1 * r2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=${y_stationary}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore$ Stationary point at $\\displaystyle \\left(${x_stationary},${y_stationary}\\right)$`
        )
      );
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(x_stationary, 0, "a) $\\ x = $");
      group1.addInput(y_stationary, 0, "a) $\\ y = $");
      question.addInputGroup(group1);
      // b
      question.addHeading(
        "solution",
        MCMaths.cleaner(`b) Finding gradient at $(${x},${y})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{dy}{dx}=${poly.derivative()}$`)
      );
      if (find === -1) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\frac{dy}{dx}|_{x=${x}}=${-1 / m}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$\\therefore$ Gradient of normal is $${m}$`)
        );
        question.addHeading("solution", `Sub into straight line equation`);
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y+${-y}=${m}(x+${-x})$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m}x+${c}$`)
        );
        const group2 = new MCQuestion.InputGroup(2);
        group2.addInput(m, 0, "b) $\\ m = $");
        group2.addInput(c, 0, "b) $\\ c = $");
        question.addInputGroup(group2);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\frac{dy}{dx}|_{x=${x}}=${m}$`)
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$\\therefore$ Gradient of tangent is $${m}$`)
        );
        question.addHeading("solution", `Sub into straight line equation`);
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$y+${-y}=${m}(x+${-x})$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore y=${m}x+${c}$`)
        );
        const group2 = new MCQuestion.InputGroup(2);
        group2.addInput(m, 0, "b) $\\ m = $");
        group2.addInput(c, 0, "b) $\\ c = $");
        question.addInputGroup(group2);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_AppliedDifferentiation_Exam_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const da = MCRandom.randomInt(2, 8);
    const a = (MCRandom.randomInt(1, da - 1, 2) / da) * -1;

    const b = MCRandom.randomInt(2, 12);
    const c = MCRandom.randomInt(5, 20);

    const poly = new MCMaths.Polynomial([a, 0, b, c], "t");
    const der = poly.derivative();

    const lss = -b / (3 * a);

    const tp =
      MCRandom.randomInt(
        Math.ceil(Math.sqrt(lss) * 100) + 10,
        Math.floor(Math.sqrt(lss) * 100) + 80
      ) / 100;

    const m = der.evaluate(tp);
    const y1 = poly.evaluate(tp);
    const x0 = (-y1 + tp * m) / m;
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat((lss * 1260).toFixed(3)) === lss * 1260 &&
      parseFloat(m.toFixed(3)) === m &&
      parseFloat(y1.toFixed(3)) === y1 &&
      parseFloat((x0 - tp).toFixed(3)) === x0 - tp &&
      x0 - tp > 15 &&
      m < 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The altitude, $A$ of a bird at time, $t$ seconds is given by the formula`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$A=${poly.toString()},t>0$`)
      );
      if (Math.sqrt(lss) % 1 === 0) {
        question.addParagraph(
          "question",
          `a) Find the range of times where the bird is ascending in the form $t<Y$, where $Y$ is constant`
        );
      } else {
        question.addParagraph(
          "question",
          `a) Find the range of times where the bird is ascending in the form $t<\\sqrt{Y}$, where $Y$ is constant`
        );
      }
      question.addParagraph(
        "question",
        `After $${tp}$ s the bird starts a steady decent to the ground, maintaining constant vertical speed`
      );
      question.addParagraph(
        "question",
        `b) Find how long it takes the bird to reach the ground after starting the descent`
      );
      // solution
      if ((Math.sqrt(lss) * 1000) % 1000 === 0) {
        question.addHeading(
          "solution",
          `a) Find the range of times where the bird is ascending in the form $t<Y$, where $Y$ is constant`
        );
      } else {
        question.addHeading(
          "solution",
          `a) Find the range of times where the bird is ascending in the form $t<\\sqrt{Y}$, where $Y$ is constant`
        );
      }
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$A=${poly.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore \\frac{dA}{dt}=${der.toString()}$`)
      );
      question.addHeading("solution", `Ascending when $\\frac{dA}{dt}>0$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${der.toString()}>0$`)
      );
      if ((Math.sqrt(lss) * 1000) % 1000 !== 0) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore t<\\sqrt{${lss}}$`)
        );
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\therefore t<${Math.sqrt(lss)}$`)
        );
      }
      // b
      question.addHeading(
        "solution",
        `b) Find how long it takes the bird to reach the ground after starting the descent`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{dA}{dt}=${der.toString()}$`)
      );
      question.addHeading(
        "solution",
        `Finding equation of the tangent at $t=${tp}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{dA}{dt}|_{t=${tp}}=${der.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${der.toString().replace(/t/g, `(${tp})`)}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${m}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore$ Gradient of tangent is $${m}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$A=${poly.toString()}$`)
      );
      question.addHeading("solution", MCMaths.cleaner(`Let $t=${tp}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$A=${poly.toString().replace(/t/g, `(${tp})`)}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${y1}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore$ The tangent goes through point $(${tp}, ${y1})$`
        )
      );
      question.addHeading(
        "solution",
        `Sub found values into straight line equation`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y-${y1}=${m}(x-${tp})$`)
      );
      question.addHeading("solution", `Let $y=0$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$-${y1}=${m}x-${tp * m}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${x0}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${x0}-${tp}=${x0 - tp}$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The bird took $${x0 - tp}$ s to reach the ground`
      );
      // answers
      const group1 = new MCQuestion.InputGroup(1);
      if ((Math.sqrt(lss) * 1000) % 1000 !== 0) {
        group1.addInput(lss, 0, "a) $\\ Y = $");
      } else {
        group1.addInput(Math.sqrt(lss), 0, "a) $\\ Y = $");
      }
      group1.addInput(x0 - tp, 0, "b)");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_AppliedDifferentiation_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const find = MCRandom.randomInt(-1, 1, 2); // -1 for normal. 1 for tangent

    const dk = MCRandom.randomInt(2, 5);
    const k =
      (MCRandom.randomInt(1, 3 * dk, 2) / dk) * MCRandom.randomInt(-1, 1, 2);

    const d1 = MCRandom.randomInt(2, 5);
    const c1 =
      (MCRandom.randomInt(1, 2 * d1, 2) / d1) * MCRandom.randomInt(-1, 1, 2);

    const c2k = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);

    const c3 = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);
    const c3k = MCRandom.randomInt(-10, -1);

    const c4k = MCRandom.randomInt(1, 20) * MCRandom.randomInt(-1, 1, 2);

    const poly = new MCMaths.Polynomial(
      [c1, c2k * k, c3 + c3k * k, c4k * k],
      "x"
    );
    const der = poly.derivative();

    const dx = MCRandom.randomInt(2, 5);
    const x =
      (MCRandom.randomInt(1, 5 * dx, 2) / dx) * MCRandom.randomInt(-1, 1, 2);
    const c_rnd = MCRandom.randomInt(1, 10) * MCRandom.randomInt(1, 10);

    let m_tmp = 0;
    if (find === -1) {
      m_tmp = -1 / der.evaluate(x);
    } else {
      m_tmp = der.evaluate(x);
    }
    const m = m_tmp;

    const y = poly.evaluate(x);
    const c = m * -x + y;

    if (
      parseFloat(m.toFixed(1)) === m &&
      Math.abs(m) < 10 &&
      m !== 0 &&
      parseFloat(y.toFixed(2)) === y &&
      Math.abs(y) < 50 &&
      parseFloat(c.toFixed(1)) === c &&
      Math.abs(c) < 10 &&
      c !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$f(x)=${c1}x^3+${c2k}kx^2+(${c3}+${c3k}k)x+${c4k}k$, where $k$ is some constant.`
        )
      );
      if (find === -1) {
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `A normal to the $f$ at $x=${x}$ is parallel to the line $y+${-m}x=${c_rnd}$.`
          )
        );
        question.addParagraph("question", `a) Find $k$.`);
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `b) Hence, find the equation of the normal to $f$ at $x=${x}$ in the form $y=mx+c$.`
          )
        );
      } else {
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `A tangent to the $f$ at $x=${x}$ is parallel to the line $y+${-m}x=${c_rnd}$.`
          )
        );
        question.addParagraph("question", `a) Find $k$.`);
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `b) Hence, find the equation of the tangent to $f$ at $x=${x}$ in the form $y=mx+c$.`
          )
        );
      }
      // solution
      // a
      question.addHeading("solution", `a) Finding Derivative:`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f'(x)=${3 * c1}x^2+${2 * c2k}kx+${c3}+${c3k}k$`)
      );
      if (find === -1) {
        question.addHeading(
          "solution",
          MCMaths.cleaner(`Using $f'(${x})=${-1 / m}$:`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$${3 * c1}(${x})^2+${2 * c2k}(${x}k)+${c3}+${c3k}k=${-1 / m}$`
          )
        );
      } else {
        question.addHeading(
          "solution",
          MCMaths.cleaner(`Using $f'(${x})=${m}$:`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$${3 * c1}(${x})^2+${2 * c2k}(${x}k)+${c3}+${c3k}k=${m}$`
          )
        );
      }
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore k=${k}$`)
      );
      // b
      if (find === -1) {
        question.addHeading(
          "solution",
          MCMaths.cleaner(`b) Finding $y$ coordinate:`)
        );
      } else {
        question.addHeading(
          "solution",
          MCMaths.cleaner(`b) Finding $y$ coordinate:`)
        );
      }
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$f(${x})=${c1}(${x})^3+${c2k * k}(${x})^2+${c3 + c3k * k}(${x})+${
            c4k * k
          }$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${y}$`));
      question.addHeading("solution", MCMaths.cleaner(`Using Line Equation:`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y+${-y}=${m}(x+${-x})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=${m}x+${c}$`)
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(k, 0, "a) $\\ k = $");
      question.addInputGroup(group1);

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(m, 0, "b) $\\ m = $");
      group2.addInput(c, 0, "b) $\\ c = $");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_AppliedDifferentiation_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const minmax = MCRandom.randomInt(-1, 1, 2); // -1 for min, 1 for max

    const d1 = MCRandom.randomInt(2, 5);
    const c1 =
      (MCRandom.randomInt(1, 2 * d1, 2) / d1) * MCRandom.randomInt(-1, 1, 2);
    const c2 = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const c3 = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);
    const c4 = MCRandom.randomInt(1, 20) * MCRandom.randomInt(-1, 1, 2);

    const poly = new MCMaths.Polynomial([c1, c2, c3, c4], "x");
    const der = poly.derivative();
    const der2 = der.derivative();
    const stat_points = der.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      stat_points[0] !== "No Real Roots" &&
      parseFloat(stat_points[0].toFixed(2)) === stat_points[0] &&
      parseFloat(stat_points[1].toFixed(2)) === stat_points[1] &&
      stat_points[0] !== stat_points[1] &&
      parseFloat(poly.evaluate(stat_points[1]).toFixed(2)) ===
        poly.evaluate(stat_points[1]) &&
      stat_points[0] !== stat_points[1] &&
      parseFloat(poly.evaluate(stat_points[0]).toFixed(2)) ===
        poly.evaluate(stat_points[0])
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        `A curve $C$ has equation $y=f(x)$ where`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$f(x)=${poly.toString()}$`)
      );
      question.addParagraph(
        "question",
        `a) Find the x-coordinates of the stationary points on $C$`
      );
      question.addParagraph(
        "question",
        `b) Find $\\frac{d^{\\ 2}y}{dx^2}$ in the form $Ax + B$`
      );
      if (minmax === -1) {
        question.addParagraph(
          "question",
          `c) Hence, determine the coordinates of the local minima`
        );
      } else {
        question.addParagraph(
          "question",
          `c) Hence, determine the coordinates of the local maxima`
        );
      }
      // solution
      // a
      question.addHeading(
        "solution",
        `a) Find the x-coordinates of the stationary points on $C$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f(x)=${poly.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f'(x)=${der.toString()}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`$f'(x)=0$ at stationary points`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${der.toString()}=0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${stat_points[1]},x=${stat_points[0]}$`)
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(stat_points, 0, "a) $\\ x = $");
      question.addInputGroup(group1);
      // b
      question.addHeading(
        "solution",
        `b) Find $\\frac{d^{\\ 2}y}{dx^2}$ in the form $Ax + B$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f'(x)=${der.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f''(x)=${der2.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore A=${der2.coefficients[0]}, B=${der2.coefficients[1]}$`
        )
      );

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(der2.coefficients[0], 0, "b) $\\ A = $");
      group2.addInput(der2.coefficients[1], 0, "b) $\\ B = $");
      question.addInputGroup(group2);
      // c
      if (minmax === -1) {
        question.addHeading(
          "solution",
          `c) Hence, determine the coordinates of the local minima`
        );
      } else {
        question.addHeading(
          "solution",
          `c) Hence, determine the coordinates of the local maxima`
        );
      }
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f''(x)=${der2.toString()}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Evaluate $f''(${stat_points[1]})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=${der2.toString().replace("x", `(${stat_points[1]})`)}$`
        )
      );
      if (der2.evaluate(stat_points[1]) < 0 && minmax === 1) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$=${der2.evaluate(stat_points[1])}<0$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`$\\therefore$ Local Maxima at $x=${stat_points[1]}$`)
        );

        question.addHeading(
          "solution",
          MCMaths.cleaner(`Evaluate $f(${stat_points[1]})$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$f(${stat_points[1]})=${poly
              .toString()
              .replace(/x/g, `(${stat_points[1]})`)}$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$=${poly.evaluate(stat_points[1])}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(
            `$\\therefore$ Local Maxima at $(${stat_points[1]},${poly.evaluate(
              stat_points[1]
            )})$`
          )
        );

        const group3 = new MCQuestion.InputGroup(2);
        group3.addInput(stat_points[1], 0, "c) $\\ x = $");
        group3.addInput(poly.evaluate(stat_points[1]), 0, "c) $\\ y = $");
        question.addInputGroup(group3);
      } else if (der2.evaluate(stat_points[1]) < 0 && minmax === -1) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$=${der2.evaluate(stat_points[1])}<0$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`$\\therefore$ Local Maxima at $x=${stat_points[1]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`$\\therefore$ Local Minima at $x=${stat_points[0]}$`)
        );

        question.addHeading(
          "solution",
          MCMaths.cleaner(`Evaluate $f(${stat_points[0]})$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$f(${stat_points[0]})=${poly
              .toString()
              .replace(/x/g, `(${stat_points[0]})`)}$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$=${poly.evaluate(stat_points[0])}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(
            `$\\therefore$ Local Minima at $(${stat_points[0]},${poly.evaluate(
              stat_points[0]
            )})$`
          )
        );

        const group3 = new MCQuestion.InputGroup(2);
        group3.addInput(stat_points[0], 0, "c) $\\ x = $");
        group3.addInput(poly.evaluate(stat_points[0]), 0, "c) $\\ y = $");
        question.addInputGroup(group3);
      } else if (der2.evaluate(stat_points[1]) > 0 && minmax === 1) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$=${der2.evaluate(stat_points[1])}<0$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`$\\therefore$ Local Minima at $x=${stat_points[1]}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`$\\therefore$ Local Maxima at $x=${stat_points[0]}$`)
        );

        question.addHeading(
          "solution",
          MCMaths.cleaner(`Evaluate $f(${stat_points[0]})$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$f(${stat_points[0]})=${poly
              .toString()
              .replace(/x/g, `(${stat_points[0]})`)}$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$=${poly.evaluate(stat_points[0])}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(
            `$\\therefore$ Local Maxima at $(${stat_points[0]},${poly.evaluate(
              stat_points[0]
            )})$`
          )
        );

        const group3 = new MCQuestion.InputGroup(2);
        group3.addInput(stat_points[0], 0, "c) $\\ x = $");
        group3.addInput(poly.evaluate(stat_points[0]), 0, "c) $\\ y = $");
        question.addInputGroup(group3);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$=${der2.evaluate(stat_points[1])}<0$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`$\\therefore$ Local Minima at $x=${stat_points[1]}$`)
        );

        question.addHeading(
          "solution",
          MCMaths.cleaner(`Evaluate $f(${stat_points[1]})$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$f(${stat_points[1]})=${poly
              .toString()
              .replace(/x/g, `(${stat_points[1]})`)}$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$=${poly.evaluate(stat_points[1])}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(
            `$\\therefore$ Local Minima at $(${stat_points[1]},${poly.evaluate(
              stat_points[1]
            )})$`
          )
        );

        const group3 = new MCQuestion.InputGroup(2);
        group3.addInput(stat_points[1], 0, "c) $\\ x = $");
        group3.addInput(poly.evaluate(stat_points[1]), 0, "c) $\\ y = $");
        question.addInputGroup(group3);
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_AppliedDifferentiation_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const inc_dec = MCRandom.randomInt(-1, 1, 2); // -1 for dec, 1 for inc
    const type = MCRandom.randomInt(-1, 1, 2); // -1 for normal, 1 for tangent

    const b1 = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const b2 = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);

    const b3 = MCRandom.randomInt(1, 15) * MCRandom.randomInt(-1, 1, 2);

    const poly = new MCMaths.Polynomial([b1, b2, b3], "x");
    const der = poly.derivative();

    const dg = MCRandom.randomInt(2, 5);
    const g = MCRandom.randomInt(1, 5 * dg, 2) / dg;

    const x = (-0.5 * b2) / b1;
    const y = poly.evaluate(x);

    if (parseFloat(x.toFixed(3)) === x && parseFloat(y.toFixed(3)) === y) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$f(x)=${poly.toString()}$`)
      );
      if (inc_dec === -1) {
        question.addParagraph(
          "question",
          `a) Find the range of values of $x$ where $f(x)$ is a decreasing function`
        );
      } else {
        question.addParagraph(
          "question",
          `a) Find the range of values of $x$ where $f(x)$ is an increasing function`
        );
      }
      question.addParagraph(`question`, `A curve $C$ has equation $y=f(x)$`);
      if (type === -1) {
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `b) Find the equation of the normal to $C$ at $(${x},${y})$`
          )
        );
      } else {
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `b) Find the equation of the tangent to $C$ at $(${x},${y})$`
          )
        );
      }
      // solution
      // a
      if (inc_dec === -1) {
        question.addHeading(
          "solution",
          `a) Find the range of values of $x$ where $f(x)$ is a decreasing function`
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$f(x)=${poly.toString()}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$f'(x)=${der.toString()}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`$f'(x)<0$ when function is decreasing`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$${der.toString()}<0$`)
        );
        if (b1 > 0) {
          question.addParagraph(
            "solution",
            MCMaths.cleaner(`$\\therefore x<${(-0.5 * b2) / b1}$`)
          );
          const group1 = new MCQuestion.InputGroup(1);
          group1.addInput(x, 0, "a) $\\ x < $");
          question.addInputGroup(group1);
        } else {
          question.addParagraph(
            "solution",
            MCMaths.cleaner(`$\\therefore x>${(-0.5 * b2) / b1}$`)
          );
          const group1 = new MCQuestion.InputGroup(1);
          group1.addInput(x, 0, "a) $\\ x > $");
          question.addInputGroup(group1);
        }
      } else {
        question.addHeading(
          "solution",
          `a) Find the range of values of $x$ where $f(x)$ is an increasing function`
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$f(x)=${poly.toString()}$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$f'(x)=${der.toString()}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`$f'(x)>0$ when function is increasing`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$${der.toString()}>0$`)
        );
        if (b1 > 0) {
          question.addParagraph(
            "solution",
            MCMaths.cleaner(`$\\therefore x>${(-0.5 * b2) / b1}$`)
          );
          const group1 = new MCQuestion.InputGroup(1);
          group1.addInput(x, 0, "a) $\\ x > $");
          question.addInputGroup(group1);
        } else {
          question.addParagraph(
            "solution",
            MCMaths.cleaner(`$\\therefore x<${(-0.5 * b2) / b1}$`)
          );
          const group1 = new MCQuestion.InputGroup(1);
          group1.addInput(x, 0, "a) $\\ x < $");
          question.addInputGroup(group1);
        }
      }
      // b
      if (type === -1) {
        question.addHeading(
          "solution",
          MCMaths.cleaner(
            `b) Find the equation of the normal to $C$ at $(${x},${y})$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$f'(x)=${der.toString()}$`)
        );
        question.addHeading("solution", MCMaths.cleaner(`Evaluate $f'(${x})$`));
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$f'(${x})=${der.toString().replace(/x/g, `(${x})`)}$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$=${der.evaluate(x)}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`$\\therefore$ Stationary at $(${x},${y})$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`$\\therefore$ Normal has equation $x=${x}$`)
        );

        const group2 = new MCQuestion.InputGroup(1);
        group2.addInput(x, 0, "b) $\\ x = $");
        question.addInputGroup(group2);
      } else {
        question.addHeading(
          "solution",
          MCMaths.cleaner(
            `b) Find the equation of the tangent to $C$ at $(${x},${y})$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$f'(x)=${der.toString()}$`)
        );
        question.addHeading("solution", MCMaths.cleaner(`Evaluate $f'(${x})$`));
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$f'(${x})=${der.toString().replace(/x/g, `(${x})`)}$`
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$=${der.evaluate(x)}$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`$\\therefore$ Stationary at $(${x},${y})$`)
        );
        question.addHeading(
          "solution",
          MCMaths.cleaner(`$\\therefore$ Tangent has equation $y=${y}$`)
        );

        const group2 = new MCQuestion.InputGroup(1);
        group2.addInput(y, 0, "b) $\\ y = $");
        question.addInputGroup(group2);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_AppliedDifferentiation_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const r1 = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);
    const r2 = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);

    const poly = new MCMaths.Polynomial([a, -r1 * a - r2, r1 * r2], "x");
    const der = poly.derivative();
    const roots = poly.roots();

    let norm_tmp = 0;
    let tang_tmp = 0;
    if (roots[1] < roots[0]) {
      norm_tmp = MCRandom.randomInt(Math.floor(roots[1]), Math.floor(roots[0]));
      tang_tmp = MCRandom.randomInt(Math.floor(roots[1]), Math.floor(roots[0]));
    } else {
      norm_tmp = MCRandom.randomInt(Math.floor(roots[0]), Math.floor(roots[1]));
      tang_tmp = MCRandom.randomInt(Math.floor(roots[0]), Math.floor(roots[1]));
    }
    const norm_x = norm_tmp;
    const norm_y = poly.evaluate(norm_x);
    const norm_m = -1 / der.evaluate(norm_x);
    const norm_c = -norm_x * norm_m + norm_y;
    const norm_meetx = (-1 * norm_c) / norm_m;

    const tang_x = tang_tmp;
    const tang_y = poly.evaluate(tang_x);
    const tang_m = der.evaluate(tang_x);
    const tang_c = -tang_x * tang_m + tang_y;
    const tang_meetx = (-1 * tang_c) / tang_m;

    const hx = (norm_c - tang_c) / (tang_m - norm_m);
    const hy = tang_m * hx + tang_c;

    const A = 0.5 * Math.abs(norm_meetx - tang_meetx) * hy;

    if (
      roots[0] !== roots[1] &&
      norm_x !== tang_x &&
      Math.abs(norm_y) < 20 &&
      Math.abs(tang_y) < 20 &&
      Math.abs(norm_y) > 5 &&
      Math.abs(tang_y) > 5 &&
      parseFloat(norm_y.toFixed(3)) === norm_y &&
      parseFloat(norm_m.toFixed(3)) === norm_m &&
      parseFloat(norm_c.toFixed(3)) === norm_c &&
      parseFloat(norm_meetx.toFixed(3)) === norm_meetx &&
      parseFloat(hx.toFixed(3)) === hx &&
      parseFloat(tang_y.toFixed(3)) === tang_y &&
      parseFloat(tang_m.toFixed(3)) === tang_m &&
      parseFloat(tang_c.toFixed(3)) === tang_c &&
      parseFloat(tang_meetx.toFixed(3)) === tang_meetx &&
      parseFloat(hy.toFixed(3)) === hy &&
      tang_y !== 0 &&
      tang_m !== 0 &&
      tang_c !== 0 &&
      tang_meetx !== 0 &&
      hx !== 0 &&
      norm_y !== 0 &&
      norm_m !== 0 &&
      norm_c !== 0 &&
      norm_meetx !== 0 &&
      hy !== 0 &&
      parseFloat(A.toFixed(3)) === A &&
      tang_m !== norm_m
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph("question", `A curve $C$ has equation`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$y=${poly.toString()}$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `The tangent to $C$ at point $P(${tang_x}, ${tang_y})$, the normal at point $Q(${norm_x}, ${norm_y})$ and the x-axis form a triangle of area $A$ units$^{2}$`
        )
      );
      question.addParagraph("question", `Find $A$`);
      // solution
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=${poly.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore \\frac{dy}{dx}=${der.toString()}$`)
      );
      // tangent
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Finding the equation of the tangent at $P(${tang_x}, ${tang_y})$`
        )
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Gradient of $C$ at $P$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{dy}{dx}|_{x=${tang_x}}=${der
            .toString()
            .replace("x", `(${tang_x})`)}$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${tang_m}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore$ Gradient of tangent is $${tang_m}$`)
      );
      question.addHeading("solution", `Sub into straight line equation`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y-${tang_y}=${tang_m}(x-${tang_x})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=${tang_m}x+${tang_c}$`)
      );
      question.addHeading(
        "solution",
        `Find where the tangent meets the x-axis`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${tang_m}x+${tang_c}=0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${tang_meetx}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore$ Meets x-axis at $(${tang_meetx}, 0)$`)
      );

      // normal
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Finding the equation of the normal at $Q(${norm_x}, ${norm_y})$`
        )
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Gradient of $C$ at $Q$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{dy}{dx}|_{x=${norm_x}}=${der
            .toString()
            .replace("x", `(${norm_x})`)}$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${-1 / norm_m}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore$ Gradient of normal is $${norm_m}$`)
      );
      question.addHeading("solution", `Sub into straight line equation`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y-${norm_y}=${norm_m}(x-${norm_x})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=${norm_m}x+${norm_c}$`)
      );
      question.addHeading("solution", `Find where the normal meets the x-axis`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${norm_m}x+${norm_c}=0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${norm_meetx}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore$ Meets x-axis at $(${norm_meetx}, 0)$`)
      );
      // normal == tangent
      question.addHeading("solution", `Find where the normal and tangent meet`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${tang_m}x+${tang_c}=${norm_m}x+${norm_c}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${hx}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=${tang_m}(${hx})+${tang_c}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=${hy}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore$ Tangent and normal meet at $(${hx},${hy})$`
        )
      );
      // Triangle
      question.addHeading("solution", `Area of a Triangle = $\\frac{1}{2}bh$`);
      if (norm_meetx > tang_meetx) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$b=${norm_meetx}-${tang_meetx}$`)
        );
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$b=${tang_meetx}-${norm_meetx}$`)
        );
      }
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${Math.abs(norm_meetx - tang_meetx)}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$h=${Math.abs(hy)}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore A=\\frac{1}{2}(${Math.abs(
            norm_meetx - tang_meetx
          )})(${Math.abs(hy)})$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${Math.abs(A)}$`));
      // answer
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(A, 0, "$A = $");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_AppliedDifferentiation_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const person = MCMisc.getName();
    const perimeter = MCRandom.randomInt(50, 100, 2);
    const poly = new MCMaths.Polynomial([-1, perimeter, 0], "x");
    const der = poly.derivative();
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // image
      const Image = new MCQuestion.Image(
        "Leo/AppliedDiff_Q6.svg",
        "height",
        20
      );
      Image.addOverlay("$l$", 8, 50);
      Image.addOverlay("$w$", 50, 20);
      question.addImage("question", Image);

      // question
      question.addParagraph(
        `question`,
        `${person.name} is given $${perimeter}$m of rope to mark out a rectangular pitch at an event.`
      );
      question.addParagraph(
        `question`,
        `a) Find the largest possible area ${person.name} can make`
      );
      question.addParagraph(
        `question`,
        `b) Prove that your answer to part (a) gives the maximum value of $A$`
      );
      // solution
      // a
      question.addHeading(
        `solution`,
        `a) Find the largest possible area ${person.name} can make`
      );
      question.addParagraph(`solution`, `$2l+2w=${perimeter}$`);
      question.addParagraph(`solution`, `$\\therefore w=${perimeter / 2}-l$`);
      question.addParagraph(`solution`, `$A=lw$`);
      question.addParagraph(`solution`, `$=l(${perimeter / 2}-l)$`);
      question.addParagraph(
        `solution`,
        `$\\therefore A=${perimeter / 2}l-l^{\\ 2}$`
      );
      question.addParagraph(
        `solution`,
        `$\\therefore \\frac{dA}{dl}=${perimeter / 2}-2l$`
      );
      question.addHeading(`solution`, `Finding where $A$ is stationary`);
      question.addParagraph(`solution`, `$${perimeter / 2}-2l=0$`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore l=${perimeter / 4}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$w=${perimeter / 2}-${perimeter / 4}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore w=${perimeter / 4}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$A=(${perimeter / 4})(${perimeter / 4})$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$=${perimeter ** 2 / 16}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$\\therefore$ Maximum Value of $A=${perimeter ** 2 / 16}$m$^{2}$`
        )
      );
      // b
      question.addHeading(
        `solution`,
        `b) Prove that your answer to part (a) gives the maximum value of $A$`
      );
      question.addParagraph(`solution`, `$\\frac{dA}{dl}=${perimeter / 2}-2l$`);
      question.addParagraph(
        `solution`,
        `$\\therefore \\frac{d^{\\ 2}A}{dl^{2}}=-2$`
      );
      question.addParagraph(
        `solution`,
        `$\\frac{d^{\\ 2}A}{dl^{2}}<0$ for all values of $l$, therefore any stationary points must be maximas`
      );
      // answers
      question.requiresSelfMarking();
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(perimeter ** 2 / 16, 0, "a) $\\ A = $");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_AppliedDifferentiation_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const V = MCRandom.randomInt(300, 1000, 50);
    const r = (V / (2 * Math.PI)) ** (1 / 3);
    const A =
      2 * Math.PI * (V / 2 / Math.PI) ** (2 / 3) +
      (2 * V) / (Math.PI * (V / 2)) ** (1 / 3);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // image
      const Image = new MCQuestion.Image(
        "Leo/AppliedDiff_Q7.svg",
        "height",
        20
      );
      Image.addOverlay("$h$", 43, 50);
      Image.addOverlay("$r$", 60, 22);

      question.addImage("question", Image);
      // question
      question.addParagraph(
        "question",
        `A flask with a capacity of $${V}$cm$^{3}$ can be modelled as a cylinder.`
      );
      question.addParagraph(
        "question",
        `In order to better maintain the temperature of its contents, the surface area of the flask must be as small as possible.`
      );
      question.addParagraph(
        "question",
        `Find the smallest possible value of this area to 3 decimal places and verify that it is the minimum value`
      );
      // solution
      question.addParagraph("solution", `$V=\\pi r^2h$`);
      question.addParagraph("solution", `$\\therefore \\pi r^2h = ${V}$`);
      question.addParagraph(
        "solution",
        `$\\therefore h = \\frac{${V}}{\\pi r^2}$`
      );
      question.addParagraph("solution", `$A=2\\pi r^2+2\\pi rh $`);
      question.addHeading("solution", `Sub in $h = \\frac{${V}}{\\pi r^2}$`);
      question.addParagraph(
        "solution",
        `$=2\\pi r^2 + 2\\pi r(\\frac{${V}}{\\pi r^2})$`
      );
      question.addParagraph("solution", `$=2\\pi r^2 +${2 * V}r^{-1}$`);
      question.addParagraph(
        "solution",
        `$\\therefore \\frac{dA}{dr}=4\\pi r-${2 * V}r^{-2}$`
      );
      question.addHeading(`solution`, `Finding where $A$ is stationary`);
      question.addParagraph("solution", `$4\\pi r-${2 * V}r^{-2}=0$`);
      question.addParagraph(
        "solution",
        `$\\therefore r=(\\frac{${
          V / 2
        }}{\\pi})^{\\frac{1}{3}}$ when $A$ is stationary`
      );
      question.addHeading(
        `solution`,
        `Verifying this value of $r$ as a minimum`
      );
      question.addParagraph(
        "solution",
        `$\\frac{dA}{dr}=4\\pi r-${2 * V}r^{-2}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\frac{d^{\\ 2}A}{dr^2}=4\\pi + ${4 * V}r^{-3}$`
      );
      question.addHeading(
        "solution",
        `Let $r=(\\frac{${V / 2}}{\\pi})^{\\frac{1}{3}}$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{d^{\\ 2}A}{dr^2}=4\\pi + ${4 * V}(\\frac{${
          V / 2
        }}{\\pi})^{-1}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\frac{d^{\\ 2}A}{dr^2}=12\\pi$`
      );
      question.addParagraph("solution", `$12\\pi>0$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ Minima at $r=(\\frac{${V / 2}}{\\pi})^{\\frac{1}{3}}$`
      );
      question.addHeading(
        "solution",
        `Sub $r=(\\frac{${
          V / 2
        }}{\\pi})^{\\frac{1}{3}}$ into the equation for $A$`
      );
      question.addParagraph(
        "solution",
        `$A=2\\pi (\\frac{${V / 2}}{\\pi})^{\\frac{2}{3}} +${2 * V}(\\frac{${
          V / 2
        }}{\\pi})^{\\frac{-1}{3}}$`
      );
      question.addParagraph("solution", `$\\approx ${A.toFixed(3)}$ cm$^{2}$`);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(parseFloat(A.toFixed(3)), 0, "$A = $");
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_AppliedDifferentiation_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const d = MCRandom.randomInt(120, 240, 20);
    const t0 = MCRandom.randomInt(20, 70, 5);

    const da = MCRandom.randomInt(32, 64, 4);
    const a = MCRandom.randomInt(1, da / 2) / da;

    const poly = new MCMaths.Polynomial([-a, d * a, t0], "t");
    const der = poly.derivative();
    const coeff = poly.getCoefficients();

    const peak = poly.evaluate(d / 2);

    const into = MCRandom.randomInt(Math.ceil(d / 4), Math.floor((3 * d) / 4));
    const roc = der.evaluate(into);

    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(coeff[0].toFixed(4)) === coeff[0] &&
      parseFloat(coeff[1].toFixed(4)) === coeff[1] &&
      parseFloat(coeff[2].toFixed(4)) === coeff[2] &&
      parseFloat(peak.toFixed(3)) === peak &&
      peak < 1000 &&
      parseFloat(roc.toFixed(3)) === roc &&
      roc < 1000
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        `The number of viewers of an awards ceremony increased until a peak when the Best Picture Award was announced.`
      );
      question.addParagraph(
        "question",
        `The number of thousands of viewers, $v$ at $t$ minutes into the show could be modelled by the equation`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$v=${poly.toString()}$`)
      );
      question.addParagraph(
        "question",
        `a) Determine the rate of change of viewers $${into}$ minutes into the ceremony`
      );
      question.addParagraph(
        "question",
        `b) Find the value of $t$ when the Best Picture Award was announced`
      );
      question.addParagraph(
        "question",
        `c) Hence, find the peak number of viewers`
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) Determine the rate of change of viewers $${into}$ minutes into the ceremony`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$v=${poly.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore \\frac{dv}{dt}=${der.toString()}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{dv}{dt}|_{t=${into}}=${der
            .toString()
            .replace("t", `(${into})`)}$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${roc}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore$ Rate of change is $${roc * 1000}$ viewers per minute`
        )
      );
      // b
      question.addHeading(
        "solution",
        `b) Find the value of $t$ when the Best Picture Award was announced`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{dv}{dt}=0$ at peak viewers`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${der.toString()}=0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore t=${d / 2}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore$ Best Picture announced $${
            d / 2
          }$ minutes into the ceremony`
        )
      );
      // c
      question.addHeading(
        "solution",
        `c) Hence, find the peak number of viewers`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$v=${poly.toString()}$`)
      );
      question.addHeading("solution", MCMaths.cleaner(`Sub in $t=${d / 2}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$v=${poly.toString().replace(/t/g, `(${d / 2})`)}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${peak}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore ${(peak * 1000 - ((peak * 1000) % 1000)) / 1000},${
            (peak * 1000) % 1000
          }$ peak viewers`
        )
      );
      // answers
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(roc * 1000, 0, "a)");
      group1.addInput(d / 2, 0, "b) $\\ t =$");
      group1.addInput(peak * 1000, 0, "c)");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_AppliedDifferentiation_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(25, 50);
    const b = MCRandom.randomInt(-8, -1);

    const x_max = (-b * (2 / a)) ** -2;
    const h_max = a * x_max ** (1 / 2) + b * x_max;

    const xb = MCRandom.randomInt(5, Math.floor(a ** 2 / b ** 2) - 2);
    const hb = a * xb ** (1 / 2) + b * xb;

    const x_roc = MCRandom.randomInt(1, Math.floor(a ** 2 / b ** 2));
    const roc = (1 / 2) * a * x_roc ** (-1 / 2) + b;

    const a_coeff = -1 / (2 * x_max ** (1 / 2));
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(x_max.toFixed(2)) === x_max &&
      parseFloat(h_max.toFixed(2)) === h_max &&
      h_max > 80 &&
      h_max < 160 &&
      parseFloat(hb.toFixed(3)) === hb &&
      x_roc !== x_max &&
      x_roc !== xb &&
      xb !== x_max &&
      parseFloat(roc.toFixed(3)) === roc
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The height, $h$ m of a rollercoaster can be modelled using the formula`
      );
      question.addParagraph("question", `$h=a\\sqrt{x}+bx$`);
      question.addParagraph(
        "question",
        `Where $a$ and $b$ are constants and $x$ is the horizontal disaplacement of the cart in metres`
      );
      question.addParagraph(
        "question",
        `The maximum height reached on the ride is $${h_max}$ m, when the cart has moved $${x_max}$ m horizontally`
      );
      question.addParagraph("question", `a) Find $a$ and $b$`);
      question.addParagraph(
        "question",
        `b) Find the height of the cart when $x=${xb}$`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `c) Determine the horizontal displacement when the rate of change of height is $${roc}$`
        )
      );
      // solution
      // a
      question.addHeading("solution", `a) Find $a$ and $b$`);
      question.addParagraph("solution", `$h=a\\sqrt{x}+bx$`);
      question.addParagraph(
        "solution",
        `$\\therefore \\frac{dh}{dx}=\\frac{a}{2\\sqrt{x}}+b$`
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`At $x=${x_max},\\frac{dh}{dx}=0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{a}{2\\sqrt{${x_max}}}+b=0$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore b=${a_coeff}a$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`At $x=${x_max},h=${h_max}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$a\\sqrt{${x_max}}+${x_max}b=${h_max}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Sub in $b=${a_coeff}a$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${x_max ** (1 / 2)}a+${x_max}(${a_coeff}a)=${h_max}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$a(${x_max ** (1 / 2)}+${x_max * a_coeff})=${h_max}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore a=${a}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$b=${a_coeff}(${a})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore b=${b}$`)
      );
      // b
      question.addHeading(
        "solution",
        `b) Find the height of the cart when $x=${xb}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$h=${a}\\sqrt{x}+${b}x$`)
      );
      question.addHeading("solution", `Let $x=${xb}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$h=${a}\\sqrt{${xb}}+${b}(${xb})$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${hb}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore$ Cart is $${hb}$ m high`)
      );

      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `c) Determine the horizontal displacement when the rate of change of height is $${roc}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{dh}{dx}=\\frac{${a}}{2\\sqrt{x}}+${b}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Let $\\frac{dh}{dx}=${roc}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{${a}}{2\\sqrt{x}}+${b}=${roc}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${a}=${2 * (roc - b)}\\sqrt{x}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${x_roc}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore$ Cart has a horizontal displacement of $${x_roc}$ m`
        )
      );
      // answer
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(a, 0, "a) $\\ a = $");
      group1.addInput(b, 0, "a) $\\ b = $");
      question.addInputGroup(group1);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(hb, 0, "b) $\\ h = $");
      group2.addInput(x_roc, 0, "c) $\\ x = $");
      question.addInputGroup(group2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_AppliedDifferentiation_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const poly = new MCMaths.Polynomial([a, 2 * b * a, a * b * b + c], "x");
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(3);
      question.addGraph(poly.graph());
      if (a === 1) {
        question.addParagraph(
          MCMaths.cleaner(
            `The curve $C$, shown above, has equation $y=(x+${b})^2+${c}$`
          )
        );
      } else {
        question.addParagraph(
          MCMaths.cleaner(
            `The curve $C$, shown above, has equation $y=-(x+${b})^2+${c}$`
          )
        );
      }
      question.addParagraph(
        MCMaths.cleaner(`Which of the following is true when $x=${-b}$`)
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(MCMaths.cleaner(`$\\frac{d^{\\ 2}y}{dx^2}>0$`));

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(MCMaths.cleaner(`$\\frac{d^{\\ 2}y}{dx^2}<0$`));

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(MCMaths.cleaner(`$\\frac{d^{\\ 2}y}{dx^2}=0$`));

      if (a === 1) {
        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
      } else {
        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
        question.addAnswer(a3, false);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_AppliedDifferentiation_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const inc = MCRandom.randomInt(-1, 1, 2);

    const stretch = 1 / MCRandom.randomInt(3, 8);
    const r1 =
      (MCRandom.randomInt(1, 8) / MCRandom.randomInt(2, 8)) *
      MCRandom.randomInt(-1, 1, 2);
    const r2 =
      (MCRandom.randomInt(1, 8) / MCRandom.randomInt(2, 8)) *
      MCRandom.randomInt(-1, 1, 2);

    const poly = new MCMaths.Polynomial(
      [
        stretch,
        (2 * r1 + r2) * stretch,
        (r2 * 2 * r1 + r1 * r1) * stretch,
        r1 * r1 * r2 * stretch,
      ],
      "x"
    );
    const tps = poly.derivative().roots();

    // const test1 = new MCMaths.Polynomial([1, 0, -12, 16], 'x');
    // const test2 = new MCMaths.Polynomial([1/3, 0, -12/3, 16/3], 'x');

    /* END VARIABLES AND CONSTANTS */
    if (
      r1 !== r2 &&
      Math.abs(r1 - r2) > 3 &&
      r2 > r1 &&
      tps[0] % (1 / 8) === 0 &&
      tps[1] % (1 / 8) === 0 &&
      tps[1] < tps[0] &&
      poly.evaluate(tps[1]) < 12
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addGraph(poly.graph());
      // question.addGraph(test1.graph());
      // question.addGraph(test2.graph());
      // question.addParagraph(`test1=$${test1.toString()}$`);
      // question.addParagraph(`test2=$${test2.toString()}$`);

      question.addParagraph(
        `The graph above shows a curve $C$ with equation $y=f(x)$ where`
      );
      // question.addParagraph(MCMaths.cleaner(`$f(x)=${stretch}(x+${r2})(x+${r1})^2=${poly.toString()}$`));
      question.addParagraph(
        MCMaths.cleaner(
          `$C$ has turning points at $x=${tps[1]}$ and $x=${
            tps[0]
          }$ and has roots at $x=${-r2}$ and $x=${-r1}$`
        )
      );

      if (inc === 1) {
        question.addParagraph(
          `For what values of $x$ is $f(x)$ always an increasing function`
        );
        question.addHeading(`Choose TWO`);
      } else {
        question.addParagraph(
          `For what values of $x$ is $f(x)$ always a decreasing function`
        );
      }

      if (inc === 1) {
        const a1 = new MCQuestion.MultipleChoiceAnswer();
        a1.addParagraph(MCMaths.cleaner(`$x<${tps[1]}$`));

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        a2.addParagraph(MCMaths.cleaner(`$x>${tps[1]}$ and $x<${tps[0]}$`));

        const a3 = new MCQuestion.MultipleChoiceAnswer();
        a3.addParagraph(MCMaths.cleaner(`$x>${-r2}$`));

        const a4 = new MCQuestion.MultipleChoiceAnswer();
        a4.addParagraph(MCMaths.cleaner(`$x>${tps[0]}$`));

        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, true);
      } else {
        const a1 = new MCQuestion.MultipleChoiceAnswer();
        a1.addParagraph(MCMaths.cleaner(`$x<${tps[1]}$`));

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        a2.addParagraph(MCMaths.cleaner(`$x>${tps[1]}$ and $x<${tps[0]}$`));

        const a3 = new MCQuestion.MultipleChoiceAnswer();
        a3.addParagraph(MCMaths.cleaner(`$x>${tps[0]}$`));

        const a4 = new MCQuestion.MultipleChoiceAnswer();
        a4.addParagraph(MCMaths.cleaner(`$x<${-r2}$`));

        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_AppliedDifferentiation_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const poly = new MCMaths.Polynomial([a, 2 * b * a, a * b * b + c], "x");

    const x = MCRandom.randomInt(-b - 1, -b + 1);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(3);
      question.addGraph(poly.graph());
      if (a === 1) {
        question.addParagraph(
          MCMaths.cleaner(
            `The curve $C$, shown above, has a minmum point at $(${-b}, ${c})$`
          )
        );
      } else {
        question.addParagraph(
          MCMaths.cleaner(
            `The curve $C$, shown above, has a maximum point at $(${-b}, ${c})$`
          )
        );
      }
      question.addParagraph(
        MCMaths.cleaner(`Which of the following is true when $x=${x}$`)
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(MCMaths.cleaner(`$\\frac{dy}{dx}>0$`));

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(MCMaths.cleaner(`$\\frac{dy}{dx}<0$`));

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(MCMaths.cleaner(`$\\frac{dy}{dx}=0$`));

      if (x === -b) {
        question.addAnswer(a1, false);
        question.addAnswer(a2, false);
        question.addAnswer(a3, true);
      } else if (a === 1 && x < -b) {
        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
        question.addAnswer(a3, false);
      } else if (a === 1 && x > -b) {
        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
      } else if (a === -1 && x < -b) {
        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
      } else {
        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
        question.addAnswer(a3, false);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_AppliedDifferentiation_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const goWrong = MCRandom.randomInt(0, 1);

    const c1 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const c2 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);

    const x = Math.cbrt((2 * c1) / c2).toFixed(2);
    const y = (c1 / (x * x) + c2 * x).toFixed(2);

    const sign = ["\\leq", "\\geq", "="][MCRandom.randomInt(0, 2)];
    const other = [">", "<"][MCRandom.randomInt(0, 1)];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      const graph = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      graph.plot(`${c1}/(x*x)+${c2}*x`, -10, 10);
      question.addGraph(graph);

      question.addParagraph(
        `A curve $C$, shown above, has a stationary point, $P$ at $(${x},${y})$`
      );
      question.addParagraph(`Which of the following statements is true at $P$`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      if (goWrong === 0) {
        a1.addParagraph(`$\\frac{dy}{dx} ${sign} 0$`);
      } else if (y > 0) {
        a1.addParagraph(`$\\frac{d^{\\ 2}y}{dx^2} > 0$`);
      } else {
        a1.addParagraph(`$\\frac{d^{\\ 2}y}{dx^2} < 0$`);
      }

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$\\frac{dy}{dx} ${other} 0$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      if (y > 0) {
        a3.addParagraph(`$\\frac{d^{\\ 2}y}{dx^2} < 0$`);
      } else {
        a3.addParagraph(`$\\frac{d^{\\ 2}y}{dx^2} > 0$`);
      }

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      if (goWrong === 0) {
        if (MCRandom.randomInt(0, 1) === 0) {
          a4.addParagraph(`$\\frac{d^{\\ 2}y}{dx^2} = 0$`);
        } else {
          a4.addParagraph(`$\\frac{d^{\\ 2}y}{dx^2} \\neq 0$`);
        }
      } else {
        a4.addParagraph(`$\\frac{dy}{dx} \\neq 0$`);
      }

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_AppliedDifferentiation_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const power = MCRandom.randomInt(2, 5);
    const c1 =
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-power, power, 2 * power);
    const c2 = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);

    const x = MCRandom.randomInt(1, 5);
    const type = MCRandom.randomInt(-1, 1, 2); // 1 is tangent
    const m = (c1 / power) * x ** (1 / power - 1) + c2;
    /* END VARIABLES AND CONSTANTS */
    if (m % (1 / 2) === 0 && m !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        MCMaths.cleaner(
          `A curve $C$ has equation $y=${c1}x^{${
            1 / power
          }}+${c2}x+p$ where $p$ is a constant`
        )
      );
      question.addParagraph(`The line $L$ is tangent to $C$ at $x=${x}$`);
      if (type === 1) {
        question.addParagraph(`Select the line below that is parallel to $L$`);
      } else {
        question.addParagraph(`Select the line below that is normal to $L$`);
      }

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      if ((m + 0.5) % 1 === 0) {
        a1.addParagraph(
          MCMaths.cleaner(`$2y+${-m * 2}x=${MCRandom.randomInt(-15, 15)}$`)
        );
      } else {
        a1.addParagraph(
          MCMaths.cleaner(`$y+${-m}x=${MCRandom.randomInt(-15, 15)}$`)
        );
      }
      const a2 = new MCQuestion.MultipleChoiceAnswer();
      if ((m + 0.5) % 1 === 0) {
        a2.addParagraph(
          MCMaths.cleaner(`$${m * 2}y+2x=${MCRandom.randomInt(-15, 15)}$`)
        );
      } else {
        a2.addParagraph(
          MCMaths.cleaner(`$${m}y+x=${MCRandom.randomInt(-15, 15)}$`)
        );
      }

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      if ((m + 0.5) % 1 === 0) {
        a3.addParagraph(
          MCMaths.cleaner(`$2y+${2 * m}x=${MCRandom.randomInt(-15, 15)}$`)
        );
      } else {
        a3.addParagraph(
          MCMaths.cleaner(`$y+${m}x=${MCRandom.randomInt(-15, 15)}$`)
        );
      }

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      if ((m + 0.5) % 1 === 0) {
        a4.addParagraph(
          MCMaths.cleaner(`$${m * 2}y-2x=${MCRandom.randomInt(-15, 15)}$`)
        );
      } else {
        a4.addParagraph(
          MCMaths.cleaner(`$${m}y-x=${MCRandom.randomInt(-15, 15)}$`)
        );
      }

      if (type === 1) {
        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      } else {
        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_BasicDifferentiation_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a1 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const r1 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const a2 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const r2 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const u1 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);

    const x = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const y = ((a1 * x + r1) * (a2 * x + r2)) / (u1 * x);

    const quad = new MCMaths.Polynomial(
      [a1 * a2, a1 * r2 + a2 * r1, r1 * r2],
      "x"
    );
    const curve = new MCMaths.Polynomial(
      [(a1 * a2) / u1, (a1 * r2 + a2 * r1) / u1, (r1 * r2) / u1],
      "x"
    ).getCoefficients();

    const m = curve[0] - curve[2] / x ** 2;
    const y2 = ((-a1 * x + r1) * (-a2 * x + r2)) / (-u1 * x);

    /* END VARIABLES AND CONSTANTS */
    if (parseFloat(y.toFixed(3)) === y && parseFloat(m.toFixed(3)) === m) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph("question", `A curve $C$ has equation`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$y=\\frac{(${a1}x+${r1})(${a2}x+${r2})}{${u1}x}$`)
      );
      question.addParagraph(
        "question",
        `a) Find $\\frac{dy}{dx}$ in the form $A+Bx^{-2}$`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `b) Hence, find the gradient of $C$ at the point $P,(${x},${y})$`
        )
      );
      question.addParagraph(
        "question",
        `c) Find another point on $C$ where the gradient is the same as at $P$`
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) Find $\\frac{dy}{dx}$ in the form $A+Bx^{-2}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=\\frac{(${a1}x+${r1})(${a2}x+${r2})}{${u1}x}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=\\frac{${quad.toString()}}{${u1}x}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${curve[0]}x+${curve[1]}+${curve[2]}x^{-1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore \\frac{dy}{dx}=${curve[0]}+${-curve[2]}x^{-2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore A=${curve[0]}, B=${-curve[2]}$`)
      );
      // b
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `b) Hence, find the gradient of $C$ at the point $(${x},${y})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{dy}{dx}=${curve[0]}+${-curve[2]}x^{-2}$`)
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`Given $x=${x}$ at point $(${x}, ${y})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{dy}{dx}=${curve[0]}+${-curve[2]}(${x})^{-2}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${m}$`));
      // c
      question.addHeading(
        "solution",
        `c) Find another point on $C$ where the gradient is the same as at $P$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{dy}{dx}=${curve[0]}+${-curve[2]}x^{-2}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${curve[0]}+${-curve[2]}x^{-2}=${m}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{1}{x^2}=${(-m + curve[0]) / curve[2]}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$x^2=${x ** 2}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x=${-x}, x \\neq ${x}$ as that's point $P$`
        )
      );
      question.addHeading("solution", `Sub into equation of $C$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$y=\\frac{(${a1}(${-x})+${r1})(${a2}(${-x})+${r2})}{${u1}(${-x})}$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${y2}$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore \\frac{dy}{dx}=${m}$ at $(${-x}, ${y2})$`)
      );
      // answer
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(curve[0], 0, "a) $\\ A = $");
      group1.addInput(-curve[2], 0, "a) $\\ B = $");
      question.addInputGroup(group1);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(m, 0, "b)");
      question.addInputGroup(group2);

      const group3 = new MCQuestion.InputGroup(2);
      group3.addInput(-x, 0, "c) $\\ x = $");
      group3.addInput(y2, 0, "c) $\\ y = $");
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_BasicDifferentiation_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const d1 = MCRandom.randomInt(2, 5);
    const c1 =
      (MCRandom.randomInt(1, 2 * d1, 2) / d1) * MCRandom.randomInt(-1, 1, 2);

    const d2 = MCRandom.randomInt(2, 5);
    const c2 =
      (MCRandom.randomInt(1, 2 * d2) / d2) * MCRandom.randomInt(-1, 1, 2);

    const d3 = MCRandom.randomInt(2, 5);
    const c3 =
      (MCRandom.randomInt(1, 2 * d3) / d3) * MCRandom.randomInt(-1, 1, 2);

    const d4 = MCRandom.randomInt(2, 5);
    const x = MCRandom.randomInt(1, 5 * d4) / d4;

    // Not actual A and B in question part a) and b)
    const A = -0.5 * -1.5 * c1;
    const B = c2 * c3 * (c3 - 1);

    const c_ans = A * x ** -2.5 + B * x ** (c3 - 2);

    const d5 = MCRandom.randomInt(2, 5);
    const x2 = MCRandom.randomInt(1, 5 * d5) / d5;

    const b_ans = -0.5 * c1 * x2 ** -1.5 + c2 * c3 * x2 ** (c3 - 1);
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(A.toFixed(3)) === A &&
      parseFloat(B.toFixed(3)) === B &&
      parseFloat(c_ans.toFixed(3)) === c_ans &&
      parseFloat(b_ans.toFixed(3)) === b_ans &&
      x !== x2 &&
      c1 % 1 !== 0 &&
      c3 % 1 !== 0 &&
      c3 !== -0.5 &&
      Math.abs(c_ans) < 100 &&
      Math.abs(b_ans) < 100
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$f(x)=\\frac{${c1 * d1}}{${d1}\\sqrt{x}}+${c2}x^{${c3}}, x>0$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `a) Find $f'(x)$ in the form $Ax^{-1.5}+Bx^{${c3 - 1}}$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`b) Hence, evaluate $f'(${x2})$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`c) Evaluate $f''(${x})$`)
      );
      // solution
      // a
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `a) Find $f''(x)$ in the form $Ax^{-2.5}+Bx^{${c3 - 2}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$f(x)=\\frac{${c1 * d1}}{${d1}\\sqrt{x}}+${c2}x^{${c3}}, x>0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${c1}x^{-0.5}+${c2}x^{${c3}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f'(x)=${-0.5 * c1}x^{-1.5}+${c2 * c3}x^{${c3 - 1}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore A=${-0.5 * c1}, B=${c2 * c3}$`)
      );
      // b
      question.addHeading(
        "solution",
        MCMaths.cleaner(`b) Hence, evaluate $f'(${x2})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f'(x)=${-0.5 * c1}x^{-1.5}+${c2 * c3}x^{${c3 - 1}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$f'(${x2})=${-0.5 * c1}(${x2})^{-1.5}+${c2 * c3}(${x2})^{${c3 - 1}}$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${b_ans}$`));
      // c
      question.addHeading(
        "solution",
        MCMaths.cleaner(`c) Evaluate $f''(${x})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$f'(x)=${-0.5 * c1}x^{-1.5}+${c2 * c3}x^{${c3 - 1}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore f''(x)=${-0.5 * -1.5 * c1}x^{-2.5}+${
            c2 * c3 * (c3 - 1)
          }x^{${c3 - 2}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$f''(${x})=${-0.5 * -1.5 * c1}(${x})^{-2.5}+${
            c2 * c3 * (c3 - 1)
          }(${x})^{${c3 - 2}}$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${c_ans}$`));
      // answer
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(-0.5 * c1, 0, "a) $\\ A = $");
      group1.addInput(c2 * c3, 0, "a) $\\ B = $");
      question.addInputGroup(group1);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(b_ans, 0, "b)");
      question.addInputGroup(group2);

      const group3 = new MCQuestion.InputGroup(1);
      group3.addInput(c_ans, 0, "c)");
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_BasicDifferentiation_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const c1 = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const c2 = MCRandom.randomInt(1, 5);
    const p1 = MCRandom.randomInt(3, 7, 2) * MCRandom.randomInt(-1, 1, 2);

    const c3 = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);
    const p2 = (MCRandom.randomInt(1, 7) * MCRandom.randomInt(-1, 1, 2)) / 2;

    const x = MCRandom.randomInt(1, 16);
    const a = c1 * c2 * x ** (p1 / 2) + c3 * x;

    const x2 = MCRandom.randomInt(1, 16);
    const a2 = ((c1 * c2 * p1) / 2) * x2 ** (p1 / 2 - 1) + c3;
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(a.toFixed(3)) === a &&
      Math.abs(a) < 100 &&
      x !== x2 &&
      parseFloat(a2.toFixed(3)) === a2 &&
      Math.abs(a2) < 100 &&
      x !== x2
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        "question",
        `The gradient of a curve, $C$ at a point is calculated using the following equation`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$\\frac{dy}{dx}=${c1}\\sqrt{${c2 ** 2}x^{${p1}}}+${c3}x, x>0$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `a) Find the rate of change of $y$ with respect to $x$ when $x=${x}$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `b) Find $\\frac{d^{\\ 2}y}{dx^{2}}$ in the form $Ax^{${
            p1 / 2 - 1
          }}+B$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `c) Hence, find $x$ when the rate of change of gradient of $C$ is $${a2}$`
        )
      );
      // solution
      // a
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `a) Find the rate of change of $y$ with respect to $x$ when $x=${x}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{dy}{dx}=${c1}\\sqrt{${c2 ** 2}x^{${p1}}}+${c3}x, x>0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{dy}{dx}|_{x=${x}}=${c1}\\sqrt{${
            c2 ** 2
          }(${x})^{${p1}}}+${c3}(${x})$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${a}$`));
      // b
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `b) Find $\\frac{d^{\\ 2}y}{dx^{2}}$ in the form $Ax^{${
            p1 / 2 - 1
          }}+B$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{dy}{dx}=${c1}\\sqrt{${c2 ** 2}x^{${p1}}}+${c3}x$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${c1 * c2}x^{${p1 / 2}}+${c3}x$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore \\frac{d^{\\ 2}y}{dx^{2}}=${(c1 * c2 * p1) / 2}x^{${
            p1 / 2 - 1
          }}+${c3}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore A=${(c1 * c2 * p1) / 2}, B=${c3}$`)
      );
      // c
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `c) Hence, find $x$ when the rate of change of gradient of $C$ is $${a2}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\frac{d^{\\ 2}y}{dx^{2}}=${(c1 * c2 * p1) / 2}x^{${
            p1 / 2 - 1
          }}+${c3}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${(c1 * c2 * p1) / 2}x^{${p1 / 2 - 1}}+${c3}=${a2}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${x2}$`)
      );
      // answer
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(a, 0, "a)");
      question.addInputGroup(group1);

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput((c1 * c2 * p1) / 2, 0, "b) $\\ A = $");
      group2.addInput(c3, 0, "b) $\\ B = $");
      question.addInputGroup(group2);

      const group3 = new MCQuestion.InputGroup(1);
      group3.addInput(x2, 0, "c) $\\ x = $");
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_BasicDifferentiation_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const dk = MCRandom.randomInt(2, 5);
    const k =
      (MCRandom.randomInt(1, 2 * dk, 2) / dk) * MCRandom.randomInt(-1, 1, 2);

    const d1 = MCRandom.randomInt(2, 5);
    const c1k =
      (MCRandom.randomInt(1, 2 * d1, 2) / d1) * MCRandom.randomInt(-1, 1, 2);

    const c2k = MCRandom.randomInt(1, 15) * MCRandom.randomInt(-1, 1, 2);

    const c3k = MCRandom.randomInt(1, 15) * MCRandom.randomInt(-1, 1, 2);

    const poly = new MCMaths.Polynomial([c1k * k, c2k * k, c3k * k], "x");
    const derivative = poly.derivative();

    const x = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);
    const m = derivative.evaluate(x);

    const y = poly.evaluate(x);
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(m.toFixed(3)) === m &&
      Math.abs(m) < 10 &&
      parseFloat(y.toFixed(3)) === y
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question
      question.addParagraph(
        `question`,
        MCMaths.cleaner(
          `$f(x)=${c1k}kx^2+${c2k}kx+${c3k}k$, where $k$ is constant`
        )
      );
      question.addParagraph(`question`, `A curve $C$ has equation $y=f(x)$`);
      question.addParagraph(
        `question`,
        MCMaths.cleaner(
          `Given that the gradient of $C$ at point $P$, where $x=${x}$, is $${m}$`
        )
      );
      question.addParagraph(`question`, `a) Find $k$`);
      question.addParagraph(`question`, `b) Find the $y$ coordinate of $P$`);
      // solution
      // a
      question.addHeading(`solution`, `a) Find $k$`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$f(x)=${c1k}kx^2+${c2k}kx+${c3k}k$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore f'(x)=${2 * c1k}kx+${c2k}k$`)
      );
      question.addHeading(`solution`, MCMaths.cleaner(`Given $f'(${x})=${m}$`));
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${2 * c1k}k(${x})+${c2k}k=${m}$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore k = ${k}$`)
      );
      // b
      question.addHeading(`solution`, `b) Find the $y$ coordinate of $P$`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$f(x)=${c1k}(${k})x^2+${c2k}(${k})x+${c3k}(${k})$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$f(x)=${poly.toString()}$`)
      );
      question.addHeading(`solution`, MCMaths.cleaner(`At $P$, $x=${x}$`));
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$f(${x})=${c1k * k}(${x})^2+${c2k * k}(${x})+${c3k * k}$`
        )
      );
      question.addParagraph(`solution`, MCMaths.cleaner(`$=${y}$`));
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\therefore y=${y}$ at $P$`)
      );
      // answers
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(k, 0, "a) $\\ k = $");
      question.addInputGroup(group1);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(y, 0, "b) $\\ y = $");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_BasicDifferentiation_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const g = MCRandom.randomInt(-1, 1, 2); // -1 = <, 1 = >

    const dk = MCRandom.randomInt(2, 5);
    const k = MCRandom.randomInt(1, 3 * dk, 2) / dk;

    const c1 = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);
    const c2k = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);
    const c3 = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);

    const cubic = new MCMaths.Polynomial([k, c1, c2k * k, c3], "x");
    const dydx = cubic.derivative();
    const d2ydx2 = dydx.derivative();

    const x = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);
    const y = cubic.evaluate(x);

    const k1_coeff = (d2ydx2.evaluate(x) - 2 * c1) / 6;

    const dydx_roots = dydx.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(y) < 100 &&
      parseFloat(y.toFixed(3)) === y &&
      parseFloat(d2ydx2.evaluate(x).toFixed(3)) === d2ydx2.evaluate(x) &&
      ((k1_coeff % 1 === 0 && Math.abs(k1_coeff) < 4) ||
        Math.abs(k1_coeff) < 1) &&
      dydx_roots[0] !== "No Real Roots" &&
      parseFloat(dydx_roots[0].toFixed(3)) === dydx_roots[0] &&
      parseFloat(dydx_roots[1].toFixed(3)) === dydx_roots[1] &&
      dydx_roots[1] < dydx_roots[0]
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$y=kx^3+${c1}x^2+${c2k}kx+${c3}, k>0$`)
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Given $\\frac{d\\ ^2y}{dx^2}=${d2ydx2.evaluate(x)}$ at $y=${y}$`
        )
      );
      question.addParagraph("question", `a) Find $k$`);
      if (g === 1) {
        question.addParagraph(
          "question",
          `b) Find the set of values of $x$ for which the rate of change of $y$ with respect to $x$ is greater than 0 `
        );
      } else {
        question.addParagraph(
          "question",
          `b) Find the set of values of $x$ for which the rate of change of $y$ with respect to $x$ is less than 0 `
        );
      }
      // solution
      // a
      question.addHeading("solution", `a) Find $k$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=kx^3+${c1}x^2+${c2k}kx+${c3}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore \\frac{dy}{dx}=3kx^2+${2 * c1}x+${c2k}k$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore \\frac{d\\ ^2 y}{dx^2}=6kx+${2 * c1}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$6kx+${2 * c1}=${d2ydx2.evaluate(x)}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x=${k1_coeff}k^{-1}$ when $\\frac{d\\ ^2y}{dx^2}=${d2ydx2.evaluate(
            x
          )}$`
        )
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `Sub into $y=kx^3+${c1}x^2+${c2k}kx + ${c3}$ at $y=${y}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$y=k(${k1_coeff}k^{-1})^3+${c1}(${k1_coeff}k^{-1})^2+${c2k}k(${k1_coeff}k^{-1})+${c3}=${y}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${k1_coeff ** 3}k^{-2}+${c1 * k1_coeff ** 2}k^{-2}+${
            c2k * k1_coeff
          }+${c3}=${y}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${k1_coeff ** 3 + c1 * k1_coeff ** 2}k^{-2}=${
            y - (c2k * k1_coeff + c3)
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore k=${k}, k \\neq ${-k}$ as $k>0$`)
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(k, 0, "a) $\\ k = $");
      question.addInputGroup(group1);
      // b
      if (g === 1) {
        question.addHeading(
          "solution",
          `b) Find the set of values of $x$ for which the rate of change of $y$ with respect to $x$ is greater than 0 `
        );
      } else {
        question.addHeading(
          "solution",
          `b) Find the set of values of $x$ for which the rate of change of $y$ with respect to $x$ is less than 0 `
        );
      }
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\frac{dy}{dx}=3kx^2+${2 * c1}x+${c2k}k$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore \\frac{dy}{dx}=${3 * k}x^2+${2 * c1}x+${c2k * k}$`
        )
      );
      if (g === 1) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$=${dydx.toString()} > 0$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\therefore x<${dydx_roots[1]}$ or $x>${dydx_roots[0]}$`
          )
        );

        const group2 = new MCQuestion.InputGroup(2);
        group2.addInput(dydx_roots[1], 0, "b) $\\ x < $");
        group2.addInput(dydx_roots[0], 0, "b) $\\ x > $");
        question.addInputGroup(group2);
      } else {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$=${dydx.toString()} < 0$`)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\therefore x>${dydx_roots[1]}$ and $x<${dydx_roots[0]}$`
          )
        );
        const group2 = new MCQuestion.InputGroup(2);
        group2.addInput(dydx_roots[1], 0, "b) $\\ x > $");
        group2.addInput(dydx_roots[0], 0, "b) $\\ x < $");
        question.addInputGroup(group2);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_BasicDifferentiation_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const coeff = MCRandom.randomInt(2, 6);
    const power = MCRandom.randomInt(3, 6);
    /* END VARIABLES AND CONSTANTS */
    if (coeff !== power && power % coeff !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        MCMaths.cleaner(`$y=\\frac{1}{${coeff}x^{${power}}}$`)
      );
      question.addParagraph(
        `Choose the correct expression for $\\frac{dy}{dx}$`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(
        MCMaths.cleaner(`$\\frac{dy}{dx}=${-power / coeff}x^{${-power - 1}}$`)
      );

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(
        MCMaths.cleaner(
          `$\\frac{dy}{dx}=${1 / (power * coeff)}x^{${-power + 1}}$`
        )
      );

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(
        MCMaths.cleaner(`$\\frac{dy}{dx}=${-coeff * power}x^{${-power - 1}}$`)
      );

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(
        MCMaths.cleaner(`$\\frac{dy}{dx}=${power / coeff}x^{${-power + 1}}$`)
      );

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_BasicDifferentiation_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(2, 6, 2);
    const c = MCRandom.randomInt(2, 16, 2) * MCRandom.randomInt(-1, 1, 2);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        MCMaths.cleaner(`$f(x)=\\frac{${a}x^2+${c}}{\\sqrt{x}}$`)
      );
      question.addParagraph(`Find $f'(x)$`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(
        MCMaths.cleaner(
          `$f'(x)=${1.5 * a}x^{\\frac{1}{2}}+${-0.5 * c}x^{\\frac{-3}{2}}$`
        )
      );

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(MCMaths.cleaner(`$f'(x)=${2 * a}x^{\\frac{1}{2}}$`));

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(MCMaths.cleaner(`$f'(x)=${4 * a}x^{\\frac{3}{2}}$`));

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(
        MCMaths.cleaner(
          `$f'(x)=${1.5 * a}x^{\\frac{3}{2}}+${-0.5 * c}x^{\\frac{-1}{2}}$`
        )
      );

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_BasicDifferentiation_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

    const degree = MCRandom.randomInt(1, 2);

    const poly = new MCMaths.Polynomial([a, 0, 0, b, 0, c], "x");
    const d1 = poly.derivative();
    const d2 = d1.derivative();
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(4);
      question.addParagraph(
        MCMaths.cleaner(
          `A curve has equation $y=${a}x^5+${b}x^2+rx+${c}$ where $r$ is a constant`
        )
      );
      if (degree === 1) {
        question.addParagraph(
          MCMaths.cleaner(`Find the gradient of the curve when $x=0$`)
        );
      } else {
        question.addParagraph(
          MCMaths.cleaner(
            `Find the rate of change of the gradient of the curve when $x=0$`
          )
        );
      }

      if (degree === 1) {
        const a1 = new MCQuestion.MultipleChoiceAnswer();
        a1.addParagraph(`$r$`);

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        a2.addParagraph(`$0$`);

        const a3 = new MCQuestion.MultipleChoiceAnswer();
        a3.addParagraph(MCMaths.cleaner(`$r+${d1.evaluate(1)}$`));

        const a4 = new MCQuestion.MultipleChoiceAnswer();
        a4.addParagraph(MCMaths.cleaner(`$${c}$`));

        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      } else {
        const a1 = new MCQuestion.MultipleChoiceAnswer();
        a1.addParagraph(`$${d2.evaluate(0)}$`);

        const a2 = new MCQuestion.MultipleChoiceAnswer();
        a2.addParagraph(`$${d2.evaluate(1)}$`);

        const a3 = new MCQuestion.MultipleChoiceAnswer();
        a3.addParagraph(MCMaths.cleaner(`$0$`));

        const a4 = new MCQuestion.MultipleChoiceAnswer();
        a4.addParagraph(MCMaths.cleaner(`$r$`));

        question.addAnswer(a1, true);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_BasicDifferentiation_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

    const poly = new MCMaths.Polynomial([1, b, 0, c], "x");
    const der1 = poly.derivative();
    const der2 = der1.derivative();

    const xt = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);
    const yt = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);

    const decoy = new MCMaths.Polynomial(
      [3, 6 * xt + 2 * b, 3 * xt * xt + 2 * b * xt + yt],
      "x"
    );
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(MCMaths.cleaner(`$f(x)=x^2(x+${b})+${c}$`));
      question.addParagraph(
        `Which of the following shows the graph of $y=f'(x)$`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addGraph(der1.graph());

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addGraph(der2.graph());

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addGraph(poly.graph());

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addGraph(decoy.graph());

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_FirstPrinciples_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const mode = MCRandom.randomInt(0, 1);
    const x = new MCMaths.Polynomial([
      MCRandom.randomInt(1, 5) * mode,
      MCRandom.randomInt(1, 5) * (1 - mode),
      MCRandom.randomInt(-2, 2),
      MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2),
    ]);
    const i = MCRandom.randomInt(1, 3) * MCRandom.randomInt(-1, 1, 2);

    let h;
    let h2;
    let ans;

    if (x.getCoefficients().length === 4) {
      const a = x.getCoefficients()[0];
      const b = i * x.getCoefficients()[0] * 3;
      ans = i * i * x.getCoefficients()[0] * 3 + x.getCoefficients()[2];
      h = new MCMaths.Polynomial([a, b, ans, 0], "h");
      h2 = new MCMaths.Polynomial([a, b, ans], "h");
    } else {
      const a = x.getCoefficients()[0];
      ans = i * x.getCoefficients()[0] * 2 + x.getCoefficients()[1];
      h = new MCMaths.Polynomial([a, ans, 0], "h");
      h2 = new MCMaths.Polynomial([a, ans], "h");
    }

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$f(x)=${x.toString()}$`,
        `$f'(${i})=k$`,
        `a) Show that $\\displaystyle k=\\lim_{h\\to 0} \\left(${h2.toString()}\\right)$`,
        `b) Find $k$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING Differentiation by First Principals:`,
        `$\\displaystyle k=\\lim_{h\\to 0} \\frac{f(${i}+h)-f(${i})}{h}$`,
        `$\\displaystyle =\\lim_{h\\to 0} \\frac{${x
          .toString()
          .replace(/x/g, `(${i}+h)`)}-(${x
          .toString()
          .replace(/x/g, `(${i})`)})}{h}$`,
        `$\\displaystyle =\\lim_{h\\to 0} \\frac{${h.toString()}}{h}$`,
        `$\\displaystyle =\\lim_{h\\to 0} \\left(${h2.toString()}\\right)$`,
        `b) $=0+${ans}$`,
        `$=${ans}$`,
      ]);
      question.requiresSelfMarking();
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans, 0, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_FirstPrinciples_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 4);
    const b = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const x = new MCMaths.Polynomial([a, b, c]);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$f(x) = ${x.toString()}$`,
        `a) Show $\\displaystyle f'(x)=\\lim_{h\\to 0} \\frac{Ahx+Bh+Ch^2}{h}$.`,
        `b) Show $f'(x)=Ax+B$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING Differntiation by first principles:`,
        `$\\displaystyle f'(x)=\\lim_{h\\to 0} \\frac{f(x+h)-f(x)}{h}$`,
        `$\\displaystyle =\\lim_{h\\to 0} \\frac{${x
          .toString()
          .replace(/x/g, "(x+h)")}-(${x.toString()})}{h}$`,
        MCMaths.cleaner(
          `$\\displaystyle =\\lim_{h\\to 0} \\frac{${
            2 * a
          }hx+${b}h+${a}h^2}{h}$`
        ),
        MCMaths.cleaner(
          `b) $\\displaystyle =\\lim_{h\\to 0} ${2 * a}x+${b}+${a}h$`
        ),
        MCMaths.cleaner(`$ = ${2 * a}x+${b}+${a}(0)$`),
        MCMaths.cleaner(`$ = ${2 * a}x+${b}$`),
      ]);
      question.requiresSelfMarking();
      const group = new MCQuestion.InputGroup(3);
      group.addInput(2 * a, 0, "A=");
      group.addInput(b, 0, "B=");
      group.addInput(a, 0, "C=");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_FirstPrinciples_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 4);
    const b = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const x = new MCMaths.Polynomial([a, 0, b, c]);

    const x2 = new MCMaths.Polynomial([3 * a, 0, b]);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$f(x) = ${x.toString()}$`,
        `a) Show $\\displaystyle f'(x)=\\lim_{h\\to 0} \\frac{Ahx(x+h)+Bh^3+Ch}{h}$.`,
        `b) Show $\\displaystyle f''(x)=\\lim_{h\\to 0} \\frac{Dhx+Eh^2}{h}$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING Differentiation by first principles:`,
        `$\\displaystyle f'(x)=\\lim_{h\\to 0} \\frac{f(x+h)-f(x)}{h}$`,
        `$\\displaystyle =\\lim_{h\\to 0} \\frac{${x
          .toString()
          .replace(/x/g, "(x+h)")}-(${x.toString()})}{h}$`,
        MCMaths.cleaner(
          `$\\displaystyle =\\lim_{h\\to 0} \\frac{${
            3 * a
          }hx(x+h)+${a}h^3+${b}h}{h}$`
        ),
        `b) HEADING Finding derivative:`,
        MCMaths.cleaner(
          `$\\displaystyle f'(x)=\\lim_{h\\to 0} ${3 * a}x^2+${
            3 * a
          }hx+${a}h^2+${b}$`
        ),
        MCMaths.cleaner(`$ = ${3 * a}x^2+${3 * a}x(0)+${a}(0)^2+${b}$`),
        MCMaths.cleaner(`$ = ${3 * a}x^2+${b}$`),
        `HEADING Differentiation by first principles:`,
        `$\\displaystyle f''(x)=\\lim_{h\\to 0} \\frac{f'(x+h)-f'(x)}{h}$`,
        `$\\displaystyle =\\lim_{h\\to 0} \\frac{${x2
          .toString()
          .replace(/x/g, "(x+h)")}-(${x2.toString()})}{h}$`,
        `$\\displaystyle = \\lim_{h\\to 0} \\frac{${6 * a}hx+${3 * a}h^2}{h}$`,
      ]);
      const group = new MCQuestion.InputGroup(3);
      group.addInput(3 * a, 0, "A=");
      group.addInput(a, 0, "B=");
      group.addInput(b, 0, "C=");
      question.addInputGroup(group);
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(6 * a, 0, "D=");
      group2.addInput(3 * a, 0, "E=");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Differentiation_FirstPrinciples_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const x = new MCMaths.Polynomial([
      MCRandom.randomInt(2, 3),
      MCRandom.randomInt(2, 4) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 4) * MCRandom.randomInt(-1, 1, 2),
    ]);
    const mid = MCRandom.randomInt(1, 3) * MCRandom.randomInt(-1, 1, 2);
    const x1 = mid;
    const x2 = mid + 0.2;

    const f1 = x.evaluate(x1);
    const f1d = Math.round(f1 * 1000) / 1000;
    const f2 = x.evaluate(x2);
    const f2d = Math.round(f2 * 1000) / 1000;
    const m = (f2d - f1d) / (x2 - x1);
    const md = Math.round(m * 1000) / 1000;

    const fp = new MCMaths.FirstPrincipals(x, x1);
    const size = Math.max(
      Math.ceil(Math.abs(f1)) + 1,
      Math.ceil(Math.abs(f2)) + 1,
      x1 + 2
    );

    const a = x.getCoefficients()[0];
    const b = x.getCoefficients()[1];
    /* END VARIABLES AND CONSTANTS */
    if (
      f1 === f1d &&
      f2 === f2d &&
      f2d !== f1d &&
      m === md /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$f(x)=${x.toString()}, \\ x_1=${x1}, \\ x_2=${x2}.$`,
        `a) Find the gradient between $f(x_1)$ and $f(x_2)$.`,
        `b) Find the gradient as $x_2\\to x_1$, by first principles.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING Evaluating:`,
        `$f(x_1)=${x.toString().replace(/x/g, `(${x1})`)}$`,
        `$=${f1}$`,
        `$f(x_2)=${x.toString().replace(/x/g, `(${x2})`)}$`,
        `$=${f2}$`,
        `HEADING Finding Gradient:`,
        `$\\displaystyle m=\\frac{f(x_2)-f(x_1)}{x_2-x_1}$`,
        MCMaths.cleaner(
          `$\\displaystyle = \\frac{${f2d}-${f1d}}{${x2}-${x1}}$`,
          false,
          false
        ),
        `$=${m}$`,
        `b) HEADING Forming Equation:`,
        `$=\\displaystyle\\lim_{h\\to 0}\\frac{f(${x1}+h)-f(${x1})}{h}$`,
        `$=\\displaystyle\\lim_{h\\to 0}\\frac{f(${x1}+h)-f(${x1})}{h}$`,
        `$=\\displaystyle\\lim_{h\\to 0}\\frac{${x
          .toString()
          .replace(/x/g, `(${x1}+h)`)}-(${x
          .toString()
          .replace(/x/g, `(${x1})`)})}{h}$`,
        `$=\\displaystyle\\lim_{h\\to 0}\\frac{${2 * a * x1 + b}h+${a}h^2}{h}$`,
        `$=\\displaystyle\\lim_{h\\to 0} ${2 * a * x1 + b}+${a}h$`,
        `$=${2 * a * x1 + b}+${a}(0)$`,
        `$=${2 * a * x1 + b}$`,
      ]);
      question.requiresSelfMarking();
      const group = new MCQuestion.InputGroup(1);
      group.addInput(m, 0, "a)");
      group.addInput(2 * a * x1 + b, 0, "b)");
      question.addInputGroup(group);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Distributions_BinomialBinomialDistribution_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function allGood(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i] < 0.075 || arr[i] > 0.925) {
          return false;
        }
      }
      return true;
    }

    const n = 40;
    const p = parseFloat((MCRandom.randomInt(20, 70, 5) / 100).toFixed(2));

    const i = MCRandom.randomInt(5, 20);
    const ii = MCRandom.randomInt(15, 25);
    const iii_low = MCRandom.randomInt(5, 25);
    const iii_high = MCRandom.randomInt(15, 35);

    const B = new MCMaths.BinomialDistribution(n, p);

    const i_ans = B.cdf(i - 1);
    const ii_ans = B.pdf(ii);
    const iii_high_ans = B.cdf(iii_high);
    const iii_low_ans = B.cdf(iii_low);
    const iii_ans = iii_high_ans - iii_low_ans;
    const b_ans = n * p;
    const c_ans = parseFloat((n * p * (1 - p)).toFixed(2));

    const answers = [i_ans, ii_ans, iii_high_ans, iii_low_ans, iii_ans];
    /* END VARIABLES AND CONSTANTS */
    if (allGood(answers)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Plastic straws are made in various colours in packs of $${n}$. The probability of a random straw in the pack being green is $${p}$`
      );
      question.addParagraph(
        "question",
        `a) Determine the probability that a pack contains:`
      );
      question.addParagraph("question", `i) less than $${i}$ green straws.`);
      question.addParagraph("question", `ii) $${ii}$ green straws.`);
      question.addParagraph(
        "question",
        `iii) at most $${iii_high}$, but more than $${iii_low}$ green straws.`
      );

      question.addParagraph(
        `question`,
        `b) State the mean amount of green straws you'd expect to find in a packet.`
      );
      question.addParagraph(
        `question`,
        `c) Calculate the variance of the number of green straws per packet.`
      );
      // solution
      // a
      question.addHeading(
        `solution`,
        `a) Let $X$ be the number of green straws in a packet.`
      );
      question.addParagraph(`solution`, `$X \\sim B(${n}, ${p})$`);
      // i
      question.addHeading(
        "solution",
        `i) Find the probability a packet contains less than $${i}$ green straws.`
      );
      question.addParagraph("solution", `$P(X<${i})=P(X \\leq ${i - 1})$`);
      question.addParagraph("solution", `$=${i_ans.toFixed(4)}$`);
      question.addParagraph("solution", `$\\approx ${i_ans.toFixed(2)}$`);
      // ii
      question.addHeading(
        "solution",
        `ii) Find the probability a packet contains $${ii}$ green straws.`
      );
      question.addParagraph("solution", `$P(X=${ii})=${ii_ans.toFixed(4)}$`);
      question.addParagraph("solution", `$\\approx ${ii_ans.toFixed(2)}$`);
      // iii
      question.addHeading(
        "solution",
        `iii) Find the probability a packet contains more than $${iii_low}$, but at most $${iii_high}$ green straws.`
      );
      question.addParagraph(
        "solution",
        `$P(${iii_low}<X\\leq${iii_high})=P(X \\leq ${iii_high}) - P(X \\leq ${iii_low})$`
      );
      question.addParagraph(
        "solution",
        `$P(X \\leq ${iii_high})=${iii_high_ans.toFixed(4)}$`
      );
      question.addParagraph(
        "solution",
        `$P(X \\leq ${iii_low})=${iii_low_ans.toFixed(4)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore P(X \\leq ${iii_high}) - P(X \\leq ${iii_low})=${iii_high_ans.toFixed(
          4
        )}-${iii_low_ans.toFixed(4)}$`
      );
      question.addParagraph("solution", `$=${iii_ans.toFixed(4)}$`);
      question.addParagraph("solution", `$\\approx ${iii_ans.toFixed(2)}$`);
      // b
      question.addHeading(`solution`, `b) $E(X)=np$`);
      question.addParagraph(`solution`, `$=${n}(${p})$`);
      question.addParagraph(`solution`, `$=${b_ans}$`);
      // c
      question.addHeading(`solution`, `c) Var$(X)=np(1-p)$`);
      question.addParagraph(`solution`, `$=${n}(${p})(1-${p})$`);
      question.addParagraph(`solution`, `$=${c_ans}$`);
      // answers
      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(parseFloat(i_ans.toFixed(2)), 0.05, `ai)`);
      g1.addInput(parseFloat(ii_ans.toFixed(2)), 0.05, `ii)`);
      g1.addInput(parseFloat(iii_ans.toFixed(2)), 0.05, `iii)`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(b_ans, 0, `b)`);
      g2.addInput(parseFloat(c_ans.toFixed(1)), 0.5, `c)`);
      question.addInputGroup(g2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Distributions_BinomialBinomialDistribution_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function allDiff(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < i; j += 1) {
          if (arr[i] === arr[j] && i !== j) {
            return false;
          }
        }
      }
      return true;
    }
    function allGood(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i] < 0.075 || arr[i] > 0.925) {
          return false;
        }
      }
      return true;
    }

    const rare = parseFloat((MCRandom.randomInt(5, 20, 5) / 100).toFixed(2));
    const medium = parseFloat((MCRandom.randomInt(40, 70, 5) / 100).toFixed(2));
    const well = parseFloat((1 - rare - medium).toFixed(2));

    const n = MCRandom.randomInt(20, 60, 5);
    const n2 = MCRandom.randomInt(80, 120, 5);
    const n3 = MCRandom.randomInt(300, 600, 50);

    const B_rare = new MCMaths.BinomialDistribution(n, rare);
    const B_medium = new MCMaths.BinomialDistribution(n, medium);
    const B_well = new MCMaths.BinomialDistribution(n, well);

    const a = MCRandom.randomInt(5, n);
    const a_ans = 1 - B_well.cdf(a);

    const bi = MCRandom.randomInt(10, 40);
    const bii_low = MCRandom.randomInt(15, 30);
    const bii_high = bii_low + MCRandom.randomInt(5, 15);

    const bi_ans = B_rare.cdf(bi);
    const bii_low_ans = B_medium.cdf(bii_low - 1);
    const bii_high_ans = B_medium.cdf(bii_high - 1);
    const bii_ans = bii_high_ans - bii_low_ans;

    const mean = n3 * medium;
    const Var = mean * (1 - medium);
    const sd = Math.sqrt(parseFloat(Var.toFixed(4)));

    const answers = [a_ans, bi_ans, bii_high_ans, bii_low_ans, bii_ans];
    /* END VARIABLES AND CONSTANTS */
    if (allDiff([rare, medium, well]) && allGood(answers) && mean % 1 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `A restaurant offers to serve steaks rare, medium or well done. The probabilities of a customer making these choices are $${rare}, ${medium}$ and $${well}$ respectively.`
      );
      question.addParagraph(
        `question`,
        `On a particular night the restaurant serves $${n}$ steaks.`
      );
      question.addParagraph(
        `question`,
        `a) Calculate the probability that more than $${a}$ customers order a well done steak.`
      );
      question.addParagraph(
        `question`,
        `b) On another day the restaurant serves $${n2}$ steaks. Calculate the probability that:`
      );
      question.addParagraph(
        `question`,
        `i) at most $${bi}$ customers order a rare steak.`
      );
      question.addParagraph(
        `question`,
        `ii) at least $${bii_low}$, but less than $${bii_high}$ customers order a medium steak.`
      );
      question.addParagraph(
        `question`,
        `c) In a week where $${n3}$ steaks are served, calculate the mean and standard deviation for the number of customers ordering medium steaks.`
      );
      // solution
      // a
      question.addHeading(
        `solution`,
        `a) Let $W$ be the number of customers who order a well done steak.`
      );
      question.addParagraph(`solution`, `$W \\sim B(${n}, ${well})$`);
      question.addParagraph(`solution`, `$P(W>${a})= 1 - P(W \\leq ${a})$`);
      question.addParagraph(`solution`, `$= 1 - ${B_well.cdf(a).toFixed(4)}$`);
      question.addParagraph(
        `solution`,
        `$\\approx ${parseFloat(a_ans.toFixed(2))}$`
      );
      // b
      question.addHeading(
        `solution`,
        `bi) Let $R$ be the number of customers who order a rare steak.`
      );
      question.addParagraph(`solution`, `$R \\sim B(${n2}, ${rare})$`);
      question.addParagraph(
        `solution`,
        `$P(R\\leq${bi})=${bi_ans.toFixed(4)}$`
      );
      question.addParagraph(
        `solution`,
        `$\\approx ${parseFloat(bi_ans.toFixed(2))}$`
      );
      // ii
      question.addHeading(
        `solution`,
        `ii) Let $M$ be the number of customers who order a medium steak.`
      );
      question.addParagraph(`solution`, `$M \\sim B(${n2}, ${medium})$`);
      question.addParagraph(
        `solution`,
        `$P(${bii_low}\\leq M<${bii_high})=P(M\\leq${
          bii_high - 1
        }) - P(M \\leq ${bii_low - 1})$`
      );
      question.addParagraph(
        "solution",
        `$P(M \\leq ${bii_high - 1})=${bii_high_ans.toFixed(4)}$`
      );
      question.addParagraph(
        "solution",
        `$P(M \\leq ${bii_low - 1})=${bii_low_ans.toFixed(4)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore P(X \\leq ${bii_high - 1}) - P(X \\leq ${
          bii_low - 1
        })=${bii_high_ans.toFixed(4)}-${bii_low_ans.toFixed(4)}$`
      );
      question.addParagraph("solution", `$=${bii_ans.toFixed(4)}$`);
      question.addParagraph(
        "solution",
        `$\\approx ${parseFloat(bii_ans.toFixed(2))}$`
      );
      // c
      question.addHeading(`solution`, `c) $M \\sim B(${n3}, ${medium})$`);
      question.addHeading(`solution`, ` $E(X)=np$`);
      question.addParagraph(`solution`, `$=${n3}(${medium})$`);
      question.addParagraph(`solution`, `$=${parseFloat(mean)}$`);
      question.addHeading(`solution`, `Var$(X)=np(1-p)$`);
      question.addParagraph(`solution`, `$=${n3}(${medium})(1-${medium})$`);
      question.addParagraph(`solution`, `$= ${parseFloat(Var.toFixed(4))}$`);
      question.addParagraph(
        `solution`,
        `$\\therefore \\sigma= \\sqrt{${parseFloat(Var.toFixed(4))}}$`
      );
      question.addParagraph(
        `solution`,
        `$\\approx ${parseFloat(sd.toFixed(2))}$`
      );

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(parseFloat(a_ans.toFixed(2)), 0.05, `a)`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(parseFloat(bi_ans.toFixed(2)), 0.05, `bi)`);
      g2.addInput(parseFloat(bii_ans.toFixed(2)), 0.05, `ii)`);
      question.addInputGroup(g2);

      const g3 = new MCQuestion.InputGroup(2);
      g3.addInput(parseFloat(mean.toFixed(2)), 0, `c) $\\ E(X)=$`);
      g3.addInput(parseFloat(sd.toFixed(2)), 0.5, `c) $\\ \\sigma=$`);
      question.addInputGroup(g3);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Distributions_BinomialBinomialDistribution_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function allGood(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i] < 0.075 || arr[i] > 0.925) {
          return false;
        }
      }
      return true;
    }

    const p = parseFloat((MCRandom.randomInt(10, 50, 5) / 100).toFixed(2));
    const n = MCRandom.randomInt(12, 36, 2);
    const person = MCMisc.getName();

    const B = new MCMaths.BinomialDistribution(n, p);

    const a = MCRandom.randomInt(n / 2 - 2, n - 2);
    const a_ans = B.pdf(a);

    const ltHalf = B.cdf(n / 2 - 1);
    const weeks = MCRandom.randomInt(2, 4);
    const days = MCRandom.randomInt(3, 5);
    const bi_ans = parseFloat(ltHalf.toFixed(2)) * 7 * weeks;
    const bii_ans = parseFloat(ltHalf.toFixed(2)) ** days;

    const answers = [a_ans, ltHalf, bii_ans];
    /* END VARIABLES AND CONSTANTS */
    if (allGood(answers) && (bi_ans % 1 < 0.3 || bi_ans % 1 > 0.7)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `${person.name}, an avid golfer, calculates that ${
          person.heshe
        } has a $${p * 100}$% chance of putting first try when ${
          person.heshe
        } makes it to the green.`
      );
      question.addParagraph(
        `question`,
        `${person.name} takes $${n}$ practice shots from random places on the green. By using the binomial distribution:`
      );
      question.addParagraph(
        "question",
        `a) Calculate the probability that she successfully putts $${a}$ of the $${n}$ balls.`
      );
      question.addParagraph(
        "question",
        `b)${person.name} practices like this every day for $${weeks}$ weeks.`
      );
      question.addParagraph(
        "question",
        `i) Calculate the number of days you would expect ${person.name} to putt less than half of the balls.`
      );
      question.addParagraph(
        "question",
        `ii) Calculate the probability that ${person.heshe} putts less than half of the balls every day for the first $${days}$ days.`
      );
      question.addParagraph(
        "question",
        `c) State an assumption you made that was necessary to use the binomial distribution in part a.`
      );
      // a
      question.addHeading(
        "solution",
        `a) Let $X$ be the number of successful putts.`
      );
      question.addHeading("solution", `$X\\sim B(${n}, ${p})$`);
      question.addHeading(
        "solution",
        `$P(X=${a})=${parseFloat(a_ans.toFixed(4))}$`
      );
      question.addHeading(
        "solution",
        `$\\approx ${parseFloat(a_ans.toFixed(2))}$`
      );
      // b
      question.addHeading(
        "solution",
        `bi) $P(X < ${n / 2}) = P(X \\leq ${n / 2 - 1})$`
      );
      question.addParagraph("solution", `$=${parseFloat(ltHalf.toFixed(4))}$`);
      question.addParagraph(
        "solution",
        `$\\approx ${parseFloat(ltHalf.toFixed(2))}$`
      );
      question.addParagraph(
        "solution",
        `$${parseFloat(ltHalf.toFixed(2))}(7\\times ${weeks})=${parseFloat(
          bi_ans.toFixed(2)
        )}$`
      );
      question.addParagraph(
        "solution",
        `$\\approx ${parseFloat(bi_ans.toFixed(0))}$ days`
      );

      question.addHeading(
        "solution",
        `ii) Putts less than half every day for the first $${days}$ days.`
      );
      question.addHeading(
        "solution",
        `$${parseFloat(ltHalf.toFixed(2))}^{${days}}=${parseFloat(
          bii_ans.toFixed(4)
        )}$`
      );
      question.addHeading(
        "solution",
        `$\\approx ${parseFloat(bii_ans.toFixed(2))}$`
      );

      question.addHeading("solution", `c) Possible assumptions:`);
      question.addParagraph(
        "solution",
        `The probability of putting is fixed for every shot.`
      );
      question.addParagraph(
        "solution",
        `Each putt attempt is independent of all others.`
      );
      question.addParagraph(
        "solution",
        `There are only two possible outcomes for each attempt, putting or not putting.`
      );

      question.requiresSelfMarking();

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(parseFloat(a_ans.toFixed(2)), 0.05, `a)`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(parseFloat(bi_ans.toFixed(0)), 0, `bi)`);
      g2.addInput(parseFloat(bii_ans.toFixed(2)), 0.05, `ii)`);
      question.addInputGroup(g2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Distributions_BinomialBinomialDistribution_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function allGood(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i] < 0.075 || arr[i] > 0.925) {
          return false;
        }
      }
      return true;
    }

    const p = parseFloat((MCRandom.randomInt(5, 20) / 100).toFixed(2));
    const n = MCRandom.randomInt(20, 50, 10);

    const i = MCRandom.randomInt(2, 8);
    const ii = MCRandom.randomInt(2, 8);

    const person = MCMisc.getName();
    const batches = MCRandom.randomInt(10, 20, 2);
    const b = MCRandom.randomInt(4, 8);

    const B = new MCMaths.BinomialDistribution(n, p);

    const i_ans = B.pdf(i);

    const ii_lt = B.cdf(ii - 1);
    const ii_ans = 1 - ii_lt;
    const p2 = parseFloat(ii_ans.toFixed(2));

    const B2 = new MCMaths.BinomialDistribution(batches, p2);
    const b_ans = B2.cdf(b - 1);

    const answers = [i_ans, ii_ans, ii_lt, b_ans];
    /* END VARIABLES AND CONSTANTS */
    if (allGood(answers)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The probability of nail being faulty is $${p}$.`
      );
      question.addParagraph(
        "question",
        `a) Find the probability that is a sample of $${n}$ nails:`
      );
      question.addParagraph("question", `i) $${i}$ nails are faulty.`);
      question.addParagraph(
        "question",
        `ii) at least $${ii}$ nails are faulty.`
      );

      question.addParagraph(
        `question`,
        `${person.name} inspects $${batches}$ batches of $${n}$ nails.`
      );
      question.addParagraph(
        `question`,
        `b) Find the probability that less than $${b}$ batches contain at least $${ii}$ faulty nails.`
      );
      // a

      question.addHeading(
        "solution",
        `a) Let $F$ be the number of faulty nails.`
      );
      question.addParagraph("solution", `$F \\sim B(${n}, ${p})$`);
      question.addHeading(
        "solution",
        `i) Find the probability that $${i}$ nails are faulty.`
      );
      question.addParagraph("solution", `$P(F=${i})=${i_ans.toFixed(4)}$`);
      question.addParagraph(
        "solution",
        `$\\approx ${parseFloat(i_ans.toFixed(2))}$`
      );
      question.addHeading(
        "solution",
        `ii) Find the probability that at least $${ii}$ nails are faulty.`
      );
      question.addParagraph(
        "solution",
        `$P(F\\geq${ii})=1-P(F \\leq ${ii - 1})$`
      );
      question.addParagraph("solution", `$=1-${parseFloat(ii_lt.toFixed(4))}$`);
      question.addParagraph("solution", `$\\approx ${p2}$`);

      // b
      question.addHeading(
        `solution`,
        `b) Let $Y$ be the number of batches that contain at least $${ii}$ faulty nails.`
      );
      question.addParagraph("solution", `$Y \\sim B(${batches}, ${p2})$`);
      question.addParagraph("solution", `$P(Y<${b})=P(Y\\leq ${b - 1})$`);
      question.addParagraph("solution", `$=${parseFloat(b_ans.toFixed(4))}$`);
      question.addParagraph(
        "solution",
        `$\\approx ${parseFloat(b_ans.toFixed(2))}$`
      );

      // ans

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(parseFloat(i_ans.toFixed(2)), 0.05, `ai)`);
      g1.addInput(parseFloat(ii_ans.toFixed(2)), 0.05, `ii)`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(parseFloat(b_ans.toFixed(2)), 0.1, `b)`);
      question.addInputGroup(g2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Distributions_BinomialBinomialDistribution_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function allGood(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i] < 0.075 || arr[i] > 0.925) {
          return false;
        }
      }
      return true;
    }
    function allDifferent(items) {
      for (let i = 0; i < items.length; i += 1) {
        for (let j = 0; j < items.length; j += 1) {
          if (
            Math.abs(parseFloat(items[i]) - parseFloat(items[j])) < 0.01 &&
            j !== i
          ) {
            return false;
          }
        }
      }
      return true;
    }

    const n = MCRandom.randomInt(10, 50, 10);
    const p = parseFloat((MCRandom.randomInt(2, 8) / 10).toFixed(1));
    const x = MCRandom.randomInt(Math.round(n / 4), Math.round((3 * n) / 4));

    const B = new MCMaths.BinomialDistribution(n, p);

    const index = MCRandom.randomInt(0, 3);
    const symbols = [`<`, `>`, `\\leq`, `\\geq`];
    const answers = [
      parseFloat(B.cdf(x - 1).toFixed(2)),
      parseFloat((1 - B.cdf(x)).toFixed(2)),
      parseFloat(B.cdf(x).toFixed(2)),
      parseFloat((1 - B.cdf(x - 1)).toFixed(2)),
    ];
    /* END VARIABLES AND CONSTANTS */
    if (allGood(answers) && allDifferent(answers)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `A random variable $X$ can be modelled by the binomial distribution such that $X \\sim B(${n}, ${p})$`
      );
      question.addParagraph(`Find $P(X ${symbols[index]} ${x})$`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${answers[0]}$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${answers[1]}$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${answers[2]}$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${answers[3]}$`);

      question.addAnswer(answer1, index === 0);
      question.addAnswer(answer2, index === 1);
      question.addAnswer(answer3, index === 2);
      question.addAnswer(answer4, index === 3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Distributions_BinomialBinomialDistribution_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function allGood(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i] < 0.075 || arr[i] > 0.925) {
          return false;
        }
      }
      return true;
    }
    function allDifferent(items) {
      for (let i = 0; i < items.length; i += 1) {
        for (let j = 0; j < items.length; j += 1) {
          if (
            Math.abs(parseFloat(items[i]) - parseFloat(items[j])) < 0.01 &&
            j !== i
          ) {
            return false;
          }
        }
      }
      return true;
    }

    const n = MCRandom.randomInt(10, 50, 10);
    const p = parseFloat((MCRandom.randomInt(2, 8) / 10).toFixed(1));

    const B = new MCMaths.BinomialDistribution(n, p);

    const index = MCRandom.randomInt(0, 2);
    const vals = [
      `the variance of $X$ `,
      `the standard deviation of $X$`,
      `$E(X)$`,
    ];
    const answers = [
      parseFloat((n * p * (1 - p)).toFixed(2)),
      parseFloat(Math.sqrt(n * p * (1 - p)).toFixed(2)),
      parseFloat((n * p).toFixed(2)),
    ];
    if (index === 0) {
      answers.push(
        parseFloat(
          (
            MCRandom.randomInt(
              Math.round(answers[0] * 25),
              Math.round(answers[0] * 75)
            ) / 100
          ).toFixed(2)
        )
      );
    } else if (index === 1) {
      answers.push(
        parseFloat(
          (
            MCRandom.randomInt(
              Math.round(answers[1] * 25),
              Math.round(answers[1] * 75)
            ) / 100
          ).toFixed(2)
        )
      );
    } else {
      answers.push(
        parseFloat(
          (
            MCRandom.randomInt(
              Math.round(answers[2] * 25),
              Math.round(answers[2] * 75)
            ) / 100
          ).toFixed(0)
        )
      );
    }
    /* END VARIABLES AND CONSTANTS */
    if (allDifferent(answers)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `A random variable $X$ can be modelled by the binomial distribution such that $X \\sim B(${n}, ${p})$`
      );
      question.addParagraph(`Find ${vals[index]}`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${answers[0]}$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${answers[1]}$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${answers[2]}$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${answers[3]}$`);

      question.addAnswer(answer1, index === 0);
      question.addAnswer(answer2, index === 1);
      question.addAnswer(answer3, index === 2);
      question.addAnswer(answer4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialDifferentiation_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const k = MCRandom.randomInt(1, 5);
    const m = 2;
    const exp = new MCMaths.Exponential(1, "e", k, `\\frac{1}{x^${m}}`);
    const der1 = `${-m * k}x^{${-(m + 1)}}`;
    const der2 = `\\frac{${-m * k}}{x^{${m + 1}}}`;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `a) Sketch the graph of $y = e^{\\frac{${k}}{x^{${m}}}}$.`,
      ]);

      question.addHeading("solution", `a)`);

      const graph = new MCQuestion.Graph(5, -5, 10, 0, 1, 1);
      graph.plot(exp.functionString().replace(/x/g, `(1/(x**${m}))`), -6, 6);
      question.addGraph("solution", graph);

      question.addMultipleParagraphs("question", [
        `b) Find the gradient function of $y = e^{\\frac{${k}}{x^{${m}}}}$.`,
      ]);

      question.addHeading(
        "solution",
        `b) Find the gradient function by using the chain rule to differentiate $y = e^{\\frac{${k}}{x^{${m}}}}$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ y = e^{2x^{${-m}}}$`,
        `$\\Rightarrow \\frac{dy}{dx} = ${der1}e^{2x^{${-m}}}$`,
        `$\\Rightarrow \\frac{dy}{dx} = ${der2}e^{\\frac{${k}}{x^{${m}}}}$`,
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialDifferentiation_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const m = MCRandom.randomInt(1, 3) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(-3, 3);
    const x1 = MCRandom.randomInt(-2, 2);
    const exponential = new MCMaths.Exponential(1, "e", m, "x", c);
    const derivative = exponential.derivative();
    const derivative2 = new MCMaths.Exponential(m, "e", m, `(${x1})`, c);
    const power = new MCMaths.Polynomial([m, c]);
    const alpha = power.evaluate(x1);
    const exponential2 = new MCMaths.Exponential(m, "e", alpha, "");
    const exponential3 = new MCMaths.Exponential(1, "e", m, `(${x1})`, c);
    const exponential4 = new MCMaths.Exponential(1, "e", m, `(${x1})`, c);
    const exponential5 = new MCMaths.Exponential(1, "e", alpha, "");
    const grad = derivative.evaluate(x1);
    const y1 = exponential.evaluate(x1);
    const constant = 1 - m * x1;
    const p1 = new MCMaths.Polynomial([m, constant]);
    /* END VARIABLES AND CONSTANTS */
    if (alpha !== 1 && alpha !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Consider the graph of $\\ y = ${exponential}$.`,
        `a) Calculate the gradient of the curve when $\\ x = ${x1}$, giving your answer in the form $Ae^\\alpha$.`,
      ]);

      question.addHeading(
        "solution",
        `a) First differentiate $\\ y = ${exponential}$ to find the gradient function:`
      );

      question.addMultipleParagraphs("solution", [
        `$y = ${exponential}$`,
        `$\\Rightarrow \\frac{dy}{dx} = ${derivative}$`,
      ]);

      question.addHeading("solution", `Now evaluate this at $\\ x = ${x1}$:`);

      question.addMultipleParagraphs("solution", [
        `$\\frac{dy}{dx}|_{x = ${x1}} = ${derivative2}$`,
        `$\\ = ${exponential2}$`,
        `$\\therefore A = ${m}, \\alpha = ${alpha}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(m, 0, "a) $\\ A = $");
      group1.addInput(alpha, 0, "a) $\\ \\alpha = $");

      question.addMultipleParagraphs("question", [
        `b) Find the equation of the line tangent to the curve $\\ y = ${exponential}$ at $\\ x = ${x1}$, giving your answer in the form $y = ${exponential5}(mx + c)$.`,
      ]);

      question.addHeading(
        "solution",
        `b) From the previous part we know the gradient is $${exponential2}$ when $x = ${x1}$.`
      );

      question.addHeading(
        "solution",
        `We can also calculate the value of $y$ at $x = ${x1}$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ y(${x1}) = ${exponential3} = ${exponential5}$`,
      ]);

      question.addHeading(
        "solution",
        `Using the identity, $\\ y - y_1 = m(x - x_1)$, we can find the equation of the tangent line:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ y - ${exponential5} = ${exponential2}(x - ${x1})$`,
        `$\\Rightarrow y = ${exponential5}(${p1})$`,
      ]);

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(m, 0, "b) $\\ m = $");
      group2.addInput(constant, 0, "b) $\\ c = $");

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialDifferentiation_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(-5, 5);
    const b = MCRandom.randomInt(-25, 25);
    const x1 = MCRandom.randomInt(-2, 2);
    const p1 = new MCMaths.Polynomial([1, a, b]);
    const p2 = new MCMaths.Polynomial([1, a, b], `(${x1})`);
    const dp1 = p1.derivative();
    const dp2 = p2.derivative();
    const exp1 = new MCMaths.Exponential(1, "e", 1, `${p1}`);
    const der1 = `(${dp1})${exp1}`;
    const exp2 = new MCMaths.Exponential(1, "e", 1, `${p2}`);
    const der2 = `(${dp2})${exp2}`;
    const A = dp1.evaluate(x1);
    const alpha = p1.evaluate(x1);
    const exp3 = new MCMaths.Exponential(A, "e", alpha, "");
    const exp4 = new MCMaths.Exponential(1, "e", alpha, "");
    const constant = 1 - A * x1;
    const p3 = new MCMaths.Polynomial([A, constant]);
    /* END VARIABLES AND CONSTANTS */
    if (A !== 0 && A !== 1 && alpha !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Consider the graph of $\\ y = ${exp1}$.`,
        `a) Calculate the gradient of the curve when $\\ x = ${x1}$, giving your answer in the form $Ae^\\alpha$.`,
      ]);

      question.addHeading(
        "solution",
        `a) First differentiate $\\ y = ${exp1}$ to find the gradient function:`
      );

      question.addMultipleParagraphs("solution", [
        `$y = ${exp1}$`,
        `$\\Rightarrow \\frac{dy}{dx} = ${der1}$`,
      ]);

      question.addHeading("solution", `Now evaluate this at $\\ x = ${x1}$:`);

      question.addMultipleParagraphs("solution", [
        `$\\frac{dy}{dx}|_{x = ${x1}} = ${der2}$`,
        `$\\ = ${exp3}$`,
        `$\\therefore A = ${A}, \\alpha = ${alpha}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(A, 0, "a) $\\ A = $");
      group1.addInput(alpha, 0, "a) $\\ \\alpha = $");

      question.addMultipleParagraphs("question", [
        `b) Find the equation of the line tangent to the curve $\\ y = ${exp1}$ at $\\ x = ${x1}$, giving your answer in the form $y = ${exp4}(mx + c)$.`,
      ]);

      question.addHeading(
        "solution",
        `b) From the previous part we know the gradient is $${exp3}$ when $x = ${x1}$.`
      );

      question.addHeading(
        "solution",
        `We can also calculate the value of $y$ at $x = ${x1}$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ y(${x1}) = ${exp2} = ${exp4}$`,
      ]);

      question.addHeading(
        "solution",
        `Using the identity, $\\ y - y_1 = m(x - x_1)$, we can find the equation of the tangent line:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ y - ${exp4} = ${exp3}(x - ${x1})$`,
        `$\\Rightarrow y = ${exp4}(${p3})$`,
      ]);

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(A, 0, "b) $\\ m = $");
      group2.addInput(constant, 0, "b) $\\ c = $");

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialDifferentiation_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const m1 = MCRandom.randomInt(1, 5);
    const m2 = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const c1 = MCRandom.randomInt(-5, 5);
    const c2 = MCRandom.randomInt(-5, 5);
    const C = MCRandom.randomInt(1, 5);
    const exp1 = new MCMaths.Exponential(1, "e", m1, "x", c1);
    const exp2 = new MCMaths.Exponential(1, "e", m2, "x", c2);
    const exp3 = new MCMaths.Exponential(1, "e", m1 + m2, "x", c1 + c2);
    const p1 = new MCMaths.Polynomial([1, C], `${exp2}`);
    /* END VARIABLES AND CONSTANTS */
    if (m1 + m2 !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Differentiate the following with respect to $x$:`,
        `$\\ ${exp1} (${p1})$`,
        `Giving your answer in the form, $\\ ${exp1} (A${exp2} + B)$`,
      ]);

      question.addHeading(
        "solution",
        `First expand the brackets to simplify the differentiation:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ ${exp1} (${p1})$`,
        `$\\rightarrow ${exp3} + ${C}${exp1}$`,
      ]);

      question.addHeading("solution", `Now differentiate with respect to $x$:`);

      question.addMultipleParagraphs("solution", [
        `$\\rightarrow ${m1 + m2} ${exp3} + ${C * m1} ${exp1}$`,
        `$\\rightarrow ${exp1} (${m1 + m2}${exp2} + ${C * m1})$`,
        `$\\therefore A = ${m1 + m2}, B = ${C * m1}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(m1 + m2, 0, "$\\ A = $");
      group1.addInput(C * m1, 0, "$\\ B = $");

      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialDifferentiation_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 5);
    const m = 1 / a;
    const exp1 = new MCMaths.Exponential(1, "e", m, "x");
    const der1 = exp1.derivative();
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Consider the graph of $\\ y = ${exp1}$.`,
        `Show that the tangent to the curve at the point $(${a}, e)$ passes through the origin.`,
      ]);

      question.addHeading(
        "solution",
        "To show that the tangent passes through the origin we need to find the equation of the tangent line, and subsequently show that this line passes through the origin."
      );

      question.addHeading(
        "solution",
        `We need to find the gradient of the curve at this point so we differentiate with respect to $x$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ y = ${exp1} \\ \\Rightarrow \\ \\frac{dy}{dx} = ${der1}$`,
        `$\\therefore$ gradient $ = \\frac{dy}{dx}|_{x = ${a}} = \\frac{1}{${a}}e$`,
      ]);

      question.addHeading(
        "solution",
        `Using the identity, $\\ y - y_1 = m(x - x_1)$, and that we know the line passes through $(${a}, e)$, we can find the equation of the tangent line:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ y - e = \\frac{1}{${a}}e (x - ${a})$`,
        `$\\Rightarrow y = \\frac{1}{${a}}x e$, which clearly passes through the origin.`,
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialEquations_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 3);
    const alpha = MCRandom.randomInt(1, 5);
    const beta = MCRandom.randomInt(1, 5);
    const b = -a * (alpha + beta);
    const c = a * alpha * beta;
    const p1 = new MCMaths.Polynomial([a, b, c], "u");
    const p2 = new MCMaths.Polynomial([a, b, c], `\\cdot ${a}^x`);
    const p3 = new MCMaths.Polynomial([b, c], `\\cdot ${a}^x`);
    const p4 = new MCMaths.Polynomial([1, -alpha], "u");
    const p5 = new MCMaths.Polynomial([1, -beta], "u");
    const p6 = new MCMaths.Polynomial([1, b / a, c / a], "u");
    const l1 = new MCMaths.Logarithm(1, a, 1, `${alpha}`);
    const l2 = new MCMaths.Logarithm(1, a, 1, `${beta}`);

    /* END VARIABLES AND CONSTANTS */
    if (alpha !== beta) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Consider, $\\ f(x) = ${a}^{2x+1} + ${p3}$`,
        `a) Given that $u = ${a}^x$, show that $f(x)$ can be written in the form`,
        `$\\   g(u) = Au^2 + Bu + C$`,
        `stating the values of $A, B$ and $C$.`,
      ]);

      question.addHeading(
        "solution",
        `a) Using laws of indices we can rewrite $${a}^{2x+1}$ in terms of $u$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ ${a}^{2x+1} = ${a}^{2x} \\cdot ${a}^1$`,
        `$\\ = ${a} \\cdot (${a}^x)^2$`,
        `$\\ = ${a}u^2$`,
      ]);

      question.addHeading(
        "solution",
        `Substituting these expressions into $f(x)$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ f(x) = ${a}^{2x+1} + ${p3}$`,
        `$\\ = ${p1} = g(u)$`,
        `$\\therefore A = ${a}, B = ${b}, C = ${c}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput(a, 0, "a) $\\ A = $");
      group1.addInput(b, 0, "a) $\\ B = $");
      group1.addInput(c, 0, "a) $\\ C = $");

      question.addParagraph(
        "question",
        `b) Solve $f(x) = 0$, give answers as integers if possible, elsewhere write in the form $\\log_${a} q$.`
      );

      question.addHeading("solution", `b) First solve $g(u) = 0$`);

      question.addMultipleParagraphs("solution", [
        `$\\ ${p1} = 0$`,
        `$\\therefore ${p6} = 0$`,
        `$\\therefore (${p4})(${p5}) = 0$`,
        `$\\therefore u = ${alpha}, ${beta}$`,
      ]);

      question.addHeading("solution", `When $u = ${alpha}$:`);

      question.addMultipleParagraphs("solution", [
        `$\\ ${a}^x = ${alpha}$`,
        `$\\ x = ${l1}$`,
      ]);

      const group2 = new MCQuestion.InputGroup(2);

      if (l1.evaluate(alpha) === Math.round(l1.evaluate(alpha))) {
        question.addParagraph("solution", `$\\ = ${l1.evaluate(alpha)}$`);
        group2.addInput(l1.evaluate(alpha), 0, "b) $\\ x = $");
      } else {
        group2.addInput(alpha, 0, "b) $\\ q = $");
      }

      question.addHeading("solution", `When $u = ${beta}$:`);

      question.addMultipleParagraphs("solution", [
        `$\\ ${a}^x = ${beta}$`,
        `$\\ x = ${l2}$`,
      ]);

      if (l2.evaluate(beta) === Math.round(l2.evaluate(beta))) {
        question.addParagraph("solution", `$\\ = ${l2.evaluate(beta)}$`);
        group2.addInput(l2.evaluate(beta), 0, "b) $\\ x = $");
      } else {
        group2.addInput(beta, 0, "b) $\\ q = $");
      }

      question.addInputGroup(group1);
      question.addInputGroup(group2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialEquations_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 5);
    const alphaPower = MCRandom.randomInt(0, 3);
    const betaPower = MCRandom.randomInt(0, 3);
    const alpha = a ** alphaPower;
    const beta = a ** betaPower;
    const b = -1 * (alpha + beta);
    const c = alpha * beta;
    const variables = ["u", "v", "w", "r"];
    const variable = MCRandom.shuffle(variables)[0];
    const p1 = new MCMaths.Polynomial([1, b, c], variable);
    const p2 = new MCMaths.Polynomial([b, c], `\\cdot ${a}^x`);
    const p3 = new MCMaths.Polynomial([1, -alpha], variable);
    const p4 = new MCMaths.Polynomial([1, -beta], variable);
    /* END VARIABLES AND CONSTANTS */
    if (alpha !== beta && a !== 4 && c < 80) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `a) Given that $\\ ${variable} = ${a}^x$, express $${
          a ** 2
        }^x$ in terms of $${variable}$.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `HEADING a) $\\ ${a ** 2}^x = (${a}^2)^x$`,
        `$\\ = ${a}^{2x}$`,
        `$\\ = (${a}^x)^2$`,
        `$\\ = ${variable}^2$`,
      ]);

      question.addMultipleParagraphs("question", [
        `b) Hence, solve $\\ ${a ** 2}^x + ${p2} = 0$ for $x$.`,
      ]);

      question.addHeading(
        "solution",
        `b) Substituting $${variable}$ in for $x$, and solving for $${variable}$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ ${p1} = 0$`,
        `$\\ (${p3})(${p4}) = 0$`,
        `$\\therefore ${variable} = ${alpha}, ${beta}$`,
      ]);

      question.addHeading("solution", `When $${variable} = ${alpha}$:`);

      question.addMultipleParagraphs("solution", [
        `$\\ ${a}^x = ${alpha}$`,
        `$\\therefore x = ${alphaPower}$`,
      ]);

      question.addHeading("solution", `When $${variable} = ${beta}$:`);

      question.addMultipleParagraphs("solution", [
        `$\\ ${a}^x = ${beta}$`,
        `$\\therefore x = ${betaPower}$`,
      ]);

      question.requiresSelfMarking();

      const group = new MCQuestion.InputGroup(2);
      group.addInput([alphaPower, betaPower], 0, "b) $\\ x = $");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialEquations_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 5);
    const alphaPower = MCRandom.randomInt(0, 3);
    const betaPower = MCRandom.randomInt(0, 3);
    const gammaPower = MCRandom.randomInt(0, 3);
    const alpha = a ** alphaPower;
    const beta = a ** betaPower;
    const gamma = a ** gammaPower;
    const variables = ["u", "v", "w", "r"];
    const variable = MCRandom.shuffle(variables)[0];
    const p1 = MCMaths.Polynomial.generateFromRoots(
      [alpha, beta, gamma],
      variable
    );
    const b = p1.getCoefficients()[1];
    const c = p1.getCoefficients()[2];
    const d = p1.getCoefficients()[3];
    const p2 = new MCMaths.Polynomial([1, -alpha], variable);
    const p3 = new MCMaths.Polynomial([1, -beta], variable);
    const p4 = new MCMaths.Polynomial([1, -gamma], variable);
    const p5 = new MCMaths.Polynomial([c, d], `\\cdot ${a}^x`);
    /* END VARIABLES AND CONSTANTS */
    if (
      alpha !== beta &&
      alpha !== gamma &&
      gamma !== beta &&
      a !== 4 &&
      d < 100
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `a) Given that $\\ ${variable} = ${a}^x$, express $${
          a ** 2
        }^x$ in terms of $${variable}$.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) $\\ ${a ** 2}^x = (${a}^2)^x$`,
        `$\\ = ${a}^{2x}$`,
        `$\\ = (${a}^x)^2$`,
        `$\\ = ${variable}^2$`,
      ]);

      question.addMultipleParagraphs("question", [
        `b) Similarly, express $${a ** 3}^x$ in terms of $${variable}$.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `b) $\\ ${a ** 3}^x = (${a}^3)^x$`,
        `$\\ = ${a}^{3x}$`,
        `$\\ = (${a}^x)^3$`,
        `$\\ = ${variable}^3$`,
      ]);

      question.addMultipleParagraphs("question", [
        `c) Hence, solve`,
        `$\\ ${a ** 3}^x ${b} \\cdot ${a ** 2}^x + ${p5} = 0$`,
        "for $x$.",
      ]);

      question.addHeading(
        "solution",
        `c) Substituting $${variable}$ in for $x$, and solving for $${variable}$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ ${p1} = 0$`,
        `$\\ (${p2})(${p3})(${p4}) = 0$`,
        `$\\therefore ${variable} = ${alpha}, ${beta}, ${gamma}$`,
      ]);

      question.addHeading("solution", `When $${variable} = ${alpha}$:`);

      question.addMultipleParagraphs("solution", [
        `$\\ ${a}^x = ${alpha}$`,
        `$\\therefore x = ${alphaPower}$`,
      ]);

      question.addHeading("solution", `When $${variable} = ${beta}$:`);

      question.addMultipleParagraphs("solution", [
        `$\\ ${a}^x = ${beta}$`,
        `$\\therefore x = ${betaPower}$`,
      ]);

      question.addHeading("solution", `When $${variable} = ${gamma}$:`);

      question.addMultipleParagraphs("solution", [
        `$\\ ${a}^x = ${gamma}$`,
        `$\\therefore x = ${gammaPower}$`,
      ]);

      const group = new MCQuestion.InputGroup(3);
      group.addInput([alphaPower, betaPower, gammaPower], 0, "c) $\\ x = $");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialEquations_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 8);
    const b = MCRandom.randomInt(1, 8);
    const p1 = MCMaths.Polynomial.generateFromRoots([a, b], "u");
    const coefficients = p1.getCoefficients();
    const p2 = new MCMaths.Polynomial([1, -a], "u");
    const p3 = new MCMaths.Polynomial([1, -b], "u");
    const A = coefficients[1];
    const B = coefficients[2];
    /* END VARIABLES AND CONSTANTS */
    if (a !== b) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        "Find the exact solutions to the equation,",
        `$\\ e^x + ${B}e^{-x} = ${-A}$`,
        "Give your answers in the form $\\ln a$.",
      ]);

      question.addHeading("solution", "First multiply both sides by $e^x$:");

      question.addMultipleParagraphs("solution", [
        `$\\ e^x + ${B}e^{-x} = ${-A}$`,
        `$\\rightarrow e^{2x} + ${B} = ${-A}e^x$`,
        `$\\rightarrow e^{2x} - ${-A}e^x + ${B} = 0$`,
      ]);

      question.addHeading(
        "solution",
        "Use the substitution $u = e^x$ and factorise:"
      );

      question.addMultipleParagraphs("solution", [
        `$\\ ${p1} = 0$`,
        `$\\Rightarrow (${p2})(${p3}) = 0$`,
        `$\\Rightarrow u = ${a}, ${b}$`,
        `$\\Rightarrow e^x = ${a}, ${b}$`,
        `$\\therefore x = \\ln ${a}, \\ln ${b}$`,
      ]);

      const group = new MCQuestion.InputGroup(2);
      group.addInput([a, b], 0, "$a = $");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialEquations_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(-5, 5);
    const b = MCRandom.randomInt(2, 8);
    const c = MCRandom.randomInt(2, 6);
    const d = MCRandom.randomInt(2, 8);
    const p = new MCMaths.Polynomial([c, -a]);
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        "Find the exact solutions to the equation,",
        `$\\ ${d}^x e^{${p}} = ${b}$`,
        "Give your answer in the form $\\frac{a + \\ln b}{c + \\ln d}$.",
      ]);

      question.addHeading(
        "solution",
        "First take the natural logarithm of each side:"
      );

      question.addMultipleParagraphs("solution", [
        `$\\ ${d}^x e^{${p}} = ${b}$`,
        `$\\rightarrow \\ln (${d}^x e^{${p}}) = \\ln ${b}$`,
      ]);

      question.addHeading(
        "solution",
        "Now use laws of logarithms to rearrange and solve for $x$:"
      );

      question.addMultipleParagraphs("solution", [
        `$\\ln (${d}^x e^{${p}}) = \\ln ${b}$`,
        `$\\Rightarrow \\ln (${d}^x) + \\ln (e^{${p}}) = \\ln ${b}$`,
        `$\\Rightarrow x\\ln ${d} + ${p} = \\ln ${b}$`,
        `$\\Rightarrow x\\ln ${d} + ${c}x = ${a} + \\ln ${b}$`,
        `$\\Rightarrow x (\\ln ${d} + ${c}) = ${a} + \\ln ${b}$`,
        `$\\Rightarrow x = \\frac{${a} + \\ln ${b}}{${c} + \\ln ${d}}$`,
      ]);

      const group = new MCQuestion.InputGroup(4);
      group.addInput(a, 0, "$a = $");
      group.addInput(b, 0, "$b = $");
      group.addInput(c, 0, "$c = $");
      group.addInput(d, 0, "$d = $");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialEquations_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 3);
    const base = a ** 2;
    const b = MCRandom.randomInt(2, 4);
    const p = MCMaths.Polynomial.generateFromRoots([a, a ** b], "y");
    const coefficients = p.getCoefficients();
    const A = coefficients[1];
    const B = coefficients[2];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `a) Sketch the graph of $\\ y = ${base}^x$, stating the point of intersection with the $y-$axis.`,
      ]);

      question.addHeading("solution", `a)`);

      const graph = new MCQuestion.Graph(2, -1, base, 0, 1, 1);

      graph.plot(`${base} ** x`, -3, 3);

      question.addGraph("solution", graph);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(1, 0, "a) $\\ y-$intercept: $\\ x = $");

      question.addMultipleParagraphs("question", [
        `b) Solve the equation, $\\ ${base}^{2x} ${A}(${base}^x) + ${B} = 0$`,
      ]);

      question.addHeading(
        "solution",
        `b) Use the substitution $y = ${base}^x$ and factorise:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ ${base}^{2x} ${A}(${base}^x) + ${B} = 0$`,
        `$\\Rightarrow ${p} = 0$`,
        `$\\Rightarrow (y - ${a})(y - ${a ** b}) = 0$`,
        `$\\Rightarrow y = ${a}, ${a ** b}$`,
        `$\\Rightarrow ${base}^x = ${a}, ${a ** b}$`,
        MCMaths.cleaner(`$\\therefore x = \\frac{1}{2}, ${b / 2}$`),
      ]);

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput([0.5, b / 2], 0, "b) $\\ x = $");

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialFunctions_Exam_Q1() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 5);
    const k = MCRandom.randomInt(1, 4);
    const n = 3;
    const exp = new MCMaths.Exponential(k, a);
    const y1 = exp.evaluate(1);
    const yn = exp.evaluate(n);

    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `Given that the graph of $y = ka^x$, passes through the points $(1, ${y1})$ and $(${n}, ${yn})$, find the missing values $k$ and $a$.`
      );

      question.addHeading(
        "solution",
        `Substituting $(1, ${y1})$ and $(${n}, ${yn})$ into $y = ka^x$ yields:`
      );

      question.addMultipleParagraphs("solution", [
        `$(1)$ $${y1} = ka$`,
        `$(2)$ $${yn} = ka^${n}$`,
      ]);

      question.addHeading("solution", `Dividing $(2)$ by $(1)$ gives:`);

      question.addMultipleParagraphs("solution", [
        `$${yn / y1} = a^2$`,
        `$\\therefore a = ${a}$`,
      ]);

      question.addHeading(
        "solution",
        `Now substitute $a = ${a}$ back into $(1)$ to find k:`
      );

      question.addMultipleParagraphs("solution", [
        `$${a}k = ${y1}$`,
        `$\\therefore k = ${k}$`,
      ]);

      const group = new MCQuestion.InputGroup(2);
      group.addInput(a, 0, "$a = $");
      group.addInput(k, 0, "$k = $");

      question.addInputGroup(group);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialFunctions_Exam_Q2() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const A = MCRandom.randomInt(2, 6);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Given that the graph of $y = Ae^x$, crosses the $y-$axis when $y = ${A}$,`,
        `Find the value of $A$.`,
      ]);

      question.addHeading(
        "solution",
        `Substituting $x = 0$ and $y = ${A}$ into $y = Ae^x$ gives:`
      );

      question.addMultipleParagraphs("solution", [
        `$${A} = Ae^0$`,
        `As $e^0 = 1$,`,
        `$\\therefore A = ${A}$`,
      ]);

      const group = new MCQuestion.InputGroup(1);
      group.addInput(A, 0, "$A = $");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialFunctions_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 4);
    const m = MCRandom.randomInt(-5, 5);
    const c = MCRandom.randomInt(1, 3);
    const exponential1 = new MCMaths.Exponential(1, a, m, "x", c);
    const power1 = new MCMaths.Polynomial([m, c]);
    const power2 = new MCMaths.Polynomial([m, 0]);
    const A = a ** c;
    const finalExpression = MCMaths.cleaner(
      `$\\ = ${A} \\cdot ${a}^{${power2}}$`,
      true
    );
    /* END VARIABLES AND CONSTANTS */
    if (m !== 0 && c !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `a) Rearrange the expression, $\\ ${a}^{${power1}}$, to the form, $\\ A \\cdot ${a}^{mx}$.`,
      ]);

      question.addHeading(
        "solution",
        "a) Using laws of indices we can rearrange:"
      );

      question.addMultipleParagraphs("solution", [
        `$\\ ${a}^{${power1}} = ${a}^{${power2}} \\cdot ${a}^{${c}}$`,
        `${finalExpression}`,
        `$\\therefore A = ${A}, m = ${m}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(A, 0, "a) $\\ A = $");
      group1.addInput(m, 0, "a) $\\ m = $");

      question.addParagraph(
        "question",
        `b) Hence write down where the graph of $y = ${a}^{${power1}}$ crosses the $y-$axis.`
      );

      question.addHeading("solution", "b) From a) we know:");

      question.addParagraph("solution", `$\\ y $${finalExpression}`);

      question.addHeading(
        "solution",
        `And when $x = 0$, $${a}^{${power2}} = 1$, so`
      );

      question.addHeading(
        "solution",
        `$\\ y = ${A}$ is when it crosses the $y-$axis`
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(A, 0, "b) $\\ y = $");

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialFunctions_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 4);
    const m = MCRandom.randomInt(-5, 5);
    const c = MCRandom.randomInt(1, 3);
    const exponential1 = new MCMaths.Exponential(1, a, m, "x", c);
    const power1 = new MCMaths.Polynomial([m, c]);
    const power2 = new MCMaths.Polynomial([m, 0]);
    const A = a ** c;
    const finalExpression = MCMaths.cleaner(
      `$\\ = ${A} \\cdot ${a}^{${power2}}$`,
      true
    );
    const upperLim = Math.round(exponential1.evaluate(4));
    /* END VARIABLES AND CONSTANTS */
    if (m !== 0 && c !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `a) Rearrange the expression, $\\ ${a}^{${power1}}$, to the form, $\\ A \\cdot ${a}^{mx}$.`,
      ]);

      question.addHeading(
        "solution",
        "a) Using laws of indices we can rearrange:"
      );

      question.addMultipleParagraphs("solution", [
        `$\\ ${a}^{${power1}} = ${a}^{${power2}} \\cdot ${a}^{${c}}$`,
        `${finalExpression}`,
        `$\\therefore A = ${A}, m = ${m}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(A, 0, "a) $\\ A = $");
      group1.addInput(m, 0, "a) $\\ m = $");

      question.addMultipleParagraphs("question", [
        `b) Hence sketch the graph of $\\ y = ${a}^{${power1}}$, giving the point of intersection with the $y-$axis.`,
      ]);

      question.addHeading(
        "solution",
        `b) From part a) we can see that the graph crosses the $y-$axis at $y = ${A}$, and has a positive exponential shape:`
      );

      const graph1 = new MCQuestion.Graph(4, -4, upperLim, 0, 1, 1);
      graph1.plot(exponential1.functionString(), -5, 5);
      question.addGraph("solution", graph1);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(A, 0, "b) $\\ y-$intercept$ = $");
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialGrowthAndDecay_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const animal = MCRandom.chooseElement([
      "blackbirds",
      "rabbits",
      "hares",
      "deer",
      "pheasants",
      "grouse",
    ]);
    const C = MCRandom.randomInt(20, 40, 10);
    const A = C - MCRandom.randomInt(10, 30, 10);
    const k = MCRandom.chooseElement([20, 25, 40, 50]);
    const year1 = MCRandom.chooseElement([2025, 2030, 2040]);
    const t1 = year1 - 2000;
    const exp = new MCMaths.Exponential(A, "e", 1 / k);
    let bAns = exp.evaluate(t1);
    bAns += C;
    bAns = Math.round(bAns * 10) / 10;
    let maxY = exp.evaluate(100);
    maxY += C;
    maxY = Math.round(maxY / 100) * 100;
    /* END VARIABLES AND CONSTANTS */
    if (A > 0 && bAns < 100) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `The population of ${animal} in the UK can be modelled by the formula`,
        `$\\ P = ${C} + ${A}e^{\\frac{t}{${k}}}$`,
        "Where $P$ is the population in thousands, and $t$ is the time in years after the year $2000$.",
        `a) State the population of ${animal} in the year $2000$.`,
      ]);

      question.addHeading(
        "solution",
        `a) The population in $2000$ is when $t = 0$`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ P(0) = ${C} + ${A}e^{\\frac{(0)}{${k}}}$`,
        `$\\ = ${C + A}$`,
      ]);

      question.addHeading(
        "solution",
        `So the population in $2000$ was $${C + A},000$.`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput((C + A) * 1000, 0, "a) Population:");

      question.addMultipleParagraphs("question", [
        `b) Use the model to predict the population in the year $${year1}$.`,
        `Give your answer to $3$ significant figures`,
      ]);

      question.addHeading(
        "solution",
        `b) In the year $${year1}$, $t = ${t1}$,`
      );

      question.addMultipleParagraphs("solution", [
        `So, $\\ P(${t1}) = ${C} + ${A}e^{\\frac{${t1}}{${k}}}$`,
        `$\\ = ${bAns} \\ (3 s.f)$`,
      ]);

      question.addHeading(
        "solution",
        `So the predicted population in the year $${year1}$ is $${
          bAns * 1000
        }$.`
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(bAns * 1000, 0, "b) Population:");

      question.addMultipleParagraphs("question", [
        "c) Sketch a graph of $P$ against $t$ for the years $2000$ to $2100$.",
      ]);

      question.addHeading("solution", "c)");

      const graph = new MCQuestion.Graph(100, 0, maxY, 0, 25, maxY / 10);

      graph.plot(`${C}+${exp.functionString()}`, 0, 100);
      question.addGraph("solution", graph);

      question.requiresSelfMarking();
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialGrowthAndDecay_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const vehicle = MCRandom.chooseElement(["car", "van", "motorbike"]);
    const initial = MCRandom.randomInt(15, 30) * 1000;
    const rate = MCRandom.randomInt(4, 10);
    const exp = new MCMaths.Exponential(initial, "e", -1 / rate, "t");
    const exp2 = new MCMaths.Exponential(initial, "e", -1 / rate, "(5)");
    let bAns = exp.evaluate(5);
    bAns = Math.round(bAns / 100) * 100;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `The value of a ${vehicle} can be modelled by the formula`,
        `$\\ V = ${exp}$`,
        `Where $V$ is the value of the car in $£$s, and $t$ is the age in years of the ${vehicle} from new.`,
        `a) State the value of the ${vehicle} when it was new.`,
      ]);

      question.addHeading("solution", `a) The value when new, is when $t = 0$`);

      question.addMultipleParagraphs("solution", [
        `$\\ V(0) = ${initial}e^0$`,
        `$\\ = ${initial}$`,
      ]);

      question.addHeading(
        "solution",
        `So the value when new is $£${initial}$.`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(initial, 0, "a) $\\ £$");

      question.addMultipleParagraphs("question", [
        `b) Find the value of the ${vehicle}, to the nearest $£100$, when its $5$ years old.`,
      ]);

      question.addHeading(
        "solution",
        `b) The value when $5$ years old is when $t = 5$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ V(5) = ${exp2}$`,
        `$\\ = £${bAns}$, (nearest $£100$)`,
      ]);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(bAns, 0, "b) $\\ £$");

      question.addMultipleParagraphs("question", [
        `c) Sketch a graph of $V$ against $t$ for the ${vehicle} from new until $10$ years old.`,
      ]);

      question.addHeading("solution", "c)");

      const graph = new MCQuestion.Graph(10, 0, initial, 0, 1, initial / 10);

      graph.plot(exp.functionString(), 0, 10);
      question.addGraph("solution", graph);

      question.requiresSelfMarking();
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialGrowthAndDecay_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const name = MCMisc.getName();
    const initial = MCRandom.randomInt(2, 6) * 1000;
    const interest = MCRandom.randomInt(1, 6) / 2;
    const k = interest / 100;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `${name.name} puts $£${initial}$ into a new bank account.`,
        `The bank that ${name.name} has chosen gives ${name.himher} $${interest}$% interest every year.`,
        `a) Show that,`,
        `$\\ \\frac{dx}{dt} = kx$`,
        `Stating the value of $k$, where $x$ is the amount in ${name.name}'s bank account in $£$s, and $t$ is the time in years since ${name.name} opened their bank account.`,
      ]);

      question.addHeading(
        "solution",
        `a) As ${name.name} recieves $${interest}$% interest, $x$ increases by $${interest}$% every year.`
      );

      question.addHeading(
        "solution",
        `So, the rate of increase of $x$, $\\frac{dx}{dt}$ is proportional to $x$ such that:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ \\frac{dx}{dt} = ${k}x$`,
        `$\\therefore k = ${k}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(k, 0, "a) $\\ k = $");

      question.addMultipleParagraphs("question", [
        `b) Show that the amount in ${name.name}'s account can be modelled by:`,
        `$\\ x = Ae^{0.015t}$`,
        `Stating the value of $A$.`,
      ]);

      question.addHeading(
        "solution",
        `b) Rearranging the expression from before:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ \\frac{dx}{dt} = ${k}x$`,
        `$\\Rightarrow \\frac{1}{x} \\frac{dx}{dt} = ${k}$`,
        `$\\Rightarrow \\int \\frac{1}{x} dx = ${k} \\int dt$`,
        `$\\Rightarrow \\ln x = ${k}t + C$`,
        `$\\Rightarrow x = e^{${k}t + C}$`,
        `$\\Rightarrow x = e^{${k}t} \\cdot e^C$`,
      ]);

      question.addHeading("solution", `Since $e^C$ is just a constant:`);

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow x = Ae^{${k}t}$`,
      ]);

      question.addHeading(
        "solution",
        `Now substituting the initial conditions, $x(0) = ${initial}$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ x(0) = Ae^{${k}(0)}$`,
        `$\\Rightarrow ${initial} = A$`,
        `$\\therefore x = ${initial}e^{${k}t}$`,
      ]);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(initial, 0, "b) $\\ A = $");

      question.requiresSelfMarking();
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialGrowthAndDecay_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const k = MCRandom.randomInt(-15, -12) / 100;
    const A = MCRandom.randomInt(4, 6) / 10;
    const exp = new MCMaths.Exponential(A, "e", k, "t");
    const expString = MCMaths.cleaner(exp.toString(), false, false);
    const exp2 = new MCMaths.Exponential(A, "e", k, "(0)");
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `The population of the UK that have owned a smartphone since $2010$, can be represented by the model,`,
        `$\\ p = 1 - ${expString}$`,
        `Where $p$ is the proportion of population that owns a smartphone, and $t$ is the time in years since $2010$.`,
        `a) State what proportion of the population owned a smartphone in $2010$.`,
      ]);

      question.addHeading("solution", `a) In $2010$, $t = 0$:`);

      question.addMultipleParagraphs("solution", [
        `$\\ p(0) = 1 - ${exp2}$`,
        `$\\ = 1 - ${A}$`,
        `$\\ = ${1 - A}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(1 - A, 0, "a) $\\ $");

      question.addMultipleParagraphs("question", [
        `b) Show that, $\\ \\frac{dp}{dt} = k (1 - p)$, where $k$ is a constant to be found.`,
      ]);

      question.addHeading(
        "solution",
        `b) Differentiating $p$ with respect to $t$ yields:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ p = 1 - ${expString}$`,
        MCMaths.cleaner(`$\\Rightarrow \\frac{dp}{dt} = ${-k}(${exp})$`),
      ]);

      question.addHeading(
        "solution",
        `Since $p = 1 - ${expString} \\Rightarrow ${exp} = 1 - p$`
      );

      question.addMultipleParagraphs("solution", [
        MCMaths.cleaner(`$\\Rightarrow \\frac{dp}{dt} = ${-k}(1 - p)$`),
        `$\\therefore k = ${-k}$`,
      ]);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(-k, 0, "b) $\\ k = $");

      question.addMultipleParagraphs("question", [
        `c) What is the long term prediction of the proportion of the population that own a smartphone?`,
      ]);

      question.addHeading(
        "solution",
        `c) If we investigate as $t$ becomes large, then $${exp} \\rightarrow 0$.`
      );

      question.addHeading("solution", `Hence $p \\rightarrow 1$`);

      question.addHeading(
        "solution",
        `This means the long term expectation is that eventually the full population will own a smartphone.`
      );

      question.addMultipleParagraphs("question", [
        `d) Sketch a graph of $p$ against $t$ from $2010$ to $2025$.`,
      ]);

      question.addHeading("solution", `d)`);

      const graph = new MCQuestion.Graph(15, 0, 1, 0, 3, 0.2);
      graph.plot(`1 - ${exp.functionString()}`, 0, 20);
      graph.plot(`1`, 0, 20);
      question.addGraph("solution", graph);

      question.requiresSelfMarking();
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialGrowthAndDecay_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const drink = MCRandom.chooseElement(["tea", "coffee", "hot chocolate"]);
    const end = MCRandom.randomInt(18, 22);
    const A = MCRandom.randomInt(50, 65, 5);
    const k = -1 / MCRandom.randomInt(5, 10);
    const exp = new MCMaths.Exponential(A, "e", k, "t");
    const expString = exp.toString();
    const exp2 = new MCMaths.Exponential(A, "e", k, "(0)");
    const T1 = MCRandom.randomInt(40, 55);
    let t1 = new MCMaths.Logarithm(1 / k, "e");
    t1 = t1.evaluate((T1 - end) / A);
    t1 = Math.round(t1);
    const exp3 = new MCMaths.Exponential(1, "e", k, `t_{${T1}}`);
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `The temperature, $T^{\\circ}C$, of a cup of ${drink} can be modelled by`,
        `$\\ T = ${exp} + ${end}$`,
        `Where $t$ is the time in minutes since measurements started.`,
        `a) State the starting temperature of the cup of ${drink}.`,
      ]);

      question.addHeading(
        "solution",
        `a) Starting temperature is when $t = 0$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ T(0) =  ${exp2} + ${end}$`,
        `$\\ = ${A} + ${end}$`,
        `$\\ = ${A + end}^{\\circ}C$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(A + end, 0, "a) $\\ ^{\\circ}C $");

      question.addMultipleParagraphs("question", [
        `b) Find the time, to the nearest minute, that it takes for the ${drink} to reach $${T1}^{\\circ}C$.`,
      ]);

      question.addHeading(
        "solution",
        `b) To find $t_{${T1}}$, substitute $T = ${T1}$ and rearrange to find $t_{${T1}}$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ ${T1} = ${expString.replace("t", `t_{${T1}}`)} + ${end}$`,
        `$\\Rightarrow ${exp3} = \\frac{${T1 - end}}{${A}}$`,
        MCMaths.cleaner(
          `$\\Rightarrow  ${k}t_{${T1}} = \\ln ${(T1 - end) / A}$`
        ),
        MCMaths.cleaner(
          `$\\Rightarrow  t_{${T1}} = ${1 / k} \\ln ${(T1 - end) / A}$`
        ),
        `$\\therefore t_{${T1}} = ${t1} mins$`,
      ]);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(t1, 0, `b) $\\ t_{${T1}} = $`);

      question.addMultipleParagraphs("question", [
        `c) Sketch a graph of $T$ against $t$, and state the minimum temperature of the cup of ${drink}.`,
      ]);

      question.addHeading("solution", `c)`);

      const graph = new MCQuestion.Graph(30, 0, A + end + 10, 0, 5, 5);
      graph.plot(`${exp.functionString()} + ${end}`, 0, 35);
      graph.plot(`${end} `, 0, 35);
      question.addGraph("solution", graph);

      question.addHeading(
        "solution",
        `From the graph you can see the minimum temperature is $${end}^{\\circ}C$.`
      );

      const group3 = new MCQuestion.InputGroup(1);
      group3.addInput(end, 0, `c) $\\ ^{\\circ}C $`);

      question.requiresSelfMarking();
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_ExponentialGrowthAndDecay_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const name = MCMisc.getName();
    const initial = MCRandom.randomInt(8, 15) * 100;
    const k1 = MCRandom.randomInt(5, 10);
    const exp1 = new MCMaths.Exponential(initial, "e", -1 / k1, "t");
    const exp1a = new MCMaths.Exponential(initial, "e", -1 / k1, "(1)");
    const exp1b = new MCMaths.Exponential(initial, "e", -1 / k1, "(10)");
    const min = MCRandom.randomInt(1, 3) * 100;
    const k2 = k1 - MCRandom.randomInt(1, 3);
    const exp2 = new MCMaths.Exponential(initial - min, "e", -1 / k2, "t");
    const exp2a = new MCMaths.Exponential(initial - min, "e", -1 / k2, "(1)");
    const exp2b = new MCMaths.Exponential(initial - min, "e", -1 / k2, "(10)");
    let P1a = exp1.evaluate(1);
    P1a = Math.round(P1a);
    let P1b = exp2.evaluate(1) + min;
    P1b = Math.round(P1b);
    const diff1 = Math.abs(P1a - P1b);
    let P10a = exp1.evaluate(10);
    P10a = Math.round(P10a);
    let P10b = exp2.evaluate(10) + min;
    P10b = Math.round(P10b);
    const diff2 = Math.abs(P10a - P10b);
    /* END VARIABLES AND CONSTANTS */
    if (diff1 <= 25 && diff2 > 100) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `${name.name} buys a new computer for $£${initial}$. ${name.HeShe} wants to model the depreciation of its price, $£P$, over time, $t$ years.`,
        `${name.HeShe} considers two models:`,
        `Model 1: $\\ P = ${exp1}$`,
        `Model 2: $\\ P = ${exp2} + ${min}$`,
        `a) Using both models, predict the value of the computer, to the nearest $£$, after $1$ year. Compare the results between the two models.`,
      ]);

      question.addHeading("solution", `a) Model 1:`);

      question.addMultipleParagraphs("solution", [
        `$\\ P(1) =  ${exp1a}$`,
        `$\\ = ${P1a}$`,
      ]);

      question.addHeading(
        "solution",
        `$\\therefore$ The price of the computer after $1$ year is predicted to be $£${P1a}$, according to this model.`
      );

      question.addHeading("solution", `Model 2:`);

      question.addMultipleParagraphs("solution", [
        `$\\ P(1) =  ${exp2a} + ${min}$`,
        `$\\ = ${P1b}$`,
      ]);

      question.addHeading(
        "solution",
        `$\\therefore$ The price of the computer after $1$ year is predicted to be $£${P1b}$, according to this model.`
      );

      question.addHeading(
        "solution",
        `Hence after $1$ year both models predict a very similar depreciation in price.`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(P1a, 0, "a) Model 1: $\\ £$");
      group1.addInput(P1b, 0, "a) Model 2: $\\ £$");

      question.addMultipleParagraphs("question", [
        `b) Using both models, predict the value of the computer, to the nearest $£$, after $10$ years. Compare the results between the two models.`,
      ]);

      question.addHeading("solution", `b) Model 1:`);

      question.addMultipleParagraphs("solution", [
        `$\\ P(10) =  ${exp1b}$`,
        `$\\ = ${P10a}$`,
      ]);

      question.addHeading(
        "solution",
        `$\\therefore$ The price of the computer after $10$ year is predicted to be $£${P10a}$, according to this model.`
      );

      question.addHeading("solution", `Model 2:`);

      question.addMultipleParagraphs("solution", [
        `$\\ P(10) =  ${exp2b} + ${min}$`,
        `$\\ = ${P10b}$`,
      ]);

      question.addHeading(
        "solution",
        `$\\therefore$ The price of the computer after $10$ year is predicted to be $£${P10b}$, according to this model.`
      );

      question.addHeading(
        "solution",
        `Hence after $10$ years, the first model predicts a larger depreciation in price than the second.`
      );

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(P10a, 0, "b) Model 1: $\\ £$");
      group2.addInput(P10b, 0, "b) Model 2: $\\ £$");

      question.addMultipleParagraphs("question", [
        `c) On the same set of axes, sketch the graphs of $P$ against $t$ for both models.`,
      ]);

      question.addHeading("solution", `c) Model 1:`);

      const graph1 = new MCQuestion.Graph(20, 0, initial + 100, 0, 2, 100);

      graph1.plot(exp1.functionString(), 0, 25);
      question.addGraph("solution", graph1);

      question.addHeading("solution", `Model 2:`);

      const graph2 = new MCQuestion.Graph(20, 0, initial + 100, 0, 2, 100);
      graph2.plot(exp1.functionString(), 0, 25);
      graph2.plot(exp2.functionString(), 0, 25);
      question.addGraph("solution", graph2);

      question.addMultipleParagraphs("question", [
        `d) Explain the significance of the $${min}$ in model 2, and suggest why this would make the model more suitable than the first.`,
      ]);

      question.addHeading(
        "solution",
        `d) The $${min}$ in the second model means that the price of the computer never drops below $£${min}$, whereas in the first model, the price will eventually reach $£0$, making the model less suitable as you would expect the computer always have some value.`
      );

      question.requiresSelfMarking();
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_LogarithmLaws_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 25);
    const m = MCRandom.randomInt(2, 3);
    const base = MCRandom.randomInt(2, 5);
    const log = new MCMaths.Logarithm(1, base, a, `x^${m}`);
    const b = MCRandom.randomInt(2, 5);
    const working = log.solveWorking(b);
    const ansPowM = log.solve(b);
    let answer = ansPowM ** (1 / m);
    answer = Math.round(100 * answer) / 100;
    let extra = "(2\\ d.p)";
    if (Math.round(answer * 10) === answer * 10) {
      extra = "";
    }
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Solve for $x$:`,
        `$\\ log_${base} ${a} + ${m} log_${base} x = ${b}$`,
        "Giving your answer to $2\\ d.p$ where appropriate.",
      ]);

      question.addHeading("solution", `Using laws of logarithms rearrange:`);

      question.addMultipleParagraphs("solution", [
        `$\\ log_${base} ${a} + log_${base} x^${m} = ${b}$`,
        `$\\Rightarrow ${log.toString()} = ${b}$`,
        `$\\Rightarrow$ ${working[2]}`,
        `${working[3].replace("\\therefore", "\\Rightarrow")}`,
        `$\\therefore x = ${answer} \\ ${extra}$`,
      ]);

      const group = new MCQuestion.InputGroup(1);
      group.addInput(answer, 0.01, "$\\ x = $");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_LogarithmLaws_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 25);
    const base = MCRandom.randomInt(2, 5);
    const m = MCRandom.randomInt(2, 3);
    const log = new MCMaths.Logarithm(
      1,
      base,
      1,
      MCMaths.cleaner(`\\frac{${a}}{x^${m}}`)
    );
    const b = MCRandom.randomInt(2, 5);
    const working = log.solveWorking(b);
    let ansCubed = log.solve(b);
    ansCubed = a / ansCubed;
    let answer = ansCubed ** (1 / m);
    answer = Math.round(100 * answer) / 100;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Solve for $x$:`,
        `$\\ log_${base} ${a} - ${m} log_${base} x = ${b}$`,
        "Giving your answer to $2\\ d.p$.",
      ]);

      question.addHeading("solution", `Using laws of logarithms rearrange:`);

      question.addMultipleParagraphs("solution", [
        `$\\ log_${base} ${a} - log_${base} x^${m} = ${b}$`,
        `$\\Rightarrow ${log.toString()} = ${b}$`,
        `$\\Rightarrow$ ${working[2]}`,
        `${working[3].replace("\\therefore", "\\Rightarrow")}`,
        `$\\Rightarrow x^${m} = ${ansCubed}$`,
        `$\\therefore x = ${answer} \\ (2\\ d.p)$`,
      ]);

      const group = new MCQuestion.InputGroup(1);
      group.addInput(answer, 0.01, "$\\ x = $");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_LogarithmLaws_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);
    const log1 = new MCMaths.Logarithm(1, "e", 1, "x", -a);
    const log2 = new MCMaths.Logarithm(1, "e", 1, "x", -b);
    const p1 = new MCMaths.Polynomial([1, -a]);
    const p2 = new MCMaths.Polynomial([1, -b]);
    const p3 = p1.multiply(p2);
    const coefficients = p3.getCoefficients();
    const A = coefficients[1];
    const B = coefficients[2] - 1;
    const p4 = new MCMaths.Polynomial([1, A, B]);
    const working = p4.completeSquare();
    const p5 = new MCMaths.Polynomial([1, A / 2]);
    const answers = p4.roots();
    /* END VARIABLES AND CONSTANTS */
    if (
      A / 2 === Math.round(A / 2) &&
      a !== -b &&
      A ** 2 - 4 * B >= 0 &&
      answers[0] === Math.round(answers[0]) &&
      answers[1] === Math.round(answers[1])
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `a) Show that,`,
        `$\\ ${log1} + ${log2} = 0$`,
        "Can be expressed in the form,",
        "$\\ x^2 + Ax + B = 0$",
      ]);

      question.addHeading(
        "solution",
        `a) Using the multiplication law of logarithms:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ ${log1} + ${log2} = 0$`,
        `$\\Rightarrow ln [(${p1})(${p2})] = 0$`,
        `$\\Rightarrow (${p1})(${p2}) = e^0$`,
        `$\\Rightarrow ${p3} = 1$`,
        `$\\Rightarrow ${p4} = 0$`,
        `$\\therefore A = ${A}, B = ${B}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(A, 0, "a) $\\ A = $");
      group1.addInput(B, 0, "a) $\\ B = $");

      question.addMultipleParagraphs("question", [
        "b) Hence solve,",
        `$\\ ${log1} + ${log2} = 0$`,
      ]);

      question.addHeading("solution", `b) From before we know,`);

      question.addMultipleParagraphs("solution", [
        `$\\ ${log1} + ${log2} = 0 \\ \\Rightarrow ${p4} = 0$`,
      ]);

      question.addMultipleParagraphs("solution", [
        `${working[0].replace("y", "0")}`,
        `$\\Rightarrow $${working[2].replace("y", "0")}`,
        `$\\Rightarrow (${p5})^2 = ${-1 * (B - (A / 2) ** 2)}$`,
        `$\\Rightarrow x = ${-A / 2} \\pm \\sqrt{${-1 * (B - (A / 2) ** 2)}}$`,
        `$\\therefore x = ${answers}$`,
      ]);

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(answers, 0, "b) $\\ x = $");

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_LogarithmLaws_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const variables = ["x", "u", "v", "w", "r", "s", "q", "t", "y", "z"];
    const variable = variables[MCRandom.randomInt(0, 9)];
    const base = MCRandom.randomInt(2, 3);
    const m = MCRandom.randomInt(2, 5);
    const c1 = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const c2 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const log1 = new MCMaths.Logarithm(1, base, m, variable, c1);
    const log2 = new MCMaths.Logarithm(2, base, 1, variable, c2);
    const log1String = log1.toString().replace("log", "\\log");
    const log2String = log2.toString().replace("log", "\\log");
    const p1 = new MCMaths.Polynomial([1, c2], variable);
    const p2 = p1.pow(2);
    const coefficients1 = p2.getCoefficients();
    for (let j = 0; j <= 2; j += 1) {
      coefficients1[j] *= base;
    }
    const p3 = new MCMaths.Polynomial([m, c1], variable);
    const p3String = MCMaths.cleaner(p3.toString());
    const p4 = p2.subtract(p3);
    const coefficients2 = p4.getCoefficients();
    const coefficients3 = [0, 0, 0];
    for (let j = 0; j <= 2; j += 1) {
      coefficients3[j] = coefficients2[j] / base;
    }
    const p5 = new MCMaths.Polynomial(coefficients3, variable);
    const bAnswers = p5.roots();
    const p6 = new MCMaths.Polynomial([1, -bAnswers[0]], variable);
    const p7 = new MCMaths.Polynomial([1, -bAnswers[1]], variable);
    /* END VARIABLES AND CONSTANTS */
    if (
      bAnswers[0] === Math.round(bAnswers[0]) &&
      bAnswers[1] === Math.round(bAnswers[1]) &&
      bAnswers[0] !== 0 &&
      bAnswers[1] !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `a) Given that, `,
        `$\\ ${log1String} = 1 + ${log2String}$`,
        `Show that, $\\ ${p5} = 0$`,
      ]);

      question.addHeading(
        "solution",
        `a) Using laws of logarithms to rearrange:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ ${log1String} = 1 + ${log2String}$`,
        `$\\Rightarrow ${log1String} - ${log2String} = 1$`,
        `$\\Rightarrow ${log1String} - \\log_${base} (${p1})^2 = 1$`,
        `$\\Rightarrow \\log_${base} (\\frac{${p3String}}{(${p1})^2}) = 1$`,
        `$\\Rightarrow \\frac{${p3String}}{(${p1})^2} = ${base}$`,
        `$\\Rightarrow ${p3String} = ${base}(${p1})^2$`,
        `$\\Rightarrow ${p3String} = ${p2}$`,
        `$\\Rightarrow ${p4} = 0$`,
        `$\\therefore ${p5} = 0$`,
      ]);

      question.addMultipleParagraphs("question", [
        `b) Hence solve, $\\ ${log1String} = 1 + ${log2String}$`,
      ]);

      question.addHeading("solution", `b) From the previous part we know:`);

      question.addMultipleParagraphs("solution", [
        `$\\ ${log1String} = 1 + ${log2String} \\ \\Rightarrow \\ ${p5} = 0$`,
        `$\\Rightarrow (${p6})(${p7}) = 0$`,
        `$\\therefore ${variable} = ${bAnswers}$`,
      ]);

      let group2 = new MCQuestion.InputGroup(2);

      if (m * bAnswers[0] + c1 <= 0 || bAnswers[0] + c2 <= 0) {
        if (bAnswers[0] + c2 <= 0) {
          question.addMultipleParagraphs("solution", [
            `However if $${variable} = ${bAnswers[0]}$, then $\\ ${p1} = ${
              bAnswers[0] + c2
            } \\leq 0$.`,
          ]);
        } else {
          question.addMultipleParagraphs("solution", [
            `However if $${variable} = ${bAnswers[0]}$, then $\\ ${p3} = ${
              m * bAnswers[0] + c1
            } \\leq 0$.`,
          ]);
        }
        question.addMultipleParagraphs("solution", [
          `So, $\\ ${variable} \\neq ${bAnswers[0]}$`,
        ]);

        group2 = new MCQuestion.InputGroup(1);
        group2.addInput(bAnswers[1], 0, `b) $\\ ${variable} = $`);
      } else if (m * bAnswers[1] + c1 <= 0 || bAnswers[1] + c2 <= 0) {
        if (bAnswers[1] + c2 <= 0) {
          question.addMultipleParagraphs("solution", [
            `However if $${variable} = ${bAnswers[1]}$, then $\\ ${p1} = ${
              bAnswers[1] + c2
            } \\leq 0$.`,
          ]);
        } else {
          question.addMultipleParagraphs("solution", [
            `However if $${variable} = ${bAnswers[1]}$, then $\\ ${p3} = ${
              m * bAnswers[1] + c1
            } \\leq 0$.`,
          ]);
        }
        question.addMultipleParagraphs("solution", [
          `So, $\\ ${variable} \\neq ${bAnswers[1]}$`,
        ]);

        group2 = new MCQuestion.InputGroup(1);
        group2.addInput(bAnswers[0], 0, `b) $\\ ${variable} = $`);
      } else {
        group2 = new MCQuestion.InputGroup(2);
        group2.addInput(bAnswers, 0, `b) $\\ ${variable} = $`);
      }

      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_LogarithmLaws_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 25);
    const b = MCRandom.randomInt(1, 20);
    let ans = b / a;
    ans = Math.round(ans * 100) / 100;
    let extra = "";
    if (Math.round(ans * 10) !== ans * 10) {
      extra = "(2\\ d.p)";
    }
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Solve for $x$:`,
        `$\\ \\log ${a} + \\log x = \\log ${b}$`,
        `Giving your answer to $2\\ d.p$ where appropriate.`,
      ]);

      question.addHeading("solution", `Using laws of logarithms rearrange:`);

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow \\log ${a}x = \\log ${b}$`,
        `$\\Rightarrow ${a}x = ${b}$`,
        `$\\therefore x = ${ans} \\ ${extra}$`,
      ]);

      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans, 0, "$\\ x = $");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_LogarithmLaws_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(3, 50);
    const b = MCRandom.randomInt(2, 5);
    const alpha = MCRandom.randomInt(2, 4);
    const polynomial = new MCMaths.Polynomial([1, -a, alpha ** b]);
    const solutions = polynomial.roots();
    const x = solutions[0];
    const y = solutions[1];
    const variables = MCRandom.chooseElement([
      ["x", "y"],
      ["a", "b"],
      ["s", "t"],
      ["u", "v"],
      ["p", "q"],
    ]);
    const xS = variables[0];
    const yS = variables[1];
    /* END VARIABLES AND CONSTANTS */
    if (y > 0 && x > y && x === Math.round(x) && y === Math.round(y)) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Given that $${xS}$ and $${yS}$ are positive integers such that $${xS} \\gt ${yS}$, solve the set of equations`,
        `(1) $\\qquad ${xS} + ${yS} = ${a}$`,
        `(2) $\\qquad \\log_${alpha} ${xS} + \\log_${alpha} ${yS} = ${b}$`,
      ]);

      question.addHeading(
        "solution",
        `For the second equation, use multiplication law for logs and rearrange:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\log_${alpha} ${xS} + \\log_${alpha} ${yS} = ${b}$`,
        `$\\Rightarrow \\log_${alpha} ${xS}${yS} = ${b}$`,
        `$\\Rightarrow ${xS}${yS} = ${alpha ** b}$`,
      ]);

      question.addHeading(
        "solution",
        `Now we have a new set of equations we can solve by inspection:`
      );

      question.addMultipleParagraphs("solution", [
        `(1) $\\qquad ${xS} + ${yS} = ${a}$`,
        `(2) $\\qquad ${xS}${yS} = ${alpha ** b}$`,
        `$\\therefore ${xS} = ${x}, \\ ${yS} = ${y}\\ $  or  $\\ ${xS} = ${y}, \\ ${yS} = ${x}$`,
      ]);

      question.addHeading("solution", `Since $${xS} > ${yS}$,`);

      question.addMultipleParagraphs("solution", [
        `$\\therefore ${xS} = ${x}, \\ ${yS} = ${y}$`,
      ]);

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(x, 0, `$\\ ${xS} = $`);
      group2.addInput(y, 0, `$\\ ${yS} = $`);

      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_LogarithmLaws_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        "By writing $\\ \\log_a x = m, \\ \\log_a y = n$, and using laws of indices, prove that",
        "$\\ \\log_a x - \\log_a y = \\log_a (\\frac{x}{y})$",
      ]);

      question.addHeading("solution", `First take`);

      question.addMultipleParagraphs("solution", [
        `$\\ \\log_a x = m, \\ \\log_a y = n$`,
      ]);

      question.addHeading("solution", `Raise both to the power of $a$:`);

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow x = a^m, \\ y = a^n$`,
      ]);

      question.addHeading("solution", `Consider`);

      question.addMultipleParagraphs("solution", [
        `$\\ \\frac{x}{y} = \\frac{a^m}{a^n}$`,
        `$\\ = a^{m - n}$`,
      ]);

      question.addHeading("solution", `Now taking the $\\log$ of booth sides:`);

      question.addMultipleParagraphs("solution", [
        `$\\ \\log_a (\\frac{x}{y}) = \\log_a (a^{m - n})$`,
        `$\\ = m - n$`,
        `$\\therefore \\log_a (\\frac{x}{y}) = \\log_a x - \\log_a y$`,
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_LogarithmLaws_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const alpha = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const beta = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const gamma = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const a = MCRandom.randomInt(1, 4);
    const base = MCRandom.randomInt(2, 3);
    const c = base ** a;
    const p1 = new MCMaths.Polynomial([1, -alpha]);
    const p2 = new MCMaths.Polynomial([1, -beta]);
    const p3 = new MCMaths.Polynomial([1, -gamma]);
    const poly2 = p2.multiply(p3);
    const coefficients = poly2.getCoefficients();
    const b = coefficients[2] + c;
    const poly3 = new MCMaths.Polynomial([1, coefficients[1], b]);
    const poly1 = p1.multiply(poly3);
    const workingA = poly1.evaluateWorking(alpha);
    /* END VARIABLES AND CONSTANTS */
    if (beta !== gamma) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Consider, $\\ f(x) = ${poly1}$`,
        `a) Show that $\\ f(${alpha}) = 0$.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) ${workingA[0]}`,
        `${workingA[1]}$`,
        `${workingA[2]}`,
      ]);

      question.addMultipleParagraphs("question", [
        `b) Show that $\\ \\log_${base} (${poly1}) = ${a} + \\log_${base} (${p1})$`,
        `Can be simplified to the form $x^2 + Ax + B = 0$.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) ${workingA[0]}`,
        `${workingA[1]}$`,
        `${workingA[2]}`,
      ]);

      question.addHeading(
        "solution",
        `b) Using the factor theorem and the result from (a), we can factorise:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ f(x) = ${poly1}$`,
        `$\\ = (${p1})(${poly3})$`,
      ]);

      question.addHeading("solution", `Now we can re-write the equation as:`);

      question.addMultipleParagraphs("solution", [
        `$\\ \\log_${base} (${poly1}) = ${a} + \\log_${base} (${p1})$`,
        `$\\rightarrow \\log_${base} [(${p1})(${poly3})] = ${a} + \\log_${base} (${p1})$`,
      ]);

      question.addHeading("solution", `Using log laws to rearrange:`);

      question.addMultipleParagraphs("solution", [
        `$\\rightarrow \\log_${base} [(${p1})(${poly3})] - \\log_${base} (${p1}) = ${a}$`,
        `$\\rightarrow \\log_${base} \\frac{(${p1})(${poly3})}{${p1}} = ${a}$`,
        `$\\rightarrow \\log_${base} (${poly3}) = ${a}$`,
        `$\\rightarrow ${poly3} = ${base}^{${a}}$`,
        `$\\rightarrow ${poly2} = 0$`,
        `$\\therefore A = ${coefficients[1]}, B = ${b}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(coefficients[1], 0, `b) $\\ A = $`);
      group1.addInput(b, 0, `b) $\\ B = $`);

      question.addMultipleParagraphs("question", [
        `c) Hence solve, $\\ \\log_${base} (${poly1}) = ${a} + \\log_${base} (${p1})$`,
      ]);

      question.addHeading(
        "solution",
        `Use the result from before, then factorise:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ \\log_${base} (${poly1}) = ${a} + \\log_${base} (${p1})$`,
        `$\\Rightarrow ${poly2} = 0$`,
        `$\\Rightarrow (${p2})(${p3}) = 0$`,
        `$\\therefore x = ${beta}, ${gamma}$`,
      ]);

      const group2 = new MCQuestion.InputGroup(2);

      if (beta - alpha <= 0 || poly1.evaluate(beta) <= 0) {
        if (beta - alpha <= 0) {
          question.addMultipleParagraphs("solution", [
            `However if $x = ${beta}$, then $\\ ${p1} = ${
              beta - alpha
            } \\leq 0$.`,
          ]);
        } else {
          question.addMultipleParagraphs("solution", [
            `However if $x = ${beta}$, then $\\ ${poly3} = ${poly1.evaluate(
              beta
            )} \\leq 0$.`,
          ]);
        }
        question.addMultipleParagraphs("solution", [
          `So, $\\ x \\neq ${beta}$`,
        ]);
      }
      if (gamma - alpha <= 0 || poly3.evaluate(gamma) <= 0) {
        if (gamma - alpha <= 0) {
          question.addMultipleParagraphs("solution", [
            `However if $x = ${gamma}$, then $\\ ${p1} = ${
              gamma - alpha
            } \\leq 0$.`,
          ]);
        } else {
          question.addMultipleParagraphs("solution", [
            `However if $x = ${gamma}$, then $\\ ${poly3} = ${poly3.evaluate(
              gamma
            )} \\leq 0$.`,
          ]);
        }
        question.addMultipleParagraphs("solution", [
          `So, $\\ x \\neq ${gamma}$`,
        ]);
      }

      if (
        (beta - alpha <= 0 || poly1.evaluate(beta) <= 0) &&
        (gamma - alpha <= 0 || poly3.evaluate(gamma) <= 0)
      ) {
        question.addMultipleParagraphs("solution", [
          `So the equation has no solutions`,
        ]);
      }

      if (
        beta - alpha > 0 &&
        poly1.evaluate(beta) > 0 &&
        gamma - alpha > 0 &&
        poly1.evaluate(gamma) > 0
      ) {
        group2.addInput([beta, gamma], 0, "c) $\\ x = $");
      } else if (beta - alpha > 0 && poly1.evaluate(beta) > 0) {
        group2.addInput(beta, 0, "c) $\\ x = $");
      } else if (gamma - alpha > 0 && poly1.evaluate(gamma) > 0) {
        group2.addInput(gamma, 0, "c) $\\ x = $");
      }

      question.requiresSelfMarking();
      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_Logarithms_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(3, 10);
    const p1 = new MCMaths.Polynomial([1, 0, -(a ** 2)]);
    const p2 = new MCMaths.Polynomial([1, -a]);
    const p3 = new MCMaths.Polynomial([1, a]);
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `a) Find the roots to the equation:`,
        `$\\ ${p1} = 0$`,
      ]);

      question.addHeading(
        "solution",
        "a) The roots can be found by factorising:"
      );

      question.addMultipleParagraphs("solution", [
        `$\\ 0 = ${p1}$`,
        `$\\   = (${p2})(${p3})$`,
        `$\\therefore x = ${a}, ${-a}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput([a, -a], 0, "a) $\\ x = $");

      question.addMultipleParagraphs("question", [
        `b) Hence solve:`,
        `$\\ \\log_x ${a ** 2} = 2$`,
      ]);

      question.addHeading(
        "solution",
        "b) Rearrange using the relationship, $\\ \\log_a n = x \\Rightarrow a^x = n$:"
      );

      question.addMultipleParagraphs("solution", [
        `$\\ \\log_x ${a ** 2} = 2$`,
        `$\\Rightarrow x^2 = ${a ** 2}$`,
        `$\\Rightarrow ${p1} = 0$`,
        `$\\therefore x = ${a}, ${-a}$`,
      ]);

      question.addHeading(
        "solution",
        "However the base of a logarithm has to be positive, so"
      );

      question.addParagraph("solution", `$\\ x = ${a}$, is the only solution.`);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(a, 0, "b) $\\ x = $");

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_Logarithms_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(-10, 10);
    const b = MCRandom.randomInt(2, 10);
    const p1 = new MCMaths.Polynomial([1, -2 * a, a ** 2 - b ** 2]);
    const p2 = new MCMaths.Polynomial([1, -a]);
    /* END VARIABLES AND CONSTANTS */
    if (a - b > 0 && a + b > 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `a) By completing the square, find the roots to the equation:`,
        `$\\ ${p1} = 0$`,
      ]);

      question.addHeading(
        "solution",
        "a) The roots can be found by completing the square:"
      );

      question.addMultipleParagraphs("solution", [
        `$\\ ${p1} = 0$`,
        `$\\Rightarrow (${p2})^2 - ${b ** 2} = 0$`,
        `$\\Rightarrow (${p2})^2 = ${b ** 2}$`,
        `$\\Rightarrow ${p2} = \\pm ${b}$`,
        `$\\therefore x = ${a + b}, ${a - b}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput([a + b, a - b], 0, "a) $\\ x = $");

      question.addMultipleParagraphs("question", [
        `b) Hence solve:`,
        `$\\ \\log_{x - ${a}} ${b ** 2} = 2$`,
      ]);

      question.addHeading(
        "solution",
        "b) Rearrange using the relationship, $\\ \\log_a n = x \\Rightarrow a^x = n$:"
      );

      question.addMultipleParagraphs("solution", [
        `$\\ \\log_{x - ${a}} ${b ** 2} = 2$`,
        `$\\Rightarrow (x - ${a})^2 = ${b ** 2}$`,
        `$\\therefore x = ${a + b}, ${a - b}$, (deduced from part (a))`,
      ]);

      question.addHeading(
        "solution",
        `However the base of a logarithm has to be positive, so $\\ x - ${a} \\gt 0$`
      );

      question.addParagraph(
        "solution",
        `$\\ x = ${a + b}$, is the only solution.`
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(a + b, 0, "b) $\\ x = $");

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_Logarithms_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 3);
    const n = MCRandom.randomInt(2, 4);
    const b = a ** n;
    const m = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const c2 = MCRandom.randomInt(1, 3) * MCRandom.randomInt(-1, 1, 2);
    const p1 = new MCMaths.Polynomial([m, c], "u");
    const p2a = new MCMaths.Polynomial([1, c2], "u");
    const p2b = new MCMaths.Polynomial([1, -c2]);
    const p3 = new MCMaths.Polynomial([m, c - m * c2]);
    const p3b = new MCMaths.Polynomial([m, c - m * c2], `(${p2a})`);
    const u = (n - c) / m;
    let uString = `${u}`;
    uString = MCMaths.cleaner(uString);
    let extra1 = ``;
    if (Math.round(u) !== u) {
      extra1 = `\\ ( = ${Math.round(100 * u) / 100}, \\ 2 d.p)`;
    }
    const x = u + c2;
    let xString = `${x}`;
    xString = MCMaths.cleaner(xString);
    let extra2 = ``;
    if (Math.round(x) !== x) {
      extra2 = `\\ ( = ${Math.round(100 * x) / 100}, \\ 2 d.p)`;
    }

    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `a) Solve:`,
        `$\\ \\log_${a} ${b} = ${p1}$`,
        `Give your answer to $2 d.p$.`,
      ]);

      question.addHeading(
        "solution",
        "a) Rearrange using the relationship, $\\ \\log_a n = x \\Rightarrow a^x = n$:"
      );

      question.addMultipleParagraphs("solution", [
        `$\\ \\log_${a} ${b} = ${p1}$`,
        `$\\Rightarrow ${b} = ${a}^{${p1}}$`,
        `$\\ ${a}^${n} = ${a}^{${p1}}$`,
        `$\\Rightarrow ${p1} = ${n}$`,
        `$\\therefore u = ${uString} ${extra1}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(Math.round(100 * u) / 100, 0.01, "a) $\\ u = $");

      question.addMultipleParagraphs("question", [
        `b) Using the substitution $\\ x = ${p2a}$, or otherwise, find the $x-$coordinate of the point of intersection of the lines:`,
        `$\\ y = ${a}^{${p3}}$, and $\\ y = ${b}$`,
      ]);

      question.addHeading(
        "solution",
        "b) Equating the two equations and rearranging:"
      );

      question.addMultipleParagraphs("solution", [
        `$\\ ${b} = ${a}^{${p3}}$`,
        `$\\Rightarrow \\log_${a} ${b} = ${p3}$`,
      ]);

      question.addHeading(
        "solution",
        `Using the substitution $\\ x = ${p2a}$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ \\log_${a} ${b} = ${p3b}$`,
        `$\\Rightarrow \\log_${a} ${b} = ${p1}$`,
      ]);

      question.addHeading("solution", `From the previous part we know:`);

      question.addMultipleParagraphs("solution", [
        `$\\ u = ${uString}$`,
        `$\\therefore x = ${xString} ${extra2}$`,
      ]);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(Math.round(100 * x) / 100, 0.01, "b) $\\ x = $");

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_Logarithms_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 3);
    const m = MCRandom.randomInt(3, 5);
    const n = m + 1;
    const b = MCRandom.randomInt(a ** m + 1, a ** n - 1);
    const log = new MCMaths.Logarithm(1, a, 1, "x", 0);
    let bAnswer = log.evaluate(b);
    bAnswer = Math.round(100 * bAnswer) / 100;
    /* END VARIABLES AND CONSTANTS */
    if (a ** n <= 250) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `a) Without using a calculator, justify why $\\ ${m} \\lt \\log_${a} ${b} \\lt ${n}$.`,
      ]);

      question.addHeading(
        "solution",
        `a) As,$\\ ${a}^${m} = ${a ** m}$ and $\\ ${a}^${n} = ${a ** n}$,`
      );

      question.addMultipleParagraphs("solution", [
        `$\\therefore ${m} = \\log_${a} ${
          a ** m
        } \\lt \\log_${a} ${b} \\lt \\log_${a} ${a ** n} = ${n}$`,
      ]);

      question.addMultipleParagraphs("question", [
        `b) Using a calculator, find the actual value of $\\ \\log_${a} ${b}$ to $\\ 2 d.p$.`,
      ]);

      question.addHeading("solution", `b) $\\ \\log_${a} ${b} = ${bAnswer}$`);

      const group = new MCQuestion.InputGroup(1);
      group.addInput(bAnswer, 0, "b) ");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_Logarithms_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 5);
    const b = MCRandom.randomInt(2, 5);
    const p1 = new MCMaths.Polynomial([-1, b]);
    const p2 = new MCMaths.Polynomial([-1, b], "(0)");
    const log = new MCMaths.Logarithm(1, "e", -1, "x", b);
    const yInter = Math.round((log.evaluate(0) + a) * 100) / 100;
    const exp = new MCMaths.Exponential(1, "e", 1, "x");
    const xInter = Math.round((b - exp.evaluate(-a)) * 100) / 100;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Sketch the graph of $\\ y = ${a} + \\ln (${p1})$, stating the points of intersection with the axes.`,
      ]);

      question.addHeading(
        "solution",
        "First find the points of the intersection with the axes"
      );

      question.addHeading("solution", "$y-$intercept, (when $x = 0$):");

      question.addMultipleParagraphs("solution", [
        `$\\ y(0) = ${a} + \\ln (${p2})$`,
        `$\\ = ${a} + \\ln ${b} \\ (\\approx ${yInter})$`,
      ]);

      question.addHeading("solution", "$x-$intercept, (when $y = 0$):");

      question.addMultipleParagraphs("solution", [
        `$\\ ${a} + \\ln (${p1}) = 0$`,
        `$\\ \\ln (${p1}) = ${-a}$`,
        `$\\ ${p1} = e^{${-a}}$`,
        `$\\ x = ${b} - e^{${-a}} \\ (\\approx ${xInter})$`,
      ]);

      question.addHeading(
        "solution",
        `Now considering the graph, there will be an asymptote at $x = ${b}$ as $${p1} \\gt 0$ for the logarithm to exist.`
      );

      const graph = new MCQuestion.Graph(
        b + 1,
        -1,
        Math.round(yInter + 2),
        -1,
        1,
        1
      );

      graph.plot(`${a} + ${log.functionString()}`, -1, b - 0.01);
      graph.addParametric(`${b} `, `t`, -2, Math.round(yInter + 2));

      question.addGraph("solution", graph);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_ExponentialsAndLogarithms_Logarithms_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const poly = MCMaths.Polynomial.generateFromRoots([a, b], "u");
    const coefficients = poly.getCoefficients();
    const p1 = new MCMaths.Polynomial(
      [-coefficients[1], -coefficients[2]],
      "\\ln x"
    );
    const p2 = new MCMaths.Polynomial([1, -a], "u");
    const p3 = new MCMaths.Polynomial([1, -b], "u");
    /* END VARIABLES AND CONSTANTS */
    if (a !== b && coefficients[1] !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Solve`,
        `$\\ (\\ln x)^2 = ${p1}$`,
        `Give your answers in the form $e^p$.`,
      ]);

      question.addHeading(
        "solution",
        `Use the substitution $u = \\ln x$ and rearrange:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ (\\ln x)^2 = ${p1}$`,
        `$\\rightarrow u^2 = ${p1.toString().replace("\\ln x", "u")}$`,
        `$\\rightarrow ${poly} = 0$`,
      ]);

      question.addHeading("solution", `Now factorise to solve for $u$:`);

      question.addMultipleParagraphs("solution", [
        `$\\ ${poly} = 0$`,
        `$\\Rightarrow (${p2})(${p3}) = 0$`,
        `$\\therefore u = ${a}, ${b}$`,
      ]);

      question.addHeading("solution", `Substituting $\\ln x$ back in:`);

      question.addMultipleParagraphs("solution", [
        `$\\ u = ${a}, ${b}$`,
        `$\\Rightarrow \\ln x = ${a}, ${b}$`,
        `$\\Rightarrow x = e^{${a}}, e^{${b}}$`,
        `$\\therefore p = ${a}, ${b}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput([a, b], 0, "$p = $");

      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Forces_ConnectedParticles_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const coef = MCRandom.randomInt(3, 8);
    const mass1 = MCRandom.randomInt(3, 9);
    const mass2 = MCRandom.randomInt(3, 9);
    const f1 = coef * mass1;
    const f2 = coef * mass2;
    const a = MCRandom.randomInt(2, 5);
    const f3 = f1 + f2 + (mass1 + mass2) * a;
    const t = mass1 * a + f1;

    const im = new MCQuestion.Image("James/ConnectedBoxes.svg", "height", 15);
    im.addOverlay(`$${mass1}kg$`, 31, 42);
    im.addOverlay(`$${mass2}kg$`, 67, 42);
    im.addOverlay(`$T$`, 46, 52);
    im.addOverlay(`$T$`, 52, 32);
    im.addOverlay(`$${f2}N$`, 55, 82);
    im.addOverlay(`$${f1}N$`, 12, 82);
    im.addOverlay(`$${f3}N$`, 89, 42);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addImage("question", im);
      question.addMultipleParagraphs("question", [
        `Two boxes are connected by a light inextensible rope on a rough surface, as shown in the diagram above.`,
        `The first box has a mass of $${mass1}kg$, and the second of $${mass2}kg$.`,
        `A force is applied to the second box, and frictional forces of $${f1}N$ and $${f2}N$ act on each boxes as displayed above.`,
        `a) Find the acceleration of the boxes.`,
        `b) Find the tension $T$ in the rope.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) HEADING Consider the system as single particle:`,
        `Resultant force $= ${f3}-${f2}-${f1}$`,
        `$=${a * (mass1 + mass2)}N$`,
        `HEADING Using Newton'second law:`,
        `$${a * (mass1 + mass2)}=(${mass1}+${mass2})a$`,
        `$a=${a}ms^{-2}$`,
        `b) HEADING Considering Newton's second law on left box:`,
        `$T-${f1} = ${mass1}\\times ${a}$`,
        `$T = ${t}N$`,
      ]);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(a, 0, "a)");
      group2.addInput(t, 0, "b)");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Forces_ConnectedParticles_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const mass1 = MCRandom.randomInt(3, 9);
    const mass2 = MCRandom.randomInt(3, 9);
    const f1 = MCRandom.randomInt(2, 4) * mass1;
    const f2 = MCRandom.randomInt(2, 4) * mass2;
    const a = MCRandom.randomInt(2, 5);
    const f3 = f1 + f2 + (mass1 + mass2) * a;
    const t = mass1 * a + f1;

    const ansa = mass1 + mass2;
    const ansb = mass1 / mass2;

    const im = new MCQuestion.Image("James/ConnectedBoxes.svg", "height", 15);
    im.addOverlay(`$Akg$`, 31, 42);
    im.addOverlay(`$Bkg$`, 67, 42);
    im.addOverlay(`$${t}N$`, 46, 52);
    im.addOverlay(`$${t}N$`, 52, 32);
    im.addOverlay(`$${f2}N$`, 55, 82);
    im.addOverlay(`$${f1}N$`, 12, 82);
    im.addOverlay(`$${f3}N$`, 89, 42);
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(100 * ansb) === 100 * ansb &&
      ansb !== 1 &&
      f1 / mass1 !==
        f2 / mass2 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addImage("question", im);
      question.addMultipleParagraphs("question", [
        `Two boxes are connected by a light inextensible rope on a rough surface, as shown in the diagram above.`,
        `The first box has a mass of $Akg$, and the second of $Bkg$.`,
        `A force is applied to the second box, and frictional forces of $${f1}N$ and $${f2}N$ act on each boxes as displayed above.`,
        `After the force is applied, the boxes accelerate at a rate of $${a}ms^{-2}$.`,
        `a) Find the combined mass of the boxes.`,
        `b) Find the value of $\\displaystyle \\frac{A}{B}$.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) HEADING Consider the system as single particle:`,
        `Resultant force $= ${f3}-${f2}-${f1}$`,
        `$=${a * ansa}N$`,
        `HEADING Using Newton'second law:`,
        `$${a * ansa}=${a}(A+B)$`,
        `$A+B=${ansa}kg$`,
        `b) HEADING Considering Newton's second law on left box:`,
        `$${t}-${f1} = ${a}A$`,
        `$A = ${mass1}kg$`,
        `$B = ${ansa}-A$`,
        `$= ${mass2}kg$`,
        `$\\displaystyle\\therefore \\frac{A}{B}=${ansb}$`,
      ]);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(ansa, 0, "a)");
      group2.addInput(ansb, 0, "b)");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Forces_ConnectedParticles_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const im = new MCQuestion.Image("James/Pulley.svg", "height", 15);

    const m1 = MCRandom.randomInt(2, 9);
    const m2 = MCRandom.randomInt(1, 10) + m1;
    const h = MCRandom.randomInt(60, 190);
    let a = ((m2 - m1) * 9.8) / (m1 + m2);
    let v = Math.sqrt((2 * a * h) / 100);

    im.addOverlay(`$${m1}kg$`, 28, 80);
    im.addOverlay(`$${m2}kg$`, 72, 46);

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(v * 100) === v * 100 &&
      Math.round(a * 100) ===
        a * 100 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      v = Math.round(100 * v) / 100;
      a = Math.round(100 * a) / 100;
      const question = new MCQuestion.ExamQuestion();
      question.addImage("question", im);
      question.addMultipleParagraphs("question", [
        `Two particles of mass $${m1}kg$ and $${m2}kg$ are connected by a light, inextensible string that runs over a smooth pulley.`,
        `The particle of mass $${m2}kg$ is suspended $${h}cm$ above the ground.`,
        `a) Find the acceleration of the particle of mass $${m2}kg$ after it is released.`,
        `b) Find the speed of the particle as it hits the ground.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Writing Equations of Motion:`,
        `$T-${m1}g = ${m1}a$`,
        `$${m2}g-T = ${m2}a$`,
        `HEADING Summing Equations:`,
        `$(${m2}-${m1})g = ${m1 + m2}a$`,
        `$a = ${a}ms^{-2}$`,
        `b) HEADING Using $v^2=u^2+2as$:`,
        `$v^2 = 2\\times ${a} \\times ${h / 100}$`,
        `$v = ${v}ms^{-1}$`,
      ]);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(a, 0, "a)");
      group2.addInput(v, 0, "b)");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Forces_ConnectedParticles_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const m1 = MCRandom.randomInt(2, 8);
    const m2 = m1 + MCRandom.randomInt(1, 9);
    const f1 = (m1 * MCRandom.randomInt(20, 200)) / 10;
    const f2 = Math.round(m2 * 980) / 100;

    let a = (f2 - f1) / (m1 + m2);
    let t = m1 * a + f1;

    const time = MCRandom.randomInt(15, 50) / 10;
    let s = (a / 2) * time ** 2;

    const im = new MCQuestion.Image("James/ConnectedBoxes2.svg", "height", 20);
    im.addOverlay(`$${m1}kg$`, 32, 23);
    im.addOverlay(`$${m2}kg$`, 82, 68);
    im.addOverlay(`$T$`, 46, 13);
    im.addOverlay(`$T$`, 76, 51);
    im.addOverlay(`$${f1}N$`, 17, 38);
    im.addOverlay(`$${f2}N$`, 82, 88);
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(1000 * a) / 1000 === a &&
      Math.round(100 * t) / 100 === t &&
      Math.round(10 * s) / 10 === s &&
      s < 4 &&
      s > 0.5 &&
      a > 0 &&
      m2 < 10 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      a = Math.round(1000 * a) / 1000;
      t = Math.round(100 * t) / 100;
      s = Math.round(100 * s) / 100;

      const question = new MCQuestion.ExamQuestion();
      question.addImage("question", im);
      question.addMultipleParagraphs("question", [
        `A block of mass $${m1}kg$ rests on a rough surface, and is connected to another block of mass $${m2}kg$ by a light inexstensible rope passing over a smooth pulley.`,
        `Assuming there is no air resistance,`,
        `a) Find the tension $T$ in the rope.`,
        `b) Find the time taken for the boxes to travel a distance of $${s}m$.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) HEADING Forming Equations:`,
        `$T-${f1} = ${m1}a$`,
        `$${f2}-T = ${m2}a$`,
        `HEADING Summing Equations:`,
        `$${Math.round((f2 - f1) * 10000) / 10000}=${m2 + m1}a$`,
        `$a=${a}ms^{-2}$`,
        `HEADING Finding $T$:`,
        `$T-${f1} = ${m1}\\times ${a}$`,
        `$T = ${t}N$`,
        `b) HEADING Using $\\displaystyle s=ut+\\frac{1}{2}at^2$:`,
        `$\\displaystyle ${s} = \\frac{${a}}{2} t^2$`,
        `$t^2 = ${(2 * s) / a}$`,
        `$t = ${time}s$`,
      ]);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(t, 0, "a)");
      group2.addInput(time, 0, "b)");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Forces_ConnectedParticles_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const m1 = MCRandom.randomInt(2, 6);
    const m2 = m1 + MCRandom.randomInt(1, 3);
    const f1 = (m1 + m2 + MCRandom.randomInt(1, 3)) * 10;
    let a = f1 / (m1 + m2);

    let T = m1 * a + m1 * 9.8;

    const d = MCRandom.randomInt(100, 800) / 100;
    let v = Math.sqrt(2 * a * d);

    const im = new MCQuestion.Image("James/Platform.svg", "height", 20);
    im.addOverlay(`$FN$`, 50, 5);
    im.addOverlay(`$A$`, 50, 65);
    im.addOverlay(`$B$`, 50, 78);
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(a * 100) / 100 === a &&
      Math.round(v * 100) / 100 === v &&
      Math.round(T * 100) / 100 ===
        T /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      a = Math.round(a * 100) / 100;
      v = Math.round(v * 100) / 100;
      T = Math.round(T * 100) / 100;
      const question = new MCQuestion.ExamQuestion();
      question.addImage("question", im);
      question.addMultipleParagraphs("question", [
        `A light platform holds two boxes, and is being pulled upwards with a force of $FN$.`,
        `Box $A$ has a mass of $${m1}kg$, and box $B$ a mass of $${m2}kg$.`,
        `Given the platform is accelerating upwards at a rate of $${a} ms^{-2}$,`,
        `a) Find the tension $F$ in the rope.`,
        `b) Find the force exerted on box $B$ by box $A$.`,
        `c) Find the speed of the boxes after they have traveled $${d}m$ from rest.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Using Newton's Second Law:`,
        `$F = (${m1}+${m2})${a}$`,
        `$F = ${f1}N$`,
        `b) HEADING $T-${m1}g=${m1}a$`,
        `$T-${m1}g=${m1 * a}$`,
        `$T = ${T}N$`,
        `c) HEADING Using $v^2=u^2+2as$:`,
        `$v^2 = 2 \\times ${a} \\times ${d}$`,
        `$=${Math.round(10000000 * v ** 2) / 10000000}$`,
        `$v =${v}ms^{-1}$`,
      ]);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(f1, 0, "a)");
      group2.addInput(T, 0, "b)");
      group2.addInput(v, 0, "c)");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Forces_ForceVectors_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const d = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const e = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const f = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const linEq = new MCMaths.Intersections([a, b, c], [d, e, f], "a", "b");
    const v1 = new MCMaths.Vector(`${a}a`, `${d}a`);
    const v2 = new MCMaths.Vector(`${b}b`, `${e}b`);
    const v3 = new MCMaths.Vector(-c, -f);

    const a1 = linEq.solutions()[0];
    const a2 = linEq.solutions()[1];
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(a / b) !== a / b &&
      Math.round(b / a) !== b / a &&
      Math.round(c / d) !== c / d &&
      Math.round(d / c) !== d / c &&
      a !== b &&
      linEq.solutionsWorkingTest() === true &&
      a1 * a2 !== 0 &&
      Math.round(a1 * 10) === a1 * 10 &&
      Math.round(a2 * 10) ===
        a2 * 10 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Three forces, $${v1.toString()}N$, $${v2.toString()}N$, and $${v3.toString()}N$, act on a particle. Given the particle is in equilibrium, find $a$ and $b$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Forming Equations:`,
        `${linEq.toStringArray()[0]}`,
        `${linEq.toStringArray()[1]}`,
        `HEADING Solving Simultaneously:`,
      ]);
      question.addMultipleParagraphs("solution", linEq.solutionsWorking());
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(a1, 0, "$a=$");
      group2.addInput(a2, 0, "$b=$");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Forces_ForceVectors_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 15) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 15) * MCRandom.randomInt(-1, 1, 2);
    const d = MCRandom.randomInt(1, 15) * MCRandom.randomInt(-1, 1, 2);
    const e = MCRandom.randomInt(1, 15) * MCRandom.randomInt(-1, 1, 2);
    const v1 = new MCMaths.Vector(a, d);
    const v2 = new MCMaths.Vector(b, e);
    const v3 = new MCMaths.Vector("a", "b");
    const a1 = -a - b;
    const b1 = -d - e;
    const v4 = new MCMaths.Vector(a1, b1);

    function br(t) {
      if (t < 0) {
        return `(${t})`;
      }
      return t;
    }

    const ans2 = v2.add(v4).magnitude();
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(ans2) ===
      ans2 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Three forces $F_1=(${v1.toStringIJK()})N$, $F_2=(${v2.toStringIJK()})N$ and $F_3=(${v3.toStringIJK()})N$ act on a particle.`,
        `a) Given the particle is moving at a constant velocity, find $a$ and $b$.`,
        `After $F_1$ is removed, there is a resultant force on the particle.`,
        `b) Find the magnitude of this resultant force.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Forming Equations:`,
        MCMaths.cleaner(`$${a}+${b}+a=0$`),
        MCMaths.cleaner(`$${d}+${e}+b=0$`),
        `HEADING Solving:`,
        `$a=${a1}$`,
        `$b=${b1}$`,
        `b) HEADING Finding resultant:`,
        `$(${v2.toStringIJK()})+(${v4.toStringIJK()}) = ${v2
          .add(v4)
          .toStringIJK()}$`,
        `HEADING Finding Magnitude:`,
        `$\\sqrt{${br(v2.add(v4).getComponents()[0])}^2+${br(
          v2.add(v4).getComponents()[1]
        )}^2}$`,
        `$=${ans2}$`,
      ]);
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(a1, 0, "a) $\\ a=$");
      group2.addInput(b1, 0, "b) $\\ b=$");
      question.addInputGroup(group2);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans2, 0, "b)");
      question.addInputGroup(group);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Forces_ForceVectors_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const m = MCRandom.randomInt(2, 8);
    const u = new MCMaths.Vector(
      MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2)
    );
    const f1 = new MCMaths.Vector(
      MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2)
    );
    const f2 = new MCMaths.Vector(
      MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2)
    );
    const t = MCRandom.randomInt(5, 20);
    const a = f1.add(f2).multiply(1 / m);
    const dist = u
      .multiply(t)
      .add(a.multiply(0.5 * t * t))
      .magnitude();

    const a1 = (a.getComponents()[0] ** 2 + a.getComponents()[1] ** 2) / 4;
    const b1 = u.getComponents()[0] ** 2 + u.getComponents()[1] ** 2;
    const c1 = -(dist ** 2);

    const cubic =
      u.getComponents()[0] * a.getComponents()[0] +
      u.getComponents()[1] * a.getComponents()[1];

    const p = new MCMaths.Polynomial([a1, b1, c1]);

    let t2a;
    let t2b;
    if (a1 !== 0) {
      [t2a, t2b] = p.roots();
    }
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(dist) === dist &&
      dist > 10 &&
      dist < 100 &&
      a.magnitude() !== 0 &&
      cubic === 0 &&
      a1 !== 0 &&
      Math.round(t2a) === t2a &&
      Math.round(t2b) === t2b &&
      t2b < 0 &&
      Math.round(a.getComponents()[0] * 100) === a.getComponents()[0] * 100 &&
      Math.round(a.getComponents()[1] * 100) ===
        a.getComponents()[1] *
          100 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A particle of $${m}kg$ is moving at a constant velocity of $(${u.toStringIJK()})ms^{-1}$. Two forces, $F_1=(${f1.toStringIJK()})N$ and $F_2=(${f2.toStringIJK()})N$, begin to act on the particle.`,
        `a) Find the acceleration vector of the particle.`,
        `b) Find the time taken for the particle to travel $${dist}m$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding resultant force:`,
        `$(${f1.toStringIJK()})N+(${f2.toStringIJK()})N = (${f1
          .add(f2)
          .toStringIJK()})N$`,
        `HEADING Using Newton's Second Law:`,
        `$\\displaystyle\\mathbf{a}=\\frac{\\mathbf{f}}{m}$`,
        `$\\displaystyle =\\frac{1}{${m}} (${f1.add(f2).toStringIJK()})$`,
        `$\\displaystyle =\\left(${a.toStringIJK()}\\right)ms^{-2}$`,
        `b) HEADING Using $|\\mathbf{s}|=\\left|\\mathbf{u}t+\\frac{1}{2}\\mathbf{a}t^2\\right|$:`,
        `$\\displaystyle ${dist} = \\left|t(${u.toStringIJK()}) + \\frac{t^2}{2}\\left(${a.toStringIJK()}\\right)\\right|$`,
        MCMaths.cleaner(
          `$\\displaystyle ${dist} = \\left|\\left(${u.getComponents()[0]}t+${
            a.getComponents()[0] / 2
          }t^2\\right)\\mathbf{i}+\\left(${u.getComponents()[1]}t+${
            a.getComponents()[1] / 2
          }t^2\\right)\\mathbf{j} \\right|$`
        ),
        MCMaths.cleaner(
          `$\\displaystyle ${dist} = \\sqrt{\\left(${
            u.getComponents()[0] ** 2
          }t^2+${u.getComponents()[0] * a.getComponents()[0]}t^3+${
            a.getComponents()[0] ** 2
          }t^4\\right)+\\left(${u.getComponents()[1] ** 2}t^2+${
            u.getComponents()[1] * a.getComponents()[1]
          }t^3+${a.getComponents()[1] ** 2}t^4\\right)}$`
        ),
        MCMaths.cleaner(`$\\displaystyle ${dist ** 2} = ${b1}t^2+${a1}t^4$`),
        `HEADING Solving Quadratic:`,
        MCMaths.cleaner(
          `$\\displaystyle ${a1}\\left(t^2\\right)^2+${b1}\\left(t^2\\right)+${c1}=0$`
        ),
        `$t^2=${p.quadraticFormula()}$`,
        `$t^2=${t2a}$ or $${t2b}$`,
        `HEADING Selecting positive real value of $t$:`,
        `$\\therefore t=${t}s$.`,
      ]);
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(a.getComponents()[0], 0, "a) $\\ \\mathbf{i}=$");
      group2.addInput(a.getComponents()[1], 0, "a) $\\ \\mathbf{j}=$");
      question.addInputGroup(group2);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(t, 0, "b) $\\ t=$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Forces_ForceVectors_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);
    const m = MCRandom.randomInt(2, 8);
    const f = new MCMaths.Vector(a, b);
    const br = f.bearing(false);

    const p = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);
    const q = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);

    const f2 = new MCMaths.Vector(p, q);

    const ac = f.add(f2).multiply(1 / m);

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(br) === br /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A force $F=${new MCMaths.Vector(
          a,
          `k`
        ).toString()}N$ acts on a particles with mass $${m}kg$.`,
        `a) Given the force acts on a bearing of $${br}^\\circ$, find $k$.`,
        `After a second force, $\\mathbf{f}$, acts on the particle, it now accelerates at a rate of $${ac}ms^{-2}$.`,
        `b) Find $\\mathbf{f}$ in the form $${new MCMaths.Vector(
          `p`,
          `q`
        ).toString()}N$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING $k=${b}$`,
        `b) HEADING Using Newton's Second Law:`,
        `$\\mathbf{f}+${f.toString()}=${m}${ac.toString()}$`,
        `$\\mathbf{f}=${ac.multiply(m).toString()}-${f.toString()}$`,
        `$\\mathbf{f}=${f2.toString()}$.`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(b, 0, "a) $\\ k=$");
      question.addInputGroup(group);
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(p, 0, "b) $\\ p=$");
      group2.addInput(q, 0, "b) $\\ q=$");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Forces_ForceVectors_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function d() {
      return MCRandom.randomInt(1, 20) * MCRandom.randomInt(-1, 1, 2);
    }
    const v1 = new MCMaths.Vector(d(), d());
    const v2 = new MCMaths.Vector(d(), d());
    const v3 = new MCMaths.Vector(d(), d());
    const r = v1.add(v2).add(v3);
    const m = MCRandom.randomInt(2, 8);
    const a = r.multiply(1 / m);

    function br(t) {
      if (t < 0) {
        return `\\left(${t}\\right)`;
      }
      return t;
    }

    const ans1 = a.bearing(true);
    const ans2 = a.magnitude();
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(ans2 * 10) === ans2 * 10 &&
      ans2 < 20 &&
      Math.round(a.getComponents()[0] * 10) === a.getComponents()[0] * 10 &&
      Math.round(a.getComponents()[1] * 10) === a.getComponents()[1] * 10 &&
      a.getComponents()[0] !== 0 &&
      a.getComponents()[1] !==
        0 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Three forces, $(${v1.toStringIJK()})N$, $(${v2.toStringIJK()})N$ and $(${v3.toStringIJK()})N$ act on a particle of mass $${m}kg$.`,
        `a) Find the bearing of the acceleration of the particle.`,
        `b) Find the magnitude of the acceleration of the particle.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding Resultant Force:`,
        `$(${v1.toStringIJK()})N + (${v2.toStringIJK()})N + (${v3.toStringIJK()})N = (${r.toStringIJK()})N$`,
        `HEADING Using Newton's Second Law:`,
        `$\\displaystyle\\mathbf{a}=\\frac{1}{${m}}(${r.toStringIJK()})$`,
        `$=\\left(${a.toStringIJK()}\\right)ms^{-2}$`,
      ]);
      question.addMultipleParagraphs("solution", a.bearingWorking(true));
      question.addMultipleParagraphs("solution", [
        "b) HEADING Finding Magnitude:",
        `$\\displaystyle = \\sqrt{${br(a.getComponents()[0])}^2+${br(
          a.getComponents()[1]
        )}^2}$`,
        `$=${ans2}ms^{-2}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans1, 0, "a)");
      group.addInput(ans2, 0, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Forces_ForceVectors_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const m1 = MCRandom.randomInt(2, 15);
    const m2 = MCRandom.randomInt(2, 15);

    const a1 = MCRandom.randomInt(0, 270, 90);
    let a1d;
    if (a1 === 0) {
      a1d = "North";
    }
    if (a1 === 90) {
      a1d = "East";
    }
    if (a1 === 180) {
      a1d = "South";
    }
    if (a1 === 270) {
      a1d = "West";
    }

    const a2 = MCRandom.randomInt(0, 345, 15);

    const d1 = new MCMaths.Vector(
      Math.round(Math.sin((a1 * Math.PI) / 180)),
      Math.round(Math.cos((a1 * Math.PI) / 180))
    );
    const v1 = d1.multiply(m1);

    const d2 = new MCMaths.Vector(`\\sin(${a2})`, `\\cos(${a2})`);
    let v2a = m2 * Math.sin((a2 * Math.PI) / 180);
    let v2b = m2 * Math.cos((a2 * Math.PI) / 180);
    if (Math.abs(v2a) < 0.0000000001) {
      v2a = 0;
    }
    if (Math.abs(v2b) < 0.0000000001) {
      v2b = 0;
    }
    const v2 = new MCMaths.Vector(v2a, v2b);

    const r = v1.add(v2);
    const ansa = r.magnitude();
    const ansb = r.bearing(true);
    /* END VARIABLES AND CONSTANTS */
    if (
      a1 !== a2 &&
      parseFloat(MCMaths.cleaner(`${v2a}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${v2b}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${r.getComponents()[0]}`).match(/\d+/g)[0]) <
        100 &&
      parseFloat(MCMaths.cleaner(`${r.getComponents()[1]}`).match(/\d+/g)[0]) <
        100 &&
      a2 % 90 !== 0 &&
      Math.round(ansa) === ansa &&
      ansb > 15 &&
      ansb < 345
      /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A particle is acted on by two forces, $F_1$ and $F_2$.`,
        `$|F_1|=${m1}N$ and acts due ${a1d}.`,
        `$|F_2|=${m2}N$, and acts in a direction with bearing $${a2}^\\circ$.`,
        `a) Find the magnitude of the resultant force applied to the particle.`,
        `b) Find the bearing of the resultant force applied to the particle.`,
        `c) Describe the resulting motion of the particle.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Writing as vectors:`,
        `$F_1 = ${m1}${d1.toString()}$ `,
        `$=${v1.toString()}$`,
        `$F_2 = ${m2}${d2.toString()}$`,
        `$=${v2.toString()}$`,
        `HEADING Finding Resultant:`,
        `$=${v1.toString()}+${v2.toString()}$`,
        `$=${r.toString()}$`,
        `HEADING Finding Magnitude:`,
        MCMaths.cleaner(
          `$\\left|${r.toString()}\\right|=\\sqrt{(${
            r.getComponents()[0]
          })^2+(${r.getComponents()[1]})^2}$`
        ),
        `$=${ansa}$`,
        `b) HEADING Finding Bearing:`,
        MCMaths.cleaner(r.bearingWorking()[1]),
        `$=${r.bearing(true)}^\\circ $`,
        `c) HEADING The particle accelerates.`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ansa, 0, "a)");
      group.addInput(ansb, 0, "b)");
      question.addInputGroup(group);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Forces_ForceVectors_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const n = MCRandom.randomInt(1, 15);
    const e = MCRandom.randomInt(1, 15);

    const a = MCRandom.randomInt(2, 10);
    const b = MCRandom.randomInt(2, 10);
    const c = MCRandom.randomInt(2, 10);
    const d = MCRandom.randomInt(2, 10);

    const im = new MCQuestion.Image("James/Forces.svg", "height", 15);
    const i = 10;
    im.addOverlay(`$${n}N$`, 50, i);
    im.addOverlay(`$(${c}P+${d}Q)N$`, 105, 50);
    im.addOverlay(`$(${a}P+${b}Q)N$`, 50, 100 - i);
    im.addOverlay(`$${e}N$`, i, 50);

    const linEq = new MCMaths.Intersections([c, d, e], [a, b, n], "P", "Q");

    const P = linEq.solutions()[0];
    const Q = linEq.solutions()[1];

    function gcd(x, y) {
      let xg = Math.abs(x);
      let yg = Math.abs(y);
      while (yg) {
        const t = yg;
        yg = xg % yg;
        xg = t;
      }
      return xg;
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      a / b !== c / d &&
      d !== b &&
      c !== a &&
      Math.round(P * 10) === P * 10 &&
      Math.round(Q * 10) === Q * 10 &&
      parseFloat(
        MCMaths.cleaner(`${linEq.solutionsWorking()[1]}`).match(/\d+/g)[2]
      ) < 100 &&
      P * Q !== 0 &&
      gcd(a, b) === 1 &&
      gcd(c, d) === 1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addImage("question", im);
      question.addMultipleParagraphs("question", [
        "Given the particle described above is moving at a constant velocity, find $P$ and $Q$.",
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Forming Equations:`,
        `$${c}P+${d}Q=${e}$`,
        `$${a}P+${b}Q=${n}$`,
        `HEADING Solving Simultaneously:`,
      ]);
      question.addMultipleParagraphs("solution", linEq.solutionsWorking());

      const group = new MCQuestion.InputGroup(2);
      group.addInput(P, 0, "$P=$");
      group.addInput(Q, 0, "$Q=$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Forces_NewtonsSecondLaw_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const m = MCRandom.randomInt(2, 8);

    const im = new MCQuestion.Image("James/Forces.svg", "height", 15);
    const i = 10;

    const x = MCRandom.randomInt(2, 10);
    const y = MCRandom.randomInt(2, 10);

    const a = MCRandom.randomInt(2, 10);
    const b = MCRandom.randomInt(2, 10);

    im.addOverlay(`$aN$`, 50, i);
    im.addOverlay(`$${x}N$`, 100 - i, 50);
    im.addOverlay(`$${y}N$`, 50, 100 - i);
    im.addOverlay(`$bN$`, i, 50);
    im.addOverlay(`$${m}kg$`, 50, 50, 0.8);

    const t = MCRandom.randomInt(5, 20);

    const ac = new MCMaths.Vector(x - b, a - y).multiply(1 / m);

    const s = ac.multiply(0.5 * t * t);

    const f = new MCMaths.Vector(x - b, a - y);

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(s.getComponents()[0]) === s.getComponents()[0] &&
      Math.round(s.getComponents()[1]) === s.getComponents()[1] &&
      ac.getComponents()[0] !== 0 &&
      ac.getComponents()[1] !== 0 &&
      Math.abs(s.getComponents()[0]) < 100 &&
      Math.abs(s.getComponents()[1]) <
        100 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addImage("question", im);
      question.addMultipleParagraphs("question", [
        `The particle of mass $${m}kg$ has four forces acting it as pictured above.`,
        `At time $t=0$, the particle is at rest at $${new MCMaths.Vector(
          0,
          0
        ).toString()}$.`,
        `Given the position of the particle at time $t=${t}s$ is $${s.toString()}$, find $a$ and $b$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Using $\\mathbf{s}=\\mathbf{u}t+\\frac{1}{2}\\mathbf{a}t^2$:`,
        `$${s.toString()}=${(t * t) / 2}\\mathbf{a}$`,
        `$\\mathbf{a} = ${ac.toString()}ms^{-2}$`,
        `HEADING Using Newton's Second Law:`,
        `$\\mathbf{f} = ${m}${ac.toString()}$`,
        `$=${f.toString()}$`,
        `HEADING Forming Equations:`,
        `$${new MCMaths.Vector(
          `${x}-b`,
          `a-${y}`
        ).toString()}=${f.toString()}$`,
        `HEADING Solving:`,
        `$a=${a}$`,
        `$b=${b}$`,
      ]);
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(a, 0, "$a=$");
      group2.addInput(b, 0, "$b=$");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Forces_NewtonsSecondLaw_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const mass = MCRandom.randomInt(1000, 6000, 500);
    const g1 = 9.8;
    const g2 = MCRandom.randomInt(13, 18) / 10;
    const w1 = Math.round(mass * g1);
    const w2 = Math.round(mass * g2);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A rover has landed on the moon.`,
        `The weight of the rover on the Earth is $${w1}N$, and on the moon is $${w2}N$.`,
        `a) Find the mass of the rover.`,
        `b) Find the acceleration due to gravity on the moon.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Using Newton's Second Law:`,
        `$${w1} = 9.8m$`,
        `$m=${mass}kg$`,
        `b) HEADING $${w1} = ${mass}a$`,
        `$a=${g2}ms^{-2}$`,
      ]);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(mass, 0, "a)");
      group2.addInput(g2, 0, "b)");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Forces_NewtonsSecondLaw_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const mass = MCRandom.randomInt(3, 20);
    const air = MCRandom.randomInt(1, 150) / 1000;
    const dist = MCRandom.randomInt(5, 45);
    const f = Math.round(((9.8 * mass) / 1000 - air) * 10000) / 10000;
    const a = 1000 * (f / mass);
    const t = Math.sqrt((2 * dist) / a);
    /* END VARIABLES AND CONSTANTS */
    if (
      f > 0.01 &&
      Math.round(a * 1000) === a * 1000 &&
      Math.round(t * 100000) ===
        t * 100000 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A leaf of mass $${mass}g$ falls out of a tree, with no initial speed.`,
        `The air resistance acting upwards on the leaf is a constant force of $${air}N$.`,
        `Assuming the only two forces acting on the leaf are air resistance and gravity,`,
        `a) Find the downwards acceleration of the leaf.`,
        `b) Find the time taken for the leaft to travel $${dist}m$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding resultant force:`,
        `$=9.8(${mass / 1000}) - ${air}$`,
        `$=${f}N$`,
        `HEADING Finding Acceleration:`,
        `$=${f}\\div ${mass / 1000}$`,
        `$=${a}ms^{-2}$`,
        `b) HEADING Using $s=ut+\\frac{1}{2}at^2$:`,
        `$${dist} = \\frac{${a}}{2}t^2$`,
        `$t^2 = ${(2 * dist) / a}$`,
        `$t=${t}s$`,
      ]);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(a, 0, "a)");
      group2.addInput(t, 0, "b)");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Hypothesis_HypothesisTesting_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p = parseFloat((MCRandom.randomInt(60, 80) / 100).toFixed(2));
    const n = MCRandom.randomInt(40, 100, 5);
    const sig = [1, 5, 10][MCRandom.randomInt(0, 2)];
    const x = MCRandom.randomInt(Math.ceil(n * p), n - 5);

    const B = new MCMaths.BinomialDistribution(n, p);
    const lt = B.cdf(x - 1);
    const ans = 1 - lt;

    let x_crit = 0;
    for (let i = 0; i < n; i += 1) {
      if (1 - B.cdf(i) < sig / 100) {
        x_crit = i;
        break;
      }
    }

    const b1 = 1 - B.cdf(x_crit - 1);
    const b2 = 1 - B.cdf(x_crit);

    const critical_value = x_crit + 1;
    /* END VARIABLES AND CONSTANTS */
    if (
      ans > 0.005 &&
      ans < 0.15 &&
      parseFloat(ans) !== sig / 100 &&
      critical_value !== x
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `A company has, in the past, produced medicine that's $${
          p * 100
        }$% effective at treating the symptoms of the flu. However, they believe that they have improved upon the effectiveness with a new medicine. In a study of $${n}$ people, $${x}$ were relieved of their symptoms.`
      );
      question.addParagraph(
        `question`,
        `a) Test at the $${sig}$% significance level their belief that the new medicine is more effective.`
      );
      question.addParagraph(
        `question`,
        `b) State the critical value of the test.`
      );
      // solution
      question.addHeading(`solution`, `a) State hypothesis`);
      question.addParagraph("solution", `$H_0: p = ${p}$`);
      question.addParagraph("solution", `$H_1: p > ${p}$`);
      question.addParagraph(
        "solution",
        `1-Tailed Test, $${sig}$% significance.`
      );
      question.addHeading(
        `solution`,
        `Let $X$ be the number of people who's symptoms were relieved.`
      );
      question.addHeading(`solution`, `$X \\sim B(${n}, ${p})$`);
      question.addHeading(`solution`, `$P(X\\geq ${x})=1-P(X \\leq ${x - 1})$`);
      question.addHeading(`solution`, `$=1-${lt.toFixed(4)}$`);
      question.addHeading(`solution`, `$\\approx ${ans.toFixed(3)}$`);
      if (sig / 100 < parseFloat(ans)) {
        // Accept H0
        question.addParagraph("solution", `$${ans.toFixed(3)} > ${sig / 100}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Accept $H_0$ at the $${sig}$% level of significance.`
        );
        question.addParagraph(
          "solution",
          `Not enough evidence to support the claim that the new medicine is more effective.`
        );
      } else {
        // Reject H0
        question.addParagraph("solution", `$${ans.toFixed(3)} < ${sig / 100}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Reject $H_0$ at the $${sig}$% level of significance.`
        );
        question.addParagraph(
          "solution",
          `Evidence to suggest that the new medicine is more effective.`
        );
      }
      // b
      question.addHeading(
        `solution`,
        `b) Find the first value of $x$ that results in a probability of less than the significance level.`
      );
      question.addParagraph(
        "solution",
        `$1-P(X\\leq${x_crit - 1})=${b1.toFixed(3)}$`
      );
      question.addParagraph(
        "solution",
        `$1-P(X\\leq${x_crit})=${b2.toFixed(3)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Critical value is $${critical_value}$ as $P(X \\geq ${critical_value})=1-P(X\\leq${x_crit})$`
      );

      //
      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(critical_value, 0, `b)`);
      question.addInputGroup(g1);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Hypothesis_HypothesisTesting_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const person = MCMisc.getName();

    const p = parseFloat((MCRandom.randomInt(20, 40) / 100).toFixed(2));
    const n = MCRandom.randomInt(40, 100, 5);
    const sig = [1, 5, 10][MCRandom.randomInt(0, 2)];
    const x = MCRandom.randomInt(5, Math.floor(n * p));

    const B = new MCMaths.BinomialDistribution(n, p);

    const ans = B.cdf(x);

    let x_crit = 0;
    for (let i = n; i > 0; i -= 1) {
      if (B.cdf(i) < sig / 100) {
        x_crit = i;
        break;
      }
    }

    const b1 = B.cdf(x_crit + 1);
    const b2 = B.cdf(x_crit);

    const critical_value = x_crit + 1;
    /* END VARIABLES AND CONSTANTS */
    if (
      ans > 0.005 &&
      ans < 0.15 &&
      parseFloat(ans) !== sig / 100 &&
      x_crit !== x
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `${person.name} aims to improve ${person.hisher} cricket bowl. At the start of the season the probability that ${person.heshe} would bowl a no-ball was $${p}$. In training at the end of the season, ${person.heshe} bowled only $${x}$ no-balls out of $${n}$ bowls.`
      );
      question.addParagraph(
        `question`,
        `a) Investigate at the $${sig}$% significance level whether ${person.name} has improved by reducing ${person.hisher} likelihood to bowl a no-ball.`
      );
      question.addParagraph(
        `question`,
        `b) State the maximum number of no-balls that ${person.name} could bowl to conclude, at the $${sig}$% significance level, that ${person.heshe} improved.`
      );
      // solution
      // a
      question.addHeading(`solution`, `a) State hypothesis`);
      question.addParagraph("solution", `$H_0: p = ${p}$`);
      question.addParagraph("solution", `$H_1: p < ${p}$`);
      question.addParagraph(
        "solution",
        `1-Tailed Test, $${sig}$% significance.`
      );
      question.addHeading(
        `solution`,
        `Let $X$ be the number of no-balls bowled by ${person.name}.`
      );
      question.addHeading(`solution`, `$X \\sim B(${n}, ${p})$`);
      question.addHeading(
        `solution`,
        `$P(X\\leq ${x})=${parseFloat(ans.toFixed(5))}$`
      );
      question.addHeading(`solution`, `$\\approx ${ans.toFixed(3)}$`);
      if (sig / 100 < parseFloat(ans)) {
        // Accept H0
        question.addParagraph("solution", `$${ans.toFixed(3)} > ${sig / 100}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Accept $H_0$ at the $${sig}$% level of significance.`
        );
        question.addParagraph(
          "solution",
          `Not enough evidence to support the claim that ${person.name} has improved.`
        );
      } else {
        // Reject H0
        question.addParagraph("solution", `$${ans.toFixed(3)} < ${sig / 100}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Reject $H_0$ at the $${sig}$% level of significance.`
        );
        question.addParagraph(
          "solution",
          `Evidence to suggest that ${person.name} has improved.`
        );
      }
      // b

      question.addHeading(
        `solution`,
        `b) Find the first value of $x$ that results in a probability of less than the significance level.`
      );
      question.addParagraph(
        "solution",
        `$P(X\\leq${x_crit + 1})=${b1.toFixed(3)}$`
      );
      question.addParagraph(
        "solution",
        `$P(X\\leq${x_crit})=${b2.toFixed(3)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Critical value is $${x_crit}$`
      );
      question.addParagraph(
        "solution",
        `${person.name} must bowl no more than $${x_crit}$ no-balls.`
      );
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Hypothesis_HypothesisTesting_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = MCRandom.randomInt(40, 100, 10);
    const p = parseFloat((MCRandom.randomInt(5, 15) / 100).toFixed(2));

    const index = MCRandom.randomInt(0, 1);
    const x = [MCRandom.randomInt(2, n), MCRandom.randomInt(2, n)];
    const sig = [5, 10][MCRandom.randomInt(0, 1)];

    const B = new MCMaths.BinomialDistribution(n, p);
    const ans = [B.cdf(x[index]), 1 - B.cdf(x[index] - 1)][index];
    /* END VARIABLES AND CONSTANTS */
    if (
      ans > 0.01 &&
      ans < 0.15 &&
      parseFloat(ans) !==
        sig / 200 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A company claims that only $${(p * 100).toFixed(
          0
        )}$% of screws it produces are faulty. However, when they replace the equipment in their factory they find that of the first $${n}$ screws produced, $${
          x[index]
        }$ are faulty.`
      );
      question.addParagraph(
        "question",
        `Test at the $${sig}$% significance level whether the probability of producing a faulty screw has changed.`
      );
      // solution
      question.addHeading(`solution`, `State hypothesis`);
      question.addParagraph("solution", `$H_0: p = ${p}$`);
      question.addParagraph("solution", `$H_1: p \\neq ${p}$`);
      question.addParagraph(
        "solution",
        `2-Tailed Test, $${sig}$% significance.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore ${sig / 2}$% at each tail.`
      );
      question.addHeading(
        `solution`,
        `Let $X$ be the number of faulty screws produced.`
      );
      question.addHeading(`solution`, `$X \\sim B(${n}, ${p})$`);
      question.addHeading(`solution`, `$np=${parseFloat((n * p).toFixed(2))}$`);
      if (index === 0) {
        // less than
        question.addHeading(`solution`, `As $np > ${x[index]}$`);
        question.addHeading(
          `solution`,
          `$P(X\\leq ${x[index]})=${parseFloat(ans.toFixed(5))}$`
        );
        question.addHeading(`solution`, `$\\approx ${ans.toFixed(3)}$`);
        if (sig / 200 < parseFloat(ans)) {
          // Accept H0
          question.addParagraph(
            "solution",
            `$${ans.toFixed(3)} > ${sig / 200}$`
          );
          question.addParagraph(
            "solution",
            `$\\therefore$ Accept $H_0$ at the $${sig}$% level of significance.`
          );
          question.addParagraph(
            "solution",
            `Not enough evidence to support the claim that the probability of producing a faulty screw has changed.`
          );
        } else {
          // Reject H0
          question.addParagraph(
            "solution",
            `$${ans.toFixed(3)} < ${sig / 200}$`
          );
          question.addParagraph(
            "solution",
            `$\\therefore$ Reject $H_0$ at the $${sig}$% level of significance.`
          );
          question.addParagraph(
            "solution",
            `Evidence to support the claim that the probability of producing a faulty screw has changed.`
          );
        }
      } else {
        // more than
        question.addHeading(`solution`, `As $np < ${x[index]}$`);
        question.addHeading(
          `solution`,
          `$P(X\\geq ${x[index]})=1-P(X \\leq ${x[index] - 1})$`
        );
        question.addHeading(
          `solution`,
          `$=1-${B.cdf(x[index] - 1).toFixed(4)}$`
        );
        question.addHeading(`solution`, `$\\approx ${ans.toFixed(3)}$`);
        if (sig / 200 < parseFloat(ans)) {
          // Accept H0
          question.addParagraph(
            "solution",
            `$${ans.toFixed(3)} > ${sig / 200}$`
          );
          question.addParagraph(
            "solution",
            `$\\therefore$ Accept $H_0$ at the $${sig}$% level of significance.`
          );
          question.addParagraph(
            "solution",
            `Not enough evidence to support the claim that the probability of producing a faulty screw has changed.`
          );
        } else {
          // Reject H0
          question.addParagraph(
            "solution",
            `$${ans.toFixed(3)} < ${sig / 200}$`
          );
          question.addParagraph(
            "solution",
            `$\\therefore$ Reject $H_0$ at the $${sig}$% level of significance.`
          );
          question.addParagraph(
            "solution",
            `Evidence to support the claim that the probability of producing a faulty screw has changed.`
          );
        }
      }
      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Hypothesis_HypothesisTesting_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const p = (MCRandom.randomInt(1, 9) / 10).toFixed(1);
    const person = MCMisc.getName();

    const word = [`lower`, `higher`, `different`][MCRandom.randomInt(0, 2)];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(3);
      question.addParagraph(`A course claims it has a $${p * 10}$% pass rate.`);
      question.addParagraph(
        `${person.name} believes that the probability of a student passing is ${word} than the course claims.`
      );
      question.addParagraph(
        `Select the correct alternative hypothesis for ${person.hisher} test.`
      );
      //

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$H_1: p \\neq ${p}$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$H_1: p< ${p}$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$H_1 :p> ${p}$`);

      question.addAnswer(answer1, word === `different`);
      question.addAnswer(answer2, word === `lower`);
      question.addAnswer(answer3, word === `higher`);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Hypothesis_HypothesisTesting_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p = (MCRandom.randomInt(1, 9) / 10).toFixed(1);
    const symbol = ["<", "\\neq", ">"][MCRandom.randomInt(0, 2)];
    const acc = ["accepted", "rejected"][MCRandom.randomInt(0, 1)];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `A hypothesis test was carried out using the hypotheses:`
      );
      question.addParagraph(`$H_0:p = ${p}$`);
      question.addParagraph(`$H_0:p ${symbol} ${p}$`);
      question.addParagraph(`The test resulted in $H_0$ being ${acc}.`);
      question.addParagraph(`Select the correct statement from below.`);

      if (symbol === "<") {
        if (acc === "accepted") {
          const answer1 = new MCQuestion.MultipleChoiceAnswer();
          answer1.addParagraph(
            `Not enough evidence to suggest $p$ has decreased`
          );

          const answer2 = new MCQuestion.MultipleChoiceAnswer();
          answer2.addParagraph(
            `Not enough evidence to suggest $p$ has changed`
          );

          const answer3 = new MCQuestion.MultipleChoiceAnswer();
          answer3.addParagraph(`Evidence to suggest $p$ has increased`);

          const answer4 = new MCQuestion.MultipleChoiceAnswer();
          answer4.addParagraph(`Evidence to suggest $p$ has decreased`);

          question.addAnswer(answer1, true);
          question.addAnswer(answer2, false);
          question.addAnswer(answer3, false);
          question.addAnswer(answer4, false);
        } else {
          const answer1 = new MCQuestion.MultipleChoiceAnswer();
          answer1.addParagraph(`Evidence to suggest $p$ has decreased`);

          const answer2 = new MCQuestion.MultipleChoiceAnswer();
          answer2.addParagraph(
            `Not enough evidence to suggest $p$ has decreased`
          );

          const answer3 = new MCQuestion.MultipleChoiceAnswer();
          answer3.addParagraph(`Evidence to suggest $p$ has increased`);

          const answer4 = new MCQuestion.MultipleChoiceAnswer();
          answer4.addParagraph(
            `Not enough evidence to suggest $p$ has changed`
          );

          question.addAnswer(answer1, true);
          question.addAnswer(answer2, false);
          question.addAnswer(answer3, false);
          question.addAnswer(answer4, false);
        }
      } else if (symbol === "\\neq") {
        if (acc === "accepted") {
          const answer1 = new MCQuestion.MultipleChoiceAnswer();
          answer1.addParagraph(
            `Not enough evidence to suggest $p$ has changed`
          );

          const answer2 = new MCQuestion.MultipleChoiceAnswer();
          answer2.addParagraph(
            `Not enough evidence to suggest $p$ has ${
              ["increased", "decreased"][MCRandom.randomInt(0, 1)]
            }`
          );

          const answer3 = new MCQuestion.MultipleChoiceAnswer();
          answer3.addParagraph(
            `Evidence to suggest $p$ has ${
              ["increased", "decreased"][MCRandom.randomInt(0, 1)]
            }`
          );

          const answer4 = new MCQuestion.MultipleChoiceAnswer();
          answer4.addParagraph(`Evidence to suggest $p$ has changed`);

          question.addAnswer(answer1, true);
          question.addAnswer(answer2, false);
          question.addAnswer(answer3, false);
          question.addAnswer(answer4, false);
        } else {
          const answer1 = new MCQuestion.MultipleChoiceAnswer();
          answer1.addParagraph(`Evidence to suggest $p$ has changed`);

          const answer2 = new MCQuestion.MultipleChoiceAnswer();
          answer2.addParagraph(
            `Not enough evidence to suggest $p$ has ${
              ["increased", "decreased"][MCRandom.randomInt(0, 1)]
            }`
          );

          const answer3 = new MCQuestion.MultipleChoiceAnswer();
          answer3.addParagraph(
            `Evidence to suggest $p$ has ${
              ["increased", "decreased"][MCRandom.randomInt(0, 1)]
            }`
          );

          const answer4 = new MCQuestion.MultipleChoiceAnswer();
          answer4.addParagraph(
            `Not enough evidence to suggest $p$ has changed`
          );

          question.addAnswer(answer1, true);
          question.addAnswer(answer2, false);
          question.addAnswer(answer3, false);
          question.addAnswer(answer4, false);
        }
      } else if (acc === "accepted") {
        const answer1 = new MCQuestion.MultipleChoiceAnswer();
        answer1.addParagraph(
          `Not enough evidence to suggest $p$ has increased`
        );

        const answer2 = new MCQuestion.MultipleChoiceAnswer();
        answer2.addParagraph(`Evidence to suggest $p$ has decreased`);

        const answer3 = new MCQuestion.MultipleChoiceAnswer();
        answer3.addParagraph(`Evidence to suggest $p$ has increased`);

        const answer4 = new MCQuestion.MultipleChoiceAnswer();
        answer4.addParagraph(`Not enough evidence to suggest $p$ has changed`);

        question.addAnswer(answer1, true);
        question.addAnswer(answer2, false);
        question.addAnswer(answer3, false);
        question.addAnswer(answer4, false);
      } else {
        const answer1 = new MCQuestion.MultipleChoiceAnswer();
        answer1.addParagraph(`Evidence to suggest $p$ has increased`);

        const answer2 = new MCQuestion.MultipleChoiceAnswer();
        answer2.addParagraph(
          `Not enough evidence to suggest $p$ has increased`
        );

        const answer3 = new MCQuestion.MultipleChoiceAnswer();
        answer3.addParagraph(
          `Not enough evidence to suggest $p$ has decreased`
        );

        const answer4 = new MCQuestion.MultipleChoiceAnswer();
        answer4.addParagraph(`Evidence to suggest $p$ has changed`);

        question.addAnswer(answer1, true);
        question.addAnswer(answer2, false);
        question.addAnswer(answer3, false);
        question.addAnswer(answer4, false);
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Integration_PolynomialIntegration_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const n = MCRandom.randomInt(2, 5);
    const m = MCRandom.randomInt(2, 5) * -1;
    const a = MCRandom.randomInt(1, 3);
    const b = MCRandom.randomInt(2, 5);
    const A = MCRandom.randomInt(2, 5);
    const B = MCRandom.randomInt(2, 5);
    const dydx1 = MCMaths.cleaner(
      `${A}x^{${n}} + x^{${a / b}} + \\frac{${B}}{x^${-m}}`
    );
    const dydx2 = MCMaths.cleaner(`${A}x^{${n}} + x^{${a / b}} + ${B}x^{${m}}`);
    const y = MCMaths.cleaner(
      `${A / (n + 1)}x^{${n + 1}} + ${1 / (a / b + 1)}x^{${a / b + 1}}  ${
        B / (m + 1)
      }x^{${m + 1}}`
    );
    /* END VARIABLES AND CONSTANTS */
    if (a !== b) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Given that,`,
        `$\\ \\frac{dy}{dx} = ${dydx1}$`,
        `Find $y$ in terms of $x$.`,
      ]);

      question.addHeading(
        "solution",
        `First re-write $\\frac{${B}}{x^${-m}}$ as $${B}x^{${m}}$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow \\frac{dy}{dx} = ${dydx2}$`,
      ]);

      question.addHeading(
        "solution",
        `Now $y$ can be found by integrating $\\frac{dy}{dx}$ with respect to $x$, using the rule, $f'(x) = x^n \\Rightarrow f(x) = \\frac{1}{n + 1} x^{n + 1}$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow y = ${y} + C$`,
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Integration_PolynomialIntegration_Exam_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const n = MCRandom.randomInt(2, 5);
    const m = MCRandom.randomInt(2, 5) * -1;
    const a = MCRandom.randomInt(1, 3);
    const b = MCRandom.randomInt(2, 5);
    const A = MCRandom.randomInt(2, 5);
    const B = MCRandom.randomInt(2, 5);
    const dydx1 = MCMaths.cleaner(
      `${A}x^{${n}} + x^{${a / b}} + \\frac{${B}}{x^${-m}}`
    );
    const dydx2 = MCMaths.cleaner(`${A}x^{${n}} + x^{${a / b}} + ${B}x^{${m}}`);
    const y = MCMaths.cleaner(
      `${A / (n + 1)}x^{${n + 1}} + ${1 / (a / b + 1)}x^{${a / b + 1}}  ${
        B / (m + 1)
      }x^{${m + 1}}`
    );
    const x1 = 0;
    const x2 = 1;
    const y1 = y.replace("x", "(0)").replace("x", "(0)").replace("x", "(0)");
    const y2 = y
      .replace("x", `(${x2})`)
      .replace("x", `(${x2})`)
      .replace("x", `(${x2})`);
    const area =
      (A / (n + 1)) * x2 ** (n + 1) +
      (1 / (a / b + 1)) * x2 ** (a / b + 1) +
      (B / (m + 1)) * x2 ** (m + 1);
    /* END VARIABLES AND CONSTANTS */
    if (a !== b) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `$\\ f(x) = ${dydx1}$`,
        `Find the area under the curve $y = f(x)$ for $0 \\leqslant x \\leqslant ${x2}$.`,
      ]);

      question.addHeading(
        "solution",
        `The area can be found by the intregral:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\int_0^{${x2}} f(x)\\ dx = \\int_0^{${x2}} (${dydx1})\\ dx$`,
      ]);

      question.addHeading(
        "solution",
        `Re-write $\\frac{${B}}{x^${-m}}$ as $${B}x^{${m}}$, and evaluate the integral:`
      );

      question.addMultipleParagraphs("solution", [
        `$A = \\int_0^{${x2}} (${dydx2})\\ dx$`,
        `$\\ = \\left[${y}\\right]_0^{${x2}}$`,
        `$\\ = \\Bigl(${y2}\\Bigr) - \\Bigl(${y1}\\Bigr)$`,
        MCMaths.cleaner(`$\\ = ${area}$`),
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Integration_PolynomialIntegration_Exam_Q11() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const n = MCRandom.randomInt(2, 4);
    let A = MCRandom.randomInt(1, 3);
    const B = MCRandom.randomInt(1, 16);
    let C = MCRandom.randomInt(2, 30, 2);
    if (Math.round((n + 1) / 2) !== (n + 1) / 2) {
      A = 2;
      C = MCRandom.randomInt(1, 29, 2);
    }
    const p1 = new MCMaths.Polynomial([A, B]);
    const p2 = p1.integral();
    const p3 = p2.toString().replace("x", `(${n}k)`).replace("x", `(${n}k)`);
    const p4 = p2.toString().replace("x", `k`).replace("x", `k`);
    const p5 = new MCMaths.Polynomial(
      [(A * (n ** 2 - 1)) / 2, B * (n - 1), 0],
      "k"
    );
    const poly = new MCMaths.Polynomial(
      [(A * (n ** 2 - 1)) / 2, B * (n - 1), -C],
      "k"
    );
    const k = poly.roots();
    /* END VARIABLES AND CONSTANTS */
    if (Math.round(C / (n - 1)) === C / (n - 1) && Math.round(k[0]) === k[0]) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        "Given that",
        `$\\int_k^{${n}k} ${p1}\\ dx = ${C}, \\ k \\gt 0$`,
        "Find all possible values of $k$.",
      ]);

      question.addHeading("solution", "First evaluate the integral:");

      question.addMultipleParagraphs("solution", [
        `$\\int_k^{${n}k} ${p1}\\ dx = ${C}$`,
        `$\\Rightarrow \\left[${p2}\\right]_k^{${n}k} = ${C}$`,
        `$\\Rightarrow \\Bigr(${p3}\\Bigl) - \\Bigr(${p4}\\Bigl) = ${C}$`,
        `$\\Rightarrow ${p5} = ${C}$`,
        `$\\Rightarrow ${poly} = 0$`,
      ]);

      question.addHeading("solution", "Now solve for $k$ by factorising:");

      question.addMultipleParagraphs("solution", [
        `$\\ ${poly} = 0$`,
        `$\\Rightarrow (k - ${k[0]})(${(A * (n ** 2 - 1)) / 2}k + ${
          (-A * (n ** 2 - 1)) / 2
        }${k[1]}) = 0$`,
        MCMaths.cleaner(`$\\therefore k = ${k}$`),
      ]);

      question.addHeading("solution", "Since $k \\gt 0$:");

      question.addMultipleParagraphs("solution", [
        MCMaths.cleaner(`$\\therefore k = ${k[0]}$`),
      ]);

      const group = new MCQuestion.InputGroup(1);
      group.addInput(k[0], 0, "$k = $");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Integration_PolynomialIntegration_Exam_Q12() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const A = -1;
    const B = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const C = MCRandom.randomInt(1, 10);
    const p1 = new MCMaths.Polynomial([A, B, C, 0]);
    const p2 = new MCMaths.Polynomial([-A, -B, -C]);
    const roots = p2.roots();
    let check = true;
    if (roots === ["No Real Roots", "No Real Roots"]) {
      check = false;
    } else if (
      Math.round(roots[0]) !== roots[0] ||
      Math.round(roots[1]) !== roots[1]
    ) {
      check = false;
    }
    roots.unshift(0);
    const x1 = roots[1];
    const int1 = p1.integral();
    const int2 = int1
      .toString()
      .replace("x", "(0)")
      .replace("x", "(0)")
      .replace("x", "(0)");
    const int3 = int1
      .toString()
      .replace("x", `(${x1})`)
      .replace("x", `(${x1})`)
      .replace("x", `(${x1})`);
    const a = int1.evaluate(x1);
    const b = int1.evaluate(0);
    const ans = a - b;

    const graph = new MCQuestion.Graph(
      roots[1] + 2,
      roots[2] - 2,
      p1.evaluate(roots[1] / 2) + 5,
      p1.evaluate(roots[2] / 2) - 5,
      1,
      5
    );
    graph.plot(p1.functionString(), roots[2] - 3, roots[1] + 3);
    /* END VARIABLES AND CONSTANTS */
    if (check && Math.round(ans * 100) === ans * 100) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `$f(x) = ${p1}$`,
        `Find the area between the graph of $y = f(x)$ and the $x-$axis for $x, f(x) \\geqslant 0$.`,
      ]);

      question.addHeading(
        "solution",
        "First find the roots of $f(x)$ to investigate the region which we need to find the area of."
      );

      question.addMultipleParagraphs("solution", [
        `$\\ ${p1} = 0$`,
        `$\\Rightarrow -x\\ (${p2}) = 0$`,
        `$\\Rightarrow -x\\ (x - ${x1})(x + ${-roots[2]}) = 0$`,
        `$\\ x = ${roots}$`,
      ]);

      question.addHeading("solution", "Sketching");

      question.addGraph("solution", graph);

      question.addHeading(
        "solution",
        `We can see the region we are intersted in is the area under $y = f(x)$ for $0 \\leqslant x \\leqslant ${x1}$`
      );

      question.addMultipleParagraphs("solution", [
        `$\\therefore Area = \\int_0^{${x1}} ${p1}\\ dx$`,
        `$\\ = \\left[${int1}\\right]_0^{${x1}}$`,
        `$\\ = \\Bigl(${int3}\\Bigr) - \\Bigl(${int2}\\Bigr)$`,
        MCMaths.cleaner(`$\\ = ${a} - ${b}$`),
        MCMaths.cleaner(`$\\ = ${ans}$`),
        `$\\ = ${ans}$`,
      ]);

      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans, 0, "$Area = $");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Integration_PolynomialIntegration_Exam_Q13() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const A = MCRandom.randomInt(2, 10);
    const m = MCRandom.randomInt(1, 7);
    const x1 = A - m;
    const B = MCRandom.randomInt(1, 10);
    const quad = new MCMaths.Polynomial([-1, A, B]);
    const line = new MCMaths.Polynomial([m, B]);
    const poly = new MCMaths.Polynomial([1, m - A, 0]);
    const intA1 = quad.integral();
    const intA2 = intA1
      .toString()
      .replace("x", "(0)")
      .replace("x", "(0)")
      .replace("x", "(0)");
    const intA3 = intA1
      .toString()
      .replace("x", `(${x1})`)
      .replace("x", `(${x1})`)
      .replace("x", `(${x1})`);
    const intB1 = line.integral();
    const intB2 = intB1.toString().replace("x", "(0)").replace("x", "(0)");
    const intB3 = intB1
      .toString()
      .replace("x", `(${x1})`)
      .replace("x", `(${x1})`);
    const a = intA1.evaluate(x1);
    const b = intB1.evaluate(x1);
    const ans = a - b;

    const graph = new MCQuestion.Graph(
      A + 3,
      -1,
      Math.round(quad.evaluate(A / 2)) + 1,
      -1,
      1,
      2
    );
    graph.plot(quad.functionString(), -2, A + 4);
    graph.plot(line.functionString(), -2, A + 4);
    /* END VARIABLES AND CONSTANTS */
    if (x1 > A / 2 && Math.round(ans * 100) === ans * 100) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Find the finite area enclosed by the graphs of`,
        `$\\ y = ${quad}$, and`,
        MCMaths.cleaner(`$\\ y = ${line}$`),
      ]);

      question.addHeading(
        "solution",
        "First sketch the graphs to investigate the region which we need to find the area of."
      );

      question.addGraph("solution", graph);

      question.addHeading(
        "solution",
        "Now find the intersections of the graphs to find the bounds of integration."
      );

      question.addMultipleParagraphs("solution", [
        MCMaths.cleaner(`$\\ ${line} = ${quad}$`),
        `$\\Rightarrow ${poly} = 0$`,
        `$\\Rightarrow x\\ (x - ${x1}) = 0$`,
        `$\\therefore x = 0, ${x1}$`,
      ]);

      question.addHeading(
        "solution",
        "Hence the area of the region bounded by the graphs can be found by:"
      );

      question.addMultipleParagraphs("solution", [
        `$\\ Area = \\int_0^${x1} (${quad})\\ dx - \\int_0^${x1} (${line})\\ dx$`,
        `$\\ = \\left[${intA1}\\right]_0^${x1} - \\left[${intB1}\\right]_0^${x1}$`,
        `$\\ = \\Biggl(\\Bigl(${intA3}\\Bigr) - \\Bigl(${intA2}\\Bigr)\\Biggr) - \\Biggl(\\Bigl(${intB3}\\Bigr) - \\Bigl(${intB2}\\Bigr)\\Biggr)$`,
        MCMaths.cleaner(`$\\ = ${a} - ${b}$`),
        MCMaths.cleaner(`$\\ = ${ans}$`),
      ]);

      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans, 0, "$Area = $");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Integration_PolynomialIntegration_Exam_Q14() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 6);
    const b = MCRandom.randomInt(3, 13);
    const poly = MCMaths.Polynomial.generateFromRoots([0, a, b]);
    const p1 = MCMaths.Polynomial.generateFromRoots([a, b]);
    const p2 = new MCMaths.Polynomial([1, -a]);
    const p3 = new MCMaths.Polynomial([1, -b]);
    const calc1 = new MCMaths.Calculus(poly, 0, a);
    const calc2 = new MCMaths.Calculus(poly, a, b);
    const im = new MCQuestion.Image("Joel/Cubic_Shaded.svg", "width", 25);
    im.addOverlay("$y$", 15, 5);
    im.addOverlay("$x$", 95, 63);
    im.addOverlay("$y = f(x)$", 68, 10);
    const im2 = new MCQuestion.Image("Joel/Cubic_Shaded.svg", "width", 15);
    im2.addOverlay("$y$", 14, 5, 0.9);
    im2.addOverlay("$x$", 94, 65, 0.9);
    im2.addOverlay("$A_1$", 30, 50, 0.9);
    im2.addOverlay("$A_2$", 60, 69, 0.9);
    im2.addOverlay("$0$", 15, 53, 0.8);
    im2.addOverlay(`$${a}$`, 44, 64, 0.8);
    im2.addOverlay(`$${b}$`, 74, 64, 0.8);
    const working1 = calc1.integralWorking();
    const int1 = calc1.evaluateIntegral();
    const working2 = calc2.integralWorking();
    const int2 = calc2.evaluateIntegral();
    const coefficients = poly.getCoefficients();
    let check = false;
    if (Math.round(coefficients[1] / 3) === coefficients[1] / 3) {
      check = true;
    }
    /* END VARIABLES AND CONSTANTS */
    if (b > a + 2 && b <= 2 * a + 1 && check) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [`$\\ f(x) = ${poly}$`]);

      question.addImage("question", im);

      question.addMultipleParagraphs("question", [
        `Find the combined area of the shaded regions.`,
      ]);

      question.addHeading(
        "solution",
        "First find the roots of $f(x)$ to get the bounds of integration:"
      );

      question.addMultipleParagraphs("solution", [
        `$\\ 0 = f(x)$`,
        `$\\Rightarrow 0 = ${poly}$`,
        `$\\ = x\\ (${p1})$`,
        `$\\ = x\\ (${p2})(${p3})$`,
        `$\\therefore x = 0, ${a}, ${b}$`,
      ]);

      question.addImage("solution", im2);

      question.addHeading(
        "solution",
        "Now finding the areas of $A_1$ and $A_2$ separately."
      );

      question.addMultipleParagraphs("solution", [
        `$\\ A_1 = $${working1[1]}`,
        `$\\ $${working1[2]}`,
        MCMaths.cleaner(`$\\ = ${int1}$`),
      ]);

      question.addHeading(
        "solution",
        "Since $A_2$ is underneath the $x-$axis, the integral will come out negative, so"
      );

      question.addMultipleParagraphs("solution", [
        `$\\ - A_2 = $${working2[1]}`,
        `$\\ $${working2[2]}`,
        MCMaths.cleaner(`$\\ = ${int2}$`),
        MCMaths.cleaner(`$\\therefore A_2 = ${-int2}$`),
      ]);

      question.addHeading("solution", "So the combined area is,");

      question.addMultipleParagraphs("solution", [
        `$\\ A = A_1 + A_2$`,
        `$\\ = ${int1} + ${-int2}$`,
        `$\\ = ${int1 - int2}$`,
      ]);

      const group = new MCQuestion.InputGroup(1);
      group.addInput(int1 - int2, 0, "$Area = $");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Integration_PolynomialIntegration_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const m = MCRandom.randomInt(1, 2);
    const c = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const a = MCRandom.randomInt(2, 3);
    const p1 = new MCMaths.Polynomial([m, c]);
    const p2 = p1.pow(a - 1);
    const p3 = p1.pow(a);
    const calc = new MCMaths.Calculus(p3);
    const working = calc.integralWorking();
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Given that,`,
        `$\\ f'(x) = (${p1})^${a}$`,
        `Find $f(x)$.`,
      ]);

      question.addHeading("solution", `First expand $(${p1})^${a}$:`);

      question.addMultipleParagraphs("solution", [
        `$\\ f'(x) = (${p1})(${p2})$`,
        `$\\ = ${p3}$`,
      ]);

      question.addHeading(
        "solution",
        `Now integrate $f'(x)$ with respect to $x$, using the rule, $f'(x) = x^n \\Rightarrow f(x) = \\frac{1}{n + 1} x^{n + 1}$:`
      );

      question.addMultipleParagraphs("solution", [`$f(x) = $${working[1]}`]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Integration_PolynomialIntegration_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const A = MCRandom.randomInt(1, 3);
    const B = MCRandom.randomInt(-2, -1);
    const l = MCRandom.randomInt(2, 7);
    const n = MCRandom.randomInt(2, 7);
    const m = MCRandom.randomInt(2, 7);
    let check = true;
    if (n - l <= 1 || m - l <= 1 || n === m) {
      check = false;
    }
    const f1 = MCMaths.cleaner(
      `(${A} ${B}\\sqrt{x}) \\frac{(x^${n} + x^${m})}{x^${l}}`
    );
    const f2 = MCMaths.cleaner(
      `\\frac{${A}x^{${n}} + ${A}x^{${m}}  ${B}x^{${n + 0.5}}  ${B}x^{${
        m + 0.5
      }}}{x^${l}}`
    );
    const f3 = MCMaths.cleaner(
      `${A}x^{${n - l}} + ${A}x^{${m - l}}  ${B}x^{${n + 0.5 - l}}  ${B}x^{${
        m + 0.5 - l
      }}`
    );
    const int = MCMaths.cleaner(
      `${A / (n - l + 1)}x^{${n - l + 1}} + ${A / (m - l + 1)}x^{${
        m - l + 1
      }}  ${B / (n + 1.5 - l)}x^{${n + 1.5 - l}}  ${B / (m + 1.5 - l)}x^{${
        m + 1.5 - l
      }}`
    );
    /* END VARIABLES AND CONSTANTS */
    if (check) {
      /* START BUILDING QUESTION USING MCQuestion */

      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [`Find,`, `$\\int ${f1} dx$`]);

      question.addHeading("solution", `First rearrange the integrand:`);

      question.addMultipleParagraphs("solution", [
        `$\\ ${f1}$`,
        `$\\ = ${f2}$`,
        `$\\ = ${f3}$`,
      ]);

      question.addHeading(
        "solution",
        `Now integrate with respect to $x$, using the rule, $\\int x^n dx = \\frac{1}{n + 1} x^{n + 1} + C$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\int ${f1} dx$`,
        `$\\ = \\int ${f3} dx$`,
        `$\\ = ${int} + C$`,
      ]);

      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Integration_PolynomialIntegration_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const m = MCRandom.randomInt(1, 2);
    const c = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const a = 3;
    const p1 = new MCMaths.Polynomial([m, c]);
    const p2 = p1.pow(a - 1);
    const p3 = p1.pow(a);
    const calc = new MCMaths.Calculus(p3);
    const working = calc.integralWorking();
    const C = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const { length } = working[1];
    let j = 0;
    for (let i = 1; i < length; i += 1) {
      if (working[1][i] === "=") {
        j = i;
      }
    }
    let working2 = "";
    for (let i = j; i < length; i += 1) {
      working2 += working[1][i];
    }
    let final = `${working2.replace("+c", `+${C}`)}`;
    if (C < 0) {
      final = `${working2.replace("+c", `${C}`)}`;
    }
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Integrate the following,`,
        `$\\ f(x) = \\int (${p1})^${a} dx$`,
        `Given that the graph of $y = f(x)$ passes through the point $(0, ${C})$.`,
      ]);

      question.addHeading("solution", `First expand $(${p1})^${a}$:`);

      question.addMultipleParagraphs("solution", [
        `$\\to (${p1})(${p2})$`,
        `$\\ = ${p3}$`,
      ]);

      question.addHeading("solution", `Now integrate`);

      question.addMultipleParagraphs("solution", [`$f(x) = $${working[1]}`]);

      question.addHeading(
        "solution",
        `As the graph of $y = f(x)$ passes through the point $(0, ${C}) \\ \\Rightarrow \\ f(0) = ${C}$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow ${C} ${working2
          .replace("x", "(0)")
          .replace("x", "(0)")
          .replace("x", "(0)")
          .replace("x", "(0)")}`,
        `$\\therefore c = ${C}$`,
        `$\\therefore f(x) ${final}`,
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Integration_PolynomialIntegration_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const A = MCRandom.randomInt(1, 3);
    const B = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const p1 = new MCMaths.Polynomial([A, B, 0]);
    const vars = ["t", "s", "u", "v", "z", "w"];
    const var1 = MCRandom.chooseElement(vars);
    const var2 = MCRandom.chooseElement(vars);
    const C = MCRandom.randomInt(2, 5);
    const f1 = `${var1}^{2} + ${p1} + ${C}${var2}${var1}`;
    let int1 = new MCMaths.Calculus(p1);
    int1 = int1.integralWorking();
    const { length } = int1[1];
    let j = 0;
    for (let i = 1; i < length; i += 1) {
      if (int1[1][i] === "=") {
        j = i + 1;
      }
    }
    let int2 = `${var1}^{2}x + `;
    for (let i = j; i < length; i += 1) {
      int2 += int1[1][i];
    }
    int2 += `$+ ${C}${var2}${var1}x`;
    int2 = int2.replace("+c", "");
    int2 += ` + C$`;
    /* END VARIABLES AND CONSTANTS */
    if (var1 !== var2) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Integrate the following:`,
        `$\\int (${f1})\\ dx$`,
      ]);

      question.addHeading(
        "solution",
        `As we are integrating with respect to $x$, we can treat the terms in $${var1}$ and $${var2}$ as constants.`
      );

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow \\int (${f1})\\ dx$`,
        `$\\ = ${int2}`,
      ]);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Integration_PolynomialIntegration_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const p = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const q = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const A = MCRandom.randomInt(1, 7);
    const x = MCRandom.chooseElement(["x", "y", "z", "r", "t", "w", "s"]);
    const f1 = new MCMaths.Polynomial([1, p, q, A], `${x}`);
    const f2 = `${x}^3 + p${x}^2 + q${x} + ${A}`;
    let int = new MCMaths.Calculus(f1);
    int = int.integralWorking();
    const { length } = int[1];
    let j = 0;
    for (let i = 1; i < length; i += 1) {
      if (int[1][i] === "=") {
        j = i + 1;
      }
    }
    let int2 = "";
    for (let i = j + 1; i < length; i += 1) {
      int2 += int[1][i];
    }
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Consider, `,
        `$\\ f(${x}) = ${f2}$`,
        `$\\ g(${x}) = ${int2}`,
        `Given that, $\\ \\int f(${x})\\ d${x} = g(${x})$,`,
        `Find the values of $p$ and $q$.`,
      ]);

      question.addHeading("solution", `First find $\\ \\int f(${x})\\ d${x}$:`);

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow \\int (${f2})\\ d${x}$`,
        `$\\ = \\frac{1}{4} ${x}^4 + \\frac{p}{3} ${x}^3 + \\frac{q}{2} ${x}^2 + ${A}${x} + c$`,
      ]);

      question.addHeading("solution", "Comparing the coefficients:");

      question.addMultipleParagraphs("solution", [
        `$\\frac{1}{4} ${x}^4 + \\frac{p}{3} ${x}^3 + \\frac{q}{2} ${x}^2 + ${A}${x} + c = ${int2}`,
        MCMaths.cleaner(
          `$\\Rightarrow \\frac{p}{3} = ${p / 3}, \\frac{q}{2} = ${q / 2}$`
        ),
        `$\\therefore p = ${p}, q = ${q}$`,
      ]);

      const group = new MCQuestion.InputGroup(2);
      group.addInput(p, 0, "$\\ p = $");
      group.addInput(q, 0, "$\\ q = $");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Integration_PolynomialIntegration_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const A1 = 1;
    const B1 = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const C1 = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const D1 = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const A2 = MCRandom.randomInt(1, 3);
    const B2 = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const C2 = MCRandom.randomInt(1, 6) * MCRandom.randomInt(-1, 1, 2);
    const p1 = new MCMaths.Polynomial([1, B1, C1, D1]);
    const p2 = new MCMaths.Polynomial([A2, B2, C2]);
    const f1 = p1.derivative();
    let f2 = p2.derivative();
    const f3 = f1.subtract(f2);
    f2 = f2.toString().replace("+", "");
    const roots = f3.roots();
    for (let i = 0; i <= 1; i += 1) {
      roots[i] = Math.round(roots[i] * 100) / 100;
    }
    const working = f3.quadraticFormula();
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `Given that,`,
        `$\\ \\frac{df_1}{dx} = ${f1}$`,
        `$\\ \\frac{df_2}{dx} = ${f2}$`,
        `Find all the values of $x$ (if any), where the graphs of`,
        `$\\ y = ${p1}$, and`,
        `$\\ y = ${p2}$`,
        `Are parallel to each other.`,
        `Give your answers to $2\\ d.p$ where appropriate.`,
      ]);

      question.addHeading(
        "solution",
        `If we first integrate $\\frac{df_1}{dx}$ and $\\frac{df_2}{dx}$, we can find $f_1$ adn $f_2$ as functions of $x$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\ \\frac{df_1}{dx} = ${f1}$`,
        `$\\Rightarrow f_1(x) = ${p1}$`,
        `$\\ \\frac{df_2}{dx} = ${f2}$`,
        `$\\Rightarrow f_2(x) = ${p2}$`,
      ]);

      question.addHeading(
        "solution",
        `Hence we need to find when the graphs of $y = f_1(x)$ and $y = f_2(x)$ are parallel.`
      );

      question.addHeading(
        "solution",
        `This is when $\\frac{df_1}{dx} = \\frac{df_2}{dx}$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow ${f1} = ${f2}$`,
        `$\\Rightarrow ${f3} = 0$`,
      ]);

      question.addHeading(
        "solution",
        `This can be solved using the quadratic formula:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\therefore x = ${working}$`,
        `$\\therefore x = ${roots} \\ (2\\ d.p)$`,
      ]);

      const group = new MCQuestion.InputGroup(2);
      group.addInput(roots, 0.01, "$\\ x = $");

      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Integration_PolynomialIntegration_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const b = MCRandom.randomInt(1, 3);
    const n = MCRandom.randomInt(5, 8);
    const poly = new MCMaths.Polynomial([-1, b]);
    const bin = new MCMaths.Binomial(b, "", -1, "x", n);
    const working = bin.expansionWorking(3);
    const coefficients = bin.expansionCoefficient(3);
    const A = coefficients[0];
    const B = coefficients[1];
    const C = coefficients[2];
    const expansion = new MCMaths.Polynomial([C, B, A]);
    const int = expansion.integral();
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `$\\ f(x) = (${poly})^{${n}}$`,
        `Given that $x$ is small:`,
        `a) Find an approximation for $f(x)$ in the form`,
        `$\\ A + Bx + Cx^2$`,
      ]);

      question.addHeading(
        "solution",
        `a) We can perform a binomial expansion to get an approximation for $f(x)$ of that form.`
      );

      question.addHeading(
        "solution",
        `Since $x$ is small we can also disregard terms of $x^3$ and higher.`
      );

      question.addMultipleParagraphs("solution", [
        `${working[0]}`,
        `$\\Rightarrow$ ${working[3]}`,
        `${working[4]}`,
        `$\\therefore A = ${A}, B = ${B}, C = ${C}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput(A, 0, "a) $\\ A = $");
      group1.addInput(B, 0, "a) $\\ B = $");
      group1.addInput(C, 0, "a) $\\ C = $");

      question.addMultipleParagraphs("question", [
        `b) Find an approximation for $\\int f(x)\\ dx$`,
      ]);

      question.addHeading(
        "solution",
        `b) We can find an approximation for $\\int f(x)\\ dx$ by using the approximation found in part (a):`
      );

      question.addMultipleParagraphs("solution", [
        `$\\int f(x)\\ dx \\approx \\int (${expansion})\\ dx$`,
        `$\\ \\approx ${int} + C$`,
      ]);

      question.requiresSelfMarking();
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Integration_PolynomialIntegration_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const c = MCRandom.randomInt(10, 20);
    const m = -1 * MCRandom.randomInt(3, 8);
    const vt = new MCMaths.Polynomial([m, c], "t");
    const at = m;
    const st = vt.integral();
    const sts = st.toString();
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `The velocity of a particle at time $t$, is given by`,
        `$\\ s'(t) = ${vt}$`,
        `a) Find the acceleration of the particle, $s''(t)$, at time t.`,
      ]);

      question.addHeading(
        "solution",
        `a) The acceleration, $s''(t)$, can be found by differentiating the velocity, $s'(t)$, of the particle:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow s''(t) = \\frac{d}{dt} (${vt})$`,
        `$\\ = ${at}$`,
      ]);

      const group = new MCQuestion.InputGroup(1);
      group.addInput(at, 0, "a) Acceleration $\\ = $");

      question.addMultipleParagraphs("question", [
        `Given that the initial displacement of the particle is $0$,`,
        `b) Find an expression for the displacement, $s(t)$, at time $t$.`,
      ]);

      question.addHeading(
        "solution",
        `b) The displacement, $s(t)$, can be found by integrating the velocity, $s'(t)$, of the particle:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow s(t) = \\int s'(t)\\ dt$`,
        `$\\ = \\int (${vt})\\ dt$`,
        `$\\ = ${st} + C$`,
      ]);

      question.addHeading(
        "solution",
        `As the initial displacement is $0\\ \\Rightarrow \\ s(0) = 0$`
      );

      question.addMultipleParagraphs("solution", [
        `$\\Rightarrow 0 = ${sts.replace("t", "(0)").replace("t", "(0)")} + C$`,
        `$\\Rightarrow C = 0$`,
        `$\\therefore s(t) = ${st}$`,
      ]);

      question.addInputGroup(group);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Averages_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = MCRandom.randomInt(5, 10);
    const sample = MCMaths.Stats.randomSample(n, 105, 145, 10);
    const sample_arr = sample.dataX;

    const extra = MCRandom.randomInt(2, 4);

    const range = [true, false][MCRandom.randomInt(0, 1)];
    const mean = sample.mean();
    const sd = Math.sqrt(sample.sx2() / n - mean ** 2);

    const addTimes = [];
    const ts = [];
    for (let i = 0; i < extra; i += 1) {
      addTimes.push(16);
      ts.push(`t_{${i + 1}}`);
    }
    const newSample = new MCMaths.Stats(sample_arr.concat(addTimes));

    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (arr[i] === arr[j] && i !== j) {
            return false;
          }
        }
      }
      return true;
    }

    /* END VARIABLES AND CONSTANTS */
    if (allDifferent(sample_arr)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `The times, in seconds, taken by $${n}$ athletes to run $100$m are as follows:`
      );
      question.addParagraph(`question`, `$${sample_arr}$`);
      question.addParagraph(
        `question`,
        `a) Calculate the mean and standard deviation of these times, giving your answers to two decimal places where appropriate.`
      );
      question.addParagraph(
        `question`,
        `In fact, $${
          n + extra
        }$ athletes ran the $100$m. However, $${extra}$ failed to complete it in under $15$ seconds.`
      );
      question.addParagraph(
        `question`,
        `For all $${n + extra}$ athletes that had their times measured:`
      );
      question.addParagraph(`question`, `b) Find the median time.`);
      if (range) {
        question.addParagraph(
          `question`,
          `c) Explain why the range is not an appropriate measure in this case.`
        );
      } else {
        question.addParagraph(
          `question`,
          `c) Explain why the mode is not an appropriate measure in this case.`
        );
      }
      // solution
      // a
      question.addHeading(
        `solution`,
        `a) Calculate the mean and standard deviation of these times.`
      );
      question.addParagraph(
        "solution",
        `$\\overline{X}=\\frac{${sample.sx().toFixed(1)}}{${n}}$`
      );
      if (mean !== parseFloat(mean.toFixed(2))) {
        question.addParagraph("solution", `$=${mean.toFixed(4)}$`);
        question.addParagraph("solution", `$\\approx ${mean.toFixed(2)}$`);
      } else {
        question.addParagraph("solution", `$=${mean.toFixed(2)}$`);
      }
      question.addParagraph(
        "solution",
        `$\\sigma=\\sqrt{\\frac{\\sum{x^2}}{n}-\\overline{X}^2}$`
      );
      question.addParagraph(
        "solution",
        `$=\\sqrt{\\frac{${sample.sx2().toFixed(2)}}{${n}}-(${mean.toFixed(
          2
        )})^2}$`
      );
      question.addParagraph("solution", `$\\approx ${sd.toFixed(2)}$`);
      // b
      question.addHeading(`solution`, `b) Find the median time.`);
      question.addHeading(
        `solution`,
        `Let the $${extra}$ extra times $=${ts}$`
      );
      question.addParagraph(`solution`, `$${sample_arr.sort().concat(ts)}$`);
      if ((n + extra) % 2 === 0) {
        question.addParagraph(
          `solution`,
          `median = $\\frac{1}{2}(${newSample.dataX.sort()[(n + extra) / 2]}+${
            newSample.dataX.sort()[(n + extra) / 2 - 1]
          })$`
        );
      }
      question.addParagraph(
        `solution`,
        `$\\therefore$ median $= ${newSample.median().toFixed(2)}$`
      );
      // c
      if (range) {
        question.addHeading(
          `solution`,
          `c) Explain why the range can't be found in this case.`
        );
        question.addParagraph(
          `solution`,
          `The maximum value/slowest time is unknown`
        );
      } else {
        question.addHeading(
          `solution`,
          `c) Explain why the mode is not an appropriate measure in this case.`
        );
        question.addParagraph(
          `solution`,
          `Mode does not exist unless it is greater than 15.`
        );
        question.addParagraph(
          `solution`,
          `There are too many different values as the data is continuous.`
        );
      }
      //
      question.requiresSelfMarking();
      //
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        parseFloat(mean.toFixed(2)),
        0.1,
        `a) $\\ \\overline{X}=$`
      );
      group1.addInput(parseFloat(sd.toFixed(2)), 0.1, `a) $\\ \\sigma=$`);
      question.addInputGroup(group1);

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(parseFloat(newSample.median().toFixed(2)), 0.1, `b)`);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Averages_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const max = MCRandom.randomInt(5, 10);
    const sample = MCMaths.Stats.randomSample(max, 5, 25);
    const sample_arr = sample.dataX;

    const actualSample = [];
    for (let i = 0; i < max; i += 1) {
      for (let j = 0; j < sample_arr[i]; j += 1) {
        actualSample.push(i);
      }
    }
    const actualSample_stat = new MCMaths.Stats(actualSample);

    let total = 0;
    for (let i = 0; i < max; i += 1) {
      total += sample_arr[i];
    }

    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (arr[i] === arr[j] && i !== j) {
            return false;
          }
        }
      }
      return true;
    }

    /* END VARIABLES AND CONSTANTS */
    if (allDifferent(sample_arr)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A school collects data on the number of maths papers completed by each of their $${total}$ students over the summer holidays, up to a maximum of $${
          max - 1
        }$ papers.`
      );
      question.addParagraph(
        "question",
        `The data is summarised in the table below.`
      );
      const table = new MCQuestion.Table(`Papers Completed`, "Frequency");
      for (let i = 0; i < max; i += 1) {
        table.addRow(`$${i}$`, `$${sample_arr[i]}$`);
      }
      question.addTable("question", table);
      question.addParagraph(
        "question",
        `a) State the values for the mode, median and range of the data.`
      );
      question.addParagraph(
        "question",
        `b) Find the quartiles for the data set.`
      );
      question.addParagraph(
        "question",
        `c) Hence, state the interquartile range.`
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) State the values for the mode, median and range of the data.`
      );
      question.addParagraph(
        "solution",
        `Mode $=${actualSample_stat.mode()}$, as it's the largest frequency in the table.`
      );
      // median
      if (total % 2 === 1) {
        question.addParagraph(
          `solution`,
          `Median value is data point $${
            (total + 1) / 2
          }$ in an ordered list of papers completed.`
        );
      } else {
        question.addParagraph(
          `solution`,
          `Median value is mean of data points $${total / 2}$ and $${
            total / 2 + 1
          }$ in an ordered list of papers completed.`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ Median $=${actualSample_stat.median()}$`
      );
      question.addParagraph(
        "solution",
        `Maximum number of papers completed is $${max - 1}$ and minimum is $0$.`
      );
      question.addParagraph("solution", `$\\therefore$ Range $=${max - 1}$`);
      // b
      question.addHeading(
        "solution",
        `b) Find the quartiles for the data set.`
      );
      if (total % 2 === 1 && (total + 1) % 4 === 0) {
        question.addParagraph(
          `solution`,
          `$Q1$ is data point $${
            (total + 1) / 4
          }$ in  an ordered list of papers completed and $Q3$ is data point $${
            (3 * (total + 1)) / 4
          }$`
        );
      } else if (total % 2 === 1 && (total - 1) % 4 === 0) {
        question.addParagraph(
          `solution`,
          `$Q1$ is the mean of data points $${(total - 1) / 4}$ and $${
            (total - 1) / 4 + 1
          }$ in  an ordered list of papers completed and $Q3$ is the mean of data points $${
            (3 * (total - 1)) / 4 + 1
          }$ and $${(3 * (total - 1)) / 4 + 2}$`
        );
      } else if (total % 4 === 0) {
        question.addParagraph(
          "solution",
          `$Q1$ is the mean of data points $${total / 4}$ and $${
            total / 4 + 1
          }$ in  an ordered list of papers completed and $Q3$ is the mean of data points $${
            (3 * total) / 4 + 1
          }$ and $${(3 * total) / 4 + 2}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$Q1$ is data point $${
            (total + 2) / 4
          }$ in  an ordered list of papers completed and $Q3$ is data point $${
            (3 * (total + 2)) / 4 - 1
          }$`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore Q1=${actualSample_stat.q1()}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore Q3=${actualSample_stat.q3()}$`
      );
      // c
      question.addHeading(
        "solution",
        `c) Hence, state the interquartile range.`
      );
      question.addParagraph("solution", `IQR$=Q3-Q1$`);
      question.addParagraph(
        "solution",
        `$=${actualSample_stat.q3()}-${actualSample_stat.q1()}$`
      );
      question.addParagraph("solution", `$=${actualSample_stat.iqr()}$`);

      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput(actualSample_stat.mode(), 0, `a) mode=`);
      group1.addInput(actualSample_stat.median(), 0, `a) median=`);
      group1.addInput(max - 1, 0, `a) range=`);
      question.addInputGroup(group1);

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(actualSample_stat.q1(), 0, `b) $\\ Q1=$`);
      group2.addInput(actualSample_stat.q3(), 0, `b) $\\ Q3=$`);
      question.addInputGroup(group2);

      const group3 = new MCQuestion.InputGroup(1);
      group3.addInput(actualSample_stat.iqr(), 0, `c)`);
      question.addInputGroup(group3);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Averages_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = MCRandom.randomInt(50, 150, 10);
    const sample = MCMaths.Stats.randomSample(n, 35, 140, 10);
    const f1 = [true, false][MCRandom.randomInt(0, 1)]; // f1 is sum of x and sum of x2, if false use (x-x bar)^2

    let xm = 0;
    const data = sample.dataX;
    for (let i = 0; i < data.length; i += 1) {
      xm += (data[i] - sample.mean()) ** 2;
    }
    const extra_n = MCRandom.randomInt(50, 150, 10);
    const extraData = MCMaths.Stats.randomSample(extra_n, 35, 140, 10);

    const meanL = sample.mean();

    const meanNew = (sample.sx() + extraData.sx()) / (n + extra_n);

    const sd = Math.sqrt(xm / n);
    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(meanNew - meanL) > 0.1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A study of $${n}$ people, found that the amount, $L$ litres of water each person consumes weekly gives the following results:`
      );
      if (f1) {
        question.addParagraph(
          `question`,
          `$\\displaystyle\\sum x=${sample
            .sx()
            .toFixed(1)}$ and $\\displaystyle\\sum x^2=${sample
            .sx2()
            .toFixed(2)}$`
        );
      } else {
        question.addParagraph(
          `question`,
          `$\\displaystyle\\sum x=${sample
            .sx()
            .toFixed(
              1
            )}$ and $\\displaystyle\\sum (x-\\overline{x})^2=${xm.toFixed(2)}$`
        );
      }
      question.addParagraph(
        "question",
        `Give your answers to two decimal places unless otherwise stated.`
      );
      question.addParagraph("question", `a) Calculate the mean of $L$.`);
      question.addParagraph(
        "question",
        `b) Calculate the standard deviation of $L$.`
      );
      question.addParagraph(
        "question",
        `As a follow up to the study, $${extra_n}$ new people are surveyed and, for the new participants, researchers find that:`
      );
      question.addParagraph(
        "question",
        `$\\displaystyle\\sum x=${extraData.sx().toFixed(1)}$`
      );
      question.addParagraph(
        "question",
        `c) Find the mean amount of water consumed per week for the full set people studied in both the initial, and follow up study.`
      );
      // solution
      // a
      question.addHeading("solution", `a) Calculate the mean of $L$.`);
      question.addParagraph(
        "solution",
        `$\\overline{X}=\\frac{${sample.sx().toFixed(1)}}{${n}}$`
      );
      if (meanL !== parseFloat(meanL.toFixed(2))) {
        question.addParagraph("solution", `$=${meanL.toFixed(4)}$`);
        question.addParagraph("solution", `$\\approx ${meanL.toFixed(2)}$`);
      } else {
        question.addParagraph("solution", `$=${meanL.toFixed(2)}$`);
      }
      // b
      question.addHeading(
        "solution",
        `b) Calculate the standard deviation of $L$.`
      );
      if (f1) {
        question.addParagraph(
          "solution",
          `$\\sigma=\\sqrt{\\frac{\\sum{x^2}}{n}-\\overline{X}^2}$`
        );
        question.addParagraph(
          "solution",
          `$=\\sqrt{\\frac{${sample.sx2().toFixed(2)}}{${n}}-(${meanL.toFixed(
            2
          )})^2}$`
        );
        question.addParagraph(
          "solution",
          `$=${sample.standardDeviation().toFixed(2)}$`
        );
        question.addParagraph(
          "solution",
          `$\\approx ${sample.standardDeviation().toFixed(2)}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\sigma=\\sqrt{\\frac{\\sum{(x-\\overline{X})^2}}{n}}$`
        );
        question.addParagraph(
          "solution",
          `$\\sigma=\\sqrt{\\frac{${xm.toFixed(2)}}{${n}}}$`
        );
        question.addParagraph("solution", `$=${sample.standardDeviation()}$`);
        question.addParagraph(
          "solution",
          `$\\approx ${sample.standardDeviation().toFixed(2)}$`
        );
      }
      // c
      question.addHeading(
        "solution",
        `c) Find the mean amount of water consumed per week for the full set people studied in both the initial, and follow up study.`
      );
      question.addParagraph("solution", `$n=${n}+${extra_n}$`);
      question.addParagraph("solution", `$=${n + extra_n}$`);
      question.addParagraph(
        "solution",
        `$\\overline{X}=\\frac{${sample
          .sx()
          .toFixed(1)}+${extraData.sx().toFixed(1)}}{${n + extra_n}}$`
      );
      if (meanNew !== parseFloat(meanNew.toFixed(2))) {
        question.addParagraph("solution", `$=${meanNew.toFixed(4)}$`);
        question.addParagraph("solution", `$\\approx ${meanNew.toFixed(2)}$`);
      } else {
        question.addParagraph("solution", `$=${meanNew.toFixed(2)}$`);
      }
      //
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(meanL.toFixed(2)),
        0.1,
        `a) $\\ \\overline{X}=$`
      );
      if (f1) {
        group1.addInput(
          parseFloat(sample.standardDeviation().toFixed(2)),
          0.1,
          `b) $\\ \\sigma=$`
        );
      } else {
        group1.addInput(parseFloat(sd.toFixed(2)), 0.1, `b) $\\ \\sigma=$`);
      }
      group1.addInput(
        parseFloat(meanNew.toFixed(2)),
        0.1,
        `c) $\\ \\overline{X}=$`
      );
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Averages_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const groups = [
      "12\\leq a<18",
      `18\\leq a<25`,
      `25\\leq a<35`,
      `35\\leq a \\leq 45`,
    ];
    const sample = MCMaths.Stats.randomSample(groups.length, 10, 40);
    const sample_arr = sample.dataX;

    let total = 0;
    for (let i = 0; i < groups.length; i += 1) {
      total += sample_arr[i];
    }

    const mean =
      (15 * sample_arr[0] +
        21.5 * sample_arr[1] +
        30 * sample_arr[2] +
        40 * sample_arr[3]) /
      total;
    const sd = Math.sqrt(
      (sample_arr[0] * (15 - mean) ** 2 +
        sample_arr[1] * (21.5 - mean) ** 2 +
        sample_arr[2] * (30 - mean) ** 2 +
        sample_arr[3] * (40 - mean) ** 2) /
        total
    );
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const table = new MCQuestion.Table(`Age`, "Frequency");
      for (let i = 0; i < groups.length; i += 1) {
        table.addRow(`$${groups[i]}$`, `$${sample_arr[i]}$`);
      }
      question.addTable("question", table);
      question.addParagraph(
        `question`,
        `The table above shows information about the age of attendees at an event. Nobody under $12$ or over $45$ or above attended.`
      );
      question.addParagraph(
        `question`,
        `You should assume that the data is continuous within the class intervals.`
      );
      question.addParagraph(
        `question`,
        `a) Estimate the mean and standard deviation for the ages of attendees, giving your answers to two decimal places.`
      );
      question.addParagraph(
        `question`,
        `b) Explain why the values you calculated in part a are only estimates.`
      );
      // solution
      // a
      question.addHeading(
        `solution`,
        `a) Estimate the mean and standard deviation for the ages of attendees, giving your answers to two decimal places.`
      );
      question.addParagraph(`solution`, `$n=${sample_arr.join(`+`)}$`);
      question.addParagraph(`solution`, `$=${total}$`);
      question.addHeading(
        "solution",
        `Finding the midpoints of the class intervals`
      );
      question.addParagraph("solution", `$12\\leq a<18$`);
      question.addParagraph("solution", `$\\frac{1}{2}(12+18)=15$`);
      question.addParagraph("solution", `$18\\leq a<25$`);
      question.addParagraph("solution", `$\\frac{1}{2}(18+25)=\\frac{43}{2}$`);
      question.addParagraph("solution", `$25\\leq a<35$`);
      question.addParagraph("solution", `$\\frac{1}{2}(25+35)=30$`);
      question.addParagraph("solution", `$35\\leq a\\leq 45$`);
      question.addParagraph("solution", `$\\frac{1}{2}(35+45)=40$`);

      question.addHeading("solution", `Estimating mean`);
      question.addParagraph(
        "solution",
        `$\\overline{X}=\\frac{15(${sample_arr[0]}) + \\frac{43}{2}(${sample_arr[1]}) + 30(${sample_arr[2]}) + 40(${sample_arr[3]}) }{${total}}$`
      );
      question.addParagraph("solution", `$=${mean}$`);
      question.addParagraph("solution", `$\\approx ${mean.toFixed(2)}$`);
      question.addHeading("solution", `Estimating standard deviation`);
      question.addParagraph(
        "solution",
        `$\\sigma=\\sqrt{\\frac{\\sum{(x-\\overline{X})^2}}{n}}$`
      );
      question.addParagraph(
        "solution",
        `$=\\sqrt{\\frac{{${sample_arr[0]}(15-${mean.toFixed(2)})^2 + ${
          sample_arr[1]
        }(\\frac{43}{2}-${mean.toFixed(2)})^2 + ${
          sample_arr[2]
        }(30-${mean.toFixed(2)})^2 + ${sample_arr[3]}(40-${mean.toFixed(
          2
        )})^2}}{${total}}}$`
      );
      question.addParagraph("solution", `$=${sd}$`);
      question.addParagraph("solution", `$\\approx ${sd.toFixed(2)}$`);
      // b
      question.addHeading(
        `solution`,
        `b) Explain why the values you calculated in part a are only estimates.`
      );
      question.addParagraph(
        `solution`,
        `Data is recorded in class intervals so exact values are not known so you can't calculate the actual mean or standard deviation.`
      );

      //
      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        parseFloat(mean.toFixed(2)),
        0.1,
        `a) $\\ \\overline{X}=$`
      );
      group1.addInput(parseFloat(sd.toFixed(2)), 0.1, `a) $\\ \\sigma =$`);
      question.addInputGroup(group1);
      //
      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Averages_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = MCRandom.randomInt(5, 10);
    const sample = MCMaths.Stats.randomSample(n, 2, 10);

    const a = sample.standardDeviation();
    const b = Math.sqrt(sample.sx2() / n);
    const c = sample.standardDeviation() ** 2;
    const d = Math.sqrt(sample.sx() ** 2 / n - sample.mean() ** 2);

    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (arr[i] === arr[j] && i !== j) {
            return false;
          }
        }
      }
      return true;
    }

    const checkArr = [a.toFixed(1), b.toFixed(1), c.toFixed(1), d.toFixed(1)];

    /* END VARIABLES AND CONSTANTS */
    if (allDifferent(checkArr)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(4);
      question.addParagraph(
        `Given that $\\displaystyle\\sum x=${sample.sx()},\\displaystyle\\sum x^2=${sample.sx2()},n=${n}$, find $\\sigma$, the standard deviation of $X$.`
      );
      // question.addParagraph(`$\\sigma=\\sqrt{\\frac{\\sum{x^2}}{n}-\\overline{X}^2}$`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`$${checkArr[0]}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`$${checkArr[1]}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`$${checkArr[2]}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${checkArr[3]}$`);

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Averages_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (arr[i] === arr[j] && i !== j) {
            return false;
          }
        }
      }
      return true;
    }

    const n = MCRandom.randomInt(6, 12, 2);
    const sample = MCMaths.Stats.randomSample(n, 2, 15);
    const pure_sample = [].concat(sample.dataX);

    const b = pure_sample[n / 2 - 1];
    const c = pure_sample[n / 2];
    const a = sample.median();

    const sample_sorted = sample.dataX.sort((a1, b1) => {
      return a1 - b1;
    });
    const d = sample_sorted[n / 2 - 1];

    const checkArr = [a, b, c, d];
    /* END VARIABLES AND CONSTANTS */
    if (allDifferent(checkArr)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `The scores in a quiz between $${n}$ teams are as follows:`
      );
      question.addParagraph(`$${pure_sample}$`);
      question.addParagraph(`Find the median of these test scores`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`$${checkArr[0]}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`$${checkArr[1]}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`$${checkArr[2]}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${checkArr[3]}$`);

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Averages_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function allDifferentPositive(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if ((arr[i] === arr[j] && i !== j) || arr[i] < 0) {
            return false;
          }
        }
      }
      return true;
    }

    const n = MCRandom.randomInt(6, 12, 2);
    const sample = MCMaths.Stats.randomSample(n, 2, 15);
    const pure_sample = [].concat(sample.dataX);

    const b = pure_sample[n - 1] - pure_sample[0];

    const sample_sorted = sample.dataX.sort((a, b1) => {
      return a - b1;
    });
    const a = sample_sorted[n - 1] - sample_sorted[0];
    const c = sample_sorted[n - MCRandom.randomInt(2, 3)] - sample_sorted[0];
    const d = sample_sorted[n - 1] - sample_sorted[MCRandom.randomInt(1, 3)];

    const checkArr = [a, b, c, d];
    /* END VARIABLES AND CONSTANTS */
    if (allDifferentPositive(checkArr)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `The scores in a quiz between $${n}$ teams are as follows:`
      );
      question.addParagraph(`$${pure_sample}$`);
      question.addParagraph(`Find the range of these test scores`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`$${checkArr[0]}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`$${checkArr[1]}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`$${checkArr[2]}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${checkArr[3]}$`);

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Coding_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const groups = [
      `15,000\\leq x<20,000`,
      `20,000\\leq x<30,000`,
      `30,000\\leq x<45,000`,
      `45,000\\leq x<60,000`,
      `60,000\\leq x<70,000`,
    ];
    const freqs = [
      MCRandom.randomInt(5, 15),
      MCRandom.randomInt(10, 20),
      MCRandom.randomInt(15, 25),
      MCRandom.randomInt(10, 20),
      MCRandom.randomInt(5, 15),
    ];
    const n = freqs.reduce((a, b) => a + b, 0);

    const midpoints = [17500, 25000, 37500, 52500, 65000];

    const divider = MCRandom.randomInt(500, 2500, 500);
    const subtracter = MCRandom.randomInt(1000, 10000, 1000);

    function encode(value) {
      return (value - subtracter) / divider;
    }

    const tmp_encoded = [];
    for (let i = 0; i < midpoints.length; i += 1) {
      const tmp_encoding = encode(midpoints[i]);
      if (parseFloat(tmp_encoding.toFixed(2)) !== tmp_encoding) {
        tmp_encoded.push(parseFloat(tmp_encoding.toFixed(2)));
      } else {
        tmp_encoded.push(tmp_encoding);
      }
    }
    const encodedMidpoints = [...tmp_encoded];

    let tmp_encodedMean = 0;
    for (let i = 0; i < encodedMidpoints.length; i += 1) {
      tmp_encodedMean += freqs[i] * encodedMidpoints[i];
    }
    tmp_encodedMean /= n;
    const encodedMean = tmp_encodedMean;

    function decode(value) {
      return value * divider + subtracter;
    }

    const decodedMean = decode(parseFloat(encodedMean.toFixed(5)));

    let tmp_sx2 = 0;
    for (let i = 0; i < freqs.length; i += 1) {
      tmp_sx2 += freqs[i] * encodedMidpoints[i] ** 2;
    }
    const sx2 = tmp_sx2;

    const encodedSD = Math.sqrt(
      sx2 / n - parseFloat(encodedMean.toFixed(5)) ** 2
    );
    const decodedSD = parseFloat(encodedSD.toFixed(5)) * divider;

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `The salaries, in pounds, of $${n}$ random people living in England are summarised in the table below.`
      );

      const table = new MCQuestion.Table(`Salaries ($\\ S\\ $)`, `Frequency`);
      for (let i = 0; i < freqs.length; i += 1) {
        table.addRow(`$${groups[i]}$`, `$${freqs[i]}$`);
      }
      question.addTable("question", table);

      question.addParagraph(
        "question",
        `By using the coding $y=\\displaystyle\\frac{x-${subtracter}}{${divider}}$, where $x$ represents the original salaries.`
      );
      question.addParagraph(
        "question",
        `a) Estimate the mean salary of the sample.`
      );
      question.addParagraph(
        "question",
        `b) Estimate the standard deviation of the sample.`
      );

      // solution
      // a

      question.addHeading("solution", `a) Calculate midpoints.`);

      const table2 = new MCQuestion.Table(
        `Salaries ($\\ S\\ $)`,
        `Frequency`,
        `Midpoint`
      );
      for (let i = 0; i < freqs.length; i += 1) {
        table2.addRow(`$${groups[i]}$`, `$${freqs[i]}$`, `$${midpoints[i]}$`);
      }
      question.addTable("solution", table2);

      question.addHeading(
        "solution",
        `Calculate coded midpoints using the formula, $y=\\displaystyle\\frac{x-${subtracter}}{${divider}}$`
      );

      const table3 = new MCQuestion.Table(`Midpoint`, `Coded Midpoint (2.d.p)`);
      for (let i = 0; i < freqs.length; i += 1) {
        table3.addRow(`$${midpoints[i]}$`, `$${encodedMidpoints[i]}$`);
      }
      question.addTable("solution", table3);

      question.addHeading(
        "solution",
        `Calculate sample mean using coded values of the midpoints.`
      );
      question.addParagraph(
        "solution",
        `$\\overline{y}=\\displaystyle\\frac{\\sum{fy}}{n}$`
      );
      question.addParagraph(
        "solution",
        `$\\overline{y}=\\displaystyle\\frac{${freqs[0]}(${encodedMidpoints[0]})+${freqs[1]}(${encodedMidpoints[1]})+${freqs[2]}(${encodedMidpoints[2]})+${freqs[3]}(${encodedMidpoints[3]})+${freqs[4]}(${encodedMidpoints[4]})}{${n}}$`
      );
      if (parseFloat(encodedMean.toFixed(5)) !== encodedMean) {
        question.addParagraph("solution", `$=${encodedMean.toFixed(8)}$`);
        question.addParagraph(
          "solution",
          `$\\approx ${encodedMean.toFixed(5)}$`
        );
      } else {
        question.addParagraph("solution", `$=${encodedMean}$`);
      }
      question.addHeading("solution", `Decode mean.`);
      question.addParagraph(
        "solution",
        `$\\overline{y}=\\displaystyle\\frac{\\overline{x}-${subtracter}}{${divider}}$`
      );
      if (parseFloat(encodedMean.toFixed(5)) !== encodedMean) {
        question.addParagraph(
          "solution",
          `$${encodedMean.toFixed(
            5
          )}=\\displaystyle\\frac{\\overline{x}-${subtracter}}{${divider}}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$${encodedMean}=\\frac{\\overline{x}-${subtracter}}{${divider}}$`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore \\overline{x}=${decodedMean.toFixed(2)}$`
      );

      // b
      question.addHeading("solution", `b) Calculating standard deviation.`);
      question.addParagraph(
        "solution",
        `$\\sigma_y = \\sqrt{\\displaystyle\\frac{\\sum{fy^2}}{n}-(\\overline{y}})^2$`
      );
      question.addParagraph(
        "solution",
        `$\\sigma_y = \\sqrt{\\displaystyle\\frac{${freqs[0]}(${
          encodedMidpoints[0]
        }^2)+${freqs[1]}(${encodedMidpoints[1]}^2)+${freqs[2]}(${
          encodedMidpoints[2]
        }^2)+${freqs[3]}(${encodedMidpoints[3]}^2)+${freqs[4]}(${
          encodedMidpoints[4]
        }^2)}{${n}}-(${encodedMean.toFixed(5)})^2}$`
      );

      question.addParagraph(
        "solution",
        `$=${parseFloat(encodedSD).toFixed(8)}$`
      );
      question.addParagraph("solution", `$\\approx ${encodedSD.toFixed(5)}$`);

      question.addHeading("solution", `Decode standard deviation.`);
      question.addParagraph(
        "solution",
        `$\\sigma_y=\\displaystyle\\frac{\\sigma_x}{${divider}}$, as standard deviation is not affected by addition/subtraction from coding.`
      );
      question.addParagraph(
        "solution",
        `$${encodedSD.toFixed(5)}=\\displaystyle\\frac{\\sigma_x}{${divider}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sigma_x=${decodedSD.toFixed(2)}$`
      );

      // answers

      const a_input = new MCQuestion.InputGroup(1);
      a_input.addInput(decodedMean, 2500, `a) $\\ \\overline{x}=$`);
      question.addInputGroup(a_input);

      const b_input = new MCQuestion.InputGroup(1);
      b_input.addInput(decodedSD, 1000, `b) $\\ \\sigma_x=$`);
      question.addInputGroup(b_input);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Coding_Exam_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = MCRandom.randomInt(5, 20);
    const sample = MCMaths.Stats.randomSample(n, 25, 75);

    const multiplier = parseFloat(
      (MCRandom.randomInt(20, 50) / 100).toFixed(2)
    );
    const adder = MCRandom.randomInt(5, 20);

    function encode(value) {
      return multiplier * value + adder;
    }

    const tmp_encoded = [...sample.dataX];
    for (let i = 0; i < n; i += 1) {
      tmp_encoded[i] = encode(tmp_encoded[i]);
    }

    const encodedSample = new MCMaths.Stats([...tmp_encoded]);

    const sy2 = encodedSample.sx2();
    const sy = encodedSample.sx();
    const y_bar = encodedSample.mean();
    const x_bar = (parseFloat(y_bar.toFixed(2)) + adder) / multiplier;

    const x_sigma = sample.standardDeviation();
    const y_sigma = encodedSample.standardDeviation();

    const n_calculate =
      sy2 / (parseFloat(y_sigma.toFixed(2)) ** 2 + y_bar ** 2);

    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(sy.toFixed(2)) === sy &&
      parseFloat(sy2.toFixed(2)) === sy2 &&
      sy2 < 5000
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `A sutdy asks $${n}$ participants to estimate the number of passengers they see on a train in motion, $x$, shown in a short video.`
      );
      question.addParagraph(
        `question`,
        MCMaths.cleaner(
          `The estimates are coded to give a score, $y$ using the formula, $y=ax+${adder}$, where $a$ is a constant.`
        )
      );
      question.addParagraph(
        `question`,
        `The summary statistics below were calculated:`
      );
      question.addParagraph(
        `question`,
        `$\\displaystyle\\sum_{r=1}^{${n}}{y_r}=${sy}$`
      );
      question.addParagraph(
        `question`,
        `$\\displaystyle\\sum_{r=1}^{${n}}{y_r^2}=${sy2}$`
      );

      question.addParagraph(
        "question",
        `It is also given that $\\sigma_x=${parseFloat(
          x_sigma.toFixed(2)
        )}$, where $\\sigma_x$ is the standard deviation for the number of passengers counted.`
      );

      question.addParagraph(
        `question`,
        `Find the mean of the number of passengers the study subjects counted on the train.`
      );
      // solution
      question.addHeading(
        `solution`,
        MCMaths.cleaner(
          `First calculate the mean score, given by the coding $y=ax+${adder}$`
        )
      );
      question.addParagraph(
        `solution`,
        `$\\overline{y} = \\displaystyle\\frac{\\sum{y}}{n}$`
      );
      question.addParagraph(
        `solution`,
        `$= \\displaystyle\\frac{${sy}}{${n}}$`
      );
      if (parseFloat(y_bar.toFixed(2)) !== y_bar) {
        question.addParagraph(`solution`, `$=${parseFloat(y_bar.toFixed(5))}$`);
        question.addParagraph(`solution`, `$\\approx ${y_bar.toFixed(2)}$`);
      } else {
        question.addParagraph(`solution`, `$=${y_bar}$`);
      }

      question.addHeading(
        `solution`,
        `Use this to calculate the standard deviation for the score, given by the coding.`
      );
      question.addParagraph(
        "solution",
        `$\\sigma_y = \\sqrt{\\frac{\\sum{y^2}}{n}-(\\overline{y}})^2$`
      );
      question.addParagraph(
        "solution",
        `$= \\sqrt{\\frac{${sy2}}{${n}}-(${parseFloat(y_bar.toFixed(2))}})^2$`
      );
      question.addParagraph(
        `solution`,
        `$\\approx ${parseFloat(y_sigma.toFixed(2))}$`
      );
      question.addHeading(
        "solution",
        `$\\sigma_y = a \\times \\sigma_x$, as standard deviation is not affected by addition/subtraction from coding.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore a=\\frac{\\sigma_y}{\\sigma_x}$`
      );
      question.addParagraph(
        "solution",
        `$=\\frac{${parseFloat(y_sigma.toFixed(2))}}{${parseFloat(
          x_sigma.toFixed(2)
        )}}$`
      );
      question.addParagraph("solution", `$\\approx ${multiplier}$`);

      question.addHeading(`solution`, `Calculate $\\overline{x}$`);
      question.addParagraph(
        `solution`,
        `$\\overline{y}=${multiplier}\\overline{x}+${adder}$`
      );
      question.addParagraph(
        `solution`,
        `$${parseFloat(y_bar.toFixed(2))}=${multiplier}\\overline{x}+${adder}$`
      );
      question.addParagraph(
        `solution`,
        `$\\therefore \\overline{x} \\approx ${parseFloat(x_bar.toFixed(2))}$`
      );

      // answers
      const a_input = new MCQuestion.InputGroup(1);
      a_input.addInput(parseFloat(x_bar.toFixed(2)), 0.5, `$\\overline{x}=$`);
      question.addInputGroup(a_input);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Coding_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const divider = MCRandom.randomInt(100, 500, 10);
    const subtractor = MCRandom.randomInt(25, 100, 5);

    function encode(value) {
      return (value - subtractor) / divider;
    }
    function decode(value) {
      return value * divider + subtractor;
    }

    const tmp_decodedSample = [];
    const tmp_encodedSample = [];

    const n = MCRandom.randomInt(5, 20);

    for (let i = 0; i < n; i += 1) {
      tmp_decodedSample.push(MCRandom.randomInt(400, 1000, 10));
      tmp_encodedSample.push(encode(tmp_decodedSample[i]));
    }
    const decodedSample = [...tmp_decodedSample];
    const encodedSample = [...tmp_encodedSample];

    const statsDecoded = new MCMaths.Stats(decodedSample);
    const statsEncoded = new MCMaths.Stats(encodedSample);

    const decodedMean = statsDecoded.mean();
    const encodedMean = statsEncoded.mean();

    const decodedSD = statsDecoded.standardDeviation();
    const encodedSD = statsEncoded.standardDeviation();

    const sy = statsEncoded.sx();

    const sy2 = statsEncoded.sx2();

    function correctTo2dp(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (parseFloat(arr[i].toFixed(2)) !== arr[i]) {
          return false;
        }
      }
      return true;
    }
    const check2dp = [sy, sy2, encodedMean, decodedMean];

    /* END VARIABLES AND CONSTANTS */
    if (correctTo2dp(check2dp)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `The masses, $x$ kg, of $${n}$ horses at a stables were measured. The results are summarised below.`
      );
      question.addParagraph(
        "question",
        `$\\displaystyle\\sum^{${n}}_{n=1}{\\frac{x_{n}-${subtractor}}{${divider}}}=${sy}$`
      );
      question.addParagraph(
        "question",
        `$\\displaystyle\\sum^{${n}}_{n=1}{\\left(\\frac{x_{n}-${subtractor}}{${divider}}\\right)^2}=${sy2}$`
      );

      question.addParagraph(
        `question`,
        `Calculate the mean and standard deviation for the masses of these $${n}$ horses.`
      );

      // solution
      // mean
      question.addHeading(
        "solution",
        `The masses of the horses are coded using the formula;`
      );
      question.addParagraph(
        "solution",
        `$y = \\displaystyle\\frac{x-${subtractor}}{${divider}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\displaystyle\\sum^{${n}}_{n=1}{y}=${sy}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\overline{y}=\\displaystyle\\frac{${sy}}{${n}}$`
      );
      question.addParagraph("solution", `$=${encodedMean}$`);

      question.addHeading("solution", `Decode mean.`);
      question.addParagraph(
        "solution",
        `$\\overline{y}=\\displaystyle\\frac{\\overline{x}-${subtractor}}{${divider}}$`
      );
      question.addParagraph(
        "solution",
        `$${encodedMean}=\\displaystyle\\frac{\\overline{x}-${subtractor}}{${divider}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\overline{x}=${decodedMean}$`
      );
      // sd
      question.addHeading(`solution`, `Finding standard deviation.`);
      question.addParagraph(
        "solution",
        `$\\sigma_y = \\sqrt{\\displaystyle\\frac{\\sum{y^2}}{n}-(\\overline{y}})^2$`
      );
      question.addParagraph(
        "solution",
        `$\\sigma_y = \\sqrt{\\displaystyle\\frac{${sy2}}{${n}}-(${encodedMean})^2}$`
      );

      question.addParagraph(
        "solution",
        `$=${parseFloat(encodedSD.toFixed(8))}$`
      );
      question.addParagraph("solution", `$\\approx ${encodedSD.toFixed(5)}$`);

      question.addHeading("solution", `Decode standard deviation.`);
      question.addParagraph(
        "solution",
        `$\\sigma_y=\\displaystyle\\frac{\\sigma_x}{${divider}}$, as standard deviation is not affected by addition/subtraction from coding.`
      );
      question.addParagraph(
        "solution",
        `$${encodedSD.toFixed(5)}=\\displaystyle\\frac{\\sigma_x}{${divider}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sigma_x=${decodedSD.toFixed(2)}$`
      );

      // answers

      const input = new MCQuestion.InputGroup(2);
      input.addInput(decodedMean, 5, `$\\overline{x}=$`);
      input.addInput(decodedSD, 5, `$\\sigma_{x}=$`);
      question.addInputGroup(input);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Coding_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const n = MCRandom.randomInt(5, 8);
    const multiplier = new MCMaths.Fraction(MCRandom.randomInt(4, 10) / 2);
    const adder = MCRandom.randomInt(20, 40, 2) * MCRandom.randomInt(-1, 1, 2);

    function encode(value) {
      return multiplier.toFloat() * (value + adder);
    }
    function decode(value) {
      return value / multiplier.toFloat() - adder;
    }

    const statsDecoded = MCMaths.Stats.randomSample(n, 20, 50);
    const tmp_encodedSample = [...statsDecoded.dataX];
    for (let i = 0; i < tmp_encodedSample.length; i += 1) {
      tmp_encodedSample[i] = encode(tmp_encodedSample[i]);
    }
    const encodedSample = [...tmp_encodedSample];

    const orderedEncoded = [...encodedSample].sort(function (a, b) {
      return a - b;
    });
    const orderedDecoded = [...statsDecoded.dataX].sort(function (a, b) {
      return a - b;
    });

    const statsEncoded = new MCMaths.Stats(encodedSample);

    const encodedMedian = statsEncoded.median();
    const decodedMedian = statsDecoded.median();

    const decodedRange = orderedDecoded[n - 1] - orderedDecoded[0];

    const encodedMean = statsEncoded.mean();
    const decodedMean = statsDecoded.mean();

    function correctTo0dp(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (parseFloat(arr[i].toFixed(0)) !== arr[i]) {
          return false;
        }
      }
      return true;
    }
    const check0dp = [
      decodedMean,
      encodedMean,
      encodedMedian,
      decodedMedian,
      decodedRange,
    ];
    /* END VARIABLES AND CONSTANTS */
    if (correctTo0dp(encodedSample) && correctTo0dp(check0dp)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A team of scientists measure the size, $x$ mm, of $${n}$ ants of the same species.`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `They code their results using the formula, $y=${multiplier}(x+${adder})$, to give the coded sample:`
        )
      );
      question.addParagraph(`question`, `$${encodedSample}$`);

      question.addParagraph(
        "question",
        `a) State the median size of the $${n}$ ants.`
      );
      question.addParagraph("question", `b) Calculate the range of the sizes.`);
      question.addParagraph("question", `c) State the mean size of the ants.`);

      // solution
      // a
      question.addHeading("solution", `a) Order the coded sample.`);
      question.addParagraph("solution", `$${orderedEncoded}$`);
      if (n % 2 === 1) {
        question.addParagraph(
          "solution",
          `$\\therefore$ Coded median $=${encodedMedian}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore$ Coded median $=\\displaystyle\\frac{${
            orderedEncoded[n / 2]
          }+${orderedEncoded[n / 2 - 1]}}{2}$`
        );
        question.addParagraph("solution", `$=${encodedMedian}$`);
      }
      question.addHeading("solution", `Decode median.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${encodedMedian}=${multiplier}(x+${adder})$`)
      );
      question.addParagraph(
        "solution",
        ` $\\therefore$ median $=${decodedMedian}$`
      );

      // b
      question.addHeading(
        "solution",
        `b) Decode the coded maximum and minimum values.`
      );
      question.addHeading("solution", `Coded max $=${orderedEncoded[n - 1]}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${orderedEncoded[n - 1]}=${multiplier}(x+${adder})$`)
      );
      question.addParagraph(
        "solution",
        ` $\\therefore$ max $=${orderedDecoded[n - 1]}$`
      );

      question.addHeading("solution", `Coded min $=${orderedEncoded[0]}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${orderedEncoded[0]}=${multiplier}(x+${adder})$`)
      );
      question.addParagraph(
        "solution",
        ` $\\therefore$ min $=${orderedDecoded[0]}$`
      );
      question.addHeading("solution", ` Calculate range.`);
      question.addParagraph(
        "solution",
        `range $=${orderedDecoded[n - 1]}-${orderedDecoded[0]}$`
      );
      question.addParagraph("solution", `$=${decodedRange}$`);

      // c
      question.addHeading(
        "solution",
        `c) Coded mean $=\\displaystyle\\frac{\\sum{y}}{n}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\displaystyle\\frac{${encodedSample.join("+")}}{${n}}$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\overline{y}=${encodedMean}$`
      );
      question.addHeading("solution", `Decode mean.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\overline{y}=${multiplier}(x+${adder})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${encodedMean}=${multiplier}(x+${adder})$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\overline{x}=${decodedMean}$`
      );

      // answers

      const input = new MCQuestion.InputGroup(1);
      input.addInput(decodedMedian, 0, `a)`);
      input.addInput(decodedRange, 0, `b)`);
      input.addInput(decodedMean, 0, `c)`);
      question.addInputGroup(input);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Coding_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = MCRandom.randomInt(5, 20);
    const sample = MCMaths.Stats.randomSample(n, -10, 10);

    const multiplier = MCRandom.randomInt(2, 10);
    const adder = MCRandom.randomInt(5, 20) * MCRandom.randomInt(-1, 1, 2);

    function encode(value) {
      return multiplier * value + adder;
    }

    const tmp_encoded = [...sample.dataX];
    for (let i = 0; i < n; i += 1) {
      tmp_encoded[i] = encode(tmp_encoded[i]);
    }

    const encodedSample = new MCMaths.Stats([...tmp_encoded]);

    const sy2 = encodedSample.sx2();
    const x_bar = sample.mean();
    const y_bar = encodedSample.mean();

    const x_sigma = sample.standardDeviation();
    const y_sigma = encodedSample.standardDeviation();

    const a = parseFloat(y_sigma.toFixed(2)) / parseFloat(x_sigma.toFixed(2));

    const n_calculate =
      sy2 / (parseFloat(y_sigma.toFixed(2)) ** 2 + y_bar ** 2);
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(x_bar.toFixed(0)) === x_bar &&
      parseFloat(sy2.toFixed(0)) === sy2 &&
      a === multiplier &&
      sy2 < 5000 &&
      parseFloat(x_sigma.toFixed(0)) === x_sigma &&
      n_calculate === n
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `A sample of $n$ random numbers has a mean of $${x_bar}$ and a standard deviation of $${x_sigma}$`
      );
      question.addParagraph(
        `question`,
        `${MCMaths.cleaner(
          `When coded using the formula $y=ax+${adder}$ where $a$ is a positive constant, $x$ is the original value and $y$ is the coded value, the coded standard deviation is `
        )}$${y_sigma}$`
      );
      question.addParagraph(
        "question",
        `It is also given that $\\displaystyle\\sum^{n}_{r=1}{y_r^2}=${sy2}$`
      );

      question.addParagraph("question", `a) Find $a$.`);
      question.addParagraph("question", `b) Find $n$.`);

      // solution
      // a
      question.addHeading(
        "solution",
        `a) $\\sigma_y = a \\times \\sigma_x$, as standard deviation is not affected by addition/subtraction from coding.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore a=\\frac{\\sigma_y}{\\sigma_x}$`
      );
      question.addParagraph("solution", `$=\\frac{${y_sigma}}{${x_sigma}}$`);
      question.addParagraph("solution", `$=${a}$`);
      // b
      question.addHeading("solution", `b) Calculate coded mean.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\overline{y}=${multiplier}\\overline{x}+${adder}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${multiplier}(${x_bar})+${adder}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${y_bar}$`));
      question.addHeading(
        "solution",
        `$\\sigma_y = \\sqrt{\\frac{\\sum{y^2}}{n}-(\\overline{y}})^2$`
      );
      question.addHeading("solution", `Rearrange for $n$.`);
      question.addParagraph(
        "solution",
        `$n = \\displaystyle\\frac{\\sum{y^2}}{\\sigma_y^2+\\overline{y}^2}$`
      );
      question.addParagraph(
        "solution",
        `$n = \\displaystyle\\frac{${sy2}}{${y_sigma}^2+(${y_bar})^2}$`
      );
      question.addParagraph("solution", `$=${n_calculate}$`);

      // answers
      const a_input = new MCQuestion.InputGroup(1);
      a_input.addInput(a, 0, `a) $\\ a=$`);
      question.addInputGroup(a_input);

      const b_input = new MCQuestion.InputGroup(1);
      b_input.addInput(n_calculate, 0, `b) $\\ n=$`);
      question.addInputGroup(b_input);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Coding_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const groups = [
      `0\\leq x<1,000`,
      `1,000\\leq x<1,500`,
      `1,500\\leq x<2,500`,
      `2,500\\leq x<3,500`,
      `3,500\\leq x<5,000`,
    ];

    const freqs = [
      MCRandom.randomInt(4, 10),
      MCRandom.randomInt(8, 15),
      MCRandom.randomInt(15, 25),
      MCRandom.randomInt(8, 15),
      MCRandom.randomInt(4, 10),
    ];

    const n = freqs.reduce((a, b) => a + b, 0);

    const midpoints = [500, 1250, 2000, 3000, 4250];

    const divider = MCRandom.randomInt(100, 500, 100);
    const subtracter = MCRandom.randomInt(1000, 3000, 500);

    function encode(value) {
      return (value - subtracter) / divider;
    }
    function decode(value) {
      return value * divider + subtracter;
    }

    const tmp_encoded = [];
    for (let i = 0; i < midpoints.length; i += 1) {
      const tmp_encoding = encode(midpoints[i]);
      if (parseFloat(tmp_encoding.toFixed(2)) !== tmp_encoding) {
        tmp_encoded.push(parseFloat(tmp_encoding.toFixed(2)));
      } else {
        tmp_encoded.push(tmp_encoding);
      }
    }
    const encodedMidpoints = [...tmp_encoded];

    let tmp_encodedMean = 0;
    for (let i = 0; i < encodedMidpoints.length; i += 1) {
      tmp_encodedMean += freqs[i] * encodedMidpoints[i];
    }
    tmp_encodedMean /= n;
    const encodedMean = tmp_encodedMean;

    const decodedMean = decode(parseFloat(encodedMean.toFixed(5)));

    let tmp_sx2 = 0;
    for (let i = 0; i < freqs.length; i += 1) {
      tmp_sx2 += freqs[i] * encodedMidpoints[i] ** 2;
    }
    const sx2 = tmp_sx2;

    const encodedSD = Math.sqrt(
      sx2 / n - parseFloat(encodedMean.toFixed(5)) ** 2
    );
    const decodedSD = parseFloat(encodedSD.toFixed(5)) * divider;

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `The table below shows the money spent in pounds, by $${n}$ families of four, to go on their last holiday.`
      );

      const table = new MCQuestion.Table(`Cost ($\\ C\\ $)`, `Frequency`);
      for (let i = 0; i < freqs.length; i += 1) {
        table.addRow(`$${groups[i]}$`, `$${freqs[i]}$`);
      }
      question.addTable("question", table);

      question.addParagraph(
        "question",
        `By using the coding $y=\\displaystyle\\frac{x-${subtracter}}{${divider}}$, where $x$ represents the original costs.`
      );
      question.addParagraph(
        "question",
        `a) Estimate the mean salary of the sample.`
      );
      question.addParagraph(
        "question",
        `b) Estimate the standard deviation of the sample.`
      );
      // solution
      // a

      question.addHeading("solution", `a) Calculate midpoints.`);

      const table2 = new MCQuestion.Table(
        `Cost ($\\ C\\ $)`,
        `Frequency`,
        `Midpoint`
      );
      for (let i = 0; i < freqs.length; i += 1) {
        table2.addRow(`$${groups[i]}$`, `$${freqs[i]}$`, `$${midpoints[i]}$`);
      }
      question.addTable("solution", table2);

      question.addHeading(
        "solution",
        `Calculate coded midpoints using the formula, $y=\\displaystyle\\frac{x-${subtracter}}{${divider}}$`
      );

      const table3 = new MCQuestion.Table(`Midpoint`, `Coded Midpoint (2.d.p)`);
      for (let i = 0; i < freqs.length; i += 1) {
        table3.addRow(`$${midpoints[i]}$`, `$${encodedMidpoints[i]}$`);
      }
      question.addTable("solution", table3);

      question.addHeading(
        "solution",
        `Calculate sample mean using coded values of the midpoints.`
      );
      question.addParagraph(
        "solution",
        `$\\overline{y}=\\displaystyle\\frac{\\sum{fy}}{n}$`
      );
      question.addParagraph(
        "solution",
        `$\\overline{y}=\\displaystyle\\frac{${freqs[0]}(${encodedMidpoints[0]})+${freqs[1]}(${encodedMidpoints[1]})+${freqs[2]}(${encodedMidpoints[2]})+${freqs[3]}(${encodedMidpoints[3]})+${freqs[4]}(${encodedMidpoints[4]})}{${n}}$`
      );

      if (parseFloat(encodedMean.toFixed(5)) !== encodedMean) {
        question.addParagraph("solution", `$=${encodedMean.toFixed(8)}$`);
        question.addParagraph(
          "solution",
          `$\\approx ${encodedMean.toFixed(5)}$`
        );
      } else {
        question.addParagraph("solution", `$=${encodedMean}$`);
      }
      question.addHeading("solution", `Decode mean.`);
      question.addParagraph(
        "solution",
        `$\\overline{y}=\\displaystyle\\frac{\\overline{x}-${subtracter}}{${divider}}$`
      );
      if (parseFloat(encodedMean.toFixed(5)) !== encodedMean) {
        question.addParagraph(
          "solution",
          `$${encodedMean.toFixed(
            5
          )}=\\displaystyle\\frac{\\overline{x}-${subtracter}}{${divider}}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$${encodedMean}=\\frac{\\overline{x}-${subtracter}}{${divider}}$`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore \\overline{x}=${decodedMean.toFixed(2)}$`
      );

      // b
      question.addHeading("solution", `b) Calculating standard deviation.`);
      question.addParagraph(
        "solution",
        `$\\sigma_y = \\sqrt{\\displaystyle\\frac{\\sum{fy^2}}{n}-(\\overline{y}})^2$`
      );
      question.addParagraph(
        "solution",
        `$\\sigma_y = \\sqrt{\\displaystyle\\frac{${freqs[0]}(${
          encodedMidpoints[0]
        }^2)+${freqs[1]}(${encodedMidpoints[1]}^2)+${freqs[2]}(${
          encodedMidpoints[2]
        }^2)+${freqs[3]}(${encodedMidpoints[3]}^2)+${freqs[4]}(${
          encodedMidpoints[4]
        }^2)}{${n}}-(${encodedMean.toFixed(5)})^2}$`
      );

      question.addParagraph(
        "solution",
        `$=${parseFloat(encodedSD.toFixed(8))}$`
      );
      question.addParagraph("solution", `$\\approx ${encodedSD.toFixed(5)}$`);

      question.addHeading("solution", `Decode standard deviation.`);
      question.addParagraph(
        "solution",
        `$\\sigma_y=\\displaystyle\\frac{\\sigma_x}{${divider}}$, as standard deviation is not affected by addition/subtraction from coding.`
      );
      question.addParagraph(
        "solution",
        `$${encodedSD.toFixed(5)}=\\displaystyle\\frac{\\sigma_x}{${divider}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sigma_x=${decodedSD.toFixed(2)}$`
      );

      // answers

      const a_input = new MCQuestion.InputGroup(1);
      a_input.addInput(decodedMean, 200, `a) $\\ \\overline{x}=$`);
      question.addInputGroup(a_input);

      const b_input = new MCQuestion.InputGroup(1);
      b_input.addInput(decodedSD, 100, `b) $\\ \\sigma_x=$`);
      question.addInputGroup(b_input);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Coding_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const divider = MCRandom.randomInt(20, 80, 5);
    const subtractor = MCRandom.randomInt(20, 100, 20);

    function encode(value) {
      return (value - subtractor) / divider;
    }
    function decode(value) {
      return value * divider + subtractor;
    }

    const tmp_decodedSample = [];
    const tmp_encodedSample = [];

    const n = MCRandom.randomInt(5, 20);

    for (let i = 0; i < n; i += 1) {
      tmp_decodedSample.push(MCRandom.randomInt(240, 300));
      tmp_encodedSample.push(encode(tmp_decodedSample[i]));
    }
    const decodedSample = [...tmp_decodedSample];
    const encodedSample = [...tmp_encodedSample];

    const statsDecoded = new MCMaths.Stats(decodedSample);
    const statsEncoded = new MCMaths.Stats(encodedSample);

    const decodedMean = statsDecoded.mean();
    const encodedMean = statsEncoded.mean();

    const decodedSD = statsDecoded.standardDeviation();
    const encodedSD = statsEncoded.standardDeviation();

    const sy = statsEncoded.sx();

    const sy2 = statsEncoded.sx2();

    function correctTo2dp(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (parseFloat(arr[i].toFixed(2)) !== arr[i]) {
          return false;
        }
      }
      return true;
    }
    const check2dp = [sy, sy2, encodedMean, decodedMean];

    /* END VARIABLES AND CONSTANTS */
    if (correctTo2dp(check2dp)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `The marathon times, $t$ minutes, of $${n}$ runners were measured. The results are summarised below.`
      );
      question.addParagraph(
        "question",
        `$\\displaystyle\\sum^{${n}}_{n=1}{\\frac{t_{n}-${subtractor}}{${divider}}}=${sy}$`
      );
      question.addParagraph(
        "question",
        `$\\displaystyle\\sum^{${n}}_{n=1}{\\left(\\frac{t_{n}-${subtractor}}{${divider}}\\right)^2}=${sy2}$`
      );

      question.addParagraph(
        `question`,
        `Calculate the mean and standard deviation times of these runners, in minutes.`
      );

      // solution
      // mean

      question.addHeading(
        "solution",
        `The times of the runners are coded using the formula;`
      );
      question.addParagraph(
        "solution",
        `$y = \\displaystyle\\frac{t-${subtractor}}{${divider}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\displaystyle\\sum^{${n}}_{n=1}{y}=${sy}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\overline{y}=\\displaystyle\\frac{${sy}}{${n}}$`
      );
      question.addParagraph("solution", `$=${encodedMean}$`);

      question.addHeading("solution", `Decode mean.`);
      question.addParagraph(
        "solution",
        `$\\overline{y}=\\displaystyle\\frac{\\overline{t}-${subtractor}}{${divider}}$`
      );
      question.addParagraph(
        "solution",
        `$${encodedMean}=\\displaystyle\\frac{\\overline{t}-${subtractor}}{${divider}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\overline{t}=${decodedMean}$`
      );
      // sd
      question.addHeading(`solution`, `Finding standard deviation.`);
      question.addParagraph(
        "solution",
        `$\\sigma_y = \\sqrt{\\displaystyle\\frac{\\sum{y^2}}{n}-(\\overline{y}})^2$`
      );
      question.addParagraph(
        "solution",
        `$\\sigma_y = \\sqrt{\\displaystyle\\frac{${sy2}}{${n}}-(${encodedMean})^2}$`
      );

      question.addParagraph(
        "solution",
        `$=${parseFloat(encodedSD.toFixed(8))}$`
      );
      question.addParagraph("solution", `$\\approx ${encodedSD.toFixed(5)}$`);

      question.addHeading("solution", `Decode standard deviation.`);
      question.addParagraph(
        "solution",
        `$\\sigma_y=\\displaystyle\\frac{\\sigma_t}{${divider}}$, as standard deviation is not affected by addition/subtraction from coding.`
      );
      question.addParagraph(
        "solution",
        `$${encodedSD.toFixed(5)}=\\displaystyle\\frac{\\sigma_t}{${divider}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sigma_x=${decodedSD.toFixed(2)}$`
      );

      // answers

      const input = new MCQuestion.InputGroup(2);
      input.addInput(decodedMean, 5, `$\\overline{t}=$`);
      input.addInput(decodedSD, 5, `$\\sigma_{t}=$`);
      question.addInputGroup(input);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Coding_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = MCRandom.randomInt(5, 8);
    const multiplier = MCRandom.randomInt(5, 20);
    const adder = MCRandom.randomInt(25, 50);

    function encode(value) {
      return multiplier * (value + adder);
    }
    function decode(value) {
      return value / multiplier - adder;
    }

    const statsDecoded = MCMaths.Stats.randomSample(n, 100, 180, 10);
    const tmp_encodedSample = [...statsDecoded.dataX];
    for (let i = 0; i < tmp_encodedSample.length; i += 1) {
      tmp_encodedSample[i] = encode(tmp_encodedSample[i]);
    }
    const encodedSample = [...tmp_encodedSample];

    const orderedEncoded = [...encodedSample].sort(function (a, b) {
      return a - b;
    });
    const orderedDecoded = [...statsDecoded.dataX].sort(function (a, b) {
      return a - b;
    });

    const statsEncoded = new MCMaths.Stats(encodedSample);

    const encodedMedian = statsEncoded.median();
    const decodedMedian = statsDecoded.median();

    const decodedRange = orderedDecoded[n - 1] - orderedDecoded[0];

    const encodedMean = statsEncoded.mean();
    const decodedMean = statsDecoded.mean();

    function correctTo1dp(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (parseFloat(arr[i].toFixed(1)) !== arr[i]) {
          return false;
        }
      }
      return true;
    }
    const check0dp = [
      decodedMean,
      encodedMean,
      encodedMedian,
      decodedMedian,
      decodedRange,
    ];

    /* END VARIABLES AND CONSTANTS */
    if (correctTo1dp(encodedSample) && correctTo1dp(check0dp)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `A group of $${n}$ athletes measure their $100$ m sprint times, $t$ s.`
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `They code their results using the formula, $y=${multiplier}(t+${adder})$, to give the coded sample:`
        )
      );
      question.addParagraph(`question`, `$${encodedSample}$`);

      question.addParagraph("question", `a) State the median time.`);
      question.addParagraph("question", `b) Calculate the range of the times.`);
      question.addParagraph("question", `c) Calculate the mean time.`);

      // solution
      // a
      question.addHeading("solution", `a) Order the coded sample.`);
      question.addParagraph("solution", `$${orderedEncoded}$`);
      if (n % 2 === 1) {
        question.addParagraph(
          "solution",
          `$\\therefore$ Coded median $=${encodedMedian}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore$ Coded median $=\\displaystyle\\frac{${
            orderedEncoded[n / 2]
          }+${orderedEncoded[n / 2 - 1]}}{2}$`
        );
        question.addParagraph("solution", `$=${encodedMedian}$`);
      }
      question.addHeading("solution", `Decode median.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${encodedMedian}=${multiplier}(x+${adder})$`)
      );
      question.addParagraph(
        "solution",
        ` $\\therefore$ median $=${decodedMedian}$`
      );
      // b
      question.addHeading(
        "solution",
        `b) Decode the coded maximum and minimum values.`
      );
      question.addHeading("solution", `Coded max $=${orderedEncoded[n - 1]}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${orderedEncoded[n - 1]}=${multiplier}(x+${adder})$`)
      );
      question.addParagraph(
        "solution",
        ` $\\therefore$ max $=${orderedDecoded[n - 1]}$`
      );

      question.addHeading("solution", `Coded min $=${orderedEncoded[0]}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${orderedEncoded[0]}=${multiplier}(x+${adder})$`)
      );
      question.addParagraph(
        "solution",
        ` $\\therefore$ min $=${orderedDecoded[0]}$`
      );
      question.addHeading("solution", ` Calculate range.`);
      question.addParagraph(
        "solution",
        `range $=${orderedDecoded[n - 1]}-${orderedDecoded[0]}$`
      );
      question.addParagraph("solution", `$=${decodedRange}$`);

      // c
      question.addHeading(
        "solution",
        `c) Coded mean $=\\displaystyle\\frac{\\sum{y}}{n}$`
      );
      question.addParagraph(
        "solution",

        `$=\\displaystyle\\frac{${encodedSample.join("+")}}{${n}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\overline{y}=${encodedMean}$`
      );
      question.addHeading("solution", `Decode mean.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\overline{y}=${multiplier}(x+${adder})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${encodedMean}=${multiplier}(x+${adder})$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\overline{x}=${decodedMean}$`
      );

      // answers

      const input = new MCQuestion.InputGroup(1);
      input.addInput(decodedMedian, 0.5, `a)`);
      input.addInput(decodedRange, 0.5, `b)`);
      input.addInput(decodedMean, 0.5, `c)`);
      question.addInputGroup(input);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Coding_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const groups = [
      `0\\leq x<60`,
      `60\\leq x<80`,
      `80\\leq x<90`,
      `90\\leq x<95`,
      `95\\leq x<100`,
    ];

    const freqs = [
      MCRandom.randomInt(4, 10),
      MCRandom.randomInt(8, 15),
      MCRandom.randomInt(15, 30),
      MCRandom.randomInt(25, 50),
      MCRandom.randomInt(15, 30),
    ];

    const n = freqs.reduce((a, b) => a + b, 0);

    const midpoints = [30, 70, 85, 92.5, 97.5];

    const divider = MCRandom.randomInt(5, 20, 5);
    const subtracter = MCRandom.randomInt(35, 65, 5);

    function encode(value) {
      return (value - subtracter) / divider;
    }
    function decode(value) {
      return value * divider + subtracter;
    }
    let tmp_mean = 0;
    for (let i = 0; i < midpoints.length; i += 1) {
      tmp_mean += freqs[i] * midpoints[i];
    }
    tmp_mean /= n;
    const mean = tmp_mean;

    const encodedMean = encode(parseFloat(mean.toFixed(5)));

    let tmp_sx2 = 0;
    for (let i = 0; i < freqs.length; i += 1) {
      tmp_sx2 += freqs[i] * midpoints[i] ** 2;
    }
    const sx2 = tmp_sx2;

    const sd = Math.sqrt(sx2 / n - parseFloat(mean.toFixed(5)) ** 2);
    const encodedSD = parseFloat(sd.toFixed(5)) / divider;

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `The table below shows the attendance of $${n}$ students, as a percentage, for their past 5 years at school.`
      );

      const table = new MCQuestion.Table(
        `Percentage Attendance ($A$)`,
        `Frequency`
      );
      for (let i = 0; i < freqs.length; i += 1) {
        table.addRow(`$${groups[i]}$`, `$${freqs[i]}$`);
      }
      question.addTable("question", table);

      question.addParagraph(
        "question",
        `a) Estimate the mean attendance of the sample.`
      );
      question.addParagraph(
        "question",
        `b) Estimate the standard deviation of the sample.`
      );
      question.addParagraph(
        "question",
        `The headteacher decides to give each student an attendance score by using the coding $y=\\displaystyle\\frac{x-${subtracter}}{${divider}}$, where $x$ represents the original attendance.`
      );
      question.addParagraph(
        `question`,
        `c) Using the values calculated in parts $a$ and $b$, estimate the mean and standard deviation for attendance scores.`
      );

      // solution
      // a

      question.addHeading("solution", `a) Calculate midpoints.`);

      const table2 = new MCQuestion.Table(
        `Percentage Attendance ($A $)`,
        `Frequency`,
        `Midpoint`
      );
      for (let i = 0; i < freqs.length; i += 1) {
        table2.addRow(`$${groups[i]}$`, `$${freqs[i]}$`, `$${midpoints[i]}$`);
      }
      question.addTable("solution", table2);

      question.addHeading(
        "solution",
        `Calculate sample mean using values of the midpoints.`
      );
      question.addParagraph(
        "solution",
        `$\\overline{x}=\\displaystyle\\frac{\\sum{fx}}{n}$`
      );
      question.addParagraph(
        "solution",
        `$\\overline{x}=\\displaystyle\\frac{${freqs[0]}(${midpoints[0]})+${freqs[1]}(${midpoints[1]})+${freqs[2]}(${midpoints[2]})+${freqs[3]}(${midpoints[3]})+${freqs[4]}(${midpoints[4]})}{${n}}$`
      );

      if (parseFloat(mean.toFixed(5)) !== mean) {
        question.addParagraph("solution", `$=${mean.toFixed(8)}$`);
        question.addParagraph("solution", `$\\approx ${mean.toFixed(5)}$`);
      } else {
        question.addParagraph("solution", `$=${mean}$`);
      }
      // b
      question.addHeading("solution", `b) Calculating standard deviation.`);
      question.addParagraph(
        "solution",
        `$\\sigma_x = \\sqrt{\\displaystyle\\frac{\\sum{fx^2}}{n}-(\\overline{x}})^2$`
      );
      question.addParagraph(
        "solution",
        `$\\sigma_x = \\sqrt{\\displaystyle\\frac{${freqs[0]}(${
          midpoints[0]
        }^2)+${freqs[1]}(${midpoints[1]}^2)+${freqs[2]}(${midpoints[2]}^2)+${
          freqs[3]
        }(${midpoints[3]}^2)+${freqs[4]}(${
          midpoints[4]
        }^2)}{${n}}-(${mean.toFixed(5)})^2}$`
      );

      question.addParagraph("solution", `$=${parseFloat(sd.toFixed(8))}$`);
      question.addParagraph("solution", `$\\approx ${sd.toFixed(5)}$`);

      // c
      question.addHeading("solution", `c) Encode Mean`);
      question.addParagraph(
        `solution`,
        `$\\overline{y}=\\displaystyle\\frac{\\overline{x}-${subtracter}}{${divider}}$`
      );
      if (parseFloat(mean.toFixed(5)) !== mean) {
        question.addParagraph(
          `solution`,
          `$=\\displaystyle\\frac{${mean.toFixed(
            5
          )}-${subtracter}}{${divider}}$`
        );
      } else {
        question.addParagraph(
          `solution`,
          `$=\\displaystyle\\frac{${mean}-${subtracter}}{${divider}}$`
        );
      }

      if (parseFloat(encodedMean.toFixed(2)) !== encodedMean) {
        question.addParagraph(`solution`, `$=${encodedMean.toFixed(5)}$`);
        question.addParagraph(
          `solution`,
          `$\\approx ${encodedMean.toFixed(2)}$`
        );
      } else {
        question.addParagraph(`solution`, `$=${encodedMean}$`);
      }

      question.addHeading("solution", `Encode Standard Deviation`);
      question.addParagraph(
        "solution",
        `$\\sigma_y=\\displaystyle\\frac{\\sigma_x}{${divider}}$, as standard deviation is not affected by addition/subtraction from coding.`
      );
      question.addParagraph(
        "solution",
        `$\\sigma_y=\\displaystyle\\frac{${sd.toFixed(5)}}{${divider}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sigma_y \\approx ${encodedSD.toFixed(2)}$`
      );

      // answers

      const a_input = new MCQuestion.InputGroup(1);
      a_input.addInput(mean, 5, `a) $\\ \\overline{x}=$`);
      question.addInputGroup(a_input);

      const b_input = new MCQuestion.InputGroup(1);
      b_input.addInput(sd, 2.5, `b) $\\ \\sigma_x=$`);
      question.addInputGroup(b_input);

      const c_input = new MCQuestion.InputGroup(2);
      c_input.addInput(encodedMean, 0.5, `b) $\\ \\overline{y}=$`);
      c_input.addInput(encodedSD, 0.25, `b) $\\ \\sigma_y=$`);
      question.addInputGroup(c_input);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Coding_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const divider = MCRandom.randomInt(50, 250, 10);
    const subtractor = MCRandom.randomInt(20, 50, 5);

    function encode(value) {
      return (value - subtractor) / divider;
    }
    function decode(value) {
      return value * divider + subtractor;
    }

    const tmp_decodedSample = [];
    const tmp_encodedSample = [];

    const n = MCRandom.randomInt(5, 20);

    for (let i = 0; i < n; i += 1) {
      tmp_decodedSample.push(MCRandom.randomInt(200, 300, 5));
      tmp_encodedSample.push(encode(tmp_decodedSample[i]));
    }
    const decodedSample = [...tmp_decodedSample];
    const encodedSample = [...tmp_encodedSample];

    const statsDecoded = new MCMaths.Stats(decodedSample);
    const statsEncoded = new MCMaths.Stats(encodedSample);

    const decodedMean = statsDecoded.mean();
    const encodedMean = statsEncoded.mean();

    const decodedSD = statsDecoded.standardDeviation();
    const encodedSD = statsEncoded.standardDeviation();

    const sy = statsEncoded.sx();

    const sy2 = statsEncoded.sx2();

    function correctTo2dp(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (parseFloat(arr[i].toFixed(2)) !== arr[i]) {
          return false;
        }
      }
      return true;
    }
    const check2dp = [sy, sy2, encodedMean, decodedMean];

    /* END VARIABLES AND CONSTANTS */
    if (correctTo2dp(check2dp)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `The masses, $x$ kg, of $${n}$ bluefin tuna caught by fisherman were measured.`
      );
      question.addParagraph(
        "question",
        `The coding $y=\\displaystyle\\frac{x-${subtractor}}{${divider}}$ was used when calculating the summary results below.`
      );

      question.addParagraph(
        "question",
        `$\\displaystyle\\sum^{${n}}_{n=1}{y_n}=${sy}$`
      );
      question.addParagraph(
        "question",
        `$\\displaystyle\\sum^{${n}}_{n=1}{y_n^2}=${sy2}$`
      );
      question.addParagraph(
        `question`,
        `a) Calculate the mean  mass of this sample bluefin tuna.`
      );
      question.addParagraph(
        `question`,
        `b) Calculate the standard deviation for the masses of these tuna.`
      );

      // solution
      // a
      question.addHeading(`solution`, `a) Calculate coded mean.`);
      question.addParagraph(
        "solution",
        `$ \\displaystyle\\sum^{${n}}_{n=1}{y}=${sy}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\overline{y}=\\displaystyle\\frac{${sy}}{n}$`
      );
      question.addParagraph("solution", `$=\\displaystyle\\frac{${sy}}{${n}}$`);
      question.addParagraph("solution", `$=${encodedMean}$`);

      question.addHeading("solution", `Decode mean.`);
      question.addParagraph(
        "solution",
        `$\\overline{y}=\\displaystyle\\frac{\\overline{x}-${subtractor}}{${divider}}$`
      );
      question.addParagraph(
        "solution",
        `$${encodedMean}=\\displaystyle\\frac{\\overline{x}-${subtractor}}{${divider}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\overline{x}=${decodedMean}$`
      );
      // b
      question.addHeading(`solution`, `b) Finding standard deviation.`);
      question.addParagraph(
        "solution",
        `$\\sigma_y = \\sqrt{\\displaystyle\\frac{\\sum{y^2}}{n}-(\\overline{y}})^2$`
      );
      question.addParagraph(
        "solution",
        `$\\sigma_y = \\sqrt{\\displaystyle\\frac{${sy2}}{${n}}-(${encodedMean})^2}$`
      );

      question.addParagraph(
        "solution",
        `$=${parseFloat(encodedSD.toFixed(8))}$`
      );
      question.addParagraph("solution", `$\\approx ${encodedSD.toFixed(5)}$`);

      question.addHeading("solution", `Decode standard deviation.`);
      question.addParagraph(
        "solution",
        `$\\sigma_y=\\displaystyle\\frac{\\sigma_x}{${divider}}$, as standard deviation is not affected by addition/subtraction from coding.`
      );
      question.addParagraph(
        "solution",
        `$${encodedSD.toFixed(5)}=\\displaystyle\\frac{\\sigma_x}{${divider}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sigma_x=${decodedSD.toFixed(2)}$`
      );

      // answers

      const input = new MCQuestion.InputGroup(2);
      input.addInput(decodedMean, 5, `$\\overline{x}=$`);
      input.addInput(decodedSD, 5, `$\\sigma_{x}=$`);
      question.addInputGroup(input);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Coding_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(5, 15) * MCRandom.randomInt(-1, 1, 2);

    const mean = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    function allGood(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if ((arr[i] === arr[j] && i !== j) || Math.abs(arr[i]) > 40) {
            return false;
          }
        }
      }
      return true;
    }

    const ans = [a * mean + b, a * mean, mean + b, mean];
    /* END VARIABLES AND CONSTANTS */
    if (allGood(ans)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        MCMaths.cleaner(
          `The coding $y=${a}x+${b}$ is used to encode a sample that has a mean of $${mean}$`
        )
      );
      question.addParagraph(
        MCMaths.cleaner(`Select the mean of the coded sample.`)
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`$${ans[0]}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`$${ans[1]}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`$${ans[2]}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${ans[3]}$`);

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Coding_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(2, 5) * -1;
    const b = MCRandom.randomInt(5, 15) * MCRandom.randomInt(-1, 1, 2);

    const sd = MCRandom.randomInt(2, 6);
    function allGood(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if ((arr[i] === arr[j] && i !== j) || Math.abs(arr[i]) > 40) {
            return false;
          }
        }
      }
      return true;
    }

    const ans = [a * sd + b, Math.abs(a * sd), sd + b, sd * a];
    /* END VARIABLES AND CONSTANTS */
    if (allGood(ans)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        MCMaths.cleaner(
          `The coding $y=${a}x+${b}$ is used to encode a sample that has a standard deviation of $${sd}$`
        )
      );
      question.addParagraph(
        MCMaths.cleaner(`Select the standard deviation of the coded sample.`)
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`$${ans[0]}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`$${ans[1]}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`$${ans[2]}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${ans[3]}$`);

      question.addAnswer(a1, false);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Coding_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(5, 15) * MCRandom.randomInt(-1, 1, 2);

    const mean = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    function allGood(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if ((arr[i] === arr[j] && i !== j) || Math.abs(arr[i]) > 40) {
            return false;
          }
        }
      }
      return true;
    }
    const coded_mean = a * mean + b;
    const ans = [
      mean,
      parseFloat((coded_mean / a).toFixed(2)),
      coded_mean - b,
      parseFloat((coded_mean / a - b).toFixed(2)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (allGood(ans)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        MCMaths.cleaner(
          `The coding $y=${a}x+${b}$ is used to encode a sample. When coded, the sample has a mean of $${coded_mean}$`
        )
      );
      question.addParagraph(
        MCMaths.cleaner(`Select the mean of the original sample.`)
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`$${ans[0]}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`$${ans[1]}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`$${ans[2]}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${ans[3]}$`);

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Measures_Coding_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(2, 5) * -1;
    const b = MCRandom.randomInt(5, 15) * MCRandom.randomInt(-1, 1, 2);

    const sd = MCRandom.randomInt(2, 6);
    function allGood(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if ((arr[i] === arr[j] && i !== j) || Math.abs(arr[i]) > 40) {
            return false;
          }
        }
      }
      return true;
    }
    const coded_sd = Math.abs(a * sd);
    const ans = [coded_sd / a - b, sd, coded_sd - b, coded_sd / a];
    /* END VARIABLES AND CONSTANTS */
    if (allGood(ans)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        MCMaths.cleaner(
          `The coding $y=${a}x+${b}$ is used to encode a sample. When encoded, the sample has a standard deviation of $${coded_sd}$`
        )
      );
      question.addParagraph(
        MCMaths.cleaner(`Select the standard deviation of the original sample.`)
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`$${ans[0]}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`$${ans[1]}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`$${ans[2]}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${ans[3]}$`);

      question.addAnswer(a1, false);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_MutuallyExclusive_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const PA = parseFloat((MCRandom.randomInt(20, 40) / 100).toFixed(2));
    const PB = parseFloat((MCRandom.randomInt(20, 40) / 100).toFixed(2));
    const PC = parseFloat((MCRandom.randomInt(50, 80) / 100).toFixed(2));
    const PD = parseFloat((MCRandom.randomInt(50, 80) / 100).toFixed(2));

    const ai_and = PA * PB;
    const ai_or = PA + PB - ai_and;
    const aii_and = 0;
    const aii_or = PA + PB;

    const b_or = PC + PD;
    /* END VARIABLES AND CONSTANTS */
    if (
      PA !== PB &&
      PC !== PD &&
      parseFloat(ai_and.toFixed(4)) === ai_and &&
      parseFloat(ai_or.toFixed(4)) === ai_or &&
      parseFloat(aii_or.toFixed(2)) === aii_or &&
      parseFloat(b_or.toFixed(2)) === b_or
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `It's given that $P(A)=${PA}$ and $P(B)=${PB}$`
      );
      question.addParagraph(
        `question`,
        `a) Find $P(A \\cap B)$ and $P(A \\cup B)$ when:`
      );
      question.addParagraph(
        `question`,
        `i) the events $A$ and $B$ are independent.`
      );
      question.addParagraph(
        `question`,
        `ii) the events $A$ and $B$ are mutually exclusive.`
      );

      question.addParagraph(
        "question",
        `Two other events $C$ and $D$ have probabilities $${PC}$ and $${PD}$ of occurring respectively.`
      );
      question.addParagraph(
        "question",
        `b) Show that $C$ and $D$ can't be mutually exclusive.`
      );
      // solution
      // a
      // i
      question.addHeading(
        `solution`,
        `ai) When $A$ and $B$ are independent, $P(A \\cap B)=P(A)P(B)$`
      );
      question.addParagraph(`solution`, `$P(A \\cap B) = ${PA}(${PB})$`);
      question.addParagraph(`solution`, `$=${ai_and}$`);
      question.addHeading(
        `solution`,
        `When $A$ and $B$ are independent, $P(A \\cup B)=P(A) + P(B) - P(A \\cap B)$`
      );
      question.addParagraph(`solution`, `$P(A \\cup B)=${PA}+${PB}-${ai_and}$`);
      question.addParagraph(`solution`, `$=${ai_or}$`);
      // ii
      question.addHeading(
        `solution`,
        `aii) When $A$ and $B$ are mutually exclusive:`
      );
      question.addParagraph(`solution`, `$P(A \\cap B)=0$, by definition`);
      question.addHeading(
        `solution`,
        `When $A$ and $B$ are mutually exclusive, $P(A \\cup B)=P(A) + P(B) - P(A \\cap B)$`
      );
      question.addParagraph(
        `solution`,
        `$P(A \\cup B)=${PA}+${PB}-${aii_and}$`
      );
      question.addParagraph(`solution`, `$=${aii_or}$`);
      // b
      question.addHeading(
        "solution",
        `b) If $C$ and $D$ are mutually exclusive, then $P(C \\cap D)=0$`
      );
      question.addParagraph(
        `solution`,
        `$\\therefore P(C \\cup D)=${PC}+${PD}-0$`
      );
      question.addParagraph(`solution`, `$=${b_or} > 1$`);
      question.addParagraph(
        `solution`,
        `Probability greater than $1$ is not possible.`
      );
      question.addParagraph(
        `solution`,
        `$\\therefore$ the events can't be mutually exclusive.`
      );
      // answers
      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(parseFloat(ai_and.toFixed(2)), 0.005, `ai) $P(A \\cap B)$`);
      g1.addInput(parseFloat(ai_or.toFixed(2)), 0.005, `ai) $P(A \\cup B)$`);

      g1.addInput(parseFloat(aii_and.toFixed(2)), 0.005, `aii) $P(A \\cap B)$`);
      g1.addInput(parseFloat(aii_or.toFixed(2)), 0.005, `aii) $P(A \\cup B)$`);
      question.addInputGroup(g1);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_MutuallyExclusive_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const co = MCRandom.randomInt(10, 30);
    const cb = MCRandom.randomInt(10, 30);
    const cm = MCRandom.randomInt(10, 30);

    const to = MCRandom.randomInt(10, 30);
    const tb = MCRandom.randomInt(10, 30);
    const tm = MCRandom.randomInt(10, 30);

    const ao = MCRandom.randomInt(10, 30);
    const ab = MCRandom.randomInt(10, 30);
    const am = MCRandom.randomInt(10, 30);

    const c_total = co + cb + cm;
    const t_total = to + tb + tm;
    const a_total = ao + ab + am;

    const o_total = co + to + ao;
    const b_total = cb + tb + ab;
    const m_total = cm + tm + am;

    const n = c_total + t_total + a_total;

    const events = [`C`, "T", "A", "O", "B", "M"];
    const i_event = events[MCRandom.randomInt(0, 5)];

    const events_dict = {
      C: c_total,
      T: t_total,
      A: a_total,
      O: o_total,
      B: b_total,
      M: m_total,
    };

    const i_val = events_dict[i_event];

    const ii_age = events[MCRandom.randomInt(0, 2)];
    const ii_flavour = events[MCRandom.randomInt(3, 5)];
    const ii_val = {
      CO: co,
      CB: cb,
      CM: cm,

      TO: to,
      TB: tb,
      TM: tm,

      AO: ao,
      AB: ab,
      AM: am,
    }[ii_age + ii_flavour];

    const iii_f1 = events[MCRandom.randomInt(3, 5)];
    const iii_f1_value = events_dict[iii_f1];
    const iii_f2 = events[MCRandom.randomInt(3, 5)];
    const iii_f2_value = events_dict[iii_f2];

    const bi_ans = i_val / n;
    const bii_ans = ii_val / n;
    const biii_ans = (iii_f1_value + iii_f2_value) / n;

    /* END VARIABLES AND CONSTANTS */
    if (iii_f1 !== iii_f2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `$${n}$ people of different ages were asked for their favourite flavour of juice. The results are summarised in the two way table below.`
      );
      const table = new MCQuestion.Table(
        ``,
        `Orange`,
        "Blackcurrant",
        `Mango`,
        `Total`
      );
      table.addRow(`Children`, `$${co}$`, `$${cb}$`, `$${cm}$`, `$${c_total}$`);
      table.addRow(`Teens`, `$${to}$`, `$${tb}$`, `$${tm}$`, `$${t_total}$`);
      table.addRow(`Adults`, `$${ao}$`, `$${ab}$`, `$${am}$`, `$${a_total}$`);
      table.addRow(
        `Total`,
        `$${o_total}$`,
        `$${b_total}$`,
        `$${m_total}$`,
        `$${n}$`
      );
      question.addTable(`question`, table);

      question.addParagraph(
        "question",
        `A person from the survey is reselected at random.`
      );
      question.addParagraph(
        "question",
        `$C$ is the event that they are a child.`
      );
      question.addParagraph(
        "question",
        `$T$ is the event that they are a teen.`
      );
      question.addParagraph(
        "question",
        `$A$ is the event that they are an adult.`
      );
      question.addParagraph(
        "question",
        `$O,B$ and $M$ denote the events that their favourite flavour is orange, blackcurrant and mango respectively.`
      );

      question.addParagraph(
        `question`,
        `a) Name two events that are mutually exclusive.`
      );
      question.addParagraph("question", `b) Find:`);
      question.addParagraph("question", `i) $P(${i_event})$`);
      question.addParagraph(
        "question",
        `ii) $P(${ii_age} \\cap ${ii_flavour})$`
      );
      question.addParagraph("question", `iii) $P(${iii_f1} \\cup ${iii_f2})$`);
      // solution
      // a
      question.addHeading(
        `solution`,
        `a) Any two events that can't happen simultaneously. For example:`
      );
      question.addParagraph(
        `solution`,
        `A person can't be a child and an adult.`
      );
      question.addParagraph(
        `solution`,
        `$\\therefore C$ and $A$ are mutually exclusive.`
      );
      question.addParagraph(
        `solution`,
        `A person's favourite flavour can't be orange and mango.`
      );
      question.addParagraph(
        `solution`,
        `$\\therefore O$ and $M$ are mutually exclusive.`
      );
      // b
      question.addHeading("solution", `b) Calculating probabilities.`);
      // i
      question.addHeading("solution", `i) Find $P(${i_event})$`);
      question.addHeading(
        "solution",
        `$P(${i_event})=\\displaystyle\\frac{${i_val}}{${n}}$`
      );
      if (parseFloat(bi_ans.toFixed(2)) !== bi_ans) {
        question.addHeading("solution", `$=${bi_ans.toFixed(4)}$`);
        question.addHeading("solution", `$\\approx${bi_ans.toFixed(2)}$`);
      } else {
        question.addHeading("solution", `$=${bi_ans.toFixed(2)}$`);
      }
      // ii
      question.addHeading(
        "solution",
        `ii) Find $P(${ii_age} \\cap ${ii_flavour})$`
      );
      question.addHeading(
        "solution",
        `$P(${ii_age} \\cap ${ii_flavour})=\\displaystyle\\frac{${ii_val}}{${n}}$`
      );
      if (parseFloat(bii_ans.toFixed(2)) !== bii_ans) {
        question.addHeading("solution", `$=${bii_ans.toFixed(4)}$`);
        question.addHeading("solution", `$\\approx${bii_ans.toFixed(2)}$`);
      } else {
        question.addHeading("solution", `$=${bii_ans.toFixed(2)}$`);
      }
      // iii
      question.addHeading(
        "solution",
        `iii) Find $P(${iii_f1} \\cup ${iii_f2})$`
      );
      question.addHeading(
        "solution",
        `$P(${iii_f1} \\cup ${iii_f2})=\\displaystyle\\frac{${iii_f1_value}+${iii_f2_value}}{${n}}$`
      );
      if (parseFloat(biii_ans.toFixed(2)) !== biii_ans) {
        question.addHeading("solution", `$=${biii_ans.toFixed(4)}$`);
        question.addHeading("solution", `$\\approx${biii_ans.toFixed(2)}$`);
      } else {
        question.addHeading("solution", `$=${biii_ans.toFixed(2)}$`);
      }

      // answers
      question.requiresSelfMarking();

      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(bi_ans, 0.005, `bi)`);
      g1.addInput(bii_ans, 0.005, `bii)`);
      g1.addInput(biii_ans, 0.005, `biii)`);
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_MutuallyExclusive_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const PX = parseFloat((MCRandom.randomInt(20, 40) / 100).toFixed(2));
    const PY = parseFloat((MCRandom.randomInt(20, 40) / 100).toFixed(2));

    const XorY = parseFloat((PX + PY).toFixed(2));

    const Y1 = parseFloat((1 - PX).toFixed(2));
    const X1 = parseFloat((XorY - PX).toFixed(2));
    const b_ans = X1 / Y1;
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `The probability of events $X$ and $Y$ occuring are such that:`
      );
      question.addParagraph(
        `question`,
        `$P(X)=${PX}$   and   $P(X \\cup Y)=${XorY}$`
      );
      question.addParagraph(
        `question`,
        `a) Determine $P(Y)$ when $X$ and $Y$ are:`
      );
      question.addParagraph(`question`, `i) mutually exclusive.`);
      question.addParagraph(`question`, `ii) independent.`);
      question.addParagraph(
        "question",
        `b) Give an example of what events $X$ and $Y$ could represent in context when they are mutually exclusive.`
      );

      // solutions
      // a
      question.addHeading(
        "solution",
        `a) $P(X \\cup Y)=P(X) + P(Y) + P(X \\cap Y)$`
      );
      question.addHeading(
        `solution`,
        `i) When $X$ and $Y$ are mutually exclusive, $P(X \\cap Y)=0$`
      );
      question.addHeading(`solution`, `$${XorY}=${PX} + P(Y) - 0$`);
      question.addHeading(`solution`, `$\\therefore P(Y)=${PY}$`);

      question.addHeading(
        `solution`,
        `ii) When $X$ and $Y$ are independent, $P(X \\cap Y)=P(X)P(Y)$`
      );
      question.addHeading(
        `solution`,
        `$${XorY}=${PX} + P(Y) - ${PX}\\times P(Y)$`
      );
      question.addHeading(`solution`, `$${Y1} \\times P(Y)= ${PY}$`);
      if (parseFloat(b_ans.toFixed(2)) !== b_ans) {
        question.addHeading(
          "solution",
          `$\\therefore P(Y)=${b_ans.toFixed(4)}$`
        );
        question.addHeading("solution", `$\\approx${b_ans.toFixed(2)}$`);
      } else {
        question.addHeading(`solution`, `$\\therefore P(Y)= ${b_ans}$`);
      }
      // b
      question.addHeading(
        `solution`,
        `b) When they're mutually exclusive, they can't possibly occur at the same time. For example:`
      );
      question.addParagraph(
        "solution",
        `Turning left and turning right at a junction, a coin landing on heads and tails on one flip, a playing card being a king and an ace, etc.`
      );
      // answers
      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(PY, 0, `ai)`);
      g1.addInput(b_ans, 0.005, `aii)`);
      question.addInputGroup(g1);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_MutuallyExclusive_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = parseFloat((MCRandom.randomInt(10, 40) / 100).toFixed(2));
    const b = parseFloat((MCRandom.randomInt(20, 50) / 100).toFixed(2));

    const a_and_b = parseFloat((MCRandom.randomInt(5, 15) / 100).toFixed(2));
    const a_or_b = parseFloat((a + b - a_and_b).toFixed(2));

    const a_plus_b = a_or_b + a_and_b;
    const constant = 1 / a_plus_b;

    const c = parseFloat((constant * a - b).toFixed(2));
    const a_or_c = parseFloat((a + c).toFixed(2));

    const constant_a = parseFloat((a_or_c + 1 / constant).toFixed(2));

    /* END VARIABLES AND CONSTANTS */
    if (
      a_and_b < 0.3 &&
      a_and_b > 0.05 &&
      (1 / a_plus_b) % 1 === 0 &&
      a_or_b - a_and_b > 0.04 &&
      c > 0.1 &&
      a + b + c < 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(`question`, `The events $A$ and $B$ satisfy:`);
      question.addParagraph(
        `question`,
        `$P(A)=a, P(B)=b,P(A \\cup B)=${a_or_b}$ and $P(A \\cap B)=${a_and_b}$`
      );
      question.addParagraph(
        "question",
        ` a) Show that, $${constant}a + ${constant}b = 1$`
      );
      question.addParagraph(
        "question",
        `The events $A$ and $C$ are mutually exclusive such that:`
      );
      question.addParagraph(
        "question",
        `$P(A \\cup C)=${a_or_c}$ and $P(C)=${constant}a-b$`
      );
      question.addParagraph(
        "question",
        `b) Hence, find the values of $a,b$ and $P(C)$`
      );

      // solution
      // a
      question.addHeading(
        "solution",
        `a) $P(A \\cup B)=P(A) + P(B) - P(A \\cap B)$`
      );
      question.addParagraph("solution", `$${a_or_b}=a + b - ${a_and_b}$`);
      question.addParagraph(
        "solution",
        `$\\therefore${constant}a + ${constant}b = 1$`
      );
      // b
      question.addHeading(
        "solution",
        `b) As $A$ and $C$ are mutually exclusive, $P(A \\cap C)=0$`
      );
      question.addParagraph("solution", `$${constant}a + ${constant}b = 1$`);
      question.addParagraph(
        "solution",
        `$b = \\displaystyle\\frac{1}{${constant}}-a$`
      );
      question.addParagraph("solution", `$P(A \\cup C)=P(A)+P(C) - 0$`);
      question.addParagraph("solution", `$${a_or_c}=a+${constant}a-b$`);
      question.addHeading(
        "solution",
        `Sub in $b=\\displaystyle\\frac{1}{${constant}}-a$`
      );
      question.addParagraph(
        "solution",
        `$${a_or_c}=a+${constant}a-(\\displaystyle\\frac{1}{${constant}}-a)$`
      );
      question.addParagraph("solution", `$${constant + 2}a=${constant_a}$`);
      question.addParagraph("solution", `$\\therefore a=${a}$`);
      question.addParagraph(
        "solution",
        `$b=\\displaystyle\\frac{1}{${constant}}-${a}$`
      );
      question.addParagraph("solution", `$\\therefore b=${b}$`);
      question.addParagraph("solution", `$P(C)=${constant}(${a})-${b}$`);
      question.addParagraph("solution", `$=${c}$`);
      // answers
      question.requiresSelfMarking();
      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(a, 0.005, `b) $\\ a=$`);
      g1.addInput(b, 0.005, `b) $\\ b=$`);
      g1.addInput(c, 0.005, `b) $\\ P(C)=$`);
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_MutuallyExclusive_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const PX = MCRandom.randomInt(2, 8) / 10;
    const PY = MCRandom.randomInt(2, 8) / 10;

    const answers = [
      0,
      (PX * PY).toFixed(2),
      (PX + PY).toFixed(1),
      Math.abs(PX - PY).toFixed(1),
    ];
    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (
      PX !== PY &&
      PX + PY < 1 &&
      answers[1] !== answers[2] &&
      answers[1] !== answers[3] &&
      answers[2] !== answers[3]
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Given that the events $X$ and $Y$ are mutually exclusive and that:`
      );
      question.addParagraph(`$P(X)=${PX}$ and $P(Y)=${PY}$`);
      question.addParagraph(`Select $P(X \\cap Y)$`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${answers[0]}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${answers[1]}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${answers[2]}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${answers[3]}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_MutuallyExclusive_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const PA = MCRandom.randomInt(2, 8) / 10;
    const PB = MCRandom.randomInt(2, 8) / 10;

    const answers = [
      0,
      (PA * PB).toFixed(2),
      (PA + PB).toFixed(1),
      Math.abs(PA - PB).toFixed(1),
    ];
    const answerArray = [false, false, true, false];
    /* END VARIABLES AND CONSTANTS */
    if (
      PA !== PB &&
      PA + PB < 1 &&
      answers[1] !== answers[2] &&
      answers[1] !== answers[3] &&
      answers[2] !== answers[3]
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Given that the events $A$ and $B$ are mutually exclusive and that:`
      );
      question.addParagraph(`$P(A)=${PA}$ and $P(B)=${PB}$`);
      question.addParagraph(`Select $P(A \\cup B)$`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${answers[0]}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${answers[1]}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${answers[2]}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${answers[3]}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const six_bias = parseFloat((MCRandom.randomInt(2, 7) / 10).toFixed(1));
    const notSix = parseFloat((1 - six_bias).toFixed(1));
    const six_green = MCRandom.randomInt(12, 20);
    const six_purple = MCRandom.randomInt(3, 10);
    const denominator = six_green + six_purple;

    const person = MCMisc.getName();
    const person2 = MCMisc.getName();

    const a_ans = six_bias * (six_purple / denominator);
    const b_ans = six_bias * (six_green / denominator) + notSix * 0.5;

    const purpleOnly = parseFloat((1 - b_ans).toFixed(3));

    const c_ans = b_ans ** 2 + purpleOnly ** 2;
    /* END VARIABLES AND CONSTANTS */
    if (parseFloat(b_ans.toFixed(3)) === b_ans) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A game consists of rolling a dice and selecting a coloured piece of paper from a bag.The dice is biased, such that it has a $${six_bias}$ probability of rolling a six.`,
        `When a six is rolled the player selects from a bag containing $${six_green}$ green strips of paper and $${six_purple}$ purple strips. Otherwise, the player selects from a bag containing an even number of both green and purple strips. The strips of paper are returned to the bag they came from once the result is noted.`,
        `A tree diagram for this game is shown below.`,
      ]);

      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/2x2.svg`,
        "height",
        20
      );

      tree.addOverlay(`$\\text{Six}$`, 44, 26, 1);
      tree.addOverlay(`$\\text{Not Six}$`, 44, 67.5, 1);
      tree.addOverlay(`$\\text{Green}$`, 86, 12, 1);
      tree.addOverlay(`$\\text{Purple}$`, 86, 42, 1);
      tree.addOverlay(`$\\text{Green}$`, 86, 54, 1);
      tree.addOverlay(`$\\text{Purple}$`, 86, 84, 1);

      tree.addOverlay(`$${six_bias}$`, 15, 32, 1.5);
      tree.addOverlay(`$${notSix}$`, 15, 64, 1.5);
      tree.addOverlay(
        MCMaths.cleaner(`$\\frac{${six_green}}{${denominator}}$`),
        65,
        10,
        1.5
      );
      tree.addOverlay(
        MCMaths.cleaner(`$\\frac{${six_purple}}{${denominator}}$`),
        65,
        41,
        1.5
      );
      tree.addOverlay(`$0.5$`, 65, 55, 1.5);
      tree.addOverlay(`$0.5$`, 65, 81, 1.5);

      question.addImage(`question`, tree);

      question.addMultipleParagraphs("question", [
        `${person.name} rolls the dice and selects a strip of paper from the appropriate bag.`,
        `a) Find the probability of ${person.himher} rolling a six and then choosing a purple strip.`,
        `b) Find the probability of ${person.himher} choosing a green strip.`,
        `${person2.name} plays the game after ${person.name}.`,
        `c) Find the probability that ${person2.name} selects a strip of paper that's the same colour as the one ${person.name} selected.`,
      ]);

      // solution
      question.addHeading("solution", `a) Calculating the probability.`);
      if (parseFloat(a_ans.toFixed(2)) !== a_ans) {
        question.addParagraph(
          "solution",
          `$${six_bias}(\\frac{${six_purple}}{${denominator}})=${a_ans.toFixed(
            4
          )}$`
        );
        question.addParagraph("solution", `$\\approx ${a_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          "solution",
          `$${six_bias}(\\frac{${six_purple}}{${denominator}})=${a_ans}$`
        );
      }

      // b
      question.addMultipleParagraphs("solution", [
        `HEADING b) Consider both cases where a green strip is possible and add them.`,
        `$${six_bias}(\\frac{${six_green}}{${denominator}}) + ${notSix}(0.5)=${b_ans}$`,
        `HEADING c) Using the result from part b, probability of a player choosing a purple strip;`,
        `$=1 - ${b_ans}$`,
        `$=${purpleOnly}$`,
        `HEADING Probability of them both picking green or both picking purple:`,
        `$=${b_ans}^2 + ${purpleOnly}^2$`,
        `$=${c_ans.toFixed(4)}$`,
        `$\\approx ${c_ans.toFixed(2)}$`,
      ]);

      // answers
      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(parseFloat(a_ans.toFixed(2)), 0.005, `a)`);
      g1.addInput(parseFloat(b_ans.toFixed(2)), 0.005, `b)`);
      g1.addInput(parseFloat(c_ans.toFixed(2)), 0.005, `c)`);
      question.addInputGroup(g1);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const red = MCRandom.randomInt(5, 12);
    const blue = MCRandom.randomInt(5, 12);

    const marbles = red + blue;

    const a_ans =
      (red / marbles) * (blue / (marbles - 1)) +
      (blue / marbles) * (red / (marbles - 1));
    const b_ans =
      (red / marbles) * ((red - 1) / (marbles - 1)) +
      (blue / marbles) * ((blue - 1) / (marbles - 1));
    const c_ans =
      (red / marbles) * (blue / (marbles - 1)) +
      (blue / marbles) * ((blue - 1) / (marbles - 1));
    /* END VARIABLES AND CONSTANTS */
    if (a_ans !== b_ans && a_ans !== c_ans && b_ans !== c_ans) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A bag contains $${red}$ red marbles and $${blue}$ marbles. A marble is selected at random from the bag and is not replaced. Then, a second marble is taken from the bag.`,
        `The tree diagram below represents this information.`,
      ]);

      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/2x2.svg`,
        "height",
        20
      );

      tree.addOverlay(`$\\text{Red}$`, 44, 26, 1);
      tree.addOverlay(`$\\text{Blue}$`, 44, 67.5, 1);
      tree.addOverlay(`$\\text{Red}$`, 86, 12, 1);
      tree.addOverlay(`$\\text{Blue}$`, 86, 42, 1);
      tree.addOverlay(`$\\text{Red}$`, 86, 54, 1);
      tree.addOverlay(`$\\text{Blue}$`, 86, 84, 1);

      tree.addOverlay(MCMaths.cleaner(`$${red / marbles}$`), 15, 32, 1.5);
      tree.addOverlay(MCMaths.cleaner(`$${blue / marbles}$`), 15, 64, 1.5);
      tree.addOverlay(
        MCMaths.cleaner(`$${(red - 1) / (marbles - 1)}$`),
        65,
        11,
        1.5
      );
      tree.addOverlay(
        MCMaths.cleaner(`$${blue / (marbles - 1)}$`),
        65,
        41,
        1.5
      );
      tree.addOverlay(MCMaths.cleaner(`$${red / (marbles - 1)}$`), 65, 54, 1.5);
      tree.addOverlay(
        MCMaths.cleaner(`$${(blue - 1) / (marbles - 1)}$`),
        65,
        82,
        1.5
      );

      question.addImage(`question`, tree);

      question.addParagraph(
        `question`,
        `a) Find the probability that only one red ball is selected.`
      );
      question.addParagraph(
        `question`,
        `b) Find the probability that both balls selected are the same colour.`
      );
      question.addParagraph(
        `question`,
        `c) Find the probability that the second ball selected is blue.`
      );

      // solution

      question.addHeading(
        `solution`,
        `a) Either red selected then blue or the opposite.`
      );

      if (parseFloat(a_ans.toFixed(2)) !== a_ans) {
        question.addParagraph(
          `solution`,
          `${MCMaths.cleaner(
            `$${red / marbles}(${blue / (marbles - 1)})+${blue / marbles}(${
              red / (marbles - 1)
            })=$`
          )}$${a_ans.toFixed(4)}$`
        );
        question.addParagraph(`solution`, `$\\approx ${a_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          `solution`,
          `${MCMaths.cleaner(
            `$${red / marbles}(${blue / (marbles - 1)})+${blue / marbles}(${
              red / (marbles - 1)
            })=$`
          )}$${parseFloat(a_ans.toFixed(2))}$`
        );
      }
      // b
      question.addHeading(
        `solution`,
        `b) Red selected twice or blue selected twice.`
      );
      if (parseFloat(b_ans.toFixed(2)) !== b_ans) {
        question.addParagraph(
          `solution`,
          `${MCMaths.cleaner(
            `$${red / marbles}(${(red - 1) / (marbles - 1)})+${
              blue / marbles
            }(${(blue - 1) / (marbles - 1)})=$`
          )}$${b_ans.toFixed(4)}$`
        );
        question.addParagraph(`solution`, `$\\approx ${b_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          `solution`,
          `${MCMaths.cleaner(
            `$${red / marbles}(${(red - 1) / (marbles - 1)})+${
              blue / marbles
            }(${(blue - 1) / (marbles - 1)})=$`
          )}$${parseFloat(b_ans.toFixed(2))}$`
        );
      }
      // c
      question.addHeading(
        `solution`,
        `c) Red selected then blue or blue selected twice.`
      );
      if (parseFloat(c_ans.toFixed(2)) !== c_ans) {
        question.addParagraph(
          `solution`,
          `${MCMaths.cleaner(
            `$${red / marbles}(${blue / (marbles - 1)})+${blue / marbles}(${
              (blue - 1) / (marbles - 1)
            })=$`
          )}$${c_ans.toFixed(4)}$`
        );
        question.addParagraph(`solution`, `$\\approx ${c_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          `solution`,
          `${MCMaths.cleaner(
            `$${red / marbles}(${blue / (marbles - 1)})+${blue / marbles}(${
              (blue - 1) / (marbles - 1)
            })=$`
          )}$${parseFloat(c_ans.toFixed(2))}$`
        );
      }
      // answers

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(parseFloat(a_ans.toFixed(2)), 0.005, `a)`);
      g1.addInput(parseFloat(b_ans.toFixed(2)), 0.005, `b)`);
      g1.addInput(parseFloat(c_ans.toFixed(2)), 0.005, `c)`);
      question.addInputGroup(g1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const black = MCRandom.randomInt(2, 8);
    const blue = MCRandom.randomInt(2, 8);
    const total = black + blue;

    const b_ans = (blue / total) * ((blue - 1) / (total - 1));
    const c_ans =
      (blue / total) * (black / (total - 1)) +
      (black / total) * (blue / (total - 1));
    const d_ans = blue / total + (black / total) * (blue / (total - 1));
    /* END VARIABLES AND CONSTANTS */
    if (
      black !== blue &&
      b_ans !== c_ans &&
      b_ans !== d_ans &&
      c_ans !== d_ans
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A pencil case contains $${black}$ black pens and $${blue}$ blue pens. Two pens are taken out, one after the other, without replacement.`,
        `a) Draw a probability tree to represent the information above.`,
        `b) Find the probability that no black pens are taken.`,
        `c) Find the probability that the two pens taken are different colours.`,
        `d) Find the probability that the first pen taken was blue, or the second pen taken was blue.`,
      ]);
      // solution
      // a
      question.addHeading(`solution`, `a) Tree diagram.`);
      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/2x2.svg`,
        "height",
        20
      );

      tree.addOverlay(`$\\text{Black}$`, 44, 26, 1);
      tree.addOverlay(`$\\text{Blue}$`, 44, 67.5, 1);

      tree.addOverlay(`$\\text{Black}$`, 86, 12, 1);
      tree.addOverlay(`$\\text{Blue}$`, 86, 42, 1);
      tree.addOverlay(`$\\text{Black}$`, 86, 54, 1);
      tree.addOverlay(`$\\text{Blue}$`, 86, 84, 1);

      tree.addOverlay(MCMaths.cleaner(`$${black / total}$`), 15, 32, 1.5);
      tree.addOverlay(MCMaths.cleaner(`$${blue / total}$`), 15, 64, 1.5);
      tree.addOverlay(
        MCMaths.cleaner(`$${(black - 1) / (total - 1)}$`),
        65,
        12,
        1.5
      );
      tree.addOverlay(MCMaths.cleaner(`$${blue / (total - 1)}$`), 65, 41, 1.5);
      tree.addOverlay(MCMaths.cleaner(`$${black / (total - 1)}$`), 65, 54, 1.5);
      tree.addOverlay(
        MCMaths.cleaner(`$${(blue - 1) / (total - 1)}$`),
        65,
        83,
        1.5
      );

      question.addImage(`solution`, tree);
      // b
      question.addHeading(`solution`, `b) Blue pen taken both times.`);
      if (parseFloat(b_ans.toFixed(2)) !== b_ans) {
        question.addParagraph(
          `solution`,
          `${MCMaths.cleaner(
            `$${blue / total}(${(blue - 1) / (total - 1)})=$`
          )}$${b_ans.toFixed(4)}$`
        );
        question.addParagraph(`solution`, `$\\approx ${b_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          `solution`,
          `${MCMaths.cleaner(
            `$${blue / total}(${(blue - 1) / (total - 1)})=$`
          )}$${parseFloat(b_ans.toFixed(2))}$`
        );
      }
      // c
      question.addHeading(
        `solution`,
        `c) Blue pen taken then black or the opposite.`
      );
      if (parseFloat(c_ans.toFixed(2)) !== c_ans) {
        question.addParagraph(
          `solution`,
          `${MCMaths.cleaner(
            `$${blue / total}(${black / (total - 1)}) + ${black / total}(${
              blue / (total - 1)
            })=$`
          )}$${c_ans.toFixed(4)}$`
        );
        question.addParagraph(`solution`, `$\\approx ${c_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          `solution`,
          `${MCMaths.cleaner(
            `$${blue / total}(${black / (total - 1)}) + ${black / total}(${
              blue / (total - 1)
            })=$`
          )}$${parseFloat(c_ans.toFixed(2))}$`
        );
      }
      // d
      question.addHeading(
        `solution`,
        `d) Blue pen taken or black, then blue pen taken.`
      );
      if (parseFloat(d_ans.toFixed(2)) !== d_ans) {
        question.addParagraph(
          `solution`,
          `${MCMaths.cleaner(
            `$${blue / total} + ${black / total}(${blue / (total - 1)})=$`
          )}$${d_ans.toFixed(4)}$`
        );
        question.addParagraph(`solution`, `$\\approx ${d_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          `solution`,
          `${MCMaths.cleaner(
            `$${blue / total} + ${black / total}(${blue / (total - 1)})=$`
          )}$${parseFloat(d_ans.toFixed(2))}$`
        );
      }
      // answers
      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(parseFloat(b_ans.toFixed(2)), 0.005, `b)`);
      g1.addInput(parseFloat(c_ans.toFixed(2)), 0.005, `c)`);
      g1.addInput(parseFloat(d_ans.toFixed(2)), 0.005, `d)`);
      question.addInputGroup(g1);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const person = MCMisc.getName();
    const early = parseFloat((MCRandom.randomInt(3, 7) / 10).toFixed(1));
    const late = parseFloat((1 - early).toFixed(1));

    const early_walk = parseFloat(
      (MCRandom.randomInt(50, 70) / 100).toFixed(2)
    );
    const early_bus = parseFloat((MCRandom.randomInt(15, 35) / 100).toFixed(2));
    const early_drive = parseFloat((1 - early_walk - early_bus).toFixed(2));

    const late_bus = parseFloat((MCRandom.randomInt(15, 35) / 100).toFixed(2));
    const late_drive = parseFloat(
      (MCRandom.randomInt(50, 70) / 100).toFixed(2)
    );
    const late_walk = parseFloat((1 - late_bus - late_drive).toFixed(2));

    const i_ans = late * late_drive;
    const ii_ans = late * late_bus + early * early_bus;
    const iii_ans = 1 - early * early_walk - late * late_walk;
    /* END VARIABLES AND CONSTANTS */
    if (early_drive > 0.05 && late_walk > 0.05) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `On any given day, the probability that ${person.name} wakes up early is $${early}$`,
        `When ${person.name} wakes up early the probabilities of ${person.himher} walking, getting the bus or driving to work are $${early_walk},${early_bus}$ and $${early_drive}$ respectively.`,
        `Otherwise, the probabilities of ${person.himher} walking, getting the bus or driving to work are $${late_walk},${late_bus}$ and $${late_drive}$ respectively.`,
        `a) Draw a probability tree to represent this information.`,
        `b) Find the probability that ${person.name}:`,
        `i) gets up late and drives.`,
        `ii) gets the bus.`,
        `iii) doesn't walk to work.`,
      ]);

      // solution
      // a
      question.addHeading(`solution`, `a) Probability tree.`);
      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/2x3.svg`,
        "height",
        20
      );

      tree.addOverlay(`$\\text{Early}$`, 49, 26, 0.6);
      tree.addOverlay(`$\\text{Late}$`, 49, 68, 0.6);

      tree.addOverlay(`$\\text{Walk}$`, 92, 12, 0.6);
      tree.addOverlay(`$\\text{Bus}$`, 92, 27, 0.6);
      tree.addOverlay(`$\\text{Drive}$`, 92, 42, 0.6);
      tree.addOverlay(`$\\text{Walk}$`, 92, 54, 0.6);
      tree.addOverlay(`$\\text{Bus}$`, 92, 69, 0.6);
      tree.addOverlay(`$\\text{Drive}$`, 92, 84, 0.6);

      tree.addOverlay(`$${early}$`, 25, 30, 1);
      tree.addOverlay(`$${late}$`, 25, 64, 1);

      tree.addOverlay(`$${early_walk}$`, 72, 14, 1);
      tree.addOverlay(`$${early_bus}$`, 78, 24, 1);
      tree.addOverlay(`$${early_drive}$`, 72, 40, 1);

      tree.addOverlay(`$${late_walk}$`, 72, 56, 1);
      tree.addOverlay(`$${late_bus}$`, 78, 66, 1);
      tree.addOverlay(`$${late_drive}$`, 72, 82, 1);

      question.addImage(`solution`, tree);
      // b
      question.addHeading(`solution`, `b) Calculating probabilities.`);
      // i
      question.addHeading(`solution`, `i) Late and drives.`);
      if (parseFloat(i_ans.toFixed(2)) !== i_ans) {
        question.addParagraph(
          `solution`,
          `$${late}(${late_drive})=${i_ans.toFixed(3)}$`
        );
        question.addParagraph(`solution`, `$\\approx ${i_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          `solution`,
          `$${late}(${late_drive})=${i_ans.toFixed(2)}$`
        );
      }
      // ii
      question.addHeading(
        `solution`,
        `ii) Late and gets the bus OR early and gets the bus.`
      );
      if (parseFloat(ii_ans.toFixed(2)) !== ii_ans) {
        question.addParagraph(
          `solution`,
          `$${late}(${late_bus}) + ${early}(${early_bus})=${ii_ans.toFixed(3)}$`
        );
        question.addParagraph(`solution`, `$\\approx ${ii_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          `solution`,
          `$${late}(${late_bus}) + ${early}(${early_bus})=${ii_ans.toFixed(2)}$`
        );
      }
      // iii
      question.addHeading(
        `solution`,
        `iii) Easier to calculate; $1-$ probability of walking.`
      );
      if (parseFloat(iii_ans.toFixed(2)) !== iii_ans) {
        question.addParagraph(
          `solution`,
          `$1 - ${early}(${early_walk}) - ${late}(${late_walk})=${iii_ans.toFixed(
            3
          )}$`
        );
        question.addParagraph(`solution`, `$\\approx ${iii_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          `solution`,
          `$1 - ${early}(${early_walk}) - ${late}(${late_walk})=${iii_ans.toFixed(
            2
          )}$`
        );
      }
      // answers
      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(parseFloat(i_ans.toFixed(2)), 0.005, `b)(i)`);
      g1.addInput(parseFloat(ii_ans.toFixed(2)), 0.005, `(ii)`);
      g1.addInput(parseFloat(iii_ans.toFixed(2)), 0.005, `(iii)`);
      question.addInputGroup(g1);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const person = new MCMisc.getName();

    const redSweets = MCRandom.randomInt(10, 20);
    const blueSweets = MCRandom.randomInt(10, 20);
    const totalSweets = redSweets + blueSweets;

    /* Probabily tree diagram/key for variable names:

            -p3
        -p1-
      -     -p4
    -
      -     -p5
        -p2-
            -p6

    */

    const p1 = new MCMaths.Fraction(redSweets / totalSweets);
    const p2 = new MCMaths.Fraction(blueSweets / totalSweets);

    const p3 = new MCMaths.Fraction((redSweets - 1) / (totalSweets - 1));
    const p4 = new MCMaths.Fraction(blueSweets / (totalSweets - 1));

    const p5 = new MCMaths.Fraction(redSweets / (totalSweets - 1));
    const p6 = new MCMaths.Fraction((blueSweets - 1) / (totalSweets - 1));

    const part_b_answer = new MCMaths.Fraction(
      (p1.numerator * p3.numerator) / (p1.denominator * p3.denominator)
    );

    const part_c_answer = new MCMaths.Fraction(
      (p2.numerator * p4.numerator) / (p2.denominator * p4.denominator)
    );

    const part_d_answer = new MCMaths.Fraction(
      part_b_answer.toFloat() + part_c_answer.toFloat()
    );

    /* END VARIABLES AND CONSTANTS */
    if (
      redSweets !==
      blueSweets /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A bag of $${totalSweets}$ sweets contains $${redSweets}$ red sweets and $${blueSweets}$ blue sweets.`,
        `${person.name} chooses a sweet at random and eats it, followed by another one.`,
        `a) Draw a tree diagram to represent this information.`,
        `Find the probability that ${person.name} eats:`,
        `b) A red sweet followed by a blue sweet`,
        `c) Two blue sweets`,
        `d) A red sweet followed by a blue sweet or two blue sweets.`,
        `Input your answers as 'a/b' where a is the numerator and b is the denominator. E.g. $\\frac{3}{15}$ should be inputted as $3/15$.`,
      ]);

      // Instantiate image object called tree
      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/2x2.svg`,
        "height",
        20
      );
      // First Branch
      // (Labels)
      tree.addOverlay(`$\\text{Red}$`, 43, 25, 1);
      tree.addOverlay(`$\\text{Blue}$`, 43, 70, 1);
      // (Probabilities)
      tree.addOverlay(`$${p1.toString()}$`, 17, 27, 1.5);
      tree.addOverlay(`$${p2.toString()}$`, 17, 65, 1.5);

      // Second Branch
      // (Labels)
      tree.addOverlay(`$\\text{Red}$`, 85, 10, 1);
      tree.addOverlay(`$\\text{Blue}$`, 85, 40, 1);
      tree.addOverlay(`$\\text{Red}$`, 85, 55, 1);
      tree.addOverlay(`$\\text{Blue}$`, 85, 85, 1);
      // (Probabilities)
      tree.addOverlay(`$${p3.toString()}$`, 60, 13, 1.5);
      tree.addOverlay(`$${p4.toString()}$`, 60, 40, 1.5);
      tree.addOverlay(`$${p5.toString()}$`, 60, 55, 1.5);
      tree.addOverlay(`$${p6.toString()}$`, 60, 82, 1.5);

      question.addHeading("solution", `a) Tree Diagram:`);

      // Add image to the solution
      question.addImage("solution", tree);

      question.addMultipleParagraphs("solution", [
        `b) $\\displaystyle ${p1.toString()} \\times ${p3.toString()} = ${part_b_answer.toString()}$`,
        `c) $\\displaystyle ${p2.toString()} \\times ${p4.toString()} = ${part_c_answer.toString()}$`,
        `d) $\\displaystyle ${part_b_answer.toString()} + ${part_c_answer.toString()} = ${part_d_answer.toString()}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput(
        parseFloat(part_b_answer.toFloat().toPrecision(3)),
        0.005,
        `b)`
      );
      group1.addInput(
        parseFloat(part_c_answer.toFloat().toPrecision(3)),
        0.005,
        `c)`
      );
      group1.addInput(
        parseFloat(part_d_answer.toFloat().toPrecision(3)),
        0.005,
        `d)`
      );
      question.addInputGroup(group1);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const person1 = MCMisc.getName();
    const person2 = MCMisc.getName();

    /* Probabily tree diagram/key for variable names:

            -p3
        -p1-
      -     -p4
    -
      -     -p5
        -p2-
            -p6

    */

    const p1 = new MCMaths.Fraction(MCRandom.randomInt(1, 10) / 100);
    const p2 = new MCMaths.Fraction(1 - p1.toFloat());

    const p3 = new MCMaths.Fraction(MCRandom.randomInt(90, 99) / 100);
    const p4 = new MCMaths.Fraction(1 - p3.toFloat());

    const p6 = new MCMaths.Fraction(MCRandom.randomInt(90, 99) / 100);
    const p5 = new MCMaths.Fraction(1 - p6.toFloat());

    const part_b_answer = new MCMaths.Fraction(
      (p1.numerator * p4.numerator) / (p1.denominator * p4.denominator)
    );
    const part_c_answer = new MCMaths.Fraction(
      part_b_answer.toFloat() +
        (p2.numerator * p6.numerator) / (p2.denominator * p6.denominator)
    );

    const check =
      p1.toFloat() * (p3.toFloat() + p4.toFloat()) +
      p2.toFloat() * (p5.toFloat() + p6.toFloat());
    /* END VARIABLES AND CONSTANTS */
    if (
      check === 1 &&
      part_b_answer.toFloat() >
        0.001 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A virus has spread through the population and is said to be present in $${p1.numerator}\\%$ of the population.`,
        `The scientific community has developed a test, however, it is not always $100\\%$ accurate in prediciting whether someone has the virus or not.`,
        `If a person has the virus, the test is positive with probability $${p3
          .toFloat()
          .toFixed(2)}$.`,
        `If a person does not have the virus, the test is negative with probability $${p6
          .toFloat()
          .toFixed(2)}$.`,

        `a) Draw a tree diagram to represent this information.`,
        `Given that ${person1.name} is randomly selected from the population and is said to have the virus.`,
        `${person1.name} is then tested.`,
        `b) Find the probability that ${person1.name} tests negative.`,
        `Now given that ${person2.name} is randomly selected from the population but it is unknown whether ${person2.heshe} has the virus.`,
        `c) Find the probability that ${person2.name} tests negative.`,
        `Give your answers to $3$ significant figures.`,
      ]);

      // Instantiate image object called tree
      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/2x2.svg`,
        "height",
        20
      );
      // First Branch
      // (Labels)
      tree.addOverlay(`$V$`, 43, 25, 1.5);
      tree.addOverlay(`$V'$`, 43, 70, 1.5);
      // (Probabilities)
      tree.addOverlay(`$${p1.toFloat().toFixed(2)}$`, 17, 27, 1.5);
      tree.addOverlay(`$${p2.toFloat().toFixed(2)}$`, 17, 65, 1.5);

      // Second Branch
      // (Labels)
      tree.addOverlay(`$\\text{Positive}$`, 90, 10, 1);
      tree.addOverlay(`$\\text{Negative}$`, 90, 40, 1);
      tree.addOverlay(`$\\text{Positive}$`, 90, 55, 1);
      tree.addOverlay(`$\\text{Negative}$`, 90, 85, 1);
      // (Probabilities)
      tree.addOverlay(`$${p3.toFloat().toFixed(2)}$`, 60, 13, 1.5);
      tree.addOverlay(`$${p4.toFloat().toFixed(2)}$`, 60, 40, 1.5);
      tree.addOverlay(`$${p5.toFloat().toFixed(2)}$`, 60, 55, 1.5);
      tree.addOverlay(`$${p6.toFloat().toFixed(2)}$`, 60, 82, 1.5);

      question.addHeading("solution", `a) Tree Diagram:`);
      question.addParagraph(
        "solution",
        `Let $V$ be the event that someone has the virus.`
      );

      // Add image to the solution
      question.addImage("solution", tree);

      question.addMultipleParagraphs("solution", [
        `b) $\\displaystyle ${p1
          .toFloat()
          .toFixed(2)} \\times ${p4
          .toFloat()
          .toFixed(2)} = ${part_b_answer.toFloat().toPrecision(3)}$`,
        `c) $\\displaystyle (${p2
          .toFloat()
          .toFixed(2)} \\times ${p6
          .toFloat()
          .toFixed(2)}) + ${part_b_answer
          .toFloat()
          .toPrecision(3)} = ${part_c_answer.toFloat().toPrecision(3)}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        parseFloat(part_b_answer.toFloat().toPrecision(3)),
        0.0005,
        `b)`
      );
      group1.addInput(
        parseFloat(part_c_answer.toFloat().toPrecision(3)),
        0.005,
        `c)`
      );
      question.addInputGroup(group1);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const person1 = new MCMisc.getName();
    const numOfSides = MCRandom.randomInt(8, 16, 2);
    const x = MCRandom.randomInt(2, 5);

    /* Probabily tree diagram/key for variable names:

            -p3
        -p1-
      -     -p4
    -
      -     -p5
        -p2-
            -p6

    */

    const p1 = new MCMaths.Fraction(1 / 2);
    const p2 = new MCMaths.Fraction(1 / 2);

    const p3 = new MCMaths.Fraction((6 - x) / 6);
    const p4 = new MCMaths.Fraction(x / 6);

    const p6 = new MCMaths.Fraction((numOfSides - x) / numOfSides);
    const p5 = new MCMaths.Fraction(x / numOfSides);

    const part_b_answer = new MCMaths.Fraction(
      (p1.numerator * p4.numerator) / (p1.denominator * p4.denominator)
    );
    const part_c_answer = new MCMaths.Fraction(
      (p2.numerator * p6.numerator) / (p2.denominator * p6.denominator)
    );

    const part_d_answer = new MCMaths.Fraction(
      part_b_answer.toFloat() + part_c_answer.toFloat()
    );

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      // Toss a coin, if heads then use distribution A, if tails use distribution B
      // I.e. heads -> die labelled from 1-6
      // tails -> die labelled from 1-12
      // prob of tossing heads and writing down 5
      // prob of tossing tails and writing down 9
      // prob of writing down 3
      question.addMultipleParagraphs("question", [
        `${person1.name} is playing a turn-based game that uses a coin, a $6$ sided die and a $${numOfSides}$ sided die.`,
        `${person1.name} flips a coin, if the coin shows heads, then ${person1.heshe} rolls the $6$ sided die.`,
        `On the other hand, if the coin shows tails, then ${person1.name} rolls the $${numOfSides}$ sided die.`,
        `If ${person1.name} rolls $${x}$ or less then ${person1.heshe} must skip a turn in the next round.`,
        `a) Draw a tree diagram to represent this information.`,
        `Find the probability that ${person1.name} must skip a turn if when ${person1.heshe} flips the coin it shows:`,
        `b) Heads`,
        `c) Tails`,
        `d) Find the probability that ${person1.name} must skip a turn.`,
      ]);

      // Instantiate image object called tree
      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/2x2.svg`,
        "height",
        20
      );
      // First Branch
      // (Labels)
      tree.addOverlay(`$\\text{Heads}$`, 43, 25, 1);
      tree.addOverlay(`$\\text{Tails}$`, 43, 70, 1);
      // (Probabilities)
      tree.addOverlay(`$${p1.toString()}$`, 17, 27, 1.5);
      tree.addOverlay(`$${p2.toString()}$`, 17, 65, 1.5);

      // Second Branch
      // (Labels)
      tree.addOverlay(`$\\text{Do not skip}$`, 92, 10, 1);
      tree.addOverlay(`$\\text{Skip}$`, 85, 40, 1);
      tree.addOverlay(`$\\text{Do not skip}$`, 92, 55, 1);
      tree.addOverlay(`$\\text{Skip}$`, 85, 85, 1);
      // (Probabilities)
      tree.addOverlay(`$${p3.toString()}$`, 60, 13, 1.5);
      tree.addOverlay(`$${p4.toString()}$`, 60, 40, 1.5);
      tree.addOverlay(`$${p5.toString()}$`, 60, 55, 1.5);
      tree.addOverlay(`$${p6.toString()}$`, 60, 82, 1.5);

      question.addHeading("solution", `a) Tree Diagram:`);

      // Add image to the solution
      question.addImage("solution", tree);

      question.addMultipleParagraphs("solution", [
        `b) $\\displaystyle ${p1.toString()} \\times ${p4.toString()} = ${part_b_answer.toString()}$`,
        `c) $\\displaystyle ${p2.toString()} \\times ${p6.toString()} = ${part_c_answer.toString()}$`,
        `d) $\\displaystyle ${part_b_answer.toString()} + ${part_c_answer.toString()} = ${part_d_answer.toString()}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(3);
      group1.addInput(parseFloat(part_b_answer.toFloat()), 0.005, `b)`);
      group1.addInput(parseFloat(part_c_answer.toFloat()), 0.005, `c)`);
      group1.addInput(parseFloat(part_d_answer.toFloat()), 0.005, `d)`);
      question.addInputGroup(group1);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    // const totalNumOfCounters = MCRandom.randomInt(30, 60);
    const redCounters = MCRandom.randomInt(10, 20);
    const greenCounters = MCRandom.randomInt(10, 20);
    const blueCounters = MCRandom.randomInt(10, 20);

    const totalNumOfCounters = redCounters + greenCounters + blueCounters;

    const zeroLabelProbability = MCRandom.randomInt(30, 70) / 100;
    const oneLabelProbability = 1 - zeroLabelProbability;

    const redCountersProbability = new MCMaths.Fraction(
      redCounters / totalNumOfCounters
    );
    const greenCountersProbability = new MCMaths.Fraction(
      greenCounters / totalNumOfCounters
    );
    const blueCountersProbability = new MCMaths.Fraction(
      blueCounters / totalNumOfCounters
    );

    const part_b_answer =
      redCountersProbability.toFloat() * zeroLabelProbability;
    const part_c_answer =
      blueCountersProbability.toFloat() * oneLabelProbability +
      greenCountersProbability.toFloat() * zeroLabelProbability;

    /* END VARIABLES AND CONSTANTS */
    if (
      zeroLabelProbability !==
      0.5 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addMultipleParagraphs("question", [
        `A bag contains red, green and blue coloured counters with either $0$ or $1$ labelled on them.`,
        `There are $${totalNumOfCounters}$ counters in the bag in total, of which $${redCounters}$ are red, $${greenCounters}$ are green and $${blueCounters}$ are blue`,
        `The percentage of counters labelled with a $0$ is $${(
          zeroLabelProbability * 100
        ).toFixed(0)}\\%$, the rest are labelled with a $1$`,
        `a) Draw a tree diagram that represents this information`,
        `Using the tree diagram calculate:`,
        `b) The probability of drawing a red counter with a $0$ label.`,
        `c) The probability of drawing a blue counter with a $1$ label or drawing a green counter with a $0$ label.`,
        `Give your answers to $3$ significant figures.`,
      ]);

      question.addParagraph("solution", `a)`);

      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/3x2.svg`,
        "height",
        25
      );

      tree.addOverlay(`$\\text{Red}$`, 46, 15);
      tree.addOverlay(`$\\text{Green}$`, 46, 48);
      tree.addOverlay(`$\\text{Blue}$`, 46, 85);

      tree.addOverlay(`$\\text{Zero}$`, 92, 8);
      tree.addOverlay(`$\\text{One}$`, 92, 25);

      tree.addOverlay(`$\\text{Zero}$`, 92, 8 + 34);
      tree.addOverlay(`$\\text{One}$`, 92, 25 + 34);

      tree.addOverlay(`$\\text{Zero}$`, 92, 8 + 2 * 34);
      tree.addOverlay(`$\\text{One}$`, 92, 25 + 2 * 34);

      tree.addOverlay(`$${redCountersProbability.toString()}$`, 20, 28, 1.5);
      tree.addOverlay(`$${greenCountersProbability.toString()}$`, 25, 45, 1.5);
      tree.addOverlay(`$${blueCountersProbability.toString()}$`, 20, 75, 1.5);

      tree.addOverlay(`$${zeroLabelProbability.toFixed(2)}$`, 70, 8, 1);
      tree.addOverlay(`$${oneLabelProbability.toFixed(2)}$`, 70, 25, 1);

      tree.addOverlay(`$${zeroLabelProbability.toFixed(2)}$`, 70, 8 + 34, 1);
      tree.addOverlay(`$${oneLabelProbability.toFixed(2)}$`, 70, 25 + 34, 1);

      tree.addOverlay(
        `$${zeroLabelProbability.toFixed(2)}$`,
        70,
        8 + 2 * 34,
        1
      );
      tree.addOverlay(
        `$${oneLabelProbability.toFixed(2)}$`,
        70,
        25 + 2 * 34,
        1
      );

      question.addImage("solution", tree);

      question.addMultipleParagraphs("solution", [
        `b) $${redCountersProbability.toString()} \\times ${zeroLabelProbability.toFixed(
          2
        )} = ${part_b_answer.toPrecision(3)}$`,
        `c) $ \\left( ${blueCountersProbability.toString()} \\times ${oneLabelProbability.toFixed(
          2
        )} \\right) + \\left(${greenCountersProbability.toString()} \\times ${zeroLabelProbability.toFixed(
          2
        )}\\right) = ${part_c_answer.toPrecision(3)}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(parseFloat(part_b_answer.toPrecision(3)), 0.01, `b)`);
      group1.addInput(parseFloat(part_c_answer.toPrecision(3)), 0.01, `c)`);

      question.addInputGroup(group1);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const A = parseFloat((MCRandom.randomInt(2, 8) / 10).toFixed(1));
    const B = parseFloat((1 - A).toFixed(1));

    const XA = parseFloat((MCRandom.randomInt(2, 8) / 10).toFixed(1));
    const YA = parseFloat((1 - XA).toFixed(1));

    const XB = parseFloat((MCRandom.randomInt(2, 8) / 10).toFixed(1));
    const YB = parseFloat((1 - XB).toFixed(1));

    const XorY = ["X", "Y"][MCRandom.randomInt(0, 1)];
    const checkArr = [];
    if (XorY === "Y") {
      checkArr.push(
        parseFloat((A * YA + XB * YB).toFixed(2)),
        parseFloat((B * YB).toFixed(2)),
        parseFloat((A * YA).toFixed(2)),
        YA
      );
    } else {
      checkArr.push(
        parseFloat((A * XA + B * XB).toFixed(2)),
        parseFloat((B * XB).toFixed(2)),
        parseFloat((A * XA).toFixed(2)),
        XA
      );
    }

    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }
    /* END VARIABLES AND CONSTANTS */
    if (XA !== XB && allDifferent(checkArr)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/2x2.svg`,
        "height",
        20
      );

      tree.addOverlay(`$A$`, 44, 26, 1.5);
      tree.addOverlay(`$B$`, 44, 67.5, 1.5);
      tree.addOverlay(`$X$`, 86, 12, 1.5);
      tree.addOverlay(`$Y$`, 86, 42, 1.5);
      tree.addOverlay(`$X$`, 86, 54, 1.5);
      tree.addOverlay(`$Y$`, 86, 84, 1.5);

      tree.addOverlay(`$${A}$`, 15, 32, 1.2);
      tree.addOverlay(`$${B}$`, 15, 64, 1.2);
      tree.addOverlay(`$${XA}$`, 65, 14, 1.2);
      tree.addOverlay(`$${YA}$`, 65, 40, 1.2);
      tree.addOverlay(`$${XB}$`, 65, 56, 1.2);
      tree.addOverlay(`$${YB}$`, 65, 80, 1.2);

      question.addImage(tree);
      question.addParagraph(
        `The probability tree above shows the probabilities of the events $A,B,X$ and $Y$ occuring.`
      );
      question.addParagraph(
        `Select the probability of event $${XorY}$ occuring.`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`$${checkArr[0]}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`$${checkArr[1]}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`$${checkArr[2]}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${checkArr[3]}$`);

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(20, 80) / 100;
    const a_prob = new MCMaths.Fraction(a);

    const b = 1 - a;
    const b_prob = new MCMaths.Fraction(b);

    const x = MCRandom.randomInt(10, 90) / 100;
    const y = 1 - x;

    const correctAns = a_prob.toFloat() * x + b_prob.toFloat() * y;
    const incorrectAns1 = a_prob.toFloat() * x;
    const incorrectAns2 = b_prob.toFloat() * y;
    const incorrectAns3 = a_prob.toFloat() * x * (b_prob.toFloat() * y);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/2x2.svg`,
        "height",
        20
      );

      tree.addOverlay(`$A$`, 43, 25, 1.5);
      tree.addOverlay(`$B$`, 43, 70, 1.5);

      tree.addOverlay(`$X$`, 85, 12, 1.5);
      tree.addOverlay(`$Y$`, 85, 40, 1.5);

      tree.addOverlay(`$X$`, 85, 53, 1.5);
      tree.addOverlay(`$Y$`, 85, 83, 1.5);

      tree.addOverlay(`$${a_prob.toString()}$`, 18, 25, 1.5);
      tree.addOverlay(`$${b_prob.toString()}$`, 18, 69, 1.5);

      tree.addOverlay(`$${x.toFixed(2)}$`, 65, 13);
      tree.addOverlay(`$${y.toFixed(2)}$`, 65, 40);

      tree.addOverlay(`$${x.toFixed(2)}$`, 65, 13 + 42);
      tree.addOverlay(`$${y.toFixed(2)}$`, 65, 40 + 42);

      question.addImage(tree);

      question.addMultipleParagraphs([
        `The probability tree above shows the probabilities of the events $A$ , $B$ , $X$ and $Y$ occuring.`,
        `Select the probability of event $A$ and $X$ occuring or $B$ and $Y$ occuring.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${correctAns.toPrecision(3)}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${incorrectAns1.toPrecision(3)}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${incorrectAns2.toPrecision(3)}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${incorrectAns3.toPrecision(3)}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(20, 80) / 100;
    const a_prob = new MCMaths.Fraction(a);

    const b = 1 - a;
    const b_prob = new MCMaths.Fraction(b);

    // (Big X and Y)
    const x = MCRandom.randomInt(10, 90) / 100;
    const y = 1 - x;

    const working1 = a_prob.toFloat() * y;

    // (Little y)
    const correctAns = (a_prob.denominator * working1) / a_prob.numerator;
    const incorrectAns1 = (a_prob.numerator * working1) / a_prob.denominator;
    const incorrectAns2 = working1 / a_prob.numerator;
    const incorrectAns3 = working1;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/2x2.svg`,
        "height",
        20
      );

      tree.addOverlay(`$A$`, 43, 25, 1.5);
      tree.addOverlay(`$B$`, 43, 70, 1.5);

      tree.addOverlay(`$X$`, 85, 12, 1.5);
      tree.addOverlay(`$Y$`, 85, 40, 1.5);

      tree.addOverlay(`$X$`, 85, 53, 1.5);
      tree.addOverlay(`$Y$`, 85, 83, 1.5);

      tree.addOverlay(`$${a_prob.toString()}$`, 18, 25, 1.5);
      tree.addOverlay(`$${b_prob.toString()}$`, 18, 69, 1.5);

      tree.addOverlay(`$x$`, 65, 13);
      tree.addOverlay(`$y$`, 65, 40);

      tree.addOverlay(`$x$`, 65, 13 + 42);
      tree.addOverlay(`$y$`, 65, 40 + 42);

      question.addImage(tree);

      question.addMultipleParagraphs([
        `The probability tree above shows the probabilities of the events $A$ , $B$ , $X$ and $Y$ occuring.`,
        `Given that the probability of event $A$ occuring followed by event $Y$ is $${(
          a_prob.toFloat() * y
        ).toPrecision(3)}$,`,
        `Choose the correct value of $y$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${correctAns.toPrecision(3)}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${incorrectAns1.toPrecision(3)}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${incorrectAns2.toPrecision(3)}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${incorrectAns3.toPrecision(3)}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(20, 80) / 100;
    const a_prob = new MCMaths.Fraction(a);

    const b = 1 - a;
    const b_prob = new MCMaths.Fraction(b);

    // (Big X and Y)
    const x = MCRandom.randomInt(10, 90) / 100;
    const y = 1 - x;

    const working1 = b_prob.toFloat() * x;

    // (Little x)
    const correctAns = (b_prob.denominator * working1) / b_prob.numerator;
    const incorrectAns1 = (a_prob.numerator * working1) / a_prob.denominator;
    const incorrectAns2 = working1 / b_prob.numerator;
    const incorrectAns3 = working1;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/2x2.svg`,
        "height",
        20
      );

      tree.addOverlay(`$A$`, 43, 25, 1.5);
      tree.addOverlay(`$B$`, 43, 70, 1.5);

      tree.addOverlay(`$X$`, 85, 12, 1.5);
      tree.addOverlay(`$Y$`, 85, 40, 1.5);

      tree.addOverlay(`$X$`, 85, 53, 1.5);
      tree.addOverlay(`$Y$`, 85, 83, 1.5);

      tree.addOverlay(`$${a_prob.toString()}$`, 18, 25, 1.5);
      tree.addOverlay(`$${b_prob.toString()}$`, 18, 69, 1.5);

      tree.addOverlay(`$x$`, 65, 13);
      tree.addOverlay(`$y$`, 65, 40);

      tree.addOverlay(`$x$`, 65, 13 + 42);
      tree.addOverlay(`$y$`, 65, 40 + 42);

      question.addImage(tree);

      question.addMultipleParagraphs([
        `The probability tree above shows the probabilities of the events $A$ , $B$ , $X$ and $Y$ occuring.`,
        `Given that the probability of event $B$ occuring followed by event $X$ is $${(
          b_prob.toFloat() * x
        ).toPrecision(3)}$,`,
        `Choose the correct value of $x$`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${correctAns.toPrecision(3)}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${incorrectAns1.toPrecision(3)}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${incorrectAns2.toPrecision(3)}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${incorrectAns3.toPrecision(3)}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(20, 80) / 100;
    const a_prob = new MCMaths.Fraction(a);

    const b = 1 - a;
    const b_prob = new MCMaths.Fraction(b);

    const x = MCRandom.randomInt(10, 90) / 100;
    const y = MCRandom.randomInt(10, 90) / 100;
    const z = 1 - (x + y);

    const correctAns = a_prob.toFloat() * z + b_prob.toFloat() * z;
    const incorrectAns1 = a_prob.toFloat() * z;
    const incorrectAns2 = b_prob.toFloat() * z;
    const incorrectAns3 = a_prob.toFloat() * z * (b_prob.toFloat() * z);

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (
      x + y + z === 1 &&
      z > 0 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/2x3.svg`,
        "height",
        20
      );

      tree.addOverlay(`$A$`, 48, 25, 1.5);
      tree.addOverlay(`$B$`, 48, 70, 1.5);

      tree.addOverlay(`$X$`, 93, 12, 1.5);
      tree.addOverlay(`$Y$`, 93, 25, 1.5);
      tree.addOverlay(`$Z$`, 93, 40, 1.5);

      tree.addOverlay(`$X$`, 93, 53, 1.5);
      tree.addOverlay(`$Y$`, 93, 69, 1.5);
      tree.addOverlay(`$Z$`, 93, 85, 1.5);

      tree.addOverlay(`$${a_prob.toString()}$`, 18, 25, 1.5);
      tree.addOverlay(`$${b_prob.toString()}$`, 18, 69, 1.5);

      tree.addOverlay(`$${x.toFixed(2)}$`, 65, 13);
      tree.addOverlay(`$${y.toFixed(2)}$`, 80, 23);
      tree.addOverlay(`$${z.toFixed(2)}$`, 65, 40);

      tree.addOverlay(`$${x.toFixed(2)}$`, 65, 13 + 42);
      tree.addOverlay(`$${y.toFixed(2)}$`, 80, 23 + 42);
      tree.addOverlay(`$${z.toFixed(2)}$`, 65, 40 + 42);

      question.addImage(tree);

      question.addMultipleParagraphs([
        `The probability tree above shows the probabilities of the events $A$ , $B$ , $X$, $Y$ and $Z$ occuring.`,
        `Select the probability of event $Z$ occuring.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${correctAns.toPrecision(3)}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${incorrectAns1.toPrecision(3)}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${incorrectAns2.toPrecision(3)}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${incorrectAns3.toPrecision(3)}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(20, 80) / 100;
    const a_prob = new MCMaths.Fraction(a);

    const b = 1 - a;
    const b_prob = new MCMaths.Fraction(b);

    const x = MCRandom.randomInt(10, 90) / 100;
    const y = MCRandom.randomInt(10, 90) / 100;
    const z = 1 - (x + y);

    const a_then_z_prob = a_prob.toFloat() * z;

    const correctAns = (a_prob.denominator * a_then_z_prob) / a_prob.numerator;
    const incorrectAns1 =
      (a_prob.numerator * a_then_z_prob) / a_prob.denominator;
    const incorrectAns2 =
      (b_prob.denominator * a_then_z_prob) / a_prob.numerator;
    const incorrectAns3 =
      (a_prob.denominator * a_then_z_prob) / b_prob.numerator;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (
      x + y + z === 1 &&
      z > 0 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/2x3.svg`,
        "height",
        20
      );

      tree.addOverlay(`$A$`, 48, 25, 1.5);
      tree.addOverlay(`$B$`, 48, 70, 1.5);

      tree.addOverlay(`$X$`, 93, 12, 1.5);
      tree.addOverlay(`$Y$`, 93, 25, 1.5);
      tree.addOverlay(`$Z$`, 93, 40, 1.5);

      tree.addOverlay(`$X$`, 93, 53, 1.5);
      tree.addOverlay(`$Y$`, 93, 69, 1.5);
      tree.addOverlay(`$Z$`, 93, 85, 1.5);

      tree.addOverlay(`$${a_prob.toString()}$`, 18, 25, 1.5);
      tree.addOverlay(`$${b_prob.toString()}$`, 18, 69, 1.5);

      tree.addOverlay(`$x$`, 65, 13);
      tree.addOverlay(`$y$`, 80, 23);
      tree.addOverlay(`$z$`, 65, 40);

      tree.addOverlay(`$x$`, 65, 13 + 42);
      tree.addOverlay(`$y$`, 80, 23 + 42);
      tree.addOverlay(`$z$`, 65, 40 + 42);

      question.addImage(tree);

      question.addMultipleParagraphs([
        `The probability tree above shows the probabilities of the events $A$ , $B$ , $X$, $Y$ and $Z$ occuring.`,
        `Given that the probability of event $Z$ occuring after event $A$ occuring is $${a_then_z_prob.toPrecision(
          3
        )}$`,
        `Select the correct value of $z$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${correctAns.toPrecision(3)}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${incorrectAns1.toPrecision(3)}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${incorrectAns2.toPrecision(3)}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${incorrectAns3.toPrecision(3)}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_MCQ_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(20, 80) / 100;
    const a_prob = new MCMaths.Fraction(a);

    const b = MCRandom.randomInt(20, 80) / 100;
    const b_prob = new MCMaths.Fraction(b);

    const c = MCRandom.randomInt(20, 80) / 100;
    const c_prob = new MCMaths.Fraction(c);

    const x = MCRandom.randomInt(10, 90) / 100;
    const y = MCRandom.randomInt(10, 90) / 100;

    const correctAns = a_prob.toFloat() * y + b_prob.toFloat() * y;
    const incorrectAns1 = a_prob.toFloat() * x + b_prob.toFloat() * y;
    const incorrectAns2 = a_prob.toFloat() * y + b_prob.toFloat() * x;
    const incorrectAns3 =
      a_prob.toFloat() * y + b_prob.toFloat() * y + c_prob.toFloat() * y;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (
      a + b + c === 1 &&
      x + y === 1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/3x2_MCQ.svg`,
        "height",
        20
      );

      tree.addOverlay(`$A$`, 52, 15, 1.5);
      tree.addOverlay(`$B$`, 52, 50, 1.5);
      tree.addOverlay(`$C$`, 52, 87, 1.5);

      tree.addOverlay(`$X$`, 93, 0, 1.5);
      tree.addOverlay(`$Y$`, 93, 28, 1.5);

      tree.addOverlay(`$X$`, 93, 37, 1.5);
      tree.addOverlay(`$Y$`, 93, 62, 1.5);

      tree.addOverlay(`$X$`, 93, 70, 1.5);
      tree.addOverlay(`$Y$`, 93, 100, 1.5);

      tree.addOverlay(`$${a_prob.toString()}$`, 18, 25, 1.5);
      tree.addOverlay(`$${b_prob.toString()}$`, 30, 40, 1.5);
      tree.addOverlay(`$${c_prob.toString()}$`, 18, 71, 1.5);

      tree.addOverlay(`$${x.toFixed(2)}$`, 74, 2);
      tree.addOverlay(`$${y.toFixed(2)}$`, 74, 30);

      tree.addOverlay(`$${x.toFixed(2)}$`, 74, 2 + 35);
      tree.addOverlay(`$${y.toFixed(2)}$`, 74, 30 + 35);

      tree.addOverlay(`$${x.toFixed(2)}$`, 74, 2 + 70);
      tree.addOverlay(`$${y.toFixed(2)}$`, 74, 30 + 70);

      question.addImage(tree);

      question.addMultipleParagraphs([
        `The probability tree above shows the probabilities of the events $A$ , $B$ , $X$, $Y$ and $Z$ occuring.`,
        `Select the probability of event $Y$ occuring after event $A$ or $B$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${correctAns.toPrecision(3)}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${incorrectAns1.toPrecision(3)}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${incorrectAns2.toPrecision(3)}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${incorrectAns3.toPrecision(3)}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_TreeDiagrams_MCQ_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(20, 80) / 100;
    const a_prob = new MCMaths.Fraction(a);

    const b = MCRandom.randomInt(20, 80) / 100;
    const b_prob = new MCMaths.Fraction(b);

    const c = MCRandom.randomInt(20, 80) / 100;
    const c_prob = new MCMaths.Fraction(c);

    const x = MCRandom.randomInt(10, 90) / 100;
    const y = MCRandom.randomInt(10, 90) / 100;

    const correctAns = b_prob.toFloat() * x + c_prob.toFloat() * x;
    const incorrectAns1 = a_prob.toFloat() * x + c_prob.toFloat() * x;
    const incorrectAns2 = b_prob.toFloat() * x + b_prob.toFloat() * y;
    const incorrectAns3 =
      b_prob.toFloat() * x + c_prob.toFloat() * x + a_prob.toFloat() * y;

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (
      a + b + c === 1 &&
      x + y === 1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      const tree = new MCQuestion.Image(
        `Leo/TreeDiagrams/3x2_MCQ.svg`,
        "height",
        20
      );

      tree.addOverlay(`$A$`, 52, 15, 1.5);
      tree.addOverlay(`$B$`, 52, 50, 1.5);
      tree.addOverlay(`$C$`, 52, 87, 1.5);

      tree.addOverlay(`$X$`, 93, 0, 1.5);
      tree.addOverlay(`$Y$`, 93, 28, 1.5);

      tree.addOverlay(`$X$`, 93, 37, 1.5);
      tree.addOverlay(`$Y$`, 93, 62, 1.5);

      tree.addOverlay(`$X$`, 93, 70, 1.5);
      tree.addOverlay(`$Y$`, 93, 100, 1.5);

      tree.addOverlay(`$${a_prob.toString()}$`, 18, 25, 1.5);
      tree.addOverlay(`$${b_prob.toString()}$`, 30, 40, 1.5);
      tree.addOverlay(`$${c_prob.toString()}$`, 18, 71, 1.5);

      tree.addOverlay(`$${x.toFixed(2)}$`, 74, 2);
      tree.addOverlay(`$${y.toFixed(2)}$`, 74, 30);

      tree.addOverlay(`$${x.toFixed(2)}$`, 74, 2 + 35);
      tree.addOverlay(`$${y.toFixed(2)}$`, 74, 30 + 35);

      tree.addOverlay(`$${x.toFixed(2)}$`, 74, 2 + 70);
      tree.addOverlay(`$${y.toFixed(2)}$`, 74, 30 + 70);

      question.addImage(tree);

      question.addMultipleParagraphs([
        `The probability tree above shows the probabilities of the events $A$ , $B$ , $X$, $Y$ and $Z$ occuring.`,
        `Select the probability of event $X$ occuring after event $B$ or $C$.`,
      ]);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${correctAns.toPrecision(3)}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${incorrectAns1.toPrecision(3)}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${incorrectAns2.toPrecision(3)}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${incorrectAns3.toPrecision(3)}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_VennDiagrams_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    // f is football, r is rugby, c is cricket
    const all = MCRandom.randomInt(2, 8);

    const fr = MCRandom.randomInt(5, 12);
    const fc = MCRandom.randomInt(5, 12);
    const cr = MCRandom.randomInt(5, 12);

    const f = MCRandom.randomInt(8, 15);
    const c = MCRandom.randomInt(8, 15);
    const r = MCRandom.randomInt(8, 15);

    const none = MCRandom.randomInt(5, 15);

    const n = all + fr + fc + cr + f + c + r + none;
    const F = f + fr + all + fc;
    const R = fr + all + r + cr;
    const C = all + fc + cr + c;

    const sports = ["football", "rugby", "cricket"];
    const sport1 = sports[MCRandom.randomInt(0, 2)];
    const sport2 = sports[MCRandom.randomInt(0, 2)];

    const dict = {
      football_TV: ["F", F],
      rugby_TV: ["R", R],
      cricket_TV: ["C", C],
      footballcricket: [
        `$F=${f}+${fr}+${all}+${fc}$`,
        `$\\therefore F=${F}$`,
        `Find the number of people who watch both football and cricket, and divide by $F$`,
        `$\\displaystyle\\frac{${all}+${fc}}{${F}}$`,
        (all + fc) / F,
      ],
      cricketfootball: [
        `$C=${all}+${fc}+${cr}+${c}$`,
        `$\\therefore C=${C}$`,
        `Find the number of people who watch both cricket and football, and divide by $C$`,
        `$\\displaystyle\\frac{${all}+${fc}}{${C}}$`,
        (all + fc) / C,
      ],

      footballrugby: [
        `$F=${f}+${fr}+${all}+${fc}$`,
        `$\\therefore F=${F}$`,
        `Find the number of people who watch both football and rugby, and divide by $F$`,
        `$\\displaystyle\\frac{${all}+${fr}}{${F}}$`,
        (all + fr) / F,
      ],
      rugbyfootball: [
        `$R=${fr}+${all}+${r}+${cr}$`,
        `$\\therefore R=${R}$`,
        `Find the number of people who watch both rugby and football, and divide by $R$`,
        `$\\displaystyle\\frac{${all}+${fr}}{${R}}$`,
        (all + fr) / R,
      ],

      rugbycricket: [
        `$R=${fr}+${all}+${r}+${cr}$`,
        `$\\therefore R=${R}$`,
        `Find the number of people who watch both rugby and cricket, and divide by $R$`,
        `$\\displaystyle\\frac{${all}+${cr}}{${R}}$`,
        (all + cr) / R,
      ],
      cricketrugby: [
        `$C=${all}+${fc}+${cr}+${c}$`,
        `$\\therefore C=${C}$`,
        `Find the number of people who watch both cricket and rugby, and divide by $C$`,
        `$\\displaystyle\\frac{${all}+${cr}}{${C}}$`,
        (all + cr) / C,
      ],
    };

    const i_ans = none / n;
    const ii_ans = (f + c + r) / n;
    const iii_ans = (fr + fc + cr + all) / n;
    /* END VARIABLES AND CONSTANTS */
    if (sport1 !== sport2 && i_ans > 0.05) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `The venn diagram below shows the number of students who watch any of the three sports; football, rugby or cricket.`
      );

      const image = new MCQuestion.Image(`Leo/3Circle.svg`, "height", 40);

      image.addOverlay(`Football`, 50, 10, 1.5);
      image.addOverlay(`Rugby`, 15, 50, 1.5);
      image.addOverlay(`Cricket`, 85, 50, 1.5);

      image.addOverlay(`$${f}$`, 55, 25, 1.8);
      image.addOverlay(`$${r}$`, 30, 60, 1.8);
      image.addOverlay(`$${c}$`, 68, 68, 1.8);

      image.addOverlay(`$${fr}$`, 40, 45, 1.8);
      image.addOverlay(`$${fc}$`, 63, 48, 1.8);
      image.addOverlay(`$${cr}$`, 50, 70, 1.8);

      image.addOverlay(`$${all}$`, 51, 52, 1.8);
      image.addOverlay(`$${none}$`, 20, 20, 2);

      question.addImage("question", image);
      question.addParagraph(`question`, `One student is selected at random.`);
      question.addParagraph(
        "question",
        `a) Find the probability that the student:`
      );
      question.addParagraph(
        "question",
        `i) Doesn't watch any of the three sports.`
      );
      question.addParagraph(
        "question",
        `ii) Watches exactly one of the sports.`
      );
      question.addParagraph(
        "question",
        `iii) Watches more than one of the sports.`
      );
      question.addParagraph(
        "question",
        `b) Given that the student selected watches ${sport1}, find the probability that they also watch ${sport2}.`
      );

      // solution
      // a
      question.addHeading(
        `solution`,
        `a) First find the sample size, $n$ by calculating the sum of all the values in the venn diagram.`
      );
      question.addParagraph(
        `solution`,
        `$n=${none}+${f}+${fr}+${all}+${fc}+${r}+${cr}+${c}$`
      );
      question.addParagraph(`solution`, `$\\therefore n=${n}$`);
      // i
      question.addHeading(
        "solution",
        `i) Probability that the student doesn't watch any of the sports.`
      );
      if (parseFloat(i_ans.toFixed(4)) !== i_ans) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${none}}{${n}}=${i_ans.toFixed(5)}$`
        );
        question.addParagraph("solution", `$\\approx${i_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${none}}{${n}}=${i_ans}$`
        );
      }
      // ii
      question.addHeading(
        "solution",
        `ii) Probability that the student only watches one of the sports.`
      );
      if (parseFloat(ii_ans.toFixed(4)) !== ii_ans) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${f}+${r}+${c}}{${n}}=${ii_ans.toFixed(5)}$`
        );
        question.addParagraph("solution", `$\\approx${ii_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${f}+${r}+${c}}{${n}}=${ii_ans}$`
        );
      }
      // iii
      question.addHeading(
        "solution",
        `iii) Probability that the student watches more than one of the sports.`
      );
      if (parseFloat(iii_ans.toFixed(4)) !== iii_ans) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${fr}+${all}+${fc}+${cr}}{${n}}=${iii_ans.toFixed(
            5
          )}$`
        );
        question.addParagraph("solution", `$\\approx${iii_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${fr}+${all}+${fc}+${cr}}{${n}}=${iii_ans}$`
        );
      }
      // b
      question.addHeading(
        "solution",
        `b) Find the number of students who watch ${sport1}, $${
          dict[`${sport1}_TV`][0]
        }$.`
      );
      question.addMultipleParagraphs(
        "solution",
        dict[sport1 + sport2].slice(0, 4)
      );

      if (
        parseFloat(dict[sport1 + sport2][4].toFixed(3)) !==
        dict[sport1 + sport2][4]
      ) {
        question.addParagraph(
          "solution",
          `$=${dict[sport1 + sport2][4].toFixed(4)}$`
        );
        question.addParagraph(
          "solution",
          `$\\approx${dict[sport1 + sport2][4].toFixed(2)}$`
        );
      } else {
        question.addParagraph("solution", `$=${dict[sport1 + sport2][4]}$`);
      }
      // answers

      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(i_ans, 0.005, `ai)`);
      g1.addInput(ii_ans, 0.005, `aii)`);
      g1.addInput(iii_ans, 0.005, `aiii)`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(parseFloat(dict[sport1 + sport2][4].toFixed(2)), 0.005, `b)`);
      question.addInputGroup(g2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_VennDiagrams_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const fm_and_e = MCRandom.randomInt(10, 40);
    const m_and_e = MCRandom.randomInt(10, 40);

    const m_total = MCRandom.randomInt(m_and_e + 15, m_and_e + 40);
    const fm_total = MCRandom.randomInt(fm_and_e + 15, fm_and_e + 40);
    const e_total = MCRandom.randomInt(
      fm_and_e + m_and_e + 15,
      fm_and_e + m_and_e + 40
    );

    const just_m = m_total - m_and_e;
    const just_fm = fm_total - fm_and_e;
    const just_e = e_total - m_and_e - fm_and_e;

    const none = MCRandom.randomInt(15, 40);
    const total_students =
      just_m + just_fm + just_e + m_and_e + fm_and_e + none;

    const bi_ans = m_total / total_students;
    const bii_ans = m_and_e / total_students;
    const biii_ans = (m_total + fm_total) / total_students;

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `There are $${total_students}$ students at a sixth form college. The headteacher collects data on the number of students currently studying maths, further maths and english. Due to the organisation of the courses, maths and further maths can't be taken simultaneously.`
      );
      question.addParagraph("question", `There are:`);
      question.addParagraph(
        "question",
        `$${m_total}$ students who study maths.`
      );
      question.addParagraph(
        "question",
        `$${fm_total}$ students who study further maths.`
      );
      question.addParagraph(
        "question",
        `$${e_total}$ students who study english.`
      );
      question.addParagraph(
        "question",
        `$${m_and_e}$ students who study maths and english.`
      );
      question.addParagraph(
        "question",
        `$${fm_and_e}$ students who study further maths and english.`
      );
      question.addParagraph(
        "question",
        `a) Draw a venn diagram to represent this information.`
      );
      question.addParagraph("question", `A student is selected at random.`);
      question.addParagraph(
        "question",
        `b) Calculate the probability that the student studies:`
      );
      question.addParagraph("question", `i) maths.`);
      question.addParagraph("question", `ii) english and further maths.`);
      question.addParagraph("question", `iii) maths or further maths.`);
      question.addParagraph("question", `iv) maths and further maths.`);

      // solution
      question.addHeading(
        "solution",
        `a) Let $E,M$ and $F$ be the set of students who study english, maths or further maths respectively.`
      );

      question.addHeading(
        "solution",
        `Finding: $M \\cap \\overline{E} = M - (M \\cap E)$`
      );
      question.addParagraph("solution", `$= ${m_total} - ${m_and_e}$`);
      question.addParagraph("solution", `$= ${just_m}$`);

      question.addHeading(
        "solution",
        `Finding: $E \\cap \\overline{(M \\cup F)} = E - (E \\cap M) - (E \\cap F)$`
      );
      question.addParagraph(
        "solution",
        `$= ${e_total} - ${m_and_e} - ${fm_and_e}$`
      );
      question.addParagraph("solution", `$= ${just_e}$`);

      question.addHeading(
        "solution",
        `Finding: $F \\cap \\overline{E} = F - (F \\cap E)$`
      );
      question.addParagraph("solution", `$= ${fm_total} - ${fm_and_e}$`);
      question.addParagraph("solution", `$= ${just_fm}$`);

      question.addHeading(
        "solution",
        `Finding: $\\overline{(M \\cup E \\cup F)} =$ Total Students - $M \\cup F \\cup (E \\cap \\overline{(M \\cup F)})$`
      );
      question.addParagraph(
        "solution",
        `$= ${total_students} - ${m_total} - ${fm_total} - ${just_e}$`
      );
      question.addParagraph("solution", `$= ${none}$`);

      const image = new MCQuestion.Image(`Leo/3Circle_ME.svg`, "height", 40);

      image.addOverlay(`Maths`, 15, 35, 1.5);
      image.addOverlay(`English`, 50, 13, 1.5);
      image.addOverlay(`Further Maths`, 88, 40, 1.5);

      image.addOverlay(`$${none}$`, 15, 15, 1.8);

      image.addOverlay(`$${just_m}$`, 25, 55, 1.8);
      image.addOverlay(`$${m_and_e}$`, 40, 50, 1.8);
      image.addOverlay(`$${just_e}$`, 50, 35, 1.8);
      image.addOverlay(`$${fm_and_e}$`, 60, 50, 1.8);
      image.addOverlay(`$${just_fm}$`, 75, 60, 1.8);

      question.addImage("solution", image);

      question.addHeading(
        "solution",
        `bi) Probability that the student studies just maths.`
      );
      if (parseFloat(bi_ans.toFixed(4)) !== bi_ans) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${m_total}}{${total_students}}=${bi_ans.toFixed(
            5
          )}$`
        );
        question.addParagraph("solution", `$\\approx${bi_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${m_total}}{${total_students}}=${bi_ans}$`
        );
      }

      question.addHeading(
        "solution",
        `ii) Probability that the student studies english and further maths.`
      );
      if (parseFloat(bii_ans.toFixed(4)) !== bii_ans) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${m_and_e}}{${total_students}}=${bii_ans.toFixed(
            5
          )}$`
        );
        question.addParagraph("solution", `$\\approx${bii_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${m_and_e}}{${total_students}}=${bii_ans}$`
        );
      }

      question.addHeading(
        "solution",
        `iii) Probability that the student studies maths or further maths.`
      );
      if (parseFloat(biii_ans.toFixed(4)) !== biii_ans) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${
            m_total + fm_total
          }}{${total_students}}=${biii_ans.toFixed(5)}$`
        );
        question.addParagraph("solution", `$\\approx${biii_ans.toFixed(2)}$`);
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${
            m_total + fm_total
          }}{${total_students}}=${biii_ans}$`
        );
      }

      question.addHeading(
        "solution",
        `iv) Probability that the student studies maths and further maths.`
      );
      question.addParagraph(
        "solution",
        `The events are mutually exclusive, so the probability is $0$`
      );
      // answers
      question.requiresSelfMarking();

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(bi_ans, 0.005, `bi)`);
      g1.addInput(bii_ans, 0.005, `bii)`);
      g1.addInput(biii_ans, 0.005, `biii)`);
      g1.addInput(0, 0, `biv)`);
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_VennDiagrams_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const X = parseFloat((MCRandom.randomInt(12, 40) / 100).toFixed(2));
    const Y = parseFloat((MCRandom.randomInt(12, 40) / 100).toFixed(2));
    const Z = parseFloat((MCRandom.randomInt(12, 40) / 100).toFixed(2));

    const XandY = parseFloat((MCRandom.randomInt(2, 10) / 100).toFixed(2));
    const YandZ = parseFloat((Y * Z).toFixed(2));

    const XorZ = parseFloat((X + Z).toFixed(2));
    const YorZ = parseFloat((Y + Z - Y * Z).toFixed(2));

    const total = parseFloat((X + Z + (Y - Y * Z - XandY)).toFixed(2));
    const none = parseFloat((1 - total).toFixed(2));

    const justX = parseFloat((X - XandY).toFixed(2));
    const justY = parseFloat((Y - XandY - Y * Z).toFixed(2));
    const justZ = parseFloat((Z - Y * Z).toFixed(2));

    function allPositive(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i] < 0.02) {
          return false;
        }
      }
      return true;
    }

    const positives = [
      X,
      Y,
      Z,
      XandY,
      YandZ,
      XorZ,
      YorZ,
      total,
      none,
      justX,
      justY,
      justZ,
    ];
    /* END VARIABLES AND CONSTANTS */
    if (total < 0.95 && allPositive(positives)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Three events $X,Y$ and $Z$ are defined for a given sample space.The events $X$ and $Z$ are mutually exclusive and $Y$ and $Z$ are independent.`
      );
      question.addParagraph(
        "question",
        `It's given that $P(X)=${X}$, $P(Z)=${Z}$ and $P(Y \\cup Z)=${YorZ}$.`
      );
      question.addParagraph("question", `a) Find $P(X \\cup Z)$`);
      question.addParagraph("question", `b) Find $P(Y)$`);
      question.addParagraph(
        "question",
        `It is further given that $P(X \\cap Y)=${XandY}$`
      );
      question.addParagraph(
        "question",
        `c) Draw a venn diagram to represent the probabilities of events $X,Y$ and $Z$.`
      );

      // solution
      question.addHeading(
        "solution",
        `a)$P(X \\cup Z) = P(X)+P(Z)$ as they're mutually exclusive.`
      );
      question.addHeading("solution", `$=${X}+${Z}$`);
      question.addHeading("solution", `$=${XorZ}$`);

      question.addHeading(
        "solution",
        `b) $P(Y \\cup Z)=P(Y)+P(Z) - P(Y \\cap Z)$`
      );
      question.addParagraph(
        "solution",
        `$=P(Y)+P(Z) - P(Y)P(Z)$ , as they're independent.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore ${YorZ}=P(Y) + ${Z} - ${Z}\\times P(Y)$`
      );
      question.addParagraph(
        "solution",
        `$P(Y) = \\frac{${YorZ} - ${Z}}{${parseFloat((1 - Z).toFixed(2))}}$`
      );
      question.addParagraph("solution", `$\\approx ${Y}$`);

      question.addHeading("solution", `c) Calculating extra values.`);

      question.addHeading(
        "solution",
        `Finding: $P(X \\cap \\overline{Y}) = P(X) - P(X \\cap Y)$`
      );
      question.addParagraph("solution", `$= ${X} - ${XandY}$`);
      question.addParagraph("solution", `$= ${justX}$`);

      question.addHeading(
        "solution",
        `Finding: $P(Y \\cap Z) = P(Y)P(Z)$, as they're independent.`
      );
      question.addParagraph("solution", `$=${Y}(${Z})$`);
      question.addParagraph("solution", `$= ${YandZ}$`);

      question.addHeading(
        "solution",
        `Finding: $P(\\overline{Y} \\cap Z) = P(Z) - P(Y \\cap Z)$`
      );
      question.addParagraph("solution", `$= ${Z} - ${YandZ}$`);
      question.addParagraph("solution", `$= ${justZ}$`);

      question.addHeading(
        "solution",
        `Finding: $P(Y \\cap \\overline{(X \\cup Z)}) = P(Y) - P(X \\cap Y) - P(Y \\cap Z)$`
      );
      question.addParagraph("solution", `$= ${Y} - ${XandY} - ${YandZ}$`);
      question.addParagraph("solution", `$= ${justY}$`);

      question.addHeading(
        "solution",
        `Finding: $P(\\overline{(X \\cup Y \\cup Z)}) = 1 - P(X \\cup Z) - P(Y \\cap \\overline{(X \\cup Z)})$`
      );
      question.addParagraph("solution", `$= 1 - ${XorZ} - ${justY}$`);
      question.addParagraph("solution", `$= ${none}$`);

      const image = new MCQuestion.Image(`Leo/3Circle_ME.svg`, "height", 40);

      image.addOverlay(`X`, 15, 35, 2);
      image.addOverlay(`Y`, 50, 13, 2);
      image.addOverlay(`Z`, 88, 40, 2);

      image.addOverlay(`$${none}$`, 15, 15, 1.8);

      image.addOverlay(`$${justX}$`, 25, 55, 1.8);
      image.addOverlay(`$${XandY}$`, 40, 50, 1.8);
      image.addOverlay(`$${justY}$`, 50, 35, 1.8);
      image.addOverlay(`$${YandZ}$`, 60, 50, 1.8);
      image.addOverlay(`$${justZ}$`, 75, 60, 1.8);

      question.addImage("solution", image);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_VennDiagrams_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const M = parseFloat((MCRandom.randomInt(20, 50) / 100).toFixed(2));
    const N = parseFloat((MCRandom.randomInt(20, 50) / 100).toFixed(2));
    const MN = parseFloat((M * N).toFixed(2));

    const independent = [false, true][MCRandom.randomInt(0, 1)];
    let tmp = 0;
    if (independent) {
      tmp = MN;
    } else {
      tmp = parseFloat((MCRandom.randomInt(5, 18) / 100).toFixed(2));
    }

    const MandN = tmp;

    const MnotN = parseFloat((M - MandN).toFixed(2));
    const NnotM = parseFloat((N - MandN).toFixed(2));

    const MorN = parseFloat((MnotN + NnotM + MandN).toFixed(2));

    const neither = parseFloat((1 - MorN).toFixed(2));

    /* END VARIABLES AND CONSTANTS */
    if (
      MorN < 0.96 &&
      ((!independent && MandN !== parseFloat((MnotN * NnotM).toFixed(2))) ||
        independent) &&
      ((independent && M * N === parseFloat((M * N).toFixed(2))) ||
        !independent) &&
      ((!independent && MandN - MN > 0.01) || independent)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `For the events $M$ and $N$ it is given that:`
      );
      question.addParagraph(
        "question",
        `$P(M \\cap \\overline{N})=${MnotN}, P(\\overline{M} \\cap N)=${NnotM}$ and $P(M \\cup N)=${MorN}$`
      );
      question.addParagraph(
        "question",
        `a) Draw a venn diagram to represent the events $M$ and $N$.`
      );
      question.addParagraph("question", `b) Find $P(M)$ and $P(N)$`);
      question.addParagraph(
        "question",
        `c) Determine whether the events $M$ and $N$ are independent.`
      );

      // solution
      question.addHeading("solution", `a) Calculating $P(M \\cap N)$ `);
      question.addParagraph(
        "solution",
        `$P(M \\cup N)= P(M \\cap \\overline{N}) + P(\\overline{M} \\cap N) + P(M \\cap N)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore P(M \\cap N)=P(M \\cup N) - P(M \\cap \\overline{N}) - P(\\overline{M} \\cap N) $`
      );
      question.addParagraph("solution", `$=${MorN} - ${MnotN} - ${NnotM} $`);
      question.addParagraph("solution", `$=${MandN} $`);

      question.addHeading(
        "solution",
        `Calculating $P(\\overline{M \\cup N})$ `
      );
      question.addHeading(
        "solution",
        `$P(\\overline{M \\cup N}) = 1 -  P(M \\cup N)$`
      );
      question.addHeading("solution", `$ = 1 -  ${MorN}$`);
      question.addHeading("solution", `$ = ${neither}$`);

      const image = new MCQuestion.Image(`Leo/2Circle.svg`, "height", 40);

      image.addOverlay(`$M$`, 15, 20, 2);
      image.addOverlay(`$N$`, 85, 20, 2);

      image.addOverlay(`$${MnotN}$`, 30, 50, 1.8);
      image.addOverlay(`$${MandN}$`, 50, 50, 1.8);
      image.addOverlay(`$${NnotM}$`, 70, 50, 1.8);

      image.addOverlay(`$${neither}$`, 50, 10, 1.8);

      question.addImage("solution", image);

      question.addHeading(
        `solution`,
        `b) $P(M) = P(M \\cap \\overline{N}) + P(M \\cap N)$`
      );
      question.addHeading(`solution`, `$= ${MnotN} + ${MandN}$`);
      question.addHeading(`solution`, `$= ${M}$`);

      question.addHeading(
        `solution`,
        `$P(N) = P(\\overline{M} \\cap N) + P(M \\cap N)$`
      );
      question.addHeading(`solution`, `$= ${NnotM} + ${MandN}$`);
      question.addHeading(`solution`, `$= ${N}$`);

      question.addHeading("solution", `c) Given that $P(M \\cap N)=${MandN}$.`);
      question.addParagraph(
        "solution",
        `If $M$ and $N$ are independent, then $P(M \\cap N)=P(M)P(N)$`
      );
      question.addParagraph("solution", `$P(M)P(N)=${M}(${N})$`);
      question.addParagraph("solution", `$\\approx ${MN}$`);
      if (independent) {
        question.addParagraph("solution", `$${MN}=${MN}$`);
        question.addParagraph("solution", `$P(M \\cap N)=P(M)P(N)$`);
        question.addParagraph(
          "solution",
          `$\\therefore M$ and $N$ are independent.`
        );
      } else {
        question.addParagraph("solution", `$${MandN} \\neq ${MN}$`);
        question.addParagraph(
          "solution",
          `$\\therefore M$ and $N$ are not independent.`
        );
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_VennDiagrams_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }

    const justA = MCRandom.randomInt(15, 30);
    const justB = MCRandom.randomInt(15, 30);

    const AandB = MCRandom.randomInt(5, 15);

    const neither = MCRandom.randomInt(5, 20);

    const total = justA + justB + AandB + neither;

    const values = {
      A: [parseFloat(((justA + AandB) / total).toFixed(2)), `event $A$.`],
      B: [parseFloat(((justB + AandB) / total).toFixed(2)), `event $B$.`],
      justA: [parseFloat((justA / total).toFixed(2)), `only event $A$.`],
      justB: [parseFloat((justB / total).toFixed(2)), `only event $B$.`],
      AandB: [parseFloat((AandB / total).toFixed(2)), `events $A$ and $B$.`],
      AorB: [
        parseFloat(((justA + AandB + justB) / total).toFixed(2)),
        `event $A$ or $B$.`,
      ],
    };
    const keys = ["justA", "justB", "AandB", "AorB", "A", "B"];

    const correct_index = MCRandom.randomInt(0, keys.length - 1);
    let w1 = MCRandom.randomInt(0, keys.length - 1);
    while (w1 === correct_index) {
      w1 = MCRandom.randomInt(0, keys.length - 1);
    }
    let w2 = MCRandom.randomInt(0, keys.length - 1);
    while (w2 === correct_index || w2 === w1) {
      w2 = MCRandom.randomInt(0, keys.length - 1);
    }
    let w3 = MCRandom.randomInt(0, keys.length - 1);
    while (w3 === correct_index || w3 === w1 || w3 === w2) {
      w3 = MCRandom.randomInt(0, keys.length - 1);
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      values[keys[correct_index]][0] !== values[keys[w1]][0] &&
      values[keys[correct_index]][0] !== values[keys[w2]][0] &&
      values[keys[correct_index]][0] !== values[keys[w3]][0] &&
      values[keys[w1]][0] !== values[keys[w2]][0] &&
      values[keys[w1]][0] !== values[keys[w3]][0] &&
      values[keys[w2]][0] !== values[keys[w3]][0]
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      const image = new MCQuestion.Image(`Leo/2Circle.svg`, "height", 40);

      image.addOverlay("$A$", 20, 20, 2);
      image.addOverlay("$B$", 80, 20, 2);

      image.addOverlay(`$${justA}$`, 30, 50, 2);
      image.addOverlay(`$${justB}$`, 70, 50, 2);
      image.addOverlay(`$${AandB}$`, 50, 50, 2);
      image.addOverlay(`$${neither}$`, 50, 15, 2);

      question.addImage(image);

      question.addParagraph(
        `The venn diagram above summarises the number of people who attended the events $A$ and $B$. One person is then picked at random from the group of people surveyed.`
      );
      question.addParagraph(
        `Find the probability that the person attended ${
          values[keys[correct_index]][1]
        }`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$${values[keys[correct_index]][0]}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$${values[keys[w1]][0]}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$${values[keys[w2]][0]}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${values[keys[w3]][0]}$`);

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Probability_VennDiagrams_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const vals = [`A`, `AandB`, `AorB`, `AornotB`, `justA`, `notA`];
    const display = [
      `A`,
      `A \\cap B`,
      `A \\cup B`,
      `A\\cup \\overline{B}`,
      `A \\cap \\overline{B}`,
      `\\overline{A}`,
    ];
    const ci = MCRandom.randomInt(0, vals.length - 1);
    const wrong = {
      A: [`\\overline{B}`, `A \\cup B`, `A \\cap \\overline{B}`],
      AandB: [`A \\cup B`, `\\overline{A \\cup B}`, `\\overline{A \\cap B}`],
      AorB: [`A \\cap B`, `A`, `B`],
      AornotB: [`\\overline{B}`, `A`, `A \\cap \\overline{B}`],
      justA: [`A`, `\\overline{B}`, `A \\cup \\overline{B}`],
      notA: [`B`, `\\overline{A} \\cup B`, `\\overline{A} \\cap B`],
    };

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      const image = new MCQuestion.Image(
        `Leo/VennDiagrams_MCQ2/${vals[ci]}.svg`,
        "height",
        40
      );

      image.addOverlay("$A$", 20, 20, 2);
      image.addOverlay("$B$", 80, 20, 2);

      question.addImage(image);

      question.addParagraph(
        `In the venn diagram above, select what the shaded area represents from below.`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$${display[ci]}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$${wrong[vals[ci]][0]}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$${wrong[vals[ci]][1]}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${wrong[vals[ci]][2]}$`);

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_DisproofByCounterExample_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(2, 5);
    const b = a + MCRandom.randomInt(2, 5);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Disprove the statement $${a}x<${b}x$.`
      );
      question.addParagraph("solution", `HEADING Using a counter example:`);
      question.addParagraph("solution", `$x=-1$,  $-${a}\\nless -${b}$`);
      question.addParagraph(
        "solution",
        `HEADING $\\therefore$ The statement is false by counter example.`
      );
      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_DisproofByCounterExample_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const power = MCRandom.randomInt(2, 5);
    const k = MCRandom.randomInt(2, 5);
    const a = k ** (power - 1);
    /* END VARIABLES AND CONSTANTS */
    if (a < 100 /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Prove or disprove the statement: "$x^${power}\\neq ${a}x$ for any positive integer".`
      );
      question.addMultipleParagraphs("solution", [
        `HEADING Finding counter example:`,
        `Assume $x^${power} = ${a}x$`,
        `$x\\left(x^{${power - 1}}-${a}\\right)=0$`.replace("x^{1}", "x"),
        `$x=${k}$`,
        `$\\therefore$ As $${k}^${power} = ${a}\\times${k}$, the statement is disproved by counter example.`,
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_DisproofByCounterExample_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", "Prove or disprove: $\\sqrt{x^2}=x$.");
      question.addMultipleParagraphs("solution", [
        "HEADING Finding Counter Example:",
        "Setting $x=-1$:",
        "$\\sqrt{1}\\neq -1$",
        "$\\therefore$ Statement is false by counterexample.",
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_DisproofByCounterExample_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        "Prove or disprove: $a^2+b^2<(a+b)^2$."
      );
      question.addMultipleParagraphs("solution", [
        "HEADING Finding Counter Example:",
        "Setting $a=b=0$:",
        "$0\\nless 0$",
        "$\\therefore$ Statement is false by counterexample.",
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_DisproofByCounterExample_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        "Prove or disprove: If $ax=bx$, then $a=b$."
      );
      question.addMultipleParagraphs("solution", [
        "HEADING Finding Counter Example:",
        "Setting $x=0, b=1,a=2$:",
        "$0 = 0$ but $1\\neq 2$",
        "$\\therefore$ Statement is false by counterexample.",
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_DisproofByCounterExample_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        'Disprove the statement: "If $a+b$ is rational, then $a$ and $b$ are rational."'
      );
      question.addMultipleParagraphs("solution", [
        `HEADING Finding counter example:`,
        `$a=\\sqrt{2}, b=2-\\sqrt{2}$`,
        `$a+b=2$`,
        `$\\therefore$ Statement is false by counter example.`,
        `Note, many other counter examples exist and are valid.`,
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_ProofByDeduction_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const x0 = MCRandom.randomInt(-4, 4);
    const x1 = MCRandom.randomInt(-4, 4);
    const x2 = MCRandom.randomInt(-4, 4);
    const y0 = MCRandom.randomInt(-4, 4);
    const y1 = MCRandom.randomInt(-4, 4);
    const y2 = MCRandom.randomInt(-4, 4);

    const m0 = (y1 - y0) / (x1 - x0);
    const m1 = (y2 - y1) / (x2 - x1);
    const m2 = (y0 - y2) / (x0 - x2);

    const l1 = Math.sqrt((x1 - x0) ** 2 + (y1 - y0) ** 2);
    const l2 = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    const l3 = Math.sqrt((x0 - x2) ** 2 + (y0 - y2) ** 2);
    /* END VARIABLES AND CONSTANTS */
    if (
      m1 * m2 === -1 &&
      x0 !== x1 &&
      x1 !== x2 &&
      x2 !== x0 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Prove that the points $A=(${x0},${y0})$, $B=(${x1},${y1})$, $C=(${x2},${y2})$ form a right angled triangle.`,
      ]);
      const graph = new MCQuestion.Graph(5, -5, 5, -5, 1, 1);
      function f(x) {}
      function g(x) {}
      function h(x) {}
      graph.plot(`${m0} * (x - ${x0}) + ${y0}`, x0, x1);
      graph.plot(`${m1} * (x - ${x1}) + ${y1}`, x1, x2);
      graph.plot(`${m2} * (x - ${x2}) + ${y2}`, x2, x0);
      question.addGraph("solution", graph);
      question.addMultipleParagraphs("solution", [
        `HEADING Method 1:`,
        `HEADING Finding gradients:`,
        MCMaths.cleaner(
          `$\\displaystyle m_1 = \\frac{${x1}-${x0}}{${y1}-${y0}}$`
        ),
        MCMaths.cleaner(`$\\displaystyle =${m0}$`),
        MCMaths.cleaner(
          `$\\displaystyle m_2 = \\frac{${x2}-${x1}}{${y2}-${y1}}$`
        ),
        MCMaths.cleaner(`$\\displaystyle =${m1}$`),
        MCMaths.cleaner(
          `$\\displaystyle m_3 = \\frac{${x0}-${x2}}{${y0}-${y2}}$`
        ),
        MCMaths.cleaner(`$\\displaystyle =${m2}$`),
        `$m_2 \\times m_3 = -1$, so are perpendicular.`,
        `$\\therefore$ The triangle is right angled.`,
        `HEADING Method 2:`,
        `HEADING Using Pythagoras' theorem:`,
        MCMaths.cleaner(
          `Length $1 \\ = \\sqrt{(${x1}-${x0})^2+(${y1}-${y0})^2}$`
        ),
        MCMaths.cleaner(`$=${l1}$`),
        MCMaths.cleaner(
          `Length $2 \\ = \\sqrt{(${x2}-${x1})^2+(${y2}-${y1})^2}$`
        ),
        MCMaths.cleaner(`$=${l2}$`),
        MCMaths.cleaner(
          `Length $3 \\ = \\sqrt{(${x0}-${x2})^2+(${y0}-${y2})^2}$`
        ),
        MCMaths.cleaner(`$=${l3}$`),
        MCMaths.cleaner(`$(${l3})^2+(${l2})^2 = ${Math.round(l1 ** 2)}$`),
        MCMaths.cleaner(`$=(${l1})^2$`),
        `$\\therefore$ Pythagoras' theorem holds`,
        `$\\therefore$ The triangle is right angled.`,
      ]);
      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_ProofByDeduction_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const x0 = MCRandom.randomInt(-4, 4);
    const x1 = MCRandom.randomInt(-4, 4);
    const x2 = MCRandom.randomInt(-4, 4);
    const x3 = MCRandom.randomInt(-4, 4);
    const y0 = MCRandom.randomInt(-4, 4);
    const y1 = MCRandom.randomInt(-4, 4);
    const y2 = MCRandom.randomInt(-4, 4);
    const y3 = MCRandom.randomInt(-4, 4);

    const m0 = (y1 - y0) / (x1 - x0);
    const m1 = (y2 - y1) / (x2 - x1);
    const m2 = (y3 - y2) / (x3 - x2);
    const m3 = (y0 - y3) / (x0 - x3);

    const l0 = Math.sqrt((x1 - x0) ** 2 + (y1 - y0) ** 2);
    const l1 = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    const l2 = Math.sqrt((x3 - x2) ** 2 + (y3 - y2) ** 2);
    const l3 = Math.sqrt((x0 - x3) ** 2 + (y0 - y3) ** 2);

    // The inner angle of the parallelogram, use to make sure it's not too flat or a straight line
    const a = (Math.atan((m0 - m1) / (1 + m0 * m1)) * 180) / Math.PI;

    /* END VARIABLES AND CONSTANTS */
    if (
      m1 === m3 &&
      m0 === m2 &&
      x0 !== x1 &&
      x0 !== x2 &&
      x0 !== x3 &&
      x1 !== x2 &&
      x1 !== x3 &&
      x2 !== x3 &&
      y0 !== y1 &&
      y0 !== y2 &&
      y0 !== y3 &&
      y1 !== y2 &&
      y1 !== y3 &&
      y2 !== y3 &&
      m0 !== 0 &&
      m1 !== 0 &&
      m2 !== 0 &&
      m3 !== 0 &&
      m0 !== m1 &&
      l1 > 2 &&
      l0 > 2 &&
      a > 30 &&
      a < 60 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Prove that the points $(${x0},${y0})$, $(${x1},${y1})$, $(${x2},${y2})$ and $(${x3},${y3})$ form a parallelogram.`,
      ]);
      const graph = new MCQuestion.Graph(5, -5, 5, -5, 1, 1);

      graph.plot(`${m0} * (x - ${x0}) + ${y0}`, x0, x1);
      graph.plot(`${m1} * (x - ${x1}) + ${y1}`, x1, x2);
      graph.plot(`${m2} * (x - ${x2}) + ${y2}`, x2, x3);
      graph.plot(`${m3} * (x - ${x3}) + ${y3}`, x3, x0);
      question.addGraph("solution", graph);

      question.addMultipleParagraphs("solution", [
        `HEADING Method 1:`,
        `HEADING Finding gradients:`,
        MCMaths.cleaner(
          `$\\displaystyle m_1 = \\frac{${x1}-${x0}}{${y1}-${y0}}$`
        ),
        MCMaths.cleaner(`$\\displaystyle =${m0}$`),
        MCMaths.cleaner(
          `$\\displaystyle m_2 = \\frac{${x2}-${x1}}{${y2}-${y1}}$`
        ),
        MCMaths.cleaner(`$\\displaystyle =${m1}$`),
        MCMaths.cleaner(
          `$\\displaystyle m_3 = \\frac{${x3}-${x2}}{${y3}-${y2}}$`
        ),
        MCMaths.cleaner(`$\\displaystyle =${m2}$`),
        MCMaths.cleaner(
          `$\\displaystyle m_4 = \\frac{${x0}-${x3}}{${y0}-${y3}}$`
        ),
        MCMaths.cleaner(`$\\displaystyle =${m3}$`),
        `$m_0 = m_2$ and $m_1=m_3$`,
        `$\\therefore$ The quadrilateral is a parallelogram.`,
        `HEADING Method 2:`,
        `HEADING Checking side lengths:`,
        MCMaths.cleaner(
          `Length $1 \\ = \\sqrt{(${x1}-${x0})^2+(${y1}-${y0})^2}$`
        ),
        MCMaths.cleaner(`$=${l0}$`),
        MCMaths.cleaner(
          `Length $2 \\ = \\sqrt{(${x2}-${x1})^2+(${y2}-${y1})^2}$`
        ),
        MCMaths.cleaner(`$=${l1}$`),
        MCMaths.cleaner(
          `Length $3 \\ = \\sqrt{(${x3}-${x2})^2+(${y3}-${y2})^2}$`
        ),
        MCMaths.cleaner(`$=${l2}$`),
        MCMaths.cleaner(
          `Length $4 \\ = \\sqrt{(${x0}-${x3})^2+(${y0}-${y3})^2}$`
        ),
        MCMaths.cleaner(`$=${l3}$`),
        `Lengths of opposing sides are equal`,
        `$\\therefore$ The quadrilateral is a parallelogram.`,
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_ProofByDeduction_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 5);
    const b = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const x = new MCMaths.Polynomial([a, b, c]);
    const x2 = new MCMaths.Polynomial([
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
    ]);
    const x1 = x.add(x2);
    let bd = b;
    const disc = b * b - 4 * a * c;
    if (b < 0) {
      bd = `(${b})`;
    }
    const n = MCRandom.randomInt(0, 1);
    const qs = [
      `$y=${x.toString()}$. Prove $y>0$ for all real $x$.`,
      `Prove $${x1.toString()}$ and $${x2.toString()}$ never intersect.`,
    ];
    /* END VARIABLES AND CONSTANTS */
    if (b * b < 4 * a * c /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [qs[n]]);

      if (n === 1) {
        question.addMultipleParagraphs("solution", [
          `HEADING Forming Equation:`,
          `$${x1.toString()} = ${x2.toString()}$`,
          `$${x.toString()}=0$`,
          `HEADING Setting $y=${x.toString()}$ and showing $y>0$ for all real $x$:`,
        ]);
      }

      question.addParagraph("solution", "HEADING Method 1:");
      question.addMultipleParagraphs("solution", x.completeSquare());
      question.addMultipleParagraphs("solution", [
        `Square are positive`,
        `$\\therefore$ $y$ for all real $x$.`,
        `HEADING Method 2:`,
        `HEADING Derivative of $y$:`,
        `$\\displaystyle \\frac{dy}{dx} = ${x.derivative().toString()}$`,
        MCMaths.cleaner(
          `$\\displaystyle \\frac{dy}{dx}=0$ at $x=${-b / (2 * a)}$`
        ),
        `$\\displaystyle \\frac{d^2y}{dx^2} = ${x
          .derivative()
          .derivative()
          .toString()}$`,
        MCMaths.cleaner(
          `$y\\left(${-b / (2 * a)}\\right)= ${x.evaluate(-b / (2 * a))} > 0$`
        ),
        `$\\therefore$ There is only one turning point - a minimun.`,
        `As minimun $>0$, $y>0$ for all real $x$`,
        `HEADING Method 3:`,
        `HEADING Discriminant of $x$:`,
        `$=${bd}^2 - 4\\times${a}\\times${c}$`,
        `$=${disc}<0$`,
        `Hence $y$ has no roots.`,
        `HEADING Evaluating:`,
        `$y(0) = ${x.evaluate(0)}$`,
        `So as $y>0$ once and has no roots, $y>0$ for all real $x$.`,
      ]);

      if (n === 1) {
        question.addParagraph(
          "solution",
          "$\\therefore$ The curves never intersect."
        );
      }

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_ProofByDeduction_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const n1 = MCRandom.randomInt(0, 6);
    const n2 = MCRandom.randomInt(0, 6);
    const qs = [
      "Prove the sum of two consecutive numbers is odd.",
      "Prove the sum of three consecutive numbers is a multiple of three.",
      "Prove the sum of four consecutive numbers is even.",
      "Prove the sum of five consecutive numbers is a multiple of five.",
      "Prove the sum of six consecutive numbers is a multiple of three.",
      "Prove the sum of seven consecutive numbers is a multiple of seven.",
      "Prove the sum of eight consecutive numbers is a multiple of four.",
    ];
    const a1 = [
      `n, \\ n+1`,
      `n, \\ n+1, \\ n+2`,
      `n, \\ n+1, \\ n+2, \\ n+3`,
      `n, \\ n+1, \\cdots, \\ n+4`,
      `n, \\ n+1, \\cdots, \\ n+5`,
      `n, \\ n+1, \\cdots, \\ n+6`,
      `n, \\ n+1, \\cdots, \\ n+7`,
    ];
    const a2 = [
      `2n+1`,
      `3(n+1)`,
      `2(2n+3)`,
      `5(n+2)`,
      `3(2n+5)`,
      `7(n+3)`,
      `4(n+7)`,
    ];
    const goal = [
      `odd`,
      `multiple of three`,
      `even`,
      `multiple of five`,
      `multiple of three`,
      `multiple of seven`,
      `multiple of four`,
    ];

    /* END VARIABLES AND CONSTANTS */
    if (
      n1 !== n2 &&
      n2 > n1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `a) ${qs[n1]}`,
        `b) ${qs[n2]}`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Writing ${n1 + 2} arbitary consecutive numbers:`,
        `$=${a1[n1]}$`,
        `HEADING Summing:`,
        `$\\sum = ${a2[n1]}$`,
        `$\\therefore$ Is ${goal[n1]}`,
      ]);
      question.addMultipleParagraphs("solution", [
        `b) HEADING Writing ${n2 + 2} arbitary consecutive numbers:`,
        `$=${a1[n2]}$`,
        `HEADING Summing:`,
        `$\\sum = ${a2[n2]}$`,
        `$\\therefore$ Is ${goal[n2]}`,
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_ProofByDeduction_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const n1 = MCRandom.randomInt(0, 1);
    const n2 = MCRandom.randomInt(2, 5);
    const qs = [
      `Prove the difference of consecutive squares is odd.`,
      `Prove any odd number can be written as the difference of two squares.`,
      `Prove the difference of squares of numbers with a difference of two is a multiple of four.`,
      `Prove the difference of squares of numbers with a difference of three is a multiple of three.`,
      `Prove the difference of squares of numbers with a difference of four is a multiple of eight.`,
      `Prove the difference of squares of numbers with a difference of five is a multiple of five.`,
    ];
    const as = [
      [
        `a) HEADING Writing consecutive numbers:`,
        `$n,n+1$`,
        `HEADING Difference of squares:`,
        `$=(n+1)^2-(n)^2$`,
        `$=n^2+2n+1-n^2$`,
        `$=2n+1$`,
        `$\\therefore$ Is odd.`,
      ],
      [
        `a) HEADING Writing consecutive numbers:`,
        `$n,n+1$`,
        `HEADING Difference of squares:`,
        `$=(n+1)^2-(n)^2$`,
        `$=n^2+2n+1-n^2$`,
        `$=2n+1$`,
        `Any odd number can be written in this form.`,
      ],
      [
        `b) HEADING Writing a difference of two:`,
        `$n,n-2$`,
        `HEADING Difference of squares:`,
        `$=(n)^2-(n-2)^2$`,
        `$=n^2-n^2+4n-4$`,
        `$=4(n-1)$`,
        `$\\therefore$ Is a multiple of four.`,
      ],
      [
        `b) HEADING Writing a difference of three:`,
        `$n,n-3$`,
        `HEADING Difference of squares:`,
        `$=(n)^2-(n-3)^2$`,
        `$=n^2-n^2+6n-9$`,
        `$=3(2n-3)$`,
        `$\\therefore$ Is a multiple of three.`,
      ],
      [
        `b) HEADING Writing a difference of four:`,
        `$n,n-4$`,
        `HEADING Difference of squares:`,
        `$=(n)^2-(n-4)^2$`,
        `$=n^2-n^2+8n-16$`,
        `$=8(n-2)$`,
        `$\\therefore$ Is a multiple of eight.`,
      ],
      [
        `b) HEADING Writing a difference of five:`,
        `$n,n-5$`,
        `HEADING Difference of squares:`,
        `$=(n)^2-(n-5)^2$`,
        `$=n^2-n^2+10n-25$`,
        `$=5(2n-5)$`,
        `$\\therefore$ Is a multiple of five.`,
      ],
    ];

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `a) ${qs[n1]}`,
        `b) ${qs[n2]}`,
      ]);
      question.addMultipleParagraphs("solution", as[n1]);
      question.addMultipleParagraphs("solution", as[n2]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_ProofByDeduction_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const qs = [
      `Prove $a^3-b^3 \\equiv (a-b)(a^2+ab+b^2)$.`,
      `Prove $\\displaystyle \\frac{x}{1+\\sqrt{2}} \\equiv x\\sqrt{2} - x$.`,
    ];

    const as = [
      [
        `$a^3-b^3 = a^3 + a^2b - a^2b + ab^2 - ab^2 - b^3$`,
        `$= a(a^2+ab+b^2) -b(a^2+ab+b^2)$`,
        `$=(a-b)(a^2+ab+b^2)$`,
      ],
      [
        `$\\displaystyle \\frac{x}{1+\\sqrt{2}} = \\frac{x\\left(1-\\sqrt{2}\\right)}{\\left(1+\\sqrt{2}\\right)\\left(1-\\sqrt{2}\\right)}$`,
        `$\\displaystyle = \\frac{x-x\\sqrt{2}}{1-2}$`,
        `$x\\sqrt{x}-x$`,
      ],
    ];

    const n = MCRandom.randomInt(0, 1);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [qs[n]]);
      question.addMultipleParagraphs("solution", as[n]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_ProofByDeduction_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 50) / 30;
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const disc = b * b - 4 * a * c;
    const x = new MCMaths.Polynomial([a, b, c]);
    let bd = b;
    const ans = b ** 2 / (4 * c);
    if (b < 0) {
      bd = `\\left(${b}\\right)`;
    }

    let sign = "<";
    if (c < 0) {
      sign = ">";
    }
    /* END VARIABLES AND CONSTANTS */
    if (
      disc > 0 &&
      Math.round(ans) !== ans /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given $${x
          .toString()
          .replace(
            MCMaths.cleaner(`${a}`),
            "a"
          )}$ has two real roots, prove $a${sign}${MCMaths.cleaner(
          `${ans}`
        )}$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Finding Discriminant:`,
        `$ = ${bd}^2 - 4a\\times ${c}$`,
        `HEADING Finding Inequality:`,
        MCMaths.cleaner(`$ 0 < ${b ** 2}-${4 * c}a$`),
        `$a ${sign} ${MCMaths.cleaner(`${ans}`)}$.`,
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_ProofByDeduction_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const bl = MCRandom.randomInt(1, 4);
    const cl = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const a = MCRandom.randomInt(1, 5);
    const b = MCRandom.randomInt(2, 5);
    const r = MCRandom.randomInt(1, 3);
    // sqrt quad
    const as = -1;
    const bs = 2 * a;
    const cs = r * r - a * a;

    // final quad
    const af = bl * bl + 1;
    const bf = 2 * bl * cl - 2 * bl * b - 2 * a;
    const cf = (cl - b) ** 2 - r * r + a * a;

    // final dis
    const dis = bf * bf - 4 * af * cf;

    const x2 = new MCMaths.Polynomial([af, bf, cf]);

    let ans = "";
    if (dis >= 0) {
      ans =
        "$\\therefore$ as discriminant $\\ge 0$ the circle and line intersect.";
    } else {
      ans =
        "$\\therefore$ as discriminant $< 0$ the circle and line do not intersect.";
    }
    /* END VARIABLES AND CONSTANTS */
    if (
      cl !== b &&
      Math.abs(dis) < 100 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Prove or disprove the statement "a line with gradient $${bl}$ and $y$ intersect $${cl}$, and a circle with center $(${a},${b})$ and radius $${r}$ intersect".`
      );

      const graph = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      graph.addCircle(a, b, r);
      graph.plot(`${bl} * x + ${cl}`, -10, 10);
      question.addGraph("solution", graph);

      question.addMultipleParagraphs("solution", [
        "HEADING Equation of Circle:",
        `$(x-${a})^2+(y-${b})^2=${r}^2$,`,
        `HEADING Rearranging:`,
        `$y=${b}\\pm \\sqrt{${r}^2-(x-${a})^2}$`,
        `HEADING Forming Equation:`,
        MCMaths.cleaner(`$${bl}x+${cl} = ${b}\\pm \\sqrt{${r}^2-(x-${a})^2}$`),
        MCMaths.cleaner(`$${bl}x+${cl - b} = \\pm \\sqrt{${r}^2-(x-${a})^2}$`),
        MCMaths.cleaner(
          `$${bl ** 2}x^2+${2 * bl * (cl - b)}x+${
            (cl - b) ** 2
          } = ${r}^2-(x-${a})^2$`
        ),
        `$=${x2.toString()}$`,
        `HEADING Finding Discriminant:`,
        `$=(${bf})^2 - 4\\times${af}\\times${cf}$`,
        `$=${dis}$`,
        ans,
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_ProofByDeduction_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const x0 = MCRandom.randomInt(-4, 4);
    const x1 = MCRandom.randomInt(-4, 4);
    const x2 = MCRandom.randomInt(-4, 4);
    const x3 = MCRandom.randomInt(-4, 4);
    const y0 = MCRandom.randomInt(-4, 4);
    const y1 = MCRandom.randomInt(-4, 4);
    const y2 = MCRandom.randomInt(-4, 4);
    const y3 = MCRandom.randomInt(-4, 4);

    const m0 = (y1 - y0) / (x1 - x0);
    const m1 = (y2 - y1) / (x2 - x1);
    const m2 = (y3 - y2) / (x3 - x2);
    const m3 = (y0 - y3) / (x0 - x3);

    const l0 = Math.sqrt((x1 - x0) ** 2 + (y1 - y0) ** 2);
    const l1 = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    const l2 = Math.sqrt((x3 - x2) ** 2 + (y3 - y2) ** 2);
    const l3 = Math.sqrt((x0 - x3) ** 2 + (y0 - y3) ** 2);

    // The inner angle of the parallelogram, use to make sure it's not too flat or a straight line
    const a = (Math.atan((m0 - m1) / (1 + m0 * m1)) * 180) / Math.PI;

    /* END VARIABLES AND CONSTANTS */
    if (
      m1 === m3 &&
      m0 === m2 &&
      x0 !== x1 &&
      x0 !== x2 &&
      x0 !== x3 &&
      x1 !== x2 &&
      x1 !== x3 &&
      x2 !== x3 &&
      y0 !== y1 &&
      y0 !== y2 &&
      y0 !== y3 &&
      y1 !== y2 &&
      y1 !== y3 &&
      y2 !== y3 &&
      m0 !== 0 &&
      m1 !== 0 &&
      m2 !== 0 &&
      m3 !== 0 &&
      m0 !== m1 &&
      l1 > 2 &&
      l0 > 2 &&
      a > 30 &&
      a < 60 &&
      l0 === l1 &&
      l1 === l2 &&
      l2 === l3 &&
      l3 === l0 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Prove that the points $(${x0},${y0})$, $(${x1},${y1})$, $(${x2},${y2})$ and $(${x3},${y3})$ form a rhombus.`,
      ]);
      const graph = new MCQuestion.Graph(5, -5, 5, -5, 1, 1);

      graph.plot(`${m0} * (x - ${x0}) + ${y0}`, x0, x1);
      graph.plot(`${m1} * (x - ${x1}) + ${y1}`, x1, x2);
      graph.plot(`${m2} * (x - ${x2}) + ${y2}`, x2, x3);
      graph.plot(`${m3} * (x - ${x3}) + ${y3}`, x3, x0);
      question.addGraph("solution", graph);

      question.addMultipleParagraphs("solution", [
        `HEADING Checking side lengths:`,
        MCMaths.cleaner(
          `Length $1 \\ = \\sqrt{(${x1}-${x0})^2+(${y1}-${y0})^2}$`
        ),
        MCMaths.cleaner(`$=${l0}$`),
        MCMaths.cleaner(
          `Length $2 \\ = \\sqrt{(${x2}-${x1})^2+(${y2}-${y1})^2}$`
        ),
        MCMaths.cleaner(`$=${l1}$`),
        MCMaths.cleaner(
          `Length $3 \\ = \\sqrt{(${x3}-${x2})^2+(${y3}-${y2})^2}$`
        ),
        MCMaths.cleaner(`$=${l2}$`),
        MCMaths.cleaner(
          `Length $4 \\ = \\sqrt{(${x0}-${x3})^2+(${y0}-${y3})^2}$`
        ),
        MCMaths.cleaner(`$=${l3}$`),
        `All side lengths are equal`,
        `$\\therefore$ The quadrilateral is a rhombus.`,
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_ProofByDeduction_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const p = MCRandom.randomInt(2, 4);
    const q1 = [
      `$n^${p}$ is odd`,
      "$n$ is odd",
      `$n^${p}$ is even`,
      "$n$ is even",
    ];

    const type1 = MCRandom.randomInt(0, 2, 2);
    const type2 = MCRandom.randomInt(1, 3, 2);

    let win = false;
    if (type1 + type2 === 1 || type1 + type2 === 5) {
      win = true;
    }
    /* END VARIABLES AND CONSTANTS */
    if (type1 !== type2 /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(4);
      question.addParagraph(
        `Given the following statements for $n\\in\\mathbb{Z}$:`
      );
      question.addParagraph(`$\\bf{A}$: ${q1[type1]}`);
      question.addParagraph(`$\\bf{B}$: ${q1[type2]}`);
      question.addParagraph(`Select the correct relation:`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`$\\bf{A} \\Rightarrow \\bf{B}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`$\\bf{B}\\Rightarrow \\bf{A}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`$\\bf{A} \\Leftrightarrow \\bf{B}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`No Relation`);

      if (win === false) {
        question.addAnswer(a1, false);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, true);
      } else {
        question.addAnswer(a1, false);
        question.addAnswer(a2, false);
        question.addAnswer(a3, true);
        question.addAnswer(a4, false);
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_ProofByDeduction_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(4);
      question.addParagraph(
        `Given the following statements for polynomial $f(x)$:`
      );
      question.addParagraph(`$\\bf{A}$: $f(p)=0$`);
      question.addParagraph(`$\\bf{B}$: $(x-p)$ is a factor of $f$.`);
      question.addParagraph(`Selecte the correct relation:`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`$\\bf{A} \\Rightarrow \\bf{B}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`$\\bf{B}\\Rightarrow \\bf{A}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`$\\bf{A} \\Leftrightarrow \\bf{B}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`No Relation`);

      question.addAnswer(a1, false);
      question.addAnswer(a2, false);
      question.addAnswer(a3, true);
      question.addAnswer(a4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_ProofByDeduction_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const win = MCRandom.randomInt(2, 3);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(4);
      question.addParagraph(`Given the following statements:`);
      question.addParagraph(`$\\bf{A}$: $x^${win}>x$`);
      question.addParagraph(`$\\bf{B}$: $x<-1$`);
      question.addParagraph(`Selecte the correct relation:`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`$\\bf{A} \\Rightarrow \\bf{B}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`$\\bf{B}\\Rightarrow \\bf{A}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`$\\bf{A} \\Leftrightarrow \\bf{B}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`No Relation`);

      if (win === 3) {
        question.addAnswer(a1, false);
        question.addAnswer(a2, true);
        question.addAnswer(a3, false);
        question.addAnswer(a4, false);
      }

      if (win === 2) {
        question.addAnswer(a1, false);
        question.addAnswer(a2, false);
        question.addAnswer(a3, false);
        question.addAnswer(a4, true);
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_ProofByExhaustion_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const coefs = [
      [-1, 4, 7, 1, 5],
      [-1, 5, 2, 7, 5],
      [-1, 5, 4, -1, 5],
      [-1, 5, 6, 7, 6],
      [-1, 6, 1, 1, 6],
      [-1, 6, 7, -7, 5],
      [-1, 6, 7, -1, 6],
      [-1, 7, -2, 1, 5],
      [-1, 7, -2, 7, 6],
      [-1, 7, 6, -1, 7],
      [0, -1, 5, 7, 5],
      [0, -1, 7, 1, 6],
      [0, -1, 7, 7, 7],
      [0, 0, 6, -1, 5],
      [0, 1, -3, 7, 5],
      [0, 1, 3, 1, 5],
      [0, 1, 7, -1, 5],
      [0, 2, -4, 7, 5],
      [0, 2, 2, 7, 5],
      [0, 4, 0, 7, 6],
      [0, 4, 2, -1, 5],
      [0, 5, -7, 7, 5],
      [0, 5, 5, 1, 5],
      [0, 5, 5, 7, 5],
      [0, 6, 0, 7, 5],
      [0, 7, -3, 7, 5],
      [0, 7, 3, 7, 5],
      [1, -4, 7, 1, 5],
      [1, -2, 5, 7, 6],
      [1, -2, 7, -1, 5],
      [1, -1, -2, 7, 5],
      [1, -1, 4, 7, 5],
      [1, -1, 6, 1, 5],
      [1, -1, 6, 7, 5],
      [1, 0, 5, -1, 5],
      [1, 1, -4, 7, 5],
      [1, 1, 6, -1, 5],
      [1, 2, -5, 7, 5],
      [2, -5, 1, 7, 5],
      [2, -5, 7, 7, 5],
      [2, -4, 6, 1, 5],
    ];
    const coef = coefs[MCRandom.randomInt(0, coefs.length - 1)];
    const x = new MCMaths.Polynomial([coef[0], coef[1], coef[2], coef[3]]);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Prove $f(x)=${x.toString()}$ is prime for all integers between $1$ and $${
          coef[4]
        }$.`,
      ]);
      question.addParagraph("solution", `HEADING Evaluating:`);
      for (let k = 1; k < coef[4] + 1; k += 1) {
        question.addParagraph(
          "solution",
          `$f(${k})=${x.toString().replace(/x/g, `(${k})`)}$`
        );
        question.addParagraph("solution", `$=${x.evaluate(k)}$`);
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ By Exhaustion $f$ is prime in the given domain.`
      );
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_ProofByExhaustion_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const power = MCRandom.randomInt(2, 10);
    const add = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);
    let ans = 1;
    while ((ans + add) ** power - power ** ans > 0) {
      ans += 1;
    }

    const final = (ans + add - 1) ** power - power ** (ans - 1);

    /* END VARIABLES AND CONSTANTS */
    if (
      ans === 5 &&
      final < 100 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Prove that $(n+${add})^${power} \\ge ${power}^n$ for positive integers less than $${ans}$.`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`HEADING Setting $f(n)=(n+${add})^${power}-${power}^n$`)
      );
      question.addParagraph("solution", `HEADING Evaluating:`);
      for (let k = 1; k < ans; k += 1) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$f(${k}) = (${k}+${add})^${power}-${power}^${k}$`)
        );
        question.addParagraph(
          "solution",
          `$=${(k + add) ** power - power ** k}$`
        );
      }
      question.addParagraph(
        "solution",
        `HEADING $\\therefore$ The statement is true by exhaustion.`
      );
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Proof_ProofByExhaustion_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const primes = [17, 19, 23, 29, 31, 37, 41, 43, 47];
    const p = primes[MCRandom.randomInt(0, primes.length - 1)];
    const sq = Math.ceil(Math.sqrt(p));
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Prove $${p}$ is prime.`);
      question.addParagraph(
        "solution",
        `HEADING Checking division by all numbers less than $${sq}$:`
      );
      for (let k = 2; k <= sq; k += 1) {
        if (Math.round((1000 * p) / k) / 1000 !== p / k) {
          question.addParagraph(
            "solution",
            `$${p}\\div ${k} = ${Math.round((1000 * p) / k) / 1000}...$`
          );
        } else {
          question.addParagraph("solution", `$${p}\\div ${k} = ${p / k}$`);
        }
      }
      question.addMultipleParagraphs("solution", [
        `$\\therefore$ No factors smaller than or equal to $\\sqrt{${p}}$`,
        `$\\therefore$ $${p}$ is prime.`,
        `Note - also acceptable to check for divisibility for all numbers less than $${p}$.`,
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_BoxPlots_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const index = MCRandom.randomInt(0, 4);

    // Lowest, Q1, Median, Q3, Highest
    const values = [
      [34, 44, 52, 64, 80], // BoxPlotsQ1_1
      [26, 36, 46, 53, 60], // BoxPlotsQ1_2
      [14, 33, 42, 58, 71], // BoxPlotsQ1_3
      [15, 23, 45, 52, 63], // BoxPlotsQ1_4
      [32, 47, 56, 62, 75],
    ]; // BoxPlotsQ1_5

    const median = values[index][2];
    const range = values[index][4] - values[index][0];
    const iqr = values[index][3] - values[index][1];

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const bp = new MCQuestion.Image(
        `Leo/BoxPlots/Q1/BoxPlotQ1_${index + 1}.svg`,
        `width`,
        40
      );
      question.addImage("question", bp);

      // question.addParagraph(`question`, `BoxPlotQ1_${index+1}`);
      question.addParagraph(
        `question`,
        `The box plot above represents the marks attained by students in their recent mock exams for maths.`
      );
      question.addParagraph(`question`, `a) State the median test score.`);
      question.addParagraph(
        `question`,
        `b) State the range of the test scores.`
      );
      question.addParagraph(
        `question`,
        `c) State the interquartile range of the test scores.`
      );
      //
      question.addHeading(
        "solution",
        `a) Reading from the middle bar on the box plot.`
      );
      question.addParagraph(`solution`, `Median $=${median}$`);

      question.addHeading(
        `solution`,
        `b) Finding the smallest and largest values.`
      );
      question.addParagraph(`solution`, `Minimum $=${values[index][0]}$`);
      question.addParagraph(`solution`, `Maximum $=${values[index][4]}$`);
      question.addParagraph("solution", `Range = Maximum - Minimum`);
      question.addParagraph(
        "solution",
        `$=${values[index][4]} - ${values[index][0]}$`
      );
      question.addParagraph("solution", `$=${range}$`);

      question.addHeading(
        "solution",
        `c) Finding the values for $Q_1$ and $Q_3$.`
      );
      question.addParagraph("solution", `$Q_1=${values[index][1]}$`);
      question.addParagraph("solution", `$Q_3=${values[index][3]}$`);
      question.addParagraph("solution", `IQR $=Q_3-Q_1$`);
      question.addParagraph(
        "solution",
        `$=${values[index][3]}-${values[index][1]}$`
      );
      question.addParagraph("solution", `$=${iqr}$`);

      //
      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(median, 0, `a)`);
      g1.addInput(range, 0, `b)`);
      g1.addInput(iqr, 0, `c)`);
      question.addInputGroup(g1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_BoxPlots_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const n = MCRandom.randomInt(52, 160, 4);
    const index = MCRandom.randomInt(0, 4);

    // Lowest, Q1, Median, Q3, Highest
    const values = [
      [9, 14, 18, 24, 32], // BoxPlotsQ2_1
      [7, 12, 17, 20, 24], // BoxPlotsQ2_2
      [6, 15, 19, 28, 34], // BoxPlotsQ2_3
      [8, 12, 23, 27, 32], // BoxPlotsQ2_4
      [12, 19, 24, 27, 33],
    ]; // BoxPlotsQ2_5

    const low = values[index][0];
    const q1 = values[index][1];
    const med = values[index][2];
    const q3 = values[index][3];
    const high = values[index][4];

    const ai = MCRandom.randomInt(0, 1);
    const ci = MCRandom.randomInt(0, 3);
    const qi = MCRandom.randomInt(0, 2);

    const quarter1 = [`$25$%`, `$\\frac{1}{4}$`, `a quarter`];
    const quarter3 = [`$75$%`, `$\\frac{3}{4}$`, `three quarters`];

    const a = [
      "a) State the fastest time the quiz was completed in.",
      "a) State the longest time taken by someone to complete the quiz.",
    ][ai];

    const c = [
      `${quarter1[qi]} of people completed the quiz in under $x$ minutes.`,
      `${quarter3[qi]} of people took longer than $x$ minutes to complete the quiz.`,
      `${quarter1[qi]} of people took longer than $x$ mintues to complete the quiz.`,
      `${quarter3[qi]} of people completed the quiz in under $x$ minutes.`,
    ][ci];

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const g1 = new MCQuestion.InputGroup(3);

      const bp = new MCQuestion.Image(
        `Leo/BoxPlots/Q2/BoxPlotQ2_${index + 1}.svg`,
        `width`,
        40
      );
      question.addImage("question", bp);

      // question.addParagraph(`question`, `BoxPlotQ2_${index+1}`);
      question.addParagraph(
        `question`,
        `The box plot above represents the time spent by $${n}$ candidates, in minutes, completing an online quiz about problem solving.`
      );
      question.addParagraph(`question`, `${a}`);
      question.addParagraph(
        `question`,
        `b) State the median time taken to complete the quiz.`
      );
      question.addParagraph(`question`, `${c}`);
      question.addParagraph("question", `c) Find $x$.`);

      //
      if (ai === 0) {
        question.addHeading(
          "solution",
          `a) Finding the smallest value on the box plot.`
        );
        question.addParagraph("solution", `Minimum $=${low}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Fastest time is $${low}$ minutes.`
        );
        g1.addInput(low, 0.5, `a)`);
      } else {
        question.addHeading(
          "solution",
          `a) Finding the largest value on the box plot.`
        );
        question.addParagraph("solution", `Maximum $=${high}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Longest time is $${high}$ minutes.`
        );
        g1.addInput(high, 0.5, `a)`);
      }
      //
      question.addHeading(
        "solution",
        `b) Reading from the middle bar on the box plot.`
      );
      question.addParagraph(
        `solution`,
        `Median time to complete the quiz is $${med}$ minutes.`
      );
      g1.addInput(med, 0.5, `b)`);
      //
      if (ci === 0) {
        question.addHeading("solution", `c) Finding $Q_1$`);
        question.addParagraph("solution", `$Q_1=${q1}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ ${quarter1[qi]} of people completed the quiz in under $${q1}$ minutes.`
        );
        g1.addInput(q1, 0.5, `c)`);
      } else if (ci === 1) {
        question.addHeading("solution", `c) Finding $Q_1$`);
        question.addParagraph("solution", `$Q_1=${q1}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ ${quarter3[qi]} of people took longer than $${q1}$ minutes to complete the quiz.`
        );
        g1.addInput(q1, 0.5, `c)`);
      } else if (ci === 2) {
        question.addHeading("solution", `c) Finding $Q_3$`);
        question.addParagraph("solution", `$Q_3=${q3}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ ${quarter1[qi]} of people took longer than $${q3}$ minutes to complete the quiz.`
        );
        g1.addInput(q3, 0.5, `c)`);
      } else {
        question.addHeading("solution", `c) Finding $Q_3$`);
        question.addParagraph("solution", `$Q_3=${q3}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ ${quarter3[qi]} of people completed the quiz in under $${q3}$ minutes.`
        );
        g1.addInput(q3, 0.5, `c)`);
      }
      //
      question.addInputGroup(g1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_BoxPlots_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const index = MCRandom.randomInt(0, 4);
    // Lowest, Q1, Median, Q3, Highest
    const values = [
      [169, 174, 178, 184, 192], // BoxPlotsQ3_1
      [171, 176, 181, 184, 188], // BoxPlotsQ3_2
      [164, 173, 178, 186, 192], // BoxPlotsQ3_3
      [169, 173, 184, 188, 193], // BoxPlotsQ3_4
      [171, 179, 183, 186, 193],
    ]; // BoxPlotsQ3_5

    const tmp = [...values[index]];
    tmp.push(values[index][2]);

    const n = MCRandom.randomInt(1, 3);

    for (let i = 0; i < 4; i += 1) {
      for (let j = 0; j < n; j += 1) {
        tmp.push(MCRandom.randomInt(values[index][i], values[index][i + 1]));
      }
    }

    const stats = new MCMaths.Stats(tmp);
    const sample = stats.sort().dataX;

    const low = sample[0];
    const q1 = stats.q1();
    const median = stats.median();
    const q3 = stats.q3();
    const high = sample[sample.length - 1];

    /* console.log(sample);
    console.log(low,q1,median,q3,high);
    console.log(values[index]);
    console.log(values[index][0], values[index][3]); */

    /* END VARIABLES AND CONSTANTS */
    if (
      values[index][0] === low &&
      values[index][1] === q1 &&
      values[index][2] === median &&
      values[index][3] === q3 &&
      values[index][4] === high &&
      true
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `The heights, in centimetres, of a random sample of $${sample.length}$ people are as follows:`
      );
      question.addParagraph("question", `$${MCRandom.shuffle(sample)}$`);
      question.addParagraph(
        `question`,
        `a) Calculate the median, range and interquartile range of the data above.`
      );
      question.addParagraph(
        `question`,
        `b) Draw a box plot to represent the data.`
      );
      // solution
      question.addHeading(`solution`, `a) Ordering the list.`);
      question.addParagraph(`solution`, `$${sample}$`);
      question.addHeading("solution", `Finding median.`);
      question.addParagraph(
        "solution",
        `Median $= \\frac{1}{2}(${median}+${median})$`
      );
      question.addParagraph("solution", `$=${median}$`);
      question.addHeading("solution", `Finding range.`);
      question.addParagraph("solution", `Minimum $=${low}$`);
      question.addParagraph("solution", `Maximum $=${high}$`);
      question.addParagraph("solution", `Range $=${high}-${low}$`);
      question.addParagraph("solution", `$=${high - low}$`);
      question.addHeading("solution", `Finding interquartile range.`);
      question.addParagraph("solution", `$Q_1=${q1}$`);
      question.addParagraph("solution", `$Q_3=${q3}$`);
      question.addParagraph("solution", `IQR $=${q3}-${q1}$`);
      question.addParagraph("solution", `$=${q3 - q1}$`);

      question.addHeading(`solution`, `b) Drawing the box plot.`);
      const bp = new MCQuestion.Image(
        `Leo/BoxPlots/Q3/BoxPlotQ3_${index + 1}.svg`,
        `width`,
        40
      );
      question.addImage("solution", bp);
      //
      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(median, 0.5, `a) Median =`);
      g1.addInput(high - low, 1, `a) Range =`);
      g1.addInput(q3 - q1, 1, `a) IQR =`);
      question.addInputGroup(g1);

      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_BoxPlots_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const index1 = MCRandom.randomInt(0, 4);
    const index2 = MCRandom.randomInt(0, 4);

    // Lowest, Q1, Median, Q3, Highest
    const values = [
      [86, 91, 98, 104, 112], // BoxPlotsQ4_1
      [92, 95, 100, 104, 107], // BoxPlotsQ4_2
      [80, 88, 95, 100, 105], // BoxPlotsQ4_3
      [87, 92, 101, 106, 114], // BoxPlotsQ4_4
      [94, 98, 103, 109, 113],
    ]; // BoxPlotsQ4_5

    const median1 = values[index1][2];
    const iqr1 = values[index1][3] - values[index1][1];

    const median2 = values[index2][2];
    const iqr2 = values[index2][3] - values[index2][1];

    /* END VARIABLES AND CONSTANTS */
    if (index1 !== index2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      // question.addParagraph("question", `$${index1}:$`+values[index1].toString())
      const bp1 = new MCQuestion.Image(
        `Leo/BoxPlots/Q4/BoxPlotQ4_${index1 + 1}.svg`,
        `width`,
        40
      );
      question.addImage("question", bp1);

      // question.addParagraph(`question`, `BoxPlotQ1_${index+1}`);
      question.addParagraph(
        `question`,
        `The box plot above represents the IQs of students in class $X$ according to an online test.`
      );
      question.addParagraph(`question`, `a) State the median IQ of the class.`);
      question.addParagraph(
        `question`,
        `b) State the interquartile range of IQs attained.`
      );

      question.addParagraph(
        `question`,
        `Another group of students, class $Y$, also take the test. Some summary data for the IQs of students in class $Y$ is shown below.`
      );

      question.addParagraph(
        "question",
        `Minimum Score = $${values[index2][0]}$, Median = $${values[index2][2]}$, $Q_3 = ${values[index2][3]}$`
      );
      question.addParagraph(
        "question",
        `IQR = $${iqr2}$, Range = $${values[index2][4] - values[index2][0]}$`
      );

      question.addParagraph(
        `question`,
        `c) Draw a box plot to represent the IQs of class $Y$.`
      );
      question.addParagraph(
        `question`,
        `d) Using the data from the box plots, compare the IQs of classes $X$ and $Y$.`
      );
      // solution

      //
      question.addHeading(
        "solution",
        `a) Reading from the middle bar on the box plot.`
      );
      question.addParagraph(`solution`, `Median $=${median1}$`);

      // b
      question.addHeading(
        "solution",
        `b) Finding the values for $Q_1$ and $Q_3$.`
      );
      question.addParagraph("solution", `$Q_1=${values[index1][1]}$`);
      question.addParagraph("solution", `$Q_3=${values[index1][3]}$`);
      question.addParagraph("solution", `IQR $=Q_3-Q_1$`);
      question.addParagraph(
        "solution",
        `$=${values[index1][3]}-${values[index1][1]}$`
      );
      question.addParagraph("solution", `$=${iqr1}$`);
      // c
      question.addHeading(
        "solution",
        `c) Finding the values of $Q_1$ and Maximum Score for class $Y$.`
      );
      question.addParagraph(`solution`, `IQR = $Q_3-Q_1$`);
      question.addParagraph(`solution`, `$\\therefore Q_1=Q_3-$ IQR`);
      question.addParagraph(`solution`, `$=${values[index2][3]}-${iqr2}$`);
      question.addParagraph(`solution`, `$=${values[index2][1]}$`);

      question.addParagraph(`solution`, `Range = Max - Min`);
      question.addParagraph(`solution`, `$\\therefore$ Max = Range + Min`);
      question.addParagraph(
        `solution`,
        `$=${values[index2][4] - values[index2][0]} + ${values[index2][0]}$`
      );
      question.addParagraph(`solution`, `$=${values[index2][4]}$`);
      question.addHeading("solution", `Draw the box plot.`);
      const bp2 = new MCQuestion.Image(
        `Leo/BoxPlots/Q4/BoxPlotQ4_${index2 + 1}.svg`,
        `width`,
        40
      );
      question.addImage("solution", bp2);
      question.addHeading("solution", `d) Comparing the two box plots.`);
      if (iqr1 > iqr2) {
        question.addParagraph(
          "solution",
          `The interquartile range of class $X$ is higher than that of class $Y$, therefore the IQs in class $X$ are more varied.`
        );
      } else {
        question.addParagraph(
          "solution",
          `The interquartile range of class $Y$ is higher than that of class $X$, therefore the IQs in class $Y$ are more varied.`
        );
      }

      if (median1 > median2) {
        question.addParagraph(
          "solution",
          `The median IQ of class $X$ is higher than that of class $Y$ so the students in class $X$ have a higher IQ on average.`
        );
      } else {
        question.addParagraph(
          "solution",
          `The median IQ of class $Y$ is higher than that of class $X$ so the students in class $Y$ have a higher IQ on average.`
        );
      }

      // answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(median1, 0, `a)`);
      g1.addInput(iqr1, 0, `b)`);
      question.addInputGroup(g1);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_BoxPlots_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const index = MCRandom.randomInt(0, 4);

    // Lowest, Q1, Median, Q3, Highest
    const values = [
      [19, 27, 39, 51, 71], // BoxPlotsQ5_1
      [6, 28, 42, 66, 76], // BoxPlotsQ5_2
      [11, 38, 65, 71, 76], // BoxPlotsQ5_3
      [26, 38, 52, 63, 77], // BoxPlotsQ5_4
      [18, 39, 51, 72, 87],
    ]; // BoxPlotsQ5_5

    const median = values[index][2];
    const range = values[index][4] - values[index][0];
    const iqr = values[index][3] - values[index][1];

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const bp = new MCQuestion.Image(
        `Leo/BoxPlots/Q5/BoxPlotQ5_${index + 1}.svg`,
        `width`,
        40
      );
      question.addImage("question", bp);

      // question.addParagraph(`question`, `BoxPlotQ1_${index+1}`);
      question.addParagraph(
        `question`,
        `The box plot above represents the times of goals scored in MCM FC's games throughout their season.`
      );
      question.addParagraph(`question`, `a) State the median time of scoring.`);
      question.addParagraph(`question`, `b) State the range of the times.`);
      question.addParagraph(
        `question`,
        `c) State the interquartile range of the times.`
      );
      //
      question.addHeading(
        "solution",
        `a) Reading from the middle bar on the box plot.`
      );
      question.addParagraph(`solution`, `Median $=${median}$`);

      question.addHeading(
        `solution`,
        `b) Finding the smallest and largest values.`
      );
      question.addParagraph(`solution`, `Minimum $=${values[index][0]}$`);
      question.addParagraph(`solution`, `Maximum $=${values[index][4]}$`);
      question.addParagraph("solution", `Range = Maximum - Minimum`);
      question.addParagraph(
        "solution",
        `$=${values[index][4]} - ${values[index][0]}$`
      );
      question.addParagraph("solution", `$=${range}$`);

      question.addHeading(
        "solution",
        `c) Finding the values for $Q_1$ and $Q_3$.`
      );
      question.addParagraph("solution", `$Q_1=${values[index][1]}$`);
      question.addParagraph("solution", `$Q_3=${values[index][3]}$`);
      question.addParagraph("solution", `IQR $=Q_3-Q_1$`);
      question.addParagraph(
        "solution",
        `$=${values[index][3]}-${values[index][1]}$`
      );
      question.addParagraph("solution", `$=${iqr}$`);

      //
      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(median, 0, `a)`);
      g1.addInput(range, 0, `b)`);
      g1.addInput(iqr, 0, `c)`);
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_BoxPlots_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const n = MCRandom.randomInt(24, 60, 4);
    const index = MCRandom.randomInt(0, 4);

    // Lowest, Q1, Median, Q3, Highest
    const values = [
      [79, 86, 94, 98, 104], // BoxPlotsQ6_1
      [84, 90, 98, 112, 118], // BoxPlotsQ6_2
      [73, 81, 89, 94, 101], // BoxPlotsQ6_3
      [73, 87, 101, 109, 117], // BoxPlotsQ6_4
      [81, 85, 89, 96, 102],
    ]; // BoxPlotsQ6_5

    const low = values[index][0];
    const q1 = values[index][1];
    const med = values[index][2];
    const q3 = values[index][3];
    const high = values[index][4];

    const ai = MCRandom.randomInt(0, 1);
    const ci = MCRandom.randomInt(0, 3);
    const qi = MCRandom.randomInt(0, 2);

    const quarter1 = [`$25$%`, `$\\frac{1}{4}$`, `a quarter`];
    const quarter3 = [`$75$%`, `$\\frac{3}{4}$`, `three quarters`];

    const a = [
      "a) State the least points they scored in a game.",
      "a) State the highest number of points they scored in a game.",
    ][ai];

    const c = [
      `The team scored less than $x$ points in ${quarter1[qi]} of their games.`,
      `The team managed to score more than $x$ points in ${quarter3[qi]} of their games.`,
      `The team only scored more than $x$ points in ${quarter1[qi]} of games.`,
      `The team scored $x$ points or less in ${quarter3[qi]} of their games.`,
    ][ci];

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const g1 = new MCQuestion.InputGroup(3);

      const bp = new MCQuestion.Image(
        `Leo/BoxPlots/Q6/BoxPlotQ6_${index + 1}.svg`,
        `width`,
        40
      );
      question.addImage("question", bp);

      // question.addParagraph(`question`, `BoxPlotQ2_${index+1}`);
      question.addParagraph(
        `question`,
        `The box plot above represents the points scored by a basketball team in their last $${n}$ games.`
      );
      question.addParagraph(`question`, `${a}`);
      question.addParagraph(
        `question`,
        `b) State the median points scored in a game.`
      );
      question.addParagraph(`question`, `${c}`);
      question.addParagraph("question", `c) Find $x$.`);

      //
      if (ai === 0) {
        question.addHeading(
          "solution",
          `a) Finding the smallest value on the box plot.`
        );
        question.addParagraph("solution", `Minimum $=${low}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Least points scored is $${low}$`
        );
        g1.addInput(low, 0, `a)`);
      } else {
        question.addHeading(
          "solution",
          `a) Finding the largest value on the box plot.`
        );
        question.addParagraph("solution", `Maximum $=${high}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ Most points scored is $${high}$`
        );
        g1.addInput(high, 0, `a)`);
      }
      //
      question.addHeading(
        "solution",
        `b) Reading from the middle bar on the box plot.`
      );
      question.addParagraph(`solution`, `Median points scored is $${med}$`);
      g1.addInput(med, 0, `b)`);
      //
      if (ci === 0) {
        question.addHeading("solution", `c) Finding $Q_1$`);
        question.addParagraph("solution", `$Q_1=${q1}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ The team scored less than $${q1}$ points in ${quarter1[qi]} of their games.`
        );
        g1.addInput(q1, 0, `c)`);
      } else if (ci === 1) {
        question.addHeading("solution", `c) Finding $Q_1$`);
        question.addParagraph("solution", `$Q_1=${q1}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ The team managed to score more than $${q1}$ points in ${quarter3[qi]} of their games.`
        );
        g1.addInput(q1, 0, `c)`);
      } else if (ci === 2) {
        question.addHeading("solution", `c) Finding $Q_3$`);
        question.addParagraph("solution", `$Q_3=${q3}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ The team only scored more than $${q3}$ points in ${quarter1[qi]} of games.`
        );
        g1.addInput(q3, 0, `c)`);
      } else {
        question.addHeading("solution", `c) Finding $Q_3$`);
        question.addParagraph("solution", `$Q_3=${q3}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ The team scored $${q3}$ points or less in ${quarter3[qi]} of their games.`
        );
        g1.addInput(q3, 0, `c)`);
      }
      //
      question.addInputGroup(g1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_BoxPlots_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const index = MCRandom.randomInt(0, 4);
    // Lowest, Q1, Median, Q3, Highest
    const values = [
      [21.3, 23.2, 24, 25.3, 26.1], // BoxPlotsQ7_1
      [22, 23.2, 25.4, 27, 28.4], // BoxPlotsQ7_2
      [20.4, 22.3, 23.9, 25.5, 26.5], // BoxPlotsQ7_3
      [22.8, 23.9, 24.9, 26.2, 27.2], // BoxPlotsQ7_4
      [20.2, 23.4, 24.6, 27, 29.4],
    ]; // BoxPlotsQ7_5

    const tmp = [...values[index]];
    tmp.push(values[index][2]);

    const n = MCRandom.randomInt(1, 3);

    for (let i = 0; i < 4; i += 1) {
      for (let j = 0; j < n; j += 1) {
        tmp.push(
          parseFloat(
            (
              MCRandom.randomInt(
                parseFloat((values[index][i] * 100).toFixed(0)),
                parseFloat((values[index][i + 1] * 100).toFixed(0))
              ) / 100
            ).toFixed(1)
          )
        );
      }
    }

    const stats = new MCMaths.Stats(tmp);
    const sample = stats.sort().dataX;

    const low = sample[0];
    const q1 = stats.q1();
    const median = stats.median();
    const q3 = stats.q3();
    const high = sample[sample.length - 1];

    /* END VARIABLES AND CONSTANTS */
    if (
      values[index][0] === low &&
      values[index][1] === q1 &&
      values[index][2] === median &&
      values[index][3] === q3 &&
      values[index][4] === high
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `The caloric intake, in thousands of calories, of a random sample of $${sample.length}$ men are as follows:`
      );
      question.addParagraph("question", `$${MCRandom.shuffle(sample)}$`);
      question.addParagraph(
        `question`,
        `a) Calculate the median, range and interquartile range of the data above.`
      );
      question.addParagraph(
        `question`,
        `b) Draw a box plot to represent the data.`
      );
      // solution
      question.addHeading(`solution`, `a) Ordering the list.`);
      question.addParagraph(`solution`, `$${sample}$`);
      question.addHeading("solution", `Finding median.`);
      question.addParagraph(
        "solution",
        `Median $= \\frac{1}{2}(${median}+${median})$`
      );
      question.addParagraph("solution", `$=${median}$`);
      question.addHeading("solution", `Finding range.`);
      question.addParagraph("solution", `Minimum $=${low}$`);
      question.addParagraph("solution", `Maximum $=${high}$`);
      question.addParagraph("solution", `Range $=${high}-${low}$`);
      question.addParagraph(
        "solution",
        `$=${parseFloat((high - low).toFixed(1))}$`
      );
      question.addHeading("solution", `Finding interquartile range.`);
      question.addParagraph("solution", `$Q_1=${q1}$`);
      question.addParagraph("solution", `$Q_3=${q3}$`);
      question.addParagraph("solution", `IQR $=${q3}-${q1}$`);
      question.addParagraph(
        "solution",
        `$=${parseFloat((q3 - q1).toFixed(0))}$`
      );

      question.addHeading(`solution`, `b) Drawing the box plot.`);
      const bp = new MCQuestion.Image(
        `Leo/BoxPlots/Q7/BoxPlotQ7_${index + 1}.svg`,
        `width`,
        40
      );
      question.addImage("solution", bp);
      //
      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(median, 0.1, `a) Median =`);
      g1.addInput(high - low, 0.2, `a) Range =`);
      g1.addInput(q3 - q1, 0.2, `a) IQR =`);
      question.addInputGroup(g1);

      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_BoxPlots_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const index1 = MCRandom.randomInt(0, 4);
    const index2 = MCRandom.randomInt(0, 4);

    // Lowest, Q1, Median, Q3, Highest
    const values = [
      [30, 43, 56, 65, 79], // BoxPlotsQ8_1
      [28, 46, 64, 76, 90], // BoxPlotsQ8_2
      [35, 47, 67, 76, 88], // BoxPlotsQ8_3
      [23, 38, 50, 61, 78], // BoxPlotsQ8_4
      [10, 40, 74, 84, 96],
    ]; // BoxPlotsQ8_5

    const median1 = values[index1][2];
    const iqr1 = values[index1][3] - values[index1][1];

    const median2 = values[index2][2];
    const iqr2 = values[index2][3] - values[index2][1];

    /* END VARIABLES AND CONSTANTS */
    if (index1 !== index2 /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      // question.addParagraph("question", `$${index1}:$`+values[index1].toString())
      const bp1 = new MCQuestion.Image(
        `Leo/BoxPlots/Q8/BoxPlotQ8_${index1 + 1}.svg`,
        `width`,
        40
      );
      question.addImage("question", bp1);

      // question.addParagraph(`question`, `BoxPlotQ1_${index+1}`);
      question.addParagraph(
        `question`,
        `The box plot above represents user reviews for product $X$ online, where scores can range from $0$ to $100$.`
      );
      question.addParagraph(
        `question`,
        `a) State the median score received by the product.`
      );
      question.addParagraph(
        `question`,
        `b) State the interquartile range of the review scores.`
      );

      question.addParagraph(
        `question`,
        `Another similar item, product $Y$, also has user reviews. Some summary data for the review scores given is shown below.`
      );

      question.addParagraph(
        "question",
        `$Q_1 = ${values[index2][1]}$, Median = $${values[index2][2]}$, Maximum Score = $${values[index2][4]}$`
      );
      question.addParagraph(
        "question",
        `IQR = $${iqr2}$, Range = $${values[index2][4] - values[index2][0]}$`
      );

      question.addParagraph(
        `question`,
        `c) Draw a box plot to represent the review scores of product $Y$.`
      );
      question.addParagraph(
        `question`,
        `d) Using the data from the box plots, compare the user experience for people who reviewed product $X$ and $Y$.`
      );
      // solution

      //
      question.addHeading(
        "solution",
        `a) Reading from the middle bar on the box plot.`
      );
      question.addParagraph(`solution`, `Median $=${median1}$`);

      // b
      question.addHeading(
        "solution",
        `b) Finding the values for $Q_1$ and $Q_3$.`
      );
      question.addParagraph("solution", `$Q_1=${values[index1][1]}$`);
      question.addParagraph("solution", `$Q_3=${values[index1][3]}$`);
      question.addParagraph("solution", `IQR $=Q_3-Q_1$`);
      question.addParagraph(
        "solution",
        `$=${values[index1][3]}-${values[index1][1]}$`
      );
      question.addParagraph("solution", `$=${iqr1}$`);
      // c
      question.addHeading(
        "solution",
        `c) Finding the values of $Q_3$ and Minimum Score for class $Y$.`
      );
      question.addParagraph(`solution`, `IQR = $Q_3-Q_1$`);
      question.addParagraph(`solution`, `$\\therefore Q_3=Q_1+$ IQR`);
      question.addParagraph(`solution`, `$=${values[index2][1]}+${iqr2}$`);
      question.addParagraph(`solution`, `$=${values[index2][3]}$`);

      question.addParagraph(`solution`, `Range = Max - Min`);
      question.addParagraph(`solution`, `$\\therefore$ Min = Max - Range`);
      question.addParagraph(
        `solution`,
        `$= ${values[index2][4]} - ${values[index2][4] - values[index2][0]}$`
      );
      question.addParagraph(`solution`, `$=${values[index2][0]}$`);
      question.addHeading("solution", `Draw the box plot.`);
      const bp2 = new MCQuestion.Image(
        `Leo/BoxPlots/Q8/BoxPlotQ8_${index2 + 1}.svg`,
        `width`,
        40
      );
      question.addImage("solution", bp2);
      question.addHeading("solution", `d) Comparing the two box plots.`);
      if (iqr1 > iqr2) {
        question.addParagraph(
          "solution",
          `The interquartile range of scores for product $X$ is higher than that of product $Y$, therefore the user experience with product $X$ is more varied.`
        );
      } else {
        question.addParagraph(
          "solution",
          `The interquartile range of scores for product $Y$ is higher than that of product $X$, therefore the user experience with product $Y$ is more varied.`
        );
      }

      if (median1 > median2) {
        question.addParagraph(
          "solution",
          `The median review score of product $X$ is higher than that of product $Y$ so the user experience with product $X$ is better on average.`
        );
      } else {
        question.addParagraph(
          "solution",
          `The median review score of product $Y$ is higher than that of product $X$ so the user experience with product $Y$ is better on average.`
        );
      }

      // answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(median1, 0, `a)`);
      g1.addInput(iqr1, 0, `b)`);
      question.addInputGroup(g1);

      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_BoxPlots_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const index = MCRandom.randomInt(0, 4);
    // Lowest, Q1, Median, Q3, Highest
    const values = [
      [14, 19, 23, 29, 37], // BoxPlotsMCQ1_1
      [11, 16, 21, 24, 28], // BoxPlotsMCQ1_2
      [14, 23, 28, 36, 42], // BoxPlotsMCQ1_3
      [14, 18, 29, 33, 38], // BoxPlotsMCQ1_4
      [11, 19, 23, 26, 33],
    ]; // BoxPlotsMCQ1_5

    const using = values[index];
    const q = ["$Q_1$", `the median`, `$Q_3$`][MCRandom.randomInt(0, 2)];

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      const bp = new MCQuestion.Image(
        `Leo/BoxPlots/MCQ1/BoxPlotMCQ1_${index + 1}.svg`,
        `width`,
        40
      );
      question.addImage(bp);
      question.addParagraph(
        `Select the value of ${q} for the box plot shown above.`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`$${using[1]}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`$${using[2]}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`$${using[3]}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${using[MCRandom.randomInt(0, 4, 4)]}$`);

      question.addAnswer(a1, q === `$Q_1$`);
      question.addAnswer(a2, q === `the median`);
      question.addAnswer(a3, q === `$Q_3$`);
      question.addAnswer(a4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_BoxPlots_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const index = MCRandom.randomInt(0, 4);
    // Lowest, Q1, Median, Q3, Highest
    const values = [
      [4, 9, 13, 19, 27], // BoxPlotsMCQ2_1
      [2, 7, 12, 15, 19], // BoxPlotsMCQ2_2
      [1, 10, 14, 23, 29], // BoxPlotsMCQ2_3
      [3, 7, 18, 22, 27], // BoxPlotsMCQ2_4
      [2, 9, 14, 17, 23],
    ]; // BoxPlotsMCQ2_5

    let using = [];
    for (let i = 0; i < 5; i += 1) {
      if (i !== index) {
        using.push(i);
      }
    }
    using = MCRandom.shuffle(using);

    const range = values[using[0]][4] - values[using[0]][0];
    const iqr = values[using[0]][3] - values[using[0]][1];

    const type = ["a", "an interquartile"][MCRandom.randomInt(0, 1)];

    let val = "";
    if (type === "a") {
      val = range;
    } else {
      val = iqr;
    }

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(1);
      question.addParagraph(
        `Select the box plot below which has ${type} range of $${val}$`
      );

      const bp1 = new MCQuestion.Image(
        `Leo/BoxPlots/MCQ2/BoxPlotMCQ2_${using[0] + 1}.svg`,
        `width`,
        30
      );
      const bp2 = new MCQuestion.Image(
        `Leo/BoxPlots/MCQ2/BoxPlotMCQ2_${using[1] + 1}.svg`,
        `width`,
        30
      );
      const bp3 = new MCQuestion.Image(
        `Leo/BoxPlots/MCQ2/BoxPlotMCQ2_${using[2] + 1}.svg`,
        `width`,
        30
      );
      const bp4 = new MCQuestion.Image(
        `Leo/BoxPlots/MCQ2/BoxPlotMCQ2_${using[3] + 1}.svg`,
        `width`,
        30
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addImage(bp1);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addImage(bp2);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addImage(bp3);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addImage(bp4);

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_BoxPlots_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const index = MCRandom.randomInt(0, 4);
    // Lowest, Q1, Median, Q3, Highest, outlier
    const values = [
      [8, 12, 19, 28, 31, 33], // BoxPlotsMCQ3_1
      [5, 10, 13, 19, 24, 2], // BoxPlotsMCQ3_2
      [10, 17, 26, 30, 35, 38], // BoxPlotsMCQ3_3
      [6, 9, 18, 25, 33, 3], // BoxPlotsMCQ3_4
      [12, 18, 22, 28, 32, 39],
    ]; // BoxPlotsMCQ3_5

    const using = values[index];

    const range = using[4] - using[0];
    const iqr = using[3] - using[1];
    let dummyRange = 0;
    if (using[5] > using[4]) {
      dummyRange = using[5] - using[0];
    } else {
      dummyRange = using[4] - using[5];
    }
    const q = ["the interquartile range", `the range`][
      MCRandom.randomInt(0, 1)
    ];
    const op4 = [using[2] - using[1], using[3] - using[2]][
      MCRandom.randomInt(0, 1)
    ];
    /* END VARIABLES AND CONSTANTS */
    if (dummyRange !== iqr && dummyRange !== range && dummyRange !== op4) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      const bp = new MCQuestion.Image(
        `Leo/BoxPlots/MCQ3/BoxPlotMCQ3_${index + 1}.svg`,
        `width`,
        40
      );
      question.addImage(bp);
      question.addParagraph(
        `Select the value of ${q} for the box plot shown above.`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`$${iqr}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`$${range}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`$${dummyRange}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${op4}$`);

      question.addAnswer(a1, q === `the interquartile range`);
      question.addAnswer(a2, q === `the range`);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Histograms_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const groups = [
      `60\\leq t < 100`,
      `100\\leq t < 150`,
      `150\\leq t < 200`,
      `200\\leq t < 240`,
      `240\\leq t < 300`,
    ];
    const widths = [40, 50, 50, 40, 60];
    const nums = [80, 125, 175, 220, 270];
    const freqs = [
      MCRandom.randomInt(4, 20),
      MCRandom.randomInt(12, 30),
      MCRandom.randomInt(20, 40),
      MCRandom.randomInt(12, 30),
      MCRandom.randomInt(4, 20),
    ];
    const dens = [
      freqs[0] / 40,
      freqs[1] / 50,
      freqs[2] / 50,
      freqs[3] / 40,
      freqs[4] / 60,
    ];

    const dataX = [];
    for (let i = 0; i < freqs.length; i += 1) {
      for (let j = 0; j < freqs[i]; j += 1) {
        dataX.push(nums[i]);
      }
    }
    // console.log(dataX);
    const sample = new MCMaths.Stats(dataX);

    const n = freqs[0] + freqs[1] + freqs[2] + freqs[3] + freqs[4];

    const lower = MCRandom.randomInt(70, 90, 10);
    const upper = MCRandom.randomInt(120, 140, 10);

    const c = dens[0] * (100 - lower) + dens[1] * (upper - 100);

    function allGoodDens(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (parseFloat(arr[i].toFixed(1)) !== arr[i]) {
          return false;
        }
      }
      return true;
    }

    /* END VARIABLES AND CONSTANTS */
    if (allGoodDens(dens)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addGraph(
        "question",
        sample.histogramWidths([60, 100, 150, 200, 240, 300])
      );
      // question.addGraph('question', sample.histogram());

      question.addParagraph(
        "question",
        `The histogram above represents the times taken by $n$ students to complete a challenge course on a sports day.`
      );
      question.addParagraph(
        "question",
        `a) Complete the frequency table below by calculating the values of $f_4$ and $f_5$.`
      );

      const table = new MCQuestion.Table(`Time (s)`, "Frequency");
      for (let i = 0; i < groups.length; i += 1) {
        if (i < 3) {
          table.addRow(`$${groups[i]}$`, `$${freqs[i]}$`);
        } else {
          table.addRow(`$${groups[i]}$`, `$f_{${i + 1}}$`);
        }
      }
      question.addTable("question", table);

      question.addParagraph("question", `b) Hence, state the value of $n$.`);
      question.addParagraph(
        "question",
        `c) Estimate the number of students who took between $${lower}$ and $${upper}$ seconds to complete the course.`
      );
      //

      question.addHeading("solution", `a) Calculating $f_4$ and $f_5$.`);
      question.addParagraph(
        "solution",
        `frequency = frequency density $\\times$ group width`
      );
      for (let i = 3; i < widths.length; i += 1) {
        question.addHeading("solution", `For $${groups[i]}$`);
        question.addParagraph(
          "solution",
          `$f_${i + 1} = ${dens[i]} \\times ${widths[i]}$`
        );
        question.addParagraph("solution", `$=${freqs[i]}$`);
      }

      /* const table2 = new MCQuestion.Table(`Time (s)`, "Frequency");
      for (let j = 0; j < groups.length; j++){
        table2.addRow(`$${groups[j]}$`,`$${freqs[j]}$`);
      }
      question.addTable("solution", table2); */

      // b
      question.addHeading(
        "solution",
        `b) Calculating the total number of students involved.`
      );
      question.addParagraph("solution", `$n=${freqs.join("+")}$`);
      question.addParagraph("solution", `$=${n}$`);

      // c
      question.addHeading(
        "solution",
        `c) Assuming times are evenly distributed in each group.`
      );
      question.addParagraph("solution", `For $${groups[0]}$`);
      question.addParagraph("solution", `frequency density $=${dens[0]}$`);
      question.addParagraph("solution", `For $${groups[1]}$`);
      question.addParagraph("solution", `frequency density $=${dens[1]}$`);
      question.addParagraph(
        "solution",
        `total = $${dens[0]}(100-${lower}) + ${dens[1]}(${upper}-100)$`
      );
      question.addParagraph("solution", `$=${c.toFixed(0)}$`);
      // answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(freqs[3], 2, `a) $\\ f_${4}=$`);
      g1.addInput(freqs[4], 2, `a) $\\ f_${5}=$`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(n, 6, `b) $\\ n=$`);
      question.addInputGroup(g2);

      const g3 = new MCQuestion.InputGroup(1);
      g3.addInput(c, 4, `c)`);
      question.addInputGroup(g3);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Histograms_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const groups = [
      `5\\leq t < 10`,
      `10\\leq t < 20`,
      `20\\leq t < 25`,
      `25\\leq t < 30`,
      `30\\leq t < 45`,
    ];

    const groupBounds = [5, 10, 20, 25, 30, 45];
    const a_random = MCRandom.randomInt(0, groupBounds.length - 2);

    const widths = [5, 10, 5, 5, 15];
    const nums = [8, 15, 23, 28, 38];
    const freqs = [
      MCRandom.randomInt(4, 20),
      MCRandom.randomInt(12, 30),
      MCRandom.randomInt(20, 40),
      MCRandom.randomInt(12, 30),
      MCRandom.randomInt(4, 20),
    ];
    const dens = [
      freqs[0] / widths[0],
      freqs[1] / widths[1],
      freqs[2] / widths[2],
      freqs[3] / widths[3],
      freqs[4] / widths[4],
    ];
    const n = freqs.reduce((a, b) => a + b, 0);
    const dataX = [];
    for (let i = 0; i < freqs.length; i += 1) {
      for (let j = 0; j < freqs[i]; j += 1) {
        dataX.push(nums[i]);
      }
    }
    // console.log(dataX);
    const sample = new MCMaths.Stats(dataX);

    function allGoodDens(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i] % 1 !== 0) {
          return false;
        }
      }
      return true;
    }

    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }

    let index = 0;
    for (let i = 0; i < freqs.length; i += 1) {
      if (freqs[i] === Math.max(...freqs)) {
        index = i;
      }
    }

    const mode = groups[index];

    let groupC = 0;
    let total = (n - 1) / 2;
    let into = 0;

    for (let i = 0; i < freqs.length; i += 1) {
      total -= freqs[i];
      if (total < 0) {
        into = total + freqs[i] + 1;
        groupC = i;
        break;
      }
    }
    const median = groupBounds[groupC] + into / dens[groupC];

    /* END VARIABLES AND CONSTANTS */
    if (allGoodDens(dens) && allDifferent(freqs) && n % 2 === 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addGraph(
        "question",
        sample.histogramWidths([5, 10, 20, 25, 30, 45])
      );
      // question.addGraph('question', sample.histogram());

      question.addParagraph(
        `question`,
        `The diagram above shows a histogram for the times taken to travel to school for $${n}$ students.`
      );

      question.addParagraph(
        "question",
        `a) Calculate the number of students who take between $${
          groupBounds[a_random]
        }$ and $${groupBounds[a_random + 1]}$ minutes to get to school.`
      );
      question.addParagraph("question", `b) State the modal group.`);
      question.addParagraph(
        "question",
        `c) Calculate an estimate for the median time spent travelling to school.`
      );

      // solution
      question.addHeading(
        "solution",
        `a) frequency = frequency density $\\times$ group width`
      );
      question.addHeading("solution", `For $${groups[a_random]}$`);
      question.addParagraph(
        "solution",
        `$f = ${dens[a_random]} \\times ${widths[a_random]}$`
      );
      question.addParagraph("solution", `$=${freqs[a_random]}$`);

      question.addHeading(
        "solution",
        `b) Calculate the frequency of every group.`
      );

      const table = new MCQuestion.Table(
        `Time (mins)`,
        "Frequency Density",
        "Group Width",
        `Frequency`
      );
      for (let i = 0; i < groups.length; i += 1) {
        table.addRow(
          `$${groups[i]}$`,
          `$${dens[i]}$`,
          `$${widths[i]}$`,
          `$${freqs[i]}$`
        );
      }
      question.addTable("solution", table);
      question.addParagraph(
        `solution`,
        `$\\therefore$ modal group is $${mode}$`
      );

      question.addHeading(
        `solution`,
        `c) Median value is data point $${(n + 1) / 2}$ in an ordered list.`
      );
      question.addParagraph(
        "solution",
        `This value occurs $${into}$ data points into the group where, $${groups[groupC]}$`
      );
      question.addHeading(
        "solution",
        `Assuming times are evenly distributed in each group.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ median = $${groupBounds[groupC]} + \\frac{${into}}{${dens[groupC]}} $`
      );
      question.addParagraph("solution", `$\\approx ${median.toFixed(2)}$`);

      question.requiresSelfMarking();

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(freqs[a_random], 7, `a) $\\ f=$`);
      question.addInputGroup(g2);

      const g3 = new MCQuestion.InputGroup(1);
      g3.addInput(median, 1, `c) median=`);
      question.addInputGroup(g3);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Histograms_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const groups = [
      `0\\leq t < 5`,
      `5\\leq t < 15`,
      `15\\leq t < 30`,
      `30\\leq t < 40`,
      `40\\leq t < 60`,
    ];

    const groupBounds = [0, 5, 15, 30, 40, 60];

    const widths = [5, 10, 15, 10, 20];
    const nums = [3, 10, 23, 35, 50];
    const freqs = [
      MCRandom.randomInt(4, 20),
      MCRandom.randomInt(4, 40),
      MCRandom.randomInt(4, 40),
      MCRandom.randomInt(20, 40),
      MCRandom.randomInt(4, 20),
    ];
    const dens = [
      freqs[0] / widths[0],
      freqs[1] / widths[1],
      freqs[2] / widths[2],
      freqs[3] / widths[3],
      freqs[4] / widths[4],
    ];
    const n = freqs.reduce((a, b) => a + b, 0);
    const dataX = [];
    for (let i = 0; i < freqs.length; i += 1) {
      for (let j = 0; j < freqs[i]; j += 1) {
        dataX.push(nums[i]);
      }
    }
    // console.log(dataX);
    const sample = new MCMaths.Stats(dataX);

    const lower = MCRandom.randomInt(20, 25);
    const upper = MCRandom.randomInt(35, 40);

    const c = dens[0] * (30 - lower) + dens[1] * (upper - 30);
    /* END VARIABLES AND CONSTANTS */
    if (c % 1 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const table = new MCQuestion.Table(`Money Spent (£)`, "Frequency");
      for (let i = 0; i < groups.length; i += 1) {
        table.addRow(`$${groups[i]}$`, `$${freqs[i]}$`);
      }
      question.addTable("question", table);

      question.addParagraph(
        `question`,
        `The table above shows data collected from shoppers at a supermarket about how much they were spending.`
      );
      question.addParagraph(
        `question`,
        `a) Draw a histogram to present this data.`
      );
      question.addParagraph(
        `question`,
        `b) Estimate the number of shoppers who spent between $£${lower}$ and $£${upper}$`
      );
      question.addHeading(
        "solution",
        `a) Calculate the group width and frequency density for each group.`
      );
      const table2 = new MCQuestion.Table(
        `Time (mins)`,
        `Frequency`,
        "Group Width",
        "Frequency Density"
      );
      for (let i = 0; i < groups.length; i += 1) {
        let density = dens[i];
        if (parseFloat(dens[i].toFixed(1)) !== density) {
          density = density.toFixed(1);
        }
        table2.addRow(
          `$${groups[i]}$`,
          `$${freqs[i]}$`,
          `$${widths[i]}$`,
          `$${density}$`
        );
      }
      question.addTable("solution", table2);
      question.addHeading("solution", `Draw the histogram.`);
      question.addGraph("solution", sample.histogramWidths(groupBounds));

      question.addHeading(
        "solution",
        `b) Assuming times are evenly distributed in each group.`
      );
      question.addParagraph(
        "solution",
        `total = $${dens[0]}(30-${lower}) + ${dens[1]}(${upper}-30)$`
      );
      question.addParagraph("solution", `$=${c.toFixed(0)}$`);

      // answers

      question.requiresSelfMarking();

      const g3 = new MCQuestion.InputGroup(1);
      g3.addInput(c, 2, `b)`);
      question.addInputGroup(g3);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Histograms_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const groups = [`0-5`, `6-12`, `13-20`, `21-30`, `31-40`];
    const groupBounds = [0, 5.5, 12.5, 20.5, 30.5, 40.5];
    const widths = [5.5, 7, 8, 10, 10];
    const nums = [3, 9, 17, 25, 35];
    const freqs = [
      MCRandom.randomInt(20, 50),
      MCRandom.randomInt(10, 30),
      MCRandom.randomInt(15, 30),
      MCRandom.randomInt(5, 15),
      MCRandom.randomInt(5, 10),
    ];
    const dens = [
      freqs[0] / widths[0],
      freqs[1] / widths[1],
      freqs[2] / widths[2],
      freqs[3] / widths[3],
      freqs[4] / widths[4],
    ];

    const dataX = [];
    for (let i = 0; i < freqs.length; i += 1) {
      for (let j = 0; j < freqs[i]; j += 1) {
        dataX.push(nums[i]);
      }
    }
    // console.log(dataX);
    const sample = new MCMaths.Stats(dataX);

    const n = freqs.reduce((a, b) => a + b, 0);

    const i_lower = MCRandom.randomInt(1, 2);
    const lower = new MCMaths.Fraction(groupBounds[i_lower]);
    const i_upper = MCRandom.randomInt(i_lower + 1, groupBounds.length - 2);
    // console.log(`i_lower:$${i_lower}$, lower:$${lower}$, i_upper:$${i_upper}$`);
    const upper = new MCMaths.Fraction(
      groupBounds[i_upper] +
        MCRandom.randomInt(
          3,
          Math.floor(groupBounds[i_upper + 1] - groupBounds[i_upper])
        )
    );

    const uBound = new MCMaths.Fraction(groupBounds[i_upper]);

    const addition = [];
    for (let i = i_lower; i < i_upper; i += 1) {
      addition.push(freqs[i]);
    }

    const subTotal = addition.reduce((a, b) => a + b, 0);
    const total =
      subTotal +
      parseFloat(dens[i_upper].toFixed(1)) *
        (upper.toFloat() - groupBounds[i_upper]);

    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const table = new MCQuestion.Table(
        `Time (to the nearest hour)`,
        "Frequency"
      );
      for (let i = 0; i < groups.length; i += 1) {
        table.addRow(`$${groups[i]}$`, `$${freqs[i]}$`);
      }
      question.addTable("question", table);

      question.addParagraph(
        "question",
        `The number of hours spent at a computer screen per week in a study of $${n}$ individuals is summarised above.`
      );

      question.addParagraph(
        `question`,
        `a) Draw a histogram to represent this data.`
      );
      question.addParagraph(
        `question`,
        `b) Calculate how many people spent between $${lower.toMixed()}$ and $${upper.toMixed()}$ hours at a screen in a week. `
      );

      // solution
      question.addHeading(
        "solution",
        `a) Calculate the group width and frequency density for each group.`
      );
      const table2 = new MCQuestion.Table(
        `Time (to the nearest hour)`,
        `Actual Group Time`,
        `Frequency`,
        "Group Width",
        "Frequency Density"
      );
      for (let i = 0; i < groups.length; i += 1) {
        let density = dens[i];
        if (parseFloat(dens[i].toFixed(1)) !== density) {
          density = density.toFixed(1);
        }
        table2.addRow(
          `$${groups[i]}$`,
          `$${groupBounds[i]}<t\\leq${groupBounds[i + 1]}$`,
          `$${freqs[i]}$`,
          `$${widths[i]}$`,
          `$${density}$`
        );
      }
      question.addParagraph(
        "solution",
        `Actual group times vary due to the rounding of the time to the nearest hour.`
      );
      question.addTable("solution", table2);
      question.addHeading("solution", `Draw the histogram.`);
      question.addGraph("solution", sample.histogramWidths(groupBounds));

      // b
      question.addHeading(
        `solution`,
        `b) For $${lower.toFloat()}<t\\leq ${uBound.toFloat()}$`
      );
      question.addParagraph("solution", `$f=${addition.join("+")}$`);
      question.addParagraph("solution", `$=${subTotal}$`);

      question.addHeading(
        "solution",
        `For $${uBound.toFloat()}<t\\leq ${upper.toFloat()}$`
      );

      question.addHeading(
        "solution",
        `Assuming times are evenly distributed in each group.`
      );
      question.addParagraph(
        "solution",
        `total = $${subTotal}+${dens[i_upper].toFixed(1)}(${upper.toFloat()}-${
          groupBounds[i_upper]
        })$`
      );
      question.addParagraph("solution", `$=${total}$`);
      if (parseFloat(total.toFixed(0)) !== total) {
        question.addParagraph("solution", `$\\approx ${total.toFixed(0)}$`);
      }

      // answers

      question.requiresSelfMarking();

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(parseFloat(total.toFixed(0)), 2, `b)`);
      question.addInputGroup(g1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Histograms_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const groups = [
      `0\\leq x < 5`,
      `5\\leq x < 10`,
      `10\\leq x < 15`,
      `15\\leq x < 20`,
    ];

    const groupBounds = [0, 5, 10, 15, 20];

    const widths = [5, 5, 5, 5];
    const nums = [3, 8, 13, 18];

    const dens = [
      MCRandom.randomInt(1, 10),
      MCRandom.randomInt(1, 10),
      MCRandom.randomInt(1, 10),
      MCRandom.randomInt(1, 10),
    ];

    const tmp = [];
    for (let i = 0; i < dens.length; i += 1) {
      tmp.push(dens[i] * widths[i]);
    }
    const freqs = [...tmp];

    const dataX = [];
    for (let i = 0; i < freqs.length; i += 1) {
      for (let j = 0; j < freqs[i]; j += 1) {
        dataX.push(nums[i]);
      }
    }
    const sample = new MCMaths.Stats(dataX);

    const n = dataX.length;

    let groupI = 0;
    let total = (n - 1) / 2;
    let into = 0;

    for (let i = 0; i < freqs.length; i += 1) {
      total -= freqs[i];
      if (total < 0) {
        into = total + freqs[i] + 1;
        groupI = i;
        break;
      }
    }
    const median = groupBounds[groupI] + into / dens[groupI];

    let answerIndex = 0;
    for (let i = 0; i < groupBounds.length - 1; i += 1) {
      if (median > groupBounds[i] && median < groupBounds[i + 1]) {
        answerIndex = i;
        break;
      }
    }

    /* END VARIABLES AND CONSTANTS */
    if (n % 2 === 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(4);
      question.addGraph(sample.histogramWidths(groupBounds));
      question.addParagraph(
        "Select the group which contains the median value of $x$."
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$${groups[0]}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$${groups[1]}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$${groups[2]}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${groups[3]}$`);

      question.addAnswer(a1, answerIndex === 0);
      question.addAnswer(a2, answerIndex === 1);
      question.addAnswer(a3, answerIndex === 2);
      question.addAnswer(a4, answerIndex === 3);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Histograms_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const groups = [
      `0\\leq x < 10`,
      `10\\leq x < 15`,
      `15\\leq x < 30`,
      `30\\leq x < 35`,
    ];

    const groupBounds = [0, 10, 15, 30, 35];

    const widths = [10, 5, 15, 5];
    const nums = [5, 13, 20, 32];

    const dens = [
      MCRandom.randomInt(3, 8),
      MCRandom.randomInt(5, 10),
      MCRandom.randomInt(1, 4),
      MCRandom.randomInt(5, 10),
    ];

    const tmp = [];
    for (let i = 0; i < dens.length; i += 1) {
      tmp.push(dens[i] * widths[i]);
    }
    const freqs = [...tmp];

    const dataX = [];
    for (let i = 0; i < freqs.length; i += 1) {
      for (let j = 0; j < freqs[i]; j += 1) {
        dataX.push(nums[i]);
      }
    }
    const sample = new MCMaths.Stats(dataX);

    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }

    const highest = Math.max(...freqs);
    let index = 0;
    for (let i = 0; i < freqs.length; i += 1) {
      if (freqs[i] === highest) {
        index = i;
      }
    }

    const mode = groups[index];

    /* END VARIABLES AND CONSTANTS */
    if (allDifferent(freqs)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(4);

      question.addGraph(sample.histogramWidths(groupBounds));
      question.addParagraph("Select the modal group of $x$.");

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`$${groups[0]}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`$${groups[1]}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$${groups[2]}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${groups[3]}$`);

      question.addAnswer(a1, mode === groups[0]);
      question.addAnswer(a2, mode === groups[1]);
      question.addAnswer(a3, mode === groups[2]);
      question.addAnswer(a4, mode === groups[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Outliers_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    let n = MCRandom.randomInt(6, 15);
    let sample = [...MCMaths.Stats.randomSample(n, 200, 250, 100).dataX];
    n += 2;

    const outlier_upper = MCRandom.randomInt(300, 320) / 100;
    const outlier_lower = MCRandom.randomInt(120, 150) / 100;
    sample.push(outlier_upper);
    sample.push(outlier_lower);
    sample = MCRandom.shuffle(sample);

    const stats = new MCMaths.Stats([...sample.sort()]);

    const mean = stats.mean();
    const q1 = stats.q1();
    const q3 = stats.q3();
    const iqr = q3 - q1;

    const upper = mean + 1.5 * iqr;
    const lower = mean - 1.5 * iqr;
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(mean.toFixed(3)) === mean &&
      parseFloat(q1.toFixed(2)) === q1 &&
      parseFloat(q3.toFixed(2)) === q3 &&
      parseFloat(iqr.toFixed(2)) === iqr &&
      parseFloat(upper.toFixed(3)) === upper &&
      parseFloat(lower.toFixed(3)) === lower &&
      upper > 2.5 &&
      lower < 2 &&
      outlier_upper > upper &&
      outlier_lower < lower
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Researchers collect a sample of $${n}$ tuna which have the following lengths in metres:`
      );
      question.addParagraph("question", `$${sample}$`);
      question.addParagraph(
        "question",
        `a) Calculate the mean length of tuna in the sample.`
      );
      question.addParagraph(
        "question",
        `b) Caclulate the interquartile range of the sample.`
      );
      question.addParagraph(
        "question",
        `An outlier is defined as being greater than a distance of $1.5 \\times IQR$ from the sample mean.`
      );
      question.addParagraph(
        "question",
        `c) Determine if any values collected in this sample are outliers.`
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) $\\overline{x}=\\displaystyle\\frac{\\sum x}{n}$`
      );
      question.addParagraph("solution", `$=\\frac{${sample.join("+")}}{${n}}$`);
      question.addParagraph("solution", `$=${mean}$`);

      // b
      question.addHeading("solution", `b) Order the sample.`);
      question.addParagraph("solution", `$${sample.sort()}$`);
      question.addHeading("solution", `Find the quartiles for the data set.`);
      if (n % 2 === 1 && (n + 1) % 4 === 0) {
        question.addParagraph(
          `solution`,
          `$Q1$ is data point $${
            (n + 1) / 4
          }$ in  an ordered list of papers completed and $Q3$ is data point $${
            (3 * (n + 1)) / 4
          }$`
        );
      } else if (n % 2 === 1 && (n - 1) % 4 === 0) {
        question.addParagraph(
          `solution`,
          `$Q1$ is the mean of data points $${(n - 1) / 4}$ and $${
            (n - 1) / 4 + 1
          }$ in  an ordered list of papers completed and $Q3$ is the mean of data points $${
            (3 * (n - 1)) / 4 + 1
          }$ and $${(3 * (n - 1)) / 4 + 2}$`
        );
      } else if (n % 4 === 0) {
        question.addParagraph(
          "solution",
          `$Q1$ is the mean of data points $${n / 4}$ and $${
            n / 4 + 1
          }$ in  an ordered list of papers completed and $Q3$ is the mean of data points $${
            (3 * n) / 4 + 1
          }$ and $${(3 * n) / 4 + 2}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$Q1$ is data point $${
            (n + 2) / 4
          }$ in  an ordered list of papers completed and $Q3$ is data point $${
            (3 * (n + 2)) / 4 - 1
          }$`
        );
      }
      question.addParagraph("solution", `$\\therefore Q1=${q1}$`);
      question.addParagraph("solution", `$\\therefore Q3=${q3}$`);
      question.addParagraph("solution", `IQR$=Q_3-Q_1$`);
      question.addParagraph("solution", `$=${q3}-${q1}$`);
      question.addParagraph("solution", `$=${iqr}$`);
      // c
      question.addHeading(
        "solution",
        `c) Find the range of values for outliers.`
      );
      question.addParagraph("solution", `$${mean}+1.5(${iqr})=${upper}$`);
      question.addParagraph("solution", `$${mean}-1.5(${iqr})=${lower}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ Outliers in the range $x<${lower}$ or $x>${upper}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore ${outlier_lower}$m and $${outlier_upper}$m are outliers for tuna lengths in this sample.`
      );
      // answers
      const g0 = new MCQuestion.InputGroup(1);
      g0.addInput(mean, 0.05, `a)`);
      question.addInputGroup(g0);

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(iqr, 0.05, `b)`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput([outlier_lower, outlier_upper], 0.05, `c)`);
      question.addInputGroup(g2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Outliers_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const groups = [
      `20 \\leq x < 30`,
      `30 \\leq x < 50`,
      `50 \\leq x < 60`,
      `60 \\leq x \\leq 80`,
    ];
    const freqs = [
      MCRandom.randomInt(10, 20),
      MCRandom.randomInt(20, 40),
      MCRandom.randomInt(20, 40),
      MCRandom.randomInt(10, 20),
    ];
    const n = freqs.reduce((a, b) => a + b);

    const mean =
      (25 * freqs[0] + 40 * freqs[1] + 55 * freqs[2] + 70 * freqs[3]) / n;
    const sd = Math.sqrt(
      (freqs[0] * (25 - parseFloat(mean.toFixed(2))) ** 2 +
        freqs[1] * (40 - parseFloat(mean.toFixed(2))) ** 2 +
        freqs[2] * (55 - parseFloat(mean.toFixed(2))) ** 2 +
        freqs[3] * (70 - parseFloat(mean.toFixed(2))) ** 2) /
        n
    );

    const upper = parseFloat(mean.toFixed(2)) + 2 * parseFloat(sd.toFixed(2));
    const lower = parseFloat(mean.toFixed(2)) - 2 * parseFloat(sd.toFixed(2));
    /* END VARIABLES AND CONSTANTS */
    if (lower < 30 && upper > 60) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const table = new MCQuestion.Table(`Test score $(x$%$)$`, "Frequency");
      for (let i = 0; i < freqs.length; i += 1) {
        table.addRow(`$${groups[i]}$`, `$${freqs[i]}$`);
      }
      question.addTable("question", table);

      question.addParagraph(
        "question",
        `The table above shows the percentage marks achieved on a test by $${n}$ students.`
      );
      question.addParagraph(
        "question",
        `a) Estimate the mean and standard deviation of scores achieved.`
      );
      question.addParagraph(
        "question",
        `An outlier is any value more than $2$ standard deviations from the sample mean.`
      );
      question.addParagraph(
        "question",
        `b) Determine whether it's possible that there are outliers in the dataset that is summarised in the table above.`
      );

      // solution
      // a
      question.addHeading(
        `solution`,
        `a) Estimate the mean and standard deviation for the ages of attendees, giving your answers to two decimal places.`
      );
      question.addParagraph(`solution`, `$n=${n}$`);
      question.addHeading(
        "solution",
        `Finding the midpoints of the class intervals`
      );
      question.addParagraph("solution", `$${groups[0]}$`);
      question.addParagraph("solution", `$\\frac{1}{2}(20+30)=25$`);
      question.addParagraph("solution", `$${groups[1]}$`);
      question.addParagraph("solution", `$\\frac{1}{2}(30+50)=40$`);
      question.addParagraph("solution", `$${groups[2]}$`);
      question.addParagraph("solution", `$\\frac{1}{2}(50+60)=55$`);
      question.addParagraph("solution", `$${groups[3]}$`);
      question.addParagraph("solution", `$\\frac{1}{2}(60+80)=70$`);

      question.addHeading("solution", `Estimating mean`);
      question.addParagraph(
        "solution",
        `$\\overline{X}=\\displaystyle\\frac{25(${freqs[0]}) + 40(${freqs[1]}) + 55(${freqs[2]}) + 70(${freqs[3]}) }{${n}}$`
      );
      question.addParagraph("solution", `$=${mean}$`);
      question.addParagraph("solution", `$\\approx ${mean.toFixed(2)}$`);
      question.addHeading("solution", `Estimating standard deviation`);
      question.addParagraph(
        "solution",
        `$\\sigma=\\displaystyle\\sqrt{\\displaystyle\\frac{\\sum{(x-\\overline{X})^2}}{n}}$`
      );
      question.addParagraph(
        "solution",
        `$=\\displaystyle\\sqrt{\\frac{{${freqs[0]}(25-${mean.toFixed(
          2
        )})^2 + ${freqs[1]}(40-${mean.toFixed(2)})^2 + ${
          freqs[2]
        }(55-${mean.toFixed(2)})^2 + ${freqs[3]}(70-${mean.toFixed(
          2
        )})^2}}{${n}}}$`
      );
      question.addParagraph("solution", `$=${sd}$`);
      question.addParagraph("solution", `$\\approx ${sd.toFixed(2)}$`);

      // b
      question.addHeading(
        "solution",
        `b) Find the range of values for outliers.`
      );
      question.addParagraph(
        "solution",
        `$${mean.toFixed(2)}+2(${sd.toFixed(2)})\\approx ${upper.toFixed(2)}$`
      );
      question.addParagraph(
        "solution",
        `$${mean.toFixed(2)}-2(${sd.toFixed(2)})\\approx ${lower.toFixed(2)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Outliers in the range $x<${lower.toFixed(
          2
        )}$ or $x>${upper.toFixed(2)}$`
      );
      if (lower > 20 && upper < 80) {
        question.addParagraph(
          "solution",
          `$\\therefore$ Possible outliers in the groups where $${groups[0]}$ and $${groups[3]}$`
        );
      } else if (lower > 20) {
        question.addParagraph(
          "solution",
          `$\\therefore$ Possible outliers in the group where $${groups[0]}$`
        );
      } else if (upper < 80) {
        question.addParagraph(
          "solution",
          `$\\therefore$ Possible outliers in the group where $${groups[3]}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore$ No possibility of outliers having been recorded.`
        );
      }

      // answers
      const g0 = new MCQuestion.InputGroup(2);
      g0.addInput(mean, 1, `a) $\\ \\displaystyle\\overline{X}=$`);
      g0.addInput(sd, 1, `a) $\\ \\sigma=$`);
      question.addInputGroup(g0);

      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Outliers_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = 10;
    const dataX = [];
    const dataY = [];
    const x_begin = 5;
    const y_begin = 40;
    for (let i = 0; i < n; i += 1) {
      dataX.push(x_begin + i + MCRandom.randomInt(-1, 1));
      dataY.push(y_begin + 5 * i + MCRandom.randomInt(-2, 2));
    }
    const ox1 = MCRandom.randomInt(2, 5);
    const oy1 = MCRandom.randomInt(75, 95);
    const ox2 = MCRandom.randomInt(12, 15);
    const oy2 = MCRandom.randomInt(20, 40);

    dataX.push(ox1);
    dataY.push(oy1);
    dataX.push(ox2);
    dataY.push(oy2);

    const stats = new MCMaths.Stats(dataX, dataY);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addGraph("question", stats.scatter(false));
      question.addParagraph(
        "question",
        `The scatter diagram above shows the hours spent practicing kick ups against the highest number of kick ups people managed to do.`
      );
      question.addParagraph(
        "question",
        `a) Identify two possible outliers and give a possible explanation for their position on the scatter diagram.`
      );
      question.addParagraph(
        "question",
        `b) Comment on the correlation shown by the scatter diagram and interpret this in context.`
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) Possible outlier at $(${ox1},${oy1})$ due to any one of the following:`
      );
      question.addMultipleParagraphs("solution", [
        `Data entry error`,
        `Person is naturally good at kick ups`,
        "Person has lots of football experience already",
        "Lucky attempt by the person",
        "Effiecient use of practice time",
      ]);
      question.addHeading(
        "solution",
        `Possible outlier at $(${ox2},${oy2})$ due to any one of the following:`
      );
      question.addMultipleParagraphs("solution", [
        `Data entry error`,
        "Person gets nervous when score is being recorded",
        "Person is naturally not very good, despite all the practice",
        "Ineffective/misused practice",
        "Person feeling ill/other external factors",
      ]);
      // b
      question.addHeading(
        "solution",
        `b) Appears to follow a strong positive correlation when ignoring outliers.`
      );
      question.addParagraph(
        "solution",
        `This shows that the more hours you spend practicing kick ups, the better you get at them/the more kick ups you can do.`
      );
      //
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Outliers_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    // Lowest, Q1, Median, Q3, Highest, Outlier
    const index = MCRandom.randomInt(0, 4);

    const plotsData = [
      [9, 14, 18, 24, 32, 34], // 0
      [7, 12, 17, 20, 24, 3], // 1
      [6, 15, 19, 28, 34, 39], // 2
      [8, 12, 23, 27, 32, 0], // 3
      [13, 19, 24, 27, 33, 38],
    ]; // 4

    const dataX = [...plotsData[index]];
    if (dataX[0] > dataX[5]) {
      // outliers below low point
      dataX.push(dataX[3] + MCRandom.randomInt(0, dataX[4] - dataX[3]));
    } else {
      dataX.push(dataX[0] + MCRandom.randomInt(0, dataX[1] - dataX[0]));
    }
    for (let i = 0; i < 4; i += 1) {
      dataX.push(
        MCRandom.randomInt(plotsData[index][i], plotsData[index][i + 1])
      );
    }

    const sorted = [...dataX].sort(function (a, b) {
      return a - b;
    });
    const stats = new MCMaths.Stats([...sorted]);

    const iqr = stats.iqr();

    const upper = plotsData[index][2] + 1.5 * iqr;
    const lower = plotsData[index][2] - 1.5 * iqr;

    /* END VARIABLES AND CONSTANTS */
    if (
      plotsData[index][2] === stats.median() &&
      plotsData[index][1] === stats.q1() &&
      plotsData[index][3] === stats.q3()
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `The number of tries needed to win a prize on a fairground game is recorded by playtesters. They record the number of tries they each take to form the sample below.`
      );
      question.addParagraph("question", `$${MCRandom.shuffle([...dataX])}$`);
      question.addParagraph(
        "question",
        `a) State the median number of attempts.`
      );
      question.addParagraph(
        "question",
        `b) State the interquartile range of attempts.`
      );
      question.addParagraph(
        "question",
        `An outlier is defined as being more than $1.5 \\times IQR$ away from the median of the sample.`
      );
      question.addParagraph(
        "question",
        `c) Draw a box plot for the data, clearly indicating any outliers.`
      );

      // solution
      // a
      question.addHeading("solution", `a) Order the list.`);
      question.addParagraph(`solution`, `$${sorted}$`);
      question.addParagraph(
        `solution`,
        `$\\therefore$ Median $=${plotsData[index][2]}$`
      );
      // b
      question.addHeading("solution", `b) Finding the quartiles.`);
      question.addParagraph("solution", `$Q_1=${plotsData[index][1]}$`);
      question.addParagraph("solution", `$Q_3=${plotsData[index][3]}$`);
      question.addParagraph(
        "solution",
        `$IQR=${plotsData[index][3]}-${plotsData[index][1]}$`
      );
      question.addParagraph("solution", `$=${iqr}$`);
      // c
      question.addHeading(
        "solution",
        `c) Find the range of values for outliers.`
      );
      question.addParagraph(
        "solution",
        `$${plotsData[index][2]}+1.5(${iqr})= ${upper}$`
      );
      question.addParagraph(
        "solution",
        `$${plotsData[index][2]}-1.5(${iqr})= ${lower}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Outliers in the range $x<${lower}$ or $x>${upper}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Only outlier is $${plotsData[index][5]}$`
      );
      question.addHeading(
        "solution",
        `Draw a boxplot without the outlier, adding it as a single point.`
      );
      const bp = new MCQuestion.Image(
        `Leo/BoxPlots/Outliers/OutliersQ4_${index + 1}.svg`,
        `width`,
        40
      );
      question.addImage("solution", bp);

      // answers
      const g = new MCQuestion.InputGroup(1);
      g.addInput(plotsData[index][2], 0, `a)`);
      g.addInput(iqr, 0, `b)`);
      question.addInputGroup(g);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Outliers_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    // Lowest, Q1, Median, Q3, Highest, Outlier
    const person = MCMisc.getName();

    const index = MCRandom.randomInt(0, 4);

    const plotsData = [
      [12, 32, 52, 64, 96, 112], // 0
      [20, 40, 56, 76, 92, 12], // 1
      [14, 28, 44, 54, 68, 8], // 2
      [14, 22, 38, 48, 60, 2], // 3
      [16, 36, 68, 80, 100, 112],
    ]; // 4
    const median = plotsData[index][2];
    const iqr = plotsData[index][3] - plotsData[index][1];
    const outlier = plotsData[index][5];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const bp = new MCQuestion.Image(
        `Leo/BoxPlots/Outliers/OutliersQ5_${index + 1}.svg`,
        `width`,
        40
      );
      question.addImage("question", bp);

      question.addParagraph(
        `question`,
        `An optometrist gives each of ${person.hisher} clients a vision score to help them understand how their vision compares to others. The boxplot above represents the scores given to clients throughout the month.`
      );
      question.addParagraph(`question`, `a) State the median vision score.`);
      question.addParagraph(
        `question`,
        `b) Caculate the interquartile range of the vision scores.`
      );
      question.addParagraph(
        `question`,
        `c) State the value of the anomalous score.`
      );
      //
      question.addHeading(
        "solution",
        `a) Reading from the middle bar on the box plot.`
      );
      question.addParagraph(
        `solution`,
        `The median vision score is $${median}$`
      );
      //

      question.addHeading(
        "solution",
        `b) Finding the values for $Q_1$ and $Q_3$.`
      );
      question.addParagraph("solution", `$Q_1=${plotsData[index][1]}$`);
      question.addParagraph("solution", `$Q_3=${plotsData[index][3]}$`);
      question.addParagraph("solution", `IQR $=Q_3-Q_1$`);
      question.addParagraph(
        "solution",
        `$=${plotsData[index][3]}-${plotsData[index][1]}$`
      );
      question.addParagraph("solution", `$=${iqr}$`);
      //
      question.addHeading(`solution`, ` c) Reading from the cross.`);
      question.addParagraph(
        `solution`,
        `Outliers are plotted as individual points on a box plot, therefore the anomalous vision score is $${outlier}$`
      );
      //

      const g = new MCQuestion.InputGroup(1);
      g.addInput(median, 0, `a)`);
      g.addInput(iqr, 0, `b)`);
      g.addInput(outlier, 0, `c)`);
      question.addInputGroup(g);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Outliers_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const groups = [
      `0 \\leq x < 10`,
      `10 \\leq x < 25`,
      `25 \\leq x < 40`,
      `40 \\leq x \\leq 60`,
    ];
    const freqs = [
      MCRandom.randomInt(5, 15),
      MCRandom.randomInt(15, 30),
      MCRandom.randomInt(15, 30),
    ];

    freqs.push(
      MCRandom.randomInt(
        5 + (freqs.reduce((a, b) => a + b) % 2),
        15 + (freqs.reduce((a, b) => a + b) % 2),
        2
      )
    );

    const n = freqs.reduce((a, b) => a + b);
    const mean =
      (5 * freqs[0] + 17.5 * freqs[1] + 32.5 * freqs[2] + 50 * freqs[3]) / n;

    const sd = Math.sqrt(
      (freqs[0] * (5 - parseFloat(mean.toFixed(2))) ** 2 +
        freqs[1] * (17.5 - parseFloat(mean.toFixed(2))) ** 2 +
        freqs[2] * (32.5 - parseFloat(mean.toFixed(2))) ** 2 +
        freqs[3] * (50 - parseFloat(mean.toFixed(2))) ** 2) /
        n
    );

    const medianDataPoint = Math.ceil(n / 2);
    const pointsIn = medianDataPoint - freqs[0] - freqs[1];
    const median = 25 + (pointsIn / freqs[2]) * 15;

    const upper = parseFloat(median.toFixed(2)) + 3 * parseFloat(sd.toFixed(2));
    const lower = parseFloat(median.toFixed(2)) - 3 * parseFloat(sd.toFixed(2));

    /* END VARIABLES AND CONSTANTS */
    if (pointsIn > 5 && medianDataPoint - freqs[0] - freqs[1] - freqs[2] < -5) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const table = new MCQuestion.Table(
        `Distance Commuted (miles)`,
        "Frequency"
      );
      for (let i = 0; i < freqs.length; i += 1) {
        table.addRow(`$${groups[i]}$`, `$${freqs[i]}$`);
      }
      question.addTable("question", table);

      question.addParagraph(
        `question`,
        `A company assesses the number of miles each of its $${n}$ employees has to commute each day. The results are summarised in the table above.`
      );
      question.addParagraph(
        `question`,
        `a) Estimate the median number of miles an employee commutes.`
      );
      question.addParagraph(
        `question`,
        `b) Estimate the standard deviation of miles commuted.`
      );
      question.addParagraph(
        `question`,
        `An outlier is any value $3$ or more standard deviations from the median.`
      );
      question.addParagraph(
        `question`,
        `c) Determine whether it is possible for there to be outliers in this dataset.`
      );
      // solution
      //
      //
      question.addHeading(
        `solution`,
        `a) Median value is datapoint $${medianDataPoint}$ in an ordered list.`
      );
      question.addParagraph(
        `solution`,
        `This occurs $${pointsIn}$ datapoints into the group $25\\leq x<40$`
      );
      question.addHeading(
        `solution`,
        `Assuming datapoints are evenly spread throughout the group.`
      );
      question.addParagraph(
        `solution`,
        `Median $=25 + \\displaystyle\\frac{${pointsIn}}{${freqs[2]}}(40-25)$`
      );
      if (parseFloat(median.toFixed(2)) !== median) {
        question.addParagraph(
          `solution`,
          `$=${parseFloat(median.toFixed(5))}$`
        );
        question.addParagraph(
          `solution`,
          `$\\approx ${parseFloat(median.toFixed(2))}$`
        );
      } else {
        question.addParagraph(
          `solution`,
          `$= ${parseFloat(median.toFixed(2))}$`
        );
      }
      // b

      question.addHeading(
        "solution",
        `b) Finding the midpoints of the class intervals`
      );
      question.addParagraph("solution", `$${groups[0]}$`);
      question.addParagraph("solution", `$\\frac{1}{2}(0+10)=5$`);
      question.addParagraph("solution", `$${groups[1]}$`);
      question.addParagraph("solution", `$\\frac{1}{2}(10+25)=17.5$`);
      question.addParagraph("solution", `$${groups[2]}$`);
      question.addParagraph("solution", `$\\frac{1}{2}(25+40)=32.5$`);
      question.addParagraph("solution", `$${groups[3]}$`);
      question.addParagraph("solution", `$\\frac{1}{2}(40+60)=50$`);

      question.addHeading("solution", `Estimating mean`);
      question.addParagraph(
        "solution",
        `$\\overline{X}=\\displaystyle\\frac{5(${freqs[0]}) + 17.5(${freqs[1]}) + 32.5(${freqs[2]}) + 50(${freqs[3]}) }{${n}}$`
      );
      question.addParagraph("solution", `$=${mean}$`);
      question.addParagraph("solution", `$\\approx ${mean.toFixed(2)}$`);
      question.addHeading("solution", `Estimating standard deviation`);
      question.addParagraph(
        "solution",
        `$\\sigma=\\displaystyle\\sqrt{\\displaystyle\\frac{\\sum{(x-\\overline{X})^2}}{n}}$`
      );
      question.addParagraph(
        "solution",
        `$=\\displaystyle\\sqrt{\\frac{{${freqs[0]}(5-${mean.toFixed(2)})^2 + ${
          freqs[1]
        }(17.5-${mean.toFixed(2)})^2 + ${freqs[2]}(32.5-${mean.toFixed(
          2
        )})^2 + ${freqs[3]}(50-${mean.toFixed(2)})^2}}{${n}}}$`
      );
      question.addParagraph("solution", `$=${sd}$`);
      question.addParagraph("solution", `$\\approx ${sd.toFixed(2)}$`);

      // c
      question.addHeading(
        "solution",
        `c) Find the range of values for outliers.`
      );
      question.addParagraph(
        "solution",
        `$${parseFloat(median.toFixed(2))}+3(${sd.toFixed(
          2
        )})\\approx ${upper.toFixed(2)}$`
      );
      question.addParagraph(
        "solution",
        `$${parseFloat(median.toFixed(2))}-3(${sd.toFixed(
          2
        )})\\approx ${lower.toFixed(2)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Outliers in the range $x<${lower.toFixed(
          2
        )}$ or $x>${upper.toFixed(2)}$`
      );
      if (lower > 0 && upper < 60) {
        question.addParagraph(
          "solution",
          `$\\therefore$ Possible outliers in the groups where $${groups[0]}$ and $${groups[3]}$`
        );
      } else if (lower > 0) {
        question.addParagraph(
          "solution",
          `$\\therefore$ Possible outliers in the group where $${groups[0]}$`
        );
      } else if (upper < 60) {
        question.addParagraph(
          "solution",
          `$\\therefore$ Possible outliers in the group where $${groups[3]}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore$ No possibility of outliers having been recorded.`
        );
      }

      // answers
      const g0 = new MCQuestion.InputGroup(1);
      g0.addInput(median, 1, `a) `);
      g0.addInput(sd, 1, `b)`);
      question.addInputGroup(g0);

      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Outliers_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    // Lowest, Q1, Median, Q3, Highest, Outlier
    const index = MCRandom.randomInt(0, 4);

    const plotsData = [
      [7, 10, 13, 17, 25, 28], // 0
      [7, 11, 15, 17, 23, 2], // 1
      [9, 13, 17, 20, 28, 1], // 2
      [11, 15, 22, 25, 32, 1], // 3
      [4, 8, 12, 16, 22, 29],
    ]; // 4

    const dataX = [...plotsData[index]];
    if (dataX[0] > dataX[5]) {
      // outliers below low point
      dataX.push(dataX[3] + MCRandom.randomInt(0, dataX[4] - dataX[3]));
    } else {
      dataX.push(dataX[0] + MCRandom.randomInt(0, dataX[1] - dataX[0]));
    }
    for (let i = 0; i < 4; i += 1) {
      dataX.push(
        MCRandom.randomInt(plotsData[index][i], plotsData[index][i + 1])
      );
    }

    const sorted = [...dataX].sort(function (a, b) {
      return a - b;
    });
    const stats = new MCMaths.Stats([...sorted]);

    const iqr = stats.iqr();

    const upper = plotsData[index][2] + 2 * iqr;
    const lower = plotsData[index][2] - 2 * iqr;

    /* END VARIABLES AND CONSTANTS */
    if (
      plotsData[index][2] === stats.median() &&
      plotsData[index][1] === stats.q1() &&
      plotsData[index][3] === stats.q3()
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `The mean screen time, per week, of $${dataX.length}$ students over summer is shown in the sample below.`
      );
      question.addParagraph("question", `$${MCRandom.shuffle([...dataX])}$`);
      question.addParagraph("question", `a) State the median screen time.`);
      question.addParagraph(
        "question",
        `b) State the interquartile range for average screen time per week.`
      );
      question.addParagraph(
        "question",
        `An outlier is defined as being at least $2 \\times IQR$ away from the median of the sample.`
      );
      question.addParagraph(
        "question",
        `c) Draw a box plot for the data, clearly indicating any outliers.`
      );
      //
      // solution
      // a
      question.addHeading("solution", `a) Order the list.`);
      question.addParagraph(`solution`, `$${sorted}$`);
      question.addParagraph(
        `solution`,
        `$\\therefore$ Median $=${plotsData[index][2]}$`
      );
      // b
      question.addHeading("solution", `b) Finding the quartiles.`);
      question.addParagraph("solution", `$Q_1=${plotsData[index][1]}$`);
      question.addParagraph("solution", `$Q_3=${plotsData[index][3]}$`);
      question.addParagraph(
        "solution",
        `$IQR=${plotsData[index][3]}-${plotsData[index][1]}$`
      );
      question.addParagraph("solution", `$=${iqr}$`);
      // c
      question.addHeading(
        "solution",
        `c) Find the range of values for outliers.`
      );
      question.addParagraph(
        "solution",
        `$${plotsData[index][2]}+2(${iqr})= ${upper}$`
      );
      question.addParagraph(
        "solution",
        `$${plotsData[index][2]}-2(${iqr})= ${lower}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Outliers in the range $x\\leq ${lower}$ or $x\\geq ${upper}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Only outlier is $${plotsData[index][5]}$`
      );
      question.addHeading(
        "solution",
        `Draw a boxplot without the outlier, adding it as a single point.`
      );
      const bp = new MCQuestion.Image(
        `Leo/BoxPlots/Outliers/OutliersQ7_${index + 1}.svg`,
        `width`,
        40
      );
      question.addImage("solution", bp);

      // answers
      const g = new MCQuestion.InputGroup(1);
      g.addInput(plotsData[index][2], 0, `a)`);
      g.addInput(iqr, 0, `b)`);
      question.addInputGroup(g);

      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Outliers_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    // for making larger numbers easier to read
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const n = 9;
    const dataX = [];
    const dataY = [];
    const y_begin = 5;
    const ox1 = MCRandom.randomInt(1, 3);
    const ox2 = MCRandom.randomInt(7, 9);

    for (let i = 1; i < n + 1; i += 1) {
      if (i === ox1) {
        dataX.push(ox1);
        dataY.push(MCRandom.randomInt(22, 28));
      } else if (i === ox2) {
        dataX.push(ox2);
        dataY.push(MCRandom.randomInt(2, 8));
      } else {
        dataX.push(i);
        dataY.push(y_begin + 2.5 * i + MCRandom.randomInt(-2, 2));
      }
    }

    const stats = new MCMaths.Stats(dataX, dataY);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      // question.addGraph("question", stats.scatter(false));
      const t = new MCQuestion.Table(
        `Months Since Release`,
        dataX[0].toString(),
        dataX[1].toString(),
        dataX[2].toString(),
        dataX[3].toString(),
        dataX[4].toString(),
        dataX[5].toString(),
        dataX[6].toString(),
        dataX[7].toString(),
        dataX[8].toString()
      );
      t.addRow(
        `Mean Player Count`,
        numberWithCommas(dataY[0] * 10000).toString(),
        numberWithCommas(dataY[1] * 10000).toString(),
        numberWithCommas(dataY[2] * 10000).toString(),
        numberWithCommas(dataY[3] * 10000).toString(),
        numberWithCommas(dataY[4] * 10000).toString(),
        numberWithCommas(dataY[5] * 10000).toString(),
        numberWithCommas(dataY[6] * 10000).toString(),
        numberWithCommas(dataY[7] * 10000).toString(),
        numberWithCommas(dataY[8] * 10000).toString()
      );
      question.addTable("question", t);
      question.addParagraph(
        "question",
        `The table above shows the months since release and the mean player count for a studio's new game.`
      );
      question.addParagraph(
        "question",
        `a) Plot the data on a scatter diagram, with months since release on the x-axis and the mean player count, in tens of thousands, on the y-axis.`
      );
      question.addParagraph(
        "question",
        `b) Identify the outliers on the scatter diagram.`
      );
      question.addParagraph(
        "question",
        `c) Comment on the correlation shown by the scatter diagram and interpret this in context.`
      );
      // solution
      // a
      question.addHeading(`solution`, `a) Plot the scatter graph`);
      question.addGraph("solution", stats.scatter(false));
      // b
      question.addHeading("solution", `b) Outliers at:`);

      question.addParagraph(
        "solution",
        `$(${ox1},${dataY[ox1 - 1]})$ and $(${ox2},${dataY[ox2 - 1]})$`
      );

      // c
      question.addHeading(
        "solution",
        `c) Appears to follow a strong positive correlation when ignoring outliers.`
      );
      question.addParagraph(
        "solution",
        `This shows that the playerbase of the game is growing over time.`
      );
      //
      question.requiresSelfMarking();

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Outliers_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const offset = MCRandom.randomInt(-1, 1);
    const q1 = MCRandom.randomInt(4, 10, 2) + offset;
    const q3 = MCRandom.randomInt(14, 20, 2) + offset;
    const iqr = q3 - q1;

    const lower = q1 - 1.5 * iqr;
    const upper = q3 + 1.5 * iqr;

    const a = MCRandom.randomInt(lower - 2, lower + 6);
    const b = MCRandom.randomInt(lower - 2, lower + 6);
    const c = MCRandom.randomInt(upper - 6, upper + 2);
    const d = MCRandom.randomInt(upper - 6, upper + 2);

    function isOutlier(value, low, high) {
      if (value <= low || value >= high) {
        return true;
      }
      return false;
    }

    function countOutliers(arr, low, high) {
      let counter = 0;
      for (let i = 0; i < arr.length; i += 1) {
        if (isOutlier(arr[i], low, high)) {
          counter += 1;
        }
      }
      return counter;
    }

    const numOutliers = countOutliers([a, b, c, d], lower, upper);

    /* END VARIABLES AND CONSTANTS */
    if (
      lower % 1 === 0 &&
      upper % 1 === 0 &&
      numOutliers >= 1 &&
      numOutliers <= 3 &&
      a !== b &&
      c !== d
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(`A sample has $Q_1=${q1}$ and $Q_3=${q3}$`);
      question.addParagraph(
        `An outlier is any value outside the range of, $Q_1-1.5\\times IQR< x< Q_3+1.5\\times IQR$.`
      );
      question.addParagraph("Select all the outliers below.");

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`$${a}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`$${b}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`$${c}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${d}$`);

      question.addAnswer(a1, isOutlier(a, lower, upper));
      question.addAnswer(a2, isOutlier(b, lower, upper));
      question.addAnswer(a3, isOutlier(c, lower, upper));
      question.addAnswer(a4, isOutlier(d, lower, upper));

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Outliers_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = 13;
    const dataX = [];
    const dataY = [];
    const x_begin = 2;
    const y_begin = 40;
    for (let i = 0; i < n; i += 1) {
      dataX.push(x_begin + i + MCRandom.randomInt(-1, 1));
      dataY.push(y_begin - 3 * i + MCRandom.randomInt(-2, 2));
    }
    const ox1 = MCRandom.randomInt(0, 2);
    const oy1 = MCRandom.randomInt(2, 10);
    const ox2 = MCRandom.randomInt(13, 15);
    const oy2 = MCRandom.randomInt(40, 50);

    const stats = new MCMaths.Stats(dataX, dataY);

    const outlier = [`$(${ox1}, ${oy1})$`, `$(${ox2}, ${oy2})$`][
      MCRandom.randomInt(0, 1)
    ];

    const vx1 = MCRandom.randomInt(2, 5);
    const vy1 = -3 * vx1 + 46;
    const vx2 = MCRandom.randomInt(6, 9);
    const vy2 = -3 * vx2 + 46;
    const vx3 = MCRandom.randomInt(10, 13);
    const vy3 = -3 * vx3 + 46;

    const validPoints = [
      `$(${vx1}, ${vy1})$`,
      `$(${vx2}, ${vy2})$`,
      `$(${vx3}, ${vy3})$`,
    ];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addGraph(stats.scatter());
      question.addParagraph(
        `A number of points were not plotted on the scatter diagram above as they were outliers.`
      );
      question.addParagraph(
        `Select ONE of the following points that would be considered an outlier if it were to be plotted.`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`${outlier}`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`${validPoints[0]}`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`${validPoints[1]}`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`${validPoints[2]}`);

      question.addAnswer(a1, true);
      question.addAnswer(a2, false);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Outliers_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const index = MCRandom.randomInt(0, 4);
    // Median, 1.5 X IQR
    const plotsData = [
      [0, 13.5], // 0
      [31, 12], // 1
      [4, 19.5], // 2
      [25, 22.5], // 3
      [7, 12],
    ][index]; // 4

    const lower = plotsData[0] - plotsData[1];
    const upper = plotsData[0] + plotsData[1];

    const a = MCRandom.randomInt(Math.floor(lower) - 2, Math.ceil(lower) + 6);
    const b = MCRandom.randomInt(Math.floor(lower) - 2, Math.ceil(lower) + 6);
    const c = MCRandom.randomInt(Math.floor(upper) - 6, Math.ceil(upper) + 2);
    const d = MCRandom.randomInt(Math.floor(upper) - 6, Math.ceil(upper) + 2);

    function isOutlier(value, low, high) {
      if (value < low || value > high) {
        return true;
      }
      return false;
    }

    function countOutliers(arr, low, high) {
      let counter = 0;
      for (let i = 0; i < arr.length; i += 1) {
        if (isOutlier(arr[i], low, high)) {
          counter += 1;
        }
      }
      return counter;
    }

    const numOutliers = countOutliers([a, b, c, d], lower, upper);

    /* END VARIABLES AND CONSTANTS */
    if (
      numOutliers >= 1 &&
      numOutliers <= 3 &&
      a !== lower &&
      b !== lower &&
      a !== b &&
      c !== upper &&
      d !== upper &&
      c !== d
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `A sample of data is used to produce the boxplot below.`
      );

      const bp = new MCQuestion.Image(
        `Leo/BoxPlots/Outliers/OutliersMCQ3_${index + 1}.svg`,
        `width`,
        40
      );
      question.addImage(bp);

      question.addParagraph(
        `Any new data recorded is an outlier if it's greater than $1.5 \\times IQR$ from the median, taking values from the boxplot above.`
      );
      question.addParagraph(`Select all the outliers below.`);

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addHeading(`$${a}$`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addHeading(`$${b}$`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addHeading(`$${c}$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$${d}$`);

      question.addAnswer(a1, isOutlier(a, lower, upper));
      question.addAnswer(a2, isOutlier(b, lower, upper));
      question.addAnswer(a3, isOutlier(c, lower, upper));
      question.addAnswer(a4, isOutlier(d, lower, upper));

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Representations_Outliers_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = 13;
    const dataX = [];
    const dataY = [];
    const x_begin = 2;
    const y_begin = 5;
    for (let i = 0; i < n; i += 1) {
      dataX.push(x_begin + i + MCRandom.randomInt(-1, 1));
      dataY.push(y_begin + 4 * i + MCRandom.randomInt(-2, 2));
    }
    const ox1 = MCRandom.randomInt(1, 3);
    const oy1 = MCRandom.randomInt(40, 50);
    const ox2 = MCRandom.randomInt(13, 15);
    const oy2 = MCRandom.randomInt(2, 10);

    dataX.push(ox1);
    dataY.push(oy1);
    dataX.push(ox2);
    dataY.push(oy2);

    const stats = new MCMaths.Stats(dataX, dataY);

    const outliers = [`$(${ox1}, ${oy1})$`, `$(${ox2}, ${oy2})$`];

    const correctIndex1 = MCRandom.randomInt(0, 5);
    const correctIndex2 = MCRandom.randomInt(6, 12);

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addGraph(stats.scatter());
      question.addParagraph(
        `Identify the outliers on the scatter graph above.`
      );

      const a1 = new MCQuestion.MultipleChoiceAnswer();
      a1.addParagraph(`${outliers[0]}`);

      const a2 = new MCQuestion.MultipleChoiceAnswer();
      a2.addParagraph(`${outliers[1]}`);

      const a3 = new MCQuestion.MultipleChoiceAnswer();
      a3.addParagraph(`$(${dataX[correctIndex1]}, ${dataY[correctIndex1]})$`);

      const a4 = new MCQuestion.MultipleChoiceAnswer();
      a4.addParagraph(`$(${dataX[correctIndex2]}, ${dataY[correctIndex2]})$`);

      question.addAnswer(a1, true);
      question.addAnswer(a2, true);
      question.addAnswer(a3, false);
      question.addAnswer(a4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}



export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q1() {
  let count = 0;
  // QUESTION a) Find the binomial expansion of Coefficient(a+bx)^n
  //          b) Hence find the expansion of Coefficient(a-bx)^n
  while (true) {
    // define required constants
    const a = MCRandom.randomInt(1, 10);
    const b = MCRandom.randomInt(1, 10);
    const n = MCRandom.randomInt(4, 8);

    // Use prebuilt modules to do the main maths involved, using the documentation as guidance
    const binomial = new MCMaths.Binomial(a, "", b, "x", n);
    const workingArray = binomial.expansionWorking(4);
    const binomialCoefficients = binomial.expansionCoefficient();
    const aSolution = binomialCoefficients[2] + binomialCoefficients[3];
    const bSolution = binomialCoefficients[2] - binomialCoefficients[3];

    const binomialB = new MCMaths.Binomial(a, "", -b, "x", n);
    if (Math.abs(aSolution) < 500 && Math.abs(bSolution) < 500) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Find the sum of the $x^2$ and $x^3$ coefficients in the expansion of $${binomial.toString()}$.`
      );

      question.addMultipleParagraphs("solution", [
        `a) HEADING${workingArray[1]}`,
        workingArray[2],
        workingArray[3],
        `${workingArray[4]}$+...$`,
      ]);

      question.addMultipleParagraphs("solution", [
        `Sum of coefficients is $ ${binomialCoefficients[2]}+${binomialCoefficients[3]}= ${aSolution}$`,
      ]);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(aSolution, 0, "a)");

      question.addParagraph(
        "question",
        `b) Hence, state the sum of the $x^2$ and $x^3$ coefficients in the expansion of $${binomialB.toString()}$.`
      );

      question.addHeading(
        "solution",
        `b) $${binomialB}=${binomial.toString().replace("x", "(-x)")}$`
      );
      question.addParagraph(
        "solution",
        `${workingArray[4]
          .replace(/x/g, "(-x)")
          .replace("\\therefore", "")
          .replace(
            binomial.toString().replace("x", "(-x)"),
            binomialB.toString()
          )}`
      );
      question.addParagraph(
        "solution",
        `$${binomial.toString()}=${binomialCoefficients[0]}-${
          binomialCoefficients[1]
        }x+${binomialCoefficients[2]}x^2-${binomialCoefficients[3]}x^3+...$`
      );
      question.addParagraph(
        "solution",
        `Sum of coefficients is $ ${binomialCoefficients[2]}-${binomialCoefficients[3]}= ${bSolution}$`
      );

      // question.addAnswerBox(bSolution, 0, "b)");
      group1.addInput(bSolution, 0, "b)");
      question.addInputGroup(group1);
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message); // done
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q10() {
  let count = 0;
  while (true) {
    const coefficient = MCRandom.randomInt(-5, 5) / 100;
    const a = MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(2, 5);
    const n = MCRandom.randomInt(5, 5);
    const a2 = MCRandom.randomInt(2, 5);
    const b_2 = MCRandom.randomInt(2, 5);

    const b1 = new MCMaths.Binomial(a, "", b, "x", n);
    const working = b1.expansionWorking(4);
    const b1coefficient = b1.expansionCoefficient(4);
    const b2 = new MCMaths.Binomial(a2, "", b_2, "x", 1);
    // const p1 = new MCMaths.Polynomial([
    //   b1coefficient[4],
    //   b1coefficient[3],
    //   b1coefficient[2],
    //   b1coefficient[1],
    //   b1coefficient[0],
    // ]);
    // const p2 = new MCMaths.Polynomial([b_2, a2]);
    // const p3 = p1.multiply(p2);

    const ai_answer = b1coefficient[1] * a2 + b1coefficient[0] * b_2;
    const aii_answer = b1coefficient[2] * a2 + b1coefficient[1] * b_2;
    const aiii_answer = b1coefficient[3] * a2 + b1coefficient[2] * b_2;

    if (
      true &&
      b1coefficient[0] !== b1coefficient[1] &&
      b1coefficient[1] !== b1coefficient[2] &&
      b1coefficient[2] !== b1coefficient[3] &&
      coefficient !== 0 &&
      coefficient !== 1 &&
      coefficient !== -1
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The expansion of $${b2
          .toString()
          .replace(1, "")}$$${b1.toString()}$ can be written as $${
          a ** n * a2
        }$$+Bx+Cx^2+Dx^3$`
      );
      question.addParagraph("question", `Find the values of $B$, $C$ and $D$.`);
      question.addMultipleParagraphs("solution", [
        working[0],
        working[1],
        working[2],
        working[3],
        working[4],
      ]);
      question.addParagraph(
        "solution",
        `$B = ($$ ${b1coefficient[1]}$$\\times$$${a2}$$)+($$${b1coefficient[0]}$$\\times$$${b_2}$$)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore B = $$ ${b1coefficient[1] * a2 + b1coefficient[0] * b_2}$`
      );
      question.addParagraph(
        "solution",
        `$C = ($$ ${b1coefficient[2]}$$\\times$$${a2}$$)+($$${b1coefficient[1]}$$\\times$$${b_2}$$)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore C = $$ ${b1coefficient[2] * a2 + b1coefficient[1] * b_2}$`
      );
      question.addParagraph(
        "solution",
        `$D = ($$ ${b1coefficient[3]}$$\\times$$${a2}$$)+($$${b1coefficient[2]}$$\\times$$${b_2}$$)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore D = $$ ${b1coefficient[3] * a2 + b1coefficient[2] * b_2}$`
      );

      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(ai_answer, 0, "$a) B = $ ");
      g1.addInput(aii_answer, 0, "$a) C = $ ");
      g1.addInput(aiii_answer, 0, "$a) D = $ ");
      question.addInputGroup(g1);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q11() {
  let count = 0;
  while (true) {
    const coefficient = MCRandom.randomInt(-5, 5) / 100;
    const a = MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(2, 5);
    const n = MCRandom.randomInt(5, 5);
    const a2 = MCRandom.randomInt(2, 5);
    const b_2 = MCRandom.randomInt(2, 5);

    const b1 = new MCMaths.Binomial(a, "", b, "x", n);
    const working = b1.expansionWorking(4);
    const b1coefficient = b1.expansionCoefficient(4);
    const b2 = new MCMaths.Binomial(a2, "", b_2, "x", 2);
    const b2coefficient = b2.expansionCoefficient(2);
    // const p1 = new MCMaths.Polynomial([
    //   b1coefficient[4],
    //   b1coefficient[3],
    //   b1coefficient[2],
    //   b1coefficient[1],
    //   b1coefficient[0],
    // ]);
    // const p2 = new MCMaths.Polynomial([
    //   b2coefficient[2],
    //   b2coefficient[1],
    //   b2coefficient[0],
    // ]);
    // const p3 = p1.multiply(p2);

    const ai_answer =
      b1coefficient[1] * b2coefficient[0] + b1coefficient[0] * b2coefficient[1];
    const aii_answer =
      b1coefficient[2] * b2coefficient[0] +
      b1coefficient[1] * b2coefficient[1] +
      b1coefficient[0] * b2coefficient[2];
    const aiii_answer =
      b1coefficient[3] * b2coefficient[0] +
      b1coefficient[2] * b2coefficient[1] +
      b1coefficient[1] * b2coefficient[2];

    if (
      b1coefficient[0] !== b1coefficient[1] &&
      b1coefficient[1] !== b1coefficient[2] &&
      b1coefficient[2] !== b1coefficient[3] &&
      coefficient !== 0 &&
      coefficient !== 1 &&
      coefficient !== -1
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The expansion of $${b2
          .toString()
          .replace(1, "")}$$${b1.toString()}$ can be written as $${
          a ** n * a2 ** 2
        }$$+Bx+Cx^2+Dx^3$`
      );
      question.addParagraph("question", `Find the values of $B$, $C$ and $D$.`);
      question.addMultipleParagraphs("solution", [
        working[0],
        working[1],
        working[2],
        working[3],
        working[4],
      ]);
      question.addParagraph(
        "solution",
        `Using simple expansion, $${b2}$$ = $$${b2.expansionString()}$`
      );
      question.addParagraph(
        "solution",
        `$B = ($$ ${b1coefficient[1]}$$\\times$$${b2coefficient[0]}$$)+($$${b1coefficient[0]}$$\\times$$${b2coefficient[1]}$$)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore B = $$ ${
          b1coefficient[1] * b2coefficient[0] +
          b1coefficient[0] * b2coefficient[1]
        }$`
      );
      question.addParagraph(
        "solution",
        `$C = ($$ ${b1coefficient[2]}$$\\times$$${b2coefficient[0]}$$)+($$${b1coefficient[1]}$$\\times$$${b2coefficient[1]}$$)+($$${b1coefficient[0]}$$\\times$$${b2coefficient[2]}$$)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore C = $$ ${
          b1coefficient[2] * b2coefficient[0] +
          b1coefficient[1] * b2coefficient[1] +
          b1coefficient[0] * b2coefficient[2]
        }$`
      );
      question.addParagraph(
        "solution",
        `$D = ($$ ${b1coefficient[3]}$$\\times$$${b2coefficient[0]}$$)+($$${b1coefficient[2]}$$\\times$$${b2coefficient[1]}$$)+($$${b1coefficient[1]}$$\\times$$${b2coefficient[2]}$$)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore D = $$ ${
          b1coefficient[3] * b2coefficient[0] +
          b1coefficient[2] * b2coefficient[1] +
          b1coefficient[1] * b2coefficient[2]
        }$`
      );

      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(ai_answer, 0, "$a) B = $ ");
      g1.addInput(aii_answer, 0, "$a) C = $ ");
      g1.addInput(aiii_answer, 0, "$a) D = $ ");
      question.addInputGroup(g1);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q12() {
  let count = 0;
  while (true) {
    // const coefficient = MCRandom.randomInt(2, 10);
    const a = MCRandom.randomInt(1, 4);
    const b = MCRandom.randomInt(2, 5);
    const n = MCRandom.randomInt(4, 4);
    const x = MCRandom.randomInt(1, 5);

    const b1 = new MCMaths.Binomial(a, "", b, "x", n);
    const working = b1.expansionWorking(4);
    const b1coefficient = b1.expansionCoefficient(4);
    const b2 = new MCMaths.Binomial(a, "", -b, "x", n);
    const working2 = b2.expansionWorking(4);
    const b2coefficient = b2.expansionCoefficient(4);
    const p1 = new MCMaths.Polynomial([
      b1coefficient[3],
      b1coefficient[2],
      b1coefficient[1],
      b1coefficient[0],
    ]);
    const p2 = new MCMaths.Polynomial([
      b2coefficient[3],
      b2coefficient[2],
      b2coefficient[1],
      b2coefficient[0],
    ]);
    const p3 = p1.subtract(p2);

    const ai_answer = b1coefficient[1];
    const aii_answer = b1coefficient[3];
    const b_answer = -aii_answer;
    const c_answer = 6 * b1coefficient[3] * x * x + 2 * b1coefficient[1];

    if (
      true &&
      b1coefficient[0] !== b1coefficient[1] &&
      b1coefficient[2] !== b1coefficient[3]
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The expansion of ${MCMaths.cleaner(
          `$${b1.toString()}$ can be written as ${MCMaths.cleaner(
            `$${b1
              .expansionString(4)
              .replace(b1coefficient[1], "p")
              .replace(b1coefficient[3], "q")}`
          )}$`
        )}`
      );
      question.addParagraph("question", `a) What are the values of p and q?`);
      question.addHeading("solution", `a) What are the values of p and q?`);
      question.addMultipleParagraphs("solution", [
        working[0],
        working[1],
        working[2],
        working[3],
        working[4],
      ]);
      question.addParagraph("solution", `$\\therefore$ $p = $ ${ai_answer}.`);
      question.addParagraph("solution", `$\\therefore q = $ ${aii_answer}.`);

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(ai_answer, 0, "$a) p = $ ");
      g1.addInput(aii_answer, 0, "$a) q = $ ");
      question.addInputGroup(g1);

      question.addParagraph(
        "question",
        `b) Hence, find the $x^3$ coefficient of the expansion of ${MCMaths.cleaner(
          `$${b1.toString().replace("+", "-")}$`
        )}`
      );
      question.addHeading(
        "solution",
        `b) Hence, find the $x^3$ coefficient of the expansion of ${MCMaths.cleaner(
          `$${b1.toString().replace("+", "-")}$`
        )}`
      );
      question.addMultipleParagraphs("solution", [
        working[4].replace("\\therefore", ""),
        working2[4],
      ]);
      question.addParagraph(
        "solution",
        `$\\therefore$ the $x^3$ coefficient for the expansion is $-${b1coefficient[3]}`
      );
      question.addParagraph(
        "question",
        `c) Hence, find the gradient of the curve $y=${b1.toString()}$$-$$${b2.toString()}$ at the point where $ x = $$${x}$`
      );
      question.addHeading(
        "solution",
        `c) Hence, find the gradient of the curve $y=${b1.toString()}$$-$$${b2.toString()}$ at the point where $ x = $$${x}$`
      );
      question.addParagraph(
        "solution",
        `$${b1.toString()}$$-$$${b2.toString()}$$= $$ ${p1.subtract(p2)}$`
      );
      question.addParagraph(
        "solution",
        `$\\frac{dy}{dx} = $$ ${p3.derivative()}$`
      );
      question.addParagraph(
        "solution",
        `At the point $ x = ${x}$, the gradient $ = ${p3
          .derivative()
          .toString()
          .replace("x", `(${x})`)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\frac{dy}{dx} = $$${c_answer}$ at the point $ x = ${x}$`
      );

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(b_answer, 0, "$b) $ ");
      question.addInputGroup(g2);
      const g3 = new MCQuestion.InputGroup(1);
      g3.addInput(c_answer, 0, "$c) $ ");
      question.addInputGroup(g3);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q13() {
  let count = 0;
  while (true) {
    // const coefficient = MCRandom.randomInt(2, 10);
    const a = MCRandom.randomInt(1, 4);
    const b = MCRandom.randomInt(2, 5);
    const n = MCRandom.randomInt(4, 5);

    const b1 = new MCMaths.Binomial(a, "", 1, "x", n);
    const working = b1.expansionWorking(4);
    const b1coefficient = b1.expansionCoefficient(4);
    const b2 = new MCMaths.Binomial(a, "", b, "x", n);
    const working2 = b2.expansionWorking(4);
    const b2coefficient = b2.expansionCoefficient(4);

    const ai_answer = b1coefficient[1];
    const aii_answer = b1coefficient[3];
    const b_answer = b2coefficient[3];

    if (
      true &&
      b1coefficient[0] !== b1coefficient[1] &&
      b1coefficient[2] !== b1coefficient[3]
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The expansion of ${MCMaths.cleaner(
          `$${b1.toString()}$ can be written as ${MCMaths.cleaner(
            `$${b1
              .expansionString(4)
              .replace(b1coefficient[1], "p")
              .replace(b1coefficient[3], "q")}`
          )}$`
        )}`
      );
      question.addParagraph("question", `a) What are the values of p and q?`);
      question.addHeading("solution", `a) What are the values of p and q?`);
      question.addMultipleParagraphs("solution", [
        working[0],
        working[1],
        working[2],
        working[3],
        working[4],
      ]);
      question.addParagraph("solution", `$\\therefore$ $p = $ ${ai_answer}.`);
      question.addParagraph("solution", `$\\therefore q = $ ${aii_answer}.`);

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(ai_answer, 0, "$a) p = $ ");
      g1.addInput(aii_answer, 0, "$a) q = $ ");
      question.addInputGroup(g1);

      question.addParagraph(
        "question",
        `b) Hence, find the $x^3$ coefficient of the expansion of ${MCMaths.cleaner(
          `$${b2.toString()}$`
        )}`
      );
      question.addHeading(
        "solution",
        `b) Hence, find the $x^3$ coefficient of the expansion of ${MCMaths.cleaner(
          `$${b1.toString()}$`
        )}`
      );
      question.addMultipleParagraphs("solution", [
        working[4].replace("\\therefore", ""),
        working[4].replace(/x/g, `(${b}x)`),
        working2[4],
      ]);
      question.addParagraph(
        "solution",
        `$\\therefore$ the $x^3$ coefficient for the expansion ${MCMaths.cleaner(
          `$${b2.toString()}$ is $${b_answer}$.`
        )}`
      );

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(b_answer, 0, "b) ");
      question.addInputGroup(g2);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q14() {
  let count = 0;
  while (true) {
    // const coefficient = MCRandom.randomInt(-5, 5) / 100;
    const a = MCRandom.randomInt(1, 5);
    const b = MCRandom.randomInt(2, 5);
    const n = MCRandom.randomInt(4, 4);
    // const i1 = MCRandom.randomInt(1, 4);
    // const i2 = MCRandom.randomInt(5, 9);

    const b1 = new MCMaths.Binomial(a, "", b, "x", n);
    const working = b1.expansionWorking(6);
    const b1coefficient = b1.expansionCoefficient(4);

    const p1 = new MCMaths.Polynomial([
      b1coefficient[4],
      b1coefficient[3],
      b1coefficient[2],
      b1coefficient[1],
      b1coefficient[0],
    ]);
    // const p2 = p1.integral();

    const a_answer = b1coefficient[2] / 3;
    if (true && b !== 0) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `$f(x) = ${b1.toString()}$`);
      question.addParagraph(
        "question",
        `Find the $x^3$ coefficient of $\\displaystyle \\int f(x) dx$.`
      );

      question.addMultipleParagraphs("solution", [
        working[0],
        working[1],
        working[2],
        working[3],
        working[4],
      ]);
      question.addParagraph(
        "solution",
        `$\\therefore \\int f(x) dx = ${p1.integral()} + c$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore $ the $x^3$ coefficient is $${a_answer}$`
      );

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(a_answer, 0, "$a)  $ ");
      question.addInputGroup(g1);
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q15() {
  let count = 0;
  while (true) {
    // const coefficient = MCRandom.randomInt(-5, 5) / 100;
    const a = MCRandom.randomInt(1, 5);
    const b = MCRandom.randomInt(2, 5);
    const n = MCRandom.randomInt(4, 4);
    // const i1 = MCRandom.randomInt(1, 4);
    // const i2 = MCRandom.randomInt(5, 9);

    const b1 = new MCMaths.Binomial(a, "", b, "x", n);
    const working = b1.expansionWorking(6);
    const b1coefficient = b1.expansionCoefficient(4);

    const p1 = new MCMaths.Polynomial([
      b1coefficient[4],
      b1coefficient[3],
      b1coefficient[2],
      b1coefficient[1],
      b1coefficient[0],
    ]);
    // const p2 = p1.integral();

    const a_answer = b1coefficient[4] * 4;
    if (true && b !== 0) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `$f(x) = ${b1.toString()}$`);
      question.addParagraph(
        "question",
        `Find the $x^3$ coefficient of $f'(x)$.`
      );
      question.addMultipleParagraphs("solution", [
        working[0],
        working[1],
        working[2],
        working[3],
        working[4],
      ]);
      question.addParagraph(
        "solution",
        `$\\therefore f'(x) = ${p1.derivative()}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore $ the $x^3$ coefficient is $${a_answer}$`
      );

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(a_answer, 0, "$a) $ ");
      question.addInputGroup(g1);
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q16() {
  let count = 0;
  while (true) {
    // const coefficient = MCRandom.randomInt(2, 10);
    const a = MCRandom.randomInt(1, 4);
    const b = MCRandom.randomInt(2, 5);
    const n = MCRandom.randomInt(4, 5);
    const stretch = MCRandom.randomInt(2, 5);

    const b1 = new MCMaths.Binomial(a, "", b, "x", n);
    const working = b1.expansionWorking(4);
    const b1coefficient = b1.expansionCoefficient(4);
    const b1expansion = b1.expansionString(4);

    const ai_answer = b1coefficient[1];
    const aii_answer = b1coefficient[3];
    // const b_answer = stretch * stretch * b1coefficient[2];

    if (
      true &&
      b1coefficient[0] !== b1coefficient[1] &&
      b1coefficient[2] !== b1coefficient[3]
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The expansion of ${MCMaths.cleaner(
          `$${b1.toString()}$ can be written as ${MCMaths.cleaner(
            `$${b1
              .expansionString(4)
              .replace(b1coefficient[1], "p")
              .replace(b1coefficient[3], "q")}`
          )}$`
        )}`
      );
      question.addParagraph("question", `a) What are the values of p and q?`);
      question.addHeading("solution", `a) What are the values of p and q?`);
      question.addMultipleParagraphs("solution", [
        working[0],
        working[1],
        working[2],
        working[3],
        working[4],
      ]);
      question.addParagraph("solution", `$\\therefore$ $p = $ ${ai_answer}.`);
      question.addParagraph("solution", `$\\therefore q = $ ${aii_answer}.`);

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(ai_answer, 0, "$a) p = $ ");
      g1.addInput(aii_answer, 0, "$a) q = $ ");
      question.addInputGroup(g1);

      question.addParagraph(
        "question",
        `b) Find the $x^2$ coefficient of $${b1}$ if the curve undergoes a stretch of scale factor $\\frac{1}{${stretch}}$ in the $x$ direction`
      );
      question.addHeading(
        "solution",
        `b) Find the $x^2$ coefficient of $${b1}$ if the curve undergoes a stretch of scale factor $\\frac{1}{${stretch}}$ in the $x$ direction`
      );
      question.addParagraph(
        "solution",
        `$= ${b1.toString().replace("x", `(${stretch}x)`)}$`
      );
      question.addParagraph(
        "solution",
        `$= ${b1expansion.toString().replace(/x/g, `(${stretch}x)`)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ the $x^2$ coefficent $=$ $${
          stretch * stretch * b1coefficient[2]
        }$`
      );

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(stretch * stretch * b1coefficient[2], 0, "$b) $ ");
      question.addInputGroup(g2);
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q17() {
  let count = 0;
  while (true) {
    const coefficient = MCRandom.randomInt(-5, 5) / 100;
    const a = MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(2, 5);
    const n = MCRandom.randomInt(4, 4);
    const ran = MCRandom.randomInt(2, 5);

    const b1 = new MCMaths.Binomial(a, "", b, "x", n);
    const working = b1.expansionWorking(4);
    const b1coefficient = b1.expansionCoefficient(4);

    const ai_answer = b1coefficient[1];
    const aii_answer = b1coefficient[2];
    const aiii_answer = b1coefficient[3];
    const bi_answer =
      b1coefficient[0] + ran * b1coefficient[2] + ran * ran * b1coefficient[4];
    const bii_answer = b1coefficient[1] + ran * b1coefficient[3];

    if (
      true &&
      b1coefficient[0] !== b1coefficient[1] &&
      b1coefficient[1] !== b1coefficient[2] &&
      b1coefficient[2] !== b1coefficient[3] &&
      coefficient !== 0 &&
      coefficient !== 1 &&
      coefficient !== -1 &&
      ran !== 4
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The expansion of ${MCMaths.cleaner(
          `$${b1.toString()}$ can be written as ${MCMaths.cleaner(
            `$${b1
              .expansionString(5)
              .replace(b1coefficient[1], "A")
              .replace(b1coefficient[2], "B")
              .replace(b1coefficient[3], "C")}`
          )}$`
        )}`
      );
      question.addParagraph(
        "question",
        `a) What are the values of A, B and C?`
      );
      question.addHeading("solution", `a) What are the values of A, B and C?`);
      question.addMultipleParagraphs("solution", [
        working[0],
        working[1],
        working[2],
        working[3],
        working[4],
      ]);
      question.addParagraph("solution", `$\\therefore$ $A = $ ${ai_answer}.`);
      question.addParagraph("solution", `$\\therefore$ $B = $ ${aii_answer}.`);
      question.addParagraph("solution", `$\\therefore$ $C = $ ${aiii_answer}.`);

      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(ai_answer, 0, "$a) A = $ ");
      g1.addInput(aii_answer, 0, "$a) B = $ ");
      g1.addInput(aiii_answer, 0, "$a) C = $ ");
      question.addInputGroup(g1);

      question.addParagraph(
        "question",
        `The expansion of $${b1
          .toString()
          .replace(
            "x",
            `(\\sqrt{${ran}})`
          )}$ can be written as  $X$ $+$ $Y$ $\\sqrt{${ran}}$`
      );
      question.addParagraph("question", `b) Find the values of X and Y `);
      question.addHeading("solution", `b) Find the values of X and Y `);
      question.addParagraph(
        "solution",
        `X$ = ${b1coefficient[0]} + (${b1coefficient[2]}\\times${ran}) + (${
          b1coefficient[4]
        }\\times${ran * ran})$`
      );
      question.addParagraph("solution", `$\\therefore$ X $ = ${bi_answer}$`);
      question.addParagraph(
        "solution",
        `Y$ = ${b1coefficient[1]}+ ( ${b1coefficient[3]}\\times${ran})$`
      );
      question.addParagraph("solution", `$\\therefore$ Y $ = ${bii_answer}$`);

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(bi_answer, 0, "$b) X = $ ");
      g2.addInput(bii_answer, 0, "$b) Y = $ ");
      question.addInputGroup(g2);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q18() {
  let count = 0;
  while (true) {
    // const coefficient = MCRandom.randomInt(2, 10);
    const a = MCRandom.randomInt(1, 4);
    const b = MCRandom.randomInt(2, 5);
    const n = MCRandom.randomInt(4, 5);

    const b1 = new MCMaths.Binomial(a, "", b, "x^2", n);
    const working = b1.expansionWorking(4);
    const b1coefficient = b1.expansionCoefficient(4);

    const ai_answer = b1coefficient[1];
    const aii_answer = b1coefficient[3];
    // const b_answer = b1coefficient[3];

    if (
      true &&
      b1coefficient[0] !== b1coefficient[1] &&
      b1coefficient[2] !== b1coefficient[3]
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The expansion of ${MCMaths.cleaner(
          `$${b1.toString()}$ can be written as ${MCMaths.cleaner(
            `$${b1
              .expansionString(4)
              .replace(b1coefficient[1], "p")
              .replace(b1coefficient[3], "q")}`
          )}$`
        )}`
      );
      question.addParagraph("question", `Find the values of $p$ and $q$.`);
      question.addMultipleParagraphs("solution", [
        working[0],
        working[1],
        working[2],
        working[3],
        working[4],
      ]);
      question.addParagraph("solution", `$\\therefore$ $p = $ ${ai_answer}.`);
      question.addParagraph("solution", `$\\therefore q = $ ${aii_answer}.`);

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(ai_answer, 0, "$a) p = $ ");
      g2.addInput(aii_answer, 0, "$a) q = $ ");
      question.addInputGroup(g2);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q19() {
  let count = 0;
  while (true) {
    const coefficient = MCRandom.randomInt(2, 10);
    const multi = MCRandom.randomInt(1, 1);
    const a = MCRandom.randomInt(1, 4) - multi * 0.5;
    const b = MCRandom.randomInt(1, 3);
    const n = MCRandom.randomInt(4, 6);

    const binomial = new MCMaths.Binomial(a, "", b, "x", n);
    const workingArray = binomial.expansionWorking(4);
    const binomialCoefficients = binomial.expansionCoefficient();
    const aSolution =
      (binomialCoefficients[2] + binomialCoefficients[3]) * coefficient;
    const bSolution =
      (binomialCoefficients[2] - binomialCoefficients[3]) * coefficient;

    const binomialB = new MCMaths.Binomial(a, "", -b, "x", n);
    if (true) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) What is the sum of the $x^2$ and $x^3$ coefficients in the expansion of $${coefficient}${binomial.toString()}$`
      );

      question.addParagraph(
        "solution",
        `a) What is the sum of the $x^2$ and $x^3$ coefficients in the expansion of $${coefficient}${binomial.toString()}$`
      );

      question.addMultipleParagraphs("solution", [
        workingArray[1],
        workingArray[2],
        workingArray[3],
        workingArray[4].replace("\\therefore", ""),
      ]);

      question.addHeading(
        "solution",
        `Using this to find $${coefficient}${binomial.toString()}$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\therefore${coefficient}${binomial.toString()}= ${coefficient}[$${workingArray[4].replace(
          `\\therefore${binomial.toString()}=`,
          ""
        )}$]$`,
      ]);

      question.addMultipleParagraphs("solution", [
        `$${coefficient}${binomial.toString()}=${
          binomialCoefficients[0] * coefficient
        }+${binomialCoefficients[1] * coefficient}x+${
          binomialCoefficients[2] * coefficient
        }x^2+${binomialCoefficients[3] * coefficient}x^3+...$`,
      ]);

      question.addParagraph(
        "solution",
        `$\\therefore$ Sum of $x^2$ and $x^3$ coefficients $= ${
          binomialCoefficients[2] * coefficient
        }+${binomialCoefficients[3] * coefficient}= ${aSolution}$`
      );

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(aSolution, 0, "$a) $ ");
      question.addInputGroup(g1);

      question.addParagraph(
        "question",
        `b) What is the sum of the $x^2$ and $x^3$ coefficients in the expansion of $${coefficient}${binomialB.toString()}$`
      );

      question.addHeading(
        "solution",
        `b)$${binomialB}=${binomial.toString().replace("x", "(-x)")}$`
      );
      question.addParagraph(
        "solution",
        `${workingArray[4]
          .replace(/x/g, "(-x)")
          .replace(
            binomial.toString().replace("x", "(-x)"),
            binomialB.toString()
          )}`
      );
      question.addParagraph(
        "solution",
        `$${coefficient}${binomial.toString()}=${
          binomialCoefficients[0] * coefficient
        }-${binomialCoefficients[1] * coefficient}x+${
          binomialCoefficients[2] * coefficient
        }x^2-${binomialCoefficients[3] * coefficient}x^3+...$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Sum of $x^2$ and $x^3$ coefficients $= ${
          binomialCoefficients[2] * coefficient
        }-${binomialCoefficients[3] * coefficient}= ${bSolution}$`
      );

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(bSolution, 0, "$b) $ ");
      question.addInputGroup(g2);
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q2() {
  let count = 0;
  while (true) {
    const coefficient = MCRandom.randomInt(2, 10);
    const a = MCRandom.randomInt(1, 4);
    const b = MCRandom.randomInt(2, 5);
    const n = MCRandom.randomInt(4, 5);

    const b1 = new MCMaths.Binomial(a, "", b, "x", n);
    const working = b1.expansionWorking(6);
    const b1coefficient = b1.expansionCoefficient(4);
    const b2 = new MCMaths.Binomial(a, "", -b, "x", n);
    const working2 = b2.expansionWorking(6);
    // const b2coefficient = b2.expansionCoefficient(4);

    const ai_answer = b1coefficient[1];
    const aii_answer = b1coefficient[3];
    const b_answer = b1coefficient[3] * -1 * coefficient;

    if (
      true &&
      b1coefficient[0] !== b1coefficient[1] &&
      b1coefficient[2] !== b1coefficient[3]
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The expansion of ${MCMaths.cleaner(
          `$${b1.toString()}$ can be written in the form ${MCMaths.cleaner(
            `$${b1
              .expansionString(6)
              .replace(b1coefficient[1], "p")
              .replace(b1coefficient[3], "q")}`
          )}$.`
        )}`
      );
      question.addParagraph("question", `a) Find the values of $p$ and $q$.`);
      question.addMultipleParagraphs("solution", [
        `a) ${working[0]}`,
        working[1],
        working[2],
        working[3],
        working[4],
      ]);
      question.addParagraph("solution", `$ p = ${ai_answer}$`);
      question.addParagraph("solution", `$ q =  ${aii_answer}$`);

      const group = new MCQuestion.InputGroup(2);
      group.addInput(ai_answer, 0, "a) $\\ p = $ ");
      group.addInput(aii_answer, 0, "a) $\\ q = $ ");
      question.addInputGroup(group);

      // DEPRECATED: question.addAnswerBox(ai_answer, 0, "$a) p = $ ");
      // DEPRECATED: question.addAnswerBox(aii_answer, 0, "$a) q = $ ");

      question.addParagraph(
        "question",
        `b) Hence, or otherwise, find the $x^3$ coefficient of the expansion of ${MCMaths.cleaner(
          `$${coefficient}${b1.toString().replace("+", "-")}$.`
        )}`
      );
      question.addHeading(
        "solution",
        `b) ${working2[4].replace("\\therefore", "")}`
      );
      question.addMultipleParagraphs("solution", [
        `$\\therefore$ Coefficient $ = -${aii_answer} \\cdot ${coefficient}$`,
        `$=${b_answer}$`,
      ]);

      // DEPRECATED question.addAnswerBox(b_answer, 0, "b)");
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(b_answer, 0, "b)");
      question.addInputGroup(group2);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q20() {
  let count = 0;
  while (true) {
    const coefficient = MCRandom.randomInt(2, 10);
    const multi = MCRandom.randomInt(1, 1);
    const a = MCRandom.randomInt(1, 3) - multi * 0.5;
    const b = MCRandom.randomInt(1, 3) - multi * 0.5;
    const n = MCRandom.randomInt(4, 4);

    const binomial = new MCMaths.Binomial(a, "", b, "x", n);
    const workingArray = binomial.expansionWorking(4);
    const binomialCoefficients = binomial.expansionCoefficient();
    const aSolution =
      (binomialCoefficients[2] + binomialCoefficients[3]) * coefficient;
    const bSolution =
      (binomialCoefficients[2] - binomialCoefficients[3]) * coefficient;

    const binomialB = new MCMaths.Binomial(a, "", -b, "x", n);
    if (true) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) What is the sum of the $x^2$ and $x^3$ coefficients in the expansion of $${coefficient}${binomial.toString()}$`
      );

      question.addParagraph(
        "solution",
        `a) What is the sum of the $x^2$ and $x^3$ coefficients in the expansion of $${coefficient}${binomial.toString()}$`
      );

      question.addMultipleParagraphs("solution", [
        workingArray[1],
        workingArray[2],
        workingArray[3],
        workingArray[4].replace("\\therefore", ""),
      ]);

      question.addHeading(
        "solution",
        `Using this to find $${coefficient}${binomial.toString()}$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\therefore${coefficient}${binomial.toString()}= ${coefficient}[$${workingArray[4].replace(
          `\\therefore${binomial.toString()}=`,
          ""
        )}$]$`,
      ]);

      question.addMultipleParagraphs("solution", [
        `$${coefficient}${binomial.toString()}=${
          binomialCoefficients[0] * coefficient
        }+${binomialCoefficients[1] * coefficient}x+${
          binomialCoefficients[2] * coefficient
        }x^2+${binomialCoefficients[3] * coefficient}x^3+...$`,
      ]);

      question.addParagraph(
        "solution",
        `$\\therefore$ Sum of $x^2$ and $x^3$ coefficients $= ${
          binomialCoefficients[2] * coefficient
        }+${binomialCoefficients[3] * coefficient}= ${aSolution}$`
      );

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(aSolution, 0, "$a) $ ");
      question.addInputGroup(g1);

      question.addParagraph(
        "question",
        `b) What is the sum of the $x^2$ and $x^3$ coefficients in the expansion of $${coefficient}${binomialB.toString()}$`
      );

      question.addHeading(
        "solution",
        `b)$${binomialB}=${binomial.toString().replace("x", "(-x)")}$`
      );
      question.addParagraph(
        "solution",
        `${workingArray[4]
          .replace(/x/g, "(-x)")
          .replace(
            binomial.toString().replace("x", "(-x)"),
            binomialB.toString()
          )}`
      );
      question.addParagraph(
        "solution",
        `$${coefficient}${binomial.toString()}=${
          binomialCoefficients[0] * coefficient
        }-${binomialCoefficients[1] * coefficient}x+${
          binomialCoefficients[2] * coefficient
        }x^2-${binomialCoefficients[3] * coefficient}x^3+...$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Sum of $x^2$ and $x^3$ coefficients $= ${
          binomialCoefficients[2] * coefficient
        }-${binomialCoefficients[3] * coefficient}= ${bSolution}$`
      );

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(bSolution, 0, "$b) $ ");
      question.addInputGroup(g2);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q3() {
  let count = 0;
  while (true) {
    const coefficient = MCRandom.randomInt(-5, 5) / 100;
    const a = MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(2, 5);
    const n = MCRandom.randomInt(4, 6);
    const c = (a + coefficient * b - a) / b;

    const b1 = new MCMaths.Binomial(a, "", b, "x", n);
    // const b2 = new MCMaths.Binomial(a, "", b, c.toString(), n);
    const working = b1.expansionWorking(4);
    const b1coefficient = b1.expansionCoefficient(4);
    const f1 = new MCMaths.Fraction(c);

    const ai_answer = b1coefficient[1];
    const aii_answer = b1coefficient[2];
    const aiii_answer = b1coefficient[3];
    const b_answer =
      b1coefficient[0] +
      c * b1coefficient[1] +
      c * c * b1coefficient[2] +
      c * c * c * b1coefficient[3];
    const b_answer2 = b_answer.toFixed(3);

    if (
      true &&
      b1coefficient[0] !== b1coefficient[1] &&
      b1coefficient[1] !== b1coefficient[2] &&
      b1coefficient[2] !== b1coefficient[3] &&
      coefficient !== 0 &&
      coefficient !== 1 &&
      coefficient !== -1
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The expansion of ${MCMaths.cleaner(
          `$${b1.toString()}$ can be written as ${MCMaths.cleaner(
            `$${b1
              .expansionString(4)
              .replace(b1coefficient[1], "A")
              .replace(b1coefficient[2], "B")
              .replace(b1coefficient[3], "C")}`
          )}$`
        )}`
      );
      question.addParagraph(
        "question",
        `a) What are the values of A, B and C?`
      );
      question.addHeading("solution", `a) What are the values of A, B and C?`);
      question.addMultipleParagraphs("solution", [
        working[0],
        working[1],
        working[2],
        working[3],
        working[4],
      ]);
      question.addParagraph("solution", `$\\therefore$ $A = $ ${ai_answer}.`);
      question.addParagraph("solution", `$\\therefore$ $B = $ ${aii_answer}.`);
      question.addParagraph("solution", `$\\therefore$ $C = $ ${aiii_answer}.`);

      question.addParagraph(
        "question",
        `b) Hence, find an estimate for $(${a + coefficient * b})^${n}$ (3dp)`
      );
      question.addHeading(
        "solution",
        `b) Hence, find an estimate for $(${a + coefficient * b})^${n}$ (3dp)`
      );
      question.addParagraph(
        "solution",
        `$x = ($ ${a + coefficient * b} $-$ ${a} $) /$ ${b}`
      );
      question.addParagraph("solution", `$\\therefore x = ${f1.toString()}$ .`);
      question.addParagraph(
        "solution",
        `We now substitute this $ x $ into our expansion`
      );
      question.addParagraph(
        "solution",
        `$\\therefore $ $ (${a + coefficient * b})^${n} $ $ = $ $ ${
          b1coefficient[0]
        } $ $ + $ $ ${b1coefficient[1]} $ $ \\times $ $ ( ${f1} ) $ $ + $ $ ${
          b1coefficient[2]
        } $ $ \\times $ $ ( ${f1} )^2 $ $ + $ $ ${
          b1coefficient[3]
        } $ $ \\times $ $ ( ${f1} )^3 $ `
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ our answer is $ ${b_answer} $ . `
      );
      question.addParagraph(
        "solution",
        `To 3 decimal places, this is $ ${b_answer.toFixed(3)} $ . `
      );

      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(ai_answer, 0, "a) A$=$");
      g1.addInput(aii_answer, 0, "a) B$=$");
      g1.addInput(aiii_answer, 0, "a) C$=$");
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(Number(b_answer2), 0, "b)");
      question.addInputGroup(g2);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q4() {
  let count = 0;
  while (true) {
    // const coefficient = MCRandom.randomInt(2, 10);
    const a = MCRandom.randomInt(1, 1);
    const b = MCRandom.randomInt(1, 1);
    const n = MCRandom.randomInt(3, 12);

    const binomial = new MCMaths.Binomial(a, "", b, "x", n);
    // const workingArray = binomial.expansionWorking(5);
    const binomialCoefficients = binomial.expansionCoefficient();
    // const aSolution = n;
    const part1 = binomialCoefficients[3] + binomialCoefficients[4];
    const part2 = binomialCoefficients[2];
    const f1 = new MCMaths.Fraction(part1 / part2);
    // const h1 = 41 - 3 * (part1 / part2);
    // const h2 = -22 + 3 * (part1 / part2);

    if (
      true &&
      a !== n &&
      b !== n &&
      part1 / part2 === Number((part1 / part2).toFixed(2)) &&
      part1 !== part2
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `In the expansion of $ ${MCMaths.cleaner(binomial.toString()).replace(
          n,
          "n"
        )} $ where $ n>3 $ , the sum of the $ x^3 $ and $ x^4 $ coefficients are $ ${f1.toString()} $ times the $ x^2 $ coefficent. `
      );
      question.addParagraph("question", `Find the value of n.`);

      question.addHeading("solution", `Rearranging:`);

      question.addParagraph(
        "solution",
        `$ ( {n \\choose 3} +  {n \\choose 4} )  =   ${f1}  \\times {n \\choose 2} $`
      );

      question.addParagraph(
        "solution",
        `$\\frac{n!}{(n-3)!\\times3!}$ + $\\frac{n!}{(n-4)!\\times4!}$ $ = $ $${f1}$ $ \\times $$ \\frac{n!}{(n-2)!\\times2!}$`
      );

      question.addParagraph(
        "solution",
        `$\\frac{n(n-1)(n-2)}{6}$ + $\\frac{n(n-1)(n-2)(n-3)}{24}$ $ = $ $${f1}$ $\\times$($\\frac{n(n-1)}{2})$ `
      );

      question.addParagraph("solution", `Now, we can cancel out $n(n-1)$.`);
      question.addParagraph(
        "solution",
        `$\\frac{n-2}{6}$ $+$ $\\frac{(n-2)(n-3)}{24}$ $=$$${f1}$$\\times$ $\\frac{1}{2}$`
      );

      question.addParagraph(
        "solution",
        `$n^2-n-2$ $=$$${(12 * part1) / part2}$`
      );
      question.addParagraph(
        "solution",
        `$n^2-n$$${-2 - (12 * part1) / part2}$$=0$`
      );
      question.addParagraph("solution", `$(n-$$${n}$$)(n+$$${n - 1}$$)=0$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ $n = $$ ${n} $ as $n>3$`
      );

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(n, 0, "a)");
      question.addInputGroup(g1);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message); // done
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q5() {
  let count = 0;
  while (true) {
    // const coefficient = MCRandom.randomInt(-5, 5) / 100;
    const a = MCRandom.randomInt(1, 5);
    const b = MCRandom.randomInt(2, 5);
    const n = MCRandom.randomInt(4, 4);
    const i1 = MCRandom.randomInt(1, 4);
    const i2 = MCRandom.randomInt(5, 9);

    const b1 = new MCMaths.Binomial(a, "", b, "x", n);
    const working = b1.expansionWorking(6);
    const b1coefficient = b1.expansionCoefficient(4);

    const p1 = new MCMaths.Polynomial([
      b1coefficient[4],
      b1coefficient[3],
      b1coefficient[2],
      b1coefficient[1],
      b1coefficient[0],
    ]);
    const p2 = p1.integral();

    const ai_answer = b1coefficient[1];
    const aii_answer = b1coefficient[2];
    const aiii_answer = b1coefficient[3];
    const b_answer =
      (b1coefficient[4] / 5) * i2 * i2 * i2 * i2 * i2 +
      (b1coefficient[3] / 4) * i2 * i2 * i2 * i2 +
      (b1coefficient[2] / 3) * i2 * i2 * i2 +
      (b1coefficient[1] / 2) * i2 * i2 +
      b1coefficient[0] * i2 -
      ((b1coefficient[4] / 5) * i1 * i1 * i1 * i1 * i1 +
        (b1coefficient[3] / 4) * i1 * i1 * i1 * i1 +
        (b1coefficient[2] / 3) * i1 * i1 * i1 +
        (b1coefficient[1] / 2) * i1 * i1 +
        b1coefficient[0] * i1);
    if (true && b !== 0) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The expansion of ${MCMaths.cleaner(
          `$${b1.toString()}$ can be written as ${MCMaths.cleaner(
            `$${b1
              .expansionString(5)
              .replace(b1coefficient[1], "X")
              .replace(b1coefficient[2], "Y")
              .replace(b1coefficient[3], "Z")}`
          )}$`
        )}`
      );
      question.addParagraph(
        "question",
        `a) What are the values of X, Y and Z?`
      );
      question.addHeading("solution", `a) What are the values of X, Y and Z?`);
      question.addMultipleParagraphs("solution", [
        working[0],
        working[1],
        working[2],
        working[3],
        working[4],
      ]);
      question.addParagraph("solution", `$\\therefore$ $X = $ ${ai_answer}.`);
      question.addParagraph("solution", `$\\therefore$ $Y = $ ${aii_answer}.`);
      question.addParagraph("solution", `$\\therefore$ $Z = $ ${aiii_answer}.`);

      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(ai_answer, 0, "$a) X = $ ");
      g1.addInput(aii_answer, 0, "$a) Y = $ ");
      g1.addInput(aiii_answer, 0, "$a) Z = $ ");
      question.addInputGroup(g1);

      question.addParagraph(
        "question",
        `b) Hence, find the the area under the curve  $ ${b1.toString()} $ from $ x = ${i1} $ to $ x = ${i2} $.`
      );
      question.addHeading(
        "solution",
        `b) Hence, find the the area under the curve  $ ${b1.toString()} $ from $ x = ${i1} $ to $ x = ${i2} $.`
      );
      question.addParagraph(
        "solution",
        `$\\int_{${i1}}^{${i2}} $$ ${p1} $$ dx $`
      );
      question.addParagraph(
        "solution",
        `$ = [${p1.integral().toString()}]_{${i1}}^{${i2}} $ `
      );
      question.addParagraph(
        "solution",
        `$ = (${p2
          .toString()
          .replace(/x/g, ` $ (${i2}) $ `)} ) $ - $ (${p2
          .toString()
          .replace(/x/g, ` $ (${i1}) $ `)} ) $`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ the answer is $ ${b_answer.toFixed(1)} $ `
      );

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(Number(b_answer.toFixed(1)), 0, "b)");
      question.addInputGroup(g2);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q6() {
  let count = 0;
  while (true) {
    const coefficient = MCRandom.randomInt(-5, 5) / 100;
    const a = MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(2, 5);
    const n = MCRandom.randomInt(5, 5);
    const a2 = MCRandom.randomInt(2, 5);
    const b_2 = MCRandom.randomInt(2, 5);
    const n2 = MCRandom.randomInt(3, 5);

    const b1 = new MCMaths.Binomial(a, "", b, "x", n);
    const working = b1.expansionWorking(4);
    const b1coefficient = b1.expansionCoefficient(4);
    const b2 = new MCMaths.Binomial(a2, "", b_2, "x", n2);
    const b2coefficient = b2.expansionCoefficient(2);
    // const p1 = new MCMaths.Polynomial([
    //   b1coefficient[4],
    //   b1coefficient[3],
    //   b1coefficient[2],
    //   b1coefficient[1],
    //   b1coefficient[0],
    // ]);
    // const p2 = new MCMaths.Polynomial([
    //   b2coefficient[2],
    //   b2coefficient[1],
    //   b2coefficient[0],
    // ]);
    // const p3 = p1.multiply(p2);
    const working2 = b2.expansionWorking(4);

    const ai_answer =
      b1coefficient[1] * b2coefficient[0] + b1coefficient[0] * b2coefficient[1];
    const aii_answer =
      b1coefficient[2] * b2coefficient[0] +
      b1coefficient[1] * b2coefficient[1] +
      b1coefficient[0] * b2coefficient[2];
    const aiii_answer =
      b1coefficient[3] * b2coefficient[0] +
      b1coefficient[2] * b2coefficient[1] +
      b1coefficient[1] * b2coefficient[2];

    if (
      true &&
      b1coefficient[0] !== b1coefficient[1] &&
      b1coefficient[1] !== b1coefficient[2] &&
      b1coefficient[2] !== b1coefficient[3] &&
      coefficient !== 0 &&
      coefficient !== 1 &&
      coefficient !== -1
    ) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The expansion of $${b2
          .toString()
          .replace(1, "")}$$${b1.toString()}$ can be written as $${
          a ** n * a2 ** 2
        }$$+Bx+Cx^2+Dx^3$.`
      );
      question.addParagraph("question", `Find the values of $B$, $C$ and $D$.`);
      question.addMultipleParagraphs("solution", [
        working[0],
        working[1],
        working[2],
        working[3],
        working[4],
      ]);
      question.addMultipleParagraphs("solution", [
        working2[0],
        working2[1],
        working2[2],
        working2[3],
        working2[4],
      ]);

      question.addParagraph(
        "solution",
        `$B = ($$ ${b1coefficient[1]}$$\\times$$${b2coefficient[0]}$$)+($$${b1coefficient[0]}$$\\times$$${b2coefficient[1]}$$)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore B = $$ ${
          b1coefficient[1] * b2coefficient[0] +
          b1coefficient[0] * b2coefficient[1]
        }$`
      );
      question.addParagraph(
        "solution",
        `$C = ($$ ${b1coefficient[2]}$$\\times$$${b2coefficient[0]}$$)+($$${b1coefficient[1]}$$\\times$$${b2coefficient[1]}$$)+($$${b1coefficient[0]}$$\\times$$${b2coefficient[2]}$$)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore C = $$ ${
          b1coefficient[2] * b2coefficient[0] +
          b1coefficient[1] * b2coefficient[1] +
          b1coefficient[0] * b2coefficient[2]
        }$`
      );
      question.addParagraph(
        "solution",
        `$D = ($$ ${b1coefficient[3]}$$\\times$$${b2coefficient[0]}$$)+($$${b1coefficient[2]}$$\\times$$${b2coefficient[1]}$$)+($$${b1coefficient[1]}$$\\times$$${b2coefficient[2]}$$)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore D = $$ ${
          b1coefficient[3] * b2coefficient[0] +
          b1coefficient[2] * b2coefficient[1] +
          b1coefficient[1] * b2coefficient[2]
        }$`
      );

      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(ai_answer, 0, "$a) B = $ ");
      g1.addInput(aii_answer, 0, "$a) C = $ ");
      g1.addInput(aiii_answer, 0, "$a) D = $ ");
      question.addInputGroup(g1);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q7() {
  let count = 0;
  while (true) {
    // const coefficient = MCRandom.randomInt(2, 10);
    const a = MCRandom.randomInt(1, 4);
    const n = MCRandom.randomInt(5, 5);
    const ran1 = MCRandom.randomInt(4, 8);
    const ran2 = MCRandom.randomInt(4, 8);

    const b1 = new MCMaths.Binomial(a, "", ran1, "x", n);
    const working = b1.expansionWorking(4);
    const b1coefficient = b1.expansionCoefficient(4);
    const b2 = new MCMaths.Binomial(a, "", ran2, "x", n);
    // const working2 = b2.expansionWorking(4);
    const b2coefficient = b2.expansionCoefficient(4);

    const a_answer = b1coefficient[3];
    const b_answer = ran2;

    if (true && a !== ran1 && ran1 !== ran2) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The expansion of $${b1
          .toString()
          .replace(
            ran1,
            "k"
          )}$ is defined for different values of k where k $ > 3 $`
      );
      question.addParagraph(
        "question",
        `a) In the case where $ k = ${ran1}$, find the coefficient for the $x^3$ term`
      );
      question.addParagraph(
        "question",
        `b) In another case, where the $x^3$ coefficient $ = ${b2coefficient[3]}$, find the value of $k$`
      );
      question.addHeading(
        "solution",
        `a) In the case where $ k = ${ran1}$, find the coefficient for the $x^3$ term`
      );
      question.addMultipleParagraphs("solution", [
        working[1],
        working[2],
        working[3],
        working[4],
      ]);
      question.addParagraph(
        "solution",
        `$\\therefore$ the $x^3$ coefficient is $${a_answer}$`
      );
      question.addHeading(
        "solution",
        `b) In another case, where the $x^3$ coefficient $ = ${b2coefficient[3]}$, find the value of $k$`
      );
      question.addParagraph(
        "solution",
        `$${b2coefficient[3]} = \\frac{n!}{r!(n-r)!} \\times (${a})^1 \\times k^3$`
      );
      question.addParagraph(
        "solution",
        `$${b2coefficient[3]} = \\frac{${n}!}{3!(${n}-3)!} \\times (${a})^2 \\times k^3$`
      );
      question.addParagraph(
        "solution",
        `$${b2coefficient[3]} = 10 \\times ${a * a}\\times k^3 $`
      );
      question.addParagraph(
        "solution",
        ` $${b2coefficient[3] / (10 * a * a)} = k^3 $`
      );
      question.addParagraph("solution", `$\\therefore k = ${b_answer}$`);

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(a_answer, 0, "a)");
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(b_answer, 0, "b)");
      question.addInputGroup(g2);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q8() {
  let count = 0;
  while (true) {
    const coefficient = MCRandom.randomInt(2, 10);
    const a = MCRandom.randomInt(2, 5);
    const multi = MCRandom.randomInt(1, 3);
    const b = MCRandom.randomInt(1, 3) - multi * 0.25;
    const n = MCRandom.randomInt(4, 6);

    const binomial = new MCMaths.Binomial(a, "", b, "x", n);
    const workingArray = binomial.expansionWorking(4);
    const binomialCoefficients = binomial.expansionCoefficient();
    const aSolution =
      (binomialCoefficients[2] + binomialCoefficients[3]) * coefficient;
    const bSolution =
      (binomialCoefficients[2] - binomialCoefficients[3]) * coefficient;

    const binomialB = new MCMaths.Binomial(a, "", -b, "x", n);
    if (true) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) What is the sum of the $x^2$ and $x^3$ coefficients in the expansion of $${coefficient}${binomial.toString()}$`
      );

      question.addParagraph(
        "solution",
        `a) What is the sum of the $x^2$ and $x^3$ coefficients in the expansion of $${coefficient}${binomial.toString()}$`
      );

      question.addMultipleParagraphs("solution", [
        workingArray[1],
        workingArray[2],
        workingArray[3],
        workingArray[4].replace("\\therefore", ""),
      ]);

      question.addHeading(
        "solution",
        `Using this to find $${coefficient}${binomial.toString()}$:`
      );

      question.addMultipleParagraphs("solution", [
        `$\\therefore${coefficient}${binomial.toString()}= ${coefficient}[$${workingArray[4].replace(
          `\\therefore${binomial.toString()}=`,
          ""
        )}$]$`,
      ]);

      question.addMultipleParagraphs("solution", [
        `$${coefficient}${binomial.toString()}=${
          binomialCoefficients[0] * coefficient
        }+${binomialCoefficients[1] * coefficient}x+${
          binomialCoefficients[2] * coefficient
        }x^2+${binomialCoefficients[3] * coefficient}x^3+...$`,
      ]);

      question.addParagraph(
        "solution",
        `$\\therefore$ Sum of $x^2$ and $x^3$ coefficients $= ${
          binomialCoefficients[2] * coefficient
        }+${binomialCoefficients[3] * coefficient}= ${aSolution}$`
      );

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(aSolution, 0, "a)");
      question.addInputGroup(g2);

      question.addParagraph(
        "question",
        `b) What is the sum of the $x^2$ and $x^3$ coefficients in the expansion of $${coefficient}${binomialB.toString()}$`
      );

      question.addHeading(
        "solution",
        `b)$${binomialB}=${binomial.toString().replace("x", "(-x)")}$`
      );
      question.addParagraph(
        "solution",
        `${workingArray[4]
          .replace(/x/g, "(-x)")
          .replace(
            binomial.toString().replace("x", "(-x)"),
            binomialB.toString()
          )}`
      );
      question.addParagraph(
        "solution",
        `$${coefficient}${binomial.toString()}=${
          binomialCoefficients[0] * coefficient
        }-${binomialCoefficients[1] * coefficient}x+${
          binomialCoefficients[2] * coefficient
        }x^2-${binomialCoefficients[3] * coefficient}x^3+...$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Sum of $x^2$ and $x^3$ coefficients $= ${
          binomialCoefficients[2] * coefficient
        }-${binomialCoefficients[3] * coefficient}= ${bSolution}$`
      );

      const g3 = new MCQuestion.InputGroup(1);
      g3.addInput(bSolution, 0, "b)");
      question.addInputGroup(g3);

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_SequencesAndSeries_BinomialExpansion_Exam_Q9() {
  let count = 0;
  while (true) {
    // const coefficient = MCRandom.randomInt(-5, 5) / 100;
    const a = MCRandom.randomInt(1, 5);
    const b = MCRandom.randomInt(2, 5);
    const n = MCRandom.randomInt(4, 5);
    const k = MCRandom.randomInt(3, 8);
    const y = MCRandom.randomInt(2, 6);

    const b1 = new MCMaths.Binomial(a, "", b, "x", n);
    const working = b1.expansionWorking(6);
    const b1coefficient = b1.expansionCoefficient(4);
    const b2 = new MCMaths.Binomial(y, "", k, "x", 1);

    const ai_answer = b1coefficient[1];
    const aii_answer = b1coefficient[2];
    const aiii_answer = b1coefficient[3];

    const b_answer = 2 * b1coefficient[0];
    const c_answer = k;
    const d_answer = ai_answer * k + aii_answer * y;

    if (true && b !== 0 && y !== k) {
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The expansion of ${MCMaths.cleaner(
          `$${b1.toString()}$ can be written as ${MCMaths.cleaner(
            `$${b1
              .expansionString(4)
              .replace(b1coefficient[1], "X")
              .replace(b1coefficient[2], "Y")
              .replace(b1coefficient[3], "Z")}`
          )}$`
        )}`
      );
      question.addParagraph(
        "question",
        `a) What are the values of X, Y and Z?`
      );
      question.addHeading("solution", `a) What are the values of X, Y and Z?`);
      question.addMultipleParagraphs("solution", [
        working[0],
        working[1],
        working[2],
        working[3],
        working[4],
      ]);
      question.addParagraph("solution", `$\\therefore$ $X = $ ${ai_answer}.`);
      question.addParagraph("solution", `$\\therefore$ $Y = $ ${aii_answer}.`);
      question.addParagraph("solution", `$\\therefore$ $Z = $ ${aiii_answer}.`);

      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(ai_answer, 0, "$a) X = $ ");
      g1.addInput(aii_answer, 0, "$a) Y = $ ");
      g1.addInput(aiii_answer, 0, "$a) Z = $ ");
      question.addInputGroup(g1);

      question.addParagraph(
        "question",
        `$f(x)=$$${b2
          .toString()
          .replace(k, "k")
          .replace("1", "")}$$${b1.toString()}$`
      );
      question.addParagraph(
        "question",
        `The expansion of $f(x)$, in ascending powers of $ x $ upto the $ x^2 $ term is $ A + $$${
          ai_answer * y + k * a ** n
        }$$x + Bx^2$`
      );
      question.addParagraph("question", `b) Find the value of A`);
      question.addParagraph("question", `c) Find the value of k`);
      question.addParagraph("question", `d) Hence, find the value of B`);
      question.addHeading("solution", `b) Find the value of A`);
      question.addParagraph(
        "solution",
        `$A = $$ ${b1coefficient[0]}$$\\times 2$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore A = $$ ${2 * b1coefficient[0]}$`
      );
      question.addHeading("solution", `c) Find the value of k`);
      question.addParagraph(
        "solution",
        `$${ai_answer * y + k * a ** n}$$ = k \\times$$${
          a ** n
        }$$+$$${y}$$\\times$$${ai_answer}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore k = \\frac{${
          ai_answer * y + k * a ** n
        }-(${y}\\times${ai_answer})}{${a ** n}} $`
      );
      question.addParagraph("solution", `$\\therefore k = ${k} $`);
      question.addHeading("solution", `d) Hence, find the value of B`);
      question.addParagraph(
        "solution",
        `$B = (X \\times k) + (Y \\times ${y})$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore B = (${ai_answer} \\times ${k}) + (${aii_answer} \\times ${y})$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore B = ${ai_answer * k + aii_answer * y}$`
      );

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(b_answer, 0, "b) A=");
      question.addInputGroup(g2);
      const g3 = new MCQuestion.InputGroup(1);
      g3.addInput(c_answer, 0, "c) k=");
      question.addInputGroup(g3);
      const g4 = new MCQuestion.InputGroup(1);
      g4.addInput(d_answer, 0, "d) B=");
      question.addInputGroup(g4);
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_RulesOfTrigonometry_Exam_Q1() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const angleCAB = MCRandom.randomInt(30, 70);
    const angleABC = MCRandom.randomInt(30, 70);
    const angleACB = 180 - angleCAB - angleABC;

    const angleCABRadians = angleCAB * (Math.PI / 180);
    const angleABCRadians = angleABC * (Math.PI / 180);
    const angleACBRadians = angleACB * (Math.PI / 180);

    const sideAC = MCRandom.randomInt(2, 9);
    const sideAB =
      (sideAC / Math.sin(angleABCRadians)) * Math.sin(angleACBRadians);
    const sideBCSquared =
      sideAC ** 2 +
      sideAB ** 2 -
      2 * sideAC * sideAB * Math.cos(angleCABRadians);
    const sideBC = Math.sqrt(sideBCSquared);

    const answer = 0.5 * sideAC * sideAB * Math.sin(angleCABRadians);

    /* END VARIABLES AND CONSTANTS */
    if (angleCAB + angleABC + angleACB === 180) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const myImage = new MCQuestion.Image("Matt/triangle1.svg", "width", 15);
      myImage.addOverlay(`$${sideAC}m$`, 20, 45);
      myImage.addOverlay(`$${angleCAB}^{\\circ}$`, 55, 20);
      myImage.addOverlay(`$${angleABC}^{\\circ}$`, 77, 30);

      myImage.addOverlay(`$A$`, 50, -2);
      myImage.addOverlay(`$B$`, 103, 30);
      myImage.addOverlay(`$C$`, -5, 100);

      question.addParagraph(
        "question",
        `A triangular region is modelled by the triangle $ABC$. The length $AC$ is $${sideAC}m$.`
      );
      question.addImage("question", myImage);
      question.addParagraph(
        "question",
        `Given that angle $CAB$ is $${angleCAB}^{\\circ}$ and angle $ABC$ is $${angleABC}^{\\circ}$,`
      );
      question.addParagraph(
        "question",
        `$a)$ Calculate the area of the triangular region.`
      );
      question.addParagraph(
        "question",
        `$b)$ Calculate the length of the side $CB$.`
      );

      question.addParagraph(
        "solution",
        `$a)$ $(AB) = \\sin{(${angleACB}^{\\circ})}\\times \\frac{${sideAC}}{\\sin{(${angleABC}^{\\circ})}}$`
      );
      question.addParagraph("solution", `$ = ${sideAB.toPrecision(3)}$`);
      question.addParagraph(
        "solution",
        `Area $\\displaystyle = \\frac{1}{2}(${sideAC.toPrecision(
          3
        )})(${sideAB.toPrecision(3)})\\sin{(${angleCAB}^{\\circ})}$`
      );
      question.addParagraph("solution", `$ = ${answer.toPrecision(3)}m^{2}$`);

      question.addParagraph(
        "solution",
        `$b)$ $CB = \\sqrt{${sideAC}^{2} + ${sideAB.toPrecision(
          3
        )}^{2} - 2(${sideAC})(${sideAB.toPrecision(
          3
        )})\\cos{(${angleCAB.toPrecision(3)}^{\\circ})}}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore CB = ${sideBC.toPrecision(3)}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(answer.toPrecision(3)),
        Math.abs(
          parseFloat(answer.toPrecision(3)) - parseFloat(answer.toPrecision(2))
        ),
        `$a) $`,
        "before"
      );
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(sideBC.toPrecision(3)),
        Math.abs(
          parseFloat(sideBC.toPrecision(3)) - parseFloat(sideBC.toPrecision(2))
        ),
        `$b) $`,
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_Trigonometry_RulesOfTrigonometry_Exam_Q2() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const sideBC = MCRandom.randomInt(2, 9);
    const sideAC = MCRandom.randomInt(2, 9);
    const area = MCRandom.randomInt(10, 20);

    const checkMath = (2 * area) / (sideBC * sideAC);
    const angleThetaRadians = Math.asin(checkMath);
    const angleTheta = angleThetaRadians * (180 / Math.PI);

    const sideBA_Squared =
      sideAC ** 2 +
      sideBC ** 2 -
      2 * sideAC * sideBC * Math.cos(angleThetaRadians);
    const sideBA = Math.sqrt(sideBA_Squared);

    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(checkMath) <= 1 && sideBA_Squared > 0 && angleTheta !== 90) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Given a triangle $ABC$, side $BC$ has length $${sideBC}m$, side $AC$ has length $${sideAC}m$ and the area of the triangle is $${area.toPrecision(
          2
        )}m^2$.`
      );
      question.addParagraph("question", `Angle $ACB = \\theta^{\\circ}$.`);
      question.addParagraph(
        "question",
        `$a)$ Find the $2$ possible values of $\\theta$.`
      );
      question.addParagraph(
        "question",
        `$b)$ Given that $\\theta$ is an acute angle, find the length of $BA$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `$a)$ $\\displaystyle ${area.toPrecision(
          2
        )} = \\frac{1}{2}(${sideBC})(${sideAC})\\sin{(\\theta)}$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = \\sin^{-1}{\\left(\\frac{2\\times${area}}{${sideBC}\\times${sideAC}}\\right)} = ${angleTheta.toPrecision(
          3
        )}^{\\circ}, ${(180 - angleTheta).toPrecision(3)}^{\\circ}$`
      );

      question.addParagraph(
        "solution",
        `$b)$ $ ({BA})^2 = ${sideBC}^{2} + ${sideAC}^{2} - 2(${sideBC})(${sideAC})\\cos{(${angleTheta.toPrecision(
          3
        )})} = ${sideBA_Squared.toPrecision(3)}$`
      );
      question.addParagraph("solution", `$BA = ${sideBA.toPrecision(3)}m$`);

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        parseFloat(angleTheta.toPrecision(3)),
        Math.abs(
          parseFloat(angleTheta.toPrecision(3)) -
            parseFloat(angleTheta.toPrecision(2))
        ),
        `$a) =$`
      );
      group1.addInput(
        parseFloat((180 - angleTheta).toPrecision(3)),
        Math.abs(
          parseFloat((180 - angleTheta).toPrecision(3)) -
            parseFloat((180 - angleTheta).toPrecision(2))
        ),
        `$a) =$`
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(sideBA.toPrecision(3)),
        Math.abs(
          parseFloat(sideBA.toPrecision(3)) - parseFloat(sideBA.toPrecision(2))
        ),
        `$b)$`,
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_Trigonometry_RulesOfTrigonometry_Exam_Q3() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const areaMultiplier = new MCMaths.Fraction(
      1 + MCRandom.randomInt(1, 6) / 10
    );
    const sideAB = MCRandom.randomInt(2, 9);

    const thetaRadians = Math.acos(1 / areaMultiplier.toFloat());
    const theta = thetaRadians * (180 / Math.PI);

    const areaABD = 0.5 * sideAB * sideAB * Math.sin(thetaRadians);
    const areaABC = 0.5 * sideAB * sideAB * Math.tan(thetaRadians);
    const areaCBD = areaABC - areaABD;

    const working1 = new MCMaths.Fraction(sideAB * sideAB * 0.5).toString();
    const working2 = new MCMaths.Fraction(
      1 / areaMultiplier.toFloat()
    ).toString();

    /* END VARIABLES AND CONSTANTS */
    if (theta > 0 && theta < 90 && areaCBD > 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const myImage = new MCQuestion.Image("Matt/triangle2.svg", "width", 15);

      question.addParagraph(
        "question",
        `The triangle $ABC$ is a right angled triangle.`
      );
      question.addParagraph(
        "question",
        `Angle $AOB = \\theta^{\\circ}, 0^{\\circ} < \\theta < 90^{\\circ}$.`
      );

      myImage.addOverlay(`$\\theta$`, 86, 17);
      myImage.addOverlay(`$A$`, 90, 0);
      myImage.addOverlay(`$B$`, 90, 100);
      myImage.addOverlay(`$C$`, 2, 100);
      question.addImage("question", myImage);

      question.addParagraph(
        "question",
        `The point $D$ lies on the hypotenuse such that $AD = AB = ${sideAB}cm$.`
      );
      question.addParagraph(
        "question",
        `The area of the triangle $ABC = ${areaMultiplier.toString()}ABD.$`
      );
      question.addParagraph("question", `$a)$ Find the value of $\\theta$.`);
      question.addParagraph(
        "question",
        `$b)$ Find the area of the triangle $CBD$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `$a)$ $BC = ${sideAB}\\tan{(\\theta)}$.`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle ABC = \\frac{1}{2}(${sideAB})(${sideAB}\\tan{(\\theta)}) = {${working1}}\\tan{(\\theta)}$.`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle ABD = \\frac{1}{2}(${sideAB})(${sideAB})\\sin{(\\theta)} = {${working1}}\\sin{(\\theta)}$.`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle ABC = ${areaMultiplier.toString()}ABD \\implies {${working1}}\\tan{(\\theta)} = ${areaMultiplier.toString()}\\left({${working1}}\\sin{(\\theta)}\\right)$.`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\therefore \\tan{(\\theta)} = ${areaMultiplier.toString()}\\sin{(\\theta)}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\implies \\sin{(\\theta)}\\left(${areaMultiplier.toString()}\\cos{(\\theta)} - 1\\right) = 0$`
      );
      question.addParagraph(
        "solution",
        `Since $\\displaystyle \\theta \\neq 0 \\implies \\theta = \\cos^{-1}{(${working2})} = ${theta.toPrecision(
          3
        )}^{\\circ}$`
      );

      question.addParagraph("solution", `$b)$ $CBD = ABC - ABD$`);
      question.addParagraph(
        "solution",
        `$\\therefore CBD = {${working1}}\\tan{(${theta.toPrecision(
          3
        )}^{\\circ})} - {${working1}}\\sin{(${theta.toPrecision(
          3
        )}^{\\circ})} = ${areaCBD.toPrecision(3)}cm^{2}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(theta.toPrecision(3)),
        Math.abs(
          parseFloat(theta.toPrecision(3)) - parseFloat(theta.toPrecision(2))
        ),
        `$a)$`,
        "before"
      );
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        parseFloat(areaCBD.toPrecision(3)),
        Math.abs(
          parseFloat(areaCBD.toPrecision(3)) -
            parseFloat(areaCBD.toPrecision(2))
        ),
        `$b)$`,
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_Trigonometry_RulesOfTrigonometry_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    // const sidePQ = MCRandom.randomInt(2,15);
    const sideQR = MCRandom.randomInt(2, 15);
    const sideRP = MCRandom.randomInt(2, 15);
    const theta = MCRandom.randomInt(30, 70);
    const thetaRadians = theta * (Math.PI / 180);

    const sidePQSquared =
      sideQR ** 2 + sideRP ** 2 - 2 * sideQR * sideRP * Math.cos(thetaRadians);
    const sidePQ = Math.sqrt(sidePQSquared);
    const area = 0.5 * sideRP * sideQR * Math.sin(thetaRadians);

    /* END VARIABLES AND CONSTANTS */
    if (sidePQ !== sideQR) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A triangle $PQR$ has sides $PQ$, $QR$, $RP$ with lengths $${sidePQ.toPrecision(
          3
        )}cm$, $${sideQR}cm$ and $${sideRP}cm$ respectively.`
      );
      question.addParagraph(
        "question",
        `Let the angle $PRQ = \\theta^{\\circ}, 0^{\\circ} < \\theta < 90^{\\circ}$.`
      );
      question.addParagraph("question", `$a)$ Find the value of $\\theta$.`);
      question.addParagraph(
        "question",
        `$b)$ Hence, or otherwise, find the area of the triangle $PQR$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `$a)$ Using $c^2 = a^2 + b^2 -2bc\\cos{(A)}$`
      );
      question.addParagraph(
        "solution",
        `$\\cos{(\\theta)} = \\frac{(${sideRP}^{2} + ${sideQR}^{2} - ${sidePQ.toPrecision(
          3
        )}^{2})}{2(${sideRP})(${sideQR})}$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = \\cos^{-1}{\\left(\\frac{(${sideRP}^{2} + ${sideQR}^{2} - ${sidePQ.toPrecision(
          3
        )}^{2})}{2(${sideRP})(${sideQR})}\\right)}$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${theta.toPrecision(3)}^{\\circ}$`
      );

      question.addParagraph(
        "solution",
        `$b)$ Area $\\displaystyle = \\frac{1}{2}(${sideQR})(${sideRP}\\sin{(${theta})})$`
      );
      question.addParagraph("solution", `Area $= ${area.toPrecision(3)}cm^2$`);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(theta.toPrecision(3)),
        Math.abs(
          parseFloat(theta.toPrecision(3)) - parseFloat(theta.toPrecision(2))
        ),
        `$a)$`,
        "before"
      );
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        parseFloat(area.toPrecision(3)),
        Math.abs(
          parseFloat(area.toPrecision(3)) - parseFloat(area.toPrecision(2))
        ),
        `$b)$`,
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_RulesOfTrigonometry_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const theta = MCRandom.randomInt(30, 80);
    const thetaRadians = theta * (Math.PI / 180);
    const sideOA = MCRandom.randomInt(5, 15);
    const sideOB = sideOA;
    const areaOAB = 0.5 * sideOA * sideOB * Math.sin(thetaRadians);
    const sectorPerimeter = (theta / 360) * (2 * Math.PI * sideOA);

    /* END VARIABLES AND CONSTANTS */
    if (theta !== 60 && theta !== 45) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const myImage = new MCQuestion.Image("Matt/sector1.svg", "width", 15);

      question.addParagraph(
        "question",
        `The triangle $OAB$ is a non-equilateral triangle.`
      );
      question.addParagraph(
        "question",
        `The area of the triangle $OAB$ is $${areaOAB.toPrecision(3)}m^2$.`
      );
      question.addParagraph("question", `Side $OA = ${sideOA}m$.`);
      question.addParagraph("question", `Side $OB = ${sideOB}m$.`);
      question.addParagraph(
        "question",
        `Angle $AOB = \\theta^{\\circ}, 0^{\\circ} < \\theta < 90^{\\circ}$.`
      );

      myImage.addOverlay(`$\\theta$`, 49.5, 80);
      myImage.addOverlay(`$O$`, 49.5, 95);
      myImage.addOverlay(`$A$`, 0, 22);
      myImage.addOverlay(`$B$`, 97, 22);
      question.addImage("question", myImage);

      question.addParagraph("question", `$a)$ Find the angle $\\theta$.`);
      question.addParagraph(
        "question",
        `$b)$ Hence, or otherwise, find the perimeter of the sector.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ to significant figures.`
      );

      question.addParagraph(
        "solution",
        `$a)$ $${areaOAB.toPrecision(
          3
        )} = \\frac{1}{2}(${sideOA})(${sideOB})\\sin{(\\theta)}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = \\sin^{-1}{\\left(\\frac{2 \\times ${areaOAB.toPrecision(
          3
        )}}{${sideOA * sideOB}}\\right)}$`
      );
      question.addParagraph("solution", `$\\theta = ${theta}^{\\circ}$`);
      question.addParagraph(
        "solution",
        `$b)$ Since the perimeter of a sector $= \\left(\\frac{\\theta}{360}\\right)\\times(2\\pi R)$`
      );
      question.addParagraph(
        "solution",
        `Perimeter of sector $= \\left(\\frac{${theta}}{360}\\right)\\times(2\\pi \\times ${sideOA})$`
      );
      question.addParagraph(
        "solution",
        `$= ${sectorPerimeter.toPrecision(3)}m$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(theta.toPrecision(3)),
        Math.abs(
          parseFloat(theta.toPrecision(3)) - parseFloat(theta.toPrecision(2))
        ),
        `$a)$`,
        "before"
      );
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        parseFloat(sectorPerimeter.toPrecision(3)),
        Math.abs(
          parseFloat(sectorPerimeter.toPrecision(3)) -
            parseFloat(sectorPerimeter.toPrecision(2))
        ),
        `$b)$`,
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_RulesOfTrigonometry_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const theta = MCRandom.randomInt(30, 80);
    const thetaRadians = theta * (Math.PI / 180);
    const sideOC = MCRandom.randomInt(5, 15);
    const sideOA = sideOC / 2;
    const sectorArea = (theta / 360) * (Math.PI * sideOC ** 2);
    const areaOAC = 0.5 * sideOC * sideOA * Math.sin(thetaRadians);
    const shadedArea = sectorArea - areaOAC;

    /* END VARIABLES AND CONSTANTS */
    if (theta !== 60 && theta !== 45) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const myImage = new MCQuestion.Image("Matt/sector2.svg", "width", 20);

      question.addParagraph(
        "question",
        `The sector $OBC$ is drawn such that point $A$ is exactly halfway between $O$ and $B$`
      );
      question.addParagraph("question", `Side $OC = ${sideOC}m$.`);
      question.addParagraph(
        "question",
        `Angle $AOC = \\theta^{\\circ}, 0^{\\circ} < \\theta < 90^{\\circ}$.`
      );

      myImage.addOverlay(`$\\theta$`, 15, 72);
      myImage.addOverlay(`$O$`, 0, 80);
      myImage.addOverlay(`$A$`, 30, 43);
      myImage.addOverlay(`$B$`, 62, 13);
      myImage.addOverlay(`$C$`, 97, 80);
      question.addImage("question", myImage);

      question.addParagraph(
        "question",
        `Given that the area of the sector is $${sectorArea.toPrecision(
          3
        )}m^2$,`
      );
      question.addParagraph("question", `$a)$ Find the angle $\\theta$.`);
      question.addParagraph(
        "question",
        `$b)$ Hence, or otherwise, find the area of the shaded region.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $2$ to significant figures.`
      );

      question.addParagraph(
        "solution",
        `$a)$ Since the area of a sector $= \\left(\\frac{\\theta}{360}\\right)\\times\\left(\\pi R^2\\right)$`
      );
      question.addParagraph(
        "solution",
        `$ ${sectorArea.toPrecision(
          3
        )} = \\left(\\frac{\\theta}{360}\\right)\\times\\left(\\pi \\times ${sideOC}^2\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = 360 \\left(\\frac{${sectorArea.toPrecision(
          3
        )}}{\\left(\\pi \\times ${sideOC}^2\\right)}\\right)$`
      );
      question.addParagraph("solution", `$\\theta = ${theta}^{\\circ}$`);

      question.addParagraph(
        "solution",
        `$b)$ Area of $OAC = \\frac{1}{2}(${sideOA})(${sideOC})\\sin{(${theta}^{\\circ})}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Shaded region $= ${sectorArea.toPrecision(
          3
        )} - \\frac{1}{2}(${sideOA})(${sideOC})\\sin{(${theta}^{\\circ})}$`
      );
      question.addParagraph("solution", `$= ${shadedArea.toPrecision(2)}m^2$`);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(theta.toPrecision(2)),
        Math.abs(
          parseFloat(theta.toPrecision(3)) - parseFloat(theta.toPrecision(2))
        ),
        `$a)$`,
        "before"
      );
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(shadedArea.toPrecision(2)),
        Math.abs(
          parseFloat(shadedArea.toPrecision(2)) -
            parseFloat(shadedArea.toPrecision(1))
        ),
        `$b)$`,
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_RulesOfTrigonometry_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const answer1Opp = MCRandom.randomInt(2, 36);
    const answer1Hyp = MCRandom.randomInt(2, 36);

    const answer2Opp = MCRandom.randomInt(2, 36);
    const answer2Hyp = MCRandom.randomInt(2, 36);

    const answer3Opp = MCRandom.randomInt(2, 36);
    const answer3Hyp = MCRandom.randomInt(2, 36);

    const answer4Opp = MCRandom.randomInt(2, 36);
    const answer4Hyp = MCRandom.randomInt(2, 36);

    /* END VARIABLES AND CONSTANTS */
    if (
      answer1Opp / answer1Hyp === answer2Opp / answer2Hyp &&
      answer2Opp / answer2Hyp === answer3Opp / answer3Hyp &&
      answer1Opp / answer1Hyp !== 1 &&
      answer1Hyp > answer1Opp &&
      answer2Hyp > answer2Opp &&
      answer3Hyp > answer3Opp &&
      answer4Hyp > answer4Opp &&
      answer1Opp + answer1Hyp !== answer2Opp + answer2Hyp &&
      answer1Opp + answer1Hyp !== answer4Opp + answer4Hyp &&
      answer3Opp + answer3Hyp !== answer4Opp + answer4Hyp &&
      answer2Opp + answer2Hyp !== answer3Opp + answer3Hyp
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        "Select all triangles that have an equal value of $\\sin(\\theta)$."
      );

      const myImage1 = new MCQuestion.Image(
        "Matt/rightAngleTriangle1.svg",
        "width",
        20
      );
      myImage1.addOverlay(`$\\theta$`, 16, 84);
      myImage1.addOverlay(`$${answer1Opp}$`, 95, 50, 2);
      myImage1.addOverlay(`$${answer1Hyp}$`, 43, 43, 2);

      const myImage2 = new MCQuestion.Image(
        "Matt/rightAngleTriangle2.svg",
        "width",
        20
      );
      myImage2.addOverlay(`$\\theta$`, 82, 17);
      myImage2.addOverlay(`$${answer2Opp}$`, 2, 45, 2);
      myImage2.addOverlay(`$${answer2Hyp}$`, 55, 55, 2);

      const myImage3 = new MCQuestion.Image(
        "Matt/rightAngleTriangle3.svg",
        "width",
        20
      );
      myImage3.addOverlay(`$\\theta$`, 84, 82);
      myImage3.addOverlay(`$${answer3Opp}$`, 50, 2, 2);
      myImage3.addOverlay(`$${answer3Hyp}$`, 45, 55, 2);

      const myImage4 = new MCQuestion.Image(
        "Matt/rightAngleTriangle4.svg",
        "width",
        20
      );
      myImage4.addOverlay(`$\\theta$`, 88, 22);
      myImage4.addOverlay(`$${answer4Opp}$`, 55, 95, 2);
      myImage4.addOverlay(`$${answer4Hyp}$`, 45, 45, 2);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addImage(myImage1);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addImage(myImage2);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addImage(myImage3);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addImage(myImage4);

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, true);
      question.addAnswer(answer3, true);
      question.addAnswer(answer4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_RulesOfTrigonometry_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const answer1SideA = MCRandom.randomInt(2, 36);
    const answer1SideB = MCRandom.randomInt(2, 36);
    const answer1Theta = MCRandom.randomInt(30, 80);
    const answer1Area =
      0.5 *
      answer1SideA *
      answer1SideB *
      Math.sin(answer1Theta * (Math.PI / 180));

    const answer2SideA = MCRandom.randomInt(2, 36);
    const answer2SideB = MCRandom.randomInt(2, 36);
    const answer2Theta = MCRandom.randomInt(30, 80);
    const answer2Area =
      0.5 *
      answer2SideA *
      answer2SideB *
      Math.sin(answer2Theta * (Math.PI / 180));

    const answer3SideA = MCRandom.randomInt(2, 36);
    const answer3SideB = MCRandom.randomInt(2, 36);
    const answer3Theta = MCRandom.randomInt(100, 160);
    const answer3Area =
      0.5 *
      answer3SideA *
      answer3SideB *
      Math.sin(answer3Theta * (Math.PI / 180));

    const answer4SideA = MCRandom.randomInt(2, 36);
    const answer4SideB = MCRandom.randomInt(2, 36);
    const answer4Theta = MCRandom.randomInt(30, 80);
    const answer4Area =
      0.5 *
      answer4SideA *
      answer4SideB *
      Math.sin(answer4Theta * (Math.PI / 180));

    const areaArray = [answer1Area, answer2Area, answer3Area, answer4Area];
    const minValue = Math.min.apply(null, areaArray);
    const newMinValue = Math.ceil(minValue / 10) * 10;

    let answer1Bool = false;
    if (answer1Area > newMinValue) {
      answer1Bool = true;
    }

    let answer2Bool = false;
    if (answer2Area > newMinValue) {
      answer2Bool = true;
    }

    let answer3Bool = false;
    if (answer3Area > newMinValue) {
      answer3Bool = true;
    }

    let answer4Bool = false;
    if (answer4Area > newMinValue) {
      answer4Bool = true;
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      answer1SideA + answer1SideB !== answer2SideA + answer2SideB &&
      answer1SideA + answer1SideB !== answer4SideA + answer4SideB &&
      answer3SideA + answer3SideB !== answer4SideA + answer4SideB &&
      answer2SideA + answer2SideB !== answer3SideA + answer3SideB &&
      answer3Area > newMinValue
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select all triangles with area greater than $${newMinValue}$.`
      );

      const myImage1 = new MCQuestion.Image(
        "Matt/irregularTriangle1.svg",
        "width",
        20
      );
      myImage1.addOverlay(`$${answer1Theta}^{\\circ}$`, 12, 60);
      myImage1.addOverlay(`$${answer1SideA}$`, 30, 25, 2);
      myImage1.addOverlay(`$${answer1SideB}$`, 43, 85, 2);

      const myImage2 = new MCQuestion.Image(
        "Matt/irregularTriangle2.svg",
        "width",
        20
      );
      myImage2.addOverlay(`$${answer2Theta}^{\\circ}$`, 25, 64);
      myImage2.addOverlay(`$${answer2SideA}$`, 30, 30, 2);
      myImage2.addOverlay(`$${answer2SideB}$`, 45, 87, 2);

      const myImage3 = new MCQuestion.Image(
        "Matt/irregularTriangle3.svg",
        "width",
        20
      );
      myImage3.addOverlay(`$${answer3Theta}^{\\circ}$`, 77, 50);
      myImage3.addOverlay(`$${answer3SideA}$`, 50, 80, 2);
      myImage3.addOverlay(`$${answer3SideB}$`, 95, 40, 2);

      const myImage4 = new MCQuestion.Image(
        "Matt/irregularTriangle4.svg",
        "width",
        20
      );
      myImage4.addOverlay(`$${answer4Theta}^{\\circ}$`, 78, 55);
      myImage4.addOverlay(`$${answer4SideA}$`, 55, 20, 2);
      myImage4.addOverlay(`$${answer4SideB}$`, 65, 85, 2);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addImage(myImage1);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addImage(myImage2);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addImage(myImage3);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addImage(myImage4);

      question.addAnswer(answer1, answer1Bool);
      question.addAnswer(answer2, answer2Bool);
      question.addAnswer(answer3, answer3Bool);
      question.addAnswer(answer4, answer4Bool);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_RulesOfTrigonometry_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const answer1SideA = MCRandom.randomInt(2, 36);
    const answer1SideB = MCRandom.randomInt(2, 36);
    const answer1Theta = MCRandom.randomInt(30, 80);
    const answer1LongestSide = Math.sqrt(
      answer1SideA ** 2 +
        answer1SideB ** 2 -
        2 *
          answer1SideA *
          answer1SideB *
          Math.cos(answer1Theta * (Math.PI / 180))
    );

    const answer2SideA = MCRandom.randomInt(2, 36);
    const answer2SideB = MCRandom.randomInt(2, 36);
    const answer2Theta = MCRandom.randomInt(30, 80);
    const answer2LongestSide = Math.sqrt(
      answer2SideA ** 2 +
        answer2SideB ** 2 -
        2 *
          answer2SideA *
          answer2SideB *
          Math.cos(answer2Theta * (Math.PI / 180))
    );

    const answer3SideA = MCRandom.randomInt(2, 36);
    const answer3SideB = MCRandom.randomInt(2, 36);
    const answer3Theta = MCRandom.randomInt(100, 160);
    const answer3LongestSide = Math.sqrt(
      answer3SideA ** 2 +
        answer3SideB ** 2 -
        2 *
          answer3SideA *
          answer3SideB *
          Math.cos(answer3Theta * (Math.PI / 180))
    );

    const answer4SideA = MCRandom.randomInt(2, 36);
    const answer4SideB = MCRandom.randomInt(2, 36);
    const answer4Theta = MCRandom.randomInt(30, 80);
    const answer4LongestSide = Math.sqrt(
      answer4SideA ** 2 +
        answer4SideB ** 2 -
        2 *
          answer4SideA *
          answer4SideB *
          Math.cos(answer4Theta * (Math.PI / 180))
    );

    const longestSideArray = [
      answer1LongestSide,
      answer2LongestSide,
      answer3LongestSide,
      answer4LongestSide,
    ];
    const minValue = Math.min.apply(null, longestSideArray);
    const newMinValue = Math.ceil(minValue / 10) * 10;

    let answer1Bool = false;
    if (answer1LongestSide > newMinValue) {
      answer1Bool = true;
    }

    let answer2Bool = false;
    if (answer2LongestSide > newMinValue) {
      answer2Bool = true;
    }

    let answer3Bool = false;
    if (answer3LongestSide > newMinValue) {
      answer3Bool = true;
    }

    let answer4Bool = false;
    if (answer4LongestSide > newMinValue) {
      answer4Bool = true;
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      answer1SideA + answer1SideB !== answer2SideA + answer2SideB &&
      answer1SideA + answer1SideB !== answer4SideA + answer4SideB &&
      answer3SideA + answer3SideB !== answer4SideA + answer4SideB &&
      answer2SideA + answer2SideB !== answer3SideA + answer3SideB &&
      answer3LongestSide > newMinValue
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select all triangles with their longest side being greater than $${newMinValue}$.`
      );

      const myImage1 = new MCQuestion.Image(
        "Matt/irregularTriangle1.svg",
        "width",
        20
      );
      myImage1.addOverlay(`$${answer1Theta}^{\\circ}$`, 12, 60);
      myImage1.addOverlay(`$${answer1SideA}$`, 30, 25, 2);
      myImage1.addOverlay(`$${answer1SideB}$`, 43, 85, 2);

      const myImage2 = new MCQuestion.Image(
        "Matt/irregularTriangle2.svg",
        "width",
        20
      );
      myImage2.addOverlay(`$${answer2Theta}^{\\circ}$`, 25, 64);
      myImage2.addOverlay(`$${answer2SideA}$`, 30, 30, 2);
      myImage2.addOverlay(`$${answer2SideB}$`, 45, 87, 2);

      const myImage3 = new MCQuestion.Image(
        "Matt/irregularTriangle3.svg",
        "width",
        20
      );
      myImage3.addOverlay(`$${answer3Theta}^{\\circ}$`, 77, 50);
      myImage3.addOverlay(`$${answer3SideA}$`, 50, 80, 2);
      myImage3.addOverlay(`$${answer3SideB}$`, 95, 40, 2);

      const myImage4 = new MCQuestion.Image(
        "Matt/irregularTriangle4.svg",
        "width",
        20
      );
      myImage4.addOverlay(`$${answer4Theta}^{\\circ}$`, 78, 55);
      myImage4.addOverlay(`$${answer4SideA}$`, 55, 20, 2);
      myImage4.addOverlay(`$${answer4SideB}$`, 65, 85, 2);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addImage(myImage1);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addImage(myImage2);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addImage(myImage3);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addImage(myImage4);

      question.addAnswer(answer1, answer1Bool);
      question.addAnswer(answer2, answer2Bool);
      question.addAnswer(answer3, answer3Bool);
      question.addAnswer(answer4, answer4Bool);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_RulesOfTrigonometry_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const answer1Opp = MCRandom.randomInt(2, 36);
    const answer1Theta = MCRandom.randomInt(30, 80);
    const answer1Hyp = answer1Opp / Math.sin(answer1Theta * (Math.PI / 180));

    const answer2Opp = MCRandom.randomInt(2, 36);
    const answer2Theta = MCRandom.randomInt(30, 80);
    const answer2Hyp = answer2Opp / Math.sin(answer2Theta * (Math.PI / 180));

    const answer3Opp = MCRandom.randomInt(2, 36);
    const answer3Theta = MCRandom.randomInt(30, 80);
    const answer3Hyp = answer3Opp / Math.sin(answer3Theta * (Math.PI / 180));

    const answer4Opp = MCRandom.randomInt(2, 36);
    const answer4Theta = MCRandom.randomInt(30, 80);
    const answer4Hyp = answer4Opp / Math.sin(answer4Theta * (Math.PI / 180));

    const hypArray = [answer1Hyp, answer2Hyp, answer3Hyp, answer4Hyp];
    const minValue = Math.min.apply(null, hypArray);
    const newMinValue = Math.ceil(minValue / 10) * 10;

    let answer1Bool = false;
    if (answer1Hyp > newMinValue) {
      answer1Bool = true;
    }

    let answer2Bool = false;
    if (answer2Hyp > newMinValue) {
      answer2Bool = true;
    }

    let answer3Bool = false;
    if (answer3Hyp > newMinValue) {
      answer3Bool = true;
    }

    let answer4Bool = false;
    if (answer4Hyp > newMinValue) {
      answer4Bool = true;
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      answer1Opp / answer1Hyp === answer2Opp / answer2Hyp &&
      answer2Opp / answer2Hyp === answer3Opp / answer3Hyp &&
      answer1Opp / answer1Hyp !== 1 &&
      answer1Hyp > answer1Opp &&
      answer2Hyp > answer2Opp &&
      answer3Hyp > answer3Opp &&
      answer4Hyp > answer4Opp &&
      answer1Opp + answer1Hyp !== answer2Opp + answer2Hyp &&
      answer1Opp + answer1Hyp !== answer4Opp + answer4Hyp &&
      answer3Opp + answer3Hyp !== answer4Opp + answer4Hyp &&
      answer2Opp + answer2Hyp !== answer3Opp + answer3Hyp
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(`Select all triangles where $x > ${newMinValue}$.`);

      const myImage1 = new MCQuestion.Image(
        "Matt/rightAngleTriangle1.svg",
        "width",
        20
      );
      myImage1.addOverlay(`$${answer1Theta}^{\\circ}$`, 16, 84);
      myImage1.addOverlay(`$${answer1Opp}$`, 95, 50, 2);
      myImage1.addOverlay(`$x$`, 43, 43, 2);

      const myImage2 = new MCQuestion.Image(
        "Matt/rightAngleTriangle2.svg",
        "width",
        20
      );
      myImage2.addOverlay(`$${answer2Theta}^{\\circ}$`, 82, 17);
      myImage2.addOverlay(`$${answer2Opp}$`, 2, 45, 2);
      myImage2.addOverlay(`$x$`, 55, 55, 2);

      const myImage3 = new MCQuestion.Image(
        "Matt/rightAngleTriangle3.svg",
        "width",
        20
      );
      myImage3.addOverlay(`$${answer3Theta}^{\\circ}$`, 84, 82);
      myImage3.addOverlay(`$${answer3Opp}$`, 50, 2, 2);
      myImage3.addOverlay(`$x$`, 45, 55, 2);

      const myImage4 = new MCQuestion.Image(
        "Matt/rightAngleTriangle4.svg",
        "width",
        20
      );
      myImage4.addOverlay(`$${answer4Theta}^{\\circ}$`, 88, 22);
      myImage4.addOverlay(`$${answer4Opp}$`, 55, 95, 2);
      myImage4.addOverlay(`$x$`, 45, 45, 2);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addImage(myImage1);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addImage(myImage2);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addImage(myImage3);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addImage(myImage4);

      question.addAnswer(answer1, answer1Bool);
      question.addAnswer(answer2, answer2Bool);
      question.addAnswer(answer3, answer3Bool);
      question.addAnswer(answer4, answer4Bool);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricEquations_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(10, 30);
    const gamma = MCRandom.randomInt(2, 9);

    const thetaRadians1 = Math.asin(gamma / alpha) + beta * (Math.PI / 180);
    const thetaRadians2 = Math.PI - thetaRadians1;
    const thetaDegrees1 = thetaRadians1 * (180 / Math.PI);
    const thetaDegrees2 = thetaRadians2 * (180 / Math.PI);

    const sinTheta = Math.sin(thetaRadians1);

    /* END VARIABLES AND CONSTANTS */
    if (gamma / alpha < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `a) Solve the following equation:`);
      question.addParagraph(
        "question",
        `$${alpha}\\sin(\\theta - ${beta}^{\\circ}) - ${gamma} = 0, \\text{ } 0^{\\circ} < \\theta < 180^{\\circ}$`
      );
      question.addParagraph(
        "question",
        `b) Hence find the value of $\\sin(\\theta)$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $${alpha}\\sin(\\theta - ${beta}^{\\circ}) - ${gamma} = 0$`
      );
      question.addParagraph(
        "solution",
        `$${alpha}\\sin(\\theta - ${beta}^{\\circ})  = ${gamma}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\sin(\\theta - ${beta}^{\\circ})  = ${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta - ${beta}^{\\circ}  = \\sin^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\sin^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right) + ${beta}^{\\circ} $`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaDegrees1.toPrecision(3)}^{\\circ} $`
      );
      question.addParagraph(
        "solution",
        `Also, $\\theta = 180 - ${thetaDegrees1.toPrecision(3)} $`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaDegrees2.toPrecision(3)}^{\\circ}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = ${thetaDegrees1.toPrecision(
          3
        )}^{\\circ}, \\text{ } ${thetaDegrees2.toPrecision(3)}^{\\circ} $`
      );

      question.addParagraph(
        "solution",
        `b) $\\sin(\\theta) = \\sin(\\sin^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right) + ${beta}^{\\circ} ) $`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sin(\\theta) = ${sinTheta.toPrecision(3)} $`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(thetaDegrees1.toPrecision(3)),
          parseFloat(thetaDegrees2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(thetaDegrees1.toPrecision(3)) -
            parseFloat(thetaDegrees1.toPrecision(2))
        ),
        "a) $\\text{ } \\theta = $"
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(sinTheta.toPrecision(3)),
        Math.abs(
          parseFloat(sinTheta.toPrecision(3)) -
            parseFloat(sinTheta.toPrecision(2))
        ),
        "b) $\\text{ } \\sin(\\theta) = $"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricEquations_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(2, 6);
    const gamma = MCRandom.randomInt(2, 9);
    const tanCoeff = new MCMaths.Fraction(alpha / gamma);

    const thetaRadians1 = (1 / beta) * Math.acos(tanCoeff.toFloat());
    const thetaRadians2 = -thetaRadians1;
    const thetaDegrees1 = thetaRadians1 * (180 / Math.PI);
    const thetaDegrees2 = thetaRadians2 * (180 / Math.PI);

    /* END VARIABLES AND CONSTANTS */
    if (tanCoeff.toFloat() < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given the following equation:`);
      question.addParagraph(
        "question",
        `$\\displaystyle ${tanCoeff.toString()}\\tan(${beta}\\theta) = \\sin(${beta}\\theta), \\text{ } -90^{\\circ} < \\theta < 90^{\\circ}$`
      );
      question.addParagraph(
        "question",
        `a) Find the value of $\\sin(${beta}\\theta)$:`
      );
      question.addParagraph(
        "question",
        `b) Hence find the value of $\\theta$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $\\displaystyle ${tanCoeff.toString()}\\tan(${beta}\\theta) = \\sin(${beta}\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{${tanCoeff.numerator}\\sin(${beta}\\theta)}{${tanCoeff.denominator}\\cos(${beta}\\theta)} = \\sin(${beta}\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$${tanCoeff.numerator}\\sin(${beta}\\theta) = ${tanCoeff.denominator}\\sin(${beta}\\theta)\\cos(${beta}\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$\\sin(${beta}\\theta)(${alpha} - ${tanCoeff.denominator}\\cos(${beta}\\theta)) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\sin(${beta}\\theta) = 0, \\text{ } ${alpha} - ${tanCoeff.denominator}\\cos(${beta}\\theta) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sin(${beta}\\theta) = 0$.`
      );
      question.addParagraph(
        "solution",
        `b) $${tanCoeff.numerator} - ${tanCoeff.denominator}\\cos(${beta}\\theta) = 0$`
      );
      question.addParagraph(
        "solution",
        `$${tanCoeff.denominator}\\cos(${beta}\\theta) = ${tanCoeff.denominator}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos(${beta}\\theta) = ${tanCoeff.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle ${beta}\\theta = \\cos^{-1}\\left(${tanCoeff.toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\frac{1}{${beta}}\\cos^{-1}\\left(${tanCoeff.toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaDegrees1.toPrecision(3)}^{\\circ}$`
      );
      question.addParagraph(
        "solution",
        `Also, $\\theta = -${thetaDegrees1.toPrecision(3)}^{\\circ}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(0, 0, `a) $\\text{ } \\sin(${beta}\\theta) = $`);

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        [
          parseFloat(thetaDegrees1.toPrecision(3)),
          parseFloat(thetaDegrees2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(thetaDegrees1.toPrecision(3)) -
            parseFloat(thetaDegrees1.toPrecision(2))
        ),
        "b) $\\text{ } \\theta = $"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricEquations_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const cosCoefficient_1 = MCRandom.randomInt(-7, 7);
    const constantTerm_1 = MCRandom.randomInt(-7, 7);
    const cosCoefficient_2 = cosCoefficient_1;
    const constantTerm_2 = -constantTerm_1;

    const sinCoefficient = MCRandom.randomInt(2, 9);
    const epsilon = MCRandom.randomInt(2, 9);

    const p1 = new MCMaths.Polynomial(
      [cosCoefficient_1, constantTerm_1],
      "\\cos(x)"
    );
    const p2 = new MCMaths.Polynomial(
      [cosCoefficient_2, constantTerm_2],
      "\\cos(x)"
    );
    const polynomialProduct = p1.multiply(p2);
    const polynomialProduct_Coefficients = polynomialProduct.getCoefficients();

    const partAAnswer_1 = new MCMaths.Fraction(
      -constantTerm_1 / cosCoefficient_1
    );
    const partAAnswer_2 = new MCMaths.Fraction(
      -constantTerm_2 / cosCoefficient_2
    );
    const partAAnswer_1Float = partAAnswer_1.toFloat();
    const partAAnswer_2Float = partAAnswer_2.toFloat();

    const partBAnswer_1Radians = Math.acos(partAAnswer_1Float);
    const partBAnswer_2Radians = Math.acos(partAAnswer_2Float);
    const partBAnswer_3Radians = -partBAnswer_1Radians;
    const partBAnswer_4Radians = -partBAnswer_2Radians;
    const partBAnswer_1Degrees = partBAnswer_1Radians * (180 / Math.PI);
    const partBAnswer_2Degrees = partBAnswer_2Radians * (180 / Math.PI);
    const partBAnswer_3Degrees = partBAnswer_3Radians * (180 / Math.PI);
    const partBAnswer_4Degrees = partBAnswer_4Radians * (180 / Math.PI);
    const partBAnswer_5Degrees = -epsilon;
    const partBAnswer_6Degrees = -180 + epsilon;

    const partBAnswer_Array = [
      parseFloat(partBAnswer_1Degrees.toPrecision(3)),
      parseFloat(partBAnswer_2Degrees.toPrecision(3)),
      parseFloat(partBAnswer_3Degrees.toPrecision(3)),
      parseFloat(partBAnswer_4Degrees.toPrecision(3)),
      parseFloat(partBAnswer_5Degrees.toPrecision(3)),
      parseFloat(partBAnswer_6Degrees.toPrecision(3)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (
      cosCoefficient_1 !== 0 &&
      cosCoefficient_2 !== 0 &&
      constantTerm_1 !== 0 &&
      constantTerm_2 !== 0 &&
      Math.abs(partAAnswer_1Float) < 1 &&
      Math.abs(partAAnswer_2Float) < 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph("question", `Given that:`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$${
            polynomialProduct_Coefficients[0] * sinCoefficient
          }\\cos^{2}(x)\\sin(x+${epsilon}^{\\circ}) = ${
            -1 * polynomialProduct_Coefficients[2] * sinCoefficient
          }\\sin(x+${epsilon}^{\\circ}),\\text{ } -180^{\\circ} < x < 180^{\\circ}$`
        )
      );
      question.addParagraph(
        "question",
        `a) Find the $2$ possible values of $\\cos(x)$.`
      );
      question.addParagraph("question", `b) Find all values of $x$.`);
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${
            polynomialProduct_Coefficients[0] * sinCoefficient
          }\\cos^{2}(x)\\sin(x+${epsilon}^{\\circ}) = ${
            -1 * polynomialProduct_Coefficients[2] * sinCoefficient
          }\\sin(x+${epsilon}^{\\circ})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${
            polynomialProduct_Coefficients[0] * sinCoefficient
          }\\cos^{2}(x)\\sin(x+${epsilon}^{\\circ})+${
            polynomialProduct_Coefficients[2] * sinCoefficient
          }\\sin(x+${epsilon}^{\\circ}) = 0$`
        )
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${sinCoefficient}\\sin(x+${epsilon}^{\\circ})(${polynomialProduct_Coefficients[0]}\\cos^{2}(x)+${polynomialProduct_Coefficients[2]}) = 0$`
        )
      );

      question.addParagraph(
        "solution",
        `$${sinCoefficient}\\sin(x+${epsilon}^{\\circ})\\left[(${p1.toString()})(${p2.toString()})\\right] = 0$`
      );

      question.addParagraph(
        "solution",
        `$\\sin(x+${epsilon}^{\\circ})\\left[(${p1.toString()})(${p2.toString()})\\right] = 0$`
      );

      question.addParagraph(
        "solution",
        `$\\therefore \\displaystyle \\cos(x) = ${partAAnswer_1.toString()},\\text{ } \\cos(x) = ${partAAnswer_2.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\cos(x) = ${partAAnswer_1Float.toPrecision(
          3
        )},\\text{ } \\cos(x) = ${partAAnswer_2Float.toPrecision(3)}$.`
      );

      question.addParagraph(
        "solution",
        `b) $\\therefore x = \\cos^{-1}(${partAAnswer_1Float.toPrecision(
          3
        )}),\\text{ } \\cos^{-1}(${partAAnswer_2Float.toPrecision(3)})$`
      );
      question.addParagraph(
        "solution",
        `$x = ${partBAnswer_1Degrees.toPrecision(
          3
        )}^{\\circ},\\text{ } x = ${partBAnswer_2Degrees.toPrecision(
          3
        )}^{\\circ}$`
      );

      question.addParagraph(
        "solution",
        `Also $x = ${partBAnswer_3Degrees.toPrecision(
          3
        )}^{\\circ}, \\text{ } x = ${partBAnswer_4Degrees.toPrecision(
          3
        )}^{\\circ}$.`
      );

      question.addParagraph(
        "solution",
        `And since $\\sin(x+${epsilon}^{\\circ}) = 0$`
      );
      question.addParagraph("solution", `$x+${epsilon}^{\\circ} = 0$`);
      question.addParagraph("solution", `$x = ${-epsilon}^{\\circ}$`);
      question.addParagraph("solution", `Also $x = -180 + ${epsilon}$`);
      question.addParagraph(
        "solution",
        `Also $x = ${-180 + epsilon}^{\\circ}$`
      );
      question.addParagraph(
        "solution",
        `Therefore all values of $x = ${partBAnswer_Array[0]}^{\\circ},\\text{ } ${partBAnswer_Array[1]}^{\\circ},\\text{ } ${partBAnswer_Array[2]}^{\\circ},\\text{ } ${partBAnswer_Array[3]}^{\\circ},\\text{ } ${partBAnswer_Array[4]}^{\\circ},\\text{ } ${partBAnswer_Array[5]}^{\\circ}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(partAAnswer_1Float.toPrecision(3)),
          parseFloat(partAAnswer_2Float.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(partAAnswer_1Float.toPrecision(3)) -
            parseFloat(partAAnswer_1Float.toPrecision(2))
        ),
        "a) $\\text{ } \\cos(x) = $",
        "before"
      );

      const group2 = new MCQuestion.InputGroup(6);
      group2.addInput(partBAnswer_Array, 0, "b) $\\text{ } x = $", "before");

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricEquations_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha_1 = MCRandom.randomInt(-9, 9);
    const alpha_2 = MCRandom.randomInt(-9, 9);
    const beta_1 = MCRandom.randomInt(-9, 9);
    const beta_2 = MCRandom.randomInt(-9, 9);
    const constantK = MCRandom.randomInt(2, 9);

    const p1 = new MCMaths.Polynomial(
      [alpha_1, beta_1],
      `\\tan(${constantK}\\theta)`
    );
    const p2 = new MCMaths.Polynomial(
      [alpha_2, beta_2],
      `\\tan(${constantK}\\theta)`
    );
    const polynomialProduct = p1.multiply(p2);
    const polynomialProduct_Coefficients = polynomialProduct.getCoefficients();

    const partAAnswer1 = new MCMaths.Fraction(beta_1 / alpha_1);
    const partAAnswer2 = new MCMaths.Fraction(beta_2 / alpha_2);
    const partAAnswerArray = [
      parseFloat(partAAnswer1.toFloat().toPrecision(3)),
      parseFloat(partAAnswer2.toFloat().toPrecision(3)),
    ];

    const partBAnswer1Radians =
      (1 / constantK) * Math.atan(partAAnswer1.toFloat());
    const partBAnswer2Radians =
      (1 / constantK) * Math.atan(partAAnswer2.toFloat());
    const partBAnswer1Degrees = partBAnswer1Radians * (180 / Math.PI);
    const partBAnswer2Degrees = partBAnswer2Radians * (180 / Math.PI);
    const partBAnswerArray = [
      parseFloat(partBAnswer1Degrees.toPrecision(3)),
      parseFloat(partBAnswer2Degrees.toPrecision(3)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (
      alpha_1 !== 0 &&
      beta_1 !== 0 &&
      alpha_2 !== 0 &&
      beta_2 !== 0 &&
      polynomialProduct_Coefficients[1] !== 0 &&
      Math.abs(partAAnswer1.toFloat()) < 1 &&
      Math.abs(partAAnswer2.toFloat()) < 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given the following equation:`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$${polynomialProduct_Coefficients[0]}\\tan^{2}(${constantK}\\theta)+${polynomialProduct_Coefficients[1]}\\tan(${constantK}\\theta)+${polynomialProduct_Coefficients[2]}=0, \\text{ } -90^{\\circ} < \\theta < 90^{\\circ}$`
        )
      );
      question.addParagraph(
        "question",
        `a) Find the possible values of $\\tan(${constantK}\\theta)$`
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, find all values of $\\theta$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `a) $${polynomialProduct_Coefficients[0]}\\tan^{2}(${constantK}\\theta)+${polynomialProduct_Coefficients[1]}\\tan(${constantK}\\theta)+${polynomialProduct_Coefficients[2]}=0$`
        )
      );
      question.addParagraph(
        "solution",
        `$(${p1.toString()})(${p2.toString()}) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\tan(${constantK}\\theta) = ${partAAnswer1.toString()}, \\text{ } \\tan(${constantK}\\theta) = ${partAAnswer2.toString()}$.`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\tan(${constantK}\\theta) = ${partAAnswer1
          .toFloat()
          .toPrecision(
            3
          )}, \\text{ } \\tan(${constantK}\\theta) = ${partAAnswer2
          .toFloat()
          .toPrecision(3)}$.`
      );

      question.addParagraph(
        "solution",
        `b) $\\displaystyle ${constantK}\\theta = \\tan^{-1}\\left(${partAAnswer1.toString()}\\right), \\text{ } ${constantK}\\theta = \\tan^{-1}\\left(${partAAnswer2.toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\frac{1}{${constantK}}\\tan^{-1}\\left(${partAAnswer1.toString()}\\right), \\text{ } \\theta = \\frac{1}{${constantK}}\\tan^{-1}\\left(${partAAnswer2.toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = ${partBAnswer1Degrees.toPrecision(
          3
        )}^{\\circ}, \\text{ } \\theta = ${partBAnswer2Degrees.toPrecision(
          3
        )}^{\\circ}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        partAAnswerArray,
        Math.abs(
          parseFloat(partAAnswer1.toFloat().toPrecision(3)) -
            parseFloat(partAAnswer1.toFloat().toPrecision(2))
        ),
        `a) $\\text{ } \\tan(${constantK}\\theta) = $`
      );

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        partBAnswerArray,
        Math.abs(
          parseFloat(partBAnswer1Degrees.toPrecision(3)) -
            parseFloat(partBAnswer1Degrees.toPrecision(2))
        ),
        `b) $\\text{ } \\theta = $`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricEquations_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(-9, 9);
    const beta = MCRandom.randomInt(-60, 60);
    const gamma_1 = MCRandom.randomInt(-9, 9);
    const gamma_2 = MCRandom.randomInt(-9, 9);
    const gamma_Fraction = new MCMaths.Fraction(gamma_2 / gamma_1);

    const partAAnswer = gamma_Fraction.toFloat();
    const partBAnswerRadians =
      (Math.atan(partAAnswer) - beta * (Math.PI / 180)) / alpha;
    const partBAnswerDegrees = partBAnswerRadians * (180 / Math.PI);

    /* END VARIABLES AND CONSTANTS */
    if (
      alpha !== 0 &&
      Math.abs(beta) > 10 &&
      Math.abs(gamma_1) > 1 &&
      Math.abs(gamma_2) > 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given the following equation:`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$${gamma_1}\\sin(${alpha}\\theta+${beta}^{\\circ}) = ${gamma_2}\\cos(${alpha}\\theta+${beta}^{\\circ}), \\text{ } -90^{\\circ} < \\theta < 90^{\\circ}$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `a) Find the value of $\\tan(${alpha}\\theta+${beta}^{\\circ})$.`
        )
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, find $\\theta$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `a) $${gamma_1}\\sin(${alpha}\\theta+${beta}^{\\circ}) = ${gamma_2}\\cos(${alpha}\\theta+${beta}^{\\circ})$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\frac{\\sin(${alpha}\\theta+${beta}^{\\circ})}{\\cos(${alpha}\\theta+${beta}^{\\circ})} = ${gamma_Fraction.toString()}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\tan(${alpha}\\theta+${beta}^{\\circ}) = ${gamma_Fraction.toString()}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\tan(${alpha}\\theta+${beta}^{\\circ}) = ${gamma_Fraction
            .toFloat()
            .toPrecision(3)}$`,
          false
        )
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `b) $\\displaystyle \\tan(${alpha}\\theta+${beta}^{\\circ}) = ${gamma_Fraction.toString()}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle ${alpha}\\theta+${beta}^{\\circ} = \\tan^{-1}\\left(${gamma_Fraction.toString()}\\right)$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\theta = \\frac{\\tan^{-1}\\left(${gamma_Fraction.toString()}\\right)+${-beta}}{${alpha}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\theta = ${partBAnswerDegrees.toPrecision(
            3
          )}^{\\circ}$`,
          false
        )
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(partAAnswer.toPrecision(3)),
        Math.abs(
          parseFloat(partAAnswer.toPrecision(3)) -
            parseFloat(partAAnswer.toPrecision(2))
        ),
        MCMaths.cleaner(
          `a) $\\text{ } \\tan(${alpha}\\theta+${beta}^{\\circ})$`
        ),
        "before"
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(partBAnswerDegrees.toPrecision(3)),
        Math.abs(
          parseFloat(partBAnswerDegrees.toPrecision(3)) -
            parseFloat(partBAnswerDegrees.toPrecision(2))
        ),
        `b) $\\text{ } \\theta = $`,
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricEquations_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(10, 30);
    const gamma = MCRandom.randomInt(2, 9);

    const thetaRadians1 = Math.acos(gamma / alpha) + beta * (Math.PI / 180);
    const thetaRadians2 = -thetaRadians1;
    const thetaDegrees1 = thetaRadians1 * (180 / Math.PI);
    const thetaDegrees2 = thetaRadians2 * (180 / Math.PI);

    const cosTheta = Math.cos(thetaRadians1);

    /* END VARIABLES AND CONSTANTS */
    if (gamma / alpha < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `a) Solve the following equation:`);
      question.addParagraph(
        "question",
        `$${alpha}\\cos(\\theta - ${beta}^{\\circ}) - ${gamma} = 0, \\text{ } -90^{\\circ} < \\theta < 90^{\\circ}$`
      );
      question.addParagraph(
        "question",
        `b) Hence find the value of $\\cos(\\theta)$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $${alpha}\\cos(\\theta - ${beta}^{\\circ}) - ${gamma} = 0$`
      );
      question.addParagraph(
        "solution",
        `$${alpha}\\cos(\\theta - ${beta}^{\\circ})  = ${gamma}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos(\\theta - ${beta}^{\\circ})  = ${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta - ${beta}^{\\circ}  = \\cos^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\cos^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right) + ${beta}^{\\circ} $`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaDegrees1.toPrecision(3)}^{\\circ} $`
      );
      question.addParagraph(
        "solution",
        `Also, $\\theta = -${thetaDegrees1.toPrecision(3)}^{\\circ} $`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = ${thetaDegrees1.toPrecision(
          3
        )}^{\\circ}, \\text{ } ${thetaDegrees2.toPrecision(3)}^{\\circ} $`
      );

      question.addParagraph(
        "solution",
        `b) $\\cos(\\theta) = \\cos(\\cos^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right) + ${beta}^{\\circ}) $`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\cos(\\theta) = ${cosTheta.toPrecision(3)} $`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(thetaDegrees1.toPrecision(3)),
          parseFloat(thetaDegrees2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(thetaDegrees1.toPrecision(3)) -
            parseFloat(thetaDegrees1.toPrecision(2))
        ),
        "a) $\\text{ } \\theta = $"
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(cosTheta.toPrecision(3)),
        Math.abs(
          parseFloat(cosTheta.toPrecision(3)) -
            parseFloat(cosTheta.toPrecision(2))
        ),
        "b) $\\text{ } \\cos(\\theta) = $"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricEquations_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(10, 30);
    const gamma = MCRandom.randomInt(2, 9);

    const thetaRadians1 = Math.atan(gamma / alpha) + beta * (Math.PI / 180);
    const thetaRadians2 = Math.PI - thetaRadians1;
    const thetaDegrees1 = thetaRadians1 * (180 / Math.PI);
    const thetaDegrees2 = thetaDegrees1 + 180;

    const tanTheta = Math.tan(thetaRadians1);

    /* END VARIABLES AND CONSTANTS */
    if (gamma / alpha < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `a) Solve the following equation:`);
      question.addParagraph(
        "question",
        `$${alpha}\\tan(\\theta - ${beta}^{\\circ}) - ${gamma} = 0, \\text{ } -90^{\\circ} < \\theta < 270^{\\circ}$`
      );
      question.addParagraph(
        "question",
        `b) Hence find the value of $\\tan(\\theta)$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $${alpha}\\tan(\\theta - ${beta}^{\\circ}) - ${gamma} = 0$`
      );
      question.addParagraph(
        "solution",
        `$${alpha}\\tan(\\theta - ${beta}^{\\circ})  = ${gamma}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\tan(\\theta - ${beta}^{\\circ})  = ${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta - ${beta}^{\\circ}  = \\tan^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\tan^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right) + ${beta}^{\\circ} $`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaDegrees1.toPrecision(3)}^{\\circ} $`
      );
      question.addParagraph(
        "solution",
        `Also, $\\theta = ${thetaDegrees1.toPrecision(3)} + 180 $`
      );
      question.addParagraph(
        "solution",
        `$\\theta = ${thetaDegrees2.toPrecision(3)}^{\\circ}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\theta = ${thetaDegrees1.toPrecision(
          3
        )}^{\\circ}, \\text{ } ${thetaDegrees2.toPrecision(3)}^{\\circ} $`
      );

      question.addParagraph(
        "solution",
        `b) $\\tan(\\theta) = \\tan(\\tan^{-1}\\left(${new MCMaths.Fraction(
          gamma / alpha
        ).toString()}\\right) + ${beta}^{\\circ}) $`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\tan(\\theta) = ${tanTheta.toPrecision(3)} $`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(thetaDegrees1.toPrecision(3)),
          parseFloat(thetaDegrees2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(thetaDegrees1.toPrecision(3)) -
            parseFloat(thetaDegrees1.toPrecision(2))
        ),
        "a) $\\text{ } \\theta = $"
      );

      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(tanTheta.toPrecision(3)),
        Math.abs(
          parseFloat(tanTheta.toPrecision(3)) -
            parseFloat(tanTheta.toPrecision(2))
        ),
        "b) $\\text{ } \\tan(\\theta) = $"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricEquations_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const commonValues = [0, 1, Math.sqrt(3) / 2, 1 / Math.sqrt(2), 1 / 2];
    const valueChooser = MCRandom.randomInt(0, commonValues.length - 1);
    const value = commonValues[valueChooser];
    const parameterScalar = MCRandom.randomInt(2, 4);

    let valueString = ``;
    let scaledX = 0;
    let x = 0;
    switch (valueChooser) {
      case 0:
        valueString = `${value}`;
        scaledX = 90;
        x = scaledX / parameterScalar;
        break;

      case 1:
        valueString = `${value}`;
        scaledX = 0;
        x = scaledX / parameterScalar;
        break;

      case 2:
        valueString = `\\frac{\\sqrt{3}}{2}`;
        scaledX = 30;
        x = scaledX / parameterScalar;
        break;

      case 3:
        valueString = `\\frac{1}{\\sqrt{2}}`;
        scaledX = 45;
        x = scaledX / parameterScalar;
        break;

      case 4:
        valueString = `\\frac{1}{2}`;
        scaledX = 60;
        x = scaledX / parameterScalar;
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct solution to the following equation:`
      );
      question.addParagraph(
        `$\\displaystyle \\cos(${parameterScalar}x) = ${valueString}, \\text{ } 0^{\\circ} \\leq x \\leq 90^{\\circ}$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$x = ${x}^{\\circ}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      if (scaledX === 0) {
        answer2.addParagraph(`$x = ${x + 10}^{\\circ}$`);
      } else {
        answer2.addParagraph(`$x = ${scaledX}^{\\circ}$`);
      }
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$x = ${x + 30}^{\\circ}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$x = ${x + 45}^{\\circ}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricEquations_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const commonValues = [0, 1, Math.sqrt(3) / 2, 1 / Math.sqrt(2), 1 / 2];
    const valueChooser = MCRandom.randomInt(0, commonValues.length - 1);
    const value = commonValues[valueChooser];
    const parameterScalar = MCRandom.randomInt(2, 4);

    let valueString = ``;
    let scaledX = 0;
    let x = 0;
    switch (valueChooser) {
      case 0:
        valueString = `${value}`;
        scaledX = 0;
        x = scaledX / parameterScalar;
        break;

      case 1:
        valueString = `${value}`;
        scaledX = 90;
        x = scaledX / parameterScalar;
        break;

      case 2:
        valueString = `\\frac{\\sqrt{3}}{2}`;
        scaledX = 60;
        x = scaledX / parameterScalar;
        break;

      case 3:
        valueString = `\\frac{1}{\\sqrt{2}}`;
        scaledX = 45;
        x = scaledX / parameterScalar;
        break;

      case 4:
        valueString = `\\frac{1}{2}`;
        scaledX = 30;
        x = scaledX / parameterScalar;
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct solution to the following equation:`
      );
      question.addParagraph(
        `$\\displaystyle \\sin(${parameterScalar}x) = ${valueString}, \\text{ } 0^{\\circ} \\leq x \\leq 90^{\\circ}$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$x = ${x}^{\\circ}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      if (scaledX === 0) {
        answer2.addParagraph(`$x = ${x + 10}^{\\circ}$`);
      } else {
        answer2.addParagraph(`$x = ${scaledX}^{\\circ}$`);
      }
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$x = ${x + 30}^{\\circ}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$x = ${x + 45}^{\\circ}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricEquations_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const commonValues = [
      0,
      1,
      2 - Math.sqrt(3),
      1 / Math.sqrt(3),
      Math.sqrt(3),
    ];
    const valueChooser = MCRandom.randomInt(0, commonValues.length - 1);
    const value = commonValues[valueChooser];
    const parameterScalar = MCRandom.randomInt(2, 4);

    let valueString = ``;
    let scaledX = 0;
    let x = 0;
    switch (valueChooser) {
      case 0:
        valueString = `${value}`;
        scaledX = 0;
        x = scaledX / parameterScalar;
        break;

      case 1:
        valueString = `${value}`;
        scaledX = 45;
        x = scaledX / parameterScalar;
        break;

      case 2:
        valueString = `2 - \\sqrt{3}`;
        scaledX = 15;
        x = scaledX / parameterScalar;
        break;

      case 3:
        valueString = `\\frac{1}{\\sqrt{3}}`;
        scaledX = 30;
        x = scaledX / parameterScalar;
        break;

      case 4:
        valueString = `\\sqrt{3}`;
        scaledX = 60;
        x = scaledX / parameterScalar;
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct solution to the following equation:`
      );
      question.addParagraph(
        `$\\displaystyle \\tan(${parameterScalar}x) = ${valueString}$`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$x = ${x}^{\\circ}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      if (scaledX === 0) {
        answer2.addParagraph(`$x = ${x + 10}^{\\circ}$`);
      } else {
        answer2.addParagraph(`$x = ${scaledX}^{\\circ}$`);
      }
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$x = ${x + 30}^{\\circ}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$x = ${x + 45}^{\\circ}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricEquations_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const commonValues = [0, 1, Math.sqrt(3) / 2, 1 / Math.sqrt(2), 1 / 2];
    const valueChooser = MCRandom.randomInt(0, commonValues.length - 1);
    const value = commonValues[valueChooser];
    const parameterShift =
      (MCRandom.randomInt(1, 4) * 10 + MCRandom.randomInt(0, 1) * 5) *
      MCRandom.randomInt(-1, 1, 2);

    let valueString = ``;
    let shiftedX = 0;
    let x = 0;
    switch (valueChooser) {
      case 0:
        valueString = `${value}`;
        shiftedX = 90;
        x = shiftedX - parameterShift;
        break;

      case 1:
        valueString = `${value}`;
        shiftedX = 0;
        x = shiftedX - parameterShift;
        break;

      case 2:
        valueString = `\\frac{\\sqrt{3}}{2}`;
        shiftedX = 30;
        x = shiftedX - parameterShift;
        break;

      case 3:
        valueString = `\\frac{1}{\\sqrt{2}}`;
        shiftedX = 45;
        x = shiftedX - parameterShift;
        break;

      case 4:
        valueString = `\\frac{1}{2}`;
        shiftedX = 60;
        x = shiftedX - parameterShift;
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct solution to the following equation:`
      );
      question.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\cos(x+${parameterShift}) = ${valueString}, \\text{ } -45^{\\circ} \\leq x \\leq 225^{\\circ}$`
        )
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$x = ${x}^{\\circ}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      if (shiftedX === x + 45 || shiftedX === x + 30) {
        answer2.addParagraph(`$x = ${x + 10}^{\\circ}$`);
      } else {
        answer2.addParagraph(`$x = ${shiftedX}^{\\circ}$`);
      }
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$x = ${x + 30}^{\\circ}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$x = ${x + 45}^{\\circ}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricEquations_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const commonValues = [0, 1, Math.sqrt(3) / 2, 1 / Math.sqrt(2), 1 / 2];
    const valueChooser = MCRandom.randomInt(0, commonValues.length - 1);
    const value = commonValues[valueChooser];
    const parameterShift =
      (MCRandom.randomInt(1, 4) * 10 + MCRandom.randomInt(0, 1) * 5) *
      MCRandom.randomInt(-1, 1, 2);

    let valueString = ``;
    let shiftedX = 0;
    let x = 0;
    switch (valueChooser) {
      case 0:
        valueString = `${value}`;
        shiftedX = 0;
        x = shiftedX - parameterShift;
        break;

      case 1:
        valueString = `${value}`;
        shiftedX = 90;
        x = shiftedX - parameterShift;
        break;

      case 2:
        valueString = `\\frac{\\sqrt{3}}{2}`;
        shiftedX = 60;
        x = shiftedX - parameterShift;
        break;

      case 3:
        valueString = `\\frac{1}{\\sqrt{2}}`;
        shiftedX = 45;
        x = shiftedX - parameterShift;
        break;

      case 4:
        valueString = `\\frac{1}{2}`;
        shiftedX = 30;
        x = shiftedX - parameterShift;
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct solution to the following equation:`
      );
      question.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\sin(x+${parameterShift}) = ${valueString}, \\text{ } -45^{\\circ} \\leq x \\leq 225^{\\circ}$`
        )
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$x = ${x}^{\\circ}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      if (shiftedX === x + 45 || shiftedX === x + 30) {
        answer2.addParagraph(`$x = ${x + 10}^{\\circ}$`);
      } else {
        answer2.addParagraph(`$x = ${shiftedX}^{\\circ}$`);
      }
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$x = ${x + 30}^{\\circ}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$x = ${x + 45}^{\\circ}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricEquations_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const commonValues = [
      0,
      1,
      2 - Math.sqrt(3),
      1 / Math.sqrt(3),
      Math.sqrt(3),
    ];
    const valueChooser = MCRandom.randomInt(0, commonValues.length - 1);
    const value = commonValues[valueChooser];
    const parameterShift =
      (MCRandom.randomInt(1, 4) * 10 + MCRandom.randomInt(0, 1) * 5) *
      MCRandom.randomInt(-1, 1, 2);

    let valueString = ``;
    let shiftedX = 0;
    let x = 0;
    switch (valueChooser) {
      case 0:
        valueString = `${value}`;
        shiftedX = 0;
        x = shiftedX - parameterShift;
        break;

      case 1:
        valueString = `${value}`;
        shiftedX = 45;
        x = shiftedX - parameterShift;
        break;

      case 2:
        valueString = `2 - \\sqrt{3}`;
        shiftedX = 15;
        x = shiftedX - parameterShift;
        break;

      case 3:
        valueString = `\\frac{1}{\\sqrt{3}}`;
        shiftedX = 30;
        x = shiftedX - parameterShift;
        break;

      case 4:
        valueString = `\\sqrt{3}`;
        shiftedX = 60;
        x = shiftedX - parameterShift;
        break;

      default:
        break;
    }

    const answerArray = [true, false, false, false];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(
        `Select the correct solution to the following equation:`
      );
      question.addParagraph(
        MCMaths.cleaner(
          `$\\displaystyle \\tan(x+${parameterShift}) = ${valueString}$`
        )
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$x = ${x}^{\\circ}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      if (shiftedX === x + 45 || shiftedX === x + 30) {
        answer2.addParagraph(`$x = ${x + 10}^{\\circ}$`);
      } else {
        answer2.addParagraph(`$x = ${shiftedX}^{\\circ}$`);
      }
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$x = ${x + 30}^{\\circ}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$x = ${x + 45}^{\\circ}$`);

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricIdentities_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alphaConstant = MCRandom.randomInt(2, 9);
    const alphaDivider = MCRandom.randomInt(2, 5);

    /* END VARIABLES AND CONSTANTS */
    if (alphaDivider < alphaConstant) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given the following:`);
      question.addParagraph(
        "question",
        `$${alphaConstant} \\sin(x) \\left[\\sin^{2}(x) + \\sin^{2}(x + 90)\\right] \\equiv ${alphaDivider}\\alpha \\tan(x) \\cos(x)$`
      );
      question.addParagraph(
        "question",
        `Find the constant $\\alpha$ to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `$${alphaConstant} \\sin(x) \\left[\\sin^{2}(x) + \\sin^{2}(x + 90)\\right] = ${alphaConstant}\\left[\\sin^{2}(x) + \\cos^{2}(x)\\right]$`
      );
      question.addParagraph(
        "solution",
        `$${alphaConstant} \\sin(x) \\left[\\sin^{2}(x) + \\cos^{2}(x)\\right] = ${alphaConstant}\\sin(x)$`
      );
      question.addParagraph(
        "solution",
        `$${alphaConstant}\\sin(x) = ${alphaDivider}\\alpha \\tan(x) \\cos(x)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore ${alphaConstant}\\sin(x) = ${alphaDivider}\\alpha \\tan(x) \\cos(x)$`
      );
      question.addParagraph(
        "solution",
        `$${alphaConstant}\\tan(x) = ${alphaDivider}\\alpha \\tan(x)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\therefore \\alpha = \\frac{${alphaConstant}}{${alphaDivider}}$`
      );
      question.addParagraph(
        "solution",
        `$\\alpha = ${(alphaConstant / alphaDivider).toPrecision(3)}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat((alphaConstant / alphaDivider).toPrecision(3)),
        Math.abs(
          parseFloat((alphaConstant / alphaDivider).toPrecision(3)) -
            parseFloat((alphaConstant / alphaDivider).toPrecision(2))
        ),
        "$\\alpha = $",
        "before"
      );
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricIdentities_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const sinCoefficient = MCRandom.randomInt(2, 9);
    const rhsCoefficientNumerator = MCRandom.randomInt(2, 9);
    const rhsCoefficientDenominator = MCRandom.randomInt(2, 9);
    const rhsCoefficient = new MCMaths.Fraction(
      rhsCoefficientNumerator / rhsCoefficientDenominator
    );
    const working1 = new MCMaths.Fraction(
      (rhsCoefficientNumerator / rhsCoefficientDenominator) *
        (1 / (1 + sinCoefficient))
    );

    const partA_Answer_1 = Math.sqrt(
      rhsCoefficient.toFloat() / (1 + sinCoefficient)
    );
    const partA_Answer_2 = -partA_Answer_1;

    const partB_Answer_1Radians = Math.asin(partA_Answer_1);
    const partB_Answer_2Radians = Math.PI - Math.asin(partA_Answer_1);
    const partB_Answer_3Radians = -partB_Answer_1Radians;
    const partB_Answer_4Radians = -partB_Answer_2Radians;

    const partB_Answer_1Degrees = partB_Answer_1Radians * (180 / Math.PI);
    const partB_Answer_2Degrees = partB_Answer_2Radians * (180 / Math.PI);
    const partB_Answer_3Degrees = partB_Answer_3Radians * (180 / Math.PI);
    const partB_Answer_4Degrees = partB_Answer_4Radians * (180 / Math.PI);

    /* END VARIABLES AND CONSTANTS */
    if (rhsCoefficient.toFloat() <= 1 + sinCoefficient) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given that:`);
      question.addParagraph(
        "question",
        `$\\displaystyle 1 - \\cos^{2}(x) + ${sinCoefficient}\\sin^{2}(x) = ${rhsCoefficient.toString()}, -180^{\\circ} \\leq x \\leq 180^{\\circ}$`
      );
      question.addParagraph("question", `a) Find the values of $\\sin(x)$.`);
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, find the values of $x$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $\\displaystyle 1 - \\cos^{2}(x) + ${sinCoefficient}\\sin^{2}(x) = \\sin^{2}(x) + ${sinCoefficient}\\sin^{2}(x)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\therefore ${
          1 + sinCoefficient
        }\\sin^{2}(x) = ${rhsCoefficient.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\sin(x) = \\pm \\sqrt{${working1.toString()}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\therefore \\sin(x) = \\pm ${partA_Answer_1.toPrecision(
          3
        )}$`
      );

      question.addParagraph(
        "solution",
        `b) $\\displaystyle x = \\sin^{-1}{\\left( ${partA_Answer_1.toPrecision(
          3
        )}\\right)}$, $x = \\sin^{-1}{\\left( ${partA_Answer_2.toPrecision(
          3
        )}\\right)}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle x = ${partB_Answer_1Degrees.toPrecision(
          3
        )}^{\\circ}$, $\\displaystyle x = ${partB_Answer_3Degrees.toPrecision(
          3
        )}^{\\circ}$`
      );
      question.addParagraph(
        "solution",
        `Also $\\displaystyle x = 180^{\\circ} - ${partB_Answer_1Degrees.toPrecision(
          3
        )}^{\\circ}$, $\\displaystyle x = -(180^{\\circ} - ${partB_Answer_1Degrees.toPrecision(
          3
        )}^{\\circ})$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\displaystyle x = ${partB_Answer_1Degrees.toPrecision(
          3
        )}^{\\circ}$, $\\displaystyle x = ${partB_Answer_2Degrees.toPrecision(
          3
        )}^{\\circ}$, $\\displaystyle x = ${partB_Answer_3Degrees.toPrecision(
          3
        )}^{\\circ}$, $\\displaystyle x = ${partB_Answer_4Degrees.toPrecision(
          3
        )}^{\\circ}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(partA_Answer_1.toPrecision(3)),
          parseFloat(partA_Answer_2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(partA_Answer_1.toPrecision(3)) -
            parseFloat(partA_Answer_1.toPrecision(2))
        ),
        "a) $\\text{ } \\sin(x) = $",
        "before"
      );

      const group2 = new MCQuestion.InputGroup(4);
      group2.addInput(
        [
          parseFloat(partB_Answer_1Degrees.toPrecision(3)),
          parseFloat(partB_Answer_2Degrees.toPrecision(3)),
          parseFloat(partB_Answer_3Degrees.toPrecision(3)),
          parseFloat(partB_Answer_4Degrees.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(partB_Answer_1Degrees.toPrecision(3)) -
            parseFloat(partB_Answer_1Degrees.toPrecision(2))
        ),
        "b) $\\text{ } x = $",
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricIdentities_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const lhsCoefficient = MCRandom.randomInt(2, 9);
    const rhsCoefficient = MCRandom.randomInt(2, 9);

    const partBAnswer_1 = Math.sqrt(lhsCoefficient / rhsCoefficient);
    const partBAnswer_2 = -partBAnswer_1;

    const partCAnswer_1Radians = Math.acos(partBAnswer_1);
    const partCAnswer_2Radians = Math.acos(partBAnswer_2);
    const partCAnswer_3Radians = -partCAnswer_1Radians;
    const partCAnswer_4Radians = -partCAnswer_2Radians;

    const partCAnswer_1Degrees = partCAnswer_1Radians * (180 / Math.PI);
    const partCAnswer_2Degrees = partCAnswer_2Radians * (180 / Math.PI);
    const partCAnswer_3Degrees = partCAnswer_3Radians * (180 / Math.PI);
    const partCAnswer_4Degrees = partCAnswer_4Radians * (180 / Math.PI);

    const working1 = new MCMaths.Fraction(lhsCoefficient / rhsCoefficient);

    const partCAnswerArray = [
      parseFloat(partCAnswer_1Degrees.toPrecision(3)),
      parseFloat(partCAnswer_2Degrees.toPrecision(3)),
      parseFloat(partCAnswer_3Degrees.toPrecision(3)),
      parseFloat(partCAnswer_4Degrees.toPrecision(3)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (lhsCoefficient < rhsCoefficient) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given that:`);
      question.addParagraph(
        "question",
        `$${lhsCoefficient}\\tan(x) = ${rhsCoefficient}\\sin(x)\\cos(x), -180^{\\circ} < x < 180^{\\circ}$.`
      );
      question.addParagraph("question", `a) Find the value of $\\tan(x)$.`);
      question.addParagraph("question", `b) Find the values of $\\cos(x)$.`);
      question.addParagraph(
        "question",
        `c) It is now known that $x \\neq 0^{\\circ}$ hence, or otherwise, find the values of $x$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $\\displaystyle ${lhsCoefficient}\\tan(x) = ${lhsCoefficient}\\frac{\\sin(x)}{\\cos(x)}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\therefore ${lhsCoefficient}\\sin(x) = ${rhsCoefficient}\\sin(x) \\cos^{2}(x)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle ${lhsCoefficient}\\sin(x) - ${rhsCoefficient}\\sin(x) \\cos^{2}(x) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\sin(x)\\left(${lhsCoefficient} - ${rhsCoefficient} \\cos^{2}(x)\\right) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sin(x) = 0$ or $${lhsCoefficient} - ${rhsCoefficient} \\cos^{2}(x) = 0$`
      );
      question.addParagraph(
        "solution",
        `Since $\\displaystyle \\tan(x) = \\frac{\\sin(x)}{\\cos(x)}$`
      );
      question.addParagraph("solution", `$\\tan(x) = 0$`);

      question.addParagraph(
        "solution",
        `b) Since $${lhsCoefficient} - ${rhsCoefficient} \\cos^{2}(x) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos(x) = \\pm \\sqrt{${working1.toString()}}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos(x) = \\pm ${partBAnswer_1.toPrecision(3)}$`
      );

      question.addParagraph(
        "solution",
        `c) $x = \\cos^{-1}(${partBAnswer_1.toPrecision(
          3
        )})$, $x = \\cos^{-1}(${partBAnswer_2.toPrecision(3)})$`
      );
      question.addParagraph(
        "solution",
        `$x = ${partCAnswer_1Degrees.toPrecision(
          3
        )}^{\\circ}$, $x = ${partCAnswer_3Degrees.toPrecision(3)}^{\\circ}$`
      );
      question.addParagraph(
        "solution",
        `Also $x = ${partCAnswer_2Degrees.toPrecision(
          3
        )}^{\\circ}$, $x = ${partCAnswer_4Degrees.toPrecision(3)}^{\\circ}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(0, 0, "a) $\\text{ } \\tan(x) = $");

      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(
        [
          parseFloat(partBAnswer_1.toPrecision(3)),
          parseFloat(partBAnswer_2.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(partBAnswer_1.toPrecision(3)) -
            parseFloat(partBAnswer_1.toPrecision(2))
        ),
        "b) $\\text{ } \\cos(x) = $"
      );

      const group3 = new MCQuestion.InputGroup(4);
      group3.addInput(
        partCAnswerArray,
        Math.abs(
          parseFloat(partCAnswer_1Degrees.toPrecision(3)) -
            parseFloat(partCAnswer_1Degrees.toPrecision(2))
        ),
        "c) $\\text{ } x = $"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricIdentities_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const cosCoefficient_1 = MCRandom.randomInt(-9, 9);
    const constantTerm_1 = MCRandom.randomInt(-9, 9);
    const cosCoefficient_2 = MCRandom.randomInt(-9, 9);
    const constantTerm_2 = MCRandom.randomInt(-9, 9);

    const p1 = new MCMaths.Polynomial(
      [cosCoefficient_1, constantTerm_1],
      "\\cos(x)"
    );
    const p2 = new MCMaths.Polynomial(
      [cosCoefficient_2, constantTerm_2],
      "\\cos(x)"
    );
    const polynomialProduct = p1.multiply(p2);
    const polynomialProduct_Coefficients = polynomialProduct.getCoefficients();

    const constantTerm_3 =
      polynomialProduct_Coefficients[0] + polynomialProduct_Coefficients[2];

    const partAAnswer_1 = new MCMaths.Fraction(
      -constantTerm_1 / cosCoefficient_1
    );
    const partAAnswer_2 = new MCMaths.Fraction(
      -constantTerm_2 / cosCoefficient_2
    );
    const partAAnswer_1Float = partAAnswer_1.toFloat();
    const partAAnswer_2Float = partAAnswer_2.toFloat();

    const partBAnswer_1Radians = Math.acos(partAAnswer_1Float);
    const partBAnswer_2Radians = Math.acos(partAAnswer_2Float);
    const partBAnswer_3Radians = -partBAnswer_1Radians;
    const partBAnswer_4Radians = -partBAnswer_2Radians;
    const partBAnswer_1Degrees = partBAnswer_1Radians * (180 / Math.PI);
    const partBAnswer_2Degrees = partBAnswer_2Radians * (180 / Math.PI);
    const partBAnswer_3Degrees = partBAnswer_3Radians * (180 / Math.PI);
    const partBAnswer_4Degrees = partBAnswer_4Radians * (180 / Math.PI);

    const partBAnswer_Array = [
      parseFloat(partBAnswer_1Degrees.toPrecision(3)),
      parseFloat(partBAnswer_2Degrees.toPrecision(3)),
      parseFloat(partBAnswer_3Degrees.toPrecision(3)),
      parseFloat(partBAnswer_4Degrees.toPrecision(3)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (
      cosCoefficient_1 !== 0 &&
      cosCoefficient_2 !== 0 &&
      constantTerm_1 !== 0 &&
      constantTerm_2 !== 0 &&
      Math.abs(partAAnswer_1Float) < 1 &&
      Math.abs(partAAnswer_2Float) < 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph("question", `Given that:`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$${polynomialProduct_Coefficients[0]}\\sin^{2}(x) = ${polynomialProduct_Coefficients[1]}\\cos(x)+${constantTerm_3},\\text{ } -180^{\\circ} < x < 180^{\\circ}$.`
        )
      );
      question.addParagraph(
        "question",
        `a) Find the $2$ possible values of $\\cos(x)$.`
      );
      question.addParagraph("question", `a) Find all values of $x$.`);
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `a) $${polynomialProduct_Coefficients[0]}\\sin^{2}(x) = ${polynomialProduct_Coefficients[1]}\\cos(x)+${constantTerm_3}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${-1 * polynomialProduct_Coefficients[0]}\\sin^{2}(x)+${
            polynomialProduct_Coefficients[1]
          }\\cos(x)+${constantTerm_3} = 0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${polynomialProduct_Coefficients[0]}-${polynomialProduct_Coefficients[0]}\\sin^{2}(x)+${polynomialProduct_Coefficients[1]}\\cos(x)+${polynomialProduct_Coefficients[2]} = 0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${polynomialProduct_Coefficients[0]}(1-\\sin^{2}(x))+${polynomialProduct_Coefficients[1]}\\cos(x)+${polynomialProduct_Coefficients[2]} = 0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${polynomialProduct_Coefficients[0]}\\cos^{2}(x)+${polynomialProduct_Coefficients[1]}\\cos(x)+${polynomialProduct_Coefficients[2]} = 0$`
        )
      );
      question.addParagraph(
        "solution",
        `$(${p1.toString()})(${p2.toString()}) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\displaystyle \\cos(x) = ${partAAnswer_1.toString()},\\text{ } \\cos(x) = ${partAAnswer_2.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\cos(x) = ${partAAnswer_1Float.toPrecision(
          3
        )},\\text{ } \\cos(x) = ${partAAnswer_2Float.toPrecision(3)}$.`
      );

      question.addParagraph(
        "solution",
        `b) $\\therefore x = \\cos^{-1}(${partAAnswer_1Float.toPrecision(
          3
        )}),\\text{ } \\cos^{-1}(${partAAnswer_2Float.toPrecision(3)})$`
      );
      question.addParagraph(
        "solution",
        `$x = ${partBAnswer_1Degrees.toPrecision(
          3
        )}^{\\circ},\\text{ } x = ${partBAnswer_2Degrees.toPrecision(
          3
        )}^{\\circ}$`
      );

      question.addParagraph(
        "solution",
        `Also $x = ${partBAnswer_3Degrees.toPrecision(
          3
        )}^{\\circ}, \\text{ } x = ${partBAnswer_4Degrees.toPrecision(
          3
        )}^{\\circ}$.`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(partAAnswer_1Float.toPrecision(3)),
          parseFloat(partAAnswer_2Float.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(partAAnswer_1Float.toPrecision(3)) -
            parseFloat(partAAnswer_1Float.toPrecision(2))
        ),
        "a) $\\text{ } \\cos(x) = $",
        "before"
      );

      const group2 = new MCQuestion.InputGroup(4);
      group2.addInput(
        partBAnswer_Array,
        Math.abs(
          parseFloat(partBAnswer_1Degrees.toPrecision(3)) -
            parseFloat(partBAnswer_1Degrees.toPrecision(2))
        ),
        "b) $\\text{ } x = $",
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricIdentities_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const sinCoefficient_1 = MCRandom.randomInt(-9, 9);
    const constantTerm_1 = MCRandom.randomInt(-1, 1);
    const sinCoefficient_2 = MCRandom.randomInt(-9, 9);
    const constantTerm_2 = MCRandom.randomInt(-1, 1);

    const p1 = new MCMaths.Polynomial(
      [sinCoefficient_1, constantTerm_1],
      "\\sin(x)"
    );
    const p2 = new MCMaths.Polynomial(
      [sinCoefficient_2, constantTerm_2],
      "\\sin(x)"
    );

    const p3 = p1.multiply(p2);
    const p3Coefficients = p3.getCoefficients();

    const partAAnswer_1 = new MCMaths.Fraction(
      -constantTerm_1 / sinCoefficient_1
    );
    const partAAnswer_2 = new MCMaths.Fraction(
      -constantTerm_2 / sinCoefficient_2
    );
    const partAAnswer_1Float = partAAnswer_1.toFloat();
    const partAAnswer_2Float = partAAnswer_2.toFloat();

    const partBAnswer_1Radians = Math.asin(partAAnswer_1Float);
    const partBAnswer_2Radians = Math.asin(partAAnswer_2Float);
    const partBAnswer_3Radians =
      Math.sign(partBAnswer_1Radians) *
      (Math.PI - Math.sign(partBAnswer_1Radians) * partBAnswer_1Radians);
    const partBAnswer_4Radians =
      Math.sign(partBAnswer_2Radians) *
      (Math.PI - Math.sign(partBAnswer_2Radians) * partBAnswer_2Radians);
    const partBAnswer_1Degrees = partBAnswer_1Radians * (180 / Math.PI);
    const partBAnswer_2Degrees = partBAnswer_2Radians * (180 / Math.PI);
    const partBAnswer_3Degrees = partBAnswer_3Radians * (180 / Math.PI);
    const partBAnswer_4Degrees = partBAnswer_4Radians * (180 / Math.PI);

    const partBAnswer_Array = [
      parseFloat(partBAnswer_1Degrees.toPrecision(3)),
      parseFloat(partBAnswer_2Degrees.toPrecision(3)),
      parseFloat(partBAnswer_3Degrees.toPrecision(3)),
      parseFloat(partBAnswer_4Degrees.toPrecision(3)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (
      sinCoefficient_1 !== 0 &&
      sinCoefficient_2 !== 0 &&
      constantTerm_2 !== 0 &&
      constantTerm_1 * constantTerm_2 === -1 &&
      Math.abs(partAAnswer_1Float) < 1 &&
      Math.abs(partAAnswer_2Float) < 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph("question", `Given that:`);
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$\\displaystyle \\tan(x)\\cos(x) = \\frac{1}{(${
            p3Coefficients[0]
          }\\sin(x)+${[
            p3Coefficients[1],
          ]})},\\text{ } -180^{\\circ} < x < 180^{\\circ}$.`
        )
      );
      question.addParagraph(
        "question",
        `a) Find the $2$ values of $\\sin(x)$.`
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, find all values of $x$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `a) $\\displaystyle \\tan(x)\\cos(x) = \\frac{1}{(${
            p3Coefficients[0]
          }\\sin(x)+${[p3Coefficients[1]]})}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\frac{\\sin(x)\\cos(x)}{\\cos(x)} = \\frac{1}{(${
            p3Coefficients[0]
          }\\sin(x)+${[p3Coefficients[1]]})}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle \\sin(x) = \\frac{1}{(${
            p3Coefficients[0]
          }\\sin(x)+${[p3Coefficients[1]]})}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\sin(x)(${p3Coefficients[0]}\\sin(x)+${[p3Coefficients[1]]}) = 1$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${p3Coefficients[0]}\\sin^{2}(x)+${p3Coefficients[1]}\\sin(x) = 1$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${p3Coefficients[0]}\\sin^{2}(x)+${p3Coefficients[1]}\\sin(x)+${p3Coefficients[2]} = 0$`
        )
      );
      question.addParagraph(
        "solution",
        `$(${p1.toString()})(${p2.toString()}) = 0$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\therefore \\sin(x) = ${partAAnswer_1.toString()}, \\text{ } \\sin(x) = ${partAAnswer_2.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\sin(x) = ${partAAnswer_1Float.toPrecision(
          3
        )}, \\text{ } \\sin(x) = ${partAAnswer_2Float.toPrecision(3)}$.`
      );

      question.addParagraph(
        "solution",
        `b) $\\therefore x = \\sin^{-1}(${partAAnswer_1Float.toPrecision(
          3
        )}), \\text{ } x = \\sin^{-1}(${partAAnswer_2Float.toPrecision(3)})$`
      );
      question.addParagraph(
        "solution",
        `$x = ${partBAnswer_1Degrees.toPrecision(
          3
        )}^{\\circ}, \\text{ } x = ${partBAnswer_2Degrees.toPrecision(
          3
        )}^{\\circ}$`
      );
      question.addParagraph(
        "solution",
        `Also $x = ${partBAnswer_3Degrees.toPrecision(
          3
        )}^{\\circ}, \\text{ } x = ${partBAnswer_4Degrees.toPrecision(
          3
        )}^{\\circ}$.`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [
          parseFloat(partAAnswer_1Float.toPrecision(3)),
          parseFloat(partAAnswer_2Float.toPrecision(3)),
        ],
        Math.abs(
          parseFloat(partAAnswer_1Float.toPrecision(3)) -
            parseFloat(partAAnswer_1Float.toPrecision(2))
        ),
        "a) $\\text{ } \\sin(x) = $",
        "before"
      );

      const group2 = new MCQuestion.InputGroup(4);
      group2.addInput(
        partBAnswer_Array,
        Math.abs(
          parseFloat(partBAnswer_1Degrees.toPrecision(3)) -
            parseFloat(partBAnswer_1Degrees.toPrecision(2))
        ),
        "b) $\\text{ } x = $",
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricIdentities_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const tanCoefficient = MCRandom.randomInt(-9, 9);

    const partAAnswer_1 = 0;
    const partAAnswer_2 =
      -tanCoefficient / 2 - Math.sqrt(tanCoefficient ** 2 + 4) / 2;
    const partAAnswer_3 =
      -tanCoefficient / 2 + Math.sqrt(tanCoefficient ** 2 + 4) / 2;

    let partAAnswer_4 = 0;
    // (Math.abs(partAAnswer_2) < Math.abs(partAAnswer_3)) ? partAAnswer_4 = partAAnswer_2 : partAAnswer_4 = partAAnswer_3;
    if (Math.abs(partAAnswer_2) < Math.abs(partAAnswer_3)) {
      partAAnswer_4 = partAAnswer_2;
    } else {
      partAAnswer_4 = partAAnswer_3;
    }

    const partAAnswer_Array = [0, parseFloat(partAAnswer_4.toPrecision(3))];

    const partBAnswer_1 = 0;
    const partBAnswer_2Radians = Math.asin(partAAnswer_4);
    const partBAnswer_3Radians =
      Math.sign(partBAnswer_2Radians) *
      (Math.PI - Math.sign(partBAnswer_2Radians) * partBAnswer_2Radians);
    const partBAnswer_2Degrees = partBAnswer_2Radians * (180 / Math.PI);
    const partBAnswer_3Degrees = partBAnswer_3Radians * (180 / Math.PI);

    const partBAnswer_Array = [
      0,
      parseFloat(partBAnswer_2Degrees.toPrecision(3)),
      parseFloat(partBAnswer_3Degrees.toPrecision(3)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (tanCoefficient !== 0 && Math.abs(tanCoefficient) !== 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `Given that,`);
      question.addParagraph(
        "question",
        `$${tanCoefficient}\\tan^{2}(\\theta) = \\sin(\\theta), \\text{ } -180^{\\circ} < \\theta < 180^{\\circ}$`
      );
      question.addParagraph(
        "question",
        `a) Find the possible values of $\\sin(\\theta)$.`
      );
      question.addParagraph(
        "question",
        `b) Hence, or otherwise, find the values of $\\theta$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `a) $${tanCoefficient}\\tan^{2}(\\theta) = \\sin(\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle ${tanCoefficient}\\frac{\\sin^{2}(\\theta)}{\\cos^{2}(\\theta)} = \\sin(\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$${tanCoefficient}\\sin^{2}(\\theta) = \\sin(\\theta)\\cos^{2}(\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$${tanCoefficient}\\sin^{2}(\\theta) = \\sin(\\theta)(1 - \\sin^{2}(\\theta))$`
      );
      question.addParagraph(
        "solution",
        `$${tanCoefficient}\\sin^{2}(\\theta) = \\sin(\\theta) - \\sin^{3}(\\theta)$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\sin^{3}(\\theta)+${tanCoefficient}\\sin^{2}(\\theta)-\\sin(\\theta) = 0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\sin(\\theta)(\\sin^{2}(\\theta)+${tanCoefficient}\\sin(\\theta)-1) = 0$`
        )
      );
      question.addParagraph("solution", `So $\\sin(\\theta) = 0$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `Or $(\\sin^{2}(\\theta)+${tanCoefficient}\\sin(\\theta)-1) = 0$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `I.e. $\\displaystyle \\sin(\\theta) = 0,\\text{ }\\sin(\\theta) = \\frac{${-tanCoefficient}+\\sqrt{${
            tanCoefficient ** 2 + 4
          }}}{2},\\text{ }\\sin(\\theta) =  \\frac{${-tanCoefficient}-\\sqrt{${
            tanCoefficient ** 2 + 4
          }}}{2}$`
        )
      );
      question.addParagraph(
        "solution",
        `But $-1 \\leq \\sin(\\theta) \\leq 1$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\sin(\\theta) = 0,\\text{ } \\sin(\\theta) =  ${partAAnswer_4.toPrecision(
          3
        )}$.`
      );
      question.addParagraph(
        "solution",
        `b) $\\therefore \\theta = \\sin^{-1}(0),\\text{ } \\theta = \\sin^{-1}(${partAAnswer_4.toPrecision(
          3
        )})$`
      );
      question.addParagraph(
        "solution",
        `$\\theta = 0^{\\circ},\\text{ } \\theta = ${partBAnswer_2Degrees.toPrecision(
          3
        )}^{\\circ}$`
      );
      question.addParagraph(
        "solution",
        `Also $\\theta = ${partBAnswer_3Degrees.toPrecision(3)}^{\\circ}.$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        partAAnswer_Array,
        Math.abs(
          parseFloat(partAAnswer_1.toPrecision(3)) -
            parseFloat(partAAnswer_1.toPrecision(2))
        ),
        "a) $\\text{ } \\sin(\\theta) = $",
        "before"
      );

      const group2 = new MCQuestion.InputGroup(3);
      group2.addInput(
        partBAnswer_Array,
        Math.abs(
          parseFloat(partBAnswer_2Degrees.toPrecision(3)) -
            parseFloat(partBAnswer_2Degrees.toPrecision(2))
        ),
        "b) $\\text{ } \\theta = $",
        "before"
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometricIdentities_MCQ_Q1() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const scalar = MCRandom.randomInt(2, 9);

    const identitiesList = [
      [`$${scalar}(\\sin^{2}(x) + \\cos^{2}(x)) \\equiv ${scalar}$`, true],
      [`$${scalar}(\\sin^{2}(x) - \\cos^{2}(x)) \\equiv ${scalar}$`, false],
      [
        `$${scalar}(\\sin^{2}(x) + \\cos^{2}(x)) \\equiv ${scalar ** 2}$`,
        false,
      ],
      [
        `$${scalar}\\sin(x) \\equiv \\sqrt{${scalar ** 2}+${
          scalar ** 2
        }\\cos^{2}(x)}$`,
        false,
      ],
      [
        `$${scalar}\\sin(x) \\equiv \\sqrt{${scalar ** 2}-${
          scalar ** 2
        }\\cos^{2}(x)}$`,
        true,
      ],
      [
        `$${scalar}\\cos(x) \\equiv \\sqrt{${scalar ** 2}+${
          scalar ** 2
        }\\sin^{2}(x)}$`,
        false,
      ],
      [
        `$${scalar}\\cos(x) \\equiv \\sqrt{${scalar ** 2}-${
          scalar ** 2
        }\\sin^{2}(x)}$`,
        true,
      ],
      [
        `$\\displaystyle ${scalar}\\tan(x) \\equiv \\frac{${scalar}\\cos(x)}{\\sin(x)}$`,
        false,
      ],
      [`$${scalar}\\sin(x) \\equiv ${scalar}\\tan(x)\\cos(x)$`, true],
      [
        `$\\displaystyle ${scalar}\\tan(x) \\equiv \\frac{${scalar}\\sin(x)}{\\cos(x)}$`,
        true,
      ],
      [
        `$\\displaystyle ${scalar}\\sin(x) \\equiv \\frac{${scalar}\\cos(x)}{\\tan(x)}$`,
        false,
      ],
      [
        `$\\displaystyle ${scalar}\\cos(x) \\equiv \\frac{${scalar}\\sin(x)}{\\tan(x)}$`,
        false,
      ],
    ];

    const identityChooser1 = MCRandom.randomInt(0, identitiesList.length - 1);
    const identityChooser2 = MCRandom.randomInt(0, identitiesList.length - 1);
    const identityChooser3 = MCRandom.randomInt(0, identitiesList.length - 1);
    const identityChooser4 = MCRandom.randomInt(0, identitiesList.length - 1);

    /* END VARIABLES AND CONSTANTS */
    if (
      identityChooser1 !== identityChooser2 &&
      identityChooser3 !== identityChooser4 &&
      identityChooser1 !== identityChooser3 &&
      identityChooser2 !== identityChooser4
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(`Choose the correct identities:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`${identitiesList[identityChooser1][0]}`);
      question.addAnswer(answer1, identitiesList[identityChooser1][1]);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`${identitiesList[identityChooser2][0]}`);
      question.addAnswer(answer2, identitiesList[identityChooser2][1]);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`${identitiesList[identityChooser3][0]}`);
      question.addAnswer(answer3, identitiesList[identityChooser3][1]);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`${identitiesList[identityChooser4][0]}`);
      question.addAnswer(answer4, identitiesList[identityChooser4][1]);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_Trigonometry_TrigonometricIdentities_MCQ_Q2() {
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const scalar = MCRandom.randomInt(2, 9);

    const identitiesList = [
      [`$${scalar}\\sin(x \\pm 360^{\\circ}) \\equiv ${scalar}\\sin(x)$`, true],
      [`$${scalar}\\cos(x \\pm 360^{\\circ}) \\equiv ${scalar}\\cos(x)$`, true],
      [`$${scalar}\\sin(x \\pm 180^{\\circ}) \\equiv ${scalar}\\sin(x)$`, true],
      [
        `$${scalar}\\cos(x \\pm 180^{\\circ}) \\equiv ${scalar}\\cos(x)$`,
        false,
      ],
      [
        `$${scalar}\\cos(x \\pm 180^{\\circ}) \\equiv -${scalar}\\cos(x)$`,
        true,
      ],
      [`$${scalar}\\sin(x \\pm 90^{\\circ}) \\equiv ${scalar}\\sin(x)$`, false],
      [`$${scalar}\\cos(x \\pm 90^{\\circ}) \\equiv ${scalar}\\cos(x)$`, false],
      [
        `$${scalar}\\cos(x \\pm 90^{\\circ}) \\equiv -${scalar}\\cos(x)$`,
        false,
      ],
      [`$${scalar}\\sin(-x) \\equiv -${scalar}\\sin(x)$`, true],
      [`$${scalar}\\sin(-x) \\equiv ${scalar}\\sin(x)$`, false],
      [`$${scalar}\\sin(-x) \\equiv ${scalar}\\cos(x)$`, false],
      [`$${scalar}\\cos(-x) \\equiv ${scalar}\\cos(x)$`, true],
      [`$${scalar}\\cos(-x) \\equiv -${scalar}\\cos(x)$`, false],
      [`$${scalar}\\cos(-x) \\equiv ${scalar}\\sin(x)$`, false],
    ];

    const identityChooser1 = MCRandom.randomInt(0, identitiesList.length - 1);
    const identityChooser2 = MCRandom.randomInt(0, identitiesList.length - 1);
    const identityChooser3 = MCRandom.randomInt(0, identitiesList.length - 1);
    const identityChooser4 = MCRandom.randomInt(0, identitiesList.length - 1);

    /* END VARIABLES AND CONSTANTS */
    if (
      identityChooser1 !== identityChooser2 &&
      identityChooser3 !== identityChooser4 &&
      identityChooser1 !== identityChooser3 &&
      identityChooser2 !== identityChooser4
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addHeading(`Choose the correct identities:`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`${identitiesList[identityChooser1][0]}`);
      question.addAnswer(answer1, identitiesList[identityChooser1][1]);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`${identitiesList[identityChooser2][0]}`);
      question.addAnswer(answer2, identitiesList[identityChooser2][1]);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`${identitiesList[identityChooser3][0]}`);
      question.addAnswer(answer3, identitiesList[identityChooser3][1]);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`${identitiesList[identityChooser4][0]}`);
      question.addAnswer(answer4, identitiesList[identityChooser4][1]);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      return question.makeQuestion();
    }
  }
}


export function AS_Trigonometry_TrigonometryProblems_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const boxHeight = MCRandom.randomInt(2, 5);
    const projectilePathWidth = MCRandom.randomInt(20, 30);
    const alpha = MCRandom.randomInt(2, 5);
    const boxLevelProjectilePathWidth = projectilePathWidth - alpha;
    const projectileAngle = MCRandom.randomInt(30, 60);
    const projectileAngle_Radians = projectileAngle * (Math.PI / 180);

    const adj = boxLevelProjectilePathWidth / 2;
    const opp = adj * Math.tan(projectileAngle_Radians);

    const h = parseFloat(opp.toPrecision(3)) + boxHeight;

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `A laser is ejected out of a box of height $${boxHeight}m$ at an angle of $${projectileAngle}^{\\circ}$ to the horizontal, reflected by a mirror and back onto the ground.`
      );
      question.addParagraph(
        "question",
        `The laser lands $${projectilePathWidth}m$ away from the box horizontally.`
      );
      question.addParagraph(
        "question",
        `A computer calculates that the laser passes below the box $${alpha}m$ before reaching the ground.`
      );
      question.addParagraph(
        "question",
        `Calculate the maximum height, $h$, that the laser's path reaches.`
      );
      question.addParagraph(
        "question",
        `Give your answer to $3$ significant figures.`
      );

      const myImage = new MCQuestion.Image(
        "Matt/trigonometryProblem1.svg",
        "width",
        30
      );
      myImage.addOverlay(`$${projectileAngle}^{\\circ}$`, 25, 76);
      myImage.addOverlay(`$${alpha}m$`, 88, 75);
      myImage.addOverlay(`$h$`, 54, 55);
      question.addImage("question", myImage);

      question.addParagraph("solution", `$h = opp + ${boxHeight}$`);
      question.addParagraph(
        "solution",
        `$opp = adj \\times \\tan(${projectileAngle}^{\\circ})$`
      );
      question.addParagraph(
        "solution",
        `$adj = \\frac{1}{2}(${projectilePathWidth} - ${alpha})$`
      );
      question.addParagraph("solution", `$= ${adj}$`);
      question.addParagraph(
        "solution",
        `$opp = ${adj}\\tan(${projectileAngle}^{\\circ})$`
      );
      question.addParagraph("solution", `$= ${opp.toPrecision(3)}$`);
      question.addParagraph(
        "solution",
        `$h = ${opp.toPrecision(3)} + ${boxHeight}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore h = ${h.toPrecision(3)}m$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(h.toPrecision(3)),
        Math.abs(parseFloat(h.toPrecision(3)) - parseFloat(h.toPrecision(2))),
        `$h =$`
      );

      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometryProblems_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const theta = MCRandom.randomInt(30, 60);
    const l1 = MCRandom.randomInt(2, 9);
    const l2 = MCRandom.randomInt(2, 9);

    const lengthOBSquared =
      l1 ** 2 + l2 ** 2 - 2 * l1 * l2 * Math.cos(theta * (Math.PI / 180));
    const lengthOB = Math.sqrt(lengthOBSquared);
    const working1 = l1 ** 2 + l2 ** 2;
    const working2 = 2 * l1 * l2;

    const sinCoefficient = new MCMaths.Fraction((1 / 2) * l1 * l2);
    const area = sinCoefficient.toFloat() * Math.sin(theta * (Math.PI / 180));

    /* END VARIABLES AND CONSTANTS */
    if (l1 !== l2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `A helicopter starts at a point $O$.`);
      question.addParagraph(
        "question",
        `The helicopter then flies at a bearing of $0${theta}^{\\circ}$ for $${l1}km$ reaching point $A$.`
      );
      question.addParagraph(
        "question",
        `Then the helicopter flies directly south from point $A$ for $${l2}km$ reaching point $B$.`
      );
      question.addParagraph(
        "question",
        `a) Calculate how many kilometres it would take for the helicopter to fly back to point $O$.`
      );
      question.addParagraph(
        "question",
        `b) Calculate the area of the triangle formed by the path $OAB$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `$OB^2 = ${l1}^2 + ${l2}^2 - 2(${l1})(${l2})\\cos(${theta}^{\\circ})$`
      );
      question.addParagraph(
        "solution",
        `So $OB = \\sqrt{${working1} - ${working2}\\cos(${theta}^{\\circ})}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore OB = ${lengthOB.toPrecision(3)}km$`
      );

      question.addParagraph(
        "solution",
        `Area $= \\frac{1}{2}(${l1})(${l2})\\sin(${theta}^{\\circ})$`
      );
      question.addParagraph(
        "solution",
        `$= ${sinCoefficient.toString()}\\sin(${theta}^{\\circ})$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Area $= ${area.toPrecision(3)}km^2$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(lengthOB.toPrecision(3)),
        Math.abs(
          parseFloat(lengthOB.toPrecision(3)) -
            parseFloat(lengthOB.toPrecision(2))
        ),
        `a) $\\text{ } =$`
      );
      group2.addInput(
        parseFloat(area.toPrecision(3)),
        Math.abs(
          parseFloat(area.toPrecision(3)) - parseFloat(area.toPrecision(2))
        ),
        `b) $\\text{ } =$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometryProblems_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const person = MCMisc.getName();

    const distanceOA = MCRandom.randomInt(300, 500);

    const trainTimeHours = MCRandom.randomInt(20, 40) / 10;
    const trainAverageSpeed = MCRandom.randomInt(110, 225);

    const distanceAB = trainAverageSpeed * trainTimeHours;

    const theta_1_Degrees = MCRandom.randomInt(100, 120);
    const theta_1_Radians = theta_1_Degrees * (Math.PI / 180);

    const distanceOB = Math.sqrt(
      distanceOA ** 2 +
        distanceAB ** 2 -
        2 * distanceOA * distanceAB * Math.cos(theta_1_Radians)
    );

    const cosTheta_2 =
      (distanceOA ** 2 +
        parseFloat(distanceOB.toFixed(0)) ** 2 -
        parseFloat(distanceAB.toFixed(0)) ** 2) /
      (2 * distanceOA * parseFloat(distanceOB.toFixed(0)));
    const theta_2_Radians = Math.acos(cosTheta_2);
    const theta_2_Degrees = theta_2_Radians * (180 / Math.PI);

    const area =
      (1 / 2) *
      distanceOA *
      parseFloat(distanceOB.toFixed(0)) *
      Math.sin(parseFloat(theta_2_Degrees.toFixed(0)) * (Math.PI / 180));

    /* END VARIABLES AND CONSTANTS */
    if (distanceOB > distanceOA && distanceOB < 1000) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `${person.name} is travelling from France to the UK for business.`
      );
      question.addParagraph(
        "question",
        `${person.name} flies $${distanceOA}km$ by plane from point $O$ to point $A$.`
      );
      question.addParagraph(
        "question",
        `${person.HeShe} then travels by train from point $A$ to point $B$.`
      );
      question.addParagraph(
        "question",
        `Given that it takes ${person.name} $${trainTimeHours}$ hours at an average speed of $${trainAverageSpeed}km/h$ to get from points $A$ to $B$.`
      );
      question.addParagraph("question", `a) Find the distance $AB$.`);
      question.addParagraph(
        "question",
        `${person.name} flies $${distanceOB.toFixed(
          0
        )}km$ back to point $O$ by plane.`
      );
      question.addParagraph(
        "question",
        `b) Calculate the angle between points $A$ and $B$ when at point $O$.`
      );
      question.addParagraph(
        "question",
        `Hence, or otherwise, find the area of the triangle formed by the path $OAB$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to the nearest whole number.`
      );

      question.addParagraph(
        "solution",
        `a) $speed = \\frac{distance}{time} \\implies distance = speed \\times time$`
      );
      question.addParagraph(
        "solution",
        `So $AB = ${trainAverageSpeed} \\times ${trainTimeHours}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore AB = ${distanceAB.toFixed(0)}km$`
      );

      question.addParagraph(
        "solution",
        `b) $AB^2 = OA^2 + OB^2 - 2(OA)(OB)\\cos(\\theta)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos(\\theta) = \\frac{OA^2 + OB^2 - AB^2}{2(OA)(OB)}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\cos(\\theta) = \\frac{${distanceOA}^2 + ${distanceOB.toFixed(
          0
        )}^2 - ${distanceAB.toFixed(
          0
        )}^2}{2(${distanceOA})(${distanceOB.toFixed(0)})}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = \\cos^{-1}\\left(\\frac{${distanceOA}^2 + ${distanceOB.toFixed(
          0
        )}^2 - ${distanceAB.toFixed(
          0
        )}^2}{2(${distanceOA})(${distanceOB.toFixed(0)})}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle \\theta = ${theta_2_Degrees.toFixed(0)}^{\\circ}$`
      );

      question.addParagraph(
        "solution",
        `c) Area $= \\frac{1}{2}(${distanceOA})(${distanceOB.toFixed(
          0
        )})\\sin(${theta_2_Degrees.toFixed(0)}^{\\circ})$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Area $= ${area.toFixed(0)}km^{2}$`
      );

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(
        parseFloat(distanceAB.toFixed(0)),
        1,
        `a) $\\text{ } AB =$`
      );
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(
        parseFloat(theta_2_Degrees.toFixed(0)),
        1,
        `b) $\\text{ } \\theta =$`
      );
      const group3 = new MCQuestion.InputGroup(1);
      group3.addInput(
        parseFloat(area.toFixed(0)),
        100,
        `a) $\\text{ }$ Area $=$`
      );

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometryProblems_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha_0 = MCRandom.randomInt(2, 5);
    const beta_0 = MCRandom.randomInt(2, 5);

    const amplitudeMultiplier_Percentage = MCRandom.randomInt(10, 30) * 10;
    const amplitudeMultiplier = amplitudeMultiplier_Percentage / 100;

    const alpha_1 = amplitudeMultiplier * alpha_0;
    const beta_1 = beta_0;

    const frequencyMultiplier_Percentage = MCRandom.randomInt(5, 99);
    const frequencyMultiplier = frequencyMultiplier_Percentage / 100;

    const alpha_2 = alpha_1;
    const beta_2 = beta_0 * frequencyMultiplier;

    const x0 = new MCMaths.Fraction(30 / beta_2);

    const h_of_x0 = alpha_2 / 2;

    /* END VARIABLES AND CONSTANTS */
    if (`${x0.numerator}`.length <= 4) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A wave machine generates waves with the function:`
      );
      question.addParagraph("question", `$f(x) = ${alpha_0}\\sin(${beta_0}x)$`);
      question.addParagraph(
        "question",
        `The wave machine's amplitude is increased by $${amplitudeMultiplier_Percentage}\\%$ and therefore uses a new function $g(x)$ in the form:`
      );
      question.addParagraph("question", `$g(x) = \\alpha_1 \\sin(\\beta_1 x)$`);
      question.addParagraph(
        "question",
        `a) Find the values of $\\alpha_1$ and $\\beta_1$.`
      );
      question.addParagraph(
        "question",
        `The wave machine's frequency is decreased so that it's new frequency is now only $${frequencyMultiplier_Percentage}\\%$ of it's original frequency.`
      );
      question.addParagraph(
        "question",
        `Subsequently the wave machine now adopts another new function $h(x)$ where $h(x) = \\alpha_2\\sin(\\beta_2 x)$`
      );
      question.addParagraph(
        "question",
        `b) Find the values of $\\alpha_2$ and $\\beta_2$.`
      );
      question.addParagraph(
        "question",
        `c) Hence, or otherwise, find the height of the wave at $x = ${x0.toString()}$`
      );

      question.addParagraph(
        "solution",
        `a) If the amplitude has increased by $${amplitudeMultiplier_Percentage}\\%$ then the new function $g(x)$ is:`
      );
      question.addParagraph(
        "solution",
        `$g(x) = (${amplitudeMultiplier} \\times ${alpha_0})\\sin(${beta_0}x)$`
      );
      question.addParagraph(
        "solution",
        `$ = ${alpha_1.toFixed(3)}\\sin(${beta_1}x)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\alpha_1 = ${alpha_1.toFixed(
          3
        )}, \\text{ } \\beta_1 = ${beta_1}$`
      );

      question.addParagraph(
        "solution",
        `b) If the frequency has decreased to $${frequencyMultiplier_Percentage}\\%$ of its original frequency then the new function $h(x)$ is:`
      );
      question.addParagraph(
        "solution",
        `$h(x) = ${alpha_1.toFixed(
          3
        )}\\sin((${frequencyMultiplier} \\times ${beta_0})x)$`
      );
      question.addParagraph(
        "solution",
        `$ = ${alpha_2.toFixed(3)}\\sin(${beta_2.toFixed(3)}x)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore \\alpha_2 = ${alpha_2.toFixed(
          3
        )}, \\text{ } \\beta_2 = ${beta_2.toFixed(3)}$`
      );

      question.addParagraph(
        "solution",
        `c) $h \\left( ${x0.toString()} \\right) = ${alpha_2.toFixed(
          3
        )}\\sin(${beta_2.toFixed(3)}\\left(${x0.toString()}\\right))$`
      );
      question.addParagraph("solution", `$= ${h_of_x0}$`);

      const group1 = new MCQuestion.InputGroup(2);
      const group2 = new MCQuestion.InputGroup(2);
      const group3 = new MCQuestion.InputGroup(1);

      group1.addInput(
        parseFloat(alpha_1.toFixed(3)),
        Math.abs(
          parseFloat(alpha_1.toFixed(3)) - parseFloat(alpha_1.toFixed(2))
        ),
        `a) $\\text{ } \\alpha_1 =$`
      );
      group1.addInput(
        parseFloat(beta_1.toFixed(3)),
        Math.abs(parseFloat(beta_1.toFixed(3)) - parseFloat(beta_1.toFixed(2))),
        `a) $\\text{ } \\beta_1 =$`
      );

      group2.addInput(
        parseFloat(alpha_2.toFixed(3)),
        Math.abs(
          parseFloat(alpha_2.toFixed(3)) - parseFloat(alpha_2.toFixed(2))
        ),
        `b) $\\text{ } \\alpha_2 =$`
      );
      group2.addInput(
        parseFloat(beta_2.toFixed(3)),
        Math.abs(parseFloat(beta_2.toFixed(3)) - parseFloat(beta_2.toFixed(2))),
        `b) $\\text{ } \\beta_2 =$`
      );

      group3.addInput(h_of_x0, 0.1, `c) $\\text{ } =$`);

      question.addInputGroup(group1);
      question.addInputGroup(group2);
      question.addInputGroup(group3);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometryProblems_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(2, 9);
    const gamma = MCRandom.randomInt(2, 9);

    const ans = gamma + alpha;

    /* END VARIABLES AND CONSTANTS */
    if (alpha !== gamma) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A particles $x$-position can be modelled by the equation:`
      );
      question.addParagraph(
        "question",
        `$x = ${alpha}t\\sin^{2}(${beta}t) + ${alpha}t\\cos^{2}(${beta}t) + ${gamma}t, \\text{ } t \\geq 0$`
      );
      question.addParagraph(
        "question",
        `Show that the velocity $(\\frac{dx}{dt})$ of the particle is constant and find its value.`
      );

      question.addParagraph(
        "solution",
        `$x = ${alpha}t\\sin^{2}(${beta}t) + ${alpha}t\\cos^{2}(${beta}t) + ${gamma}t$`
      );
      question.addParagraph(
        "solution",
        `$x = ${alpha}t(\\sin^{2}(t) + \\cos^{2}(t)) + ${gamma}t$`
      );
      question.addParagraph(
        "solution",
        `Since $\\sin^{2}(t) + \\cos^{2}(t) = 1$ the equation becomes:`
      );
      question.addParagraph("solution", `$x = ${alpha}t + ${gamma}t$`);
      question.addParagraph("solution", `$x = ${ans}t$`);
      question.addParagraph("solution", `$\\frac{dx}{dt} = ${ans}$`);
      question.addParagraph("solution", `$\\therefore v = ${ans}ms^{-1}$.`);

      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(ans, 0, `$v =$`);
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Trigonometry_TrigonometryProblems_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const alpha = MCRandom.randomInt(2, 9);
    const beta = MCRandom.randomInt(10, 60);

    const gammaNumerator = MCRandom.randomInt(2, 9);
    const gammaDenominator = MCRandom.randomInt(2, 9);
    const gamma = new MCMaths.Fraction(gammaNumerator / gammaDenominator);

    const working1 = new MCMaths.Fraction(gamma.toFloat() / alpha);
    const x1 = Math.asin(working1.toFloat()) * (180 / Math.PI) + beta;
    const x2 = 180 - x1;

    /* END VARIABLES AND CONSTANTS */
    if (gamma.toFloat() < 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A wave machine generates a wave with function $f(x) = ${alpha}\\sin(x-${beta}^{\\circ})$`
      );
      question.addParagraph(
        "question",
        `At point $x_1$ the wave has height $${gamma.toString()}$`
      );
      question.addParagraph(
        "question",
        `Find the $2$ possible values of $x_1$.`
      );
      question.addParagraph(
        "question",
        `Give your answers to $3$ significant figures.`
      );

      question.addParagraph(
        "solution",
        `Since at $x_1$ the wave has height $${gamma.toString()}$ this implies that:`
      );
      question.addParagraph("solution", `$f(x_1) = ${gamma.toString()}$`);
      question.addParagraph(
        "solution",
        `$${alpha}\\sin(x_1-${beta}) = ${gamma.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$\\sin(x_1 = ${beta} = ${working1.toString()}$`
      );
      question.addParagraph(
        "solution",
        `$x_1 - ${beta}= \\sin^{-1}\\left(${working1.toString()}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$x_1 = \\sin^{-1}\\left(${working1.toString()}\\right) + ${beta}$`
      );
      question.addParagraph("solution", `$x_1 = ${x1.toPrecision(3)}$`);
      question.addParagraph(
        "solution",
        `Also $x_1 = 180 - \\left[\\sin^{-1}\\left(${working1.toString()}\\right) + ${beta}\\right]$`
      );
      question.addParagraph("solution", `$= ${x2.toPrecision(3)}$`);
      question.addParagraph(
        "solution",
        `$\\therefore x_1 = ${x1.toPrecision(
          3
        )}^{\\circ}$ or $\\text{ } x_1 = ${x2.toPrecision(3)}^{\\circ}$`
      );

      const group1 = new MCQuestion.InputGroup(2);
      group1.addInput(
        [parseFloat(x1.toPrecision(3)), parseFloat(x2.toPrecision(3))],
        Math.abs(parseFloat(x1.toPrecision(3)) - parseFloat(x1.toPrecision(2))),
        `$x_1 =$`
      );
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_VariableAcceleration_VariableAcceleration_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const m = MCRandom.randomInt(2, 8);
    const root = MCRandom.randomInt(2, 6);
    const notRoot = MCRandom.randomInt(-7, -2);
    const div = MCRandom.randomInt(1, 2) ** MCRandom.randomInt(-1, 1, 2);
    const v = MCMaths.Polynomial.generateFromRoots(
      [notRoot, root],
      "t"
    ).multiply(div);
    const s = v
      .integral()
      .add(
        new MCMaths.Polynomial(
          [(MCRandom.randomInt(10, 50) * MCRandom.randomInt(-1, 1, 2)) / 10],
          "t"
        )
      );
    const tmax = root + MCRandom.randomInt(1, 3);
    const a = v.derivative();
    const ansb = a.evaluate(root);
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A particle with mass $${m}kg$ is traveling along a straight path.`,
        `Its position, $(s) m$, at time $t$, relative to a fixed origin $O$, is given by $s=${s.toString()}$, $0\\le t \\le ${tmax}$.`,
        `a) Find the time where the particle has zero velocity.`,
        `b) Find acceleration of the particle when it has zero velocity.`,
      ]);

      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding Velocity:`,
        `$\\displaystyle v = \\frac{d}{dt}s$`,
        `$= \\left(${v.toString()}\\right)ms^{-1}$`,
        MCMaths.cleaner(
          `$= \\left(${div}x+${-div * notRoot}\\right)(x-${root})ms^{-1}$`
        ).replace(/x/g, "t"),
        `Zero Velocity at $t=${root}s$.`,
        `b) HEADING Finding Acceleration:`,
        `$\\displaystyle a = \\frac{d}{dt}v$`,
        `$=\\left(${a.toString()}\\right)ms^{-2}$`,
        `HEADING Evaluating:`,
        `$=${a.toString()}$`.replace(/t/g, `(${root})`),
        `$=${ansb}ms^{-2}$`,
      ]);
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(root, 0, "a)");
      group1.addInput(ansb, 0, "b)");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_VariableAcceleration_VariableAcceleration_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a1 = MCRandom.randomInt(1, 5);
    const b1 = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);
    const c1 = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);
    const v = new MCMaths.Polynomial([a1, b1, c1], "t");
    const t1 = MCRandom.randomInt(2, 6);
    const t2 = t1 + MCRandom.randomInt(2, 5);

    const ansa = v.derivative().evaluate(t1);
    const ansb = v.integral().evaluate(t2) - v.integral().evaluate(t1);
    /* END VARIABLES AND CONSTANTS */
    if (
      b1 * b1 < 4 * a1 * c1 &&
      ansb > 3 &&
      ansb < 30 &&
      Math.round(ansb * 100) === ansb * 100 &&
      ansa !== 0 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `The velocity of a particle moving in a straight line at a time $t$ seconds is given by $(${v.toString()})ms^{-1}$.`,
        `a) Find the acceleration of the particles at $t=${t1}$.`,
        `b) Find the distance traveled by the particles from $t=${t1}s$ to $t=${t2}s$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding Acceleration:`,
        `$\\displaystyle a=\\frac{d}{dt}v$`,
        `$=(${v.derivative()})ms^{-2}$`,
        `HEADING Evaluating:`,
        `$=(${v.derivative().toString().replace(/t/g, `(${t1})`)})ms^{-2}$`,
        `$=${ansa}ms^{-2}$`,
        `b) HEADING Finding Distance:`,
        `$\\displaystyle s=\\int_{${t1}}^{${t2}} v dt$`,
        `$\\displaystyle = \\left[${v
          .integral()
          .toString()} \\right]_{${t1}}^{${t2}}$`,
        `$=\\left(${v
          .integral()
          .toString()
          .replace(
            /t/g,
            `(${t2})`
          )}\\right)-\\left(${v
          .integral()
          .toString()
          .replace(/t/g, `(${t1})`)}\\right)$`,
        `$=${ansb}m$`,
      ]);
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(ansa, 0, "a)");
      group1.addInput(ansb, 0, "b)");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_VariableAcceleration_VariableAcceleration_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const r1 = -MCRandom.randomInt(2, 7);
    const r2 = MCRandom.randomInt(2, 7);
    const mul = -1 * MCRandom.randomInt(1, 3) ** MCRandom.randomInt(-1, 1, 2);
    const a = MCMaths.Polynomial.generateFromRoots([r1, r2], "t").multiply(mul);

    const v0 = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2);
    const v = a.integral();
    const v1 = v.add(new MCMaths.Polynomial([v0], "t"));

    const ansa = v1.evaluate(r2);

    const s = v1.integral();

    const ansb = s.evaluate(r2) - s.evaluate(0);
    /* END VARIABLES AND CONSTANTS */
    if (
      ansa > 1 &&
      ansa < 30 &&
      Math.round(ansa * 10) === ansa * 10 &&
      Math.round(ansb) === ansb &&
      ansb > 1 &&
      ansb < 30 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `The acceleration of a particle moving in a straight is given by $a=\\left(${a.toString()}\\right)ms^{-2}$ for time $t$ seconds.`,
        `a) Find the maximun velocity $v$ the particle achieves, given $v=${v0}$ at time $t=0$.`,
        `b) Find the distance traveled by the particles from time $t=0$ to the time it achieves its maximun velocity.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING Maximun Velocity is at $a=0ms^{-2}$:`,
        MCMaths.cleaner(
          `$a=\\left(${mul}t-${mul * r1}\\right)\\left(t-${r2}\\right)$`
        ),
        `HEADING Selecting Positive $t$:`,
        `$t=${r2}s$`,
        `HEADING Using $v=\\int a dt$:`,
        `$\\displaystyle  v= \\int ${a.toString()}dt$`,
        `$\\displaystyle = \\left(${v.toString()}+c\\right)ms^{-1}$`,
        `HEADING Using $v=${v0}$:`,
        `$0+c=${v0}$`,
        `$\\displaystyle v = \\left(${v1.toString()}\\right)ms^{-1}$`,
        `HEADING Evaluating at $t=${r2}$:`,
        `$v = ${v1.toString().replace(/t/g, `(${r2})`)}$`,
        `$=${ansa}ms^{-1}$`,
        `b) HEADING Using $s=\\int v dt$:`,
        `$\\displaystyle s = \\int_0^{${r2}} ${v1.toString()}dt$`,
        `$\\displaystyle =\\left[${s.toString()}\\right]_0^{${r2}}$`,
        `$\\displaystyle =\\left(${s
          .toString()
          .replace(/t/g, `(${r2})`)}\\right)-\\left(${s
          .toString()
          .replace(/t/g, `(0)`)}\\right)$`,
        `$=${ansb}m$`,
      ]);
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(ansa, 0, "a)");
      group1.addInput(ansb, 0, "b)");
      question.addInputGroup(group1);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_VariableAcceleration_VariableAcceleration_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const r0 = MCRandom.randomInt(-5, -1);
    const r1 = MCRandom.randomInt(1, 4);
    const r2 = r1 + MCRandom.randomInt(1, 4);
    const tmax = r2 + MCRandom.randomInt(1, 4);
    const v = MCMaths.Polynomial.generateFromRoots([r0, r1, r2], "t");

    const h0 = MCRandom.randomInt(1, 30);
    const s = v.integral().add(new MCMaths.Polynomial([h0], "t"));

    const start = s.evaluate(0);
    const end = s.evaluate(tmax);
    const max = s.evaluate(r1);
    const min = s.evaluate(r2);

    const grp = new MCQuestion.Graph(tmax, 0, Math.ceil(max * 5) / 5, 0, 1, 5);
    grp.plot(s.functionString(), 0, tmax);

    const tc = Math.round((r1 + r2) / 2);

    const ansa = s.evaluate(r1);
    const ansb = s.evaluate(r2);

    const a = v.derivative();

    const ansc = a.evaluate(tc);
    /* END VARIABLES AND CONSTANTS */
    if (
      min < end &&
      end < start &&
      start < max &&
      min > 0 &&
      min < 5 &&
      max < 40 &&
      Math.round(ansa * 100) ===
        ansa * 100 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addGraph("question", grp);

      question.addMultipleParagraphs("question", [
        `The verticle height of a helicopter is given by $h=\\left(${s.toString()}\\right)m$ for time $0\\le t \\le ${tmax}$.`,
        `Above is the displacement-time graph of the helicopter's motion.`,
        `a) Find the maximun height of the helicopter.`,
        `b) Find the minimun height of the helicopter.`,
        `c) Find the vertical acceleration of the helicopter at $t=${tc}s$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING Finding Derivative:`,
        `$\\displaystyle v = \\frac{d}{dt}h$`,
        `$\\displaystyle = \\left(${v.toString()} \\right)ms^{-1}$`,
        `HEADING Using Factor Theorem:`,
        `$v(${r1})=0$`,
        `$\\therefore t=${r1}$ is a turning point.`,
        `HEADING Finding Height:`,
        `$=${s.toString().replace(/t/g, `(${r1})`)}$`,
        `$=${ansa}m$`,
        `b)HEADING Using Factor Theorem:`,
        `$v(${r2})=0$`,
        `$\\therefore t=${r2}$ is a turning point.`,
        `HEADING Finding Height:`,
        `$=${s.toString().replace(/t/g, `(${r2})`)}$`,
        `$=${ansb}m$`,
        `c)HEADING Finding Second Derivative:`,
        `$\\displaystyle a=\\frac{d}{dt} v$`,
        `$=\\left(${a.toString()}\\right)ms^{-2}$`,
        `HEADING Evaluating:`,
        `$=(${a.toString().replace(/t/g, `(${tc})`)}$`,
        `$=${ansc}ms^{-2}$`,
      ]);
      const group1 = new MCQuestion.InputGroup(1);
      group1.addInput(ansa, 0, "a)");
      group1.addInput(ansb, 0, "b)");
      group1.addInput(ansc, 0, "c)");
      question.addInputGroup(group1);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_VariableAcceleration_VariableAcceleration_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A particle accelerates at a constant rate of $a \\ ms^{-2}$. Given it has an initial speed of $u \\ ms^{-1}$, and is initially at the origin $s=0$, prove:`,
        `a) The velocity $v$ of the particle is given by $v=u+at$.`,
        `b) The displacement $s$ of the particle is given by $s=ut+\\frac{1}{2}at^2$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING $v=\\int a dt$`,
        `$=at + c$`,
        `At $t=0$, $v=u$, so $c=u$`,
        `$\\therefore v=u+at$`,
        `b) HEADING $s=\\int vdt$`,
        `$s=\\int u+at dt$`,
        `$=ut+\\frac{1}{2}at^2+c$`,
        `At $t=0$, $s=0$, so $c=0$`,
        `$\\therefore s=ut+\\frac{1}{2}at^2$`,
      ]);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorProblems_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(100, 900, 100);
    const at = MCRandom.randomInt(-1, 1, 2);
    let aName = "north";
    if (at === -1) {
      aName = "south";
    }
    let ad = a;
    if (at === -1) {
      ad = `(-${a})`;
    }
    const b = MCRandom.randomInt(100, 900, 100);
    const bt = MCRandom.randomInt(-1, 1, 2);
    let bName = "east";
    if (bt === -1) {
      bName = "west";
    }
    let bd = b;
    if (bt === -1) {
      bd = `(-${b})`;
    }

    const partA = new MCMaths.Vector(b * bt, a * at);

    const partB = Math.sqrt(a ** 2 + b ** 2);
    let partC;
    /* END VARIABLES AND CONSTANTS */
    if (
      at + bt !== 2 &&
      Math.round(partB) ===
        partB /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A Student walks $${a}m$ due ${aName}, stops, and walks $${b}m$ due ${bName}.`,
        `a) Find the position vector of the student's final location.`,
        `b) Find the distance travelled, as the crow flies, to the nearest metre.`,
        `c) Find the bearing of the students final location.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) $= ${partA.toString()}$`,
        `b) $= \\left|${partA.toString()}\\right|$`,
        `$= \\sqrt{${ad}^2+${bd}^2}$`,
        `$=${partB}m$`,
      ]);
      if (bt === 1 && at === -1) {
        partC = Math.round(180 - (Math.atan(b / a) * 180) / Math.PI);
        question.addMultipleParagraphs("solution", [
          `c) $= 180 - \\tan^{-1}\\left(\\frac{${b}}{${a}}\\right)$`,
          `$= ${partC}^\\circ$`,
        ]);
      }

      if (bt === -1 && at === 1) {
        partC = Math.round(360 - (Math.atan(b / a) * 180) / Math.PI);
        question.addMultipleParagraphs("solution", [
          `c) $= 360 - \\tan^{-1}\\left(\\frac{${b}}{${a}}\\right)$`,
          `$= ${partC}^\\circ$`,
        ]);
      }

      if (bt === -1 && at === -1) {
        partC = Math.round(180 + (Math.atan(b / a) * 180) / Math.PI);
        question.addMultipleParagraphs("solution", [
          `c) $= 180 + \\tan^{-1}\\left(\\frac{${b}}{${a}}\\right)$`,
          `$= ${partC}^\\circ$`,
        ]);
      }
      const group = new MCQuestion.InputGroup(2);
      group.addInput(b * bt, 0, "a) $\\ \\mathbf{i} = $");
      group.addInput(a * at, 0, "a) $\\ \\mathbf{j} = $");
      question.addInputGroup(group);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(partB, 0, "b)");
      group2.addInput(partC, 0, "c)");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorProblems_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const p = new MCMaths.Vector(a, b);
    const c = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const d = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const q = new MCMaths.Vector(c, d);
    const sTime = MCRandom.randomInt(9, 11);
    const fTime = MCRandom.randomInt(25, 55, 5);

    const working1 = q.sub(p);
    const wi = working1.getComponents()[0];
    const wj = working1.getComponents()[1];
    let wid = wi;
    if (wi < 0) {
      wid = `(${wi})`;
    }
    let wjd = wj;
    if (wj < 0) {
      wjd = `(${wj})`;
    }

    const working2 = Math.sqrt(wi ** 2 + wj ** 2);

    const ans2 = Math.round((working2 * 600) / fTime) / 10;
    /* END VARIABLES AND CONSTANTS */
    if (
      wi !== 0 &&
      wj !== 0 &&
      ans2 > 2 &&
      ans2 < 10 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A hot air balloon $H$ is drifting in the wind. It starts with position vector $(${p.toStringIJK()})km$ relative to an origin $O$ at $${sTime}am$.`,
        `A time $${sTime}$:$${fTime}am$, the balloon is at $(${q.toStringIJK()})km$.`,
        `Assuming constant wind speed:`,
        `a) Find the bearing on which the balloon is drifting.`,
        `b) Find the speed the balloon is drifting, in $kmh^{-1}$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding direction vector:`,
        `$= (${q.toStringIJK()})-(${p.toStringIJK()})$`,
        `$=(${working1.toStringIJK()})km$`,
      ]);
      question.addMultipleParagraphs("solution", working1.bearingWorking());
      question.addMultipleParagraphs("solution", [
        `b) HEADING Finding Magnitude:`,
        `$=\\sqrt{${wid}^2+${wjd}^2}$`,
        MCMaths.cleaner(`$=${working2}$`),
        `HEADING Finding Speed:`,
        MCMaths.cleaner(`Time = $\\displaystyle ${fTime / 60}$ hours.`),
        MCMaths.cleaner(`Speed $=${working2} \\div ${fTime / 60} $`),
        `$=${ans2}kmh^{-1}$.`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(working1.bearing(), 0, "a)");
      group.addInput(ans2, 0.1, "b)");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorProblems_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const OA = new MCMaths.Vector(a, b);
    const c = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const d = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const OB = new MCMaths.Vector(c, d);

    const r1 = MCRandom.randomInt(1, 5);
    const r2 = MCRandom.randomInt(1, 5);

    const ON = OA.multiply(r1 / (r1 + r2));
    const ni = ON.getComponents()[0];
    const nj = ON.getComponents()[1];

    const AB = OB.sub(OA);

    const NA = OA.sub(ON);

    const linEq = new MCMaths.Intersections(
      [OB.getComponents()[0], -AB.getComponents()[0], ON.getComponents()[0]],
      [OB.getComponents()[1], -AB.getComponents()[1], ON.getComponents()[1]],
      "k",
      "l"
    );

    const k = linEq.solutions()[0];

    const NM = ON.multiply(-1).add(OB.multiply(k));
    const NMi = NM.getComponents()[0];
    const NMj = NM.getComponents()[1];

    const angle = Math.abs(OA.bearing() - OB.bearing());
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(r1 / r2) !== r1 / r2 &&
      Math.round(r2 / r1) !== r2 / r1 &&
      Math.round(ni * 20) === ni * 20 &&
      Math.round(nj * 20) === nj * 20 &&
      OA.isParallel(OB) === false &&
      Math.round(NMi * 20) === NMi * 20 &&
      Math.round(NMj * 20) === NMj * 20 &&
      AB.getComponents()[0] !== 0 &&
      AB.getComponents()[1] !== 0 &&
      OB.getComponents()[0] !== 0 &&
      OB.getComponents()[1] !== 0 &&
      Math.abs(NMi) !== Math.abs(NMj) &&
      angle > 50 &&
      angle < 100 &&
      Math.abs(OB.magnitude() - OA.magnitude()) <
        2 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `In a triangle $OAB$, $\\overrightarrow{OA}=${OA.toStringIJK()}$, $\\overrightarrow{OB}=${OB.toStringIJK()}$. $M$ is a point on $\\overrightarrow{OA}$, $N$ a point on $\\overrightarrow{OB}$.`,
        `Given $\\overrightarrow{MN}$ is parallel to $\\overrightarrow{AB}$, and that $N$ divides $\\overrightarrow{OA}$ in the ratio $${r1}:${r2}$:`,
        `a) Find the vector $\\overrightarrow{ON}$.`,
        `b) Find the vector $\\overrightarrow{NM}$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding $\\overrightarrow{ON}$:`,
        `$\\displaystyle = \\frac{${r1}}{${r1}+${r2}}(${OA.toStringIJK()})$`,
        `$=${ON.toStringIJK()}$`,
        `b) HEADING $\\overrightarrow{NM} = -\\overrightarrow{ON} + k\\overrightarrow{OB}$, for some $k\\in\\mathbb{R}$.`,
        `$= -(${ON.toStringIJK()})+k(${OB.toStringIJK()})$`,
        `HEADING Finding $\\overrightarrow{AB}$:`,
        `$\\overrightarrow{AB}=-\\overrightarrow{OA}+\\overrightarrow{OB}$`,
        `$=-(${OA.toStringIJK()})+(${OB.toStringIJK()})$`,
        `$=${AB.toStringIJK()}$`,
        `HEADING Forming Equation:`,
        `$ -(${ON.toStringIJK()})+k(${OB.toStringIJK()}) = l(${AB.toStringIJK()})$, for some $l\\in\\mathbb{R}$.`,
        `${linEq.toStringArray()[0]}`,
        `${linEq.toStringArray()[1]}`,
        `HEADING Solving Simultaneously:`,
      ]);
      question.addMultipleParagraphs("solution", linEq.solutionsWorking());
      question.addMultipleParagraphs("solution", [
        `HEADING Substituting $k$:`,
        MCMaths.cleaner(
          `$\\overrightarrow{NM} = -(${ON.toStringIJK()})+${k}(${OB.toStringIJK()})$`
        ),
        `$=${NM.toStringIJK()}$.`,
      ]);
      const g = new MCQuestion.Graph(5, -5, 5, -5, 1, 1);
      g.addParametric(`${a} * t`, `${b} * t`, 0, 1);
      g.addParametric(`${c} * t`, `${d} * t`, 0, 1);
      g.addParametric(
        `${a} + ${AB.getComponents()[0]} * t`,
        `${b} + ${AB.getComponents()[1]} * t`,
        0,
        1
      );
      g.addParametric(
        `${ON.getComponents()[0]} + ${NM.getComponents()[0]} * t`,
        `${ON.getComponents()[1]} + ${NM.getComponents()[1]} * t`,
        0,
        1
      );
      question.addGraph("solution", g);

      const group = new MCQuestion.InputGroup(2);
      group.addInput(ON.getComponents()[0], 0, "a) $\\ \\mathbf{i} = $");
      group.addInput(ON.getComponents()[1], 0, "a) $\\ \\mathbf{j} = $");
      group.addInput(NM.getComponents()[0], 0, "b) $\\ \\mathbf{i} = $");
      group.addInput(NM.getComponents()[1], 0, "b) $\\ \\mathbf{j} = $");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorProblems_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const OA = new MCMaths.Vector(
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2)
    );
    const AB = new MCMaths.Vector(
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2)
    );
    const BC = new MCMaths.Vector(
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2)
    );
    const OB = OA.add(AB);
    const OC = OA.add(AB).add(BC);
    const AC = OC.sub(OA);

    const a1 = OA.angle(AB);
    const a2 = AB.angle(BC);
    const a3 = OC.angle(OA);

    const linEq = new MCMaths.Intersections(
      [OB.getComponents()[0], -AC.getComponents()[0], OA.getComponents()[0]],
      [OB.getComponents()[1], -AC.getComponents()[1], OA.getComponents()[1]]
    );

    const x = linEq.solutions()[0];
    const xd = MCMaths.cleaner(`${x}`);
    const OP = OB.multiply(x);
    const pi = OP.getComponents()[0];
    const pj = OP.getComponents()[1];
    /* END VARIABLES AND CONSTANTS */
    if (
      OC.magnitude() <= 5 &&
      OB.magnitude() <= 5 &&
      OB.magnitude() >= 3 &&
      a1 < 120 &&
      a1 > 30 &&
      a2 < 120 &&
      a2 > 30 &&
      a3 < 120 &&
      a3 > 30 &&
      AC.magnitude() >= 7 &&
      OB.getComponents()[0] !== 0 &&
      OB.getComponents()[1] !== 0 &&
      AC.getComponents()[0] !== 0 &&
      AC.getComponents()[1] !== 0 &&
      Math.round(pi * 20) / 20 === pi &&
      Math.round(pj * 20) / 20 === pj &&
      linEq.solutionsWorkingTest() ===
        true /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `In a quadrilateral $OABC$, $\\overrightarrow{OA}=${OA.toStringIJK()}$, $\\overrightarrow{AB}=${AB.toStringIJK()}$, $\\overrightarrow{BC}=${BC.toStringIJK()}$.`,
        `$P$ is the point at the intersection of $\\overrightarrow{OB}$ and $\\overrightarrow{AC}$.`,
        `a) Find $\\overrightarrow{OC}$.`,
        `b) Find $\\overrightarrow{OP}$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a)HEADING $\\overrightarrow{OC}=\\overrightarrow{OA}+\\overrightarrow{AB}+\\overrightarrow{BC}$`,
        `$=(${OA.toStringIJK()})+(${AB.toStringIJK()})+(${BC.toStringIJK()})$`,
        `$=${OC.toStringIJK()}$`,
        `b) HEADING Finding $\\overrightarrow{OB}$:`,
        `$=\\overrightarrow{OA}+\\overrightarrow{AB}$`,
        `$=(${OA.toStringIJK()})+(${AB.toStringIJK()})$`,
        `$=${OB.toStringIJK()}$`,
        `HEADING Finding $\\overrightarrow{AC}$:`,
        `$=-\\overrightarrow{OA}+\\overrightarrow{OC}$`,
        `$=-(${OA.toStringIJK()})+(${OC.toStringIJK()})$`,
        `$=${AC.toStringIJK()}$`,
        `HEADING Forming Equation:`,
        `$x(\\overrightarrow{OB})=\\overrightarrow{OA}+y(\\overrightarrow{AC})$, for $x,y\\in\\mathbb{R}$.`,
        `$x${OB.toString()}=${OA.toString()}+y${AC.toString()}$`,
        `${linEq.toStringArray()[0]}`,
        `${linEq.toStringArray()[1]}`,
        `HEADING Solving Simultaneously:`,
      ]);
      question.addMultipleParagraphs("solution", linEq.solutionsWorking());
      question.addMultipleParagraphs("solution", [
        `HEADING Finding Vector:`,
        `$\\overrightarrow{OP}=x(\\overrightarrow{OB})$`,
        `$=${xd}${OB.toString()}$`,
        `$=${OP.toString()}$`,
        `$=${OP.toStringIJK()}$`,
      ]);
      const g = new MCQuestion.Graph(5, -5, 5, -5, 1, 1);
      g.addParametric(
        `${OA.getComponents()[0]} * t`,
        `${OA.getComponents()[1]} * t`,
        0,
        1
      );
      g.addParametric(
        `${OC.getComponents()[0]} * t`,
        `${OC.getComponents()[1]} * t`,
        0,
        1
      );
      g.addParametric(
        `${OA.getComponents()[0]} + ${AB.getComponents()[0]} * t`,
        `${OA.getComponents()[1]} + ${AB.getComponents()[1]} * t`,
        0,
        1
      );
      g.addParametric(
        `(${OA.getComponents()[0]} +${AB.getComponents()[0]} +${
          BC.getComponents()[0]
        } * t)`,
        `(${OA.getComponents()[1]} +${AB.getComponents()[1]} +${
          BC.getComponents()[1]
        } * t)`,
        0,
        1
      );
      g.addParametric(
        `${OA.getComponents()[0]} + ${AC.getComponents()[0]} * t`,
        `${OA.getComponents()[1]} + ${AC.getComponents()[1]} * t`,
        0,
        1
      );
      g.addParametric(
        `${OB.getComponents()[0]} * t`,
        `${OB.getComponents()[1]} * t`,
        0,
        1
      );
      g.addParametric(
        `${OP.getComponents()[0]} * t`,
        `${OP.getComponents()[1]} * t`,
        0,
        1
      );

      question.addGraph("solution", g);

      const group = new MCQuestion.InputGroup(2);
      group.addInput(OC.getComponents()[0], 0, "a) $\\ \\mathbf{i} = $");
      group.addInput(OC.getComponents()[1], 0, "a) $\\ \\mathbf{j} = $");
      group.addInput(pi, 0, "b) $\\ \\mathbf{i} = $");
      group.addInput(pj, 0, "b) $\\ \\mathbf{j} = $");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorProblems_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const u = new MCMaths.Vector(
      MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2)
    );
    const v = new MCMaths.Vector(
      MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2)
    );
    const t = MCRandom.randomInt(2, 4);
    const a = v.sub(u).multiply(1 / t);
    const ai = a.getComponents()[0];
    const aj = a.getComponents()[1];
    let aid = ai;
    if (ai < 0) {
      aid = `(${ai})`;
    }
    let ajd = aj;
    if (aj < 0) {
      ajd = `(${aj})`;
    }
    const mag = a.magnitude();
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(ai) === ai &&
      Math.round(aj) === aj &&
      Math.round(mag) === mag &&
      aj !== 0 &&
      ai !== 0 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A student is modelling the motion of a car as a particle.`,
        `At $t=0$, the velocity of the car is $\\mathbf{u}=(${u.toStringIJK()})ms^{-1}$`,
        `At $t=${t}s$, the velocity of the car is $\\mathbf{v}=(${v.toStringIJK()})ms^{-1}$`,
        `The acceleration vector of the car is given by $\\displaystyle \\mathbf{a} = \\frac{\\mathbf{v}-\\mathbf{u}}{t}$.`,
        `a) Find the acceleration vector.`,
        `b) Find the magnitude of $\\mathbf{a}$.`,
        `c) Find the bearing of $\\mathbf{a}$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING $\\displaystyle \\frac{\\mathbf{v}-\\mathbf{u}}{t} = \\frac{(${v.toStringIJK()})-(${u.toStringIJK()})}{${t}}$`,
        `$\\displaystyle =\\frac{${v.sub(u).toStringIJK()}}{${t}}$`,
        `$=${a.toStringIJK()}ms^{-2}$`,
        `b) HEADING Finding Magnitude:`,
        `$|\\mathbf{a}|=\\sqrt{${aid}^2+${ajd}^2}$`,
        `$=${mag}ms^{-2}$`,
        `c) HEADING Finding Bearing:`,
        a.bearingWorking()[1],
        a.bearingWorking()[2],
      ]);
      const group = new MCQuestion.InputGroup(2);
      group.addInput(ai, 0, "a) $\\ \\mathbf{i} = $");
      group.addInput(aj, 0, "a) $\\ \\mathbf{j} = $");
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(mag, 0, "b) ");
      group2.addInput(a.bearing(), 0, "c) ");
      question.addInputGroup(group);
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorProblems_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const A = new MCMaths.Vector(
      MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2)
    );
    const B = new MCMaths.Vector(
      MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 10)
    );
    const bi = B.getComponents()[0];
    const bj = B.getComponents()[1];
    const C = new MCMaths.Vector(
      MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2)
    );
    const R = A.add(B);
    const R2 = A.add(B).add(C);
    const br = R2.bearing(false);

    const R2i = R2.getComponents()[0];
    const R2j = R2.getComponents()[1];

    function gcd(a1, b1) {
      const a = Math.abs(a1);
      const b = Math.abs(b1);
      if (!b) {
        return a;
      }
      return gcd(b, a % b);
    }
    let div = gcd(R2i, R2j);
    if (R2i === 0) {
      div = R2j;
    }
    if (R2j === 0) {
      div = R2i;
    }
    let Rpar = R2.multiply(1 / div);
    if (R2.getComponents()[0] < 0) {
      Rpar = new MCMaths.Vector(
        -Rpar.getComponents()[0],
        Rpar.getComponents()[1]
      );
    }
    if (R2.getComponents()[1] < 0) {
      Rpar = new MCMaths.Vector(
        Rpar.getComponents()[0],
        -Rpar.getComponents()[1]
      );
    }

    let Rparid = Rpar.getComponents()[0];
    if (Rparid < 0) {
      Rparid = `(${Rparid})`;
    }
    let Rparjd = Rpar.getComponents()[1];
    if (Rparjd < 0) {
      Rparjd = `(${Rparjd})`;
    }

    const ans2 =
      Math.round((Math.abs(Math.atan(bi / bj)) * 1800) / Math.PI) / 10;

    const magR2 = R2.magnitude();
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(br) === br &&
      br !== 0 &&
      Rpar !== R2 &&
      Math.round(magR2) === magR2 &&
      Math.abs(div) > 1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A student is looking at forces acting on a particle $p$. Two forces act on the particle, $\\mathbf{A}$ and $\\mathbf{B}$.`,
        `$\\mathbf{A} = (${A.toStringIJK()})N$.`,
        `$\\mathbf{B} = (x\\mathbf{i}+y\\mathbf{j})N$.`,
        `Given the resultant force on the particle is $(${R.toStringIJK()})N$,`,
        `a) Find $\\mathbf{B}$.`,
        `b) Find the angle $\\mathbf{B}$ makes with $\\mathbf{j}$.`,
        `c) Given after a third force $\\mathbf{C}$ is applied, the resultant force has a bearing of $${br}^\\circ$ and magnitude $${magR2}N$, find $\\mathbf{C}$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING $\\mathbf{B} = ${R.toStringIJK()} - \\mathbf{A}$`,
        `$=(${B.toStringIJK()})N$.`,
        `b) HEADING $=\\tan^{-1}\\left(\\frac{${Math.abs(bi)}}{${Math.abs(
          bj
        )}}\\right)$`,
        `$\\approx ${ans2}^\\circ$.`,
        `c) HEADING Finding parallel vector:`,
        `Bearing of $${Rpar.toStringIJK()} =${br}$.`,
        `HEADING Finding vector with magnitude $${magR2}$:`,
        `$k\\sqrt{${Rparid}^2 + ${Rparjd}^2}=${magR2}$`,
        `$k=${Math.abs(div)}$`,
        `Resultant vector is $${R2.toStringIJK()}$.`,
        `Finding $\\mathbf{c}$:`,
        `$\\mathbf{C} = (${R2.toStringIJK()}) - (${R.toStringIJK()})$`,
        `$=(${C.toStringIJK()})N$`,
      ]);
      const group = new MCQuestion.InputGroup(2);
      group.addInput(bi, 0, "a) $\\ \\mathbf{i} = $");
      group.addInput(bj, 0, "a) $\\ \\mathbf{j} = $");
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(ans2, 0.1, "b) ");
      const group3 = new MCQuestion.InputGroup(2);
      group3.addInput(C.getComponents()[0], 0, "c) $\\ \\mathbf{i} = $");
      group3.addInput(C.getComponents()[1], 0, "c) $\\ \\mathbf{j} = $");
      question.addInputGroup(group);
      question.addInputGroup(group2);
      question.addInputGroup(group3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorProblems_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const h = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const a = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const b = a + MCRandom.randomInt(1, 5);
    const c = MCRandom.randomInt(2, 5);

    const ratio = c / (b - a);
    const r2 = new MCMaths.Fraction(ratio).numerator;
    const r1 = new MCMaths.Fraction(ratio).denominator;

    let r1d = r1;
    let r2d = r2;
    if (r1 === 1) {
      r1d = "";
    }
    if (r2 === 1) {
      r2d = "";
    }

    const P = new MCMaths.Vector(a, h);
    const Q = new MCMaths.Vector(b, h);
    const R = new MCMaths.Vector(c, 0);

    const PQ = Q.sub(P);

    const area = (Math.abs(h) * (b - a + c)) / 2;

    const QR = R.sub(Q);

    /* END VARIABLES AND CONSTANTS */
    if (
      Q.magnitude() > 2 &&
      Q.magnitude() < 15 &&
      b <= 5 &&
      ratio !== 1 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `A trapezium $OPQR$ has area $${area}$.`,
        `$\\overrightarrow{OP}=${P.toStringIJK()}$, and $${r1d}\\overrightarrow{OR}=${r2d}\\overrightarrow{PQ}$.`,
        `The bearing of $\\overrightarrow{OR}$ is $90^\\circ$.`,
        `Find $\\overrightarrow{QR}.$`,
      ]);
      const g = new MCQuestion.Graph(5, -5, 5, -5, 1, 1);
      g.addParametric(`${a} * t`, `${h} * t`, 0, 1);
      g.addParametric(`${a} + (${b} - ${a}) * t`, `${h}`, 0, 1);
      g.addParametric(`${b} + (${c} - ${b}) * t`, `${h} * (1 - t)`, 0, 1);
      g.addParametric(`${c} * t`, `0`, 0, 1);

      question.addMultipleParagraphs("solution", [
        `HEADING Using Area:`,
        `Trapezium Area $\\displaystyle = \\frac{a+b}{2}\\cdot h$`,
        `$h=\\mathbf{j}$ component of $\\overrightarrow{OP}$`,
        `$=${Math.abs(h)}$.`,
        `$\\displaystyle \\frac{a+b}{2}\\cdot h = \\frac{|\\overrightarrow{OR}|+|\\overrightarrow{PQ}|}{2}\\cdot ${Math.abs(
          h
        )}$`,
        MCMaths.cleaner(
          `$\\displaystyle \\frac{a+b}{2}\\cdot h = \\left(|\\overrightarrow{OR}|+${
            r1 / r2
          }|\\overrightarrow{OR}|\\right)\\cdot ${Math.abs(h) / 2}$`
        ),
        MCMaths.cleaner(
          `$\\displaystyle ${area} = ${
            (Math.abs(h) / 2) * (1 + r1 / r2)
          }|\\overrightarrow{OR}|$`
        ),
        `$|\\overrightarrow{OR}|=${c}$`,
        `HEADING Using bearing:`,
        `$90^\\circ$ bearing $\\Rightarrow \\overrightarrow{OR}$ multiple of $\\mathbf{i}$`,
        `$\\therefore \\overrightarrow{OR} = ${R.toStringIJK()}$`,
        `HEADING Finding $\\overrightarrow{PQ}$:`,
        `$${r1d}\\overrightarrow{OR}=${r2d}\\overrightarrow{PQ}$`,
        `$\\overrightarrow{PQ}=${PQ.toStringIJK()}$`,
        `HEADING Finding $\\overrightarrow{OQ}$:`,
        `$=\\overrightarrow{OP}+\\overrightarrow{PQ}$`,
        `$=(${P.toStringIJK()})+(${PQ.toStringIJK()})$`,
        `$=${Q.toStringIJK()}$`,
        `HEADING Finding $\\overrightarrow{QR}$:`,
        `$=-\\overrightarrow{OQ}+\\overrightarrow{OR}$`,
        `$=-(${Q.toStringIJK()})+(${R.toStringIJK()})$`,
        `$=${QR.toStringIJK()}$`,
      ]);
      question.addGraph("solution", g);
      const group = new MCQuestion.InputGroup(2);
      group.addInput(QR.getComponents()[0], 0, "$\\ \\mathbf{i} = $");
      group.addInput(QR.getComponents()[1], 0, "$\\ \\mathbf{j} = $");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorSkills_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = new MCMaths.Vector(
      MCRandom.randomInt(1, 7) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 7) * MCRandom.randomInt(-1, 1, 2)
    );
    const b = new MCMaths.Vector(
      MCRandom.randomInt(1, 7) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 7) * MCRandom.randomInt(-1, 1, 2)
    );

    const m1 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const m2 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const m3 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const m4 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const m5 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const m6 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);

    const ans1 = a.multiply(m1).add(b.multiply(m2)).getComponents()[0];
    const ans2 = a.multiply(m1).add(b.multiply(m2)).getComponents()[1];
    const ans3 = a.multiply(m3).add(b.multiply(m4)).getComponents()[0];
    const ans4 = a.multiply(m3).add(b.multiply(m4)).getComponents()[1];
    const ans5 = a.multiply(m5).add(b.multiply(m6)).getComponents()[0];
    const ans6 = a.multiply(m5).add(b.multiply(m6)).getComponents()[1];
    /* END VARIABLES AND CONSTANTS */
    if (
      a !== b &&
      Math.abs(ans1) <= 10 &&
      Math.abs(ans2) <= 10 &&
      Math.abs(ans3) <= 10 &&
      Math.abs(ans4) <= 10 &&
      Math.abs(ans5) <= 10 &&
      Math.abs(ans6) <= 10 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given $\\mathbf{a}=${a.toStringIJK()}, \\ \\mathbf{b}=${b.toStringIJK()}$, find:`,
        MCMaths.cleaner(`a) $${m1}\\mathbf{a}+${m2}\\mathbf{b}$`),
        MCMaths.cleaner(`b) $${m3}\\mathbf{a}+${m4}\\mathbf{b}$`),
        MCMaths.cleaner(`c) $${m5}\\mathbf{a}+${m6}\\mathbf{b}$`),
      ]);
      question.addMultipleParagraphs("solution", [
        MCMaths.cleaner(
          `a)HEADING $ = ${m1}(${a.toStringIJK()})+${m2}(${b.toStringIJK()})$`
        ),
        MCMaths.cleaner(
          `$ = (${a.multiply(m1).toStringIJK()})+(${b
            .multiply(m2)
            .toStringIJK()})$`
        ),
        `$= ${a.multiply(m1).add(b.multiply(m2)).toStringIJK()}$`,
        MCMaths.cleaner(
          `b)HEADING $ = ${m3}(${a.toStringIJK()})+${m4}(${b.toStringIJK()})$`
        ),
        MCMaths.cleaner(
          `$ = (${a.multiply(m3).toStringIJK()})+(${b
            .multiply(m4)
            .toStringIJK()})$`
        ),
        `$= ${a.multiply(m3).add(b.multiply(m4)).toStringIJK()}$`,
        MCMaths.cleaner(
          `c)HEADING $ = ${m5}(${a.toStringIJK()})+${m6}(${b.toStringIJK()})$`
        ),
        MCMaths.cleaner(
          `$ = (${a.multiply(m5).toStringIJK()})+(${b
            .multiply(m6)
            .toStringIJK()})$`
        ),
        `$= ${a.multiply(m5).add(b.multiply(m6)).toStringIJK()}$`,
      ]);
      const group = new MCQuestion.InputGroup(2);
      group.addInput(ans1, 0, "a) $\\ \\mathbf{i}$");
      group.addInput(ans2, 0, "$\\mathbf{j}$");
      group.addInput(ans3, 0, "b) $\\ \\mathbf{i}$");
      group.addInput(ans4, 0, "$\\mathbf{j}$");
      group.addInput(ans5, 0, "c) $\\ \\mathbf{i}$");
      group.addInput(ans6, 0, "$\\mathbf{j}$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorSkills_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const display = MCRandom.randomInt(0, 1);
    const a = new MCMaths.Vector(
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2)
    );
    const b = new MCMaths.Vector(
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2)
    );

    const x = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const y = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const c = a.multiply(x).add(b.multiply(y));

    let ad;
    let bd;
    let cd;

    if (display === 0) {
      ad = a.toString();
      bd = b.toString();
      cd = c.toString();
    } else {
      ad = a.toStringIJK();
      bd = b.toStringIJK();
      cd = c.toStringIJK();
    }

    const sol = new MCMaths.Intersections(
      [a.getComponents()[0], b.getComponents()[0], c.getComponents()[0]],
      [a.getComponents()[1], b.getComponents()[1], c.getComponents()[1]]
    );

    /* END VARIABLES AND CONSTANTS */
    if (
      a.isParallel(b) === false &&
      c.magnitude() < 10 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given $\\mathbf{a}=${ad}$, $\\mathbf{b}=${bd}$, find the values of $x,y$, such that $x\\mathbf{a}+y\\mathbf{b}=${cd}$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Forming Equation:`,
        sol.toStringArray()[0],
        sol.toStringArray()[1],
        `HEADING Solving Simultaneously:`,
      ]);
      question.addMultipleParagraphs("solution", sol.solutionsWorking());

      const group = new MCQuestion.InputGroup(2);
      group.addInput(x, 0, "$x=$");
      group.addInput(y, 0, "$y=$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorSkills_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const display = MCRandom.randomInt(0, 1);
    const a = new MCMaths.Vector(
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2)
    );
    const b = new MCMaths.Vector(
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2)
    );

    const x1 = (MCRandom.randomInt(1, 15) * MCRandom.randomInt(-1, 1, 2)) / 10;
    const y1 = (MCRandom.randomInt(1, 15) * MCRandom.randomInt(-1, 1, 2)) / 10;

    function gcd(x, y) {
      let xg = Math.abs(x);
      let yg = Math.abs(y);
      while (yg) {
        const t = yg;
        yg = xg % yg;
        xg = t;
      }
      return xg;
    }

    const c = a.add(b.multiply(x1));
    const cg = gcd(c.getComponents()[0], c.getComponents()[1]);
    const c2 = new MCMaths.Vector(
      c.getComponents()[0] / cg,
      c.getComponents()[1] / cg
    );

    let ad;
    let bd;
    let cd;

    if (display === 0) {
      ad = a.toString();
      bd = b.toString();
      cd = c2.toString();
    } else {
      ad = a.toStringIJK();
      bd = b.toStringIJK();
      cd = c2.toStringIJK();
    }

    const sol = new MCMaths.Intersections(
      [b.getComponents()[0], -c2.getComponents()[0], -a.getComponents()[0]],
      [b.getComponents()[1], -c2.getComponents()[1], -a.getComponents()[1]],
      "x",
      "k"
    );

    /* END VARIABLES AND CONSTANTS */
    if (
      cg > 2 &&
      a.isParallel(b) === false &&
      c2.magnitude() < 20 &&
      x1 !== y1 &&
      c2.getComponents()[0] !== 0 &&
      c2.getComponents()[1] !==
        0 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given $\\mathbf{a}=${ad}$, $\\mathbf{b}=${bd}$, find $x$, such that $\\mathbf{a}+x\\mathbf{b}$ is parallel to $${cd}$.`,
      ]);
      question.addMultipleParagraphs("solution", [`HEADING Forming Equation:`]);
      if (display === 1) {
        question.addMultipleParagraphs("solution", [
          `$\\mathbf{a}+x\\mathbf{b} = k(${cd})$`,
          `$${ad}+x(${bd})= k(${cd})$`,
        ]);
      } else {
        question.addMultipleParagraphs("solution", [
          `$\\mathbf{a}+x\\mathbf{b} = k${cd}$`,
          `$${ad}+x${bd}= k${cd}$`,
        ]);
      }
      question.addMultipleParagraphs("solution", [
        `HEADING Seperating Components:`,
        MCMaths.cleaner(
          `$${a.getComponents()[0]}+${b.getComponents()[0]}x = ${
            c2.getComponents()[0]
          }k$`,
          false,
          false
        ),
        MCMaths.cleaner(
          `$${a.getComponents()[1]}+${b.getComponents()[1]}x = ${
            c2.getComponents()[1]
          }k$`,
          false,
          false
        ),
        `HEADING Solving Simultaneously:`,
      ]);
      question.addMultipleParagraphs("solution", sol.solutionsWorking());
      const group = new MCQuestion.InputGroup(1);
      group.addInput(x1, 0, "$x=$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorSkills_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const display = MCRandom.randomInt(0, 1);
    const a = new MCMaths.Vector(
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2)
    );
    const b = new MCMaths.Vector(
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2)
    );

    function gcd(x, y) {
      let xg = Math.abs(x);
      let yg = Math.abs(y);
      while (yg) {
        const t = yg;
        yg = xg % yg;
        xg = t;
      }
      return xg;
    }
    const bg = gcd(b.getComponents()[0], b.getComponents()[1]);
    const b2 = new MCMaths.Vector(
      b.getComponents()[0] / bg,
      b.getComponents()[1] / bg
    );

    let ad;
    let bd;
    let bd2;
    let bw;
    if (display === 0) {
      ad = a.toString();
      bd = b.toString();
      bd2 = b2.toString();
      bw = `\\begin{pmatrix} b_1 \\\\ b_2 \\end{pmatrix}`;
    } else {
      ad = a.toStringIJK();
      bd = b.toStringIJK();
      bd2 = b2.toStringIJK();
      bw = `b_1 \\mathbf{i} + b_2 \\mathbf{j}`;
    }

    const m = a.add(b).magnitude();

    const a1 = a.getComponents()[0];
    const a2 = a.getComponents()[1];

    const br = b.getComponents()[1] / b.getComponents()[0];
    let brd = br;
    if (br === 1) {
      brd = "";
    }
    if (br === -1) {
      brd = "-";
    }

    let brd2 = br ** 2;
    if (brd2 === 1) {
      brd2 = "";
    }

    const bq = new MCMaths.Polynomial(
      [1 + br ** 2, a1 * 2 + a2 * 2 * br, a1 ** 2 + a2 ** 2 - m ** 2],
      "b_1"
    );

    const b1a = bq.roots()[0];
    const b1b = bq.roots()[1];

    const b2a = br * bq.roots()[0];
    const b2b = br * bq.roots()[1];

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(m * 10) === m * 10 &&
      m > 1 &&
      bg > 1 &&
      b1a !== b1b &&
      Math.round(b2a * 10) === b2a * 10 &&
      Math.round(b2b * 10) ===
        b2b * 10 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given $\\mathbf{a}=${ad}$, and $\\left|\\mathbf{a} + \\mathbf{b} \\right|=${m}$, given $\\mathbf{b}$ is parallel to $${bd2}$, find the possible values of $\\mathbf{b}$.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Forming Equation:`,
        `$\\displaystyle \\left| ${ad}+${bw} \\right|=${m}$`,
        `$\\displaystyle \\sqrt{(${a1}+b_1)^2+(${a2}+b_2)^2}=${m}$`,
        `$\\displaystyle (${a1}+b_1)^2+(${a2}+b_2)^2=${m ** 2}$`,
        `HEADING Finding Second Equation:`,
      ]);
      if (display === 0) {
        question.addParagraph("solution", `$\\displaystyle ${bw} = k ${bd2}$`);
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle ${bw} = k (${bd2})$`
        );
      }
      question.addMultipleParagraphs("solution", [
        MCMaths.cleaner(`$b_2 = ${brd}b_1$`),
        `HEADING Substituting:`,
        MCMaths.cleaner(
          `$\\displaystyle (${a1}+b_1)^2+\\left(${a2}+${brd}b_1\\right)^2=${
            m ** 2
          }$`
        ),
        MCMaths.cleaner(
          `$b_1^2 +${2 * a1}b_1+${a1 ** 2}+${brd2}b_1^2+${a2 * 2 * br}b_1 + ${
            a2 ** 2
          }=${m ** 2}$`
        ),
        `$${bq.toString()}=0$`,
        `HEADING Solving:`,
        `$${bq.quadraticFormula()}$`,
        `$b_1=${b1a}, b_1=${b1b}$`,
        `HEADING Finding $b_2$:`,
        MCMaths.cleaner(`$b_2 = ${brd}b_1$`),
        `$b_2=${b2a}, b_2=${b2b}$`,
      ]);
      const group = new MCQuestion.InputGroup(2);
      group.addInput([b1a, b1b], 0, "$b_1=$");
      group.addInput([b2a, b2b], 0, "$b_2=$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorSkills_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const x = MCRandom.randomInt(2, 12);
    const y = MCRandom.randomInt(2, 12);
    const m = Math.sqrt(x ** 2 + y ** 2);
    const d = y / m;
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(m) === m /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        MCMaths.cleaner(
          `A vector $\\mathbf{a}$ satisfies $|\\mathbf{a}|=${m}$, and makes an angle $\\theta$ with the poitive $x$ axis. Given $\\sin(\\theta)=${d}$, find the possible values of $\\mathbf{a}$, in the form $p\\mathbf{i}+q\\mathbf{j}$.`
        ),
      ]);
      question.addMultipleParagraphs("solution", [
        `HEADING Finding $q$:`,
        `$\\sin(\\theta)=\\frac{q}{|a|}$`,
        MCMaths.cleaner(`$${d}=\\frac{q}{${m}}$`),
        `$q=${y}$`,
        `HEADING Finding $p$`,
        `$\\sqrt{p^2+q^2}=${m}$`,
        `$p^2+q^2=${m ** 2}$`,
        `$p^2 = ${m ** 2}-${y}^2$`,
        `$p = \\pm ${x}$`,
      ]);
      const group = new MCQuestion.InputGroup(2);
      group.addInput([x, -x], 0, "$p=$");
      group.addInput([y, y], 0, "$q=$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorSkills_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const i = MCRandom.randomInt(1, 5);
    const j = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    let jd = j;
    if (j < 0) {
      jd = `(${j})`;
    }
    const a = new MCMaths.Vector(i, j);
    const ans1 = (Math.atan(Math.abs(j) / i) * 180) / Math.PI;
    const ans1d = Math.round(ans1 * 100) / 100;
    const ans2 = Math.sqrt(i ** 2 + j ** 2);
    const ans2d = Math.round(ans2 * 100) / 100;

    const p = i / ans2;
    const q = j / ans2;
    const pd = Math.round(p * 100) / 100;
    const qd = Math.round(q * 100) / 100;
    /* END VARIABLES AND CONSTANTS */
    if (
      ans1 !== ans1d &&
      ans2 !== ans2d /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$\\mathbf{a}=${a.toStringIJK()}$`,
        `a) Find the angle $\\mathbf{a}$ makes with $\\mathbf{i}$, in degrees.`,
        `b) Find the magnitude of $\\mathbf{a}$.`,
        `c) Find a unit vector with the same direction as $\\mathbf{a}$, in the form $p\\mathbf{i}+q\\mathbf{j}$.`,
        `Give answers correct to 2dp.`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding angle:`,
        `$\\tan(\\theta) = \\frac{${Math.abs(j)}}{${i}}$`,
        `$\\theta \\approx ${ans1d}^\\circ$`,
        `b) HEADING Finding magnitude:`,
        `$|\\mathbf{a}|=\\sqrt{${i}^2+${jd}^2}$`,
        `$\\approx ${ans2d}$`,
        `c) HEADING Finding Vector:`,
        `$\\displaystyle = \\frac{\\mathbf{a}}{|\\mathbf{a}|}$`,
        MCMaths.cleaner(
          `$\\approx ${pd}\\mathbf{i}+${qd}\\mathbf{j}$`,
          false,
          false
        ),
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans1, 0.05, "a)");
      group.addInput(ans2, 0.05, "b)");
      question.addInputGroup(group);
      const group2 = new MCQuestion.InputGroup(2);
      group2.addInput(p, 0.05, "$p=$");
      group2.addInput(q, 0.05, "$q=$");
      question.addInputGroup(group2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorSkills_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = new MCMaths.Vector(
      MCRandom.randomInt(2, 4) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 4) * MCRandom.randomInt(-1, 1, 2)
    );
    const b = new MCMaths.Vector(
      MCRandom.randomInt(2, 4) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(2, 4) * MCRandom.randomInt(-1, 1, 2)
    );

    const ab = b.sub(a);

    const area = a.cross(b).magnitude() * 0.5;

    const c = MCMaths.cleaner(`${b.magnitude()}`);
    const coef1 = c.match(/\d+/g)[0];
    const coef2 = c.match(/\d+/g)[1];

    const i = b.getComponents()[0];
    const j = b.getComponents()[1];

    let id = i;
    let jd = j;
    if (i < 0) {
      id = `(${i})`;
    }
    if (j < 0) {
      jd = `(${j})`;
    }
    /* END VARIABLES AND CONSTANTS */
    if (
      area > 7 &&
      coef2 !== undefined &&
      coef1 > 1 &&
      Math.round(Number(coef1)) === Number(coef1) &&
      Math.round(Number(coef2)) ===
        Number(coef2) /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `Given $\\displaystyle \\overrightarrow{OA} = ${a.toStringIJK()}$, $\\displaystyle \\overrightarrow{AB} = ${ab.toStringIJK()}$:`,
        `a) Find $\\displaystyle \\overrightarrow{OB}$, in the form $p\\mathbf{i}+q\\mathbf{j}$.`,
        `b) Sketch the Triangle $OAB$.`,
        `c) Find $\\displaystyle |\\overrightarrow{OB}|$ in the form $r\\sqrt{${coef2}}$`,
      ]);
      question.addMultipleParagraphs("solution", [
        `a) HEADING $\\displaystyle \\overrightarrow{OB} = \\overrightarrow{OA}+\\overrightarrow{AB}$`,
        MCMaths.cleaner(
          `$=${a.toStringIJK()}+${ab.toStringIJK()}$`,
          false,
          false
        ),
        `$=${b.toStringIJK()}$`,
        `b) HEADING`,
      ]);
      const g = new MCQuestion.Graph(5, -5, 5, -5, 1, 1);
      g.addParametric(
        `${a.getComponents()[0]} * t`,
        `${a.getComponents()[1]} * t`,
        0,
        1
      );
      g.addParametric(
        `${b.getComponents()[0]} * t`,
        `${b.getComponents()[1]} * t`,
        0,
        1
      );
      g.addParametric(
        `${a.getComponents()[0]} * (1 - t) + ${b.getComponents()[0]} * t`,
        `${a.getComponents()[1]} * (1 - t) + ${b.getComponents()[1]} * t`,
        0,
        1
      );
      question.addGraph("solution", g);
      question.addMultipleParagraphs("solution", [
        `c) HEADING $|\\overrightarrow{OB}|=\\sqrt{${id}^2+${jd}^2}$`,
        `$=\\sqrt{${Math.round(b.magnitude() ** 2)}}$`,
        `$=${c}$`,
      ]);
      const group = new MCQuestion.InputGroup(2);
      group.addInput(i, 0, "$p=$");
      group.addInput(j, 0, "$q=$");
      question.addInputGroup(group);
      const group2 = new MCQuestion.InputGroup(1);
      group2.addInput(Number(coef1), 0, "$r=$");
      question.addInputGroup(group2);
      question.requiresSelfMarking();
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorSkills_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 4);
    const b = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 4);
    const d = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const ab = new MCMaths.Vector(a, b);
    const ac = new MCMaths.Vector(c, d);

    let bd = b;
    if (b < 0) {
      bd = `(${b})`;
    }

    let dd = d;
    if (d < 0) {
      dd = `(${d})`;
    }

    const ang1 = (Math.atan(b / a) * 180) / Math.PI;
    const ang1r = Math.round(ang1 * 1000) / 1000;
    const ang2 = (Math.atan(d / c) * 180) / Math.PI;
    const ang2r = Math.round(ang2 * 1000) / 1000;
    const ang3 = ang1 - ang2;
    const ang3r = Math.round(ang3);

    const side1 = Math.sqrt(a ** 2 + b ** 2);
    const side2 = Math.sqrt(c ** 2 + d ** 2);

    const ans2 = side1 * side2 * Math.sin((ang3 * Math.PI) / 180);
    const ans2r = Math.round(ans2);
    // const ans2d = Math.round(ans2*1000)/1000
    /* END VARIABLES AND CONSTANTS */
    if (
      ang1r > ang2r &&
      ang1 !== ang1r &&
      ang2 !== ang2r &&
      ang3 > 30 &&
      ang3 < 120 &&
      side1 > 1 &&
      side2 > 1 &&
      Math.abs(ans2 - ans2r) <
        0.001 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `In a Triangle $ABC$, $\\overrightarrow{AB}=${ab.toStringIJK()}$, $\\overrightarrow{AC}=${ac.toStringIJK()}$.`,
        `a) Find the angle $\\angle BAC$, to the nearest degrees.`,
        `b) Find the area of the triangle.`,
      ]);
      const g = new MCQuestion.Graph(4, -4, 4, -4, 1, 1);
      g.addParametric(`${a} * t`, `${b} * t`, 0, 1);
      g.addParametric(`${c} * t`, `${d} * t`, 0, 1);
      g.addParametric(
        `${a} + (${c} - ${a}) * t`,
        `${b} + (${d} - ${b}) * t`,
        0,
        1
      );
      question.addGraph("solution", g);
      question.addMultipleParagraphs("solution", [
        `a) HEADING Finding the angle between $\\overrightarrow{AB}$ and $\\mathbf{i}$:`,
        `$=\\tan^{-1}\\left(\\frac{${b}}{${a}}\\right)$`,
        `$\\approx ${ang1r}^\\circ$.`,
        `HEADING Finding the angle between $\\overrightarrow{AC}$ and $\\mathbf{i}$:`,
        `$=\\tan^{-1}\\left(\\frac{${d}}{${c}}\\right)$`,
        `$\\approx ${ang2r}^\\circ$.`,
        `HEADING Finding $\\angle BAC$:`,
        MCMaths.cleaner(`$=${ang1r}-${ang2r}$`, false, false),
        `$\\approx ${ang3r}^\\circ$.`,
        `b) HEADING Finding $|\\overrightarrow{AB}|$:`,
        `$=\\sqrt{${a}^2+${bd}^2}$`,
        MCMaths.cleaner(`$=${side1}$`),
        `HEADING Finding $|\\overrightarrow{AC}|$:`,
        `$=\\sqrt{${c}^2+${dd}^2}$`,
        MCMaths.cleaner(`$=${side2}$`),
        `HEADING Finding Area:`,
        `$=|\\overrightarrow{AB}||\\overrightarrow{AC}|\\sin(\\theta)$`,
        MCMaths.cleaner(`$=${side1}\\times ${side2} \\times \\sin(${ang3r})$`),
        `$= ${ans2r}$`,
      ]);
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ang3r, 0, "$a)$");
      group.addInput(ans2r, 0.1, "$b)$");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorSkills_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 5);
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 5);
    const d = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const OP = new MCMaths.Vector(a, b);
    const OR = new MCMaths.Vector(c, d);

    const r1 = MCRandom.randomInt(1, 7);
    const r2 = MCRandom.randomInt(1, 7);

    const partA = OR.sub(OP);
    const ai = partA.getComponents()[0];
    const aj = partA.getComponents()[1];
    const partB = OP.add(partA.multiply(r1 / (r1 + r2)));
    const bi = partB.getComponents()[0];
    const bj = partB.getComponents()[1];
    const partC = OR.add(OP).sub(partB);
    const ci = partC.getComponents()[0];
    const cj = partC.getComponents()[1];
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.round(r1 / r2) !== r1 / r2 &&
      Math.round(r2 / r1) !== r2 / r1 &&
      Math.round(bi * 20) / 20 === bi &&
      Math.round(bj * 20) / 20 === bj &&
      partA.magnitude() > 1 &&
      a + c > 5 &&
      Math.abs(b - d) > 5 &&
      Math.round(ci) === ci &&
      Math.round(cj) === cj /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addMultipleParagraphs("question", [
        `$OPQR$ is a parallelogram. A point, $X$, divides $PR$ in the ratio $${r1}:${r2}$. Given $\\overrightarrow{OP} = ${OP.toString()}$, and $\\overrightarrow{OR} = ${OR.toString()}$, find:`,
        `a) $\\overrightarrow{PR}$`,
        `b) $\\overrightarrow{OX}$`,
        `c) $\\overrightarrow{XQ}$`,
      ]);
      const g = new MCQuestion.Graph(10, 0, 5, -5, 1, 1);
      g.addParametric(`t * ${a}`, `t * ${b}`, 0, 1);
      g.addParametric(`t * ${c}`, `t * ${d}`, 0, 1);
      g.addParametric(`${a} + t * ${c}`, `${b} + t * ${d}`, 0, 1);
      g.addParametric(`${c} + t * ${a}`, `${d} + t * ${b}`, 0, 1);
      g.addParametric(
        `${a} + t * (${c} - ${a})`,
        `${b} + t * (${d} - ${b})`,
        0,
        1
      );
      g.addParametric(`t * ${bi}`, `t * ${bj}`, 0, 1);
      g.addParametric(`${bi} + t * ${ci}`, `${bj} + t * ${cj}`, 0, 1);
      question.addGraph("solution", g);
      question.addMultipleParagraphs("solution", [
        `a) HEADING $\\overrightarrow{PR} = -\\overrightarrow{OP}+\\overrightarrow{PR}$`,
        `$=-${OP.toString()}+${OR.toString()}$`,
        `$=${partA.toString()}$`,
        `b) HEADING $\\displaystyle\\overrightarrow{OX} = \\overrightarrow{OP}+\\frac{${r1}}{${
          r1 + r2
        }}\\overrightarrow{PR}$`,
        `$\\displaystyle = ${OP.toString()}+\\frac{${r1}}{${
          r1 + r2
        }}${partA.toString()}$`,
        `$\\displaystyle =${partB.toString()}$`,
        `c) HEADING $\\overrightarrow{XQ} = -\\overrightarrow{OX} + \\overrightarrow{OP} + \\overrightarrow{OR}$`,
        `$=-${partB.toString()} + ${OP.toString()} + ${OR.toString()} $`,
        `$=${partC.toString()}$`,
      ]);
      const group = new MCQuestion.InputGroup(2);
      group.addInput(ai, 0, "a) $\\ \\mathbf{i} = $");
      group.addInput(aj, 0, "a) $\\ \\mathbf{j} = $");
      group.addInput(bi, 0, "b) $\\ \\mathbf{i} = $");
      group.addInput(bj, 0, "b) $\\ \\mathbf{j} = $");
      group.addInput(ci, 0, "c) $\\ \\mathbf{i} = $");
      group.addInput(cj, 0, "c) $\\ \\mathbf{j} = $");
      question.addInputGroup(group);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorSkills_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const x = new MCMaths.Vector(
      MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2),
      MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2)
    );
    const note = MCRandom.randomInt(0, 1);

    const a1 = x.multiply(
      MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2)
    );
    const a2 = x.multiply(
      MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2)
    );

    const wrong = [
      x.add(
        new MCMaths.Vector(
          MCRandom.randomInt(1, 3) * MCRandom.randomInt(-1, 1, 2),
          0
        )
      ),
      x.add(
        new MCMaths.Vector(
          0,
          MCRandom.randomInt(1, 3) * MCRandom.randomInt(-1, 1, 2)
        )
      ),
      new MCMaths.Vector(x.getComponents()[0] * -1, x.getComponents()[1]),
      new MCMaths.Vector(x.getComponents()[0], -x.getComponents()[1]),
      new MCMaths.Vector(
        x.getComponents()[0] * -1,
        x.getComponents()[1]
      ).multiply(MCRandom.randomInt(2, 3)),
      new MCMaths.Vector(x.getComponents()[0], -x.getComponents()[1]).multiply(
        MCRandom.randomInt(2, 3)
      ),
    ];

    const a3 = wrong[MCRandom.randomInt(0, wrong.length - 1)];
    const a4 = wrong[MCRandom.randomInt(0, wrong.length - 1)];

    let xd;
    let a1d;
    let a2d;
    let a3d;
    let a4d;
    if (note === 0) {
      xd = x.toString();
      a1d = a1.toString();
      a2d = a2.toString();
      a3d = a3.toString();
      a4d = a4.toString();
    } else {
      xd = x.toStringIJK();
      a1d = a1.toStringIJK();
      a2d = a2.toStringIJK();
      a3d = a3.toStringIJK();
      a4d = a4.toStringIJK();
    }
    /* END VARIABLES AND CONSTANTS */
    if (
      a1 !== a2 &&
      a3 !== a4 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(`Select all vectors parallel to $${xd}$.`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${a1d}$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${a2d}$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${a3d}$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${a4d}$`);

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, true);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorSkills_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const a = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const d = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const e = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const f = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const p1 = new MCMaths.Vector(a, b);
    const p2 = new MCMaths.Vector(c, d);
    const p3 = new MCMaths.Vector(e, f);

    const a1 = new MCMaths.Vector(a + c - e, b + d - f);
    const a2 = new MCMaths.Vector(-a + c + e, -b + d + f);
    const a3 = new MCMaths.Vector(a - c + e, b - d + f);

    const falses = [
      a1.multiply(2),
      a2.multiply(2),
      a3.multiply(2),
      a1.multiply(1 / 2),
      a2.multiply(1 / 2),
      a3.multiply(1 / 2),
      a1.add(
        new MCMaths.Vector(
          MCRandom.randomInt(-1, 1, 2),
          MCRandom.randomInt(-1, 1, 2)
        )
      ),
      a2.add(
        new MCMaths.Vector(
          MCRandom.randomInt(-1, 1, 2),
          MCRandom.randomInt(-1, 1, 2)
        )
      ),
      a3.add(
        new MCMaths.Vector(
          MCRandom.randomInt(-1, 1, 2),
          MCRandom.randomInt(-1, 1, 2)
        )
      ),
      a1.multiply(-1),
      a2.multiply(-1),
      a3.multiply(-1),
      new MCMaths.Vector(-a, -b),
      new MCMaths.Vector(-a, -d),
      new MCMaths.Vector(-a, -f),
    ];
    const f1 = falses[MCRandom.randomInt(0, falses.length - 1)];
    const f2 = falses[MCRandom.randomInt(0, falses.length - 1)];

    // Checking inner angle so is not too thin.
    const m0 =
      (p1.getComponents()[1] - p2.getComponents()[1]) /
      (p1.getComponents()[0] - p2.getComponents()[0]);
    const m1 =
      (p1.getComponents()[1] - p3.getComponents()[1]) /
      (p1.getComponents()[0] - p3.getComponents()[0]);
    const angle = 45; // (Math.atan((m0 - m1) / (1 + m0 * m1)) * 180) / Math.PI;
    /* END VARIABLES AND CONSTANTS */
    if (
      angle > 30 &&
      angle < 60 &&
      f1 !== a1 &&
      f1 !== a2 &&
      f1 !== a3 &&
      f2 !== a1 &&
      f2 !== a2 &&
      f2 !== a3 &&
      f1 !== p1 &&
      f1 !== p2 &&
      f1 !== p3 &&
      f2 !== p1 &&
      f2 !== p2 &&
      f2 !== p3 &&
      f1 !== f2 &&
      a1 !== a2 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        `Three of the points of a parallelogram are given by the position vectors $${p1.toString()}$, $${p2.toString()}$, $${p3.toString()}$. Select the possible fourth points:`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${a1}$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${a2}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${f1}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${f2}$`);

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, true);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function AS_Vectors_VectorSkills_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const type = MCRandom.randomInt(0, 1);

    let a = MCRandom.randomInt(1, 5);
    let b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    if (type === 1) {
      a = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
      b = MCRandom.randomInt(1, 5);
    }

    const A = new MCMaths.Vector(a, b);

    let i = "i";
    if (type === 1) {
      i = "j";
    }

    const a1 =
      Math.round(10 * Math.abs((Math.atan(b / a) * 180) / Math.PI)) / 10;
    const a2 =
      Math.round(10 * Math.abs(((1 / Math.atan(b / a)) * 180) / Math.PI)) / 10;
    const a3 =
      Math.round(10 * Math.abs((Math.atan(b / (a + b)) * 180) / Math.PI)) / 10;
    const a4 =
      Math.round(10 * Math.abs((Math.atan(a / b) * 180) / Math.PI)) / 10;

    /* END VARIABLES AND CONSTANTS */
    if (
      a1 !== a2 &&
      a1 !== a3 &&
      a1 !== a4 &&
      a2 !== a3 &&
      a2 !== a4 &&
      a3 !== a4 /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(4);
      question.addParagraph(
        `Find the angle $${A.toStringIJK()}$ makes with $\\mathbf{${i}}$.`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${a1}^\\circ$`);
      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${a2}^\\circ$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${a3}^\\circ$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${a4}^\\circ$`);

      if (type === 0) {
        question.addAnswer(answer1, true);
        question.addAnswer(answer2, false);
        question.addAnswer(answer3, false);
        question.addAnswer(answer4, false);
      } else {
        question.addAnswer(answer1, false);
        question.addAnswer(answer2, false);
        question.addAnswer(answer3, false);
        question.addAnswer(answer4, true);
      }
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_Graphs_FunctionGraphs_Exam_Q1() {
  class recip {
    constructor() {
      this.esLint = 0;
    }

    toString() {
      this.esLint += 1;
      return "\\frac{1}{x}";
    }

    evaluate(x) {
      this.esLint += 1;
      return 1 / x;
    }

    graph() {
      this.esLint += 1;
      const graph1 = new MCQuestion.Graph(5, -5, 5, -5, 1, 1);
      graph1.plot("1/x", -5, 5);
      return graph1;
    }
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const x3k = MCRandom.randomInt(-5, 5);
    const x2a = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const x2b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const x2c = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const fs = [
      new MCMaths.Polynomial([1, 0, 0, x3k]),
      new recip(),
      new MCMaths.Polynomial([x2a, x2b, x2c]),
    ];

    const mode = MCRandom.randomInt(0, fs.length - 1);

    const xb = MCRandom.randomInt(-5, 5);
    const yb = fs[mode].evaluate(xb);

    let ans;
    let ansd;
    let test = true;
    if (mode === 2) {
      ans = fs[mode].subtract(new MCMaths.Polynomial([yb])).roots();
      if (ans.length !== 2) {
        test = false;
        ans = [0, 0];
      } else {
        ansd = `$x=${ans[0]}$ or $x=${ans[1]}$`;
      }
      if (Math.round(ans[0]) !== ans[0] || Math.round(ans[1]) !== ans[1]) {
        test = false;
      }
    } else {
      ans = xb;
      ansd = `$x=${ans}$`;
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`Given the function $y=${fs[mode].toString()}$:`)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a)$ Sketch the graph of the function,`)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Use the graph to find the value(s) of $x$ when $y=${yb}$.`
      )
    );
    question.addParagraph("solution", MCMaths.cleaner(`$(a)$`));
    question.addGraph("solution", fs[mode].graph());
    question.addParagraph("solution", MCMaths.cleaner(`$(b)$`));
    const g2 = fs[mode].graph();
    g2.plot(`${yb}`, -10, 10);
    question.addGraph("solution", g2);

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ans, 0.01, "$(b)$");
    question.addInputGroup(group);
    question.addParagraph("solution", `Hence ${ansd}`);

    if (Math.abs(yb) > 5 || test === false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_Graphs_Lines_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const ans = 1;

    const a = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const d = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const e = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const mode = MCRandom.randomInt(0, 2);
    let p1;
    let p2;
    let p3;
    let ansD;
    if (mode === 0) {
      p1 = new MCMaths.Polynomial([a, b]);
      p2 = new MCMaths.Polynomial([a, c]);
      p3 = new MCMaths.Polynomial([d, e]);
      ansD = "$(a)$ and $(b)$";
    }
    if (mode === 1) {
      p3 = new MCMaths.Polynomial([a, b]);
      p1 = new MCMaths.Polynomial([a, c]);
      p2 = new MCMaths.Polynomial([d, e]);
      ansD = "$(a)$ and $(c)$";
    }
    if (mode === 2) {
      p2 = new MCMaths.Polynomial([a, b]);
      p3 = new MCMaths.Polynomial([a, c]);
      p1 = new MCMaths.Polynomial([d, e]);
      ansD = "$(b)$ and $(c)$";
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", `Find the equations of:`);

    question.addParagraph("question", MCMaths.cleaner(`$(a)$`));

    const g1 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
    g1.plot(
      p1
        .toString()
        .replace("x", "*x")
        .replace("-*x", "-x")
        .replace("+*x", "+x"),
      -10,
      10
    );
    question.addGraph("question", g1);
    question.addParagraph("question", MCMaths.cleaner(`$(b)$`));

    const g2 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
    g2.plot(
      p2
        .toString()
        .replace("x", "*x")
        .replace("-*x", "-x")
        .replace("+*x", "+x"),
      -10,
      10
    );
    question.addGraph("question", g2);
    question.addParagraph("question", MCMaths.cleaner(`$(c)$`));

    const g3 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
    g3.plot(
      p3
        .toString()
        .replace("x", "*x")
        .replace("-*x", "-x")
        .replace("+*x", "+x"),
      -10,
      10
    );
    question.addGraph("question", g3);

    question.addParagraph(
      "question",
      `$(d)$ Which of the two graphs show parallel lines?`
    );

    question.addParagraph("solution", `$(a) \\ y=${p1.toString()}$`);
    question.addParagraph("solution", `$(b) \\ y=${p2.toString()}$`);
    question.addParagraph("solution", `$(c) \\ y=${p3.toString()}$`);
    question.addParagraph("solution", `$(d) \\ $ ${ansD}`);

    question.requiresSelfMarking();

    if (a === d || b === c) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_Graphs_Lines_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const m = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const p = new MCMaths.Polynomial([m, c]);

    const x1 = MCRandom.randomInt(-5, 5);
    const x2 = MCRandom.randomInt(-5, 5);

    const y1 = p.evaluate(x1);
    const y2 = p.evaluate(x2);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `Find the equations of the line going through $(${x1},${y1})$ and $(${x2},${y2})$.`
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`Give your answer in the form $y=mx+c$.`)
    );

    question.addHeading("solution", `Finding $m$:`);

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\displaystyle  m = \\frac{${y2}-${y1}}{${x2}-${x1}}=${m}$`
      )
    );

    question.addHeading("solution", `Finding $c$:`);

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\displaystyle  ${y1}=(${m} \\times ${x1})+c$`)
    );
    question.addParagraph("solution", `$c=${c}$`);

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Hence the equation is $y=${p.toString()}$.`)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(m, 0.01, `$m=$`);
    group.addInput(c, 0.01, `$c=$`);
    question.addInputGroup(group);

    if (x1 === x2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_Graphs_Lines_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const m = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const p = new MCMaths.Polynomial([m, c]);

    const x1 = MCRandom.randomInt(-5, 5);
    const x2 = MCRandom.randomInt(-5, 5);

    const y1 = p.evaluate(x1);
    const y2 = p.evaluate(x2);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `Find the equations of the line going through $(${x1},${y1})$ with gradient $${m}$.`
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`Give your answer in the form $y=mx+c$.`)
    );

    question.addParagraph(
      "solution",
      `Substituting into $y=mx+c$: $${y1}=(${m} \\times ${x1})+c$.`
    );
    question.addParagraph("solution", `Rearranging: $c=${c}$.`);

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Hence the equation is $y=${p.toString()}$.`)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(m, 0.01, `$m=$`);
    group.addInput(c, 0.01, `$c=$`);
    question.addInputGroup(group);

    if (x1 === x2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_Graphs_Lines_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const a = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const mul = MCRandom.randomInt(2, 3) * MCRandom.randomInt(-1, 1, 2);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Show that the lines $y=${a}x+${b}$ and $${mul * a}x+${-mul}y=${
          -mul * c
        }$ are parallel.`
      )
    );

    question.addHeading(
      "solution",
      MCMaths.cleaner(`Writing equations in the form $y=mx+c$:`)
    );
    question.addParagraph("solution", MCMaths.cleaner(`$y=${a}x+${b}$`));
    question.addParagraph("solution", MCMaths.cleaner(`$y=${a}x+${c}$`));
    question.addParagraph(
      "solution",
      `As both equations have the same gradients, the lines are parallel.`
    );

    question.requiresSelfMarking();

    if (b === c) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_Graphs_Quadratics_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const question = new MCQuestion.ExamQuestion();

    const root1 = MCRandom.randomInt(-5, 5);
    const root2 = MCRandom.randomInt(-5, 5);

    const eq = MCMaths.Polynomial.generateFromRoots([root1, root2]);
    const g = eq.graph();

    const ans2x = (root1 + root2) / 2;
    const ans2y = eq.evaluate(ans2x);

    question.addGraph("question", g);

    question.addParagraph(
      "question",
      MCMaths.cleaner(`The graph above shows the plot of $y=${eq.toString()}$.`)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a)$ Write down the roots of the equation.`)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Write down the coordinates of the turning point of the equation.`
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a)$ Roots are $x=${root1}$ and $x=${root2}$`)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ Turning point is $\\left(${ans2x},${ans2y}\\right)$`
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput([root1, root2], 0, "$(a) \\ x=$");
    group.addInput(ans2x, 0, "$(b) \\ x=$");
    group.addInput(ans2y, 0, "$(b) \\ y=$");
    question.addInputGroup(group);

    if (
      eq.plottingRange()[0] > 5 ||
      root1 === root2 ||
      Math.round(ans2y) !== ans2y
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_Graphs_Quadratics_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const question = new MCQuestion.ExamQuestion();

    const root1 = MCRandom.randomInt(-5, 5);
    const root2 = MCRandom.randomInt(-5, 5);

    const eq = MCMaths.Polynomial.generateFromRoots([root1, root2]);
    const g = eq.graph();

    const turning_x = (root1 + root2) / 2;
    const turning_y = eq.evaluate(turning_x);

    const range = eq.plottingRange()[0];

    const modeA = MCRandom.randomInt(0, 1);
    if (modeA === 0) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$(a)$ Plot the graph of $${eq.toString()}$ showing all roots and turning points.`
        )
      );
    }
    if (modeA === 1) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Plot the graph of $(x-${root1})(x-${root2})$ showing all roots and turning points.`.replace(
            "(x-0)",
            "x"
          )
        )
      );
    }
    question.addParagraph("solution", MCMaths.cleaner(`$(a)$`));
    question.addGraph("solution", g);

    const modeB = MCRandom.randomInt(0, 2);
    const group = new MCQuestion.InputGroup(2);
    if (modeB === 0) {
      question.addParagraph(
        "question",
        `$(b)$ Write down the coordinates of the minima of the function.`
      );
      question.addParagraph(
        "solution",
        `$(b)$ From the graph, the minima is at $(${
          (root1 + root2) / 2
        },${eq.evaluate((root1 + root2) / 2)})$`
      );
      group.addInput((root1 + root2) / 2, 0.01, "$(b) \\ x=$");
      group.addInput(eq.evaluate((root1 + root2) / 2), 0.01, "$(b) \\ y=$");
    }
    if (modeB === 1) {
      question.addParagraph(
        "question",
        `$(b)$ Write down the coordinates of the $y$-intercept of the function.`
      );
      question.addParagraph(
        "solution",
        `$(b)$ From the graph, the $y$-intercept is at $(0,${eq.evaluate(0)})$`
      );

      group.addInput(0, 0.01, "$(b) \\ x=$");
      group.addInput(eq.evaluate(0), 0.01, "$(b) \\ y=$");
    }
    if (modeB === 2) {
      question.addParagraph(
        "question",
        `$(b)$ Write down the roots of the function.`
      );
      question.addParagraph(
        "solution",
        `$(b)$ From the graph, the roots are at $x=${root1}$ and $x=${root2}$.`
      );

      group.addInput([root1, root2], 0.01, "$(b) \\ x=$");
    }
    question.requiresSelfMarking();

    question.addInputGroup(group);

    if (
      range > 5 ||
      root1 === root2 ||
      root2 === 0 ||
      Math.round(turning_y) !== turning_y
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_Nvam_IdentityAndProof_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name = MCMisc.getName();
    const odd = MCRandom.randomInt(3, 9);
    const even = MCRandom.randomInt(2, 8);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.name} says,`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `"$${odd}$ is odd and $${even}$ is even,`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `so the sum of a multiple of $${odd}$ and a multiple of $${even}$ is always odd."`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`Is ${name.heshe} correct?`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Justify your answer with a calculation.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${name.name} is incorrect.`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("A number multiplied by $2$ is even.", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${odd}  \\times 2 = ${odd * 2}$ which is even.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`All multiples of $${even}$ are even.`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${even}  \\times 2 = ${even * 2}$ which is even.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${odd * 2} + ${even * 2} = ${odd * 2 + even * 2}$ which is even.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("The sum of two even numbers is even.", false, true, true)
    );

    question.requiresSelfMarking();
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (odd / 2 === Math.round(odd / 2) || even / 2 !== Math.round(even / 2)) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Algebra_Seai_Inequalities_Exam_Q1() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();

    const a = MCRandom.randomInt(10, 30);
    const b = MCRandom.randomInt(10, 100);
    const c = MCRandom.randomInt(10, 100);

    const term1 = `${a}x+${b}`;
    const term2 = `${a}x`;

    const ans = (c - b) / a;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} is ordering some clothes from an online store. In order for ${person.himher} to get free delivery, ${person.heshe} needs to spend at least $£${c}$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} buys a pair of jeans that costs $£${b}$ and needs to buy some t-shirts. A t-shirt costs $£${a}$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` By forming and solving an inequality how many t-shirts does ${person.name} need to buy?`,
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${term1} > ${c}$ `, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${term2} > ${c - b}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $x > ${ans.toPrecision(3)}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` So ${person.name} needs to buy $${Math.ceil(ans)}$ t-shirts.`,
        false
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(Math.ceil(ans), 0, "Number of t-shirts");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (Math.ceil(ans) <= 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_Seai_Inequalities_Exam_Q2() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const a_left = MCRandom.randomInt(2, 20);
    const b_left = MCRandom.randomInt(1, 20);
    const leftTerm1 = `${a_left}n+${b_left}`;
    const a_right = MCRandom.randomInt(2, 20);
    const b_right = MCRandom.randomInt(1, 20);
    const rightTerm1 = `${a_right}n+${b_right}`;
    const c = MCRandom.randomInt(1, 20);

    const midTerm1 = c;
    const midTerm2a = c - b_right;
    const midTerm3a = midTerm2a / a_right;

    const midTerm2b = c - b_left;
    const midTerm3b = midTerm2b / a_left;

    const rightTerm2 = `${a_right}n`;
    const rightTerm3 = "n";

    const leftTerm2 = `${a_left}n`;
    const leftTerm3 = "n";

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `How many integers that satisfy $${leftTerm1} \\leq ${midTerm1} \\leq ${rightTerm1}?$`,
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Splitting up the inequalites we get:", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $(i) \\ ${midTerm1} \\leq ${rightTerm1}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $  ${midTerm2a} \\leq ${rightTerm2}$`, false)
    );
    if (midTerm3a === Math.round(midTerm3a)) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` $  ${Math.ceil(midTerm3a)} \\leq ${rightTerm3}$`,
          false
        )
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` $  ${midTerm3a.toPrecision(3)} \\leq ${rightTerm3}$`,
          false
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` $  ${Math.ceil(midTerm3a)} \\leq ${rightTerm3}$`,
          false
        )
      );
    }

    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $(ii) \\ ${leftTerm1} \\leq ${midTerm1}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $ ${leftTerm2} \\leq ${midTerm2b}$`, false)
    );
    if (midTerm3b === Math.round(midTerm3b)) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` $ ${leftTerm3} \\leq ${Math.floor(midTerm3b)}$`,
          false
        )
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` $ ${leftTerm3} \\leq ${midTerm3b.toPrecision(3)}$`,
          false
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` $ ${leftTerm3} \\leq ${Math.floor(midTerm3b)}$`,
          false
        )
      );
    }

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Combining parts $(i)$ and $(ii)$ we get  $${Math.ceil(
          midTerm3a
        )} \\leq n \\leq ${Math.floor(midTerm3b)}$.`,
        false
      )
    );

    let ans_string = "";
    let ans = 0;
    for (let j = Math.ceil(midTerm3a); j <= Math.floor(midTerm3b); j += 1) {
      ans += 1;
      if (j < Math.floor(midTerm3b)) {
        ans_string += `$${j}$, `;
      } else {
        ans_string = `${ans_string.slice(0, -2)} and $${j}$`;
      }
    }

    question.addParagraph(
      "solution",
      `Therefore the integer solutions to this inequality are ${ans_string}, so $${ans}$ numbers in total.`
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      a_left >= a_right ||
      b_left >= b_right ||
      midTerm3a <= 0 ||
      Math.ceil(midTerm3a) >= Math.floor(midTerm3b) ||
      (Math.ceil(midTerm3a) === (1 || 2) && MCRandom.randomInt(1, 6) > 1)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_Seai_Inequalities_Exam_Q3() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const imagearray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const a_answers = [
      "$-4 \\lt x \\leq 3$",
      "$-2 \\lt x \\leq 4$",
      "$-1 \\lt x \\leq 2$",
      "$-4 \\leq x \\leq 1$",
      "$-4 \\leq x \\leq 0$",
      "$-2 \\lt x \\lt 5$",
      "$-4 \\lt x \\lt 1$",
      "$-4 \\leq x \\lt 3$",
      "$-3 \\leq x \\lt 2$",
      "$-3 \\leq x \\lt 3$",
    ];

    const b_answers = [
      "$-3,-2,-1,0,1,2,3$",
      "$-1,0,1,2,3,4$",
      "$0,1,2$",
      "$-4,-3,-2,-1,0,1$",
      "$-4,-3,-2,-1,0$",
      "$-1,0,1,2,3,4$",
      "$-3,-2,-1,0$",
      "$-4,-3,-2,-1,0,1,2$",
      "$-3,-2,-1,0,1$",
      "$-3,-2,-1,0,1,2$",
    ];
    const j = MCRandom.randomInt(0, 9);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(a)$ Describe this number line using algebra.", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(b)$ List all the integers in the inequality.", false)
    );

    const img = new MCQuestion.Image(
      `GCSE/GCSE_Crossover_Algebra_Seai_Inequalities_Exam_Q3/numberLine${imagearray[j]}.jpg`,
      "width",
      20
    );
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " $(a)$ The number line can be described as follows:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` ${a_answers[j]}`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " $(b)$ The integers contained in this inequality is as follows:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` ${b_answers[j]}`, false)
    );

    question.requiresSelfMarking();
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_Seai_Inequalities_Exam_Q4() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const imagearray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const answers = [
      "$x \\gt 5$",
      "$x \\gt 3$",
      "$x \\geq 4$",
      "$x \\geq -1$",
      "$x \\geq -4$",
      "$x \\leq -4$",
      "$x \\leq 2$",
      "$x \\lt -1$",
      "$x \\lt -3$",
      "$x \\lt -4$",
    ];
    const j = MCRandom.randomInt(0, 9);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Describe this number line using algebra.", false)
    );
    const img = new MCQuestion.Image(
      `GCSE/GCSE_Crossover_Algebra_Seai_Inequalities_Exam_Q4/numberLine${imagearray[j]}.jpg`,
      "width",
      20
    );
    question.addImage("question", img);

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` The number line can be described as ${answers[j]}`,
        false
      )
    );

    question.requiresSelfMarking();
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_Seai_SimultaneousEquations_Exam_Q1() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    function roundToTwo(num) {
      return +`${Math.round(`${num}e+2`)}e-2`;
    }

    const person1 = MCMisc.getName();
    const person2 = MCMisc.getName();
    const fruits = ["bananas", "watermelons", "apples", "pears", "kiwis"];
    const fruit1 = fruits[MCRandom.randomInt(0, fruits.length - 1)];
    const fruit2 = fruits[MCRandom.randomInt(0, fruits.length - 1)];
    const numOfFruit1ForPerson1 = MCRandom.randomInt(2, 6);
    const numOfFruit2ForPerson1 = MCRandom.randomInt(2, 6);
    const numOfFruit1ForPerson2 = MCRandom.randomInt(2, 6);
    const numOfFruit2ForPerson2 = MCRandom.randomInt(2, 6);
    const fruit1Cost = MCRandom.randomInt(20, 50);
    const fruit2Cost = MCRandom.randomInt(20, 50);
    const person1cost =
      (numOfFruit1ForPerson1 * fruit1Cost +
        numOfFruit2ForPerson1 * fruit2Cost) /
      100;
    const person2cost =
      (numOfFruit1ForPerson2 * fruit1Cost +
        numOfFruit2ForPerson2 * fruit2Cost) /
      100;
    const fruit1Singular = fruit1.substring(0, fruit1.length - 1);
    const fruit2Singular = fruit2.substring(0, fruit2.length - 1);
    const numOfFruit1ForPerson1Mult =
      numOfFruit1ForPerson1 * numOfFruit2ForPerson2;
    const numOfFruit2ForPerson1Mult =
      numOfFruit2ForPerson1 * numOfFruit2ForPerson2;
    const person1costMult = roundToTwo(person1cost * numOfFruit2ForPerson2);
    const numOfFruit1ForPerson2Mult =
      numOfFruit1ForPerson2 * numOfFruit2ForPerson1;
    const numOfFruit2ForPerson2Mult =
      numOfFruit2ForPerson2 * numOfFruit2ForPerson1;
    const person2costMult = roundToTwo(person2cost * numOfFruit2ForPerson1);

    const xCoeSubtraction =
      numOfFruit1ForPerson1Mult - numOfFruit1ForPerson2Mult;
    const costSubtraction = roundToTwo(person1costMult - person2costMult);
    const x = fruit1Cost / 100;
    const y = fruit2Cost / 100;

    // if(numOfFruit1ForPerson1Mult >

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` 2 shoppers, ${person1.name} and ${person2.name}, go to their local market and buy some fruit.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person1.name} buys ${numOfFruit1ForPerson1} ${fruit1} and ${numOfFruit2ForPerson1} ${fruit2}`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person2.name} buys ${numOfFruit1ForPerson2} ${fruit1} and ${numOfFruit2ForPerson2} ${fruit2}`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person1.name} spends $\\pounds${person1cost}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person2.name} spends $\\pounds${person2cost}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` What are the prices of the ${fruit1Singular} and ${fruit2Singular}?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Start by formulating a simultaneous equation:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` Let $x = ${fruit1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` Let $y = ${fruit2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Now we let the quantity of fruits that each person buys, equal the amount of money they spend:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(1)$ For ${person1.name}: $${numOfFruit1ForPerson1}x + ${numOfFruit2ForPerson1}y = ${person1cost}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(2)$ For ${person2.name}: $${numOfFruit1ForPerson2}x + ${numOfFruit2ForPerson2}y = ${person2cost}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " In order for us to be able to solve the equation we need to be working in terms of one letiable only",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " We do this by multiplying equation $(1)$ and $(2)$ such that the coefficients of either the $y$ or $x$ terms are the same in both equations like so:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " (In this case we make the $y$ coefficients the same)",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(1)$ $${numOfFruit1ForPerson1}x + ${numOfFruit2ForPerson1}y = ${person1cost}$ $( \\times ${numOfFruit2ForPerson2})$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\implies$ $${numOfFruit1ForPerson1Mult}x + ${numOfFruit2ForPerson1Mult}y = ${person1costMult}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(2)$ $${numOfFruit1ForPerson2}x + ${numOfFruit2ForPerson2}y = ${person2cost}$ $( \\times ${numOfFruit2ForPerson1})$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $\\implies$ $${numOfFruit1ForPerson2Mult}x + ${numOfFruit2ForPerson2Mult}y = ${person2costMult}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Subtract equation $(1)$ from equation $(2)$:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${xCoeSubtraction}x${1} = ${costSubtraction}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Divide by the $x$ coefficient to obtain the value of $x$:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $x = (${costSubtraction})  \\div (${xCoeSubtraction})$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $x = ${x}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Substitute this value for $x$ into either of the equations and solve for $y$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${numOfFruit1ForPerson1}(${x}) + ${numOfFruit2ForPerson1}y = ${person1cost}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${numOfFruit2ForPerson1}y = ${person1cost} - (${numOfFruit1ForPerson1}  \\times ${x})$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${numOfFruit2ForPerson1}y = ${roundToTwo(
          numOfFruit2ForPerson1 * y
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $y = ${y}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore $${fruit1}$ costs $${DisplayMoney(
          fruit1Cost
        )}$ and $${fruit2}$ costs $${DisplayMoney(fruit2Cost)}$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(fruit1Cost, 0.01, fruit1);
    group.addInput(fruit2Cost, 0.01, fruit2);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      fruit1 === fruit2 ||
      person1cost * 10 === Math.round(person1cost * 10) ||
      person2cost * 10 === Math.round(person2cost * 10) ||
      person1cost === person2cost ||
      numOfFruit1ForPerson1 === numOfFruit2ForPerson1 ||
      numOfFruit1ForPerson2 === numOfFruit2ForPerson2 ||
      person1costMult === person2costMult ||
      xCoeSubtraction === 1 ||
      fruit1Cost === fruit2Cost ||
      fruit1 > 100 ||
      fruit2 > 100
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Algebra_Seai_SimultaneousEquations_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    function roundToTwo(num) {
      return +`${Math.round(`${num}e+2`)}e-2`;
    }

    const person1 = MCMisc.getName();
    const person2 = MCMisc.getName();
    const fruits = ["bananas", "watermelons", "apples", "pears", "kiwis"];
    const fruit1 = fruits[MCRandom.randomInt(0, fruits.length - 1)];
    const fruit2 = fruits[MCRandom.randomInt(0, fruits.length - 1)];
    const numOfFruit1ForPerson1 = MCRandom.randomInt(2, 6);
    const numOfFruit2ForPerson1 = MCRandom.randomInt(2, 6);
    const numOfFruit1ForPerson2 = MCRandom.randomInt(2, 6);
    const numOfFruit2ForPerson2 = MCRandom.randomInt(2, 6);
    const fruit1Cost = MCRandom.randomInt(20, 50);
    const fruit2Cost = MCRandom.randomInt(20, 50);
    const person1cost =
      (numOfFruit1ForPerson1 * fruit1Cost +
        numOfFruit2ForPerson1 * fruit2Cost) /
      100;
    const person2cost =
      (numOfFruit1ForPerson2 * fruit1Cost +
        numOfFruit2ForPerson2 * fruit2Cost) /
      100;
    const fruit1Singular = fruit1.substring(0, fruit1.length - 1);
    const fruit2Singular = fruit2.substring(0, fruit2.length - 1);
    const numOfFruit1ForPerson1Mult =
      numOfFruit1ForPerson1 * numOfFruit2ForPerson2;
    const numOfFruit2ForPerson1Mult =
      numOfFruit2ForPerson1 * numOfFruit2ForPerson2;
    const person1costMult = roundToTwo(person1cost * numOfFruit2ForPerson2);
    const numOfFruit1ForPerson2Mult =
      numOfFruit1ForPerson2 * numOfFruit2ForPerson1;
    const numOfFruit2ForPerson2Mult =
      numOfFruit2ForPerson2 * numOfFruit2ForPerson1;
    const person2costMult = roundToTwo(person2cost * numOfFruit2ForPerson1);

    const xCoeSubtraction =
      numOfFruit1ForPerson1Mult - numOfFruit1ForPerson2Mult;
    const costSubtraction = roundToTwo(person1costMult - person2costMult);
    const x = fruit1Cost / 100;
    const y = fruit2Cost / 100;

    // if(numOfFruit1ForPerson1Mult >

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` 2 shoppers, ${person1.name} and ${person2.name}, go to their local market and buy some fruit.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person1.name} buys ${numOfFruit1ForPerson1} ${fruit1} and ${numOfFruit2ForPerson1} ${fruit2}`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person2.name} buys ${numOfFruit1ForPerson2} ${fruit1} and ${numOfFruit2ForPerson2} ${fruit2}`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person1.name} spends $£${Math.round(person1cost * 100)}$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person2.name} spends $£${Math.round(person2cost * 100)}$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` What are the prices of the ${fruit1Singular} and ${fruit2Singular}?`,
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Start by formulating a simultaneous equation:", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` Let $x = ${fruit1}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` Let $y = ${fruit2}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Now we let the quantity of fruits that each person buys, equal the amount of money they spend:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(1)$ For ${person1.name}: $${numOfFruit1ForPerson1}x + ${numOfFruit2ForPerson1}y = ${person1cost}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(2)$ For ${person2.name}: $${numOfFruit1ForPerson2}x + ${numOfFruit2ForPerson2}y = ${person2cost}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " In order for us to be able to solve the equation we need to be working in terms of one letiable only",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " We do this by multiplying equation $(1)$ and $(2)$ such that the coefficients of either the $y$ or $x$ terms are the same in both equations like so:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " (In this case we make the $y$ coefficients the same)",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(1)\\ ${numOfFruit1ForPerson1}x + ${numOfFruit2ForPerson1}y = ${person1cost}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\implies$ $${numOfFruit1ForPerson1Mult}x + ${numOfFruit2ForPerson1Mult}y = ${person1costMult}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(2)\\ ${numOfFruit1ForPerson2}x + ${numOfFruit2ForPerson2}y = ${person2cost}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $\\implies$ $${numOfFruit1ForPerson2Mult}x + ${numOfFruit2ForPerson2Mult}y = ${person2costMult}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Subtract equation $(1)$ from equation $(2)$:", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${xCoeSubtraction}x = ${costSubtraction}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Divide by the $x$ coefficient to obtain the value of $x$:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $x = (${costSubtraction})  \\div (${xCoeSubtraction})$`,
        false
      )
    );
    question.addParagraph("solution", MCMaths.cleaner(` $x = ${x}$`, false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Substitute this value for $x$ into either of the equations and solve for $y$.",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${numOfFruit1ForPerson1}(${x}) + ${numOfFruit2ForPerson1}y = ${person1cost}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${numOfFruit2ForPerson1}y = ${person1cost} - (${numOfFruit1ForPerson1}  \\times ${x})$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${numOfFruit2ForPerson1}y = ${roundToTwo(
          numOfFruit2ForPerson1 * y
        )}$`,
        false
      )
    );
    question.addParagraph("solution", MCMaths.cleaner(` $y = ${y}$`, false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore $${fruit1}$ costs $${fruit1Cost}p$ and $${fruit2}$ costs $${fruit2Cost}p$.`,
        false
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(fruit1Cost, 0.1, `${fruit1} price in pence`);
    group.addInput(fruit2Cost, 0.1, `${fruit2} price in pence`);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      fruit1 === fruit2 ||
      person1cost * 10 === Math.round(person1cost * 10) ||
      person2cost * 10 === Math.round(person2cost * 10) ||
      person1cost === person2cost ||
      numOfFruit1ForPerson1 === numOfFruit2ForPerson1 ||
      numOfFruit1ForPerson2 === numOfFruit2ForPerson2 ||
      person1costMult === person2costMult ||
      xCoeSubtraction === 1 ||
      fruit1Cost === fruit2Cost
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_Sequences_AdvancedSequences_Exam_Q1() {
  function QuadSeq(a, b, c, n) {
    return a * n * n + b * n + c;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const a = MCRandom.randomInt(-10, 10);
    const b = MCRandom.randomInt(1, 10);
    const c = MCRandom.randomInt(1, 10);

    const x1 = QuadSeq(a, b, c, 1);
    const x2 = QuadSeq(a, b, c, 2);
    const x3 = QuadSeq(a, b, c, 3);
    const x4 = QuadSeq(a, b, c, 4);
    const x5 = QuadSeq(a, b, c, 5);

    const y1 = x2 - x1;
    const y2 = x3 - x2;
    const y3 = x4 - x3;
    const y4 = x5 - x4;

    const z1 = y2 - y1;
    const z2 = y3 - y2;
    const z3 = y4 - y3;

    const w1 = QuadSeq(0, b, c, 1);
    const w2 = QuadSeq(0, b, c, 2);
    const w3 = QuadSeq(0, b, c, 3);
    const w4 = QuadSeq(0, b, c, 4);
    const w5 = QuadSeq(0, b, c, 5);

    const v1 = w2 - w1;
    const v2 = w3 - w2;
    const v3 = w4 - w3;
    const v4 = w5 - w4;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("Find the $n$th terms of this sequence:", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${QuadSeq(a, b, c, 1)}, \\ ${QuadSeq(a, b, c, 2)}, \\ ${QuadSeq(
          a,
          b,
          c,
          3
        )}, \\ ${QuadSeq(a, b, c, 4)}, \\ ${QuadSeq(a, b, c, 5)}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Finding the first differences: $${y1}, \\ ${y2}, \\ ${y3}, \\ ${y4}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Finding the second differences: $${z1}, \\ ${z2}, \\ ${z3}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So the coefficient of $n^2$ is $${z1 / 2}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Subtracting $${a}n^2$ from the original sequence,` +
          `$${w1}, \\ ${w2}, \\ ${w3}, \\ ${w4}, \\ ${w5}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Finding the first differences: $${v1}, \\ ${v2}, \\ ${v3}, \\ ${v4}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`So the coefficient of $n$ is $${b}$.`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Subtracting $${a}n^2+${b}n$ from the original sequence, ` +
          `$${c}, \\ ${c}, \\ ${c}, \\ ${c}, \\ ${c}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So the $n$th term is $${a}n^2+${b}n+${c}$.`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();

    if (a === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\ (USE ALT (92))
// || (USE ALT (124))
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)


export function GCSE_Crossover_Algebra_Sequences_AdvancedSequences_Exam_Q2() {
  function QuadSeq(a, b, c, n) {
    return a * n * n + b * n + c;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const a = MCRandom.randomInt(-10, 10);
    const b = MCRandom.randomInt(2, 10);
    const c = MCRandom.randomInt(1, 10);
    const n = MCRandom.randomInt(4, 10);
    const ans = QuadSeq(a, b, c, n);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A sequence has the $n$th term of $${a}n^2+${b}n+${c}$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Find the $${n}$th term in the sequence.`,
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(${a} \\times${n}^2)+(${b} \\times${n})+${c}=${ans}$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0);
    question.addInputGroup(group);

    if (a === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\ (USE ALT (92))
// || (USE ALT (124))
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)


export function GCSE_Crossover_Algebra_Sequences_AdvancedSequences_Exam_Q3() {
  function QuadSeq(a, b, c, n) {
    return a * n * n + b * n + c;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const a = MCRandom.randomInt(2, 10);
    const b = MCRandom.randomInt(2, 10);
    const c = MCRandom.randomInt(-10, -1);
    const n = MCRandom.randomInt(4, 10);
    const ans = QuadSeq(a, b, c, n);
    const finalC = c - ans;
    const notN = (-b - Math.sqrt(b * b - 4 * a * finalC)) / (2 * a);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A sequence has the $n$th term of $${a}n^2+${b}n${c}$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `What term of the sequence is $${ans}$?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${a}n^2+${b}n${c}=${ans}$.`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${a}n^2+${b}n${finalC}=0$.`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Using the quadratic formula, $n=${n}$ or $n=${notN}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `But as $n$ is a term number, $n=${n}$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(n, 0);
    question.addInputGroup(group);

    if (a === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\ (USE ALT (92))
// || (USE ALT (124))
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)


export function GCSE_Crossover_Algebra_Sequences_AdvancedSequences_Exam_Q4() {
  function QuadSeq(a, b, c, n) {
    return a * n * n + b * n + c;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const a = MCRandom.randomInt(-10, 10);
    const b = MCRandom.randomInt(2, 10);
    const c = MCRandom.randomInt(1, 10);
    const n = MCRandom.randomInt(4, 10);
    const ans1 = QuadSeq(a, b, c, 1);
    const ans2 = QuadSeq(a, b, c, 2);
    const ans3 = QuadSeq(a, b, c, 3);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A sequence has the $n$th term of $${a}n^2+${b}n+${c}$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find the first 3 terms in the sequence.",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(${a} \\times${1}^2)+(${b} \\times${1})+${c}=${ans1}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(${a} \\times${2}^2)+(${b} \\times${2})+${c}=${ans2}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(${a} \\times${3}^2)+(${b} \\times${3})+${c}=${ans3}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So the first 3 terms are $${ans1},${ans2},${ans3}$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans1, 0, "$1st \\ $ Term");
    group.addInput(ans2, 0, "$2nd \\ $ Term");
    group.addInput(ans3, 0, "$3rd \\ $ Term");
    question.addInputGroup(group);

    if (a === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\ (USE ALT (92))
// || (USE ALT (124))
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)


export function GCSE_Crossover_Algebra_Sequences_AdvancedSequences_Exam_Q5() {
  function QuadSeq(a, b, c, n) {
    return a * n * n + b * n + c;
  }

  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // part a
    const initial = MCRandom.randomInt(50, 100);
    const add = MCRandom.randomInt(5, 20);

    const name1 = MCMisc.getName();
    const name2 = MCMisc.getName();

    // part b
    const a = MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(5, 20);
    const c = MCRandom.randomInt(50, 100);
    const initial2 = QuadSeq(a, b, c, 1);
    const a2 = 2 * a;
    const add1 = 2 * a + b;
    const add2 = 4 * a + b;
    const add3 = 6 * a + b;

    const i1 = QuadSeq(a, b, c, 1);
    const i2 = QuadSeq(a, b, c, 2);
    const i3 = QuadSeq(a, b, c, 3);
    const i4 = QuadSeq(a, b, c, 4);

    const j1 = QuadSeq(0, b, c, 1);
    const j2 = QuadSeq(0, b, c, 2);
    const j3 = QuadSeq(0, b, c, 3);
    const j4 = QuadSeq(0, b, c, 4);

    const l1 = j2 - j1;
    const l2 = j3 - j2;
    const l3 = j4 - j3;

    // MONEY in bank, 10 then 12 then 14, etc etc etc, = n^2+9n, part a linear, part b quadratic.

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} has $\\pounds${initial}$ in ${name1.hisher} bank account, and decides to save an extra $\\pounds${add}$ per month.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Find a formula to find how much money ${name1.name} has in ${name1.hisher} bank account after n months.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name2.name} has $\\pounds${initial2}$ in ${
          name2.hisher
        } bank account, and decides to save $\\pounds${add1}$, then $\\pounds${add2}$, then $\\pounds${add3}$ each month (saving an extra $${DisplayMoney(
          2 * a * 100
        )}$ each month).`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Find a formula to find how much money ${name2.name} has in ${name2.hisher} bank account after n months.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a)\\ ${initial}+${add}n$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ The first difference of the sequence is $${add1},${add2},${add3}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `The second difference of the sequence is $${a2},${a2},${a2}$, which is constant, so this is a quadratic sequence, with the coefficient of $n^2$ being \${${a2}\\over 2}=${a}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `The origial sequence is $${i1},${i2},${i3},${i4}$, so subtracting $${a}n^2$ leaves $${j1},${j2},${j3},${j4}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `The first difference of this sequence is $${l1},${l2},${l3}$, so the coefficient of $n$ is $${b}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Subtracting $${b}n$ from the second sequence leaves $${c}$, so ${name2.name} has $\\pounds(${a}n^2+${b}n+${c})$ after $n$ months.`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();

    if (name1.name === name2.name) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\ (USE ALT (92))
// || (USE ALT (124))
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)


export function GCSE_Crossover_Algebra_Sequences_AdvancedSequences_Exam_Q6() {
  function QuadSeq(a, b, c, n) {
    return a * n * n + b * n + c;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(-10, 10);
    const b = 2;
    const c = 1;
    const x1 = QuadSeq(a, b, c, 1);
    const x2 = QuadSeq(a, b, c, 2);
    const x3 = QuadSeq(a, b, c, 3);
    const x4 = QuadSeq(a, b, c, 4);

    const y1 = x2 - x1;
    const y2 = x3 - x2;
    const y3 = x4 - x3;

    const z1 = y2 - y1;
    const z2 = y3 - y2;

    const w1 = QuadSeq(0, b, c, 1);
    const w2 = QuadSeq(0, b, c, 2);
    const w3 = QuadSeq(0, b, c, 3);
    const w4 = QuadSeq(0, b, c, 4);

    const v1 = w2 - w1;
    const v2 = w3 - w2;
    const v3 = w4 - w3;

    const t5 = QuadSeq(a, b, c, 5);
    const t6 = QuadSeq(a, b, c, 6);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A quadratic sequence starts with $${x1},\\ ${x2},\\ ${x3},\\ ${x4}...$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find the next two terms in the sequence.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Finding the first differences: $${y1}, \\ ${y2}, \\ ${y3}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Finding the second differences: $${z1}, \\ ${z2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So the coefficient of $n^2$ is $${z1 / 2}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Subtracting $${a}n^2$ from the original sequence, $${w1}, \\ ${w2}, \\ ${w3}, \\ ${w4}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Finding the first differences: $${v1}, \\ ${v2}, \\ ${v3}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`So the coefficient of $n$ is $${b}$.`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Subtracting $${a}n^2+${b}n$ from the original sequence, ` +
          `$${c}, \\ ${c}, \\ ${c}, \\ ${c}, \\ ${c}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So the $n$th term is $${a}n^2+${b}n+${c}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So the $5^{th}$ and $6^{th}$ terms of the sequence are $${t5}$ and $${t6}$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(t5, 0, "Fith Term:");
    group.addInput(t6, 0, "Sixth Term:");
    question.addInputGroup(group);

    if (a === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Algebra_SolvingEquationsAndInequalities_AlgebraProblems_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const x = MCRandom.randomInt(2, 20);
    const mw = MCRandom.randomInt(1, 4);
    const cw = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2)
    const width = mw * x + cw

    const mh = MCRandom.randomInt(1, 4);
    const ch = MCRandom.randomInt(1, 10) * MCRandom.randomInt(-1, 1, 2)
    const height = mh * x + ch

    const perimeter = 2 * (width + height)

      /* END VARIABLES AND CONSTANTS */
    if ( width < height && width > 2 && cw !== -ch) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const img = new MCQuestion.Image(`GCSE/Perimeter_and_area/Rectangle1.jpg`, 'width', 15);
      img.addOverlay(MCMaths.cleaner(`$${mw}x+${cw}$`), 50, -5);
      img.addOverlay(MCMaths.cleaner(`$${mh}x+${ch}$`), 107, 50);
      question.addImage('question', img);


      question.addParagraph('question', `A parallelogram has the dimesions defined above, shown in terms of $x$.`);
      question.addParagraph('question', `a) Write down the formula for the perimeter of the rectangle.`);
      question.addParagraph('question', `b) Given that the rectangle has a perimeter of $${perimeter}$ units, find the value of $x$.`);

      //
      question.addHeading('solution', `a) The perimeter, $P$ is the sum of the length of all $4$ sides of the rectangle or $2($length + width$)$.`);
      question.addParagraph('solution', MCMaths.cleaner(`$P = 2(${mw}x+${cw} + ${mh}x+${ch})$`));
      question.addParagraph('solution', MCMaths.cleaner(`$= 2(${mw+mh}x+${cw+ch})$`));
      question.addParagraph('solution', MCMaths.cleaner(`$= ${2*(mw+mh)}x+${2*(cw+ch)}$`));

      // b

      question.addHeading('solution', `b) Equate the formula calculated in part (a) to the actual perimeter of the rectangle and solve.`);
      question.addParagraph('solution', MCMaths.cleaner(`$${2*(mw+mh)}x+${2*(cw+ch)}=${perimeter}$`));
      question.addParagraph('solution', `$${2*(mw+mh)}x=${perimeter - 2 * (cw+ch)}$`);
      question.addParagraph('solution', `$\\therefore x=${x}$`);

      // answers
      question.requiresSelfMarking();
      const g = new MCQuestion.InputGroup(1);
      g.addInput(x, 0, `$(b)$`);
      question.addInputGroup(g)
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_SolvingEquationsAndInequalities_AlgebraProblems_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p1 = new MCMisc.getName();
    const p2 = new MCMisc.getName();

    const a = MCRandom.randomInt(15 , 40)

    const m = MCRandom.randomInt(2,5)
    const c = MCRandom.randomInt(4, 20) * MCRandom.randomInt(-1,1,2)

    const b = m * a + c

    const sum = a*(m+1) + c

    const t = MCRandom.randomInt(10, 15)

    const built = sum * t + a;
    /* END VARIABLES AND CONSTANTS */
    if ( p1.name !== p2.name && b < 100 && p2.name.substr(p2.name.length - 1) !== 's' && p1.name.substr(p1.name.length - 1) !== 's'
    && built < 1900 && built > 1000 ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      if (c < 0)
      {
      question.addParagraph("question", `${p1.name} is $${-c}$ years younger than $${m}$ times ${p2.name}'s age.`)
      }
      else
      {
      question.addParagraph("question", `${p1.name} is $${c}$ years older than $${m}$ times ${p2.name}'s age.`)
      }

      question.addParagraph('question', `The sum of their ages is $${sum}$`);

      question.addParagraph('question', `a) Find ${p2.name}'s age.`);

      question.addParagraph('question', `${p1.name} and ${p2.name} come across a building that was built in the year that is $${t}$ times the sum of their ages plus ${p1.name}'s age.`);

      question.addParagraph('question', `b) Calculate when the building was built.`);

      // 

      question.addHeading('solution', `a) Let ${p1.name}'s age be $a$.`);
      question.addParagraph('solution', `Form an equation for the sum of their ages in terms of $a$ and solve.`);
      question.addParagraph('solution', MCMaths.cleaner(`$a + (${m}a+${c})=${sum}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$${m+1}a+${c}=${sum}$`));
      question.addParagraph('solution', `$${m+1}a=${sum-c}$`);
      question.addParagraph('solution', `$\\therefore a=${a}$`);
      question.addHeading('solution', `Sub into the expression for ${p2.name}'s age.`);
      question.addParagraph('solution', MCMaths.cleaner(`$${m}(${a})+${c}=${b}$`));
      question.addParagraph('solution', `$\\therefore$ ${p2.name} is $${b}$ years old.`);

      question.addHeading('solution', `b) Let the year the building was constructed be $y$.`);
      question.addParagraph('solution', `Form an equation for the sum of their ages in terms of $a$ and solve.`);
      question.addParagraph('solution', `$y=${t}(${sum})+${a}$`);
      question.addParagraph('solution', `$=${built}$`);
      question.addParagraph('solution', `$\\therefore$ The building was built in $${built}$`);

      // 
      const g = new MCQuestion.InputGroup(1);
      g.addInput(b, 0, `$(a)$`);
      g.addInput(built, 0, `$(b)$`);
      question.addInputGroup(g)
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_SolvingEquationsAndInequalities_AlgebraProblems_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const adult = MCRandom.randomInt(40, 100, 5);
    const child = MCRandom.randomInt(20, 60, 5);

    const a1 = MCRandom.randomInt(2,8);
    const c1 = MCRandom.randomInt(2,8);
    const cost1 = a1 * adult + c1 * child;

    const a2 = MCRandom.randomInt(2,8);
    const c2 = MCRandom.randomInt(2,8);
    const cost2 = a2 * adult + c2 * child;

    const p1 = MCMisc.getName();
    const p2 = MCMisc.getName();

    const aCoeff = a2 - (c2 * a1/c1)
    const aEquals = cost2 - (c2*cost1/c1)
    /* END VARIABLES AND CONSTANTS */
    if ( adult - child > 15 && a1 !== a2 && c1 !== c2 && a2 !== (c2*a1/c1) && aCoeff !== 1 && parseFloat(MCMaths.cleaner(`${aCoeff}`).match(/\d+/g)[0]) < 100 && parseFloat(MCMaths.cleaner(`${aEquals}`).match(/\d+/g)[0]) < 100
      && p1.name !== p2.name) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph("question", `${p1.name} bought flights for his family of $${a1}$ adults and $${c1}$ children which cost ${p1.himher} $£${cost1}$`);
      question.addParagraph("question", `${p2.name} bought the same flights for $${a2}$ adults and $${c2}$ children which cost ${p2.himher} $£${cost2}$`);

      question.addParagraph('question', `Calculate the costs of the adult and child tickets.`);

      // solution

      question.addHeading('solution', `Let the cost, in pounds, of an adult ticket be $a$ and the cost of a child ticket be $c$.`);
      question.addParagraph('solution', `Form a pair of equations using the information given in the question.`);
      question.addParagraph('solution', `$1: ${a1}a+${c1}c=${cost1}$`);
      question.addParagraph('solution', `$2: ${a2}a+${c2}c=${cost2}$`);
      question.addHeading('solution', `Make $c$ the subject of the first equation.`);
      question.addParagraph('solution', `$${c1}c=${cost1}-${a1}a$`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle c=${cost1/c1}-${a1/c1}a$`));

      question.addHeading('solution', `Sub your value of $c$ into the second equation and solve for $a$.`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle ${a2}a+${c2}(${cost1/c1}-${a1/c1}a)=${cost2}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle ${a2}a + ${c2*cost1/c1}-${c2 * a1/c1}a=${cost2}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle ${aCoeff}a =${aEquals}$`));
      question.addParagraph('solution', `$\\therefore a=${adult}$`);
      question.addHeading('solution', `Sub your value of $a$ into the equation where $c$ is the subject.`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle c=${cost1/c1}-${a1/c1}(${adult})$`));
      question.addParagraph('solution', MCMaths.cleaner(`$=${child}$`));

      question.addParagraph('solution', `$\\therefore$ An adult ticket costs $£${adult}$ and a child ticket costs $£${child}$`);

      // answers

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(adult, 0, `Adult = `);
      g1.addInput(child, 0, `Child = `);

      question.addInputGroup(g1);
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_SolvingEquationsAndInequalities_AlgebraProblems_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const milkshake = MCRandom.randomInt(300, 500, 5)/100;
    const soft = MCRandom.randomInt(100, 250, 5)/100;

    const m1 = MCRandom.randomInt(4,12);
    const s1 = MCRandom.randomInt(4,12);
    const cost1 = parseFloat((m1 * milkshake + s1 * soft).toFixed(2));

    const times = MCRandom.randomInt(2, 4);
    const minus = MCRandom.randomInt(4, Math.floor(m1*times/2));

    const m2 = m1 * times - minus;
    const s2 = MCRandom.randomInt(4,12);
    const cost2 = parseFloat((m2 * milkshake + s2 * soft).toFixed(2));

    const p1 = MCMisc.getName();
    const p2 = MCMisc.getName();

    const mTotal = m1 + m2;
    const n = m1  + m2 +s1  + s2;

    const mCoeff = m2 - (s2 * m1/s1)
    const mEquals = cost2 - (s2*cost1/s1)

    /* END VARIABLES AND CONSTANTS */
    if ( p2.name.substr(p2.name.length - 1) !== 's' && p1.name.substr(p1.name.length - 1) !== 's' && Math.abs(m1 - m2 ) < 6 &&  m1 !== m2 && s1 !== s2 && m2 !== (s2*m1/s1) && mCoeff !== 1 
      && parseFloat(MCMaths.cleaner(`${mCoeff}`).match(/\d+/g)[0]) < 100 && parseFloat(MCMaths.cleaner(`${mEquals}`).match(/\d+/g)[0]) < 100
      && p1.name !== p2.name ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph('question', `${p1.name} and ${p2.name} both place orders for a combination of milkshakes and soft drinks at a drinks bar.`);
      question.addParagraph('question', `${p2.name} orders $${minus}$ less than $${times}$ times as many milkshakes as ${p1.name} and $${s2}$ soft drinks.`);
      question.addParagraph('question', `Together, their orders consist of $${mTotal}$ milkshakes and $${n}$ drinks.`);
      question.addParagraph('question', `a) Find the number of milkshakes ${p1.name} ordered.`);
      question.addParagraph('question', `b) Given that ${p1.name}'s order cost $£${cost1.toFixed(2)}$ and ${p2.name}'s order cost $£${cost2.toFixed(2)}$, calculate the cost of a milkshake and the cost of a soft drink.`);
  
      // solution
      question.addHeading('solution', `a) Let the number of milkshakes ${p1.name} ordered be $n$.`);
      question.addParagraph('solution', `Form an equation for the total number of milkshakes ordered in terms of $n$ and solve.`);
      question.addParagraph('solution', MCMaths.cleaner(`$n + (${times}n-${minus})=${mTotal}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$${times+1}n-${minus}=${mTotal}$`));
      question.addParagraph('solution', `$${times+1}n=${mTotal+minus}$`);
      question.addParagraph('solution', `$n=${m1}$`);

      question.addParagraph('solution', `$\\therefore$ ${p1.name} ordered $${m1}$ milkshakes.`);

      question.addHeading('solution', `b) Calculate the number of milkshakes ${p2.name} ordered.`);
      question.addParagraph('solution', `$${times}(${m1})-${minus}=${m2}$`);
      question.addHeading('solution', `Calculate the number of soft drinks ${p1.name} ordered.`);
      question.addParagraph('solution', `The total number of soft drinks ordered is $${n}-${mTotal}=${s1+s2}$.`);
      question.addParagraph('solution', `So ${p1.name} ordered $${s1+s2}-${s2}=${s1}$`);

      question.addHeading('solution', `Let $m$ be the price of a milkshake and $s$ be the price of a soft drink.`);
      question.addParagraph('solution', `Form a pair of equations using the information given in the question.`);

      question.addParagraph('solution', `$1: ${m1}m+${s1}s=${cost1}$`);
      question.addParagraph('solution', `$2: ${m2}m+${s2}s=${cost2}$`);
      question.addHeading('solution', `Make $s$ the subject of the first equation.`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle ${s1}s=${cost1}-${m1}m$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle s=${cost1/s1}-${m1/s1}a$`));

      question.addHeading('solution', `Sub your value of $s$ into the second equation and solve for $m$.`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle ${m2}m+${s2}(${cost1/s1}-${m1/s1}m)=${cost2}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle ${m2}m + ${s2*cost1/s1}-${s2 * m1/s1}m=${cost2}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle ${mCoeff}m =${mEquals}$`));
      question.addParagraph('solution', `$\\therefore m=${milkshake}$`);
      question.addHeading('solution', `Sub your value of $m$ into the equation where $s$ is the subject.`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle s=${cost1/s1}-${m1/s1}(${milkshake})$`));
      question.addParagraph('solution', `$=${soft}$`);

      question.addParagraph('solution', `$\\therefore$ A milkshake is $£${milkshake.toFixed(2)}$ and a soft drink is $£${soft.toFixed(2)}$`);


      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(m1, 0, `$(a)$`);

      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(milkshake, 0, `$(b)\\ $ Milkshake = `);
      g2.addInput(soft, 0, `$(b)\\ $ Soft Drink = `);

      question.addInputGroup(g2);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Algebra_SolvingEquationsAndInequalities_AlgebraProblems_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const x = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);
    const y = MCRandom.randomInt(1, 8) * MCRandom.randomInt(-1, 1, 2);

    const xw = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const yw = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const xl = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const yl = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const w =  x * xw + y * yw;
    const l = x * xl + y * yl;

    const perimeter = 2*(l+w);

    const xCoeff = xl - (yl * xw / yw);
    const xEquals = l - (yl * w / yw);
    /* END VARIABLES AND CONSTANTS */
    if ( w > 1 && l > 1 && xw !== xl && yw !== yl && w < l && w < 80 && l < 80
      && parseFloat(MCMaths.cleaner(`${xCoeff}`).match(/\d+/g)[0]) < 100 && parseFloat(MCMaths.cleaner(`${xEquals}`).match(/\d+/g)[0]) < 100) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const img = new MCQuestion.Image(`GCSE/Perimeter_and_area/Rectangle1.jpg`, 'width', 15);
      img.addOverlay(MCMaths.cleaner(`$(${xw}x+${yw}y)$ cm`), 50, -5);
      img.addOverlay(MCMaths.cleaner(`$(${xl}x+${yl}y)$ cm`), 110, 50);

      img.addOverlay(MCMaths.cleaner(`$${w}$ cm`), 50, 105);
      img.addOverlay(MCMaths.cleaner(`$l$ cm`), 0, 50);
      question.addImage('question', img);

      question.addParagraph('question', `The rectangle above has a perimeter of $${perimeter}$ cm.`);
      question.addParagraph('question', `a) Calculate the value of $l$.`);
      question.addParagraph('question', `b) Find the values of $x$ and $y$.`);

      // solution

      question.addHeading('solution', `a) The perimeter, $P$ is the sum of the length of all $4$ sides of the rectangle or $2($length + width$)$. `);
      question.addParagraph('solution', `Given that $P=${perimeter}$`);
      question.addParagraph('solution', `$2(l+${w})=${perimeter}$`);
      question.addParagraph('solution', `$l+${w}=${perimeter/2}$`);
      question.addParagraph('solution', `$\\therefore l=${l}$`);

      // b

      question.addHeading('solution', `b) Form a pair of equations using the diagram provided in the question.`);
      question.addParagraph('solution', MCMaths.cleaner(`$1: ${xw}x+${yw}y=${w}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$2: ${xl}x+${yl}y=${l}$`));

      question.addHeading('solution', `Make $y$ the subject of the first equation.`);
      question.addParagraph('solution', MCMaths.cleaner(`$${yw}y=${w}-${xw}x$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle y=${w/yw}-${xw/yw}x$`));

      question.addHeading('solution', `Sub your value of $y$ into the second equation and solve for $x$.`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle ${xl}x+${yl}(${w/yw}-${xw/yw}x)=${l}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle ${xl}x + ${yl * w / yw}-${yl * xw / yw}x=${l}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle ${xCoeff}x =${xEquals}$`));
      question.addParagraph('solution', `$\\therefore x=${x}$`);
      question.addHeading('solution', `Sub your value of $x$ into the equation where $y$ is the subject.`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle y=${w/yw}-${xw/yw}(${x})$`));
      question.addParagraph('solution', `$=${y}$`);

      // 

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(l, 0, `$(a)\\ l =$`);

      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(x, 0, `$(b)\\ x =$`);
      g2.addInput(y, 0, `$(b)\\ y = $`);

      question.addInputGroup(g2);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Geometry_Mac_CircleMeasurments_Exam_Q1() {
  let count = 0;
  const img_scale = 1.4;

  for (let i = 1; i <= 1; i += 1) {
    const radius = MCRandom.randomInt(12, 24);
    const angle = MCRandom.randomInt(30, 70);

    const length = (angle / 360) * radius * 2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image("GCSE/Sector3.jpg", "height", 15);
    img.addOverlay(`$${radius}cm$`, 55 / img_scale, 10);
    img.addOverlay(`$${radius}cm$`, 150 / img_scale - 7, 80 / img_scale);
    img.addOverlay(`$${angle}^\\circ$`, 118 / img_scale, 26 / img_scale);
    img.addOverlay("$O$", 150 / img_scale - 5, 0);
    img.addOverlay("$A$", -5, 30);
    img.addOverlay("$B$", 135 / img_scale - 7, 100);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$OAB$ is a sector of a circle, centre $O$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Work out the length of $AB$, giving your answer in the form $x\\pi$.",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Arc Length $= \\frac{\\theta}{360}  \\times 2\\pi r$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$AB = \\frac{${angle}}{360}  \\times 2\\pi  \\times ${radius}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$AB = \\frac{${angle}}{360}  \\times \\pi  \\times ${radius * 2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$ AB = ${length}\\pi$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(length, 0.01, "$x=$");
    question.addInputGroup(group);

    if (
      length.length === null ||
      length.error === 1 ||
      Math.round(length * 1000) !== length * 1000
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Mac_CircleMeasurments_Exam_Q2() {
  let count = 0;
  const img_scale = 1.4;

  for (let i = 1; i <= 1; i += 1) {
    const radius = MCRandom.randomInt(12, 24);
    const angle = MCRandom.randomInt(30, 70);

    const length = (angle / 360) * radius * 2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image("GCSE/Sector3.jpg", "height", 15);
    img.addOverlay(`$${radius}cm$`, 55 / img_scale, 10);
    img.addOverlay(`$${radius}cm$`, 150 / img_scale - 7, 80 / img_scale);
    img.addOverlay(`$${angle}^\\circ$`, 118 / img_scale, 26 / img_scale);
    img.addOverlay("$O$", 150 / img_scale - 5, 0);
    img.addOverlay("$A$", -5, 30);
    img.addOverlay("$B$", 135 / img_scale - 7, 100);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$OAB$ is a sector of a circle, centre $O$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Work out the perimeter of $OAB$, giving your answer to $3dp$.",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Arc Length $= \\frac{\\theta}{360}  \\times 2\\pi r$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$AB = \\frac{${angle}}{360}  \\times 2\\pi  \\times ${radius}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$= \\frac{${angle}}{360}  \\times \\pi  \\times ${radius * 2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$ = ${length}\\pi$`, false, true, true)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$ \\approx ${(length * Math.PI).toFixed(3)}$`,
        false,
        false,
        false
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Adding radii:`, false, false, false)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Perimeter = $${(length * Math.PI).toFixed(3)}+${radius}+${radius}$`,
        false,
        false,
        false
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` = $${(length * Math.PI + radius + radius).toFixed(3)}cm$`,
        false,
        false,
        false
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(length * Math.PI + radius + radius, 0.01);
    question.addInputGroup(group);

    if (
      length.length === null ||
      length.error === 1 ||
      Math.round(length * 1000) !== length * 1000
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Mac_CircleMeasurments_Exam_Q3() {
  let count = 0;
  const img_scale = 1.45;
  for (let i = 1; i <= 1; i += 1) {
    function randomLength(lower, upper) {
      const int = MCRandom.randomInt(lower, upper);
      const dec = MCRandom.randomInt(1, 9) / 10;
      return int + dec;
    }
    function toRadians(angle) {
      return angle * (Math.PI / 180);
    }
    function toDegrees(angle) {
      return angle * (180 / Math.PI);
    }

    const radius = MCRandom.randomInt(11, 24);
    const angle = MCRandom.randomInt(135, 165);
    const radians = toRadians(angle);
    const area = (angle / 360) * Math.PI * radius ** 2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image("GCSE/Sectors/Sector5.jpg", "height", 15);
    img.addOverlay(`$${radius}cm$`, 5 / img_scale + 11, 75 / img_scale);
    img.addOverlay(`$${radius}cm$`, 120 / img_scale, 75 / img_scale);
    img.addOverlay(
      `$${angle}^\\circ $`,
      63 / img_scale + 5,
      55 / img_scale + 4
    );
    img.addOverlay("$O$", 68 / img_scale - 2, 90 / img_scale);
    question.addImage("question", img);
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The diagram shows a sector of a circle, centre $O$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find the area of the sector, give your answer correct to 3 significant figures.",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Area $= \\frac{${angle}}{360}  \\times \\pi  \\times (${radius})^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Area $= \\frac{${angle}}{360}  \\times \\pi  \\times ${
          radius * radius
        }$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Area $= ${parseFloat(area.toFixed(5))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\approx ${area.toPrecision(3)}cm^2$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(area, 0.5);
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Mac_CircleMeasurments_Exam_Q4() {
  let count = 0;
  const img_scale = 1.5;

  for (let i = 1; i <= 1; i += 1) {
    const radius = MCRandom.randomInt(2, 9);
    const arcLength = MCRandom.randomInt(2, 20);

    function angleFromArcLength(arc, rad) {
      let angle = 0;
      let working = "";
      working = "Arc Length $= \\frac{\\theta}{360}  \\times 2\\pi r$, ";
      working += `$ ${arc} = \\frac{\\theta}{360}  \\times 2\\pi  \\times ${rad}$, `;
      working += `$ ${arc} = \\frac{\\theta}{360}  \\times \\pi  \\times ${
        rad * 2
      }$, `;
      working += `$ \\frac{${arc}}{${rad * 2}\\pi} = \\frac{\\theta}{360}$, `;
      working += `$ \\frac{${arc}}{${rad * 2}\\pi}  \\times 360 = \\theta$, `;
      working += `$ \\theta = \\frac{${arc}}{${rad * 2}\\pi}  \\times 360$, `;
      angle = parseFloat(((arc / (rad * 2 * Math.PI)) * 360).toFixed(6));
      working += `$\\theta = ${angle}^\\circ $`;
      return {
        angle,
        working,
      };
    }
    function areaFromAngleAndRad(angle, rad) {
      let working = "";
      let area = 0;
      working += `Area $= \\frac{${angle}}{360}  \\times \\pi  \\times (${rad})^2$, `;
      working += `Area $= \\frac{${angle}}{360}  \\times \\pi  \\times ${
        rad * rad
      }$, `;
      area = (angle / 360) * Math.PI * radius ** 2;
      working += `Area $= ${parseFloat(area.toPrecision(3))}cm^2$.`;
      return {
        area,
        working,
      };
    }

    const angle = angleFromArcLength(arcLength, radius);
    const area = areaFromAngleAndRad(angle.angle, radius);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image("GCSE/Sectors/Sector1.jpg", "height", 15);
    img.addOverlay(`$${radius}cm$`, 6, 45);
    img.addOverlay(`$${radius}cm$`, 105 / img_scale - 15, 145 / img_scale);
    img.addOverlay(`$${arcLength}cm$`, 130 / img_scale, 40 / img_scale);
    img.addOverlay("$\\theta$", 24 / img_scale, 120 / img_scale);
    img.addOverlay("$O$", -5 / img_scale, 155 / img_scale);
    question.addImage("question", img);
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The diagram shows a sector of a circle, centre $O$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Find the area of the sector.", false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(angle.working, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(area.working, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(area.area, 0.1);
    question.addInputGroup(group);

    if (angle.angle >= 75 || angle.angle <= 35 || arcLength === radius) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Mac_CircleMeasurments_Exam_Q5() {
  let count = 0;
  const img_scale = 1.55;
  for (let i = 1; i <= 1; i += 1) {
    const length = MCRandom.randomInt(4, 36);

    function toRadians(angle) {
      return angle * (Math.PI / 180);
    }
    function areaFromAngleAndRad(angle, rad) {
      let working = "";
      let area = 0;
      working += `Area $= \\frac{${angle}}{360}  \\times \\pi  \\times (${rad})^2$`;
      working += `Area $= \\frac{${angle}}{360}  \\times \\pi  \\times ${
        rad * rad
      }$`;
      area = (angle / 360) * Math.PI * rad ** 2;
      working += `Area $= ${parseFloat(area.toFixed(5))}$`;
      working += `Area $= ${parseFloat(area.toPrecision(3))}cm^2$`;
      return {
        area,
        working,
      };
    }
    function arcLength(angle, rad) {
      let working = "";
      let length2 = 0;
      let error = 0;
      working = "Arc Length $= \\frac{\\theta}{360}  \\times 2\\pi r$";
      working += `$AB = \\frac{${angle}}{360}  \\times 2\\pi  \\times ${rad}$`;
      working += `$AB = \\frac{${angle}}{360}  \\times \\pi  \\times ${
        rad * 2
      }$`;
      length2 = (angle / 360) * rad * 2;
      if (length2 !== parseFloat(length2.toFixed(3))) {
        error = 1;
      }
      working += `$ AB = ${length2}\\pi$`;
      return {
        length: length2,
        working,
        error,
      };
    }

    const triangleArea = 0.5 * length ** 2 * Math.sin(toRadians(60));
    const area = areaFromAngleAndRad(60, length / 2);
    const shadedArea =
      parseFloat(triangleArea.toFixed(6)) - parseFloat(area.area.toFixed(6));

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image(
      "GCSE/Sectors/tri_sector1.jpg",
      "height",
      15
    );
    img.addOverlay(`$${length}cm$`, 13, 60 / img_scale);
    img.addOverlay("$C$", 78 / img_scale, -14 / img_scale);
    img.addOverlay("$A$", 0 / img_scale, 155 / img_scale);
    img.addOverlay("$B$", 158 / img_scale, 155 / img_scale);
    img.addOverlay("$D$", 130 / img_scale, 70 / img_scale);
    img.addOverlay("$E$", 80 / img_scale, 160 / img_scale);
    question.addImage("question", img);
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$ABC$ is an equilateral triangle, with sides of length $${length}cm$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$BDE$ is a sector of a circle, centre $B$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$D$ is the midpoint of $BC$ and $E$ is the midpoint of $AB$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Work out the area of the shaded region, giving your answer correct to 3 significant figures.",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addHeading(
      "solution",
      MCMaths.cleaner("Triangle", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Area $= \\frac{1}{2}ab\\sin(C)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Area $= \\frac{1}{2} \\times ${length}  \\times ${length}  \\times \\sin(60)$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Area $= ${parseFloat(triangleArea.toFixed(5))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Area $= ${parseFloat(triangleArea.toPrecision(3))}cm^2$`,
        false,
        true,
        true
      )
    );
    question.addHeading(
      "solution",
      MCMaths.cleaner("Sector", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(area.working, false, true, true)
    );
    question.addHeading(
      "solution",
      MCMaths.cleaner("Shaded Region", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Area $= ${parseFloat(triangleArea.toFixed(6))} - ${parseFloat(
          area.area.toFixed(6)
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Area $= ${parseFloat(shadedArea.toFixed(6))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Area $= ${parseFloat(shadedArea.toPrecision(3))}cm^2$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(shadedArea, 0.51);
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Mac_TriangleTheorems_Exam_Q1() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    let mode = MCRandom.randomInt(0, 2);
    const sct = MCRandom.randomInt(0, 5); // 01 is sin, 23 is cos, 45 is tan

    if (sct === 0) {
      mode = MCRandom.randomInt(0, 1);
    }
    if (sct === 1) {
      mode = MCRandom.randomInt(0, 1) * 2;
    }
    if (sct === 2) {
      mode = MCRandom.randomInt(0, 1) * 2;
    }
    if (sct === 3) {
      mode = MCRandom.randomInt(0, 1);
    }

    let long;
    let short;
    let hyp;
    let angS;
    let angB;

    if (mode === 0) {
      long = MCRandom.randomInt(5, 9);
      short = MCRandom.randomInt(2, 4);
      hyp = MCRandom.randomInt(long + 2, long + 5);
      angS = "\\alpha";
      angB = "\\alpha";
    }

    // long
    if (mode === 1) {
      long = "x";
      short = MCRandom.randomInt(2, 4);
      hyp = MCRandom.randomInt(9, 16);
      angS = MCRandom.randomInt(15, 40);
      angB = MCRandom.randomInt(50, 75);
    }

    // short
    if (mode === 2) {
      long = MCRandom.randomInt(5, 9);
      short = "x";
      hyp = MCRandom.randomInt(long + 2, long + 5);
      angS = MCRandom.randomInt(15, 40);
      angB = MCRandom.randomInt(50, 75);
    }

    const shortLoc = [65, 128, 125, 65, 70, 125, -2, 55];
    const longLoc = [5, 70, 72, 18, 132, 70, 55, 6];
    const hypLoc = [98, 70, 70, 85, 38, 68, 70, 63];
    const angSLoc = [38, 48, 39, 35, 103, 45, 85, 22];
    const angBLoc = [81, 110, 110, 90, 49, 110, 21, 77];

    const im = MCRandom.randomInt(0, 3);

    const question = new MCQuestion.ExamQuestion();
    if (mode === 0) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          "Given this right angled triangle, find the size of angle $\\alpha$ to $3dp$. (Diagram not drawn actually).",
          false,
          true,
          true
        )
      );
    }
    if (mode === 1 || mode === 2) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          "Given this right angled triangle, find the size of side $x$ to $3dp$. (Diagram not drawn actually).",
          false,
          true,
          true
        )
      );
    }

    const img_scale = 1.3;
    const img = new MCQuestion.Image(
      `GCSE/RightAngledTriangles/${im}.JPG`,
      "height",
      15
    );
    if (sct === 0 || sct === 3 || sct === 4 || sct === 5) {
      img.addOverlay(
        `$${long}cm$`,
        longLoc[2 * im] / img_scale,
        longLoc[2 * im + 1] / img_scale
      );
    }
    if (sct === 1 || sct === 2 || sct === 4 || sct === 5) {
      img.addOverlay(
        `$${short}cm$`,
        shortLoc[2 * im] / img_scale,
        shortLoc[2 * im + 1] / img_scale
      );
    }
    if (sct === 0 || sct === 1 || sct === 2 || sct === 3) {
      img.addOverlay(
        `$${hyp}cm$`,
        hypLoc[2 * im] / img_scale,
        hypLoc[2 * im + 1] / img_scale
      );
    }
    if (sct === 1 || sct === 3 || sct === 5) {
      img.addOverlay(
        `$${angS}^\\circ$`,
        angSLoc[2 * im] / img_scale,
        angSLoc[2 * im + 1] / img_scale
      );
    }
    if (sct === 0 || sct === 2 || sct === 4) {
      img.addOverlay(
        `$${angB}^\\circ$`,
        angBLoc[2 * im] / img_scale,
        angBLoc[2 * im + 1] / img_scale
      );
    }

    question.addImage("question", img);

    let ans = 0;
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    if (sct === 0) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\sin(${angB})={${long}  \\over ${hyp}}$`,
          false,
          true,
          true
        )
      );
    }
    if (sct === 1) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\sin(${angS})={${short} \\over ${hyp}}$`,
          false,
          true,
          true
        )
      );
    }
    if (sct === 2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\cos(${angB})={${short} \\over ${hyp}}$`,
          false,
          true,
          true
        )
      );
    }
    if (sct === 3) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\cos(${angS})={${long}  \\over ${hyp}}$`,
          false,
          true,
          true
        )
      );
    }
    if (sct === 4) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\tan(${angB})={${long} \\over ${short}}$`,
          false,
          true,
          true
        )
      );
    }
    if (sct === 5) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\tan(${angS})={${short} \\over ${long}}$`,
          false,
          true,
          true
        )
      );
    }

    if (mode === 0) {
      if (sct === 0) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\alpha=\\sin^{-1}\\left({${long} \\over ${hyp}}\\right)$`,
            false,
            true,
            true
          )
        );
        ans = Math.asin(long / hyp);
      }
      if (sct === 1) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\alpha=\\sin^{-1}\\left({${short} \\over ${hyp}}\\right)$`,
            false,
            true,
            true
          )
        );
        ans = Math.asin(short / hyp);
      }
      if (sct === 2) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\alpha=\\cos^{-1}\\left({${short} \\over ${hyp}}\\right)$`,
            false,
            true,
            true
          )
        );
        ans = Math.acos(short / hyp);
      }
      if (sct === 3) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\alpha=\\cos^{-1}\\left({${long} \\over ${hyp}}\\right)$`,
            false,
            true,
            true
          )
        );
        ans = Math.acos(long / hyp);
      }
      if (sct === 4) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\alpha=\\tan^{-1}\\left({${long} \\over ${short}}\\right)$`,
            false,
            true,
            true
          )
        );
        ans = Math.atan(long / short);
      }
      if (sct === 5) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\alpha=\\tan^{-1}\\left({${short} \\over ${long}}\\right)$`,
            false,
            true,
            true
          )
        );
        ans = Math.atan(short / long);
      }
    }
    if (mode === 1) {
      if (sct === 0) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$x=${hyp}\\sin(${angB})$`, false, true, true)
        );
        ans = hyp * Math.sin(angB * (Math.PI / 180));
      }
      if (sct === 3) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$x=${hyp}\\cos(${angS})$`, false, true, true)
        );
        ans = hyp * Math.cos(angS * (Math.PI / 180));
      }
      if (sct === 4) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$x=${short}\\tan(${angB})$`, false, true, true)
        );
        ans = short * Math.tan(angB * (Math.PI / 180));
      }
      if (sct === 5) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$x={${short}\\over\\tan(${angS})}$`,
            false,
            true,
            true
          )
        );
        ans = short / Math.tan(angS * (Math.PI / 180));
      }
    }

    if (mode === 2) {
      if (sct === 1) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$x=${hyp}\\sin(${angS})$`, false, true, true)
        );
        ans = hyp * Math.sin(angS * (Math.PI / 180));
      }
      if (sct === 2) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$x=${hyp}\\cos(${angB})$`, false, true, true)
        );
        ans = hyp * Math.cos(angB * (Math.PI / 180));
      }
      if (sct === 4) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$x={${long}\\over\\tan(${angB})}$`,
            false,
            true,
            true
          )
        );
        ans = long / Math.tan(angB * (Math.PI / 180));
      }
      if (sct === 5) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$x=${long}\\tan(${angS})$`, false, true, true)
        );
        ans = long * Math.tan(angS * (Math.PI / 180));
      }
    }

    if (mode === 0) {
      ans = (180 * ans) / Math.PI;
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${angS}=${ans}$`, false, true, true)
      );
    }
    if (mode === 1 || mode === 2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x=${ans}$`, false, true, true)
      );
    }

    if (mode === 0) {
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans, 0.1, "$\\alpha=$");
      question.addInputGroup(group);
    }
    if (mode === 1 || mode === 2) {
      const group = new MCQuestion.InputGroup(1);
      group.addInput(ans, 0.1, "$x=$");
      question.addInputGroup(group);
    }

    if (1 === 2 || Math.round(ans * 10000) === ans * 10000) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Mac_TriangleTheorems_Exam_Q2() {
  let count = 0;
  const img_scale = 2.3;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const floor = MCRandom.randomInt(40, 100);
    const ladder = MCRandom.randomInt(200, 340);
    const ans1 = Math.sqrt(ladder * ladder - floor * floor);
    const ans2 = Math.acos(floor / ladder);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} places a ladder $${floor}cm$ away from a wall.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Given the ladder is $${ladder}cm$ long, find to $3dp$:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ How high on the wall does the latter reach $(x)$?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ What angle does the latter form with the ground $(\\alpha)$?",
        false,
        true,
        true
      )
    );
    const img = new MCQuestion.Image(
      "GCSE/RightAngledTriangles/Ladder.JPG",
      "height",
      20
    );
    img.addOverlay("$x$", 173 / img_scale + 12, 178 / img_scale - 10);
    img.addOverlay("$\\alpha$", 105 / img_scale + 10, 224 / img_scale - 16);

    img.addOverlay(`$${ladder}cm$`, 72 / img_scale + 10, 153 / img_scale - 5);
    img.addOverlay(`$${floor}cm$`, 100 / img_scale + 18, 257 / img_scale - 17);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      `$(a) \\ x=\\sqrt{${ladder}^2-${floor}^2}=${ans1}$`
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ \\cos(\\alpha)={${floor}\\over${ladder}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\alpha=\\cos^{-1}\\left({${floor}\\over${ladder}}\\right)$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\alpha=${ans2 * (180 / Math.PI)}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ans1, 0.1, "$(a) \\ x=$");
    group.addInput(ans2 * (180 / Math.PI), 0.1, "$(b) \\ \\alpha=$");
    question.addInputGroup(group);

    if (1 === 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Mac_TriangleTheorems_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const north = MCRandom.randomInt(3, 15);
    const east = MCRandom.randomInt(2, 10);
    const ans1 = Math.sqrt(north * north + east * east);
    const ans2 = (180 * Math.tan(east / north)) / Math.PI;
    const ans2b = Math.round((180 * Math.tan(east / north)) / Math.PI);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} walks $${north}m$ north, turns $90^\\circ$ east and then walks $${east}m$ forward.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ How far is ${name.name} from where ${name.heshe} started?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ What Bearing is ${name.name}'s final position from ${name.hisher} starting position?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      `$(a) \\ \\sqrt{${north}^2+${east}^2}=${ans1} m$`
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ \\tan\\left({${east} \\over ${north}}\\right)=${ans2}^\\circ$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So the bearing is $0${ans2b}^\\circ$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ans1, 0.1, "$(a)$");
    group.addInput(ans2b, 0.1, "$(b)$");
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Mac_TriangleTheorems_Exam_Q4() {
  let count = 0;
  const img_scale = 1.3;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const height = MCRandom.randomInt(2, 6);
    const angle = MCRandom.randomInt(20, 40);
    const price = MCRandom.randomInt(10, 30);
    const length = MCRandom.randomInt(5, 20);
    const hyp = height / Math.sin((Math.PI * angle) / 180);
    const area = (Math.round(hyp * 1000) / 1000) * length;
    const notans = area * price;
    const ans = Math.round(area * price * 100) / 100;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} is tiling one side of a roof. The height of the roof is $${height}m$, and it's angle from the horizontal is $${angle}^\\circ$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ What is the length of the hypotenuse of the roof to $3dp$?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Each square metre of tiling costs ${name.name} $\\pounds${price}$. The roof is $${length}m$ long.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ How much will is cost ${name.name} to tile the roof?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    const img = new MCQuestion.Image(
      "GCSE/RightAngledTriangles/1r.JPG",
      "height",
      15
    );
    img.addOverlay("$x$", 75 / img_scale - 10, 23 / img_scale + 20);
    img.addOverlay(
      `$${angle}^\\circ$`,
      82 / img_scale + 5,
      58 / img_scale + 27
    );
    img.addOverlay(`$${height}m$`, -11 / img_scale + 12, 36 / img_scale + 21);
    question.addImage("solution", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\displaystyle \\sin(${angle})={${height}\\over x}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\displaystyle x={${height}\\over \\sin(${angle})}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$x=${Math.round(hyp * 1000) / 1000}m$ to $3dp$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ Area of the roof is approximately equal to $${
          Math.round(hyp * 1000) / 1000
        } \\times${length}=${area}m^2$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${area} \\times ${price}=${notans}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, It will cost ${name.name} $\\pounds${ans} $ to tile the roof.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(hyp, 0.1, "$(a)$");
    group.addInput(ans, 0.1, "$(b)$");
    question.addInputGroup(group);

    if (
      1 === 2 ||
      Math.round(hyp) === hyp ||
      Math.round(ans * 10) === ans * 10
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Mac_TriangleTheorems_Exam_Q5() {
  let count = 0;
  const img_scale = 1.9;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const height = MCRandom.randomInt(2, 6);
    const angle = MCRandom.randomInt(1, 4);
    const len = MCRandom.randomInt(35, 45) / 10;
    const x =
      Math.round((len / 2 / Math.tan((Math.PI * (angle / 2)) / 180)) * 1000) /
      1000;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.name} is looking at a car.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The car takes up $${angle}^\\circ$ of ${name.hisher} field of view, and the car is $${len}m$ long.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("How far away is the car to $3dp$?", false, true, true)
    );

    const img = new MCQuestion.Image(
      "GCSE/RightAngledTriangles/double.JPG",
      "height",
      15
    );
    img.addOverlay("$x$", 112 / img_scale - 5, 55 / img_scale + 8);
    img.addOverlay(
      `$${angle / 2}^\\circ$`,
      80 / img_scale + 1,
      95 / img_scale + 12
    );
    img.addOverlay(
      `$${angle / 2}^\\circ$`,
      110 / img_scale,
      95 / img_scale + 12
    );
    img.addOverlay(`$${len / 2}m$`, 43 / img_scale + 6, 2);
    img.addOverlay(`$${len / 2}m$`, 135 / img_scale, 2);
    question.addImage("solution", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\displaystyle \\tan(${angle / 2})={${len / 2}\\over x}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\displaystyle x={${len / 2}\\over \\tan(${angle / 2})}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x=${x}m$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(x, 0.1);
    question.addInputGroup(group);

    if (1 === 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Mac_TriangleTheorems_Exam_Q6() {
  let count = 0;
  const img_scale = 1.8;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const name2 = MCMisc.getName();
    const height = MCRandom.randomInt(10, 30);
    const angle1 = MCRandom.randomInt(40, 60);
    const angle2 = MCRandom.randomInt(40, 60);
    const x1 =
      Math.round((height / Math.tan((Math.PI * angle1) / 180)) * 1000) / 1000;
    const x2 =
      Math.round((height / Math.tan((Math.PI * angle2) / 180)) * 1000) / 1000;
    const dist = x1 + x2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} and ${name2.name} are standing either side of a $${height}m$ tall lighthouse.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The top of the lighthouse is at an angle of $${angle1}^\\circ$ from ${name.name}'s point of view, and $${angle2}^\\circ$ from ${name2.name}'s.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `How far away is ${name.name} from ${name2.name}, to $3dp$?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    const img = new MCQuestion.Image(
      "GCSE/RightAngledTriangles/doubler.JPG",
      "height",
      15
    );
    img.addOverlay("$x_1$", 43 / img_scale, 156 / img_scale + 9);
    img.addOverlay("$x_2$", 142 / img_scale - 3, 156 / img_scale + 9);
    img.addOverlay(
      `$${angle1}^\\circ$`,
      32 / img_scale + 1,
      134 / img_scale + 10
    );
    img.addOverlay(
      `$${angle2}^\\circ$`,
      151 / img_scale - 4,
      134 / img_scale + 10
    );
    img.addOverlay(`$${height}m$`, 107 / img_scale - 1, 88 / img_scale + 3);
    question.addImage("solution", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\displaystyle \\tan(${angle1})={${height}\\over x_1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\displaystyle x_1={${height}\\over \\tan(${angle1})}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x_1=${x1}m$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\displaystyle \\tan(${angle2})={${height}\\over x_2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\displaystyle x_2={${height}\\over \\tan(${angle2})}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x_2=${x2}m$`, false, true, true)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(` Distance $= x_1+x_2=${dist}m$.`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(dist, 0.1);
    question.addInputGroup(group);

    if (angle1 === angle2 || name === name2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Mac_TriangleTheorems_Exam_Q7() {
  let count = 0;
  const img_scale = 1.8;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const type = MCRandom.randomInt(0, 3);
    const length = MCRandom.randomInt(11, 30) / 10;
    const length2 = (2 * MCRandom.randomInt(10, 30)) / 10;
    const angle1 = MCRandom.randomInt(50, 70);
    const angle2 = 2 * (90 - angle1);
    let h = 0;
    let b = length2 / 2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner("Find the area of this triangle:", false, true, true)
    );
    const img = new MCQuestion.Image(
      "GCSE/RightAngledTriangles/iso.JPG",
      "height",
      15
    );
    if (type === 0 || type === 1) {
      img.addOverlay(
        `$${angle1}^\\circ$`,
        23 / img_scale + 10,
        160 / img_scale
      );
    }
    if (type === 2 || type === 3) {
      img.addOverlay(`$${angle2}^\\circ$`, 57 / img_scale + 18, 60 / img_scale);
    }
    if (type === 0 || type === 2) {
      img.addOverlay(`$${length}m$`, 113 / img_scale + 30, 106 / img_scale + 5);
    } // a
    if (type === 1 || type === 3) {
      img.addOverlay(`$${length2}m$`, 47, 186 / img_scale - 3);
    } // b
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Spliiting the Triangle into two:", false, true, true)
    );
    const img2 = new MCQuestion.Image(
      "GCSE/RightAngledTriangles/isoright.JPG",
      "height",
      15
    );
    if (type === 0 || type === 1) {
      img2.addOverlay(
        `$${angle1}^\\circ$`,
        23 / img_scale + 10,
        160 / img_scale
      );
    }
    if (type === 2 || type === 3) {
      img2.addOverlay(
        `$${angle2 / 2}^\\circ$`,
        43 / img_scale + 16,
        79 / img_scale + 3
      );
    }
    if (type === 0 || type === 2) {
      img2.addOverlay(
        `$${length}m$`,
        113 / img_scale + 30,
        106 / img_scale + 5
      );
    } // a
    if (type === 1 || type === 3) {
      img2.addOverlay(
        `$${length2 / 2}m$`,
        17 / img_scale + 15,
        179 / img_scale + 1
      );
    } // b
    if (type === 0 || type === 2) {
      img2.addOverlay("$b$", 29 / img_scale + 4, 184 / img_scale - 1);
    }
    img2.addOverlay("$h$", 73 / img_scale + 1, 116 / img_scale);
    question.addImage("solution", img2);
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Finding the height of the triangle, ", false, true, true)
    );
    if (type === 0) {
      h = length * Math.sin((angle1 * Math.PI) / 180);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$h=${length}\\sin(${angle1})=${h}$`, false, true, true)
      );
    }
    if (type === 1) {
      h = (length2 * Math.tan((angle1 * Math.PI) / 180)) / 2;
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$h=${length2 / 2}\\tan(${angle1})=${h}$`,
          false,
          true,
          true
        )
      );
    }
    if (type === 2) {
      h = length * Math.cos((angle2 * Math.PI) / 360);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$h=${length}\\cos(${angle2 / 2})=${h}$`,
          false,
          true,
          true
        )
      );
    }
    if (type === 3) {
      h = length2 / (2 * Math.tan((angle2 * Math.PI) / 360));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$h={${length2 / 2}\\over \\tan(${angle2 / 2})}=${h}$`,
          false,
          true,
          true
        )
      );
    }
    if (type === 0 || type === 2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          "Finding the base of the each triangle, ",
          false,
          true,
          true
        )
      );
    }
    if (type === 0) {
      b = length * Math.cos((angle1 * Math.PI) / 180);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$b=${length}\\cos(${angle1})=${b}$`, false, true, true)
      );
    }
    if (type === 2) {
      b = length * Math.sin((angle2 * Math.PI) / 360);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$b=${length}\\sin(${angle2 / 2})=${b}$`,
          false,
          true,
          true
        )
      );
    }
    const area = 0.5 * b * h;
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So the area of each smaller triangle is half base times height, \${1\\over 2} \\times${b} \\times${h}=${area}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Hence the total area of the triangle is twice this, $=${
          2 * area
        }m^2$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(2 * area, 0.1);
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Mac_TriangleTheorems_Exam_Q8() {
  let count = 0;
  const img_scale = 2.7;
  for (let i = 1; i <= 1; i += 1) {
    const ladder = MCRandom.randomInt(200, 340);
    const max = MCRandom.randomInt(75, 85);
    const min = MCRandom.randomInt(55, 73);
    const ans1 = ladder * Math.cos((max * Math.PI) / 180);
    const ans2 = ladder * Math.cos((min * Math.PI) / 180);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A $${ladder}cm$ long ladder can be placed safely at angle between $${min}^\\circ$ and $${max}^\\circ$ with the ground.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find the range of distances the ladder can be placed from the wall.",
        false,
        true,
        true
      )
    );

    const img = new MCQuestion.Image(
      "GCSE/RightAngledTriangles/Ladder.JPG",
      "height",
      20
    );
    img.addOverlay("$y$", 173 / img_scale + 24, 178 / img_scale);
    img.addOverlay("$\\alpha$", 105 / img_scale + 15, 224 / img_scale - 1);
    img.addOverlay(`$${ladder}cm$`, 72 / img_scale + 13, 153 / img_scale + 8);
    img.addOverlay("$x$", 113 / img_scale + 19, 255 / img_scale - 1);
    question.addImage("solution", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Finding the minimun value of $x$, $\\cos(${max})={x\\over ${ladder}}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x=${ladder}\\cos(${max})$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x=${ans1}cm$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Finding the maximun value of $x$, $\\cos(${min})={x\\over ${ladder}}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x=${ladder}\\cos(${min})$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x=${ans2}cm$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`So $${ans1}\\le x \\le${ans2}$.`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ans1, 0.1, "$x\\ge$");
    group.addInput(ans2, 0.1, "$x\\le$");
    question.addInputGroup(group);

    if (1 === 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Mac_TrigExactValues_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const b_index = MCRandom.randomInt(0,2)
    const angle = [30, 45, 60][b_index];

    const exactValue = [1/2, Math.sqrt(2)/2, Math.sqrt(3)/2][b_index]

    const hypotenuse = MCRandom.randomInt(8,20);

    const a_index = MCRandom.randomInt(0,4)
    const a = [0, 30, 45, 60, 90][a_index]
    const a_ans = [0, 1/2, Math.sqrt(2)/2, Math.sqrt(3)/2, 1][a_index]

    const x = exactValue * hypotenuse

    const b = Math.sqrt(hypotenuse*hypotenuse - x*x)
    /* END VARIABLES AND CONSTANTS */
    if (parseFloat(MCMaths.cleaner(`${b}`).match(/\d+/g)[0]) < 100 && a!==angle) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const triangle = new MCQuestion.Image(`GCSE/RightAngledTriangles/angle0.svg`, `width`, 20);
      triangle.addOverlay(`$${angle}\\degree$`, 30, 30);
      triangle.addOverlay(`$${hypotenuse}$ cm`, 65, 50);
      triangle.addOverlay(`$x$ cm`, 50, 95);

      triangle.addOverlay(`$A$`, 17, 96, 1.5);
      triangle.addOverlay(`$B$`, 18, 0, 1.5);
      triangle.addOverlay(`$C$`, 83, 96, 1.5);


      question.addImage('question', triangle);

      question.addParagraph('question', `a) State the exact value of $\\sin(${a})$.`);
      question.addParagraph('question', `b) Find the value of $x$.`);
      question.addParagraph('question', `c) Calculate the length of the missing side $AB$.`);

      // solution

      question.addHeading('solution', `a) You are expected to know exact values of $\\sin(x)$ for $x=0,30,45,60,90$`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle \\sin(${a})=${a_ans}$`));
      // b

      question.addHeading('solution', `b) Remember the trigonometric ratio, $\\sin \\theta = \\displaystyle \\frac{O}{H}$`);
      question.addParagraph('solution', `Let $\\theta=${angle}, O = x, H = ${hypotenuse}$`);
      question.addParagraph('solution', `$\\sin(${angle}) = \\displaystyle \\frac{x}{${hypotenuse}}$`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle ${exactValue} = \\frac{x}{${hypotenuse}}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle \\therefore x=${x}$`));

      // c

      question.addHeading('solution', `c) Use Pythagoras' Theorem $a^2+b^2=c^2$`);
      question.addParagraph('solution', MCMaths.cleaner(`Let $\\displaystyle a=${x}, c=${hypotenuse}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle (${x})^2+b^2=${hypotenuse}^2$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle b= \\sqrt{${hypotenuse}^2-(${x})^2}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle \\therefore b= ${b}$`));

      // answers


      const a1 = new MCQuestion.InputGroup(3);
      a1.addInput(a_ans, 0.05, `$(a)$`)
      a1.addInput(x, 0.05, `$(b)$`)
      a1.addInput(b, 0.05, `$(c)$`)
      question.addInputGroup(a1)



      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Geometry_Mac_TrigExactValues_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const angleIndex = MCRandom.randomInt(0,2)
    const angle = [30, 45, 60][angleIndex];

    const exactValue = [Math.sqrt(3)/2, Math.sqrt(2)/2, 1/2][angleIndex]

    const hypotenuse = MCRandom.randomInt(8,20);

    const adjacent = exactValue * hypotenuse;

    const a_index = MCRandom.randomInt(0,4)
    const a = [0, 30, 45, 60, 90][a_index]
    const a_ans = [1,Math.sqrt(3)/2, Math.sqrt(2)/2, 1/2, 0][a_index]

    const c_ans = 90 - angle

    /* END VARIABLES AND CONSTANTS */
    if ( a!==angle ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const triangle = new MCQuestion.Image(`GCSE/RightAngledTriangles/angle0.svg`, `width`, 20);
      triangle.addOverlay(`$x\\degree$`, 30, 30);
      triangle.addOverlay(`$${hypotenuse}$ cm`, 65, 50);
      triangle.addOverlay(MCMaths.cleaner(`$\\displaystyle ${adjacent}$ cm`), 8, 50);

      triangle.addOverlay(`$A$`, 17, 96, 1.5);
      triangle.addOverlay(`$B$`, 18, 0, 1.5);
      triangle.addOverlay(`$C$`, 83, 96, 1.5);


      question.addImage('question', triangle);

      question.addParagraph('question', `a) State the exact value of $\\cos(${a}).$`);
      question.addParagraph('question', `b) Find the value of angle $x$.`);
      question.addParagraph('question', `c) Calculate the size of the angle $\\angle ACB$`)

      // solution

      question.addHeading('solution', `a) You are expected to know exact values of $\\cos(x)$ for $x=0,30,45,60,90$`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle \\cos(${a})=${a_ans}$`));

      // b

      question.addHeading('solution', `b) Remember the trigonometric ratio, $\\cos \\theta = \\displaystyle \\frac{A}{H}$`);
      question.addParagraph('solution', MCMaths.cleaner(`Let $\\displaystyle \\theta=x, A = ${adjacent}, H = ${hypotenuse}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\cos(x) = \\displaystyle \\frac{${adjacent}}{${hypotenuse}}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle \\cos(x) = ${exactValue}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle \\therefore x=${angle}$`));

      // c 

      question.addHeading('solution', `c) Angles in a triangle add to $180\\degree$`);
      question.addParagraph('solution', `$180-90-${angle}=${c_ans}$`);
      question.addParagraph('solution', `$\\therefore \\measuredangle ACB=${c_ans}\\degree$`);

      // answers


      const a1 = new MCQuestion.InputGroup(3);
      a1.addInput(a_ans, 0.05, `$(a)$`)
      a1.addInput(angle, 0, `$(b)$`)
      a1.addInput(c_ans, 0, `$(c)$`)
      question.addInputGroup(a1)
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Geometry_Mac_TrigExactValues_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const angleIndex = MCRandom.randomInt(0,2)
    const angle = [30, 45, 60][angleIndex];

    const exactValue = [Math.sqrt(3)/3, 1, Math.sqrt(3)][angleIndex]
    const exactValueText = [`\\frac{\\sqrt{3}}{3}`, 1, Math.sqrt(3)][angleIndex]

    const opposite = MCRandom.randomInt(8,20);

    const adjacent = opposite/exactValue;

    const a_index = MCRandom.randomInt(0, 3)
    const a = [0, 30, 45, 60][a_index]
    const a_ans = [0 ,Math.sqrt(3)/3, 1, Math.sqrt(3)][a_index]
    const a_ans_text = [0 ,`\\frac{\\sqrt{3}}{3}`, 1, Math.sqrt(3)][a_index]

    let xText = 0;
    if (angle === 30)
    {
      xText = `${opposite}\\sqrt{3}`;
    }else if (angle === 60)
    {
      xText = `${opposite/3}\\sqrt{3}`;
    }
    else
    {
      xText = `${adjacent}`
    }

    const c_ans = 0.5 * adjacent * opposite;
    /* END VARIABLES AND CONSTANTS */
    if ( a!==angle ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const triangle = new MCQuestion.Image(`GCSE/RightAngledTriangles/angle0.svg`, `width`, 20);
      triangle.addOverlay(`$${angle}\\degree$`, 30, 30);
      triangle.addOverlay(`$${opposite}$ cm`, 50, 97);
      triangle.addOverlay(`$x$ cm`, 10, 50);

      triangle.addOverlay(`$A$`, 17, 96, 1.5);
      triangle.addOverlay(`$B$`, 18, 0, 1.5);
      triangle.addOverlay(`$C$`, 83, 96, 1.5);


      question.addImage('question', triangle);

      question.addParagraph('question', `a) State the exact value of $\\tan(${a}).$`);
      question.addParagraph('question', `b) Calculate the value of $x$.`);
      question.addParagraph('question', `c) Calculate the area of the triangle $ABC.$`);



      //solution
      question.addHeading('solution', `a) You are expected to know exact values of $\\tan(x)$ for $x=0,30,45,60$`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle \\tan(${a})=${a_ans_text}$`));

      // b

      question.addHeading('solution', `b) Remember the trigonometric ratio, $\\tan \\theta = \\displaystyle \\frac{O}{A}$`);
      question.addParagraph('solution', `Let $\\theta=${angle}, O = ${opposite}, A = x$`);
      question.addParagraph('solution', `$\\tan(${angle}) = \\displaystyle \\frac{${opposite}}{x}$`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle x = \\frac{${opposite}}{\\tan(${angle})}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle = \\frac{${opposite}}{${exactValueText}}$`));
      
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle \\therefore x=${xText}$`));

      //c
      question.addHeading('solution', `c) The area of a right-angled triangle is given by $\\displaystyle \\frac{1}{2}bh$`);
      question.addParagraph('solution', MCMaths.cleaner(`Let $\\displaystyle b=${opposite}, h=${xText}$`));
      if (angle === 30)
      {
        question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle \\frac{1}{2}(${opposite})(${xText})=${0.5 * opposite * opposite}\\sqrt{3}$`));
      }else if (angle === 60)
      {
        question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle \\frac{1}{2}(${opposite})(${xText})=${0.5 * opposite * opposite / 3}\\sqrt{3}$`));
      }
      else
      {
        question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle \\frac{1}{2}(${opposite})(${xText})=${c_ans}$`));
      }

      // answers


      const a1 = new MCQuestion.InputGroup(3);
      a1.addInput(a_ans, 0.05, `$(a)$`)
      a1.addInput(adjacent, 0.05, `$(b)$`)
      a1.addInput(c_ans, 0.05, `$(c)$`)
      question.addInputGroup(a1)
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Geometry_Mac_TrigExactValues_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    

    const BCDIndex = MCRandom.randomInt(0,2)
    const BCD = [30, 45, 60][BCDIndex];

    const BCDexactValue = [Math.sqrt(3)/3, 1, Math.sqrt(3)][BCDIndex]
    const BCDexactValueText = [`\\frac{\\sqrt{3}}{3}`, 1, Math.sqrt(3)][BCDIndex]

    const ADBIndex = MCRandom.randomInt(0,2)
    const ADB = [30, 45, 60][ADBIndex];

    const ADBexactValue = [Math.sqrt(3)/2, Math.sqrt(2)/2, 1/2][ADBIndex]


    const AD = MCRandom.randomInt(5, 15);

    const BD = ADBexactValue * AD;

    const BC = BD / BCDexactValue;

    const sinExactValue = [1/2, Math.sqrt(2)/2, Math.sqrt(3)/2][ADBIndex]
    const b_ans = 0.5 * AD * BD * sinExactValue;
    /* END VARIABLES AND CONSTANTS */
    if ( parseFloat(MCMaths.cleaner(`${BC}`).match(/\d+/g)[0]) < 100 && parseFloat(MCMaths.cleaner(`${b_ans}`).match(/\d+/g)[0]) < 100) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const triangle = new MCQuestion.Image(`GCSE/RightAngledTriangles/double_angle0.svg`, `width`, 20);
      triangle.addOverlay(`$${ADB}\\degree$`, 46, 70);
      triangle.addOverlay(`$x\\degree$`, 83, 15);
      triangle.addOverlay(`$${AD}$ cm`, 15, 50);
      triangle.addOverlay(MCMaths.cleaner(`$\\displaystyle ${BC}$ cm`), 70, 0);

      triangle.addOverlay(`$A$`, 5, 5, 1.5);
      triangle.addOverlay(`$B$`, 51, 4, 1.5);
      triangle.addOverlay(`$C$`, 95, 5, 1.5);
      triangle.addOverlay(`$D$`, 50, 98, 1.5);

      triangle.addOverlay(`Not drawn accurately`, 95, 75, 1);

      question.addImage('question', triangle);
      //
      question.addParagraph('question', `Consider the triangle above that is not drawn to scale.`);
      question.addParagraph('question', `a) Find the value of $x$.`);
      question.addParagraph('question', `b) Calculate the area of the triangle $ABD$.`);

      //

      question.addHeading('solution', `a) First calculate the length of side $BD, b$ cm.`);
      question.addParagraph('solution', `Remember the trigonometric ratio, $\\cos \\theta = \\displaystyle \\frac{A}{H}$`);
      question.addHeading('solution', MCMaths.cleaner(`Let $\\displaystyle \\theta=${ADB}, A = b, H = ${AD}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\cos(${ADB}) = \\displaystyle \\frac{b}{${AD}}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle b = ${ADBexactValue}(${AD})$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle \\therefore b=${BD}$`));

      question.addHeading('solution', `Then you can calculate the value of angle $BCD, x\\degree$.`);

      question.addParagraph('solution', `Remember the trigonometric ratio, $\\tan \\theta = \\displaystyle \\frac{O}{A}$`);
      question.addHeading('solution', MCMaths.cleaner(`Let $\\theta=x, O = ${BD}, A = ${BC}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\tan(x) = \\displaystyle \\frac{${BD}}{${BC}}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\tan(x) = \\displaystyle ${BCDexactValueText}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle \\therefore x=${BCD}$`));

      //

      question.addHeading('solution', `b) Area of a triangle is equal to $\\displaystyle\\frac{1}{2}AB\\sin(C)$`);
      question.addParagraph('solution', MCMaths.cleaner(`Let $\\displaystyle A=${AD}, B=${BD}, C=${ADB}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle\\frac{1}{2}(${AD})(${BD})\\sin(${ADB})=${b_ans}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\therefore$ The area of triangle ABD is $\\displaystyle ${b_ans}$ cm$^2$.`));
      // answers

      const a1 = new MCQuestion.InputGroup(3);
      a1.addInput(BCD, 0.05, `$(a)$`)
      a1.addInput(b_ans, 0.05, `$(c)$`)
      question.addInputGroup(a1)
      /* RETURN THE QUESTION */

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Geometry_Mac_TrigExactValues_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const angleIndex = MCRandom.randomInt(0,2)
    const angle = [30, 45, 60][angleIndex];
    const exactValue = [1/2, Math.sqrt(2)/2, Math.sqrt(3)/2][angleIndex];

    const b = MCRandom.randomInt(8, 25);

    const h = MCRandom.randomInt(5, 20);

    const slant = h / exactValue;

    const area = b*h;

    const perimeter = 2*slant + 2*b

    const a_ans = 180 - angle;
    /* END VARIABLES AND CONSTANTS */
    if ( b > h && parseFloat(MCMaths.cleaner(`${slant}`).match(/\d+/g)[0]) < 100) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const parallelogram = new MCQuestion.Image(`Leo/parallelogramh_angle0.svg`, `width`, 40);

      parallelogram.addOverlay(`Not drawn accurately`, 95, 75, 1);

      parallelogram.addOverlay(`$${angle}\\degree$`, 16, 82, 1);
      parallelogram.addOverlay(MCMaths.cleaner(`$\\displaystyle ${h}$ cm`), 31, 55, 1);
      // parallelogram.addOverlay(`$${area}$ cm$^2$`, 50, 55, 1);

      parallelogram.addOverlay(`$A$`, 24, 13, 1.5);
      parallelogram.addOverlay(`$B$`, 92, 13, 1.5);
      parallelogram.addOverlay(`$C$`, 7, 92, 1.5);
      parallelogram.addOverlay(`$D$`, 75, 92, 1.5);

      question.addImage('question', parallelogram);

      question.addParagraph('question', MCMaths.cleaner(`Consider the parallelogram above that has an area of $\\displaystyle ${area}$cm$^2$.`));
      question.addParagraph('question', `a) State the size of angle $\\angle CAB$.`);
      question.addParagraph('question', `b) Calculate the perimeter of the parallelogram.`);

      // 
      question.addHeading('solution', `a) Consecutive angles in a parallelogram must add to $180\\degree$`);
      question.addParagraph('solution', `$180-${angle}=${a_ans}$`);
      question.addParagraph('solution', `$\\therefore\\angle CAB=${a_ans}\\degree$`);
      //b
      question.addHeading('solution', `b) The area, $A$ of a parallelogram is equal to its base, $b$ multiplied by its perpendicular height, $h$.`);
      question.addParagraph('solution', `Let $A=${area}, h=${h}$`);
      question.addParagraph('solution', `$${area}=${h}b$`);
      question.addParagraph('solution', `$\\therefore b=${b}$`);

      question.addHeading('solution', `To calculate the length of the slanted side, use the trigonometric ratio $\\displaystyle \\sin(\\theta)=\\frac{O}{H}$`);
      question.addParagraph('solution', `Let $\\theta=${angle}, O=${h}, H=x$`);
      question.addParagraph('solution', `$\\sin(${angle}) = \\displaystyle \\frac{${h}}{x}$`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle ${exactValue} = \\frac{${h}}{x}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle \\therefore x=${slant}$`));

      question.addHeading('solution', `Then the perimeter is equal to $2b+2x$`);
      if (slant % 0.5 === 0)
      {
        question.addParagraph('solution', MCMaths.cleaner(`$2(${b})+2(${slant})=${perimeter}$`));
      }
      else
      {
        question.addParagraph('solution', MCMaths.cleaner(`$2(${b})+2(${slant})=${2*b} + ${2*slant}$`));
      }

      //

      const a1 = new MCQuestion.InputGroup(3);
      a1.addInput(a_ans, 0.05, `$(a)$`)
      a1.addInput(perimeter, 0.05, `$(b)$`)
      question.addInputGroup(a1)

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Geometry_Pac_Triangles_Exam_Q1() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    const question = new MCQuestion.ExamQuestion();
    const width = MCRandom.randomInt(2, 5);
    const height = MCRandom.randomInt(3, 8);
    const hyp = Math.sqrt(width * width + height * height);
    const img = new MCQuestion.Image(
      "GCSE/LadderAgainstWall.jpg",
      "height",
      15
    );
    const group = new MCQuestion.InputGroup(1);
    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    switch (MCRandom.randomInt(1, 3)) {
      case 1:
        // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.

        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The length from the base of the ladder to the wall is $${width}m$.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The length from the top of the ladder to the floor is $${height}m$.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            " Find the length of the ladder, $x$, to $3$ significant figures.",
            false
          )
        );
        img.addOverlay(`$${width}m$`, 60, 100);
        img.addOverlay(`$${height}m$`, 100, 60);
        img.addOverlay("$x$", 50, 50);
        question.addImage("question", img);

        question.addParagraph("solution", MCMaths.cleaner("", false));
        question.addParagraph(
          "solution",
          MCMaths.cleaner(" Using pythagoras' theorem:", false)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $x^2 = (${width})^2 + (${height})^2$`, false)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $x = \\sqrt{(${width})^2 + (${height})^2}$`, false)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $x = ${hyp.toPrecision(3)}$`, false)
        );
        group.addInput(hyp, 0.1, "$x=$");
        break;

      case 2:
        // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The length from the base of the ladder to the wall is $${width}m$.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The length of the ladder is $${hyp.toPrecision(3)}m$.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            " Find the length of the top of the ladder to the floor, $x$, to $3$ significant figures.",
            false
          )
        );

        img.addOverlay(`$${width}m$`, 60, 100);
        img.addOverlay("$x$", 100, 60);
        img.addOverlay(`$${hyp.toPrecision(3)}m$`, 48, 50);
        question.addImage("question", img);

        question.addParagraph("solution", MCMaths.cleaner("", false));
        question.addParagraph(
          "solution",
          MCMaths.cleaner(" Using pythagoras' theorem:", false)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $(${hyp.toPrecision(3)})^2 = (${width})^2 + x^2$`,
            false
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $x^2 = (${hyp.toPrecision(3)})^2 - (${width})^2$`,
            false
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $x = \\sqrt{(${hyp.toPrecision(3)})^2 - (${width})^2}$`,
            false
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $x = ${height}$`, false)
        );
        group.addInput(height, 0.1, "$x=$");
        break;

      case 3:
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The length from the top of the ladder to the bottom of the wall is $${height}m$.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The length of the ladder is $${hyp.toPrecision(3)}m$.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            " Find the length of from the bottom of the ladder to the wall, $x$, to $3$ significant figures.",
            false
          )
        );
        img.addOverlay("$x$", 60, 100);
        img.addOverlay(`$${height}m$`, 100, 60);
        img.addOverlay(`$${hyp.toPrecision(3)}m$`, 48, 50);
        question.addImage("question", img);

        question.addParagraph("solution", MCMaths.cleaner("", false));
        question.addParagraph(
          "solution",
          MCMaths.cleaner(" Using pythagoras' theorem:", false)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $(${hyp.toPrecision(3)})^2 = x^2 + (${height})^2$`,
            false
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $x^2 = (${hyp.toPrecision(3)})^2 - (${height})^2$`,
            false
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $x = \\sqrt{(${hyp.toPrecision(3)})^2 - (${height})^2}$`,
            false
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $x = ${width}$`, false)
        );
        group.addInput(width, 0.1, "$x=$");
        break;
      default:
    }

    question.addInputGroup(group);
    if (width > height || width === height) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Geometry_Pac_Triangles_Exam_Q10() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    // sides of length x, x+y, x+z
    let x = Math.random().toFixed(4);
    x += MCRandom.randomInt(2, 7);
    const y = MCRandom.randomInt(3, 5);
    const z = MCRandom.randomInt(6, 12);
    const coefficient1 = MCRandom.randomInt(2, 8);
    const coefficient2 = MCRandom.randomInt(2, 8);

    const initialEquationLHS = `x^2+(${coefficient1}x+${y})^2`;
    const initialEquationRHS = `(${coefficient2}x+${z})^2`;

    const lhs1 = `${1}x^${2}+${coefficient1 ** 2}x^${2}+${
      2 * y * coefficient1
    }x+${y ** 2}`;
    const rhs1 = `${coefficient2 ** 2}x^${2}+${2 * z * coefficient2}x+${
      z ** 2
    }`;

    const lhs2 = `${1 + coefficient1 ** 2}x^${2}+${2 * y * coefficient1}x+${
      y ** 2
    }`;
    const rhs2 = rhs1;

    const lhs3 = `${coefficient2 ** 2 - (1 + coefficient1 ** 2)}x^2+${
      2 * z * coefficient2 - 2 * y * coefficient1
    }x+${z ** 2 - y ** 2}`;
    const rhs3 = 0;

    const x2Co = coefficient2 ** 2 - (1 + coefficient1 ** 2);
    const xCo = 2 * z * coefficient2 - 2 * y * coefficient1;
    const constant = z ** 2 - y ** 2;

    const discriminant = xCo ** 2 - 4 * x2Co * constant;
    const sqrtDisc = Math.sqrt(discriminant);
    const formulaDenominator = 2 * x2Co;
    const x1 = (0 - xCo - sqrtDisc) / formulaDenominator;
    const x2 = (0 - xCo + sqrtDisc) / formulaDenominator;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A right-angled triangle has sides of length $x, (${coefficient1}x + ${y})$ and $(${coefficient2}x+${z})$ meters.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "(a) Write down an equation in $x$ using Pythagoras' theorem.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "(b) Find the possible values of $x$ giving your answers to 3 significant figures.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `(a) $${initialEquationLHS} = ${initialEquationRHS}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("(b) Collecting terms:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${lhs1} = ${rhs1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${lhs2} = ${rhs2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${lhs3} = ${rhs3}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Solving using the quadratic formula:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$x$ = $ {-b \\pm \\sqrt{b^{2} - 4ac}} \\over 2a$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$x$ = $ {-${xCo} \\pm \\sqrt{${xCo}^2 - 4 \\times${x2Co} \\times${constant}}} \\over 2 \\times${x2Co}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$x$ = $ {-${xCo} \\pm \\sqrt{${discriminant}}} \\over 2 \\times${x2Co}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$x$ = $ {-${xCo} \\pm ${sqrtDisc.toPrecision(
          5
        )} \\over ${formulaDenominator}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore x = ${x1.toPrecision(3)}$ and $x = ${x2.toPrecision(3)}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore x = ${x1.toPrecision(3)}$ meters`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(x1, 0.1, "$x=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      coefficient1 <= coefficient2 ||
      xCo <= 0 ||
      (x1 > 0 && x2 > 0) ||
      (x1 < 0 && x2 < 0)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Pac_Triangles_Exam_Q11() {
  let count = 0;
  const img_scale = 3;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const width = MCRandom.randomInt(4, 8);
    const height = MCRandom.randomInt(4, 8);
    const hyp = Math.sqrt(width * width + height * height);
    const wallHeight = MCRandom.randomInt(5, 10);
    const houseHeight = wallHeight + height;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(" This image shows the plan of a house roof.", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The slant length of the roof needs to be $${hyp.toPrecision(
          3
        )}m$ long and the width that it covers over the floor needs to be $${
          width * 2
        }m$ long.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The height of the walls of the house is $${wallHeight}m$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " What is the height, $x$, that the builders need to build the house in order for it to match these dimensions, to $3$ significant figures?",
        false,
        true,
        true
      )
    );

    const img = new MCQuestion.Image(
      "GCSE/Pythagoras/RoofProblem.jpg",
      "height",
      15
    );
    img.addOverlay(`$${width * 2}m$`, 160 / img_scale, 350 / img_scale - 13);
    img.addOverlay("$x$", 155 / img_scale + 1, 160 / img_scale);
    img.addOverlay(`$${wallHeight}m$`, 5 / img_scale + 1, 257 / img_scale - 10);
    img.addOverlay(`$${hyp.toPrecision(3)}m$`, 265, 80);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Let the height of the roof be $R$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Using pythagoras' theorem:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(${hyp.toPrecision(3)})^2 = (${width})^2 + R^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $R^2 = (${hyp.toPrecision(3)})^2 - (${width})^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $R = \\sqrt{(${hyp.toPrecision(3)})^2 - (${width})^2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $R = ${height}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore the height of the house is $${height} + ${wallHeight}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Height of the house $= ${houseHeight}m$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(houseHeight, 0);
    question.addInputGroup(group);
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (width === height || height > wallHeight) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Pac_Triangles_Exam_Q12() {
  let count = 0;
  const img_scale = 2.3;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const height = MCRandom.randomInt(30, 60);
    const width = MCRandom.randomInt(15, 30);
    const hyp = Math.sqrt(width * width + height * height);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A piece of paper measures $${width}$ cm wide by $${height}$ cm tall.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" What is the length of $AB$ ?", false, true, true)
    );

    const img = new MCQuestion.Image("GCSE/Pythagoras/Paper.jpg", "height", 15);
    img.addOverlay(`$${width}cm$`, 100 / img_scale + 8, 250 / img_scale - 5);
    img.addOverlay(`$${height}cm$`, 222 / img_scale + 2, 110 / img_scale);
    img.addOverlay("$B$", 220 / img_scale - 4, 0);
    img.addOverlay("$A$", 13 / img_scale, 245 / img_scale - 6);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Using pythagoras' theorem:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(AB)^2 = (${width})^2 + (${height})^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $AB = \\sqrt{(${width})^2 + (${height})^2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $AB = ${hyp.toPrecision(3)}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(hyp, 0.1);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (width >= height) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Pac_Triangles_Exam_Q13() {
  let count = 0;
  const img_scale = 3;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const height = MCRandom.randomInt(30, 60);
    const width = MCRandom.randomInt(15, 30);
    const hyp = Math.sqrt(width * width + height * height);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} starts at postion A and walks $${width}m$ due east, then proceeds to walks $${height}m$ due north and ends up at point B.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` How far is ${person.name} from ${person.hisher} original position at A?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Using pythagoras' theorem:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(AB)^2 = (${width})^2 + (${height})^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $AB = \\sqrt{(${width})^2 + (${height})^2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $AB = ${hyp.toPrecision(3)}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore ${person.name} is $${hyp.toPrecision(3)}m$ from ${
          person.hisher
        } original position.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(hyp, 0.1);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Pac_Triangles_Exam_Q2() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const yellowSquareSide = MCRandom.randomInt(3, 12);
    const yellowSquareArea = yellowSquareSide ** 2;
    const greenSquareSide = MCRandom.randomInt(3, 12);
    const greenSquareArea = greenSquareSide ** 2;
    const bigBlueSquareArea = yellowSquareArea + greenSquareArea;
    const bigBlueSquareSide = Math.sqrt(bigBlueSquareArea);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " Three squares are touching each other to form a triangle.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The yellow square has an area of $${yellowSquareArea}$ cm$^2$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The green square has an area of $${greenSquareArea}$ cm$^2$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " What is the length, $x$, of one side of the turquoise square, to $3$ significant figures?",
        false
      )
    );

    const img = new MCQuestion.Image(
      "GCSE/Pythagoras/3SquareTriangle.jpg",
      "height",
      15
    );
    img.addOverlay(`$${yellowSquareArea}cm^2$`, 50, 85);
    img.addOverlay(`$${greenSquareArea}cm^2$`, 82, 50);
    // img.addOverlay('$'+bigTurqoiseSquareArea+'cm$',90,120);
    img.addOverlay("$x$", 46, 46);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Using pythagoras' theorem:", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $x^2 = ${yellowSquareArea} + ${greenSquareArea}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $x = \\sqrt{${yellowSquareArea} + ${greenSquareArea}}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $x = \\sqrt{${bigBlueSquareArea}}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $x = ${bigBlueSquareSide.toPrecision(3)}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore the length of one side of the turquoise square is $${bigBlueSquareSide.toPrecision(
          3
        )}$ cm.`,
        false
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(bigBlueSquareSide, 0.1);

    question.addInputGroup(group);
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (yellowSquareArea >= greenSquareArea) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Geometry_Pac_Triangles_Exam_Q3() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const width = MCRandom.randomInt(2, 5);
    const height = MCRandom.randomInt(3, 8);
    const hyp = Math.sqrt(width * width + height * height);

    const group = new MCQuestion.InputGroup(1);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    const img = new MCQuestion.Image(
      "GCSE/Pythagoras/SimpleTriangle.jpg",
      "height",
      15
    );
    const mode = 3; // MCRandom.randomInt(1,3)
    switch (mode) {
      case 1:
        // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The width of a right-angled triangle is $${width}m$.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(` The height of the triangle is $${height}m$.`, false)
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            " Find the length of the hypotenuse, $x$, to $3$ significant figures.",
            false
          )
        );
        img.addOverlay(`$${width}m$`, 55, 103);
        img.addOverlay(`$${height}m$`, 105, 55);
        img.addOverlay("$x$", 45, 45);
        question.addImage("question", img);
        question.addParagraph("solution", MCMaths.cleaner("", false));
        question.addParagraph(
          "solution",
          MCMaths.cleaner(" Using pythagoras' theorem:", false)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $x^2 = (${width})^2 + (${height})^2$`, false)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $x = \\sqrt{(${width})^2 + (${height})^2}$`, false)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $x = ${hyp.toPrecision(3)}$`, false)
        );
        group.addInput(hyp, 0.1);

        break;

      case 2:
        // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The width of a right-angled triangle is $${width}m$.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The length of the hypotenuse is $${hyp.toPrecision(3)}m$.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            " Find the height of the triangle, $x$, to $3$ significant figures.",
            false
          )
        );
        img.addOverlay(`$${width}m$`, 55, 103);
        img.addOverlay("$x$", 102, 55);
        img.addOverlay(`$${hyp.toPrecision(3)}m$`, 39, 44);
        question.addImage("question", img);

        question.addParagraph("solution", MCMaths.cleaner("", false));
        question.addParagraph(
          "solution",
          MCMaths.cleaner(" Using pythagoras' theorem:", false)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $(${hyp.toPrecision(3)})^2 = (${width})^2 + x^2$`,
            false
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $x^2 = (${hyp.toPrecision(3)})^2 - (${width})^2$`,
            false
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $x = \\sqrt{(${hyp.toPrecision(3)})^2 - (${width})^2}$`,
            false
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $x = ${height}$`, false)
        );
        group.addInput(height, 0.1);

        break;

      case 3:
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The height of a right-angled triangle is $${height}m$.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The length of hypotenuse is $${hyp.toPrecision(3)}m$.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            " Find the width of the triangle, $x$, to $3$ significant figures.",
            false
          )
        );
        img.addOverlay("$x$", 55, 103);
        img.addOverlay(`$${height}m$`, 105, 55);
        img.addOverlay(`$${hyp.toPrecision(3)}m$`, 39, 44);
        question.addImage("question", img);

        question.addParagraph("solution", MCMaths.cleaner("", false));
        question.addParagraph(
          "solution",
          MCMaths.cleaner(" Using pythagoras' theorem:", false)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $(${hyp.toPrecision(3)})^2 = x^2 + (${height})^2$`,
            false
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $x^2 = (${hyp.toPrecision(3)})^2 - (${height})^2$`,
            false
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $x = \\sqrt{(${hyp.toPrecision(3)})^2 - (${height})^2}$`,
            false
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $x = ${width}$`, false)
        );
        group.addInput(width, 0.1);

        break;
      default:
    }

    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (width > height || width === height) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Geometry_Pac_Triangles_Exam_Q4() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const width = MCRandom.randomInt(4, 8);
    const height = MCRandom.randomInt(4, 8);
    const hyp = Math.sqrt(width * width + height * height);
    const wallHeight = MCRandom.randomInt(5, 10);
    const houseHeight = wallHeight + height;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(" This image shows the plan of a house roof.", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The slant length of the roof needs to be $${hyp.toPrecision(
          3
        )}m$ long and the width that it covers over the floor needs to be $${
          width * 2
        }m$ long.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The height of the walls of the house is $${wallHeight}m$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " What is the height, $x$, that the builders need to build the house in order for it to match these dimensions, to $3$ significant figures?",
        false
      )
    );
    const img = new MCQuestion.Image(
      "GCSE/Pythagoras/RoofProblem.jpg",
      "height",
      15
    );
    const scale = 3.5;
    img.addOverlay(`$${width * 2}m$`, 170 / scale, 359 / scale);
    img.addOverlay("$x$", 155 / scale, 160 / scale);
    img.addOverlay(`$${wallHeight}m$`, 5 / scale, 257 / scale);
    img.addOverlay(`$${hyp.toPrecision(3)}m$`, 295 / scale, 80 / scale);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Let the height of the roof be $R$", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Using pythagoras' theorem:", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(${hyp.toPrecision(3)})^2 = (${width})^2 + R^2$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $R^2 = (${hyp.toPrecision(3)})^2 - (${width})^2$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $R = \\sqrt{(${hyp.toPrecision(3)})^2 - (${width})^2}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $R = ${height}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore the height of the house is $${height} + ${wallHeight}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` Height of the house $= ${houseHeight}m$`, false)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(houseHeight, 0.1);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (width === height || height > wallHeight) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Geometry_Pac_Triangles_Exam_Q5() {
  let count = 0;
  const img_scale = 2.4;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const height = MCRandom.randomInt(30, 60);
    const width = MCRandom.randomInt(15, 30);
    const hyp = Math.sqrt(width * width + height * height);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A piece of paper measures $${width}$ cm wide by $${height}$ cm tall.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" What is the length of $AB$ ?", false)
    );

    const img = new MCQuestion.Image("GCSE/Pythagoras/Paper.jpg", "height", 15);
    img.addOverlay(`$${width}cm$`, 100 / img_scale, 250 / img_scale);
    img.addOverlay(`$${height}cm$`, 240 / img_scale, 110 / img_scale);
    img.addOverlay("$B$", 220 / img_scale, -5);
    img.addOverlay("$A$", 13 / img_scale, 245 / img_scale);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Using pythagoras' theorem:", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $(AB)^2 = (${width})^2 + (${height})^2$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $AB = \\sqrt{(${width})^2 + (${height})^2}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $AB = ${hyp.toPrecision(3)}$`, false)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(hyp, 0.1);
    question.addInputGroup(group);
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (width >= height) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Geometry_Pac_Triangles_Exam_Q6() {
  let count = 0;
  const img_scale = 3;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const height = MCRandom.randomInt(30, 60);
    const width = MCRandom.randomInt(15, 30);
    const hyp = Math.sqrt(width * width + height * height);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} starts at postion A and walks $${width}m$ due east, then proceeds to walks $${height}m$ due north and ends up at point B.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` How far is ${person.name} from ${person.hisher} original position at A?`,
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Using pythagoras' theorem:", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $(AB)^2 = (${width})^2 + (${height})^2$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $AB = \\sqrt{(${width})^2 + (${height})^2}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $AB = ${hyp.toPrecision(3)}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore ${person.name} is $${hyp.toPrecision(3)}m$ from ${
          person.hisher
        } original position.`,
        false
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(hyp, 0.1);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Pac_Triangles_Exam_Q7() {
  let count = 0;
  const img_scale = 3;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const width = MCRandom.randomInt(4, 8);
    const height = MCRandom.randomInt(13, 20);
    const hyp = Math.sqrt(width * width + height * height);

    const DB = MCRandom.randomInt(2, 6);
    const heightOfBuilding = height + DB;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The distance from point $A$ to point $B$, on the third floor of the building is $${hyp.toPrecision(
          3
        )}m$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The distance from point $A$ to point $C$ at the bottom of the building is $${width}m$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The height of the building is $${heightOfBuilding}m$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " What is the length of $DB$, to $3$ significant figures?",
        false
      )
    );

    const img = new MCQuestion.Image(
      "GCSE/Pythagoras/Building.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", -5, 300 / img_scale);
    img.addOverlay("$B$", 210 / img_scale, 58 / img_scale);
    img.addOverlay("$C$", 198 / img_scale, 280 / img_scale);
    img.addOverlay("$D$", 210 / img_scale, 20 / img_scale);
    img.addOverlay(`$${width}m$`, 90 / img_scale, 310 / img_scale);
    // img.addOverlay('$x$',155/img_scale,160/img_scale);
    // img.addOverlay('$'+wallHeight+'m$',5/img_scale,257/img_scale);
    img.addOverlay(`$${hyp.toPrecision(3)}m$`, 60, 160);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Using pythagoras' theorem:", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(${hyp.toPrecision(3)})^2 = (${width})^2 + (BC)^2$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(BC)^2 = (${hyp.toPrecision(3)})^2 - (${width})^2$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $BC = \\sqrt{(${hyp.toPrecision(3)})^2 - (${width})^2}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $BC = ${height}m$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${height} + DB = ${heightOfBuilding}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $DB = ${heightOfBuilding} - ${height}$`, false)
    );
    question.addParagraph("solution", MCMaths.cleaner(` $DB = ${DB}m$`, false));

    const group = new MCQuestion.InputGroup(1);
    group.addInput(DB, 0.1);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (width >= height || DB >= height) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Pac_Triangles_Exam_Q8() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const base = Math.round(Math.random() * 10 + 10);
    const side = base + Math.round(Math.random() * 3 + 2);
    const height =
      Math.round((side * side - 0.25 * base * base) ** 0.5 * 1000) / 1000;
    const area = Math.round(0.5 * base * height * 10) / 10;
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find the area of the triangle below to one decimal place.",
        false
      )
    );
    const img = new MCQuestion.Image("GCSE/Triangle2.jpg", "height", 15);
    img.addOverlay(`$${base}$`, 55, 95.5);
    img.addOverlay(`$${side}$`, 14, 37.5);
    question.addImage("question", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Height $= \\sqrt{${side}^2-${base / 2}^2} = ${height}cm$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Area $= {1 \\over2} \\times ${base} \\times${height} = ${area}cm^2$`,
        false,
        true,
        true
      )
    );
    const group = new MCQuestion.InputGroup(1);
    group.addInput(area, 0);
    question.addInputGroup(group);
    if (1 === 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Pac_Triangles_Exam_Q9() {
  let count = 0;
  const img_scale = 2.4;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const width = MCRandom.randomInt(2, 5);
    const height = MCRandom.randomInt(3, 8);
    const hyp = Math.sqrt(width * width + height * height);
    const question = new MCQuestion.ExamQuestion();
    const img = new MCQuestion.Image(
      "GCSE/LadderAgainstWall.jpg",
      "height",
      15
    );
    const group = new MCQuestion.InputGroup(1);
    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.

    switch (MCRandom.randomInt(1, 3)) {
      case 1:
        // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.

        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The length from the base of the ladder to the wall is $${width}m$.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The length from the top of the ladder to the floor is $${height}m$.`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            " Find the length of the ladder, $x$, to $3$ significant figures.",
            false,
            true,
            true
          )
        );

        img.addOverlay(`$${width}m$`, 135 / img_scale, 240 / img_scale);
        img.addOverlay(`$${height}m$`, 235 / img_scale + 2, 140 / img_scale);
        img.addOverlay("$x$", 130 / img_scale, 120 / img_scale);
        question.addImage("question", img);

        question.addParagraph(
          "solution",
          MCMaths.cleaner("", false, true, true)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(" Using pythagoras' theorem:", false, true, true)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $x^2 = (${width})^2 + (${height})^2$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $x = \\sqrt{(${width})^2 + (${height})^2}$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $x = ${hyp.toPrecision(3)}$`, false, true, true)
        );

        group.addInput(hyp, 0.1, "$x=$");
        question.addInputGroup(group);

        break;

      case 2:
        // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.

        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The length from the base of the ladder to the wall is $${width}m$.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The length of the ladder is $${hyp.toPrecision(3)}m$.`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            " Find the length of the top of the ladder to the floor, $x$, to $3$ significant figures.",
            false,
            true,
            true
          )
        );

        img.addOverlay(`$${width}m$`, 135 / img_scale, 240 / img_scale);
        img.addOverlay("$x$", 235 / img_scale, 140 / img_scale);
        img.addOverlay(
          `$${hyp.toPrecision(3)}m$`,
          100 / img_scale,
          120 / img_scale
        );
        question.addImage("question", img);

        question.addParagraph(
          "solution",
          MCMaths.cleaner("", false, true, true)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(" Using pythagoras' theorem:", false, true, true)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $(${hyp.toPrecision(3)})^2 = (${width})^2 + x^2$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $x^2 = (${hyp.toPrecision(3)})^2 - (${width})^2$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $x = \\sqrt{(${hyp.toPrecision(3)})^2 - (${width})^2}$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $x = ${height}$`, false, true, true)
        );

        group.addInput(height, 0.1, "$x=$");
        question.addInputGroup(group);
        break;

      case 3:
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The length from the top of the ladder to the bottom of the wall is $${height}m$.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            ` The length of the ladder is $${hyp.toPrecision(3)}m$.`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            " Find the length of from the bottom of the ladder to the wall, $x$, to $3$ significant figures.",
            false,
            true,
            true
          )
        );

        img.addOverlay("$x$", 135 / img_scale, 240 / img_scale);
        img.addOverlay(`$${height}m$`, 235 / img_scale + 2, 140 / img_scale);
        img.addOverlay(
          `$${hyp.toPrecision(3)}m$`,
          100 / img_scale,
          120 / img_scale
        );
        question.addImage("question", img);

        question.addParagraph(
          "solution",
          MCMaths.cleaner("", false, true, true)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(" Using pythagoras' theorem:", false, true, true)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $(${hyp.toPrecision(3)})^2 = x^2 + (${height})^2$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $x^2 = (${hyp.toPrecision(3)})^2 - (${height})^2$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` $x = \\sqrt{(${hyp.toPrecision(3)})^2 - (${height})^2}$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $x = ${width}$`, false, true, true)
        );

        group.addInput(width, 0.1, "$x=$");
        question.addInputGroup(group);

        break;
      default:
    }

    if (width > height || width === height) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Geometry_Vectors_VectorArithmetic_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const a1 = MCRandom.randomInt(-9, 9);
    const a2 = MCRandom.randomInt(-9, 9);
    const b1 = MCRandom.randomInt(-9, 9);
    const b2 = MCRandom.randomInt(-9, 9);
    const c1 = MCRandom.randomInt(-9, 9);
    const c2 = MCRandom.randomInt(-9, 9);
    const scalar1 = MCRandom.randomInt(2, 8);
    const scalar2 = MCRandom.randomInt(-12, 12);
    const scalar3 = MCRandom.randomInt(-12, 12);
    let scalar2ForWorking = scalar2;
    let scalar3ForWorking = scalar3;
    let sign2 = 1;
    let sign3 = 1;
    if (scalar2 < 0) {
      scalar2ForWorking = scalar2 * -1;
      sign2 = -1;
    }
    if (scalar3 < 0) {
      scalar3ForWorking = scalar3 * -1;
      sign3 = -1;
    }
    const questionB = `${scalar2}\\textbf{b}+${scalar3}\\textbf{c}`;
    const questionBWorking = `${sign2}\\begin{pmatrix}${
      b1 * scalar2ForWorking
    } \\\\${b2 * scalar2ForWorking} \\end{pmatrix}+${sign3}\\begin{pmatrix}${
      c1 * scalar3ForWorking
    } \\\\ ${c2 * scalar3ForWorking} \\end{pmatrix}`;

    const vectorB1 = scalar2 * b1;
    const vectorB2 = scalar2 * b2;
    const vectorC1 = scalar3 * c1;
    const vectorC2 = scalar3 * c2;

    const finalVector1 = vectorB1 + vectorC1;
    const finalVector2 = vectorB2 + vectorC2;

    const finalVector = `\\begin{pmatrix}${finalVector1} \\\\ ${finalVector2} \\end{pmatrix}`;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The vectors $\\textbf{a}$, $\\textbf{b}$ and $\\textbf{c}$ are defined as such:",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$\\textbf{a}$ = $\\begin{pmatrix} ${a1} \\\\ ${a2} \\end{pmatrix}$ $\\textbf{b}$ = $\\begin{pmatrix} ${b1}  \\\\ ${b2} \\end{pmatrix}$ $\\textbf{c}$ = $\\begin{pmatrix}${c1} \\\\ ${c2} \\end{pmatrix}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Write, as a column vector, $${scalar1}\\textbf{a}$. Give you answer in the form $\\begin{pmatrix} m \\\\ n \\end{pmatrix}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Write, as a column vector, $${questionB}$. Give you answer in the form $\\begin{pmatrix} o \\\\ p \\end{pmatrix}$.`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a) \\ ${scalar1}\\textbf{a}$ = $\\begin{pmatrix}${
          a1 * scalar1
        } \\\\ ${a2 * scalar1}\\end{pmatrix}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ ${scalar2ForWorking}\\textbf{b}$ = $\\begin{pmatrix}${
          b1 * scalar2ForWorking
        } \\\\ ${b2 * scalar2ForWorking}\\end{pmatrix}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${scalar3ForWorking}\\textbf{c}$ = $\\begin{pmatrix}${
          c1 * scalar3ForWorking
        } \\\\ ${c2 * scalar3ForWorking}\\end{pmatrix}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${questionB}$ = $${questionBWorking}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${questionB}$ = $${finalVector}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(a1 * scalar1, 0, "$(a) \\ m=$");
    group.addInput(a2 * scalar1, 0, "$(a) \\ n=$");
    group.addInput(finalVector1, 0, "$(b) \\ o=$");
    group.addInput(finalVector2, 0, "$(b) \\ p=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      a1 === a2 ||
      a1 === b1 ||
      a1 === c1 ||
      b1 === b2 ||
      c1 === c2 ||
      a2 === b2 ||
      a2 === c2 ||
      b2 === c2 ||
      b1 === c1 ||
      scalar1 === scalar2 ||
      scalar1 === scalar3 ||
      scalar2 === scalar3 ||
      scalar2 === 0 ||
      scalar3 === 0 ||
      (scalar2 < 0 && scalar3 < 0) ||
      c1 === c2 ||
      b1 === b2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Number_Sac_CalculationsWithPowers_Exam_Q1() {
  function PowerTimes(base) {
    const p1 = MCRandom.randomInt(2, 9);
    const p2 = MCRandom.randomInt(2, 9);
    const q = `$${base}^{${p1}}\\times ${base}^{${p2}}$`;
    const a = `$${base}^{${p1 + p2}}$`;
    return {
      question: q,
      answer: a,
      ans: p1 + p2,
    };
  }

  function PowerDivide(base) {
    const p2 = MCRandom.randomInt(2, 9);
    const p1 = p2 + MCRandom.randomInt(2, 9);
    const q = `$${base}^{${p1}}\\div ${base}^{${p2}}$`;
    const a = `$${base}^{${p1 - p2}}$`;
    return {
      question: q,
      answer: a,
      ans: p1 - p2,
    };
  }

  function PowerBracket(base) {
    const p2 = MCRandom.randomInt(2, 9);
    const p1 = MCRandom.randomInt(2, 9);
    const q = `$\\left( ${base}^{${p1}}\\right)^{${p2}}$`;
    const a = `$${base}^{${p1 * p2}}$`;
    return {
      question: q,
      answer: a,
      ans: p1 * p2,
    };
  }

  function PowerOneSquared(base) {
    const p1 = MCRandom.randomInt(2, 9);
    const p2 = MCRandom.randomInt(2, 9);
    const q = `$${base ** 2}^{${p1}}\\times ${base}^{${p2}}$`;
    const a = `$${base}^{${2 * p1 + p2}}$`;
    return {
      question: q,
      answer: a,
      ans: 2 * p1 + p2,
    };
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const base = MCRandom.randomInt(2, 9);

    const qs = [PowerTimes, PowerDivide, PowerOneSquared, PowerBracket];

    const q1 = qs[MCRandom.randomInt(0, qs.length - 1)](base);
    const q2 = qs[MCRandom.randomInt(0, qs.length - 1)](base);
    const q3 = qs[MCRandom.randomInt(0, qs.length - 1)](base);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `Write the following in the form $${base}^{x}$:`
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a) \\ $${q1.question},`)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b) \\ $${q2.question},`)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(c) \\ $${q3.question}.`)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ $ ${q1.answer}`)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ $ ${q2.answer}`)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ $ ${q3.answer}`)
    );

    const group = new MCQuestion.InputGroup(3);
    group.addInput(q1.ans, 0.01, "$(a) \\ x = $");
    group.addInput(q2.ans, 0.01, "$(b) \\ x = $");
    group.addInput(q3.ans, 0.01, "$(c) \\ x = $");
    question.addInputGroup(group);

    if (q1 === q2 || q2 === q3 || q3 === q1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Number_Sac_CalculationsWithPowers_Exam_Q2() {
  function SumOfSquaresCR() {
    const a = [10, 11, 16, 30, 18];
    const b = [5, 2, 16, 10, 26];
    const c = [5, 5, 8, 10, 10];
    const mode = MCRandom.randomInt(0, c.length - 1);
    const q = `\\sqrt[3]{${a[mode]}^2+${b[mode]}^2}`;
    const ans = c[mode];
    const working = `\\sqrt[3]{${a[mode] ** 2 + b[mode] ** 2}}`;
    return {
      question: q,
      ans,
      working,
    };
  }

  function DiffSquaresCR() {
    const n = MCRandom.randomInt(2, 10);
    const a = (n ** 2 + n) / 2;
    const b = (n ** 2 - n) / 2;
    const q = `\\sqrt[3]{${a}^2-${b}^2}`;
    const working = `\\sqrt[3]{${a ** 2 - b ** 2}}`;
    return {
      question: q,
      ans: n,
      working,
    };
  }

  function SumOfSquares() {
    const n = MCRandom.randomInt(1, 3);
    const m = n + MCRandom.randomInt(1, 3);
    const a = m ** 2 - n ** 2;
    const b = 2 * m * n;
    const c = m ** 2 + n ** 2;
    const q = `\\sqrt{${a}^2+${b}^2}`;
    const working = `\\sqrt{${a ** 2 + b ** 2}}`;
    return {
      question: q,
      ans: c,
      working,
    };
  }

  function DiffSquares() {
    const n = MCRandom.randomInt(1, 3);
    const m = n + MCRandom.randomInt(1, 3);
    const a = m ** 2 - n ** 2;
    const b = 2 * m * n;
    const c = m ** 2 + n ** 2;
    const q = `\\sqrt{${c}^2-${b}^2}`;
    const working = `\\sqrt{${c ** 2 - b ** 2}}`;
    return {
      question: q,
      ans: a,
      working,
    };
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const qs = [SumOfSquaresCR, DiffSquaresCR, SumOfSquares, DiffSquares];

    const q1 = qs[MCRandom.randomInt(0, qs.length - 1)](1);
    const q2 = qs[MCRandom.randomInt(0, qs.length - 1)](1);
    const q3 = qs[MCRandom.randomInt(0, qs.length - 1)](1);

    const qss = [q1.question, q2.question, q3.question];
    const anss = [q1.ans, q2.ans, q3.ans];

    const minN = anss.indexOf(Math.min(...anss));
    const maxN = anss.indexOf(Math.max(...anss));
    const otherN = 3 - minN - maxN;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", `Without using a calculator, order:`);
    question.addParagraph("question", `$\\bullet \\ ${q1.question}$`);
    question.addParagraph("question", `$\\bullet \\ ${q2.question}$`);
    question.addParagraph("question", `$\\bullet \\ ${q3.question}$`);
    question.addParagraph(
      "solution",
      `$\\bullet \\ ${q1.question} = ${q1.working} = ${q1.ans}$`
    );
    question.addParagraph(
      "solution",
      `$\\bullet \\ ${q2.question} = ${q2.working} = ${q2.ans}$`
    );
    question.addParagraph(
      "solution",
      `$\\bullet \\ ${q3.question} = ${q3.working} = ${q3.ans}$`
    );
    question.addParagraph(
      "solution",
      `So, $ ${qss[minN]} < ${qss[otherN]} < ${qss[maxN]}.$`
    );

    question.requiresSelfMarking();

    if (q1.ans === q2.ans || q2.ans === q3.ans || q3.ans === q1.ans) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Number_Sac_CalculationsWithPowers_Exam_Q3() {
  function PowerTimes(base) {
    const p1 = MCRandom.randomInt(2, 9);
    const p2 = MCRandom.randomInt(2, 9);
    const q = `${base}^{${p1}}\\times ${base}^{${p2}}`;
    const a = `${base}^{${p1 + p2}}`;
    return {
      question: q,
      answer: a,
      ans: p1 + p2,
    };
  }

  function PowerDivide(base) {
    const p2 = MCRandom.randomInt(2, 9);
    const p1 = p2 + MCRandom.randomInt(2, 9);
    const q = `${base}^{${p1}}\\div ${base}^{${p2}}`;
    const a = `${base}^{${p1 - p2}}`;
    return {
      question: q,
      answer: a,
      ans: p1 - p2,
    };
  }

  function PowerBracket(base) {
    const p2 = MCRandom.randomInt(2, 9);
    const p1 = MCRandom.randomInt(2, 9);
    const q = `\\left( ${base}^{${p1}}\\right)^{${p2}}`;
    const a = `${base}^{${p1 * p2}}`;
    return {
      question: q,
      answer: a,
      ans: p1 * p2,
    };
  }

  function PowerOneSquared(base) {
    const p1 = MCRandom.randomInt(2, 9);
    const p2 = MCRandom.randomInt(2, 9);
    const q = `${base ** 2}^{${p1}}\\times ${base}^{${p2}}`;
    const a = `${base}^{${2 * p1 + p2}}`;
    return {
      question: q,
      answer: a,
      ans: 2 * p1 + p2,
    };
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const base = MCRandom.randomInt(2, 9);

    const qs = [PowerTimes, PowerDivide, PowerOneSquared, PowerBracket];

    const q1 = qs[MCRandom.randomInt(0, qs.length - 1)](base);
    const q2 = qs[MCRandom.randomInt(0, qs.length - 1)](base);
    const power = MCRandom.randomInt(2, 4);

    const ans = (q1.ans - q2.ans) * power;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `Write $\\displaystyle \\left(\\frac{ ${q1.question} }{ ${q2.question} }\\right)^{${power}}$ in the form $${base}^{x}$.`
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$ ${q1.question} = ${q1.answer} $`)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$ ${q2.question} = ${q2.answer} $`)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, $\\displaystyle \\left(\\frac{ ${q1.question} }{ ${q2.question} }\\right)^{${power}} = \\left(\\frac{${q1.answer}}{${q2.answer}}\\right)^{${power}}$`
      )
    );
    question.addParagraph(
      "solution",
      `$\\displaystyle =\\left(${base}^{${q1.ans - q2.ans}}\\right)^{${power}}$`
    );
    question.addParagraph("solution", `$=${base}^{${ans}}$`);

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.01, "$x = $");
    question.addInputGroup(group);

    if (ans < 1 || ans > 10) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_ProbabilityAndStatistics_Probability_DependentProbability_Exam_Q1() {
  let count = 0;
  const img_scale = 2.35;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const red = MCRandom.randomInt(2, 10);
    const blue = MCRandom.randomInt(2, 10);
    const tot1 = red + blue;
    const tot2 = tot1 - 1;
    const r1 = red / tot1;
    const b1 = blue / tot1;
    const bb = b1;
    const br = r1;
    const rb = b1;
    const rr = r1;
    const ans = bb * b1;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has a bag with $${red}$ balls and $${blue}$ balls. ${name.HeShe} takes one ball out of the bag, then takes another one after replacing the first ball.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Draw a probability tree for these events.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ What is the probability ${name.name} picks two blue balls?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    const img = new MCQuestion.Image(
      "GCSE/ProbabilityTree/ProbabilityTree.jpg",
      "height",
      15
    );
    const img_scaley1 = 2.75;
    const img_scaley2 = 2.75;
    img.addOverlay("$blue$", 100 / img_scale, 70 / img_scaley1);
    img.addOverlay("$red$", 100 / img_scale, 240 / img_scaley1);
    img.addOverlay("$blue$", 220 / img_scale, 35 / img_scaley1);
    img.addOverlay("$red$", 220 / img_scale, 110 / img_scaley1);
    img.addOverlay("$blue$", 220 / img_scale, 205 / img_scaley1 - 2);
    img.addOverlay("$red$", 220 / img_scale, 280 / img_scaley1 - 4);
    img.addOverlay(
      MCMaths.cleaner(`$${b1}$`),
      20 / img_scale + 3,
      90 / img_scaley2 + 2
    );
    img.addOverlay(
      MCMaths.cleaner(`$${r1}$`),
      20 / img_scale + 3,
      220 / img_scaley2 - 5
    );
    img.addOverlay(
      MCMaths.cleaner(`$${bb}$`),
      180 / img_scale - 7,
      25 / img_scaley2 + 2
    );
    img.addOverlay(
      MCMaths.cleaner(`$${br}$`),
      180 / img_scale - 7,
      120 / img_scaley2
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rb}$`),
      180 / img_scale - 7,
      190 / img_scaley2
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rr}$`),
      180 / img_scale - 7,
      290 / img_scaley2 - 6
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.01, "$(b)$");
    question.addInputGroup(group);

    question.addImage("solution", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ {${b1}} \\times{${bb}}=${ans}$`,
        true,
        true,
        true
      )
    );
    if (ans.numerator > 998) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_ProbabilityAndStatistics_Probability_DependentProbability_Exam_Q2() {
  let count = 0;
  const img_scale = 2.4;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    // BLUE IS HEAD< RED IS TAILS
    const red = MCRandom.randomInt(2, 10);
    const blue = MCRandom.randomInt(2, 10);
    const tot1 = red + blue;
    const tot2 = tot1 - 1;
    const r1 = red / tot1;
    const b1 = blue / tot1;
    const bb = b1;
    const br = r1;
    const rb = b1;
    const rr = r1;
    const ans = r1 * r1;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} flips a biased coin twice. The coin has a $${b1}$ chance of landing heads up.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Draw a probability tree for these events.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ What is the probability the coin doesn't land on a heads?",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    const img = new MCQuestion.Image(
      "GCSE/ProbabilityTree/ProbabilityTree.jpg",
      "height",
      15
    );
    const img_scaley = 2.85;
    img.addOverlay("$head$", 100 / img_scale + 1, 70 / img_scaley);
    img.addOverlay("$tails$", 100 / img_scale + 1, 240 / img_scaley);
    img.addOverlay("$heads$", 220 / img_scale + 3, 35 / img_scaley);
    img.addOverlay("$tails$", 220 / img_scale + 3, 110 / img_scaley);
    img.addOverlay("$heads$", 220 / img_scale + 3, 205 / img_scaley);
    img.addOverlay("$tails$", 220 / img_scale + 3, 280 / img_scaley);
    img.addOverlay(MCMaths.cleaner(`$${b1}$`), 20 / img_scale, 90 / img_scale);
    img.addOverlay(
      MCMaths.cleaner(`$${r1}$`),
      20 / img_scale,
      220 / img_scale - 20
    );
    img.addOverlay(MCMaths.cleaner(`$${bb}$`), 180 / img_scale, 25 / img_scale);
    img.addOverlay(
      MCMaths.cleaner(`$${br}$`),
      180 / img_scale,
      120 / img_scale - 5
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rb}$`),
      180 / img_scale,
      190 / img_scale - 13
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rr}$`),
      180 / img_scale,
      290 / img_scale - 18
    );
    question.addImage("solution", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ {${r1}} \\times{${r1}}=${ans}$`,
        true,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.01, "$(b)$");
    question.addInputGroup(group);

    if (
      ans.numerator > 998 ||
      b1.denominator === 2 ||
      b1.denominator === 4 ||
      b1.denominator >= 998
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_ProbabilityAndStatistics_Probability_DependentProbability_Exam_Q3() {
  let count = 0;
  const img_scale = 2.4;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();

    const e1 = MCRandom.randomInt(1, 119) / 120;
    const e2 = MCRandom.randomInt(1, 119) / 120;
    const e1n = 1 - e1;
    const e2n = 1 - e2;
    const b1 = e1;
    const r1 = e1n;
    const bb = e2;
    const br = e2n;
    const rb = e2;
    const rr = e2n;
    const ans = e1 * e2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.name} plays football and hockey.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The chance of ${name.name} winning ${name.hisher} football match is $${e1}$, and the chance of winning ${name.hisher} hockey match is $${e2}$.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Draw a probability tree for these events.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ What is the probability ${name.name} wins both ${name.hisher} matches?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    const img = new MCQuestion.Image(
      "GCSE/ProbabilityTree/ProbabilityTree.jpg",
      "height",
      15
    );
    const img_scaley = 2.85;

    img.addOverlay("$Football$", 85 / img_scale, -3);
    img.addOverlay("$Hockey$", 210 / img_scale, -3);

    img.addOverlay("$win$", 100 / img_scale + 1, 70 / img_scaley);
    img.addOverlay("$lose$", 100 / img_scale + 1, 240 / img_scaley);
    img.addOverlay("$wins$", 220 / img_scale + 3, 35 / img_scaley);
    img.addOverlay("$lose$", 220 / img_scale + 3, 110 / img_scaley);
    img.addOverlay("$wins$", 220 / img_scale + 3, 205 / img_scaley);
    img.addOverlay("$lose$", 220 / img_scale + 3, 280 / img_scaley);

    img.addOverlay(
      MCMaths.cleaner(`$${b1}$`),
      20 / img_scale + 4,
      90 / img_scaley
    );
    img.addOverlay(
      MCMaths.cleaner(`$${r1}$`),
      20 / img_scale + 4,
      220 / img_scaley
    );
    img.addOverlay(
      MCMaths.cleaner(`$${bb}$`),
      180 / img_scale - 6,
      25 / img_scaley + 3
    );
    img.addOverlay(
      MCMaths.cleaner(`$${br}$`),
      180 / img_scale - 6,
      120 / img_scaley + 1
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rb}$`),
      180 / img_scale - 6,
      190 / img_scaley + 1
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rr}$`),
      180 / img_scale - 6,
      290 / img_scaley - 2
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.01);
    question.addInputGroup(group);

    question.addImage("solution", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ {${e1}} \\times{${e2}}=${ans}$`,
        true,
        true,
        true
      )
    );
    if (
      ans.numerator > 998 ||
      e1n.denominator >= 15 ||
      e2n.denominator >= 15 ||
      e1 >= 0.9 ||
      e1 <= 0.3 ||
      e2 >= 0.9 ||
      e2 <= 0.3
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_ProbabilityAndStatistics_Probability_DependentProbability_Exam_Q4() {
  let count = 0;
  const img_scale = 3.3;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const w3 = MCRandom.randomInt(1, 119) / 120;
    const w2 = w3;
    const w1 = w3;
    const l1 = 1 - w1;
    const l2 = l1;
    const l3 = l1;
    const ans = w1 * w2;
    // let ans2 = (w1*w2*w2)
    const mode = MCRandom.randomInt(0, 1);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${name.name} is playing in a $3$ round knockout tournament, where if ${name.heshe} loses a round ${name.heshe} is out.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The probability of ${name.name} winning any round $${w1}$.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Draw a probability tree for these events.",
        false,
        true,
        true
      )
    );
    if (mode === 0) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$(b)$ What is the probability ${name.name} gets to the third round?`,
          false,
          true,
          true
        )
      );
    }
    if (mode === 1) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$(b)$ What is the probability ${name.name} gets to the second round?`,
          false,
          true,
          true
        )
      );
    }

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    const img_scaley = 2.2;
    const img = new MCQuestion.Image(
      "GCSE/ProbabilityTree/ProbabilityTree2.jpg",
      "height",
      15
    );
    img.addOverlay("$win$", 110 / img_scale + 2, 130 / img_scaley);
    img.addOverlay("$lose$", 110 / img_scale + 2, 213 / img_scaley);
    img.addOverlay("$win$", 220 / img_scale, 85 / img_scaley);
    img.addOverlay("$lose$", 220 / img_scale, 165 / img_scaley);
    img.addOverlay("$win$", 330 / img_scale - 2, 40 / img_scaley + 5);
    img.addOverlay("$lose$", 330 / img_scale - 2, 125 / img_scaley);
    img.addOverlay(
      MCMaths.cleaner(`$${w1}$`),
      50 / img_scale,
      130 / img_scaley
    );
    img.addOverlay(
      MCMaths.cleaner(`$${l1}$`),
      50 / img_scale,
      210 / img_scaley - 4
    );
    img.addOverlay(
      MCMaths.cleaner(`$${w2}$`),
      170 / img_scale,
      80 / img_scaley
    );
    img.addOverlay(
      MCMaths.cleaner(`$${l2}$`),
      170 / img_scale,
      165 / img_scaley
    );
    img.addOverlay(
      MCMaths.cleaner(`$${w3}$`),
      280 / img_scale,
      40 / img_scaley
    );
    img.addOverlay(
      MCMaths.cleaner(`$${l3}$`),
      280 / img_scale,
      125 / img_scaley + 2
    );
    img.addOverlay("$Round \\ 1$", 35 / img_scale, 10 / img_scaley);
    img.addOverlay("$Round \\ 2$", 155 / img_scale, 10 / img_scaley);
    img.addOverlay("$Round \\ 3$", 265 / img_scale, 10 / img_scaley);

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);

    question.addInputGroup(group);

    question.addImage("solution", img);
    if (mode === 0) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(b) \\ {${w1}} \\times{${w2}}=${ans}$`,
          true,
          true,
          true
        )
      );
      group.addInput(ans, 0.01, "$(b)$");
    }
    if (mode === 1) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(b) \\ ${w1}$`, true, true, true)
      );
      group.addInput(w1, 0.01, "$(b)$");
    }
    if (
      l3.denominator >= 99 ||
      l2.denominator >= 99 ||
      l1.denominator >= 99 ||
      w1 >= 0.99 ||
      ans.denominator >= 99 ||
      w1.denominator <= 3
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_ProbabilityAndStatistics_Probability_DependentProbability_Exam_Q5() {
  let count = 0;
  const img_scale = 2.35;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const blue = MCRandom.randomInt(1, 119) / 120;
    const red = 1 - blue;
    const blue2 = MCRandom.randomInt(1, 119) / 120;
    const red2 = 1 - blue2;
    const r1 = red;
    const b1 = blue;
    const bb = blue2;
    const br = red2;
    const rb = blue2;
    const rr = red2;

    const mode = MCRandom.randomInt(0, 2);
    let ans = 0;
    // mode 0 exact 1
    // mode 1 never red
    // mode 2 both red
    let kill = 0;
    if (red.denominator > 50 || red2.denominator > 50) {
      kill = 1;
    }
    if (kill === 0) {
      if (mode === 0) {
        ans = blue * red2 + blue2 * red;
      }
      if (mode === 1) {
        ans = blue * blue2;
      }
      if (mode === 2) {
        ans = red * red2;
      }
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} is driving home and goes through two sets of traffic lights.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The probability of ${name.name} getting through the first set of lights on a green is $${blue}$.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The probability of ${name.name} getting stopped at the second set of lights is $${red2}$.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Draw a probability tree for these events.",
        false,
        true,
        true
      )
    );
    if (mode === 0) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$(b)$ What is the probability ${name.name} stops at exactly one set of lights?`,
          false,
          true,
          true
        )
      );
    }
    if (mode === 1) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$(b)$ What is the probability ${name.name} doesn't have to stop?`,
          false,
          true,
          true
        )
      );
    }
    if (mode === 2) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$(b)$ What is the probability ${name.name} has to stop twice?`,
          false,
          true,
          true
        )
      );
    }

    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    const img = new MCQuestion.Image(
      "GCSE/ProbabilityTree/ProbabilityTree.jpg",
      "height",
      15
    );
    img.addOverlay("$blue$", 100 / img_scale, 70 / img_scale - 3);
    img.addOverlay("$red$", 100 / img_scale, 240 / img_scale - 18);
    img.addOverlay("$blue$", 220 / img_scale, 35 / img_scale - 1);
    img.addOverlay("$red$", 220 / img_scale, 110 / img_scale - 5);
    img.addOverlay("$blue$", 220 / img_scale, 205 / img_scale - 16);
    img.addOverlay("$red$", 220 / img_scale, 280 / img_scale - 22);
    img.addOverlay(MCMaths.cleaner(`$${b1}$`), 20 / img_scale, 90 / img_scale);
    img.addOverlay(
      MCMaths.cleaner(`$${r1}$`),
      20 / img_scale,
      220 / img_scale - 20
    );
    img.addOverlay(
      MCMaths.cleaner(`$${bb}$`),
      180 / img_scale - 7,
      25 / img_scale + 1
    );
    img.addOverlay(
      MCMaths.cleaner(`$${br}$`),
      180 / img_scale - 7,
      120 / img_scale - 7
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rb}$`),
      180 / img_scale - 7,
      190 / img_scale - 12
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rr}$`),
      180 / img_scale - 7,
      290 / img_scale - 23
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);

    question.addImage("solution", img);
    if (mode === 0) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(b) \\ {(${blue}} \\times{${red2})+(${red}} \\times{${blue2})}=${ans}$`,
          true,
          true,
          true
        )
      );
      group.addInput(ans, 0.01, "$(b)$");
    }
    if (mode === 1) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(b) \\ {${blue}} \\times{${blue2}}=${ans}$`,
          true,
          true,
          true
        )
      );
      group.addInput(ans, 0.01, "$(b)$");
    }
    if (mode === 2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(b) \\ {${red}} \\times{${red2}}=${ans}$`,
          true,
          true,
          true
        )
      );
      group.addInput(ans, 0.01, "$(b)$");
    }

    question.addInputGroup(group);
    if (
      red.denominator > 50 ||
      red2.denominator > 50 ||
      kill === 1 ||
      ans.denominator > 200 ||
      blue > 0.9 ||
      blue < 0.1 ||
      blue2 > 0.9 ||
      blue2 < 0.1
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_ProbabilityAndStatistics_Statistics_Sampling_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const pop1 = MCRandom.randomInt(50, 100);
    const pop2 = MCRandom.randomInt(50, 100);
    const frac2 = MCRandom.randomInt(1, 119) / 120;
    const tag2 = frac2 * pop2;
    const x = (pop1 * pop2) / tag2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} is researching the population size of birds in a field.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} goes to the field and catches $${pop1}$ birds, tagging each one he catches.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A few days later ${name.heshe} returns to the field and catches $${pop2}$ birds.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${tag2}$ of these birds are tagged.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Estimate the population size of the birds living the the field.",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `\${${pop1} \\over x}={${tag2}\\over ${pop2}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x=${x}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So there are approximately $${x}$ birds in the field.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(x, 5);
    question.addInputGroup(group);

    if (
      Math.round(tag2) !== tag2 ||
      frac2 >= 0.9 ||
      frac2 <= 0.01 ||
      Math.round(x) !== x ||
      pop1 === pop2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_ProbabilityAndStatistics_Statistics_Sampling_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const pop1 = MCRandom.randomInt(50, 100);
    const pop2 = MCRandom.randomInt(50, 100);
    const frac2 = MCRandom.randomInt(1, 119) / 120;
    const tag2 = frac2 * pop2;
    const x = (pop1 * pop2) / tag2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} is researching the population size of ducks in a pond.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.heshe} goes to the pond and tags $${pop1}$ ducks.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Later on in the year ${name.heshe} returns to the field and checks $${pop2}$ ducks.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${tag2}$ of these ducks are tagged.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Estimate the population size of the ducks.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ State an assumption you have made.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ \${${pop1} \\over x}={${tag2}\\over ${pop2}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x=${x}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b)$ Any one of:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\bullet$ The population does not immigrate/emigrate",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\bullet$ None of the population dies",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\bullet$ The tagging does not affect the population.",
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(x, 5, "$(a)$");
    question.addInputGroup(group);

    if (
      Math.round(tag2) !== tag2 ||
      frac2 >= 0.9 ||
      frac2 <= 0.01 ||
      Math.round(x) !== x ||
      pop1 === pop2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_ProbabilityAndStatistics_Statistics_Sampling_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const birds = [
      "blackbird",
      "owl",
      "robin",
      "duck",
      "swan",
      "raven",
      "pigeon",
    ];
    const b1 = birds[MCRandom.randomInt(0, 6)];
    const b2 = birds[MCRandom.randomInt(0, 6)];

    const pop1 = MCRandom.randomInt(50, 100);
    const pop2 = MCRandom.randomInt(50, 100);
    const frac2 = MCRandom.randomInt(1, 119) / 120;
    const tag2 = frac2 * pop2;
    const x = (pop1 * pop2) / tag2;

    const pop1b = MCRandom.randomInt(50, 100);
    const pop2b = MCRandom.randomInt(50, 100);
    const frac2b = MCRandom.randomInt(1, 119) / 120;
    const tag2b = frac2b * pop2b;
    const xb = (pop1b * pop2b) / tag2b;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "A group of scientists is researching population sizes of two populations of birds.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `They go to a field and catch $${pop1}$ ${b1}s, and $${pop1b}$ ${b2}s, tagging each caught bird.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A few days later they returns to the field and catches $${pop2}$ ${b1}s, and $${pop2b}$ ${b2}s.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${tag2}$ of the ${b1}s are tagged, compared to $${tag2b}$ of the ${b2}s.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Which species of birds has the largest populaiton in the field?",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `\${${pop1} \\over a}={${tag2}\\over ${pop2}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$a=${x}$, so there are $${x}$ ${b1}s.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `\${${pop1b} \\over b}={${tag2b}\\over ${pop2b}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$b=${xb}$, so there are $${xb}$ ${b2}s.`,
        false,
        true,
        true
      )
    );
    if (x >= xb) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `So, ${b1}s have a greater population size than ${b2}s in the field.`,
          false,
          true,
          true
        )
      );
    }
    if (x <= xb) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `So, ${b2}s have a greater population size than ${b1}s in the field.`,
          false,
          true,
          true
        )
      );
    }

    question.requiresSelfMarking();

    if (
      Math.round(tag2) !== tag2 ||
      frac2 >= 0.9 ||
      frac2 <= 0.01 ||
      Math.round(x) !== x ||
      pop1b === pop2b ||
      Math.round(tag2b) !== tag2b ||
      frac2b >= 0.9 ||
      frac2b <= 0.01 ||
      Math.round(xb) !== xb ||
      pop1 === pop2 ||
      Math.abs(x - xb) >= 10 ||
      x === xb ||
      b1 === b2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_ProbabilityAndStatistics_Statistics_Sampling_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const pop1 = MCRandom.randomInt(50, 100);
    const pop2 = MCRandom.randomInt(50, 100);
    const frac2 = MCRandom.randomInt(1, 119) / 120;
    const tag2 = frac2 * pop2;
    const x = (pop1 * pop2) / tag2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.name} is a fisherman.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} wants to see how many fish there are in ${name.hisher} favorite fishing spot.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} goes to the spot and catches $${pop1}$ fish, tagging and throwing back each one ${name.heshe} catches.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A few days later ${name.heshe} returns to the spot and catches $${pop2}$ fish.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${tag2}$ of these fish are tagged.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Estimate the population size of the fish at the spot.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `\${${pop1} \\over x}={${tag2}\\over ${pop2}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x=${x}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So there are approximately $${x}$ fish at the spot.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(x, 5);
    question.addInputGroup(group);

    if (
      Math.round(tag2) !== tag2 ||
      frac2 >= 0.9 ||
      frac2 <= 0.01 ||
      Math.round(x) !== x ||
      pop1 === pop2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_ProbabilityAndStatistics_Statistics_Sampling_Exam_Q5() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // 0.abc * 0.d  b c and d reccuring
    const ParkAnimals = ["squirrel", "hedgehog", "robin"];
    const Animal =
      ParkAnimals[Math.round(Math.random() * (ParkAnimals.length - 1))];
    const name1 = MCMisc.getName();
    // test
    // test
    const AnimalsTagged = Math.round(Math.random() * 40 + 20);

    const AnimalsRecaptured = Math.round(Math.random() * 40 + 20);

    const AnimalsRepaturedWithTag = Math.round(
      Math.random() * 0.2 * AnimalsRecaptured + 10
    );

    const EstimatedPopulation = Math.round(
      (AnimalsTagged * AnimalsRecaptured) / AnimalsRepaturedWithTag
    );

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} wants to estimate the ${Animal} population in a local park.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} catchs a sample of $${AnimalsTagged}$ of the ${Animal} in the park, tags each of them and releases them.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The following day ${name1.name} catchs another $${AnimalsRecaptured}$ of the ${Animal}, $${AnimalsRepaturedWithTag}$ of which already have a tag on.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Estimate the number of ${Animal} in the park.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ State an assumption you have made.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ Estimate the number of ${Animal} in the park.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$Estimated~population ={Total~Animals~Tagged \\times Animals~Recaptured \\over Animals~Recaptured~With~Tag }$ ",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$={${AnimalsTagged} \\times${AnimalsRecaptured}\\over${AnimalsRepaturedWithTag}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$=${EstimatedPopulation}$ ${Animal} in the park`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(b)$ State an assumption you have made.",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\bullet$ The population does not immigrate/emigrate",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\bullet$ None of the population dies",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\bullet$ The tagging does not affect the population",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\bullet$ None of the tags have fallen off.",
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(EstimatedPopulation, 5, "$(a)$");
    question.addInputGroup(group);

    if (
      AnimalsRepaturedWithTag > AnimalsRecaptured ||
      AnimalsRepaturedWithTag > AnimalsTagged
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Rparoc_Rparoc_CompoundUnits_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const elements = [
      ["Gold", 19.3],
      ["Iron", 7.87],
      ["Silver", 10.5],
      ["Bronze", 8.73],
      ["Magnesium", 1.74],
      ["Aluminium", 2.7],
    ];
    const randomiser = MCRandom.randomInt(0, elements.length - 1);
    const element = elements[randomiser][0];

    const density = elements[randomiser][1];
    const sideLength = MCRandom.randomInt(1, 20);
    const volume = sideLength ** 3;

    const mass = density * volume;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${element} has a density of $${density}$ $g/cm^3$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` What is the mass of a gold cube with a side length of $${sideLength}cm$ in kilograms to $3$  significant figures?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` The mass of the ${element.toLowerCase()} cube is $${density}  \\times ${sideLength}^3 $`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$= ${density}  \\times ${volume}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$ = ${mass}g$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$  = ${(mass / 1000).toPrecision(3)}kg \\ (3 sf)$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(mass / 1000, 0.1);
    question.addInputGroup(group);

    if (mass < 1000) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Rparoc_Rparoc_CompoundUnits_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const oldCountyArea = MCRandom.randomInt(20, 67) * 100;
    const oldCountyPopDensity = MCRandom.randomInt(20, 35) * 10;
    const oldCountyPopulation = oldCountyPopDensity * oldCountyArea;

    const cityArea = MCRandom.randomInt(5, 20) * 10;
    const cityPopulation = MCRandom.randomInt(200, 400) * 1000;
    const cityPopDensity = cityPopulation / cityArea;

    const newCountyArea = oldCountyArea + cityArea;
    const newCountyPopulation = oldCountyPopulation + cityPopulation;
    const newCountyPopDensity = newCountyPopulation / newCountyArea;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " A county in the UK gets allocated some new land and subsequently decides to build a new city in this new land.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The county, before being allocated the new land, had an area of $${oldCountyArea}$ km$^2$ and a population density of $${oldCountyPopDensity}$ per km$^2$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The city is planned to have a population of $${cityPopulation}$ and area of $${cityArea}$ km$^2$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " What is the new population density of the county after the city is built, to $3$ significant figures?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " Given that $population\\ density = {{population} \\over {area}}$.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " You are given that $population\\ density = {{population} \\over {area}}$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " So in order to find population density we need to find the county's new area and population.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Start by obtaining the value for the area of the county after the city is built.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " The new area is obtained by adding the old county area and city area together:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $A_{new} = ${oldCountyArea} + ${cityArea}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $A_{new} = ${newCountyArea}$.`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " The new value for population is found by adding the old population and city population.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " However, we do not know the old population, so we must first obtain that by multiplying the old population density by the old area:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $P_{old} = ${oldCountyPopDensity}  \\times ${oldCountyArea}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $P_{old} = ${oldCountyPopulation}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Now find the new population:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $P_{new} = ${oldCountyPopulation} + ${cityPopulation}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $P_{new} = ${newCountyPopulation}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Now we have the new area and population we can find the new population density.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $D_{new} = {${newCountyPopulation} \\over ${newCountyArea}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $D_{new} = ${newCountyPopDensity.toPrecision(3)}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore the new population density is $${newCountyPopDensity.toPrecision(
          3
        )}$ km$^2$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(newCountyPopDensity, 0.6);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (oldCountyPopDensity % 100 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Rparoc_Rparoc_GrowthAndDecay_Exam_Q1() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const money = MCRandom.randomInt(10, 100) * 10000;
    const time = MCRandom.randomInt(3, 5);

    // Plan 1
    const interest1 = MCRandom.randomInt(1, 8);
    const final1 = money * (interest1 / 100 + 1 ** time);
    const working1 = interest1 / 100 + 1;
    // Plan 2
    const initial2 = MCRandom.randomInt(1, 100) / 10;
    const interest2 = MCRandom.randomInt(1, 20) / 4;
    const final2 =
      money * (interest2 / 100 + 1 ** (time - 1)) * (initial2 / 100 + 1);
    const working2initial = initial2 / 100 + 1;
    const working2interest = interest2 / 100 + 1;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has $${DisplayMoney(
          money
        )}$ and wants to invest it for $${time}$ years.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.hisher.charAt(0).toUpperCase()}${name.hisher
          .substr(1)
          .toLowerCase()} bank offers two saving plans:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$\\cdot$ Standard Saver: $${interest1}\\%$ compound interest per year.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$\\cdot$ Quick Saver: $${initial2}\\%$ interest in the first year, and then $${interest2}\\%$ compound interest each following year.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `For the best investment, show which plan should ${name.heshe} use.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Standard Saver $=${DisplayMoney(
          money
        )} \\times${working1}^${time}=${DisplayMoney(final1.toFixed(0))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Quick Saver $=${DisplayMoney(
          money
        )} \\times${working2initial} \\times${working2interest}^${
          time - 1
        }=${DisplayMoney(final2.toFixed(0))}$`,
        false,
        true,
        true
      )
    );

    if (final1 >= final2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `So, ${name.heshe} should use Standard Saver`,
          false,
          true,
          true
        )
      );
    }
    if (final1 <= final2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `So, ${name.heshe} should use Quick Saver`,
          false,
          true,
          true
        )
      );
    }

    question.requiresSelfMarking();

    if (
      Math.abs(final1 / final2 - 1) >= 0.2 ||
      interest1 <= interest2 ||
      initial2 <= interest1 ||
      final1 === final2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */

// \\
// ||
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)


export function GCSE_Crossover_Rparoc_Rparoc_GrowthAndDecay_Exam_Q10() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const numOfYears = MCRandom.randomInt(3, 15);
    const investment = MCRandom.randomInt(10, 50) * 100;
    const interest = MCRandom.randomInt(15, 60) / 10;
    const interest_Dec = interest / 100;
    const interest_Multiplier = 1 + interest_Dec;

    const currentBalance = investment * interest_Multiplier ** numOfYears;
    const moneyGained = currentBalance - investment;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} invests $\\pounds${investment}$ into a bank that offers $I\\%$ interest per annum.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " The amount of money in the account, $M$ after $n$ years can be calculated using the formula:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` $M = ${investment}  \\times ${interest_Multiplier}^{n}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("   $(a)$ What is the value of $I$?", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `   $(b)$ How much money does ${person.name} gain from interest after $${numOfYears}$ years?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " $(a)$ When we are increasing a value of something by compound interest, we make a multiplier that is: $1$ $+$ (the interest rate as a decimal)",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore we can see that the interest rate as a decimal is $${interest_Dec}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Multplying this value by $100$ will get the interest as a decimal back to a percentage:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${interest_Dec}  \\times 100 = ${interest}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` Therefore $I = ${interest}\\%$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(b)$ Using the formula that is given, since $n$ is the number of years and the investment is kept in the bank for $${numOfYears}$, replace $n$ for: $${numOfYears}$:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $M = ${investment}  \\times ${interest_Multiplier}^{${numOfYears}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $M = ${currentBalance.toFixed(2)}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " The money gained from the interest is the current balance in the account minus the initial investment:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${currentBalance.toFixed(2)} - ${investment} = ${moneyGained.toFixed(
          2
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore ${person.name} has gained $${DisplayMoney(
          moneyGained.toFixed(2) * 100
        )}$ from interest.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(interest, 0, "$(a)$");
    group.addInput(moneyGained, 0.01, "$(b)\\ \\pounds $");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (investment % 1000 === 0 || interest % 1 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Rparoc_Rparoc_GrowthAndDecay_Exam_Q11() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const initialPopulation = MCRandom.randomInt(5, 70) * 100;
    const populationMultiplier = MCRandom.randomInt(110, 150) / 100;
    const numOfYears = MCRandom.randomInt(4, 13);
    const currentPopulation = Math.round(
      initialPopulation * populationMultiplier ** numOfYears
    );

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` On $1^{st}$ January $2005$ there were $${initialPopulation}$ people living in a town.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` After $${numOfYears}$ years there were $${currentPopulation}$ people living in the same town.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " The population, $N$, after $t$ years, is given by:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " $N = Ar^t$, where $r$ is a constant.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "   $(a)$ Write down the value of $A$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "   $(b)$ What is the value of $r$ to $3$ significant figures?",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " $(a)$ The value of $A$ is the population of the town when $t = 0$, i.e. the initial population.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore $A = ${initialPopulation}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(b)$ In order to find the value of $r$, using the given equation, set it equal to the population after $${numOfYears}$ years:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $Ar^t = ${currentPopulation}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " However, $A$ and $t$ are both known, since $A$ is found in part $(a)$ and $t$ is the amount of years since $1^{st}$ January $2005$:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${initialPopulation}r^{${numOfYears}} = ${currentPopulation}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Now solve for $r$:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $r^{${numOfYears}} = {${currentPopulation} \\over ${initialPopulation}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $r = \\sqrt[${numOfYears}]{${currentPopulation} \\over ${initialPopulation}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $r = ${populationMultiplier.toPrecision(
          3
        )}$ to $3$ significant figures`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(initialPopulation, 1, "$(a) \\ A=$");
    group.addInput(populationMultiplier, 0.1, "$(b) \\ r=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (initialPopulation % 500 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Rparoc_Rparoc_GrowthAndDecay_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    function roundToTwo(num) {
      return +`${Math.round(`${num}e+2`)}e-2`;
    }

    const person = MCMisc.getName();
    const accountTotal = MCRandom.randomInt(3, 30) * 100;
    const bankInterestRate = MCRandom.randomInt(15, 50) / 1000;
    const bankInterestRatePerc = roundToTwo(bankInterestRate * 100);
    const numOfYears = MCRandom.randomInt(2, 10);
    const interestMultiplier = 1 + bankInterestRate;
    const originalAccountTotal = accountTotal;
    const newAccountTotal = roundToTwo(
      accountTotal * interestMultiplier ** numOfYears
    );
    const interestGained = roundToTwo(newAccountTotal - accountTotal);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} has a savings account with $\\pounds${accountTotal}$ within it.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The bank that ${person.name} is with, provides an interest rate of $${bankInterestRatePerc}\\%$ on their account total.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` After $${numOfYears}$ years ${person.name} closes the account and withdraws all of ${person.hisher} money.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `   $(a)$ How much money does ${person.name} withdraw?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `   $(b)$ How much money has ${person.name} gained from interest?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(a)$ First divide the percentage by $100$:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${bankInterestRatePerc}  \\div 100 = ${bankInterestRate}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Since ${person.name} gains money from this interest, their account total will increase, therefore we will be multiplying by $1 +$ 'the bank interest rate':`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $1 + ${bankInterestRate} = ${interestMultiplier}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Then multiply our account total, by this interest multiplier, to the power of the number of years we keep the money in the account for:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${accountTotal}  \\times (${interestMultiplier})^${numOfYears} = ${newAccountTotal}$`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Hence ${person.name} has withdrawn $\\pounds${newAccountTotal}$ from their account.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(b)$ To work out how much money they have gained from interest, simply subtract their original account total from the amount of money they have withdrawn:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${newAccountTotal} - ${accountTotal} = ${interestGained}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Hence ${person.name} has gained $\\pounds${interestGained}$ from interest.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(newAccountTotal, 0.01, "$(a) \\ \\pounds $");
    group.addInput(interestGained, 0.01, "$(b) \\ \\pounds $");
    question.addInputGroup(group);

    if (accountTotal % 500 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Rparoc_Rparoc_GrowthAndDecay_Exam_Q3() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const numOfYears = MCRandom.randomInt(3, 15);
    const appreciationPerc = MCRandom.randomInt(20, 60) / 10;
    const appreciationPerc_Decimal = appreciationPerc / 100;
    const originalPrice = MCRandom.randomInt(50, 200) * 1000;
    const currentPrice =
      originalPrice * (1 + appreciationPerc_Decimal ** numOfYears);
    const moneyGained = currentPrice - originalPrice;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} bought a house $${numOfYears}$ years ago and now wants to sell it.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The average increase in value of a house over the past $${numOfYears}$ years was $${appreciationPerc}\\%$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      ` The original price of the house was $${DisplayMoney(
        originalPrice.toFixed(2) * 100
      )}$`
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "   $(a)$ What is the valuation of the house now?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `   $(b)$ How much money has ${person.name} gained?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " Give your answers to two decimal places.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " $(a)$ Change the percentage to a decimal by dividing it by $100$:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${appreciationPerc}  \\div 100 = ${appreciationPerc_Decimal}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Since the price of the house is increasing, add this value to $1$ in order to make it a multiplier:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $1 + ${appreciationPerc_Decimal} = ${1 + appreciationPerc_Decimal}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Now multiply the original price by the appreciation multiplier to the power of how many years ${person.name} kept the house for:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${originalPrice}  \\times ${appreciationPerc_Decimal}^{${numOfYears}} = ${currentPrice.toFixed(
          2
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore the current value of the house is $${DisplayMoney(
          currentPrice.toFixed(2) * 100
        )}$`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(b)$ In order to find out how much money ${person.name} has gained subtract the original price from the current valuation:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${currentPrice.toFixed(
          2
        )} - ${originalPrice} = ${moneyGained.toFixed(2)}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore ${person.name} gained $${DisplayMoney(
          moneyGained.toFixed(2) * 100
        )}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(currentPrice, 0.01, "$(a) \\ \\pounds $");
    group.addInput(moneyGained, 0.01, "$(b) \\ \\pounds $");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (originalPrice % 10000 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Rparoc_Rparoc_GrowthAndDecay_Exam_Q4() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const numOfYears = MCRandom.randomInt(3, 15);
    const depreciationPerc = MCRandom.randomInt(100, 200) / 10;
    const depreciationPerc_Decimal = depreciationPerc / 100;
    const multiplier = 1 - depreciationPerc_Decimal;
    const originalPrice = MCRandom.randomInt(50, 500) * 100;
    const currentPrice = originalPrice * multiplier ** numOfYears;
    const moneyLost = originalPrice - currentPrice;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} bought a car $${numOfYears}$ years ago and now wants to sell it.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The average rate of depreciation of the value of a car over the past $${numOfYears}$ years was $${depreciationPerc}\\%$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      ` The original price of the car $${DisplayMoney(
        originalPrice.toFixed(2) * 100
      )}$`
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "   $(a)$ What is the valuation of the car now?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `   $(b)$ How much money has ${person.name} lost?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " Give your answers to two decimal places.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "  $(a)$ Start of by dividing the percentage by $100$ to get the percentage as a decimal:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${depreciationPerc}  \\div 100 = ${depreciationPerc_Decimal}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Since the value of the car is decreasing after each year, subtract this decimal from $1$ in order to obtain the multiplier:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $1 - ${depreciationPerc_Decimal} = ${multiplier}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` In order to find out the value of the car now, you must multiply the orignal price by the multiplier to the power of how many years ${person.name} kept the car for:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${originalPrice}  \\times ${multiplier}^{${numOfYears}} = ${currentPrice.toFixed(
          2
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore the current value of the car is $${DisplayMoney(
          currentPrice.toFixed(2) * 100
        )}$.`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(b)$ In order to find out how much money ${person.heshe} has lost you must subtract the current value of the car from the original price of the car:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${originalPrice} - ${currentPrice.toFixed(2)} = ${moneyLost.toFixed(
          2
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore ${person.name} has lost $${DisplayMoney(
          moneyLost.toFixed(2) * 100
        )}$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(currentPrice, 0.01, "$(a) \\ \\pounds $");
    group.addInput(moneyLost, 0.01, "$(b) \\ \\pounds $");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (originalPrice % 1000 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Rparoc_Rparoc_GrowthAndDecay_Exam_Q5() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const moneyInvested = MCRandom.randomInt(10, 50) * 100;
    const interestPerc = MCRandom.randomInt(15, 50) / 10;
    const interestPerc_Decimal = interestPerc / 100;
    const interestMultiplier = 1 + interestPerc_Decimal;
    const numOfYears = MCRandom.randomInt(5, 15);
    const moneyWithdrawn = moneyInvested * interestMultiplier ** numOfYears;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} invests $\\pounds${moneyInvested}$ into a bank account that offers $${interestPerc}\\%$ compound interest per annum on all investments.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` After '$n$' number of years, ${person.heshe} withdraws the money ${person.heshe} invested.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      ` The amount withdrawn is $${DisplayMoney(
        moneyWithdrawn.toFixed(2) * 100
      )}$`
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` How many years did ${person.name} keep the money in the account for?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Consider the following:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${moneyInvested}  \\times ${interestMultiplier}^{n}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Now input any sensible value and repeat until the value matches:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${moneyInvested}  \\times ${interestMultiplier}^{2} = ${(
          moneyInvested *
          interestMultiplier ** 2
        ).toFixed(2)}$. (Too small therefore increase '$n$')`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${moneyInvested}  \\times ${interestMultiplier}^{4} = ${(
          moneyInvested *
          interestMultiplier ** 4
        ).toFixed(2)}$. (Too small therefore increase '$n$')`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" ...", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${moneyInvested}  \\times ${interestMultiplier}^{${numOfYears}} = ${moneyWithdrawn.toFixed(
          2
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore ${person.name} kept the money in the account for $${numOfYears}$ years.`,
        false,
        true,
        true
      )
    );

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    const group = new MCQuestion.InputGroup(1);
    group.addInput(numOfYears, 0);
    question.addInputGroup(group);

    if (interestPerc % 1 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Rparoc_Rparoc_GrowthAndDecay_Exam_Q6() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const savings = MCRandom.randomInt(15, 40) * 100;
    const numOfYears = MCRandom.randomInt(3, 8);
    const bank1Interest = MCRandom.randomInt(15, 60) / 10;
    const bank1Interest_Dec = bank1Interest / 100;
    const bank1Interest_Multiplier = 1 + bank1Interest_Dec;
    const bank2Interest = MCRandom.randomInt(5, 20) / 10;
    const bank2Interest_Dec = bank2Interest / 100;
    const bank2Interest_Multiplier = 1 + bank2Interest_Dec;
    const bank2Reward = savings / MCRandom.randomInt(10, 30);

    const bank1Balance = savings * bank1Interest_Multiplier ** numOfYears;
    const bank2Balance =
      savings * bank2Interest_Multiplier ** numOfYears + bank2Reward;

    const bank1MoneyGained = bank1Balance - savings;
    const bank2MoneyGained = bank2Balance - savings;

    let chosenBank;

    if (bank1MoneyGained > bank2MoneyGained) {
      chosenBank = "Archer Bank";
    } else {
      chosenBank = "Brookes Bank";
    }

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} wants to gain interest on ${person.hisher} savings.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.HeShe} has two options of two different banks, Archer Bank and Brookes Bank.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` Archer Bank offers $${bank1Interest}\\%$ on all savings.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` Brookes Bank offers a lower interest of $${bank2Interest}\\%$, however they offer a joining reward of $${DisplayMoney(
          bank2Reward.toFixed(2) * 100
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} has $\\pounds${savings}$ in savings.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` Which bank should ${person.name} go with if ${person.heshe} wants to earn the most savings after having ${person.hisher} savings in the bank for $${numOfYears}$ years.`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Start off by changing each percentage to their corresponding decimal version:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Archer Bank Interest $= ${bank1Interest_Dec}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Brookes Bank Interest $= ${bank2Interest_Dec}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Since interest is being gained we need to make this interest into a positve multiplier by adding this decimal to $1$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Archer Bank multiplier $= ${bank1Interest_Multiplier}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Brookes Bank multiplier $= ${bank2Interest_Multiplier}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` The balance that ${person.name} would have in Archer Bank after $${numOfYears}$ years would be:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${savings}  \\times ${bank1Interest_Multiplier}^{${numOfYears}} = ${bank1Balance.toFixed(
          2
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` The money gained from having ${person.hisher} savings in Archer Bank for $${numOfYears}$ years therefore is:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${bank1Balance.toFixed(2)} -${savings} = ${bank1MoneyGained.toFixed(
          2
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore the money that would be gained from Archer Bank is $${DisplayMoney(
          bank1MoneyGained.toFixed(2) * 100
        )}$.`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` The balance that ${person.name} would have in Brookes Bank after $${numOfYears}$ years would be:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${savings}  \\times ${bank2Interest_Multiplier}^{${numOfYears}} + ${bank2Reward.toFixed(
          2
        )} = ${bank2Balance.toFixed(2)}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` The money gained from having his savings in Brookes Bank for $${numOfYears}$ years therefore is:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${bank2Balance.toFixed(2)} -${savings} = ${bank2MoneyGained.toFixed(
          2
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore the money that would be gained from Brookes Bank is $${DisplayMoney(
          bank2MoneyGained.toFixed(2) * 100
        )}$.`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore ${person.name} should choose to save money with ${chosenBank}.`,
        false,
        true,
        true
      )
    );
    question.requiresSelfMarking();
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (savings % 1000 === 0 || bank2Reward % 100 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Rparoc_Rparoc_GrowthAndDecay_Exam_Q7() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const moneyDeposited = MCRandom.randomInt(15, 50) * 100;

    const person1 = MCMisc.getName();
    const person2 = MCMisc.getName();

    const bank1NumOfYears = MCRandom.randomInt(5, 10);
    const bank2NumOfYears = MCRandom.randomInt(2, 4);

    const bank1Interest = MCRandom.randomInt(5, 30) / 10;
    const bank2Interest = MCRandom.randomInt(15, 60) / 10;

    const bank1Interest_Dec = bank1Interest / 100;
    const bank2Interest_Dec = bank2Interest / 100;

    const bank1Interest_Multiplier = 1 + bank1Interest_Dec;
    const bank2Interest_Multiplier = 1 + bank2Interest_Dec;

    const bank1Balance =
      moneyDeposited * bank1Interest_Multiplier ** bank1NumOfYears;
    const bank2Balance =
      moneyDeposited * bank2Interest_Multiplier ** bank2NumOfYears;

    let answer;
    if (bank1Balance > bank2Balance) {
      answer = "correct";
    } else {
      answer = "incorrect";
    }

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` Two people, ${person1.name} and ${person2.name}, deposit the same amount of money into different banks for different amounts of time.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` They both deposit $\\pounds${moneyDeposited}$ into their corresponding banks.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person1.name} chooses a bank that provides $${bank1Interest}\\%$ per annum on savings and keeps ${person1.hisher} money there for $${bank1NumOfYears}$ years.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person2.name} chooses a bank that provides $${bank2Interest}\\%$ per annum on savings and keeps ${person2.hisher} money there for $${bank2NumOfYears}$ years.`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person1.name} believes that ${person1.heshe} will earn more money from interest than ${person2.name} will.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(` Is ${person1.name} correct?`, false, true, true)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` ${person1.name}'s account balance after $${bank1NumOfYears}$ years will be:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${moneyDeposited}  \\times ${bank1Interest_Multiplier}^{${bank1NumOfYears}} = ${bank1Balance.toFixed(
          2
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` ${person2.name}'s account balance after $${bank2NumOfYears}$ years will be:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${moneyDeposited}  \\times ${bank2Interest_Multiplier}^{${bank2NumOfYears}} = ${bank2Balance.toFixed(
          2
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore ${person1.name} is ${answer}.`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      bank1Interest > bank2Interest ||
      bank1NumOfYears < bank2NumOfYears ||
      bank1Interest % 1 === 0 ||
      bank2Interest % 1 === 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Rparoc_Rparoc_GrowthAndDecay_Exam_Q8() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const initialCost = MCRandom.randomInt(18, 200) * 10;
    const interestRate = MCRandom.randomInt(30, 100) / 10;
    const numOfYears = MCRandom.randomInt(5, 30);

    const interestRate_Dec = interestRate / 100;
    const interestRate_Multiplier = 1 + interestRate_Dec;
    const guitarValue = initialCost * interestRate_Multiplier ** numOfYears;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} recently bought a rare guitar for $\\pounds${initialCost}$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} estimates that the guitar will gain value at a rate of $${interestRate}\\%$ per annum.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` If ${person.heshe} is right how much will the guitar be worth in $${numOfYears}$ years?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Convert the interest rate percentage to a decimal:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${interestRate}  \\div 100 = ${interestRate_Dec}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Add this value to $1$ in order to make it an increasing multiplier:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $1 + ${interestRate_Dec} = ${interestRate_Multiplier}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Then multiply the initial cost of the guitar by the multiplier to the power of how many years ${person.heshe} kept the guitar for:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${initialCost}  \\times ${interestRate_Multiplier}^{${numOfYears}} = ${guitarValue.toFixed(
          2
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore the guitar now has an increased value of $${DisplayMoney(
          guitarValue.toFixed(2) * 100
        )}$.`,
        false,
        true,
        true
      )
    );

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (initialCost % 100 === 0 || interestRate % 1 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Rparoc_Rparoc_GrowthAndDecay_Exam_Q9() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const initialValue = MCRandom.randomInt(30, 150) * 10;

    const numOfYears = MCRandom.randomInt(2, 6);

    const depreciationRate = MCRandom.randomInt(100, 200) / 10;
    const depreciationRate_Dec = depreciationRate / 100;
    const depreciationRate_Multiplier = 1 - depreciationRate_Dec;

    const currentValue =
      initialValue * depreciationRate_Multiplier ** numOfYears;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` $${numOfYears}$ years ago ${person.name} bought a brand new laptop for $\\pounds${initialValue}$, and now wants to sell it at a reasonable price.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} estimates that the value of the laptop depreciated at a rate of $${depreciationRate}\\%$ per year.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` If ${person.heshe} is right how much should ${person.name} sell the laptop for?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Convert the depreciation rate percentage to a decimal:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${depreciationRate}  \\div 100 = ${depreciationRate_Dec}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Subtract this value from $1$ in order to make it an decreasing multiplier:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $1 - ${depreciationRate_Dec} = ${depreciationRate_Multiplier}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Then multiply the initial cost of the guitar by the multiplier to the power of how many years ${person.heshe} kept the guitar for:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${initialValue}  \\times ${depreciationRate_Multiplier}^{${numOfYears}} = ${currentValue.toFixed(
          2
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore ${person.name} should sell the laptop for $${DisplayMoney(
          currentValue.toFixed(2) * 100
        )}$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(currentValue, 0.01, "$\\pounds $");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (initialValue % 100 === 0 || depreciationRate % 1 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Rparoc_Rparoc_InverseProportion_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c

    function directK(let1, let2, power, init1, init2) {
      let k = 0;
      let working = "";
      working = `$${let1} = k${let2}^{${power}}$, `;
      if (power > 1) {
        working += `$${init1} = k(${init2})^{${power}}$, `;
      }
      working += `$${init1} = k  \\times ${init2 ** power}$, `;
      working += `$k = \\frac{${init1}}{${init2 ** power}}, $`;
      k = init1 / init2 ** power;
      working += `$k = ${k}$`;
      return {
        k,
        working,
      };
    }
    function inverseK(let1, let2, power, init1, init2) {
      let k = 0;
      let working = "";
      working = `$${let1} = \\frac{k}{${let2}^{${power}}}$, `;
      if (power > 1) {
        working += `$${init1} = \\frac{k}{(${init2})^{${power}}}$, `;
      }
      working += `$${init1} = \\frac{k}{${init2 ** power}}$, `;
      working += `$k = ${init1}  \\times ${init2 ** power}$, `;
      k = init1 * init2 ** power;
      working += `$k = ${k}$`;
      return {
        k,
        working,
      };
    }

    const initVol = (MCRandom.randomInt(4, 9) / 10) * 100;
    const initTime = MCRandom.randomInt(60, 120);
    const initPower = MCRandom.randomInt(1200, 1800);
    const initTime2 = MCRandom.randomInt(30, 90);
    const max = (1 + MCRandom.randomInt(6, 8) / 10) * 100;
    const ansA = directK("T", "l", 1, initTime, initVol);
    const ansB = inverseK("T", "P", 1, initTime2, initPower);
    const timeA = ansA.k * max;
    const wattsB = MCRandom.randomInt(800, initPower - 200);
    const timeB = ansB.k / wattsB;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The time, $T$ seconds, it takes for a block of ice to melt is directly proportional to the volume of water, $l$ milliletres, that is frozen.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `When $l = ${initVol}$, $T = ${initTime}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ How long does it take for the block of ice to melt when the volume is $${max}ml$? Give your answer correct to 3 significant figures.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The time, $T$ seconds, it takes a block of ice of constant volume to melt is inversely proportional to the power, $P$ watts, of a heater besides it.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `When $P = ${initPower}$, $T = ${initTime2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ How long does it take to melt the block of ice when the heater is receiving $${wattsB}$ watts of power? Give your answer correct to 3 significant figures.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a)\\ $ ${ansA.working}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$T = ${ansA.k}l$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$T = ${ansA.k}  \\times ${max}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$T = ${parseFloat(timeA.toFixed(5))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore ${timeA.toPrecision(3)}s$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b)\\ $${ansB.working}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$T = \\frac{${ansB.k}}{P}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$T = \\frac{${ansB.k}}{${wattsB}}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$T = ${parseFloat(timeB.toFixed(5))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore ${timeB.toPrecision(3)}s$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(timeA, 0.5, "$(a)$");
    group.addInput(timeB, 0.5, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      ansA.k * 100 !== Math.round(ansA.k * 100) ||
      initPower / 100 !== Math.round(initPower / 100) ||
      wattsB / 50 !== Math.round(wattsB / 50) ||
      timeB >= 600
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Crossover_Rparoc_Rparoc_ProportionAndGraphs_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const speed = MCRandom.randomInt(22, 32);

    const a = MCRandom.randomInt(2, 10);
    const a_ans = speed * a;

    /* END VARIABLES AND CONSTANTS */
    if ( true ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const g = new MCQuestion.Graph(12, 0, speed * 15, 0, 1, 50);
      g.plot(`${speed} * x`, 0, 12);
      question.addGraph('question', g);

      question.addParagraph('question', `The figure above shows a distance-time graph for a lorry travelling along the motorway where the distance is measured in metres, and time is measured in seconds.`);
      question.addParagraph('question', `a) Estimate how far the lorry travels in the first $${a}$ seconds?`);
      question.addParagraph('question',` b) Determine whether time is directly or inversely proportional to distance.`);
      question.addParagraph('question',` Explain your answer.`);
      question.addParagraph('question',` c) Hence, find the equation of the graph.`);
      question.addParagraph('question', `d) Interpret the gradient of the graph in the context of the question.`);


      // solution

      question.addHeading('solution', `a) Read the graph where $x=${a}$`);
      question.addParagraph('solution', `At $x=${a}, y=${a_ans}$`);
      question.addParagraph('solution', `$\\therefore$ The lorry travels $${a_ans}$m in the first $${a}$ seconds.`);

      // b

      question.addHeading('solution', `b) Distance is directly proportional to time.`);
      question.addParagraph('solution', `We know this as the graph is a straight line that passes through the origin with positive gradient.`);
      question.addParagraph('solution', `As the time doubles/increases so does the distance travelled.`);

      // c

      question.addHeading('solution', `c) From part b, we know $y \\propto x$`);
      question.addParagraph('solution', `$\\therefore y=kx$`);
      question.addHeading('solution', `Let $y=${a_ans}$ and $x=${a}$`);
      question.addParagraph('solution', `$${a_ans}=${a}k$`);
      question.addParagraph('solution', `$\\therefore k=${speed}$`);
      question.addParagraph('solution', `The equation of the graph is $y=${speed}x$`);

      // d

      question.addHeading('solution', `d) The gradient of a graph is the rate of change of the variable on the vertical axis compared to the horizontal axis.`);
      question.addParagraph('solution', `In this case that is the rate of change of the distance travelled by the lorry compared to time.`);
      question.addParagraph('solution', `This is the speed of the lorry which we calculated, in part c, to be $${speed}$m/s.`);

      // 

      const ans = new MCQuestion.InputGroup(1);
      ans.addInput(a_ans, 12.5, `$(a)$`);
      question.addInputGroup(ans);

      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Rparoc_Rparoc_ProportionAndGraphs_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    
    const hoursTotal = MCRandom.randomInt(8, 20);

    const hours = MCRandom.randomInt(2, 8)
    const days = hoursTotal/hours

    const person = MCMisc.getName();
    const b = MCRandom.randomInt(5, 15)
    const b_ans = hoursTotal/b;

    /* END VARIABLES AND CONSTANTS */
    if ( hoursTotal % hours === 0 ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();


      
      
      question.addParagraph('question', `${person.name} knows that if ${person.heshe} works $${hours}$ hours a day it will take ${person.himher} $${days}$ days to complete ${person.hisher} coursework.`);
      question.addParagraph('question', `The number of days is inversely proportional to the number of hours per day.`);
      question.addParagraph('question', `a) Find the equation for the number of days against the number of hours worked per day and plot the graph.`);
      question.addParagraph('question', `b) Given that the deadline for the coursework is in $${b}$ days, calculate the minimum number of hours ${person.name} must work each day to make the deadline.`);


      // solution

      question.addHeading('solution', `a) $y$ is inversely proportional to $x$`);
      question.addParagraph('solution', `$\\therefore y=\\displaystyle\\frac{k}{x}$`);
      question.addHeading('solution', `Let $x=${hours}$ and $y=${days}$`);
      question.addParagraph('solution', `$ ${days}=\\displaystyle\\frac{k}{${hours}}$`);
      question.addParagraph('solution', `$\\therefore k=${hoursTotal}$`);
      question.addParagraph('solution', `The equation of the graph, plotted below, is $y=\\displaystyle\\frac{${hoursTotal}}{x} $`);

      const g = new MCQuestion.Graph(12, 0, 20, 0, 1, 2);
      g.plot(`${hoursTotal} / x`, 0, 12);
      question.addGraph('solution', g);

      // b

      question.addHeading('solution',`b) Let $y=${b}$ in the formula calculated in part a.`);
      question.addParagraph('solution', `$${b}=\\displaystyle\\frac{${hoursTotal}}{x}$`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\therefore x=\\displaystyle ${b_ans}$`));
      question.addParagraph('solution', `${person.name} must work a minimum of $${parseFloat((b_ans.toFixed(2)))}$ hours a day to meet the deadline.`);
      //

      question.requiresSelfMarking();

      const a1 = new MCQuestion.InputGroup(1);
      a1.addInput(b_ans, 0.05, `$(b)$`);
      question.addInputGroup(a1);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Rparoc_Rparoc_ProportionAndGraphs_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const cpu = parseFloat((MCRandom.randomInt(10, 50)/10).toFixed(1));
    const p = MCMisc.getName();

    const n = MCRandom.randomInt(12, 25);
    const nCost = parseFloat((n * cpu).toFixed(2));

    const cMoney = MCRandom.randomInt(20, 50);
    const c_ans_float = cMoney/cpu;
    const c_ans = Math.floor(c_ans_float)
    

    /* END VARIABLES AND CONSTANTS */
    if ( c_ans_float % 1 > 0.5 ) {
      /*  BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph('question', `${p.name} makes keyrings and sells them on ${p.hisher} website. The number of keyrings in the basket is directly proportional to the price of the order.`);
      question.addParagraph('question', `${p.HeShe} receives an order for $${n}$ keyrings which he charges the buyer $£${nCost.toFixed(2)}$ for.`);

      question.addParagraph('question', `a) Draw the graph of cost against number of keyrings and state its gradient.`);
      question.addParagraph('question', `b) Interpret the gradient of your graph in context.`);
      question.addParagraph('question', `c) Calculate how many keyrings a customer can buy with $£${cMoney}$`);


      // solution

      question.addHeading('solution', `a) Let $y$ be the cost of an order and $x$ be the number of keyrings in that order.`);
      question.addParagraph('solution',` $y \\propto x$`);
      question.addParagraph('solution',` $\\therefore y=kx$`);
      question.addHeading('solution', `Let $y=${nCost}$ and $x=${n}$`);
      question.addParagraph('solution',` $${nCost}=${n}k$`);
      question.addParagraph('solution',` $\\therefore k = ${cpu}$`);

      question.addParagraph('solution', `The equation of the graph (drawn below) is $y=${cpu}x$, so the gradient of the graph is $${cpu}$`);

      const g = new MCQuestion.Graph(10, 0, Math.ceil(cpu) * 10, 0, 1, Math.ceil(cpu));
      g.plot(`${cpu}*x`, 0, 10);
      question.addGraph('solution', g);
      // b

      question.addHeading('solution', `b) The gradient of a graph is the rate of change of the variable on the vertical axis compared to the horizontal axis.`);
      question.addParagraph('solution', `In this case that is the rate of change of the cost of an order compared to the number of keyrings in the order.`);
      question.addParagraph('solution', `This is the cost per keyring which is $£${cpu.toFixed(2)}$.`);

      // c
      question.addHeading('solution', `c) Let $y=${cMoney}$ in the equation for the graph.`);
      question.addParagraph('solution', `$${cMoney}=${cpu}x$`);
      if (parseFloat(c_ans_float.toFixed(2))===c_ans_float)
      {
        question.addParagraph('solution', `$ x = ${c_ans_float}$`);

      }
      else
      {
        question.addParagraph('solution', `$ x\\approx ${parseFloat(c_ans_float.toFixed(2))}$`);

      }

      question.addParagraph('solution', `$\\therefore$ The customer can afford to buy $${c_ans}$ keyrings.`);

      // answers

      const a1 = new MCQuestion.InputGroup(1);
      a1.addInput(cpu, 0, `$(a)$`);
      a1.addInput(c_ans, 0, `$(c)$`);
      question.addInputGroup(a1);

      question.requiresSelfMarking();


      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Rparoc_Rparoc_ProportionAndGraphs_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    
    const k = MCRandom.randomInt(50, 100);

    const n = MCRandom.randomInt(100, 800, 25);

    const rating = MCRandom.randomInt(2, 5)
    const rating2 = MCRandom.randomInt(12, 15)

    const d_ans = k / rating2;

    const a_ans = parseFloat((k/rating).toFixed(0));
    /* END VARIABLES AND CONSTANTS */
    if ( d_ans > 5 && k/rating % 1 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(`question`, `A group of $${n}$ participants in a study rate videos based on how boring they perceive the subject matter to be, with a score of $10$ being extremely boring.`);
      question.addParagraph(`question`, `Then, a week after watching the videos, they completed a series of short quizzes focused on the topics covered in them.`);
      question.addParagraph('question', `The median test score, as a percentage, is plotted against the rating of its corresponding video to give the graph below.`);


      const g = new MCQuestion.Graph(10, 0, 100, 0, 1, 10);
      g.plot(`${k}/x`, 1, 10);
      question.addGraph('question', g);

      question.addParagraph('question', `The researchers hypothesise that median score is inveresely proportional to the score given to the corresponding video.`);


      question.addParagraph('question', `a) Estimate the median test score for a video with a rating of ${rating}`);
      question.addParagraph('question', `b) Explain what the researchers' hypothesis means in context.`);
      question.addParagraph('question', `c) Assuming the hypothesis is correct, calculate the equation of the graph.`);
      

      // solutions

      question.addHeading('solution', `a) Read the graph at $x=${rating}$`);
      question.addParagraph('solution', `At $x=${rating}, y = ${a_ans}$`);
      question.addParagraph('solution', `$\\therefore$ The median test score is $${a_ans}\\%$`);

      // b

      question.addHeading('solution', `b) Inveresely proportional means that one varaible decreases with respect to the increase in another variable.`);
      question.addParagraph('solution', `In this scenario, as a video becomes increasingly boring, the retention of its contents gets weaker (hence the lower median test scores).`);

      // c

      question.addHeading('solution', `c) Let $y$ be the median test score and $x$ be the rating.`);
      question.addParagraph('solution', `Assuming $\\displaystyle y \\propto \\frac{1}{x}$`);
      question.addParagraph('solution', `$\\displaystyle y = \\frac{k}{x}$`);
      question.addHeading('solution', `Let $y=${a_ans}$ and $x=${rating}$`);
      question.addParagraph('solution', `$\\displaystyle ${a_ans} = \\frac{k}{${rating}}$`);
      question.addParagraph('solution', `$ k = ${k}$`);
      question.addParagraph('solution', `$\\therefore \\displaystyle y = \\frac{${k}}{x}$`);
      //answers

      const a1 = new MCQuestion.InputGroup(1);

      a1.addInput(a_ans, 3, `$(a)$`);
      question.addInputGroup(a1)

      question.requiresSelfMarking();


      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Crossover_Rparoc_Rparoc_ProportionAndGraphs_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const pressure = MCRandom.randomInt(5, 25) / 10

    const area = MCRandom.randomInt(1, 8)

    const a_ans = parseFloat((area * pressure).toFixed(2));

    const c_area = MCRandom.randomInt(50, 250)

    const times = MCRandom.randomInt(2, 4)

    const c_pressure = parseFloat((times * pressure).toFixed(2));

    const c_force = parseFloat((c_pressure * c_area).toFixed(2))
    

    /* END VARIABLES AND CONSTANTS */
    if ( a_ans < 8 && a_ans > 2 && pressure === a_ans/area) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(`question`, `The equation for calculating the pressure applied to a surface is $P = \\displaystyle \\frac{F}{A}$`);
      question.addParagraph('question', `where $P$ is pressure in pascals $(Pa)$, $F$ is force in newtons $(N)$ and $A$ is surface area in $m^2$.`);
      question.addParagraph('question', `The graph below shows force against surface area.`);

      const g = new MCQuestion.Graph(10, 0, 10, 0, 1, 1);
      g.plot(`x/${pressure}`, 0, 10);
      question.addGraph('question', g);

      question.addParagraph('question', `a) Caculate the force applied to a surface area of $${area} m^2$.`);
      question.addParagraph('question', `b) Calculate the gradient of the graph and explain how this relates to the pressure exerted on the surface.`);
      question.addParagraph('question', `c) State the force exerted on a surface area of $${c_area} m^2$ such that the pressure exerted is $${times} \\times$ that of the graph above.`);

      // solution

      question.addHeading('solution', `a) Read the graph where $x=${area}$`);
      question.addParagraph('solution', `At $x=${area}, y=${a_ans}$`);
      question.addParagraph('solution', `$\\therefore$ The force is $${a_ans}N$ on a surface area of $${area}m^2$.`);

      question.addHeading('solution', `b) $m\\displaystyle =\\frac{\\Delta y}{\\Delta x}$`);
      question.addParagraph('solution', `As the graph passes through the origin, we only need our values from the previous question.`);
      question.addParagraph('solution', `$\\displaystyle m = \\frac{${a_ans}}{${area}}$`);
      question.addParagraph('solution', `$=${pressure}$`);

      question.addHeading('solution', `The gradient of the graph is the pressure exerted on the surface, as $F=PA$`);
      question.addParagraph('solution', `$\\therefore$ The gradient is constant so the pressure exerted never changes.`);

      // c

      question.addHeading('solution', `c) Let $A=${c_area}$`);
      question.addHeading('solution', `Let $P=${times}(${pressure})$`);
      question.addParagraph('solution', `$=${c_pressure}$`);

      question.addParagraph('solution', `$F=${c_pressure}(${c_area})$`)
      question.addParagraph('solution', `$=${c_force}$`)
      question.addParagraph('solution', `$\\therefore ${c_force} N$ of force is exerted.`)

      // answers

      const a = new MCQuestion.InputGroup(1);
      a.addInput(a_ans, 0.05, `$(a)$`);
      a.addInput(pressure, 0.05, `$(b)$`);
      a.addInput(c_force, 0.5, `$(c)$`);
      question.addInputGroup(a);

      question.requiresSelfMarking();

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const ax = MCRandom.randomInt(-9, 9);
    const ay = MCRandom.randomInt(-9, 9);

    const bx = MCRandom.randomInt(-9, 9);
    const by = MCRandom.randomInt(-9, 9);

    const mx = new MCMaths.Fraction((ax + bx) / 2);
    const my = new MCMaths.Fraction((ay + by) / 2);

    let pos = "";
    let comp = "";
    if (ax < bx) {
      pos = "leftmost";
      comp = "lesser";
    } else {
      pos = "rightmost";
      comp = "greater";
    }

    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(ax - bx) > 2 && Math.abs(ay - by) > 2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g.addCircle(ax, ay, 0.1);
      g.addCircle(bx, by, 0.1);
      question.addGraph("question", g);

      // for sake of answer boxes
      question.addParagraph(
        `question`,
        `The figure above shows two points, $A$ and $B$, plotted on a graph where $A$ is the ${pos} point.`
      );

      question.addParagraph(
        "question",
        `a) State the coordinates of $A$ and $B$.`
      );
      question.addParagraph(
        "question",
        `b) Calculate the coordinates of the midpoint of $AB$.`
      );
      //
      question.addHeading(
        "solution",
        `a) Reading the coordinates from the graph.`
      );
      question.addParagraph(
        "solution",
        `We can see the points are at $(${ax}, ${ay})$ and $(${bx}, ${by})$`
      );
      question.addParagraph(
        "solution",
        `$A$ is the ${pos} point so it has the ${comp} $x$-coordinate.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The points are $A(${ax}, ${ay})$ and $B(${bx}, ${by})$`
      );
      // b
      question.addHeading("solution", `b) Find $x$-coordinate of midpoint.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{${ax}+${bx}}{2}=${mx}$`)
      );
      question.addHeading("solution", `Find $y$-coordinate of midpoint.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{${ay}+${by}}{2}=${my}$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Midpoint of $AB$ is at $\\displaystyle\\left(${mx}, ${my}\\right)$`
      );

      // answers
      const a1 = new MCQuestion.InputGroup(2);
      a1.addInput(ax, 0, "$(a) \\ A: x=$");
      a1.addInput(ay, 0, "$(a) \\ A: y=$");

      a1.addInput(bx, 0, "$(a) \\ B: x=$");
      a1.addInput(by, 0, "$(a) \\ B: y=$");

      a1.addInput(mx.toFloat(), 0.05, "$(b) \\ x=$");
      a1.addInput(my.toFloat(), 0.05, "$(b) \\ y=$");

      question.addInputGroup(a1);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    // A is top-left, B is top-right, C is bottom-left, D is bottom-right
    const ax = MCRandom.randomInt(-9, -1);
    const ay = MCRandom.randomInt(1, 9);

    const xDiff = MCRandom.randomInt(4, 16);
    const yDiff = MCRandom.randomInt(4, 16);

    const bx = ax + xDiff;
    const by = ay;

    const dx = ax;
    const dy = ay - yDiff;

    const cx = bx;
    const cy = dy;

    const missingPoint = ["A", "B", "C", "D"][MCRandom.randomInt(0, 3)];

    const perimeter = 2 * xDiff + 2 * yDiff;
    /* END VARIABLES AND CONSTANTS */
    if (
      ax + xDiff < 10 &&
      ay - yDiff > -10 &&
      xDiff !== yDiff &&
      cy < 0 &&
      bx > 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const a1 = new MCQuestion.InputGroup(2);

      if (missingPoint === "A") {
        question.addParagraph(
          "question",
          `Three coordinates of a rectangle $ABCD$ are $B(${bx}, ${by}), C(${cx}, ${cy})$ and $D(${dx}, ${dy})$`
        );
        question.addParagraph(
          "question",
          `a) Calculate the coordinates of $A$.`
        );

        question.addHeading(
          "solution",
          `a) The easiest way to find $A$ is to visualise the rectangle.`
        );
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(bx, by, 0.1);
        g.addCircle(cx, cy, 0.1);
        g.addCircle(dx, dy, 0.1);
        question.addGraph("solution", g);

        question.addParagraph(
          "solution",
          `As you can see, the top left vertex of the rectangle is missing. This point must have the same $x$-coordinate as $D$, as it's directly above it, and the same $y$-coordinate as $B$, as it's level with it.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ $A$ is located at $(${ax}, ${ay})$`
        );

        a1.addInput(ax, 0, "$(a)\\ x=$");
        a1.addInput(ay, 0, "$(a)\\ y=$");
      } else if (missingPoint === "B") {
        question.addParagraph(
          "question",
          `Three coordinates of a rectangle $ABCD$ are $A(${ax}, ${ay}), C(${cx}, ${cy})$ and $D(${dx}, ${dy})$`
        );
        question.addParagraph(
          "question",
          `a) Calculate the coordinates of $B$.`
        );

        question.addHeading(
          "solution",
          `a) The easiest way to find $B$ is to visualise the rectangle.`
        );
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(ax, ay, 0.1);
        g.addCircle(cx, cy, 0.1);
        g.addCircle(dx, dy, 0.1);
        question.addGraph("solution", g);
        question.addParagraph(
          "solution",
          `As you can see, the top right vertex of the rectangle is missing. This point must have the same $x$-coordinate as $C$, as it's directly above it, and the same $y$-coordinate as $A$, as it's level with it.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ $B$ is located at $(${bx}, ${by})$`
        );

        a1.addInput(bx, 0, "$(a)\\ x=$");
        a1.addInput(by, 0, "$(a)\\ y=$");
      } else if (missingPoint === "C") {
        question.addParagraph(
          "question",
          `Three coordinates of a rectangle $ABCD$ are $A(${ax}, ${ay}), B(${bx}, ${by})$ and $D(${dx}, ${dy})$`
        );
        question.addParagraph(
          "question",
          `a) Calculate the coordinates of $C$.`
        );

        question.addHeading(
          "solution",
          `a) The easiest way to find $C$ is to visualise the rectangle.`
        );
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(bx, by, 0.1);
        g.addCircle(ax, ay, 0.1);
        g.addCircle(dx, dy, 0.1);
        question.addGraph("solution", g);
        question.addParagraph(
          "solution",
          `As you can see, the bottom right vertex of the rectangle is missing. This point must have the same $x$-coordinate as $B$, as it's directly below it, and the same $y$-coordinate as $D$, as it's level with it.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ $C$ is located at $(${cx}, ${cy})$`
        );

        a1.addInput(cx, 0, "$(a)\\ x=$");
        a1.addInput(cy, 0, "$(a)\\ y=$");
      } // D
      else {
        question.addParagraph(
          "question",
          `Three coordinates of a rectangle $ABCD$ are $A(${ax}, ${ay}), B(${bx}, ${by})$ and $C(${cx}, ${cy})$`
        );
        question.addParagraph(
          "question",
          `a) Calculate the coordinates of $D$.`
        );

        question.addHeading(
          "solution",
          `a) The easiest way to find $D$ is to visualise the rectangle.`
        );
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(bx, by, 0.1);
        g.addCircle(cx, cy, 0.1);
        g.addCircle(ax, ay, 0.1);
        question.addGraph("solution", g);
        question.addParagraph(
          "solution",
          `As you can see, the bottom left vertex of the rectangle is missing. This point must have the same $x$-coordinate as $A$, as it's directly below it, and the same $y$-coordinate as $C$, as it's level with it.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ $D$ is located at $(${dx}, ${dy})$`
        );

        a1.addInput(dx, 0, "$(a)\\ x=$");
        a1.addInput(dy, 0, "$(a)\\ y=$");
      }
      question.addParagraph("question", `b) Draw the rectangle on a graph.`);
      question.addParagraph(
        "question",
        `c) Calculate the perimeter of the rectangle.`
      );
      // solution
      // b
      question.addHeading("solution", `b) Drawing the rectangle $ABCD$.`);
      const g2 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g2.plot(`${ay}`, ax, ax + xDiff);
      g2.plot(`${cy}`, ax, ax + xDiff);

      g2.addParametric(`${ax}`, `t`, cy, ay);
      g2.addParametric(`${bx}`, `t`, cy, ay);
      question.addGraph("solution", g2);

      // c
      question.addHeading(
        `solution`,
        `c) Calculate the length, $l$ of the rectangle.`
      ); // where length is the longest side
      if (xDiff > yDiff) {
        question.addParagraph("solution", `$${bx}-(${ax})=${bx - ax}$`);
        question.addHeading(
          "solution",
          `Calculate the width, $w$ of the rectangle.`
        );
        question.addParagraph("solution", `$${ay}-(${cy})=${ay - cy}$`);
        question.addHeading(
          "solution",
          `For a rectangle, perimeter, $p = 2l + 2w$`
        );
        question.addParagraph(
          "solution",
          `$p = 2(${bx - ax}) + 2(${ay - cy})$`
        );
      } else {
        question.addParagraph("solution", `$${ay}-(${cy})=${ay - cy}$`);
        question.addHeading(
          "solution",
          `Calculate the width, $w$ of the rectangle.`
        );
        question.addParagraph("solution", `$${bx}-(${ax})=${bx - ax}$`);
        question.addHeading(
          "solution",
          `For a rectangle, perimeter, $p = 2l + 2w$`
        );
        question.addParagraph(
          "solution",
          `$p = 2(${ay - cy}) + 2(${bx - ax})$`
        );
      }
      question.addParagraph("solution", `$ = ${perimeter}$`);

      question.addParagraph(
        "solution",
        `$\\therefore$ The rectangle has a perimeter of $${perimeter}$ units.`
      );
      // answers

      a1.addInput(perimeter, 0, "$(c)$");

      question.addInputGroup(a1);

      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const ax = MCRandom.randomInt(-9, -1);
    const ay = MCRandom.randomInt(1, 9);

    const xDiff = MCRandom.randomInt(4, 16);
    const yDiff = MCRandom.randomInt(4, 16);

    const offset = MCRandom.randomInt(2, 4) * MCRandom.randomInt(-1, 1, 2);

    const bx = ax + xDiff;
    const by = ay;

    const dx = ax + offset;
    const dy = ay - yDiff;

    const cx = bx + offset;
    const cy = dy;

    const missingPoint = ["A", "B", "C", "D"][MCRandom.randomInt(0, 3)];

    const vertical = ["AD", "BC"][MCRandom.randomInt(0, 1)];

    let mx = 0;
    let my = 0;

    if (vertical === "AD") {
      mx = new MCMaths.Fraction((ax + dx) / 2);
      my = new MCMaths.Fraction((ay + dy) / 2);
    } else {
      mx = new MCMaths.Fraction((bx + cx) / 2);
      my = new MCMaths.Fraction((by + cy) / 2);
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      bx < 10 &&
      cx < 10 &&
      dy > -10 &&
      xDiff !== yDiff &&
      Math.abs(dx) < 10
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const a1 = new MCQuestion.InputGroup(2);

      if (missingPoint === "A") {
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(bx, by, 0.1);
        g.addCircle(dx, dy, 0.1);
        g.addCircle(cx, cy, 0.1);
        g.plot(
          `${(by - cy) / (bx - cx)}*(x+${-bx})+${by}`,
          Math.min(bx, cx),
          Math.max(bx, cx)
        );
        g.plot(`${cy}`, Math.min(cx, dx), Math.max(cx, dx));
        question.addGraph("question", g);

        question.addParagraph(
          "question",
          `The figure above shows the points $B(${bx}, ${by}), C(${cx}, ${cy})$ and $D(${dx}, ${dy})$ of the parallelogram $ABCD$.`
        );
        question.addParagraph("question", `a) State the coordinates of $A$.`);

        question.addHeading(
          "solution",
          `a) Using the figure provided, we can see the top left vertex of the parallelogram is missing.`
        );
        question.addParagraph(
          "solution",
          `As $ABCD$ is a parallelogram, the difference in the $x$-coordinate between $A$ and $B$ must be the same as that for $D$ and $C$.`
        );
        question.addHeading(
          "solution",
          `Let $A$ have an $x$-coordinate represented by $x$.`
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$x-${bx}=${dx}-${cx}$`)
        );
        question.addParagraph("solution", `$\\therefore x=${ax}$`);
        question.addParagraph(
          "solution",
          `$A$ must have the same $y$-coordinate as $B$ because $C$ and $D$ have the same $y$-coordinates.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore A$ is located at $(${ax}, ${ay})$`
        );

        a1.addInput(ax, 0, "$(a) \\ x=$");
        a1.addInput(ay, 0, "$(a) \\ y=$");
      } else if (missingPoint === "B") {
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(ax, ay, 0.1);
        g.addCircle(dx, dy, 0.1);
        g.addCircle(cx, cy, 0.1);

        g.plot(
          `${(ay - dy) / (ax - dx)}*(x+${-ax})+${ay}`,
          Math.min(ax, dx),
          Math.max(ax, dx)
        );
        g.plot(`${cy}`, Math.min(cx, dx), Math.max(cx, dx));
        question.addGraph("question", g);

        question.addParagraph(
          "question",
          `The figure above shows the points $A(${ax}, ${ay}), C(${cx}, ${cy})$ and $D(${dx}, ${dy})$ of the parallelogram $ABCD$.`
        );
        question.addParagraph("question", `a) State the coordinates of $B$.`);

        question.addHeading(
          "solution",
          `a) Using the figure provided, we can see the top right vertex of the parallelogram is missing.`
        );
        question.addParagraph(
          "solution",
          `As $ABCD$ is a parallelogram, the difference in the $x$-coordinate between $A$ and $B$ must be the same as that for $D$ and $C$.`
        );
        question.addHeading(
          "solution",
          `Let $B$ have an $x$-coordinate represented by $x$.`
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$${ax}-x=${dx}-${cx}$`)
        );
        question.addParagraph("solution", `$\\therefore x=${bx}$`);
        question.addParagraph(
          "solution",
          `$B$ must have the same $y$-coordinate as $A$ because $C$ and $D$ have the same $y$-coordinates.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore B$ is located at $(${bx}, ${by})$`
        );

        a1.addInput(bx, 0, "$(a) \\ x=$");
        a1.addInput(by, 0, "$(a) \\ y=$");
      } else if (missingPoint === "C") {
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(bx, by, 0.1);
        g.addCircle(dx, dy, 0.1);
        g.addCircle(ax, ay, 0.1);

        g.plot(
          `${(ay - dy) / (ax - dx)}*(x+${-ax})+${ay}`,
          Math.min(ax, dx),
          Math.max(ax, dx)
        );
        g.plot(`${ay}`, Math.min(ax, bx), Math.max(ax, bx));
        question.addGraph("question", g);

        question.addParagraph(
          "question",
          `The figure above shows the points $A(${ax}, ${ay}), B(${bx}, ${by})$ and $D(${dx}, ${dy})$ of the parallelogram $ABCD$.`
        );
        question.addParagraph("question", `a) State the coordinates of $C$.`);

        question.addHeading(
          "solution",
          `a) Using the figure provided, we can see the bottom right vertex of the parallelogram is missing.`
        );
        question.addParagraph(
          "solution",
          `As $ABCD$ is a parallelogram, the difference in the $x$-coordinate between $A$ and $B$ must be the same as that for $D$ and $C$.`
        );
        question.addHeading(
          "solution",
          `Let $C$ have an $x$-coordinate represented by $x$.`
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$${ax}-${bx}=${dx}-x$`)
        );
        question.addParagraph("solution", `$\\therefore x=${cx}$`);
        question.addParagraph(
          "solution",
          `$C$ must have the same $y$-coordinate as $D$ because $A$ and $B$ have the same $y$-coordinates.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore C$ is located at $(${cx}, ${cy})$`
        );

        a1.addInput(cx, 0, "$(a) \\ x=$");
        a1.addInput(cy, 0, "$(a) \\ y=$");
      } else {
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(bx, by, 0.1);
        g.addCircle(cx, cy, 0.1);
        g.addCircle(ax, ay, 0.1);

        g.plot(
          `${(by - cy) / (bx - cx)}*(x+${-bx})+${by}`,
          Math.min(bx, cx),
          Math.max(bx, cx)
        );
        g.plot(`${ay}`, Math.min(ax, bx), Math.max(ax, bx));
        question.addGraph("question", g);

        question.addParagraph(
          "question",
          `The figure above shows the points $A(${ax}, ${ay}), B(${bx}, ${by})$ and $C(${cx}, ${cy})$ of the parallelogram $ABCD$.`
        );
        question.addParagraph("question", `a) State the coordinates of $D$.`);

        question.addHeading(
          "solution",
          `a) Using the figure provided, we can see the bottom left vertex of the parallelogram is missing.`
        );
        question.addParagraph(
          "solution",
          `As $ABCD$ is a parallelogram, the difference in the $x$-coordinate between $A$ and $B$ must be the same as that for $D$ and $C$.`
        );
        question.addHeading(
          "solution",
          `Let $D$ have an $x$-coordinate represented by $x$.`
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$${ax}-${bx}=x-${cx}$`)
        );
        question.addParagraph("solution", `$\\therefore x=${dx}$`);
        question.addParagraph(
          "solution",
          `$D$ must have the same $y$-coordinate as $C$ because $A$ and $B$ have the same $y$-coordinates.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore D$ is located at $(${dx}, ${dy})$`
        );

        a1.addInput(dx, 0, "$(a) \\ x=$");
        a1.addInput(dy, 0, "$(a) \\ y=$");
      }

      question.addParagraph(
        `question`,
        `$M$ is the midpoint of the line $${vertical}$.`
      );
      question.addParagraph(`question`, `b) Calculate the coordinates of $M$.`);

      if (vertical === "AD") {
        question.addHeading("solution", `b) Find $x$-coordinate of $M$.`);
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\displaystyle\\frac{${ax}+${dx}}{2}=${mx}$`)
        );
        question.addHeading("solution", `Find $y$-coordinate of $M$.`);
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\displaystyle\\frac{${ay}+${dy}}{2}=${my}$`)
        );
        question.addParagraph(
          "solution",
          `$\\therefore M$ is located at $\\displaystyle\\left(${mx}, ${my}\\right)$`
        );
      } else {
        question.addHeading("solution", `b) Find $x$-coordinate of $M$.`);
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\displaystyle\\frac{${bx}+${cx}}{2}=${mx}$`)
        );
        question.addHeading("solution", `Find $y$-coordinate of $M$.`);
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\displaystyle\\frac{${by}+${cy}}{2}=${my}$`)
        );
        question.addParagraph(
          "solution",
          `$\\therefore M$ is located at $\\displaystyle\\left(${mx}, ${my}\\right)$`
        );
      }

      a1.addInput(mx.toFloat(), 0, "$(b) \\ x=$");
      a1.addInput(my.toFloat(), 0, "$(b) \\ y=$");

      question.addInputGroup(a1);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const cx = MCRandom.randomInt(-5, 5);
    const cy = MCRandom.randomInt(-5, 5);

    const r = MCRandom.randomInt(5, 10);

    const dir = ["horizontal", "vertical"][MCRandom.randomInt(0, 1)];

    let find = "";

    let ax = 0;
    let ay = 0;

    let bx = 0;
    let by = 0;

    if (dir === "horizontal") {
      ax = cx - r;
      ay = cy;

      bx = cx + r;
      by = cy;

      find = ["lowermost", "uppermost"][MCRandom.randomInt(0, 1)];
    } else {
      ax = cx;
      ay = cy + r;

      bx = cx;
      by = cy - r;

      find = ["leftmost", "rightmost"][MCRandom.randomInt(0, 1)];
    }

    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      // for testing
      // const g = new MCQuestion.Graph(20, -20, 20, -20, 4, 4);
      // g.addCircle(cx, cy, r);
      // question.addGraph('question', g);

      // question
      question.addParagraph(
        `question`,
        `The points $A(${ax}, ${ay})$ and $B(${bx}, ${by})$ lie on the circumference of a circle $C$.`
      );
      question.addParagraph(
        `question`,
        `The line $AB$ is the diameter of the circle.`
      );
      question.addParagraph("question", `a) Calculate the radius of $C$.`);
      question.addParagraph(
        "question",
        `b) Calculate the coordinates of the center of $C$.`
      );
      question.addParagraph(
        "question",
        `c) Calculate the coordinates of the ${find} point on the circumference of $C$.`
      );

      // solution
      // a

      if (dir === "horizontal") {
        question.addHeading(
          "solution",
          `a) As $A$ and $B$ have the same $y$-coordinates, we can simply use their $x$-coordinates to calculate the distance between them, which is the diameter.`
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$${bx}-${ax}=${r * 2}$`)
        );
        question.addHeading(
          "solution",
          `The diameter of a circle is $2\\times$ its radius.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore C$ has a radius of $${r}$ units.`
        );
      } else {
        question.addHeading(
          "solution",
          `a) As $A$ and $B$ have the same $x$-coordinates, we can simply use their $y$-coordinates to calculate the distance between them, which is the diameter.`
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$${ay}-${by}=${r * 2}$`)
        );
        question.addHeading(
          "solution",
          `The diameter of a circle is $2\\times$ its radius.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore C$ has a radius of $${r}$ units.`
        );
      }
      // b
      const a1 = new MCQuestion.InputGroup(2);

      question.addHeading(
        "solution",
        `b) The center of $C$ is the midpoint of $AB$.`
      );
      if (dir === "horizontal") {
        question.addParagraph(
          "solution",
          `They have the same $y$-coordinates, so no need to calculate a midpoint for the $y$-axis.`
        );
        question.addHeading("solution", `Find $x$-coordinate of midpoint.`);
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\displaystyle\\frac{${ax}+${bx}}{2}=${cx}$`)
        );

        question.addParagraph(
          "solution",
          `$\\therefore$ The midpoint of $AB$, and the center of $C$, is at $\\displaystyle\\left(${cx}, ${cy}\\right)$`
        );
      } else {
        question.addParagraph(
          "solution",
          `They have the same $x$-coordinates, so no need to calculate a midpoint for the $x$-axis.`
        );
        question.addHeading("solution", `Find $y$-coordinate of midpoint.`);
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\displaystyle\\frac{${ay}+${by}}{2}=${cy}$`)
        );

        question.addParagraph(
          "solution",
          `$\\therefore$ The midpoint of $AB$, and the center of $C$, is at $\\displaystyle\\left(${cx}, ${cy}\\right)$`
        );
      }

      a1.addInput(cx, 0, "$(b) \\ x=$");
      a1.addInput(cy, 0, "$(b) \\ y=$");
      // c

      if (find === "uppermost") {
        question.addHeading(
          "solution",
          `c) The uppermost point will be $${r}$ units (the same as the radius) directly up from the center of $C$.`
        );
        question.addParagraph(
          "solution",
          `This may be easier to see with a graph of $C$ where the center is marked.`
        );
        const g = new MCQuestion.Graph(15, -15, 15, -15, 3, 3);
        g.addCircle(cx, cy, r);
        g.addCircle(cx, cy, 0.1);
        question.addGraph("solution", g);

        question.addParagraph(
          "solution",
          `$\\therefore$ The ${find} point on the circumference of $C$ is located at $(${cx}, ${
            cy + r
          })$`
        );

        a1.addInput(cx, 0, "$(c) \\ x=$");
        a1.addInput(cy + r, 0, "$(c) \\ y=$");
      } else if (find === "lowermost") {
        question.addHeading(
          "solution",
          `c) The lowermost point will be $${r}$ units (the same as the radius) directly down from the center of $C$.`
        );
        question.addParagraph(
          "solution",
          `This may be easier to see with a graph of $C$ where the center is marked.`
        );
        const g = new MCQuestion.Graph(15, -15, 15, -15, 3, 3);
        g.addCircle(cx, cy, r);
        g.addCircle(cx, cy, 0.1);
        question.addGraph("solution", g);

        question.addParagraph(
          "solution",
          `$\\therefore$ The ${find} point on the circumference of $C$ is located at $(${cx}, ${
            cy - r
          })$`
        );
        a1.addInput(cx, 0, "$(c) \\ x=$");
        a1.addInput(cy - r, 0, "$(c) \\ y=$");
      } else if (find === "rightmost") {
        question.addHeading(
          "solution",
          `c) The rightmost point will be $${r}$ units (the same as the radius) directly right of the center of $C$.`
        );
        question.addParagraph(
          "solution",
          `This may be easier to see with a graph of $C$ where the center is marked.`
        );
        const g = new MCQuestion.Graph(15, -15, 15, -15, 3, 3);
        g.addCircle(cx, cy, r);
        g.addCircle(cx, cy, 0.1);
        question.addGraph("solution", g);

        question.addParagraph(
          "solution",
          `$\\therefore$ The ${find} point on the circumference of $C$ is located at $(${
            cx + r
          }, ${cy})$`
        );

        a1.addInput(cx + r, 0, "$(c) \\ x=$");
        a1.addInput(cy, 0, "$(c) \\ y=$");
      } else {
        question.addHeading(
          "solution",
          `c) The leftmost point will be $${r}$ units (the same as the radius) directly left of the center of $C$.`
        );
        question.addParagraph(
          "solution",
          `This may be easier to see with a graph of $C$ where the center is marked.`
        );
        const g = new MCQuestion.Graph(15, -15, 15, -15, 3, 3);
        g.addCircle(cx, cy, r);
        g.addCircle(cx, cy, 0.1);
        question.addGraph("solution", g);

        question.addParagraph(
          "solution",
          `$\\therefore$ The ${find} point on the circumference of $C$ is located at $(${
            cx - r
          }, ${cy})$`
        );

        a1.addInput(cx - r, 0, "$(c) \\ x=$");
        a1.addInput(cy, 0, "$(c) \\ y=$");
      }

      const a2 = new MCQuestion.InputGroup(1);
      a2.addInput(r, 0, "$(a)$");

      question.addInputGroup(a2);
      question.addInputGroup(a1);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const ax = MCRandom.randomInt(-9, 9);
    const ay = MCRandom.randomInt(-9, 9);

    const bx = MCRandom.randomInt(-9, 9);
    const by = MCRandom.randomInt(-9, 9);

    const ratio = new MCMaths.Fraction(MCRandom.randomInt(8, 10) / 12);

    const mx = new MCMaths.Fraction((ax + bx) / 2);
    const my = new MCMaths.Fraction((ay + by) / 2);

    let pos = "";

    const px = new MCMaths.Fraction(bx - ax)
      .multiply(ratio)
      .add(new MCMaths.Fraction(ax));
    const py = new MCMaths.Fraction(by - ay)
      .multiply(ratio)
      .add(new MCMaths.Fraction(ay));

    if (ax < bx) {
      pos = "leftmost";
    } else {
      pos = "rightmost";
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(ax - bx) > 2 &&
      Math.abs(ay - by) > 2 &&
      parseFloat(MCMaths.cleaner(`${px}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${py}`).match(/\d+/g)[0]) < 100
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g.addCircle(ax, ay, 0.1);
      g.addCircle(bx, by, 0.1);
      question.addGraph("question", g);

      question.addParagraph(
        `question`,
        `The figure above shows the points $A$ and $B$, with $A$ being the ${pos} point`
      );

      question.addParagraph(
        "question",
        `a) Find the midpoint of the line $AB$.`
      );
      question.addParagraph(
        "question",
        `$P$ is a point such that $AP$ is $\\displaystyle${ratio}$ of the length of $AB$.`
      );
      question.addParagraph(
        "question",
        `b) Calculate the coordinates of point $P$.`
      );

      // solution
      // a
      question.addHeading(
        "solution",
        `a) Read the coordinates from the figure.`
      );
      question.addParagraph("solution", `$A$ is located at $(${ax}, ${ay})$`);
      question.addParagraph("solution", `$B$ is located at $(${bx}, ${by})$`);

      question.addHeading("solution", `Find $x$-coordinate of midpoint.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{${ax}+${bx}}{2}=${mx}$`)
      );
      question.addHeading("solution", `Find $y$-coordinate of midpoint.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{${ay}+${by}}{2}=${my}$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Midpoint of $AB$ is at $\\displaystyle\\left(${mx}, ${my}\\right)$`
      );

      // b
      question.addHeading(
        "solution",
        `b) Find the difference in $x$ between $A$ and $B$.`
      );

      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${bx}-${ax}=${bx - ax}$`)
      );
      question.addHeading(
        `solution`,
        `Use the ratio to find the $x$-coordinate of $P$.`
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${ax}+\\displaystyle${ratio}(${bx - ax})=${px}$`)
      );

      question.addHeading("solution", `Repeat for the $y$-coordinate of $P$.`);

      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${by}-${ay}=${by - ay}$`)
      );
      question.addHeading(
        `solution`,
        `Use the ratio to find the $y$-coordinate of $P$.`
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${ay}+\\displaystyle${ratio}(${by - ay})=${py}$`)
      );

      question.addParagraph(
        "solution",
        `$\\therefore P$ is located at $\\displaystyle\\left(${px}, ${py}\\right)$`
      );

      const a1 = new MCQuestion.InputGroup(2);

      a1.addInput(mx.toFloat(), 0.05, "$(a) \\ x=$");
      a1.addInput(my.toFloat(), 0.05, "$(a)\\ y=$");

      a1.addInput(px.toFloat(), 0.05, "$(b)\\ x=$");
      a1.addInput(py.toFloat(), 0.05, "$(b) \\ y=$");

      question.addInputGroup(a1);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const ax = MCRandom.randomInt(-9, -1);
    const ay = MCRandom.randomInt(1, 9);

    const xDiff = MCRandom.randomInt(4, 16);
    const yDiff = MCRandom.randomInt(4, 16);

    const offset = MCRandom.randomInt(2, 4) * MCRandom.randomInt(-1, 1, 2);

    const bx = ax + xDiff;
    const by = ay;

    const dx = ax + offset;
    const dy = ay - yDiff;

    const cx = bx + offset;
    const cy = dy;

    const missingPoint = ["A", "B", "C", "D"][MCRandom.randomInt(0, 3)];

    const vertical = ["AD", "BC"][MCRandom.randomInt(0, 1)];
    const ratio = new MCMaths.Fraction(MCRandom.randomInt(8, 10) / 12);
    const inverse = new MCMaths.Fraction(1).divide(ratio);

    let px;
    let py;
    if (vertical === "AD") {
      px = new MCMaths.Fraction(dx - ax)
        .divide(ratio)
        .add(new MCMaths.Fraction(ax));
      py = new MCMaths.Fraction(dy - ay)
        .divide(ratio)
        .add(new MCMaths.Fraction(ay));
    } else {
      px = new MCMaths.Fraction(cx - bx)
        .divide(ratio)
        .add(new MCMaths.Fraction(bx));
      py = new MCMaths.Fraction(cy - by)
        .divide(ratio)
        .add(new MCMaths.Fraction(by));
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      bx < 10 &&
      cx < 10 &&
      dy > -10 &&
      xDiff !== yDiff &&
      Math.abs(dx) < 10 &&
      parseFloat(MCMaths.cleaner(`${px}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${py}`).match(/\d+/g)[0]) < 100
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      // testing
      // const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      // g.addCircle(ax, ay, 0.1);
      // g.addCircle(bx, by, 0.1);
      // g.addCircle(dx, dy, 0.2);
      // g.addCircle(cx, cy, 0.1);

      // question.addGraph("question", g);
      // solution
      const a1 = new MCQuestion.InputGroup(2);

      if (missingPoint === "A") {
        question.addParagraph(
          "question",
          `The points $A, B(${bx}, ${by}), C(${cx}, ${cy})$ and $D(${dx}, ${dy})$ form a parallelogram, $ABCD$.`
        );

        question.addParagraph("question", `a) State the coordinates of $A$.`);
        //

        //
        question.addHeading(
          "solution",
          `a) The easiest way to find $A$ is to visualise the parallelogram.`
        );
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(bx, by, 0.1);
        g.addCircle(dx, dy, 0.1);
        g.addCircle(cx, cy, 0.1);

        question.addGraph("solution", g);

        question.addParagraph("solution", `The top left vertex is missing.`);
        question.addParagraph(
          "solution",
          `As $ABCD$ is a parallelogram, the difference in the $x$-coordinate between $A$ and $B$ must be the same as that for $D$ and $C$.`
        );
        question.addHeading(
          "solution",
          `Let $A$ have an $x$-coordinate represented by $x$.`
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$x-${bx}=${dx}-${cx}$`)
        );
        question.addParagraph("solution", `$\\therefore x=${ax}$`);
        question.addParagraph(
          "solution",
          `$A$ must have the same $y$-coordinate as $B$ because $C$ and $D$ have the same $y$-coordinates.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore A$ is located at $(${ax}, ${ay})$`
        );

        a1.addInput(ax, 0, "$(a) \\ x=$");
        a1.addInput(ay, 0, "$(a) \\ y=$");
      } else if (missingPoint === "B") {
        question.addParagraph(
          "question",
          `The points $A(${ax}, ${ay}), B, C(${cx}, ${cy})$ and $D(${dx}, ${dy})$ form a parallelogram, $ABCD$.`
        );

        question.addParagraph("question", `a) State the coordinates of $B$.`);

        question.addHeading(
          "solution",
          `a) The easiest way to find $B$ is to visualise the parallelogram.`
        );
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(ax, ay, 0.1);
        g.addCircle(dx, dy, 0.1);
        g.addCircle(cx, cy, 0.1);

        question.addGraph("solution", g);

        question.addParagraph("solution", `The top right vertex is missing.`);
        question.addParagraph(
          "solution",
          `As $ABCD$ is a parallelogram, the difference in the $x$-coordinate between $A$ and $B$ must be the same as that for $D$ and $C$.`
        );
        question.addHeading(
          "solution",
          `Let $B$ have an $x$-coordinate represented by $x$.`
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$${ax}-x=${dx}-${cx}$`)
        );
        question.addParagraph("solution", `$\\therefore x=${bx}$`);
        question.addParagraph(
          "solution",
          `$B$ must have the same $y$-coordinate as $A$ because $C$ and $D$ have the same $y$-coordinates.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore B$ is located at $(${bx}, ${by})$`
        );

        a1.addInput(bx, 0, "$(a) \\ x=$");
        a1.addInput(by, 0, "$(a) \\ y=$");
      } else if (missingPoint === "C") {
        question.addParagraph(
          "question",
          `The points $A(${ax}, ${ay}), B(${bx}, ${by}), C$ and $D(${dx}, ${dy})$ form a parallelogram, $ABCD$.`
        );

        question.addParagraph("question", `a) State the coordinates of $C$.`);

        question.addHeading(
          "solution",
          `a) The easiest way to find $C$ is to visualise the parallelogram.`
        );
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(ax, ay, 0.1);
        g.addCircle(dx, dy, 0.1);
        g.addCircle(bx, by, 0.1);

        question.addGraph("solution", g);

        question.addParagraph(
          "solution",
          `The bottom right vertex is missing.`
        );
        question.addParagraph(
          "solution",
          `As $ABCD$ is a parallelogram, the difference in the $x$-coordinate between $A$ and $B$ must be the same as that for $D$ and $C$.`
        );
        question.addHeading(
          "solution",
          `Let $C$ have an $x$-coordinate represented by $x$.`
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$${ax}-${bx}=${dx}-x$`)
        );
        question.addParagraph("solution", `$\\therefore x=${cx}$`);
        question.addParagraph(
          "solution",
          `$C$ must have the same $y$-coordinate as $D$ because $A$ and $B$ have the same $y$-coordinates.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore C$ is located at $(${cx}, ${cy})$`
        );

        a1.addInput(cx, 0, "$(a) \\ x=$");
        a1.addInput(cy, 0, "$(a) \\ y=$");
      } else {
        question.addParagraph(
          "question",
          `The points $A(${ax}, ${ay}), B(${bx}, ${by}), C(${cx}, ${cy})$ and $D$ form a parallelogram, $ABCD$.`
        );

        question.addParagraph("question", `a) State the coordinates of $D$.`);

        question.addHeading(
          "solution",
          `a) The easiest way to find $D$ is to visualise the parallelogram.`
        );
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(ax, ay, 0.1);
        g.addCircle(cx, cy, 0.1);
        g.addCircle(bx, by, 0.1);

        question.addGraph("solution", g);

        question.addParagraph("solution", `The bottom left vertex is missing.`);
        question.addParagraph(
          "solution",
          `As $ABCD$ is a parallelogram, the difference in the $x$-coordinate between $A$ and $B$ must be the same as that for $D$ and $C$.`
        );
        question.addHeading(
          "solution",
          `Let $D$ have an $x$-coordinate represented by $x$.`
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(`$${ax}-${bx}=x-${cx}$`)
        );
        question.addParagraph("solution", `$\\therefore x=${dx}$`);
        question.addParagraph(
          "solution",
          `$D$ must have the same $y$-coordinate as $C$ because $A$ and $B$ have the same $y$-coordinates.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore D$ is located at $(${dx}, ${dy})$`
        );

        a1.addInput(dx, 0, "$(a) \\ x=$");
        a1.addInput(dy, 0, "$(a) \\ y=$");
      }

      if (vertical === "AD") {
        question.addParagraph(
          "question",
          `The line $AD$ represents $\\displaystyle${ratio}$ the length of $AP$.`
        );
        question.addParagraph(
          "question",
          `b) Find the coordinates of point $P$.`
        );

        question.addHeading(
          "solution",
          `b) Find the difference in $x$ between $A$ and $D$.`
        );

        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$${dx}-${ax}=${dx - ax}$`)
        );
        question.addHeading(
          `solution`,
          `Use the ratio to find the $x$-coordinate of $P$.`
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$${ax}+\\displaystyle${inverse}(${dx - ax})=${px}$`)
        );

        question.addHeading(
          "solution",
          `Repeat for the $y$-coordinate of $P$.`
        );

        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$${dy}-${ay}=${dy - ay}$`)
        );
        question.addHeading(
          `solution`,
          `Use the ratio to find the $y$-coordinate of $P$.`
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$${ay}+\\displaystyle${inverse}(${dy - ay})=${py}$`)
        );

        question.addParagraph(
          "solution",
          `$\\therefore P$ is located at $\\displaystyle\\left(${px}, ${py}\\right)$`
        );
      } else {
        question.addParagraph(
          "question",
          `The line $BC$ represents $\\displaystyle${ratio}$ the length of $BP$.`
        );
        question.addParagraph(
          "question",
          `b) Find the coordinates of point $P$.`
        );

        question.addHeading(
          "solution",
          `b) Find the difference in $x$ between $B$ and $C$.`
        );

        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$${cx}-${bx}=${cx - bx}$`)
        );
        question.addHeading(
          `solution`,
          `Use the ratio to find the $x$-coordinate of $P$.`
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$${bx}+\\displaystyle${inverse}(${cx - bx})=${px}$`)
        );

        question.addHeading(
          "solution",
          `Repeat for the $y$-coordinate of $P$.`
        );

        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$${cy}-${by}=${cy - by}$`)
        );
        question.addHeading(
          `solution`,
          `Use the ratio to find the $y$-coordinate of $P$.`
        );
        question.addParagraph(
          `solution`,
          MCMaths.cleaner(`$${by}+\\displaystyle${inverse}(${cy - by})=${py}$`)
        );

        question.addParagraph(
          "solution",
          `$\\therefore P$ is located at $\\displaystyle\\left(${px}, ${py}\\right)$`
        );
      }

      a1.addInput(px.toFloat(), 0.05, "$(b) \\ x=$");
      a1.addInput(py.toFloat(), 0.05, "$(b) \\ y=$");
      question.addInputGroup(a1);
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p1 = MCMisc.getName();
    const p2 = MCMisc.getName();
    const p3 = MCMisc.getName();

    const x1 = MCRandom.randomInt(1, 20);
    const y1 = MCRandom.randomInt(1, 20);
    const x2 = MCRandom.randomInt(1, 20);
    const y2 = MCRandom.randomInt(1, 20);

    const mx = (x1 + x2) / 2;
    const my = (y1 + y2) / 2;

    const x3 = MCRandom.randomInt(1, 20);
    const y3 = MCRandom.randomInt(1, 20);

    const px = 2 * (x3 - mx) + mx;
    const py = 2 * (y3 - my) + my;

    /* END VARIABLES AND CONSTANTS */
    if (
      p1.name !== p2.name &&
      p1.name !== p3.name &&
      p2.name !== p3.name &&
      Math.abs(x1 - x2) > 2 &&
      Math.abs(y1 - y2) > 2 &&
      Math.abs(mx - x3) > 2 &&
      Math.abs(my - y3) > 2 &&
      px >= 0 &&
      py >= 0 &&
      (x1 + x2) % 2 === 0 &&
      (y1 + y2) % 2 === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const i = new MCQuestion.Image(`Leo/map.svg`, "width", 25);
      question.addImage("question", i);

      question.addParagraph(
        `question`,
        `A map is split into quadrants which are labelled by a unique pair of integers, as you can see above.`
      );

      question.addParagraph(
        `question`,
        `${p1.name} is in quadrant $(${x1}, ${y1})$ and ${p2.name} is in quadrant $(${x2}, ${y2})$.`
      );
      question.addParagraph(
        `question`,
        `a) State which quadrant they should meet in so that they both travel equal distance.`
      );
      question.addParagraph(
        "question",
        `The quadrant $(${x3}, ${y3})$ is halfway between their meeting point and their friend ${p3.name}.`
      );
      question.addParagraph(
        `question`,
        `b) Find the quadrant ${p3.name} is in.`
      );
      // solution
      // a
      question.addHeading(
        "solution",
        `a) Find the midpoint of the two quadrants.`
      );
      question.addParagraph(
        "solution",
        `Let $A,B$ be the quadrants $(${x1}, ${y1})$ and $(${x2}, ${y2})$ respectively.`
      );
      question.addHeading("solution", `Find $x$-coordinate of midpoint.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{${x1}+${x2}}{2}=${mx}$`)
      );
      question.addHeading("solution", `Find $y$-coordinate of midpoint.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{${y1}+${y2}}{2}=${my}$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ They should meet at quadrant $\\displaystyle\\left(${mx}, ${my}\\right)$`
      );

      // b
      question.addHeading(
        "solution",
        `b) Find the difference in $x$ between them and $(${x3}, ${y3})$.`
      );

      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${x3}-${mx}=${x3 - mx}$`)
      );
      question.addHeading(
        `solution`,
        `Use that difference to find the $x$-coordinate of ${p3.name}.`
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${mx}+2(${x3 - mx})=${px}$`)
      );

      question.addHeading(
        "solution",
        `Repeat for the $y$-coordinate of ${p3.name}.`
      );

      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${y3}-${my}=${y3 - my}$`)
      );
      question.addHeading(
        `solution`,
        `Use that difference to find the $y$-coordinate of ${p3.name}.`
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${my}+2(${y3 - my})=${py}$`)
      );

      question.addParagraph(
        "solution",
        `$\\therefore$ ${p3.name} is in quadrant $(${px}, ${py})$`
      );

      // answers
      const a1 = new MCQuestion.InputGroup(2);

      a1.addInput(mx, 0, "$(a)\\ x=$");
      a1.addInput(my, 0, "$(a)\\ y=$");

      a1.addInput(px, 0, "$(b)\\ x=$");
      a1.addInput(py, 0, "$(b)\\ y=$");

      question.addInputGroup(a1);
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p1 = MCMisc.getName();
    const p2 = MCMisc.getName();

    const toNums = {
      A: 1,
      B: 2,
      C: 3,
      D: 4,
      E: 5,
      F: 6,
      G: 7,
      H: 8,
      I: 9,
      J: 10,
    };
    const toLetters = {
      1: "A",
      2: "B",
      3: "C",
      4: "D",
      5: "E",
      6: "F",
      7: "G",
      8: "H",
      9: "I",
      10: "J",
    };

    const x1 = MCRandom.randomInt(1, 10);
    const y1 = MCRandom.randomInt(1, 10);

    const x2 = MCRandom.randomInt(1, 10);
    const y2 = MCRandom.randomInt(1, 10);

    const mx = (x1 + x2) / 2;
    const my = (y1 + y2) / 2;
    /* END VARIABLES AND CONSTANTS */
    if (
      p1.name !== p2.name &&
      Math.abs(x1 - x2) > 2 &&
      Math.abs(y1 - y2) > 2 &&
      (x1 + x2) % 2 === 0 &&
      (y1 + y2) % 2 === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `Battleships is a board game where you have a grid of coordinates labelled $1$ to $10$, left to right, on the $x$-axis and $A$ to $J$, top to bottom, on the $y$-axis. Each player takes turns guessing a coordinate (taking a shot) where they think a ship (or part of a ship) resides until one players entire fleet is destroyed .`
      );
      question.addParagraph(
        "question",
        `${p1.name} misses with ${p1.hisher} shots at $${toLetters[y1]}${x1}$ and $${toLetters[y2]}${x2}$ but ${p2.name} tells ${p1.himher} that if he fires directly in the middle of them he'll land a hit.`
      );

      question.addParagraph(
        `question`,
        `a) State what ${p1.name} should guess on ${p1.hisher} next turn to land a guaranteed hit.`
      );

      question.addParagraph(
        "question",
        `The misses at $${toLetters[y1]}${x1}$ and $${toLetters[y2]}${x2}$ form right-angled triangles with two other guaranteed hits.`
      );

      question.addParagraph(
        `question`,
        `b) State the coordinates of these hits.`
      );
      // solution

      // a

      question.addHeading(
        "solution",
        `a) First convert the Battleship coordinates to Cartesian $(xy)$ coordinates`
      );
      question.addParagraph(
        "solution",
        `$${toLetters[y1]}${x1}=(${x1}, ${y1})$`
      );
      question.addParagraph(
        "solution",
        `$${toLetters[y2]}${x2}=(${x2}, ${y2})$`
      );

      question.addHeading("solution", `Find $x$-coordinate of midpoint.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{${x1}+${x2}}{2}=${mx}$`)
      );
      question.addHeading("solution", `Find $y$-coordinate of midpoint.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{${y1}+${y2}}{2}=${my}$`)
      );
      question.addParagraph(
        "solution",
        `Midpoint of the misses is $\\displaystyle\\left(${mx}, ${my}\\right)$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ ${p1.name} should make the guess $${toLetters[my]}${mx}$`
      );
      // b
      question.addHeading(
        "solution",
        `b) It is easier to find the correct solutions if you visualise the two guesses and use Cartesian $(xy)$ coordinates.`
      );
      question.addParagraph(
        "solution",
        `Let guess $A$ represent $${toLetters[y1]}${x1}$ which is $(${x1}, ${y1})$ in Cartesian coordinates.`
      );
      question.addParagraph(
        "solution",
        `Let guess $B$ represent $${toLetters[y2]}${x2}$ which is $(${x2}, ${y2})$ in Cartesian coordinates.`
      );
      const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g.addCircle(x1, y1, 0.1);
      g.addCircle(x2, y2, 0.1);
      question.addGraph("solution", g);

      question.addParagraph(
        "solution",
        `As you can see, a right-angled triangle will be formed if the new guess is either level with guess $A$ on the $x$-axis and level with $B$ on the $y$-axis, or vice versa.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The guesses should be at $(${x1}, ${y2})$ and $(${x2}, ${y1})$ which represent $${toLetters[y2]}${x1}$ and $${toLetters[y1]}${x2}$ respectively.`
      );
      // answers
      question.requiresSelfMarking();

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function check(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i][0] === arr[j][0] && arr[i][1] === arr[j][1]) {
            return false;
          }
        }
      }
      return true;
    }

    const ax = MCRandom.randomInt(-9, 9);
    const ay = MCRandom.randomInt(-9, 9);

    const bx = MCRandom.randomInt(-9, 9);
    const by = MCRandom.randomInt(-9, 9);

    const mx = new MCMaths.Fraction((ax + bx) / 2);
    const my = new MCMaths.Fraction((ay + by) / 2);

    const fx1 = new MCMaths.Fraction((ax - bx) / 2);
    const fy1 = new MCMaths.Fraction((ay - by) / 2);

    const fx2 = ax + bx;
    const fy2 = ay + by;

    const fx3 = new MCMaths.Fraction((bx - ax) / 2);
    const fy3 = new MCMaths.Fraction((by - ay) / 2);

    const graph = MCRandom.randomInt(0, 1);
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(ax - bx) > 2 &&
      Math.abs(ay - by) > 2 &&
      check([
        [mx, my],
        [fx1, fy1],
        [fx2, fy2],
        [fx3, fy3],
      ]) /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      if (graph === 0) {
        question.addParagraph(
          `The points $A(${ax}, ${ay})$ and $B(${bx}, ${by})$ form the line $AB$.`
        );
        question.addParagraph(`State the midpoint of the line $AB$.`);
      } else {
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(ax, ay, 0.1);
        g.addCircle(bx, by, 0.1);
        question.addGraph(g);

        question.addParagraph(
          `The figure above shows two points, $A$ and $B$.`
        );
        question.addParagraph(`State the midpoint of the line $AB$.`);
      }

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$\\left(\\displaystyle${mx}, ${my}\\right)$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$\\left(\\displaystyle${fx1}, ${fy1}\\right)$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$\\left(\\displaystyle${fx2}, ${fy2}\\right)$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$\\left(\\displaystyle${fx3}, ${fy3}\\right)$`);

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const ax = MCRandom.randomInt(-9, -1);
    const ay = MCRandom.randomInt(1, 9);

    const xDiff = MCRandom.randomInt(4, 16);
    const yDiff = MCRandom.randomInt(4, 16);

    const bx = ax + xDiff;
    const by = ay;

    const dx = ax;
    const dy = ay - yDiff;

    const cx = bx;
    const cy = dy;

    const missingPoint = ["A", "B", "C", "D"][MCRandom.randomInt(0, 3)];

    let mx;
    let my;

    if (missingPoint === "A") {
      mx = ax;
      my = ay;
    } else if (missingPoint === "B") {
      mx = bx;
      my = by;
    } else if (missingPoint === "C") {
      mx = cx;
      my = cy;
    } else {
      mx = dx;
      my = dy;
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      ax + xDiff < 10 &&
      ay - yDiff > -10 &&
      xDiff !== yDiff &&
      cy < 0 &&
      bx > 0 &&
      mx !== 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      if (missingPoint === "A") {
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(bx, by, 0.1);
        g.addCircle(cx, cy, 0.1);
        g.addCircle(dx, dy, 0.1);
        question.addGraph(g);
      } else if (missingPoint === "B") {
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(ax, ay, 0.1);
        g.addCircle(cx, cy, 0.1);
        g.addCircle(dx, dy, 0.1);
        question.addGraph(g);
      } else if (missingPoint === "C") {
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(bx, by, 0.1);
        g.addCircle(ax, ay, 0.1);
        g.addCircle(dx, dy, 0.1);
        question.addGraph(g);
      } else {
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(bx, by, 0.1);
        g.addCircle(cx, cy, 0.1);
        g.addCircle(ax, ay, 0.1);
        question.addGraph(g);
      }
      question.addParagraph(
        `The figure above shows three points on the rectangle $ABCD$.`
      );
      question.addParagraph(`State the coordinates of the missing point.`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$(${mx}, ${my})$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$(${-mx + MCRandom.randomInt(-1, 1, 2)}, ${my})$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$(${mx}, ${my + MCRandom.randomInt(-1, 1, 2)})$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$(${-mx}, ${my})$`);

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function check(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i][0] === arr[j][0] && arr[i][1] === arr[j][1]) {
            return false;
          }
        }
      }
      return true;
    }
    //
    const ax = MCRandom.randomInt(-9, 9);
    const ay = MCRandom.randomInt(-9, 9);

    const cx = MCRandom.randomInt(-9, 9);
    const cy = MCRandom.randomInt(-9, 9);

    const mx = new MCMaths.Fraction((ax + cx) / 2);
    const my = new MCMaths.Fraction((ay + cy) / 2);

    const fx1 = new MCMaths.Fraction((mx.toFloat() + ax) / 2);
    const fy1 = new MCMaths.Fraction((my.toFloat() + ay) / 2);

    const fx2 = new MCMaths.Fraction(ax - (mx.toFloat() - ax));
    const fy2 = new MCMaths.Fraction(ay - (my.toFloat() - ay));

    const fx3 = new MCMaths.Fraction(ax + mx.toFloat());
    const fy3 = new MCMaths.Fraction(ay + my.toFloat());

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(ax - cx) > 2 &&
      Math.abs(ay - cy) > 2 &&
      check([
        [cx, cy],
        [fx1, fy1],
        [fx2, fy2],
        [fx3, fy3],
      ]) /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(
        `$B$ is the midpoint of the line $AC$, where $A$ and $B$ have coordinates $(${ax}, ${ay})$ and $\\left(\\displaystyle${mx}, ${my}\\right)$ respectively.`
      );
      question.addParagraph(`State the coordinates of point $C$.`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$(${cx}, ${cy})$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$\\left(\\displaystyle${fx1}, ${fy1}\\right)$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$\\left(\\displaystyle${fx2}, ${fy2}\\right)$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$\\left(\\displaystyle${fx3}, ${fy3}\\right)$`);

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function check(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i][0] === arr[j][0] && arr[i][1] === arr[j][1]) {
            return false;
          }
        }
      }
      return true;
    }

    const ax = MCRandom.randomInt(-20, 20);
    const ay = MCRandom.randomInt(-20, 20);

    const bx = MCRandom.randomInt(-20, 20);
    const by = MCRandom.randomInt(-20, 20);

    const ratio = new MCMaths.Fraction(MCRandom.randomInt(8, 10) / 12);

    const px = new MCMaths.Fraction(bx - ax)
      .multiply(ratio)
      .add(new MCMaths.Fraction(ax));
    const py = new MCMaths.Fraction(by - ay)
      .multiply(ratio)
      .add(new MCMaths.Fraction(ay));

    const fx1 = new MCMaths.Fraction(ax - bx)
      .multiply(ratio)
      .add(new MCMaths.Fraction(bx));
    const fy1 = new MCMaths.Fraction(ay - by)
      .multiply(ratio)
      .add(new MCMaths.Fraction(by));

    const fx2 = new MCMaths.Fraction(ax - bx)
      .multiply(ratio)
      .add(new MCMaths.Fraction(ax));
    const fy2 = new MCMaths.Fraction(ay - by)
      .multiply(ratio)
      .add(new MCMaths.Fraction(ay));

    const fx3 = new MCMaths.Fraction(bx - ax)
      .multiply(ratio)
      .add(new MCMaths.Fraction(bx));
    const fy3 = new MCMaths.Fraction(by - ay)
      .multiply(ratio)
      .add(new MCMaths.Fraction(by));

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(ax - bx) > 2 &&
      Math.abs(ay - by) > 2 &&
      px.toFloat() % 1 === 0 &&
      py.toFloat() % 1 === 0 &&
      check([
        [px, py],
        [fx1, fy1],
        [fx2, fy2],
        [fx3, fy3],
      ])
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      const g = new MCQuestion.Graph(20, -20, 20, -20, 4, 4);
      g.addCircle(ax, ay, 0.1);
      g.addCircle(bx, by, 0.1);
      question.addGraph(g);

      question.addParagraph(
        `The figure above shows the points $A(${ax}, ${ay})$ and $B(${bx}, ${by})$`
      );

      question.addParagraph(
        `$P$ is a point such that $AP$ is $\\displaystyle${ratio}$ the length of $AB$.`
      );
      question.addParagraph(`Calculate the coordinates of point $P$.`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$\\left(\\displaystyle${px}, ${py}\\right)$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$\\left(\\displaystyle${fx1}, ${fy1}\\right)$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$\\left(\\displaystyle${fx2}, ${fy2}\\right)$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$\\left(\\displaystyle${fx3}, ${fy3}\\right)$`);

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function check(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i][0] === arr[j][0] && arr[i][1] === arr[j][1]) {
            return false;
          }
        }
      }
      return true;
    }

    const ax = MCRandom.randomInt(-20, 20);
    const ay = MCRandom.randomInt(-20, 20);

    const bx = MCRandom.randomInt(-20, 20);
    const by = MCRandom.randomInt(-20, 20);

    const ratio = new MCMaths.Fraction(MCRandom.randomInt(8, 10) / 12);

    const px = new MCMaths.Fraction(bx - ax)
      .divide(ratio)
      .add(new MCMaths.Fraction(ax));
    const py = new MCMaths.Fraction(by - ay)
      .divide(ratio)
      .add(new MCMaths.Fraction(ay));

    const fx1 = new MCMaths.Fraction(bx - ax)
      .divide(ratio)
      .add(new MCMaths.Fraction(bx));
    const fy1 = new MCMaths.Fraction(by - ay)
      .divide(ratio)
      .add(new MCMaths.Fraction(by));

    const fx2 = new MCMaths.Fraction(ax - bx)
      .divide(ratio)
      .add(new MCMaths.Fraction(bx));
    const fy2 = new MCMaths.Fraction(ay - by)
      .divide(ratio)
      .add(new MCMaths.Fraction(by));

    const fx3 = new MCMaths.Fraction(ax - bx)
      .divide(ratio)
      .add(new MCMaths.Fraction(ax));
    const fy3 = new MCMaths.Fraction(ay - by)
      .divide(ratio)
      .add(new MCMaths.Fraction(ay));

    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(ax - bx) > 2 &&
      Math.abs(ay - by) > 2 &&
      px.toFloat() % 1 === 0 &&
      py.toFloat() % 1 === 0 &&
      check([
        [px, py],
        [fx1, fy1],
        [fx2, fy2],
        [fx3, fy3],
      ])
      /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      const g = new MCQuestion.Graph(20, -20, 20, -20, 4, 4);
      g.addCircle(ax, ay, 0.1);
      g.addCircle(bx, by, 0.1);
      question.addGraph(g);

      question.addParagraph(
        `The figure above shows the points $A(${ax}, ${ay})$ and $B(${bx}, ${by})$`
      );

      question.addParagraph(
        `The line $AB$ represents $\\displaystyle${ratio}$ the length of $AP$.`
      );
      question.addParagraph(`Find the coordinates of point $P$.`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$\\left(\\displaystyle${px}, ${py}\\right)$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$\\left(\\displaystyle${fx1}, ${fy1}\\right)$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$\\left(\\displaystyle${fx2}, ${fy2}\\right)$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$\\left(\\displaystyle${fx3}, ${fy3}\\right)$`);

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_MCQ_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    const ax = MCRandom.randomInt(-9, -1);
    const ay = MCRandom.randomInt(1, 9);

    const xDiff = MCRandom.randomInt(4, 16);
    const yDiff = MCRandom.randomInt(4, 16);

    const offset = MCRandom.randomInt(2, 4) * MCRandom.randomInt(-1, 1, 2);

    const bx = ax + xDiff;
    const by = ay;

    const dx = ax + offset;
    const dy = ay - yDiff;

    const cx = bx + offset;
    const cy = dy;

    const missingPoint = ["A", "B", "C", "D"][MCRandom.randomInt(0, 3)];

    let mx;
    let my;

    if (missingPoint === "A") {
      mx = ax;
      my = ay;
    } else if (missingPoint === "B") {
      mx = bx;
      my = by;
    } else if (missingPoint === "C") {
      mx = cx;
      my = cy;
    } else {
      mx = dx;
      my = dy;
    }
    /* END VARIABLES AND CONSTANTS */
    if (
      bx < 10 &&
      cx < 10 &&
      dy > -10 &&
      xDiff !== yDiff &&
      Math.abs(dx) < 10 &&
      mx !== 0
      /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      if (missingPoint === "A") {
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(bx, by, 0.1);
        g.addCircle(cx, cy, 0.1);
        g.addCircle(dx, dy, 0.1);

        g.plot(
          `${(by - cy) / (bx - cx)}*(x+${-bx})+${by}`,
          Math.min(bx, cx),
          Math.max(bx, cx)
        );
        g.plot(`${cy}`, Math.min(cx, dx), Math.max(cx, dx));

        question.addGraph(g);

        question.addParagraph(
          `The figure above shows the points $B(${bx}, ${by}), C(${cx}, ${cy})$ and $D(${dx}, ${dy})$ that make up the parallelogram $ABCD$.`
        );
        question.addParagraph(`State the coordinates of $A$.`);
      } else if (missingPoint === "B") {
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(ax, ay, 0.1);
        g.addCircle(cx, cy, 0.1);
        g.addCircle(dx, dy, 0.1);

        g.plot(
          `${(ay - dy) / (ax - dx)}*(x+${-ax})+${ay}`,
          Math.min(ax, dx),
          Math.max(ax, dx)
        );
        g.plot(`${cy}`, Math.min(cx, dx), Math.max(cx, dx));
        question.addGraph(g);

        question.addParagraph(
          `The figure above shows the points $A(${ax}, ${ay}), C(${cx}, ${cy})$ and $D(${dx}, ${dy})$ that make up the parallelogram $ABCD$.`
        );
        question.addParagraph(`State the coordinates of $B$.`);
      } else if (missingPoint === "C") {
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(bx, by, 0.1);
        g.addCircle(ax, ay, 0.1);
        g.addCircle(dx, dy, 0.1);
        g.plot(
          `${(ay - dy) / (ax - dx)}*(x+${-ax})+${ay}`,
          Math.min(ax, dx),
          Math.max(ax, dx)
        );
        g.plot(`${ay}`, Math.min(ax, bx), Math.max(ax, bx));
        question.addGraph(g);

        question.addParagraph(
          `The figure above shows the points $A(${ax}, ${ay}), B(${bx}, ${by})$ and $D(${dx}, ${dy})$ that make up the parallelogram $ABCD$.`
        );
        question.addParagraph(`State the coordinates of $C$.`);
      } else {
        const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        g.addCircle(bx, by, 0.1);
        g.addCircle(cx, cy, 0.1);
        g.addCircle(ax, ay, 0.1);
        g.plot(
          `${(by - cy) / (bx - cx)}*(x+${-bx})+${by}`,
          Math.min(bx, cx),
          Math.max(bx, cx)
        );
        g.plot(`${ay}`, Math.min(ax, bx), Math.max(ax, bx));
        question.addGraph(g);

        question.addParagraph(
          `The figure above shows the points $A(${ax}, ${ay}), B(${bx}, ${by})$ and $C(${cx}, ${cy})$ that make up the parallelogram $ABCD$.`
        );
        question.addParagraph(`State the coordinates of $D$.`);
      }

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$(${mx}, ${my})$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$(${mx + MCRandom.randomInt(-1, 1, 2)}, ${my})$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$(${mx}, ${-my + MCRandom.randomInt(-1, 1, 2)})$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$(${mx}, ${-my})$`);

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_Skills_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function checkAll(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (Math.abs(arr[i][0] - arr[i][1]) < 3) {
          return false;
        }
      }
      return true;
    }

    // a
    const ax1 = MCRandom.randomInt(-20, 20);
    const ay1 = MCRandom.randomInt(-20, 20);

    const bx1 = MCRandom.randomInt(-20, 20);
    const by1 = ay1;

    const mx1 = new MCMaths.Fraction((ax1 + bx1) / 2);
    const my1 = by1;

    // b
    const ax2 = MCRandom.randomInt(-20, 20);
    const ay2 = MCRandom.randomInt(-20, 20);

    const bx2 = MCRandom.randomInt(-20, 20);
    const by2 = MCRandom.randomInt(-20, 20);

    const mx2 = new MCMaths.Fraction((ax2 + bx2) / 2);
    const my2 = new MCMaths.Fraction((ay2 + by2) / 2);

    // c
    const ax3 = MCRandom.randomInt(-20, 20);
    const ay3 = MCRandom.randomInt(-20, 20);

    const bx3 = ax3;
    const by3 = MCRandom.randomInt(-20, 20);

    const ratio3 = new MCMaths.Fraction(MCRandom.randomInt(8, 10) / 12);

    const px3 = ax3;
    const py3 = new MCMaths.Fraction(by3 - ay3)
      .multiply(ratio3)
      .add(new MCMaths.Fraction(ay3));

    // d
    const ax4 = MCRandom.randomInt(-20, 20);
    const ay4 = MCRandom.randomInt(-20, 20);

    const bx4 = MCRandom.randomInt(-20, 20);
    const by4 = MCRandom.randomInt(-20, 20);

    const ratio4 = new MCMaths.Fraction(MCRandom.randomInt(8, 10) / 12);

    const px4 = new MCMaths.Fraction(bx4 - ax4)
      .multiply(ratio4)
      .add(new MCMaths.Fraction(ax4));

    const py4 = new MCMaths.Fraction(by4 - ay4)
      .multiply(ratio4)
      .add(new MCMaths.Fraction(ay4));

    /* END VARIABLES AND CONSTANTS */
    if (
      checkAll([
        [ax1, bx1],
        [ax2, bx2],
        [ay2, by2],
        [ay3, by3],
        [ax4, bx4],
        [ay4, by4],
      ]) &&
      parseFloat(MCMaths.cleaner(`${py3}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${px4}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${py4}`).match(/\d+/g)[0]) < 100
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.SkillsQuestion(2);

      const p1 = new MCQuestion.SkillsPart();
      p1.addParagraph(
        "question",
        `The points $A$ and $B$ have coordinates $(${ax1}, ${ay1})$ and $(${bx1}, ${by1})$ respectively.`
      );
      p1.addParagraph("question", `Calculate the midpoint of the line $AB$.`);
      p1.addHeading("solution", `Find $x$-coordinate of midpoint.`);
      p1.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{${ax1}+${bx1}}{2}=${mx1}$`)
      );
      p1.addHeading(
        "solution",
        `$A$ and $B$ share a $y$-coordinate so the midpoint will have this same $y$-coordinate.`
      );

      p1.addParagraph(
        "solution",
        `$\\therefore$ Midpoint of $AB$ is at $\\displaystyle\\left(${mx1}, ${my1}\\right)$`
      );

      p1.addAnswer(mx1.toFloat(), 0, "$(a)\\ x=$");
      p1.addAnswer(my1, 0, "$(a)\\ y=$");

      const p2 = new MCQuestion.SkillsPart();
      p2.addParagraph(
        "question",
        `Given the points $C(${ax2}, ${ay2})$ and $D(${bx2}, ${by2})$, find the midpoint of the line $CD$.`
      );
      p2.addHeading("solution", `Find $x$-coordinate of midpoint.`);
      p2.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{${ax2}+${bx2}}{2}=${mx2}$`)
      );
      p2.addHeading("solution", `Find $y$-coordinate of midpoint.`);
      p2.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{${ay2}+${by2}}{2}=${my2}$`)
      );
      p2.addParagraph(
        "solution",
        `$\\therefore$ Midpoint of $CD$ is at $\\displaystyle\\left(${mx2}, ${my2}\\right)$`
      );

      p2.addAnswer(mx2.toFloat(), 0, "$(b)\\ x=$");
      p2.addAnswer(my2.toFloat(), 0, "$(b)\\ y=$");
      // 3
      const p3 = new MCQuestion.SkillsPart();
      p3.addParagraph(
        "question",
        `The points $E(${ax3}, ${ay3})$ and $F(${bx3}, ${by3})$ form the line $EF$.`
      );
      p3.addParagraph(
        "question",
        `Find the coordinates of $P$, given that $EP$ is $\\displaystyle${ratio3}$ the length of $EF$.`
      );

      p3.addHeading(
        "solution",
        `$E$ and $F$ share an $x$-coordinate so $P$ will also have this same $x$-coordinate. `
      );

      p3.addHeading(
        "solution",
        `Find the difference in $y$ between $E$ and $F$.`
      );

      p3.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${by3}-${ay3}=${by3 - ay3}$`)
      );
      p3.addHeading(
        `solution`,
        `Use the ratio to find the $y$-coordinate of $P$.`
      );
      p3.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${ay3}+\\displaystyle${ratio3}(${by3 - ay3})=${py3}$`)
      );

      p3.addParagraph(
        "solution",
        `$\\therefore P$ is located at $\\displaystyle\\left(${px3}, ${py3}\\right)$`
      );

      p3.addAnswer(px3, 0, "$(c)\\ x=$");
      p3.addAnswer(py3.toFloat(), 0.05, "$(c)\\ y=$");

      const p4 = new MCQuestion.SkillsPart();
      p4.addParagraph(
        "question",
        `Point $K$ lies on a line $GH$ such that $GK$ is $\\displaystyle${ratio4}$ the total length of $GH$.`
      );
      p4.addParagraph(
        "question",
        `Given that $G=(${ax4}, ${ay4})$ and $H = (${bx4}, ${by4})$`
      );
      p4.addParagraph("question", `Calculate the coordinates of $K$.`);

      p4.addHeading(
        "solution",
        `Find the difference in $x$ between $G$ and $H$.`
      );

      p4.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${bx4}-${ax4}=${bx4 - ax4}$`)
      );
      p4.addHeading(
        `solution`,
        `Use the ratio to find the $x$-coordinate of $K$.`
      );
      p4.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${ax4}+\\displaystyle${ratio4}(${bx4 - ax4})=${px4}$`)
      );

      p4.addHeading("solution", `Repeat for the $y$-coordinate of $K$.`);

      p4.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${by4}-${ay4}=${by4 - ay4}$`)
      );
      p4.addHeading(
        `solution`,
        `Use the ratio to find the $y$-coordinate of $K$.`
      );
      p4.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${ay4}+\\displaystyle${ratio4}(${by4 - ay4})=${py4}$`)
      );

      p4.addParagraph(
        "solution",
        `$\\therefore K$ is located at $\\displaystyle\\left(${px4}, ${py4}\\right)$`
      );

      p4.addAnswer(px4.toFloat(), 0.05, "$(d)\\ x=$");
      p4.addAnswer(py4.toFloat(), 0.05, "$(d)\\ y=$");

      question.addPart(p1);
      question.addPart(p2);
      question.addPart(p3);
      question.addPart(p4);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_Skills_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // a
    const ax1 = MCRandom.randomInt(-9, -1);
    const ay1 = MCRandom.randomInt(1, 9);

    const xDiff1 = MCRandom.randomInt(4, 16);
    const yDiff1 = MCRandom.randomInt(4, 16);

    const bx1 = ax1 + xDiff1;
    const by1 = ay1;

    const dx1 = ax1;
    const dy1 = ay1 - yDiff1;

    const cx1 = bx1;
    const cy1 = dy1;

    const missingPoint1 = ["A", "C"][MCRandom.randomInt(0, 1)];

    // b
    const ax2 = MCRandom.randomInt(-9, -1);
    const ay2 = MCRandom.randomInt(1, 9);

    const xDiff2 = MCRandom.randomInt(4, 16);
    const yDiff2 = MCRandom.randomInt(4, 16);

    const bx2 = ax2 + xDiff2;
    const by2 = ay2;

    const dx2 = ax2;
    const dy2 = ay2 - yDiff2;

    const cx2 = bx2;
    const cy2 = dy2;

    const missingPoint2 = ["B", "D"][MCRandom.randomInt(0, 1)];

    // c
    const ax3 = MCRandom.randomInt(-9, -1);
    const ay3 = MCRandom.randomInt(1, 9);

    const xDiff3 = MCRandom.randomInt(4, 16);
    const yDiff3 = MCRandom.randomInt(4, 16);

    const offset3 = MCRandom.randomInt(2, 4) * MCRandom.randomInt(-1, 1, 2);

    const bx3 = ax3 + xDiff3;
    const by3 = ay3;

    const dx3 = ax3 + offset3;
    const dy3 = ay3 - yDiff3;

    const cx3 = bx3 + offset3;
    const cy3 = dy3;

    const missingPoint3 = ["A", "C"][MCRandom.randomInt(0, 1)];

    // d
    const ax4 = MCRandom.randomInt(-9, -1);
    const ay4 = MCRandom.randomInt(1, 9);

    const xDiff4 = MCRandom.randomInt(4, 16);
    const yDiff4 = MCRandom.randomInt(4, 16);

    const offset4 = MCRandom.randomInt(2, 4) * MCRandom.randomInt(-1, 1, 2);

    const bx4 = ax4 + xDiff4;
    const by4 = ay4;

    const dx4 = ax4 + offset4;
    const dy4 = ay4 - yDiff4;

    const cx4 = bx4 + offset4;
    const cy4 = dy4;

    const missingPoint4 = ["B", "D"][MCRandom.randomInt(0, 1)];

    /* END VARIABLES AND CONSTANTS */
    if (
      ax1 + xDiff1 < 10 &&
      ay1 - yDiff1 > -10 &&
      xDiff1 !== yDiff1 &&
      ax2 + xDiff2 < 10 &&
      ay2 - yDiff2 > -10 &&
      xDiff2 !== yDiff2 &&
      bx3 < 10 &&
      cx3 < 10 &&
      dy3 > -10 &&
      xDiff3 !== yDiff3 &&
      Math.abs(dx3) < 10 &&
      bx4 < 10 &&
      cx4 < 10 &&
      dy4 > -10 &&
      xDiff4 !== yDiff4 &&
      Math.abs(dx4) < 10

      /* conditions to be met - true if no conditions */
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.SkillsQuestion(2);

      const p1 = new MCQuestion.SkillsPart();
      if (missingPoint1 === "A") {
        p1.addParagraph(
          "question",
          `Three points on a rectangle $ABCD$ are $B(${bx1}, ${by1}), C(${cx1}, ${cy1})$ and $D(${dx1}, ${dy1})$`
        );
        p1.addParagraph("question", `Calculate the coordinates of $A$.`);

        p1.addHeading(
          "solution",
          `The easiest way to find $A$ is to visualise the rectangle.`
        );
        // const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        // g.addCircle(bx1, by1, 0.1);
        // g.addCircle(cx1, cy1, 0.1);
        // g.addCircle(dx1, dy1, 0.1);
        // p1.addGraph('solution', g);

        // p1.addParagraph(
        //   "solution",
        //   `As you can see, the top left vertex of the rectangle is missing. This point must have the same $x$-coordinate as $D$, as it's directly above it, and the same $y$-coordinate as $B$, as it's level with it.`
        // );

        p1.addParagraph(
          "solution",
          `The top left vertex of the rectangle is missing. This point must have the same $x$-coordinate as $D$, as it's directly above it, and the same $y$-coordinate as $B$, as it's level with it.`
        );
        p1.addParagraph(
          "solution",
          `$\\therefore$ $A$ is located at $(${ax1}, ${ay1})$`
        );

        p1.addAnswer(ax1, 0, "$(a)\\ x=$");
        p1.addAnswer(ay1, 0, "$(a)\\ y=$");
      } else {
        p1.addParagraph(
          "question",
          `Three coordinates of a rectangle $ABCD$ are $A(${ax1}, ${ay1}), B(${bx1}, ${by1})$ and $D(${dx1}, ${dy1})$`
        );
        p1.addParagraph("question", `Calculate the coordinates of $C$.`);

        p1.addHeading(
          "solution",
          `The easiest way to find $C$ is to visualise the rectangle.`
        );
        // const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        // g.addCircle(bx1, by1, 0.1);
        // g.addCircle(ax1, ay1, 0.1);
        // g.addCircle(dx1, dy1, 0.1);
        // p1.addGraph('solution', g);
        // p1.addParagraph(
        //   "solution",
        //   `As you can see, the bottom right vertex of the rectangle is missing. This point must have the same $x$-coordinate as $B$, as it's directly below it, and the same $y$-coordinate as $D$, as it's level with it.`
        // );

        p1.addParagraph(
          "solution",
          `The bottom right vertex of the rectangle is missing. This point must have the same $x$-coordinate as $B$, as it's directly below it, and the same $y$-coordinate as $D$, as it's level with it.`
        );
        p1.addParagraph(
          "solution",
          `$\\therefore$ $C$ is located at $(${cx1}, ${cy1})$`
        );

        p1.addAnswer(cx1, 0, "$(a)\\ x=$");
        p1.addAnswer(cy1, 0, "$(a)\\ y=$");
      }

      // p2
      const p2 = new MCQuestion.SkillsPart();
      if (missingPoint2 === "B") {
        p2.addParagraph(
          "question",
          `The points $A(${ax2}, ${ay2}), B, C(${cx2}, ${cy2})$ and $D(${dx2}, ${dy2})$ form the rectangle $ABCD$.`
        );
        p2.addParagraph("question", `Calculate the coordinates of $B$.`);

        p2.addHeading(
          "solution",
          `The easiest way to find $B$ is to visualise the rectangle.`
        );
        // const g2 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        // g2.addCircle(ax2, ay2, 0.1);
        // g2.addCircle(cx2, cy2, 0.1);
        // g2.addCircle(dx2, dy2, 0.1);
        // p2.addGraph('solution', g2);

        // p2.addParagraph(
        //   "solution",
        //   `As you can see, the top right vertex of the rectangle is missing. This point must have the same $x$-coordinate as $C$, as it's directly above it, and the same $y$-coordinate as $A$, as it's level with it.`
        // );

        p2.addParagraph(
          "solution",
          `The top right vertex of the rectangle is missing. This point must have the same $x$-coordinate as $C$, as it's directly above it, and the same $y$-coordinate as $A$, as it's level with it.`
        );
        p2.addParagraph(
          "solution",
          `$\\therefore$ $B$ is located at $(${bx2}, ${by2})$`
        );

        p2.addAnswer(bx2, 0, "$(b)\\ x=$");
        p2.addAnswer(by2, 0, "$(b)\\ y=$");
      } else {
        p2.addParagraph(
          "question",
          `The points $A(${ax2}, ${ay2}), B(${bx2}, ${by2}), C(${cx2}, ${cy2})$ and $D$ form the rectangle $ABCD$.`
        );
        p2.addParagraph("question", `Calculate the coordinates of $D$.`);

        p2.addHeading(
          "solution",
          `The easiest way to find $D$ is to visualise the rectangle.`
        );
        // const g2 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        // g2.addCircle(bx2, by2, 0.1);
        // g2.addCircle(ax2, ay2, 0.1);
        // g2.addCircle(cx2, cy2, 0.1);
        // p2.addGraph('solution', g2);
        // p2.addParagraph(
        //   "solution",
        //   `As you can see, the bottom left vertex of the rectangle is missing. This point must have the same $x$-coordinate as $A$, as it's directly below it, and the same $y$-coordinate as $C$, as it's level with it.`
        // );

        p2.addParagraph(
          "solution",
          `The bottom left vertex of the rectangle is missing. This point must have the same $x$-coordinate as $A$, as it's directly below it, and the same $y$-coordinate as $C$, as it's level with it.`
        );
        p2.addParagraph(
          "solution",
          `$\\therefore$ $D$ is located at $(${dx2}, ${dy2})$`
        );

        p2.addAnswer(dx2, 0, "$(b)\\ x=$");
        p2.addAnswer(dy2, 0, "$(b)\\ y=$");
      }

      // p3
      const p3 = new MCQuestion.SkillsPart();
      if (missingPoint3 === "A") {
        p3.addParagraph(
          "question",
          `Three points of the parallelogram $ABCD$ are $B(${bx3}, ${by3}), C(${cx3}, ${cy3})$ and $D(${dx3}, ${dy3})$.`
        );

        p3.addParagraph("question", `State the coordinates of $A$.`);
        //

        //
        p3.addHeading(
          "solution",
          `The easiest way to find $A$ is to visualise the parallelogram.`
        );
        // const g3 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        // g3.addCircle(bx3, by3, 0.1);
        // g3.addCircle(dx3, dy3, 0.1);
        // g3.addCircle(cx3, cy3, 0.1);

        // p3.addGraph("solution", g3);

        p3.addParagraph("solution", `The top left vertex is missing.`);
        p3.addParagraph(
          "solution",
          `As $ABCD$ is a parallelogram, the difference in the $x$-coordinate between $A$ and $B$ must be the same as that for $D$ and $C$.`
        );
        p3.addHeading(
          "solution",
          `Let $A$ have an $x$-coordinate represented by $x$.`
        );
        p3.addParagraph(
          "solution",
          MCMaths.cleaner(`$x-${bx3}=${dx3}-${cx3}$`)
        );
        p3.addParagraph("solution", `$\\therefore x=${ax3}$`);
        p3.addParagraph(
          "solution",
          `$A$ must have the same $y$-coordinate as $B$ because $C$ and $D$ have the same $y$-coordinates.`
        );
        p3.addParagraph(
          "solution",
          `$\\therefore A$ is located at $(${ax3}, ${ay3})$`
        );

        p3.addAnswer(ax3, 0, "$(c) \\ x=$");
        p3.addAnswer(ay3, 0, "$(c) \\ y=$");
      } else {
        p3.addParagraph(
          "question",
          `The points $A(${ax3}, ${ay3}), B(${bx3}, ${by3}), C$ and $D(${dx3}, ${dy3})$ form a parallelogram, $ABCD$.`
        );

        p3.addParagraph("question", `State the coordinates of $C$.`);

        p3.addHeading(
          "solution",
          `The easiest way to find $C$ is to visualise the parallelogram.`
        );
        // const g3 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        // g3.addCircle(ax3, ay3, 0.1);
        // g3.addCircle(dx3, dy3, 0.1);
        // g3.addCircle(bx3, by3, 0.1);

        // p3.addGraph("solution", g3);

        p3.addParagraph("solution", `The bottom right vertex is missing.`);
        p3.addParagraph(
          "solution",
          `As $ABCD$ is a parallelogram, the difference in the $x$-coordinate between $A$ and $B$ must be the same as that for $D$ and $C$.`
        );
        p3.addHeading(
          "solution",
          `Let $C$ have an $x$-coordinate represented by $x$.`
        );
        p3.addParagraph(
          "solution",
          MCMaths.cleaner(`$${ax3}-${bx3}=${dx3}-x$`)
        );
        p3.addParagraph("solution", `$\\therefore x=${cx3}$`);
        p3.addParagraph(
          "solution",
          `$C$ must have the same $y$-coordinate as $D$ because $A$ and $B$ have the same $y$-coordinates.`
        );
        p3.addParagraph(
          "solution",
          `$\\therefore C$ is located at $(${cx3}, ${cy3})$`
        );

        p3.addAnswer(cx3, 0, "$(c) \\ x=$");
        p3.addAnswer(cy3, 0, "$(c) \\ y=$");
      }

      // p4
      const p4 = new MCQuestion.SkillsPart();
      if (missingPoint3 === "B") {
        p4.addParagraph(
          "question",
          `The points $A(${ax4}, ${ay4}), B, C(${cx4}, ${cy4})$ and $D(${dx4}, ${dy4})$ form the parallelogram $ABCD$.`
        );

        p4.addParagraph("question", `State the coordinates of $B$.`);
        //

        //
        p4.addHeading(
          "solution",
          `The easiest way to find $B$ is to visualise the parallelogram.`
        );
        // const g4 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        // g4.addCircle(ax4, ay4, 0.1);
        // g4.addCircle(dx4, dy4, 0.1);
        // g4.addCircle(cx4, cy4, 0.1);

        // p4.addGraph("solution", g4);

        p4.addParagraph("solution", `The top right vertex is missing.`);
        p4.addParagraph(
          "solution",
          `As $ABCD$ is a parallelogram, the difference in the $x$-coordinate between $A$ and $B$ must be the same as that for $D$ and $C$.`
        );
        p4.addHeading(
          "solution",
          `Let $B$ have an $x$-coordinate represented by $x$.`
        );
        p4.addParagraph(
          "solution",
          MCMaths.cleaner(`$${ax4}-x=${dx4}-${cx4}$`)
        );
        p4.addParagraph("solution", `$\\therefore x=${bx4}$`);
        p4.addParagraph(
          "solution",
          `$A$ must have the same $y$-coordinate as $B$ because $C$ and $D$ have the same $y$-coordinates.`
        );
        p4.addParagraph(
          "solution",
          `$\\therefore B$ is located at $(${bx4}, ${by4})$`
        );

        p4.addAnswer(bx4, 0, "$(d) \\ x=$");
        p4.addAnswer(by4, 0, "$(d) \\ y=$");
      } else {
        p4.addParagraph(
          "question",
          `The points $A(${ax4}, ${ay4}), B(${bx4}, ${by4}), C(${cx4}, ${cy4})$ and $D$ form the parallelogram $ABCD$.`
        );

        p4.addParagraph("question", `State the coordinates of $D$.`);
        //

        //
        p4.addHeading(
          "solution",
          `The easiest way to find $D$ is to visualise the parallelogram.`
        );
        // const g4 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
        // g4.addCircle(ax4, ay4, 0.1);
        // g4.addCircle(bx4, by4, 0.1);
        // g4.addCircle(cx4, cy4, 0.1);

        // p4.addGraph("solution", g4);

        p4.addParagraph("solution", `The bottom left vertex is missing.`);
        p4.addParagraph(
          "solution",
          `As $ABCD$ is a parallelogram, the difference in the $x$-coordinate between $A$ and $B$ must be the same as that for $D$ and $C$.`
        );
        p4.addHeading(
          "solution",
          `Let $D$ have an $x$-coordinate represented by $x$.`
        );
        p4.addParagraph(
          "solution",
          MCMaths.cleaner(`$${ax4}-${bx4}=x-${cx4}$`)
        );
        p4.addParagraph("solution", `$\\therefore x=${dx4}$`);
        p4.addParagraph(
          "solution",
          `$D$ must have the same $y$-coordinate as $C$ because $A$ and $B$ have the same $y$-coordinates.`
        );
        p4.addParagraph(
          "solution",
          `$\\therefore D$ is located at $(${dx4}, ${dy4})$`
        );

        p4.addAnswer(dx4, 0, "$(d) \\ x=$");
        p4.addAnswer(dy4, 0, "$(d) \\ y=$");
      }

      question.addPart(p1);
      question.addPart(p2);
      question.addPart(p3);
      question.addPart(p4);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Coordinates_Skills_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function checkAll(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        if (Math.abs(arr[i][0] - arr[i][1]) < 3) {
          return false;
        }
      }
      return true;
    }

    // a
    const ax1 = MCRandom.randomInt(-20, 20);
    const ay1 = MCRandom.randomInt(-20, 20);

    const bx1 = MCRandom.randomInt(-20, 20);
    const by1 = ay1;

    const px1 = bx1 + (bx1 - ax1);
    const py1 = by1;

    // b
    const ax2 = MCRandom.randomInt(-20, 20);
    const ay2 = MCRandom.randomInt(-20, 20);

    const bx2 = MCRandom.randomInt(-20, 20);
    const by2 = MCRandom.randomInt(-20, 20);

    const qx2 = bx2 + (bx2 - ax2);
    const qy2 = by2 + (by2 - ay2);

    // c
    const ax3 = MCRandom.randomInt(-20, 20);
    const ay3 = MCRandom.randomInt(-20, 20);

    const bx3 = ax3;
    const by3 = MCRandom.randomInt(-20, 20);

    const ratio3 = new MCMaths.Fraction(MCRandom.randomInt(8, 10) / 12);
    const inverse3 = new MCMaths.Fraction(1).divide(ratio3);

    const kx3 = ax3;
    const ky3 = new MCMaths.Fraction(by3 - ay3)
      .divide(ratio3)
      .add(new MCMaths.Fraction(ay3));

    // d
    const ax4 = MCRandom.randomInt(-20, 20);
    const ay4 = MCRandom.randomInt(-20, 20);

    const bx4 = MCRandom.randomInt(-20, 20);
    const by4 = MCRandom.randomInt(-20, 20);

    const ratio4 = new MCMaths.Fraction(MCRandom.randomInt(8, 10) / 12);
    const inverse4 = new MCMaths.Fraction(1).divide(ratio4);

    const lx4 = new MCMaths.Fraction(bx4 - ax4)
      .divide(ratio4)
      .add(new MCMaths.Fraction(ax4));

    const ly4 = new MCMaths.Fraction(by4 - ay4)
      .divide(ratio4)
      .add(new MCMaths.Fraction(ay4));
    /* END VARIABLES AND CONSTANTS */
    if (
      checkAll([
        [ax1, bx1],
        [ax2, bx2],
        [ay2, by2],
        [ay3, by3],
        [ax4, bx4],
        [ay4, by4],
      ]) &&
      parseFloat(MCMaths.cleaner(`${ky3}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${lx4}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${ly4}`).match(/\d+/g)[0]) < 100
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.SkillsQuestion(2);

      const p1 = new MCQuestion.SkillsPart();
      p1.addParagraph(
        "question",
        `The point $B(${bx1}, ${by1})$ is the midpoint of the line $AP$, where $A$ has coordinates $(${ax1}, ${ay1})$.`
      );
      p1.addParagraph("question", `Calculate the coordinates of $P$.`);

      p1.addHeading(
        "solution",
        `Find the difference in $x$ between $A$ and $B$.`
      );

      p1.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${bx1}-${ax1}=${bx1 - ax1}$`)
      );
      p1.addHeading(
        `solution`,
        `Add this to the $x$-coordinate of $B$ to find the $x$-coordinate of $P$.`
      );
      p1.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${bx1}+${bx1 - ax1}=${px1}$`)
      );

      p1.addHeading(
        "solution",
        `$A$ and $B$ share the same $y$-coordinate so $P$ will have this same $y$-coordinate.`
      );

      p1.addParagraph(
        "solution",
        `$\\therefore P$ is located at $\\displaystyle\\left(${px1}, ${py1}\\right)$`
      );

      const p2 = new MCQuestion.SkillsPart();
      p2.addParagraph(
        "question",
        `The line $AB$ is exactly half the length of the line $AQ$, where $A$ has coordinates $(${ax2}, ${ay2})$ and $B$ has coordinates $(${bx2}, ${by2})$.`
      );
      p2.addParagraph("question", `Calculate the coordinates of $Q$.`);

      p2.addHeading(
        "solution",
        `Find the difference in $x$ between $A$ and $B$.`
      );

      p2.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${bx2}-${ax2}=${bx2 - ax2}$`)
      );
      p2.addHeading(
        `solution`,
        `Add this to the $x$-coordinate of $B$ to find the $x$-coordinate of $Q$.`
      );
      p2.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${bx2}+${bx2 - ax2}=${qx2}$`)
      );

      p2.addHeading("solution", `Repeat for the $y$-coordinate of $P$.`);

      p2.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${by2}-${ay2}=${by2 - ay2}$`)
      );
      p2.addHeading(
        `solution`,
        `Add this to the $y$-coordinate of $B$ to find the $y$-coordinate of $Q$.`
      );
      p2.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${by2}+${by2 - ay2}=${qy2}$`)
      );

      p2.addParagraph(
        "solution",
        `$\\therefore Q$ is located at $\\displaystyle\\left(${qx2}, ${qy2}\\right)$`
      );

      const p3 = new MCQuestion.SkillsPart();
      p3.addParagraph(
        "question",
        `The point $B(${bx3}, ${by3})$ is $\\displaystyle${ratio3}$ of the way along the line $AK$, where $A$ has coordinates $(${ax3}, ${ay3})$.`
      );
      p3.addParagraph("question", `Calculate the coordinates of $K$.`);

      p3.addHeading(
        "solution",
        `Find the difference in $y$ between $A$ and $B$.`
      );

      p3.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${by3}-${ay3}=${by3 - ay3}$`)
      );
      p3.addHeading(
        `solution`,
        `Use the ratio to find the $y$-coordinate of $L$.`
      );
      p3.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$${ay3}+\\displaystyle${inverse3}(${by3 - ay3})=${ky3}$`
        )
      );

      p1.addHeading(
        "solution",
        `$A$ and $B$ share the same $x$-coordinate so $K$ will have this same $y$-coordinate.`
      );

      p3.addParagraph(
        "solution",
        `$\\therefore L$ is located at $\\displaystyle\\left(${kx3}, ${ky3}\\right)$`
      );

      const p4 = new MCQuestion.SkillsPart();
      p4.addParagraph(
        "question",
        `The line $AB$ is exactly $\\displaystyle${ratio4}$ the length of the line $AL$, where $A$ has coordinates $(${ax4}, ${ay4})$ and $B$ has coordinates $(${bx4}, ${by4})$.`
      );
      p4.addParagraph("question", `Calculate the coordinates of $L$.`);

      p4.addHeading(
        "solution",
        `Find the difference in $x$ between $A$ and $B$.`
      );

      p4.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${bx4}-${ax4}=${bx4 - ax4}$`)
      );
      p4.addHeading(
        `solution`,
        `Use the ratio to find the $x$-coordinate of $L$.`
      );
      p4.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$${ax4}+\\displaystyle${inverse4}(${bx4 - ax4})=${lx4}$`
        )
      );

      p4.addHeading("solution", `Repeat for the $y$-coordinate of $L$.`);

      p4.addParagraph(
        `solution`,
        MCMaths.cleaner(`$${by4}-${ay4}=${by4 - ay4}$`)
      );
      p4.addHeading(
        `solution`,
        `Use the ratio to find the $y$-coordinate of $L$.`
      );
      p4.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$${ay4}+\\displaystyle${inverse4}(${by4 - ay4})=${ly4}$`
        )
      );

      p4.addParagraph(
        "solution",
        `$\\therefore L$ is located at $\\displaystyle\\left(${lx4}, ${ly4}\\right)$`
      );

      p1.addAnswer(px1, 0, `$(a) \\ x=$`);
      p1.addAnswer(py1, 0, `$(a) \\ y=$`);

      p2.addAnswer(qx2, 0, `$(b) \\ x=$`);
      p2.addAnswer(qy2, 0, `$(b) \\ y=$`);

      p3.addAnswer(kx3, 0, `$(c) \\ x=$`);
      p3.addAnswer(ky3.toFloat(), 0.05, `$(c) \\ y=$`);

      p4.addAnswer(lx4.toFloat(), 0.05, `$(d) \\ x=$`);
      p4.addAnswer(ly4.toFloat(), 0.05, `$(d) \\ y=$`);

      question.addPart(p1);
      question.addPart(p2);
      question.addPart(p3);
      question.addPart(p4);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_GradientAndIntercept_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

    const x = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);
    const y = m * x + c;
    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(y) < 13) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        MCMaths.cleaner(`A line has equation $y=${m}x+${c}$`)
      );
      question.addParagraph("question", `a) State the gradient of the line.`);
      question.addParagraph(
        "question",
        `b) State the $y$-intercept of the line.`
      );
      question.addParagraph(
        "question",
        `c) Determine the value of $x$ when $y=${y}$`
      );

      // solution

      // a
      question.addHeading(
        "solution",
        `a) A line of the form $y=mx+c$ has gradient $m$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The gradient of the line is $${m}$`
      );

      // b
      question.addHeading(
        "solution",
        `b) A line of the form $y=mx+c$ has $y$-intercept $c$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The $y$-intercept of the line is $${c}$`
      );

      // c
      question.addHeading("solution", `c) Let $y=${y}$`);
      question.addParagraph("solution", MCMaths.cleaner(`$${y}=${m}x+${c}$`));
      question.addParagraph("solution", MCMaths.cleaner(`$${y - c}=${m}x$`));
      question.addParagraph("solution", MCMaths.cleaner(`$${x}=x$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x=${x}$ when $y=${y}$`)
      );

      // answers
      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(m, 0, `$(a)$`);
      g1.addInput(c, 0, `$(b)$`);
      g1.addInput(x, 0, `$(c)$`);
      question.addInputGroup(g1);
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_GradientAndIntercept_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(2, 8, 2) * MCRandom.randomInt(-1, 1, 2);

    const xIntercept = -c / m;
    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(xIntercept) < 10 && xIntercept % 2 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const g = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
      g.plot(`${m}*x+${c}`, -12, 12);
      question.addGraph("question", g);

      // question
      question.addParagraph(
        "question",
        `A straight line $L$ is shown in the figure above.`
      );
      question.addParagraph(
        "question",
        `a) Determine the $y$-intercept of $L$.`
      );
      question.addParagraph(
        "question",
        `b) Determine the value of $x$ where $L$ crosses the $x$-axis.`
      );
      question.addParagraph("question", `c) Calculate the gradient of $L$.`);
      // solution
      // a
      question.addHeading(
        "solution",
        `a) Read from the figure the $y$-coordinate where $L$ crosses the $y$-axis.`
      );
      question.addParagraph("solution", `The $y$-intercept of $L$ is $${c}$`);
      // b
      question.addHeading(
        "solution",
        `b) Read from the figure the $x$-coordinate where $L$ crosses the $x$-axis.`
      );
      question.addParagraph(
        "solution",
        `$L$ crosses the $x$-axis when $x=${xIntercept}$`
      );

      // c
      question.addHeading(
        "solution",
        `c) Read two points on $L$ from the figure.`
      );
      question.addParagraph(
        "solution",
        `In this case we can use the $x$ and $y$ intercepts located above which are $(${xIntercept}, 0)$ and $(0, ${c})$ respectively.`
      );
      question.addHeading(
        "solution",
        `$m = \\displaystyle\\frac{\\Delta y}{\\Delta x}$`
      );
      question.addHeading(
        "solution",
        MCMaths.cleaner(`$ = \\displaystyle\\frac{${c}-0}{0-${xIntercept}}$`)
      );
      question.addHeading("solution", `$=${m}$`);
      // answers
      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(c, 0, `$(a)$`);
      g1.addInput(xIntercept, 0, `$(b)$`);
      g1.addInput(m, 0, `$(c)$`);
      question.addInputGroup(g1);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_GradientAndIntercept_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

    const x = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);
    const y = m * x + c + MCRandom.randomInt(-1, 1);
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        MCMaths.cleaner(`A straight line $L$ has the equation $y-${m}x=${c}$`)
      );
      question.addParagraph("question", `a) Calculate the gradient of $L$.`);
      question.addParagraph(
        "question",
        `b) State the coordinates where $L$ crosses the $y$-axis.`
      );
      question.addParagraph(
        "question",
        `c) Determine whether $L$ passes through the point $(${x}, ${y})$.`
      );

      // solution
      question.addHeading(
        "solution",
        `a) Rearrange the equation of $L$ to the form $y=mx+c$`
      );
      question.addParagraph("solution", MCMaths.cleaner(`$y-${m}x=${c}$`));
      question.addParagraph("solution", MCMaths.cleaner(`$y=${m}x+${c}$`));
      question.addHeading(
        "solution",
        `A line of the form $y=mx+c$ has gradient $m$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The gradient of the line $L$ is $${m}$`
      );

      question.addHeading(
        "solution",
        `b) A straight line always crosses the $y$ axis at $(0, c)$ where $c$ is the line's $y$-intercept.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore L$ crosses the $y$-axis at $(0,${c})$ because a line of the form $y=mx+c$ has $y$-intercept $c$`
      );

      question.addHeading("solution", `c) Let $x=${x}$`);
      question.addParagraph("solution", MCMaths.cleaner(`$y=${m}(${x})+${c}$`));
      question.addParagraph("solution", `$=${m * x + c}$`);

      if (m * x + c === y) {
        question.addParagraph(
          "solution",
          `$\\therefore L$ passes through the point $(${x}, ${y})$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore L$ does NOT pass through the point $(${x}, ${y})$`
        );
      }

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(m, 0, `$(a)$`);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(0, 0, `$(b)\\ x = $`);
      g2.addInput(c, 0, `$(b)\\ y = $`);
      question.addInputGroup(g2);

      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_GradientAndIntercept_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const n = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);
    const d = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

    const x = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

    const gradient = new MCMaths.Fraction(m / n);
    const yIntercept = new MCMaths.Fraction((c - d) / n);

    const c_ans = gradient.multiply(new MCMaths.Fraction(x)).add(yIntercept);
    /* END VARIABLES AND CONSTANTS */
    if (
      c !== d &&
      m !== n &&
      parseFloat(MCMaths.cleaner(`${gradient}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${yIntercept}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${c_ans}`).match(/\d+/g)[0]) < 100
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A line is defined by the equation $${n}y+${d}=${m}x+${c}$`
        )
      );
      question.addParagraph("question", `By rearranging to the form $y=mx+c$`);
      question.addParagraph(
        "question",
        `a) Find the $y$-intercept of the line.`
      );
      question.addParagraph("question", `b) Find the gradient of the line.`);
      question.addParagraph(
        "question",
        `c) Calculate the value of $y$ when $x=${x}$`
      );

      // solution

      // a
      question.addHeading(
        "solution",
        `a) Rearrange $${n}y+${d}=${m}x+${c}$ to the form $y=mx+c$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${n}y+${d}=${m}x+${c}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${n}y=${m}x+${c - d}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=\\displaystyle${gradient}x+${yIntercept}$`)
      );
      question.addHeading(
        "solution",
        `A line of the form $y=mx+c$ has $y$-intercept $c$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The $y$-intercept of the line is $\\displaystyle${yIntercept}$`
      );
      // b
      question.addHeading(
        "solution",
        `b) A line of the form $y=mx+c$ has gradient $m$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The gradient of the line is $\\displaystyle${gradient}$`
      );

      // c
      question.addHeading("solution", `c) Let $x=${x}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=\\displaystyle${gradient}(${x})+${yIntercept}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=\\displaystyle${c_ans}$`)
      );

      // answers
      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(yIntercept.toFloat(), 0.05, `$(a)$`);
      g1.addInput(gradient.toFloat(), 0.05, `$(b)$`);
      g1.addInput(c_ans.toFloat(), 0.05, `$(c)$`);
      question.addInputGroup(g1);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_GradientAndIntercept_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);
    const k = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

    const gradient = new MCMaths.Fraction(m / k);
    const yIntercept = new MCMaths.Fraction(c / k);
    const xIntercept = yIntercept
      .divide(gradient)
      .divide(new MCMaths.Fraction(-1));
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(MCMaths.cleaner(`${gradient}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${yIntercept}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${xIntercept}`).match(/\d+/g)[0]) < 100
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        MCMaths.cleaner(
          `The line $L$ has equation $\\displaystyle\\frac{${c}+${m}x}{y}=${k}$`
        )
      );

      question.addParagraph(
        "question",
        `a) Find the point where $L$ cuts the $y$-axis.`
      );
      question.addParagraph("question", `b) Find the gradient of $L$.`);
      question.addParagraph("question", `c) Find the $x$-intercept of $L$.`);

      // solution

      // a
      question.addHeading(
        "solution",
        MCMaths.cleaner(
          `a) Rearrange $\\displaystyle\\frac{${c}+${m}x}{y}=${k}$ to the form $y=mx+c$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{${c}+${m}x}{y}=${k}$`)
      );
      question.addParagraph("solution", MCMaths.cleaner(`$${c}+${m}x=${k}y$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle${yIntercept}+${gradient}x=y$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore y = \\displaystyle ${gradient}x+${yIntercept}$`
        )
      );
      question.addHeading(
        "solution",
        `A straight line always crosses the $y$ axis at $(0, c)$ where $c$ is the line's $y$-intercept.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore L$ crosses the $y$-axis at $\\displaystyle\\left(0,${yIntercept}\\right)$ because a line of the form $y=mx+c$ has $y$-intercept $c$`
      );
      // b
      question.addHeading(
        "solution",
        `b) A line of the form $y=mx+c$ has gradient $m$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The gradient of $L$ is $\\displaystyle ${gradient}$`
      );

      // c
      question.addHeading(
        "solution",
        `c) The $x$-intercept occurs when $y=0$, so, let $y=0$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ 0 = \\displaystyle ${gradient}x+${yIntercept}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$ \\displaystyle ${yIntercept.divide(
            new MCMaths.Fraction(-1)
          )} =  ${gradient}x$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ \\displaystyle ${xIntercept} =  x$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ \\therefore   x = \\displaystyle ${xIntercept} $`)
      );

      // answers

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(0, 0.05, `$(a)\\ x =$`);
      g2.addInput(yIntercept.toFloat(), 0.05, `$(a)\\ y =$`);
      question.addInputGroup(g2);

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(gradient.toFloat(), 0.05, `$(b)$`);
      g1.addInput(xIntercept.toFloat(), 0.05, `$(c)$`);
      question.addInputGroup(g1);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_GradientAndIntercept_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const n = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);
    const k = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

    const gradient = new MCMaths.Fraction(m / n);
    const yIntercept = new MCMaths.Fraction((m * c - k) / n);
    const x = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);
    const y = new MCMaths.Fraction(x)
      .multiply(gradient)
      .add(yIntercept)
      .add(MCRandom.randomInt(-1, 1));

    const trueY = new MCMaths.Fraction(x).multiply(gradient).add(yIntercept);
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(MCMaths.cleaner(`${gradient}`).match(/\d+/g)[0]) < 100 &&
      parseFloat(MCMaths.cleaner(`${yIntercept}`).match(/\d+/g)[0]) < 100 &&
      y.toFloat() % 1 === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A line is defined by the equation $ ${n}y+${k}=${m}(x+${c})$`
        )
      );

      question.addParagraph("question", `a) Find the gradient of the line.`);
      question.addParagraph(
        "question",
        `b) Find the $y$-intercept of the line.`
      );
      question.addParagraph(
        "question",
        `c) Determine whether the line passes through the point $(${x}, ${y})$`
      );
      // solution

      // a
      question.addHeading(
        `solution`,
        MCMaths.cleaner(
          `a) Rearrange $\\displaystyle ${n}y+${k}=${m}(x+${c})$ to the form $y=mx+c$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ ${n}y+${k}=${m}(x+${c})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ ${n}y+${k}=${m}x+${m * c}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ ${n}y=${m}x+${m * c - k}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore  y=\\displaystyle ${gradient}x+${yIntercept}$`
        )
      );
      question.addHeading(
        "solution",
        `A line of the form $y=mx+c$ has gradient $m$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The gradient of the line is $\\displaystyle ${gradient}$`
      );

      // b
      question.addHeading(
        "solution",
        `b) A line of the form $y=mx+c$ has $y$-intercept $c$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The $y$-intercept of the line is $\\displaystyle ${yIntercept}$`
      );
      // c
      question.addHeading("solution", `c) Let $x=${x}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=\\displaystyle ${gradient}(${x})+${yIntercept}$`)
      );
      question.addParagraph("solution", `$=\\displaystyle${trueY}$`);
      if (trueY.toFloat() === y.toFloat()) {
        question.addParagraph(
          "solution",
          `$\\therefore$ The line passes through the point $(${x}, ${y})$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore$ The line does NOT pass through the point $(${x}, ${y})$`
        );
      }
      // answers
      question.requiresSelfMarking();

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(gradient.toFloat(), 0.05, `$(a)$`);
      g1.addInput(yIntercept.toFloat(), 0.05, `$(b)$`);
      question.addInputGroup(g1);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Graphs_Lines_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const ans = 1;

    const a = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const p = new MCMaths.Polynomial([a, b]);

    const mul = MCRandom.randomInt(1, 2);
    const add = MCRandom.randomInt(-5, 5);
    const xs = [
      0 * mul + add,
      1 * mul + add,
      2 * mul + add,
      3 * mul + add,
      4 * mul + add,
    ];
    const ys = [
      p.evaluate(xs[0]),
      p.evaluate(xs[1]),
      p.evaluate(xs[2]),
      p.evaluate(xs[3]),
      p.evaluate(xs[4]),
    ];

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Find the values $y$ in $y=${p.toString()}$ for $x=${xs}$.`
      )
    );
    question.addParagraph(
      "question",
      `$(b)$ Plot the graph of $${p.toString()}$.`
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ x=${xs[0]}, y=${ys[0]} $`)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$ x=${xs[1]}, y=${ys[1]} $`)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$ x=${xs[2]}, y=${ys[2]} $`)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$ x=${xs[3]}, y=${ys[3]} $`)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$ x=${xs[4]}, y=${ys[4]} $`)
    );
    question.addParagraph("solution", `$(b)$`);

    const g = new MCQuestion.Graph(
      Math.max(xs[4] + 1, 1),
      Math.min(xs[0] - 1, -1),
      Math.max(...ys, 0) + 1,
      Math.min(0, ...ys) - 1,
      1,
      1
    );
    g.plot(`${a}*x+${b}`, xs[0] - 1, xs[4] + 1);

    question.addGraph("solution", g);

    const group = new MCQuestion.InputGroup(5);
    group.addInput(ys[0], 0.01, `$(a) \\ x=${xs[0]}$`);
    group.addInput(ys[1], 0.01, `$(a) \\ x=${xs[1]}$`);
    group.addInput(ys[2], 0.01, `$(a) \\ x=${xs[2]}$`);
    group.addInput(ys[3], 0.01, `$(a) \\ x=${xs[3]}$`);
    group.addInput(ys[4], 0.01, `$(a) \\ x=${xs[4]}$`);
    question.addInputGroup(group);
    question.requiresSelfMarking();

    if (Math.min(...ys) < -10 || Math.max(...ys) > 10) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const yc = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const xc = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const xm = MCRandom.randomInt(2, 5);

    const ySub = MCRandom.randomInt(-15, 15);

    const b_ans = new MCMaths.Fraction((ySub + (yc - xc)) / xm);

    const c = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);

    const c_ansX = new MCMaths.Fraction((yc - xc) / (xm - c));
    const c_ansY = c_ansX.multiply(new MCMaths.Fraction(c));
    /* END VARIABLES AND CONSTANTS */
    if (c !== xm && xm - c !== 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `a) Rearrange $y +${yc} = ${xm}x +${xc}$ to make $x$ the subject.`
        )
      );
      question.addParagraph(
        "question",
        `b) Calculate the value of $x$ when $y=${ySub}$`
      );
      question.addParagraph(
        "question",
        `c) Solve the equation for $x$ and $y$ when $y=${c}x$`
      );
      // solution
      question.addHeading("solution", `a) Make $x$ the subject.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y +${yc} = ${xm}x +${xc}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y +${yc - xc} = ${xm}x$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{y +${yc - xc}}{${xm}} = x$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x = \\displaystyle\\frac{y +${yc - xc}}{${xm}}$`
        )
      );
      // b
      question.addHeading(
        "solution",
        `b) Sub $y=${ySub}$ into the answer to part a.`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$x = \\displaystyle\\frac{${ySub} +${yc - xc}}{${xm}}$`
        )
      );
      question.addParagraph(
        "solution",
        `$\\therefore x = \\displaystyle${b_ans}$`
      );
      // c
      question.addHeading(
        "solution",
        `c) Sub $y=${c}x$ into the answer to part a.`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x = \\displaystyle\\frac{${c}x +${yc - xc}}{${xm}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${xm}x = ${c}x +${yc - xc}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${xm - c}x = ${yc - xc}$`)
      );
      question.addParagraph(
        "solution",
        `$\\therefore x = \\displaystyle${c_ansX}$`
      );
      question.addHeading(
        "solution",
        `Sub $x = \\displaystyle${c_ansX}$ into $y=${c}x$`
      );
      question.addHeading("solution", `$y=${c}(\\displaystyle${c_ansX})$`);
      question.addHeading(
        "solution",
        `$\\therefore y=\\displaystyle${c_ansY}$`
      );
      // answers
      question.requiresSelfMarking();

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(b_ans.toFloat(), 0.05, "b)");
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(c_ansX.toFloat(), 0.05, "c) $x =$");
      g2.addInput(c_ansY.toFloat(), 0.05, "c) $y =$");
      question.addInputGroup(g2);
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Exam_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const ha = MCRandom.randomInt(4, 12);
    const ra = MCRandom.randomInt(2, 8);
    const Va = ra * ra * ha;

    const h = MCRandom.randomInt(2, 6);
    /* END VARIABLES AND CONSTANTS */
    if (ha !== ra) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The formula for the volume, $V$cm$^3$, of a cylinder is given by $V=\\pi r^2h$`
      );
      question.addParagraph(
        "question",
        `a) Find, in terms of $\\pi$, the volume of a cylinder with height $${ha}$cm and radius $${ra}$cm.`
      );
      question.addParagraph(
        "question",
        `b) Given that the radius of a cylinder is $${h}$ times its height, find a formula for its height in terms of its volume.`
      );
      // a
      question.addHeading("solution", `a) Let $h=${ha}$ and $r=${ra}$`);
      question.addParagraph("solution", `$V=\\pi (${ra}^2)(${ha})$`);
      question.addParagraph("solution", `$=${Va}\\pi$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ The cylinder has a volume of $${Va}\\pi$cm$^3$`
      );
      // b
      question.addHeading(
        "solution",
        `b) Let $r=${h}h$ and make $h$ the subject.`
      );
      question.addParagraph("solution", `$V=\\pi (${h}h)^2h$`);
      question.addParagraph("solution", `$V=${h ** 2}\\pi h^3$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle\\frac{V}{${h ** 2}\\pi}= h^3$`
      );
      question.addParagraph(
        "solution",
        `$^3\\sqrt{\\displaystyle\\frac{V}{${h ** 2}\\pi}}= h$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore h = $ $ ^3\\sqrt{\\displaystyle\\frac{V}{${h ** 2}\\pi}}$`
      );

      question.requiresSelfMarking();

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(Va, 0, "$\\pi$cm$^3$", `after`);
      question.addInputGroup(g1);

      /* RETURN THE QUESTION */

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Exam_Q11() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const mt = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);
    const ct = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const mb = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);
    const cb = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const x = MCRandom.randomInt(-12, 12);
    let y = false;
    if (mb * x + cb !== 0) {
      y = new MCMaths.Fraction((mt * x + ct) / (mb * x + cb));
    }
    /* END VARIABLES AND CONSTANTS */
    if (y && (y.toFloat() * 24) % 1 === 0 && mb * y - mt !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A line is given by the equation $y=\\displaystyle\\frac{${mt}x+${ct}}{${cb}+${mb}x}$`
        )
      );
      question.addParagraph(
        "question",
        `a) Make $x$ the subject of the equation.`
      );
      question.addParagraph(
        "question",
        `b) Find the point on the line where $y=\\displaystyle${y}$`
      );

      //
      question.addHeading("solution", `a) Making $x$ the subject.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y=\\displaystyle\\frac{${mt}x+${ct}}{${cb}+${mb}x}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y(${cb}+${mb}x)=${mt}x+${ct}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${cb}y+${mb}xy=${mt}x+${ct}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${mb}xy-${mt}x=${ct}-${cb}y$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x(${mb}y-${mt})=${ct}-${cb}y$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x=\\displaystyle\\frac{${ct}-${cb}y}{${mb}y-${mt}}$`
        )
      );

      question.addHeading("solution", `b) Let $y=\\displaystyle${y}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$x=\\displaystyle\\frac{${ct}-${cb}(\\displaystyle${y})}{${mb}(\\displaystyle${y})-${mt}}$`
        )
      );
      question.addParagraph("solution", MCMaths.cleaner(`$=${x}$`));
      question.addParagraph(
        "solution",
        `$\\therefore$ $y = \\displaystyle${y}$ at the point $(${x}, \\displaystyle${y})$`
      );

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(x, 0, "b) $x=$");
      question.addInputGroup(g1);

      question.requiresSelfMarking();

      /* RETURN THE QUESTION */

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Exam_Q12() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const xym = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);
    const xyc = MCRandom.randomInt(1, 16) * MCRandom.randomInt(-1, 1, 2);

    const xm = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);
    const ym = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);

    const x = MCRandom.randomInt(1, 16) * MCRandom.randomInt(-1, 1, 2);
    let y = false;
    if (xym * x - ym !== 0) {
      y = new MCMaths.Fraction((xm * x - xyc) / (xym * x - ym));
    }
    /* END VARIABLES AND CONSTANTS */
    if (y && (y.toFloat() * 24) % 1 === 0 && xym * y.toFloat() - xm !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(`Given the equation $${xym}xy+${xyc}=${xm}x+${ym}y$`)
      );
      question.addParagraph(
        "question",
        `a) By rearraning to make $y$ the subject, find the value of $y$ when $x=${x}$`
      );
      question.addParagraph(
        "question",
        `b) Make $x$ the subject of the equation.`
      );
      //
      question.addHeading("solution", `a) Making $y$ the subject.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${xym}xy+${xyc}=${xm}x+${ym}y$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${xym}xy-${ym}y=${xm}x-${xyc}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y(${xym}x-${ym})=${xm}x-${xyc}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore y=\\displaystyle\\frac{${xm}x-${xyc}}{${xym}x-${ym}}$`
        )
      );
      question.addHeading("solution", `Let $x=${x}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$y=\\displaystyle\\frac{${xm}(${x})-${xyc}}{${xym}(${x})-${ym}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=\\displaystyle${y}$`)
      );
      // b
      question.addHeading("solution", `b) Making $x$ the subject.`);

      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${xym}xy+${xyc}=${xm}x+${ym}y$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${xym}xy-${xm}x=${ym}y-${xyc}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x(${xym}y-${xm})=${ym}y-${xyc}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x=\\displaystyle\\frac{${ym}y-${xyc}}{${xym}y-${xm}}$`
        )
      );

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(y.toFloat(), 0.05, "a)");
      question.addInputGroup(g1);

      question.requiresSelfMarking();

      /* RETURN THE QUESTION */

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Exam_Q13() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const xd = MCRandom.randomInt(1, 4);
    const xn = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);

    const xf = new MCMaths.Fraction(xn / xd);

    const yn = MCRandom.randomInt(1, 4);

    const wd = MCRandom.randomInt(1, 4);
    const wn = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);

    const wf = new MCMaths.Fraction(wn / wd);
    const wInverse = new MCMaths.Fraction(wd / wn);

    const x = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);
    const y = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);

    const mid = xf
      .multiply(new MCMaths.Fraction(1 / x))
      .add(new MCMaths.Fraction(yn / y));
    const mid2 = mid.multiply(wInverse);

    const w = new MCMaths.Fraction(1).divide(mid2);
    // const w = new MCMaths.Fraction()
    /* END VARIABLES AND CONSTANTS */
    if (
      xf.toFloat() % 1 !== 0 &&
      wf.toFloat() % 1 !== 0 &&
      (w.toFloat() * 24) % 1 === 0 &&
      wn % wd !== 0 &&
      (wn % 2 !== 0 || wd % 2 !== 0) &&
      xn % xd !== 0 &&
      (xn % 2 !== 0 || xd % 2 !== 0)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Three variables $w,x$ and $y$ are related by the equation $\\displaystyle${`${xf
            .toString()
            .slice(
              0,
              -1
            )}x}`}+\\displaystyle\\frac{${yn}}{y} = \\displaystyle${`${wf
            .toString()
            .slice(0, -1)}w}`}$`
        )
      );
      question.addParagraph(
        "question",
        `a) Find the value of $w$ when $x=${x}$ and $y=${y}$`
      );
      question.addParagraph(
        "question",
        `b) Rearrange the equation to make $y$ the subject.`
      );

      //
      question.addHeading("solution", `a) Let $x=${x}$ and $y=${y}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle${`${xf
            .toString()
            .slice(
              0,
              -1
            )}(${x})}`}+\\displaystyle\\frac{${yn}}{${y}} = \\displaystyle${`${wf
            .toString()
            .slice(0, -1)}w}`}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle${mid} = \\displaystyle${`${wf
            .toString()
            .slice(0, -1)}w}`}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle${mid2} = \\displaystyle\\frac{1}{w}$`)
      );

      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore w=\\displaystyle${w}$`)
      );
      // b
      question.addHeading("solution", `b) Making $y$ the subject.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle${`${xf
            .toString()
            .slice(
              0,
              -1
            )}x}`}+\\displaystyle\\frac{${yn}}{y} = \\displaystyle${`${wf
            .toString()
            .slice(0, -1)}w}`}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${xn}+\\displaystyle\\frac{${yn * xd}x}{y} = \\displaystyle\\frac{${
            wn * xd
          }x}{${wd}w}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${xn}y+${yn * xd}x = \\displaystyle\\frac{${wn * xd}xy}{${wd}w}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${xn * wd}wy+${yn * xd * wd}wx = ${wn * xd}xy$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${xn * wd}wy-${wn * xd}xy=-${yn * xd * wd}wx $`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y(${xn * wd}w-${wn * xd}x)=-${yn * xd * wd}wx $`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore y=\\displaystyle\\frac{-${yn * xd * wd}wx}{${
            xn * wd
          }w-${wn * xd}x} $`
        )
      );
      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(w.toFloat(), 0.05, "a)");
      question.addInputGroup(g1);

      question.requiresSelfMarking();
      /* RETURN THE QUESTION */

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Exam_Q14() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const yc = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const mx = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);
    const mv = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);

    const vc = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const vy = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);
    /* END VARIABLES AND CONSTANTS */
    if (mx + mv * vc !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A formula $y+${yc}=${mx}x+${mv}xv$ relates the variables $v,x$ and $y$.`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`Given that $v=${vy}y+${vc}$`)
      );
      question.addParagraph(
        "question",
        `a) Find an equation for $x$ in terms of $y$`
      );
      question.addParagraph(
        "question",
        `b) Make $y$ the subject of your equation from part a.`
      );
      // a
      question.addHeading("solution", `a) Making $x$ the subject.`);

      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y+${yc}=${mx}x+${mv}xv$ `)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y+${yc}=x(${mx}+${mv}v)$ `)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{y+${yc}}{${mx}+${mv}v}=x$ `)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ x = \\displaystyle\\frac{y+${yc}}{${mx}+${mv}v}$ `)
      );

      question.addHeading("solution", MCMaths.cleaner(`Let $v=${vy}y+${vc}$`));

      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$ x = \\displaystyle\\frac{y+${yc}}{${mx}+${mv}(${vy}y+${vc})}$ `
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$ \\therefore x = \\displaystyle\\frac{y+${yc}}{${mx + mv * vc}+${
            mv * vy
          }y}$ `
        )
      );
      // b

      question.addHeading("solution", `b) Making $y$ the subject.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$ x = \\displaystyle\\frac{y+${yc}}{${mx + mv * vc}+${mv * vy}y}$ `
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ x(${mx + mv * vc}+${mv * vy}y) = y+${yc}$ `)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ ${mx + mv * vc}x+${mv * vy}xy = y+${yc}$ `)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ ${mv * vy}xy-y= ${yc}-${mx + mv * vc}x$ `)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ y(${mv * vy}x-1)= ${yc}-${mx + mv * vc}x$ `)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore y= \\displaystyle\\frac{${yc}-${mx + mv * vc}x}{${
            mv * vy
          }x-1}$ `
        )
      );

      question.requiresSelfMarking();

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Exam_Q15() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m = MCRandom.randomInt(2, 5);
    const r = MCRandom.randomInt(2, 12);
    const A = r ** 2 * (2 * m + 2);
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `The surface area, $A$cm$^2$ of a cylinder, with a base radius if $r$ cm and height of $h$ cm, is given by the formula $A=2\\pi rh + 2\\pi r^2$`
      );
      question.addParagraph(
        "question",
        `a) Given that the height of the cylinder is $${m}$ times its radius, find $r$ in terms of $A$.`
      );
      question.addParagraph(
        "question",
        `b) Calculate the radius of a cylinder with a surface area of $${A}\\pi$cm$^2$`
      );

      // solution
      // a
      question.addHeading("solution", `a) Let $h=${m}r$`);
      question.addParagraph(`solution`, `$A=2\\pi r(${m}r) + 2\\pi r^2$`);
      question.addParagraph(`solution`, `$A=${2 * m}\\pi r^2 + 2\\pi r^2$`);
      question.addParagraph(`solution`, `$A=${2 * m + 2}\\pi r^2$`);
      question.addParagraph(
        `solution`,
        `$\\displaystyle\\frac{A}{${2 * m + 2}\\pi}=r^2$`
      );
      question.addParagraph(
        `solution`,
        `$\\displaystyle\\sqrt{\\frac{A}{${2 * m + 2}\\pi}}=r$`
      );
      question.addParagraph(
        `solution`,
        `$\\therefore r = \\displaystyle\\sqrt{\\frac{A}{${2 * m + 2}\\pi}}$`
      );
      //
      question.addHeading("solution", `b) Let $A=${A}\\pi$cm$^2$`);
      question.addParagraph(
        `solution`,
        `$ r = \\displaystyle\\sqrt{\\frac{${A}\\pi}{${2 * m + 2}\\pi}}$`
      );
      question.addParagraph(
        `solution`,
        `$  = \\displaystyle\\sqrt{${r ** 2}}$`
      );
      question.addParagraph(`solution`, `$  = ${r}$`);
      question.addParagraph(
        `solution`,
        `$\\therefore$ The cylinder has a base with radius $${r}$ cm`
      );

      question.requiresSelfMarking();
      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(r, 0, "b)");
      question.addInputGroup(g1);
      /* RETURN THE QUESTION */

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const ym = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const xd = MCRandom.randomInt(4, 9);
    const xn = MCRandom.randomInt(2, xd - 2) * MCRandom.randomInt(-1, 1, 2);
    const xc = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const xf = new MCMaths.Fraction(xn / xd);

    const a = MCRandom.randomInt(1, 15) * MCRandom.randomInt(-1, 1, 2);

    const a_ans = new MCMaths.Fraction(((a * xn) / xd + xc) / ym);
    const a_mid = a_ans.multiply(ym);

    const xfInverse = new MCMaths.Fraction(1).divide(xf);

    const b = MCRandom.randomInt(-8, 8);

    const b_ans = new MCMaths.Fraction(
      xfInverse.multiply(ym * b - xc).toFloat()
    );
    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(b_ans.toFloat()) < 10) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Given the formula $${ym}y=\\displaystyle${xf
            .toString()
            .replace("}", "x}")}+${xc}$`
        )
      );
      question.addParagraph(
        "question",
        `a) Calculate the value of $y$ when $x=${a}$`
      );
      question.addParagraph(
        "question",
        `b) By rearranging to make $x$ the subject, find the value of $x$ when $y=${b}$.`
      );
      // solution
      question.addHeading(
        "solution",
        `a) Sub $x=${a}$ into the given formula.`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${ym}y=\\displaystyle${xf
            .toString()
            .replace("}", `(${a})}`)}+${xc}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${ym}y=\\displaystyle${a_mid}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore y=\\displaystyle${a_ans}$`)
      );
      // b
      question.addHeading("solution", `b) Make $x$ the subject.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${ym}y=\\displaystyle${xf.toString().replace("}", "x}")}+${xc}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${ym}y-${xc}=\\displaystyle${xf.toString().replace("}", "x}")}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ \\displaystyle${xfInverse}(${ym}y-${xc})=x$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x = \\displaystyle${xfInverse}(${ym}y-${xc})$`
        )
      );
      question.addHeading("solution", `Let $y=${b}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ x = \\displaystyle${xfInverse}(${ym}(${b})-${xc})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ = \\displaystyle${xfInverse}(${ym * b - xc})$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=\\displaystyle${b_ans}$`)
      );
      // answers
      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(a_ans.toFloat(), 0.05, "a)");
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(b_ans.toFloat(), 0.05, "b) ");
      question.addInputGroup(g2);
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const yc = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const ym = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);

    const xc = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const xd = MCRandom.randomInt(2, 6);

    const y = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const v = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);

    const b_ans = new MCMaths.Fraction((ym * y + (yc - xc * xd)) / (xd * v));
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `a) Make $x$ the subject of the formula $${ym}y+${yc}=\\displaystyle\\frac{vx+${xc}}{${xd}}$`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`b) Find the value of $x$ when $y=${y}$ and $v=${v}$`)
      );

      // solution
      question.addHeading("solution", `a) Making $x$ the subject.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${ym}y+${yc}=\\displaystyle\\frac{vx+${xc}}{${xd}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{${ym}y+${yc}}{${xd}}=vx+${xc}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle\\frac{${ym}y+${yc - xc * xd}}{${xd}}=vx$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle\\frac{${ym}y+${yc - xc * xd}}{${xd}v}=x$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x = \\displaystyle\\frac{${ym}y+${
            yc - xc * xd
          }}{${xd}v}$`
        )
      );
      // b
      question.addHeading("solution", `b) Let $y=${y}$ and $v=${v}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$ x = \\displaystyle\\frac{${ym}(${y})+${
            yc - xc * xd
          }}{${xd}(${v})}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$=\\displaystyle\\frac{${ym * y}+${yc - xc * xd}}{${xd * v}}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=\\displaystyle${b_ans}$`)
      );
      // ans
      question.requiresSelfMarking();

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(b_ans.toFloat(), 0.05, "b)");
      question.addInputGroup(g1);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const yc = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const xm = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);
    const vm = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);

    const y = MCRandom.randomInt(-9, 9);
    const vx = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);

    const c_ans = new MCMaths.Fraction((y + yc) / (xm + vm * vx));
    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(xm + vm * vx) < 10 && Math.abs(xm + vm * vx) !== 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(`Given the formula $y+${yc}=${xm}x+${vm}v$`)
      );
      question.addParagraph(`question`, `a) Make $v$ the subject. `);
      question.addParagraph(`question`, `b) Make $x$ the subject.`);
      question.addParagraph(
        `question`,
        `c) Solve for $x$ when $y=${y}$ and $v=${vx}x$`
      );
      // a
      question.addHeading("solution", `a) Making $v$ the subject.`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$y+${yc}=${xm}x+${vm}v$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$y+${yc}-${xm}x=${vm}v$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\displaystyle\\frac{y+${yc}-${xm}x}{${vm}}=v$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$\\therefore v = \\displaystyle\\frac{y+${yc}-${xm}x}{${vm}}$`
        )
      );
      // b
      question.addHeading("solution", `b) Making $x$ the subject.`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$y+${yc}=${xm}x+${vm}v$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$y+${yc}-${vm}v=${xm}x$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$\\displaystyle\\frac{y+${yc}-${vm}v}{${xm}}=x$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$\\therefore x = \\displaystyle\\frac{y+${yc}-${vm}v}{${xm}}$`
        )
      );
      // c
      question.addHeading(`solution`, `c) Let $y=${y}$ and $v=${vx}x$`);
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(
          `$ x = \\displaystyle\\frac{${y}+${yc}-${vm}(${vx}x)}{${xm}}$`
        )
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$ ${xm}x = ${y + yc}-${vm * vx}x$`)
      );
      question.addParagraph(
        `solution`,
        MCMaths.cleaner(`$ ${xm + vm * vx}x = ${y + yc}$`)
      );
      question.addParagraph(
        `solution`,
        `$ \\therefore x = \\displaystyle${c_ans}$`
      );

      question.requiresSelfMarking();

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(c_ans.toFloat(), 0.05, "c)");
      question.addInputGroup(g1);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const root = MCRandom.randomInt(1, 10);

    const mx = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);
    const cx = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const y = root * root * mx + cx;
    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(y) < 100) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `a) Rearrange the formula $y=${mx}x^2+${cx}$ to make $x$ the subject.`
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(`b) State the possible values of $x$ when $y=${y}$`)
      );
      // a
      question.addHeading("solution", `a) Making $x$ the subject.`);
      question.addParagraph("solution", MCMaths.cleaner(`$y=${mx}x^2+${cx}$`));
      question.addParagraph("solution", MCMaths.cleaner(`$y-${cx}=${mx}x^2$`));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{y-${cx}}{${mx}}=x^2$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\sqrt{\\frac{y-${cx}}{${mx}}}=x$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x =\\displaystyle\\sqrt{\\frac{y-${cx}}{${mx}}}$`
        )
      );
      // b
      question.addHeading("solution", `b) Let $y=${y}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x =\\displaystyle\\sqrt{\\frac{${y}-${cx}}{${mx}}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$ =\\displaystyle\\sqrt{\\frac{${y - cx}}{${mx}}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=\\displaystyle\\sqrt{${root ** 2}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x =\\pm ${Math.sqrt(root ** 2)}$`)
      );

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput([-root, root], 0, "b)");
      question.addInputGroup(g1);

      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const xd = MCRandom.randomInt(4, 9);
    const xn = MCRandom.randomInt(2, xd - 2) * MCRandom.randomInt(-1, 1, 2);
    const xf = new MCMaths.Fraction(xn / xd);
    const xfInverse = new MCMaths.Fraction(1).divide(xf);

    const xc = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const yc = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const a = MCRandom.randomInt(1, 9);

    const a_ans = xf.multiply(a).toFloat() + xc - yc;
    const a_ans2 = xf.multiply(-a).toFloat() + xc - yc;
    /* END VARIABLES AND CONSTANTS */
    if (
      xc !== yc &&
      Math.abs(a_ans) > 0.25 &&
      Math.abs(a_ans) < 10 &&
      Math.abs(a_ans2) > 0.25 &&
      Math.abs(a_ans2) < 10 &&
      a_ans !== a_ans2
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `Given the formula $y+${yc}=\\displaystyle${xf
            .toString()
            .replace("}", "\\sqrt{x}}")}+${xc}$`
        )
      );
      question.addParagraph(
        "question",
        `a) Calculate the possible values of $y$ when $x=${a ** 2}$`
      );
      question.addParagraph(
        "question",
        `b) Rearrange to make $x$ the subject.`
      );
      // a solution
      question.addHeading("solution", `a) Let $x=${a ** 2}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$y+${yc}=\\displaystyle${xf
            .toString()
            .replace("}", `\\sqrt{${a ** 2}}}`)}+${xc}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$y=\\displaystyle${xf
            .toString()
            .replace("}", `\\sqrt{${a ** 2}}}`)}+${xc - yc}$`
        )
      );
      question.addHeading(`solution`, `Case $\\sqrt{${a ** 2}}=${a}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$y=\\displaystyle${xf.toString().replace("}", `(${a})}`)}+${
            xc - yc
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=\\displaystyle${a_ans}$`)
      );

      question.addHeading(`solution`, `Case $\\sqrt{${a ** 2}}=${-a}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$y=\\displaystyle${xf.toString().replace("}", `(${-a})}`)}+${
            xc - yc
          }$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=\\displaystyle${a_ans2}$`)
      );
      // b
      question.addHeading(`solution`, `b) Making $x$ the subject.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$y+${yc}=\\displaystyle${xf
            .toString()
            .replace("}", `\\sqrt{x}}`)}+${xc}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$y+${yc - xc}=\\displaystyle${xf
            .toString()
            .replace("}", `\\sqrt{x}}`)}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle${xfInverse}y+${xfInverse.multiply(
            yc - xc
          )}=\\displaystyle\\sqrt{x}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(\\displaystyle${xfInverse}y+${xfInverse.multiply(yc - xc)})^2=x$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x = (\\displaystyle${xfInverse}y+${xfInverse.multiply(
            yc - xc
          )})^2$`
        )
      );
      //
      question.requiresSelfMarking();

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput([a_ans, a_ans2], 0.05, "a)");
      question.addInputGroup(g1);
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const xc = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const yc = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const ym = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);

    const y = MCRandom.randomInt(1, 12) * MCRandom.randomInt(-1, 1, 2);

    const a_ans = new MCMaths.Fraction(ym - xc + yc / y);

    const bx = MCRandom.randomInt(2, 6);

    const b_ans = new MCMaths.Fraction((ym - xc) / (-yc * bx + 1));
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(a_ans.toFloat()) < 5 &&
      ym !== xc &&
      Math.abs(-yc * bx + 1) < 12
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `a) By rearranging to make $x$ the subject of the formula $y(x+${xc})=${ym}y+${yc}$, find the value of $x$ when $y=${y}$`
        )
      );
      question.addParagraph(
        "question",
        `b) Solve the equation for $x$ when $y=\\displaystyle\\frac{1}{${bx}x}$`
      );

      // solution
      question.addHeading("solution", `a) Making $x$ the subject.`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$y(x+${xc})=${ym}y+${yc}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x+${xc}=${ym}+\\displaystyle\\frac{${yc}}{y}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x=${ym - xc}+\\displaystyle\\frac{${yc}}{y}$`
        )
      );
      question.addHeading("solution", `Let $y=${y}$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x=${ym - xc}+\\displaystyle\\frac{${yc}}{${y}}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=\\displaystyle${a_ans}$`)
      );
      //
      question.addHeading(
        "solution",
        `b) Let $y=\\displaystyle\\frac{1}{${bx}x}$`
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$x=${
            ym - xc
          }+\\displaystyle\\frac{${yc}}{\\left(\\displaystyle\\frac{1}{${bx}x}\\right)}$`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x=${ym - xc}+${yc * bx}x$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${-yc * bx + 1}x=${ym - xc}$`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x = \\displaystyle${b_ans}$`)
      );

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(a_ans.toFloat(), 0.05, "a)");
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(b_ans.toFloat(), 0.05, "b)");
      question.addInputGroup(g2);
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const person = MCMisc.getName();
    const rate = MCRandom.randomInt(650, 1200, 10) / 100;

    const hoursWorked = MCRandom.randomInt(10, 40) * 4;
    const noTips = hoursWorked * rate;
    const tips = parseFloat(
      ((noTips * MCRandom.randomInt(5, 15)) / 100).toFixed(2)
    );

    const wage = noTips + tips;
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `${person.name} earns $p$ pounds based off of the hours ${
          person.heshe
        } works, $h$ at a set hourly rate of $£${rate.toFixed(
          2
        )}$ per hour, and any tips, $t$ pounds, ${person.heshe} receives.`
      );
      question.addParagraph(
        "question",
        `a) State the formula for calculating tips in terms of overall earnings and hours worked.`
      );
      question.addParagraph(
        "question",
        `${person.name} works $${hoursWorked}$ hours in a month where ${
          person.heshe
        } earns $£${wage.toFixed(2)}$`
      );
      question.addParagraph(
        "question",
        `b) Calculate how much ${person.name} must have earned in tips.`
      );
      //
      question.addHeading("solution", `a) Constructing the formula.`);
      question.addParagraph(
        "solution",
        `money earned = hourly rate $\\times$ hours worked $+$ tips`
      );
      question.addParagraph("solution", `$p=${rate.toFixed(2)} \\times h + t$`);
      question.addParagraph("solution", `$p=${rate}h + t$`);
      question.addHeading("solution", `Rearrange to make $t$ the subject.`);
      question.addParagraph("solution", `$p-${rate}h=t$`);
      question.addParagraph("solution", `$\\therefore t = p-${rate}h$`);
      // b
      question.addHeading(
        "solution",
        `b) Let $h=${hoursWorked}$ and $p=${wage.toFixed(2)}$`
      );
      question.addParagraph(
        "solution",
        `$t = ${wage.toFixed(2)}-${rate}(${hoursWorked})$`
      );
      question.addParagraph("solution", `$= ${tips}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ ${person.name} earned $£${tips.toFixed(2)}$ in tips.`
      );

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(tips, 0.05, "b)");
      question.addInputGroup(g1);

      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const r = new MCMaths.Fraction(MCRandom.randomInt(1, 24) / 4);
    const V = r
      .multiply(r)
      .multiply(r)
      .multiply(new MCMaths.Fraction(4 / 3));
    /* END VARIABLES AND CONSTANTS */
    if ((V.toFloat() * 24) % 1 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `The volume of a sphere, $V$cm$^3$ is given by the formula $V=\\displaystyle\\frac{4}{3}\\pi r^3$`
      );
      question.addParagraph(
        "question",
        `a) Rearrange the formula to make $r$ the subject.`
      );
      question.addParagraph(
        "question",
        `b) Calculate the radius of a sphere with a volume of $\\displaystyle${V}\\pi$cm$^3$`
      );
      //
      question.addHeading("solution", `a) Making $r$ the subject.`);
      question.addParagraph(
        "solution",
        `$V=\\displaystyle\\frac{4}{3}\\pi r^3$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle\\frac{3V}{4}=\\pi r^3$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle\\frac{3V}{4\\pi}=r^3$`
      );
      question.addParagraph(
        "solution",
        `$^3\\sqrt{\\displaystyle\\frac{3V}{4\\pi}}=r$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore r =$  $ ^3\\sqrt{\\displaystyle\\frac{3V}{4\\pi}}$`
      );
      //
      question.addHeading("solution", `b) Let $V=\\displaystyle${V}\\pi$`);
      question.addParagraph(
        "solution",
        `$ r =$  $ ^3\\sqrt{\\displaystyle\\frac{3\\left(\\displaystyle${V}\\pi\\right)}{4\\pi}}$`
      );
      question.addParagraph(
        "solution",
        `$  =$  $ ^3\\sqrt{\\displaystyle\\frac{3\\left(\\displaystyle${V}\\pi\\right)}{4\\pi}}$`
      );
      question.addParagraph(
        "solution",
        `$  =$  $ ^3\\sqrt{\\displaystyle${r.multiply(r).multiply(r)}}$`
      );
      question.addParagraph("solution", `$ =\\displaystyle${r}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ The sphere has a radius of $\\displaystyle${r}$cm`
      );

      question.requiresSelfMarking();

      const g1 = new MCQuestion.InputGroup(1);
      g1.addInput(r.toFloat(), 0.05, "b)");
      question.addInputGroup(g1);
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const mx = MCRandom.randomInt(2, 6) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const choices = [
      `$x=\\displaystyle\\frac{y-${c}}{${mx}}$`,
      `$x=\\displaystyle\\frac{y+${c}}{${mx}}$`,
      `$x=\\displaystyle\\frac{y}{${mx}}-${c}$`,
      `$x=\\displaystyle\\frac{y-${c}}{${-mx}}$`,
    ];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(`Given the formula:`);
      question.addParagraph(MCMaths.cleaner(`$y=${mx}x+${c}$`));
      question.addParagraph(`Rearrange to make $x$ the subject.`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(MCMaths.cleaner(choices[0]));

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(MCMaths.cleaner(choices[1]));

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(MCMaths.cleaner(choices[2]));

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(MCMaths.cleaner(choices[3]));

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const xd = MCRandom.randomInt(4, 9);
    const xn = MCRandom.randomInt(2, xd - 2) * MCRandom.randomInt(-1, 1, 2);

    const xf = new MCMaths.Fraction(xn / xd);
    const xfInverse = new MCMaths.Fraction(1).divide(xf);

    const c = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const choices = [
      `$x=\\displaystyle${xfInverse}(y-${c})$`,
      `$x=\\displaystyle${xf}(y-${c})$`,
      `$x=\\displaystyle${xfInverse}y-${c}$`,
      `$x=\\displaystyle${xfInverse}(y+${c})$`,
    ];
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(`Given the formula:`);
      question.addParagraph(
        MCMaths.cleaner(
          `$y=\\displaystyle${xf.toString().replace("}", "x}")}+${c}$`
        )
      );
      question.addParagraph(`Rearrange to make $x$ the subject.`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(MCMaths.cleaner(choices[0]));

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(MCMaths.cleaner(choices[1]));

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(MCMaths.cleaner(choices[2]));

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(MCMaths.cleaner(choices[3]));

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const xd = MCRandom.randomInt(4, 9);
    const xn = MCRandom.randomInt(2, xd - 2) * MCRandom.randomInt(-1, 1, 2);

    const c = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const choices = [
      `$x=\\displaystyle\\frac{${xd}y-${c}}{${xn}}$`,
      `$x=\\displaystyle\\frac{${xn}y-${c}}{${xd}}$`,
      `$x=\\displaystyle\\frac{${xd}y+${c}}{${xn}}$`,
      `$x=\\displaystyle\\frac{${xd}y}{${xn}}-${c}$`,
    ];

    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(`Given the formula:`);
      question.addParagraph(
        MCMaths.cleaner(`$y=\\displaystyle\\frac{${xn}x+${c}}{${xd}}$`)
      );
      question.addParagraph(`Rearrange to make $x$ the subject.`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(MCMaths.cleaner(choices[0]));

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(MCMaths.cleaner(choices[1]));

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(MCMaths.cleaner(choices[2]));

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(MCMaths.cleaner(choices[3]));

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const c = MCRandom.randomInt(1, 9);

    const choices = [
      `$x=\\displaystyle\\sqrt{y-${c ** 2}}$`,
      `$x=\\displaystyle\\sqrt{y}-${c ** 2}$`,
      `$x^2=y-${c ** 2}$`,
      `$x=\\displaystyle\\sqrt{y}-${c}$`,
    ];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(`Given the formula:`);
      question.addParagraph(MCMaths.cleaner(`$y=x^2+${c ** 2}$`));
      question.addParagraph(`Rearrange to make $x$ the subject.`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(MCMaths.cleaner(choices[0]));

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(MCMaths.cleaner(choices[1]));

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(MCMaths.cleaner(choices[2]));

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(MCMaths.cleaner(choices[3]));

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const c = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const choices = [
      `$x=(y-${c})^2$`,
      `$x=y^2-${c}$`,
      `$x=y^2-${c ** 2}$`,
      `$x=(y+${c})^2$`,
    ];
    /* END VARIABLES AND CONSTANTS */
    if (true /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);

      question.addParagraph(`Given the formula:`);
      question.addParagraph(MCMaths.cleaner(`$y=\\sqrt{x}+${c}$`));
      question.addParagraph(`Rearrange to make $x$ the subject.`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(MCMaths.cleaner(choices[0]));

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(MCMaths.cleaner(choices[1]));

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(MCMaths.cleaner(choices[2]));

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(MCMaths.cleaner(choices[3]));

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Skills_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // p1
    const m1 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    const c1 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    // p2
    const mx2 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    const cx2 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const my2 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    const cy2 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    // p3
    const mx3 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    const cx3 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const my3 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    const xy3 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    // p4
    const mx4 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    const w4 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const my4 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    const cy4 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    /* END VARIABLES AND CONSTANTS */
    if (cy2 !== cx2 && xy3 !== mx3 && Math.abs(xy3 - mx3) !== 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.SkillsQuestion(1);
      question.addParagraph(
        `For each of the following equations, rearrange to make $x$ the subject:`
      );

      const p1 = new MCQuestion.SkillsPart();
      p1.addParagraph("question", MCMaths.cleaner(`$y=${m1}x+${c1}$`));
      p1.addHeading(
        "solution",
        MCMaths.cleaner(`Make $x$ the subject of $y=${m1}x+${c1}$`)
      );
      p1.addParagraph("solution", MCMaths.cleaner(`$y-${c1}=${m1}x$`));
      p1.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{y-${c1}}{${m1}}=x$`)
      );
      p1.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x = \\displaystyle\\frac{y-${c1}}{${m1}}$`
        )
      );

      const p2 = new MCQuestion.SkillsPart();
      p2.addParagraph(
        "question",
        MCMaths.cleaner(`$${my2}y+${cy2}=${mx2}x+${cx2}$`)
      );
      p2.addHeading(
        "solution",
        MCMaths.cleaner(
          `Make $x$ the subject of $${my2}y+${cy2}=${mx2}x+${cx2}$`
        )
      );
      p2.addParagraph(
        "solution",
        MCMaths.cleaner(`$${my2}y+${cy2 - cx2}=${mx2}x$`)
      );
      p2.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle\\frac{${my2}y+${cy2 - cx2}}{${mx2}}=x$`
        )
      );
      p2.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x = \\displaystyle\\frac{${my2}y+${cy2 - cx2}}{${mx2}}$`
        )
      );

      const p3 = new MCQuestion.SkillsPart();
      p3.addParagraph(
        "question",
        MCMaths.cleaner(`$${my3}y+${xy3}x=${mx3}x+${cx3}$`)
      );
      p3.addHeading(
        "solution",
        MCMaths.cleaner(
          `Make $x$ the subject of $${my3}y+${xy3}x=${mx3}x+${cx3}$`
        )
      );
      p3.addParagraph(
        "solution",
        MCMaths.cleaner(`$${my3}y+${xy3 - mx3}x=${cx3}$`)
      );
      p3.addParagraph(
        "solution",
        MCMaths.cleaner(`$${xy3 - mx3}x=${cx3}-${my3}y$`)
      );
      p3.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x=\\displaystyle\\frac{${cx3}-${my3}y}{${xy3 - mx3}}$`
        )
      );

      const p4 = new MCQuestion.SkillsPart();
      p4.addParagraph(
        "question",
        MCMaths.cleaner(`$${my4}y+${cy4}=${mx4}x+${w4}w$`)
      );
      p4.addHeading(
        "solution",
        MCMaths.cleaner(
          `Make $x$ the subject of $${my4}y+${cy4}=${mx4}x+${w4}w$`
        )
      );
      p4.addParagraph(
        "solution",
        MCMaths.cleaner(`$${my4}y+${cy4}-${w4}w=${mx4}x$`)
      );
      p4.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle\\frac{${my4}y+${cy4}-${w4}w}{${mx4}}=x$`
        )
      );
      p4.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x = \\displaystyle\\frac{${my4}y+${cy4}-${w4}w}{${mx4}}$`
        )
      );

      p1.requiresSelfMarking();
      p2.requiresSelfMarking();
      p3.requiresSelfMarking();
      p4.requiresSelfMarking();

      question.addPart(p1);
      question.addPart(p2);
      question.addPart(p3);
      question.addPart(p4);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Skills_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // p1
    const xd1 = MCRandom.randomInt(4, 9);
    const xn1 = MCRandom.randomInt(2, xd1 - 2) * MCRandom.randomInt(-1, 1, 2);
    const xf1 = new MCMaths.Fraction(xn1 / xd1);
    const xc1 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const xI1 = new MCMaths.Fraction(1).divide(xf1);
    // p2

    const xd2 = MCRandom.randomInt(4, 9);
    const xm2 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    const xc2 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    // p3
    const xd3 = MCRandom.randomInt(2, 5);
    const xn3 = MCRandom.randomInt(2, 8);
    const xc3 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);

    const ym3 = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const yc3 = MCRandom.randomInt(1, 4) * MCRandom.randomInt(-1, 1, 2);

    // p4

    /* END VARIABLES AND CONSTANTS */
    if (xd3 * yc3 + xc3 !== 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.SkillsQuestion(1);
      question.addParagraph(
        `Make $x$ the subject of each of the following equations:`
      );

      const p1 = new MCQuestion.SkillsPart();
      p1.addParagraph(
        "question",
        MCMaths.cleaner(
          `$y=\\displaystyle${xf1.toString().replace("}", "x}")}+${xc1}$`
        )
      );
      p1.addHeading(
        "solution",
        MCMaths.cleaner(
          `Make $x$ the subject of $y=\\displaystyle${xf1
            .toString()
            .replace("}", "x}")}+${xc1}$`
        )
      );
      p1.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$y-${xc1}=\\displaystyle${xf1.toString().replace("}", "x}")}$`
        )
      );
      p1.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle${xI1}(y-${xc1})=x$`)
      );
      p1.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore x = \\displaystyle${xI1}(y-${xc1})$`)
      );

      const p2 = new MCQuestion.SkillsPart();
      p2.addParagraph(
        "question",
        MCMaths.cleaner(`$y=\\displaystyle\\frac{${xm2}x+${xc2}}{${xd2}}$`)
      );
      p2.addHeading(
        "solution",
        MCMaths.cleaner(
          `Make $x$ the subject of $y=\\displaystyle\\frac{${xm2}x+${xc2}}{${xd2}}$`
        )
      );
      p2.addParagraph("solution", MCMaths.cleaner(`$${xd2}y=${xm2}x+${xc2}$`));
      p2.addParagraph("solution", MCMaths.cleaner(`$${xd2}y-${xc2}=${xm2}x$`));
      p2.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\displaystyle\\frac{${xd2}y-${xc2}}{${xm2}}=x$`)
      );
      p2.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x=\\displaystyle\\frac{${xd2}y-${xc2}}{${xm2}}$`
        )
      );

      const p3 = new MCQuestion.SkillsPart();
      p3.addParagraph(
        "question",
        MCMaths.cleaner(
          `$${ym3}y=\\displaystyle\\frac{${xn3}x+${xc3}}{${xd3}}+${yc3}$`
        )
      );
      p3.addHeading(
        "solution",
        MCMaths.cleaner(
          `Make $x$ the subject of $${ym3}y=\\displaystyle\\frac{${xn3}x+${xc3}}{${xd3}}+${yc3}$`
        )
      );
      p3.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${ym3}y-${yc3}=\\displaystyle\\frac{${xn3}x+${xc3}}{${xd3}}$`
        )
      );
      p3.addParagraph(
        "solution",
        MCMaths.cleaner(`$${xd3 * ym3}y-${xd3 * yc3}=${xn3}x+${xc3}$`)
      );
      p3.addParagraph(
        "solution",
        MCMaths.cleaner(`$${xd3 * ym3}y-${xd3 * yc3 + xc3}=${xn3}x$`)
      );
      p3.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle\\frac{${xd3 * ym3}y-${xd3 * yc3 + xc3}}{${xn3}}=x$`
        )
      );
      p3.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x=\\displaystyle\\frac{${xd3 * ym3}y-${
            xd3 * yc3 + xc3
          }}{${xn3}}$`
        )
      );

      p1.requiresSelfMarking();
      p2.requiresSelfMarking();
      p3.requiresSelfMarking();

      question.addPart(p1);
      question.addPart(p2);
      question.addPart(p3);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Skills_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // p1
    const type1 = MCRandom.randomInt(0, 1);
    const m1 = MCRandom.randomInt(2, 8);
    const c1 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    // p2
    const type2 = MCRandom.randomInt(0, 1);
    const m2 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    const c2 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const n2 = MCRandom.randomInt(2, 3);
    // p3
    const c3 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const n3 = MCRandom.randomInt(2, 3);

    const y3 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    const yc3 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    // p4
    const m4 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    const c4 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const yc4 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    /* END VARIABLES AND CONSTANTS */
    if (m1 !== 4) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.SkillsQuestion(1);
      question.addParagraph(
        `Rearrange the following formulas to make $x$ the subject:`
      );
      const p1 = new MCQuestion.SkillsPart();
      if (type1 === 0) {
        p1.addParagraph(
          "question",
          MCMaths.cleaner(`$y=\\displaystyle\\sqrt{${m1}x}+${c1}$`)
        );
        p1.addHeading(
          "solution",
          MCMaths.cleaner(
            `Make $x$ the subject of $y=\\displaystyle\\sqrt{${m1}x}+${c1}$`
          )
        );
        p1.addParagraph(
          "solution",
          MCMaths.cleaner(`$y-${c1}=\\displaystyle\\sqrt{${m1}x}$`)
        );
        p1.addParagraph("solution", MCMaths.cleaner(`$(y-${c1})^2=${m1}x$`));
        p1.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\displaystyle\\frac{(y-${c1})^2}{${m1}}=x$`)
        );
        p1.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\therefore x = \\displaystyle\\frac{(y-${c1})^2}{${m1}}$`
          )
        );
      } else {
        p1.addParagraph(
          "question",
          MCMaths.cleaner(`$y=\\displaystyle\\sqrt{${m1}x+${c1}}$`)
        );
        p1.addHeading(
          "solution",
          MCMaths.cleaner(
            `Make $x$ the subject of $y=\\displaystyle\\sqrt{${m1}x+${c1}}$`
          )
        );
        p1.addParagraph("solution", MCMaths.cleaner(`$y^2=${m1}x+${c1}$`));
        p1.addParagraph("solution", MCMaths.cleaner(`$y^2-${c1}=${m1}x$`));
        p1.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\displaystyle\\frac{y^2-${c1}}{${m1}}=x$`)
        );
        p1.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\therefore x = \\displaystyle\\frac{y^2-${c1}}{${m1}}$`
          )
        );
      }

      const p2 = new MCQuestion.SkillsPart();
      if (type2 === 0) {
        p2.addParagraph("question", MCMaths.cleaner(`$y=${m2}x^${n2}+${c2}$`));
        p2.addHeading(
          "solution",
          MCMaths.cleaner(`Make $x$ the subject of $y=${m2}x^${n2}+${c2}$`)
        );
        p2.addParagraph("solution", MCMaths.cleaner(`$y-${c2}=${m2}x^${n2}$`));
        p2.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\displaystyle\\frac{y-${c2}}{${m2}}=x^${n2}$`)
        );
        if (n2 === 2) {
          p2.addParagraph(
            "solution",
            MCMaths.cleaner(`$\\displaystyle\\sqrt{\\frac{y-${c2}}{${m2}}}=x$`)
          );
          p2.addParagraph(
            "solution",
            MCMaths.cleaner(
              `$\\therefore x = \\displaystyle\\sqrt{\\frac{y-${c2}}{${m2}}}$`
            )
          );
        } else {
          p2.addParagraph(
            "solution",
            MCMaths.cleaner(
              `$\\displaystyle^3\\sqrt{\\frac{y-${c2}}{${m2}}}=x$`
            )
          );
          p2.addParagraph(
            "solution",
            MCMaths.cleaner(
              `$\\therefore x = \\displaystyle^3\\sqrt{\\frac{y-${c2}}{${m2}}}$`
            )
          );
        }
      } else {
        p2.addParagraph(
          "question",
          MCMaths.cleaner(`$y=(${m2}x+${c2})^${n2}$`)
        );
        p2.addHeading(
          "solution",
          MCMaths.cleaner(`Make $x$ the subject of $y=(${m2}x+${c2})^${n2}$`)
        );
        if (n2 === 2) {
          p2.addParagraph(
            "solution",
            MCMaths.cleaner(`$\\displaystyle\\sqrt{y}=${m2}x+${c2}$`)
          );
          p2.addParagraph(
            "solution",
            MCMaths.cleaner(`$\\displaystyle\\sqrt{y}-${c2}=${m2}x$`)
          );
          p2.addParagraph(
            "solution",
            MCMaths.cleaner(`$\\displaystyle\\frac{\\sqrt{y}-${c2}}{${m2}}=x$`)
          );
          p2.addParagraph(
            "solution",
            MCMaths.cleaner(
              `$\\therefore x = \\displaystyle\\frac{\\sqrt{y}-${c2}}{${m2}}$`
            )
          );
        } else {
          p2.addParagraph(
            "solution",
            MCMaths.cleaner(`$\\displaystyle^3\\sqrt{y}=${m2}x+${c2}$`)
          );
          p2.addParagraph(
            "solution",
            MCMaths.cleaner(`$\\displaystyle^3\\sqrt{y}-${c2}=${m2}x$`)
          );
          p2.addParagraph(
            "solution",
            MCMaths.cleaner(
              `$\\displaystyle\\frac{^3\\sqrt{y}-${c2}}{${m2}}=x$`
            )
          );
          p2.addParagraph(
            "solution",
            MCMaths.cleaner(
              `$\\therefore x = \\displaystyle\\frac{^3\\sqrt{y}-${c2}}{${m2}}$`
            )
          );
        }
      }

      const p3 = new MCQuestion.SkillsPart();
      p3.addParagraph(
        "question",
        MCMaths.cleaner(`$${y3}y+${yc3}=\\displaystyle\\sqrt{x^${n3}+${c3}}$`)
      );
      p3.addHeading(
        "solution",
        MCMaths.cleaner(
          `Make $x$ the subject of $${y3}y+${yc3}=\\displaystyle\\sqrt{x^${n3}+${c3}}$`
        )
      );
      p3.addParagraph(
        "solution",
        MCMaths.cleaner(`$(${y3}y+${yc3})^2=x^${n3}+${c3}$`)
      );
      p3.addParagraph(
        "solution",
        MCMaths.cleaner(`$(${y3}y+${yc3})^2-${c3}=x^${n3}$`)
      );

      if (n3 === 2) {
        p3.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\displaystyle\\sqrt{(${y3}y+${yc3})^2-${c3}}=x$`)
        );
        p3.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\therefore x = \\displaystyle\\sqrt{(${y3}y+${yc3})^2-${c3}}$`
          )
        );
      } else {
        p3.addParagraph(
          "solution",
          MCMaths.cleaner(`$\\displaystyle^3\\sqrt{(${y3}y+${yc3})^2-${c3}}=x$`)
        );
        p3.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$\\therefore x = \\displaystyle^3\\sqrt{(${y3}y+${yc3})^2-${c3}}$`
          )
        );
      }

      const p4 = new MCQuestion.SkillsPart();
      p4.addParagraph(
        "question",
        MCMaths.cleaner(`$y+${yc4}=${m4}\\displaystyle\\sqrt{x+${c4}}$`)
      );
      p4.addHeading(
        "solution",
        MCMaths.cleaner(
          `Make $x$ the subject of $y+${yc4}=${m4}\\displaystyle\\sqrt{x+${c4}}$`
        )
      );
      p4.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\displaystyle\\frac{y+${yc4}}{${m4}}=\\displaystyle\\sqrt{x+${c4}}$`
        )
      );
      p4.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\left(\\displaystyle\\frac{y+${yc4}}{${m4}}\\right)^2=x+${c4}$`
        )
      );
      p4.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\left(\\displaystyle\\frac{y+${yc4}}{${m4}}\\right)^2-${c4}=x$`
        )
      );
      p4.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x = \\left(\\displaystyle\\frac{y+${yc4}}{${m4}}\\right)^2-${c4}$`
        )
      );

      p1.requiresSelfMarking();
      p2.requiresSelfMarking();
      p3.requiresSelfMarking();
      p4.requiresSelfMarking();

      question.addPart(p1);
      question.addPart(p2);
      question.addPart(p3);
      question.addPart(p4);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_ChangingTheSubject_Skills_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // p1
    const nx1 = MCRandom.randomInt(2, 8);
    const nc1 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const dx1 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    const dc1 = MCRandom.randomInt(1, 9);

    // p2
    const ny2 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const nx2 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const c2 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    // p3
    const mx3 = MCRandom.randomInt(2, 8);
    const xc3 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const c3 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);
    const y3 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);

    const yFrac3 = new MCMaths.Fraction(c3 / mx3);
    const Frac3 = new MCMaths.Fraction((y3 - xc3) / mx3);

    // p4
    const yx4 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    const c4 = MCRandom.randomInt(1, 9) * MCRandom.randomInt(-1, 1, 2);

    const x4 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);
    const y4 = MCRandom.randomInt(2, 8) * MCRandom.randomInt(-1, 1, 2);

    /* END VARIABLES AND CONSTANTS */
    if (y3 !== xc3 /* conditions to be met - true if no conditions */) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.SkillsQuestion(1);
      question.addParagraph(`Make $x$ the subject of the following equations:`);
      const p1 = new MCQuestion.SkillsPart();
      p1.addParagraph(
        "question",
        MCMaths.cleaner(
          `$y=\\displaystyle\\frac{${nx1}x+${nc1}}{${dc1}+${dx1}x}$`
        )
      );
      p1.addHeading(
        "solution",
        MCMaths.cleaner(
          `Make $x$ the subject of $y=\\displaystyle\\frac{${nx1}x+${nc1}}{${dc1}+${dx1}x}$`
        )
      );
      p1.addParagraph(
        "solution",
        MCMaths.cleaner(`$y(${dc1}+${dx1}x)=${nx1}x+${nc1}$`)
      );
      p1.addParagraph(
        "solution",
        MCMaths.cleaner(`$${dc1}y+${dx1}xy=${nx1}x+${nc1}$`)
      );
      p1.addParagraph(
        "solution",
        MCMaths.cleaner(`$${dx1}xy-${nx1}x=${nc1}-${dc1}y$`)
      );
      p1.addParagraph(
        "solution",
        MCMaths.cleaner(`$x(${dx1}y-${nx1})=${nc1}-${dc1}y$`)
      );
      p1.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x = \\displaystyle\\frac{${nc1}-${dc1}y}{${dx1}y-${nx1}}$`
        )
      );

      const p2 = new MCQuestion.SkillsPart();
      p2.addParagraph(
        "question",
        MCMaths.cleaner(
          `$\\displaystyle\\frac{${ny2}}{y}=\\frac{${nx2}}{x}+${c2}$`
        )
      );
      p2.addHeading(
        "solution",
        MCMaths.cleaner(
          `Make $x$ the subject of $\\displaystyle\\frac{${ny2}}{y}=\\frac{${nx2}}{x}+${c2}$`
        )
      );
      p2.addParagraph(
        "solution",
        MCMaths.cleaner(`$${ny2}=y(\\displaystyle\\frac{${nx2}}{x}+${c2})$`)
      );
      p2.addParagraph(
        "solution",
        MCMaths.cleaner(`$${ny2}=\\displaystyle\\frac{${nx2}y}{x}+${c2}y$`)
      );
      p2.addParagraph("solution", MCMaths.cleaner(`$${ny2}x=${nx2}y+${c2}xy$`));
      p2.addParagraph("solution", MCMaths.cleaner(`$${ny2}x-${c2}xy=${nx2}y$`));
      p2.addParagraph(
        "solution",
        MCMaths.cleaner(`$x(${ny2}-${c2}y)=${nx2}y$`)
      );
      p2.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x =\\displaystyle\\frac{${nx2}y}{${ny2}-${c2}y}$`
        )
      );

      const p3 = new MCQuestion.SkillsPart();
      p3.addParagraph(
        "question",
        MCMaths.cleaner(`$y(x+${xc3})=${c3}+${y3}y$`)
      );
      p3.addHeading(
        "solution",
        MCMaths.cleaner(`Make $x$ the subject of $y(x+${xc3})=${c3}+${y3}y$`)
      );
      p3.addParagraph(
        "solution",
        MCMaths.cleaner(`$x+${xc3}=\\displaystyle\\frac{${c3}}{y}+${y3}$`)
      );
      p3.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x=\\displaystyle\\frac{${c3}}{y}+${y3 - xc3}$`
        )
      );

      const p4 = new MCQuestion.SkillsPart();
      p4.addParagraph(
        "question",
        MCMaths.cleaner(`$${yx4}xy+${c4}=${x4}x+${y4}y$`)
      );
      p4.addHeading(
        "solution",
        MCMaths.cleaner(
          `Make $x$ the subject of $${yx4}xy+${c4}=${x4}x+${y4}y$`
        )
      );
      p4.addParagraph(
        "solution",
        MCMaths.cleaner(`$${yx4}xy-${x4}x=${y4}y-${c4}$`)
      );
      p4.addParagraph(
        "solution",
        MCMaths.cleaner(`$x(${yx4}y-${x4})=${y4}y-${c4}$`)
      );
      p4.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore x=\\displaystyle\\frac{${y4}y-${c4}}{${yx4}y-${x4}}$`
        )
      );

      p1.requiresSelfMarking();
      p2.requiresSelfMarking();
      p3.requiresSelfMarking();
      p4.requiresSelfMarking();

      // reordered to better suit the questions
      question.addPart(p3);
      question.addPart(p4);
      question.addPart(p1);
      question.addPart(p2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Manipulation_Exam_Q1() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const widthCoefficient = MCRandom.randomInt(2, 7);
    const widthConstant = MCRandom.randomInt(1, 15);
    const heightCoefficient = MCRandom.randomInt(2, 7);
    const heightConstant = MCRandom.randomInt(1, 15);
    const x_1 = MCRandom.randomInt(3, 12);
    const x_2 = MCRandom.randomInt(3, 12);
    const x_3 = MCRandom.randomInt(3, 12);
    const area_1 =
      (widthCoefficient * x_1 + widthConstant) *
      (heightCoefficient * x_1 + heightConstant);
    const area_2 =
      (widthCoefficient * x_2 + widthConstant) *
      (heightCoefficient * x_2 + heightConstant);
    const area_3 =
      (widthCoefficient * x_3 + widthConstant) *
      (heightCoefficient * x_3 + heightConstant);

    const height = `$${heightCoefficient}x+${heightConstant}$`;
    const width = `$${widthCoefficient}x+${widthConstant}$`;

    const a = widthCoefficient * heightCoefficient;
    const b =
      widthConstant * heightCoefficient + heightConstant * widthCoefficient;
    const c = widthConstant * heightConstant;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${person.name} sells custom made canvases.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${person.HeShe} sells the canvases of width: $\\big($${width}$\\big)cm^2$, by height: $\\big($${height}$\\big)cm^2$, where the '$x$' is how much a customer would want to scale the size of the canvas by.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A customer wants to buy canvases with $x=${x_1}, ${x_2}$ and $${x_3}$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " By expanding the brackets, what is the area of each of the canvases?",
        false
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Expanding the brackets gives $${a}x^${2}+${b}x+${c}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Now substitute each value of '$x$' in:", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` First area $= ${a}(${x_1})^2 + ${b}(${x_1}) + ${c}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${area_1}cm^2$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Second area $= ${a}(${x_2})^2 + ${b}(${x_2}) + ${c}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${area_2}cm^2$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Third area $= ${a}(${x_3})^2 + ${b}(${x_3}) + ${c}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${area_3}cm^2$`, false)
    );

    const group = new MCQuestion.InputGroup(3);
    group.addInput(area_1, 0, "First Canvas");
    group.addInput(area_2, 0, "Second Canvas");
    group.addInput(area_3, 0, "Third Canvas");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      area_1 > 1000 ||
      area_2 > 1000 ||
      area_3 > 1000 ||
      widthConstant === heightConstant ||
      widthCoefficient === heightCoefficient ||
      height === width ||
      x_1 === x_2 ||
      x_2 === x_3 ||
      x_3 === x_1
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Manipulation_Exam_Q2() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const x_hCoef = MCRandom.randomInt(1, 9);
    const x_wCoef = MCRandom.randomInt(1, 9);
    const x_hConst = MCRandom.randomInt(1, 9);
    const x_wConst = MCRandom.randomInt(1, 9);

    const height = `$${x_hCoef}x+${x_hConst}$`;
    const width = `$${x_wCoef}x+${x_wConst}$`;

    const a = x_wCoef * x_hCoef;
    const b = x_wConst * x_hCoef + x_hConst * x_wCoef;
    const c = x_wConst * x_hConst;
    const quadratic = `${a}x^${2}+${b}x+${c}`;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A rectangle has height ${height} and width ${width}. What is the area in terms of $x$, in the form $Ax^2+Bx+C$?`,
        false
      )
    );
    const img = new MCQuestion.Image("GCSE/Rectangle1_small.jpg", "height", 15);
    img.addOverlay(`$${height}$`, 25, 50);
    img.addOverlay(`$${width}$`, 50, 92);
    question.addImage("question", img);
    // question.addParagraph("question", MCMaths.cleaner( HtmlCanvas(height,135,65),false));
    // question.addParagraph("question", MCMaths.cleaner( HtmlCanvas(width,50,155),false));
    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Area of a rectangle is width $ \\times$ height, hence:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` Area $= ($${width}$) \\times($${height}$)$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${quadratic}$`, false)
    );

    const group = new MCQuestion.InputGroup(3);
    group.addInput(a, 0, "$A=$");
    group.addInput(b, 0, "$B=$");
    group.addInput(c, 0, "$C=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Manipulation_Exam_Q3() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const x_hCoef = MCRandom.randomInt(1, 9);
    const x_wCoef = MCRandom.randomInt(1, 9);
    const x_hConst = MCRandom.randomInt(1, 9);
    const x_wConst = MCRandom.randomInt(1, 9);

    const height = `$${x_hCoef}x+${x_hConst}$`;
    const width = `$${x_wCoef}x+${x_wConst}$`;

    const a = x_wCoef * x_hCoef;
    const b = x_wConst * x_hCoef + x_hConst * x_wCoef;
    const c = x_wConst * x_hConst;
    const quadratic = `${a / 2}x^${2}+${b / 2}x+${c / 2}`;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A triangle has height ${height} and width ${width}. What is the area in terms of $x$, in the form $Ax^2+Bx+C ?$`,
        false
      )
    );
    const img = new MCQuestion.Image("GCSE/Triangle1.jpg", "height", 15);
    img.addOverlay(`$${height}$`, 40, 60);
    img.addOverlay(`$${width}$`, 50, 104);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Area of a triange is half of the width $ \\times$ height, hence:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Area $= {\\frac{1}{2}}($${width}$) \\times($${height}$)$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${quadratic}$`, false)
    );

    const group = new MCQuestion.InputGroup(3);
    group.addInput(a / 2, 0, "$A=$");
    group.addInput(b / 2, 0, "$B=$");
    group.addInput(c / 2, 0, "$C=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (height === width) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Manipulation_Exam_Q4() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();

    const xCoef = MCRandom.randomInt(1, 9);
    const xConstant = MCRandom.randomInt(1, 9);

    const a = xCoef * xCoef;
    const b = 2 * xCoef * xConstant;
    const c = xConstant * xConstant;

    const sideLength = `${xCoef}x+${xConstant}`;
    const quadratic = `${a}x^${2}+${b}x+${c}`;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A square field has a side length $${sideLength}$ `,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " What is the area of the field in terms of $x$, in the form $Ax^2+Bx+C ?$",
        false
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " The area of a square is just its side length multiplied by itself, or the length 'squared' (hence the name).",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` Hence, the area $= (${sideLength})^2$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= (${sideLength})  \\times (${sideLength})$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${quadratic}$`, false)
    );
    const group = new MCQuestion.InputGroup(3);
    group.addInput(a, 0, "$A=$");
    group.addInput(b, 0, "$B=$");
    group.addInput(c, 0, "$C=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (xCoef === xConstant) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Manipulation_Exam_Q5() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const x_hCoef = MCRandom.randomInt(1, 9);
    const x_wCoef = MCRandom.randomInt(1, 9);
    const x_hConst = MCRandom.randomInt(1, 9);
    const x_wConst = MCRandom.randomInt(1, 9);

    const height = `$${x_hCoef}x+${x_hConst}$`;
    const width = `$${x_wCoef}x+${x_wConst}$`;

    const a = x_wCoef * x_hCoef;
    const b = x_wConst * x_hCoef + x_hConst * x_wCoef;
    const c = x_wConst * x_hConst;
    const quadratic = `${a}x^${2}+${b}x+${c}`;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A television has height ${height} and width ${width}. What is the area in terms of $x$, in the form $Ax^2+Bx+C ?$`,
        false
      )
    );
    const img = new MCQuestion.Image("GCSE/TV1.jpg", "height", 15);
    img.addOverlay(`$${height}$`, 11, 45);
    img.addOverlay(`$${width}$`, 48, 70);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Area of a rectangle is width $ \\times$ height, hence:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` Area $= ($${width}$) \\times($${height}$)$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${quadratic}$`, false)
    );

    const group = new MCQuestion.InputGroup(3);
    group.addInput(a, 0, "$A=$");
    group.addInput(b, 0, "$B=$");
    group.addInput(c, 0, "$C=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (height === width || width < height) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Manipulation_Exam_Q6() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const r_1_b = MCRandom.randomInt(1, 20);
    const r_2_b = MCRandom.randomInt(1, 20);
    const r_3_b = MCRandom.randomInt(1, 20);
    const r_4_b = MCRandom.randomInt(1, 20);
    const r_5_b = MCRandom.randomInt(1, 20);

    const r_6_b = MCRandom.randomInt(1, 20);
    const r_7_b = MCRandom.randomInt(1, 20);

    const general_Eq = `${1}ax^${2}+${1}bx+${1}c`;
    const general_Eq_b = `${r_1_b}x^${2}+${r_2_b}x+${r_3_b}`;

    const r_1 = MCRandom.randomInt(1, 9);
    const r_2 = MCRandom.randomInt(1, 9);
    const r_3 = MCRandom.randomInt(1, 9);
    const r_4 = MCRandom.randomInt(1, 9);

    const bracket1 = `${r_1}x+${r_2}`;
    const bracket2 = `${r_3}x+${r_4}`;

    const bracket1_b = `${r_6_b}px+${r_4_b}`;
    const bracket2_b = `${r_5_b}x+${r_7_b}q`;

    const a = r_1 * r_3;
    const b = r_2 * r_3 + r_4 * r_1;
    const c = r_2 * r_4;

    const a_b = r_1_b / (r_6_b * r_5_b);
    const b_b = r_3_b / (r_4_b * r_7_b);

    const specific_Eq = `${a}x^${2}+${b}x+${c}`;

    const c1 = `[${r_7_b * r_6_b}pq+${r_4_b * r_5_b}]x`;
    const specific_Eq_b = `${r_6_b * r_5_b}px^${2}+${c1}+${r_4_b * r_7_b}q`;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find the values of $a, b$ and $c$ by comparing coefficients with the following identity:",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` $(${bracket1})(${bracket2}) \\equiv ${general_Eq}$`,
        false
      )
    );

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the values of $p$ and $q$ by comparing coefficients with the following identity:",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` $(${bracket1_b})(${bracket2_b}) \\equiv ${general_Eq_b}$`,
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " $(a)$ First expand the brackets to obtain the following:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(${bracket1})(${bracket2}) = ${specific_Eq}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${specific_Eq} \\equiv ${general_Eq}$`, false)
    );
    question.addParagraph("solution", MCMaths.cleaner(" Therefore:", false));
    question.addParagraph("solution", MCMaths.cleaner(` $a = ${a}$`, false));
    question.addParagraph("solution", MCMaths.cleaner(` $b = ${b}$`, false));
    question.addParagraph("solution", MCMaths.cleaner(` $c = ${c}$`, false));

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " $(b)$ First expand the brackets to obtain the following:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(${bracket1_b})(${bracket2_b}) = ${specific_Eq_b}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${specific_Eq_b} \\equiv ${general_Eq_b}$`, false)
    );
    question.addParagraph("solution", MCMaths.cleaner(" Therefore:", false));
    question.addParagraph("solution", MCMaths.cleaner(` $p = ${a_b}$`, false));
    question.addParagraph("solution", MCMaths.cleaner(` $q = ${b_b}$`, false));

    const group = new MCQuestion.InputGroup(3);
    group.addInput(a, 0, "$a=$");
    group.addInput(b, 0, "$b=$");
    group.addInput(c, 0, "$c=$");
    question.addInputGroup(group);

    const group2 = new MCQuestion.InputGroup(2);
    group2.addInput(a_b, 0, "$p=$");
    group2.addInput(b_b, 0, "$q=$");
    question.addInputGroup(group2);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      a_b !== Math.round(a_b) ||
      b_b !== Math.round(b_b) ||
      (a_b === b_b && MCRandom.randomInt(1, 2) === 2)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Substitution_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const a = MCRandom.randomInt(-15, 15);
    const b = MCRandom.randomInt(-20, 20);
    const c = MCRandom.randomInt(-10, 10);
    const expression = `${a}y+${b}`;
    const numerator = c - b;
    const y = numerator / a;

    const d = MCRandom.randomInt(-4, 4);
    const e = MCRandom.randomInt(-8, 8);
    const f = MCRandom.randomInt(-25, 25);

    const xExpression = `${d}y^${2}+${e}y+${f}`;
    const randY = MCRandom.randomInt(2, 6);

    const x = d * randY * randY + (e * randY + f);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`(a) Solve $${expression} = ${c}$`, true)
    );

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `(b) Given $x = ${xExpression}$, find the value of $x$ when $y$ = $${randY}$.`,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("(a)", true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${expression} = ${c}$`, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${a}y = ${c - b}$`, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${a}y = ${numerator}$`, true)
    );
    question.addParagraph("solution", MCMaths.cleaner(`$y = ${y}$`, true));

    question.addParagraph("solution", MCMaths.cleaner("(b)", true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x = ${d}(${randY})^2+${e}(${randY})+${f}$`, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x = ${d}(${randY * randY})+${e * randY}+${f}$`, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x = ${d * randY * randY}+${e * randY}${f}$`, true)
    );
    question.addParagraph("solution", MCMaths.cleaner(`$x = ${x}$`, true));

    const group = new MCQuestion.InputGroup(1);
    group.addInput(y, 0.05, "$(a) \\ y=$");
    group.addInput(x, 0.05, "$(b) \\ x=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    // Added a check to eliminate undefined fractions
    if (
      b === 0 ||
      a === 0 ||
      a === 1 ||
      c === 0 ||
      d === 0 ||
      e === 0 ||
      f === 0 ||
      e * randY + f === 0 ||
      d * randY * randY + (e * randY + f) === 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Substitution_Exam_Q10() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(2, 20);
    const t = MCRandom.randomInt(10, 30);
    const ans = (a * t * t) / 2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "A formula for distance travelled is $s={{at^2}\\over 2}$, with $s$ being distance, $a$ acceleration and $t$ time.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `If a car accelerated at $${a} m/s^2$, for $${t} s$, from rest, find how far it would have travelled. `,
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${a}  \\times ${t}^2  \\times \\frac{1}{2}= ${ans} m$`,
        false
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.005);
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Substitution_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const tempInC = MCRandom.randomInt(5, 35);
    const tempInF = (9 / 5) * tempInC + 32;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} wants to know the temperature, in farenheit, outside. However, he only has a thermometer that measures in celcius.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.HeShe} recalls the formula for celcius to farenheit:`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" $F = {9 \\over 5}C + 32$", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " Where $F$ is the temperature in farenheit and $C$ is the temperature in celcius.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The thermometer reads $${tempInC}$ degrees celcius.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" What is the temperature in degrees farenheit?", false)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Since the thermometer reads $${tempInC}$ degrees celcius, this means that $C = ${tempInC}$.`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore we substitute $C=${tempInC}$ into the formula.`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $F = {9 \\over 5}(${tempInC}) + 32$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $F = ({9 \\over 5}  \\times ${tempInC}) + 32$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $F = ${tempInF}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore the temperature in degrees farenheit is $${tempInF}^\\circ F$`,
        false
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(tempInF, 0.05, "Temperature");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (tempInC % 5 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Substitution_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const integer = MCRandom.randomInt(40, 60);
    const fraction = 1 + MCRandom.randomInt(1, 35) / 36;
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = months[MCRandom.randomInt(0, 11)];
    const price = MCRandom.randomInt(10, 100);
    const nights = MCRandom.randomInt(5, 14);
    const aForNights = integer + fraction * (nights - 1);
    const bForNights = price * nights;
    const modDifference = Math.abs(aForNights - bForNights);
    const newFrac = fraction * (nights - 1);
    const hotels = [
      "Sunny Days Hotel",
      "Sleepy Nights Hotel",
      "Satisfactory Inn",
      "Full Moon Hotel",
      "Late Night Inn",
    ];
    const hotel1 = hotels[MCRandom.randomInt(0, 4)];
    const hotel2 = hotels[MCRandom.randomInt(0, 4)];

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The cost of a hotel room, $£C$, at ${hotel1}, for $n$ nights is given by the formula:`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$C = ${integer} + ${fraction}(n - 1)$`, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "(a) Write down the cost of staying at the hotel for one night.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `There is a second hotel called ${hotel2} on the same street.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${hotel2} costs $£${price}$ a night.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `(b) Which hotel is cheaper to stay at for $${nights}$ nights?`,
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph("solution", MCMaths.cleaner("(a)", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$C = ${integer} + ${fraction}(0)$`, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$C = ${integer}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Cost = $${integer}$`, false)
    );
    question.addParagraph("solution", MCMaths.cleaner("(b)", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${hotel1} cost for $${nights}$ nights $= ${integer} + ${fraction}(${
          nights - 1
        })$`,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$= ${integer} + ${newFrac}$`, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$= ${aForNights}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${hotel2} cost for $${nights}$ nights $= ${nights}  \\times ${price}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$= ${bForNights}$`, false)
    );
    if (aForNights < bForNights) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`So, ${hotel1} is cheaper.`, false)
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`So, ${hotel2} is cheaper.`, false)
      );
    }

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(integer, 0, "$(a)$");
    question.addInputGroup(group);
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      modDifference > 5 ||
      aForNights !== Math.round(aForNights) ||
      aForNights === bForNights ||
      hotel1 === hotel2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Substitution_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const base = MCRandom.randomInt(200, 1000);
    const add = MCRandom.randomInt(8, 15);
    const baseD = `£${(base / 100).toFixed(2)}`;
    const addD = `${add}p`;
    const used = MCRandom.randomInt(100, 500);
    const ans = base + add * used;
    const ansD = `£${(ans / 100).toFixed(2)}`;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} needs to pay ${name.hisher} electricity bill.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name}'s electric company charges a base rate of $${baseD}$, and then $${addD}$ for every  $kwh$ of electricity ${name.heshe} uses.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find an equaiton for the price $p$ the company charges for $h \\ kwh$ of electricity.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ If ${name.name} uses $${used}$ $kwh$ of electricity, find how much ${name.hisher} bill will be.`,
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ p=${baseD}+h \\times${addD}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ ${baseD} + ${used}  \\times ${addD} = ${ansD}$`,
        false
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false));

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans / 100, 0.006, "$(b)$");
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Substitution_Exam_Q5() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const equationsD = [
      "a+bx",
      "{{de}\\over {2x}}",
      "ax^2+bx+c",
      "a+bx+{c\\over x}",
      "{{abc}\\over{de}}",
      "a+de",
      "\\sqrt{b^2-4ac}",
      "{{a}\\over{b}}+{{c}\\over{d}}",
      "e^3+b^2",
      "(a+b)^2",
    ];
    const a = MCRandom.randomInt(1, 10);
    const b = MCRandom.randomInt(1, 10);
    const c = MCRandom.randomInt(1, 10);
    const d = MCRandom.randomInt(1, 10);
    const e = MCRandom.randomInt(1, 10);
    const x = MCRandom.randomInt(1, 100);
    const ans = [
      a + b * x,
      (d * e) / (2 * x),
      a * x * x + b * x + c,
      a + b * x + c / x,
      (a * b * c) / (d * e),
      a + d * e,
      Math.sqrt(b * b - 4 * a * c),
      a / b + c / d,
      e * e * e + b * b,
      (a + b) * (a + b),
    ];
    const q1 = MCRandom.randomInt(0, equationsD.length - 1);
    const q2 = MCRandom.randomInt(0, equationsD.length - 1);
    const q3 = MCRandom.randomInt(0, equationsD.length - 1);
    const d1 = equationsD[q1];
    const d2 = equationsD[q2];
    const d3 = equationsD[q3];
    const a1 = ans[q1];
    const a2 = ans[q2];
    const a3 = ans[q3];

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Given $a=${a}$, $b=${b}$, $c=${c}$, $d=${d}$, $e=${e}$, and $x=${x}$,`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Find the values of:", false)
    );
    question.addParagraph("question", MCMaths.cleaner(`$(a) \\ ${d1}$`, false));
    question.addParagraph("question", MCMaths.cleaner(`$(b) \\ ${d2}$`, false));
    question.addParagraph("question", MCMaths.cleaner(`$(c) \\ ${d3}$`, false));

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph("solution", MCMaths.cleaner(`$(a) \\ ${a1}$`, false));
    question.addParagraph("solution", MCMaths.cleaner(`$(b) \\ ${a2}$`, false));
    question.addParagraph("solution", MCMaths.cleaner(`$(c) \\ ${a3}$`, false));

    const group = new MCQuestion.InputGroup(1);
    group.addInput(a1, 0.005, "$(a)$");
    group.addInput(a2, 0.005, "$(b)$");
    group.addInput(a3, 0.005, "$(c)$");
    question.addInputGroup(group);

    if (
      q1 === q2 ||
      q2 === q3 ||
      q3 === q1 ||
      4 * a * c >= b * b ||
      Math.round(a1 * 1000) !== a1 * 1000 ||
      Math.round(a2 * 1000) !== a2 * 1000 ||
      Math.round(a3 * 1000) !== a3 * 1000
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Substitution_Exam_Q6() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const no = MCRandom.randomInt(1, 40);
    const ans = no * (9 / 5) + 32;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The formula to convert from Fahrenheit to Celsius is $C={5\\over 9}(F-32)$.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(a)$ Make $F$ the subject of the formula.", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b)$ Convert $${no} ^\\circ C$ to Fahrenheit.`, false)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$ $F={9 \\over 5}C+32$", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b)$ $F={9 \\over 5} \\times ${no}+32$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$F=${ans} ^\\circ$`, false)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.005, "$(b)$");
    question.addInputGroup(group);
    question.requiresSelfMarking();
    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Substitution_Exam_Q7() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const hour = MCRandom.randomInt(1000, 1500);
    const bonus = MCRandom.randomInt(1000, 5000);
    const hoursD = `£${(hour / 100).toFixed(2)}`;
    const bonusD = `£${(bonus / 100).toFixed(2)}`;
    const worked = MCRandom.randomInt(24, 40);
    const cars = MCRandom.randomInt(2, 10);
    const ans = `£${((hour * worked + bonus * cars) / 100).toFixed(2)}`;
    const ans2 = (hour * worked + bonus * cars) / 100;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} works at a car shop. ${name.HeShe} is paid $${hoursD}$ per hour, and then gets a bonus of $${bonusD}$ for each car ${name.heshe} sells.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Find an expression for how much money ${name.name} makes if ${name.heshe} works $h$ hours and sells $c$ cars.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ If ${name.heshe} works for $${worked}$ hours and sells $${cars}$ cars, how much money does ${name.heshe} make?`,
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ $(h  \\times ${hoursD} )+( c  \\times ${bonusD})$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ $(${worked}  \\times ${hoursD} )+( ${cars}  \\times ${bonusD} )= ${ans}$`,
        false
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans2, 0.005, "$(b)$");
    question.addInputGroup(group);
    question.requiresSelfMarking();

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Substitution_Exam_Q8() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(-5, 5);
    const b = MCRandom.randomInt(-5, 5);
    const c = MCRandom.randomInt(-10, 5);
    const ans1 = (-b + Math.sqrt(b * b - 4 * a * c)) / (2 * a);
    const ans2 = (-b - Math.sqrt(b * b - 4 * a * c)) / (2 * a);
    const b2 = b * b;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The formula for finding the roots of a quadratic in the form $ax^2+bx+c$ is $ {-b\\pm\\sqrt{b^2-4ac}}\\over{2a}$.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Find the roots of the equation $${a}x^2+${b}x+${c}$.`,
        false
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\displaystyle {{${-b} \\pm\\sqrt{${b2}-(4 \\times ${a} \\times${c})}}\\over{2 \\times ${a}}}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\displaystyle {{${-b}+\\sqrt{${b2}-(4 \\times ${a} \\times${c})}}\\over{2 \\times ${a}}}=${ans1}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\displaystyle {{${-b}-\\sqrt{${b2}-(4 \\times ${a} \\times${c})}}\\over{2 \\times ${a}}}=${ans2}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`So, $x=${ans1}$ or $${ans2}$.`, false)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput([ans1, ans2], 0.005);
    question.addInputGroup(group);

    if (
      b * b < 4 * a * c ||
      ans1 * 10 !== Math.round(ans1 * 10) ||
      ans2 * 10 !== Math.round(ans2 * 10) ||
      a === 0 ||
      b === 0 ||
      c === 0 ||
      ans1 === ans2 ||
      a === 1
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Substitution_Exam_Q9() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const s = MCRandom.randomInt(5, 20);
    const k = MCRandom.randomInt(1, 10) / 1000;
    const ans = s * k;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "A scientist's model for how plants grow depends on how much sunlight they get $(s)$ times an amount depending on the type of flower $(k)$.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Write a formula for how much plants grow $(g)$.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b)$ If $s=${s}$ and $k=${k}$, find $g$.`, false)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph("solution", MCMaths.cleaner("$(a) \\ g=sk$", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ g=${ans}$`, false)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.005, "$(b)$");
    question.addInputGroup(group);
    question.requiresSelfMarking();

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Vocabulary_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name1 = MCMisc.getName();
    const name2 = MCMisc.getName();
    const name3 = MCMisc.getName();
    const name4 = MCMisc.getName();
    const apples1 = MCRandom.randomInt(2, 8);
    const apples2 = MCRandom.randomInt(2, 12);
    const bananas1 = MCRandom.randomInt(2, 12);
    const bananas2 = MCRandom.randomInt(2, 12);
    const cherries1 = MCRandom.randomInt(2, 12);
    const cherries2 = MCRandom.randomInt(2, 12);
    const fruits = ["mangos", "pears", "plums"];
    const fruit = fruits[MCRandom.randomInt(0, 2)];
    const gaveAway = MCRandom.randomInt(4, 16);
    const totalFruit = MCRandom.randomInt(10, 30);
    const fruitLeft = totalFruit - gaveAway;
    const timesAsMany = MCRandom.randomInt(3, 6);
    const letter = fruit[0];

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Three friends compare the fruits that they have bought from the shop.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} has $${apples1}$ apples and $${bananas1}$ bananas.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name2.name} has $${bananas2}$ bananas and $${cherries1}$ cherries.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name3.name} has $${cherries2}$ cherries and $${apples2}$ apples.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Define $a, b$ and $c$ as the number of apples, bananas and cherries respectively.",
        false
      )
    );
    question.addHeading(
      "question",
      MCMaths.cleaner(
        "$(a)$ Write a simplified expression for the total number of apples, bananas and cherries the three friends have.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name4.name} has some ${fruit}.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name4.HeShe} is given some more of the same fruit so that ${name4.heshe} now has $${timesAsMany}$ times as many.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name4.HeShe} gives $${gaveAway}$ of them away and is left with $${fruitLeft}$.`,
        false
      )
    );
    question.addHeading(
      "question",
      MCMaths.cleaner(
        `$(b)$ By setting up and solving an equation, find how many ${fruit} ${name4.name} had to begin with.`,
        false
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$  $${apples1}a + ${bananas1}b + ${bananas2}b + ${cherries1}c + ${cherries2}c + ${apples2}a$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${apples1 + apples2}a + ${bananas1 + bananas2}b + ${
          cherries1 + cherries2
        }c$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$  $${timesAsMany}${letter} - ${gaveAway} = ${fruitLeft}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${timesAsMany}${letter} = ${fruitLeft + gaveAway}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${letter} = ${(fruitLeft + gaveAway) / timesAsMany}$`,
        false
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(
      (fruitLeft + gaveAway) / timesAsMany,
      0,
      `$(b)$   $${letter}=$`
    );
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      name1.name === name2.name ||
      name1.name === name3.name ||
      name1.name === name4.name ||
      name2.name === name3.name ||
      name2.name === name4.name ||
      name3.name === name4.name ||
      apples1 === apples2 ||
      bananas1 === bananas2 ||
      cherries1 === cherries2 ||
      apples1 === bananas1 ||
      bananas2 === cherries1 ||
      cherries2 === apples2 ||
      totalFruit <= 1 ||
      fruitLeft <= 2 ||
      (fruitLeft + gaveAway) / timesAsMany !==
        Math.round((fruitLeft + gaveAway) / timesAsMany)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Vocabulary_Exam_Q10() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name = MCMisc.getName();
    const headValue = MCRandom.randomInt(2, 5);
    const tailValue = MCRandom.randomInt(2, 5);
    const numberOfHeads = MCRandom.randomInt(1, 10);
    const numberOfTails = MCRandom.randomInt(1, 10);
    const points = headValue * numberOfHeads + numberOfTails * tailValue;
    const rounds = numberOfHeads + numberOfTails;
    const newNumberOfHeads = MCRandom.randomInt(10, 20);
    const newNumberOfTails = MCRandom.randomInt(10, 20);
    const newRounds = newNumberOfTails + newNumberOfHeads;
    const newPoints =
      headValue * newNumberOfHeads + newNumberOfTails * tailValue;
    const valueOfAllHeads = newNumberOfHeads * headValue;
    const valueOfAllTails = newPoints - valueOfAllHeads;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${name.name} plays a game where ${name.heshe} flips a coin.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `If the coin is a head then ${name.name} earns $${headValue}$ points.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `If the coin is tails then ${name.name} earns $${tailValue}$ points.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` (a) Write an expression in terms of $h$ (heads) and $t$ (tails) for ${name.name}'s number of points. `,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(` ${name.name} plays the game $${rounds}$ times.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} gets $${numberOfHeads}$ heads and $${numberOfTails}$ tails.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(` (b) What is ${name.hisher} score?`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` After $${newRounds}$ rounds, ${name.name} has $${newPoints}$ points.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${newNumberOfHeads}$ heads were thrown. `, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" (c) How many tails were thrown?", false)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `(a) ${name.name} will earn $${headValue}$ for each head and $${tailValue}$ for each tail which implies `,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `that ${name.hisher} number of points $ = ${headValue}h + ${tailValue}t$.`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " (b) Subbing values into the expression found in part (a):",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$P = ${headValue}  \\times ${numberOfHeads} + ${tailValue}  \\times ${numberOfTails}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$P = ${points}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "(c) Begin by subtracting the value of all of the heads from the total points:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Value of all heads = number of heads $ \\times$ value of each head = $${newNumberOfHeads}  \\times ${headValue} = ${valueOfAllHeads}$.`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Total points - value of all heads = $${newPoints} - ${valueOfAllHeads} = ${valueOfAllTails}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Total number of tails thrown = value of all tails $ \\div$ value of one tail = $${valueOfAllTails}  \\div ${tailValue} = ${newNumberOfTails}$`,
        false
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(points, 0.005, "$(b)$");
    group.addInput(newNumberOfTails, 0.005, "$(c)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      headValue === tailValue ||
      name.name.substr(name.name.length - 1) === "s" ||
      numberOfHeads === 1 ||
      numberOfTails === 1 ||
      numberOfTails === numberOfHeads
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Vocabulary_Exam_Q11() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    let a;
    let b;
    let c;
    let d;
    let sign1;
    let sign2;
    let sign3;
    let sign4;
    if (Math.random() < 0.25) {
      a = -Math.round(Math.random() * 6 + 2);
    } else {
      a = Math.round(Math.random() * 6 + 2);
    }
    if (Math.random() < 0.25) {
      b = -Math.round(Math.random() * 6 + 2);
    } else {
      b = Math.round(Math.random() * 6 + 2);
    }
    if (Math.random() < 0.25) {
      c = -Math.round(Math.random() * 6 + 2);
    } else {
      c = Math.round(Math.random() * 6 + 2);
    }
    if (Math.random() < 0.25) {
      d = -Math.round(Math.random() * 6 + 2);
    } else {
      d = Math.round(Math.random() * 6 + 2);
    }
    const Pens = Math.round(Math.random() * 6 + 2);
    const Pencils = Math.round(Math.random() * 6 + 2);
    if (b < 0) {
      sign1 = "";
    } else {
      sign1 = "+";
    }
    if (c < 0) {
      sign2 = "";
    } else {
      sign2 = "+";
    }
    if (d < 0) {
      sign3 = "";
    } else {
      sign3 = "+";
    }
    if (b + d < 0) {
      sign4 = "";
    } else {
      sign4 = "+";
    }
    let xcoe = a + c;
    let ycoe = b + d;
    if (xcoe === -1) {
      xcoe = "-";
    }
    if (ycoe === -1) {
      ycoe = "-";
    }
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Simplify $${a}x${sign1}${b}y${sign2}${c}x${sign3}${d}y$  `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Pens cost $a$ pence.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Pencils cost $b$ pence.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Write down an expression for the cost, in pence, of ${Pens} pens and ${Pencils} pencils.`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${xcoe}x${sign4}${ycoe}y$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${Pens}a+${Pencils}b$`, false, true, true)
    );

    question.requiresSelfMarking();

    if (
      Pens === Pencils ||
      xcoe === 1 ||
      xcoe === 0 ||
      ycoe === 1 ||
      ycoe === 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup;
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Algebra_Nvam_Vocabulary_Exam_Q2() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const angle1 = 1;
    const angle1Text = "$x$";

    const angle2a = MCRandom.randomInt(1, 5);
    const angle2b = MCRandom.randomInt(1, 9);
    const angle2Text = `$\\left(${angle2a}x+${angle2b}\\right)$`;

    const angle3Denom = MCRandom.randomInt(2, 9);
    const angle3 = 1 / angle3Denom;
    const angle3Text = `$\\frac{1}{${angle3Denom}}x$`;

    const angle4a = 1;
    const angle4b = -MCRandom.randomInt(2, 9);
    const angle4Text = `$\\left(${1}x+${angle4b}\\right)$`;

    const xCoef = angle1 + angle2a + angle3 + angle4a;
    const c = angle2b + angle4b;

    const ans = (360 - c) / xCoef;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(" A quadrilateral is shown below.", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " Form and solve an equation in $x$ to find the angle at vertex $A$.",
        false
      )
    );
    const img = new MCQuestion.Image("GCSE/QuadAngles.jpg", "height", 15);
    img.addOverlay(MCMaths.cleaner(`${angle1Text}$^\\circ$`), 12, 53);
    img.addOverlay(MCMaths.cleaner(`${angle2Text}$^\\circ$`), 43, 83);
    img.addOverlay(MCMaths.cleaner(`${angle3Text}$^\\circ$`), 80, 15);
    img.addOverlay(MCMaths.cleaner(`${angle4Text}$^\\circ$`), 78, 87);
    img.addOverlay(`$A$`, -5, 53);
    question.addImage("question", img);

    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Total of angles is $360^\\circ$ so:", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` ${angle1Text} + ${angle2Text} + ${angle3Text} + ${angle4Text} $= 360$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${xCoef}x+${c} = 360$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${xCoef}x = ${360 - c}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${1}x = ${(360 - c) / xCoef}^\\circ$`, false)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0, "Angle at $\\ A=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      xCoef * 100 !== Math.round(xCoef * 100) ||
      ((360 - c) / xCoef) * 100 !== Math.round(((360 - c) / xCoef) * 100)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Vocabulary_Exam_Q3() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const x = MCRandom.randomInt(2, 9);
    const height = x;
    const length_shorter = MCRandom.randomInt(1, 10);
    const length = x - length_shorter;
    const width_divider = MCRandom.randomInt(2, 10);
    const width = x / width_divider;

    // ChainTerms for width and length
    const length_text = `${1}x+${-length_shorter}`;
    const width_text = `$\\displaystyle\\frac{${1}x+${-length_shorter}}{${width_divider}}$`;
    const width_text2 = `\\displaystyle\\left(\\frac{${1}x+${-length_shorter}}{${width_divider}}\\right)`;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A triangular prism has height $x$ $cm$. Its length is $${length_shorter}$ $cm$ shorter than its height and its width is $\\displaystyle\\frac{1}{${width_divider}}$ the length.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " Formulate a cubic expression in $x$ for the volume of the triangular prism.",
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Since the volume of a triangular prism $=$ area of triangular face $ \\times$ length",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Volume $= \\big(\\frac{1}{2}  \\times$ width $ \\times$ height $\\big) \\times$ length",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Since height = $x$, length = $x-${length_shorter}$, width = $\\displaystyle \\frac{x-${length_shorter}}{${width_divider}}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Volume $=\\displaystyle \\left[ \\frac{x}{2}\\left(\\frac{x-${length_shorter}}{${width_divider}}\\right) \\right] \\left( x - ${length_shorter}\\right)$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Volume $=\\displaystyle \\frac{x}{${
          2 * width_divider
        }} \\left( x-${length_shorter} \\right)^2$ $cm^3$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Volume $=\\displaystyle \\frac{x}{${
          2 * width_divider
        }} \\left( {x^2 - ${2 * length_shorter}x + ${
          length_shorter * length_shorter
        }} \\right)$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Volume $=\\displaystyle \\frac{1}{${
          2 * width_divider
        }} \\left( {x^3 - ${2 * length_shorter}x^2 + ${
          length_shorter * length_shorter
        }x} \\right)$`,
        false
      )
    );
    question.requiresSelfMarking();
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (width_divider === length_shorter) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Vocabulary_Exam_Q4() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const side2a = MCRandom.randomInt(1, 10);
    const side2b = MCRandom.randomInt(1, 10);
    const side3a = MCRandom.randomInt(1, 10);
    const side3b = MCRandom.randomInt(1, 10);
    const side4a = MCRandom.randomInt(1, 10);
    const side4b = MCRandom.randomInt(1, 10);

    const side1_label = `${1}x`;
    const side2_label = `${side2a}x+${side2b}`;
    const side3_label = `${side3a}x+${side3b}`;
    const side4_label = `${side4a}x+${side4b}`;

    const perimeter = MCRandom.randomInt(80, 120);

    const aSum = 1 + side2a + side3a + side4a;
    const bSum = side2b + side3b + side4b;

    const eq_label_1 = `${aSum}x+${bSum}`;
    const eq_label_2 = `${aSum}x`;

    const x = (perimeter - bSum) / aSum;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The perimeter of the quadrilateral is $${perimeter}$ $cm$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " by forming and solving an equation, find the value of $x$.",
        false
      )
    );

    const img = new MCQuestion.Image("GCSE/QuadLengths.jpg", "height", 15);
    img.addOverlay(MCMaths.cleaner(`$${side1_label}$`), 25, 53);
    img.addOverlay(MCMaths.cleaner(`$${side2_label}$`), 82, 30);
    img.addOverlay(MCMaths.cleaner(`$${side3_label}$`), 50, 11);
    img.addOverlay(MCMaths.cleaner(`$${side4_label}$`), 63, 70);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Since the perimeter is equal to the sum of lengths of the sides of the quadrilateral:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${side1_label} + (${side2_label}) + (${side3_label}) + (${side4_label}) = ${perimeter}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${eq_label_1} = ${perimeter}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${eq_label_2} = ${perimeter - bSum}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $x = ${(perimeter - bSum) / aSum}$`, false)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(x, 0, "$x=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      ((side1_label === side2_label) === side3_label) === side4_label ||
      x !== Math.round(x)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Vocabulary_Exam_Q5() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const x = MCRandom.randomInt(2, 20);

    const width_xCoef = MCRandom.randomInt(2, 9);
    const width_constant = MCRandom.randomInt(2, 9);
    const width = width_xCoef * x + width_constant;

    const height_xCoef = MCRandom.randomInt(2, 9);
    const height_constant = MCRandom.randomInt(2, 9);
    const height = height_xCoef * x + height_constant;

    const area = width * height;

    const width_label = `$\\left(${width_xCoef}x + ${width_constant}\\right)cm$`;
    const height_label = `$\\left(${height_xCoef}x + ${height_constant}\\right)cm$`;

    const a = width_xCoef * height_xCoef;
    const b = width_xCoef * height_constant + height_xCoef * width_constant;
    const c = width_constant * height_constant;
    const quadratic_label_1 = `${a}x^2+${b}x+${c}`;
    const quadratic_label_2 = `${a}x^2+${b}x+${c - area}`;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The area of the quadrilateral is $${area}$ $cm^2$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The width of the rectangle is ${width_label} and the height is ${height_label}, where $x>0$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " Form and solve an equation in $x$ to find the value of $x$.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " You are given that the quadratic formula is $\\displaystyle x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}$.",
        false
      )
    );

    const img = new MCQuestion.Image("GCSE/RectArea.jpg", "height", 15);
    img.addOverlay(MCMaths.cleaner(width_label), 50, 80);
    img.addOverlay(MCMaths.cleaner(height_label), 25, 53);
    // img.addOverlay(MCMaths.cleaner('$'+area+'$ $cm^2$'), 50, 11);
    question.addImage("question", img);

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\left(${width_xCoef}x + ${width_constant}\\right)\\left(${height_xCoef}x + ${height_constant}\\right) = ${area}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${quadratic_label_1} = ${area}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${quadratic_label_2} = 0$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` Since $x>0$, $x=${x}$.`, false)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(x, 0, "$x=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      width_xCoef === width_constant ||
      height_xCoef === height_constant ||
      area > 5000
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Vocabulary_Exam_Q6() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    const person1 = MCMisc.getName();
    const person2 = MCMisc.getName();
    const person3 = MCMisc.getName();

    const timeShorter = MCRandom.randomInt(2, 9);
    const percShorter = MCRandom.randomInt(12, 18) * 5;
    const percShorterOp = percShorter / 100;

    const averageTime = MCRandom.randomInt(24, 35);

    const T1 = "x";
    const T2 = `x-${timeShorter}`;
    const T3 = `${percShorterOp}(x-${timeShorter})`;

    const a = 2 + percShorterOp;
    const b = -(timeShorter + percShorterOp * timeShorter);

    const eq1 = `${a}x+${b}`;
    const eq2 = `${a}x`;

    const rhs1 = averageTime * 3;
    const rhs2 = rhs1 - b;
    const rhs3 = (rhs1 - b) / a;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person1.name}, ${person2.name} and ${person3.name} run a 200m race.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(` ${person1.name} runs the race in $x$ seconds.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person2.name} beats ${person1.name} by $${timeShorter}$ seconds.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person3.name} only takes $${percShorter}\\%$ of the time it took ${person2.name} to run the race.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The mean of their $3$ times is $${averageTime}$ seconds.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` By forming and solving an equation in $x$, what time did ${person1.name} run the race in?`,
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Since the mean of their times $= ${averageTime}$ seconds this means:`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $\\displaystyle \\frac{(T_1 + T_2 + T_3)}{3} = ${averageTime}$.`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Where $T_1, T_2$ and $T_3$ are the times of ${person1.name}, ${person2.name} and ${person3.name} respectively.`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` So $ \\implies T_1 + T_2 + T_3 = ${rhs1}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Since $T_1 = ${T1}$, $T_2 = ${T2}$ and $T_3 = ${T3}$`,
        false
      )
    );
    question.addParagraph("solution", MCMaths.cleaner(" We obtain:", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${T1} + (${T2}) + ${T3} = ${rhs1}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $\\displaystyle ${eq1} = ${rhs1}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $\\displaystyle ${eq2} = ${rhs2}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $\\displaystyle x = ${rhs3}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore ${person1.name} runs the race in $${rhs3}$ seconds.`,
        false
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(rhs3, 0.01, "Time $\\ =$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (rhs3 !== Math.round(rhs3)) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Vocabulary_Exam_Q7() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const angle1a = 1;
    const angle1b = 0;

    const angle2a = MCRandom.randomInt(2, 20);
    const angle2b = MCRandom.randomInt(2, 20);

    const angle3a = MCRandom.randomInt(2, 20);
    const angle3b = MCRandom.randomInt(2, 20);

    const angle4a = MCRandom.randomInt(2, 9);
    const angle4b = MCRandom.randomInt(2, 9);

    const angle1Text = `${angle1a}x`;
    const angle2Text = `${angle2a}x+${angle2b}`;
    const angle3Text = `${angle3a}x+${angle3b}`;
    const angle4Text = `${angle4a}x+${angle4b}`;

    const a = angle1a + angle2a + angle3a + angle4a;
    const b = angle1b + angle2b + angle3b + angle4b;

    const eq1 = `${a}x+${b}`;
    const eq2 = `${a}x`;

    const rhs1 = 360 - b;
    const rhs2 = rhs1 / a;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A circle is split up into $4$ sectors each with angles $${angle1Text}^\\circ$, $(${angle2Text})^\\circ$, $(${angle3Text})^\\circ$, $(${angle4Text})^\\circ$ respectively.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " By forming and solving an equation in $x$, find the angle of the first sector with angle $x$.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Give your answer to $3$ significant figures.", false)
    );
    const img = new MCQuestion.Image("GCSE/AnglesInACircle.jpg", "height", 15);
    img.addOverlay(MCMaths.cleaner(`$${angle1Text}^\\circ$`), 55, 35);
    img.addOverlay(MCMaths.cleaner(`$(${angle2Text})^\\circ$`), 30, 55);
    img.addOverlay(MCMaths.cleaner(`$(${angle3Text})^\\circ$`), 73, 50);
    img.addOverlay(MCMaths.cleaner(`$(${angle4Text})^\\circ$`), 34, 26);
    question.addImage("question", img);

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Since the sum of all angles in a circle $= 360$:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${angle1Text}^\\circ + (${angle2Text})^\\circ + (${angle3Text})^\\circ + (${angle4Text})^\\circ = 360$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${eq1} = 360$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${eq2} = ${rhs1}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $x = ${rhs2.toPrecision(3)}^\\circ$`, false)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(rhs2, 0.005, "$x=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      angle4a * rhs2 + angle4b >= angle2a * rhs2 + angle2b ||
      angle2a * rhs2 + angle2b >= angle3a * rhs2 + angle3b
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Vocabulary_Exam_Q8() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const radius_a = MCRandom.randomInt(2, 10);
    const radius_b = MCRandom.randomInt(2, 10);

    const radius_text = `${radius_a}x+${radius_b}`;

    const area_to_diameter = MCRandom.randomInt(3, 10);
    const minus_perimeter = MCRandom.randomInt(1, 10);

    const a = radius_a * radius_a;
    const b = 2 * radius_a * radius_b;
    const c = radius_b * radius_b;
    const lhs1 = `${a}x^${2}+${b}x+${c}`;

    const d = area_to_diameter * 2 * radius_a;
    const e = area_to_diameter * 2 * radius_b - minus_perimeter;
    const rhs1 = `${d}x+${e}`;

    const lhs2 = (`${a}x^${2}+${b - d}`, `x+${c - e}`);
    const ans =
      (-(b - d) + Math.sqrt(2 ** (b - d) - 4 * a * (c - e))) / (2 * a);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A circle has radius $${radius_text}$ and area = $${area_to_diameter}p - ${minus_perimeter}\\pi$ where $p$ = perimeter.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " By forming an equation in equation in $x$, find $x$ to $3$ significant figures.",
        false
      )
    );

    const img = new MCQuestion.Image(
      "GCSE/AreaEqualToDiameterCircle.jpg",
      "height",
      15
    );
    img.addOverlay(MCMaths.cleaner(`$${radius_text}$`), 75, 42);

    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` area $= \\pi(${radius_text})^2$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` perimeter $= p = 2\\pi(${radius_text})$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` area $=${area_to_diameter}p - ${minus_perimeter}\\pi$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\implies \\pi(${radius_text})^2 = ${area_to_diameter}\\left[ 2\\pi(${radius_text})\\right] - ${minus_perimeter}\\pi  $`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${lhs1} = ${rhs1}$`, false)
    );
    question.addParagraph("solution", MCMaths.cleaner(` $${lhs2} = 0$`, false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x =${ans.toPrecision(3)}$`, false)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.005, "$x=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (ans < 0.1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Nvam_Vocabulary_Exam_Q9() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const numOfBags = MCRandom.randomInt(3, 9);
    const rotten = MCRandom.randomInt(2, 7);
    const ripe = MCRandom.randomInt(20, 40) * numOfBags;

    const x = ripe / numOfBags + rotten;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} buys $${numOfBags}$ bags of grapes, each containing $x$ grapes, but $${rotten}$ of the grapes in each bag are rotten.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` However, a total of $${ripe}$ grapes are still ripe.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " By forming and solving equation involving $x$, find out how many grapes there are per bag.",
        false
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${numOfBags}\\left(x - ${rotten}\\right) = ${ripe}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $x - ${rotten} = ${ripe / numOfBags}$`, false)
    );
    question.addParagraph("solution", MCMaths.cleaner(` $x = ${x}$`, false));

    const group = new MCQuestion.InputGroup(1);
    group.addInput(x, 0.005, "Grapes per bag");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (x !== Math.round(x)) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Algebra_Sequences_NthTerm_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const a = Math.round(Math.random() * 8 + 3);
    const d = Math.round(Math.random() * 8 + 3);
    const c = a - d;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Here are the first 5 terms of an arithmetic sequence:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `   $${a},${a + d},${a + 2 * d},${a + 3 * d},${a + 4 * d}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ What is the $n^{th}$ term of the sequence. ",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the $50^{th}$ term of the sequence.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ Is $${a + 19 * d + 1}$ in the sequence, justify your answer. `,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ Common difference $= ${d}$, first term - difference $= ${c}$`,
        false,
        true,
        true
      )
    );
    if (c < 0) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$n^{th} term=${d}n${c}$`)
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$n^{th} term=${d}n+${c}$`, false, true, true)
      );
    }

    if (c < 0) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(b) \\ 50^{th} term=${d} \\times 50${c} = ${a + 49 * d}$`
        )
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(b) \\ 50^{th} term=${d} \\times 50+${c} = ${a + 49 * d}$`,
          false,
          true,
          true
        )
      );
    }
    if (c < 0) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(c) \\ ${d}n${c} = ${a + 19 * d + 1}$`)
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(c) \\ ${d}n+${c} = ${a + 19 * d + 1}$`,
          false,
          true,
          true
        )
      );
    }
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${d}n = ${a + 19 * d + 1 - c}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Therefore no as $${
          a + 19 * d + 1 - c
        }$ is not a multiple of $${d}$, $n$ would not be a whole number.`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(a + 49 * d, 0.1, "$(b)$");
    question.addInputGroup(group);

    if (a === d) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Algebra_Sequences_Terms_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(1, 10);
    const m1 = b + a;
    const m2 = b + 2 * a;
    const m3 = b + 3 * a;
    const m4 = b + 4 * a;
    const m5 = b + 5 * a;
    const m6 = b + 6 * a;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Given that $${m1}$, $${m2}$ and $${m3}$ are the first three terms in a linear sequence:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find the next 3 terms of the sequence.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the $n$th of the sequence.",
        false,
        true,
        true
      )
    );
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ The sequence continues $${m4},${m5},${m6}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ The $n$th term of the sequence is $${a}n+${b}$.`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Algebra_Sequences_Terms_Exam_Q10() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(3, 7);
    const b = MCRandom.randomInt(1, 6);
    const m1 = b + a;
    const m2 = b + 2 * a;
    const m3 = b + 3 * a;
    const m4 = b + 4 * a;
    const m5 = b + 5 * a;
    const ans = MCRandom.randomInt(20, 50);
    const mAns = b + ans * a;
    const pos = MCRandom.randomInt(100, 200);
    const add = MCRandom.randomInt(2, 5);
    const pos2 = pos + add;
    const ans2 = add * a;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(`For the $n$th term $${a}n+${b}$:`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ What term of the sequence is $${mAns}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ What is the difference between the $${pos2}$th and the $${pos}$th terms in the sequence?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $(a)$ $${a}n+${b}=${mAns}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$n=${ans}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ There are $${add}$ places between the two terms, and the sequences increases by $${a}$ each place in the sequence.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, there is a difference of $${add}  \\times ${a} = ${ans2}$ between the terms.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ans, 0, "$(a)$");
    group.addInput(ans2, 0, "$(b)$");
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Algebra_Sequences_Terms_Exam_Q11() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(3, 7);
    const b = MCRandom.randomInt(10, 30);
    const m1 = b + a;
    const m2 = b + 2 * a;
    const m3 = b + 3 * a;
    const m4 = b + 4 * a;
    const m5 = b + 5 * a;
    const years = MCRandom.randomInt(2, 5);
    const ans = b + a * 52 * years;
    const n1 = 52 * years;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has $\\pounds ${b}$ in ${name.hisher} piggy bank. Every week ${name.heshe} puts in $\\pounds${a}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ After $n$ weeks how much money will ${name.name} have saved up?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ If ${name.name} keeps this up for $${years}$ years, how much money will he have saved?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a)$ $${a}n+${b}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b)$ $n=52  \\times ${years}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(${a}  \\times  ${n1} )+${b} = \\pounds ${ans}$`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0, "$(b)$");
    question.addInputGroup(group);
    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Algebra_Sequences_Terms_Exam_Q12() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(40, 60);
    const b = MCRandom.randomInt(300, 800);
    const ans = Math.floor(b / a);
    const q2 = MCRandom.randomInt(2, ans);
    const ans2 = b - q2 * a;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has left ${name.hisher} job.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has $\\pounds ${b}$ in ${name.hisher} bank account, and budgets to spend  $\\pounds ${a}$ a week. `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ How many weeks can ${name.name} go without a job?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ How much would ${name.name} have left after  $${q2}$ weeks?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ How much would ${name.name} have left after $n$ weeks?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${ans}$ weeks.`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ \\pounds ${ans2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ ${b}-${a}n$`, false, true, true)
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(2);
    group.addInput(ans, 0, "$(a)$");
    group.addInput(ans2, 0, "$(b)$");
    question.addInputGroup(group);
    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Algebra_Sequences_Terms_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name = MCMisc.getName();
    const nCo = MCRandom.randomInt(2, 7);
    const constant2 = MCRandom.randomInt(1, 20);
    const nth_termOutput = `${nCo}n+${constant2}`;
    const firstSixMonths = [];
    function nth_termCalc(coefficient, constant3, array) {
      for (let j = 1; j <= 6; j += 1) {
        const salesFigure = coefficient * j + constant3;
        array.push(salesFigure);
      }
    }
    nth_termCalc(nCo, constant2, firstSixMonths);

    const ansA = nCo * 7 + constant2;
    const lastNOutput = (`${nCo}(12)`, 1, constant2, "n", 0);
    const ansC = nCo * 12 + constant2;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has started a new business that sells double glazed windows.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `In each of ${name.hisher} first six months the number of windows sold are:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${firstSixMonths}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The sales figures form an arithmetic sequence.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find the number of windows sold in the $7$th month.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the $n$th term of this sequence.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(c)$ Find the number of windows sold in the final month of the sales year.",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${ansA}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ Difference between terms = $${nCo}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$n$th term so far = $${nCo}n$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `The first term in $${nCo}n$ is $${nCo}$ and the first term in the sequence is $${firstSixMonths[0]}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `The constant in the $n$th term = $${firstSixMonths[0]} - ${nCo} = ${constant2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$n$th term = $${nth_termOutput}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(c)$ Final month therefore $n = 12$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Number of sales in final month = $${
          nCo * 12
        } + ${constant2} = ${ansC}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ansA, 0, "$(a)$");
    group.addInput(ansC, 0, "$(c)$");
    question.addInputGroup(group);
    question.requiresSelfMarking();

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (nCo === constant2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Algebra_Sequences_Terms_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(3, 7);
    const b = MCRandom.randomInt(1, 6);
    const m1 = b + a;
    const m2 = b + 2 * a;
    const m3 = b + 3 * a;
    const m4 = b + 4 * a;
    const m5 = b + 5 * a;
    const notTerm = b + MCRandom.randomInt(20, 40) * a + 2;
    const notTerm2 = notTerm - b;
    const Ans = notTerm2 / a;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`For the $n$th term $${a}n+${b}$:`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Write the first 5 terms of the sequence.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Show that ${notTerm} cannot be in this sequence.`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ $${m1},${m2},${m3},${m4},${m5}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ Solving for $n$, $${a}n+${b}=${notTerm}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${a}n=${notTerm2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$n=${Ans}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Hence, as this leads to $n$ not being an integer, $${notTerm}$ is not in the sequence. `,
        false,
        true,
        true
      )
    );
    question.requiresSelfMarking();
    if (Ans.denominator >= 100) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Algebra_Sequences_Terms_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(3, 7);
    const b = MCRandom.randomInt(1, 6);
    const m1 = b + a;
    const m2 = b + 2 * a;
    const m3 = b + 3 * a;
    const m4 = b + 4 * a;
    const m5 = b + 5 * a;
    const ans = MCRandom.randomInt(20, 50);
    const mAns = b + ans * a;
    const pos = MCRandom.randomInt(100, 200);
    const add = MCRandom.randomInt(2, 5);
    const pos2 = pos + add;
    const ans2 = add * a;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(`For the $n$th term $${a}n+${b}$:`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ What term of the sequence is $${mAns}$?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ What is the difference between the $${pos2}$th and the $${pos}$th terms in the sequence?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $(a)$ $${a}n+${b}=${mAns}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$n=${ans}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ There are $${add}$ places between the two terms, and the sequences increases by $${a}$ each place in the sequence.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, there is a difference of $${add}  \\times ${a} = ${ans2}$ between the terms.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0, "$(a)$");
    group.addInput(ans2, 0, "$(b)$");
    question.addInputGroup(group);
    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Algebra_Sequences_Terms_Exam_Q5() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(3, 7);
    const b = MCRandom.randomInt(10, 30);
    const m1 = b + a;
    const m2 = b + 2 * a;
    const m3 = b + 3 * a;
    const m4 = b + 4 * a;
    const m5 = b + 5 * a;
    const years = MCRandom.randomInt(2, 5);
    const ans = b + a * 52 * years;
    const n1 = 52 * years;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has $\\pounds${b}$ in ${name.hisher} piggy bank. Every week ${name.heshe} puts in $\\pounds${a}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ After $n$ weeks how much money will ${name.name} have saved up?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ If ${name.name} keeps this up for $${years}$ years, how much money will ${name.heshe} have saved?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${a}n+${b}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ n=52  \\times ${years}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(${a}  \\times  ${n1} )+${b} = \\pounds${ans}$`,
        false,
        true,
        true
      )
    );
    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0, "$(b)$");
    question.addInputGroup(group);
    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Algebra_Sequences_Terms_Exam_Q6() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(40, 60);
    const b = MCRandom.randomInt(300, 800);
    const ans = Math.floor(b / a);
    const q2 = MCRandom.randomInt(2, ans);
    const ans2 = b - q2 * a;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has finished university.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has $\\pounds${b}$ in ${name.hisher} bank account, and budgets to spend  $\\pounds${a}$ a week. `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ How many weeks can ${name.name} go without a job?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ How much would ${name.name} have left after  $${q2}$ weeks?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ How much would ${name.name} have left after $n$ weeks?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${ans}$ weeks.`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ \\pounds${ans2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ ${b}-${a}n$`, false, true, true)
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(2);
    group.addInput(ans, 0, "$(a)$");
    group.addInput(ans2, 0, "$(b)$");
    question.addInputGroup(group);
    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Algebra_Sequences_Terms_Exam_Q7() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(2, 5);
    const b = MCRandom.randomInt(1, 10);
    const m1 = b + a;
    const m2 = b + 2 * a;
    const m3 = b + 3 * a;
    const m4 = b + 4 * a;
    const m5 = b + 5 * a;
    const m6 = b + 6 * a;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Given that $${m1}$, $${m2}$ and $${m3}$ are the first three terms in a linear sequence:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find the next 3 terms of the sequence.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the $n$th of the sequence.",
        false,
        true,
        true
      )
    );
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ The sequence continues $${m4},${m5},${m6}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ The $n$th term of the sequence is $${a}n+${b}$.`,
        false,
        true,
        true
      )
    );
    question.requiresSelfMarking();
    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Algebra_Sequences_Terms_Exam_Q8() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = 2 * MCRandom.randomInt(1, 3);
    const b = 2 * MCRandom.randomInt(1, 3);
    const m1 = b + a;
    const m2 = b + 2 * a;
    const m3 = b + 3 * a;
    const m4 = b + 4 * a;
    const m5 = b + 5 * a;
    const notTerm = b + MCRandom.randomInt(20, 40) * a + 1;
    const a2 = a / 2;
    const b2 = b / 2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Given that $${m1}$, $${m2}$ and $${m3}$ are the first three terms in a linear sequence:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find the $n$th of the sequence.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Expain why ${notTerm} cannot be in this sequence.`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ The $n$th term of the sequence is $${a}n+${b}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ As the Nth term can be expressed as $2(${a2}n+${b2})$ every term in the sequence is even.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Hence, $${notTerm}$ is not in the sequence. `,
        false,
        true,
        true
      )
    );
    question.requiresSelfMarking();
    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Algebra_Sequences_Terms_Exam_Q9() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(3, 7);
    const b = MCRandom.randomInt(1, 6);
    const m1 = b + a;
    const m2 = b + 2 * a;
    const m3 = b + 3 * a;
    const m4 = b + 4 * a;
    const m5 = b + 5 * a;
    const notTerm = b + MCRandom.randomInt(20, 40) * a + 2;
    const notTerm2 = notTerm - b;
    const Ans = notTerm2 / a;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`For the $n$th term $${a}n+${b}$:`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Write the first 5 terms of the sequence.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Show that ${notTerm} cannot be in this sequence.`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a) \\ ${m1},${m2},${m3},${m4},${m5}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ Solving for $n$, $${a}n+${b}=${notTerm}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${a}n=${notTerm2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$n=${Ans}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Hence, as this leads to $n$ not being an integer, $${notTerm}$ is not in the sequence. `,
        false,
        true,
        true
      )
    );
    question.requiresSelfMarking();
    if (Ans.denominator >= 100) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Geometry_Mac_AreaAndVolume_Exam_Q1() {
  let count = 0;
  const img_scale = 6;
  const img_scale2 = 3;

  for (let i = 1; i <= 1; i += 1) {
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("a) Find the volume of the cube below.", false)
    );
    const img = new MCQuestion.Image("GCSE/Prisms/3x3.jpg", "height", 15);
    img.addOverlay("$= 1cm^{3}$", 43, 25);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "b) Using the result from above, calculate the volume of:",
        false
      )
    );
    const img2 = new MCQuestion.Image(
      "GCSE/Prisms/3x3 minus row.jpg",
      "height",
      15
    );
    img2.addOverlay("$= 1cm^{3}$", 68, 18);
    question.addImage("question", img2);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("a) Volume = Width x Length x Height", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" $= 3  \\times 3  \\times 3 = 27cm^{3}$", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "b) Notice that the new shape is the old shape with $3$ blocks removed.",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Therefore, Volume of new shape = Volume of old shape - $3 = 27 - 3 = 24cm^{3}$",
        false
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(27, 0, "$(a)$");
    group.addInput(24, 0, "$(b)$");
    question.addInputGroup(group);

    if (1 === 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_AreaAndVolume_Exam_Q2() {
  let count = 0;
  const img_scale = 3;
  const img_scale2 = 3;
  const img_scale3 = 3;

  for (let i = 1; i <= 1; i += 1) {
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "a) Find the area of the shaded region below, where each square represents $1 cm^{2}$.",
        false
      )
    );
    const img = new MCQuestion.Image("GCSE/Prisms/area b.jpg", "height", 15);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner("b) Now find the volume of the prism below.", false)
    );
    const img2 = new MCQuestion.Image("GCSE/Prisms/3x2 b.jpg", "height", 15);
    img2.addOverlay("$= 1cm^{3}$", 180 / img_scale2, 45 / img_scale2);
    question.addImage("question", img2);
    question.addParagraph(
      "question",
      MCMaths.cleaner("c) From this result find the volume of:", false)
    );
    const img3 = new MCQuestion.Image("GCSE/Prisms/3x2 L.jpg", "height", 15);
    img3.addOverlay("$= 1cm^{3}$", 180 / img_scale3, 45 / img_scale3);
    question.addImage("question", img3);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "a) Area $=$ No. squares in shaded region $= 5cm^{2}$",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "b) Volume of a Prism $=$ Area of the cross-section x length",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" $= 5cm^{2}  \\times 3cm = 15cm^{3}$", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "c) Notice that 3 blocks have been removed from the old shape.",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Then, Volume of the new shape $=$ Volume of the old shape $- 3 = 15 - 3 = 12cm^{3}$",
        false
      )
    );

    const group = new MCQuestion.InputGroup(3);
    group.addInput(5, 0, "$(a)$");
    group.addInput(15, 0, "$(b)$");
    group.addInput(12, 0, "$(c)$");
    question.addInputGroup(group);

    if (1 === 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Geometry_Mac_AreaAndVolume_Exam_Q3() {
  let count = 0;
  const img_scale = 3.6;

  for (let i = 1; i <= 1; i += 1) {
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "a) Find the area of the shaded region below, where each square represents $1cm^{2}$.",
        false
      )
    );
    const img = new MCQuestion.Image("GCSE/Prisms/area L.jpg", "height", 15);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner("b) Now find the volume of the prism below.", false)
    );
    const img2 = new MCQuestion.Image("GCSE/Prisms/3x3 L.jpg", "height", 15);
    img2.addOverlay("$= 1cm^{3}$", 232 / img_scale, 45 / img_scale);
    question.addImage("question", img2);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "a) Area $=$ No. squares in shaded region $= 5cm^{2}$",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "b) Volume of a Prism $=$ Area of the cross-section x length",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" $= 5cm^{2}  \\times 3cm = 15cm^{3}$", false)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(5, 0, "$(a)$");
    group.addInput(15, 0, "$(b)$");
    question.addInputGroup(group);

    if (1 === 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Geometry_Mac_AreaAndVolume_Exam_Q4() {
  let count = 0;
  const img_scale2 = 3;

  for (let i = 1; i <= 1; i += 1) {
    const h = MCRandom.randomInt(10, 20);
    const b = MCRandom.randomInt(5, 15);
    const l = MCRandom.randomInt(30, 50);
    const A = (b * h) / 2;
    const V = A * l;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("$a)$ Find the area of the triangle below.", false)
    );
    const img = new MCQuestion.Image(
      "GCSE/Prisms/Triangle Area.jpg",
      "height",
      15
    );
    img.addOverlay(`$${h}cm$`, 32, 44);
    img.addOverlay(`$${b}cm$`, 16, 88);
    img.addOverlay("$A$", 17, 49);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner("$b)$ Hence, find the volume of the prism below.", false)
    );
    const img2 = new MCQuestion.Image(
      "GCSE/Prisms/Triangle Prism.jpg",
      "height",
      15
    );
    img2.addOverlay(`$${l}cm$`, 81 / img_scale2, 20 / img_scale2);
    img2.addOverlay("A", 15, 60);
    question.addImage("question", img2);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$a)$ Area $=$ $\\frac{1}{2}$(base $ \\times$ height)",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $= \\frac{1}{2}(${b}  \\times ${h}) = ${A}cm^{2}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$b)$ Volume of a Prism $=$ Area of the cross-section x length",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${A}cm^{2}  \\times ${l}cm = ${V}cm^{3}$`, false)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(A, 0, "$a)$");
    group.addInput(V, 0, "$b)$");
    question.addInputGroup(group);

    if (b >= h) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Geometry_Mac_AreaAndVolume_Exam_Q5() {
  let count = 0;
  const img_scale = 1.5;

  for (let i = 1; i <= 1; i += 1) {
    // part a
    const rx10 = MCRandom.randomInt(5, 15, 5);
    let r = rx10 / 10;
    r = r.toFixed(1);
    const l1 = MCRandom.randomInt(3, 10);
    const v1 = r * r * l1; // not including pi
    // part b
    const l2 = MCRandom.randomInt(8, 20);
    const v2 = r * r * l2; // not inclunding pi

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The diagram below shows a section of a hosepipe with uniform radius.",
        false
      )
    );
    const img = new MCQuestion.Image("GCSE/Prisms/Cylinder.jpg", "height", 15);
    img.addOverlay(`$${r}cm$`, 50 / img_scale, 92 / img_scale);
    img.addOverlay(`$${l1}cm$`, 60 / img_scale, 15 / img_scale);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "a) Find the maximum volume of water that can fit in the section of hosepipe at once, in the form $x \\pi cm^3$.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Another section of the same hosepipe has a maximum volume of $${v2}\\pi{cm}^{3}$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("b) Find the length of this new section of pipe.", false)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "a) Volume $=$ Area of Cross-Section $ \\times$ Length",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" $= r^{2}l\\pi$", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= (${r})^{2}(${l1})\\pi$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${v1}\\pi{cm}^{3}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("b) $V = r^{2}l\\pi$", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Rearranging gives, $l = \\frac{V}{r^{2}\\pi}$", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`So, $l = \\frac{${v2}\\pi}{(${r})^{2}\\pi}$`, false)
    );
    question.addParagraph("solution", MCMaths.cleaner(` $= ${l2}cm$`, false));

    const group = new MCQuestion.InputGroup(1);
    group.addInput(v1, 0, "$a)\\ x=$");
    group.addInput(l2, 0, "$b)$");
    question.addInputGroup(group);

    if (l1 === l2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Geometry_Mac_AreaAndVolume_Exam_Q6() {
  let count = 0;
  const img_scale = 3;
  const img_scale2 = 4;

  for (let i = 1; i <= 1; i += 1) {
    // A
    const h1 = MCRandom.randomInt(2, 6);
    const l1 = MCRandom.randomInt(10, 15);
    const w1 = MCRandom.randomInt(8, 12);
    const VA = h1 * l1 * w1;
    // B
    const h2 = MCRandom.randomInt(8, 12);
    const l2 = MCRandom.randomInt(4, 8);
    const w2 = MCRandom.randomInt(8, 12);
    const VB = h2 * l2 * w2;
    // difference
    const d = Math.abs(VA - VB);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("Two fish tanks, A and B, are shown.", false)
    );
    const img = new MCQuestion.Image(
      "GCSE/Prisms/Two cuboids.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", 50 / img_scale, 140 / img_scale);
    img.addOverlay("$B$", 220 / img_scale2, 170 / img_scale2);
    img.addOverlay(`$${h1}cm$`, 0, 130 / img_scale);
    img.addOverlay(`$${l1}cm$`, 10 / img_scale, 50 / img_scale);
    img.addOverlay(`$${w1}cm$`, 70 / img_scale, 5);
    img.addOverlay(`$${h2}cm$`, 310 / img_scale2, 150 / img_scale2);
    img.addOverlay(`$${l2}cm$`, 290 / img_scale2, 250 / img_scale2);
    img.addOverlay(`$${w2}cm$`, 220 / img_scale2, 280 / img_scale2);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner("Which tank holds the most water?", false)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Volume of a Cuboid $=$ Length $ \\times$ Width $ \\times$ Height",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Volume of $A = ${l1}  \\times ${w1}  \\times ${h1}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${VA}cm^{3}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Volume of $B = ${l2}  \\times ${w2}  \\times ${h2}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${VB}cm^{3}$`, false)
    );
    if (VA > VB) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          "Therefore tank $A$ holds more water than tank $B$.",
          false
        )
      );
    } else if (VA < VB) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          "Therefore tank $B$ holds more water than tank $A$.",
          false
        )
      );
    }

    question.requiresSelfMarking();

    if (w1 <= l1 || VA === VB || d > 50) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Geometry_Mac_AreaAndVolume_Exam_Q7() {
  let count = 0;
  const img_scale = 3.8;
  const img_scale2 = 3;

  for (let i = 1; i <= 1; i += 1) {
    const b = MCRandom.randomInt(1, 10);
    const c = MCRandom.randomInt(5, 15);
    const d = MCRandom.randomInt(1, 10);
    const e = MCRandom.randomInt(5, 15);
    const f = MCRandom.randomInt(1, 10);
    const g = MCRandom.randomInt(7, 20);
    const area = d * e + g * f - d * g + g * b - b * c - b * e;
    const l = MCRandom.randomInt(15, 30);
    const vol = area * l;
    const a = g - c - e;
    const A = a * b;
    const b2 = f - d;
    const B = b2 * g;
    const C = d * e;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("a) Find the area of the region below.", false)
    );
    const img = new MCQuestion.Image("GCSE/Prisms/U region.jpg", "height", 15);
    img.addOverlay(`$${b}cm$`, 30 / img_scale, 33 / img_scale + 13);
    img.addOverlay(`$${c}cm$`, 82 / img_scale + 2, 82 / img_scale + 15);
    img.addOverlay(`$${d}cm$`, 105 / img_scale + 10, 52 / img_scale + 18);
    img.addOverlay(`$${e}cm$`, 156 / img_scale, 26 / img_scale + 10);
    img.addOverlay(`$${f}cm$`, 211 / img_scale + 1, 79 / img_scale + 20);
    img.addOverlay(`$${g}cm$`, 95 / img_scale, 127 / img_scale + 37);
    img.addOverlay("Not drawn to scale", 232 / img_scale, 5);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The prism below has the same cross section as the region above.",
        false
      )
    );
    const img2 = new MCQuestion.Image("GCSE/Prisms/U volume.jpg", "height", 15);
    img2.addOverlay(`$${l}cm$`, 45, 60);
    question.addImage("question", img2);
    question.addParagraph(
      "question",
      MCMaths.cleaner("b) Calculate the volume of the prism.", false)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "a) First split up the region into 3 rectangles where the area of each can be found more easily:",
        false
      )
    );
    const img3 = new MCQuestion.Image(
      "GCSE/Prisms/U region split.jpg",
      "height",
      15
    );
    img3.addOverlay("A", 30 / img_scale2 - 1, 34 / img_scale2 + 10);
    img3.addOverlay("B", 26, 50);
    img3.addOverlay("C", 91 / img_scale2 + 11, 93 / img_scale2 + 1);
    img3.addOverlay(`$${b}cm$`, 64 / img_scale2 - 1, 37 / img_scale2 + 10);
    img3.addOverlay(`$${d}cm$`, 102 / img_scale2 - 7, 52 / img_scale2 + 15);
    img3.addOverlay(`$${e}cm$`, 158 / img_scale2 - 12, 26 / img_scale2 + 10);
    img3.addOverlay(`$${g}cm$`, 85 / img_scale2, 129 / img_scale2 + 28);
    img3.addOverlay("$a$", 31 / img_scale2 - 2, 0);
    img3.addOverlay("$b$", 210 / img_scale2 - 16, 94 / img_scale2 + 20);
    question.addImage("solution", img3);
    question.addParagraph("solution", MCMaths.cleaner("Region A:", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Finding, $a = ${g} - ${e} - ${c} = ${a} cm$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `gives the area of A $= ${a}  \\times ${b} = ${A} cm^{2}$`,
        false
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("Region B:", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Finding, $b = ${f} - ${d} = ${b2}cm$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `gives the area of B $= ${b2}  \\times ${g} = ${B} cm^{2}$`,
        false
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("Region C:", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Area of C $= ${d}  \\times ${e} = ${C}cm^{2}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Therefore, the total area $= ${A} + ${B} + ${C} = ${area}cm^{2}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "b) Volume of a Prism = Cross-Sectional Area $  \\times $ Length",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $= ${area}cm^{2}  \\times ${l}cm = ${vol}cm^{3}$`,
        false
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(area, 0, "$a)$");
    group.addInput(vol, 0, "$b)$");
    question.addInputGroup(group);

    if (
      g <= c + e ||
      b <= d ||
      f <= d ||
      c <= d ||
      e <= d ||
      Math.abs(c - e) > 2 ||
      g - c - e > 4
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Geometry_Mac_AreaAndVolume_Exam_Q8() {
  let count = 0;
  const img_scale = 4;

  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a1 = MCRandom.randomInt(16, 22);
    const a = a1 / 10;
    const b1 = MCRandom.randomInt(12, 16);
    const b = b1 / 10;
    const h1 = MCRandom.randomInt(8, 12);
    const h = h1 / 10;
    const l1 = MCRandom.randomInt(8, 12);
    const l = l1 / 10;
    const area = 0.5 * h * (a + b);
    const v = 0.5 * h * l * (a + b);
    const s = MCRandom.randomInt(1, 2);
    let bags = 0;
    if (s === 1) {
      bags = Math.trunc(v * 10);
    } else {
      bags = Math.trunc(v * 10 + 1);
    }
    const bAns = ["Yes", "No"];

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has hired a skip and wants to know how much ${name.heshe} can fit in it.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("a) Calculate the volume of the skip below.", false)
    );
    const img = new MCQuestion.Image("GCSE/Prisms/Skip.jpg", "height", 15);
    img.addOverlay(`$${a}m$`, 120 / img_scale, 0 / img_scale + 3);
    img.addOverlay(`$${b}m$`, 97 / img_scale + 3, 123 / img_scale + 37);
    img.addOverlay(`$${h}m$`, 118 / img_scale + 2, 68 / img_scale + 23);
    img.addOverlay(`$${l}m$`, 173 / img_scale + 4, 101 / img_scale + 30);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has $${bags}$ bags of rubbish, each taking up $0.1 m^{3}$ of space in the skip.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `b) Can ${name.heshe} fit all of the bags in the skip?`,
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "a) First find the area of the trapezium cross-section:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $A = \\frac{1}{2}(a + b)h = \\frac{1}{2}(${a} + ${b})(${h}) = ${area.toFixed(
          3
        )}m^{2}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Then multiply by the length of the prism:", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $V = A  \\times l = ${area.toFixed(3)}  \\times ${l} = ${v.toFixed(
          3
        )} m^{3}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `b) ${
          bAns[s - 1]
        }, as the $${bags}$ bags take up $${bags}  \\times 0.1 = ${
          bags / 10
        } m^{3}$ of space in the skip, `,
        false
      )
    );
    if (s === 1) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `and the skip can hold $${v.toFixed(
            3
          )} m^{3}$ so they can all fit in.`,
          false
        )
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `but the skip can only hold $${v.toFixed(
            3
          )} m^{3}$ so not all the bags can fit in.`,
          false
        )
      );
    }

    const group = new MCQuestion.InputGroup(1);
    group.addInput(v, 0.1, "a)");
    question.addInputGroup(group);
    question.requiresSelfMarking();
    if (a1 - b1 < 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Geometry_Mac_CircleMeasurments_Exam_Q1() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();

    const radius = MCRandom.randomInt(10, 19) * 10;
    const perimeter = Math.PI * (2 * radius);
    const area = Math.PI * (radius * radius);
    const area_in_pi = Math.round(area / Math.PI);

    const numOfPeople = MCRandom.randomInt(5, 10);
    const space = perimeter / numOfPeople;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} is organising the meal at a wedding.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" The tables at the wedding meal are circular.", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${
          person.HeShe
        } wants to make sure each guest on the table has about $${space.toFixed(
          0
        )}cm$ of space (arced length) on the table.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` Given that ${person.heshe} knows that each table will have an area of $${area_in_pi}\\pi$ $cm^2$, how many people can ${person.heshe} seat around each table?`,
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${area_in_pi}\\pi = \\pi(r^2)$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $r = \\pm \\sqrt{${area_in_pi}}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Since the radius is always going to be postive:", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $r = ${radius}cm$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $perimeter = 2\\pi(${radius})$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${2 * radius}\\pi$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Let space for each person $= s$, and number of people that can seat around the table $= n$",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" $s = \\frac{perimeter}{n}$", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" $n = \\frac{perimeter}{s}$", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $n = \\frac{${2 * radius}\\pi}{${space.toFixed(0)}}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $n = ${Math.floor(numOfPeople)}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore she can seat $${Math.floor(
          numOfPeople
        )}$ people around each table.`,
        false
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(Math.floor(numOfPeople), 0);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (numOfPeople === 10 && MCRandom.randomInt(1, 4) > 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_Measuring_Exam_Q1() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const angle = MCRandom.randomInt(30, 60);
    const ans = 360 - angle;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(" There are two points on a map, $A$ and $B$.", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" What is the bearing of $B$ from $A$?", false)
    );
    const img = new MCQuestion.Image(
      "GCSE/Bearings/bearingImage1.jpg",
      "height",
      15
    );

    img.addOverlay(`$${angle}^\\circ$`, 42, 35);
    img.addOverlay("$A$", 58, 55);
    img.addOverlay("$B$", 2, 12);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Bearing of $B$ from $A = 360^\\circ - ${angle}^\\circ$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${ans}^\\circ$`, false)
    );

    question.addImage("question", img);

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0);
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_Measuring_Exam_Q2() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const angle = MCRandom.randomInt(91, 135);
    const ans = 180 - angle;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " There are two points on a map, $A$ and $B$. (INCOMPLETE)",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" What is the bearing of $B$ from $A$?", false)
    );
    const img = new MCQuestion.Image(
      "GCSE/Bearings/bearingImage2.jpg",
      "height",
      15
    );
    img.addOverlay(`$${angle}^\\circ$`, 70, 60);
    img.addOverlay("$A$", 43, 54);
    img.addOverlay("$B$", 90, 6);

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Bearing of $B$ from $A = 180^\\circ - ${angle}^\\circ$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= 0${ans}^\\circ$`, false)
    );
    question.addImage("question", img);

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_PerimeterAndSurfaceArea_Exam_Q1() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const radius = MCRandom.randomInt(3, 20);

    const perimeter = Math.PI * (2 * radius);
    const area = Math.PI * (radius * radius);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(` A circle has radius $${radius}cm$.`, false)
    );
    question.addHeading("question", MCMaths.cleaner(" Given that:", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Perimeter $= \\pi d$", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Area $= \\pi r^2$", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(a)$ What is the perimeter of the circle?", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(b)$ What is the area of the circle?", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Give your answers to $3$ significant figures.", false)
    );
    const img = new MCQuestion.Image(
      "GCSE/Perimeter_and_area/Circle1Small.jpg",
      "height",
      15
    );
    img.addOverlay(`$${radius}cm$`, 74, 57);

    question.addImage("question", img);
    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `  $(a)$: Perimeter $= \\pi (2  \\times ${radius}) $`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${perimeter.toPrecision(3)} cm$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`  $(b)$: Area $= \\pi (${radius}^2) $`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${area.toPrecision(3)} cm^2$`, false)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(perimeter, 0.1, "$(a)$");
    group.addInput(area, 1, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (area >= 1000) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_PerimeterAndSurfaceArea_Exam_Q10() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const rectHeight = MCRandom.randomInt(3, 10);
    const rectWidth = MCRandom.randomInt(12, 40);

    const squareSide = MCRandom.randomInt(3, 10);

    const triangleBase = MCRandom.randomInt(3, 10);

    const circleRadius = 0.5 * squareSide;

    const smallWidth = rectWidth - (triangleBase + squareSide);
    const triangleHyp = Math.sqrt(
      triangleBase * triangleBase + squareSide * squareSide
    );

    const perimeter =
      rectWidth + 2 * rectHeight + smallWidth + triangleHyp + 2 * squareSide;

    const rectArea = rectWidth * rectHeight;
    const triangleArea = 0.5 * triangleBase * squareSide;
    const circleArea = Math.PI * (circleRadius * circleRadius);
    const squareCutOutArea = squareSide * squareSide - circleArea;

    const area = rectArea + triangleArea + squareCutOutArea;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " A compound shape is made out of a rectangle, a triangle and a square with a circle cut out of it.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The rectangle has width $${rectWidth}cm$ and height $${rectHeight}cm$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The triangle has a base width of $${triangleBase}cm$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(` The square has a side length $${squareSide}cm$`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The radius of the circle cut out of the square is $${circleRadius}cm$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " What is $(a)$ the perimeter and $(b)$ the area of the compound shape?",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Give your answers to $3$ significant figures.", false)
    );

    const img = new MCQuestion.Image(
      "GCSE/Perimeter_and_area/CompoundShape1.jpg",
      "height",
      15
    );
    img.addOverlay(`$${rectHeight}cm$`, 10, 73);
    img.addOverlay(`$${rectWidth}cm$`, 50, 92);
    img.addOverlay(`$${squareSide}cm$`, 105, 25);
    img.addOverlay(`$${triangleBase}cm$`, 52, 53);
    img.addOverlay(`$${circleRadius}cm$`, 89, 19);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(a)$: Perimeter $= ${rectWidth} + (2  \\times ${rectHeight}) + (${rectWidth} - (${triangleBase} + ${squareSide})) + \\sqrt{${triangleBase}^2 + ${squareSide}^2} + (2  \\times ${squareSide})$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${perimeter.toPrecision(3)}cm$`, false)
    );

    //                             rect                                      trian                                                             squareCutOut
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(b)$: Area $= (${rectWidth}  \\times ${rectHeight}) + ({1 \\over 2}  \\times ${triangleBase}  \\times ${squareSide}) + ( (${squareSide}^2) - (\\pi  \\times ${circleRadius}^2) )$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${area.toPrecision(3)}cm^2$`, false)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(perimeter, 0.5, "$(a)$");
    group.addInput(area, 0.5, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (rectHeight === squareSide || squareSide === triangleBase) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_PerimeterAndSurfaceArea_Exam_Q11() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const rectHeight = MCRandom.randomInt(3, 10);
    const rectWidth = MCRandom.randomInt(6, 20);

    const triangleBase = MCRandom.randomInt(6, 20);
    const arrowHeight = MCRandom.randomInt(10, 30);

    const smallTriangleSide = (arrowHeight - rectHeight) / 2;

    const triangleHeight = smallTriangleSide + rectHeight / 2;

    const triangleHyp = Math.sqrt(
      triangleBase * triangleBase + triangleHeight * triangleHeight
    );

    const perimeter =
      2 * rectWidth + rectHeight + 2 * smallTriangleSide + 2 * triangleHyp;
    const area = rectWidth * rectHeight + triangleBase * (arrowHeight / 2);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " An arrow is made up of two congruent triangles and a rectangle.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The rectangle has width $${rectWidth}cm$ and height $${rectHeight}cm$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The triangle has a base width of $${triangleBase}cm$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(` The arrow has a height of $${arrowHeight}cm$.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " What is $(a)$ the area and $(b)$ the perimeter of the arrow?",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Give your answers to $3$ significant figures.", false)
    );

    const img = new MCQuestion.Image(
      "GCSE/Perimeter_and_area/Arrow1.jpg",
      "height",
      15
    );
    img.addOverlay(`$${rectHeight}cm$`, 11, 50);
    img.addOverlay(`$${rectWidth}cm$`, 20, 73);
    img.addOverlay(`$${triangleBase}cm$`, 70, 45);
    img.addOverlay(`$${arrowHeight}cm$`, 108, 50);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(a)$: Area $= (${rectHeight}  \\times ${rectWidth}) + {1 \\over 2}(${triangleBase}  \\times ${
          arrowHeight / 2
        }) + {1 \\over 2}(${triangleBase}  \\times ${arrowHeight / 2})$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${area.toPrecision(3)}cm^2$`, false)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(b)$: Perimeter $= (2  \\times ${rectWidth}) + ${rectHeight} + (${arrowHeight} - ${rectHeight}) + (2  \\times \\sqrt{(${triangleBase})^2+(${triangleHeight})^2})$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${perimeter.toPrecision(3)}cm$`, false)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(area, 0.5, "$(a)$");
    group.addInput(perimeter, 0.5, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      triangleBase === rectHeight ||
      arrowHeight > 3 * rectHeight ||
      triangleBase === rectWidth
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_PerimeterAndSurfaceArea_Exam_Q12() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const bigHeight = MCRandom.randomInt(10, 30);
    const bigWidth = MCRandom.randomInt(10, 30);

    const smallHeight = MCRandom.randomInt(6, 18);
    const smallWidth = MCRandom.randomInt(6, 18);

    const person = MCMisc.getName();

    const triangleBase = bigWidth - smallWidth;
    const triangleHeight = bigHeight - smallHeight;
    const triangleHyp = Math.sqrt(
      triangleBase * triangleBase + triangleHeight * triangleHeight
    );

    const area =
      0.5 * (bigWidth + smallWidth) * (bigHeight - smallHeight) +
      bigWidth * smallHeight;
    const perimeter =
      bigHeight + bigWidth + smallWidth + smallHeight + triangleHyp;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} owns a field that has a width $${bigWidth}m$ and height $${bigHeight}m$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` However ${person.name} doesn't own a small region of the top right hand corner of the field.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The smaller sides of the field have lengths $${smallWidth}m$ and $${smallHeight}m$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` What is $(a)$ the area and $(b)$ the perimeter of ${person.name}'s field?`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " Given that the area of a trapezium $={1 \\over 2} (a + b) h$",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Give your answers to $3$ significant figures.", false)
    );

    const img = new MCQuestion.Image(
      "GCSE/Perimeter_and_area/Trapezium2.jpg",
      "height",
      15
    );

    img.addOverlay(`$${bigHeight}m$`, 10, 50);
    img.addOverlay(`$${bigWidth}m$`, 50, 95);
    img.addOverlay(`$${smallHeight}m$`, 90, 70);
    img.addOverlay(`$${smallWidth}m$`, 33, 7);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(a)$: Area $= \\Big( {1 \\over 2}  \\times (${bigWidth} + ${smallWidth})  \\times (${bigHeight} - ${smallHeight}) \\Big) + (${bigWidth}  \\times ${smallHeight})$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${area.toPrecision(3)}m^2$`, false)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(b)$: Perimeter $= ${bigWidth} + ${bigHeight} + ${smallWidth} + ${smallHeight} + (\\sqrt{(${bigHeight} - ${smallHeight})^2+(${bigWidth} - ${smallWidth})^2})$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${perimeter.toPrecision(3)}m$`, false)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(area, 0.5, "$(a)$");
    group.addInput(perimeter, 0.5, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      smallWidth >= bigWidth ||
      smallHeight >= bigHeight ||
      bigWidth === bigHeight ||
      smallWidth === smallHeight
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_PerimeterAndSurfaceArea_Exam_Q13() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const circleRadius = MCRandom.randomInt(30, 50);
    const smallCircleArea = Math.PI * MCRandom.randomInt(8, 20) ** 2;

    const rectWidth = MCRandom.randomInt(10, 20);
    const rectHeight = MCRandom.randomInt(75, 100);

    const triangleHeight = MCRandom.randomInt(10, 30);
    const triangleHyp = Math.sqrt(
      rectWidth * rectWidth + triangleHeight * triangleHeight
    );

    const area =
      Math.PI * (circleRadius * circleRadius) +
      rectWidth * rectHeight +
      (triangleHeight * rectWidth) / 2 -
      smallCircleArea;

    const perimeter =
      Math.PI * (2 * circleRadius) -
      rectWidth +
      2 * rectHeight +
      triangleHyp +
      triangleHeight;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " A banjo is made up of a circle, a rectangle, a triangle and a small circle cut out of the big circle.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(` The circle has a radius of $${circleRadius}cm$`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The rectangle has a width of $${rectWidth}cm$ and a height of $${rectHeight}cm$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The triangle has a height of $${triangleHeight}cm$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The small circle has an area of $${smallCircleArea.toPrecision(
          3
        )}cm^2$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " What is $(a)$ the area and $(b)$ the perimeter of a cross sectional area of the banjo.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` *Note* Let the curved area of where the rectangle meets the circle $\\approx ${rectWidth}cm$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Give your answers to $3$ significant figures.", false)
    );

    const img = new MCQuestion.Image(
      "GCSE/Perimeter_and_area/Banjo1.jpg",
      "height",
      20
    );
    img.addOverlay(`$${circleRadius}cm$`, 40, 80);
    img.addOverlay(`$${rectWidth}cm$`, 102, 0);
    img.addOverlay(`$${rectHeight}cm$`, 44, 25);
    img.addOverlay(`$${triangleHeight}cm$`, 107, 15);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(a)$: Area $= (\\pi  \\times (${circleRadius})^2) + (${rectWidth}  \\times ${rectHeight}) + {1 \\over 2}(${triangleHeight}  \\times ${rectWidth}) - (${smallCircleArea.toPrecision(
          3
        )})$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${parseFloat(area.toPrecision(3))}cm^2$`, false)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(b)$: Perimeter $= ((\\pi  \\times (2  \\times ${circleRadius})) - ${rectWidth}) + (2  \\times ${rectHeight}) + \\sqrt{(${rectWidth})^2 + (${triangleHeight})^2}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${parseFloat(perimeter.toPrecision(3))}cm$`, false)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(area, 2, "$(a)$");
    group.addInput(perimeter, 0.6, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (smallCircleArea >= 1000) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_PerimeterAndSurfaceArea_Exam_Q2() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const radius = MCRandom.randomInt(3, 20);

    const perimeter = Math.PI * (2 * radius);
    const area = Math.PI * (radius * radius);
    const area_in_pi = Math.round(area / Math.PI);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A circle has an area of $${area_in_pi}\\pi$ $cm^2$. What is the $(a)$ the radius and $(b)$ the perimeter of the circle?`,
        false
      )
    );
    question.addParagraph("question", MCMaths.cleaner(" Given that:", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Perimeter $= \\pi d$", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Area $= \\pi r^2$", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Give your answers to $3$  significant figures. ", false)
    );
    const img = new MCQuestion.Image(
      "GCSE/Perimeter_and_area/Circle1Small.jpg",
      "height",
      15
    );
    img.addOverlay("$r$ $cm$", 74, 57);

    question.addImage("question", img);

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`  $(a)$: $${area_in_pi}\\pi = \\pi(r^2) $`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${area_in_pi} = r^2$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $r = \\pm \\sqrt{${area_in_pi}}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Since the value of the radius can only be positve:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $r = ${radius.toPrecision(3)}cm$`, false)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " $(b)$ Since the diameter is $ 2  \\times radius$",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" perimeter $= 2 \\pi r$", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` perimeter $= 2 \\pi (${radius})$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${perimeter.toPrecision(3)}cm$`, false)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(radius, 0.1, "$(a)$");
    group.addInput(perimeter, 0.1, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (area >= 1000) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_PerimeterAndSurfaceArea_Exam_Q3() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const boxWidth = MCRandom.randomInt(9, 18) / 10;
    const area = Math.PI * boxWidth * boxWidth;
    const boxArea = 2 * boxWidth * boxWidth;

    const shadedArea = 0.5 * (boxArea - 0.5 * area);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A circular trampoline is shipped and boxed in a way that the frame is split, equally, into two. The box has a width of $${boxWidth}cm$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " $(a)$ What is the area enclosed by the trampoline frame?",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " $(b)$ What is the area of the grey section of the box?",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " Give your answers to the nearest $3$ significant figures.",
        false
      )
    );
    const img = new MCQuestion.Image(
      "GCSE/Perimeter_and_area/SemiCircleInARectangle1.jpg",
      "height",
      15
    );
    img.addOverlay(`$${boxWidth}m$`, 50, 105);
    question.addImage("question", img);
    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " $(a)$ Since the trampoline frame is split equally, the box $width$ $=$ trampoline frame's $radius$ so:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $r = ${boxWidth}cm$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` Hence the $area = \\pi  \\times ${boxWidth}^2$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${area.toPrecision(3)}m^2$`, false)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " $(b)$ Since the trampoline frame is split equally, the box $height$ $=$ $2  \\times$ trampoline frame's $radius$ so:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Area of the box $= ${boxWidth}  \\times (2  \\times ${boxWidth})$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= 2  \\times ${boxWidth}^2$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${boxArea.toPrecision(3)}m^2$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Let area of the box $= A_{b}$, area of the circle $= A_{c}$",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Shaded area $= \\frac{1}{2}\\left(A_b - \\frac{1}{2}A_c\\right)$",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $A_b = ${boxArea.toPrecision(3)}, A_c = ${area.toPrecision(3)}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $\\implies$ Shaded area $= ${shadedArea.toPrecision(3)}m^2$`,
        false
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(area, 0.1, "$(a)$");
    group.addInput(shadedArea, 0.01, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (boxWidth === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_PerimeterAndSurfaceArea_Exam_Q4() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();

    const size1 = MCRandom.randomInt(9, 18);
    const size2 = MCRandom.randomInt(9, 18);

    const size1Cost = MCRandom.randomInt(10, 20);
    const size2Cost = 2 * size1Cost;

    const twoSize1Area = 2 * Math.PI * (size1 / 2) * (size1 / 2);
    const size2Area = Math.PI * (size2 / 2) * (size2 / 2);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} wants to order some pizza for a party ${person.heshe} is hosting.`,
        false
      )
    );
    question.addParagraph(
      "question",
      `$${size1}' $ pizzas costs $\\pounds ${size1Cost}$`
    );
    question.addParagraph(
      "question",
      ` $${size2}' $ pizzas costs $\\pounds ${size2Cost}$`
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` Should ${person.name} order two $${size1}'$ pizzas or one $${size2}'$ pizza if ${person.heshe} wants to get the most area of pizza per pound spent?`,
        false
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Two $${size1}'$ pizzas has an area of $= 2  \\times \\left( \\pi  \\times ${
          size1 / 2
        }^2 \\right)$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${twoSize1Area.toPrecision(3)}\\ inches^2$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` One $${size2}'$ pizzas has an area of $=  \\pi  \\times ${
          size2 / 2
        }^2$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${size2Area.toPrecision(3)}\\ inches^2$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` And since two $${size1}'$ pizzas costs the same as one $${size2}'$ pizza then it is clear to see that ${person.name} should buy one $${size2}'$ pizza.`,
        false
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(size2, 0, "Pizza Size");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (size2Area - twoSize1Area <= 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_PerimeterAndSurfaceArea_Exam_Q5() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const smallCircleRadius = MCRandom.randomInt(3, 9);
    const edgeWidth = MCRandom.randomInt(1, 4);
    const bigCircleRadius = smallCircleRadius + edgeWidth;

    const bigCircleArea = bigCircleRadius * bigCircleRadius;
    const smallCircleArea = smallCircleRadius * smallCircleRadius;

    const shadedArea = bigCircleArea - smallCircleArea;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` There are 2 circles, an inner circle with diameter $${
          2 * smallCircleRadius
        }cm$ in the centre of an encompassing circle.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The width from the edge of the inner circle to the edge of the encompassing circle is $${edgeWidth}cm$`,
        false
      )
    );
    const img = new MCQuestion.Image(
      "GCSE/Perimeter_and_area/CircleInCircle1.jpg",
      "height",
      15
    );

    img.addOverlay(`$${2 * smallCircleRadius}cm$`, 41, 41);
    img.addOverlay(`$${edgeWidth}cm$`, 68, 15);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " What is the area of the shaded part of the encompassing circle",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Give your answer in the form of $x\\pi$", false)
    );
    question.addImage("question", img);
    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Area of encompassing circle $= \\pi\\left(\\frac{${
          2 * smallCircleRadius
        }}{2} + ${edgeWidth}\\right)^2$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${bigCircleArea}\\pi$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Area of inner circle $= \\pi\\left(\\frac{${
          2 * smallCircleRadius
        }}{2}\\right)^2$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${smallCircleArea}\\pi$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Shaded area $= ${bigCircleArea}\\pi - ${smallCircleArea}\\pi$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${shadedArea}\\pi$`, false)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(shadedArea, 0, "$x=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_PerimeterAndSurfaceArea_Exam_Q6() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const height = MCRandom.randomInt(3, 20);
    const width = MCRandom.randomInt(3, 20);

    const perimeter = 2 * width + 2 * height;
    const area = width * height;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A rectangle has height $${height}cm$ and width $${width}cm$. What is the $(a)$ the perimeter and $(b)$ the area?`,
        false
      )
    );
    const img = new MCQuestion.Image(
      "GCSE/Perimeter_and_area/Rectangle1.jpg",
      "height",
      15
    );
    img.addOverlay(`$${height}cm$`, 25, 50);
    img.addOverlay(`$${width}cm$`, 50, 7);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `  $(a)$ The perimeter = $${width} + ${width} + ${height} + ${height}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${perimeter}cm$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $(b)$ The area = $${width}  \\times ${height}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${area}cm^2$`, false)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(perimeter, 0, "$(a)$");
    group.addInput(area, 0, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (height <= width) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_PerimeterAndSurfaceArea_Exam_Q7() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const radius = MCRandom.randomInt(3, 20);

    const perimeter = Math.PI * (2 * radius);
    const area = Math.PI * (radius * radius);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A circle has radius $${radius}cm$. What is the $(a)$ the perimeter and $(b)$ the area of the circle?`,
        false
      )
    );
    question.addHeading("question", MCMaths.cleaner(" Given that:", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Perimeter $= \\pi d$", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Area $= \\pi r^2$", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Give your answers to $3$ significant figures.", false)
    );
    const img = new MCQuestion.Image(
      "GCSE/Perimeter_and_area/Circle1.jpg",
      "height",
      15
    );
    img.addOverlay(`$${radius}cm$`, 75, 45);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `  $(a)$ Perimeter $= \\pi (2  \\times ${radius}) $`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${perimeter.toPrecision(3)}cm$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`  $(b)$ Area $= \\pi (${radius}^2) $`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${area.toPrecision(3)}cm^2$`, false)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(perimeter, 0.5, "$(a)$");
    group.addInput(area, 0.5, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (area >= 1000) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_PerimeterAndSurfaceArea_Exam_Q8() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const height = MCRandom.randomInt(3, 20);
    const width = MCRandom.randomInt(3, 20);

    const triangleBase = MCRandom.randomInt(1, 7);
    const triangleHyp = Math.sqrt(
      height * height + triangleBase * triangleBase
    );

    const b = 2 * triangleBase + width;

    const area = 0.5 * (b + width) * height;
    const perimeter = 2 * triangleBase + 2 * width + 2 * triangleHyp;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A trapezium is made up of a rectangle of height $${height}cm$ and width $${width}cm$, and 2 congruent triangles that have a base width of $${triangleBase}cm$. What is the $(a)$ the area and $(b)$ the perimeter of the trapezium?`,
        false
      )
    );
    question.addHeading("question", MCMaths.cleaner(" Given that:", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Area $={1 \\over 2} (a + b) h$", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Give your answers to $3$ significant figures. ", false)
    );
    const img = new MCQuestion.Image(
      "GCSE/Perimeter_and_area/Trapezium1.jpg",
      "height",
      15
    );

    img.addOverlay(`$${height}cm$`, 35, 50);
    img.addOverlay(`$${width}cm$`, 50, 92);
    img.addOverlay(`$${triangleBase}cm$`, 15, 92);
    question.addImage("question", img);
    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `  $(a)$: Area $= {1 \\over 2}  \\times ((${triangleBase} + ${width} + ${triangleBase}) + ${width})  \\times ${height}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${area.toPrecision(3)}cm$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `  $(b)$: Perimeter $= (2  \\times ${triangleBase}) + (2  \\times ${width}) + (2  \\times \\sqrt {${triangleBase}^2 + ${height}^2})$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${perimeter.toPrecision(3)}cm^2$`, false)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(area, 0.5, "$(a)$");
    group.addInput(perimeter, 0.5, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (triangleBase > width || triangleBase > height) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Mac_PerimeterAndSurfaceArea_Exam_Q9() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const side = MCRandom.randomInt(2, 10);
    const height = MCRandom.randomInt(4, 20);

    const smallTriangleBase =
      0.5 * Math.sqrt(height * height + 0.25 * (side * side));
    const smallTriangleHeight = Math.sqrt(
      (15 / 16) * (side * side) - 0.25 * (height * height)
    );

    const perimeter = 5 * side;

    const smallTriangleArea = 0.5 * (smallTriangleBase * smallTriangleHeight);
    const bigTriangleArea = 0.5 * ((side / 2) * height);

    const area = 4 * smallTriangleArea + 2 * bigTriangleArea;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A regular pentagon has a side length of $${side}cm$ and height $${height}cm$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " $(a)$ What is the perimeter of the regular pentagon?",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " $(b)$ What is the area of the regular pentagon, to $3$ significant figures?",
        false
      )
    );
    const img = new MCQuestion.Image(
      "GCSE/Perimeter_and_area/Pentagon1.jpg",
      "height",
      15
    );
    img.addOverlay(`$${side}cm$`, 0, 70);
    img.addOverlay(`$${height}cm$`, 39, 85);
    question.addImage("question", img);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $(a)$ Perimeter $= 5  \\times ${side}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${perimeter}cm$`, false)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " $(b)$ Total area $= (4  \\times$(area of small triangle)$)$ $+ (2  \\times$(area of big triangle)$)$",
        false
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Area of big triangle $= {1 \\over 2}(({1 \\over 2}  \\times ${side})  \\times ${height})$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${bigTriangleArea}cm$`, false)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Area of small triangle $= {1 \\over 2}(base  \\times height)$",
        false
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Small triangle base $= {1 \\over 2} \\sqrt{${height}^2 + ({1 \\over 2}  \\times ${side})^2}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${smallTriangleBase.toPrecision(3)}$`, false)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Small triangle height $= \\sqrt{${side}^2 - ${smallTriangleBase.toPrecision(
          3
        )}^2}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${smallTriangleHeight.toPrecision(3)}$`, false)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Area of small triangle $= {1 \\over 2}(${smallTriangleBase.toPrecision(
          3
        )}  \\times ${smallTriangleHeight.toPrecision(3)})$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${smallTriangleArea.toPrecision(3)}cm$`, false)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Total area $= ((4  \\times ${smallTriangleArea.toPrecision(
          3
        )}) + (2  \\times ${bigTriangleArea.toPrecision(3)}))$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${area.toPrecision(3)}cm^2$`, false)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(perimeter, 0.5, "$(a)$");
    group.addInput(area, 0.5, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (side >= height || smallTriangleBase >= side) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Pac_Angles_Exam_Q1() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const type = MCRandom.randomInt(1, 3);
    let ans = 0;
    const question = new MCQuestion.ExamQuestion();
    if (type === 1) {
      const bigA = MCRandom.randomInt(100, 140);
      const smallA = 180 - bigA;
      const mode = MCRandom.randomInt(0, 2);

      question.addParagraph("question", MCMaths.cleaner("", false));
      question.addParagraph(
        "question",
        MCMaths.cleaner("Find angle $b$. (Diagram is not to scale)", false)
      );
      const img = new MCQuestion.Image(
        "GCSE/ParallelLines/1.jpg",
        "height",
        15
      );
      img.addOverlay(MCMaths.cleaner(`$${bigA}^\\circ$`), 65, 30);
      if (mode === 0) {
        img.addOverlay("$b$", 45, 65);
      }
      if (mode === 1) {
        img.addOverlay("$b$", 33, 65);
      }
      if (mode === 2) {
        img.addOverlay("$b$", 37, 79);
      }

      question.addParagraph("solution", MCMaths.cleaner("", false));
      if (mode === 0) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$b=180^\\circ-${bigA}^\\circ=${smallA}^\\circ$, as they are co-interior angles.`,
            false
          )
        );
        ans = smallA;
      }
      if (mode === 1) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$b=${bigA}^\\circ$, as they are alternate angles.`,
            false
          )
        );
        ans = bigA;
      }
      if (mode === 2) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$b=${bigA}^\\circ$, as they are corresponding angles.`,
            false
          )
        );
        ans = bigA;
      }
      question.addImage("question", img);
    }
    if (type === 2) {
      const bigA = MCRandom.randomInt(100, 140);
      const smallA = 180 - bigA;
      const mode = MCRandom.randomInt(0, 2);

      question.addParagraph("question", MCMaths.cleaner("", false));
      question.addParagraph(
        "question",
        MCMaths.cleaner("Find angle $b$. (Diagram is not to scale)", false)
      );
      const img = new MCQuestion.Image(
        "GCSE/ParallelLines/2.jpg",
        "height",
        15
      );
      img.addOverlay(MCMaths.cleaner(`$${bigA}^\\circ$`), 35, 28);
      if (mode === 0) {
        img.addOverlay("$b$", 50, 65);
      }
      if (mode === 1) {
        img.addOverlay("$b$", 61, 65);
      }
      if (mode === 2) {
        img.addOverlay("$b$", 57, 78);
      }

      question.addParagraph("solution", MCMaths.cleaner("", false));
      if (mode === 0) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$b=180^\\circ-${bigA}^\\circ=${smallA}^\\circ$, as they are co-interior angles.`,
            false
          )
        );
        ans = smallA;
      }
      if (mode === 1) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$b=${bigA}^\\circ$, as they are alternate angles.`,
            false
          )
        );
        ans = bigA;
      }
      if (mode === 2) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$b=${bigA}^\\circ$, as they are corresponding angles.`,
            false
          )
        );
        ans = bigA;
      }
      question.addImage("question", img);
    }

    if (type === 3) {
      const bigA = MCRandom.randomInt(10, 40);
      const smallA = 180 - bigA;
      const mode = 2; // MCRandom.randomInt(0,2)

      question.addParagraph("question", MCMaths.cleaner("", false));
      question.addParagraph(
        "question",
        MCMaths.cleaner("Find angle $b$. (Diagram is not to scale)", false)
      );
      const img = new MCQuestion.Image(
        "GCSE/ParallelLines/4.jpg",
        "height",
        15
      );
      img.addOverlay(MCMaths.cleaner(`$${bigA}^\\circ$`), 43, 65);
      if (mode === 0) {
        img.addOverlay("$b$", 78, 30);
      }
      if (mode === 1) {
        img.addOverlay("$b$", 62, 30);
      }
      if (mode === 2) {
        img.addOverlay("$b$", 87, 17);
      }

      question.addParagraph("solution", MCMaths.cleaner("", false));
      if (mode === 0) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$b=180^\\circ-${bigA}^\\circ=${smallA}^\\circ$, as they are co-interior angles.`,
            false
          )
        );
        ans = smallA;
      }
      if (mode === 1) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$b=${bigA}^\\circ$, as they are alternate angles.`,
            false
          )
        );
        ans = bigA;
      }
      if (mode === 2) {
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$b=${bigA}^\\circ$, as they are corresponding angles.`,
            false
          )
        );
        ans = bigA;
      }
      question.addImage("question", img);
    }

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0, "$b=$");
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Pac_Angles_Exam_Q2() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const type = MCRandom.randomInt(1, 4);

    const bigA = MCRandom.randomInt(100, 140);
    const smallA = 180 - bigA;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find angles $a$, $b$ and $c$ (Diagram is not to scale).",
        false
      )
    );
    const img = new MCQuestion.Image(
      "GCSE/ParallelLines/TripleParrallel.jpg",
      "height",
      15
    );
    img.addOverlay(`$${bigA}^\\circ$`, 68, 28);
    img.addOverlay("$a$", 54, 43);
    img.addOverlay("$b$", 25, 69);
    img.addOverlay("$c$", 31, 79);

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$a=180^\\circ-${bigA}^\\circ=${smallA}^\\circ$, as they are co-interior angles.`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$b=${bigA}^\\circ$, as they are alternate angles.`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$c=${bigA}^\\circ$, as they are corresponding angles.`,
        false
      )
    );

    question.addImage("question", img);

    const group = new MCQuestion.InputGroup(3);
    group.addInput(smallA, 0, "$a=$");
    group.addInput(bigA, 0, "$b=$");
    group.addInput(bigA, 0, "$c=$");
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Pac_Angles_Exam_Q3() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const bigA = MCRandom.randomInt(30, 60);
    const smallA = 180 - bigA;
    const mode = 1; // MCRandom.randomInt(0,1)
    const new2 = MCRandom.randomInt(50, 80);
    const ans = 180 - bigA - new2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find angles $x$ and $y$ (Diagram is not to scale).",
        false
      )
    );

    const img = new MCQuestion.Image("GCSE/ParallelLines/10.jpg", "height", 15);

    if (mode === 0) {
      img.addOverlay(`$${bigA}^\\circ$`, 49, 25);
      img.addOverlay("$x$", 53, 52);
      img.addOverlay("$y$", 78, 22);
      img.addOverlay(`$${new2}^\\circ$`, 59, 75);
    }
    if (mode === 1) {
      img.addOverlay(`$${bigA}^\\circ$`, 36, 20);
      img.addOverlay("$x$", 53, 52);
      img.addOverlay("$y$", 78, 22);
      img.addOverlay(`$${new2}^\\circ$`, 59, 75);
    }

    if (mode === 0) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x=${bigA}^\\circ$, as they are corresponding angles.`)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$y=180^\\circ-${bigA}^\\circ-${new2}^\\circ=${ans}^\\circ$, as angles in a triangle add to $180^\\circ$.`
        )
      );
    }
    if (mode === 1) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\cdot x=${bigA}^\\circ$, as they are alternate angles.`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\cdot y=180^\\circ-${bigA}^\\circ-${new2}^\\circ=${ans}^\\circ$, as angles in a triangle add to $180^\\circ$.`
        )
      );
    }

    question.addImage("question", img);

    const group = new MCQuestion.InputGroup(2);
    group.addInput(bigA, 0, "$x=$");
    group.addInput(ans, 0, "$y=$");
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Pac_Angles_Exam_Q4() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const bigA = MCRandom.randomInt(30, 60);
    const smallA = 180 - bigA;
    const mode = 1; // MCRandom.randomInt(0,1)
    const new2 = MCRandom.randomInt(50, 80);
    const ans = 180 - bigA - new2;

    const img = new MCQuestion.Image("GCSE/ParallelLines/8.jpg", "height", 15);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find angles $x$ and $y$ (Diagram is not to scale).",
        false
      )
    );

    if (mode === 0) {
      img.addOverlay(`$${bigA}^\\circ$`, 34, 75);
      img.addOverlay("$x$", 49, 44);
      img.addOverlay("$y$", 66, 13);
      img.addOverlay(`$${new2}^\\circ$`, 81, 44);
    }
    if (mode === 1) {
      img.addOverlay(`$${bigA}^\\circ$`, 12, 90);
      img.addOverlay("$x$", 49, 44);
      img.addOverlay("$y$", 66, 13);
      img.addOverlay(`$${new2}^\\circ$`, 81, 44);
    }

    if (mode === 0) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\cdot x=${bigA}^\\circ$, as they are corresponding angles.`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\cdot y=180^\\circ-${bigA}^\\circ-${new2}^\\circ=${ans}^\\circ$, as angles in a triangle add to $180^\\circ$.`
        )
      );
    }

    if (mode === 1) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\cdot x=${bigA}^\\circ$, as they are alternate angles.`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\cdot y=180^\\circ-${bigA}^\\circ-${new2}^\\circ=${ans}^\\circ$, as angles in a triangle add to $180^\\circ$.`
        )
      );
    }
    question.addImage("question", img);

    const group = new MCQuestion.InputGroup(2);
    group.addInput(bigA, 0, "$x=$");
    group.addInput(ans, 0, "$y=$");
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Pac_Angles_Exam_Q5() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const bigA = MCRandom.randomInt(30, 60);
    const smallA = 180 - bigA;
    const mode = 1; // MCRandom.randomInt(0,1)
    const new2 = MCRandom.randomInt(50, 80);
    const ans = 180 - bigA - new2;

    const img = new MCQuestion.Image("GCSE/ParallelLines/9.jpg", "height", 15);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find angles $x$ and $y$ (Diagram is not to scale).",
        false
      )
    );

    if (mode === 0) {
      img.addOverlay(`$${bigA}^\\circ$`, 25, 23);
      img.addOverlay("$x$", 58, 74);
      img.addOverlay("$y$", 25, 74);
      img.addOverlay(`$${new2}^\\circ$`, 38, 44);
    }
    if (mode === 1) {
      img.addOverlay(`$${bigA}^\\circ$`, 48, 37);
      img.addOverlay("$x$", 58, 74);
      img.addOverlay("$y$", 25, 74);
      img.addOverlay(`$${new2}^\\circ$`, 38, 44);
    }

    if (mode === 0) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\cdot x=${bigA}^\\circ$, as they are corresponding angles.`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\cdot y=180^\\circ-${bigA}^\\circ-${new2}^\\circ=${ans}^\\circ$, as angles in a triangle add to $180^\\circ$.`
        )
      );
    }

    if (mode === 1) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\cdot x=${bigA}^\\circ$, as they are alternate angles.`
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\cdot y=180^\\circ-${bigA}^\\circ-${new2}^\\circ=${ans}^\\circ$, as angles in a triangle add to $180^\\circ$.`
        )
      );
    }
    question.addImage("question", img);

    const group = new MCQuestion.InputGroup(2);
    group.addInput(bigA, 0, "$x=$");
    group.addInput(ans, 0, "$y=$");
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Geometry_Pac_GeometricalProblems_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name = MCMisc.getName();
    const x1 = MCRandom.randomInt(1, 18);
    const y1 = MCRandom.randomInt(1, 18);
    const x2 = MCRandom.randomInt(1, 18);
    const y2 = MCRandom.randomInt(1, 18);
    const co1 = `(${x1}, ${y1})`;
    const co2 = `(${x2}, ${y2})`;
    const x = (x1 + x2) / 2;
    const y = (y1 + y2) / 2;
    const co = `(${x}, ${y})`;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} wants to open a shop in the town centre.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} has a map of the town centre, the map is split up into square sections and has a set of coordinate axes.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Two successful shops already exist in the town centre at $${co1}$ and $${co2}$ respectively.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has been advised to open the shop in the middle of the two existing shops for maximum profit.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Where should ${name.name} try and open ${name.hisher} shop? Give your answer in the form $(a,b)$.`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$x$-coordinate = $(x_1 + x_2) \\over 2$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x_1 = ${x1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x_2 = ${x2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ x-coordinate = \${(${x1} + ${x2}) \\over 2} = ${x}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$y$-coordinate = $(y_1 + y_2) \\over 2$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$y_1 = ${y1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$y_2 = ${y2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ y-coordinate = \${(${y1} + ${y2}) \\over 2} = ${y}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ ${name.name} should try and open ${name.hisher} shop at $= ${co}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(x, 0, "$a=$");
    group.addInput(y, 0, "$b=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      x1 === y1 ||
      x1 === x2 ||
      x1 === y2 ||
      y1 === x2 ||
      y1 === y2 ||
      x2 === y2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Geometry_V_Vectors_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const a1 = MCRandom.randomInt(-9, 9);
    const a2 = MCRandom.randomInt(-9, 9);
    const b1 = MCRandom.randomInt(-9, 9);
    const b2 = MCRandom.randomInt(-9, 9);
    const c1 = MCRandom.randomInt(-9, 9);
    const c2 = MCRandom.randomInt(-9, 9);
    const scalar1 = MCRandom.randomInt(2, 8);
    const scalar2 = MCRandom.randomInt(-12, 12);
    const scalar3 = MCRandom.randomInt(-12, 12);
    let scalar2ForWorking = scalar2;
    let scalar3ForWorking = scalar3;
    let sign2 = 1;
    let sign3 = 1;
    if (scalar2 < 0) {
      scalar2ForWorking = scalar2 * -1;
      sign2 = -1;
    }
    if (scalar3 < 0) {
      scalar3ForWorking = scalar3 * -1;
      sign3 = -1;
    }
    const questionB = `${scalar2}\\textbf{b}+${scalar3}\\textbf{c}`;
    const questionBWorking = `${sign2}\\begin{pmatrix}${
      b1 * scalar2ForWorking
    }\\\\${b2 * scalar2ForWorking}\\end{pmatrix}+${sign3}\\begin{pmatrix}${
      c1 * scalar3ForWorking
    }\\\\${c2 * scalar3ForWorking}\\end{pmatrix}`;

    const vectorB1 = scalar2 * b1;
    const vectorB2 = scalar2 * b2;
    const vectorC1 = scalar3 * c1;
    const vectorC2 = scalar3 * c2;

    const finalVector1 = vectorB1 + vectorC1;
    const finalVector2 = vectorB2 + vectorC2;

    const finalVector = `\\begin{pmatrix}${finalVector1} \\\\ ${finalVector2}\\end{pmatrix}`;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The vectors $\\textbf{a}$, $\\textbf{b}$ and $\\textbf{c}$ are defined as such:",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$\\textbf{a}$ = $\\begin{pmatrix}${a1} \\\\ ${a2}\\end{pmatrix}$ $\\textbf{b}$ = $\\begin{pmatrix}${b1} \\\\ ${b2}\\end{pmatrix}$ $\\textbf{c}$ = $\\begin{pmatrix}${c1} \\\\ ${c2}\\end{pmatrix}$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Write, as a column vector, $${scalar1}\\textbf{a}$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b)$ Write, as a column vector, $${questionB}$.`, false)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a) \\ ${scalar1}\\textbf{a}$ = $\\begin{pmatrix}${
          a1 * scalar1
        } \\\\ ${a2 * scalar1}\\end{pmatrix}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$ (b) \\ ${scalar2ForWorking}\\textbf{b}$ = $\\begin{pmatrix}${
          b1 * scalar2ForWorking
        } \\\\ ${b2 * scalar2ForWorking}\\end{pmatrix}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${scalar3ForWorking}\\textbf{c}$ = $\\begin{pmatrix}${
          c1 * scalar3ForWorking
        } \\\\ ${c2 * scalar3ForWorking}\\end{pmatrix}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${questionB}$ = $${questionBWorking}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${questionB}$ = $${finalVector}$`, false)
    );

    question.requiresSelfMarking();

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      a1 === a2 ||
      a1 === b1 ||
      a1 === c1 ||
      b1 === b2 ||
      c1 === c2 ||
      a2 === b2 ||
      a2 === c2 ||
      b2 === c2 ||
      b1 === c1 ||
      scalar1 === scalar2 ||
      scalar1 === scalar3 ||
      scalar2 === scalar3 ||
      scalar2 === 0 ||
      scalar3 === 0 ||
      (scalar2 < 0 && scalar3 < 0) ||
      c1 === c2 ||
      b1 === b2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


// Write as a percentage
export function GCSE_Foundation_Number_Fdap_ConvertingFractions_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const num1 = MCRandom.randomInt(1, 100) / 100;
    const fraction2 = MCRandom.randomInt(1, 119) / 120;
    const fraction3 = MCRandom.randomInt(1, 119) / 120;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("Write as percentages:", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a) \\ ${num1}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b) \\ ${fraction2}$`, true, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(c) \\ ${fraction3}$`, true, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${num1 * 100}\\% $`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${fraction2 * 100}\\% $`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ ${fraction3 * 100}\\% $`, false, true, true)
    );
    const group = new MCQuestion.InputGroup(1);
    group.addInput(num1 * 100, 0.1, "$(a)$");
    group.addInput(fraction2 * 100, 0.1, "$(b)$");
    group.addInput(fraction3 * 100, 0.1, "$(c)$");
    question.addInputGroup(group);

    if (
      fraction2 * 10000 !== Math.round(fraction2 * 10000) ||
      fraction3 * 10000 !== Math.round(fraction3 * 10000) ||
      fraction2 === fraction3 ||
      num1 === fraction2 ||
      num1 === fraction3 ||
      fraction2 === 0 ||
      fraction2 === 1 ||
      fraction3 === 0 ||
      fraction3 === 1 ||
      fraction2.denominator === 10 ||
      fraction3.denominator === 10 ||
      fraction2.denominator === 2 ||
      fraction3.denominator === 2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */

// JP2018


// Write as a decimal
export function GCSE_Foundation_Number_Fdap_ConvertingFractions_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const num1 = MCRandom.randomInt(1, 100) / 100;
    const fraction2 = MCRandom.randomInt(1, 119) / 120;
    const fraction3 = MCRandom.randomInt(1, 119) / 120;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Write as decimals, to $3$ decimal places where necessary:",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a) \\ ${num1 * 100}\\% $`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b) \\ ${fraction2}$`, true, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(c) \\ ${fraction3}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${num1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${fraction2.toPrecision(3)}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ ${fraction3.toPrecision(3)}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(num1, 0.01, "$(a)$");
    group.addInput(fraction2, 0.01, "$(b)$");
    group.addInput(fraction3, 0.01, "$(c)$");
    question.addInputGroup(group);

    if (
      (fraction2 * 1000 === Math.round(1000 * fraction2) &&
        fraction2 * 1000 === Math.round(1000 * fraction2)) ||
      fraction2 === fraction3 ||
      num1 === fraction2 ||
      num1 === fraction3 ||
      fraction2 === 0 ||
      fraction2 === 1 ||
      fraction3 === 0 ||
      fraction3 === 1
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */

// JP2018


// Write as a fraction
export function GCSE_Foundation_Number_Fdap_ConvertingFractions_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const num1 = MCRandom.randomInt(3, 99) / 100;
    const n = MCRandom.randomInt(1, 49);
    const d = MCRandom.randomInt(1, 49) + n;
    const fraction2 = n / d;
    const multi2 = MCRandom.randomInt(3, 9);
    const num3 = MCRandom.randomInt(3, 99) / 100;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("Write as simplified fractions:", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a) \\ ${num1}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b) \\ \\frac{${n * multi2}}{${d * multi2}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(c) \\ ${100 * num3}\\% $`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${num1}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${fraction2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ ${num3}$`, true, true, true)
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(fraction2, 0.01, "$(b)$");
    question.addInputGroup(group);

    if (
      fraction2 === 0 ||
      fraction2 === 1 ||
      num1 === num3 ||
      Math.round(100 * fraction2) !== 100 * fraction2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */

// JP2018


export function GCSE_Foundation_Number_Fdap_ConvertingFractions_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const num1 = MCRandom.randomInt(1, 119) / 120;
    const num2 = MCRandom.randomInt(1, 119) / 120;
    const num3 = MCRandom.randomInt(1, 119) / 120;
    const num4 = MCRandom.randomInt(1, 119) / 120;

    let display1;
    let display2;
    let display3;
    let display4;

    const mode = MCRandom.randomInt(0, 2);
    if (mode === 0) {
      display1 = num1;
      display2 = `${100 * num2}\\% `;
      display3 = num3;
    }
    if (mode === 1) {
      display2 = num2;
      display3 = `${100 * num3}\\% `;
      display1 = num1;
    }
    if (mode === 2) {
      display3 = num3;
      display1 = `${100 * num1}\\% `;
      display2 = num2;
    }

    const mode2 = MCRandom.randomInt(0, 2);
    if (mode2 === 0) {
      display4 = num4;
    }
    if (mode2 === 1) {
      display4 = `${100 * num4} \\% `;
    }
    if (mode2 === 2) {
      display4 = num4;
    }

    const average = (num1 + num2 + num3 + num4) / 4;
    const dista = Math.abs(num1 / average - 1);
    const distb = Math.abs(num2 / average - 1);
    const distc = Math.abs(num3 / average - 1);
    const distd = Math.abs(num4 / average - 1);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("Which of these is the largest:", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${display1},\\ ${display2},\\ ${display3},\\ ${display4}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${display1}=${num1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${display2}=${num2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${display3}=${num3}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${display4}=${num4}$`, false, true, true)
    );
    if (Math.max(num1, num2, num3, num4) === num1) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(` So, $${display1}$ is the largest.`, false, true, true)
      );
    }
    if (Math.max(num1, num2, num3, num4) === num2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(` So, $${display2}$ is the largest.`, false, true, true)
      );
    }
    if (Math.max(num1, num2, num3, num4) === num3) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(` So, $${display3}$ is the largest.`, false, true, true)
      );
    }
    if (Math.max(num1, num2, num3, num4) === num4) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(` So, $${display4}$ is the largest.`, false, true, true)
      );
    }

    question.requiresSelfMarking();

    if (
      dista >= 0.4 ||
      distb >= 0.4 ||
      distc >= 0.4 ||
      distd >= 0.4 ||
      Math.round(num1 * 100000) !== num1 * 100000 ||
      Math.round(num2 * 100000) !== num2 * 100000 ||
      Math.round(num3 * 100000) !== num3 * 100000 ||
      Math.round(num4 * 100000) !== num4 * 100000 ||
      num1 === 1 ||
      num2 === 1 ||
      num3 === 1 ||
      num4 === 1 ||
      num1 === num2 ||
      num1 === num3 ||
      num1 === num4 ||
      num2 === num3 ||
      num2 === num4 ||
      num3 === num4
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */

// JP2018


export function GCSE_Foundation_Number_Fdap_ConvertingFractions_Exam_Q5() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const offer1 = MCRandom.randomInt(1, 119) / 120;
    const offer2 = MCRandom.randomInt(1, 119) / 120;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("On a certain item, there are two offers:", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${offer1}$ off,`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`or $${100 * offer2}\\% $ off.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Which offer is better?", false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${offer1}=${offer1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${100 * offer2}\\% =${offer2}$`, false, true, true)
    );
    if (offer1 >= offer2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner("So, the first offer is better.", false, true, true)
      );
    }
    if (offer2 >= offer1) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner("So, the seconds offer is better.", false, true, true)
      );
    }

    question.requiresSelfMarking();

    if (
      Math.abs(offer1 / offer2 - 1) >= 0.1 ||
      offer1 >= 0.4 ||
      offer2 >= 0.4 ||
      Math.round(offer2 * 10000) !== offer2 * 10000 ||
      offer1 === offer2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Fdap_ConvertingFractions_Exam_Q6() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const money = MCRandom.randomInt(1000, 6000, 10);
    const fraction1 = MCRandom.randomInt(1, 119) / 120;
    const price1 = money * fraction1;

    const price2 = money - price1 - ((money - price1) % 100);

    const change = money - price1 - price2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} is at a record shop, and has $\\pounds ${(
          money / 100
        ).toPrecision(4)}$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The album ${name.heshe} wants costs $\\pounds ${(
          price1 / 100
        ).toPrecision(4)}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ As a simplified fraction of ${name.hisher} total money, how much does the album cost?`,
        false,
        true,
        true
      )
    );

    if (Math.round(fraction1 * 1000) !== fraction1 * 1000) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          "$(b)$ Write this fraction as a percentage, to $3$ $sf$.",
          false,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          "$(b)$ Write this fraction as a percentage.",
          false,
          true,
          true
        )
      );
    }

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A single ${name.name} also wants costs $\\pounds ${price2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ How much change, in pounds, will ${name.name} have left if ${name.heshe} buys both the album and single?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a) \\ {${price1}\\over${money}}=${fraction1}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ ${fraction1}=${fraction1 * 100}\\%$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(c) \\ ${money / 100}-${price1 / 100}-${price2 / 100}=\\pounds${(
          change / 100
        ).toPrecision(3)}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(fraction1 * 100, 0.1, "$(b)$");
    group.addInput(change / 100, 0.01, "$(c)$");
    question.addInputGroup(group);

    if (
      fraction1 === 0 ||
      fraction1 === 0.5 ||
      fraction1 === 1 ||
      fraction1.denominator <= 6 ||
      price1 >= 2000 ||
      price1 <= 1000 ||
      price2 <= 200 ||
      price2 >= 500 ||
      change <= 0 ||
      Math.round(fraction1 * 100) !== fraction1 * 100 ||
      Math.round(price1) !== price1
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Fdap_ConvertingFractions_Exam_Q7() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    let fraction1 = MCRandom.randomInt(1, 119) / 120;
    let total = MCRandom.randomInt(40, 200);

    let red = fraction1 * total;

    while (total - Math.round(red) < 5) {
      fraction1 = MCRandom.randomInt(1, 119) / 120;
      total = MCRandom.randomInt(40, 200);

      red = fraction1 * total;
    }

    const green = MCRandom.randomInt(5, total - Math.round(red));
    const blue = total - red - green;

    const fraction2 = green / total;
    const fraction3 = blue / total;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`In a box of sweets there are $${total}$ sweets.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${fraction1}$ are red,`, true, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${fraction2}$ are green,`, true, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("the rest are blue.", false, true, true)
    );

    if (Math.round(fraction1 * 1000) !== fraction1 * 1000) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          "$(a)$ Write the fraction of red sweets as a percentage, to $3$ $sf$.",
          false,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          "$(a)$ Write the fraction of red sweets as a percentage.",
          false,
          true,
          true
        )
      );
    }
    if (Math.round(fraction2 * 1000) !== fraction2 * 1000) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          "$(b)$ Write the fraction of green sweets as a decimal, to $3$ $sf$.",
          false,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          "$(b)$ Write the fraction of green sweets as a decimal.",
          false,
          true,
          true
        )
      );
    }

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(c)$ What fraction of the sweets are blue?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a) \\ ${(fraction1 * 100).toPrecision(3)}\\%$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${fraction2.toPrecision(3)}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(c) \\ ${total} \\times${fraction1}=${red}$ sweets are red.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${total} \\times${fraction2}=${green}$ sweets are green.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${total}-${red}-${green}=${blue}$ sweets are blue.`,
        true,
        true,
        true
      )
    );

    if (fraction3.denominator === total) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `So the fraction of blue sweets $=${fraction3}$`,
          true,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `So the fraction of blue sweets $={${blue}\\over${total}}=${fraction3}$`,
          true,
          true,
          true
        )
      );
    }

    question.requiresSelfMarking();

    const group = new MCQuestion.InputGroup(2);
    group.addInput(fraction1 * 100, 1, "$(a)$");
    group.addInput(fraction2, 0.1, "$(b)$");
    question.addInputGroup(group);

    if (Math.round(red) !== red || blue <= 5) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Fdap_ConvertingFractions_Exam_Q8() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();

    const time1 = MCRandom.randomInt(270, 370) / 10;
    const reduction1 = MCRandom.randomInt(900, 980) / 1000;
    const fraction1 = reduction1;
    const time2 = time1 * reduction1;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.name} is part of a swimming club.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} usually swims 50m freestyle in $${time1}s$, but has been training, and is now faster.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HisHer} new time is $${reduction1}$ of ${name.hisher} old time.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ What is ${name.hisher} new time?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ What is $${reduction1}$ as a simplified fraction?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ What is $${reduction1}$ as a percentage?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a) \\ ${time1}s \\times${reduction1}=${time2}s$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ {${1000 * reduction1}\\over1000}=${fraction1}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ ${100 * reduction1}\\% $`, false, true, true)
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(2);
    group.addInput(time2, 0.1, "$(a)$");
    group.addInput(100 * reduction1, 0.1, "$(c)$");
    question.addInputGroup(group);

    if (fraction1.denominator >= 200 || fraction1.denominator <= 11) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Fdap_ConvertingFractions_Exam_Q9() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    function gcd(x2, y2) {
      let x = Math.abs(x2);
      let y = Math.abs(y2);
      while (y) {
        const t = y;
        y = x % y;
        x = t;
      }
      return x;
    }

    function LCM(x, y) {
      return !x || !y ? 0 : Math.abs((x * y) / gcd(x, y));
    }

    const an = MCRandom.randomInt(1, 20);
    const bn = MCRandom.randomInt(1, 20);
    const cn = MCRandom.randomInt(1, 20);
    const dn = MCRandom.randomInt(1, 20);

    const ad = MCRandom.randomInt(an, 30);
    const bd = MCRandom.randomInt(bn, 30);
    const cd = MCRandom.randomInt(cn, 30);
    const dd = MCRandom.randomInt(dn, 30);

    const a = an / ad;
    const b = bn / bd;
    const c = cn / cd;
    const d = dn / dd;

    const LCM1 = LCM(ad, bd);
    const LCM2 = LCM(bd, cd);
    const LCM3 = LCM(cd, dd);

    const LCM4 = LCM(LCM1, LCM2);
    const LCM5 = LCM(LCM2, LCM3);

    const LCM6 = LCM(LCM4, LCM5);

    const acom = `{${an * (LCM6 / ad)}\\over${LCM6}}`;
    const bcom = `{${bn * (LCM6 / bd)}\\over${LCM6}}`;
    const ccom = `{${cn * (LCM6 / cd)}\\over${LCM6}}`;
    const dcom = `{${dn * (LCM6 / dd)}\\over${LCM6}}`;

    const all = [a, b, c, d];
    all.sort();

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("Order these fractions from smallest to largest:", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${a}, \\ ${b}, \\ ${c}, \\ ${d}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Multiply each fraction to set the denominators to the LCM of the denominators: $${acom}$$${bcom}$$${ccom}$$${dcom}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Order the fractions: $${all[0]}, \\ ${all[1]}, \\ ${all[2]}, \\ ${all[3]}$.`,
        true,
        true,
        true
      )
    );

    question.requiresSelfMarking();

    if (
      ad === bd ||
      ad === cd ||
      ad === dd ||
      bd === cd ||
      bd === dd ||
      cd === dd ||
      Math.abs(a - b) >= 0.2 ||
      Math.abs(b - c) >= 0.2 ||
      Math.abs(c - d) >= 0.2 ||
      Math.abs(d - a) >= 0.2 ||
      LCM6 > 300
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q1() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const totalCount = MCRandom.randomInt(500, 2500, 100);
    const offRoad = MCRandom.randomInt(200, totalCount - 200);
    const onRoad = totalCount - offRoad;

    const itemsList = ["bicycles", "motorcycles", "buggys"];
    const itemList = ["bicycle", "motorcycle", "buggy"];
    const itemType = MCRandom.randomInt(0, itemList.length - 1);
    const items = itemsList[itemType];
    const item = itemList[itemType];

    const red1 = MCRandom.randomInt(1, 119) / 120;
    const redCount1 = red1 * offRoad;
    const red2 = MCRandom.randomInt(1, 119) / 120;
    const redCount2 = red2 * onRoad;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A ${item} factory has $${totalCount}$ ${items} in stock.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${offRoad}$ of the ${items} are off-road models.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${red1}$ of the off-road ${items} are red.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${red2}$ of the on-road ${items} are red.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`How many ${items} are red?`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Work out how many red off-road ${items} there are: $${offRoad} \\times${red1}=${redCount1}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Work out how many red on-road ${items} there are: $(${totalCount}-${offRoad}) \\times${red2}=${redCount2}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So the total number of red ${items} $=${redCount1}+${redCount2}=${
          redCount1 + redCount2
        }$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(redCount1 + redCount2, 0);
    question.addInputGroup(group);

    if (
      red1 === 1 ||
      red2 === 1 ||
      red1 === 0 ||
      red2 === 0 ||
      Math.round(redCount1) !== redCount1 ||
      Math.round(redCount2) !== redCount2 ||
      red1 === red2 ||
      red1.denominator >= 50 ||
      red2.denominator >= 50
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\
// ||
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)

// JP2018


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q10() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const name1 = MCMisc.getName();
    const name2 = MCMisc.getName();

    const size1 = MCRandom.randomInt(100, 999);
    const size2 = MCRandom.randomInt(100, 999);

    const fraction1 = MCRandom.randomInt(1, 119) / 120;
    const fraction2 = MCRandom.randomInt(1, 119) / 120;

    const ans1 = fraction1 * size1;
    const ans2 = fraction2 * size2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} and ${name2.name} each have a cake.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} has $${fraction1}$ of  a $${size1}g$ cake.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name2.name} has $${fraction2}$ of  a $${size2}g$ cake.`,
        true,
        true,
        true
      )
    );

    question.addParagraph(
      "question",
      MCMaths.cleaner("Who has more cake?", false, true, true)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${name1.name} has $${fraction1} \\times${size1}=${ans1}g$ of cake.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${name2.name} has $${fraction2} \\times${size2}=${ans2}g$ of cake.`,
        true,
        true,
        true
      )
    );
    if (ans1 >= ans2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` So, ${name1.name} has the most cake.`,
          false,
          true,
          true
        )
      );
    }
    if (ans2 >= ans1) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` So, ${name2.name} has the most cake.`,
          false,
          true,
          true
        )
      );
    }

    question.requiresSelfMarking();

    if (
      ans1 === ans2 ||
      Math.abs(ans1 / ans2 - 1) >= 0.1 ||
      Math.round(ans1) !== ans1 ||
      Math.round(ans2) !== ans2 ||
      fraction1 === 1 ||
      fraction2 === 1 ||
      fraction1 === 0 ||
      fraction2 === 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\ (USE ALT (92))
// ||
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)

// JP2018


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q11() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const an = MCRandom.randomInt(1, 20);
    const bn = MCRandom.randomInt(1, 20);
    const cn = MCRandom.randomInt(1, 20);
    const dn = MCRandom.randomInt(1, 20);

    const a = an / MCRandom.randomInt(an, 21);
    const b = bn / MCRandom.randomInt(bn, 21);
    const c = cn / MCRandom.randomInt(cn, 21);
    const d = dn / MCRandom.randomInt(dn, 21);

    const a1 = MCRandom.randomInt(1, 1000);
    const b1 = MCRandom.randomInt(1, 1000);
    const c1 = MCRandom.randomInt(1, 1000);
    const d1 = MCRandom.randomInt(1, 1000);

    const a2 = a * a1;
    const b2 = b * b1;
    const c2 = c * c1;
    const d2 = d * d1;

    const average = (a2 + b2 + c2 + d2) / 4;
    const dista = Math.abs(a2 / average - 1);
    const distb = Math.abs(b2 / average - 1);
    const distc = Math.abs(c2 / average - 1);
    const distd = Math.abs(d2 / average - 1);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("Which of these is the largest:", true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${a} \\times${a1}, \\ ${b} \\times${b1}, \\ ${c} \\times${c1}, \\ ${d} \\times${d1}$`,
        true,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", true, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${a} \\times${a1}=${a2}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${b} \\times${b1}=${b2}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${c} \\times${c1}=${c2}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${d} \\times${d1}=${d2}$`, true, true, true)
    );
    if (Math.max(a2, b2, c2, d2) === a2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` So, $${a} \\times${a1}$ is the largest.`,
          true,
          true,
          true
        )
      );
    }
    if (Math.max(a2, b2, c2, d2) === b2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` So, $${b} \\times${b1}$ is the largest.`,
          true,
          true,
          true
        )
      );
    }
    if (Math.max(a2, b2, c2, d2) === c2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` So, $${c} \\times${c1}$ is the largest.`,
          true,
          true,
          true
        )
      );
    }
    if (Math.max(a2, b2, c2, d2) === d2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` So, $${d} \\times${d1}$ is the largest.`,
          true,
          true,
          true
        )
      );
    }

    question.requiresSelfMarking();

    if (
      Math.round(a2 * 10) !== a2 * 10 ||
      Math.round(b2 * 10) !== b2 * 10 ||
      Math.round(c2 * 10) !== c2 * 10 ||
      Math.round(d2 * 10) !== d2 * 10 ||
      a2 === b2 ||
      a2 === c2 ||
      a2 === d2 ||
      b2 === c2 ||
      b2 === d2 ||
      c2 === d2 ||
      dista >= 0.4 ||
      distb >= 0.4 ||
      distc >= 0.4 ||
      distd >= 0.4 ||
      a === 0 ||
      a === 1 ||
      b === 0 ||
      b === 1 ||
      c === 0 ||
      c === 1 ||
      d === 0 ||
      d === 1 ||
      a === b ||
      a === c ||
      a === d ||
      b === c ||
      b === d ||
      c === d
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// JP2018


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q12() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();

    const price = MCRandom.randomInt(20, 100);

    const price2 = MCRandom.randomInt(20, 100);
    const fraction1 = MCRandom.randomInt(1, 119) / 120;
    const price2ans = price2 - fraction1 * price2;

    const fracDisplay = MCMaths.cleaner(` ${fraction1}`);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} is shopping, and has found the pair of boots ${name.heshe} wants in two different shops.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The boots in the first shop cost $\\pounds ${price}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The boots cost $\\pounds ${price2}$ in the second shop, but have $${fraction1}$ off.`,
        true,
        true,
        true
      )
    );

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Which shop should ${name.name} buy their boots from?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\pounds ${price2} - \\left({${fracDisplay} \\times\\pounds ${price2}}\\right)=\\pounds ${price2ans}$`,
        false,
        true,
        true
      )
    );
    if (price >= price2ans) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` As, $\\pounds ${price}$ is greater than $\\pounds ${price2ans}$, ${name.name} should buy from the second shop.`,
          false,
          true,
          true
        )
      );
    }
    if (price <= price2ans) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` As, $\\pounds ${price}$ is less than $\\pounds ${price2ans}$, ${name.name} should buy from the first shop.`,
          false,
          true,
          true
        )
      );
    }

    question.requiresSelfMarking();
    if (
      price >= price2 ||
      Math.abs(price / price2ans - 1) >= 0.1 ||
      price === price2ans ||
      Math.round(price2ans * 10) === price2ans * 10 ||
      Math.round(price2ans * 100) !== price2ans * 100
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q13() {
  let count = 0;
  let j = 0;
  for (let i = 1; i <= 1; i += 1) {
    j += 1;
    // -----------------------------------This can take over 20k iterations, but there is no use of toFraction or rounding error so isn't too slow ------------------------------------

    const af = MCRandom.randomInt(1, 119) / 120;
    const bf = MCRandom.randomInt(1, 119) / 120;
    const cf = MCRandom.randomInt(1, 119) / 120;
    const df = MCRandom.randomInt(1, 119) / 120;

    const a1 = MCRandom.randomInt(20, 90);
    const b1 = MCRandom.randomInt(20, 90);
    const c1 = MCRandom.randomInt(20, 90);
    const d1 = MCRandom.randomInt(20, 90);

    const a2 = a1 * af;
    const b2 = b1 * bf;
    const c2 = c1 * cf;
    const d2 = d1 * df;

    let match = 0;
    let type = 0;
    let error1 = 0;

    // checks there are no halves as this makes the question too obvious
    if (a2 === b2) {
      match += 1;
      type = 1;
      if (a1 / b1 === 2 || b1 / a1 === 2) {
        error1 = 1;
      }
    }
    if (a2 === c2) {
      match += 1;
      type = 2;
      if (a1 / c1 === 2 || c1 / a1 === 2) {
        error1 = 1;
      }
    }
    if (a2 === d2) {
      match += 1;
      type = 3;
      if (a1 / d1 === 2 || d1 / a1 === 2) {
        error1 = 1;
      }
    }
    if (b2 === c2) {
      match += 1;
      type = 4;
      if (b1 / c1 === 2 || c1 / b1 === 2) {
        error1 = 1;
      }
    }
    if (b2 === d2) {
      match += 1;
      type = 5;
      if (b1 / d1 === 2 || d1 / b1 === 2) {
        error1 = 1;
      }
    }
    if (c2 === d2) {
      match += 1;
      type = 6;
      if (c1 / d1 === 2 || d1 / c1 === 2) {
        error1 = 1;
      }
    }

    if (a1 === b1) {
      error1 = 1;
    }
    if (a1 === c1) {
      error1 = 1;
    }
    if (a1 === d1) {
      error1 = 1;
    }
    if (b1 === c1) {
      error1 = 1;
    }
    if (b1 === d1) {
      error1 = 1;
    }
    if (c1 === d1) {
      error1 = 1;
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("Which two values are equal?", false, true, true)
    );

    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a) \\ ${af}$ of $${a1}$`, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b) \\ ${bf}$ of $${b1}$`, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(c) \\ ${cf}$ of $${c1}$`, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(d) \\ ${df}$ of $${d1}$`, true)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${af} \\times${a1}=${a2}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${bf} \\times${b1}=${b2}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${cf} \\times${c1}=${c2}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${df} \\times${d1}=${d2}$`, true, true, true)
    );
    if (type === 1) {
      question.addParagraph("solution", "So, $(a)$ and $(b)$ are equal.");
    }
    if (type === 2) {
      question.addParagraph("solution", "So, $(a)$ and $(c)$ are equal.");
    }
    if (type === 3) {
      question.addParagraph("solution", "So, $(a)$ and $(d)$ are equal.");
    }
    if (type === 4) {
      question.addParagraph("solution", "So, $(b)$ and $(c)$ are equal.");
    }
    if (type === 5) {
      question.addParagraph("solution", "So, $(b)$ and $(d)$ are equal.");
    }
    if (type === 6) {
      question.addParagraph("solution", "So, $(c)$ and $(d)$ are equal.");
    }
    question.requiresSelfMarking();

    if (
      match !== 1 ||
      af === 0 ||
      af === 1 ||
      bf === 0 ||
      bf === 1 ||
      cf === 0 ||
      cf === 1 ||
      df === 0 ||
      df === 1 ||
      error1 === 1
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q14() {
  let count = 0;

  function Weight(input) {
    if (input >= 1000) {
      return `${input / 1000}kg`;
    }
    return `${input}g`;
  }

  for (let i = 1; i <= 1; i += 1) {
    const bag1 = MCRandom.randomInt(200, 2000, 10);
    const bag2 = MCRandom.randomInt(200, 2000, 10);

    const fraction1 = MCRandom.randomInt(1, 119) / 120;
    const fraction2 = MCRandom.randomInt(1, 119) / 120;

    const a1 = fraction1 * bag1;
    const a2 = fraction2 * bag2;

    const ans = a1 + a2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A recipe uses $${fraction1}$ of a $${Weight(
          bag1
        )}$ bag of flour, and $${fraction2}$ of a $${Weight(
          bag2
        )}$ bag of sugar.`,
        true
      )
    );

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "How much total flour and sugar does the recipe require?",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${fraction1} \\times${Weight(bag1)}=${Weight(a1)}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${fraction2} \\times${Weight(bag2)}=${Weight(a2)}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, the total is $${Weight(a1)}+${Weight(a2)}=${Weight(ans)}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.1);
    question.addInputGroup(group);

    if (
      Math.round(a1) !== a1 ||
      Math.round(a2) !== a2 ||
      fraction1 === 0 ||
      fraction1 === 1 ||
      fraction2 === 0 ||
      fraction2 === 1 ||
      bag1 === bag2 ||
      ans > 999
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q15() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();

    const total = MCRandom.randomInt(50, 500);

    const fraction1 = MCRandom.randomInt(1, 119) / 120;
    const fraction2 = MCRandom.randomInt(1, 119) / 120;

    const thingList = [
      "records",
      "records",
      "records",
      "records",
      "records",
      "model cars",
    ];
    const type1List = ["rock", "RnB", "blues", "indie rock", "jazz", "red"];
    const type2List = [
      "jazz",
      "hip hop",
      "country",
      "classical",
      "pop",
      "blue",
    ];
    const type3List = ["pop", "rock", "grime", "swing", "country", "green"];
    const listNum = MCRandom.randomInt(0, thingList.length - 1);
    const thing = thingList[listNum];
    const type1 = type1List[listNum];
    const type2 = type2List[listNum];
    const type3 = type3List[listNum];

    const a1 = total * fraction1;
    const a2 = total * fraction2;

    const ans = total - a1 - a2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.name} collects ${thing}.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} has $${total}$ ${thing}.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${fraction1}$ of ${name.hisher} ${thing} are ${type1}.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${fraction2}$ of ${name.hisher} ${thing} are ${type2}.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The rest of ${name.hisher} ${thing} are ${type3}.`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `How many ${type3} ${thing} does ${name.name} have?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${fraction1} \\times${total}=${a1}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${fraction2} \\times${total}=${a2}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${total}-${a1}-${a2}=${ans}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` So, ${name.name} has $${ans}$ ${type3} ${thing}.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.01);
    question.addInputGroup(group);

    if (
      ans <= 0 ||
      Math.round(ans) !== ans ||
      fraction1 === 0 ||
      fraction2 === 0 ||
      Math.round(a1) !== a1 ||
      Math.round(a2) !== a2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}
// JP2018


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q16() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const numOfCars = MCRandom.randomInt(20, 300);
    const redFrac = MCRandom.randomInt(1, numOfCars) / numOfCars;
    const blueFrac = MCRandom.randomInt(1, numOfCars) / numOfCars;
    const blackFrac = MCRandom.randomInt(1, numOfCars) / numOfCars;

    const numOfRedCars = redFrac * numOfCars;
    const numOfBlueCars = blueFrac * numOfCars;
    const numOfBlackCars = blackFrac * numOfCars;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " A garage stores and sells cars that are either red, blue or black.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The garage owns $${numOfCars}$ cars.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(` $${redFrac}$ of the cars are red.`, true, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(` $${blueFrac}$ of the cars are blue.`, true, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" The rest of the cars are black.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" How many cars are black?", false, true, true)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " First, find out how many red and blue cars there are:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Number of red cars $= ${redFrac}  \\times ${numOfCars}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${numOfRedCars}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Number of blue cars $= ${blueFrac}  \\times ${numOfCars}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $= ${numOfBlueCars}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Subtract these two values from the total amount of cars in the garage to get the amount of black cars in the garage:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Number of black cars $ = ${numOfCars} - ${numOfRedCars} - ${numOfBlueCars}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Number of black cars $ = ${numOfBlackCars}$`,
        false,
        true,
        true
      )
    );
    const group = new MCQuestion.InputGroup(1);
    group.addInput(numOfBlackCars, 0.1);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      redFrac === null ||
      blueFrac === null ||
      blackFrac === null ||
      numOfRedCars + numOfBlueCars + numOfBlackCars !== numOfCars ||
      numOfCars % 10 === 0 ||
      redFrac.denominator < 10 ||
      blueFrac.denominator < 10 ||
      redFrac.denominator === numOfCars ||
      blueFrac.denominator === numOfCars
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q17() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const firstYearProfit = MCRandom.randomInt(5, 100) * 100;
    const secondYearProfit = MCRandom.randomInt(5, 100) * 100;

    const percentageIncrease = (secondYearProfit / firstYearProfit) * 100;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} owns a business that sells books.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name}'s business made $\\pounds${firstYearProfit}$ profit in ${person.hisher} first year.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name}'s business then made $\\pounds${secondYearProfit}$ profit in the following year.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " What is the percentage increase in profit from the first, to the second year?",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " First divide the second year profit by the first year profit:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${secondYearProfit}  \\div ${firstYearProfit} = ${(
          percentageIncrease / 100
        ).toPrecision(3)}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Then multiply this value by $100$ to obtain a percentage:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${(percentageIncrease / 100).toPrecision(
          3
        )}  \\times 100 = ${percentageIncrease.toPrecision(4)}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore the percentage increase in profit for ${
          person.name
        }'s business is $${(percentageIncrease - 100).toFixed(0)}\\%$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(percentageIncrease - 100, 1);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      secondYearProfit < firstYearProfit ||
      percentageIncrease < 120 ||
      percentageIncrease > 500
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q18() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const sundayLunch = MCRandom.randomInt(35, 45);
    const steakChips = MCRandom.randomInt(25, 40);
    const scones = MCRandom.randomInt(10, 20);
    const appleCrumble = 100 - sundayLunch - steakChips - scones;
    const people = MCRandom.randomInt(1000, 2000);
    const percentageMultiplier = appleCrumble / 100;
    const numberOfPeople = percentageMultiplier * people;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A survey of $${people}$ people was conducted to determine Britain's favourite food.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${sundayLunch}\\%$ of people voted for Sunday lunch`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${steakChips}\\%$ of people voted for steak and chips`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${scones}\\%$ of people voted for scones with jam and cream`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The rest of the votes were for apple crumble.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Work out the number of people who voted for apple crumble.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Percentage of people that voted for apple crumble = $100 - ${sundayLunch} - ${steakChips} - ${scones} = ${appleCrumble}\\%$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Number of people that voted for apple crumble = $${appleCrumble}\\%$ of $${people} = ${percentageMultiplier}  \\times ${people} = ${numberOfPeople}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(numberOfPeople, 0);
    question.addInputGroup(group);
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      steakChips >= sundayLunch ||
      appleCrumble > scones ||
      numberOfPeople !== Math.round(numberOfPeople) ||
      appleCrumble < 3
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentagess_Exam_Q19() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
      }
      return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const money = MCRandom.randomInt(800, 3000);
    const percentage = MCRandom.randomInt(1, 15);
    const increase = (money * percentage) / 100;
    const total = money + increase;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} earns $\\pounds${money}$ every month.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} then gets a $${percentage}\\%$ pay rise.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `How much will ${name.name} now earn in a month?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Find how much extra ${
          name.name
        } will earn $= {${percentage}\\over${100}} \\times ${DisplayMoney(
          money * 100
        )}= \\pounds${increase}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Add this to how much they earned before the pay rise $=\\pounds${money}+\\pounds${increase} = \\pounds${total}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(total, 0);
    question.addInputGroup(group);

    if (total % 1 !== 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q2Time(
  input
) {
  let hour = Math.floor(input / 60);
  let minute = input % 60;
  while (hour >= 24) {
    hour -= 24;
  }
  if (hour <= 9) {
    hour = `0${hour}`;
  }
  if (minute <= 9) {
    minute = `0${minute}`;
  }
  return `${hour}$:$${minute}`;
}

export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q2() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();

    // let placeList = ['Jersey','Dublin','Stockholm','Svalbard']
    // let placeType = MCRandom.randomInt(0,placeList.length-1)
    // let place = placeList[placeType]

    // Part a
    const setOff = MCRandom.randomInt(100, 1000);
    const flightTime = MCRandom.randomInt(60, 120);
    const arrive = setOff + flightTime;

    // Part b
    const lateChance = MCRandom.randomInt(1, 30) / 100;
    const numFlights = MCRandom.randomInt(100, 1000);
    const numLate = lateChance * numFlights;

    // Part c (used to be ten mins, changed to random)
    const diff = MCRandom.randomInt(5, 20);
    const tenChance = MCRandom.randomInt(1, 119) / 100;
    const numten = (1 - tenChance) * numLate;
    const tenLater = arrive + diff;

    // Part d
    const totalGood = numten + (numFlights - numLate);
    const totalProp = totalGood / numFlights;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} and the rest of ${name.hisher} year planning on flying abroad for a school trip.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Their flight departs at $${GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q2Time(
          setOff
        )}$ and takes $${flightTime}$ minutes.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` $(a)$ What time should ${name.name} expect to arrive?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `On a specific day, $${lateChance}$ of the $${numFlights}$ daily flights out of the Airport were late.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ How many flights were late on that day?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `On that same day, $${tenChance}$ of the late flights were more than $${diff}$ minutes late.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ How many late flights were less than $${diff}$ minutes late on that day?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(d)$ What is the probability ${
          name.name
        } arrives before $${GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q2Time(
          tenLater
        )}$, given that ${name.heshe} was on one of the flights on that day?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a) \\ ${GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q2Time(
          arrive
        )}$`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ ${lateChance} \\times${numFlights}=${numLate}$`,
        true,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(c) \\ ${numLate}-(${tenChance} \\times${numLate})=${numten}$`,
        true,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(d) \\ ${diff}$ minutes later than $${GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q2Time(
          arrive
        )}$ is $${GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q2Time(
          tenLater
        )}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Work out the number of flights that are less than $${diff}$ minutes late $=(${numFlights}-$part $(b))+$part $(c)$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${numFlights - numLate}+${numten}=${totalGood}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Work out the probability out of the total number of flights: $${totalGood} \\div${numFlights}=${totalProp}$`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(3);
    group.addInput(numLate, 0, "$(b)$");
    group.addInput(numten, 0, "$(c)$");
    group.addInput(totalProp, 0.01, "$(c)$");
    question.addInputGroup(group);

    if (
      lateChance === 0 ||
      lateChance >= 0.4 ||
      Math.round(numLate) !== numLate ||
      lateChance === tenChance ||
      tenChance === 1 ||
      tenChance === 0 ||
      Math.round(numten) !== numten ||
      tenChance === 0.5
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\
// ||
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)

// JP2018


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q20() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    function roundToTwo(num) {
      return +`${Math.round(`${num}e+2`)}e-2`;
    }

    const passPercentage = MCRandom.randomInt(51, 70);
    const testMaxMark = MCRandom.randomInt(35, 90);
    const testScoreMark = MCRandom.randomInt(5, testMaxMark - 1);
    const name1 = MCMisc.getName();
    const testScoreFraction = roundToTwo(testScoreMark / testMaxMark);
    const testScorePercentage = testScoreFraction * 100;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${name1.name} takes a Maths test, in order to pass he must obtain a percentage of $${passPercentage}\\%$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${name1.name} obtains a score of $${testScoreMark}$ out of $${testMaxMark}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` What percentage does ${name1.heshe} obtain and hence conclude whether ${name1.heshe} has passed the test?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Divide $${testScoreMark}$ by $${testMaxMark}$:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${testScoreMark}  \\div ${testMaxMark} = ${testScoreFraction}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Multiply this value by $100\\%$ to obtain the percentage mark:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${testScoreFraction}  \\times 100 = ${testScorePercentage}\\%$`,
        false,
        true,
        true
      )
    );
    if (testScorePercentage > passPercentage) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` This value is greater than $${passPercentage}\\%$, ${name1.name} has passed the test.`,
          false,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          ` This value is less than $${passPercentage}\\%$, ${name1.name} has not passed the test.`,
          false,
          true,
          true
        )
      );
    }

    question.requiresSelfMarking();

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      testScorePercentage / 10 === Math.round(testScorePercentage / 10) ||
      testScorePercentage === 25
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q3() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const name2 = MCMisc.getName();
    const name3 = MCMisc.getName();

    // let placeList = ['Jersey','Dublin','Stockholm','Svalbard']
    // let placeType = MCRandom.randomInt(0,placeList.length-1)
    // let place = placeList[placeType]

    // Part a
    const total = MCRandom.randomInt(20, 200);

    const fraction1 = MCRandom.randomInt(1, 119) / 120;
    const fraction2 = MCRandom.randomInt(1, 119) / 120;

    const given1 = fraction1 * total;
    const given2 = fraction2 * total;

    const answer = total - given1 - given2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.name} has $${total}$ trading cards.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} gives $${fraction1}$ of ${name.hisher} cards to ${name2.name}.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} gives $${fraction2}$ of ${name.hisher} cards to ${name3.name}.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `How many cards does ${name.name} have left?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${name.name} gives $${fraction1} \\times${total}=${given1}$ cards to ${name2.name}.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${name.name} gives $${fraction2} \\times${total}=${given2}$ cards to ${name3.name}.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` So, ${name.name} has $${total}-${given1}-${given2}=${answer}$ cards left.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(answer, 0.1);
    question.addInputGroup(group);

    if (
      Math.round(given1) !== given1 ||
      Math.round(given2) !== given2 ||
      answer <= 2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */

// JP2018


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q4() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const total = MCRandom.randomInt(20, 100);

    const red = MCRandom.randomInt(3, total - 5);
    const notRed = total - red;
    const answer = notRed / total;

    const gcd = function (a, b) {
      if (!b) {
        return a;
      }
      return gcd(b, a % b);
    };

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`There are $${total}$ sweets in a bag.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${red}$ of the sweets are red.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("What fraction of sweets are not red?", false, true, true)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Work out the number of non-red sweets $=(${total}-${red})=${notRed}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` So, $${notRed}\\over${total}$ sweeets are not red.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` This simplifies to $${answer}$.`, true, true, true)
    );

    question.requiresSelfMarking();

    if (gcd(notRed, total) === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\ (USE ALT (92))
// ||
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)

// JP2018


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q5() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();

    const total = MCRandom.randomInt(50, 500);

    const fraction1 = MCRandom.randomInt(1, 119) / 120;

    const eaten = total * fraction1;
    const answer = total - eaten;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`A tub has $${total}$ sweets.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} and ${name.hisher} friends eat $${fraction1}$ of the sweets.`,
        true,
        true,
        true
      )
    );

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `How many sweets do ${name.name} and ${name.hisher} friends have left?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${fraction1} \\times${total}=${eaten}$, so $${eaten}$ sweets were eaten.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` So, there are $${total}-${eaten}=${answer}$ sweets left.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(answer, 0.01);
    question.addInputGroup(group);

    if (
      fraction1 === 0 ||
      fraction1 === 1 ||
      fraction1.denominator <= 7 ||
      Math.round(eaten) !== eaten
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}

// JP2018


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q6() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();

    const fraction1 = MCRandom.randomInt(1, 119) / 120;

    const itemList = ["calculator", "album", "speaker"];
    const item = itemList[MCRandom.randomInt(0, itemList.length - 1)];

    const price = MCRandom.randomInt(15, 35);

    const savings = price * fraction1;

    const answer = price - savings;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has a  voucher for $${fraction1}$ off for a shop.`,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The ${item} ${name.heshe} wants to buy usually costs $ \\pounds ${price}$.`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `How much does ${name.name} pay if ${name.heshe} uses the  voucher?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Work out how much ${name.name} will save: $${fraction1} \\times \\pounds${price}=\\pounds ${savings}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` So, ${name.name} will pay $\\pounds${price}-\\pounds ${savings}=\\pounds ${answer}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(answer, 0.01);
    question.addInputGroup(group);

    if (
      Math.round(savings) !== savings ||
      fraction1 >= 0.4 ||
      fraction1 === 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q7() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();

    const total = MCRandom.randomInt(20, 400);
    const RPM45 = MCRandom.randomInt(1, total - 10);

    const answer = RPM45 / total;

    const gcd = function g(a, b) {
      if (!b) {
        return a;
      }
      return gcd(b, a % b);
    };

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.name} collects vinyl records.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has $${total}$ records in ${name.hisher} collection.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${RPM45}$ of ${name.hisher} records are albums.`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "What fraction of their collection is made up of albums, giving your answer as a simplified fraction?",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$={${RPM45}\\over${total}}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$=${answer}$`, true, true, true)
    );

    question.requiresSelfMarking();

    if (gcd(RPM45, total) === 1 || total > 50) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\ (USE ALT (92))
// ||
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)

// JP2018


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q8() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();

    const initial = MCRandom.randomInt(150, 780);

    const fraction1 = MCRandom.randomInt(1, 119) / 120;
    const fraction2 = MCRandom.randomInt(1, 119) / 120;

    const answerA = initial * fraction1;

    const answerC = Math.round(60 * fraction2);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.name} works at a cafe.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} isn\`t very good at ${name.hisher} job and has spilt some of the drink ${name.heshe} was carrying, so that only $${fraction1}$ of the drink is still in the glass.`,
        true,
        true,
        true
      )
    );

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Given that the drink was initially $${initial}ml$, work out how much is left in the glass.`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} spends $${fraction2}$ of an hour cleaning up the spill.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Write $${fraction2}$ as a decimal, to 3 significant figures.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ To the nearest minute, how long is $${fraction2}$ of an hour?`,
        true,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ $${fraction1} \\times${initial}=${answerA}ml$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b)$ $ ${fraction2.toFixed(3)}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(c)$ $60 \\times${fraction2}$ rounds to $${answerC}$ minutes.`,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(3);
    group.addInput(answerA, 0.1, "$(a)$");
    group.addInput(fraction2, 0.1, "$(b)$");
    group.addInput(answerC, 0.1, "$(c)$");
    question.addInputGroup(group);

    if (
      fraction1 <= 0.65 ||
      fraction1 === 0.1 ||
      fraction1 === 1 ||
      Math.round(answerA) !== answerA ||
      fraction2 >= 0.3 ||
      fraction1 === 0 ||
      60 * fraction2 * 1000 === Math.round(1000 * 60 * fraction2)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}
// JP2018


export function GCSE_Foundation_Number_Fdap_FractionsAndPercentages_Exam_Q9() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const red = MCRandom.randomInt(1, 119) / 120;
    const green = MCRandom.randomInt(1, 119) / 120;

    const total = MCRandom.randomInt(40, 600);

    const redNum = total * red;
    const notRedNum = total - redNum;
    const greenNum = green * notRedNum;
    const blueNum = notRedNum - greenNum;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`A box of sweets has $${total}$ sweets in it.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${red}$ of the sweets are red, and get eaten.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${green}$ of the remaining sweets are green, and the rest are blue.`,
        true,
        true,
        true
      )
    );

    question.addParagraph(
      "question",
      MCMaths.cleaner("How many blue sweets are there?", false, true, true)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${red}$ of $${total}=${redNum}$, so there are $${redNum}$ red sweets.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, there are $${total}-${redNum}=${notRedNum}$ blue and green sweets.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `There are $${green}  \\times ${notRedNum}=${greenNum}$ green sweets.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `This leaves $${notRedNum}-${greenNum}=${blueNum}$ blue sweets.`,
        false,
        true,
        true
      )
    );
    const group = new MCQuestion.InputGroup(1);
    group.addInput(blueNum, 0.01);
    question.addInputGroup(group);

    if (
      Math.round(blueNum) !== blueNum ||
      red === 0 ||
      red === 1 ||
      green === 0 ||
      green === 1 ||
      red === 0.5 ||
      green === 0.5 ||
      Math.round(greenNum) !== greenNum ||
      Math.round(redNum) !== redNum ||
      redNum % 10 === 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}
// JP2018


export function GCSE_Foundation_Number_Fdap_FractionsAndRatio_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const ans = 1;

    const blonde = MCRandom.randomInt(5, 15);
    const brown = MCRandom.randomInt(5, 15);
    const total = MCRandom.randomInt(20, 42);
    const other = total - brown - blonde;

    function gcd(x2, y2) {
      let x = Math.abs(x2);
      let y = Math.abs(y2);
      while (y) {
        const t = y;
        y = x % y;
        x = t;
      }
      return x;
    }

    const g = gcd(gcd(blonde, brown), other);

    const bl = blonde / g;
    const br = brown / g;
    const ot = other / g;

    const ans1 = bl / (bl + br + ot);
    const ans2 = (bl + br) / (bl + br + ot);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `In a classroom the ratio of students with blonde, brown and other hairs colours is $${bl}:${br}:${ot}$.`
    );
    question.addParagraph(
      "question",
      `$(a)$ What fraction of students have blonde hair?`
    );
    question.addParagraph(
      "question",
      `$(b)$ What fraction of students have blonde or black hair?`
    );
    question.addParagraph(
      "solution",
      `$\\displaystyle (a) \\ = \\frac{${bl}}{${bl}+${br}+${ot}}$ `
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\displaystyle =${ans1}$`)
    );
    question.addParagraph(
      "solution",
      `$\\displaystyle (a) \\ = \\frac{${bl}+${br}}{${bl}+${br}+${ot}}$ `
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\displaystyle =${ans2}$`)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ans1, 0.01, "$(a)$");
    group.addInput(ans2, 0.01, "$(b)$");
    question.addInputGroup(group);

    if (
      other <= 1 ||
      g === 1 ||
      other > blonde ||
      other > brown ||
      blonde === brown
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_Fdap_FractionsAndRatio_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const ans = 1;

    const red = MCRandom.randomInt(5, 50);
    const green = MCRandom.randomInt(5, 50);
    const blue = MCRandom.randomInt(5, 50);
    const total = red + green + blue;

    function gcd(x2, y2) {
      let x = Math.abs(x2);
      let y = Math.abs(y2);
      while (y) {
        const t = y;
        y = x % y;
        x = t;
      }
      return x;
    }

    const g = gcd(red, blue);
    const rr = red / g;
    const br = blue / g;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `A bag contains red, green and blue counters.`
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`A bag contains ${total} red, green and blue counters.`)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${green / total}$ of the counters are green.`)
    );
    question.addParagraph(
      "question",
      `The ratio of red to blue counters is $${rr}:${br}$`
    );
    question.addParagraph("question", `How many counters are red?`);

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Number of green counters $= ${
          green / total
        } \\times ${total} = ${green}$ `
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Number of blue and red counters $= ${total} - ${green} = ${
          blue + red
        }$`
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Fraction of red counters $= \\frac{${rr}}{${rr}+${br}} = ${
          red / (red + blue)
        }$`
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Number of red counters $= ${red / (red + blue)} \\times ${
          blue + red
        } = ${red}$`
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(red, 0.01);
    question.addInputGroup(group);

    if (gcd(green, total) === 1 || g === 1 || rr > 6 || br > 6 || rr === br) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_Fdap_FractionsAndRatio_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    function DisplayMoney(input) {
      if (input >= 100) {
        if (input % 100 === 0) {
          return `\\pounds ${parseFloat(input / 100)
            .toFixed(2)
            .replace("0", " 0 ")} `;
        }
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `${input}p`;
    }

    const ans = 1;

    const coins = [
      "1p",
      "2p",
      "5p",
      "10p",
      "20p",
      "50p",
      "\\pounds 1",
      "\\pounds 2",
    ];
    const values = [1, 2, 5, 10, 20, 50, 100, 200];

    const t1 = MCRandom.randomInt(0, coins.length - 1);
    const t2 = MCRandom.randomInt(0, coins.length - 1);

    const n1 = MCRandom.randomInt(5, 60);
    const n2 = MCRandom.randomInt(5, 60);

    function gcd(x2, y2) {
      let x = Math.abs(x2);
      let y = Math.abs(y2);
      while (y) {
        const t = y;
        y = x % y;
        x = t;
      }
      return x;
    }

    const g = gcd(n1, n2);
    const n1r = n1 / g;
    const n2r = n2 / g;

    const val1 = values[t1] * n1r;
    const val2 = values[t2] * n2r;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `The ratio of number of coins in two piles is $${n1r}:${n2r}$.`
    );
    question.addParagraph(
      "question",
      `The first pile contains only $${coins[t1]}$ coins, and the second only $${coins[t2]}$ coins.`
    );
    question.addParagraph("question", `Which pile has a greater value?`);

    question.addParagraph(
      "solution",
      `Ratio of values $= ${n1r}\\times ${values[t1]}:${n2r}\\times ${values[t2]}$`
    );
    question.addParagraph(
      "solution",
      `$= ${DisplayMoney(val1)}:${DisplayMoney(val2)}$`
    );

    if (val1 > val2) {
      question.addParagraph(
        "solution",
        `Hence the first pile has a greater value.`
      );
    }

    if (val1 < val2) {
      question.addParagraph(
        "solution",
        `Hence the second pile has a greater value.`
      );
    }

    question.requiresSelfMarking();

    if (
      g === 1 ||
      Math.abs(val1 - val2) > 5 ||
      val1 === val2 ||
      t1 === t2 ||
      n1r < 4 ||
      n2r < 4
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_Fdap_FractionsAndRatio_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    function gcd(x2, y2) {
      let x = Math.abs(x2);
      let y = Math.abs(y2);
      while (y) {
        const t = y;
        y = x % y;
        x = t;
      }
      return x;
    }

    const red = MCRandom.randomInt(5, 50);
    const green = MCRandom.randomInt(5, 50);
    const blue = MCRandom.randomInt(5, 50);
    const total = red + green + blue;

    const gr = green / total;

    const gcdBG = gcd(blue, green);
    const g2 = green / gcdBG;
    const b2 = blue / gcdBG;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `A bag contains red, blue and green balls.`
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Given $${gr}$ of the balls are green, the ratio of green to blue balls is $${g2}:${b2}$, and there are $${total}$ balls in total, how many balls are red?`
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Total green balls: $${gr}\\times ${total}= ${green}$`)
    );
    question.addParagraph(
      "solution",
      `Ratio of green to blue: $${g2}:${b2} = ${green}:${blue}$, hence there are $${blue}$ blue balls.`
    );
    question.addParagraph(
      "solution",
      `Number of red balls: $${total}-${green}-${blue}=${red}$.`
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(red, 0.01);
    question.addInputGroup(group);

    if (gcd(green, total) === 1 || gcdBG === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_Fdap_FractionsAndRatio_Exam_Q5() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    function gcd(x2, y2) {
      let x = Math.abs(x2);
      let y = Math.abs(y2);
      while (y) {
        const t = y;
        y = x % y;
        x = t;
      }
      return x;
    }

    const red = MCRandom.randomInt(5, 50);
    const green = MCRandom.randomInt(5, 50);
    const blue = MCRandom.randomInt(5, 50);
    const total = red + green + blue;

    const gr = green / total;
    const br = blue / total;
    const rr = red / total;

    const gcdBG = gcd(blue, green);
    const g2 = green / gcdBG;
    const b2 = blue / gcdBG;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `A bag contains red, blue and green balls.`
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The probability of picking a green ball out of the bag at random is $${gr}$.`
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The ratio of green balls to blue balls is $${g2}:${b2}$.`
      )
    );
    question.addParagraph(
      "question",
      `What is the probability of randomly picking a red ball out of the bag?`
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Green and blue probability ratio: $${g2}:${b2} = ${gr}:${br}$`
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Red probability: $1-${gr}-${br}=${rr}$`)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(rr, 0.01);
    question.addInputGroup(group);

    if (gcd(green, total) === 1 || gcdBG === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_Maa_Bounds_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name1 = MCMisc.getName();
    const name2 = MCMisc.getName();
    const name3 = MCMisc.getName();
    const name4 = MCMisc.getName();
    const items = [
      "pencils",
      "pens",
      "erasers",
      "rulers",
      "packets of paper",
      "ink cartridges",
    ];
    const item1 = items[MCRandom.randomInt(0, 5)];
    const item2 = items[MCRandom.randomInt(0, 5)];
    const item3 = items[MCRandom.randomInt(0, 5)];
    const item4 = items[MCRandom.randomInt(0, 5)];
    const num1 = MCRandom.randomInt(10, 30);
    const num2 = MCRandom.randomInt(10, 30);
    const num3 = MCRandom.randomInt(20, 60);
    const num4 = MCRandom.randomInt(20, 60);
    const ansA = num1 - num2;
    const ansB = num3 - 1 + num4;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} and ${name2.name} take a trip to a stationary wholesalers.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name1.name} buys at least $${num1}$ ${item1}.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name2.name} buys no more than $${num2}$ ${item2}.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `(a) Work out the smallest possible value of the number of ${item1} $-$ the number of ${item2}.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name3.name} and ${name4.name} also go to the wholesalers.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name3.name} buys less than $${num3}$ ${item3}.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name4.name} buys no more than $${num4}$ ${item4}.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `(b) Work out the largest possible value of the number of ${item3} $+$ the number of ${item4}.`,
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph("solution", MCMaths.cleaner("(a)", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Smallest value = smallest number of ${item1} - largest number of ${item2} = $${num1} - ${num2} = ${ansA}$`,
        false
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("(b)", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Largest value = largest number of ${item3} + largest number of ${item4} = $${
          num3 - 1
        } + ${num4} = ${ansB}$`,
        false
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ansA, 0, "(a)");
    group.addInput(ansB, 0, "(b)");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      name1.name === name2.name ||
      name1.name === name3.name ||
      name1.name === name4.name ||
      name2.name === name3.name ||
      name2.name === name4.name ||
      name3.name === name4.name ||
      num1 === num2 ||
      num3 === num4 ||
      item1 === item2 ||
      item1 === item3 ||
      item1 === item4 ||
      item2 === item3 ||
      item2 === item4 ||
      item3 === item4 ||
      num1 < num2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


function Que1(input) {
  const num = MCRandom.randomInt(20, 200);
  const num2 = MCRandom.randomInt(20, 200);

  const approxAns =
    Number(Number.parseFloat(num).toPrecision(1)) *
    Number(Number.parseFloat(num2).toPrecision(1));
  const real = num * num2;

  const ans = Number(Number.parseFloat(approxAns).toPrecision(1));

  const question = `Estimate $${num} \\times${num2}$.`;
  let solution = `$${num} \\times${num2}≈${Number(
    Number.parseFloat(num).toPrecision(1)
  )} \\times${Number(Number.parseFloat(num2).toPrecision(1))}$`;
  solution += `$≈${approxAns}$`;
  let test = true;

  if (
    Number(Number.parseFloat(num).toPrecision(1)) === num ||
    Number(Number.parseFloat(num2).toPrecision(1)) === num2 ||
    Math.abs(ans / real - 1) >= input ||
    ans === 1 ||
    real === ans ||
    approxAns !== ans
  ) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    real,
    ans,
  };
}

function Que2(input) {
  const num = MCRandom.randomInt(20, 200);
  const num2 = MCRandom.randomInt(20, 200);

  const approxAns =
    Number(Number.parseFloat(num).toPrecision(1)) /
    Number(Number.parseFloat(num2).toPrecision(1));
  const real = num / num2;

  const ans = Number(Number.parseFloat(approxAns).toPrecision(1));

  const question = `Estimate \${${num} \\over${num2}}$.`;
  let solution = `\${${num} \\over${num2}}≈{${Number(
    Number.parseFloat(num).toPrecision(1)
  )} \\over${Number(Number.parseFloat(num2).toPrecision(1))}}$`;
  solution += `$≈${approxAns}$`;
  let test = true;

  if (
    ans !== approxAns ||
    Number(Number.parseFloat(num).toPrecision(1)) === num ||
    Number(Number.parseFloat(num2).toPrecision(1)) === num2 ||
    Math.abs(ans / real - 1) >= input ||
    real === ans ||
    ans === 1
  ) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    real,
    ans,
  };
}

function Que3(input) {
  const num = MCRandom.randomInt(20, 200);
  const num2 = MCRandom.randomInt(20, 200);
  const num3 = MCRandom.randomInt(20, 200);

  const approxTop = Number(
    Number.parseFloat(
      Number(Number.parseFloat(num).toPrecision(1)) *
        Number(Number.parseFloat(num2).toPrecision(1))
    ).toPrecision(1)
  );
  const real = (num * num2) / num3;

  const ans = Number(
    Number.parseFloat(
      approxTop / Number(Number.parseFloat(num3).toPrecision(1))
    ).toPrecision(1)
  );

  const question = `Estimate \${{${num} \\times${num2}} \\over${num3}}$.`;
  let solution = `\${{${num} \\times${num2}} \\over${num3}}\\ ≈\\ {{${Number(
    Number.parseFloat(num).toPrecision(1)
  )} \\times${Number(Number.parseFloat(num2).toPrecision(1))}} \\over${Number(
    Number.parseFloat(num3).toPrecision(1)
  )}}$`;
  solution += `$≈{${approxTop} \\over${Number(
    Number.parseFloat(num3).toPrecision(1)
  )}}$`;
  solution += `$≈${ans}$`;
  let test = true;

  if (
    Math.round(ans * 10) !== ans * 10 ||
    Number(Number.parseFloat(num).toPrecision(1)) === num ||
    Number(Number.parseFloat(num2).toPrecision(1)) === num2 ||
    Number(Number.parseFloat(num3).toPrecision(1)) === num3 ||
    Math.abs(ans / real - 1) >= input ||
    real === ans
  ) {
    test = false;
  }
  return {
    question,
    solution,
    test,
    real,
    ans,
  };
}

function Que4(input) {
  const num = MCRandom.randomInt(20, 200);
  const num2 = MCRandom.randomInt(20, 200);
  const num3 = MCRandom.randomInt(-200, 200);

  const real = num / (num2 + num3);

  const bottom = num2 + num3;

  const ans = Number(
    Number.parseFloat(
      Number(Number.parseFloat(num).toPrecision(1)) /
        Number(Number.parseFloat(bottom).toPrecision(1))
    ).toPrecision(1)
  );

  const question = `Estimate \${{${num}} \\over${num2}+${num3}}$.`;
  let solution = `\${{${num}} \\over${num2}+${num3}}≈{{${Number(
    Number.parseFloat(num).toPrecision(1)
  )}} \\over${bottom}}$`;
  solution += `$≈{{${Number(
    Number.parseFloat(num).toPrecision(1)
  )}} \\over${Number(Number.parseFloat(bottom).toPrecision(1))}}$`;
  solution += `$≈${ans}$`;
  let test = true;

  if (
    Number(Number.parseFloat(num).toPrecision(1)) === num ||
    Number(Number.parseFloat(num2).toPrecision(1)) === num2 ||
    Number(Number.parseFloat(num3).toPrecision(1)) === num3 ||
    Math.abs(ans / real - 1) >= input ||
    real === ans ||
    Math.abs(num3) <= 11 ||
    Math.abs(bottom) <= 11
  ) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    real,
    ans,
  };
}

function Que5(input) {
  const num = MCRandom.randomInt(20, 200);
  const num2 = MCRandom.randomInt(20, 200);
  const num3 = MCRandom.randomInt(20, 200);

  const approxAns =
    Number(Number.parseFloat(num).toPrecision(1)) *
    Number(Number.parseFloat(num2).toPrecision(1)) *
    Number(Number.parseFloat(num3).toPrecision(1));
  const real = num * num2 * num3;

  const ans = Number(Number.parseFloat(approxAns).toPrecision(1));

  const question = `Estimate $${num} \\times${num2} \\times${num3}$.`;
  let solution = `$${num} \\times${num2} \\times${num3}≈${Number(
    Number.parseFloat(num).toPrecision(1)
  )} \\times${Number(Number.parseFloat(num2).toPrecision(1))} \\times${Number(
    Number.parseFloat(num3).toPrecision(1)
  )}$`;
  solution += `$≈${approxAns}$`;
  if (Number(Number.parseFloat(approxAns).toPrecision(1)) !== approxAns) {
    solution += `$≈${approxAns}$`;
  }

  let test = true;

  if (
    Number(Number.parseFloat(num).toPrecision(1)) === num ||
    Number(Number.parseFloat(num2).toPrecision(1)) === num2 ||
    Number(Number.parseFloat(num3).toPrecision(1)) === num3 ||
    Math.abs(ans / real - 1) >= input ||
    real === ans
  ) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    real,
    ans,
  };
}

function Que6(input) {
  const div = 10 ** (-1 * MCRandom.randomInt(0, 5));
  const div2 = 10 ** (-1 * MCRandom.randomInt(0, 5));

  const num = MCRandom.randomInt(20, 200) * div;
  const num2 = MCRandom.randomInt(20, 200) * div2;

  const approxAns =
    Number(Number.parseFloat(num).toPrecision(1)) *
    Number(Number.parseFloat(num2).toPrecision(1));
  const real = num * num2;

  const ans = Number(Number.parseFloat(approxAns).toPrecision(1));

  const question = `Estimate $${num} \\times${num2}$.`;
  let solution = `$${num} \\times${num2}≈${Number(
    Number.parseFloat(num).toPrecision(1)
  )} \\times${Number(Number.parseFloat(num2).toPrecision(1))}$`;
  solution += `$≈${approxAns}$`;
  let test = true;

  if (
    approxAns !== ans ||
    Number(Number.parseFloat(num).toPrecision(1)) === num ||
    Number(Number.parseFloat(num2).toPrecision(1)) === num2 ||
    Math.abs(ans / real - 1) >= input ||
    real === ans
  ) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    real,
    ans,
  };
}

function Que7(input) {
  const div = 10 ** (-1 * MCRandom.randomInt(0, 5));
  const div2 = 10 ** (-1 * MCRandom.randomInt(0, 5));

  const num = MCRandom.randomInt(20, 200) * div;
  const num2 = MCRandom.randomInt(20, 200) * div2;

  const approxAns =
    Number(Number.parseFloat(num).toPrecision(1)) /
    Number(Number.parseFloat(num2).toPrecision(1));
  const real = num / num2;

  const ans = Number(Number.parseFloat(approxAns).toPrecision(1));

  const question = `Estimate \${${num} \\over${num2}}$.`;
  let solution = `\${${num} \\over${num2}}≈{${Number(
    Number.parseFloat(num).toPrecision(1)
  )} \\over${Number(Number.parseFloat(num2).toPrecision(1))}}$`;
  solution += `$≈${approxAns}$`;
  let test = true;

  if (
    approxAns !== ans ||
    Number(Number.parseFloat(num).toPrecision(1)) === num ||
    Number(Number.parseFloat(num2).toPrecision(1)) === num2 ||
    Math.abs(ans / real - 1) >= input ||
    real === ans ||
    ans === 1 ||
    ans <= 0.01
  ) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    real,
    ans,
  };
}

function Que8(input) {
  const div = 10 ** (-1 * MCRandom.randomInt(0, 5));
  const div2 = 10 ** (-1 * MCRandom.randomInt(0, 5));
  const div3 = 10 ** (-1 * MCRandom.randomInt(0, 5));

  const num = MCRandom.randomInt(20, 200) * div;
  const num2 = MCRandom.randomInt(20, 200) * div2;
  const num3 = MCRandom.randomInt(20, 200) * div3;

  const approxTop = Number(
    Number.parseFloat(
      Number(Number.parseFloat(num).toPrecision(1)) *
        Number(Number.parseFloat(num2).toPrecision(1))
    ).toPrecision(1)
  );
  const real = (num * num2) / num3;

  const ans = Number(
    Number.parseFloat(
      approxTop / Number(Number.parseFloat(num3).toPrecision(1))
    ).toPrecision(1)
  );

  const question = `Estimate \${{${num} \\times${num2}} \\over${num3}}$.`;
  let solution = `\${{${num} \\times${num2}} \\over${num3}}≈{{${Number(
    Number.parseFloat(num).toPrecision(1)
  )} \\times${Number(Number.parseFloat(num2).toPrecision(1))}} \\over${Number(
    Number.parseFloat(num3).toPrecision(1)
  )}}$`;
  solution += `$≈{${approxTop} \\over${Number(
    Number.parseFloat(num3).toPrecision(1)
  )}}$`;
  solution += `$≈${
    approxTop / Number(Number.parseFloat(num3).toPrecision(1))
  }$`;
  let test = true;

  if (
    approxTop / Number(Number.parseFloat(num3).toPrecision(1)) !== ans ||
    Number(Number.parseFloat(num).toPrecision(1)) === num ||
    Number(Number.parseFloat(num2).toPrecision(1)) === num2 ||
    Number(Number.parseFloat(num3).toPrecision(1)) === num3 ||
    Math.abs(ans / real - 1) >= input ||
    real === ans ||
    ans <= 0.01
  ) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    real,
    ans,
  };
}

function Que9(input) {
  const div = 10 ** (-1 * MCRandom.randomInt(0, 5));
  const div2 = 10 ** (-1 * MCRandom.randomInt(0, 5));
  const div3 = 10 ** (-1 * MCRandom.randomInt(0, 5));

  const num = MCRandom.randomInt(20, 200) * div;
  const num2 = MCRandom.randomInt(20, 200) * div2;
  const num3 = MCRandom.randomInt(20, 200) * div3;

  const real = num / (num2 + num3);

  const bottom = num2 + num3;

  const ans = Number(
    Number.parseFloat(
      Number(Number.parseFloat(num).toPrecision(1)) /
        Number(Number.parseFloat(bottom).toPrecision(1))
    ).toPrecision(1)
  );

  const question = `Estimate \${{${num}} \\over${num2}+${num3}}$.`;
  let solution = `\${{${num}} \\over${num2}+${num3}}≈{{${Number(
    Number.parseFloat(num).toPrecision(1)
  )}} \\over${bottom}}$`;
  solution += `$≈{{${Number(
    Number.parseFloat(num).toPrecision(1)
  )}} \\over${Number(Number.parseFloat(bottom).toPrecision(1))}}$`;
  solution += `$≈${
    Number(Number.parseFloat(num).toPrecision(1)) /
    Number(Number.parseFloat(bottom).toPrecision(1))
  }$`;
  let test = true;

  if (
    Number(Number.parseFloat(num).toPrecision(1)) === num ||
    Number(Number.parseFloat(num2).toPrecision(1)) === num2 ||
    Number(Number.parseFloat(num3).toPrecision(1)) === num3 ||
    Math.abs(ans / real - 1) >= input ||
    real === ans ||
    Math.abs(num3) <= 11 ||
    Math.abs(bottom) <= 11 ||
    ans <= 0.01 ||
    ans === 1
  ) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    real,
    ans,
  };
}

function Que10(input) {
  const div = 10 ** (-1 * MCRandom.randomInt(0, 5));
  const div2 = 10 ** (-1 * MCRandom.randomInt(0, 5));
  const div3 = 10 ** (-1 * MCRandom.randomInt(0, 5));

  const num = MCRandom.randomInt(20, 200) * div;
  const num2 = MCRandom.randomInt(20, 200) * div2;
  const num3 = MCRandom.randomInt(20, 200) * div3;

  const approxAns =
    Number(Number.parseFloat(num).toPrecision(1)) *
    Number(Number.parseFloat(num2).toPrecision(1)) *
    Number(Number.parseFloat(num3).toPrecision(1));
  const real = num * num2 * num3;

  const ans = Number(Number.parseFloat(approxAns).toPrecision(1));

  const question = `Estimate $${num} \\times${num2} \\times${num3}$.`;
  let solution = `$${num} \\times${num2} \\times${num3}≈${Number(
    Number.parseFloat(num).toPrecision(1)
  )} \\times${Number(Number.parseFloat(num2).toPrecision(1))} \\times${Number(
    Number.parseFloat(num3).toPrecision(1)
  )}$`;
  solution += `$≈${approxAns}$`;
  if (Number(Number.parseFloat(approxAns).toPrecision(1)) !== approxAns) {
    solution += `$≈${approxAns}$`;
  }

  let test = true;

  if (
    Number(Number.parseFloat(num).toPrecision(1)) === num ||
    Number(Number.parseFloat(num2).toPrecision(1)) === num2 ||
    Number(Number.parseFloat(num3).toPrecision(1)) === num3 ||
    Math.abs(ans / real - 1) >= input ||
    real === ans
  ) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    real,
    ans,
  };
}

function QueS1() {
  const num = MCRandom.randomInt(20, 200);

  const real = Math.sqrt(num);

  // -----------------------------------------Working out 1
  const work = Math.floor(real);
  const work2 = work * work;

  const workPlus = work + 1;
  const workPlus2 = workPlus * workPlus;
  //-----------------------------------------

  // -----------------------------------------Working out 2
  const bork = Math.floor(real * 10) / 10;
  const bork2 = bork * bork;

  const borkPlus = bork + 0.1;
  const borkPlus2 = borkPlus * borkPlus;
  //-----------------------------------------

  // -----------------------------------------Working out 3

  const borkAvg = (bork + borkPlus) * 0.5;
  const borkAvg2 = borkAvg * borkAvg;

  //-----------------------------------------------------
  let ans;

  const question = `Estimate $\\sqrt{${num}}$ to $1 DP$.`;
  let solution = `$${work}^2=${work2}$`;
  solution += `$${workPlus}^2=${workPlus2}$. `;
  solution += `So, $${work}<\\sqrt{${num}}<${workPlus}$, `;
  solution += `$${bork}^2=${bork2}$, `;
  solution += `$${borkPlus}^2=${borkPlus2}$. `;
  solution += `So, $${bork}<\\sqrt{${num}}<${borkPlus}$, `;
  solution += `$${borkAvg}^2=${borkAvg2}$.`;

  if (borkAvg2 >= num) {
    solution += ` As $${borkAvg2}>${num}$, $\\sqrt{${num}}≈${bork}$`;
    ans = bork;
  }
  if (borkAvg2 <= num) {
    solution += ` As $${borkAvg2}<${num}$, $\\sqrt{${num}}≈${borkPlus}$`;
    ans = borkPlus;
  }

  let test = true;

  if (
    Number(Number.parseFloat(num).toPrecision(1)) === num ||
    Math.abs(Number(Number.parseFloat(real).toPrecision(1)) / real - 1) <=
      0.04 ||
    real === ans ||
    borkAvg2 === num
  ) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    real,
    ans,
  };
}

function QueS2(qNo, ans, real) {
  let letter;
  if (qNo === 1) {
    letter = "a";
  }
  if (qNo === 2) {
    letter = "b";
  }
  if (qNo === 3) {
    letter = "c";
  }

  const dived = ans / real;

  const answer = Math.abs(1 - dived);

  const question = `Using a calculator, find the percentage error for your answer to part $(${letter})$.`;

  let solution = `The real value of $(${letter})$ is $${real}$.`;
  solution += `\${${ans} \\over${real}}=${dived}$, `;
  if (answer <= real) {
    solution += `$100\\%-${dived.toFixed(3) * 100}=${answer.toFixed(3) * 100}$`;
  } else {
    solution += `$${dived.toFixed(3) * 100}-100\\%=${answer.toFixed(3) * 100}$`;
  }
  solution += `, I.e. the percentage error is $${answer.toFixed(3) * 100}$.`;

  return {
    question,
    solution,
    real: answer,
  };
}

export function GCSE_Foundation_Number_Maa_Estimation_Exam_Q1() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Use this to alter question generation speed, higher is quicker but with worse questions.
    const maxDiff = 0.1;

    const num = MCRandom.randomInt(20, 200);
    const num2 = MCRandom.randomInt(20, 200);

    const QList = [
      Que1(maxDiff),
      Que2(maxDiff),
      Que3(maxDiff),
      Que4(maxDiff),
      Que5(maxDiff),
      Que6(maxDiff),
      Que7(maxDiff),
      Que8(maxDiff),
      Que9(maxDiff),
      Que10(maxDiff),
    ];

    const mode1 = MCRandom.randomInt(0, 4);
    const mode2 = MCRandom.randomInt(0, 4);
    const mode3 = MCRandom.randomInt(5, 9);

    const Question1 = QList[mode1];
    const Question2 = QList[mode2];
    const Question3 = QList[mode3];

    const mode4 = MCRandom.randomInt(1, 15);
    let Question4;
    if (mode4 === 1) {
      Question4 = QueS2(1, Question1.ans, Question1.real);
    }
    if (mode4 === 2) {
      Question4 = QueS2(2, Question2.ans, Question2.real);
    }
    if (mode4 === 3) {
      Question4 = QueS2(3, Question3.ans, Question3.real);
    }
    if (mode4 === 4) {
      Question4 = QueS1();
    }
    if (mode4 === 5) {
      Question4 = QueS1();
    }
    if (mode4 === 6) {
      Question4 = QueS1();
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a)$ ${Question1.question}`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b)$ ${Question2.question}`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(c)$ ${Question3.question}`, false, true, true)
    );
    if (mode4 <= 6) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$(d)$ ${Question4.question}`, false, true, true)
      );
    }
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a)\\ $ ${Question1.solution}`, false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b)\\ $ ${Question2.solution}`, false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c)\\ $ ${Question3.solution}`, false, true, true)
    );
    if (mode4 <= 6) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(d)\\ $ ${Question4.solution}`, false, true, true)
      );
    }

    if (mode4 <= 6) {
      const group = new MCQuestion.InputGroup(2);
      group.addInput(Question1.real, Question1.real / 5, "(a)");
      group.addInput(Question2.real, Question2.real / 5, "(b)");
      group.addInput(Question3.real, Question3.real / 5, "(c)");
      group.addInput(Question4.real, Question4.real / 5, "(d)");
      question.addInputGroup(group);
    } else {
      const group = new MCQuestion.InputGroup(3);
      group.addInput(Question1.real, Question1.real / 5, "(a)");
      group.addInput(Question2.real, Question2.real / 5, "(b)");
      group.addInput(Question3.real, Question3.real / 5, "(c)");
      question.addInputGroup(group);
    }

    if (
      Question1.test === false ||
      Question2.test === false ||
      Question3.test === false ||
      mode1 === mode2 ||
      mode1 + 5 === mode3 ||
      mode2 + 5 === mode3 ||
      Number(Number.parseFloat(Question1.ans).toPrecision(1)) !==
        Question1.ans ||
      Number(Number.parseFloat(Question2.ans).toPrecision(1)) !==
        Question2.ans ||
      Number(Number.parseFloat(Question3.ans).toPrecision(1)) !== Question3.ans
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


function Q2QueS2(qNo, ans, real) {
  let letter;
  if (qNo === 1) {
    letter = "a";
  }
  if (qNo === 2) {
    letter = "b";
  }
  if (qNo === 3) {
    letter = "c";
  }

  const dived = ans / real;

  const answer = Math.abs(1 - dived);

  const question = `Using a calculator, find the percentage error for your answer to part $(${letter})$.`;

  let solution = `The real value of $(${letter})$ is $${real}$.`;
  solution += `\${${ans}\\over${real}}=${dived}$`;
  if (answer <= real) {
    solution += `$100\\%-${dived * 100}\\%=${answer * 100}\\%$`;
  } else {
    solution += `$${dived * 100}\\%-100\\%=${answer * 100}\\%$`;
  }
  solution += `I.e. the percentage error is $${answer * 100}\\%$`;

  return {
    question,
    solution,
  };
}

export function GCSE_Foundation_Number_Maa_Estimation_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const radius = MCRandom.randomInt(21, 150) / 10;
    const radius2 = radius * radius;
    const radius3 = radius * radius * radius;
    const ans = 3 * Number(Number.parseFloat(radius2).toPrecision(1));
    const real = Math.PI * radius * radius;

    const ans2 = 4 * Number(Number.parseFloat(radius3).toPrecision(1));
    const real2 = Math.PI * radius * radius * radius * (4 / 3);

    let Question4;
    if (MCRandom.randomInt(0, 1) === 1) {
      Question4 = Q2QueS2(1, ans, real);
    } else {
      Question4 = Q2QueS2(2, ans2, real2);
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`A circle has a radius of $${radius} cm$.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(a)$ Estimate its area.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Estimate the volume of a sphere with the same radius.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(c)$ ${Question4.question}`, false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$ Area of a circle $=\\pi r^2$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$\\pi ≈3$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$r^2=${radius2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\approx${radius2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So the area is approximately $3 \\times${Number(
          Number.parseFloat(radius2).toPrecision(1)
        )}\\approx${ans}cm^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(b)$ Volum of a sphere $={4\\over 3} \\pi r^3$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$\\pi ≈3$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("So, $ { 4\\over 3} \\pi ≈ 4$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$r^3=${radius3}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\approx${radius3}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So the volume is approximately $4 \\times${Number(
          Number.parseFloat(radius3).toPrecision(1)
        )}\\approx${ans2}cm^3$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c)$  ${Question4.solution}`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ans, ans / 5, "$(a)$");
    group.addInput(ans2, ans2 / 5, "$(b)$");
    question.addInputGroup(group);
    question.requiresSelfMarking();
    if (
      Number(Number.parseFloat(radius2).toPrecision(1)) === radius2 ||
      Math.abs(real / ans - 1) >= 0.1 ||
      Number(Number.parseFloat(radius3).toPrecision(1)) === radius3 ||
      Math.abs(real2 / ans2 - 1) >= 0.1
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


// pi
function Q3EstGen1() {
  const display = "\\pi";
  const solution = "$\\pi \\approx  3.1$";
  const approx = 3.1;
  const real = Math.PI;
  const test = true;
  return {
    display,
    solution,
    approx,
    real,
    test,
  };
}

// a*b
function Q3EstGen2() {
  // test
  const num1 = MCRandom.randomInt(1, 1000) / 1000;
  const num2 = MCRandom.randomInt(1, 1000) / 100;
  // test
  const display = `${num1} \\times${num2}`;
  let solution = `$${num1} \\times${num2}\\approx ${Number(
    Number.parseFloat(num1).toPrecision(2)
  )} \\times${Number(Number.parseFloat(num2).toPrecision(2))}$`;
  solution += `$\\approx${(
    Number(Number.parseFloat(num1).toPrecision(2)) *
    Number(Number.parseFloat(num2).toPrecision(2))
  ).toPrecision(2)}$`;
  // test
  const approx = Number(
    Number.parseFloat(
      Number(Number.parseFloat(num1).toPrecision(2)) *
        Number(Number.parseFloat(num2).toPrecision(2))
    ).toPrecision(2)
  );
  const real = num1 * num2;
  let test = true;
  // test
  if (
    Number(Number.parseFloat(num1).toPrecision(2)) === num1 &&
    Number(Number.parseFloat(num2).toPrecision(2)) === num2
  ) {
    test = false;
  }
  if (Math.abs(real / approx - 1) >= 0.1) {
    test = false;
  }
  // test
  return {
    display,
    solution,
    approx,
    real,
    test,
  };
}

// a/b
function Q3EstGen3() {
  const num1 = MCRandom.randomInt(1, 1000) / 1000;
  const num2 = MCRandom.randomInt(1, 1000) / 1000;
  // test
  // test
  const display = `{${num1} \\over ${num2}}`;
  let solution = `$ \\frac{ ${num1}} { ${num2} } \\approx \\frac{ ${Number(
    Number.parseFloat(num1).toPrecision(1)
  )} }{ ${Number(Number.parseFloat(num2).toPrecision(1))} } $`;
  solution += `$ \\approx ${(
    Number(Number.parseFloat(num1).toPrecision(1)) /
    Number(Number.parseFloat(num2).toPrecision(1))
  ).toPrecision(2)} $`;
  const approx = Number(
    Number.parseFloat(
      Number(Number.parseFloat(num1).toPrecision(1)) /
        Number(Number.parseFloat(num2).toPrecision(1))
    )
  ).toPrecision(2);
  const real = num1 / num2;
  let test = true;

  if (
    Number(Number.parseFloat(num1).toPrecision(2)) === num1 &&
    Number(Number.parseFloat(num2).toPrecision(2)) === num2
  ) {
    test = false;
  }
  if (Math.abs(real / approx - 1) >= 0.1) {
    test = false;
  }

  return {
    display,
    solution,
    approx,
    real,
    test,
  };
}

function Q3EstGen4() {
  const num = MCRandom.randomInt(2, 35);
  const real = Math.sqrt(num);
  const work = Math.floor(real);
  const work2 = work * work;

  const workPlus = work + 1;
  const workPlus2 = workPlus * workPlus;

  const bork = Math.floor(real * 10) / 10;
  const bork2 = bork * bork;

  const borkPlus = bork + 0.1;
  const borkPlus2 = borkPlus * borkPlus;

  const borkAvg = (bork + borkPlus) * 0.5;
  const borkAvg2 = borkAvg * borkAvg;

  let ans = 0;

  const display = `\\sqrt{${num}}`;
  let solution = `$${work}^2=${work2}$, `;
  solution += `$${workPlus}^2=${workPlus2}$, `;
  solution += `So, $${work}<\\sqrt{ ${num} } <${workPlus}$, `;
  solution += `$${bork}^2=${bork2}$, `;
  solution += `$${borkPlus}^2=${borkPlus2}$, `;
  solution += `So, $${bork}<\\sqrt{${num}}<${borkPlus}$, `;
  solution += `$${borkAvg}^2=${borkAvg2}$, `;

  if (borkAvg2 >= num) {
    solution += `As $${borkAvg2}>${num}$, $\\sqrt{${num}}\\approx ${bork}$`;
    ans = bork;
  }
  if (borkAvg2 <= num) {
    solution += `As $${borkAvg2}<${num}$, $\\sqrt{${num}}\\approx ${borkPlus}$`;
    ans = borkPlus;
  }

  let test = true;

  if (real === ans || borkAvg2 === num) {
    test = false;
  }

  return {
    display,
    solution,
    test,
    real,
    approx: ans,
  };
}

// ------------------------------------------------------------THESE ARE COPISES TO BE CALLED MULTIPLE TIMES ETC.------------------------------------
// a*b
function Q3EstGen5() {
  const num1 = MCRandom.randomInt(1, 1000) / 1000;
  const num2 = MCRandom.randomInt(1, 1000) / 100;

  const display = `${num1} \\times ${num2}`;
  let solution = `$ ${num1} \\times ${num2} \\approx ${Number(
    Number.parseFloat(num1).toPrecision(2)
  )} \\times ${Number(Number.parseFloat(num2).toPrecision(2))}$`;
  solution += `$\\approx${(
    Number(Number.parseFloat(num1).toPrecision(2)) *
    Number(Number.parseFloat(num2).toPrecision(2))
  ).toPrecision(2)}$`;
  const approx = Number(
    Number.parseFloat(
      Number(Number.parseFloat(num1).toPrecision(2)) *
        Number(Number.parseFloat(num2).toPrecision(2))
    ).toPrecision(2)
  );
  const real = num1 * num2;
  let test = true;

  if (
    Number(Number.parseFloat(num1).toPrecision(2)) === num1 &&
    Number(Number.parseFloat(num2).toPrecision(2)) === num2
  ) {
    test = false;
  }
  if (Math.abs(real / approx - 1) >= 0.1) {
    test = false;
  }

  return {
    display,
    solution,
    approx,
    real,
    test,
  };
}

// a/b
function Q3EstGen6() {
  const num1 = MCRandom.randomInt(1, 1000) / 1000;
  const num2 = MCRandom.randomInt(1, 1000) / 1000;
  // test
  const display = `{${num1} \\over ${num2}}`;
  let solution = `$ {${num1} \\over ${num2}}\\approx {${Number(
    Number.parseFloat(num1).toPrecision(1)
  )} \\over ${Number(Number.parseFloat(num2).toPrecision(1))}}$`;
  solution += `$\\approx${(
    Number(Number.parseFloat(num1).toPrecision(1)) /
    Number(Number.parseFloat(num2).toPrecision(1))
  ).toPrecision(2)}$`;
  const approx = Number(
    Number.parseFloat(
      Number(Number.parseFloat(num1).toPrecision(1)) /
        Number(Number.parseFloat(num2).toPrecision(1))
    ).toPrecision(2)
  );
  const real = num1 / num2;
  let test = true;

  if (
    Number(Number.parseFloat(num1).toPrecision(2)) === num1 &&
    Number(Number.parseFloat(num2).toPrecision(2)) === num2
  ) {
    test = false;
  }
  if (Math.abs(real / approx - 1) >= 0.1) {
    test = false;
  }

  return {
    display,
    solution,
    approx,
    real,
    test,
  };
}

function Q3EstGen7() {
  // test
  const num = MCRandom.randomInt(2, 35);
  const real = Math.sqrt(num);
  const work = Math.floor(real);
  const work2 = work * work;
  const workPlus = work + 1;
  const workPlus2 = workPlus * workPlus;
  const bork = Math.floor(real * 10) / 10;
  const bork2 = bork * bork;
  const borkPlus = bork + 0.1;
  const borkPlus2 = borkPlus * borkPlus;
  const borkAvg = (bork + borkPlus) * 0.5;
  const borkAvg2 = borkAvg * borkAvg;
  let ans = 0;
  const display = `\\sqrt{${num}}`;
  let solution = `$${work}^2=${work2}$`;
  solution += `$${workPlus}^2=${workPlus2}$`;
  solution += `So, $${work}<\\sqrt{${num}}<${workPlus}$`;
  solution += `$${bork}^2=${bork2}$`;
  solution += `$${borkPlus}^2=${borkPlus2}$`;
  solution += `So, $${bork}<\\sqrt{${num}}<${borkPlus}$`;
  solution += `$${borkAvg}^2=${borkAvg2}$`;

  if (borkAvg2 >= num) {
    solution += `As $${borkAvg2}>${num}$, $\\sqrt{${num}}\\approx ${bork}$`;
    ans = bork;
  }
  if (borkAvg2 <= num) {
    solution += `As $${borkAvg2}<${num}$, $\\sqrt{${num}}\\approx ${borkPlus}$`;
    ans = borkPlus;
  }

  let test = true;

  if (real === ans || borkAvg2 === num) {
    test = false;
  }

  return {
    display,
    solution,
    test,
    real,
    approx: ans,
  };
}

// ------------------------------------------------------------THESE ARE COPISES TO BE CALLED MULTIPLE TIMES ETC.------------------------------------
// a*b
function Q3EstGen8() {
  // test
  const num1 = MCRandom.randomInt(1, 1000) / 1000;
  const num2 = MCRandom.randomInt(1, 1000) / 100;
  // test
  // test
  const display = `${num1} \\times${num2}`;
  let solution = `$${num1} \\times${num2}\\approx ${Number(
    Number.parseFloat(num1).toPrecision(2)
  )} \\times${Number(Number.parseFloat(num2).toPrecision(2))}$`;
  solution += `$\\approx${(
    Number(Number.parseFloat(num1).toPrecision(2)) *
    Number(Number.parseFloat(num2).toPrecision(2))
  ).toPrecision(2)}$`;
  const approx = Number(
    Number.parseFloat(
      Number(Number.parseFloat(num1).toPrecision(2)) *
        Number(Number.parseFloat(num2).toPrecision(2))
    ).toPrecision(2)
  );
  const real = num1 * num2;
  let test = true;

  if (
    Number(Number.parseFloat(num1).toPrecision(2)) === num1 &&
    Number(Number.parseFloat(num2).toPrecision(2)) === num2
  ) {
    test = false;
  }
  if (Math.abs(real / approx - 1) >= 0.1) {
    test = false;
  }

  return {
    display,
    solution,
    approx,
    real,
    test,
  };
}

// a/b
function Q3EstGen9() {
  // test
  const num1 = MCRandom.randomInt(1, 1000) / 1000;
  const num2 = MCRandom.randomInt(1, 1000) / 1000;
  // test
  // test
  const display = `{${num1} \\over ${num2}}`;
  let solution = `$ {${num1} \\over ${num2}}\\approx {${Number(
    Number.parseFloat(num1).toPrecision(1)
  )} \\over ${Number(Number.parseFloat(num2).toPrecision(1))}}$`;
  solution += `$\\approx${(
    Number(Number.parseFloat(num1).toPrecision(1)) /
    Number(Number.parseFloat(num2).toPrecision(1))
  ).toPrecision(2)}$`;
  const approx = Number(
    Number.parseFloat(
      Number(Number.parseFloat(num1).toPrecision(1)) /
        Number(Number.parseFloat(num2).toPrecision(1))
    ).toPrecision(2)
  );
  const real = num1 / num2;
  let test = true;

  if (
    Number(Number.parseFloat(num1).toPrecision(2)) === num1 &&
    Number(Number.parseFloat(num2).toPrecision(2)) === num2
  ) {
    test = false;
  }
  if (Math.abs(real / approx - 1) >= 0.1) {
    test = false;
  }

  return {
    display,
    solution,
    approx,
    real,
    test,
  };
}

export function GCSE_Foundation_Number_Maa_Estimation_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const QList = [
      Q3EstGen1(),
      Q3EstGen2(),
      Q3EstGen3(),
      Q3EstGen4(),
      Q3EstGen5(),
      Q3EstGen6(),
      Q3EstGen7(),
      Q3EstGen8(),
      Q3EstGen9(),
    ];
    const mode1 = MCRandom.randomInt(0, QList.length - 1);
    const mode2 = MCRandom.randomInt(0, QList.length - 1);
    const mode3 = MCRandom.randomInt(0, QList.length - 1);
    const mode4 = MCRandom.randomInt(0, QList.length - 1);
    const num1 = QList[mode1];
    const num2 = QList[mode2];
    const num3 = QList[mode3];
    const num4 = QList[mode4];
    const average = (num1.real + num2.real + num3.real + num4.real) / 4;
    let win = true;
    if (num1.approx === num2.approx) {
      win = false;
    }
    if (num1.approx === num3.approx) {
      win = false;
    }
    if (num1.approx === num4.approx) {
      win = false;
    }
    if (num2.approx === num3.approx) {
      win = false;
    }
    if (num2.approx === num4.approx) {
      win = false;
    }
    if (num3.approx === num4.approx) {
      win = false;
    }
    if (Math.abs(num1.approx - average) >= 0.5) {
      win = false;
    }
    if (Math.abs(num2.approx - average) >= 0.5) {
      win = false;
    }
    if (Math.abs(num3.approx - average) >= 0.5) {
      win = false;
    }
    if (Math.abs(num4.approx - average) >= 0.5) {
      win = false;
    }
    const approxList = [num1, num2, num3, num4];
    approxList.sort(function (a, b) {
      return a.approx - b.approx;
    });

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "By estimating the value of each, order from smallest to largest:",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${num1.display},\\ ${num2.display},\\ ${num3.display},\\ ${num4.display}$`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${num1.solution}`, false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${num2.solution}`, false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${num3.solution}`, false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${num4.solution}`, false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Ordering the numbers: $${approxList[0].approx},\\ ${approxList[1].approx},\\ ${approxList[2].approx},\\ ${approxList[3].approx}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$= ${approxList[0].display},\\ ${approxList[1].display},\\ ${approxList[2].display},\\ ${approxList[3].display}$`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    if (
      num1.test === false ||
      num2.test === false ||
      num3.test === false ||
      num4.test === false ||
      mode1 === mode2 ||
      mode1 === mode3 ||
      mode1 === mode4 ||
      mode2 === mode3 ||
      mode2 === mode4 ||
      mode3 === mode4 ||
      win === false ||
      Math.round(100 * approxList[1].approx) !== 100 * approxList[1].approx ||
      Math.round(100 * approxList[2].approx) !== 100 * approxList[2].approx ||
      Math.round(100 * approxList[3].approx) !== 100 * approxList[3].approx ||
      Math.round(100 * approxList[0].approx) !== 100 * approxList[0].approx
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_Maa_Estimation_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const n = MCRandom.randomInt(1, 200);
    const d = MCRandom.randomInt(1, 200);

    const name = MCMisc.getName();
    const fraction1 = n / d;
    const mass = MCRandom.randomInt(100, 1200);

    const n2 = Number(Number.parseFloat(n).toPrecision(1));
    const d2 = Number(Number.parseFloat(d).toPrecision(1));

    const approxFraction = n2 / d2;

    const ans = Number(Number.parseFloat(mass).toPrecision(2)) * approxFraction;
    const real = fraction1 * mass;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`A cake weights $${mass} g $.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} eats $${fraction1}$ of the cake.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Estimate how much cake ${name.name} has eaten.`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${fraction1}≈{${n2}\\over${d2}}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$≈${approxFraction}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, $${mass} \\times${fraction1}≈ ${Number(
          Number.parseFloat(mass).toPrecision(2)
        )} \\times${approxFraction}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\approx${
          Number(Number.parseFloat(mass).toPrecision(2)) * approxFraction
        }g$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, ${name.name} eats about $${ans}g$ of cake.`,
        true,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, ans / 5);
    question.addInputGroup(group);

    if (
      n === n2 ||
      d === d2 ||
      fraction1 >= 0.4 ||
      approxFraction >= 0.4 ||
      approxFraction <= 0.01 ||
      fraction1 <= 0.01 ||
      Math.abs(real / ans - 1) >= 0.1 ||
      Math.round(ans) !== ans
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Maa_Measures_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const ans = 1;

    const examples = [
      "The distance between two cities",
      "The height of a building",
      "The height of a person",
      "The weight of a person",
      "The length of a pencil",
      "The speed of a car",
    ];
    const unit_metric = [
      "Kilometres",
      "Metres",
      "Centimetres or Metres",
      "Kilograms",
      "Centimetres",
      "Kilometres per hour",
    ];
    const unit_imperial = [
      "Miles",
      "Feet",
      "Feet and Inches      ",
      "Pounds or Stone",
      "Inches",
      "Miles per hour",
    ];

    const type1 = MCRandom.randomInt(0, examples.length - 1);
    const type2 = MCRandom.randomInt(0, examples.length - 1);
    const type3 = MCRandom.randomInt(0, examples.length - 1);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", `What is a suitable measure for:`);
    question.addParagraph(
      "question",
      `$(a)$ ${examples[type1]} in metric units,`
    );
    question.addParagraph(
      "question",
      `$(b)$ ${examples[type2]} in metric units,`
    );
    question.addParagraph(
      "question",
      `$(c)$ ${examples[type3]} in imperial units.`
    );
    question.addParagraph("solution", `$(a)$ ${unit_metric[type1]}`);
    question.addParagraph("solution", `$(b)$ ${unit_metric[type2]}`);
    question.addParagraph("solution", `$(c)$ ${unit_imperial[type3]}`);

    question.requiresSelfMarking();

    if (type1 === type2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_Maa_Measures_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const units1 = ["kilometres", "metres", "millilitres"];
    const units1b = ["km", "m", "ml"];
    const units2 = ["m", "km", "l"];
    const units3 = ["a stone", "a foot"];
    const units3b = ["pounds", "inches"];
    const units4 = ["pounds", "inches"];
    const units4b = ["stone", "feet"];
    const ratios1 = [0.001, 1000, 1000];
    const ratios2 = [14, 12];

    const type1 = MCRandom.randomInt(0, units1.length - 1);
    const type2 = MCRandom.randomInt(0, units3.length - 1);

    const n1 = MCRandom.randomInt(10, 10000, 10);
    const ans1 = n1 * ratios1[type1];
    const n2 = MCRandom.randomInt(10, 10000, 10);
    const ans2 = n2 * ratios2[type2];

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", `How many:`);
    question.addParagraph(
      "question",
      `$(a)$ ${units1[type1]} are in $${n1}${units2[type1]}$.`
    );
    question.addParagraph(
      "question",
      `Given that there are $${ratios2[type2]}$ ${units4[type2]} in ${units3[type2]}:`
    );
    question.addParagraph(
      "question",
      `$(b)$ How many ${units3b[type2]} are in $${n2}$ ${units4b[type2]}.`
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${ans1}${units1b[type1]} $`, false, false, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${ans2}$ ${units3b[type2]}`, false, false, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ans1, 0.01, "$(a)$");
    group.addInput(ans2, 0.01, "$(b)$");
    question.addInputGroup(group);

    if (ans1 > 10000 || ans2 > 1000) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_Maa_Measures_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();

    const question = new MCQuestion.ExamQuestion();

    const pPerCM = MCRandom.randomInt(2, 12);
    const amount = MCRandom.randomInt(10, 100, 5);
    const ans = pPerCM * amount;

    const context1 = ["planks of wood", "lengths of pipe", "legths of wire"];
    const context2 = ["planks", "pipes", "wire"];
    const mode = MCRandom.randomInt(0, context1.length - 1);

    question.addParagraph(
      "question",
      `${name.name} is buying ${context1[mode]}.`
    );
    question.addParagraph("question", `These planks cost $${pPerCM}p$ per cm.`);
    question.addParagraph(
      "question",
      `$(a)$ Find the cost of the ${context2[mode]} in $\\pounds / m$.`
    );
    question.addParagraph(
      "question",
      `$(b)$ Find the cost of buying $${amount}m$ of ${context2[mode]}.`
    );
    question.addParagraph(
      "solution",
      `$(a) \\ 1m $ costs $${pPerCM * 100}p=\\pounds ${pPerCM}$, so the ${
        context2[mode]
      } cost $\\pounds ${pPerCM} /m$.`
    );
    question.addParagraph(
      "solution",
      `$(b)  \\ \\pounds ${pPerCM} /m \\times ${amount}m = \\pounds ${ans}$.`
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(pPerCM, 0.01, "$(a) \\ \\pounds/m$");
    group.addInput(ans, 0.01, "$(b) \\ \\pounds $");
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_Maa_Measures_Exam_Q4() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();

    const question = new MCQuestion.ExamQuestion();

    const metric = MCRandom.randomInt(200, 500, 10);
    const imperial =
      Math.round(metric / 2.2) + MCRandom.randomInt(-100, 100, 10);

    question.addParagraph(
      "question",
      `${name.name} is buying clothes at a charity sale, where clothes are priced by their weight.`
    );
    question.addParagraph(
      "question",
      `${name.HeShe} can choose to either pay $ ${DisplayMoney(
        metric
      )}$ per kilo, or $${DisplayMoney(imperial)}$ per pound.`
    );
    question.addParagraph(
      "question",
      `Given that $2.2lbs = 1kg$, which option is cheaper?`
    );

    question.addParagraph(
      "solution",
      `Converting the imperial measure: $${DisplayMoney(
        imperial
      )} / lbs =  ${DisplayMoney(imperial * 2.2)} /kg $.`
    );
    if (imperial * 2.2 > metric) {
      question.addParagraph(
        "solution",
        `Hence, weighing the clothes in metric is cheaper.`
      );
    } else {
      question.addParagraph(
        "solution",
        `Hence, weighing the clothes in imperial is cheaper.`
      );
    }

    question.requiresSelfMarking();

    if (
      Math.round(imperial * 2.2) === Math.round(metric) ||
      Math.abs(metric - imperial * 2.2) > 10
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_Maa_Rounding_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const FracDe = MCRandom.randomInt(6, 13);
    const divedDisplay = 60 / FracDe;
    const ans = Math.round(60 / FracDe);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} works for $1 \\over${FracDe} $ of an hour. Round this to the nearest minute.`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$60  \\div ${FracDe} =${divedDisplay}$ which rounds to $${ans}$ minutes.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0);
    question.addInputGroup(group);

    if (Math.round(60 / FracDe) === 60 / FracDe) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Maa_Rounding_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const Potats = MCRandom.randomInt(10, 40);
    const Guest = MCRandom.randomInt(2, 3);
    const ans = Potats / Guest;
    const ansR = Math.floor(ans);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has $${Potats}$ roast potatoes, and ${name.hisher} guests want $${Guest}$ potatoes each.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `How many people can ${name.name} feed?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${Potats}  \\div ${Guest}=${ans}$, which rounds down to $${ansR}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So ${name.name} can feed $${ansR}$ guests.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ansR, 0);
    question.addInputGroup(group);

    if (Math.round(Potats / Guest) === Potats / Guest) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Maa_Rounding_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const n1 = MCRandom.randomInt(4, 950);
    const n1d = 1 + n1 / 1000;
    const n2 = MCRandom.randomInt(4, 950);
    const n3 = MCRandom.randomInt(1, 10);
    const n2d = n3 + n2 / 1000;
    const ans1 = Math.round(n1d);
    const ans2 = Math.round(10 * n2d) / 10;

    const tens = MCRandom.randomInt(11, 300);
    const ans3 = Math.round(tens);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Round $${n1d}$ to the nearest whole number.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Round $${tens}$ to the nearest ten.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(c)$ Round $${n2d}$ to $2$s.f.`, false, true, true)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a)\\ ${ans1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b)\\ ${ans3}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c)\\ ${ans2}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans1, 0, "$(a)$");
    group.addInput(ans3, 0, "$(b)$");
    group.addInput(ans2, 0, "$(c)$");
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Maa_Rounding_Exam_Q4() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
      }
      return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const Money = MCRandom.randomInt(9, 30) * 10;
    const Price = MCRandom.randomInt(5, 20);
    const MoneyD = DisplayMoney(Money);
    const ansD = Money / Price;
    const ans = Math.floor(Money / Price);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `${name.name} goes to the shop with $${MoneyD}$. `
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} wants to buy sweets that cost $${Price} p$ each.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `How many sweets can ${name.name} buy?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${Money}  \\div ${Price} = ${ansD}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `This rounds down to $${ans}$, so ${name.name} can buy $${ans}$ sweets.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0);
    question.addInputGroup(group);

    if (ans === Money / Price || ans <= 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Maa_Rounding_Exam_Q5() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const Dist = MCRandom.randomInt(100, 900, 100);
    const Total = MCRandom.randomInt(2, 10);
    const ansD = (Total * 1000) / Dist;
    const ans = Math.floor((Total * 1000) / Dist);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} goes on a jog. He decides he will eat a sweet for every $${Dist} m$ he jogs.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `If ${name.name} jogs $${Total} km$, how many sweets will he eat?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${
          Total * 1000
        }  \\div ${Dist} = ${ansD}$ which rounds down to $${ans}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, ${name.name} will eat $${ans}$ sweets.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0);
    question.addInputGroup(group);

    if (
      ans <= 0 ||
      (Total * 1000) / Dist === Math.round((Total * 1000) / Dist)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_Maa_Rounding_Exam_Q6() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const Cap = MCRandom.randomInt(10000, 80000);
    const ans = Math.round(Cap / 5000) * 5000;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A football stadium has $${Cap}$ people in it. How many people is the rounded to the nearest $5000$?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${ans}$ people.`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0);
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_ExactValues_Exam_Q1() {
  let count = 0;
  const img_scale = 3;

  function isSquare(number) {
    if (Math.sqrt(number) % 1 === 0) {
      return true;
    }
    return false;
  }

  for (let i = 1; i <= 1; i += 1) {
    const a = MCRandom.randomInt(1, 20);
    const b = MCRandom.randomInt(2, 8);
    const c = MCRandom.randomInt(3, 12);
    const d = MCRandom.randomInt(1, 20);
    const e = MCRandom.randomInt(2, 8);
    const chain1 = `${a}+${b}\\sqrt{${c}}`;
    const chain2 = `${d}+${e}\\sqrt{${c}}`;

    const fraction = `\\frac{${d} - ${e}\\sqrt{${c}}}{${d} - ${e}\\sqrt{${c}}}`;
    const chainSol1 = `${a * d}+${a * -e}\\sqrt{${c}}+${b * d}\\sqrt{${c}}+${
      b * -e * c
    }`;
    const chainSol2 = `${a * d + b * -e * c}+${a * -e}${b * d}\\sqrt{${c}}`;
    const chainSol3 = `${d * d}+${e * -e * c}`;
    const chainSol4 = d * d + e * -e * c;
    const constantTerm = (a * d + b * -e * c) / (d * d + e * -e * c);
    const root = (a * -e + b * d) / (d * d + e * -e * c);
    const chainSol5 = `${constantTerm}+${root}\\sqrt{${c}}`;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Express $\\frac{${chain1}}{${chain2}}$ in the form $p + q\\sqrt{${c}}$ where $p$ and $q$ are rational numbers.`,
        false
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addHeading(
      "solution",
      MCMaths.cleaner("Numerator:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(${chain1})(${d} - ${e}\\sqrt{${c}})$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$ = ${chainSol1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$ = ${chainSol2}$`, false, true, true)
    );
    question.addHeading(
      "solution",
      MCMaths.cleaner("Denominator:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(${chain2})(${d} - ${e}\\sqrt{${c}})$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$ = ${chainSol3}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$ = ${chainSol4}$`, false, true, true)
    );
    question.addHeading(
      "solution",
      MCMaths.cleaner("Answer:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\frac{${chainSol2}}{${chainSol4}}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$=${chainSol5}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(constantTerm, 0.1, "$p=$");
    group.addInput(root, 0.1, "$q=$");
    question.addInputGroup(group);

    if (
      isSquare(c) === true ||
      b <= e ||
      a === d ||
      (d % 2 === 0 && e % 2 === 0) ||
      d === 1 ||
      d * d + e * -e * c === 1 ||
      constantTerm === 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_ExactValues_Exam_Q2() {
  let count = 0;
  const img_scale = 3;

  function isSquare(number) {
    if (Math.sqrt(number) % 1 === 0) {
      return true;
    }
    return false;
  }

  for (let i = 1; i <= 1; i += 1) {
    const a1 = MCRandom.randomInt(5, 25);
    const rootNums = [
      3,
      5,
      6,
      7,
      8,
      10,
      11,
      12,
      13,
      14,
      15,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      26,
      27,
      28,
      29,
      30,
    ];
    const rootNum = rootNums[MCRandom.randomInt(0, 23)];
    const rootMultipliers = [9, 16, 25, 36];
    const rootMultiplier = rootMultipliers[MCRandom.randomInt(0, 3)];
    const sqrtOfMultiplier = Math.sqrt(rootMultiplier);
    const largerRoot = rootNum * rootMultiplier;
    const b1 = MCRandom.randomInt(2, 12);
    const b2 = b1 * sqrtOfMultiplier;
    const equalToRoot = MCRandom.randomInt(20, 250);
    const otherNum = equalToRoot / rootNum;
    const equalToSimple = `${Math.sqrt(otherNum)}\\sqrt{${rootNum}}`;

    const insideBracket = `${a1 - b2}\\sqrt{${rootNum}}`;
    const constantFromBracket = a1 - b2;
    const constantRHS = Math.sqrt(otherNum);
    const multiplierRequired = constantRHS / constantFromBracket;
    const fracMult = multiplierRequired;

    const numerator = constantRHS;
    const denominator = constantFromBracket;

    const chain3 = `${multiplierRequired}(${a1}\\sqrt{${rootNum}}-${b1}x)`;
    const chainSol1 = `${numerator * a1}\\sqrt{${rootNum}}+${numerator * -b1}x`;
    const chainSol2 = `${Math.sqrt(otherNum)}\\sqrt{${rootNum}}`;
    const chainSol3 = `${
      numerator * a1 - denominator * Math.sqrt(otherNum)
    }\\sqrt{${rootNum}}`;
    const x =
      (numerator * a1 - denominator * Math.sqrt(otherNum)) / (numerator * b1);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("Solve the following equation:", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(` $${chain3} = \\sqrt{${equalToRoot}}$`, true, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Give your answer in its simplest form.",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${chainSol1} = ${denominator}\\sqrt{${equalToRoot}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\sqrt{${equalToRoot}} = \\sqrt{${rootNum}}  \\times \\sqrt{${otherNum}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore ${chainSol1} = ${denominator}  \\times \\sqrt{${rootNum}}  \\times \\sqrt{${otherNum}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${chainSol1} = ${denominator}  \\times ${chainSol2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${chainSol1} = ${
          denominator * Math.sqrt(otherNum)
        }\\sqrt{${rootNum}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${chainSol3} = ${numerator * b1}x$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x = ${x}\\sqrt{${rootNum}}$`, false, true, true)
    );

    question.requiresSelfMarking();

    if (
      a1 - b2 <= 2 ||
      isSquare(equalToRoot) === true ||
      equalToRoot % rootNum !== 0 ||
      isSquare(otherNum) === false ||
      constantFromBracket === constantRHS ||
      fracMult === null
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_ExactValues_Exam_Q3() {
  let count = 0;
  const img_scale = 3;

  function isSquare(number) {
    if (Math.sqrt(number) % 1 === 0) {
      return true;
    }
    return false;
  }

  for (let i = 1; i <= 1; i += 1) {
    const x = MCRandom.randomInt(2, 5);
    const y = MCRandom.randomInt(2, 5);
    const xRoot = 6;
    const yRootMultOf6 = MCRandom.randomInt(2, 180);
    const yRoot = 6 * yRootMultOf6;
    const numChain = `${x}\\sqrt{${xRoot}}+${y}\\sqrt{${yRoot}}`;
    const denominator = 2 * x + 2 * y * Math.sqrt(yRootMultOf6);
    const solChain1 = `${x}\\sqrt{${xRoot / 2}}+${y}\\sqrt{${yRoot / 2}}`;
    const solChain2 = `${x}\\sqrt{${xRoot / 2}}+${y} \\times${Math.sqrt(
      yRootMultOf6
    )}\\sqrt{${xRoot / 2}}`;
    const solChain3 = `${x}\\sqrt{${xRoot / 2}}+${
      y * Math.sqrt(yRootMultOf6)
    }\\sqrt{${xRoot / 2}}`;
    const solChain4 = `${x + y * Math.sqrt(yRootMultOf6)}\\sqrt{${xRoot / 2}}`;
    const caseChosen = MCRandom.randomInt(1, 4);
    let trigExpression;
    let expressionRHS;
    let xEquals;
    let reciprocate;
    switch (caseChosen) {
      case 1:
        trigExpression = "$\\sin(x)$";
        xEquals = 60;
        expressionRHS = `$\\frac{${numChain}}{${denominator}\\sqrt{\\frac{1}{2}}}$`;
        reciprocate = false;
        break;
      case 2:
        trigExpression = "$\\cos(x)$";
        xEquals = 30;
        expressionRHS = `$\\frac{${numChain}}{${denominator}\\sqrt{\\frac{1}{2}}}$`;
        reciprocate = false;
        break;
      case 3:
        trigExpression = "$\\tan(x)$";
        xEquals = 30;
        expressionRHS = `$\\frac{${denominator}\\sqrt{\\frac{1}{2}}}{${numChain}}$`;
        reciprocate = true;
        break;
      case 4:
        trigExpression = "$\\tan(x)$";
        xEquals = 60;
        expressionRHS = `$\\frac{${numChain}}{${denominator}\\sqrt{\\frac{1}{2}}}$`;
        reciprocate = false;
        break;
      default:
        break;
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Show that $\\frac{${numChain}}{${denominator}\\sqrt{\\frac{1}{2}}}$ can be expressed in the form $\\sqrt{k}$ where $k$ is an integer.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Hence, solve the following equation:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${trigExpression} $=$ ${expressionRHS}`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a) \\ \\frac{${numChain}}{${denominator}\\sqrt{\\frac{1}{2}}}  \\times \\frac{\\sqrt{\\frac{1}{2}}}{\\sqrt{\\frac{1}{2}}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\frac{${solChain1}}{${denominator}  \\times \\frac{1}{2}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\frac{${solChain1}}{${denominator / 2}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\sqrt{${yRoot / 2}} = \\sqrt{${
          xRoot / 2
        }}  \\times \\sqrt{${yRootMultOf6}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\sqrt{${yRoot / 2}} = ${Math.sqrt(yRootMultOf6)}\\sqrt{${
          xRoot / 2
        }}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore \\frac{${solChain2}}{${denominator / 2}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\frac{${solChain3}}{${denominator / 2}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\frac{${solChain4}}{${denominator / 2}} = \\sqrt{${xRoot / 2}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$k = ${xRoot / 2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(b)$ From knowledge of exact values:",
        false,
        true,
        true
      )
    );
    if (reciprocate === true) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `${trigExpression} $= \\frac{1}{\\sqrt{3}}$`,
          false,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`${trigExpression} $= \\sqrt{3}$`, false, true, true)
      );
    }
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\therefore x = ${xEquals}^\\circ$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(xRoot / 2, 0, "$(a) \\ k=$");
    group.addInput(xEquals, 0, "$(b) \\ x=$");
    question.addInputGroup(group);

    if (isSquare(yRootMultOf6) === false || (x % 2 === 0 && y % 2 === 0)) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_ExactValues_Exam_Q4() {
  let count = 0;
  const img_scale = 3;

  function isSquare(number) {
    if (Math.sqrt(number) % 1 === 0) {
      return true;
    }
    return false;
  }

  for (let i = 1; i <= 1; i += 1) {
    const m = MCRandom.randomInt(2, 12);
    const n = MCRandom.randomInt(-1, 1);
    let x = MCRandom.randomInt(2, 45);
    if (n === -1) {
      x *= -1;
    }
    const rootNum = MCRandom.randomInt(2, 12);
    const chainLHS = `${m}+${n}\\sqrt{a}`;
    const chainRHS = `${1}b+${x}\\sqrt{${rootNum}}`;
    const chainSol1 = `${m * m}+${2 * m * n}\\sqrt{a}+${n * n}a`;
    const chainSol2 = `${1}b+${x}\\sqrt{${rootNum}}`;
    const chainSol3 = `${m * m}+${n * n}a`;
    const chainSol4 = `${1}(${chainSol3})+${2 * m * n}\\sqrt{a}`;
    let chainSol5;
    if (n === -1) {
      chainSol5 = `${x * -1}\\sqrt{${rootNum}}`;
    } else {
      chainSol5 = `${x}\\sqrt{${rootNum}}`;
    }
    const chainSol6 = `${m * m}+${n * n * (x / (2 * m)) ** 2 * rootNum}`;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find positive integer values of $a$ and $b$ given that:",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(${chainLHS})^{2} \\equiv ${chainRHS}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${chainSol1} \\equiv ${chainSol2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${chainSol4} \\equiv ${chainSol2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${chainSol4} \\equiv ${chainSol2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$b \\equiv ${chainSol3}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${2 * m}\\sqrt{a} \\equiv ${chainSol5}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Find $a$:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${2 * m}\\sqrt{a} \\equiv ${2 * m}  \\times ${
          x / (2 * m)
        }\\sqrt{${rootNum}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${2 * m}\\sqrt{a} \\equiv ${2 * m}  \\times \\sqrt{${
          (x / (2 * m)) ** 2
        }}  \\times \\sqrt{${rootNum}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${2 * m}\\sqrt{a} \\equiv ${2 * m}\\sqrt{${
          (x / (2 * m)) ** 2 * rootNum
        }}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$a = ${(x / (2 * m)) ** 2 * rootNum}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Find $b$:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$b \\equiv ${chainSol6}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$b = ${m * m + n * n * (x / (2 * m)) ** 2 * rootNum}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput((x / (2 * m)) ** 2 * rootNum, 0.1, "$a=$");
    group.addInput(m * m + n * n * (x / (2 * m)) ** 2 * rootNum, 0.1, "$b=$");
    question.addInputGroup(group);

    if (
      n === 0 ||
      isSquare(rootNum) === true ||
      x % (2 * m) !== 0 ||
      x < 2 * m ||
      x / (2 * m) === 1
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_ExactValues_Exam_Q5() {
  let count = 0;
  const img_scale = 1.4;

  function isSquare(number) {
    if (Math.sqrt(number) % 1 === 0) {
      return true;
    }
    return false;
  }

  for (let i = 1; i <= 1; i += 1) {
    const n = MCRandom.randomInt(2, 20);
    const x = MCRandom.randomInt(2, 20);
    const m = MCRandom.randomInt(2, 20);
    const sol = (n * 2) / m + Math.sqrt(x * 2 * m);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image(
      "GCSE/NonRightangleTriangle/Cosine/1c.jpg",
      "height",
      15
    );
    img.addOverlay("$45^\\circ$", 35 / img_scale + 6, 20 / img_scale + 2);
    img.addOverlay("$C$", 37 / img_scale + 1, -5);
    img.addOverlay("$B$", -7, 130 / img_scale);
    img.addOverlay("$A$", 150 / img_scale - 2, 135 / img_scale + 4);
    img.addOverlay("$k cm$", 0, 45 / img_scale + 10);
    img.addOverlay(`$\\sqrt{${m}} cm$`, 100 / img_scale, 55 / img_scale);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Triangle $ABC$ has area $${n}\\sqrt{${x}} cm^2$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Find the value of $k$ to $3dp$.", false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Area = $\\frac{1}{2} \\times a  \\times b  \\times \\sin(C)$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Area = $\\frac{1}{2} \\times k  \\times \\sqrt{${m}}  \\times \\frac{1}{\\sqrt{2}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Area = $\\frac{k\\sqrt{${m}}}{2\\sqrt{2}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore \\frac{k\\sqrt{${m}}}{2\\sqrt{2}} = ${n}\\sqrt{${x}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$k\\sqrt{${m}} = ${n * 2}\\sqrt{${x * 2}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$k = \\frac{${n * 2}\\sqrt{${x * 2}}}{\\sqrt{${m}}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$k = \\frac{${n * 2}\\sqrt{${
          x * 2
        }}}{\\sqrt{${m}}}  \\times \\frac{\\sqrt{${m}}}{\\sqrt{${m}}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$k = \\frac{${n * 2}\\sqrt{${x * 2 * m}}}{${m}}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$k \\approx ${sol.toFixed(3)}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(sol, 0.1, "$k=$");
    question.addInputGroup(group);

    if (
      isSquare(x) === true ||
      isSquare(m) === true ||
      (x * 2) % m === 0 ||
      n === x ||
      x === m ||
      n === m
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const hotelARooms = MCRandom.randomInt(500, 1000);
    const hotelBRooms = MCRandom.randomInt(500, 1000);
    const hotelCRooms = MCRandom.randomInt(500, 1000);
    const totalRooms = hotelARooms + hotelBRooms + hotelCRooms;
    const sum = hotelARooms + hotelBRooms;
    const difference = hotelCRooms - hotelBRooms;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "There are three hotels on a street, hotel A, hotel B and hotel C. Each has a different number of rooms.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`There are $${totalRooms}$ rooms in total.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The total number of rooms in hotel A and hotel B is $${sum}$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The difference between the number of rooms in hotel B and hotel C is $${difference}$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Work out the number of rooms in each hotel.", false)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Total rooms = rooms in hotel A $+$ rooms in hotel B $+$ rooms in hotel C",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`A $+$ B $+$ C = $${totalRooms}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Number of rooms in both hotel A and B = $${sum}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`A $+$ B = $${sum}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Rooms in both hotel A and hotel B $+$ hotel C = Total Rooms",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${sum}$ $+$ C = $${totalRooms}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Rooms in hotel C = Total Rooms $-$ Rooms in both A and B",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`C = $${totalRooms} - ${sum}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`C = $${hotelCRooms}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Rooms in hotel C $-$ rooms in hotel B = $${difference}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${hotelCRooms}$ $-$ B = $${difference}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${hotelCRooms}$ = $${difference}$ $+$ B`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`B = $${hotelBRooms}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Rooms in hotel A $+$ rooms in hotel B = $${sum}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`A $+$ $${hotelBRooms}$ = $${sum}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`A = $${sum} - ${hotelBRooms}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`A = $${hotelARooms}$`, false)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(hotelARooms, 0, "$A$");
    group.addInput(hotelBRooms, 0, "$B$");
    group.addInput(hotelCRooms, 0, "$C$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      hotelARooms === hotelBRooms ||
      hotelARooms === hotelCRooms ||
      hotelBRooms === hotelCRooms ||
      difference < 50
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q10() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
      }
      return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const price1 = MCRandom.randomInt(300, 1500);
    const price2 = MCRandom.randomInt(300, 1500);
    const item1ListC = ["CDs", "DVDs"];
    const item1List = ["CDs", "DVDs"];
    const item2ListC = ["Vinyls", "Blu-Ray discs"];
    const item2List = ["vinyls", "Blu-Ray discs"];
    const num1 = MCRandom.randomInt(2, 6);
    const num2 = MCRandom.randomInt(2, 6);

    const total = MCRandom.randomInt(1000, 5000, 500);

    const mode = MCRandom.randomInt(0, item2List.length - 1);
    const Item1 = item1ListC[mode];
    const item1 = item1List[mode];
    const Item2 = item2ListC[mode];
    const item2 = item2List[mode];

    const sub1 = num1 * price1;
    const sub2 = num2 * price2;
    const sub3 = sub2 + sub1;
    const ans = total - sub3;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `${Item1} each cost $${DisplayMoney(price1)}$`
    );
    question.addParagraph(
      "question",
      `${Item2} each cost $${DisplayMoney(price2)}$`
    );
    question.addParagraph(
      "question",
      `${name.name} has $${DisplayMoney(
        total
      )}$ and buys $${num1}$ ${item1} and $${num2}$ ${item2}.`
    );
    question.addParagraph(
      "question",
      `Work how much change ${name.heshe} should receive.`
    );
    question.addParagraph(
      "solution",
      `$(${num1} \\times${DisplayMoney(price1)})+(${num2} \\times${DisplayMoney(
        price2
      )})=${DisplayMoney(sub1)}+${DisplayMoney(sub2)}=${DisplayMoney(sub3)}$`
    );
    question.addParagraph(
      "solution",
      `$${DisplayMoney(total)}-${DisplayMoney(sub3)}=${DisplayMoney(ans)}$`
    );
    question.addParagraph(
      "solution",
      ` So, ${name.name} should receive $${DisplayMoney(ans)}$ in change.`
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans / 100, 0.01);
    question.addInputGroup(group);

    if (price1 === price2 || ans <= 50) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q11() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
      }
      return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const money = MCRandom.randomInt(500, 2000, 100);
    const price = MCRandom.randomInt(40, 99);
    const item1ListC = ["Donuts", "Gingerbread men", "Cookies"];
    const item1List = ["donuts", "gingerbread men", "cookies"];
    const mode = MCRandom.randomInt(0, item1List.length - 1);
    const Item1 = item1ListC[mode];
    const item1 = item1List[mode];

    const working = money / price;
    const numberBought = Math.floor(money / price);

    const spent = numberBought * price;

    const change = money - spent;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `${Item1} each cost $${DisplayMoney(price)}$.`
    );
    question.addParagraph(
      "question",
      `${name.name} has $${DisplayMoney(money)}$ and buys as many as ${
        name.heshe
      } can.`
    );
    question.addParagraph(
      "question",
      `$(a)$ How many ${item1} can ${name.name} buy?`
    );
    question.addParagraph(
      "question",
      `$(b)$ How much change should ${name.name} get?`
    );
    question.addParagraph(
      "solution",
      `$(a) ${DisplayMoney(money)} \\div${DisplayMoney(
        price
      )}=${working.toFixed(3)}...$, which rounds down to $${numberBought}$.`
    );
    question.addParagraph(
      "solution",
      `So, ${name.name} can buy $${numberBought}$ ${item1}.`
    );
    question.addParagraph(
      "solution",
      `$(b) ${numberBought} \\times${DisplayMoney(price)}=${DisplayMoney(
        spent
      )}$`
    );
    question.addParagraph(
      "solution",
      `$${DisplayMoney(money)}-${DisplayMoney(spent)}=${DisplayMoney(change)}$`
    );
    question.addParagraph(
      "solution",
      `So, ${name.name} gets $${DisplayMoney(change)}$ in change.`
    );

    const group = new MCQuestion.InputGroup(2);

    group.addInput(numberBought, 0, "$(a)$");

    group.addInput(change, 0.01, "$(b)$");
    question.addInputGroup(group);

    if (working === numberBought || change <= 3 || change >= price - 4) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q12() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
      }
      return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const rate = MCRandom.randomInt(420, 795);
    const hours = MCRandom.randomInt(10, 60);
    const ans1 = rate * hours;
    const working = hours / 8;
    const ans2 = Math.floor(working);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `${name.name} works at a cafe, and is paid $${DisplayMoney(
        rate
      )}$ per hour.`
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Last month ${name.name} worked $${hours}$ hours.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ How much should ${name.name} be paid for the month?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `For each $8$ hours ${name.name} works ${name.heshe} is given a holiday hour.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ How many holiday hours has ${name.name} accumulated?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      `$(a)$ $${hours} \\times${DisplayMoney(rate)}=${DisplayMoney(ans1)}$`
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ $${hours} \\div8=${working}$, which rounds down to $${ans2}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, ${name.name} has accumulated $${ans2}$ holiday hours.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans1 / 100, 0.005, "$(a) \\ \\pounds$");
    group.addInput(ans2, 0, "$(b)$");
    question.addInputGroup(group);

    if (ans2 === working || ans2 <= 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q13() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
      }
      return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const wageInt = MCRandom.randomInt(8, 12);
    const wageFloat = (Math.random().toFixed(2) * 100) / 100;
    let wage = wageInt + wageFloat;
    wage = wage.toFixed(2);
    const name = MCMisc.getName();
    const hours = MCRandom.randomInt(16, 35);
    const costsInt = MCRandom.randomInt(2, 4);
    const costsFloat = (Math.random().toFixed(2) * 100) / 100;
    let costs = costsInt + costsFloat;
    costs = costs.toFixed(2);
    const weeks = MCRandom.randomInt(40, 50);
    const pph = wage - costs;
    const ppw = pph * hours;
    const ppy = ppw * weeks;
    let paysNationalInsurance = true;
    if (ppy < 6205) {
      paysNationalInsurance = false;
    }

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} is self-employed and earns $\\pounds${wage}$ per hour.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} has costs of $\\pounds${costs}$ per hour.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${capitalizeFirstLetter(
          name.hisher
        )} profit is the difference between the money earned and the costs.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} works for $${hours}$ hours per week for $${weeks}$ weeks.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} must pay National Insurance contributions if ${name.heshe} earns a profit of over $\\pounds 6205$ per year.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Will ${name.name} pay National Insurance in this year?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Profit per hour = hourly wage - costs per hour = $${wage} - ${costs} = ${pph}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Profit per week = profit per hour $ \\times$ hours per week = $${pph}  \\times ${hours} = ${ppw}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Profit per year = profit per week $ \\times$ weeks per year = $${ppw}  \\times ${weeks} = ${ppy}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Total profit per year = $${DisplayMoney(ppy.toFixed(2) * 100)}$`,
        false,
        true,
        true
      )
    );
    if (paysNationalInsurance === true) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${ppy} \\gt 6250$`, false, true, true)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `${name.name} will pay NI contributions.`,
          false,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${ppy} \\lt 6250$`, false, true, true)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `${name.name} will not pay NI contributions.`,
          false,
          true,
          true
        )
      );
    }

    question.requiresSelfMarking();

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (ppy > 8000 || ppy.toFixed(0) === ppy || ppy === 6250) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q14() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const a = Math.round(Math.random() * 80 + 10);
    const b = Math.round(Math.random() * 800 + 100);
    const c = a * b;
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(`Given, $${a} \\times${b}=${c}$ find:`, false, true, true)
    );
    if (Math.random() < 0.5) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$(a) \\ ${a * 10} \\times${b * 10}$ `)
      );
    } else {
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$(a) \\ ${a * 100} \\times${b}$ `, false, true, true)
      );
    }
    if (Math.random() < 0.5) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$(b) \\ ${a / 10} \\times${b / 100}$ `)
      );
    } else {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$(b) \\ ${a / 100} \\times${b / 10}$ `,
          false,
          true,
          true
        )
      );
    }

    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(c) \\ {${c}\\over${b * 10}}$ `, false, true, true)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${c * 100}$ `, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${c / 1000}$ `, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ ${a / 10}$ `, false, true, true)
    );

    const group = new MCQuestion.InputGroup();
    group.addInput(c * 100, 0, "$(a)$");
    group.addInput(c / 1000, 0.01, "$(b)$");
    group.addInput(a / 10, 0.01, "$(c)$");
    question.addInputGroup(group);

    if (a === b) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup;
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q15() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const initial = MCRandom.randomInt(10, 200);
    const TV = MCRandom.randomInt(initial + 10, initial + 100);
    const Bal = initial - TV;
    const Paid = MCRandom.randomInt(-Bal + 10, -Bal + 100);
    const BalM = -Bal;
    const ans = Bal + Paid;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has $\\pounds${initial}$ in ${name.hisher} bank account, and decides to buy a TV which costs $\\pounds${TV}$, taking ${name.hisher} into their overdraft.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ How big is ${name.name}'s overdraft now?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} is now paid $\\pounds${Paid}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ How much money does ${name.name} now have?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a) \\ ${initial} - ${TV} = ${Bal}$, so ${name.name}'s overdraft is $\\pounds${BalM}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ ${Bal} + ${Paid} = ${ans}$, so ${name.name} has $\\pounds${ans}$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(BalM, 0, "$(a) \\ \\pounds $");
    group.addInput(ans, 0, "$(b) \\ \\pounds $");
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q16() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(-10, -1);
    const b = MCRandom.randomInt(1, 10);
    const c = MCRandom.randomInt(-10, -2);
    const d = MCRandom.randomInt(-10, 10);
    const e = MCRandom.randomInt(-10, 10);
    const f = MCRandom.randomInt(-10, 10);
    const ans1 = a + b;
    const ans2 = c * d;
    const ans3 = e / f;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a)$ What is $${a} + ${b}?$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b)$ What is $${c}  \\times ${d}?$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ What is $\\displaystyle {${e} \\over ${f}}?$`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${ans1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${ans2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ ${ans3}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans1, 0, "$(a)$");
    group.addInput(ans2, 0, "$(b)$");
    group.addInput(ans3, 0, "$(c)$");
    question.addInputGroup(group);

    if (d === 0 || f === 0 || Math.round(ans3) !== ans3) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q17() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const coldPlace = ["Russia", "Sweden", "Norway", "Denmark"];
    const hotPlace = ["Egypt", "Mexico", "Brazil", "Australia"];
    const cold1 = coldPlace[MCRandom.randomInt(0, 3)];
    const cold2 = coldPlace[MCRandom.randomInt(0, 3)];
    const hot1 = hotPlace[MCRandom.randomInt(0, 3)];
    const hot2 = hotPlace[MCRandom.randomInt(0, 3)];
    const temp1 = MCRandom.randomInt(-30, -5);
    const temp2 = MCRandom.randomInt(-30, -5);
    const temp3 = MCRandom.randomInt(20, 30);
    const temp4 = MCRandom.randomInt(20, 30);
    let ans1 = hot1;
    if (temp3 < temp4) {
      ans1 = hot2;
    }
    let ans2 = cold1;
    if (temp1 > temp2) {
      ans2 = cold2;
    }
    const ans3 = temp3 - temp1;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `On a certain day in ${cold1}, ${cold2}, ${hot1}, ${hot2}, it is $${temp1}^o,${temp2}^o,${temp3}^o,${temp4}^o$ receptively.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Which country has the hottest temperature?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Which country has the coldest temperature?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ What is the difference in temperatures between ${cold1} and ${hot1}?`,
        false,
        true,
        true
      )
    );
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a)$ ${ans1}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b)$ ${ans2}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ ${ans3}^o$`, false, true, true)
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans3, 0, "$(c)$");
    question.addInputGroup(group);

    if (
      hot1 === hot2 ||
      cold1 === cold2 ||
      temp1 === temp2 ||
      temp3 === temp4
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q18() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const day = MCRandom.randomInt(1, 10);
    const drop = MCRandom.randomInt(day + 1, day + 10);
    const night = day - drop;
    const lon = MCRandom.randomInt(3, 13);
    const dif = lon - night;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `One day in Newcastle, the temperature is $${day}^o$. During the night it drops by $${drop}^o$. `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(a)$What is the new temperature?", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The temperature in London on the same night is $${lon}^o$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ What is the difference in night temperatures between London and Newcastle?",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${night}^o$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${dif}^o$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(night, 0, "$(a)$");
    group.addInput(dif, 0, "$(b)$");
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q19() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(1, 10);
    const b = MCRandom.randomInt(-10, 10);
    const c = MCRandom.randomInt(-10, 10);
    const d = MCRandom.randomInt(-10, -1);
    const e = MCRandom.randomInt(-10, -1);
    const f = MCRandom.randomInt(1, 10);
    const g = MCRandom.randomInt(-10, 10);
    const arr = [a, b, c, d, e, f, g];
    arr.sort(function sorter(a2, b2) {
      return a2 - b2;
    });
    const ans2 = arr[6] - arr[0];

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Order these numbers: $${a},${b},${c},${d},${e},${f},${g}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ What is the difference between the smallest and largest numbers in the list?",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ $${arr[0]},${arr[1]},${arr[2]},${arr[3]},${arr[4]},${arr[5]},${arr[6]}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b)$ $${ans2}$`, false, true, true)
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans2, 0, "$(b)$");
    question.addInputGroup(group);

    if (
      arr[0] === arr[1] ||
      arr[1] === arr[2] ||
      arr[2] === arr[3] ||
      arr[3] === arr[4] ||
      arr[4] === arr[5] ||
      arr[5] === arr[6]
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const question = new MCQuestion.ExamQuestion();

    const name = MCMisc.getName();
    const liquidAvailable = MCRandom.randomInt(900, 1500);
    const liquidPerSolution = MCRandom.randomInt(25, 45);
    const batches = Math.floor(liquidAvailable / liquidPerSolution);
    const liquidUsed = batches * liquidPerSolution;
    const liquidLeft = liquidAvailable - liquidUsed;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} makes batches of copper sulphate crystals.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Each batch uses $${liquidPerSolution}$ ml of sulphuric acid and a letying amount of black copper oxide.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has $${liquidAvailable}$ ml of sulphuric acid available.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "You may assume that the amount of black copper oxide available has no limit.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `(a) How many batches of copper sulphate crystals can ${name.name} make?`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `(b) How much sulphuric acid will ${name.name} have left?`,
        false
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `(a) Number of batches = sulphuric acid available $ \\div$ sulphuric acid per batch = $${liquidAvailable}  \\div ${liquidPerSolution} = ${parseFloat(
          (liquidAvailable / liquidPerSolution).toFixed(3)
        )}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Number of batches = $${batches}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `(b) Amount of sulphuric acid used = batches made $ \\times$ acid used per batch = $${batches}  \\times ${liquidPerSolution} = ${liquidUsed}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Amount left over = available amount $-$ amount used = $${liquidAvailable} - ${liquidUsed} = ${liquidLeft}$ ml`,
        false
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(batches, 0.1, "$(a)$");
    group.addInput(liquidLeft, 0.1, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (liquidAvailable / 5 !== Math.round(liquidAvailable / 5)) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q20() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const aft = MCRandom.randomInt(5, 15);
    const night = MCRandom.randomInt(-10, -1);
    const fre = MCRandom.randomInt(1, 5);
    const ans1 = aft - night;
    const ans2 = fre - night;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} goes into his garden in the afternoon and measures the temperature to be $${aft}^o$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Later that night ${name.heshe} measures it again and finds it is now $${night}^o$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ How much has the temperature dropped?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name}'s friend measures the temperature in their garden to be $${fre}^o$ during the night.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` $(b)$ What is the difference in temperatures during the night measured by ${name.name} and ${name.hisher} friend?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${ans1}^o$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${ans2}^o$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans1, 0, "$(a)$");
    group.addInput(ans2, 0, "$(b)$");
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q21() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const freez = MCRandom.randomInt(-10, -3);
    const hot = MCRandom.randomInt(55, 75);
    const cool = MCRandom.randomInt(30, 40);
    const ans1 = hot - freez;
    const ans2 = hot - cool;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} takes a pizza out of his freezer , which is at $${freez}^o$, and puts it in ${name.hisher} over.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The over heats the pizza to $${hot}^o$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ How much has the temperature of the pizza increased by?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `After an hour the pizza has cooled by $${cool}^o$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " $(b)$ What is the new temperature of the pizza?",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${ans1}^o$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${ans2}^o$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans1, 0, "$(a)$");
    group.addInput(ans2, 0, "$(b)$");
    question.addInputGroup(group);

    if (1 === 2 || 2 === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q22() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(-10, -2);
    const b = MCRandom.randomInt(-10, -2);
    const c = MCRandom.randomInt(1, 5);
    const ans1 = a + b;
    const ans2 = a - b;
    const ans3 = a * b * c;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Given that $a=${a}$, $b=${b}$ and $c=${c}$, find:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(a) \\ a+b$", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(b) \\ a-b$", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(c) \\ c  \\times b  \\times a$", false, true, true)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${ans1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${ans2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ ${ans3}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(3);
    group.addInput(ans1, 0, "$(a)$");
    group.addInput(ans2, 0, "$(b)$");
    group.addInput(ans3, 0, "$(c)$");
    question.addInputGroup(group);

    if (a === b) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name1 = MCMisc.getName();
    const name2 = MCMisc.getName();
    const ticketsBoughtByOne = MCRandom.randomInt(4, 9);
    const ticketCost = MCRandom.randomInt(35, 80);
    const moneySpentByOne = ticketsBoughtByOne * ticketCost;
    const ticketsBoughtByTwo = MCRandom.randomInt(4, 9);
    const moneySpentByTwo = ticketsBoughtByTwo * ticketCost;
    const totalSpent = moneySpentByOne + moneySpentByTwo;
    const firstClassCost = ticketCost + MCRandom.randomInt(30, 50);
    const totalTickets = ticketsBoughtByOne + ticketsBoughtByTwo;
    const firstClassTotal = totalTickets * firstClassCost;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} and ${name2.name} are planning a trip to London.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "They buy train tickets for everybody who is travelling.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `They pay $\\pounds${totalSpent}$ altogether.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} pays $\\pounds${moneySpentByOne}$ for $${ticketsBoughtByOne}$ tickets.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$  Find the number of tickets bought by ${name2.name}.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} and ${name2.name} decide the group will travel first class.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `First class tickets cost $\\pounds${firstClassCost}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$  What is the new total cost of the tickets?",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ Price per ticket = money spent by ${name1.name} $ \\div$ number of tickets bought = $${moneySpentByOne}  \\div ${ticketsBoughtByOne} = \\pounds${ticketCost}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Money spent by ${name2.name} $= ${totalSpent} - ${moneySpentByOne} = \\pounds${moneySpentByTwo}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Tickets bought by ${name2.name} = money spent by ${name2.name} $ \\div$ price per ticket $= ${moneySpentByTwo}  \\div ${ticketCost} = ${ticketsBoughtByTwo}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ Total number of tickets to be bought = number to be bought by ${name1.name} $+$ tickets to be bought by ${name2.name} $= ${ticketsBoughtByOne} + ${ticketsBoughtByTwo} = ${totalTickets}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Cost of all tickets = number of tickets $ \\times$ cost of first class ticket = $${totalTickets}  \\times ${firstClassCost} = \\pounds${firstClassTotal}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ticketsBoughtByTwo, 0, "$(a)$");
    group.addInput(firstClassTotal, 0, "$(b)$");
    question.addInputGroup(group);

    if (
      ticketsBoughtByOne === ticketsBoughtByTwo ||
      name1.name === name2.name
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name = MCMisc.getName();
    const cost = MCRandom.randomInt(699, 999);
    const savings = MCRandom.randomInt(99, 399);
    const wage = MCRandom.randomInt(50, 100);
    const saveRatePercentage = MCRandom.randomInt(10, 30);
    const moneyNeeded = cost - savings;
    const savingsPerWeek = wage * (saveRatePercentage / 100);
    const weeks = Math.ceil(moneyNeeded / savingsPerWeek);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} is saving to buy a new laptop which costs $\\pounds${cost}$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} has $\\pounds${savings}$ in ${name.hisher} bank account.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HisHer} job pays $\\pounds${wage}$ per week of which ${name.heshe} saves $${saveRatePercentage}\\%$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `How many weeks must ${name.name} save for?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Money left to save = cost of laptop $-$ money already saved $ = ${cost} - ${savings} = \\pounds${moneyNeeded}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Savings per week = $${saveRatePercentage}\\%$ of weekly wage = $${wage}  \\times ${
          saveRatePercentage / 100
        } = \\pounds${savingsPerWeek}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Weeks = money left to save $ \\div$ savings per week = $${moneyNeeded}  \\div ${savingsPerWeek} = ${parseFloat(
          (moneyNeeded / savingsPerWeek).toFixed(2)
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Min number of weeks = $${weeks}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(weeks, 0);
    question.addInputGroup(group);
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (savingsPerWeek !== Math.round(savingsPerWeek)) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q5() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name = MCMisc.getName();
    const possibleCoins = [5, 10, 20, 50];
    const coin1 = possibleCoins[MCRandom.randomInt(0, 3)];
    const coin2 = possibleCoins[MCRandom.randomInt(0, 3)];
    const timesAsMany = MCRandom.randomInt(3, 5);
    const numberOfCoin2 = MCRandom.randomInt(75, 150);
    const numberOfCoin1 = timesAsMany * numberOfCoin2;
    const totalValueOfCoins =
      numberOfCoin1 * (coin1 / 100) + numberOfCoin2 * (coin2 / 100);
    const onePart = coin1 * timesAsMany + coin2;
    const totalValueOfCoinsPence = totalValueOfCoins * 100;
    const totalNumberOfCoinsInOnePart = totalValueOfCoinsPence / onePart;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has a collection of $${coin1}$p coins and $${coin2}$p coins.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.HeShe} has`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${timesAsMany}$ times as many $${coin1}$p coins as $${coin2}$p coins`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `a total of $\\pounds${totalValueOfCoins}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `How many $${coin1}$p coins does ${name.name} have?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Value of coins in one part = $${coin1}$p $ \\times$ $${timesAsMany} + ${coin2}$p $= ${onePart}$p`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Total number parts = total value in pence $ \\div$ value of coins in one part = $${totalValueOfCoinsPence}  \\div ${onePart} = ${totalNumberOfCoinsInOnePart}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${name.name} has $${numberOfCoin2}  \\times ${coin2}$p coins.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Number of $${coin1}$p coins = $${timesAsMany}  \\times ${totalNumberOfCoinsInOnePart} = ${numberOfCoin1}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(numberOfCoin1, 0);
    question.addInputGroup(group);
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      coin1 === coin2 ||
      totalValueOfCoins !== Math.round(totalValueOfCoins) ||
      totalValueOfCoins > 100
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(numberOfCoin1, 0);
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q6() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
      }
      return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const totalMoney = MCRandom.randomInt(500, 2000, 500);
    const totalMoneyDisplay = `$${DisplayMoney(totalMoney)}$`;
    const cost1 = MCRandom.randomInt(10, 50) / 10;
    const cost2 = MCRandom.randomInt(10, 50) / 10;
    const cost3 = MCRandom.randomInt(10, 50) / 10;
    const items = [
      "bags of sweets",
      "packets of chocolates",
      "packets of crisps",
      "packets of chewing gum",
    ];
    const item1 = items[MCRandom.randomInt(0, items.length - 1)];
    const item2 = items[MCRandom.randomInt(0, items.length - 1)];
    const item3 = items[MCRandom.randomInt(0, items.length - 1)];
    const numberOfItem1 = MCRandom.randomInt(2, 5);
    const numberOfItem2 = MCRandom.randomInt(2, 5);
    const numberOfItem3 = MCRandom.randomInt(2, 5);
    const priceOfItem1 = MCRandom.randomInt(50, 100, 5);
    const priceOfItem2 = MCRandom.randomInt(50, 100, 5);
    const priceOfItem3 = MCRandom.randomInt(50, 100, 5);
    const change =
      totalMoney -
      numberOfItem1 * priceOfItem1 -
      numberOfItem2 * priceOfItem2 -
      numberOfItem3 * priceOfItem3;
    const changeDisplay = `$${DisplayMoney((change / 100).toFixed(2) * 100)}$`;

    let display1;
    let display2;
    let display3;
    // These Variables Are for is the item costs 75p vs '+CurrencyMajor+'1, etc.
    if (priceOfItem1 >= 100) {
      display1 = `$${DisplayMoney(priceOfItem1)}$`;
    } else {
      display1 = `$${priceOfItem1}p$`;
    }
    if (priceOfItem2 >= 100) {
      display2 = `$${DisplayMoney(priceOfItem2)}$`;
    } else {
      display2 = `$${priceOfItem2}p$`;
    }
    if (priceOfItem3 >= 100) {
      display3 = `$${DisplayMoney(priceOfItem3)}$`;
    } else {
      display3 = `$${priceOfItem3}p$`;
    }

    const totalcost1 = (numberOfItem1 * priceOfItem1) / 100;
    const totalcost1Display = `$${DisplayMoney(totalcost1 * 100)}$`;
    const totalcost2 = (numberOfItem2 * priceOfItem2) / 100;
    const totalcost2Display = `$${DisplayMoney(totalcost2 * 100)}$`;

    const totalTotalCost = parseFloat(totalcost1 + totalcost2).toFixed(2);
    const totalTotalCostDisplay = `$\\pounds${totalTotalCost}$`;

    const totalSpentOn3 = totalMoney / 100 - totalTotalCost - change / 100;
    const totalSpentOn3Display = `$${DisplayMoney(totalSpentOn3 * 100)}$`;

    const name = MCMisc.getName();

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", `${name.name} goes to the shop.`);
    question.addParagraph("question", `${name.HeShe} buys:`);
    question.addParagraph(
      "question",
      `$${numberOfItem1}$ ${item1} each costing ${display1}`
    );
    question.addParagraph(
      "question",
      `$${numberOfItem2}$ ${item2} each costing ${display2}`
    );
    question.addParagraph("question", `$${numberOfItem3}$ ${item3}`);
    question.addParagraph(
      "question",
      `${name.name} pays ${totalMoneyDisplay} and gets ${changeDisplay} in change.`
    );
    question.addParagraph(
      "question",
      `How much does a ${item3
        .replace("packets", "packet")
        .replace("bags", "bag")} cost?`
    );

    question.addParagraph("solution", "");
    question.addParagraph(
      "solution",
      ` The amount paid for ${item1} $=$ number bought $ \\times$ cost of item $= ${numberOfItem1} \\times$${display1} $=$ ${totalcost1Display}`
    );
    question.addParagraph(
      "solution",
      ` The amount paid for ${item2} $=$ number bought $ \\times$ cost of item $= ${numberOfItem2} \\times$${display2} $=$ ${totalcost2Display}`
    );
    question.addParagraph(
      "solution",
      ` The total amount paid is ${totalcost1Display}$+$${totalcost2Display} $=$ ${totalTotalCostDisplay}`
    );
    question.addParagraph(
      "solution",
      ` The toal amount spent on ${item3} $=$ ${totalMoneyDisplay} $-$ ${totalTotalCostDisplay} $-$ ${changeDisplay} $=$ ${totalSpentOn3Display}`
    );
    question.addParagraph(
      "solution",
      ` So each ${item3} costs $\\pounds ${+totalSpentOn3.toFixed(
        2
      )} \\div${numberOfItem3} = \\pounds ${+(priceOfItem3 / 100).toFixed(2)}$`
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(priceOfItem3 / 100, 0.1);
    question.addInputGroup(group);

    if (
      item1 === item2 ||
      item2 === item3 ||
      item1 === item3 ||
      change <= 100 ||
      (totalMoney === 1500 && change >= 500)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 

 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q7() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
      }
      return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
    }
    return `${input} p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    let count2 = MCRandom.randomInt(50, 300);
    const coinTypes = [200, 100, 50, 20, 5, 2, 1];
    let proportion = MCRandom.randomInt(10, 90) / 100;
    const coin1 = coinTypes[MCRandom.randomInt(0, coinTypes.length - 1)];
    while (Math.round(proportion * count2) !== proportion * count2) {
      count2 = MCRandom.randomInt(50, 300);
      proportion = MCRandom.randomInt(10, 90) / 100;
    }
    const num1 = proportion * count2;
    const coin2 = coinTypes[MCRandom.randomInt(0, coinTypes.length - 1)];
    const num2 = MCRandom.randomInt(1, count2 - num1);
    const coin3 = coinTypes[MCRandom.randomInt(0, coinTypes.length - 1)];
    const num3 = count2 - num1 - num2;
    const answer = num1 * coin1 + num2 * coin2 + num3 * coin3;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.name} has $${count2}$ coins:`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${proportion}$ of the coins are $${DisplayMoney(coin1)}$ coins.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${num2}$ are $${DisplayMoney(coin2)}$ coins.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The rest are $${DisplayMoney(coin3)}$ coins.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `How much money does ${name.heshe} have?`,
        false,
        true,
        true
      )
    );

    console.log(coin3);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${proportion}$ of $${count2} = ${num1}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${count2}-${num1}-${num2}=${num3}$, so there are $${num3}$ $${DisplayMoney(
          coin3
        )}$ coins`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(${num1} \\times${DisplayMoney(
          coin1
        )}) + (${num2} \\times ${DisplayMoney(
          coin2
        )}) + (${num3} \\times ${DisplayMoney(coin3)}) = ${DisplayMoney(
          answer
        )}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(answer / 100, 0.01);
    question.addInputGroup(group);

    if (
      coin1 === coin2 ||
      coin2 === coin3 ||
      coin3 === coin1 ||
      Math.round(num3) !== num3 ||
      num1 <= 10 ||
      num2 <= 10 ||
      num3 <= 10 ||
      num1 >= 101 ||
      num2 >= 101 ||
      num3 >= 101
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */

// version 2


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q8() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const hoursWorked = MCRandom.randomInt(8, 40);
    const payRate = MCRandom.randomInt(60, 130) / 10;
    const moneyEarned = hoursWorked * payRate;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${person.name} is working as a developer for a website.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.HeShe} works for $${hoursWorked}$ hours in a week.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` At the end of the week ${person.heshe} gets paid $\\pounds ${moneyEarned}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` How much money does ${person.name} earn per hour?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` To work out ${person.hisher} hourly rate we must divide the amount of money ${person.heshe} has earned, by the amount of hours ${person.heshe} has worked.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${moneyEarned.toFixed(2)}  \\div ${hoursWorked} = ${payRate}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore ${person.name} earns $\\pounds ${payRate}$ per hour.`,
        false,
        true,
        true
      )
    );

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    const group = new MCQuestion.InputGroup(1);
    group.addInput(payRate, 0);
    question.addInputGroup(group);
    if (payRate !== Math.round(payRate) || hoursWorked % 5 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(payRate, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_OperationsAndPlaceValue_Exam_Q9() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
      }
      return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const person = MCMisc.getName();
    const flowerList = [
      "roses",
      "orchids",
      "daisies",
      "daffodils",
      "carnations",
    ];
    const flower1 = flowerList[MCRandom.randomInt(0, flowerList.length - 1)];
    const flower2 = flowerList[MCRandom.randomInt(0, flowerList.length - 1)];
    const numOfFlower1 = MCRandom.randomInt(3, 12);
    const numOfFlower2 = MCRandom.randomInt(3, 12);
    const flower1Cost = MCRandom.randomInt(60, 300) / 100;
    const flower2Cost = MCRandom.randomInt(60, 300) / 100;
    const totalFlower1Cost = numOfFlower1 * flower1Cost;
    const totalFlower2Cost = numOfFlower2 * flower2Cost;
    const totalCost = totalFlower1Cost + totalFlower2Cost;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(` ${person.name} wants to buy some flowers.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.HeShe} goes into a flower shop and buys $${numOfFlower1}$ ${flower1} and $${numOfFlower2}$ ${flower2}.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} wants to know the cost of ${flower1} per flower.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` Unfortunately, ${person.name} forgot to get a receipt, however, ${
          person.heshe
        } can remember that ${flower2} costs $${DisplayMoney(
          flower2Cost.toFixed(2) * 100
        )}$ per flower.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.HeShe} can also remember that ${
          person.heshe
        } spent $${DisplayMoney(totalCost.toFixed(2) * 100)}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` What is the cost of ${flower1} per flower?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Since the ${flower2} cost $${DisplayMoney(
          flower2Cost.toFixed(2) * 100
        )}$ and ${
          person.name
        } bought $${numOfFlower2}$ of them, the total cost of the ${flower2} is:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${numOfFlower2}  \\times ${flower2Cost} = ${totalFlower2Cost}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Subtract this value from the total cost to get the value of how much ${person.name} spent on ${flower1}:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${totalCost} - ${totalFlower2Cost} = ${totalFlower1Cost}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Now in order to obtain the value of the cost of each individual ${flower1} divide the value of how much ${person.name} spent on ${flower1} by how many ${person.heshe} bought:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${totalFlower1Cost}  \\div ${numOfFlower1} = ${flower1Cost}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore the ${flower1} cost $${DisplayMoney(
          flower1Cost.toFixed(2) * 100
        )}$.`,
        false,
        true,
        true
      )
    );

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.
    const group = new MCQuestion.InputGroup(1);
    group.addInput(flower1Cost, 0.01);
    question.addInputGroup(group);

    if (
      flower1 === flower2 ||
      numOfFlower1 === numOfFlower2 ||
      /* Makes sure prices aren't too high for the lower quality flowers: */ (flower1 !==
        "roses" &&
        flower1Cost > 1) ||
      (flower2 !== "roses" && flower2Cost > 1) ||
      flower1Cost < 1
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 

 */


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const offset1 = MCRandom.randomInt(0, 4);

    const n1 = new MCMaths.Fraction(MCRandom.randomInt(5, 95) / 100 + offset1);
    const n2 = new MCMaths.Fraction(
      MCRandom.randomInt(5, 950) / 1000 + offset1
    );
    const n3 = new MCMaths.Fraction(
      MCRandom.randomInt(5, 950) / 1000 + offset1
    );
    const n4 = new MCMaths.Fraction(MCRandom.randomInt(5, 95) / 100 + offset1);

    const nList = [
      n1.toFloat(),
      n2.toFloat(),
      n3.toFloat(),
      n4.toFloat(),
    ].sort();
    const nListShuffle = MCRandom.shuffle(nList);

    const offset2 = MCRandom.randomInt(1, 9);
    const m1 = new MCMaths.Fraction(
      MCRandom.randomInt(offset2 * 100 + 5, offset2 * 100 + 95) / 1000
    );
    const m2 = new MCMaths.Fraction(
      MCRandom.randomInt(offset2 * 100 + 5, offset2 * 100 + 95) / 1000
    );
    const m3 = new MCMaths.Fraction(
      MCRandom.randomInt(offset2 * 100 + 5, offset2 * 100 + 95) / 1000
    );
    const m4 = new MCMaths.Fraction(
      MCRandom.randomInt(offset2 * 100 + 5, offset2 * 100 + 95) / 1000
    );

    const mList = [m1.toFloat(), m2.toFloat(), m3.toFloat(), m4.toFloat()].sort(
      function (a, b) {
        return b - a;
      }
    );
    const mListShuffle = MCRandom.shuffle(mList);
    /* END VARIABLES AND CONSTANTS */
    if (
      n1.toFloat() !== n2.toFloat() &&
      n1.toFloat() !== n3.toFloat() &&
      n1.toFloat() !== n4.toFloat() &&
      n2.toFloat() !== n3.toFloat() &&
      n2.toFloat() !== n4.toFloat() &&
      n3.toFloat() !== n4.toFloat() &&
      m1.toFloat() !== m2.toFloat() &&
      m1.toFloat() !== m3.toFloat() &&
      m1.toFloat() !== m4.toFloat() &&
      m2.toFloat() !== m3.toFloat() &&
      m2.toFloat() !== m4.toFloat() &&
      m3.toFloat() !== m4.toFloat()
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        "a) Write the following numbers in order of size, largest to smallest."
      );
      question.addParagraph("question", `$${mListShuffle}$`);

      question.addParagraph(
        "question",
        "b) Write the following numbers in ascending order."
      );
      question.addParagraph("question", `$${nListShuffle}$`);

      // solution
      question.addHeading(
        "solution",
        "a) The correct order (largest to smallest) is:"
      );
      question.addParagraph("solution", `$${mList}$`);
      question.addParagraph(
        "solution",
        `$${mList[0]}>${mList[1]}$   because   $${mList[0]}-${mList[1]}>0$`
      );
      question.addParagraph(
        "solution",
        `$${mList[1]}>${mList[2]}$   because   $${mList[1]}-${mList[2]}>0$`
      );
      question.addParagraph(
        "solution",
        `$${mList[2]}>${mList[3]}$   because   $${mList[2]}-${mList[3]}>0$`
      );

      question.addHeading(
        "solution",
        "b) The correct order (smallest to largest) is:"
      );
      question.addParagraph("solution", `$${nList}$`);
      question.addParagraph(
        "solution",
        `$${nList[0]}<${nList[1]}$   because   $${nList[0]}-${nList[1]}<0$`
      );
      question.addParagraph(
        "solution",
        `$${nList[1]}<${nList[2]}$   because   $${nList[1]}-${nList[2]}<0$`
      );
      question.addParagraph(
        "solution",
        `$${nList[2]}<${nList[3]}$   because   $${nList[2]}-${nList[3]}<0$`
      );
      // answers

      const g1 = new MCQuestion.InputGroup(4);
      g1.addInput(mList[0], 0, "a) 1");
      g1.addInput(mList[1], 0, "2");
      g1.addInput(mList[2], 0, "3");
      g1.addInput(mList[3], 0, "4");

      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(4);
      g2.addInput(nList[0], 0, "b) 1");
      g2.addInput(nList[1], 0, "2");
      g2.addInput(nList[2], 0, "3");
      g2.addInput(nList[3], 0, "4");

      question.addInputGroup(g2);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Exam_Q10() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const m = MCRandom.randomInt(2, 6);
    const n1 = MCRandom.randomInt(m * 100, m * 100 + 99);
    const n2 = MCRandom.randomInt(m * 100, m * 100 + 99);
    const n3 = MCRandom.randomInt(m * 100, m * 100 + 99);
    const n4 = MCRandom.randomInt(m * 100, m * 100 + 99);

    const nListShuffle = [n1, n2, n3, n4];
    const nList = [n1, n2, n3, n4].sort();

    const months = ["September", "October", "November", "December"];

    const sum = nListShuffle.reduce((a, b) => a + b, 0);

    const meanAnswer = parseFloat((sum / 4).toFixed(0));
    /* END VARIABLES AND CONSTANTS */
    if (
      n1 !== n2 &&
      n1 !== n3 &&
      n1 !== n4 &&
      n2 !== n3 &&
      n2 !== n4 &&
      n3 !== n4
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `Four months worth of data is collected about the attendance of fans to rugby games at the end of the year. The table below shows the average attendance for each month.`
      );
      const t = new MCQuestion.Table(
        "September",
        "October",
        "November",
        "December"
      );
      t.addRow(`$${n1}$`, `$${n2}$`, `$${n3}$`, `$${n4}$`);
      question.addTable("question", t);

      question.addParagraph(
        "question",
        `a) List the months in order of average attendance, starting with the smallest average attendance.`
      );

      question.addParagraph(
        "question",
        `b) Calculate the mean of the average attendances, to the nearest whole number.`
      );
      // solution
      question.addHeading(
        "solution",
        "a) The correct order for the indices (smallest to largest) is:"
      );
      question.addParagraph("solution", `$${nList}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ The order of months is:`
      );

      question.addParagraph(
        "solution",
        `${months[nListShuffle.indexOf(nList[0])]}, ${
          months[nListShuffle.indexOf(nList[1])]
        }, ${months[nListShuffle.indexOf(nList[2])]}, ${
          months[nListShuffle.indexOf(nList[3])]
        }`
      );
      // b
      question.addHeading(
        "solution",
        `b) The mean is the sum of all values, divided by the number of values ($4$ in this case).`
      );
      question.addParagraph(
        "solution",
        `$${nListShuffle.join(" + ")} = ${sum}$`
      );
      if (sum % 4 === 0) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${sum}}{4} = ${sum / 4}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${sum}}{4} = ${parseFloat(
            (sum / 4).toFixed(3)
          )}$`
        );
        question.addParagraph(
          "solution",
          `$\\approx ${parseFloat((sum / 4).toFixed(0))}$`
        );
      }

      // answer
      question.requiresSelfMarking();

      const g = new MCQuestion.InputGroup(1);
      g.addInput(meanAnswer, 0, "b)");
      question.addInputGroup(g);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function sortDescending(a, b) {
      if (typeof a !== "number" && typeof b !== "number") {
        return b.toFloat() - a.toFloat();
      }
      if (typeof b !== "number") {
        return b.toFloat() - a;
      }
      if (typeof a !== "number") {
        return b - a.toFloat();
      }
      return b - a;
    }

    const n1 = new MCMaths.Fraction(MCRandom.randomInt(5, 95) / 100);

    const n2Denom = MCRandom.randomInt(3, 20);
    const n2 = new MCMaths.Fraction(
      MCRandom.randomInt(1, n2Denom - 1) / n2Denom
    );

    const n3Denom = MCRandom.randomInt(3, 20);
    const n3 = new MCMaths.Fraction(
      MCRandom.randomInt(1, n3Denom - 1) / n3Denom
    );

    const n4Denom = MCRandom.randomInt(3, 20);
    const n4 = new MCMaths.Fraction(
      MCRandom.randomInt(1, n4Denom - 1) / n4Denom
    );

    const nList = [n1.toFloat(), n2, n3, n4].sort(sortDescending);

    const nListFloats = [
      n1.toFloat(),
      parseFloat(n2.toFloat().toFixed(3)),
      parseFloat(n3.toFloat().toFixed(3)),
      parseFloat(n4.toFloat().toFixed(3)),
    ].sort(sortDescending);

    const nListShuffle = MCRandom.shuffle([n1.toFloat(), n2, n3, n4]);

    const fracs = [n2, n3, n4];
    /* END VARIABLES AND CONSTANTS */
    if (
      n1.toFloat() !== n2.toFloat() &&
      n1.toFloat() !== n3.toFloat() &&
      n1.toFloat() !== n4.toFloat() &&
      n2.toFloat() !== n3.toFloat() &&
      n2.toFloat() !== n4.toFloat() &&
      n3.toFloat() !== n4.toFloat() &&
      nListFloats[0] - nListFloats[1] > 0.05
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        "a) Put the following numbers in descending order."
      );
      question.addParagraph("question", `$${nListShuffle}$`);

      question.addParagraph(
        "question",
        `b) Find a new number that would be the second largest in the list above.`
      );

      // solution
      question.addHeading(
        "solution",
        "a) First, convert all numbers to decimals."
      );
      for (let i = 0; i < 3; i += 1) {
        if (parseFloat(fracs[i].toFloat().toFixed(3)) === fracs[i].toFloat()) {
          question.addParagraph(
            "solution",
            `$${fracs[i]} = ${fracs[i].toFloat()}$`
          );
        } else {
          question.addParagraph(
            "solution",
            `$${fracs[i]} \\approx ${fracs[i].toFloat().toFixed(3)}$`
          );
        }
      }
      question.addHeading("solution", `The decimal order is:`);
      question.addParagraph("solution", `$${nListFloats}$`);
      question.addHeading(
        "solution",
        "Therefore, the correct order (largest to smallest) is:"
      );
      question.addParagraph("solution", `$${nList}$`);
      // b
      question.addHeading(
        "solution",
        `b) New number, $n$ must lie in the range $${nListFloats[1]}<n<${nListFloats[0]}$`
      );
      question.addParagraph(
        "solution",
        `For example, $${parseFloat(
          (
            MCRandom.randomInt(
              nListFloats[1] * 1000 + 1,
              nListFloats[0] * 1000 - 1
            ) / 1000
          ).toFixed(3)
        )}$`
      );
      // answer
      const g1 = new MCQuestion.InputGroup(4);
      g1.addInput(nListFloats[0], 0.005, "a) 1");
      g1.addInput(nListFloats[1], 0.005, "2");
      g1.addInput(nListFloats[2], 0.005, "3");
      g1.addInput(nListFloats[3], 0.005, "4");

      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(4);
      g2.addInput(
        (nListFloats[0] + nListFloats[1]) / 2,
        nListFloats[0] - (nListFloats[0] + nListFloats[1]) / 2,
        "b)"
      );

      question.addInputGroup(g2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const n1Denom = MCRandom.randomInt(3, 10);
    const n1 = new MCMaths.Fraction(
      MCRandom.randomInt(n1Denom + 1, 3 * n1Denom - 1) / n1Denom
    );

    const n2Denom = MCRandom.randomInt(3, 10);
    const n2 = new MCMaths.Fraction(
      MCRandom.randomInt(n2Denom + 1, 3 * n2Denom - 1) / n2Denom
    );

    const n3Denom = MCRandom.randomInt(3, 10);
    const n3 = new MCMaths.Fraction(
      MCRandom.randomInt(n3Denom + 1, 3 * n3Denom - 1) / n3Denom
    );

    const n4Denom = MCRandom.randomInt(3, 10);
    const n4 = new MCMaths.Fraction(
      MCRandom.randomInt(n4Denom + 1, 3 * n4Denom - 1) / n4Denom
    );

    const nList = [
      [n1, n1.toMixed()],
      [n2, n2.toMixed()],
      [n3, n3],
      [n4, n4],
    ].sort(function (a, b) {
      return a[0].toFloat() - b[0].toFloat();
    });
    const nListFloats = [
      parseFloat(n1.toFloat().toFixed(3)),
      parseFloat(n2.toFloat().toFixed(3)),
      parseFloat(n3.toFloat().toFixed(3)),
      parseFloat(n4.toFloat().toFixed(3)),
    ].sort();
    const nListStrings = [nList[0][1], nList[1][1], nList[2][1], nList[3][1]];

    const nListShuffle = MCRandom.shuffle(nList);
    const nListShuffleStrings = [
      nListShuffle[0][1],
      nListShuffle[1][1],
      nListShuffle[2][1],
      nListShuffle[3][1],
    ];

    const randomNum = new MCMaths.Fraction(
      MCRandom.randomInt(150, 250) / 100
    ).toFloat();

    const extendedList = [
      parseFloat(n1.toFloat().toFixed(3)),
      parseFloat(n2.toFloat().toFixed(3)),
      parseFloat(n3.toFloat().toFixed(3)),
      parseFloat(n4.toFloat().toFixed(3)),
      randomNum,
    ].sort();

    let place = 0;
    while (extendedList[place] !== randomNum) {
      place += 1;
    }

    let lowerDiff = 0;
    let upperDiff = 0;
    if (place !== 0 && place !== 4) {
      lowerDiff = parseFloat((randomNum - nListFloats[place - 1]).toFixed(3));
      upperDiff = parseFloat((nListFloats[place] - randomNum).toFixed(3));
    }
    let correctIndex = 0;
    if (upperDiff < lowerDiff) {
      correctIndex = place;
    } else {
      correctIndex = place - 1;
    }
    /* END VARIABLES AND CONSTANTS */
    if (
      n1.toFloat() !== n2.toFloat() &&
      n1.toFloat() !== n3.toFloat() &&
      n1.toFloat() !== n4.toFloat() &&
      n2.toFloat() !== n3.toFloat() &&
      n2.toFloat() !== n4.toFloat() &&
      n3.toFloat() !== n4.toFloat() &&
      randomNum !== n1.toFloat() &&
      randomNum !== n2.toFloat() &&
      randomNum !== n3.toFloat() &&
      randomNum !== n4.toFloat() &&
      upperDiff !== lowerDiff
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `a) Write the following fractions in order of size, starting with the smallest.`
      );
      question.addParagraph("question", `$${nListShuffleStrings}$`);

      question.addParagraph(
        "question",
        `b) Which fraction is closest to the number $${randomNum}$`
      );
      //

      // solution
      question.addHeading(
        "solution",
        "a) First, convert all numbers to decimals."
      );
      for (let i = 0; i < 4; i += 1) {
        if (
          parseFloat(nListShuffle[i][0].toFloat().toFixed(3)) ===
          nListShuffle[i][0].toFloat()
        ) {
          question.addParagraph(
            "solution",
            `$${nListShuffleStrings[i]} = ${nListShuffle[i][0].toFloat()}$`
          );
        } else {
          question.addParagraph(
            "solution",
            `$${nListShuffleStrings[i]} \\approx ${nListShuffle[i][0]
              .toFloat()
              .toFixed(3)}$`
          );
        }
      }
      question.addHeading("solution", `The decimal order is:`);
      question.addParagraph("solution", `$${nListFloats}$`);
      question.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      question.addParagraph("solution", `$${nListStrings}$`);

      const g1 = new MCQuestion.InputGroup(4);
      g1.addInput(nListFloats[0], 0.005, "a) 1");
      g1.addInput(nListFloats[1], 0.005, "2");
      g1.addInput(nListFloats[2], 0.005, "3");
      g1.addInput(nListFloats[3], 0.005, "4");

      question.addInputGroup(g1);

      // b
      question.addHeading(
        "solution",
        `b) The ordered (decimal) list with $${randomNum}$ would be:`
      );
      question.addParagraph("solution", `$${extendedList}$`);

      if (place === 0) {
        question.addParagraph(
          "solution",
          `$${randomNum}$ belongs at the start of the list so $${nListStrings[0]}(${extendedList[1]})$ is the closest.`
        );
        const g2 = new MCQuestion.InputGroup(4);
        g2.addInput(extendedList[1], 0.005, "b)");

        question.addInputGroup(g2);
      } else if (place === 4) {
        question.addParagraph(
          "solution",
          `$${randomNum}$ belongs at the end of the list so $${nListStrings[3]}(${extendedList[3]})$ is the closest.`
        );

        const g2 = new MCQuestion.InputGroup(4);
        g2.addInput(extendedList[3], 0.005, "b)");

        question.addInputGroup(g2);
      } else {
        question.addParagraph(
          "solution",
          `$${randomNum}$ is between $${extendedList[place - 1]}$ and $${
            extendedList[place + 1]
          }$`
        );
        question.addParagraph(
          "solution",
          `$${randomNum} - ${extendedList[place - 1]} = ${lowerDiff}$`
        );
        question.addParagraph(
          "solution",
          `$${extendedList[place + 1]} - ${randomNum} = ${upperDiff}$`
        );
        if (upperDiff < lowerDiff) {
          question.addParagraph("solution", `$${upperDiff}<${lowerDiff}$`);
        } else {
          question.addParagraph("solution", `$${lowerDiff}<${upperDiff}$`);
        }
        question.addParagraph(
          "solution",
          `$\\therefore ${randomNum}$ is closer to $${nListStrings[correctIndex]}(${nListFloats[correctIndex]})$`
        );
        const g2 = new MCQuestion.InputGroup(4);
        g2.addInput(nListFloats[correctIndex], 0.005, "b)");

        question.addInputGroup(g2);
      }
      // answer

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n1 = MCRandom.randomInt(1, 10) * -1;
    const n2 = MCRandom.randomInt(1, 10) * -1;
    const n3 = MCRandom.randomInt(1, 10);
    const n4 = MCRandom.randomInt(1, 10);

    const nList = [n1, n2, n3, n4].sort(function (a, b) {
      return b - a;
    });
    const nListShuffle = MCRandom.shuffle(nList);

    const lower = MCRandom.randomInt(-4, -2);
    const upper = MCRandom.randomInt(2, 4);

    const lowerSign = [`<`, `\\leq`][MCRandom.randomInt(0, 1)];
    const upperSign = [`<`, `\\leq`][MCRandom.randomInt(0, 1)];

    const range = [];
    for (let i = lower; i < upper + 1; i += 1) {
      if (lower === i && lowerSign === `\\leq`) {
        range.push(i);
      } else if (upper === i && upperSign === `\\leq`) {
        range.push(i);
      } else if (i !== upper && i !== lower) {
        range.push(i);
      }
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      n1 !== n2 &&
      n1 !== n3 &&
      n1 !== n4 &&
      n2 !== n3 &&
      n2 !== n4 &&
      n3 !== n4
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Put the following numbers in order, starting with the largest.`
      );
      question.addParagraph("question", `$${nListShuffle}$`);
      question.addParagraph(
        "question",
        `b) State the number of integers that lie in the range $${lower}${lowerSign} n${upperSign}${upper}$`
      );

      // solution
      question.addHeading(
        "solution",
        "a) The correct order (largest to smallest) is:"
      );
      question.addParagraph("solution", `$${nList}$`);
      question.addParagraph(
        "solution",
        `$${nList[0]}>${nList[1]}$   because   $${nList[0]}-${nList[1]}>0$`
      );
      question.addParagraph(
        "solution",
        `$${nList[1]}>${nList[2]}$   because   $${nList[1]}-(${nList[2]})>0$`
      );
      question.addParagraph(
        "solution",
        `$${nList[2]}>${nList[3]}$   because   $${nList[2]}-(${nList[3]})>0$`
      );
      // b
      question.addHeading(
        "solution",
        `b) The integers that lie in the range $${lower}${lowerSign} n${upperSign}${upper}$ are:`
      );
      question.addParagraph("solution", `$${range}$`);
      if (lowerSign === `\\leq`) {
        question.addParagraph(
          "solution",
          `$${lower}\\leq${lower}$ because $${lower}=${lower}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$${lower}\\nless${lower}$ because $${lower}=${lower}$`
        );
      }
      if (upperSign === `\\leq`) {
        question.addParagraph(
          "solution",
          `$${upper}\\geq${upper}$ because $${upper}=${upper}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$${upper}\\ngtr${upper}$ because $${upper}=${upper}$`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ There are $${range.length}$ integers in this range.`
      );

      // answers

      const g1 = new MCQuestion.InputGroup(4);
      g1.addInput(nList[0], 0, "a) 1");
      g1.addInput(nList[1], 0, "2");
      g1.addInput(nList[2], 0, "3");
      g1.addInput(nList[3], 0, "4");

      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(4);
      g2.addInput(range.length, 0, "b)");

      question.addInputGroup(g2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const offset2 = MCRandom.randomInt(1, 9);
    const m1 = new MCMaths.Fraction(
      MCRandom.randomInt(offset2 * 100 + 5, offset2 * 100 + 95) / -1000
    );
    const m2 = new MCMaths.Fraction(
      MCRandom.randomInt(offset2 * 100 + 5, offset2 * 100 + 95) / -1000
    );
    const m3 = new MCMaths.Fraction(
      MCRandom.randomInt(offset2 * 100 + 5, offset2 * 100 + 95) / 1000
    );
    const m4 = new MCMaths.Fraction(
      MCRandom.randomInt(offset2 * 100 + 5, offset2 * 100 + 95) / 1000
    );

    const mList = [m1.toFloat(), m2.toFloat(), m3.toFloat(), m4.toFloat()].sort(
      function (a, b) {
        return b - a;
      }
    );
    const mListShuffle = MCRandom.shuffle(mList);

    const diff = parseFloat((mList[0] - mList[3]).toFixed(3));
    /* END VARIABLES AND CONSTANTS */
    if (
      m1.toFloat() !== m2.toFloat() &&
      m1.toFloat() !== m3.toFloat() &&
      m1.toFloat() !== m4.toFloat() &&
      m2.toFloat() !== m3.toFloat() &&
      m2.toFloat() !== m4.toFloat() &&
      m3.toFloat() !== m4.toFloat()
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        "a) Write the following numbers in order of size, largest to smallest."
      );
      question.addParagraph("question", `$${mListShuffle}$`);

      question.addParagraph(
        "question",
        "b) Calculate the difference between the largest and smallest number."
      );

      // solution
      question.addHeading(
        "solution",
        "a) The correct order (largest to smallest) is:"
      );
      question.addParagraph("solution", `$${mList}$`);
      question.addParagraph(
        "solution",
        `$${mList[0]}>${mList[1]}$   because   $${mList[0]}-${mList[1]}>0$`
      );
      question.addParagraph(
        "solution",
        `$${mList[1]}>${mList[2]}$   because   $${mList[1]}-(${mList[2]})>0$`
      );
      question.addParagraph(
        "solution",
        `$${mList[2]}>${mList[3]}$   because   $${mList[2]}-(${mList[3]})>0$`
      );

      question.addHeading(
        "solution",
        `b) Calculate the largest value subtract the smallest value.`
      );
      question.addParagraph("solution", `$${mList[0]}-(${mList[3]}) $`);
      question.addParagraph("solution", `$=${mList[0]}+${-mList[3]} $`);
      question.addParagraph("solution", `$= ${diff}$`);
      // answers

      const g1 = new MCQuestion.InputGroup(4);
      g1.addInput(mList[0], 0, "a) 1");
      g1.addInput(mList[1], 0, "2");
      g1.addInput(mList[2], 0, "3");
      g1.addInput(mList[3], 0, "4");

      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(4);
      g1.addInput(diff, 0.005, "b)");

      question.addInputGroup(g2);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Exam_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function sortAscending(a, b) {
      if (typeof a !== "number" && typeof b !== "number") {
        return -b.toFloat() + a.toFloat();
      }
      if (typeof b !== "number") {
        return -b.toFloat() + a;
      }
      if (typeof a !== "number") {
        return -b + a.toFloat();
      }
      return -b + a;
    }

    const n1 = new MCMaths.Fraction(
      (MCRandom.randomInt(5, 95) / 100) * MCRandom.randomInt(-1, 1, 2)
    );

    const n2Denom = MCRandom.randomInt(3, 20);
    const n2 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, n2Denom - 1) / n2Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const n3Denom = MCRandom.randomInt(3, 20);
    const n3 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, n3Denom - 1) / n3Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const n4Denom = MCRandom.randomInt(3, 20);
    const n4 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, n4Denom - 1) / n4Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const nList = [n1.toFloat(), n2, n3, n4].sort(sortAscending);

    const nListFloats = [
      n1.toFloat(),
      parseFloat(n2.toFloat().toFixed(3)),
      parseFloat(n3.toFloat().toFixed(3)),
      parseFloat(n4.toFloat().toFixed(3)),
    ].sort(sortAscending);

    const nListShuffle = MCRandom.shuffle([n1.toFloat(), n2, n3, n4]);

    const fracs = [n2, n3, n4];

    const b = new MCMaths.Fraction(
      (MCRandom.randomInt(-50, 20) / 100) * MCRandom.randomInt(-1, 1, 2)
    ).toFloat();

    let total = 0;
    for (let i = 0; i < 4; i += 1) {
      if ([n1, n2, n3, n4][i].toFloat() > b) {
        total += 1;
      }
    }

    const extendedList = [
      parseFloat(n1.toFloat().toFixed(3)),
      parseFloat(n2.toFloat().toFixed(3)),
      parseFloat(n3.toFloat().toFixed(3)),
      parseFloat(n4.toFloat().toFixed(3)),
      b,
    ].sort(sortAscending);

    /* END VARIABLES AND CONSTANTS */
    if (
      n1.toFloat() !== n2.toFloat() &&
      n1.toFloat() !== n3.toFloat() &&
      n1.toFloat() !== n4.toFloat() &&
      n2.toFloat() !== n3.toFloat() &&
      n2.toFloat() !== n4.toFloat() &&
      n3.toFloat() !== n4.toFloat()
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        "a) Place the following numbers in ascending order."
      );
      question.addParagraph("question", `$${nListShuffle}$`);

      question.addParagraph(
        "question",
        `b) State the number of decimals/fractions in the list greater than $${b}$`
      );

      // solution
      question.addHeading(
        "solution",
        "a) First, convert all numbers to decimals."
      );
      for (let i = 0; i < 3; i += 1) {
        if (parseFloat(fracs[i].toFloat().toFixed(3)) === fracs[i].toFloat()) {
          question.addParagraph(
            "solution",
            `$${fracs[i]} = ${fracs[i].toFloat()}$`
          );
        } else {
          question.addParagraph(
            "solution",
            `$${fracs[i]} \\approx ${fracs[i].toFloat().toFixed(3)}$`
          );
        }
      }
      question.addHeading("solution", `The decimal order is:`);
      question.addParagraph("solution", `$${nListFloats}$`);
      question.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      question.addParagraph("solution", `$${nList}$`);
      // b
      question.addHeading(
        "solution",
        `b) An ordered (decimal) list including $${b}$ would be:`
      );
      question.addParagraph("solution", `$${extendedList}$`);
      if (total === 0) {
        question.addParagraph(
          "solution",
          `$\\therefore$ There are $${total}$ numbers in the list greater than $${b}$`
        );
      } else if (total === 1) {
        question.addParagraph(
          "solution",
          `$\\therefore$ There is only $${total}$ number in the list greater than $${b}, (${
            nList[nList.length - 1]
          })$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore$ There are $${total}$ numbers in the list greater than $${b}, (${nList.slice(
            nList.length - total
          )})$`
        );
      }

      // answer
      const g1 = new MCQuestion.InputGroup(4);
      g1.addInput(nListFloats[0], 0.005, "a) 1");
      g1.addInput(nListFloats[1], 0.005, "2");
      g1.addInput(nListFloats[2], 0.005, "3");
      g1.addInput(nListFloats[3], 0.005, "4");

      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(4);
      g2.addInput(total, 0, "b)");

      question.addInputGroup(g2);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Exam_Q7() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const n1Denom = MCRandom.randomInt(3, 10);
    const n1 = new MCMaths.Fraction(
      (MCRandom.randomInt(n1Denom + 1, 3 * n1Denom - 1) / n1Denom) * -1
    );

    const n2Denom = MCRandom.randomInt(3, 10);
    const n2 = new MCMaths.Fraction(
      (MCRandom.randomInt(n2Denom + 1, 3 * n2Denom - 1) / n2Denom) * -1
    );

    const n3Denom = MCRandom.randomInt(3, 10);
    const n3 = new MCMaths.Fraction(
      MCRandom.randomInt(n3Denom + 1, 3 * n3Denom - 1) / n3Denom
    );

    const n4Denom = MCRandom.randomInt(3, 10);
    const n4 = new MCMaths.Fraction(
      MCRandom.randomInt(n4Denom + 1, 3 * n4Denom - 1) / n4Denom
    );

    const nList = [
      [n1, n1.toMixed()],
      [n2, n2.toMixed()],
      [n3, n3],
      [n4, n4],
    ].sort(function (a, b) {
      return b[0].toFloat() - a[0].toFloat();
    });
    const nListFloats = [
      parseFloat(n1.toFloat().toFixed(3)),
      parseFloat(n2.toFloat().toFixed(3)),
      parseFloat(n3.toFloat().toFixed(3)),
      parseFloat(n4.toFloat().toFixed(3)),
    ].sort(function (a, b) {
      return b - a;
    });
    const nListStrings = [nList[0][1], nList[1][1], nList[2][1], nList[3][1]];

    const nListShuffle = MCRandom.shuffle(nList);
    const nListShuffleStrings = [
      nListShuffle[0][1],
      nListShuffle[1][1],
      nListShuffle[2][1],
      nListShuffle[3][1],
    ];

    const comparator = parseFloat(
      (MCRandom.randomInt(-150, 150) / 100).toFixed(3)
    );

    const extendedList = [
      parseFloat(n1.toFloat().toFixed(3)),
      parseFloat(n2.toFloat().toFixed(3)),
      parseFloat(n3.toFloat().toFixed(3)),
      parseFloat(n4.toFloat().toFixed(3)),
      comparator,
    ].sort(function (a, b) {
      return b - a;
    });

    const pos = extendedList.indexOf(comparator);

    /* END VARIABLES AND CONSTANTS */
    if (
      n1.toFloat() !== n2.toFloat() &&
      n1.toFloat() !== n3.toFloat() &&
      n1.toFloat() !== n4.toFloat() &&
      n2.toFloat() !== n3.toFloat() &&
      n2.toFloat() !== n4.toFloat() &&
      n3.toFloat() !== n4.toFloat() &&
      comparator !== n1.toFloat() &&
      comparator !== n2.toFloat() &&
      comparator !== n3.toFloat() &&
      comparator !== n4.toFloat() &&
      pos < extendedList.length - 1
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `a) Place the following fractions in descending order.`
      );
      question.addParagraph("question", `$${nListShuffleStrings}$`);

      question.addParagraph(
        "question",
        `b) State the largest number in the list that's smaller than $${comparator}$`
      );
      //

      // solution
      question.addHeading(
        "solution",
        "a) First, convert all numbers to decimals."
      );
      for (let i = 0; i < 4; i += 1) {
        if (
          parseFloat(nListShuffle[i][0].toFloat().toFixed(3)) ===
          nListShuffle[i][0].toFloat()
        ) {
          question.addParagraph(
            "solution",
            `$${nListShuffleStrings[i]} = ${nListShuffle[i][0].toFloat()}$`
          );
        } else {
          question.addParagraph(
            "solution",
            `$${nListShuffleStrings[i]} \\approx ${nListShuffle[i][0]
              .toFloat()
              .toFixed(3)}$`
          );
        }
      }
      question.addHeading("solution", `The decimal order is:`);
      question.addParagraph("solution", `$${nListFloats}$`);
      question.addHeading(
        "solution",
        "Therefore, the correct order (largest to smallest) is:"
      );
      question.addParagraph("solution", `$${nListStrings}$`);

      // b#

      question.addHeading(
        `solution`,
        `b) An ordered (decimal) list would look like:`
      );
      question.addParagraph("solution", `$${extendedList}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ The largest number smaller than $${comparator}$ in the list is $${
          extendedList[pos + 1]
        }$`
      );
      // answers

      const g1 = new MCQuestion.InputGroup(4);
      g1.addInput(nListFloats[0], 0.005, "a) 1");
      g1.addInput(nListFloats[1], 0.005, "2");
      g1.addInput(nListFloats[2], 0.005, "3");
      g1.addInput(nListFloats[3], 0.005, "4");

      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(4);
      g2.addInput(extendedList[pos + 1], 0.005, "b)");

      question.addInputGroup(g2);

      // b

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Exam_Q8() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n1 = MCRandom.randomInt(1, 10) * -1;
    const n2 = MCRandom.randomInt(1, 10) * -1;
    const n3 = MCRandom.randomInt(1, 10);
    const n4 = MCRandom.randomInt(1, 10);

    const nList = [n1, n2, n3, n4].sort(function (a, b) {
      return a - b;
    });
    const nListShuffle = MCRandom.shuffle(nList);

    const days = ["Monday", "Wednesday", "Friday", "Sunday"];

    /* END VARIABLES AND CONSTANTS */
    if (
      n1 !== n2 &&
      n1 !== n3 &&
      n1 !== n4 &&
      n2 !== n3 &&
      n2 !== n4 &&
      n3 !== n4
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Shown below are a number of temperatures recorded in the first week of March 2018.`
      );
      const t = new MCQuestion.Table("Monday", "Wednesday", "Friday", "Sunday");
      t.addRow(
        `$${nListShuffle[0]}$`,
        `$${nListShuffle[1]}$`,
        `$${nListShuffle[2]}$`,
        `$${nListShuffle[3]}$`
      );
      question.addTable("question", t);

      question.addParagraph(
        "question",
        `Place the days in order of temperature, starting with the coldest.`
      );

      question.addParagraph(
        "question",
        `Tuesday was warmer than ${
          days[nListShuffle.indexOf(nList[1])]
        } but colder than ${days[nListShuffle.indexOf(nList[2])]}.`
      );

      question.addParagraph(
        "question",
        `b) State a possibility for the temperature recorded on Tuesday.`
      );

      // solution
      question.addHeading(
        "solution",
        "a) The correct order for the temperatures (smallest to largest) is:"
      );
      question.addParagraph("solution", `$${nList}$`);
      question.addParagraph("solution", `$\\therefore$ The order of days is:`);

      question.addParagraph(
        "solution",
        `${days[nListShuffle.indexOf(nList[0])]}, ${
          days[nListShuffle.indexOf(nList[1])]
        }, ${days[nListShuffle.indexOf(nList[2])]}, ${
          days[nListShuffle.indexOf(nList[3])]
        }`
      );

      // b
      question.addHeading(
        "solution",
        `b) The temperature must lie between $${nList[1]}$ and $${nList[2]}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ Must lie in the range $${nList[1]}<t<${nList[2]}$`
      );
      question.addParagraph(
        "solution",
        `For example, $${(nList[1] + nList[2]) / 2}$`
      );

      // answers

      question.requiresSelfMarking();

      const g2 = new MCQuestion.InputGroup(4);
      g2.addInput(
        (nList[1] + nList[2]) / 2,
        nList[2] - (nList[1] + nList[2]) / 2 - 0.001,
        "b)"
      );

      question.addInputGroup(g2);

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Exam_Q9() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const m1 = new MCMaths.Fraction(MCRandom.randomInt(50, 950) / -1000);
    const m2 = new MCMaths.Fraction(MCRandom.randomInt(50, 950) / -1000);
    const m3 = new MCMaths.Fraction(MCRandom.randomInt(50, 950) / 1000);
    const m4 = new MCMaths.Fraction(MCRandom.randomInt(50, 950) / 1000);

    const mList = [m1.toFloat(), m2.toFloat(), m3.toFloat(), m4.toFloat()].sort(
      function (a, b) {
        return b - a;
      }
    );
    const mListShuffle = MCRandom.shuffle(mList);

    const insects = [
      "Pharaoh Ant",
      "Common Wasp",
      "Carpenter Ant",
      "Mining Bee",
    ];

    const p = parseFloat(MCRandom.randomInt(-450, 450) / 1000).toFixed(3);

    const extendedList = [
      m1.toFloat(),
      m2.toFloat(),
      m3.toFloat(),
      m4.toFloat(),
      p,
    ].sort(function (a, b) {
      return b - a;
    });

    const working = [
      ["Pharaoh Ant", mListShuffle[0]],
      ["Common Wasp", mListShuffle[1]],
      ["Carpenter Ant", mListShuffle[2]],
      ["Mining Bee", mListShuffle[3]],
      ["Paper Wasp", p],
    ];

    const finalOrder = [];
    for (let i = 0; i < 5; i += 1) {
      for (let j = 0; j < 5; j += 1) {
        if (working[j][1] === extendedList[i]) {
          finalOrder.push(working[j][0]);
        }
      }
    }

    /* END VARIABLES AND CONSTANTS */
    if (
      m1.toFloat() !== m2.toFloat() &&
      m1.toFloat() !== m3.toFloat() &&
      m1.toFloat() !== m4.toFloat() &&
      m2.toFloat() !== m3.toFloat() &&
      m2.toFloat() !== m4.toFloat() &&
      m3.toFloat() !== m4.toFloat() &&
      p !== m1.toFloat() &&
      p !== m2.toFloat() &&
      p !== m3.toFloat() &&
      p !== m4.toFloat()
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `An insect pain scale maps the severity of different bites to a range of $-1 \\leq p \\leq1$`
      );
      question.addParagraph(
        "question",
        `The ranking of four different insects are shown in the table below.`
      );

      const t = new MCQuestion.Table(
        insects[0],
        insects[1],
        insects[2],
        insects[3]
      );
      t.addRow(
        `$${mListShuffle[0]}$`,
        `$${mListShuffle[1]}$`,
        `$${mListShuffle[2]}$`,
        `$${mListShuffle[3]}$`
      );
      question.addTable("question", t);

      question.addParagraph(
        "question",
        "a) Order the insects by their pain indices, from largest to smallest. "
      );
      question.addParagraph(
        "question",
        `A paper wasp has a pain index of $${p}$`
      );

      question.addParagraph(
        "question",
        `b) State the position the paper wasp would be in the list above.`
      );

      // solution
      question.addHeading(
        "solution",
        "a) The correct order for the indices (largest to smallest) is:"
      );
      question.addParagraph("solution", `$${mList}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ The order of insects is:`
      );

      question.addParagraph(
        "solution",
        `${insects[mListShuffle.indexOf(mList[0])]}, ${
          insects[mListShuffle.indexOf(mList[1])]
        }, ${insects[mListShuffle.indexOf(mList[2])]}, ${
          insects[mListShuffle.indexOf(mList[3])]
        }`
      );

      question.addHeading(
        "solution",
        `b) The new ordered list of pain indices would be:`
      );
      question.addParagraph("solution", `$${extendedList}$`);

      question.addParagraph(
        "solution",
        `$\\therefore$ The new order of insects is:`
      );
      question.addParagraph("solution", `${finalOrder}`.replace(/,/g, ", "));

      question.addParagraph(
        "solution",
        `$\\therefore$ The paper wasp would be in position $${
          finalOrder.indexOf("Paper Wasp") + 1
        }$`
      );

      // answers

      question.requiresSelfMarking();

      const g2 = new MCQuestion.InputGroup(4);
      g2.addInput(finalOrder.indexOf("Paper Wasp") + 1, 0, "b)");

      question.addInputGroup(g2);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_MCQ_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function sortAscending(a, b) {
      if (typeof a !== "number" && typeof b !== "number") {
        return a.toFloat() - b.toFloat();
      }
      if (typeof a !== "number") {
        return a.toFloat() - b;
      }
      if (typeof b !== "number") {
        return a - b.toFloat();
      }
      return a - b;
    }

    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }

    function arrayEquals(a, b) {
      for (let i = 0; i < a.length; i += 1) {
        if (a[i] !== b[i]) {
          return false;
        }
      }
      return true;
    }
    // option1
    const offset1 = MCRandom.randomInt(0, 4);

    const m1 = new MCMaths.Fraction(MCRandom.randomInt(5, 95) / 100 + offset1);

    const m2 = new MCMaths.Fraction(
      MCRandom.randomInt(5, 950) / 1000 + offset1
    );
    const m3 = new MCMaths.Fraction(
      MCRandom.randomInt(5, 950) / 1000 + offset1
    );
    const m4 = new MCMaths.Fraction(MCRandom.randomInt(5, 95) / 100 + offset1);

    const mList = [
      m1.toFloat(),
      m2.toFloat(),
      m3.toFloat(),
      m4.toFloat(),
    ].sort();

    const mShuffle = MCRandom.shuffle(mList);

    // option 2

    const n1 = new MCMaths.Fraction(MCRandom.randomInt(5, 95) / 100);

    const n2Denom = MCRandom.randomInt(3, 20);
    const n2 = new MCMaths.Fraction(
      MCRandom.randomInt(1, n2Denom - 1) / n2Denom
    );

    const n3Denom = MCRandom.randomInt(3, 20);
    const n3 = new MCMaths.Fraction(
      MCRandom.randomInt(1, n3Denom - 1) / n3Denom
    );

    const n4Denom = MCRandom.randomInt(3, 20);
    const n4 = new MCMaths.Fraction(
      MCRandom.randomInt(1, n4Denom - 1) / n4Denom
    );

    const nList = [n1.toFloat(), n2, n3, n4].sort(sortAscending);

    const nFloats = [n1.toFloat(), n2.toFloat(), n3.toFloat(), n4.toFloat()];

    const nShuffle = MCRandom.shuffle(nList);

    // option3
    const o1Denom = MCRandom.randomInt(3, 10);
    const o1 = new MCMaths.Fraction(
      MCRandom.randomInt(1, 2 * o1Denom - 1) / o1Denom
    );

    const o2Denom = MCRandom.randomInt(3, 10);
    const o2 = new MCMaths.Fraction(
      MCRandom.randomInt(1, 2 * o2Denom - 1) / o2Denom
    );

    const o3Denom = MCRandom.randomInt(3, 10);
    const o3 = new MCMaths.Fraction(
      MCRandom.randomInt(1, 2 * o3Denom - 1) / o3Denom
    );

    const o4Denom = MCRandom.randomInt(3, 10);
    const o4 = new MCMaths.Fraction(
      MCRandom.randomInt(1, 2 * o4Denom - 1) / o4Denom
    );

    const oList = [
      [o1, o1.toMixed()],
      [o2, o2.toMixed()],
      [o3, o3],
      [o4, o4],
    ].sort(function (a, b) {
      return a[0].toFloat() - b[0].toFloat();
    });

    const oListStrings = [oList[0][1], oList[1][1], oList[2][1], oList[3][1]];
    const oListFloats = [
      parseFloat(o1.toFloat().toFixed(3)),
      parseFloat(o2.toFloat().toFixed(3)),
      parseFloat(o3.toFloat().toFixed(3)),
      parseFloat(o4.toFloat().toFixed(3)),
    ];

    const oShuffle = MCRandom.shuffle(oListStrings);

    // option4
    const p1 = new MCMaths.Fraction(MCRandom.randomInt(-500, 500) / 100);
    const p2 = new MCMaths.Fraction(MCRandom.randomInt(-500, 500) / 100);
    const p3 = new MCMaths.Fraction(MCRandom.randomInt(-500, 500) / 100);
    const p4 = new MCMaths.Fraction(MCRandom.randomInt(-500, 500) / 100);

    const pList = [p1.toFloat(), p2.toFloat(), p3.toFloat(), p4.toFloat()].sort(
      sortAscending
    );
    const pShuffle = MCRandom.shuffle(pList);

    const correctAnswer = MCRandom.randomInt(0, 3);
    const answerArray = [
      correctAnswer === 0,
      correctAnswer === 1,
      correctAnswer === 2,
      correctAnswer === 3,
    ];
    /* END VARIABLES AND CONSTANTS */
    if (
      allDifferent(mList) &&
      allDifferent(nFloats) &&
      allDifferent(oListFloats) &&
      allDifferent(pList) &&
      !arrayEquals(mList, mShuffle) &&
      !arrayEquals(nList, nShuffle) &&
      !arrayEquals(oListStrings, oShuffle) &&
      !arrayEquals(pList, pShuffle)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        "Which of the following lists is correctly ordered from smallest to largest."
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      if (!answerArray[0]) {
        answer1.addParagraph(`$${mShuffle}$`);
      } else {
        answer1.addParagraph(`$${mList}$`);
      }

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      if (!answerArray[1]) {
        answer2.addParagraph(`$${nShuffle}$`);
      } else {
        answer2.addParagraph(`$${nList}$`);
      }

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      if (!answerArray[2]) {
        answer3.addParagraph(`$${oShuffle}$`);
      } else {
        answer3.addParagraph(`$${oListStrings}$`);
      }

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      if (!answerArray[3]) {
        answer4.addParagraph(`$${pShuffle}$`);
      } else {
        answer4.addParagraph(`$${pList}$`);
      }

      question.addAnswer(answer1, answerArray[0]);
      question.addAnswer(answer2, answerArray[1]);
      question.addAnswer(answer3, answerArray[2]);
      question.addAnswer(answer4, answerArray[3]);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_MCQ_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function sortDescending(a, b) {
      if (typeof a !== "number" && typeof b !== "number") {
        return b.toFloat() - a.toFloat();
      }
      if (typeof b !== "number") {
        return b.toFloat() - a;
      }
      if (typeof a !== "number") {
        return b - a.toFloat();
      }
      return b - a;
    }

    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }
    // list
    const n1 = new MCMaths.Fraction(
      (MCRandom.randomInt(5, 295) / 100) * MCRandom.randomInt(-1, 1, 2)
    );

    const n2 = new MCMaths.Fraction(
      (MCRandom.randomInt(5, 295) / 100) * MCRandom.randomInt(-1, 1, 2)
    );

    const n3Denom = MCRandom.randomInt(3, 10);
    const n3 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, 3 * n3Denom - 1) / n3Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const n4Denom = MCRandom.randomInt(3, 10);
    const n4 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, 3 * n4Denom - 1) / n4Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const n5 = new MCMaths.Fraction(
      (MCRandom.randomInt(5, 295) / 100) * MCRandom.randomInt(-1, 1, 2)
    );

    const n6 = new MCMaths.Fraction(
      (MCRandom.randomInt(5, 295) / 100) * MCRandom.randomInt(-1, 1, 2)
    );

    const n7Denom = MCRandom.randomInt(3, 10);
    const n7 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, 3 * n7Denom - 1) / n7Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const n8Denom = MCRandom.randomInt(3, 10);
    const n8 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, 3 * n8Denom - 1) / n8Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const nList = [
      n1.toFloat(),
      n2.toFloat(),
      n3,
      n4,
      n5.toFloat(),
      n6.toFloat(),
      n7,
      n8,
    ].sort(sortDescending);

    const nListFloats = [
      n1.toFloat(),
      n2.toFloat(),
      parseFloat(n3.toFloat().toFixed(3)),
      parseFloat(n4.toFloat().toFixed(3)),
      n5.toFloat(),
      n6.toFloat(),
      parseFloat(n7.toFloat().toFixed(3)),
      parseFloat(n8.toFloat().toFixed(3)),
    ].sort(sortDescending);

    const in1 = MCRandom.randomInt(0, 1);
    const in2 = MCRandom.randomInt(2, 3);
    const in3 = in2 + 2; // 4 or 5
    const in4 = MCRandom.randomInt(6, 7);

    const pos = in2 + 1;

    const out1 = 1 - (in1 % 2);
    let out2 = 0;
    if (in2 === 2) {
      out2 = 5;
    } else {
      out2 = 2;
    }
    const out3 = 7 - (in4 % 2);

    const displayList = [];
    for (let i = 0; i < nList.length; i += 1) {
      if (i === pos) {
        displayList.push(`\\_\\_\\_`);
      }
      if (i === in1 || i === in2 || i === in3 || i === in4) {
        displayList.push(nList[i]);
      }
    }

    const optionIndexes = [];
    for (let i = 0; i < nList.length; i += 1) {
      if (i !== in1 && i !== in2 && i !== in3 && i !== in4) {
        optionIndexes.push(i);
      }
    }

    /* END VARIABLES AND CONSTANTS */
    if (allDifferent(nListFloats)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        "Which of the following numbers completes this ordered list."
      );

      question.addParagraph(`$\\displaystyle ${displayList}$ `);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${nList[pos]}$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${nList[out1]}$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${nList[out2]}$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${nList[out3]}$`);

      question.addAnswer(answer1, true);
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, false);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_MCQ_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function sortDescending(a, b) {
      if (typeof a !== "number" && typeof b !== "number") {
        return b.toFloat() - a.toFloat();
      }
      if (typeof b !== "number") {
        return b.toFloat() - a;
      }
      if (typeof a !== "number") {
        return b - a.toFloat();
      }
      return b - a;
    }

    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }

    const n1 = new MCMaths.Fraction(MCRandom.randomInt(5, 195) / 100);

    const n2 = new MCMaths.Fraction(MCRandom.randomInt(5, 195) / 100);

    const n3Denom = MCRandom.randomInt(3, 10);
    const n3 = new MCMaths.Fraction(
      MCRandom.randomInt(2, 2 * n3Denom - 1) / n3Denom
    );

    const n4Denom = MCRandom.randomInt(3, 10);
    const n4 = new MCMaths.Fraction(
      MCRandom.randomInt(2, 2 * n4Denom - 1) / n4Denom
    );

    const nList = [n1.toFloat(), n2.toFloat(), n3, n4].sort(sortDescending);

    const nListFloats = [
      n1.toFloat(),
      n2.toFloat(),
      parseFloat(n3.toFloat().toFixed(3)),
      parseFloat(n4.toFloat().toFixed(3)),
    ].sort(sortDescending);

    const word = ["smallest", "largest"][MCRandom.randomInt(0, 1)];

    /* END VARIABLES AND CONSTANTS */
    if (allDifferent(nListFloats)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(`Select the ${word} number below.`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${nList[0]}$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${nList[1]}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${nList[2]}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${nList[3]}$`);
      question.addAnswer(answer1, word === "largest");
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, word === "smallest");
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_MCQ_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }

    const n1 = new MCMaths.Fraction(MCRandom.randomInt(20, 180) / 100);

    const n2 = new MCMaths.Fraction(MCRandom.randomInt(20, 180) / 100);

    const n3Denom = MCRandom.randomInt(3, 10);
    const n3 = new MCMaths.Fraction(
      MCRandom.randomInt(2, 2 * n3Denom - 1) / n3Denom
    );

    const n4Denom = MCRandom.randomInt(3, 10);
    const n4 = new MCMaths.Fraction(
      MCRandom.randomInt(2, 2 * n4Denom - 1) / n4Denom
    );

    const nList = [n1.toFloat(), n2.toFloat(), n3, n4];

    const nListFloats = [
      n1.toFloat(),
      n2.toFloat(),
      parseFloat(n3.toFloat().toFixed(3)),
      parseFloat(n4.toFloat().toFixed(3)),
    ];

    const word = ["less", "greater"][MCRandom.randomInt(0, 1)];
    const comparator = new MCMaths.Fraction(MCRandom.randomInt(60, 140) / 100);
    /* END VARIABLES AND CONSTANTS */
    if (allDifferent(nListFloats)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(
        `Select all numbers below that are ${word} than $${comparator.toFloat()}$.`
      );

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${nList[0]}$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${nList[1]}$`);
      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${nList[2]}$`);
      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${nList[3]}$`);

      if (word === "less") {
        question.addAnswer(answer1, nListFloats[0] < comparator.toFloat());
        question.addAnswer(answer2, nListFloats[1] < comparator.toFloat());
        question.addAnswer(answer3, nListFloats[2] < comparator.toFloat());
        question.addAnswer(answer4, nListFloats[3] < comparator.toFloat());
      } else {
        question.addAnswer(answer1, nListFloats[0] > comparator.toFloat());
        question.addAnswer(answer2, nListFloats[1] > comparator.toFloat());
        question.addAnswer(answer3, nListFloats[2] > comparator.toFloat());
        question.addAnswer(answer4, nListFloats[3] > comparator.toFloat());
      }

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_MCQ_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }
    const offset1 = MCRandom.randomInt(1, 9);
    const offset2 = MCRandom.randomInt(1, 9);
    const offset3 = MCRandom.randomInt(1, 9);

    const m1 = new MCMaths.Fraction(
      MCRandom.randomInt(
        offset2 * 100 + offset3 * 10,
        offset2 * 100 + offset3 * 10 + 9
      ) /
        1000 +
        offset1
    );
    const m2 = new MCMaths.Fraction(
      MCRandom.randomInt(
        offset2 * 100 + offset3 * 10,
        offset2 * 100 + offset3 * 10 + 9
      ) /
        1000 +
        offset1
    );
    const m3 = new MCMaths.Fraction(
      MCRandom.randomInt(offset2 * 100 + 5, offset2 * 100 + 95) / 1000 + offset1
    );
    const m4 = new MCMaths.Fraction(
      MCRandom.randomInt(offset2 * 100 + 5, offset2 * 100 + 95) / 1000 + offset1
    );

    const mList = [m1.toFloat(), m2.toFloat(), m3.toFloat(), m4.toFloat()].sort(
      function (a, b) {
        return b - a;
      }
    );

    const word = ["smallest", "largest"][MCRandom.randomInt(0, 1)];
    /* END VARIABLES AND CONSTANTS */
    if (allDifferent(mList)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.MultipleChoiceQuestion(2);
      question.addParagraph(`Select the ${word} decimal below.`);

      const answer1 = new MCQuestion.MultipleChoiceAnswer();
      answer1.addParagraph(`$${mList[0]}$`);

      const answer2 = new MCQuestion.MultipleChoiceAnswer();
      answer2.addParagraph(`$${mList[1]}$`);

      const answer3 = new MCQuestion.MultipleChoiceAnswer();
      answer3.addParagraph(`$${mList[2]}$`);

      const answer4 = new MCQuestion.MultipleChoiceAnswer();
      answer4.addParagraph(`$${mList[3]}$`);

      question.addAnswer(answer1, word === "largest");
      question.addAnswer(answer2, false);
      question.addAnswer(answer3, false);
      question.addAnswer(answer4, word === "smallest");

      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Skills_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }

    function sortAscending(a, b) {
      if (typeof a !== "number" && typeof b !== "number") {
        return a.toFloat() - b.toFloat();
      }

      return a - b;
    }
    // part1
    const m_offset1 = MCRandom.randomInt(2, 6);
    const m_offset2 = MCRandom.randomInt(1, 9);
    const m_offset3 = MCRandom.randomInt(1, 9);

    const m1 = MCRandom.randomInt(
      m_offset1 * 1000 + m_offset2 * 100 + m_offset3 * 10,
      m_offset1 * 1000 + m_offset2 * 100 + m_offset3 * 10 + 9
    );
    const m2 = MCRandom.randomInt(
      m_offset1 * 1000 + m_offset2 * 100 + m_offset3 * 10,
      m_offset1 * 1000 + m_offset2 * 100 + m_offset3 * 10 + 9
    );
    const m3 = MCRandom.randomInt(
      m_offset1 * 1000 + m_offset2 * 100 + 5,
      m_offset1 * 1000 + m_offset2 * 100 + 95
    );
    const m4 = MCRandom.randomInt(
      m_offset1 * 1000 + m_offset2 * 100 + 5,
      m_offset1 * 1000 + m_offset2 * 100 + 95
    );

    const mList = [m1, m2, m3, m4].sort();

    // part2
    const n_offset1 = MCRandom.randomInt(2, 6);

    const n1 = new MCMaths.Fraction(
      MCRandom.randomInt(100 * n_offset1 + 5, 100 * (n_offset1 + 2) - 5) / 100
    );
    const n2 = new MCMaths.Fraction(
      MCRandom.randomInt(100 * n_offset1 + 5, 100 * (n_offset1 + 2) - 5) / 100
    );
    const n3 = new MCMaths.Fraction(
      MCRandom.randomInt(100 * n_offset1 + 5, 100 * (n_offset1 + 2) - 5) / 100
    );
    const n4 = new MCMaths.Fraction(
      MCRandom.randomInt(100 * n_offset1 + 5, 100 * (n_offset1 + 2) - 5) / 100
    );

    const nList = [
      n1.toFloat(),
      n2.toFloat(),
      n3.toFloat(),
      n4.toFloat(),
    ].sort();

    // part3

    const o_offset1 = MCRandom.randomInt(2, 6);

    const o1 = new MCMaths.Fraction(
      MCRandom.randomInt(1000 * o_offset1 + 50, 1000 * o_offset1 + 950, 10) /
        1000
    );
    const o2 = new MCMaths.Fraction(
      MCRandom.randomInt(1000 * o_offset1 + 50, 1000 * o_offset1 + 950, 10) /
        1000
    );
    const o3 = new MCMaths.Fraction(
      MCRandom.randomInt(1000 * o_offset1 + 50, 1000 * o_offset1 + 950) / 1000
    );
    const o4 = new MCMaths.Fraction(
      MCRandom.randomInt(1000 * o_offset1 + 50, 1000 * o_offset1 + 950) / 1000
    );

    const oList = [
      o1.toFloat(),
      o2.toFloat(),
      o3.toFloat(),
      o4.toFloat(),
    ].sort();

    // part4

    const p_offset1 = MCRandom.randomInt(2, 6);
    const p_offset2 = MCRandom.randomInt(1, 9);
    const p_offset3 = MCRandom.randomInt(1, 9);

    const p1 = new MCMaths.Fraction(
      MCRandom.randomInt(
        p_offset1 * 1000 + p_offset2 * 100 + p_offset3 * 10,
        p_offset1 * 1000 + p_offset2 * 100 + p_offset3 * 10 + 9
      ) / 1000
    );
    const p2 = new MCMaths.Fraction(
      MCRandom.randomInt(
        p_offset1 * 1000 + p_offset2 * 100 + p_offset3 * 10,
        p_offset1 * 1000 + p_offset2 * 100 + p_offset3 * 10 + 9
      ) / 1000
    );
    const p3 = new MCMaths.Fraction(
      MCRandom.randomInt(
        p_offset1 * 1000 + p_offset2 * 100 + 5,
        p_offset1 * 1000 + p_offset2 * 100 + 95
      ) / 1000
    );
    const p4 = new MCMaths.Fraction(
      MCRandom.randomInt(
        p_offset1 * 1000 + p_offset2 * 100 + 5,
        p_offset1 * 1000 + p_offset2 * 100 + 95
      ) / 1000
    );

    const pList = [
      p1.toFloat(),
      p2.toFloat(),
      p3.toFloat(),
      p4.toFloat(),
    ].sort();

    /* END VARIABLES AND CONSTANTS */
    if (
      allDifferent(mList) &&
      allDifferent(nList) &&
      allDifferent(oList) &&
      allDifferent(pList)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.SkillsQuestion(4);

      question.addParagraph(
        "Place the following lists of decimals in ascending order."
      );

      const part1 = new MCQuestion.SkillsPart();

      part1.addParagraph("question", `$${MCRandom.shuffle(mList)}$`);
      part1.addHeading(
        "solution",
        `The correct order (smallest to largest) is:`
      );
      part1.addParagraph("solution", `$${mList}$`);
      part1.addParagraph(
        "solution",
        `$${mList[0]}<${mList[1]}$   because   $${mList[0]}-${mList[1]}<0$`
      );
      part1.addParagraph(
        "solution",
        `$${mList[1]}<${mList[2]}$   because   $${mList[1]}-${mList[2]}<0$`
      );
      part1.addParagraph(
        "solution",
        `$${mList[2]}<${mList[3]}$   because   $${mList[2]}-${mList[3]}<0$`
      );

      const part2 = new MCQuestion.SkillsPart();

      part2.addParagraph("question", `$${MCRandom.shuffle(nList)}$`);
      part2.addHeading(
        "solution",
        `The correct order (smallest to largest) is:`
      );
      part2.addParagraph("solution", `$${nList}$`);

      part2.addParagraph(
        "solution",
        `$${nList[0]}<${nList[1]}$   because   $${nList[0]}-${nList[1]}<0$`
      );
      part2.addParagraph(
        "solution",
        `$${nList[1]}<${nList[2]}$   because   $${nList[1]}-${nList[2]}<0$`
      );
      part2.addParagraph(
        "solution",
        `$${nList[2]}<${nList[3]}$   because   $${nList[2]}-${nList[3]}<0$`
      );

      const part3 = new MCQuestion.SkillsPart();

      part3.addParagraph("question", `$${MCRandom.shuffle(oList)}$`);
      part3.addHeading(
        "solution",
        `The correct order (smallest to largest) is:`
      );
      part3.addParagraph("solution", `$${oList}$`);

      part3.addParagraph(
        "solution",
        `$${oList[0]}<${oList[1]}$   because   $${oList[0]}-${oList[1]}<0$`
      );
      part3.addParagraph(
        "solution",
        `$${oList[1]}<${oList[2]}$   because   $${oList[1]}-${oList[2]}<0$`
      );
      part3.addParagraph(
        "solution",
        `$${oList[2]}<${oList[3]}$   because   $${oList[2]}-${oList[3]}<0$`
      );

      const part4 = new MCQuestion.SkillsPart();

      part4.addParagraph("question", `$${MCRandom.shuffle(pList)}$`);
      part4.addHeading(
        "solution",
        `The correct order (smallest to largest) is:`
      );
      part4.addParagraph("solution", `$${pList}$`);

      part4.addParagraph(
        "solution",
        `$${pList[0]}<${pList[1]}$   because   $${pList[0]}-${pList[1]}<0$`
      );
      part4.addParagraph(
        "solution",
        `$${pList[1]}<${pList[2]}$   because   $${pList[1]}-${pList[2]}<0$`
      );
      part4.addParagraph(
        "solution",
        `$${pList[2]}<${pList[3]}$   because   $${pList[2]}-${pList[3]}<0$`
      );

      // answers

      part1.addAnswer(mList[0], 0, "a) 1");
      part1.addAnswer(mList[1], 0, "2");
      part1.addAnswer(mList[2], 0, "3");
      part1.addAnswer(mList[3], 0, "4");

      part2.addAnswer(nList[0], 0.05, "b) 1");
      part2.addAnswer(nList[1], 0.05, "2");
      part2.addAnswer(nList[2], 0.05, "3");
      part2.addAnswer(nList[3], 0.05, "4");

      part3.addAnswer(oList[0], 0.005, "c) 1");
      part3.addAnswer(oList[1], 0.005, "2");
      part3.addAnswer(oList[2], 0.005, "3");
      part3.addAnswer(oList[3], 0.005, "4");

      part4.addAnswer(pList[0], 0.005, "d) 1");
      part4.addAnswer(pList[1], 0.005, "2");
      part4.addAnswer(pList[2], 0.005, "3");
      part4.addAnswer(pList[3], 0.005, "4");

      question.addPart(part1);
      question.addPart(part2);
      question.addPart(part3);
      question.addPart(part4);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Skills_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }

    function sortAscending(a, b) {
      return a.toFloat() - b.toFloat();
    }
    function sort2D(a, b) {
      return a[0].toFloat() - b[0].toFloat();
    }
    // part1

    const m1Denom = [2, 3, 4, 6, 8, 12][MCRandom.randomInt(0, 5)];
    const m1 = new MCMaths.Fraction(
      MCRandom.randomInt(1, m1Denom - 1) / m1Denom
    );

    const m2Denom = [2, 3, 4, 6, 8, 12][MCRandom.randomInt(0, 5)];
    const m2 = new MCMaths.Fraction(
      MCRandom.randomInt(1, m2Denom - 1) / m2Denom
    );

    const m3Denom = [2, 3, 4, 6, 8, 12][MCRandom.randomInt(0, 5)];
    const m3 = new MCMaths.Fraction(
      MCRandom.randomInt(1, m3Denom - 1) / m3Denom
    );

    const m4Denom = [2, 3, 4, 6, 8, 12][MCRandom.randomInt(0, 5)];
    const m4 = new MCMaths.Fraction(
      MCRandom.randomInt(1, m4Denom - 1) / m4Denom
    );

    const mList = [m1, m2, m3, m4].sort(sortAscending);

    const mListFloats = [
      parseFloat(m1.toFloat().toFixed(3)),
      parseFloat(m2.toFloat().toFixed(3)),
      parseFloat(m3.toFloat().toFixed(3)),
      parseFloat(m4.toFloat().toFixed(3)),
    ].sort();

    const mShuffle = MCRandom.shuffle(mList);

    // part2
    const n1Denom = MCRandom.randomInt(3, 20);
    const n1 = new MCMaths.Fraction(
      MCRandom.randomInt(1, n1Denom - 1) / n1Denom
    );
    const n2Denom = MCRandom.randomInt(3, 20);
    const n2 = new MCMaths.Fraction(
      MCRandom.randomInt(1, n2Denom - 1) / n2Denom
    );

    const n3Denom = MCRandom.randomInt(3, 20);
    const n3 = new MCMaths.Fraction(
      MCRandom.randomInt(1, n3Denom - 1) / n3Denom
    );

    const n4Denom = MCRandom.randomInt(3, 20);
    const n4 = new MCMaths.Fraction(
      MCRandom.randomInt(1, n4Denom - 1) / n4Denom
    );

    const nList = [n1, n2, n3, n4].sort(sortAscending);

    const nListFloats = [
      parseFloat(n1.toFloat().toFixed(3)),
      parseFloat(n2.toFloat().toFixed(3)),
      parseFloat(n3.toFloat().toFixed(3)),
      parseFloat(n4.toFloat().toFixed(3)),
    ].sort();

    const nShuffle = MCRandom.shuffle(nList);

    // part3

    const o1Denom = MCRandom.randomInt(3, 10);
    const o1 = new MCMaths.Fraction(
      MCRandom.randomInt(o1Denom + 1, 2 * o1Denom - 1) / o1Denom
    );
    const o2Denom = MCRandom.randomInt(3, 10);
    const o2 = new MCMaths.Fraction(
      MCRandom.randomInt(o2Denom + 1, 2 * o2Denom - 1) / o2Denom
    );

    const o3Denom = MCRandom.randomInt(3, 10);
    const o3 = new MCMaths.Fraction(
      MCRandom.randomInt(1, 2 * o3Denom - 1) / o3Denom
    );

    const o4Denom = MCRandom.randomInt(3, 10);
    const o4 = new MCMaths.Fraction(
      MCRandom.randomInt(1, 2 * o4Denom - 1) / o4Denom
    );

    const oList = [
      [o1, o1],
      [o2, o2],
      [o3, o3],
      [o4, o4],
    ].sort(sort2D);

    for (let i = 0; i < oList.length; i += 1) {
      if (MCRandom.randomInt(0, 5) % 5 === 0) {
        oList[i][1] = oList[i][1].toMixed();
      }
    }

    const oListFloats = [
      parseFloat(o1.toFloat().toFixed(3)),
      parseFloat(o2.toFloat().toFixed(3)),
      parseFloat(o3.toFloat().toFixed(3)),
      parseFloat(o4.toFloat().toFixed(3)),
    ].sort();

    const oShuffle = MCRandom.shuffle(oList);

    const oShuffleFloats = [
      parseFloat(oShuffle[0][0].toFloat().toFixed(3)),
      parseFloat(oShuffle[1][0].toFloat().toFixed(3)),
      parseFloat(oShuffle[2][0].toFloat().toFixed(3)),
      parseFloat(oShuffle[3][0].toFloat().toFixed(3)),
    ];

    // part4
    const p_offset = MCRandom.randomInt(1, 2);

    const p1Denom = MCRandom.randomInt(3, 10);
    const p1 = new MCMaths.Fraction(
      MCRandom.randomInt(1, p1Denom - 1) / p1Denom + p_offset
    );
    const p2Denom = MCRandom.randomInt(3, 10);
    const p2 = new MCMaths.Fraction(
      MCRandom.randomInt(1, p2Denom - 1) / p2Denom + p_offset
    );

    const p3Denom = MCRandom.randomInt(3, 10);
    const p3 = new MCMaths.Fraction(
      MCRandom.randomInt(1, p3Denom - 1) / p3Denom + p_offset
    );

    const p4Denom = MCRandom.randomInt(3, 10);
    const p4 = new MCMaths.Fraction(
      MCRandom.randomInt(1, p4Denom - 1) / p4Denom + p_offset
    );

    const pList = [
      [p1, p1],
      [p2, p2],
      [p3, p3],
      [p4, p4],
    ].sort(sort2D);

    for (let i = 0; i < pList.length; i += 1) {
      if (MCRandom.randomInt(0, 5) % 5 === 0) {
        pList[i][1] = pList[i][1].toMixed();
      }
    }

    const pListFloats = [
      parseFloat(p1.toFloat().toFixed(3)),
      parseFloat(p2.toFloat().toFixed(3)),
      parseFloat(p3.toFloat().toFixed(3)),
      parseFloat(p4.toFloat().toFixed(3)),
    ].sort();

    const pShuffle = MCRandom.shuffle(pList);

    const pShuffleFloats = [
      parseFloat(pShuffle[0][0].toFloat().toFixed(3)),
      parseFloat(pShuffle[1][0].toFloat().toFixed(3)),
      parseFloat(pShuffle[2][0].toFloat().toFixed(3)),
      parseFloat(pShuffle[3][0].toFloat().toFixed(3)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (
      allDifferent(mListFloats) &&
      allDifferent(nListFloats) &&
      allDifferent(oListFloats) &&
      allDifferent(pListFloats)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.SkillsQuestion(4);

      question.addParagraph(
        "Place the following lists of fractions in order of size, starting with the smallest."
      );

      const part1 = new MCQuestion.SkillsPart();

      part1.addParagraph("question", `$\\displaystyle ${mShuffle}$`);

      part1.addHeading("solution", "First, convert all numbers to decimals.");
      for (let i = 0; i < 4; i += 1) {
        if (mShuffle[i].toFloat() !== 1) {
          if (
            parseFloat(mShuffle[i].toFloat().toFixed(3)) ===
            mShuffle[i].toFloat()
          ) {
            part1.addParagraph(
              "solution",
              `$${mShuffle[i]} = ${mShuffle[i].toFloat()}$`
            );
          } else {
            part1.addParagraph(
              "solution",
              `$${mShuffle[i]} \\approx ${parseFloat(
                mShuffle[i].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part1.addHeading("solution", `The decimal order is:`);
      part1.addParagraph("solution", `$${mListFloats}$`);
      part1.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part1.addParagraph("solution", `$${mList}$`);

      const part2 = new MCQuestion.SkillsPart();

      part2.addParagraph("question", `$\\displaystyle ${nShuffle}$`);

      part2.addHeading("solution", "First, convert all numbers to decimals.");
      for (let i = 0; i < 4; i += 1) {
        if (nShuffle[i].toFloat() !== 1) {
          if (
            parseFloat(nShuffle[i].toFloat().toFixed(3)) ===
            nShuffle[i].toFloat()
          ) {
            part2.addParagraph(
              "solution",
              `$${nShuffle[i]} = ${nShuffle[i].toFloat()}$`
            );
          } else {
            part2.addParagraph(
              "solution",
              `$${nShuffle[i]} \\approx ${parseFloat(
                nShuffle[i].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part2.addHeading("solution", `The decimal order is:`);
      part2.addParagraph("solution", `$${nListFloats}$`);
      part2.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part2.addParagraph("solution", `$${nList}$`);

      const part3 = new MCQuestion.SkillsPart();

      part3.addParagraph(
        "question",
        `$\\displaystyle ${oShuffle.map((x) => x[1])}$`
      );
      part3.addHeading("solution", "First, convert all numbers to decimals.");
      for (let i = 0; i < 4; i += 1) {
        if (oShuffle[i][0].toFloat() !== 1) {
          if (
            parseFloat(oShuffle[i][0].toFloat().toFixed(3)) ===
            oShuffle[i][0].toFloat()
          ) {
            part3.addParagraph(
              "solution",
              `$${oShuffle[i][1]} = ${oShuffle[i][0].toFloat()}$`
            );
          } else {
            part3.addParagraph(
              "solution",
              `$${oShuffle[i][1]} \\approx ${parseFloat(
                oShuffle[i][0].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part3.addHeading("solution", `The decimal order is:`);
      part3.addParagraph("solution", `$${oListFloats}$`);
      part3.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part3.addParagraph("solution", `$${oList.map((x) => x[1])}$`);

      const part4 = new MCQuestion.SkillsPart();

      part4.addParagraph(
        "question",
        `$\\displaystyle ${pShuffle.map((x) => x[1])}$`
      );

      part4.addHeading("solution", "First, convert all numbers to decimals.");
      for (let i = 0; i < 4; i += 1) {
        if (pShuffle[i][0].toFloat() !== 1) {
          if (
            parseFloat(pShuffle[i][0].toFloat().toFixed(3)) ===
            pShuffle[i][0].toFloat()
          ) {
            part4.addParagraph(
              "solution",
              `$${pShuffle[i][1]} = ${pShuffle[i][0].toFloat()}$`
            );
          } else {
            part4.addParagraph(
              "solution",
              `$${pShuffle[i][1]} \\approx ${parseFloat(
                pShuffle[i][0].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part4.addHeading("solution", `The decimal order is:`);
      part4.addParagraph("solution", `$${pListFloats}$`);
      part4.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part4.addParagraph("solution", `$${pList.map((x) => x[1])}$`);

      // answers

      part1.addAnswer(mListFloats[0], 0.005, "a) 1");
      part1.addAnswer(mListFloats[1], 0.005, "2");
      part1.addAnswer(mListFloats[2], 0.005, "3");
      part1.addAnswer(mListFloats[3], 0.005, "4");

      part2.addAnswer(nListFloats[0], 0.005, "b) 1");
      part2.addAnswer(nListFloats[1], 0.005, "2");
      part2.addAnswer(nListFloats[2], 0.005, "3");
      part2.addAnswer(nListFloats[3], 0.005, "4");

      part3.addAnswer(oListFloats[0], 0.005, "c) 1");
      part3.addAnswer(oListFloats[1], 0.005, "2");
      part3.addAnswer(oListFloats[2], 0.005, "3");
      part3.addAnswer(oListFloats[3], 0.005, "4");

      part4.addAnswer(pListFloats[0], 0.005, "d) 1");
      part4.addAnswer(pListFloats[1], 0.005, "2");
      part4.addAnswer(pListFloats[2], 0.005, "3");
      part4.addAnswer(pListFloats[3], 0.005, "4");

      question.addPart(part1);
      question.addPart(part2);
      question.addPart(part3);
      question.addPart(part4);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Skills_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }

    function sortAscending(a, b) {
      if (typeof a !== "number" && typeof b !== "number") {
        return a.toFloat() - b.toFloat();
      }
      if (typeof a !== "number") {
        return a.toFloat() - b;
      }
      if (typeof b !== "number") {
        return a - b.toFloat();
      }

      return a - b;
    }

    function sort2D(a, b) {
      return a[0].toFloat() - b[0].toFloat();
    }
    // part1

    const m1Denom = [2, 3, 4, 6, 8, 12][MCRandom.randomInt(0, 5)];
    const m1 = new MCMaths.Fraction(
      MCRandom.randomInt(1, m1Denom - 1) / m1Denom
    );

    const m2Denom = [2, 3, 4, 6, 8, 12][MCRandom.randomInt(0, 5)];
    const m2 = new MCMaths.Fraction(
      MCRandom.randomInt(1, m2Denom - 1) / m2Denom
    );

    const m3 = new MCMaths.Fraction(MCRandom.randomInt(10, 90) / 100);

    const m4 = new MCMaths.Fraction(MCRandom.randomInt(10, 90) / 100);

    const mList = [m1, m2, m3.toFloat(), m4.toFloat()].sort(sortAscending);

    const mFloats = [
      parseFloat(m1.toFloat().toFixed(3)),
      parseFloat(m2.toFloat().toFixed(3)),
      parseFloat(m3.toFloat().toFixed(3)),
      parseFloat(m4.toFloat().toFixed(3)),
    ].sort();

    const mShuffle = MCRandom.shuffle(mList);

    const mFracs = [m1, m2];

    // part 2

    const n1Denom = MCRandom.randomInt(3, 10);
    const n1 = new MCMaths.Fraction(
      MCRandom.randomInt(1, 2 * n1Denom - 1) / n1Denom
    );

    const n2Denom = MCRandom.randomInt(3, 10);
    const n2 = new MCMaths.Fraction(
      MCRandom.randomInt(1, 2 * n2Denom - 1) / n2Denom
    );

    const n3 = new MCMaths.Fraction(MCRandom.randomInt(10, 190) / 100);

    const n4 = new MCMaths.Fraction(MCRandom.randomInt(10, 190) / 100);

    const nList = [n1, n2, n3.toFloat(), n4.toFloat()].sort(sortAscending);

    const nFloats = [
      parseFloat(n1.toFloat().toFixed(3)),
      parseFloat(n2.toFloat().toFixed(3)),
      parseFloat(n3.toFloat().toFixed(3)),
      parseFloat(n4.toFloat().toFixed(3)),
    ].sort();

    const nShuffle = MCRandom.shuffle(nList);

    const nFracs = [n1, n2];

    // part 3

    const o1Denom = MCRandom.randomInt(3, 10);
    const o1 = new MCMaths.Fraction(
      MCRandom.randomInt(1, 2 * o1Denom - 1) / o1Denom
    );
    const o2Denom = MCRandom.randomInt(3, 10);
    const o2 = new MCMaths.Fraction(
      MCRandom.randomInt(1, 2 * o2Denom - 1) / o2Denom
    );

    const o3 = new MCMaths.Fraction(MCRandom.randomInt(100, 1900) / 1000);
    const o4 = new MCMaths.Fraction(MCRandom.randomInt(100, 1900) / 1000);

    const oList = [
      [o1, o1],
      [o2, o2],
      [o3, o3.toFloat()],
      [o4, o4.toFloat()],
    ];

    for (let i = 0; i < 2; i += 1) {
      if (MCRandom.randomInt(0, 2) !== 0) {
        oList[i][1] = oList[i][1].toMixed();
      }
    }

    oList.sort(sort2D);

    const oFloats = [
      parseFloat(o1.toFloat().toFixed(3)),
      parseFloat(o2.toFloat().toFixed(3)),
      parseFloat(o3.toFloat().toFixed(3)),
      parseFloat(o4.toFloat().toFixed(3)),
    ].sort();

    const oShuffle = MCRandom.shuffle(oList);

    const oShuffleFloats = [
      parseFloat(oShuffle[0][0].toFloat().toFixed(3)),
      parseFloat(oShuffle[1][0].toFloat().toFixed(3)),
      parseFloat(oShuffle[2][0].toFloat().toFixed(3)),
      parseFloat(oShuffle[3][0].toFloat().toFixed(3)),
    ];

    // part 4

    const p_offset = MCRandom.randomInt(1, 2);

    const p1Denom = MCRandom.randomInt(3, 10);
    const p1 = new MCMaths.Fraction(
      MCRandom.randomInt(1, p1Denom - 1) / p1Denom + p_offset
    );
    const p2Denom = MCRandom.randomInt(3, 10);
    const p2 = new MCMaths.Fraction(
      MCRandom.randomInt(1, p2Denom - 1) / p2Denom + p_offset
    );

    const p3 = new MCMaths.Fraction(
      MCRandom.randomInt(100, 900) / 1000 + p_offset
    );
    const p4 = new MCMaths.Fraction(
      MCRandom.randomInt(100, 900) / 1000 + p_offset
    );

    const pList = [
      [p1, p1],
      [p2, p2],
      [p3, p3.toFloat()],
      [p4, p4.toFloat()],
    ];

    for (let i = 0; i < 2; i += 1) {
      if (MCRandom.randomInt(0, 2) !== 0) {
        pList[i][1] = pList[i][1].toMixed();
      }
    }

    pList.sort(sort2D);

    const pFloats = [
      parseFloat(p1.toFloat().toFixed(3)),
      parseFloat(p2.toFloat().toFixed(3)),
      parseFloat(p3.toFloat().toFixed(3)),
      parseFloat(p4.toFloat().toFixed(3)),
    ].sort();

    const pShuffle = MCRandom.shuffle(pList);

    const pShuffleFloats = [
      parseFloat(pShuffle[0][0].toFloat().toFixed(3)),
      parseFloat(pShuffle[1][0].toFloat().toFixed(3)),
      parseFloat(pShuffle[2][0].toFloat().toFixed(3)),
      parseFloat(pShuffle[3][0].toFloat().toFixed(3)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (
      allDifferent(mFloats) &&
      allDifferent(nFloats) &&
      allDifferent(oFloats) &&
      allDifferent(pFloats)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.SkillsQuestion(4);

      question.addParagraph(
        "Place the following lists of numbers in order of size, starting with the smallest."
      );

      const part1 = new MCQuestion.SkillsPart();
      part1.addParagraph("question", `$${mShuffle}$`);
      part1.addHeading("solution", "First, convert all fractions to decimals.");
      for (let i = 0; i < 2; i += 1) {
        if (mFracs[i].toFloat() !== 1) {
          if (
            parseFloat(mFracs[i].toFloat().toFixed(3)) === mFracs[i].toFloat()
          ) {
            part1.addParagraph(
              "solution",
              `$${mFracs[i]} = ${mFracs[i].toFloat()}$`
            );
          } else {
            part1.addParagraph(
              "solution",
              `$${mFracs[i]} \\approx ${parseFloat(
                mFracs[i].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part1.addHeading("solution", `The decimal order is:`);
      part1.addParagraph("solution", `$${mFloats}$`);
      part1.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part1.addParagraph("solution", `$${mList}$`);

      const part2 = new MCQuestion.SkillsPart();
      part2.addParagraph("question", `$${nShuffle}$`);
      part2.addHeading("solution", "First, convert all fractions to decimals.");
      for (let i = 0; i < 2; i += 1) {
        if (nFracs[i].toFloat() !== 1) {
          if (
            parseFloat(nFracs[i].toFloat().toFixed(3)) === nFracs[i].toFloat()
          ) {
            part2.addParagraph(
              "solution",
              `$${nFracs[i]} = ${nFracs[i].toFloat()}$`
            );
          } else {
            part2.addParagraph(
              "solution",
              `$${nFracs[i]} \\approx ${parseFloat(
                nFracs[i].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part2.addHeading("solution", `The decimal order is:`);
      part2.addParagraph("solution", `$${nFloats}$`);
      part2.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part2.addParagraph("solution", `$${nList}$`);

      const part3 = new MCQuestion.SkillsPart();
      part3.addParagraph("question", `$${oShuffle.map((x) => x[1])}$`);
      part3.addHeading("solution", "First, convert all fractions to decimals.");
      for (let i = 0; i < 4; i += 1) {
        if (typeof oList[i][1] !== "number" && oList[i][0].toFloat() !== 1) {
          if (
            parseFloat(oList[i][0].toFloat().toFixed(3)) ===
            oList[i][0].toFloat()
          ) {
            part3.addParagraph(
              "solution",
              `$${oList[i][1]} = ${oList[i][0].toFloat()}$`
            );
          } else {
            part3.addParagraph(
              "solution",
              `$${oList[i][1]} \\approx ${parseFloat(
                oList[i][0].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part3.addHeading("solution", `The decimal order is:`);
      part3.addParagraph("solution", `$${oFloats}$`);
      part3.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part3.addParagraph("solution", `$${oList.map((x) => x[1])}$`);

      const part4 = new MCQuestion.SkillsPart();
      part4.addParagraph("question", `$${pShuffle.map((x) => x[1])}$`);
      part4.addHeading("solution", "First, convert all fractions to decimals.");
      for (let i = 0; i < 4; i += 1) {
        if (typeof pList[i][1] !== "number" && pList[i][0].toFloat() !== 1) {
          if (
            parseFloat(pList[i][0].toFloat().toFixed(3)) ===
            pList[i][0].toFloat()
          ) {
            part4.addParagraph(
              "solution",
              `$${pList[i][1]} = ${pList[i][0].toFloat()}$`
            );
          } else {
            part4.addParagraph(
              "solution",
              `$${pList[i][1]} \\approx ${parseFloat(
                pList[i][0].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part4.addHeading("solution", `The decimal order is:`);
      part4.addParagraph("solution", `$${pFloats}$`);
      part4.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part4.addParagraph("solution", `$${pList.map((x) => x[1])}$`);

      // answers
      part1.addAnswer(mFloats[0], 0.005, "a) 1");
      part1.addAnswer(mFloats[1], 0.005, "2");
      part1.addAnswer(mFloats[2], 0.005, "3");
      part1.addAnswer(mFloats[3], 0.005, "4");

      part2.addAnswer(nFloats[0], 0.005, "b) 1");
      part2.addAnswer(nFloats[1], 0.005, "2");
      part2.addAnswer(nFloats[2], 0.005, "3");
      part2.addAnswer(nFloats[3], 0.005, "4");

      part3.addAnswer(oFloats[0], 0.005, "c) 1");
      part3.addAnswer(oFloats[1], 0.005, "2");
      part3.addAnswer(oFloats[2], 0.005, "3");
      part3.addAnswer(oFloats[3], 0.005, "4");

      part4.addAnswer(pFloats[0], 0.005, "d) 1");
      part4.addAnswer(pFloats[1], 0.005, "2");
      part4.addAnswer(pFloats[2], 0.005, "3");
      part4.addAnswer(pFloats[3], 0.005, "4");

      question.addPart(part1);
      question.addPart(part2);
      question.addPart(part3);
      question.addPart(part4);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Skills_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }

    function sortAscending(a, b) {
      if (typeof a !== "number" && typeof b !== "number") {
        return a.toFloat() - b.toFloat();
      }

      return a - b;
    }
    // part1
    const m1 = MCRandom.randomInt(1, 9) * -1;
    const m2 = MCRandom.randomInt(1, 9) * -1;
    const m3 = MCRandom.randomInt(1, 9);
    const m4 = MCRandom.randomInt(1, 9);

    const mList = [m1, m2, m3, m4].sort(sortAscending);

    // part2
    const n_offset1 = MCRandom.randomInt(2, 6);

    const n1 = new MCMaths.Fraction(
      MCRandom.randomInt(100 * n_offset1 + 5, 100 * (n_offset1 + 2) - 5) / -100
    );
    const n2 = new MCMaths.Fraction(
      MCRandom.randomInt(100 * n_offset1 + 5, 100 * (n_offset1 + 2) - 5) / -100
    );
    const n3 = new MCMaths.Fraction(
      MCRandom.randomInt(100 * n_offset1 + 5, 100 * (n_offset1 + 2) - 5) / 100
    );
    const n4 = new MCMaths.Fraction(
      MCRandom.randomInt(100 * n_offset1 + 5, 100 * (n_offset1 + 2) - 5) / 100
    );

    const nList = [n1.toFloat(), n2.toFloat(), n3.toFloat(), n4.toFloat()].sort(
      sortAscending
    );

    // part3

    const o_offset1 = MCRandom.randomInt(2, 6);

    const o1 = new MCMaths.Fraction(
      MCRandom.randomInt(1000 * o_offset1 + 50, 1000 * o_offset1 + 950, 10) /
        -1000
    );
    const o2 = new MCMaths.Fraction(
      MCRandom.randomInt(1000 * o_offset1 + 50, 1000 * o_offset1 + 950, 10) /
        -1000
    );
    const o3 = new MCMaths.Fraction(
      MCRandom.randomInt(1000 * o_offset1 + 50, 1000 * o_offset1 + 950) / 1000
    );
    const o4 = new MCMaths.Fraction(
      MCRandom.randomInt(1000 * o_offset1 + 50, 1000 * o_offset1 + 950) / 1000
    );

    const oList = [o1.toFloat(), o2.toFloat(), o3.toFloat(), o4.toFloat()].sort(
      sortAscending
    );

    // part4

    const p_offset1 = MCRandom.randomInt(2, 6);
    const p_offset2 = MCRandom.randomInt(1, 9);
    const p_offset3 = MCRandom.randomInt(1, 9);

    const p1 = new MCMaths.Fraction(
      MCRandom.randomInt(
        p_offset1 * 1000 + p_offset2 * 100 + p_offset3 * 10,
        p_offset1 * 1000 + p_offset2 * 100 + p_offset3 * 10 + 9
      ) / -1000
    );
    const p2 = new MCMaths.Fraction(
      MCRandom.randomInt(
        p_offset1 * 1000 + p_offset2 * 100 + p_offset3 * 10,
        p_offset1 * 1000 + p_offset2 * 100 + p_offset3 * 10 + 9
      ) / 1000
    );
    const p3 = new MCMaths.Fraction(
      MCRandom.randomInt(
        p_offset1 * 1000 + p_offset2 * 100 + 5,
        p_offset1 * 1000 + p_offset2 * 100 + 95
      ) / -1000
    );
    const p4 = new MCMaths.Fraction(
      MCRandom.randomInt(
        p_offset1 * 1000 + p_offset2 * 100 + 5,
        p_offset1 * 1000 + p_offset2 * 100 + 95
      ) / 1000
    );

    const pList = [p1.toFloat(), p2.toFloat(), p3.toFloat(), p4.toFloat()].sort(
      sortAscending
    );

    /* END VARIABLES AND CONSTANTS */
    if (
      allDifferent(mList) &&
      allDifferent(nList) &&
      allDifferent(oList) &&
      allDifferent(pList)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.SkillsQuestion(4);

      question.addParagraph(
        "Place the following lists of decimals in ascending order."
      );

      const part1 = new MCQuestion.SkillsPart();

      part1.addParagraph("question", `$${MCRandom.shuffle(mList)}$`);
      part1.addHeading(
        "solution",
        `The correct order (smallest to largest) is:`
      );
      part1.addParagraph("solution", `$${mList}$`);
      part1.addParagraph(
        "solution",
        `$${mList[0]}<${mList[1]}$   because   $${mList[0]}-(${mList[1]})<0$`
      );
      part1.addParagraph(
        "solution",
        `$${mList[1]}<${mList[2]}$   because   $${mList[1]}-${mList[2]}<0$`
      );
      part1.addParagraph(
        "solution",
        `$${mList[2]}<${mList[3]}$   because   $${mList[2]}-${mList[3]}<0$`
      );

      const part2 = new MCQuestion.SkillsPart();

      part2.addParagraph("question", `$${MCRandom.shuffle(nList)}$`);
      part2.addHeading(
        "solution",
        `The correct order (smallest to largest) is:`
      );
      part2.addParagraph("solution", `$${nList}$`);

      part2.addParagraph(
        "solution",
        `$${nList[0]}<${nList[1]}$   because   $${nList[0]}-(${nList[1]})<0$`
      );
      part2.addParagraph(
        "solution",
        `$${nList[1]}<${nList[2]}$   because   $${nList[1]}-${nList[2]}<0$`
      );
      part2.addParagraph(
        "solution",
        `$${nList[2]}<${nList[3]}$   because   $${nList[2]}-${nList[3]}<0$`
      );

      const part3 = new MCQuestion.SkillsPart();

      part3.addParagraph("question", `$${MCRandom.shuffle(oList)}$`);
      part3.addHeading(
        "solution",
        `The correct order (smallest to largest) is:`
      );
      part3.addParagraph("solution", `$${oList}$`);

      part3.addParagraph(
        "solution",
        `$${oList[0]}<${oList[1]}$   because   $${oList[0]}-(${oList[1]})<0$`
      );
      part3.addParagraph(
        "solution",
        `$${oList[1]}<${oList[2]}$   because   $${oList[1]}-${oList[2]}<0$`
      );
      part3.addParagraph(
        "solution",
        `$${oList[2]}<${oList[3]}$   because   $${oList[2]}-${oList[3]}<0$`
      );

      const part4 = new MCQuestion.SkillsPart();

      part4.addParagraph("question", `$${MCRandom.shuffle(pList)}$`);
      part4.addHeading(
        "solution",
        `The correct order (smallest to largest) is:`
      );
      part4.addParagraph("solution", `$${pList}$`);

      part4.addParagraph(
        "solution",
        `$${pList[0]}<${pList[1]}$   because   $${pList[0]}-(${pList[1]})<0$`
      );
      part4.addParagraph(
        "solution",
        `$${pList[1]}<${pList[2]}$   because   $${pList[1]}-${pList[2]}<0$`
      );
      part4.addParagraph(
        "solution",
        `$${pList[2]}<${pList[3]}$   because   $${pList[2]}-${pList[3]}<0$`
      );

      // answers

      part1.addAnswer(mList[0], 0, "a) 1");
      part1.addAnswer(mList[1], 0, "2");
      part1.addAnswer(mList[2], 0, "3");
      part1.addAnswer(mList[3], 0, "4");

      part2.addAnswer(nList[0], 0.05, "b) 1");
      part2.addAnswer(nList[1], 0.05, "2");
      part2.addAnswer(nList[2], 0.05, "3");
      part2.addAnswer(nList[3], 0.05, "4");

      part3.addAnswer(oList[0], 0.005, "c) 1");
      part3.addAnswer(oList[1], 0.005, "2");
      part3.addAnswer(oList[2], 0.005, "3");
      part3.addAnswer(oList[3], 0.005, "4");

      part4.addAnswer(pList[0], 0.005, "d) 1");
      part4.addAnswer(pList[1], 0.005, "2");
      part4.addAnswer(pList[2], 0.005, "3");
      part4.addAnswer(pList[3], 0.005, "4");

      question.addPart(part1);
      question.addPart(part2);
      question.addPart(part3);
      question.addPart(part4);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Skills_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }

    function sortAscending(a, b) {
      if (typeof a !== "number" && typeof b !== "number") {
        return a.toFloat() - b.toFloat();
      }
      if (typeof a !== "number") {
        return a.toFloat() - b;
      }
      if (typeof b !== "number") {
        return a - b.toFloat();
      }

      return a - b;
    }

    function sort2D(a, b) {
      return a[0].toFloat() - b[0].toFloat();
    }
    // part1

    const m1Denom = [2, 3, 4, 6, 8, 12][MCRandom.randomInt(0, 5)];
    const m1 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, m1Denom - 1) / m1Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const m2Denom = [2, 3, 4, 6, 8, 12][MCRandom.randomInt(0, 5)];
    const m2 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, m2Denom - 1) / m2Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const m3Denom = [2, 3, 4, 6, 8, 12][MCRandom.randomInt(0, 5)];
    const m3 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, m3Denom - 1) / m3Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const m4Denom = [2, 3, 4, 6, 8, 12][MCRandom.randomInt(0, 5)];
    const m4 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, m4Denom - 1) / m4Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const mList = [m1, m2, m3, m4].sort(sortAscending);

    const mListFloats = [
      parseFloat(m1.toFloat().toFixed(3)),
      parseFloat(m2.toFloat().toFixed(3)),
      parseFloat(m3.toFloat().toFixed(3)),
      parseFloat(m4.toFloat().toFixed(3)),
    ].sort(sortAscending);

    const mShuffle = MCRandom.shuffle(mList);

    // part2
    const n1Denom = MCRandom.randomInt(3, 20);
    const n1 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, n1Denom - 1) / n1Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );
    const n2Denom = MCRandom.randomInt(3, 20);
    const n2 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, n2Denom - 1) / n2Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const n3Denom = MCRandom.randomInt(3, 20);
    const n3 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, n3Denom - 1) / n3Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const n4Denom = MCRandom.randomInt(3, 20);
    const n4 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, n4Denom - 1) / n4Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const nList = [n1, n2, n3, n4].sort(sortAscending);

    const nListFloats = [
      parseFloat(n1.toFloat().toFixed(3)),
      parseFloat(n2.toFloat().toFixed(3)),
      parseFloat(n3.toFloat().toFixed(3)),
      parseFloat(n4.toFloat().toFixed(3)),
    ].sort(sortAscending);

    const nShuffle = MCRandom.shuffle(nList);

    // part3

    const o1Denom = MCRandom.randomInt(3, 10);
    const o1 = new MCMaths.Fraction(
      (MCRandom.randomInt(o1Denom + 1, 2 * o1Denom - 1) / o1Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );
    const o2Denom = MCRandom.randomInt(3, 10);
    const o2 = new MCMaths.Fraction(
      (MCRandom.randomInt(o2Denom + 1, 2 * o2Denom - 1) / o2Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const o3Denom = MCRandom.randomInt(3, 10);
    const o3 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, 2 * o3Denom - 1) / o3Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const o4Denom = MCRandom.randomInt(3, 10);
    const o4 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, 2 * o4Denom - 1) / o4Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const oList = [
      [o1, o1],
      [o2, o2],
      [o3, o3],
      [o4, o4],
    ].sort(sort2D);

    for (let i = 0; i < oList.length; i += 1) {
      if (MCRandom.randomInt(0, 5) % 5 === 0) {
        oList[i][1] = oList[i][1].toMixed();
      }
    }

    const oListFloats = [
      parseFloat(o1.toFloat().toFixed(3)),
      parseFloat(o2.toFloat().toFixed(3)),
      parseFloat(o3.toFloat().toFixed(3)),
      parseFloat(o4.toFloat().toFixed(3)),
    ].sort(sortAscending);

    const oShuffle = MCRandom.shuffle(oList);

    const oShuffleFloats = [
      parseFloat(oShuffle[0][0].toFloat().toFixed(3)),
      parseFloat(oShuffle[1][0].toFloat().toFixed(3)),
      parseFloat(oShuffle[2][0].toFloat().toFixed(3)),
      parseFloat(oShuffle[3][0].toFloat().toFixed(3)),
    ];

    // part4
    const p_offset = MCRandom.randomInt(1, 2);

    const p1Denom = MCRandom.randomInt(3, 10);
    const p1 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, p1Denom - 1) / p1Denom + p_offset) *
        MCRandom.randomInt(-1, 1, 2)
    );
    const p2Denom = MCRandom.randomInt(3, 10);
    const p2 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, p2Denom - 1) / p2Denom + p_offset) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const p3Denom = MCRandom.randomInt(3, 10);
    const p3 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, p3Denom - 1) / p3Denom + p_offset) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const p4Denom = MCRandom.randomInt(3, 10);
    const p4 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, p4Denom - 1) / p4Denom + p_offset) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const pList = [
      [p1, p1],
      [p2, p2],
      [p3, p3],
      [p4, p4],
    ].sort(sort2D);

    for (let i = 0; i < pList.length; i += 1) {
      if (MCRandom.randomInt(0, 5) % 5 === 0) {
        pList[i][1] = pList[i][1].toMixed();
      }
    }

    const pListFloats = [
      parseFloat(p1.toFloat().toFixed(3)),
      parseFloat(p2.toFloat().toFixed(3)),
      parseFloat(p3.toFloat().toFixed(3)),
      parseFloat(p4.toFloat().toFixed(3)),
    ].sort(sortAscending);

    const pShuffle = MCRandom.shuffle(pList);

    const pShuffleFloats = [
      parseFloat(pShuffle[0][0].toFloat().toFixed(3)),
      parseFloat(pShuffle[1][0].toFloat().toFixed(3)),
      parseFloat(pShuffle[2][0].toFloat().toFixed(3)),
      parseFloat(pShuffle[3][0].toFloat().toFixed(3)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (
      allDifferent(mListFloats) &&
      allDifferent(nListFloats) &&
      allDifferent(oListFloats) &&
      allDifferent(pListFloats)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.SkillsQuestion(4);

      question.addParagraph(
        "Place the following lists of fractions in order of size, starting with the smallest."
      );

      const part1 = new MCQuestion.SkillsPart();

      part1.addParagraph("question", `$\\displaystyle ${mShuffle}$`);

      part1.addHeading("solution", "First, convert all numbers to decimals.");
      for (let i = 0; i < 4; i += 1) {
        if (Math.abs(mShuffle[i].toFloat()) !== 1) {
          if (
            parseFloat(mShuffle[i].toFloat().toFixed(3)) ===
            mShuffle[i].toFloat()
          ) {
            part1.addParagraph(
              "solution",
              `$${mShuffle[i]} = ${mShuffle[i].toFloat()}$`
            );
          } else {
            part1.addParagraph(
              "solution",
              `$${mShuffle[i]} \\approx ${parseFloat(
                mShuffle[i].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part1.addHeading("solution", `The decimal order is:`);
      part1.addParagraph("solution", `$${mListFloats}$`);
      part1.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part1.addParagraph("solution", `$${mList}$`);

      const part2 = new MCQuestion.SkillsPart();

      part2.addParagraph("question", `$\\displaystyle ${nShuffle}$`);

      part2.addHeading("solution", "First, convert all numbers to decimals.");
      for (let i = 0; i < 4; i += 1) {
        if (Math.abs(nShuffle[i].toFloat()) !== 1) {
          if (
            parseFloat(nShuffle[i].toFloat().toFixed(3)) ===
            nShuffle[i].toFloat()
          ) {
            part2.addParagraph(
              "solution",
              `$${nShuffle[i]} = ${nShuffle[i].toFloat()}$`
            );
          } else {
            part2.addParagraph(
              "solution",
              `$${nShuffle[i]} \\approx ${parseFloat(
                nShuffle[i].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part2.addHeading("solution", `The decimal order is:`);
      part2.addParagraph("solution", `$${nListFloats}$`);
      part2.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part2.addParagraph("solution", `$${nList}$`);

      const part3 = new MCQuestion.SkillsPart();

      part3.addParagraph(
        "question",
        `$\\displaystyle ${oShuffle.map((x) => x[1])}$`
      );
      part3.addHeading("solution", "First, convert all numbers to decimals.");
      for (let i = 0; i < 4; i += 1) {
        if (Math.abs(oShuffle[i][0].toFloat()) !== 1) {
          if (
            parseFloat(oShuffle[i][0].toFloat().toFixed(3)) ===
            oShuffle[i][0].toFloat()
          ) {
            part3.addParagraph(
              "solution",
              `$${oShuffle[i][1]} = ${oShuffle[i][0].toFloat()}$`
            );
          } else {
            part3.addParagraph(
              "solution",
              `$${oShuffle[i][1]} \\approx ${parseFloat(
                oShuffle[i][0].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part3.addHeading("solution", `The decimal order is:`);
      part3.addParagraph("solution", `$${oListFloats}$`);
      part3.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part3.addParagraph("solution", `$${oList.map((x) => x[1])}$`);

      const part4 = new MCQuestion.SkillsPart();

      part4.addParagraph(
        "question",
        `$\\displaystyle ${pShuffle.map((x) => x[1])}$`
      );

      part4.addHeading("solution", "First, convert all numbers to decimals.");
      for (let i = 0; i < 4; i += 1) {
        if (Math.abs(pShuffle[i][0].toFloat()) !== 1) {
          if (
            parseFloat(pShuffle[i][0].toFloat().toFixed(3)) ===
            pShuffle[i][0].toFloat()
          ) {
            part4.addParagraph(
              "solution",
              `$${pShuffle[i][1]} = ${pShuffle[i][0].toFloat()}$`
            );
          } else {
            part4.addParagraph(
              "solution",
              `$${pShuffle[i][1]} \\approx ${parseFloat(
                pShuffle[i][0].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part4.addHeading("solution", `The decimal order is:`);
      part4.addParagraph("solution", `$${pListFloats}$`);
      part4.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part4.addParagraph("solution", `$${pList.map((x) => x[1])}$`);

      // answers

      part1.addAnswer(mListFloats[0], 0.005, "a) 1");
      part1.addAnswer(mListFloats[1], 0.005, "2");
      part1.addAnswer(mListFloats[2], 0.005, "3");
      part1.addAnswer(mListFloats[3], 0.005, "4");

      part2.addAnswer(nListFloats[0], 0.005, "b) 1");
      part2.addAnswer(nListFloats[1], 0.005, "2");
      part2.addAnswer(nListFloats[2], 0.005, "3");
      part2.addAnswer(nListFloats[3], 0.005, "4");

      part3.addAnswer(oListFloats[0], 0.005, "c) 1");
      part3.addAnswer(oListFloats[1], 0.005, "2");
      part3.addAnswer(oListFloats[2], 0.005, "3");
      part3.addAnswer(oListFloats[3], 0.005, "4");

      part4.addAnswer(pListFloats[0], 0.005, "d) 1");
      part4.addAnswer(pListFloats[1], 0.005, "2");
      part4.addAnswer(pListFloats[2], 0.005, "3");
      part4.addAnswer(pListFloats[3], 0.005, "4");

      question.addPart(part1);
      question.addPart(part2);
      question.addPart(part3);
      question.addPart(part4);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_OrderingNumbers_Skills_Q6() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function allDifferent(arr) {
      for (let i = 0; i < arr.length; i += 1) {
        for (let j = 0; j < arr.length; j += 1) {
          if (i !== j && arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    }

    function sortAscending(a, b) {
      if (typeof a !== "number" && typeof b !== "number") {
        return a.toFloat() - b.toFloat();
      }
      if (typeof a !== "number") {
        return a.toFloat() - b;
      }
      if (typeof b !== "number") {
        return a - b.toFloat();
      }

      return a - b;
    }

    function sort2D(a, b) {
      return a[0].toFloat() - b[0].toFloat();
    }
    // part1

    const m1Denom = [2, 3, 4, 6, 8, 12][MCRandom.randomInt(0, 5)];
    const m1 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, m1Denom - 1) / m1Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const m2Denom = [2, 3, 4, 6, 8, 12][MCRandom.randomInt(0, 5)];
    const m2 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, m2Denom - 1) / m2Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const m3 = new MCMaths.Fraction(
      (MCRandom.randomInt(10, 90) / 100) * MCRandom.randomInt(-1, 1, 2)
    );

    const m4 = new MCMaths.Fraction(
      (MCRandom.randomInt(10, 90) / 100) * MCRandom.randomInt(-1, 1, 2)
    );

    const mList = [m1, m2, m3.toFloat(), m4.toFloat()].sort(sortAscending);

    const mFloats = [
      parseFloat(m1.toFloat().toFixed(3)),
      parseFloat(m2.toFloat().toFixed(3)),
      parseFloat(m3.toFloat().toFixed(3)),
      parseFloat(m4.toFloat().toFixed(3)),
    ].sort(sortAscending);

    const mShuffle = MCRandom.shuffle(mList);

    const mFracs = [m1, m2];

    // part 2

    const n1Denom = MCRandom.randomInt(3, 10);
    const n1 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, 2 * n1Denom - 1) / n1Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const n2Denom = MCRandom.randomInt(3, 10);
    const n2 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, 2 * n2Denom - 1) / n2Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const n3 = new MCMaths.Fraction(
      (MCRandom.randomInt(10, 190) / 100) * MCRandom.randomInt(-1, 1, 2)
    );

    const n4 = new MCMaths.Fraction(
      (MCRandom.randomInt(10, 190) / 100) * MCRandom.randomInt(-1, 1, 2)
    );

    const nList = [n1, n2, n3.toFloat(), n4.toFloat()].sort(sortAscending);

    const nFloats = [
      parseFloat(n1.toFloat().toFixed(3)),
      parseFloat(n2.toFloat().toFixed(3)),
      parseFloat(n3.toFloat().toFixed(3)),
      parseFloat(n4.toFloat().toFixed(3)),
    ].sort(sortAscending);

    const nShuffle = MCRandom.shuffle(nList);

    const nFracs = [n1, n2];

    // part 3

    const o1Denom = MCRandom.randomInt(3, 10);
    const o1 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, 2 * o1Denom - 1) / o1Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );
    const o2Denom = MCRandom.randomInt(3, 10);
    const o2 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, 2 * o2Denom - 1) / o2Denom) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const o3 = new MCMaths.Fraction(
      (MCRandom.randomInt(100, 1900) / 1000) * MCRandom.randomInt(-1, 1, 2)
    );
    const o4 = new MCMaths.Fraction(
      (MCRandom.randomInt(100, 1900) / 1000) * MCRandom.randomInt(-1, 1, 2)
    );

    const oList = [
      [o1, o1],
      [o2, o2],
      [o3, o3.toFloat()],
      [o4, o4.toFloat()],
    ];

    for (let i = 0; i < 2; i += 1) {
      if (MCRandom.randomInt(0, 2) !== 0) {
        oList[i][1] = oList[i][1].toMixed();
      }
    }

    oList.sort(sort2D);

    const oFloats = [
      parseFloat(o1.toFloat().toFixed(3)),
      parseFloat(o2.toFloat().toFixed(3)),
      parseFloat(o3.toFloat().toFixed(3)),
      parseFloat(o4.toFloat().toFixed(3)),
    ].sort(sortAscending);

    const oShuffle = MCRandom.shuffle(oList);

    const oShuffleFloats = [
      parseFloat(oShuffle[0][0].toFloat().toFixed(3)),
      parseFloat(oShuffle[1][0].toFloat().toFixed(3)),
      parseFloat(oShuffle[2][0].toFloat().toFixed(3)),
      parseFloat(oShuffle[3][0].toFloat().toFixed(3)),
    ];

    // part 4

    const p_offset = MCRandom.randomInt(1, 2);

    const p1Denom = MCRandom.randomInt(3, 10);
    const p1 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, p1Denom - 1) / p1Denom + p_offset) *
        MCRandom.randomInt(-1, 1, 2)
    );
    const p2Denom = MCRandom.randomInt(3, 10);
    const p2 = new MCMaths.Fraction(
      (MCRandom.randomInt(1, p2Denom - 1) / p2Denom + p_offset) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const p3 = new MCMaths.Fraction(
      (MCRandom.randomInt(100, 900) / 1000 + p_offset) *
        MCRandom.randomInt(-1, 1, 2)
    );
    const p4 = new MCMaths.Fraction(
      (MCRandom.randomInt(100, 900) / 1000 + p_offset) *
        MCRandom.randomInt(-1, 1, 2)
    );

    const pList = [
      [p1, p1],
      [p2, p2],
      [p3, p3.toFloat()],
      [p4, p4.toFloat()],
    ];

    for (let i = 0; i < 2; i += 1) {
      if (MCRandom.randomInt(0, 2) !== 0) {
        pList[i][1] = pList[i][1].toMixed();
      }
    }

    pList.sort(sort2D);

    const pFloats = [
      parseFloat(p1.toFloat().toFixed(3)),
      parseFloat(p2.toFloat().toFixed(3)),
      parseFloat(p3.toFloat().toFixed(3)),
      parseFloat(p4.toFloat().toFixed(3)),
    ].sort(sortAscending);

    const pShuffle = MCRandom.shuffle(pList);

    const pShuffleFloats = [
      parseFloat(pShuffle[0][0].toFloat().toFixed(3)),
      parseFloat(pShuffle[1][0].toFloat().toFixed(3)),
      parseFloat(pShuffle[2][0].toFloat().toFixed(3)),
      parseFloat(pShuffle[3][0].toFloat().toFixed(3)),
    ];

    /* END VARIABLES AND CONSTANTS */
    if (
      allDifferent(mFloats) &&
      allDifferent(nFloats) &&
      allDifferent(oFloats) &&
      allDifferent(pFloats)
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.SkillsQuestion(4);

      question.addParagraph(
        "Place the following lists of numbers in order of size, starting with the smallest."
      );

      const part1 = new MCQuestion.SkillsPart();
      part1.addParagraph("question", `$${mShuffle}$`);
      part1.addHeading("solution", "First, convert all fractions to decimals.");
      for (let i = 0; i < 2; i += 1) {
        if (Math.abs(mFracs[i].toFloat()) !== 1) {
          if (
            parseFloat(mFracs[i].toFloat().toFixed(3)) === mFracs[i].toFloat()
          ) {
            part1.addParagraph(
              "solution",
              `$${mFracs[i]} = ${mFracs[i].toFloat()}$`
            );
          } else {
            part1.addParagraph(
              "solution",
              `$${mFracs[i]} \\approx ${parseFloat(
                mFracs[i].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part1.addHeading("solution", `The decimal order is:`);
      part1.addParagraph("solution", `$${mFloats}$`);
      part1.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part1.addParagraph("solution", `$${mList}$`);

      const part2 = new MCQuestion.SkillsPart();
      part2.addParagraph("question", `$${nShuffle}$`);
      part2.addHeading("solution", "First, convert all fractions to decimals.");
      for (let i = 0; i < 2; i += 1) {
        if (Math.abs(nFracs[i].toFloat()) !== 1) {
          if (
            parseFloat(nFracs[i].toFloat().toFixed(3)) === nFracs[i].toFloat()
          ) {
            part2.addParagraph(
              "solution",
              `$${nFracs[i]} = ${nFracs[i].toFloat()}$`
            );
          } else {
            part2.addParagraph(
              "solution",
              `$${nFracs[i]} \\approx ${parseFloat(
                nFracs[i].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part2.addHeading("solution", `The decimal order is:`);
      part2.addParagraph("solution", `$${nFloats}$`);
      part2.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part2.addParagraph("solution", `$${nList}$`);

      const part3 = new MCQuestion.SkillsPart();
      part3.addParagraph("question", `$${oShuffle.map((x) => x[1])}$`);
      part3.addHeading("solution", "First, convert all fractions to decimals.");
      for (let i = 0; i < 4; i += 1) {
        if (
          typeof oList[i][1] !== "number" &&
          Math.abs(oList[i][0].toFloat()) !== 1
        ) {
          if (
            parseFloat(oList[i][0].toFloat().toFixed(3)) ===
            oList[i][0].toFloat()
          ) {
            part3.addParagraph(
              "solution",
              `$${oList[i][1]} = ${oList[i][0].toFloat()}$`
            );
          } else {
            part3.addParagraph(
              "solution",
              `$${oList[i][1]} \\approx ${parseFloat(
                oList[i][0].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part3.addHeading("solution", `The decimal order is:`);
      part3.addParagraph("solution", `$${oFloats}$`);
      part3.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part3.addParagraph("solution", `$${oList.map((x) => x[1])}$`);

      const part4 = new MCQuestion.SkillsPart();
      part4.addParagraph("question", `$${pShuffle.map((x) => x[1])}$`);
      part4.addHeading("solution", "First, convert all fractions to decimals.");
      for (let i = 0; i < 4; i += 1) {
        if (
          typeof pList[i][1] !== "number" &&
          Math.abs(pList[i][0].toFloat()) !== 1
        ) {
          if (
            parseFloat(pList[i][0].toFloat().toFixed(3)) ===
            pList[i][0].toFloat()
          ) {
            part4.addParagraph(
              "solution",
              `$${pList[i][1]} = ${pList[i][0].toFloat()}$`
            );
          } else {
            part4.addParagraph(
              "solution",
              `$${pList[i][1]} \\approx ${parseFloat(
                pList[i][0].toFloat().toFixed(3)
              )}$`
            );
          }
        }
      }
      part4.addHeading("solution", `The decimal order is:`);
      part4.addParagraph("solution", `$${pFloats}$`);
      part4.addHeading(
        "solution",
        "Therefore, the correct order (smallest to largest) is:"
      );
      part4.addParagraph("solution", `$${pList.map((x) => x[1])}$`);

      // answers
      part1.addAnswer(mFloats[0], 0.005, "a) 1");
      part1.addAnswer(mFloats[1], 0.005, "2");
      part1.addAnswer(mFloats[2], 0.005, "3");
      part1.addAnswer(mFloats[3], 0.005, "4");

      part2.addAnswer(nFloats[0], 0.005, "b) 1");
      part2.addAnswer(nFloats[1], 0.005, "2");
      part2.addAnswer(nFloats[2], 0.005, "3");
      part2.addAnswer(nFloats[3], 0.005, "4");

      part3.addAnswer(oFloats[0], 0.005, "c) 1");
      part3.addAnswer(oFloats[1], 0.005, "2");
      part3.addAnswer(oFloats[2], 0.005, "3");
      part3.addAnswer(oFloats[3], 0.005, "4");

      part4.addAnswer(pFloats[0], 0.005, "d) 1");
      part4.addAnswer(pFloats[1], 0.005, "2");
      part4.addAnswer(pFloats[2], 0.005, "3");
      part4.addAnswer(pFloats[3], 0.005, "4");

      question.addPart(part1);
      question.addPart(part2);
      question.addPart(part3);
      question.addPart(part4);
      /* END BUILDING QUESTION */
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


// n*n+n
function OoO1(r) {
  const num = MCRandom.randomInt(-r, r);
  const num2 = MCRandom.randomInt(-r, r);
  const num3 = MCRandom.randomInt(-r, r);
  const working1 = num * num2;

  const Value = num * num2 + num3;

  const question = `$${num} \\times${num2}+${num3}$`;
  const solution = `$${working1}+${num3} = ${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
  };
}

// n/n+n
function OoO2(r) {
  const num = MCRandom.randomInt(-r, r);
  let num2 = MCRandom.randomInt(-r, r);

  while (num2 === 0) {
    num2 = MCRandom.randomInt(-r, r);
  }

  const num3 = MCRandom.randomInt(-r, r);
  const working1 = num / num2;

  const Value = num / num2 + num3;

  const question = `$${num} \\div${num2}+${num3}$`;
  const solution = `$${working1}+${num3} = ${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
  };
}

// n+n/n
function OoO3(r) {
  const num = MCRandom.randomInt(1, r);
  let num2 = MCRandom.randomInt(-r, r);

  while (num2 === 0) {
    num2 = MCRandom.randomInt(-r, r);
  }

  const num3 = MCRandom.randomInt(-r, r);
  const working1 = num / num2;

  const Value = num / num2 + num3;

  const question = `$${num3}+${num} \\div${num2}$`;
  const solution = `$${num3}+${working1} = ${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
  };
}

// n+n*n
function OoO4(r) {
  const num = MCRandom.randomInt(1, r);
  const num2 = MCRandom.randomInt(-r, r);
  const num3 = MCRandom.randomInt(-r, r);
  const working1 = num * num2;

  const Value = num * num2 + num3;

  const question = `$${num3}+${num} \\times${num2}$`;
  const solution = `$${num3}+${working1} = ${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
  };
}

// n+n/n+n
function OoO5(r) {
  let num = MCRandom.randomInt(-r, r);
  while (num === 0) {
    num = MCRandom.randomInt(-r, r);
  }
  let num2 = MCRandom.randomInt(-r, r);
  while (num2 === 0) {
    num2 = MCRandom.randomInt(-r, r);
  }

  let num3 = MCRandom.randomInt(-r, r);
  while (num3 === 0) {
    num3 = MCRandom.randomInt(-r, r);
  }
  let num4 = MCRandom.randomInt(-r, r);
  while (num4 === 0) {
    num4 = MCRandom.randomInt(-r, r);
  }
  const working1 = num2 / num3;

  const Value = num + num2 / num3 + num4;

  const question = `$${num}+${num2} \\div${num3}+${num4}$`;
  const solution = `$${num}+${working1}+${num4}=${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
  };
}

// n/n+n/n
function OoO6(r) {
  let num = MCRandom.randomInt(-r, r);
  while (num === 0) {
    num = MCRandom.randomInt(-r, r);
  }
  let num2 = MCRandom.randomInt(-r, r);
  while (num2 === 0) {
    num2 = MCRandom.randomInt(-r, r);
  }

  let num3 = MCRandom.randomInt(-r, r);
  while (num3 === 0) {
    num3 = MCRandom.randomInt(-r, r);
  }
  let num4 = MCRandom.randomInt(-r, r);
  while (num4 === 0) {
    num4 = MCRandom.randomInt(-r, r);
  }
  const working1 = num / num2;
  let working2 = num3 / num4;

  while (working2 === 0) {
    num3 = MCRandom.randomInt(1, r);
    num4 = MCRandom.randomInt(1, r);
    working2 = num3 / num4;
  }

  const Value = num / num2 + num3 / num4;

  const question = `$${num} \\div${num2}+${num3} \\div${num4}$`;
  const solution = `$${working1} + ${working2}=${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
  };
}

// (n*n)^2
function OoO7(r) {
  let num = MCRandom.randomInt(-5, 5);
  let num2 = MCRandom.randomInt(-5, 5);
  let working1 = num * num2;

  while (Math.abs(working1) >= 12 || num === 0 || num2 === 0) {
    num = MCRandom.randomInt(-5, 5);
    num2 = MCRandom.randomInt(-5, 5);
    while (num === 0) {
      num = MCRandom.randomInt(-5, 5);
    }
    while (num2 === 0) {
      num2 = MCRandom.randomInt(-5, 5);
    }
    working1 = num * num2;
  }

  const Value = num * num2 * (num * num2);

  const question = `$(${num} \\times${num2})^2$`;
  const solution = `$${working1}^2 = ${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
  };
}

// n*n^2
function OoO8(r) {
  let num = MCRandom.randomInt(-5, 5);
  let num2 = MCRandom.randomInt(-5, 5);

  while (num === 0) {
    num = MCRandom.randomInt(-5, 5);
  }
  while (num2 === 0) {
    num2 = MCRandom.randomInt(-5, 5);
  }

  const working1 = num2 * num2;
  const Value = num * num2 * num2;

  const question = `$${num} \\times${num2}^2$`;
  const solution = `$${num} \\times${working1} = ${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
  };
}

// n(n+n)/n
function OoO9(r) {
  let num = MCRandom.randomInt(-r, r);
  while (num === 0) {
    num = MCRandom.randomInt(-r, r);
  }
  let num2 = MCRandom.randomInt(-r, r);
  while (num2 === 0) {
    num2 = MCRandom.randomInt(-r, r);
  }

  let num3 = MCRandom.randomInt(-r, r);
  while (num3 === 0) {
    num3 = MCRandom.randomInt(-r, r);
  }
  let num4 = MCRandom.randomInt(-r, r);
  while (num4 === 0) {
    num4 = MCRandom.randomInt(-r, r);
  }

  const working1 = num2 + num3;
  const working2 = num * working1;

  const Value = (num / num4) * (num2 + num3);

  const question = `$${num}(${num2}+${num3}) \\div${num4}$`;
  let solution = `\${{${num}  \\times ${working1}}\\over${num4}}$`;
  solution += `$=\\frac{${working2}}{${num4}}$`;
  solution += `$=${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
  };
}

// n+n*n/n
function OoO10(r) {
  let num = MCRandom.randomInt(-r, r);
  while (num === 0) {
    num = MCRandom.randomInt(-r, r);
  }
  let num2 = MCRandom.randomInt(-r, r);
  while (num2 === 0) {
    num2 = MCRandom.randomInt(-r, r);
  }

  let num4 = MCRandom.randomInt(-r, r);
  while (num4 === 0) {
    num4 = MCRandom.randomInt(-r, r);
  }
  let num3 = num4 * MCRandom.randomInt(-r, r);
  while (num3 === 0) {
    num3 = num4 * MCRandom.randomInt(-r, r);
  }

  const working1 = num3 / num4;
  const working2 = num2 * working1;

  const Value = num + num2 * (num3 / num4);

  const question = `$${num}+${num2} \\times${num3} \\div${num4}$`;
  let solution = `$${num}+${num2} \\times${working1}$`;
  solution += `$=${num}+${working2}$`;
  solution += `$=${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
  };
}

export function GCSE_Foundation_Number_StructureAndCalculation_OrderOfOperations_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const r = 10;

    const QList = [
      OoO1(r),
      OoO2(r),
      OoO3(r),
      OoO4(r),
      OoO5(r),
      OoO6(r),
      OoO7(r),
      OoO8(r),
      OoO9(r),
      OoO10(r),
    ];

    let qnum1 = MCRandom.randomInt(0, QList.length - 1);
    let qnum2 = MCRandom.randomInt(0, QList.length - 1);
    let qnum3 = MCRandom.randomInt(0, QList.length - 1);

    while (qnum1 === qnum2 || qnum2 === qnum3 || qnum3 === qnum1) {
      qnum1 = MCRandom.randomInt(0, QList.length - 1);
      qnum2 = MCRandom.randomInt(0, QList.length - 1);
      qnum3 = MCRandom.randomInt(0, QList.length - 1);
    }

    const num = QList[qnum1];
    const num2 = QList[qnum2];
    const num3 = QList[qnum3];

    const question = new MCQuestion.ExamQuestion();
    question.addHeading(
      "question",
      MCMaths.cleaner("Evaluate the following expressions:", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a)$   ${num.question}`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b)$   ${num2.question}`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(c)$   ${num3.question}`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a)$   ${num.solution}`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b)$   ${num2.solution}`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c)$   ${num3.solution}`, false)
    );

    const group = new MCQuestion.InputGroup(3);
    group.addInput(num.Value, 0, "$(a)$");
    group.addInput(num2.Value, 0, "$(b)$");
    group.addInput(num3.Value, 0, "$(c)$");
    question.addInputGroup(group);

    if (
      Math.round(num.Value * 100) !== num.Value * 100 ||
      Math.round(num2.Value * 100) !== num2.Value * 100 ||
      Math.round(num3.Value * 100) !== num3.Value * 100
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


// n*n+n
function OoO1b(r) {
  const num = MCRandom.randomInt(-r, r);
  const num2 = MCRandom.randomInt(-r, r);
  const num3 = MCRandom.randomInt(-r, r);
  const working1 = num * num2;

  const Value = num * num2 + num3;

  const Wrong = num * (num2 + num3);

  const question = `$${num} \\times${num2}+${num3}$`;
  const solution = `$${working1}+${num3} = ${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
    Wrong,
  };
}

// n/n+n
function OoO2b(r) {
  const num = MCRandom.randomInt(-r, r);
  let num2 = MCRandom.randomInt(-r, r);

  while (num2 === 0) {
    num2 = MCRandom.randomInt(-r, r);
  }

  const num3 = MCRandom.randomInt(-r, r);
  const working1 = num / num2;

  const Value = num / num2 + num3;
  const Wrong = num / (num2 + num3);

  const question = `$${num} \\div${num2}+${num3}$`;
  const solution = `$${working1}+${num3} = ${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
    Wrong,
  };
}

// n+n/n
function OoO3b(r) {
  const num = MCRandom.randomInt(1, r);
  let num2 = MCRandom.randomInt(-r, r);

  while (num2 === 0) {
    num2 = MCRandom.randomInt(-r, r);
  }

  const num3 = MCRandom.randomInt(-r, r);
  const working1 = num / num2;

  const Value = num / num2 + num3;
  const Wrong = num / (num2 + num3);

  const question = `$${num3}+${num} \\div${num2}$`;
  const solution = `$${num3}+${working1} = ${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
    Wrong,
  };
}

// n+n*n
function OoO4b(r) {
  const num = MCRandom.randomInt(1, r);
  const num2 = MCRandom.randomInt(-r, r);
  const num3 = MCRandom.randomInt(-r, r);
  const working1 = num * num2;

  const Value = num * num2 + num3;
  const Wrong = num * (num2 + num3);

  const question = `$${num3}+${num} \\times${num2}$`;
  const solution = `$${num3}+${working1} = ${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
    Wrong,
  };
}

// n+n/n+n
function OoO5b(r) {
  let num = MCRandom.randomInt(-r, r);
  while (num === 0) {
    num = MCRandom.randomInt(-r, r);
  }
  let num2 = MCRandom.randomInt(-r, r);
  while (num2 === 0) {
    num2 = MCRandom.randomInt(-r, r);
  }

  let num3 = MCRandom.randomInt(-r, r);
  while (num3 === 0) {
    num3 = MCRandom.randomInt(-r, r);
  }
  let num4 = MCRandom.randomInt(-r, r);
  while (num4 === 0) {
    num4 = MCRandom.randomInt(-r, r);
  }
  const working1 = num2 / num3;

  const Wrong = (num + num2) / (num3 + num4);
  const Value = num + num2 / num3 + num4;

  const question = `$${num}+${num2} \\div${num3}+${num4}$`;
  const solution = `$${num}+${working1}+${num4}=${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
    Wrong,
  };
}

// n/n+n/n
function OoO6b(r) {
  let num = MCRandom.randomInt(-r, r);
  while (num === 0) {
    num = MCRandom.randomInt(-r, r);
  }
  let num2 = MCRandom.randomInt(-r, r);
  while (num2 === 0) {
    num2 = MCRandom.randomInt(-r, r);
  }

  let num3 = MCRandom.randomInt(-r, r);
  while (num3 === 0) {
    num3 = MCRandom.randomInt(-r, r);
  }
  let num4 = MCRandom.randomInt(-r, r);
  while (num4 === 0) {
    num4 = MCRandom.randomInt(-r, r);
  }
  const working1 = num / num2;
  let working2 = num3 / num4;

  while (working2 === 0) {
    num3 = MCRandom.randomInt(1, r);
    num4 = MCRandom.randomInt(1, r);
    working2 = num3 / num4;
  }

  const Value = num / num2 + num3 / num4;
  const Wrong = num / (num2 + num3) / num4;

  const question = `$${num} \\div${num2}+${num3} \\div${num4}$`;
  const solution = `$${working1} + ${working2}=${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
    Wrong,
  };
}

// (n*n)^2
function OoO7b(r) {
  let num = MCRandom.randomInt(-5, 5);
  let num2 = MCRandom.randomInt(-5, 5);
  let working1 = num * num2;

  while (Math.abs(working1) >= 12 || num === 0 || num2 === 0) {
    num = MCRandom.randomInt(-5, 5);
    num2 = MCRandom.randomInt(-5, 5);
    while (num === 0) {
      num = MCRandom.randomInt(-5, 5);
    }
    while (num2 === 0) {
      num2 = MCRandom.randomInt(-5, 5);
    }
    working1 = num * num2;
  }

  const Value = num * num2 * (num * num2);
  const Wrong = num * num2 * num2;

  const question = `$(${num} \\times${num2})^2$`;
  const solution = `$${working1}^2 = ${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
    Wrong,
  };
}

// n*n^2
function OoO8b(r) {
  let num = MCRandom.randomInt(-5, 5);
  let num2 = MCRandom.randomInt(-5, 5);

  while (num === 0) {
    num = MCRandom.randomInt(-5, 5);
  }
  while (num2 === 0) {
    num2 = MCRandom.randomInt(-5, 5);
  }

  const working1 = num2 * num2;
  const Value = num * num2 * num2;
  const Wrong = num * num * num2 * num2;

  const question = `$${num} \\times${num2}^2$`;
  const solution = `$${num} \\times${working1} = ${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
    Wrong,
  };
}

// n(n+n)/n
function OoO9b(r) {
  let num = MCRandom.randomInt(-r, r);
  while (num === 0) {
    num = MCRandom.randomInt(-r, r);
  }
  let num2 = MCRandom.randomInt(-r, r);
  while (num2 === 0) {
    num2 = MCRandom.randomInt(-r, r);
  }

  let num3 = MCRandom.randomInt(-r, r);
  while (num3 === 0) {
    num3 = MCRandom.randomInt(-r, r);
  }
  let num4 = MCRandom.randomInt(-r, r);
  while (num4 === 0) {
    num4 = MCRandom.randomInt(-r, r);
  }

  const working1 = num2 + num3;
  const working2 = num * working1;

  const Value = (num / num4) * (num2 + num3);
  const Wrong = num / (num4 + num2 + num3);

  const question = `$${num}(${num2}+${num3})\\div${num4}$`;
  let solution = `\${{${num} \\times ${working1}}\\over${num4}}$`;
  solution += `$={${working2}\\over${num4}}$`;
  solution += `$=${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
    Wrong,
  };
}

// n+n*n/n
function OoO10b(r) {
  let num = MCRandom.randomInt(-r, r);
  while (num === 0) {
    num = MCRandom.randomInt(-r, r);
  }
  let num2 = MCRandom.randomInt(-r, r);
  while (num2 === 0) {
    num2 = MCRandom.randomInt(-r, r);
  }

  let num3 = MCRandom.randomInt(-r, r);
  while (num3 === 0) {
    num3 = MCRandom.randomInt(-r, r);
  }
  let num4 = MCRandom.randomInt(-r, r);
  while (num4 === 0) {
    num4 = MCRandom.randomInt(-r, r);
  }

  const working1 = num3 / num4;
  const working2 = num2 * working1;

  const Value = num + num2 * (num3 / num4);
  const Wrong = (num + num2) * (num3 / num4);

  const question = `$${num}+${num2} \\times${num3} \\div${num4}$`;
  let solution = `$${num}+${num2} \\times${working1}$`;
  solution += `$=${num}+${working2}$`;
  solution += `$=${Value}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
    Wrong,
  };
}

export function GCSE_Foundation_Number_StructureAndCalculation_OrderOfOperations_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = "Student 1";
    const name2 = "Student 2";

    const r = 10;

    const QList = [
      OoO1b(r),
      OoO2b(r),
      OoO3b(r),
      OoO4b(r),
      OoO5b(r),
      OoO6b(r),
      OoO7b(r),
      OoO8b(r),
      OoO9b(r),
      OoO10b(r),
    ];

    const num = QList[MCRandom.randomInt(0, QList.length - 1)];

    const question = new MCQuestion.ExamQuestion();
    const group = new MCQuestion.InputGroup(1);
    if (MCRandom.randomInt(0, 1) === 1) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A teacher asks "what is the value of ${num.question} $?$"`,
          false
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `${name} thinks that the solution to this is $${num.Value}$.`,
          false
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `${name2} thinks that the solution to this is $${num.Wrong}$.`,
          false
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner("Explain who is correct.", false)
      );

      question.addParagraph("solution", MCMaths.cleaner("", false));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`${num.solution}`, false)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`So, ${name} is correct.`, false)
      );
      group.addInput(1, 0, "Student ");
    } else {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `A teacher asks "what is the value of ${num.question}?"`,
          false
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `${name} thinks that the solution to this is $${num.Wrong}$.`,
          false
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `${name2} thinks that the solution to this is $${num.Value}$.`,
          false
        )
      );
      question.addParagraph(
        "question",
        MCMaths.cleaner("Explain who is correct.", false)
      );

      question.addParagraph("solution", MCMaths.cleaner("", false));
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`${num.solution}`, false)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`So, ${name2} is correct.`, false)
      );
      group.addInput(2, 0, "Student ");
    }

    question.addInputGroup(group);

    if (
      num.Wrong === num.Value ||
      num.Wrong >= 1000 ||
      num.Wrong <= -1000 ||
      Math.round(num.Wrong * 1000) !== num.Wrong * 1000 ||
      Math.round(num.Value * 1000) !== num.Value * 1000
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


function OoB1(r, mode) {
  const n1 = MCRandom.randomInt(2, r);
  const n2 = MCRandom.randomInt(2, r);
  const n3 = MCRandom.randomInt(2, r);
  const n4 = MCRandom.randomInt(2, r);

  const a = (n1 + n2) * (n3 + n4);
  const b = n1 + n2 * (n3 + n4);
  const c = (n1 + n2) * n3 + n4;

  let Value = 0;
  let question = "";
  let solution = "";

  if (mode === 0) {
    Value = a;
    question = `$${n1}+${n2} \\times${n3}+${n4}=${Value}$`;
    solution = `$(${n1}+${n2}) \\times (${n3}+${n4})=${Value}$`;
  }

  if (mode === 1) {
    Value = b;
    question = `$${n1}+${n2} \\times${n3}+${n4}=${Value}$`;
    solution = `$${n1}+${n2} \\times (${n3}+${n4})=${Value}$`;
  }

  if (mode === 2) {
    Value = c;
    question = `$${n1}+${n2} \\times${n3}+${n4}=${Value}$`;
    solution = `$(${n1}+${n2}) \\times ${n3}+${n4}=${Value}$`;
  }

  let test = true;
  if (a === b || b === c || c === a) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    Value,
  };
}

function OoB2(r, mode) {
  const n1 = MCRandom.randomInt(2, r);
  const n2 = MCRandom.randomInt(2, r);
  const n3 = MCRandom.randomInt(2, r);
  const n4 = MCRandom.randomInt(2, r);

  const a = (n1 + n2) / (n3 + n4);
  const b = n1 + n2 / (n3 + n4);
  const c = (n1 + n2) / n3 + n4;

  let Value = 0;
  let question = "";
  let solution = "";

  if (mode === 0) {
    Value = a;
    question = `$${n1}+${n2} \\div${n3}+${n4}=${Value}$`;
    solution = `$(${n1}+${n2}) \\div (${n3}+${n4})=${Value}$`;
  }

  if (mode === 1) {
    Value = b;
    question = `$${n1}+${n2} \\div${n3}+${n4}=${Value}$`;
    solution = `$${n1}+${n2} \\div (${n3}+${n4})=${Value}$`;
  }

  if (mode === 2) {
    Value = c;
    question = `$${n1}+${n2} \\div${n3}+${n4}=${Value}$`;
    solution = `$(${n1}+${n2}) \\div ${n3}+${n4}=${Value}$`;
  }

  let test = true;
  if (a === b || b === c || c === a) {
    test = false;
  }
  if (Math.round(Value * 100) !== Value * 100) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    Value,
  };
}

function OoB3(r, mode) {
  const n1 = MCRandom.randomInt(2, r);
  const n2 = MCRandom.randomInt(2, r);
  const n3 = MCRandom.randomInt(2, r);
  const n4 = MCRandom.randomInt(2, r);

  const a = n1 * (n2 + n3) * n4;
  const b = n1 * (n2 + n3 * n4);
  const c = (n1 * n2 + n3) * n4;

  let Value = 0;
  let question = "";
  let solution = "";

  if (mode === 0) {
    Value = a;
    question = `$${n1} \\times${n2}+${n3} \\times${n4}=${Value}$`;
    solution = `$${n1} \\times (${n2}+${n3}) \\times${n4}=${Value}$`;
  }

  if (mode === 1) {
    Value = b;
    question = `$${n1} \\times${n2}+${n3} \\times${n4}=${Value}$`;
    solution = `$${n1} \\times (${n2}+${n3} \\times${n4})=${Value}$`;
  }

  if (mode === 2) {
    Value = c;
    question = `$${n1} \\times${n2}+${n3} \\times${n4}=${Value}$`;
    solution = `$(${n1} \\times${n2}+${n3}) \\times${n4}=${Value}$`;
  }

  let test = true;
  if (a === b || b === c || c === a) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    Value,
  };
}

function OoB4(r, mode) {
  const n1 = MCRandom.randomInt(2, r);
  const n2 = MCRandom.randomInt(2, r);
  const n3 = MCRandom.randomInt(2, r);
  const n4 = MCRandom.randomInt(2, r);

  const n5 = MCRandom.randomInt(2, r);

  const a = n1 * (n2 + n5 + n3) * n4;
  const b = n1 * (n2 + n5 + n3 * n4);
  const c = (n1 * n2 + n5 + n3) * n4;

  let Value = 0;
  let question = "";
  let solution = "";

  if (mode === 0) {
    Value = a;
    question = `$${n1} \\times${n2}+${n5}+${n3} \\times${n4}=${Value}$`;
    solution = `$${n1} \\times (${n2}+${n5}+${n3}) \\times${n4}=${Value}$`;
  }

  if (mode === 1) {
    Value = b;
    question = `$${n1} \\times${n2}+${n5}+${n3} \\times${n4}=${Value}$`;
    solution = `$${n1} \\times (${n2}+${n5}+${n3} \\times${n4})=${Value}$`;
  }

  if (mode === 2) {
    Value = c;
    question = `$${n1} \\times${n2}+${n5}+${n3} \\times${n4}=${Value}$`;
    solution = `$(${n1} \\times${n2}+${n5}+${n3}) \\times${n4}=${Value}$`;
  }

  let test = true;
  if (a === b || b === c || c === a) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    Value,
  };
}

function OoB5(r, mode) {
  const n1 = MCRandom.randomInt(2, r);
  const n2 = MCRandom.randomInt(2, r);
  const n3 = MCRandom.randomInt(2, r);
  const n4 = MCRandom.randomInt(2, r);

  const n5 = MCRandom.randomInt(2, r);

  const a = n1 + (n2 * (n3 + n4)) / n5;
  const b = n1 + n2 * (n3 + n4 / n5);
  const c = (n1 + n2 * n3 + n4) / n5;

  let Value = 0;
  let question = "";
  let solution = "";

  if (mode === 0) {
    Value = a;
    question = `$${n1}+${n2} \\times${n3}+${n4} \\div${n5}=${Value}$`;
    solution = `$${n1}+${n2} \\times(${n3}+${n4}) \\div${n5}=${Value}$`;
  }

  if (mode === 1) {
    Value = b;
    question = `$${n1}+${n2} \\times${n3}+${n4} \\div${n5}=${Value}$`;
    solution = `$${n1}+${n2} \\times(${n3}+${n4} \\div${n5})=${Value}$`;
  }

  if (mode === 2) {
    Value = c;
    question = `$${n1}+${n2} \\times${n3}+${n4} \\div${n5}=${Value}$`;
    solution = `$(${n1}+${n2} \\times${n3}+${n4}) \\div${n5}=${Value}$`;
  }

  let test = true;
  if (a === b || b === c || c === a) {
    test = false;
  }
  if (Math.round(Value * 100) !== Value * 100) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    Value,
  };
}

function OoB1b(r, mode) {
  const n1 = MCRandom.randomInt(2, r);
  const n2 = MCRandom.randomInt(2, r);
  const n3 = MCRandom.randomInt(2, r);
  const n4 = MCRandom.randomInt(2, r);

  const a = (n1 + n2) * (n3 + n4);
  const b = n1 + n2 * (n3 + n4);
  const c = (n1 + n2) * n3 + n4;

  let Value = 0;
  let question = "";
  let solution = "";

  if (mode === 0) {
    Value = a;
    question = `$${n1}+${n2} \\times${n3}+${n4}=${Value}$`;
    solution = `$(${n1}+${n2}) \\times (${n3}+${n4})=${Value}$`;
  }

  if (mode === 1) {
    Value = b;
    question = `$${n1}+${n2} \\times${n3}+${n4}=${Value}$`;
    solution = `$${n1}+${n2} \\times (${n3}+${n4})=${Value}$`;
  }

  if (mode === 2) {
    Value = c;
    question = `$${n1}+${n2} \\times${n3}+${n4}=${Value}$`;
    solution = `$(${n1}+${n2}) \\times ${n3}+${n4}=${Value}$`;
  }

  let test = true;
  if (a === b || b === c || c === a) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    Value,
  };
}

function OoB2b(r, mode) {
  const n1 = MCRandom.randomInt(2, r);
  const n2 = MCRandom.randomInt(2, r);
  const n3 = MCRandom.randomInt(2, r);
  const n4 = MCRandom.randomInt(2, r);

  const a = (n1 + n2) / (n3 + n4);
  const b = n1 + n2 / (n3 + n4);
  const c = (n1 + n2) / n3 + n4;

  let Value = 0;
  let question = "";
  let solution = "";

  if (mode === 0) {
    Value = a;
    question = `$${n1}+${n2} \\div${n3}+${n4}=${Value}$`;
    solution = `$(${n1}+${n2}) \\div (${n3}+${n4})=${Value}$`;
  }

  if (mode === 1) {
    Value = b;
    question = `$${n1}+${n2} \\div${n3}+${n4}=${Value}$`;
    solution = `$${n1}+${n2} \\div (${n3}+${n4})=${Value}$`;
  }

  if (mode === 2) {
    Value = c;
    question = `$${n1}+${n2} \\div${n3}+${n4}=${Value}$`;
    solution = `$(${n1}+${n2}) \\div ${n3}+${n4}=${Value}$`;
  }

  let test = true;
  if (a === b || b === c || c === a) {
    test = false;
  }
  if (Math.round(Value * 100) !== Value * 100) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    Value,
  };
}

function OoB3b(r, mode) {
  const n1 = MCRandom.randomInt(2, r);
  const n2 = MCRandom.randomInt(2, r);
  const n3 = MCRandom.randomInt(2, r);
  const n4 = MCRandom.randomInt(2, r);

  const a = n1 * (n2 + n3) * n4;
  const b = n1 * (n2 + n3 * n4);
  const c = (n1 * n2 + n3) * n4;

  let Value = 0;
  let question = "";
  let solution = "";

  if (mode === 0) {
    Value = a;
    question = `$${n1} \\times${n2}+${n3} \\times${n4}=${Value}$`;
    solution = `$${n1} \\times (${n2}+${n3}) \\times${n4}=${Value}$`;
  }

  if (mode === 1) {
    Value = b;
    question = `$${n1} \\times${n2}+${n3} \\times${n4}=${Value}$`;
    solution = `$${n1} \\times (${n2}+${n3} \\times${n4})=${Value}$`;
  }

  if (mode === 2) {
    Value = c;
    question = `$${n1} \\times${n2}+${n3} \\times${n4}=${Value}$`;
    solution = `$(${n1} \\times${n2}+${n3}) \\times${n4}=${Value}$`;
  }

  let test = true;
  if (a === b || b === c || c === a) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    Value,
  };
}

function OoB4b(r, mode) {
  const n1 = MCRandom.randomInt(2, r);
  const n2 = MCRandom.randomInt(2, r);
  const n3 = MCRandom.randomInt(2, r);
  const n4 = MCRandom.randomInt(2, r);

  const n5 = MCRandom.randomInt(2, r);

  const a = n1 * (n2 + n5 + n3) * n4;
  const b = n1 * (n2 + n5 + n3 * n4);
  const c = (n1 * n2 + n5 + n3) * n4;

  let Value = 0;
  let question = "";
  let solution = "";

  if (mode === 0) {
    Value = a;
    question = `$${n1} \\times${n2}+${n5}+${n3} \\times${n4}=${Value}$`;
    solution = `$${n1} \\times (${n2}+${n5}+${n3}) \\times${n4}=${Value}$`;
  }

  if (mode === 1) {
    Value = b;
    question = `$${n1} \\times${n2}+${n5}+${n3} \\times${n4}=${Value}$`;
    solution = `$${n1} \\times (${n2}+${n5}+${n3} \\times${n4})=${Value}$`;
  }

  if (mode === 2) {
    Value = c;
    question = `$${n1} \\times${n2}+${n5}+${n3} \\times${n4}=${Value}$`;
    solution = `$(${n1} \\times${n2}+${n5}+${n3}) \\times${n4}=${Value}$`;
  }

  let test = true;
  if (a === b || b === c || c === a) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    Value,
  };
}

function OoB5b(r, mode) {
  const n1 = MCRandom.randomInt(2, r);
  const n2 = MCRandom.randomInt(2, r);
  const n3 = MCRandom.randomInt(2, r);
  const n4 = MCRandom.randomInt(2, r);

  const n5 = MCRandom.randomInt(2, r);

  const a = n1 + (n2 * (n3 + n4)) / n5;
  const b = n1 + n2 * (n3 + n4 / n5);
  const c = (n1 + n2 * n3 + n4) / n5;

  let Value = 0;
  let question = "";
  let solution = "";

  if (mode === 0) {
    Value = a;
    question = `$${n1}+${n2} \\times${n3}+${n4} \\div${n5}=${Value}$`;
    solution = `$${n1}+${n2} \\times(${n3}+${n4}) \\div${n5}=${Value}$`;
  }

  if (mode === 1) {
    Value = b;
    question = `$${n1}+${n2} \\times${n3}+${n4} \\div${n5}=${Value}$`;
    solution = `$${n1}+${n2} \\times(${n3}+${n4} \\div${n5})=${Value}$`;
  }

  if (mode === 2) {
    Value = c;
    question = `$${n1}+${n2} \\times${n3}+${n4} \\div${n5}=${Value}$`;
    solution = `$(${n1}+${n2} \\times${n3}+${n4}) \\div${n5}=${Value}$`;
  }

  let test = true;
  if (a === b || b === c || c === a) {
    test = false;
  }
  if (Math.round(Value * 100) !== Value * 100) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    Value,
  };
}

function OoB1c(r, mode) {
  const n1 = MCRandom.randomInt(2, r);
  const n2 = MCRandom.randomInt(2, r);
  const n3 = MCRandom.randomInt(2, r);
  const n4 = MCRandom.randomInt(2, r);

  const a = (n1 + n2) * (n3 + n4);
  const b = n1 + n2 * (n3 + n4);
  const c = (n1 + n2) * n3 + n4;

  let Value = 0;
  let question = "";
  let solution = "";

  if (mode === 0) {
    Value = a;
    question = `$${n1}+${n2} \\times${n3}+${n4}=${Value}$`;
    solution = `$(${n1}+${n2}) \\times (${n3}+${n4})=${Value}$`;
  }

  if (mode === 1) {
    Value = b;
    question = `$${n1}+${n2} \\times${n3}+${n4}=${Value}$`;
    solution = `$${n1}+${n2} \\times (${n3}+${n4})=${Value}$`;
  }

  if (mode === 2) {
    Value = c;
    question = `$${n1}+${n2} \\times${n3}+${n4}=${Value}$`;
    solution = `$(${n1}+${n2}) \\times ${n3}+${n4}=${Value}$`;
  }

  let test = true;
  if (a === b || b === c || c === a) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    Value,
  };
}

function OoB2c(r, mode) {
  const n1 = MCRandom.randomInt(2, r);
  const n2 = MCRandom.randomInt(2, r);
  const n3 = MCRandom.randomInt(2, r);
  const n4 = MCRandom.randomInt(2, r);

  const a = (n1 + n2) / (n3 + n4);
  const b = n1 + n2 / (n3 + n4);
  const c = (n1 + n2) / n3 + n4;

  let Value = 0;
  let question = "";
  let solution = "";

  if (mode === 0) {
    Value = a;
    question = `$${n1}+${n2} \\div${n3}+${n4}=${Value}$`;
    solution = `$(${n1}+${n2}) \\div (${n3}+${n4})=${Value}$`;
  }

  if (mode === 1) {
    Value = b;
    question = `$${n1}+${n2} \\div${n3}+${n4}=${Value}$`;
    solution = `$${n1}+${n2} \\div (${n3}+${n4})=${Value}$`;
  }

  if (mode === 2) {
    Value = c;
    question = `$${n1}+${n2} \\div${n3}+${n4}=${Value}$`;
    solution = `$(${n1}+${n2}) \\div ${n3}+${n4}=${Value}$`;
  }

  let test = true;
  if (a === b || b === c || c === a) {
    test = false;
  }
  if (Math.round(Value * 100) !== Value * 100) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    Value,
  };
}

function OoB3c(r, mode) {
  const n1 = MCRandom.randomInt(2, r);
  const n2 = MCRandom.randomInt(2, r);
  const n3 = MCRandom.randomInt(2, r);
  const n4 = MCRandom.randomInt(2, r);

  const a = n1 * (n2 + n3) * n4;
  const b = n1 * (n2 + n3 * n4);
  const c = (n1 * n2 + n3) * n4;

  let Value = 0;
  let question = "";
  let solution = "";

  if (mode === 0) {
    Value = a;
    question = `$${n1} \\times${n2}+${n3} \\times${n4}=${Value}$`;
    solution = `$${n1} \\times (${n2}+${n3}) \\times${n4}=${Value}$`;
  }

  if (mode === 1) {
    Value = b;
    question = `$${n1} \\times${n2}+${n3} \\times${n4}=${Value}$`;
    solution = `$${n1} \\times (${n2}+${n3} \\times${n4})=${Value}$`;
  }

  if (mode === 2) {
    Value = c;
    question = `$${n1} \\times${n2}+${n3} \\times${n4}=${Value}$`;
    solution = `$(${n1} \\times${n2}+${n3}) \\times${n4}=${Value}$`;
  }

  let test = true;
  if (a === b || b === c || c === a) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    Value,
  };
}

function OoB4c(r, mode) {
  const n1 = MCRandom.randomInt(2, r);
  const n2 = MCRandom.randomInt(2, r);
  const n3 = MCRandom.randomInt(2, r);
  const n4 = MCRandom.randomInt(2, r);

  const n5 = MCRandom.randomInt(2, r);

  const a = n1 * (n2 + n5 + n3) * n4;
  const b = n1 * (n2 + n5 + n3 * n4);
  const c = (n1 * n2 + n5 + n3) * n4;

  let Value = 0;
  let question = "";
  let solution = "";

  if (mode === 0) {
    Value = a;
    question = `$${n1} \\times${n2}+${n5}+${n3} \\times${n4}=${Value}$`;
    solution = `$${n1} \\times (${n2}+${n5}+${n3}) \\times${n4}=${Value}$`;
  }

  if (mode === 1) {
    Value = b;
    question = `$${n1} \\times${n2}+${n5}+${n3} \\times${n4}=${Value}$`;
    solution = `$${n1} \\times (${n2}+${n5}+${n3} \\times${n4})=${Value}$`;
  }

  if (mode === 2) {
    Value = c;
    question = `$${n1} \\times${n2}+${n5}+${n3} \\times${n4}=${Value}$`;
    solution = `$(${n1} \\times${n2}+${n5}+${n3}) \\times${n4}=${Value}$`;
  }

  let test = true;
  if (a === b || b === c || c === a) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    Value,
  };
}

function OoB5c(r, mode) {
  const n1 = MCRandom.randomInt(2, r);
  const n2 = MCRandom.randomInt(2, r);
  const n3 = MCRandom.randomInt(2, r);
  const n4 = MCRandom.randomInt(2, r);

  const n5 = MCRandom.randomInt(2, r);

  const a = n1 + (n2 * (n3 + n4)) / n5;
  const b = n1 + n2 * (n3 + n4 / n5);
  const c = (n1 + n2 * n3 + n4) / n5;

  let Value = 0;
  let question = "";
  let solution = "";

  if (mode === 0) {
    Value = a;
    question = `$${n1}+${n2} \\times${n3}+${n4} \\div${n5}=${Value}$`;
    solution = `$${n1}+${n2} \\times(${n3}+${n4}) \\div${n5}=${Value}$`;
  }

  if (mode === 1) {
    Value = b;
    question = `$${n1}+${n2} \\times${n3}+${n4} \\div${n5}=${Value}$`;
    solution = `$${n1}+${n2} \\times(${n3}+${n4} \\div${n5})=${Value}$`;
  }

  if (mode === 2) {
    Value = c;
    question = `$${n1}+${n2} \\times${n3}+${n4} \\div${n5}=${Value}$`;
    solution = `$(${n1}+${n2} \\times${n3}+${n4}) \\div${n5}=${Value}$`;
  }

  let test = true;
  if (a === b || b === c || c === a) {
    test = false;
  }
  if (Math.round(Value * 100) !== Value * 100) {
    test = false;
  }

  return {
    question,
    solution,
    test,
    Value,
  };
}

export function GCSE_Foundation_Number_StructureAndCalculation_OrderOfOperations_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const mode1 = MCRandom.randomInt(0, 2);
    const mode2 = MCRandom.randomInt(0, 2);
    const mode3 = MCRandom.randomInt(0, 2);
    const r1 = 10;
    const r2 = 10;
    const r3 = 10;

    const QList = [
      OoB1(r1, mode1),
      OoB2(r1, mode1),
      OoB3(r1, mode1),
      OoB4(r1, mode1),
      OoB5(r1, mode1),
    ];
    const QListb = [
      OoB1b(r2, mode2),
      OoB2b(r2, mode2),
      OoB3b(r2, mode2),
      OoB4b(r2, mode2),
      OoB5b(r2, mode2),
    ];
    const QListc = [
      OoB1c(r3, mode3),
      OoB2c(r3, mode3),
      OoB3c(r3, mode3),
      OoB4c(r3, mode3),
      OoB5c(r3, mode3),
    ];

    const qNum1 = MCRandom.randomInt(0, QList.length - 1);
    const qNum2 = MCRandom.randomInt(0, QListb.length - 1);
    const qNum3 = MCRandom.randomInt(0, QListc.length - 1);

    const q1 = QList[qNum1];
    const q2 = QList[qNum2];
    const q3 = QList[qNum3];

    const question = new MCQuestion.ExamQuestion();
    question.addHeading(
      "question",
      MCMaths.cleaner("Add brackets to make the following correct:", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a)$  ${q1.question}`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b)$  ${q2.question}`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(c)$  ${q3.question}`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a)$  ${q1.solution}`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b)$  ${q2.solution}`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c)$  ${q3.solution}`, false)
    );
    question.requiresSelfMarking();

    if (
      q1.test === false ||
      q2.test === false ||
      q3.test === false ||
      qNum1 === qNum2 ||
      qNum1 === qNum3 ||
      q1.Value === q2.Value ||
      q2.Value === q3.Value ||
      q1.Value === q3.Value
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_Powers_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // ---------------------------------------------use Math.pow() not ** ---------------------
    const num1 = MCRandom.randomInt(2, 15);
    const pow1 = MCRandom.randomInt(2, 5);
    const ans1 = num1 ** pow1;

    const num2 = MCRandom.randomInt(2, 15);
    const pow2 = MCRandom.randomInt(2, 5);
    const ans2 = num2 ** pow2;

    const num3 = MCRandom.randomInt(5, 15);
    const num3s = num3 * num3;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("Find: ", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a) \\ ${num1}^${pow1}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b) \\ ${num2}^${pow2}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(c) \\ \\sqrt{${num3s}}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${ans1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${ans2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ ${num3}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans1, 0, "$(a)$");
    group.addInput(ans2, 0, "$(b)$");
    group.addInput(num3, 0, "$(c)$");
    question.addInputGroup(group);

    if (ans1 >= 1200 || ans2 >= 1200 || ans1 === ans2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_Powers_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // ---------------------------------------------use Math.pow() not ** ---------------------
    const num1 = MCRandom.randomInt(2, 15);
    const pow1 = MCRandom.randomInt(2, 3);
    let name1;
    if (pow1 === 2) {
      name1 = "squared";
    }
    if (pow1 === 3) {
      name1 = "cubed";
    }
    const ans1 = num1 ** pow1;

    const num2 = MCRandom.randomInt(2, 3);
    const pow2 = MCRandom.randomInt(5, 9);
    const ans2 = num2 ** pow2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a)$ What is $${num1}$ ${name1}?`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b)$ Work out $${num2}^${pow2}$`, false, true, true)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${ans1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${ans2}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ans1, 0, "$(a)$");
    group.addInput(ans2, 0, "$(b)$");
    question.addInputGroup(group);

    if (ans1 === ans2 || ans1 >= 1200 || ans2 >= 1000) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_Powers_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // ---------------------------------------------use Math.pow() not ** ---------------------
    const base = MCRandom.randomInt(2, 9);

    const pow1 = MCRandom.randomInt(2, 3);
    let name1;
    if (pow1 === 2) {
      name1 = "squared";
    }
    if (pow1 === 3) {
      name1 = "cubed";
    }
    const ans1 = base ** pow1;

    const pow2 = MCRandom.randomInt(4, 9);
    const ans2 = base ** pow2;

    const pow3 = MCRandom.randomInt(4, 7);

    let name3 = `${base}`;
    for (let j = 0; j <= pow3 - 2; j += 1) {
      name3 += ` \\times${base}`;
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a)$ What is $${base}$ ${name1}`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b)$ Find $${base}^${pow2}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ Write $${name3}$ as a power of $${base}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${ans1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${ans2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ ${base}^${pow3}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ans1, 0, "$(a)$");
    group.addInput(ans2, 0, "$(b)$");
    question.addInputGroup(group);

    question.requiresSelfMarking();

    if (ans2 >= 800 || pow2 === pow3) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_Powers_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // ---------------------------------------------use Math.pow() not ** ---------------------
    const base1 = MCRandom.randomInt(2, 15);
    const base2 = MCRandom.randomInt(2, 15);
    const base3 = MCRandom.randomInt(2, 15);

    const pow1 = MCRandom.randomInt(2, 8);
    const pow2 = MCRandom.randomInt(2, 8);
    const pow3 = MCRandom.randomInt(2, 8);

    const ans1a = base1 ** pow1;
    const ans2a = base2 ** pow2;
    const ans3a = base3 ** pow3;

    const multi1 = MCRandom.randomInt(1, 9);
    const multi2 = MCRandom.randomInt(1, 9);
    const multi3 = MCRandom.randomInt(1, 9);

    const ans1b = ans1a * multi1;
    const ans2b = ans2a * multi2;
    const ans3b = ans3a * multi3;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("Solve: ", false));
    if (multi1 !== 1) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$(a) \\ x=${multi1} \\times{${base1}^${pow1}}$`,
          false,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$(a) \\ x={${base1}^${pow1}}$`, false, true, true)
      );
    }
    if (multi2 !== 1) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$(b) \\ y=${multi2} \\times{${base2}^${pow2}}$`,
          false,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$(b) \\ y={${base2}^${pow2}}$`, false, true, true)
      );
    }
    if (multi3 !== 1) {
      question.addParagraph(
        "question",
        MCMaths.cleaner(
          `$(c) \\ z=${multi3} \\times{${base3}^${pow3}}$`,
          false,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "question",
        MCMaths.cleaner(`$(c) \\ z={${base3}^${pow3}}$`, false, true, true)
      );
    }
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    if (multi1 !== 1) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(a) \\ x=${multi1} \\times${ans1a}=${ans1b}$`,
          false,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(a) \\ x=${ans1b}$`, false, true, true)
      );
    }
    if (multi2 !== 1) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(b) \\ y=${multi2} \\times${ans2a}=${ans2b}$`,
          false,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(b) \\ y=${ans2b}$`, false, true, true)
      );
    }
    if (multi3 !== 1) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(c) \\ z=${multi3} \\times${ans3a}=${ans3b}$`,
          false,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(c) \\ z=${ans3b}$`, false, true, true)
      );
    }

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans1b, 0, "$(a)$");
    group.addInput(ans2b, 0, "$(b)$");
    group.addInput(ans3b, 0, "$(c)$");
    question.addInputGroup(group);

    if (
      Math.min(multi1, multi2, multi3) !== 1 ||
      ans1a >= 1700 ||
      ans2a >= 1700 ||
      ans3a >= 1700 ||
      ans1a === ans2a ||
      ans2a === ans3a ||
      ans1a === ans3a
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_Powers_Exam_Q5() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // ---------------------------------------------use Math.pow() not ** ---------------------
    const size1 = MCRandom.randomInt(3, 15);
    const ans1 = size1 ** 2;

    const size2 = MCRandom.randomInt(3, 15);
    const ans2 = size2 ** 2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`A square field has sides measuring $${size1}km$.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(a)$ What is the area of the field?", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Another square field has sides measuring $${size2}km$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ What is the area of this field?",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ {${size1}^2}=${ans1}km^2$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ {${size2}^2}=${ans2}km^2$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ans1, 0, "$(a)$");
    group.addInput(ans2, 0, "$(b)$");
    question.addInputGroup(group);

    if (size1 === size2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_Powers_Exam_Q6() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // ---------------------------------------------use Math.pow() not ** ---------------------
    const size = MCRandom.randomInt(20, 90, 10);
    const sizeS = size ** 2;
    const sizeC = size ** 3;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A square of cardboard has sides measuring $${size}cm$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ What is the area of the cardboard ?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Six identical squares with the same dimensions are made into a box.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ What is the volume of this box?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ {${size}^2}=${sizeS}cm^2$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ {${size}^3}=${sizeC}cm^3$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(sizeS, 0, "$(a)$");
    group.addInput(sizeC, 0, "$(b)$");
    question.addInputGroup(group);

    if (1 === 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_Powers_Exam_Q7() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // ---------------------------------------------use Math.pow() not ** ---------------------
    const num = MCRandom.randomInt(2, 7);
    const numS = num ** 2;
    const numC = num ** 3;

    const itemList = ["cookies", "biscuits"];
    const itemListCap = ["Cookies", "Biscuits"];
    const itemNum = MCRandom.randomInt(0, itemList.length - 1);
    const item = itemList[itemNum];
    const itemCap = itemListCap[itemNum];

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${itemCap} are packaged into packs of $${num}$.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ How many ${item} are in $${num}$ packs of ${item}?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Packs of ${item} are packed into boxes of $${num}$ packs.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b)$ How many ${item} are in a box?`, false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a) \\ {${num}^2}=${numS}$ ${item} in a pack.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ {${num}^3}=${numC}$ ${item} in a box.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(numS, 0, "$(a)$");
    group.addInput(numC, 0, "$(b)$");
    question.addInputGroup(group);

    if (1 === 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_Powers_Exam_Q8() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // ---------------------------------------------use Math.pow() not ** ---------------------
    const num = MCRandom.randomInt(5, 9);
    const numS = num ** 2;
    const numC = num ** 3;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`A trampoline park has $${num}$ trampolines.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Each trampoline can fit $${num}$ people on it.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ What is the maximun number of people that can use trampolines at once?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The park is part of a chain, and there are $${num - 1}$ other sites.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ What is the combined capacity of all of the sites?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ {${num}^2}=${numS}$ people.`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ {${num}^3}=${numC}$ people.`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(numS, 0, "$(a)$");
    group.addInput(numC, 0, "$(b)$");
    question.addInputGroup(group);

    if (1 === 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Number_StructureAndCalculation_Powers_Exam_Q9() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // ---------------------------------------------use Math.pow() not ** ---------------------
    const pow1 = MCRandom.randomInt(2, 10);
    const pow2 = MCRandom.randomInt(2, 10);
    const pow3 = MCRandom.randomInt(2, 10);
    const pow4 = MCRandom.randomInt(2, 10);
    const pow5 = MCRandom.randomInt(2, 10);
    const pow6 = MCRandom.randomInt(2, 10);

    const num1 = 2 ** pow1;
    const num2 = 2 ** pow2;
    const num3 = 2 ** pow3;
    const num4 = 2 ** pow4;
    const num5 = 2 ** pow5;
    const num6 = 2 ** pow6;

    const ans1 = num1 + num2;
    const ans2 = num3 + num4;
    const ans3 = num5 + num6;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("Work out:", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a) \\ {2^{${pow1}}}+{2^{${pow2}}}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b) \\ {2^{${pow3}}}+{2^{${pow4}}}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(c) \\ {2^{${pow5}}}+{2^{${pow6}}}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${num1}+${num2}=${ans1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${num3}+${num4}=${ans2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${num5}+${num6}=${ans3}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(3);
    group.addInput(ans1, 0, "$(a)$");
    group.addInput(ans2, 0, "$(b)$");
    group.addInput(ans3, 0, "$(c)$");
    question.addInputGroup(group);

    if (ans1 === ans2 || ans2 === ans3 || ans3 === ans1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


function Q1StandardFormRoundingError(x) {
  return Math.round(100000000 * x) / 100000000;
}

function Q1StandardForm(n2) {
  let n = n2;
  // -=-=-=-=-JPicko Edit-=-=-=-=-=-=-
  if (n === 0) {
    return {
      power: 0,
      n: 0,
      Display: "0 \\times 10^0",
    };
  }
  // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

  let power = 0;
  let negative;
  if (n < 0) {
    negative = 1;
    n = -n;
  } else {
    negative = 0;
  }

  if (n < 1) {
    while (n < 1) {
      n *= 10;
      power -= 1;
    }
    if (negative === 1) {
      n = -n;
    }
    return {
      n,
      power,
      Display: `${Q1StandardFormRoundingError(n)} \\times 10^{${power}}`,
    };
  }
  while (n > 10) {
    n /= 10;
    power += 1;
  }
  if (negative === 1) {
    n = -n;
  }
  return {
    n,
    power,
    Display: `${Q1StandardFormRoundingError(n)} \\times 10^{${power}}`,
  };
}

function Q1Stnd1() {
  const num = MCRandom.randomInt(10, 99) / 10;
  const num2 = MCRandom.randomInt(-4, 6);

  const Value = num * 10 ** num2;

  const question = `Write $${
    Q1StandardForm(Value).Display
  }$ as a normal number.`;
  const solution = `$${Q1StandardForm(Value).Display} = ${String(Value)}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
  };
}

function Q1Stnd2() {
  const num = MCRandom.randomInt(10, 99) / 10;
  const num2 = MCRandom.randomInt(-4, 6);

  const Value = num * 10 ** num2;

  const question = `Write $${String(
    Q1StandardFormRoundingError(Value)
  )}$ in standard form.`;
  const solution = `$${String(Value)} = ${Q1StandardForm(Value).Display}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
  };
}

function Q1Stnd3() {
  const num = MCRandom.randomInt(10, 99) / 10;
  const num2 = MCRandom.randomInt(-4, 6);

  const Value = num * 10 ** num2;

  const question = `Write $${
    Q1StandardForm(Value).Display
  }$ as a normal number.`;
  const solution = `$${Q1StandardForm(Value).Display} = ${String(Value)}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
  };
}

function Q1Stnd4() {
  const num = MCRandom.randomInt(10, 99) / 10;
  const num2 = MCRandom.randomInt(-4, 6);

  const Value = num * 10 ** num2;

  const question = `Write $${String(
    Q1StandardFormRoundingError(Value)
  )}$ in standard form.`;
  const solution = `$${String(Value)} = ${Q1StandardForm(Value).Display}$`;
  const test = true;

  return {
    question,
    solution,
    test,
    Value,
  };
}

function Q1StndS(val1, val2, mode) {
  let ans;
  let question;
  let solution;
  if (mode === 0) {
    ans = val1 + val2;
  }
  if (mode === 1) {
    ans = val1 * val2;
  }
  if (mode === 2) {
    ans = val1 / val2;
  }

  if (mode === 0) {
    question = "Add your answers to part $(a)$ and $(b)$.";
  }
  if (mode === 1) {
    question = "Multiply your answers to part $(a)$ and $(b)$.";
  }
  if (mode === 2) {
    question = "Divide part $(a)$ by part $(b)$.";
  }

  if (mode === 0) {
    solution = `$(${Q1StandardForm(val1).Display})+(${
      Q1StandardForm(val2).Display
    })=${Q1StandardForm(ans).Display}$`;
  }
  if (mode === 1) {
    solution = `$(${Q1StandardForm(val1).Display}) \\times (${
      Q1StandardForm(val2).Display
    })=${Q1StandardForm(ans).Display}$`;
  }
  if (mode === 2) {
    solution = `$(${Q1StandardForm(val1).Display}) \\div (${
      Q1StandardForm(val2).Display
    })=${Q1StandardForm(ans).Display}$`;
  }

  return {
    ans,
    question,
    solution,
  };
}

export function GCSE_Foundation_Number_StructureAndCalculation_StandardForm_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const QList = [Q1Stnd1(), Q1Stnd2(), Q1Stnd3(), Q1Stnd4()];

    const num = QList[MCRandom.randomInt(0, 1)];
    const num2 = QList[MCRandom.randomInt(2, 3)];
    const num3 = Q1StndS(num.Value, num2.Value, MCRandom.randomInt(0, 2));

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a) \\ $ ${num.question}`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b) \\ $ ${num2.question}`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(c) \\ $ ${num3.question}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ $ ${num.solution}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ $ ${num2.solution}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ $ ${num3.solution}`, false, true, true)
    );

    question.requiresSelfMarking();
    if (1 === 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\ (USE ALT (92))
// || (USE ALT (124))
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)


export function GCSE_Foundation_Number_StructureAndCalculation_StandardForm_Exam_Q2() {
  function Q2RoundingError(x) {
    return Math.round(100000000 * x) / 100000000;
  }

  function StandardForm(n2) {
    let n = n2;
    // -=-=-=-=-JPicko Edit-=-=-=-=-=-=-
    if (n === 0) {
      return {
        power: 0,
        n: 0,
        Display: "0 \\times 10^0",
      };
    }
    // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

    let power = 0;
    let negative;
    if (n < 0) {
      negative = 1;
      n = -n;
    } else {
      negative = 0;
    }

    if (n < 1) {
      while (n < 1) {
        n *= 10;
        power -= 1;
      }
      if (negative === 1) {
        n = -n;
      }
      return {
        n,
        power,
        Display: `${Q2RoundingError(n)} \\times 10^{${power}}`,
      };
    }
    while (n > 10) {
      n /= 10;
      power += 1;
    }
    if (negative === 1) {
      n = -n;
    }
    return {
      n,
      power,
      Display: `${Q2RoundingError(n)} \\times 10^{${power}}`,
    };
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const num1 = MCRandom.randomInt(10, 99) / 10;
    const num2 = MCRandom.randomInt(-6, 6);
    const x = num1 * 10 ** num2;

    const num3 = MCRandom.randomInt(10, 99) / 10;
    const num4 = MCRandom.randomInt(-6, 6);
    const y = num3 * 10 ** num4;

    const num5 = MCRandom.randomInt(10, 99) / 10;
    const num6 = MCRandom.randomInt(-6, 6);
    const z = num5 * 10 ** num6;

    const mode = MCRandom.randomInt(0, 2);
    // mode 0 = x+y+z
    // mode 1 = (x+y)*z
    // mode 2 = (x*y)/z
    let ans;
    let working;
    if (mode === 0) {
      ans = x + y + z;
    }
    if (mode === 1) {
      ans = (x + y) * z;
    }
    if (mode === 2) {
      ans = (x * y) / z;
    }

    if (mode === 1) {
      working = x + y;
    }
    if (mode === 2) {
      working = x * y;
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$x=${StandardForm(x).Display}$`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$y=${StandardForm(y).Display}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$z=${StandardForm(z).Display}$`, false, true, true)
    );
    if (mode === 0) {
      question.addParagraph(
        "question",
        MCMaths.cleaner("Find $x+y+z$.", false, true, true)
      );
    }
    if (mode === 1) {
      question.addParagraph(
        "question",
        MCMaths.cleaner("Find $(x+y) \\times z$.", false, true, true)
      );
    }
    if (mode === 2) {
      question.addParagraph(
        "question",
        MCMaths.cleaner("Find $ { x \\times y}\\over z$.", false, true, true)
      );
    }
    question.addParagraph(
      "question",
      MCMaths.cleaner("Give your answer in standard form.", false, true, true)
    );

    if (mode === 0) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${StandardForm(ans).Display}$`, false, true, true)
      );
    }
    if (mode === 1) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$={${StandardForm(working).Display}\\over${
            StandardForm(z).Display
          }}$`,
          false,
          true,
          true
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${StandardForm(ans).Display}$`, false, true, true)
      );
    }
    if (mode === 2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$={${StandardForm(working).Display}\\over${
            StandardForm(z).Display
          }}$`,
          false,
          true,
          true
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$=${StandardForm(ans).Display}$`, false, true, true)
      );
    }

    question.requiresSelfMarking();

    if (
      Math.round(StandardForm(ans).n * 1000) !== 1000 * StandardForm(ans).n ||
      ans === 0 ||
      StandardForm(ans).power === 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_StandardForm_Exam_Q3() {
  function Q2RoundingError(x) {
    return Math.round(100000000 * x) / 100000000;
  }

  function StandardForm(n2) {
    let n = n2;
    // -=-=-=-=-JPicko Edit-=-=-=-=-=-=-
    if (n === 0) {
      return {
        power: 0,
        n: 0,
        Display: "0 \\times 10^0",
      };
    }
    // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

    let power = 0;
    let negative;
    if (n < 0) {
      negative = 1;
      n = -n;
    } else {
      negative = 0;
    }

    if (n < 1) {
      while (n < 1) {
        n *= 10;
        power -= 1;
      }
      if (negative === 1) {
        n = -n;
      }
      return {
        n,
        power,
        Display: `${Q2RoundingError(n)} \\times 10^{${power}}`,
      };
    }
    while (n > 10) {
      n /= 10;
      power += 1;
    }
    if (negative === 1) {
      n = -n;
    }
    return {
      n,
      power,
      Display: `${Q2RoundingError(n)} \\times 10^{${power}}`,
    };
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const num = MCRandom.randomInt(1, 10);
    const Planets = [
      "Jupiter",
      "Saturn",
      "Uranus",
      "Neptune",
      "Earth",
      "Venus",
      "Mars",
      "Mercury",
    ];
    const PlanetDistance = [
      780000000,
      1400000000,
      2900000000,
      4500000000,
      150000000,
      110000000,
      230000000,
      58000000,
    ];

    const type1 = MCRandom.randomInt(0, Planets.length - 1);
    let type2 = MCRandom.randomInt(0, Planets.length - 1);
    while (type1 === type2) {
      type2 = MCRandom.randomInt(0, Planets.length - 1);
    }

    let ans;
    if (PlanetDistance[type1] >= PlanetDistance[type2]) {
      ans = PlanetDistance[type1] / PlanetDistance[type2];
    } else {
      ans = PlanetDistance[type2] / PlanetDistance[type1];
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${Planets[type1]} is $≈${
          StandardForm(PlanetDistance[type1]).Display
        }km$ from the sun.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${Planets[type2]} is $≈${
          StandardForm(PlanetDistance[type2]).Display
        }km$ from the sun.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Which planet is further from the sun?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ How many times further away is this planet than the other one?",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));

    if (PlanetDistance[type1] >= PlanetDistance[type2]) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(a)$ ${Planets[type1]} is further away.`,
          false,
          true,
          true
        )
      );
    }
    if (PlanetDistance[type1] <= PlanetDistance[type2]) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(a)$ ${Planets[type2]} is further away.`,
          false,
          true,
          true
        )
      );
    }

    if (PlanetDistance[type1] >= PlanetDistance[type2]) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(b)$ $${StandardForm(PlanetDistance[type1]).Display} \\div${
            StandardForm(PlanetDistance[type2]).Display
          }=${ans}$ times further away.`,
          false,
          true,
          true
        )
      );
    }

    if (PlanetDistance[type1] <= PlanetDistance[type2]) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(b)$ $${StandardForm(PlanetDistance[type2]).Display} \\div${
            StandardForm(PlanetDistance[type1]).Display
          }=${ans}$ times further away.`,
          false,
          true,
          true
        )
      );
    }
    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.5, "$(b)$");
    question.addInputGroup(group);

    if (1 === 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\ (USE ALT (92))
// || (USE ALT (124))
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)


export function GCSE_Foundation_Number_StructureAndCalculation_StandardForm_Exam_Q4() {
  function Q2RoundingError(x) {
    return Math.round(100000000 * x) / 100000000;
  }

  function StandardForm(n2) {
    let n = n2;
    // -=-=-=-=-JPicko Edit-=-=-=-=-=-=-
    if (n === 0) {
      return {
        power: 0,
        n: 0,
        Display: "0 \\times 10^0",
      };
    }
    // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

    let power = 0;
    let negative;
    if (n < 0) {
      negative = 1;
      n = -n;
    } else {
      negative = 0;
    }

    if (n < 1) {
      while (n < 1) {
        n *= 10;
        power -= 1;
      }
      if (negative === 1) {
        n = -n;
      }
      return {
        n,
        power,
        Display: `${Q2RoundingError(n)} \\times 10^{${power}}`,
      };
    }
    while (n > 10) {
      n /= 10;
      power += 1;
    }
    if (negative === 1) {
      n = -n;
    }
    return {
      n,
      power,
      Display: `${Q2RoundingError(n)} \\times 10^{${power}}`,
    };
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const usb = MCRandom.randomInt(100, 800);
    const file = MCRandom.randomInt(100, 500);
    const hard = MCRandom.randomInt(128, 512, 64);

    const working1 = (usb * 1000) / file;
    const ans1 = Math.floor(working1);
    const working2 = 1000 * hard;
    const ans2 = Math.round((1000 * hard) / usb);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "There are $1000$ kilobytes in a megabyte, and $1000$ megabytes in a gigabyte.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A flash drive has a capacity of $${usb}mb$, and a file is $${file}kb$ big.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ How many copyies of the file can fit on the memory sitck?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A hard-drive has a capacity of $${hard} gb$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ How many times bigger is the hard-drive than the flash drive? Give your answer in standard form.",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ $(${usb} \\times 1000 )  \\div${file}=${working1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`This rounds down to $${ans1}$ files.`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ The hard drive is $1000  \\times ${hard}=${working2}mb$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${working2} \\div ${usb}≈${ans2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$=${StandardForm(ans2).Display}$`, false, true, true)
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans1, 0.1, "$(a)$");
    question.addInputGroup(group);

    if (working2 / usb === ans2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\ (USE ALT (92))
// || (USE ALT (124))
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)


export function GCSE_Foundation_Number_StructureAndCalculation_SystematicListing_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const ans = 1;

    const question = new MCQuestion.ExamQuestion();

    const name = MCMisc.getName();

    const foods = ["crisps", "cola", "sweets", "chocolate", "gum"];
    const letters = ["c", "c", "s", "c", "g"];

    const type1 = 4; // MCRandom.randomInt(0,foods.length-1)
    const type2 = MCRandom.randomInt(0, foods.length - 1);
    const type3 = MCRandom.randomInt(0, foods.length - 1);

    const f1 = foods[type1];
    const f2 = foods[type2];
    const f3 = foods[type3];
    const l1 = letters[type1];
    const l2 = letters[type2];
    const l3 = letters[type3];

    question.addParagraph(
      "question",
      `A vending machine sells ${f1} ($${l1}$), ${f2} ($${l2}$) and ${f3} ($${l3}$).`
    );
    question.addParagraph(
      "question",
      `${name.name} has enough money for two items.`
    );
    question.addParagraph(
      "question",
      `$(a)$ List all the cominations of items ${name.heshe} can buy.`
    );
    question.addParagraph(
      "question",
      `$(b)$ What is the probability ${name.name} buys two packs of ${f1}?`
    );
    question.addParagraph(
      "solution",
      `$(a) \\ ${l1}$ and $${l2}$, $${l1}$ and $${l3}$, $${l2}$ and $${l3}$, $${l1}$ and $${l1}$, $${l2}$ and $${l2}$, $${l3}$ and $${l3}$.`
    );
    question.addParagraph("solution", `$(b) \\ \\frac{1}{9}$ `);

    question.requiresSelfMarking();

    const group = new MCQuestion.InputGroup(1);
    group.addInput(1 / 9, 0.01, "$(b)$");
    question.addInputGroup(group);

    if (l1 === l2 || l2 === l3 || l3 === l1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_SystematicListing_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const amounts = ["＄$\\hspace{-0.22em}1.20$", "¢$80$", "¢$60$", "¢$15$"];
    const anss = [1 / 6, 1 / 3, 1 / 3, 2 / 3];

    const type = MCRandom.randomInt(0, anss.length - 1);

    const ans = 1;

    const name = MCMisc.getName();

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `In America, there are ¢$1$, ¢$5$, ¢$10$, ¢$25$, ¢$50$ and ＄$\\hspace{-0.22em}1$ coins.`
    );
    question.addParagraph(
      "question",
      `${name.name} has two of the same coins. What is the probability ${name.name} has over ${amounts[type]}?`
    );
    question.addParagraph(
      "solution",
      `Listing the values: ¢$2$, ¢$10$, ¢$20$, ¢$50$, ＄$\\hspace{-0.22em}1$ and ＄$\\hspace{-0.22em}2$.`
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`So, the probability is $${anss[type]}$.`)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(anss[type], 0.01);
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_SystematicListing_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const num =
      2 ** MCRandom.randomInt(0, 4) *
      3 ** MCRandom.randomInt(0, 3) *
      5 ** MCRandom.randomInt(0, 3) *
      7 ** MCRandom.randomInt(0, 2);
    const less = Math.floor((MCRandom.randomInt(10, 60) * num) / 100);
    let ans = 0;

    const factors = [];
    for (let j = 2; j < num; j += 1) {
      if (num % j === 0) {
        factors.push(j);
        if (j < less) {
          ans += 1;
        }
      }
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `How many factors of $${num}$ are less than $${less}$?`
    );
    question.addParagraph("solution", `Listing factors: $${factors}$`);
    question.addParagraph(
      "solution",
      `Hence there are $${ans}$ factors under $${less}$.`
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.01);
    question.addInputGroup(group);

    if (factors.length > 10 || factors.length < 5 || ans < 2 || ans > 6) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_SystematicListing_Exam_Q4() {
  function shuffle(array2) {
    const array = array2;
    let currentIndex = array.length;
    let randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    let odds = [
      1 + MCRandom.randomInt(0, 4) * 2,
      1 + MCRandom.randomInt(0, 4) * 2,
      1 + MCRandom.randomInt(0, 4) * 2,
      1 + MCRandom.randomInt(0, 4) * 2,
    ];
    let evens = [
      MCRandom.randomInt(1, 4) * 2,
      MCRandom.randomInt(1, 4) * 2,
      MCRandom.randomInt(1, 4) * 2,
      MCRandom.randomInt(1, 4) * 2,
    ];

    odds = odds.splice(0, MCRandom.randomInt(1, 3)).sort();
    evens = evens.splice(0, MCRandom.randomInt(1, 3)).sort();

    const numbers = shuffle(odds.concat(evens));
    const ans1 = parseInt(
      (
        [...odds.concat([...evens].splice(0, evens.length - 1))]
          .sort()
          .toString() + evens[evens.length - 1]
      ).replace(/,/g, ""),
      10
    );
    const ans2 = parseInt(
      (
        [...evens.concat(odds.splice(1, odds.length))]
          .sort()
          .reverse()
          .toString() + odds[0]
      ).replace(/,/g, ""),
      10
    );

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `Given the digits $${numbers},$`.replace(/,(?=\d,\$)/, "$ and $")
    );
    question.addParagraph(
      "question",
      `$(a)$ Find the smallest even number you can make,`
    );
    question.addParagraph(
      "question",
      `$(b)$ Find the largest odd number you can make.`
    );
    question.addParagraph("solution", `$(a) \\ ${ans1}$`);
    question.addParagraph("solution", `$(b) \\ ${ans2}$`);

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans1, 0.00001, "$(a)$");
    group.addInput(ans2, 0.00001, "$(b)$");
    question.addInputGroup(group);

    if (numbers.length < 4 || numbers.length > 6) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Number_StructureAndCalculation_SystematicListing_Exam_Q5() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const ans = 1;

    const coins = [
      "1p",
      "2p",
      "5p",
      "10p",
      "20p",
      "50p",
      "\\pounds 1",
      "\\pounds 2",
    ];
    const vals = [1, 2, 5, 10, 20, 50, 100, 200];
    const name = MCMisc.getName();

    const c1 = MCRandom.randomInt(0, coins.length - 1);
    const c2 = MCRandom.randomInt(0, coins.length - 1);

    const num = MCRandom.randomInt(4, 7);

    const totals = [];
    for (let j = 0; j <= num; j += 1) {
      totals.push(DisplayMoney(vals[c1] * j + vals[c2] * (num - j)));
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `${name.name} has a jar of $${coins[c1]}$ and $${coins[c2]}$ coins.`
    );
    question.addParagraph(
      "question",
      `If ${name.heshe} picks $${num}$ coins at random, how many possible amounts of money could ${name.heshe} have?`
    );
    question.addParagraph("solution", `Possible totals = $${totals}$`);
    question.addParagraph("solution", `= $${totals.length}$ possibilities.`);

    const group = new MCQuestion.InputGroup(1);
    group.addInput(totals.length, 0.0000000001);
    question.addInputGroup(group);

    if (c1 === c2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_BasicProbability_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const colours = [
      "red",
      "green",
      "blue",
      "yellow",
      "purple",
      "grey",
      "pink",
    ];
    const colour1 = colours[MCRandom.randomInt(0, 6)];
    const colour2 = colours[MCRandom.randomInt(0, 6)];
    const beads1 = MCRandom.randomInt(30, 90);
    const beads2 = MCRandom.randomInt(30, 90);
    const probability1 = MCRandom.randomInt(1, 56) / 60;
    const probability2 = MCRandom.randomInt(1, 56) / 60;
    const numberOfSmall = probability1 * beads1 + probability2 * beads2;
    const totalBeads = beads1 + beads2;
    const largeBeads = totalBeads - numberOfSmall;
    const probabilityLarge = largeBeads / totalBeads;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `There are $${beads1}$ ${colour1} beads and $${beads2}$ ${colour2} beads in a bag.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Beads may either be small or large.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The probability that a ${colour1} bead is small is $${probability1}$.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The probability that a ${colour2} bead is small is $${probability2}$.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "(a) Find the total number of small beads.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "(b) Find the probability that a bead chosen at random is large.",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ Number of small beads = ${colour1} small beads $+$ ${colour2} small beads`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Number of small beads = $${probability1}  \\times ${beads1} + ${probability2}  \\times ${beads2}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Number of small beads = $${numberOfSmall}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ Total beads = ${colour1} beads $+$ ${colour2} beads $= ${totalBeads}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Number of large beads = total beads $-$ small beads = $${largeBeads}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Probability of large = $${probabilityLarge}$`,
        true,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(numberOfSmall, 0, "$(a)$");
    group.addInput(probabilityLarge, 0.05, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      probability1 >= 1 ||
      probability2 >= 1 ||
      probability1 === null ||
      probability2 === null ||
      colour1 === colour2 ||
      probability1 === probability2 ||
      numberOfSmall !== Math.round(numberOfSmall)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_BasicProbability_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const men = MCRandom.randomInt(300, 450);
    const women = MCRandom.randomInt(400, 550);
    const numerators = [1, 3, 5, 7, 9];
    const numerator1 = numerators[MCRandom.randomInt(0, 4)];
    const numerator2 = numerators[MCRandom.randomInt(0, 4)];
    const denominator1 = MCRandom.randomInt(2, 9);
    const denominator2 = MCRandom.randomInt(2, 9);
    const manWearsJeansProbabilityDec = numerator1 / denominator1;
    const womanWearsJeansProbabilityDec = numerator2 / denominator2;
    const manWearsJeansProbability = manWearsJeansProbabilityDec;
    const womanWearsJeansProbability = womanWearsJeansProbabilityDec;
    const menWearingJeans = manWearsJeansProbabilityDec * men;
    const womenWearingJeans = womanWearsJeansProbabilityDec * women;
    const numberWearingJeans = menWearingJeans + womenWearingJeans;

    const totalPeople = men + women;
    const notWearingJeans = totalPeople - numberWearingJeans;
    const simpleAns = notWearingJeans / totalPeople;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `There are $${men}$ men and $${women}$ women in a night club.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The probability that a man chosen at random is wearing jeans is $${manWearsJeansProbability}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The probability that a woman chosen at random is wearing jeans is $${womanWearsJeansProbability}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Work out the number of people in the club who are wearing jeans.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Work out the probability that one person chosen at random from the club is not wearing jeans.",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ Number of men wearing jeans $=$ number of men $ \\times$ probability of a man wearing jeans = $${men}  \\times ${manWearsJeansProbability} = ${menWearingJeans}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Number of women wearing jeans $=$ number of women $ \\times$ probability of a woman wearing jeans = $${women}  \\times ${womanWearsJeansProbability} = ${womenWearingJeans}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ Total people wearing jeans $= ${womenWearingJeans} + ${menWearingJeans} = ${numberWearingJeans}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ Total people $=$ total men + total women $= ${men} + ${women} = ${totalPeople}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Number of people that aren't wearing jeans $=$ total people - those that are $= ${totalPeople} - ${numberWearingJeans} = ${notWearingJeans}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ Probability of one person not wearing jeans $=$ number of people not wearing jeans $ \\div$ total people $= {${notWearingJeans} \\over ${totalPeople}} = ${simpleAns}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(numberWearingJeans, 0, "$(a)$");
    group.addInput(simpleAns, 0.05, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      manWearsJeansProbability === null ||
      womanWearsJeansProbability === null ||
      men === women ||
      numerator1 === numerator2 ||
      manWearsJeansProbabilityDec >= 1 ||
      womanWearsJeansProbabilityDec >= 1 ||
      manWearsJeansProbabilityDec <= womanWearsJeansProbabilityDec ||
      menWearingJeans !== Math.round(menWearingJeans) ||
      womenWearingJeans !== Math.round(womenWearingJeans) ||
      denominator1 === denominator2 ||
      notWearingJeans === simpleAns.numerator
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_BasicProbability_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const a = MCRandom.randomInt(2, 50);
    const b = MCRandom.randomInt(1, 50);
    const c = MCRandom.randomInt(1, 50);
    const total = a + b + c;
    const itemlist = ["sweet", "pen", "flower"];
    const itemslist = ["sweets", "pens", "flowers"];
    const itemType = MCRandom.randomInt(0, itemlist.length - 1);
    const singleItem = itemlist[itemType];
    const pluralItem = itemslist[itemType];
    let itema;
    let itemb;
    let itemc;
    if (a === 1) {
      itema = singleItem;
    } else {
      itema = pluralItem;
    }
    if (b === 1) {
      itemb = singleItem;
    } else {
      itemb = pluralItem;
    }
    if (c === 1) {
      itemc = singleItem;
    } else {
      itemc = pluralItem;
    }
    const proba = (100 * a) / total;
    const probb = (100 * b) / total;
    const probc = (100 * c) / total;
    const type1 = MCRandom.randomInt(0, 2);
    const type2 = MCRandom.randomInt(0, 3);

    const group = new MCQuestion.InputGroup(2);

    const question = new MCQuestion.ExamQuestion();
    switch (type1) {
      case 0:
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `In a box there are $${a}$ red ${itema}, $${b}$ blue ${itemb} and $${c}$ green ${itemc}.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `A ${singleItem} is taken from the box.`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `$(a)$ What probabilty does the ${singleItem} have of being red? Write your answer as a percentage.`,
            false,
            true,
            true
          )
        );

        question.addParagraph(
          "solution",
          MCMaths.cleaner("", false, true, true)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$(a)$ Total number of ${pluralItem} $=${a}+${b}+${c}=${total}$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `Probability the ${singleItem} is red $=$ number of red ${singleItem} $ \\div$ the total number of items $={${a} \\over ${total}}$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `Percentage $= 100  \\times $probability $=${proba}\\%$`,
            false,
            true,
            true
          )
        );
        group.addInput(proba, 0.05, "$(a)$");
        break;
      case 1:
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `In a box there are $${a}$ red ${itema}, $${b}$ blue ${itemb} and $${c}$ green ${itemc}.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `A ${singleItem} is taken from the box.`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `$(a)$ What probabilty does the ${singleItem} have of being blue? Write your answer as a percentage.`,
            false,
            true,
            true
          )
        );

        question.addParagraph(
          "solution",
          MCMaths.cleaner("", false, true, true)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$(a)$ Total number of ${pluralItem} $=${a}+${b}+${c}=${total}$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `Probability the ${singleItem} is blue $=$ number of blue ${singleItem} $ \\div$ the total number of items $={${b} \\over ${total}}$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `Percentage $= 100  \\times $probability $=${probb}\\%$`,
            false,
            true,
            true
          )
        );
        group.addInput(probb, 0.05, "$(a)$");

        break;
      case 2:
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `In a box there are $${a}$ red ${itema}, $${b}$ blue ${itemb} and $${c}$ green ${itemc}.`,
            false
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `A ${singleItem} is taken from the box.`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `$(a)$ What probabilty does the ${singleItem} have of being green? Write your answer as a percentage.`,
            false,
            true,
            true
          )
        );

        question.addParagraph(
          "solution",
          MCMaths.cleaner("", false, true, true)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$(a)$ Total number of ${pluralItem} $=${a}+${b}+${c}=${total}$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `Probability the ${singleItem} is green $=$ number of green ${singleItem} $ \\div$ the total number of items $={${c} \\over ${total}}$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `Percentage $= 100  \\times $probability $=${probc}\\%$`,
            false,
            true,
            true
          )
        );
        group.addInput(probc, 0.05, "$(a)$");

        break;
      default:
    }

    switch (type2) {
      case 0:
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `The first ${singleItem} is returned to the box. Another ${singleItem} is taken.`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `$(b)$ What probabilty does the ${singleItem} have of being red ? Write your answer as a percentage.`,
            false,
            true,
            true
          )
        );

        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$(b)$ Total number of ${pluralItem} $=${a}+${b}+${c}=${total}$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `Probability the ${singleItem} is red $=$ number of red ${singleItem} $ \\div$ the total number of items $={${a} \\over ${total}}$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `Percentage $= 100  \\times $probability $=${proba}\\%$`,
            false,
            true,
            true
          )
        );
        group.addInput(proba, 0.05, "$(b)$");

        break;
      case 1:
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `The first ${singleItem} is returned to the box. Another ${singleItem} is taken.`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `$(b)$ What probabilty does the ${singleItem} have of being blue? Write your answer as a percentage.`,
            false,
            true,
            true
          )
        );

        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$(b)$ Total number of ${pluralItem} $=${a}+${b}+${c}=${total}$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `Probability the ${singleItem} is blue $=$ number of blue ${singleItem} $ \\div$ the total number of items $={${b} \\over ${total}}$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `Percentage $= 100  \\times $probability $=${probb}\\%$`,
            false,
            true,
            true
          )
        );
        group.addInput(probb, 0.05, "$(b)$");

        break;
      case 2:
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `The first ${singleItem} is returned to the box. Another ${singleItem} is taken.`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `$(b)$ What probabilty does the ${singleItem} have of being green? Write your answer as a percentage.`,
            false,
            true,
            true
          )
        );

        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$(b)$ Total number of ${pluralItem} $=${a}+${b}+${c}=${total}$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `Probability the ${singleItem} is green $=$ number of green ${singleItem} $ \\div$ the total number of items $={${c} \\over ${total}}$`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `Percentage $= 100  \\times $probability $=${probc}\\%$`,
            false,
            true,
            true
          )
        );
        group.addInput(probc, 0.05, "$(b)$");

        break;
      case 3:
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `The first ${singleItem} is returned to the box. Another ${singleItem} is taken.`,
            false,
            true,
            true
          )
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            `$(b)$ What probabilty does the ${singleItem} have of being yellow?`,
            false,
            true,
            true
          )
        );

        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            `$(b) \\ 0$, as there are no yellow ${pluralItem}.`,
            false,
            true,
            true
          )
        );
        group.addInput(0, 0, "$(b)$");

        break;
      default:
    }

    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      a + b + c < 5 ||
      a + b + c > 70 ||
      a === b ||
      b === c ||
      c === a ||
      proba * 2 !== Math.round(proba * 2) ||
      probb * 2 !== Math.round(probb * 2) ||
      probc * 2 !== Math.round(probc * 2) ||
      type1 === type2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_BasicProbability_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const men = MCRandom.randomInt(300, 450);
    const women = MCRandom.randomInt(400, 550);
    const numerators = [1, 3, 5, 7, 9];
    const numerator1 = numerators[MCRandom.randomInt(0, 4)];
    const numerator2 = numerators[MCRandom.randomInt(0, 4)];
    const denominator1 = MCRandom.randomInt(2, 9);
    const denominator2 = MCRandom.randomInt(2, 9);
    const manWearsJeansProbabilityDec = numerator1 / denominator1;
    const womanWearsJeansProbabilityDec = numerator2 / denominator2;
    const manWearsJeansProbability = manWearsJeansProbabilityDec;
    const womanWearsJeansProbability = womanWearsJeansProbabilityDec;
    const menWearingJeans = manWearsJeansProbabilityDec * men;
    const womenWearingJeans = womanWearsJeansProbabilityDec * women;
    const numberWearingJeans = menWearingJeans + womenWearingJeans;

    const totalPeople = men + women;
    const notWearingJeans = totalPeople - numberWearingJeans;
    const simpleAns = notWearingJeans / totalPeople;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `There are $${men}$ men and $${women}$ women in a night club.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The probability that a man chosen at random is wearing jeans is $${manWearsJeansProbability}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The probability that a woman chosen at random is wearing jeans is $${womanWearsJeansProbability}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Work out the number of people in the club who are wearing jeans.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Work out the probability that one person chosen at random from the club is not wearing jeans.",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ Number of men wearing jeans $=$ number of men $ \\times$ probability of a man wearing jeans = $${men}  \\times ${manWearsJeansProbability} = ${menWearingJeans}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Number of women wearing jeans $=$ number of women $ \\times$ probability of a woman wearing jeans = $${women}  \\times ${womanWearsJeansProbability} = ${womenWearingJeans}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ Total people wearing jeans $= ${womenWearingJeans} + ${menWearingJeans} = ${numberWearingJeans}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ Total people $=$ total men + total women $= ${men} + ${women} = ${totalPeople}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Number of people that aren't wearing jeans $=$ total people - those that are $= ${totalPeople} - ${numberWearingJeans} = ${notWearingJeans}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ Probability of one person not wearing jeans $=$ number of people not wearing jeans $ \\div$ total people $= {${notWearingJeans} \\over ${totalPeople}} = ${simpleAns}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(numberWearingJeans, 0, "$(a)$");
    group.addInput(simpleAns, 0.01, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      manWearsJeansProbability === null ||
      womanWearsJeansProbability === null ||
      men === women ||
      numerator1 === numerator2 ||
      manWearsJeansProbabilityDec >= 1 ||
      womanWearsJeansProbabilityDec >= 1 ||
      manWearsJeansProbabilityDec <= womanWearsJeansProbabilityDec ||
      menWearingJeans !== Math.round(menWearingJeans) ||
      womenWearingJeans !== Math.round(womenWearingJeans) ||
      denominator1 === denominator2 ||
      notWearingJeans === simpleAns.numerator
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ExpectedFrequencies_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = MCRandom.randomInt(40, 200, 20);

    const heads = n / 2 - MCRandom.randomInt(n / 20, n / 10);
    const tails = n - heads;

    const focus = ["heads", "tails"][MCRandom.randomInt(0, 1)];

    const person = MCMisc.getName();
    const m = MCRandom.randomInt(20, 100, 10);

    const relTails = new MCMaths.Fraction(tails / n);
    const relHeads = new MCMaths.Fraction(1).subtract(relTails);

    const expected = relHeads.multiply(new MCMaths.Fraction(m));
    /* END VARIABLES AND CONSTANTS */
    if (m !== n && relHeads.toFloat() > 0.43 && relHeads.toFloat() < 0.47) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `A coin is flipped $${n}$ times and lands on heads $${heads}$ of those times.`
      );

      question.addParagraph(
        "question",
        `a) Calculate the realtive frequency of the coin landing on tails.`
      );
      question.addParagraph(
        "question",
        `b) ${person.name} says that the coin must be biased. Do you agree with ${person.himher}?`
      );
      question.addParagraph("question", `Explain your answer.`);
      question.addParagraph(
        "question",
        `${person.name} flips the coin another $${m}$ times.`
      );
      question.addParagraph(
        "question",
        `c) Estimate the number of times the coin will land on heads.`
      );
      // solution

      question.addHeading(
        "solution",
        `a) First calculate the number of times the coin lands on tails.`
      );
      question.addParagraph("solution", `$${n}-${heads}=${tails}$`);
      if (parseFloat(relTails.toFloat().toFixed(3)) === relTails.toFloat()) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${tails}}{${n}}=${relTails.toFloat()}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${tails}}{${n}}\\approx${parseFloat(
            relTails.toFloat().toFixed(3)
          )}$`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ The relative frequency of tails is $${parseFloat(
          relTails.toFloat().toFixed(3)
        )}$`
      );
      // b

      question.addHeading("solution", `b) Either`);
      question.addParagraph(
        "solution",
        `I agree with ${
          person.name
        }. When flipping a fair coin $${n}$ you would expect it to land on tails $${
          n / 2
        }$ times. This suggests that the coin may be biased.`
      );
      question.addHeading("solution", `Or`);
      question.addParagraph(
        "solution",
        `I disagree with ${person.name}. There is not enough data/difference to determine whether the dice is truly biased. The outcome could be due to chance alone.`
      );

      // c
      question.addHeading(
        "solution",
        `c) First calculate the relative frequency of heads.`
      );

      if (parseFloat(relHeads.toFloat().toFixed(3)) === relHeads.toFloat()) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${heads}}{${n}}=${relHeads.toFloat()}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${heads}}{${n}}\\approx${parseFloat(
            relHeads.toFloat().toFixed(3)
          )}$`
        );
      }
      question.addHeading(
        "solution",
        `Multiply by the number of trials, $${m}$`
      );
      if (parseFloat(expected.toFloat().toFixed(3)) === expected.toFloat()) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${heads}}{${n}}(${m})=${expected.toFloat()}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${heads}}{${n}}(${m})\\approx${parseFloat(
            expected.toFloat().toFixed(3)
          )}$`
        );
      }

      question.addParagraph(
        "solution",
        `$\\therefore$ You would expect $${expected
          .toFloat()
          .toFixed(0)}$ heads out of the $${m}$`
      );

      // answers

      const a1 = new MCQuestion.InputGroup(1);
      a1.addInput(relTails.toFloat(), 0.05, `$(a)$`);
      a1.addInput(parseFloat(expected.toFloat().toFixed(0)), 0, `$(c)$`);

      question.addInputGroup(a1);

      question.requiresSelfMarking();

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ExpectedFrequencies_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const ai = MCRandom.randomInt(2, 3);
    const aii = ["less", "greater"][MCRandom.randomInt(0, 1)];
    const aiii = ["less", "greater"][MCRandom.randomInt(0, 1)];

    const p = MCMisc.getName();

    const sides = MCRandom.randomInt(10, 12);
    const n = MCRandom.randomInt(100, 200, 5);
    const target = MCRandom.randomInt(1, sides);

    const relFreq = MCRandom.randomInt(12, 20) / 100;

    const c_ans = relFreq * n;

    const a = ["a", "an", "a"][sides - 10];

    const aii_expected = n / sides;
    /* END VARIABLES AND CONSTANTS */
    if (c_ans % 1 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `a) Using an ordinary, fair dice calculate the probability of rolling:`
      );
      question.addParagraph("question", `i) a multiple of $${ai}$`);
      question.addParagraph("question", `ii) a number ${aii} than $7$`);
      question.addParagraph("question", `iii) a number ${aiii} than $2.5$`);

      question.addParagraph(
        "question",
        `${p.name} rolls ${a} $${sides}$ sided dice $${n}$ times.`
      );
      question.addParagraph(
        "question",
        `b) Assuming the dice is fair, how many times would you expect to roll a $${target}$`
      );
      question.addParagraph(
        "question",
        `The relative frequency of $${target}$ from the $${n}$ rolls was $${relFreq}$`
      );
      question.addParagraph(
        "question",
        `c) Calculate the number of $${target}$s that were rolled.`
      );
      question.addParagraph(
        "question",
        `d) Does this suggest that the dice is biased?`
      );
      question.addParagraph("question", `Explain your answer.`);

      // solution
      const a1 = new MCQuestion.InputGroup(3);
      const a2 = new MCQuestion.InputGroup(1);
      // a
      question.addHeading(
        "solution",
        `ai) The probability for each outcome is $\\displaystyle\\frac{1}{6}$ as it's a fair dice.`
      );
      if (ai === 2) {
        question.addParagraph(
          "solution",
          `There are three multiples of $2$ in the range $(2,4$ and $6)$`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability is $\\displaystyle\\frac{3}{6}$`
        );
        question.addParagraph("solution", `$=0.5$`);
        a1.addInput(0.5, 0, `$(ai)$`);
      } else {
        question.addParagraph(
          "solution",
          `There are two multiples of $3$ in the range $(3$ and $6)$`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability is $\\displaystyle\\frac{2}{6}$`
        );
        question.addParagraph("solution", `$\\approx 0.33$`);
        a1.addInput(1 / 3, 0.05, `$(ai)$`);
      }
      if (aii === "less") {
        question.addHeading(
          "solution",
          `aii) All the possible outcomes are numbers less than $7$.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ This outcome happens with a probability of $1$.`
        );
        a1.addInput(1, 0, `$(aii)$`);
      } else {
        question.addHeading(
          "solution",
          `aii) None of the possible outcomes are numbers greater than $7$.`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ This outcome happens with a probability of $0$.`
        );
        a1.addInput(0, 0, `$(aii)$`);
      }

      if (aiii === "less") {
        question.addHeading(
          "solution",
          `aiii) There are two possible outcomes less than $2.5$ $(1$and$2)$`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability is $\\displaystyle\\frac{2}{6}$`
        );
        question.addParagraph("solution", `$\\approx 0.33$`);
        a1.addInput(1 / 3, 0.05, `$(aiii)$`);
      } else {
        question.addHeading(
          "solution",
          `aiii) There are four possible outcomes greater than $2.5$ $(3,4,5$and$6)$`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability is $\\displaystyle\\frac{4}{6}$`
        );
        question.addParagraph("solution", `$\\approx 0.67$`);
        a1.addInput(2 / 3, 0.05, `$(aiii)$`);
      }
      // b
      question.addHeading(
        "solution",
        `b) The probability for each outcome is $\\displaystyle\\frac{1}{${sides}}$ assuming it's a fair dice.`
      );
      if (parseFloat(aii_expected.toFixed(3)) === aii_expected) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{1}{${sides}}(${n})=${aii_expected}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{1}{${sides}}(${n})\\approx${parseFloat(
            aii_expected.toFixed(3)
          )}$`
        );
      }

      question.addParagraph(
        "solution",
        `$\\therefore$ You would expect $${target}$ to be rolled $${aii_expected.toFixed(
          0
        )}$ times.`
      );
      a2.addInput(parseFloat(aii_expected.toFixed(0)), 0, `$(b)$`);
      // c
      question.addHeading(
        "solution",
        `c) Multiply the relative frequency with the number of rolls.`
      );
      question.addParagraph("solution", `$${relFreq}(${n})=${c_ans}$`);
      question.addParagraph(
        "solution",
        `$\\therefore ${target}$ was rolled $${c_ans}$ times.`
      );
      a2.addInput(c_ans, 0, `$(c)$`);
      // d
      question.addHeading(
        "solution",
        `d) This does suggest the dice is biased.`
      );
      question.addParagraph(
        "solution",
        `Both the difference in the expected vs actual outcome and the number of data points are significant enough to suggest the dice is biased.`
      );

      // answers

      question.addInputGroup(a1);
      question.addInputGroup(a2);

      question.requiresSelfMarking();

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ExpectedFrequencies_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p1 = MCMisc.getName();
    const p2 = MCMisc.getName();

    const p1Tries = MCRandom.randomInt(20, 50, 10);
    const p1Ends = p1Tries / 10 + MCRandom.randomInt(-3, 3);

    const p2Tries = MCRandom.randomInt(100, 200, 10);
    const p2Ends = p2Tries / 10 + MCRandom.randomInt(-3, 3);

    const p1RF = p1Ends / p1Tries;
    const p2RF = p2Ends / p2Tries;

    const n = MCRandom.randomInt(250, 500, 50);

    const d_ans = n * p2RF;
    /* END VARIABLES AND CONSTANTS */
    if (Math.abs(p1RF - p2RF) > 0.05 && p1Ends > 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `${p1.name} and ${p2.name} want to estimate how many likely they are to get the secret ending of a video game.`
      );
      question.addParagraph(
        "question",
        `The table below shows the number of trials and the number of secret endings each of them got.`
      );
      const t = new MCQuestion.Table(
        ``,
        `Number Of Trials`,
        `Number of Secret Endings`
      );
      t.addRow(`${p1.name}`, `$${p1Tries}$`, `$${p1Ends}$`);
      t.addRow(`${p2.name}`, `$${p2Tries}$`, `$${p2Ends}$`);
      question.addTable("question", t);

      question.addParagraph(
        "question",
        `a) Calculate the relative frequency of the secret ending for ${p1.name}.`
      );
      question.addParagraph(
        "question",
        `b) Calculate the relative frequency of the secret ending for ${p2.name}.`
      );
      question.addParagraph(
        "question",
        `c) State, with a reason, who gives the more reliable estimate for the probability of getting the secret ending.`
      );
      question.addParagraph(
        "question",
        `d) Hence, estimate the number of secret endings you will get from $${n}$ playthroughs.`
      );

      // solution

      // a

      question.addHeading(
        "solution",
        `a) Divide the number of tries by the number of secret endings for ${p1.name}.`
      );
      if (p1RF === parseFloat(p1RF.toFixed(3))) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${p1Ends}}{${p1Tries}}=${p1RF}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${p1Ends}}{${p1Tries}}\\approx${parseFloat(
            p1RF.toFixed(3)
          )}$`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ The relative frequency of the secret ending for ${
          p1.name
        } is $${parseFloat(p1RF.toFixed(3))}$`
      );
      // b
      question.addHeading(
        "solution",
        `b) Divide the number of tries by the number of secret endings for ${p2.name}.`
      );
      if (p2RF === parseFloat(p2RF.toFixed(3))) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${p2Ends}}{${p2Tries}}=${p2RF}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${p2Ends}}{${p2Tries}}\\approx${parseFloat(
            p2RF.toFixed(3)
          )}$`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ The relative frequency of the secret ending for ${
          p2.name
        } is $${parseFloat(p2RF.toFixed(3))}$`
      );

      // c
      question.addHeading(
        "solution",
        `c) The more reliable estimate is the one given by ${p2.name}.`
      );
      question.addParagraph(
        "solution",
        `This is due to the fact that ${p2.name} completed more trials.`
      );
      // d
      question.addHeading(
        "solution",
        `d) Use the relative frequency generated by ${p2.name}.`
      );
      if (d_ans === parseFloat(d_ans.toFixed(3))) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${p1Ends}}{${p1Tries}}(${n})=${d_ans}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${p1Ends}}{${p1Tries}}(${n})\\approx${parseFloat(
            d_ans.toFixed(3)
          )}$`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ You would expect to see the secret ending $${d_ans.toFixed(
          0
        )}$ times.`
      );

      const a1 = new MCQuestion.InputGroup(1);
      a1.addInput(p1RF, 0.05, `$(a)$`);
      a1.addInput(p2RF, 0.05, `$(b)$`);
      a1.addInput(parseFloat(d_ans.toFixed(0)), 0, `$(d)$`);

      question.addInputGroup(a1);

      question.requiresSelfMarking();

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ExpectedFrequencies_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = MCRandom.randomInt(50, 250, 50);
    const A = MCRandom.randomInt(n / 10, (2 * n) / 5);
    const B = MCRandom.randomInt(n / 10, (2 * n) / 5);
    const C = n - A - B;

    const p = MCMisc.getName();
    const p2 = MCMisc.getName();

    const multiple = ["twice", "three times"][MCRandom.randomInt(0, 1)];

    const AFreq = MCRandom.randomInt(20, 30) / 100;
    const m = MCRandom.randomInt(20, 100, 10);

    const relA = A / n;
    const relB = B / n;
    const relC = C / n;

    const c_ans = AFreq * m;
    /* END VARIABLES AND CONSTANTS */
    if (
      Math.abs(AFreq - relA) > 0.025 &&
      Math.abs(AFreq - relA) < 0.075 &&
      m !== n &&
      m !== 2 * n
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A spinner has three possible outcomes. ${p.name} spins the spinner $${n}$ times and the frequency of each outcome is recorded in the table below.`
      );
      const t = new MCQuestion.Table(`Outcome`, `A`, `B`, `C`);
      t.addRow(`Frequency`, `$${A}$`, `$${B}$`, `$${C}$`);
      question.addTable("question", t);

      question.addParagraph(
        "question",
        `a) Calculate the relative frequency of the three different outcomes.`
      );
      question.addParagraph(
        "question",
        `${p2.name} spins ${multiple} as many times as ${p.name} did and calculates ${p2.hisher} own relative frequencies for each outcome.`
      );
      question.addParagraph(
        "question",
        `b) State, and explain, whose relative frequencies are likely to provide the best estimate for the true frequencies of the outcomes.`
      );
      question.addParagraph(
        "question",
        `Given that ${p2.name} calculated outcome A to have a relative frequency of $${AFreq}$.`
      );
      question.addParagraph(
        "question",
        `c) Estimate the number of times outcome A will appear in the next $${m}$ spins.`
      );
      // solution

      question.addHeading(
        "solution",
        `a) Calculating the relative frequency of outcome A.`
      );
      if (parseFloat(relA.toFixed(3)) === relA) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${A}}{${n}}=${relA}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${A}}{${n}}\\approx${parseFloat(
            relA.toFixed(3)
          )}$`
        );
      }

      question.addHeading(
        "solution",
        `Calculating the relative frequency of outcome B.`
      );
      if (parseFloat(relB.toFixed(3)) === relB) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${B}}{${n}}=${relB}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${B}}{${n}}\\approx${parseFloat(
            relB.toFixed(3)
          )}$`
        );
      }

      question.addHeading(
        "solution",
        `Calculating the relative frequency of outcome C.`
      );
      if (parseFloat(relC.toFixed(3)) === relC) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${C}}{${n}}=${relC}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${C}}{${n}}\\approx${parseFloat(
            relC.toFixed(3)
          )}$`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ The outcomes occur with relative frequencies shown in the table below.`
      );

      const t2 = new MCQuestion.Table(`Outcome`, `A`, `B`, `C`);
      t2.addRow(
        `Relative Frequency`,
        `$${parseFloat(relA.toFixed(3))}$`,
        `$${parseFloat(relB.toFixed(3))}$`,
        `$${parseFloat(relC.toFixed(3))}$`
      );
      question.addTable("solution", t2);

      // b
      question.addHeading(
        "solution",
        `b) The more reliable estimate is the one given by ${p2.name}.`
      );
      question.addParagraph(
        "solution",
        `This is due to the fact that ${p2.name} completed more trials.`
      );

      question.addHeading(
        "solution",
        `c) Use the relative frequency generated by ${p2.name}.`
      );
      if (c_ans === parseFloat(c_ans.toFixed(3))) {
        question.addParagraph("solution", `$${AFreq}(${m})=${c_ans}$`);
      } else {
        question.addParagraph("solution", `$${AFreq}(${m})\\approx${c_ans}$`);
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ You would expect to see outcome A $${c_ans.toFixed(
          0
        )}$ times.`
      );

      // a nswers

      const a1 = new MCQuestion.InputGroup(3);
      a1.addInput(relA, 0.05, `$(a)\\ $A`);
      a1.addInput(relB, 0.05, `$(a)\\ $B`);
      a1.addInput(relC, 0.05, `$(a)\\ $C`);

      question.addInputGroup(a1);

      const a2 = new MCQuestion.InputGroup(1);
      a2.addInput(parseFloat(c_ans.toFixed(0)), 0, `$(c)$`);

      question.addInputGroup(a2);

      question.requiresSelfMarking();

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ExpectedFrequencies_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const lemonade = MCRandom.randomInt(20, 40);
    const oj = MCRandom.randomInt(20, 40);
    const water = MCRandom.randomInt(20, 40);
    const aj = MCRandom.randomInt(20, 40);
    const squash = MCRandom.randomInt(20, 40);

    const n = lemonade + oj + water + aj + squash;

    const m = MCRandom.randomInt(100, 500, 100);

    const waterPref = water / n;
    const jPref = (aj + oj) / n;
    const cPref = (n - water) / n;
    const c_ans = cPref * m;
    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `$${n}$ students are given the choice of four drinks. Their preferences are recorded in the table below.`
      );
      const t = new MCQuestion.Table(`Flavour`, `Frequency`);
      t.addRow("Water", `$${water}$`);
      t.addRow("Lemonade", `$${lemonade}$`);
      t.addRow("Apple Juice", `$${aj}$`);
      t.addRow("Orange Juice", `$${oj}$`);
      t.addRow("Squash", `$${squash}$`);
      question.addTable("question", t);

      question.addParagraph(
        "question",
        `a) Find the relative frequency of a preference for water.`
      );
      question.addParagraph(
        "question",
        `b) Find the relative frequency of a preference for apple or orange juice.`
      );

      question.addParagraph(
        "question",
        `Another $${m}$ students are expected to take part in the survey.`
      );
      question.addParagraph(
        "question",
        `c) Estimate the number of these students whose preference will not be water.`
      );
      // a

      question.addHeading(
        "solution",
        `a) Divide the total number of students by the number with a preference for water.`
      );
      if (parseFloat(waterPref.toFixed(3)) === waterPref) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${water}}{${n}}=${waterPref}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${water}}{${n}}\\approx${parseFloat(
            waterPref.toFixed(3)
          )}$`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ The relative frequency of a preference for water is $${parseFloat(
          waterPref.toFixed(3)
        )}$`
      );
      // b
      question.addHeading(
        "solution",
        `b) Divide the total number of students by the number with a preference for apple or orange juice.`
      );
      if (parseFloat(jPref.toFixed(3)) === jPref) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${aj}+${oj}}{${n}}=${jPref}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${aj}+${oj}}{${n}}\\approx${parseFloat(
            jPref.toFixed(3)
          )}$`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ The relative frequency of a preference for apple or orange juice is $${parseFloat(
          jPref.toFixed(3)
        )}$`
      );

      // c

      question.addHeading(
        "solution",
        `c) Calculate the relative frequency of a preference for everything other than water.`
      );
      if (parseFloat(cPref.toFixed(3)) === cPref) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${n}-${water}}{${n}}=${cPref}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${n}-${water}}{${n}}\\approx${parseFloat(
            cPref.toFixed(3)
          )}$`
        );
      }
      question.addHeading(
        "solution",
        `Multiply by the number of new students to be asked, $${m}$`
      );
      if (parseFloat(c_ans.toFixed(3)) === c_ans) {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${n - water}}{${n}}(${m})=${c_ans}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle\\frac{${n - water}}{${n}}(${m})\\approx${parseFloat(
            c_ans.toFixed(3)
          )}$`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ You would expect $${c_ans.toFixed(
          0
        )}$ students to not have a preference for water.`
      );

      const a2 = new MCQuestion.InputGroup(1);
      a2.addInput(waterPref, 0.05, `$(a)$`);
      a2.addInput(jPref, 0.05, `$(b)$`);
      a2.addInput(parseFloat(c_ans.toFixed(0)), 0, `$(c)$`);

      question.addInputGroup(a2);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ProbabilityDiagrams_Exam_Q1() {
  let count = 0;
  const img_scale = 2.2;
  for (let i = 1; i <= 1; i += 1) {
    const tot = MCRandom.randomInt(20, 35);
    const both = MCRandom.randomInt(5, 10);
    const white = MCRandom.randomInt(10, 15);
    const dark = MCRandom.randomInt(5, 10);
    const whiteD = both + white;
    const darkD = both + dark;
    const neither = tot - white - dark - both;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A group of $${tot}$ students is surveyed about the chocolate they like. `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${darkD}$ like dark chocolate`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${whiteD}$ like white chocolate`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${both}$ like both types.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Using a Venn Diagram find the number of students who like neither types.",
        false,
        true,
        true
      )
    );

    const img = new MCQuestion.Image(
      "GCSE/VennDiagrams/BigVenn.JPG",
      "height",
      15
    );
    img.addOverlay("White", 60 / img_scale, 12 / img_scale + 5);
    img.addOverlay(`$${white}$`, 66 / img_scale, 85 / img_scale + 17);
    img.addOverlay("Dark", 145 / img_scale, 12 / img_scale + 5);
    img.addOverlay(`$${dark}$`, 154 / img_scale, 85 / img_scale + 17);
    img.addOverlay(`$${both}$`, 111 / img_scale, 85 / img_scale + 17);
    img.addOverlay(`$${neither}$`, 19 / img_scale, 140 / img_scale + 20);
    question.addImage("solution", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So $${neither}$ students like niether types of chocolate.`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(neither, 0.0001);
    question.addInputGroup(group);

    if (neither <= 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ProbabilityDiagrams_Exam_Q2() {
  let count = 0;
  const img_scale = 2.2;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const tot = MCRandom.randomInt(20, 35);
    const both = MCRandom.randomInt(5, 10);
    const white = MCRandom.randomInt(10, 15);
    const dark = MCRandom.randomInt(5, 10);
    const whiteD = both + white;
    const darkD = both + dark;
    const neither = tot - white - dark - both;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} decides to count the number of cars that are red, and that are saloons, parked outside ${name.hisher} school.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} counts $${tot}$ cars in total. `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${darkD}$ are red`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${whiteD}$ are saloons.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${both}$ are red saloons.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Using a Venn Diagram find the number of red cars that aren't saloons.",
        false,
        true,
        true
      )
    );

    const img = new MCQuestion.Image(
      "GCSE/VennDiagrams/BigVenn.JPG",
      "height",
      15
    );

    img.addOverlay("Saloon", 60 / img_scale, 12 / img_scale + 5);
    img.addOverlay(`$${white}$`, 66 / img_scale, 85 / img_scale + 17);
    img.addOverlay("Red", 145 / img_scale, 12 / img_scale + 5);
    img.addOverlay(`$${dark}$`, 154 / img_scale, 85 / img_scale + 17);
    img.addOverlay(`$${both}$`, 111 / img_scale, 85 / img_scale + 17);
    img.addOverlay(`$${neither}$`, 19 / img_scale, 140 / img_scale + 20);

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(dark, 0.0001);
    question.addInputGroup(group);

    question.addImage("solution", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So $${dark}$ cars are red non-saloons.`,
        false,
        true,
        true
      )
    );
    if (neither <= 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ProbabilityDiagrams_Exam_Q3() {
  const img_scale = 3;

  function Venn1() {
    const question = "$P(A \\cap B)$";
    const img = new MCQuestion.Image(
      "GCSE/VennDiagrams/Intersection.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", 28, 48);
    img.addOverlay("$B$", 72, 48);
    return { question, solution: img };
  }

  function Venn2() {
    const question = "$P(A \\cup B)$";
    const img = new MCQuestion.Image(
      "GCSE/VennDiagrams/Union.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", 28, 48);
    img.addOverlay("$B$", 72, 48);
    return { question, solution: img };
  }

  function Venn3() {
    const question = "$P(A' \\cap B')$";
    const img = new MCQuestion.Image(
      "GCSE/VennDiagrams/NotANotB.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", 28, 48);
    img.addOverlay("$B$", 72, 48);
    return { question, solution: img };
  }

  function Venn4() {
    const question = "$P(A \\cap B')$";
    const img = new MCQuestion.Image(
      "GCSE/VennDiagrams/AnotB.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", 28, 48);
    img.addOverlay("$B$", 72, 48);
    return { question, solution: img };
  }

  function Venn5() {
    const question = "$P(A' \\cap B)$";
    const img = new MCQuestion.Image(
      "GCSE/VennDiagrams/BnotA.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", 28, 48);
    img.addOverlay("$B$", 72, 48);
    return { question, solution: img };
  }

  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    const ques = [Venn1(), Venn2(), Venn3(), Venn4(), Venn5()];
    const n1 = MCRandom.randomInt(0, 4);
    const n2 = MCRandom.randomInt(0, 4);
    const n3 = MCRandom.randomInt(0, 4);
    const q1 = ques[n1];
    const q2 = ques[n2];
    const q3 = ques[n3];

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Shade a Venn diagram to represent each of the following:",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a)$ ${q1.question}`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b)$ ${q2.question}`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(c)$ ${q3.question}`, false, true, true)
    );

    question.addParagraph("solution", "$(a)$");
    question.addImage("solution", q1.solution);
    question.addParagraph("solution", "$(b)$");
    question.addImage("solution", q2.solution);
    question.addParagraph("solution", "$(c)$");
    question.addImage("solution", q3.solution);

    question.requiresSelfMarking();

    if (n1 === n2 || n2 === n3 || n3 === n1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ProbabilityDiagrams_Exam_Q4() {
  function shuffle(a2) {
    const a = a2;
    let j;
    let x;
    let i;
    for (i = a.length - 1; i > 0; i -= 1) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  function multiple16(input) {
    const output = [];

    for (let i = 1; i < 17; i += 1) {
      if (i % input === 0) {
        output.push(i);
      }
    }

    return output;
  }

  function bothVenn(input1, input2) {
    const out1 = [];
    const out2 = [];
    const both = [];

    for (let i = 0; i <= input1.length - 1; i += 1) {
      for (let j = 0; j <= input2.length - 1; j += 1) {
        if (input1[i] === input2[j] && both.indexOf(input2[j]) === -1) {
          both.push(input1[i]);
        }
      }
    }

    input1.forEach(function temp(element) {
      if (both.indexOf(element) === -1) {
        out1.push(element);
      } else {
        out1.push(" ");
      }
    });

    input2.forEach(function temp2(element) {
      if (both.indexOf(element) === -1) {
        out2.push(element);
      } else {
        out2.push(" ");
      }
    });

    while (both.length < 4) {
      both.push(" ");
    }
    while (out1.length < 8) {
      out1.push(" ");
    }
    while (out2.length < 8) {
      out2.push(" ");
    }

    const neither = [];

    for (let j = 1; j <= 16; j += 1) {
      if (both.indexOf(j) === -1) {
        if (out1.indexOf(j) === -1) {
          if (out2.indexOf(j) === -1) {
            neither.push(j);
          }
        }
      }
    }
    while (neither.length < 8) {
      neither.push(" ");
    }

    return {
      out1: shuffle(out1),
      out2: shuffle(out2),
      both: shuffle(both),
      neither: shuffle(neither),
    };
  }

  function Upp(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  let count = 0;
  const img_scale = 2.6;
  const img_scaley = 2.2;
  const ox = 5;
  const oxy = 10;
  for (let i = 1; i <= 1; i += 1) {
    const optionLST = [
      "multiples of $2$",
      "multiples of $3$",
      "multiples of $5$",
      "multiples of $7$",
      "prime numbers",
      "square numbers",
    ];
    const opt1 = MCRandom.randomInt(0, 5);
    const opt2 = MCRandom.randomInt(0, 5);

    const primes = [2, 3, 5, 7, 11, 13];
    const squares = [1, 4, 9, 16];

    const optionARR = [
      multiple16(2),
      multiple16(3),
      multiple16(5),
      multiple16(7),
      primes,
      squares,
    ];

    const final = bothVenn(optionARR[opt1], optionARR[opt2]);

    // m(2),m(3),(m5),prime, square
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `For the numbers $1$ to $16$, draw a venn diagram of ${optionLST[opt1]} and ${optionLST[opt2]}.`,
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    const img = new MCQuestion.Image(
      "GCSE/VennDiagrams/BigVenn.JPG",
      "height",
      18
    );
    // LHS
    img.addOverlay(
      Upp(optionLST[opt1]),
      12 / img_scale + ox + 20,
      18 / img_scaley + oxy - 7
    );
    img.addOverlay(
      `$${final.out1[0]}$`,
      72 / img_scale + ox,
      48 / img_scaley + oxy + 3
    );
    img.addOverlay(
      `$${final.out1[1]}$`,
      47 / img_scale + ox,
      63 / img_scaley + oxy
    );
    img.addOverlay(
      `$${final.out1[2]}$`,
      72 / img_scale + ox,
      64 / img_scaley + oxy + 5
    );
    img.addOverlay(
      `$${final.out1[3]}$`,
      33 / img_scale + ox + 2,
      80 / img_scaley + oxy + 4
    );
    img.addOverlay(
      `$${final.out1[4]}$`,
      64 / img_scale + ox,
      81 / img_scaley + oxy + 8
    );
    img.addOverlay(
      `$${final.out1[5]}$`,
      38 / img_scale + ox,
      101 / img_scaley + oxy + 7
    );
    img.addOverlay(
      `$${final.out1[6]}$`,
      72 / img_scale + ox,
      103 / img_scaley + oxy + 8
    );
    img.addOverlay(
      `$${final.out1[7]}$`,
      65 / img_scale + ox,
      121 / img_scaley + oxy + 10
    );

    // middle
    img.addOverlay(
      `$${final.both[0]}$`,
      107 / img_scale + ox + 4,
      64 / img_scaley + oxy + 3
    );
    img.addOverlay(
      `$${final.both[1]}$`,
      102 / img_scale + ox + 2,
      80 / img_scaley + oxy + 7
    );
    img.addOverlay(
      `$${final.both[2]}$`,
      115 / img_scale + ox + 3,
      94 / img_scaley + oxy + 9
    );
    img.addOverlay(
      `$${final.both[3]}$`,
      105 / img_scale + ox + 4,
      108 / img_scaley + oxy + 12
    );

    // RHS
    img.addOverlay(
      Upp(optionLST[opt2]),
      120 / img_scale + ox + 18,
      18 / img_scaley + oxy - 7
    );
    img.addOverlay(
      `$${final.out2[0]}$`,
      145 / img_scale + ox + 7,
      45 / img_scaley + oxy + 2
    );
    img.addOverlay(
      `$${final.out2[1]}$`,
      140 / img_scale + ox + 4,
      62 / img_scaley + oxy + 2
    );
    img.addOverlay(
      `$${final.out2[2]}$`,
      167 / img_scale + ox + 7,
      63 / img_scaley + oxy + 4
    );
    img.addOverlay(
      `$${final.out2[3]}$`,
      149 / img_scale + ox + 2,
      82 / img_scaley + oxy + 4
    );
    img.addOverlay(
      `$${final.out2[4]}$`,
      174 / img_scale + ox,
      84 / img_scaley + oxy + 8
    );
    img.addOverlay(
      `$${final.out2[5]}$`,
      140 / img_scale + ox + 5,
      103 / img_scaley + oxy + 9
    );
    img.addOverlay(
      `$${final.out2[6]}$`,
      173 / img_scale + ox + 4,
      106 / img_scaley + oxy + 10
    );
    img.addOverlay(
      `$${final.out2[7]}$`,
      145 / img_scale + ox + 3,
      126 / img_scaley + oxy + 10
    );

    // outside
    img.addOverlay(
      `$${final.neither[0]}$`,
      10 / img_scale + ox,
      56 / img_scaley + oxy
    );
    img.addOverlay(
      `$${final.neither[1]}$`,
      5 / img_scale + ox,
      85 / img_scaley + oxy
    );
    img.addOverlay(
      `$${final.neither[2]}$`,
      3 / img_scale + ox,
      113 / img_scaley + oxy
    );
    img.addOverlay(
      `$${final.neither[3]}$`,
      18 / img_scale + ox - 5,
      138 / img_scaley + oxy
    );
    img.addOverlay(
      `$${final.neither[4]}$`,
      110 / img_scale + ox,
      145 / img_scaley + oxy + 17
    );
    img.addOverlay(
      `$${final.neither[5]}$`,
      201 / img_scale + ox + 10,
      135 / img_scaley + oxy
    );
    img.addOverlay(
      `$${final.neither[6]}$`,
      207 / img_scale + ox + 10,
      106 / img_scaley + oxy
    );
    img.addOverlay(
      `$${final.neither[7]}$`,
      206 / img_scale + ox + 10,
      64 / img_scaley + oxy
    );

    question.requiresSelfMarking();

    question.addImage("solution", img);

    if (
      opt1 === opt2 ||
      final.out1.length > 8 ||
      final.out2.length > 8 ||
      final.neither.length > 8 ||
      final.both.length > 4
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ProbabilityDiagrams_Exam_Q5() {
  function shuffle(a2) {
    const a = a2;
    let j;
    let x;
    let i;
    for (i = a.length - 1; i > 0; i -= 1) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  function multiple16(input) {
    const output = [];

    for (let i = 1; i < 17; i += 1) {
      if (i % input === 0) {
        output.push(i);
      }
    }

    return output;
  }

  function bothVenn(input1, input2) {
    const out1 = [];
    const out2 = [];
    const both = [];

    for (let i = 0; i <= input1.length - 1; i += 1) {
      for (let j = 0; j <= input2.length - 1; j += 1) {
        if (input1[i] === input2[j] && both.indexOf(input2[j]) === -1) {
          both.push(input1[i]);
        }
      }
    }

    input1.forEach(function temp(element) {
      if (both.indexOf(element) === -1) {
        out1.push(element);
      } else {
        out1.push(" ");
      }
    });

    input2.forEach(function temp2(element) {
      if (both.indexOf(element) === -1) {
        out2.push(element);
      } else {
        out2.push(" ");
      }
    });

    while (both.length < 4) {
      both.push(" ");
    }
    while (out1.length < 8) {
      out1.push(" ");
    }
    while (out2.length < 8) {
      out2.push(" ");
    }

    const neither = [];

    for (let j = 1; j <= 16; j += 1) {
      if (both.indexOf(j) === -1) {
        if (out1.indexOf(j) === -1) {
          if (out2.indexOf(j) === -1) {
            neither.push(j);
          }
        }
      }
    }
    while (neither.length < 8) {
      neither.push(" ");
    }

    return {
      out1: shuffle(out1),
      out2: shuffle(out2),
      both: shuffle(both),
      neither: shuffle(neither),
    };
  }

  function Upp(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  let count = 0;
  const img_scale = 2.6;
  const img_scaley = 2.2;
  const ox = 5;
  const oxy = 10;
  for (let i = 1; i <= 1; i += 1) {
    const optionLST = [
      "multiples of $2$",
      "multiples of $3$",
      "multiples of $5$",
      "multiples of $7$",
      "prime numbers",
      "square numbers",
    ];
    const opt1 = MCRandom.randomInt(0, 5);
    const opt2 = MCRandom.randomInt(0, 5);

    const primes = [2, 3, 5, 7, 11, 13];
    const squares = [1, 4, 9, 16];

    const optionARR = [
      multiple16(2),
      multiple16(3),
      multiple16(5),
      multiple16(7),
      primes,
      squares,
    ];

    const final = bothVenn(optionARR[opt1], optionARR[opt2]);

    // m(2),m(3),(m5),prime, square
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`What do $A$ and $B$ represent?.`, false)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$A$ represents ${optionLST[opt1]}, and $B$ represents ${optionLST[opt2]}.`,
        false,
        true,
        true
      )
    );
    const img = new MCQuestion.Image(
      "GCSE/VennDiagrams/BigVenn.JPG",
      "height",
      18
    );
    // LHS
    img.addOverlay("$A$", 12 / img_scale + ox + 20, 18 / img_scaley + oxy - 7);
    img.addOverlay(
      `$${final.out1[0]}$`,
      72 / img_scale + ox,
      48 / img_scaley + oxy + 3
    );
    img.addOverlay(
      `$${final.out1[1]}$`,
      47 / img_scale + ox,
      63 / img_scaley + oxy
    );
    img.addOverlay(
      `$${final.out1[2]}$`,
      72 / img_scale + ox,
      64 / img_scaley + oxy + 5
    );
    img.addOverlay(
      `$${final.out1[3]}$`,
      33 / img_scale + ox + 2,
      80 / img_scaley + oxy + 4
    );
    img.addOverlay(
      `$${final.out1[4]}$`,
      64 / img_scale + ox,
      81 / img_scaley + oxy + 8
    );
    img.addOverlay(
      `$${final.out1[5]}$`,
      38 / img_scale + ox,
      101 / img_scaley + oxy + 7
    );
    img.addOverlay(
      `$${final.out1[6]}$`,
      72 / img_scale + ox,
      103 / img_scaley + oxy + 8
    );
    img.addOverlay(
      `$${final.out1[7]}$`,
      65 / img_scale + ox,
      121 / img_scaley + oxy + 10
    );

    // middle
    img.addOverlay(
      `$${final.both[0]}$`,
      107 / img_scale + ox + 4,
      64 / img_scaley + oxy + 3
    );
    img.addOverlay(
      `$${final.both[1]}$`,
      102 / img_scale + ox + 2,
      80 / img_scaley + oxy + 7
    );
    img.addOverlay(
      `$${final.both[2]}$`,
      115 / img_scale + ox + 3,
      94 / img_scaley + oxy + 9
    );
    img.addOverlay(
      `$${final.both[3]}$`,
      105 / img_scale + ox + 4,
      108 / img_scaley + oxy + 12
    );

    // RHS
    img.addOverlay("$B$", 120 / img_scale + ox + 18, 18 / img_scaley + oxy - 7);
    img.addOverlay(
      `$${final.out2[0]}$`,
      145 / img_scale + ox + 7,
      45 / img_scaley + oxy + 2
    );
    img.addOverlay(
      `$${final.out2[1]}$`,
      140 / img_scale + ox + 4,
      62 / img_scaley + oxy + 2
    );
    img.addOverlay(
      `$${final.out2[2]}$`,
      167 / img_scale + ox + 7,
      63 / img_scaley + oxy + 4
    );
    img.addOverlay(
      `$${final.out2[3]}$`,
      149 / img_scale + ox + 2,
      82 / img_scaley + oxy + 4
    );
    img.addOverlay(
      `$${final.out2[4]}$`,
      174 / img_scale + ox,
      84 / img_scaley + oxy + 8
    );
    img.addOverlay(
      `$${final.out2[5]}$`,
      140 / img_scale + ox + 5,
      103 / img_scaley + oxy + 9
    );
    img.addOverlay(
      `$${final.out2[6]}$`,
      173 / img_scale + ox + 4,
      106 / img_scaley + oxy + 10
    );
    img.addOverlay(
      `$${final.out2[7]}$`,
      145 / img_scale + ox + 3,
      126 / img_scaley + oxy + 10
    );

    // outside
    img.addOverlay(
      `$${final.neither[0]}$`,
      10 / img_scale + ox,
      56 / img_scaley + oxy
    );
    img.addOverlay(
      `$${final.neither[1]}$`,
      5 / img_scale + ox,
      85 / img_scaley + oxy
    );
    img.addOverlay(
      `$${final.neither[2]}$`,
      3 / img_scale + ox,
      113 / img_scaley + oxy
    );
    img.addOverlay(
      `$${final.neither[3]}$`,
      18 / img_scale + ox - 5,
      138 / img_scaley + oxy
    );
    img.addOverlay(
      `$${final.neither[4]}$`,
      110 / img_scale + ox,
      145 / img_scaley + oxy + 17
    );
    img.addOverlay(
      `$${final.neither[5]}$`,
      201 / img_scale + ox + 10,
      135 / img_scaley + oxy
    );
    img.addOverlay(
      `$${final.neither[6]}$`,
      207 / img_scale + ox + 10,
      106 / img_scaley + oxy
    );
    img.addOverlay(
      `$${final.neither[7]}$`,
      206 / img_scale + ox + 10,
      64 / img_scaley + oxy
    );

    question.requiresSelfMarking();

    question.addImage("question", img);

    if (
      opt1 === opt2 ||
      final.out1.length > 8 ||
      final.out2.length > 8 ||
      final.neither.length > 8 ||
      final.both.length > 4
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ProbabilityDiagrams_Exam_Q6() {
  let count = 0;
  const img_scale = 1.85;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();

    // display things
    const a = MCRandom.randomInt(10, 20);
    const b = MCRandom.randomInt(10, 20);
    const c = MCRandom.randomInt(10, 20);
    const ab = MCRandom.randomInt(5, 10);
    const bc = MCRandom.randomInt(5, 10);
    const ac = MCRandom.randomInt(5, 10);

    const tot = Math.ceil((a + b + c + ab + bc + ac) / 10) * 10;
    const abc = tot - (a + b + c + ab + bc + ac);
    // quesiton things
    const g = a + ab + ac + abc;
    const p = b + ab + bc + abc;
    const d = c + bc + ac + abc;
    const gp = ab + abc;
    const pd = bc + abc;
    const dg = ac + abc;
    const all = abc;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "A group of musicians is surveyed about the instruments they can play.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Each of these musicains can play guira, piano and drums, or a combination of the three.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${g}$ can play guitar.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${d}$ can play drums.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${p}$ can play piano.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${a}$ can only play guitar.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${all}$ can play all three instruments.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${pd}$ can play piano and drums.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${gp}$ can play guitar and piano.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Using a Venn Diagram:", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find the number of musicians who can play guitar and drums.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the total number of musicians.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    const img = new MCQuestion.Image(
      "GCSE/VennDiagrams/TripleVenn.jpg",
      "height",
      18
    );
    img.addOverlay("Guitar", 4 / img_scale + 10, 1 / img_scale + 5);
    img.addOverlay("Piano", 25 / img_scale + 15, 175 / img_scale);
    img.addOverlay("Drums", 156 / img_scale + 3, 1 / img_scale + 5);
    img.addOverlay(`$${a}$`, 40 / img_scale, 51 / img_scale);
    img.addOverlay(`$${b}$`, 90 / img_scale, 134 / img_scale);
    img.addOverlay(`$${c}$`, 140 / img_scale, 51 / img_scale);
    img.addOverlay(`$${ab}$`, 66 / img_scale, 95 / img_scale);
    img.addOverlay(`$${bc}$`, 118 / img_scale, 93 / img_scale);
    img.addOverlay(`$${ac}$`, 91 / img_scale, 50 / img_scale);
    img.addOverlay(`$${abc}$`, 95 / img_scale, 80 / img_scale);

    question.addImage("solution", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a) \\ ${dg}$ can play guitar and drums.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ There are $${tot}$ musicians in total.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(dg, 0, "$(a)$");
    group.addInput(tot, 0, "$(b)$");
    question.addInputGroup(group);

    if (abc <= 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ProbabilityDiagrams_Exam_Q7() {
  let count = 0;
  const img_scale = 2.25;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const x = MCRandom.randomInt(4, 15);
    const fracDog = 1 / MCRandom.randomInt(3, 9);
    const fracCat = 1 / MCRandom.randomInt(3, 9);

    const fDm = 1 / fracDog - 1;
    const fCm = 1 / fracCat - 1;
    const tot = x * (1 + fDm + fCm);
    const coeff = 1 + fDm + fCm;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A group of $${tot}$ students is asked about the pets they own.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Each student owns a cat, dog, or both.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$x$ students own both.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${fracDog}$ dog owners own both a cat and dog.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${fracCat}$ cat owners own both a cat and dog.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Using a Venn Diagram find the number of students who own both.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    const img = new MCQuestion.Image(
      "GCSE/VennDiagrams/BigVenn.JPG",
      "height",
      15
    );
    img.addOverlay("Dogs", 60 / img_scale + 5, 12 / img_scale + 5);
    img.addOverlay(`$${fDm}x$`, 66 / img_scale, 85 / img_scale + 18);
    img.addOverlay("Cats", 145 / img_scale + 2, 12 / img_scale + 5);
    img.addOverlay(`$${fCm}x$`, 154 / img_scale, 85 / img_scale + 18);
    img.addOverlay("$x$", 111 / img_scale, 85 / img_scale + 18);
    question.addImage("solution", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`So, $${tot}=${coeff}x$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${x}$ students own both.`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(x, 0);
    question.addInputGroup(group);

    if (Math.round(tot) !== tot || fracCat === fracDog) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ProbabilitySpaces_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const person = MCMisc.getName();

    const ii_drink = ["water", "coffee", "orange juice"][
      MCRandom.randomInt(0, 2)
    ];
    const i1_drink = ["water", "coffee", "orange juice"][
      MCRandom.randomInt(0, 2)
    ];
    const i2_drink = ["water", "coffee", "orange juice"][
      MCRandom.randomInt(0, 2)
    ];
    const iv_drink = ["water", "coffee", "orange juice"][
      MCRandom.randomInt(0, 2)
    ];

    const sameDiff = ["of the same", "different"][MCRandom.randomInt(0, 1)];
    /* END VARIABLES AND CONSTANTS */
    if (i1_drink !== i2_drink) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `${person.name} wins two coupons for a free Water$(W)$, Coffee$(C)$ or Orange Juice$(O)$.`
      );
      question.addParagraph(
        "question",
        `${person.HeShe} chooses the two rewards at random.`
      );
      question.addParagraph(
        "question",
        `a) List all the possible pairs of drinks he could receive.`
      );
      question.addParagraph(
        "question",
        `b) Find the probability that ${person.heshe}:`
      );
      question.addParagraph(
        "question",
        `i) receives ${i1_drink} and ${i2_drink}.`
      );

      question.addParagraph("question", `ii) receives a ${ii_drink}.`);
      question.addParagraph(
        "question",
        `iii) receives two ${sameDiff} drinks.`
      );
      question.addParagraph("question", `iv) doesn't receive any ${iv_drink}.`);

      // solution

      question.addHeading(
        "solution",
        `a) The possible pairs of drinks, using their assigned letters, are:`
      );
      question.addParagraph("solution", `$WW, WC, WO, CC, CO $ and $OO$.`);
      question.addParagraph(
        "solution",
        `$($Where $WC$ represents receiving a water and a coffee$)$`
      );
      // bi
      question.addHeading(
        "solution",
        `bi) There are $6$ possible outcomes, each of which is equally likely due to the random aspect of their selection.`
      );
      question.addParagraph(
        "solution",
        `A ${i1_drink} and ${i2_drink} represents $1$ outcome of $6$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The probability of getting ${i1_drink} and ${i2_drink} is $\\displaystyle \\frac{1}{6}$`
      );
      // ii
      question.addHeading(
        "solution",
        `bii) There are $3$ outcomes where ${person.name} receives ${ii_drink}.`
      );
      if (ii_drink === "water") {
        question.addParagraph("solution", `These are $WW, WC$ and $WO$.`);
      } else if (ii_drink === "coffee") {
        question.addParagraph("solution", `These are $CC, WC$ and $CO$.`);
      } else {
        question.addParagraph("solution", `These are $OO, WO$ and $CO$.`);
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ The probability of getting ${ii_drink} is $\\displaystyle \\frac{3}{6}$ or $\\displaystyle \\frac{1}{2}$`
      );
      // iii

      question.addHeading(
        "solution",
        `biii) There are $3$ outcomes where ${person.name} receives two ${sameDiff} drinks.`
      );
      if (sameDiff === "different") {
        question.addParagraph("solution", `These are $WC, WO$ and $CO$.`);
      } else {
        question.addParagraph("solution", `These are $WW, CC$ and $OO$.`);
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ The probability of getting two ${sameDiff} drinks is $\\displaystyle \\frac{3}{6}$ or $\\displaystyle \\frac{1}{2}$`
      );

      // iv
      question.addHeading(
        "solution",
        `biv) There is only $1$ outcome where ${person.name} doesn't receive any ${iv_drink}.`
      );
      if (ii_drink === "water") {
        question.addParagraph(
          "solution",
          `This is when he receives two waters.`
        );
      } else if (ii_drink === "coffee") {
        question.addParagraph(
          "solution",
          `This is when he receives two coffees.`
        );
      } else {
        question.addParagraph(
          "solution",
          `This is when he receives two orange juices.`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ The probability of not getting any ${iv_drink} is $\\displaystyle \\frac{5}{6}$`
      );

      //

      const g = new MCQuestion.InputGroup(4);
      g.addInput(1 / 6, 0.05, `$(bi)$`);

      g.addInput(1 / 2, 0, `$(ii)$`);
      g.addInput(1 / 2, 0, `$(iii)$`);
      g.addInput(5 / 6, 0.05, `$(iv)$`);
      question.addInputGroup(g);

      question.requiresSelfMarking();

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ProbabilitySpaces_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p = MCMisc.getName();
    const p2 = MCMisc.getName();
    const outcomes = [`HHH`, `HHT`, `HTH`, `HTT`, `THH`, `THT`, `TTH`, `TTT`];

    const i = MCRandom.randomInt(0, 7);

    const iii_side = ["heads", "tails"][MCRandom.randomInt(0, 1)];
    const ii_sameDiff = ["the same", "different"][MCRandom.randomInt(0, 1)];

    const n = MCRandom.randomInt(250, 1000, 50);

    const c_ans = n / 4;
    /* END VARIABLES AND CONSTANTS */
    if (p.name !== p2.name && (n / 4) % 1 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `${p.name} flips a fair coin $3$ times.`
      );
      question.addParagraph(
        "question",
        `Let $H$ and $T$ represent ${p.name} flipping a heads or tails. The sequence $HTH$ represents ${p.himher} flpping a heads, tails then heads again.`
      );
      question.addParagraph(
        "question",
        `a) List all the possible outcomes for the series of three flips.`
      );
      question.addParagraph(
        "question",
        `b) Find the probability that ${p.name} gets:`
      );
      question.addParagraph("question", `i) the sequence $${outcomes[i]}$.`);
      question.addParagraph(
        "question",
        `ii) ${ii_sameDiff} sides on ${p.hisher} first and last flip.`
      );
      question.addParagraph("question", `iii) only one ${iii_side}.`);

      question.addParagraph(
        "question",
        `${p2.name} simulates flipping a fair coin $3$ times in a row $${n}$ times.`
      );
      question.addParagraph(
        "question",
        `c) Calculate the number of times ${p2.heshe} should expect to see a sequence of all heads or all tails.`
      );
      // solution
      question.addHeading("solution", `a) The possible outcomes are:`);
      question.addParagraph(
        "solution",
        `$${outcomes.slice(0, outcomes.length - 1)}$ and $TTT$`
      );

      // bi
      question.addHeading(
        `solution`,
        `bi) There are $8$ possible outcomes, each of which is equally likely.`
      );
      question.addParagraph(
        "solution",
        `The sequence $${outcomes[i]}$ represents $1$ of the $8$ outcomes.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$The probability of getting the sequence $${outcomes[i]}$ is $\\displaystyle\\frac{1}{8}$`
      );
      // ii
      question.addHeading(
        "solution",
        `bii) There are $4$ outcomes where ${ii_sameDiff} sides are flipped on the first and last flips.`
      );
      if (ii_sameDiff === "different") {
        question.addParagraph(
          "solution",
          `These are the sequences $HHT, HTT, THH$ and $TTH$.`
        );
      } else {
        question.addParagraph(
          "solution",
          `These are the sequences $HHH, HTH, THT$ and $TTT$.`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ The probability of flipping ${ii_sameDiff} sides on the first and last flip is $\\displaystyle \\frac{4}{8}$ or $\\displaystyle \\frac{1}{2}$`
      );

      // iii

      question.addHeading(
        "solution",
        `biii) There are $3$ outcomes where only $1$ ${iii_side} is flipped.`
      );
      if (iii_side === "heads") {
        question.addParagraph(
          "solution",
          `These are the sequences $HTT, THT$ and $TTH$`
        );
      } else {
        question.addParagraph(
          "solution",
          `These are the sequences $HHT, HTH$ and $THH$`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ The probability of flipping only $1$ ${iii_side} is $\\displaystyle \\frac{3}{8}$`
      );

      // c

      question.addHeading(
        "solution",
        `c) First calculate the probability of seeing $HHH$ or $TTT$.`
      );
      question.addParagraph(
        "solution",
        `These are two possible outcomes of $8$ so the probability is $\\displaystyle \\frac{2}{8}$ or $\\displaystyle\\frac{1}{4}$`
      );

      question.addHeading(
        "solution",
        `Multiply the probability by the number of trials.`
      );
      question.addParagraph(
        `solution`,
        `$\\displaystyle\\frac{1}{4}(${n})=${c_ans}$`
      );

      question.addParagraph(
        "solution",
        `$\\therefore$ ${p2.HeShe} should expect to see $HHH$ or $TTT \\ ${
          n / 4
        }$ times.`
      );
      /* RETURN THE QUESTION */

      const g = new MCQuestion.InputGroup(3);
      g.addInput(1 / 8, 0.05, `$(bi)$`);

      g.addInput(1 / 2, 0, `$(ii)$`);
      g.addInput(3 / 8, 0.05, `$(iii)$`);
      g.addInput(n / 4, 0, `$(c)$`);
      question.addInputGroup(g);

      question.requiresSelfMarking();

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ProbabilitySpaces_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p = MCMisc.getName();

    const tableFill = [
      [``, ``, ``, ``, ``, ``],
      [``, ``, ``, ``, ``, ``],
      [``, ``, ``, ``, ``, ``],
      [``, ``, ``, ``, ``, ``],
      [``, ``, ``, ``, ``, ``],
      [``, ``, ``, ``, ``, ``],
    ];
    const fullTable = [
      [``, ``, ``, ``, ``, ``],
      [``, ``, ``, ``, ``, ``],
      [``, ``, ``, ``, ``, ``],
      [``, ``, ``, ``, ``, ``],
      [``, ``, ``, ``, ``, ``],
      [``, ``, ``, ``, ``, ``],
    ];

    for (let i = 1; i < 7; i += 1) {
      for (let j = 1; j < 7; j += 1) {
        if (MCRandom.randomInt(0, 3) === 0) {
          tableFill[j - 1][i - 1] = i + j;
        }
        fullTable[j - 1][i - 1] = i + j;
      }
    }

    const i = MCRandom.randomInt(3, 11);
    const ii = MCRandom.randomInt(5, 9);

    const iii_low = MCRandom.randomInt(4, 6);
    const iii_high = MCRandom.randomInt(8, 10);

    const c_mulitple = MCRandom.randomInt(3, 5, 2);
    const cIndex = MCRandom.randomInt(0, 1);
    const c_evenOdd = ["even", "odd"][cIndex];
    const cModulo = [0, 1][cIndex];
    // ways[3-2] = 1 because there is only one way to roll a score of two
    const ways = [1, 2, 3, 4, 5, 6, 5, 4, 3, 2, 1];

    const ii_ways = ways.slice(ii - 1, ways.length).reduce((a, b) => a + b, 0);
    const iii_ways = ways
      .slice(iii_low - 2, iii_high - 2)
      .reduce((a, b) => a + b, 0);

    const c_scores = [];
    let c_total = 0;

    for (let j = 2; j < 13; j += 1) {
      if (j % 2 === cModulo || j % c_mulitple === 0) {
        c_scores.push(j);
        c_total += ways[j - 2];
      }
    }
    /* END VARIABLES AND CONSTANTS */
    if (i !== ii) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `${p.name} rolls two fair dice and sums their faces to give a score.`
      );

      question.addParagraph(
        "question",
        `a) Complete the table below to show all possible scores.`
      );

      const t = new MCQuestion.Table(
        `$+$`,
        `$1$`,
        `$2$`,
        `$3$`,
        `$4$`,
        `$5$`,
        `$6$`
      );
      t.addRow(
        `$1$`,
        `$${tableFill[0][0]}$`,
        `$${tableFill[0][1]}$`,
        `$${tableFill[0][2]}$`,
        `$${tableFill[0][3]}$`,
        `$${tableFill[0][4]}$`,
        `$${tableFill[0][5]}$`
      );
      t.addRow(
        `$2$`,
        `$${tableFill[1][0]}$`,
        `$${tableFill[1][1]}$`,
        `$${tableFill[1][2]}$`,
        `$${tableFill[1][3]}$`,
        `$${tableFill[1][4]}$`,
        `$${tableFill[1][5]}$`
      );
      t.addRow(
        `$3$`,
        `$${tableFill[2][0]}$`,
        `$${tableFill[2][1]}$`,
        `$${tableFill[2][2]}$`,
        `$${tableFill[2][3]}$`,
        `$${tableFill[2][4]}$`,
        `$${tableFill[2][5]}$`
      );
      t.addRow(
        `$4$`,
        `$${tableFill[3][0]}$`,
        `$${tableFill[3][1]}$`,
        `$${tableFill[3][2]}$`,
        `$${tableFill[3][3]}$`,
        `$${tableFill[3][4]}$`,
        `$${tableFill[3][5]}$`
      );
      t.addRow(
        `$5$`,
        `$${tableFill[4][0]}$`,
        `$${tableFill[4][1]}$`,
        `$${tableFill[4][2]}$`,
        `$${tableFill[4][3]}$`,
        `$${tableFill[4][4]}$`,
        `$${tableFill[4][5]}$`
      );
      t.addRow(
        `$6$`,
        `$${tableFill[5][0]}$`,
        `$${tableFill[5][1]}$`,
        `$${tableFill[5][2]}$`,
        `$${tableFill[5][3]}$`,
        `$${tableFill[5][4]}$`,
        `$${tableFill[5][5]}$`
      );

      question.addTable("question", t);

      question.addParagraph(
        "question",
        `b) Find the probability that ${p.name} scores:`
      );
      question.addParagraph("question", `i) exactly $${i}$`);
      question.addParagraph("question", `ii) greater than $${ii}$`);
      question.addParagraph(
        "question",
        `iii) at least $${iii_low}$ but less than $${iii_high}$`
      );

      question.addParagraph(
        "question",
        `c) Find the probability that ${p.name} rolls an ${c_evenOdd} number or a multiple of $${c_mulitple}$`
      );

      // solution
      question.addHeading(
        "solution",
        `a) The complete table should have the following values:`
      );
      const t2 = new MCQuestion.Table(
        `$+$`,
        `$1$`,
        `$2$`,
        `$3$`,
        `$4$`,
        `$5$`,
        `$6$`
      );
      t2.addRow(
        `$1$`,
        `$${fullTable[0][0]}$`,
        `$${fullTable[0][1]}$`,
        `$${fullTable[0][2]}$`,
        `$${fullTable[0][3]}$`,
        `$${fullTable[0][4]}$`,
        `$${fullTable[0][5]}$`
      );
      t2.addRow(
        `$2$`,
        `$${fullTable[1][0]}$`,
        `$${fullTable[1][1]}$`,
        `$${fullTable[1][2]}$`,
        `$${fullTable[1][3]}$`,
        `$${fullTable[1][4]}$`,
        `$${fullTable[1][5]}$`
      );
      t2.addRow(
        `$3$`,
        `$${fullTable[2][0]}$`,
        `$${fullTable[2][1]}$`,
        `$${fullTable[2][2]}$`,
        `$${fullTable[2][3]}$`,
        `$${fullTable[2][4]}$`,
        `$${fullTable[2][5]}$`
      );
      t2.addRow(
        `$4$`,
        `$${fullTable[3][0]}$`,
        `$${fullTable[3][1]}$`,
        `$${fullTable[3][2]}$`,
        `$${fullTable[3][3]}$`,
        `$${fullTable[3][4]}$`,
        `$${fullTable[3][5]}$`
      );
      t2.addRow(
        `$5$`,
        `$${fullTable[4][0]}$`,
        `$${fullTable[4][1]}$`,
        `$${fullTable[4][2]}$`,
        `$${fullTable[4][3]}$`,
        `$${fullTable[4][4]}$`,
        `$${fullTable[4][5]}$`
      );
      t2.addRow(
        `$6$`,
        `$${fullTable[5][0]}$`,
        `$${fullTable[5][1]}$`,
        `$${fullTable[5][2]}$`,
        `$${fullTable[5][3]}$`,
        `$${fullTable[5][4]}$`,
        `$${fullTable[5][5]}$`
      );

      question.addTable("solution", t2);

      question.addHeading(
        "solution",
        `bi) There are $36$ possible outcomes in the table and $${
          ways[i - 2]
        }$ ways to roll a score of $${i}$ `
      );
      if (36 % ways[i - 2] === 0) {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of rolling a score of exactly $${i}$ is $\\displaystyle\\frac{${
            ways[i - 2]
          }}{36}$ or $\\displaystyle ${new MCMaths.Fraction(ways[i - 2] / 36)}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of rolling a score of exactly $${i}$ is $\\displaystyle\\frac{${
            ways[i - 2]
          }}{36}$`
        );
      }

      // ii

      question.addHeading(
        "solution",
        `bii) There are $${ii_ways}$ ways to roll a score greater than $${ii}\\ ( ${
          ii + 1
        }$ or greater$)$.`
      );
      if (ii_ways % 2 === 0 || ii_ways % 3 === 0) {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of rolling a score greater than $${ii}$ is $\\displaystyle\\frac{${ii_ways}}{36}$ or $\\displaystyle ${new MCMaths.Fraction(
            ii_ways / 36
          )}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of rolling a score greater than $${ii}$ is $\\displaystyle\\frac{${ii_ways}}{36}$`
        );
      }
      // iii

      question.addHeading(
        "solution",
        `biii) There are $${iii_ways}$ ways to roll a score, $s$ in the range $${iii_low}\\leq s <${iii_high}$.`
      );
      if (iii_ways % 2 === 0 || iii_ways % 3 === 0) {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of rolling a score in the range $${iii_low}\\leq s <${iii_high}$ is $\\displaystyle\\frac{${iii_ways}}{36}$ or $\\displaystyle ${new MCMaths.Fraction(
            iii_ways / 36
          )}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of rolling a score in the range $${iii_low}\\leq s <${iii_high}$ is $\\displaystyle\\frac{${iii_ways}}{36}$`
        );
      }

      // c

      question.addHeading(
        "solution",
        `c) The scores fitting the requirements are $${c_scores.slice(
          0,
          c_scores.length - 1
        )}$ and $${c_scores[c_scores.length - 1]}$`
      );
      question.addParagraph(
        "solution",
        `These occur in the table a total of $${c_total}$ times.`
      );

      if (c_total % 2 === 0 || c_total % 3 === 0) {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of rolling a score that is either ${c_evenOdd} or a multiple of $${c_mulitple}$ (or both) is $\\displaystyle\\frac{${c_total}}{36}$ or $\\displaystyle ${new MCMaths.Fraction(
            c_total / 36
          )}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of rolling a score that is either ${c_evenOdd} or a multiple of $${c_mulitple}$ (or both) is $\\displaystyle\\frac{${c_total}}{36}$`
        );
      }
      // answers

      const g = new MCQuestion.InputGroup(3);
      g.addInput(ways[i - 2] / 36, 0.05, `$(bi)$`);

      g.addInput(ii_ways / 36, 0.05, `$(ii)$`);
      g.addInput(iii_ways / 36, 0.05, `$(iii)$`);
      g.addInput(c_total / 36, 0.05, `$(c)$`);
      question.addInputGroup(g);

      question.requiresSelfMarking();

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ProbabilitySpaces_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const fullTable = [
      [``, ``, ``, ``],
      [``, ``, ``, ``],
      [``, ``, ``, ``],
      [``, ``, ``, ``],
    ];

    for (let i = 1; i < 5; i += 1) {
      for (let j = 1; j < 5; j += 1) {
        fullTable[j - 1][i - 1] = i * j;
      }
    }
    const scores = [1, 2, 3, 4, 6, 8, 9, 12, 16];
    const ways = [1, 2, 2, 3, 2, 2, 1, 2, 1];

    const i_index = MCRandom.randomInt(0, scores.length - 1);
    const i_score = scores[i_index];
    const i_ways = ways[i_index];

    const ii_index = MCRandom.randomInt(1, scores.length - 2);
    const ii_score = scores[ii_index];
    const ii_ways = ways.slice(0, ii_index + 1).reduce((a, b) => a + b, 0);

    const iii_index = MCRandom.randomInt(0, 1);
    const iii_oddEven = ["odd", "even"][iii_index];
    const iii_ways = [4, 12][iii_index];

    const c_low = MCRandom.randomInt(2, 5);
    const c_high = MCRandom.randomInt(9, 13);

    const n = MCRandom.randomInt(100, 500, 50);

    let c_ways = 0;
    const c_scores = [];

    for (let i = 0; i < scores.length; i += 1) {
      if (scores[i] < c_high && scores[i] > c_low) {
        c_scores.push(scores[i]);
        c_ways += ways[i];
      }
    }

    const c_ans = (n * c_ways) / 16;
    /* END VARIABLES AND CONSTANTS */
    if (i_score !== ii_score) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        "question",
        `Two fair spinners with sections labeled $1$ to $4$ are spun and their results are multiplied together to get a score.`
      );
      question.addParagraph(
        "question",
        `a) Construct a two-way table to show all the possible scores.`
      );
      question.addParagraph(
        "question",
        `b) State the probability of getting a score that is:`
      );
      question.addParagraph("question", `i) exactly $${i_score}$`);
      question.addParagraph("question", `ii) at most $${ii_score}$`);
      question.addParagraph("question", `iii) ${iii_oddEven}`);

      question.addParagraph(
        "question",
        `c) State the expected number of scores, $s$ in the range $${c_low}<s<${c_high}$ from $${n}$ spins.`
      );

      // solution
      question.addHeading(
        "solution",
        `a) The complete table should have the following values:`
      );
      const t = new MCQuestion.Table(`$\\times $`, `$1$`, `$2$`, `$3$`, `$4$`);
      t.addRow(
        `$1$`,
        `$${fullTable[0][0]}$`,
        `$${fullTable[0][1]}$`,
        `$${fullTable[0][2]}$`,
        `$${fullTable[0][3]}$`
      );
      t.addRow(
        `$2$`,
        `$${fullTable[1][0]}$`,
        `$${fullTable[1][1]}$`,
        `$${fullTable[1][2]}$`,
        `$${fullTable[1][3]}$`
      );
      t.addRow(
        `$3$`,
        `$${fullTable[2][0]}$`,
        `$${fullTable[2][1]}$`,
        `$${fullTable[2][2]}$`,
        `$${fullTable[2][3]}$`
      );
      t.addRow(
        `$4$`,
        `$${fullTable[3][0]}$`,
        `$${fullTable[3][1]}$`,
        `$${fullTable[3][2]}$`,
        `$${fullTable[3][3]}$`
      );

      question.addTable("solution", t);

      // bi
      if (i_ways === 1) {
        question.addHeading(
          "solution",
          `bi) There are $16$ possible outcomes in the table and $${i_ways}$ way to score $${i_score}$`
        );
      } else {
        question.addHeading(
          "solution",
          `bi) There are $16$ possible outcomes in the table and $${i_ways}$ ways to score $${i_score}$`
        );
      }
      if (16 % i_ways === 0 && i_ways !== 1) {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of scoring exactly $${i_score}$ is $\\displaystyle\\frac{${i_ways}}{16}$ or $\\displaystyle ${new MCMaths.Fraction(
            i_ways / 16
          )}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of scoring exactly $${i_score}$ is $\\displaystyle\\frac{${i_ways}}{16}$`
        );
      }
      // bii
      question.addHeading(
        "solution",
        `bii) There are $${ii_ways}$ ways to score $${ii_score}$ or less.`
      );
      if (ii_ways % 2 === 0) {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of scoring at most $${ii_score}$ is $\\displaystyle\\frac{${ii_ways}}{16}$ or $\\displaystyle ${new MCMaths.Fraction(
            ii_ways / 16
          )}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of scoring at most $${ii_score}$ is $\\displaystyle\\frac{${ii_ways}}{16}$`
        );
      }
      // biii
      question.addHeading(
        "solution",
        `biii) There are $${iii_ways}$ ways to get an ${iii_oddEven} score.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The probability of getting an ${iii_oddEven} score is $\\displaystyle\\frac{${iii_ways}}{16}$ or $\\displaystyle ${new MCMaths.Fraction(
          iii_ways / 16
        )}$`
      );

      // c
      question.addHeading(
        "solution",
        `c) The possible scores in the correct range are $${c_scores.slice(
          0,
          c_scores.length - 1
        )}$ and $${c_scores[c_scores.length - 1]}$`
      );

      question.addParagraph(
        "solution",
        `These occur in the table a total of $${c_ways}$ times.`
      );

      if (c_ways % 2 === 0) {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of rolling a score in the range $${c_low}<s<${c_high}$ is $\\displaystyle\\frac{${c_ways}}{16}$ or $\\displaystyle ${new MCMaths.Fraction(
            c_ways / 16
          )}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of rolling a score in the range $${c_low}<s<${c_high}$ is $\\displaystyle\\frac{${c_ways}}{16}$`
        );
      }

      question.addHeading(
        "solution",
        `Multiply the probability by the number of trials.`
      );

      question.addParagraph(
        `solution`,
        `$\\displaystyle ${new MCMaths.Fraction(c_ways / 16)}(${n})=${c_ans}$`
      );

      question.addParagraph(
        "solution",
        `$\\therefore$ A score in the range $${c_low}<s<${c_high}$ is expected to occur $ ${c_ans.toFixed(
          0
        )}$ times.`
      );
      // answers

      const g = new MCQuestion.InputGroup(3);
      g.addInput(i_ways / 16, 0.05, `$(bi)$`);

      g.addInput(ii_ways / 16, 0.05, `$(ii)$`);
      g.addInput(iii_ways / 16, 0.05, `$(iii)$`);
      g.addInput(parseFloat(c_ans.toFixed(0)), 0, `$(c)$`);
      question.addInputGroup(g);

      question.requiresSelfMarking();

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_ProbabilitySpaces_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const p = MCMisc.getName();
    const charge = 1;

    const avgWin = 2;
    const maxWin = 5;

    const n = MCRandom.randomInt(150, 1000, 150);

    const admissions = parseFloat((n * charge).toFixed(2));
    const wins2 = parseFloat((0.4 * n).toFixed(2));
    const wins5 = parseFloat(((5 * n) / 15).toFixed(2));

    const b_ans = admissions - wins2 - wins5;
    /* END VARIABLES AND CONSTANTS */
    if (b_ans % 1 === 0 && b_ans > 20) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `A game is played with two hats and some tokens.`
      );
      question.addParagraph(
        "question",
        `In the first hat, there are three tokens, one red, one amber and one green.`
      );
      question.addParagraph(
        "question",
        `In the second hat, there are six tokens labeled $0$ to $5$`
      );
      question.addParagraph(
        "question",
        `If you pull the red token from the first hat you get $0$ score, if you get amber then your score is the number you pull from the second hat and if you get green the number you pull from the second hat is doubled to give your score.`
      );

      question.addParagraph(
        "question",
        `a) Complete the table below to show the possible scores.`
      );

      const t = new MCQuestion.Table(
        ``,
        `$0$`,
        `$1$`,
        `$2$`,
        `$3$`,
        `$4$`,
        `$5$`
      );
      t.addRow(`Red`, ``, ``, ``, ``, ``, ``);
      t.addRow(`Amber`, ``, ``, ``, ``, ``, ``);
      t.addRow(`Green`, ``, ``, ``, ``, ``, ``);

      question.addTable("question", t);

      question.addParagraph(
        "question",
        `${p.name} charges $£1$ per try at this game. If you get the maximum possible score of $10$ you win $£5$ and if you get $5$ or above $($not including $10)$ you win $£2$`
      );

      question.addParagraph(
        "question",
        `b) How much money should ${p.name} expect to make from $${n}$ games.`
      );

      // solution
      question.addHeading(
        "solution",
        `a) The complete table should look like the table below.`
      );

      const t2 = new MCQuestion.Table(
        ``,
        `$0$`,
        `$1$`,
        `$2$`,
        `$3$`,
        `$4$`,
        `$5$`
      );
      t2.addRow(`Red`, `$0$`, `$0$`, `$0$`, `$0$`, `$0$`, `$0$`);
      t2.addRow(`Amber`, `$0$`, `$1$`, `$2$`, `$3$`, `$4$`, `$5$`);
      t2.addRow(`Green`, `$0$`, `$2$`, `$4$`, `$6$`, `$8$`, `$10$`);

      question.addTable("solution", t2);
      // b
      question.addHeading(
        "solution",
        `b) Each games costs $£1$ and $${n}$ games are played.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore £${admissions}$ is made from people paying to play.`
      );

      question.addHeading(
        "solution",
        `Calculate the expected money lost from $£2$ payouts.`
      );
      question.addParagraph(
        "solution",
        `If you get a score of $5,6$ or $8$ you win $£2$, which are $3$ possible outcomes of $15$ in the table.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The probability of winning $£2$ is $\\displaystyle \\frac{3}{15}$ or $\\displaystyle \\frac{1}{5}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle\\frac{1}{5}(2)(${n})=${wins2} $`
      );

      question.addHeading(
        "solution",
        `Calculate the expected money lost from $£5$ payouts.`
      );
      question.addParagraph(
        "solution",
        `The probability of winning $£5$ is $\\displaystyle \\frac{1}{15}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle\\frac{1}{15}(5)(${n})=${wins5} $`
      );

      question.addHeading("solution", `Calculate the expected profit.`);

      question.addParagraph(
        "solution",
        `$${admissions}-${wins2}-${wins5}=${b_ans}$`
      );

      question.addParagraph(
        "solution",
        `$\\therefore$ ${p.name} can expect to make $£${b_ans}$`
      );

      const g = new MCQuestion.InputGroup(3);
      g.addInput(b_ans, 0, `$(b)\\ £$`);
      question.addInputGroup(g);

      question.requiresSelfMarking();

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_Randomness_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const bias = MCRandom.randomInt(1, 6);
    const type = ["even", "odd"][MCRandom.randomInt(0, 1)];
    const n = MCRandom.randomInt(30, 300, 30);
    const c = MCRandom.randomInt(1, 6);
    const expected = n / 6;
    const actual = expected + MCRandom.randomInt(n / 15, n / 10);
    const results = [];

    for (let i = 0; i < 5; i += 1) {
      results.push(MCRandom.randomInt(1, 6));
    }
    results.push(bias);
    results.push(bias);
    results.push(bias);

    /* END VARIABLES AND CONSTANTS */
    if (c !== bias && actual < n) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `A fair dice was rolled $8$ times and gave the following results:`
      );
      question.addParagraph("question", `$${results}$`);

      question.addParagraph(
        "question",
        `a) State the probability that a $${bias}$ is rolled next.`
      );
      question.addParagraph(
        "question",
        `b) Calculate the probability that the next roll is an ${type} number.`
      );
      question.addParagraph(
        "question",
        `The dice is rolled another $${n}$ times.`
      );
      question.addParagraph(
        "question",
        `c) How many times, out of the $${n}$, would you expect a $${c}$ to be rolled?`
      );
      question.addParagraph(
        "question",
        `A $${c}$ was rolled $${actual}$ times out of the $${n}$.`
      );
      question.addParagraph(
        "question",
        `d) Give an explanation as to why this value differs from the expected value.`
      );

      // solution
      question.addHeading(
        "solution",
        `a) Every outcome is equally likely as it's a fair dice, even after a run of $${bias}$'s.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The probability of rolling a $${bias}$ is $\\displaystyle\\frac{1}{6}$`
      );
      // b
      if (type === "even") {
        question.addHeading(
          "solution",
          `b) Out of the $6$ outcomes, $3$ are even $(2, 4 $ and $ 6)$`
        );
      } else {
        question.addHeading(
          "solution",
          `b) Out of the $6$ outcomes, $3$ are odd $(1, 3 $ and $ 5)$`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ The probability of rolling an ${type} number is $\\displaystyle\\frac{1}{2}$`
      );
      // c
      question.addHeading(
        "solution",
        `c) The probability of rolling a $${c}$ is $\\displaystyle\\frac{1}{6}$, as it's a fair dice.`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle\\frac{1}{6}(${n})=${expected}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ We would expect to roll a $${c}$ excatly $${expected}$ times out of the $${n}$`
      );
      // d
      question.addHeading(
        "solution",
        `d) We know that the dice is fair so it can't be due to bias.`
      );
      question.addParagraph(
        "solution",
        `Rolling a dice is a random process so this outcome happened by chance.`
      );
      // answers

      const a1 = new MCQuestion.InputGroup(1);
      a1.addInput(1 / 6, 0.05, `$(a)$`);
      a1.addInput(1 / 2, 0, `$(b)$`);
      a1.addInput(expected, 0, `$(c)$`);
      question.addInputGroup(a1);

      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_Randomness_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const times = MCRandom.randomInt(20, 200, 10);

    const p1 = new MCMisc.getName();
    const p2 = new MCMisc.getName();

    const n1 = 5 + MCRandom.randomInt(-1, 1, 2);
    const n2 = MCRandom.randomInt(0, 1);
    /* END VARIABLES AND CONSTANTS */
    if (p1.name !== p2.name) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A fair coin is flipped $3$ times and lands on tails all $3$ times.`
      );
      question.addParagraph(
        "question",
        `a) State the probability that the next flip also lands on tails.`
      );
      question.addParagraph(
        "question",
        `b) Calculate the number of times you'd expect to get tails after $${times}$ throws.`
      );
      if (n2 === 0) {
        question.addParagraph(
          "question",
          `${p1.name} and ${p2.name} flip their own coins $10$ times each. ${p1.name} gets heads $${n1}$ times while ${p2.name} doesn't get heads at all.`
        );
      } else {
        question.addParagraph(
          "question",
          `${p1.name} and ${p2.name} flip their own coins $10$ times each. ${p1.name} gets heads $${n1}$ times while ${p2.name} only gets heads once.`
        );
      }
      question.addParagraph(
        "question",
        `c) Given a possible explanation for their results.`
      );

      // solution

      question.addHeading(
        "solution",
        `a) A tails is still just as likely, even after a run of them.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The probability of getting a tails is $0.5$, as the coin is fair.`
      );
      // b
      question.addHeading(
        "solution",
        `b) The probability of getting a tails is $0.5$.`
      );
      question.addParagraph("solution", `$0.5(${times})=${times / 2}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ We would expect to get tails $${
          times / 2
        }$ times out of the $${times}$`
      );
      // c
      question.addHeading("solution", `c) Possible explanations are:`);
      question.addParagraph(
        "solution",
        `Their coins are biased as neither got the expected amount of heads. This is more likely to be the explanation for ${p2.name} due to the more significant difference between expected number and actual number.`
      );
      question.addParagraph(
        "solution",
        `The outcomes were due to chance alone.`
      );

      const a1 = new MCQuestion.InputGroup(1);
      a1.addInput(0.5, 0, `$(a)$`);
      a1.addInput(times / 2, 0, `$(b)$`);
      question.addInputGroup(a1);

      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_Randomness_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const win = new MCMaths.Fraction(MCRandom.randomInt(1, 2) / 8);
    const lose = new MCMaths.Fraction(MCRandom.randomInt(3, 5) / 8);
    const draw = new MCMaths.Fraction(1).subtract(win).subtract(lose);

    const n = MCRandom.randomInt(40, 240, 40);

    const person = MCMisc.getName();

    const profit = MCRandom.randomInt(10, 50, 10);

    const expectedWin = new MCMaths.Fraction(n).multiply(win);
    const expectedLoss = new MCMaths.Fraction(n).multiply(lose);
    const expectedMoney = expectedWin
      .multiply(new MCMaths.Fraction(-profit / 100))
      .add(expectedLoss.multiply(new MCMaths.Fraction(profit / 100)));

    /* END VARIABLES AND CONSTANTS */
    if (true) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `${person.name} takes bets on a spinner with $3$ possible outcomes. You can either win, lose  or draw.`
      );
      question.addParagraph(
        `question`,
        `${person.name} knows with certainty that the probability you will win is $\\displaystyle${win}$ and the probability that you will lose is $\\displaystyle${lose}$.`
      );
      question.addParagraph(
        "question",
        `a) Calculate the probability that you draw.`
      );
      question.addParagraph(
        "question",
        `b) State the expected number of wins from $${n}$ spins.`
      );
      question.addParagraph(
        "question",
        `Every time you lose, ${person.name} profits $${profit}$p, but every time you win ${person.name} loses $${profit}$p. Drawing means that you both break even.`
      );
      question.addParagraph(
        "question",
        `c) How much money should ${person.name} expect to make from $${n}$ spins. Give your answer in pounds.`
      );
      question.addParagraph(
        "question",
        `After $${n}$ spins ${person.name} only managed to break even.`
      );
      question.addParagraph(
        "question",
        `d) Give a reason as to why this might have happened.`
      );
      // solution

      // a
      question.addHeading("solution", `a) Probabilities must add to $1$`);
      question.addParagraph(
        "solution",
        `$1-\\displaystyle${win}-${lose}=${draw}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The probability of a draw is $\\displaystyle ${draw}$`
      );
      // b

      question.addHeading(
        `solution`,
        `b) The probability of winning is $\\displaystyle${win}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle${win}(${n})=${expectedWin} $`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ We would expect to win $${expectedWin}$ times out of $${n}$`
      );
      // c

      question.addHeading(
        "solution",
        `c) Calculate the expected number of losses.`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle${lose}(${n})=${expectedLoss} $`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ We would expect to lose $${expectedLoss}$ times out of $${n}$`
      );
      question.addHeading(
        "solution",
        `Subtract the expected number of wins from this result.`
      );
      question.addParagraph(
        "solution",
        `$${expectedLoss}-${expectedWin}=${expectedLoss.subtract(expectedWin)}$`
      );
      question.addHeading(
        "solution",
        `Multiply by $£${(profit / 100).toFixed(
          2
        )}$ to get the expected profit for ${person.name}.`
      );
      question.addParagraph(
        "solution",
        `$${profit / 100}(${expectedLoss.subtract(
          expectedWin
        )})= ${expectedMoney.toFloat()}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ ${
          person.name
        } would expect to make $£${expectedMoney
          .toFloat()
          .toFixed(2)}$ every $${n}$ spins.`
      );
      // d

      question.addHeading(
        "solution",
        `d) As we know the probability of each event we can assume the spinner is not biased.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The outcome must be due to chance alone.`
      );

      const a1 = new MCQuestion.InputGroup(1);
      a1.addInput(draw.toFloat(), 0.05, `$(a)$`);
      a1.addInput(expectedWin.toFloat(), 0, `$(b)$`);
      a1.addInput(expectedMoney.toFloat(), 0.05, `$(c)$`);
      question.addInputGroup(a1);

      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_Randomness_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const science = MCRandom.randomInt(5, 20);
    const maths = MCRandom.randomInt(5, 20);
    const english = MCRandom.randomInt(5, 20);
    const pe = MCRandom.randomInt(5, 20);
    const history = MCRandom.randomInt(5, 20);

    const total = science + maths + english + pe + history;

    const subjects = ["Science", "Maths", "English", "P.E", "History"];
    const subjectTotals = [science, maths, english, pe, history];

    const prev = [];
    const index = MCRandom.randomInt(0, 4);
    const index2 = MCRandom.randomInt(0, 4);
    const run = subjects[index];
    const subjectB = subjects[index2];
    for (let i = 0; i < 3; i += 1) {
      prev.push(subjects[MCRandom.randomInt(0, 4)]);
    }
    prev.push(run);
    prev.push(run);
    prev.push(run);

    const n = MCRandom.randomInt(5, 52);
    const a_ans = subjectTotals[index] / total;
    const b_prob = subjectTotals[index2] / total;

    /* END VARIABLES AND CONSTANTS */
    if (
      run !== subjectB &&
      a_ans > 0.05 &&
      b_prob > 0.05 &&
      (b_prob * n) % 1 === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        "question",
        `A number of cards each labeled with a subject are placed in a box. The number of each card are shown below.`
      );
      const t = new MCQuestion.Table(`Subject`, `No. in box`);
      t.addRow(`Science`, `$${science}$`);
      t.addRow(`Maths`, `$${maths}$`);
      t.addRow(`English`, `$${english}$`);
      t.addRow(`P.E`, `$${pe}$`);
      t.addRow(`History`, `$${history}$`);
      question.addTable("question", t);

      question.addParagraph(
        "question",
        `Each week a card is drawn from the box, and then replaced. The results from the previous $6$ weeks are:`
      );
      question.addParagraph("question", `${prev.slice(0, 5)} and ${prev[5]}.`);

      question.addParagraph(
        "question",
        `a) Calculate the probability of ${run} being the next pick.`
      );
      question.addParagraph(
        "question",
        `b) State the number of times you would expect to see ${subjectB} in the next $${n}$ weeks.`
      );

      // a

      question.addHeading(
        `solution`,
        `a) Calculate the total number of cards in the box.`
      );
      question.addParagraph(
        "solution",
        `$${science}+${maths}+${english}+${pe}+${history}=${total}$`
      );
      question.addHeading("solution", `Calculate the probability for ${run}.`);
      if (parseFloat(a_ans.toFixed(3)) !== a_ans) {
        question.addParagraph(
          "solution",
          `$\\displaystyle \\frac{${
            subjectTotals[index]
          }}{${total}}\\approx${parseFloat(a_ans.toFixed(3))}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle \\frac{${subjectTotals[index]}}{${total}}=${a_ans}$`
        );
      }
      // b
      question.addHeading(
        "solution",
        `b) Calculate the probability for ${subjectB}.`
      );
      if (parseFloat(b_prob.toFixed(3)) !== b_prob) {
        question.addParagraph(
          "solution",
          `$\\displaystyle \\frac{${
            subjectTotals[index2]
          }}{${total}}\\approx${parseFloat(b_prob.toFixed(3))}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle \\frac{${subjectTotals[index2]}}{${total}}=${b_prob}$`
        );
      }
      question.addHeading(
        "solution",
        `Multiply by the number of weeks to get the expected number.`
      );

      question.addParagraph(
        "solution",
        `$\\displaystyle \\frac{${subjectTotals[index2]}}{${total}}(${n})=${
          n * b_prob
        }$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore $You would expect to see ${subjectB} $${
          n * b_prob
        }$ times in the next $${n}$ weeks.`
      );

      const a1 = new MCQuestion.InputGroup(1);
      a1.addInput(a_ans, 0.05, `$(a)$`);
      a1.addInput(b_prob * n, 0, `$(b)$`);
      question.addInputGroup(a1);

      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_SumsOfProbabilities_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = MCRandom.randomInt(1, 6);
    const oddeven = ["odd", "even"][MCRandom.randomInt(0, 1)];
    const rolls = MCRandom.randomInt(50, 200, 10);
    const n2 = MCRandom.randomInt(1, 6);

    const p1 = new MCMaths.Fraction(MCRandom.randomInt(1, 4) / 12);
    const p2 = new MCMaths.Fraction(MCRandom.randomInt(1, 4) / 12);
    const p3 = new MCMaths.Fraction(MCRandom.randomInt(1, 4) / 12);
    const p4 = new MCMaths.Fraction(MCRandom.randomInt(1, 4) / 12);
    const p5 = new MCMaths.Fraction(MCRandom.randomInt(1, 4) / 12);
    const p6 = new MCMaths.Fraction(1)
      .subtract(p1)
      .subtract(p2)
      .subtract(p3)
      .subtract(p4)
      .subtract(p5);

    const allProbs = [p1, p2, p3, p4, p5, p6];
    const probs = [p1, p2, p3, p4, p5, p6];
    probs[n - 1] = `x`;

    const c = new MCMaths.Fraction(1).subtract(allProbs[n - 1]);

    const odd = p1.add(p3).add(p5);
    const even = p2.add(p4).add(p6);

    const d_ans = new MCMaths.Fraction(rolls).multiply(allProbs[n2 - 1]);

    const b1 = MCRandom.randomInt(1, 6);
    const b2 = MCRandom.randomInt(1, 6);

    const b_ans = allProbs[b1 - 1].add(allProbs[b2 - 1]);
    /* END VARIABLES AND CONSTANTS */
    if (
      n !== n2 &&
      p6.toFloat() >= 1 / 12 &&
      n !== b1 &&
      n !== b2 &&
      b1 !== b2 &&
      b1 < b2
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const g = new MCQuestion.InputGroup(1);

      question.addParagraph(
        "question",
        `The table below shows the probabilities of getting the numbers $1$ to $6$ with a biased dice.`
      );
      const t = new MCQuestion.Table(
        `Number`,
        `$1$`,
        `$2$`,
        `$3$`,
        `$4$`,
        `$5$`,
        `$6$`
      );
      t.addRow(
        "Probability",
        `$\\displaystyle ${probs[0]}$`,
        `$\\displaystyle ${probs[1]}$`,
        `$\\displaystyle ${probs[2]}$`,
        `$\\displaystyle ${probs[3]}$`,
        `$\\displaystyle ${probs[4]}$`,
        `$\\displaystyle ${probs[5]}$`
      );
      question.addTable("question", t);

      question.addParagraph("question", `a) Calculate the value of $x$.`);
      question.addParagraph(
        "question",
        `b) Find the probability of rolling a $${b1}$ or a $${b2}$`
      );
      question.addParagraph(
        "question",
        `c) Find the probability of rolling an ${oddeven} number.`
      );
      question.addParagraph("question", `The dice is rolled $${rolls}$ times.`);
      question.addParagraph(
        "question",
        `d) State the number of times you would expect to roll a $${n2}$`
      );

      // solution

      question.addHeading("solution", `a) Probabilities must sum to $1$`);
      question.addParagraph(
        "solution",
        `$\\displaystyle ${probs.join("+")}=1$`
      );
      question.addParagraph("solution", `$\\displaystyle x+${c}=1$`);
      question.addParagraph("solution", `$\\displaystyle x=1-${c}$`);
      question.addParagraph(
        "solution",
        `$\\therefore \\displaystyle x=${allProbs[n - 1]}$`
      );

      // b
      question.addHeading(
        "solution",
        `b) Sum the probabilities for rolling $${b1}$ and $${b2}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle ${allProbs[b1 - 1]}+${allProbs[b2 - 1]}=${b_ans}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The probability of rolling a $${b1}$ or a $${b2}$ is $\\displaystyle ${b_ans}$`
      );

      g.addInput(allProbs[n - 1].toFloat(), 0.05, `$(a)$`);
      g.addInput(b_ans.toFloat(), 0.05, `$(b)$`);
      // c
      if (oddeven === "odd") {
        question.addHeading(
          "solution",
          `c) Sum the probabilities for rolling $1,3$ and $5$`
        );
        question.addParagraph(
          "solution",
          `$\\displaystyle ${p1}+${p3}+${p5}=${odd}$`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of rolling an odd number is $\\displaystyle ${odd}$`
        );
        g.addInput(odd.toFloat(), 0.05, `$(c)$`);
      } else {
        question.addHeading(
          "solution",
          `c) Sum the probabilities for rolling $2,4$ and $6$`
        );
        question.addParagraph(
          "solution",
          `$\\displaystyle ${p2}+${p4}+${p6}=${even}$`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of rolling an even number is $\\displaystyle ${even}$`
        );
        g.addInput(even.toFloat(), 0.05, `$(c)$`);
      }
      // d

      question.addHeading(
        `solution`,
        `d) Multiply the probability of rolling a $${n2}$ by the number of trials.`
      );
      if (parseFloat(d_ans.toFloat().toFixed(3)) === d_ans.toFloat()) {
        question.addParagraph(
          "solution",
          `$\\displaystyle ${allProbs[n2 - 1]}(${rolls})=${d_ans.toFloat()}$`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ You would expect a $${n2}$ to be rolled $${d_ans
            .toFloat()
            .toFixed(0)}$ times.`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle ${allProbs[n2 - 1]}(${rolls})\\approx${parseFloat(
            d_ans.toFloat().toFixed(3)
          )}$`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ You would expect a $${n2}$ to be rolled $${parseFloat(
            d_ans.toFloat().toFixed(0)
          )}$ times.`
        );
      }

      // answers

      g.addInput(parseFloat(d_ans.toFloat().toFixed(0)), 0, `$(d)$`);

      question.addInputGroup(g);
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_SumsOfProbabilities_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const common = new MCMaths.Fraction(MCRandom.randomInt(45, 60) / 100);
    const ultraRare = new MCMaths.Fraction(MCRandom.randomInt(2, 5) / 100);

    const likely = ["twice", `three times`][MCRandom.randomInt(0, 1)];

    let uncommon = 0;
    let rare = 0;

    if (likely === "twice") {
      rare = new MCMaths.Fraction(1)
        .subtract(common)
        .subtract(ultraRare)
        .divide(new MCMaths.Fraction(3));
      uncommon = rare.multiply(new MCMaths.Fraction(2));
    } else {
      rare = new MCMaths.Fraction(1)
        .subtract(common)
        .subtract(ultraRare)
        .divide(new MCMaths.Fraction(4));
      uncommon = rare.multiply(new MCMaths.Fraction(3));
    }
    const cPlusUR = common.add(ultraRare);
    const cPlusUC = common.add(uncommon);
    const index = MCRandom.randomInt(0, 1);
    const c = ["rare", `ultra rare`][index];
    const a = ["a", "an"][index];

    const n = MCRandom.randomInt(100, 500, 50);

    const b_ans = cPlusUC.multiply(new MCMaths.Fraction(n));

    const c_ans = new MCMaths.Fraction(1).divide(
      [rare.toFloat(), ultraRare.toFloat()][index]
    );
    /* END VARIABLES AND CONSTANTS */
    if (
      parseFloat(rare.toFloat().toFixed(2)) === rare.toFloat() &&
      b_ans.toFloat() % 1 === 0
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const t = new MCQuestion.Table(
        `Rarity`,
        `Common`,
        `Uncommon`,
        `Rare`,
        `Ultra Rare`
      );
      t.addRow(
        "Probability",
        `$${common.toFloat()}$`,
        ``,
        ``,
        `$${ultraRare.toFloat()}$`
      );
      question.addTable("question", t);

      question.addParagraph(
        "question",
        `The table above shows the likelihood of getting $4$ different types of items in a mystery box.`
      );
      question.addParagraph(
        "question",
        `Given that you are ${likely} as likely to get an uncommon as you are to get rare;`
      );
      question.addParagraph("question", `a) Complete the table.`);
      question.addParagraph(
        "question",
        `b) Calculate the number of common or uncommon items you would expect to get from $${n}$ mystery boxes.`
      );
      question.addParagraph(
        "question",
        `c) State the number of boxes you expect to need to open to get ${a} ${c} item.`
      );
      // a

      question.addHeading(
        "solution",
        `a) Let the probability of getting a rare be $x$.`
      );
      if (likely === "twice") {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of getting an uncommon is $2x$.`
        );
        question.addHeading("solution", `Probabilities must sum to $1$`);
        question.addParagraph(
          "solution",
          `$${common.toFloat()}+2x+x+${ultraRare.toFloat()}=1$`
        );
        question.addParagraph("solution", `$3x+${cPlusUR.toFloat()}=1$`);
        question.addParagraph(
          "solution",
          `$3x=${new MCMaths.Fraction(1).subtract(cPlusUR).toFloat()}$`
        );
        question.addParagraph("solution", `$ x=${rare.toFloat()}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of getting an uncommon is $${uncommon.toFloat()}$ and the probability of getting a rare is $${rare.toFloat()}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of getting an uncommon is $3x$.`
        );
        question.addHeading("solution", `Probabilities must sum to $1$`);
        question.addParagraph(
          "solution",
          `$${common.toFloat()}+3x+x+${ultraRare.toFloat()}=1$`
        );
        question.addParagraph("solution", `$4x+${cPlusUR.toFloat()}=1$`);
        question.addParagraph(
          "solution",
          `$4x=${new MCMaths.Fraction(1).subtract(cPlusUR).toFloat()}$`
        );
        question.addParagraph("solution", `$ x=${rare.toFloat()}$`);
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of getting an uncommon is $${uncommon.toFloat()}$ and the probability of getting a rare is $${rare.toFloat()}$`
        );
      }

      const t2 = new MCQuestion.Table(
        `Rarity`,
        `Common`,
        `Uncommon`,
        `Rare`,
        `Ultra Rare`
      );
      t2.addRow(
        "Probability",
        `$${common.toFloat()}$`,
        `$${uncommon.toFloat()}$`,
        `$${rare.toFloat()}$`,
        `$${ultraRare.toFloat()}$`
      );
      question.addTable("solution", t2);

      // b
      question.addHeading(
        `solution`,
        `b) Sum the probabilities of getting a common and uncommon.`
      );
      question.addParagraph(
        "solution",
        `$${common.toFloat()}+${uncommon.toFloat()}=${cPlusUC.toFloat()}$`
      );
      question.addHeading(
        "solution",
        `Multiply this probability by the number of trials.`
      );

      question.addParagraph(
        "solution",
        `$${cPlusUC.toFloat()}(${n})=${b_ans.toFloat()}$`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ You would expect to get $${b_ans.toFloat()}$ common or uncommons.`
      );

      // c

      question.addHeading(
        "solution",
        `c) Find $n$, the number of boxes before your expected number of ${c}s is $1$.`
      );

      question.addParagraph(
        "solution",
        `$${[rare, ultraRare][index].toFloat()}n=1$`
      );
      question.addParagraph(
        "solution",
        `$n=\\displaystyle \\frac{1}{${[rare, ultraRare][index].toFloat()}}$`
      );
      if (parseFloat(c_ans.toFloat().toFixed(3)) === c_ans.toFloat()) {
        question.addParagraph("solution", `$=${c_ans.toFloat()}$`);
      } else {
        question.addParagraph(
          "solution",
          `$\\approx ${parseFloat(c_ans.toFloat().toFixed(3))}$`
        );
      }
      question.addParagraph(
        "solution",
        `$\\therefore$ You would need to open $${parseFloat(
          c_ans.toFloat().toFixed(0)
        )}$ boxes to get ${a} ${c}.`
      );

      // answers

      const g = new MCQuestion.InputGroup(2);
      g.addInput(uncommon.toFloat(), 0.05, `$(a)\\ $Uncommon$=$`);
      g.addInput(rare.toFloat(), 0.05, `$(a)\\ Rare=$`);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(b_ans.toFloat(), 0, `$(b)$`);
      g2.addInput(parseFloat(c_ans.toFloat().toFixed(0)), 0, `$(c)$`);

      question.addInputGroup(g);
      question.addInputGroup(g2);
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_SumsOfProbabilities_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const opt1 = MCRandom.randomInt(1, 3);
    const opt2 = MCRandom.randomInt(1, 3);
    const opt3 = MCRandom.randomInt(1, 3);
    const opt4 = MCRandom.randomInt(1, 3);
    const opt5 = MCRandom.randomInt(1, 3);

    const index = MCRandom.randomInt(0, 1);
    const oddEven = ["odd", "even"][index];
    const c1 = MCRandom.randomInt(1, 5);
    const c2 = MCRandom.randomInt(1, 5);

    const n = MCRandom.randomInt(50, 250, 10);

    const coefficients = [opt1, opt2, opt3, opt4, opt5];

    const x = new MCMaths.Fraction(1 / coefficients.reduce((a, b) => a + b, 0));

    const b_ans = [
      x.multiply(new MCMaths.Fraction(opt1 + opt3 + opt5)),
      x.multiply(new MCMaths.Fraction(opt2 + opt4)),
    ][index];

    const pc = x.multiply(
      new MCMaths.Fraction(coefficients[c1 - 1] + coefficients[c2 - 1])
    );

    const c_ans = pc.multiply(new MCMaths.Fraction(n));
    /* END VARIABLES AND CONSTANTS */
    if (c1 < c2 && (c1 !== 2 || c2 !== 4)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(
        `question`,
        `The probability of a spinner landing on the numbers $1$ to $5$ are given, in terms of $x$ , in the table below.`
      );
      const t = new MCQuestion.Table(
        `Number`,
        `$1$`,
        `$2$`,
        `$3$`,
        `$4$`,
        `$5$`
      );
      t.addRow(
        `Probability`,
        MCMaths.cleaner(`$${opt1}x$`),
        MCMaths.cleaner(`$${opt2}x$`),
        MCMaths.cleaner(`$${opt3}x$`),
        MCMaths.cleaner(`$${opt4}x$`),
        MCMaths.cleaner(`$${opt5}x$`)
      );
      question.addTable("question", t);

      question.addParagraph("question", `a) Find $x$.`);
      question.addParagraph(
        "question",
        `b) Find the probability of the spinner landing on an ${oddEven} number.`
      );
      question.addParagraph(
        "question",
        `c) From $${n}$ spins, how many times would you expect to get $${c1}$ or $${c2}?$`
      );

      // solution

      question.addHeading("solution", `a) Probabilities must sum to $1$`);
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${coefficients.join(`x+`)}x=1$`)
      );
      question.addParagraph(
        "solution",
        `$${new MCMaths.Fraction(1).divide(x)}x=1$`
      );
      question.addParagraph("solution", `$\\therefore \\displaystyle x=${x}$`);

      // b

      if (oddEven === "odd") {
        question.addHeading(
          "solution",
          `b) Sum the probabilities for rolling $1,3$ and $5$`
        );
        question.addParagraph(
          "solution",
          `$\\displaystyle ${x.multiply(
            new MCMaths.Fraction(opt1)
          )}+${x.multiply(new MCMaths.Fraction(opt3))}+${x.multiply(
            new MCMaths.Fraction(opt5)
          )}=${b_ans}$`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of rolling an odd number is $\\displaystyle ${b_ans}$`
        );
      } else {
        question.addHeading(
          "solution",
          `b) Sum the probabilities for rolling $2$ and $4$`
        );
        question.addParagraph(
          "solution",
          `$\\displaystyle ${x.multiply(
            new MCMaths.Fraction(opt2)
          )}+${x.multiply(new MCMaths.Fraction(opt4))}=${b_ans}$`
        );
        question.addParagraph(
          "solution",
          `$\\therefore$ The probability of rolling an even number is $\\displaystyle ${b_ans}$`
        );
      }
      // c

      question.addHeading(
        "solution",
        `c) Sum the probabilities of rolling $${c1}$ or $${c2}$`
      );
      question.addParagraph(
        "solution",
        `$\\displaystyle ${x.multiply(
          new MCMaths.Fraction(coefficients[c1 - 1])
        )}+${x.multiply(new MCMaths.Fraction(coefficients[c2 - 1]))}=${pc}$`
      );
      question.addHeading("solution", `Multiply by the number of trials.`);
      if (parseFloat(c_ans.toFloat().toFixed(3)) !== c_ans.toFloat()) {
        question.addParagraph(
          "solution",
          `$\\displaystyle ${pc}(${n})\\approx${parseFloat(
            c_ans.toFloat().toFixed(3)
          )}$`
        );
      } else {
        question.addParagraph(
          "solution",
          `$\\displaystyle ${pc}(${n})=${c_ans.toFloat()}$`
        );
      }

      question.addParagraph(
        "solution",
        `$\\therefore$ You would expect a $${c1}$ or $${c2}$ to be spun $${c_ans
          .toFloat()
          .toFixed(0)} $ times.`
      );

      // answers

      const g = new MCQuestion.InputGroup(1);
      g.addInput(x.toFloat(), 0.05, `$(a)$`);
      g.addInput(b_ans.toFloat(), 0.05, `$(b)$`);
      g.addInput(parseFloat(c_ans.toFloat().toFixed(0)), 0, `$(c)$`);
      question.addInputGroup(g);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Probability_SumsOfProbabilities_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const lose = parseFloat((MCRandom.randomInt(20, 40) / 100).toFixed(2));
    const win = parseFloat((MCRandom.randomInt(20, 40) / 100).toFixed(2));
    const draw = parseFloat((1 - lose - win).toFixed(2));

    const notLose = parseFloat((1 - lose).toFixed(2));

    const games = MCRandom.randomInt(32, 48, 2);

    const eWins = parseFloat((win * games).toFixed(2));
    const eDraws = parseFloat((draw * games).toFixed(2));

    const winPoints = 3 * parseFloat(eWins.toFixed(0));
    const drawPoints = parseFloat(eDraws.toFixed(0));
    const points = winPoints + drawPoints;

    const win2 = parseFloat((MCRandom.randomInt(30, 50) / 100).toFixed(2));
    const times = MCRandom.randomInt(2, 4);

    const draw2 = parseFloat(((1 - win2) / (times + 1)).toFixed(2));
    const lose2 = parseFloat((times * draw2).toFixed(2));

    const eWins2 = parseFloat((win2 * games).toFixed(2));
    const eDraws2 = parseFloat((draw2 * games).toFixed(2));

    const winPoints2 = 3 * parseFloat(eWins2.toFixed(0));
    const drawPoints2 = parseFloat(eDraws2.toFixed(0));
    const points2 = winPoints2 + drawPoints2;

    /* END VARIABLES AND CONSTANTS */
    if (
      win !== draw &&
      draw2 + lose2 + win2 === 1 &&
      win2 !== draw2 &&
      points !== points2
    ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph(
        `question`,
        `The probability that a football team, York FC, win a game is $${win}$ and the probability that they draw it is $${draw}$`
      );
      question.addParagraph(
        "question",
        `a) Find the probability that they lose their next game.`
      );
      question.addParagraph(
        "question",
        `Given that you earn $3$ points for a win, $1$ for a draw and $0$ for a loss;`
      );
      question.addParagraph(
        "question",
        `b) Find the expected points tally for the team at the end of a $${games}$ game season.`
      );

      question.addParagraph(
        "question",
        `Another team, Doncaster FC, in the same league win with a probability of $${win2}$ and are $${times}$ times as likely to lose as they are to draw. They also play $${games}$ games in their season.`
      );

      question.addParagraph(
        "question",
        `c) By calculating the expected points tally for Doncaster FC, determine whether they are expected to finish above York FC in the league.`
      );

      // solution

      // a

      question.addHeading(
        "solution",
        `a) Probabilities must sum to $1$, let the probability of a loss be $x$.`
      );
      question.addParagraph("solution", `$${win}+${draw}+x=1$`);
      question.addParagraph(
        "solution",
        `$x+${parseFloat((win + draw).toFixed(2))}=1$`
      );
      question.addParagraph("solution", `$\\therefore x=${lose}$`);

      // b

      question.addHeading("solution", `b) Find the expected number of wins.`);
      question.addParagraph("solution", `$${win}(${games})=${eWins}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ You would expect $${eWins.toFixed(
          0
        )}$ wins which would get them $${winPoints}$ points.`
      );

      question.addHeading("solution", `Find the expected number of draws.`);
      question.addParagraph("solution", `$${draw}(${games})=${eDraws}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ You would expect $${eDraws.toFixed(
          0
        )}$ draws which would get them $${drawPoints}$ points.`
      );

      question.addParagraph(
        "solution",
        `So, York FC would be expected to finish on $${points}$ points.`
      );

      // c

      question.addHeading(
        "solution",
        `c) Let the probability of a draw be $x$.`
      );
      question.addParagraph(
        "solution",
        `$\\therefore$ The probability of a loss is $${times}x$.`
      );
      question.addHeading("solution", `Probabilities must sum to $1$`);
      question.addParagraph("solution", `$${win2}+x+${times}x=1$`);
      question.addParagraph(
        "solution",
        `$${1 + times}x=${parseFloat((1 - win2).toFixed(2))}$`
      );
      question.addParagraph("solution", `$\\therefore x=${draw2}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ The probability of York FC drawing is $${draw2}$ and the probability of them losing is $${lose2}$`
      );

      question.addHeading("solution", `Find the expected number of wins.`);
      question.addParagraph("solution", `$${win2}(${games})=${eWins2}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ You would expect $${eWins2.toFixed(
          0
        )}$ wins which would get them $${winPoints2}$ points.`
      );

      question.addHeading("solution", `Find the expected number of draws.`);
      question.addParagraph("solution", `$${draw2}(${games})=${eDraws2}$`);
      question.addParagraph(
        "solution",
        `$\\therefore$ You would expect $${eDraws2.toFixed(
          0
        )}$ draws which would get them $${drawPoints2}$ points.`
      );

      if (points > points2) {
        question.addParagraph(
          "solution",
          `So, Doncaster FC would be expected to finish on $${points2}$ points and below York FC.`
        );
      } else {
        question.addParagraph(
          "solution",
          `So, Doncaster FC would be expected to finish on $${points2}$ points and above York FC.`
        );
      }

      // answers

      const g = new MCQuestion.InputGroup(1);
      g.addInput(lose, 0.005, `$(a)$`);
      g.addInput(points, 0, `$(b)$`);
      g.addInput(points2, 0, `$(c)$`);
      question.addInputGroup(g);

      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_ProbabilityAndStatistics_Statistics_StatisticalMeasures_Exam_Q1() {
  let count = 0;
  const img_scale = 3;

  for (let i = 1; i <= 1; i += 1) {
    const mode = (arr) => {
      if (
        arr.filter((x, index) => arr.indexOf(x) === index).length === arr.length
      )
        return arr;
      return mode(
        arr
          .sort((x, index) => x - index)
          .map((x, index) => (arr.indexOf(x) !== index ? x : null))
          .filter((x) => x !== null)
      );
    };

    const marks = [];
    const max = 50;
    for (let j = 0; j <= 9; j += 1) {
      marks.push(MCRandom.randomInt(7, max - 1));
    }
    const subjects = ["French", "Spanish", "German"];
    const subject = subjects[MCRandom.randomInt(0, 2)];
    let mean = 0;
    let sum = 0;
    for (let z = 0; z <= 9; z += 1) {
      sum += marks[z];
    }
    mean = sum / marks.length;
    const mod = mode(marks);
    const percentage = MCRandom.randomInt(70, 90);
    const marksByPercentage = (max * percentage) / 100;
    let passes = 0;
    const passMarks = [];
    let sum2 = 0;
    for (let m = 0; m <= 9; m += 1) {
      if (marks[m] >= marksByPercentage) {
        passes += 1;
        passMarks.push(marks[m]);
        sum2 = marks[m] + sum2;
      }
    }
    const mean2 = sum2 / passes;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A list of mark acheived by students in a ${subject} spelling test has been created.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${marks}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find the mean score of the marks.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the mode score of the marks.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The test has a maximum score of $50$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ Find the number of students who scored $${percentage}\\%$ or more.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(d)$ Find the mean number of marks scored by students who scored $${percentage}\\%$ or more.`,
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Sum of all scores $= ${sum}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Mean $=$ sum $ \\div$ number of students $= ${sum}  \\div 10 = ${mean}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Mode $=$ most common score $= ${mod}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(c)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${percentage}\\%$ mark $=$ percentage multiplier $ \\times$ max score $= ${
          percentage / 100
        }  \\times ${max} = ${marksByPercentage}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Number of students that scored more than $${marksByPercentage}$ marks $= ${passes}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(d)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Marks of students that scored higher than $${percentage}\\% = ${passMarks}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Sum of the above scores $= ${sum2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Mean of the above scores $=$ sum of marks $ \\div$ number of students $= ${sum2}  \\div ${passes} = ${mean2}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(mean, 0.1, "$(a)$");
    group.addInput(mod, 0.01, "$(b)$");
    group.addInput(passes, 0.01, "$(c)$");
    group.addInput(mean2, 0.1, "$(d)$");
    question.addInputGroup(group);

    if (
      passes <= 2 ||
      mod.length !== 1 ||
      marksByPercentage !== Math.round(marksByPercentage) ||
      percentage / 2 !== Math.round(percentage / 2) ||
      Math.round(100 * mean) !== mean * 100
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Statistics_StatisticalMeasures_Exam_Q2() {
  let count = 0;
  const img_scale = 3;

  for (let i = 1; i <= 1; i += 1) {
    const mode = (arr) => {
      if (
        arr.filter((x, index) => arr.indexOf(x) === index).length === arr.length
      )
        return arr;
      return mode(
        arr
          .sort((x, index) => x - index)
          .map((x, index) => (arr.indexOf(x) !== index ? x : null))
          .filter((x) => x !== null)
      );
    };

    function range(numbers) {
      numbers.sort();
      return {
        smallest: numbers[0],
        largest: numbers[numbers.length - 1],
      };
    }

    const results = [];
    const turns = MCRandom.randomInt(12, 16);
    for (let z = 1; z <= turns; z += 1) {
      results.push(MCRandom.randomInt(1, 5));
    }
    const clone = results.slice(0);
    const rang = range(results);
    const acRange = rang.largest - rang.smallest;
    const mod = mode(results);
    let sum = 0;
    for (let m = 1; m <= results.length; m += 1) {
      sum += results[m - 1];
    }
    const mean = sum / turns;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "A spinner has the numbers $1$ to $5$ printed on it.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The spinner is spun $${turns}$ times and the results are shown below:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${clone}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(a)$ Find the range of the results.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(b)$ Find the mode of the results.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(c)$ Find the mean of the results.", false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Range $= ${acRange}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Mode $= ${mod}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(c)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Sum of results $= ${sum}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Mean $=$ sum $ \\div$ number of results $= ${mean}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(3);
    group.addInput(acRange, 0, "$(a)$");
    group.addInput(mod, 0, "$(b)$");
    group.addInput(mean, 0.05, "$(c)$");
    question.addInputGroup(group);

    if (
      turns / 2 !== Math.round(turns / 2) ||
      mod.length !== 1 ||
      Math.round(mean * 1000) !== mean * 1000
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Statistics_StatisticalMeasures_Exam_Q3() {
  let count = 0;
  const img_scale = 3;

  for (let i = 1; i <= 1; i += 1) {
    const mode = (arr) => {
      if (
        arr.filter((x, index) => arr.indexOf(x) === index).length === arr.length
      )
        return arr;
      return mode(
        arr
          .sort((x, index) => x - index)
          .map((x, index) => (arr.indexOf(x) !== index ? x : null))
          .filter((x) => x !== null)
      );
    };

    function range(numbers) {
      numbers.sort();
      return {
        smallest: numbers[0],
        largest: numbers[numbers.length - 1],
      };
    }

    const heights = [];
    const employees = MCRandom.randomInt(9, 16);
    for (let z = 1; z <= employees; z += 1) {
      heights.push(MCRandom.randomInt(162, 195));
    }
    const clone = heights.slice(0);
    const rang = range(heights);
    const acRange = rang.largest - rang.smallest;
    const mod = mode(heights);
    const pos = (heights.length + 1) / 2;
    const pos2 = MCRandom.randomInt(0, clone.length);
    const heights2 = heights.slice(0);
    heights2.splice(pos2, 1);
    let sum = 0;
    for (let m = 1; m <= heights2.length; m += 1) {
      sum += heights2[m - 1];
    }
    const mean = sum / (employees - 1);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "A list of the heights, in centimeters, of a number of employees has been compiled.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${clone}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(a)$ Find the mode of the heights.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(b)$ Find the range of the heights.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(c)$ Find the median of the heights.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `One of the employees in the sample were fired, their height was $${heights[pos2]}cm$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(d)$ Find the mean of the remaining employees in the sample.",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Mode $= ${mod}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Range $= ${rang.largest} - ${rang.smallest} = ${acRange}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(c)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Ordered list $= ${heights}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Medain place $=$ $${pos}^{th}$ value`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Medain value $=$ $${heights[pos]}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(d)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`New list $= ${heights2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Sum of heights $= ${sum}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Mean $= ${sum}  \\div ${heights2.length} = ${mean}$`,
        false,
        true,
        true
      )
    );

    const ansC = heights[pos];

    const group = new MCQuestion.InputGroup(2);
    group.addInput(mod, 0.1, "$(a)$");
    group.addInput(acRange, 0.01, "$(b)$");
    try {
      group.addInput(ansC, 0.01, "$(c)$");
    } catch (err) {
      // pass
    }
    group.addInput(mean, 0.1, "$(d)$");
    question.addInputGroup(group);

    if (
      employees / 2 === Math.round(employees / 2) ||
      mod.length !== 1 ||
      mean * 1000 !== Math.round(mean * 1000)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Statistics_StatisticalMeasures_Exam_Q4() {
  let count = 0;
  const img_scale = 3;

  for (let i = 1; i <= 1; i += 1) {
    const mode = (arr) => {
      if (
        arr.filter((x, index) => arr.indexOf(x) === index).length === arr.length
      )
        return arr;
      return mode(
        arr
          .sort((x, index) => x - index)
          .map((x, index) => (arr.indexOf(x) !== index ? x : null))
          .filter((x) => x !== null)
      );
    };

    function range(numbers) {
      numbers.sort();
      return {
        smallest: numbers[0],
        largest: numbers[numbers.length - 1],
      };
    }

    const heights = [];
    const employees = MCRandom.randomInt(9, 16);
    for (let z = 1; z <= employees; z += 1) {
      heights.push(MCRandom.randomInt(162, 195));
    }
    const clone = heights.slice(0);
    const rang = range(heights);
    const acRange = rang.largest - rang.smallest;
    const mod = mode(heights);
    const pos = (heights.length + 1) / 2;
    const pos2 = MCRandom.randomInt(0, clone.length);
    const heights2 = heights.slice(0);
    heights2.splice(pos2, 1);
    let sum = 0;
    for (let m = 1; m <= heights2.length; m += 1) {
      sum += heights2[m - 1];
    }
    const mean = sum / (employees - 1);

    const name = MCMisc.getName();
    const games1 = MCRandom.randomInt(5, 12);
    const games2 = games1 - 1;
    const score1 = MCRandom.randomInt(73, 84);
    const scoreIncreaseBy = MCRandom.randomInt(2, 4);
    const score2 = score1 - scoreIncreaseBy;
    const sum1 = score1 * games1;
    const sum2 = score2 * games2;
    const lastRound = sum1 - sum2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.name} plays golf.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `After ${name.hisher} $${games1}^{th}$ round, ${name.hisher} mean score was $${score1}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `After ${name.hisher} $${games2}^{th}$ round, ${name.hisher} mean score was $${scoreIncreaseBy}$ lower.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Prove that ${name.name} scored $${lastRound}$ in ${name.hisher} $${games1}^{th}$ round.`,
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Sum of scores after round $${games1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Sum $=$ number of rounds $ \\times$ mean score $= ${games1}  \\times ${score1} = ${sum1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Sum of scores after round $${games2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Sum $=$ number of rounds $ \\times$ mean score $= ${games2}  \\times ${score2} = ${sum2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Score in $${games1}^{th}$ round`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Score $=$ sum of scores after round $${games1}$ $-$ sum of scores after round $${games2} = ${lastRound}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Score $= ${sum1} - ${sum2} = ${lastRound}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${lastRound}$ scored in $${games1}^{th}$ round`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();

    if (
      employees / 2 === Math.round(employees / 2) ||
      mod.length !== 1 ||
      mean * 1000 !== Math.round(mean * 1000) ||
      lastRound >= 105
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Statistics_StatisticalMeasures_Exam_Q5() {
  let count = 0;
  const img_scale = 3;

  for (let i = 1; i <= 1; i += 1) {
    const mode = (arr) => {
      if (
        arr.filter((x, index) => arr.indexOf(x) === index).length === arr.length
      )
        return arr;
      return mode(
        arr
          .sort((x, index) => x - index)
          .map((x, index) => (arr.indexOf(x) !== index ? x : null))
          .filter((x) => x !== null)
      );
    };

    function range(numbers) {
      numbers.sort();
      return {
        smallest: numbers[0],
        largest: numbers[numbers.length - 1],
      };
    }

    const weights = [];
    const people = MCRandom.randomInt(6, 12);
    let sum = 0;
    for (let m = 0; m <= people - 1; m += 1) {
      const weight = MCRandom.randomInt(62, 85);
      weights.push(weight);
      sum += weight;
    }
    const mean = sum / people;
    const name = MCMisc.getName();
    const newWeight = MCRandom.randomInt(60, 95);
    const newSum = sum + newWeight;
    const newMean = newSum / (people + 1);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The mean weight of a group of $${people}$ people is $${mean}kg$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ What is the sum of the weights of the $${people}$ people?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} joins the group.The new mean weight is $${newMean}kg$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Work out ${name.name}'s weight.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ Sum of weights = mean weight $ \\times$ number of people $= ${mean}  \\times ${people} = ${sum}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ New number of people $= ${people + 1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `New sum of weights $=$ new number of people $ \\times$ mean weight $= ${newMean}  \\times ${
          people + 1
        } = ${newSum}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${name.name}'s weight $=$ new sum $-$ previous sum $= ${newSum} - ${sum} = ${newWeight}kg$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(sum, 0, "$(a)$");
    group.addInput(newWeight, 0, "$(b)$");
    question.addInputGroup(group);

    if (
      mean !== Math.round(mean) ||
      newMean !== Math.round(newMean) ||
      mean === newMean ||
      name.name.slice(-1) === "s"
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Statistics_StatisticalMeasures_Exam_Q6() {
  let count = 0;
  const img_scale = 3;

  for (let i = 1; i <= 1; i += 1) {
    const mode = (arr) => {
      if (
        arr.filter((x, index) => arr.indexOf(x) === index).length === arr.length
      )
        return arr;
      return mode(
        arr
          .sort((x, index) => x - index)
          .map((x, index) => (arr.indexOf(x) !== index ? x : null))
          .filter((x) => x !== null)
      );
    };

    function range(numbers) {
      numbers.sort();
      return {
        smallest: numbers[0],
        largest: numbers[numbers.length - 1],
      };
    }

    const cards = [];
    const numberOfCards = MCRandom.randomInt(4, 4);
    let sum = 0;
    for (let m = 0; m <= numberOfCards - 1; m += 1) {
      const value = MCRandom.randomInt(1, 9);
      cards.push(value);
      sum += value;
    }
    const mean = sum / numberOfCards;
    const rang = range(cards);
    const ran = rang.largest - rang.smallest;
    let sumMin1 = 0;
    for (let z = 0; z <= numberOfCards - 2; z += 1) {
      sumMin1 += cards[z];
    }
    const largest = sum - sumMin1;
    const smallest = largest - ran;
    const mod = mode(cards);
    const midCards = sum - (smallest + largest);
    const median = midCards / 2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${numberOfCards}$ cards are picked from a deck of playing cards.The cards are sorted so that they are in value order, starting with the smallest.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The mean value of the cards is $${mean}$.The sum of the $${
          numberOfCards - 1
        }$ smallest cards is $${sumMin1}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find the value of the largest card.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `It is known that the range of the cards is $${ran}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(b)$ Find the median of the cards.", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ Total sum $=$ number of cards $ \\times$ mean value $= ${numberOfCards}  \\times ${mean} = ${sum}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Card with largest value $=$ sum of all cards $-$ sum of the $${
          numberOfCards - 1
        }$ smallest cards $= ${sum} - ${sumMin1} = ${largest}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b)$ Largest card $= ${largest}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Largest card $-$ smallest card $= ${ran}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${largest}$ $-$ smallest card $= ${ran}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Smallest card $= ${largest} - ${ran} = ${smallest}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Sum of middle cards $=$ sum of all cards $-$ sum of smallest and largest card $= ${sum} - (${smallest} + ${largest}) = ${midCards}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Median $=$ value between the second and third card $=$ sum of middle cards $ \\div$ $2 = ${midCards}  \\div 2 = ${median}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(largest, 0, "$(a)$");
    group.addInput(median, 0, "$(b)$");
    question.addInputGroup(group);

    if (mean !== Math.round(mean) || mod.length !== 4) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Statistics_StatisticalMeasures_Exam_Q7() {
  let count = 0;
  const img_scale = 3;

  for (let i = 1; i <= 1; i += 1) {
    const mode = (arr) => {
      if (
        arr.filter((x, index) => arr.indexOf(x) === index).length === arr.length
      )
        return arr;
      return mode(
        arr
          .sort((x, index) => x - index)
          .map((x, index) => (arr.indexOf(x) !== index ? x : null))
          .filter((x) => x !== null)
      );
    };

    function range(numbers) {
      numbers.sort();
      return {
        smallest: numbers[0],
        largest: numbers[numbers.length - 1],
      };
    }

    const numberOfWomen = MCRandom.randomInt(6, 20);
    const numberOfMen = MCRandom.randomInt(6, 20);
    const womenSpeeds = [];
    const menSpeeds = [];
    let sumWomen = 0;
    let sumMen = 0;
    const speedLimits = [30, 40, 50, 60];
    const speedLimit = speedLimits[MCRandom.randomInt(0, 3)];
    for (let w = 0; w <= numberOfWomen - 1; w += 1) {
      const speed = MCRandom.randomInt(
        speedLimit - speedLimit / 10 - 10,
        speedLimit + speedLimit / 10 + 10
      );
      womenSpeeds.push(speed);
      sumWomen += speed;
    }
    for (let m = 0; m <= numberOfMen - 1; m += 1) {
      const speed = MCRandom.randomInt(
        speedLimit - speedLimit / 10 - 10,
        speedLimit + speedLimit / 10 + 10
      );
      menSpeeds.push(speed);
      sumMen += speed;
    }
    const womenMean = sumWomen / numberOfWomen;
    const menMean = sumMen / numberOfMen;
    const totalSum = sumMen + sumWomen;
    const drivers = numberOfMen + numberOfWomen;
    const mean = totalSum / drivers;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "A survey has been conducted to determine whether drivers are following the speed limit on certain roads. ",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The survey was conducted on a road with a speed limit of $${speedLimit}mph$and the sample consisted of $${numberOfMen}$ male drivers and $${numberOfWomen}$ female drivers.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The mean speed of male drivers was $${menMean}mph$.The mean speed of female drivers was $${womenMean}mph$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find the mean speed of the whole sample. Give your answer to the nearest mile per hour.",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Sum of Speeds - Male Drivers", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Sum $=$ number of drivers $ \\times$ mean speed $= ${numberOfMen}  \\times ${menMean} = ${sumMen}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Sum of Speeds - Female Drivers", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Sum $=$ number of drivers $ \\times$ mean speed $= ${numberOfWomen}  \\times ${womenMean} = ${sumWomen}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Sum of Speeds - Full Sample", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Sum $=$ nsum for male drivers $+$ sum for female drivers $= ${sumMen} + ${sumWomen} = ${totalSum}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Mean Speed - Full Sample", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Number of drivers in sample $= ${drivers}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Mean speed of sample $=$ sum of speeds in sample $ \\div$ number of drivers in sample $= ${totalSum}  \\div ${drivers} = ${mean}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Mean $= ${mean.toFixed(0)}mph$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(mean, 0.5);
    question.addInputGroup(group);

    if (
      Math.abs(womenMean - menMean) <= 2 ||
      Math.abs(numberOfMen - numberOfWomen) <= 4 ||
      menMean !== Math.round(menMean) ||
      womenMean !== Math.round(womenMean) ||
      Math.round(mean * 10000) !== mean * 10000 ||
      Math.round(mean) === speedLimit
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Statistics_StatisticalMeasures_Exam_Q8() {
  let count = 0;
  const img_scale = 3;

  for (let i = 1; i <= 1; i += 1) {
    const mode = (arr) => {
      if (
        arr.filter((x, index) => arr.indexOf(x) === index).length === arr.length
      )
        return arr;
      return mode(
        arr
          .sort((x, index) => x - index)
          .map((x, index) => (arr.indexOf(x) !== index ? x : null))
          .filter((x) => x !== null)
      );
    };

    function range(numbers) {
      numbers.sort();
      return {
        smallest: numbers[0],
        largest: numbers[numbers.length - 1],
      };
    }

    const name = MCMisc.getName();
    const sizes = [];
    const customers = MCRandom.randomInt(12, 18);
    for (let s = 0; s <= customers - 1; s += 1) {
      let size = MCRandom.randomInt(4, 9);
      const addDec = MCRandom.randomInt(0, 1);
      if (addDec === 1) {
        size += 0.5;
      }
      sizes.push(size);
    }
    const mod = mode(sizes);
    mod.sort();

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} is the owner of a shoe shop.${name.HeShe} has recorded the size of each pair of shoes bought in the last week:`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${sizes}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} would like to know which size is the most popular so that ${name.heshe} isable to order a larger quantity of them.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Which average would ${name.name} find most useful?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the average from your answer in part $(a)$.",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$ Mode", false, true, true)
    );
    if (mod.length === 2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(b) \\ ${mod[0]}$ and $${mod[1]}$`, false, true, true)
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$(b) \\ ${mod}$`, false, true, true)
      );
    }

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(mod, 0.1, "$(b)$");
    question.addInputGroup(group);

    if (mod.length >= 3) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_ProbabilityAndStatistics_Statistics_StatisticalMeasures_Exam_Q9() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    function sort(arr) {
      return arr.concat().sort();
    }

    const name1 = MCMisc.getName();
    const name2 = MCMisc.getName();
    const numbers = [];
    for (let j = 1; j <= 15; j += 1) {
      numbers.push(MCRandom.randomInt(1, 30));
    }
    const sortedList = sort(numbers);
    const median = sortedList[7];

    const goals = [];
    for (let z = 1; z <= 4; z += 1) {
      goals.push(0);
      goals.push(MCRandom.randomInt(0, 4));
    }

    goals.push(0);
    const modeAns = 0;

    let sumOfNumbers = 0;
    for (let x = 0; x <= 14; x += 1) {
      sumOfNumbers += numbers[x];
    }
    const mean = sumOfNumbers / 15;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} is teaching ${name1.hisher} friend, ${name2.name}, about the mean, median and mode.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} gives ${name2.name} this list of numbers:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${numbers}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.HeShe} tells ${name2.himher} that the median is the middle number and so in this case the median is $${numbers[7]}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Is ${name1.name} correct? If not, work out the median.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Work out the mean of the numbers.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} looks at some data from ${name1.hisher} favourite football team.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.HeShe} finds the number of goals scored each game as follows:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${goals}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.HeShe} tests ${name2.name} by asking ${name2.himher} for the mode of the goals scored.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ What should ${name2.name}'s answer be?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$ No.", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Order the numbers = $${sortedList}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Median = middle element = 8th element = $${median}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ Sum of all numbers = $${sumOfNumbers}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Mean = sum of numbers $ \\div$ number of numbers = $${sumOfNumbers}  \\div 15$ = $${parseFloat(
          mean.toFixed(2)
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(c)$ Mode = most common element = $${modeAns}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(3);
    group.addInput(median, 0, "$(a)$");
    group.addInput(mean, 0.2, "$(b)$");
    group.addInput(modeAns, 0, "$(c)$");
    question.addInputGroup(group);
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (median === numbers[7] || name2.name[name2.name.length - 1] === "s") {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_ComparingQuantity_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function calculateRatio(num_1, num_2){
    for(let num=num_2; num>1; num--) {
        if((num_1 % num) == 0 && (num_2 % num) == 0) {
            num_1=num_1/num;
            num_2=num_2/num;
        }
    }
      return [num_1, num_2];
    }
    
    const aUnit = MCRandom.randomInt(25, 40);
    const bUnit = MCRandom.randomInt(20, 35);

    const aN = MCRandom.randomInt(4, 10, 2);
    const bN = MCRandom.randomInt(8, 16, 2);

    const aCost = (aN * aUnit / 100)
    const bCost = (bN * bUnit / 100)

    const n = aN * bN;

    const ratio = calculateRatio(aUnit, bUnit)

    const aC = parseFloat(((aCost) * bN).toFixed(2))
    const bC = parseFloat(((bCost) * aN).toFixed(2))

    const c_ans = parseFloat((aC - bC).toFixed(2));

    /* END VARIABLES AND CONSTANTS */
    if ( aUnit > bUnit && aN < bN && aCost < bCost && ratio[0] !== aUnit) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      
      question.addParagraph('question', `A certain brand of toilet paper is sold in packs of $${aN}$ and packs of $${bN}$ rolls.`);

      question.addParagraph('question', `It costs $£${aCost}$ for a pack of $${aN}$ and $£${bCost}$ for a pack of $${bN}$`);

      question.addParagraph('question', `a) Calculate which pack is better value for money.`);
      question.addParagraph('question', `b) Express the cost per roll, in pence, for a pack of $${aN}$ to the cost per roll for a pack of $${bN}$ as a ratio in its simplest form.`);
      question.addParagraph('question', `c) How much cheaper is it to buy $${n}$ rolls using packs of $${bN}$ rather than packs of $${aN}?$`);
      question.addParagraph('question', `Give your answer in pounds.`);
      //
      // solution
      //a
      question.addHeading('solution', `a) Work out cost per roll for a pack of $${aN}$`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${aCost}}{${aN}} = ${(aUnit/100)}$`);
      question.addParagraph('solution', `$\\therefore$ It's $${aUnit}$p per roll in a pack of $${aN}$`);

      question.addHeading('solution', `Work out cost per roll for a pack of $${bN}$`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${bCost}}{${bN}} = ${(bUnit/100)}$`);
      question.addParagraph('solution', `$\\therefore$ It's $${(bUnit)}$p per roll in a pack of $${bN}$`);

      question.addParagraph('solution', `$${aUnit} > ${bUnit}$`); 
      question.addParagraph('solution', `$\\therefore$ The pack of $${bN}$ is better value for money.`);

      // b
      question.addHeading('solution', `b) For a pack of $${aN}$ it's $${aUnit}$p per roll and for a pack of $${bN}$ it's $${bUnit}$p per roll.`);
      question.addParagraph('solution', `This gives a ratio of $${aUnit}:${bUnit}$`);
      question.addParagraph('solution', `In simplest form this is $${ratio[0]}:${ratio[1]}$`);
      //c 

      question.addHeading('solution', `c) To buy $${n}$ rolls using packs of $${aN}$ you must buy:`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${n}}{${aN}} = ${bN}$ packs.`);
      question.addParagraph('solution', `$${bN}(${aCost}) = ${aC}$`);
      
      question.addHeading('solution', `To buy $${n}$ rolls using packs of $${bN}$ you must buy:`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${n}}{${bN}} = ${aN}$ packs.`);
      question.addParagraph('solution', `$${aN}(${bCost}) = ${bC}$`);

      question.addParagraph('solution', `$${aC}-${bC}=${c_ans}$`);

      question.addParagraph('solution', `$\\therefore$ You would save $£${c_ans.toFixed(2)}$ buying in packs of $${bN}$ rather than packs of $${aN}$`);
      // answers

      question.requiresSelfMarking();
      const g = new MCQuestion.InputGroup(2);

      g.addInput(ratio[0], 0, `$(b)$`)
      g.addInput(ratio[1], 0, `$:$`)

      g.addInput(c_ans, 0 , `$(c)$`);
      question.addInputGroup(g);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_ComparingQuantity_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function calculateRatio(num_1, num_2){
    for(let num=num_2; num>1; num--) {
        if((num_1 % num) == 0 && (num_2 % num) == 0) {
            num_1=num_1/num;
            num_2=num_2/num;
        }
    }
      return [num_1, num_2];
    }
    
    const ratio = calculateRatio(MCRandom.randomInt(1, 8), MCRandom.randomInt(1,8));

    const t = ratio[0] + ratio[1];


    const n = MCRandom.randomInt(5, 20) * t;

    const part = n/t;

    const n2 = MCRandom.randomInt(5, 20) * t;


    const a_ans = part * ratio[1];
    const men = n - a_ans;

    const b_ans = new MCMaths.Fraction(a_ans/men);

    const c_ans =( b_ans.toFloat() * n2)
    /* END VARIABLES AND CONSTANTS */
    if ( ratio[0] !== ratio[1] && n !== n2 && ratio[0] !== 1 && ratio[1] !== 1 && c_ans % 1 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph('question', `A group of $${n}$ people has a ratio of men to women of $${ratio[0]}:${ratio[1]}$`);
      question.addParagraph('question', `a) Calculate the number of women in the group.`);
      question.addParagraph('question', `b) Express the number of women as a fraction of the number of men.`);
      question.addParagraph('question', `Another group has the same ratio of men to women and contains $${n2}$ men.`);
      question.addParagraph('question',` c) Calculate the number of women in the group.`);

      // solution

      question.addHeading('solution', `a) There are $${n}$ people in total with the ratio of men to women being $${ratio[0]}:${ratio[1]}$`);
      question.addParagraph('solution', `$${ratio[0]}+${ratio[1]}=${t}$`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${n}}{${t}} = ${part}$`);
      question.addParagraph('solution', `$${part}(${ratio[1]})=${a_ans}$`);

      question.addParagraph('solution', `$\\therefore$ There are $${a_ans}$ women in the group.`);

      // solution

      question.addHeading('solution', `b) Using the ratio we figure that:`);
      question.addParagraph('solution', `For every $${ratio[1]}$ women in the group there are $${ratio[0]}$ men.`);
      question.addParagraph('solution', `$\\therefore$ There are $\\displaystyle ${b_ans} \\times$ as many women as there are men.`);

      // solution

      question.addHeading('solution', `c) Using the answer to part b we know there are $\\displaystyle ${b_ans} \\times$ as many women as there are men.`);
      question.addParagraph('solution', `There are $${n2}$ men in this new group.`);
      question.addParagraph('solution', `$\\displaystyle ${b_ans}(${n2})=${c_ans} $`);
      question.addParagraph('solution', `$\\therefore$ There are $${c_ans}$ women in the new group.`);

      // answers

      const g = new MCQuestion.InputGroup(1);

      g.addInput(a_ans, 0, `$(a)$`);
      g.addInput(b_ans.toFloat(), 0.05, `$(b)$`);
      g.addInput(c_ans, 0, `$(c)$`);

      question.addInputGroup(g);
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_ComparingQuantity_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function calculateRatio(num_1, num_2){
    for(let num=num_2; num>1; num--) {
        if((num_1 % num) == 0 && (num_2 % num) == 0) {
            num_1=num_1/num;
            num_2=num_2/num;
        }
    }
      return [num_1, num_2];
    }
    
    const aUnit = MCRandom.randomInt(55, 85);
    const bUnit = MCRandom.randomInt(50, 80);

    const aN = MCRandom.randomInt(2, 4);
    const bN = MCRandom.randomInt(4, 8);

    const aCost = (aN * aUnit / 100)
    const bCost = (bN * bUnit / 100)

    const ratio = calculateRatio(bUnit, aUnit)

    const frac = new MCMaths.Fraction(bUnit / aUnit);
    /* END VARIABLES AND CONSTANTS */
    if ( aUnit > bUnit && aN < bN && aCost < bCost && ratio[0] !== aUnit && ((aUnit % 2 === 0 && bUnit % 2 === 0)||(aUnit % 3 === 0 && bUnit % 3 === 0)||(aUnit % 5 === 0 && bUnit % 5 === 0)) ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      
      question.addParagraph(`question`, `Tins of beans are sold in packs of $${aN}$ and packs of $${bN}$`);
      question.addParagraph('question', `A pack of $${aN}$ beans costs $£${aCost.toFixed(2)}$`);
      question.addParagraph('question', `A pack of $${bN}$ beans costs $£${bCost.toFixed(2)}$`);

      question.addParagraph('question', `a) Calculate the cost per tin, in pence, for each size pack.`);
      question.addParagraph('question',` b) Find the ratio of the cost per tin for the pack of $${bN}$ to the cost per tin for the pack of $${aN}$.`);
      question.addParagraph('question', `Give your answer in its simplest form.`);
      question.addParagraph('question', `c) State how many times cheaper it is to buy the same quantity of beans in packs of $${bN}$ than in packs of $${aN}$`);

      // solution

      question.addHeading('solution', `a) Find the cost per tin for a pack of $${aN}$`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${aCost}}{${aN}} = ${(aUnit/100)}$`);
      question.addParagraph('solution', `$\\therefore$ It's $${aUnit}$p per tin in a pack of $${aN}$`);

      question.addHeading('solution', `Work out cost per roll for a pack of $${bN}$`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${bCost}}{${bN}} = ${(bUnit/100)}$`);
      question.addParagraph('solution', `$\\therefore$ It's $${(bUnit)}$p per tin in a pack of $${bN}$`);

      // b
      question.addHeading('solution', `b) Using the answers from part a:`);
      question.addParagraph('solution', `This gives a ratio of $${bUnit}:${aUnit}$`);
      question.addParagraph('solution', `In simplest form this is $${ratio[0]}:${ratio[1]}$`);

      // c

      question.addHeading('solution', `c) Using the ratio from part b:`);
      question.addParagraph('solution', `The cost per tin in packs of $${bN}$ is $\\displaystyle ${frac} \\times$  the cost per tin in packs of $${aN}$`);


      // answers

      const g = new MCQuestion.InputGroup(2);

      g.addInput(aUnit,0,`$(a)$ Packs of $\\ ${aN}$`);
      g.addInput(bUnit,0,`$(a)$ Packs of $\\ ${bN}$`);

      question.addInputGroup(g);

      const g2 = new MCQuestion.InputGroup(2);

      g2.addInput(ratio[0],0,`$(b)$ `);
      g2.addInput(ratio[1],0,`$:$`);
      g2.addInput(frac.toFloat(),0.05,`$(c)$`);

      question.addInputGroup(g2);


      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_ComparingQuantity_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
        
    

    const unitsPP = MCRandom.randomInt(10, 50);
    const m = MCRandom.randomInt(2, 6);

    const current = MCRandom.randomInt(3, 10);
    const future = current * m;

    const b = (future - current)* unitsPP;

    const ratio = [1, unitsPP]

    /* END VARIABLES AND CONSTANTS */
    if ( true ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      
      question.addParagraph('question', `The table below shows the number of salesmen working and the number of units sold in a day.`);

      const t = new MCQuestion.Table(`Number of People`, `Time Taken (hours)`);
      t.addRow(`$3$`, `$${3*unitsPP}$`);
      t.addRow(`$4$`, `$${4*unitsPP}$`);
      t.addRow(`$5$`, `$${5*unitsPP}$`);
      t.addRow(`$6$`, `$${6*unitsPP}$`);
      question.addTable('question', t);

      question.addParagraph('question', `a) State the ratio of the number of salesmen working to units sold in its simplest form.`);
      question.addParagraph('question', `A company plans to increase the number of salesmen they employ to $${m}$ times what they have currently. This would result in $${b}$ more sales per day.`)
      question.addParagraph('question', `b) Calculate the number of salesmen they currently have working for them.`);

      // solution 


      question.addHeading('solution', `a) Using the first row of the table where $3$ people sell $${3*unitsPP}$ units per day.`);
      question.addParagraph('solution', `This gives a ratio of $3:${3*unitsPP}$`);
      question.addParagraph('solution', `This simplifies to $1:${unitsPP}$`);

      // b

      question.addHeading('solution', `b) Let the current number of employees be $c$.`);
      question.addParagraph('solution', `We know from the question that they plan to have $${m}c$ employees in the future which will increase sales by $${b}$ units per day.`);
      question.addParagraph('solution', `Also, each new employee generates $${unitsPP}$ sales per day.`);
      question.addParagraph('solution', `$\\therefore ${unitsPP}(${m}c - c)=${b}$`);
      question.addParagraph('solution', (`$${unitsPP}(${m-1}c)=${b}$`));
      question.addParagraph('solution', (`$${unitsPP * (m-1)}c=${b}$`));
      question.addParagraph('solution', (`$\\therefore c = ${current}$`));

      question.addParagraph('solution', `$\\therefore$ The company currently has $${current}$ employees.`);

      // answers

      const g = new MCQuestion.InputGroup(2);
g.addInput(1,0,`$(a)$ `);
      g.addInput(unitsPP,0,`$:$`);

      g.addInput(current, 0 , `$(b)$`);
      question.addInputGroup(g);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_ComparingQuantity_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const n = MCRandom.randomInt(20, 80, 5)
    const stepsPerN = MCRandom.randomInt(2, 10)

    const times = MCRandom.randomInt(3, 8)

    const steps = n*stepsPerN;
    /* END VARIABLES AND CONSTANTS */
    if ( true ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      
      question.addParagraph('question', `A sorting algorithm takes $${times * steps}$ steps to return a fully sorted list of numbers from an unsorted list of size $${n}$.`);
      question.addParagraph('question', `A new algorithm claims to be able to do the same task in only $${steps}$ steps.`);
      question.addParagraph('question', `a) How many times faster is the new algorithm compared to the original.`);
      question.addParagraph('question', `b) Express the number of steps in the original algorithm as a ratio to the number of steps in the new one.`);
      question.addParagraph('question', `Give the ratio in its simplest form.`);

      question.addParagraph('question', `c) Calculate the steps per number in the list for each of the algorithms.`);
      // solution

      question.addHeading('solution', `a) The original algorithm took $${times * steps}$ steps compared to the new one's $${steps}$ steps.`);

      question.addParagraph('solution', `$\\displaystyle \\frac{${times * steps}}{${steps}}= ${times}$`);
      question.addParagraph('solution', `$\\therefore$ The new algorithm is $${times}$ times faster than the original.`);
      // b

      question.addHeading('solution', `b) The new algorithm is $${times}$ times faster than the original.`);
      question.addParagraph('solution', `This gives a ratio of $${times}:1$`);

      // c

      question.addHeading('solution', `c) The original algorithm took $${times * steps}$ steps.`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${times * steps}}{${n}}=${times * stepsPerN}$`);
      question.addHeading('solution', `The new algorithm took $${steps}$ steps.`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${steps}}{${n}}=${stepsPerN}$`);
      // answers

      const g = new MCQuestion.InputGroup(1)
      g.addInput(times, 0 , `$(a)$`);
      question.addInputGroup(g)

      const g2 = new MCQuestion.InputGroup(2)
      g2.addInput(times, 0 , `$(b)$`);
      g2.addInput(1, 0 , `$:$`);

      g2.addInput(times * stepsPerN, 0 , `$(c)\\ $ Original`);
      g2.addInput(stepsPerN, 0 , `$(c)\\ $ New`);
      question.addInputGroup(g2)
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_CompoundUnits_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const distanceMultiplier = MCRandom.randomInt(4, 20);
    const speedMultiplier = MCRandom.randomInt(4, 20);
    const answerA = distanceMultiplier / speedMultiplier;
    const carLeaves = MCRandom.randomInt(80, 200);
    const carSpeed = MCRandom.randomInt(31, 45);
    const vanSpeed = MCRandom.randomInt(20, 30);
    const distance = carLeaves * carSpeed;
    const time = distance / vanSpeed;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("Average Speed$ \\ = \\frac{Distance}{Time}$", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "A car and a lorry drive parallel to each other on a straight motorway.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The distance travelled by the car is $${distanceMultiplier}$ times more than the distance travelled by the lorry.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The average speed of the car is $${speedMultiplier}$ times more than the average speed of the lorry.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ How many times longer is the car travelling for.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "A car and a van now travel on the motorway.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("They both travel the same distance.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The car leaves the motorway after $${carLeaves}$ seconds and has an average speed of $${carSpeed}$ meters per second.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The van has an average speed of $${vanSpeed}$ meters per second.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ How long does it take for the van to leave the motorway?",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(a)$ Rearranging the given equation gives $Time = \\frac{Distance}{Speed}$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Substituting the multipliers into the equation gives $Time = \\frac{${distanceMultiplier}  \\times Distance}{${speedMultiplier}  \\times Speed}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `This can be simplified to $Time = ${answerA}  \\times \\frac{Distance}{Speed}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `The time that the car travelled for is $${answerA}$ times the amount of time that the lorry travelled for.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ The distance travelled by the car = speed $ \\times$ time = $${carSpeed}  \\times ${carLeaves} = ${distance}$m`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `The distance travelled by the van = the distance travelled by the car = $${distance}$m`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $Time = \\frac{Distance}{Speed}$ = $${distance}  \\div ${vanSpeed} = ${time}$ seconds`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(answerA, 0, "$(a)$");
    group.addInput(time, 0, "$(b)$");
    question.addInputGroup(group);
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      distanceMultiplier === speedMultiplier ||
      answerA !== Math.round(answerA) ||
      time !== Math.round(time)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// Av speed = Distance / time
// A car and a van drive parallel to each other on a motorway
// The distance that the car travels before leaving the motorway is x times the distance that the van travels
// The average speed of the car is y times more than the average speed of the van
// The time that the car travelled its distance in is z times the amount that the van travelled its distance in.
// Find z.

// Another car and van drive on the same motorway
// They both travel the same distance on the motorway
// The car leaves the motorway after x seconds with an average speed of y meters per second
// The van has an average speed of z meters per second.
// How long does it take for the van to leave the motorway?


export function GCSE_Foundation_Rparoc_Rparoc_CompoundUnits_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const person = MCMisc.getName();
    const time = MCRandom.randomInt(2, 5);
    const distance = MCRandom.randomInt(10, 30) * 10;
    const speed = distance / time;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` It takes ${person.name} $${time}$ hours to travel $${distance}$ miles by car.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` What was ${person.name}'s average speed?`,
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` ${person.name} was travelling at an average speed of $\\displaystyle {${distance}\\over ${time}} = ${speed}mph$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(speed, 0);
    question.addInputGroup(group);

    if (speed > 70 || speed < 50 || speed * 1000 !== Math.round(speed * 1000)) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_CompoundUnits_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const diameter = MCRandom.randomInt(5, 10);
    const radius = diameter / 20;
    const area = Math.PI * radius * radius;
    const force = MCRandom.randomInt(10, 50);

    const pressure = force / area;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A circular bottom glass is on a table. It has a base of diameter of $${diameter}cm$ and exerts a force of $${force}$ newtons on the table.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " What is the pressure on the table to $3$ significant figures?",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` The pressure on the table is \${\\displaystyle \\frac{${force}}{\\pi\\left(${radius}\\right)^{2}}} = ${pressure.toPrecision(
          3
        )}  N/m^2$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(pressure, 0.1);
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_CompoundUnits_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const speed = MCRandom.randomInt(30, 70);
    const t1 = MCRandom.randomInt(6, 11);
    const t2 = MCRandom.randomInt(13, 21);
    const t_diff = t2 - t1;
    const distance = speed * t_diff;
    const person = MCMisc.getName();

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} sets off from home at $${t1}am$ and arrives at ${
          person.hisher
        } destination at $${t2 - 12}pm$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} drives at an average speed of $${speed}mph$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` How much distance has ${person.heshe} travelled by car?`,
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${person.name} has travelled $${speed}  \\times (${t2} - ${t1}) = ${speed}  \\times (${t_diff}) = ${distance}$ miles by car.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(distance, 0);
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_CompoundUnits_Exam_Q5() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const n = MCRandom.randomInt(2, 20);
    const volume = MCRandom.randomInt(10, 50) * 10;
    const mass = MCRandom.randomInt(10, 100) * 10;
    const density = mass / volume;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A manufacturer orders $${n}$ blocks of wood. The total mass of the blocks is $${
          mass * n
        }g$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Each block of wood has a volume of $${volume}cm^3$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " What is the density of the wood  to $3$ significant figures?",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Density $\\displaystyle = {{\\left(\\frac{${
          n * mass
        }}{${n}}\\right)} \\over ${volume}}= {${mass}\\over${volume}} = ${density.toPrecision(
          3
        )}$ $g/cm^3$`,
        false,
        true,
        true
      )
    );
    const group = new MCQuestion.InputGroup(1);
    group.addInput(density, 0.01);
    question.addInputGroup(group);
    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_CompoundUnits_Exam_Q6() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const width = MCRandom.randomInt(10, 30);
    const height = MCRandom.randomInt(10, 30);
    const area = (width * height) / 10000;
    const pressure = MCRandom.randomInt(2, 10) * 1000;
    const force = pressure * area;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(" A rectangular box sits on a shelf.", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The box has width $${width}cm$ and length $${height}cm$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The box exerts a pressure of $${pressure}N/m^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " How much force is pushing down on the shelf?",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${force}N$ is pushing down on the shelf.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(force, 0.1);
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_CompoundUnits_Exam_Q7() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const person = MCMisc.getName();

    const speed = MCRandom.randomInt(30, 70);
    const distance = MCRandom.randomInt(30, 200);
    const time = distance / speed;
    const time_InMinutes = time * 60;
    const setOff_Hours = MCRandom.randomInt(7, 11);
    const setOff_Minutes = MCRandom.randomInt(10, 59);
    const initialTime = setOff_Hours * 60 + setOff_Minutes;
    const endTime = (initialTime + time_InMinutes) / 60;
    let arrived_Hours = Math.floor(endTime);
    const arrived_Minutes = (endTime - arrived_Hours) * 60;
    let time_affix;
    if (arrived_Hours > 12) {
      arrived_Hours -= 12;
      time_affix = "pm";
    } else {
      time_affix = "am";
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} travels $${distance}$ miles by car going at an average speed of $${speed}mph$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ How long does it take (in hours) for ${person.name} to reach ${person.hisher} destination?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ If ${person.name} set off at $${setOff_Hours}$:$${setOff_Minutes}am$, what time does ${person.heshe} arrive at his destination?`,
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ It takes ${person.name} $${time}$ hours to reach ${person.hisher} destination.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ ${person.name} will arive at $${arrived_Hours}$:$${arrived_Minutes}${time_affix}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(time, 0.1, "$(a)$");
    question.addInputGroup(group);
    question.requiresSelfMarking();

    if (
      time_InMinutes !== Math.round(time_InMinutes) ||
      arrived_Minutes < 10 ||
      speed === distance
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_CompoundUnits_Exam_Q8() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const elements = [
      ["Gold", 19.3],
      ["Iron", 7.87],
      ["Silver", 10.5],
      ["Bronze", 8.73],
      ["Magnesium", 1.74],
      ["Aluminium", 2.7],
    ];
    const randomiser = MCRandom.randomInt(0, elements.length - 1);
    const element = elements[randomiser][0];

    const mass = MCRandom.randomInt(50, 200) * 10;
    const density = elements[randomiser][1];
    const volume = mass / density;
    const sideLength = Math.cbrt(volume);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A cube of ${element} has a mass of $${mass}g$ and a density of $${density}g/cm^3$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " What is the width of the cube, in centimetres, to  $3$ significant figures?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Mass $=$ Density $\\times$ Volume", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${mass}=${density}\\times $ Width$^3$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Width$^3 = \\frac{${mass}}{${density}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Width $ = \\sqrt[3]{${mass / density}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, the cube has a width of $${sideLength.toPrecision(3)}cm$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(sideLength, 0.02);
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_FractionsOfQuantities_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p1 = MCMisc.getName();
    const p2 = MCMisc.getName();

    const d1 = MCRandom.randomInt(3, 9)
    const d3 = MCRandom.randomInt(2, 5)


    const f1 = new MCMaths.Fraction(MCRandom.randomInt(1, d1-1)/d1);
    const f2 = new MCMaths.Fraction(1).subtract(f1);

    const fc = new MCMaths.Fraction(MCRandom.randomInt(d3 + 1, 2*d3 - 1)/d3);

    const bn = MCRandom.randomInt(100, 750, 50);
    const t = (new MCMaths.Fraction(bn)).divide(f1)

    const b_ans = t.toFloat() - bn;

    const c_ans = new MCMaths.Fraction(1).divide(fc).multiply(t)
    /* END VARIABLES AND CONSTANTS */
    if ( b_ans % 1 === 0 && f1.toFloat() !== 0.5 && c_ans.toFloat() % 1 === 0 && p1.name !== p2.name) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph("question", `${p1.name} and ${p2.name} are organising a music event and the tickets are distributed between the two of them to sell. ${p1.name} is given $\\displaystyle ${f1}$ of the tickets to sell.`);
      question.addParagraph('question', `a) State the proportion of the tickets ${p2.name} receives.`);
      question.addParagraph('question', `b) Given that ${p1.name} has $${bn}$ tickets, how many does ${p2.name} have?`);
      question.addParagraph('question', `The venue ${p1.name} and ${p2.name} secured means they have $\\displaystyle ${fc}$ times the number of tickets to sell as the previous year.`);
      question.addParagraph('question', `c) Calculate the number of tickets the previous venue allowed to be sold.`);

      //solution

      question.addHeading('solution', `a) ${p1.name} has $\\displaystyle ${f1}$ of the total tickets.`);
      question.addParagraph('solution', `$1- \\displaystyle ${f1}=${f2}$`);
      question.addParagraph('solution', `$\\therefore$ ${p2.name} has $\\displaystyle ${f2}$ of the tickets to sell.`);

      // b

      question.addHeading('solution', `b) Let the total number of tickets be $t$`);
      question.addParagraph('solution', `${p1.name} has $\\displaystyle ${f1}$ of the tickets which is $${bn}$ of them.`);
      question.addParagraph('solution', `$\\displaystyle ${f1} t = ${bn}$`);
      question.addParagraph('solution', `$\\displaystyle t = ${(new MCMaths.Fraction(1)).divide(f1)} (${bn})$`);
      question.addParagraph('solution', `$\\therefore t = ${t}$`);

      question.addParagraph('solution', `$${t}-${bn}=${b_ans}$`);

      question.addParagraph('solution', `$\\therefore$ ${p2.name} has $${b_ans}$ tickets.`);

      // c

      question.addHeading('solution', `c) Let the number of tickets available previously be $p$`);
      question.addParagraph('solution', `The total number of tickets available this year is $\\displaystyle ${fc}$ times the previous year, $p$.`);
      question.addParagraph('solution', `$\\displaystyle ${fc}p=${t}$`);
      question.addParagraph('solution', `$\\displaystyle p = ${(new MCMaths.Fraction(1)).divide(fc)} (${t})$`);
      question.addParagraph('solution', `$\\therefore p = ${c_ans}$`);

      question.addParagraph('solution', `$\\therefore$ There were only $${c_ans}$ tickets available the previous year.`);

      // answers

      const g1 = new MCQuestion.InputGroup(1);

      g1.addInput(f2.toFloat(), 0.05, `$(a)$`)
      g1.addInput(b_ans, 0, `$(b)$`)
      g1.addInput(c_ans.toFloat(), 0, `$(c)$`)

      question.addInputGroup(g1)



      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_FractionsOfQuantities_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const p1 = MCMisc.getName();
    const p2 = MCMisc.getName();

    const d2 = MCRandom.randomInt(3, 9)
    const f2 = new MCMaths.Fraction(MCRandom.randomInt(1, d2-1)/d2);

    const s1 = MCRandom.randomInt(40, 70);
    const s2 = f2.multiply(new MCMaths.Fraction(s1));

    const db = MCRandom.randomInt(5, 9)
    const fb = new MCMaths.Fraction(MCRandom.randomInt(db - 3 , db-1)/db);

    const m = (new MCMaths.Fraction(s1)).divide(fb)

    const c_ans = 100 * s2 / m;

    /* END VARIABLES AND CONSTANTS */
    if ( s2.toFloat() % 1 === 0 && p1.name !== p2.name && m.toFloat() % 1 === 0 && m < 120 && c_ans % 1 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph('question', `${p1.name} got the highest mark in his class on a maths paper which was $${s1}$ marks.`);
      question.addParagraph('question', `${p2.name} managed to get $${s2}$ marks on the same paper.`);

      question.addParagraph('question', `a) Express ${p2.name}'s mark as a fraction of ${p1.name}'s.`);
      question.addParagraph('question', `b) Given that ${p1.name} only got $\\displaystyle ${fb}$ of the available marks, find the number of marks in the paper.`);
      question.addParagraph('question', `c) Hence, express ${p2.name}'s score as a percentage of the possible marks.`);

      // solution

      question.addHeading('solution', `a) ${p2.name} got $${s2}$ marks while ${p1.name} got $${s1}$`);

      question.addParagraph('solution', `$\\displaystyle \\frac{${s2}}{${s1}}=${f2}$`);

      question.addParagraph('solution', `$\\therefore$ ${p2.name} got $\\displaystyle ${f2}$ of ${p1.name}'s score.`);

      // b

      question.addHeading('solution', `b) Let the maximum number of marks be $m$`);
      question.addParagraph('solution', `${p1.name} got $\\displaystyle ${fb}$ of the marks which was $${s1}$ marks.`);
      question.addParagraph('solution', `$\\displaystyle ${fb}m=${s1}$`);
      question.addParagraph('solution', `$\\displaystyle m = ${(new MCMaths.Fraction(1)).divide(fb)} (${s1})$`);
      question.addParagraph('solution', `$\\therefore m = \\displaystyle ${m}$`);
      question.addParagraph('solution', `$\\therefore$ There were $${m}$ marks on the paper.`);

      // c

      question.addHeading('solution', `c) ${p2.name} got $${s2}$ marks out of a possible $${m}$`);

      question.addParagraph('solution', `$\\displaystyle \\frac{${s2}}{${m}} = ${c_ans/100}$`);

      question.addParagraph('solution', `$\\therefore$ ${p2.name} got $${c_ans}\\%$ of the available marks.`);

      // answers

      const g1 = new MCQuestion.InputGroup(1);

      g1.addInput(f2.toFloat(), 0.05, `$(a)$`)
      g1.addInput(m.toFloat(), 0, `$(b)$`)
      g1.addInput(c_ans, 0, `$(c)$`)

      question.addInputGroup(g1)


      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_FractionsOfQuantities_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p1 = MCMisc.getName();

    const m1 = MCRandom.randomInt(50, 200, 10);

    const d1 = MCRandom.randomInt(3, 9)
    const d2 = MCRandom.randomInt(3, 9)


    const f1 = new MCMaths.Fraction(MCRandom.randomInt(1, d1-1)/d1);
    const f2= new MCMaths.Fraction(MCRandom.randomInt(1, d2-1)/d2);

    const b_ans = new MCMaths.Fraction(1).subtract(f1).subtract(f2)

    const a1 = new MCMaths.Fraction(m1).multiply(f1)
    const a2 = new MCMaths.Fraction(m1).multiply(f2)

    const a_ans = m1 - a1.toFloat() - a2.toFloat();


    const dc = MCRandom.randomInt(2, 5)

    const fc = new MCMaths.Fraction(MCRandom.randomInt(2*dc + 1, 3*dc - 1)/dc);

    const p = (new MCMaths.Fraction(a_ans)).divide(fc)

    /* END VARIABLES AND CONSTANTS */
    if ( d1 !== d2 && f1.subtract(f2).toFloat() !== 0 && a_ans > 0 
      && a1.toFloat() % 1 === 0 && a2.toFloat() % 1 === 0 && p.toFloat() % 1 === 0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph('question', `${p1.name} has $£${m1}$. ${p1.HeShe} spends $\\displaystyle ${f1}$ of it on food and $\\displaystyle ${f2}$ on ${p1.heshe} clothes.`);

      question.addParagraph('question', `a) Calculate how much money ${p1.heshe} has left.`);
      question.addParagraph('question', `b) Express your answer to part (a) as a fraction of the original amount.`);

      question.addParagraph('question', `${p1.name} has $\\displaystyle ${fc.toMixed()}$ times as much money as ${p1.heshe} had before ${p1.heshe} was last paid.`);

      question.addParagraph('question', `c) How much money did ${p1.heshe} have before ${p1.heshe} was paid?`);

      // solution

      question.addHeading('solution', `a) ${p1.HeShe} spends $\\displaystyle ${f1}$ of it on food.`);
      question.addParagraph('solution', `$\\displaystyle ${f1}(${m1})=${a1}$`);
      question.addHeading('solution', `${p1.HeShe} spends $\\displaystyle ${f2}$ of it on clothes.`);
      question.addParagraph('solution', `$\\displaystyle ${f2}(${m1})=${a2}$`);

      question.addParagraph('solution', `$${m1}-${a1}-${a2}=${a_ans}$`);

      question.addParagraph('solution', `$\\therefore$ ${p1.name} has $£${a_ans}$ left.`);

      // b

      question.addHeading('solution', `b) ${p1.name} originally had $£${m1}$`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${a_ans}}{${m1}}=${b_ans}$`);

      question.addParagraph('solution', `$\\therefore$ ${p1.name} has $\\displaystyle ${b_ans}$ of the original amount.`);

      // c

      question.addHeading('solution', `c) Let the money ${p1.heshe} had before she was paid be $p$`);
      question.addParagraph('solution', `${p1.name} has $£${a_ans}$ now which is $\\displaystyle${fc.toMixed()}$ times what she had before, $p$`);
      question.addParagraph('solution', `$\\displaystyle ${fc}p=${a_ans}$`);
      question.addParagraph('solution', `$\\displaystyle p = ${(new MCMaths.Fraction(1)).divide(fc)} (${a_ans})$`);
      question.addParagraph('solution', `$\\therefore p = \\displaystyle ${p}$`);

      question.addParagraph('solution', `$\\therefore$ ${p1.name} had $£${p}$ before he was last paid.`);

      // answers

      const g1 = new MCQuestion.InputGroup(1);

      g1.addInput(a_ans, 0, `$(a)$`)
      g1.addInput(b_ans.toFloat(), 0.05, `$(b)$`)
      g1.addInput(p.toFloat(), 0, `$(c)$`)

      question.addInputGroup(g1)

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_FractionsOfQuantities_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p1 = MCMisc.getName();
    const p2 = MCMisc.getName();
    const t1 = MCRandom.randomInt(20, 80);

    const d1 = MCRandom.randomInt(3, 9)

    const f1 = new MCMaths.Fraction(MCRandom.randomInt(d1-2, d1-1)/d1);

    const d2 = MCRandom.randomInt(5, 9)

    const f2 = new MCMaths.Fraction(MCRandom.randomInt(d2-3, d2 - 1)/d2);


    const faster = MCRandom.randomInt(5, 15);
    const mean = MCRandom.randomInt(40, 60);

    const a_ans = f1.multiply(new MCMaths.Fraction(t1))

    const b_ans = (new MCMaths.Fraction(t1)).divide(f2)

    const pYear = mean - faster;

    const c_ans = new MCMaths.Fraction(mean/pYear);

    /* END VARIABLES AND CONSTANTS */
    if ( p1.name !== p2.name && a_ans.toFloat() % 1 === 0 && b_ans.toFloat() % 1 === 0
       && ((mean % 2 === 0 && pYear % 2 === 0) || (mean % 3 === 0 && pYear % 3 === 0) || (mean % 2 === 5 && pYear % 5 === 0))) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph('question', `${p1.name} finished a race with a time of $${t1}$ seconds. ${p1.HisHer} friend ${p2.name} completed the same race in $\\displaystyle ${f1}$ the time.`);

      question.addParagraph('question', `a) Calculate the time ${p2.name} ran the race in.`);

      question.addParagraph('question', `${p1.name}'s time was $\\displaystyle ${f2}$ of the slowest recorded time.`);

      question.addParagraph('question', `b) Calculate the slowest time recorded for the race.`);

      question.addParagraph('question', `The mean time for the current year's races is $${faster}$ seconds faster than the previous year at $${mean}$ seconds.`);

      question.addParagraph('question', `c) Express the current year's mean as a fraction of the previous year's.`);

      //solution

      question.addHeading('solution', `a) ${p2.name}'s time was $\\displaystyle ${f1}$ the time of ${p1.name}'s.`);
      question.addParagraph('solution', `$\\displaystyle ${f1}(${t1})=${a_ans}$`);
      question.addParagraph('solution', `$\\therefore$ ${p2.name} ran the race in $${a_ans}$ seconds.`);

      // b
      question.addHeading('solution', `b) Let the slowest time be $t$`);

      question.addParagraph('solution', `${p1.name}'s time of $${t1}$ seconds is $\\displaystyle ${f2}$ of the slowest time, $t$`);

      question.addParagraph('solution', `$\\displaystyle ${f2}t=${t1}$`);
      question.addParagraph('solution', `$\\displaystyle t = ${(new MCMaths.Fraction(1)).divide(f2)} (${t1})$`);
      question.addParagraph('solution', `$\\therefore t = \\displaystyle ${b_ans}$`);

      question.addParagraph('solution', `$\\therefore$ The slowest time was $${b_ans}$ seconds.`);

      // c

      question.addHeading('solution', `c) The previous year's mean time must be $${pYear}$ seconds because:`);
      question.addParagraph('solution', `$${mean}-${faster}=${pYear}$`);

      question.addParagraph('solution', `$\\displaystyle \\frac{${mean}}{${pYear}}=${c_ans}$`);

      question.addParagraph('solution', `$\\therefore$ This year's mean time is $\\displaystyle${c_ans}$ times that of the previous year's.`);

        // solution


        const g1 = new MCQuestion.InputGroup(1);

      g1.addInput(a_ans.toFloat(), 0, `$(a)$`)
      g1.addInput(b_ans.toFloat(), 0, `$(b)$`)
      g1.addInput(c_ans.toFloat(), 0.05, `$(c)$`)

      question.addInputGroup(g1)


      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_FractionsOfQuantities_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const p1 = MCMisc.getName();

    const a = MCRandom.randomInt(1000, 3000, 100);

    const d1 = MCRandom.randomInt(3, 10)

    const f1 = new MCMaths.Fraction(MCRandom.randomInt(Math.round(d1/2), d1-1)/d1);

    const b = new MCMaths.Fraction(a).divide(f1)

    const d2 = MCRandom.randomInt(3, 10)

    const f2 = new MCMaths.Fraction(MCRandom.randomInt(Math.round(d2/2), d2-1)/d2);

    const d3 = MCRandom.randomInt(3, 10)
    const f3 = new MCMaths.Fraction(MCRandom.randomInt(1, d3-1)/d3);

    const ab = a + b.toFloat();

    const c= (new MCMaths.Fraction(ab)).divide(f2)

    const c_ans = c.multiply(f3);
    /* END VARIABLES AND CONSTANTS */
    if ( b.toFloat() % 1 === 0 && c.toFloat() % 1 === 0 && c_ans.toFloat()%1===0) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph('question', `${p1.name} has two jugs, jug $A$ and jug $B$. Jug $A$ can hold $${a}$ ml while jug $B$ can hold $${b}$ ml.`);

      question.addParagraph('question', `a) Express the capacity of jug $A$ as a fraction of the capacity of jug $B$.`);
      question.addParagraph('question', `The combined capacity of both jugs is only $\\displaystyle ${f2}$ the capacity of another jug $C$.`);
      question.addParagraph('question', `b) Calculate the capacity of jug $C$.`);

      question.addParagraph('question', `c) How much liquid can jug $C$ hold when filled to $\\displaystyle ${f3}$ of its capacity.`);

      // solution

      question.addHeading('solution', `a) Jug $A$ holds $${a}$ ml and jug $B$ holds $${b}$ ml.`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${a}}{${b}}=${f1}$`);
      question.addParagraph('solution', `$\\therefore$ Jug $A$ has $\\displaystyle ${f1}$ the capacity of jug $B$.`);

      // b
      question.addHeading('solution', `b) Let the capacity of jug $C$ be $c$`);
      question.addParagraph('solution', `$${a}+${b}=${ab}$`);
      question.addParagraph('solution', `The total capacity of $A$ and $B\\ $ $(${ab}$ ml$)$ is $\\displaystyle ${f2}$ the capacity of jug $C$, $c$`);

      question.addParagraph('solution', `$\\displaystyle ${f2}c=${ab}$`);
      question.addParagraph('solution', `$\\displaystyle c = ${(new MCMaths.Fraction(1)).divide(f2)} (${ab})$`);
      question.addParagraph('solution', `$\\therefore c = \\displaystyle ${c}$`);

      question.addParagraph('solution', `$\\therefore$ Jug $C$ has a capcaity of $${c}$ ml.`);

      question.addHeading('solution', `c) Use the capacity of jug $C$ calculated in the previous part.`);
      question.addParagraph('solution', `$\\displaystyle ${f3}(${c})=${c_ans}$`);

      question.addParagraph('solution', `$\\therefore$ Jug $C$ can hold $${c_ans}$ ml at $\\displaystyle ${f3}$ capacity.`);

      // answers

      const g1 = new MCQuestion.InputGroup(1);

      g1.addInput(f1.toFloat(), 0.05, `$(a)$`)
      g1.addInput(c.toFloat(), 0, `$(b)$`)
      g1.addInput(c_ans.toFloat(), 0, `$(c)$`)

      question.addInputGroup(g1)

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_Percentages_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const tolerance1 = MCRandom.randomInt(5, 18);
    const tolerance2 = MCRandom.randomInt(5, 18);
    const tolerance3 = MCRandom.randomInt(5, 18);
    const speedLimits = [20, 30, 40, 50, 60];
    const speedLimit1 = speedLimits[MCRandom.randomInt(0, 4)];
    const speedLimit2 = speedLimits[MCRandom.randomInt(0, 4)];
    const speedLimit3 = speedLimits[MCRandom.randomInt(0, 4)];
    const speedAddition1 = MCRandom.randomInt(1, 12);
    const speedAddition2 = MCRandom.randomInt(1, 12);
    const speedAddition3 = MCRandom.randomInt(1, 12);
    const percentOver1 =
      ((speedLimit1 + speedAddition1) / speedLimit1) * 100 - 100;
    const percentOver2 =
      ((speedLimit2 + speedAddition2) / speedLimit2) * 100 - 100;
    const percentOver3 =
      ((speedLimit3 + speedAddition3) / speedLimit3) * 100 - 100;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "A series of $3$ speed cameras are set up on a particular route through a village.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Each of the cameras have a different level of tolerance on speed.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "If the speed of the vehicle is above the level of tolerance then the license details of the vehicle are recorded.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "People are warned to slow down if they are going above the level of tolerance.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "If the vehicle's details are recorded on all three roads then the owner of the vehicle will receive a speeding ticket.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Road 1 has a speed limit of $${speedLimit1}$ mph and the camera has $${tolerance1}\\%$ tolerance.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Road 2 has a speed limit of $${speedLimit2}$ mph and the camera has $${tolerance2}\\%$ tolerance.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Road 3 has a speed limit of $${speedLimit3}$ mph and the camera has $${tolerance3}\\%$ tolerance.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A vehicle travels on roads 1, 2 and 3 at $${
          speedLimit1 + speedAddition1
        }$ mph, $${speedLimit2 + speedAddition2}$ mph and $${
          speedLimit3 + speedAddition3
        }$ mph respectively.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Work out the percentage above the limit that the vehicle travels down each road and hence conclude whether the owner will receive a speeding ticket.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Percentage over limit on road 1 = ((speed travelled $ \\div$ speed limit) $ \\times$ $100$) $- 100$ = $${percentOver1}\\%$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Percentage over limit on road 2 = $${percentOver2}\\%$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Percentage over limit on road 3 = $${percentOver3}\\%$`,
        false,
        true,
        true
      )
    );
    if (
      percentOver1 > tolerance1 &&
      percentOver2 > tolerance2 &&
      percentOver3 > tolerance3
    ) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          "All three percentages are greater than their respective tolerances and therefore the owner will receive a speeding ticket.",
          false,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          "At least one percentage is less than its respective tolerance and so the owner of the vehicle will not receive a ticket.",
          false,
          true,
          true
        )
      );
    }

    question.requiresSelfMarking();

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      speedLimit1 === speedLimit2 ||
      speedLimit1 === speedLimit3 ||
      speedLimit2 === speedLimit3 ||
      tolerance1 === tolerance2 ||
      tolerance1 === tolerance3 ||
      tolerance2 === tolerance3 ||
      percentOver1 !== Math.round(percentOver1) ||
      percentOver2 !== Math.round(percentOver2) ||
      percentOver3 !== Math.round(percentOver3)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// A series of 3 speed cameras are set up on a particular route through a village.
// Each camera has a different tolerance on a vehicle's speed.
// Road 1 has a speed limit of x and the camera has a 5% tolerance.
// Road 2 has a speed limit of y and the camera has a 15% tolerance.
// Road 3 has a speed limit of z and the camera has a 12% tolerance.
// If the camera records the vehicle's speed on all three roads then the person receives a ticket.


export function GCSE_Foundation_Rparoc_Rparoc_Percentages_Exam_Q10() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name = MCMisc.getName();
    const calorieGoal = MCRandom.randomInt(400, 700);
    const exerciseMinsGoal = MCRandom.randomInt(30, 100);
    const perc1 = MCRandom.randomInt(50, 85);
    const calories = calorieGoal * (perc1 / 100);
    const perc2 = MCRandom.randomInt(30, 60);
    const exerciseComplete = 100 - perc2;
    const exerciseMinsCompleted = exerciseMinsGoal * (perc2 / 100);
    const mult1 = perc1 / 100;
    const mult2 = exerciseComplete / 100;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has just bought a new Keep Fit wristband.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} has set ${name.hisher} daily calories burned and minutes of exercise goals.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `On a particular day ${name.heshe} completes $${perc1}\\%$ of ${name.hisher} calorie goal having burned $${calories}$ calories.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `(a) What is ${name.name}'s daily calorie goal?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `On the same day ${name.name} was $${perc2}\\%$ away from meeting his exercise minutes goal.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} had completed $${exerciseMinsCompleted}$ minutes.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `(b) What is ${name.name}'s daily exercise goal in minutes?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("(a)", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Calorie goal $ \\times$ $${mult1}$ $= ${calories}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Calorie goal $= ${calories}  \\div ${mult1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Calorie goal $= ${calorieGoal}$ calories`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("(b)", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${perc2}\\%$ away from completion = $${exerciseComplete}\\%$ complete`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Exercise goal $ \\times$ $${mult2}$ $= ${exerciseMinsCompleted}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Calorie goal $= ${exerciseMinsCompleted}  \\div ${mult2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Calorie goal $= ${exerciseMinsGoal}$ minutes`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(calorieGoal, 0.1, "$(a)$");
    group.addInput(exerciseMinsGoal, 0.1, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      calorieGoal / 10 !== Math.round(calorieGoal / 10) ||
      exerciseMinsGoal / 5 !== Math.round(exerciseMinsGoal / 5) ||
      calories !== Math.round(calories) ||
      perc1 === 75 ||
      perc1 === 50 ||
      perc1 === 60 ||
      perc1 === 70 ||
      perc1 === 80 ||
      name.name.slice(-1) === "s"
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Percentages_Exam_Q11() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const tax = MCRandom.randomInt(7, 17);
    let priceBeforeTax = 1;
    const penceBefore = [0.6, 0.7, 0.75, 0.8];
    priceBeforeTax += penceBefore[MCRandom.randomInt(0, 3)];
    const priceBeforeInPence = priceBeforeTax * 100;
    const priceIncrease = ((priceBeforeInPence * tax) / 100).toFixed(0);
    const actualPriceBefore = Math.round(priceIncrease / (tax / 100));
    const actualPriceAfter =
      parseInt(actualPriceBefore, 10) + parseInt(priceIncrease, 10);
    const pounds = actualPriceAfter / 100;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The price of all quantities of crazy-cola has increased by $${tax}\\%$ due to added tax.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The price of a $375$ml bottle of crazy-cola has increased by $${priceIncrease}$ pence.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Work out the new price of a $375$ml bottle.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Price before tax $ \\times$ $${tax / 100}$ $=$ $${priceIncrease}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Price before tax $=$ $${priceIncrease}  \\div ${tax / 100}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Price before tax $=$ $${actualPriceBefore}$ pence`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Price after tax $=$ $${actualPriceBefore} + ${priceIncrease}$ `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Price after tax $=$ $${actualPriceAfter}$ pence`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Price after tax $=$ $${DisplayMoney(pounds.toFixed(2) * 100)}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(pounds, 0.01, "$\\pounds$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (tax === 10) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Percentages_Exam_Q12() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    function roundToTwo(num) {
      return +`${Math.round(`${num}e+2`)}e-2`;
    }

    const person = MCMisc.getName();
    const loan = MCRandom.randomInt(10, 150) * 100;
    const bankInterestRate = MCRandom.randomInt(15, 50) / 1000;
    const bankInterestRatePerc = roundToTwo(bankInterestRate * 100);
    const numOfYears = MCRandom.randomInt(2, 10);
    const interestPaid = bankInterestRate * loan;
    const totalInterestPaid = interestPaid * numOfYears;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} needs money to start up a new business.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.HeShe} decides to take a loan out from a bank.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The bank charges $${bankInterestRatePerc}\\%$ simple interest for every year the money is borrowed for.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} takes out a loan of $\\pounds${loan}$ and decides to pay it off over $${numOfYears}$ years.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `   $(a)$ How much interest does ${person.name} need to pay to the bank every year?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `   $(b)$ How much $total$ interest does ${person.name} need to pay to the bank?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(a)$ To calculate the yearly amount of interest ${person.name} needs to pay we first need to convert the percentage to a decimal:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${bankInterestRatePerc}  \\div 100 = ${bankInterestRate}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Then multiply this decimal version of the interest rate by the amount ${person.name} has taken out as a loan:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${bankInterestRate}  \\times ${loan} = ${interestPaid}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore ${person.name} pays $${DisplayMoney(
          interestPaid.toFixed(2) * 100
        )}$ interest every year.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $(b)$ To find how much total interest ${person.name} pays, multiply the yearly interest paid by the amount of years ${person.heshe} took the loan out for:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${interestPaid}  \\times ${numOfYears} = ${totalInterestPaid}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore ${person.name} pays $${DisplayMoney(
          totalInterestPaid.toFixed(2) * 100
        )}$ total interest.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(interestPaid, 0.01, "$(a) \\ \\pounds $");
    group.addInput(totalInterestPaid, 0.01, "$(b) \\ \\pounds $");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (loan % 500 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Percentages_Exam_Q13() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name = MCMisc.getName();
    const initialCost = MCRandom.randomInt(999, 2499);
    const depreciationPercentageYear1 = MCRandom.randomInt(12, 30);
    const depreciationPercentageYearByYear = MCRandom.randomInt(6, 10);
    const currentTime = new Date();
    const year = currentTime.getFullYear();
    const nextYear = year + 1;
    const yearsLater = MCRandom.randomInt(3, 6);
    const yearsAfterBuying = nextYear + yearsLater;
    const percentageMultiplierYear1 = 1 - depreciationPercentageYear1 / 100;
    const afterYear1 = percentageMultiplierYear1 * initialCost;
    const yearsLeft = yearsLater - 1;
    const finalValue =
      afterYear1 * (1 - depreciationPercentageYearByYear / 100) ** yearsLeft;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} is looking to buy a new computer in $${nextYear}$. The computer will have a value of $\\pounds${initialCost}$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} has been looking on an auction website to gather data on how much similar models depreciate year by year.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} finds that the computer will depreciate in value:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `by $${depreciationPercentageYear1}\\%$ in the first year`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `and by $${depreciationPercentageYearByYear}\\%$ each year after`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Find the value of the computer in $${yearsAfterBuying}$.`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Value after year 1 = initial value - $${depreciationPercentageYear1}\\% = ${initialCost}  \\times ${percentageMultiplierYear1} = ${afterYear1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Remaining years = number of years - $1$ = $${yearsLater} - 1 = ${yearsLeft}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Value after following $${yearsLeft}$ years = $${afterYear1}  \\times (1-${
          depreciationPercentageYear1 / 100
        })^{${yearsLeft}} = ${parseFloat(finalValue.toFixed(4))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ Value of computer in $${yearsAfterBuying}$ = $${DisplayMoney(
          finalValue.toFixed(2) * 100
        )}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(finalValue, 0.1, "$\\pounds$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (afterYear1 * 100 !== Math.round(100 * afterYear1)) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Percentages_Exam_Q2() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name1 = MCMisc.getName();
    const pay = Math.round(Math.random() * 16 + 16) * 50;
    const percentagesaved = Math.round(Math.random() * 16 + 2) * 2.5;
    const fraction = Math.round(Math.random() * 4) / 10;
    const saved = (pay * percentagesaved) / 100;
    const rent = pay * fraction;
    const payleft = pay - rent - saved;
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} gets paid $\\pounds${pay}$ a month.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.HeShe} saves $${percentagesaved}\\% $.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.HeShe} spends $${fraction}$ on his rent.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `How much does ${name1.heshe} have left?`,
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Money saved:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `\${${percentagesaved}\\over100} \\times ${pay} = ${DisplayMoney(
          saved.toFixed(2) * 100
        )} $`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Money spent on rent:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${fraction}$ $ \\times ${pay} = ${DisplayMoney(
          rent.toFixed(2) * 100
        )} $`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Money left:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${pay} - ${saved} - ${rent} = ${DisplayMoney(
          payleft.toFixed(2) * 100
        )} $`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(payleft, 0.01, "$\\pounds$");
    question.addInputGroup(group);

    if (
      Math.round(rent * 10) !== rent * 10 ||
      Math.round(saved * 10) !== saved * 10 ||
      fraction === 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Percentages_Exam_Q3() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const items = ["dress", "shirt", "hat", "bag"];
    const item1 = items[MCRandom.randomInt(0, 3)];
    const priceToDecreaseInt = MCRandom.randomInt(40, 60);
    const priceToDecreaseFloat = Math.random().toFixed(2);
    const priceToDecrease = (
      priceToDecreaseInt +
      (priceToDecreaseFloat * 100) / 100
    ).toFixed(2);
    const item2 = items[MCRandom.randomInt(0, 3)];
    const priceToIncreaseInt = MCRandom.randomInt(20, 40);
    const priceToIncreaseFloat = Math.random().toFixed(2);
    const priceToIncrease = (
      priceToIncreaseInt +
      (priceToIncreaseFloat * 100) / 100
    ).toFixed(2);
    const percentages = [10, 20, 25, 30, 40, 60, 70];
    const percentageDecrease = percentages[MCRandom.randomInt(0, 6)];
    const multiplier = 1 - percentageDecrease / 100;
    const numerators = [1, 3, 5, 7];
    const denominators = [4, 5, 8];
    const numerator = numerators[MCRandom.randomInt(0, 3)];
    const denominator = denominators[MCRandom.randomInt(0, 2)];
    const fraction = numerator / denominator;
    const salePrice = multiplier * priceToDecrease;
    const percentageOf = 100 - fraction * 100;
    const divisor = percentageOf / 100;
    const originalPrice = priceToIncrease / divisor;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `In a shop, the original price of a ${item1} is $\\pounds${priceToDecrease}$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `There is a sale on that reduces the price of the ${item1} by $${percentageDecrease}\\%$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `(a) Work out the price of the ${item1} in the sale.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The price of a ${item2} in the sale is $\\pounds${priceToIncrease}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The original price was reduced by $${fraction}$ to arrive at the sale price.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `(b) Work out the original price of the ${item1}.`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `(a) Sale price = original price $-$ $${percentageDecrease}\\% = ${priceToDecrease}  \\times ${multiplier} = ${DisplayMoney(
          salePrice.toFixed(2) * 100
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `(b) Sale price is $${percentageOf}\\%$ of the original price.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Original price $ \\times ${divisor} = $ sale price`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Original price $=$ sale price $ \\div ${divisor} $`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${priceToIncrease}  \\div ${divisor} = ${DisplayMoney(
          originalPrice.toFixed(2) * 100
        )}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(salePrice, 0.01, "$(a)$");
    group.addInput(originalPrice, 0.01, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      priceToDecrease === priceToIncrease ||
      item1 === item2 ||
      numerator >= denominator ||
      fraction === null ||
      (numerator === 3 && denominator === 6)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Percentages_Exam_Q4() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name1 = MCMisc.getName();
    const name2 = MCMisc.getName();
    const money1 = Math.round(Math.random() * 6 + 4) * 50;
    const money2 = Math.round(Math.random() * 6 + 4) * 50;
    const fraction1 = MCRandom.randomInt(1, 56) / 60;
    const percentage2 = Math.round(Math.random() * 15 + 2) * 5;
    const savings1 = money1 * fraction1;
    const savings2 = (money2 * percentage2) / 100;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} gets paid $\\pounds${money1}$ a week.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name2.name} gets paid $\\pounds${money2}$ a week.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} puts $${fraction1}$ of ${name1.hisher} money in savings a week.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name2.name} puts $${percentage2}\\%$ of ${name2.hisher} money in savings a week.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Who is saving the most money?", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("You must show your working out.", false, true, true)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${name1.name}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`\${${fraction1}} \\times${money1}$`, true, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$=${DisplayMoney(parseFloat(savings1).toFixed(2) * 100)}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${name2.name}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `\${${percentage2}\\over100} \\times${money2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$=${DisplayMoney(parseFloat(savings2).toFixed(2) * 100)}$`,
        false,
        true,
        true
      )
    );
    if (savings1 > savings2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `Therefore ${name1.name} saves more.`,
          false,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `Therefore ${name2.name} saves more.`,
          false,
          true,
          true
        )
      );
    }

    question.requiresSelfMarking();

    if (
      fraction1 === percentage2 / 100 ||
      fraction1 - percentage2 / 100 > 0.2 ||
      fraction1 - percentage2 / 100 < -0.2 ||
      (fraction1 > percentage2 / 100 && money1 > money2) ||
      (fraction1 < percentage2 / 100 && money1 < money2) ||
      name1 === name2 ||
      money2 === money1 ||
      savings1 * 100 !== Math.round(savings1 * 100) ||
      savings2 * 100 !== Math.round(savings2 * 100)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Percentages_Exam_Q5() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name = MCMisc.getName();
    const dayAndNight = MCRandom.randomInt(50, 75);
    const day = MCRandom.randomInt(25, 40);
    const vatPercentage = 20;
    const creditCardFee = MCRandom.randomInt(2, 5);
    const x = MCRandom.randomInt(2, 10);
    const y = MCRandom.randomInt(2, 10);
    const totalDayNight = x * dayAndNight;
    const totalDay = y * day;
    const initialTotal = totalDayNight + totalDay;
    const afterVAT = initialTotal * 1.2;
    const afterCreditCard = afterVAT * (1 + creditCardFee / 100);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.name} is a tour guide.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} provides tours of London.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Customers are able to buy combined day and night tickets or day only tickets.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} had the following tours booked for a particular day:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${x}$ combined day and night tickets at $\\pounds${dayAndNight}$ each`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${y}$ day only tickets at $\\pounds${day}$ each`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The prices of these tickets are subject to VAT at an additional $20\\%.$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `An additional $${creditCardFee}\\%$ fee is added when paying by credit card.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Work out ${name.name}'s total revenue from this particular day if all customers pay by credit card.`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Find the total cost of both ticket types combined:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Day and night total = $${x}  \\times ${dayAndNight} = ${totalDayNight}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Day only total = $${y}  \\times ${day} = ${totalDay}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Total cost = $${totalDayNight} + ${totalDay} = ${initialTotal}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Increase the value by $20\\%$ for VAT:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Find $20\\%$:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$10\\% = ${initialTotal}  \\div 10 = ${initialTotal / 10}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$20\\% = ${initialTotal / 10}  \\times 2 = ${initialTotal / 5}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Revenue after VAT $= ${
          initialTotal / 5
        } + ${initialTotal} = ${afterVAT}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Increase the revenue by $${creditCardFee}\\%$ for credit card fee:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Find $${creditCardFee}\\%$:`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$1\\% = ${afterVAT}  \\div 100 = ${parseFloat(
          (afterVAT / 100).toFixed(4)
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${creditCardFee}\\% = ${parseFloat(
          (afterVAT / 100).toFixed(4)
        )}  \\times ${creditCardFee} = ${parseFloat(
          (afterVAT * (creditCardFee / 100)).toFixed(4)
        )}$`,
        false,
        true,
        true
      )
    ); // afterVAT*(creditCardFee/100),false,true,true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Revenue after credit card fee $= ${parseFloat(
          (afterVAT * (creditCardFee / 100)).toFixed(4)
        )} + ${afterVAT} = ${afterCreditCard}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Total revenue = $${DisplayMoney(afterCreditCard.toFixed(2) * 100)}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(afterCreditCard, 0.01, "$\\pounds$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (x === y) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Percentages_Exam_Q6() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    function roundToTwo(num) {
      return +`${Math.round(`${num}e+2`)}e-2`;
    }

    const person = MCMisc.getName();
    const itemList = ["watch", "necklace", "bracelet", "ring"];
    const item = itemList[MCRandom.randomInt(0, itemList.length - 1)];
    const priceAfterTax = MCRandom.randomInt(5, 100) * 10;
    const vat = 20;
    const vatDecimal = vat / 100;
    const vatMultiplier = roundToTwo(1 + vatDecimal);
    const priceBeforeTax = roundToTwo(priceAfterTax / vatMultiplier);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} walks into a jewellery shop and wants to buy a ${item} for ${person.hisher} Mum, for mother's day.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` Since ${person.name} is a student, ${person.heshe} is elligible for discount which reduces the price of the items to what their price was before value added tax (VAT).`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The price of the ${item}, after VAT, is $\\pounds${priceAfterTax}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The current rate of VAT is $${vat}\\%$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` What was the price of the ${item} before VAT?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` As the original price has been increase by $${vat}\\%$:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${100 + vat}\\%$ of original price $= ${priceAfterTax}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" $ \\div 120$ gives:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $1\\% = ${priceAfterTax / 120}$.`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" $ \\times 100$ gives:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $100\\% = ${(priceAfterTax / 120) * 100}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore the price before VAT is $\\pounds${priceBeforeTax}$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(priceBeforeTax, 0.01, "$ \\pounds $");
    question.addInputGroup(group);
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (Math.round(priceBeforeTax * 10) === priceBeforeTax * 10) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Percentages_Exam_Q7() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const currentYear = new Date().getFullYear();
    const twoYearsAgo = currentYear - 2;
    const oneYearAgo = currentYear - 1;
    const cities = [
      "Liverpool",
      "Coventry",
      "Manchester",
      "Newcastle",
      "Nottingham",
      "Bath",
      "Bristol",
      "Sheffield",
      "Oxford",
      "Cambridge",
      "Edinburgh",
      "Glasgow",
      "Leeds",
      "Lincoln",
      "Cardiff",
      "Swansea",
      "Exeter",
      "Hull",
      "York",
    ];
    const city = cities[MCRandom.randomInt(0, 18)];
    let percentage = MCRandom.randomInt(0, 2);
    const addDec = MCRandom.randomInt(1, 1);
    if (addDec === 1) {
      const decs = [
        0.1,
        0.15,
        0.2,
        0.25,
        0.3,
        0.4,
        0.5,
        0.6,
        0.65,
        0.7,
        0.75,
        0.8,
        0.95,
      ];
      const dec = decs[MCRandom.randomInt(0, 12)];
      percentage += dec;
    }
    const population = MCRandom.randomInt(150000, 450000);

    function numberWithCommas(x2) {
      let x = x2.toString();
      const pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    }

    const popWithComma = numberWithCommas(population);
    const multiplier = ((1 + percentage / 100) * 100) / 100;
    let populationTwoYearsAgo = population / multiplier;
    populationTwoYearsAgo = populationTwoYearsAgo.toFixed(3);

    const populationTwoYearsAgo2 = numberWithCommas(
      Math.round(populationTwoYearsAgo)
    );

    const percOver100 = 100 + percentage;
    const onePerc = population / percOver100;
    const onePerc1 = onePerc.toFixed(3);
    let onehundPerc = onePerc * 100;
    onehundPerc = onehundPerc.toFixed(3);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The population of ${city} in $${oneYearAgo}$ was $${popWithComma}$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `This was an increase of approximately $${percentage}\\%$ from $${twoYearsAgo}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `What was the total population of ${city} in $${twoYearsAgo}$?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Percentage increase from $${twoYearsAgo}$ is $${percentage}\\%$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ Population in $${oneYearAgo}$ $=$ population in $${twoYearsAgo}  \\times ${multiplier}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${popWithComma} =$ population in $${twoYearsAgo}  \\times ${multiplier}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ Population in $${twoYearsAgo}$ $=$ $${popWithComma}  \\div ${multiplier}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Population in $${twoYearsAgo}$ $=$ $${populationTwoYearsAgo}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Population in $${twoYearsAgo}$ $=$ $${populationTwoYearsAgo2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Alternative Method", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${percOver100}\\% = ${popWithComma}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$1\\% = ${onePerc1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$100\\% = ${onehundPerc}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ Population in $${twoYearsAgo} = ${populationTwoYearsAgo2}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(population / multiplier, 0.5);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Percentages_Exam_Q8() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p `;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const items = ["laptop", "phone", "bicycle", "car"];
    const item = items[MCRandom.randomInt(0, 3)];

    let profitMargin = MCRandom.randomInt(5, 14);
    const addDec = MCRandom.randomInt(0, 1);
    if (addDec === 1) {
      const decs = [
        0.1,
        0.15,
        0.125,
        0.2,
        0.25,
        0.3,
        0.4,
        0.5,
        0.6,
        0.625,
        0.65,
        0.7,
        0.75,
        0.8,
        0.9,
      ];
      const dec = decs[MCRandom.randomInt(0, 14)];
      profitMargin += dec;
    }

    const name1 = MCMisc.getName();
    const name2 = MCMisc.getName();

    let lossMargin = MCRandom.randomInt(5, 25);
    const addDec1 = MCRandom.randomInt(0, 1);
    if (addDec1 === 1) {
      const decs = [
        0.1,
        0.15,
        0.125,
        0.2,
        0.25,
        0.3,
        0.4,
        0.5,
        0.6,
        0.625,
        0.65,
        0.7,
        0.75,
        0.8,
        0.9,
      ];
      const dec = decs[MCRandom.randomInt(0, 14)];
      lossMargin += dec;
    }
    const price = MCRandom.randomInt(300, 700);
    const multiplier1 = 1 + profitMargin / 100;
    const ansA = price / multiplier1;
    const multiplier2 = 1 - lossMargin / 100;
    const ansB = ansA.toFixed(2) / multiplier2;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} is selling ${name1.hisher} ${item} that ${name1.heshe} bought when it was new.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.HeShe} sells it to ${name2.name} and makes a $${lossMargin}\\%$ loss on the price she paid.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name2.name} then sells the ${item} on for $\\pounds${price}$ and makes a $${profitMargin}\\%$ profit.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `(a) Find the price that ${name2.name} paid for the ${item}.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `(b) Find the original cost of the ${item}.`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("(a)", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${name2.name} makes $${profitMargin}\\%$ profit $\\therefore$ the price ${name2.heshe} paid $ \\times$ $${multiplier1} = ${price}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Price ${name2.name} paid $ \\times$ $${multiplier1}$ $= ${price}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Price ${name2.name} paid $= ${price}  \\div ${multiplier1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Price ${name2.name} paid $= ${DisplayMoney(ansA.toFixed(2) * 100)}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("(b)", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${name1.name} makes $${lossMargin}\\%$ loss $\\therefore$ the price ${name1.heshe} paid $ \\times$ $${multiplier2} = ${ansA}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Price ${name1.name} paid $ \\times$ $${multiplier2}$ $= ${ansA}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Price ${name1.name} paid $= ${ansA}  \\div ${multiplier2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Price ${name1.name} paid $= ${DisplayMoney(ansB.toFixed(2) * 100)}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ansA, 0.01, "$(a)$");
    group.addInput(ansB, 0.01, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      name1.name === name2.name ||
      price / 5 !== Math.round(price / 5) ||
      profitMargin === lossMargin
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Percentages_Exam_Q9() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const categories = [
      "mens clothing",
      "womens clothing",
      "electricals",
      "sport & leisure",
      "furniture",
    ];
    const cat1 = categories[MCRandom.randomInt(0, 4)];
    const cat2 = categories[MCRandom.randomInt(0, 4)];
    const mens = ["shirt", "blazer", "tie", "jacket", "coat", "hoody"];
    const womens = [
      "coat",
      "jacket",
      "dress",
      "jumpsuit",
      "playsuit",
      "blouse",
      "skirt",
    ];
    const electricals = ["laptop", "phone", "TV", "monitor"];
    const sports = [
      "yoga mat",
      "tennis racket",
      "badminton racket",
      "box of tennis balls",
      "box of shuttlecocks",
      "box of golf balls",
      "bag of golf clubs",
      "football",
      "basketball",
      "snorkel",
    ];
    const furnitures = [
      "chest of drawers",
      "bedside table",
      "desk",
      "mattress",
      "wardrobe",
      "sofa",
      "dining table",
    ];
    const name = MCMisc.getName();
    let cat1Item = "";
    let cat2Item = "";
    let cat1ItemPrice = 0;
    let cat2ItemPrice = 0;
    function giveRandomPrice(lowerInt, upperInt) {
      const decs = [0.99, 0.5];
      const dec = decs[MCRandom.randomInt(0, 1)];
      const intPrice = MCRandom.randomInt(lowerInt, upperInt);
      return intPrice + dec;
    }
    if (cat1 === "mens clothing") {
      cat1Item = mens[MCRandom.randomInt(0, 5)];
      cat1ItemPrice = giveRandomPrice(20, 44);
    } else if (cat1 === "womens clothing") {
      cat1Item = womens[MCRandom.randomInt(0, 6)];
      cat1ItemPrice = giveRandomPrice(24, 44);
    } else if (cat1 === "electricals") {
      cat1Item = electricals[MCRandom.randomInt(0, 3)];
      cat1ItemPrice = giveRandomPrice(600, 999);
    } else if (cat1 === "sport & leisure") {
      cat1Item = sports[MCRandom.randomInt(0, 9)];
      cat1ItemPrice = giveRandomPrice(29, 59);
    } else if (cat1 === "furniture") {
      cat1Item = furnitures[MCRandom.randomInt(0, 6)];
      cat1ItemPrice = giveRandomPrice(199, 399);
    }
    if (cat2 === "mens clothing") {
      cat2Item = mens[MCRandom.randomInt(0, 5)];
      cat2ItemPrice = giveRandomPrice(20, 44);
    } else if (cat2 === "womens clothing") {
      cat2Item = womens[MCRandom.randomInt(0, 6)];
      cat2ItemPrice = giveRandomPrice(24, 44);
    } else if (cat2 === "electricals") {
      cat2Item = electricals[MCRandom.randomInt(0, 3)];
      cat2ItemPrice = giveRandomPrice(600, 999);
    } else if (cat2 === "sport & leisure") {
      cat2Item = sports[MCRandom.randomInt(0, 9)];
      cat2ItemPrice = giveRandomPrice(29, 59);
    } else if (cat2 === "furniture") {
      cat2Item = furnitures[MCRandom.randomInt(0, 6)];
      cat2ItemPrice = giveRandomPrice(199, 399);
    }
    function randomPerc(lower, upper) {
      const decs = [
        0.1,
        0.15,
        0.2,
        0.25,
        0.3,
        0.4,
        0.5,
        0.6,
        0.7,
        0.75,
        0.8,
        0.9,
      ];
      const dec = decs[MCRandom.randomInt(0, 11)];
      const percInt = MCRandom.randomInt(lower, upper);
      return percInt + dec;
    }
    const perc1Sale = randomPerc(10, 50);
    const perc2Sale = randomPerc(10, 50);
    const totalPrice = cat1ItemPrice + cat2ItemPrice;
    const cat2Mult = 1 - perc2Sale / 100;
    const cat2PriceBefore = (cat2ItemPrice / cat2Mult).toFixed(2);
    const cat1Mult = 1 - perc1Sale / 100;
    const cat1PriceBefore = (cat1ItemPrice / cat1Mult).toFixed(2);
    const originalCost = (
      parseFloat(cat1PriceBefore) + parseFloat(cat2PriceBefore)
    ).toFixed(2);
    const moneySaved = (
      parseFloat(originalCost) - parseFloat(totalPrice)
    ).toFixed(2);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("A department store has a sale on.", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The store has $${perc1Sale}\\%$ off of ${cat1} and $${perc2Sale}\\%$ off of ${cat2}.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} buys a ${cat1Item} and a ${cat2Item} for a total of $\\pounds${totalPrice}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The original price of the ${cat2Item} before the sale was $\\pounds${cat2PriceBefore}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Find the amount of money that ${name.name} saved as a result of buying the items in the sale.`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Find the price of the ${cat2Item} in sale:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${perc2Sale}\\%$ off of ${cat2} $\\therefore$ original price $ \\times$ $${cat2Mult} =$ sale price `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${cat2PriceBefore}$ $ \\times$ $${cat2Mult} =$ sale price`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Sale Price = $${DisplayMoney(cat2ItemPrice.toFixed(2) * 100)}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Find the price of the ${cat1Item} in sale:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Sale price of ${cat1Item} = total paid $-$ cost of ${cat2Item}`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Sale price of ${cat1Item} = $${totalPrice}$ $-$ $${cat2ItemPrice.toFixed(
          2
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Sale price of ${cat1Item} = $${DisplayMoney(
          cat1ItemPrice.toFixed(2) * 100
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Find the original price of the ${cat1Item}:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${perc1Sale}\\%$ off of ${cat1} $\\therefore$ original price $ \\times$ $${cat1Mult} =$ sale price`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Original price $ \\times$ $${cat1Mult} =$ $${cat1ItemPrice}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Original price $=$ $${cat1ItemPrice}  \\div ${cat1Mult}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Original price $=$ $${cat1PriceBefore}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Find the original price of the sum of the items:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Original total = original cost of ${cat1Item} $+$ original cost of ${cat2Item}`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Original total = $${cat1PriceBefore}$ $+$ $${cat2PriceBefore}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Original total = $${originalCost}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Find the difference between the original cost and the sale price:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Money saved = original price $-$ sale price",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Money saved = $${originalCost}$ $-$ $${totalPrice}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Money saved = $\\pounds${moneySaved}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(
      parseFloat(originalCost) - parseFloat(totalPrice),
      0.01,
      "$\\pounds$"
    );
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      cat1 === cat2 ||
      Math.abs(perc1Sale - perc2Sale) < 5 ||
      (name.sex === "male" && cat1 === "womens clothing") ||
      (name.sex === "male" && cat2 === "womens clothing") ||
      (name.sex === "female" && cat1 === "mens clothing") ||
      (name.sex === "female" && cat2 === "mens clothing")
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Proportion_Exam_Q1() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const countryList = [
      "Austria",
      "Belgium",
      "Cyprus",
      "Estonia",
      "Finland",
      "France",
      "Germany",
      "Italy",
    ];
    const country = `${
      countryList[MCRandom.randomInt(0, countryList.length - 1)]
    }`;
    const exchangeRate = MCRandom.randomInt(69, 93) / 100;
    const laptop1Price = MCRandom.randomInt(300, 1000);
    const laptop2Price = MCRandom.randomInt(400, 1100);
    const laptop2PriceGBP = laptop2Price * exchangeRate;
    const randomSwitch = MCRandom.randomInt(0, 1); // If === 0 then laptop1 is cheaper. If === 1 then laptop is more expensive.
    let cheaperOrNot = "";
    if (randomSwitch === 0) {
      cheaperOrNot = "cheaper";
    } else {
      cheaperOrNot = "more expensive";
    }

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} wants to buy a specific model of a laptop.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.HeShe} finds the model ${person.heshe} was looking for on two different online stores and wants to know which one is cheapest.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` One store is based in ${country} and the other is based in the UK.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The current exchange rate for euroes (€) to pounds ($\\pounds$) is currently $1:${exchangeRate}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The laptop on the store based in the UK costs $${DisplayMoney(
          laptop1Price.toFixed(2) * 100
        )}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The laptop on the store based in ${country} costs €$${laptop2Price.toFixed(
          2
        )}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " Which store sells the laptop for the cheapest (in pounds)?",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " First we must change the price of the european laptop from being in euros (€) to being in pounds ($\\pounds$).",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " We do this by multiplying the price in euros by the exchange rate:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${laptop2Price}  \\times ${exchangeRate} = ${laptop2PriceGBP}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore the laptop that was being sold by the store based in ${country} costs $${DisplayMoney(
          laptop2PriceGBP.toFixed(2) * 100
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Hence the laptop based in the UK is ${cheaperOrNot} than the laptop being sold from the store based in ${country}.`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    // This makes it so the prices are realistic and that either laptop can be the more expensive one.
    if (
      (randomSwitch === 0 && laptop1Price > laptop2PriceGBP) ||
      (randomSwitch === 1 && laptop1Price < laptop2PriceGBP) ||
      laptop2Price < laptop1Price ||
      laptop1Price === laptop2Price ||
      laptop1Price === laptop2PriceGBP
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Proportion_Exam_Q2() {
  function Weight(input) {
    if (input >= 1000) {
      return `$${input / 1000}kg$`;
    }
    return `$${input}g$`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const itemlist = ["tin of beans", "box of strawberries", "bag of sweets"];
    const itemslist = [
      "tins of beans",
      "boxes of strawberries",
      "bags of sweets",
    ];
    const itemType = MCRandom.randomInt(0, itemlist.length - 1);
    const singleItem = itemlist[itemType];
    const pluralItem = itemslist[itemType];
    const itemlist2 = [
      "barrel of beans",
      "crate of strawberries",
      "box of sweets",
    ];
    const itemslist2 = [
      "barrels of beans",
      "crates of strawberries",
      "boxes of sweets",
    ];
    const singleItem2 = itemlist2[itemType];
    const pluralItem2 = itemslist2[itemType];

    const numberItem = MCRandom.randomInt(2, 12);

    const numberItem3 = MCRandom.randomInt(2, 12);
    const numberItem4 = MCRandom.randomInt(2, 12);
    const numberItem5 = MCRandom.randomInt(2, 12);
    const numberItem6 = MCRandom.randomInt(2, 12);
    const weightItem = MCRandom.randomInt(50, 100, 10);
    const weightItem2 = MCRandom.randomInt(200, 1000, 10);

    const totalweight1 = numberItem * weightItem;
    const totalweight2 = numberItem3 * weightItem + numberItem4 * weightItem2;

    const solution1 = numberItem3 * weightItem;
    const solution2 = totalweight2 - solution1;
    const finalSolution = numberItem5 * weightItem + numberItem6 * weightItem2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${numberItem}$ ${pluralItem} weigh ${Weight(totalweight1)}.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${numberItem3}$ ${pluralItem} and $${numberItem4}$ ${pluralItem2} weigh ${Weight(
          totalweight2
        )}.`,
        false,
        true,
        true
      )
    );
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `How much do $${numberItem5}$ ${pluralItem} and $${numberItem6}$ ${pluralItem2} weigh, in kilos?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Find the weight of each ${pluralItem} $=$ ${Weight(
          totalweight1
        )}$ \\div${numberItem} = $${Weight(weightItem)}`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Finding the weight of $${numberItem3}$ ${pluralItem} $= ${numberItem3} \\times$${Weight(
          weightItem
        )} $=$ ${Weight(solution1)}`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Subtracting from the total weight $=$ ${Weight(
          totalweight2
        )}$-$${Weight(solution1)} $=$ ${Weight(solution2)}`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Dividing by the number of ${pluralItem2} $=$ ${Weight(
          solution2
        )}$ \\div${numberItem4} =$ ${Weight(weightItem2)}`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Add the weights of $${numberItem5}$ ${pluralItem} and $${numberItem6}$ ${pluralItem2} $= (${numberItem5} \\times$${Weight(
          weightItem
        )}) $+ (${numberItem6} \\times$${Weight(weightItem2)}) $=$${Weight(
          finalSolution
        )}`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(finalSolution / 1000, 0.05);
    question.addInputGroup(group);

    if (
      numberItem3 / numberItem4 === numberItem5 / numberItem6 ||
      numberItem3 === numberItem4 ||
      numberItem5 === numberItem6 ||
      numberItem % numberItem3 === 0 ||
      numberItem3 % numberItem === 0 ||
      numberItem5 % numberItem === 0 ||
      numberItem5 % numberItem === 0 ||
      weightItem % 100 === 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Proportion_Exam_Q3() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100)
          .toFixed(2)
          .replace("0", " 0 ")} `;
      }
      return `\\pounds ${parseFloat(input / 100)
        .toFixed(2)
        .replace("0", " 0 ")} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const sweetsWeight = MCRandom.randomInt(30, 100) * 10;
    const pricePerKilogram = MCRandom.randomInt(30, 60) / 10;
    const sweetsWeightKg = sweetsWeight / 1000;
    const cost = sweetsWeightKg * pricePerKilogram;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} goes to the cinema with some friends`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.HeShe} decides that ${person.heshe} wants to buy some sweets to eat during the movie.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` She buys $${sweetsWeight}$ g of sweets.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The cinema charges $${DisplayMoney(
          pricePerKilogram.toFixed(2) * 100
        )}$ per kilogram.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` How much does it cost ${person.name} for the sweets?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " First, convert the unit of the sweets' weight from grammes to kilogrammes.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Do this by dividing the sweets' weight by a thousand:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${sweetsWeight}  \\div 1000 = ${sweetsWeightKg}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore the sweets weigh $${sweetsWeightKg}$ kg.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Since it costs $${DisplayMoney(
          pricePerKilogram.toFixed(2) * 100
        )}$ per kilogram, you are able to multiply the price per kilogram, by the weight of the sweets in kilogrammes:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${sweetsWeightKg}  \\times ${pricePerKilogram} = ${cost}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore it costs ${person.name} $${DisplayMoney(
          cost.toFixed(2) * 100
        )}$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(cost, 0.01, "$\\pounds$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (sweetsWeight % 100 === 0 || pricePerKilogram % 0.5 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_ProportionProblems_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const name1 = MCMisc.getName();
    const name1Speed = MCRandom.randomInt(40, 70);
    const name1Time = MCRandom.randomInt(50, 100);
    const name1Distance = name1Speed * name1Time;

    const name2 = MCMisc.getName();
    const name2Time = MCRandom.randomInt(50, 100);
    const name2Distance = name1Distance;
    const name2Speed = Math.round(name2Distance / name2Time);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${name1.name} races a formula 1 race car around a race track and completes the circuit in $${name1Time}$ seconds, with an average speed of $${name1Speed}$ metres per second.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${name2.name} races a different formula 1 race car around the same race track, but in a time of $${name2Time}$ seconds.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` What is ${name2.name}'s average speed?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Work out the length of the race track by multiplying ${name1.name}'s speed and time:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${name1Speed}  \\times ${name1Time} = ${name1Distance}m$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Since both of them are racing on the same race track, both of their distances are equal.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Since $Speed = {Distance \\over Time}$:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` ${name2.name}'s average speed = \${${name2Distance} \\over ${name2Time}} \\approx ${name2Speed}m/s$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(name2Speed, 0.1);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (name1.name === name2.name || name1Time === name2Time) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_ProportionProblems_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name = MCMisc.getName();
    const name2 = MCMisc.getName();
    const days = MCRandom.randomInt(4, 6);
    const distanceToWorkInt = MCRandom.randomInt(25, 40);
    const distanceToWorkFloat = Math.random().toFixed(1);
    const distanceToWork =
      distanceToWorkInt + (distanceToWorkFloat / 100) * 100;
    const milesPerGallonInt = MCRandom.randomInt(35, 55);
    const milesPerGallonFloat = Math.random().toFixed(1);
    const milesPerGallon =
      milesPerGallonInt + (milesPerGallonFloat / 100) * 100;
    const costOfPetrolInt = MCRandom.randomInt(1, 1);
    const costOfPetrolFloat = Math.random().toFixed(2);
    const costOfPetrol = (
      costOfPetrolInt +
      (costOfPetrolFloat / 100) * 100
    ).toFixed(2);
    const busPassInt = MCRandom.randomInt(18, 25);
    const busPassFloat = Math.random().toFixed(2);
    const busPass = (busPassInt + (busPassFloat / 100) * 100).toFixed(2);
    const trainTicketInt = MCRandom.randomInt(3, 7);
    const trainTicketFloat = Math.random().toFixed(2);
    const trainTicket = (
      trainTicketInt +
      (trainTicketFloat / 100) * 100
    ).toFixed(2);
    const discounts = [20, 25, 30, 40, 50];
    const discount = discounts[MCRandom.randomInt(0, 4)];
    const milesPerWeek = distanceToWork * days;
    const gallonsNeeded = parseFloat(
      (milesPerWeek / milesPerGallon).toFixed(3)
    );
    const litresNeeded = parseFloat(
      ((milesPerWeek / milesPerGallon).toFixed(3) * 4.5).toFixed(3)
    );
    const costOfFuel = (litresNeeded * costOfPetrol).toFixed(2);
    const costOfTrain = (trainTicket * days).toFixed(2);
    const percentageMultipler = 1 - discount / 100;
    const newTrainTicket = (trainTicket * percentageMultipler).toFixed(2);
    const newCostOfTrain = (newTrainTicket * days).toFixed(2);
    const halfOfFuelCost = (costOfFuel / 2).toFixed(2);
    const trainComparison = (newCostOfTrain * 100) / 100;
    const fuelComparison = (halfOfFuelCost * 100) / 100;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} is planning ${name.hisher} journey to work. ${name.HeShe} works for $${days}$ days each week.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} will either drive, take the bus or take the train.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `If ${name.heshe} uses ${name.hisher} car to drive to work,`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `each day ${name.heshe} will drive a distance of $${distanceToWork}$ miles`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.hisher} car can travel $${milesPerGallon}$ miles per gallon`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `petrol costs $\\pounds${costOfPetrol}$ per litre.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A weekly ticket for the bus costs $\\pounds${busPass}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A return train ticket costs $\\pounds${trainTicket}$ per day.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Given that 1 gallon = 4.5 litres.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `(a) Which method of transport should ${name.name} use if ${name.heshe} wants to spend the least amount of money?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name}'s co-worker, ${name2.name}, is also planning ${name2.hisher} journey.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} and ${name2.name} work in the same place at the same time.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name2.name} will not travel by bus.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name2.HeShe} can get an exclusive discount on train tickets that reduces the cost of a return ticket by $${discount}\\%$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Alternatively ${name2.heshe} may car share with ${name.name} and will pay half of the cost of petrol.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `(b) Should ${name2.name} take the train or car share?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("(a)", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Miles per week = distance per day $ \\times$ days a week = $${distanceToWork}  \\times ${days} = ${milesPerWeek}$ miles`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Gallons of petrol needed = miles per week $ \\div$ miles per gallon = $${milesPerWeek}  \\div ${milesPerGallon} = ${gallonsNeeded}$ gallons`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Litres of petrol needed = gallons needed $ \\times$ 4.5 = $${gallonsNeeded}  \\times 4.5 = ${litresNeeded}$ litres`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Cost of petrol = litres needed $ \\times$ cost per litre = $${litresNeeded}  \\times ${costOfPetrol} = \\pounds${costOfFuel}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Cost of train per week = ticket price $ \\times$ days working = $${trainTicket}  \\times ${days} = \\pounds${costOfTrain}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Cost of bus per week = $\\pounds${busPass}$`,
        false,
        true,
        true
      )
    );
    if (costOfFuel < busPass && costOfFuel < costOfTrain) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`${name.name} should drive to work.`, false, true, true)
      );
    } else if (busPass < costOfFuel && busPass < costOfTrain) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`${name.name} should take the bus.`, false, true, true)
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `${name.name} should take the train.`,
          false,
          true,
          true
        )
      );
    }
    question.addParagraph(
      "solution",
      MCMaths.cleaner("(b)", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Discounted cost of train ticket = cost of train ticket $-$ $${discount}\\%$ = $${trainTicket}  \\times ${percentageMultipler} = \\pounds${newTrainTicket}$ `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Cost of train for a week = discounted cost of train ticket $ \\times$ days working = $${newTrainTicket}  \\times ${days} = \\pounds${newCostOfTrain}$ `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Half of petrol cost = cost of petrol $ \\div$ $2$ = $${costOfFuel}  \\div 2 = \\pounds${halfOfFuelCost}$ `,
        false,
        true,
        true
      )
    );
    if (fuelComparison < trainComparison) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`${name2.name} should car share.`, false, true, true)
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `${name2.name} should take the train.`,
          false,
          true,
          true
        )
      );
    }

    question.requiresSelfMarking();

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      distanceToWorkInt === milesPerGallonInt ||
      distanceToWorkFloat === milesPerGallonFloat ||
      costOfPetrolFloat === 0 ||
      busPassFloat === 0 ||
      trainTicketFloat === 0 ||
      costOfTrain === costOfFuel ||
      costOfTrain === busPass ||
      busPass === costOfFuel ||
      halfOfFuelCost === newCostOfTrain
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_ProportionProblems_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c

    function directK(let1, let2, power, init1, init2) {
      let k = 0;
      let working = "";
      working = `$${let1} = k${let2}^{${power}}$, `;
      if (power > 1) {
        working += `$${init1} = k(${init2})^{${power}}$, `;
      }
      working += `$${init1} = k  \\times ${init2 ** power}$, `;
      working += `$k = \\frac{${init1}}{${init2 ** power}}, $`;
      k = init1 / init2 ** power;
      working += `$k = ${k}$`;
      return {
        k,
        working,
      };
    }
    function inverseK(let1, let2, power, init1, init2) {
      let k = 0;
      let working = "";
      working = `$${let1} = \\frac{k}{${let2}^{${power}}}$, `;
      if (power > 1) {
        working += `$${init1} = \\frac{k}{(${init2})^{${power}}}$, `;
      }
      working += `$${init1} = \\frac{k}{${init2 ** power}}$, `;
      working += `$k = ${init1}  \\times ${init2 ** power}$, `;
      k = init1 * init2 ** power;
      working += `$k = ${k}$`;
      return {
        k,
        working,
      };
    }

    const initSpeed = MCRandom.randomInt(100, 200);
    const initAp = MCRandom.randomInt(5, 8);
    const ansA = inverseK("P", "m", 2, initSpeed, initAp);
    const apB = MCRandom.randomInt(2, 4);
    const s = ansA.k / (apB * apB);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("$P$ leties inversely with the square of $m$.", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `When $P = ${initSpeed}$, $m = ${initAp}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find an equation in $P$ and $m$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Hence find the value of $P$ when $m = ${apB}$ Give your answer correct to 3 significant figures.`,
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${ansA.working}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$P = \\frac{${ansA.k}}{m^2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$P = \\frac{${ansA.k}}{${apB}^2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$P = \\frac{${ansA.k}}{${apB * apB}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$P = ${parseFloat(s.toFixed(5))}$`, false, true, true)
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(s, 0, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (s >= 600 || s !== Math.round(s)) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_ProportionProblems_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const speeds = [20, 30, 40, 50, 60, 70];
    const speed = speeds[MCRandom.randomInt(1, 5)];
    const powerOutput = MCRandom.randomInt(1000, 3000);
    const speedIncreases = [10, 15, 20];
    const fasterBy = speedIncreases[MCRandom.randomInt(0, 2)];
    const k = powerOutput / speed ** 2;
    const newSpeed = speed + fasterBy;
    const speedSq = speed ** 2;
    const newPower = k * newSpeed ** 2;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("A car drives on a straight horizontal road.", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The car engine has power $p$ and travels at speed $s$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$p$ is directly proportional to the square of $s$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The car travels at $${speed}$ m/s when the power output of the engine is $${powerOutput}$ watts.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Find the power output of the engine when the car is travelling $${fasterBy}$ m/s faster.`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$p \\propto s^{2}$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$p = ks^{2}$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${powerOutput} = k  \\times ${speed}^{2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${powerOutput} = ${speedSq}k$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$k = {${powerOutput} \\over ${speedSq}} = ${k}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `New speed = original speed + speed increase = $${speed} + ${fasterBy} = ${newSpeed}$ m/s`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$p = ${k}  \\times ${newSpeed}^{2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$p = ${k}  \\times ${newSpeed ** 2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$p = ${newPower}$ watts`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(newPower, 0.1);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (Math.round(k * 100) !== k * 100 || newPower !== Math.round(newPower)) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_ProportionProblems_Exam_Q5() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c

    function directK(let1, let2, power, init1, init2) {
      let k = 0;
      let working = "";
      working = `$${let1} = k${let2}^{${power}}$, `;
      if (power > 1) {
        working += `$${init1} = k(${init2})^{${power}}$, `;
      }
      working += `$${init1} = k  \\times ${init2 ** power}$, `;
      working += `$k = \\frac{${init1}}{${init2 ** power}}, $`;
      k = init1 / init2 ** power;
      working += `$k = ${k}$`;
      return {
        k,
        working,
      };
    }

    const initDistance = MCRandom.randomInt(30, 80);
    const initFuelUsed =
      MCRandom.randomInt(2, 6) + MCRandom.randomInt(1, 9) / 10;
    const distA = MCRandom.randomInt(120, 180);
    const ansA = directK("L", "d", 1, initFuelUsed, initDistance);
    const lA = ansA.k * distA;
    const lB = MCRandom.randomInt(8, 25) + MCRandom.randomInt(1, 9) / 10;
    const dB = lB / ansA.k;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The amount of fuel used by a car is directly proportional to the distance travelled by the car.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `When the car has travelled $${initDistance}km$, $${initFuelUsed}$ litres of fuel has been used.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ What is the minimum amount of fuel required for a $${distA}km$ journey? Give your answer correct to 3 significant figures.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ How far can the car travel with $${lB}$ litres of fuel?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a)\\ $${ansA.working}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$L = ${ansA.k}  \\times ${distA}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$L = ${lA}$ litres`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ ${lB} = ${ansA.k}  \\times d$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$d = \\frac{${lB}}{${ansA.k}}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$d = ${dB}km$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(lA, 0.1, "$(a)$");
    group.addInput(dB, 0.5, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      initDistance / 5 !== Math.round(initDistance / 5) ||
      initFuelUsed / 0.5 !== Math.round(initFuelUsed / 0.5) ||
      distA / 5 !== Math.round(distA / 5) ||
      ansA.k !== parseFloat(ansA.k.toFixed(5)) ||
      ansA.k === 0.1 ||
      lA <= 10 ||
      Math.abs(lB - lA) <= 5 ||
      dB !== Math.round(dB)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_ProportionProblems_Exam_Q6() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c

    function directK(let1, let2, power, init1, init2) {
      let k = 0;
      let working = "";
      working = `$${let1} = k${let2}^{${power}}$, `;
      if (power > 1) {
        working += `$${init1} = k(${init2})^{${power}}$, `;
      }
      working += `$${init1} = k  \\times ${init2 ** power}$, `;
      working += `$k = \\frac{${init1}}{${init2 ** power}}, $`;
      k = init1 / init2 ** power;
      working += `$k = ${k}$`;
      return {
        k,
        working,
      };
    }

    const initTension = MCRandom.randomInt(100, 200);
    const initExt = MCRandom.randomInt(4, 6) + MCRandom.randomInt(1, 9) / 10;
    const ansA = directK("T", "x", 1, initTension, initExt);
    const extB =
      MCRandom.randomInt(parseInt(initExt + 3, 10), 10) +
      MCRandom.randomInt(1, 9) / 10;
    const tenC = MCRandom.randomInt(300, 500);
    const ansB = ansA.k * extB;
    const ansC = tenC / ansA.k;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "In an elastic string, the tension, $T$ newtons, is directly proportional to its extension, $x$ $cm$.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `When $T = ${initTension}N$, $x = ${initExt}cm$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find an equation in $T$ and $x$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Find the tension in the string when the extension is $${extB}cm$ Give your answer correct to 3 significant figures.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ Find the extension of the string when the tension is $${tenC}N$ Give your answer correct to 3 significant figures.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ $${ansA.working}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$T = ${ansA.k}x$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)\\ T = ${ansA.k}  \\times ${extB}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$T = ${ansB}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore ${ansB.toPrecision(3)} N$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ ${tenC} = ${ansA.k}x$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x = ${parseFloat(ansC.toFixed(5))}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore ${ansC.toPrecision(3)} cm$`,
        false,
        true,
        true
      )
    );
    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(2);

    group.addInput(ansB, 1, "$(b)$");
    group.addInput(ansC, 0.1, "$(c)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      ansA.k * 10 !== Math.round(ansA.k * 10) ||
      Math.abs(ansB - tenC) <= 50
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_ProportionProblems_Exam_Q7() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name = MCMisc.getName();
    const initTime = MCRandom.randomInt(45, 95) / 100;
    let initDistance = MCRandom.randomInt(1, 1);
    initDistance += MCRandom.randomInt(1, 9) / 10;
    const timeB = 1 + MCRandom.randomInt(1, 9) / 10;
    const distance = MCRandom.randomInt(10, 14) + MCRandom.randomInt(1, 9) / 10;
    const k = initDistance / (initTime * initTime);
    const bDist = k * timeB * timeB;
    const t2 = distance / k;
    const t = Math.sqrt(t2);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} is a tiler and places ${name.hisher} phone down whilst working on a roof.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The phone receives a call and begins to vibrate which causes the phone to slide down the roof.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The phone then falls off of the roof, after $t$ seconds the phone has fallen $x$ meters.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The distance from the point at which the phone leaves the roof to the ground is $${distance}$ meters.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$x$ is directly proportional to the square of $t$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `When $t = ${initTime}, x = ${initDistance}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find an equation in $x$ and $t$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Find the value of $x$, correct to 3 significant figures, when $t = ${timeB}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(c)$ How long does it take for the phone to reach the ground? Give your answer correct to 3 significant figures.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a) \\ x = kt^2$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${initDistance} = k(${initTime})^2$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${initDistance} = k  \\times ${initTime * initTime}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$k = \\frac{${initDistance}}{${initTime * initTime}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$k = ${k}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\therefore x = ${k}t^2$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ x = ${k}(${timeB})^2$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x = ${k}  \\times ${timeB * timeB}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x = ${bDist.toPrecision(3)}m$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ ${distance} = ${k}t^2$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$t^2 = \\frac{${distance}}{${k}}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$t^2 = ${t2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$t = ${t}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$t = ${t.toPrecision(3)}s$`, false, true, true)
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(2);
    group.addInput(bDist, 0.1, "$(b) \\ x=$");
    group.addInput(t, 0.1, "$(c) \\ t=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      initTime / 0.05 !== Math.round(initTime / 0.05) ||
      Math.round(k * 100) !== k * 100 ||
      bDist >= 9 ||
      t2 * 1000 !== Math.round(1000 * t2)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Ratio_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name1 = MCMisc.getName();
    const name2 = MCMisc.getName();
    const name3 = MCMisc.getName();
    const ratio1 = Math.round(Math.random() * 4 + 1);
    const ratio2 = Math.round(Math.random() * 4 + 1);
    const ratio3 = Math.round(Math.random() * 4 + 1);
    const RatioTotal = ratio3 + ratio2 + ratio1;
    const amount = Math.round(Math.random() * 300 + 100);
    const name1amount = (amount / RatioTotal) * ratio1;
    const name2amount = (amount / RatioTotal) * ratio2;
    const name3amount = (amount / RatioTotal) * ratio3;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name}, ${name2.name} and ${name3.name} share a prize in the ratio $${ratio1}:${ratio2}:${ratio3}$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name2.name} and ${name3.name} get $\\pounds${
          name2amount + name3amount
        }$ in total.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `How much does ${name1.name} recieve? `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `How much does ${name1.name} recieve? `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Each share $={${
          name2amount + name3amount
        }\\over${ratio2}+${ratio3}}$ = $${amount / RatioTotal}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Hence, ${name1.name} gets $\\pounds${name1amount}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(name1amount, 0.1, "$\\pounds$");
    question.addInputGroup(group);

    if (
      name1 === name2 ||
      name2 === name3 ||
      name1 === name3 ||
      ratio1 === ratio2 ||
      ratio2 === ratio3 ||
      ratio1 === ratio3 ||
      amount / RatioTotal !== Math.round(amount / RatioTotal)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Ratio_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name1 = MCMisc.getName();
    const name2 = MCMisc.getName();
    const name3 = MCMisc.getName();

    const TotalMoney = Math.round(Math.random() * 120 + 40) * 5;

    const Prime = [2, 3, 5, 7, 13, 17, 23, 29];

    const name1ratio12 = Prime[Math.round(Math.random() * 4)];

    const name2ratio12 = Prime[Math.round(Math.random() * 4)];

    const name2ratio23 = Prime[Math.round(Math.random() * 4)];

    const name3ratio23 = Prime[Math.round(Math.random() * 4)];

    const oneshare =
      TotalMoney /
      (name1ratio12 * name2ratio23 +
        name2ratio12 * name2ratio23 +
        name3ratio23 * name2ratio12);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name}, ${name2.name} and ${name3.name} share $\\pounds${TotalMoney}$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} and ${name2.name} share in the ratio $${name1ratio12}:${name2ratio12}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name2.name} and ${name3.name} share in the ratio $${name2ratio23}:${name3ratio23}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`How much does ${name3.name} get?`, false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Find the ratio of ${name1.name} to ${name2.name} to ${name3.name}. `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Multiply to make common ratio equal.", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${name1.name} : ${name2.name}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${name1ratio12}:${name2ratio12}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${name1ratio12 * name2ratio23}:${name2ratio12 * name2ratio23}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${name2.name} : ${name3.name}. `, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${name2ratio23}:${name3ratio23}$. `, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${name2ratio23 * name2ratio12}:${name3ratio23 * name2ratio12}$. `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Therefore,", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${name1.name} : ${name2.name} : ${name3.name}`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${name1ratio12 * name2ratio23}:${name2ratio12 * name2ratio23}:${
          name3ratio23 * name2ratio12
        }$. `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `One share $={${TotalMoney}\\over${
          name1ratio12 * name2ratio23 +
          name2ratio12 * name2ratio23 +
          name3ratio23 * name2ratio12
        }}=${oneshare}$. `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${name3.name} gets $=${oneshare} \\times${
          name3ratio23 * name2ratio12
        } =\\pounds ${oneshare * (name3ratio23 * name2ratio12)}$. `,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(oneshare * (name3ratio23 * name2ratio12), 0.1, "$\\pounds$");
    question.addInputGroup(group);

    if (
      oneshare !== Math.round(oneshare) ||
      name1 === name2 ||
      name1 === name3 ||
      name3 === name2 ||
      name1ratio12 === name3ratio23 ||
      name2ratio23 === name2ratio12 ||
      name1ratio12 === name2ratio12 ||
      name2ratio23 === name3ratio23 ||
      name2ratio23 + name2ratio12 === 18 ||
      name2ratio23 + name2ratio12 === 12 ||
      name2ratio23 + name2ratio12 === 16
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_Ratio_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const numberSold = MCRandom.randomInt(200, 400);
    const ratioAA = MCRandom.randomInt(5, 9);
    const ratioAAA = MCRandom.randomInt(7, 14);
    const ratioAAAA = MCRandom.randomInt(3, 8);
    const profit1 = MCRandom.randomInt(2, 2);
    const profit2 = MCRandom.randomInt(3, 4);
    const multiplier1 = "twice";
    let multiplier2 = "";
    if (profit2 === 3) {
      multiplier2 = "three times";
    } else {
      multiplier2 = "four times";
    }
    const totalProfitPounds = MCRandom.randomInt(600, 800);
    const totalProfitPence = MCRandom.randomInt(0, 99);
    const totalProfit = (totalProfitPounds + totalProfitPence / 100).toFixed(2);

    const totalParts = ratioAA + ratioAAA + ratioAAAA;
    const onePart = numberSold / totalParts;
    const aa = onePart * ratioAA;
    const aaa = onePart * ratioAAA;
    const aaaa = onePart * ratioAAAA;

    const aaaP = aaa * 2;
    const aaaaP = aaaa * profit2;

    const profitTermsOfAA = aa + aaaP + aaaaP;

    const profitPerPack = totalProfit / profitTermsOfAA;
    const finalProfit = profitPerPack.toFixed(2);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A shop sells $${numberSold}$ packs of batteries in the ratio AA : AAA : AAAA = $${ratioAA}:${ratioAAA}:${ratioAAAA}$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The profit for one pack of AAA batteries is ${multiplier1} the profit for one pack of AA batteries.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The profit for one pack of AAAA batteries is ${multiplier2} the profit for one pack of AA batteries.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The shop earns a total profit of $\\pounds${totalProfit}$ on the batteries.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Work out the profit gained from the sale of one packet of AA batteries.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Total parts = $${ratioAA} + ${ratioAAA} + ${ratioAAAA} = ${totalParts}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `One part = $${numberSold}  \\div ${totalParts} = ${onePart}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Packs of AA batteries = $${onePart}  \\times ${ratioAA} = ${aa}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Packs of AAA batteries = $${onePart}  \\times ${ratioAAA} = ${aaa}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Packs of AAAA batteries = $${onePart}  \\times ${ratioAAAA} = ${aaaa}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Let:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("AA batteries have a profit of $x$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("AAA batteries have a profit of $2x$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `AAAA batteries have a profit of $${profit2}x$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ Total profit = $${aa}x + ${aaa}  \\times 2x + ${aaaa}  \\times ${profit2}x = ${profitTermsOfAA}x$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${profitTermsOfAA}x= \\pounds${totalProfit}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$x= {${totalProfit}\\over ${profitTermsOfAA}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ Profit for one pack of AA batteries = $\\pounds${finalProfit}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(profitPerPack, 0.01, "$\\pounds$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      ratioAA === ratioAAA ||
      ratioAA === ratioAAAA ||
      ratioAAA === ratioAAAA ||
      (ratioAA / 2 === Math.round(ratioAA / 2) &&
        ratioAAA / 2 === Math.round(ratioAAA / 2) &&
        ratioAAAA / 2 === Math.round(ratioAAAA / 2)) ||
      onePart !== Math.round(onePart) ||
      Math.round(profitPerPack * 100) !== 100 * profitPerPack
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_Ratio_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name = MCMisc.getName();
    const ratio1 = MCRandom.randomInt(2, 5);
    const ratio2 = MCRandom.randomInt(2, 5);
    const percentageDoneBy1 = MCRandom.randomInt(30, 70);
    const hours1 = MCRandom.randomInt(600, 900);
    const hours2 = MCRandom.randomInt(500, 1000);
    const multipleof1 = ratio1 * MCRandom.randomInt(4, 10);
    const multipleOf2 = (multipleof1 / ratio1) * ratio2;

    const totalFactory1 = Math.floor(hours1 / multipleof1);
    const totalFactory2 = Math.floor(hours2 / multipleOf2);

    let finalAns = totalFactory1;
    if (totalFactory2 < totalFactory1) {
      finalAns = totalFactory2;
    }

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Sample units of a product will be manufactured by two different factories working together.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The number of hours spent in each factory to complete one unit of the product is in the ratio $${ratio1}:${ratio2}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `It takes the first factory $${multipleof1}$ hours to complete one unit.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The owner of the company ordering the product, ${name.name}, secures a contract with each factory for the total time they will work on the product:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The first factory will spend a maximum of $${hours1}$ hours on the product`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The second factory will spend a maximum of $${hours2}$ hours on the product`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "What is the maximum number of finished units that the factories will produce?",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Find the number of hours for factory two to complete one unit:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `One part of the ratio = $${multipleof1}  \\div ${ratio1} = ${
          multipleof1 / ratio1
        }$ hours`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Hours for one unit = multiplier $ \\times$ ratio = $${
          multipleof1 / ratio1
        }  \\times ${ratio2} = ${multipleOf2}$ hours in factory two`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Find the max output of each factory:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "How many times can factory one complete their part = total hours $ \\div$ hours per unit",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$= ${hours1}  \\div ${multipleof1} = ${parseFloat(
          (hours1 / multipleof1).toFixed(2)
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$= ${totalFactory1}$ units`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "How many times can factory two complete their part = total hours $ \\div$ hours per unit",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$= ${hours2}  \\div ${multipleOf2} = ${parseFloat(
          (hours2 / multipleOf2).toFixed(2)
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$= ${totalFactory2}$ units`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `The maximum amount of units = the minimum output of both factories = $${finalAns}$ units.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(finalAns, 0.1);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      ratio1 === ratio2 ||
      ratio1 / ratio2 === Math.round(ratio1 / ratio2) ||
      ratio2 / ratio1 === Math.round(ratio2 / ratio1) ||
      hours1 === hours2 ||
      hours1 / 5 !== Math.round(hours1 / 5) ||
      hours2 / 5 !== Math.round(hours2 / 5)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_RatioAndLinearFunctions_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const water = MCRandom.randomInt(2, 8)

    const c = MCRandom.randomInt(250, 800, 50);

    const a_ans = new MCMaths.Fraction(1/ (water + 1))

    const c_ans = c/water;

    /* END VARIABLES AND CONSTANTS */
    if ( true ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph('question',` A company produces orange squash that is to be mixed in the ratio $1:${water}$ with water to make orange juice.`);

      question.addParagraph('question', `a) State the fraction of the resulting drink that is squash.`);
      question.addParagraph('question', `b) Represent this information on a straight line graph where the amount of water is on the $x$-axis and the amount of squash is on the $y$-axis.`);
      question.addParagraph('question', `Draw your graph in the range $0\\leq x \\leq 1000$`);
      question.addParagraph('question', `c) Use your graph to estimate the amount of squash needed when there is $${c}$ml of water.`);

      // 

      question.addHeading('solution', `a) There is $1$ part squash for every $${water}$ parts water in the drink.`);
      question.addParagraph('solution', `$\\displaystyle \\frac{1}{1+${water}}=\\frac{1}{${water + 1}}$`);
      question.addParagraph('solution', `$\\therefore \\displaystyle \\frac{1}{${water + 1}}$ of the drink is squash.`);

      // b

      question.addHeading('solution', `b) The graph has an equation of $\\displaystyle y=\\frac{1}{${water}}x$`);

      const g = new MCQuestion.Graph(1000, 0, Math.floor(1000/water) + 50, 0, 100, 50);
      g.plot(`y=${1/water} * x`, 0, 1000);
      question.addGraph('solution', g);

      // c
      if (c_ans % 5 === 0)
      {
        question.addHeading('solution',`c) Reading from the graph at $x=${c}$ we get $y = ${c_ans}$`);
        question.addParagraph('solution', `$\\therefore$ You need $${c_ans}$ml of squash to mix with $${c}$ml of water.`);
      }
      else
      {
        question.addHeading('solution',`c) Reading from the graph at $x=${c}$ we get $y \\approx ${Math.round(c_ans)}$`);
        question.addParagraph('solution', `$\\therefore$ You need approximately $${Math.round(c_ans)}$ml of squash to mix with $${c}$ml of water.`);
      }
      // answers

      question.requiresSelfMarking();

      const a1 = new MCQuestion.InputGroup(1);
      a1.addInput(a_ans.toFloat(), 0.005, `$(a)$`);
      a1.addInput(c_ans, 12.5, `$(c)$`);
      question.addInputGroup(a1);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_RatioAndLinearFunctions_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    function no_factors(n1, n2)
    {

      let max = Math.max(n1,n2);
      for (let i = 2; i <= max; i += 1)
      {
        if (n1 % i === 0 && n2 % i === 0)
        {
          return false;
        }
      }
      return true;
    }


    // your definitions here
    const rs = MCRandom.randomInt(2,4);
    const sv = MCRandom.randomInt(4, 9);

    let ints = []
    while(ints.length !== 5)
    {
      let tmp = MCRandom.randomInt(6, 14);
      if (tmp !== 10 && !ints.includes(tmp))
      { 
        ints.push(tmp);
      }
    }


    const b = MCRandom.randomInt(15, 20);

    const m = new MCMaths.Fraction(rs/sv);

    /* END VARIABLES AND CONSTANTS */
    if ( no_factors(rs, sv) ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      
      question.addParagraph('question', `Below shows the number of boxes of ready salted and salt and vinegar crisps ordered by a shop in $5$ previous orders. The shop orders ready salted and salt and vinegar crisps in the same ratio each time.`);

      const t = new MCQuestion.Table('Ready Salted', 'Salt & Vinegar');
      t.addRow(`$${ints[0] * rs}$`, `$${ints[0]*sv}$`);
      t.addRow(`$${ints[1] * rs}$`, `$${ints[1]*sv}$`);
      t.addRow(`$${ints[2] * rs}$`, `$${ints[2]*sv}$`);
      t.addRow(`$${ints[3] * rs}$`, `$${ints[3]*sv}$`);
      t.addRow(`$${ints[4] * rs}$`, `$${ints[4]*sv}$`);

      question.addTable('question', t);

      question.addParagraph('question', `a) State the ratio of ready salted to salt and vinegar crisps ordered by the shop in its simplest form.`);
      question.addParagraph('question', `The shop makes another order of $${b*sv}$ boxes of salt and vinegar crisps.`);
      question.addParagraph('question', `b) How many boxes of ready salted crisps would you expect them to buy?`);
      question.addParagraph('question', `Let the number of boxes of ready salted crisps be $R$ and the number of boxes of salt and vinegar crisps be $S$.`);
      question.addParagraph('question', `c) By finding an equation for $R$ is terms of $S$, draw a graph of $R$ against $S$.`);

      // solution

      // a
      question.addHeading('solution', `a) Take the example where $${ints[2] * rs}$ boxes of ready salted crisps and $${ints[2] * sv}$ boxes of salt and vinegar crisps were ordered.`);
      question.addParagraph('solution', `This gives the ratio $${ints[2] * rs}:${ints[2] * sv}$`);
      question.addParagraph('solution', `To get simplest form keep dividing both sides by common factors until you can't simplify any further.`);
      question.addParagraph('solution', `This gives the ratio $${rs}:${sv}$`);


      // b
      question.addHeading('solution',`b) Divide the number of salt and vinegar boxes by the amount of salt and vinegar in the ratio.`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${b*sv}}{${sv}} = ${b}$`);
      question.addParagraph('solution', `Then multiply by the number of ready salted in the ratio.`);
      question.addParagraph('solution', `$${b}(${rs}) = ${b * rs}$`);
      question.addParagraph('solution', `$\\therefore$ You would expect them to order $${b*rs}$ boxes of ready salted crisps.`);


      // c
      question.addHeading('solution', `c) We can acquire the equation $R=\\displaystyle ${m}S$ from the ratio calculated in part (a).`);
      question.addParagraph('solution', `The graph should look like to the one shown below.`);

      const g = new MCQuestion.Graph(20 * sv, 0, 20 * rs, 0, Math.ceil(0.2 * sv)* 10, Math.ceil(0.4 * rs)* 5);
      g.plot(`y=${m.toFloat()} * x`, 0, 20 * sv);
      question.addGraph('solution', g);


      // answers
      question.requiresSelfMarking();

      const a1 = new MCQuestion.InputGroup(2);
      a1.addInput(rs, 0, `$(a)$`);
      a1.addInput(sv, 0, `$:$`);
      a1.addInput(b*rs, 0, `$(b)$`);
      question.addInputGroup(a1);



      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_RatioAndLinearFunctions_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function no_factors(n1, n2)
    {

      let max = Math.max(n1,n2);
      for (let i = 2; i <= max; i += 1)
      {
        if (n1 % i === 0 && n2 % i === 0)
        {
          return false;
        }
      }
      return true;
    }


    // your definitions here
    const boys = MCRandom.randomInt(2,9);
    const girls = MCRandom.randomInt(2, 9);

    const a_ans = new MCMaths.Fraction((girls)/(girls+boys))

    const bn = girls * MCRandom.randomInt(10, 100)
    const cn = boys * MCRandom.randomInt(10, 100)

    const b_ans = new MCMaths.Fraction(bn).divide(a_ans);
    const c_ans = cn * girls/boys
    /* END VARIABLES AND CONSTANTS */
    if ( no_factors(boys, girls) && boys !== girls && girls/boys < 2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph('question', `On average, the ratio of boys to girls at a yearly event is $${boys}:${girls}$`);
      
      question.addParagraph('question', `a) Express the number of girls at the event as a fraction of the whole attendance.`);
      question.addParagraph('question', `b) Calculate the total attendance of the event in a year where there are $${bn}$ girls.`);
      question.addParagraph('question', `c) Calculate the number of girls in attendance in a year when there are $${cn}$ boys.`);
      question.addParagraph('question', `d) Represent the relationship graphically, with boys on the $x$-axis and girls on the $y$-axis.`);

      // solution

      question.addHeading('solution', `a) There are $${girls}$ girls for every $${boys}$ boys at the event.`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${girls}}{${girls} + ${boys}}= ${a_ans}$`);
      question.addParagraph('solution', `$\\therefore \\displaystyle ${a_ans}$ of the attendees are girls.`);
      // b

      question.addHeading('solution', `b) Using the answer from part (a) and letting total attendance $= A$.`);
      question.addParagraph('solution', `$\\displaystyle ${a_ans}$ of the total attendees are girls`);
      question.addParagraph('solution', `$\\therefore \\displaystyle ${a_ans}A=${bn}$`);
      question.addParagraph('solution', `$\\displaystyle A=${new MCMaths.Fraction(1).divide(a_ans)}(${bn})$`);
      question.addParagraph('solution', `$=${b_ans}$`);
      question.addParagraph('solution', `$\\therefore$ The total attendance of the event is $${b_ans}$`);

      // c

      question.addHeading('solution', `c) For every $${boys}$ boys there are $${girls}$ girls.`);
      question.addParagraph('solution', `$\\therefore$ There are $\\displaystyle \\frac{${girls}}{${boys}}\\times$ as many girls as boys.`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${girls}}{${boys}}(${cn})=${c_ans}$`);
      question.addParagraph('solution', `$\\therefore$ There are $${c_ans}$ girls in attendance.`);

      // d

      question.addHeading('solution', `d) The graph has an equation of $\\displaystyle y=\\frac{${girls}}{${boys}}x$`);

      const g = new MCQuestion.Graph(1000, 0, 1000, 0, 100, 100);
      g.plot(`y=${girls/boys} * x`, 0, 1000);
      question.addGraph('solution', g);

      // answers

      question.requiresSelfMarking();

      const a1 = new MCQuestion.InputGroup(1);
      a1.addInput(a_ans.toFloat(), 0.005, `$(a)$`);
      a1.addInput(b_ans.toFloat(), 0, `$(b)$`);
      a1.addInput(c_ans, 0, `$(c)$`);

      question.addInputGroup(a1);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_RatioAndLinearFunctions_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    function no_factors(n1, n2)
    {

      let max = Math.max(n1,n2);
      for (let i = 2; i <= max; i += 1)
      {
        if (n1 % i === 0 && n2 % i === 0)
        {
          return false;
        }
      }
      return true;
    }


    // your definitions here
    const short = MCRandom.randomInt(2,4);
    const long = MCRandom.randomInt(4, 9);

    const total = (short + long) * MCRandom.randomInt(5, 15);

    const c = MCRandom.randomInt(3, 12) * long

    const a_ans = short * total / (short+long)
    const c_ansS = c*short/long
    const c_ansT = c+c_ansS

    /* END VARIABLES AND CONSTANTS */
    if ( no_factors(short, long) ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph('question', `A string of length $${total}$cm is cut in the ratio $${short}:${long}$`);
      question.addParagraph('question', `a) Calculate the length of the smaller piece.`);
      question.addParagraph('question', `b) State the fraction of the total length that the longer piece represents.`);
      question.addParagraph('question', `Another piece of string is cut in the same ratio and the length of the longer piece is $${c}$cm.`);
      question.addParagraph('question', `c) By representing the relationship between the length of the short peice, $s$ and the length of the long piece $l$ as a linear equation, calculate the length of the shorter piece and the total length of the original piece of string.`);

      // 

      question.addHeading('solution', `a) Calculate the fraction the smaller piece represents of the whole.`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${short}}{${short}+${long}} = \\frac{${short}}{${short+long}}$`);
      question.addParagraph('solution', `Multiply this fraction by the whole.`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${short}}{${short+long}}(${total})=${a_ans}$`);
      question.addParagraph('solution', `$\\therefore$ The shorter piece is $${a_ans}$cm long.`);

      // b

      question.addHeading('solution', `b) The short piece is $${short}$cm for every $${long}$cm of the long piece.`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${long}}{${short}+${long}} = \\frac{${long}}{${short+long}}$`);
      question.addParagraph('solution', `$\\therefore$ The long piece is $\\displaystyle \\frac{${long}}{${short+long}}$ of the total length.`);


      // c

      question.addHeading('solution', `c) From the ratio we can see that the length of the shorter piece is $\\displaystyle \\frac{${short}}{${long}}$ that of the longer piece.`);
      question.addParagraph('solution', `Changing this from writing to variables we get $s=\\displaystyle \\frac{${short}}{${long}}l$`);
      question.addParagraph('solution', `Let $l=${c}$`);
      question.addParagraph('solution', `$s=\\displaystyle \\frac{${short}}{${long}}(${c})$`);
      question.addParagraph('solution', `$=${c_ansS}$`);
      question.addParagraph('solution', `$\\therefore$ The short piece is $${c_ansS}$cm long.`);
      question.addHeading('solution', `Add the lengths of the short and long pieces to get the total length of the original string.`);
      question.addParagraph('solution', `$${c_ansS}+${c}=${c_ansT}$`);
      question.addParagraph('solution', `$\\therefore$ The length of the original string was $${c_ansT}$cm`);

      // answers

      const a1 = new MCQuestion.InputGroup(1);
      a1.addInput(a_ans, 0, `$(a)$`);
      a1.addInput(long/(short+long), 0.005, `$(b)$`);
      question.addInputGroup(a1)

      const a2 = new MCQuestion.InputGroup(2)
      a2.addInput(c_ansS, 0, `$(c)\\ $ Short =`);
      a2.addInput(c_ansT, 0, `$(c)\\ $ Total =`);
      question.addInputGroup(a2)
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Foundation_Rparoc_Rparoc_RatioAndLinearFunctions_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    function no_factors(n1, n2, n3)
    {

      let max = Math.max(n1,n2,n3);
      for (let i = 2; i <= max; i += 1)
      {
        if (n1 % i === 0 && n2 % i === 0 && n3 % i === 0)
        {
          return false;
        }
      }
      return true;
    }


    // your definitions here
    const s1 = MCRandom.randomInt(1, 9);
    const s2 = MCRandom.randomInt(1, 9);
    const s3 = MCRandom.randomInt(1, 9);

    const p1 = new MCMisc.getName();
    const p2 = new MCMisc.getName();
    const p3 = new MCMisc.getName();

    const bTotal = (s1+s2+s3) * MCRandom.randomInt(50, 200);
    const newS2 = MCRandom.randomInt(1,5)
    const newS3 = MCRandom.randomInt(1,5)

    const cFrac = new MCMaths.Fraction(newS3/(newS3 + newS2))
    const dProfit = MCRandom.randomInt(200, 450, 50)

    const a_ans = new MCMaths.Fraction((s2)/(s1+s2+s3))
    const p1Share = new MCMaths.Fraction((s1)/(s1+s2+s3))
    const p3Share = new MCMaths.Fraction((s3)/(s1+s2+s3))

    const b1_ans = p1Share.toFloat() * bTotal
    const b2_ans = a_ans.toFloat() * bTotal
    const b3_ans = p3Share.toFloat() * bTotal

    const m = new MCMaths.Fraction(newS2/newS3);

    const d_ans = dProfit*newS2/newS3

    /* END VARIABLES AND CONSTANTS */
    if ( p1.name !== p2.name && p1.name !== p3.name && p2.name !== p3.name && s1 !== s2 && s1 !== s3 && s2 !== s3
    && no_factors(s1,s2,s3) && p2.name.charAt(p2.name.length-1)!=='s' && p3.name.charAt(p3.name.length-1)!=='s' 
    && no_factors(0, newS2, newS3)
    && newS2 !== newS3 && newS2 < newS3) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      question.addParagraph('question', `${p1.name}, ${p2.name} and ${p3.name} split the profits from their business in the ratio $${s1}:${s2}:${s3}$`);

      question.addParagraph('question', `a) Express ${p2.name}'s share of the profits as a fraction of the total profit.`);
      question.addParagraph('question', `b) How much would each person get in a month where the company makes $£${bTotal}$ in profits.`);
      question.addParagraph('question', `${p1.name} sells their shares in the company to ${p2.name} and ${p3.name} such that ${p3.name} now receives $\\displaystyle ${cFrac}$ of total profits.`);
      question.addParagraph('question', `c) Express the profit split between ${p2.name} and ${p3.name} as a ratio.`);
      question.addParagraph('question', `d) Represent this ratio using a straight line graph and use it to estimate how much ${p2.name} receives when ${p3.name} receives $£${dProfit}$.`);

      // 

      question.addHeading('solution', `a) ${p2.name} gets $£${s2}$ for every $£${s1}$ and $£${s3}$ ${p1.name} and ${p3.name} get respectively.`);
      question.addParagraph('solution', `$\\displaystyle \\frac{${s2}}{${s1} + ${s2} + ${s3}}= ${a_ans}$`);
      question.addParagraph('solution', `$\\therefore \\displaystyle ${a_ans}$ of the profits go to ${p2.name}.`);

      // b

      question.addHeading('solution', `b) Using the method in part (a) we calculate(d) that:`);
      question.addParagraph('solution', `$\\displaystyle ${p1Share}$ of the profits go to ${p1.name}.`);
      question.addParagraph('solution', `$\\displaystyle ${a_ans}$ of the profits go to ${p2.name}.`);
      question.addParagraph('solution', `$\\displaystyle ${p3Share}$ of the profits go to ${p3.name}.`);
      question.addHeading('solution', `Multiplying the total profit by each person's cut gives you:`);

      question.addParagraph('solution', `$\\displaystyle ${p1Share}(${bTotal})=${b1_ans}$`);
      question.addParagraph('solution', `$\\therefore$ ${p1.name} received $£${b1_ans}$`);

      question.addParagraph('solution', `$\\displaystyle ${a_ans}(${bTotal})=${b2_ans}$`);
      question.addParagraph('solution', `$\\therefore$ ${p2.name} received $£${b2_ans}$`);

      question.addParagraph('solution', `$\\displaystyle ${p3Share}(${bTotal})=${b3_ans}$`);
      question.addParagraph('solution', `$\\therefore$ ${p3.name} received $£${b3_ans}$`);

      // c

      question.addHeading('solution', `c) If ${p3.name} receives $\\displaystyle ${cFrac}$ of the total profits then ${p2.name} must receive $\\displaystyle ${new MCMaths.Fraction(1).subtract(cFrac)}$ of the profits.`);
      question.addParagraph('solution', `$\\therefore$ The profits will be split in the ratio $${newS2}:${newS3}$ between ${p2.name} and ${p3.name}`);

      // d
      question.addHeading('solution', `d) If you're plotting ${p2.name}'s profits on the $y$-axis and ${p3.name}'s profits' on the $x$-axis you get the equation $\\displaystyle y=${m}x$`)

      const g = new MCQuestion.Graph(500, 0, 500, 0, 50, 50);
      g.plot(`y=${newS2/newS3} * x`, 0, 500);
      question.addGraph('solution', g);

      if (d_ans % 1 === 0)
      {
        question.addParagraph('solution', `Reading from where $x=${dProfit}$ you get $y=${d_ans}$`);
        question.addParagraph('solution',` $\\therefore$ ${p2.name} would receive $£${d_ans}$`);
      }
      else
      {
        question.addParagraph('solution', `Reading from where $x=${dProfit}$ you get $y\\approx ${d_ans}$`);
        question.addParagraph('solution',` $\\therefore$ ${p2.name} would receive roughly $£${d_ans}$`);
      }

      question.requiresSelfMarking();

      const a1 = new MCQuestion.InputGroup(1);
      a1.addInput(a_ans.toFloat(), 0.005, `$(a)$`);
      question.addInputGroup(a1);

      const a2 = new MCQuestion.InputGroup(3);
      a2.addInput(b1_ans, 0, `$(b)\\ $ ${p1.name} $=$`);
      a2.addInput(b2_ans, 0, `$(b)\\ $ ${p2.name} $=$`);
      a2.addInput(b3_ans, 0, `$(b)\\ $ ${p3.name} $=$`);
      question.addInputGroup(a2);

      const a3 = new MCQuestion.InputGroup(2);
      a3.addInput(newS2, 0, `$(c)$`);
      a3.addInput(newS3, 0, `$:$`);
      a3.addInput(d_ans, 12.5, `$(d)$`);
      question.addInputGroup(a3);


      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}



export function GCSE_Foundation_Rparoc_Rparoc_RatioProblems_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const grams = [250, 275, 300, 325, 350, 375, 400, 425, 450];
    const gram = grams[MCRandom.randomInt(0, 8)];
    const percentageIncrease = MCRandom.randomInt(10, 30);
    const percentageDecrease = MCRandom.randomInt(10, 30);

    const weightA = gram * (1 + percentageIncrease / 100);

    const ppgA = ((100 / weightA) * 100).toFixed(0);

    const priceInPenceB = 100 * (1 - percentageDecrease / 100);

    const ppgB = ((priceInPenceB / gram) * 100).toFixed(0);

    let betterValue;

    if (ppgA > ppgB) {
      betterValue = "B";
    } else {
      betterValue = "A";
    }

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Two shops sell a $${gram}$ gram bag of Pick & Mix sweets.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Each has a special offer on.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Offer from shop A:", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${percentageIncrease}\\%$ more sweets`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Price remains the same", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Offer from shop B:", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Usual amount of sweets", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${percentageDecrease}\\%$ off the price`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Which shop has the better value?", false, true, true)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Shop A weight = original weight + $${percentageIncrease}\\%$ $=  ${gram}  \\times ${
          1 + percentageIncrease / 100
        } = ${weightA} g$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Shop B weight = $${gram}g$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Assume that the price is $\\pounds 1$:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Shop A, pence per gram = $100$ $ \\div$ $${weightA} = ${ppgA}$ (nearest pence)`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Shop B, cost in pence = initial cost - $${percentageDecrease}\\%$ = $100$ $ \\times$ $${
          1 - percentageDecrease / 100
        }$ = $${priceInPenceB}$p`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Shop B, pence per gram = $${priceInPenceB}$ $ \\div$ $${gram} = ${ppgB}$ (nearest pence)`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ Shop ${betterValue} is better value.`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      percentageIncrease === percentageDecrease ||
      weightA !== Math.round(weightA) ||
      priceInPenceB !== Math.round(priceInPenceB) ||
      ppgA === ppgB ||
      ppgA + 1 === ppgB ||
      ppgB + 1 === ppgA ||
      ppgA - 1 === ppgB ||
      ppgB - 1 === ppgA
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_RatioProblems_Exam_Q10() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const flour = MCRandom.randomInt(600, 700) / 100;
    const butter = MCRandom.randomInt(350, 450) / 100;
    const sugar = MCRandom.randomInt(3125, 3500) / 1000;
    const walnuts = MCRandom.randomInt(75, 100) / 100;
    const chips = (MCRandom.randomInt(2, 5) / 10) * 8;
    const makes = MCRandom.randomInt(6, 80);
    const recipeFlour = flour * makes;
    const recipeButter = butter * makes;
    const recipeSugar = sugar * makes;
    const recipeWalnut = walnuts * makes;
    const recipeChips = chips * makes;
    const toMake = MCRandom.randomInt(10, 60);
    const toMake2 = MCRandom.randomInt(10, 60);
    const name = MCMisc.getName();
    const ingredientNum = MCRandom.randomInt(1, 5);
    let ingredient;
    let ingredient2;
    let amountForOneCookie;
    let amountInRecipe;
    let amountForOneCookie2;
    let amountInRecipe2;
    switch (ingredientNum) {
      case 1:
        ingredient = "flour";
        ingredient2 = "chocolate chips";
        amountForOneCookie = flour;
        amountInRecipe = recipeFlour;
        amountForOneCookie2 = chips;
        amountInRecipe2 = recipeChips;
        break;
      case 2:
        ingredient = "butter";
        ingredient2 = "walnuts";
        amountForOneCookie = butter;
        amountInRecipe = recipeButter;
        amountForOneCookie2 = walnuts;
        amountInRecipe2 = recipeWalnut;
        break;
      case 3:
        ingredient = "caster sugar";
        ingredient2 = "flour";
        amountForOneCookie = sugar;
        amountInRecipe = recipeSugar;
        amountForOneCookie2 = flour;
        amountInRecipe2 = recipeFlour;
        break;
      case 4:
        ingredient = "crushed walnuts";
        ingredient2 = "butter";
        amountForOneCookie = walnuts;
        amountInRecipe = recipeWalnut;
        amountForOneCookie2 = butter;
        amountInRecipe2 = recipeButter;
        break;
      case 5:
        ingredient = "chocolate chips";
        ingredient2 = "sugar";
        amountForOneCookie = chips;
        amountInRecipe = recipeChips;
        amountForOneCookie2 = sugar;
        amountInRecipe2 = recipeSugar;
        break;
      default:
    }
    const neededForA = amountForOneCookie * toMake;
    const neededForB = amountForOneCookie2 * toMake2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has a recipe for Walnut Chocolate Chip Cookies.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The ingredients to make ${makes} cookies are shown below:`,
        false,
        true,
        true
      )
    );
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${recipeFlour}g$ Flour`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${recipeButter}g$ Butter`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${recipeSugar}g$ Caster Sugar`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${recipeWalnut}g$ Crushed Walnuts`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${recipeChips}g$ Chocolate Chips`, false, true, true)
    );
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ ${name.name} is going to make ${toMake} cookies. What quantity of ${ingredient} will ${name.heshe} need?  Give your answer to the nearest gram.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ On another day, ${name.name} wants to make ${toMake2} cookies. What quantity of ${ingredient2} will ${name.heshe} need?  Give your answer to the nearest gram.`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Quantity for one cookie:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${amountInRecipe}g$ makes ${makes} cookies`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Amount of ${ingredient} for one cookie $=$ $($amount for ${makes}$)$ $ \\div$ $${makes}$ `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Amount of ${ingredient} for one cookie $=$ $${amountInRecipe}  \\div ${makes}$ `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Amount of ${ingredient} for one cookie $=$ $${amountForOneCookie}g$ `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Quantity for ${toMake} cookies:`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Quantity $=$ $($quantity for one$)$ $ \\times$ $${toMake}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Quantity $=$ $${amountForOneCookie}  \\times ${toMake}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Quantity $=$ $${parseFloat(neededForA.toFixed(5))}g$`,
        false,
        true,
        true
      )
    );
    if (neededForA !== neededForA.toFixed(0)) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `Quantity $=$ $${neededForA.toFixed(0)}g$`,
          false,
          true,
          true
        )
      );
    }
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Quantity for one cookie:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${amountInRecipe2}g$ makes ${makes} cookies`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Amount of ${ingredient2} for one cookie $=$ $($amount for ${makes}$)$ $ \\div$ $${makes}$ `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Amount of ${ingredient2} for one cookie $=$ $${amountInRecipe2}  \\div ${makes}$ `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Amount of ${ingredient2} for one cookie $=$ $${amountForOneCookie2}g$ `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Quantity for ${toMake2} cookies:`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Quantity $=$ $($quantity for one$)$ $ \\times$ $${toMake2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Quantity $=$ $${amountForOneCookie2}  \\times ${toMake2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Quantity $=$ $${parseFloat(neededForB.toFixed(5))}g$`,
        false,
        true,
        true
      )
    );
    if (neededForB !== neededForB.toFixed(0)) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `Quantity $=$ $${neededForB.toFixed(0)}g$`,
          false,
          true,
          true
        )
      );
    }

    const group = new MCQuestion.InputGroup(2);
    group.addInput(neededForA, 0.5, "$(a)$");
    group.addInput(neededForB, 0.5, "$(b)$");
    question.addInputGroup(group);

    if (
      Math.abs(toMake - toMake2) < 20 ||
      recipeFlour % 1 !== 0 ||
      recipeButter % 1 !== 0 ||
      recipeSugar % 1 !== 0 ||
      recipeWalnut % 1 !== 0 ||
      recipeChips % 1 !== 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_RatioProblems_Exam_Q11() {
  let count = 0;

  function roundUp(int) {
    return Math.ceil(int / 10) * 10;
  }

  for (let i = 1; i <= 1; i += 1) {
    const recipes = [
      "Cookie Dough Pizza",
      "Rainbow Cookies",
      "Banana & Walnut Bread",
      "Salted Caramel Cheesecake",
    ];
    const selectedRecipe = recipes[MCRandom.randomInt(0, 3)];
    let ingredient1;
    let ingredient2;
    let ingredient3;
    let ingredient4;
    let ingredient5;
    let toBuy;
    let toBuy2;
    let toBuyPossible;
    switch (selectedRecipe) {
      case "Cookie Dough Pizza":
        ingredient1 = {
          name: "butter",
          quantity: MCRandom.randomInt(115, 125) / 10,
          unit: "g",
        };
        ingredient2 = {
          name: "chocolate chips",
          quantity: MCRandom.randomInt(125, 135) / 10,
          unit: "g",
        };
        ingredient3 = {
          name: "brown sugar",
          quantity: MCRandom.randomInt(95, 105) / 10,
          unit: "g",
        };
        ingredient4 = {
          name: "self-raising flour",
          quantity: MCRandom.randomInt(215, 225) / 10,
          unit: "g",
        };
        ingredient5 = {
          name: "mini marshmallows",
          quantity: MCRandom.randomInt(15, 25) / 10,
          unit: "g",
        };
        toBuyPossible = [ingredient1, ingredient4];
        toBuy = toBuyPossible[MCRandom.randomInt(0, 1)];
        toBuy2 = toBuyPossible[MCRandom.randomInt(0, 1)];
        break;
      case "Rainbow Cookies":
        ingredient1 = {
          name: "butter",
          quantity: MCRandom.randomInt(75, 85) / 10,
          unit: "g",
        };
        ingredient2 = {
          name: "caster sugar",
          quantity: MCRandom.randomInt(15, 25) / 10,
          unit: "g",
        };
        ingredient3 = {
          name: "plain flour",
          quantity: MCRandom.randomInt(135, 145) / 10,
          unit: "g",
        };
        ingredient4 = {
          name: "icing sugar",
          quantity: MCRandom.randomInt(65, 75) / 10,
          unit: "g",
        };
        ingredient5 = {
          name: "sprinkles",
          quantity: MCRandom.randomInt(50, 200) / 100,
          unit: "g",
        };
        toBuyPossible = [ingredient1, ingredient3];
        toBuy = toBuyPossible[MCRandom.randomInt(0, 1)];
        toBuy2 = toBuyPossible[MCRandom.randomInt(0, 1)];
        break;
      case "Banana & Walnut Bread":
        ingredient1 = {
          name: "coconut oil",
          quantity: MCRandom.randomInt(600, 700) / 100,
          unit: "g",
        };
        ingredient2 = {
          name: "self-raising flour",
          quantity: MCRandom.randomInt(245, 255) / 10,
          unit: "g",
        };
        ingredient3 = {
          name: "walnut pieces",
          quantity: MCRandom.randomInt(85, 95) / 10,
          unit: "g",
        };
        ingredient4 = {
          name: "muscovado sugar",
          quantity: MCRandom.randomInt(95, 105) / 10,
          unit: "g",
        };
        ingredient5 = {
          name: "mashed banana",
          quantity: MCRandom.randomInt(450, 550) / 10,
          unit: "g",
        };
        toBuyPossible = [ingredient1, ingredient5, ingredient2];
        toBuy = toBuyPossible[MCRandom.randomInt(0, 2)];
        toBuy2 = toBuyPossible[MCRandom.randomInt(0, 2)];
        break;
      case "Salted Caramel Cheesecake":
        ingredient1 = {
          name: "butter",
          quantity: MCRandom.randomInt(35, 45) / 10,
          unit: "g",
        };
        ingredient2 = {
          name: "chocolate digestives",
          quantity: MCRandom.randomInt(160, 180) / 10,
          unit: "g",
        };
        ingredient3 = {
          name: "cream cheese",
          quantity: MCRandom.randomInt(600, 700) / 10,
          unit: "g",
        };
        ingredient4 = {
          name: "dulce de leche",
          quantity: MCRandom.randomInt(200, 300) / 10,
          unit: "g",
        };
        ingredient5 = {
          name: "caster sugar",
          quantity: MCRandom.randomInt(100, 150) / 10,
          unit: "g",
        };
        toBuyPossible = [ingredient2, ingredient4];
        toBuy = toBuyPossible[MCRandom.randomInt(0, 1)];
        toBuy2 = toBuyPossible[MCRandom.randomInt(0, 1)];
        break;
      default:
    }
    const serves = MCRandom.randomInt(8, 32) * 2;
    const wantsToServe = MCRandom.randomInt(serves + 30, serves + 60);
    const name = MCMisc.getName();
    const name2 = MCMisc.getName();
    const costInt = MCRandom.randomInt(2, 4);
    const costDec = MCRandom.randomInt(0, 99) / 100;
    const costs = parseFloat((costInt + costDec).toFixed(2));
    const weight = MCRandom.randomInt(2, 6) * 50;
    const weight2 = MCRandom.randomInt(4, 12) * 25;

    const catersFor = MCRandom.randomInt(100, 200);
    const forOneB = roundUp(toBuy2.quantity * serves) / serves;
    const neededB = forOneB * catersFor;
    const unitsB = neededB / weight2;
    const intUnitB = Math.ceil(unitsB);
    const costInt2 = MCRandom.randomInt(2, 4);
    const costDec2 = MCRandom.randomInt(0, 99) / 100;
    const costs2 = parseFloat((costInt2 + costDec2).toFixed(2));
    const cost = parseFloat((intUnitB * costs2).toFixed(2));
    const budget = MCRandom.randomInt(
      Math.round(cost) - 20,
      Math.round(cost) + 20
    );

    function capitalise(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner("Here is an extract of a recipe:", false, true, true)
    );
    question.addHeading(
      "question",
      MCMaths.cleaner(`${selectedRecipe}: `, false, true, true)
    );
    question.addParagraph(
      "question",
      `${capitalise(ingredient1.name)}, $${roundUp(
        ingredient1.quantity * serves
      )}${ingredient1.unit}$`
    );
    question.addParagraph(
      "question",
      `${capitalise(ingredient2.name)}, $${roundUp(
        ingredient2.quantity * serves
      )}${ingredient2.unit}$`
    );
    question.addParagraph(
      "question",
      `${capitalise(ingredient3.name)}, $${roundUp(
        ingredient3.quantity * serves
      )}${ingredient3.unit}$`
    );
    question.addParagraph(
      "question",
      `${capitalise(ingredient4.name)}, $${roundUp(
        ingredient4.quantity * serves
      )}${ingredient4.unit}$`
    );
    question.addParagraph(
      "question",
      `${capitalise(ingredient5.name)}, $${roundUp(
        ingredient5.quantity * serves
      )}${ingredient5.unit}$`
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `These ingredients can serve $${serves}$ people.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ ${name.name}, a caterer, goes to the shop for ${toBuy.name}.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${name.HeShe} wants to buy enough ${toBuy.name} to make ${selectedRecipe} to serve $${wantsToServe}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` $${weight}g$ of ${toBuy.name} costs $\\pounds${costs.toFixed(2)} $`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` How much will ${name.name} need to spend on ${toBuy.name}?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Another caterer, ${name2.name}, wants to serve for $${catersFor}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${name2.HeShe} will need to buy enough ${toBuy2.name}.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      ` $${weight2}g$ of ${toBuy2.name} costs $\\pounds${costs2.toFixed(2)} $`
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${name2.name} has a budget of $\\pounds${budget.toFixed(2)}$, can ${
          name2.heshe
        } afford it?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${toBuy.name} for one serving $=$ $${roundUp(
          toBuy.quantity * serves
        )}  \\div ${serves}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${toBuy.name} for one serving $=$ $${
          roundUp(toBuy.quantity * serves) / serves
        }g$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${toBuy.name} needed $=$ $${
          roundUp(toBuy.quantity * serves) / serves
        }  \\times ${wantsToServe}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${toBuy.name} needed $=$ $${
          (roundUp(toBuy.quantity * serves) / serves) * wantsToServe
        }g$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `units of ${toBuy.name} required $=$ $${
          (roundUp(toBuy.quantity * serves) / serves) * wantsToServe
        }  \\div ${weight}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `units of ${toBuy.name} required $=$ $${parseFloat(
          (
            ((roundUp(toBuy.quantity * serves) / serves) * wantsToServe) /
            weight
          ).toFixed(4)
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `integer units of ${toBuy.name} required $=$ $${Math.ceil(
          ((roundUp(toBuy.quantity * serves) / serves) * wantsToServe) / weight
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `cost of ${Math.ceil(
          ((roundUp(toBuy.quantity * serves) / serves) * wantsToServe) / weight
        )} units $=$ $${Math.ceil(
          ((roundUp(toBuy.quantity * serves) / serves) * wantsToServe) / weight
        )}  \\times ${costs}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `cost of ${Math.ceil(
          ((roundUp(toBuy.quantity * serves) / serves) * wantsToServe) / weight
        )} units $=$ $\\pounds ${(
          Math.ceil(
            ((roundUp(toBuy.quantity * serves) / serves) * wantsToServe) /
              weight
          ) * costs
        ).toFixed(2)} $`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${toBuy2.name} for one serving $=$ $${roundUp(
          toBuy2.quantity * serves
        )}  \\div ${serves}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${toBuy2.name} for one serving $=$ $${forOneB}g$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${toBuy2.name} needed $=$ $${forOneB}  \\times ${wantsToServe}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${toBuy2.name} needed $=$ $${neededB}g$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `units of ${toBuy2.name} required $=$ $${neededB}  \\div ${weight}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `units of ${toBuy2.name} required $=$ $${unitsB}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `integer units of ${toBuy2.name} required $=$ $${intUnitB}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `cost of ${intUnitB} units $=$ $${intUnitB}  \\times ${costs2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `cost of ${intUnitB} units $=$ $\\pounds${(intUnitB * costs2).toFixed(
          2
        )} $`,
        false,
        true,
        true
      )
    );
    if (budget < cost) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`${name2.name} cannot afford it.`, false, true, true)
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`${name2.name} can afford it.`, false, true, true)
      );
    }

    const group = new MCQuestion.InputGroup(1);
    group.addInput(
      Math.ceil(
        ((roundUp(toBuy.quantity * serves) / serves) * wantsToServe) / weight
      ) * costs,
      0.01,
      "$(a) \\ \\pounds $"
    );
    question.requiresSelfMarking();
    question.addInputGroup(group);

    if (
      wantsToServe % serves === 0 ||
      toBuy === toBuy2 ||
      weight === weight2 ||
      Math.abs(catersFor - wantsToServe) < 20 ||
      Math.abs(costs - costs2) < 1 ||
      budget < 0 ||
      budget === cost ||
      Math.abs(budget - cost) < 3
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_RatioProblems_Exam_Q12() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const recipeLitres = MCRandom.randomInt(10, 30) / 10;
    const recipeRed = (MCRandom.randomInt(0, 10) / 10) * recipeLitres;
    const recipeGreen = (MCRandom.randomInt(0, 10) / 10) * recipeLitres;
    const recipeBlue = (MCRandom.randomInt(0, 10) / 10) * recipeLitres;
    const wantedLitres = MCRandom.randomInt(5, 90) / 10;
    const multiplier = (wantedLitres * 10) / (recipeLitres * 10);
    const multiplierValue = multiplier;

    let colour;
    let wantedColour;
    let recipeAmount;

    switch (MCRandom.randomInt(0, 2)) {
      case 0:
        colour = "red";
        wantedColour = multiplierValue * recipeRed;
        recipeAmount = recipeRed;
        break;

      case 1:
        colour = "green";
        wantedColour = multiplierValue * recipeGreen;
        recipeAmount = recipeGreen;
        break;

      case 2:
        colour = "blue";
        wantedColour = multiplierValue * recipeBlue;
        recipeAmount = recipeBlue;
        break;
      default:
    }

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} wants to mix a certain colour of paint.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The recipe for $${recipeLitres}$ litres of this paint is as follows:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(` $${recipeRed}$ litres of red paint.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` $${recipeGreen}$ litres of green paint.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` $${recipeBlue}$ litres of blue paint.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} wants $${wantedLitres}$ litres of this paint.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` How much ${colour} paint does ${person.name} need in order to make $${wantedLitres}$ litres of this paint?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " First we need to work out how much more paint we need compared to the recipe.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " This is done by dividing the wanted amount of litres by the recipes litres:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${wantedLitres}  \\div ${recipeLitres} = ${multiplier}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Then multiply this fraction by by the amount of ${colour} in the recipe:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${multiplier}  \\times ${recipeAmount} = ${wantedColour}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Therefore ${person.name} needs $${wantedColour}$ litres of ${colour} paint to make $${wantedLitres}$ litres of the desired paint.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(wantedColour, 0.05);
    question.addInputGroup(group);
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      recipeRed + recipeGreen + recipeBlue !== recipeLitres ||
      recipeRed === recipeGreen ||
      recipeRed === recipeBlue ||
      recipeGreen === recipeBlue ||
      recipeRed === 0 ||
      recipeGreen === 0 ||
      recipeBlue === 0 ||
      wantedLitres === recipeLitres ||
      multiplier === null
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(wantedColour, 0);
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_RatioProblems_Exam_Q2() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
      }
      return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const total = MCRandom.randomInt(50, 500);
    const itemList = ["pens", "pencils", "nails", "screws"];
    const itemType = MCRandom.randomInt(0, 1);
    const shopList = [
      "Stationary World",
      "Pen World",
      "Hardware Depot",
      "Building Supplies",
    ];
    const shop1 = shopList[2 * itemType];
    const shop2 = shopList[2 * itemType + 1];
    const item = itemList[2 * itemType + MCRandom.randomInt(0, 1)];

    // shop 1:
    const pack1 = MCRandom.randomInt(10, 50, 5);
    const price1 = MCRandom.randomInt(100, 500, 10);
    let numberNeeded = 0;
    for (let j = 0; j < total; j += pack1) {
      numberNeeded += 1;
    }

    // shop 2:
    const price2 = MCRandom.randomInt(5, 20);

    const total1 = numberNeeded * price1;
    const total2 = total * price2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", `${name.name} needs $${total}$ ${item}.`);
    question.addParagraph("question", "There are two shops near him:");
    question.addParagraph(
      "question",
      `$\\cdot$ ${shop1}: Packets of $${pack1}$ ${item} for $${DisplayMoney(
        price1
      )}$.`
    );
    question.addParagraph(
      "question",
      `$\\cdot$ ${shop2}: Each ${item} costs $${DisplayMoney(price2)}$.`
    );
    question.addParagraph(
      "question",
      `For the lowest price, where should ${name.heshe} buy from?`
    );

    question.addParagraph(
      "solution",
      `$${numberNeeded}$ packets of ${item} are needed from ${shop1}, costing $${numberNeeded} \\times${DisplayMoney(
        price1
      )}=${DisplayMoney(total1)}$`
    );
    question.addParagraph(
      "solution",
      `$${total}$ ${item} are needed from ${shop2}, costing $${total} \\times${DisplayMoney(
        price2
      )}=${DisplayMoney(total2)}$`
    );

    if (total1 <= total2) {
      question.addParagraph(
        "solution",
        `So, ${name.name} shoud shop at ${shop1}`
      );
    }
    if (total1 >= total2) {
      question.addParagraph(
        "solution",
        `So, ${name.name} shoud shop at ${shop2}`
      );
    }
    question.requiresSelfMarking();

    if (
      Math.abs(total1 / total2 - 1) >= 0.005 ||
      total % pack1 === 0 ||
      total1 === total2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */

// \\
// ||
// toFraction()
// toFraction().denominator
// ()
//


export function GCSE_Foundation_Rparoc_Rparoc_RatioProblems_Exam_Q3() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
      }
      return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const companies = ["BeFresh", "FreshCo"];
    const company1 = companies[MCRandom.randomInt(0, 1)];
    const company2 = companies[MCRandom.randomInt(0, 1)];
    const quant1 = MCRandom.randomInt(3, 8);
    const quant2 = MCRandom.randomInt(3, 8);
    const cost1 = MCRandom.randomInt(1, 3) + MCRandom.randomInt(1, 9) / 10;
    const cost2 = MCRandom.randomInt(1, 3) + MCRandom.randomInt(1, 9) / 10;

    const cost1_PerItem = cost1 / quant1;
    const cost2_PerItem = cost2 / quant2;

    let ans;

    if (cost1_PerItem < cost2_PerItem) {
      ans = `${company1}`;
    } else {
      ans = `${company2}`;
    }

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Two supermarkets, ${company1} and ${company2}, both sell Super Soup.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Each sells multipacks of different quantities as such:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      `${company1}: $${quant1}$ tins for $${DisplayMoney(cost1 * 100)}$`
    );
    question.addParagraph(
      "question",
      `${company2}: $${quant2}$ tins for $${DisplayMoney(cost2 * 100)}$`
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "At which shop are tins of Super Soup the better value for money?",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      ` Since the cost per tin at ${company1} is $${cost1.toFixed(
        2
      )}  \\div ${quant1} = \\pounds ${cost1_PerItem.toFixed(2)}$`
    );
    question.addParagraph(
      "solution",
      ` and the cost per tin at ${company2} is $${cost2.toFixed(
        2
      )}  \\div ${quant2} = \\pounds ${cost2_PerItem.toFixed(2)}$.`
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` Super soup is cheaper at ${ans}.`, false, true, true)
    );

    question.requiresSelfMarking();

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      company1 === company2 ||
      Math.abs(quant1 - quant2) <= 2 ||
      Math.abs(cost1 - cost2) <= 0.3
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_RatioProblems_Exam_Q4() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
      }
      return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c

    function bestBuyTwoItemsWConversion(
      shop1,
      shop2,
      vol1,
      quant1,
      vol2,
      quant2,
      units1,
      units2,
      price1,
      price2,
      unit
    ) {
      let bestValuePlace = "";
      let working = "";
      let ppu1 = 0;
      let ppu2 = 0;
      let err = false;
      working = `${shop1}`;
      working += `Totals litres = $${vol1}l  \\times ${quant1} = ${units1}l$. `;
      working += `Price per ${unit} = $${DisplayMoney(
        price1.toFixed(2) * 100
      )}  \\div ${units1}$. `;
      ppu1 = price1 / units1;
      working += `Price per ${unit} = $${DisplayMoney(ppu1.toFixed(2) * 100)}$`;
      working += `${shop2}. `;
      working += `Totals litres = $${vol2}ml  \\times ${quant2} = ${units2}l$. `;
      working += `Price per ${unit} = $${DisplayMoney(
        price2.toFixed(2) * 100
      )}  \\div ${units2}$. `;
      ppu2 = price2 / units2;
      working += `Price per ${unit} = $${DisplayMoney(
        ppu2.toFixed(2) * 100
      )}$. `;
      working += "Best Value";
      if (ppu1.toFixed(2) === ppu2.toFixed(2)) {
        err = true;
      }
      if (ppu2 < ppu1) {
        bestValuePlace = shop2;
        working += `$${DisplayMoney(ppu2.toFixed(2) * 100)}\\lt${DisplayMoney(
          ppu1.toFixed(2) * 100
        )}$. `;
        working += `${shop2} are better value. `;
      } else {
        bestValuePlace = shop1;
        working += `$${DisplayMoney(ppu1.toFixed(2) * 100)}\\lt${DisplayMoney(
          ppu2.toFixed(2) * 100
        )}$. `;
        working += `${shop1}s are better value.`;
      }
      return {
        bestPlace: bestValuePlace,
        ppu1,
        ppu2,
        working,
        error: err,
      };
    }

    // test
    // test
    const name = MCMisc.getName();
    const companies = ["BeFresh", "FreshCo"];
    const company1 = companies[MCRandom.randomInt(0, 1)];
    const company2 = companies[MCRandom.randomInt(0, 1)];
    const vol1 = MCRandom.randomInt(1, 2) + MCRandom.randomInt(25, 75) / 100;
    const vols = [330, 450];
    const vol2 = vols[MCRandom.randomInt(0, 1)];
    const quant1 = MCRandom.randomInt(2, 4);
    const quant2 = MCRandom.randomInt(12, 24);
    const cost1 = MCRandom.randomInt(3, 5) + MCRandom.randomInt(1, 9) / 10;
    const cost2 =
      MCRandom.randomInt(parseInt(cost1 + 1, 10), 9) +
      MCRandom.randomInt(1, 9) / 10;
    const ans = bestBuyTwoItemsWConversion(
      `$${vol1}$ litre bottle`,
      `$${vol2} ml$ cans`,
      vol1,
      quant1,
      vol2,
      quant2,
      quant1 * vol1,
      (quant2 / 1000) * vol2,
      cost1,
      cost2,
      "litre"
    );

    // test
    // test

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`Crazy Cola is on offer at ${company1}.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("The offers available are:", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${vol1}$ litre bottle: $${quant1}$ bottles for $${DisplayMoney(
          cost1.toFixed(2) * 100
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${vol2}ml$ cans: $${quant2}$ cans for $${DisplayMoney(
          cost2.toFixed(2) * 100
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "With which offer is Crazy Cola the better value for money?",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${ans.working}`, false, true, true)
    );

    question.requiresSelfMarking();
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      company1 === company2 ||
      vol1 / 0.25 !== Math.round(vol1 / 0.25) ||
      quant2 / 3 !== Math.round(quant2 / 3) ||
      Math.abs(ans.ppu1 - ans.ppu2) >= 0.05 ||
      ans.error === true
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_RatioProblems_Exam_Q5() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
      }
      return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const quant1 = MCRandom.randomInt(2, 6);
    const quant2 = MCRandom.randomInt(quant1 + 4, 16);
    const cost1 = MCRandom.randomInt(2, 4) + MCRandom.randomInt(1, 9) / 10;
    const cost2 =
      MCRandom.randomInt(parseInt(cost1 + 3, 10), 9) +
      MCRandom.randomInt(1, 9) / 10;

    const cost1_PerItem = cost1 / quant1;
    const cost2_PerItem = cost2 / quant2;

    let ans;

    if (cost1_PerItem < cost2_PerItem) {
      ans = `$${quant1}$`;
    } else {
      ans = `$${quant2}$`;
    }

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      "A supermarket sells muffins in boxes of different quantities."
    );
    question.addParagraph("question", "The boxes available are:");
    question.addParagraph(
      "question",
      `Pack of $${quant1}$: $${DisplayMoney(cost1 * 100)}$`
    );
    question.addParagraph(
      "question",
      `Pack of $${quant2}$: $${DisplayMoney(cost2 * 100)}$`
    );
    question.addParagraph(
      "question",
      "Which box of muffins is the better value for money?"
    );

    question.addParagraph(
      "solution",
      ` Since the cost per muffin in the pack of $${quant1}$ is $${cost1.toFixed(
        2
      )}  \\div ${quant1} = \\pounds ${cost1_PerItem.toFixed(2)}$`
    );
    question.addParagraph(
      "solution",
      ` and the cost per muffin in the pack of $${quant2}$ is $${cost2.toFixed(
        2
      )}  \\div ${quant2} = \\pounds ${cost2_PerItem.toFixed(2)}$. `
    );
    question.addParagraph(
      "solution",
      ` The pack of ${ans} muffins is the best value for money.`
    );

    question.requiresSelfMarking();
    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      quant1 / 2 !== Math.round(quant1 / 2) ||
      quant2 / 2 !== Math.round(quant2 / 2) ||
      (quant1 === 6 && quant2 === 12) ||
      Math.round(quant1) === Math.round(quant2)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_RatioProblems_Exam_Q6() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
      }
      return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
    }
    return `${input}p`;
  }
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c

    function bestBuyOffers(shop1, shop2, price1, price2, percentage, cashback) {
      let bestValueShop = "";
      let working = "";
      const newPrice1 = 0;
      const newPrice2 = 0;
      working = `${shop1}: `;
      working += `$${percentage}\\%$ of $\\pounds${price1} = ${price1}  \\times ${
        percentage / 100
      } = ${DisplayMoney(price1 * (percentage / 100) * 100)}$. `;
      working += `Cost = Original Price $-$ Money Off $= ${price1} - ${
        price1 * (percentage / 100)
      } = ${DisplayMoney((price1 - price1 * (percentage / 100)) * 100)}$. `;
      working += `${shop2}: `;
      working += `Cost = Original Price $-$ Money Off = $${price2} - ${cashback} = ${DisplayMoney(
        (price2 - cashback) * 100
      )}$. `;
      working += "Best Deal: ";
      if (price1 - price1 * (percentage / 100) < price2 - cashback) {
        bestValueShop = shop1;
        working += `$${DisplayMoney(
          (price1 - price1 * (percentage / 100)) * 100
        )}\\lt${DisplayMoney((price2 - cashback) * 100)}$. `;
        working += `So, ${shop1} has the better deal`;
      } else {
        bestValueShop = shop2;
        working += `$${DisplayMoney(
          (price2 - cashback) * 100
        )}\\lt${DisplayMoney((price1 - price1 * (percentage / 100)) * 100)}$. `;
        working += `So, ${shop2} has the better deal`;
      }
      return {
        bestShop: bestValueShop,
        working,
      };
    }

    const name = MCMisc.getName();
    const companies = ["Ron Lewis", "PC Planet", "Murry's", "eBuy"];
    const company1 = companies[MCRandom.randomInt(0, 3)];
    const company2 = companies[MCRandom.randomInt(0, 3)];
    const offerPercentage = MCRandom.randomInt(5, 25);
    const cashback = MCRandom.randomInt(50, 200);
    const ususal1 = MCRandom.randomInt(800, 1200);
    const ususal2 = MCRandom.randomInt(ususal1 - 80, ususal1 + 100);
    const ans = bestBuyOffers(
      company1,
      company2,
      ususal1,
      ususal2,
      offerPercentage,
      cashback
    );

    // test
    // test

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.name} is looking to buy a new computer.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The computer that ${name.heshe} wants is sold in two different shops which have different offers on:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${company1}: $${offerPercentage}\\%$ off the orginal price of $\\pounds${ususal1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${company2}: Save $\\pounds${cashback}$ when the computer is bought for $\\pounds${ususal2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Which shop should ${name.name} buy the computer from to get the best deal?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${ans.working}`, false, true, true)
    );

    question.requiresSelfMarking();

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      company1 === company2 ||
      offerPercentage / 10 !== Math.round(offerPercentage / 10) ||
      cashback / 10 !== Math.round(cashback / 10) ||
      ususal1 === ususal2 ||
      ususal1 / 20 !== Math.round(ususal1 / 20) ||
      ususal2 / 20 !== Math.round(ususal2 / 20)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_RatioProblems_Exam_Q7() {
  function DisplayMoney(input) {
    if (input >= 100) {
      if (input % 100 === 0) {
        return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
      }
      return `\\pounds ${parseFloat(input / 100).toFixed(2)} `;
    }
    return `${input}p`;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c

    function bestBuyThreeItems(
      item1,
      item2,
      item3,
      shop1,
      shop2,
      shop3,
      units1,
      units2,
      units3,
      price1,
      price2,
      price3,
      unit
    ) {
      let bestValuePlace = "";
      let bestValueItem = "";
      let working = "";
      let ppu1 = 0;
      let ppu2 = 0;
      let ppu3 = 0;
      let error = false;
      working = `${shop1}: `;
      working += `Price per ${unit} = $${DisplayMoney(
        price1.toFixed(2) * 100
      )}  \\div ${units1}$. `;
      ppu1 = price1 / units1;
      working += `Price per ${unit} = $${DisplayMoney(
        ppu1.toFixed(2) * 100
      )}$. `;
      working += `${shop2}: `;
      working += `Price per ${unit} = $${DisplayMoney(
        price2.toFixed(2) * 100
      )}  \\div ${units2}$. `;
      ppu2 = price2 / units2;
      working += `Price per ${unit} = $${DisplayMoney(
        ppu2.toFixed(2) * 100
      )}$. `;
      working += `${shop3}: `;
      working += `Price per ${unit} = $${DisplayMoney(
        price3.toFixed(2) * 100
      )}  \\div ${units3}$. `;
      ppu3 = price3 / units3;
      working += `Price per ${unit} = $${DisplayMoney(
        ppu3.toFixed(2) * 100
      )}$. `;
      working += "Best Value: ";
      if (ppu1 === ppu2 || ppu1 === ppu3 || ppu2 === ppu3) {
        error = true;
      }
      if (ppu2 < ppu1 && ppu2 < ppu3) {
        bestValuePlace = shop2;
        bestValueItem = item2;
        working += `$${DisplayMoney(ppu2.toFixed(2) * 100)}\\lt${DisplayMoney(
          ppu1.toFixed(2) * 100
        )}$, `;
        working += `$${DisplayMoney(ppu2.toFixed(2) * 100)}\\lt${DisplayMoney(
          ppu3.toFixed(2) * 100
        )}$. `;
        working += `So, ${shop2} is better value.`;
      } else if (ppu1 < ppu2 && ppu1 < ppu3) {
        bestValuePlace = shop1;
        bestValueItem = item1;
        working += `$${DisplayMoney(ppu1.toFixed(2) * 100)}\\lt${DisplayMoney(
          ppu2.toFixed(2) * 100
        )}$, `;
        working += `$${DisplayMoney(ppu1.toFixed(2) * 100)}\\lt${DisplayMoney(
          ppu3.toFixed(2) * 100
        )}$. `;
        working += `So, ${shop1} is better value.`;
      } else {
        bestValuePlace = shop3;
        bestValueItem = item3;
        working += `$${DisplayMoney(ppu3.toFixed(2) * 100)}\\lt${DisplayMoney(
          ppu1.toFixed(2) * 100
        )}$, `;
        working += `$${DisplayMoney(ppu3.toFixed(2) * 100)}\\lt${DisplayMoney(
          ppu2.toFixed(2) * 100
        )}$. `;
        working += `So, ${shop3} is better value.`;
      }
      return {
        bestPlace: bestValuePlace,
        bestItem: bestValueItem,
        working,
        error,
      };
    }

    const name = MCMisc.getName();
    const companies = [
      "Cool Cabs",
      "Terry's Taxis",
      "Corey's Cars",
      "DriveOnTime",
      "QuickCab",
    ];
    const company1 = companies[MCRandom.randomInt(0, 4)];
    const company2 = companies[MCRandom.randomInt(0, 4)];
    const company3 = companies[MCRandom.randomInt(0, 4)];
    const miles1 = MCRandom.randomInt(5, 25);
    const miles2 = MCRandom.randomInt(5, 25);
    const miles3 = MCRandom.randomInt(5, 25);
    const cost1 = MCRandom.randomInt(5, 20) + MCRandom.randomInt(1, 9) / 10;
    const cost2 = MCRandom.randomInt(5, 20) + MCRandom.randomInt(1, 9) / 10;
    const cost3 = MCRandom.randomInt(5, 20) + MCRandom.randomInt(1, 9) / 10;
    const ans = bestBuyThreeItems(
      "taxi",
      "taxi",
      "taxi",
      company1,
      company2,
      company3,
      miles1,
      miles2,
      miles3,
      cost1,
      cost2,
      cost3,
      "mile"
    );

    // test
    // test

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} is going out with ${name.hisher} friends and needs to book a taxi.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} has rang three taxi companies and received quotes, as follows:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${company1}: $${DisplayMoney(
          cost1.toFixed(2) * 100
        )}$ for a $${miles1}$ mile journey`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${company2}: $${DisplayMoney(
          cost2.toFixed(2) * 100
        )}$ for a $${miles2}$ mile journey`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${company3}: $${DisplayMoney(
          cost3.toFixed(2) * 100
        )}$ for a $${miles3}$ mile journey`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Which company gives the best value?", false, true, true)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${ans.working} `, false, true, true)
    );

    question.requiresSelfMarking();

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      company1 === company2 ||
      company1 === company3 ||
      company2 === company3 ||
      Math.abs(miles1 - miles2) <= 3 ||
      Math.abs(miles1 - miles3) <= 3 ||
      Math.abs(miles2 - miles3) <= 3 ||
      Math.abs(cost1 - cost2) <= 1.5 ||
      Math.abs(cost1 - cost3) <= 1.5 ||
      Math.abs(cost2 - cost3) <= 1.5 ||
      ans.error === true
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_RatioProblems_Exam_Q8() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const fruits = [
      "apple",
      "banana",
      "kiwi",
      "mango",
      "orange",
      "passionfruit",
      "peach",
      "pear",
      "pineapple",
      "plum",
      "star fruit",
      "watermelon",
    ];

    const fruit1 = fruits[Math.floor(Math.random() * fruits.length)];
    const fruit2 = fruits[Math.floor(Math.random() * fruits.length)];
    const name = MCMisc.getName();
    const ratio1 = MCRandom.randomInt(2, 5);
    const ratio2 = MCRandom.randomInt(1, 5);
    const ratio3 = MCRandom.randomInt(1, 3);
    const ratio4 = MCRandom.randomInt(1, 3);
    const numberOfLarge = MCRandom.randomInt(4, 9);
    const onePartLargeFruit = numberOfLarge / ratio3;
    const totalFruit1 = onePartLargeFruit * (ratio3 + ratio4);
    const totalFruit2 = (totalFruit1 / ratio1) * ratio2;
    const total = totalFruit1 + totalFruit2;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} is making a fruit salad. Two of ${name.hisher} ingredients are ${fruit1} and ${fruit2} where:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${fruit1} : ${fruit2} = $${ratio1} : ${ratio2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `large pieces of ${fruit1} : small pieces of ${fruit1} = $${ratio3} : ${ratio4}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${numberOfLarge}$ pieces of ${fruit1} are large.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `What is the total number of pieces of ${fruit1} and ${fruit2}?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Total pieces of ${fruit1} = $(${numberOfLarge}  \\div ${ratio3})  \\times (${ratio3} + ${ratio4}) = ${totalFruit1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Total ${fruit2} = $(${totalFruit1}  \\div ${ratio1})  \\times ${ratio2} = ${totalFruit2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Total pieces of both fruits = total ${fruit1} + total ${fruit2} $= ${totalFruit1} + ${totalFruit2} = ${total}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(total, 0);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      fruit1 === fruit2 ||
      ratio1 === ratio2 ||
      ratio3 === ratio4 ||
      totalFruit1 / ratio1 !== Math.round(totalFruit1 / ratio1)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Foundation_Rparoc_Rparoc_RatioProblems_Exam_Q9() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const name2 = MCMisc.getName();
    const x = MCRandom.randomInt(4, 40);
    const milk = Math.round(25 * x);
    const eggs = Math.round(x / 6);
    const flour = Math.round(10 * x);
    const as = [3, 5, 7];
    const a = as[MCRandom.randomInt(0, 2)];
    const amountToMake = (a * x) / 2;
    const milkNeeded = (a * milk) / 2;
    const eggsNeeded = (a * eggs) / 2;
    const flourNeeded = (a * flour) / 2;
    const amountOfFlour = MCRandom.randomInt(500, 1200);
    const obviousAmount = parseInt(amountOfFlour / flour, 10) * x;
    const flourUsedForObviousAmount = obviousAmount * 10;
    const flourLeft = amountOfFlour - flourUsedForObviousAmount;
    const morePancakes = flourLeft / 10;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has a recipe for ${x} pancakes.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Here is the list of ingredients:", false, true, true)
    );
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${milk}ml$ Milk`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${eggs}$ Eggs`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${flour}g$ Flour`, false, true, true)
    );
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} wants to make $${amountToMake}$ pancakes.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Write a list of ingredients for $${amountToMake}$ pancakes.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name2.name} has $${amountOfFlour}$g of flour and plenty of the other ingredients.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ What is the maximum number of pancakes that ${name2.name} can make?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Ingredients for $${x / 2}$ pancakes:`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Divide initial quantities by $2$", false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${milk / 2}ml$ Milk`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${eggs / 2}$ Eggs`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${flour / 2}g$ Flour`, false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Ingredients for $${amountToMake}$ pancakes:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Multiply above quantities by $${a}$`, false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${milkNeeded}ml$ Milk`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${eggsNeeded}$ Eggs`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${flourNeeded}g$ Flour`, false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${flourUsedForObviousAmount}g$ makes $${obviousAmount}$ pancakes.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Flour left $= ${flourLeft}g$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Flour for one pancake $= ${flour / x}g$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Amount of extra pancakes that can be made $= ${flourLeft}  \\div 10$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Amount of extra pancakes that can be made $= ${morePancakes}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Amount of extra pancakes that can be made $= ${parseInt(
          morePancakes,
          10
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Amount of pancakes that can be made $= ${obviousAmount} + ${parseInt(
          morePancakes,
          10
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Amount of pancakes that can be made $= ${
          obviousAmount + parseInt(morePancakes, 10)
        }$`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(obviousAmount + parseInt(morePancakes, 10), 0, "$(b)$");
    question.addInputGroup(group);

    if (
      (x % 2 !== 0 && x % 5 !== 0) ||
      amountToMake % 1 !== 0 ||
      name.name === name2.name ||
      eggsNeeded % 1 !== 0 ||
      flourLeft < 50 ||
      parseInt(morePancakes, 10) === morePancakes ||
      Math.abs(obviousAmount + parseInt(morePancakes, 10) - amountToMake) <
        20 ||
      amountOfFlour % 5 !== 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Algebra_Graphs_AreaAndGradient_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const question = new MCQuestion.ExamQuestion();

    const m = MCRandom.randomInt(4, 10);

    const t1 = MCRandom.randomInt(1, 4) * m;
    const t2 = t1 + MCRandom.randomInt(1, 4) * m;
    const t3 = t2 + MCRandom.randomInt(1, 4) * m;
    // Making v non-integer to prevent answer from being guessed.
    const v = MCRandom.randomInt(130, 310) / 10;

    const v_step = Math.floor(v / 4);

    const dist = ((t1 + t3 - t2) / 2 + t2 - t1) * v;

    question.addParagraph(
      "question",
      "The following graph shows the speed of a car on the $y$-axis in metres per second, and time in seconds on the $x$-axis."
    );

    const g = new MCQuestion.Graph(t3, 0, v + 2, 0, m, v_step);
    g.plot(`${v}*x/${t1}`, 0, t1);
    g.plot(`${v}`, t1, t2);
    g.plot(`${v}-${v}*(x-${t2})/${t3 - t2}`, t2, t3);
    g.addParametric(`${t1}+0*t`, "t", 0, v);
    g.addParametric(`${t2}+0*t`, "t", 0, v);
    question.addGraph("question", g);

    question.addParagraph(
      "question",
      `Given the car travels $${dist}m$, find the maximum speed it achieves.`
    );

    question.addParagraph("solution", `Maximum speed = $v$.`);
    question.addParagraph("solution", `Distance = Area under graph.`);
    question.addParagraph(
      "solution",
      `$\\displaystyle ${dist}m = \\left(\\frac{${t1}}{2}\\times v\\right)+\\big((${t2}-${t1})\\times v\\big)+\\left(\\frac{${t3}-${t2}}{2}\\times v\\right)$`
    );
    question.addParagraph("solution", `$${dist} = ${dist / v}\\times v$`);
    question.addParagraph("solution", `$v=${v}m/s$`);

    const group = new MCQuestion.InputGroup(1);
    group.addInput(v, 0.001, `$m/s$`);
    question.addInputGroup(group);

    if (
      t3 % 2 !== 0 ||
      v % v_step === 0 ||
      dist % 10 !== 0 ||
      Math.round(v) === v
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_AreaAndGradient_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const question = new MCQuestion.ExamQuestion();

    const m = MCRandom.randomInt(4, 10);

    const t1 = MCRandom.randomInt(1, 4) * m;
    const t2 = t1 + MCRandom.randomInt(1, 4) * m;
    const t3 = t2 + MCRandom.randomInt(1, 4) * m;
    // Making v non-integer to prevent answer from being guessed.
    const v = MCRandom.randomInt(130, 310) / 10;

    const v_step = Math.floor(v / 4);

    const dist = ((t1 + t3) / 2 + t2) * v;

    question.addParagraph(
      "question",
      "The following graph shows the speed of a car on the $y$-axis in metres per second, and time in seconds on the $x$-axis."
    );
    const ansa = v / t1;
    const ansb = v * (t2 - t1);
    const ansc = ((t3 - t2) * v) / 2;

    const g = new MCQuestion.Graph(t3, 0, v + 2, 0, m, v_step);
    g.plot(`${v}*x/${t1}`, 0, t1);
    g.plot(`${v}`, t1, t2);
    g.plot(`${v}-${v}*(x-${t2})/${t3 - t2}`, t2, t3);
    g.addParametric(`${t1}+0*t`, "t", 0, v);
    g.addParametric(`${t2}+0*t`, "t", 0, v);
    question.addGraph("question", g);

    question.addParagraph(
      "question",
      `Given the maximum speed of the car is $${v}m/\\hspace{-0.2em}s \\hspace{0.1em}$:`
    );

    question.addParagraph(
      "question",
      `$(a)$ Find the acceleration of the car during the first $${t1}$ seconds.`
    );
    question.addParagraph(
      "question",
      `$(b)$ Find the distance covered by the car while its speed is constant.`
    );
    question.addParagraph(
      "question",
      `$(c)$ Find the distance covered by the car as it decelerates.`
    );

    question.addParagraph("solution", `$(a)$ Acceleration $=$ Change in speed`);
    question.addParagraph("solution", `$=$ distance $\\div$ time`);
    question.addParagraph("solution", `$= ${v} \\div ${t1}$`);
    question.addParagraph("solution", `$=${ansa} m/s^2$`);
    question.addParagraph(
      "solution",
      `$(b)$ Distance $=$ Speed $\\times$ Time$`
    );
    question.addParagraph("solution", `$= ${v}\\times (${t2}-${t1})$`);
    question.addParagraph("solution", `$=${ansb}m$`);
    question.addParagraph("solution", `$(c)$ Distance $=$ Area under Graph`);
    question.addParagraph(
      "solution",
      `$=\\frac{1}{2}\\times (${t3}-${t2}) \\times ${v}$`
    );
    question.addParagraph("solution", `$=${ansc}m$`);

    const group = new MCQuestion.InputGroup(3);
    group.addInput(ansa, 0.001, `$m/s^2$`);
    group.addInput(ansb, 0.001, `$m$`);
    group.addInput(ansc, 0.001, `$m$`);
    question.addInputGroup(group);

    if (
      t3 % 2 !== 0 ||
      v % v_step === 0 ||
      dist % 10 !== 0 ||
      Math.round(v) === v ||
      Math.round(ansa * 10) !== ansa * 10
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_AreaAndGradient_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const question = new MCQuestion.ExamQuestion();

    const m = MCRandom.randomInt(10, 30, 5);

    const t1 = MCRandom.randomInt(1, 2) * m;
    const t2 = t1 + MCRandom.randomInt(1, 2) * m;
    const t3 = t2 + MCRandom.randomInt(1, 2) * m;

    const t4 = t3 + MCRandom.randomInt(1, 2) * m;
    const t5 = t4 + MCRandom.randomInt(1, 2) * m;
    const t6 = t5 + MCRandom.randomInt(1, 2) * m;

    // Making v non-integer to prevent answer from being guessed.
    const v = MCRandom.randomInt(130, 310) / 10;
    const v2 = MCRandom.randomInt(130, 310) / 10;

    const vmax = Math.max(v, v2);

    const v_step = Math.floor(v / 4);

    const dist1 = ((t1 + t3 - t2) / 2 + t2 - t1) * v;
    const dist2 = ((t4 + t6 - t3 - t5) / 2 + t5 - t4) * v2;
    const dist = dist1 + dist2;

    question.addParagraph(
      "question",
      "The following graph shows the speed of a train going between 3 stations on the $y$-axis in metres per second, and time in seconds on the $x$-axis."
    );

    const g = new MCQuestion.Graph(t6, 0, vmax + 2, 0, m, v_step);
    g.plot(`${v}*x/${t1}`, 0, t1);
    g.plot(`${v}`, t1, t2);
    g.plot(`${v}-${v}*(x-${t2})/${t3 - t2}`, t2, t3);
    g.addParametric(`${t1}+0*t`, "t", 0, v);
    g.addParametric(`${t2}+0*t`, "t", 0, v);

    g.plot(`${v2}*(x-${t3})/${t4 - t3}`, t3, t4);
    g.plot(`${v2}`, t4, t5);
    g.plot(`${v2}-${v2}*(x-${t5})/${t6 - t5}`, t5, t6);
    g.addParametric(`${t4}+0*t`, "t", 0, v2);
    g.addParametric(`${t5}+0*t`, "t", 0, v2);

    question.addGraph("question", g);

    question.addParagraph(
      "question",
      `Given the maximum speed of the train is $${vmax}m/\\hspace{-0.2em}s \\hspace{0.1em}$, and the train covers a total distance of $${dist}m$, find the other speed of the train at the other time where its speed is constant.`
    );

    question.addHeading(
      "solution",
      `Distance covered between second and third stations:`
    );
    question.addParagraph(
      "solution",
      `$\\displaystyle  = \\left(\\frac{${t4}-${t3}}{2}\\times ${v2}\\right)+\\big((${t5}-${t4})\\times ${v2}\\big)+\\left(\\frac{${t6}-${t5}}{2}\\times ${v2}\\right)$`
    );
    question.addParagraph("solution", `$=${dist2}m$`);
    question.addHeading(
      "solution",
      `Distance covered between the first and second station:`
    );
    question.addParagraph("solution", `$=${dist}-${dist2}$`);
    question.addParagraph("solution", `$=${dist1}m$`);
    question.addHeading(
      "solution",
      `Setting constant speed between first and second station equal to $v$:`
    );
    question.addParagraph(
      "solution",
      `$\\displaystyle ${dist1}m = \\left(\\frac{${t1}}{2}\\times v\\right)+\\big((${t2}-${t1})\\times v\\big)+\\left(\\frac{${t3}-${t2}}{2}\\times v\\right)$`
    );
    question.addParagraph("solution", `$${dist1} = ${dist1 / v}\\times v$`);
    question.addParagraph("solution", `$v=${v}m/s$`);

    const group = new MCQuestion.InputGroup(1);
    group.addInput(v, 0.001, `$m/\\hspace{-0.1em}s$`);
    question.addInputGroup(group);

    if (
      Math.abs(v - v2) < 4 ||
      v2 < v ||
      Math.round(v) === v ||
      Math.round(v2) === v2 ||
      Math.round(dist / 10) !== dist / 10 ||
      Math.round(dist2) !== dist2 ||
      Math.round(dist1) !== dist1 ||
      Math.round(dist1 / v) !== dist1 / v
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_AreaAndGradient_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const question = new MCQuestion.ExamQuestion();

    const m = MCRandom.randomInt(10, 30, 5);

    const t1 = MCRandom.randomInt(1, 2) * m;
    const t2 = t1 + MCRandom.randomInt(1, 2) * m;
    const t3 = t2 + MCRandom.randomInt(1, 2) * m;
    const t4 = t3 + MCRandom.randomInt(1, 2) * m;

    // Making v non-integer to prevent answer from being guessed.
    const v = MCRandom.randomInt(130, 210) / 10;
    let v2 = v + MCRandom.randomInt(20, 100) / 10;
    v2 = Math.round(1000 * v2) / 1000;

    const v_step = Math.floor(v2 / 4);

    question.addParagraph(
      "question",
      "The following graph shows the speed of a car on the $y$-axis in metres per second, and time in seconds on the $x$-axis."
    );

    const g = new MCQuestion.Graph(t4, 0, v2 + 2, 0, m, 5);
    g.plot(`${v}*x/${t1}`, 0, t1);
    g.plot(`${v}+${v2 - v}*(x-${t1})/${t2 - t1}`, t1, t2);
    g.plot(`${v2}`, t2, t3);
    g.plot(`${v2}-${v2}*(x-${t3})/${t4 - t3}`, t3, t4);

    g.addParametric(`${t1}+0*t`, "t", 0, v);
    g.addParametric(`${t2}+0*t`, "t", 0, v2);
    g.addParametric(`${t3}+0*t`, "t", 0, v2);

    question.addGraph("question", g);

    const ansa = v / t1;

    const area1 = (t1 * v) / 2;
    const area2 = ((t2 - t1) * (v + v2)) / 2;
    const area3 = (t3 - t2) * v2;
    const area4 = ((t4 - t3) * v2) / 2;
    const ansb = area1 + area2 + area3 + area4;

    question.addParagraph(
      "question",
      `$(a)$ Given that the car has a speed of $${v}m/s$ after $${t1}$ seconds, find its acceleration up to this point.`
    );
    question.addParagraph(
      "question",
      `$(b)$ Given that the maximun speed of the car is $${v2}m/s$, find the total distace it travels in the $${t4}$ seconds.`
    );

    question.addParagraph(
      "solution",
      `$(a)$ Acceleration $=$ change in speed over time,`
    );
    question.addParagraph("solution", `$=${v}\\div ${t1}$`);
    question.addParagraph("solution", `$=${ansa}m/s^2$`);

    question.addHeading(
      "solution",
      `$(b)$ Distace between $0$ and $${t1}$ seconds:`
    );
    question.addParagraph(
      "solution",
      `Area of a triangle $\\displaystyle = \\frac{1}{2} \\times ${t1} \\times ${v}$`
    );
    question.addParagraph("solution", `$=${area1}m$`);
    question.addHeading(
      "solution",
      `Distace between $${t1}$ and $${t2}$ seconds:`
    );
    question.addParagraph(
      "solution",
      `Area of a trapezium $\\displaystyle = \\frac{1}{2} \\times (${v} + ${v2}) \\times (${t2}-${t1})$`
    );
    question.addParagraph("solution", `$=${area2}m$`);
    question.addHeading(
      "solution",
      `Distace between $${t2}$ and $${t3}$ seconds:`
    );
    question.addParagraph(
      "solution",
      `Area of a rectangle $\\displaystyle = ${v2} \\times (${t3}-${t2})$`
    );
    question.addParagraph("solution", `$=${area3}m$`);
    question.addHeading(
      "solution",
      `Distace between $${t3}$ and $${t4}$ seconds:`
    );
    question.addParagraph(
      "solution",
      `Area of a triangle $\\displaystyle = \\frac{1}{2} \\times (${t4}-${t3}) \\times ${v2}$`
    );
    question.addParagraph("solution", `$=${area4}m$`);
    question.addHeading(
      "solution",
      `Total area = $${area1}+${area2}+${area3}+${area4}$`
    );
    question.addParagraph("solution", `$=${ansb}m$`);

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ansa, 0.001, `$(a)$`);
    group.addInput(ansb, 0.001, `$(b)$`);
    question.addInputGroup(group);

    if (Math.round(ansa * 1000) !== ansa * 1000 || Math.round(ansb) !== ansb) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_Circles_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    
    const r = MCRandom.randomInt(2, 16);

    const x = MCRandom.randomInt(-r + 1, r - 1);
    const y = Math.sqrt(r*r - x*x) * MCRandom.randomInt(-1, 1, 2) + MCRandom.randomInt(-1, 1);

    /* END VARIABLES AND CONSTANTS */
    if ( Math.sqrt(r*r - x*x) % 1 === 0 ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(`question`, `A circle, $C$ has the equation $x^2+y^2=${r*r}$`);    
      question.addParagraph('question',` a) State the coordinates of the centre of $C$.`);  
      question.addParagraph('question',` b) State the diameter of $C$.`);  
      question.addParagraph('question', `c) Determine whether the point $(${x}, ${y})$ lies on the circumference of $C$.`);

      // solution

      // a

      question.addHeading('solution', `a) A circle with equation $x^2+y^2=r^2$ is always centred on the origin.`);
      question.addParagraph('solution', `$\\therefore$ The centre of $C$ lies at $(0,0)$.`);

      // b

      question.addHeading('solution', `b) The equation of $C$ is of the form $x^2+y^2=r^2$, so we can calculate its radius, $r$.`);
      question.addParagraph('solution', `$r^2=${r*r}$`);
      question.addParagraph('solution', `$\\therefore r=${r}$`);
      question.addParagraph('solution', `The diameter of a circle is simply double its radius.`);
      question.addParagraph('solution', `$2(${r})=${2*r}$`);
      question.addParagraph('solution', `$\\therefore$ The diameter of $C$ is $${2*r}$`);

      // c

      question.addHeading('solution', `c) The equation of $C$ is $x^2+y^2=${r*r}$`);
      question.addParagraph('solution', `Let $x=${x}$ and $y=${y}$`);
      

      if (x*x + y*y === r*r)
      {
        question.addParagraph('solution', `$(${x})^2+(${y})^2=${r*r}$`);
        question.addParagraph('solution', `$${x*x}+${y*y}=${r*r}$`);
        question.addParagraph('solution', `$${r*r}=${r*r}$`);
        question.addParagraph('solution', `$\\therefore$ The point $(${x}, ${y})$ does lie on the circumference of $C$.`);
      }
      else
      {
        question.addParagraph('solution', `$(${x})^2+(${y})^2\\neq ${r*r}$`);
        question.addParagraph('solution', `$${x*x}+${y*y}\\neq ${r*r}$`);
        question.addParagraph('solution', `$${x*x + y*y}\\neq ${r*r}$`);
        question.addParagraph('solution', `$\\therefore$ The point $(${x}, ${y})$ doesn't lie on the circumference of $C$.`);
      }

      // answers

      const g1 = new MCQuestion.InputGroup(2);

      g1.addInput(0, 0, `$(a) \\ x =$`);
      g1.addInput(0, 0, `$(a) \\ y =$`);

      g1.addInput(2*r, 0, `$(b)$`);

      question.addInputGroup(g1);

      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_Circles_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    
    const r = MCRandom.randomInt(2, 16);

    const x = MCRandom.randomInt(-r + 1, r - 1);
    const y = Math.sqrt(r*r - x*x)* MCRandom.randomInt(-1, 1, 2);

    const c = Math.PI * 2 * r;
    /* END VARIABLES AND CONSTANTS */
    if ( y % 1 === 0 ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph('question', `A circle is centred at $(0, 0)$ and has a radius of $${r}$`);
      question.addParagraph('question', `a) Calculate the circumference of the circle.`);
      question.addParagraph('question', `b) State the equation of the circle.`);
      question.addParagraph('question', `The point $P(${x}, y)$ lies on the circumference of the circle.`);
      question.addParagraph('question', `c) Calculate the possible values of $y$.`);

      // solution
      question.addHeading('solution', `a) The circumference of a circle is given by the formula $\\pi d=2\\pi r$`);
      question.addParagraph('solution', `Let $r=${r}$`);
      question.addParagraph('solution', `$2\\pi (${r}) \\approx ${parseFloat(c.toFixed(5))}$`);
      question.addParagraph('solution', `$\\approx ${parseFloat(c.toFixed(2))}$`);

      // b

      question.addHeading('solution', `b) A circle centred on the origin with radius $r$ has an equation of the form $x^2+y^2=r^2$`);
      question.addParagraph('solution', `Let $r=${r}$`);
      question.addParagraph('solution', `$x^2+y^2=${r}^2$`);
      question.addParagraph('solution', `$x^2+y^2=${r*r}$`);
      question.addParagraph('solution', `$\\therefore$ The equation of the circle is $x^2+y^2=${r*r}$`);

      // c


      question.addHeading('solution', `c) Use the equation of the circle found in part b.`);
      question.addParagraph('solution', `Let $x=${x}$`);
      question.addParagraph('solution', `$(${x})^2+y^2=${r*r}$`);
      question.addParagraph('solution', `$${x*x}+y^2=${r*r}$`);
      question.addParagraph('solution', `$y^2=${y*y}$`);
      question.addParagraph('solution', `$\\therefore y=${y}$ or $y=${-y}$`);

       // answers

       const g1 = new MCQuestion.InputGroup(1);

      g1.addInput(parseFloat(c.toFixed(1)), 0.05, `$(a)$`);
      question.addInputGroup(g1);


      const g2 = new MCQuestion.InputGroup(2);

      g2.addInput([y, -y], 0, `$(c)$`);
      question.addInputGroup(g2);

      question.requiresSelfMarking();


      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_Circles_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    
    const r = MCRandom.randomInt(6, 16, 2);

    const x1 = MCRandom.randomInt(-r, r);
    const y1 = new MCMaths.Fraction(Math.sqrt(r*r - x1*x1)* MCRandom.randomInt(-1, 1, 2));

    const y2 = MCRandom.randomInt(-r, r);
    const x2 = new MCMaths.Fraction(Math.sqrt(r*r - y2*y2)* MCRandom.randomInt(-1, 1, 2));

    const m = new MCMaths.Fraction((y2-y1)/(x2-x1));
    const c = new MCMaths.Fraction(y2 - m * x2);

    const A = Math.PI * r*r;

    const aq = new MCMaths.Fraction(1).add(m.multiply(m))
    const bq = new MCMaths.Fraction(2).multiply(m.multiply(c))
    const cq = c.multiply(c).subtract(new MCMaths.Fraction(r*r))

    const poly = new MCMaths.Polynomial([aq.toFloat(), bq.toFloat(), cq.toFloat()], `x`);

    const c24 = new MCMaths.Fraction(24);
    /* END VARIABLES AND CONSTANTS */
    if ( y1.multiply(c24).toFloat() % 1 === 0 && x2.multiply(c24).toFloat() % 1 === 0 && x1 !== x2.toFloat() && y1.toFloat()!== y2 && m.multiply(c24).toFloat() % 1 === 0 && c.multiply(c24).toFloat() % 1 === 0 
          &&  x1 > x2.toFloat() && (poly.roots()[0] === x1||poly.roots()[1] === x1)) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph(`question`, MCMaths.cleaner(`The figure below shows a circle, $C$.`));

      const g = new MCQuestion.Graph(r + 4, -(r + 4), r + 4, -(r + 4), 2, 2);
      g.addCircle(0, 0, r);
      question.addGraph('question', g);

      question.addParagraph('question', `a) Calculate the area of $C$.`);
      question.addParagraph('question', MCMaths.cleaner(`The line $L$ with equation $\\displaystyle y=${m}x+${c}$ intersects $C$ at two points $A$ and $B$ where $A$ is the rightmost point.`));
      question.addParagraph('question', `b) Determine the coordinates of $A$ and $B$.`);
      question.addParagraph('question', `Show your working.`);

      // solution

      // a

      question.addHeading('solution', `a) By reading from the graph we can see that $C$ has a radius, $r=${r}$`);
      question.addParagraph('solution', `The formula for area of a circle, $A$ is $A=\\pi r^2$`);
      question.addParagraph('solution', `$A=\\pi (${r})^2$`);
      question.addParagraph('solution', `$\\approx ${parseFloat(A.toFixed(5))}$`);
      question.addParagraph('solution', `$\\approx ${parseFloat(A.toFixed(2))}$`);

      // b

      question.addHeading('solution', `b) First determine the formula that defines $C$.`);
      question.addParagraph('solution', `A circle centered at $(0,0)$ is defined by the formula of the form $x^2+y^2=r^2$ where $r$ is the circle's radius.`);
      question.addParagraph('solution', `$\\therefore C$ is defined by the formula $x^2+y^2=${r}^2$`);

      question.addHeading('solution', MCMaths.cleaner(`Let $\\displaystyle y=${m}x+${c}$`));
      question.addParagraph('solution',MCMaths.cleaner( `$ \\displaystyle x^2+(${m}x+${c})^2=${r}^2$`));
      question.addParagraph('solution',MCMaths.cleaner( `$ \\displaystyle x^2+${m.multiply(m)}x^2+${bq}x+${c.multiply(c)}=${r*r}$`));
      question.addParagraph('solution',MCMaths.cleaner( `$ \\displaystyle ${aq}x^2+${bq}x+${cq}=0$`));

      question.addHeading('solution', `Use the quadratic formula $\\left(x=\\displaystyle \\frac{-b \\pm \\sqrt{b^2-4ac}}{2a}\\right)$ to find solutions for $x$.`);
      question.addParagraph('solution', `Let $ \\displaystyle a=${aq}, b=${bq}$ and $c= \\displaystyle ${cq}$`);
      question.addHeading('solution', MCMaths.cleaner(`$x=\\displaystyle \\frac{${bq.multiply(new MCMaths.Fraction(-1))} \\pm \\sqrt{(${bq})^2-4(${aq})(${cq})}}{2(${aq})}$`));

      question.addParagraph('solution', `$\\therefore x=${x1}$ or $x=${x2}$`);

      question.addHeading('solution', MCMaths.cleaner(`Sub these values into the equation of $L, \\displaystyle y=${m}x+${c}$`));
      question.addParagraph('solution', `Let $x=${x1}$`);
      question.addParagraph('solution', MCMaths.cleaner(`$y=${m}(${x1})+${c}$`));
      question.addParagraph('solution', (`$\\therefore y=${y1}$`));
      question.addParagraph('solution', `Let $x=${x2}$`);
      question.addParagraph('solution', MCMaths.cleaner(`$y=${m}(${x2})+${c}$`));
      question.addParagraph('solution', (`$\\therefore y=${y2}$`));

      question.addParagraph('solution', `$L$ intersects $C$ at $(${x1}, ${y1})$ and $(${x2}, ${y2})$.`);

      const g1 = new MCQuestion.InputGroup(1);

      g1.addInput(parseFloat(A.toFixed(1)), 0.05, `$(a)$`);
      question.addInputGroup(g1);

      

      const g2 = new MCQuestion.InputGroup(2);
      g2.addInput(x1, 0.05, `$(b)\\ x_A =$`);
      g2.addInput(y1.toFloat(), 0.05, `$(b)\\ y_A =$`);

      g2.addInput(x2.toFloat(), 0.05, `$(b)\\ x_B =$`);
      g2.addInput(y2, 0.05, `$(b)\\ y_B =$`);
      question.addInputGroup(g2);

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_Circles_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    
    const r = MCRandom.randomInt(2, 16)

    const x = MCRandom.randomInt(-r + 1, r - 1);
    const y = Math.sqrt(r*r - x*x) * MCRandom.randomInt(-1, 1, 2);

    const mr = new MCMaths.Fraction((y)/(x));

    const mt = new MCMaths.Fraction(-1).divide(mr)
    const c = new MCMaths.Fraction(y).subtract(mt.multiply(new MCMaths.Fraction(x)))

    /* END VARIABLES AND CONSTANTS */
    if ( y % 1 === 0 && x!== 0 && y!== 0 && parseFloat(MCMaths.cleaner(`${c}`).match(/\d+/g)[0]) < 100) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph('question', `A circle has the equation $x^2+y^2=${r}^2$`);

      question.addParagraph('question', `a) Show that the point $P(${x}, ${y})$ lies on the circumference of the circle.`);
      question.addParagraph('question', `b) Find the equation of the tangent to the circle at $P$, giving your answer in the form $y=mx+c$.`);

      // solution

      question.addHeading('solution', `a) Given the equation of the circle, let $x=${x}$ and $y=${y}$`);
      question.addParagraph('solution', `$(${x})^2+(${y})^2=${r}^2$`);
      question.addParagraph('solution', `$${x*x}+${y*y}=${r*r}$`);
      question.addParagraph('solution', `$${r*r}=${r*r}$`);
      question.addParagraph('solution', `$\\therefore P$ lies on the circumference of the circle.`);

      // b

      question.addHeading('solution', `b) First find the gradient of the line between the centre of the circle and $P$.`);
      question.addParagraph('solution', `Any circle of the form $x^2+y^2=${r}^2$ is centred on the origin, $(0,0)$.`);

      question.addParagraph('solution', `$m= \\displaystyle \\frac{\\Delta y}{\\Delta x}$`);
      question.addParagraph('solution', `$= \\displaystyle \\frac{${y} - 0}{${x} - 0}$`);
      question.addParagraph('solution', `$= \\displaystyle ${mr}$`);

      question.addParagraph('solution', `The angle between the tangent and radius is $90\\degree$, so the gradient we just calculated is perpendicular to that of the tangent.`);
      question.addHeading('solution', `$\\therefore$ The gradient of the tangent is:`);
      question.addParagraph('solution', `$\\displaystyle \\frac{-1}{\\left(\\displaystyle ${mr}\\right)}=${mt}$`);

      question.addParagraph('solution', `The equation of the tangent is $y=\\displaystyle ${mt}x+c$, where $c$ is a constant.`);
      question.addHeading('solution', `Let $x=${x}$ and $y=${y}$`);
      question.addParagraph('solution', `$${y}=\\displaystyle ${mt}(${x})+c$`);
      question.addParagraph('solution', `$\\therefore c=\\displaystyle ${c}$`);

      question.addParagraph('solution', MCMaths.cleaner(`$\\therefore$ The equation of the tangent is $y=\\displaystyle ${mt}x+${c}$`));

      // answers

      const g1 = new MCQuestion.InputGroup(2);

      g1.addInput(mt.toFloat(), 0.05, `$(b) \\ m =$`);
      g1.addInput(c.toFloat(), 0.05, `$(b) \\ c =$`);


      question.addInputGroup(g1);

      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}
 

export function GCSE_Higher_Algebra_Graphs_Circles_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    
    const r = MCRandom.randomInt(6, 16, 2);

    const x1 = MCRandom.randomInt(-r, r);
    const y1 = new MCMaths.Fraction(Math.sqrt(r*r - x1*x1)* MCRandom.randomInt(-1, 1, 2));

    const y2 = MCRandom.randomInt(-r, r);
    const x2 = new MCMaths.Fraction(Math.sqrt(r*r - y2*y2)* MCRandom.randomInt(-1, 1, 2));

    const m = new MCMaths.Fraction((y2-y1)/(x2-x1));
    const c = new MCMaths.Fraction(y2 - m * x2);

    const A = Math.PI * r*r;

    const aq = new MCMaths.Fraction(1).add(m.multiply(m))
    const bq = new MCMaths.Fraction(2).multiply(m.multiply(c))
    const cq = c.multiply(c).subtract(new MCMaths.Fraction(r*r))

    const poly = new MCMaths.Polynomial([aq.toFloat(), bq.toFloat(), cq.toFloat()], `x`);
    const c24 = new MCMaths.Fraction(24);

    const b_ans = Math.sqrt((x1-x2)**2+(y1-y2)**2)
    /* END VARIABLES AND CONSTANTS */
    if ( y1.multiply(c24).toFloat() % 1 === 0 && x2.multiply(c24).toFloat() % 1 === 0 && x1 !== x2.toFloat() && y1.toFloat()!== y2 && m.multiply(c24).toFloat() % 1 === 0 && c.multiply(c24).toFloat() % 1 === 0 
          &&  x1 > x2.toFloat() && (poly.roots()[0] === x1||poly.roots()[1] === x1) ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph('question', `A circle, $C$ has a radius of $${r}$ and a centre at $(0,0)$.`);
      question.addParagraph('question', `a) State the equation that defines $C$.`);
      question.addParagraph('question', MCMaths.cleaner(`The line $y=\\displaystyle ${m}x+${c}$ intersects $C$ at two points $A$ and $B$.`));
      question.addParagraph('question', `b) Calcualte the length of the line $AB$.`);
      
      // solution

      // a

      question.addHeading('solution', `a) A circle centred on the origin with radius $r$ has an equation of the form $x^2+y^2=r^2$`);
      question.addParagraph('solution', `Let $r=${r}$`);
      question.addParagraph('solution', `$x^2+y^2=${r}^2$`);
      question.addParagraph('solution', `$x^2+y^2=${r*r}$`);
      question.addParagraph('solution', `$\\therefore$ The equation of the circle is $x^2+y^2=${r*r}$`);

      // b
      question.addHeading('solution', `b) Using the equation for $C$ found in part a.`);

      question.addHeading('solution', MCMaths.cleaner(`Let $\\displaystyle y=${m}x+${c}$`));
      question.addParagraph('solution',MCMaths.cleaner( `$ \\displaystyle x^2+(${m}x+${c})^2=${r}^2$`));
      question.addParagraph('solution',MCMaths.cleaner( `$ \\displaystyle x^2+${m.multiply(m)}x^2+${bq}x+${c.multiply(c)}=${r*r}$`));
      question.addParagraph('solution',MCMaths.cleaner( `$ \\displaystyle ${aq}x^2+${bq}x+${cq}=0$`));

      question.addHeading('solution', `Use the quadratic formula $\\left(x=\\displaystyle \\frac{-b \\pm \\sqrt{b^2-4ac}}{2a}\\right)$ to find solutions for $x$.`);
      question.addParagraph('solution', `Let $ \\displaystyle a=${aq}, b=${bq}$ and $c= \\displaystyle ${cq}$`);
      question.addHeading('solution', MCMaths.cleaner(`$x=\\displaystyle \\frac{${bq.multiply(new MCMaths.Fraction(-1))} \\pm \\sqrt{(${bq})^2-4(${aq})(${cq})}}{2(${aq})}$`));

      question.addParagraph('solution', `$\\therefore x=${x1}$ or $x=${x2}$`);

      question.addHeading('solution', MCMaths.cleaner(`Sub these values into the equation of  the line $\\displaystyle y=${m}x+${c}$`));
      question.addParagraph('solution', `Let $x=${x1}$`);
      question.addParagraph('solution', MCMaths.cleaner(`$y=${m}(${x1})+${c}$`));
      question.addParagraph('solution', (`$\\therefore y=${y1}$`));
      question.addParagraph('solution', `Let $x=${x2}$`);
      question.addParagraph('solution', MCMaths.cleaner(`$y=${m}(${x2})+${c}$`));
      question.addParagraph('solution', (`$\\therefore y=${y2}$`));

      question.addParagraph('solution', `The line intersects $C$ at $(${x1}, ${y1})$ and $(${x2}, ${y2})$.`);

      question.addHeading('solution', `Calcualte the length of $AB$ using pythagorus' theorem.`);
      question.addParagraph('solution',`$a^2+b^2=c^2$`);
      question.addParagraph('solution', MCMaths.cleaner(`$(${x1}-${x2})^2+(${y1}-${y2})^2=${(b_ans**2).toFixed(0)}$`));
      question.addParagraph('solution', `$\\sqrt{${(b_ans**2).toFixed(0)}}\\approx ${parseFloat(b_ans.toFixed(2))}$`);

      // answer


      const g1 = new MCQuestion.InputGroup(1);

      g1.addInput(parseFloat(b_ans.toFixed(2)), 0.05, `$(b)$`);
      question.addInputGroup(g1);


      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_FunctionGraphs_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const ans = 1;

    const mode = MCRandom.randomInt(0, 1);

    let f;
    let ys;
    const xs = [0, 30, 60, 90, 120, 150, 180];
    if (mode === 0) {
      f = "\\sin";
      ys = [0, 0.5, Math.sqrt(3) / 2, 1, Math.sqrt(3) / 2, 0.5, 0];
    }
    if (mode === 1) {
      f = "\\cos";
      ys = [1, Math.sqrt(3) / 2, 0.5, 0, -0.5, -Math.sqrt(3) / 2, -1];
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      `$(a)$ Write the values of $${f}(x)$ for $x=0^\\circ,30^\\circ,60^\\circ,90^\\circ,120^\\circ,150^\\circ$ and $180^\\circ.$`
    );
    question.addParagraph(
      "question",
      `$(b)$ Plot the graph of $${f}(x)$ for $0^\\circ \\le x \\le 180^\\circ$.`
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${f}(${xs[0]})=${ys[0]}$`)
    );
    for (let k = 1; k <= 6; k += 1) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$${f}(${xs[k]})=${ys[k]}$`)
      );
    }

    question.addParagraph("solution", "$(b)$");

    const g = new MCQuestion.Graph(180, -15, 1, -1, 15, 0.25);
    if (mode === 0) {
      g.plot("Math.sin(x*Math.PI/180)", 0, 180);
    }
    if (mode === 1) {
      g.plot("Math.cos(x*Math.PI/180)", 0, 180);
    }
    question.addGraph("solution", g);

    const group = new MCQuestion.InputGroup(4);
    for (let k = 0; k <= 6; k += 1) {
      group.addInput(ys[k], 0.1, `$(a) \\ ${f}(${xs[k]})=$`);
    }
    question.addInputGroup(group);
    question.requiresSelfMarking();

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_FunctionGraphs_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const mode = MCRandom.randomInt(0, 1);
    const t1 = MCRandom.randomInt(4, 20);
    const m = MCRandom.randomInt(1, 2) * 15;
    let f;
    let ans1;
    if (mode === 0) {
      f = "\\sin";
      ans1 = Math.sin((m * t1 * Math.PI) / 180);
    }
    if (mode === 1) {
      f = "\\cos";
      ans1 = Math.cos((m * t1 * Math.PI) / 180);
    }

    ans1 = Math.round(ans1 * 1000) / 1000;

    const end = m;

    let t1d;

    if (t1 > 12) {
      t1d = `${t1 % 12}pm`;
    } else if (t1 === 12) {
      t1d = "12pm";
    } else {
      t1d = `${t1}am`;
    }

    const question = new MCQuestion.ExamQuestion();
    question.addMultipleParagraphs("question", [
      `The height of a tide, relative to a fixed point, is given by $${f}(${m}x)$ metres, where $x$ is the hour of the day, i.e. $x=0$ is midnight, $x=14$ is $2pm$.`,
      `$(a)$ Find the relative height of the tide at $${t1d}$.`,
      `$(b)$ Plot the graph of the tide height for times between $12am$ and $12pm$.`,
    ]);

    question.addParagraph(
      "solution",
      `$(a) \\ ${f}(${m}\\times ${t1}) = ${ans1}m$`
    );

    question.addParagraph("solution", "$(b)$");

    const g = new MCQuestion.Graph(12, -1, 1, -1, 1, 0.25);
    if (mode === 0) {
      g.plot(`Math.sin(${m}*x*Math.PI/180)`, 0, 12);
    }
    if (mode === 1) {
      g.plot(`Math.cos(${m}*x*Math.PI/180)`, 0, 12);
    }
    question.addGraph("solution", g);

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans1, 0.1, `$(a)$`);
    question.addInputGroup(group);
    question.requiresSelfMarking();

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_FunctionGraphs_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const ans = MCRandom.randomInt(0, 1);

    const base = MCRandom.randomInt(2, 4) ** MCRandom.randomInt(-1, 1, 2);
    const h = Math.max(base ** 2, base ** -2);
    const e1 = new MCMaths.Exponential(1, base);
    const e1d = e1.toString().replace("1\\cdot", "");

    const x1 = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);
    const y1 = base ** x1;

    const x2 = MCRandom.randomInt(3, 5) * MCRandom.randomInt(-1, 1, 2);
    const y2 = base ** x2;

    const question = new MCQuestion.ExamQuestion();

    const g = new MCQuestion.Graph(2, -2, h, -5, 1, 1);
    g.plot(`${base}**x`, -3, 3);
    question.addGraph("question", g);

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Given that in that $\\left(${x1},${y1}\\right)$ is a point on the given graph:`
      )
    );
    question.addParagraph(
      "question",
      `$(a)$ Write down the function that the graph displays.`
    );
    question.addParagraph(
      "question",
      `$(b)$ Find the value of the graph at $x=${x2}$.`
    );

    question.addParagraph("solution", MCMaths.cleaner(`$(a) \\ y= ${e1d}$`));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${base}^{${x2}} =${y2}$`)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(y2, 0.1, `$(b)$`);
    question.addInputGroup(group);
    question.requiresSelfMarking();

    if (Math.round(y1) !== y1 || y2 > 200 || y2 < 2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_FunctionGraphs_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const ans = MCRandom.randomInt(0, 1);

    const question = new MCQuestion.ExamQuestion();

    const g1 = new MCQuestion.Graph(2, -2, 4, -1, 1, 1);
    g1.plot(`2**x`, -3, 3);
    const g2 = new MCQuestion.Graph(360, 0, 1, -1, 45, 1);
    g2.plot(`Math.sin(x*Math.PI/180)`, 0, 360);
    const g3 = new MCQuestion.Graph(360, 0, 1, -1, 45, 1);
    g3.plot(`Math.cos(x*Math.PI/180)`, 0, 360);
    const g4 = new MCQuestion.Graph(360, 0, 5, -5, 45, 1);
    g4.plot(`Math.tan(x*Math.PI/180)`, 0, 360);
    const g5 = new MCQuestion.Graph(5, -5, 5, -5, 1, 1);
    g5.plot(`1/x`, -5, 5);
    const g6 = new MCQuestion.Graph(3, -3, 8, -8, 1, 2);
    g6.plot(`x**3`, -3, 3);
    const g7 = new MCQuestion.Graph(3, -3, 8, -2, 1, 2);
    g7.plot(`x**2`, -3, 3);
    const gs = [g1, g2, g3, g4, g5, g6, g7];
    const fs = [
      "2^x",
      "\\sin(x)",
      "\\cos(x)",
      "\\tan(x)",
      "\\frac{1}{x}",
      "x^3",
      "x^2",
    ];

    const mode1 = MCRandom.randomInt(0, gs.length - 1);
    const mode2 = MCRandom.randomInt(0, gs.length - 1);
    const mode3 = MCRandom.randomInt(0, gs.length - 1);

    const modes1 = [mode1, mode2, mode3];
    const modes = MCRandom.shuffle(modes1);

    const ans1 = modes.indexOf(mode1) + 1;
    const ans2 = modes.indexOf(mode2) + 1;
    const ans3 = modes.indexOf(mode3) + 1;

    question.addParagraph("question", `Match the following graphs:`);
    question.addParagraph("question", `$(a)$`);
    question.addGraph("question", gs[mode1]);
    question.addParagraph("question", `$(b)$`);
    question.addGraph("question", gs[mode2]);
    question.addParagraph("question", `$(c)$`);
    question.addGraph("question", gs[mode3]);

    question.addParagraph("question", `To the following functions:`);
    question.addParagraph("question", `$(1) \\ ${fs[modes[0]]}$`);
    question.addParagraph("question", `$(2) \\ ${fs[modes[1]]}$`);
    question.addParagraph("question", `$(3) \\ ${fs[modes[2]]}$`);

    question.addParagraph("solution", `$(a) \\ (${ans1}) \\ ${fs[mode1]}$`);
    question.addParagraph("solution", `$(b) \\ (${ans2}) \\ ${fs[mode2]}$`);
    question.addParagraph("solution", `$(c) \\ (${ans3}) \\ ${fs[mode3]}$`);

    const group = new MCQuestion.InputGroup(3);
    group.addInput(ans1, 0.1, `$(a)$`);
    group.addInput(ans2, 0.1, `$(b)$`);
    group.addInput(ans3, 0.1, `$(c)$`);
    question.addInputGroup(group);

    if (
      mode1 === mode2 ||
      mode2 === mode3 ||
      mode3 === mode1 ||
      (ans1 === 1 && ans2 === 2 && ans3 === 3)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_FunctionGraphs_Exam_Q5() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const ans = MCRandom.randomInt(0, 1);

    const question = new MCQuestion.ExamQuestion();

    const g1 = new MCQuestion.Graph(2, -2, 4, -1, 1, 1);
    g1.plot(`2**x`, -3, 3);
    const g2 = new MCQuestion.Graph(360, 0, 1, -1, 45, 1);
    g2.plot(`Math.sin(x*Math.PI/180)`, 0, 360);
    const g3 = new MCQuestion.Graph(360, 0, 1, -1, 45, 1);
    g3.plot(`Math.cos(x*Math.PI/180)`, 0, 360);
    const g4 = new MCQuestion.Graph(360, 0, 5, -5, 45, 1);
    g4.plot(`Math.tan(x*Math.PI/180)`, 0, 360);
    const g5 = new MCQuestion.Graph(5, -5, 5, -5, 1, 1);
    g5.plot(`1/x`, -5, 5);
    const g6 = new MCQuestion.Graph(3, -3, 8, -8, 1, 2);
    g6.plot(`x**3`, -3, 3);
    const g7 = new MCQuestion.Graph(3, -3, 8, -2, 1, 2);
    g7.plot(`x**2`, -3, 3);
    const gs = [g1, g2, g3, g4, g5, g6, g7];
    const fs = [
      "2^x",
      "\\sin(x)",
      "\\cos(x)",
      "\\tan(x)",
      "\\frac{1}{x}",
      "x^3",
      "x^2",
    ];

    const mode1 = MCRandom.randomInt(0, gs.length - 1);
    const mode2 = MCRandom.randomInt(0, gs.length - 1);
    const mode3 = MCRandom.randomInt(0, gs.length - 1);

    const modes1 = [mode1, mode2, mode3];
    const modes = MCRandom.shuffle(modes1);

    const ans1 = modes.indexOf(mode1) + 1;
    const ans2 = modes.indexOf(mode2) + 1;
    const ans3 = modes.indexOf(mode3) + 1;

    question.addParagraph("question", `The following two graphs,`);
    question.addGraph("question", gs[mode1]);
    question.addGraph("question", gs[mode2]);

    question.addParagraph(
      "question",
      `match with two of these three functions:`
    );
    question.addParagraph("question", `$\\bullet \\ ${fs[modes[0]]}$,`);
    question.addParagraph("question", `$\\bullet \\ ${fs[modes[1]]}$,`);
    question.addParagraph("question", `$\\bullet \\ ${fs[modes[2]]}$.`);

    question.addParagraph(
      "question",
      `Draw the graph of the function that does not match a graph.`
    );

    question.addGraph("solution", gs[mode3]);

    question.requiresSelfMarking();

    if (
      mode1 === mode2 ||
      mode2 === mode3 ||
      mode3 === mode1 ||
      (ans1 === 1 && ans2 === 2 && ans3 === 3)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_Lines_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const a = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const b = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const p1 = new MCMaths.Polynomial([a, b]);

    const question = new MCQuestion.ExamQuestion();

    const m = -1 / a;
    const c = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);

    const par = new MCMaths.Polynomial([m, c]);
    const x2 = MCRandom.randomInt(-5, 5);
    const y2 = par.evaluate(x2);

    const g1 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
    g1.plot(`${a}*x+${b}`, -10, 10);
    question.addGraph("question", g1);

    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a)$ Find the equation of the line in the graph.`)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Find the equation of the line passing through $(${x2},${y2})$, perpendicular to the previous line. Give your answer in the form $y=mx+c$.`
      )
    );
    question.addParagraph(
      "question",
      `$(c)$ Plot the graph of the line from part $(b)$, for $-10\\le x \\le 10$.`
    );

    question.addParagraph(
      "solution",
      `$(a)$ Gradient = $\\frac{${2 * a}}{2}=${a}$`
    );
    question.addParagraph("solution", `$y$-Intercept = $${b}$`);
    question.addParagraph("solution", `So, $\\ y=${p1.toString()}$`);

    question.addParagraph(
      "solution",
      `$(b)$ Product of gradients: $${a}\\times m=-1$`
    );
    question.addParagraph("solution", MCMaths.cleaner(`Hence $m=${m}$`));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Finding $c$: $${y2}=${m}\\times ${x2}+c$`)
    );
    question.addParagraph("solution", `$c=${c}$`);
    question.addParagraph("solution", MCMaths.cleaner(`Hence, $y=${m}x+${c}$`));

    question.addParagraph("solution", `$(c)$`);

    const g2 = new MCQuestion.Graph(10, -10, 10, -10, 2, 2);
    g2.plot(`${a}*x+${b}`, -10, 10);
    g2.plot(`${m}*x+${c}`, -10, 10);
    question.addGraph("solution", g2);

    const group = new MCQuestion.InputGroup(2);
    group.addInput(a, 0.01, `$(a) \\ m=$`);
    group.addInput(b, 0.01, `$(a) \\ c=$`);
    group.addInput(m, 0.01, `$(b) \\ m=$`);
    group.addInput(c, 0.01, `$(b) \\ c=$`);
    question.addInputGroup(group);

    question.requiresSelfMarking();

    if (Math.round(y2) !== y2) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_Lines_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const m = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const c1 = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const c2 = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);

    const l1 = new MCMaths.Polynomial([m, c1]);
    const l2 = new MCMaths.Polynomial([m, c2]);

    const x1 = MCRandom.randomInt(-5, 5);
    const x2 = MCRandom.randomInt(-5, 5);
    const x3 = MCRandom.randomInt(-5, 5);
    const x4 = MCRandom.randomInt(-5, 5);

    const y1 = l1.evaluate(x1);
    const y2 = l1.evaluate(x2);
    const y3 = l2.evaluate(x3);
    const y4 = l2.evaluate(x4);

    const question = new MCQuestion.ExamQuestion();

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Four points are as follow: $A=(${x1},${y1}), \\ B=(${x2},${y2}), \\ C=(${x3},${y3})$ and $ D=(${x4},${y4})$.`
      )
    );

    question.addParagraph(
      "question",
      `$(a)$ Find the equation of the line $AB$. Give your answer in the form $y=mx+c$.`
    );
    question.addParagraph(
      "question",
      `$(b)$ By finding the equation of another line, show that $ABCD$ is a trapezium.`
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ Finding gradient: $\\frac{${y2}-${y1}}{${x2}-${x1}}=${m}$`
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Finding $y$-intercept: $${y1}=(${m}\\times ${x1})+c$`)
    );
    question.addParagraph("solution", MCMaths.cleaner(`$c=${c1}$`));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Hence, $y=${m}x+${c1}$.`)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b)$ Finding the equation of $CD$:`)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$m=\\frac{${y4}-${y3}}{${x4}-${x3}}=${m}$`)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Finding $y$-intercept: $${y3}=(${m}\\times ${x3})+c$`)
    );
    question.addParagraph("solution", MCMaths.cleaner(`$c=${c2}$`));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Hence, $y=${m}x+${c2}$.`)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `As the equations have the same gradient, $ABCD$ is a trapezium.`
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(m, 0.01, `$(a) \\ m=$`);
    group.addInput(c1, 0.01, `$(a) \\ c=$`);
    question.addInputGroup(group);

    question.requiresSelfMarking();

    if (c1 === c2 || x1 <= x2 || x3 >= x4) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_Lines_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const m = MCRandom.randomInt(2, 5) * MCRandom.randomInt(-1, 1, 2);
    const c = MCRandom.randomInt(1, 5) * MCRandom.randomInt(-1, 1, 2);
    const mul = MCRandom.randomInt(2, 3) * MCRandom.randomInt(-1, 1, 2);

    const p1 = new MCMaths.Polynomial([m, c]);

    const m2 = -1 / m;

    const question = new MCQuestion.ExamQuestion();

    question.addParagraph(
      "question",
      MCMaths.cleaner(`Given a line $l:${mul * m}x+${-mul}y=${-mul * c}$:`)
    );
    question.addParagraph(
      "question",
      `$(a)$ Find the gradient of a line parallel to $l$.`
    );
    question.addParagraph(
      "question",
      `$(b)$ Find the gradient of a line perpendicular to $l$.`
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a)$ Writing $l$ in the form $y=mx+c$: $y=${m}x+${c}$`)
    );
    question.addParagraph(
      "solution",
      `Parallel lines have the same gradient, so gradient is $${m}$.`
    );
    question.addParagraph(
      "solution",
      `$(b)$ Using product of perpendicular gradients is minus 1:`
    );
    question.addParagraph("solution", `$${m}\\times m = -1$`);
    question.addParagraph("solution", MCMaths.cleaner(`$m = ${m2}$`));

    const group = new MCQuestion.InputGroup(2);
    group.addInput(m, 0.01, `$(a) \\ m=$`);
    group.addInput(m2, 0.01, `$(b) \\ m=$`);
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_Transformations_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const question = new MCQuestion.ExamQuestion();

    const fs = [
      "2^{x}",
      "\\sin(x)",
      "\\cos(x)",
      "\\tan(x)",
      "\\frac{1}{x}",
      "x^3",
      "x^2",
    ];

    const fsp = [
      `2**x`,
      `Math.sin(x*Math.PI/180)`,
      `Math.cos(x*Math.PI/180)`,
      `Math.tan(x*Math.PI/180)`,
      `1/x`,
      `x**3`,
      `x**2`,
    ];

    const plotSizes = [
      [2, -2, 4, -1, 1, 1],
      [360, 0, 1, -1, 45, 1],
      [360, 0, 1, -1, 45, 1],
      [360, 0, 5, -5, 45, 1],
      [5, -5, 5, -5, 1, 1],
      [3, -3, 8, -8, 1, 2],
      [3, -3, 8, -2, 1, 2],
    ];

    const plotRange = [
      [-3, 3],
      [0, 360],
      [0, 360],
      [0, 360],
      [-5, 5],
      [-3, 3],
      [-3, 3],
    ];

    const mode = MCRandom.randomInt(0, fs.length - 1);

    const g = new MCQuestion.Graph(...plotSizes[mode]);
    const g2 = new MCQuestion.Graph(...plotSizes[mode]);
    g.plot(fsp[mode], ...plotRange[mode]);

    const transformMode = MCRandom.randomInt(0, 3);

    let ftd;
    let gt;
    if (transformMode === 0) {
      ftd = `-${fs[mode]}`;
      g2.plot(`-(${fsp[mode]})`, ...plotRange[mode]);
    }
    if (transformMode === 1) {
      ftd = fs[mode].replace("x", "(-x)").replace("((", "(").replace("))", ")");
      g2.plot(fsp[mode].replace("x", "(-x)"), ...plotRange[mode]);
    }
    if (transformMode === 2) {
      let a = MCRandom.randomInt(1, 3) * MCRandom.randomInt(-1, 1, 2);
      if (mode === 1 || mode === 2) {
        a /= 4;
      }
      ftd = `${fs[mode]}+${a}`;
      g2.plot(`(${fsp[mode]})+${a}`, ...plotRange[mode]);
    }
    if (transformMode === 3) {
      let a = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);
      if (mode === 1 || mode === 2 || mode === 3) {
        a = MCRandom.randomInt(30, 60, 15) * MCRandom.randomInt(-1, 1, 2);
      }
      ftd = fs[mode]
        .replace("x", `(x+${a})`)
        .replace("((", "(")
        .replace("))", ")");
      g2.plot(fsp[mode].replace("x", `(x+${a})`), ...plotRange[mode]);
    }

    question.addParagraph(
      "question",
      `The following graphs shows the plot of $${fs[mode]}$:`
    );
    question.addGraph("question", g);

    question.addParagraph(
      "question",
      MCMaths.cleaner(`Plot the graph of $${ftd}$.`)
    );

    question.addGraph("solution", g2);

    question.requiresSelfMarking();

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Graphs_Transformations_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const question = new MCQuestion.ExamQuestion();

    const fs = [
      "2^{x}",
      "\\sin(x)",
      "\\cos(x)",
      "\\tan(x)",
      "\\frac{1}{x}",
      "x^3",
      "x^2",
    ];

    const fsp = [
      `2**x`,
      `Math.sin(x*Math.PI/180)`,
      `Math.cos(x*Math.PI/180)`,
      `Math.tan(x*Math.PI/180)`,
      `1/x`,
      `x**3`,
      `x**2`,
    ];

    const plotSizes = [
      [2, -2, 4, -1, 1, 1],
      [360, 0, 1, -1, 45, 1],
      [360, 0, 1, -1, 45, 1],
      [360, 0, 5, -5, 45, 1],
      [5, -5, 5, -5, 1, 1],
      [3, -3, 8, -8, 1, 2],
      [3, -3, 8, -2, 1, 2],
    ];

    const plotRange = [
      [-3, 3],
      [0, 360],
      [0, 360],
      [0, 360],
      [-5, 5],
      [-3, 3],
      [-3, 3],
    ];

    const mode = MCRandom.randomInt(0, fs.length - 1);

    const g = new MCQuestion.Graph(...plotSizes[mode]);
    const g2 = new MCQuestion.Graph(...plotSizes[mode]);
    g.plot(fsp[mode], ...plotRange[mode]);

    const transformMode = MCRandom.randomInt(0, 3);

    let ftd;
    let gt;
    let sol;
    if (transformMode === 0) {
      ftd = `-${fs[mode]}`;
      g.plot(`-(${fsp[mode]})`, ...plotRange[mode]);
      sol = "A reflection in the $y$-axis";
    }
    if (transformMode === 1) {
      ftd = fs[mode].replace("x", "(-x)").replace("((", "(").replace("))", ")");
      g.plot(fsp[mode].replace("x", "(-x)"), ...plotRange[mode]);
      sol = "A reflection in the $x$-axis";
    }
    if (transformMode === 2) {
      let a = MCRandom.randomInt(1, 3) * MCRandom.randomInt(-1, 1, 2);
      if (mode === 1 || mode === 2) {
        a /= 4;
      }
      ftd = `${fs[mode]}+${a}`;
      g.plot(`(${fsp[mode]})+${a}`, ...plotRange[mode]);
      sol = `A translation of $${a}$ in the $y$ direction`;
    }
    if (transformMode === 3) {
      let a = MCRandom.randomInt(1, 2) * MCRandom.randomInt(-1, 1, 2);
      if (mode === 1 || mode === 2 || mode === 3) {
        a = MCRandom.randomInt(30, 60, 15) * MCRandom.randomInt(-1, 1, 2);
      }
      ftd = fs[mode]
        .replace("x", `(x+${a})`)
        .replace("((", "(")
        .replace("))", ")");
      g.plot(fsp[mode].replace("x", `(x+${a})`), ...plotRange[mode]);
      sol = `A translation of $${-a}$ in the $x$ direction`;
    }

    question.addParagraph(
      "question",
      `The following graphs shows the plot of $${fs[mode]}$ in blue, and a tranformation of the function in red:`
    );
    question.addGraph("question", g);

    question.addParagraph(
      "question",
      MCMaths.cleaner(`Find the transformation applied to the function`)
    );

    question.addParagraph("solution", MCMaths.cleaner(`${sol}.`));

    question.requiresSelfMarking();

    if (
      (mode === 1 && transformMode === 1) ||
      (mode === 2 && transformMode === 1) ||
      (mode === 6 && transformMode === 1)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Nvam_AlgebraicManipulation_Exam_Q1() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const r_1 = MCRandom.randomInt(1, 4);
    const r_2 = MCRandom.randomInt(1, 4);
    const r_3 = MCRandom.randomInt(1, 4);
    const r_4 = MCRandom.randomInt(1, 4);
    const r_5 = MCRandom.randomInt(1, 4);
    const r_6 = MCRandom.randomInt(1, 4);

    const s_1 = MCRandom.randomInt(1, 4);
    const s_2 = MCRandom.randomInt(1, 4);
    const s_3 = MCRandom.randomInt(1, 4);
    const s_4 = MCRandom.randomInt(1, 4);
    const s_5 = MCRandom.randomInt(1, 4);
    const s_6 = MCRandom.randomInt(1, 4);
    const s_7 = MCRandom.randomInt(1, 4);

    const general_Eq = `${1}ax^${3}+${1}bx^${2}+${1}cx+${1}d`;

    const general_Eq_b = `${1}qx^${3}+${s_7}x^${2}+${1}rx+${1}s`;

    const bracket1 = `${r_1}x+${r_2}`;
    const bracket2 = `${r_3}x+${r_4}`;
    const bracket3 = `${r_5}x+${r_6}`;

    const bracket1_b = `${s_1}x+${s_2}`;
    const bracket2_b = `${s_3}x+${s_4}`;
    const bracket3_b = `${s_5}px+${s_6}`;

    const multipliedBrackets = `(${bracket1})(${bracket2})(${bracket3})`;

    const multipliedBrackets_b = `(${bracket1_b})(${bracket2_b})(${bracket3_b})`;

    const x3_Coe = r_5 * r_1 * r_3;
    const x2_Coe = r_2 * r_3 * r_5 + r_4 * r_1 * r_5 + r_6 * r_1 * r_3;
    const x1_Coe = r_2 * r_4 * r_5 + r_2 * r_3 * r_6 + r_4 * r_1 * r_6;
    const x0_Coe = r_2 * r_4 * r_6;

    const x3_Coe_b = r_5 * r_1 * r_3;
    const x2_Coe_b_p1 = r_2 * r_3 * r_5 + r_4 * r_1 * r_5;
    const x2_Coe_b_p0 = r_6 * r_1 * r_3;
    const x1_Coe_b_p1 = r_2 * r_4 * r_5;
    const x1_Coe_b_p0 = r_2 * r_3 * r_6 + r_4 * r_1 * r_6;
    const x0_Coe_b = r_2 * r_4 * r_6;

    const specific_Eq = `${x3_Coe}x^${3}+${x2_Coe}x^${2}+${x1_Coe}x+${x0_Coe}`;

    const c1 = `[${x2_Coe_b_p1}p+${x2_Coe_b_p0}]x`;
    const c2 = `[${x1_Coe_b_p1}p+${x1_Coe_b_p0}]x`;
    const specific_Eq_b = `${x3_Coe_b}px^${3}+${c1}^2+${c2}+${x0_Coe_b}`;

    const p = (s_7 - x2_Coe_b_p0) / x2_Coe_b_p1;
    const q = x3_Coe_b * p;
    const r = x1_Coe_b_p1 + x1_Coe_b_p0;
    const s = x0_Coe_b;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " $(a)$ Find the values of $a, b, c$ and $d$ by comparing coefficients with the following identity:",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${multipliedBrackets} \\equiv ${general_Eq}$`, false)
    );

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " $(b)$ Find the values of $p, q, r$ and $s$ by comparing coefficients with the following identity:",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${multipliedBrackets_b} \\equiv ${general_Eq_b}$`,
        false
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " $(a)$ First expand the brackets to obtain the following:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${multipliedBrackets} = ${specific_Eq}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${specific_Eq} \\equiv ${general_Eq}$`, false)
    );
    question.addParagraph("solution", MCMaths.cleaner(" Therefore:", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $a = ${x3_Coe}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $b = ${x2_Coe}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $c = ${x1_Coe}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $d = ${x0_Coe}$`, false)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " $(b)$ First expand the brackets to obtain the following:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${multipliedBrackets_b} = ${specific_Eq_b}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${specific_Eq_b} \\equiv ${general_Eq_b}$`, false)
    );
    question.addParagraph("solution", MCMaths.cleaner(" Therefore:", false));
    question.addParagraph("solution", MCMaths.cleaner(` $p = ${p}$`, false));
    question.addParagraph("solution", MCMaths.cleaner(` $q = ${q}$`, false));
    question.addParagraph("solution", MCMaths.cleaner(` $r = ${r}$`, false));
    question.addParagraph("solution", MCMaths.cleaner(` $s = ${s}$`, false));

    const group = new MCQuestion.InputGroup(4);
    group.addInput(x3_Coe, 0, "$a=$");
    group.addInput(x2_Coe, 0, "$b=$");
    group.addInput(x1_Coe, 0, "$c=$");
    group.addInput(x0_Coe, 0, "$d=$");
    question.addInputGroup(group);

    const group2 = new MCQuestion.InputGroup(4);
    group2.addInput(p, 0, "$p=$");
    group2.addInput(q, 0, "$q=$");
    group2.addInput(r, 0, "$r=$");
    group2.addInput(s, 0, "$s=$");
    question.addInputGroup(group2);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      p !== Math.round(p) ||
      (p === -1 && MCRandom.randomInt(1, 3) === 2) ||
      p === 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Nvam_AlgebraicManipulation_Exam_Q2() {
  function gcd_two_numbers(x2, y2) {
    let x = Math.abs(x2);
    let y = Math.abs(y2);
    while (y) {
      const t = y;
      y = x % y;
      x = t;
    }
    return x;
  }

  function lcm(x, y) {
    if (typeof x !== "number" || typeof y !== "number") return false;
    return !x || !y ? 0 : Math.abs((x * y) / gcd_two_numbers(x, y));
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const num = MCRandom.randomInt(1, 10);

    const diva = MCRandom.randomInt(2, 26);
    const divb = MCRandom.randomInt(2, 26);
    const divc = MCRandom.randomInt(2, 26);
    const xa = MCRandom.randomInt(-9, 9);
    const xb = MCRandom.randomInt(-9, 9);
    const xc = MCRandom.randomInt(-9, 9);
    const ca = MCRandom.randomInt(1, 9);
    const cb = MCRandom.randomInt(1, 9);
    const cc = MCRandom.randomInt(1, 9);

    const divLCM = lcm(lcm(diva, divb), divc);

    // Working A
    const xaW = xa * (divLCM / diva);
    const caW = ca * (divLCM / diva);
    // Working B
    const xbW = xb * (divLCM / divb);
    const cbW = cb * (divLCM / divb);
    // Working C
    const xcW = xc * (divLCM / divc);
    const ccW = cc * (divLCM / divc);

    // Working (More Of)
    const sumC = ccW - caW - cbW;
    const sumX = xaW + xbW - xcW;

    const answer = sumC / sumX;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$ {{${xa}x+${ca}}\\over${diva}}+{{${xb}x+${cb}}\\over${divb}}={{${xc}x+${cc}}\\over${divc}}$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(" Solve for $x$.", false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Multiply both sides by LCM of the denominators $= \\times${divLCM}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$ {${divLCM / diva}(${xa}x+${ca})}` +
          `$$+$$${divLCM / divb}{(${xb}x+${cb})}` +
          `$$=$$${divLCM / divc}{(${xc}x+${cc})}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${xaW}x+${caW}$ $+$ $${xbW}x+${cbW}$ $=$ $${xcW}x+${ccW}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Rearrange in terms of $x$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${sumX}x $ = $${sumC}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x $ = $${answer}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(answer, 0, "$x=$");
    question.addInputGroup(group);

    if (
      diva === divb ||
      divb === divc ||
      divc === diva ||
      divLCM >= 51 ||
      divLCM <= 10 ||
      xa === 0 ||
      xb === 0 ||
      xc === 0 ||
      diva % divb === 0 ||
      divb % diva === 0 ||
      Math.round(10 * answer) !== 10 * answer ||
      sumX === 0 ||
      sumC === 0 ||
      sumX === 1 ||
      sumX === -1
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\
// ||
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)


export function GCSE_Higher_Algebra_Nvam_AlgebraicManipulation_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const a = MCRandom.randomInt(-4, 4);
    const b = MCRandom.randomInt(-8, 8);
    const c = MCRandom.randomInt(-6, 6);
    const power1 = 2;
    const power2 = 2;
    const power3 = 2;

    const bracket1 = `${a}x^${power1}+${b}`;
    const bracket2 = `${1}x^${power2}+${c}y`;

    const bracket3 = `${1}x^${2 * power2}+${c * 2}x^{${power2}}y+${
      c * c
    }y^${2}`;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("Expand and simplify:", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(${bracket1})(${bracket2})^${power3}$`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Expansion of $(${bracket2})^${power3}$: `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(${bracket2})^${power3}=` +
          `x^${2 * power2}+${c}x^{${power2}}y+${c}x^{${power2}}y+${
            c * c
          }y^${2}` +
          `$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$=${bracket3}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Expansion of $(${bracket3})(${bracket1})$:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(${bracket3})(${bracket1})=${a}x^{6}+${c * 2 * a}x^{${
          power2 + power1
        }}y+${c * c * a}x^{${power1}}y^2+${b}x^{${power2 + power2}}+${
          c * b * 2
        }x^{${power2}}y+${c * c * b}y^{2}$`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (a === 0 || b === 0 || c === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// (Ax^{1,2} + B)(X^{1,2} +Cy)^2


export function GCSE_Higher_Algebra_Nvam_AlgebraicManipulation_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const baseList = [2, 3, 5, 7];
    const baseNum = MCRandom.randomInt(0, baseList.length - 1);

    const base = baseList[baseNum];

    const powa1 = MCRandom.randomInt(2, 5);
    const powa2 = MCRandom.randomInt(1, 56) / 60;
    const numa = base ** powa1;

    const powb1 = MCRandom.randomInt(2, 5);
    const powb2 = MCRandom.randomInt(1, 56) / 60;
    const numb = base ** powb1;

    const workinga = powa1 * powa2;
    const workingb = powb1 * powb2;

    const answer = workingb - workinga;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${numa}^{${powa2}}$ $ \\times$ $${base}^x` +
          `$ $=$ $${numb}^{${powb2}}$`,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Find the exact value of $x$.", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Convert all numbers to the same base: $(${base}^${powa1})^${powa2}$ $ \\times$ $${base}^x` +
          `$ $=$ $(${base}^${powb1})^${powb2}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${base}^${workinga}$ $ \\times$ $${base}^x` +
          `$ $=$ $${base}^${workingb}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Simplify the LHS: $${base}^{(${workinga}+ x)}` +
          `$ $=$ $${base}^${workingb}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, $${workinga}+ x $ $= $ $${workingb}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x $ $=$ $${answer}$`, true, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(answer, 0.05, "$x=$");
    question.addInputGroup(group);

    // ||answer === null
    if (
      powa2.denominator >= 13 ||
      powb2.denominator >= 13 ||
      numa > 300 ||
      numb > 300 ||
      powa1 === powb1 ||
      powa2 === powb2 ||
      powa2 === null ||
      powb2 === null ||
      powa2 === 0 ||
      powa2 === 1 ||
      powb2 === 0 ||
      powb2 === 1 ||
      powa2 >= 3 ||
      powb2 >= 3 ||
      workinga === null ||
      workingb === null ||
      answer.denominator >= 20 ||
      answer === 1 ||
      answer === 0 ||
      answer === null
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\
// ||
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)


export function GCSE_Higher_Algebra_Nvam_Functions_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const a = MCRandom.randomInt(1, 5);
    const b = MCRandom.randomInt(-6, 6);
    const x1 = MCRandom.randomInt(-5, 5);
    let signB;
    if (b > 0) {
      signB = " + ";
    } else {
      signB = " ";
    }
    const f = a * x1 + b;
    const c = MCRandom.randomInt(-9, 9);
    const d = c - b;
    const e = d / a;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`Given that $f(x) = ${a}x+${b}$:`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a)$ Find $f(${x1})$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b)$ Solve $f(x) = ${c}$`, false, true, true)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ Substituting $${x1}$ into $f(x)$:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$f(${x1}) = ${a}(${x1})${signB}${b}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$f(${x1}) = ${f}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ f(x) = ${c}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${a}x+${b} = ${c}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${a}x = ${d}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x = ${e}$`, true, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(f, 0, "$(a)$");
    group.addInput(e, 0.01, "$(b)$");
    question.addInputGroup(group);

    if (x1 === 0 || x1 === 1 || b === 0 || Math.round(e) === e) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Algebra_Nvam_Functions_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const x1 = MCRandom.randomInt(-5, 5);
    const x2 = MCRandom.randomInt(-5, 5);
    const a = -1 * (x1 + x2);
    const b = x1 * x2;
    const x = MCRandom.randomInt(-9, 9);
    let signB;
    let signA;
    let signAlpha;
    let signBeta;
    if (b > 0) {
      signB = " + ";
    } else {
      signB = " ";
    }
    if (a > 0) {
      signA = " + ";
    } else {
      signA = " ";
    }
    const f = x ** 2 + a * x + b;
    const alpha = -1 * x1;
    const beta = -1 * x2;
    if (alpha > 0) {
      signAlpha = " + ";
    } else {
      signAlpha = " ";
    }
    if (beta > 0) {
      signBeta = " + ";
    } else {
      signBeta = " ";
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`Given that $f(x) = ${1}x^${2}+${a}x+${b}$:`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a)$ Find $f(${x})$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(b)$ Solve $f(x) = 0$", false, true, true)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ Substituting $${x}$ into $f(x)$:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$f(${x}) = {(${x})}^{2}${signA}${a}(${x})${signB}${b}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$f(${x}) = ${f}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b) \\ f(x) = 0$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${1}x^${2}+${a}x+${b} = 0$`, false, true, true)
    );
    if (x1 === x2) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`\${(x${signAlpha}${alpha})}^{2}$`, false, true, true)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore$ $x = ${x1}$`, false, true, true)
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(x${signAlpha}${alpha})(x${signBeta}${beta}) = 0$`,
          false,
          true,
          true
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore$ $x = ${x1}, x = ${x2}$`,
          false,
          true,
          true
        )
      );
    }

    if (x1 === x2) {
      const group = new MCQuestion.InputGroup(2);
      group.addInput(f, 0, "$(a)$");
      group.addInput(x1, 0, "$(b)$");
      question.addInputGroup(group);
    } else {
      const group = new MCQuestion.InputGroup(1);
      const group2 = new MCQuestion.InputGroup(2);
      group.addInput(f, 0, "$(a)$");
      group2.addInput([x1, x2], 0, "$(b)$");
      question.addInputGroup(group);
      question.addInputGroup(group2);
    }

    if (a === 0 || b === 0 || a === 1 || a === -1 || x1 === 0 || x2 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Algebra_Nvam_Functions_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // f(x)
    const a1 = MCRandom.randomInt(2, 5);
    const b1 = MCRandom.randomInt(-6, 6);
    // g(x)
    const a2 = MCRandom.randomInt(1, 3);
    const b2 = MCRandom.randomInt(-5, 5);
    const c2 = MCRandom.randomInt(-15, 15);
    // gf(x)
    const x = MCRandom.randomInt(-6, 6);

    let signB1;
    let signB2;
    let signC2;
    let a1a;
    let a2a;
    let b2a;
    if (b1 < 0) {
      signB1 = " ";
    } else {
      signB1 = " + ";
    }
    if (a1 === 1) {
      a1a = "";
    } else {
      a1a = a1;
    }
    const fx = a1 * x + b1;
    if (b2 < 0) {
      signB2 = " ";
    } else {
      signB2 = " + ";
    }
    if (c2 < 0) {
      signC2 = " ";
    } else {
      signC2 = " + ";
    }
    if (a2 === 1) {
      a2a = "";
    } else {
      a2a = a2;
    }
    if (b2 === 1) {
      b2a = "";
    } else {
      b2a = b2;
    }
    const gfx = a2 * x ** 2 + b2 * x + c2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`Given that $f(x) = ${a1}x+${b1}$:`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(a)$ Find $f^{-1}(x)$", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Given further that $g(x) = ${a2}x^${2}+${b2}x+${c2}$:`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(b)$ Find $gf(${x})$`, false, true, true)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$ Let $f(x) = y$:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$y = ${a1}x+${b1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Swapping $x$ and $y$:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x = ${a1}y+${b1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Rearranging for $y$:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${a1}y = ${1}x+${-b1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$y = \\frac{${1}x+${-b1}}{${a1}}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore {f}^{-1}(x) = \\frac{${1}x+${-b1}}{${a1}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ f(${x}) = ${a1a}(${x})${signB1}${b1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$f(${x}) = ${fx}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ $gf(${x}) = ${a2a}{(${x})}^{2}${signB2}${b2a}(${x})${signC2}${c2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\therefore$ $gf(${x}) = ${gfx}$`, false, true, true)
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(gfx, 0, "$(b)$");
    question.addInputGroup(group);

    if (b1 === 0 || b2 === 0 || c2 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Algebra_Nvam_Functions_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const alpha = MCRandom.randomInt(-9, 9);
    const beta = MCRandom.randomInt(-9, 9);
    // g(x)
    const a2 = MCRandom.randomInt(-15, 15);
    // f(x)
    const a1 = alpha + beta + a2;
    const b1 = MCRandom.randomInt(-9, 9);
    // g(x)
    const b2 = alpha * beta + b1;
    // g(x) - f(x)
    const a3 = -1 * (alpha + beta);
    const b3 = alpha * beta;

    let signa;
    let signb;
    let alpha2;
    let beta2;

    if (alpha > 0) {
      signa = " - ";
      alpha2 = alpha;
    } else {
      signa = " + ";
      alpha2 = -1 * alpha;
    }
    if (beta > 0) {
      signb = " - ";
      beta2 = beta;
    } else {
      signb = " + ";
      beta2 = -1 * beta;
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`Given that $f(x) = ${a1}x+${b1}$,`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `and that $g(x) = ${1}x^${2}+${a2}x+${b2}$,`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Solve, $g(x) = f(x)$", false, true, true)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${1}x^${2}+${a2}x+${b2} = ${a1}x+${b1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Rearrange to:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${1}x^${2}+${a3}x+${b3} = 0$`, false, true, true)
    );
    if (alpha === beta) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`\${(x${signa}${alpha2})}^{2}$`, false, true, true)
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$\\therefore$ $x = ${alpha}$`, false, true, true)
      );

      const group = new MCQuestion.InputGroup(1);
      group.addInput(alpha, 0);
      question.addInputGroup(group);
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$(x${signa}${alpha2})(x${signb}${beta2}) = 0$`,
          false,
          true,
          true
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore$ $x = ${alpha}, x = ${beta}$`,
          false,
          true,
          true
        )
      );

      const group = new MCQuestion.InputGroup(1);
      group.addInput([alpha, beta], 0);
      question.addInputGroup(group);
    }

    if (a1 === 0 || b1 === 0 || a2 === 0 || b2 === 0 || a3 === 0 || b3 === 0) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Algebra_Nvam_Functions_Exam_Q5() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    let signa1;
    let signa2;
    let a2a;
    let signa2a;
    let signb2;
    let signa3;

    // f(x)
    const a1 = MCRandom.randomInt(-9, 9);
    // g(x)
    const a2 = MCRandom.randomInt(-15, 15);
    const b2 = MCRandom.randomInt(-9, 9);
    // fg(x)
    if (a1 < 0) {
      signa1 = " ";
    } else {
      signa1 = " + ";
    }
    const b3 = a1 + b2;
    // gf(x)
    if (a2 === 1) {
      signa2 = " + ";
      a2a = "";
      signa2a = "";
    } else if (a2 === -1) {
      signa2 = " - ";
      a2a = "";
      signa2a = " - ";
    } else if (a2 < 0) {
      signa2 = " ";
      a2a = a2;
      signa2a = "";
    } else {
      signa2 = " + ";
      a2a = a2;
      signa2a = "";
    }
    if (b2 < 0) {
      signb2 = " ";
    } else {
      signb2 = " + ";
    }
    const a3 = a1 * a2;
    if (a3 < 0) {
      signa3 = " ";
    } else {
      signa3 = " + ";
    }
    // c)
    const p1 = 2 * a1 + a2;
    const q1 = a1 ** 2 + a3 + b2;
    const p = p1 - a2;
    const q = q1 - b3;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Given that $f(x) = ${1}x+${a1}$ and that $g(x) = ${1}x^${2}+${a2}x+${b2}$,`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(a)$ Find $fg(x)$", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(b)$ Find $gf(x)$", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(c)$ Hence solve, $gf(x) = fg(x)$", false, true, true)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(a)$ Substituting $g(x)$ into $f(x)$ gives $fg(x)$:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$fg(x) = (${1}x^${2}+${a2}x+${b2})${signa1}${a1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ $fg(x) = ${1}x^${2}+${a2}x+${b3}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(b)$ Substituting $f(x)$ into $g(x)$ gives $gf(x)$:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore gf(x) = {(${1}x+${a1})}^{2}${signa2}${a2a}(${1}x+${a1})${signb2}${b2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$gf(x) = (${1}x^${2}+${2 * a1}x+${
          a1 ** 2
        }) + (${signa2a}${a2a}x${signa3}${a3})${signb2}${b2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore gf(x) = x^${2}+${2 * a1}${a2}x+${a1 ** 2 + a3 + b2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(c) \\ gf(x) = fg(x)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$x^${2}+${2 * a1}${a2}x+${
          a1 ** 2 + a3 + b2
        }= ${1}x^${2}+${a2}x+${b3}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${p}x+${q} = 0$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${p}x = ${-1 * q}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\therefore$ $x = ${-q / p}$`, false, true, true)
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(-q / p, 0.01, "$(c)$");
    question.addInputGroup(group);

    if (
      a1 === 0 ||
      a2 === 0 ||
      b2 === 0 ||
      b3 === 0 ||
      Math.round(-q / p) !== -q / p
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Algebra_Nvam_Functions_Exam_Q6() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // solutions
    const alpha = MCRandom.randomInt(-10, 10);
    const beta = MCRandom.randomInt(-10, 10);
    // f(x) and g(x)
    const a1 = MCRandom.randomInt(2, 4);
    const b1 = MCRandom.randomInt(-10, 10);
    const b2 = MCRandom.randomInt(-10, 10);
    // part c
    const a3 = -2 * b2;
    const b3 = b2 * b2;
    const a4 = a1;
    const b4 = a3 * a1;
    const c4 = b3 * a1;
    const b5 = b4 - 1;
    const c5 = c4 + b1;
    const b5b = -b5;
    const b = b5;
    const bsmfac = b * b - 4 * a4 * c5;
    const ta = 2 * a4;
    const sqrt = Math.sqrt(bsmfac);
    const top1 = -b + sqrt;
    const top2 = -b - sqrt;
    const ans1 = top1 / ta;
    const ans1a = ans1;
    const ans2 = top2 / ta;
    const ans2a = ans2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`Given that $f(x) = ${a1}x+${b1}$:`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(a)$ Find $f^{-1}(x)$", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Another function, $g(x) = \\sqrt{x}+${b2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(b)$ Find $g^{-1}(x)$", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(c)$ Solve, $f^{-1}(x) = g^{-1}(x)$", false, true, true)
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$ Let $f(x) = y$:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$y = ${a1}x+${b1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Swapping $x$ and $y$:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x = ${a1}y+${b1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Rearranging for $y$:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${a1}y = ${1}x+${-b1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$y = \\frac{${1}x+${-b1}}{${a1}}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore f^{-1}(x) = \\frac{${1}x+${-b1}}{${a1}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b)$ Let $g(x) = y$:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$y = \\sqrt{x}+${b2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Swapping $x$ and $y$:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x = \\sqrt{y}+${b2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Rearranging for $y$:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\sqrt{y} = ${1}x+${-b2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$y = {(${1}x+${-b2})}^{2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(c) \\ {(${1}x+${-b2})}^{2} = \\frac{${1}x+${-b1}}{${a1}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Expanding the bracket:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${1}x^${2}+${a3}x+${b3} = \\frac{${1}x+${-b1}}{${a1}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Rearrange all to one side:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${a4}x^${2}+${b4}x+${c4} = ${1}x+${-b1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${a4}x^${2}+${b5}x+${c5} = 0$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Using the quadratic formula:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$x = \\frac{(${b5b}) ± \\sqrt{{(${b5})}^{2} - 4(${a4})(${c5})}}{2(${a4})}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$x = \\frac{${-b} ± \\sqrt{${bsmfac}}}{${ta}}$`,
        false,
        true,
        true
      )
    );
    if (Math.round(Math.sqrt(bsmfac)) === Math.sqrt(bsmfac)) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$x = \\frac{${-b} ± ${sqrt}}{${ta}}$`,
          false,
          true,
          true
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$x = \\frac{${top1}}{${ta}}$ or $x = \\frac{${top2}}{${ta}}$`,
          false,
          true,
          true
        )
      );
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$\\therefore$ $x = ${ans1a}$ or $x = ${ans2a}$`,
          true,
          true,
          true
        )
      );
    }
    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(2);
    group.addInput([ans1a, ans2a], 0, "$(c) \\ x=$");
    question.addInputGroup(group);

    if (
      b1 === 0 ||
      b2 === 0 ||
      bsmfac < 0 ||
      Math.round(Math.sqrt(bsmfac)) !== Math.sqrt(bsmfac)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Algebra_Nvam_Functions_Exam_Q7() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const rootPower = MCRandom.randomInt(2, 4);
    const xCo = MCRandom.randomInt(2, 30);
    const constant = MCRandom.randomInt(2, 30);
    let funcX = `\\sqrt[${rootPower}]{x}`;
    if (rootPower === 2) {
      funcX = "\\sqrt{x}";
    }
    const questionA = MCRandom.randomInt(2, 100);
    const gX = (xCo * questionA) / (questionA + constant);
    const questionC = MCRandom.randomInt(2, 50);
    const newgX = (xCo * questionC) / (questionC + constant);
    const fX = questionC ** 1 / rootPower;
    const fgX = newgX ** 1 / rootPower;
    const gA = MCRandom.randomInt(1, 25);
    const partD = (gA * constant) / (xCo - gA);

    const aNum = xCo * questionA;
    const aDen = questionA + constant;
    const partA = aNum / aDen;

    const powerSq = rootPower * rootPower;
    const partB = `\\sqrt[${powerSq}]{x}`;

    let funcXWNum = `\\sqrt[${rootPower}]{${questionC}}`;
    if (rootPower === 2) {
      funcXWNum = `\\sqrt{${questionC}}`;
    }

    const partC = fgX;

    let rad = `\\sqrt[${rootPower}]`;
    if (rootPower === 2) {
      rad = "\\sqrt";
    }

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("The functions $f$ and $g$ are defined as:", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$f(x) = ${funcX}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$g(x) = {${xCo}x \\over x + ${constant}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$(a)$ Work out $g(${questionA})$.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Write down an expression for $ff(x)$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ Find the value of $fg(${questionC})$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`It is given that $g(a) = ${gA}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(d)$ Find an expression for $g^{-1}(x)$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(e)$ Find the value of $a$.", false, true, true)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a) \\ g(${questionA}) = {${xCo}  \\times ${questionA} \\over ${questionA} + ${constant}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$g(${questionA}) = {${aNum} \\over ${aDen}} = ${partA}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ff(x) = ${partB}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(c) \\ fg(x) = ${rad}{${xCo}x \\over x + ${constant}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$fg(x) = ${rad}{${xCo}  \\times ${questionC} \\over ${questionC} + ${constant}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$fg(x) = ${parseFloat(partC.toFixed(3))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(d)$ let $y = g(x)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `\${${xCo}x \\over x + ${constant}} = y$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${xCo}x = y(x + ${constant})$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${xCo}x = yx + ${constant}y$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${xCo}x - yx = ${constant}y$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x(${xCo} - y) = ${constant}y$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$x = {${constant}y \\over ${xCo} - y}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$g^{-1}(x) = {${constant}x \\over ${xCo} - x}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(e) \\ g^{-1}(${gA}) = a$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `\${${constant}  \\times ${gA} \\over ${xCo} - ${gA}} = a$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$a = ${partD}$`, false, true, true)
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(3);
    group.addInput(partA, 0.01, "$(a)$");
    group.addInput(partC, 0.01, "$(c)$");
    group.addInput(partD, 0.01, "$(e)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      xCo === constant ||
      Math.round(100 * gX) !== gX * 100 ||
      gX === 1 ||
      partD !== Math.round(partD) ||
      xCo - gA === 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Algebra_Nvam_IdentityAndProof_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const x2 = 1;
    const x = MCRandom.randomInt(-20, 20);
    const constant = MCRandom.randomInt(-12, 12);
    let constantSign = "+";
    if (constant < 0) {
      constantSign = "";
    }
    const xDiv2 = x / 2;
    const fractionCS = (x / 2) ** 2;
    const question2 = `${1}x^${2}+${x}x+${constant}`;
    const completeSquareBracket = `${1}x+${xDiv2}`;
    const completeSquareConst = constant - (x / 2) ** 2;
    const constantAsFraction = completeSquareConst;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`Prove that $${question2}$ is always positive.`, false)
    );
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Complete the square:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(${completeSquareBracket})^{2} - ${fractionCS} ${constantSign} ${constant}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(${completeSquareBracket})^{2} + ${constantAsFraction}$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Given $(${completeSquareBracket})^{2} \\ge 0$ and $${constantAsFraction} \\ge 0$`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ $${question2}$ is always positive`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      x === 0 ||
      constant === 0 ||
      completeSquareConst <= 0 ||
      fractionCS === null
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Algebra_Seai_Inequalities_Exam_Q1() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const maxArea = MCRandom.randomInt(25, 300);
    const wider = MCRandom.randomInt(5, 15);
    const maxHeight_1 = -wider + Math.sqrt(wider * wider + 4 * maxArea) / 2;
    const maxHeight_2 = -wider - Math.sqrt(wider * wider + 4 * maxArea) / 2;

    const expandedTerm = `${1}h^${2}+${wider}h`;
    const quadraticTerm = `${1}h^${2}+${wider}h+${-maxArea}`;

    const minHeight = Math.round(maxHeight_1 - 2);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` A billboard is $${wider}m$ wider than it is tall`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` The billboard also has maximum area of $${maxArea}m^2$ and minumum height of $${minHeight}m$`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " Find the minimum and maximum possible heights of the billboard.",
        false
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Let height = $h$", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Area of billboard is $width  \\times height$", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $width = h + ${wider}$`, false)
    );
    question.addParagraph("solution", MCMaths.cleaner(" $height = h$", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` Therefore $area = h(h+ ${wider})$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` Area is of maximum $${maxArea}m^2$ so:`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $h(h+ ${wider}) \\leq ${maxArea}$`, false)
    );
    question.addParagraph("solution", MCMaths.cleaner(" Expand:", false));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${expandedTerm} \\leq ${maxArea}$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${quadraticTerm} \\leq 0$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Set the left hand side $= 0$ and solve the quadratic equation to obtain critical points:",
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $${quadraticTerm} = 0$`, false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $h = \\frac{-${wider}\\pm\\sqrt{${wider}^2+4${maxArea}}}{2}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $h = ${maxHeight_1.toPrecision(3)},${maxHeight_2.toPrecision(3)}$`,
        false
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" Since the height is non-negative:", false)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $${minHeight.toPrecision(3)}m \\leq h \\leq ${maxHeight_1.toPrecision(
          3
        )}m$`,
        false
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(minHeight, 0.005, "Minimum");
    group.addInput(maxHeight_1, 0.005, "Maximum");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (maxHeight_1 < 3) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Seai_Inequalities_Exam_Q2() {
  let count = 0;

  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const leftNum_x = MCRandom.randomInt(1, 20);
    const rightNum_x = MCRandom.randomInt(1, 20);

    const leftNum_y = MCRandom.randomInt(1, 20);
    const rightNum_y = MCRandom.randomInt(1, 20);
    const question = new MCQuestion.ExamQuestion();

    let ans_string = "";
    const ans = 0;
    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    switch (MCRandom.randomInt(0, 3)) {
      case 0:
        question.addParagraph(
          "question",
          MCMaths.cleaner(" Consider the following inequalities:", false)
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(` $${leftNum_x} \\leq x \\lt ${rightNum_x}$ `, false)
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(` $${leftNum_y} \\leq y \\lt ${rightNum_y}$ `, false)
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            " What are the integer values that satisfy these two inequalities?",
            false
          )
        );

        question.addParagraph("solution", MCMaths.cleaner("", false));
        question.addParagraph(
          "solution",
          MCMaths.cleaner(" Combining the two inequalities we get:", false)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $${leftNum_x} \\leq z \\lt ${rightNum_y}$`, false)
        );

        question.addParagraph("solution", MCMaths.cleaner("", false));

        for (let j = leftNum_x; j < rightNum_y; j += 1) {
          if (j < rightNum_y - 1) {
            ans_string += `${j}, `;
          } else {
            ans_string = `${ans_string.slice(0, -2)}$ and $${j}$.`;
          }
        }

        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` Hence the integer values that satisfy this inequality are $${ans_string}`,
            false
          )
        );

        break;
      case 1:
        question.addParagraph(
          "question",
          MCMaths.cleaner(" Consider the following inequalities:", false)
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(` $${leftNum_x} \\leq x \\leq ${rightNum_x}$ `, false)
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(` $${leftNum_y} \\lt y \\lt ${rightNum_y}$ `, false)
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            " What are the integer values that satisfy these two inequalities?",
            false
          )
        );

        question.addParagraph("solution", MCMaths.cleaner("", false));
        question.addParagraph(
          "solution",
          MCMaths.cleaner(" Combining the two inequalities we get:", false)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $${leftNum_x} \\leq z \\lt ${rightNum_y}$`, false)
        );

        for (let j = leftNum_x; j < rightNum_y; j += 1) {
          if (j < rightNum_y - 1) {
            ans_string += `${j}, `;
          } else {
            ans_string = `${ans_string.slice(0, -2)}$ and $${j}$.`;
          }
        }
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` Hence the integer values that satisfy this inequality are $${ans_string}`,
            false
          )
        );

        break;
      case 2:
        question.addParagraph(
          "question",
          MCMaths.cleaner(" Consider the following inequalities:", false)
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(` $${leftNum_x} \\leq x \\lt ${rightNum_x}$ `, false)
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(` $${leftNum_y} \\lt y \\leq ${rightNum_y}$ `, false)
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            " What are the integer values that satisfy these two inequalities?",
            false
          )
        );

        question.addParagraph("solution", MCMaths.cleaner("", false));
        question.addParagraph(
          "solution",
          MCMaths.cleaner(" Combining the two inequalities we get:", false)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $${leftNum_x} \\leq z \\leq ${rightNum_y}$`, false)
        );

        for (let j = leftNum_x; j <= rightNum_y; j += 1) {
          if (j < rightNum_y) {
            ans_string += `${j}, `;
          } else {
            ans_string = `${ans_string.slice(0, -2)}$ and $${j}$.`;
          }
        }
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` Hence the integer values that satisfy this inequality are $${ans_string}`,
            false
          )
        );

        break;
      case 3:
        question.addParagraph(
          "question",
          MCMaths.cleaner(" Consider the following inequalities:", false)
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(` $${leftNum_x} \\lt x \\leq ${rightNum_x}$ `, false)
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(` $${leftNum_y} \\leq y \\lt ${rightNum_y}$ `, false)
        );
        question.addParagraph(
          "question",
          MCMaths.cleaner(
            " What are the integer values that satisfy these two inequalities?",
            false
          )
        );

        question.addParagraph("solution", MCMaths.cleaner("", false));
        question.addParagraph(
          "solution",
          MCMaths.cleaner(" Combining the two inequalities we get:", false)
        );
        question.addParagraph(
          "solution",
          MCMaths.cleaner(` $${leftNum_x} \\lt z \\lt ${rightNum_y}$`, false)
        );

        for (let j = leftNum_x + 1; j < rightNum_y; j += 1) {
          if (j < rightNum_y - 1) {
            ans_string += `${j}, `;
          } else {
            ans_string = `${ans_string.slice(0, -2)}$ and $${j}$.`;
          }
        }
        question.addParagraph(
          "solution",
          MCMaths.cleaner(
            ` Hence the integer values that satisfy this inequality are $${ans_string}`,
            false
          )
        );
        break;
      default:
    }
    question.requiresSelfMarking();

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      rightNum_x <= leftNum_x ||
      rightNum_y <= leftNum_y ||
      leftNum_x <= leftNum_y ||
      rightNum_x <= rightNum_y ||
      leftNum_x >= rightNum_y ||
      rightNum_y - leftNum_x <= 2 ||
      rightNum_y - leftNum_x >= 6
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Algebra_Seai_Iteration_Exam_Q1() {
  function EvaluateCubic(x, a, b, c, d) {
    const Fx = x * x * x * a + x * x * b + x * c + d;
    return Fx;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const a = MCRandom.randomInt(1, 4);
    const b = MCRandom.randomInt(-3, 5);
    const c = MCRandom.randomInt(-3, 5);
    const d = MCRandom.randomInt(-3, 5);
    let cubic = MCMaths.cleaner(`${a}x^3+${b}x^2+${c}x+${d}`);

    // Check solution between lower and upper bound
    let L = 0;
    let H = 1;
    let FL = EvaluateCubic(L, a, b, c, d);
    let FH = EvaluateCubic(H, a, b, c, d);
    let test = "";
    if (FL * FH >= 0) {
      L = 1;
      H = 2;
      FL = EvaluateCubic(L, a, b, c, d);
      FH = EvaluateCubic(H, a, b, c, d);
    }
    if (FL * FH >= 0) {
      L = 2;
      H = 3;
      FL = EvaluateCubic(L, a, b, c, d);
      FH = EvaluateCubic(H, a, b, c, d);
    }
    if (FL * FH >= 0) {
      L = 3;
      H = 4;
      FL = EvaluateCubic(L, a, b, c, d);
      FH = EvaluateCubic(H, a, b, c, d);
    }
    if (FL * FH >= 0) {
      test = "fail";
    }

    let rearranged = 1;
    // check which letiable = 0.
    // Create rearranged form.
    // Create Iterative form. NOT COMPLETED YET!!!!
    // find X1, X2, X3. NOT COMPLETED YET!!!!

    let X1;
    let X2;
    let X3;
    let rearrangeworking;
    let iterativeform;

    if (b === 0) {
      if (MCRandom.randomInt(0, 1) === 1) {
        rearranged = `$x={${-d}\\over ${a}x^${2}+${c}}$`;
        rearrangeworking = `$${cubic}=0$`;
        rearrangeworking += `$${a}x^${3}+${c}x=${-d}$`;
        rearrangeworking += `$x(${a}x^${2}+${c})=${-d}$`;
        rearrangeworking += rearranged;
        iterativeform = `$x_{n+1}={${-d}\\over ${a}x_{n}^${2}+${c}x_{n}}$`;
        X1 = Math.round((-d / (a * L * L + c)) * 100000) / 100000;
        X2 = Math.round((-d / (a * X1 * X1 + c)) * 100000) / 100000;
        X3 = Math.round((-d / (a * X2 * X2 + c)) * 100000) / 100000;
      } else {
        rearranged = `$x={${-d}+${-a}x^${3}\\over ${c}}$`;
        rearrangeworking = `$${cubic}=0$`;
        rearrangeworking += `$${c}x=${-d}+${-a}x^${3}$`;
        rearrangeworking += rearranged;
        iterativeform = `$x_{n+1}={${-d}+${-a}x_{n}^${3}\\over ${c}x_{n}}$`;
        X1 = Math.round(((-d - a * L * L * L) / c) * 100000) / 100000;
        X2 = Math.round(((-d - a * X1 * X1 * X1) / c) * 100000) / 100000;
        X3 = Math.round(((-d - a * X2 * X2 * X2) / c) * 100000) / 100000;
        if (c === 1 || c === -1) {
          test = "fail";
        }
      }
    } else if (c === 0) {
      if (MCRandom.randomInt(0, 1) === 1) {
        rearranged = `$x={${-d}\\over ${a}x^${2}+${b}x}$`;
        rearrangeworking = `$${cubic}=0$`;
        rearrangeworking += `$${a}x^${3}+${b}x^${2}=${-d}$`;
        rearrangeworking += `$x(${a}x^${2}+${b}x)=${-d}$`;
        rearrangeworking += rearranged;
        iterativeform = `$x_{n+1}={${-d}\\over ${a}x_{n}^${2}+${b}x_{n}}$`;
        X1 = Math.round((-d / (a * L * L + b * L)) * 100000) / 100000;
        X2 = Math.round((-d / (a * X1 * X1 + b * X1)) * 100000) / 100000;
        X3 = Math.round((-d / (a * X2 * X2 + c * X2)) * 100000) / 100000;
      } else {
        rearranged = `$x=\\pm \\sqrt{${-d}+${-a}x^${3}\\over${b}}$`;
        rearrangeworking = `$${cubic}=0$`;
        rearrangeworking += `$${b}x^${2}=${-d}+${-a}x^${3}$`;
        rearrangeworking += `$x^{2}={${-d}+${-a}x^${3}\\over${b}}$`;
        rearrangeworking += rearranged;
        if (b === 1 || b === -1) {
          test = "fail";
        }
        iterativeform = `$x_{n+1}= \\sqrt{${-d}+${-a}x_{n}^${3}\\over${b}}$`;
        X1 = Math.round(((-d - a * L * L * L) / b ** 0.5) * 100000) / 100000;
        X2 = Math.round(((-d - a * X1 * X1 * X1) / b ** 0.5) * 100000) / 100000;
        X3 = Math.round(((-d - a * X2 * X2 * X2) / b ** 0.5) * 100000) / 100000;
      }
    } else {
      rearranged = `$x={${-b}x^${2}+${-a}x^${3}\\over ${c}}$`;
      rearrangeworking = `$${cubic}=0$`;
      rearrangeworking += `$${c}x=${-b}x^${2}+${-a}x^${3}$`;
      rearrangeworking += rearranged;
      iterativeform = `$x_{n+1}={${-b}x_{n}^${2}+${-a}x_{n}^${3}\\over ${c}x_{n}}$`;
      X1 = Math.round(((-b * L * L - a * L * L * L) / c) * 100000) / 100000;
      X2 =
        Math.round(((-b * X1 * X1 - a * X1 * X1 * X1) / c) * 100000) / 100000;
      X3 =
        Math.round(((-b * X2 * X2 - a * X2 * X2 * X2) / c) * 100000) / 100000;
      if (c === 1 || c === -1) {
        test = "fail";
      }
    }
    cubic = cubic.replace("0x^2", "");
    cubic = cubic.replace("0x", "");

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Show $${cubic}=0$ has a solution between $${L}$ and $${H}$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Show $${cubic}=0$ can be written in the form ${rearranged}.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ Using ${iterativeform} with $x_0=${L}$ find $x_3$ (3dp).`,
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ Show $${cubic}=0$ has a solution between $${L}$ and $${H}$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${`${a}x^${3}+${b}x^${2}+${c}x+${d}`.replace(/x/g, `(${L})`)}=${FL}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${`${a}x^${3}+${b}x^${2}+${c}x+${d}`.replace(/x/g, `(${H})`)}=${FH}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ Change in sign shows $${cubic}=0$ has a solution between $${L}$ and $${H}$ `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ Show $${cubic}=0$ can be written in the form ${rearranged}.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(rearrangeworking, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(c)$ Using ${iterativeform} with $x_0=${L}$ find $x_3$ (3dp).`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x_{0}=${L}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x_{1}=${X1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x_{2}=${X2}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x_{3}=${X3}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore x_{3}=${Math.round(X3 * 1000) / 1000}$ (3dp)`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(X3, 0.001, "$x=$");
    question.addInputGroup(group);

    if (
      (b !== 0 && c !== 0 && d !== 0) ||
      test === "fail" ||
      (b === 0 && c === 0) ||
      (b === 0 && d === 0) ||
      (c === 0 && d === 0) ||
      (a * a === b * b && a * a === c * c) ||
      (a * a === c * c && a * a === d * d) ||
      (b * b === c * c && b * b === d * d) ||
      X3 < L + 0.02 ||
      Number.isNaN(X3) ||
      (L === 0 && MCRandom.randomInt(1, 100) > 1) ||
      X3 > H
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


// -------------------------Use this one for repeats--------------------------------------------------------

export function GCSE_Higher_Algebra_Seai_Quadratics_Exam_Q1() {
  function QuadraticFormula(x, a, b, c) {
    const disc = b * b - 4 * a * c;

    const ans1 = (-b + Math.sqrt(disc)) / (2 * a);
    const ans2 = (-b - Math.sqrt(disc)) / (2 * a);

    let working = `Using the quadratic formula, $${x}={-b±\\sqrt{b^2-4ac}\\over2a}$, `;
    working +=
      `$${x}={${-b}±` +
      `\\sqrt{${Math.abs(b)}^2-` +
      `(4\\times${a}\\times${c})}\\over${2}\\times${a}}$, `;
    if (ans1 !== ans2) {
      working += `$${x}={${-b}±\\sqrt{${disc}}\\over${2 * a}}$, `;
    } else {
      working += `$${x}={${-b}\\over${2 * a}}$, `;
    }
    if (ans1 !== ans2) {
      working += `$${x}=${ans1}$ or $${ans2}$`;
    } else {
      working += `$${x}=${ans1}$`;
    }

    const Display = `${MCMaths.cleaner(`${a}x^2+${b}x+${c}`)}=0`;

    let test;
    if (4 * a * c <= b * b) {
      test = true;
    } else {
      test = false;
    }

    if (ans1 >= 9999) {
      test = false;
    }
    if (ans1 <= -9999) {
      test = false;
    }
    if (ans2 >= 9999) {
      test = false;
    }
    if (ans2 <= -9999) {
      test = false;
    }
    if (a === 0) {
      test = false;
    }

    let test2 = true;

    if (a === 0 || b === 0 || c === 0) {
      test2 = false;
    }

    return {
      ans1,
      ans2,
      working,
      Display,
      test,
      test2,
      a,
      b,
      c,
      x,
    };
  }

  let count = 0;
  const j = 0;
  for (let i = 1; i <= 1; i += 1) {
    const a = MCRandom.randomInt(-10, 10);
    const b = MCRandom.randomInt(-10, 10);
    const c = MCRandom.randomInt(-10, 10);

    const test = QuadraticFormula("x", a, b, c);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Solve for $x$, give your answer to 3 decimal places:",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${test.Display}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${test.working}`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput([test.ans1, test.ans2], 0.05, "$x=$");
    question.addInputGroup(group);

    if (
      test.test === false ||
      test.test2 === false ||
      Math.round(test.ans1) === test.ans1 ||
      Math.round(test.ans2) === test.ans2 ||
      Math.round(test.ans1) === 0 ||
      Math.round(test.ans2) === 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Algebra_Seai_Quadratics_Exam_Q2() {
  function QuadraticFormula(x, a, b, c) {
    const disc = b * b - 4 * a * c;

    const ans1 = (-b + Math.sqrt(disc)) / (2 * a);
    const ans2 = (-b - Math.sqrt(disc)) / (2 * a);

    let working = `Using the quadratic formula, $${x}={-b±\\sqrt{b^2-4ac}\\over2a}$, `;
    working +=
      `$${x}={${-b}±` +
      `\\sqrt{${Math.abs(b)}^2-` +
      `(4\\times${a}\\times${c})}\\over${2}\\times${a}}$, `;
    if (ans1 !== ans2) {
      working += `$${x}={${-b}±\\sqrt{${disc}}\\over${2 * a}}$, `;
    } else {
      working += `$${x}={${-b}\\over${2 * a}}$, `;
    }
    if (ans1 !== ans2) {
      working += `$${x}=${ans1}$ or $${ans2}$`;
    } else {
      working += `$${x}=${ans1}$`;
    }

    const Display = `${MCMaths.cleaner(`${a}x^2+${b}x+${c}`)}=0`;

    let test;
    if (4 * a * c <= b * b) {
      test = true;
    } else {
      test = false;
    }

    if (ans1 >= 9999) {
      test = false;
    }
    if (ans1 <= -9999) {
      test = false;
    }
    if (ans2 >= 9999) {
      test = false;
    }
    if (ans2 <= -9999) {
      test = false;
    }
    if (a === 0) {
      test = false;
    }

    let test2 = true;

    if (a === 0 || b === 0 || c === 0) {
      test2 = false;
    }

    return {
      ans1,
      ans2,
      working,
      Display,
      test,
      test2,
      a,
      b,
      c,
      x,
    };
  }

  let count = 0;
  const img_scale = 2;
  for (let i = 1; i <= 1; i += 1) {
    const n1 = MCRandom.randomInt(2, 10);
    const n2 = MCRandom.randomInt(2, 10);
    const n3 = MCRandom.randomInt(-5, 10);
    const n4 = MCRandom.randomInt(2, 20);
    const n5 = MCRandom.randomInt(-5, 20);
    const area = MCRandom.randomInt(50, 200);

    const test = QuadraticFormula(
      "x",
      0.5 * n4 * n2,
      0.5 * n2 * (n5 + n1) + n3 * n4 * 0.5,
      0.5 * n3 * (n5 + n1) - area
    );

    const midpoint = Math.floor((test.ans1 + test.ans2) / 2);

    let x;
    if (test.ans1 >= test.ans2) {
      x = test.ans1;
    } else {
      x = test.ans2;
    }

    const solutionC = n4 * x + n5;

    const question = new MCQuestion.ExamQuestion();

    const img = new MCQuestion.Image("GCSE/Trapezium.jpg", "height", 15);
    img.addOverlay(`$${n1}$`, 50, 1);
    img.addOverlay(MCMaths.cleaner(`$${n2}x+${n3}$`), 7, 45);
    img.addOverlay(MCMaths.cleaner(`$${n4}x+${n5}$`), 50, 90);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The area of this shape is $${area}cm^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Show that $x$ satisfies $${test.Display}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the possible values of $x$ to $3DP$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(c)$ Given that $x$ is greater than $${midpoint}$, what is the length of the longest side to $3DP$?`,
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(a)$ The area of a trapezium is $\\frac{a+b}{2} \\times h$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$h=${n2}x+${n3}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\frac{a+b}{2}=\\frac{${n4}x+${n5}+${n1}}{2}=${0.5 * n4}x+${
          0.5 * (n1 + n5)
        }$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\frac{a+b}{2} \\times h =(${n2}x+${n3})(${0.5 * n4}x+${
          0.5 * (n1 + n5)
        })=${test.a}x^${2}+${test.b}x+${test.c + area}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, $${area}=${test.a}x^${2}+${test.b}x+${test.c + area}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Hence, $${test.Display}$`, false, true, true)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b)$ ${test.working}`, false, true, true)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c)$ $x=${x}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, the longest side $=${n4} \\times x+${n5}=${solutionC}cm$.`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    const group2 = new MCQuestion.InputGroup(1);
    group.addInput([test.ans1, test.ans2], 0.01, "$(b) \\ x=$");
    group2.addInput(solutionC, 0.01, "$(c)$");
    question.addInputGroup(group);
    question.addInputGroup(group2);

    if (
      test.test === false ||
      test.test2 === false ||
      n2 * x + n3 >= n1 ||
      n1 >= n4 * x + n5 ||
      n2 * x + n3 <= 0 ||
      n4 * x + n5 <= 0 ||
      Math.round(test.a) !== test.a ||
      Math.round(test.b) !== test.b ||
      Math.round(test.c) !== test.c ||
      (test.ans1 >= midpoint && test.ans2 >= midpoint) ||
      Math.round(x) === x ||
      n3 === 0 ||
      n5 === 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Algebra_Seai_Quadratics_Exam_Q3() {
  function QuadraticFormula(x, a, b, c) {
    const disc = b * b - 4 * a * c;

    const ans1 = (-b + Math.sqrt(disc)) / (2 * a);
    const ans2 = (-b - Math.sqrt(disc)) / (2 * a);

    let working = `Using the quadratic formula, $${x}={-b±\\sqrt{b^2-4ac}\\over2a}$, `;
    working +=
      `$${x}={${-b}±` +
      `\\sqrt{${Math.abs(b)}^2-` +
      `(4\\times${a}\\times${c})}\\over${2}\\times${a}}$, `;
    if (ans1 !== ans2) {
      working += `$${x}={${-b}±\\sqrt{${disc}}\\over${2 * a}}$, `;
    } else {
      working += `$${x}={${-b}\\over${2 * a}}$, `;
    }
    if (ans1 !== ans2) {
      working += `$${x}=${ans1}$ or $${ans2}$`;
    } else {
      working += `$${x}=${ans1}$`;
    }

    const Display = `${MCMaths.cleaner(`${a}x^2+${b}x+${c}`)}=0`;

    let test;
    if (4 * a * c <= b * b) {
      test = true;
    } else {
      test = false;
    }

    if (ans1 >= 9999) {
      test = false;
    }
    if (ans1 <= -9999) {
      test = false;
    }
    if (ans2 >= 9999) {
      test = false;
    }
    if (ans2 <= -9999) {
      test = false;
    }
    if (a === 0) {
      test = false;
    }

    let test2 = true;

    if (a === 0 || b === 0 || c === 0) {
      test2 = false;
    }

    return {
      ans1,
      ans2,
      working,
      Display,
      test,
      test2,
      a,
      b,
      c,
      x,
    };
  }

  let count = 0;
  const img_scale = 2;
  for (let i = 1; i <= 1; i += 1) {
    let fail = false;

    const n1 = MCRandom.randomInt(-5, 10);
    const n2 = MCRandom.randomInt(-5, 10);
    const n3 = MCRandom.randomInt(-5, 10);
    const n4 = MCRandom.randomInt(-5, 10);
    const n5 = MCRandom.randomInt(-10, 20);
    const n6 = MCRandom.randomInt(-10, 20);
    const n7 = MCRandom.randomInt(-10, 20);
    const n8 = MCRandom.randomInt(-10, 20);
    const area1 = MCRandom.randomInt(20, 300);

    // length display
    const l1 = `${n1}x+${n2}`;
    const l2 = `${n3}x+${n4}`;
    const l3 = `${n5}x+${n6}`;
    const l4 = `${n7}x+${n8}`;

    const test = QuadraticFormula(
      "x",
      n1 * n3,
      n2 * n3 + n1 * n4,
      n2 * n4 - area1
    );

    const x1 = test.ans1;
    const x2 = test.ans2;

    const s1a = n1 * x1 + n2;
    const s1b = n3 * x1 + n4;

    const s2a = n1 * x2 + n2;
    const s2b = n3 * x2 + n4;

    let ans = 0;
    let notAns = 0;
    if (s1a >= 0 && s1b >= 0 && s2a <= 0 && s2b <= 0) {
      ans = x1;
      notAns = x2;
      if (s1b >= s1a) {
        fail = true;
      }
    }
    if (s1a <= 0 && s1b <= 0 && s2a >= 0 && s2b >= 0) {
      ans = x2;
      notAns = x1;
      if (s2b >= s2a) {
        fail = true;
      }
    }
    if (s1a >= 0 && s1b >= 0 && s2a >= 0 && s2b >= 0) {
      ans = x1;
      fail = true;
    }
    if (s1a <= 0 && s1b <= 0 && s2a <= 0 && s2b <= 0) {
      ans = x1;
      fail = true;
    }
    if (ans === 0) {
      fail = true;
    }

    const b1 = n5 * ans + n6;
    const b2 = n7 * ans + n8;

    const area2 = b1 * b2;
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("In $cm$:", false, true, true)
    );
    const img = new MCQuestion.Image("GCSE/SimilarRectangle.jpg", "height", 15);
    img.addOverlay(MCMaths.cleaner(`$${l1}$`), 40 / img_scale + 7, 7);
    img.addOverlay(MCMaths.cleaner(`$${l2}$`), 102 / img_scale, 55 / img_scale);
    img.addOverlay(MCMaths.cleaner(`$${l3}$`), 105, 68);
    img.addOverlay(MCMaths.cleaner(`$${l4}$`), 75, 97);
    question.addImage("question", img);

    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The area of the smaller rectangle is $${area1}cm^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Show that $x$ satisfies $${test.Display}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the area of the larger rectangle to $3DP$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ The area of the smaller rectange: $(${l1})(${l2})=${
          test.a
        }x^${2}+${test.b}x+${test.c + area1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, $${area1}=${test.a}x^${2}+${test.b}x+${test.c + area1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Hence, $${test.Display}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b)$ ${test.working}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `As $${notAns}$ leads to the sides of the smaller rectangle being negative, it is rejected as a solution.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, the area of the larger  rectangle is $(${l3})(${l4})=${b1} \\times${b2}=${area2.toFixed(
          3
        )}cm^2$`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(area2, 0.1, "$(b)$");
    question.addInputGroup(group);

    if (
      test.test === false ||
      test.test2 === false ||
      n1 === 0 ||
      n2 === 0 ||
      n3 === 0 ||
      n4 === 0 ||
      n5 === 0 ||
      n7 === 0 ||
      fail === true ||
      area1 >= area2 ||
      b1 <= 0 ||
      b2 <= 0 ||
      b2 <= b1 ||
      Math.round(ans) === ans ||
      Math.round(notAns) === notAns ||
      test.ans1 === test.ans2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Algebra_Seai_Quadratics_Exam_Q4() {
  function QuadraticFormula(x, a, b, c) {
    const disc = b * b - 4 * a * c;

    const ans1 = (-b + Math.sqrt(disc)) / (2 * a);
    const ans2 = (-b - Math.sqrt(disc)) / (2 * a);

    let working = `Using the quadratic formula, $${x}={-b±\\sqrt{b^2-4ac}\\over2a}$, `;
    working +=
      `$${x}={${-b}±` +
      `\\sqrt{${Math.abs(b)}^2-` +
      `(4\\times${a}\\times${c})}\\over${2}\\times${a}}$, `;
    if (ans1 !== ans2) {
      working += `$${x}={${-b}±\\sqrt{${disc}}\\over${2 * a}}$, `;
    } else {
      working += `$${x}={${-b}\\over${2 * a}}$, `;
    }
    if (ans1 !== ans2) {
      working += `$${x}=${ans1}$ or $${ans2}$`;
    } else {
      working += `$${x}=${ans1}$`;
    }

    const Display = `${MCMaths.cleaner(`${a}x^2+${b}x+${c}`)}=0`;

    let test;
    if (4 * a * c <= b * b) {
      test = true;
    } else {
      test = false;
    }

    if (ans1 >= 9999) {
      test = false;
    }
    if (ans1 <= -9999) {
      test = false;
    }
    if (ans2 >= 9999) {
      test = false;
    }
    if (ans2 <= -9999) {
      test = false;
    }
    if (a === 0) {
      test = false;
    }

    let test2 = true;

    if (a === 0 || b === 0 || c === 0) {
      test2 = false;
    }

    return {
      ans1,
      ans2,
      working,
      Display,
      test,
      test2,
      a,
      b,
      c,
      x,
    };
  }

  let count = 0;
  const img_scale = 1.3;
  for (let i = 1; i <= 1; i += 1) {
    const fail = false;

    const n1 = MCRandom.randomInt(-5, 10);
    const n2 = MCRandom.randomInt(-5, 10);
    const n3 = MCRandom.randomInt(-5, 10);
    const n4 = MCRandom.randomInt(-5, 10);
    const c = MCRandom.randomInt(5, 15);

    const volume = MCRandom.randomInt(100, 5000);

    // length display
    const l1 = `${n1}x+${n2}`;
    const l2 = `${n3}x+${n4}`;

    const test = QuadraticFormula(
      "x",
      n1 * n3 * c,
      c * n2 * n3 + c * n1 * n4,
      c * n2 * n4 - volume
    );

    const l1a = n1 * test.ans1 + n2;
    const l2a = n3 * test.ans1 + n4;
    const l1b = n1 * test.ans2 + n2;
    const l2b = n3 * test.ans2 + n4;

    const question = new MCQuestion.ExamQuestion();

    question.addParagraph(
      "question",
      MCMaths.cleaner("In $cm$:", false, true, true)
    );
    const img = new MCQuestion.Image("GCSE/cuboid4NoCm.jpg", "height", 15);
    img.addOverlay(MCMaths.cleaner(`$${l1}$`), 20, 115 / img_scale);
    img.addOverlay(MCMaths.cleaner(`$${c}$`), 70 / img_scale, 94 / img_scale);
    img.addOverlay(MCMaths.cleaner(`$${l2}$`), 96 / img_scale, 45 / img_scale);

    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The volume of the box is $${volume}cm^3$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Show that $x$ satisfies $${test.Display}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the possible values of $x$ to $3DP$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ The volume of the box: $${c}(${l1})(${l2})=${test.a}x^${2}+${
          test.b
        }x+${test.c + volume}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, $${volume}=${test.a}x^${2}+${test.b}x+${test.c + volume}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Hence, $${test.Display}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b)$ ${test.working}`, false, true, true)
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput([test.ans1, test.ans2], 0.1, "$(b) \\ x=$");
    question.addInputGroup(group);

    if (
      test.test === false ||
      test.test2 === false ||
      n1 === 0 ||
      n2 === 0 ||
      n3 === 0 ||
      n4 === 0 ||
      l1a <= 0 ||
      l2a <= 0 ||
      l1b <= 0 ||
      l2b <= 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Algebra_Seai_Quadratics_Exam_Q5() {
  function QuadraticFormula(x, a, b, c) {
    const disc = b * b - 4 * a * c;

    const ans1 = (-b + Math.sqrt(disc)) / (2 * a);
    const ans2 = (-b - Math.sqrt(disc)) / (2 * a);

    let working = `Using the quadratic formula, $${x}={-b±\\sqrt{b^2-4ac}\\over2a}$, `;
    working +=
      `$${x}={${-b}±` +
      `\\sqrt{${Math.abs(b)}^2-` +
      `(4\\times${a}\\times${c})}\\over${2}\\times${a}}$, `;
    if (ans1 !== ans2) {
      working += `$${x}={${-b}±\\sqrt{${disc}}\\over${2 * a}}$, `;
    } else {
      working += `$${x}={${-b}\\over${2 * a}}$, `;
    }
    if (ans1 !== ans2) {
      working += `$${x}=${ans1}$ or $${ans2}$`;
    } else {
      working += `$${x}=${ans1}$`;
    }

    const Display = `${MCMaths.cleaner(`${a}x^2+${b}x+${c}`)}=0`;

    let test;
    if (4 * a * c <= b * b) {
      test = true;
    } else {
      test = false;
    }

    if (ans1 >= 9999) {
      test = false;
    }
    if (ans1 <= -9999) {
      test = false;
    }
    if (ans2 >= 9999) {
      test = false;
    }
    if (ans2 <= -9999) {
      test = false;
    }
    if (a === 0) {
      test = false;
    }

    let test2 = true;

    if (a === 0 || b === 0 || c === 0) {
      test2 = false;
    }

    return {
      ans1,
      ans2,
      working,
      Display,
      test,
      test2,
      a,
      b,
      c,
      x,
    };
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();

    const n1 = MCRandom.randomInt(-5, 10);
    const n2 = MCRandom.randomInt(-5, 10);
    const n3 = MCRandom.randomInt(-5, 10);
    const n4 = MCRandom.randomInt(-5, 10);

    const total = MCRandom.randomInt(1000, 5000);

    const l1 = `${n1}x+${n2}`;
    const l2 = `${n3}x+${n4}`;

    const test = QuadraticFormula(
      "x",
      n1 * n3,
      n2 * n3 + n1 * n4,
      n2 * n4 - total
    );

    let ans = 0;
    let notAns = 0;

    const val1a = n1 * test.ans1 + n2;
    const val1b = n3 * test.ans1 + n4;
    const val2a = n1 * test.ans2 + n2;
    const val2b = n3 * test.ans2 + n4;

    let mode = 0;
    if (val1a >= 0 && val1b >= 0 && val2a <= 0 && val2b <= 0) {
      mode = 1;
      ans = test.ans1;
      notAns = test.ans2;
    }
    if (val1a <= 0 && val1b <= 0 && val2a >= 0 && val2b >= 0) {
      mode = 2;
      notAns = test.ans1;
      ans = test.ans2;
    }
    if (val1a <= 0 && val1b <= 0 && val2a <= 0 && val2b <= 0) {
      mode = 3;
    }
    if (val1a >= 0 && val1b >= 0 && val2a >= 0 && val2b >= 0) {
      mode = 3;
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `${name.name} has $${total / 1000}kg$ of sugar.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} has $(${l1})$ bags of sugar, each one containing $(${l2})g$ of sugar.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Find $x$ to 3DP.", false, true, true)
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `The total weight of sugar: $(${l1})(${l2})=${test.a}x^${2}+${
          test.b
        }x+${test.c + total}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, $${total}=${test.a}x^${2}+${test.b}x+${test.c + total}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Hence, $${test.Display}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${test.working}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      `But, as $x=${notAns}$ makes $${l1}$ negative, it is rejected, as there cannot be a negative number of bags of sugar.`
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`So, $x=${ans}$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.1, "$x=$");
    question.addInputGroup(group);

    if (
      test.test === false ||
      test.test2 === false ||
      n1 === 0 ||
      n2 === 0 ||
      n3 === 0 ||
      n4 === 0 ||
      mode === 3
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */

// JP2018


export function GCSE_Higher_Algebra_Seai_Quadratics_Exam_Q6() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.

    const person = MCMisc.getName();
    const angleInDegrees = MCRandom.randomInt(20, 60);
    const angleInRadians = (angleInDegrees / 180) * Math.PI;
    const speed = -MCRandom.randomInt(1, 15);
    const bString = `\\sin(${angleInDegrees}^\\circ)t`;
    const postHeight = MCRandom.randomInt(30, 80) / 10;
    const expression = `(0.5g)t^2+${speed}${bString}+${postHeight}`;
    const quadraticFormula = "$t = {{-b \\pm \\sqrt{b^2 - 4ac}} \\over 2a}$";
    const ta = 4.9;
    const tb = speed * Math.sin(angleInRadians);
    const tc = postHeight;

    const result = (-tb + Math.sqrt(tb ** 2 - 4 * ta * tc)) / (2 * ta);
    const result2 = (-tb - Math.sqrt(tb ** 2 - 4 * ta * tc)) / (2 * ta);

    const solution1 = `$t_1 =${result}, t_2 = ${result2}$`;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${person.name} is playing with ${person.hisher} slingshot and trying to hit a target placed on top of a post of height $${postHeight}$ metres.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` ${
          person.HeShe
        } fires a stone with speed $${-speed}$ m/s at an angle of $${angleInDegrees}^\\circ$ to the ground.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " The time it takes, $t$, for the stone to hit the target can be expressed by the following equation:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(` $${expression} = 0$.`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " Where $g=9.8$ is the acceleration due to gravity.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " By using the quadratic formula, find the possible values for $t$, and hence find the time it takes for the stone to hit the target  for the first time.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        " Give your answer to $3$  significant figures.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        ` Qudratic Formula: ${quadraticFormula}`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " First start by finding your values for $a$, $b$, and $c$, so that you can input it into the quadratic formula:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(" $a = 4.9$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $b = ${tb.toPrecision(3)}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(` $c = ${tc}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        " Use these values to input into your quadratic formula and obtain your two values for $t$:",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $t_1 = {{-(${tb.toPrecision(3)}) + \\sqrt{({${tb.toPrecision(
          3
        )}})^2 - 4(4.9)(${tc})}} \\over 2(4.9)}$ `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` $t_2 = {{-(${tb.toPrecision(3)}) - \\sqrt{({${tb.toPrecision(
          3
        )}})^2 - 4(4.9)(${tc})}} \\over 2(4.9)}$ `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${solution1} `, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        ` Hence ${person.name} first hits the target after $${result2}$ seconds (to 3 significant figures).`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(result2, 0.05, "$t=$");
    question.addInputGroup(group);

    if (
      tb ** (2 - 4 * ta * tc) < 0 ||
      tb ** 2 - 4 * ta * tc < 0 ||
      result2 < 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Algebra_Seai_SimultaneousEquations_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // ax+by
    const a = MCRandom.randomInt(-20, 20);
    const b = MCRandom.randomInt(-20, 20);
    const x = MCRandom.randomInt(-9, 9);
    const y = MCRandom.randomInt(-9, 9);
    const sumSquare = x * x + y * y;
    const sumLin = a * x + b * y;
    const c = -a / b;
    const sumLin2 = sumLin / b;

    // a1 x^2 + b1 x + c1
    const a1 = 1 + c * c;
    const b1 = 2 * c * sumLin2;
    const c1 = sumLin2 * sumLin2 - sumSquare;
    const x1 = (-b1 + Math.sqrt(b1 * b1 - 4 * a1 * c1)) / (2 * a1);
    const x2 = (-b1 - Math.sqrt(b1 * b1 - 4 * a1 * c1)) / (2 * a1);
    const y1 = c * x1 + sumLin2;
    const y2 = c * x2 + sumLin2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("Solve algebraically: ", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${1}x^${2}+${1}y^${2}=${sumSquare}$`, false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(`$${a}x+${b}y = ${sumLin}$`, false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Writing the second equation in terms of $x$:  $y= ${c}x+${sumLin2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Substituting into the first equation: $${1}x^${2}+(${c}x+${sumLin2})^2=${sumSquare}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Expanding: $${a1}x^${2}+${b1}x+${c1} = 0$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Solving with quadratic formula: $x=${x1}$ or $x=${x2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Substituting values of x into the second equation to find $y=${y1}$ or $y=${y2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, $x=${x1}$ and $y=${y1}$ or $x=${x2}$ and $y=${y2}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput([x1, x2], 0, "$x=$");
    group.addInput([y1, y2], 0, "$y=$");
    question.addInputGroup(group);

    if (
      (Math.sign(a) === -1 && Math.sign(b) === -1) ||
      a === 0 ||
      b === 0 ||
      b === a ||
      b === -a ||
      Math.round(10 * c.toFixed(1)) !== c * 10 ||
      Math.round(sumLin2 * 10) !== c * 10 ||
      x === 0 ||
      y === 0 ||
      x === 1 ||
      b === 1 ||
      Math.round(10 * x1.toFixed(1)) !== 10 * x1 ||
      Math.round(10 * x2.toFixed(1)) !== 10 * x2 ||
      x1 === x2 ||
      y1 === y2
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Algebra_Sequences_NthTerm_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const a = MCRandom.randomInt(-3, 3);
    const b = MCRandom.randomInt(-9, 9);
    const c = MCRandom.randomInt(-10, 10);

    const f1 = a * 1 ** 2 + b * 1 + c;
    const f2 = a * 2 ** 2 + b * 2 + c;
    const f3 = a * 3 ** 2 + b * 3 + c;
    const f4 = a * 4 ** 2 + b * 4 + c;
    const f5 = a * 5 ** 2 + b * 5 + c;

    const s1 = f2 - f1;
    const s2 = f3 - f2;
    const s3 = f4 - f3;
    const s4 = f5 - f4;

    const t1 = s2 - s1;
    const t2 = s3 - s2;
    const t3 = s4 - s3;

    const i1 = b * 1 + c;
    const i2 = b * 2 + c;
    const i3 = b * 3 + c;
    const i4 = b * 4 + c;
    const i5 = b * 5 + c;

    const j1 = i2 - i1;
    const j2 = i3 - i2;
    const j3 = i4 - i3;
    const j4 = i5 - i4;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("Find the $Nth$ term of this sequence:", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$${f1}, \\ ${f2}, \\ ${f3}, \\ ${f4}, \\ ${f5}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Find the first difference of the terms: $${s1}, \\ ${s2}, \\ ${s3}, \\ ${s4}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Find the second difference of the terms: $${t1}, \\ ${t2}, \\ ${t3}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `As the third difference is constant, the sequence is quadratic, and as half of $${t1}$ is $${a}$, the coefficient of $x^2$ is $${a}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `The difference between $${a}n^2$ and the original sequence is $${i1}, \\ ${i2}, \\ ${i3}, \\ ${i4}, \\ ${i5}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `The second difference of the new sequence: $${j1}, \\ ${j2}, \\ ${j3}, \\ ${j4}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`So the coefficient of $x$ is $${j1}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `The difference between $${a}n^2+${b}n$ and the original sequence is $${c}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `So, the $Nth$ Term is $${a}n^2+${b}n+${c}$`,
        false,
        true,
        true
      )
    );

    question.requiresSelfMarking();

    if (
      a === 0 ||
      b === 0 ||
      Math.sign(f1) === -1 ||
      Math.sign(f2) === -1 ||
      f1 === 0 ||
      c === 0
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */
// \\
// ||
// toFraction()
// toFraction().denominator
// ()
//
// (1+'x^'+2)


export function GCSE_Higher_Geometry_Mac_Congruence_Exam_Q1() {
  let count = 0;
  const img_scale = 1.5;

  for (let i = 1; i <= 1; i += 1) {
    const radiusSmall = MCRandom.randomInt(4, 8);
    let radiusRegMult = MCRandom.randomInt(2, 2);
    const rand = MCRandom.randomInt(0, 1);
    if (rand === 1) {
      const rand1 = MCRandom.randomInt(0, 1);
      if (rand1 === 1) {
        radiusRegMult += 0.5;
      } else {
        radiusRegMult -= 0.5;
      }
    }
    const radiusReg = radiusSmall * radiusRegMult;
    const volSmall = MCRandom.randomInt(180, 220);
    const lengthSF = radiusReg / radiusSmall;
    const volSF = lengthSF ** 3;
    const volReg = volSmall * volSF;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "A fish & chip shop sells chips in small and regular portion sizes as shown below:",
        false
      )
    );
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));
    const img = new MCQuestion.Image(
      "GCSE/Similar/similar_frustum.jpg",
      "height",
      15
    );
    img.addOverlay("Small", 14, -4);
    img.addOverlay("Regular", 78, -4);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The base of the small portion has a radius of $${radiusSmall}cm$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The base of the regular portion has a radius of $${radiusReg}cm$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The volume of the small portion is $${volSmall}{cm}^3$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find the volume of the regular portion.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Give your answer correct to $3$ significant figures.",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Length Scale Factor", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `LSF $= ${radiusReg}  \\div ${radiusSmall} = ${lengthSF}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Volume Scale Factor", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`VSF $= ${lengthSF}^3 = ${volSF}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Volume of Regular Portion", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Volume $= ${volSmall}  \\times ${volSF} = ${volReg}{cm}^3$`,
        false,
        true,
        true
      )
    );
    if (volReg !== volReg.toPrecision(3)) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `Volume $= ${Number(volReg.toPrecision(3))}{cm}^3$`,
          false,
          true,
          true
        )
      );
    }

    const group = new MCQuestion.InputGroup(1);
    group.addInput(volReg, 1);
    question.addInputGroup(group);

    if (volSmall / 5 !== Math.round(volSmall / 5)) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Mac_Congruence_Exam_Q2() {
  let count = 0;
  const img_scale = 2;

  for (let i = 1; i <= 1; i += 1) {
    const lengthS = MCRandom.randomInt(20, 45);
    const widthS = MCRandom.randomInt(20, 45);
    const mult = MCRandom.randomInt(2, 3);
    const lengthL = lengthS * mult;
    const widthL = widthS * mult;
    let wordMult = "";
    if (mult === 2) {
      wordMult = "twice";
    } else {
      wordMult = "three times";
    }
    const saSmall = lengthS * widthS * 4 + widthS * widthS * 2;
    const volLg = lengthL * widthL * widthL;
    const areaSF = mult ** 2;
    const saLg = saSmall * areaSF;
    const volSF = mult ** 3;
    const volS = volLg / volSF;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "A pet shop sells small and large fish tanks as shown below:",
        false
      )
    );
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));
    const img = new MCQuestion.Image(
      "GCSE/Similar/similar_cuboid.jpg",
      "height",
      15
    );
    img.addOverlay("Small", 30 / img_scale + 10, -5);
    img.addOverlay("Large", 148 / img_scale + 5, 30);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The width of the large tank is ${wordMult} that of the small tank.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The total surface area of the small tank is $${saSmall}{cm}^2$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find the total surface area of the large tank.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The volume of the large tank is $${volLg}{cm}^3$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the volume of the small tank.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$ Length Scale Factor", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`LSF $= ${mult}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Area Scale Factor", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`ASF $= ${mult}^2 = ${areaSF}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Surface Area of Large Tank", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `SA $= ${saSmall}  \\times ${areaSF} = ${saLg}{cm}^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b)$ Length Scale Factor", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`LSF $= ${mult}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Volume Scale Factor", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`VSF $= ${mult}^3 = ${volSF}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Volume of Small Tank", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Volume $= ${volLg}  \\div ${volSF} = ${volS}{cm}^3$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(saLg, 0.1, "$(a)$");
    group.addInput(volS, 0.1, "$(b)$");
    question.addInputGroup(group);

    if (
      saSmall / 10 !== Math.round(saSmall / 10) ||
      volLg / 1000 !== Math.round(volLg / 1000)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Mac_Congruence_Exam_Q3() {
  let count = 0;
  const img_scale = 1.5;

  for (let i = 1; i <= 1; i += 1) {
    const lengthA = MCRandom.randomInt(60, 120);
    let mult = MCRandom.randomInt(1, 3);
    const adaptMult = MCRandom.randomInt(0, 1);
    if (adaptMult === 1) {
      mult += 0.5;
    }
    const lengthB = lengthA * mult;
    const volB = MCRandom.randomInt(4, 8) ** 2 * lengthB;
    const lengthSF = mult;
    const volSF = mult ** 3;
    const volA = volB / volSF;
    const underOver = MCRandom.randomInt(0, 1);
    let volNew = 0;
    if (underOver === 0) {
      volNew = volA + MCRandom.randomInt(100, 200);
    } else {
      volNew = volA - MCRandom.randomInt(100, 200);
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "A plumber is replacing a series of pipes in a house.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The plumber has two similar solid pipes available, A and B, as shown below:",
        false,
        true,
        true
      )
    );
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));
    const img = new MCQuestion.Image(
      "GCSE/Similar/Cylinder1.jpg",
      "height",
      15
    );
    img.addOverlay("Pipe A", 20 / img_scale, -3);
    img.addOverlay("Pipe B", 67, -3);
    img.addOverlay(`$${lengthA}cm$`, 32, 15);
    img.addOverlay(`$${lengthB}cm$`, 88, 15);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The length of pipe A is $${lengthA}cm$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The length of pipe B is $${lengthB}cm$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The volume of pipe B is $${volB}\\pi{cm}^3$.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("Pipe B is currently installed.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A minimum volume of $${volNew}\\pi{cm}^3$ must be able to pass through the newly installed pipe.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Is the plumber able to replace pipe B with pipe A?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Length Scale Factor", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `LSF $= ${lengthB}  \\div ${lengthA} = ${lengthSF}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Volume Scale Factor", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `VSF $=$ LSF$^3$ $= ${lengthSF}^3 = ${volSF}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Volume of Pipe A", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Volume $= ${volB}\\pi  \\div ${volSF} = ${volA}\\pi$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Replace pipe?", false, true, true)
    );
    if (volNew < volA) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${volNew}\\pi \\lt ${volA}\\pi \\therefore$ pipe B can be replaced by pipe A`,
          false,
          true,
          true
        )
      );
    } else {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(
          `$${volNew}\\pi \\gt ${volA}\\pi \\therefore$ pipe B cannot be replaced by pipe A`,
          false,
          true,
          true
        )
      );
    }

    question.requiresSelfMarking();

    if (
      lengthA === lengthB ||
      lengthB !== Math.round(lengthB) ||
      volNew !== Math.round(volNew)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Mac_Congruence_Exam_Q4() {
  let count = 0;
  const img_scale = 3;

  for (let i = 1; i <= 1; i += 1) {
    const name1 = MCMisc.getName();
    const name2 = MCMisc.getName();
    const lX = MCRandom.randomInt(2, 5);
    const wX = MCRandom.randomInt(5, 10);
    const dX = MCRandom.randomInt(2, 9);
    const saX = lX * wX * 4 + wX * dX * 2;
    const mult = MCRandom.randomInt(2, 5) + MCRandom.randomInt(10, 99) / 100;
    const aSF = mult ** 2;
    const saY = saX * aSF;
    const volX = lX * wX * dX;
    const aSFfrac = aSF;
    const lSFfrac = mult;
    const vSF = mult ** 3;
    const vSFfrac = vSF;
    const volY = volX * vSF;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1.name} and ${name2.name} have a solid each. The solids are similar.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("They know that:", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$\\bullet$ The surface area of ${name1.name}'s solid is $${saX}{cm}^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$\\bullet$ The surface area of ${name2.name}'s solid is $${saY}{cm}^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$\\bullet$ The volume of ${name1.name}'s solid is $${volX}{cm}^3$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Find the volume of ${name2.name}'s solid.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Area Scale Factor", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${saY}  \\div ${saX} = ${aSFfrac}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Length Scale Factor", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\sqrt{${aSFfrac}} = ${lSFfrac}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Volume Scale Factor", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\left(${lSFfrac}\\right)^3 = ${vSFfrac}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Volume of ${name2.name}'s solid`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Volume $= ${volX}  \\times ${vSFfrac} = ${volY}{cm}^3$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(volY, 1);
    question.addInputGroup(group);

    if (
      saY !== Math.round(saY) ||
      name1.name.slice(-1) === "s" ||
      name2.name.slice(-1) === "s" ||
      aSFfrac === null ||
      lSFfrac === null ||
      vSFfrac === null ||
      volY !== Math.round(volY)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Mac_Congruence_Exam_Q5() {
  let count = 0;
  const img_scale = 2.2;

  for (let i = 1; i <= 1; i += 1) {
    let lengthX = MCRandom.randomInt(4, 20);
    const addDec = MCRandom.randomInt(0, 1);
    if (addDec === 1) {
      const decs = [0.25, 0.5, 0.75];
      const dec = decs[MCRandom.randomInt(0, 2)];
      lengthX += dec;
    }
    const lengthSF = MCRandom.randomInt(2, 5);
    const lengthY = lengthX * lengthSF;
    const heightX = MCRandom.randomInt(2, 60);
    const saX =
      lengthX ** 2 + 2 * lengthX * Math.sqrt(lengthX ** 2 / 4 + heightX ** 2);
    const areaSF = lengthSF ** 2;
    const saY = saX * areaSF;
    const volumeX = (lengthX ** 2 * heightX) / 3;
    const volSF = lengthSF ** 3;
    const volumeY = volumeX * volSF;
    const areaSFFrac = areaSF;
    const lengthSFFrac = lengthSF;
    const volSFFrac = volSF;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The diagram below shows two similar square based pyramids:",
        false
      )
    );
    const img = new MCQuestion.Image(
      "GCSE/Similar/sqbPyramid.jpg",
      "height",
      15
    );
    img.addOverlay(`$${lengthY}cm$`, 210 / img_scale - 3, 105 / img_scale + 40);
    img.addOverlay("A", 12, 0 / img_scale);
    img.addOverlay("B", 140 / img_scale, 0 / img_scale);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The surface area of A is $${saX}{cm}^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The surface area of B is $${saY}{cm}^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The length of each side of the base of B is $${lengthY}{cm}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find the length of each side of the base of A.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The volume of B is $${volumeY}{cm}^3$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(b)$ Find the volume of A.", false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Area Scale Factor", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${saY}  \\div ${saX} = ${areaSFFrac}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Length Scale Factor", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\sqrt{${areaSFFrac}} = ${lengthSFFrac}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Length of Base Side of A", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Length $= ${lengthY}  \\div ${lengthSF} = ${lengthX}cm$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Volume Scale Factor", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$${lengthSF}^3 = ${volSFFrac}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Volume of B", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Volume $= ${volumeY}  \\div ${volSFFrac} = ${volumeX}{cm}^3$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(lengthX, 1, "$(a)$");
    group.addInput(volumeX, 1, "$(b)$");
    question.addInputGroup(group);

    if (
      saX !== Math.round(saX) ||
      volumeX !== Math.round(volumeX) ||
      areaSFFrac === null ||
      lengthSFFrac === null ||
      volSFFrac === null
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Mac_Congruence_Exam_Q6() {
  let count = 0;
  const img_scale = 3;

  for (let i = 1; i <= 1; i += 1) {
    const sideRatio1 = MCRandom.randomInt(2, 5);
    const areaRatio1 = sideRatio1 ** 2;
    const sideRatio2 = MCRandom.randomInt(sideRatio1 + 2, 10);
    const areaRatio2 = sideRatio2 ** 2;
    const volSmall = MCRandom.randomInt(24, 84);
    const volRatio1 = sideRatio1 ** 3;
    const volRatio2 = sideRatio2 ** 3;
    const onePart = volSmall / volRatio1;
    const volLarge = onePart * volRatio2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The surface areas of two similar solids are in the ratio $${areaRatio1}:${areaRatio2}$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The volume of the smaller shape is $${volSmall}{cm}^3$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "What is the volume of the larger shape?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Length ratio = square root of area ratio = $${sideRatio1}:${sideRatio2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Volume ratio = cube of length ratio = $${volRatio1}:${volRatio2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Volume of Large Solid", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `One part $= ${volSmall}  \\div ${volRatio1} = ${onePart}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Volume $= ${onePart}  \\times ${volRatio2} = ${volLarge}{cm}^3$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(volLarge, 0.1);
    question.addInputGroup(group);

    if (
      areaRatio2 / areaRatio1 === Math.round(areaRatio2 / areaRatio1) ||
      (sideRatio1 === 4 && sideRatio2 === 6) ||
      (sideRatio1 === 5 && sideRatio2 === 10) ||
      (sideRatio1 === 4 && sideRatio2 === 10) ||
      volRatio2 / volRatio1 === Math.round(volRatio2 / volRatio1) ||
      onePart * 100 !== Math.round(onePart * 100) ||
      volLarge * 100 !== Math.round(volLarge * 100)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Mac_TriangleArea_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const angle = MCRandom.randomInt(25, 65);
    const a = MCRandom.randomInt(4, 12)
    const b = MCRandom.randomInt(4, 12)

    const area = 0.5 * a * b * Math.sin(angle * Math.PI/180)

    const unknown = parseFloat((Math.sqrt(a*a +b*b - 2*a*b*Math.cos(angle*Math.PI/180))).toFixed(2))
    /* END VARIABLES AND CONSTANTS */
    if ( true ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const img = new MCQuestion.Image(
      "GCSE/NonRightangleTriangle/Cosine/1a.jpg",
      "height",
      15
      );

      img.addOverlay(`$${a}$cm`, 4, 45)
      img.addOverlay(`$${b}$cm`, 45, 100)

      img.addOverlay(`$${angle}\\degree$`, 16, 80)
      question.addImage("question", img);

      question.addParagraph('question', `a) Calculate the area of the triangle above.`);
      question.addParagraph('question', `b) Calculate the length of the missing side.`)

      // solutions
      question.addHeading('solution', `a) Area of a triangle = $\\displaystyle\\frac{1}{2}ab\\sin(C)$`);
      question.addParagraph('solution', `Let $a=${a}, b=${b}, C=${angle}$`);
      if(area === parseFloat(area.toFixed(2)))
      {
      question.addParagraph('solution', `$\\displaystyle\\frac{1}{2}(${a})(${b})\\sin(${angle})=${area}$`);
      }
      else
      {
      question.addParagraph('solution', `$\\displaystyle\\frac{1}{2}(${a})(${b})\\sin(${angle})\\approx ${parseFloat(area.toFixed(2))}$`);

      }
      question.addParagraph('solution', `$\\therefore$ The area of the triangle is $${parseFloat(area.toFixed(2))}$cm$^2$`);

      // b

      question.addHeading('solution', `b) Use the cosine rule, $a^2 = b^2 + c^2 - 2bc\\ cos(A)$`);
      question.addParagraph('solution', `$a = \\displaystyle\\sqrt{b^2 + c^2 - 2bc\\ cos(A)}$`);
      question.addParagraph('solution', `Let $b=${a}, c = ${b}, A=${angle}$`);
      question.addParagraph('solution', `$a = \\displaystyle\\sqrt{${a}^2 + ${b}^2 - 2(${a})(${b})\\ cos(${angle})}$`);

      question.addParagraph('solution', `$\\approx ${unknown}$`);
      question.addParagraph('solution', `$\\therefore$ The length of the unknown side is $${unknown}$cm`);

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(area, 0.05, `$(a)$`);
      g1.addInput(unknown, 0.05, `$(b)$`);
      question.addInputGroup(g1)
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Geometry_Mac_TriangleArea_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const angle = MCRandom.randomInt(25, 65);
    const a = MCRandom.randomInt(4, 12)
    const area = MCRandom.randomInt(20, 50)

    const b = 2*area / (a*Math.sin(angle * Math.PI/180))

    const base = MCRandom.randomInt(4, 16);
    const height = 2*area/base;

    const hypotenuse = Math.sqrt(base**2+height**2)
    /* END VARIABLES AND CONSTANTS */
    if ( true ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

       const img = new MCQuestion.Image(
      "GCSE/NonRightangleTriangle/Cosine/2a.jpg",
      "height",
      15
      );

      img.addOverlay(`$${a}$cm`, 43, 3)
      img.addOverlay(`$A$`, -5, 19, 1.5)
      img.addOverlay(`$B$`, 80, -5, 1.5)
      img.addOverlay(`$C$`, 88, 105, 1.5)

      img.addOverlay(`$${angle}\\degree$`, 69, 13)
      question.addImage("question", img);

      question.addParagraph("question", MCMaths.cleaner(`The triangle above has an area of $${area}$cm$^2.$`));
      question.addParagraph('question', `a) Calculate the length of side $BC$.`);
      question.addParagraph('question', `A right angled triangle, $T$ has a base of length $${base}$cm and the same area as the triangle pictured above.`)
      question.addParagraph('question', `b) Calculate the length of the hypotenuse of $T$.`);

      // solution

      question.addHeading('solution', `a) Area of a triangle, $A = \\displaystyle\\frac{1}{2}ab\\sin(C)$`);
      question.addParagraph('solution', `$\\therefore b = \\displaystyle \\frac{2A}{a\\sin(C)}$`);
      question.addHeading('solution', `Let $A=${area}, a = ${a}, C=${angle}$`);
      question.addParagraph('solution', `$b = \\displaystyle \\frac{2(${area})}{${a}\\sin(${angle})} $`);
      if (parseFloat(b.toFixed(2)) === b)
      {
      question.addParagraph('solution', `$= ${parseFloat(b.toFixed(2))} $`);
      }
      else
      {
      question.addParagraph('solution', `$\\approx ${parseFloat(b.toFixed(2))} $`);

      }
      question.addParagraph('solution', `$\\therefore$ Side $BC$ is $${parseFloat(b.toFixed(2))}$cm long.`);

      // b

      question.addHeading('solution', `b) Area of a triangle, $A=\\frac{1}{2}bh$`);
      question.addParagraph('solution', `Let $A=${area}, b = ${base}$`);
      question.addParagraph('solution', `$${area}=\\frac{1}{2}(${base})h$`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\therefore \\displaystyle h = ${height}$`));

      question.addHeading('solution', `Use Pythagoras' theorem, $a^2+b^2=c^2$`);
      question.addParagraph('solution', MCMaths.cleaner(`Let $a=${base}, b= ${height}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$c=\\displaystyle \\sqrt{${base}^2+(${height})^2}$`));

      if (parseFloat(hypotenuse.toFixed(2)) === hypotenuse)
      {
      question.addParagraph('solution', `$= ${parseFloat(hypotenuse.toFixed(2))} $`);
      }
      else
      {
      question.addParagraph('solution', `$\\approx ${parseFloat(hypotenuse.toFixed(2))} $`);

      }
      question.addParagraph('solution', `$\\therefore$ The hypotenuse of $T$ is $${parseFloat(hypotenuse.toFixed(2))}$cm long.`);

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(b, 0.05, `$(a)$`);
      g1.addInput(hypotenuse, 0.05, `$(b)$`);
      question.addInputGroup(g1)


      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Geometry_Mac_TriangleArea_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const a = MCRandom.randomInt(4, 15)
    const b = MCRandom.randomInt(3, 9)
    const area = MCRandom.randomInt(8, 64)

    const angle = (Math.asin(2*area/(a*b))) * 180/Math.PI
    
    const sf = MCRandom.randomInt(6, 30)/12;

    const c = parseFloat((Math.sqrt(a*a +b*b - 2*a*b*Math.cos(angle*Math.PI/180))).toFixed(2));

    const c2 = c * sf
    const area2 = area * sf * sf
    /* END VARIABLES AND CONSTANTS */
    if ( angle > 20 && angle < 70 && parseFloat(MCMaths.cleaner(`${c2}`).match(/\d+/g)[0]) < 100 && parseFloat(MCMaths.cleaner(`${area2}`).match(/\d+/g)[0]) < 100
      && sf !== 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();
      const img = new MCQuestion.Image(
      "GCSE/NonRightangleTriangle/Cosine/3a.jpg",
      "width",
      15
      );
      img.addOverlay(`$${a}$cm`, 35, 98)

      img.addOverlay(`$${b}$cm`, 86, 70)
      img.addOverlay(`$A$`, -6, 82, 1.5)
      img.addOverlay(`$B$`, 100, -5, 1.5)
      img.addOverlay(`$C$`, 70, 105, 1.5)

      question.addImage("question", img);

      question.addParagraph("question", `The triangle, $T_1$ above has an area of $${area}$cm$^2$.`);
      question.addParagraph('question', `a) Find the size of the angle $\\angle BCA$`);
      question.addParagraph('question', `A similar triangle, $T_2$ is shown below.`);

      const img2 = new MCQuestion.Image(
      "GCSE/NonRightangleTriangle/Cosine/3a.jpg",
      "width",
      15
      );
      img2.addOverlay(MCMaths.cleaner(`$\\displaystyle ${c2}$cm`), 25, 40)
      img2.addOverlay(`$X$`, -6, 82, 1.5)
      img2.addOverlay(`$Y$`, 100, -5, 1.5)
      img2.addOverlay(`$Z$`, 70, 105, 1.5)

      question.addImage("question", img2);

      question.addParagraph('question', `b) Calculate the area of $T_2$.`);


      // solution
      question.addHeading('solution', `a) Area of a triangle, $A = \\displaystyle\\frac{1}{2}ab\\sin(C)$`);
      question.addParagraph('solution', `$\\therefore C = \\displaystyle \\sin^{-1}\\left(\\frac{2A}{ab} \\right)$`);
      question.addHeading('solution', `Let $A=${area}, a=${a}, b=${b}$`);

      question.addParagraph('solution', `$C = \\displaystyle \\sin^{-1}\\left(\\frac{2(${area})}{${a}(${b})} \\right)$`);
      if (parseFloat(angle.toFixed(2)) === angle)
      {
      question.addParagraph('solution', `$= ${parseFloat(angle.toFixed(2))} $`);
      }
      else
      {
      question.addParagraph('solution', `$\\approx ${parseFloat(angle.toFixed(2))} $`);

      }
      question.addParagraph('solution', `$\\therefore$ Angle $\\angle BCA$ is $${parseFloat(angle.toFixed(2))}\\degree$.`);

      // b

      question.addHeading('solution', `b) Calculate the length of side $AB$ using the cosine rule, $a^2 = b^2 + c^2 - 2bc\\ cos(A)$`);
      question.addParagraph('solution', `$a = \\displaystyle\\sqrt{b^2 + c^2 - 2bc\\ cos(A)}$`);
      question.addHeading('solution', `Let $b=${a}, c=${b}, A=${parseFloat((angle).toFixed(2))}$`);
      question.addParagraph('solution', `$a = \\displaystyle\\sqrt{${a}^2 + ${b}^2 - 2(${a})(${b})\\ cos(${parseFloat(angle.toFixed(2))})}$`);
      question.addParagraph('solution', `$\\approx ${c}$`);

      question.addHeading('solution', `Calculate the scale factor that transforms $T_1$ to $T_2$.`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle\\frac{(${c2})}{(${c})} = ${sf}$`));
      question.addHeading('solution', `Square this ratio and multiply by the area of $T_1$.`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle (${sf})^2(${area})=${area2}$`));
      question.addParagraph('solution', MCMaths.cleaner(`$\\therefore$ The area of $T_2$ is $\\displaystyle ${area2}$cm$^2$`));

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(angle, 0.05, `$(a)$`);
      g1.addInput(area2, 0.5, `$(b)$`);
      question.addInputGroup(g1)

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Geometry_Mac_TriangleArea_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here

    const a = MCRandom.randomInt(4, 12)
    const b = MCRandom.randomInt(4, 12)
    const angle = MCRandom.randomInt(25, 65);

    const perimeter = 2*a + 2*b
    const area = a * b * Math.sin(angle * Math.PI/180)

    const AD = parseFloat((Math.sqrt(a*a +b*b - 2*a*b*Math.cos(angle*Math.PI/180))).toFixed(2))

    const perimeter_c = parseFloat((a + b + AD).toFixed(2));
    /* END VARIABLES AND CONSTANTS */
    if ( true ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const img = new MCQuestion.Image(
      "Leo/parallelogram_angle0.svg",
      "width",
      30
      );
      img.addOverlay(`$${a}$cm`, 12, 50)
      img.addOverlay(`$${b}$cm`, 45, 98)
      img.addOverlay(`$${angle}\\degree$`, 17, 80)

      img.addOverlay(`$A$`, 24, 11, 1.5)
      img.addOverlay(`$B$`, 92, 11, 1.5)
      img.addOverlay(`$C$`, 7, 91, 1.5)
      img.addOverlay(`$D$`, 76, 91, 1.5)
      question.addImage("question", img);

      question.addParagraph('question', `a) Calculate the perimeter of the parallelogram above.`);
      question.addParagraph('question', `b) Calculate the area of the parallelogram above.`);
      question.addParagraph('question', `c) Calculate the perimeter of triangle $ADB$.`);

      // solutions

      question.addHeading('solution', `a) Parallel sides in a parallelogram are the same length.`);
      question.addParagraph('solution', `$2(${a}) + 2(${b}) = ${perimeter}$`);
      question.addParagraph('solution', `$\\therefore$ The parallelogram has a perimeter of $${perimeter}$cm.`);

      // b

      question.addHeading('solution', `b) Divide the parallelogram into two equivalent triangles as follows.`);

      const img2 = new MCQuestion.Image(
      "Leo/parallelogram_angle0_split.svg",
      "width",
      30
      );
      img2.addOverlay(`$${a}$cm`, 12, 50)
      img2.addOverlay(`$${b}$cm`, 45, 98)
      img2.addOverlay(`$${angle}\\degree$`, 17, 80)

      img2.addOverlay(`$A$`, 24, 11, 1.5)
      img2.addOverlay(`$B$`, 92, 11, 1.5)
      img2.addOverlay(`$C$`, 7, 91, 1.5)
      img2.addOverlay(`$D$`, 76, 91, 1.5)
      question.addImage("solution", img2);

      question.addHeading('solution', `Area of a triangle, $A=\\displaystyle \\frac{1}{2}ab\\sin(C)$`);
      question.addParagraph('solution', `$\\therefore$ Area of a parallelogram, $A=ab\\sin(C)$`);
      question.addHeading('solution', `Let $a=${a}, b=${b}, C=${angle}$`)
      if(area === parseFloat(area.toFixed(2)))
      {
      question.addParagraph('solution', `$(${a})(${b})\\sin(${angle})=${area}$`);
      }
      else
      {
      question.addParagraph('solution', `$(${a})(${b})\\sin(${angle})\\approx ${parseFloat(area.toFixed(2))}$`);

      }
      question.addParagraph('solution', `$\\therefore$ The area of the parallelogram is $${parseFloat(area.toFixed(2))}$cm$^2$`);
      // c
      question.addHeading('solution', `c) Use the cosine rule, $a^2 = b^2 + c^2 - 2bc\\ cos(A)$, to find the length of $AD$`);
      question.addParagraph('solution', `$a = \\displaystyle\\sqrt{b^2 + c^2 - 2bc\\ cos(A)}$`);
      question.addParagraph('solution', `Let $b=${a}, c = ${b}, A=${angle}$`);
      question.addParagraph('solution', `$a = \\displaystyle\\sqrt{${a}^2 + ${b}^2 - 2(${a})(${b})\\ cos(${angle})}$`);

      question.addParagraph('solution', `$\\approx ${AD}$`);
      question.addParagraph('solution', `$\\therefore$ The length of $AD$ is $${AD}$cm`);
      question.addHeading('solution', `Then calculate the perimeter of $ADB$`);

      question.addParagraph('solution', `$${a}+${b}+${AD}=${perimeter_c}$`);
      question.addParagraph('solution', `$\\therefore$ Triangle $ADB$ has a perimeter of $${perimeter_c}$cm.`);
      //answers

      const g1 = new MCQuestion.InputGroup(3);
      g1.addInput(perimeter, 0, `$(a)$`);
      g1.addInput(area, 0.05, `$(b)$`);
      g1.addInput(perimeter_c, 0.05, `$(c)$`);
      question.addInputGroup(g1)
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Geometry_Mac_TriangleArea_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here


    const angle = MCRandom.randomInt(25, 65);
    const b = MCRandom.randomInt(4, 12)
    const area = MCRandom.randomInt(20, 50, 2)
    const areaT = area/2

    const a = 2*areaT / (b*Math.sin(angle * Math.PI/180))
    const h = area/b


    /* END VARIABLES AND CONSTANTS */
    if ( true ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      const img = new MCQuestion.Image(
      "Leo/parallelogramh_angle0.svg",
      "width",
      30
      );
      img.addOverlay(`$x$ cm`, 12, 50)
      img.addOverlay(`$${b}$cm`, 45, 98)
      img.addOverlay(`$${angle}\\degree$`, 16, 80)

      img.addOverlay(`$A$`, 24, 11, 1.5)
      img.addOverlay(`$B$`, 92, 11, 1.5)
      img.addOverlay(`$C$`, 7, 91, 1.5)
      img.addOverlay(`$D$`, 76, 91, 1.5)
      question.addImage("question", img);

      question.addParagraph('question', `The parallelogram shown above has an area of $${area}$cm$^2$.`);
      question.addParagraph('question', `a) Find the value of $x$.`);
      question.addParagraph('question', `b) Find the perpendicular height of the parallelogram.`);

      // 

      question.addHeading('solution', `a) Divide the parallelogram into two equivalent triangles as follows.`);
      const img2 = new MCQuestion.Image(
      "Leo/parallelogram_angle0_split.svg",
      "width",
      30
      );
      img2.addOverlay(`$x$ cm`, 12, 50)
      img2.addOverlay(`$${b}$cm`, 45, 98)
      img2.addOverlay(`$${angle}\\degree$`, 16, 80)

      img2.addOverlay(`$A$`, 24, 11, 1.5)
      img2.addOverlay(`$B$`, 92, 11, 1.5)
      img2.addOverlay(`$C$`, 7, 91, 1.5)
      img2.addOverlay(`$D$`, 76, 91, 1.5)
      question.addImage("solution", img2);

      question.addHeading('solution', `Each triangle must have an area of half that of the parallelogram, $${areaT}$cm$^2$.`);
      question.addParagraph('solution', `Area of a triangle, $A=\\displaystyle\\frac{1}{2}ab\\sin(C)$`);
      question.addParagraph('solution', `$\\therefore a=\\displaystyle \\frac{2A}{b\\sin(C)}$`);

      question.addHeading('solution', `Let $a=x,b=${b},C=${angle}, A=${areaT}$`);

      question.addParagraph('solution', `$x = \\displaystyle \\frac{2(${areaT})}{${b}\\sin(${angle})} $`);
      if (parseFloat(a.toFixed(2)) === a)
      {
      question.addParagraph('solution', `$= ${parseFloat(a.toFixed(2))} $`);
      }
      else
      {
      question.addParagraph('solution', `$\\approx ${parseFloat(a.toFixed(2))} $`);

      }
      question.addParagraph('solution', `$\\therefore x=${parseFloat(a.toFixed(2))}$`);

      // b

      question.addHeading('solution', `b) Area of a parallelogram, $A=bh$, where $h$ is perpendicular height.`);
      question.addParagraph('solution', `$\\therefore h=\\displaystyle \\frac{A}{b}$`);
      question.addHeading('solution', `Let $A=${area}, b=${b}$`);
      question.addParagraph('solution', MCMaths.cleaner(`$\\displaystyle h=${h}$`));

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(a, 0.05, `$(a)$`);
      g1.addInput(h, 0.05, `$(b)$`);
      question.addInputGroup(g1)
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Geometry_Mac_TrigRules_Exam_Q1() {
  let count = 0;
  const img_scale = 1.3;

  for (let i = 1; i <= 1; i += 1) {
    function randomLength(lower, upper) {
      const int = MCRandom.randomInt(lower, upper);
      const dec = MCRandom.randomInt(1, 9) / 10;
      return int + dec;
    }
    function toRadians(angle) {
      return angle * (Math.PI / 180);
    }
    const angleB = MCRandom.randomInt(75, 95);
    const ab = randomLength(5, 15);
    const bc = randomLength(Math.ceil(ab + 3), 25);
    const ansA = 0.5 * ab * bc * Math.sin(toRadians(angleB));
    const ansB2 = bc ** 2 + ab ** 2 - 2 * bc * ab * Math.cos(toRadians(angleB));
    const ansB = Math.sqrt(ansB2);

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image(
      "GCSE/NonRightangleTriangle/Cosine/3a.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", -5, 108 / img_scale);
    img.addOverlay("$B$", 92 / img_scale, 135 / img_scale);
    img.addOverlay("$C$", 135 / img_scale, -5);
    img.addOverlay(`$${angleB}^\\circ$`, 70 / img_scale + 5, 110 / img_scale);
    img.addOverlay(`$${ab}cm$`, 23 / img_scale + 5, 132 / img_scale - 5);
    img.addOverlay(`$${bc}cm$`, 115 / img_scale + 10, 70 / img_scale);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Calculate the area of the triangle $ABC$, giving your answer correct to 3 significant figures.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Calculate the length of $AC$, giving your answer correct to 3 significant figures.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(a)$ Area $= \\frac{1}{2}ab\\sin(C)$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Area $= \\frac{1}{2} \\times${ab} \\times${bc} \\times \\sin(${angleB})$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Area $= ${parseFloat(ansA.toFixed(5))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Area $= ${ansA.toPrecision(3)}cm^2$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b)$ Using the cosine rule:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$a^2 = b^2 + c^2 - 2bcCos(A)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$a^2 = ${ab}^2 + ${bc}^2 - 2 \\times${ab} \\times${bc} \\times \\cos(${angleB})$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$a^2 = ${parseFloat(ansB2.toFixed(5))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$a = ${parseFloat(ansB.toFixed(5))}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$a = ${ansB.toPrecision(3)}cm$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ansA, 0.1, "$(a)$");
    group.addInput(ansB, 0.1, "$(b)$");
    question.addInputGroup(group);

    if (angleB === 90) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Mac_TrigRules_Exam_Q2() {
  let count = 0;
  const img_scale = 1.5;
  for (let i = 1; i <= 1; i += 1) {
    function randomLength(lower, upper) {
      const int = MCRandom.randomInt(lower, upper);
      const dec = MCRandom.randomInt(1, 9) / 10;
      return int + dec;
    }
    function toRadians(angle) {
      return angle * (Math.PI / 180);
    }
    function toDegrees(angle) {
      return angle * (180 / Math.PI);
    }
    const angleA = MCRandom.randomInt(25, 55);
    const angleTheta = MCRandom.randomInt(30, 50);
    const ab = randomLength(10, 25);
    const bc = randomLength(Math.ceil(ab) + 1, Math.ceil(ab) + 6);
    const ansA2 = ab ** 2 + bc ** 2 - 2 * bc * ab * Math.cos(toRadians(angleA));
    const ansA = Math.sqrt(ansA2);
    const ansB = toDegrees(
      Math.asin((bc * Math.sin(toRadians(angleA))) / ansA)
    );

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image(
      "GCSE/NonRightangleTriangle/Sine/1.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", -2, 135 / img_scale);
    img.addOverlay("$B$", 147 / img_scale + 3, 143 / img_scale);
    img.addOverlay("$C$", 38 / img_scale + 3, 0);
    img.addOverlay("$\\theta$", 20 / img_scale, 107 / img_scale + 6);
    img.addOverlay(
      `$${angleA}^\\circ$`,
      100 / img_scale + 12,
      115 / img_scale + 10
    );
    img.addOverlay(`$${ab}cm$`, 50 / img_scale + 8, 140 / img_scale + 2);
    img.addOverlay(`$${bc}cm$`, 100 / img_scale + 5, 60 / img_scale);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Calculate the length of $AC$, giving your answer correct to 3 significant figures.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Calculate the size of angle $BAC$, giving your answer correct to 1 decimal place.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a) \\ AC^2 = ${ab}^2 + ${bc}^2 - 2  \\times ${ab}  \\times ${bc}  \\times \\cos(${angleA})$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$AC^2 = ${parseFloat(ansA2.toFixed(5))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$AC = ${parseFloat(ansA.toFixed(5))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$AC = ${ansA.toPrecision(3)}cm$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ \\frac{\\sin(\\theta)}{${bc}} = \\frac{\\sin(${angleA})}{${parseFloat(
          ansA.toFixed(5)
        )}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\sin(\\theta) = \\frac{${bc} \\sin(${angleA})}{${parseFloat(
          ansA.toFixed(5)
        )}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\theta = \\sin^{-1}(\\frac{${bc} \\sin(${angleA})}{${parseFloat(
          ansA.toFixed(5)
        )}})$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\theta = ${parseFloat(ansB.toFixed(5))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\theta = ${ansB.toPrecision(3)}^\\circ $`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ansA, 0.1, "$(a)$");
    group.addInput(ansB, 0.1, "$(b)$");
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Mac_TrigRules_Exam_Q3() {
  let count = 0;
  const img_scale = 1.5;
  for (let i = 1; i <= 1; i += 1) {
    function randomLength(lower, upper) {
      const int = MCRandom.randomInt(lower, upper);
      const dec = MCRandom.randomInt(1, 9) / 10;
      return int + dec;
    }
    function toRadians(angle) {
      return angle * (Math.PI / 180);
    }
    function toDegrees(angle) {
      return angle * (180 / Math.PI);
    }
    const angleA = MCRandom.randomInt(25, 55);
    const bc = randomLength(10, 20);
    const ab = randomLength(Math.ceil(bc) + 1, Math.floor(bc) + 6);
    const ac = randomLength(Math.ceil(bc) - 10, Math.floor(bc) - 4);

    const num = ac ** 2 + bc ** 2 - ab ** 2;
    const den = 2 * bc * ac;
    const theta = toDegrees(Math.acos(num / den));

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image(
      "GCSE/NonRightangleTriangle/Cosine/2a.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", -5, 5 / img_scale + 15);
    img.addOverlay("$B$", 135 / img_scale, 140 / img_scale + 7);
    img.addOverlay("$C$", 122 / img_scale, 0);
    img.addOverlay("$\\theta$", 105 / img_scale - 1, 10 / img_scale + 7);
    img.addOverlay(`$${ab}cm$`, 20 / img_scale + 8, 80 / img_scale);
    img.addOverlay(`$${bc}cm$`, 135 / img_scale + 3, 60 / img_scale);
    if (ac >= 10) {
      img.addOverlay(`$${ac}cm$`, 30 / img_scale + 5, -10 / img_scale + 10);
    } else {
      img.addOverlay(`$${ac}cm$`, 40 / img_scale + 5, -10 / img_scale + 10);
    }
    question.addImage("question", img);
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Calculate the size of angle $ACB$, giving your answer correct to $1$ decimal place.",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${ab}^2 = ${bc}^2 + ${ac}^2 - 2  \\times ${bc}  \\times ${ac}  \\times \\cos(\\theta)$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${ab}^2 - ${bc}^2 - ${ac}^2 = - 2  \\times ${bc}  \\times ${ac}  \\times \\cos(\\theta)$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$${ac}^2 + ${bc}^2 - ${ab}^2 =  2  \\times ${bc}  \\times ${ac}  \\times \\cos(\\theta)$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\frac{${ac}^2 + ${bc}^2 - ${ab}^2}{2  \\times ${bc}  \\times ${ac}} = \\cos(\\theta)$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\cos(\\theta) = \\frac{${num}}{${den}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\theta = \\cos^{-1}(\\frac{${num}}{${den}})$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\theta = ${parseFloat(theta.toFixed(5))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\theta = ${parseFloat(theta.toFixed(1))}^\\circ $`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(theta, 0.1, "$\\theta =$");
    question.addInputGroup(group);

    if (theta > 85 || Math.abs(num) > Math.abs(den)) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Mac_TrigRules_Exam_Q4() {
  let count = 0;
  const img_scale = 1.4;
  for (let i = 1; i <= 1; i += 1) {
    function randomLength(lower, upper) {
      const int = MCRandom.randomInt(lower, upper);
      const dec = MCRandom.randomInt(1, 9) / 10;
      return int + dec;
    }
    function toRadians(angle) {
      return angle * (Math.PI / 180);
    }
    function toDegrees(angle) {
      return angle * (180 / Math.PI);
    }

    const ab = randomLength(10, 20);
    const bc = randomLength(Math.floor(ab) + 1, Math.ceil(ab) + 4);
    const area = MCRandom.randomInt(60, 140);
    const theta = toDegrees(Math.asin((2 * area) / (ab * bc)));
    const ansA2 = ab ** 2 + bc ** 2 - 2 * bc * ab * Math.cos(toRadians(theta));
    const ansA = Math.sqrt(ansA2);
    const perimeter = ab + bc + parseFloat(ansA.toFixed(5));

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image(
      "GCSE/NonRightangleTriangle/Cosine/1b.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", -5 / img_scale, 133 / img_scale);
    img.addOverlay("$B$", 147 / img_scale, 143 / img_scale);
    img.addOverlay("$C$", 37 / img_scale, -20 / img_scale);
    img.addOverlay("$\\theta$", 120 / img_scale, 120 / img_scale + 5);
    img.addOverlay(`$${ab}cm$`, 40 / img_scale + 20, 140 / img_scale + 1);
    img.addOverlay(`$${bc}cm$`, 100 / img_scale + 7, 50 / img_scale + 10);
    question.addImage("question", img);
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The area of triangle $ABC$ is $${area}cm^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find the perimeter of triangle $ABC$, giving your answer correct to 1 decimal place.",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$Area = \\frac{1}{2}ab\\sin(\\theta)$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$2  \\times Area$ = $ab\\sin(\\theta)$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\frac{2  \\times Area}{ab} = \\sin(\\theta)$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\theta = \\sin^{-1}(\\frac{2  \\times Area}{ab})$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\theta = \\sin^{-1}(\\frac{2  \\times ${area}}{${ab}  \\times ${bc}})$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\theta = ${parseFloat(theta.toFixed(5))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$AC^2 = ${ab}^2 + ${bc}^2 - 2  \\times ${ab}  \\times ${bc}  \\times \\cos(${parseFloat(
          theta.toFixed(5)
        )})$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$AC^2 = ${parseFloat(ansA2.toFixed(5))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$AC = ${parseFloat(ansA.toFixed(5))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Perimeter = $AB + AC + BC$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Perimeter = $${ab} + ${parseFloat(ansA.toFixed(5))} + ${bc}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Perimeter = $${parseFloat(perimeter.toFixed(5))}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Perimeter = $${perimeter.toFixed(1)}cm$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(perimeter, 0.5, "$\\theta=$");
    question.addInputGroup(group);

    if (theta < 35 || theta > 58 || 2 * area > ab * bc) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Mac_TrigRules_Exam_Q5() {
  let count = 0;
  const img_scale = 1.4;
  for (let i = 1; i <= 1; i += 1) {
    function randomLength(lower, upper) {
      const int = MCRandom.randomInt(lower, upper);
      const dec = MCRandom.randomInt(1, 9) / 10;
      return int + dec;
    }
    function toRadians(angle) {
      return angle * (Math.PI / 180);
    }
    function toDegrees(angle) {
      return angle * (180 / Math.PI);
    }

    const db = randomLength(10, 20);
    const dbc = MCRandom.randomInt(60, 80);
    const dcb = MCRandom.randomInt(15, 35);
    const dac = MCRandom.randomInt(35, 55);
    const cd = (db * Math.sin(toRadians(dbc))) / Math.sin(toRadians(dcb));
    const bdc = 180 - (dbc + dcb);
    const adc = 180 - bdc;
    const ac =
      (parseFloat(cd.toFixed(5)) * Math.sin(toRadians(adc))) /
      Math.sin(toRadians(dac));

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image(
      "GCSE/NonRightangleTriangle/Sine/6.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", -2 / img_scale, 145 / img_scale);
    img.addOverlay("$D$", 50 / img_scale, 145 / img_scale);
    img.addOverlay("$B$", 145 / img_scale, 145 / img_scale);
    img.addOverlay("$C$", 108 / img_scale - 5, -5 / img_scale);
    img.addOverlay(`$${dbc}^\\circ$`, 110 / img_scale + 5, 120 / img_scale + 5);
    img.addOverlay(
      `$${dcb}^\\circ$`,
      123 / img_scale - 17,
      10 / img_scale + 17
    );
    img.addOverlay(`$${dac}^\\circ$`, 27 / img_scale, 120 / img_scale + 5);
    img.addOverlay(`$${db}cm$`, 80 / img_scale + 8, 145 / img_scale - 1);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$ABC$ is a triangle, point $D$ lies on the line $AB$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find the length of line $CD$ to 3 significant figures.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the size of the angle $ADC$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(c)$ Find the length of line $AD$ to 3 significant figures.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a) \\ \\frac{\\sin(${dcb})}{${db}} = \\frac{\\sin(${dbc})}{CD}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$CD = \\frac{${db}  \\times \\sin(${dbc})}{\\sin(${dcb})}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$CD = ${parseFloat(cd.toFixed(5))}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$CD = ${cd.toPrecision(3)}cm$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(b)$ Sum of angles in a triangle $= 180^\\circ$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Sum of $DBC$ and $BCD$ $= ${dbc + dcb}^\\circ$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ $BDC$ $= ${bdc}^\\circ$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Sum of angles on a straight line $= 180^\\circ$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ $ADC$ $= ${adc}^\\circ$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(c) \\ \\frac{\\sin(${dac})}{${parseFloat(
          cd.toFixed(5)
        )}} = \\frac{\\sin(${adc})}{AC}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$AC = \\frac{${parseFloat(
          cd.toFixed(5)
        )}  \\times \\sin(${adc})}{\\sin(${dac})}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$AC = ${parseFloat(cd.toFixed(5))}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$AC = ${cd.toPrecision(3)}cm$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(3);
    group.addInput(cd, 0.1, "$(a)$");
    group.addInput(adc, 0.1, "$(b)$");
    group.addInput(cd, 0.1, "$(c)$");
    question.addInputGroup(group);

    if (bdc >= 80) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Pac_CircleTheorems_Exam_Q1() {
  let count = 0;
  const img_scale = 1.9;

  for (let i = 1; i <= 1; i += 1) {
    function randomLength(lower, upper) {
      const int = MCRandom.randomInt(lower, upper);
      const dec = MCRandom.randomInt(1, 9) / 10;
      return int + dec;
    }
    function toRadians(angle) {
      return angle * (Math.PI / 180);
    }
    function toDegrees(angle) {
      return angle * (180 / Math.PI);
    }

    const angleA = MCRandom.randomInt(30, 60);
    const angleB = MCRandom.randomInt(35, 75);
    const angleCentre = 180 - angleA - angleB;
    const angleX = 180 - angleCentre;

    const question = new MCQuestion.ExamQuestion();
    const img = new MCQuestion.Image(
      "GCSE/CircleTheorems/same_segment1.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", 22 / img_scale, 168 / img_scale);
    img.addOverlay("$B$", 35 / img_scale + 2, 4);
    img.addOverlay("$C$", 180 / img_scale - 4, 20 / img_scale + 4);
    img.addOverlay("$D$", 200 / img_scale - 6, 130 / img_scale);
    img.addOverlay("$X$", 118 / img_scale, 90 / img_scale + 2);
    img.addOverlay("$\\Large{\\bullet}$", 119 / img_scale, 76 / img_scale + 1);
    img.addOverlay(
      `$${angleA}^\\circ$`,
      45 / img_scale + 3,
      120 / img_scale + 3
    );
    img.addOverlay(
      `$${angleB}^\\circ$`,
      50 / img_scale + 5,
      42 / img_scale + 4
    );
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$A, B, C$ and $D$ are points on the circumference of a circle.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(a)$ Find the size of angle $XDC$", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Give a reason for your answer to part $(a)$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$(c)$ Find the size of angle $AXD$", false, true, true)
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${angleA}^\\circ$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(b)$ Angles in the same segment are equal.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(c) \\ ${angleX}^\\circ$`, false, true, true)
    );
    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(2);
    group.addInput(angleA, 0, "$(a)$");
    group.addInput(angleX, 0, "$(c)$");
    question.addInputGroup(group);

    if (
      angleA === angleB ||
      Math.abs(angleA - angleB) >= 20 ||
      angleCentre >= 90
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Pac_CircleTheorems_Exam_Q2() {
  let count = 0;
  const img_scale = 1.95;

  for (let i = 1; i <= 1; i += 1) {
    function randomLength(lower, upper) {
      const int = MCRandom.randomInt(lower, upper);
      const dec = MCRandom.randomInt(1, 9) / 10;
      return int + dec;
    }
    function toRadians(angle) {
      return angle * (Math.PI / 180);
    }
    function toDegrees(angle) {
      return angle * (180 / Math.PI);
    }

    const angleNotD = MCRandom.randomInt(40, 60);
    const angleD = 90 - angleNotD;
    const angleAOD = 90 - angleD;
    const angleABC = 0.5 * angleAOD;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image(
      "GCSE/CircleTheorems/half_at_centre1.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", 75 / img_scale - 1, 170 / img_scale + 4);
    img.addOverlay("$B$", 120 / img_scale, 10 / img_scale + 4);
    img.addOverlay("$C$", 150 / img_scale - 1, 120 / img_scale + 3);
    img.addOverlay("$D$", 205 / img_scale - 1, 165 / img_scale + 4);
    img.addOverlay("$O$", 67 / img_scale - 0.5, 72 / img_scale + 4);
    img.addOverlay(
      "$\\large{\\bullet}$",
      76 / img_scale - 0.5,
      85 / img_scale + 2
    );
    img.addOverlay(
      `$${angleNotD}^\\circ$`,
      180 / img_scale,
      130 / img_scale + 3
    );
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The diagram shows a circle, centre $O$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$A, B$ and $C$ are points on the circumference of the circle.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Point $D$ lies at the bottom of a straight vertical.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$OCD$ is a straight line.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$AD$ is a tangent to the circle and is horizontal.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find the size of angle $ABC$ giving a reason in each stage of your working.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\angle ADC = ${angleD}^\\circ \\because$ Right angle occurs between $AD$ and the vertical`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\angle OAD = 90^\\circ \\because$ Radius and tangent meet at the circumference to form a right angle",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\angle AOD = 180 - 90 - ${angleD} =${angleAOD}^\\circ \\because$ Sum of angles in a triangle is $180^\\circ$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Alternative", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\angle AOD = ${angleAOD}^\\circ \\because$ Alternate angles`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\angle ABC = \\frac{1}{2} \\times \\angle AOC \\because$ Angle at the centre is twice the angle at the circumference",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\angle ABC = \\frac{1}{2} \\times \\angle AOD$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\angle ABC = \\frac{1}{2} \\times ${angleAOD}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\angle ABC = ${angleABC}^\\circ$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(angleABC, 0);
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Pac_CircleTheorems_Exam_Q3() {
  let count = 0;
  const img_scale = 1.95;

  for (let i = 1; i <= 1; i += 1) {
    function randomLength(lower, upper) {
      const int = MCRandom.randomInt(lower, upper);
      const dec = MCRandom.randomInt(1, 9) / 10;
      return int + dec;
    }
    function toRadians(angle) {
      return angle * (Math.PI / 180);
    }
    function toDegrees(angle) {
      return angle * (180 / Math.PI);
    }

    const angleA = MCRandom.randomInt(20, 40);
    const angleA0B = 180 - 2 * angleA;
    const angleBOC = 180 - angleA0B;
    const x = 180 - 90 - angleBOC;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image(
      "GCSE/CircleTheorems/tangent1.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", 5 / img_scale, 30 / img_scale + 2);
    img.addOverlay("$B$", 76 / img_scale + 1, 170 / img_scale + 4);
    img.addOverlay("$C$", 207 / img_scale - 2, 165 / img_scale + 3);
    img.addOverlay("$O$", 85 / img_scale, 65 / img_scale + 5);
    img.addOverlay("$x$", 170 / img_scale - 4, 150 / img_scale + 3);
    img.addOverlay(
      "$\\large{\\bullet}$",
      81 / img_scale - 0.5,
      82 / img_scale + 3
    );
    img.addOverlay(
      `$${angleA}^\\circ$`,
      40 / img_scale + 6,
      73 / img_scale + 5
    );
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The diagram shows a circle, centre $O$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$A$ and $B$ are points on the circumference of the circle.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$AOC$ is a straight line.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$BC$ is a tangent to the circle.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find the value of $x$ giving a reason in each stage of your working.",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Triangle $AOB$ is isosceles $\\because$ $OA$ and $OB$ are radii $\\therefore \\angle OBA = ${angleA}^\\circ$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore \\angle AOB = ${angleA0B}^\\circ \\because$ Sum of angles in a traingle is $180^\\circ$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\angle BOC = 180 - ${angleA0B} = ${angleBOC}^\\circ \\because$ Sum of angles on a straight line is $180^\\circ$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\angle OBC = 90^\\circ \\because$ Tangent and radius meet at circumference to form a right angle",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$x = ${x}^\\circ \\because$ Sum of angles in a triangle is $180^\\circ$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(x, 0, "$x=$");
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Pac_CircleTheorems_Exam_Q4() {
  let count = 0;
  const img_scale = 1.85;

  for (let i = 1; i <= 1; i += 1) {
    const angleC = MCRandom.randomInt(25, 40);
    const angleBOC = 180 - (90 + angleC);
    const x = 180 - angleBOC * 2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image(
      "GCSE/CircleTheorems/tangent2.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", 110 / img_scale - 3, 25 / img_scale + 1);
    img.addOverlay("$B$", 105 / img_scale - 3, 163 / img_scale - 3);
    img.addOverlay("$C$", 210 / img_scale - 10, 92 / img_scale);
    img.addOverlay("$D$", 20 / img_scale, 27 / img_scale);
    img.addOverlay("$O$", 53 / img_scale, 100 / img_scale + 1);
    img.addOverlay("$x$", 65 / img_scale - 3, 60 / img_scale + 1);
    img.addOverlay("$\\large{\\bullet}$", 63 / img_scale - 1.5, 90 / img_scale);
    img.addOverlay(`$${angleC}^\\circ$`, 150 / img_scale - 4, 103 / img_scale);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The diagram shows a circle, centre $O$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$A, B$ and $D$ are points on the circumference of the circle.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$DOB$ is a diameter of the circle.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$AC$ and $BC$ are tangents to the circle.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find the value of $x$ giving a reason in each stage of your working.",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\angle OBC = 90^\\circ \\because$ Radius and tangent meet at the circumference to form a right angle",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\angle BOC = 180 - (90 + ${angleC}) = ${angleBOC}^\\circ \\because$ Sum of angles in a triangle is $180^\\circ$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\angle BOC = \\angle AOC \\because \\triangle BOC$ and $\\triangle AOC$ are congruent",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$x = 180 - (${angleBOC} + ${angleBOC}) = ${x}^\\circ \\because$ Sum of angles on a straight line is $180^\\circ$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(x, 0, "$x=$");
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Pac_CircleTheorems_Exam_Q5() {
  let count = 0;
  const img_scale = 1.9;

  for (let i = 1; i <= 1; i += 1) {
    const angleC = MCRandom.randomInt(65, 85);
    const angleBOA = 180 - angleC;
    const x2 = 180 - angleBOA;
    const x = x2 / 2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image(
      "GCSE/CircleTheorems/tangent3.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", 48 / img_scale, 48 / img_scale + 2);
    img.addOverlay("$B$", 125 / img_scale - 4, 172 / img_scale + 2);
    img.addOverlay("$C$", -4, 172 / img_scale);
    img.addOverlay("$O$", 135 / img_scale - 5, 80 / img_scale);
    img.addOverlay("$x$", 115 / img_scale - 3, 125 / img_scale);
    img.addOverlay(
      "$\\large{\\bullet}$",
      125 / img_scale - 3.5,
      87 / img_scale + 1.56
    );
    img.addOverlay(
      `$${angleC}^\\circ$`,
      20 / img_scale + 6,
      145 / img_scale + 1
    );
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The diagram shows a circle, centre $O$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$A$ and $B$ are points on the circumference of the circle.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$AC$ and $BC$ are tangents to the circle.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find the value of $x$ giving a reason in each stage of your working.",
        false,
        true,
        true
      )
    );
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\angle CAO = \\angle CBO = 90^\\circ \\because$ Radius and tangent meet at the circumference to form a right angle",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\angle AOB = 360 - (90 + 90 + ${angleC}) = ${angleBOA}^\\circ \\because$ Sum of angles in a quadrilateral is $180^\\circ$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$\\triangle AOB$ is isosceles $\\because OA = OB \\because$ $OA$ and $OB$ are radii",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$2x = 180 - ${angleBOA} = ${x2}^\\circ \\because$ Sum of angles in a triangle is $180^\\circ$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x = ${x}^\\circ$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(x, 0, "$x=$");
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Pac_CircleTheorems_Exam_Q6() {
  let count = 0;
  const img_scale = 2;

  for (let i = 1; i <= 1; i += 1) {
    function randomLength(lower, upper) {
      const int = MCRandom.randomInt(lower, upper);
      const dec = MCRandom.randomInt(1, 9) / 10;
      return int + dec;
    }
    function toRadians(angle) {
      return angle * (Math.PI / 180);
    }
    function toDegrees(angle) {
      return angle * (180 / Math.PI);
    }

    const angleC = MCRandom.randomInt(100, 145);
    const angleA = 180 - angleC;
    const angleBOD = 2 * angleA;
    const y2 = 360 - angleC - angleBOD;
    const y = y2 / 2;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image(
      "GCSE/CircleTheorems/cyclic_quad1.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", 100 / img_scale, -5);
    img.addOverlay("$B$", 20 / img_scale, 160 / img_scale + 5);
    img.addOverlay("$C$", 97 / img_scale + 2, 198 / img_scale + 5);
    img.addOverlay("$D$", 180 / img_scale, 160 / img_scale + 5);
    img.addOverlay("$O$", 100 / img_scale, 80 / img_scale + 5);
    img.addOverlay("$x$", 100 / img_scale, 20 / img_scale + 5);
    img.addOverlay("$y$", 145 / img_scale, 150 / img_scale + 4);
    img.addOverlay("$\\large{\\bullet}$", 101 / img_scale, 92 / img_scale + 4);
    img.addOverlay(
      `$${angleC}^\\circ$`,
      90 / img_scale + 6,
      160 / img_scale + 5
    );
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The diagram shows a circle, centre $O$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$A, B, C$ and $D$ are points on the circumference of the circle.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find the value of $x$ giving a reason in each stage of your working.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the value of $y$ giving a reason in each stage of your working.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a) \\ x = 180 - ${angleC} = ${angleA}^\\circ$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Opposite angles in a cyclic quadrilateral have a sum of $180^\\circ$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ \\angle BOD = 2  \\times \\angle BAD = 2  \\times ${angleA} = ${angleBOD}^\\circ \\because$ Angle at the centre is twice the angle at the circumference`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$OBCD$ is a kite $\\therefore \\angle OBC = \\angle ODC$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$2y = 360 - ${angleC} - ${angleBOD} = ${y2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$y = ${y2}  \\div 2 = ${y}^\\circ$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(angleA, 0, "$(a) \\ x=$");
    group.addInput(y, 0, "$(b) \\ y=$");
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Geometry_Pac_CircleTheorems_Exam_Q7() {
  let count = 0;
  const img_scale = 1.9;

  for (let i = 1; i <= 1; i += 1) {
    const angleB = MCRandom.randomInt(25, 45);
    const x = 90 - angleB;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image(
      "GCSE/CircleTheorems/diameter1.jpg",
      "height",
      15
    );
    img.addOverlay("$A$", 200 / img_scale - 6, 25 / img_scale + 3);
    img.addOverlay("$B$", 170 / img_scale - 5, 115 / img_scale + 2);
    img.addOverlay("$C$", 115 / img_scale - 4, 200 / img_scale);
    img.addOverlay("$D$", 5 / img_scale + 2, 30 / img_scale + 4);
    img.addOverlay("$E$", 20 / img_scale, 140 / img_scale);
    img.addOverlay("$O$", 90 / img_scale, 60 / img_scale + 4);
    img.addOverlay("$x$", 130 / img_scale - 7, 135 / img_scale - 2);
    img.addOverlay("$y$", 30 / img_scale + 2, 60 / img_scale + 2);
    img.addOverlay("$\\large{\\bullet}$", 85 / img_scale, 73 / img_scale + 3);
    img.addOverlay(`$${angleB}^\\circ$`, 112 / img_scale, 107 / img_scale + 1);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The diagram shows a circle, centre $O$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$B, D$ and $E$ are points on the circumference of the circle.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$ABC$ is a tangent to the circle.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner("$BD$ is a diameter of the circle.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find the value of $x$ giving a reason in each stage of your working.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the value of $y$ giving a reason in each stage of your working.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(a) \\ \\angle ABD = 90^\\circ \\because$ Tangent and radius meet at the circumference to form a right angle",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\angle CBE = 180 - ${angleB} - 90 = ${x}^\\circ \\because$ Sum of angles on a straight line is $180^\\circ$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\therefore x = ${x}^\\circ$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(b) \\ \\angle BED = 90^\\circ \\because$ The angle at the circumference of a semi-circle is a right angle",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\angle EDB = 180 - ${angleB} - 90 = ${x}^\\circ \\because$ Sum of angles in a triangle is $180^\\circ$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$\\therefore y = ${x}^\\circ$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(x, 0, "$(a) \\ x=$");
    group.addInput(x, 0, "$(b) \\ y=$");
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Number_Fdap_ConvertingFractions_Exam_Q1() {
  function gcd(x2, y2) {
    let x = Math.abs(x2);
    let y = Math.abs(y2);
    while (y) {
      const t = y;
      y = x % y;
      x = t;
    }
    return x;
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // 0.abc * 0.d  b c and d reccuring
    const a = Math.round(Math.random() * 8 + 1);
    const b = Math.round(Math.random() * 8 + 1);
    const c = Math.round(Math.random() * 8 + 1);
    const d = Math.round(Math.random() * 8 + 1);
    const e = Math.round(Math.random() * 8 + 1);
    const top = 100 * a + 10 * b + (c - a);
    const Simplified = (100 * a + 10 * b + (c - a)) / 990;
    const bSimplified = (Simplified * d) / 9;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Show algebraically $0. ${a} \\dot{${b}} \\dot{${c}}={${Simplified}}$ as a fraction.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Express $0. ${a}\\dot{${b}}\\dot{${c}}  \\times 0.\\dot{${d}}$ as a fraction.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(a)$ Let $x = 0.${a}\\dot{${b}}\\dot{${c}}$ therefore $100x = ${a}${b}.${c}\\dot{${b}}\\dot{${c}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$100x = ${a}${b}.${c}\\dot{${b}}\\dot{${c}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x = 0.${a}\\dot{${b}}\\dot{${c}}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$99x = ${a}${b}.${c - a}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$x = {${a}${b}${c - a}\\over990}$`, false, true, true)
    );
    if (Simplified.denominator !== 990) {
      question.addParagraph(
        "solution",
        MCMaths.cleaner(`$x = {${Simplified}}$`, true, true, true)
      );
    }
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ {${Simplified}} \\times{ ${d}  \\over 9}={${bSimplified}}$`,
        true,
        true,
        true
      )
    );
    question.requiresSelfMarking();
    if (a === b || b === c || c <= a || d === 9 || gcd(990, top) === 1) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Number_Maa_Bounds_Exam_Q1() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const fruit = ["bag of apples", "bag of bananas", "punnet of strawberries"];
    const item = fruit[MCRandom.randomInt(0, 2)];
    let weight = 0;
    if (item === "bag of bananas") {
      weight = 1 + MCRandom.randomInt(1, 9) / 10;
    } else if (item === "bag of apples") {
      weight = MCRandom.randomInt(2, 3) + MCRandom.randomInt(1, 9) / 10;
    } else {
      weight = 0 + MCRandom.randomInt(2, 5) / 10;
    }

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The weight of a ${item} is $${weight}$ kilograms, correct to the nearest $0.1$ kilogram.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ What is the smallest possible weight of the ${item}?`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ What is the largest possible weight of the ${item}?`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${weight - 0.05}kg$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${weight + 0.05}kg$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(weight - 0.05, 0.0001, "$(a)$");
    group.addInput(weight + 0.05, 0.0001, "$(b)$");
    question.addInputGroup(group);

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Number_Maa_Bounds_Exam_Q2() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const height = MCRandom.randomInt(2, 8);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The height of a wall is $${height}$ meters, correct to the nearest meter.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ What is the least possible height of the wall?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ What is the greatest possible height of the wall?",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(a) \\ ${height - 0.5}m$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`$(b) \\ ${height + 0.5}m$`, false, true, true)
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(height - 0.5, 0.0001, "$(a)$");
    group.addInput(height + 0.5, 0.0001, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Number_Maa_Bounds_Exam_Q3() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const height = MCRandom.randomInt(70, 110);
    const width = MCRandom.randomInt(50, 90);

    const least_height = height - 0.5;
    const least_width = width - 0.5;
    const least_perimeter = 2 * least_height + 2 * least_width;

    const greatest_height = height + 0.5;
    const greatest_width = width + 0.5;
    const greatest_area = greatest_height * greatest_width;

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner("A window is in the shape of a rectangle.", false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The window is $${width}cm$ wide and $${height}cm$ tall, each to the nearest centimeter.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Calculate the least possible perimeter of the window.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Calculate the greatest possible area of the window.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Lower bound (LB) is given by the LB of the height and the LB of the width",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`LB Width $= ${least_width}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`LB Height $= ${least_height}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ LB perimeter $= \\left(2  \\times ${least_width}\\right) + \\left(2  \\times ${least_height}\\right) = ${least_perimeter}cm$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Upper bound (UB) is given by the UB of the height and the UB of the width",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`UB Width $= ${greatest_width}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`UB Height $= ${greatest_height}$`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ UB area $= ${greatest_width}  \\times ${greatest_height} = ${greatest_area}cm^2$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput(least_perimeter, 0.1, "$(a)$");
    group.addInput(greatest_area, 0.1, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      Math.abs(height - width) <= 15 ||
      height / 2 !== Math.round(height / 2) ||
      width / 2 !== Math.round(width / 2) ||
      height < width
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Number_Maa_Bounds_Exam_Q4() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const diameter = MCRandom.randomInt(4, 16);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `A circle has diameter of $${diameter}cm$, to the nearest $cm$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ What is the greatest possible radius of the circle?",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Calculate the greatest possible area of the circle giving your answer correct to $3$ significant figures.",
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Greatest diameter $= ${diameter + 0.5}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ Greatest radius $= ${(diameter + 0.5) / 2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Upper bound (UB) area is given by the UB radius",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `UB radius $= ${(diameter + 0.5) / 2}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ UB area $= \\pi  \\times ${(diameter + 0.5) / 2}^2 = ${(
          ((diameter + 0.5) / 2) *
          ((diameter + 0.5) / 2) *
          Math.PI
        ).toFixed(4)}cm^2$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ UB area $= ${(
          ((diameter + 0.5) / 2) *
          ((diameter + 0.5) / 2) *
          Math.PI
        ).toPrecision(3)}cm^2$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput((diameter + 0.5) / 2, 0.1, "$(a)$");
    group.addInput(
      ((diameter + 0.5) / 2) * ((diameter + 0.5) / 2) * Math.PI,
      1,
      "$(b)$"
    );
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Number_Maa_Bounds_Exam_Q5() {
  let count = 0;
  const img_scale = 1.85;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const shadedLength = MCRandom.randomInt(4, 8);
    const fullWidth = MCRandom.randomInt(shadedLength + 4, shadedLength + 6);
    const shadedWidth = MCRandom.randomInt(shadedLength + 1, shadedLength + 2);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image("GCSE/bounds_square1.jpg", "height", 15);
    img.addOverlay(`$${shadedLength}m$`, 85 / img_scale, 130 / img_scale);
    img.addOverlay(`$${fullWidth}m$`, 85 / img_scale + 4, 195 / img_scale - 2);
    img.addOverlay(`$${shadedWidth}m$`, 120 / img_scale, 100 / img_scale);
    img.addOverlay("$A$", -5 / img_scale, -10 / img_scale);
    img.addOverlay("$B$", 200 / img_scale - 5, -10 / img_scale);
    img.addOverlay("$C$", 200 / img_scale - 5, 185 / img_scale);
    img.addOverlay("$D$", -5 / img_scale, 185 / img_scale);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner("$ABCD$ is a square.", false, true, true)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The measures shown in the diagram are given to the nearest meter.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find the least possible area of the shaded region.",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Lower bound of width of square $= ${fullWidth - 0.5}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Least area of the square $= ${fullWidth - 0.5}^2 = ${
          (fullWidth - 0.5) ** 2
        }$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Greatest length of the rectangle $= ${shadedLength + 0.5}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Greatest width of the rectangle $= ${shadedWidth + 0.5}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Greatest area of the rectangle $= ${shadedWidth + 0.5}  \\times ${
          shadedLength + 0.5
        } = ${(shadedWidth + 0.5) * (shadedLength + 0.5)}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Least area of shaded region $=$ least area of square $-$ greatest area of rectangle",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Least area of shaded region $= ${(fullWidth - 0.5) ** 2} - ${
          (shadedWidth + 0.5) * (shadedLength + 0.5)
        } = ${
          (fullWidth - 0.5) ** 2 - (shadedWidth + 0.5) * (shadedLength + 0.5)
        }m^2$`,
        false,
        true,
        true
      )
    );

    const ans =
      (fullWidth - 0.5) ** 2 - (shadedWidth + 0.5) * (shadedLength + 0.5);

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.1);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Number_Maa_Bounds_Exam_Q6() {
  let count = 0;
  const img_scale = 1.95;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const hyp = MCRandom.randomInt(4, 9);
    const adj = MCRandom.randomInt(1, hyp - 2);

    function toRadians(angle) {
      return angle * (Math.PI / 180);
    }
    function toDegrees(angle) {
      return angle * (180 / Math.PI);
    }

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    const img = new MCQuestion.Image("GCSE/bounds_triangle1.jpg", "height", 15);
    img.addOverlay(`$${adj}m$`, 80 / img_scale, 200 / img_scale + 2);
    img.addOverlay(`$${hyp}m$`, 100 / img_scale, 75 / img_scale);
    img.addOverlay("$A$", -5 / img_scale, -8 / img_scale);
    img.addOverlay("$B$", -5 / img_scale, 200 / img_scale);
    img.addOverlay("$C$", 200 / img_scale, 200 / img_scale);
    img.addOverlay("$\\theta$", 140 / img_scale, 165 / img_scale + 3);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The measures shown in the diagram are given to the nearest $m$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Find the least possible value of $\\theta$. Give your answer correct to $3$ significant figures.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ Find the greatest possible value of $\\theta$. Give your answer correct to $3$ significant figures.",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(a)$ Least possible $\\theta$ is given by the greatest adjacent length and least hypotenuse length",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\cos(\\theta) = \\frac{${adj + 0.5}}{${hyp - 0.5}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\theta = \\cos^{-1}\\left({${adj + 0.5}}\\over{${
          hyp - 0.5
        }}\\right)$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\theta = ${toDegrees(Math.acos((adj + 0.5) / (hyp - 0.5))).toFixed(
          5
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\theta = ${toDegrees(
          Math.acos((adj + 0.5) / (hyp - 0.5))
        ).toPrecision(3)}^\\circ$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(b)$ Greatest possible $\\theta$ is given by the least adjacent length and greatest hypotenuse length",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\cos(\\theta) = \\frac{${adj - 0.5}}{${hyp + 0.5}}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\theta = \\cos^{-1}\\left({${adj - 0.5}}\\over{${
          hyp + 0.5
        }}\\right)$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\theta = ${toDegrees(Math.acos((adj - 0.5) / (hyp + 0.5))).toFixed(
          5
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\theta = ${toDegrees(
          Math.acos((adj - 0.5) / (hyp + 0.5))
        ).toPrecision(3)}^\\circ$`,
        false,
        true,
        true
      )
    );

    const ansA = toDegrees(Math.acos((adj + 0.5) / (hyp - 0.5)));
    const ansB = toDegrees(Math.acos((adj - 0.5) / (hyp + 0.5)));

    const group = new MCQuestion.InputGroup(2);
    group.addInput(ansA, 0.5, "$\\theta=$");
    group.addInput(ansB, 0.5, "$\\theta=$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Number_Maa_Bounds_Exam_Q7() {
  let count = 0;
  const img_scale = 3;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const name = MCMisc.getName();
    const distance = MCRandom.randomInt(100, 200);
    const time = MCRandom.randomInt(16, 22);

    function toRadians(angle) {
      return angle * (Math.PI / 180);
    }
    function toDegrees(angle) {
      return angle * (180 / Math.PI);
    }

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph("question", MCMaths.cleaner("", false));
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} runs for $${time}s$, to the nearest second, and travels  a distance of $${distance}$ meters, to the nearest meter.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(a)$ Find ${name.name}'s least possible speed.Give your answer correct to $3$ significant figures.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Find ${name.name}'s greatest possible speed.Give your answer correct to $3$ significant figures.`,
        false,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Speed $=$ Distance $ \\div$ Time", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Least possible speed $=$ least distance $ \\div$ greatest time",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Least possible speed $=$ $${distance - 0.5}  \\div ${time + 0.5}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Least possible speed $=$ $${((distance - 0.5) / (time + 0.5)).toFixed(
          5
        )}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Least possible speed $=$ $${(
          (distance - 0.5) /
          (time + 0.5)
        ).toPrecision(3)}ms^{-1}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(b)$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "Greatest possible speed $=$ greatest distance $ \\div$ least time",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Greatest possible speed $=$ $${distance + 0.5}  \\div ${time - 0.5}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Greatest possible speed $=$ $${(
          (distance + 0.5) /
          (time - 0.5)
        ).toFixed(5)}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Greatest possible speed $=$ $${(
          (distance + 0.5) /
          (time - 0.5)
        ).toPrecision(3)}ms^{-1}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(2);
    group.addInput((distance - 0.5) / (time + 0.5), 0.1, "$(a)$");
    group.addInput((distance + 0.5) / (time - 0.5), 0.1, "$(b)$");
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (
      name.name.slice(-1) === "s" ||
      ((distance + 0.5) / (time - 0.5)).toPrecision(3) >= 10.5
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Number_Maa_Bounds_Exam_Q8() {
  let count = 0;
  const img_scale = 2;
  for (let i = 1; i <= 1; i += 1) {
    const height = MCRandom.randomInt(120, 199);
    const radius = MCRandom.randomInt(
      parseInt(height / 2 - 35, 10),
      parseInt(height / 2 - 25, 10)
    );

    const question = new MCQuestion.ExamQuestion();
    const img = new MCQuestion.Image("GCSE/bounds_cylinder1.jpg", "height", 15);
    img.addOverlay(`$${height}cm$`, 110 / img_scale + 10, 90 / img_scale + 5);
    img.addOverlay(`$${radius}cm$`, 115 / img_scale + 14, 160 / img_scale + 6);
    question.addImage("question", img);
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "A water tank is in the shape of a cylinder.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "The measures shown in the diagram of the tank are given to the nearest $cm$.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Find the least possible volume of the tank.Give your answer correct to the nearest $cm^3$.",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Lower bound of radius $= ${radius - 0.5} $`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Lower bound of height $= ${height - 0.5} $`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Volume of cylinder $= \\pi r^2h$", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Lower bound volume $= \\pi  \\times ${radius - 0.5}^2  \\times ${
          height - 0.5
        }$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Lower bound volume $= ${(
          Math.PI *
          (radius - 0.5) *
          (height - 0.5)
        ).toFixed(5)}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Lower bound volume $= ${(
          Math.PI *
          (radius - 0.5) *
          (height - 0.5)
        ).toFixed(0)}{cm}^3$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(Math.PI * (radius - 0.5) * (height - 0.5), 0.9);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (false) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Number_Maa_Bounds_Exam_Q9() {
  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    // Question Programming - This is the section that does all of the Mathematics for the question. Everything in this section will be done on Javascript.
    // Finding the equation of a line between 2 points ay+bx=c
    const litres = MCRandom.randomInt(800, 4000);
    const tinInt = MCRandom.randomInt(250, 400);
    const tinDec = MCRandom.randomInt(1, 9);
    const tinVol = tinInt + tinDec / 10;
    const soupUpper = litres + 2.5;
    const soupLower = litres - 2.5;
    const soupUpperML = (litres + 2.5) * 1000;
    const soupLowerML = (litres - 2.5) * 1000;
    const tinUpper = tinVol + 0.05;
    const tinLower = tinVol - 0.05;
    const tinUpperL = (tinVol + 0.05) / 1000;
    const tinLowerL = (tinVol - 0.05) / 1000;
    const soupUpperDivTinLower = (soupUpper / tinLowerL).toFixed(4);
    const ans = Math.floor(soupUpperDivTinLower);

    // The outputs - This is the HTML (containing MathJax) that will be processed then placed on screen.
    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "A factory is responsible for filling tin cans with soup.",
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The volume of soup to be canned is $${litres}$ litres to the nearest $5$ litres.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `The tins have a volume of $${tinVol}$ ml, correct to one decimal place.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "Calculate the maximum number of full tins that could be produced.",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Upper and lower bounds of volume of soup (litres) = $${litres}\\pm 2.5$ = $${soupUpper}$ and $${soupLower}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Upper and lower bounds of volume of tin (ml) = $${tinVol}\\pm 0.05$ = $${tinUpper}$ and $${tinLower}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Upper and lower bounds of volume of tin (litres) = $${tinUpperL}$ and $${tinLowerL}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `Max number of tins = volume of soup upper bound $ \\div$ volume of tin lower bound = $${soupUpper}  \\div ${tinLowerL} = ${soupUpperDivTinLower}$`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$\\therefore$ Max number of tins = $${ans}$`,
        false,
        true,
        true
      )
    );

    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0);
    question.addInputGroup(group);

    // This if statement allows you to add conditions to the question, if the condition is true the questions scrapped. Be careful of infinite loops.
    // In this case if the product of the two values is greater than 30 or if the product of the values is negative the question is scrapped.

    if (litres / 5 !== Math.round(litres / 5)) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Number_Sac_FactorsMultiplesAndPrimes_Exam_Q1() {
  function gcd(x2, y2) {
    let x = Math.abs(x2);
    let y = Math.abs(y2);
    while (y) {
      const t = y;
      y = x % y;
      x = t;
    }
    return x;
  }
  function lcm(x, y) {
    if (typeof x !== "number" || typeof y !== "number") return false;
    return !x || !y ? 0 : Math.abs((x * y) / gcd(x, y));
  }

  let count = 0;
  for (let i = 1; i <= 1; i += 1) {
    const name1 = MCMisc.getName().name;

    const name2 = MCMisc.getName().name;

    const firstbustime = Math.round(Math.random() * 3 + 7);

    const bus1interval = Math.round(Math.random() * 15 + 5);

    const bus2interval = Math.round(Math.random() * 15 + 5);

    let Lcm = "";
    if (bus1interval !== bus2interval) {
      Lcm = lcm(bus1interval, bus2interval);
    }

    let bus1times = bus1interval;
    let bus2times = bus2interval;
    let string1 = `$${bus1times}$`;
    let string2 = `$${bus2times}$`;

    if (
      bus1times !== Lcm ||
      bus2times !== Lcm ||
      bus2times * 2 !== Lcm ||
      bus1times * 2 !== Lcm
    ) {
      for (let j = 1; j <= 1; j += 1) {
        bus1times += bus1interval;
        string1 += `, $${bus1times}$`;
        if (bus1times !== Lcm) {
          j -= 1;
        }
        if (bus1times >= Lcm) {
          j = 2;
        }
      }

      for (let j = 1; j <= 1; j += 1) {
        bus2times += bus2interval;
        string2 += `, $${bus2times}$`;
        if (bus2times !== Lcm) {
          j -= 1;
        }
        if (bus2times >= Lcm) {
          j = 2;
        }
      }
    }

    let nextbus;
    if (Lcm > 60) {
      nextbus = `$${firstbustime + 1}:${Lcm - 60}am$`;
    } else if (Lcm === 60) {
      nextbus = `$${firstbustime + 1}:00am$`;
    } else {
      nextbus = `$${firstbustime}:${Lcm}am$`;
    }

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1} and ${name2} are both getting a bus at $${firstbustime}:00am$.`,
        false
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name1}'s bus arrives every $${bus1interval}$ minutes.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name2}'s bus arrives every $${bus2interval}$ minutes.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "What is the next time they could have both caught the bus?",
        false,
        true,
        true
      )
    );
    question.addParagraph("question", MCMaths.cleaner("", false, true, true));
    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("Minutes each bus arrives:", false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${name1}'s bus - ${string1}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`${name2}'s bus - ${string2}`, false, true, true)
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(`Next time for both buses ${nextbus}.`, false, true, true)
    );
    question.requiresSelfMarking();
    if (
      bus1interval === Lcm ||
      bus1interval === Lcm ||
      bus1interval * 2 === Lcm ||
      bus1interval * 2 === Lcm ||
      Lcm > 119 ||
      bus1interval === bus2interval ||
      (Lcm > 60 && Lcm < 70)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_ProbabilityAndStatistics_Probability_ConditionalProbability_Exam_Q1() {
  let count = 0;
  const img_scale = 2.6;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const red = MCRandom.randomInt(2, 10);
    const blue = MCRandom.randomInt(2, 10);
    const tot1 = red + blue;
    const tot2 = tot1 - 1;
    const r1 = red / tot1;
    const b1 = blue / tot1;
    const bb = (blue - 1) / tot2;
    const br = red / tot2;
    const rb = blue / tot2;
    const rr = (red - 1) / tot2;
    const ans = bb * b1;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has a bag with $${red}$ balls and $${blue}$ balls. ${name.HeShe} takes one ball out of the bag, then takes another one without replacing the first ball.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Draw a probability tree for these events.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ What is the probability ${name.name} picks two blue balls?`,
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    const img = new MCQuestion.Image(
      "GCSE/ProbabilityTree/ProbabilityTree.jpg",
      "height",
      15
    );
    img.addOverlay(
      MCMaths.cleaner("$blue$"),
      100 / img_scale + 4,
      70 / img_scale
    );
    img.addOverlay(
      MCMaths.cleaner("$red$"),
      100 / img_scale + 4,
      240 / img_scale - 7
    );
    img.addOverlay(
      MCMaths.cleaner("$blue$"),
      220 / img_scale + 8,
      35 / img_scale
    );
    img.addOverlay(
      MCMaths.cleaner("$red$"),
      220 / img_scale + 8,
      110 / img_scale - 3
    );
    img.addOverlay(
      MCMaths.cleaner("$blue$"),
      220 / img_scale + 8,
      205 / img_scale - 8
    );
    img.addOverlay(
      MCMaths.cleaner("$red$"),
      220 / img_scale + 8,
      280 / img_scale - 8
    );
    img.addOverlay(
      MCMaths.cleaner(`$${b1}$`),
      20 / img_scale,
      90 / img_scale + 3
    );
    img.addOverlay(
      MCMaths.cleaner(`$${r1}$`),
      20 / img_scale,
      220 / img_scale - 10
    );
    img.addOverlay(MCMaths.cleaner(`$${bb}$`), 180 / img_scale, 25 / img_scale);
    img.addOverlay(
      MCMaths.cleaner(`$${br}$`),
      180 / img_scale,
      120 / img_scale - 2
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rb}$`),
      180 / img_scale,
      190 / img_scale - 4
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rr}$`),
      180 / img_scale,
      290 / img_scale - 10
    );
    question.addImage("solution", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ {${b1}} \\times{${bb}}=${ans}$`,
        true,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.05, "$(b)$");
    question.addInputGroup(group);

    if (ans.numerator > 998) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_ProbabilityAndStatistics_Probability_ConditionalProbability_Exam_Q2() {
  let count = 0;
  const img_scale = 2.45;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const red = MCRandom.randomInt(2, 10);
    const blue = MCRandom.randomInt(2, 10);
    const tot1 = red + blue;
    const tot2 = tot1 - 1;
    const r1 = red / tot1;
    const b1 = blue / tot1;
    const bb = (blue - 1) / tot2;
    const br = red / tot2;
    const rb = blue / tot2;
    const rr = (red - 1) / tot2;
    const ans = bb * b1;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has a set of ${tot1} cards, $${red}$ with a value of 1 and $${blue}$ with a value of 2. `,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} picks one card at random, then picks another without replacing the first card.`,
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Draw a probability tree for these events.",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(b)$ What is the probability the values of the cards add to $3$?",
        false,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    const img = new MCQuestion.Image(
      "GCSE/ProbabilityTree/ProbabilityTree.jpg",
      "height",
      15
    );
    img.addOverlay(MCMaths.cleaner("$2$"), 100 / img_scale, 70 / img_scale);
    img.addOverlay(
      MCMaths.cleaner("$1$"),
      100 / img_scale,
      240 / img_scale - 11
    );
    img.addOverlay(MCMaths.cleaner("$2$"), 220 / img_scale, 35 / img_scale);
    img.addOverlay(
      MCMaths.cleaner("$1$"),
      220 / img_scale,
      110 / img_scale - 4
    );
    img.addOverlay(
      MCMaths.cleaner("$2$"),
      220 / img_scale,
      205 / img_scale - 13
    );
    img.addOverlay(
      MCMaths.cleaner("$1$"),
      220 / img_scale,
      280 / img_scale - 15
    );
    img.addOverlay(MCMaths.cleaner(`$${b1}$`), 20 / img_scale, 90 / img_scale);
    img.addOverlay(
      MCMaths.cleaner(`$${r1}$`),
      20 / img_scale,
      220 / img_scale - 15
    );
    img.addOverlay(
      MCMaths.cleaner(`$${bb}$`),
      180 / img_scale - 4,
      25 / img_scale
    );
    img.addOverlay(
      MCMaths.cleaner(`$${br}$`),
      180 / img_scale - 4,
      120 / img_scale - 3
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rb}$`),
      180 / img_scale - 4,
      190 / img_scale - 10
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rr}$`),
      180 / img_scale - 4,
      290 / img_scale - 17
    );

    question.addImage("solution", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b) \\ ({${b1}} \\times{${br}})+({${r1}} \\times{${rb}})=${ans}$`,
        true,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.05, "$(b)$");
    question.addInputGroup(group);

    if (ans.numerator > 998) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_ProbabilityAndStatistics_Probability_ConditionalProbability_Exam_Q3() {
  let count = 0;
  const img_scale = 2.3;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const red = MCRandom.randomInt(2, 10);
    const blue = MCRandom.randomInt(2, 10);
    const tot1 = red + blue;
    const tot2 = tot1 - 1;

    const w = MCRandom.randomInt(1, 119) / 120;
    const ww = MCRandom.randomInt(1, 119) / 120;
    const lw = w / 2;

    const l = 1 - w;
    const wl = 1 - ww;
    const ll = 1 - lw;

    const r1 = l;
    const b1 = w;
    const bb = ww;
    const br = wl;
    const rb = lw;
    const rr = ll;
    const ans = bb * b1;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.name} is a rower.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has noticed that if ${name.hisher} boats wins the first race of the day, they have a $${ww}$ chance of winning thier second race.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} also notices if ${name.hisher} boat loses their first race, the chance of them winning their second race is half the chance of winning the first.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Given the chance of ${name.name}'s boat winning their first race is $${w}$:`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Draw a probability tree for these events.",
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Find the probability ${name.name}'s boat wins both of thier races.`,
        true,
        true,
        true
      )
    );

    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    const img = new MCQuestion.Image(
      "GCSE/ProbabilityTree/ProbabilityTree.jpg",
      "height",
      15
    );
    img.addOverlay(
      MCMaths.cleaner("$win$"),
      100 / img_scale,
      70 / img_scale - 3
    );
    img.addOverlay(
      MCMaths.cleaner("$lose$"),
      100 / img_scale,
      240 / img_scale - 20
    );
    img.addOverlay(MCMaths.cleaner("$Race \\ 1$"), 90 / img_scale, 0);
    img.addOverlay(
      MCMaths.cleaner("$win$"),
      220 / img_scale,
      35 / img_scale - 1
    );
    img.addOverlay(
      MCMaths.cleaner("$lose$"),
      220 / img_scale,
      110 / img_scale - 7
    );
    img.addOverlay(
      MCMaths.cleaner("$win$"),
      220 / img_scale,
      205 / img_scale - 20
    );
    img.addOverlay(
      MCMaths.cleaner("$lose$"),
      220 / img_scale,
      280 / img_scale - 24
    );
    img.addOverlay(MCMaths.cleaner("$Race \\ 2$"), 210 / img_scale, 0);
    img.addOverlay(MCMaths.cleaner(`$${b1}$`), 20 / img_scale, 90 / img_scale);
    img.addOverlay(MCMaths.cleaner(`$${r1}$`), 20 / img_scale, 220 / img_scale);
    img.addOverlay(
      MCMaths.cleaner(`$${bb}$`),
      180 / img_scale - 10,
      25 / img_scale + 2
    );
    img.addOverlay(
      MCMaths.cleaner(`$${br}$`),
      180 / img_scale - 10,
      120 / img_scale - 9
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rb}$`),
      180 / img_scale - 10,
      190 / img_scale - 14
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rr}$`),
      180 / img_scale - 10,
      290 / img_scale - 26
    );
    question.addImage("solution", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$(b)$ \${${b1}} \\times{${bb}}=${ans}$`,
        true,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.005, "$(b)$");
    question.addInputGroup(group);

    if (
      ans.numerator > 998 ||
      w >= 0.6 ||
      w <= 0.3 ||
      ww >= 0.95 ||
      ww <= w + 0.1 ||
      wl >= w ||
      wl <= 0.1 ||
      l.denominator >= 20 ||
      wl.denominator >= 20 ||
      ll.denominator >= 20 ||
      lw.denominator >= 20 ||
      w * 2 === MCRandom.randomInt(1, 2) ||
      ww.denominator >= 20
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_ProbabilityAndStatistics_Probability_ConditionalProbability_Exam_Q4() {
  let count = 0;
  const img_scale = 2.3;
  const img_scaley = 2.9;
  for (let i = 1; i <= 1; i += 1) {
    const name = MCMisc.getName();
    const red = MCRandom.randomInt(2, 10);
    const blue = MCRandom.randomInt(2, 10);
    const tot1 = red + blue;
    const tot2 = tot1 - 1;

    const w = MCRandom.randomInt(1, 56) / 60;
    const ww = MCRandom.randomInt(1, 56) / 60;
    const lw = w / 2;

    const l = 1 - w;
    const wl = 1 - ww;
    const ll = 1 - lw;

    const winwin = w * ww;

    const r1 = l;
    const b1 = w;
    const bb = ww;
    const br = wl;
    const rb = lw;
    const rr = ll;
    const ans = 1 - r1 * rr;

    const question = new MCQuestion.ExamQuestion();
    question.addParagraph(
      "question",
      MCMaths.cleaner(`${name.name} plays rugby.`, false)
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.name} has noticed that if ${name.hisher} team wins the first game of the season, they have a $${ww}$ chance of winning thier next game.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `${name.HeShe} also notices if ${name.hisher} teams loses their first game, the chance of them winning their second game is half the chance of winning the first.`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `Given the chance of ${name.name}'s team winning thier first two games is $${winwin}$:`,
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        "$(a)$ Draw a probability tree for these events.",
        true,
        true,
        true
      )
    );
    question.addParagraph(
      "question",
      MCMaths.cleaner(
        `$(b)$ Find the probability ${name.name}'s team wins at least one of thier games.`,
        true,
        true,
        true
      )
    );

    question.addParagraph("solution", MCMaths.cleaner("", false, true, true));
    question.addParagraph(
      "solution",
      MCMaths.cleaner("$(a)$", false, true, true)
    );
    const img = new MCQuestion.Image(
      "GCSE/ProbabilityTree/ProbabilityTree.jpg",
      "height",
      15
    );
    img.addOverlay(
      MCMaths.cleaner("$win$"),
      100 / img_scale,
      70 / img_scaley + 3
    );
    img.addOverlay(
      MCMaths.cleaner("$lose$"),
      100 / img_scale,
      240 / img_scaley + 2
    );
    img.addOverlay(MCMaths.cleaner("$Game\\ 1$"), 90 / img_scale, -10);
    img.addOverlay(MCMaths.cleaner("$win$"), 220 / img_scale, 35 / img_scaley);
    img.addOverlay(
      MCMaths.cleaner("$lose$"),
      220 / img_scale,
      110 / img_scaley + 3
    );
    img.addOverlay(MCMaths.cleaner("$win$"), 220 / img_scale, 205 / img_scaley);
    img.addOverlay(
      MCMaths.cleaner("$lose$"),
      220 / img_scale,
      280 / img_scaley + 3
    );
    img.addOverlay(MCMaths.cleaner("$Game\\ 2$"), 210 / img_scale, -10);
    img.addOverlay(
      MCMaths.cleaner(`$${b1}$`),
      20 / img_scale,
      90 / img_scaley + 4
    );
    img.addOverlay(
      MCMaths.cleaner(`$${r1}$`),
      20 / img_scale,
      220 / img_scaley
    );
    img.addOverlay(
      MCMaths.cleaner(`$${bb}$`),
      180 / img_scale - 10,
      25 / img_scaley + 3
    );
    img.addOverlay(
      MCMaths.cleaner(`$${br}$`),
      180 / img_scale - 10,
      120 / img_scaley + 1
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rb}$`),
      180 / img_scale - 10,
      190 / img_scaley + 3
    );
    img.addOverlay(
      MCMaths.cleaner(`$${rr}$`),
      180 / img_scale - 10,
      290 / img_scaley
    );
    question.addImage("solution", img);
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        "$(b) \\ P($winning at least one game$)=1-P($losing both games$)$",
        false,
        true,
        true
      )
    );
    question.addParagraph(
      "solution",
      MCMaths.cleaner(
        `$1-\\left({${r1}} \\times{${rr}}\\right)=${ans}$`,
        true,
        true,
        true
      )
    );

    question.requiresSelfMarking();
    const group = new MCQuestion.InputGroup(1);
    group.addInput(ans, 0.005, "$(b)$");
    question.addInputGroup(group);

    // if(1===2)
    if (
      ans.numerator > 998 ||
      w >= 0.6 ||
      w <= 0.3 ||
      ww >= 0.95 ||
      ww <= w + 0.1 ||
      wl >= w ||
      wl <= 0.1 ||
      w * 2 === MCRandom.randomInt(1, 2)
    ) {
      i -= 1;
    } else {
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
} /* 
 const group = new MCQuestion.InputGroup(1);
 group.addInput(ans, 0, "$x=$");
 question.addInputGroup(group); 
 */


export function GCSE_Higher_Rparoc_Rparoc_Gradients_Exam_Q1() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x2 = MCRandom.randomInt(1,2) * MCRandom.randomInt(-1, 1, 2);
    const x = MCRandom.randomInt(-5, 5);
    const c = MCRandom.randomInt(-5, 5);

    const poly = new MCMaths.Polynomial([x2, x , c]);

    const ax = parseFloat((MCRandom.randomInt(-50, 50)/10).toFixed(1));
    const bx = parseFloat((MCRandom.randomInt(-50, 50)/10).toFixed(1));

    const ay = parseFloat((poly.evaluate(ax)).toFixed(1))
    const by = parseFloat((poly.evaluate(bx)).toFixed(1))

    const a_ans = ((ay-by)/(ax-bx))


    const m = MCRandom.randomInt(5, 50) * MCRandom.randomInt(-1, 1, 2) / 10


    const px = parseFloat(((m-x)/(2*x2)).toFixed(1)) // (2 *x2)x + x = m
    const py = parseFloat((poly.evaluate(px)).toFixed(1))

    const lx1 = MCRandom.randomInt(-10, 10);
    const lx2 = MCRandom.randomInt(-10, 10);

    const lc =  parseFloat((-m*px + py).toFixed(1)) // y-y_1 = m(x-x_1) ==> y = mx + (-mx_1 + y_1)

    const ly1 = parseFloat((lx1 * m + lc).toFixed(1))
    const ly2 = parseFloat((lx2 * m + lc).toFixed(1))

    /* END VARIABLES AND CONSTANTS */

    if ( Math.abs(ax-bx) > 2 && Math.abs(a_ans) <= 5 && Math.abs(lx1-lx2) > 2 ) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion(20, -20);

      const g = poly.graph();
      g.plot(`${m}*x+${lc}`, -20, 20);

      question.addGraph('question', g);

      question.addParagraph('question', `The points $A(${ax}, ${ay})$ and $B(${bx}, ${by})$ are located on the curve $C$ above.`);
      question.addParagraph('question', `a) Calculate the average gradient between the points $A$ and $B$.`);

      question.addParagraph('question', `The line $L$ also shown on the graph is tangent to $C$ at point $P( ${px}, ${py})$. `);
      question.addParagraph('question', `The points with coordinates $(${lx1}, ${ly1})$ and $(${lx2}, ${ly2})$ lie on the line $L$.`);

      question.addParagraph('question', `b) Find the rate of change of $y$ with respect to $x$ on $C$ when $x= ${px}$`);

      // solution
      question.addHeading('solution', `a) The average gradient is just the gradient of the line connecting the two points.`);

      question.addParagraph('solution', `$m=\\displaystyle \\frac{\\Delta y}{\\Delta x}$`);
      question.addParagraph('solution', MCMaths.cleaner(`$=\\displaystyle \\frac{${ay}-${by}}{${ax}-${bx}}$`, 0));
      if (parseFloat(a_ans.toFixed(2)) !== a_ans)
      {
        question.addParagraph('solution', `$\\approx ${(parseFloat(a_ans.toFixed(2)))}$`);
      }
      else
      {
        question.addParagraph('solution', `$= ${a_ans}$`);

      }
      question.addParagraph('solution', `$\\therefore$ The average gradient between points $A$ and $B$ is $${parseFloat(a_ans.toFixed(2))}$`);

      // b
      question.addHeading(`solution`, `b) The gradient of $L$ is the same as the rate of change of $y$ with respect to $x$ at $x= ${px}$, as it's tangent at this point.`);
      question.addParagraph('solution', `$m=\\displaystyle \\frac{\\Delta y}{\\Delta x}$`);
      question.addParagraph('solution', MCMaths.cleaner(`$=\\displaystyle \\frac{${ly1}-${ly2}}{${lx1}-${lx2}}$`, 0));
      question.addParagraph('solution', `$=${m}$`);
      question.addParagraph('solution', `$\\therefore$ The rate of change of $y$ with respect to $x$ on $C$ at $x=${px}$ is $${m}$`);
      // answers

      const a1 = new MCQuestion.InputGroup(1)

      a1.addInput(a_ans, 0.05, `$(a)$`);
      a1.addInput(m, 0.05, `$(b)$`);

      question.addInputGroup(a1)


      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Rparoc_Rparoc_Gradients_Exam_Q2() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x2 = -2 / MCRandom.randomInt(1, 16)
    const x = MCRandom.randomInt(1, 5);

    const poly = new MCMaths.Polynomial([x2, x , 0]);
    const roots = poly.roots();

    const peakX =  parseFloat(((-x)/(2*x2)).toFixed(1)) // 2x + (x) = 0 // x = -x/2
    const peakY = parseFloat((poly.evaluate(peakX)).toFixed(1))

    let t1 = 0
    let t2 = 0;

    if (peakX > 1)
    {
    t1 = MCRandom.randomInt(1, Math.ceil(peakX-1))
    t2 = MCRandom.randomInt(Math.ceil(peakX), Math.floor(roots[1]))
    }

    const h1 = parseFloat((poly.evaluate(t1)).toFixed(1))
    const h2 = parseFloat((poly.evaluate(t2)).toFixed(1))

    const c_ans = (h1-h2)/(t1-t2)

    const boundsA = 0.5
    const boundsC = 0.5

    /* END VARIABLES AND CONSTANTS */
    if ( peakX > 2 && peakX < 10 && peakY > 4 && peakY < 10 && roots[1] <= 10 && Math.abs(t1-t2)>2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph('question', `The graph below shows the height of a drone, in metres on the $y$-axis, plotted against time, in seconds on the $x$-axis.`);

      const g = new MCQuestion.Graph(10, 0, 10, 0, 1, 1);
      g.plot(`${x2}*x*x+${x}*x`, 0, roots[1])

      question.addGraph('question', g);


      question.addParagraph('question', `a) State the drone's peak height and how long it takes to reach it.`);
      question.addParagraph('question', `b) State the speed of the drone at its peak, explain your answer.`);
      question.addParagraph('question', `c) Determine the average speed of the drone between $${t1}$ and $${t2}$ seconds.`);

      // solutions

      question.addHeading('solution', `a) By reading from the graph:`);
      question.addParagraph('solution', `The peak height is the maximum $y$-coordinate on the curve which is when $y=${peakY}$`);
      question.addParagraph('solution', `$\\therefore$ The drone's peak height was $${peakY}$ metres.`);
      question.addParagraph('solution',` When $y=${peakY}$ on the graph, $x=${peakX}$`);
      question.addParagraph('solution', `$\\therefore$ It took the drone $${peakX}$ seconds to reach it's peak height.`);

      // B

      question.addHeading('solution', `b) The gradient at the peak of the graph is $0$ as the tangent at that point is a horizontal line, $y=${peakY}$`);
      question.addParagraph('solution', `$\\therefore$ The drone is travelling at $0$m/s because in a distance/time graph, the gradient at a point is the instantaneous speed of the object.`);

      // c

      question.addHeading('solution', `c) By reading from the graph:`);
      question.addParagraph('solution', `The height of the drone at $x=${t1}$ is $${h1}$m.`);
      question.addParagraph('solution', `The height of the drone at $x=${t2}$ is $${h2}$m.`);
      question.addHeading('solution', `The gradient of the line connecting these two points is the average speed of the drone during this time period.`);
      question.addParagraph('solution', `$m=\\displaystyle \\frac{\\Delta y}{\\Delta x}$`);
      question.addParagraph('solution', MCMaths.cleaner(`$=\\displaystyle \\frac{${h1}-${h2}}{${t1}-${t2}}$`, 0));
      if (parseFloat(c_ans.toFixed(2))===c_ans)
      {
        question.addParagraph('solution', `$ =${c_ans}$`);

      }
      else
      {
        question.addParagraph('solution', `$ \\approx ${parseFloat(c_ans.toFixed(2))}$`);
      }
      question.addParagraph('solution', `$\\therefore$ The average speed of the drone in this time period was $\\displaystyle ${parseFloat(c_ans.toFixed(2))}$m/s.`);

      // answers

      const g1 = new MCQuestion.InputGroup(2);
      g1.addInput(peakY, boundsA, `$(a)\\ $ Height = `);
      g1.addInput(peakX, boundsA, `$(a)\\ $ Time = `);
      question.addInputGroup(g1);

      const g2 = new MCQuestion.InputGroup(1);
      g2.addInput(0,0,`$(b)$`)
      g2.addInput(c_ans,boundsC,`$(c)$`)
      question.addInputGroup(g2);



      question.requiresSelfMarking()
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Rparoc_Rparoc_Gradients_Exam_Q3() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x = MCRandom.randomInt(4, 20)/4;
    const top = MCRandom.randomInt(16, 50)/4
    const bottom = MCRandom.randomInt(4, 40)/4;

    const b_speed = MCRandom.randomInt(1,3)

    function getY(xCoord){
      return top/(x*xCoord + bottom);
    }

    function evalDerivative(xCoord)
    {
      return (-top*x)/((x*xCoord+bottom)**2);
    }
    const tangentX = MCRandom.randomInt(25, 600)/100;

    const m = parseFloat((evalDerivative(tangentX)).toFixed(1))
    const c = parseFloat((getY(tangentX)-m*tangentX).toFixed(1)); // y - y_1 = m(x-x_1) \\// y = mx + (y_1 - mx_1)

    const b_ans = parseFloat(((top - bottom*b_speed)/(b_speed*x)).toFixed(1))

    const a_ans = parseFloat((top/bottom).toFixed(1));

    const c_ans = (a_ans-b_speed)/-b_ans

    /* END VARIABLES AND CONSTANTS */
    if ( a_ans > 4 && a_ans < 10 &&  b_ans < 4 && m!==0 && c!==0 && b_ans > 0.5) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph('question', `The figure below is a speed-time graph of a ball, as it slowly comes to a stop, with speed in m/s on the $y$-axis and time in seconds on the $x$-axis.`);

      const g = new MCQuestion.Graph(10, 0, 10, 0, 1, 1);
      g.plot(`${top}/(${x}*x + ${bottom})`, 0, 10);
      question.addGraph('question', g);

      question.addParagraph('question', `a) State the initial speed of the ball.`);
      question.addParagraph('question', `b) How long does it take for the ball to reach a speed of $${b_speed}$m/s?`);
      question.addParagraph('question', `c) Hence, calculate the average acceleration of the ball up until the point it reaches a speed of $${b_speed}$m/s.`);

      question.addParagraph('question', ` The line $y=${m}x+${c}$ is tangent to the curve above when $x=${tangentX}$`);
      question.addParagraph('question', `d) State, with justification, the acceleration of the ball after $${tangentX}$ seconds.`);

      // solution

      question.addHeading('solution', `a) The intial speed of the ball is found at $x=0$, where the curve intercepts the $y$-axis.`);
      question.addParagraph('solution',` By reading from the graph you find the ball has an initial speed of $${(a_ans)}$m/s.`);

      // b

      question.addHeading('solution', `b) Read from the graph where $y=${b_speed}$ to find the time it takes for the ball to reach $${b_speed}$m/s.`);
      question.addParagraph('solution', `It takes the ball $${parseFloat((b_ans.toFixed(2)))}$s to reach a speed of $${b_speed}$m/s.`);

      // c
      question.addHeading('solution', `c) The gradient of the line connecting the points highlighted in part a and b is the average acceleration of the ball between these points.`);
      question.addParagraph('solution', `$m=\\displaystyle \\frac{\\Delta y}{\\Delta x}$`);
      question.addParagraph('solution', MCMaths.cleaner(`$=\\displaystyle \\frac{${a_ans}-${b_speed}}{0-${b_ans}}$`, 0));
      if (parseFloat(c_ans.toFixed(2))===c_ans)
      {
        question.addParagraph('solution', `$ =${c_ans}$`);

      }
      else
      {
        question.addParagraph('solution', `$ \\approx ${parseFloat(c_ans.toFixed(2))}$`);
      }      
      question.addParagraph('solution', `$\\therefore$ The average acceleration of the ball in this time period was $\\displaystyle ${parseFloat((c_ans.toFixed(2)))}$m/s$^2$.`);

      // d

      question.addHeading('solution', `d) The gradient of the tangent at a point is the same as the rate of change of $y$ with respect to $x$ at the same point, which in this case is the acceleration.`);
      question.addParagraph('solution', `$\\therefore$ The acceleration of the ball after $${tangentX}$ seconds is $${m}$m/s$^2$.`);

      // answers

      const a = new MCQuestion.InputGroup(1);

      a.addInput(a_ans, 0.25, `$(a)$`);
      a.addInput(b_ans, 0.25, `$(b)$`);
      a.addInput(c_ans, 0.5, `$(c)$`);
      a.addInput(m, 0, `$(d)$`);
      question.addInputGroup(a);

      question.requiresSelfMarking();
      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Rparoc_Rparoc_Gradients_Exam_Q4() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x = MCRandom.randomInt(4, 20)/4;
    const top = MCRandom.randomInt(16, 50)/4
    const bottom = MCRandom.randomInt(4, 40)/4;

    function getY(xCoord){
      return -(top/(x*xCoord + bottom)) + (top/bottom);
    }

    function evalDerivative(xCoord)
    {
      return (top*x)/((x*xCoord+bottom)**2);
    }

    const a = MCRandom.randomInt(1,3);
    const a_ans = parseFloat((getY(a)).toFixed(1));

    const tangentX = MCRandom.randomInt(25, 600)/100;

    const x1 = MCRandom.randomInt(-10, 10)
    const x2 = MCRandom.randomInt(-10, 10);

    const m = parseFloat((evalDerivative(tangentX)).toFixed(1));
    const c = parseFloat((getY(tangentX)-m*tangentX).toFixed(1));

    const y1 = parseFloat((m*x1 + c).toFixed(2))
    const y2 = parseFloat((m*x2 + c).toFixed(2))

    /* END VARIABLES AND CONSTANTS */
    if ( getY(10) > 4 && getY(10) < 10 && Math.abs(x1-x2) > 1) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph('question', `The figure below shows the amount of water, in litres, in a container as it fills on the $y$-axis and time, in seconds, on the $x$-axis.`);

      const g = new MCQuestion.Graph(10, 0, 10, 0, 1, 1);
      g.plot(`-(${top}/(${x}*x + ${bottom}))+${top/bottom}`, 0, 10);
      question.addGraph('question', g);

      question.addParagraph('question', `a) How much water is in the container after $${a}$ seconds?`);
      question.addParagraph('question', `b) Is the container filling up quicker or slower as time progresses? Explain your answer.`);
      question.addParagraph('question', `The tangent to the curve above at $x=${tangentX}$ passes through the points $(${x1}, ${y1})$ and $(${x2}, ${y2})$`);
      question.addParagraph('question', `c) Calculate the rate the container is filling at after $${tangentX}$ seconds.`);

      // solution
      question.addHeading('solution', `a) By reading from the graph at $x=${a}$`);
      question.addParagraph('solution', `There is $${a_ans}$ litres of water after $${a}$ seconds.`);

      // b

      question.addHeading('solution', `b) The rate of change of the amount of water in the container can be determined by the gradient of the curve.`);
      question.addParagraph('solution', `By observation, you can see that the gradient decreases as time increases on the figure above.`);
      question.addParagraph('solution', `$\\therefore$ The container fills slower as time progresses.`);

      // c

      question.addHeading('solution', `c) The gradient of a tangent at a point is the same as the rate of change of the graph at that point, and in this case the rate at which the container is filling.`);
      question.addParagraph('solution', `$m=\\displaystyle \\frac{\\Delta y}{\\Delta x}$`);
      question.addParagraph('solution', MCMaths.cleaner(`$=\\displaystyle \\frac{${y1}-${y2}}{${x1}-${x2}}$`, 0));
      question.addParagraph('solution', `$\\displaystyle =${m}$`);
      question.addParagraph('solution', `$\\therefore$ The rate that the container is filling at is $${m}$ l/s.`);

      // answers

      const ans = new MCQuestion.InputGroup(1);
      ans.addInput(a_ans, 0.25, `$(a)$`)
      ans.addInput(m, 0.05, `$(c)$`)
      question.addInputGroup(ans)

      question.requiresSelfMarking();

      /* RETURN THE QUESTION */
      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}


export function GCSE_Higher_Rparoc_Rparoc_Gradients_Exam_Q5() {
  let count = 0;
  while (true) {
    /* START VARIABLES AND CONSTANTS */
    // your definitions here
    const x2 = -2 / MCRandom.randomInt(1, 16)
    const x = MCRandom.randomInt(1, 5);

    const poly = new MCMaths.Polynomial([x2, x , 0]);
    const roots = poly.roots();

    const peakX =  (-x)/(2*x2) // 2x + (x) = 0 // x = -x/2
    const peakY = poly.evaluate(peakX)
    // 
    function getY(xCoord)
    {
      return -(x2*xCoord**2 + x*xCoord) + peakY
    }

    let b = 0;
    let t1 = 0
    let t2 = 0
    if(peakX >=1 && peakX + 1 < 2*peakX - 1)
    {
      b = MCRandom.randomInt(Math.ceil(peakX), Math.floor(2*peakX));
      t1 = MCRandom.randomInt(0,Math.ceil(peakX - 1));
      t2 = MCRandom.randomInt(Math.floor(peakX+1), Math.floor(2*peakX - 1));
    }
    
    const y1 = parseFloat((getY(t1)).toFixed(1));
    const y2 = parseFloat((getY(t2)).toFixed(1));
    const b_ans = parseFloat((getY(b)).toFixed(1))
    const c_ans = (y1-y2)/(t1-t2)
    
    /* END VARIABLES AND CONSTANTS */
    if ( peakX > 2 && peakX < 10 && peakY > 4 && peakY < 10 && roots[1] < 10 && Math.abs(t1-t2)>2) {
      /* START BUILDING QUESTION USING MCQuestion */
      // your question writing here
      const question = new MCQuestion.ExamQuestion();

      question.addParagraph('question', `The graph below shows the distance of an RC car from its operator, in metres, on the $y$-axis and time, in seconds, on the $x$-axis.`);

      const g = new MCQuestion.Graph(10, 0, 10, 0, 1, 1);
      g.plot(`-1*(${x2}*x*x+${x}*x) + ${peakY}`, 0, peakX*2)

      question.addGraph('question', g);


      question.addParagraph('question', `a) How long does it take for the car to return to its operator from its initial position.`);
      question.addParagraph('question', `b) How far away is the car after $${b}$ seconds?`);

      question.addParagraph('question', `c) Estimate the average speed of the car between $${t1}$ and $${t2}$ seconds.`);

      // solution

      question.addHeading('solution', `a) The car has 'returned' when it's $0$m from its operator, when $y=0$`);
      question.addParagraph('solution', `By reading from the graph you find that $x=${peakX}$ when $y=0$`);
      question.addParagraph('solution', `$\\therefore$ The car takes $${peakX}$ seconds to return.`);

      // b

      question.addHeading('solution', `b) By reading from the graph at $x=${b}$`);
      question.addParagraph('solution', `When $x=${b}, y=${b_ans}$`);
      question.addParagraph('solution', `$\\therefore$ The car is $${b_ans}$m away after $${b}$ seconds.`);

      // c

      question.addHeading('solution', `c) Read the values of $y$ when $x=${t1}$ and $x=${t2}$ from the graph.`);
      question.addParagraph('solution', `When $x=${t1}, y=${y1}$ and when $x=${t2}, y=${y2}$`);
      question.addHeading('solution', `The average speed is the gradient of the line connecting these two points.`);
      question.addParagraph('solution', `$m=\\displaystyle \\frac{\\Delta y}{\\Delta x}$`);
      question.addParagraph('solution', MCMaths.cleaner(`$=\\displaystyle \\frac{${y1}-${y2}}{${t1}-${t2}}$`, 0));
      if(parseFloat((c_ans.toFixed(2))) !== c_ans)
      {
        question.addParagraph('solution', `$\\approx ${parseFloat((c_ans).toFixed(2))}$`);
      }
      else
      {
        question.addParagraph('solution', `$ =${c_ans}$`);
      }
      question.addParagraph('solution', `$\\therefore$ The average speed of the car in this time period was $\\displaystyle ${parseFloat((c_ans.toFixed(2)))}$m/s.`);

      // answers



      const a = new MCQuestion.InputGroup(1);
      a.addInput(peakX, 0.25, `$(a)$`)
      a.addInput(b_ans, 0.25, `$(b)$`)
      a.addInput(c_ans, 0.5, `$(c)$`)
      question.addInputGroup(a);


      /* RETURN THE QUESTION */

      try {
        return question.makeQuestion();
      } catch (error) {
        if (error instanceof MCError.QuestionError) {
          count += 1;
          if (count === 1000) {
            throw new MCError.QuestionError(error.message);
          }
        }
      }
    }
  }
}
