import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSiteID } from "../../system/utils/getSite";
import DevQuestionDisplay from "../developmentComps/DevQuestionDisplay";

const Development = () => {
  const { questionID } = useParams();
  const [question, setQuestion] = useState({});

  useEffect(() => {
    const parts = questionID.split("_");
    if (getSiteID() === 0) {
      import(
        `../../system/questions/questions/${parts[0]}/${parts[1]}/${parts[2]}/${parts[3]}/${parts[4]}/${parts[5]}/${questionID}`
      ).then((q) => {
        setQuestion(q[questionID]());
      });
    } else {
      import(
        `../../system/questions/questions/${parts[0]}/${parts[1]}/${parts[2]}/${parts[3]}/${questionID}`
      ).then((q) => {
        setQuestion(q[questionID]());
      });
    }
  }, [questionID]);

  return (
    <div className="container my-5">
      <DevQuestionDisplay question={question} />
    </div>
  );
};

export default Development;
