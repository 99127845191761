export default {
  AS: {
    Proof: {
      ProofByDeduction: false,
      ProofByExhaustion: false,
      DisproofByCounterExample: false,
    },
    AlgebraAndFunctions: {
      LawsOfIndices: false,
      Surds: false,
      QuadraticFunctions: false,
      SimultaneousEquations: false,
      Inequalities: false,
      Polynomials: false,
      Graphs: false,
      Transformations: false,
    },
    CoordinateGeometry: {
      StraightLines: false,
      Circles: false,
    },
    SequencesAndSeries: {
      BinomialExpansion: false,
    },
    Trigonometry: {
      RulesOfTrigonometry: false,
      TrigonometricIdentities: false,
      TrigonometricEquations: false,
    },
    ExponentialsAndLogarithms: {
      ExponentialFunctions: false,
      ExponentialDifferentiation: false,
      Logarithms: false,
      LogarithmLaws: false,
      ExponentialEquations: false,
      ExponentialGrowthAndDecay: false,
    },
    Differentiation: {
      BasicDifferentiation: false,
      AppliedDifferentiation: false,
      FirstPrinciples: false,
    },
    Integration: {
      PolynomialIntegration: false,
    },
    Vectors: {
      VectorSkills: false,
      VectorProblems: false,
    },
    Data: {
      Sampling: false,
      Data: false,
    },
    Measures: {
      Averages: false,
      Coding: false,
    },
    Representations: {
      Outliers: false,
      BoxPlots: false,
      Histograms: false,
    },
    Correlation: {
      Correlation: false,
      LinearRegression: false,
    },
    Probability: {
      Probabilities: false,
      VennDiagrams: false,
      MutuallyExclusive: false,
      TreeDiagrams: false,
    },
    Distributions: {
      Probability: false,
      BinomialDistribution: false,
      Cumulative: false,
    },
    Hypothesis: {
      HypothesisTesting: false,
    },
    ConstantAcceleration: {
      SUVATGraphs: false,
      SUVAT: false,
    },
    Forces: {
      ForceVectors: false,
      NewtonsSecondLaw: false,
      ConnectedParticles: false,
    },
    VariableAcceleration: {
      VariableAcceleration: false,
    },
  },
  A2: {
    Proof: {
      ProofByContradiction: false,
    },
    AlgebraAndFunctions: {
      AlgebraicDivision: false,
      ModulusFunction: false,
      CompositeAndInverseFunctions: false,
      PartialFractions: false,
      ParametricEquations: false,
      A2Transformations: false,
    },
    SequencesAndSeries: {
      Recurrence: false,
      ArithmeticSequences: false,
      GeometricSequences: false,
    },
    Trigonometry: {
      SmallAngleApproximation: false,
      ExactValues: false,
      DoubleAngleFormulae: false,
      Proofs: false,
      TrigonometryProblems: false,
    },
    Differentiation: {
      MaximaAndMinima: false,
      FunctionDerivatives: false,
      TurningPoints: false,
      ProductRule: false,
      QuotientRule: false,
      ChainRule: false,
      A2FirstPrinciples: false,
      ParametricResults: false,
    },
    Integration: {
      FunctionIntegration: false,
      IntegrationBySubstitution: false,
      IntegrationByParts: false,
      IntegrationOfPartialFractions: false,
      DifferentialEquations: false,
    },
    NumericalMethods: {
      IterativeMethods: false,
      NewtonRaphsonMethod: false,
      NumericalIntegration: false,
    },
    Vectors: {
      Vectors3D: false,
    },
    Regression: {
      ExponentialModels: false,
      MeasuringCorrelation: false,
      CorrelationHypothesisTesting: false,
    },
    Probability: {
      SetNotation: false,
      ConditionalProbability: false,
      ProbabilityFormulae: false,
      A2TreeDiagrams: false,
    },
    NormalDistribution: {
      NormalProbabilities: false,
      InverseNormal: false,
      BinomialApproximation: false,
      NormalHypothesisTesting: false,
    },
    Moments: {
      MomentsSkills: false,
      MomentsProblems: false,
    },
    Forces: {
      ResolvingForces: false,
    },
    Projectiles: {
      ProjectileSkills: false,
      ProjectileProblems: false,
    },
    AppliedForces: {
      Statics: false,
      A2ConnectedParticles: false,
    },
    FurtherKinematics: {
      FurtherVectors: false,
      A2VariableAcceleration: false,
      VectorCalculus: false,
    },
  },
};
