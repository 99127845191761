import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import AlertDropdown from "./AlertDropdown";
// eslint-disable-next-line import/no-cycle
import QuestionDisplay from "./QuestionDisplay";
import RoundedBtn from "./RoundedBtn";
import useWindowDimensions from "../system/hooks/useWindowDimensions";
// eslint-disable-next-line import/no-cycle
import { AppContext } from "../App";
import { getSiteID } from "../system/utils/getSite";

const HistoryCard = ({ questions, loading }) => {
  const { role } = useContext(AppContext);
  const [questionSlices, setQuestionSlices] = useState([[]]);
  const [pageNumber, setPageNumber] = useState(0);
  const history = useHistory();
  const { width } = useWindowDimensions();
  const site = getSiteID();

  useEffect(() => {
    const perPage = 5;
    const slices = [];
    const questionsCopy = [...questions];
    while (questionsCopy.length) slices.push(questionsCopy.splice(0, perPage));
    setQuestionSlices(slices);
  }, [questions]);

  const handleChangePage = (data) => {
    setPageNumber(data.selected);
  };

  const addLeadingMins = (mins) => {
    return mins > 9 ? mins : `0${mins}`;
  };

  const display = (str) => {
    let theTitle = _.startCase(str);
    theTitle = theTitle.replace("And", "and");
    theTitle = theTitle.replace("By", "by");
    theTitle = theTitle.replace("Of", "of");
    theTitle = theTitle.replace("Newtons", "Newton's");
    theTitle = theTitle.replace("A 2 ", "");
    theTitle = theTitle.replace("3 D", "3D");
    theTitle = theTitle.replace("Counter Example", "Counterexample");
    if (str === "Nvam") theTitle = "Notation, Vocabulary and Manipulation";
    if (str === "Mac") theTitle = "Mensuration and Calculation";
    if (str === "Pac") theTitle = "Properties and Constructions";
    if (str === "V") theTitle = "Vectors";
    if (str === "Fdap") theTitle = "Fractions, Decimals and Percentages";
    if (str === "Maa") theTitle = "Measures and Accuracy";
    if (str === "Rparoc") theTitle = "Ratio, Proportion and Rates of Change";
    if (str === "Sac") theTitle = "Structure and Calculation";
    if (str === "Seai") theTitle = "Solving Equations and Inequalities";

    return theTitle;
  };

  const generateText = (date, question) => {
    if (width > 768) {
      return `${addLeadingMins(date.getDate())}/${addLeadingMins(
        date.getMonth() + 1
      )}/${date.getFullYear()} (${addLeadingMins(
        date.getHours()
      )}:${addLeadingMins(date.getMinutes())}) \u2014 ${question.level}${
        site === 0 ? "," : ""
      } ${display(question.major)}, ${display(question.minor)}`;
    }
    if (width > 400) {
      return `${date.getDate()}/${addLeadingMins(
        date.getMonth() + 1
      )}/${date.getFullYear()} \u2014 ${display(question.minor)}`;
    }
    return `${display(question.minor)}`;
  };

  return (
    <div className="card text-center">
      <h5 className="card-header">History</h5>
      <div className="card-body">
        {loading ? (
          <div className="my-5">
            <ClipLoader loading color="#74c5ed" size="3em" />
          </div>
        ) : (
          <div>
            {questionSlices.length !== 0 ? (
              <div>
                {questionSlices[pageNumber].map((question, i) => {
                  let variant = "danger";
                  if (question.correct > 0 && question.correct < 1) {
                    variant = "warning";
                  } else if (question.correct >= 1) {
                    variant = "success";
                  }
                  const qDate = question.dateAnswered.split(/[- : T Z]/);
                  console.log(qDate);
                  const date = new Date(
                    qDate[0],
                    qDate[1] - 1,
                    qDate[2],
                    qDate[3],
                    qDate[4],
                    qDate[5]
                  );
                  let qObj;
                  if (question.type === "Exam") {
                    qObj = JSON.parse(question.context);
                  }
                  if (question.type === "MCQ") {
                    qObj = {
                      type: "mcq",
                      content: {
                        question: JSON.parse(question.context),
                        answers: JSON.parse(question.answers),
                      },
                    };
                  }
                  if (question.type === "Skills") {
                    qObj = {
                      type: "skill",
                      content: JSON.parse(question.context),
                    };
                  }
                  return (
                    <AlertDropdown
                      key={pageNumber * 10 + i}
                      text={generateText(date, question)}
                      variant={variant}
                    >
                      <>
                        <div style={{ textAlign: "left" }}>
                          <QuestionDisplay
                            question={qObj}
                            oldInputs={JSON.parse(question.answers)}
                            oldCorrectness={question.correct}
                          />
                        </div>
                        {question.correct < 1 && role === "PUPIL" && (
                          <div className="mt-2">
                            <RoundedBtn
                              text="Try Again"
                              width={12.5}
                              onClick={() => {
                                history.push("/try-again", {
                                  level: question.level,
                                  major: question.major,
                                  minor: question.minor,
                                  type: question.type,
                                  number: question.qNumber,
                                });
                              }}
                            />
                          </div>
                        )}
                      </>
                    </AlertDropdown>
                  );
                })}
                {questionSlices.length > 1 && (
                  <ReactPaginate
                    previousLabel="&laquo;"
                    nextLabel="&raquo;"
                    breakLabel="..."
                    breakClassName="page-item"
                    pageCount={questionSlices.length}
                    breakLinkClassName="page-link"
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={1}
                    containerClassName="pagination"
                    subContainerClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeClassName="page-item active"
                    disabledClassName="page-item disabled"
                    onPageChange={handleChangePage}
                  />
                )}
              </div>
            ) : (
              <div>No questions answered yet.</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

HistoryCard.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
};

HistoryCard.defaultProps = {
  loading: false,
};

export default HistoryCard;
